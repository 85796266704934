import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import infoBoxTemplate from "text!../../../templates/common/infoBoxTemplate.html";
import RSVP from "rsvp";

const DEFAULT_ICON_ERROR_CLASSNAME = "pe-7s-attention";
const InfoBoxView = Backbone.View.extend({
    

    initialize : function(options) {
        this.defaultTemplate =  _.template(infoBoxTemplate);
        
        if(options){
            this._infoBoxClassName = options.infoBoxClassName?options.infoBoxClassName : "";
            this._iconClass = options.iconClass?options.iconClass :"pe-7s-search";
            this._actionButtonIconClass = options.actionButtonIconClass?options.actionButtonIconClass :"";
            
            this._message = options.message?options.message :"";
            this._helpMessage =  options.helpMessage?options.helpMessage :"";
            this._actionButtonLabel =  options.actionButtonLabel?options.actionButtonLabel : null;
            this._displayActionButton = (this._actionButtonLabel == null)? false : true;
            this._isViewRendered =  false;
        
        }
    },
    
    events : {
        "click .actionButton" : "onActionButtonPressed",
    },
    
    onActionButtonPressed: function(){
        this.trigger("actionButtonPressed");
    },
    
    
    show : function(){
        this.$el.css("display", "");
    },
    
    hide : function(){
        this.$el.css("display", "none");
    },
    
    setInfoBoxClassName : function(newInfoBoxClassName){
        const oldClassName = this._infoBoxClassName;
        this._infoBoxClassName = newInfoBoxClassName;
        if(this._isViewRendered)
        {
            if(oldClassName)
            {
                this.$el.find(".infoBoxWrapper").removeClass(oldClassName)
            }

            if(this._infoBoxClassName){
                this.$el.find(".infoBoxWrapper").addClass(this._infoBoxClassName);
            }
        }
    },
    
    setIconClassName : function(newIconClassName){
        const oldIconClass = this._iconClass;
        this._iconClass = newIconClassName;
        if(this._isViewRendered)
        {
            if(oldIconClass)
            {
                this.$el.find(".iconWrapper").removeClass(oldIconClass)
            }

            if(this._iconClass){
                this.$el.find(".iconWrapper").addClass(this._iconClass);
            }
        }
    },
    
    setActionButtonIconClassName : function(newIconClassName){
        const oldIconClass = this._actionButtonIconClass;
        this._actionButtonIconClass = newIconClassName;
        if(this._isViewRendered)
        {
            if(oldIconClass)
            {
                this.$el.find(".actionButton").removeClass(oldIconClass)
            }

            if(this._actionButtonIconClass){
                this.$el.find(".actionButton").addClass(this._actionButtonIconClass);
            }
        }
    },
    
    setMessage : function(newMessage){
        const oldMessage = this._message;
        this._message = newMessage;
        if(this._isViewRendered)
        {
            if(oldMessage)
            {
                //this.$el.find(".iconWrapper").removeClass(oldIconClass)
            }

            if(this._message){
                this.$el.find(".message").html(this._message);
            }
        }
    },
    
    _setLoadingIconVisible : function(visible){
        if(visible){
            this.$el.find(".content").hide();
            this.$el.find("#loadingImg").show();
        }else{
            this.$el.find("#loadingImg").hide();
            this.$el.find(".content").show();
        
        }
    },
    
    showLoading : function(){
        this._setLoadingIconVisible(true);
    },
    
    hideLoading : function(){
        this._setLoadingIconVisible(false);
        this._reloadMessage();
    },
    
    showErrorMessage : function(errorMessage, helpErrorMessage, errorActionButtonLabel){
        if(this._isViewRendered)
        {
            this._setLoadingIconVisible(false);
            this.$el.find(".iconWrapper").removeClass(this._iconClass);
            this.$el.find(".iconWrapper").addClass(DEFAULT_ICON_ERROR_CLASSNAME);
            if(errorMessage){
                this.$el.find(".message").html(errorMessage);
            }else{
                this.$el.find(".message").html("");
            }
            if(helpErrorMessage){
                this.$el.find(".helpMessage").html(helpErrorMessage);
            }else{
                this.$el.find(".helpMessage").html("");
            }
            if(errorActionButtonLabel){
                this.$el.find(".actionButtonWrapper").css("display","flex");
                this.$el.find(".actionButtonWrapper").css("display","-webkit-flex");
                this.$el.find(".actionButton").html(errorActionButtonLabel);
            }else{
                this.$el.find(".actionButtonWrapper").css("display","none");
            }
        }
    },
    _reloadMessage : function(){
        if(this._isViewRendered)
        {
            this.update(this._iconClass, this._message, this._helpMessage,this._infoBoxClassName, this._actionButtonLabel, this._actionButtonIconClass  )
        }
    },
    
    update : function(iconClass,message,helpMessage,infoBoxClassName,actionButtonLabel,actionButtonIconClass){
        if(this._isViewRendered)
        {
            this.setInfoBoxClassName(infoBoxClassName);
            this.setIconClassName(iconClass);
            this.setMessage(message);
            this.setActionButtonLabel(actionButtonLabel);
            this.setActionButtonIconClassName(actionButtonIconClass);
            this.setHelpMessage(helpMessage);
        }
    },
    hideErrorMessage : function(errorMessage, helpErrorMessage, errorActionButtonLabel){
        this._setLoadingIconVisible(false);
        this._reloadMessage();
    },
    
    
    setHelpMessage : function(newHelpMessage){
        const oldHelpMessage = this._helpMessage;
        this._helpMessage = newHelpMessage;
        if(this._isViewRendered)
        {
            if(oldHelpMessage)
            {
                //this.$el.find(".iconWrapper").removeClass(oldIconClass)
            }

            if(this._helpMessage){
                this.$el.find(".helpMessage").html(this._helpMessage);
            }
        }
    },
    
    setActionButtonLabel : function(newActionButtonLabel){
        const oldActionButtonLabel = this._actionButtonLabel;
        this._actionButtonLabel = newActionButtonLabel;
        this._displayActionButton = (this._actionButtonLabel == null)? false : true;
        if(this._isViewRendered)
        {
            if(oldActionButtonLabel)
            {
                //this.$el.find(".iconWrapper").removeClass(oldIconClass)
            }

            if(this._actionButtonLabel){
                this.$el.find(".actionButtonWrapper").css("display","flex");
                this.$el.find(".actionButtonWrapper").css("display","-webkit-flex");
                this.$el.find(".actionButton").html(this._actionButtonLabel);
            }else{
                this.$el.find(".actionButtonWrapper").css("display","none");
            }
        }
    },
    

    render : function(){
        const params = {};
        
        params.iconClass = this._iconClass;
        params.actionButtonIconClass = this._actionButtonIconClass;
        params.message = this._message;
        params.helpMessage = this._helpMessage;
        params.infoBoxClassName = this._infoBoxClassName;
        params.actionButtonLabel = this._actionButtonLabel;
        params.displayActionButton = this._displayActionButton;
        this.$el.html(this.defaultTemplate(params));
        this.$el.css("overscroll","auto");
        this._isViewRendered =  true;
        return this;
    },
     

});

export default InfoBoxView;
