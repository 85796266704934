import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import popupTemplate from 'text!../../../templates/audioEditor/SequencerHelpContextMenuTemplate.html';
import ti18n from "i18n!nls/Sequenceri18n";

const SequencerHelpContextOptionContentView = Backbone.View.extend({


    initialize : function(options) {
        
        this.compiledTemlate =  _.template(popupTemplate);
        this.model = options? options.model : null;
        this._templateParams = {"model" : this.model, "ti18n": ti18n };
    },
    
    events : {
        "click #showHelpCenterBtn:not(.disabled)": "_onShowHelpCenterButtonClicked",
        "click #showInAppChatWidgetBtn:not(.disabled)": "_onShowInAppChatWidgetButtonClicked",
        "click #showBookATourPageBtn:not(.disabled)": "_onShowBookTourButtonClicked",
        "click #showFeatureRequestPageBtn:not(.disabled)": "_onShowFeatureRequestsButtonClicked",
    },

    _onShowHelpCenterButtonClicked :function(event){
        if(!$(event.target).hasClass("disabled")){
            this.trigger("showHelpCenter");
        }
    },

    _onShowInAppChatWidgetButtonClicked :function(event){
        if(!$(event.target).hasClass("disabled")){
            this.trigger("showInAppChatWidget");
        }
    },


    _onShowBookTourButtonClicked :function(event){
        if(!$(event.target).hasClass("disabled")){
            this.trigger("showBookTour");
        }
    },

    _onShowFeatureRequestsButtonClicked :function(event){
        if(!$(event.target).hasClass("disabled")){
            this.trigger("showFeatureRequests");
        }
    },

    
    getModel: function(){
        return this.model;
    },

    render : function() {
        
        this.$el.html(this.compiledTemlate(this._templateParams));
        this.setElement(this.$el.find("ul").first());
        return this;
    }

    
    

});
export default SequencerHelpContextOptionContentView;