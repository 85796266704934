"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
//Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Crunker is the simple way to merge, concatenate, play, export and download audio files using the Web Audio API.
 */
var Crunker = /** @class */ (function () {
    /**
     * Creates a new instance of Crunker with the provided options.
     *
     * If `sampleRate` is not defined, it will auto-select an appropriate sample rate
     * for the device being used.
     */
    function Crunker(_a) {
        var _b = _a === void 0 ? {} : _a, sampleRate = _b.sampleRate;
        this._context = this._createContext();
        sampleRate || (sampleRate = this._context.sampleRate);
        this._sampleRate = sampleRate;
    }
    /**
     * Creates Crunker's internal AudioContext.
     *
     * @internal
     */
    Crunker.prototype._createContext = function () {
        window.AudioContext = window.AudioContext || window.webkitAudioContext || window.mozAudioContext;
        return new AudioContext();
    };
    Object.defineProperty(Crunker.prototype, "context", {
        /**
         *
         * The internal AudioContext used by Crunker.
         */
        get: function () {
            return this._context;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * Asynchronously fetches multiple audio files and returns an array of AudioBuffers.
     */
    Crunker.prototype.fetchAudio = function () {
        var filepaths = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            filepaths[_i] = arguments[_i];
        }
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.all(filepaths.map(function (filepath) { return __awaiter(_this, void 0, void 0, function () {
                            var buffer;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(filepath instanceof File || filepath instanceof Blob)) return [3 /*break*/, 2];
                                        return [4 /*yield*/, filepath.arrayBuffer()];
                                    case 1:
                                        buffer = _a.sent();
                                        return [3 /*break*/, 4];
                                    case 2: return [4 /*yield*/, fetch(filepath).then(function (response) {
                                            if (response.headers.has('Content-Type') && !response.headers.get('Content-Type').includes('audio/')) {
                                                console.warn("Crunker: Attempted to fetch an audio file, but its MIME type is `".concat(response.headers.get('Content-Type').split(';')[0], "`. We'll try and continue anyway. (file: \"").concat(filepath, "\")"));
                                            }
                                            return response.arrayBuffer();
                                        })];
                                    case 3:
                                        buffer = _a.sent();
                                        _a.label = 4;
                                    case 4: return [4 /*yield*/, this._context.decodeAudioData(buffer)];
                                    case 5: return [2 /*return*/, _a.sent()];
                                }
                            });
                        }); }))];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };

    Crunker.prototype.getMatchingBufferChannelNumber = function(buffer, otherBufferNumberOfChannels, channelNumber){
        if(buffer.numberOfChannels > channelNumber){
            return channelNumber;
        }else{
            return (Math.ceil((channelNumber + 1) * buffer.numberOfChannels/otherBufferNumberOfChannels)) - 1;
        }
    }
    /**
     * Merges (layers) multiple AudioBuffers into a single AudioBuffer.
     *
     * **Visual representation:**
     *
     * ![](https://user-images.githubusercontent.com/12958674/88806278-968f0680-d186-11ea-9cb5-8ef2606ffcc7.png)
     */
    Crunker.prototype.mergeAudio = function (buffers, duplicateChannelsIfNecessary) {
        var getMatchingBufferChannelNumberFunction = this.getMatchingBufferChannelNumber;
        var output = this._context.createBuffer(this._maxNumberOfChannels(buffers), this._sampleRate * this._maxDuration(buffers), this._sampleRate);
        buffers.forEach(function (buffer) {
            if(duplicateChannelsIfNecessary){
                for (var channelNumber = 0; channelNumber < output.numberOfChannels; channelNumber++) {
                    var outputData = output.getChannelData(channelNumber);
                    var matchingChannelNumber = getMatchingBufferChannelNumberFunction(buffer, output.numberOfChannels, channelNumber);
                    var bufferData =  buffer.getChannelData(matchingChannelNumber);
                    for (var i = buffer.getChannelData(matchingChannelNumber).length - 1; i >= 0; i--) {
                        outputData[i] += bufferData[i];
                    }
                    output.getChannelData(channelNumber).set(outputData);
                }
            }else{
                for (var channelNumber = 0; channelNumber < buffer.numberOfChannels; channelNumber++) {
                    var outputData = output.getChannelData(channelNumber);
                    var bufferData = buffer.getChannelData(channelNumber);
                    for (var i = buffer.getChannelData(channelNumber).length - 1; i >= 0; i--) {
                        outputData[i] += bufferData[i];
                    }
                    output.getChannelData(channelNumber).set(outputData);
                }
            }
        });
        return output;
    };
    /**
     * Concatenates multiple AudioBuffers into a single AudioBuffer.
     *
     * **Visual representation:**
     *
     * ![](https://user-images.githubusercontent.com/12958674/88806297-9d1d7e00-d186-11ea-8cd2-c64cb0324845.png)
     */
    Crunker.prototype.concatAudio = function (buffers, duplicateChannelsIfNecessary) {
        var getMatchingBufferChannelNumberFunction = this.getMatchingBufferChannelNumber;
        var output = this._context.createBuffer(this._maxNumberOfChannels(buffers), this._totalLength(buffers), this._sampleRate);
        var offset = 0;
        buffers.forEach(function (buffer) {
            if(duplicateChannelsIfNecessary){
                for (var channelNumber = 0; channelNumber < output.numberOfChannels; channelNumber++) {
                    var matchingChannelNumber = getMatchingBufferChannelNumberFunction(buffer, output.numberOfChannels, channelNumber);
                    output.getChannelData(channelNumber).set(buffer.getChannelData(matchingChannelNumber), offset);
                }
                offset += buffer.length;
            }else{
                for (var channelNumber = 0; channelNumber < buffer.numberOfChannels; channelNumber++) {
                    output.getChannelData(channelNumber).set(buffer.getChannelData(channelNumber), offset);
                }
                offset += buffer.length;
            }
        });
        return output;
    };
    /**
     * Pads a specified AudioBuffer with silence from a specified start time,
     * for a specified length of time.
     *
     * Accepts float values as well as whole integers.
     *
     * @param buffer AudioBuffer to pad
     * @param padStart Time to start padding (in seconds)
     * @param seconds Duration to pad for (in seconds)
     */
    Crunker.prototype.padAudio = function (buffer, padStart, seconds) {
        if (padStart === void 0) { padStart = 0; }
        if (seconds === void 0) { seconds = 0; }
        if (seconds === 0)
            return buffer;
        if (padStart < 0)
            throw new Error('Crunker: Parameter "padStart" in padAudio must be positive');
        if (seconds < 0)
            throw new Error('Crunker: Parameter "seconds" in padAudio must be positive');
        var updatedBuffer = this._context.createBuffer(buffer.numberOfChannels, Math.ceil(buffer.length + seconds * buffer.sampleRate), buffer.sampleRate);
        for (var channelNumber = 0; channelNumber < buffer.numberOfChannels; channelNumber++) {
            var channelData = buffer.getChannelData(channelNumber);
            updatedBuffer
                .getChannelData(channelNumber)
                .set(channelData.subarray(0, Math.ceil(padStart * buffer.sampleRate) + 1), 0);
            updatedBuffer
                .getChannelData(channelNumber)
                .set(channelData.subarray(Math.ceil(padStart * buffer.sampleRate) + 2, updatedBuffer.length + 1), Math.ceil((padStart + seconds) * buffer.sampleRate));
        }
        return updatedBuffer;
    };
    /**
     * Plays the provided AudioBuffer in an AudioBufferSourceNode.
     */
    Crunker.prototype.play = function (buffer) {
        var source = this._context.createBufferSource();
        source.buffer = buffer;
        source.connect(this._context.destination);
        source.start();
        return source;
    };
    /**
     * Exports the specified AudioBuffer to a Blob, Object URI and HTMLAudioElement.
     *
     * Note that changing the MIME type does not change the actual file format. The
     * file format will **always** be a WAVE file due to how audio is stored in the
     * browser.
     *
     * @param buffer Buffer to export
     * @param type MIME type (default: `audio/wav`)
     */
    Crunker.prototype.export = function (buffer, type, bitDepthIfApplicable, progressReportFunction) {
        if (type === void 0) { type = 'audio/wav'; }
        var recorded = this._interleave(buffer);
        var dataview = this._writeHeaders(recorded, buffer.numberOfChannels, buffer.sampleRate, bitDepthIfApplicable, progressReportFunction);
        var audioBlob = new Blob([dataview], { type: type });
        return {
            blob: audioBlob,
            url: this._renderURL(audioBlob),
            element: this._renderAudioElement(audioBlob),
        };
    };
    /**
     * Downloads the provided Blob.
     *
     * @param blob Blob to download
     * @param filename An optional file name to use for the download (default: `crunker`)
     */
    Crunker.prototype.download = function (blob, filename) {
        if (filename === void 0) { filename = 'crunker'; }
        var a = document.createElement('a');
        a.style.display = 'none';
        a.href = this._renderURL(blob);
        a.download = "".concat(filename, ".").concat(blob.type.split('/')[1]);
        a.click();
        return a;
    };
    /**
     * Executes a callback if the browser does not support the Web Audio API.
     *
     * Returns the result of the callback, or `undefined` if the Web Audio API is supported.
     *
     * @param callback callback to run if the browser does not support the Web Audio API
     */
    Crunker.prototype.notSupported = function (callback) {
        return this._isSupported() ? undefined : callback();
    };
    /**
     * Closes Crunker's internal AudioContext.
     */
    Crunker.prototype.close = function () {
        this._context.close();
        return this;
    };
    /**
     * Returns the largest duration of the longest AudioBuffer.
     *
     * @internal
     */
    Crunker.prototype._maxDuration = function (buffers) {
        return Math.max.apply(Math, buffers.map(function (buffer) { return buffer.duration; }));
    };
    /**
     * Returns the largest number of channels in an array of AudioBuffers.
     *
     * @internal
     */
    Crunker.prototype._maxNumberOfChannels = function (buffers) {
        return Math.max.apply(Math, buffers.map(function (buffer) { return buffer.numberOfChannels; }));
    };
    /**
     * Returns the sum of the lengths of an array of AudioBuffers.
     *
     * @internal
     */
    Crunker.prototype._totalLength = function (buffers) {
        return buffers.map(function (buffer) { return buffer.length; }).reduce(function (a, b) { return a + b; }, 0);
    };
    /**
     * Returns whether the browser supports the Web Audio API.
     *
     * @internal
     */
    Crunker.prototype._isSupported = function () {
        return 'AudioContext' in window || 'webkitAudioContext' in window || 'mozAudioContext' in window;
    };
    /**
     * Writes the WAV headers for the specified Float32Array.
     *
     * Returns a DataView containing the WAV headers and file content.
     *
     * @internal
     */
    Crunker.prototype._writeHeaders = function (buffer, numOfChannels, sampleRate, bitDepth, progressReportFunction) {
        if(!bitDepth){
            bitDepth = 16;
        }
        var bytesPerSample = bitDepth / 8;
        var sampleSize = numOfChannels * bytesPerSample;
        var fileHeaderSize = 8;
        var chunkHeaderSize = 36;
        var chunkDataSize = buffer.length * bytesPerSample;
        var chunkTotalSize = chunkHeaderSize + chunkDataSize;
        var arrayBuffer = new ArrayBuffer(fileHeaderSize + chunkTotalSize);
        var view = new DataView(arrayBuffer);
        this._writeString(view, 0, 'RIFF');
        view.setUint32(4, chunkTotalSize, true);
        this._writeString(view, 8, 'WAVE');
        this._writeString(view, 12, 'fmt ');
        view.setUint32(16, 16, true);
        view.setUint16(20, 1, true);
        view.setUint16(22, numOfChannels, true);
        view.setUint32(24, sampleRate, true);
        view.setUint32(28, sampleRate * sampleSize, true);
        view.setUint16(32, sampleSize, true);
        view.setUint16(34, bitDepth, true);
        this._writeString(view, 36, 'data');
        view.setUint32(40, chunkDataSize, true);
        if(bitDepth){
            return this._floatTo16BitPCM(view, buffer, fileHeaderSize + chunkHeaderSize, progressReportFunction);
        }else{
            return this._writeFloat32(view, buffer, fileHeaderSize + chunkHeaderSize, progressReportFunction);
        }
    };
    /**
     * Converts a Float32Array to 16-bit PCM.
     *
     * @internal
     */
    Crunker.prototype._floatTo16BitPCM = function (dataview, buffer, offset, progressReportFunction) {
        for (var i = 0; i < buffer.length; i++, offset += 2) {
            var tmp = Math.max(-1, Math.min(1, buffer[i]));
            dataview.setInt16(offset, tmp < 0 ? tmp * 0x8000 : tmp * 0x7fff, true);
            if(progressReportFunction){
                progressReportFunction(i/buffer.length * 100);
            }
        }
        return dataview;
    };

    Crunker.prototype._writeFloat32 = function (output, offset, input, progressReportFunction) {
      for (var i = 0; i < input.length; i++, offset += 4) {
        output.setFloat32(offset, input[i], true);
        if(progressReportFunction){
            progressReportFunction(i/input.length * 100);
        }
      }
      return output;
    }
    /**
     * Writes a string to a DataView at the specified offset.
     *
     * @internal
     */
    Crunker.prototype._writeString = function (dataview, offset, header) {
        for (var i = 0; i < header.length; i++) {
            dataview.setUint8(offset + i, header.charCodeAt(i));
        }
    };
    /**
     * Converts an AudioBuffer to a Float32Array.
     *
     * @internal
     */
    Crunker.prototype._interleave = function (input) {
        var channels = Array.from({ length: input.numberOfChannels }, function (_, i) { return i; });
        var length = channels.reduce(function (prev, channelIdx) { return prev + input.getChannelData(channelIdx).length; }, 0);
        var result = new Float32Array(length);
        var index = 0;
        var inputIndex = 0;
        // for 2 channels its like: [L[0], R[0], L[1], R[1], ... , L[n], R[n]]
        while (index < length) {
            channels.forEach(function (channelIdx) {
                result[index++] = input.getChannelData(channelIdx)[inputIndex];
            });
            inputIndex++;
        }
        return result;
    };
    /**
     * Creates an HTMLAudioElement whose source is the specified Blob.
     *
     * @internal
     */
    Crunker.prototype._renderAudioElement = function (blob) {
        var audio = document.createElement('audio');
        audio.controls = true;
        audio.src = this._renderURL(blob);
        return audio;
    };
    /**
     * Creates an Object URL for the specified Blob.
     *
     * @internal
     */
    Crunker.prototype._renderURL = function (blob) {
        return (window.URL || window.webkitURL).createObjectURL(blob);
    };
    return Crunker;
}());

export default Crunker;