import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import Utils from "models/helper/Utils";
import InfoBoxView from "views/common/InfoBoxView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import RSVP from "rsvp";

const InfoBoxController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if(options)
        {

                this._infoBoxClassName = options.infoBoxClassName?options.infoBoxClassName : "";
                this._iconClass = options.iconClass?options.iconClass :"pe-7s-search";
                this._actionButtonIconClass = options.actionButtonIconClass?options.actionButtonIconClass :"";
                this._message = options.message?options.message :"";
                this._helpMessage =  options.helpMessage?options.helpMessage :"";
                this._actionButtonLabel =  options.actionButtonLabel?options.actionButtonLabel : null;
                this._actionButtonHandlerFunction = options.actionButtonHandlerFunction?options.actionButtonHandlerFunction : null;
                this._viewOptions = options;

        }
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },
    
    onActionButtonPressed : function(){
        if(this._actionButtonHandlerFunction){
            this._actionButtonHandlerFunction();
        }
    },
    
    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
            
                this.view = new InfoBoxView(this._viewOptions);
                this.listenTo(this.view, "actionButtonPressed",this.onActionButtonPressed);
                this.view.render();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default InfoBoxController;