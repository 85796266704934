import React from "react";
import { ConfigProvider } from "antd";
import useDefaultTrebbleAntDTokenConfig from "../helper/useDefaultTrebbleAntDTokenConfig";
import PropTypes from "prop-types";

const TrebbleConfigProvider = ({useEditorTheme, ...restProps}) => {
  const themeConfig = useDefaultTrebbleAntDTokenConfig({useEditorTheme});

  return (
    <ConfigProvider theme={themeConfig}  {...restProps}/>
  );
};

TrebbleConfigProvider.propTypes = {
  useEditorTheme: PropTypes.bool
}
export default TrebbleConfigProvider;
