import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import ReplacementSequencerNode from "models/audioEditor/sequencerNodes/ReplacementSequencerNode";
import RSVP from "rsvp";

const CLASSNAME = "DeletedSequencerNode";
const DeletedSequencerNode =  ReplacementSequencerNode.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        ReplacementSequencerNode.apply(this, [attributes, options]);

        
    },

    getConstructor : function(){
        return DeletedSequencerNode;
    },

    cloneJSON: function(){
        const objJson = ReplacementSequencerNode.prototype.cloneJSON.call(this);
        objJson.type = DeletedSequencerNode.CLASSNAME;
        return objJson;
    },

    getSerializedData : function(){
        const serializedData = ReplacementSequencerNode.prototype.getSerializedData.call(this);
        return serializedData;
    },

    restoreFromSerializedData: function(serializedData, audioSegmentFactoryInstance, audioFilterFactoryInstance, additionalSpeakerInfoArray){
        return ReplacementSequencerNode.prototype.restoreFromSerializedData.call(this, serializedData, audioSegmentFactoryInstance, audioFilterFactoryInstance, additionalSpeakerInfoArray);
    },

    afterAllInstancesCreatedFromSerializedData : function(serializedData, sequencerNodeCidToSequencerNodeMap){
        ReplacementSequencerNode.prototype.afterAllInstancesCreatedFromSerializedData.call(this, serializedData, sequencerNodeCidToSequencerNodeMap);
    },




});

DeletedSequencerNode.CLASSNAME =  CLASSNAME;
export default DeletedSequencerNode; 