import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PersistentModels from "services/PersistentModels";
import pageTemplate from 'text!../../../templates/capsulesPage/CapsulesPageTemplate.html';
import ti18n from "i18n!nls/CapsulesPagei18n";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const CapsulesPageView = Backbone.View.extend({

    template : _.template(pageTemplate),
    "pageName" : "Capsules",

    events : {
        "click #homeHeaderButton": "showHomePage",
        "click #myCapsulesTab" : "_onOnAirCapsulesTabSelected",
        "click #myOffAirCapsulesTab" : "_onOffAirCapsulesTabSelected",
        "click #myDraftCapsulesTab" : "_onDraftCapsulesTabSelected",
        "click #myReceivedCapsulesTab" : "_onReceivedCapsulesAirTabSelected",
        "click #header-back-button": "navigateToPreviousPage",
        "click #createNewCapsuleButton" : "createCapsule",
        "click #whatsacapsuleButton" : "onWhatsACapsuleButtonClicked"
    },

    initialize : function(options) {
        this.selectedTab = null;
        this._onOnAirCapsulesTabSelected()
        this._capsulesListView = options.capsulesListView;
        this._receivedCapsulesListView = options.receivedCapsulesListView;
        this._myOffAirCapsulesListView = options.myOffAirCapsulesListView;
        this._myDraftCapsulesListView = options.myDraftCapsulesListView;
        this._myScheduledCapsulesListView = options.myScheduledCapsulesListView;
    },

    _onOnAirCapsulesTabSelected : function(){
        this.selectedTab = "myCapsulesTab";
    },
    _onOffAirCapsulesTabSelected : function(){
        this.selectedTab = "myOffAirCapsulesTab";
    },
    _onDraftCapsulesTabSelected : function(){
        this.selectedTab = "myDraftCapsulesTab";
    },

    _onReceivedCapsulesAirTabSelected : function(){
        this.selectedTab = "myReceivedCapsulesTab";
    },

    onWhatsACapsuleButtonClicked : function(){
        this.trigger("onWhatsACapsuleButton");
    },

    showHomePage : function(){
        this.trigger("showHomePage")
    },

    navigateToPreviousPage : function(){
        PersistentModels.getInstance().getRouter().navigateToPreviousPage();
    },

    createCapsule : function(){
        this.trigger("createCapsule");
    },

    setCapsuleListView : function(capsulesListView){
        this.$el.find("#myOnAirCapsulesListWrapper > #loadingImg").remove();
        this._capsulesListView = capsulesListView;
        if(this._capsulesListView){
            this.$el.find("#myOnAirCapsulesListWrapper").append(this._capsulesListView.$el);
            /*this._capsulesListView.getCollectionContainer$el().css("display","-webkit-flex");
            this._capsulesListView.getCollectionContainer$el().css("display","-moz-flex");
            this._capsulesListView.getCollectionContainer$el().css("display","-ms-flex");
            this._capsulesListView.getCollectionContainer$el().css("display","flex");*/
        }
    },

    setMyOffAirCapsuleListView : function(offAircapsulesListView){
        this.$el.find("#myOffAirCapsulesListWrapper > #loadingImg").remove();
        this._myOffAirCapsulesListView = offAircapsulesListView;
        if(this._myOffAirCapsulesListView){
            this.$el.find("#myOffAirCapsulesListWrapper").append(this._myOffAirCapsulesListView.$el);
            /*this._capsulesListView.getCollectionContainer$el().css("display","-webkit-flex");
            this._capsulesListView.getCollectionContainer$el().css("display","-moz-flex");
            this._capsulesListView.getCollectionContainer$el().css("display","-ms-flex");
            this._capsulesListView.getCollectionContainer$el().css("display","flex");*/
        }
    },

    setMyDraftCapsuleListView : function(draftcapsulesListView){
        this.$el.find("#myDraftCapsulesListWrapper > #loadingImg").remove();
        this._myDraftCapsulesListView = draftcapsulesListView;
        if(this._myDraftCapsulesListView){
            this.$el.find("#myDraftCapsulesListWrapper").append(this._myDraftCapsulesListView.$el);
            /*this._capsulesListView.getCollectionContainer$el().css("display","-webkit-flex");
            this._capsulesListView.getCollectionContainer$el().css("display","-moz-flex");
            this._capsulesListView.getCollectionContainer$el().css("display","-ms-flex");
            this._capsulesListView.getCollectionContainer$el().css("display","flex");*/
        }
    },

    setMyScheduledCapsuleListView : function(scheduledcapsulesListView){
        this.$el.find("#myDraftCapsulesListWrapper > #loadingImg").remove();
        this._myScheduledCapsulesListView = scheduledcapsulesListView;
        if(this._myScheduledCapsulesListView){
            this.$el.find("#myScheduledCapsulesListWrapper").append(this._myScheduledCapsulesListView.$el);
            /*this._capsulesListView.getCollectionContainer$el().css("display","-webkit-flex");
            this._capsulesListView.getCollectionContainer$el().css("display","-moz-flex");
            this._capsulesListView.getCollectionContainer$el().css("display","-ms-flex");
            this._capsulesListView.getCollectionContainer$el().css("display","flex");*/
        }
    },


    setReceivedCapsuleListView : function(receivedCapsulesListView){
        this.$el.find("#myReceivedCapsulesListWrapper > #loadingImg").remove();
        this._receivedCapsulesListView = receivedCapsulesListView;
        if(this._receivedCapsulesListView){
            this.$el.find("#myReceivedCapsulesListWrapper").append(this._receivedCapsulesListView.$el);
            /*this._capsulesListView.getCollectionContainer$el().css("display","-webkit-flex");
            this._capsulesListView.getCollectionContainer$el().css("display","-moz-flex");
            this._capsulesListView.getCollectionContainer$el().css("display","-ms-flex");
            this._capsulesListView.getCollectionContainer$el().css("display","flex");*/
        }
    },

    getCapsuleListView : function(){
        return this._capsulesListView;
    },

    getReceivedCapsuleListView : function(){
        return this._receivedCapsulesListView;
    },

    getMyOffAirCapsuleListView : function(){
        return this._myOffAirCapsulesListView;
    },

    getMyDraftCapsuleListView : function(){
        return this._myDraftCapsulesListView;
    },

    getMyScheduledCapsuleListView : function(){
        return this._myScheduledCapsulesListView;
    },

    render : function() {
        this.$el.html(this.template({"ti18n": ti18n}));
        this.setElement(this.$el.find("div").first());
        this.$el.page();

        //manually adding the wrapper necessary by JQM for showing Panel
        //JQM does it on the fly but that can cause flickering on the screen when it is done by jquery mobile
        //the workaround is to do that manually when the page is getting initialized
        this.$el.children( ".ui-header:not(.ui-header-fixed), .ui-content:not(.ui-popup), .ui-footer:not(.ui-footer-fixed)" ).wrapAll( "<div class='ui-panel-wrapper'></div>" )
        this.$el.on("pagebeforeshow", this.onPageBeforeShow.bind(this));
        this.$el.on("pagehide", this._onPageHide.bind(this));
        this.$el.on("pageshow", this._onPageShow.bind(this));
        if(this._capsulesListView){
            this.$el.find("#myOnAirCapsulesListWrapper").append(this._capsulesListView.$el);
            /*this._capsulesListView.getCollectionContainer$el().css("display","-webkit-flex");
            this._capsulesListView.getCollectionContainer$el().css("display","-moz-flex");
            this._capsulesListView.getCollectionContainer$el().css("display","-ms-flex");
            this._capsulesListView.getCollectionContainer$el().css("display","flex");*/
        }
        if(this._receivedCapsulesListView){
            this.$el.find("#myReceivedCapsulesListWrapper").append(this._receivedCapsulesListView.$el);
            /*this._capsulesListView.getCollectionContainer$el().css("display","-webkit-flex");
            this._capsulesListView.getCollectionContainer$el().css("display","-moz-flex");
            this._capsulesListView.getCollectionContainer$el().css("display","-ms-flex");
            this._capsulesListView.getCollectionContainer$el().css("display","flex");*/
        }

        if(this._myOffAirCapsulesListView){
            this.$el.find("#myOffAirCapsulesListWrapper").append(this._myOffAirCapsulesListView.$el);
            /*this._capsulesListView.getCollectionContainer$el().css("display","-webkit-flex");
            this._capsulesListView.getCollectionContainer$el().css("display","-moz-flex");
            this._capsulesListView.getCollectionContainer$el().css("display","-ms-flex");
            this._capsulesListView.getCollectionContainer$el().css("display","flex");*/
        }


        if(this._myDraftCapsulesListView){
            this.$el.find("#myDraftCapsulesListWrapper").append(this._myDraftCapsulesListView.$el);
            /*this._capsulesListView.getCollectionContainer$el().css("display","-webkit-flex");
            this._capsulesListView.getCollectionContainer$el().css("display","-moz-flex");
            this._capsulesListView.getCollectionContainer$el().css("display","-ms-flex");
            this._capsulesListView.getCollectionContainer$el().css("display","flex");*/
        }
        if(this._myScheduledCapsulesListView){
            this.$el.find("#myScheduledCapsulesListWrapper").append(this._myScheduledCapsulesListView.$el);
            /*this._capsulesListView.getCollectionContainer$el().css("display","-webkit-flex");
            this._capsulesListView.getCollectionContainer$el().css("display","-moz-flex");
            this._capsulesListView.getCollectionContainer$el().css("display","-ms-flex");
            this._capsulesListView.getCollectionContainer$el().css("display","flex");*/
        }
        return this;
    },

    _onPageShow : function(){
        this.trigger("pageShow");
    },

    onPageBeforeShow : function(){
        this.$el.find("#"+this.selectedTab).addClass("ui-btn-active");
    },

    _onPageHide : function(){
        this.trigger("pageHide");
        if(PersistentModels.getInstance().getRouter().isBrowserHistoryGoingBack())
        {
            //PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._listenerViewportKey);
            this.onBeforeRemoveFromPage();
            this.remove();
            this.onRemovedFromPage();
        }
    },


    showWhatsACapsuleButton : function(){
        this.$el.find("#whatsacapsuleButton").show();
    },

    hideWhatsACapsuleButton : function(){
        this.$el.find("#whatsacapsuleButton").hide();
    },


    onBeforeRemoveFromPage : function(){
        this.trigger("beforeRemoveFromPage", this);
    },

    onRemovedFromPage : function(){
        this.undelegateEvents();
        this.$el.removeData().unbind(); 
        this.trigger("removedFromPage", this);
    },





});

export default CapsulesPageView;