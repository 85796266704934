import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import OptionPopupView from "views/common/OptionPopupView";
import Utils from "models/helper/Utils";
import ti18n from "i18n!nls/Commoni18n";
import popupTemplate from 'text!../../../templates/common/OptionPopup.html';

const SongTagManagerPopup = Backbone.View.extend({

    template : _.template(popupTemplate),

    "pageName" : "My Library",

    initialize : function(options) {
        this._contentView =  options? options.contentView: null;
        this._song =  options? options.song: null;
    },


    events : {


        "click #closeBtn" : "_close",
        
        
    },
    
    setContentView : function(contentView){
        this._contentView  = contentView;
    },

    _close : function(){
        this.$el.popup('close' );
    },

    
    render : function() {
        this.$el.html(this.template({"contentPosition": "center","displayTitle": false, "titleToDisplay": ""}));
        this.setElement(this.$el.find("div").first());
        const elementId = this.$el.attr('id');
        this.$el.on({
            popupbeforeposition: (function() {
                const maxHeight = $( window ).height() - 0 + "px";
                const width = $( window ).width() - 0 + "px";
                this.$el.css( "height", maxHeight );
                this.$el.css( "width", width );
                
            }).bind(this)
        });
        this.$el.on({
            popupafterclose: (function() {
                this.trigger("closed");
                this.$el.remove();
                this.undelegateEvents();
                
            }).bind(this)});
        $.mobile.pageContainer.prepend(this.$el);
        this.$el.popup({transition:"fade", dismissible: false,  tolerance: "0,0"});
        if(elementId)
        {
            const screenPopup$el = $("#"+elementId + "-screen");
            $.mobile.pageContainer.remove("#"+elementId + "-screen");
            $.mobile.pageContainer.prepend(screenPopup$el);
        }
        if(this._contentView){
            this.$el.find(".popupContent").append($("<div class='trebblePopupHeader'>"+ window.getI18n(ti18n, "GRADE_SONG") +"</div>"));
            this.$el.find(".popupContent").append(this._contentView.$el);
        }
        this.$el.enhanceWithin();
        
        Utils.getInstance().closeCurrentJQMPopup().then((function(){
            this.$el.popup('open' );
        }).bind(this));
        return this;
    }

});

export default SongTagManagerPopup;