	import $ from 'jquery';
    import _ from 'underscore';
    import Backbone from "backbone";
    import Base64 from 'Base64';

    const TrebbleLocalStorageModel = Backbone.Model.extend({
    
        //list of all key present in local storage
        "default" : {
            "usernameKey" : "trebbleUserName",
            "passwordKey" : "trebblePassword",
            
        },

        initialize : function() {

        },

        saveUsername : function(usernameToSave){
            window.localStorage.setItem(this.get("usernameKey"), usernameToSave);
        },
        
        
        /*savePassword : function(passwordToSave){
        const encodedPasswordToSave =  Base64.encode(passwordToSave);
            window.localStorage.setItem(this.get("passwordKey"), encodedPasswordToSave);
        },*/
        
        retrieveUsername : function(){
            return window.localStorage.getItem(this.get("usernameKey"));
        },
        
        
        savePassword : function(passwordToSave){
        
            const encodedPasswordToSave = window.localStorage.getItem(this.get("passwordKey"));
            if(encodedPasswordToSave)
            {
                return Base64.decode(encodedPasswordToSave);
            }
            return null;
        },
        

    });

    const TrebbleLocalStorage = new TrebbleLocalStorageModel();

    export default {
        getInstance : function() {
            return TrebbleLocalStorage;
        }
    };