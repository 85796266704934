import _ from 'underscore';
import SequencerOperation from "models/audioEditor/sequencerOperation/SequencerOperation";
import ti18n from 'i18n!nls/Sequenceri18n';

const RenameSpeakerNameSequencerOperation =  SequencerOperation.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperation.apply(this, [attributes, options]);
    },

    execute : function(speakerInfo, newModifiedSpeakerLabel){
        const speakerLabelAlreadyExist = this.getSequencer().isSpeakerWithLabelExist(newModifiedSpeakerLabel);
        if(speakerLabelAlreadyExist){
            throw window.getI18n(ti18n, "A_SPEAKER_WITH_THE_SAME_NAME_ALREADY_EXIST")
        }
        const initialSpeakerLabel = speakerInfo.getInitialSpeakerLabel();
        const oldModifiedSpeakerLabel = speakerInfo.getModifiedSpeakerLabel();
        speakerInfo.setModifiedSpeakerLabel(newModifiedSpeakerLabel);
        this.getSequencer().getHistoryManagement().addSequencerOperationRenameSpeakerLabelLog(speakerInfo, initialSpeakerLabel, oldModifiedSpeakerLabel, newModifiedSpeakerLabel);
        this.trigger("speakerLabelChange");
        return true;
    },


    undo: function(sequencerOperationRenameSpeakerLabelLog){
        const speakerInfo = sequencerOperationRenameSpeakerLabelLog.getSpeakerInfo();
        const initialSpeakerLabel = sequencerOperationRenameSpeakerLabelLog.getInitialSpeakerLabel();
        const oldModifiedSpeakerLabel = sequencerOperationRenameSpeakerLabelLog.getOldModifiedSpeakerLabel();
        const newModifiedSpeakerLabel = sequencerOperationRenameSpeakerLabelLog.getNewModifiedSpeakerLabel();
        speakerInfo.setModifiedSpeakerLabel(oldModifiedSpeakerLabel);
        this.trigger("speakerLabelChange");
    },

    redo: function(sequencerOperationRenameSpeakerLabelLog){
        const speakerInfo = sequencerOperationRenameSpeakerLabelLog.getSpeakerInfo();
        const initialSpeakerLabel = sequencerOperationRenameSpeakerLabelLog.getInitialSpeakerLabel();
        const oldModifiedSpeakerLabel = sequencerOperationRenameSpeakerLabelLog.getOldModifiedSpeakerLabel();
        const newModifiedSpeakerLabel = sequencerOperationRenameSpeakerLabelLog.getNewModifiedSpeakerLabel();
        speakerInfo.setModifiedSpeakerLabel(newModifiedSpeakerLabel);
        this.trigger("speakerLabelChange");
    },





});

export default RenameSpeakerNameSequencerOperation; 