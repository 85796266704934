var amdi18n={"__root":{"RETRIEVING_TREBBLE_INFORMATION":"Retrieving Channel Information ...","GETTING_YOU_SUBSCRIBED_TO":"Getting You Subscribed to","THANKS_FOR_SUBSCRIBING_TO":"Thanks for subscribing to","PLEASE_ALLOW_POPUPS":"Please allow popups","AN_ERROR_OCCURED_WHEN_TRYING_TO_SUBSCRIBE_TO":"An error occured when we were trying to get you subscribe to","WE_COULDNT_FIND_THE_SPECIFIED_TREBBLE":"We couldn't find the specifed channel."},"__fr":{"RETRIEVING_TREBBLE_INFORMATION":"Récupération des informations de la chaîne ...","GETTING_YOU_SUBSCRIBED_TO":"Nous établissons votre abonnement à","THANKS_FOR_SUBSCRIBING_TO":"Merci de vous être abonné à","PLEASE_ALLOW_POPUPS":"autoriser les popups","AN_ERROR_OCCURED_WHEN_TRYING_TO_SUBSCRIBE_TO":"Une erreur est survenue lorsque nous avons essayé de vous abonner à","WE_COULDNT_FIND_THE_SPECIFIED_TREBBLE":"Nous n'avons pas pu trouver la chaîne spécifiée."},"__en":{"RETRIEVING_TREBBLE_INFORMATION":"Retrieving Channel Information ...","GETTING_YOU_SUBSCRIBED_TO":"Getting You Subscribed to","THANKS_FOR_SUBSCRIBING_TO":"Thanks for subscribing to","PLEASE_ALLOW_POPUPS":"Please allow popups","AN_ERROR_OCCURED_WHEN_TRYING_TO_SUBSCRIBE_TO":"An error occured when we were trying to get you subscribe to","WE_COULDNT_FIND_THE_SPECIFIED_TREBBLE":"We couldn't find the specifed channel."}};amdi18n.init=function(language){
		// get the default language
		if(!language){
			if(typeof window !== 'undefined' && window._i18n && window._i18n.locale){
				language = window._i18n.locale;
			}else if(typeof document !== 'undefined' && document.documentElement.lang){
				language = document.documentElement.lang;
			}else if(typeof navigator !== 'undefined' && (navigator.languages || navigator.language || navigator.userLanguage)){
				// navigator.languages does not exist in IE 11
				language = (navigator.languages && navigator.languages[0]) ||
					navigator.language ||
					navigator.userLanguage;
				language = (language || 'root').toLowerCase();
			}else{
				language = 'root';
			}
		}
		var target = this['__' + language] || this.__root;

		// copy definition to root level
		if (target) {
			for (var name in target) {
				this[name] = target[name];
			}
		}

		// fallback to root
		var recursiveFallback = function(target, source){
			for(var name in source){
				switch(typeof target[name]){
				case 'undefined':
					target[name] = source[name];
					break;
				case 'object':
					recursiveFallback(target[name], source[name]);
				}
			}
		};
		recursiveFallback(this, this.__root);
	};amdi18n.init();module.exports=amdi18n;