import React from "react";
import { Table} from "antd";
import ConfigProvider from "./ConfigProvider";
import useDefaultTrebbleAntDTokenConfig from "../helper/useDefaultTrebbleAntDTokenConfig";

const TrebbleTable = (props) => {
  const themeConfig = useDefaultTrebbleAntDTokenConfig();

  return (
    <ConfigProvider theme={themeConfig}>
      <Table {...props} />
    </ConfigProvider>
  );
};

export default TrebbleTable;
