
import _ from 'underscore';
import ReplaceSelectedNodesOperation from "models/audioEditor/sequencerOperation/ReplaceSelectedNodesOperation";
import DeletedSequencerNode from "models/audioEditor/sequencerNodes/DeletedSequencerNode";
import Utils from "models/helper/Utils";


const DeleteAllExceptSelectedNodesOperation =  ReplaceSelectedNodesOperation.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        ReplaceSelectedNodesOperation.apply(this, [attributes, options]);
    },

    execute : function(startSequencerNodeToKeep, endSequencerNodeToKeep){
        const arrayOfNodeToDeleteBefore = [];
        const arrayOfNodeToDeleteAfter = [];
        let previousNode = startSequencerNodeToKeep.getPrevious();
        while(previousNode){
            arrayOfNodeToDeleteBefore.push(previousNode);
            previousNode = previousNode.getPrevious();
        }
        arrayOfNodeToDeleteBefore.reverse();
       
        let nextNode =  endSequencerNodeToKeep.getNext();
        while(nextNode){
            arrayOfNodeToDeleteAfter.push(nextNode);
            nextNode = nextNode.getNext();
        }
        
        let deleteNodeBefore = null;
        if(arrayOfNodeToDeleteBefore.length > 0){
            deleteNodeBefore = new DeletedSequencerNode({"audioSegment": Utils.getInstance().getDeletedAudioSegment(), "sequencer": this.getSequencer()});
            this.replaceSequencerNodes(deleteNodeBefore, arrayOfNodeToDeleteBefore);
        }

        let deleteNodeAfter = null;
        if(arrayOfNodeToDeleteAfter.length > 0){
            deleteNodeAfter = new DeletedSequencerNode({"audioSegment": Utils.getInstance().getDeletedAudioSegment(), "sequencer": this.getSequencer()});
            this.replaceSequencerNodes(deleteNodeAfter, arrayOfNodeToDeleteAfter);
        }

        this.getSequencer().getHistoryManagement().addDeleteAllExceptSelectedNodesOperationLog(startSequencerNodeToKeep, endSequencerNodeToKeep, arrayOfNodeToDeleteBefore, deleteNodeBefore, deleteNodeAfter, arrayOfNodeToDeleteAfter);
    },


    undo : function(sequencerOperationDeleteAllExceptSelectedNodesLog ){
        this.revertReplacement(sequencerOperationDeleteAllExceptSelectedNodesLog.getDeleteNodeBefore());
        this.revertReplacement(sequencerOperationDeleteAllExceptSelectedNodesLog.getDeleteNodeAfter());
    },

    redo: function(sequencerOperationDeleteAllExceptSelectedNodesLog){
        this.replaceSequencerNodes(sequencerOperationDeleteAllExceptSelectedNodesLog.getDeleteNodeBefore(), sequencerOperationDeleteAllExceptSelectedNodesLog.getArrayOfNodeToDeleteBefore());
        this.replaceSequencerNodes(sequencerOperationDeleteAllExceptSelectedNodesLog.getDeleteNodeAfter(), sequencerOperationDeleteAllExceptSelectedNodesLog.getArrayOfNodeToDeleteAfter());
    },

});


export default DeleteAllExceptSelectedNodesOperation; 