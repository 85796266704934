import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import HomeMenuPanelView from "views/homeMenuPanel/HomeMenuPanelView";
import CordovaHelper from "models/helper/CordovaHelper";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import PlayerService from "services/PlayerService";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import GoogleAnalyticsEvents from "models/helper/GoogleAnalyticsEvents";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import LiveChatWidgetHelper from "models/helper/LiveChatWidgetHelper";
import SubscriptionManager from "models/helper/SubscriptionManager";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import ti18n from 'i18n!nls/HomeMenuPaneli18n';
import RSVP from "rsvp";

const Rollout = RolloutHelper.getInstance();
const DISPLAY_HOME_MENU  = Rollout.isFeatureEnabled(Rollout.FEATURES.ADD_MY_FEED_PAGE);
const ENABLE_TREBBLE_PLUS_SUB = Rollout.isFeatureEnabled(Rollout.FEATURES.ENABLE_TREBBLE_PLUS_SUB);
const ENABLE_JOURNEY_CREATION = Rollout.isFeatureEnabled(Rollout.FEATURES.ENABLE_JOURNEY_CREATION);
const HomeMenuPanelController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if (options) {

        }
        //LocalStorageHelper.getInstance().registerForUserInfoChange("HomeMenuPanelController",this.onUserInfoChanged.bind(this));
        this.listenTo(LocalStorageHelper.getInstance(),LocalStorageHelper.getInstance().eventTypes.USER_INFO_UPDATED, this.onUserInfoChanged.bind(this));
        this.listenTo(SubscriptionManager.getInstance(),SubscriptionManager.getInstance().eventTypes.ON_SUBSCRIPTION_UPDATED, this.onUserSubscriptionChanged.bind(this));
        const userInfoJson= LocalStorageHelper.getInstance().getUserInfo();
        this._userInfo =  userInfoJson?Utils.getInstance().getModelFromUserJson(userInfoJson):null;
        this._allNotificationRead =  true;
        this._unreadMessagesInHelpSection = false;
    },

    onUserInfoChanged :function(newUserInfoJson){
        this._userInfo = newUserInfoJson?Utils.getInstance().getModelFromUserJson(newUserInfoJson):null;
        if(this.view != null){
            this.view.updateUserInfo(this._userInfo);
        }
    },

    onUserSubscriptionChanged : function(productInfo){
        const showUpgradeBtnInFloatingMenu = Rollout.getFeatureVariable(Rollout.FEATURES.ENABLE_TREBBLE_PLUS_SUB, Rollout.FEATURES.ENABLE_TREBBLE_PLUS_SUB.variables.show_upgrade_btn_in_floating_menu, false);
        if(showUpgradeBtnInFloatingMenu && ENABLE_TREBBLE_PLUS_SUB && SubscriptionManager.getInstance().isPaidAccountSubscriptionOrderAvailable()){
            return SubscriptionManager.getInstance().isCurrentUserSubscribedToTrebblePlus(false, productInfo? true:false).then((function(isSubscribedToTrebblePlus){
                if(isSubscribedToTrebblePlus){
                    this.view.hideUpgradeBtn();
                }else{
                    this.view.showUpgradeBtn();
                }
            }).bind(this))
        }else{
            this.view.hideUpgradeBtn();	
        }
    },

    showGlobalSearch : function(){
        PersistentModels.getInstance().getRouter().showGlobalSearchPage(null, true);
    },

    showHelpAndFeedback : function(){
        PersistentModels.getInstance().getRouter().showHelpAndFeedback(null, true);
    },

    showExplorePage : function(){
        PersistentModels.getInstance().getRouter().showExplorePage(null, true);
    },

    showOnAirPage : function(){
        PersistentModels.getInstance().getRouter().showOnAirPage(true);
    },

    showMyLibrary : function(){
        PersistentModels.getInstance().getRouter().showMyLibraryPage(null,null, true);
    },

    showReferPage : function(){
        PersistentModels.getInstance().getRouter().showReferralPage(null, true);
    },

    showPlaylistPodcastPage : function(){
        PersistentModels.getInstance().getRouter().showPlaylistPodcasts( true);
    },

    showMySubscriptionsPage : function(){
        PersistentModels.getInstance().getRouter().showMySubscriptionsPage( true);
    },

    showMyJourneysPage : function(){
        PersistentModels.getInstance().getRouter().showMyJourneysPage( true);
    },

    showMyPersonalRadioDetailPage : function(){
        PersistentModels.getInstance().getRouter().showMyPersonalTrebbleDetailPage(true);
    },

    showHomePage : function(){
        PersistentModels.getInstance().getRouter().showNewHomePage( true);
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    showSettingsPage : function(){
        PersistentModels.getInstance().getRouter().showSettingsPage(true);
    },

    showfeedsAndNotificationPage : function(){
        PersistentModels.getInstance().getRouter().showFeedAndNotificationPage(true);
    },

    showCapsulesPage : function(){
        PersistentModels.getInstance().getRouter().showCapsulesPage(true);
    },

    showHelpPage : function(){
        PersistentModels.getInstance().getRouter().showHelpPage(true);
    },

    setAllNotificationRead : function(allNotificationRead){
        this._allNotificationRead =  allNotificationRead;
        this.view.setAllNotificationRead(this._allNotificationRead);
    },

    setUnreadMessagesInHelpSection : function(unreadMessagesInHelpSection){
        this._unreadMessagesInHelpSection =  unreadMessagesInHelpSection;
        this.view.setUnreadMessagesInHelpSection(this._unreadMessagesInHelpSection);
    },


    setHelpDisplayedAtLeastOnceToUser : function(displayOnceToUser){
        this.view.setHelpDisplayedAtLeastOnceToUser(displayOnceToUser);
    },



    showLoggedInUserPage  : function(){
        if(this._userInfo){
            PersistentModels.getInstance().getRouter().showUserProfilePageByUsername(null,this._userInfo.getUserId(), null, true );
        }else{
            TrebbleClientAPIHelper.getInstance().doLoginWithLoginPage().then((function(){
                if(this._userInfo){
                    PersistentModels.getInstance().getRouter().showUserProfilePageByUsername(null,this._userInfo.getUserId(), null, true );
                }
            }).bind(this));
        }
    },

    installChromeExtension : function(){
        PersistentModels.getInstance().getRouter().showChromeExtensionInstallHelperPopup();
    },

    installWPA : function(){
        if(window.installPromptEvent){
            window.installPromptEvent.prompt();
            // Wait for the user to respond to the prompt
            window.installPromptEvent.userChoice.then((function(choice){
                if (choice.outcome === 'accepted') {
                    this.view.hideInstallWPABtn();
                    if(window.trebbleAnalyticsHelper){
                        window.trebbleAnalyticsHelper.trackEvent(GoogleAnalyticsEvents.getInstance().CATEGORY.INSTALL_PROGRESSIVE_WEB_APP.name, GoogleAnalyticsEvents.getInstance().CATEGORY.INSTALL_PROGRESSIVE_WEB_APP.actions.ACCEPTED_PWA_INSTALL.name, GoogleAnalyticsEvents.getInstance().CATEGORY.INSTALL_PROGRESSIVE_WEB_APP.actions.ACCEPTED_PWA_INSTALL.label);
                    }

                } else {
                    this.view.showInstallWPABtn();
                    if(window.trebbleAnalyticsHelper){
                        window.trebbleAnalyticsHelper.trackEvent(GoogleAnalyticsEvents.getInstance().CATEGORY.INSTALL_PROGRESSIVE_WEB_APP.name, GoogleAnalyticsEvents.getInstance().CATEGORY.INSTALL_PROGRESSIVE_WEB_APP.actions.REJECTED_PWA_INSTALL.name, GoogleAnalyticsEvents.getInstance().CATEGORY.INSTALL_PROGRESSIVE_WEB_APP.actions.REJECTED_PWA_INSTALL.label);
                    }
                }
                // Clear the saved prompt since it can't be used again
                window.installPromptEvent = null;
            }).bind(this));
}
},

createMyOwnTrebble :function(){
if(window.trebble.userNeedToRequestAnInviteToSetupTrebble){
this.openRequesInvitePage();
}else{
PersistentModels.getInstance().getRouter().showTrebbleSetupPage(null, true);
}
},

createMyFirstJourney :function(){
return PersistentModels.getInstance().getRouter().showJourneyCreationWizardPage(true, (function(model, action, data){
if(action == "newJourneyInfo" && data){
    return TrebbleClientAPIHelper.getInstance().getUserProfileInfoByUsername(LocalStorageHelper.getInstance().getUserInfo().username).then((function(updatedUserInfo){
        LocalStorageHelper.getInstance().updateUserInfo(updatedUserInfo);
    }).bind(this))
}
}).bind(this));
},

openRequesInvitePage : function(){
const openedWindow = window.open("https://trebble.typeform.com/to/JXo8tX", "_blank");
if((!openedWindow || openedWindow.closed || typeof openedWindow.closed=='undefined') && !window.waitForCordovaToLoad){
window.alertErrorMessage(window.getI18n(ti18n, "PLEASE_ALLOW_POPUPS_FOR_TREBBLE"));
}else{
if(openedWindow.focus){
    openedWindow.focus();
}
}

},

onPanelClosed : function(){
PersistentModels.getInstance().getRouter().updateActivePageHeightToMatchPlayerFooterHeight();
},

onUnreadMessagesInHelpSectionChange : function(unreadMessagesCount){
if(unreadMessagesCount && unreadMessagesCount > 0){
this.view.setUnreadMessagesInHelpSection(true);
}else{
this.view.setUnreadMessagesInHelpSection(false);
}

},

showUpgradeToPaidSubscriptionPopup: function() {
PersistentModels.getInstance().getRouter().showUpgradeToPaidSubscriptionPopup();
},


buildModelAndRenderView : function() {

return new RSVP.Promise((function(resolve, reject) {
try {
    const params = {};
    params.userInfo = this._userInfo;
    //TODO: This is experimental and has only be added for the webby awards entry. This should be removed after.
    params.isUserAllowToSetupTrebble = this._userInfo? this._userInfo.getProfileType() != Utils.getInstance().getProfileTypes().CREATOR  && !window.waitForCordovaToLoad : false ;//&& LocalStorageHelper.getInstance()  && LocalStorageHelper.getInstance().isUserAllowToSetupATrebble ? LocalStorageHelper.getInstance().isUserAllowToSetupATrebble(): false;
    params.isUserAllowToCreateFirstJourney = this._userInfo? this._userInfo.getProfileType() == Utils.getInstance().getProfileTypes().LISTENER  && !window.waitForCordovaToLoad: false ;//&& LocalStorageHelper.getInstance()  && LocalStorageHelper.getInstance().isUserAllowToSetupATrebble ? LocalStorageHelper.getInstance().isUserAllowToSetupATrebble(): false;
    this.view = new HomeMenuPanelView(params);
    this.view.render();
    this.listenTo(this.view,"showGlobalSearch",this.showGlobalSearch);
    this.listenTo(this.view,"showHelpAndFeedback",this.showHelpAndFeedback);
    this.listenTo(this.view,"showOnAirPage",this.showOnAirPage);
    this.listenTo(this.view,"showLibraryPage",this.showMyLibrary);
    this.listenTo(this.view,"showReferPage",this.showReferPage);
    this.listenTo(this.view,"showTrebblesPage",this.showMyPersonalRadioDetailPage);
    this.listenTo(this.view,"showSettingsPage",this.showSettingsPage);
    this.listenTo(this.view,"showfeedsAndNotificationPage",this.showfeedsAndNotificationPage);
    this.listenTo(this.view,"showLoggedInUserPage",this.showLoggedInUserPage);
    this.listenTo(this.view,"showHomePage",this.showHomePage);
    this.listenTo(this.view,"showExplorePage", this.showExplorePage);
    this.listenTo(this.view,"showCapsulesPage", this.showCapsulesPage);
    this.listenTo(this.view,"showMySubscriptionsPage", this.showMySubscriptionsPage);
    this.listenTo(this.view,"showMyJourneysPage", this.showMyJourneysPage);
    this.listenTo(this.view,"showHelpPage", this.showHelpPage);
    this.listenTo(this.view, "installChromeExtension", this.installChromeExtension);
    this.listenTo(this.view, "installWPA", this.installWPA);
    this.listenTo(this.view, "showUpgradeToPaidSubscriptionPopup", this.showUpgradeToPaidSubscriptionPopup);
    
    this.listenTo(this.view, "createMyOwnTrebble", this.createMyOwnTrebble);
    this.listenTo(this.view, "createMyFirstJourney", this.createMyFirstJourney);
    
    this.listenTo(this.view, "panelClosed", this.onPanelClosed);
    if(!DISPLAY_HOME_MENU){
        this.view.hideHomeMenu();
    }
    if(!(ENABLE_JOURNEY_CREATION || LocalStorageHelper.getInstance().isLoggedInUserAJourneyCreator())){
        this.view.hideMyJourneysBtn()
    }
    this.view.setHelpDisplayedAtLeastOnceToUser(LocalStorageHelper.getInstance().isHelpForBloggerDisplayedKey());
    if((!Rollout.isFeatureEnabled(Rollout.FEATURES.ENABLE_INTERCOM_HELP, true)  && !Rollout.isFeatureEnabled(Rollout.FEATURES.USE_ZENDESK_FOR_LIVE_CHAT, true))   || !LiveChatWidgetHelper.getInstance().isAvailable()){
        this.view.hideHelpAndFeedbackMenuBtn();
    }else{
        LiveChatWidgetHelper.getInstance().getUnreadConversationCount().then((function(unreadCount){
            this.onUnreadMessagesInHelpSectionChange(unreadCount);
        }).bind(this))
        this.listenTo(LiveChatWidgetHelper.getInstance(),"unreadMessagesCountChange", this.onUnreadMessagesInHelpSectionChange);
    }
    this.onUserSubscriptionChanged();

    //if(!LocalStorageHelper.getInstance().isUserAllowedUserReferral() || !Utils.getInstance().canReferralLinkBeGenerated()){
        if(!Rollout.isFeatureEnabled(Rollout.FEATURES.USER_REFERRAL) || !Utils.getInstance().canReferralLinkBeGenerated()){
            this.view.hideReferUserMenuBtn();
        }else{

            //Refer menu will be enabled
            //const timeLimit = LocalStorageHelper.getInstance().getUserRefferalTimeStringLimit();

            /*const timeLimit =  Rollout.getFeatureVariable(Rollout.FEATURES.USER_REFERRAL, Rollout.FEATURES.USER_REFERRAL.variables.referral_program_time_limit_string);
            const timeLimitDate =  timeLimit? new Date(): null;
            if(!timeLimitDate && moment(new Date()).isAfter(timeLimitDate)){
                this.view.hideReferUserMenuBtn();
            }*/
        }

        resolve();
    } catch (error) {
        reject(error);
    }

}).bind(this));

}

});

export default HomeMenuPanelController;