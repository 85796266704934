import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Song from "models/Song";
import RSVP from "rsvp";

var playlistTypes  =  {"FIXED" : "fixed", "DYNAMIC": "dynamic","AUTOFIXED":"autoFixed"};
var playlistContexts =  {"GLOBAL_SEARCH" : "globalSearch", "MY_LIBRARY_SEARCH": "myLibrarySearch","LOCAL_AUTO_PLAYLIST":"localAutoPlaylist","RADIO": "radio"};
var Playlist =  Backbone.Collection.extend({
    model: Song,
    idAttribute: "id",

    initialize: function (){

    },
    
    getSongAtPosition : function(position){
        
    },
    
    getSize : function(){
        
    },
    
    getType : function(){
        
    },
    
    getName : function(){
        
    },
    
    setName : function(newName){
        
    },
    
    getLoaderKey : function(){
        
    },
    
    getCreator : function(){
        
    },
    
    getSeedData : function(){
        
    },
    
    setSeedData : function(seedData){
        
    },
    
    getCreationDate : function(){
        
    },
    
    getLastModificationDate : function(){
        
    },
    
    getDescription : function(){
        
    },
    
    setDescription : function(newDescription){
        
    },
    
    addSongs : function(){
        
    },
    
    removeSongsWithUri : function(){
        
    },
    
    getAllLoadedSongs : function(){
        
    },
    
    shuffleOn : function(){
        
    },
    
    shuffleOff : function(){
        
    },
    
    getAvailableTagGroupFilters : function(){
        
    },
    
    getSelectedTagFilters : function(){
        
    },
    
    setSelectedTagFilter : function(tagFilter , selected){
        
    },
    
    getContext : function(){
        
    },
    
    updateContext : function(newContext){
        
    },
    
    
    tune : function(tuneOptions){
        
    },
    
    canRetrieveAllAvailableSongsInJson : function(){
        
    },
    
    retrieveAllAvailableSongsInJson : function(){
        
    },

    
    isAudioPreviewAvailable: function(){
        
    },
    
    getAudioPreviewUrl : function(){
        
    },

    getCategoryId : function(){
        
    },

    getCityId : function(){
        
    },
    
    getGenreIdToAudioPreviewUrl : function(){
        
    },
    
    getAudioPreviewUrlByGenreId : function(genreId){
        
    },
    
    getCoverArtUrl : function(){

    },
    
    
    
    setCoverArtUrl : function(newCoverArtUrl){

    },
    
    getCoverArtUrlBlur : function(){

    },
    

    setCoverArtUrlBlur : function(newCoverArtUrlBlur){

    },
    
    getCoverArtUrlInfo : function(){

    },
    
    
    setCoverArtUrlInfo : function(newCoverArtUrlInfo){

    },
    
    getCoverArtUrlBlurInfo : function(){

    },
    

    setCoverArtUrlBlurInfo : function(newCoverArtUrlBlurInfo){

    },
    
    getCoverArtBase64 : function(){

    },
    
    setCoverArtBase64 : function(newCoverArtBase64){

    },
    
    isFollowing : function(){
        
    },
    
    setFollowing : function(following){
        
    },
    
    canBeFollowed : function(){
        
    },
    
    
    canBeEdited : function(){
        
    },
    
    getNumberOfFollowers : function(){
        
    },
    
    getNumberOfSongs : function(){
        
    },
    
    getTotalNumberOfPlays : function(){
        
    },
    
    getOwnerNumberOfPlays : function(){
        
    },
    
    getPlaySuccessRate : function(){
        
    },
    
    getNumberOfSongsFavorited : function(){
        
    },
    
    getNumberOfCapsules : function(){
        
    },
    
    
    setPlaying : function(isPlaying){
        
    },
    
    isPlaying : function(isPlaying){
        
    },
    
    
    addFollower : function(){
        
    },
    
    removeFollower : function(){

    },
    
    _registernToChangeEvents : function(modelToListenTo, propertyName){
        if(modelToListenTo && modelToListenTo.on){
            modelToListenTo.on("change:"+propertyName, (function(){
                this.self["_"+this.propertyName] = this.modelToListenTo.get(this.propertyName);
            }).bind({"self":this, "modelToListenTo": modelToListenTo, "propertyName": propertyName}))
        }
    },
    
    
});
Playlist.types = playlistTypes;
export default Playlist;