import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import owlcarousel from "owlcarousel";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import ti18n from "i18n!nls/JourneyCreationWizardi18n";
import pageTemplate from 'text!../../../templates/journeyCreation/JourneyCreationWizardPageTemplate.html';
import RSVP from 'rsvp';
import Mousetrap from 'mousetrap';

const Rollout = RolloutHelper.getInstance();
const ALLOW_USER_TO_CREATE_PAID_JOURNEYS = Rollout.isFeatureEnabled(Rollout.FEATURES.ALLOW_USER_TO_CREATE_PAID_JOURNEYS, true);

const ACCESS_CODE_REQUIRED = true;
const DISABLE_PASSWORD_CONFIRM = true;
const FORM_VALIDATION_DELAY_IN_MILLISEC = 1000;
const PROFILE_TYPE = {"LISTENER":"listener","CREATOR": "creator"};
const SLIDE_INDEXES ={
    "WELCOME_MESSAGE" : 0,
    "CHOOSE_PROFILE_TYPE" : 1,
    "CHOOSE_JOURNEY_NAME" : 2,
    "CHOOSE_JOURNEY_GOAL" : 3,
    "CHOOSE_TREBBLE_DESCRIPTION" : 4,
    "CHOOSE_TREBBLE_CATEGORY" : 5,
    "CHOOSE_TREBBLE_LANGUAGE" : 6,
    //"WANT_OFFER_IT_FREE_OR_PAID" : 7,
    "HOW_MUCH" : 7,
    "FINISH_USER_SIGNUP" : 8
}

const JourneyCreationWizardPageView = Backbone.View.extend({

    template : _.template(pageTemplate),

    initialize : function(options) {
        this._removeOnHide = true;
        this._accessCode = options? options.accessCode: null;
        this._citySelectFieldView =  options.citySelectFieldView;
        if(this._accessCode == "undefined"){
            this._accessCode = null;
        }
        this._hideBackButton = options? options.hideBackButton : false;
        this._mousetrapObj = null;
        this._mousetrapOnTrebbleNameObj = null;
        this._mousetrapOnTrebbleDescObj = null;
        this._enabledColor = "#e9605d";
        this._disabledColor = "#cccccc";
        this._displayWelcomeMessage = false;
        this._trebbleCategoryInitialized = false;
        this._trebbleLanguageInitialized = false;
    },

    events : {
        "click #header-back-button" : "navigateToPreviousPage",
        "click #nextButton:not(.trebble_disabled)" : "onNextButtonClicked",
        "click #previousButton:not(.trebble_disabled)" : "onPreviousButtonClicked",
        "click #iAmACuratorBtn" :"onIAmACuratorBtnClicked",
        "click #saveButtonIcon:not(.trebble_disabled)": "onFinishButtonClicked"

    },

    navigateToPreviousPage : function(){
        /*if(PersistentModels && PersistentModels.getInstance().getRouter()  ){
            PersistentModels.getInstance().getRouter().navigateToPreviousPage();
        }else{*/
            window.history.back();  
            //}

        },

        onIAmACuratorBtnClicked : function(){
            if(this.carousel$el && this.carousel$el.data('owlCarousel')){
                const carouselData = this.carousel$el.data('owlCarousel');
                //Go to trebble Name Slide
                carouselData.goTo(SLIDE_INDEXES.CHOOSE_JOURNEY_NAME);

            }
        },

        onFinishButtonClicked : function(){

            const data = {};
            
            data.trebbleName = this.trebbleNameField$el.val();
            data.journeyGoal = this.journeyGoalField$el.val();
            data.trebbleDescription = this.trebbleDescriptionField$el.val();
            data.trebbleCategoryId = this.trebbleCategoryField$el.val();
            data.trebbleCityId = this._citySelectFieldView.getSelectedId();
            data.trebbleTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
            data.trebbleLanguage = this.getTrebbleLanguageCodeValue();
            data.isPaid = !this.isJourneyCostFree();
            if(data.isPaid){
                data.costInUSD = this.getJourneyCostInUSD();
            }
            this.trigger("onFinishButtonClicked", data);
        },

        
        getTrebbleLanguageCodeValue : function(){
            let languageCode = this.trebbleLanguageField$el.val();
            if(!languageCode){
                languageCode = "en";
            }
            return languageCode;
        },


        onBeforeRemoveFromPage : function(){
            this.trigger("beforeRemoveFromPage", this);
            if(this._mousetrapObj){
                this._mousetrapObj.unbind("enter");
            }
            if(this._mousetrapOnTrebbleDescObj){
                this._mousetrapOnTrebbleDescObj.unbind("tab");
            }
            if(this._mousetrapOnTrebbleNameObj){
                this._mousetrapOnTrebbleNameObj.unbind("tab");
            }

        },

        onRemovedFromPage : function(){
            this.undelegateEvents();
            window.removeEventListener('resize', window[this._onViewportHeightChangedFunctionRef] );
            this.$el.removeData().unbind(); 
            this.trigger("removedFromPage", this);
        },

        

        _onViewportHeightChanged : function(){
            const headerHeight = 54 ;
            this.$el.find(".ui-content").css("height",(window.getDocumentClienHeight() -headerHeight)+ "px");
        },

        onTrebbleNameChanged : function(){
            this.disableNextButton(true);
            this.trigger("trebbleNameChanged");
            if(this._validationTrebbleNameTriggerFunctionTimeout){
                clearTimeout(this._validationTrebbleNameTriggerFunctionTimeout);
            }
            this._validationTrebbleNameTriggerFunctionTimeout = setTimeout((function(){
                this.setTrebbleNameBeingValidate();
            }).bind(this), FORM_VALIDATION_DELAY_IN_MILLISEC);

        },

        onJourneyGoalChanged  : function(){
            this.disableNextButton(true);
            const journeyGoal = this.journeyGoalField$el.val();
            if(!journeyGoal){
                //empty field
                this.journeyGoalWrapper$el.removeClass("successValidation");
                this.journeyGoalWrapper$el.removeClass("failValidation");
                this.journeyGoalWrapper$el.removeClass("showWaitMessage");
                this._journeyGoalValid  = false;
            }else{
                this.trigger("validateJourneyGoal", journeyGoal);
            }

        },
        onTrebbleDescriptionChanged  : function(){
            this.disableNextButton(true);
            const trebbleDescription = this.trebbleDescriptionField$el.val();
            if(!trebbleDescription){
                //empty field
                this.trebbleDescriptionfoWrapper$el.removeClass("successValidation");
                this.trebbleDescriptionfoWrapper$el.removeClass("failValidation");
                this.trebbleDescriptionfoWrapper$el.removeClass("showWaitMessage");
                this._trebbleDescriptionValid  = false;
            }else{
                this.trigger("validateJourneyDescription", this.trebbleDescriptionField$el.val());
            }

        },

        onTrebbleCategoryChanged  : function(){
            const trebbleCategory = this.trebbleCategoryField$el.val();
            this.hideShowCityFieldIfApplicable();
            if(!trebbleCategory){
                this._trebbleCategoryValid  = false;
                this.disableNextButton(true);
            }else{
                this._trebbleCategoryValid  = true;
                if(trebbleCategory == Utils.getInstance().getLocalCategoryId()){
                    if(this._citySelectFieldView.getSelectedId()){
                        this.disableNextButton(false);
                    }else{
                        this.disableNextButton(true);
                    }
                }else{
                    this.disableNextButton(false);
                }
            }
        },

        isJourneyCostFree : function(){
            return this.isJourneyFreeField$el.val() == "true";
        },

        getJourneyCostInUSD : function(){
            return this.journeyCostField$el.val();
        },

        /*onJourneyFreeOrPaidChanged  : function(){
            const isJourneyFree = this.isJourneyCostFree();
            if(!isJourneyFree){
                this.disableNextButton(false);
                this.nextButton$el.show();
                this.disableSaveButton(true);
                this.saveButton$el.hide();
            }else{
                this.disableNextButton(true);
                this.nextButton$el.hide();
                this.disableSaveButton(false);
                this.saveButton$el.show();
            }
        },*/

        onJourneyCostChanged  : function(){
            const isJourneyFree = this.isJourneyCostFree();
            this.hideShowPriceFieldIfApplicable();
            if(isJourneyFree){
                this.disableNextButton(true);
                this.nextButton$el.hide();
                this.disableSaveButton(false);
                this.saveButton$el.show();
            }else{
                const journeyCost = this.getJourneyCostInUSD();
                if(!journeyCost){
                    this._journeyCostValid  = false;
                    this.disableNextButton(true);
                    this.disableSaveButton(true);
                    this.nextButton$el.hide();
                    this.saveButton$el.show();
                }else{
                    this._journeyCostValid  = true;
                    this.disableNextButton(true);
                    this.disableSaveButton(false);
                    this.nextButton$el.hide();
                    this.saveButton$el.show();
                }
            }
        },

        hideShowPriceFieldIfApplicable : function(){
            const isJourneyFree = this.isJourneyCostFree();
            if(!isJourneyFree){
                this.$el.find("#journeyCostField-button").show();
            }else{
                this.$el.find("#journeyCostField-button").hide();
            }

        },

        onJourneyPriceChanged  : function(){
            const journeyCost = this.getJourneyCostInUSD();
            if(!journeyCost){
                this._journeyCostValid  = false;
                this.disableNextButton(true);
                this.disableSaveButton(true);
                this.nextButton$el.hide();
                this.saveButton$el.show();
            }else{
                this._journeyCostValid  = true;
                this.disableNextButton(true);
                this.disableSaveButton(false);
                this.nextButton$el.hide();
                this.saveButton$el.show();
            }
        },

        hideShowCityFieldIfApplicable : function(){
            const trebbleCategory = this.trebbleCategoryField$el.val();
            if(trebbleCategory == Utils.getInstance().getLocalCategoryId()){
                this._citySelectFieldView.$el.show();
            }else{
                this._citySelectFieldView.$el.hide();
            }

        },

        onTrebbleLanguageChanged  : function(){
            const trebbleLanguage = this.trebbleLanguageField$el.val();
            if(!trebbleLanguage){
                this._trebbleLanguageValid  = false;
                this.disableSaveButton(true);
                this.disableNextButton(true);
                if(ALLOW_USER_TO_CREATE_PAID_JOURNEYS){
                    this.saveButton$el.hide();
                    this.nextButton$el.show();
                }else{
                    this.nextButton$el.hide();
                    this.saveButton$el.show();
                }
            }else{
                this._trebbleLanguageValid  = true;
                this.disableSaveButton(false);
                this.disableNextButton(false);
                if(ALLOW_USER_TO_CREATE_PAID_JOURNEYS){
                    this.saveButton$el.hide();
                    this.nextButton$el.show();
                }else{
                    this.nextButton$el.hide();
                    this.saveButton$el.show();
                }
            }
        },

        setTrebbleNameBeingValidate : function(){
            this.disableNextButton(true);
            const trebbleName = this.trebbleNameField$el.val();
            if(!trebbleName){
                //empty field
                this.trebbleNameInfoWrapper$el.removeClass("successValidation");
                this.trebbleNameInfoWrapper$el.removeClass("failValidation");
                this.trebbleNameInfoWrapper$el.removeClass("showWaitMessage");
                this._trebbleNameValid  = false;
            }else{
                this.trebbleNameInfoWrapper$el.removeClass("successValidation");
                this.trebbleNameInfoWrapper$el.removeClass("failValidation");
                this.trebbleNameInfoWrapper$el.addClass("showWaitMessage");
                this.trigger("validateJourneyName", this.trebbleNameField$el.val());
            }
        },

        setTrebbleNameValid : function(isValid, errorMessage){
            this._trebbleNameValid = isValid;
            if(!errorMessage){
                errorMessage = window.getI18n(ti18n, "TREBBLE_NAME_VALIDATION_ERROR");
            }
            this.trebbleNameInfoWrapper$el.removeClass("showWaitMessage");
            if(this._trebbleNameValid){
                this.trebbleNameInfoWrapper$el.removeClass("failValidation");
                this.trebbleNameInfoWrapper$el.addClass("successValidation");
                this.disableNextButton(false);
            }else{
                this.trebbleNameInfoWrapper$el.removeClass("successValidation");
                this.trebbleNameInfoWrapper$el.find(".failValidationMessageBox .label").html(errorMessage);
                this.trebbleNameInfoWrapper$el.addClass("failValidation");
                this.disableNextButton(true);
            }

        },


        setTrebbleDescriptionValid : function(isValid, errorMessage){
            this._trebbleDescriptionValid = isValid;
            if(!errorMessage){
                errorMessage = window.getI18n(ti18n, "SORRY_BUT_TREBBLE_DESCRIPTION_IS_BETWEEN_X_CHAR");
            }
            this.trebbleDescriptionfoWrapper$el.removeClass("showWaitMessage");
            if(this._trebbleDescriptionValid){
                this.trebbleDescriptionfoWrapper$el.removeClass("failValidation");
                this.trebbleDescriptionfoWrapper$el.addClass("successValidation");
                this.disableNextButton(false);
            }else{
                this.trebbleDescriptionfoWrapper$el.removeClass("successValidation");
                this.trebbleDescriptionfoWrapper$el.find(".failValidationMessageBox .label").html(errorMessage);
                this.trebbleDescriptionfoWrapper$el.addClass("failValidation");
                this.disableNextButton(true);

            }

        },

        setJourneyGoalValid : function(isValid, errorMessage){
            this._journeyGoalValid = isValid;
            if(!errorMessage){
                errorMessage = window.getI18n(ti18n, "SORRY_BUT_JOURNEY_GOAL_IS_BETWEEN_X_CHAR");
            }
            this.journeyGoalWrapper$el.removeClass("showWaitMessage");
            if(this._journeyGoalValid){
                this.journeyGoalWrapper$el.removeClass("failValidation");
                this.journeyGoalWrapper$el.addClass("successValidation");
                this.disableNextButton(false);
            }else{
                this.journeyGoalWrapper$el.removeClass("successValidation");
                this.journeyGoalWrapper$el.find(".failValidationMessageBox .label").html(errorMessage);
                this.journeyGoalWrapper$el.addClass("failValidation");
                this.disableNextButton(true);

            }

        },


        _onPageShow : function(){
            this.afterRendering();
            if(this._hideBackButton){

                this.$el.find("#header-back-button").hide();
            }


        },

        _updateHeaderButtonsVisibility : function(){

            if(this.carousel$el && this.carousel$el.data('owlCarousel')){

                const carouselData = this.carousel$el.data('owlCarousel');

                if(carouselData.currentItem +1 > carouselData.maximumItem){
                    this.nextButton$el.hide();
                    this.saveButton$el.show();
                }else{
                    this.nextButton$el.show();
                    this.saveButton$el.hide();
                }
                if(carouselData.currentItem > 0){
                    this.previousButton$el.show();
                    this.hideBackButton();
                }else{
                    this.previousButton$el.hide();
                    this.showBackButton();
                }

                this._carouselPosition = carouselData.currentItem;
                if(this._carouselPosition === SLIDE_INDEXES.WELCOME_MESSAGE || this._carouselPosition === SLIDE_INDEXES.CHOOSE_PROFILE_TYPE){
                    //user profile type choice slide is displayed or welcome Page is displayed
                    this.disableNextButton(true);
                    this.previousButton$el.hide();
                    this.nextButton$el.hide();
                    this.showBackButton();

                }
                if(this._carouselPosition === SLIDE_INDEXES.CHOOSE_JOURNEY_NAME){
                    //Trebble Name Field is being displayed
                    this.disableNextButton(true);
                    this.nextButton$el.show();

                    //We are assuming the user has the curator profile
                    this.previousButton$el.hide();
                    this.showBackButton();

                    return this.setTrebbleNameBeingValidate();

                }
                if(this._carouselPosition === SLIDE_INDEXES.CHOOSE_JOURNEY_GOAL){
                    //Trebble Description Field is being displayed
                    this.disableNextButton(true);
                    this.nextButton$el.show();
                    this.previousButton$el.show();
                    this.hideBackButton();
                    return this.onJourneyGoalChanged();

                }
                if(this._carouselPosition === SLIDE_INDEXES.CHOOSE_TREBBLE_DESCRIPTION){
                    //Trebble Description Field is being displayed
                    this.disableNextButton(true);
                    this.nextButton$el.show();
                    this.previousButton$el.show();
                    this.hideBackButton();
                    return this.onTrebbleDescriptionChanged();

                }
                if(this._carouselPosition === SLIDE_INDEXES.CHOOSE_TREBBLE_CATEGORY){
                    //Trebble category Field is being displayed
                    this.disableNextButton(true);
                    this.nextButton$el.show();
                    this.previousButton$el.show();
                    this.hideBackButton();
                    return this.onTrebbleCategoryChanged();

                }
                if(this._carouselPosition === SLIDE_INDEXES.CHOOSE_TREBBLE_LANGUAGE){
                    //Trebble language Field is being displayed
                    this.disableNextButton(true);
                    this.nextButton$el.hide();
                    this.previousButton$el.show();
                    this.hideBackButton();
                    return this.onTrebbleLanguageChanged();

                }

                /*if(this._carouselPosition === SLIDE_INDEXES.WANT_OFFER_IT_FREE_OR_PAID){
                    //Trebble journey free or paid Field is being displayed
                    this.disableNextButton(true);
                    this.nextButton$el.hide();
                    this.previousButton$el.show();
                    this.hideBackButton();
                    return this.onJourneyFreeOrPaidChanged();

                }*/


                if(this._carouselPosition === SLIDE_INDEXES.HOW_MUCH){
                    //Trebble journey free or paid Field is being displayed
                    this.disableNextButton(true);
                    this.nextButton$el.hide();
                    this.previousButton$el.show();
                    this.hideBackButton();
                    return this.onJourneyPriceChanged();

                }

            }
        },

        hideBackButton : function(){
            this.headerBackButton$el.hide();
        },

        showBackButton : function(){
            this.headerBackButton$el.show();
        },



        onNextButtonClicked: function() {
            if(this.carousel$el && this.carousel$el.data('owlCarousel')){
                const carouselData = this.carousel$el.data('owlCarousel');
                if(carouselData.currentItem + 1 <= carouselData.maximumItem){
                    carouselData.next();
                }
            }
        },

        _initializeCarousels: function() {


            this.carousel$el = this.$el.find(".carouselWrapper");
            this.carousel$el.owlCarousel({

                navigation: false, // Show next and prev buttons
                pagination: false,
                slideSpeed: 300,
                paginationSpeed: 400,
                afterAction: this._onCarouselMove.bind(this),
                touchDrag: false,
                mouseDrag: false,
                singleItem: true
            });
            const carouselData = this.carousel$el.data('owlCarousel');
            this._numberOfCarouselItems = 4;
            this._currentCarouselPositon = 0;

            if(this._displayWelcomeMessage){
                carouselData.goTo(SLIDE_INDEXES.WELCOME_MESSAGE);
            }else{

                //jump to the third slide (Choose your trebble Name)
                carouselData.goTo(SLIDE_INDEXES.CHOOSE_JOURNEY_NAME);

            }

            this._onCarouselMove();


        },


        _onCarouselMove: function() {
            this._updateHeaderButtonsVisibility();
        },



        onPreviousButtonClicked: function() {
            if(this.carousel$el && this.carousel$el.data('owlCarousel')){

                const carouselData = this.carousel$el.data('owlCarousel');
                if(!this._allowUserToChooseProfileType && carouselData.currentItem === SLIDE_INDEXES.CHOOSE_JOURNEY_NAME){
                    //user is at the trebble name slide
                    //Do not allow user to go back to first screen
                    return;
                }
                if(this._allowUserToChooseProfileType && carouselData.currentItem === SLIDE_INDEXES.FINISH_USER_SIGNUP){
                    //user is at the signup form
                    //go back to first slide

                    carouselData.goTo(SLIDE_INDEXES.CHOOSE_PROFILE_TYPE);
                    this.onboardingSignupFormWrapper$el.removeClass("listenerOnboardinOnly");
                    return;


                }
                carouselData.prev();
            }

        },

        _initializeCityField : function(){
            this.$el.find(".categoryWrapper").append(this._citySelectFieldView.$el);
            this.hideShowCityFieldIfApplicable();

        },

        afterRendering: function() {
            this._initializeTrebbleCategoryField();
            this._initializeTrebbleLanguageField();
            this._initializeCityField();
            this._initializeCarousels();
            this._initializeFreeOrPaidField();
            this._initializeJourneyCostField();
            this.disableNextButton(true);

        },

        disableNextButton: function(forceDisable) {
            this._nextButtonEnable = false;
            if (!forceDisable) {
                this._nextButtonEnable = true;
                this.nextButton$el.removeClass("trebble_disabled");
            } else {
                this._nextButtonEnable = false;
                if(!this.nextButton$el.hasClass("trebble_disabled")){
                    this.nextButton$el.addClass("trebble_disabled");
                }
            }

        },

        disableSaveButton: function(forceDisable) {
            this._saveButtonEnable = false;
            if (!forceDisable) {
                this._saveButtonEnable = true;
                this.saveButton$el.removeClass("trebble_disabled");
            } else {
                this._saveButtonEnable = false;
                if(!this.saveButton$el.hasClass("trebble_disabled")){
                    this.saveButton$el.addClass("trebble_disabled");
                }
            }

        },

        disablePreviousButton: function(forceDisable) {
            this._previousButtonEnable = false;
            if (!forceDisable) {
                this._previousButtonEnable = true;
                this.previousButton$el.removeClass("trebble_disabled");
            } else {
                this._previousButtonEnable = false;
                if(!this.previousButton$el.hasClass("trebble_disabled")){
                    this.previousButton$el.addClass("trebble_disabled");
                }
            }

        },

        _initializeTrebbleCategoryField : function(){
            if(!this._trebbleCategoryInitialized){
                const categoryListItemArray  = Utils.getInstance().getCapsuleCategoryListItemArray();
                const categorySelect$el  = this.$el.find("#trebbleCategoryField");
                categoryListItemArray.map(function(listItem, index){
                    categorySelect$el.append("<option value='"+listItem.getId()+"'>"+ listItem.getLabel()+"</option>");
                });
                categorySelect$el.after('<i class="ion-chevron-down" style="display: inline-block;"></i>');
                categorySelect$el.selectmenu('refresh');
                this._trebbleCategoryInitialized = true;
            }
        },

        _initializeFreeOrPaidField : function(){
            if(!this._isJourneyFreeInitialized){
                const isJourneyFreeField$el  = this.$el.find("#isJourneyFreeField");
                isJourneyFreeField$el.append("<option value='true'>"+ window.getI18n(ti18n, "FREE") +"</option>");
                isJourneyFreeField$el.append("<option value='false'>"+ window.getI18n(ti18n, "PAID") +"</option>");
                isJourneyFreeField$el.after('<i class="ion-chevron-down" style="display: inline-block;"></i>');
                isJourneyFreeField$el.selectmenu('refresh');
                this._isJourneyFreeInitialized = true;
            }
        },

        _initializeJourneyCostField : function(){
            if(!this._isJourneyCostInitialized){
                const journeyCostField$el  = this.$el.find("#journeyCostField");
                const journeyCostInUSDListItemArray  = Utils.getInstance().getPossibleJourneyCostInUSDListItemArray();
                journeyCostInUSDListItemArray.map(function(listItem, index){
                    journeyCostField$el.append("<option value='"+listItem.getId()+"'>"+ listItem.getLabel()+"</option>");
                });
                journeyCostField$el.after('<i class="ion-chevron-down" style="display: inline-block;"></i>');
                journeyCostField$el.selectmenu('refresh');
                this._isJourneyCostInitialized = true;
                this.hideShowPriceFieldIfApplicable();
            }
        },


        _initializeTrebbleLanguageField : function(){
            if(!this._trebbleLanguageInitialized){
                const languageListItemArray  = Utils.getInstance().getLanguageListItemArray();
                const languageSelect$el  = this.$el.find("#trebbleLanguageField");
                const defaultLanguageCode =  Utils.getInstance().isBrowserLanguageAvailableInLanguageList()? navigator.language: Utils.getInstance().getDefaultLanguageCode();
                languageListItemArray.map(function(listItem, index){
                    if(defaultLanguageCode == listItem.getId()){
                        languageSelect$el.append("<option selected=\"selected\" value='"+listItem.getId()+"'>"+ listItem.getLabel()+"</option>");
                    }else{
                        languageSelect$el.append("<option value='"+listItem.getId()+"'>"+ listItem.getLabel()+"</option>");
                    }
                });
                languageSelect$el.after('<i class="ion-chevron-down" style="display: inline-block;"></i>');
                languageSelect$el.selectmenu('refresh');
                this._trebbleLanguageInitialized = true;
            }
        },


        
        render : function(){
            const params ={};
            params.displayWelcomeMessage = this._displayWelcomeMessage;
            params.ti18n = ti18n;
            
            this.$el.html(this.template(params));
            this.setElement(this.$el.find("div").first());

            this.$el.page();
            this._carouselPosition = 0;
            this._trebbleNameValid = false;
            this._trebbleDescriptionValid = false;
            this._trebbleCategoryValid = false;
            this._trebbleLanguageValid = true;
            this._journeyCostValid = false;

            const functionUniqueGlobalName = "onViewportHeightChangeForSignupPage"+ (new Date()).getTime();
            window[functionUniqueGlobalName] =  this._onViewportHeightChanged.bind(this)
            this._onViewportHeightChangedFunctionRef = functionUniqueGlobalName;
            window.addEventListener('resize', window[functionUniqueGlobalName]  );
            this.onboardingSignupFormWrapper$el = this.$el.find(".onboardingSignupFormWrapper");
            this.headerBackButton$el  = this.$el.find("#header-back-button");
            this.trebbleNameField$el = this.$el.find("#trebbleNameField");
            this.trebbleNameField$el.on(" keyup input", this.onTrebbleNameChanged.bind(this));
            this.journeyGoalField$el = this.$el.find("#journeyGoalField");
            this.journeyGoalField$el.on(" keyup input", this.onJourneyGoalChanged.bind(this));
            this.trebbleDescriptionField$el = this.$el.find("#trebbleDescriptionField");
            this.trebbleDescriptionField$el.on(" keyup input", this.onTrebbleDescriptionChanged.bind(this));
            this.nextButton$el = this.$el.find("#nextButton");
            this.previousButton$el = this.$el.find("#previousButton");
            this.saveButton$el = this.$el.find("#saveButtonIcon");
            this.trebbleNameInfoWrapper$el = this.$el.find(".trebbleNameInfoWrapper");
            this.journeyGoalWrapper$el = this.$el.find(".journeyGoalWrapper");
            this.trebbleDescriptionfoWrapper$el = this.$el.find(".trebbleDescriptionfoWrapper");
            this.trebbleCategoryField$el = this.$el.find("#trebbleCategoryField");
            this.trebbleLanguageField$el = this.$el.find("#trebbleLanguageField");
            this.isJourneyFreeField$el = this.$el.find("#isJourneyFreeField");
            this.journeyCostField$el = this.$el.find("#journeyCostField");

            this.trebbleCategoryField$el.change(this.onTrebbleCategoryChanged.bind(this));
            this.trebbleLanguageField$el.change(this.onTrebbleLanguageChanged.bind(this));
            this.isJourneyFreeField$el.change(this.onJourneyCostChanged.bind(this));
            this.journeyCostField$el.change(this.onJourneyCostChanged.bind(this));

            this.$el.on("pagehide", this._onPageHide.bind(this));
            this.$el.on("pageshow", this._onPageShow.bind(this));
            this._mousetrapObj = Mousetrap(this.$el.find("#SignUpForm").get(0)).bind('enter', (function(e, combo) {
                if(this.signUpBtn$el.hasClass("enabled")){
                    this.onSignUpBtnTapped();
                }
            }).bind(this));
            
            

            this._mousetrapOnTrebbleNameObj = Mousetrap(this.$el.find("#trebbleNameField").get(0)).bind('tab', (function(e, combo) {
                e.preventDefault();
            }).bind(this));
            this._mousetrapOnTrebbleDescObj = Mousetrap(this.$el.find("#trebbleDescriptionField").get(0)).bind('tab', (function(e, combo) {
                e.preventDefault();
            }).bind(this));
            this._mousetrapOnTrebbleDescObj = Mousetrap(this.$el.find("#trebbleCategoryField").get(0)).bind('tab', (function(e, combo) {
                e.preventDefault();
            }).bind(this));
            this._mousetrapOnTrebbleDescObj = Mousetrap(this.$el.find("#trebbleLanguageField").get(0)).bind('tab', (function(e, combo) {
                e.preventDefault();
            }).bind(this));

            
            //Fill in accessCode if available and hide field

            this.$el.on("pageAppendedToBody", (function(){

            }).bind(this));


            return this;
        },
        setRemoveOnHide : function(removeOnHide){
            this._removeOnHide =removeOnHide;
        },

        _onPageHide : function(){

            if(this._removeOnHide)
            {
                //PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._listenerViewportKey);
                this.onBeforeRemoveFromPage();
                this.remove();
                this.onRemovedFromPage();
            }
        },



    });
export default JourneyCreationWizardPageView;