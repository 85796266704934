import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import UrlPreviewView from "views/common/UrlPreviewView";
import Utils from "models/helper/Utils";
import ti18n from "i18n!nls/Commenti18n";
import CommentListItemTemplate from 'text!../../../templates/comment/CommentListItemTemplate.html';
import RSVP from 'rsvp';

const CommentListItemView = Backbone.View.extend({



    initialize : function(options) {
        this.compiledTemlate =  _.template(CommentListItemTemplate);
        this.model = options? options.model : null;
        this._disableReply = options? options.disableReply : false;
    },
    
    events : {
        "click #likeButton": "likeComment",
        "click #unlikeButton": "unlikeComment",
        "click #replyButton": "reply",
        "click .replySummary":"reply",
    },
    
    reply : function(){
        this.trigger("reply");
    },
    
    likeComment : function(){
        this.incrementLikes();
        this.trigger("likeComment");
        this.setLikedComment(true);
        
    },

    focus : function(){					
        this.$el.addClass("hightlightAnim").one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend',  (function() {
            this.$el.removeClass('hightlightAnim');
        }).bind(this));
    },
    
    setLikedComment : function(likedComment){
        if(likedComment){
            this.$el.find(" #likeButton").hide();
            this.$el.find(" #likeButton").next(".delimiter").hide();
            this.$el.find(" #unlikeButton").show();
            this.$el.find(" #unlikeButton").next(".delimiter").show();
        }else{
            this.$el.find(" #unlikeButton").hide();
            this.$el.find(" #unlikeButton").next(".delimiter").hide();
            this.$el.find(" #likeButton").show();
            this.$el.find(" #likeButton").next(".delimiter").show();
        }
    },
    
    incrementLikes : function(){
        this.$el.find("#numberOfThumbsUp").html(this.model.getNumberOfLikes() + 1);
    },
    
    decrementLikes : function(){
        this.$el.find("#numberOfThumbsUp").html(this.model.getNumberOfLikes() - 1);
    },
    
    updateLikeNumberView : function(){
        this.$el.find("#numberOfThumbsUp").html(this.model.getNumberOfLikes());
    },
    
    
    unlikeComment : function(){
        this.decrementLikes();
        this.trigger("unlikeComment");
        this.setLikedComment(false);
        
    },
    
    _buildPreviewIfApplicable : function(){
        if( this.model){
            const urls = Utils.getInstance().getUrlsFromString(this.model.getText());
            const previewPlaceHolder$el =  this.$el.find("#urlPreviewsPlaceholder");
            for(let index in urls){
                const urlPreviewOption ={};
                urlPreviewOption.url =urls[index];
                const previewView = new UrlPreviewView(urlPreviewOption);
                previewView.render();
                previewPlaceHolder$el.append(previewView.$el);
                this._listenForClickEventOnUrlPreviewEl(previewView.$el, urlPreviewOption.url);
            }
            if(!urls || urls.length == 0 ){
                previewPlaceHolder$el.hide();
            }
        }
        
    },

    _listenForClickEventOnUrlPreviewEl : function($elToListTo, url){
        if($elToListTo){
            $elToListTo.on("click", (function(){
                this.self.trigger("clickOnUrlPreview",this.url);
            }).bind({"self": this, "url": url}));
        }
    },

    _getVoiceAssistantCommentWasSentVia : function(){
        const commentContext =  this.model.getContext();
        if(Utils.getInstance().isContextEnvironmentAmazonAlexa(commentContext)){
            return window.getI18n(ti18n, "AMAZON_ALEXA"); 
        }
        if(Utils.getInstance().isContextEnvironmentGoogleAssistant(commentContext)){
            return window.getI18n(ti18n, "GOOGLE_ASSISTANT"); 
        }
        if(Utils.getInstance().isContextEnvironmentSamsungBixby(commentContext)){
            return window.getI18n(ti18n, "SAMSUNG_BIXBY"); 
        }
        if(Utils.getInstance().isContextEnvironmentAppleSiri(commentContext)){
            return window.getI18n(ti18n, "APPLE_SIRI"); 
        }
        return null;
    },

    render : function() {
        const templateParams = {};
        templateParams.model  = this.model;
        const options = {};
        options.disableReply = this._disableReply;
        templateParams.options = options;
        templateParams.ti18n = ti18n;
        templateParams.nameOfTheVoiceAssistantCommentWasSentVia = this._getVoiceAssistantCommentWasSentVia();
        this.$el.html(this.compiledTemlate(templateParams));
        this.setElement(this.$el.find("div").first());
        this._buildPreviewIfApplicable();
        return this;
    }

});
export default CommentListItemView;