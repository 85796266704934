import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import AlbumsTabContentCollection from "collections/myLibrary/AlbumsTabContentCollection";
import AlbumsTabContentView from "views/myLibrary/AlbumsTabContentView";
import PersistentModels from "services/PersistentModels";
import PlayerService from "services/PlayerService";
import RSVP from "rsvp";

const AlbumsTabContentController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = options.model;

    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    showAlbumDetailsPage : function(albumModel){
        PersistentModels.getInstance().getRouter().showAlbumDetailPage(albumModel.get("albumId") , albumModel, true);
    },
    
    addSongsAlbumToQueue :function(album){
        //this.trigger("addAlbumToEndOfQueue",this.model.where({uri:songModelSwiped.uri}));
        const songURIs = [];
        const songsJson = album.get("songs");
        for(let songIndex in songsJson)
        {
            songURIs.push(songsJson[songIndex].uri)
        }
        if(songURIs.length > 0){
            PlayerService.getInstance().addSongsToQueue(PersistentModels.getInstance().getSongCollection().getSongsWithURI(songURIs));
        }
    },
    
     filterList: function(searchCharaterers, filterOptions, sortOptions){
         if(this.view){
             this.view.filterList(searchCharaterers, filterOptions, sortOptions);
         }
     },
     
     clearSearchfilter : function(){
         if(this.view){
             this.view.clearSearchfilter();
         }
     },
    
    showMenuOption : function(albumModel){
        PersistentModels.getInstance().getRouter().showAlbumOptionPopup(albumModel);
    },
    
    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                if(!this.model)
                {
                    this.model = new AlbumsTabContentCollection([]);
                }
                this.view = new AlbumsTabContentView({
                    "collection" : this.model
                });
                this.listenTo(this.view, "itemTaped",this.showAlbumDetailsPage);
                this.listenTo(this.view, "itemSwipedRight",this.addSongsAlbumToQueue);
                this.listenTo(this.view, "itemOptionBtnTapped",this.showMenuOption);
                this.view.render();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default AlbumsTabContentController;