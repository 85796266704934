import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import noUiSlider from "nouislider";
import BasicInspectorNodeParamView from "views/audioEditor/sequencerNodeInspectorSection/paramView/BasicInspectorNodeParamView";
import DurationParamTemplate from 'text!../../../../../templates/audioEditor/sequencerNodeInspectorSection/DurationParamWithSliderAndInputTemplate.html';
import RSVP from 'rsvp';


const DurationInSecondsWithSliderAndInputParamView = BasicInspectorNodeParamView.extend({

    constructor : function(attributes, options) {
        BasicInspectorNodeParamView.apply(this, [attributes, options]);
        this.compiledTemlate =  _.template(DurationParamTemplate);
        this._getDisplayedLabelFromValueFunctionCustom =  attributes && attributes.getDisplayedLabelFromValueFunction;
        this._getValueFromDisplayedLabelFunctionCustom = attributes && attributes.getValueFromDisplayedLabelFunction;
        this._maxValueInSeconds = null;
        this._minValueInSeconds = null;
        this._editable = false;
    },	

    setStep : function(step){
        this._step =  step;
    },

    getStep: function(){
        return this._step;
    },		

    setMaxValueInSeconds : function(maxValueInSeconds){
        this._maxValueInSeconds = maxValueInSeconds;
    },

    getMaxValueInSeconds : function(){
        return this._maxValueInSeconds;
    },

    setMinValueInSeconds : function(minValueInSeconds){
        this._minValueInSeconds = minValueInSeconds;
    },

    getMinValueInSeconds : function(){
        return this._minValueInSeconds;
    },

    setEditable : function(editable){
        this._editable = editable;
        if(this.durationInput$el){
            if(editable){
                this.durationInput$el.removeAttr("disabled");
            }else{
                this.durationInput$el.attr("disabled", "disabled");
            }
        }
    },

    getEditable : function(){
        return this._editable;
    },

    _initSlider : function(){
        const sliderParams = {
            start: 0,
            connect: [true, false],
            behaviour :"tap-drag",
            orientation: 'horizontal', // 'horizontal' or 'vertical'
            step: this.getStep(),
            range: {
                'min': this.getMinValueInSeconds(),
                'max': this.getMaxValueInSeconds()
            },
            tooltips: true
        };
        if(this._getDisplayedLabelFromValueFunction && this._getValueFromDisplayedLabelFunction){
            sliderParams.format = {"to": this._getDisplayedLabelFromValueFunction, "from": this._getValueFromDisplayedLabelFunction};
        }
        
        this._noUiSliderObj = noUiSlider.create(this._sliderEl,sliderParams );
        this._sliderEl.noUiSlider.on('change',this._onUserSliderPosition.bind(this));
        this.updateTextInputValueOnSliderChange(this.getValue());
        this._sliderEl.noUiSlider.set(this._getDisplayedLabelFromValueFunction(this.getValue()));
    },

    _onUserSliderPosition: function(formatedValues, handleNumber, unformattedValues ){
        const newValue = unformattedValues && unformattedValues.length > 0? unformattedValues[0]: this._sliderEl.noUiSlider.get();
        this.updateTextInputValueOnSliderChange(newValue);
    },

    _getDisplayedLabelFromValueFunction : function(value){
        return value +"s";
    },

    _getValueFromDisplayedLabelFunction : function(displayedLabel){
        return displayedLabel? parseFloat(displayedLabel.substr(0 ,displayedLabel.length -1)): 0;
    },

    getDisplayedLabelFromValue : function(value){
        return this._getDisplayedLabelFromValueFunction? this._getDisplayedLabelFromValueFunction(value): value;
    },

    updateTextInputValueOnSliderChange : function(newValue){
        if((this.getMinValueInSeconds() == null || newValue >= this.getMinValueInSeconds())  && (this.getMaxValueInSeconds() == null || newValue <= this.getMaxValueInSeconds())){
            if(parseFloat(newValue) === 0){
                newValue = 0;
            }
                if(this.durationInput$el){
                    this.durationInput$el.val(newValue)
                }
        }
    },

    updateSliderValueOnTextInputChange : function(newValue){
        if(this._sliderEl && (this.getMinValueInSeconds() == null || newValue >= this.getMinValueInSeconds())  && (this.getMaxValueInSeconds() == null || newValue <= this.getMaxValueInSeconds())){
            this._sliderEl.noUiSlider.set(newValue); //Slider change will be propagated to text input
        }
    },
    
    updateViewOnValueChange : function(newValue){
        this.updateSliderValueOnTextInputChange(newValue);
    },

    _initTextInput : function(){
        
            this.durationInput$el.on("change", (function(){
                const newValue = parseFloat(this.durationInput$el.val());
                if(!isNaN(newValue)){
                    if(newValue < this.getMinValueInSeconds()){
                        return this.durationInput$el.val(this.getMinValueInSeconds())
                    }
                    if(newValue > this.getMaxValueInSeconds()){
                        return this.durationInput$el.val(newValue > this.getMaxValueInSeconds());
                    }
                    this.updateSliderValueOnTextInputChange(newValue);
                    this.onViewValueChanged(newValue);
                }
            }).bind(this));
    },


    render : function(){
        this.$el.html(this.compiledTemlate({ "viewObj": this}));
        this.setElement(this.$el.find(".basic_inpector_param_view").first());
        this.durationInput$el = this.$el.find(".duration_value input");
        this.$el.enhanceWithin();
        this.sliderContainer$el = this.$el.find(".slider_container");
        this.minValueContainer$el = this.$el.find(".min_value");
        this.maxValueContainer$el = this.$el.find(".max_value");
        this.minValueContainer$el.html(this.getDisplayedLabelFromValue(this.getMinValueInSeconds()));
        this.maxValueContainer$el.html(this.getDisplayedLabelFromValue(this.getMaxValueInSeconds()));
        this._sliderEl = this.sliderContainer$el.get(0);
        this._initSlider();
        this._initTextInput();

    }
});
export default DurationInSecondsWithSliderAndInputParamView;