import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import PersistentModels from "services/PersistentModels";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import RatingSummaryCardTemplate from 'text!../../../templates/common/RatingSummaryCardTemplate.html';
import ti18n from 'i18n!nls/Journeyi18n';
import RSVP from 'rsvp';

const TIME_IN_MILLISECONDS_TO_HOLD_TO_TRIGGER_PREVIEW = 2000;
const RatingSummaryCardView = Backbone.View.extend({



    initialize : function(options) {
        this.compiledTemlate =  _.template(RatingSummaryCardTemplate);
        this.model = options? options.model : null;


    },
    
    events : {

    },
    

    setModel: function(model){
        this.model = model;
        this._updateViewFromModel();
    },

    _updateViewFromModel : function(){
        const averageRating = this.model? Math.round(this.model.getAverageRatings(),1 ):0;
        const totalRatings = this.model? this.model.getTotalNumberOfRatings():0;
        const percentageOf5StarsRatings = this.model? Math.round(this.model.getPercentageOf5StarRatings()): 0;
        const percentageOf4StarsRatings = this.model? Math.round(this.model.getPercentageOf4StarRatings()): 0;
        const percentageOf3StarsRatings = this.model? Math.round(this.model.getPercentageOf3StarRatings()): 0;
        const percentageOf2StarsRatings = this.model? Math.round(this.model.getPercentageOf2StarRatings()): 0;
        const percentageOf1StarsRatings = this.model? Math.round(this.model.getPercentageOf1StarRatings()): 0;

        this.$el.find(".rating_average_value").html( " " +averageRating+" ");
        this.$el.find(".total_ratings_value").html(totalRatings+ " ");

        this.$el.find(".5_stars_details .rating_visual_bar .rating_progress_bar").css("width",percentageOf5StarsRatings +"%");
        this.$el.find(".5_stars_details .rating_percentage").html(percentageOf5StarsRatings +"%");

        this.$el.find(".4_stars_details .rating_visual_bar .rating_progress_bar").css("width",percentageOf4StarsRatings +"%");
        this.$el.find(".4_stars_details .rating_percentage").html(percentageOf4StarsRatings +"%");

        this.$el.find(".3_stars_details .rating_visual_bar .rating_progress_bar").css("width",percentageOf3StarsRatings +"%");
        this.$el.find(".3_stars_details .rating_percentage").html(percentageOf3StarsRatings +"%");

        this.$el.find(".2_stars_details .rating_visual_bar .rating_progress_bar").css("width",percentageOf2StarsRatings +"%");
        this.$el.find(".2_stars_details .rating_percentage").html(percentageOf2StarsRatings +"%");

        this.$el.find(".1_stars_details .rating_visual_bar .rating_progress_bar").css("width",percentageOf1StarsRatings +"%");
        this.$el.find(".1_stars_details .rating_percentage").html(percentageOf1StarsRatings +"%");
        this.$el.find(".rating_summary_card").attr("total-ratings" , totalRatings);




    },

    
    render : function() {

        this.$el.html(this.compiledTemlate({"ti18n": ti18n}));
        this._updateViewFromModel();

        return this;
    }

});
export default RatingSummaryCardView;