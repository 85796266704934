import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import OptionPopupView from "views/common/OptionPopupView";
import ti18n from "i18n!nls/shareTrebblePopupi18n";
import shareTrebblePopupContent from 'text!../../../templates/shareTrebble/shareTrebblePopupContent.html';

const SHOW_SMART_SPEAKERS_INSTRUCTIONS_CLASS = "showSmartSpeakerInstructions";
const SHOW_SUBSCRIBE_PAGE_CLASS = "showSubscribePage";
const SHOW_PLAYER_LINK_CLASS = "showLinkToPlayer";
const SHOW_EMBED_CODE = "showEmbedCode";
const SHOW_SEND_CAPSULE_LINK_CLASS = "showSendCapsuleLink";
const SHOW_FLASH_BRIEFING_RSS_LINK = "showFlashBriefingRSSLink";

const ShareTrebblePopupView = OptionPopupView.extend({



    "pageName": "Record Audio Hightlight Page",

    initialize: function(options) {
        const viewOptions = {};
        if(!options.showPlayerFooterAlwaysVisibleOption){
            options.showPlayerFooterAlwaysVisibleOption = false;
        }
        options.ti18n = ti18n;

        this._shareOnSmartSpeakers =  options.shareOnSmartSpeakers;
        this._shareSubscribePage =  options.shareSubscribePage;
        this._shareSencCapsulePage =  options.shareSencCapsulePage;
        this._canCopyEmbeddedWidgetLink = options.canCopyEmbeddedWidgetLink;
        this._canEmbedLandscapePlayer = options.canEmbedLandscapePlayer;
        if(!options.canEmbedLandscapePlayer){
            //put a default in case this is not set
            options.canEmbedLandscapePlayer = false;
        }
        this._canEmbedPortraitPlayer = options.canEmbedPortraitPlayer;
        if(!options.canEmbedPortraitPlayer){
            //put a default in case this is not set
            options.canEmbedPortraitPlayer = false;
        }
        if(!options.customTextToInviteToSubscribe){
            options.customTextToInviteToSubscribe = null;
        }
        if(!options.customTextToInviteToListenOnWeb){
            options.customTextToInviteToListenOnWeb = null;
        }
         if(!options.customTextToInviteToListenOnWebDescription){
            options.customTextToInviteToListenOnWebDescription = null;
        }
        this._trebbleShareableLink = options.trebbleShareableLink;
        this._shareableLinkHasAShortcast =  options.shareableLinkHasAShortcast;
        this._trebbleSubscribePageLink = options.trebbleSubscribePageLink;
        this._canPeopleSendCapsuleLink = options.canPeopleSendCapsuleLink;
        this._canGetFlashBriefingRssLink = options.canGetFlashBriefingRssLink;
        this._sendCapsulePageLink = options.sendCapsulePageLink;
        this._amazonFlashBriefingRssLink = options.amazonFlashBriefingRssLink;
        this._googleHomeAndAmazonEchoShareableLink =  options.googleHomeAndAmazonEchoShareableLink;
        this._trebbleWidgetLandscapeLink = options.trebbleWidgetLandscapeLink;
        this._trebbleWidgetPortraitLink = options.trebbleWidgetPortraitLink;
        this._embedCodeInNewsletter =  options.embedCodeInNewsletter;
        this._embedCodeInNewsletterColored =  options.embedCodeInNewsletterColored;
        this._trebbleFooterAlwaysVisibleScript =  options.trebbleFooterAlwaysVisibleScript;
        this._showPlayerFooterAlwaysVisibleOption =  options.showPlayerFooterAlwaysVisibleOption;
        this._customTextToInviteToSubscribe =  options.customTextToInviteToSubscribe;
        this._customTextToInviteToListenOnWeb = options.customTextToInviteToListenOnWeb;
        this._customTextToInviteToListenOnWebDescription = options.customTextToInviteToListenOnWebDescription;

        const contentView = {};
        const contentViewTemplate = _.template(shareTrebblePopupContent);
        const templateParams = options;
        if(!options.canPeopleSendCapsuleLink){
            options.canPeopleSendCapsuleLink = false;
        }
        if(!options.canGetFlashBriefingRssLink){
            options.canGetFlashBriefingRssLink = false;
        }
        if(!options.doNotTrebblePlayeableLink){
            options.doNotTrebblePlayeableLink = false;
        }
        contentView.$el = $(contentViewTemplate(templateParams));
        viewOptions.contentView = contentView;
        viewOptions.contentPosition = "center";
        viewOptions.id = "trebbleSharePopup";


        OptionPopupView.prototype.initialize.call(this, viewOptions);
    },



    _initializeCarousels: function() {

    },


    _onCarouselMove: function() {

    },

    copyGoogleHomeAmazonEchoShareableLinkToClipboard : function(){
        this._googleHomeAmazonEchoShareableLinkWrapperInput.removeAttr('disabled');
        if(!('cordova' in window && window.cordova && window.cordova.plugins && window.cordova.plugins.clipboard )){
            this._googleHomeAmazonEchoShareableLinkWrapperInput.focus().select();
        }
        this.trigger("copyLinkToClipboard",  this._googleHomeAndAmazonEchoShareableLink);
        this._googleHomeAmazonEchoShareableLinkWrapperInput.attr('disabled','disabled');
    },


    copyTrebbleShareableLinkToClipboard : function(){
        this._shareableLinkWrapperInput.removeAttr('disabled');
        if(!('cordova' in window && window.cordova && window.cordova.plugins && window.cordova.plugins.clipboard )){
            this._shareableLinkWrapperInput.focus().select();
        }
        this.trigger("copyLinkToClipboard",  this._trebbleShareableLink);
        this._shareableLinkWrapperInput.attr('disabled','disabled');
    },

    copyTrebbleSubscribePageLinkToClipboard : function(){
        this._subscribePageLinkWrapperInput.removeAttr('disabled');
        if(!('cordova' in window && window.cordova && window.cordova.plugins && window.cordova.plugins.clipboard )){
            this._subscribePageLinkWrapperInput.focus().select();
        }
        this.trigger("copyLinkToClipboard",  this._trebbleSubscribePageLink);
        this._subscribePageLinkWrapperInput.attr('disabled','disabled');
    },

    copySendCapsulePageLinkToClipboard : function(){
        this._sendCapsulePageLinkWrapperInput.removeAttr('disabled');
        if(!('cordova' in window && window.cordova && window.cordova.plugins && window.cordova.plugins.clipboard )){
            this._sendCapsulePageLinkWrapperInput.focus().select();
        }
        this.trigger("copyLinkToClipboard",  this._sendCapsulePageLink);
        this._sendCapsulePageLinkWrapperInput.attr('disabled','disabled');
    },

    copyAmazonFlashBriefingRSSLinkToClipboard : function(){
        this._flashBriefingRssLinkWrapperInput.removeAttr('disabled');
        if(!('cordova' in window && window.cordova && window.cordova.plugins && window.cordova.plugins.clipboard )){
            this._flashBriefingRssLinkWrapperInput.focus().select();
        }
        this.trigger("copyLinkToClipboard",  this._amazonFlashBriefingRssLink);
        this._flashBriefingRssLinkWrapperInput.attr('disabled','disabled');
    },

    previewGoogleHomeAmazonEchoShareableLinkToClipboard : function(){
        this.trigger("preview",  this._googleHomeAndAmazonEchoShareableLink);
    },


    previewTrebbleShareableLinkToClipboard : function(){
        this.trigger("preview",  this._trebbleShareableLink);
    },

    previewTrebbleSubscribePageLinkToClipboard : function(){
        this.trigger("preview",  this._trebbleSubscribePageLink);
    },

    previewSendCapsulePageLinkToClipboard : function(){
        this.trigger("preview",  this._sendCapsulePageLink);
    },

    previewFlashBriefingRSSLinkToClipboard : function(){
        this.trigger("preview",  this._amazonFlashBriefingRssLink);
    },


    copyTrebbleWidgetLandscapeLinkToClipboard : function(){
        this._shareableWidgetLandscapeWrapperInput.removeAttr('disabled');
        if(!('cordova' in window && window.cordova && window.cordova.plugins && window.cordova.plugins.clipboard )){
            this._shareableWidgetLandscapeWrapperInput.focus().select();
        }
        this.trigger("copyWidgetLandscapeToClipboard",  this._trebbleWidgetLandscapeLink);
        this._shareableWidgetLandscapeWrapperInput.attr('disabled','disabled');
    },


    copyTrebbleWidgetPortraitLinkToClipboard : function(){
        this._shareableWidgetSquareWrapperInput.removeAttr('disabled');
        if(!('cordova' in window && window.cordova && window.cordova.plugins && window.cordova.plugins.clipboard )){
            this._shareableWidgetSquareWrapperInput.focus().select();
        }
        this.trigger("copyWidgetPortraitToClipboard",  this._trebbleWidgetPortraitLink);
        this._shareableWidgetSquareWrapperInput.attr('disabled','disabled');
    },

    copyEmbedInNewsletterCodeToClipboard : function(){
        this._shareableEmbedInNewsletterWrapperInput.removeAttr('disabled');
        if(!('cordova' in window && window.cordova && window.cordova.plugins && window.cordova.plugins.clipboard )){
            this._shareableEmbedInNewsletterWrapperInput.focus().select();
        }
        this.trigger("copyEmbedToNewsletterCodeToClipboard",  this._embedCodeInNewsletter);
        this._shareableEmbedInNewsletterWrapperInput.attr('disabled','disabled');
    },

    copyEmbedInNewsletterColoredCodeToClipboard : function(){
        this._shareableEmbedInNewsletterColoredWrapperInput.removeAttr('disabled');
        if(!('cordova' in window && window.cordova && window.cordova.plugins && window.cordova.plugins.clipboard )){
            this._shareableEmbedInNewsletterColoredWrapperInput.focus().select();
        }
        this.trigger("copyEmbedToNewsletterColoredCodeToClipboard",  this._embedCodeInNewsletterColored);
        this._shareableEmbedInNewsletterColoredWrapperInput.attr('disabled','disabled');
    },

    

    copyTrebblePlayerFooterAlwaysVisibleScriptToClipboard : function(){
        this._trebblePlayerFooterAlwaysVisibleWidgetWrapperInput.removeAttr('disabled');
        if(!('cordova' in window && window.cordova && window.cordova.plugins && window.cordova.plugins.clipboard )){
            this._trebblePlayerFooterAlwaysVisibleWidgetWrapperInput.focus().select();
        }
        this.trigger("copyTrebbleFooterPlayerScriptToClipboard",  this._trebbleFooterAlwaysVisibleScript);
        this._trebblePlayerFooterAlwaysVisibleWidgetWrapperInput.attr('disabled','disabled');
    },




    shareNativelyTrebbleShareableLinkToClipboard : function(){
        this.trigger("shareNativelyLinkToClipboard",  this._trebbleShareableLink);
    },

    shareNativelyTrebbleSubscribeLinkToClipboard : function(){
        this.trigger("shareNativelyLinkToClipboard",  this._trebbleSubscribePageLink);
    },

    shareNativelySendCapsulePageLinkToClipboard : function(){
        this.trigger("shareNativelyLinkToClipboard",  this._sendCapsulePageLink);
    },

    shareNativelyFlashBriefingRSSLinkToClipboard : function(){
        this.trigger("shareNativelyLinkToClipboard",  this._amazonFlashBriefingRssLink);
    },


    shareNativelyGoogleHomeAmazonEchoShareableLinkToClipboard : function(){
        this.trigger("shareNativelyLinkToClipboard",  null, this._googleHomeAndAmazonEchoShareableLink);
    },


    shareNativelyTrebbleWidgetLandscapeLinkToClipboard : function(){
        this.trigger("shareNativelyWidgetLandscapeToClipboard",  this._trebbleWidgetLandscapeLink);
    },


    shareNativelyTrebbleWidgetPortraitLinkToClipboard : function(){
        this.trigger("shareNativelyWidgetPortraitToClipboard",  this._trebbleWidgetPortraitLink);
    },

    shareNativelyEmbedInNewsletterCodeToClipboard : function(){
        this.trigger("shareNativelyEmbedCodeToNewsletterToClipboard",  this._embedCodeInNewsletter);
    },

    shareNativelyEmbedInNewsletterColoredCodeToClipboard : function(){
        this.trigger("shareNativelyEmbedCodeToNewsletterColoredToClipboard",  this._embedCodeInNewsletterColored);
    },

    shareNativelyTrebblePlayerFooterAlwaysVisibleScriptToClipboard : function(){
        this.trigger("shareNativelyTrebbleFooterPlayerScriptToClipboard",  this._trebbleFooterAlwaysVisibleScript);
    },

    shareLinkOnFacebook: function(){
        this.trigger("shareOnFacebookButtonClicked", this._trebbleShareableLink, this._shareableLinkHasAShortcast? window.getI18n(ti18n, "CHECK_OUT_TODAYS_SHORTCAST")+" ": null);
    },

    shareLinkOnTwitter: function(){
        this.trigger("shareOnTwitterButtonClicked", this._trebbleShareableLink, this._shareableLinkHasAShortcast? window.getI18n(ti18n, "CHECK_OUT_TODAYS_SHORTCAST")+" " : null);
    },

    shareSubscribePageLinkOnFacebook: function(){

        this.trigger("shareOnFacebookButtonClicked",  this._trebbleSubscribePageLink);
    },

    shareSendCapsulePageLinkOnFacebook: function(){

        this.trigger("shareOnFacebookButtonClicked",  this._sendCapsulePageLink);
    },

    shareFlashBriefingRSSLinkOnFacebook: function(){

        this.trigger("shareOnFacebookButtonClicked",  this._amazonFlashBriefingRssLink);
    },



    shareSubscribePageLinkOnTwitter: function(){
        this.trigger("shareOnTwitterButtonClicked",  this._trebbleSubscribePageLink);
    },

    shareSendCapsulePageLinkOnTwitter: function(){
        this.trigger("shareOnTwitterButtonClicked",  this._sendCapsulePageLink);
    },

    shareSendFlashBriefingRSSLinkOnTwitter: function(){
        this.trigger("shareOnTwitterButtonClicked",  this._amazonFlashBriefingRssLink);
    },


    shareGoogleHomeAmazonEchoLinkOnFacebook: function(){

        this.trigger("shareOnFacebookButtonClicked", null, this._googleHomeAndAmazonEchoShareableLink);
    },

    shareGoogleHomeAmazonEchoLinkOnTwitter: function(){
        this.trigger("shareOnTwitterButtonClicked", null, this._googleHomeAndAmazonEchoShareableLink);
    },

    showSmartSpeakersInstructions :function(){
        if(!this._shareOptionsWrapper$el.hasClass(SHOW_SMART_SPEAKERS_INSTRUCTIONS_CLASS)){
            this._shareOptionsWrapper$el.addClass(SHOW_SMART_SPEAKERS_INSTRUCTIONS_CLASS);
        }
    },

    showSubscribePage :function(){
        if(!this._shareOptionsWrapper$el.hasClass(SHOW_SUBSCRIBE_PAGE_CLASS)){
            this._shareOptionsWrapper$el.addClass(SHOW_SUBSCRIBE_PAGE_CLASS);
        }
    },

    showPlayerLink :function(){
        if(!this._shareOptionsWrapper$el.hasClass(SHOW_PLAYER_LINK_CLASS)){
            this._shareOptionsWrapper$el.addClass(SHOW_PLAYER_LINK_CLASS);
        }
    },

    showSendCapsuleLink :function(){
        if(!this._shareOptionsWrapper$el.hasClass(SHOW_SEND_CAPSULE_LINK_CLASS)){
            this._shareOptionsWrapper$el.addClass(SHOW_SEND_CAPSULE_LINK_CLASS);
        }
    },

    showFlashBriefingRSSLink :function(){
        if(!this._shareOptionsWrapper$el.hasClass(SHOW_FLASH_BRIEFING_RSS_LINK)){
            this._shareOptionsWrapper$el.addClass(SHOW_FLASH_BRIEFING_RSS_LINK);
        }
    },


    showEmbedCode :function(){
        if(!this._shareOptionsWrapper$el.hasClass(SHOW_EMBED_CODE)){
            this._shareOptionsWrapper$el.addClass(SHOW_EMBED_CODE);
        }
    },

    _onNewsletterThemeChanged : function(){
        this.$el.find("#shareTrebblePopupContent").attr("newsletter_theme", this.$el.find("#newsletterEmbedThemeSelector option:selected").val());
        this.$el.find("#shareableWidgetNewsletterWrapper").attr("show_embedded_code", null);
        this.$el.find("#shareableWidgetNewsletterColoredWrapper").attr("show_embedded_code", null);
    },

    showNewsletterTransparentEmbeddedCode : function(){
        this.$el.find("#shareableWidgetNewsletterWrapper").attr("show_embedded_code",true);
    },

    showNewsletterColouredEmbeddedCode : function(){
        this.$el.find("#shareableWidgetNewsletterColoredWrapper").attr("show_embedded_code",true);
    },

    showNewsletterTransparentPreview : function(){
        this.$el.find("#shareableWidgetNewsletterWrapper").attr("show_embedded_code", null);
    },

    showNewsletterColouredPreview : function(){
        this.$el.find("#shareableWidgetNewsletterColoredWrapper").attr("show_embedded_code", null);
    },

    afterRendering: function() {
        this._initializeCarousels();
        this.$el.find("#newsletterEmbedThemeSelector").selectmenu();
        this.$el.find("#newsletterEmbedThemeSelector-button").append('<i class="ion-chevron-down" style="display: inline-block;"></i>');
        this._shareOptionsWrapper$el = this.$el.find("#shareOptions");
        this.$el.find("#shareOptions #shareSmartSpeakersIntructionBtn").click(this.showSmartSpeakersInstructions.bind(this));
        this.$el.find("#shareOptions #shareSubscribePageBtn").click(this.showSubscribePage.bind(this));
        this.$el.find("#shareOptions #sharePlayerLinkBtn").click(this.showPlayerLink.bind(this));
        this.$el.find("#shareOptions #shareCMailLinkBtn").click(this.showSendCapsuleLink.bind(this));
        this.$el.find("#shareOptions #shareFlashBriefingRssLinkBtn").click(this.showFlashBriefingRSSLink.bind(this));
        this.$el.find("#shareOptions #shareEmbedCodeBtn").click(this.showEmbedCode.bind(this));
        this.newsletterThemeSelector$el = this.$el.find("#newsletterEmbedThemeSelector")
        this.newsletterThemeSelector$el.on("change", this._onNewsletterThemeChanged.bind(this));

        const googleHomeAmazonEchoShareableLinkWrapperInput = this.$el.find("#shareableGoogleHomeAmazonEchoLinkWrapper * textarea");
        this._googleHomeAmazonEchoShareableLinkWrapperInput = googleHomeAmazonEchoShareableLinkWrapperInput;
        googleHomeAmazonEchoShareableLinkWrapperInput.val( this._googleHomeAndAmazonEchoShareableLink);
        googleHomeAmazonEchoShareableLinkWrapperInput.click(function () {
            $(this).select()
        });


        const shareableLinkWrapperInput = this.$el.find("#shareableLinkWrapper * input");
        this._shareableLinkWrapperInput = shareableLinkWrapperInput;
        shareableLinkWrapperInput.val( this._trebbleShareableLink);
        shareableLinkWrapperInput.click(function () {
            $(this).select();
        });

        const subscribePageLinkWrapperInput = this.$el.find("#shareableSubscribePageLinkWrapper * input");
        this._subscribePageLinkWrapperInput = subscribePageLinkWrapperInput;
        subscribePageLinkWrapperInput.val( this._trebbleSubscribePageLink);
        subscribePageLinkWrapperInput.click(function () {
            $(this).select();
        });

        const sendCapsulePageLinkWrapperInput = this.$el.find("#sendCapsulePageLinkWrapper * input");
        this._sendCapsulePageLinkWrapperInput = sendCapsulePageLinkWrapperInput;
        sendCapsulePageLinkWrapperInput.val( this._sendCapsulePageLink);
        sendCapsulePageLinkWrapperInput.click(function () {
            $(this).select();
        });

        const flashBriefingRssLinkWrapperInput = this.$el.find("#flashBriefingRssLinkWrapper * input");
        this._flashBriefingRssLinkWrapperInput = flashBriefingRssLinkWrapperInput;
        flashBriefingRssLinkWrapperInput.val( this._amazonFlashBriefingRssLink);
        flashBriefingRssLinkWrapperInput.click(function () {
            $(this).select();
        });

        const shareableWidgetLandscapeWrapperInput =  this.$el.find("#shareableWidgetLandscapeWrapper * textarea");
        this._shareableWidgetLandscapeWrapperInput = shareableWidgetLandscapeWrapperInput;
        shareableWidgetLandscapeWrapperInput.val( this._trebbleWidgetLandscapeLink);
        shareableWidgetLandscapeWrapperInput.click(function () {
            $(this).select();
        });
        const shareableWidgetSquareWrapperInput = this.$el.find("#shareableWidgetSquareWrapper * textarea");
        this._shareableWidgetSquareWrapperInput = shareableWidgetSquareWrapperInput;
        shareableWidgetSquareWrapperInput.val( this._trebbleWidgetPortraitLink);
        shareableWidgetSquareWrapperInput.click(function () {
            $(this).select();
        });
        this.$el.find("#shareableWidgetNewsletterWrapper * .showEmbeddedCodeButton").click(this.showNewsletterTransparentEmbeddedCode.bind(this));
        this.$el.find("#shareableWidgetNewsletterWrapper * .showPreviewButton").click(this.showNewsletterTransparentPreview.bind(this));
        const shareableEmbedInNewsletterWrapperInput = this.$el.find("#shareableWidgetNewsletterWrapper * textarea");
        this._shareableEmbedInNewsletterWrapperInput = shareableEmbedInNewsletterWrapperInput;
        shareableEmbedInNewsletterWrapperInput.val( this._embedCodeInNewsletter);
        this.$el.find("#shareableWidgetNewsletterWrapper * .htmlTableEmbed").html( this._embedCodeInNewsletter);
        shareableEmbedInNewsletterWrapperInput.click(function () {
            $(this).select();
        });
        this.$el.find("#shareableWidgetNewsletterColoredWrapper * .showEmbeddedCodeButton").click(this.showNewsletterColouredEmbeddedCode.bind(this));
        this.$el.find("#shareableWidgetNewsletterColoredWrapper * .showPreviewButton").click(this.showNewsletterColouredPreview.bind(this));
        const shareableEmbedInNewsletterColoredWrapperInput = this.$el.find("#shareableWidgetNewsletterColoredWrapper * textarea");
        this._shareableEmbedInNewsletterColoredWrapperInput = shareableEmbedInNewsletterColoredWrapperInput;
        shareableEmbedInNewsletterColoredWrapperInput.val( this._embedCodeInNewsletterColored);
        this.$el.find("#shareableWidgetNewsletterColoredWrapper * .htmlTableEmbed").html( this._embedCodeInNewsletterColored);
        shareableEmbedInNewsletterColoredWrapperInput.click(function () {
            $(this).select();
        });



        const trebblePlayerFooterAlwaysVisibleWidgetWrapperInput = this.$el.find("#trebblePlayerFooterAlwaysVisibleWidgetWrapper * textarea");
        this._trebblePlayerFooterAlwaysVisibleWidgetWrapperInput = trebblePlayerFooterAlwaysVisibleWidgetWrapperInput;
        trebblePlayerFooterAlwaysVisibleWidgetWrapperInput.val( this._trebbleFooterAlwaysVisibleScript);
        trebblePlayerFooterAlwaysVisibleWidgetWrapperInput.click(function () {
            $(this).select();
        });

        if(!this._showPlayerFooterAlwaysVisibleOption){
            this.$el.find("#trebblePlayerFooterAlwaysVisibleWidgetWrapper").hide();
        }

        this.$el.find("#shareableLinkWrapper * .shareNativeLinkButton").click(this.shareNativelyTrebbleShareableLinkToClipboard.bind(this));
        this.$el.find("#shareableSubscribePageLinkWrapper * .shareNativeLinkButton").click(this.shareNativelyTrebbleSubscribeLinkToClipboard.bind(this));
        this.$el.find("#sendCapsulePageLinkWrapper * .shareNativeLinkButton").click(this.shareNativelySendCapsulePageLinkToClipboard.bind(this));
        this.$el.find("#flashBriefingRssLinkWrapper * .shareNativeLinkButton").click(this.shareNativelyFlashBriefingRSSLinkToClipboard.bind(this));
        this.$el.find("#shareableGoogleHomeAmazonEchoLinkWrapper * .shareNativeLinkButton").click(this.shareNativelyGoogleHomeAmazonEchoShareableLinkToClipboard.bind(this));
        this.$el.find("#shareableWidgetLandscapeWrapper * .shareNativeLinkButton").click(this.shareNativelyTrebbleWidgetLandscapeLinkToClipboard.bind(this));
        this.$el.find("#shareableWidgetSquareWrapper * .shareNativeLinkButton").click(this.shareNativelyTrebbleWidgetPortraitLinkToClipboard.bind(this));
        this.$el.find("#shareableWidgetNewsletterWrapper * .shareNativeLinkButton").click(this.shareNativelyEmbedInNewsletterCodeToClipboard.bind(this));
        this.$el.find("#shareableWidgetNewsletterColoredWrapper * .shareNativeLinkButton").click(this.shareNativelyEmbedInNewsletterColoredCodeToClipboard.bind(this));
        
        this.$el.find("#trebblePlayerFooterAlwaysVisibleWidgetWrapper * .shareNativeLinkButton").click(this.shareNativelyTrebblePlayerFooterAlwaysVisibleScriptToClipboard.bind(this));


        this.$el.find("#shareableSubscribePageLinkWrapper * .previewLinkButton").click(this.previewTrebbleSubscribePageLinkToClipboard.bind(this));
        this.$el.find("#sendCapsulePageLinkWrapper * .previewLinkButton").click(this.previewSendCapsulePageLinkToClipboard.bind(this));
        this.$el.find("#flashBriefingRssLinkWrapper * .previewLinkButton").click(this.previewFlashBriefingRSSLinkToClipboard.bind(this));
        this.$el.find("#shareableLinkWrapper * .previewLinkButton").click(this.previewTrebbleShareableLinkToClipboard.bind(this));


        if(window.device && window.device.platform == "browser"){
            //this.$el.find("#shareableLinkWrapper * .copyLinkButton").hide();
            // this.$el.find("#shareableWidgetLandscapeWrapper * .copyLinkButton").hide();
            //this.$el.find("#shareableWidgetSquareWrapper * .copyLinkButton").hide();
            this._googleHomeAmazonEchoShareableLinkWrapperInput.attr('disabled','disabled');
            this._shareableLinkWrapperInput.attr('disabled','disabled');
            this._subscribePageLinkWrapperInput.attr('disabled','disabled');
            this._shareableWidgetLandscapeWrapperInput.attr('disabled','disabled');
            this._shareableWidgetSquareWrapperInput.attr('disabled','disabled');
            this._shareableEmbedInNewsletterWrapperInput.attr('disabled','disabled');
            this._trebblePlayerFooterAlwaysVisibleWidgetWrapperInput.attr('disabled','disabled');
            this.$el.find("#shareableLinkWrapper * .copyLinkButton").click(this.copyTrebbleShareableLinkToClipboard.bind(this));
            this.$el.find("#shareableSubscribePageLinkWrapper * .copyLinkButton").click(this.copyTrebbleSubscribePageLinkToClipboard.bind(this));
            this.$el.find("#sendCapsulePageLinkWrapper * .copyLinkButton").click(this.copySendCapsulePageLinkToClipboard.bind(this));
            this.$el.find("#flashBriefingRssLinkWrapper * .copyLinkButton").click(this.copyAmazonFlashBriefingRSSLinkToClipboard.bind(this));
            this.$el.find("#shareableGoogleHomeAmazonEchoLinkWrapper * .copyLinkButton").click(this.copyGoogleHomeAmazonEchoShareableLinkToClipboard.bind(this));
            this.$el.find("#shareableWidgetLandscapeWrapper * .copyLinkButton").click(this.copyTrebbleWidgetLandscapeLinkToClipboard.bind(this));
            this.$el.find("#trebblePlayerFooterAlwaysVisibleWidgetWrapper * .copyLinkButton").click(this.copyTrebblePlayerFooterAlwaysVisibleScriptToClipboard.bind(this));
            this.$el.find("#shareableWidgetSquareWrapper * .copyLinkButton").click(this.copyTrebbleWidgetPortraitLinkToClipboard.bind(this));
            this.$el.find("#shareableWidgetNewsletterWrapper * .copyLinkButton").click(this.copyEmbedInNewsletterCodeToClipboard.bind(this));
            this.$el.find("#shareableWidgetNewsletterColoredWrapper * .copyLinkButton").click(this.copyEmbedInNewsletterColoredCodeToClipboard.bind(this));
            this.$el.find("#shareableLinkWrapper * .shareOnFacebookButton").click(this.shareLinkOnFacebook.bind(this));
            this.$el.find("#shareableLinkWrapper * .shareOnTwitterButton").click(this.shareLinkOnTwitter.bind(this));
            this.$el.find("#shareableSubscribePageLinkWrapper * .shareOnFacebookButton").click(this.shareSubscribePageLinkOnFacebook.bind(this));
            this.$el.find("#shareableSubscribePageLinkWrapper * .shareOnTwitterButton").click(this.shareSendCapsulePageLinkOnTwitter.bind(this));
            this.$el.find("#sendCapsulePageLinkWrapper * .shareOnFacebookButton").click(this.shareSendCapsulePageLinkOnFacebook.bind(this));
            this.$el.find("#sendCapsulePageLinkWrapper * .shareOnTwitterButton").click(this.shareSubscribePageLinkOnTwitter.bind(this));
            this.$el.find("#flashBriefingRssLinkWrapper * .shareOnFacebookButton").click(this.shareFlashBriefingRSSLinkOnFacebook.bind(this));
            this.$el.find("#flashBriefingRssLinkWrapper * .shareOnTwitterButton").click(this.shareSendFlashBriefingRSSLinkOnTwitter.bind(this));
            this.$el.find("#shareableGoogleHomeAmazonEchoLinkWrapper * .shareOnFacebookButton").click(this.shareGoogleHomeAmazonEchoLinkOnFacebook.bind(this));
            this.$el.find("#shareableGoogleHomeAmazonEchoLinkWrapper * .shareOnTwitterButton").click(this.shareGoogleHomeAmazonEchoLinkOnTwitter.bind(this));
            if(window.trebble.defaultValues.clipboardCopyAvailable && window.isMobileBrowser){
                this.$el.find("#shareableLinkWrapper * .copyLinkButton").hide();
                this.$el.find("#shareableGoogleHomeAmazonEchoLinkWrapper * .copyLinkButton").hide();

                this.$el.find("#shareableWidgetLandscapeWrapper * .copyLinkButton").hide();
                this.$el.find("#shareableWidgetSquareWrapper * .copyLinkButton").hide();
                this.$el.find("#shareableWidgetNewsletterWrapper * .copyLinkButton").hide();
                this.$el.find("#shareableWidgetNewsletterColoredWrapper * .copyLinkButton").hide();
                this.$el.find("#trebblePlayerFooterAlwaysVisibleWidgetWrapper * .copyLinkButton").hide();
                this._googleHomeAmazonEchoShareableLinkWrapperInput.removeAttr('disabled');
                
                this._shareableLinkWrapperInput.removeAttr("disabled");
                this._subscribePageLinkWrapperInput.removeAttr("disabled");
                this._shareableWidgetLandscapeWrapperInput.removeAttr("disabled");
                this._shareableWidgetSquareWrapperInput.removeAttr("disabled");
                this._shareableEmbedInNewsletterWrapperInput.removeAttr("disabled");
                this._trebblePlayerFooterAlwaysVisibleWidgetWrapperInput.removeAttr("disabled");
            }
        }else{
            this._googleHomeAmazonEchoShareableLinkWrapperInput.attr('disabled','disabled');
            this._shareableLinkWrapperInput.attr('disabled','disabled');
            this._subscribePageLinkWrapperInput.attr('disabled','disabled');
            this._shareableWidgetLandscapeWrapperInput.attr('disabled','disabled');
            this._shareableWidgetSquareWrapperInput.attr('disabled','disabled');
            this._shareableEmbedInNewsletterWrapperInput.attr('disabled','disabled');
            this._trebblePlayerFooterAlwaysVisibleWidgetWrapperInput.attr('disabled','disabled');
            this.$el.find("#shareableLinkWrapper * .copyLinkButton").click(this.copyTrebbleShareableLinkToClipboard.bind(this));
            this.$el.find("#shareableSubscribePageLinkWrapper * .copyLinkButton").click(this.copyTrebbleSubscribePageLinkToClipboard.bind(this));
            this.$el.find("#sendCapsulePageLinkWrapper * .copyLinkButton").click(this.copySendCapsulePageLinkToClipboard.bind(this));
            this.$el.find("#flashBriefingRssLinkWrapper * .copyLinkButton").click(this.copyAmazonFlashBriefingRSSLinkToClipboard.bind(this));
            this.$el.find("#shareableGoogleHomeAmazonEchoLinkWrapper * .copyLinkButton").click(this.copyGoogleHomeAmazonEchoShareableLinkToClipboard.bind(this));
            this.$el.find("#shareableWidgetLandscapeWrapper * .copyLinkButton").click(this.copyTrebbleWidgetLandscapeLinkToClipboard.bind(this));
            this.$el.find("#shareableWidgetSquareWrapper * .copyLinkButton").click(this.copyTrebbleWidgetPortraitLinkToClipboard.bind(this));
            this.$el.find("#shareableWidgetNewsletterWrapper * .copyLinkButton").click(this.copyEmbedInNewsletterCodeToClipboard.bind(this));
            this.$el.find("#shareableWidgetNewsletterColoredWrapper * .copyLinkButton").click(this.copyEmbedInNewsletterColoredCodeToClipboard.bind(this));
            this.$el.find("#trebblePlayerFooterAlwaysVisibleWidgetWrapper * .copyLinkButton").click(this.copyTrebblePlayerFooterAlwaysVisibleScriptToClipboard.bind(this));
            this.$el.find("#shareableLinkWrapper * .shareOnFacebookButton").click(this.shareLinkOnFacebook.bind(this));
            this.$el.find("#shareableLinkWrapper * .shareOnTwitterButton").click(this.shareLinkOnTwitter.bind(this));
            this.$el.find("#shareableSubscribePageLinkWrapper * .shareOnFacebookButton").click(this.shareSubscribePageLinkOnFacebook.bind(this));
            this.$el.find("#shareableSubscribePageLinkWrapper * .shareOnTwitterButton").click(this.shareSubscribePageLinkOnTwitter.bind(this));
            this.$el.find("#sendCapsulePageLinkWrapper * .shareOnFacebookButton").click(this.shareSendCapsulePageLinkOnFacebook.bind(this));
            this.$el.find("#sendCapsulePageLinkWrapper * .shareOnTwitterButton").click(this.shareSubscribePageLinkOnTwitter.bind(this));
            this.$el.find("#flashBriefingRssLinkWrapper * .shareOnFacebookButton").click(this.shareFlashBriefingRSSLinkOnFacebook.bind(this));
            this.$el.find("#flashBriefingRssLinkWrapper * .shareOnTwitterButton").click(this.shareSendFlashBriefingRSSLinkOnTwitter.bind(this));
            this.$el.find("#shareableGoogleHomeAmazonEchoLinkWrapper * .shareOnFacebookButton").click(this.shareGoogleHomeAmazonEchoLinkOnFacebook.bind(this));
            this.$el.find("#shareableGoogleHomeAmazonEchoLinkWrapper * .shareOnTwitterButton").click(this.shareGoogleHomeAmazonEchoLinkOnTwitter.bind(this));

        }
        this.showDefaultOptionIfOnlyOneAvailable();

    },


    showDefaultOptionIfOnlyOneAvailable : function(){
        const shareOption$el = this.$el.find("#shareOptions");
        if(shareOption$el.children().length == 1){
            if(this.$el.find("#shareOptions #shareSmartSpeakersIntructionBtn").length == 1){
                this.showSmartSpeakersInstructions();
            }
            if(this.$el.find("#shareOptions #shareSubscribePageBtn").length == 1){
                this.showSubscribePage();
            }
            if(this.$el.find("#shareOptions #sharePlayerLinkBtn").length == 1){
                this.showPlayerLink();
            }
            if(this.$el.find("#shareOptions #shareCMailLinkBtn").length == 1){
                this.showSendCapsuleLink();
            }
            if(this.$el.find("#shareOptions #shareFlashBriefingRssLinkBtn").length == 1){
                this.showFlashBriefingRSSLink();
            }
            if(this.$el.find("#shareOptions #shareEmbedCodeBtn").length == 1){
                this.showEmbedCode();
            }
            
        }else{
            //do nothing
        }
    },


    onPopupClosed: function() {
       this._shareableLinkWrapperInput.off();
       this._subscribePageLinkWrapperInput.off();
       this._shareableWidgetLandscapeWrapperInput.off();
       this._shareableWidgetSquareWrapperInput.off();
       this._shareableEmbedInNewsletterWrapperInput.off();
   },





   onAfterPageShow: function() {

   },



});

export default ShareTrebblePopupView;
