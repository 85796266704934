import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import ti18n from 'i18n!nls/Sequenceri18n';
import RSVP from 'rsvp';

const SequencerNodeView = Backbone.View.extend({



    initialize : function(options) {
        this.model = options? options.model : null;
        this.listenTo(this.model,"change:selected",this._onNodeSelected);
        this.listenTo(this.model,"change:playing",this._onNodePlaying);
        this.listenTo(this.model,"change:highlighted",this._onNodeHightlighted);
        this.listenTo(this.model,"change:hoverHighlighted",this._onNodeHoverHightlighted);
        this.listenTo(this.model,"change:searchHightlighted",this._onNodeSearchHightlighted);
        this.listenTo(this.model,"change:beingProcessed", this._onNodeBeingProcessed)
        this.listenTo(this.model,"change:addedToSequenecer",this._onModelHiddenChange);
        this.listenTo(this.model,"updateContinuityVisualCue",this.updateContinuityVisualCue);
        this.listenTo(this.model,"change:insertedByPasting",this._onInsertedByPastingChange);
        this.listenTo(this.model,"change:not_selectable",this._onSelectableChange);
        this.listenTo(this.model,"change:audioSegment",this.updateContinuityVisualCueWithSiblings);
        this.listenTo(this.model,"previousNodeChange",this.updateContinuityVisualCueOnLeft);
        this.listenTo(this.model,"nextNodeChange",this.updateContinuityVisualCueOnRight);
        //this.listenTo(this.model,"sequencerViewSettingsChange",this.updateContinuityVisualCueWithSiblings);
        
    },
    
    events : {
        "dragstart" : "_onDragStart",
        "dragover" : "_onDragOver",
        "dragleave" : "_onDragLeave",
        "drop" : "_onDrop",

    },

    _onDragStart : function(e){
        if(this.model){
            const modelCid =  this.model.cid;
            e.originalEvent.dataTransfer.setData("text/plain", modelCid);
            Utils.getInstance().triggerShortVibration();
        }
    },

    _onDragOver : function(e){
        e.originalEvent.preventDefault();
        this.$el.addClass("node_drag_over");
        Utils.getInstance().triggerShortVibration();
    },

    _onDragLeave : function(){
        this.$el.removeClass("node_drag_over");
    },

    updateContinuityVisualCueOnLeft : function(){
        const not_continuous_on_left  = !this.model.isThereContinuityOnLeft();
        if(this.model.getPreviousSequencerNodeWithDuration()){
            this.model.getPreviousSequencerNodeWithDuration().trigger("updateContinuityVisualCue");
        }
        this.$el.attr("not_continuous_on_left", not_continuous_on_left);
    },

    updateContinuityVisualCueOnRight : function(){
        const not_continuous_on_right  = !this.model.isThereContinuityOnRight();
        if(this.model.getNextSequencerNodeWithDuration()){
            this.model.getNextSequencerNodeWithDuration().trigger("updateContinuityVisualCue");
        }
        this.$el.attr("not_continuous_on_right", not_continuous_on_right);
    },


    updateContinuityVisualCueWithSiblings : function(){
        this.updateContinuityVisualCueOnLeft();
        this.updateContinuityVisualCueOnRight();
    },

    updateContinuityVisualCue: function(){
        this.$el.attr("not_continuous_on_left", !this.model.isThereContinuityOnLeft())
        this.$el.attr("not_continuous_on_right", !this.model.isThereContinuityOnRight())
    },

    _onInsertedByPastingChange: function(){
        if(this.model.getInsertedByPasting()){
            this.$el.addClass("insert_by_paste");
        }else{
            this.$el.removeClass("insert_by_paste")
        }
    },

    _onSelectableChange: function(){
        if(this.model.getSelectable()){
            this.$el.removeClass("not_selectable");
        }else{
            this.$el.addClass("not_selectable")
        }
    },

    _onDrop : function(e){
        e.originalEvent.preventDefault();
        this.$el.removeClass("node_drag_over");
        if(e.originalEvent.dataTransfer.files.length > 0){
            const fileDropInfo  = {"file": e.originalEvent.dataTransfer.files[0], "sequencerNodeViewTarget": this};
            this.trigger("fileDroppedBeforeNode", fileDropInfo);
        }else{
            const dropInfo  = {"modelCidOfSequencerNodeBeingDrop": e.originalEvent.dataTransfer.getData("text/plain"), "sequencerNodeViewTarget": this};
            this.trigger("sequencerNodeBeingDroppedBefore", dropInfo);
        }
        Utils.getInstance().triggerShortVibration();
    },


    _onModelHiddenChange : function(){
        this.trigger("onHiddenValueChange", this);
    },

    _onNodePlaying : function(){
        if(this.model.isPlaying()){
            this.$el.addClass("playing");
            this.trigger("sequencerNodeHighlightedAsPlaying", this);
        }else{
            this.$el.removeClass("playing")
        }
    },


    _onNodeSelected: function(){
        if(this.model.isSelected()){
            this.$el.addClass("selected")
        }else{
            this.$el.removeClass("selected")
        }
    },

    _onNodeHightlighted: function(){
        if(this.model.isHighlighted()){
            if(!this.$el.hasClass("highlighted")){
                this.$el.addClass("highlighted")
            }
        }else{
            this.$el.removeClass("highlighted")
        }
    },

    _onNodeHoverHightlighted: function(){
        if(this.model.isHoverHighlighted()){
            if(!this.$el.hasClass("hover_highlighted")){
                this.$el.addClass("hover_highlighted")
            }
        }else{
            this.$el.removeClass("hover_highlighted")
        }
    },

    _onNodeSearchHightlighted: function(){
        if(this.model.isSearchHightlighted()){
            if(!this.$el.hasClass("search_highlighted")){
                this.$el.addClass("search_highlighted")
            }
        }else{
            this.$el.removeClass("search_highlighted")
        }
    },

    _onNodeBeingProcessed: function(){
        if(this.model.getBeingProcessed()){
            if(!this.$el.hasClass("being_processed")){
                this.$el.addClass("being_processed")
            }
        }else{
            this.$el.removeClass("being_processed")
        }
    },

    getParagraphContainerView :function(){
        return this._paragraphContainerView;
    },

    setParagraphContainerView :function(paragraphContainerView){
        this._paragraphContainerView =  paragraphContainerView;
    },

    shouldBeAddedToParagraphContainerOfNextNodeFirst : function(){
        return false;
    },

    getModel: function(){
        return this.model;
    },

    afterRendering : function(){
        this.updateContinuityVisualCueWithSiblings();
        this._onInsertedByPastingChange();
        this._onSelectableChange();
        
    },
    
    

});
export default SequencerNodeView;