
import Backbone from "backbone";
import Crunker from 'crunker';


const PLAY_USING_HTML_AUDIO = window.isSafariBrowser;
const PLAY_HTML_5_AUDIO_RIGHT_AWAY = false;
const AudioBufferPlayer =  Backbone.Model.extend({
    idAttribute: "id",

    constructor: function(attributes, options) {

        this._audioBuffer = attributes.audioBuffer;
        this._audioFileUrl = attributes.audioFileUrl;
        this._audioContext = attributes.audioContext;
        this._whenToPlayInMilliseconds = attributes.whenToPlayInMilliseconds;
        this._offsetStartPositionInMilliseconds = attributes.offsetStartPositionInMilliseconds;
        this._onAudioFinishPlayingHandler = attributes.onAudioFinishPlayingHandler;
        this._onAudioStartingPlayingHandler = attributes.onAudioStartingPlayingHandler;
        this._onAudioErrorPlayingHandler = attributes.onAudioErrorPlayingHandler;
        this._crunkerInstance = attributes.crunkerInstance;
        this._isUsingHtmlAudioByDefault = PLAY_USING_HTML_AUDIO || attributes.useHtmlAudio;
        this.init();

        Backbone.Model.apply(this, [attributes, options]);
    },

    isUsingHtmlAudio : function(){
        return this._isUsingHtmlAudioByDefault || this._audioFileUrl;
    },

    init : function(){

    },

    _getAudioHtmlElement : function(){
        if(!this._audioHtmlElement){
            this._audioHtmlElement = this._createAudioHtmlElement();
        }
        return this._audioHtmlElement;
    },

    _getAudioSourceNode : function(){
        if(!this._audioSourceNode){
            this._audioSourceNode = this._createAudioSourceForPlayback();
        }
        return this._audioSourceNode;
    },

    _getAudioContext: function(){
        return this._audioContext;
    },

    _getCrunkerInstance : function(){
        return this._crunkerInstance ? this._crunkerInstance : new Crunker() ;
    },

    _createAudioHtmlElement : function(){
        let audioHtmlElement = null;
        if(this._audioFileUrl){
            audioHtmlElement = document.createElement('audio');
            audioHtmlElement.controls = true;
            audioHtmlElement.src = this._audioFileUrl
        }else{
            const exportResult = this._getCrunkerInstance().export(this._audioBuffer);
            audioHtmlElement = exportResult.element;
        }
        audioHtmlElement.currentTime = this._offsetStartPositionInMilliseconds ?(this._offsetStartPositionInMilliseconds/ 1000): 0;
        audioHtmlElement.onended = (function(){
            if(!this._wasManuallyStopped){
                this._onAudioFinishPlayingHandler();
            }
            this._cleanUp();
        }).bind(this);

        /*const arrayOfEventToMonitor = ["waiting", "suspend","timeupdate","stalled","playing","play","pause","loadedmetadata","loadeddata","ended","emptied","durationchange","complete","canplaythrough","canplay","audioprocess"];
        arrayOfEventToMonitor.map((function(eventName){
            audioHtmlElement.addEventListener(eventName, (function(event) {
                console.log(' audio event:'+eventName);
            }).bind(this));
        }).bind(this));*/

            audioHtmlElement.addEventListener("error", (function() {
                console.error(this._getAudioHtmlElement().error);
                if(this._onAudioErrorPlayingHandler){
                    this._onAudioErrorPlayingHandler(this._getAudioHtmlElement().error);
                }
            }).bind(this));

        
        
        audioHtmlElement.oncanplaythrough = (function(){
            audioHtmlElement.canPlayThrough = true;
            if(audioHtmlElement.playAndSetCurrentTimeWhenAudioCanPlayThrough){
                audioHtmlElement.playAndSetCurrentTimeWhenAudioCanPlayThrough = false;
                if(this._offsetStartPositionInMilliseconds){
                    audioHtmlElement.currentTime = this._offsetStartPositionInMilliseconds/ 1000;
                }
                audioHtmlElement.play();
            }
            
        }).bind(this)
        audioHtmlElement.onplaying = (function(){
            if(!this._audioStartedHandlerCalled){
                this._audioStartedHandlerCalled = true;
                this._onAudioStartingPlayingHandler();
            }
        }).bind(this);
        audioHtmlElement.load();
        return audioHtmlElement;
    },

    _cleanUp : function(){
        delete this._audioBuffer;
       /* if(this.isUsingHtmlAudio()){
            
        }else{*/
            if(this._getAudioSourceNode()){
                this._getAudioSourceNode().disconnect();
            }
        //}
    },

    _createAudioSourceForPlayback : function(){
        const sourceNode = this._getAudioContext().createBufferSource();
        sourceNode.buffer = this._audioBuffer;
        sourceNode.playbackRate.value = this._getAudioContext().sampleRate / sourceNode.buffer.sampleRate;//Adjust playback speed
        sourceNode.connect(this._getAudioContext().destination);
        sourceNode.onended = (function(){
            if(!this._wasManuallyStopped){
                this._onAudioFinishPlayingHandler();
            }
            this._cleanUp();
        }).bind(this);
        return sourceNode
    },


    start : function(){
        this._wasManuallyStopped = false;
        if(this.isUsingHtmlAudio()){
            //setTimeout((function(){
                if(this._getAudioHtmlElement().canPlayThrough || PLAY_HTML_5_AUDIO_RIGHT_AWAY){
                    this._getAudioHtmlElement().currentTime = this._offsetStartPositionInMilliseconds ?(this._offsetStartPositionInMilliseconds/ 1000): 0;
                    this._getAudioHtmlElement().play();
                }else{
                    this._getAudioHtmlElement().playAndSetCurrentTimeWhenAudioCanPlayThrough = true;
                    this._getAudioHtmlElement().load();
                }
            //}).bind(this), 0)
        }else{
            const playbackStartedAtSecondsInAudioContext = this._whenToPlayInMilliseconds ? this._getAudioContext().currentTime + (this._whenToPlayInMilliseconds/1000) :  this._getAudioContext().currentTime + 0.2; //If starttime is not provided, start 200 millieconds after
            this._getAudioSourceNode().playbackStartedAtSecondsInAudioContext = playbackStartedAtSecondsInAudioContext;
            this._getAudioSourceNode().offsetStartPositionInMilliseconds = this._offsetStartPositionInMilliseconds? this._offsetStartPositionInMilliseconds: 0;
            this._getAudioSourceNode().start(this._getAudioSourceNode().playbackStartedAtSecondsInAudioContext, this._offsetStartPositionInMilliseconds? (this._offsetStartPositionInMilliseconds * this._getAudioSourceNode().playbackRate.value)/1000: 0);
            if(this._onAudioStartingPlayingHandler){
                this._onAudioStartingPlayingHandler();
            }
        }
    },

    stop : function(){
        this._wasManuallyStopped = true;
        if(this.isUsingHtmlAudio()){
            this._cleanUp();
            this._getAudioHtmlElement().pause();
            if(this._getAudioHtmlElement().playAndSetCurrentTimeWhenAudioCanPlayThrough){
                this._getAudioHtmlElement().playAndSetCurrentTimeWhenAudioCanPlayThrough = false;
            }
        }else{
            this._getAudioSourceNode().stop();
        }
    },

    getAudioContextCurrentTime : function(){
        return this._getAudioContext().currentTime;
    },

    canPlayThrough: function(){
        return this.isUsingHtmlAudio()? this._getAudioHtmlElement().canPlayThrough: true;
    },

    getPlaybackTimeInMilliseconds : function(){
        if(this.isUsingHtmlAudio()){
            return this._getAudioHtmlElement().canPlayThrough? (this._getAudioHtmlElement()? this._getAudioHtmlElement().currentTime * 1000 : 0): (this._offsetStartPositionInMilliseconds ?(this._offsetStartPositionInMilliseconds/ 1000): 0);
        }else{
            return this._getAudioSourceNode()? (this._getAudioContext().currentTime  - this._getAudioSourceNode().playbackStartedAtSecondsInAudioContext + this._getAudioSourceNode().offsetStartPositionInMilliseconds/1000 ) * 1000 : 0;

        }
    },




});


export default AudioBufferPlayer; 