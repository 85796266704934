import _ from 'underscore';
import Backbone from "backbone";
import Sequencer from "models/audioEditor/Sequencer";
import SequencerNodeUtils from 'models/audioEditor/SequencerNodeUtils';
import RSVP from "rsvp";

const SequencerRendererUtils = Backbone.Model.extend({
    idAttribute: "id",

    getRenderedAudioBlob: function (projectId, renderWavFile, asVideo, progressReportFunction, alreadyRenderedAudioWAVBlob)
    {
        return this._createSequencerWithProjectId(projectId, progressReportFunction).then(function (sequencer)
        {
            return sequencer.getRenderedAudioBlob(renderWavFile, asVideo, progressReportFunction, alreadyRenderedAudioWAVBlob);
        })
    },

    getSrtText: function (projectId, asVtt, doNotIncludeSpeaker, progressReportFunction)
    {
        return this._createSequencerWithProjectId(projectId, progressReportFunction).then(function (sequencer)
        {
            return sequencer.getSrtText(null, null, progressReportFunction, asVtt, doNotIncludeSpeaker);
        })
    },

    getTranscriptionTextFile: async function (projectId, includeSpeaker, progressReportFunction)
    {
        const sequencer = await this._createSequencerWithProjectId(projectId, progressReportFunction);
        return sequencer.getSelectedNodesTextContent(null, null, includeSpeaker);
    },

    _createSequencerWithProjectId: async function (projectId, progressReportFunction)
    {
        const sequencer = new Sequencer();
        await sequencer.setAndLoadProject(projectId, false, progressReportFunction)
        return sequencer;

    },

    playWithSelectionButtonClick: (sequencer, startSequencerNodeModel, endSequencerNodeModel, playBySentences, includeDeletedSegmentsWhenPossible) =>
    {

        const firstSequencerNodeToPlay = playBySentences? SequencerNodeUtils.getInstance().getPreviousWordSequencerNodeAtBeginningOfSentence(startSequencerNodeModel, 4, true): SequencerNodeUtils.getInstance().getPreviousNWordSequencerNode(startSequencerNodeModel, 4, true);
        const lastSequencerNodeToPlay = playBySentences? SequencerNodeUtils.getInstance().getNextWordSequencerNodeAtEndOfSentence(startSequencerNodeModel, 4, true):SequencerNodeUtils.getInstance().getNextNWordSequencerNode(endSequencerNodeModel, 4, true);
        sequencer.playPauseSequencerRenderingFromNode(firstSequencerNodeToPlay, true, 0, lastSequencerNodeToPlay, null, includeDeletedSegmentsWhenPossible);

    },

    playWithoutSelectionButtonClick: (sequencer, startSequencerNodeModel, endSequencerNodeModel, playBySentences) =>
    {
        const firstSequencerNodeToPlay = playBySentences? SequencerNodeUtils.getInstance().getPreviousWordSequencerNodeAtBeginningOfSentence(startSequencerNodeModel, 4, true): SequencerNodeUtils.getInstance().getPreviousNWordSequencerNode(startSequencerNodeModel, 4, true);
        const lastSequencerNodeToPlay = playBySentences? SequencerNodeUtils.getInstance().getNextWordSequencerNodeAtEndOfSentence(startSequencerNodeModel, 4, true): SequencerNodeUtils.getInstance().getNextNWordSequencerNode(endSequencerNodeModel, 4, true);
        const arrayOfSequencerNodesToIgnore = SequencerNodeUtils.getInstance().getArrayOfSequencerNodesFromStartToEnd(startSequencerNodeModel, endSequencerNodeModel);
        const shouldSequencerNodeBePlayedValidatorFunction = (sequencerNode, nodeSchedulingInfo) =>
        {
            if (arrayOfSequencerNodesToIgnore)
            {
                return !arrayOfSequencerNodesToIgnore.includes(sequencerNode);
            }
            return true;
        }
        sequencer.playPauseSequencerRenderingFromNode(firstSequencerNodeToPlay, true, 0, lastSequencerNodeToPlay, shouldSequencerNodeBePlayedValidatorFunction);
    }



});



const SequencerRendererUtilsInstance = new SequencerRendererUtils();
window.trebble.globalHelpers.SequencerRendererUtils = SequencerRendererUtilsInstance;

export default {
    getInstance: function ()
    {
        return SequencerRendererUtilsInstance;
    }
}; 