import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import RSVP from "rsvp";
import GooglePlusHelper from "models/helper/GooglePlusHelper";
import AppleIDHelper from "models/helper/AppleIDHelper";
import pageTemplate from 'text!../../../templates/loginView/loginPageTemplate.html';
import pageMobileTemplate from 'text!../../../templates/loginView/loginPageTemplateMobile.html';
import popupTemplate from 'text!../../../templates/loginView/loginPopupTemplate.html';
import pagei18n from 'i18n!nls/loginPagei18n';
import popupi18n from 'i18n!nls/loginPopupi18n';
import Mousetrap from 'mousetrap';

const NO_HEADER = window.waitForCordovaToLoad? true: false;
const USE_NEW_GOOGE_API_ON_WEB = true;
const LoginPageView = Backbone.View.extend({

    template : _.template(window.waitForCordovaToLoad?pageMobileTemplate: pageTemplate),
    ptemplate : _.template(popupTemplate),

    initialize : function(options) {
        this._removeOnHide = true;
        this._showAsPopup = options? options.showAsPopup: false;
        this._clickingOnSocialLoginWillSignupAutomatically =  options? options.clickingOnSocialLoginWillSignupAutomatically : false;
        this._doNotAllowGuestLogin = options? options.doNotAllowGuestLogin : false;
        this.isUserpreviouslyLoggedInAsGuest = options? options.isUserpreviouslyLoggedInAsGuest: false;
        this._mousetrapObj = null;
        this._enabledColor = "#e9605d";
        this._disabledColor = window.waitForCordovaToLoad?"#e9605d": "#cccccc";
    },

    navigateToPreviousPage : function(){
        /*if(PersistentModels && PersistentModels.getInstance().getRouter()  ){
            PersistentModels.getInstance().getRouter().navigateToPreviousPage();
        }else{*/
            window.history.back();  
            //}
            
        },
        
        events : {
            "click #header-back-button" : "navigateToPreviousPage",
            "click #LoginBtn.enabled" : "onLoginBtnTapped",
            "click #SignUpBtn" : "onSignupBtnTapped",
            "click #requestInviteBtn" : "onRequestInviteBtnTapped",
            "click #UpdateGuessProfileBtn": "onSignupBtnTapped",
            "click #LoginWithFacebookBtn" : "onLoginWithFacebookBtnTapped",
            "click #LoginWithAppleBtn" : "onLoginWithAppleBtnTapped",
            "click #continueAsGuessBtn": "onContinueAsGuessButtonTapped",
            "click .forgotPasswordLink" : "onForgotPasswordLinkClicked",
            "click #showEmailLoginFormBtn": "showEmailLoginFormBtn"
        },
        
        onLoginBtnTapped : function(){
            
            const username  = this.$el.find("#usernameFieldInput").val();
            const password  =  this.$el.find("#passwordField").val();
            const data = {};
            data.username =  username?username.trim(): username;
            data.password =  password;
            this.trigger("loginBtnTapped", data);
        },
        onContinueAsGuessButtonTapped: function(){
            this.trigger("continueAsGuessBtnTapped");
        },

        onForgotPasswordLinkClicked: function(){
            this.trigger("forgotPasswordLinkClicked");
        },
        onLoginWithFacebookBtnTapped: function(){
            this.trigger("loginWithFacebookBtnTapped");
        },

        onLoginWithAppleBtnTapped: function(){
            this.trigger("loginWithAppleBtnTapped");
        },

        showEmailLoginFormBtn : function(){
            this.$el.find(".loginFormWrapper").removeClass("hideLoginForm");
        },
        
        
        
        onSignupBtnTapped : function(){
            this.trigger("signupButtonTapped");
        },

        onRequestInviteBtnTapped : function(){
            this.trigger("requestInviteBtnTapped");
        },
        
        
        onBeforeRemoveFromPage : function(){
            this.trigger("beforeRemoveFromPage", this);
            if(this._mousetrapObj){
                this._mousetrapObj.unbind("enter");
            }
        },
        
        onRemovedFromPage : function(){
            this.undelegateEvents();
            this.$el.removeData().unbind(); 
            window.removeEventListener('resize', window[this._onViewportHeightChangedFunctionRef] );
            this.trigger("removedFromPage", this);
        },

        onFieldValueChanged : function(event){
            if(this.isAllMandatoryFieldsFilled()){
                this.trebbleLogo$el.css("color",this._enabledColor);
                this.loginBtn$el.removeClass("disabled").addClass("enabled");
            }else{
                this.trebbleLogo$el.css("color",this._disabledColor);
                this.loginBtn$el.removeClass("enabled").addClass("disabled");
            }
        },
        isAllMandatoryFieldsFilled : function(){
            return this.$el.find("#usernameFieldInput").val() && this.$el.find("#passwordField").val();
        },
        
        _onViewportHeightChanged : function(){
            const headerHeight = 54 ;
            this.$el.find(".ui-content").css("height",(NO_HEADER? window.getDocumentClienHeight() : window.getDocumentClienHeight() - headerHeight)+ "px");
        },
        
        onUserNameFieldKeyUp :function(){
            this.usernameField$el.val( this.usernameField$el.val().replace(/ /g, "_").toLowerCase());
        },

        close : function(){
            const p = new RSVP.Promise((function(resolve, reject){
                try{
                    this.$el.on({
                        popupafterclose: (function() {
                            try{
                                resolve();
                            }catch(error){
                                reject(error);
                            }
                            
                        }).bind(this)});
                }catch(error){
                    reject(error);
                }
            }).bind(this));
            this.$el.popup('close' );
            return p;
        },

        _onGoogleLoginSuccess : function(googleUser){
            this.trigger("loginWithGoogleBtnSuccess",googleUser);
        },

        _onGoogleLoginFailed : function(error){
            this.trigger("loginWithGoogleBtnFailed",error);
        },

        /*_loadGAPI : function(){
            return new Promise((resolve, reject) => {
                window._loadScriptipt('https://apis.google.com/js/client')
                  .then(() => {
                    // Check if the Google API client is available
                    if (typeof gapi !== 'undefined') {
                      // Assuming _onGAuthLoadedPromise is a global variable or defined elsewhere
                      // You can return it if it's available
                      resolve(_onGAuthLoadedPromise);
                    } else {
                      reject(new Error('Failed to load Google API client'));
                    }
                  })
                  .catch(reject);
              });

        },*/

        _onPageShow: function(){
            $.mobile.resetActivePageHeight(); 
        },
        
        render : function(){
            const options = {};
            options.isUserpreviouslyLoggedInAsGuest = this.isUserpreviouslyLoggedInAsGuest;
            options.doNotAllowGuestLogin = this._doNotAllowGuestLogin;
            options.ti18n = this._showAsPopup? popupi18n : pagei18n ;
            options.clickingOnSocialLoginWillSignupAutomatically = this._clickingOnSocialLoginWillSignupAutomatically;
            if(!this._showAsPopup){
                this.$el.html(this.template(options));
                this.setElement(this.$el.find("div").first());
                this.$el.page();
                this.$el.on("pagehide", this._onPageHide.bind(this));
                const functionUniqueGlobalName = "onViewportHeightChangeForLoginPage"+ (new Date()).getTime();
                window[functionUniqueGlobalName] =  this._onViewportHeightChanged.bind(this)
                this._onViewportHeightChangedFunctionRef = functionUniqueGlobalName;
                window.addEventListener('resize', window[functionUniqueGlobalName]  );
                this.trebbleLogo$el =  this.$el.find(".icon-trebble-icon-2ton");
                this.loginBtn$el =  this.$el.find("#LoginBtn");
                //this.$el.find("#LoginWithFacebookBtn").hide();
                if(window.waitForCordovaToLoad ){
                    if(window.isIOSMobileDevice){
                        this.$el.find("#LoginWithAppleBtn").insertBefore(this.$el.find("#LoginWithFacebookBtn"))
                    }else{
                        this.$el.find("#LoginWithAppleBtn").hide();
                        this.$el.find("#LoginWithGoogleBtn").insertBefore(this.$el.find("#LoginWithFacebookBtn"))
                    }
                }
                this.usernameField$el = this.$el.find("#usernameFieldInput");
                //this.usernameField$el.on("click blur keyup input", this.onUserNameFieldKeyUp.bind(this));
                this.$el.find(".formField").on("click blur keyup input", this.onFieldValueChanged.bind(this));
                this._mousetrapObj = Mousetrap(this.$el.find("#loginForm").get(0)).bind('enter', (function(e, combo) {
                    this.onLoginBtnTapped();
                }).bind(this));
                const loginWithGoogleBtn$el = this.$el.find("#LoginWithGoogleBtn");
                if(!loginWithGoogleBtn$el.hasClass("hide")){
                    loginWithGoogleBtn$el.addClass("hide");
                }

                this.$el.on("pageshow", this._onPageShow.bind(this));


                

                GooglePlusHelper.getInstance().attachClickHandlerToElementForLoginIntent(loginWithGoogleBtn$el.get(0), {},this._onGoogleLoginSuccess.bind(this), this._onGoogleLoginFailed.bind(this), USE_NEW_GOOGE_API_ON_WEB, this.$el.find(".google_button_container").get(0),{text:"signin_with"}).then((function(){
                    if(!USE_NEW_GOOGE_API_ON_WEB || window.isInMobileAppContext()){
                        loginWithGoogleBtn$el.removeClass("hide");
                    }
                }).bind(this));
                
                this.usernameField$el.focus();
                this._onViewportHeightChanged();
                this.onFieldValueChanged();
                return RSVP.Promise.resolve(this);
            }else{
                
                return window.trebble.pageContainerReadyPromise.then((function(){
                    this.$el.html(this.ptemplate(options));
                    this.setElement(this.$el.find("div").first());
                    const elementId = this.$el.attr('id');
                    
                    this.$el.on({
                        popupbeforeposition: (function() {
                            const maxHeight = $( window ).height() - 0 + "px";
                            const width = $( window ).width() - 0 + "px";
                            this.$el.css( "height", maxHeight );
                            this.$el.css( "width", width );
                            
                        }).bind(this)
                    });
                    this.$el.on({
                        popupafterclose: (function() {
                            this.onRemovedFromPage();
                            this.trigger("closed");
                            $(".ui-page-active").css("position",this._currenPagePositionValue);
                            this.$el.find("paper-input").detach();
                            this.$el.find("paper-input-decorator").detach();
                            this.remove();
                            $("#loginPoppup-popup").remove();
                            $("#loginPoppup-placeholder").remove();
                            $("#loginPoppup-screen").remove();
                            this.undelegateEvents();
                            
                        }).bind(this)});
                    $.mobile.pageContainer.prepend(this.$el);
                    this.$el.popup({transition:"fade", dismissible: false,  tolerance: "0,0"});
                    this.$el.find("#closeBtn").on("click", this.close.bind(this));
                    if(elementId)
                    {
                        const screenPopup$el = $("#"+elementId + "-screen");
                        $.mobile.pageContainer.remove("#"+elementId + "-screen");
                        $.mobile.pageContainer.prepend(screenPopup$el);
                    }
                    
                    this.$el.enhanceWithin();
                    this.$el.on("pageshow", this._onPageShow.bind(this));
                    this.$el.popup('open' );
                    
                    const functionUniqueGlobalName = "onViewportHeightChangeForLoginPage"+ (new Date()).getTime();
                    window[functionUniqueGlobalName] =  this._onViewportHeightChanged.bind(this)
                    this._onViewportHeightChangedFunctionRef = functionUniqueGlobalName;
                    window.addEventListener('resize', window[functionUniqueGlobalName]  );
                    this.trebbleLogo$el =  this.$el.find(".icon-trebble-icon-2ton");
                    this.loginBtn$el =  this.$el.find("#LoginBtn");
                    
                    this.usernameField$el = this.$el.find("#usernameFieldInput");
                    this.usernameField$el.on("click blur keyup input", this.onUserNameFieldKeyUp.bind(this));
                    this.$el.find(".formField").on("click blur keyup input", this.onFieldValueChanged.bind(this));
                    this._mousetrapObj = Mousetrap(this.$el.find("#loginForm").get(0)).bind('enter', (function(e, combo) {
                        this.onLoginBtnTapped();
                    }).bind(this));

                    const loginWithGoogleBtn$el = this.$el.find("#LoginWithGoogleBtn");
                    if(!loginWithGoogleBtn$el.hasClass("hide")){
                        loginWithGoogleBtn$el.addClass("hide");
                    }

                    GooglePlusHelper.getInstance().attachClickHandlerToElementForLoginIntent(loginWithGoogleBtn$el.get(0), {},this._onGoogleLoginSuccess.bind(this), this._onGoogleLoginFailed.bind(this), USE_NEW_GOOGE_API_ON_WEB, this.$el.find(".google_button_container").get(0),{text:"signin_with"}).then((function(){
                        if(USE_NEW_GOOGE_API_ON_WEB || window.isInMobileAppContext()){
                            loginWithGoogleBtn$el.removeClass("hide");
                        }
                    }).bind(this));

                    
                    this.usernameField$el.focus();
                    this._onViewportHeightChanged();
                    this.onFieldValueChanged();
                    return this;
                }).bind(this))

}


},
setRemoveOnHide : function(removeOnHide){
this._removeOnHide =removeOnHide;
},
_onPageHide : function(){

if(this._removeOnHide)
{
//PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._listenerViewportKey);
this.onBeforeRemoveFromPage();
this.remove();
this.onRemovedFromPage();
}
},



});
export default LoginPageView;