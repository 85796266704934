import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import owlCarousel from "owlcarousel";
import DynamicPageView from "views/common/DynamicPageView";
import Utils from "models/helper/Utils";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import ti18n from "i18n!nls/ExplorePagei18n";
import PersistentModels from "services/PersistentModels";
import ExplorePageTemplate from 'text!../../../templates/explore/ExplorePageTemplate.html';

const DISPLAY_AUTO_PREVIEW_TOOLTIP = false;
const DEFAULT_BACKGROUND_IMAGE_URL = window.getResourceURLAccordingToLocationProtocol("/img/blackWhitebackgroundplayer.jpg");
const Rollout = RolloutHelper.getInstance();

const LISTEN_TO_SCROLLS_ON_LIST_VIEW = Rollout.isFeatureEnabled(Rollout.FEATURES.TRACK_SCROLLS_IN_BROWSE_PAGE_LIST);
const SHOW_SEARCH_BUTTON = Rollout.getFeatureVariable(Rollout.FEATURES.SHOW_FOOTER_MENU, Rollout.FEATURES.SHOW_FOOTER_MENU.variables.show_search_btn_in_browse_page, true);

const ExplorePageView = DynamicPageView.extend({

    //template : _.template(ExplorePageTemplate),

    initialize : function(options) {
        const dynamicPageOptions = {};
        this.model =  options? options.model: {};
        this.genreSelectFieldView =  options? options.genreSelectFieldView : null;
        this.langaugeSelectFieldView =  options? options.langaugeSelectFieldView : null;
        this.citySelectFieldView = options? options.citySelectFieldView : null;
        this._pageId = "Browse";
        this._showListView = false;
        dynamicPageOptions.pageViewTemplate = ExplorePageTemplate;
        dynamicPageOptions.model = this.model;
        dynamicPageOptions.pageId = this._pageId;
        dynamicPageOptions.headerFullscren = true;
        dynamicPageOptions.persistent = true;
        dynamicPageOptions.onPageShow = this.onPageShow.bind(this);
        dynamicPageOptions.onPageHide = this.onPageHide.bind(this);
        dynamicPageOptions.pageTemplateOptions = {"ti18n":ti18n};
        this._turnOnAutoPreviewWasAcknoledgeByUser = false;
        this._whiteTheme = false;
        PersistentModels.getInstance().getRouter().registerForViewportHeightChange(this._pageId,this._onViewportHeightChanged.bind(this));
        return DynamicPageView.prototype.initialize.call(this, dynamicPageOptions);  
    },

    events : {
        "click #homeHeaderButton" : "onHomeHeaderButtonClicked",
        "click #helpHeaderButton" : "onHelpHeaderButtonClicked",
        "click #showCarouselViewButton" : "onShowCarouselViewButtonClicked",
        "click #showListViewButton" : "onShowListViewButtonClicked",
        "click #searchBtn": "showSearchPage",
    },

    showSearchPage: function(){
        this.trigger("showSearchPage");
    },

    onHomeHeaderButtonClicked : function(){
        this.trigger("showHomePopup");
    },

    onHelpHeaderButtonClicked : function(){
        this.trigger("showHelpPage");
    },


    onShowCarouselViewButtonClicked : function(){
        this.trigger("showCarouselView");
    },

    onShowListViewButtonClicked : function(){
        this.trigger("showListView");
    },

    _listenForScrollEventOnListView: function(){
        let isScrolling = null;
        this._scrollTopBeforeSCrolling = null;
        this.trebbleListWrapper$el.scroll((function ( event ) {
            if(this._scrollTopBeforeSCrolling === null){
                this._scrollTopBeforeSCrolling = this.trebbleListWrapper$el.scrollTop();
            }
            window.clearTimeout( isScrolling );
            isScrolling = setTimeout((function() {
                const amountOfSroll = this.trebbleListWrapper$el.scrollTop() - this._scrollTopBeforeSCrolling;
                this._scrollTopBeforeSCrolling = null;
                this.trigger("userScrolledListView", amountOfSroll);
            }).bind(this), 66);

        }).bind(this))
    },

    _onViewportHeightChanged : function(newViewPortHeigh, forceResizing) {
        if(PersistentModels.getInstance().getRouter().isEqualToCurrentPageUrl("#ExplorePage") || forceResizing){
            if (this.el) {
                const optionsHeight = 60;
                const adjustedListContainerHeight = (newViewPortHeigh - optionsHeight ) ;
                if(this.trebbleListWrapper$el){
                    this.trebbleListWrapper$el.css("height", adjustedListContainerHeight + "px")
                }

            }
        }
    },

    preloadBackgroundImage : function(){
        return 	Utils.getInstance().preloadImageUsingUrl(DEFAULT_BACKGROUND_IMAGE_URL);
    },

    navigateToPreviousPage : function(){
        PersistentModels.getInstance().getRouter().navigateToPreviousPage();
    },

    onBeforePageInitialized : function(){

    },

    hideInfoHelperBox:function(){
        this.$el.find("#explorePlaylistsWrapper").hide();
    },

    hideAllSubcribeTooltips : function(){
        if(!this.$el.hasClass("do_not_show_subscribe_tooltip")){
            this.$el.addClass("do_not_show_subscribe_tooltip");
        }
    },

    setCarouselView : function(carouselView){
        this.hideInfoHelperBox();
        if(this.carouselView ){
            this.carouselView.destroy();
        }
        this.carouselView = carouselView;
        if(this.carouselView ){
            this.$el.find("#trebbleCarouselWrapper").first().append(this.carouselView.$el);
            this.carouselView.initSwiperContainer();
        }
    },

    setListView : function(listView){
        this.hideInfoHelperBox();
        this.listView = listView;
        if(this.listView ){
            this.trebbleListWrapper$el.first().append(this.listView.$el);
        }
    },

    showListView : function(showListView){
        this._showListView =  !!showListView;
        this.$el.attr("showListView",this._showListView?"true":"false");
        if(this._showListView){
            this.$el.removeClass("lighttext");
            this.$el.removeClass("transparentBackground");
            PersistentModels.getInstance().getRouter().setCanPlayerFooterBeShowOnActivePage(true, this._whiteTheme?false:(PersistentModels.getInstance().getRouter().isFooterMenuUsedInApp()? false: true));
        }else{
            if(!this.$el.hasClass("lighttext")){
                this.$el.addClass("lighttext");
            }
            if(!this.$el.hasClass("transparentBackground")){
                this.$el.addClass("transparentBackground");
            }
            PersistentModels.getInstance().getRouter().setCanPlayerFooterBeShowOnActivePage(true, this._whiteTheme?false:(PersistentModels.getInstance().getRouter().isFooterMenuUsedInApp()? false: true));
        }
    },

    setWhiteTheme: function(whiteThemeOn){
        this._whiteTheme = whiteThemeOn;
        if(whiteThemeOn){
            this.$el.removeClass("darkTheme");
            if(Utils.getInstance().isPageDisplayed(this.$el)){
                PersistentModels.getInstance().getRouter().setCanPlayerFooterBeShowOnActivePage(true, (PersistentModels.getInstance().getRouter().isFooterMenuUsedInApp()? false: true));
            }
        }else{
            if(!this.$el.hasClass("darkTheme")){
                this.$el.addClass("darkTheme");
                if(Utils.getInstance().isPageDisplayed(this.$el)){
                    PersistentModels.getInstance().getRouter().setCanPlayerFooterBeShowOnActivePage(true, (PersistentModels.getInstance().getRouter().isFooterMenuUsedInApp()? false: true));
                }
            }
        }

    },


    isPageCurrentlyDisplayed : function(){
        return this.$el.hasClass("ui-page-active");
    },

    onTurnOnAutoPreviewTooltipClickedOn : function(){
        this.showHideTurnOnAutoPreviewTooltipIfApplicable(false, true);
    },

    setThreeSelectorDisplayed : function(threeSelectorDisplayed){
        if(threeSelectorDisplayed){
            if(!this.$el.hasClass("threeSelectorDisplayed")){
                this.$el.addClass("threeSelectorDisplayed")
            }
        }else{
            if(this.$el.hasClass("threeSelectorDisplayed")){
                this.$el.removeClass("threeSelectorDisplayed")
            }
        }
    },

    showHideTurnOnAutoPreviewTooltipIfApplicable: function(show, wasAcknowledgeByUser){
        const turn_explore_auto_preview_tooltip$el = this.$el.find(".turn_explore_auto_preview_tooltip");
        if(DISPLAY_AUTO_PREVIEW_TOOLTIP && (!LocalStorageHelper.getInstance().isAutoPreviewTooltipDiscardedByUser() && !this._turnOnAutoPreviewWasAcknoledgeByUser && show)){
            turn_explore_auto_preview_tooltip$el.removeClass("hide");
        }else{
            if(!turn_explore_auto_preview_tooltip$el.hasClass("hide")){
                turn_explore_auto_preview_tooltip$el.addClass("hide");
                this._turnOnAutoPreviewWasAcknoledgeByUser = wasAcknowledgeByUser;
                LocalStorageHelper.getInstance().setAutoPreviewTooltipDiscardedByUser();
            }
        }
    },

    setBackgroundImage : function(imageUrl){

        if(!imageUrl){
            this.$el.find("#backgroundBlurImage").css("background-image","url("+window.getResourceURLAccordingToLocationProtocol("/img/blackWhitebackgroundplayer.jpg")+")");
        }else{
            //const bludImageUrl = window.trebble.globalHelpers.getBlurImageUrl(imageUrl)
            const bludImageUrlWithLimitedSize = window.trebble.globalHelpers.getResizeImageUrl(imageUrl, 640,640 );
            //this.$el.find("#backgroundBlurImage").css("background-image","url("+ bludImageUrlWithLimitedSize+")");
            this._currentBackgroundImageBlur = bludImageUrlWithLimitedSize;
            Utils.getInstance().preloadImageUsingUrl(bludImageUrlWithLimitedSize).then((function(){
                if(this.self._currentBackgroundImageBlur == this.bludImageUrlWithLimitedSize){
                    this.self.$el.find("#backgroundBlurImage").css("background-image","url("+ bludImageUrlWithLimitedSize+")");
                }
            }).bind({"self":this, "bludImageUrlWithLimitedSize": bludImageUrlWithLimitedSize}));


        }

    },

    onAutoPreviewFieldChanged : function(elem, args, notTriggerByUserInteration){
        const autoPreviewValueString = this.autoPreviewField$el.val();
        const autoPreviewValueBoolean = autoPreviewValueString && autoPreviewValueString ===  "true"?true:false;
        if(autoPreviewValueBoolean && !notTriggerByUserInteration){
            this.onTurnOnAutoPreviewTooltipClickedOn();
        }else{
            this.showHideTurnOnAutoPreviewTooltipIfApplicable(!autoPreviewValueBoolean, false);
        }
        this.trigger("autoPreviewEnabled",autoPreviewValueBoolean);
    },

    forceLoadTrebbleCoverImage:function(){
        this.trebbleListWrapper$el.trigger("scrollstop");
    },

    forceTrebbleListToScrollToTop : function(){
        this.trebbleListWrapper$el.scrollTop(0);
    },

    setLoadingTrebbleList : function(loadingTrebbleList){
        this._loadingTrebbleList = loadingTrebbleList;
        if(this._loadingTrebbleList){
            if(!this.$el.hasClass("loading_trebble_list")){
                this.$el.addClass("loading_trebble_list");
            }
        }else{
            this.$el.removeClass("loading_trebble_list");
        }
    },

    onAfterPageInitialized : function(){
        //this._initializeCarousels();
        this.autoPreviewField$el = this.$el.find("#autoPreviewField");
        this.autoPreviewField$el.on("change", this.onAutoPreviewFieldChanged.bind(this));
        if(this.langaugeSelectFieldView){
            this.$el.find("#filtersWrapper").first().append(this.langaugeSelectFieldView.$el);
        }
        if(this.genreSelectFieldView){
            this.$el.find("#filtersWrapper").first().append(this.genreSelectFieldView.$el);
        }
        if(this.citySelectFieldView){
            this.$el.find("#filtersWrapper").first().append(this.citySelectFieldView.$el);
        }
        this.$el.find(".turn_explore_auto_preview_tooltip").on("click", this.onTurnOnAutoPreviewTooltipClickedOn.bind(this));
        this.onAutoPreviewFieldChanged(null, null,true);
        this._fixedBugWithAutoPreviewField();
        this.showListView(this._showListView);
        this.trebbleListWrapper$el = this.$el.find("#trebbleListWrapper");
        Utils.getInstance().initJqueryLazyLoadingPluginOnElemNew(this.trebbleListWrapper$el, "img.lazy", this.trebbleListWrapper$el,"scrollstop");
        this._onViewportHeightChanged(PersistentModels.getInstance().getRouter().getViewportHeight(), true);
        if(LISTEN_TO_SCROLLS_ON_LIST_VIEW){
            this._listenForScrollEventOnListView();
        }
        if(!SHOW_SEARCH_BUTTON){
            this.$el.find("#searchBtn").hide();
        }
        if(window.waitForCordovaToLoad && Rollout.isFeatureEnabled(Rollout.FEATURES.DISABLE_SWITCH_TO_CAROUSEL_VIEW_IN_BROWSE_PAGE_ON_MOBILE)){
            this.$el.find("#showCarouselViewButton").hide();
        }
    },

    _fixedBugWithAutoPreviewField : function(){
        this.$el.find("a.ui-flipswitch-on").removeAttr("href");
    },
    onPageShow : function(){
        if(this._showListView){
            PersistentModels.getInstance().getRouter().setCanPlayerFooterBeShowOnActivePage(true, this._whiteTheme?false:true);
        }else{
            PersistentModels.getInstance().getRouter().setCanPlayerFooterBeShowOnActivePage(true, this._whiteTheme?false:true);
        }
        this.$el.removeClass("hideAllContent");
        this.trigger("pageShow");
    },

    onPageHide : function(){
        this.trigger("pageHide");
        if(!this.$el.hasClass("hideAllContent")){
            this.$el.addClass("hideAllContent");
        }
        
    },
    onBeforeRemoveFromPage : function(){
        DynamicPageView.prototype.onBeforeRemoveFromPage.call(this); 
        PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._pageId);
    },

});
export default ExplorePageView;