import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import SequencerNodeView from "views/audioEditor/sequencerNodeView/SequencerNodeView";
import SequencerPuncuationNodeTemplate from 'text!../../../../templates/audioEditor/sequencerNodeView/SequencerPuncuationNodeTemplate.html';
import ti18n from 'i18n!nls/Sequenceri18n';
import RSVP from 'rsvp';


const SequencerPunctuationNodeView = SequencerNodeView.extend({



    constructor : function(attributes, options) {
        SequencerNodeView.apply(this, [attributes, options]);
        this.compiledTemlate =  _.template(SequencerPuncuationNodeTemplate);
    },
    
    events : _.extend({

    }, SequencerNodeView.prototype.events),

    render : function() {

        this.$el.html(this.compiledTemlate({"model": this.model}));
        if(this.model.getAudioSegment().getContent() === '.'){
            this.setElement(this.$el.find("fullstop").first());
        }else{
            this.setElement(this.$el.find("span").first());
        }
        this.afterRendering();

        return this;
    }

});
export default SequencerPunctuationNodeView;