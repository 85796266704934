import Backbone from "backbone";
import SongSaveWrapper from "collections/savewrapper/SongSaveWrapper";
import TrebbleClientAPI from "models/helper/TrebbleClientAPI";
import TrebbleDBHelper from "models/helper/TrebbleDBHelper22";
import RSVP from "rsvp";

const _TREBBLE_INDEX_DB_NAME = "trebbleIndexDB";
const _INDEX_DB_VERSION = 1;

const TrebbleIndexDBHelper = Backbone.Model.extend({
  default: {
    db: null,
  },

  initialize: function () {
    this.initialized = true;
  },

  isReady: function () {
    return RSVP.Promise.resolve(this.initialized);
  },

  notifyListeners: function () {
    for (let i in this.listenersReady) {
      const listener = this.listenersReady[i];
      listener();
    }
  },

  getAllAbums: function () {
    return TrebbleDBHelper.getInstance().getAll("album", null, "album");
  },

  getAllArtists: function () {
    return TrebbleDBHelper.getInstance().getAll("artist", null, "artistName");
  },

  //API Method
  /* addIdentifiedSongAsFavorite : function( basicSongInfo , idifiedInfo){
        
      self = this;
      const song = null;
      const album = null;
      const artist = null;
      return new RSVP.Promise(function(resolve, reject){
         
            try{
              song = basicSongInfo;
           if(!song && idifiedInfo)
    	  {
	          song = {};
	          song.title  =  idifiedInfo.title;
	          song.artist = idifiedInfo.artist;
	          song.album = idifiedInfo.album;
	          song.albumId = idifiedInfo.albumId;
	          song.dateAdded = new Date();
	          song.dateModified =  new Date();
	          song.uri =  "gracenoteId:" + idifiedInfo.gracenoteId;
	          song.type = "remote";
	          song.favorite = "true";
	          song.duration = idifiedInfo.duration;
    	  }
           song.idified = "false";
           song.synced = "false";
           song.deleted = "false";
           song.idifiedTitle = "";

            promiseArray = [];
         
            if(idifiedInfo)
            	{
              song.idified = "true";
              song.idifiedSuccess = "true";
              song.idifiedTitle = idifiedInfo.title;
              song.idifiedArtist = idifiedInfo.artist;
              song.idifiedAlbum = idifiedInfo.album;
              song.idifiedArtistId = idifiedInfo.artistId;
             // song.idifiedArtisId = idifiedInfo.artist+"<gracenoteId>";
              song.songGracenoteId = idifiedInfo.gracenoteId;
              song.idifiedAlbumArtURL = idifiedInfo.albumArtUrl;
              song.idifiedAlbumId = idifiedInfo.albumId;
             // song.idifiedAlbumArtBase64 = idifiedInfo.coverArtBase64;
            //  song.idifiedSongLinkDataArray = idifiedInfo.songLinkData ;
             // song.idifiedAlbumLinkDataArray = idifiedInfo.albumLinkData ;
              song.idifiedInfo = idifiedInfo ;
            	}


            if(idifiedInfo)
            	{
	               album = {
	                "albumId" : idifiedInfo.albumId,
	                "album" : idifiedInfo.album,
	                "artist" : idifiedInfo.artist,
	                "artistId" : idifiedInfo.artistId,
	                //"artistId" : idifiedInfo.artist+"<gracenoteId>",
	                "year" : idifiedInfo.year,
	                "albumArtBase64" : idifiedInfo.coverArtBase64,
	                "idifiedAlbumLinkDataArray" : idifiedInfo.albumLinkData ,
	                "albumArtURL" : idifiedInfo.albumArtUrl
	                
	              };
	               artist = {
	                      "artistId" : idifiedInfo.artistId,
	                      //"artistId" : idifiedInfo.artist+"<gracenoteId>",
	                      "artistName" : idifiedInfo.artist
	                    };
            }else{
            	if(basicSongInfo)
            	{
            		 album = {
        	                "albumId" : basicSongInfo.albumId,
        	                "album" : basicSongInfo.album,
        	                "artist" : basicSongInfo.artist,
        	                "artistId" : basicSongInfo.artistId,
        	                //"artistId" : idifiedInfo.artist+"<gracenoteId>",
        	                "year" : basicSongInfo.year,
        	                "albumArtBase64" : basicSongInfo.coverArtBase64,
        	                "albumArtURL" : basicSongInfo.albumArtUrl
        	                
        	              };
            		 artist = {
            	              "artistId" : basicSongInfo.artistId,
            	              //"artistId" : idifiedInfo.artist+"<gracenoteId>",
            	              "artistName" : basicSongInfo.artist
            	            };
            	}
            }
            
  
            if(song && album && artist)
            {
            	promiseArray.push(TrebbleDBHelper.getInstance().put("song", song));
            	promiseArray.push(TrebbleDBHelper.getInstance().put("album", album));
            	promiseArray.push(TrebbleDBHelper.getInstance().put("artist", artist));
           	}
            
            return RSVP.Promise.all(promiseArray).then(resolve);
         
        }catch(error){
          reject(error);
        }
         
    }).then((function(){
    	 this.syncAllSongs();
    	 return {"song": song, "album",album,"artist":artist};
    }).bind(this));
   
    },
*/
  //API Method
  /*  updateSongWithIdifiedInfo : function(songURI, idifiedInfo){
    	self = this;
    	return new RSVP.Promise(function(resolve, reject){
    		try{
    	
    			const song = null;
    			//const album = null
    			TrebbleDBHelper.getInstance().find("song",songURI).then(function(songFound){
    				song = songFound;
    				return RSVP.Promise.resolve(songFound);	
    			}).then(function(songFound){
    				return self.getAlbumWithAlbumId(songFound.albumId);
    			}).then(function(albumFound){

    				promiseArray = [];
    				if(idifiedInfo)
    				{
    					song.idified = "true";
    					song.idifiedSuccess = "true";
    					song.synced = "false";
   						song.idifiedTitle = idifiedInfo.title;
   						song.idifiedArtist = idifiedInfo.artist;
   						song.idifiedAlbum = idifiedInfo.album;
   						song.idifiedArtisId = idifiedInfo.artistId;
   						song.songGracenoteId = idifiedInfo.gracenoteId;
   						song.idifiedAlbumArtURL = idifiedInfo.albumArtUrl;
   						song.idifiedAlbumId = idifiedInfo.albumId;
   						song.idifiedInfo = idifiedInfo ;


   						const album = {
   							"albumId" : idifiedInfo.albumId,
							"album" : idifiedInfo.album,
							"artist" : idifiedInfo.artist,
							"artistId" : idifiedInfo.artistId,
							//"artistId" : idifiedInfo.artist+"<gracenoteId>",
							"year" : idifiedInfo.year,
							"idifiedAlbumLinkDataArray" : idifiedInfo.albumLinkData ,
 							"albumArtBase64" : idifiedInfo.coverArtBase64,
   							"albumArtURL" : idifiedInfo.albumArtUrl
   						};
   					
	
						const artist = {
							"artistId" : idifiedInfo.artistId,
							//"artistId" : idifiedInfo.artist+"<gracenoteId>",
							"artistName" : idifiedInfo.artist
						};
						//window.alertErrorMessage(22);
						promiseArray.push(TrebbleDBHelper.getInstance().put("song", song));
	  					promiseArray.push(TrebbleDBHelper.getInstance().put("album", album));
	  					promiseArray.push(TrebbleDBHelper.getInstance().put("artist", artist));
   					}else{
   						song.idified = "true";
   						song.idifiedSuccess = "false";
   						song.synced = "false";
   						promiseArray.push(TrebbleDBHelper.getInstance().put("song", song));
   					}
   					return RSVP.Promise.all(promiseArray);
    			}).then(resolve).catch(reject);
    		}catch(error){
    			reject(error);
    		}
		});

    },*/

  /*toggleMarkSongAsFavorite : function(songURI)
    {
      return new  RSVP.Promise(function(resolve, reject){
        try{
          const transaction = self.getTransaction(["song"],"readwrite");
          this.getSongWithURI(songURI, transaction).then(function(songFound){
            if(songFound){
              if(songFound.favorite == "true"){
                songFound.favorite  = "false";
              }else{
                songFound.favorite  = "true";
              }
              const songObjectStore = transaction.objectStore("song");
              return this.putToObjectStore(songObjectStore, songFound).then(resolve).catch(reject);
            }else{
              return resolve();
            }
          });
        }catch(error){
          reject(error);
        }
      });
    },*/

  getSongWithURI: function (songURI) {
    return TrebbleDBHelper.getInstance().find("song", songURI);
  },

  getAlbumWithAlbumId: function (albumId) {
    return TrebbleDBHelper.getInstance().find("album", albumId);
  },

  getArtistWithArtistId: function (artistId) {
    return TrebbleDBHelper.getInstance().find("artist", artistId);
  },

  //API Method
  /*  updateSongWithAlbumArtBase64 : function(albumId,idifiedAlbumArtBase64,idifiedAlbumArtBase64Blur){
    	const self = this;
    	return new RSVP.Promise((function(resolve, reject){
    
    		this.getAlbumWithAlbumId(albumId).then((function(album){
    			if(album){
    				album.idifiedAlbumArtBase64 = idifiedAlbumArtBase64;
    				album.idifiedAlbumArtBase64Blur = idifiedAlbumArtBase64Blur;
    				TrebbleDBHelper.getInstance().put("album", album).then(resolve);
    			}else{
    				resolve();
    			}
    		}).bind(this)).catch(reject);
    	}).bind(this));
    
    },*/

  //API Method
  getNextSongToIdify: function () {
    return TrebbleDBHelper.getInstance()
      .getAll("song", { idified: "false" }, null, 1)
      .then(function (jsonObjArray) {
        if (jsonObjArray && jsonObjArray.length > 0) {
          return RSVP.Promise.resolve(jsonObjArray[0]);
        } else {
          return RSVP.Promise.resolve();
        }
      });
  },

  getUnsyncedSongs: function (maxNumberOfSongToReturn) {
    return TrebbleDBHelper.getInstance().getAll(
      "song",
      { synced: "false" },
      null,
      maxNumberOfSongToReturn
    );
  },

  syncSongs: function (numberOfSongsToSync, minimumNumberToSync) {
    let songToSync = undefined;
    const self = this;
    return this.getUnsyncedSongs(numberOfSongsToSync)
      .then(function (arrayOfSongToSync) {
        songToSync = arrayOfSongToSync;
        if (
          songToSync &&
          songToSync.length > 0 &&
          (!minimumNumberToSync ||
            (minimumNumberToSync && songToSync.length > minimumNumberToSync))
        ) {
          const firstSync = window.localStorage.getItem("firstSync");
          let isFirstSync = false;
          if (!firstSync) {
            window.localStorage.setItem("firstSync", "pending");
            isFirstSync = true;
          } else {
            if (firstSync == "pending") {
              isFirstSync = true;
            }
          }
          return TrebbleClientAPI.getInstance().syncSongsArrayWithDB(
            songToSync,
            isFirstSync
          );
        } else {
          window.localStorage.setItem("firstSync", "done");
          return RSVP.resolve();
        }
      })
      .then(function (results) {
        if (
          songToSync &&
          (!minimumNumberToSync ||
            (minimumNumberToSync && songToSync.length > minimumNumberToSync))
        ) {
          return self._markSongSynced(songToSync);
        } else {
          return RSVP.resolve(0);
        }
      });
  },

  savePlayerHistoryLog: function (playerHistoryLogToSave) {
    if (playerHistoryLogToSave) {
      TrebbleDBHelper.getInstance().put("log", playerHistoryLogToSave);
      const transactionOnLogStore = this.getTransaction(["log"], "write");
      const logStore = transactionOnLogStore.objectStore("log");
      this.putToObjectStore(logStore, playerHistoryLogToSave);
    }
  },

  //API Method
  syncAllSongs: function (minimumNumber) {
    const self = this;
    const recursiveSyncFunction = function () {
      return self
        .syncSongs(100, minimumNumber)
        .then(function (numberOfSyncedSongs) {
          if (numberOfSyncedSongs == 0) {
            return RSVP.resolve();
          } else {
            return recursiveSyncFunction();
          }
        })
        .catch(function (error) {
          window.log("Failed Syncing songs. Error:" + error);
          throw error;
        });
    }.bind(this);
    return recursiveSyncFunction();
  },

  _markSongSynced: function (arrayOfSongToMarkSynced) {
    const markSyncedSongPromises = [];
    const self = this;
    const uriToSongMap = [];
    uriToSongMap.mapLength = 0;
    for (let i in arrayOfSongToMarkSynced) {
      const song = arrayOfSongToMarkSynced[i];
      song.synced = "true";
      uriToSongMap[song.uri] = song;
      uriToSongMap.mapLength = uriToSongMap.mapLength + 1;
    }
    return new RSVP.Promise(function (resolve, reject) {
      try {
        TrebbleDBHelper.getInstance()
          .putAll("song", arrayOfSongToMarkSynced)
          .then(resolve);
      } catch (error) {
        reject(error);
      }
    }).then(function () {
      return RSVP.Promise.resolve(arrayOfSongToMarkSynced.length);
    });
  },

  //API Method
  saveSongArray: function (songArray) {
    const uriToSongArray = [];
    for (const i in songArray) {
      const song = songArray[i];
      uriToSongArray[song.uri] = song;
    }
    const self = this;
    return this._markSongAsDeletedInDbIfNotPresentInArray(uriToSongArray).then(
      function (cleanedUriToSongArray) {
        const songsToSave = [];
        const albumsToSave = [];
        const artistsToSave = [];
        const songsUriToSave = [];
        const albumIdsToSave = [];
        const artistIdsToSave = [];
        const promiseArray = [];
        for (const i in cleanedUriToSongArray) {
          const song = cleanedUriToSongArray[i];
          song.deleted = "false";
          if (!song.idified) {
            song.idified = false;
            song.idifiedTitle = "";
            song.synced = false;
            song.idifiedSuccess = false;
            song.idifiedDate = new Date();
          }
          songsUriToSave.push(song.uri);
          const album = {
            albumId: song.albumId,
            album: song.album,
            year: song.year,
            artist: song.artist,
            artistId: song.artist,
          };

          /*  const idifiedAlbum = {

          };*/
          const artist = {
            artistId: song.artist,
            artistName: song.artist,
          };
          /*const idifiedArtist = {

          }*/
          songsToSave.push(song);
          if (albumIdsToSave.indexOf(album.albumId) == -1) {
            albumsToSave.push(album);
            albumIdsToSave.push(album.albumId);
          }
          if (artistIdsToSave.indexOf(artist.artistId) == -1) {
            artistsToSave.push(artist);
            artistIdsToSave.push(artist.artistId);
          }
        }
        promiseArray.push(
          TrebbleDBHelper.getInstance().addAll("song", songsToSave)
        );
        promiseArray.push(
          TrebbleDBHelper.getInstance().addAll("album", albumsToSave)
        );
        promiseArray.push(
          TrebbleDBHelper.getInstance().addAll("artist", artistsToSave)
        );
        return Promise.all(promiseArray);
      }
    );
  },

  /* _updateSongWithIdifiedInfo : function(song,idifiedInfo){
    	
    },*/

  getAllLocalSongs: function () {
    return TrebbleDBHelper.getInstance().getAll(
      "song",
      { deleted: "false", type: "localFile" },
      ["idifiedTitle", "title"]
    );
  },

  getAllFavoriteSongs: function () {
    return TrebbleDBHelper.getInstance().getAll(
      "song",
      { deleted: "false", type: "remote", favorite: "true" },
      ["idifiedTitle", "title"]
    );
  },

  _markSongAsDeletedInDbIfNotPresentInArray: function (uriToSongMap) {
    const songToMarkDeleted = [];
    const self = this;
    return new RSVP.Promise(function (resolve, reject) {
      try {
        TrebbleDBHelper.getInstance()
          .getAll("song")
          .then(function (allSongArray) {
            const promiseArray = [];
            const songToMarkUnsynced = [];
            for (let index in allSongArray) {
              const song = allSongArray[index];
              if (song.type == "localFile") {
                if (!(song.uri in uriToSongMap)) {
                  song.deleted = "true";
                  songToMarkDeleted.push(song);
                } else {
                  /* song.synced= "false";
	        				 songToMarkUnsynced.push(song);*/
                  delete uriToSongMap[song.uri];
                }
              }
            }
            /* if(songToMarkUnsynced.length > 0)
        		{
        			 promiseArray.push(TrebbleDBHelper.getInstance().putAll("song", songToMarkUnsynced));
        		}*/
            if (songToMarkDeleted.length > 0) {
              promiseArray.push(
                TrebbleDBHelper.getInstance().putAll("song", songToMarkDeleted)
              );
            }
            return resolve(RSVP.Promise.all(promiseArray));
          });
      } catch (error) {
        reject(error);
      }
    }).then(function () {
      return RSVP.Promise.resolve(uriToSongMap);
    });
  },

  //API Method
  getSongsAlbumArtists: function () {
    const songArray = [];
    const albumArray = [];
    const artistArray = [];
    const artistKeyToAlbumKeyMap = [];
    const albumKeyToSongMap = [];
    const albumKeyToAlbum = [];
    const self = this;
    return new RSVP.Promise(function (resolve, reject) {
      self
        .getAllLocalSongs()
        .then(function (songs) {
          for (let index in songs) {
            const song = songs[index];
            if (song.deleted == "false" && song.type == "localFile") {
              songArray[song.uri] = song;
              let artistKey = null;
              let albumKey = null;
              if (song.idifiedSuccess == "true") {
                artistKey = song.idifiedArtistId;
                albumKey = song.idifiedAlbumId;
              } else {
                artistKey = song.artist;
                albumKey = song.albumId;
              }
              if (!(artistKey in artistKeyToAlbumKeyMap)) {
                artistKeyToAlbumKeyMap[artistKey] = [];
              }
              if (artistKeyToAlbumKeyMap[artistKey].indexOf(albumKey) < 0) {
                artistKeyToAlbumKeyMap[artistKey].push(albumKey);
              }
              if (!(albumKey in albumKeyToSongMap)) {
                albumKeyToSongMap[albumKey] = [];
              }
              albumKeyToSongMap[albumKey].push(song);
            }
          }
          return self.getAllAbums();
        })
        .then(function (albums) {
          for (let index in albums) {
            const album = albums[index];
            if (album.albumId in albumKeyToSongMap) {
              album.songs = albumKeyToSongMap[album.albumId];
              albumArray[album.albumId] = album;
              albumKeyToAlbum[album.albumId] = album;
              for (let songIndex in album.songs) {
                const songJSON = album.songs[songIndex];
                songJSON.albumJson = album;
              }
            }
          }
          return self.getAllArtists();
        })
        .then(function (artists) {
          for (let index in artists) {
            const artist = artists[index];
            if (artist.artistId in artistKeyToAlbumKeyMap) {
              artist.albums = [];
              const artistAlbumKeyArray =
                artistKeyToAlbumKeyMap[artist.artistId];
              for (let artistAlbumKey in artistAlbumKeyArray) {
                const album =
                  albumKeyToAlbum[artistAlbumKeyArray[artistAlbumKey]];
                if (!(album in artist.albums)) {
                  //artist.albums.push(albumKeyToAlbum[artistAlbumKeyArray[artistAlbumKey]]);
                  artist.albums.push(album);
                  album.artistJson = artist;
                }
              }
              artistArray[artist.artistId] = artist;
            }
          }
          const result = {
            songs: songArray,
            albums: albumArray,
            artists: artistArray,
          };
          return resolve(result);
        })
        .catch(reject);
    });
  },

  //API Method
  getFavoriteSongsAlbumArtists: function () {
    const songArray = [];
    const albumArray = [];
    const artistArray = [];
    const artistKeyToAlbumKeyMap = [];
    const albumKeyToSongMap = [];
    const albumKeyToAlbum = [];
    const self = this;
    return new RSVP.Promise(function (resolve, reject) {
      self
        .getAllFavoriteSongs()
        .then(function (songs) {
          for (let index in songs) {
            const song = songs[index];
            if (song.type == "remote") {
              songArray.push(song);
              let artistKey = null;
              let albumKey = null;
              if (song.idifiedSuccess == "true") {
                artistKey = song.idifiedArtistId;
                albumKey = song.idifiedAlbumId;
              } else {
                artistKey = song.artist;
                albumKey = song.albumId;
              }
              if (!(artistKey in artistKeyToAlbumKeyMap)) {
                artistKeyToAlbumKeyMap[artistKey] = [];
              }
              if (artistKeyToAlbumKeyMap[artistKey].indexOf(albumKey) < 0) {
                artistKeyToAlbumKeyMap[artistKey].push(albumKey);
              }
              if (!(albumKey in albumKeyToSongMap)) {
                albumKeyToSongMap[albumKey] = [];
              }
              albumKeyToSongMap[albumKey].push(song);
            }
          }
          return self.getAllAbums();
        })
        .then(function (albums) {
          for (let index in albums) {
            const album = albums[index];
            if (album.albumId in albumKeyToSongMap) {
              album.songs = albumKeyToSongMap[album.albumId];
              albumArray.push(album);
              albumKeyToAlbum[album.albumId] = album;
              for (let songIndex in album.songs) {
                const songJSON = album.songs[songIndex];
                songJSON.albumJson = album;
              }
            }
          }
          return self.getAllArtists();
        })
        .then(function (artists) {
          for (let index in artists) {
            const artist = artists[index];
            if (artist.artistId in artistKeyToAlbumKeyMap) {
              artist.albums = [];
              const artistAlbumKeyArray =
                artistKeyToAlbumKeyMap[artist.artistId];
              for (let artistAlbumKey in artistAlbumKeyArray) {
                const album =
                  albumKeyToAlbum[artistAlbumKeyArray[artistAlbumKey]];
                if (!(album in artist.albums)) {
                  //artist.albums.push(albumKeyToAlbum[artistAlbumKeyArray[artistAlbumKey]]);
                  artist.albums.push(album);
                  album.artistJson = artist;
                }
              }
              artistArray.push(artist);
            }
          }
          const result = {
            songs: songArray,
            albums: albumArray,
            artists: artistArray,
          };
          return resolve(result);
        })
        .catch(reject);
    });
  },

  _buildSongFromIdifiedInfo: function (idifiedInfo) {
    if (idifiedInfo) {
      const song = {};
      song.title = idifiedInfo.title;
      song.artist = idifiedInfo.artist;
      song.album = idifiedInfo.album;
      song.albumId = idifiedInfo.albumId;
      if (idifiedInfo.dateAdded) {
        song.dateAdded = idifiedInfo.dateAdded;
      } else {
        song.dateAdded = new Date();
      }

      if (idifiedInfo.dateModified) {
        song.dateModified = idifiedInfo.dateModified;
      } else {
        song.dateModified = new Date();
      }

      song.uri = "gracenoteId:" + idifiedInfo.gracenoteId;
      song.type = "remote";
      song.favorite = true;
      song.favoriteString = "true";
      song.duration = idifiedInfo.duration;
      song.idified = false;
      song.synced = "false";
      song.idified = true;
      song.idifiedSuccess = "true";
      song.idifiedTitle = idifiedInfo.title;
      song.idifiedArtist = idifiedInfo.artist;
      song.idifiedAlbum = idifiedInfo.album;
      song.idifiedArtistId = idifiedInfo.artistId;
      song.songGracenoteId = idifiedInfo.gracenoteId;
      song.idifiedAlbumArtURL = idifiedInfo.albumArtUrl;
      song.idifiedAlbumId = idifiedInfo.albumId;
      song.idifiedTopGenreId = idifiedInfo.topGenreId;
      song.idifiedTopGenre = idifiedInfo.topGenre;
      song.idifiedTopOriginId = idifiedInfo.topOriginId;
      song.idifiedTopOrigin = idifiedInfo.topOrigin;
      song.idifiedTopEraId = idifiedInfo.topEraId;
      song.idifiedTopEra = idifiedInfo.topEra;
      song.idifiedTopMoodId = idifiedInfo.topMoodId;
      song.idifiedTopMood = idifiedInfo.topMood;
      song.idifiedTopTempoId = idifiedInfo.topTempoId;
      song.idifiedTopTempo = idifiedInfo.topTempo;
      song.idifiedTopArtistTypeId = idifiedInfo.topArtistTypeId;
      song.idifiedTopArtistType = idifiedInfo.topArtistType;
      const songidifiedGenreArray = idifiedInfo.genreArray;
      const songidifiedOriginArray = idifiedInfo.originArray;
      const songidifiedEraArray = idifiedInfo.eraArray;
      const songidifiedMoodArray = idifiedInfo.moodArray;
      const songidifiedTempoArray = idifiedInfo.tempoArray;
      const songidifiedArtistTypeArray = idifiedInfo.artistTypeArray;
      song.idifiedInfo = idifiedInfo;
    } else {
      return null;
    }
  },

  _buildAlbumFromSong: function (song) {
    const idifiedInfo = song.idifiedInfo;
    let album = null;
    if (idifiedInfo) {
      album = {
        albumId: idifiedInfo.albumId,
        album: idifiedInfo.album,
        artist: idifiedInfo.artist,
        artistId: idifiedInfo.artistId,
        year: idifiedInfo.year,
        idifiedAlbumLinkDataArray: idifiedInfo.albumLinkData,
        albumTrackCount: idifiedInfo.albumTrackCount,
        topGenreId: idifiedInfo.topGenreId,
        topGenre: idifiedInfo.topGenre,
        topOriginId: idifiedInfo.topOriginId,
        topOrigin: idifiedInfo.topOrigin,
        topEraId: idifiedInfo.topEraId,
        topEra: idifiedInfo.topEra,
        topMoodId: idifiedInfo.topModId,
        topMood: idifiedInfo.topMood,
        topTempoId: idifiedInfo.topTempoId,
        topTempo: idifiedInfo.topTempo,
        topArtistTypeId: idifiedInfo.topArtistTypeId,
        topArtistType: idifiedInfo.topArtistType,
        genreArray: idifiedInfo.genreArray,
        originArray: idifiedInfo.originArray,
        eraArray: idifiedInfo.eraArray,
        moodArray: idifiedInfo.moodArray,
        tempoArray: idifiedInfo.tempoArray,
        artistTypeArray: idifiedInfo.artistTypeArray,
        albumArtURL: idifiedInfo.albumArtUrl,
        albumArtImages: idifiedInfo.albumArtImages,
      };
    } else {
      album = {
        albumId: song.albumId,
        album: song.album,
        artist: song.artist,
        artistId: song.artistId,
        year: song.year,
      };
    }
    /*	if(song.doNotUseIdifiedUrl == true)
    	{
    		album.preferredAlbumIdForCoverArt = song.preferredAlbumIdForCoverArt;
    		album.preferredAlbumArtURL = song.preferredAlbumArtURL;
    	}*/

    return album;
  },

  _buildArtistFromSong: function (song) {
    const idifiedInfo = song.idifiedInfo;
    let artist = null;
    if (idifiedInfo) {
      artist = {
        artistId: idifiedInfo.artistId,
        artistName: idifiedInfo.artist,
      };
    } else {
      artist = {
        artistId: song.artistId,
        artistName: song.artist,
      };
    }
    return artist;
  },
  _addBidirectionalLinkBetweenSongAndAlbum: function (song, album) {
    song.albumJson = album;
    if (!album.songs) {
      album.songs = [];
    }
    album.songs.push(song);
  },

  _addBidirectionalLinkBetweenAlbumAndArtist: function (album, artist) {
    album.artistJson = artist;
    if (!artist.albums) {
      artist.albums = [];
    }
    artist.albums.push(album);
  },

  //API Method
  saveSongFromIdifiedInfo: function (idifiedInfo, context, silent) {
    const song = this._buildSongFromIdifiedInfo(idifiedInfo);
    return this.saveSong(song, context, silent);
  },
  //API Method
  saveSong: function (song, context, silent, doNotSync, minimumSyncData) {
    song.deleted = "false";
    let albumUpdatedOrCreated = null;
    let albumCreated = null;
    let artistUpdatedOrCreated = null;
    let artistCreated = null;
    if (song.idified == "true") {
      if (song.idifiedInfo) {
        const idifiedInfo = song.idifiedInfo;
        song.idifiedSuccess = "true";
        song.idifiedTitle = idifiedInfo.title;
        song.idifiedArtist = idifiedInfo.artist;
        song.idifiedAlbum = idifiedInfo.album;
        song.idifiedArtistId = idifiedInfo.artistId;
        song.songGracenoteId = idifiedInfo.gracenoteId;
        song.idifiedAlbumArtURL = idifiedInfo.albumArtUrl;
        song.idifiedAlbumArtImages = idifiedInfo.albumArtImages;
        song.idifiedAlbumId = idifiedInfo.albumId;
        song.idifiedTopGenreId = idifiedInfo.topGenreId;
        song.idifiedTopGenre = idifiedInfo.topGenre;
        song.idifiedTopOriginId = idifiedInfo.topOriginId;
        song.idifiedTopOrigin = idifiedInfo.topOrigin;
        song.idifiedTopEraId = idifiedInfo.topEraId;
        song.idifiedTopEra = idifiedInfo.topEra;
        song.idifiedTopMoodId = idifiedInfo.topMoodId;
        song.idifiedTopMood = idifiedInfo.topMood;
        song.idifiedTopTempoId = idifiedInfo.topTempoId;
        song.idifiedTopTempo = idifiedInfo.topTempo;
        song.idifiedTopArtistTypeId = idifiedInfo.topArtistTypeId;
        song.idifiedTopArtistType = idifiedInfo.topArtistType;
        const songidifiedGenreArray = idifiedInfo.genreArray;
        const songidifiedOriginArray = idifiedInfo.originArray;
        const songidifiedEraArray = idifiedInfo.eraArray;
        const songidifiedMoodArray = idifiedInfo.moodArray;
        const songidifiedTempoArray = idifiedInfo.tempoArray;
        const songidifiedArtistTypeArray = idifiedInfo.artistTypeArray;
        song.idifiedInfo = idifiedInfo;
      } else {
        song.idifiedSuccess = "false";
      }
    }
    if (song.uri.indexOf("/") == 0) {
      song.type = "localFile";
    } else {
      song.type = "remote";
    }
    song.synced = "false";

    const albumJsonRef = song.albumJson;
    song.albumJson = null;
    const songClone = JSON.parse(JSON.stringify(song));
    song.albumJson = albumJsonRef;
    return TrebbleDBHelper.getInstance()
      .put("song", songClone)
      .then(
        function () {
          const albumId = song.idifiedAlbumId
            ? song.idifiedAlbumId
            : song.albumId;
          return this.getAlbumWithAlbumId(albumId);
        }.bind(this)
      )
      .then(
        function (albumFound) {
          if (!albumFound) {
            const album = this._buildAlbumFromSong(song);
            albumUpdatedOrCreated = album;
            albumCreated = true;

            const artistJsonRef = album.artistJson;
            album.artistJson = null;
            const albumClone = JSON.parse(JSON.stringify(album));
            album.artistJson = artistJsonRef;
            return TrebbleDBHelper.getInstance().put("album", albumClone);
          } else {
            albumCreated = false;
            albumUpdatedOrCreated = albumFound;
            /* if(song.doNotUseIdifiedUrl ==  true){
    			 albumUpdatedOrCreated.doNotUseIdifiedUrl == song.doNotUseIdifiedUrl;
    			 albumUpdatedOrCreated.preferredAlbumIdForCoverArt = song.preferredAlbumIdForCoverArt;
    			 albumUpdatedOrCreated.preferredAlbumArtURL = song.preferredAlbumArtURL;
    		 }*/
            return null;
          }
        }.bind(this)
      )
      .then(
        function () {
          const artistId = song.idifiedArtistId
            ? song.idifiedArtistId
            : song.artistId;
          return this.getArtistWithArtistId(artistId);
        }.bind(this)
      )
      .then(
        function (artistFound) {
          if (!artistFound) {
            const artist = this._buildArtistFromSong(song);
            artistUpdatedOrCreated = artist;
            artistCreated = true;
            return TrebbleDBHelper.getInstance().put("artist", artist);
          } else {
            artistCreated = false;
            artistUpdatedOrCreated = artistFound;
            return null;
          }
        }.bind(this)
      )
      .then(
        function () {
          this.trigger(
            "onSongSaved",
            song,
            albumUpdatedOrCreated,
            artistUpdatedOrCreated,
            silent
          );

          /*	 if(albumCreated)
	         	{
	        		 this._addBidirectionalLinkBetweenSongAndAlbum(song, albumUpdatedOrCreated);
	         		 this.trigger("onNewAlbumAdded", albumUpdatedOrCreated);
	         	}else{
	         		this.trigger("onSongAddedToExsitingAlbum", song, albumUpdatedOrCreated);
	         	}
       		if(artistCreated)
           	{
       			this._addBidirectionalLinkBetweenAlbumAndArtist(albumUpdatedOrCreated, artistUpdatedOrCreated);
           		 this.trigger("onNewArtistAdded", artistUpdatedOrCreated);
           	}else{
           		this.trigger("onAlbumAddedToExsitingArtist", albumUpdatedOrCreated, artistUpdatedOrCreated);
           	}*/
        }.bind(this)
      )
      .then(
        function () {
          if (!doNotSync) {
            this.syncAllSongs(minimumSyncData);
          }
        }.bind(this)
      );
  },
});

const trebbleIndexDBHelperSingleton = new TrebbleIndexDBHelper();

export default {
  getInstance: function () {
    return trebbleIndexDBHelperSingleton;
  },
};
