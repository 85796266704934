
import _ from 'underscore';
import Backbone from "backbone";
import { AudioClassifier, FilesetResolver } from "@mediapipe/tasks-audio";
import RSVP from 'rsvp';
import AudioBufferUtils from "models/audioEditor/AudioBufferUtils";
import SequencerNodeUtils from 'models/audioEditor/SequencerNodeUtils';


const MEDIA_PIPE_TIME_UNIT_IN_MS = 975;

const AudioClassificationHelper = Backbone.Model.extend({
    idAttribute: "id",

    constructor: function(attributes, options) {
        Backbone.Model.apply(this, [attributes, options]);
        this._audioClassifierInstance =  null;
        this._audioClassifierInstanceBeingCreated =  null;
    },  

    _createAudioClassifier : async function(){
        const audio = await FilesetResolver.forAudioTasks(
          "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-audio@0.10.0/wasm"
        );
      
        const audioClassifier = await AudioClassifier.createFromOptions(audio, {
          baseOptions: {
            modelAssetPath:
            new URL('shared/models/yamnet.tflite', import.meta.url)
          },
          displayNamesLocale: "en",
          scoreThreshold: 0.2,
          categoryAllowlist: SequencerNodeUtils.getInstance().getDefaultAllowedCategoryClassesForAudioClassifier()
        });
        return audioClassifier;
      },

      _cleanupResults : function(audioClassifierResultArray){
        const trebbleAudioClassifierResultArray =  audioClassifierResultArray.map((audioClassifierResult)=>{
            return {timestampMs	: audioClassifierResult.timestampMs,classifications:audioClassifierResult.classifications.length > 0?audioClassifierResult.classifications[0].categories :[] }
        });
      trebbleAudioClassifierResultArray.getClassifications = (startTimeInMs, endTimeInMs)=>{
            const startIndex = Math.floor(startTimeInMs/MEDIA_PIPE_TIME_UNIT_IN_MS);
            const endIndex = Math.floor(endTimeInMs/MEDIA_PIPE_TIME_UNIT_IN_MS);
            return trebbleAudioClassifierResultArray.slice(startIndex, endIndex+1);
        }

        return trebbleAudioClassifierResultArray;

      },

      getAudioClassifier : async function(){
        /*if(this._audioClassifierInstance){
            return this._audioClassifierInstance;
        }
        if(this._audioClassifierInstanceBeingCreated){
            return await this._audioClassifierInstanceBeingCreated;
        }else{
            this._audioClassifierInstanceBeingCreated = this._createAudioClassifier();
            this._audioClassifierInstance = await this._audioClassifierInstanceBeingCreated;
            this._audioClassifierInstanceBeingCreated = null;
            return this._audioClassifierInstance;
        }*/
        return this._createAudioClassifier();
      },

      classify : async function (audioBuffer){
        const audioClassifier = await this.getAudioClassifier();
        const results = audioClassifier.classify(AudioBufferUtils.getInstance().mixDownToMono(audioBuffer), audioBuffer.sampleRate);
        return this._cleanupResults(results);
      },

      decodeAndClassify: async function (blob){
        const arrayBuffer = await blob.arrayBuffer();
        const audioBuffer = await new AudioContext().decodeAudioData(arrayBuffer);
        return this.classify(audioBuffer);
      },

      classifyInWorker : async function (audioBuffer, transfer){
        // Set up the worker

        return new RSVP.Promise(async (resolve, reject)=>{
            const audioWorker = new Worker(new URL('workers/audioClassifierWorker.js', import.meta.url));

            const classifyAudio = async (audioBuffer, transfer = false) => {
                const channelData = audioBuffer.getChannelData(0); // Assuming mono or first channel
                const sampleRate = audioBuffer.sampleRate;

                // Decide to transfer or copy the data
                if (transfer) {
                    // Transfer the ArrayBuffer (main thread loses access)
                    audioWorker.postMessage({ channelData: channelData.buffer, sampleRate, transfer, allowsSoundClasses: SequencerNodeUtils.getInstance().getDefaultAllowedCategoryClassesForAudioClassifier() }, [channelData.buffer]);
                } else {
                    // Copy the data (main thread retains access)
                    audioWorker.postMessage({ channelData: channelData.buffer.slice(0), sampleRate, transfer, allowsSoundClasses: SequencerNodeUtils.getInstance().getDefaultAllowedCategoryClassesForAudioClassifier() });
                }

                audioWorker.onmessage = function (event){
                    console.log('Classification Results:', event.data.results);
                    resolve(this._cleanupResults(event.data.results));
                };

                audioWorker.onerror = function (error){
                    console.error('Error in worker:', error.message);
                    reject(error);
                };
            };
            await classifyAudio(audioBuffer, transfer);


        })
        
      },



});



const AudioClassificationHelperInstance = new AudioClassificationHelper();

export default {
    getInstance: function ()
    {
        return AudioClassificationHelperInstance;
    }
}; 