import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import UserListController from "controllers/common/UserListController";
import DynamicPageView from "views/common/DynamicPageView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utils from "models/helper/Utils";
import ti18n from "i18n!nls/Useri18n";
import UserPageTemplate from 'text!../../../templates/common/userListPageViewTemplate.html';
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";

const UserListPageController = Chaplin.Controller.extend({


    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if (options) {
            if (options.userCollection) {
                this.model = options.userCollection;
                this.userCollection = options.userCollection;
            }
            if (options.pageId) {
                this.pageId = options.pageId;
            }
            if (options.searchKeyword) {
                this.searchKeyword = options.searchKeyword;
            }
            this._pageTitle =  options.pageTitle? options.pageTitle: window.getI18n(ti18n, "USERS");
            
        }
        const userListControllerParams = {};
        userListControllerParams.resizePlaceHolderElem = false;
        userListControllerParams.doNotSortDisplayedCollection = true;
        userListControllerParams.loadHandler = this.getUserListLoadHandler().bind(this);
        userListControllerParams.context =  (options && options.context)?options.context:null;//Utils.getInstance().buildContextForSongCollectionGlobalSearch(this.searchKeyword);
        this.userListController = new UserListController(userListControllerParams);
        
                    
    },
    getSearchKeyword :function(){
        return this.searchKeyword;
    },
    
    getModel : function(){
        return this.model;
    },
    
    getView : function(){
        return this.view;
    },
    
    navigateToPreviousPage : function(){
        PersistentModels.getInstance().getRouter().navigateToPreviousPage();
    },
    
    updateListOfUsers : function(arrayOfUserJson){
        this.model.reset(arrayOfUserJson);
    },

    getUserListLoadHandler : function(){
        const searchKeyword = this.searchKeyword;
        return (function(){
            return TrebbleClientAPIHelper.getInstance().searchForUsers(this.searchKeyword && this.searchKeyword.trim(), 100, 1);
        }).bind(this);
    },
    
    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve,reject){
            try{
                this.userListController.buildModelAndRenderView().then((function(){
                    const userListView = this.userListController.getView();
                    const pageTemplateOptions=  {};
                    pageTemplateOptions.pageTitle = this._pageTitle;
                    const pageViewParams= {};
                    pageViewParams.pageId = this.pageId;
                    pageViewParams.containerSelector = ".ui-content";
                    pageViewParams.contentView = userListView;
                    pageViewParams.pageViewTemplate = UserPageTemplate;
                    pageViewParams.addContentLeftRightPadding = true;
                    pageViewParams.pageTemplateOptions = pageTemplateOptions;
                    pageViewParams.ignoreDefaultPageUIContentPadding = true;
                    this.view = new DynamicPageView(pageViewParams);
                    this.view.render();
                    this.view.$el.on("click","#header-back-button", this.navigateToPreviousPage.bind(this));
                    this.view.$el.find(".ui-content > div").addClass("left-right-content-padding");
                    resolve(this);
                }).bind(this)).catch(reject);

            }catch(error){
                reject(error);
            }
            
        }).bind(this));

        
    }

});
export default UserListPageController;