import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Song from "models/Song";
import DynamicPlaylist from "collections/DynamicPlaylist";
import Utils from "models/helper/Utils";
import Utilsi18n from "i18n!nls/Utilsi18n";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";

var SocialDynamicPlaylist =  DynamicPlaylist.extend({
    model: PersistentModels.getInstance().getSongContructorFunctionForCollection(),
    
    
    
    refresh : function(){
        if(this._isInitialLoading && this._loadingPromise){
            return this._loadingPromise;
        }else{
            this.trigger("startedLoading");
            var selectedTagIds = this._tuneOptions? this._tuneOptions : this._getSelectedTagIds();
            this._isLoading = true;
            var refreshParams = {};
            refreshParams.selectedTagIds = selectedTagIds;
            var loadingPromise = this._onRefresh(refreshParams).then((function(data){
                var songArray = data? data.songs : [];
                this._songUriToContextInfoMap = data? data.songUriToContextInfo : {};
                this._songUriToCapsuleSummaryMap = data? data.songUriToCapsuleSummary : {};
                this._songUriToCommentSummaryMap = data? data.songUriToCommentSummary : {};
                this._songUriToSongRefSummaryMap = data? data.songUriToSongRefSummary : {};
                var tagGroupIdToTagsMap = (data && data.allAvailableTagFilters)? data.allAvailableTagFilters.tagGroupIdToTags: null;
                var tagGroupIdToTagGrouInfo = (data && data.allAvailableTagFilters)? data.allAvailableTagFilters.tagGroupIdToTagGroupInfo: null;
                this._allAvailableTagGroupArray = Utils.getInstance().buildTagGroupFromtagGroupIdToTagsMap(tagGroupIdToTagsMap, tagGroupIdToTagGrouInfo);
                this.reset(null/*, {silent:true}*/);
                this.add(songArray);
                this._isLoaded = true;
                this._isLoading = false;
                this._lastModificationDate = new Date();
                this._loadingPromise = null;
                this.trigger("finishLoading");
                return songArray;
            }).bind(this)).catch((function(error){
                if(!error){
                    error =  window.getI18n(Utilsi18n, "LOADING_FAILED");
                }
                this.trigger("failedLoading", error);
                throw error;
            }).bind(this));
            this._loadingPromise = loadingPromise;
            return this._loadingPromise;
        }
    },
    
    _getSelectedTagIds : function(){
        var selectedTagIds = [];
        if(this._tagGroupsArrayFilter){
            for(let index in this._tagGroupsArrayFilter){
                var tagGroupFilter = this._tagGroupsArrayFilter[index];
                var selectedTagIdsForTagGroup = tagGroupFilter.getSelectedTagIds();
                if(selectedTagIdsForTagGroup && selectedTagIdsForTagGroup.length > 0){
                    selectedTagIds =  selectedTagIds.concat(selectedTagIdsForTagGroup);
                }
                
            }
        }
        return selectedTagIds;
    },
    
    getSocialInfoForSongWitUri : function(songUri){
        return this._songUriToContextInfoMap? this._songUriToContextInfoMap[songUri]: null;
    },
    
    getCapsuleSummaryForSongWitUri : function(songUri){
        return this._songUriToCapsuleSummaryMap? this._songUriToCapsuleSummaryMap[songUri]:null;
    },
    
    getCommentSummaryForSongWitUri : function(songUri){
        return this._songUriToCommentSummaryMap?this._songUriToCommentSummaryMap[songUri] : null;
    },
    
    getSongRefSummaryForSongWitUri : function(songUri){
        return this._songUriToSongRefSummaryMap?this._songUriToSongRefSummaryMap[songUri] : null;
    },
    
    getAvailableTagGroupFilters : function(){
        return this._allAvailableTagGroupArray;
    },
    
    getSelectedTagFilters : function(){
        return this._tagGroupsArrayFilter;
    },
    
    setSelectedTagFilter : function(tagGroupsArrayFilter){
        this._tagGroupsArrayFilter = tagGroupsArrayFilter;
        return this.refresh();
    },
    
    tune : function(tuneOptions){
        this._tuneOptions = tuneOptions;
    },
    
    
    getContext : function(){
        return this._context;
    },
    
    updateContext : function(newContext){
        this._context = newContext
    },
    

    
});
export default SocialDynamicPlaylist;