import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import UserListPageController from "controllers/user/UserListPageController";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import ti18n from "i18n!nls/Useri18n";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const FollowerListPageController = UserListPageController.extend({


    initialize : function(options) {
        options = options? options:{};
        options.pageTitle = window.getI18n(ti18n, "FOLLOWERS");
        this.username =  options.username;
        return UserListPageController.prototype.initialize.call(this, options);
    },


    getUserListLoadHandler : function(){
        return (function(){
            return TrebbleClientAPIHelper.getInstance().getFollowersForUserWithUsername(this.username);
        }).bind(this);
    },

});
export default FollowerListPageController;