import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utils from "models/helper/Utils";
import SoundEnhancerSettingsController from "controllers/audioEditor/SoundEnhancerSettingsController";
import SoundEnhancerSettingsPopupView from "views/audioEditor/SoundEnhancerSettingsPopupView";
import BottomSheet from "libs/bottomSheet/BottomSheet";
import RSVP from "rsvp";

const SoundEnhancerSettingsPopupContoller = Chaplin.Controller.extend({

    initialize : function(options) {
        this.context = options? options.context: null;
        this.model = options.sequencer;
        this.elementSource$el =  options.elementSource$el;
        this.popupPosition = options.popupPosition;
        _.extend(this, Backbone.Events);
    },


    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    _onPopupClosed : function(){
        this.trigger("popupClosed")
    },


    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                this._soundEnhancerSettingsController = new SoundEnhancerSettingsController({"sequencer":  this.model});

                this._soundEnhancerSettingsController.buildModelAndRenderView().then((function(){
                    if(window.isMobileBrowser){
                        //const bottomSheet = Utils.getInstance().createBottomSheet(this._sequencerNodeInspectorSectionController.getView().$el.get(0), resolve, this._onPopupClosed.bind(this));
                        const bottomSheet = new BottomSheet(this._soundEnhancerSettingsController.getView().$el.get(0), resolve, this._onPopupClosed.bind(this), true);
                        bottomSheet.activate();
                    }else{
                        const params = {"soundEnhancerSettingsView": this._soundEnhancerSettingsController.getView(),"elementSource$el": this.elementSource$el, "popupPosition": this.popupPosition};
                        this.view = new SoundEnhancerSettingsPopupView(params);
                        this.view.render();
                        this.listenTo(this.view, "onPopupClosed", this._onPopupClosed.bind(this))
                    }
                }).bind(this)).then(resolve).catch(reject);

            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default SoundEnhancerSettingsPopupContoller;