	import $ from 'jquery';
    import _ from 'underscore';
    import Backbone from "backbone";
    import SQLDatabaseHelper from "models/helper/SQLDatabaseHelper";
    import RSVP from 'rsvp';

    const albumImageEntityName = "albumImage";

    const ImageStoreHelper = Backbone.Model.extend({
    

        initialize : function() {
            this._imageStoreDB = new SQLDatabaseHelper("trebbleImageStore");
            this._isReady = false;
            this._albumImageEntityName = "albumImage";
            this._albumImageSchema = {
                    entityName : this._albumImageEntityName,
                    primaryKeyField : { jsonFieldProperty : 'albumId'},
                    indexes : {
                            albumArtUrl : { 
                                    jsonFieldProperty : 'albumArtUrl',
                                    unique: false, 
                                    },
                        originalBase64Image : { 
                                    jsonFieldProperty : 'originalBase64Image',
                                    unique: false, 
                                    },
                        originalBlurBase64Image : { 
                                    jsonFieldProperty : 'originalBlurBase64Image',
                                    unique: false, 
                                },
                            
                        
                    }
            };

        },
        
        
        
        createTables :function(){
            return this._imageStoreDB.createTable(this._albumImageSchema).then((function(){
                this._isReady = true;
            }).bind(this));
        },

        saveAlbumImageFromJson : function(albumImageInfoJson){
            if(albumImageInfoJson)
            {
                return this._imageStoreDB.put(this._albumImageEntityName, albumImageInfoJson);
                
            }else{
                return RSVP.Promise.resolve();
            }
        },
        saveAlbumImage : function(albumId, albumArtUrl, albumArtBase64RegularSize, albumArtBase64BlurScreenSize)
        {
            const imageInfo = {
                    "albumId" :albumId ,
                    "albumArtUrl": albumArtUrl,
                    "originalBase64Image" : albumArtBase64RegularSize,
                    "originalBlurBase64Image": albumArtBase64BlurScreenSize,
                        
            };
            
            return this.saveAlbumImageFromJson(imageInfo)
            
        },
        
        getAlbumImageByImageId : function(albumId){
            if(albumId)
            {
                return this._imageStoreDB.find(this._albumImageEntityName, albumId);
            }else{
                return RSVP.Promise.resolve();
            }
        },
        

        

    });

    const ImageStoreHelperInstance = new ImageStoreHelper();

    export default {
        getInstance : function() {
            return ImageStoreHelperInstance;
        }
    };