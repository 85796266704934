import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Tag from "models/Tag";

var TagGroup =  Backbone.Collection.extend({
    model: Tag,

    "default": {
        "exclusive" : false,
        "groupId" : null,
        "label" : {"EN": null},
        "value" : null,
    },
    
    initialize: function (arrayOfTags, options){
        this._sort_key ="title";
        this._exclusive = false;
        this._label = {"EN": null},
        this._groupId = null;
        this._value = null;
        var tagIdToTag ={};
        
    
    },
    
    setSelectedTagIds : function(tagIds){
            for(let index = 0; index < this.length; index++){
                var tag = this.at(index)
                
                var tagId = tag.get("id");
                if(tagIds.indexOf(tagId) > -1){
                    tag.set("selected",true);
                }else{
                    if(this._exclusive){
                        if(tag.get("selected")){
                            tag.set("selected",false);
                        }
                    }
                }
                
            }

    },
    
    addSelectedTagId : function(selectedTagId){
        for(let index = 0; index < this.length; index++){
            var tag = this.at(index)
            var tagId = tag.get("id");
            if(selectedTagId == tagId){
                tag.set("selected",true);
                if(!this._exclusive){
                    break;
                }
            }else{
                if(this._exclusive){
                    if(tag.get("selected")){
                        tag.set("selected",false);
                    }
                }
            }
            
        }
    },
    
    removeTagIdFromSelected : function(unselectedTagId){
        for(let index = 0; index < this.length; index++){
            var tag = this.at(index)
            var tagId = tag.get("id");
            if(tag == tagId){
                tag.set("selected",false);
                if(this._exclusive){
                    break;
                }
            }
            
        }
    },
    
    getSelectedTagIds : function(){
        var selectedTagIds = [];
        for(let index = 0; index < this.length; index++){
            var tag = this.at(index)
            if(tag.get("selected")){
                selectedTagIds.push(tag.get("id"));
            }
        }
        return selectedTagIds;
    },
    

    setExclusive : function(exclusive){
        this._exclusive = exclusive;
    },
    
    isExclusive : function(exclusive){
        return this._exclusive;
    },
    
    setLabel : function(label){
        this._label = label;
    },
    
    getLabel : function(){
        return this._label;
    },
    
    setId : function(id){
        this._groupId = id;
    },
    
    getId : function(){
        return this._groupId;
    },
    
    setValue : function(value){
        this._value = value;
    },
    
    getValue : function(){
        return this._value;
    },

    setCustom : function(custom){
        this._custom = custom;
    },
    
    getCustom : function(){
        return this._custom;
    },
    
     comparator: function(item) {
            var data = item.get(this._sort_key);
            if(data && ( typeof data == "string" || data instanceof String))
            {
                data = data.trim().toLowerCase();
            }
            return  data;
      },
      
      sortByField: function(fieldName) {
            this._sort_key = fieldName? fieldName : this._sort_key;
            this.sort();
      },



});
export default TagGroup;