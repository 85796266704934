import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import SelectionListController from "controllers/selectionList/SelectionListController";
import CustomSelectFieldView from "views/common/CustomSelectFieldView";
import Utils from "models/helper/Utils";
import OptionPopupView from "views/common/OptionPopupView";
import RSVP from "rsvp";

const CustomListSelectorPopupController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = options.model;
        options.singleSelect = true;
        this.context = options.context;
        this._listTitleToDisplay = options.listTitleToDisplay;
        this._propertyFunction = options? options.propertyFunction : null;
        this._propertyName = options? options.propertyName : null;
        options.onItemSelected =  this._onItemSelectedHandler.bind(this);
        this.selectionListControllerOptions = options;
        this._maxWidth = options.maxWidth ?options.maxWidth : 400;
        this._defaultText = options.defaultText ;
        this._sourceElement$el  = options? options.sourceElement$el  : null;
        this._selectedModel = null;
        this._doNotCloseOnItemSelected = options? options.doNotCloseOnItemSelected : false;
        this._resultPromise = new RSVP.Promise((function(resolve, reject){
            this._resultPromiseResolve = resolve;
            this._resultPromiseReject = reject;
        }).bind(this));

    },

    getResultPromise : function(){
        return this._resultPromise;
    },	
    
    _onItemSelectedHandler : function(selected, model){
        if(selected){
            this._selectedModel = model;
        }else{
            this._selectedModel = null;
        }
        if(!this._doNotCloseOnItemSelected && this._selectedModel ){
            this.view.close();
        }
        
    },
    
    setSelected : function(model, selected){
        this._onItemSelectedHandler(selected, model);
        this._onPopupSelectioClosed();
    },
    
    _getModelId : function(model){
        if(model){
            return this._propertyFunction?model[this._propertyFunction]():model[this._propertyName ];
        }
        return null;
    },
    


    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },
    
    _onPopupSelectioClosed  : function(){
        this.trigger("selectionChanged", this._selectedModel);
        if(this._resultPromiseResolve){
            this._resultPromiseResolve(this._selectedModel);
        }
    }, 
    

    onViewRemoved:function(){
        this.stopListening();
    },

    buildModelAndRenderView : function() {

        return new RSVP.Promise((function(resolve, reject){
            try{
                const controller = new SelectionListController(this.selectionListControllerOptions);
                controller.buildModelAndRenderView().then((function(){
                    controller.setSelected(this._selectedModel, true);
                    const params= {};
                    params.contentView = controller.getView();
                    params.contentPosition = "center";
                    params.id = "customListSelectorPopup"+ (new Date()).getTime();
                    params.addContentAfterEnhancement = true;
                    if(this._listTitleToDisplay){
                        params.displayTitle = true;
                        params.titleToDisplay = this._listTitleToDisplay;
                    }
                    params.elementSource$el   = this._sourceElement$el;
                    params.showAsMenuIfInBrowser = true;
                    const selectionPopupView = new OptionPopupView(params);
                    this.view = selectionPopupView;
                    this.listenTo(this.view, "remove", this.onViewRemoved.bind(this));
                    this.listenTo(selectionPopupView ,"popupbeforeposition", (function(popupView){
                        const content$el  = popupView.$el.find(".popupContent > div:not(.trebblePopupHeader)");
                        content$el.css("margin-top","40px");
                        content$el.css("margin-bottom","40px");
                        content$el.css("overflow","auto");
                        content$el.css("max-width", this._maxWidth +"px");
                    }).bind(this));
                
                    this.listenTo(selectionPopupView,"closed", this._onPopupSelectioClosed.bind(this));
                    selectionPopupView.render();

                }).bind(this)).then(resolve).catch(reject);
            }catch(error){
                reject(error);
            }
        }).bind(this));
    }

});

export default CustomListSelectorPopupController;