import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import RSVP from "rsvp";

const allowedOperation = ["retrieveBase64FromUrl", "retrieveScaledCoverArtImages", "updateMetaData"];
const TrebbleCordovaHelperModel = Backbone.Model.extend({
    "default" : {

    },

    initialize : function() {

    },

    executePluginOperation : function(pluginName, pluginOperation, operationParams){
        return new RSVP.Promise(function(resolve, reject){
            if(!window.cordova && (window.device.platform == "browser" && allowedOperation.indexOf(pluginOperation) == -1)){
                resolve();
            }else{
                if(allowedOperation.indexOf(pluginOperation) != -1){
                    window.cordovaPolyfill.exec(resolve, reject, pluginName, pluginOperation, operationParams);
                }else{
                    //cordova.exec(resolve, reject, pluginName, pluginOperation, operationParams);	
                    resolve();
                }
            }
        });
    },

});

const TrebbleCordovaHelper = new TrebbleCordovaHelperModel();

export default {
    getInstance : function() {
        return TrebbleCordovaHelper;
    }
};