import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import Utils from "models/helper/Utils";
import ArtistsTabContentCollection from "collections/myLibrary/ArtistsTabContentCollection";
import ArtistListView from "views/common/ArtistListView";
import PersistentModels from "services/PersistentModels";
import PlayerService from "services/PlayerService";
import RSVP from "rsvp";

const ArtistListController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if(options)
        {
            if(options.model)
            {
                if(Utils.getInstance().is("Array",options.model))
                {
                    const artistJsonArray = options.model;
                    this.model = new ArtistsTabContentCollection(artistJsonArray);
                }else{
                        this.model = options.model;
                }
                
            }
            if(options.resizePlaceHolderElem)
            {
                this.resizePlaceHolderElem = options.resizePlaceHolderElem;
            }else{
                this.resizePlaceHolderElem = false;
            }
            
            if(options.loadHandler)
            {
                this._loadHandler = options.loadHandler;
            }
            if(options.heightMargin){
                this._heightMargin = options.heightMargin;
            }
        }

    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },
    
    updateListOfArtists : function(arrayOfArtistJson){
        this.model.reset(arrayOfArtistJson/*, {silent: true}*/);
    },
    
    showArtistDetailsPage : function(artistModel){
        PersistentModels.getInstance().getRouter().showArtistDetailPage(artistModel.get("artistId"), artistModel, true);
    },
    
    addSongsArtistToQueue :function(artists){
        const songURIs = [];
        const albumsJson  =  artists.get("albums"); 
        for(let albumIndex in albumsJson)
        {
            const album = albumsJson[albumIndex];
            for(let songIndex in album.songs)
            {
                songURIs.push(album.songs[songIndex].uri)
            }
        }
        if(songURIs.length > 0){
            PlayerService.getInstance().addSongsToQueue(PersistentModels.getInstance().getSongCollection().getSongsWithURI(songURIs));
        }
    },
    
    showMenuOption : function(artistModel){
        PersistentModels.getInstance().getRouter().showArtistOptionPopup(artistModel);
        
    },
    
    cancelPendingImageLoading : function(){
        this.view.cancelPendingImageLoading();
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                if(!this.model)
                {
                    this.model = new ArtistsTabContentCollection([]);
                }
                this.view = new ArtistListView({
                    "collection" : this.model,
                    "delayOnCollectionUpdate" : 1000,
                    "resizeToAvailableScreenSizeOnOrientationChange" : false,
                    "resizePlaceHolderElem" : this.resizePlaceHolderElem,
                    "loadHandler" : this._loadHandler,
                    "heightMargin": this._heightMargin,
                });
                this.listenTo(this.view, "itemTaped",this.showArtistDetailsPage);
                this.listenTo(this.view, "itemSwipedRight",this.addSongsArtistToQueue);
                this.listenTo(this.view, "itemOptionBtnTapped",this.showMenuOption);
                this.view.render();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default ArtistListController;