import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerOperationLog from "models/audioEditor/sequencerOperationLog/SequencerOperationLog";
import RSVP from "rsvp";

const SequencerOperationCreateNewSpeakerAndAssignToSequencerNodesOperationLog =  SequencerOperationLog.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperationLog.apply(this, [attributes, options]);
        
    },

    getOperationName : function(){
        return "Create New Speaker And Assign";
    },
    
    setArrayOfSequencerNodeToAssign : function(arrayOfSequencerNodeToAssign){
        this.set("arrayOfSequencerNodeToAssign", arrayOfSequencerNodeToAssign);
    },

    getArrayOfSequencerNodeToAssign : function(){
        return this.get("arrayOfSequencerNodeToAssign");
    },

    setSpeakerLabel : function(speakerLabel){
        this.set("speakerLabel", speakerLabel);
    },

    getSpeakerLabel : function(){
        return this.get("speakerLabel");
    },

    setSequencerNodeCidToOldSpeakerId : function(sequencerNodeCidToOldSpeakerId){
        this.set("sequencerNodeCidToOldSpeakerId", sequencerNodeCidToOldSpeakerId);
    },

    getSequencerNodeCidToOldSpeakerId : function(){
        return this.get("sequencerNodeCidToOldSpeakerId");
    },

    setNewSpeakerWasAdded : function(newSpeakerWasAdded){
        this.set("newSpeakerWasAdded", newSpeakerWasAdded);
    },

    getNewSpeakerWasAdded : function(){
        return this.get("newSpeakerWasAdded");
    },

    setParagraphSequencerNodeView : function(paragraphSequencerNodeView){
        this.set("paragraphSequencerNodeView", paragraphSequencerNodeView);
    },

    getParagraphSequencerNodeView : function(){
        return this.get("paragraphSequencerNodeView");
    },

    setOldSpeakerInfo : function(oldSpeakerInfo){
        this.set("oldSpeakerInfo", oldSpeakerInfo);
    },

    getOldSpeakerInfo : function(){
        return this.get("oldSpeakerInfo");
    },





});

export default SequencerOperationCreateNewSpeakerAndAssignToSequencerNodesOperationLog; 