import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import SongsTabContentCollection from "collections/myLibrary/SongsTabContentCollection";
import MoreTabContentView from "views/myLibrary/MoreTabContentView";
import PersistentModels from "services/PersistentModels";
import PlayerService from "services/PlayerService";
import RSVP from "rsvp";
import ti18n from "i18n!nls/MyLibraryi18n";
import Utils from "models/helper/Utils";

const MoreTabContentController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = options.model;
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },
    


    
clearQueueAndSongAndRestToQueue : function(model, displayedCollection){
        
        if(this._isYoutubeLinkNeeded(model))
        {
            
                let isMusicCurrentlyPlaying =  PlayerService.getInstance().isMusicPlaying();
                let context = displayedCollection.getContext();
                PlayerService.getInstance().clearQueueAndSongToQueue(model, this._buildPlayListName(),context);
                if(!isMusicCurrentlyPlaying)
                {
                    PlayerService.getInstance().playFirstSongInQueue();
                }
                window.setTimeout((function(){
                    const backboneModel =  this.displayedCollection.get(model.get("uri"));
                    let songsJsonAfterSelection = this.displayedCollection.getSongsAfter(backboneModel);
                    // temporary workaround
                    if(backboneModel.type == "remote" && backboneModel.get("uriType") != "soundcloud")
                    {
                        const notSoundcloudSongAfterSelectedSong = [];
                        for( let songIndex in  songsJsonAfterSelection)
                        {
                            const songJson = songsJsonAfterSelection[songIndex]
                            if(songJson.type == "remote" && songJson.get("uriType") != "soundcloud")
                            {
                                notSoundcloudSongAfterSelectedSong.push(songJson);
                            }
                        }
                        songsJsonAfterSelection = notSoundcloudSongAfterSelectedSong;
                        
                    }
                    PlayerService.getInstance().addSongsToQueue(songsJsonAfterSelection);
                }).bind({"displayedCollection": displayedCollection}),0);
            
            
        }else{
            let isMusicCurrentlyPlaying =  PlayerService.getInstance().isMusicPlaying();
            let context = displayedCollection.getContext();
            PlayerService.getInstance().clearQueueAndSongToQueue(model,this._buildPlayListName(),context);
            if(!isMusicCurrentlyPlaying)
            {
                PlayerService.getInstance().playFirstSongInQueue();
            }
            window.setTimeout((function(){
                const backboneModel =  this.displayedCollection.get(model.get("uri"));
                let songsJsonAfterSelection = this.displayedCollection.getSongsAfter(backboneModel);
                // temporary workaround
                if(backboneModel.get("uriType") == "soundcloud")
                {
                    const onlySoundcloudSongAfterSelectedSong = [];
                    for( let songIndex in  songsJsonAfterSelection)
                    {
                        const songJson = songsJsonAfterSelection[songIndex]
                        if(songJson.get("uriType") == "soundcloud")
                        {
                            onlySoundcloudSongAfterSelectedSong.push(songJson);
                        }
                    }
                    songsJsonAfterSelection = onlySoundcloudSongAfterSelectedSong;
                    
                }
                PlayerService.getInstance().addSongsToQueue(songsJsonAfterSelection);
            }).bind({"displayedCollection": displayedCollection}),0);
            
            
        }
    
        
        
    },
    
    addSongToQueue :function(songModelSwiped){
        PlayerService.getInstance().addSongsToQueue(this.model.where({uri:songModelSwiped.get("uri")}));
    },
    
    showMenuOption : function(songModelTapped){
        const context = this.getContext();
        PersistentModels.getInstance().getRouter().showSongMenuOption(songModelTapped, context, null, this.onActionExecutedOnSongHandler.bind(this));
    },
    
    onActionExecutedOnSongHandler : function(songModel, action){
        if(action == "delete" || action == "removeFromRadio" || action == "removeToFavorite"){
            //Do notthing because it is already handled by the collection listeners
        }
    },
    
    
    _buildPlayListName : function(){
        if(this.view.isSearchApplied()){
            const searchKey = this.view.getAppliedSearchCharacters();
            return window.getI18n(ti18n,"SEARCH_RESULTS_FROM") +" \""+searchKey+"\" "+ window.getI18n(ti18n,"IN_MY_FAVORITES") ;
        }else{
            return window.getI18n(ti18n,"ALL_MUSIC_FROM_FAVORITES");
        }
    },

    _isYoutubeLinkNeeded : function(song){
        
        const songUri = song.get("uri");
        if(songUri.indexOf("gracenoteId:") == 0){
            return true;
        }else{
            return false;
        }
    

    },
    
     filterList: function(searchCharaterers, filterOptions, sortOptions){
         if(this.view){
             this.view.filterList(searchCharaterers, null,filterOptions, sortOptions);
         }
     },
     
     clearSearchfilter : function(){
         if(this.view){
             this.view.clearSearchfilter();
         }
     },
    
    getContext : function(){
        return Utils.getInstance().buildContextForSongCollectionMyLibrary();
    },
    
    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                if(!this.model)
                {
                    this.model = new SongsTabContentCollection([]);
                    this.model.updateContext(this.getContext());
                }
                this.view = new MoreTabContentView({
                    "collection" : this.model
                });
                this.listenTo(this.view, "itemTaped",this.clearQueueAndSongAndRestToQueue);
                this.listenTo(this.view, "itemSwipedRight",this.addSongToQueue);
                this.listenTo(this.view, "songOptionTapped", this.showMenuOption);
                this.view.render();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default MoreTabContentController;