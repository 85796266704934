import $ from "jquery";

class TouchDragListener {
    constructor({el, sheelEl, touchStartCallback, touchEndCallback, touchMoveCallback, showLog}) {
        this.el = el;
        this.sheelEl = sheelEl;
        this.touchStartCallback = touchStartCallback;
        this.touchEndCallback = touchEndCallback;
        this.touchMoveCallback = touchMoveCallback;
        this.showLog = showLog;

        this.active = false;
        this.currentY;
        this.initialY;
        this.yOffset = 0;

        this.dragStart = this.dragStart.bind(this);
        this.dragEnd = this.dragEnd.bind(this);
        this.drag = this.drag.bind(this);

        this.el.addEventListener("mousedown", this.dragStart);
        this.el.addEventListener("mouseleave", this.dragEnd);
        this.el.addEventListener("mouseup", this.dragEnd);
        this.el.addEventListener("mousemove", this.drag);

        this.el.addEventListener("touchstart", this.dragStart);
        this.el.addEventListener("touchend", this.dragEnd);
        this.el.addEventListener("touchmove", this.drag);
    }

    dragStart(e) {
        this.active = true;
        this.sheelEl.classList.add("active");

        if (e.type === "touchstart") {
            this.initialY = e.touches[0].clientY - this.yOffset;
        } else {
            this.initialY = e.clientY - this.yOffset;
        }

        if (!this.touchStartCallback) return;

        this.touchStartCallback({
            el: this.el,
            active: this.active,
            currentY: this.currentY,
            initialY: this.initialY,
            yOffset: this.offSetY
        })
    }

    dragEnd(e) {
        this.active = false;
        this.sheelEl.classList.remove("active");

        this.yOffset = 0;

        this.initialY = this.currentY;

        if (!this.touchEndCallback) return;

        this.touchEndCallback({
            el: this.el,
            active: this.active,
            currentY: this.currentY,
            initialY: this.initialY,
            yOffset: this.offSetY
        })
    }
    drag(e) {
        if (!this.active) return;
        e.preventDefault();

        if (e.type === "touchmove") {
            this.currentY = e.touches[0].clientY - this.initialY;
        } else {
            this.currentY = e.clientY - this.initialY;
        }

        this.yOffset = this.currentX;

        if (!this.touchMoveCallback) return;

        this.touchMoveCallback({
            el: this.el,
            active: this.active,
            currentY: this.currentY,
            initialY: this.initialY,
            yOffset: this.offSetY
        });

        if (this.showLog) {
            console.log({
                active: this.active,
                initialY: this.initialY,
                currentY: this.currentY,
                offSetY: this.offSetY
            });
        }        
    }
}


class BottomSheet {
    constructor(el, onBottomSheetOpened, onBottomSheetClosed, darkTheme) {
        this._template$el = $('<div class="c-bottom-sheet c-bottom-sheet--list"><div class="c-bottom-sheet__scrim"></div><div class="c-bottom-sheet__sheet"><div class="c-bottom-sheet__handle"><span></span><span></span></div><div class="c-bottom-sheet__list"></div></div><div class="c-bottom-sheet__container"></div></div>')
        this._template$el.find(".c-bottom-sheet__list").append(el)
        if(darkTheme){
            this._template$el.addClass("darkTheme");
        }
        this.el = this._template$el.get(0);
        this.onBottomSheetOpened = onBottomSheetOpened;
        this.onBottomSheetClosed = onBottomSheetClosed;
        this.scrim = this.el.querySelector(".c-bottom-sheet__scrim");
        this.handle = this.el.querySelector(".c-bottom-sheet__handle");
        this.sheet = this.el.querySelector(".c-bottom-sheet__sheet");
        this.activate = this.activate.bind(this);
        this.deactivate = this.deactivate.bind(this);        
        var sheetElRef = this.sheet;
        this.scrim.addEventListener("click", this.deactivate);
        this.handle.addEventListener("click", this.deactivate);

        this.sheetListener = new TouchDragListener({
            el: this.handle, sheelEl : this.sheet,
            touchStartCallback: ({el, active, initialY, currentY, yOffset}) => {
                sheetElRef.style.setProperty("--translateY", `translateY(0)`);
                sheetElRef.style.setProperty("transition", `unset`);
            },
            touchEndCallback: ({el, active, initialY, currentY, yOffset}) => {
                sheetElRef.style.setProperty(
                    "transition",
                    `transform 150ms cubic-bezier(0.4, 0, 0.2, 1)`
                    );
                sheetElRef.style.setProperty(
                    "--translateY",
                    `translateY(${currentY}px)`
                    );
            },
            touchMoveCallback: ({el, active, initialY, currentY, yOffset}) => {
                if (currentY <= -40) {
                    currentY = -41 + currentY / 10;
                } else if (currentY <= -60) {
                    currentY = -60;
                } else if (currentY >= 210) {
                    this.deactivate(currentY);
                    return;
                }

                sheetElRef.style.setProperty(
                    "--translateY",
                    `translateY(${currentY}px)`
                    );
                
            }
        });

this.scrimListener = new TouchDragListener({
el: this.scrim, sheelEl : this.sheet,
touchMoveCallback: ({el, active, initialY, currentY, yOffset}) => {
    if (currentY >= 83) {
        this.deactivate();
        return;
    }
}
});
}
activate(e) {
if (e) e.preventDefault();
if(!$.contains(document.body, this.el)){
    $(document.body).append(this.el);
}
this.el.classList.add("active");
if(this.onBottomSheetOpened){
    this.onBottomSheetOpened();
}
}
deactivate(translateY) {
if (!translateY) {
    this.sheet.style.setProperty("--translateY", `translateY(201px)`);
} else {
    this.sheet.style.setProperty(
        "transition",
        `transform 150ms cubic-bezier(0.4, 0, 0.2, 1)`
        );
    this.sheet.style.setProperty(
        "--translateY",
        `translateY(${translateY}px)`
        );
}

this.el.classList.remove("active");
$(this.el).remove();
if(this.onBottomSheetClosed){
    this.onBottomSheetClosed();
}
}
}

export default BottomSheet;