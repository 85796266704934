import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import pageTemplate from 'text!../../../templates/queue/QueuePage.html';
import TrebbleSongListView from "views/common/TrebbleSongListView";
import ti18n from "i18n!nls/Queuei18n";
import Utils from "models/helper/Utils";
import PersistentModels from "services/PersistentModels";
import Playlist from "collections/Playlist";
import CordovaHelper from "models/helper/CordovaHelper";
import RSVP from "rsvp";

const DISABLE_QUEUE_TUNING = true;
const QUEUE_INFO_SECTION_HEIGHT = 60;
const APPLY_FILTER_ON_AUDIO_DISPLAYED_IN_QUEUE_FOR_CAPSULE_FEED = true;
const QueuePageView = Backbone.View.extend({

    template : _.template(pageTemplate),

    "pageName" : "Album Detail",

    events : {
        "click #queueMenuOptionBtn" : "showQueueMenuOption",
        "click #queueRefreshButton" : "onRefreshButonClicked",
        "click #header-back-button": "navigateToPreviousPage",
        "click #tuninButton" : "showQueueTuningOption",

    },

    initialize : function(options) {
        this.model = options.model;
        if (this.model) {
            this.listenTo(this.model, 'playlistChanged', this.onPlaylistChanged);
            this.listenTo(this.model, 'playlistNameChanged', this.onPlaylistNameChanged);
            this.listenTo(this.model, "playlistReset", this.onPlaylistReset)
        }
        this._removeFromDom = false;
        this._listenerViewportKey = "queueSongListView" + (new Date()).getTime();
        PersistentModels.getInstance().getRouter().registerForViewportHeightChange(this._listenerViewportKey,this._onOrientationChangeFunction.bind(this));
    },

    _onOrientationChangeFunction : function(newViewPortHeigh, forceResizing) {
        if((PersistentModels.getInstance().getRouter().isEqualToCurrentPageUrl("#queue") &&  !this._removeFromDom) || forceResizing){
            if (this.$el) {
                this.$el.find("#songList").css("height", (newViewPortHeigh  - QUEUE_INFO_SECTION_HEIGHT) + "px"); 
            }
            if(this.infiniteListView){
                this.infiniteListView._onRootElementResize();
            }
        }
    },

    navigateToPreviousPage : function(){
        PersistentModels.getInstance().getRouter().navigateToPreviousPage();
    },


    showQueueMenuOption : function(){
        this.trigger("showQueueMenuOption",this.model);	
    },

    showQueueTuningOption : function(){
        this.trigger("showQueueTuningOption",this.model);

    },

    onPlaylistChanged : function(newPlaylist) {
        this.trigger("queuePlaylistChanged", newPlaylist);
        this.$el.find("#playlistNameWrapper").html(newPlaylist.getName());
        if(this.model.canBeRefresh())
        {
            this.$queueRefreshButtonWrapperEl.show();
        }else{
            this.$queueRefreshButtonWrapperEl.hide();
        }

        if(this.model.canBeTuned() && !DISABLE_QUEUE_TUNING)
        {
            this.$tuninButtonWrapperEl.show();
        }else{
            this.$tuninButtonWrapperEl.hide();
        }
    },

    onPlaylistNameChanged : function(newPlaylistName) {
        this.trigger("queuePlaylistNameChanged", newPlaylistName);
        this.$el.find("#playlistNameWrapper").html(newPlaylistName);
    },

    onPlaylistReset : function(){
        this.infiniteListView.setUpdating(true);
        this.model.getAllLoadedSongs().then((function(jsonSongArray){
            if(!this._removeFromDom){
                this.infiniteListView.updateModels(this._keepCapsulesAndSongsOnly(jsonSongArray));
                this.infiniteListView.setUpdating(false);
            }
        }).bind(this)).catch((function(error){
            this.infiniteListView.setErrorMessage(error);
        }).bind(this));

    },

    onRefreshButonClicked : function(){
        this.$queueRefreshButtonEl.addClass("animate-spin");
        this.infiniteListView.setUpdating(true);
        this.model.refresh().then((function(){
            return this.model.getAllLoadedSongs();
        }).bind(this)).then((function(jsonSongArray){
            if(!this._removeFromDom){
                this.$queueRefreshButtonEl.removeClass("animate-spin");
                const p = this.infiniteListView.updateModels(this._keepCapsulesAndSongsOnly(jsonSongArray));
                this.infiniteListView.setUpdating(false);
                return p;
            }
        }).bind(this)).catch((function(error){
            if(!this._removeFromDom){
                this.$queueRefreshButtonEl.removeClass("animate-spin");
                this.infiniteListView.setErrorMessage(error);
            }
            //throw error;
        }).bind(this));
    },

    onSongAddedOrRemovedFromQueue : function(){
        if(this.model &&  this.model.getAllLoadedSongs && !this._removeFromDom){
            //this.$queueRefreshButtonEl.addClass("animate-spin");
            this.infiniteListView.setUpdating(true);
            try{
                const jsonSongArray  = this.model.getAllLoadedSongs();
                //this.$queueRefreshButtonEl.removeClass("animate-spin");
                const p = this.infiniteListView.updateModels(this._keepCapsulesAndSongsOnly(jsonSongArray));
                this.infiniteListView.setUpdating(false);
                return p;
            }catch(error){
                //this.$queueRefreshButtonEl.removeClass("animate-spin");
                this.infiniteListView.setErrorMessage(error);
                //throw error;
            }
        }
    },

    _getOnListItemTapHandler : function(){
        return  (function(model,evnt, item) {
            this.trigger("itemTaped",model);		
        }).bind(this);
    },

    _onItemSwipeRight : function(){
        return  (function(model,evnt, item){

            this.trigger("itemSwipedRight",model);
        }).bind(this);
    },

    _modelTransformHandlerBeforeTemplatePiping : function(model){
        //TODO: this parsing of the song title or artist name need to be added to the song/album model
        let artistToDisplay, titleToDisplay;
        if (model.idified  && (model.idified ==="true" || model.idified === true) && (model.idifiedSuccess ==="true" || model.idifiedSuccess ===true))
        {
            if(window.trebble.config.showIdifiedArtistName)
            {
                artistToDisplay = model.idifiedArtist;
            }
            if(window.trebble.config.showIdifiedSongTitle)
            {
                titleToDisplay = model.idifiedTitle; 
            }
        }else{		
            artistToDisplay = model.artist;
            titleToDisplay = model.title;
        }
        if(!artistToDisplay)
        {
            artistToDisplay = "";
        }
        if(!titleToDisplay)
        {
            titleToDisplay = "";
        }
        /*const explicit = model.idified? model.idifiedExplicit : model.explicit;
        if(explicit){
            titleToDisplay = titleToDisplay + "<e class='trebbleExplicitIcon'></e>";
        }*/
        const templateData = {firstLineText : titleToDisplay, secondLineText : artistToDisplay, "model" : model};
        return templateData;
    },

    _getOnListButtonTappedHandler :function(){
        return  (function(songJson,evnt, item) {
            this.trigger("itemOptionBtnTapped",songJson);		
        }).bind(this);
    },

    _setImageBackgroundOnPLaylistInfoBox : function(backgroundImageBase64){
        const tempImg = new Image();
        tempImg.onload  = (function(){
            const blurredData = tempImg.src;
            this.$el.find("#playerSocialInfoWrapperInQueue").css("background-image","url('"+blurredData+"')");
        }).bind(this);
        tempImg.src = "data:image/png;base64,"+ backgroundImageBase64;
    },

    _onPageShow : function(){
       // if(!this._loadedAtleastOnce){
           // this._deferListLoading();
       // }
    },

    render : function() {
        this.$el.html(this.template({"model" :this.model.toJSON(),"playlistName": this.model.getPlaylistName(), "ti18n": ti18n}));
        this.setElement(this.$el.find("div").first());
        return this._buildSongList().then((function(){
            return this.model.getPlaylist().getType();
        }).bind(this)).then((function(playlistType){
            this.$el.page();
            this.$el.on("pagehide", this._onPageHide.bind(this));
            //this.$el.on("pageshow", this._onPageShow.bind(this));
            this._deferListLoading();
            this.$queueRefreshButtonEl = this.$el.find("#queueRefreshButton").first();
            this.$queueRefreshButtonWrapperEl = this.$queueRefreshButtonEl.parent();
            this.$tuninButtonEl = this.$el.find("#tuninButton").first();
            this.$tuninButtonWrapperEl = this.$tuninButtonEl.parent();
            if(this.model.canBeRefresh())
            {
                this.$queueRefreshButtonWrapperEl.show();
            }else{
                this.$queueRefreshButtonWrapperEl.hide();
            }

            if(this.model.canBeTuned() && !DISABLE_QUEUE_TUNING)
            {
                this.$tuninButtonWrapperEl.show();
            }else{
                this.$tuninButtonWrapperEl.hide();
            }
            if(this.model.getPlaylist().getSeedData && playlistType == Playlist.types.DYNAMIC )
            {
                const seedData = this.model.getPlaylist().getSeedData() ;
                if(seedData &&  seedData.get("albumJson"))
                {
                    let albumId = seedData.get("albumJson").albumId;
                    if(seedData.get("doNotUseIdifiedUrl") == true)
                    {
                        albumId = seedData.get("preferredAlbumIdForCoverArt");
                    }
                    const p = CordovaHelper.getInstance().executePluginOperation("MusicFileReaderPlugin","getAlbumArtInfoFromImageStore", [albumId]);
                    p.then((function(albumImageInfo){
                        if(albumImageInfo)
                        {
                            const albumArtBase64Stored = albumImageInfo.originalBase64Image;
                            const albumArtBase64BlurStored = albumImageInfo.originalBlurBase64Image;
                            if(albumArtBase64Stored)
                            {
                                this._setImageBackgroundOnPLaylistInfoBox(albumArtBase64BlurStored);
                            }else{
                                this.$el.find("#playerSocialInfoWrapperInQueue").css("background-image","url("+window.getResourceURLAccordingToLocationProtocol("/img/blackWhitebackgroundplayer.jpg")+")");
                            }
                        }else{
                            this.$el.find("#playerSocialInfoWrapperInQueue").css("background-image","url("+window.getResourceURLAccordingToLocationProtocol("/img/blackWhitebackgroundplayer.jpg")+")");
                        }

                    }).bind(this)).catch((function(error){
                        window.alertErrorMessage(error);
                        this.$el.find("#playerSocialInfoWrapperInQueue").css("background-image","url("+window.getResourceURLAccordingToLocationProtocol("/img/blackWhitebackgroundplayer.jpg")+")");
                    }).bind(this));

                }
            }
            return this;
        }).bind(this));

},

onBeforeRemoveFromPage : function(){
this.trigger("beforeRemoveFromPage", this);
},

onRemovedFromPage : function(){
this.undelegateEvents();
this.$el.removeData().unbind(); 
this.trigger("removedFromPage", this);
},

_onPageHide : function(){

if(PersistentModels.getInstance().getRouter().isBrowserHistoryGoingBack())
{
PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._listenerViewportKey);
this.onBeforeRemoveFromPage();
this.remove();
this.onRemovedFromPage();
}
},


_onNodeRemovedFromDom : function(){
this._removeFromDom  = true;
PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._listenerViewportKey);
},

_deferListLoading: function(){
setTimeout((function(){
this.onPlaylistReset();
}).bind(this), 0);
},

onActionExecutedOnSong : function(songModel, action){
if(action == "delete" || action == "removeFromRadio" || action == "removeToFavorite"){
this.displayedCollection.remove(songModel);
this.infiniteListView.reload();
}
},

onSongGradeButtonTapped : function(songModel){

return this.trigger("gradeButtonTapped",songModel);

},


onAddToRadioButtonTapped : function(songModel){
return this.trigger("addToRadioButtonTapped",songModel);
},



onRemoveFromRadioButtonTapped : function(songModel){
return this.trigger("removeFromRadioButtonTapped",songModel);

},

_keepCapsulesAndSongsOnly : function(songJSONArray){
const context = this.model.getPlaylist().getContext();
const isCapsuleFeed =  Utils.getInstance().isContextTypeCapsuleFeed(context);
const isCategoryShortcastRadioPlaylist = Utils.getInstance().isContextTypeCategoryShortcastRadio(context);
if((isCapsuleFeed || isCategoryShortcastRadioPlaylist) && APPLY_FILTER_ON_AUDIO_DISPLAYED_IN_QUEUE_FOR_CAPSULE_FEED){
const filteredArray = [];
if(songJSONArray && songJSONArray.length){
    for(let i=0; i < songJSONArray.length; i++){
        const songJson = songJSONArray[i];
        const uri = songJson.uri
        if(Utils.getInstance().isSongUri(uri) || Utils.getInstance().isCapsuleUri(uri)){
            filteredArray.push(songJson);
        }
    }
}
return filteredArray;
}else{
return songJSONArray;	
}

},

_buildSongList : function(){
const listRootElement = this.$el.find("#songList");
listRootElement.css("height",PersistentModels.getInstance().getRouter().getViewportHeight().toString() +'px');
const listParams ={};
listParams.model = [];

listParams.collection = this.model.getPlaylist();
listParams.listRootElement = listRootElement.get(0);
listParams.defaultNumberOfElements = 50;
listParams.defaultHeightElement = 60;
listParams.doNotShowAddToRadioButton = true;
listParams.doNotShowGradeSongButton = true;
listParams.doNotShowMoreInfoButton = true;
listParams.showInfoButton = true;
listParams.showCommentButton = false;
listParams.showCoverArt =  true;
listParams.placeHolderHeight = PersistentModels.getInstance().getRouter().getViewportHeight() - QUEUE_INFO_SECTION_HEIGHT;
const infiniteListView =  new TrebbleSongListView(listParams);
infiniteListView.setSwipeRightRevealEnabled(false);
//infiniteListView.setModelTransformHandlerBeforeTemplatePiping(this._modelTransformHandlerBeforeTemplatePiping);
infiniteListView.addOnItemTapHandler(this._getOnListItemTapHandler());
infiniteListView.addOnOptionButtonTappedHandlers(this._getOnListButtonTappedHandler());
infiniteListView.addOnSwipeRightRevealHandler(this._onItemSwipeRight());
infiniteListView.render();
this.listenTo(infiniteListView, "actionExecutedOnSongHandler", this.onActionExecutedOnSong.bind(this));
this.listenTo(infiniteListView, "addToRadioButtonTapped", this.onAddToRadioButtonTapped);
this.listenTo(infiniteListView, "removeFromRadioButtonTapped", this.onRemoveFromRadioButtonTapped);
this.listenTo(infiniteListView, "gradeButtonTapped", this.onSongGradeButtonTapped);
this.infiniteListView = infiniteListView; 
this.$el.on("remove",this._onNodeRemovedFromDom.bind(this));
this._onOrientationChangeFunction(PersistentModels.getInstance().getRouter().getViewportHeight(), true);

return RSVP.Promise.resolve();
/*
return this.model.getAllLoadedSongs().then((function(jsonSongArray){


}).bind(this));*/

}

});

export default QueuePageView;