

import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerOperation from "models/audioEditor/sequencerOperation/SequencerOperation";

const CutAndCopyToClipboardOperation =  SequencerOperation.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperation.apply(this, [attributes, options]);
    },

    execute : function(startNodeToCut, endNodeToCut){
        if(this.isStartNodeAndEndNodeConnected(startNodeToCut, endNodeToCut)){
            const arrayOfSequencerNodeToCut = this.getArrayOfSequencerNodes(startNodeToCut, endNodeToCut);
            const sequencerNodeBeforeStartNode = startNodeToCut.getPrevious();
            this.cutAndCopyToClipboard(arrayOfSequencerNodeToCut);
            this.getSequencer().getHistoryManagement().addSequencerOperationCutAndCopyToClipboardOperationLog(arrayOfSequencerNodeToCut, sequencerNodeBeforeStartNode);
        }
    },

    cutAndCopyToClipboard: function(arrayOfSequencerNodeToCut){
        const startNodeToCut = arrayOfSequencerNodeToCut[0]; 
        const endNodeToCut = arrayOfSequencerNodeToCut[arrayOfSequencerNodeToCut.length - 1];
        if(this.isStartNodeAndEndNodeConnected(startNodeToCut, endNodeToCut)){
            this.removeAudioNodeArray(arrayOfSequencerNodeToCut);
            let lastSequencerNodeConnected = startNodeToCut;
            arrayOfSequencerNodeToCut.forEach(function(sequencerNodeToConnect){
                if(sequencerNodeToConnect !== lastSequencerNodeConnected){
                    lastSequencerNodeConnected.setNext(sequencerNodeToConnect);
                }
                lastSequencerNodeConnected = sequencerNodeToConnect;
            })


            if(startNodeToCut == this.getSequencer().getFirstNode() || endNodeToCut == this.getSequencer().getFirstNode()){
                this.getSequencer().setFirstNode(null);
            }
            if(startNodeToCut == this.getSequencer().getLastNode() ||  endNodeToCut == this.getSequencer().getLastNode()){
                this.getSequencer().setLastNode(null);
            }
            this.getSequencer().updateClipboard(startNodeToCut, endNodeToCut, true);
            return true;
        }else{
            return false;
        }

    },

    undo: function(cutAndCopyToClipboardOperationLog){
        const arrayOfSequencerNodeThatWasCut = cutAndCopyToClipboardOperationLog.getArrayOfSequencerNodeToCut();
        const sequencerNodeToInsertNextTo = cutAndCopyToClipboardOperationLog.getSequencerNodeToInsertNextTo();
        const lastSequencerNodeToDisconnect = arrayOfSequencerNodeThatWasCut
        arrayOfSequencerNodeThatWasCut.forEach(function(sequencerNodeToDisconnect){
            sequencerNodeToDisconnect.clearPrevious();
            sequencerNodeToDisconnect.clearNext();
        })
        this.insertAudioNodeArray(arrayOfSequencerNodeThatWasCut, sequencerNodeToInsertNextTo, false );
    },

    redo: function(cutAndCopyToClipboardOperationLog){
        this.cutAndCopyToClipboard(cutAndCopyToClipboardOperationLog.getArrayOfSequencerNodeToCut());
    },





});

export default CutAndCopyToClipboardOperation; 