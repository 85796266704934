import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import BasicSequencerNodeInspectorView from "views/audioEditor/sequencerNodeInspectorSection/BasicSequencerNodeInspectorView";
import InfoBoxView from "views/common/InfoBoxView";
import AudioBufferHelper from "models/audioEditor/AudioBufferHelper";
import ti18n from 'i18n!nls/Sequenceri18n';
import RSVP from 'rsvp';


const NoElementSelectedNodeInspectorView = BasicSequencerNodeInspectorView.extend({


    constructor : function(attributes, options) {
        BasicSequencerNodeInspectorView.apply(this, [attributes, options]);

    },

    listenToChangeEventsOnModel :function(){

    },


    getCustomCssClass : function(){
        return "no_element_selected_inpector_container";
    },


    renderAllParamViews : function(){
        const paramViewArray  = [];
        const infoBoxView =  new InfoBoxView({
            "iconClass" :"soundwave-text-selection",
            "message" :window.getI18n(ti18n, "NO_ELEMENT_SELECTED"),
            "helpMessage" :window.getI18n(ti18n, "SELECT_AN_ELEMENT_TO_INSPECT_AND_MODIFY")
        });


        infoBoxView.render();
        paramViewArray.push(infoBoxView);

        return paramViewArray;
    }


});
export default NoElementSelectedNodeInspectorView;