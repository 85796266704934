import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import ExplorePageView from "views/explore/ExplorePageView";
import CordovaHelper from "models/helper/CordovaHelper";
import PlaylistHelper from "models/helper/PlaylistHelper";
import PersistentModels from "services/PersistentModels";
import CustomSelectFieldController from "controllers/common/CustomSelectFieldController";
import Utils from "models/helper/Utils";
import ListItemCollection from "collections/ListItemCollection";
import CollectionInfiniteCarouselController from "controllers/common/CollectionInfiniteCarouselController";
import TrebbleCardController from "controllers/playlistPodcast/TrebbleCardController";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import ti18n from "i18n!nls/ExplorePagei18n";
import PlayerService from "services/PlayerService";
import RSVP from "rsvp";

const ALL_GENRE_ID = "ALL_GENRES";
const ALL_GENRE_LABEL = window.getI18n(ti18n, "ALL_GENRE_LABEL");
const MY_SUBSCRIPTIONS_ID = "MY_SUBSCRIPTIONS";
const MY_SUBSCRIPTIONS_LABEL = window.getI18n(ti18n, "MY_SUBSCRIPTIONS_LABEL");
const SPECIAL_SELECTIONS_INFO = [];
SPECIAL_SELECTIONS_INFO.push(Utils.getInstance().getListItemModel(MY_SUBSCRIPTIONS_ID,  MY_SUBSCRIPTIONS_LABEL));
SPECIAL_SELECTIONS_INFO.push(Utils.getInstance().getListItemModel(ALL_GENRE_ID,  ALL_GENRE_LABEL));
const ExplorePageController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if (options) {

        }
        this.genreSelectFieldController = this._buildGenreSelectFieldController();
        this.listenTo(this.genreSelectFieldController, "selectionChanged",this._onGenreSelectionChanged.bind(this) );
        this._currentPlaylistSummaryModel = null;
        this._currentGenreInfoListItemModel = null;
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    showHomePopup : function(){
        PersistentModels.getInstance().getRouter().showHomePage();
    },

    _buildGenreSelectFieldController : function(){
        const controllerParams = {};
        controllerParams.singleSelect =  true;
        controllerParams.propertyFunction = "getLabel";
        controllerParams.propertyIdFunction = "getId";
        controllerParams.showEmtptyInfoBox  = false;
        controllerParams.loadHandler = this.getAllTrebbleGenres.bind(this);
        controllerParams.defaultText = window.getI18n(ti18n, "BROWSE");
        return new CustomSelectFieldController(controllerParams);
    },

    getAllTrebbleGenres : function(){
        return TrebbleClientAPIHelper.getInstance().retrieveAllTrebbleGenres().then((function(genreInfoArray){
            const listItemCollection = new ListItemCollection();
            if(genreInfoArray){
                for(let index  in genreInfoArray){
                    const genreInfo =  genreInfoArray[index];
                    listItemCollection.add(Utils.getInstance().getListItemModel(genreInfo.genreId,  genreInfo.genre, genreInfo));
                }
                
            }
            listItemCollection.sort();
            for(let i in SPECIAL_SELECTIONS_INFO){
                listItemCollection.add(SPECIAL_SELECTIONS_INFO[i],{"sort": false,"at": i});
            }
            return listItemCollection.models;
        }).bind(this));
    },

    getAllTrebbleCategories : function(){
        return new RSVP.Promise((function(resolve, reject){
            return resolve(Utils.getInstance().getCapsuleCategoryListItemArray());
        }).bind(this))
        
    },

    isGenreDifferentFromCurrentSelected: function(genreInfoListItemModel){
        return (this._currentGenreInfoListItemModel || genreInfoListItemModel) &&( 
            (!this._currentGenreInfoListItemModel && genreInfoListItemModel) || 
            (this._currentGenreInfoListItemModel && !genreInfoListItemModel) || 
            (this._currentGenreInfoListItemModel.getId() != genreInfoListItemModel.getId()));
    },

    _isGenreCustomGenre : function(genreInfoListItemModel){
        if(genreInfoListItemModel){
            const additionalData = genreInfoListItemModel.getAdditionalData();
            if(additionalData && additionalData.isCustomGenre){
                return true;
            }
        }else{
            return false;
        }
    },

    _isSpecialGenereListItem : function(genreInfoListItemModel){
        if(genreInfoListItemModel){
            for(let i in SPECIAL_SELECTIONS_INFO){
                if(SPECIAL_SELECTIONS_INFO[i].getId() === genreInfoListItemModel.getId()){
                    return true;
                }
            }
        }
        return false;

    },

    _onGenreSelectionChanged : function(genreInfoListItemModel){
        if(this.isGenreDifferentFromCurrentSelected(genreInfoListItemModel)){
            if(this.collectionInfiniteCarouselController){
                this.collectionInfiniteCarouselController.destroy();
                this.collectionInfiniteCarouselController = null;
            }
            this._currentGenreInfoListItemModel = genreInfoListItemModel;
            const controllerParams = {};
            if(genreInfoListItemModel && !this._isSpecialGenereListItem(genreInfoListItemModel) && !this._isGenreCustomGenre(genreInfoListItemModel)){
                const tuneOptions= [];
                tuneOptions.push(genreInfoListItemModel.getId());
                controllerParams.tuneOptions = tuneOptions;
            }else{
                controllerParams.tuneOptions = null
            }
            controllerParams.customImageUrlProperty = "data-src";
            const collectionRepeatControllerParams = {};
            collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildInfoBoxParams();
            collectionRepeatControllerParams.showEmtptyInfoBox = true;
            collectionRepeatControllerParams.controllerOption = controllerParams;
            collectionRepeatControllerParams.itemControllerConstructor = TrebbleCardController;
            collectionRepeatControllerParams.onSlideChangeHandler = this.onActiveTrebbleChanged.bind(this);
            collectionRepeatControllerParams.itemWidth = 450;
            collectionRepeatControllerParams.modelLoadHandler = this._getTrebbleLoadHanderForGenre(genreInfoListItemModel);
            /*if(options.context){
                collectionRepeatControllerParams.context = options.context;
            }*/
            this.collectionInfiniteCarouselController = new CollectionInfiniteCarouselController(collectionRepeatControllerParams);
            this.collectionInfiniteCarouselController.buildModelAndRenderView().then((function(){
                this.view.setCarouselView(this.collectionInfiniteCarouselController.getView());
            }).bind(this));
        }
    },

    _buildInfoBoxParams : function(){
        const params = {};
        params.iconClass = "pe-7s-radio";
        params.message = window.getI18n(ti18n, "YOU_RE_NOT_SUBSCRIBED_TO_ANY_TREBBLE");
        params.helpMessage = window.getI18n(ti18n, "SUBSCRIBE_TO_TREBBLE_YOU_LIKE");
        return params;

    },


    _getPlaylistSummariesForGenereInfo : function(genreInfoListItemModel){
        const genreLabel = genreInfoListItemModel? genreInfoListItemModel.getLabel(): null;
        if(genreLabel ===  ALL_GENRE_LABEL){
            return TrebbleClientAPIHelper.getInstance().retrieveTrebbleByGenres("");
        }
        if(genreLabel === MY_SUBSCRIPTIONS_LABEL){
            return TrebbleClientAPIHelper.getInstance().getFollowersPersonalUserRadios();
        }
        return TrebbleClientAPIHelper.getInstance().retrieveTrebbleByGenres(genreLabel);
    },

    _getTrebbleLoadHanderForGenre  : function(genreInfoListItemModel){
        return (function(numberOfItemToLoad, offset){
            if(this.allPlaylistSummaryModelArray){
                let result  = null;
                if(offset + numberOfItemToLoad <= this.allPlaylistSummaryModelArray.length){
                    result = this.allPlaylistSummaryModelArray.slice(offset, offset+numberOfItemToLoad );
                }
                return RSVP.Promise.resolve(result);
            }else{
                return this.self._getPlaylistSummariesForGenereInfo(this.genreInfoListItemModel).then((function(playlistSummaryArray){
                    const summaryModelArray = [];
                    if(playlistSummaryArray){
                        for(let index  in playlistSummaryArray){
                            const playlistSummaryJson =  playlistSummaryArray[index];
                            summaryModelArray.push(Utils.getInstance().buildPlaylistSummaryFromJson(playlistSummaryJson));
                        }
                    }
                    this.allPlaylistSummaryModelArray = summaryModelArray;
                    let result  = this.allPlaylistSummaryModelArray;
                    if(offset + numberOfItemToLoad <= this.allPlaylistSummaryModelArray.length){
                        result = this.allPlaylistSummaryModelArray.slice(offset, offset+numberOfItemToLoad );
                    }
                    return result;
                }).bind(this));
            }
        }).bind({"self": this, "genreInfoListItemModel": genreInfoListItemModel});

},

onActiveTrebbleChanged : function(playlistSummaryModel){
this._currentPlaylistSummaryModel = playlistSummaryModel;
this.view.setBackgroundImage(playlistSummaryModel?playlistSummaryModel.getCoverArtUrlBlur(): null);
if(this._autoPreviewEnabled){
const genreId = this._currentGenreInfoListItemModel?this._currentGenreInfoListItemModel.getId():null;
const isCustomGenre = this._isGenreCustomGenre(this._currentGenreInfoListItemModel);
const isSpecialGenre = this._isSpecialGenereListItem(this._currentGenreInfoListItemModel);
let genreAudioPreviewUrl = (genreId && !isSpecialGenre && !isCustomGenre)?playlistSummaryModel.getAudioPreviewUrlByGenreId(genreId):null;
if(!genreAudioPreviewUrl){
    genreAudioPreviewUrl = playlistSummaryModel.getAudioPreviewUrl();
    if(genreAudioPreviewUrl && !isSpecialGenre && !isCustomGenre){
        Utils.getInstance().showShortToastMessage(window.getI18n(ti18n,"PREVIEW_GENERAL_PREVIEW"));
    }
}
if(genreAudioPreviewUrl){
    PersistentModels.getInstance().getPlayer().playAudioPreview(genreAudioPreviewUrl);
}else{
    Utils.getInstance().showShortToastMessage(window.getI18n(ti18n,"NO_PREVIEW_YET_AVAILABLE"));
}
}

},

onAutoPreviewEnabled : function(autoPreviewEnabled){
this._autoPreviewEnabled = autoPreviewEnabled;
if(!this._autoPreviewEnabled){
PersistentModels.getInstance().getPlayer().terminateAudioPreviewAndResumePlayback();
}else{
if(this._currentPlaylistSummaryModel){
    this.onActiveTrebbleChanged(this._currentPlaylistSummaryModel);
}
}
},

onPageShow : function(){
if(this.collectionInfiniteCarouselController){
this.collectionInfiniteCarouselController.updateSwiperWidget();
}
},

buildModelAndRenderView : function() {
const promiseArray = [];
promiseArray.push(this.genreSelectFieldController.buildModelAndRenderView());
return RSVP.Promise.all(promiseArray).then((function(){
return new RSVP.Promise((function(resolve, reject) {
    try {
        const options = {};
        options.genreSelectFieldView  = this.genreSelectFieldController.getView();
        this.view = new ExplorePageView(options);
        this.view.setBackgroundImage(null);
        this.listenTo(this.view, "showHomePopup", this.showHomePopup);
        this.listenTo(this.view, "autoPreviewEnabled", this.onAutoPreviewEnabled);
        this.listenTo(this.view, "pageShow", this.onPageShow);

        this.view.render();
        this.view.preloadBackgroundImage().then(function(){
            resolve();
        }).catch(function(err){
            console.error(window.getI18n(ti18n,"FAILED_PRELOADING_EXPLORE_PAGE") +err);
            resolve();
        })

    } catch (error) {
        reject(error);
    }

}).bind(this));
}).bind(this));

}

});

export default ExplorePageController;