		import $ from 'jquery';
        import _ from 'underscore';
        import Backbone from "backbone";
        import MixpanelJS from "mixpanel";
        import LocalStorageHelper from "models/helper/LocalStorageHelper";
        import FeatureRolloutHelper from "models/helper/FeatureRolloutHelper";
        import PusherHelper from "models/helper/PusherHelper";
        import Utils from "models/helper/Utils";
        import SubscriptionManager from "models/helper/SubscriptionManager";
        import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
        import RSVP from "rsvp";


        const eventTypes = {
            "JOURNEY_PURCHASE_INITIATED": "journeyPurchaseInitiated",
            "JOURNEY_PURCHASE_COMPLETED": "journeyPurchaseCompleted",
            "JOURNEY_PURCHASE_INTERUPTED": "journeyPurchaseInterupted",
            "JOURNEY_PURCHASE_BEING_FULLFILLED": "journeyPurchaseBeingFullfilled",
            "JOURNEY_PURCHASE_BEING_VERIFIED":"journeyPurchaseBeingVerified",
            "PURCHASE_OF_JOURNEY_COMPLETED_AND_PAID":"purchaseOfJourneyCompletedAndPaid",
            "PURCHASE_OF_JOURNEY_FULLFILLED":"purchaseOfJourneyFullfilled",
            "PURCHASE_OF_JOURNEY_PAYMENT_FAILED":"purchaseOfJourneyPaymentFailed"
        }

        const Rollout = FeatureRolloutHelper.getInstance();
        const OPEN_NEW_TAB_ON_WEB =  Rollout.getFeatureVariable(Rollout.FEATURES.JOURNEY_PURCHASE_CONFIG, Rollout.FEATURES.JOURNEY_PURCHASE_CONFIG.variables.open_stripe_checkout_in_new_window_on_web);
        const USE_NATIVE_PAYMENT_ON_IOS =  Rollout.getFeatureVariable(Rollout.FEATURES.JOURNEY_PURCHASE_CONFIG, Rollout.FEATURES.JOURNEY_PURCHASE_CONFIG.variables.use_native_payment_on_ios);
        const USE_NATIVE_PAYMENT_ON_ANDROID =  Rollout.getFeatureVariable(Rollout.FEATURES.JOURNEY_PURCHASE_CONFIG, Rollout.FEATURES.JOURNEY_PURCHASE_CONFIG.variables.use_native_payment_on_android);
        const PurchaseManager = Backbone.Model.extend({
            initialize : function(options) {
                this.init();
            },

            init : function(){
                
                try{
                    this.listenTo( PusherHelper.getInstance(),PusherHelper.getInstance().eventTypes.WEB_PUSH_NOTIFICATION, this._processNotificationEventWeb.bind(this));
                }catch(e){
                    console.error("Failed initializing Purchase Manager. Error:"+e);
                }
                return RSVP.Promise.resolve();
            },

            _getAuthenticatedUserId : function(){
                const userInfo = LocalStorageHelper.getInstance().getAuthenticatedUserInfo();
                return userInfo? userInfo._id: null;

            },

            initiateJourneyPurchase: function(journeySummaryModel){
                if(!window.waitForCordovaToLoad){
                    return this.initiateJourneyPurchaseViaStripe(journeySummaryModel.getLoaderKey());
                }
                if(window.isIOSMobileDevice ){
                    if(USE_NATIVE_PAYMENT_ON_IOS){
                        return this.initiateJourneyPurchaseViaMobileNative(journeySummaryModel);
                    }else{
                        return this.initiateJourneyPurchaseViaStripe(journeySummaryModel.getLoaderKey());
                    }
                }else{
                    if(USE_NATIVE_PAYMENT_ON_ANDROID){
                        return this.initiateJourneyPurchaseViaMobileNative(journeySummaryModel);
                    }else{
                        return this.initiateJourneyPurchaseViaStripe(journeySummaryModel.getLoaderKey());
                    }
                }
            },

            initiateJourneyPurchaseViaMobileNative : function(journeySummaryModel){
                if(journeySummaryModel){
                    this.trigger(eventTypes.JOURNEY_PURCHASE_INITIATED,journeySummaryModel.getLoaderKey());
                    const onOrderCompleted = (function(){
                        this.trigger(eventTypes.JOURNEY_PURCHASE_COMPLETED,journeySummaryModel.getLoaderKey());
                    }).bind(this)
                    const onOrderCancelled = (function(){
                        this.trigger(eventTypes.JOURNEY_PURCHASE_INTERUPTED,journeySummaryModel.getLoaderKey());
                    }).bind(this)
                    const onOrderError = (function(error){
                        //window.alertErrorMessage(error);
                        this.trigger(eventTypes.JOURNEY_PURCHASE_INTERUPTED,journeySummaryModel.getLoaderKey());
                    }).bind(this)
                    const onOrderBeingFullfilled = (function(){
                        this.trigger(eventTypes.JOURNEY_PURCHASE_BEING_FULLFILLED,journeySummaryModel.getLoaderKey());
                    }).bind(this)

                    const onOrderBeingVerified = (function(){
                        this.trigger(eventTypes.JOURNEY_PURCHASE_BEING_VERIFIED,journeySummaryModel.getLoaderKey());
                    }).bind(this)
                    return SubscriptionManager.getInstance().initiateJourneyPurchase(journeySummaryModel, onOrderCompleted, onOrderCancelled, onOrderError, onOrderBeingFullfilled, onOrderBeingVerified).catch((function(error){
                        window.alertErrorMessage(error);
                        this.trigger(eventTypes.JOURNEY_PURCHASE_INTERUPTED,journeySummaryModel.getLoaderKey());
                    }).bind(this));
                }else{
                    return RSVP.Promise.resolve();
                }

            },

            initiateJourneyPurchaseViaStripe : function(journeyRadioId){
                if(journeyRadioId){
                    this.trigger(eventTypes.JOURNEY_PURCHASE_INITIATED,journeyRadioId);
                    const forwardUrlOnPurchaseSuccessfull = this.getOnStripePurchaseSuccessfullURL(journeyRadioId);
                    const forwardUrlOnPurchaseCancelled = this.getOnStripePurchaseCancelledURL(journeyRadioId);
                    return TrebbleClientAPIHelper.getInstance().getStripeUrlForPurchasingJourney(journeyRadioId, forwardUrlOnPurchaseSuccessfull,forwardUrlOnPurchaseCancelled).then((function(stripeUrlToPayForJourney){
                        if(OPEN_NEW_TAB_ON_WEB || window.waitForCordovaToLoad){
                            const openedWindowRef =  window.open(stripeUrlToPayForJourney);
                            if(!openedWindowRef){
                                window.location = stripeUrlToPayForJourney;
                            }
                            const onWindowCloseCallback = (function(){
                                this.trigger(eventTypes.JOURNEY_PURCHASE_COMPLETED,journeyRadioId);
                            }).bind(this);
                            Utils.getInstance().listenOnWindowClose(openedWindowRef, onWindowCloseCallback);
                        }else{
                            window.location = stripeUrlToPayForJourney;
                        }
                    }).bind(this)).catch((function(error){
                        window.alertErrorMessage(error);
                        this.trigger(eventTypes.JOURNEY_PURCHASE_INTERUPTED,journeyRadioId);
                    }).bind(this));
                }else{
                    return RSVP.Promise.resolve();
                }

            },

            getOnStripePurchaseSuccessfullURL : function(journeyRadioId){
                const urlRoute =  "onStripePurchaseSuccessfull";
                if(window.waitForCordovaToLoad){
                    return "https://web.trebble.fm/"+ urlRoute;
                }else{
                    if(window.isWebAppInProduction()){
                        if(OPEN_NEW_TAB_ON_WEB){
                            return "https://web.trebble.fm/"+urlRoute;
                        }else{
                            return "https://web.trebble.fm/successJourneyPurchase/"+journeyRadioId;
                        }
                    }else{
                        if(OPEN_NEW_TAB_ON_WEB){
                            return "https://localhost:8000/"+urlRoute;
                        }else{
                            return "https://localhost:8000/successJourneyPurchase/"+journeyRadioId;
                        }
                    }
                }
            },



            getOnStripePurchaseCancelledURL : function(journeyRadioId){
                const urlRoute =  "onStripePurchaseCancelled";
                if(window.waitForCordovaToLoad){
                    return "https://web.trebble.fm/"+ urlRoute;
                }else{
                    if(window.isWebAppInProduction()){
                        if(OPEN_NEW_TAB_ON_WEB){
                            return "https://web.trebble.fm/"+urlRoute;
                        }else{
                            return "https://web.trebble.fm/j/"+journeyRadioId;
                        }
                    }else{
                        if(OPEN_NEW_TAB_ON_WEB){
                            return "https://localhost:8000/"+urlRoute;
                        }else{
                            return "https://localhost:8000/j/"+journeyRadioId;
                        }
                    }
                }
            },


            _processNotificationEventWeb: function(event, wasDeferred) {
                const isForeground = true;
                return  TrebbleClientAPIHelper.getInstance().getNotificationCustomPayloadByNotificationId(event.notificationId).then((function(customPlayloadJson){
                    const customPlayloadModel =  Utils.getInstance().getModelFromCustomNotificationPayload(customPlayloadJson);
                    const notiticationType =  customPlayloadModel.getNotificationType();

                    if(notiticationType == Utils.getInstance().getNotificationTypes().PURCHASE_OF_JOURNEY_COMPLETED_AND_PAID){
                        return this._onJourneyPurchaseCompletedAndPaid(customPlayloadModel.getNotificationData())
                    }

                    if(notiticationType == Utils.getInstance().getNotificationTypes().PURCHASE_OF_JOURNEY_FULLFILLED){
                        return this._onJourneyPurchaseFullfilled(customPlayloadModel.getNotificationData())
                    }

                    if(notiticationType == Utils.getInstance().getNotificationTypes().PURCHASE_OF_JOURNEY_PAYMENT_FAILED){
                        return this._onJourneyPurchasePaymentFailed(customPlayloadModel.getNotificationData())
                    }
                }).bind(this))
            },

            _onJourneyPurchaseCompletedAndPaid : function(notificationData){
                const journeyRadioId = notificationData? notificationData.journeyRadioId: null;
                this.trigger(eventTypes.PURCHASE_OF_JOURNEY_COMPLETED_AND_PAID,journeyRadioId);
            },

            _onJourneyPurchasePaymentFailed : function(notificationData){
                const journeyRadioId = notificationData? notificationData.journeyRadioId: null;
                this.trigger(eventTypes.PURCHASE_OF_JOURNEY_PAYMENT_FAILED,journeyRadioId);

            },

            _onJourneyPurchaseFullfilled : function(notificationData){
                const journeyRadioId = notificationData? notificationData.journeyRadioId: null;
                this.trigger(eventTypes.PURCHASE_OF_JOURNEY_FULLFILLED,journeyRadioId);

            },


        });

        const PurchaseManagerInstance = new PurchaseManager();

        PurchaseManagerInstance.eventTypes = eventTypes;

        window.PurchaseManagerInstanceTest = PurchaseManagerInstance;


        export default {
			getInstance : function() {
				return PurchaseManagerInstance;
			}
		};