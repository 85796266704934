
import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import SoundEnhancerSettingsTemplate from 'text!../../../templates/audioEditor/SoundEnhancerSettingsTemplate.html';
import ti18n from 'i18n!nls/Sequenceri18n';
import RSVP from 'rsvp';

const TRACK_BUTTON_IDS = {"VOICE": "voice_enhancer_btn","MUSIC":"music_enhancer_btn","SOUND":"sound_enhancer_btn"};
const track_settings_array =  [];
const createSettingsParams = function(buttonId, title){
    return {"buttonId": buttonId, "title": title };
}
track_settings_array.push(createSettingsParams(TRACK_BUTTON_IDS.VOICE, window.getI18n(ti18n, "VOICE_ENHANCER")));
track_settings_array.push(createSettingsParams(TRACK_BUTTON_IDS.MUSIC, window.getI18n(ti18n, "MUSIC_ENHANCER")));
track_settings_array.push(createSettingsParams(TRACK_BUTTON_IDS.SOUND, window.getI18n(ti18n, "SOUNDFX_ENHANCER")));


const SoundEnhancerSettingsView = Backbone.View.extend({



    initialize : function(options) {
        this.compiledTemlate =  _.template(SoundEnhancerSettingsTemplate);
        this.model = options? options.model : null;
        this._sequencer = this.model;
    },
    
    events : {

    },

    _getButtonSwitchClassNameByButtonId: function(buttonId){
        return  buttonId +"_switch";
    },

    _getSettingValueByButtonId : function(buttonId){
        return this.$el.find(".switch."+ this._getButtonSwitchClassNameByButtonId(buttonId) +" input").prop('checked');
    },

    _setSettingValueByButtonId : function(buttonId, turnOn){
        this.$el.find(".switch."+ this._getButtonSwitchClassNameByButtonId(buttonId) +"  input").prop('checked', turnOn);
    },

    _listenToClickOnSettingButton : function(buttonId, handler){
        this.$el.find(".switch."+ this._getButtonSwitchClassNameByButtonId(buttonId) +"  input").on("click", (function(event){
            handler(event);
            document.scrollingElement.scrollTop = 0;// fix a bug where the scroll position change when the last switch is toggled
        }).bind(this));
    },

    setVoiceEnhancerOn : function(turnOn){
        this._setSettingValueByButtonId(TRACK_BUTTON_IDS.VOICE, turnOn);
    },

    isVoiceEnhancerOn : function(){
        return this._getSettingValueByButtonId(TRACK_BUTTON_IDS.VOICE);
    },

    onVoiceEnhancerClicked : function(){
        this.trigger("voiceEnhancerChange", this.isVoiceEnhancerOn());
        
    },

    setMusicEnhancerOn : function(turnOn){
        this._setSettingValueByButtonId(TRACK_BUTTON_IDS.MUSIC, turnOn);
    },

    isMusicEnhancerOn : function(){
        return this._getSettingValueByButtonId(TRACK_BUTTON_IDS.MUSIC);
    },

    onMusicEnhancerClicked : function(){
        this.trigger("musicEnhancerChange", this.isMusicEnhancerOn());
    },

    setSoundEnhancerOn : function(turnOn){
        this._setSettingValueByButtonId(TRACK_BUTTON_IDS.SOUND, turnOn);
    },

    isSoundEnhancerOn : function(){
        return this._getSettingValueByButtonId(TRACK_BUTTON_IDS.SOUND);
    },

    onSoundEnhancerClicked : function(){
        this.trigger("soundEnhancerChange", this.isSoundEnhancerOn())
    },

    onSoundEnhancerSettingChange : function(){
        this.setSoundEnhancerOn(this.model.isSoundFiltersOn());
    },

    onMusicEnhancerSettingChange : function(){
        this.setMusicEnhancerOn(this.model.isMusicFiltersOn());
    },

    onVoiceEnhancerSettingChange : function(){
        this.setVoiceEnhancerOn(this.model.isAllDefaultVoiceFiltersOn());
    },
    
    initSwitches : function(){
        this.setVoiceEnhancerOn(this.model.isAllDefaultVoiceFiltersOn());
        this.setMusicEnhancerOn(this.model.isMusicFiltersOn());
        this.setSoundEnhancerOn(this.model.isSoundFiltersOn());
    },

    getModel: function(){
        return this.model;
    },

    render : function() {
        this.$el.html(this.compiledTemlate({"track_settings_array": track_settings_array, "ti18n": ti18n}));
        this.setElement(this.$el.find(".sequencer_settings").first());
        this.initSwitches();

        this._listenToClickOnSettingButton(TRACK_BUTTON_IDS.VOICE, this.onVoiceEnhancerClicked.bind(this));
        this._listenToClickOnSettingButton(TRACK_BUTTON_IDS.MUSIC, this.onMusicEnhancerClicked.bind(this));
        this._listenToClickOnSettingButton(TRACK_BUTTON_IDS.SOUND, this.onSoundEnhancerClicked.bind(this));
        this.listenTo(this.model, "change:musicFiltersOn",  this.onMusicEnhancerSettingChange.bind(this));
        this.listenTo(this.model, "change:soundFiltersOn",  this.onSoundEnhancerSettingChange.bind(this));
        this.listenTo(this.model, "change:allDefaultVoiceFiltersOn",  this.onVoiceEnhancerSettingChange.bind(this));
        
        return this;
    }

    
    

});
export default SoundEnhancerSettingsView;