import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import TrebbleAudioCompilationListItemTemplate from 'text!../../../templates/common/TrebbleAudioCompilationListItemTemplate.html';
import ti18n from 'i18n!nls/TrebbleAudioListItem18n';
import RSVP from 'rsvp';


const TrebbleAudioCompilationListItemView = Backbone.View.extend({



    initialize : function(options) {
        this.compiledTemlate =  _.template(TrebbleAudioCompilationListItemTemplate);
        this.model = options? options.model : null;
        this._customImageUrlProperty = options.customImageUrlProperty? options.customImageUrlProperty : null;
        this._notTransparent = options.notTransparent;
        this._notPlayable = options.notPlayable;
        this._canBeSelected = options.canBeSelected;
        this._canBeDeleted = options.canBeDeleted;
        this._canShowMoreOptions = options.canShowMoreOptions;
        this._covertArtNotDisplayed  = options.covertArtNotDisplayed;
        this._isPlaying  = false;
        this._isLoading = false;
        this.model.on("change:selected",this._onModelPropertyChange.bind(this));
    },
    
    events : {

        "click .play_audio_btn" : "onPlayPauseButtonClick",
        "click .select_button" : "onSelectButtonClicked",
        "click .unselect_button" : "onUnselectButtonClicked",
        "click .delete_button" : "onDeleteButtonClicked",
        "click .more_options_button" : "onMoreOptionsButtonClicked",

        
    },
    
    setPlaybackState : function(isPlaying, isLoading){
        this._isPlaying  = isPlaying;
        this._isLoading = isLoading;
        if(isPlaying ){
            if(!this.$el.hasClass("isCurrentlyPlaying")){
                this.$el.addClass("isCurrentlyPlaying");
            }
        }else{
            this.$el.removeClass("isCurrentlyPlaying");
        }
        if(isLoading ){
            if(!this.$el.hasClass("isCurrentlyLoading")){
                this.$el.addClass("isCurrentlyLoading");
            }
        }else{
            this.$el.removeClass("isCurrentlyLoading");
        }
    },

    onSelectButtonClicked : function(){
        this.trigger("audioCompilationSelected");
    },

    onUnselectButtonClicked : function(){
        this.trigger("audioCompilationUnselected");
    },

    onDeleteButtonClicked : function(){
        this.trigger("onDeleteButtonClicked");
    },

    onMoreOptionsButtonClicked : function(){
        this.trigger("showOptionMenu");
    },


    /*onPlayButtonClick : function(){
        this.trigger("playAudio", this.model);
    },

    onStopButtonClick : function(){
        this.trigger("stopAudio", this.model);
    },
    */

    onPlayPauseButtonClick : function(){
        if(this._isPlaying){
            this.trigger("stopAudio", this.model);
        }else{
            this.trigger("playAudio", this.model);
        }
    },
    
    _onModelPropertyChange: function(){
        const isSelected = this.model.isSelected();
        this.$el.attr("is_selected", !!isSelected);
    },


    
    render : function() {
        const templateParams = {};
        const trebbleJson  = this.model.toJSON();
        this.$el.html(this.compiledTemlate({model: this.model, "customImageUrlProperty": this._customImageUrlProperty, "transparent": !this._notTransparent,  "notPlayable": this._notPlayable,"canShowMoreOptions":this._canShowMoreOptions,"canBeSelected":this._canBeSelected,"canBeDeleted": this._canBeDeleted, "covertArtNotDisplayed":this._covertArtNotDisplayed, "ti18n": ti18n}));
        this.setElement(this.$el.find("div").first());
        this._onModelPropertyChange();

        return this;
    }

});
export default TrebbleAudioCompilationListItemView;