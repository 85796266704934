var amdi18n={"__root":{"ALL_MY_MUSIC_FROM":"All My music From","ALL_SONGS_FROM":"All Songs From","COMING_SOON":"Coming Soon!","WE_COULDNT_FIND_THIS_ALBUM":"We couldn't find this album","MORE":"More","SORT_AND_FILTER":"Sort & Filter","RELEASED_IN":"Released in","TRACKS":"Tracks"},"__fr":{"ALL_MY_MUSIC_FROM":"Toute ma musique de","ALL_SONGS_FROM":"Toutes les chansons de","COMING_SOON":"Arrive bientôt!","WE_COULDNT_FIND_THIS_ALBUM":"Nous n'avons pas pu trouver cet album","MORE":"Plus","SORT_AND_FILTER":"Trier et filtrer","RELEASED_IN":"Sorti en","TRACKS":"Chansons"},"__en":{"ALL_MY_MUSIC_FROM":"All My music From","ALL_SONGS_FROM":"All Songs From","COMING_SOON":"Coming Soon!","WE_COULDNT_FIND_THIS_ALBUM":"We couldn't find this album","MORE":"More","SORT_AND_FILTER":"Sort & Filter","RELEASED_IN":"Released in","TRACKS":"Tracks"}};amdi18n.init=function(language){
		// get the default language
		if(!language){
			if(typeof window !== 'undefined' && window._i18n && window._i18n.locale){
				language = window._i18n.locale;
			}else if(typeof document !== 'undefined' && document.documentElement.lang){
				language = document.documentElement.lang;
			}else if(typeof navigator !== 'undefined' && (navigator.languages || navigator.language || navigator.userLanguage)){
				// navigator.languages does not exist in IE 11
				language = (navigator.languages && navigator.languages[0]) ||
					navigator.language ||
					navigator.userLanguage;
				language = (language || 'root').toLowerCase();
			}else{
				language = 'root';
			}
		}
		var target = this['__' + language] || this.__root;

		// copy definition to root level
		if (target) {
			for (var name in target) {
				this[name] = target[name];
			}
		}

		// fallback to root
		var recursiveFallback = function(target, source){
			for(var name in source){
				switch(typeof target[name]){
				case 'undefined':
					target[name] = source[name];
					break;
				case 'object':
					recursiveFallback(target[name], source[name]);
				}
			}
		};
		recursiveFallback(this, this.__root);
	};amdi18n.init();module.exports=amdi18n;