import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import ProgressBarTemplate from 'text!../../../templates/common/progressBarTemplate.html';
import RSVP from 'rsvp';

const ProgressBarView = Backbone.View.extend({



    initialize : function(options) {
        this.compiledTemlate = (options && options.customTemplate )? _.template(options.customTemplate): _.template(ProgressBarTemplate);
        
    },
    
    setPercentageProgress : function(percentageProgress){
        this.setIndeterminate(this._percentageProgress === "100%" && percentageProgress=== "100%" ?true: false);
        this._percentageProgress = percentageProgress;
        this.percentageProgress$el.html(this._percentageProgress);
        this.currentProgress$el.css({"width": percentageProgress});
    },

    setProgressMessage : function(progressMessage){
        this._progressMessage = progressMessage;
        this.progressMessage$el.html(this._progressMessage);
    },

    setProgressSubMessage : function(progressSubMessage){
        this._progressSubMessage = progressSubMessage;
        this.progressSubMessage$el.html(this._progressSubMessage);
    },

    setIndeterminate : function(indeterminate){
        if(indeterminate){
            this.$el.addClass("inderterminate");
            this.currentProgress$el.css({"width": "100%"});
        }else{
            this.$el.removeClass("inderterminate");
        }
    },
    
    render : function() {
        const params = {"percentageProgress": this._percentageProgress ,"progressMessage": this._progressMessage,"progressSubMessage": this._progressSubMessage};
        this.$el.html(this.compiledTemlate(params));
        this.setElement(this.$el.find("div").first());
        this.percentageProgress$el = this.$el.find("#percentageProgress");
        this.progressMessage$el = this.$el.find("#progressMessage");
        this.progressSubMessage$el = this.$el.find("#progressSubMessage");
        this.currentProgress$el = this.$el.find(".currentProgress");
        return this;
    }

});
export default ProgressBarView;