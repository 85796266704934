import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utils from "models/helper/Utils";
import PersistentModels from "services/PersistentModels";
import OperationProgressPageView from "views/common/OperationProgressPageView";
import OperationProgressPopupView from "views/common/OperationProgressPopupView";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import ti18n from "i18n!nls/SubscribeToTrebblePagei18n";
import RSVP from "rsvp";

const SubscribeToTrebblePageController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this._trebbleId = options.trebbleId;
        this._displayAsPopup =  options.displayAsPopup;
        this._onTrebbleSubscriptionSucessHandler = options?options.onTrebbleSubscriptionSucessHandler : null;
        this._onTrebbleSubscriptionFailureHandler = options?options.onTrebbleSubscriptionFailureHandler : null;
        this._showThankYouMessage =  options? options.showThankYouMessage : false;

    },

    getPageId : function(){
        return "OperationProgressPage";
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    subsribeToTrebble : function(){
        this.view.setLoadingMessage(window.getI18n(ti18n, "RETRIEVING_TREBBLE_INFORMATION"));
        return TrebbleClientAPIHelper.getInstance().getMyTrebbleInfoByTrebbleId(this._trebbleId).then((function(radioJsonInfo){
            if(radioJsonInfo){
                this.view.setLoadingMessage(window.getI18n(ti18n, "GETTING_YOU_SUBSCRIBED_TO")+" "+ radioJsonInfo.name+" ...");
                const personalRadioSummaryModel = Utils.getInstance().buildPlaylistSummaryFromJson(radioJsonInfo);
                return PersistentModels.getInstance().subscribe(personalRadioSummaryModel).then((function(){
                    const headerMessage = this._showThankYouMessage ? window.getI18n(ti18n, "THANKS_FOR_SUBSCRIBING_TO")+" "+radioJsonInfo.name:"";
                    this.view.setSuccessMessage(headerMessage, Utils.getInstance().getHtmlTrebbleListeningInstructions(radioJsonInfo.name, true));
                    if(this._onTrebbleSubscriptionSucessHandler){
                        this._onTrebbleSubscriptionSucessHandler();
                    }
                }).bind(this)).catch((function(error){
                    const errorMessage = error && error.indexOf && error.indexOf( window.getI18n(ti18n, "PLEASE_ALLOW_POPUPS")) != -1? error: window.getI18n(ti18n, "AN_ERROR_OCCURED_WHEN_TRYING_TO_SUBSCRIBE_TO")+" "+radioJsonInfo.name;
                    this.view.setErrorMessage(errorMessage);
                    if(this._onTrebbleSubscriptionFailureHandler){
                        this._onTrebbleSubscriptionFailureHandler(errorMessage);
                    }
                }).bind(this));
            }else{
                this.view.setErrorMessage(window.getI18n(ti18n, "WE_COULDNT_FIND_THE_SPECIFIED_TREBBLE"));
                if(this._onTrebbleSubscriptionFailureHandler){
                    this._onTrebbleSubscriptionFailureHandler(window.getI18n(ti18n, "WE_COULDNT_FIND_THE_SPECIFIED_TREBBLE"));
                }
            }
        }).bind(this));

},

onViewRemoved:function(){
this.stopListening();
},


buildModelAndRenderView : function() {
return new RSVP.Promise((function(resolve, reject) {
try {
    if(this._displayAsPopup){
        this.view = new OperationProgressPopupView();
    }else{
        this.view = new OperationProgressPageView();
    }
    this.listenTo(this.view, "remove", this.onViewRemoved.bind(this));
    this.listenTo(this.view, "onPopupClosed", this.onViewRemoved.bind(this));
    this.view.render();
    this.subsribeToTrebble();
    resolve();
} catch (error) {
    reject(error);
}

}).bind(this));

}

});

export default SubscribeToTrebblePageController;