import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import WrapSequencerNode from "models/audioEditor/sequencerNodes/WrapSequencerNode";
import RSVP from "rsvp";

const CLASSNAME = "EndWrapSequencerNode";
//this class is missing some methods that should be implemented
const EndWrapSequencerNode =  WrapSequencerNode.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        WrapSequencerNode.apply(this, [attributes, options]);
        this._startWrapSequencerNode = attributes.startWrapSequencerNode;
        this.on("nodeInsertedBefore", this._onNodeInsertedBefore.bind(this));
    },

    getConstructor : function(){
        return EndWrapSequencerNode;
    },

    _onNodeInsertedBefore: function(sequencerNodeInserted){
        if(sequencerNodeInserted){
            sequencerNodeInserted.addEndWrapNode(this);
            sequencerNodeInserted.addStartWrapNode(this.getStartWrapSequencerNode());
        }
    },
    
    afterInstanceCreatedFromConstructorForCloning : function(createdClonedInstance, sequencerNodeCidToCloneNodeMap){
        const startNodeCid = this.getStartWrapSequencerNode()? this.getStartWrapSequencerNode().cid: null;
        const cloneStartWrapSequencerNode = startNodeCid ? sequencerNodeCidToCloneNodeMap[startNodeCid]: null;
        if(cloneStartWrapSequencerNode){
            createdClonedInstance._startWrapSequencerNode = cloneStartWrapSequencerNode;
            cloneStartWrapSequencerNode._endWrapSequencerNode = createdClonedInstance;
        }
    },

    restoreFromSerializedData: function(serializedData, audioSegmentFactoryInstance, audioFilterFactoryInstance, additionalSpeakerInfoArray){
        return WrapSequencerNode.prototype.restoreFromSerializedData.call(this, serializedData, audioSegmentFactoryInstance, audioFilterFactoryInstance, additionalSpeakerInfoArray);
    },

    afterAllInstancesCreatedFromSerializedData : function(serializedData, sequencerNodeCidToSequencerNodeMap){
        WrapSequencerNode.prototype.afterAllInstancesCreatedFromSerializedData.call(this, serializedData, sequencerNodeCidToSequencerNodeMap);
        this._startWrapSequencerNode = sequencerNodeCidToSequencerNodeMap[serializedData._startWrapSequencerNodeCid];
    },

    cloneJSON: function(){
        const objJson = WrapSequencerNode.prototype.cloneJSON.call(this);
        objJson.type = EndWrapSequencerNode.CLASSNAME;
        return objJson;
    },

    getSerializedData: function(){
        const serializedData = WrapSequencerNode.prototype.getSerializedData.call(this);
        serializedData._startWrapSequencerNodeCid = this.getStartWrapSequencerNode()? this.getStartWrapSequencerNode().cid: null;
        serializedData._endWrapSequencerNodeCid = this.getEndWrapSequencerNode()? this.getEndWrapSequencerNode().cid: null;
        return serializedData;
    },

    wrapTo :function(startNodeWrapped, endNodeWrapped, checkIfWrapIsValidBeforeWrapping){
        startNodeWrapped =  this.getSelectableStartNodeWrappedIfApplicable(startNodeWrapped);
        endNodeWrapped =  this.getSelectableEndNodeWrappedIfApplicable(endNodeWrapped);
        let wrapingIsValid = false;
        if(checkIfWrapIsValidBeforeWrapping){
            wrapingIsValid = this.canWrapTo(startNodeWrapped, endNodeWrapped);
        }else{
            wrapingIsValid = true;
        }
        if(wrapingIsValid){
            if(this.getStartWrappedNode() && this.getEndWrappedNode()){
                this.unwrap();
            }
            this._startWrapSequencerNode._setupAsStartWrapNodeTo(startNodeWrapped, endNodeWrapped );
            this._setupAsEndWrapNodeTo(startNodeWrapped, endNodeWrapped);
            if(this.getStartWrappedNode() == this.getSequencer().getFirstNode()){
                this.getSequencer().setFirstNode(this._startWrapSequencerNode);
            }
            if(this.getEndWrappedNode() == this.getSequencer().getLastNode()){
                this.getSequencer().setLastNode(this);
            }
        }
    },

    _setupAsEndWrapNodeTo : function(startNodeWrapped, endNodeWrapped ){
        //this.setStartWrappedNode(startNodeWrapped);
        //this.setEndWrappedNode(endNodeWrapped);
        this.insertAfter(endNodeWrapped);
        this._addToWrapNodeAsEndWrapNode(startNodeWrapped, endNodeWrapped);
    },

    _tearDownAsEndWrapNodeTo :function(startNodeWrapped, endNodeWrapped){
        const nextNodeToEndWrap =  this.getNext();
        this.clearNext();
        endNodeWrapped.clearNext();
        if(nextNodeToEndWrap){
            endNodeWrapped.setNext(nextNodeToEndWrap);
        }
        this._removeFromWrapNodeAsEndWrapNode(startNodeWrapped, endNodeWrapped);
        //this.setStartWrappedNode(null);
        //this.setEndWrappedNode(null);
        this.removeFromSequencer();
    },


    _addToWrapNodeAsEndWrapNode : function(startNodeWrapped, endNodeWrapped){
        let nodeToAddAsWrapNode = startNodeWrapped;
        while(nodeToAddAsWrapNode){
            nodeToAddAsWrapNode.addEndWrapNode(this);
            if(nodeToAddAsWrapNode == endNodeWrapped){
                nodeToAddAsWrapNode = null;
            }else{
                nodeToAddAsWrapNode = nodeToAddAsWrapNode.getNext();
            }
        }
    },

    _removeFromWrapNodeAsEndWrapNode : function(startNodeWrapped, endNodeWrapped){
        let nodeToRemoveFromAsWrapNode = endNodeWrapped;
        while(nodeToRemoveFromAsWrapNode){
            nodeToRemoveFromAsWrapNode.removeEndWrapNode(this);
            if(nodeToRemoveFromAsWrapNode == startNodeWrapped){
                nodeToRemoveFromAsWrapNode = null;
            }else{
                nodeToRemoveFromAsWrapNode = nodeToRemoveFromAsWrapNode.getPrevious();
            }
        }
    },

    

    _onStartWrapNodeShrinkTo : function(newStartNodeToWrapTo){
        let nodeToRemoveFromAsWrapNode = this.getStartWrappedNode();
        while(nodeToRemoveFromAsWrapNode == newStartNodeToWrapTo){
            nodeToRemoveFromAsWrapNode.removeEndWrapNode(this);
            if(nodeToRemoveFromAsWrapNode == this.getEndWrappedNode()){
                nodeToRemoveFromAsWrapNode = null;
            }else{
                nodeToRemoveFromAsWrapNode = nodeToRemoveFromAsWrapNode.getNext();
            }
        }
        this.setStartWrappedNode(newStartNodeToWrapTo);
        return nodeToRemoveFromAsWrapNode == newStartNodeToWrapTo;
    },


    unwrap : function(){
        const startWrappedNode = this.getStartWrappedNode();
        const endWrappedNode = this.getEndWrappedNode();
        this._startWrapSequencerNode._tearDownAsStartWrapNodeTo(startWrappedNode, endWrappedNode);
        this._tearDownAsEndWrapNodeTo(startWrappedNode, endWrappedNode);
        if(this._startWrapSequencerNode == this.getSequencer().getFirstNode()){
            this.getSequencer().setFirstNode(startWrappedNode);
        }
        if(this == this.getSequencer().getLastNode()){
            this.getSequencer().setLastNode(endWrappedNode);
        }
    },


    getStartWrapSequencerNode : function(){
        return this._startWrapSequencerNode;
    },

    getEndWrapSequencerNode : function(){
        return this;
    },

    shrinkWrapTo : function(newEndNodeToWrapTo){
        const currentStartNode = this.getStartWrappedNode();
        const newStartNodeToWrapTo = currentStartNode;
        this.wrapTo(newStartNodeToWrapTo, newEndNodeToWrapTo);

    },

    changeEndNodeToWrapTo : function(newEndNodeToWrapTo){
        const currentStartNode = this.getStartWrappedNode();
        const newStartNodeToWrapTo = currentStartNode;
        this.wrapTo(newStartNodeToWrapTo, newEndNodeToWrapTo);
    },



});

EndWrapSequencerNode.CLASSNAME =  CLASSNAME;
export default EndWrapSequencerNode; 