import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";
import ti18n from "i18n!nls/MyLibraryi18n";
import popupTemplate from 'text!../../../templates/mylibrary/SongOptionPopup.html';

const SongOptionView = Backbone.View.extend({

    template : _.template(popupTemplate),

    "pageName" : "My Library",

    initialize : function(options) {
        this._eventsToTriggerAfterPopupIsInactive = {};
        this.isInUserCollection= options? options.isInUserCollection: false;
    },


    events : {


        "click #addToQueueBtn" : "addToQueue",
        "click #playSimilarFromLibraryBtn" : "playSimilarFromLibrary",
        "click #playSimilarBtn" : "playSimilar",
        "click #playSimilarFromFollowersBtn" : "playSimilarFromFollowers",
        "click #browseAlbumBtn" : "browseAlbum",
        //"click #playSimilarOptionsBtn" :"showPlaySimilarAvailableOptions",
        "click #browseArtistBtn" : "browseArtist",
        "click #playFromYoutubeBtn" : "playFromYoubube",
        "click #addToTrebbleBtn" : "addToRadio",
        //"click #capsulesBtn" : "showCapsules",
        "click #capsulesBtn" : "createCapsule",
        "click #addTagsBtn" : "addTags",
        "click #removeFromMyTrebbleBtn" : "removeFromRadio",
        "click #deleteBtn" : "deleteSong",
        "click #commentBtn" : "commentOnSong",
        "click #closeBtn" : "_close",
        "click #shareBtn" : "share",
        "click #statisticsBtn" : "showStatistics"
        /*	"click #playSimilarFromFollowersBtn" : "playSimilarFromFollowers",
        "click #addToPlaylistBtn" : "addToPlaylist",
        "click #shareBtn" : "share",
        "click #DeleteBtn" : "delete",*/
        
    },

    addToQueue : function(){
        this._close().then((function(){
            this.trigger("addToQueue", this.model);
        }).bind(this));
    },
    
    _close : function(){
        const p = new RSVP.Promise((function(resolve, reject){
            try{
                this.$el.on({
                    popupafterclose: (function() {
                        try{
                            resolve();
                        }catch(error){
                            reject(error);
                        }
                        
                    }).bind(this)});
            }catch(error){
                reject(error);
            }
        }).bind(this));
        this.$el.popup('close' );
        return p;
    },

    playSimilarFromLibrary : function(){
        this._close().then((function(){
            this.trigger("playSimilarFromLibrary");
        }).bind(this));
    },
    
    playSimilar : function(){
        this._close().then((function(){
            this.trigger("playSimilar");
        }).bind(this));
    },
    
    addTags : function(){
        this._close().then((function(){
            this.trigger("rate");
        }).bind(this));
    },
    
    showCapsules : function(){
        this._close().then((function(){
            this.trigger("showCapsules");
        }).bind(this));
        
    },

    createCapsule : function(){
        this._close().then((function(){
            this.trigger("createCapsule");
        }).bind(this));
        
    },

    share : function(){
        this._close().then((function(){
            this.trigger("share");
        }).bind(this));
        
    },

    showStatistics : function(){
        this._close().then((function(){
            this.trigger("showStatistics");
        }).bind(this));
        
    },
    
    playSimilarFromFollowers : function(){
        this._close().then((function(){
            this.trigger("playSimilarFromFollowers");
        }).bind(this));	
    },
    
    browseAlbum : function(){
        this._close().then((function(){
            this.trigger("browseAlbum");
        }).bind(this));	
    },
    
    playFromYoubube : function(){
        this._close().then((function(){
            this.trigger("playFromYoubube");
        }).bind(this));
    },

    
    browseArtist : function(){
        this._close().then((function(){
            this.trigger("browseArtist");
        }).bind(this));		
    },
    

    
    addToRadio : function(){
        this._close().then((function(){
            this.trigger("addToRadio");
        }).bind(this));
    },

    
    removeFromRadio : function(){
        this._close().then((function(){
            this.trigger("removeFromRadio");
        }).bind(this));
    },

    deleteSong : function(){
        this._close().then((function(){
            this.trigger("deleteSong");
        }).bind(this));
    },

    commentOnSong : function(){
        this.addEventToTriggerAfterPopupInactive("commentOnSong");
        this._close();
    },
    
    addEventToTriggerAfterPopupInactive : function(eventName, params){
        this._eventsToTriggerAfterPopupIsInactive[eventName] = params;
    },
    
    _triggerEventsAfterPopupInactive : function(){
        for(let eventName in this._eventsToTriggerAfterPopupIsInactive){
            const params = this._eventsToTriggerAfterPopupIsInactive[eventName];
            this.trigger(eventName, params);
        }
    },
    /*
    showPlaySimilarAvailableOptions : function(){
        
        const inMyLibraryOption  = {value : "inMyLibrary" , text : "In My Library"};
        const fromFollowingsOption  = {value : "fromFollowings" , text : "From Followings"};
        const inTrebbleCatalogOption  = {value : "inTrebbleCatalog" , text : "In And Out Of My Library"};
        const availableOptions   = [inMyLibraryOption,fromFollowingsOption, inTrebbleCatalogOption ];
        return Utils.getInstance().showListPicker("Play Similar ...", availableOptions, null, "OK", "Cancel").then((function(selectedValue){
            if(selectedValue){
                if(selectedValue == inMyLibraryOption.value){
                    this.trigger("playSimilarFromLibrary", this.model);
                    this._close();
                    return;
                }
                if(selectedValue == fromFollowingsOption.value){
                    this.trigger("playSimilarFromFollowers", this.model);
                    this._close();
                    return;
                }
                if(selectedValue == inTrebbleCatalogOption.value){
                    this.trigger("playSimilar", this.model);
                    this._close();
                    return;
                }
            }
            this._close();
        }).bind(this)).catch(function(error){
            Utils.getInstance().showErrorPopup(error);
        });
    },*/

    render : function() {
        this.$el.html(this.template({"model":this.model,"ti18n":ti18n,"isInUserCollection": this.isInUserCollection, "isFromSoundcloudOrYoutube": Utils.getInstance().isYoutubeUri(this.model.get("uri")) || Utils.getInstance().isSoundcloudUri(this.model.get("uri")) }));
        this.setElement(this.$el.find("div").first());
        const elementId = this.$el.attr('id');
        this.$el.on({
            popupbeforeposition: (function() {
                const maxHeight = $( window ).height() - 0 + "px";
                const width = $( window ).width() - 0 + "px";
                this.$el.css( "height", maxHeight );
                this.$el.css( "width", width );
                
            }).bind(this)
        });
        this.$el.on({
            popupafterclose: (function() {

                this.remove();
                this._triggerEventsAfterPopupInactive();
                this.undelegateEvents();
                
            }).bind(this)});
        $.mobile.pageContainer.prepend(this.$el);
        this.$el.popup({transition:"fade", dismissible: false,  tolerance: "0,0"});
        if(elementId)
        {
            const screenPopup$el = $("#"+elementId + "-screen");
            $.mobile.pageContainer.remove("#"+elementId + "-screen");
            $.mobile.pageContainer.prepend(screenPopup$el);
        }
        
        this.$el.enhanceWithin();
        Utils.getInstance().closeCurrentJQMPopup().then((function(){
            this.$el.popup('open' );
        }).bind(this));
        return this;
    }

});

export default SongOptionView;