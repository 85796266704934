import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import popupTemplate from 'text!../../../templates/audioEditor/SequencerNodeMoreOptionContent.html';
import ti18n from "i18n!nls/Sequenceri18n";


const SequencerNodeMoreOptionContentView = Backbone.View.extend({


    initialize : function(options) {
        
        this.compiledTemlate =  _.template(popupTemplate);
        this.model = options? options.model : null;
        this._templateParams = {"model" : this.model, "ti18n": ti18n, "clippingAvailble": options.clippingAvailable, "cutAvailable":options.cutAvailable,"copyAvailable": options.copyAvailable, "pasteAvailable": options.pasteAvailable,"transcriptCorrectionAvailable": options.transcriptCorrectionAvailable,"downloadClipAvailble": options.downloadClipAvailble };
    },
    
    events : {
        "click #cutBtn:not(.disabled)": "onCutButtonClicked",
        "click #copyBtn:not(.disabled)": "onCopySelectionToClipboardClicked",
        "click #pasteBtn:not(.disabled)": "onPasteButtonClicked",
        "click #clipSelection:not(.disabled)": "onClipButtonClicked",
        "click #downloadClip:not(.disabled)": "onDownloadClipButtonClicked",
        "click #editTranscriptBtn:not(.disabled)": "onCorrectTranscriptionButtonClicked",
        "click #deleteAllExceptSelectedBtn:not(.disabled)":"onDeleteAllExceptSelectedNodesButtonClicked",
    },

    onCutButtonClicked :function(event){
        if(!$(event.target).hasClass("disabled")){
            this.trigger("cutAndCopySelectionToClipboard");
        }
    },

    onCopySelectionToClipboardClicked :function(event){
        if(!$(event.target).hasClass("disabled")){
            this.trigger("copySelectionToClipboard");
        }
    },


    onPasteButtonClicked :function(event){
        if(!$(event.target).hasClass("disabled")){
            this.trigger("paste");
        }
    },

    onClipButtonClicked :function(event){
        if(!$(event.target).hasClass("disabled")){
            this.trigger("clip");
        }
    },

    onDownloadClipButtonClicked :function(event){
        if(!$(event.target).hasClass("disabled")){
            this.trigger("download");
        }
    },


    onCorrectTranscriptionButtonClicked :function(event){
        if(!$(event.target).hasClass("disabled")){
            this.trigger("correctTranscript");
        }
    },

    onDeleteAllExceptSelectedNodesButtonClicked :function(event){
        if(!$(event.target).hasClass("disabled")){
            this.trigger("deleteAllExceptSelectedNodes");
        }
    },

    getModel: function(){
        return this.model;
    },

    render : function() {
        
        this.$el.html(this.compiledTemlate(this._templateParams));
        this.setElement(this.$el.find("ul").first());
        return this;
    }

    
    

});
export default SequencerNodeMoreOptionContentView;