import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import LoginPageViewNew from "views/login/LoginPageViewNew";
import swal from 'sweetalert';

//"controllers/signup/SignUpController",
//"controllers/signup/FullSignUpController",
import LocalStorageHelper from "models/helper/LocalStorageHelper";

import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import SignUpController from 'controllers/signup/SignUpController';
import FullSignUpController from 'controllers/signup/FullSignUpController';

//"controllers/featurePage/FeaturePageController",
import FacebookHelper from "models/helper/FacebookHelper";
import PersistentModels from "services/PersistentModels";
import FacebookEventTrackingHelper from "models/helper/FacebookEventTrackingHelper";
import ti18n from "i18n!nls/loginPagei18n";
import GooglePlusHelper from "models/helper/GooglePlusHelper";
import AppleIDHelper from "models/helper/AppleIDHelper";
import RSVP from "rsvp";
const DISABLE_FEATURE_PAGE = !window.enableFeatureDisplayBeforeLogin;
let BUILT_IN_ACCESS_CODE_FOR_LISTENERS = "7B2-DXWC-EMP1";
//const BUILT_IN_ACCESS_CODE_FOR_LISTENERS = "SAMS-UNG-KKGE";
if(!window.waitForCordovaToLoad){
    BUILT_IN_ACCESS_CODE_FOR_LISTENERS = "WEBB-58D2-V8V"
}
const LoginController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.onLoginSuccessfullyCompleted =  options? options.onLoginSuccessfullyCompleted:null;
        this.loginApi =  options? options.loginApi:null;
        this.loginWithFacebookApi =  options? options.loginWithFacebookApi:null;
        this.loginWithGoogleApi =  options? options.loginWithGoogleApi:null;
        this.loginWithAppleApi =  options? options.loginWithAppleApi:null;
        this.showAsPopup = options? options.showAsPopup:null;
        this.loginAsGuestApi=  options? options.loginAsGuestApi:null;
        this.signupApi =  options? options.signupApi:null;
        this.signupWithFacebookApi =  options? options.signupWithFacebookApi:null;
        this.signupWithGoogleApi =  options? options.signupWithGoogleApi:null;
        this.signupWithAppleApi =  options? options.signupWithAppleApi:null;
        this.doNotAllowGuestLogin = options? options.doNotAllowGuestLogin : false;
        this.forceSignUpAsCreator =  options? options.forceSignUpAsCreator: false;
        this._successSignupData =  options? options.successSignupData : null;
        this.isUserpreviouslyLoggedInAsGuest =  false;//THIS IS FOR TEMPORARY USAGE AND SHOULD BE REMOVED LATER//options? options.isUserpreviouslyLoggedInAsGuest : false;
        this._signupPageOpened = false;
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    doLoginViaFacebookPopupWindow : function(){

        return FacebookHelper.getInstance().login();
    },

    doLoginViaApplePopupWindow : function(){				
        return AppleIDHelper.getInstance().login();
    },


    doLoginWithFacebook : function(facebookAccessToken){
        $.mobile.loading("show");
        if(this.view){
            this.view.setRemoveOnHide(true);
        }
        let facebookAuthRetrivedInfo = null;
        let loginErrorMessage =  null;
        const getAccessTokenPromise = facebookAccessToken? RSVP.Promise.resolve({"accessToken":facebookAccessToken}):this.doLoginViaFacebookPopupWindow();
        return getAccessTokenPromise.then((function(facebookAuthInfo){
            if(facebookAuthInfo){
                facebookAuthRetrivedInfo = facebookAuthInfo;
                return this.loginWithFacebookApi(facebookAuthInfo.accessToken).catch((function(error){
                    loginErrorMessage =  error;
                    return false;
                }).bind(this));
            }else{
                throw window.getI18n(ti18n, "PLEASE_LOGIN_WITH_FACEBOOK_ACCOUNT");
            }
        }).bind(this)).then((function(loginSuccessfull){
            const p = loginSuccessfull? RSVP.Promise.resolve(): this.tryToSilentlySignupUserWithFacebookInfoThenLoginAgain(facebookAuthRetrivedInfo, loginErrorMessage);
            return p.then((function(){
                LocalStorageHelper.getInstance().setUsingNewLoginModule();
                if(this.onLoginSuccessfullyCompleted){
                    this.onLoginSuccessfullyCompleted(true);
                }
            }).bind(this));
        }).bind(this)).then((function(){
            $.mobile.loading("hide");
            if(this.showAsPopup){
                this.view.close();
            }else{
                if($.mobile.navigate.history.activeIndex > 0){
                    $.mobile.loading("show");
                    $.mobile.back();
                }
            }
        }).bind(this)).catch((function(error){
            $.mobile.loading("hide");
            const onPopupClose = (function(buttonIndex){
                if(buttonIndex == 2){
                    this.showSignup();
                }
            }).bind(this);
            navigator.trebbleNotification.confirm(error, onPopupClose, "Login Failure", [ window.getI18n(ti18n, "OK"), window.getI18n(ti18n, "CREATE_NEW_ACCOUNT_BUTTON_LABEL")]);
        }).bind(this));
    },

    doLoginWithApple : function(appleAccessToken){
        //$.mobile.loading("show");
        if(this.view){
            this.view.setRemoveOnHide(true);
        }
        let appleAuthRetrivedInfo = null;
        let loginErrorMessage =  null;
        const getAccessTokenPromise = appleAccessToken?RSVP.Promise.resolve({"authorization":{"id_token":appleAccessToken}}): this.doLoginViaApplePopupWindow();
        return getAccessTokenPromise.then((function(appleAuthInfo){
            if(appleAuthInfo && appleAuthInfo.authorization){
                appleAuthRetrivedInfo = appleAuthInfo;
                return this.loginWithAppleApi(appleAuthInfo.authorization.id_token).catch((function(error){
                    loginErrorMessage =  error;
                    return false;
                }).bind(this));
            }else{
                throw window.getI18n(ti18n, "PLEASE_LOGIN_WITH_APPLE_ACCOUNT");
            }
        }).bind(this)).then((function(loginSuccessfull){
            const p = loginSuccessfull? RSVP.Promise.resolve(): this.tryToSilentlySignupUserWithAppleInfoThenLoginAgain(appleAuthRetrivedInfo, loginErrorMessage);
            return p.then((function(){
                LocalStorageHelper.getInstance().setUsingNewLoginModule();
                if(this.onLoginSuccessfullyCompleted){
                    this.onLoginSuccessfullyCompleted(true);
                }
            }).bind(this));
        }).bind(this)).then((function(){
            //$.mobile.loading("hide");
            if(this.showAsPopup){
                this.view.close();
            }else{
                if($.mobile.navigate.history.activeIndex > 0){
                    //$.mobile.loading("show");
                    $.mobile.back();
                }
            }
        }).bind(this)).catch((function(error){
            //$.mobile.loading("hide");
            const onPopupClose = (function(buttonIndex){
                if(buttonIndex == 2){
                    this.showSignup();
                }
            }).bind(this);
            console.error(error);
            let shouldSuggestAccountCreation = true;
            if(typeof(error) != "string"){
                error = window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_CREATING_APPLE_ACCOUNT");
                shouldSuggestAccountCreation = false;
            }

            if(error ===  window.getI18n(ti18n, "USER_CANCELLED_LOGIN_WITH_APPLE") || error ===  window.getI18n(ti18n, "USER_CLOSE_APPLE_LOGIN_POPUP")){
                shouldSuggestAccountCreation = false;
            }
            if(shouldSuggestAccountCreation){
                navigator.trebbleNotification.confirm(error, onPopupClose, "Login Failure", [ window.getI18n(ti18n, "OK"), window.getI18n(ti18n, "CREATE_NEW_ACCOUNT_BUTTON_LABEL")]);
            }else{
                window.alertErrorMessage(error);
            }
        }).bind(this));
    },


    createUserAccountOnLoginIfUserDoesntExist : function(){
        return this.forceSignUpAsCreator || window.trebble.allowEasySignupForListenersAtLogin || window.waitForCordovaToLoad || (window.trebble.presetAccessCode && window.trebble.presetAccessCode != BUILT_IN_ACCESS_CODE_FOR_LISTENERS) || $("body").hasClass("embedded") || window.trebble.entryPointUrlHashLoadedPlayerQueue || window.trebble.isEmbededLoginInPage;
    },


    isUserNotAllowedToSignupAsCreator : function(){
        return window.waitForCordovaToLoad || $("body").hasClass("embedded") || window.trebble.entryPointUrlHashLoadedPlayerQueue || window.trebble.isEmbededLoginInPage;
    },

    onGoogleLoginFailed : function(error){
        //window.alertErrorMessage("Please login with your Google account.");

        if(window.waitForCordovaToLoad){
            if(GooglePlusHelper.getInstance().SIGN_IN_CANCELLED == parseInt(error)){
                //do not show error message
                return
            }else{
                if(GooglePlusHelper.getInstance().getErrorCodes().SIGN_IN_FAILED == parseInt(error)){
                    return window.alertErrorMessage(window.getI18n(ti18n, "LOGIN_FAILED"));
                }
                if(GooglePlusHelper.getInstance().getErrorCodes().SIGN_IN_REQUIRED == parseInt(error)){
                    return window.alertErrorMessage(window.getI18n(ti18n, "YOU_NEED_TO_BE_SIGN_IN_FIRST"));
                }
                if(GooglePlusHelper.getInstance().getErrorCodes().INTERNAL_ERROR == parseInt(error)){
                    return window.alertErrorMessage(window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_CREATING_GOOGLE_ACCOUNT"));
                }
                if(GooglePlusHelper.getInstance().getErrorCodes().NETWORK_ERROR == parseInt(error)){
                    return window.alertErrorMessage(window.getI18n(ti18n, "WE_COULDNT_COMPLETE_THE_LOGIN_PLEASE_CHECK_YOUR_INTERNET_CONNECTION"));
                }
                return window.alertErrorMessage(window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_CREATING_GOOGLE_ACCOUNT"));
            }
        }else{
            const onPopupClose = (function(buttonIndex){
                if(buttonIndex == 2){
                    this.showSignup();
                }
            }).bind(this);
            navigator.trebbleNotification.confirm(window.getI18n(ti18n, "PLEASE_LOGIN_WITH_GOOGLE_ACCOUNT"), onPopupClose, "Login Failure", [ window.getI18n(ti18n, "OK"), window.getI18n(ti18n, "CREATE_NEW_ACCOUNT_BUTTON_LABEL")]);
        }
    },

    doLoginWithGoogle : function(googleUser){
        $.mobile.loading("show");
        if(this.view){
            this.view.setRemoveOnHide(true);
        }
        let loginErrorMessage = null;
        const getAccessTokenPromise =  RSVP.Promise.resolve(googleUser.getAuthResponse())
        return getAccessTokenPromise.then((function(googleAuthInfo){
            return this.loginWithGoogleApi(googleAuthInfo.id_token).catch((function(error){
                loginErrorMessage = error;
                return false;
            }).bind(this));
        }).bind(this)).then((function(loginSuccessfull){
            const p = loginSuccessfull? RSVP.Promise.resolve(): this.tryToSilentlySignupUserWithGoogleInfoThenLoginAgain(googleUser, loginErrorMessage);
            return p.then((function(){
                LocalStorageHelper.getInstance().setUsingNewLoginModule();
                if(this.onLoginSuccessfullyCompleted){
                    this.onLoginSuccessfullyCompleted(true);
                }
            }).bind(this));
        }).bind(this)).then((function(){
            $.mobile.loading("hide");
            if(this.showAsPopup){
                this.view.close();
            }else{
                if($.mobile.navigate.history.activeIndex > 0){
                    $.mobile.loading("show");
                    $.mobile.back();
                }
            }
        }).bind(this)).catch((function(error){
            $.mobile.loading("hide");
            const onPopupClose = (function(buttonIndex){
                if(buttonIndex == 2){
                    this.showSignup();
                }
            }).bind(this);
            navigator.trebbleNotification.confirm(error, onPopupClose, "Login Failure", [window.getI18n(ti18n, "OK"), window.getI18n(ti18n, "CREATE_NEW_ACCOUNT_BUTTON_LABEL")]);
        }).bind(this));
    },


    tryToSilentlySignupUserWithFacebookInfoThenLoginAgain : function(facebookAuthInfo, loginErrorMessage){
        if(facebookAuthInfo && this.createUserAccountOnLoginIfUserDoesntExist()){
            //Try to sign up the user automatically assuming since there is no extra field to fill
            return this.signupWithFacebookApi(facebookAuthInfo.accessToken, BUILT_IN_ACCESS_CODE_FOR_LISTENERS, null, null, null, null,null, null, null, null, null, this.forceSignUpAsCreator?"shortcast_creator":null).then((function(operationResult){
                if(operationResult && operationResult.userCreated){
                    if(window.trebbleAnalyticsHelper){
                        window.trebbleAnalyticsHelper.trackEvent("Onboarding", "SignupCompleted", "Sign-up Completed");
                    } 
                    FacebookEventTrackingHelper.getInstance().trackRegistration(false, false, BUILT_IN_ACCESS_CODE_FOR_LISTENERS);
                }else{
                    if(window.trebbleAnalyticsHelper){
                        window.trebbleAnalyticsHelper.trackEvent("Onboarding", "SignupFailed", "Sign-up Failed", null, {"loginError": loginErrorMessage});
                    } 
                    if(operationResult.userAlreadyExist){
                        //User with this Facebook account already exist so try to login again
                    }else{
                        if(operationResult.emailAlreadyUsed){
                            throw (window.getI18n(ti18n, "FACEBOOK_ACCOUNT_IS_CURRENTLY_USED"))
                        }
                    }
                    throw window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_CREATING_FACEBOOK_ACCOUNT");
                }
            }).bind(this)).then((function(){
                window.trebble.accountSignupHasOccuredInSession = true;
                return this.loginWithFacebookApi(facebookAuthInfo.accessToken);
            }).bind(this));
        }else{
            //throw an error
            throw loginErrorMessage? loginErrorMessage: window.getI18n(ti18n, "LOGIN_FAILED");
        }

    },

    tryToSilentlySignupUserWithGoogleInfoThenLoginAgain : function(googleUser, loginErrorMessage){
        if(googleUser && this.createUserAccountOnLoginIfUserDoesntExist()){
            //Try to sign up the user automatically assuming since there is no extra field to fill

            return this.signupWithGoogleApi(googleUser.getAuthResponse().id_token, BUILT_IN_ACCESS_CODE_FOR_LISTENERS, null, null, null, null,null, null, null, null, null, this.forceSignUpAsCreator?"shortcast_creator":null).then((function(operationResult){
                if(operationResult && operationResult.userCreated){
                    if(window.trebbleAnalyticsHelper){
                        window.trebbleAnalyticsHelper.trackEvent("Onboarding", "SignupCompleted", "Sign-up Completed");
                    } 
                    FacebookEventTrackingHelper.getInstance().trackRegistration(false, false, BUILT_IN_ACCESS_CODE_FOR_LISTENERS);
                }else{
                    if(window.trebbleAnalyticsHelper){
                        window.trebbleAnalyticsHelper.trackEvent("Onboarding", "SignupFailed", "Sign-up Failed", null, {"signupError": loginErrorMessage});
                    } 
                    if(operationResult.userAlreadyExist){
                        //User with this Google account already exist so try to login again
                    }else{
                        if(operationResult.emailAlreadyUsed){
                            throw (window.getI18n(ti18n, "GOOGLE_ACCOUNT_IS_CURRENTLY_USED"))
                        }
                    }
                    throw window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_CREATING_GOOGLE_ACCOUNT");
                }
            }).bind(this)).then((function(){
                window.trebble.accountSignupHasOccuredInSession = true;
                return this.loginWithGoogleApi(googleUser.getAuthResponse().id_token);
            }).bind(this));

        }else{
            //throw an error
            throw loginErrorMessage? loginErrorMessage: window.getI18n(ti18n, "LOGIN_FAILED");
        }

    },

    tryToSilentlySignupUserWithAppleInfoThenLoginAgain : function(appleAuthInfo, loginErrorMessage){
        if(appleAuthInfo && appleAuthInfo.authorization && this.createUserAccountOnLoginIfUserDoesntExist()){
            //Try to sign up the user automatically assuming since there is no extra field to fill
            return this.signupWithAppleApi(appleAuthInfo.authorization.id_token, BUILT_IN_ACCESS_CODE_FOR_LISTENERS, null, null, null, null,null, null, null, null, null, this.forceSignUpAsCreator?"shortcast_creator":null).then((function(operationResult){
                if(operationResult && operationResult.userCreated){
                    if(window.trebbleAnalyticsHelper){
                        window.trebbleAnalyticsHelper.trackEvent("Onboarding", "SignupCompleted", "Sign-up Completed");
                    } 
                    FacebookEventTrackingHelper.getInstance().trackRegistration(false, false);
                }else{
                    if(window.trebbleAnalyticsHelper){
                        window.trebbleAnalyticsHelper.trackEvent("Onboarding", "SignupFailed", "Sign-up Failed", null, {"loginError": loginErrorMessage});
                    } 
                    if(operationResult.userAlreadyExist){
                        //User with this Facebook account already exist so try to login again
                    }else{
                        if(operationResult.emailAlreadyUsed){
                            throw (window.getI18n(ti18n, "APPLE_ACCOUNT_IS_CURRENTLY_USED"))
                        }
                    }
                    throw window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_CREATING_APPLE_ACCOUNT");
                }
            }).bind(this)).then((function(){
                window.trebble.accountSignupHasOccuredInSession = true;
                return this.loginWithAppleApi(appleAuthInfo.authorization.id_token);
            }).bind(this));
        }else{
            //throw an error
            throw loginErrorMessage? loginErrorMessage: window.getI18n(ti18n, "LOGIN_FAILED");
        }

    },

    doLogin : function(data){
        $.mobile.loading("show");
        if(this.view){
            this.view.setRemoveOnHide(true);
        }
        return this.loginApi(data.username, data.password).then((function(loginSuccessfull){
            if(loginSuccessfull){
                LocalStorageHelper.getInstance().setUsingNewLoginModule();
                if(this.onLoginSuccessfullyCompleted){
                    this.onLoginSuccessfullyCompleted(true);
                }
            }else{
                throw window.getI18n(ti18n, "LOGIN_FAILED");
            }
        }).bind(this)).then((function(){
            $.mobile.loading("hide");
            if(this.showAsPopup){
                this.view.close();
            }else{
                if($.mobile.navigate.history.activeIndex > 0){
                    $.mobile.loading("show");
                    $.mobile.back();
                }
            }
        }).bind(this)).catch((function(error){
            $.mobile.loading("hide");
            window.alertErrorMessage(error);
        }).bind(this));
    },




    showSignupWithoutUsingRouter : function(){

        if(this.view){
            this.view.setRemoveOnHide(false);
        }

        this._signupPageOpened = true;
        const signupAsShortcastCreator = window.trebble.launchFromEditorLandingPage || this.forceSignUpAsCreator;
        const controllerParams = {};
        controllerParams.isUserpreviouslyLoggedInAsGuest = this.isUserpreviouslyLoggedInAsGuest;
        controllerParams.signupApi = this.signupApi;
        controllerParams.signupWithFacebookApi = this.signupWithFacebookApi;
        controllerParams.signupWithGoogleApi = this.signupWithGoogleApi;
        controllerParams.signupWithAppleApi = this.signupWithAppleApi;
        controllerParams.signupAsShortcastCreator = signupAsShortcastCreator;
        controllerParams.onSignupSuccessfullyCompleted = this._onSignupPageClosed.bind(this);

        const userNotAllowedToSignupAsCreator =  this.isUserNotAllowedToSignupAsCreator()
        let signUpController  = null;
        const SignupControlledLoaded  = userNotAllowedToSignupAsCreator || signupAsShortcastCreator? SignUpController:FullSignUpController;
        const pageId = userNotAllowedToSignupAsCreator || signupAsShortcastCreator? "newSignupPage":"onboardingSignupPage";
        $.mobile.loading("show");
         //window.trebble.helper.requirePromise(signUpControllerToLoad).then((function(SignupControlledLoaded){
            signUpController = new SignupControlledLoaded(controllerParams);
            return signUpController.buildModelAndRenderView().then((function(){
            $.mobile.loading("hide");
            return window.trebble.pageContainerReadyPromise;
        }).bind(this)).then((function(){
            $('body').append(signUpController.getView().$el);
            signUpController.getView().$el.trigger("pageAppendedToBody")
            signUpController.getView().$el.attr("appendedToBody", true);
            $.mobile.changePage("#"+pageId,{changeHash : true});	
            const signupPageUrl =  (window.location.protocol == "http:"||window.location.protocol == "https:")? location.origin + window.getAppBaseUrl() + "#"+pageId :window.getBaseUrlWithoutEndSlash()+"#"+pageId;
            
            if(history.pushState) {
                history.pushState(null, null, "/"+ pageId);
            }else{
                window.location.assign(signupPageUrl );
            }
            let popped = ('state' in window.history && window.history.state !== null), initialURL = location.href;

            $(document).ready((function(){

                this.self.onBackButtonPressHanlder = (function(){
                    const initialPop = !popped && location.href == initialURL;
                    popped = true;
                    if (initialPop) {
                        //The page has not finish loading and this might be a wrong event sent by Chrome so ignore see https://github.com/vojtech-dobes/nette.ajax.js/issues/73
                        return;
                    }

                    $.mobile.back();
                    window.removeEventListener('popstate', this.onBackButtonPressHanlder);

                }).bind(this.self);
                window.addEventListener('popstate', this.self.onBackButtonPressHanlder);
            }).bind(this));

            $.mobile.resetActivePageHeight();
        }).bind({/*"signUpController": signUpController,*/"self": this})).catch(function(error){
$.mobile.loading("hide");
console.error("An error occured while showing Login Page.Error:"+ error,error);
window.alertErrorMessage(error);
});
    },

    showSignup : function(){
        if(this.view){
            this.view.setRemoveOnHide(false);
        }
        this._signupPageOpened = true;
       // return window.trebble.helper.requirePromise(["services/PersistentModels"]).then((function(PersistentModels){
            if(PersistentModels.getInstance().getRouter()){
                const userNotAllowedToSignupAsCreator =  this.isUserNotAllowedToSignupAsCreator();//window.trebble.presetAccessCode || $("body").hasClass("embedded") || window.trebble.entryPointUrlHashLoadedPlayerQueue || window.trebble.isEmbededLoginInPage;
                if(userNotAllowedToSignupAsCreator ){
                    return PersistentModels.getInstance().getRouter().showNewSignupPage(null, null, true , this.signupApi, this.signupWithFacebookApi,this.signupWithGoogleApi,this.signupWithAppleApi, this._onSignupPageClosed.bind(this), this.isUserpreviouslyLoggedInAsGuest);
                }else{
                    if(window.trebble.launchFromEditorLandingPage || this.forceSignUpAsCreator){
                        const signupAsShortcastCreator = true;
                        return PersistentModels.getInstance().getRouter().showNewSignupPage(null, null, true , this.signupApi, this.signupWithFacebookApi,this.signupWithGoogleApi,this.signupWithAppleApi, this._onSignupPageClosed.bind(this), this.isUserpreviouslyLoggedInAsGuest, signupAsShortcastCreator);
                    }else{
                        return PersistentModels.getInstance().getRouter().showFullSignupPage(null, null, true , this.signupApi, this.signupWithFacebookApi,this.signupWithGoogleApi,this.signupWithAppleApi, this._onSignupPageClosed.bind(this), this.isUserpreviouslyLoggedInAsGuest);	
                    }
                }
            }else{
                return this.showSignupWithoutUsingRouter();
            }
       /* }).bind(this)).catch(function(error){
            console.error("An error occured while showing Login Page.Error:"+ error,error);
            window.alertErrorMessage(error);
});*/

    },

    _onSignupPageClosed  : function(data){
        this._signupPageOpened = false;
        if(data){
            let loginPromise =  null;
            if("Facebook" === data.externalProfileSignup){
                loginPromise = this.doLoginWithFacebook(data.facebookAccessToken);
            }else{
                if("Google" === data.externalProfileSignup){
                    loginPromise = this.doLoginWithGoogle(data.googleUser);
                }else{
                    if("Apple" === data.externalProfileSignup){
                        loginPromise = this.doLoginWithApple(data.appleAccessToken);
                    }else{

                        loginPromise = this.doLogin(data);
                    }
                }
            }
            if(loginPromise && loginPromise.then){
                loginPromise.then((function(){
                    if(window.trebbleAnalyticsHelper){
                        window.trebbleAnalyticsHelper.trackEvent("Onboarding", "SignupCompletedSentAfterLogin", "Sign-up Completed Sent After Login");
                    }
                    FacebookEventTrackingHelper.getInstance().trackRegistration(false, false); 
                }).bind(this))
            }
        }else{
            this._showLoginPageView();
        }

    },




    onViewRemovedFromDocument : function(){
        if(!this._signupPageOpened && this.onLoginSuccessfullyCompleted){
            this.onLoginSuccessfullyCompleted(false);
        }
    },

    onContinueAsGuessBtnTapped : function(){
        if(this.view){
            this.view.setRemoveOnHide(true);
        }
        $.mobile.loading("show");
        this.loginAsGuestApi().then((function(loginSuccessfull){
            if(loginSuccessfull){
                if(this.onLoginSuccessfullyCompleted){
                    this.onLoginSuccessfullyCompleted(true);
                }
            }else{
                throw window.getI18n(ti18n, "LOGIN_FAILED");
            }
        }).bind(this)).then((function(){
            $.mobile.loading("hide");
            if(this.showAsPopup){
                this.view.close();
            }else{
                if($.mobile.navigate.history.activeIndex > 0){
                    $.mobile.back();
                }
            }
        }).bind(this)).catch((function(error){
            $.mobile.loading("hide");
            window.alertErrorMessage(error);
        }).bind(this));
    },

    showFeaturesPage : function(){
        return new RSVP.Promise((function(resolve, reject) {
            try{
                const options = {};
                options.onFeaturePageClosedHandler = function(){ resolve()};
                let featurePageController = null;
                return window.trebble.helper.requirePromise(["controllers/featurePage/FeaturePageController"]).then((function(FeaturePageController){
                    featurePageController = new FeaturePageController(options);
                    return featurePageController.buildModelAndRenderView();
                }).bind(this)).then((function(){
                    return window.trebble.pageContainerReadyPromise;
                }).bind(this)).then((function(){
                    $('body').append(featurePageController.getView().$el);
                    $.mobile.changePage("#featurePage",{changeHash:true});
                    $.mobile.resetActivePageHeight();
                    document.body.style["background-color"]="rgba(17,17,17,1)";
                    document.body.style["border-color"]="rgba(17,17,17,1)";
                    $("#loadingBoxWidget").css("display","none");
                    if(navigator.splashscreen){
                        navigator.splashscreen.hide();
                    }
                }).bind({/*"featurePageController": featurePageController,*/ "router": this})).catch(function(error){
                    document.body.style["background-color"]="rgba(17,17,17,1)";
                    document.body.style["border-color"]="rgba(17,17,17,1)";
                    $("#loadingBoxWidget").css("display","none");
                    if(navigator.splashscreen){
                        navigator.splashscreen.hide();
                    }
                    console.error("An error occured while displaying feature Page.Error:"+ error);
                    reject(error);
                });
            }catch(error){
                reject(error);
            }
        }).bind(this));

},

isPlayerGettingOpened : function(){
return location.hash && (location.hash.indexOf("#player")  == 0 ||  location.hash.indexOf("#p/")  == 0 ) ||  window.getPathNameWithoutHtmlPage() && (window.getPathNameWithoutHtmlPage().indexOf("/player")  == 0 ||  window.getPathNameWithoutHtmlPage().indexOf("/p/")  == 0 );
},

openRequesInvitePage : function(){
const openedWindow = window.open("https://trebble.typeform.com/to/JXo8tX", "_blank");
if((!openedWindow || openedWindow.closed || typeof openedWindow.closed=='undefined') && !window.waitForCordovaToLoad){
window.alertErrorMessage(window.getI18n(ti18n, "PLEASE_ALLOW_POPUPS_FOR_TREBBLE"));
}else{
if(openedWindow.focus){
    openedWindow.focus();
}
}

},

onForgotPasswordLinkClicked : function(){
swal({
title: window.getI18n(ti18n, "RESET_PASSWORD"),
text: window.getI18n(ti18n, "RESET_PASSWORD_INSTRUCTIONS"),
type: "input",
showCancelButton: true,
closeOnConfirm: false,
animation: "slide-from-top",
inputPlaceholder: window.getI18n(ti18n, "EMAIL_FIELD_PLACEHOLDER")
},
(function(inputValue){
if (inputValue === false) return false;

if (!this.validateEmail(inputValue)) {
    swal.showInputError(window.getI18n(ti18n, "EMAIL_MUST_CONTAIN") +"\"@\"");
    return false
}

// swal("Nice!", "You wrote: " + inputValue, "success");
this.sendRecoveryPasswordEmail(inputValue);
}).bind(this));
},

validateEmail : function(email) {
const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
return re.test(email? email.trim(): email);
},

sendRecoveryPasswordEmail : function(email){

$.mobile.loading("show");
return TrebbleClientAPIHelper.getInstance().sendRecoveryPasswordEmail(email).then((function(){
//window.alertSuccessMessage("A link to reset your password has been sent to "+ email);
window.alertSuccessMessage(window.getI18n(ti18n, "A_LINK_TO_RESET_PASSWORD_SENT"));
$.mobile.loading("hide");
}).bind(this)).catch((function(error){
$.mobile.loading("hide");
const errorMessage = error? error: window.getI18n(ti18n, "SOMETHING_WENT_WRONG_SENDING_PASSWORD_RESET_LINK");
swal.showInputError(errorMessage);
}).bind(this));


},

onViewRemoved:function(){
this.stopListening();
},

shouldJumpToSignupPage : function(){
try{
const searchparams = new URLSearchParams(location.search);
if(searchparams && searchparams.get("jumpToSignup") === "true"){
    searchparams.set("jumpToSignup", "false");
    const newRelativePathQuery = window.location.pathname + '?' + searchparams.toString();
    history.pushState(null, '', newRelativePathQuery);
    return true;
}
}catch(error){
console.error(error);
}
return false;
},

_showLoginPageView : function(){
return new RSVP.Promise((function(resolve, reject) {
try {
    if(DISABLE_FEATURE_PAGE || LocalStorageHelper.getInstance().isFirstApplicationLaunchDone() || (window.trebble.presetAccessCode != BUILT_IN_ACCESS_CODE_FOR_LISTENERS)|| $("body").hasClass("embedded") || window.trebble.entryPointUrlHashLoadedPlayerQueue || window.trebble.isEmbededLoginInPage){
        const options = {};
        options.isUserpreviouslyLoggedInAsGuest = this.isUserpreviouslyLoggedInAsGuest;
        options.showAsPopup = this.showAsPopup;
        options.doNotAllowGuestLogin = this.doNotAllowGuestLogin;
        options.clickingOnSocialLoginWillSignupAutomatically =  this.createUserAccountOnLoginIfUserDoesntExist();
        this.view = new LoginPageViewNew(options);
        /*if(this.isPlayerGettingOpened()){
            resolve();
        }else{*/
            this.view.render().then((function(){
                this.listenTo(this.view, "loginBtnTapped", this.doLogin.bind(this));
                this.listenTo(this.view, "signupButtonTapped", this.showSignup.bind(this));
                this.listenTo(this.view, "requestInviteBtnTapped", this.openRequesInvitePage);
                this.listenTo(this.view, "loginWithFacebookBtnTapped", this.doLoginWithFacebook);
                this.listenTo(this.view, "loginWithAppleBtnTapped", this.doLoginWithApple);
                this.listenTo(this.view, "loginWithGoogleBtnSuccess", this.doLoginWithGoogle);
                this.listenTo(this.view, "loginWithGoogleBtnFailed",this.onGoogleLoginFailed);
                this.listenTo(this.view, "removedFromPage", this.onViewRemovedFromDocument);
                this.listenTo(this.view, "continueAsGuessBtnTapped", this.onContinueAsGuessBtnTapped);
                this.listenTo(this.view, "forgotPasswordLinkClicked", this.onForgotPasswordLinkClicked);
                this.listenTo(this.view, "remove", this.onViewRemoved.bind(this));
                resolve();
                if(window.trebbleAnalyticsHelper){
                    window.trebbleAnalyticsHelper.trackEvent("Onboarding", "LoginPageOpened", "Login Page Opened");
                } 
                if(this._successSignupData){
                    this._onSignupPageClosed(this._successSignupData);
                }
                if(this.shouldJumpToSignupPage()){
                    this.showSignup();
                }
            }).bind(this)).catch(reject);
//}

}else{
this.showFeaturesPage().then((function(){
const options = {};
options.isUserpreviouslyLoggedInAsGuest = this.isUserpreviouslyLoggedInAsGuest;
options.showAsPopup = this.showAsPopup;
options.doNotAllowGuestLogin = this.doNotAllowGuestLogin;
options.clickingOnSocialLoginWillSignupAutomatically =  this.createUserAccountOnLoginIfUserDoesntExist();
this.view = new LoginPageViewNew(options);
this.view.render();
this.listenTo(this.view, "loginBtnTapped", this.doLogin.bind(this));
this.listenTo(this.view, "signupButtonTapped", this.showSignup.bind(this));
this.listenTo(this.view, "requestInviteBtnTapped", this.openRequesInvitePage);
this.listenTo(this.view, "loginWithFacebookBtnTapped", this.doLoginWithFacebook);
this.listenTo(this.view, "loginWithAppleBtnTapped", this.doLoginWithApple);
this.listenTo(this.view, "loginWithGoogleBtnSuccess", this.doLoginWithGoogle);
this.listenTo(this.view, "loginWithGoogleBtnFailed",this.onGoogleLoginFailed);
this.listenTo(this.view, "removedFromPage", this.onViewRemovedFromDocument);
this.listenTo(this.view, "continueAsGuessBtnTapped", this.onContinueAsGuessBtnTapped);
this.listenTo(this.view, "forgotPasswordLinkClicked", this.onForgotPasswordLinkClicked);
this.listenTo(this.view, "remove", this.onViewRemoved.bind(this));

resolve();
if(window.trebbleAnalyticsHelper){
    window.trebbleAnalyticsHelper.trackEvent("Onboarding", "LoginPageOpened", "Login Page Opened");
} 
if(this.shouldJumpToSignupPage()){
    this.showSignup();
}
}).bind(this)).catch(reject);
}

return;
} catch (error) {
reject(error);
}

}).bind(this));
},


buildModelAndRenderView : function() {
return this._showLoginPageView();

}

});

export default LoginController;