import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import Utils from "models/helper/Utils";
import AddOrScheduleCapsuleToTrebblePopupView from "views/capsule/AddOrScheduleCapsuleToTrebblePopupView";
import PersistentModels from "services/PersistentModels";
import ListItemCollection from "collections/ListItemCollection";
import RSVP from "rsvp";

const AddOrScheduleCapsuleToTrebblePopupController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.model = options? options.capsuleModel : null;
        this.radioIdsToAddCapsuleTo = options? options.radioIdsToAddCapsuleTo : null;
        this.context = options? options.context : null;
        this._changeScheduleTime = options  && options.changeScheduleTime? options.changeScheduleTime : null;
        this._presetOnAirDurationInDays = options && options.presetOnAirDurationInDays? options.presetOnAirDurationInDays :null;
        this._presetScheduledTime = options  && options.presetScheduledTime? options.presetScheduledTime : null;
        this._presetTimezone = options  && options.presetTimezone? options.presetTimezone : null;
        this._onCapsuleAddedHandler = options? options.onCapsuleAddedHandler: null;
        this._onCapsuleAddedErrorHandler = options? options.onCapsuleAddedErrorHandler : null;
        this._onPopupCloseHanlder = options? options.onPopupClose : null;
        this._capsuleWasAdded = false;
        this._errorAddingCapsule = null;
        _.extend(this, Backbone.Events);
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    changeCapsuleScheduledTime :function(){
        $.mobile.loading("show");
        return PersistentModels.getInstance().removeCapsuleFromRadio(this.model, PersistentModels.getInstance().getAllMyPersonalRadioIds(),  this.context).then((function(){
            $.mobile.loading("hide");
            return this.addCapsuleToTrebble();
        }).bind(this))
    },
    

    addCapsuleToTrebble : function(){
        $.mobile.loading("show");
        let lifeSpan = this.view.getLifeSpan();
        if(!lifeSpan || lifeSpan == ""){
            lifeSpan = null
        }
        const isCapsuleScheduled = this.view.isScheduled();
        const scheduleStartDate =  isCapsuleScheduled ? this.view.getScheduleStartDate(): null;
        const timezone = this.view.getTimezone();
        PersistentModels.getInstance().addCapsuleToRadio(this.model, this.radioIdsToAddCapsuleTo, lifeSpan,   isCapsuleScheduled, scheduleStartDate,timezone, this.context).then((function(){
            this._capsuleWasAdded = true;
            $.mobile.loading("hide");
            this.view._close();	
        }).bind(this)).catch((function(error){
            $.mobile.loading("hide");
            this._errorAddingCapsule = error;
            if(!this._errorAddingCapsule){
                window.alertErrorMessage(error);
            }
            this.view._close();
        }).bind(this));

    },

    _onPopupClosed : function(){
        if(this._onPopupCloseHanlder){
            this._onPopupCloseHanlder();
        }
        if(this._onCapsuleAddedHandler && !this._errorAddingCapsule){
            this._onCapsuleAddedHandler(this._capsuleWasAdded, this.model);
        }
        if(this._onCapsuleAddedErrorHandler && this._errorAddingCapsule){
            this._onCapsuleAddedHandler(this._errorAddingCapsule);
        }
    },



    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                const params = {};
                params.timezoneCollection = new ListItemCollection(Utils.getInstance().getTimezoneListItemArray());
                const trebbleDefauftTimezones = PersistentModels.getInstance().getAllMyPersonalRadioTimezones();
                const trebbleDefaultTimezone  = trebbleDefauftTimezones && trebbleDefauftTimezones.length> 0? trebbleDefauftTimezones[0]: Intl.DateTimeFormat().resolvedOptions().timeZone;
                params.selectedDefaultTimezone = trebbleDefaultTimezone;
                params.changeScheduleTime =  this._changeScheduleTime;
                params.presetOnAirDurationInDays =  this._presetOnAirDurationInDays;
                params.presetScheduledTime =  this._presetScheduledTime;
                params.presetTimezone =  this._presetTimezone;
                const addOrScheduleCapsuleToTrebblePopupView = new AddOrScheduleCapsuleToTrebblePopupView(params);
                addOrScheduleCapsuleToTrebblePopupView.render();
                this.view = addOrScheduleCapsuleToTrebblePopupView;
                this.listenTo(this.view, "onPopupClosed", this._onPopupClosed.bind(this));
                this.listenTo(this.view, "addToTrebble", this._changeScheduleTime? this.changeCapsuleScheduledTime.bind(this): this.addCapsuleToTrebble.bind(this));
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

}

});

export default AddOrScheduleCapsuleToTrebblePopupController;