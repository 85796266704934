import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Song from "models/Song";
import Playlist from "collections/Playlist";
import Utils from "models/helper/Utils";
import Utilsi18n from "i18n!nls/Utilsi18n";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";

var FixedPlaylist =  Playlist.extend({
    model: PersistentModels.getInstance().getSongContructorFunctionForCollection(),

    /*constructor : function ( models, options ) {

        Playlist.apply( this, [models,options] );

    },*/

    initialize: function (models, options){
        this._type = Playlist.types.FIXED;
        this._name = (options && options.name)? options.name :window.getI18n(Utilsi18n, "ALL_TRACKS");
        this._creator =  (options && options.creator)? options.creator :null;
        this._context =  (options && options.context)? options.context :null;
        this._playlistId =  (options && options.id)? options.id :null;
        this._categoryId =  (options && options.categoryId)? options.categoryId :null;
        this._cityId =  (options && options.cityId)? options.cityId :null;
        this._timezone =  (options && options.timezone)? options.timezone :null;
        this._language =  (options && options.language)? options.language :null;
        this._creationDate =  (options && options.creationDate)? options.creationDate : new Date();
        this._lastModificationDate = this._creationDate;
        this._description =  (options && options.description)? options.description :null;
        this._shuffle =  (options && options.shuffle)? options.shuffle :false;
        this._seedData =  (options && options.seedData)? options.seedData :null;
        this._originalCollectionClone = null;
        this._loaderKey =  (options && options.loaderKey)? options.loaderKey :null;
        this._tagGroupsArrayFilter = null;
        this._audioPreviewUrl =  (options && options.audioPreviewUrl)? options.audioPreviewUrl :null;
        this._genreIdToAudioPreviewUrl = ( options && options.genreIdToAudioPreviewUrl)? options.genreIdToAudioPreviewUrl :null;
        this._coverArtUrl = ( options && options.coverArtUrl)? options.coverArtUrl :null;
        this._coverArtUrlBlur = ( options && options.coverArtUrlBlur)? options.coverArtUrlBlur :null;
        this._coverArtUrlInfo = ( options && options.coverArtUrlInfo)? options.coverArtUrlInfo :null;
        this._coverArtUrlBlurInfo = ( options && options.coverArtUrlBlurInfo)? options.coverArtUrlBlurInfo :null;
        this._coverArtBase64 = ( options && options.coverArtBase64)? options.coverArtBase64 :null;
        this._isFollowing = ( options && options.isFollowing)? options.isFollowing :false;
        this._hasPurchased = ( options && options.hasPurchased)? options.hasPurchased :false;
        this._canBeFollowed = ( options && options.canBeFollowed)? options.canBeFollowed :false;
        this._canBeEdited = ( options && options.canBeEdited)? options.canBeEdited :false;
        this._numberOfSongs =  options? options.numberOfSongs :null;
        this._numberOfFollowers =  options? options.numberOfFollowers :null;
        this._numberOfPurchasers =  options? options.numberOfPurchasers :null;
        this._totalNumberOfPlays =  options? options.totalNumberOfPlays :null;
        this._ownerNumberOfPlays =  options? options.ownerNumberOfPlays :null;
        this._playSuccessRate =  options? options.playSuccessRate :null;
        this._numberOfSongsFavorited =  options? options.numberOfSongsFavorited :null;
        this._numberOfCapsules =  options? options.numberOfCapsules :null;
        this._isPaid =  options? options.isPaid :null;
        this._costInUSD =  options? options.costInUSD :null;
        this._customURL = options? options.customURL: null;
    },

    getSongAtPosition : function(position){
        return RSVP.Promise.resolve(this.at(position));
    },

    getSize : function(){
        return RSVP.Promise.resolve(this.length);
    },

    getType : function(){
        return RSVP.Promise.resolve(this._type);
    },

    getName : function(){
        return this._name;
    },
    getLoaderKey : function(){
        return this._loaderKey;
    },


    getSeedData : function(){
        return this._seedData;
    },

    setSeedData : function(seedData){
        this._seedData = seedData;
    },

    setName : function(newPlaylistName){

        return RSVP.Promise.resolve(this._name = newPlaylistName ).then((function(){
            this.trigger("playlistNameChanged",this._name);
        }).bind(this));
    },

    getCreator : function(){
        return RSVP.Promise.resolve(this._creator);
    },

    getCreationDate : function(){
        return RSVP.Promise.resolve(this._creationDate);
    },

    getLastModificationDate : function(){
        return RSVP.Promise.resolve(this._lastModificationDate);
    },

    getDescription : function(){
        return RSVP.Promise.resolve(this._description);
    },

    setDescription : function(newDescription){
        this._description = newDescription;
    },

    addSongs : function(songArray){
        return RSVP.Promise.resolve(this.add(songArray));
    },

    _getSongsWithURI : function(arrayOfURIs){
        return this.filter(function(data) {
            return  (arrayOfURIs.indexOf(data.get("uri")) > -1);

        });
    },

    removeSongsWithUri : function(arrayOfSongUri){
        return RSVP.Promise.resolve(this.remove(this._getSongsWithURI(arrayOfSongUri)));
    },

    setFollowing : function(following){
        this._isFollowing =  following;
    },

    getAllLoadedSongs : function(){
        return RSVP.Promise.resolve(this.toJSON());
    },

    getCategoryId : function(){
        return this._categoryId;
    },

    setCategoryId : function(categoryId){
        return this._categoryId = categoryId;
    },

    getCityId : function(){
        return this._cityId;
    },

    setCityId : function(cityId){
        return this._cityId = cityId;
    },
    
    getTimezone : function(){
        return this._timezone;
    },

    setTimezone : function(timezone){
        return this._timezone = timezone;
    },

    getLanguage : function(){
        return this._language;
    },

    setLanguage : function(language){
        return this._language = language;
    },

    shuffleOn : function(){

        if(!this._originalCollectionClone){
            this._originalCollectionClone = this.clone();
        }
        var originalCollectionClone = this._originalCollectionClone;
        this.reset(this.shuffle()/*,{silent: true}*/);
        this._shuffle = true;

    },

    shuffleOff : function(){
        if(this._originalCollectionClone){
            this.reset(this._originalCollectionClone.models/*,{silent: true}*/);
            this._shuffle = true;
        }

    },

    getAvailableTagGroupFilters : function(){
        var unFilteredSongJsonArray = this._originalCollectionClone? this._originalCollectionClone.toJSON(): this.toJSON(); 
        return Utils.getInstance().buildTagGroupArrayFromSongArray(unFilteredSongJsonArray)
    },


    getSelectedTagFilters : function(){
        return this._tagGroupsArrayFilter;
    },

    setSelectedTagFilter : function(tagGroupsArrayFilter){
        this._tagGroupsArrayFilter = tagGroupsArrayFilter;
        if(!this._originalCollectionClone){
            this._originalCollectionClone = this.clone();
        }
        var originalCollectionClone = this._originalCollectionClone;
        this.reset(Utils.getInstance().applySelectedTags(originalCollectionClone, this._tagGroupsArrayFilter));
    },

    tune : function(tuneOptions){

    },

    getContext : function(){
        return this._context;
    },

    updateContext : function(newContext){
        this._context = newContext
    },

    getPlaylistSummary : function(){
        var playlistSummaryModel = Utils.getInstance().buildPlaylistSummary(this._playlistId, this._name, this._description, 
            this._loaderKey,this._creator,this._categoryId, this._cityId, null,
            this._lastModificationDate, this._audioPreviewUrl, this._coverArtUrl,this._coverArtUrlInfo,this._coverArtUrl,this._coverArtUrlBlurInfo,this._coverArtBase64,this._isFollowing,this._hasPurchased, this._canBeFollowed,this._canBeEdited ,
            null, null,  null, null, null,
            null, null, null, this._genreIdToAudioPreviewUrl ,this._customURL, this._timezone, this._language, this._isPaid, this._costInUSD );
        this._registernToChangeEvents(playlistSummaryModel, "isPlaying");
        return playlistSummaryModel;
    },

    canRetrieveAllAvailableSongsInJson : function(){
        return true;
    },

    isAudioPreviewAvailable: function(){
        return false;
    },

    getAudioPreviewUrl : function(){
        return this._audioPreviewUrl;
    },

    getGenreIdToAudioPreviewUrl : function(){
        return this._genreIdToAudioPreviewUrl;
    },

    getAudioPreviewUrlByGenreId : function(genreId){
        return this._genreIdToAudioPreviewUrl? this._genreIdToAudioPreviewUrl[genreId]:null;
    },

    getCoverArtUrl : function(){
        return this._coverArtUrl;
    },

    setCoverArtUrl : function(newCoverArtUrl){
        this._coverArtUrl = newCoverArtUrl;
    },

    getCoverArtUrlBlur : function(){
        return this._coverArtUrlBlur;
    },


    setCoverArtUrlBlur : function(newCoverArtUrlBlur){
        this._coverArtUrlBlur = newCoverArtUrlBlur;
    },

    getCoverArtUrlInfo : function(){
        return this._coverArtUrlInfo;
    },


    setCoverArtUrlInfo : function(newCoverArtUrlInfo){
        this._coverArtUrlInfo = newCoverArtUrlInfo;
    },

    getCoverArtUrlBlurInfo : function(){
        return this._coverArtUrlBlurInfo;
    },


    setCoverArtUrlBlurInfo : function(newCoverArtUrlBlurInfo){
        this._coverArtUrlBlurInfo = newCoverArtUrlBlurInfo;
    },

    getCoverArtBase64 : function(){
        return this._coverArtBase64;
    },

    setCoverArtBase64 : function(newCoverArtBase64){
        this.this._coverArtBase64 = newCoverArtBase64;
    },

    isFollowing : function(){
        return this._isFollowing;
    },

    canBeFollowed : function(){
        return this._canBeFollowed;
    },


    canBeEdited : function(){
        return this._canBeEdited;
    },

    getNumberOfSongs : function(){
        return this._numberOfSongs;
    },

    getNumberOfFollowers : function(){
        return this._numberOfFollowers;
    },

    getTotalNumberOfPlays : function(){
        return this._totalNumberOfPlays;
    },

    getOwnerNumberOfPlays : function(){
        return this._ownerNumberOfPlays;
    },

    getPlaySuccessRate : function(){
        return this._playSuccessRate;
    },

    getNumberOfSongsFavorited : function(){
        return this._numberOfSongsFavorited;
    },

    getNumberOfCapsules : function(){
        return this._numberOfCapsules;
    },

    addFollower : function(){
        var numberOfFollowers = this._numberOfFollowers;
        numberOfFollowers =  numberOfFollowers? numberOfFollowers: 0;
        numberOfFollowers = numberOfFollowers + 1;
        this._numberOfFollowers = numberOfFollowers;
    },



    setPlaying : function(isPlaying){
        this._isPlaying = isPlaying;
        this.trigger("isPlayingChanged",this._isPlaying );
    },

    isPlaying : function(isPlaying){
        return this._isPlaying;
    },


    removeFollower : function(){
        var numberOfFollowers = this._numberOfFollowers;
        numberOfFollowers =  numberOfFollowers? numberOfFollowers: 0;
        if(numberOfFollowers != 0){
            numberOfFollowers = numberOfFollowers - 1;
        }
        this._numberOfFollowers = numberOfFollowers;
    },

    retrieveAllAvailableSongsInJson : function(){
        var unFilteredSongJsonArray = this._originalCollectionClone? this._originalCollectionClone.toJSON(): this.toJSON(); 
        return unFilteredSongJsonArray;
    },


});
export default FixedPlaylist;