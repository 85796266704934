import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import User from "models/User";
import TrebbleListView from "views/common/TrebbleListView";
import ti18n from "i18n!nls/Commoni18n";
import UserListItemTemplate from "text!../../../templates/user/UserListItemTemplate.html";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";

const TrebbleUserListView = TrebbleListView.extend({

    initialize : function(options) {
        if(!options){
            options= {};
        }
        if(!options.rightSwipeRevealOption){
            options.rightSwipeRevealOption = this._buildItemRightOption()
        }
        if(!options.leftSwipeRevealOption){
            options.leftSwipeRevealOption = this._buildItemLeftOption()
        }

        this.collection = options.collection;
        options.modelIdAttribute  = "username";
        options.listItemTemplate = UserListItemTemplate;
        options.doNotShowAddToRadioButton = true;
        options.doNotShowGradeSongButton = true;
        const self = TrebbleListView.prototype.initialize.call(this, options);
        this.setModelTransformHandlerBeforeTemplatePiping(this._modelTransformHandlerBeforeTemplatePiping);
        this.setItemViewUpdateDuringScrollingHandler(this._getItemViewUpdateDuringScrollingHandler);
        return self;
        
    },
    
    _getDefaultItemEventHandler : function(){
        /*const defaultItemEventHandler =  TrebbleListView.prototype._getDefaultItemEventHandler.call(this);
        defaultItemEventHandler = defaultItemEventHandler? defaultItemEventHandler : [];
        delete defaultItemEventHandler["click div.songOptionBtnWrapper > #songLibraryOptionButtuon"];*/
        //defaultItemEventHandler["click div.capsuleBtnWrapper > #songCapsuleOptionButtuon"] = this._getInternalOnCapsuleIconTapHandler.bind(this);
        return {};
    },
    reload : function(){
        this.setUpdating(true);
        try{
            const displayedUserJSONArray = this.collection? this.collection.toJSON(): [];
            this.updateModels(displayedUserJSONArray);
            this.setUpdating(false);
        }catch(error){
            this.setErrorMessage(error);
        }
    },
    
    _getInternalOnCapsuleIconTapHandler : function(model,evnt, item) {
        const songModel = this._getSongModelFromSongJson(model);
        const context = this.getContext();
        if(songModel){
            return PersistentModels.getInstance().getRouter().showCapsulesForSong(songModel, context);
        }else{
            return RSVP.Promise.reject(window.getI18n(ti18n,"SONG_COULDNT_BE_FOUND"));
        }	
    },

    
_buildItemRightOption : function(){
    const optionConfig = [];
    optionConfig.push( {"optionLabel" : "..."});
    optionConfig.push( {"optionLabel" : window.getI18n(ti18n,"ADD_TO_MY_TREBBLE")+"<i class='ion-ios7-plus-outline'></i>","name": "addToRadio", "validationHandler": this._canBeAddedToRadio.bind(this), "onOptionSelected": this._onAddSongToRadioMenuSelected.bind(this)});//"<i class='fontello-icon-list-add'></i>","name": "addToQueue"};// "Add To Queue"};
    optionConfig.push( {"optionLabel" : window.getI18n(ti18n,"GRADE")+"<i class='ion-ios7-pricetags-outline'></i>","name": "rateOrTag" , "validationHandler": null, "onOptionSelected": this._onTagSongOptionMenuSelected.bind(this)});//"<i class='ion-radio-waves'></i>","name": "playSimilarInLibrary"};//: "Play Similar In Library"};
    return {};
},

_buildItemLeftOption : function(){
    const optionConfig = [];
    optionConfig.push( {"optionLabel" : "..."});
    optionConfig.push( {"optionLabel": "<i class='pe-7s-more'></i>"+window.getI18n(ti18n,"SHOW_OPTIONS"),"name": "showOptions", "validationHandler": null, "onOptionSelected": this._onShowSongOptionMenuSelected.bind(this)});//"<i class='fontello-icon-list-add'></i>"};// "Add To Queue"};
    optionConfig.push( {"optionLabel": "<i class='ion-ios7-minus-outline'></i>"+window.getI18n(ti18n,"REMOVE_FROM_MY_TREBBLE"),"name": "removeFromRadio", "validationHandler":  this._canBeRemovedToRadio.bind(this), "onOptionSelected": this._onRemoveSongToRadioMenuSelected.bind(this)});//"<i class='ion-radio-waves'></i>"};// : "Play Similar In Library"};
    return {};
},

_canBeAddedToRadio : function(songJson){
    const songModel = this._getSongModelFromSongJson(songJson);
    return songModel?!songModel.get("addedToRadio"): true;
    
},

_canBeRemovedToRadio : function(songJson){
    const songModel = this._getSongModelFromSongJson(songJson);
    return songModel?songModel.get("addedToRadio"): false;
    
},

_onAddSongToRadioMenuSelected : function(songJson){
    const songModel = this._getSongModelFromSongJson(songJson);
    const context = this.getContext();
    if(songModel){
        const p =  PersistentModels.getInstance().addSongToDefaultRadio(songModel, context);
        this.trigger("songAddedToTrebble", songModel);
        return p;
    }else{
        return RSVP.Promise.reject(window.getI18n(ti18n,"SONG_COULDNT_BE_FOUND"));
    }
    
},

_onRemoveSongToRadioMenuSelected : function(songJson){
    const songModel = this._getSongModelFromSongJson(songJson);
    const context = this.getContext();
    if(songModel){
        const  p = PersistentModels.getInstance().removeSongFromDefaultRadio(songModel, context);
        this.trigger("songRemovedFromTrebble", songModel);
        return p;
    }else{
        return RSVP.Promise.reject(window.getI18n(ti18n,"SONG_COULDNT_BE_FOUND"));
    }
},

_onShowSongOptionMenuSelected : function(songJson){
    const songModel = this._getSongModelFromSongJson(songJson);
    if(songModel){
        const context = this.getContext();
        return PersistentModels.getInstance().getRouter().showSongMenuOption(songModel, context,null, this.onActionExecutedOnSongHandler.bind(this));
    }else{
        return RSVP.Promise.reject(window.getI18n(ti18n,"SONG_COULDNT_BE_FOUND"));
    }
},

onActionExecutedOnSongHandler : function(songModel, action){
    this.trigger("actionExecutedOnSongHandler", songModel, action);
    /*if(action == "delete" || action == "removeFromRadio" || action == "removeToFavorite"){
        
    }*/
},


_onTagSongOptionMenuSelected : function(songJson){
    const songModel = this._getSongModelFromSongJson(songJson);
    if(songModel){
        const context = this.getContext();
        return PersistentModels.getInstance().getRouter().showSongTagManagerPopup(songModel, context);
    }else{
        return RSVP.Promise.reject(window.getI18n(ti18n,"SONG_COULDNT_BE_FOUND"));
    }
},

getContext : function(){
    return this.collection? this.collection.getContext(): null;
},


_getSongModelFromSongJson : function(songJson){
    return this.collection.get(songJson.uri);
},

_modelTransformHandlerBeforeTemplatePiping: function(userJson) {
    let fullname  = "";
    if(userJson.firstName){
        fullname = fullname + userJson.firstName;
    }
    if(userJson.lastName){
        fullname = fullname + " "+ userJson.lastName;
    }
    fullname = this.toTitleCase(fullname.trim());
    const profileImageUrl = userJson.profileImageUrl?window.trebble.globalHelpers.getResizeImageUrl(userJson.profileImageUrl, 100, 100): "";
    const templateData = {"userProfileImageUrl": profileImageUrl, "fullname": fullname, "username":  userJson.username,"actionButtonIconClass": null};
    return templateData;
},

toTitleCase : function(str)
{
    return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
},


_getItemViewUpdateDuringScrollingHandler : function(elem,templateData){
    const profileImageElem = elem.querySelector(".userCoverImage");
    profileImageElem.src = window.getResourceURLAccordingToLocationProtocol("/img/videoLoading.png");
    profileImageElem.setAttribute("data-original", templateData.userProfileImageUrl);
    if(!profileImageElem.className ||  profileImageElem.className.indexOf("lazy") == -1){
        profileImageElem.className = (!profileImageElem.className)? "lazy" :  profileImageElem.className +" lazy";
    }
    
    const userFullnameElem = elem.querySelector(".userFullname");
    userFullnameElem.textContent = templateData.fullname;
    
    const userUsernameElem = elem.querySelector(".userUsername");
    userUsernameElem.textContent = templateData.username;
    
    const actionButtonElem = elem.querySelector("#actionButton");
    if(templateData.actionButtonIconClass){
        
        actionButtonElem.className = "actionIcon "+templateData.actionButtonIconClass;
        actionButtonElem.style.display = "flex";
        if(actionButtonElem.style.display != "flex"){
            actionButtonElem.style.display = "-webkit-flex";
        }
    }else{
        actionButtonElem.style.display = "none";
    }
},


});

export default TrebbleUserListView;
