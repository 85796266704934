import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import jqueryembedly from "jqueryembedly";
import UrlPreviewTemplate from 'text!../../../templates/common/UrlPreviewTemplate.html';
import RSVP from 'rsvp';

const UrlPreviewView = Backbone.View.extend({



    initialize : function(options) {
        this.compiledTemlate =  _.template(UrlPreviewTemplate);
        this.url = options? options.url : "";
        if($.embedly){
            $.embedly.defaults.key = 'c5a20d3e04ef4dd19754b50920135380';
        }
    },
    
    events : {
        "click": "onClick",
    },

    onClick : function(){
        
    },
    
    _onEmbeddContentAppended : function(data){
        const iframe$el = this.$el.find("iframe").first();
        if(iframe$el){
            const currentSrc = iframe$el.attr("src");
            if(currentSrc && currentSrc.indexOf("//") == 0){
                if(window.location.protocol == "https:"){
                    iframe$el.attr("src","https:"+ currentSrc);
                }else{
                    iframe$el.attr("src","http:"+ currentSrc);
                }	
            }
        }
    },

    _onEmbeddContentDisplay : function(data){
        /*	if(data.type == "video" || data.type == "rich"){
            $(this).replaceWith("<div><div class='thumbnailWrapper'>"+data.html+"</div><div class='info'><div class='title'>"+data.title+"</div><div class='description'>"+data.description+"</div><div class='source'></div></div>");
        }else{
            $(this).replaceWith("<div><div class='thumbnailWrapper'><img class='thumbnail' src='"+data.thumbnail_url+"'/></div><div class='info'><div class='title'>"+data.title+"</div><div class='description'>"+data.description+"</div><div class='source'></div></div>");
        }*/
        if(data && data.type == "error"){
            data.title = data.url;
        }
        if(data.original_url.indexOf("web.trebble.fm") != -1){
            data.thumbnail_url = window.getResourceURLAccordingToLocationProtocol("/css/images/trebbleLogoFullWhite.png");
            data.title = data.original_url;
        }else{
            data.thumbnail_url = window.trebble.globalHelpers.getResizeImageUrl(data.thumbnail_url,  120,52);
        }
        this.$el.html(this.compiledTemlate({"data": data}));
        
        
    },
    
    render : function() {

        this.$el.html("<span class='linkAlternative' href='"+ this.url+"'>"+ this.url+"</span>");
        const isSecure = window.location.protocol == "https:";
        this.$el.addClass("urlPreviewWrapper");
        this.$el.find("span").embedly({query: { maxheight: 76, maxwidth: 120} ,
            done: this._onEmbeddContentAppended.bind(this),
            display : this._onEmbeddContentDisplay.bind(this),
            secure : isSecure
        });
        return this;
    }

});
export default UrlPreviewView;