import _ from 'underscore';
import SequencerOperationLog from "models/audioEditor/sequencerOperationLog/SequencerOperationLog";

const SequencerOperationDeleteAllExceptSelectedNodesLog =  SequencerOperationLog.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperationLog.apply(this, [attributes, options]);
        
    },

    getOperationName : function(){
        return "Delete All Except Selected Nodes";
    },
    
    setStartSequencerNodeToKeep : function(startSequencerNodeToKeep){
        this.set("startSequencerNodeToKeep", startSequencerNodeToKeep);
    },

    getStartSequencerNodeToKeep : function(){
        return this.get("startSequencerNodeToKeep");
    },

    setEndSequencerNodeToKeep : function(endSequencerNodeToKeep){
        this.set("endSequencerNodeToKeep", endSequencerNodeToKeep);
    },

    getEndSequencerNodeToKeep : function(){
        return this.get("endSequencerNodeToKeep");
    },

    setArrayOfNodeToDeleteBefore : function(arrayOfNodeToDeleteBefore){
        this.set("arrayOfNodeToDeleteBefore", arrayOfNodeToDeleteBefore);
    },

    getArrayOfNodeToDeleteBefore : function(){
        return this.get("arrayOfNodeToDeleteBefore");
    },

    setDeleteNodeBefore : function(deleteNodeBefore){
        this.set("deleteNodeBefore", deleteNodeBefore);
    },

    getDeleteNodeBefore : function(){
        return this.get("deleteNodeBefore");
    },

    setDeleteNodeAfter : function(deleteNodeAfter){
        this.set("deleteNodeAfter", deleteNodeAfter);
    },

    getDeleteNodeAfter : function(){
        return this.get("deleteNodeAfter");
    },

    setArrayOfNodeToDeleteAfter : function(arrayOfNodeToDeleteAfter){
        this.set("arrayOfNodeToDeleteAfter", arrayOfNodeToDeleteAfter);
    },

    getArrayOfNodeToDeleteAfter : function(){
        return this.get("arrayOfNodeToDeleteAfter");
    },




});

export default SequencerOperationDeleteAllExceptSelectedNodesLog; 