

import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import ChooseJourneyWidgetController from "controllers/journey/ChooseJourneyWidgetController";
import JourneySearchHelper from "models/helper/JourneySearchHelper";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import CollectionRepeatController from "controllers/common/CollectionRepeatController";
import TrebbleAudioListItemController from "controllers/common/TrebbleAudioListItemController";
import PlaylistHelper from "models/helper/PlaylistHelper";
import TrebbleAudio from "models/TrebbleAudio";
import FileUploadHelper from "models/helper/FileUploadHelper";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import ti18n from "i18n!nls/ConfigureBackgroundMusici18n";
import FacebookEventTrackingHelper from "models/helper/FacebookEventTrackingHelper";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const Rollout = RolloutHelper.getInstance();

//const CONFIGURE_BACKGROUND_MUSIC = Rollout.isFeatureEnabled(Rollout.FEATURES.CONFIGURE_BACKGROUND_MUSIC);

const ChooseMyDraftJourneyWidgetController = ChooseJourneyWidgetController.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);

        options.getTrebbleAudiosLoadHandler = JourneySearchHelper.getInstance().searchForDraftJourneysAndConvertToTrebbleAudioCompilation.bind(JourneySearchHelper.getInstance());

        return ChooseJourneyWidgetController.prototype.initialize.call(this, options);
    },


    
    _buildInfoBoxParamsForTrebbleAudioListController : function(){
        const params = {};
        params.iconClass = "pe-7s-music";
        params.message = window.getI18n(ti18n, "NO_BACKGROUND_MUSIC_IMPORTED");
        return params;
    },



});

export default ChooseMyDraftJourneyWidgetController;