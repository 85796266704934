import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import SongIdentifiedDetaulPopupView from "views/songIdentifiedDetail/SongIdentifiedDetailPopupView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import TrebbleIndexDBHelper from "models/helper/TrebbleIndexDBHelper";
import CordovaHelper from "models/helper/CordovaHelper";
import RSVP from "rsvp";

const SongIdentifiedDetailPopupController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.model = options.model;
        this.context = options.context;
        this.isTrebblePlayingMusic = options.isTrebblePlayingMusic;
        _.extend(this, Backbone.Events);
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    
    
    
    


    
    addSongAsFavorite : function(){
        TrebbleIndexDBHelper.getInstance().saveSongFromIdifiedInfo(this.model, this.context).then(function(){
            window.log("song added to library as favorite");
        }).catch(function(error){
            window.alertErrorMessage(error);
        });
    },
    
    updateSongOfCurrentPlayingSong : function(){
        return PlayerService.getInstance().getCurrentLoadedSong().then((function(currentLoadedSong){
            if(currentLoadedSong)
            {
                const songJsonUpdated = currentLoadedSong.toJSON();
                songJsonUpdated.idified = true;
                songJsonUpdated.idifiedString = "true"
                songJsonUpdated.idifiedInfo =  this.model;
                songJsonUpdated.idifiedSuccess = true;
                songJsonUpdated.idifiedSuccessString = "true";
                songJsonUpdated.idifiedDate = new Date();
                const albumId = this.model.albumId;
                return CordovaHelper.getInstance().executePluginOperation("MusicFileReaderPlugin","deleteAlbumArtInfoFromImageStore", [albumId]).then((function(){
                    const context = PlayerService.getInstance().getPlayerQueueContext();
                    return TrebbleIndexDBHelper.getInstance().saveSong(songJsonUpdated, context);
                }).bind(this));
                
            }else{
                return RSVP.Promise.resolve();
            }
        }).bind(this)).then(function(){
            window.log("song updated in library as favorite");
        }).catch(function(error){
            window.alertErrorMessage(error);
        });
    
    },
    
    updateSongOfCurrentPlayingSongExceptCoverArt : function(){
        return PlayerService.getInstance().getCurrentLoadedSong().then((function(currentLoadedSong){
            if(currentLoadedSong)
            {
                const songJsonUpdated = currentLoadedSong.toJSON();
                songJsonUpdated.idified = true;
                songJsonUpdated.idifiedString = "true"
                songJsonUpdated.idifiedInfo =  this.model;
                songJsonUpdated.idifiedSuccess = true;
                songJsonUpdated.idifiedSuccessString = "true";
                songJsonUpdated.idifiedDate = new Date();
            //	songJsonUpdated.doNotUseIdifiedUrl = true;
            //	songJsonUpdated.preferredAlbumIdForCoverArt = songJsonUpdated.albumJson.albumId;
            //	songJsonUpdated.preferredAlbumArtURL = songJsonUpdated.albumJson.albumArtURL? songJsonUpdated.albumJson.albumArtURL : songJsonUpdated.albumArtUrl ;
                
                this.model.albumArtUrl = null;
                const context = PlayerService.getInstance().getPlayerQueueContext();
                return TrebbleIndexDBHelper.getInstance().saveSong(songJsonUpdated, context);
            }else{
                return RSVP.Promise.resolve();
            }
        }).bind(this)).then(function(){
            window.log("song updated in library as favorite");
        }).catch(function(error){
            window.alertErrorMessage(error);
        });
    
    },
    
    onViewRemoved:function(){
        this.stopListening();
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
            
                this.view = new SongIdentifiedDetaulPopupView({
                    "model" : this.model,
                    "isTrebblePlayingMusic": this.isTrebblePlayingMusic,
                });
                this.listenTo(this.view, "addSongAsFavorite",this.addSongAsFavorite);
                this.listenTo(this.view, "updateSongOfCurrentPlayingSong",this.updateSongOfCurrentPlayingSong);
                this.listenTo(this.view, "remove", this.onViewRemoved.bind(this));
                this.listenTo(this.view, "updateSongOfCurrentPlayingSongExceptCoverArt", this.updateSongOfCurrentPlayingSongExceptCoverArt);
                //this.listenTo(this.view, "playSimilar",this.playSimilarFromLibrary);
                //this.listenTo(this.view, "browseArtist",this.browseArtist);
                this.view.render();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default SongIdentifiedDetailPopupController;