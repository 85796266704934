import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import Levenshtein from "libs/levenshtein/levenshtein";
import TrebbleAudio from "models/TrebbleAudio";
import ti18n from "i18n!nls/helperi18n";
import RSVP from "rsvp";

const client_id = 'AMfhsXogG0lPV25fcTJU';
const client_secret = "fec3c076073343b21bd04d32acf67903b051dd99"
const FREESOUND_ID_PREFIX = "myInstants:";
const NUMBER_OF_RESULTS_PER_PAGE = 10;
const MyInstantsSoundSearchHelper =  Backbone.Model.extend({

    initialize : function() {

    },

    getNumberOfResultsPerPage : function(){
        return NUMBER_OF_RESULTS_PER_PAGE;
    },

    searchForSounds : function(query, pageIndex){
        const params = {};
        params.name = query;
        params.format = "api";
        params.page = pageIndex + 1;
        return this._makeRequestToMyInstantsApi("/api/v1/instants/", params).then((function(responseString){
            const response = JSON.parse(responseString);
            const trebbleAudioArray = [];
            if(response && response.count > 0){
                const results = response.results;
                for(let i =0; i < response.results.length; i++){
                    trebbleAudioArray.push(this._createTrebbleAudioFromMyInstantsData(response.results[i]));
                }
            }
            return trebbleAudioArray;
        }).bind(this))
    },


    _createTrebbleAudioFromMyInstantsData : function(myInstantsData){
        const audioId = FREESOUND_ID_PREFIX + myInstantsData.slug;
        const title = myInstantsData.name;
        const artist = "";
        const creationDate = null;//new Date(myInstantsData.created);
        const audioUrl = myInstantsData.sound;
        return new TrebbleAudio({
            "audioId": audioId,
            "audioType": Utils.getInstance().getTrebbleAudioType().MY_INSTANT_SOUND,
            "title": title,
            "artist": artist,
            "creationDate": creationDate,
            "audioUrl": audioUrl
        })

    },


    _makeRequestToMyInstantsApi : function(apiEndpoint,params ){
        return new Promise(function(resolve, reject){
            try{
                const url =  "https://www.myinstants.com"+apiEndpoint;
                if(!params){
                    params = {};
                }
                params.token = client_secret;
                const cachedAuthorization  = $.ajaxSettings.headers["Authorization"]; 
                delete $.ajaxSettings.headers["Authorization"]; 
                $.ajax({
                    url: url,
                    type: 'GET', 
                    //crossDomain: true,
                    data: params,
                    // Fetch the stored token from localStorage and set in the header

                }).done(resolve).fail(reject);
                $.ajaxSettings.headers["Authorization"] = cachedAuthorization;
            }catch(error){
                reject(error);
            }
        });
    },




});
const myInstantsSoundSearchHelperInstance = new MyInstantsSoundSearchHelper();

export default {
	getInstance : function() {
		return myInstantsSoundSearchHelperInstance;
	}
};
