import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import ti18n from "i18n!nls/loginPagei18n";
import RSVP from "rsvp";

const ENDPOINT_URL = 'https://appleid.apple.com';
const APPLE_ID_STATE = Utils.getInstance().generateRandomId();
const AUTHORIZATION_URL_REDIRECT = "https://s.trebble.fm/redirect";
const APPLE_CLIENT_ID = 'fm.trebble.web';

const WEB_ERROR_CODE = {
    "POPUP_CLOSED_BY_USER": "popup_closed_by_user",
    "USER_CANCELLED_LOGIN_WITH_APPLE": "user_cancelled_authorize"
}

const AppleIDHelper = Backbone.Model.extend({


    initialize: function() {
        if (window.waitForCordovaToLoad && window.isIOSMobileDevice) {

        } else {
            if (window.AppleID && window.AppleID.auth) {
                window.AppleID.auth.init({
                    clientId: APPLE_CLIENT_ID,
                    scope: 'name email',
                    redirectURI: 'https://web.trebble.fm',
                    state: APPLE_ID_STATE,
                    usePopup: true
                });

            }
        }


    },

    getAuthorizationUrl: function() {
        const queryData = {};
        queryData.client_id = APPLE_CLIENT_ID;
       // queryData.response_mode = "form_post";
        queryData.response_type = "code";
        queryData.state = APPLE_ID_STATE;
        queryData.redirect_uri = AUTHORIZATION_URL_REDIRECT;
        queryData.scope = "openid";
        return ENDPOINT_URL + "/auth/authorize?" + Utils.getInstance().encodeQueryData(queryData);
    },

    getAuthorizationCodeInfo: function() {
        //if (cordova.InAppBrowser) {
        if (true) {
            let operationCompleted = false;
            return new RSVP.Promise((function(resolve, reject) {
                const childDocument = window.open(this.getAuthorizationUrl(), '_blank');
                childDocument.addEventListener("loadstop", (function() {
                    if (childDocument.url.startsWith(AUTHORIZATION_URL_REDIRECT)) {
                        const success = Utils.getInstance().getParameterByName("success", childDocument.url);
                        if (success === "true") {
                            const code = Utils.getInstance().getParameterByName("code", childDocument.url);
                            const client_secret = Utils.getInstance().getParameterByName("client_secret", childDocument.url);
                            const email = Utils.getInstance().getParameterByName("email", childDocument.url);

                            let firstName = null;
                            let middleName = null;
                            let lastName = null;
                            if (email) {
                                firstName = Utils.getInstance().getParameterByName("first_name", childDocument.url);
                                middleName = Utils.getInstance().getParameterByName("middle_name", childDocument.url);
                                lastName = Utils.getInstance().getParameterByName("last_name", childDocument.url);
                            }
                            operationCompleted = true;
                            resolve({
                                "code": code,
                                "client_secret": client_secret,
                                "email": email,
                                "firstName": firstName,
                                "middleName": middleName,
                                "lastName": lastName
                            });
                            childDocument.close();

                        } else {
                            operationCompleted = true;
                            reject(window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_CREATING_APPLE_ACCOUNT"))
                        }
                    }
                }).bind(this))

                childDocument.addEventListener("loaderror", (function() {
                    if (!operationCompleted) {
                        operationCompleted = true;
                        reject(window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_CREATING_APPLE_ACCOUNT"));
                    }
                }).bind(this))

                childDocument.addEventListener("exit", (function() {
                    if (!operationCompleted) {
                        operationCompleted = true;
                        reject(window.getI18n(ti18n, "USER_CLOSE_APPLE_LOGIN_POPUP"));
                    }
                }).bind(this))
            }).bind(this))
        }
    },

    isAvailable: function() {
        return RSVP.Promise.resolve(true);
    },

    formatIOSAuthInfoToWebAuthInfo: function(iosAuthInfo) {
        if (iosAuthInfo) {
            const id_token = iosAuthInfo.identityToken
            const state = iosAuthInfo.state;
            const appleUserId = iosAuthInfo.user;
            const email = iosAuthInfo.email;
            const firstName = iosAuthInfo.fullName ? iosAuthInfo.fullName.givenName : null;
            const lastName = iosAuthInfo.fullName ? iosAuthInfo.fullName.familyName : null;
            const code = iosAuthInfo.authorizationCode;
            const webAuthInfo = {
                "authorization": {
                    "state": state,
                    "code": code,
                    "id_token": id_token
                }
            };
            if (email || firstName || lastName) {
                webAuthInfo.user = {
                    "email": email,
                    "name": {
                        "firstName": firstName,
                        "lastName": lastName
                    }
                }
            }

            return webAuthInfo;
        } else {
            return null;
        }
    },

    _getAuthInfo: function() {
        if (window.isIOSMobileDevice && window.cordova && window.cordova.plugins && window.cordova.plugins.SignInWithApple) {
            return this._getAuthInfoForIOS();
        } else {
            return this._getAuthInfoForWeb();
        }
        

    },

    _parseAndThrowLocalisedErrorFromWebError: function(webError) {

        if (webError.error === WEB_ERROR_CODE.POPUP_CLOSED_BY_USER) {
            throw window.getI18n(ti18n, "USER_CLOSE_APPLE_LOGIN_POPUP");
        }
        if (webError.error === WEB_ERROR_CODE.USER_CANCELLED_LOGIN_WITH_APPLE) {
            throw window.getI18n(ti18n, "USER_CANCELLED_LOGIN_WITH_APPLE");
        }
        console.error(webError.error);
        throw window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_CREATING_APPLE_ACCOUNT");
    },

    _parseAndThrowLocalisedErrorFromIOSError: function(iosError) {
        if (iosError.code === 1000) {
            throw window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_CREATING_APPLE_ACCOUNT");
        }
        if (iosError.code === 1001 || iosError.code === 1003) {
            throw window.getI18n(ti18n, "USER_CANCELLED_LOGIN_WITH_APPLE");
        }

        if (iosError.code === 1002) {
            throw window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_CREATING_APPLE_ACCOUNT");
        }
        throw window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_CREATING_APPLE_ACCOUNT");
    },

    _getAuthInfoForWeb: function() {

        return window.AppleID.auth.signIn().catch(this._parseAndThrowLocalisedErrorFromWebError.bind(this));
    },

    _getAuthInfoForAndroid: function() {
        //return window.AppleID.auth.signIn().catch(this._parseAndThrowLocalisedErrorFromWebError.bind(this));
        let authorizationCodeInfoRetrieved = null;
        return this.getAuthorizationCodeInfo().then(function(authorizationCodeInfo) {
            authorizationCodeInfoRetrieved = authorizationCodeInfo;
            return TrebbleClientAPIHelper.getInstance().retrieveAppleAuthorizationTokenInfo(authorizationCodeInfo.code, authorizationCodeInfo.client_secret)
        }).then(function(authorizationTokenInfo) {
            const webAuthInfo = {
                "authorization": {
                    "state": APPLE_ID_STATE,
                    "code": authorizationCodeInfoRetrieved.code,
                    "id_token": authorizationTokenInfo.id_token
                }
            };
            if (authorizationCodeInfoRetrieved.email || authorizationCodeInfoRetrieved.firstName || authorizationCodeInfoRetrieved.lastName) {
                webAuthInfo.user = {
                    "email": authorizationCodeInfoRetrieved.email,
                    "name": {
                        "firstName": authorizationCodeInfoRetrieved.firstName,
                        "lastName": authorizationCodeInfoRetrieved.lastName
                    }
                }
            }

            return webAuthInfo;
        })
    },

    _getAuthInfoForIOS: function() {
        const onSuccessResponse = (function(resolveFunction) {
            return (function(iosAuthInfo) {
                resolveFunction(this.formatIOSAuthInfoToWebAuthInfo(iosAuthInfo));
            }).bind(this)
        }).bind(this)

        const onFailureResponse = (function(rejectFunction) {
            return (function(error) {
                rejectFunction(this._parseAndThrowLocalisedErrorFromIOSError(error));
            }).bind(this)
        }).bind(this);

        const requestedScopesArray = [0, 1];
        return new RSVP.Promise(function(resolve, reject) {
            window.cordova.plugins.SignInWithApple.signin({
                requestedScopes: requestedScopesArray
            }, onSuccessResponse(resolve), onFailureResponse(reject));

        })
    },

    login: function() {
        let appleUserAuthInfo = null;
        return this._getAuthInfo().then((function(authInfo) {
            appleUserAuthInfo = authInfo;
            if (authInfo.user) {
                return this.saveAppleUserInfo(authInfo, 5, 0);
            } else {
                return RSVP.Promise.resolve(true);
            }
        }).bind(this)).then(function(noErrorSavingAppleUserInfo) {
            if (noErrorSavingAppleUserInfo) {
                return appleUserAuthInfo;
            }
        });
    },

    saveAppleUserInfo: function(authInfo, maxNumberOfTrial, trialCounter) {
        if (trialCounter < maxNumberOfTrial) {
            const email = authInfo.user ? authInfo.user.email : null;
            const firstName = authInfo.user && authInfo.user.name ? authInfo.user.name.firstName : null;
            const lastName = authInfo.user && authInfo.user.name ? authInfo.user.name.lastName : null;
            return TrebbleClientAPIHelper.getInstance().saveAppleUserInfo(authInfo.authorization.id_token, email, firstName, lastName).then((function(sucessfullSave) {
                if (sucessfullSave) {
                    return RSVP.Promise.resolve(true);
                } else {
                    if (window.trebbleAnalyticsHelper) {
                        window.trebbleAnalyticsHelper.trackEvent("AppleUserID", "Failed Saving Apple User Info", "Failed Saving Apple User Info", null, authInfo);
                    }
                    return this.saveAppleUserInfo(authInfo, maxNumberOfTrial, trialCounter + 1);

                }
            }).bind(this)).catch((function(error) {
                console.error("Failed saving Apple User Info. User info =" + JSON.stringify(authInfo));
                if (window.trebbleAnalyticsHelper) {
                    window.trebbleAnalyticsHelper.trackEvent("AppleUserID", "Failed Saving Apple User Info", "Failed Saving Apple User Info", null, {
                        "authInfo": authInfo,
                        "error": error
                    });
                }
                return this.saveAppleUserInfo(authInfo, maxNumberOfTrial, trialCounter + 1);

            }).bind(this))
        } else {
            return RSVP.Promise.resolve(false);
        }
    },



    logout: function() {
        return new RSVP.Promise(function(resolve, reject) {
            if (window.waitForCordovaToLoad) {
                if (window.cordova && window.cordova.plugins && window.cordova.plugins.SignInWithApple) {
                    try {
                        //window.cordova.plugins.SignInWithApple
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                }
            } else {
                if (window.AppleID) {
                    try {
                        //window.AppleID.auth.logout(resolve);
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                } else {
                    resolve();
                }
            }

        });
    }



});

const AppleIDHelperInstance = new AppleIDHelper();

export default {
    getInstance: function() {
        return AppleIDHelperInstance;
    }
};