import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import WordSequencerNodeInspectorView from "views/audioEditor/sequencerNodeInspectorSection/WordSequencerNodeInspectorView";
import PauseSequencerNodeInspectorView from "views/audioEditor/sequencerNodeInspectorSection/PauseSequencerNodeInspectorView";
import PunctuationSequencerNodeInspectorView from "views/audioEditor/sequencerNodeInspectorSection/PunctuationSequencerNodeInspectorView";
import WrapWithMusicSequencerNodeInspectorView from "views/audioEditor/sequencerNodeInspectorSection/WrapWithMusicSequencerNodeInspectorView";
import SequencerNodeInspectorSectorTemplate from 'text!../../../../templates/audioEditor/sequencerNodeInspectorSection/SequencerNodeInspectorSectorTemplate.html';
import ti18n from 'i18n!nls/Sequenceri18n';
import RSVP from 'rsvp';


const SequencerNodeInspectorSectionView = Backbone.View.extend({



    initialize : function(options) {
        this.compiledTemlate =  _.template(SequencerNodeInspectorSectorTemplate);
        this.model = options? options.model : null;
        this._sequencer = this.model;
        this._sequenverView = options? options.sequencerView: null;
        this._currentInspectorView = null;
    },
    
    events : {

    },

    updateCurrentInspectorView : function(newInspectorView){
        this.destroyCurrentInspectorView();
        if(newInspectorView){
            this._currentInspectorView = newInspectorView;
            this.$el.append(newInspectorView.$el);
        }
    },
    
    destroyCurrentInspectorView : function(){
        if(this._currentInspectorView){
            this._currentInspectorView = null
        }
    },


    getModel: function(){
        return this.model;
    },

    render : function() {
        this.$el.html(this.compiledTemlate());
        this.setElement(this.$el.find(".sequencer_node_inspector").first());
        return this;
    }

    
    

});
export default SequencerNodeInspectorSectionView;