import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import AudioSequencerNodeInspectorController from "controllers/audioEditor/sequencerNodeInspectorSection/AudioSequencerNodeInspectorController";
import VideoSequencerNodeInspectorController from "controllers/audioEditor/sequencerNodeInspectorSection/VideoSequencerNodeInspectorController";
import WrapWithMusicSequencerNodeInspectorController from "controllers/audioEditor/sequencerNodeInspectorSection/WrapWithMusicSequencerNodeInspectorController";
import PunctuationSequencerNodeInspectorController from "controllers/audioEditor/sequencerNodeInspectorSection/PunctuationSequencerNodeInspectorController";
import PauseSequencerNodeInspectorController from "controllers/audioEditor/sequencerNodeInspectorSection/PauseSequencerNodeInspectorController";
import DeletedSequencerNodeInspectorController from "controllers/audioEditor/sequencerNodeInspectorSection/DeletedSequencerNodeInspectorController";
import WordSequencerNodeInspectorController from "controllers/audioEditor/sequencerNodeInspectorSection/WordSequencerNodeInspectorController";
import NoElementSelectedNodeInspectorController from "controllers/audioEditor/sequencerNodeInspectorSection/NoElementSelectedNodeInspectorController";
import MultipleElementSelectedNodeInspectorController from "controllers/audioEditor/sequencerNodeInspectorSection/MultipleElementSelectedNodeInspectorController";
import SequencerNodeInspectorSectionView from "views/audioEditor/sequencerNodeInspectorSection/SequencerNodeInspectorSectionView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const SequencerNodeInspectorSectionController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.model =  options.sequencer;
        this._sequencerView = options.sequencerView;
        this._nodeInspectorControllerForSelectedNode =  null;
        this._selectionInfoForInitialization = options.selectionInfoForInitialization;
        _.extend(this, Backbone.Events);
        if(this._sequencerView){
            this.listenTo(this._sequencerView, "onSelectionChange", this._onSequencerSelectionChange);
        }
    },

    _onSequencerSelectionChange : function(selectionInfo){
        if(selectionInfo){
            const startSequencerNodeModel = selectionInfo.startSequencerNodeModel;
            const endSequencerNodeModel = selectionInfo.endSequencerNodeModel;
            const startSequencerNodeView = selectionInfo.startSequencerNodeView;
            const endSequencerNodeView = selectionInfo.endSequencerNodeView;
            const selectionEndFocusNodeView = selectionInfo.selectionEndFocusNodeView;
            if(startSequencerNodeModel  == endSequencerNodeModel){
                if(startSequencerNodeModel.getSelectable()){
                    this._updateNodeInspectorForSelection(this._createSequencerNodeInspectorController(startSequencerNodeModel));
                }else{
                    this._destroyCurrentInspectorControllerForSelectedNode();
                    this._updateNodeInspectorForSelection(new NoElementSelectedNodeInspectorController({}));
                }
            }else{
                this._destroyCurrentInspectorControllerForSelectedNode();
                this._updateNodeInspectorForSelection(new MultipleElementSelectedNodeInspectorController({"numberOfElementsSelected": this._sequencerView.getNumberOfElementsSelected()}));
            }
        }else{
            this._destroyCurrentInspectorControllerForSelectedNode();
            this._updateNodeInspectorForSelection(new NoElementSelectedNodeInspectorController({}));
        }
    },

    _updateNodeInspectorForSelection : function(newNodeInspectorControllerForSelectedNode){
        this._nodeInspectorControllerForSelectedNode = newNodeInspectorControllerForSelectedNode;
        if(this._nodeInspectorControllerForSelectedNode){
            this._nodeInspectorControllerForSelectedNode.buildModelAndRenderView().then((function(){
                if(this._nodeInspectorControllerForSelectedNode){
                    this.getView().updateCurrentInspectorView(this._nodeInspectorControllerForSelectedNode.getView());
                }
            }).bind(this))
        }
    },

    _destroyCurrentInspectorControllerForSelectedNode : function(){
        if(this._nodeInspectorControllerForSelectedNode){
            this._nodeInspectorControllerForSelectedNode.destroyView();
        }
        this._nodeInspectorControllerForSelectedNode =  null;
        this.getView().updateCurrentInspectorView(null);
    },

    _createSequencerNodeInspectorController : function(sequencerNode){
        const params = {"sequencerNode": sequencerNode, "sequencer": this.model};
        const audioSegment = sequencerNode.getAudioSegment();
        if(Utils.getInstance().isWordSequencerNodeInstance(sequencerNode)){
            return new WordSequencerNodeInspectorController(params);

        }
        if(Utils.getInstance().isPauseSequencerNodeInstance(sequencerNode)){
            return new PauseSequencerNodeInspectorController(params);
            
        }
        if(Utils.getInstance().isPunctuationSequencerNodeInstance(sequencerNode)){
            return new PunctuationSequencerNodeInspectorController(params);

        }
        if(Utils.getInstance().isDeletedSequencerNodeInstance(sequencerNode)){
           return new DeletedSequencerNodeInspectorController(params);
        }
        if(Utils.getInstance().isStartMusicWrapSequencerNodeInstance(sequencerNode)){
            return new WrapWithMusicSequencerNodeInspectorController(params);

        }

        if(Utils.getInstance().isEndMusicWrapSequencerNodeInstance(sequencerNode)){
            return new WrapWithMusicSequencerNodeInspectorController(params);
        }
        
        if(Utils.getInstance().isAudioSequencerNodeInstance(sequencerNode)){
            return new AudioSequencerNodeInspectorController(params);
        }

        if(Utils.getInstance().isVideoSequencerNodeInstance(sequencerNode)){
            return new VideoSequencerNodeInspectorController(params);
        }

        /*if(Utils.getInstance().isUnsupportedAudioSegmentInstance(audioSegment)){
            const s = new SequencerUnsupportedNodeView(params);
            s.render();
            return s;
        }*/

        return null;

    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {

                const params = {};
                params.model = this.model ;
                params.sequencerView = this._sequencerView;
                this.view = new SequencerNodeInspectorSectionView(params);
                this.view.render();
                if(this._selectionInfoForInitialization){
                    this._onSequencerSelectionChange(this._selectionInfoForInitialization);
                }else{
                    this._onSequencerSelectionChange(null);
                }


                resolve();

            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default SequencerNodeInspectorSectionController;