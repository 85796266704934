import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import SetupVoiceEnabledDeviceContentView from "views/setupVoiceEnabledDevices/SetupVoiceEnabledDeviceContentView";
import DynamicPageView from "views/common/DynamicPageView";
import ti18n from "i18n!nls/SetupVoiceEnabledDevicei18n";
import PageTemplate from 'text!../../../templates/setupVoiceEnabledDevices/SetupVoiceEnabledDevicePageTemplate.html';
import RSVP from "rsvp";

const SetupVoiceEnabledDevicesPageController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = options.model;
        this._pageId = options.pageId;
        
    },
    
    _onPageShow : function(){
        
    },
    


    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    showAllOptionsIfApplicableOrNavigateToPreviousPage : function(){
        if(this.setupVoiceEnabledDeviceContentView.isSpecificInstuctionDisplayed()){
            this.setupVoiceEnabledDeviceContentView.showAllInstructionOptions();
        }else{
            this.view.navigateToPreviousPage();
        }
    },
    
    

    buildModelAndRenderView : function() {
        const params = {};
        const setupVoiceEnabledDeviceContentView =  new SetupVoiceEnabledDeviceContentView(params);
        this.setupVoiceEnabledDeviceContentView = setupVoiceEnabledDeviceContentView;
        const pageViewParams= {};
        pageViewParams.pageId = this._pageId;
        pageViewParams.pageViewTemplate = PageTemplate;
        pageViewParams.containerSelector = ".ui-content";
        pageViewParams.contentView = setupVoiceEnabledDeviceContentView;
        pageViewParams.onPageShow = this._onPageShow.bind(this);
        pageViewParams.pageTemplateOptions = {"ti18n":ti18n};
        this.view = new DynamicPageView(pageViewParams);
        this.view.render();
        this.view.$el.on("click", "#header-back-button", this.showAllOptionsIfApplicableOrNavigateToPreviousPage.bind(this));
        return RSVP.Promise.resolve();

    }

});

export default SetupVoiceEnabledDevicesPageController;