import React from "react";
import LocalStorageHelper from "../../models/helper/LocalStorageHelper";
import useBackboneModel from "./useBackboneModel";

const useSequencerDarkModeStatus = () => {
    return useBackboneModel(LocalStorageHelper.getInstance(), (model) =>{ 
        return model.getUseEditorDarkTheme();
    }, "useEditorDarkThemeKey");
}

export default useSequencerDarkModeStatus;