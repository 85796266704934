import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import ti18n from "i18n!nls/ConfigureBackgroundMusici18n";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import CustomSearchFieldView from "views/common/CustomSearchFieldView";
import Utils from "models/helper/Utils";
import ChooseSoundPopupContentTemplate from 'text!../../../templates/chooseSound/ChooseSoundPopupContentTemplate.html';

const Rollout = RolloutHelper.getInstance();

const ChooseSoundWidgetView = Backbone.View.extend({

    "pageName": "Choose Background Music",

    initialize: function(options) {
        this.contentViewTemplate = _.template(ChooseSoundPopupContentTemplate);
        this.trebbleAudioListView = options.trebbleAudioListView;
        this.sourceCreditName = options.sourceCreditName;
        this.sourceCreditWebsite = options.sourceCreditWebsite;
        this._delayInMilliSecondOnSearchQueryExecution = 500;
    },


    _buildSearchHeaderButtonAndToPopup: function(){
        const customSearchFieldViewParams = {};
        customSearchFieldViewParams.searchFieldPlaceholderSelector = ".search_field_wrapper";
        customSearchFieldViewParams.searchFieldPlaceholderSelectorRootNodeEl  = this.el;
        customSearchFieldViewParams.placeholderText = window.getI18n(ti18n, "SEARCH_PLACEHOLDER");
        this.customSearchFieldView = new CustomSearchFieldView(customSearchFieldViewParams);
        this.customSearchFieldView.render();
        this.customSearchFieldView.addKeyUpHandlerFunction(this._onKeyUpInSearchField.bind(this));
        this.customSearchFieldView.addOnSearchFieldClearedFunction(this._onSearchFieldCleared.bind(this));
        this.customSearchFieldView.addOnScreenResizeHandlerFunction(this._resizeSearchField.bind(this));
        //this.customSearchFieldView.focusSearchField();
        this.$el.find(".search_field_wrapper").prepend(this.customSearchFieldView.el);
        this.customSearchFieldView.onSearchButtonTapped();
    },

    _onKeyUpInSearchField : function(searchFieldValue){

        if(this._pendingSearchQueryId)
        {
            clearTimeout(this._pendingSearchQueryId);
        }
        //wait a certain amount of time before sending search event
        this._pendingSearchQueryId = setTimeout((function(){
            this.trigger("keyUpOnSearchField",searchFieldValue);
        }).bind(this), this._delayInMilliSecondOnSearchQueryExecution);
    },

    _onSearchFieldCleared : function(){
        this.trigger("searchFieldCleared");
    },

    _resizeSearchField : function(searchField){

    },

    isLoading : function(isLoading){
        this.$el.find(".choose_background_music_popup_content_wrapper").attr("isLoading",isLoading)
    },

    afterRendering: function() {
       this.backgroundMusicList$el = this.$el.find(".background_music_list");
       this.backgroundMusicCategoryList$el = this.$el.find(".tag_button_list_wrapper");
       this.backgroundMusicList$el.append(this.trebbleAudioListView.$el);
       this.sourceCreditWrapper$el = this.$el.find(".sound_source_credit_wrapper");
       if(this.sourceCreditName){
        let sourceCreditHtml = window.getI18n(ti18n, "POWERED_BY")+ " ";
        if(this.sourceCreditWebsite){
            sourceCreditHtml = sourceCreditHtml + "<a target='_blank' href='"+this.sourceCreditWebsite+"'>"+this.sourceCreditName+"</a>";
        }else{
            sourceCreditHtml = sourceCreditHtml + this.sourceCreditName
        }
        this.sourceCreditWrapper$el.html(sourceCreditHtml)
       }else{
        this.sourceCreditWrapper$el.hide();
       }
       this._buildSearchHeaderButtonAndToPopup();
   },


   render : function() {
    const  templateParams = {};
    templateParams.ti18n =  ti18n;
    templateParams.Rollout = Rollout;
    this.$el.html(this.contentViewTemplate(templateParams));
    this.setElement(this.$el.find(".choose_background_music_popup_content_wrapper").first());
    this.afterRendering();
    return this;
}



});

export default ChooseSoundWidgetView;
