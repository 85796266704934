import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Song from "models/Song";

const Songs =  Backbone.Collection.extend({
    model: Song,


    initialize: function (){
        this._sort_key ="title";
        this._descending = false;

    },

    url: function() {
        return '/myOwnSongLibrary'; 
    },

    searchOnTitleAndArtist : function(letters, resultSize, customFilter){
        if(!letters){
            letters = "";
        }
        if(letters == "" && !customFilter)
        {
            if(resultSize)
            {
                return this.models.slice(0,resultSize );
            }else{
                return this.models;
            }
            
        }
        
        var resultFound = 0;
        letters  = letters.toLowerCase();
        
        var patternArray = [];
        var words = letters.split(/\s+/);
        for(let index in words){
            patternArray.push(new RegExp(words[index],"gi"));
        }
        return this.filter(function(data) {
            if(customFilter){
                var songType = data.get("type");
                var capsuleUIDs = data.get("capsuleUIDs");
                var tagIds = data.get("tagIds");
                if(customFilter == "ON_DEVICE"){
                    if(songType != "localFile"){
                        return false;
                    }
                }
                if(customFilter == "IN_CLOUD"){
                    if(songType != "remote"){
                        return false;
                    }
                    
                }
                if(customFilter == "WITHOUT_CAPSULE"){
                    if(capsuleUIDs  &&  capsuleUIDs.length > 0){
                        return false;
                    }
                }
                
                if(customFilter == "WITH_CAPSULE"){
                    if(!capsuleUIDs  ||  capsuleUIDs.length == 0){
                        return false;
                    }
                }
                
                if(customFilter == "UNGRADED"){
                    if(tagIds  &&  tagIds.length > 0){
                        return false;
                    }
                }
                
                if(customFilter == "GRADED"){
                    if(!tagIds  ||  tagIds.length == 0){
                        return false;
                    }
                }
                
            }
            if(!resultSize || (resultSize  && resultFound < resultSize))
            {
                var titleAndArtist = "";
                if(data.get("title")){
                    titleAndArtist  = titleAndArtist  + data.get("title");
                }
                if(data.get("artist")){
                    titleAndArtist  = titleAndArtist  + " " + data.get("artist");
                }
                var titleAndArtistFromID3Tags = "";
                if(data.get("titleFromFileID3Tags")){
                    titleAndArtistFromID3Tags  = titleAndArtistFromID3Tags  + data.get("titleFromFileID3Tags");
                }
                if(data.get("artistFromFileID3Tags")){
                    titleAndArtistFromID3Tags  = titleAndArtistFromID3Tags  + " " + data.get("artistFromFileID3Tags");
                }
                 
                
                var found = true;
                for(let index in patternArray){
                    var pattern = patternArray[index];
                    found = pattern.test(titleAndArtist.toLowerCase()) || pattern.test(titleAndArtistFromID3Tags.toLowerCase()); 
                    if(!found){
                        break;
                    }
                }
                if(found){
                    resultFound = resultFound+ 1;
                }
            
                return  found;
            }else{
                return false;
            }
        });
    },
    
     comparatorOld: function(item) {
            var data = item.get(this._sort_key);
            if(data && ( typeof data == "string" || data instanceof String))
            {

                data = data.trim().toLowerCase();
            }
            if(data && ( data instanceof Date))
            {
                data = data.getTime();
            }
            if(this._descending ){
                return  -data;
            }else{
                return  data;
            }
      },
      
      getCompareValue : function(item){
            var data = item.get(this._sort_key);
            if(data && ( typeof data == "string" || data instanceof String))
            {

                data = data.trim().toLowerCase();
            }
            if(data && ( data instanceof Date))
            {
                data = data.getTime();
            }

            return  data;
      },
      
         comparator: function(itemA , itemB) {
                var dataA = this.getCompareValue(itemA);
                var dataB = this.getCompareValue(itemB);

                    if(!dataA && dataB){
                        if(this._descending){
                            return 1;
                        }else{
                            return -1;
                        }
                    }
                    if(!dataB && dataA){
                        if(this._descending){
                            return -1;
                        }else{
                            return 1;
                        }
                    }
                    if (dataA > dataB) {
                        if(this._descending){
                            return -1;
                        }else{
                            return 1;
                        }
                    }
                     if (dataB > dataA){
                        if(this._descending){
                            return 1;
                        }else{
                            return -1;
                        }
                     }
                     return 0;
            
          },
      
      sortByField: function(fieldName, descending) {
          if(fieldName){
              if(fieldName == "TITLE_ASCENDING"){
                  this._sort_key = "title";
                  this._descending = false;
              }else{
                  if(fieldName == "TITLE_DESCENDING"){
                      this._sort_key = "title";
                      this._descending = true;
                  }else{
                      if(fieldName == "RECENTLY_ADDED"){
                          this._sort_key = "dateAdded";
                          this._descending = true;
                      }else{ 
                          if(fieldName == "FIRST_ADDED"){
                              this._sort_key = "dateAdded";
                              this._descending = false;
                          }else{
                              this._sort_key = fieldName? fieldName : this._sort_key;
                              this._descending = descending?descending :false;
                          }
                      }
                  }
              }
             
              this.sort();
          }else{
              this._sort_key = null;
          }
      },

    getSongsWithURI : function(arrayOfURIs){
        
 
        return this.filter(function(data) {
            return  (arrayOfURIs.indexOf(data.get("uri")) > -1);
            
        });
    },
    
    getContext : function(){
        return this._context;
    },
    
    updateContext : function(newContext){
        this._context = newContext
    },
    

    save: function(attrs, options){
        window.alertErrorMessage("attrs" + JSON.stringify(attrs));
        window.alertErrorMessage("options" + JSON.stringify(options));

        Backbone.sync('create', this, {
            success: function() {
                window.log('Saved!');
            },
            failure: function(){
                window.alertErrorMessage("savin..6");
            }
        });
    }
});
export default Songs;