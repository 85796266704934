self.addEventListener(
    "message",
    function (e) {
      const { sharedBuffer, numberOfChannels, sampleRate, length  } = e.data;
      const sharedArray = new Float32Array(sharedBuffer);
  
      const writeUTFBytes = function (view, offset, string) {
        for (let i = 0; i < string.length; i++) {
          view.setUint8(offset + i, string.charCodeAt(i));
        }
      };
  
      const writePCMBytes = function (view, offset, audioData, bitsPerSample) {
        const multiplier = Math.pow(2, bitsPerSample - 1);
        for (let i = 0; i < audioData.length; i++) {
          view.setInt16(offset + i * 2, audioData[i] * multiplier, true);
        }
      };
  
      // Create WAV header
      
      const bitsPerSample = 16; // 16-bit audio
      const byteRate = (sampleRate * numberOfChannels * bitsPerSample) / 8;
      const blockAlign = (numberOfChannels * bitsPerSample) / 8;
      const dataSize = length * numberOfChannels * (bitsPerSample / 8);
      const buffer = new ArrayBuffer(44 + dataSize);
      const view = new DataView(buffer);
  
      // Write RIFF header
      writeUTFBytes(view, 0, "RIFF");
      view.setUint32(4, 36 + dataSize, true);
      writeUTFBytes(view, 8, "WAVE");
  
      // Write fmt subchunk
      writeUTFBytes(view, 12, "fmt ");
      view.setUint32(16, 16, true);
      view.setUint16(20, 1, true); // PCM format
      view.setUint16(22, numberOfChannels, true);
      view.setUint32(24, sampleRate, true);
      view.setUint32(28, byteRate, true);
      view.setUint16(32, blockAlign, true);
      view.setUint16(34, bitsPerSample, true);
  
      // Write data subchunk
      writeUTFBytes(view, 36, "data");
      view.setUint32(40, dataSize, true);
  
      const audioData = new Float32Array(length * numberOfChannels);


      for (let i = 0; i < length; i++) {
        for (let channel = 0; channel < numberOfChannels; channel++) {
            audioData[i * numberOfChannels + channel] = sharedArray[channel * length + i];
            self.postMessage({
                progress:
                  ((length * channel + i) * 100) /
                  (length * numberOfChannels),
              });
          }
      
      }
  
      writePCMBytes(view, 44, audioData, bitsPerSample);
  
      // Create Blob
      const wavBlob = new Blob([view], { type: "audio/wav" });
  
      self.postMessage({ wavBlob });
    },
    false
  );
  