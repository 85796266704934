import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import LocalStorageHelper from "models/helper/LocalStorageHelper";

import RSVP from "rsvp";
//"optimizely"

const DEFAUT_DATA_FILE = {

};



const createFeatureWithKey = function(key, variablesArray, shouldBeSharedWithAnalyticsPlatform){
    const variablesObj = {};
    if(variablesArray){
        for(let index in variablesArray){
            const variableKey = variablesArray[index];
            variablesObj[variableKey]= variableKey;
        }
    }
    return {"key": key, "variables" : variablesObj, "shouldBeShared": !!shouldBeSharedWithAnalyticsPlatform};

};

const FEATURES = {
    "DISABLE_SWITCH_TO_CAROUSEL_VIEW_IN_BROWSE_PAGE_ON_MOBILE": createFeatureWithKey("DISABLE_SWITCH_TO_CAROUSEL_VIEW_IN_BROWSE_PAGE_ON_MOBILE"),
    "REDIRECT_TO_BROWSE_PAGE_AFTER_LOGIN_IF_NO_SUBSCRIPTION": createFeatureWithKey("REDIRECT_TO_BROWSE_PAGE_AFTER_LOGIN_IF_NO_SUBSCRIPTION"),
    "SUGGEST_CATEGORY_CHANGE_IN_LIST_VIEW_IN_BROWSE_PAGE": createFeatureWithKey("SUGGEST_CATEGORY_CHANGE_IN_LIST_VIEW_IN_BROWSE_PAGE",["time_in_sec_before_displaying_popup","max_number_of_subscriptions_required_to_suggest","label_cant_find_what_to_subscribe_to_en","label_cant_find_what_to_subscribe_to_fr"]),
    "ASK_FOR_SHORTCASTS_USER_IS_INTERESTED_IN": createFeatureWithKey("ASK_FOR_SHORTCASTS_USER_IS_INTERESTED_IN",["time_in_sec_before_displaying_popup_since_suggestion", "max_number_of_subscriptions_required_to_ask","label_let_us_know_what_you_want_to_hear_en","label_let_us_know_what_you_want_to_hear_fr","label_enter_your_suggesion_here_en","label_enter_your_suggesion_here_fr"]),
    "SHOW_SUGGESTION_TO_LISTEN_TO_FEED" : createFeatureWithKey("SHOW_SUGGESTION_TO_LISTEN_TO_FEED",["min_number_of_subscriptions_to_suggest","label_Its_a_great_time_to_listen_en","label_Its_a_great_time_to_listen_fr","force_user_to_manually_push_play"]),
    "SHOW_SUGGESTION_TO_LISTEN_TO_FEED_ON_FIRST_SUBSCRIPTION" : createFeatureWithKey("SHOW_SUGGESTION_TO_LISTEN_TO_FEED_ON_FIRST_SUBSCRIPTION",["label_yay_just_added_your_first_sub_en","label_yay_just_added_your_first_sub_fr","label_add_2_or_3_more_en","label_add_2_or_3_more_fr"]),
    "PERSONALIZE_BROWSE_PAGE_ON_IOS_APP": createFeatureWithKey("PERSONALIZE_BROWSE_PAGE_ON_IOS_APP",["limit_the_number_of_shortcast_displayed","max_number_of_shortcasts_to_displayed"]),
    "PERSONALIZE_BROWSE_PAGE_ON_WEB": createFeatureWithKey("PERSONALIZE_BROWSE_PAGE_ON_WEB",["min_number_of_swipes_to_suggest_category_change","min_number_of_swipers_to_ask_shortcast_user_interested_in"]),
    "BASE_CUSTOMIZATION_FOR_BROWSE_PAGE": createFeatureWithKey("BASE_CUSTOMIZATION_FOR_BROWSE_PAGE",["display_all_categories_and_languages_by_default","use_default_language_in_filter_if_not_using_all_languages","show_white_theme","fit_current_theme"]),
    "USER_REFERRAL": createFeatureWithKey("USER_REFERRAL",["referral_program_time_limit_string","cost_per_referral_string","refer_title_en","refer_title_fr","refer_desc_en","refer_desc_fr"], true),
    "SHOW_ONE_SLIDE_WITH_VAUE_PROP_AFTER_SPASHSCREEN": createFeatureWithKey("SHOW_ONE_SLIDE_WITH_VAUE_PROP_AFTER_SPASHSCREEN", null, true),
    "TURN_ON_SENDING_SOME_EVENTS_TO_INTERCOM": createFeatureWithKey("TURN_ON_SENDING_SOME_EVENTS_TO_INTERCOM",["events_to_send"]),
    "TRACK_SCROLLS_IN_BROWSE_PAGE_LIST": createFeatureWithKey("TRACK_SCROLLS_IN_BROWSE_PAGE_LIST"),
    "DISPLAY_FEED_SETUP_AFTER_SIGNUP": createFeatureWithKey("DISPLAY_FEED_SETUP_AFTER_SIGNUP",["number_of_subs_to_complete_setup","label_welcome_message_part_1_en","label_welcome_message_part_2_en",
        "label_welcome_small_message_en","label_button_close_welcome_message_en","label_setup_page_title_en","label_setup_instructions_en","label_setup_feed_complete_button_en","label_welcome_message_part_1_fr",
        "label_welcome_message_part_2_fr","label_welcome_small_message_fr","label_button_close_welcome_message_fr","label_setup_page_title_fr","label_setup_instructions_fr","label_setup_feed_complete_button_fr",
        "replace_predefined_formatted_welcome_message_by_html","html_for_welcome_message_en","html_for_welcome_message_fr","force_user_to_manually_push_play","instruction_box_color", "number_of_journey_subs_to_complete_setup",
        "label_yay_only_en","label_yay_only_fr","label_left_to_subscribe_to_continue_en","label_left_to_subscribe_to_continue_fr","label_tap_on_en","label_tap_on_fr","label_to_continue_en","label_to_continue_fr"]),
"ENABLE_INTERCOM_HELP":createFeatureWithKey("ENABLE_INTERCOM_HELP"),
"ENABLE_RECORDING_VIA_SMARTLOOK": createFeatureWithKey("ENABLE_RECORDING_VIA_SMARTLOOK"),
"ENABLE_RECORDING_VIA_UXCAM": createFeatureWithKey("ENABLE_RECORDING_VIA_UXCAM"),
"ENABLE_RECORDING_VIA_FULLSTORY": createFeatureWithKey("ENABLE_RECORDING_VIA_FULLSTORY"),
"AUTOMATICALLY_SKIP_TO_THE_NEXT_AUDIO_PLAYBACK_FAILS_ON_MOBILE": createFeatureWithKey("AUTOMATICALLY_SKIP_TO_THE_NEXT_AUDIO_PLAYBACK_FAILS_ON_MOBILE"),
"FALLBACK_TO_WEB_AUDIO_IF_CORDOVA_MEDIA_PLAYBACK_FAILS": createFeatureWithKey("FALLBACK_TO_WEB_AUDIO_IF_CORDOVA_MEDIA_PLAYBACK_FAILS"),
"TURN_ON_NOTIFICATION_ON_IOS": createFeatureWithKey("TURN_ON_NOTIFICATION_ON_IOS",["time_in_millisec_to_wait_before_prompting"]),
"CAPSULE_RECORDING_UI_DEFAULT_CONFIG": createFeatureWithKey("CAPSULE_RECORDING_UI_DEFAULT_CONFIG",["css_page_background","background_music_intro_length_in_sec","background_fade_in_outro_in_sec",
"background_music_fade_out_end_in_sec","background_fade_out_intro_in_sec","background_volume_level","fade_increment","background_music_end_buffer_in_sec","disable_ability_to_play_music_after_capsule",
"always_use_media_recordjs"]),
"ALLOW_USE_MUSIC_WHILE_RECORDING_CAPSULE_ON_MOBILE": createFeatureWithKey("ALLOW_USE_MUSIC_WHILE_RECORDING_CAPSULE_ON_MOBILE", ["use_cordova_media","use_native_audio"]),
"SHOW_ANALYTICS_PER_CAPSULE": createFeatureWithKey("SHOW_ANALYTICS_PER_CAPSULE",["show_in_mobile_app"]),
"SHOW_INTERSTITIAL_ADS":createFeatureWithKey("SHOW_INTERSTITIAL_ADS", ["show_after_first_shortcast","show_on_app_open","show_interstitials_in_app","show_before_feed","show_when_app_comes_foreground",
"min_time_in_millsec_to_wait_before_showing_ads","show_on_app_open_show_video","show_before_feed_show_video","show_after_first_shortcast_show_video","show_when_app_comes_foreground_show_video",
"show_when_app_comes_foreground_and_audio_is_loaded", "use_faecbook_ads","mute_audio"]),
"PROMPT_USER_TO_RATE_APP": createFeatureWithKey("PROMPT_USER_TO_RATE_APP",["min_number_of_days_between_app_rating_requests","max_number_of_rejection_to_rate_app",
"max_number_of_times_to_suggest_user_to_give_app_improvements","min_number_of_times_feed_needs_to_play_to_for_rating","use_intercom_to_send_suggestion",
"label_how_i_think_the_app_could_be_improved_en","label_how_i_think_the_app_could_be_improved_fr","number_of_seconds_to_wait_before_prompting_user_to_rate"]),
"ENABLE_TREBBLE_PLUS_SUB": createFeatureWithKey("ENABLE_TREBBLE_PLUS_SUB",["wait_for_store_to_be_ready","show_upgrade_btn_in_floating_menu","popup_title_to_upgrade_to_trebble_plus_en","popup_title_to_upgrade_to_trebble_plus_fr",
"popup_subtitle_to_upgrade_to_trebble_plus_en","popup_subtitle_to_upgrade_to_trebble_plus_fr","promo_message_for_yearly_sub_en","promo_message_for_yearly_sub_fr","doublecheck_subscription_via_server_req"]),
"ADD_MY_FEED_PAGE": createFeatureWithKey("ADD_MY_FEED_PAGE",["should_feed_info_displayed_in_subs_page","should_be_default_page","here_is_whats_on_your_feed_en","here_is_whats_on_your_feed_fr",
"here_is_whats_on_your_feed_after_onboarding_en","here_is_whats_on_your_feed_after_onboarding_fr"]),
"SHOW_FLOATING_PLAYER": createFeatureWithKey("SHOW_FLOATING_PLAYER",["use_colored_background","use_background_blurry_image","use_default_gradient_color_for_background","use_floating_player_on_web","use_moving_background"]),
"SHOW_FOOTER_MENU": createFeatureWithKey("SHOW_FOOTER_MENU",["show_on_web_app","always_display_help","show_search_btn_in_browse_page"]),
"MY_SUBS_IN_ALWAYS_EDIT_MODE": createFeatureWithKey("MY_SUBS_IN_ALWAYS_EDIT_MODE",["time_to_wait_before_sending_save_req_in_ms","hide_instructions"]),
"DARK_MODE" :createFeatureWithKey("DARK_MODE",["available_in_app","available_on_web","is_default_in_app","is_default_in_web"]),
"SHOW_RECENTLY_ADDED_IN_BROWSE": createFeatureWithKey("SHOW_RECENTLY_ADDED_IN_BROWSE",["number_of_results","show_featured_only","show_in_onboarding"]),
"CREATE_CAPSULE_FROM_TEXT": createFeatureWithKey("CREATE_CAPSULE_FROM_TEXT",["add_trebble_tag_before_audio","trebble_tag_ssml_en","trebble_tag_ssml_es"]),
"ENABLE_CAPABILITY_TO_PLAY_MUSIC_AFTER_CAPSULE": createFeatureWithKey("ENABLE_CAPABILITY_TO_PLAY_MUSIC_AFTER_CAPSULE",[]),
"CONFIGURE_AUDIO_ANNOUNCEMENTS": createFeatureWithKey("CONFIGURE_AUDIO_ANNOUNCEMENTS",["audio_announcement_turn_off_by_default"]),
"CREATE_SHORTCAST_CATEGORY_RADIO":createFeatureWithKey("CREATE_SHORTCAST_CATEGORY_RADIO",["use_app_language","minimum_number_of_shortcast_available_per_category_to_display",
"start_radio_btn_label_en","start_radio_btn_label_fr","selection_intruction_en","selection_intruction_fr","category_ids_to_exclude","category_ids_separator",
"playlist_title_prefix_en","playlist_title_prefix_fr","playlist_title_sufix_en","playlist_title_sufix_fr", "start_radio_btn_icon_class"]),
"CONFIGURE_BACKGROUND_MUSIC": createFeatureWithKey("CONFIGURE_BACKGROUND_MUSIC",[]),
"CONFIGURE_OAUTH_LOGIN": createFeatureWithKey("CONFIGURE_OAUTH_LOGIN",["create_account_if_login_from_social_and_no_account"]),
"CREATE_CAPSULE_USING_VOICE_ACTOR": createFeatureWithKey("CREATE_CAPSULE_USING_VOICE_ACTOR", ["max_number_of_words_allowed","min_time_required_for_narration_in_hours"]),
"TREBBLE_PLUS_FREE_TRIAL": createFeatureWithKey("TREBBLE_PLUS_FREE_TRIAL",["number_of_days","intercom_carousel_id"]),
"ENABLE_RECORDING_VIA_SMARTLOOK_ANDROID": createFeatureWithKey("ENABLE_RECORDING_VIA_SMARTLOOK_ANDROID",[]),
"SHORTCAST_JOURNEY_DEFAULT_CONFIG": createFeatureWithKey("SHORTCAST_JOURNEY_DEFAULT_CONFIG",["min_number_of_shortcast_required", "trailer_required_for_publising"]),
"ENABLE_JOURNEY_CREATION": createFeatureWithKey("ENABLE_JOURNEY_CREATION",[]),
"ENABLE_JOURNEY_SEARCH": createFeatureWithKey("ENABLE_JOURNEY_SEARCH",[]),
"CONFIRGURE_SERVER_ENDPOINT":createFeatureWithKey("CONFIRGURE_SERVER_ENDPOINT",["server_endpoint"]),
"DISPLAY_PREMIUM_PLANS_AFTER_SETUP":createFeatureWithKey("DISPLAY_PREMIUM_PLANS_AFTER_SETUP",[]),
"CREATE_JOURNEY_USER_REVIEWS":createFeatureWithKey("CREATE_JOURNEY_USER_REVIEWS",["number_of_shortcast_listened_to_leave_review"]),
"SHOW_PROGRESS_BAR_ON_JOURNEY_PAGE": createFeatureWithKey("SHOW_PROGRESS_BAR_ON_JOURNEY_PAGE",["coloured_progress_bar"]),
"SHARE_JOURNEY_TRAILER":createFeatureWithKey("SHARE_JOURNEY_TRAILER"),
"ALLOW_USERS_TO_DISABLE_EMAIL_FEED_REMINDERS":createFeatureWithKey("ALLOW_USERS_TO_DISABLE_EMAIL_FEED_REMINDERS"),
"ALLOW_USER_TO_CONFIGURE_REMINDER_TIME":createFeatureWithKey("ALLOW_USER_TO_CONFIGURE_REMINDER_TIME",["default_time_in_hour","default_timezone"]),
"USE_CUSTOM_LIST_PICKER_ON_IOS":createFeatureWithKey("USE_CUSTOM_LIST_PICKER_ON_IOS"),
"CHANGE_TOOLBAR_COLOR_ON_PAGE_CHANGE":createFeatureWithKey("CHANGE_TOOLBAR_COLOR_ON_PAGE_CHANGE"),
"ALLOW_CREATORS_TO_SETUP_BANKING_INFO": createFeatureWithKey("ALLOW_CREATORS_TO_SETUP_BANKING_INFO"),
"ALLOW_USER_TO_CREATE_PAID_JOURNEYS": createFeatureWithKey("ALLOW_USER_TO_CREATE_PAID_JOURNEYS"),
"JOURNEY_PURCHASE_CONFIG":createFeatureWithKey("JOURNEY_PURCHASE_CONFIG",["open_stripe_checkout_in_new_window_on_web","use_popup_when_waiting_for_purchase_to_complete",
"label_thank_you_for_purchasing_this_journey_en","label_thank_you_for_purchasing_this_journey_fr",
"label_thank_you_for_purchasing_this_journey_submessage_en","label_thank_you_for_purchasing_this_journey_submessage_fr",
"label_thank_you_for_purchasing_en","label_thank_you_for_purchasing_fr",
"label_thank_you_for_purchasing_submessage_en","label_thank_you_for_purchasing_submessage_fr",
"label_waiting_for_your_order_to_complete_en","label_waiting_for_your_order_to_complete_fr",
"use_native_payment_on_ios","use_native_payment_on_android",
"min_cost_of_journey_in_usd","max_cost_of_journey_in_usd","cost_increment_in_usd",
"register_journey_consumables_on_android","register_journey_consumables_on_ios",
"show_success_popup_when_order_is_displayed_during_order","journey_product_id_prefix",
"label_completing_your_order_en","label_completing_your_order_fr"]),
"TREBBLE_TEXT_BASED_AUDIO_EDITOR": createFeatureWithKey("TREBBLE_TEXT_BASED_AUDIO_EDITOR",["render_audio_only_if_necessary", "default_fade_in_milliseconds_for_ducking","normalize_all_audio_buffers",
"apply_loundness_normalization_to_inserted_sounds","inserted_sound_db_level","display_noise_cancellation_warning", "enable_noise_cancellation_feature",
"enable_reset_on_word_and_pause_trim","enable_triming_on_nodes", "apply_sequencer_settings_at_playback", "final_rendered_file_db_lebel","automatic_crossfade_duration_in_milliseconds",
"turn_on_automatic_crossfade_between_cuts", "use_new_audio_rendering_engine","use_slider_input_for_duration", "apply_chrome_workaround_by_resuing_audio_worklet_processor"
,"apply_safari_workaround_for_audioworklet_incomplete_impl", "save_wav_file_when_saving_shortcast","export_mp3_using_a_worker","render_video_using_ffmpeg_overlay"]),
"DOWNLOAD_SHORTCAST": createFeatureWithKey("DOWNLOAD_SHORTCAST",[]),
"RETRIEVE_MUSIC_FROM_EPIDEMIC_SOUND": createFeatureWithKey("RETRIEVE_MUSIC_FROM_EPIDEMIC_SOUND",["epidemic_sound_endpoint","show_music_by_genre"]),
"ALLOW_USER_TO_CLIP_SELECTION_IN_AUDIO_EDITOR": createFeatureWithKey("ALLOW_USER_TO_CLIP_SELECTION_IN_AUDIO_EDITOR",[]),
"BREAK_DOWN_TEXT_IN_AUDIO_EDITOR_AUTOMATICALLY": createFeatureWithKey("BREAK_DOWN_TEXT_IN_AUDIO_EDITOR_AUTOMATICALLY",[]),
"CUT_COPY_PASTE_IN_AUDIO_EDITOR": createFeatureWithKey("CUT_COPY_PASTE_IN_AUDIO_EDITOR",[]),
"ALLOW_TEXT_SEARCH_IN_AUDIO_EDITOR": createFeatureWithKey("ALLOW_TEXT_SEARCH_IN_AUDIO_EDITOR",["allow_playback_of_search_result"]),
"TURN_ON_HACK_IN_AUDIO_EDITOR_TO_PREVENT_STALLED_PLAYBACK": createFeatureWithKey("TURN_ON_HACK_IN_AUDIO_EDITOR_TO_PREVENT_STALLED_PLAYBACK", ["time_in_milliseconds_to_trigger_restart_after_stalled"]),
"USE_CLOUDFRONT_URLS_INSTEAD_OF_S3_URLS_IN_AUDIO_EDITOR": createFeatureWithKey("USE_CLOUDFRONT_URLS_INSTEAD_OF_S3_URLS_IN_AUDIO_EDITOR",[]),
"SHOW_WARNING_WITH_NUMBER_OF_FREE_HOURS_LEFT_OF_TRANSCRIPTION": createFeatureWithKey("SHOW_WARNING_WITH_NUMBER_OF_FREE_HOURS_LEFT_OF_TRANSCRIPTION",["number_of_free_minutes_of_transcription", "limit_on_lifetime", "show_link_to_upgrade"]),
"ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION": createFeatureWithKey("ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION",["stripe_api_key","pro_subscription_price_id","pro_plus_subscription_price_id",
"pro_subscription_price_yearly_id","pro_plus_subscription_price_yearly_id","pro_subscription_product_id","pro_plus_subscription_product_id",
"pro_tier_name_en", "pro_tier_name_fr","pro_plus_tier_name_en","pro_plus_tier_name_fr","minutes_for_pro_tier","minutes_for_pro_plus_tier",
"pro_tier_cost_usd_billed_monthly","pro_tier_cost_usd_billed_yearly","pro_plus_tier_cost_usd_billed_monthly", "pro_plus_tier_cost_usd_billed_yearly",
"stripe_portal_configuration_for_payment_method_change","stripe_portal_configuration_for_invoice_history"]),
"ALLOW_DUCKING_OF_BACKGROUND_MUSIC_IN_AUDIO_EDITOR": createFeatureWithKey("ALLOW_DUCKING_OF_BACKGROUND_MUSIC_IN_AUDIO_EDITOR", []),
"MAKE_DOWNLOAD_SHORTCAST_AS_PAID_FEATURE": createFeatureWithKey("MAKE_DOWNLOAD_SHORTCAST_AS_PAID_FEATURE",[]),
"CONFIGURE_SOUND_ENHANCING":createFeatureWithKey("CONFIGURE_SOUND_ENHANCING",[]),
"AUDIO_EDITOR_ONBOARDING":createFeatureWithKey("AUDIO_EDITOR_ONBOARDING",[]),
"DISABLE_CAN_SAVE_SHORTCAST_ONLY_AFTER_EDIT":createFeatureWithKey("DISABLE_CAN_SAVE_SHORTCAST_ONLY_AFTER_EDIT",[]),
"EDIT_VIDEO_FILES": createFeatureWithKey("EDIT_VIDEO_FILES",[]),
"USE_ZENDESK_FOR_LIVE_CHAT": createFeatureWithKey("USE_ZENDESK_FOR_LIVE_CHAT",[]),
"RENDER_VIDEO_IN_CLOUD":createFeatureWithKey("RENDER_VIDEO_IN_CLOUD",[]),
"MY_LIBRARY_ONBOARDING":createFeatureWithKey("MY_LIBRARY_ONBOARDING",[]),
"RECORD_PAGE_ONBOARDING":createFeatureWithKey("RECORD_PAGE_ONBOARDING",[]),
"SHOW_SEARCH_WIDGET_ON_LEFT_SECTION_OF_EDITOR": createFeatureWithKey("SHOW_SEARCH_WIDGET_ON_LEFT_SECTION_OF_EDITOR",[])




}

const EVENTS_ALLOWED_VIA_OPTIMIZELY = ["CapsuleAudioRecorded","capsuleCreated","SongSetWasPlayed","referralLinkCopiedToClipboard","SubscriptionPlayBackOrderChangesCancelled",
"CapsuleFeedPlayed","UserFeedWasPlayed","CategoryShortcastRadioPlayed","referralUrlSentViaSMS","UserManuallyAgreedToTurnOnNotification","playMyFeedWithNoSubscriptions","SubscriptionPlayBackOrderSaved",
"OnLanguageSelectionChanged","SignupCompleted","showCarouselView","UserDidNotAgreeToTurnOnNotification","CapsuleCreationPageOpened","SimplePlayerWasPlayed",
"OnActiveTrebbleChange","TrebbleWasPlayed","showListView","OnCategorySelectionChanged","referralURLSharedViaSocialMedia","CapsuleSetWasPlayed"];

const createABTest = function(testName, testVariationValues){
    const toReturn =  {"key": testName, "values" : {}};
    if(testVariationValues){
        for(let index in testVariationValues){
            const variationValue = testVariationValues[index];
            toReturn.values[variationValue] = variationValue;
        }
    }
    return toReturn;
}

const AB_TESTS = {

}


const FeatureRolloutHelper = Backbone.Model.extend({


    initialize: function() {
        try{
            if(window.optimizelySdk){
               this.optimizelyClientInstance = window.optimizelySdk.createInstance({
                datafile: window.optimizelyDatafile,
                logLevel: 3
            });
               this._isInitialized = false;
               this._isReady = this.optimizelyClientInstance.onReady({ timeout: 5000 }).then((function(result){
                if(result.success){
                    this._isInitialized = true;
                    return true;
                }else{
                    console.error("Failed lo feature flags. Error:"+result.reason);
                    console.error(result.reason);
                }
            }).bind(this));
           }else{
            console.error("Optimizely not initialized!");
        }

    }catch(error){
        console.error(error);
    }

},


isReady : function(){
    return this._isReady;
},

_getLoggedInUserId : function(){
    const userInfo =  LocalStorageHelper.getInstance().getUserInfo();
    const randomAnonymousUserID =  LocalStorageHelper.getInstance().getRandomUserIdForUser();
    return userInfo? userInfo._id: randomAnonymousUserID;
},

_getUserAttributes : function(){
    let userAttributes =  LocalStorageHelper.getInstance().getAllUserFeatures();
    if(!userAttributes){
        userAttributes = {};
    }
    userAttributes.userId = this._getLoggedInUserId();
    return userAttributes;
},

isFeatureEnabled : function(featureKey, defaultValue){
    try{
        if(featureKey && typeof(featureKey) === "object" && featureKey.key){
            featureKey = featureKey.key;
        }
        if(this.optimizelyClientInstance && this.optimizelyClientInstance.__isValidInstance()){
            if(featureKey){
                return this.optimizelyClientInstance.isFeatureEnabled(featureKey, this._getLoggedInUserId(), this._getUserAttributes());
            }else{
                console.error("Feature Key is missing!");
            }
        }else{
            return defaultValue;
        }
    }catch(error){
        console.error(error)
    }
    return defaultValue;
},

trackActivation : function(featureKey){
  try{
    if(featureKey && typeof(featureKey) === "object" && featureKey.key){
        featureKey = featureKey.key;
    }
    if(this.optimizelyClientInstance){
        if(featureKey){
            const featuresVariableMap = {};
            for(let variableKey in FEATURES[featureKey].variables){
                const variableValue = this.getFeatureVariable(featureKey, variableKey);
                if(variableValue){
                    featuresVariableMap[variableKey] = variableValue;
                }
            }
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("FeaturesActivated", featureKey, featureKey, null, featuresVariableMap);
            }
        }

    }else{
        console.error("Feature Key is missing!");
    }

}catch(error){
    console.error(error)
}

},

getFeatureVariable : function(featureKey, variableKey, defaultValue){
    let variableValue = null;
    try{
        if(featureKey && typeof(featureKey) === "object" && featureKey.key){
            featureKey = featureKey.key;
        }
        if(this.optimizelyClientInstance && this.optimizelyClientInstance.__isValidInstance()){
            if(featureKey && variableKey){
                variableValue = this.optimizelyClientInstance.getFeatureVariable(featureKey, variableKey, this._getLoggedInUserId(),this._getUserAttributes());
            }else{
                console.error("Missing parameter for retrieving Feature variable. featureKey="+featureKey+" variableKey="+variableKey);
            }
        }else{
            return defaultValue;
        }

    }catch(error){
        console.error(error)
    }
    if(variableValue || (variableValue !== undefined && variableValue !== null)){
        return variableValue;
    }else{
        return defaultValue;
    }
},

getFeaturesEnabled : function(){
    try{
        return this.optimizelyClientInstance.getEnabledFeatures(this._getLoggedInUserId(),this._getUserAttributes());
    }catch(error){
        console.error(error)
    }
    return null
},

getFeaturesEnabledMap : function(){
    const featuresMap = {};
    try{
        const features = this.optimizelyClientInstance.getEnabledFeatures(this._getLoggedInUserId(),this._getUserAttributes());
        for(let featureKey in FEATURES){
            const featureInfo = FEATURES[featureKey];
            if(featureInfo.shouldBeShared && features.indexOf(featureKey) != -1){
                featuresMap[features[featureKey]] = true;
            }
        }
    }catch(error){
        console.error(error)
    }
    return featuresMap;
},

activateABTest : function(ABTestName){
    try{
        if(this.optimizelyClientInstance){
            if(ABTestName){
                return this.optimizelyClientInstance.activate(ABTestName, this._getLoggedInUserId(),this._getUserAttributes());
            }else{
                console.error("A/B test name is missing!");
            }
        }
    }catch(error){
        console.error(error)
    }
    return null;
},

track : function(eventName){
    try{
        if(this.optimizelyClientInstance){
            if(eventName && EVENTS_ALLOWED_VIA_OPTIMIZELY.indexOf(eventName) != -1){
                return this.optimizelyClientInstance.track(eventName, this._getLoggedInUserId(), this._getUserAttributes());
            }else{
                if(!eventName){
                    console.error("Event name is missing!");
                }
            }
        }
    }catch(error){
        console.error(error)
    }

}



});




const FeatureRolloutHelperInstance = new FeatureRolloutHelper();
window.FeatureRolloutHelper = FeatureRolloutHelperInstance;
FeatureRolloutHelperInstance.FEATURES = FEATURES;
FeatureRolloutHelperInstance.AB_TESTS = AB_TESTS;

export default {
    getInstance: function() {
        return FeatureRolloutHelperInstance;
    },
    "FEATURES": FEATURES,
    "AB_TESTS": AB_TESTS
};