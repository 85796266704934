import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import FeaturePageView from "views/featurePage/FeaturePageView";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import RSVP from "rsvp";
import PersistentModels from "services/PersistentModels";

const FeaturePageController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this._onFeaturePageCloserHandler = options?options.onFeaturePageClosedHandler : null;

    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },
    
    showDefaultPage : function(){
        if(this._onFeaturePageCloserHandler){
            this._onFeaturePageCloserHandler();
        }else{
            PersistentModels.getInstance().getRouter().showDefaultPage(true);
        }

    },
    onFeaturePageRemoved : function(){
        LocalStorageHelper.getInstance().setFirstApplicationLaunchDone();
    },

    onViewRemoved:function(){
        this.stopListening();
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                this.view = new FeaturePageView();
                this.view.render();
    
                this.listenTo(this.view, "showDefaultPage", this.showDefaultPage);
                this.listenTo(this.view, "removedFromPage", this.onFeaturePageRemoved);
                this.listenTo(this.view, "remove", this.onViewRemoved.bind(this));
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default FeaturePageController;