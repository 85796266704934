import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SongsTabContentCollection from "collections/myLibrary/SongsTabContentCollection";
import PersistentModels from "services/PersistentModels";

//THIS SONG COLLECTION WILL USE EXISITING SONG MODEL IF THE SONG IS ALREADY PRESENT IN USER LIBRARY
var SongsCollection =  SongsTabContentCollection.extend({
    
    model: PersistentModels.getInstance().getSongContructorFunctionForCollection(),


    
});
export default SongsCollection;