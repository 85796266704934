import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import RSVP from "rsvp";

const DynamicContentView = Backbone.View.extend({
    

    
    initialize : function(options) {
        this._canBeAppendedBeforeLoad = (options && options.canBeAppendedBeforeLoad)? options.canBeAppendedBeforeLoad: false;
    },
    
    load : function(){
        return RSVP.Promise.resolve();
    },
    
    canBeAppendedBeforeLoad : function(){
        return this._canBeAppendedBeforeLoad;
    },

});

export default DynamicContentView;
