
import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import AudioFilter from "models/audioEditor/filters/AudioFilter";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import RSVP from "rsvp";

const PRESETS ={};

const PRESET_IDS = {
    "DEFAULT_DEESSER":"defaultDe-esser",
    "DEFAULT_MALE_VOICE_DEESSER":"defaultMaleVoiceDe-esser",
    "DEFAULT_MALE_FEMALE_DEESSER":"defaultFemaleVoiceDe-esser",
    "DEFAULT_DEESSER_AUDITION_CONFIG":"defaultDe-esserAuditionPodcastVoice"
};
const Rollout = RolloutHelper.getInstance();
const CACHING_AUDIO_NODE_ON_CHROME = Rollout.getFeatureVariable(Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR, Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR.variables.apply_chrome_workaround_by_resuing_audio_worklet_processor, true);
const USE_SCRIPT_PROCESSOR_IN_SAFARI = Rollout.getFeatureVariable(Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR, Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR.variables.apply_safari_workaround_for_audioworklet_incomplete_impl, true);

const PARAMS ={};
PARAMS.ATTACK ="/TrebbleDeesser/DesserBand/Attack (sec)";
PARAMS.RELEASE = "/TrebbleDeesser/DesserBand/Release (sec)";
PARAMS.RATIO ="/TrebbleDeesser/DesserBand/Ratio";
PARAMS.BYPASS = "/TrebbleDeesser/DesserBand/bypass";
PARAMS.THRESHOLD = "/TrebbleDeesser/DesserBand/Threshold (db)";
PARAMS.START_FREQUENCY = "/TrebbleDeesser/DesserBand/De-esser Start Frequency";
PARAMS.END_FREQUENCY = "/TrebbleDeesser/DesserBand/De-esser End Frequency";
PARAMS.FIRST_BAND_FREQUENCY_END = "/TrebbleDeesser/FirstBand/De-esser Start Frequency";
PARAMS.THIRD_BAND_FREQUENCY_START = "/TrebbleDeesser/ThirdBand/De-esser End Frequency";

const createDeesserConfigPreset = function(startFequency, endFrequency, threshold, ratio, attack, release, bypass){
    const presetParams = {};
    presetParams[PARAMS.START_FREQUENCY] = startFequency;
    presetParams[PARAMS.FIRST_BAND_FREQUENCY_END] = startFequency;
    presetParams[PARAMS.END_FREQUENCY] = endFrequency;
    presetParams[PARAMS.THIRD_BAND_FREQUENCY_START] = endFrequency;
    presetParams[PARAMS.THRESHOLD] = threshold;
    presetParams[PARAMS.RATIO] = ratio;
    presetParams[PARAMS.ATTACK] = attack;
    presetParams[PARAMS.RELEASE] = release;
    presetParams[PARAMS.BYPASS] = bypass;
    return presetParams;
};

PRESETS[PRESET_IDS.DEFAULT_DEESSER] = createDeesserConfigPreset(4000,8000, -23, 3.2, 0.005, 0.008, 0);
PRESETS[PRESET_IDS.DEFAULT_MALE_VOICE_DEESSER] = createDeesserConfigPreset(3000,6000, -23, 3.2, 0.005, 0.008, 0);
PRESETS[PRESET_IDS.DEFAULT_MALE_FEMALE_DEESSER] = createDeesserConfigPreset(6000,8000, -23, 3.2, 0.005, 0.008, 0);
PRESETS[PRESET_IDS.DEFAULT_DEESSER_AUDITION_CONFIG] = createDeesserConfigPreset(1649,4154, -17.6, 3.2, 0.005, 0.008, 0);

const CLASSNAME = "DeesserFilter";
const DeesserFilter =  AudioFilter.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        AudioFilter.apply(this, [attributes, options]);
        this.setPresetIdToConfigMap(PRESETS);
        this.set("type", DeesserFilter.CLASSNAME)
        
        
    },
    
    setAttack : function(attack){
        this.set(PARAMS.ATTACK,attack);
    },

    getAttack : function(){
        return this.get(PARAMS.ATTACK);
    },

    setThreshold : function(threshold){
        this.set(PARAMS.THRESHOLD,threshold);
    },

    getThreshold : function(){
        return this.get(PARAMS.THRESHOLD);
    },

    setRelease : function(release){
        this.set(PARAMS.RELEASE,release);
    },

    getRelease : function(){
        return this.get(PARAMS.RELEASE);
    },

    setRatio : function(ratio){
        this.set(PARAMS.RATIO,ratio);
    },

    getRatio : function(){
        return this.get(PARAMS.RATIO);
    },

    setBypass : function(bypass){
        this.set(PARAMS.BYPASS,bypass);
    },

    getBypass : function(){
        return this.get(PARAMS.BYPASS);
    },



    setStartFrequency : function(startFequency){
        this.set(PARAMS.START_FREQUENCY,startFequency);
        this.set(PARAMS.FIRST_BAND_FREQUENCY_END,startFequency);
    },

    getStartFrequency : function(){
        return this.get(PARAMS.START_FREQUENCY);
    },

    setEndFrequency : function(endFequency){
        this.set(PARAMS.END_FREQUENCY ,endFequency);
        this.set(PARAMS.THIRD_BAND_FREQUENCY_START ,endFequency);

    },

    getEndFrequency : function(){
        return this.get(PARAMS.END_FREQUENCY );
    },

    isEqual : function(filter){
        if(filter instanceof DeesserFilter){
            return (this.isDisabled() == filter.isDisabled() && this.getAttack() === filter.getAttack() && this.getThreshold() === filter.getThreshold() && this.getRelease() === filter.getRelease() && this.getRatio() === filter.getRatio() && this.getStartFrequency() === filter.getStartFrequency() && this.getEndFrequency() === filter.getEndFrequency()) 
        }
        return false;
    },

    clone : function(){
        const cloneFilter = new DeesserFilter();
        cloneFilter.setDisabled(this.isDisabled());
        cloneFilter.setThreshold(this.getThreshold());
        cloneFilter.setRelease(this.getRelease());
        cloneFilter.setRatio(this.getRatio());
        cloneFilter.setBypass(this.getBypass());
        cloneFilter.setStartFrequency(this.getStartFrequency());
        cloneFilter.setEndFrequency(this.getEndFrequency());

        return cloneFilter;
    },

    needToPrepareAudioContext: function(){
        return true;
    },

    prepareAudioContext: function(audioContext){
        return RSVP.Promise.resolve(audioContext);
    },

    _applyParameterOnNode:function(deesserNode,paramName){
        deesserNode.setParamValue(paramName,this.get(paramName));
    },

    applyFilterToNode : function(audioNode, audioContext, arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime,  sequencerRendererTracksInfo, createdFilterNode){
        if(!this.isDisabled()){
            
            // Create a compressor node
            const deesserNode = createdFilterNode;
            this._applyParameterOnNode(deesserNode, PARAMS.START_FREQUENCY);
            this._applyParameterOnNode(deesserNode, PARAMS.END_FREQUENCY);
            this._applyParameterOnNode(deesserNode, PARAMS.FIRST_BAND_FREQUENCY_END);
            this._applyParameterOnNode(deesserNode, PARAMS.THIRD_BAND_FREQUENCY_START);
            this._applyParameterOnNode(deesserNode, PARAMS.RATIO);
            this._applyParameterOnNode(deesserNode, PARAMS.ATTACK);
            this._applyParameterOnNode(deesserNode, PARAMS.RELEASE);
            this._applyParameterOnNode(deesserNode, PARAMS.THRESHOLD);
            this._applyParameterOnNode(deesserNode, PARAMS.BYPASS);

            audioNode.connect(deesserNode);

            if(arrayOfAudioNodesUsed){

                arrayOfAudioNodesUsed.push(deesserNode);
            }
            return deesserNode;

        }else{
            return audioNode;
        }
    },

    _createDeesserNode : function(audioContext){
        return new RSVP.Promise(function(resolve, reject){
            require(["libs/deesser/TrebbleDeesser", "libs/deesser/TrebbleDeesserScriptProcessor"],function({TrebbleDeesser}, {TrebbleDeesserScriptProcessor}){
                if((window.isSafariBrowser || window.isIOSMobileDevice) && USE_SCRIPT_PROCESSOR_IN_SAFARI){
                    return TrebbleDeesserScriptProcessor.createDSP(audioContext, 1024 ,"js/libs/deesser").then(resolve).catch(reject);
                }else{
                    const TrebbleDeesserFactory = new TrebbleDeesser(audioContext,"js/libs/deesser");
                    if(window.chrome && CACHING_AUDIO_NODE_ON_CHROME && audioContext._lastDeesserNodeCreated && !audioContext._lastDeesserNodeCreated.isConnected){
                        return resolve(audioContext._lastDeesserNodeCreated);
                    }
                    return TrebbleDeesserFactory.load().then(resolve).catch(reject);	
            }
            })

        })
    },

    applyFilter : function(previousOutputNode, audioContext, arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime, sequencerRendererTracksInfo, audioBuffer){
        if(!this.isDisabled()){
            if(!previousOutputNode){
                previousOutputNode = audioContext.createBufferSource();
                previousOutputNode.buffer = audioBuffer;
                if(arrayOfAudioNodesUsed){
                    arrayOfAudioNodesUsed.push(previousOutputNode);
                }
            }
            return this._createDeesserNode(audioContext).then((function(deesserNode){
                if(window.chrome && CACHING_AUDIO_NODE_ON_CHROME && deesserNode){
                    audioContext._lastDeesserNodeCreated = deesserNode;
                    deesserNode.isConnected = true;
                }
                return this.applyFilterToNode(previousOutputNode, audioContext,arrayOfAudioNodesUsed, audioBufferStartTime,audioBufferEndTime, sequencerRendererTracksInfo, deesserNode);
            }).bind(this));

            
        }else{
            return RSVP.Promise.resolve(previousOutputNode);
        }
    },


});

DeesserFilter.PRESET_IDS = PRESET_IDS;
DeesserFilter.PRESETS = PRESETS;
DeesserFilter.CLASSNAME =  CLASSNAME;

export default DeesserFilter; 