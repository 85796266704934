import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import ti18n from "i18n!nls/ConfigureBackgroundMusici18n";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import Utils from "models/helper/Utils";
import AllMyCapsuleContentTemplate from 'text!../../../templates/capsule/AllMyCapsuleContentTemplate.html';

const Rollout = RolloutHelper.getInstance();
const AllMyCapsuleWidgetView = Backbone.View.extend({

    initialize : function(options) {
        this.contentViewTemplate = _.template(AllMyCapsuleContentTemplate);
        this.capsuleListView = options.capsuleListView;
        this._categoryListItemCollectionToDisplay = null;
        this._selectedCategortButton$el = null;              
    },

    setCategoryIdToCategorylabelMapAvailable : function(categoryIdToCategoryLabelMap){
        this.category_fld$el.html("");
        const categoryLabelsArray = [];
        const categoryLabelToCategoryId = {}
        for(let categoryId in categoryIdToCategoryLabelMap){
            const categoryLabel = categoryIdToCategoryLabelMap[categoryId];
            categoryLabelsArray.push(categoryLabel);
            categoryLabelToCategoryId[categoryLabel]= categoryId;
        }
        categoryLabelsArray.sort();
        for(let i =0; i < categoryLabelsArray.length; i++){
            const categoryLabel = categoryLabelsArray[i];
            const categoryId = categoryLabelToCategoryId[categoryLabel];
            const categoryTagButton$el = this._getHtmlForCategory(categoryId, categoryLabel)
            this.tagButtonListWrapper$el.append(categoryTagButton$el)
            this.category_fld$el.append("<option value='"+categoryId+"'    >"+categoryLabel+"</option>");
        }
    },

    _setCategory$elAsSelected : function(list_tag_button$el){
        if(this._selectedCategortButton$el){
            this._selectedCategortButton$el.removeClass("selected");
        }
        this._selectedCategortButton$el = list_tag_button$el;
        this._selectedCategortButton$el.addClass("selected");

    },

    showFirstCategory : function(){
        this.showSelectedCategory(this.$el.find(".list_tag_button:first-child"));
    },

    showSelectedCategory : function(categoryListTagButton$el ){
       this._setCategory$elAsSelected(categoryListTagButton$el);
   },

   _getHtmlForCategory : function(categoryId, categoryLabel){
    const categor$el = $("<div class='list_tag_button' ><div class='itemLabel'>"+categoryLabel+"</div></div>");
    categor$el.on("click", (function(){
        this.trigger("categorySelected", categoryId);
        this.showSelectedCategory(categor$el, false);
    }).bind(this));
    return categor$el;
},


isLoading : function(isLoading){
    this.$el.find(".capsule_list_content_wrapper").attr("isLoading",isLoading)
},

_onCategorySelectionChangedOnSelectField : function(){
    const categoryId =  this.$el.find("#category_fld option:selected").val();
    Utils.getInstance().setJQMSelectFieldValue(this.category_fld$el, categoryId);
    this.trigger("categorySelected", categoryId);    
},

hideCategoryList : function(){
    this.$el.addClass("hide_category_list");
},

afterRendering : function() {
    this.capsuleListContainer$el = this.$el.find(".capsule_list");
    this.tagButtonListWrapper$el = this.$el.find(".tag_button_list_wrapper");
    this.capsuleListContainer$el.append(this.capsuleListView.$el);
    this.category_fld$el = this.$el.find("#category_fld");
    this.category_fld$el.on("change", this._onCategorySelectionChangedOnSelectField.bind(this));
},


render : function(){
  const  templateParams = {};
  templateParams.ti18n =  ti18n;
  templateParams.Rollout = Rollout;
  this.$el.html(this.contentViewTemplate(templateParams));
  this.setElement(this.$el.find(".capsule_list_content_wrapper").first());
  this.afterRendering();
  return this;

},



});

export default AllMyCapsuleWidgetView;
