import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import PersistentModels from "services/PersistentModels";
import BasicInspectorNodeParamView from "views/audioEditor/sequencerNodeInspectorSection/paramView/BasicInspectorNodeParamView";
import AudioSourceParamTemplate from 'text!../../../../../templates/audioEditor/sequencerNodeInspectorSection/paramView/AudioSourceParamTemplate.html';
import ti18n from 'i18n!nls/Sequenceri18n';
import RSVP from 'rsvp';


const AudioSourceParamView = BasicInspectorNodeParamView.extend({

    constructor : function(attributes, options) {
        BasicInspectorNodeParamView.apply(this, [attributes, options]);
        this.compiledTemlate =  _.template(AudioSourceParamTemplate);
        this._disableAudioSourceChange = attributes.disableAudioSourceChange
        this._onChangeAudioFileOperationRequested = attributes.onChangeAudioFileOperationRequested;
        this._getDefaultTrimSectionValuesFunction = attributes.getDefaultTrimSectionValuesFunction;
        this._sequencerSettings = attributes.sequencerSettings;
        this._audioType = attributes.audioType;
    },	

    events: {
        "click #changeTrimBtn:not(.disabled)":"_onTrimButtonClicked",
        "click #resetTrimBtn:not(.disabled)":"_onResetTrimSectionButtonClicked",
        "click .edit_btn":"_onEditButtonClicked",
    },	

    isDisabled: function(){
        return this._disabled;
    },

    setDisabled : function(disabled){
        this._disabled = disabled;
        if(!this._disabled){
            this.startTime$el.removeAttr("disabled");
            this.endTime$el.removeAttr("disabled");
            this.changeTrimBtn$el.removeAttr("disabled");
            this.audioSourceName$el.removeAttr("disabled");
        }else{
            this.startTime$el.attr("disabled", !!disabled);
            this.endTime$el.attr("disabled", !!disabled);
            this.changeTrimBtn$el.attr("disabled", !!disabled);
            this.audioSourceName$el.attr("disabled", !!disabled);
        }
        
    },

    getValueString: function(){
        const audioSegmentJson = this.getValue();
        if(audioSegmentJson.endTime){
            const startTimeInSec = audioSegmentJson.startTime/1000;
            const endTimeInSec = audioSegmentJson.endTime/1000;
            return Utils.getInstance().formatPlaybackPositionToString(startTimeInSec, true) + " "+ Utils.getInstance().formatPlaybackPositionToString(endTimeInSec, true);
        }else{
            return "--:---";
        }
    },

    _getAudioSourceFileName : function(value){
        const trebbleAudio = value.trebbleAudio;
        if(trebbleAudio){
            return Utils.getInstance().stripHTMLTags(trebbleAudio.getTitle()?trebbleAudio.getTitle(): Utils.getInstance().getFilenameWithExtension(trebbleAudio.getAudioUrl()));
        }
        return "";
    },

    
    updateViewOnValueChange : function(value){
        if(this.audioSourceName$el){
            this.audioSourceName$el.html(this._getAudioSourceFileName(value));
        }
        if(this.startTime$el && this.endTime$el){
            if(value.endTime){
                const startTimeInSec = value.startTime/1000;
                const endTimeInSec = value.endTime/1000;
                this.startTime$el.html(Utils.getInstance().formatPlaybackPositionToString(startTimeInSec, true));
                this.endTime$el.html(Utils.getInstance().formatPlaybackPositionToString(endTimeInSec, true));
                this.changeTrimBtn$el.find("label").html(window.getI18n(ti18n,"ADJUST_TRIM"));
            //return Utils.getInstance().formatPlaybackPositionToString(startTimeInSec, true) + " - "+ Utils.getInstance().formatPlaybackPositionToString(endTimeInSec, true);
        }else{
            this.startTime$el.html("--:---");
            this.endTime$el.html("--:---");
            this.changeTrimBtn$el.find("label").html(window.getI18n(ti18n,"TRIM"));
        }
    }
},

_onTrimButtonClicked : function(){
    if(!this._disabled){
        const onAudioSegmentRegionChanged = (function(newStartTime, newEndTime){
            const newValue = {"startTime": newStartTime, "endTime": newEndTime, "trebbleAudio": this.getValue().trebbleAudio};
            this.updateViewOnValueChange(newValue);
            this.onViewValueChanged(newValue);
        }).bind(this)

        const onAudioSegmentRegionNotChanged = (function(){

        }).bind(this)
        const params = {"startTime": this.getValue().startTime, "endTime": this.getValue().endTime, "audioUrl": this.getValue().trebbleAudio.getAudioUrl()};
        PersistentModels.getInstance().getRouter().showSelectAudioRegion(params, onAudioSegmentRegionChanged, onAudioSegmentRegionNotChanged, this.getValue().trebbleAudio.getAudioUrl(), this._audioType , this._sequencerSettings)
    }
},

_onEditButtonClicked : function(){
    if(!this._disabled && this._onChangeAudioFileOperationRequested){
        const onAudioFileUpdateSuccessfullyChanged = (function(selectedTrebbleAudio){
            const newValue = {"startTime": null, "endTime": null, "trebbleAudio": selectedTrebbleAudio};
            this.updateViewOnValueChange(newValue);
            this.onViewValueChanged(newValue);
        }).bind(this)

        const onAudioFileUpdateCancelled = (function(){

        }).bind(this)
        this._onChangeAudioFileOperationRequested(this.getValue(), onAudioFileUpdateSuccessfullyChanged, onAudioFileUpdateCancelled);
    }
},

_onResetTrimSectionButtonClicked : function(){
        if(!this._disabled && this._getDefaultTrimSectionValuesFunction){
            const getDefaultTrimSectionValuesFunctionCallback = (function(newStartTime, newEndTime){
                const newValue = {"startTime": newStartTime, "endTime": newEndTime, "trebbleAudio": this.getValue().trebbleAudio};
                this.updateViewOnValueChange(newValue);
                this.onViewValueChanged(newValue);
            }).bind(this)

            this._getDefaultTrimSectionValuesFunction(getDefaultTrimSectionValuesFunctionCallback);
        }
    },


render : function(){
    this.$el.html(this.compiledTemlate({ "viewObj": this, "ti18n": ti18n, "disableAudioSourceChange": this._disableAudioSourceChange, "disableResetTrim": !this._getDefaultTrimSectionValuesFunction}));
    this.setElement(this.$el.find(".basic_inpector_param_view").first());
    this.startTime$el = this.$el.find(".start_info .start_time");
    this.endTime$el = this.$el.find(".end_info .end_time");
    this.changeTrimBtn$el = this.$el.find("#changeTrimBtn");
    this.audioSourceName$el = this.$el.find(".audio_source_string_value");
    this.updateViewOnValueChange(this.getValue());
}
});
export default AudioSourceParamView;