import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import SliderInputParamView from "views/audioEditor/sequencerNodeInspectorSection/paramView/SliderInputParamView";
import RSVP from 'rsvp';


const DurationInSecondsWithSliderParamView = SliderInputParamView.extend({

    constructor : function(attributes, options) {
        if(!attributes){
            attributes = {};
        }
        attributes.getDisplayedLabelFromValueFunction = this._getDisplayedLabelFromValue.bind(this);
        attributes.getValueFromDisplayedLabelFunction = this._getValueFromDisplayedLabel.bind(this);
        SliderInputParamView.apply(this, [attributes, options]);

    },		
    _getDisplayedLabelFromValue : function(value){
        return value +"s";
    },

    _getValueFromDisplayedLabel : function(displayedLabel){
        return displayedLabel? parseFloat(displayedLabel.substr(0 ,displayedLabel.length -1)): 0;
    },	

    setMaxValueInSeconds : function(maxValueInSeconds){
        this.setMaxValue(maxValueInSeconds);
    },

    getMaxValueInSeconds : function(){
        return this.getMaxValue();
    },

    setMinValueInSeconds : function(minValueInSeconds){
        this.setMinValue(minValueInSeconds);
    },

    getMinValueInSeconds : function(){
        return this.getMinValue();
    },

});
export default DurationInSecondsWithSliderParamView;