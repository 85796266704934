import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import Utils from "models/helper/Utils";
import Artists from "collections/myLibrary/ArtistsTabContentCollection";
import ArtistListView from "views/common/ArtistListView";
import ArtistListView2 from "views/common/ArtistListView2";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";

const ArtistListController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if(options)
        {
            if(options.model)
            {
                if(Utils.getInstance().is("Array",options.model))
                {
                    const artistJsonArray = options.model;
                    this.model = new Artists(artistJsonArray);
                }else{
                        this.model = options.model;
                }
                
            }
            
            if(options.resizePlaceHolderElem)
            {
                this.resizePlaceHolderElem = options.resizePlaceHolderElem;
            }else{
                this.resizePlaceHolderElem = false;
            }
            if(options.showEmtptyInfoBox){
                this._showEmtptyInfoBox = options.showEmtptyInfoBox;
            }
            if(options.loadHandler)
            {
                this._loadHandler = options.loadHandler;
            }
            if(options.context){
                if(this.model instanceof Artists){
                    this.model.updateContext(options.context);
                }
                this._modelContext = options.context;
            }
            if(options.customInfoBoxInfoParams){
                this._customInfoBoxInfoParams = options.customInfoBoxInfoParams;
            }
            if(options.container$elForImageLazyLoad){
                this._container$elForImageLazyLoad = options.container$elForImageLazyLoad;
            }
            
            this._doNotSortDisplayedCollection = options.doNotSortDisplayedCollection;
            if(options.sortDescending){
                this._sortDescending = options.sortDescending;
            }
            if(options.sortField ){
                this._sortField = options.sortField;
                if(this.model){
                    this.model.sortByField(this._sortField, this._sortDescending);
                }
            }
            if(options.actionExecutedOnArtistHandler){
                this._actionExecutedOnArtistHandler = options.actionExecutedOnArtistHandler;
            }
            if(options.container$elForImageLazyLoad){
                this._container$elForImageLazyLoad = options.container$elForImageLazyLoad;
            }
            if(options.doNotInitializeLazyLoadingAtRender){
                this._doNotInitializeLazyLoadingAtRender = options.doNotInitializeLazyLoadingAtRender;
            }
            if(options.showArtistProfileImage){
                this._showArtistProfileImage = options.showArtistProfileImage;
            }
            if(options.heightMargin){
                this._heightMargin = options.heightMargin;
            }
            this._showArtistProfileImage = true;
            
        }
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },
    
    showArtistProfilePage : function(artistModel){
        PersistentModels.getInstance().getRouter().showArtistDetailPage(artistModel.get("artistId"), artistModel, true);
    },
    
    showArtistFullProfilePage : function(artistModel){
        PersistentModels.getInstance().getRouter().showArtistFullDetailPage(artistModel.get("artistId"),artistModel.get("artistName"), artistModel, true);
    },
    
    onArtistActionButtonTapped : function(artistModel){
        PersistentModels.getInstance().getRouter().showArtistOptionPopup(artistModel);
    },
    
    
    updateListOfArtists : function(arrayOfArtistJson){
        this.model.reset(arrayOfArtistJson/*, {silent: true}*/);
    },
    
    
     updateInfoBoxIfApplicable : function(iconClass,message,helpMessage,infoBoxClassName,actionButtonLabel,actionButtonIconClass){
            if(this.view){
                this.view.update(iconClass,message,helpMessage,infoBoxClassName,actionButtonLabel,actionButtonIconClass);
            }
    },
    
    reload : function(){
        if(this.view && this.view.load){
            return this.view.load();
        }else{
            return  RSVP.Promise.resolve();
        }
    },
    

    initializeImageLazyLoadPlugin : function(container$elForImageLazyLoad){
        this.infiniteListView.initializeImageLazyLoadPlugin(container$elForImageLazyLoad);
    },
    
    cancelPendingImageLoading : function(){
        this.view.cancelPendingImageLoading();
    },
    
    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                if(!this.model)
                {
                    this.model = new Artists([]);
                    if(this._modelContext){
                        this.model.updateContext(this._modelContext);
                    }
                }
                this.model.sortByField(this._sortField, this._sortDescending);
                if(this._showArtistProfileImage){
                    this.view = new ArtistListView2({
                        "collection" : this.model,
                        "delayOnCollectionUpdate" : 1000,
                        "resizeToAvailableScreenSizeOnOrientationChange" : false,
                        "resizePlaceHolderElem" : this.resizePlaceHolderElem,
                        "loadHandler" : this._loadHandler,
                        "showEmtptyInfoBox" :this._showEmtptyInfoBox ,
                        "customInfoBoxInfoParams": this._customInfoBoxInfoParams,
                        "heightMargin": this._heightMargin,
                        "container$elForImageLazyLoad": this._container$elForImageLazyLoad,
                        "doNotInitializeLazyLoadingAtRender" : this._doNotInitializeLazyLoadingAtRender,
                    });
                    this.listenTo(this.view, "artistInfoTapped",this.showArtistFullProfilePage);
                    this.listenTo(this.view, "artistActionButtonTapped",this.onArtistActionButtonTapped);
                }else{
                    this.view = new ArtistListView({
                        "collection" : this.model,
                        "delayOnCollectionUpdate" : 1000,
                        "resizeToAvailableScreenSizeOnOrientationChange" : false,
                        "resizePlaceHolderElem" : this.resizePlaceHolderElem,
                        "loadHandler" : this._loadHandler,
                        "heightMargin": this._heightMargin,
                    });
                    this.listenTo(this.view, "itemTaped",this.showArtistProfilePage);
                    this.listenTo(this.view, "itemOptionBtnTapped",this.onArtistActionButtonTapped);
                }
                this.view.render();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default ArtistListController;