import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlaylistHelper from "models/helper/PlaylistHelper";
import Utils from "models/helper/Utils";
import PersistentModels from "services/PersistentModels";
import PlayerService from "services/PlayerService";
import RSVP from "rsvp";
import PlaylistDetailsController from 'controllers/common/PlaylistDetailsController';
import ti18n from "i18n!nls/PlaylistPodcasti18n";
import PageTemplate from 'text!../../../templates/playlistPodcasts/RemoteRadioDetails.html';
import Hammer from 'hammerjs';

const FollowerPersonalRadioDetailsController = PlaylistDetailsController.extend({


    initialize : function(options) {
        options.getAllSongsInPlaylistHandler  = this.loadPlaylistDetails.bind(this);
        options.pageTemplate = PageTemplate;
        const eventHandlerMap = {};
        this._detailedPlaylistInfoRetrieved = false;
        eventHandlerMap["click #startRadioBtn"] = this.onStartRadioButtonTapped.bind(this);
        eventHandlerMap["click div[data-role=header] #menuOptionButton"] = this.showOptionPopup.bind(this);
        eventHandlerMap["click div[data-role=header] #editButton"] = this.showEditPage.bind(this);
        eventHandlerMap["click #addSongsBtn"] = this.showGlobalSearch.bind(this);	
        eventHandlerMap["click #shareTrebbleBtn"] = this.showShareTrebblePopup.bind(this);
        options.eventHandlerMap = eventHandlerMap;
        options.customInfoBoxInfoParams = this._buildCustomInfoBoxParams();
        options.pageTemplateOptions = {"persistentPage": false,"ti18n": ti18n};
        options.context = Utils.getInstance().buildContextForSongCollectionUserRadio(options.playlistSummary.get("id"));
        return PlaylistDetailsController.prototype.initialize.call(this, options);

    },
    
    _buildCustomInfoBoxParams  : function(){
        const params = {};
        params.iconClass = "ion-ios7-musical-notes";
        params.message = window.getI18n(ti18n, "NO_SONG");
        params.helpMessage = window.getI18n(ti18n, "THIS_TREBBLE_HAS_NO_SONG_YET");
        return params;
    },

    showOptionPopup : function(){
        PersistentModels.getInstance().getRouter().showMyPersonalRadioMenuOption(this._playlist, true);
    },

    afterPageRendered : function(){
        const editButton$El = this.view.$el.find("#playPreviewBtn");
        const editButtonEl = editButton$El.get(0);
        if(editButtonEl){
            Hammer(editButtonEl, {drag_lock_to_axis: true}).on("press", this.onPreviewButtonPressed.bind(this), false);
            Hammer(editButtonEl, {drag_lock_to_axis: true}).on("pressUp", this.onPreviewButtonPressedUp.bind(this), false);
            //editButton$El.on("touchend",this.onPreviewButtonPressedUp.bind(this));
            //editButton$El.on("mouseover",this.onPreviewButtonPressed.bind(this));
            //editButton$El.on("mouseup",this.onPreviewButtonPressedUp.bind(this));
            editButton$El.hoverIntent(this.onPreviewButtonPressed.bind(this),this.onPreviewButtonPressedUp.bind(this));
        }
    },

    onPreviewButtonPressed : function(){
        this._previewButtonPressed = true;
        this.playPreview();
    },

    onPreviewButtonPressedUp : function(){
        if(this._previewButtonPressed){
            this._previewButtonPressed = false;
            this.stopPreview();
        }
    },

    showEditPage : function(){
        PersistentModels.getInstance().getRouter().showTrebbleInfoEditPage(this._playlist, true);
    },

    playPreview : function(){
        PersistentModels.getInstance().getPlayer().playAudioPreview(this._playlist.getAudioPreviewUrl());
    },

    stopPreview : function(){
        PersistentModels.getInstance().getPlayer().terminateAudioPreviewAndResumePlayback();
    },

    showGlobalSearch : function(){
        PersistentModels.getInstance().getRouter().showGlobalSearchPage(null, true);
    },

    showShareTrebblePopup : function(){
        return PersistentModels.getInstance().getRouter().showTrebbleSharePopup(this._playlist, null);
    },

    onStartRadioButtonTapped : function(){
        if(PlayerService.getInstance().isTrebblePlayable(this._playlist) || (window._capsuleCreatedInThisSession && this._playlist.canBeEdited())){//The second clause here is more of a around where if the user created a capsule in the current user session, we will try to play his trebble anyway. We are simply doing this because the numberof capsules on Trebble is not updated until the user refreshes. this is a bug
            $.mobile.loading( 'show');

            this._retrieveDetailedPlaylistInfo().then((function(){
                return PersistentModels.getInstance().getPlayer().setPlaylist(this._playlist, true);
            }).bind(this)).then((function(){
                $.mobile.loading( 'hide');
                /*if(this._playlist){
                    const loaderKey = this._playlist.getLoaderKey();
                    const name = this._playlist.getName();
                    const context = Utils.getInstance().buildContextForFollowingRadioDetail(loaderKey);
                    TrebbleClientAPIHelper.getInstance().addTebbleLoadActionToMyHistory(loaderKey,name ,new Date(), null,context, null);
                }*/
            }).bind(this)).catch((function(error){
                $.mobile.loading( 'hide');
                if(PersistentModels.getInstance().getPlayer().isNoContentToPlayError(error)){
                    this._onPlaylistNotBeingAbleToPlay();
                    PersistentModels.getInstance().getPlayer().clearQueue();
                }else{
                    console.error("Coudn't load playlist.Error:"+ error);
                    window.alertErrorMessage("Coudn't load playlist.Error:"+ error);
                }
            }).bind(this));
        }else{
            this._onPlaylistNotBeingAbleToPlay();
            PersistentModels.getInstance().getPlayer().clearQueue();
        }
    },

    _onPlaylistNotBeingAbleToPlay : function(){
        if(this._playlist.canBeEdited()){
            const buttonLabels = [window.getI18n(ti18n, "OK"), window.getI18n(ti18n, "CREATE_CAPSULE")];
            const confirmCallback = (function(buttonIndex){
                if(buttonIndex ==  2){
                    setTimeout((function(){
                        PersistentModels.getInstance().getRouter().showCreateACapsulePageWithoutContext(null);
                    }).bind(this), 100);

                }else{

                }
            }).bind(this);
            const minimumOfSongsNeeded  =  Utils.getInstance().getDefaultNumberOfSongsRequiredToPlayATrebble();
            
            const message = window.getI18n(ti18n,"TREBBLE_HAS_NO_CONTENT");
            const subMessage = window.getI18n(ti18n,"YOU_CAN_ADD_CONTENT_BY_CREATING_CAPSULES");
            navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n,"NEED_TO_HAVE_MINIMUM_OF_SONGS"), buttonLabels, null, null, subMessage);
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("onboarding", "Discover Mininum Song Number For Trebble", "Discover Mininum Song Number For Trebble");
            } 
        }else{
            navigator.trebbleNotification.alert(window.getI18n(ti18n,"THIS_TREBBLE_HAS_NO_ACTIVE_CAPSULE"), null, false);
        }
    },


    _retrieveDetailedPlaylistInfo : function(){
        //	if(!this._playlist){
            if(this._playlistRetrievalPendingPromise){
                return this._playlistRetrievalPendingPromise;
            }else{
                const p = PlaylistHelper.getInstance().getPersonalUserRadioPlaylistById(this.model).then((function(playlist){
                    this._detailedPlaylistInfoRetrieved = true; 
                    this._playlistRetrievalPendingPromise = null;
                    this._playlist = playlist;
                    return this.playlist;
                }).bind(this));
                this._playlistRetrievalPendingPromise =  p;
                return this._playlistRetrievalPendingPromise;
            }
            /*}else{
                return RSVP.Promise.resolve(this._playlist);
            }*/
        },

        loadPlaylistDetails : function(){
            return this._retrieveDetailedPlaylistInfo().then((function(){
                if(this._playlist){
                    if(this._playlist.load){
                        return this._playlist.retrieveAllAvailableSongsInJson();
                    }else{
                        return this._playlist.toJSON();
                    }
                }else{
                    return [];
                }

            }).bind(this)).catch((function(error){
                console.error(window.getI18n(ti18n,"COULDNT_FIND_ANY_PLAYLIST_BY_ID") ,error);
                window.alertErrorMessage(window.getI18n(ti18n,"COULDNT_FIND_ANY_PLAYLIST_BY_ID") + " '"+this._playlist.getLoaderKey() + "'. "+ window.getI18n(ti18n,"ERROR") +error);
            }).bind(this));

        },



    });
export default FollowerPersonalRadioDetailsController;