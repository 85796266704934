import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import RSVP from "rsvp";
import ti18n from "i18n!nls/helperi18n";

//"models/helper/FeatureRolloutHelper",
/*"controllers/login/LoginControllerNew", */
import Utils from "models/helper/Utils";

import LocalStorageHelper from "models/helper/LocalStorageHelper";

const httpOrHttpsPrefix = "https://";

//const Rollout = FeatureRolloutHelper.getInstance();
const playTrebbleServerUrl = "mysterious-fortress-7412.herokuapp.com";
const trebbleServerUrl = "s.trebble.fm";
//const trebbleServerUrl = "localhost:8888"; //maison
//const trebbleServerUrl = "33a75c9e.ngrok.io";
//const trebbleServerUrl = "a107-2607-fa49-113f-3100-7c51-39c4-e198-ce2c.ngrok-free.app";//travail
//const trebbleServerUrl = Utils.getInstance().getTestServerEndpoint();
//const trebbleServerUrl = "192.168.1.14:8888"; //
//const trebbleServerUrl = "192.168.1.16:8888";//Douala
const isEmbeddedInIframe = window.top !== window.self;
const isEmbedded = isEmbeddedInIframe || $("body").hasClass("embedded");

const ENABLE_BACKEND_CALL_ANAYLYTICS_TRACKING = false;
const TrebbleClientAPIModel = Backbone.Model.extend({
  default: {},

  initialize: function () {
    this._loginSuccessfull = false;
    this._loginDone = false;
    this._loginSuccessfullWithToken = false;
    this._loginDoneWithToken = false;
    this.username = null;
    this.password = null;
  },

  logOutExecute: function (doNotReloadPage) {
    LocalStorageHelper.getInstance().logoutUserAndClearInfo();
    if (navigator.splashscreen) {
      navigator.splashscreen.show();
    }

    window.clearUserIdForTracking();
    window.trebble.helper.requirePromise(["models/helper/FacebookHelper"]).then(
      function (FacebookHelper) {
        window.trebble.helper.requirePromise(["models/helper/GooglePlusHelper"]).then(
          function (GooglePlusHelper) {
            if (window.waitForCordovaToLoad) {
              FacebookHelper.getInstance().logout();
              GooglePlusHelper.getInstance().logout();
            }
            if (!doNotReloadPage) {
              document.body.style.display = "none";
              if (window.device && window.device.platform == "browser") {
                const currentUrl = document.location.href;
                if (currentUrl.indexOf("https://") == 0) {
                  document.location.href = currentUrl.substr(0, currentUrl.indexOf("/", "https://".length));
                } else {
                  if (currentUrl.indexOf("http://") == 0) {
                    document.location.href = currentUrl.substr(0, currentUrl.indexOf("/", "http://".length));
                  } else {
                    if (window.waitForCordovaToLoad) {
                      window.location = window.location.href.substr(0, window.location.href.indexOf("#")); //remove hash
                    } else {
                      document.location.href = "https://web.trebble.fm";
                    }
                  }
                }
              } else {
                document.location.href = "trebbleLibrary140.html";
                document.location.reload();
              }
            }
          }.bind(this),
        );
      }.bind(this),
    );
  },

  _getDefaultResponseHandler: function (response, resolve, reject, callback, operationName) {
    if (response && response.success) {
      window.log(operationName + " was made successfully to server");
      resolve(response.message);
      if (callback) {
        callback.onSuccess(response.message);
      }
    } else {
      if (response && response.errorMessage) {
        if (response.errorMessage.indexOf(window.getI18n(ti18n, "USER_NOT_FOUND")) != -1) {
          this.logOutExecute(true);
        }
        console.error(response.errorMessage);
      } else {
        console.error("Something went wrong when executing the  operation '" + operationName);
      }
      reject(response.errorMessage);
      if (callback) {
        callback.onFailure(response.errorMessage);
      }
    }
  },

  _getDefaultFailedResponseHandler: function (error, reject, callback, operationName) {
    window.log(
      "Error response from server for operation '" +
        operationName +
        "' .Details :" +
        JSON.stringify(error.responseText),
    );
    if (error.status == 401 || error.status == "401") {
      this.logOutExecute(true);
      reject(window.getI18n(ti18n, "UNAUTHORIZED_TRANSACTION"));
    } else {
      if (error.responseJSON && error.responseJSON.error && error.responseJSON.error.message) {
        reject(error.responseJSON.error.message);
      } else {
        if (error.responseText) {
          reject(JSON.stringify(error.responseText));
        } else {
          console.error("Something went wrong when executing the  operation '" + operationName);
          reject(window.getI18n(ti18n, "SOMETHING_WENT_WRONG"));
        }
      }
    }
    if (callback) {
      callback.onFailure(error.responseText);
    }
  },

  /*_doLoginWithoutToken : function() {
        const username = LocalStorageHelper.getInstance().retrieveUsername() || (device.platform+ device.uuid) ;
        const password = LocalStorageHelper.getInstance().retrieveUserPassword() || (device.uuid);
        const isUsingUIDForLogin =  LocalStorageHelper.getInstance().retrieveUsername() ? false : true;
        return this.doLoginWithoutToken(username, password);
    },*/

  _doLoginInPopup: function (forceLogin) {
    if (!forceLogin && Utils.getInstance().isTokenValid() && LocalStorageHelper.getInstance().isUsingNewLoginModule()) {
      const tokenInfo = LocalStorageHelper.getInstance().retrieveAuthentificationToken();
      $.ajaxSetup({
        headers: {
          Authorization: "Bearer " + tokenInfo.token,
        },
      });
      return RSVP.Promise.resolve();
    } else {
      return new RSVP.Promise((resolve, reject) => {
        try {
          const uniquePopupLoginPageHash = window.trebble.popupLoginPageHash + "_" + new Date().getTime();
          const completeLoginPopupUrl =
            window.location.protocol == "http:" || window.location.protocol == "https:"
              ? location.origin + window.getAppBaseUrl() + "#" + uniquePopupLoginPageHash
              : window.getBaseUrlWithoutEndSlash() + "#" + uniquePopupLoginPageHash;
          const w = 500;
          const h = 700;
          const left = window.getLeftPositionForPopupWindow(w);
          const top = window.getTopPositionForPopupWindow(h);
          const openedWindow = window.open(
            completeLoginPopupUrl,
            "_blank",
            "toolbar=no, scrollbars=yes, resizable=yes, width=" +
              w +
              ", height=" +
              h +
              " top=" +
              top +
              " left=" +
              left +
              "",
          );

          if (!(openedWindow && !openedWindow.closed && !window.waitForCordovaToLoad)) {
            const onMessageReceivedFunc = (event) => {
              if (event.source == openedWindow) {
                const receivedMessageJson = this.buildJsonFromBrowserMessageString(event.data);

                if (receivedMessageJson.success) {
                  const tokenInfo = receivedMessageJson.message;
                  LocalStorageHelper.getInstance().saveAuthentificationToken(tokenInfo);
                  const userInfo = tokenInfo && tokenInfo.userInfo ? tokenInfo.userInfo : {};
                  if (window.trebbleAnalyticsHelper && userInfo) {
                    //const uniqueUserIdentifier = LocalStorageHelper.getInstance().getUniqueUserIdentifier();
                    window.trebbleAnalyticsHelper.setUserId(
                      userInfo.temporary && userInfo.temporaryUserRandomId
                        ? userInfo.username + "_" + userInfo.temporaryUserRandomId
                        : userInfo.username,
                    );
                  }
                  window.saveUserIdForTracking(
                    userInfo._id,
                    userInfo.email,
                    userInfo.lastName,
                    userInfo.firstName,
                    userInfo.creationDate,
                    userInfo,
                  );
                  $.ajaxSetup({
                    headers: {
                      Authorization: "Bearer " + tokenInfo.token,
                    },
                  });
                  resolve(true);
                } else {
                  reject(receivedMessageJson.errorMessage);
                }
                window.removeEventListener("message", onMessageReceivedFunc);
                openedWindow.close();
              }
            };

            window.addEventListener("message", onMessageReceivedFunc, false);
            openedWindow.focus();
            if (openedWindow.focus) {
              openedWindow.focus();
            }

            openedWindow.onload = function () {
              openedWindow.onunload = function () {
                reject(window.getI18n(ti18n, "PLEASE_LOGIN"));
              };
            };
          } else {
            reject(window.getI18n(ti18n, "PLEASE_ALLOW_POPUPS"));
          }
        } catch (error) {
          reject(error);
        }
      });
    }
  },

  buildBrowserMessageStringToPost: function (action, success, message, errorMessage) {
    const toSend = {};
    toSend.action = action;
    toSend.success = success;
    toSend.message = message;
    toSend.errorMessage = errorMessage;
    return JSON.stringify(toSend);
  },

  buildJsonFromBrowserMessageString: function (messageString) {
    return JSON.parse(messageString);
  },

  _doLogin: function (forceLoginPage, forceLoginOnlyIfGuest, forceSignUpAsCreator) {
    const loginShouldForceBecauseGuestAccount = forceLoginOnlyIfGuest && this.isUserpreviouslyLoggedInAsGuest();
    if (
      !forceLoginPage &&
      !loginShouldForceBecauseGuestAccount &&
      Utils.getInstance().isTokenValid() &&
      LocalStorageHelper.getInstance().isUsingNewLoginModule() &&
      !(this.isUserpreviouslyLoggedInAsGuest() && !window.trebble.allowAutoLoginAsGuest)
    ) {
      const tokenInfo = LocalStorageHelper.getInstance().retrieveAuthentificationToken();
      $.ajaxSetup({
        headers: {
          Authorization: "Bearer " + tokenInfo.token,
        },
      });
      return RSVP.Promise.resolve();
    } else {
      /*
return Utils.getInstance().getUserLoginCredentials().then((function(userCred){
const username = userCred.username;
const password = userCred.password;
return this.doLogin(username, password);
}).bind(this));*/
      if (window.trebble.allowAutoLoginAsGuest && !loginShouldForceBecauseGuestAccount) {
        return this.doLoginAsGuest();
      } else {
        return this.doLoginWithLoginPage(
          false,
          forceLoginPage || loginShouldForceBecauseGuestAccount,
          null,
          forceSignUpAsCreator,
        );
      }
    }
  },

  _createTemporaryAccountWithDeviceUID: function () {
    let tempUsername = null;
    let uid = null;
    if (window.device && window.device.platform == "browser") {
      uid = new Date().getTime() + window.ID();
    } else {
      uid = window.device.uuid + window.ID();
    }
    if (window.device && window.device.platform == "browser") {
      tempUsername = "visitor_" + uid;
    } else {
      tempUsername = window.device.platform + uid;
    }
    const tempPassword = "cocoBongo1";
    const params = {
      firstName: tempUsername,
      lastName: tempUsername,
      email: tempUsername,
      birthDate: new Date(),
      username: tempUsername,
      temporary: true,
      accessCode: "QDS5-ZPFK-4F46",
      password: tempPassword,
    };
    if (window.device && window.device.platform == "browser") {
      params.visitor = true;
      params.referrer = window.trebble.referrer;
    }
    let result = null;
    const apiSelf = this;
    if (!window.trebble.allowAutoLoginAsGuest) {
      Utils.getInstance().showInfoNotification(window.getI18n(ti18n, "WE_ARE_CREATING_YOU_A_TEMPORARY_ACCOUNT"));
    }

    const context = { referrer: window.trebble.referrer };
    return this.signup(params, null, context).then(
      function (signupResult) {
        result = signupResult;
        if (result.userInfo) {
          const username = result.userInfo.username;
          //const password = tempPassword;
          return (this.loginPromise = new RSVP.Promise(function (resolve, reject) {
            $.post(window.trebble.usedProtocolPrefix + trebbleServerUrl + "/loginWithToken", {
              username: username,
              password: tempPassword,
              language: window.getAppUsedLanguage(),
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              environment: window.getAppEnvironment(),
            })
              .done(
                function (result) {
                  apiSelf._loginSuccessfull = result.message;
                  return apiSelf._getDefaultResponseHandler(result, resolve, reject, null, "Login");
                }.bind(this),
              )
              .fail(
                function (error) {
                  apiSelf._loginSuccessfull = false;
                  apiSelf._loginFailureReason = error;
                  return apiSelf._getDefaultFailedResponseHandler(error, reject, null, "Login");
                }.bind(this),
              );
          }).then(function (tokenInfo) {
            LocalStorageHelper.getInstance().saveAuthentificationToken(tokenInfo);
            const userInfo = tokenInfo && tokenInfo.userInfo ? tokenInfo.userInfo : {};
            if (window.trebbleAnalyticsHelper && userInfo) {
              const uniqueUserIdentifier = LocalStorageHelper.getInstance().getUniqueUserIdentifier();
              window.trebbleAnalyticsHelper.setUserId(
                userInfo.temporary && userInfo.temporaryUserRandomId
                  ? userInfo.username + "_" + userInfo.temporaryUserRandomId
                  : userInfo.username,
              );
            }
            window.saveUserIdForTracking(
              userInfo._id,
              userInfo.email,
              userInfo.lastName,
              userInfo.firstName,
              userInfo.creationDate,
              userInfo,
            );
            $.ajaxSetup({
              headers: {
                Authorization: "Bearer " + tokenInfo.token,
              },
            });
            return true;
          }));
          //return this.doLogin(username,password, true);
        }
      }.bind(this),
    );
  },

  getUsername: function () {
    return this.username;
  },

  doLoginAsGuest: function (doNotCreateTempUserIfUserNotFound) {
    const username = "anonymous"; //tempUsername;
    const password = "doudou"; //uid;
    window.log("doLoginAsGuest ");
    if (Utils.getInstance().isInterntetConnectionIsAvailable()) {
      const apiSelf = this;
      if (
        this._loginDoneWithToken &&
        Utils.getInstance().isTokenValid() &&
        LocalStorageHelper.getInstance().isUsingNewLoginModule()
      ) {
        {
          const tokenInfo = LocalStorageHelper.getInstance().retrieveAuthentificationToken();

          $.ajaxSetup({
            headers: {
              Authorization: "Bearer " + tokenInfo.token,
            },
          });
          return RSVP.Promise.resolve(true);
        }
      } else {
        if (!this.loginPromiseWithTokenAsGuest) {
          this._loginDoneWithToken = true;
          this.loginPromiseWithTokenAsGuest = new RSVP.Promise(function (resolve, reject) {
            $.post(window.trebble.usedProtocolPrefix + trebbleServerUrl + "/loginWithToken", {
              username: username,
              password: password,
              language: window.getAppUsedLanguage(),
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              environment: window.getAppEnvironment(),
            })
              .done(
                function (result) {
                  return apiSelf._getDefaultResponseHandler(result, resolve, reject, null, "Login As Guest");
                }.bind(this),
              )
              .fail(
                function (error) {
                  apiSelf._loginFailureReasonWithToken = error;
                  return apiSelf._getDefaultFailedResponseHandler(error, reject, null, "Login As Guest");
                }.bind(this),
              );
          })
            .then(function (tokenInfo) {
              LocalStorageHelper.getInstance().saveAuthentificationToken(tokenInfo);
              /*if(window.trebbleAnalyticsHelper && userInfo){
                const uniqueUserIdentifier = LocalStorageHelper.getInstance().getUniqueUserIdentifier();
                window.trebbleAnalyticsHelper.setUserId((userInfo.temporary && userInfo.temporaryUserRandomId) ? userInfo.username +"_"+ userInfo.temporaryUserRandomId:  userInfo.username);
            }
            window.saveUserIdForTracking(userInfo._id, userInfo.email, userInfo.lastName, userInfo.firstName, userInfo.creationDate, userInfo); */
              $.ajaxSetup({
                headers: {
                  Authorization: "Bearer " + tokenInfo.token,
                },
              });
              return true;
            })
            .catch(
              function (error) {
                if (
                  !doNotCreateTempUserIfUserNotFound &&
                  error &&
                  error.indexOf &&
                  (error.indexOf("User not found") != -1 || error.indexOf("Invalid password!"))
                ) {
                  return this._createTemporaryAccountWithDeviceUID();
                } else {
                  apiSelf._loginFailureReasonWithToken = error;
                  throw error;
                }
              }.bind(this),
            );
          this.loginPromiseWithTokenAsGuest
            .then(
              function () {
                this.loginPromiseWithTokenAsGuest = null;
                this._loginDoneWithToken = false;
              }.bind(this),
            )
            .catch(
              function (error) {
                this.loginPromiseWithTokenAsGuest = null;
                this._loginDoneWithToken = false;
                console.error("Login Failed: Reason" + error);
                throw window.getI18n(ti18n, "PLEASE_LOGIN");
              }.bind(this),
            );
          return this.loginPromiseWithTokenAsGuest;
        } else {
          return this.loginPromiseWithTokenAsGuest.then(
            function () {
              const tokenInfo = LocalStorageHelper.getInstance().retrieveAuthentificationToken();

              $.ajaxSetup({
                headers: {
                  Authorization: "Bearer " + tokenInfo.token,
                },
              });
            }.bind(this),
          );
        }
      }
    } else {
      return RSVP.Promise.reject(window.getI18n(ti18n, "NO_INTERNET_CONNECTION_AVAILABLE"));
    }
  },

  isUserpreviouslyLoggedInAsGuest: function () {
    const tokenInfo = LocalStorageHelper.getInstance().retrieveAuthentificationToken();
    return tokenInfo && tokenInfo.userInfo && tokenInfo.userInfo.temporary;
  },

  requireLoginController: function () {
    $.mobile.loading("show");
    return new RSVP.Promise(function (resolve, reject) {
      try {
        require(["controllers/login/LoginControllerNew"], function (loadedFiled) {
          $.mobile.loading("hide");
          resolve(loadedFiled.default);
        });
      } catch (error) {
        $.mobile.loading("hide");
        reject(error);
      }
    });
  },

  doLoginWithLoginPage: function (
    doNotCreateTempUserIfUserNotFound,
    forceLogin,
    successSignupData,
    forceSignUpAsCreator,
  ) {
    window.log("doLoginWithLoginPage  ");
    if (Utils.getInstance().isInterntetConnectionIsAvailable()) {
      if (
        !forceLogin &&
        this._loginDoneWithToken &&
        Utils.getInstance().isTokenValid() &&
        LocalStorageHelper.getInstance().isUsingNewLoginModule()
      ) {
        /*if(Utils.getInstance().isTokenValid())*/
        {
          const tokenInfo = LocalStorageHelper.getInstance().retrieveAuthentificationToken();
          $.ajaxSetup({
            headers: {
              Authorization: "Bearer " + tokenInfo.token,
            },
          });
          return RSVP.Promise.resolve(true);
        }
        /*else{
        return RSVP.Promise.reject("User is not logged in.");
    }*/
      } else {
        if (!this.loginPromiseWithToken /*|| forceLogin*/) {
          this._loginDoneWithToken = true;
          let p = null;
          window.log("window.trebble.isEmbededLoginInPage is " + window.trebble.isEmbededLoginInPage);
          if (
            (!isEmbedded &&
              !Utils.getInstance().isJQMPopupOpen() &&
              !window.trebble.entryPointUrlHashLoadedPlayerQueue) ||
            window.trebble.isEmbededLoginInPage
          ) {
            //SHOW LOGIN IF IT IS EMBEDDED LOGIN PAGE OPENED OR
            // APP IS NOT EMBEDDED AND THE APP WAS NOT LOADED VIA AN URL WHICH LOAD A PLAYLIST IN THE PLAYER
            p = new RSVP.Promise(
              function (resolve, reject) {
                try {
                  const activePage$el = $(".ui-page-active");
                  const showAsPopup =
                    activePage$el.size() != 0 &&
                    activePage$el.get(0).id != "featurePage" &&
                    activePage$el.get(0).id != "loginPage" &&
                    activePage$el.get(0).id != "onboardingSignupPage";
                  //isEmbedded;
                  const params = {};
                  params.loginApi = this.postLogin.bind(this);
                  params.loginWithFacebookApi = this.postLoginWithFacebook.bind(this);
                  params.loginWithAppleApi = this.postLoginWithApple.bind(this);
                  params.loginWithGoogleApi = this.postLoginWithGoogle.bind(this);
                  params.showAsPopup = showAsPopup;
                  params.forceSignUpAsCreator = forceSignUpAsCreator;
                  params.isUserpreviouslyLoggedInAsGuest = this.isUserpreviouslyLoggedInAsGuest();
                  if (window.trebble.isEmbededLoginInPage) {
                    params.isUserpreviouslyLoggedInAsGuest = false;
                  }
                  /* if (false && params.isUserpreviouslyLoggedInAsGuest) {
                        params.signupApi = this.updateGuestProfile.bind(this);
                    } else {*/
                  params.signupApi = this.signup.bind(this);
                  params.signupWithFacebookApi = this.signupWithFacebook.bind(this);
                  params.signupWithGoogleApi = this.signupWithGoogle.bind(this);
                  params.signupWithAppleApi = this.signupWithApple.bind(this);
                  //}
                  params.doNotAllowGuestLogin = window.trebble.isEmbededLoginInPage;
                  params.loginAsGuestApi = this.doLoginAsGuest.bind(this);
                  params.successSignupData = successSignupData;
                  params.onLoginSuccessfullyCompleted = function (loginResult) {
                    this.loginPromiseWithToken = null;
                    this._loginDoneWithToken = false;
                    if (loginResult) {
                      const tokenInfo = LocalStorageHelper.getInstance().retrieveAuthentificationToken();
                      $.ajaxSetup({
                        headers: {
                          Authorization: "Bearer " + tokenInfo.token,
                        },
                      });
                      resolve();
                    } else {
                      reject(window.getI18n(ti18n, "LOGIN_FAILED"));
                    }
                  };
                  window.log("Opening LoginControllerNew");

                  const requireLoginControllerPromise = this._cachedLoginControllerObj
                    ? RSVP.Promise.resolve(this._cachedLoginControllerObj)
                    : this.requireLoginController(["controllers/login/LoginControllerNew"]);
                  requireLoginControllerPromise
                    .then(
                      function (LoginControllerNew) {
                        this._cachedLoginControllerObj = LoginControllerNew;
                        const loginController = new LoginControllerNew(params);
                        $.mobile.loading("show");
                        window.log(" LoginControllerNew built");
                        loginController
                          .buildModelAndRenderView()
                          .then(
                            function () {
                              window.log(" waiting for pageContainerReadyPromise");
                              window.log(
                                " waiting for pageContainerReadyPromise" + window.trebble.pageContainerReadyPromise,
                              );
                              return window.trebble.pageContainerReadyPromise;
                            }.bind(this),
                          )
                          .then(
                            function () {
                              window.log(" LoginControllerNew show popup");
                              if (!showAsPopup) {
                                if (window.trebble && window.trebble.trebbleRouter) {
                                  return window.trebble.trebbleRouter.navigateToPageWithId(
                                    "#NewLoginPage",
                                    "Login page",
                                    false,
                                    false,
                                    null,
                                    null,
                                    false,
                                  );
                                } else {
                                  if (this.loginController.getView()) {
                                    $("body").append(this.loginController.getView().$el);
                                    $.mobile.changePage("#NewLoginPage", {
                                      changeHash: false,
                                    });
                                    if (window.trebble && window.trebble.trebbleRouter) {
                                      window.trebble.trebbleRouter.setCanPlayerFooterBeShowOnActivePage(false);
                                    }
                                    $.mobile.resetActivePageHeight();
                                  }
                                }
                                $.mobile.loading("hide");
                              } else {
                                $.mobile.loading("hide");
                              }
                            }.bind({
                              loginController: loginController,
                            }),
                          )
                          .catch(function (error) {
                            $.mobile.loading("hide");
                            console.error("An error occured while showing Login Page.Error:" + error);
                            reject(error);
                          });
                      }.bind(this),
                    )
                    .catch(
                      function (error) {
                        console.error("Error loading Login controller.Error" + error);
                        window.alertErrorMessage(window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_DISPLAYING_LOGIN"));
                      }.bind(this),
                    );
                } catch (error) {
                  reject(error);
                }
              }.bind(this),
            );
          } else {
            p = this._doLoginInPopup(true);
          }
          p.then(
            function () {
              this.loginPromiseWithToken = null;
              this._loginDoneWithToken = false;
            }.bind(this),
          ).catch(
            function (error) {
              this.loginPromiseWithToken = null;
              this._loginDoneWithToken = false;
              console.error("Login Failed: Reason" + error);
              throw window.getI18n(ti18n, "PLEASE_LOGIN");
            }.bind(this),
          );
          this.loginPromiseWithToken = p;
          return this.loginPromiseWithToken;
        } else {
          return this.loginPromiseWithToken.then(
            function () {
              const tokenInfo = LocalStorageHelper.getInstance().retrieveAuthentificationToken();
              $.ajaxSetup({
                headers: {
                  Authorization: "Bearer " + tokenInfo.token,
                },
              });
            }.bind(this),
          );
        }
      }
    } else {
      return RSVP.Promise.reject(window.getI18n(ti18n, "NO_INTERNET_CONNECTION_AVAILABLE"));
    }
  },

  postLogin: function (username, password) {
    this._loginDoneWithToken = true;
    const params = {
      username: username,
      password: password,
      language: window.getAppUsedLanguage(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      environment: window.getAppEnvironment(),
    };
    return this._makeApiServerCall("loginWithToken", params, "Do login with token", true)
      .then(function (tokenInfo) {
        LocalStorageHelper.getInstance().saveAuthentificationToken(tokenInfo);
        const userInfo = tokenInfo && tokenInfo.userInfo ? tokenInfo.userInfo : {};
        if (window.trebbleAnalyticsHelper && userInfo) {
          window.trebbleAnalyticsHelper.setUserId(
            userInfo.temporary && userInfo.temporaryUserRandomId
              ? userInfo.username + "_" + userInfo.temporaryUserRandomId
              : userInfo.username,
          );
        }
        window.saveUserIdForTracking(
          userInfo._id,
          userInfo.email,
          userInfo.lastName,
          userInfo.firstName,
          userInfo.creationDate,
          userInfo,
        );
        $.ajaxSetup({
          headers: {
            Authorization: "Bearer " + tokenInfo.token,
          },
        });
        return true;
      })
      .catch(function (error) {
        throw error;
      });
  },

  postLoginWithFacebook: function (facebookAccessToken) {
    this._loginDoneWithToken = true;
    const apiSelf = this;
    const params = {
      facebookAccessToken: facebookAccessToken,
      language: window.getAppUsedLanguage(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      environment: window.getAppEnvironment(),
    };
    return this._makeApiServerCall("loginWithFacebookToken", params, "Do login with facebook token", true)
      .then(function (tokenInfo) {
        LocalStorageHelper.getInstance().saveAuthentificationToken(tokenInfo);
        const userInfo = tokenInfo && tokenInfo.userInfo ? tokenInfo.userInfo : {};
        if (window.trebbleAnalyticsHelper && userInfo) {
          const uniqueUserIdentifier = LocalStorageHelper.getInstance().getUniqueUserIdentifier();
          window.trebbleAnalyticsHelper.setUserId(
            userInfo.temporary && userInfo.temporaryUserRandomId
              ? userInfo.username + "_" + userInfo.temporaryUserRandomId
              : userInfo.username,
          );
        }
        window.saveUserIdForTracking(
          userInfo._id,
          userInfo.email,
          userInfo.lastName,
          userInfo.firstName,
          userInfo.creationDate,
          userInfo,
        );

        $.ajaxSetup({
          headers: {
            Authorization: "Bearer " + tokenInfo.token,
          },
        });
        return userInfo;
      })
      .catch(function (error) {
        throw error;
      });
  },

  postLoginWithApple: function (appleIdToken) {
    this._loginDoneWithToken = true;
    const apiSelf = this;
    const params = {
      appleIdToken: appleIdToken,
      language: window.getAppUsedLanguage(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      environment: window.getAppEnvironment(),
    };
    return this._makeApiServerCall("loginWithAppleToken", params, "Do login with Apple token", true)
      .then(function (tokenInfo) {
        LocalStorageHelper.getInstance().saveAuthentificationToken(tokenInfo);
        const userInfo = tokenInfo && tokenInfo.userInfo ? tokenInfo.userInfo : {};
        if (window.trebbleAnalyticsHelper && userInfo) {
          const uniqueUserIdentifier = LocalStorageHelper.getInstance().getUniqueUserIdentifier();
          window.trebbleAnalyticsHelper.setUserId(
            userInfo.temporary && userInfo.temporaryUserRandomId
              ? userInfo.username + "_" + userInfo.temporaryUserRandomId
              : userInfo.username,
          );
        }
        window.saveUserIdForTracking(
          userInfo._id,
          userInfo.email,
          userInfo.lastName,
          userInfo.firstName,
          userInfo.creationDate,
          userInfo,
        );

        $.ajaxSetup({
          headers: {
            Authorization: "Bearer " + tokenInfo.token,
          },
        });
        return userInfo;
      })
      .catch(function (error) {
        throw error;
      });
  },

  postLoginWithGoogle: function (googleAccessToken) {
    this._loginDoneWithToken = true;
    const apiSelf = this;
    const params = {
      googleAccessToken: googleAccessToken,
      language: window.getAppUsedLanguage(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      environment: window.getAppEnvironment(),
    };
    return this._makeApiServerCall("loginWithGoogleToken", params, "Do login with Google token", true)
      .then(function (tokenInfo) {
        LocalStorageHelper.getInstance().saveAuthentificationToken(tokenInfo);
        const userInfo = tokenInfo && tokenInfo.userInfo ? tokenInfo.userInfo : {};
        if (window.trebbleAnalyticsHelper && userInfo) {
          const uniqueUserIdentifier = LocalStorageHelper.getInstance().getUniqueUserIdentifier();
          window.trebbleAnalyticsHelper.setUserId(
            userInfo.temporary && userInfo.temporaryUserRandomId
              ? userInfo.username + "_" + userInfo.temporaryUserRandomId
              : userInfo.username,
          );
        }
        window.saveUserIdForTracking(
          userInfo._id,
          userInfo.email,
          userInfo.lastName,
          userInfo.firstName,
          userInfo.creationDate,
          userInfo,
        );
        $.ajaxSetup({
          headers: {
            Authorization: "Bearer " + tokenInfo.token,
          },
        });
        return userInfo;
      })
      .catch(function (error) {
        throw error;
      });
  },

  getAllMyFavoritedSongs: function () {
    return this._makeApiServerCall("retrieveFavoritedSongs", {}, "Get my list of favorited Songs");
  },

  getMyFollowers: function () {
    return this._makeApiServerCall("getMyFollowers", {}, "Get my list of followers");
  },

  getMyFollowings: function () {
    return this._makeApiServerCall("getMyFollowings", {}, "Get my list of followings");
  },

  isTrebblePaidSubscriptionValid: function () {
    return this._makeApiServerCall(
      "isTrebblePaidSubscriptionValid",
      {},
      "Is Trebble paid subscription valid for logged in user",
    );
  },

  isStripeAccountFullySetupToReceivePayments: function () {
    return this._makeApiServerCall(
      "isStripeAccountFullySetupToReceivePayments",
      {},
      "Is Stripe Account Fully Setup To Receive Payments",
    );
  },

  hasPurchasedJourney: function (journeyRadioId) {
    return this._makeApiServerCall(
      "hasPurchasedJourney",
      { journeyRadioId: journeyRadioId },
      "Has User Purchased Journey",
    );
  },

  onMobileJourneyPurchaseApproved: function (foveaProduct, journeyRadioId) {
    return this._makeApiServerCall(
      "onMobileJourneyPurchaseApproved",
      { journeyRadioId: journeyRadioId, foveaProduct: foveaProduct },
      "On Mobile Journey Journey Purchased Approved",
    );
  },

  onMobileJourneyPurchaseVerified: function (foveaProduct, journeyRadioId) {
    return this._makeApiServerCall(
      "onMobileJourneyPurchaseVerified",
      { journeyRadioId: journeyRadioId, foveaProduct: foveaProduct },
      "On Mobile Journey Journey Purchased Approved",
    );
  },

  getUrlToSetupStripe: function (forwardUrlOnOnboardingCompleted, forwardUrlOnOnboardingInterupted) {
    return this._makeApiServerCall(
      "getUrlToSetupStripe",
      {
        forwardUrlOnOnboardingCompleted: forwardUrlOnOnboardingCompleted,
        forwardUrlOnOnboardingInterupted: forwardUrlOnOnboardingInterupted,
        locale: window.getAppUsedLanguage(),
      },
      "Get Url to setup stripe",
    );
  },

  getStripeUrlForPurchasingJourney: function (
    journeyRadioId,
    forwardUrlOnPurchaseSuccessfull,
    forwardUrlOnPurchaseCancelled,
  ) {
    return this._makeApiServerCall(
      "getStripeUrlForPurchasingJourney",
      {
        journeyRadioId: journeyRadioId,
        forwardUrlOnPurchaseSuccessfull: forwardUrlOnPurchaseSuccessfull,
        forwardUrlOnPurchaseCancelled: forwardUrlOnPurchaseCancelled,
        locale: window.getAppUsedLanguage(),
      },
      "Get Url to setup stripe",
    );
  },

  getUrlToStripeDashboard: function () {
    return this._makeApiServerCall("getUrlToStripeDashboard", {}, "Get Url to Stripe Dashboard");
  },
  disconnectFromStripe: function () {
    return this._makeApiServerCall("disconnectFromStripe", {}, "Disconnect From Stripe");
  },

  deleteStripeAccount: function () {
    return this._makeApiServerCall("deleteStripeAccount", {}, "Delete stripe account");
  },

  createSubscriptionForCreatorPro: function () {
    return this._makeApiServerCall("createSubscriptionForCreatorPro", {}, "Create subcription for creator pro ");
  },

  changeSubscriptionToCreatorPro: function () {
    return this._makeApiServerCall("changeSubscriptionToCreatorPro", {}, "Change to subcription for creator pro ");
  },

  createSubscriptionForCreatorProPlus: function () {
    return this._makeApiServerCall(
      "createSubscriptionForCreatorProPlus",
      {},
      "Create subcription for creator pro plus",
    );
  },

  changeSubscriptionToCreatorProPlus: function () {
    return this._makeApiServerCall(
      "changeSubscriptionToCreatorProPlus",
      {},
      "Change to subcription for creator pro plus",
    );
  },

  createSubscriptionForCreatorProYearly: function () {
    return this._makeApiServerCall(
      "createSubscriptionForCreatorProYearly",
      {},
      "Create subcription for creator pro yearly ",
    );
  },

  reactivateCreatorSubscription: function () {
    return this._makeApiServerCall("reactivateCreatorSubscription", {}, "Reactivate creator subcription");
  },

  changeSubscriptionToCreatorProYearly: function () {
    return this._makeApiServerCall(
      "changeSubscriptionToCreatorProYearly",
      {},
      "Change to subcription for creator pro yearly ",
    );
  },

  createSubscriptionForCreatorProPlusYearly: function () {
    return this._makeApiServerCall(
      "createSubscriptionForCreatorProPlusYearly",
      {},
      "Create subcription for creator pro plus yearly",
    );
  },

  changeSubscriptionToCreatorProPlusYearly: function () {
    return this._makeApiServerCall(
      "changeSubscriptionToCreatorProPlusYearly",
      {},
      "Change to subcription for creator pro plus yearly",
    );
  },

  cancelCreatorSubscription: function () {
    return this._makeApiServerCall("cancelCreatorSubscription", {}, "Cancel creator subscription");
  },

  getStripeCreatorCustomerPortalUrl: function (returnUrl, showPaymentMethodUpdate, showInvoiceHistory) {
    return this._makeApiServerCall(
      "getStripeCreatorCustomerPortalUrl",
      {
        returnUrl: returnUrl,
        showPaymentMethodUpdate: showPaymentMethodUpdate,
        showInvoiceHistory: showInvoiceHistory,
      },
      "get Stripe Creator Customer Portal Url",
    );
  },

  retriveUpcomingInvoiceForCreatorSubscriptionUpgradeToProPlan: function (yearlyBilling) {
    return this._makeApiServerCall(
      "retriveUpcomingInvoiceForCreatorSubscriptionUpgradeToProPlan",
      { yearlyBilling: yearlyBilling },
      "Retrieve upcoming invoice for creator upgrading to pro plan",
    );
  },

  retriveUpcomingInvoiceForCreatorSubscriptionUpgradeToProPlusPlan: function (yearlyBilling) {
    return this._makeApiServerCall(
      "retriveUpcomingInvoiceForCreatorSubscriptionUpgradeToProPlusPlan",
      { yearlyBilling: yearlyBilling },
      "Retrieve upcoming invoice for creator upgrading to pro plus plan",
    );
  },

  retrievePaymentMethodUsedForCreatorSubscription: function () {
    return this._makeApiServerCall(
      "retrievePaymentMethodUsedForCreatorSubscription",
      {},
      "Retrieve default payment method used for creator subscription",
    );
  },

  getValidTrebblePaidSubscription: function () {
    return this._makeApiServerCall(
      "getValidTrebblePaidSubscription",
      {},
      "Get valid Trebble paid subscription valid for logged in user",
    );
  },

  getUserActiveCreatorPaidSubscription: function (includeDisplayablePaymentMethod) {
    return this._makeApiServerCall(
      "getUserActiveCreatorPaidSubscription",
      { includeDisplayablePaymentMethod: includeDisplayablePaymentMethod },
      "Get active Trebble creator paid subscription valid for logged in user",
    );
  },

  getSecretToSetupPaymentIntent: function () {
    return this._makeApiServerCall("getSecretToSetupPaymentIntent", {}, "Get secret to setup payment intent");
  },

  getNumberOfMinutesOfTranscriptionLeft: function () {
    return this._makeApiServerCall("getNumberOfMinutesOfTranscriptionLeft", {}, "Get minutes of transcription left");
  },

  enhanceSpeechInFile : function(fileLocationToEnhance, transcriptionJobId){
    return this._makeApiServerCall("enhanceSpeechInFile", {fileLocationToEnhance,transcriptionJobId}, "Enhance speech in file");
  },

  startVideoRenderingInCloud : function(capsuleId, projectId, audioFileUrl, srtFileUrl, useTemporaryLocation, ffmpegCommandJsonUrl){
    return this._makeApiServerCall("startVideoRenderingProcess", { capsuleId, projectId, audioFileUrl, srtFileUrl, useTemporaryLocation, ffmpegCommandJsonUrl}, "start video rendering in the cloud");
  },

  getPresignedUrlForUploadingFFMPEGCommands : function(){
    return this._makeApiServerCall("getPresignedUrlForUploadingFFMPEGCommands", {}, "get presigned url for uploading ffmpeg commands");
  },

  getPresignedUrlForUploadingSrtFile : function(){
    return this._makeApiServerCall("getPresignedUrlForUploadingSrtFile", {}, "get presigned url for uploading srt file");
  },

  getPresignedUrlForUploadingTemporaryAudioOrVideoFile: function(mimeType){
    return this._makeApiServerCall("getPresignedUrlForUploadingTemporaryAudioOrVideoFile", {mimeType}, "get presigned url for uploading temporaru audio or video file");
  },

  getEnhancedFileUrlForMediaFile : function(fileLocationToEnhance){
    return this._makeApiServerCall("getEnhancedFileUrlForMediaFile", {fileLocationToEnhance}, "Get enhanced file url for media file");
  },

  getZendeskToken: function () {
    return this._makeApiServerCall("getZendeskToken", {}, "Get Zendesk Token");
  },

  getTawkToToken: function () {
    return this._makeApiServerCall("getTawkToToken", {}, "Get TawkTo Token");
  },

  getTranscriptionInfo: function (transcriptionId) {
    return this._makeApiServerCall(
      "getTranscriptionInfo",
      { transcriptionId: transcriptionId },
      "Get transcription info",
    );
  },

  findTranscriptionInfoForAudio: function (audioUrl, language) {
    return this._makeApiServerCall(
      "findTranscriptionInfoForAudio",
      { audioUrl, language },
      "Find transcription info for audio",
    );
  },



  getTranscribedFiles: function (searchKeyword, pageOffset, numberOfResults) {
    return this._makeApiServerCall(
      "getTranscribedFiles",
      {
        searchKeyword: searchKeyword,
        numberOfResults: numberOfResults,
        pageOffset: pageOffset,
      },
      "Get transcribed  files",
      false,
      false,
      false,
    );
  },

  getSequencerProjects: function (searchKeyword, pageOffset, numberOfResults, referenceProjectOnly) {
    return this._makeApiServerCall(
      "getSequencerProjects",
      {
        searchKeyword: searchKeyword,
        numberOfResults: numberOfResults,
        pageOffset: pageOffset,
        referenceProjectOnly: referenceProjectOnly,
      },
      "Get sequencer projects  files",
      false,
      false,
      false,
    );
  },

  getUserProcessedCreatorPaidSubscription: function () {
    return this._makeApiServerCall(
      "getUserProcessedCreatorPaidSubscription",
      {},
      "Get processed Trebble creator paid subscription valid for logged in user",
    );
  },

  getMyCompleteUserInfo: function () {
    return this._makeApiServerCall("getCompleteUserInfo", {}, "Get my complete user info");
  },

  getAllMyCapsules: function () {
    return this._makeApiServerCall("getAllMyCapsules", {}, "Get All My Capsules");
  },

  getEpidemicSoundToken: function () {
    return this._makeApiServerCall("getEpidemicSoundToken", {}, "Get Epidemic Sound Token");
  },

  searchForAllUsers: function (keyword, numberOfResults, startPage) {
    return this._makeApiServerCall(
      "searchForAllUsers",
      {
        keyword: keyword,
        numberOfResults: numberOfResults,
        startPage: startPage,
      },
      "Search for Users",
    );
  },

  searchForCities: function (keyword, language) {
    return this._makeApiServerCall(
      "searchForCities",
      {
        keyword: keyword,
        language: language,
      },
      "Search for Cities",
      true,
    );
  },

  getCityInfo: function (placeId, language) {
    return this._makeApiServerCall(
      "getCityInfo",
      {
        placeId: placeId,
        language: language,
      },
      "Get City Info",
      true,
    );
  },

  followUserByUsername: function (usernameOfUserToFollow) {
    return this._makeApiServerCall(
      "followUserByUsername",
      {
        usernameOfUserToFollow: usernameOfUserToFollow,
      },
      "Follow User By Username",
      false,
      false,
      true,
    );
  },

  unfollowUserByUsername: function (usernameOfUserToUnfollow) {
    return this._makeApiServerCall(
      "unfollowUserByUsername",
      {
        usernameOfUserToUnfollow: usernameOfUserToUnfollow,
      },
      "UnFollow User By Username",
      false,
      false,
      true,
    );
  },

  followTrebble: function (trebbleId) {
    return this._makeApiServerCall(
      "followRadioByRadioId",
      {
        radioIdToFollow: trebbleId,
      },
      "Follow Trebble",
      false,
      false,
      true,
    );
  },

  followJourney: function (journeyRadioId) {
    return this._makeApiServerCall(
      "followJourneyByJourneyRadioId",
      {
        journeyRadioIdToFollow: journeyRadioId,
      },
      "Follow Trebble",
      false,
      false,
      true,
    );
  },

  getJourneyStats: function (radioId) {
    return this._makeApiServerCall(
      "getStatsForJourney",
      {
        radioId: radioId,
      },
      "Get Stats for Journey",
      false,
      false,
      true,
    );
  },

  getJourneyOwner: function (journeyRadioId) {
    return this._makeApiServerCall(
      "getJourneyOwner",
      {
        journeyRadioId: journeyRadioId,
      },
      "Get Journey Owner",
      false,
      false,
      false,
    );
  },

  unfollowTrebble: function (trebbleId) {
    return this._makeApiServerCall(
      "unfollowRadioByRadioId",
      {
        radioIdToUnfollow: trebbleId,
      },
      "Unfollow Trebble",
      false,
      false,
      true,
    );
  },

  unfollowJourney: function (journeyRadioId) {
    return this._makeApiServerCall(
      "unfollowJourneyByJourneyRadioId",
      {
        journeyRadioIdToUnfollow: journeyRadioId,
      },
      "Unfollow Journey",
      false,
      false,
      true,
    );
  },

  getIdifiedSongInfoBySongTitle: function (songTitle, artistName, albumName) {
    return this._makeApiServerCall(
      "identifySongBySongInfo",
      {
        songTitle: songTitle,
        artistName: artistName,
        albumName: albumName,
      },
      "get gracenote song info by song title and artist name",
      false,
    );
  },

  getTokenInfo: function (token) {
    return this._makeApiServerCall(
      "getTokenInfo",
      {
        token: token,
      },
      "retrieve token info using token",
      true,
    );
  },

  getMyTrebbleInfoByTrebbleId: function (trebbleId) {
    return this._makeApiServerCall(
      "getRadioInfoByRadioId",
      {
        radioId: trebbleId,
      },
      "Get a trebble info via trebble id",
    );
  },

  getJourneyInfoByJourneyRadioId: function (journeyRadioId) {
    return this._makeApiServerCall(
      "getJourneyRadioInfoByJourneyRadioId",
      {
        journeyRadioId: journeyRadioId,
      },
      "Get a journey radio info via journey radio id",
    );
  },

  createAJourneyDraft: function (journeyRadioId) {
    return this._makeApiServerCall(
      "createAJourneyDraft",
      {
        radioId: journeyRadioId,
      },
      "create journey draft",
    );
  },

  deletAJourney: function (journeyRadioId) {
    return this._makeApiServerCall(
      "deletAJourney",
      {
        radioId: journeyRadioId,
      },
      "delete a journey",
    );
  },

  getRadioInfoAndCapsuleInfoByShortcastId: function (shortcastId) {
    return this._makeApiServerCall(
      "getRadioInfoAndCapsuleInfoByShortcastId",
      {
        episodeUID: shortcastId,
      },
      "Get capsules and radio info via shortcast id",
    );
  },

  getCapsulesInDraftJourney: function (journeyRadioId) {
    return this._makeApiServerCall(
      "getCapsulesInDraftJourney",
      {
        journeyRadioId: journeyRadioId,
      },
      "Get Capsules In Draft Journey",
    );
  },

  getCapsulesInAvailableJourney: function (journeyRadioId) {
    return this._makeApiServerCall(
      "getCapsulesInAvailableJourney",
      {
        journeyRadioId: journeyRadioId,
      },
      "Get Capsules In Available Journey",
    );
  },

  getTrailerForDraftJourney: function (journeyRadioId) {
    return this._makeApiServerCall(
      "getCapsuleUsedAsTrailerInDraftJourney",
      {
        journeyRadioId: journeyRadioId,
      },
      "Get Trailer For Draft Journey",
    );
  },

  getTrailerForAvailableJourney: function (journeyRadioId) {
    return this._makeApiServerCall(
      "getCapsuleUsedAsTrailerInPublishedJourney",
      {
        journeyRadioId: journeyRadioId,
      },
      "Get Trailer For Available Journey",
    );
  },

  addCapsuleAsTrailerToDraftJourney: function (capsuleId, journeyRadioId) {
    return this._makeApiServerCall(
      "addCapsuleAsTrailerToDraftJourney",
      {
        radioId: journeyRadioId,
        capsuleId: capsuleId,
      },
      "Clear Journey Trailer",
    );
  },

  clearJourneyTrailer: function (journeyRadioId) {
    return this._makeApiServerCall(
      "clearJourneyTrailer",
      {
        radioId: journeyRadioId,
      },
      "Clear Journey Trailer",
    );
  },

  getCapsulesInAvailableJourneyWithProgress: function (journeyRadioId) {
    return this._makeApiServerCall(
      "getCapsulesInAvailableJourneyWithProgress",
      {
        journeyRadioId: journeyRadioId,
      },
      "Get Capsules In Available Journey with progress",
    );
  },

  getCapsulesInDraftJourneyWithProgress: function (journeyRadioId) {
    return this._makeApiServerCall(
      "getCapsulesInDraftJourneyWithProgress",
      {
        journeyRadioId: journeyRadioId,
      },
      "Get Capsules In Draft Journey with progress",
    );
  },

  addCapsulesToDraftJourney: function (capsuleId, journeyRadioId) {
    return this._makeApiServerCall(
      "addCapsulesToDraftJourney",
      {
        radioId: journeyRadioId,
        capsuleId: capsuleId,
      },
      "Add Capsules To Draft Journey",
    );
  },

  archivePublishedJourney: function (journeyRadioId) {
    return this._makeApiServerCall(
      "archivePublishedJourney",
      {
        radioId: journeyRadioId,
      },
      "Archive Published Journey",
    );
  },

  logRadioPageVisited: function (radioId) {
    return this._makeApiServerCall(
      "logRadioPageVisited",
      {
        radioId: radioId,
      },
      "Log Radio Page was visited",
    );
  },

  logRadioTrailerPlayed: function (radioId) {
    return this._makeApiServerCall(
      "logRadioTrailerPlayed",
      {
        radioId: radioId,
      },
      "Log Journey Trailer Played",
    );
  },

  removeCapsulesToDraftJourney: function (capsuleId, journeyRadioId) {
    return this._makeApiServerCall(
      "removeCapsulesToDraftJourney",
      {
        radioId: journeyRadioId,
        capsuleId: capsuleId,
      },
      "Remove Capsules From Draft Journey",
    );
  },

  publishJourney: function (journeyRadioId, availabilityDate, timezone, context) {
    return this._makeApiServerCall(
      "publishJourney",
      {
        radioId: journeyRadioId,
        availabilityDate: availabilityDate,
        timezone: timezone,
        context: context,
      },
      "Publish Journey",
    );
  },

  revertToDraft: function (journeyRadioId, context) {
    return this._makeApiServerCall(
      "cancelScheduledJourney",
      {
        radioId: journeyRadioId,
        context: context,
      },
      "revert journey to draft",
    );
  },

  trackCapsuleDownload: function (capsuleId, downloadedUrl, downloadType, projectId) {
    return this._makeApiServerCall(
      "trackCapsuleDownload",
      {
        capsuleId: capsuleId,
        downloadedUrl: downloadedUrl,
        downloadType: downloadType,
        projectId
      },
      "track Capsule Download",
    );
  },

  removeFromArchive: function (journeyRadioId, context) {
    return this._makeApiServerCall(
      "removeJourneyFromArchive",
      {
        radioId: journeyRadioId,
        context: context,
      },
      "remove journey from archive",
    );
  },

  changeJourneyPublishedDate: function (journeyRadioId, availabilityDate, timezone, context) {
    return this._makeApiServerCall(
      "changeJourneyPublishedDate",
      {
        radioId: journeyRadioId,
        availabilityDate: availabilityDate,
        timezone: timezone,
        context: context,
      },
      "Change Journey Scheduled Date",
    );
  },

  getMyCompleteTrebbleInfoByTrebbleId: function (trebbleId) {
    return this._makeApiServerCall(
      "getRadioCompleteInfoByRadioId",
      {
        radioId: trebbleId,
      },
      "Get my complete trebble info via trebble id",
    );
  },

  getMyCompleteJourneyInfoByTrebbleId: function (trebbleId) {
    return this._makeApiServerCall(
      "getJourneyCompleteInfoByRadioId",
      {
        radioId: trebbleId,
      },
      "Get my complete journey info via trebble id",
    );
  },

  getHostUserIdByTrebbleId: function (trebbleId) {
    return this._makeApiServerCall(
      "getHostUserIdByRadioId",
      {
        radioId: trebbleId,
      },
      "Get host user id by trebble id",
    );
  },

  getMyTrebbleInfoByTrebbleCustomURL: function (customURL) {
    return this._makeApiServerCall(
      "getRadioInfoByRadioCustomURL",
      {
        customURL: customURL,
      },
      "Get a trebble info via custom url",
    );
  },

  getCapsuleInfoByCapsuleId: function (capsuleId) {
    return this._makeApiServerCall(
      "getCapsuleInfoByCapsuleId",
      {
        capsuleId: capsuleId,
      },
      "Get a capsule info",
    );
  },

  getCategoryIdsForRadioWithContent: function (language) {
    return this._makeApiServerCall(
      "getCategoryIdsForRadioWithContent",
      {
        language: language,
      },
      "Get category ids for available Trebble with audio preview",
    );
  },

  getCategoryIdsAvailableForShortcastRadio: function (language) {
    return this._makeApiServerCall(
      "getCategoryIdsAvailableForShortcastRadio",
      {
        language: language,
      },
      "Get category ids  available for shortcast radio",
    );
  },

  getCityInfoForRadioWithContentForSpecificLanguage: function (language, i18nLanguage) {
    return this._makeApiServerCall(
      "getCityInfoForRadioWithContentForSpecificLanguage",
      {
        language: language,
        i18nLanguage: i18nLanguage,
      },
      "Get city info for available Trebble with audio preview for specific language",
    );
  },

  getLanguagesForRadioWithContent: function (categoryId) {
    return this._makeApiServerCall(
      "getLanguageForRadioWithContent",
      {
        categoryId: categoryId,
      },
      "Get languages for available Trebble with audio preview",
    );
  },

  getLanguagesForRadioWithContentForSpecificCity: function (cityId) {
    return this._makeApiServerCall(
      "getLanguageForRadioWithContentForSpecificCity",
      {
        cityId: cityId,
      },
      "Get languages for available Trebble with audio preview for specific city",
    );
  },

  getCapsuleForSongCreatedFromCapsuleInfo: function (songJson) {
    return this._makeApiServerCall(
      "getCapsuleForSongCreatedFromCapsuleInfo",
      { song: songJson },
      "Get capsule client json for song created from capsule info",
    );
  },

  getNumberOfUnreadNotification: function () {
    return this._makeApiServerCall(
      "getNumberOfUnreadNotificationForLoggedInUser",
      {},
      "Get number of unread notifications",
    );
  },

  markUnreadNotificationAsRead: function () {
    return this._makeApiServerCall(
      "markUnreadNotificationForLoggedInUserAsRead",
      {},
      "mark unread notifications as read",
    );
  },

  getSongInfoBySongId: function (songId) {
    return this._makeApiServerCall(
      "getSongInfoBySongId",
      {
        songId: songId,
      },
      "Get a song info",
    );
  },

  getFollowersForUserWithUsername: function (username) {
    return this._makeApiServerCall(
      "getFollowersForUserByUsername",
      {
        username: username,
      },
      "Get Followers for User with username",
    );
  },

  getFollowedTrebblesForUserWithUsername: function (username) {
    return this._makeApiServerCall(
      "getFollowedTrebblesForUserWithUsername",
      {
        username: username,
      },
      "Get Followed Trebbles for User with username",
    );
  },

  getMyJourneys: function () {
    return this._makeApiServerCall("getMyJourneys", {}, "Get my journeys");
  },

  saveMyTrebbleSubscriptionOrder: function (orderedTrebbleIds) {
    return this._makeApiServerCall(
      "saveMyTrebbleSubscriptionOrder",
      {
        orderedTrebbleIds: orderedTrebbleIds,
      },
      "Save My Trebble Subscription Order",
    );
  },

  saveShortcastOrderInJourney: function (radioId, orderedShortcastIds) {
    return this._makeApiServerCall(
      "saveShortcastOrderInJourney",
      {
        radioId: radioId,
        orderedShortcastIds: orderedShortcastIds,
      },
      "Save Shortcast Order In Journey",
    );
  },

  getFollowingsForUserWithUsername: function (username) {
    return this._makeApiServerCall(
      "getFollowingsForUserByUsername",
      {
        username: username,
      },
      "Get Followings for User with username",
    );
  },

  getCommentInfoByCommentId: function (commentId) {
    return this._makeApiServerCall(
      "getCommentInfoByCommentId",
      {
        commentId: commentId,
      },
      "Get a comment info",
    );
  },

  getNotificationCustomPayloadByNotificationId: function (notificationId) {
    return this._makeApiServerCall(
      "getNotificationCustomPayloadByNotificationId",
      {
        notificationId: notificationId,
      },
      "Get custom payload by notification Id",
    );
  },

  getCapsulesInfoForSong: function (songJson, songRefSummary) {
    return this._makeApiServerCall(
      "getCapsulesInfoForSong",
      {
        songJson: songJson,
        songRefSummary: songRefSummary,
      },
      "Get all capsules info for song",
    );
  },

  getAllCapsulesForShortcastId: function (shortcastId) {
    return this._makeApiServerCall(
      "getAllCapsulesForShortcastId",
      {
        episodeUID: shortcastId,
      },
      "Get all capsules for shortcast",
    );
  },

  getCapsuleCoverArtUrl: function (capsuleId) {
    return this._makeApiServerCall(
      "getCapsuleCoverArtUrl",
      {
        capsuleId: capsuleId,
      },
      "Get Capsule Cover Art Url By Capsule Id",
    );
  },

  getCapsuleCoverArtImages: function (capsuleId) {
    return this._makeApiServerCall(
      "getCapsuleCoverArtImages",
      {
        capsuleId: capsuleId,
      },
      "Get Capsule Cover Art images info By Capsule Id",
    );
  },

  getCommentsInfoForSong: function (songJson, songRefSummary) {
    return this._makeApiServerCall(
      "getCommentsInfoForSong",
      {
        songJson: songJson,
        songRefSummary: songRefSummary,
      },
      "Get all comments for song",
    );
  },

  getCommentsInfoForCapsule: function (capsuleId, songRefSummary) {
    return this._makeApiServerCall(
      "getCommentsInfoForCapsule",
      {
        capsuleId: capsuleId,
        songRefSummary: songRefSummary,
      },
      "Get all comments for Capsule",
    );
  },

  getRepliesForComment: function (commentId) {
    return this._makeApiServerCall(
      "getRepliesForComment",
      {
        commentId: commentId,
      },
      "Get replies for comment",
    );
  },

  isRadioWithSameCustomURL: function (customURL, radioId) {
    return this._makeApiServerCall(
      "isRadioWithSameCustomURL",
      {
        radioId: radioId,
        customURL: customURL,
      },
      "Check radio exist with the same custom URL",
      true,
    );
  },

  isUrlASupportedFeed: function (rssFeedUrl) {
    return this._makeApiServerCall(
      "isUrlASupportedFeed",
      {
        feedUrl: rssFeedUrl,
      },
      "Check is RSS Feed URL is valid",
      true,
    );
  },

  getGeneratedCustomURLFromTrebbleName: function (trebbleName) {
    return this._makeApiServerCall(
      "getGeneratedCustomURLFromTrebbleName",
      {
        trebbleName: trebbleName,
      },
      "get generated custom URL from Trebble Name",
      true,
    );
  },

  isRadioWithSameName: function (name, radioId) {
    return this._makeApiServerCall(
      "isRadioWithSameName",
      {
        name: name,
        radioId: radioId,
      },
      "Check radio exist with the same name",
      true,
    );
  },

  isUserWithSameUsername: function (username, userId) {
    return this._makeApiServerCall(
      "isUsernameAlreadyInUse",
      {
        username: username,
        userId: userId,
      },
      "Check if user with the same username exist",
      true,
    );
  },

  isUserWithSameEmail: function (email, userId) {
    return this._makeApiServerCall(
      "isEmailAlreadyInUse",
      {
        email: email,
        userId: userId,
      },
      "Check if user with the same email exist",
      true,
    );
  },

  verifyBetaAccessCode: function (accessCode) {
    return this._makeApiServerCall(
      "verifyBetaAccessCode",
      {
        accessCode: accessCode,
      },
      "Check access code",
      true,
    );
  },

  updateCapsuleTitleAndNotes: function (capsuleId, title, notes) {
    return this._makeApiServerCall(
      "updateCapsuleTitleAndNotes",
      {
        capsuleId: capsuleId,
        title: title,
        notes: notes,
      },
      "Update shortcast info",
    );
  },

  updateTrebbleInfoByTrebbleId: function (
    trebbleId,
    name,
    description,
    customURL,
    categoryId,
    cityId,
    timezone,
    language,
    syncWithRssFeed,
    rssFeedUrlContentSource,
  ) {
    return this._makeApiServerCall(
      "UpdateRadioInfoByRadioId",
      {
        radioId: trebbleId,
        name: name,
        description: description,
        customURL: customURL,
        categoryId: categoryId,
        cityId: cityId,
        timezone: timezone,
        language: language,
        syncWithRssFeed: syncWithRssFeed,
        rssFeedUrlContentSource: rssFeedUrlContentSource,
      },
      "Update a trebble info",
    );
  },

  updateJourneyInfo: function (
    trebbleId,
    name,
    goal,
    description,
    categoryId,
    cityId,
    timezone,
    language,
    isPaid,
    costInUSD,
    visibility,
  ) {
    return this._makeApiServerCall(
      "updateJourneyInfo",
      {
        radioId: trebbleId,
        name: name,
        goal: goal,
        description: description,
        categoryId: categoryId,
        cityId: cityId,
        timezone: timezone,
        language: language,
        isPaid: isPaid,
        costInUSD: costInUSD,
        visibility: visibility,
      },
      "Update a journey info",
    );
  },

  changePassword: function (currentPassword, newPassword, reenterNewPassword) {
    return this._makeApiServerCall(
      "changePassword",
      {
        currentPassword: currentPassword,
        newPassword: newPassword,
        reenterNewPassword: reenterNewPassword,
      },
      "Change password",
    );
  },

  redeemCodeForCreatorSubscription: function (code) {
    return this._makeApiServerCall(
      "redeemCodeForCreatorSubscription",
      {
        codeToRedeem: code,
      },
      "Redeem code for creator subscription",
    );
  },

  getSignatureForCapsuleUpload: function (filename, songUri, requireCreatorSignup) {
    return this._makeApiServerCall(
      "getSignatureForCapsuleUpload",
      {
        songURI: songUri,
        fileName: filename,
      },
      "Get signature for capsule upload",
      false,
      false,
      true,
      requireCreatorSignup,
    );
  },


  getSignatureForCapsuleMP3Upload: function (filename, songUri, requireCreatorSignup) {
    return this._makeApiServerCall(
      "getSignatureForCapsuleMP3Upload",
      {
        songURI: songUri,
        fileName: filename,
      },
      "Get signature for capsule MP3 upload",
      false,
      false,
      true,
      requireCreatorSignup,
    );
  },

  getSignatureForCapsuleVideoUpload: function (filename, songUri, requireCreatorSignup) {
    return this._makeApiServerCall(
      "getSignatureForCapsuleVideoUpload",
      {
        songURI: songUri,
        fileName: filename,
      },
      "Get signature for capsule video upload",
      false,
      false,
      true,
      requireCreatorSignup,
    );
  },

  getSignatureForCapsuleVideoWebMUpload: function (filename, songUri, requireCreatorSignup) {
    return this._makeApiServerCall(
      "getSignatureForCapsuleVideoWebMUpload",
      {
        songURI: songUri,
        fileName: filename,
      },
      "Get signature for capsule video WebM upload",
      false,
      false,
      true,
      requireCreatorSignup,
    );
  },

  getSignatureForCapsuleWithNoSongUpload: function (filename, noParams, requireCreatorSignup) {
    return this._makeApiServerCall(
      "getSignatureForCapsuleWithNoSongUpload",
      {
        fileName: filename,
      },
      "Get signature for capsule upload with no song",
      false,
      false,
      true,
      requireCreatorSignup,
    );
  },

  

  getSignatureForDataUploadForEditQualityClassifier: async function (projectIdOrTranscriptionId, fileName, unsecure, mimeType, requireCreatorSignup) {
    return this._makeApiServerCall(
      "getSignatureForDataUploadForEditQualityClassifier",
      {
        projectIdOrTranscriptionId, fileName, unsecure, mimeType
      },
      "get signature for data input upload for edit quality classifier",
      false,
      false,
      true,
      requireCreatorSignup,
    );
  },

  getPresignedUrlForDataUploadForEditQualityClassifier: async function (projectIdOrTranscriptionId, fileName, unsecure, mimeType, requireCreatorSignup) {
    return this._makeApiServerCall(
      "getPresignedUrlForDataUploadForEditQualityClassifier",
      {
        projectIdOrTranscriptionId, fileName, unsecure, mimeType
      },
      "get presigned url for data input upload for edit quality classifier",
      false,
      false,
      true,
      requireCreatorSignup,
    );
  },


  saveUserFeedback: function (feedback, feedbackType) {
    return this._makeApiServerCall(
      "saveUserFeedback",
      {
        feedback: feedback,
        feedbackType: feedbackType,
      },
      "Send feedback",
      false,
      false,
      true,
    );
  },

  saveApps: function (names) {
    return this._makeApiServerCall(
      "saveApps",
      {
        names: names,
      },
      "Save names",
      false,
      false,
      true,
    );
  },

  saveAppleUserInfo: function (id_token, email, firstName, lastName) {
    return this._makeApiServerCall(
      "saveAppleUserInfo",
      {
        id_token: id_token,
        email: email,
        firstName: firstName,
        lastName: lastName,
      },
      "Save Apple User Info",
      true,
    );
  },

  retrieveAppleAuthorizationTokenInfo: function (code, client_secret) {
    return this._makeApiServerCall(
      "retrieveAppleAuthorizationTokenInfo",
      {
        code: code,
        client_secret: client_secret,
      },
      "Retrieve Apple Authorization Token Info",
      true,
    );
  },
  getSignatureForCapsuleReplyUpload: function (filename, capsuleRefId, requireCreatorSignup) {
    return this._makeApiServerCall(
      "getSignatureForCapsuleReplyUpload",
      {
        capsuleId: capsuleRefId,
        fileName: filename,
      },
      "Get signature for capsule reply upload",
      false,
      false,
      false,
      true,
    );
  },

  getUserProfileInfoByUsername: function (username) {
    return this._makeApiServerCall(
      "getUserProfileInfoByUsername",
      {
        username: username,
      },
      "Get user profile info by username",
    );
  },

  getUserProfileInfoByUserUniqueId: function (userUID) {
    return this._makeApiServerCall(
      "getUserProfileInfoByUserUniqueId",
      {
        userUID: userUID,
      },
      "Get user profile info by user unique id",
    );
  },

  sendRecoveryPasswordEmail: function (email) {
    return this._makeApiServerCall(
      "sendRecoveryPasswordEmail",
      {
        email: email,
      },
      "Send a link to reset a user password via email",
      true,
    );
  },

  getAvailableDefaultBackgroundMusic: function () {
    return this._makeApiServerCall(
      "getAvailableDefaultBackgroundMusic",
      null,
      "Get Available Default Background Music",
      true,
    );
  },

  getUserUploadedBackgroundMusic: function () {
    return this._makeApiServerCall(
      "getUserUploadedBackgroundMusic",
      null,
      "Get User Uploaded Background Music",
      false,
      false,
      true,
    );
  },

  getUserUploadedMedia: function () {
    return this._makeApiServerCall(
      "getUserUploadedMedia",
      null,
      "Get User Uploaded Media",
      false,
      false,
      true,
    );
  },

  getUserUploadedVideo: function () {
    return this._makeApiServerCall(
      "getUserUploadedMedia",
      {
        videoOnly: true,
      },
      "Get User Uploaded Media",
      false,
      false,
      true,
    );
  },


  getUserUploadedAudio: function () {
    return this._makeApiServerCall(
      "getUserUploadedMedia",
      {
        audioOnly: true,
      },
      "Get User Uploaded Media",
      false,
      false,
      true,
    );
  },
  

  addBackgroundMusicToUserLibrary: function (name, audioUrl, uploadAudioInfo, isVideo, metadata) {
    return this._makeApiServerCall(
      "addBackgroundMusicToUserLibrary",
      { name: name, audioUrl: audioUrl, uploadAudioInfo: uploadAudioInfo, isVideo: isVideo, metadata },
      "Add Background Music To User Library",
      false,
      false,
      true,
    );
  },

  addUploadedMediaToUserLibrary: function (name, audioUrl, uploadAudioInfo, isVideo, metadata) {
    return this._makeApiServerCall(
      "addUploadedMediaToUserLibrary",
      { name: name, audioUrl: audioUrl, uploadAudioInfo: uploadAudioInfo, isVideo: isVideo, metadata },
      "Add Uploaded Media To User Library",
      false,
      false,
      true,
    );
  },


  removeBackgroundMusicToUserLibrary: function (audioUID) {
    return this._makeApiServerCall(
      "removeBackgroundMusicToUserLibrary",
      { audioUID: audioUID },
      "Remove Background Music From User Library",
      false,
      false,
      true,
    );
  },

  removeUserUploadedMediaToUserLibrary: function (audioUID) {
    return this._makeApiServerCall(
      "removeUserUploadedMediaToUserLibrary",
      { audioUID: audioUID },
      "Remove User Uploaded Media From User Library",
      false,
      false,
      true,
    );
  },

  startTranscriptionForAudioRecording: function (audioRecordingId, language, audioType) {
    return this._makeApiServerCall(
      "startTranscriptionForAudioRecording",
      { audioRecordingId: audioRecordingId, language: language, audioType },
      "start Transcription For Audio Recording",
      false,
      false,
      true,
    );
  },

  saveSequencerProject: function (projectId, dateTimestamp, serializedData, willBeSaveByClient) {
    return this._makeApiServerCall(
      "saveSequencerProject",
      {
        projectId: projectId,
        dateTimestamp: dateTimestamp,
        willBeSaveByClient: willBeSaveByClient,
        serializedDataStringified: JSON.stringify(serializedData),
      },
      "Save sequencer project",
      false,
      false,
      true,
    );
  },

  getSerializedDataFileLocation: function (projectId, confirmed) {
    return this._makeApiServerCall(
      "getSerializedDataFileLocation",
      { projectId: projectId, confirmed: confirmed },
      "Get serialized data file location",
      false,
      false,
      true,
    );
  },

  getSequencerProjectTexTranscription: function (projectId, confirmed) {
    return this._makeApiServerCall(
      "getSequencerProjectTexTranscription",
      { projectId: projectId, confirmed: confirmed },
      "Get sequencer project text transcription",
      false,
      false,
      true,
    );
  },

  confirmProjectWasSuccessfullySaved: function (serializedDataFileLocation) {
    return this._makeApiServerCall(
      "confirmProjectWasSuccessfullySaved",
      { serializedDataFileLocation: serializedDataFileLocation },
      "Confirm sequencer project was successfully saved",
      false,
      false,
      true,
    );
  },

  getNumberOfFreeMinutesOfTranscriptionLeft: function () {
    return this._makeApiServerCall(
      "getNumberOfFreeMinutesOfTranscriptionLeft",
      null,
      "Get Number Of Free Minutes Of Transcription Left",
      false,
      false,
      true,
    );
  },

  addUploadedAudioRecordingToUserLibrary: function (name, audioUrl, uploadAudioInfo, originalFilename) {
    return this._makeApiServerCall(
      "addUploadedAudioRecordingToUserLibrary",
      { name: name, audioUrl: audioUrl, uploadAudioInfo: uploadAudioInfo, originalFilename: originalFilename },
      "Add User Audio Recording To User Library",
      false,
      false,
      true,
    );
  },

  addUploadedAudioRecordingArrayToUserLibrary: function (audioPartsArray) {
    return this._makeApiServerCall(
      "addUploadedAudioRecordingArrayToUserLibrary",
      { audioPartsArray: audioPartsArray },
      "Add User Audio Recording Array To User Library",
      false,
      false,
      true,
    );
  },

  removeBackgroundAudioRecordingFromUserLibrary: function (audioUID) {
    return this._makeApiServerCall(
      "removeBackgroundAudioRecordingFromUserLibrary",
      { audioUID: audioUID },
      "Remove Background Music From User Library",
      false,
      false,
      true,
    );
  },

  getSignatureForTrebbleCoverArtUpload: function (fileName, trebbleId, requireCreatorSignup) {
    return this._makeApiServerCall(
      "getSignatureForTrebbleCovertArtUpload",
      {
        trebbleId: trebbleId,
        fileName: fileName,
      },
      "Get signature for cover art trebble upload",
      false,
      false,
      true,
      requireCreatorSignup,
    );
  },

  getSignatureForTrebbleSweeperUpload: function (fileName, trebbleId, requireCreatorSignup) {
    return this._makeApiServerCall(
      "getSignatureForTrebbleSweeperUpload",
      {
        trebbleId: trebbleId,
        fileName: fileName,
      },
      "Get signature for trebble sweeper upload",
      false,
      false,
      true,
      requireCreatorSignup,
    );
  },

  getSignatureForTrebbleUserBackgroundMusicUpload: function (fileName, userId, requireCreatorSignup) {
    return this._makeApiServerCall(
      "getSignatureForTrebbleUserBackgroundMusicUpload",
      {
        userId: userId,
        fileName: fileName,
      },
      "Get signature for trebble user background music upload",
      false,
      false,
      true,
      requireCreatorSignup,
    );
  },

  getSignatureForTrebbleUserAudioUpload: function (fileName, userId, requireCreatorSignup) {
    return this._makeApiServerCall(
      "getSignatureForTrebbleUserAudioUpload",
      {
        userId: userId,
        fileName: fileName,
      },
      "Get signature for trebble user audio upload",
      false,
      false,
      true,
      requireCreatorSignup,
    );
  },

  getSignatureForTrebbleUserBackgroundMusicVideoUpload: function (fileName, userId, requireCreatorSignup) {
    return this._makeApiServerCall(
      "getSignatureForTrebbleUserBackgroundMusicVideoUpload",
      {
        userId: userId,
        fileName: fileName,
      },
      "Get signature for trebble user background music video upload",
      false,
      false,
      true,
      requireCreatorSignup,
    );
  },

  getSignatureForTrebbleUserVideoUpload: function (fileName, userId, requireCreatorSignup) {
    return this._makeApiServerCall(
      "getSignatureForTrebbleUserVideoUpload",
      {
        userId: userId,
        fileName: fileName,
      },
      "Get signature for trebble user video upload",
      false,
      false,
      true,
      requireCreatorSignup,
    );
  },

  getSignatureForTrebbleUserClipUpload: function (fileName, userId, requireCreatorSignup) {
    return this._makeApiServerCall(
      "getSignatureForTrebbleUserClipUpload",
      {
        userId: userId,
        fileName: fileName,
      },
      "Get signature for trebble user clip upload",
      false,
      false,
      true,
      requireCreatorSignup,
    );
  },

  getSignatureForTrebbleUserAudioRecordingUpload: function (fileName, userId, requireCreatorSignup) {
    return this._makeApiServerCall(
      "getSignatureForTrebbleUserAudioRecordingUpload",
      {
        userId: userId,
        fileName: fileName,
      },
      "Get signature for trebble user audio recording upload",
      false,
      false,
      true,
      requireCreatorSignup,
    );
  },

  getSignatureForTrebbleGreaterUpload: function (fileName, trebbleId, requireCreatorSignup) {
    return this._makeApiServerCall(
      "getSignatureForTrebbleGreaterUpload",
      {
        trebbleId: trebbleId,
        fileName: fileName,
      },
      "Get signature for trebble greater upload",
      false,
      false,
      true,
      requireCreatorSignup,
    );
  },

  getSignatureForTrebbleIntroUpload: function (fileName, trebbleId, requireCreatorSignup) {
    return this._makeApiServerCall(
      "getSignatureForTrebbleIntroUpload",
      {
        trebbleId: trebbleId,
        fileName: fileName,
      },
      "Get signature for trebble intro upload",
      false,
      false,
      true,
      requireCreatorSignup,
    );
  },

  getSignatureForTrebbleOutroUpload: function (fileName, trebbleId, requireCreatorSignup) {
    return this._makeApiServerCall(
      "getSignatureForTrebbleOutroUpload",
      {
        trebbleId: trebbleId,
        fileName: fileName,
      },
      "Get signature for trebble outro upload",
      false,
      false,
      true,
      requireCreatorSignup,
    );
  },

  getSignatureForSequencerProjectTextTranscriptionUpload: function (fileName, userId, requireCreatorSignup) {
    return this._makeApiServerCall(
      "getSignatureForSequencerProjectTextTranscriptionUpload",
      {
        userId: userId,
        fileName: fileName,
      },
      "Get signature for sequencer project txt transcription upload",
      false,
      false,
      true,
      requireCreatorSignup,
    );
  },

  updateRadioCoverArtByRadioId: function (completeImageFileUrl, trebbleId) {
    return this._makeApiServerCall(
      "updateRadioCoverArtByRadioId",
      {
        radioId: trebbleId,
        coverArtUrl: completeImageFileUrl,
      },
      "Update cover art for trebble",
    );
  },

  updateJourneyCoverArtByRadioId: function (completeImageFileUrl, journeyId) {
    return this._makeApiServerCall(
      "updateJourneyCoverArtByRadioId",
      {
        journeyId: journeyId,
        coverArtUrl: completeImageFileUrl,
      },
      "Update cover art for journey",
    );
  },

  hasUserLeftReviewOnJourney: function (radioId) {
    return this._makeApiServerCall(
      "hasUserLeftReviewOnJourney",
      {
        radioId: radioId,
      },
      "Has user left review on journey",
    );
  },

  canUserSubmitAJourneyReview: function (radioId) {
    return this._makeApiServerCall(
      "canUserSubmitAJourneyReview",
      {
        radioId: radioId,
      },
      "Can User Submit A Journey Review",
    );
  },

  getUserReviewOnJourney: function (radioId) {
    return this._makeApiServerCall(
      "getUserReviewOnJourney",
      {
        radioId: radioId,
      },
      "Get user left review on journey",
    );
  },

  createUserJourneyReview: function (radioId, journeyId, textReview, numberOfStars) {
    return this._makeApiServerCall(
      "createUserJourneyReview",
      {
        radioId: radioId,
        journeyId: journeyId,
        textReview: textReview,
        numberOfStars: numberOfStars,
      },
      "create user journey review",
    );
  },

  updateUserJourneyReview: function (reviewId, textReview, numberOfStars) {
    return this._makeApiServerCall(
      "updateUserJourneyReview",
      {
        reviewId: reviewId,
        textReview: textReview,
        numberOfStars: numberOfStars,
      },
      "update user journey review",
    );
  },

  setJingleToMyDefaultPersonalRadio: function (jingleUrl, trebbleId, duration) {
    return this._makeApiServerCall(
      "setJingleToMyDefaultPersonalRadio",
      {
        radioUID: trebbleId,
        jingleUri: jingleUrl,
        duration: duration,
      },
      "Update Jingle On Default Trebble",
    );
  },

  setGreaterToMyDefaultPersonalRadio: function (greaterUrl, trebbleId, duration) {
    return this._makeApiServerCall(
      "setGreaterToMyDefaultPersonalRadio",
      {
        radioUID: trebbleId,
        greaterUri: greaterUrl,
        duration: duration,
      },
      "Update Greater On Default Trebble",
    );
  },

  setIntroToMyDefaultPersonalRadio: function (introUrl, trebbleId, duration) {
    return this._makeApiServerCall(
      "setIntroToMyDefaultPersonalRadio",
      {
        radioUID: trebbleId,
        introUri: introUrl,
        duration: duration,
      },
      "Update Intro On Default Trebble",
    );
  },

  setOutroToMyDefaultPersonalRadio: function (outroUrl, trebbleId, duration) {
    return this._makeApiServerCall(
      "setOutroToMyDefaultPersonalRadio",
      {
        radioUID: trebbleId,
        outroUri: outroUrl,
        duration: duration,
      },
      "Update Outro On Default Trebble",
    );
  },

  getJingleForMyDefaultPersonalRadio: function (trebbleId) {
    return this._makeApiServerCall(
      "getJingleForMyDefaultPersonalRadio",
      {
        radioUID: trebbleId,
      },
      "Retrieve Jingle On Default Trebble",
    );
  },

  getGreaterForMyDefaultPersonalRadio: function (trebbleId) {
    return this._makeApiServerCall(
      "getGreaterForMyDefaultPersonalRadio",
      {
        radioUID: trebbleId,
      },
      "Retrieve Greater On Default Trebble",
    );
  },

  getIntroForMyDefaultPersonalRadio: function (trebbleId) {
    return this._makeApiServerCall(
      "getIntroForMyDefaultPersonalRadio",
      {
        radioUID: trebbleId,
      },
      "Retrieve Intro On Default Trebble",
    );
  },

  getOutroForMyDefaultPersonalRadio: function (trebbleId) {
    return this._makeApiServerCall(
      "getOutroForMyDefaultPersonalRadio",
      {
        radioUID: trebbleId,
      },
      "Retrieve Outro On Default Trebble",
    );
  },

  unsetCurrentJingleFromMyDefaultPersonalRadio: function (trebbleId) {
    return this._makeApiServerCall(
      "disableJingleOnMyDefaultPersonalRadio",
      {
        radioUID: trebbleId,
      },
      "Unset Jingle On Default Trebble",
    );
  },

  unsetCurrentGreaterFromMyDefaultPersonalRadio: function (trebbleId) {
    return this._makeApiServerCall(
      "disableGreaterOnMyDefaultPersonalRadio",
      {
        radioUID: trebbleId,
      },
      "Unset Greater On Default Trebble",
    );
  },

  unsetCurrentIntroFromMyDefaultPersonalRadio: function (trebbleId) {
    return this._makeApiServerCall(
      "disableIntroOnMyDefaultPersonalRadio",
      {
        radioUID: trebbleId,
      },
      "Unset Intro On Default Trebble",
    );
  },

  unsetCurrentOutroFromMyDefaultPersonalRadio: function (trebbleId) {
    return this._makeApiServerCall(
      "disableOutroOnMyDefaultPersonalRadio",
      {
        radioUID: trebbleId,
      },
      "Unset Outro On Default Trebble",
    );
  },

  deleteCurrentJingleFromMyDefaultPersonalRadio: function (trebbleId) {
    return this._makeApiServerCall(
      "deleteCurrentJingleOnMyDefaultPersonalRadio",
      {
        radioUID: trebbleId,
      },
      "Delete Current Jingle On Default Trebble",
    );
  },

  deleteCurrentGreaterFromMyDefaultPersonalRadio: function (trebbleId) {
    return this._makeApiServerCall(
      "deleteCurrentGreaterOnMyDefaultPersonalRadio",
      {
        radioUID: trebbleId,
      },
      "Delete Current Greater On Default Trebble",
    );
  },

  deleteCurrentIntroFromMyDefaultPersonalRadio: function (trebbleId) {
    return this._makeApiServerCall(
      "deleteCurrentIntroOnMyDefaultPersonalRadio",
      {
        radioUID: trebbleId,
      },
      "Delete Current Intro On Default Trebble",
    );
  },

  deleteCurrentOutroFromMyDefaultPersonalRadio: function (trebbleId) {
    return this._makeApiServerCall(
      "deleteCurrentOutroOnMyDefaultPersonalRadio",
      {
        radioUID: trebbleId,
      },
      "Delete Current Outro On Default Trebble",
    );
  },

  getSignatureForUserProfileImageUpload: function (fileName) {
    return this._makeApiServerCall(
      "getSignatureForUserProfileImageUpload",
      {
        fileName: fileName,
      },
      "Get signature for user profile image upload",
    );
  },

  updateUserProfileImage: function (completeImageFileUrl) {
    return this._makeApiServerCall(
      "updateUserProfileImage",
      {
        profileImageUrl: completeImageFileUrl,
      },
      "Update user profile image",
    );
  },

  updateUserSetTimeForNotification: function (timeHour, timezone) {
    return this._makeApiServerCall(
      "updateUserSetTimeForNotification",
      {
        timeHour: timeHour,
        timezone: timezone,
      },
      "Update user set time for daily digest",
    );
  },

  markSongCommentsAsNotified: function (songJson, context, songRefSummary) {
    return this._makeApiServerCall(
      "markSongCommentsAsNotifiedByLoggedInUser",
      {
        songJson: songJson,
        songRefSummary: songRefSummary,
        context: context,
      },
      "Mark comments for song as notified",
    );
  },

  markSongCommentsAsConsumed: function (songJson, context, songRefSummary) {
    return this._makeApiServerCall(
      "markSongCommentsAsConsumedByLoggedInUser",
      {
        songJson: songJson,
        songRefSummary: songRefSummary,
        context: context,
      },
      "Mark comments for song as consumed",
    );
  },

  markCapsuleCommentsAsConsumed: function (capsuleId, context, songRefSummary) {
    return this._makeApiServerCall(
      "markCapsuleCommentsAsConsumedByLoggedInUser",
      {
        capsuleId: capsuleId,
        songRefSummary: songRefSummary,
        context: context,
      },
      "Mark comments for song as consumed",
    );
  },

  markCommentRepliesAsConsumed: function (commentId, context, songRefSummary) {
    return this._makeApiServerCall(
      "markCommentRepliesAsConsumedByLoggedInUser",
      {
        commentId: commentId,
        context: context,
      },
      "Mark comment replies as consumed",
    );
  },

  searchForUsers: function (keyword, numberOfResults, pageOffset) {
    return this._makeApiServerCall(
      "searchForUsers",
      {
        keyword: keyword,
        numberOfResults: numberOfResults,
        pageOffset: pageOffset,
      },
      "Search for Users matching keyword",
    );
  },

  searchForMyDraftJourneys: function (searchKeyword, numberOfResults, pageOffset) {
    return this._makeApiServerCall(
      "searchForMyDraftJourneys",
      {
        searchKeyword: searchKeyword,
        numberOfResults: numberOfResults,
        pageOffset: pageOffset,
      },
      "Search for my draft journeys matching keyword",
    );
  },

  searchForBigBankSounds: function (searchKeyword, numberOfResults, pageOffset) {
    return this._makeApiServerCall(
      "searchForBigBankSounds",
      {
        searchKeyword: searchKeyword,
        numberOfResults: numberOfResults,
        pageOffset: pageOffset,
      },
      "Search for big bank sounds matching keyword",
    );
  },

  searchForTrebbles: function (keyword, numberOfResults, pageOffset) {
    return this._makeApiServerCall(
      "searchForTrebbles",
      {
        keyword: keyword,
        numberOfResults: numberOfResults,
        pageOffset: pageOffset,
      },
      "Search for Trebbles matching keyword",
    );
  },

  searchForJourneys: function (keyword, numberOfResults, pageOffset) {
    return this._makeApiServerCall(
      "searchForJourneys",
      {
        keyword: keyword,
        numberOfResults: numberOfResults,
        pageOffset: pageOffset,
      },
      "Search for Journeys matching keyword",
    );
  },

  getAllAvailableJourneysCreatedByUserWithId: function (userId) {
    return this._makeApiServerCall(
      "getAllAvailableJourneysCreatedByUserWithId",
      {
        userId: userId,
      },
      "Get all journeys created by user with id",
    );
  },

  retrieveAllTrebbleGenres: function (searchOnTrebbleWithNoPreview) {
    return this._makeApiServerCall(
      "retrieveAllGenres",
      {
        searchOnTrebbleWithNoPreview: searchOnTrebbleWithNoPreview,
      },
      "Retrieve All Trebble ' Genres",
    );
  },

  retrieveTrebbleByGenres: function (genre, maxNumberOfResults, pageOffset) {
    return this._makeApiServerCall(
      "retrieveTrebblesByGenre",
      {
        genre: genre,
        numberOfResults: maxNumberOfResults,
        pageOffset: pageOffset,
      },
      "Retrieve Trebble By Genres",
    );
  },

  retrieveTrebbleByCategory: function (category, maxNumberOfResults) {
    return this._makeApiServerCall(
      "retrieveTrebbleByCategory",
      {
        category: category,
        numberOfResults: maxNumberOfResults,
      },
      "Retrieve Trebble By Category",
    );
  },

  retrieveTrebbleByCity: function (cityId) {
    return this._makeApiServerCall(
      "retrieveTrebbleByCity",
      {
        cityId: cityId,
      },
      "Retrieve Trebble By City",
    );
  },

  retrieveTrebbleByCategoryAndLanguage: function (category, language, maxNumberOfResults, pageOffset) {
    return this._makeApiServerCall(
      "retrieveTrebbleByCategoryAndLanguage",
      {
        category: category,
        language: language,
        numberOfResults: maxNumberOfResults,
        pageOffset: pageOffset,
      },
      "Retrieve Trebble By Category And Language",
    );
  },

  retrieveTrebbleByCityAndLanguage: function (cityId, language, numberOfResults, pageOffset) {
    return this._makeApiServerCall(
      "retrieveTrebbleByCityAndLanguage",
      {
        cityId: cityId,
        language: language,
        numberOfResults: numberOfResults,
        pageOffset: pageOffset,
      },
      "Retrieve Trebble By City And Language",
    );
  },

  postComment: function (songJson, songRefSummary, commentToPost, context) {
    const params = {};
    params.songJson = songJson;
    params.comment = commentToPost;
    params.songRefSummary = songRefSummary;
    params.context = context;
    return this._makeApiServerCall("commentOnSong", params, "Comment on song");
  },

  commentOnCapsule: function (capsuleId, songRefSummary, commentToPost, context) {
    const params = {};
    params.capsuleRefId = capsuleId;
    params.comment = commentToPost;
    params.songRefSummary = songRefSummary;
    params.context = context;
    return this._makeApiServerCall("commentOnSong", params, "Comment on song");
  },

  postReply: function (commentId, commentToPost, context) {
    const params = {};
    params.commentRefId = commentId;
    params.comment = commentToPost;
    params.context = context;
    return this._makeApiServerCall("commentOnSong", params, "Post a comment reply");
  },

  likeComment: function (commentId) {
    const params = {};
    params.commentId = commentId;
    return this._makeApiServerCall("likeComment", params, "Like comment");
  },

  unlikeComment: function (commentId) {
    const params = {};
    params.commentId = commentId;
    return this._makeApiServerCall("unlikeComment", params, "Unlike comment");
  },

  updateCapsule: function (
    capsuleId,
    audioPartsInfo,
    title,
    comment,
    imageArtUrl,
    context,
    capsuleReceiverId,
    wasTranscribedAndEditedInTrebble,
    hqAudioInfo,
    projectId,
    videoInfo,
    audioInfo
  ) {
    const params = {};
    params.capsuleId = capsuleId;
    params.audioPartsInfo = audioPartsInfo;
    params.title = title;
    params.comment = comment;
    params.imageArtUrl = imageArtUrl;
    params.context = context;
    params.capsuleReceiverId = capsuleReceiverId;
    params.wasTranscribedAndEditedInTrebble = wasTranscribedAndEditedInTrebble;
    params.hqAudioInfo = hqAudioInfo;
    params.projectId = projectId;
    params.videoInfo = videoInfo;
    params.audioInfo = audioInfo;
    return this._makeApiServerCall("updateSongCapsule", params, "Update Capsule", false, false, true);
  },

  createSongCapsule: function (
    songJson,
    capsuleId,
    audioPartsInfo,
    lifeSpanInDays,
    title,
    comment,
    imageArtUrl,
    backgroundMusicUri,
    doNotTriggerAudioProcessing,
    categoryId,
    isPrivate,
    context,
    capsuleReceiverId,
    addToRadioWitIds,
    doNotAddOnAir,
    isScheduled,
    scheduledStartDate,
    timezone,
    language,
    textToConvertToAudio,
    voiceToUseForTTS,
    ttsLanguage,
    narrationParams,
    wasTranscribedAndEditedInTrebble,
    hqAudioInfo,
    projectId,
    videoInfo,
  ) {
    const params = {};
    params.songJson = songJson;
    params.capsuleRefId = capsuleId;
    params.audioPartsInfo = audioPartsInfo;
    params.lifeSpanInDays = lifeSpanInDays;
    params.title = title;
    params.comment = comment;
    params.imageArtUrl = imageArtUrl;
    params.backgroundMusicUri = backgroundMusicUri;
    params.categoryId = categoryId;
    params.capsuleReceiverId = capsuleReceiverId;
    params.doNotTriggerAudioProcessing = doNotTriggerAudioProcessing;
    params.private = isPrivate;
    params.context = context;
    params.addToRadioWitIds = addToRadioWitIds;
    params.doNotAddOnAir = doNotAddOnAir;
    params.isScheduled = isScheduled;
    params.scheduledStartDate = scheduledStartDate;
    params.timezone = timezone;
    params.language = language;
    params.textToConvertToAudio = textToConvertToAudio;
    params.voiceToUseForTTS = voiceToUseForTTS;
    params.ttsLanguage = ttsLanguage;
    params.narrationParams = narrationParams;
    params.wasTranscribedAndEditedInTrebble = wasTranscribedAndEditedInTrebble;
    params.hqAudioInfo = hqAudioInfo;
    params.projectId = projectId;
    params.videoInfo = videoInfo;
    return this._makeApiServerCall("createSongCapsule", params, "Create Capsule", false, false, true);
  },

  deleteSongCapsule: function (capsuleId) {
    const params = {};
    params.capsuleUID = capsuleId;
    return this._makeApiServerCall("deleteCapsule", params, "Delete song capsule");
  },

  updateSongCapsule: function (
    capsuleId,
    title,
    notes,
    updateLifeSpan,
    numberOfDaysToLive,
    makeActive,
    updateCategoryId,
    newCategoryId,
    isPrivate,
  ) {
    const params = {};
    params.capsuleId = capsuleId;
    params.title = title;
    params.comment = notes;
    params.updateExpirationDate = updateLifeSpan;
    params.numberOfDaysToLive = numberOfDaysToLive;
    params.setAsActiveCapsule = makeActive;
    params.updateCategoryId = updateCategoryId;
    params.private = isPrivate;
    params.categoryId = newCategoryId;
    return this._makeApiServerCall("updateCapsuleInfo", params, "Update song capsule info", false, false, true);
  },

  _makeApiServerCall: function (
    apiName,
    params,
    actionLogName,
    noLogin,
    doNotSendAnalytics,
    forceLoginIfGuest,
    forceSignUpAsCreator,
  ) {
    const apiSelf = this;
    const startTime = new Date().getTime();
    const resolverFunc = function (resolve, reject) {
      $.post(window.trebble.usedProtocolPrefix + trebbleServerUrl + "/" + apiName, params)
        .done(function (result) {
          apiSelf._getDefaultResponseHandler(result, resolve, reject, undefined, actionLogName);
          const endTime = new Date().getTime();
          if (!doNotSendAnalytics && window.trebbleAnalyticsHelper && ENABLE_BACKEND_CALL_ANAYLYTICS_TRACKING) {
            window.trebbleAnalyticsHelper.trackEvent("backendCall", apiName, actionLogName);
            window.trebbleAnalyticsHelper.trackTiming("Server API Call", endTime - startTime, "Success", actionLogName);
          }
        })
        .fail(function (errorObj) {
          apiSelf._getDefaultFailedResponseHandler(errorObj, reject, undefined, actionLogName);
          const endTime = new Date().getTime();
          if (!doNotSendAnalytics && window.trebbleAnalyticsHelper && ENABLE_BACKEND_CALL_ANAYLYTICS_TRACKING) {
            window.trebbleAnalyticsHelper.trackEvent("backendCall", apiName, actionLogName, -1);
            window.trebbleAnalyticsHelper.trackTiming(
              "Server API Call",
              endTime - startTime,
              "Error" + errorObj,
              actionLogName,
            );
          }
        });
    }.bind(this);
    if (noLogin) {
      return new RSVP.Promise(resolverFunc);
    } else {
      window.log("Login for " + actionLogName);
      return this._doLogin(false, forceLoginIfGuest, forceSignUpAsCreator).then(function () {
        return new RSVP.Promise(resolverFunc);
      });
    }
  },

  _makeApiGetServerCall: function (
    apiName,
    params,
    actionLogName,
    noLogin,
    doNotSendAnalytics,
    forceLoginIfGuest,
    forceSignUpAsCreator,
  ) {
    const apiSelf = this;
    const startTime = new Date().getTime();
    const resolverFunc = function (resolve, reject) {
      $.get(window.trebble.usedProtocolPrefix + trebbleServerUrl + "/" + apiName, params)
        .done(function (result) {
          apiSelf._getDefaultResponseHandler(result, resolve, reject, undefined, actionLogName);
          const endTime = new Date().getTime();
          if (!doNotSendAnalytics && window.trebbleAnalyticsHelper && ENABLE_BACKEND_CALL_ANAYLYTICS_TRACKING) {
            window.trebbleAnalyticsHelper.trackEvent("backendCall", apiName, actionLogName);
            window.trebbleAnalyticsHelper.trackTiming("Server API Call", endTime - startTime, "Success", actionLogName);
          }
        })
        .fail(function (errorObj) {
          apiSelf._getDefaultFailedResponseHandler(errorObj, reject, undefined, actionLogName);
          const endTime = new Date().getTime();
          if (!doNotSendAnalytics && window.trebbleAnalyticsHelper && ENABLE_BACKEND_CALL_ANAYLYTICS_TRACKING) {
            window.trebbleAnalyticsHelper.trackEvent("backendCall", apiName, actionLogName, -1);
            window.trebbleAnalyticsHelper.trackTiming(
              "Server API Call",
              endTime - startTime,
              "Error" + errorObj,
              actionLogName,
            );
          }
        });
    }.bind(this);
    if (noLogin) {
      return new RSVP.Promise(resolverFunc);
    } else {
      window.log("Login for " + actionLogName);
      return this._doLogin(false, forceLoginIfGuest, forceSignUpAsCreator).then(function () {
        return new RSVP.Promise(resolverFunc);
      });
    }
  },

  _makeApiPlayTrebbleCall: function (apiName, params, actionLogName, noLogin, doNotSendAnalytics) {
    const apiSelf = this;
    const startTime = new Date().getTime();
    const resolverFunc = function (resolve, reject) {
      $.ajax({
        url: httpOrHttpsPrefix + playTrebbleServerUrl + "/" + apiName,
        type: "POST",
        data: JSON.stringify(params),
        contentType: "application/json; charset=utf-8",
        dataType: "json",
      })
        .done(function (result) {
          apiSelf._getDefaultResponseHandler(result, resolve, reject, undefined, actionLogName);
          const endTime = new Date().getTime();
          if (!doNotSendAnalytics && window.trebbleAnalyticsHelper && ENABLE_BACKEND_CALL_ANAYLYTICS_TRACKING) {
            window.trebbleAnalyticsHelper.trackEvent("backendCall", apiName, actionLogName);
            window.trebbleAnalyticsHelper.trackTiming("Server API Call", endTime - startTime, "Success", actionLogName);
          }
        })
        .fail(function (errorObj) {
          apiSelf._getDefaultFailedResponseHandler(errorObj, reject, undefined, actionLogName);
          const endTime = new Date().getTime();
          if (!doNotSendAnalytics && window.trebbleAnalyticsHelper && ENABLE_BACKEND_CALL_ANAYLYTICS_TRACKING) {
            window.trebbleAnalyticsHelper.trackEvent("backendCall", apiName, actionLogName, -1);
            window.trebbleAnalyticsHelper.trackTiming(
              "Server API Call",
              endTime - startTime,
              "Error" + errorObj,
              actionLogName,
            );
          }
        });
    }.bind(this);
    if (noLogin) {
      return new RSVP.Promise(resolverFunc);
    } else {
      return this._doLogin().then(function () {
        return new RSVP.Promise(resolverFunc);
      });
    }
  },

  generatePlaylistFromSongBasedOnAllUsersData: function (song) {
    const apiSelf = this;
    let endTime = null;
    const resolverFunc = function (resolve, reject) {
      const startTime = new Date().getTime();
      $.post(window.trebble.usedProtocolPrefix + trebbleServerUrl + "/generatePlaylistFromSongBasedOnAllUsers", {
        songURI: song.uri,
      })
        .done(function (response) {
          try {
            const arrayOfSongs = response.message;
            const result = {};
            result.songs = arrayOfSongs;
            result.albums = apiSelf._getAlbumsArrayFromSongsJson(arrayOfSongs);
            result.artists = apiSelf._getArtistArrayFromAlbumsJson(result.albums);
            response.message = arrayOfSongs;
            apiSelf._getDefaultResponseHandler(
              response,
              resolve,
              reject,
              undefined,
              "Get list of  similar songs from othes users catalog",
            );
            endTime = new Date().getTime();
            if (window.trebbleAnalyticsHelper && ENABLE_BACKEND_CALL_ANAYLYTICS_TRACKING) {
              window.trebbleAnalyticsHelper.trackEvent(
                "backendCall",
                "generatePlaylistFromSongBasedOnAllUsers",
                "Get list of  similar songs from othes users catalog",
              );
              window.trebbleAnalyticsHelper.trackTiming(
                "Server API Call",
                endTime - startTime,
                "Success",
                "Get list of  similar songs from othes users catalog",
              );
            }
          } catch (error) {
            apiSelf._getDefaultFailedResponseHandler(
              error,
              reject,
              undefined,
              "Get list of  similar songs from othes users catalog",
            );
            endTime = new Date().getTime();
            if (window.trebbleAnalyticsHelper && ENABLE_BACKEND_CALL_ANAYLYTICS_TRACKING) {
              window.trebbleAnalyticsHelper.trackEvent(
                "backendCall",
                "generatePlaylistFromSongBasedOnAllUsers",
                "Get list of  similar songs from othes users catalog",
                -1,
              );
              window.trebbleAnalyticsHelper.trackTiming(
                "Server API Call",
                endTime - startTime,
                "Error" + error,
                "Get list of  similar songs from othes users catalog",
              );
            }
          }
        })
        .fail(function (error) {
          apiSelf._getDefaultFailedResponseHandler(
            error,
            reject,
            undefined,
            "Get list of  similar songs from othes users catalog",
          );
        });
    }.bind(this);

    return this._doLogin().then(function () {
      return new RSVP.Promise(resolverFunc);
    });
  },

  generatePlaylistOfSimilarSongs: function (song, allowSongOutsideLibrary) {
    const apiSelf = this;
    let endTime = null;
    const startTime = new Date().getTime();
    const resolverFunc = function (resolve, reject) {
      $.post(window.trebble.usedProtocolPrefix + trebbleServerUrl + "/generatePlaylistOfSimilarSongs", {
        songURI: song.uri,
        allowSongsOutsideCatalog: allowSongOutsideLibrary,
      })
        .done(function (response) {
          try {
            const arrayOfSongs = response.message;
            const result = {};
            result.songs = arrayOfSongs;
            result.albums = apiSelf._getAlbumsArrayFromSongsJson(arrayOfSongs);
            result.artists = apiSelf._getArtistArrayFromAlbumsJson(result.albums);
            response.message = arrayOfSongs;
            apiSelf._getDefaultResponseHandler(response, resolve, reject, undefined, "Get list of similar songs");
            endTime = new Date().getTime();
            if (window.trebbleAnalyticsHelper && ENABLE_BACKEND_CALL_ANAYLYTICS_TRACKING) {
              window.trebbleAnalyticsHelper.trackEvent(
                "backendCall",
                "generatePlaylistOfSimilarSongs",
                "Get list of similar songs",
              );
              window.trebbleAnalyticsHelper.trackTiming(
                "Server API Call",
                endTime - startTime,
                "Success",
                "Get list of similar songs",
              );
            }
          } catch (error) {
            apiSelf._getDefaultFailedResponseHandler(error, reject, undefined, "Get list of similar songs");
            endTime = new Date().getTime();
            if (window.trebbleAnalyticsHelper && ENABLE_BACKEND_CALL_ANAYLYTICS_TRACKING) {
              window.trebbleAnalyticsHelper.trackEvent(
                "backendCall",
                "generatePlaylistOfSimilarSongs",
                "Get list of similar songs",
                -1,
              );
              window.trebbleAnalyticsHelper.trackTiming(
                "Server API Call",
                endTime - startTime,
                "Error" + error,
                "Get list of similar songs",
              );
            }
          }
        })
        .fail(function (error) {
          apiSelf._getDefaultFailedResponseHandler(error, reject, undefined, "Get list of similar songs");
        });
    }.bind(this);

    return this._doLogin().then(function () {
      return new RSVP.Promise(resolverFunc);
    });
  },

  searchForSongsAlbumsArtists: function (keyword, numberOfResults, songWithAudioPreviewOnly) {
    const apiSelf = this;
    const startTime = new Date().getTime();
    let endTime = null;
    const resolverFunc = function (resolve, reject) {
      $.post(window.trebble.usedProtocolPrefix + trebbleServerUrl + "/searchForTracksAlbumsArtists", {
        searchQuery: keyword,
        numberOfResults: numberOfResults,
        songWithAudioPreviewOnly: songWithAudioPreviewOnly,
      })
        .done(function (response) {
          try {
            const results = response.message;
            const arrayOfSongs = results.songs;
            const albumsFromSongs = apiSelf._getAlbumsArrayFromSongsJson(arrayOfSongs);
            const artistsFromSongs = apiSelf._getArtistArrayFromAlbumsJson(albumsFromSongs);
            results.songs = arrayOfSongs;
            apiSelf._getDefaultResponseHandler(
              response,
              resolve,
              reject,
              undefined,
              "Searching for songs , albums and artists",
            );
            endTime = new Date().getTime();
            if (window.trebbleAnalyticsHelper && ENABLE_BACKEND_CALL_ANAYLYTICS_TRACKING) {
              window.trebbleAnalyticsHelper.trackEvent(
                "backendCall",
                "searchForTracksAlbumsArtists",
                "Searching for songs , albums and artists",
              );
              window.trebbleAnalyticsHelper.trackTiming(
                "Server API Call",
                endTime - startTime,
                "Success",
                "Searching for songs , albums and artists",
              );
            }
          } catch (error) {
            apiSelf._getDefaultFailedResponseHandler(
              error,
              reject,
              undefined,
              "Searching for songs , albums and artists",
            );
            endTime = new Date().getTime();
            if (window.trebbleAnalyticsHelper && ENABLE_BACKEND_CALL_ANAYLYTICS_TRACKING) {
              window.trebbleAnalyticsHelper.trackEvent(
                "backendCall",
                "searchForTracksAlbumsArtists",
                "Searching for songs , albums and artists",
                -1,
              );
              window.trebbleAnalyticsHelper.trackTiming(
                "Server API Call",
                endTime - startTime,
                "Error" + error,
                "Searching for songs , albums and artists",
              );
            }
          }
        })
        .fail(function (error) {
          apiSelf._getDefaultFailedResponseHandler(
            error,
            reject,
            undefined,
            "Searching for songs , albums and artists",
          );
        });
    }.bind(this);

    return this._doLogin().then(function () {
      return new RSVP.Promise(resolverFunc);
    });
  },

  searchForAllSongs: function (keyword, numberOfResults, startPage, advancedQuery, songWithAudioPreviewOnly) {
    const apiSelf = this;
    const startTime = new Date().getTime();
    let endTime = null;
    const resolverFunc = function (resolve, reject) {
      $.post(window.trebble.usedProtocolPrefix + trebbleServerUrl + "/searchForTracks", {
        searchQuery: keyword,
        numberOfResults: numberOfResults,
        startPage: startPage,
        advancedQuery: advancedQuery,
        songWithAudioPreviewOnly: songWithAudioPreviewOnly,
      })
        .done(function (response) {
          try {
            const results = response.message;
            const arrayOfSongs = results.songs;
            const albumsFromSongs = apiSelf._getAlbumsArrayFromSongsJson(arrayOfSongs);
            const artistsFromSongs = apiSelf._getArtistArrayFromAlbumsJson(albumsFromSongs);
            results.songs = arrayOfSongs;
            apiSelf._getDefaultResponseHandler(
              response,
              resolve,
              reject,
              undefined,
              "Searching for all songs for keyword ",
            );
            endTime = new Date().getTime();
            if (window.trebbleAnalyticsHelper && ENABLE_BACKEND_CALL_ANAYLYTICS_TRACKING) {
              window.trebbleAnalyticsHelper.trackEvent(
                "backendCall",
                "searchForTracks",
                "Searching for all songs for keyword ",
              );
              window.trebbleAnalyticsHelper.trackTiming(
                "Server API Call",
                endTime - startTime,
                "Success",
                "Searching for all songs for keyword ",
              );
            }
          } catch (error) {
            apiSelf._getDefaultFailedResponseHandler(error, reject, undefined, "Searching for all songs for keyword ");
            endTime = new Date().getTime();
            if (window.trebbleAnalyticsHelper && ENABLE_BACKEND_CALL_ANAYLYTICS_TRACKING) {
              window.trebbleAnalyticsHelper.trackEvent(
                "backendCall",
                "searchForTracks",
                "Searching for all songs for keyword ",
                -1,
              );
              window.trebbleAnalyticsHelper.trackTiming(
                "Server API Call",
                endTime - startTime,
                "Error" + error,
                "Searching for all songs for keyword ",
              );
            }
          }
        })
        .fail(function (error) {
          apiSelf._getDefaultFailedResponseHandler(error, reject, undefined, "Searching for all songs for keyword ");
        });
    }.bind(this);

    return this._doLogin().then(function () {
      return new RSVP.Promise(resolverFunc);
    });
  },

  getTopTracksByArtistIdOrName: function (artistId, artistName) {
    const apiSelf = this;
    const startTime = new Date().getTime();
    let endTime = null;
    const resolverFunc = function (resolve, reject) {
      $.post(window.trebble.usedProtocolPrefix + trebbleServerUrl + "/getTopTracksByArtistIdOrName", {
        artistId: artistId,
        artistName: artistName,
      })
        .done(function (response) {
          try {
            const results = response.message;
            const arrayOfSongs = results;
            const albumsFromSongs = apiSelf._getAlbumsArrayFromSongsJson(arrayOfSongs);
            const artistsFromSongs = apiSelf._getArtistArrayFromAlbumsJson(albumsFromSongs);
            response.message = arrayOfSongs;
            apiSelf._getDefaultResponseHandler(response, resolve, reject, undefined, "Get Top Tracks For Artist ");
            endTime = new Date().getTime();
            if (window.trebbleAnalyticsHelper && ENABLE_BACKEND_CALL_ANAYLYTICS_TRACKING) {
              window.trebbleAnalyticsHelper.trackEvent(
                "backendCall",
                "getTopTracksByArtistIdOrName",
                "Get Top Tracks For Artist ",
              );
              window.trebbleAnalyticsHelper.trackTiming(
                "Server API Call",
                endTime - startTime,
                "Success",
                "Get Top Tracks For Artist ",
              );
            }
          } catch (error) {
            apiSelf._getDefaultFailedResponseHandler(error, reject, undefined, "Get Top Tracks For Artist ");
            endTime = new Date().getTime();
            if (window.trebbleAnalyticsHelper && ENABLE_BACKEND_CALL_ANAYLYTICS_TRACKING) {
              window.trebbleAnalyticsHelper.trackEvent(
                "backendCall",
                "getTopTracksByArtistIdOrName",
                "Get Top Tracks For Artist ",
                -1,
              );
              window.trebbleAnalyticsHelper.trackTiming(
                "Server API Call",
                endTime - startTime,
                "Error" + error,
                "Get Top Tracks For Artist ",
              );
            }
          }
        })
        .fail(function (error) {
          apiSelf._getDefaultFailedResponseHandler(error, reject, undefined, "Searching for all songs for keyword ");
        });
    }.bind(this);

    return this._doLogin().then(function () {
      return new RSVP.Promise(resolverFunc);
    });
  },

  getArtistInfoByArtistIdOrName: function (artistId, artistName) {
    const params = {};
    params.artistId = artistId;
    params.artistName = artistName;
    return this._makeApiServerCall(
      "getArtistInfoByArtistIdOrName",
      params,
      "Get Artist Info By artist id or Arist name",
    );
  },

  getArtistBiographyInfoByArtistIdOrName: function (artistId, artistName) {
    const params = {};
    params.artistId = artistId;
    params.artistName = artistName;
    return this._makeApiServerCall(
      "getArtistBiographyInfoByArtistIdOrName",
      params,
      "Get Artist Biography By artist id or Arist name",
    );
  },

  getMyRecentNotificationCustomPayload: function (maxNumberOfResults) {
    const params = {};
    params.maxNumberOfResults = maxNumberOfResults;
    return this._makeApiServerCall(
      "getMyRecentCustomPayloadNotifiactions",
      params,
      "Get My Recent Notification Custom Payload",
    );
  },
  getAlbumByAlbumId: function (albumId) {
    const params = {};
    params.albumId = albumId;
    return this._makeApiServerCall("getAlbumByAlbumId", params, "Get Album By Album Id");
  },

  searchForAllAlbums: function (keyword, numberOfResults, startPage) {
    const apiSelf = this;
    const startTime = new Date().getTime();
    let endTime = null;
    const resolverFunc = function (resolve, reject) {
      $.post(window.trebble.usedProtocolPrefix + trebbleServerUrl + "/searchForAlbums", {
        searchQuery: keyword,
        numberOfResults: numberOfResults,
        startPage: startPage,
      })
        .done(function (response) {
          try {
            const results = response.message;
            const arrayOfAlbums = results.albums;
            const artistsFromAlbums = apiSelf._getArtistArrayFromAlbumsJson(arrayOfAlbums);
            results.albums = arrayOfAlbums;
            apiSelf._getDefaultResponseHandler(
              response,
              resolve,
              reject,
              undefined,
              "Searching for all albums for keyword ",
            );
            endTime = new Date().getTime();
            if (window.trebbleAnalyticsHelper && ENABLE_BACKEND_CALL_ANAYLYTICS_TRACKING) {
              window.trebbleAnalyticsHelper.trackEvent(
                "backendCall",
                "searchForAlbums",
                "Searching for all albums for keyword ",
              );
              window.trebbleAnalyticsHelper.trackTiming(
                "Server API Call",
                endTime - startTime,
                "Success",
                "Searching for all albums for keyword ",
              );
            }
          } catch (error) {
            apiSelf._getDefaultFailedResponseHandler(error, reject, undefined, "Searching for all albums for keyword ");
            endTime = new Date().getTime();
            if (window.trebbleAnalyticsHelper && ENABLE_BACKEND_CALL_ANAYLYTICS_TRACKING) {
              window.trebbleAnalyticsHelper.trackEvent(
                "backendCall",
                "searchForAlbums",
                "Searching for all albums for keyword ",
                -1,
              );
              window.trebbleAnalyticsHelper.trackTiming(
                "Server API Call",
                endTime - startTime,
                "Error" + error,
                "Searching for all albums for keyword ",
              );
            }
          }
        })
        .fail(function (error) {
          apiSelf._getDefaultFailedResponseHandler(error, reject, undefined, "Searching for all albums for keyword ");
        });
    }.bind(this);

    return this._doLogin().then(function () {
      return new RSVP.Promise(resolverFunc);
    });
  },

  getAlbumsForArtist: function (artistId, artistName, albumType, numberOfResults, offset) {
    const apiSelf = this;
    const startTime = new Date().getTime();
    let endTime = null;
    const resolverFunc = function (resolve, reject) {
      $.post(window.trebble.usedProtocolPrefix + trebbleServerUrl + "/getAlbumsForArtistByAlbumType", {
        artistId: artistId,
        artistName: artistName,
        numberOfResults: numberOfResults,
        albumType: albumType,
        offset: offset,
      })
        .done(function (response) {
          try {
            let results = response.message;
            const arrayOfAlbums = results;
            const artistsFromAlbums = apiSelf._getArtistArrayFromAlbumsJson(arrayOfAlbums);
            results = arrayOfAlbums;
            response.message = results;
            apiSelf._getDefaultResponseHandler(response, resolve, reject, undefined, "Get Albums for Artist ");
            endTime = new Date().getTime();
            if (window.trebbleAnalyticsHelper && ENABLE_BACKEND_CALL_ANAYLYTICS_TRACKING) {
              window.trebbleAnalyticsHelper.trackEvent(
                "backendCall",
                "getAlbumsForArtistByAlbumType",
                "Get Albums for Artist ",
              );
              window.trebbleAnalyticsHelper.trackTiming(
                "Server API Call",
                endTime - startTime,
                "Success",
                "Get Albums for Artist ",
              );
            }
          } catch (error) {
            apiSelf._getDefaultFailedResponseHandler(error, reject, undefined, "Get Albums for Artist ");
            endTime = new Date().getTime();
            if (window.trebbleAnalyticsHelper && ENABLE_BACKEND_CALL_ANAYLYTICS_TRACKING) {
              window.trebbleAnalyticsHelper.trackEvent(
                "backendCall",
                "getAlbumsForArtistByAlbumType",
                "Get Albums for Artist ",
                -1,
              );
              window.trebbleAnalyticsHelper.trackTiming(
                "Server API Call",
                endTime - startTime,
                "Error" + error,
                "Get Albums for Artist ",
              );
            }
          }
        })
        .fail(function (error) {
          apiSelf._getDefaultFailedResponseHandler(error, reject, undefined, "Get Albums for Artist ");
        });
    }.bind(this);

    return this._doLogin().then(function () {
      return new RSVP.Promise(resolverFunc);
    });
  },

  searchForAllArtists: function (keyword, numberOfResults, startPage) {
    const apiSelf = this;
    const startTime = new Date().getTime();
    let endTime = null;
    const resolverFunc = function (resolve, reject) {
      $.post(window.trebble.usedProtocolPrefix + trebbleServerUrl + "/searchForArtists", {
        searchQuery: keyword,
        numberOfResults: numberOfResults,
        startPage: startPage,
      })
        .done(function (response) {
          try {
            const results = response.message;
            const arrayOfArtists = results.artists;
            results.artists = arrayOfArtists;
            apiSelf._getDefaultResponseHandler(
              response,
              resolve,
              reject,
              undefined,
              "Searching for all artists for keyword ",
            );
            endTime = new Date().getTime();
            if (window.trebbleAnalyticsHelper && ENABLE_BACKEND_CALL_ANAYLYTICS_TRACKING) {
              window.trebbleAnalyticsHelper.trackEvent(
                "backendCall",
                "searchForArtists",
                "Searching for all artists for keyword ",
              );
              window.trebbleAnalyticsHelper.trackTiming(
                "Server API Call",
                endTime - startTime,
                "Success",
                "Searching for all artists for keyword ",
              );
            }
          } catch (error) {
            apiSelf._getDefaultFailedResponseHandler(
              error,
              reject,
              undefined,
              "Searching for all artists for keyword ",
            );
            endTime = new Date().getTime();
            if (window.trebbleAnalyticsHelper && ENABLE_BACKEND_CALL_ANAYLYTICS_TRACKING) {
              window.trebbleAnalyticsHelper.trackEvent(
                "backendCall",
                "searchForArtists",
                "Searching for all artists for keyword ",
                -1,
              );
              window.trebbleAnalyticsHelper.trackTiming(
                "Server API Call",
                endTime - startTime,
                "Error" + error,
                "Searching for all artists for keyword ",
              );
            }
          }
        })
        .fail(function (error) {
          apiSelf._getDefaultFailedResponseHandler(error, reject, undefined, "Searching for all artists for keyword ");
        });
    }.bind(this);

    return this._doLogin().then(function () {
      return new RSVP.Promise(resolverFunc);
    });
  },

  getRelatedArtistsByArtistIdOrName: function (artistId, artistName) {
    const apiSelf = this;
    const startTime = new Date().getTime();
    let endTime = null;
    const resolverFunc = function (resolve, reject) {
      $.post(window.trebble.usedProtocolPrefix + trebbleServerUrl + "/getRelatedArtistsByArtistIdOrName", {
        artistId: artistId,
        artistName: artistName,
      })
        .done(function (response) {
          try {
            let results = response.message;
            const arrayOfArtists = results;
            results = arrayOfArtists;
            response.message = results;
            apiSelf._getDefaultResponseHandler(response, resolve, reject, undefined, "Get related artists for artist ");
            endTime = new Date().getTime();
            if (window.trebbleAnalyticsHelper && ENABLE_BACKEND_CALL_ANAYLYTICS_TRACKING) {
              window.trebbleAnalyticsHelper.trackEvent(
                "backendCall",
                "getRelatedArtistsByArtistIdOrName",
                "Get related artists for artist ",
              );
              window.trebbleAnalyticsHelper.trackTiming(
                "Server API Call",
                endTime - startTime,
                "Success",
                "Get related artists for artist ",
              );
            }
          } catch (error) {
            apiSelf._getDefaultFailedResponseHandler(error, reject, undefined, "Get related artists for artist ");
            endTime = new Date().getTime();
            if (window.trebbleAnalyticsHelper && ENABLE_BACKEND_CALL_ANAYLYTICS_TRACKING) {
              window.trebbleAnalyticsHelper.trackEvent(
                "backendCall",
                "getRelatedArtistsByArtistIdOrName",
                "Get related artists for artist ",
                -1,
              );
              window.trebbleAnalyticsHelper.trackTiming(
                "Server API Call",
                endTime - startTime,
                "Error" + error,
                "Get related artists for artist ",
              );
            }
          }
        })
        .fail(function (error) {
          apiSelf._getDefaultFailedResponseHandler(error, reject, undefined, "Get related artists for artist ");
        });
    }.bind(this);

    return this._doLogin().then(function () {
      return new RSVP.Promise(resolverFunc);
    });
  },

  sendPushNotificationRegistrationId: function (
    registrationId,
    devicePlatform,
    registrationType,
    uniqueDeviceId,
    language,
    timezone,
  ) {
    return this._makeApiServerCall(
      "setMyRegistrationId",
      {
        registrationId: registrationId,
        devicePlatform: devicePlatform,
        registrationType: registrationType,
        uniqueDeviceId: uniqueDeviceId,
        language: language,
        timezone: timezone,
      },
      "Send Push notification registration Id",
    );
  },

  deletePushNotificationRegistrationId: function (
    userUID,
    registrationId,
    devicePlatform,
    registrationType,
    uniqueDeviceId,
  ) {
    return this._makeApiServerCall(
      "deleteMyPushRegistrationInfo",
      {
        userUID: userUID,
        registrationId: registrationId,
        devicePlatform: devicePlatform,
        uniqueDeviceId: uniqueDeviceId,
        registrationType: registrationType,
      },
      "delete Push notification registration Id",
      true,
    );
  },

  markSongsAsDeleted: function (deletedSongUriArray) {
    return this._makeApiServerCall(
      "markSongsAsDeleted",
      {
        songUrisToMarkDeleted: deletedSongUriArray,
      },
      "Mark songs as deleted",
      false,
      false,
      true,
    );
  },

  getAllMyPersonalRadioInfos: function () {
    return this._makeApiServerCall("getAllMyPersonalRadioInfos", {}, "Get All My Personal Trebbles Infos");
  },

  createAPersonalRadio: function (name, description, type, coverArtBase64) {
    return this._makeApiServerCall(
      "createAPersonalRadio",
      {
        name: name,
        description: description,
        type: type,
        coverArtBase64: coverArtBase64,
      },
      "Create a personal Trebble",
    );
  },

  createAJourney: function (
    name,
    goal,
    description,
    categoryId,
    cityId,
    language,
    timezone,
    isPaid,
    costInUSD,
    coverArtUrl,
    coverArtBase64,
    context,
  ) {
    return this._makeApiServerCall(
      "createAJourney",
      {
        name: name,
        goal: goal,
        description: description,
        categoryId: categoryId,
        cityId: cityId,
        language: language,
        timezone: timezone,
        isPaid: isPaid,
        costInUSD: costInUSD,
        coverArtUrl: coverArtUrl,
        coverArtBase64: coverArtBase64,
        context: context,
      },
      "Create a journey",
      null,
      null,
      null,
      null,
      true,
    );
  },

  addSongsToMyPersonalRadio: function (songUrisArray, radioUIDs, context) {
    return this._makeApiServerCall(
      "addSongsToMyPersonalRadio",
      {
        songURIs: songUrisArray,
        radioIUDs: radioUIDs,
        context: context,
      },
      "Add Songs To My personal Trebble",
      false,
      false,
      true,
    );
  },

  removeSongsToMyPersonalRadio: function (songUrisArray, radioUIDs, context) {
    return this._makeApiServerCall(
      "removeSongsToMyPersonalRadio",
      {
        songURIs: songUrisArray,
        radioIUDs: radioUIDs,
        context: context,
      },
      "Remove Songs From My personal Trebble",
      false,
      false,
      true,
    );
  },

  addSongsToMyDefaultPersonalRadio: function (songUrisArray, context) {
    return this._makeApiServerCall(
      "addSongsToMyDefaultPersonalRadio",
      {
        songURIs: songUrisArray,
        context: context,
      },
      "Add Songs To My default personal Trebble",
      false,
      false,
      true,
    );
  },

  removeSongsFromMyDefaultPersonalRadio: function (songUrisArray, context) {
    return this._makeApiServerCall(
      "removeSongsToMyDefaultPersonalRadio",
      {
        songURIs: songUrisArray,
        context: context,
      },
      "Remove Songs From My default personal Trebble",
      false,
      false,
      true,
    );
  },

  addCapsuleToRadios: function (
    capsuleId,
    radioIds,
    lifeSpanInDays,
    isScheduled,
    scheduledStartDate,
    timezone,
    context,
  ) {
    return this._makeApiServerCall(
      "addCapsuleToRadio",
      {
        capsuleId: capsuleId,
        radioIds: radioIds,
        lifeSpanInDays: lifeSpanInDays,
        isScheduled: isScheduled,
        scheduledStartDate: scheduledStartDate,
        timezone: timezone,
        context: context,
      },
      "Add Capsule To Trebbles",
      false,
      false,
      true,
    );
  },

  removeCapsuleFromRadios: function (capsuleId, radioIds, context) {
    return this._makeApiServerCall(
      "removeCapsuleFromRadio",
      {
        capsuleId: capsuleId,
        radioIds: radioIds,
        context: context,
      },
      "Remove Capsule From Trebbles",
      false,
      false,
      true,
    );
  },

  logUserClickedOnUrlPreviewInCapsule: function (capsuleId, url, context) {
    return this._makeApiServerCall(
      "logClickedOnUrlPreviewInCapsuleDetails",
      {
        url: url,
        capsuleId: capsuleId,
        context: context,
      },
      "Log user clicked on url preview in capsule",
    );
  },

  logUserClickedOnUrlPreviewInComment: function (commentId, url, context) {
    return this._makeApiServerCall(
      "logClickedOnUrlPreviewInComment",
      {
        url: url,
        commentId: commentId,
        context: context,
      },
      "Log user clicked on url preview in comment",
    );
  },

  getListOfSongRecentlyPlayedByAllOthers: function (options) {
    const apiSelf = this;
    return this._getSongsSetFromParams(
      options,
      "generatePlaylistOfRecentlyPlayedSongsByOther",
      "Get list of songs recently played by all other users",
      false,
    );
  },

  getListOfSongRecentlyAddedOrFavoritedByAllOthers: function (options) {
    const apiSelf = this;
    const params = {};
    if (options) {
      params.selectedTagIds = options.selectedTagIds;
    }
    return this._getSongsSetFromParams(
      params,
      "generatePlaylistOfRecentlyAddedOrFavoritedSongsByOther",
      "Get list of songs recently played by all other users",
      false,
    );
  },

  getListOfSongFromPersonalUserId: function (personalUserRadioId, options) {
    const apiSelf = this;
    const params = {};
    //TODO:Need to correct Typo here and in the server
    params.personalUserId = personalUserRadioId;
    if (options) {
      params.selectedTagIds = options.selectedTagIds;
    }
    return this._getSongsSetFromParams(
      params,
      "generatePlaylistForPersonalUserId",
      "Get list of songs For personal user radio",
      false,
    );
  },

  getCapsuleFeedForUserId: function (userId, options) {
    const apiSelf = this;
    const params = {};
    params.userId = userId;
    if (options && options.doNotUseTextToSpeech) {
      params.doNotUseTextToSpeech = options.doNotUseTextToSpeech;
    }
    return this._getSongsSetFromParams(
      params,
      "generatePlaylistCapsuleFeedForUserId",
      "Get capsule feed for user id",
      false,
    );
  },

  getCapsulePlaylistForCategories: function (categoryIds, language, options) {
    const apiSelf = this;
    const params = {};
    params.categoryIds = categoryIds;
    params.language = language;
    if (options && options.doNotUseTextToSpeech) {
      params.doNotUseTextToSpeech = options.doNotUseTextToSpeech;
    }
    return this._getSongsSetFromParams(
      params,
      "generatePlaylistCapsuleForCategories",
      "Get capsule playlist for categories",
      false,
    );
  },

  getPlaylistOfNextShortcastToPlayForJourney: function (capsuleId, journeyRadioId) {
    const apiSelf = this;
    const params = {};
    params.journeyRadioId = journeyRadioId;
    params.capsuleId = capsuleId;
    return this._getSongsSetFromParams(
      params,
      "generatePlaylistOfNextShortcastToPlayForJourneyRadioId",
      "get Playlist Of Next Shortcast To Play For Journey",
      false,
    );
  },

  getAllSongInPersonalUserRadio: function (personalUserRadioId) {
    const apiSelf = this;
    const params = {};
    params.personalUserRadioId = personalUserRadioId;
    return this._getSongsSetFromParamsViaGet(
      params,
      "getAllSongsInPersonalUserId",
      "Get all songs in personal user Trebble",
      true,
    );
  },

  getMostRecentSongsInPersonalUserRadio: function (personalUserRadioId, numberOfSongs) {
    const apiSelf = this;
    const params = {};
    params.personalUserRadioId = personalUserRadioId;
    params.numberOfSongs = numberOfSongs;
    return this._getSongsSetFromParams(
      params,
      "getMostRecentSongsInPersonalUserId",
      "Get most recent songs in personal user Trebble",
      true,
    );
  },

  getTrebbleTracksInfoForSpotifyIds: function (spotifyTrackIds) {
    const apiSelf = this;
    const params = {};
    params.spotifyTrackIds = spotifyTrackIds;
    return this._getSongsSetFromParams(
      params,
      "getTrebbeSongInfoForSpotifyTrackIds",
      "Get track infos songs for spotify ids",
      true,
    );
  },

  getMostPlayedSongsForUserByUserIdOrUsername: function (userId, username, numberOfResults, pageOffset) {
    const apiSelf = this;
    const params = {};
    params.userId = userId;
    params.username = username;
    params.numberOfResults = numberOfResults;
    params.pageOffset = pageOffset;
    return this._getSongsSetFromParams(
      params,
      "getMostPlayedSongsForUserByUserIdOrUsername",
      "Get most played songs for a user",
      true,
    );
  },

  getRecentlyAddedSongsForUserByUserIdOrUsername: function (userId, username, numberOfResults, pageOffset) {
    const apiSelf = this;
    const params = {};
    params.userId = userId;
    params.username = username;
    params.numberOfResults = numberOfResults;
    params.pageOffset = pageOffset;
    return this._getSongsSetFromParams(
      params,
      "getRecentlyAddedSongsForUserByUserIdOrUsername",
      "Get recently addded songs for a user",
      true,
    );
  },

  getUserTrebblesForUserByUserIdOrUsername: function (userId, username, numberOfResults, pageOffset) {
    return this._makeApiServerCall(
      "getUserTrebblesForUserByUserIdOrUsername",
      {
        userId: userId,
        username: username,
        numberOfResults: numberOfResults,
        pageOffset: pageOffset,
      },
      "Get trebbles  owned by user",
      false,
      false,
      false,
    );
  },

  getExpiredCapsulesForUserByUserIdOrUsername: function (userId, username, numberOfResults, pageOffset) {
    return this._makeApiServerCall(
      "getExpiredCapsulesForUserByUserIdOrUsername",
      {
        userId: userId,
        username: username,
        numberOfResults: numberOfResults,
        pageOffset: pageOffset,
      },
      "Get expired user capsules",
      false,
      false,
      false,
    );
  },

  getReceivedCapsules: function () {
    return this._makeApiServerCall("getReceivedCapsules", {}, "Get received  capsules", false, false, false);
  },

  getReceivedCapsulesWithPaging: function (searchKeyword, pageOffset, numberOfResults) {
    return this._makeApiServerCall(
      "getReceivedCapsulesWithPaging",
      {
        searchKeyword: searchKeyword,
        numberOfResults: numberOfResults,
        pageOffset: pageOffset,
      },
      "Get received  capsules",
      false,
      false,
      false,
    );
  },

  getOffAirCapsules: function () {
    return this._makeApiServerCall("getOffAirCapsules", {}, "Get Off-Air  capsules", false, false, false);
  },

  getOffAirCapsulesWithPaging: function (searchKeyword, pageOffset, numberOfResults) {
    return this._makeApiServerCall(
      "getOffAirCapsulesWithPaging",
      {
        searchKeyword: searchKeyword,
        numberOfResults: numberOfResults,
        pageOffset: pageOffset,
      },
      "Get Off-Air  capsules with paging",
      false,
      false,
      false,
    );
  },

  getDraftCapsules: function () {
    return this._makeApiServerCall("getDraftCapsules", {}, "Get Draft  capsules", false, false, false);
  },

  getDraftCapsulesWithPaging: function (searchKeyword, pageOffset, numberOfResults) {
    return this._makeApiServerCall(
      "getDraftCapsulesWithPaging",
      {
        searchKeyword: searchKeyword,
        numberOfResults: numberOfResults,
        pageOffset: pageOffset,
      },
      "Get Draft  capsules with paging",
      false,
      false,
      false,
    );
  },

  getOnAirCapsules: function () {
    return this._makeApiServerCall("getOnAirCapsules", {}, "Get On-Air  capsules", false, false, false);
  },

  getOnAirCapsulesWithPaging: function (searchKeyword, pageOffset, numberOfResults) {
    return this._makeApiServerCall(
      "getOnAirCapsulesWithPaging",
      {
        searchKeyword: searchKeyword,
        numberOfResults: numberOfResults,
        pageOffset: pageOffset,
      },
      "Get On-Air  capsules with paging",
      false,
      false,
      false,
    );
  },

  getAllMyCapsulesWithPaging: function (searchKeyword, pageOffset, numberOfResults) {
    return this._makeApiServerCall(
      "getAllMyCapsulesWithPaging",
      {
        searchKeyword: searchKeyword,
        numberOfResults: numberOfResults,
        pageOffset: pageOffset,
      },
      "Get On-Air  capsules with paging",
      false,
      false,
      false,
    );
  },

  getScheduledCapsules: function () {
    return this._makeApiServerCall("getScheduleCapsules", {}, "Get Scheduled  capsules", false, false, false);
  },

  getScheduledCapsulesWithPaging: function (searchKeyword, pageOffset, numberOfResults) {
    return this._makeApiServerCall(
      "getScheduleCapsulesWithPaging",
      {
        searchKeyword: searchKeyword,
        numberOfResults: numberOfResults,
        pageOffset: pageOffset,
      },
      "Get Scheduled  capsules with paging",
      false,
      false,
      false,
    );
  },

  getMyCapsuleFeed: function () {
    return this._makeApiServerCall("getMyCapsuleFeed", {}, "Get my  capsules feed", false, false, false);
  },

  getAllCapsulesByType: function (maxNumberOfResults) {
    return this._makeApiServerCall(
      "getAllCapsulesByType",
      { maxNumberOfResults: maxNumberOfResults },
      "Get All  capsules by type",
      false,
      false,
      false,
    );
  },

  getCapsulesMatchingSearchFromRecentlyListenOnSmartSpeakers: function (audioPlayerToken, searchCriteria) {
    return this._makeApiServerCall(
      "getCapsulesMatchingSearchFromRecentlyListenOnSmartSpeakers",
      {
        audioPlayerToken: audioPlayerToken,
        searchCriteria: searchCriteria,
      },
      "Get Capsules Matching Search From Recently Listened On Smart Speakers",
      false,
      false,
      false,
    );
  },

  getOnAirCapsulesForRadioId: function (radioId, numberOfResults, pageOffset) {
    return this._makeApiServerCall(
      "getOnAirCapsulesForRadioId",
      {
        radioId: radioId,
        numberOfResults: numberOfResults,
        pageOffset: pageOffset,
      },
      "Get On-Air  capsules for radio id",
      false,
      false,
      false,
    );
  },

  getScheduledCapsulesForRadioId: function (radioId) {
    return this._makeApiServerCall(
      "getScheduleCapsulesForRadioId",
      {
        radioId: radioId,
      },
      "Get Schedule  capsules for radio id",
      false,
      false,
      false,
    );
  },

  getArchivedCapsulesForRadioId: function (radioId, numberOfResults, pageOffset) {
    return this._makeApiServerCall(
      "getArchivedCapsulesForRadioId",
      {
        radioId: radioId,
        numberOfResults: numberOfResults,
        pageOffset: pageOffset,
      },
      "Get archived  capsules for radio id",
      false,
      false,
      false,
    );
  },

  getSimpleSetForCapsuleId: function (capsuleId) {
    const apiSelf = this;
    const params = {};
    params.capsuleId = capsuleId;
    return this._getSongsSetFromParams(
      params,
      "generateCapsuleSimpleSetForCapsuleId",
      "Get simple set for capsule id",
      false,
    );
  },

  getSimpleSetForSongId: function (songId) {
    const apiSelf = this;
    const params = {};
    params.songId = songId;
    return this._getSongsSetFromParams(params, "generateSongSimpleSetForSongId", "Get simple set for song id", false);
  },

  _getSongsSetFromParams: function (params, restApiName, actionName, isQueryForSongArray) {
    const apiSelf = this;
    const startTime = new Date().getTime();
    let arrayOfSongs = null;
    let endTime = null;
    let result = null;
    const resolverFunc = function (resolve, reject) {
      $.post(window.trebble.usedProtocolPrefix + trebbleServerUrl + "/" + restApiName, params)
        .done(function (response) {
          try {
            if (isQueryForSongArray) {
              arrayOfSongs = response.message;
              if (arrayOfSongs) {
                arrayOfSongs = arrayOfSongs ? arrayOfSongs : [];
                result = {};
                result.songs = arrayOfSongs;
                result.albums = apiSelf._getAlbumsArrayFromSongsJson(arrayOfSongs);
                result.artists = apiSelf._getArtistArrayFromAlbumsJson(result.albums);
                response.message = arrayOfSongs;
              }
            } else {
              const socialPlaylist = response.message;
              if (socialPlaylist) {
                arrayOfSongs = socialPlaylist ? socialPlaylist.songs : [];
                result = {};
                result.songs = arrayOfSongs;
                result.albums = apiSelf._getAlbumsArrayFromSongsJson(arrayOfSongs);
                result.artists = apiSelf._getArtistArrayFromAlbumsJson(result.albums);
                socialPlaylist.songs = arrayOfSongs;
                response.message = socialPlaylist;
              }
            }
            apiSelf._getDefaultResponseHandler(response, resolve, reject, undefined, actionName);
            endTime = new Date().getTime();
            if (window.trebbleAnalyticsHelper && ENABLE_BACKEND_CALL_ANAYLYTICS_TRACKING) {
              window.trebbleAnalyticsHelper.trackEvent("backendCall", restApiName, actionName);
              window.trebbleAnalyticsHelper.trackTiming("Server API Call", endTime - startTime, "Success", actionName);
            }
          } catch (error) {
            apiSelf._getDefaultFailedResponseHandler(error, reject, undefined, actionName);
            endTime = new Date().getTime();
            if (window.trebbleAnalyticsHelper && ENABLE_BACKEND_CALL_ANAYLYTICS_TRACKING) {
              window.trebbleAnalyticsHelper.trackEvent("backendCall", restApiName, actionName, -1);
              window.trebbleAnalyticsHelper.trackTiming(
                "Server API Call",
                endTime - startTime,
                "Error" + error,
                actionName,
              );
            }
          }
        })
        .fail(function (error) {
          apiSelf._getDefaultFailedResponseHandler(error, reject, undefined, actionName);
        });
    }.bind(this);

    return this._doLogin().then(function () {
      return new RSVP.Promise(resolverFunc);
    });
  },

  _getSongsSetFromParamsViaGet: function (params, restApiName, actionName, isQueryForSongArray) {
    const apiSelf = this;
    const startTime = new Date().getTime();
    let arrayOfSongs = null;
    let result = null;
    let endTime = null;
    const resolverFunc = function (resolve, reject) {
      $.get(window.trebble.usedProtocolPrefix + trebbleServerUrl + "/" + restApiName, params)
        .done(function (response) {
          try {
            if (isQueryForSongArray) {
              if (arrayOfSongs) {
                arrayOfSongs = arrayOfSongs ? arrayOfSongs : [];
                result = {};
                result.songs = arrayOfSongs;
                result.albums = apiSelf._getAlbumsArrayFromSongsJson(arrayOfSongs);
                result.artists = apiSelf._getArtistArrayFromAlbumsJson(result.albums);
                response.message = arrayOfSongs;
              }
            } else {
              const socialPlaylist = response.message;
              if (socialPlaylist) {
                arrayOfSongs = socialPlaylist ? socialPlaylist.songs : [];
                result = {};
                result.songs = arrayOfSongs;
                result.albums = apiSelf._getAlbumsArrayFromSongsJson(arrayOfSongs);
                result.artists = apiSelf._getArtistArrayFromAlbumsJson(result.albums);
                socialPlaylist.songs = arrayOfSongs;
                response.message = socialPlaylist;
              }
            }
            apiSelf._getDefaultResponseHandler(response, resolve, reject, undefined, actionName);
            endTime = new Date().getTime();
            if (window.trebbleAnalyticsHelper && ENABLE_BACKEND_CALL_ANAYLYTICS_TRACKING) {
              window.trebbleAnalyticsHelper.trackEvent("backendCall", restApiName, actionName);
              window.trebbleAnalyticsHelper.trackTiming("Server API Call", endTime - startTime, "Success", actionName);
            }
          } catch (error) {
            apiSelf._getDefaultFailedResponseHandler(error, reject, undefined, actionName);
            endTime = new Date().getTime();
            if (window.trebbleAnalyticsHelper && ENABLE_BACKEND_CALL_ANAYLYTICS_TRACKING) {
              window.trebbleAnalyticsHelper.trackEvent("backendCall", restApiName, actionName, -1);
              window.trebbleAnalyticsHelper.trackTiming(
                "Server API Call",
                endTime - startTime,
                "Error" + error,
                actionName,
              );
            }
          }
        })
        .fail(function (error) {
          apiSelf._getDefaultFailedResponseHandler(error, reject, undefined, actionName);
        });
    }.bind(this);

    return this._doLogin().then(function () {
      return new RSVP.Promise(resolverFunc);
    });
  },

  getAllSongInUserLibrary: function (username) {
    const apiSelf = this;
    const params = {};
    const startTime = new Date().getTime();
    let arrayOfSongs = null;
    let result = null;
    let endTime = null;
    params.username = username;
    const resolverFunc = function (resolve, reject) {
      $.post(window.trebble.usedProtocolPrefix + trebbleServerUrl + "/getAllSongsInUserLibrary", params)
        .done(function (response) {
          try {
            arrayOfSongs = response.message;
            if (arrayOfSongs) {
              arrayOfSongs = arrayOfSongs ? arrayOfSongs : [];
              result = {};
              result.songs = arrayOfSongs;
              result.albums = apiSelf._getAlbumsArrayFromSongsJson(arrayOfSongs);
              result.artists = apiSelf._getArtistArrayFromAlbumsJson(result.albums);
              response.message = arrayOfSongs;
            }
            apiSelf._getDefaultResponseHandler(
              response,
              resolve,
              reject,
              undefined,
              "Get all songs in user personal library",
            );
            endTime = new Date().getTime();
            if (window.trebbleAnalyticsHelper && ENABLE_BACKEND_CALL_ANAYLYTICS_TRACKING) {
              window.trebbleAnalyticsHelper.trackEvent(
                "backendCall",
                "getAllSongsInUserLibrary",
                "Get all songs in user personal library",
              );
              window.trebbleAnalyticsHelper.trackTiming(
                "Server API Call",
                endTime - startTime,
                "Success",
                "Get all songs in user personal library",
              );
            }
          } catch (error) {
            apiSelf._getDefaultFailedResponseHandler(
              error,
              reject,
              undefined,
              "Get all songs in user personal library",
            );
            endTime = new Date().getTime();
            if (window.trebbleAnalyticsHelper && ENABLE_BACKEND_CALL_ANAYLYTICS_TRACKING) {
              window.trebbleAnalyticsHelper.trackEvent(
                "backendCall",
                "getAllSongsInUserLibrary",
                "Get all songs in user personal library",
                -1,
              );
              window.trebbleAnalyticsHelper.trackTiming(
                "Server API Call",
                endTime - startTime,
                "Error" + error,
                "Get all songs in user personal library",
              );
            }
          }
        })
        .fail(function (error) {
          apiSelf._getDefaultFailedResponseHandler(error, reject, undefined, "Get all songs in user personal library");
        });
    }.bind(this);

    return this._doLogin().then(function () {
      return new RSVP.Promise(resolverFunc);
    });
  },

  getFollowersPersonalUserRadios: function (withPreviewOnly) {
    return this._makeApiServerCall(
      "getFollowersPersonalUserRadios",
      { withPreviewOnly: withPreviewOnly },
      "Get followers personal user Trebble",
    );
  },

  getFeaturedRadios: function (language, numberOfResults, pageOffset) {
    return this._makeApiServerCall(
      "getFeaturedRadios",
      { language: language, numberOfResults: numberOfResults, pageOffset: pageOffset },
      "Get featured trebbles",
    );
  },

  getRecentlyAddedRadios: function (language, showFeatured, numberOfResults, pageOffset) {
    return this._makeApiServerCall(
      "getRecentlyAddedRadios",
      { language: language, showFeatured: showFeatured, numberOfResults: numberOfResults, pageOffset: pageOffset },
      "Get recently added trebbles",
    );
  },

  getMyPersonalRadios: function () {
    return this._makeApiServerCall("getMyPersonalRadios", null, "Get my personal user Trebbles");
  },

  getTrebbleNamesAndLatestHeadlinesInUserSubsription: function () {
    return this._makeApiServerCall(
      "getTrebbleNamesAndLatestHeadlinesInUserSubsription",
      null,
      "Get trebble names and latest headlines for user id",
    );
  },

  getYoutubeDirectVideoUrl: function (videoId) {
    return this._makeApiServerCall(
      "getDirectYoutubeUrl",
      {
        videoId: videoId,
      },
      "Get youtube direct video URL",
    );
  },

  getStatisticsForMyTrebble: function (trebbleId) {
    return this._makeApiServerCall(
      "getStatisticsForMyTrebble",
      {
        radioId: trebbleId,
      },
      "Get statistics for trebble",
    );
  },

  getStatsForMyTrebble: function (trebbleId) {
    return this._makeApiServerCall(
      "getStatsForMyTrebble",
      {
        radioId: trebbleId,
      },
      "Get statistics for trebble",
    );
  },

  getStatisticsForSong: function (songTrebbleId) {
    return this._makeApiServerCall(
      "getStatisticsForSong",
      {
        songId: songTrebbleId,
      },
      "Get statistics for song",
    );
  },

  getStatisticsForCapsule: function (capsuleTrebbleId) {
    return this._makeApiServerCall(
      "getStatisticsForCapsule",
      {
        capsuleId: capsuleTrebbleId,
      },
      "Get statistics for capsule",
    );
  },

  enableEmailDigestNotification: function () {
    return this._makeApiServerCall(
      "enableEmailDigestNotification",
      null,
      "Enable Email Digest Notification",
      false,
      false,
      true,
    );
  },

  disableEmailDigestNotification: function () {
    return this._makeApiServerCall(
      "disableEmailDigestNotification",
      null,
      "Disable Email Digest Notification",
      false,
      false,
      true,
    );
  },

  getDecryptedYoutubeSignature: function (encryptedSignature, playerUrl) {
    return this._makeApiServerCall(
      "getDecipherSignature",
      {
        signature: encryptedSignature,
        playerUrl: playerUrl,
      },
      "Get decrypted youtube signature",
    );
  },

  syncSongsArrayWithDB: function (arrayOfSongsToSync, isFirstSync) {
    return this._makeApiServerCall(
      "resyncMySongLibrary",
      {
        model: arrayOfSongsToSync,
        isFirstSync: isFirstSync,
      },
      "Sync Songs",
      false,
      false,
      true,
    );
  },

  signup: function (params, callback, context) {
    const apiSelf = this;
    const language = window.getAppUsedLanguage();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (window.trebble && window.trebble.adReference) {
      if (!context) {
        context = {};
      }
      context.environment = Utils.getInstance().getCurrentEnvironmentContext();
      context.adReference = window.trebble.adReference;
    }
    if (params && context) {
      params.context = context;
      params.language = language;
      params.timezone = timezone;
    }
    const resolverFunc = function (resolve, reject) {
      $.post(window.trebble.usedProtocolPrefix + trebbleServerUrl + "/signup", params)
        .done(function (result) {
          apiSelf._getDefaultResponseHandler(result, resolve, reject, callback, "Signup");
        })
        .fail(function (error) {
          apiSelf._getDefaultFailedResponseHandler(error, reject, callback, "Signup");
        });
    };
    //return this._doLogin().then(function(){
    return new RSVP.Promise(resolverFunc);
    //})
  },

  signupWithFacebook: function (
    facebookAccessToken,
    accessCode,
    context,
    trebbleName,
    trebbleDescription,
    trebbleCategoryId,
    trebbleCityId,
    trebbleCustomUrl,
    trebbleTimezone,
    trebbleLanguage,
    rssFeedUrlContentSource,
    profileType,
  ) {
    const apiSelf = this;
    const language = window.getAppUsedLanguage();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const params = {
      facebookAccessToken: facebookAccessToken,
      accessCode: accessCode,
      trebbleName: trebbleName,
      trebbleDescription: trebbleDescription,
      trebbleCategoryId: trebbleCategoryId,
      trebbleCustomUrl: trebbleCustomUrl,
      trebbleTimezone: trebbleTimezone,
      trebbleLanguage: trebbleLanguage,
      rssFeedUrlContentSource: rssFeedUrlContentSource,
      language: language,
      timezone: timezone,
      trebbleCityId: trebbleCityId,
      profileType: profileType,
    };
    if (window.trebble && window.trebble.adReference) {
      if (!context) {
        context = {};
      }
      context.adReference = window.trebble.adReference;
    }
    if (params && context) {
      params.context = context;
    }
    const resolverFunc = function (resolve, reject) {
      $.post(window.trebble.usedProtocolPrefix + trebbleServerUrl + "/signupWithFacebookAccount", params)
        .done(function (result) {
          apiSelf._getDefaultResponseHandler(result, resolve, reject, undefined, "Signup With Facebook Account");
        })
        .fail(function (error) {
          apiSelf._getDefaultFailedResponseHandler(error, reject, undefined, "Signup With Facebook Account");
        });
    };
    //return this._doLogin().then(function(){
    return new RSVP.Promise(resolverFunc);
    //})
  },

  signupWithGoogle: function (
    googleAccessToken,
    accessCode,
    context,
    trebbleName,
    trebbleDescription,
    trebbleCategoryId,
    trebbleCityId,
    trebbleCustomUrl,
    trebbleTimezone,
    trebbleLanguage,
    rssFeedUrlContentSource,
    profileType,
  ) {
    const apiSelf = this;
    const language = window.getAppUsedLanguage();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const params = {
      googleAccessToken: googleAccessToken,
      accessCode: accessCode,
      trebbleName: trebbleName,
      trebbleDescription: trebbleDescription,
      trebbleCategoryId: trebbleCategoryId,
      trebbleCustomUrl: trebbleCustomUrl,
      trebbleTimezone: trebbleTimezone,
      trebbleLanguage: trebbleLanguage,
      rssFeedUrlContentSource: rssFeedUrlContentSource,
      language: language,
      timezone: timezone,
      trebbleCityId: trebbleCityId,
      profileType: profileType,
    };
    if (window.trebble && window.trebble.adReference) {
      if (!context) {
        context = {};
      }
      context.adReference = window.trebble.adReference;
    }
    if (params && context) {
      params.context = context;
    }
    const resolverFunc = function (resolve, reject) {
      $.post(window.trebble.usedProtocolPrefix + trebbleServerUrl + "/signupWithGoogleAccount", params)
        .done(function (result) {
          apiSelf._getDefaultResponseHandler(result, resolve, reject, undefined, "Signup With Google Account");
        })
        .fail(function (error) {
          apiSelf._getDefaultFailedResponseHandler(error, reject, undefined, "Signup With Google Account");
        });
    };
    //return this._doLogin().then(function(){
    return new RSVP.Promise(resolverFunc);
    //})
  },

  signupWithApple: function (
    appleIdToken,
    accessCode,
    context,
    trebbleName,
    trebbleDescription,
    trebbleCategoryId,
    trebbleCityId,
    trebbleCustomUrl,
    trebbleTimezone,
    trebbleLanguage,
    rssFeedUrlContentSource,
    profileType,
  ) {
    const apiSelf = this;
    const language = window.getAppUsedLanguage();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const params = {
      appleIdToken: appleIdToken,
      accessCode: accessCode,
      trebbleName: trebbleName,
      trebbleDescription: trebbleDescription,
      trebbleCategoryId: trebbleCategoryId,
      trebbleCustomUrl: trebbleCustomUrl,
      trebbleTimezone: trebbleTimezone,
      trebbleLanguage: trebbleLanguage,
      rssFeedUrlContentSource: rssFeedUrlContentSource,
      language: language,
      timezone: timezone,
      trebbleCityId: trebbleCityId,
      profileType: profileType,
    };
    if (window.trebble && window.trebble.adReference) {
      if (!context) {
        context = {};
      }
      context.adReference = window.trebble.adReference;
    }
    if (params && context) {
      params.context = context;
    }
    const resolverFunc = function (resolve, reject) {
      $.post(window.trebble.usedProtocolPrefix + trebbleServerUrl + "/signupWithAppleAccount", params)
        .done(function (result) {
          apiSelf._getDefaultResponseHandler(result, resolve, reject, undefined, "Signup With Apple Account");
        })
        .fail(function (error) {
          apiSelf._getDefaultFailedResponseHandler(error, reject, undefined, "Signup With Apple Account");
        });
    };
    //return this._doLogin().then(function(){
    return new RSVP.Promise(resolverFunc);
    //})
  },

  updateGuestProfile: function (params, callback) {
    const apiSelf = this;
    const resolverFunc = function (resolve, reject) {
      $.post(window.trebble.usedProtocolPrefix + trebbleServerUrl + "/updateGuestUserProfile", {
        updatedData: params,
      })
        .done(function (result) {
          apiSelf._getDefaultResponseHandler(result, resolve, reject, callback, "Update Guest User Profile");
        })
        .fail(function (error) {
          apiSelf._getDefaultFailedResponseHandler(error, reject, callback, "Update Guest User Profile");
        });
    };
    //return this._doLogin().then(function(){
    return this.doLoginAsGuest(true).then(
      function () {
        return new RSVP.Promise(resolverFunc);
      }.bind(this),
    );

    //})
  },

  updateLoggedInUserProfile: function (params, callback) {
    const apiSelf = this;
    const resolverFunc = function (resolve, reject) {
      $.post(window.trebble.usedProtocolPrefix + trebbleServerUrl + "/updateMyProfileInfo", {
        updatedData: params,
      })
        .done(function (result) {
          apiSelf._getDefaultResponseHandler(result, resolve, reject, callback, "Update Logged In User Profile");
        })
        .fail(function (error) {
          apiSelf._getDefaultFailedResponseHandler(error, reject, callback, "Update Logged In User Profile");
        });
    };
    return this._doLogin().then(
      function () {
        return new RSVP.Promise(resolverFunc);
      }.bind(this),
    );
  },

  _getAlbumsArrayFromSongsJson: function (arrayOfSongs) {
    const albums = [];
    if (arrayOfSongs && arrayOfSongs.length > 0) {
      for (const songUri in arrayOfSongs) {
        const song = arrayOfSongs[songUri];
        if (song.albumJson) {
          let album = (album = albums[song.albumJson.albumId]);
          if (!album) {
            album = song.albumJson;
            album.songs = [];
            albums[album.albumId] = album;
          }
          album.songs.push(song);
        }
      }
    }
    return albums;
  },

  _getArtistArrayFromAlbumsJson: function (arrayOfAlbums) {
    const artists = [];
    if (arrayOfAlbums && arrayOfAlbums.length > 0) {
      for (const albumId in arrayOfAlbums) {
        const album = arrayOfAlbums[albumId];
        if (album.artistJson) {
          let artist = artists[album.artistJson.artistId];
          if (!artist) {
            artist = album.artistJson;
            artist.albums = [];
            artists[artist.artistId] = artist;
          }
          artist.albums.push(album);
        }
      }
    }
    return artists;
  },

  getAllSongsInAlbumFromGracenoteAlbumId: function (gracenoteAlbumId, callback) {
    const apiSelf = this;
    const resolverFunc = function (resolve, reject) {
      const postParams = {
        gracenoteAlbumId: gracenoteAlbumId,
      };
      $.post(window.trebble.usedProtocolPrefix + trebbleServerUrl + "/getTrackListFromGracenoteId", postParams)
        .done(
          function (response) {
            try {
              const arrayOfSongs = response.message;
              const result = {};
              result.songs = arrayOfSongs;
              result.albums = apiSelf._getAlbumsArrayFromSongsJson(arrayOfSongs);
              result.artists = apiSelf._getArtistArrayFromAlbumsJson(result.albums);
              response.message = result;
              apiSelf._getDefaultResponseHandler(
                response,
                resolve,
                reject,
                undefined,
                "Get All Songs In Album From Gracenote Id",
              );
            } catch (error) {
              apiSelf._getDefaultFailedResponseHandler(
                error,
                reject,
                undefined,
                "Get All Songs In Album From Gracenote Id",
              );
            }
          }.bind(this),
        )
        .fail(
          function (error) {
            apiSelf._getDefaultFailedResponseHandler(
              error,
              reject,
              undefined,
              "Get All Songs In Album From Gracenote Id",
            );
          }.bind(this),
        );
    }.bind(this);
    return this._doLogin().then(
      function () {
        return new RSVP.Promise(resolverFunc);
      }.bind(this),
    );
  },

  getAllSongsInAlbumFromAlbumId: function (albumId, callback) {
    const apiSelf = this;
    const resolverFunc = function (resolve, reject) {
      const postParams = {
        albumId: albumId,
      };
      $.post(window.trebble.usedProtocolPrefix + trebbleServerUrl + "/getCompleteAlbumDetailsByAlbumId", postParams)
        .done(
          function (response) {
            try {
              const completeAlbumInfo = response.message;
              const arrayOfSongs = completeAlbumInfo && completeAlbumInfo.songs ? completeAlbumInfo.songs : [];
              const result = {};
              result.songs = arrayOfSongs;
              const albumIdToAlbumMap = apiSelf._getAlbumsArrayFromSongsJson(arrayOfSongs);
              const artistIdToArtistMap = apiSelf._getArtistArrayFromAlbumsJson(result.albums);
              let albumInfoJson = null;
              for (const id in albumIdToAlbumMap) {
                albumInfoJson = albumIdToAlbumMap[id];
                break;
              }
              response.message = albumInfoJson;
              apiSelf._getDefaultResponseHandler(
                response,
                resolve,
                reject,
                undefined,
                "Get All Songs In Album From Album Id",
              );
            } catch (error) {
              apiSelf._getDefaultFailedResponseHandler(
                error,
                reject,
                undefined,
                "Get All Songs In Album From Album Id",
              );
            }
          }.bind(this),
        )
        .fail(
          function (error) {
            apiSelf._getDefaultFailedResponseHandler(error, reject, undefined, "Get All Songs In Album From Album Id");
          }.bind(this),
        );
    }.bind(this);
    return this._doLogin().then(
      function () {
        return new RSVP.Promise(resolverFunc);
      }.bind(this),
    );
  },

  addPlayActionToMyHistory: function (
    songTitle,
    artist,
    uri,
    actionTime,
    songJson,
    context,
    songRefSummary,
    schedulingPosition,
    playbackElapsedTime,
    callback,
  ) {
    this.addPlayerActionToMyHistory(
      window.trebble.usedProtocolPrefix + trebbleServerUrl + "/addPlayActionToMyHistory",
      songTitle,
      artist,
      uri,
      actionTime,
      songJson,
      null,
      context,
      songRefSummary,
      schedulingPosition,
      playbackElapsedTime,
      callback,
    );
  },

  addFinishPlayingActionToMyHistory: function (
    songTitle,
    artist,
    uri,
    actionTime,
    songJson,
    context,
    songRefSummary,
    schedulingPosition,
    playbackElapsedTime,
    callback,
  ) {
    this.addPlayerActionToMyHistory(
      window.trebble.usedProtocolPrefix + trebbleServerUrl + "/addFinishPlayingActionToMyHistory",
      songTitle,
      artist,
      uri,
      actionTime,
      songJson,
      null,
      context,
      songRefSummary,
      schedulingPosition,
      playbackElapsedTime,
      callback,
    );
  },

  addShareSongPageLaunchedActionToMyHistory: function (
    songTitle,
    artist,
    uri,
    actionTime,
    songJson,
    context,
    songRefSummary,
    schedulingPosition,
    playbackElapsedTime,
    callback,
  ) {
    this.addPlayerActionToMyHistory(
      window.trebble.usedProtocolPrefix + trebbleServerUrl + "/addShareSongPageLaunchedActionToMyHistory",
      songTitle,
      artist,
      uri,
      actionTime,
      songJson,
      null,
      context,
      songRefSummary,
      schedulingPosition,
      playbackElapsedTime,
      callback,
    );
  },

  addViewExternalMediaSourcePageActionToMyHistory: function (
    songTitle,
    artist,
    uri,
    actionTime,
    songJson,
    mediaSourceExternalUrl,
    context,
    songRefSummary,
    schedulingPosition,
    playbackElapsedTime,
    callback,
  ) {
    this.addPlayerActionToMyHistory(
      window.trebble.usedProtocolPrefix + trebbleServerUrl + "/addViewExternalMediaSourcePageActionToMyHistory",
      songTitle,
      artist,
      uri,
      actionTime,
      songJson,
      { mediaSourceExternalUrl: mediaSourceExternalUrl },
      context,
      songRefSummary,
      schedulingPosition,
      playbackElapsedTime,
      callback,
    );
  },

  addIndirectPlayActionToMyHistory: function (
    songTitle,
    artist,
    uri,
    actionTime,
    songJson,
    context,
    songRefSummary,
    schedulingPosition,
    playbackElapsedTime,
    callback,
  ) {
    this.addPlayerActionToMyHistory(
      window.trebble.usedProtocolPrefix + trebbleServerUrl + "/addIndirectPlayActionToMyHistory",
      songTitle,
      artist,
      uri,
      actionTime,
      songJson,
      null,
      context,
      songRefSummary,
      schedulingPosition,
      playbackElapsedTime,
      callback,
    );
  },

  addFinishIndirectPlayingActionToMyHistory: function (
    songTitle,
    artist,
    uri,
    actionTime,
    songJson,
    context,
    songRefSummary,
    schedulingPosition,
    playbackElapsedTime,
    callback,
  ) {
    this.addPlayerActionToMyHistory(
      window.trebble.usedProtocolPrefix + trebbleServerUrl + "/addFinishIndirectPlayingActionToMyHistory",
      songTitle,
      artist,
      uri,
      actionTime,
      songJson,
      null,
      context,
      songRefSummary,
      schedulingPosition,
      playbackElapsedTime,
      callback,
    );
  },

  addIndirectViewExternalMediaSourcePageActionToMyHistory: function (
    songTitle,
    artist,
    uri,
    actionTime,
    songJson,
    mediaSourceExternalUrl,
    context,
    songRefSummary,
    schedulingPosition,
    playbackElapsedTime,
    callback,
  ) {
    this.addPlayerActionToMyHistory(
      window.trebble.usedProtocolPrefix + trebbleServerUrl + "/addIndirectViewExternalMediaSourcePageActionToMyHistory",
      songTitle,
      artist,
      uri,
      actionTime,
      songJson,
      { mediaSourceExternalUrl: mediaSourceExternalUrl },
      context,
      songRefSummary,
      schedulingPosition,
      playbackElapsedTime,
      callback,
    );
  },

  addLoopActionToMyHistory: function (
    songTitle,
    artist,
    uri,
    actionTime,
    songJson,
    numberOfTimes,
    context,
    songRefSummary,
    schedulingPosition,
    playbackElapsedTime,
    callback,
  ) {
    const additionalParams = {};
    additionalParams["numberOfTimes"] = numberOfTimes;
    this.addPlayerActionToMyHistory(
      window.trebble.usedProtocolPrefix + trebbleServerUrl + "/addLoopActionToMyHistory",
      songTitle,
      artist,
      uri,
      actionTime,
      songJson,
      additionalParams,
      context,
      songRefSummary,
      schedulingPosition,
      playbackElapsedTime,
      callback,
    );
  },

  addSkipActionToMyHistory: function (
    songTitle,
    artist,
    uri,
    actionTime,
    songJson,
    context,
    songRefSummary,
    schedulingPosition,
    playbackElapsedTime,
    callback,
  ) {
    this.addPlayerActionToMyHistory(
      window.trebble.usedProtocolPrefix + trebbleServerUrl + "/addSkipActionToMyHistory",
      songTitle,
      artist,
      uri,
      actionTime,
      songJson,
      null,
      context,
      songRefSummary,
      schedulingPosition,
      playbackElapsedTime,
      callback,
    );
  },

  addIndirectSkipActionToMyHistory: function (
    songTitle,
    artist,
    uri,
    actionTime,
    songJson,
    context,
    songRefSummary,
    schedulingPosition,
    playbackElapsedTime,
    skipReasonId,
    callback,
  ) {
    const additionalData = { skipReasonId: skipReasonId };
    this.addPlayerActionToMyHistory(
      window.trebble.usedProtocolPrefix + trebbleServerUrl + "/addIndirectSkipActionToMyHistory",
      songTitle,
      artist,
      uri,
      actionTime,
      songJson,
      additionalData,
      context,
      songRefSummary,
      schedulingPosition,
      playbackElapsedTime,
      callback,
    );
  },

  addLiveLikeActionToMyHistory: function (
    songTitle,
    artist,
    uri,
    actionTime,
    songJson,
    context,
    songRefSummary,
    callback,
    weight,
    playbackElapsedTime,
  ) {
    this.addPlayerActionToMyHistory(
      window.trebble.usedProtocolPrefix + trebbleServerUrl + "/addLiveLikeActionToMyHistory",
      songTitle,
      artist,
      uri,
      actionTime,
      songJson,
      null,
      context,
      songRefSummary,
      null,
      playbackElapsedTime,
      callback,
      weight,
    );
  },

  addTebbleLoadActionToMyHistory: function (
    radioLoaderKey,
    radioName,
    actionTime,
    additionalData,
    context,
    callback,
    sync,
    existingLogEntryIdToUpdate,
  ) {
    return this.addTrebbleActionToMyHistory(
      window.trebble.usedProtocolPrefix + trebbleServerUrl + "/addRadioLoadedActionToHistory",
      radioLoaderKey,
      null,
      null,
      radioName,
      actionTime,
      additionalData,
      context,
      callback,
      sync,
      existingLogEntryIdToUpdate,
    );
  },

  addSimpleSongLoadActionToMyHistory: function (
    songId,
    actionTime,
    additionalData,
    context,
    callback,
    sync,
    existingLogEntryIdToUpdate,
  ) {
    return this.addTrebbleActionToMyHistory(
      window.trebble.usedProtocolPrefix + trebbleServerUrl + "/addRadioLoadedActionToHistory",
      null,
      null,
      songId,
      null,
      actionTime,
      additionalData,
      context,
      callback,
      sync,
      existingLogEntryIdToUpdate,
    );
  },

  addSimpleCapsuleLadActionToMyHistory: function (
    capsuleId,
    actionTime,
    additionalData,
    context,
    callback,
    sync,
    existingLogEntryIdToUpdate,
  ) {
    return this.addTrebbleActionToMyHistory(
      window.trebble.usedProtocolPrefix + trebbleServerUrl + "/addRadioLoadedActionToHistory",
      null,
      capsuleId,
      null,
      null,
      actionTime,
      additionalData,
      context,
      callback,
      sync,
      existingLogEntryIdToUpdate,
    );
  },

  addTebbleFinishPlayingActionToMyHistory: function (
    radioLoaderKey,
    radioName,
    actionTime,
    additionalData,
    context,
    callback,
    sync,
    existingLogEntryIdToUpdate,
  ) {
    return this.addTrebbleActionToMyHistory(
      window.trebble.usedProtocolPrefix + trebbleServerUrl + "/addRadioFinishPlayingActionToHistory",
      radioLoaderKey,
      null,
      null,
      radioName,
      actionTime,
      additionalData,
      context,
      callback,
      sync,
      existingLogEntryIdToUpdate,
    );
  },

  addUserFeedPlayedActionToHistory: function (
    userFeedId,
    actionTime,
    additionalData,
    context,
    callback,
    sync,
    existingLogEntryIdToUpdate,
  ) {
    return this.addTrebbleActionToMyHistory(
      window.trebble.usedProtocolPrefix + trebbleServerUrl + "/addUserFeedPlayedActionToHistory",
      userFeedId,
      null,
      null,
      null,
      actionTime,
      additionalData,
      context,
      callback,
      sync,
      existingLogEntryIdToUpdate,
    );
  },

  addCategoryShortcastRadioPlayedActionToHistory: function (
    categoryId,
    actionTime,
    additionalData,
    context,
    callback,
    sync,
    existingLogEntryIdToUpdate,
  ) {
    return this.addTrebbleActionToMyHistory(
      window.trebble.usedProtocolPrefix + trebbleServerUrl + "/addCategoryShortcastRadioPlayedActionToHistory",
      null,
      null,
      null,
      null,
      actionTime,
      additionalData,
      context,
      callback,
      sync,
      existingLogEntryIdToUpdate,
      categoryId,
    );
  },

  //Only one from radioLoaderKey, capsuleId and songId should be specified
  addTrebbleActionToMyHistory: function (
    url,
    radioLoaderKey,
    capsuleId,
    songId,
    radioName,
    actionTime,
    additionalData,
    context,
    callback,
    sync,
    existingLogEntryIdToUpdate,
    categoryId,
  ) {
    const apiSelf = this;
    const dataToSend = {
      loaderKey: radioLoaderKey,
      trebbleId: radioLoaderKey,
      capsuleId: capsuleId,
      songId: songId,
      trebbleName: radioName,
      time: actionTime,
      additionalData: additionalData,
      actionContext: context,
      existingLogEntryIdToUpdate: existingLogEntryIdToUpdate,
      referrer: window.trebble.referrer,
      isEmbedded: window.isEmbedded,
      categoryId: categoryId,
      uniqueUserIdentifier: LocalStorageHelper.getInstance().getUniqueUserIdentifier(),
      customTrackingKey: window.trebble.customTrackingKey,
    };
    const resolveFunc = function (resolve, reject) {
      $.post(url, dataToSend)
        .done(function (result) {
          apiSelf._getDefaultResponseHandler(result, resolve, reject, callback, "Add Player Action History");
        })
        .fail(function (error) {
          apiSelf._getDefaultFailedResponseHandler(error, reject, callback, "Add Player Action History");
        });
    };

    if (sync) {
      $.ajax({
        type: "POST",
        url: url,
        data: dataToSend,
        success: function (result) {
          apiSelf._getDefaultResponseHandler(
            result,
            function () {},
            function () {},
            callback,
            "Add Player Action History",
          );
        },
        async: false,
      });
      return RSVP.Promise.resolve();
    } else {
      return this._doLogin().then(function () {
        return new RSVP.Promise(resolveFunc);
      });
    }
  },

  addPlayerActionToMyHistory: function (
    url,
    songTitle,
    artist,
    uri,
    actionTime,
    songJson,
    additionalData,
    context,
    songRefSummary,
    schedulingPosition,
    playbackElapsedTime,
    callback,
    weight,
  ) {
    const apiSelf = this;
    const resolveFunc = function (resolve, reject) {
      $.post(url, {
        songTitle: songTitle,
        artistName: artist,
        uri: uri,
        time: actionTime,
        songCachedData: songJson ? songJson._hiddedDBData : null,
        additionalData: additionalData,
        actionContext: context,
        songRefSummary: songRefSummary,
        schedulingPosition: schedulingPosition,
        playbackElapsedTime: playbackElapsedTime,
        weight: weight,
        referrer: window.trebble.referrer,
        isEmbedded: window.isEmbedded,
        uniqueUserIdentifier: LocalStorageHelper.getInstance().getUniqueUserIdentifier(),
        customTrackingKey: window.trebble.customTrackingKey,
      })
        .done(function (result) {
          apiSelf._getDefaultResponseHandler(result, resolve, reject, callback, "Add Player Action History");
        })
        .fail(function (error) {
          apiSelf._getDefaultFailedResponseHandler(error, reject, callback, "Add Player Action History");
        });
    };
    return this._doLogin().then(function () {
      return new RSVP.Promise(resolveFunc);
    });
  },
});

const TrebbleClientAPI = new TrebbleClientAPIModel();

export default {
  getInstance: function () {
    return TrebbleClientAPI;
  },
};
