import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import CordovaHelper from "models/helper/CordovaHelper";
import ti18n from "i18n!nls/helperi18n";
import RSVP from "rsvp";



const YOUTUBE_URL_PREFIX = "https://www.youtube.com/watch?v=";
const VERIFY_AGE_PREFIX = "verify_age?";
const VERIFY_AGE_THUMB = "verify-age-thumb";
const CAPTCHA_PREFIX = "das_captcha";
const VIDEO_TYPE_ID_TO_INFO = {
    "139": {"itagId": "139",
        "type": "M4A",
        "detail": "Low Quality - 640x360"
    },
    
    "140": {"itagId": "140",
        "type": "M4A",
        "detail": "Medium Quality - 854x480"
    },
    "141": {"itagId": "141",
        "type": "M4A",
        "detail": "High Quality - 1280x720"
    },
    "171": {"itagId": "171",
        "type": "WEBMA",
        "detail": "Medium Quality - 854x480"
    },
    "172": {"itagId": "172",
        "type": "WEBMA",
        "detail": "High Quality - 1280x720"
    },
    "43": {"itagId": "43",
        "type": "WEBM",
        "detail": "Low Quality - 640x360"
    },
    "44": {"itagId": "44",
        "type": "WEBM",
        "detail": "Medium Quality - 854x480"
    },
    "45": {"itagId": "45",
        "type": "WEBM",
        "detail": "High Quality - 1280x720"
    },
    "22": {"itagId": "22",
        "type": "MP4",
        "detail": "High Quality - 1280x720"
    },
    "37": {"itagId": "37",
        "type": "MP4",
        "detail": "High Quality - 1920x1080"
    },
    "38": {"itagId": "38",
        "type": "MP4",
        "detail": "High Quality - 4096x230"
    },
    "5": {"itagId": "5",
        "type": "FLV",
        "detail": "High Quality - 4096x230"
    },
}
const YoutubeVideoUrlExtractor = Backbone.Model.extend({
    
    initialize: function() {
    
    },
    
    getVideoUrl: function(videoId) {
        if (videoId) {
            const youtubeVideoUrl = YOUTUBE_URL_PREFIX + videoId;
            let htmlContent = null;
            let playerJsUrl
            return this.getHtmlContent(youtubeVideoUrl).then((function(html) {
                htmlContent = Utils.getInstance().replaceAll("\\u0026", "&", html);
                if (htmlContent.indexOf(VERIFY_AGE_THUMB)) {
                    console.error("YouTube is asking for age verification. We can't handle that sorry.");
                //throw new Error("YouTube is asking for age verification. We can't handle that sorry.");
                }
                if (htmlContent.indexOf(CAPTCHA_PREFIX)) {
                    console.error("Captcha found, please try with different IP address.");
                //throw new Error("Captcha found, please try with different IP address.");
                }
                return this.getExtractPlayerUrl(htmlContent);
            
            }).bind(this)).then((function(playerUrl) {
                playerJsUrl = playerUrl;
                return this.getDirectVideoInfos(htmlContent);
            }).bind(this)).then((function(directVideoInfos) {
                if (directVideoInfos) {
                    directVideoInfos.playerJSUrl = playerJsUrl;
                }
                return directVideoInfos;
            }).bind(this));
        
        } else {
            return RSVP.Promise.resolve();
        }
    },
    
    getDirectVideoInfos: function(html) {
        const re1 =  /stream_map\":\"(.*?)?\"/g;
        let atLeastOneStreamMapFound = false;
       // const streamMap = html.match(/stream_map\":\"(.*?)?\"/g);
       let streamMap = null;
       let urls = null;
        const itagToDirectUrl = {};
        const itagToSignature = {};
        const directVideoInfo = {};
        while((streamMap = re1.exec(html)) !== null){
            if (!streamMap || streamMap.length == 0) {
                if(!atLeastOneStreamMapFound){
                    console.error("Cannot find the list of itags.");
                    throw new Error(window.getI18n(ti18n, "CANNOT_FIND_THE_LIST_OF_ITAGS"));
                }
            } else {
                atLeastOneStreamMapFound = true;
                urls = streamMap[1].split(",");
                for (let index in urls) {
                    const url = urls[index];
                    const decodedUrl = Utils.getInstance().decode_utf8(url);
                    const itagMatch = decodedUrl.match(/itag=([0-9]+?)([&]|$)/);
                    const directVideoUrlMatch = url.match(/url=(.*?)([&]|$)/);
                    
                    let directVideoUrl = (directVideoUrlMatch && directVideoUrlMatch.length > 0) ? directVideoUrlMatch[1] : null;
                    directVideoUrl = Utils.getInstance().decode_utf8(directVideoUrl);
                    const itag = (itagMatch && itagMatch.length > 0) ? itagMatch[1] : null;
                    
                    let startSig = decodedUrl.indexOf("&signature=");
                    let sigPrefixLength = ("&signature=").length;
                    let signatureExplicit = false;
                    if (startSig == -1) 
                    {
                        startSig = decodedUrl.indexOf('&s=');
                        sigPrefixLength = ('&s=').length;
                        if (startSig == -1) 
                        {
                            startSig = decodedUrl.indexOf("signature=");
                            sigPrefixLength = ("signature=").length;
                            if (startSig == -1) 
                            {
                                startSig = decodedUrl.indexOf('s=');
                                sigPrefixLength = ('s=').length;
                            }else{
                                signatureExplicit = true;
                            }
                        }
                    }else{
                        signatureExplicit = true;
                    }
                   
                    let signature = null;
                    if (startSig > -1 && !signatureExplicit) 
                    {
                        if (decodedUrl.indexOf("&", startSig + sigPrefixLength) > -1) 
                        {
                            signature = decodedUrl.substring(startSig + sigPrefixLength, decodedUrl.indexOf("&", startSig + sigPrefixLength));
                        } else {
                            signature = decodedUrl.substring(startSig + sigPrefixLength);
                        }
                        if(signature && !signatureExplicit){
                            directVideoUrl = directVideoUrl +  "&" + "signature=" + signature;
                        }
                        
                    }
                
                    
                    if (itag && directVideoUrl) {
                        itagToDirectUrl[itag] = directVideoUrl;
                        if (signature) {
                            itagToSignature[itag] = signature;
                        }
                    }
                
                
                }
               
                
                
            }
        }
        directVideoInfo.itagToSignature = itagToSignature;
        directVideoInfo.itagToUrl = itagToDirectUrl;
        return directVideoInfo;
    },
    
    getExtractPlayerUrl: function(html) {
        const matches = html.match(/\"assets\":.+?\"js\":\s*(\"[^\"]+\")/);
        let playerJsUrl = null;
        if (!matches || matches.length == 0) {
            console.error("Cannot find the ytplayer url.");
            throw new Error(window.getI18n(ti18n, "CANNOT_FIND_THE_VIDEO_URL"));
        } else {
            playerJsUrl = matches[1];
            playerJsUrl = Utils.getInstance().decode_utf8(playerJsUrl);
        }
        return playerJsUrl;
    },
    
    getHtmlContentViaNative: function(url) {
        return CordovaHelper.getInstance().executePluginOperation("MusicFileReaderPlugin", "downloadHtmlContent", [url]).then((function(htmlContent) {
            return htmlContent;
        }).bind(this));
    },
    getHtmlContent: function(url) {
        if (url) {
            const youtubeVideoUrl = url;
            return this.getHtmlContentViaNative(youtubeVideoUrl).then(function(content) {
                if (!content) {
                    throw new Error(window.getI18n(ti18n, "NO_CONTENT_FOUND_FOR_GIVEN_URL"));
                } else {
                    const verifyAgePrefixPosition = content.indexOf(VERIFY_AGE_PREFIX);
                    if (verifyAgePrefixPosition != -1) {
                        const startRedirectUrl = content.indexOf('value="', verifyAgePrefixPosition + 8);
                        const endUrlRedirectUrl = content.indexOf('"', startRedirectUrl + 8);
                        const redirectUrl = content.substring(startRedirectUrl, endUrlRedirectUrl);
                        const newVideoUrl = YOUTUBE_URL_PREFIX + redirectUrl;
                        return this.getHtmlContentViaNative(newVideoUrl);
                    } else {
                        return content;
                    }
                }
            });
        } else {
            return RSVP.Promise.resolve();
        }
    },




});
const youtubeVideoUrlExtractorInstance = new YoutubeVideoUrlExtractor();

export default {
    getInstance: function() {
        return youtubeVideoUrlExtractorInstance;
    }
};