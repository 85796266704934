

import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import ChooseSoundWidgetController from "controllers/chooseSound/ChooseSoundWidgetController";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import CollectionRepeatController from "controllers/common/CollectionRepeatController";
import TrebbleAudioCompilationListItemController from "controllers/common/TrebbleAudioCompilationListItemController";
import PlaylistHelper from "models/helper/PlaylistHelper";
import TrebbleAudio from "models/TrebbleAudio";
import FileUploadHelper from "models/helper/FileUploadHelper";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import ti18n from "i18n!nls/ConfigureBackgroundMusici18n";
import FacebookEventTrackingHelper from "models/helper/FacebookEventTrackingHelper";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const Rollout = RolloutHelper.getInstance();

//const CONFIGURE_BACKGROUND_MUSIC = Rollout.isFeatureEnabled(Rollout.FEATURES.CONFIGURE_BACKGROUND_MUSIC);

const ChooseJourneyWidgetController = ChooseSoundWidgetController.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if(!options.numberOfResultsPerPage){
            options.numberOfResultsPerPage = 21;
        }
        options.onTrebbleAudioSelected = function(trebbleAudioSelected){
            trebbleAudioSelected.setSelected(true);
            if(options.onJourneySelected){
                options.onJourneySelected(trebbleAudioSelected? trebbleAudioSelected.getModelSourceReference(): null);
            }
        }
        options.onTrebbleAudioUnselected = function(trebbleAudioUnselected){
            trebbleAudioUnselected.setSelected(false);
            if(options.onJourneyUnselected){
                options.onJourneyUnselected(trebbleAudioUnselected? trebbleAudioUnselected.getModelSourceReference(): null);
            }
        }
            
        this._onTrebbleAudioUnselected = options.onTrebbleAudioUnselected;
        return ChooseSoundWidgetController.prototype.initialize.call(this, options);
    },

    getControllerItemParams : function(){
        const controllerParams = {};

        controllerParams.customImageUrlProperty = "data-original";
        controllerParams.notTransparent = false;
        controllerParams.canBeSelected = true;
        controllerParams.covertArtNotDisplayed = false;
        controllerParams.onTrebbleAudioCompilationSelected = this.onTrebbleAudioSelected.bind(this);
        controllerParams.onTrebbleAudioCompilationUnselected = this.onTrebbleAudioUnselected.bind(this);
        return controllerParams;
    },

    getItemControllerConstructor : function(){
        return TrebbleAudioCompilationListItemController
    },



    
    _buildInfoBoxParamsForTrebbleAudioListController : function(){
        const params = {};
        params.iconClass = "pe-7s-music";
        params.message = window.getI18n(ti18n, "NO_BACKGROUND_MUSIC_IMPORTED");
        return params;
    },



});

export default ChooseJourneyWidgetController;