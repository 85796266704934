
import Backbone from "backbone";

const Album=  Backbone.Model.extend({
     idAttribute: "albumId",
     
    "default": {
        "title": "",
        "artist": "",
        "idifiedTitle": "",
        "idifiedArtist": false,
        "idified": false,
        "idifiedSuccess": false,
        "_completeAlbumInfoJson" : null,
    },



     get: function (attr) {

            if(attr ==="title" && window.trebble.config.showIdifiedAlbumTitle )
            {
                return Backbone.Model.prototype.get.call(this, "idifiedTitle");
            }
            if(attr ==="artist" && window.trebble.config.showIdifiedArtistName)
            {
                return Backbone.Model.prototype.get.call(this, "idifiedArtist");
            }

        return Backbone.Model.prototype.get.call(this, attr);
    },
    
    /*getAllSongAlbumArtistJsonFromAlbumIdPromise : function(){
        if(this._completeAlbumInfoJson)
        {
            const songJsonArray =  (this._completeAlbumInfoJson && this._completeAlbumInfoJson.songs)? this._completeAlbumInfoJson.songs : [];
            return RSVP.Promise.resolve(songJsonArray);
        }else{
            return 	TrebbleClientAPIHelper.getInstance().getAllSongsInAlbumFromAlbumId(this.get("albumId")).then((function(completeAlbumInfo){
                this._completeAlbumInfoJson = completeAlbumInfo;
                
                return (this._completeAlbumInfoJson && this._completeAlbumInfoJson.songs)? this._completeAlbumInfoJson.songs : [];
            }).bind(this));
        }
        
    },*/



});
export default Album;