import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import Utils from "models/helper/Utils";
import FooterMenuBarView from "views/footerMenuBar/FooterMenuBarView";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";

const FooterMenuBarController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if (options) {
            if (options.view) {
                this.view = options.view;
            }
            if (options.model) {
                this.model = options.model;
            }
        }
        const userInfoJson= LocalStorageHelper.getInstance().getUserInfo();
        this._userInfo =  userInfoJson?Utils.getInstance().getModelFromUserJson(userInfoJson):null;
        this.canBeShownOnActivePage = (options && options.canBeShownOnActivePage) || false;
        this.transparent = (options && options.transparent) || false;
        this.listenTo(PersistentModels.getInstance().getRouter(),"panelMenuMethodCall", this.onSlidingPanelMenuCalled.bind(this));
        //this.listenTo(LocalStorageHelper.getInstance(),LocalStorageHelper.getInstance().eventTypes.USER_INFO_UPDATED, this.onUserInfoChanged.bind(this));

    },

    

    getModel : function() {
        return this.model;
    },

    setCanBeShownOnActivePage : function(canBeShownOnActivePage, transparent){
        this.canBeShownOnActivePage = canBeShownOnActivePage;
        this.transparent = transparent;
        if(this.getView())
        {
            this.getView().setCanBeShownOnActivePage(this.canBeShownOnActivePage, transparent);
        }
    },

    onSlidingPanelMenuCalled : function(menuFunctionCalled){
        if(this.view[menuFunctionCalled]){
            this.view[menuFunctionCalled]();
        }
    },

    updateActivePageHeightToMatchFooterMenuHeight : function(){
        $.mobile.resetActivePageHeight(this.canBeShownOnActivePage? window.getDocumentClienHeight() +60: null); 
    },

    isFooterMenuViewDisplayed : function(){
        if(this.getView())
        {
            return this.getView().isFooterMenuViewDisplayed();
        }
        return false;
    },

    isFooterMenuViewTransparent : function(){
        return this.transparent;
    },

    getView : function() {
        return this.view;
    },

    deselectSelectedMenu : function(){
        return this.view.deselectSelectedMenu();
    },

    showHomePage : function(){
        PersistentModels.getInstance().getRouter().showNewHomePage( true);
    },

    showMySubscriptionsPage : function(){
        PersistentModels.getInstance().getRouter().showMySubscriptionsPage( true);
    },

    showExplorePage : function(){
        PersistentModels.getInstance().getRouter().showExplorePage(null, true);
    },

    showGlobalSearch : function(){
        PersistentModels.getInstance().getRouter().showGlobalSearchPage(null, true);
    },

    showLoggedInUserPage  : function(){
        if(this._userInfo){
            PersistentModels.getInstance().getRouter().showUserProfilePageByUsername(null,this._userInfo.getUserId(), null, true );
        }else{
            TrebbleClientAPIHelper.getInstance().doLoginWithLoginPage().then((function(){
                if(this._userInfo){
                    PersistentModels.getInstance().getRouter().showUserProfilePageByUsername(null,this._userInfo.getUserId(), null, true );
                }
            }).bind(this));
        }
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                

                this.view = new FooterMenuBarView({"model" : this.model, "canBeShownOnActivePage" : this.canBeShownOnActivePage });
                this.listenTo(this.view, "showHomePage", this.showHomePage);
                this.listenTo(this.view, "showMySubscriptionsPage", this.showMySubscriptionsPage);
                this.listenTo(this.view, "showExplorePage", this.showExplorePage);
                this.listenTo(this.view, "showGlobalSearch", this.showGlobalSearch);
                this.listenTo(this.view,"showLoggedInUserPage",this.showLoggedInUserPage);
                this.view.render().then(resolve);
                //	resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default FooterMenuBarController;