import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import SongListController from "controllers/common/SongListController";
import PageView from "views/common/PageView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utils from "models/helper/Utils";
import PlayerService from "services/PlayerService";
import RSVP from "rsvp";

const SongListPageController = Chaplin.Controller.extend({


    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if (options) {
            if (options.songCollection) {
                this.model = options.songCollection;
                this.songCollection = options.songCollection;
            }
            if (options.pageTitle) {
                this.pageTitle = options.pageTitle;
            }
            if (options.pageId) {
                this.pageId = options.pageId;
            }
            if (options.searchKeyword) {
                this.searchKeyword = options.searchKeyword;
            }
            
        }
        const songListControllerParams = {};
        songListControllerParams.sortField = "title";
        songListControllerParams.resizePlaceHolderElem = false;
        songListControllerParams.doNotSortDisplayedCollection = options.doNotSortDisplayedCollection;
        songListControllerParams.loadHandler =(options && options.songListLoadHandler)?options.songListLoadHandler: this.getSongListLoadHandler().bind(this);
        songListControllerParams.context =  Utils.getInstance().buildContextForSongCollectionGlobalSearch(this.searchKeyword);
        this._songWithAudioPreviewOnly =  (options && options.songWithAudioPreviewOnly)?options.songWithAudioPreviewOnly: true;
        this.songListController = new SongListController(songListControllerParams);
        
                    
    },
    getSearchKeyword :function(){
        return this.searchKeyword;
    },
    
    getModel : function(){
        return this.model;
    },
    
    getView : function(){
        return this.view;
    },
    
    updateListOfSongs : function(arrayOfSongsJson){
        this.model.reset(arrayOfSongsJson);
    },

    getSongListLoadHandler : function(){
        const searchKeyword = this.searchKeyword;
        return (function(){
            return TrebbleClientAPIHelper.getInstance().searchForAllSongs(this.searchKeyword && this.searchKeyword.trim(), 100, 1,this._songWithAudioPreviewOnly).then(function(results){
                return results.songs;
            });
        }).bind(this);
    },

    onViewRemoved:function(){
        this.stopListening();
    },
    
    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve,reject){
            try{
                this.songListController.buildModelAndRenderView().then((function(){
                    const songListView = this.songListController.getView();
                    const pageViewParams= {};
                    pageViewParams.pageId = this.pageId;
                    pageViewParams.pageTitle = this.pageTitle;
                    pageViewParams.contentView = songListView;
                    pageViewParams.addContentLeftRightPadding = true;
                    this.view = new PageView(pageViewParams);
                    this.view.render();
                    this.listenTo(this.view, "remove", this.onViewRemoved.bind(this));
                    resolve(this);
                }).bind(this)).catch(reject);

            }catch(error){
                reject(error);
            }
            
        }).bind(this));

        
    }

});
export default SongListPageController;