import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import Utils from "models/helper/Utils";
import QueueTuningView from "views/queue/QueueTuningView";
import ti18n from "i18n!nls/Queuei18n";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import RSVP from "rsvp";

const QueueTuningController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if(options)
        {
            if(options.model)
            {
                this.model = options.model;
            }
        }
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                if(!this.model)
                {
                    throw window.getI18n(ti18n, "QUEUE_INFORMATION_CANNOT_BE_FOUND");
                }
                this.view = new QueueTuningView({
                    "queue": this.model
                });
                this.view.render();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default QueueTuningController;