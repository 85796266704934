
import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import DeleteSelectedNodesOperation from "models/audioEditor/sequencerOperation/DeleteSelectedNodesOperation";
import RSVP from "rsvp";


const RevertDeleteSelectedNodesOperation =  DeleteSelectedNodesOperation.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        DeleteSelectedNodesOperation.apply(this, [attributes, options]);
    },

    execute : function(deletedSequencerNode){
        const arrayOfNodeReplaced = deletedSequencerNode.getArrayOfNodeReplaced();
        this.revertReplacement(deletedSequencerNode);
        this.getSequencer().getHistoryManagement().addDeleteNodeDeletedLog(arrayOfNodeReplaced, deletedSequencerNode);
        
    },

    
    undo : function(sequencerOperationDeleteDeleteNodesLog ){
        return DeleteSelectedNodesOperation.prototype.redo.apply(this, [sequencerOperationDeleteDeleteNodesLog]);
    },

    redo: function(sequencerOperationDeleteDeleteNodesLog){
        return DeleteSelectedNodesOperation.prototype.undo.apply(this, [sequencerOperationDeleteDeleteNodesLog]);
    },

});


export default RevertDeleteSelectedNodesOperation; 