

import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import ChooseSoundWidgetView from "views/chooseSound/ChooseSoundWidgetView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import CollectionRepeatController from "controllers/common/CollectionRepeatController";
import TrebbleAudioListItemController from "controllers/common/TrebbleAudioListItemController";
import PlaylistHelper from "models/helper/PlaylistHelper";
import TrebbleAudio from "models/TrebbleAudio";
import FileUploadHelper from "models/helper/FileUploadHelper";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import ti18n from "i18n!nls/ConfigureBackgroundMusici18n";
import FacebookEventTrackingHelper from "models/helper/FacebookEventTrackingHelper";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const Rollout = RolloutHelper.getInstance();

//const CONFIGURE_BACKGROUND_MUSIC = Rollout.isFeatureEnabled(Rollout.FEATURES.CONFIGURE_BACKGROUND_MUSIC);

const ChooseSoundWidgetController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this._selectedTrebbleAudio = null;
        this._numberOfResultsPerPage  = options.numberOfResultsPerPage;
        this._onTrebbleAudioSelected = options.onTrebbleAudioSelected;
        this._onTrebbleAudioUnselected = options.onTrebbleAudioUnselected;
        this._searchWord =  "";
        this._getTrebbleAudiosLoadHandler = options.getTrebbleAudiosLoadHandler;
        this._sourceCreditName = options.sourceCreditName;
        this._sourceCreditWebsite = options.sourceCreditWebsite;
    },


    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },


    setSelectedTrebbleAudio : function(trebbleAudioSelected){
        this.onTrebbleAudioSelected(trebbleAudioSelected);
    },

    setViewLoading :function(isLoading){
        this.view.isLoading(isLoading);
    },

    _buildInfoBoxParamsForTrebbleAudioListController : function(){
        const params = {};
        params.iconClass = "soundwave-sound-wave";
        params.message = window.getI18n(ti18n, "NO_RESULT_FOUND");
        return params;
    },

    getSelectedTrebbleAudio : function(){
        return this._selectedTrebbleAudio;
    },

    _buildTrebbleAudioListController: function() {
        const controllerParams = this.getControllerItemParams();

        const collectionRepeatControllerParams = {};
        collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildInfoBoxParamsForTrebbleAudioListController();
        collectionRepeatControllerParams.showEmtptyInfoBox = true;
        collectionRepeatControllerParams.controllerOption = controllerParams;
        collectionRepeatControllerParams.itemControllerConstructor = this.getItemControllerConstructor();
        collectionRepeatControllerParams.context = this.context;
        collectionRepeatControllerParams.resizePlaceHolderElem = true;
        collectionRepeatControllerParams.heightMargin = 52 + 39 + 50; //Page Header + Close Button + tab section

        collectionRepeatControllerParams.modelLoadMoreHandler = this._getTrebbleAudiosToDisplayWithPaging.bind(this);
        collectionRepeatControllerParams.loadOnScroll = true;
        collectionRepeatControllerParams.numberOfResultsPerPage = this._numberOfResultsPerPage? this._numberOfResultsPerPage: 21;

        this.trebbleAudioListController = new CollectionRepeatController(collectionRepeatControllerParams);


    },

    getControllerItemParams : function(){
        const controllerParams = {};

        controllerParams.customImageUrlProperty = "data-original";
        controllerParams.notTransparent = false;
        controllerParams.canBeSelected = true;
        controllerParams.doNotShowArtistName = true;
        controllerParams.covertArtNotDisplayed = true;
        controllerParams.onTrebbleAudioSelected = this.onTrebbleAudioSelected.bind(this);
        controllerParams.onTrebbleAudioUnselected = this.onTrebbleAudioUnselected.bind(this);
        return controllerParams;
    },

    getItemControllerConstructor : function(){
        return TrebbleAudioListItemController
    },

    _getTrebbleAudiosToDisplayWithPaging  : function(filter, offset, numberOfItemToLoad){
        return this._getTrebbleAudiosLoadHandler(this._searchWord, offset, numberOfItemToLoad).then((function(trebbleAudioModelArray){
            if(trebbleAudioModelArray && trebbleAudioModelArray.length){
                for(let i =0; i < trebbleAudioModelArray.length; i++){
                    const trebbleAudioModel = trebbleAudioModelArray[i];
                    if(this._selectedTrebbleAudio && this._selectedTrebbleAudio.getAudioId() === trebbleAudioModel.getAudioId()){
                        trebbleAudioModel.setSelected(true);
                    }
                }
            }
            return trebbleAudioModelArray;
        }).bind(this));
    },

    onTrebbleAudioPlaybackStateChange: function(trebbleAudioMode, isPlaying, isLoading){
        this._isBackgroundMusicAudioPreviewPlaying = isPlaying || isLoading;
    },

    isBackgroundMusicAudioPreviewPlaying : function(){
        return this._isBackgroundMusicAudioPreviewPlaying
    },

    onTrebbleAudioSelected : function(trebbleAudioSelected){
        if(this._selectedTrebbleAudio){
            this._selectedTrebbleAudio.setSelected(false);
        }
        if(trebbleAudioSelected){
            trebbleAudioSelected.setSelected(true);
        }
        this._selectedTrebbleAudio = trebbleAudioSelected;
        if(this._onTrebbleAudioSelected){
            this._onTrebbleAudioSelected(this._selectedTrebbleAudio)
        }
    },

    onTrebbleAudioUnselected : function(trebbleAudioUnselected){
        trebbleAudioUnselected.setSelected(false);
        this._selectedTrebbleAudio = null;
        if(this._onTrebbleAudioUnselected){
            this._onTrebbleAudioUnselected(trebbleAudioUnselected)
        }
    },

    setScrollContainer : function(scrollContainer){
        this.trebbleAudioListController.setScrollContainer(scrollContainer);
    },

    _onSearchValueChanged : function(searchFieldValue){
        this._searchWord = searchFieldValue;
        if(this.trebbleAudioListController){
            this.trebbleAudioListController.reload();
        }
    },

    _onSearchFieldCleared : function(){
        this.onSearchValueChanged("");
    },

    buildModelAndRenderView : function() {
        this._buildTrebbleAudioListController();
        return this.trebbleAudioListController.buildModelAndRenderView().then((function(){
            const params = {};
            params.trebbleAudioListView = this.trebbleAudioListController.getView();
            params.sourceCreditName = 	this._sourceCreditName ;
            params.sourceCreditWebsite = this._sourceCreditWebsite;
            this.view = new ChooseSoundWidgetView(params);


            this.listenTo(this.view, "keyUpOnSearchField", this._onSearchValueChanged);
            this.listenTo(this.view, "searchFieldCleared", this._onSearchFieldCleared);

            this.view.render();
        }).bind(this))


    }

});

export default ChooseSoundWidgetController;