import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import ti18n from "i18n!nls/Commoni18n";
import PersistentModels from "services/PersistentModels";
import pageViewTemplate from 'text!../../../templates/common/pageViewTemplate.html';

const PageView = Backbone.View.extend({



    initialize : function(options) {
        this.defaultTemplate =  _.template(pageViewTemplate);
        this.model = options? options.model : null;
        this._pageTitle = (options && options.pageTitle != null)? options.pageTitle: "";
        this._pageId = (options && options.pageId != null)? options.pageId : "";
        this._contentView = (options && options.contentView != null)? options.contentView : null;
        this._persistent = (options && options.persistent != null)? options.persistent : false ;
        this._refreshContentOnPageShow = (options && options.refreshContentOnPageShow != null)? options.refreshContentOnPageShow :false;
        this._contentViewAppended = false;
        this._contentLoadedOnce = false;
        this._addContentLeftRightPadding = (options && options.addContentLeftRightPadding != null)? options.addContentLeftRightPadding: false;
                    
    },
    
    events : {
        "click #header-back-button": "navigateToPreviousPage",
    },

    navigateToPreviousPage : function(){
        PersistentModels.getInstance().getRouter().navigateToPreviousPage();
    },
    getPageId : function(){
        return this._pageId;
    },
    
    _onPageShow : function(){
        if(!this._contentLoadedOnce)
        {
            this._loadContentView();
        
        }else{
            if(this._refreshContentOnPageShow)
            {
                this._loadContentView();
            }
        }
        
    },
    
    _loadContentView : function(){
        if(this._contentView)
        {
            if(this._contentView.load){
                this._onContentLoading();
                /*if(this._contentView.canBeAppendedBeforeLoad && this._contentView.canBeAppendedBeforeLoad() ){
                if(!this._contentViewAppended){
                    this.$el.find(this._containerSelector).first().append(this._contentView.$el);
                    this._contentViewAppended = true;
                }
                }*/
                if(this._contentView.$el ){
                    if(!this._contentViewAppended){
                        this.$el.find(".ui-content > div:first").first().append(this._contentView.$el);
                        this._contentViewAppended = true;
                    }
                }
                this._contentView.load().then((function(){
                    if(!this._contentViewAppended){
                        this.$el.find(".ui-content > div:first").first().append(this._contentView.$el);
                        this._contentViewAppended = true;
                    }
                    this._onContentLoaded();
                }).bind(this)).catch((function(error){
                    this._onContentFailedLoading(error);
                }).bind(this));
            }else{
                if(!this._contentViewAppended){
                    this.$el.find(".ui-content").first().append(this._contentView.$el);
                }
            }
            this._contentLoadedOnce = true;
        }
    },
    
    _onContentLoading : function(){
        $.mobile.loading( 'show');
    },
    
    _onContentLoaded : function(){
        $.mobile.loading( "hide" );
    },
    
    _onContentFailedLoading : function(error){
        $.mobile.loading( "hide" );
        console.error("Page content failed loading. Error:"+error);
        window.alertErrorMessage(window.getI18n(ti18n,"PAGE_FAILED_LOADING"));
    },
    
    _onPageHide : function(){
        if(!this._persistent && PersistentModels.getInstance().getRouter().isBrowserHistoryGoingBack())
        {
            this.remove();
        }
        
    },

    
    render : function() {
        const templateParams = {};
        templateParams.pageId = this._pageId;
        templateParams.pageTitle = this._pageTitle;
        templateParams.addContentLeftRightPadding = this._addContentLeftRightPadding;
        this.$el.html(this.defaultTemplate(templateParams));
        this.setElement(this.$el.find("div").first());
        this.$el.page();
        this._loadContentView();
        
        this.$el.on("pagehide", this._onPageHide.bind(this));
        this.$el.on("pageshow", this._onPageShow.bind(this));
        return this;
    }

});
export default PageView;