import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import Levenshtein from "libs/levenshtein/levenshtein";
import ti18n from "i18n!nls/helperi18n";
import RSVP from "rsvp";



const client_id = 'e1ddc5a48279cd6d15c35595c26c7b05';
const DEFAULT_LEVENSTEIN_DISTANCE_ACCEPTABLE= 0.8;
const ACCEPTABLE_TIME_DIFFERENCE_IN_MILLISEC= 3000
const SENSIBLE_KEY_WORDS = ["snippet", "cover","chopped","screwed"];
const SoundcloudSearchHelper =  Backbone.Model.extend({

    initialize : function() {
    
    },
    
    searchSongs : function(query, numberOfresults, numberOfResultFromSoundCloud, allowPreviewSong){
        return new RSVP.Promise((function(resolve,reject){
            try{
                if(query && query.indexOf("https://soundcloud.com/") == 0){
                    
                    this.getSoundcloudTrackInfoFromPermanentUrl(query).then((function(trackInfoFound){
                        const result = [];
                        if(trackInfoFound){
                            result.push(trackInfoFound);
                        }
                        resolve(result);
                    }).bind(this)).catch((function(error){
                        reject(error);
                    }).bind(this));
                }else{
                    if(!numberOfResultFromSoundCloud){
                        numberOfResultFromSoundCloud = numberOfresults;
                    }
                    if(query && query.charAt(query.length-1) == "%"){
                        //THIS IS DONE TO FIX A POSSIBLE IN SOUNDCOUD WHERE IT RETURN AN ERROR IF YOU HAVE THE CHARACTER '%' AT THE END OF THE QUERY
                        query = query +" ";
                    }
                    const scUrl = window.trebble.usedProtocolPrefix+'api.soundcloud.com/tracks.json?client_id='  +client_id+'&q='+query+'&limit='+numberOfResultFromSoundCloud+'&filter=public,streamable';
                    $.get(scUrl, (function (trackInfos) {
                        const arrayOfTracks = [];
                        let numberOfNotStreamableTrack  = 0;
                        if(trackInfos && trackInfos.length > 0)
                        {
                            for(let index  in trackInfos)
                            {
                                const trackInfo = trackInfos[index];
                                //This is a hack needed to bypass the fact that soundcloud will returned not streamable track even with filters. 
                                //Also ignore songs with duration equals to 30000ms = 30 second as they are most likely preview songs
                                if(trackInfo && trackInfo.streamable && (trackInfo.duration != 30000 || allowPreviewSong)){
                                    const trebbleSongJson = Utils.getInstance().getTrebblesSongJsonFromSoundCloudTrackInfo(trackInfo);
                                    arrayOfTracks.push(trebbleSongJson);
                                    if(arrayOfTracks.length == numberOfresults){
                                        break;
                                    }
                                }else{
                                    numberOfNotStreamableTrack = numberOfNotStreamableTrack + 1;
                                }
                            }
                            
                        }
                        if(numberOfNotStreamableTrack > 0)
                        {
                            if((arrayOfTracks.length == numberOfresults) || (numberOfResultFromSoundCloud >= numberOfresults*2) || numberOfResultFromSoundCloud >= 100)
                            {
                                //return results as is
                                resolve(arrayOfTracks);
                            }else{
                                this.searchSongs(query, numberOfresults, numberOfResultFromSoundCloud + numberOfNotStreamableTrack ).then(function(r){
                                    resolve(r);
                                }).catch(reject);
                            }
                        }else{
                            resolve(arrayOfTracks);
                        }
                    
                    }).bind(this)).fail((function(error){
                        console.error(error);
                        resolve([]);
                    }).bind(this));

                }
            }catch(err){
                reject(err);
            }
        }).bind(this));
    },
    
    isStringMatchSensibleKeyWord : function(word1, word2){
        if(!word1 || !word1 ){
            return true;
        }else{
            const word1lowerCase = word1.toLowerCase();
            const word2lowerCase = word2.toLowerCase();
            for(let index in SENSIBLE_KEY_WORDS){
                const sensibleKeyWord = SENSIBLE_KEY_WORDS[index];
                const word1ContainsKeyword = word1lowerCase.indexOf(sensibleKeyWord) > -1;
                const word2ContainsKeyword = word2lowerCase.indexOf(sensibleKeyWord) > -1;
                if((!word1ContainsKeyword && word2ContainsKeyword) || (word1ContainsKeyword && !word2ContainsKeyword)){
                    return false;
                }
            }
        }
        return true;
        
    },
    
    findSoundcloudSongInfoForSong : function(songModel){
        const artistName  = songModel.get("artist")?songModel.get("artist"): "" ;
        const title  = songModel.get("title")?songModel.get("title") : "" ;
        const query = artistName.trim() + " " + title.trim();
        const duration = songModel.get("duration");
        return this.searchSongs(query,4).then((function(arrayOfJsonSongFound){
            let soundCloudInfo = null;
            if(arrayOfJsonSongFound)
            {
                for(let index = 0; index <  arrayOfJsonSongFound.length; index++){
                    const jsonSong =  arrayOfJsonSongFound[index];
                    let artistAndTitle = "" ;
                    if(jsonSong.artist){
                        artistAndTitle =  artistAndTitle + jsonSong.artist.trim();
                    }
                    if(jsonSong.title){
                        artistAndTitle =  artistAndTitle + " " + jsonSong.title.trim();
                    }
                    if(this.isStringMatchSensibleKeyWord(query, artistAndTitle)){
                        const l= new Levenshtein(query? query.toUpperCase(): query, artistAndTitle? artistAndTitle.toUpperCase():artistAndTitle)
                        const distance = l.distance;
                        const biggerLength = Math.max(query.length, artistAndTitle.length);
                        const pct = (biggerLength - distance) / biggerLength
                        let canSoundcloudUriBeUsed = pct >DEFAULT_LEVENSTEIN_DISTANCE_ACCEPTABLE? true : false;
                        if(canSoundcloudUriBeUsed && duration && duration > 0 && jsonSong.duration ){
                            if(Math.abs(jsonSong.duration - duration ) < ACCEPTABLE_TIME_DIFFERENCE_IN_MILLISEC ){ 
                                canSoundcloudUriBeUsed = true;
                            }else{
                                canSoundcloudUriBeUsed = false;
                            }
                        }
                        if(canSoundcloudUriBeUsed){
                            soundCloudInfo = jsonSong;
                            break;
                        }
                    }
                }
            }
            return soundCloudInfo;
            
        }).bind(this));
    },
    
    getSoundcloudTrackInfo : function(soundCloundTrackId){

        return new Promise(function(resolve, reject){
            try{
                const scUrl = window.trebble.usedProtocolPrefix+'api.soundcloud.com/tracks/'+soundCloundTrackId+'.json?client_id='  +client_id;
                window.log(scUrl);
                $.get(scUrl).done( (function (trackInfo) {
                    if(trackInfo.errors && trackInfo.errors.length > 0 ){
                        reject(trackInfo.errors[0].error_message);
                    }else{
                        window.log("soundcloud reack info found:" +trackInfo);
                        resolve(trackInfo);
                    }
                }).bind(this)).fail((function(error) {
                    reject(error);
                  }).bind(this));
            }catch(error){
                reject(error);
            }
        });
        
    },

        getSoundcloudTrackInfoFromPermanentUrl : function(permanentUrl){

        return new Promise(function(resolve, reject){
            try{
                if(permanentUrl && permanentUrl.indexOf("soundcloud.com")  == -1){
                    permanentUrl= "http://soundcloud.com"+permanentUrl;
                }
                const scUrl = window.trebble.usedProtocolPrefix+'api.soundcloud.com/resolve.json?url='+ permanentUrl+'&client_id='  +client_id;
                window.log(scUrl);
                $.get(scUrl).done( (function (trackInfo) {
                    if(trackInfo.errors && trackInfo.errors.length > 0 ){
                        reject(trackInfo.errors[0].error_message);
                    }else{
                        window.log("soundcloud reack info found:" +trackInfo);
                        resolve(trackInfo);
                    }
                }).bind(this)).fail((function(error) {
                    if(error.statusText == "Forbidden"){
                        reject(window.getI18n(ti18n, "THIS_AUDIO_CANNOT_STREAMED_OUTSIDE_OF_SOUNDCLOUD"));
                    }else{
                        reject(error);
                    }
                    
                  }).bind(this));
            }catch(error){
                reject(error);
            }
        });
        
    },
    
    


});
const soundcloudSearchHelperInstance = new SoundcloudSearchHelper();

export default {
    getInstance : function() {
        return soundcloudSearchHelperInstance;
    }
};
