import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import CustomListSelectorPopupController from "controllers/common/CustomListSelectorPopupController";
import ListItemCollection from "collections/ListItemCollection";
import Utils from "models/helper/Utils";
import PersistentModels from "services/PersistentModels";
import ti18n from "i18n!nls/PlayerPagei18n";
import RSVP from "rsvp";

const SkipCurrentPlayingSongWithReasonController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this._sourceElement$el = options? options.sourceElement$el : null;
    

    },
    

    
    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    getAllSkipReasonListItemCollection: function(){
        const listItemCollection = new ListItemCollection();
        const skipTrackReasonsKeyValueObj = 	Utils.getInstance().getSkipTrackReasonsKeyValueObj()
        for(let reasonKey  in skipTrackReasonsKeyValueObj){
            const reasonLabel =  skipTrackReasonsKeyValueObj[reasonKey];
            listItemCollection.add(Utils.getInstance().getListItemModel(reasonKey,  reasonLabel));
        }
        return RSVP.Promise.resolve(listItemCollection.models);
    },
    
    
    buildModelAndRenderView : function() {

        const controllerParams = {};
        controllerParams.singleSelect =  true;
        controllerParams.propertyFunction = "getLabel";
        controllerParams.showEmtptyInfoBox  = false;
        controllerParams.maxWidth = 600;
        controllerParams.listTitleToDisplay =  "Skip Because ...";
        controllerParams.sourceElement$el  = this._sourceElement$el ;
        controllerParams.loadHandler = this.getAllSkipReasonListItemCollection.bind(this);
        const customListSelectorPopupController = new CustomListSelectorPopupController(controllerParams);
        const promiseResult = customListSelectorPopupController.getResultPromise();
        promiseResult.then((function(reasonSelectedListItemObj){
            if(reasonSelectedListItemObj){
                const reasonId =  reasonSelectedListItemObj.getId()
                PersistentModels.getInstance().getPlayer().playNextSongInPlaylist(true, reasonId);
            }
        }).bind(this))
        return customListSelectorPopupController.buildModelAndRenderView().then((function(){
            this.view = customListSelectorPopupController; 
        }).bind(this));

    }

});

export default SkipCurrentPlayingSongWithReasonController;