import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import ti18n from "i18n!nls/AddSongToTrebblei18n";
import PersistentModels from "services/PersistentModels";
import AddSongToTrebbleTemplate from 'text!../../../templates/addSongToTrebble/AddSongToTrebbleTemplate.html';

const AddSongToTrebbleView =  Backbone.View.extend({

    template : _.template(AddSongToTrebbleTemplate),

initialize : function(options) {
    this.loadingMessage$el =null;
},

events : {
    "click #closeBtn" : "onCloseBtnClicked"
},

onCloseBtnClicked : function(){
    this.trigger("closeBtnClicked");
},

setStatus : function(newStatus){
    this.loadingMessage$el.html(newStatus);
},


render : function() {
    this.$el.html(this.template({"ti18n": ti18n}));
    this.setElement(this.$el.find("div").first());
    this.$el.page();
    this.loadingMessage$el = this.$el.find("#loadingMessage");
    return this;
},


    });
export default AddSongToTrebbleView;