

import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import ConfigureBackgroundMusicPopupView from "views/configureBackgroundMusic/ConfigureBackgroundMusicPopupView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import CollectionRepeatController from "controllers/common/CollectionRepeatController";
import TrebbleAudioListItemController from "controllers/common/TrebbleAudioListItemController";
import PlaylistHelper from "models/helper/PlaylistHelper";
import TrebbleAudio from "models/TrebbleAudio";
import TrebbleVideo from "models/TrebbleVideo";
import FileUploadHelper from "models/helper/FileUploadHelper";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import ti18n from "i18n!nls/ConfigureBackgroundMusici18n";
import FacebookEventTrackingHelper from "models/helper/FacebookEventTrackingHelper";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";
import swal from 'sweetalert';




const Rollout = RolloutHelper.getInstance();
//const CONFIGURE_BACKGROUND_MUSIC = Rollout.isFeatureEnabled(Rollout.FEATURES.CONFIGURE_BACKGROUND_MUSIC);

const ConfigureBackgroundMusicPopupController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        //this._categoriesSeedArray = [];
        this._categoryIdToCategoryLabelMap = {}
        this._categoryToBackgroundMusicTrebbleAudioMap = {};
        this._uploadedBackgroundMusicTrebbleAudioArray = [];
        this._selectedBackgroundMusicTrebbleAudio = null;
        this._selectedCategoryId = null;
        this._showingUserUploads = false;
        this._onBackgroundMusicSelected = options.onBackgroundMusicSelected;
    },


    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },


    onPopupClosed : function(){
        if(this._onBackgroundMusicSelected){
            this._onBackgroundMusicSelected(this._selectedBackgroundMusicTrebbleAudio);
        }
        //if(this.isBackgroundMusicAudioPreviewPlaying()){
            PersistentModels.getInstance().getPlayer().releaseCurrentAudioPreviewIfApplicable();
            //}
        },



        setViewLoading :function(isLoading){
            this.view.isLoading(isLoading);
        },

        _getTrebbleAudiosToDisplay : function(){
            let trebbleAudioModelArray = [];
            if(this._showingUserUploads){
                trebbleAudioModelArray = this._uploadedBackgroundMusicTrebbleAudioArray;
            }else{
                if(this._selectedCategoryId){
                    trebbleAudioModelArray = this._categoryToBackgroundMusicTrebbleAudioMap[this._selectedCategoryId];
                }
            }
            return RSVP.Promise.resolve(trebbleAudioModelArray);
        },

        _buildInfoBoxParamsForTrebbleAudioListController : function(){
            const params = {};
            params.iconClass = "pe-7s-music";
            params.message = window.getI18n(ti18n, "NO_BACKGROUND_MUSIC_IMPORTED");

            return params;
        },

        _buildTrebbleAudioListController: function() {



            const controllerParams = {};

            controllerParams.customImageUrlProperty = "data-original";
            controllerParams.notTransparent = false;
            controllerParams.canBeSelected = true;
            controllerParams.doNotShowArtistName = true;
            controllerParams.covertArtNotDisplayed = false;
            controllerParams.onTrebbleAudioSelected = this.onTrebbleAudioSelected.bind(this);
            controllerParams.onTrebbleAudioUnselected = this.onTrebbleAudioUnselected.bind(this);
            controllerParams.onTrebbleAudioRequestToBeDeleted = this.onTrebbleAudioRequestToBeDeleted.bind(this);
            //controllerParams.onPlaybackStateChange =  this.onTrebbleAudioPlaybackStateChange.bind(this);


            const collectionRepeatControllerParams = {};
            collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildInfoBoxParamsForTrebbleAudioListController();
            collectionRepeatControllerParams.showEmtptyInfoBox = true;
            collectionRepeatControllerParams.controllerOption = controllerParams;
            collectionRepeatControllerParams.itemControllerConstructor = TrebbleAudioListItemController;
            collectionRepeatControllerParams.modelLoadHandler = this._getTrebbleAudiosToDisplay.bind(this);
            collectionRepeatControllerParams.context = this.context;
            collectionRepeatControllerParams.resizePlaceHolderElem = true;
            collectionRepeatControllerParams.heightMargin = 52 + 39 + 50; //Page Header + Close Button + tab section

            this.trebbleAudioListController = new CollectionRepeatController(collectionRepeatControllerParams);


        },

        onTrebbleAudioPlaybackStateChange: function(trebbleAudioMode, isPlaying, isLoading){
            this._isBackgroundMusicAudioPreviewPlaying = isPlaying || isLoading;
        },

        isBackgroundMusicAudioPreviewPlaying : function(){
            return this._isBackgroundMusicAudioPreviewPlaying
        },

        onTrebbleAudioSelected : function(trebbleAudioSelected){
            trebbleAudioSelected.setSelected(true);
            this._selectedBackgroundMusicTrebbleAudio = trebbleAudioSelected;
            this.view.close();
        },

        onTrebbleAudioUnselected : function(trebbleAudioUnselected){
            trebbleAudioUnselected.setSelected(false);
            this._selectedBackgroundMusicTrebbleAudio = null;
            this.view.close();
        },

        onTrebbleAudioRequestToBeDeleted : function(trebbleAudioToBeDeleted){
            return TrebbleClientAPIHelper.getInstance().removeUserUploadedMediaToUserLibrary(trebbleAudioToBeDeleted.getAudioId()).then((function(){
                return this.retrieveAndUpdateUserUploadedBackgroundMusic();
            }).bind(this)).then((function(){
                if(this.trebbleAudioListController){
                    this.trebbleAudioListController.reload();
                }
            }).bind(this));
        },

        retrieveAndUpdateUserUploadedBackgroundMusic : function(){
            return TrebbleClientAPIHelper.getInstance().getUserUploadedBackgroundMusic().then((function(userBackgroundMusicTrebbleAudioArray){
                this._uploadedBackgroundMusicTrebbleAudioArray = [];
                if(userBackgroundMusicTrebbleAudioArray && userBackgroundMusicTrebbleAudioArray.length){
                    for(let i =0; i < userBackgroundMusicTrebbleAudioArray.length; i++){
                        const trebbleAudioJSON = userBackgroundMusicTrebbleAudioArray[i];
                        const trebbleAudioOrVideoModel = trebbleAudioJSON.isVideo?  new TrebbleVideo(trebbleAudioJSON):  new TrebbleAudio(trebbleAudioJSON);
                        this._uploadedBackgroundMusicTrebbleAudioArray.push(trebbleAudioOrVideoModel);
                    }
                }
                Utils.getInstance().sortTrebbleAudioModelArray(this._uploadedBackgroundMusicTrebbleAudioArray);
                

            }).bind(this));
        },


        retrieveAndUpdateAvailableDefaultBackgroundMusic : function(){
            if(this.trebbleAudioListController){
                this.trebbleAudioListController.setUpdating(true);
            }
            return TrebbleClientAPIHelper.getInstance().getAvailableDefaultBackgroundMusic().then((function(trebbleBackgroundMusicTrebbleAudioArray){
                this._categoryToBackgroundMusicTrebbleAudioMap = {};
                this._categoryIdArray = [];
                if(trebbleBackgroundMusicTrebbleAudioArray && trebbleBackgroundMusicTrebbleAudioArray.length){
                    for(let i =0; i < trebbleBackgroundMusicTrebbleAudioArray.length; i++){
                        const trebbleAudioJSON = trebbleBackgroundMusicTrebbleAudioArray[i];
                        const trebbleAudioOrVideoModel = trebbleAudioJSON.isVideo?  new TrebbleVideo(trebbleAudioJSON):  new TrebbleAudio(trebbleAudioJSON);
                        const categoryId =  trebbleAudioOrVideoModel.getCategoryId();
                        this._categoryIdToCategoryLabelMap[categoryId] = Utils.getInstance().getBackgroundMusicCategoryLabel(categoryId);
                        if(!this._categoryToBackgroundMusicTrebbleAudioMap[categoryId]){
                            this._categoryToBackgroundMusicTrebbleAudioMap[categoryId] = [];
                        }
                        this._categoryToBackgroundMusicTrebbleAudioMap[categoryId].push(trebbleAudioOrVideoModel);

                    }
                }
                for( let categoryId in this._categoryToBackgroundMusicTrebbleAudioMap){
                    Utils.getInstance().sortTrebbleAudioModelArray(this._categoryToBackgroundMusicTrebbleAudioMap[categoryId]);
                }
                if(this.trebbleAudioListController){
                    this.trebbleAudioListController.setUpdating(false);
                }
            }).bind(this));
},


retrieveAndDisplayBackgroundMusic : function(){
this.setViewLoading(true);

const retrieveDefaultBackgroundMusicPromise = this.retrieveAndUpdateAvailableDefaultBackgroundMusic();

const retrieveUserUploadedBackgroundMusicPromise = this.retrieveAndUpdateUserUploadedBackgroundMusic();

const promises = [];
promises.push(retrieveDefaultBackgroundMusicPromise);
promises.push(retrieveUserUploadedBackgroundMusicPromise);
return  RSVP.Promise.all(promises).then((function(){
this.view.setCategoryIdToCategorylabelMapAvailable(this._categoryIdToCategoryLabelMap);
this.view.showUserImports();
this.setViewLoading(false);
}).bind(this)).catch((function(error){
this.setViewLoading(false);
throw error;
}).bind(this));

},

_onUserUploadsSelected : function(){
this._showingUserUploads = true;
if(this.trebbleAudioListController){
this.trebbleAudioListController.reload();
}
},

_onBackgroundMusicCategorySelected : function(selectedCategoryId){
this._selectedCategoryId = selectedCategoryId;
this._showingUserUploads = false;
if(this.trebbleAudioListController){
this.trebbleAudioListController.reload();
}
},



showImportYourOwnMusicPopup : function(){

const loggedInUserUID = LocalStorageHelper.getInstance().getUserInfo()._id;
const fileSizeValid = true;
let fileName = null;
return new  RSVP.Promise((function(resolve, reject){
const uploadEementId = "uploadWrapper_"+((new Date()).getTime());
let nameOfFileToUpload =  "userBackgroundMusic_"+(new Date()).getTime()+".mp3";
const uploadInstruction = window.isMobileBrowser ? window.getI18n(ti18n, "TAP_HERE_TO_IMPORT_AN_AUDIO_FILE"): window.getI18n(ti18n, "DRAG_AUDIO_FILE_TO_IMPORT");
const uploadZoneHtml=  "<span class='uploadZonePlaceHolder' id='"+uploadEementId +"'><div class='pe-7s-upload uploadIcon' style='font-size:40px'></div><span  class='dz-message'>"+ uploadInstruction +"</span></span>";
const buttonLabels = [window.getI18n(ti18n,"CANCEL"), window.getI18n(ti18n,"IMPORT")];
let dropZoneObj =  null;
const confirmCallback = (function(buttonIndex){
    if(buttonIndex ==  2){
        if(dropZoneObj){
            if(dropZoneObj.getQueuedFiles().length == 0){
                setTimeout(function(){swal.enableButtons()}, 300);
            }else{
                if(fileSizeValid){
                    const fname = dropZoneObj.getQueuedFiles()[0].name;
                    const extension = fname.substr(fname.lastIndexOf("."));
                    nameOfFileToUpload = "userBackgroundMusic_"+(new Date()).getTime()+extension;
                    FileUploadHelper.getInstance().validateAndSignFunctionHanlder(dropZoneObj, nameOfFileToUpload, FileUploadHelper.getInstance().getAudioFileTypeValidationFunction(), "getSignatureForTrebbleUserBackgroundMusicUpload" ,loggedInUserUID).then(function(){
                        dropZoneObj.processQueue();
                    }).catch(function(error){
                        navigator.trebbleNotification.alert(error, null, false, "error");
                    })
                }

            }
        }
    }
}).bind(this);
navigator.trebbleNotification.confirm(uploadZoneHtml , confirmCallback, window.getI18n(ti18n,"IMPORT_MUSIC"), buttonLabels, true, null, null, true);
const handlers = Utils.getInstance().getConfirmDialogDeferredHandlers(resolve, reject, this);
const onFileAccepted = (function(fileAdded, done){
    if(fileAdded){
        const file = dropZoneObj.files[0];
        fileName = file.name
        const url = URL.createObjectURL(file);
        done();

    }

}).bind(this)
dropZoneObj =  FileUploadHelper.getInstance().uploadUserBackgroundMusicInWebBrowser(nameOfFileToUpload,"#"+ uploadEementId,handlers.resolve,handlers.reject, onFileAccepted);

dropZoneObj.on("error", (function(file, error){
    //Do no throw error on screen as the promise which was to make this call will throw an error as well. This handler should not be remove as the default handler will unhide error message as soon as it is displayed
    setTimeout(function(){window.alertErrorMessage(error);}, 500);
}).bind(this));
}).bind(this)).then((function(uploadedFileURL){
if(uploadedFileURL){
    return Utils.getInstance().getMediaMetadata(uploadedFileURL).then((function(metadata){
        const {duration} = metadata;
        return TrebbleClientAPIHelper.getInstance().addUploadedMediaToUserLibrary(fileName, uploadedFileURL, {duration, uri: uploadedFileURL, lastUdpdatedDate: new Date()}, metadata.isVideo, metadata);
    }).bind(this))
}
}).bind(this)).then((function(){
return this.retrieveAndUpdateUserUploadedBackgroundMusic();
}).bind(this)).then((function(){
if(this.trebbleAudioListController){
    this.trebbleAudioListController.reload();
}
}).bind(this));
},


buildModelAndRenderView : function() {
this._buildTrebbleAudioListController();
return this.trebbleAudioListController.buildModelAndRenderView().then((function(){
const params = {};
params.trebbleAudioListView = this.trebbleAudioListController.getView()
this.view = new ConfigureBackgroundMusicPopupView(params);

this.listenTo(this.view, "closed",this.onPopupClosed);
this.listenTo(this.view, "userUploadsSelected",this._onUserUploadsSelected);
this.listenTo(this.view, "backgroundMusicCategorySelected",this._onBackgroundMusicCategorySelected);
this.listenTo(this.view, "showImportYourOwnMusicPopup",this.showImportYourOwnMusicPopup);

this.retrieveAndDisplayBackgroundMusic();
this.view.render();
}).bind(this))


}

});

export default ConfigureBackgroundMusicPopupController;