import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerOperationLog from "models/audioEditor/sequencerOperationLog/SequencerOperationLog";
import RSVP from "rsvp";

const SequencerOperationMoveNodeLog =  SequencerOperationLog.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperationLog.apply(this, [attributes, options]);	
    },


    getOperationName : function(){
        return "Move Node";
    },

    setNodeToMove : function(nodeToMove){
        this.set("nodeToMove", nodeToMove);
    },

    getNodeToMove : function(){
        return this.get("nodeToMove");
    },

    setOldPreviousSequencerNode : function(oldPreviousSequencerNode){
        this.set("oldPreviousSequencerNode", oldPreviousSequencerNode);
    },

    getOldPreviousSequencerNode : function(){
        return this.get("oldPreviousSequencerNode");
    },

    setOldNextSequencerNode: function(oldNextSequencerNode){
        this.set("oldNextSequencerNode", oldNextSequencerNode);
    },

    getOldNextSequencerNode : function(){
        return this.get("oldNextSequencerNode");
    },

    setNewPreviousSequencerNode : function(newPreviousSequencerNode){
        this.set("newPreviousSequencerNode", newPreviousSequencerNode);
    },

    getNewPreviousSequencerNode : function(){
        return this.get("newPreviousSequencerNode");
    },

    setNewNextSequencerNode: function(newNextSequencerNode){
        this.set("newNextSequencerNode", newNextSequencerNode);
    },

    getNewNextSequencerNode : function(){
        return this.get("newNextSequencerNode");
    },





});

export default SequencerOperationMoveNodeLog; 