import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import ti18n from "i18n!nls/Commoni18n";
import Utils from "models/helper/Utils";
import InfoBoxView from "views/common/InfoBoxView";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";

const COLLECTION_CONTAINER_ID = "collection_repeat_container";

const MIN_HEIGHT_IN_PX_TO_TRIGGER_LOAD = 100;
const SENTINEL_HEIGHT_IN_PX =  50;

const CollectionRepeatView = Backbone.View.extend({



    initialize : function(options) {
        this._infoBoxInfoParams  = options.customInfoBoxInfoParams? options.customInfoBoxInfoParams: this._buildDefaultCustomBoxParams(); 
        this._showEmtptyInfoBox = (options.showEmtptyInfoBox == false)? false: true;
        this._customInfoBoxInfo  = options.customInfoBoxInfo? options.customInfoBoxInfo: null;
        this._resizePlaceHolderElem =  options  ? options.resizePlaceHolderElem :  false;
        this._heightMargin= (options && options.heightMargin)  ? options.heightMargin :  null;
        this._setHeightToFitContainer = (options && options.setHeightToFitContainer)  ? options.setHeightToFitContainer :  false;
        this._setHeightContainerAuto = (options && options.setHeightContainerAuto)  ? options.setHeightContainerAuto :  false;
        this._displayCollectionInline = (options && options.displayCollectionInline)  ? options.displayCollectionInline :  false;
        this._loadOnScroll =  (options && options.loadOnScroll)  ? options.loadOnScroll :  false;
        this._loadOnLoadMoreButtonClick =  (options && options.loadOnLoadMoreButtonClick)  ? options.loadOnLoadMoreButtonClick :  false;
        this._loadMoreButtonLabel =  (options && options.loadMoreButtonLabel)  ? options.loadMoreButtonLabel :  false;
        this._addPaddingBlock =  (options && options.addPaddingBlock)  ? options.addPaddingBlock :  false;
        this._paddingBlockHeight =  (options && options.paddingBlockHeight)  ? options.paddingBlockHeight :  null;
        this._noMoreDataToLoad = false;
        
        this._itemViewArray = [];
        this._isUpdating = false;
        this._initInfoBoxIfApplicable();
        this._listenerViewportKey = "CollectionRepeatView" + (new Date()).getTime();
        if(PersistentModels.getInstance().getRouter()){
            PersistentModels.getInstance().getRouter().registerForViewportHeightChange(this._listenerViewportKey,this._onOrientationChangeFunction.bind(this));
        }else{
            window.addEventListener('orientationchange', this._onViewportHeightChanged);
            window.addEventListener('resize', this._onViewportHeightChanged);
        }

    },

    _onViewportHeightChanged : function(){
        const headerHeight =  54;
        const footerHeight = 67;
        //const paddingHeight = 10 * 2;
        const newViewportSize = (window.getDocumentClienHeight() - headerHeight ) ;
        const viewPortSizeForPopup = window.getDocumentClienHeight();
        try{
            this._onOrientationChangeFunction(newViewportSize);
        }catch(error){
            window.alertErrorMessage(error);
        }


    },


    setNoMoreDataToLoad : function(noMoreDataToLoad){
        this._noMoreDataToLoad = noMoreDataToLoad;
        if(this._scrollContainer$el){
            if(noMoreDataToLoad){
                this._scrollContainer$el.off("scroll",this._debounceOnScrollFunction);	
            }else{
                this._debounceOnScrollFunction = _.debounce(this._onScroll.bind(this), 300);
                this._scrollContainer$el.on("scroll",this._debounceOnScrollFunction);	
            }
        }
        this.collectionContainer$el.attr("no_more_data_to_load", noMoreDataToLoad);
    },

    isNoMoreDataToLoad : function(){
        return this._noMoreDataToLoad
    },
    
    _buildDefaultCustomBoxParams : function(){
        const params = {};
        params.iconClass = "ion-ios7-musical-notes";
        params.message = window.getI18n(ti18n, "NO_SONG_FOUND");
        params.helpMessage = window.getI18n(ti18n, "YOU_CAN_PLAY_THIS_SONG_IN_SHUFFLE_IF_YOU_ADD_IT_TO_YOUR_LIBRARY");
        return params;
    },

    scrollToTheTop : function(){
        this.collectionContainer$el.get(0).scrollTop = 0;
    },

    _onRootElementResize : function(newViewPortHeigh){
        const root$el = this.collectionContainer$el;
        let rootElInnerHeight = newViewPortHeigh? newViewPortHeigh : root$el.innerHeight() ;
        if(this._heightMargin){
            rootElInnerHeight = rootElInnerHeight - this._heightMargin;
        }
        if(this._emptyInfoBox && this._emptyInfoBox.$el){
            if(this._setHeightContainerAuto){
                this._emptyInfoBox.$el.css("height","auto");
            }else{
                if( rootElInnerHeight != 0){
                    if(this._setHeightToFitContainer){
                        this._emptyInfoBox.$el.css("height","100%");
                    }else{
                        this._emptyInfoBox.$el.css("height",rootElInnerHeight + "px");
                    }
                }
            }
        }
        if (!this._resizePlaceHolderElem && root$el) {
            if(this._setHeightContainerAuto){
                root$el.css("height","auto");
            }else{
                if(this._setHeightToFitContainer){
                    root$el.css("height" ,  "100%"); 
                }else{
                    root$el.css("height" , (rootElInnerHeight ) + "px"); 
                }
            }
        }


    },

    _onOrientationChangeFunction : function(newViewPortHeigh){
        const root$el = this.collectionContainer$el;
        let rootElInnerHeight = newViewPortHeigh? newViewPortHeigh : root$el.innerHeight() ;
        if(this._heightMargin){
            rootElInnerHeight = rootElInnerHeight - this._heightMargin;
        }
        if(this._emptyInfoBox && this._emptyInfoBox.$el){

            if( rootElInnerHeight != 0){
                if(this._setHeightContainerAuto){
                    this._emptyInfoBox.$el.css("height","auto");
                }else{
                    if(this._setHeightToFitContainer){
                        this._emptyInfoBox.$el.css("height","100%");
                    }else{
                        this._emptyInfoBox.$el.css("height",rootElInnerHeight + "px");
                    }
                }
            }
        }
        if (!this._resizePlaceHolderElem && root$el) {
            if(this._setHeightContainerAuto){
                root$el.css("height","auto");
            }else{
                if(this._setHeightToFitContainer){
                    root$el.css("height" ,  "100%"); 
                }else{
                    root$el.css("height" , (rootElInnerHeight ) + "px"); 
                } 
            }
        }
    },

    _onNodeRemovedFromDom : function(){
        this._removeFromDom  = true;
        if(PersistentModels.getInstance().getRouter()){
            PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._listenerViewportKey);
        }else{
            window.removeEventListener('orientationchange', this._onViewportHeightChanged);
            window.removeEventListener('resize', this._onViewportHeightChanged);
        }
    },


    setFitContainerHeight : function(fitContainerHeight, autoHeight){
        this._setHeightToFitContainer =  fitContainerHeight;
        this._setHeightContainerAuto = autoHeight;
        const viewPortHeigh = PersistentModels.getInstance().getRouter().getViewportHeight();
        const root$el = this.collectionContainer$el;
        const rootElInnerHeight = viewPortHeigh? viewPortHeigh : root$el.innerHeight() ;
        if(this._emptyInfoBox && this._emptyInfoBox.$el){

            if( rootElInnerHeight != 0){
                if(this._setHeightContainerAuto){
                    this._emptyInfoBox.$el.css("height","auto");
                }else{
                    if(this._setHeightToFitContainer){
                        this._emptyInfoBox.$el.css("height","100%");
                    }else{
                        this._emptyInfoBox.$el.css("height",rootElInnerHeight + "px");
                    }
                }
            }
        }
        if (!this._resizePlaceHolderElem && root$el) {
            if(this._setHeightContainerAuto){
                root$el.css("height","auto");
            }else{
                if(this._setHeightToFitContainer){
                    root$el.css("height" ,  "100%"); 
                }else{
                    root$el.css("height" , (rootElInnerHeight ) + "px"); 
                }
            }
        }
    },


    _initInfoBoxIfApplicable : function(){
        if(this._showEmtptyInfoBox ){
            if(!this._customInfoBoxInfo){
                this._emptyInfoBox =  new InfoBoxView(this._infoBoxInfoParams);
                this._emptyInfoBox.render();
                this.el.appendChild(this._emptyInfoBox.el);
            }else{
                this._emptyInfoBox = this._customInfoBoxInfo;
            }
            this.listenTo(this._emptyInfoBox, "actionButtonPressed",this._onDefaultInfoboxButtonCalled.bind(this));
        }

    },


    isLoading : function(){
        return this._isUpdating;
    },

    setUpdating : function(updating){
        this._isUpdating = updating;
        if(this._showEmtptyInfoBox && this._emptyInfoBox){
            if(this._isUpdating){
                this._emptyInfoBox.showLoading();
                this._emptyInfoBox.show();
                this.collectionContainer$el.hide();
            }else{
                this._emptyInfoBox.hide();
                this._emptyInfoBox.hideLoading();
                if(this._displayCollectionInline){
                    this.collectionContainer$el.css("display","inline-flex");
                    if(!this.collectionContainer$el.hasClass("inline_flex_collection")){
                        this.collectionContainer$el.addClass("inline_flex_collection")
                    }
                }else{
                    this.collectionContainer$el.show();
                }
                this._displayInfoBoxIfApplicable();
                
            }
        }
    },

    setErrorMessage : function(errorMessage, helpErrorMessage, errorActionButtonLabel){
        if(this._showEmtptyInfoBox && this._emptyInfoBox){
            if(errorMessage){
                this._emptyInfoBox.showErrorMessage(errorMessage, helpErrorMessage, errorActionButtonLabel);
                this._emptyInfoBox.show();
                this.collectionContainer$el.hide();
            }else{
                this._emptyInfoBox.hideErrorMessage();
                this.collectionContainer$el.show();
                this._displayInfoBoxIfApplicable();
                
            }
        }
    },

    updateInfoBoxIfApplicable : function(iconClass,message,helpMessage,infoBoxClassName,actionButtonLabel,actionButtonIconClass){
        if(this._showEmtptyInfoBox && this._emptyInfoBox){
            this._emptyInfoBox.update(iconClass,message,helpMessage,infoBoxClassName,actionButtonLabel,actionButtonIconClass);
            this._displayInfoBoxIfApplicable();
        }
    },

    _displayInfoBoxIfApplicable : function(){
        if(this._showEmtptyInfoBox && this._emptyInfoBox){
            if(this._itemViewArray && this._itemViewArray.length > 0){
                this._emptyInfoBox.hide();
                this.collectionContainer$el.show();
            }else{
                this.collectionContainer$el.hide();
                this._emptyInfoBox.show();
            }
        }

    },

    addViewItem : function(viewItem){
        if(viewItem){
            this._itemViewArray.push(viewItem);
            this.collectionContentWrapper$el.append(viewItem.$el);
        }
        this._displayInfoBoxIfApplicable();

    },

    deleteViewItem : function(viewItem){
        if(viewItem){
            const viewItemIndex = this._itemViewArray.indexOf(viewItem);
            if(viewItemIndex > -1){
                this._itemViewArray.splice(viewItemIndex,1)
            }
            viewItem.remove();
        }
        this._displayInfoBoxIfApplicable();

    },

    focusOnItem : function(viewItem){
        if(viewItem){
            viewItem.$el.get(0).scrollIntoView({block: "start", behavior: "smooth"});
            
            viewItem.$el.addClass("hightlightAnim").one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend',  (function() {
                this.viewItem.$el.removeClass('hightlightAnim');
            }).bind({"viewItem": viewItem}));
        }
        this._displayInfoBoxIfApplicable();
    },




    _onDefaultInfoboxButtonCalled : function(){
        this.trigger("infoBoxButtonPressed");
    },

    scollToBottom : function(){
        this.collectionContainer$el.scrollTop(this.collectionContainer$el[0].scrollHeight);
    },

    getCollectionContainer$el : function(){
        return this.collectionContainer$el;
    },
    
    loadImages : function(){
        this.$el.find("#"+COLLECTION_CONTAINER_ID).trigger("scrollstop");
    },

    _shouldLoadMore :function(){
        return this._scrollContainer$el.get(0).scrollTop + this._scrollContainer$el.get(0).offsetHeight + MIN_HEIGHT_IN_PX_TO_TRIGGER_LOAD + SENTINEL_HEIGHT_IN_PX > this.collectionContentWrapper$el.get(0).offsetHeight;
    },

    _onScroll : function(){
        if(!this.isNoMoreDataToLoad() && this._shouldLoadMore()){
            this.trigger("loadMoreData");
        }
    },

    setScrollContainer :function(scrollContainer$el){
        if(this._loadOnScroll && !this._scrollContainer$el){
            this._scrollContainer$el = scrollContainer$el;
            this._debounceOnScrollFunction = _.debounce(this._onScroll.bind(this), 300);
            this._scrollContainer$el.on("scroll",this._debounceOnScrollFunction);
        }

    },

    _onLoadMoreButtonClick : function(){
        if(!this.isNoMoreDataToLoad()){
            this.trigger("loadMoreData");
        }
    },

    render : function() {
        const templateParams = {};
        /*if(this._emptyInfoBox){
            this.$el.append(this._emptyInfoBox.$el);
        }*/
        this.$el.css("width","100%");
        this.$el.on("remove",this._onNodeRemovedFromDom.bind(this));
        this.$el.append("<div  id='"+COLLECTION_CONTAINER_ID+"' class='collection_container' ><div class='collection_content_wrapper' style='height: fit-content;'></div></div>");
        this.collectionContainer$el = this.$el.find("#"+COLLECTION_CONTAINER_ID);
        this.collectionContentWrapper$el = this.$el.find("#"+COLLECTION_CONTAINER_ID +" .collection_content_wrapper");
        if(this._loadOnScroll){
            this.collectionContainer$el.append("<div  class='sentinel_block_wrapper' style='height:"+SENTINEL_HEIGHT_IN_PX+"px;'><div class='sentinel ion-loading-c'></div></div>");
            this.sentinelBlockWrapper$el =  this.$el.find(".sentinel_block_wrapper");
            
        }
        if(this._loadOnLoadMoreButtonClick){
            this.collectionContainer$el.append("<div  class='load_more_button_block_wrapper' style='height:"+SENTINEL_HEIGHT_IN_PX+"px;'><div class='shrinkAnimation transparent_button ' id='load_more_btn' style='display: flex;'>"+this._loadMoreButtonLabel+"</div></div>");
            this.load_more_btn$el =  this.$el.find("#load_more_btn");
            this.load_more_btn$el.on("click",_.debounce(this._onLoadMoreButtonClick.bind(this), 300));
        }
        if(this._addPaddingBlock){
            this.collectionContainer$el.append("<div class='padding_block' style='height:"+this._paddingBlockHeight+"px;'></div>");
        }
        this._displayInfoBoxIfApplicable();
        return this;
    }

});
export default CollectionRepeatView;