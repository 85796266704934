import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utils from "models/helper/Utils";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";
import TrebbleEditDetailsContentView from "views/playlistPodcast/TrebbleEditDetailsContentView";
import DynamicPageView from "views/common/DynamicPageView";
import CustomSelectFieldController from "controllers/common/CustomSelectFieldController";
import ListItemCollection from "collections/ListItemCollection";
import ti18n from "i18n!nls/PlaylistPodcasti18n";
import PageTemplate from 'text!../../../templates/playlistPodcasts/TrebbleEditDetailsPage.html';

const CUSTOM_TREBBLE_URL_REGEX_VALIDATION = /^[a-zA-Z0-9_\-]{3,255}$/;

const DEMO_GREETER_URL = "https://web.trebble.fm/audioassets/cultureBanx-greeter-demo.mp3";
const DEMO_INTRO_URL = "https://web.trebble.fm/audioassets/welcomeEdited-edited.mp3";
const DEMO_OUTRO_URL = "https://web.trebble.fm/audioassets/welcomeEdited-edited.mp3";
const DEMO_SWEEPER_URL = "https://web.trebble.fm/audioassets/Been_Hugging_March_2015+_short.mp3";

const TrebbleEditDetailsController =  Chaplin.Controller.extend({


    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = options.trebbleModel;
        this._pageId = options.pageId;
        this._onCoverPictureChangeTrigger = options.onCoverPictureChangeTrigger;
        this._onSweeperChangeTrigger = options.onSweeperChangeTrigger;
        this._onSweeperChangeTriggerConfirm = options.onSweeperChangeTriggerConfirm;
        this._onDeleteSweeperTrigger = options.onDeleteSweeperTrigger;
        this._onGreaterChangeTrigger = options.onGreaterChangeTrigger;
        this._onIntroChangeTrigger = options.onIntroChangeTrigger;
        this._onOutroChangeTrigger = options.onOutroChangeTrigger;
        this._onGreaterChangeTriggerConfirm = options.onGreaterChangeTriggerConfirm;
        this._onIntroChangeTriggerConfirm = options.onIntroChangeTriggerConfirm;
        this._onOutroChangeTriggerConfirm = options.onOutroChangeTriggerConfirm;
        this._onDeleteGreaterTrigger = options.onDeleteGreaterTrigger;
        this._onDeleteIntroTrigger = options.onDeleteIntroTrigger;
        this._onDeleteOutroTrigger = options.onDeleteOutroTrigger;
        this.onActionExecutedOnTrebbleHandler = options? options.onActionExecutedOnTrebbleHandler: null;
        if(!this.onActionExecutedOnTrebbleHandler){
            this.onActionExecutedOnTrebbleHandler = (function(){});
        }

    },
    
    getModel : function(){
        return this.model;
    },
    
    getView : function(){
        return this.view;
    },

    executeActionCallback :function(action, data){
        if(this.onActionExecutedOnTrebbleHandler){
            this.onActionExecutedOnTrebbleHandler(this.model,action, data);
        }
    },


    confirmAction : function(actionName, message, functionNameToExecuteOnConfirmation){
        const buttonLabels = [window.getI18n(ti18n, "NO"), window.getI18n(ti18n, "YES")];
        const confirmCallback = (function(buttonIndex){
            if(buttonIndex ==  2){
                setTimeout((function(){
                    this[functionNameToExecuteOnConfirmation]();
                }).bind(this), 100);			
            }
        }).bind(this);
        navigator.trebbleNotification.confirm(message , confirmCallback, actionName, buttonLabels);

    },
    
    
    saveChanges : function(){
        //if(this.isEditedInfoValid()){
            $.mobile.loading("show");
            const editedData = this.getEditedTrebbleInfo();
            let name = editedData.name;
            const customURL = editedData.customURL;
            const categoryId = editedData.categoryId;
            const cityId  = editedData.cityId;
            const timezone = editedData.timezone;
            const language = editedData.language;
            const rssFeedUrlContentSource = editedData.rssFeedUrlContentSource;
            const syncWithRssFeed = editedData.syncWithRssFeed;
            name = Utils.getInstance().capitalizeFirstLetter(name);
            const description = editedData.description;
            return TrebbleClientAPIHelper.getInstance().updateTrebbleInfoByTrebbleId(this.model.getLoaderKey(),
                name, description, customURL, categoryId, cityId, timezone, language,syncWithRssFeed, rssFeedUrlContentSource).then((function(){
                    this.model.setName(name);
                    this.model.setDescription(description);
                    this.model.setCategoryId(categoryId);
                    this.model.setCityId(cityId);
                    this.model.setTimezone(timezone);
                    this.model.setLanguage(language);
                    this.executeActionCallback("editTrebbleInfo", editedData);
                    const updatedData = JSON.parse(JSON.stringify(editedData));
                    updatedData.id = this.model.getLoaderKey();
                    PersistentModels.getInstance().onPersonalTrebbleCreatedOrEdited(updatedData);
                    $.mobile.loading("hide");
                    this.view.navigateToPreviousPage();
                    window.alertSuccessMessage(window.getI18n(ti18n, "YOUR_TREBBLE_INFO_WAS_UPDATED"));
                }).bind(this)).catch((function(error){
                    $.mobile.loading("hide");
                    window.alertErrorMessage(window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHEN_SAVING_YOUR_CHANGES")+ error);
                }).bind(this));
                //}

            },

            getTrebbleInfoLoadFunction : function(trebbleId){
                return (function(){
                    /*const promiseHash =  {};
                    promiseHash["trebbleInfo"] = TrebbleClientAPIHelper.getInstance().getMyTrebbleInfoByTrebbleId(this.trebbleId);
                    promiseHash["jingleInfo"] = TrebbleClientAPIHelper.getInstance().getJingleForMyDefaultPersonalRadio(this.trebbleId);
                    promiseHash["greaterInfo"] = TrebbleClientAPIHelper.getInstance().getGreaterForMyDefaultPersonalRadio(this.trebbleId);
                    promiseHash["introInfo"] = TrebbleClientAPIHelper.getInstance().getIntroForMyDefaultPersonalRadio(this.trebbleId);
                    promiseHash["outroInfo"] = TrebbleClientAPIHelper.getInstance().getOutroForMyDefaultPersonalRadio(this.trebbleId);*/
                    return TrebbleClientAPIHelper.getInstance().getMyCompleteTrebbleInfoByTrebbleId(this.trebbleId);
                }).bind({"trebbleId": trebbleId});

            },

            getEditedTrebbleInfo : function(){
                return this.contentView? this.contentView.getEditedTrebbleInfo(): {};
            },

            isEditedInfoValid : function(){
                const editedData = this.getEditedTrebbleInfo();
                if(!editedData.name){
                    console.error("trebble name cannot be empty");
                    window.alertErrorMessage(window.getI18n(ti18n, "YOU_CANNOT_HAVE_A_TREBBLE_WITH_NO_NAME"));
                    return false;
                }else{
                    if(editedData.name && editedData.name.length >= 100){
                        console.error("A trebble name cannot be more than 100 characters");
                        window.alertErrorMessage(window.getI18n(ti18n, "TREBBLE_NAME_CANNOT_BE_MORE_THAN_X_CHAR"));
                        return false;
                    }
                    if(!editedData.name.match(Utils.getInstance().getTrebbleNameRegexValidation())){

                        window.alertErrorMessage(window.getI18n(ti18n, "TREBBLE_NAME_CAN_ONLY_CONTAIN_LETTER"));
                        return false;
                    }
                    if(editedData.name.indexOf("_") != -1){
                        window.alertErrorMessage(window.getI18n(ti18n, "TREBBLE_CANNOT_CONTAINS_HYPHENS"));
                        return false;
                    }
                }
                if(!editedData.description){
                    console.error("trebble description cannot be empty");
                    window.alertErrorMessage(window.getI18n(ti18n, "TREBBLE_DESC_CANNOT_BE_EMPTY"));
                    return false;
                }else{
                    if(editedData.description && editedData.description.length >= 400){
                        console.error("A trebble description cannot be more than 400 characters");
                        window.alertErrorMessage(window.getI18n(ti18n, "TREBBLE_DESC_CANNOT_BE_MORE_THAN_X_CHAR"));
                        return false;
                    }
                }
                return true;
            },

            editTrebbleCoverPic : function(){
                if(this._onCoverPictureChangeTrigger){
                    this._onCoverPictureChangeTrigger().then((function(coverArtUrl){
                        this.setCoverArtInfo(coverArtUrl, null);
                    }).bind(this));
                }else{
                    this._callEditCoverPicDefaultHandler();
                }
            },

            _callEditCoverPicDefaultHandler : function(){
                const params = {};
                params.trebbleModel = this.model;
                params.context = this._context;
                params.onActionExecutedOnTrebbleHandler = this.onActionExecutedOnTrebbleHandler.bind(this);
                return PersistentModels.getInstance().getRouter().require(["controllers/playlistPodcast/PersonalTrebbleOptionController"]).then((function(PersonalTrebbleOptionController){
                    const personalTrebbleOptionController = new PersonalTrebbleOptionController(params);
                    return personalTrebbleOptionController.upadteCoverArt();
                }).bind(this)).then((function(coverArtUrl){
                    this.setCoverArtInfo(coverArtUrl, null);
                }).bind(this)).catch(function(error){
                    navigator.trebbleNotification.alert(error, null, false, "error");
                });
            },

            editSweeper : function(){
                if(this.contentView.getJingleInfo()){
                    if(this._onSweeperChangeTriggerConfirm){
                        this._onSweeperChangeTriggerConfirm();
                    }else{
                        this.confirmAction(window.getI18n(ti18n, "CHANGE_SWEEPER"), window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_CHANGE_SWEEPER"), "_callEditSweeperDefaultHandler");
                    }
                }else{
                    if(this._onSweeperChangeTrigger){
                        this._onSweeperChangeTrigger();
                    }else{
                        this._callEditSweeperDefaultHandler();
                    }
                }
            },

            _callEditSweeperDefaultHandler : function(){
                const params = {};
                params.trebbleModel = this.model;
                params.context = this._context;
                params.onActionExecutedOnTrebbleHandler = this.onActionExecutedOnTrebbleHandler.bind(this);
                return PersistentModels.getInstance().getRouter().require(["controllers/playlistPodcast/PersonalTrebbleOptionController"]).then((function(PersonalTrebbleOptionController){
                    const personalTrebbleOptionController = new PersonalTrebbleOptionController(params);
                    return personalTrebbleOptionController.changeSweeper();
                }).bind(this)).then((function(sweeperInfo){
                    this.setJingleInfo(sweeperInfo);
                }).bind(this)).catch(function(error){
                    navigator.trebbleNotification.alert(error, null, false, "error");
                });
            },

            editGreater : function(){
                if(this.contentView.getGreaterInfo()){
                    if(this._onGreaterChangeTriggerConfirm){
                        this._onGreaterChangeTriggerConfirm();
                    }else{
                        this.confirmAction(window.getI18n(ti18n, "CHANGE_GREETER"), window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_CHANGE_GREETER"), "_callEditGreaterDefaultHandler");
                    }
                }else{
                    if(this._onGreaterChangeTrigger){
                        this._onGreaterChangeTrigger();
                    }else{
                        this._callEditGreaterDefaultHandler();
                    }
                }
            },

            editIntro : function(){
                if(this.contentView.getIntroInfo()){
                    if(this._onIntroChangeTriggerConfirm){
                        this._onIntroChangeTriggerConfirm();
                    }else{
                        this.confirmAction(window.getI18n(ti18n, "CHANGE_INTRO"), window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_CHANGE_INTRO"), "_callEditIntroDefaultHandler");
                    }
                }else{
                    if(this._onIntroChangeTrigger){
                        this._onIntroChangeTrigger();
                    }else{
                        this._callEditIntroDefaultHandler();
                    }
                }
            },

            editOutro : function(){
                if(this.contentView.getOutroInfo()){
                    if(this._onOutroChangeTriggerConfirm){
                        this._onOutroChangeTriggerConfirm();
                    }else{
                        this.confirmAction(window.getI18n(ti18n, "CHANGE_OUTRO"), window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_CHANGE_OUTRO"), "_callEditOutroDefaultHandler");
                    }
                }else{
                    if(this._onOutroChangeTrigger){
                        this._onOutroChangeTrigger();
                    }else{
                        this._callEditOutroDefaultHandler();
                    }
                }
            },

            _callEditGreaterDefaultHandler : function(){
                const params = {};
                params.trebbleModel = this.model;
                params.context = this._context;
                params.onActionExecutedOnTrebbleHandler = this.onActionExecutedOnTrebbleHandler.bind(this);
                return PersistentModels.getInstance().getRouter().require(["controllers/playlistPodcast/PersonalTrebbleOptionController"]).then((function(PersonalTrebbleOptionController){
                    const personalTrebbleOptionController = new PersonalTrebbleOptionController(params);
                    return personalTrebbleOptionController.changeGreater();
                }).bind(this)).then((function(greaterInfo){
                    this.setGreaterInfo(greaterInfo);
                }).bind(this)).catch(function(error){
                    navigator.trebbleNotification.alert(error, null, false, "error");
                });
            },

            _callEditIntroDefaultHandler : function(){
                const params = {};
                params.trebbleModel = this.model;
                params.context = this._context;
                params.onActionExecutedOnTrebbleHandler = this.onActionExecutedOnTrebbleHandler.bind(this);
                return PersistentModels.getInstance().getRouter().require(["controllers/playlistPodcast/PersonalTrebbleOptionController"]).then((function(PersonalTrebbleOptionController){
                    const personalTrebbleOptionController = new PersonalTrebbleOptionController(params);
                    return personalTrebbleOptionController.changeIntro();
                }).bind(this)).then((function(introInfo){
                    this.setIntroInfo(introInfo);
                }).bind(this)).catch(function(error){
                    navigator.trebbleNotification.alert(error, null, false, "error");
                });
            },


            _callEditOutroDefaultHandler : function(){
                const params = {};
                params.trebbleModel = this.model;
                params.context = this._context;
                params.onActionExecutedOnTrebbleHandler = this.onActionExecutedOnTrebbleHandler.bind(this);
                return PersistentModels.getInstance().getRouter().require(["controllers/playlistPodcast/PersonalTrebbleOptionController"]).then((function(PersonalTrebbleOptionController){
                    const personalTrebbleOptionController = new PersonalTrebbleOptionController(params);
                    return personalTrebbleOptionController.changeOutro();
                }).bind(this)).then((function(outroInfo){
                    this.setOutroInfo(outroInfo);
                }).bind(this)).catch(function(error){
                    navigator.trebbleNotification.alert(error, null, false, "error");
                });
            },


            deleteSweeper : function(){
                if(this._onDeleteSweeperTrigger){
                    this._onDeleteSweeperTrigger();
                }else{
                    this.confirmAction(window.getI18n(ti18n, "DELETE_SWEEPER"), window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_DELETE_SWEEPER"), "_callDeleteCurrentSweeperDefaultHandler");
                }
            },

            _callDeleteCurrentSweeperDefaultHandler : function(){
                const params = {};
                params.trebbleModel = this.model;
                params.context = this._context;
                params.onActionExecutedOnTrebbleHandler = this.onActionExecutedOnTrebbleHandler.bind(this);
                return PersistentModels.getInstance().getRouter().require(["controllers/playlistPodcast/PersonalTrebbleOptionController"]).then((function(PersonalTrebbleOptionController){
                    const personalTrebbleOptionController = new PersonalTrebbleOptionController(params);
                    return personalTrebbleOptionController.deleteCurrentSweeper();
                }).bind(this)).then((function(){
                    this.setJingleInfo(null);
                }).bind(this)).catch(function(error){
                    navigator.trebbleNotification.alert(error, null, false, "error");
                });
            },

            deleteGreater : function(){
                if(this._onDeleteGreaterTrigger){
                    this._onDeleteGreaterTrigger();
                }else{
                    this.confirmAction(window.getI18n(ti18n, "DELETE_GREETER"), window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_DELETE_GREETER"), "_callDeleteCurrentGreaterDefaultHandler");
                }
            },

            deleteIntro : function(){
                if(this._onDeleteIntroTrigger){
                    this._onDeleteIntroTrigger();
                }else{
                    this.confirmAction(window.getI18n(ti18n, "DELETE_INTRO"), window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_DELETE_INTRO"), "_callDeleteCurrentIntroDefaultHandler");
                }
            },

            deleteOutro : function(){
                if(this._onDeleteOutroTrigger){
                    this._onDeleteOutroTrigger();
                }else{
                    this.confirmAction(window.getI18n(ti18n, "DELETE_OUTRO"), window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_DELETE_OUTRO"), "_callDeleteCurrentOutroDefaultHandler");
                }
            },

            _callDeleteCurrentGreaterDefaultHandler : function(){
                const params = {};
                params.trebbleModel = this.model;
                params.context = this._context;
                params.onActionExecutedOnTrebbleHandler = this.onActionExecutedOnTrebbleHandler.bind(this);
                return PersistentModels.getInstance().getRouter().require(["controllers/playlistPodcast/PersonalTrebbleOptionController"]).then((function(PersonalTrebbleOptionController){
                    const personalTrebbleOptionController = new PersonalTrebbleOptionController(params);
                    return personalTrebbleOptionController.deleteCurrentGreater();
                }).bind(this)).then((function(){
                    this.setGreaterInfo(null);
                }).bind(this)).catch(function(error){
                    navigator.trebbleNotification.alert(error, null, false, "error");
                });
            },

            _callDeleteCurrentIntroDefaultHandler : function(){
                const params = {};
                params.trebbleModel = this.model;
                params.context = this._context;
                params.onActionExecutedOnTrebbleHandler = this.onActionExecutedOnTrebbleHandler.bind(this);
                return PersistentModels.getInstance().getRouter().require(["controllers/playlistPodcast/PersonalTrebbleOptionController"]).then((function(PersonalTrebbleOptionController){
                    const personalTrebbleOptionController = new PersonalTrebbleOptionController(params);
                    return personalTrebbleOptionController.deleteCurrentIntro();
                }).bind(this)).then((function(){
                    this.setIntroInfo(null);
                }).bind(this)).catch(function(error){
                    navigator.trebbleNotification.alert(error, null, false, "error");
                });
            },

            _callDeleteCurrentOutroDefaultHandler : function(){
                const params = {};
                params.trebbleModel = this.model;
                params.context = this._context;
                params.onActionExecutedOnTrebbleHandler = this.onActionExecutedOnTrebbleHandler.bind(this);
                return PersistentModels.getInstance().getRouter().require(["controllers/playlistPodcast/PersonalTrebbleOptionController"]).then((function(PersonalTrebbleOptionController){
                    const personalTrebbleOptionController = new PersonalTrebbleOptionController(params);
                    return personalTrebbleOptionController.deleteCurrentOutro();
                }).bind(this)).then((function(){
                    this.setOutroInfo(null);
                }).bind(this)).catch(function(error){
                    navigator.trebbleNotification.alert(error, null, false, "error");
                });
            },

            setJingleInfo : function(jingleInfo){
                if(this.contentView){
                    this.contentView.setJingleInfo(jingleInfo);
                }
            },

            setGreaterInfo : function(greaterInfo){
                if(this.contentView){
                    this.contentView.setGreaterInfo(greaterInfo);
                }
            },

            setIntroInfo : function(introInfo){
                if(this.contentView){
                    this.contentView.setIntroInfo(introInfo);
                }
            },

            setOutroInfo : function(outroInfo){
                if(this.contentView){
                    this.contentView.setOutroInfo(outroInfo);
                }
            },

            updateTrebbleNameAndDescription  : function(newName, newDescription, customURL){
                if(this.contentView){
                    this.contentView.updateTrebbleNameAndDescription(newName, newDescription, customURL);
                }
            },

            setCoverArtInfo : function(coverArtUrl, coverArtUrlInfo){
                if(this.contentView){
                    this.contentView.setCoverArtInfo(coverArtUrl, coverArtUrlInfo);
                }
            },

            startPlayingSweeper : function(sweeperAudioUrl){
                if(sweeperAudioUrl){
                    const audioUrl = sweeperAudioUrl;
                    const successCB = (function(){
                        this.contentView.setSweeperPlaying(false);
                    }).bind(this);
                    const failureCB = (function(){
                        this.contentView.setSweeperPlaying(false);
                    }).bind(this);
                    if(audioUrl){
                        PersistentModels.getInstance().getPlayer().playAudioPreview(audioUrl, successCB, failureCB);
                    }else{
                        Utils.getInstance().showShortToastMessage(window.getI18n(ti18n, "NO_AUDIO_FOUND_TO_PLAY"));
                    }
                }
            },

            startPlayingGreater : function(greaterAudioUrl){
                if(greaterAudioUrl){
                    const audioUrl = greaterAudioUrl;
                    const successCB = (function(){
                        this.contentView.setGreaterPlaying(false);
                    }).bind(this);
                    const failureCB = (function(){
                        this.contentView.setGreaterPlaying(false);
                    }).bind(this);
                    if(audioUrl){
                        PersistentModels.getInstance().getPlayer().playAudioPreview(audioUrl, successCB, failureCB);
                    }else{
                        Utils.getInstance().showShortToastMessage(window.getI18n(ti18n, "NO_AUDIO_FOUND_TO_PLAY"));
                    }
                }
            },

            startPlayingIntro : function(introAudioUrl){
                if(introAudioUrl){
                    const audioUrl = introAudioUrl;
                    const successCB = (function(){
                        this.contentView.setIntroPlaying(false);
                    }).bind(this);
                    const failureCB = (function(){
                        this.contentView.setIntroPlaying(false);
                    }).bind(this);
                    if(audioUrl){
                        PersistentModels.getInstance().getPlayer().playAudioPreview(audioUrl, successCB, failureCB);
                    }else{
                        Utils.getInstance().showShortToastMessage(window.getI18n(ti18n, "NO_AUDIO_FOUND_TO_PLAY"));
                    }
                }
            },

            startPlayingOutro : function(outroAudioUrl){
                if(outroAudioUrl){
                    const audioUrl = outroAudioUrl;
                    const successCB = (function(){
                        this.contentView.setOutroPlaying(false);
                    }).bind(this);
                    const failureCB = (function(){
                        this.contentView.setOutroPlaying(false);
                    }).bind(this);
                    if(audioUrl){
                        PersistentModels.getInstance().getPlayer().playAudioPreview(audioUrl, successCB, failureCB);
                    }else{
                        Utils.getInstance().showShortToastMessage(window.getI18n(ti18n, "NO_AUDIO_FOUND_TO_PLAY"));
                    }
                }
            },

            stopPlayingSweeper : function(){

                PersistentModels.getInstance().getPlayer().terminateAudioPreviewAndResumePlayback();

            },

            stopPlayingGreater : function(){

                PersistentModels.getInstance().getPlayer().terminateAudioPreviewAndResumePlayback();

            },

            stopPlayingIntro : function(){

                PersistentModels.getInstance().getPlayer().terminateAudioPreviewAndResumePlayback();

            },

            stopPlayingOutro : function(){

                PersistentModels.getInstance().getPlayer().terminateAudioPreviewAndResumePlayback();

            },


            startPlayingDemoSweeper : function(){
                const successCB = (function(){
                    this.contentView.setDemoSweeperPlaying(false);
                }).bind(this);
                const failureCB = (function(){
                    this.contentView.setDemoSweeperPlaying(false);
                }).bind(this);
                PersistentModels.getInstance().getPlayer().playAudioPreview(DEMO_SWEEPER_URL, successCB, failureCB);
            },

            startPlayingDemoGreater : function(greaterAudioUrl){
                const successCB = (function(){
                    this.contentView.setDemoGreaterPlaying(false);
                }).bind(this);
                const failureCB = (function(){
                    this.contentView.setDemoGreaterPlaying(false);
                }).bind(this);
                PersistentModels.getInstance().getPlayer().playAudioPreview(DEMO_GREETER_URL, successCB, failureCB);
            },

            startPlayingDemoIntro : function(introAudioUrl){
                const successCB = (function(){
                    this.contentView.setDemoIntroPlaying(false);
                }).bind(this);
                const failureCB = (function(){
                    this.contentView.setDemoIntroPlaying(false);
                }).bind(this);
                PersistentModels.getInstance().getPlayer().playAudioPreview(DEMO_INTRO_URL, successCB, failureCB);
            },

            startPlayingDemoOutro : function(outroAudioUrl){
                const successCB = (function(){
                    this.contentView.setDemoOutroPlaying(false);
                }).bind(this);
                const failureCB = (function(){
                    this.contentView.setDemoOutroPlaying(false);
                }).bind(this);
                PersistentModels.getInstance().getPlayer().playAudioPreview(DEMO_OUTRO_URL, successCB, failureCB);
            },

            stopPlayingDemoSweeper : function(){
                PersistentModels.getInstance().getPlayer().terminateAudioPreviewAndResumePlayback();

            },

            stopPlayingDemoGreater : function(){
                PersistentModels.getInstance().getPlayer().terminateAudioPreviewAndResumePlayback();
            },

            stopPlayingDemoIntro : function(){
                PersistentModels.getInstance().getPlayer().terminateAudioPreviewAndResumePlayback();
            },

            stopPlayingDemoOutro : function(){
                PersistentModels.getInstance().getPlayer().terminateAudioPreviewAndResumePlayback();
            },


            setSaveButtonEnabled : function(enabled){
                if(enabled){
                    this.saveButton$el.removeClass("disabled");
                }else{
                    if(!this.saveButton$el.hasClass("disabled")){
                        this.saveButton$el.addClass("disabled");
                    }
                }
            },

            setFormBeingValidated : function(beingValidated){
                if(!beingValidated){
                    this.view.$el.removeClass("validating");
                }else{
                    if(!this.view.$el.hasClass("validating")){
                        this.view.$el.addClass("validating");
                    }
                }
            },

            validateCustomURL : function(urlFragment){
                let errorMessage =  null;
                if(urlFragment && Utils.getInstance().getDummyCustomURL() != urlFragment){
                    if(!urlFragment || urlFragment.length < 3 || urlFragment.length > 255){
                        errorMessage =  window.getI18n(ti18n, "CUSTOM_URL_MUST_BE_BETWEEN");
                    }
                    if(!errorMessage && !(new RegExp(CUSTOM_TREBBLE_URL_REGEX_VALIDATION)).test(urlFragment)){
                        errorMessage =  window.getI18n(ti18n, "CUSTOM_URL_CANNOT_CONTAINS");
                    }
                }
                const isValid = !errorMessage;
                this.contentView.setCustomURLValid(isValid, errorMessage);
                if(isValid && urlFragment && Utils.getInstance().getDummyCustomURL() != urlFragment){
                    return TrebbleClientAPIHelper.getInstance().isRadioWithSameCustomURL(urlFragment,this.model.getLoaderKey()).then((function(alreadyExists){
                        if(alreadyExists){
                            this.contentView.setCustomURLValid(false, window.getI18n(ti18n, "THIS_URL_IS_ALREADY_TAKEN"));
                        }
                        return !alreadyExists;
                    }).bind(this));
                }else{
                    return RSVP.Promise.resolve(isValid);
                }
                return isValid;
            },

            validateRssFeedUrl : function(rssFeedUrl){
                let errorMessage =  null;
                if(!rssFeedUrl || !Utils.getInstance().isUrl(rssFeedUrl) ){
                    if(!rssFeedUrl){
                        errorMessage =  window.getI18n(ti18n, "RSS_URL_CANNOT_BE_EMPTY");
                    }else{
                        errorMessage =  window.getI18n(ti18n, "INVALID_URL");
                    }
                }
                const isValid = !errorMessage;
                this.contentView.setRssFeedURLValid(isValid, errorMessage);
                if(isValid && rssFeedUrl){
                    return TrebbleClientAPIHelper.getInstance().isUrlASupportedFeed(rssFeedUrl).then((function(isValid){
                        if(!isValid){
                            this.contentView.setRssFeedURLValid(false, window.getI18n(ti18n, "THE_URL_IS_NOT_SUPPORTED"));
                        }
                        return isValid;
                    }).bind(this));
                }else{
                    return RSVP.Promise.resolve(isValid);
                }
                return isValid;
            },

            validateName : function(name){
                let errorMessage =  null;
                if(!name){
                    errorMessage  = window.getI18n(ti18n, "TREBBLE_NAME_CANNOT_BE_EMPTY");
                }else{
                    if(name && name.length >= 100){
                        errorMessage = window.getI18n(ti18n, "TREBBLE_NAME_CANNOT_BE_MORE_THAN_X_CHAR");
                    }
                    if(!name.match(Utils.getInstance().getTrebbleNameRegexValidation())){
                        errorMessage = window.getI18n(ti18n, "TREBBLE_NAME_CAN_ONLY_CONTAIN_LETTER");
                    }
                    if(name.indexOf("_") != -1){
                        errorMessage = window.getI18n(ti18n, "TREBBLE_CANNOT_CONTAINS_HYPHENS");
                    }
                }
                const isValid = !errorMessage;
                this.contentView.setNameValid(isValid, errorMessage);
                if(isValid){
                    return TrebbleClientAPIHelper.getInstance().isRadioWithSameName(name,this.model.getLoaderKey()).then((function(alreadyExists){
                        if(alreadyExists){
                            this.contentView.setNameValid(false, window.getI18n(ti18n, "TREBBLE_NAME_ALREADY_TAKEN"));
                        }
                        return !alreadyExists;
                    }).bind(this));
                }else{
                    return RSVP.Promise.resolve(isValid);
                }
                return isValid;
            },

            validateDescription : function(description){
                let errorMessage =  null;
                if(!description){
                    errorMessage  = window.getI18n(ti18n, "A_TREBBLE_DESC_CANNOT_BE_EMPTY");
                }else{
                    if(description && description.length >= 400){
                        errorMessage  = window.getI18n(ti18n, "TREBBLE_DESC_CANNOT_BE_MORE_THAN_X_CHAR");
                    }
                }
                const isValid = !errorMessage;
                this.contentView.setDescriptionValid(isValid, errorMessage);
                return RSVP.Promise.resolve(isValid);
            },

            validateForm : function(){
                const formData = this.getEditedTrebbleInfo();
                this.setSaveButtonEnabled(false);
                this.setFormBeingValidated(true);
                const promiseHash = {};
                promiseHash["validateName"] = this.validateName(formData.name);
                promiseHash["validateCustomURL"] = this.validateCustomURL(formData.customURL);
                promiseHash["validateDescription"] = this.validateDescription(formData.description);
                promiseHash["validationRssFeedUrl"] = formData.syncWithRssFeed ? this.validateRssFeedUrl(formData.rssFeedUrlContentSource): RSVP.Promise.resolve(true);
                return RSVP.hash(promiseHash).then((function(results){
                    const formValidation = results.validateName &&  results.validateCustomURL && results.validateDescription && results.validationRssFeedUrl;
                    this.setFormBeingValidated(false);
                    this.setSaveButtonEnabled(formValidation);
                    return formValidation;
                }).bind(this)).catch(function(error){
                    this.setFormBeingValidated(false);
                    this.setSaveButtonEnabled(false);
                    window.alertErrorMessage(error? error:window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHEN_SAVING_THE_TREBBLE_INFORMATION"));
                });


            },

            onFormEdited :function(){
                this.setSaveButtonEnabled(false);
            },


            getCities : function(keyword){
                if(keyword){
                $.mobile.loading("show");
                return TrebbleClientAPIHelper.getInstance().searchForCities(keyword,window.getAppUsedLanguage()).then((function(cityInfoArray){
                    const cityListModelArray = [];
                    for(let i =0; i <  cityInfoArray.length; i++){
                        const cityInfo = cityInfoArray[i];
                        cityListModelArray.push(Utils.getInstance().getListItemModel(cityInfo.place_id,cityInfo.description));
                    }
                    $.mobile.loading("hide");
                    const listItemCollection = new ListItemCollection(cityListModelArray);
                    return listItemCollection.models;
                }).bind(this)).catch((function(error){
                    $.mobile.loading("hide");
                    throw error;
                }).bind(this));
            }else{
                return RSVP.Promise.resolve([]);
            }


            },

            _buildCitySelectFieldController : function(){
                const controllerParams = {};
                controllerParams.singleSelect =  true;
                controllerParams.propertyFunction = "getLabel";
                controllerParams.propertyIdFunction = "getId";
                controllerParams.showEmtptyInfoBox  = false;
                controllerParams.loadHandler = this.getCities.bind(this);
                controllerParams.defaultText = window.getI18n(ti18n, "CITY");
                controllerParams.enableSearch =  true;
                const customSelectFieldController = new CustomSelectFieldController(controllerParams);
                return customSelectFieldController;
            },

            _initControllers : function(){
                this.citySelectFieldController = this._buildCitySelectFieldController();
                this.listenTo(this.citySelectFieldController, "selectionChanged",this._onCitySelectionChanged.bind(this) );
                const promiseArray = [];

                promiseArray.push(this.citySelectFieldController.buildModelAndRenderView());
                return RSVP.Promise.all(promiseArray);
            },


            _onCitySelectionChanged : function(cityInfoListItemModel){
                /*if(this.isGenreDifferentFromCurrentSelected(genreInfoListItemModel)){
                    if(window.trebbleAnalyticsHelper){
                        window.trebbleAnalyticsHelper.trackEvent("ExplorePage", 'OnCategorySelectionChanged', genreInfoListItemModel && genreInfoListItemModel.getId? genreInfoListItemModel.getId():null);
                    }
                    this._didUserChangeCategories = true;
                    this._currentGenreInfoListItemModel = genreInfoListItemModel;
                    this._updateRadioResults();
                }*/
            },

            setCityFieldValueWithCityId: function(cityId){
                if(cityId){
                    return TrebbleClientAPIHelper.getInstance().getCityInfo(cityId).then((function(cityInfo){
                        this.citySelectFieldController.setSelected(Utils.getInstance().getListItemModel(cityInfo.place_id, cityInfo.description), true, true);
                        this.contentView.hideShowCityFieldIfApplicable();
                    }).bind(this));
                }else{
                    this.citySelectFieldController.setSelected(null, true, true);
                    this.contentView.hideShowCityFieldIfApplicable();
                }
            },

            onPageRemovedFromBody : function(){
                this._pageRemovedFromBody = true;
                this.contentView.setWasRemovedFromPage();
            },

            buildModelAndRenderView : function() {
                return this._initControllers().then((function(){
                    return new RSVP.Promise((function(resolve, reject) {
                        try {

                            const contentViewParams = {};
                            contentViewParams.loadFunction = this.getTrebbleInfoLoadFunction(this.model.getLoaderKey());
                            contentViewParams.citySelectFieldView  = this.citySelectFieldController.getView();
                            const contentView = new  TrebbleEditDetailsContentView(contentViewParams);
                            contentView.render();
                            this.contentView = contentView;
                            this.listenTo(this.contentView, "editTrebbleCoverPic", this.editTrebbleCoverPic);
                            this.listenTo(this.contentView, "editSweeper", this.editSweeper);
                            this.listenTo(this.contentView, "deleteSweeper", this.deleteSweeper);
                            this.listenTo(this.contentView, "startPlayingSweeper", this.startPlayingSweeper);
                            this.listenTo(this.contentView, "stopPlayingSweeper", this.stopPlayingSweeper);
                            this.listenTo(this.contentView, "startPlayingDemoSweeper", this.startPlayingDemoSweeper);
                            this.listenTo(this.contentView, "stopPlayingDemoSweeper", this.stopPlayingDemoSweeper);

                            this.listenTo(this.contentView, "editGreater", this.editGreater);
                            this.listenTo(this.contentView, "editIntro", this.editIntro);
                            this.listenTo(this.contentView, "editOutro", this.editOutro);
                            this.listenTo(this.contentView, "deleteGreater", this.deleteGreater);
                            this.listenTo(this.contentView, "deleteIntro", this.deleteIntro);
                            this.listenTo(this.contentView, "deleteOutro", this.deleteOutro);
                            this.listenTo(this.contentView, "startPlayingGreater", this.startPlayingGreater);
                            this.listenTo(this.contentView, "startPlayingIntro", this.startPlayingIntro);
                            this.listenTo(this.contentView, "startPlayingOutro", this.startPlayingOutro);
                            this.listenTo(this.contentView, "stopPlayingGreater", this.stopPlayingGreater);
                            this.listenTo(this.contentView, "stopPlayingIntro", this.stopPlayingIntro);
                            this.listenTo(this.contentView, "stopPlayingOutro", this.stopPlayingOutro);
                            this.listenTo(this.contentView, "startPlayingDemoGreater", this.startPlayingDemoGreater);
                            this.listenTo(this.contentView, "startPlayingDemoIntro", this.startPlayingDemoIntro);
                            this.listenTo(this.contentView, "startPlayingDemoOutro", this.startPlayingDemoOutro);
                            this.listenTo(this.contentView, "stopPlayingDemoGreater", this.stopPlayingDemoGreater);
                            this.listenTo(this.contentView, "stopPlayingDemoIntro", this.stopPlayingDemoIntro);
                            this.listenTo(this.contentView, "stopPlayingDemoOutro", this.stopPlayingDemoOutro);
                            this.listenTo(this.contentView, "nameValidation", this.validateName);
                            this.listenTo(this.contentView, "customURLValidation", this.validateCustomURL);
                            this.listenTo(this.contentView,"validateForm", this.validateForm);
                            this.listenTo(this.contentView, "formChanged", this.onFormEdited);
                            this.listenTo(this.contentView, "setCityFieldValueWithCityId", this.setCityFieldValueWithCityId);
                            const pageViewParams= {};
                            pageViewParams.pageId = this._pageId;
                            pageViewParams.pageViewTemplate = PageTemplate;
                            pageViewParams.containerSelector = ".ui-content";
                            pageViewParams.contentView = contentView;
                            pageViewParams.pageTemplateOptions = {"ti18n":ti18n}
                            this.view = new DynamicPageView(pageViewParams);
                            this.view.render();
                            this.view.$el.on("click", "#saveButtonIcon:not(.disabled)", this.saveChanges.bind(this));
                            this.saveButton$el  = this.view.$el.find("#saveButtonIcon")
                            this.view.$el.on("click", "#header-back-button", this.view.navigateToPreviousPage.bind(this.view));
                            this.listenTo(this.view,"removedFromPage", this.onPageRemovedFromBody.bind(this))
                            resolve(this);
                        } catch (error) {
                            reject(error);
                        }

                    }).bind(this));
}).bind(this));

}


});
export default TrebbleEditDetailsController;