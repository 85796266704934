import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import ArtistsTabContentCollection from "collections/myLibrary/ArtistsTabContentCollection";
import ArtistsTabContentView from "views/myLibrary/ArtistsTabContentView";
import PersistentModels from "services/PersistentModels";
import PlayerService from "services/PlayerService";
import RSVP from "rsvp";

const ArtistsTabContentController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = options.model;

    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },
    
    showArtistDetailsPage : function(artistModel){
        PersistentModels.getInstance().getRouter().showArtistDetailPage(artistModel.get("artistId"), artistModel, true);
    },
    
    addSongsArtistToQueue :function(artists){
        const songURIs = [];
        const albumsJson  =  artists.get("albums"); 
        for(let albumIndex in albumsJson)
        {
            const album = albumsJson[albumIndex];
            for(let songIndex in album.songs)
            {
                songURIs.push(album.songs[songIndex].uri)
            }
        }
        if(songURIs.length > 0){
            PlayerService.getInstance().addSongsToQueue(PersistentModels.getInstance().getSongCollection().getSongsWithURI(songURIs));
        }
    },
    
     filterList: function(searchCharaterers, filterOptions, sortOptions){
         if(this.view){
             this.view.filterList(searchCharaterers, filterOptions, sortOptions);
         }
     },
     
     clearSearchfilter : function(){
         if(this.view){
             this.view.clearSearchfilter();
         }
     },
    
    showMenuOption : function(artistModel){
        PersistentModels.getInstance().getRouter().showArtistOptionPopup(artistModel);
        
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                if(!this.model)
                {
                    this.model = new ArtistsTabContentCollection([]);
                }
                this.view = new ArtistsTabContentView({
                    "collection" : this.model
                });
                this.listenTo(this.view, "itemTaped",this.showArtistDetailsPage);
                this.listenTo(this.view, "itemSwipedRight",this.addSongsArtistToQueue);
                this.listenTo(this.view, "itemOptionBtnTapped",this.showMenuOption);
                this.view.render();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default ArtistsTabContentController;