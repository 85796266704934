import React from "react";
import useDarkModeStatus from './useDarkModeStatus';
import useSequencerDarkModeStatus from "./useSequencerDarkModeStatus";
import { theme} from "antd";

const useDefaultTrebbleAntDTokenConfig = (params) => {
    const useEditorTheme = params?.useEditorTheme || false;
    const darkModeStatus = useDarkModeStatus();
    const editorInDarkMode = useSequencerDarkModeStatus();
    const inDarkMode = useEditorTheme? darkModeStatus || editorInDarkMode: darkModeStatus;

    return {
        inDarkMode,
        algorithm: inDarkMode? theme.darkAlgorithm: theme.defaultAlgorithm,
        token: {
          algorithm: inDarkMode? theme.darkAlgorithm: theme.defaultAlgorithm,
          fontSize: 15,
          borderRadius: 1,
          colorWarning: "#f5ab35e6",
          colorError: "#ef4836e6",
          colorPrimary: "#e9605d",
          colorInfo: "#e9605d",
          colorTextBase: inDarkMode?"#ffffff":"#121212",
          colorText: inDarkMode?"rgba(255, 255, 255, 0.88)":"rgba(0, 0, 0, 0.88)",
          colorTextDescription: inDarkMode?"rgba(255, 255, 255, 0.45)":"rgba(0, 0, 0, 0.45)",
          fontFamily: '"Montserrat",sans-serif',
          colorBgElevated: inDarkMode? "#232323": "#ffffff",
        },
        components: {
          Table: {
            fontFamily: '"Montserrat",sans-serif',
            headerBorderRadius: 0,
            fontWeightStrong: 600,
            colorLink: "rgb(231, 125, 123)",
            colorLinkActive: "rgb(225, 137, 135)",
            colorLinkHover: "rgb(233, 96, 93)",
            colorPrimary: "rgb(233, 96, 93)",
            controlItemBgActive: "rgb(255, 230, 229)",
            controlItemBgActiveHover: "rgb(255, 184, 182)",
            rowSelectedHoverBg: "rgb(255, 192, 191)",
            rowSelectedBg: "rgb(255, 229, 229)",
            borderRadius: 0,
            borderRadiusLG: 0
          },
        },
      }
}

export default useDefaultTrebbleAntDTokenConfig;