import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import AudioFilter from "models/audioEditor/filters/AudioFilter";
import RSVP from "rsvp";

const PRESETS ={};

const PRESET_IDS = {
    "AUDIBLE_SOUND_MIN_LIMIT":"audibleSoundMinLimit",
    "DEBOOM_LIGHT":"deboomLight",
    "DEBOOM_MEDIUM":"deboomMedium",
    "DEBOOM_HEAVY":"deboomHeavy",
    "VOCAL_LIMIT_BASS":"vocalLimitBass",
    "VOCAL_LIMIT_BARITONE":"vocalLimitBaritone",
    "VOCAL_LIMIT_CONTRALTO":"vocalLimitContralto",
    "VOCAL_LIMIT_SOPRANO":"vocalLimitSoprano"
}

const createHighPassConfigPreset = function(frequency, Q){
    return {"frequency": frequency, "Q": Q};
}
PRESETS[PRESET_IDS.AUDIBLE_SOUND_MIN_LIMIT] = createHighPassConfigPreset(20);
PRESETS[PRESET_IDS.DEBOOM_LIGHT] = createHighPassConfigPreset(65);
PRESETS[PRESET_IDS.DEBOOM_LIGHT] = createHighPassConfigPreset(85);
PRESETS[PRESET_IDS.DEBOOM_HEAVY] = createHighPassConfigPreset(125);
PRESETS[PRESET_IDS.VOCAL_LIMIT_BASS] = createHighPassConfigPreset(80, 0.71);
PRESETS[PRESET_IDS.VOCAL_LIMIT_BARITONE] = createHighPassConfigPreset(110);
PRESETS[PRESET_IDS.VOCAL_LIMIT_CONTRALTO] = createHighPassConfigPreset(200);
PRESETS[PRESET_IDS.VOCAL_LIMIT_SOPRANO] = createHighPassConfigPreset(250);


const CLASSNAME = "HighPassFilter";
const HighPassFilter =  AudioFilter.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        AudioFilter.apply(this, [attributes, options]);
        this.setPresetIdToConfigMap(PRESETS);
        this.set("type", HighPassFilter.CLASSNAME);
    },

    
    setFrequency : function(frequency){
        this.set("frequency",frequency);
    },


    getFrequency : function(){
        return this.get("frequency");
    },


    setQ : function(Q){
        this.set("Q",Q);
    },


    getQ: function(){
        return this.get("Q");
    },


    isEqual : function(filter){
        if(filter instanceof HighPassFilter){
            return (this.isDisabled() == filter.isDisabled() && this.getFrequency() === filter.getFrequency() && this.getQ() === filter.getQ()); 
        }
        return false;
    },

    clone : function(){
        const cloneFilter = new HighPassFilter();
        cloneFilter.setDisabled(this.isDisabled());
        cloneFilter.setFrequency(this.getFrequency());
        cloneFilter.setQ(this.getQ());
        return cloneFilter;
    },

    needToPrepareAudioContext: function(){
        return false;
    },

    prepareAudioContext: function(audioContext){
        return RSVP.Promise.resolve(audioContext);
    },

    applyFilterToNode : function(audioNode, audioContext, arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime, sequencerRendererTracksInfo ){
        if(!this.isDisabled()){
            const frequency = this.getFrequency();
            const Q = this.getQ();
            const highPassFilterNode = audioContext.createBiquadFilter();
            highPassFilterNode.type = "highpass";
            highPassFilterNode.frequency.value = frequency;
            if(Q){
                highPassFilterNode.frequency.Q = Q;
            }

            audioNode.connect(highPassFilterNode);

            if(arrayOfAudioNodesUsed){
                arrayOfAudioNodesUsed.push(highPassFilterNode);
            }

            return highPassFilterNode;
        }else{
            return audioNode;
        }
    },

    applyFilter : function(previousOutputNode, audioContext, arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime,sequencerRendererTracksInfo, audioBuffer){
        if(!this.isDisabled()){
            if(!previousOutputNode){
                previousOutputNode = audioContext.createBufferSource();
                previousOutputNode.buffer = audioBuffer;
                if(arrayOfAudioNodesUsed){
                    arrayOfAudioNodesUsed.push(previousOutputNode);
                }
            }
            return RSVP.Promise.resolve(this.applyFilterToNode(previousOutputNode, audioContext, arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime, sequencerRendererTracksInfo ));
        }else{
            return RSVP.Promise.resolve(previousOutputNode);
        }
    },


});
HighPassFilter.PRESET_IDS = PRESET_IDS;
HighPassFilter.PRESETS = PRESETS;
HighPassFilter.CLASSNAME = CLASSNAME;

export default HighPassFilter; 