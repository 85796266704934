import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utils from "models/helper/Utils";
import ti18n from "i18n!nls/Commenti18n";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";
import CommentListItemView from "views/comment/CommentListItemView";

const CommentListItemController =  Chaplin.Controller.extend({


    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = options.model;
        this.context = options.context;
        this._disableReply = options.disableReply;
    },
    
    getModel : function(){
        return this.model;
    },
    
    getView : function(){
        return this.view;
    },
    
    likeComment:  function(){
        const commentId = this.model.getCommentId();
        TrebbleClientAPIHelper.getInstance().likeComment(commentId).then((function(){
            this.model.setThumbsUp(true);
            this.view.setLikedComment(true);
            this.view.updateLikeNumberView();
        }).bind(this)).catch((function(error){
            this.view.setLikedComment(false);
            this.view.updateLikeNumberView();
            console.error(error);
            Utils.getInstance().showShortToastMessage(window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_LIKING_COMMENT"));
        }).bind(this));
        if(window.trebbleAnalyticsHelper && window.trebbleAnalyticsHelper.trebbleHelperTrackLikeComment){
            window.trebbleAnalyticsHelper.trebbleHelperTrackLikeComment();
        }

        
    },
    
    unlikeComment:  function(){
        const commentId = this.model.getCommentId();
        TrebbleClientAPIHelper.getInstance().unlikeComment(commentId).then((function(){
            this.model.setThumbsUp(false);
            this.view.setLikedComment(false);
            this.view.updateLikeNumberView();
        }).bind(this)).catch((function(error){
            this.view.setLikedComment(true);
            this.view.updateLikeNumberView();
            console.error(error);
            Utils.getInstance().showShortToastMessage(window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_UNLIKING_COMMENT"));
        }).bind(this));
        if(window.trebbleAnalyticsHelper && window.trebbleAnalyticsHelper.trebbleHelperTrackUnlikeComment){
            window.trebbleAnalyticsHelper.trebbleHelperTrackUnlikeComment();
        }
        
    },
    
    reply : function(){
        return PersistentModels.getInstance().getRouter().showPageCommentReplies(this.model,true,null, this.context, true);
    },
    
    onClickOnUrlPreview : function(url){
        //send data to the backend to track that the url was clicked in this capsule
        const commentId = this.model.getCommentId();
        const openedWindow = window.open(url, "_blank");
        if((!openedWindow || openedWindow.closed || typeof openedWindow.closed=='undefined') && !window.waitForCordovaToLoad){
            window.alertErrorMessage(window.getI18n(ti18n, "PLEASE_ALLOW_POPUPS"));
        }else{
            if(openedWindow.focus){
                openedWindow.focus();
            }
        }
        TrebbleClientAPIHelper.getInstance().logUserClickedOnUrlPreviewInComment(commentId, url, this.context );
    },

    focus : function(){
        this.view.focus();
    },
    
    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                
                const viewParams = {};
                viewParams.model  = this.model;
                viewParams.disableReply = this._disableReply;
                const commentListItemView = new  CommentListItemView(viewParams);
                commentListItemView.render();
                this.view = commentListItemView;
                this.listenTo(this.view,"likeComment", this.likeComment);
                this.listenTo(this.view,"unlikeComment", this.unlikeComment);
                this.listenTo(this.view,"reply", this.reply);
                this.listenTo(this.view, "clickOnUrlPreview", this.onClickOnUrlPreview);
                resolve(this);
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }
    

});
export default CommentListItemController;