import Backbone from "backbone";
import Mousetrap from 'mousetrap';


const KeyboardKeyHandlerHelper = Backbone.Model.extend({
    "default" : {

    },

    initialize : function() {
        this._eventToHandlers = {};
        this._handlerIdToHandler = {};
        this._eventToStopcallback = {};
        const _belongsTo = function (element, ancestor) {
            if (element === null || element === document) {
                return false;
            }

            if (element === ancestor) {
                return true;
            }

            return _belongsTo(element.parentNode, ancestor);
        };

        const keyboardKeyHandlerHelperInstance = this;

        //Adding polymer element as part of the list of element for which keyboard shortcut should be ignored
        Mousetrap.prototype.stopCallback = function(e, element, combo) {
            const self = this;

            const stopCallbacks = keyboardKeyHandlerHelperInstance._eventToStopcallback[combo];

            if(stopCallbacks && stopCallbacks.length){
                for(let i =0; i < stopCallbacks.length; i++){
                    const shouldStop = stopCallbacks[i](e, element, combo);
                    if(shouldStop){
                        return shouldStop;
                    }
                }
            }

            // if the element has the class "mousetrap" then no need to stop
            if ((' ' + element.className + ' ').indexOf(' mousetrap ') > -1) {
                return false;
            }

            if (_belongsTo(element, self.target)) {
                return false;
            }
            

            // stop for input, select, and textarea
            return element.tagName == 'INPUT' ||element.tagName == 'PAPER-INPUT' ||element.tagName == 'PAPER-INPUT-DECORATOR' || element.tagName == 'SELECT' || element.tagName == 'TEXTAREA' || element.isContentEditable;
        };


    },


    
    registerForKeyboardEvent : function( eventToRegisterFors, handler, handlerId, stopCallback, elementToBindTo){
        if(window.device && window.device.platform == "browser"){
            if(!Array.isArray(eventToRegisterFors)){
                eventToRegisterFors = [eventToRegisterFors];
            }
            eventToRegisterFors.forEach((function(eventToRegisterFor){
                let eventHandlers = this._eventToHandlers[eventToRegisterFor];
                if(!eventHandlers){
                    eventHandlers = [];
                    if(handlerId){
                        this._handlerIdToHandler[handlerId] = handler;
                    }
                    this._eventToHandlers[eventToRegisterFor] = eventHandlers;
                    (elementToBindTo? Mousetrap(elementToBindTo):Mousetrap).bind(eventToRegisterFor, (function(e) {
                        try{
                            this.self._notifyHandlerForEvent(e, this.eventName);
                        }catch(error){
                            console.error(error);
                        }
                        return false;
                    }).bind({"self":this, "eventName": eventToRegisterFor}));
                }
                eventHandlers.push(handler);

                if(stopCallback){
                    let stopCallbacks = this._eventToStopcallback[eventToRegisterFor];
                    if(!stopCallbacks){
                        stopCallbacks = [];
                        this._eventToStopcallback[eventToRegisterFor] = stopCallbacks;
                    }
                    stopCallbacks.push(stopCallback);
                }
            }).bind(this));
            
            
        }
    },

    unregisterForKeyboardEvent : function(eventToUnregisterFors, handler){
        if(window.device && window.device.platform == "browser"){
            if(!Array.isArray(eventToUnregisterFors)){
                eventToUnregisterFors = [eventToUnregisterFors];
            }
            eventToUnregisterFors.forEach((function(eventToUnregisterFor){
                if(handler){

                    const eventHandlers = this._eventToHandlers[eventToUnregisterFor];
                    const indexOfHandler = eventHandlers.indexOf(handler);
                    if(indexOfHandler > -1){
                        eventHandlers.splice(indexOfHandler, 1);
                        if(eventHandlers.length == 0){
                            delete this._eventToHandlers[eventToUnregisterFor];
                            Mousetrap.unbind(eventToUnregisterFor);
                        }
                    }

                    const stopCallbackHandlers = this._eventToStopcallback[eventToUnregisterFor];
                    if(stopCallbackHandlers){
                        const indexOfStopCallbackHandler = stopCallbackHandlers.indexOf(handler);
                        if(indexOfStopCallbackHandler > -1){
                            stopCallbackHandlers.splice(indexOfStopCallbackHandler, 1);
                            if(stopCallbackHandlers.length == 0){
                                delete this._eventToStopcallback[eventToUnregisterFor];
                            }
                        }
                    }

                }else{
                    delete this._eventToHandlers[eventToUnregisterFor];
                    Mousetrap.unbind(eventToUnregisterFor);
                }
            }).bind(this))
        }
    },

    unregisterForKeyboardEventByHandlerId : function(eventToUnregisterFors, handlerId){
        const handler = this._handlerIdToHandler[handlerId];
        if(handler){
            this.unregisterForKeyboardEvent(eventToUnregisterFors, handler);
        }
    },
    
    
    _notifyHandlerForEvent : function(e, eventName){
        const eventHandlers = this._eventToHandlers[eventName];
        if(eventHandlers){
            for(let index in eventHandlers){
                try{
                    const shouldStopPropagatingEvent = eventHandlers[index](e, eventName);
                    if(shouldStopPropagatingEvent){
                        break;
                    }
                }catch(error){
                    console.error("Error occured while calling handler for keyboard event "+eventName +". Error:"+error);
                    window.alertErrorMessage(error);
                }
            }
        }
    },

});

const KeyboardKeyHandlerHelperInstance = new KeyboardKeyHandlerHelper();

export default {
	getInstance : function() {
		return KeyboardKeyHandlerHelperInstance;
	}
};