import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import AudioBufferHelper from "models/audioEditor/AudioBufferHelper";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";




const SequencerRendererTracksInfo =  Backbone.Model.extend({
    idAttribute: "id",

    constructor: function(attributes, options) {
        Backbone.Model.apply(this, [attributes, options]);

    },

    init : function(audioContext, sequencer, arrayOfAudioNodesUsed, applySequencerSettings, videoContext, arrayOfVideoNodesUsed){
        this.setAudioContext(audioContext);
        this.setVideoContext(videoContext);
        this.setSequencer(sequencer);
        this.setStartNodeOnMasterTrack(this.getAudioContext().createGain());
        if(this.getVideoContext()){
            this.setStartNodeOnVideoMasterTrack(this._createCombineCompositionNodeFromVideoContext());
        }
        this.setStartNodeOnVoiceTrack(this.getAudioContext().createGain());
        this.setStartNodeOnWrapTrack(this.getAudioContext().createGain());
        this.setStartNodeOnInsertTrack(this.getAudioContext().createGain());
        this.setStartNodeOnUndefinedTrack(this.getAudioContext().createGain());
        if(this.getVideoContext()){
            this.setStartNodeOnVideoMatchingAudioTrack(this._createCombineCompositionNodeFromVideoContext());
        }

        if(applySequencerSettings){
            const promiseArray = [];
            promiseArray.push(this._applySequencerSettingsToTrackNodeAsync(this.getStartNodeOnVoiceTrack(), this.getAudioContext(), this.getSequencer().getSequencerSettings().getVoiceDefaultFiltersArray(), 0 , arrayOfAudioNodesUsed).then((function(resultNode){
                this.setEndNodeOnVoiceTrack(resultNode);
                resultNode.connect(this.getStartNodeOnMasterTrack());
            }).bind(this)));
            promiseArray.push(this._applySequencerSettingsToTrackNodeAsync(this.getStartNodeOnWrapTrack(), this.getAudioContext(), this.getSequencer().getSequencerSettings().getMusicDefaultFiltersArray(), 0 , arrayOfAudioNodesUsed).then((function(resultNode){
                this.setEndNodeOnWrapTrack(resultNode);
                resultNode.connect(this.getStartNodeOnMasterTrack());
            }).bind(this)));
            promiseArray.push(this._applySequencerSettingsToTrackNodeAsync(this.getStartNodeOnInsertTrack(), this.getAudioContext(), this.getSequencer().getSequencerSettings().getSoundDefaultFiltersArray(), 0 , arrayOfAudioNodesUsed).then((function(resultNode){
                this.setEndNodeOnInsertTrack(resultNode);
                resultNode.connect(this.getStartNodeOnMasterTrack());
            }).bind(this)));
            promiseArray.push(this._applySequencerSettingsToTrackNodeAsync(this.getStartNodeOnUndefinedTrack(), this.getAudioContext(), this.getSequencer().getSequencerSettings().getUndefinedDefaultFiltersArray(), 0 , arrayOfAudioNodesUsed).then((function(resultNode){
                this.setEndNodeOnUndefinedTrack(resultNode);
                resultNode.connect(this.getStartNodeOnMasterTrack());
            }).bind(this)));
            promiseArray.push(this._applySequencerSettingsToTrackNodeAsync(this.getStartNodeOnMasterTrack(), this.getAudioContext(), this.getSequencer().getSequencerSettings().getMasterDefaultFiltersArray(), 0 , arrayOfAudioNodesUsed).then((function(resultNode){
                this.setEndNodeOnMasterTrack(resultNode);
            }).bind(this)));
            if(this.getVideoContext()){
                this.setEndNodeOnVideoMatchingAudioTrack(this.getStartNodeOnVideoMatchingAudioTrack());
                this.getEndNodeOnVideoMatchingAudioTrack().connect(this.getStartNodeOnVideoMasterTrack());
                this.setEndNodeOnVideoMasterTrack(this.getStartNodeOnVideoMasterTrack());
                if(arrayOfVideoNodesUsed){
                    arrayOfVideoNodesUsed.push(this.getStartNodeOnVideoMasterTrack());
                    arrayOfVideoNodesUsed.push(this.getStartNodeOnVideoMatchingAudioTrack());
                }
            }
            return RSVP.Promise.all(promiseArray).then((function(){
                return this;
            }).bind(this))
        }else{
            this.setEndNodeOnVoiceTrack(this.getStartNodeOnVoiceTrack());
            this.setEndNodeOnWrapTrack(this.getStartNodeOnWrapTrack());
            this.setEndNodeOnInsertTrack(this.getStartNodeOnInsertTrack());
            this.setEndNodeOnUndefinedTrack(this.getStartNodeOnUndefinedTrack());
            this.setEndNodeOnMasterTrack(this.getStartNodeOnMasterTrack());
            if(this.getVideoContext()){
                this.setEndNodeOnVideoMatchingAudioTrack(this.getStartNodeOnVideoMatchingAudioTrack());
                this.setEndNodeOnVideoMasterTrack(this.getStartNodeOnVideoMasterTrack());
            }


            this.getStartNodeOnVoiceTrack().connect(this.getStartNodeOnMasterTrack());
            this.getStartNodeOnWrapTrack().connect(this.getStartNodeOnMasterTrack());
            this.getStartNodeOnInsertTrack().connect(this.getStartNodeOnMasterTrack());
            this.getStartNodeOnUndefinedTrack().connect(this.getStartNodeOnMasterTrack());
            if(this.getVideoContext()){
                this.getEndNodeOnVideoMatchingAudioTrack().connect(this.getStartNodeOnVideoMasterTrack());
            }
            if(arrayOfAudioNodesUsed){
                arrayOfAudioNodesUsed.push(this.getStartNodeOnVoiceTrack());
                arrayOfAudioNodesUsed.push(this.getStartNodeOnWrapTrack());
                arrayOfAudioNodesUsed.push(this.getStartNodeOnInsertTrack());
                arrayOfAudioNodesUsed.push(this.getStartNodeOnUndefinedTrack());
                arrayOfAudioNodesUsed.push(this.getStartNodeOnMasterTrack());
            }
            if(arrayOfVideoNodesUsed){
                arrayOfVideoNodesUsed.push(this.getStartNodeOnVideoMasterTrack());
                arrayOfVideoNodesUsed.push(this.getStartNodeOnVideoMatchingAudioTrack());
            }

            return RSVP.Promise.resolve(this);

        }
    },

    _createCombineCompositionNodeFromVideoContext : function(){
        const combineDecription ={
            title:"Combine",
            description: "A basic effect which renders the input to the output, Typically used as a combine node for layering up media with alpha transparency.",
            vertexShader : `
            attribute vec2 a_position;
            attribute vec2 a_texCoord;
            varying vec2 v_texCoord;
            void main() {
                gl_Position = vec4(vec2(2.0,2.0)*a_position-vec2(1.0, 1.0), 0.0, 1.0);
                v_texCoord = a_texCoord;
            }`,
            fragmentShader : `
            precision mediump float;
            uniform sampler2D u_image;
            varying vec2 v_texCoord;
            varying float v_mix;
            void main(){
                vec4 color = texture2D(u_image, v_texCoord);
                gl_FragColor = color;
            }`,
            properties:{
            },
            inputs:["u_image"]
        };
        return this.getVideoContext().compositor(combineDecription);
    },

    getAllStartAudioTrackNodes : function(){
        const nodesToReturn = [];

        if(this.getStartNodeOnVoiceTrack()){
            nodesToReturn.push(this.getStartNodeOnVoiceTrack());
        }
        if(this.getStartNodeOnWrapTrack()){
            nodesToReturn.push(this.getStartNodeOnWrapTrack());
        }
        if(this.getStartNodeOnInsertTrack()){
            nodesToReturn.push(this.getStartNodeOnInsertTrack());
        }
        if(this.getStartNodeOnUndefinedTrack()){
            nodesToReturn.push(this.getStartNodeOnUndefinedTrack());
        }
        if(this.getStartNodeOnMasterTrack()){
            nodesToReturn.push(this.getStartNodeOnMasterTrack());
        }

        return nodesToReturn;
    },

    getAllEndAudioTrackNodes : function(){
        const nodesToReturn = [];

        if(this.getEndNodeOnVoiceTrack()){
            nodesToReturn.push(this.getEndNodeOnVoiceTrack());
        }
        if(this.getEndNodeOnWrapTrack()){
            nodesToReturn.push(this.getEndNodeOnWrapTrack());
        }
        if(this.getEndNodeOnInsertTrack()){
            nodesToReturn.push(this.getEndNodeOnInsertTrack());
        }
        if(this.getEndNodeOnUndefinedTrack()){
            nodesToReturn.push(this.getEndNodeOnUndefinedTrack());
        }
        if(this.getEndNodeOnMasterTrack()){
            nodesToReturn.push(this.getEndNodeOnMasterTrack());
        }

        return nodesToReturn;
    },

    getStartAudioTrackNodeByAudioType : function(audioType){
        if(audioType ===  AudioBufferHelper.getInstance().getAudioTypes().MASTER){
            return this.getStartNodeOnMasterTrack();
        }
        if(audioType ===  AudioBufferHelper.getInstance().getAudioTypes().VOICE){
            return this.getStartNodeOnVoiceTrack();
        }
        if(audioType ===  AudioBufferHelper.getInstance().getAudioTypes().WRAP){
            return this.getStartNodeOnWrapTrack();
        }
        if(audioType ===  AudioBufferHelper.getInstance().getAudioTypes().INSERT){
            return this.getStartNodeOnInsertTrack();
        }
        if(audioType ===  AudioBufferHelper.getInstance().getAudioTypes().UNDEFINED){
            return this.getStartNodeOnUndefinedTrack();
        }
        return this.getStartNodeOnUndefinedTrack();

    },

    getStartVideoTrackNodeByAudioType : function(audioType){
        if(audioType ===  AudioBufferHelper.getInstance().getAudioTypes().MASTER){
            return this.getStartNodeOnVideoMasterTrack();
        }
        if(audioType ===  AudioBufferHelper.getInstance().getAudioTypes().VOICE){
            return this.getStartNodeOnVideoMatchingAudioTrack();
        }
        if(audioType ===  AudioBufferHelper.getInstance().getAudioTypes().WRAP){
            return this.getStartNodeOnVideoMatchingAudioTrack();
        }
        if(audioType ===  AudioBufferHelper.getInstance().getAudioTypes().INSERT){
            return this.getStartNodeOnVideoMatchingAudioTrack();
        }
        if(audioType ===  AudioBufferHelper.getInstance().getAudioTypes().UNDEFINED){
            return this.getStartNodeOnVideoMatchingAudioTrack();
        }
        return this.getStartNodeOnVideoMatchingAudioTrack();

    },

    getEndAudioTrackNodeByAudioType : function(audioType){
        if(audioType ===  AudioBufferHelper.getInstance().getAudioTypes().MASTER){
            return this.getEndNodeOnMasterTrack();
        }
        if(audioType ===  AudioBufferHelper.getInstance().getAudioTypes().VOICE){
            return this.getEndNodeOnVoiceTrack();
        }
        if(audioType ===  AudioBufferHelper.getInstance().getAudioTypes().WRAP){
            return this.getEndNodeOnWrapTrack();
        }
        if(audioType ===  AudioBufferHelper.getInstance().getAudioTypes().INSERT){
            return this.getEndNodeOnInsertTrack();
        }
        if(audioType ===  AudioBufferHelper.getInstance().getAudioTypes().UNDEFINED){
            return this.getEndNodeOnUndefinedTrack();
        }
        return this.getEndNodeOnUndefinedTrack();

    },

    getEndVideoTrackNodeByAudioType : function(audioType){
        if(audioType ===  AudioBufferHelper.getInstance().getAudioTypes().MASTER){
            return this.getEndNodeOnVideoMasterTrack();
        }
        if(audioType ===  AudioBufferHelper.getInstance().getAudioTypes().VOICE){
            return this.getEndNodeOnVideoMatchingAudioTrack();
        }
        if(audioType ===  AudioBufferHelper.getInstance().getAudioTypes().WRAP){
            return this.getEndNodeOnVideoMatchingAudioTrack();
        }
        if(audioType ===  AudioBufferHelper.getInstance().getAudioTypes().INSERT){
            return this.getEndNodeOnVideoMatchingAudioTrack();
        }
        if(audioType ===  AudioBufferHelper.getInstance().getAudioTypes().UNDEFINED){
            return this.getEndNodeOnVideoMatchingAudioTrack();
        }
        return this.getEndNodeOnVideoMatchingAudioTrack();

    },

    _applySequencerSettingsToTrackNodeAsync : function(lastAudioNodeReturned, audioContext, filterArray,indexToStartFrom,  arrayOfAudioNodesUsed){
        if(lastAudioNodeReturned){
            if(filterArray){
                if(indexToStartFrom < filterArray.length){
                    const nodeFilter = filterArray[indexToStartFrom];
                    return nodeFilter.applyFilter(lastAudioNodeReturned, audioContext, arrayOfAudioNodesUsed, null, null, this).then((function(newAudioNodeReturned){
                        return this._applySequencerSettingsToTrackNodeAsync(newAudioNodeReturned, audioContext, filterArray,indexToStartFrom+1,  arrayOfAudioNodesUsed);
                    }).bind(this))
                }
            }
            return RSVP.Promise.resolve(lastAudioNodeReturned);
        }
        return RSVP.Promise.resolve();
    },

    setAudioContext : function(audioContext){
        this.set("audioContext",audioContext)
    },

    getAudioContext : function(){
        return this.get("audioContext")
    },

    setVideoContext : function(videoContext){
        this.set("videoContext",videoContext)
    },

    getVideoContext : function(){
        return this.get("videoContext")
    },

    setSequencer : function(sequencer){
        this.set("sequencer",sequencer)
    },

    getSequencer : function(){
        return this.get("sequencer")
    },

    setStartNodeOnMasterTrack : function(startNodeOnMasterTrack){
        this.set("startNodeOnMasterTrack",startNodeOnMasterTrack)
    },

    getStartNodeOnMasterTrack : function(){
        return this.get("startNodeOnMasterTrack")
    },

    setStartNodeOnVoiceTrack : function(startNodeOnVoiceTrack){
        this.set("startNodeOnVoiceTrack",startNodeOnVoiceTrack)
    },

    getStartNodeOnVoiceTrack : function(){
        return this.get("startNodeOnVoiceTrack")
    },

    setStartNodeOnWrapTrack : function(startNodeOnWrapTrack){
        this.set("startNodeOnWrapTrack",startNodeOnWrapTrack)
    },

    getStartNodeOnWrapTrack : function(){
        return this.get("startNodeOnWrapTrack")
    },


    setStartNodeOnInsertTrack : function(startNodeOnInsertTrack){
        this.set("startNodeOnInsertTrack",startNodeOnInsertTrack)
    },

    getStartNodeOnInsertTrack : function(){
        return this.get("startNodeOnInsertTrack")
    },

    setStartNodeOnVideoMatchingAudioTrack : function(startNodeOnVideoMatchingAudioTrack){
        this.set("startNodeOnVideoMatchingAudioTrack",startNodeOnVideoMatchingAudioTrack)
    },

    getStartNodeOnVideoMatchingAudioTrack : function(){
        return this.get("startNodeOnVideoMatchingAudioTrack")
    },

    setStartNodeOnVideoMasterTrack : function(startNodeOnVideoMasterTrack){
        this.set("startNodeOnVideoMasterTrack",startNodeOnVideoMasterTrack)
    },

    getStartNodeOnVideoMasterTrack : function(){
        return this.get("startNodeOnVideoMasterTrack")
    },

    setStartNodeOnUndefinedTrack : function(startNodeOnUndefinedTrack){
        this.set("startNodeOnUndefinedTrack",startNodeOnUndefinedTrack)
    },

    getStartNodeOnUndefinedTrack : function(){
        return this.get("startNodeOnUndefinedTrack")
    },

    setEndNodeOnMasterTrack : function(endNodeOnMasterTrack){
        this.set("endNodeOnMasterTrack",endNodeOnMasterTrack)
    },

    getEndNodeOnMasterTrack : function(){
        return this.get("endNodeOnMasterTrack")
    },

    setEndNodeOnVoiceTrack : function(endNodeOnVoiceTrack){
        this.set("endNodeOnVoiceTrack",endNodeOnVoiceTrack)
    },

    getEndNodeOnVoiceTrack : function(){
        return this.get("endNodeOnVoiceTrack")
    },

    setEndNodeOnWrapTrack : function(endNodeOnWrapTrack){
        this.set("endNodeOnWrapTrack",endNodeOnWrapTrack)
    },

    getEndNodeOnWrapTrack : function(){
        return this.get("endNodeOnWrapTrack")
    },


    setEndNodeOnInsertTrack : function(endNodeOnInsertTrack){
        this.set("endNodeOnInsertTrack",endNodeOnInsertTrack)
    },

    getEndNodeOnInsertTrack : function(){
        return this.get("endNodeOnInsertTrack")
    },

    setEndNodeOnVideoMatchingAudioTrack : function(endNodeOnVideoMatchingAudioTrack){
        this.set("endNodeOnVideoMatchingAudioTrack",endNodeOnVideoMatchingAudioTrack)
    },

    getEndNodeOnVideoMatchingAudioTrack : function(){
        return this.get("endNodeOnVideoMatchingAudioTrack")
    },

    setEndNodeOnVideoMasterTrack : function(endNodeOnVideoMasterTrack){
        this.set("endNodeOnVideoMasterTrack",endNodeOnVideoMasterTrack)
    },

    getEndNodeOnVideoMasterTrack : function(){
        return this.get("endNodeOnVideoMasterTrack")
    },

    setEndNodeOnUndefinedTrack : function(endNodeOnUndefinedTrack){
        this.set("endNodeOnUndefinedTrack",endNodeOnUndefinedTrack)
    },

    getEndNodeOnUndefinedTrack : function(){
        return this.get("endNodeOnUndefinedTrack")
    },





});



export default SequencerRendererTracksInfo; 