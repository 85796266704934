import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import Levenshtein from "libs/levenshtein/levenshtein";
import TrebbleAudio from "models/TrebbleAudio";
import ti18n from "i18n!nls/helperi18n";
import RSVP from "rsvp";

const client_id = 'AMfhsXogG0lPV25fcTJU';
const client_secret = "fec3c076073343b21bd04d32acf67903b051dd99"
const FREESOUND_ID_FROM_CLEAR_VOICE_PREFIX = "myInstantsFromClearVoice:";
const NUMBER_OF_RESULTS_PER_PAGE = 10;
const MyInstantsSoundFromClearVoiceSearchHelper =  Backbone.Model.extend({

    initialize : function() {

    },

    getNumberOfResultsPerPage : function(){
        return NUMBER_OF_RESULTS_PER_PAGE;
    },

    searchForSounds : function(query, pageIndex){
        const params = {};
        params.search = query;
        params.offset = pageIndex * NUMBER_OF_RESULTS_PER_PAGE;
        params.limit = NUMBER_OF_RESULTS_PER_PAGE;
        params.type = "many";
        return this._makeRequestToMyInstantsApi("/myinstants/", params).then((function(responseString){
            const response = JSON.parse(responseString);
            const trebbleAudioArray = [];
            if(response && response.count > 0){
                const items = response.items;
                for(let i =0; i < response.items.length; i++){
                    trebbleAudioArray.push(this._createTrebbleAudioFromMyInstantsData(response.items[i]));
                }
            }
            return trebbleAudioArray;
        }).bind(this))
    },


    _createTrebbleAudioFromMyInstantsData : function(myInstantsData){
        const audioId = FREESOUND_ID_FROM_CLEAR_VOICE_PREFIX + myInstantsData.id;
        const title = myInstantsData.title;
        const artist = "";
        const creationDate = null;//new Date(myInstantsData.created);
        const audioUrl = 'https://api.cleanvoice.ru/myinstants/?type=file&id=' + myInstantsData.id;
        return new TrebbleAudio({
            "audioId": audioId,
            "audioType":Utils.getInstance().getTrebbleAudioType().FREESOUND_FROM_CLEAR_VOICE,
            "title": title,
            "artist": artist,
            "creationDate": creationDate,
            "audioUrl": audioUrl
        })

    },


    _makeRequestToMyInstantsApi : function(apiEndpoint,params ){
        return new Promise(function(resolve, reject){
            try{
                const url =  "https://api.cleanvoice.ru"+apiEndpoint;
                if(!params){
                    params = {};
                }
                //params.token = client_secret;
                const cachedAuthorization  = $.ajaxSettings.headers["Authorization"]; 
                delete $.ajaxSettings.headers["Authorization"]; 
                $.ajax({
                    url: url,
                    type: 'GET', 
                    crossDomain: true,
                    data: params,
                    // Fetch the stored token from localStorage and set in the header

                }).done(resolve).fail(reject);
                $.ajaxSettings.headers["Authorization"] = cachedAuthorization;
            }catch(error){
                reject(error);
            }
        });
    },




});
const myInstantsSoundSearchHelperInstance = new MyInstantsSoundFromClearVoiceSearchHelper();

export default {
	getInstance : function() {
		return myInstantsSoundSearchHelperInstance;
	}
};
