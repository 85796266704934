import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import ti18n from "i18n!nls/Commoni18n";
import PersistentModels from "services/PersistentModels";
import defaultPageViewTemplate from 'text!../../../templates/common/pageViewTemplate.html';

const DynamicPageView = Backbone.View.extend({



    initialize : function(options) {
        const pageViewTemplate = (options && options.pageViewTemplate != null)?options.pageViewTemplate : defaultPageViewTemplate;
        this.defaultTemplate =  _.template(pageViewTemplate);
        this.model = options? options.model : null;
        this._onPageShowHandler = options? options.onPageShow : null;
        this._onPageHideHandler = options? options.onPageHide : null;
        this._isPlayerFooterTransparent = options? options.isPlayerFooterTransparent : false;
        this._ignoreDefaultPageUIContentPadding  = options?options.ignoreDefaultPageUIContentPadding: false;
        this._pageTemplateOptions = options? options.pageTemplateOptions : null;
        this._containerSelector = (options && options.containerSelector != null)? options.containerSelector : ".ui-content > div:first";
        this._pageId = (options && options.pageId != null)? options.pageId : "";
        this._contentView = (options && options.contentView != null)? options.contentView : null;
        this._persistent = (options && options.persistent != null)? options.persistent : false ;
        this._refreshContentOnPageShow = (options && options.refreshContentOnPageShow != null)? options.refreshContentOnPageShow :false;
        this._contentViewAppended = false;
        this._contentLoadedOnce = false;
        this._headerFullscren = (options && options.headerFullscren)?options.headerFullscren : false;
        this._onOrientationChangeCustomHandler = (options && options.onOrientationChangeCustomHandler)?options.onOrientationChangeCustomHandler : null;
        PersistentModels.getInstance().getRouter().registerForViewportHeightChange(this._pageId,this.onOrientationChangeFunction.bind(this));
        
    },
    
    onOrientationChangeFunction  : function(newViewPortHeigh, forceResizing) {
        if(this.$el.is(":visible") || forceResizing){
            if (this.el) {
                let containerContentHeight = this._ignoreDefaultPageUIContentPadding?newViewPortHeigh : (newViewPortHeigh) - (PersistentModels.getInstance().getRouter().getDefaultPageUiContentPadding() * 2) ;
                if(this._headerFullscren){
                    containerContentHeight = containerContentHeight + PersistentModels.getInstance().getRouter().getDefaultHeaderHeight();
                }
                const footerHeight = 67;
                if(this._isPlayerFooterTransparent && PersistentModels.getInstance().getRouter().isFooterDisplayed()){

                    containerContentHeight = containerContentHeight + footerHeight;
                }
                this.$el.find(".ui-content").css("height", containerContentHeight + "px");
                if(this._onOrientationChangeCustomHandler){
                    this._onOrientationChangeCustomHandler(containerContentHeight, this.$el)
                }
            }
        }
    },

    navigateToPreviousPage : function(){
        PersistentModels.getInstance().getRouter().navigateToPreviousPage();
    },
    
    getPageId : function(){
        return this._pageId;
    },
    
    _onPageShow : function(){
        this.trigger("pageShowing");
        this.onOrientationChangeFunction(PersistentModels.getInstance().getRouter().getViewportHeight(), true);
        if(!this._contentLoadedOnce)
        {
            this._loadContentView();
        
        }else{
            if(this._refreshContentOnPageShow)
            {
                this._loadContentView();
            }
        }
        if(this._onPageShowHandler){
            this._onPageShowHandler(this);
        }

        
    },
    
    _loadContentView : function(){
        if(this._contentView)
        {
            if(this._contentView.load){
                /*if(this._contentView.canBeAppendedBeforeLoad && this._contentView.canBeAppendedBeforeLoad() ){
                    if(!this._contentViewAppended){
                        this.$el.find(this._containerSelector).first().append(this._contentView.$el);
                        this._contentViewAppended = true;
                    }
                }*/
                if(this._contentView.$el ){
                    if(!this._contentViewAppended){
                        this.$el.find(this._containerSelector).first().append(this._contentView.$el);
                        this._contentViewAppended = true;
                    }
                }
                this._onContentLoading();
                this._contentView.load().then((function(){
                    if(!this._contentViewAppended){
                        this.$el.find(this._containerSelector).first().append(this._contentView.$el);
                        this._contentViewAppended = true;
                    }
                    this._onContentLoaded();
                }).bind(this)).catch((function(error){
                    this._onContentFailedLoading(error);
                }).bind(this));
            }else{
                if(!this._contentViewAppended){
                    this.$el.find(this._containerSelector).first().append(this._contentView.$el);
                }
            }
            this._contentLoadedOnce = true;
        }
    },
    
    _onContentLoading : function(){
        $.mobile.loading( 'show');
    },
    
    _onContentLoaded : function(){
        $.mobile.loading( "hide" );
    },
    
    _onContentFailedLoading : function(error){
        $.mobile.loading( "hide" );
        console.error("Page content failed loading. Error:"+error);
        window.alertErrorMessage(window.getI18n(ti18n, "PAGE_FAILED_LOADING"));
    },
    
    _onPageHide : function(){
        
        if(!this._persistent && PersistentModels.getInstance().getRouter().isBrowserHistoryGoingBack())
        //if(!this._persistent)
        {
            this.onBeforeRemoveFromPage();
            this.remove();
            PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._pageId);
            this.onRemovedFromPage();
        }
        if(this._onPageHideHandler){
            this._onPageHideHandler(this);
        }
    },

    onBeforeRemoveFromPage : function(){
        this.trigger("beforeRemoveFromPage", this);
    },
    
    onRemovedFromPage : function(){
         this.undelegateEvents();
         this.$el.removeData().unbind(); 
         this.trigger("removedFromPage", this);
    },
    
    onBeforePageInitialized: function(){
        
    },
    
    onAfterPageInitialized : function(){
        
    },
    render : function() {
        this.$el.html(this.defaultTemplate({"model": this.model, "pageId": this._pageId, "options": this._pageTemplateOptions}));
        this.setElement(this.$el.find("div").first());
        this.onBeforePageInitialized();
        this.$el.page();
        this.$el.children( ".ui-header:not(.ui-header-fixed), .ui-content:not(.ui-popup), .ui-footer:not(.ui-footer-fixed)" ).wrapAll( "<div class='ui-panel-wrapper'></div>" )
        this.onAfterPageInitialized();
        //this.$el.find(".topsticky").fixedsticky();
        this._loadContentView();
        this.$el.on("pagehide", this._onPageHide.bind(this));
        this.$el.on("pageshow", this._onPageShow.bind(this));
        return this;
    }

});
export default DynamicPageView;