import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import TrebblePlaylistListExtendedView from "views/common/TrebblePlaylistListExtendedView";
import PlaylistCollection from "collections/PlaylistCollection";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";

const PlaylistListExtendedView = Backbone.View.extend({





    initialize: function(options) {
        this.displayedCollection = this.collection;
        this.collection.bind("add", this._onPlaylistAddedOrRemoved, this);
        this.collection.bind("change", this._onPlaylistUpdated, this);
        this.collection.bind("remove", this._onPlaylistAddedOrRemoved, this);
        this.collection.bind("reset", this._onPlaylistAddedOrRemoved, this);
        this._delayOnCollectionUpdate = (options && options.delayOnCollectionUpdate) ? options.delayOnCollectionUpdate :  1000;
        this._resizeToAvailableScreenSizeOnOrientationChange = options  ? options.resizeToAvailableScreenSizeOnOrientationChange :  true;
        this._showHeader = options  ? options.showHeader :  false;
        this._headerText= options  ? options.headerText :  "";
        this._showFooter= options  ? options.showFooter :  false;
        this._footerText= options  ? options.footerText :  "";
        this._customInfoBoxParams =options? options.customInfoBoxParams : null;
        this._customEmptyInfoBoxParams =options? options.customEmptyInfoBoxParams : null;
        this._container$elForImageLazyLoad = options? options.container$elForImageLazyLoad: null;
        this._customMapFunctionToConvertJsonObjToTemplateData = options? options.customMapFunctionToConvertJsonObjToTemplateData: null;
        this._loadHandler= (options && options.loadHandler)  ? options.loadHandler :  null;
        this._heightMargin= (options && options.heightMargin)  ? options.heightMargin :  null;
        this._resizePlaceHolderElem =  options  ? options.resizePlaceHolderElem :  false;
        this._doNotSortDisplayedCollection =  options  ? options.doNotSortDisplayedCollection :  true;
        this._listenerViewportKey = "playlistListExtendedView" + (new Date()).getTime();
        this._doNotInitializeLazyLoadingAtRender = options? options.doNotInitializeLazyLoadingAtRender : false;
        this._showOnePlaylistPerLine =  options.showOnePlaylistPerLine;
        this._customCollectionConstructor = options ? options.customCollectionConstructor: null;
        this._customItemViewUpdateDuringScrollingHandler = options? options.customItemViewUpdateDuringScrollingHandler: null;
        PersistentModels.getInstance().getRouter().registerForViewportHeightChange(this._listenerViewportKey,this._onOrientationChangeFunction.bind(this));
    },

    _onOrientationChangeFunction : function(newViewPortHeigh, forceResizing) {
        if(!this._removeFromDom && (this.$el.is(":visible") || forceResizing)){
            if (!this._resizePlaceHolderElem && this.el) {
                if(this._heightMargin){
                    newViewPortHeigh = newViewPortHeigh - this._heightMargin;
                }
                this.el.style.height = (newViewPortHeigh ) + "px"; 
            }
            this.infiniteListView._onRootElementResize();
        }
    },
    
    
    filterList: function(searchCharaterers) {
        if(!this._removeFromDom){
            this.appliedSearchCharacters = searchCharaterers;
            const searchFunction = (function() {
                $.mobile.loading("show");
                const r = this.collection.searchOnPlaylistName(this.appliedSearchCharacters);
                if(r instanceof RSVP.Promise){
                    r.then((function(arrayOfResults){
                       const newPlaylistCollection = this._getCollection(arrayOfResults);
                       this._changePlaylistList(newPlaylistCollection);
                       this.pendingSearchFunctionTimerId = null;
                       $.mobile.loading("hide");
                   }).bind(this)).catch((function(error){
                    $.mobile.loading("hide");
                    window.alertErrorMessage(error);
                }).bind(this));
               }else{
                const newPlaylistCollection = this._getCollection(r);
                   /*const playlistJsonArray = this.collection.searchOnPlaylistName(this.appliedSearchCharacters);
                   for(let index in playlistJsonArray){
                       const playlistJson  = playlistJsonArray[index];
                       newPlaylistCollection.add(new FixedPlaylist(playlistJson));
                   }*/
                   this._changePlaylistList(newPlaylistCollection);
                   this.pendingSearchFunctionTimerId = null;
                   $.mobile.loading("hide");
               }
               
           }).bind(this);
if (this.pendingSearchFunctionTimerId) 
{
window.clearTimeout(this.pendingSearchFunctionTimerId);
}
this.pendingSearchFunctionTimerId = window.setTimeout(searchFunction,  this._delayOnCollectionUpdate);
}
},

_getCollection : function(arrayOfObjects){
if(this._customCollectionConstructor){
return this._customCollectionConstructor(arrayOfObjects)
}else{
return new PlaylistCollection(arrayOfObjects);
}
},

clearSearchfilter: function() {
if(!this._removeFromDom){
this.appliedSearchCharacters = null;
const searchFunction = (function() {
    const newPlaylistCollection = this._getCollection(this.collection.searchOnPlaylistName(""));
    /*  const playlistJsonArray = this.collection.searchOnPlaylistName("");
    for(let index in playlistJsonArray){
        const playlistJson  = playlistJsonArray[index];
        newPlaylistCollection.add(new FixedPlaylist(playlistJson));
    }*/
    this._changePlaylistList(newPlaylistCollection);
    this.pendingSearchFunctionTimerId = null;
}).bind(this);
if (this.pendingSearchFunctionTimerId) 
{
    window.clearTimeout(this.pendingSearchFunctionTimerId);
}
this.pendingSearchFunctionTimerId = window.setTimeout(searchFunction, 0);
}
},


_changePlaylistList: function(newPlaylistModels) {
if(!this._removeFromDom){
this.displayedCollection = newPlaylistModels;
if(!this._doNotSortDisplayedCollection)
{
    this.displayedCollection.sort();
}
this.infiniteListView.updateModels(this.displayedCollection.toJSON());
}
},


_updatePlaylistInPlaylistList: function(updatedPlaylistModel) {
if(!this._removeFromDom){
if(!this._doNotSortDisplayedCollection)
{
    this.displayedCollection.sort();
}
this.infiniteListView.updateModel(updatedPlaylistModel.toJSON());
this.infiniteListView.setUpdating(false);
}
},


_getOnListItemTapHandler: function() {
return (function(playlistJson, evnt, item) {
this.trigger("itemTaped", this._getPlaylistModelFromPlaylistJson(playlistJson) );
}).bind(this);
},

_getOnListItemTapEditHandler: function() {
return (function(playlistJson, evnt, item) {
this.trigger("itemTapedEdit", this._getPlaylistModelFromPlaylistJson(playlistJson) );
}).bind(this);
},

_getOnListItemTapShareHandler: function() {
return (function(playlistJson, evnt, item) {
this.trigger("itemTapedShare", this._getPlaylistModelFromPlaylistJson(playlistJson) );
}).bind(this);
},

_getOnListItemPressHandler : function(){
return  (function(albumJson,evnt, item) {
this.trigger("itemPressed",this._getPlaylistModelFromPlaylistJson(albumJson));     
}).bind(this);
},

_getOnListItemTapPlayHandler : function(){
return  (function(albumJson,evnt, item) {
this.trigger("itemTapedPlay",this._getPlaylistModelFromPlaylistJson(albumJson));     
}).bind(this);
},

_getOnListItemPressUpHandler : function(){
return  (function(albumJson,evnt, item) {
this.trigger("itemPressedUp",this._getPlaylistModelFromPlaylistJson(albumJson));       
}).bind(this);
},

_onItemSwipeRight: function() {
return (function(playlistJson, evnt, item) {
//this.trigger("itemSwipedRight", this._getSongModelFromSongJson(songJson));

}).bind(this);
},

_getPlaylistModelFromPlaylistJson : function(playlistJson){
return this.collection.get(playlistJson.id );
},

/* _modelTransformHandlerBeforeTemplatePiping: function(songJson) {

if (songJson.idified && (songJson.idified === "true" || songJson.idified === true) && (songJson.idifiedSuccess === "true" || songJson.idifiedSuccess === true)) 
{
if (window.trebble.config.showIdifiedArtistName) 
{
    const featuredArtists = songJson.idifiedFeaturedArtists;
    const featuredArtistsString = null;
    if(featuredArtists && featuredArtists.length > 0){
        for(let index in featuredArtists){
            const featuredArtist = featuredArtists[index];
            if(!featuredArtistsString)
            {
                featuredArtistsString =   featuredArtist.artist;
            }else{
                featuredArtistsString =  featuredArtistsString + ", " + featuredArtist.artist;
            }
        }
    }
    const mainArtist = songJson.idifiedArtist;

    const allArtists = featuredArtistsString? mainArtist + ", " +featuredArtistsString : mainArtist;
    artistToDisplay = allArtists;
}else{
   artistToDisplay = songJson.artist;
}
if (window.trebble.config.showIdifiedSongTitle) 
{
titleToDisplay = songJson.idifiedTitle;
}else{
titleToDisplay = songJson.title;
}
if(window.trebble.config.showIdifiedAlbumTitle){
albumNameToDisplay = songJson.idifiedAlbum;
}else{
albumNameToDisplay = songJson.album;
}
} else {
artistToDisplay = songJson.artist;
titleToDisplay = songJson.title;
albumNameToDisplay = songJson.album;
}
if (!artistToDisplay) 
{
artistToDisplay = "";
}
if (!titleToDisplay) 
{
titleToDisplay = "";
}

if (!albumNameToDisplay) 
{
albumNameToDisplay = "";
}
templateData = {firstLineText: titleToDisplay,secondLineText: artistToDisplay + " �� "+ albumNameToDisplay };
return templateData;
},*/




isSearchApplied : function(){
return this.appliedSearchCharacters != null;
},

getAppliedSearchCharacters : function(){
return this.appliedSearchCharacters;
}, 

_onPlaylistAddedOrRemoved : function(){
if(!this._removeFromDom){
if(this.isSearchApplied())
{
    this.filterList(this.getAppliedSearchCharacters());
}else{
    this._changePlaylistList(this.collection);
}
}
this.trigger("modelChange", this.collection);

},

_onPlaylistUpdated : function(playlistUpdatedModel){
if(!this._removeFromDom){
if(this.isSearchApplied())
{
this.filterList(this.getAppliedSearchCharacters());
}else{
//this.infiniteListView.setUpdating(true);
this._updatePlaylistInPlaylistList(playlistUpdatedModel);
}
}
this.trigger("modelChange", this.collection);

},

isCollectionEmpty : function(){
if(this.collection)
{
return this.collection.length == 0;
}else{
return true;
}
},



load : function(params){
if(this._loadHandler && !this._removeFromDom){
//this.infiniteListView.setUpdating(true);
return this._loadHandler(params).then((function(arrayOfPlaylists){
    if(!this._removeFromDom){
        this.collection.reset(arrayOfPlaylists);
    }
    //this.infiniteListView.setUpdating(false);
}).bind(this));
}else{
return RSVP.Promise.resolve();
}
},

show : function(){
this.$el.css("display", "");
},

hide : function(){
this.$el.css("display", "none");
},

_onNodeRemovedFromDom : function(){
this._removeFromDom  = true;
PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._listenerViewportKey);
},

initializeImageLazyLoadPlugin: function(container$elForImageLazyLoad){
this.infiniteListView.initializeImageLazyLoadPlugin(container$elForImageLazyLoad);
},

cancelPendingImageLoading : function(){
this.infiniteListView.cancelPendingImageLoading();
},


render: function() {
let newViewPortHeigh = PersistentModels.getInstance().getRouter().getViewportHeight();
if(this._heightMargin){
newViewPortHeigh = newViewPortHeigh - this._heightMargin;
}
this.$el.html(' <div id="playlistExtendedWrapper"  style="height:' +(newViewPortHeigh + "px") + ';overflow:hidden;width:100%"></div>');
this.setElement(this.$el.find("div").first());
const listParams = {};
if(!this._doNotSortDisplayedCollection)
{
this.displayedCollection.sort();
}
listParams.model = this.displayedCollection.toJSON();
listParams.listRootElement = this.el;
listParams.defaultNumberOfElements = 80;
listParams.defaultHeightElement = 364;//190;
listParams.placeHolderHeight =  PersistentModels.getInstance().getRouter().getViewportHeight();
listParams.resizePlaceHolderElem =  this._resizePlaceHolderElem;
listParams.container$elForImageLazyLoad = this._container$elForImageLazyLoad;
listParams.doNotInitializeLazyLoadingAtRender = this._doNotInitializeLazyLoadingAtRender;
listParams.modelIdAttribute = "id";
listParams.showOnePlaylistPerLine = this._showOnePlaylistPerLine;
listParams.customInfoBoxParams = this._customInfoBoxParams;
listParams.customEmptyInfoBoxParams = this._customEmptyInfoBoxParams;
const infiniteListView = new TrebblePlaylistListExtendedView(listParams);
infiniteListView.setModelTransformHandlerBeforeTemplatePiping(this._customMapFunctionToConvertJsonObjToTemplateData);
infiniteListView.setItemViewUpdateDuringScrollingHandler(this._customItemViewUpdateDuringScrollingHandler);
this.infiniteListView = infiniteListView;
// infiniteListView.setModelTransformHandlerBeforeTemplatePiping(this._modelTransformHandlerBeforeTemplatePiping);
infiniteListView.addOnItemTapHandler(this._getOnListItemTapHandler());
infiniteListView.addOnItemTapEditHandler(this._getOnListItemTapEditHandler());
infiniteListView.addOnItemTapShareHandler(this._getOnListItemTapShareHandler());
infiniteListView.addOnItemPressHandler(this._getOnListItemPressHandler());
infiniteListView.addOnItemPressUpHandler(this._getOnListItemPressUpHandler());
infiniteListView.addOnItemTapPlayHandler(this._getOnListItemTapPlayHandler());

// infiniteListView.addOnOptionButtonTappedHandlers(this._getOnListButtonTappedHandler());
// infiniteListView.addOnSwipeRightRevealHandler(this._onItemSwipeRight());
infiniteListView.render();
this._onOrientationChangeFunction(PersistentModels.getInstance().getRouter().getViewportHeight(), true);
this.$el.on("remove",this._onNodeRemovedFromDom.bind(this));
return this;
}

});

export default PlaylistListExtendedView;
