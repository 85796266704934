


import _ from 'underscore';
import SequencerOperation from "models/audioEditor/sequencerOperation/SequencerOperation";

const CorrectTranscriptOnSingleSequencerNodeOperation =  SequencerOperation.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperation.apply(this, [attributes, options]);
    },

    execute : function(wordSequencerNode, newTranscriptText){
        const oldTranscriptText = wordSequencerNode.getAudioSegment().getContent();
        wordSequencerNode.getAudioSegment().setContent(newTranscriptText);
        this.getSequencer().getHistoryManagement().addSequencerOperationCorrectTranscriptSingleSequencerNodeLog(wordSequencerNode, oldTranscriptText, newTranscriptText);
        this.trigger("transcriptionCorrection");
    },


    undo: function(sequencerOperationCorrectTranscriptSingleSequencerNodeLog){
        const wordSequencerNode = sequencerOperationCorrectTranscriptSingleSequencerNodeLog.getWordSequencerNode();
        const oldTranscriptText = sequencerOperationCorrectTranscriptSingleSequencerNodeLog.getOldTranscriptText();
        wordSequencerNode.getAudioSegment().setContent(oldTranscriptText);
        this.trigger("transcriptionCorrection");
    },

    redo: function(sequencerOperationCorrectTranscriptSingleSequencerNodeLog){
        const wordSequencerNode = sequencerOperationCorrectTranscriptSingleSequencerNodeLog.getWordSequencerNode();
        const newTranscriptText = sequencerOperationCorrectTranscriptSingleSequencerNodeLog.getNewTranscriptText();
        wordSequencerNode.getAudioSegment().setContent(newTranscriptText);
        this.trigger("transcriptionCorrection");
    },





});

export default CorrectTranscriptOnSingleSequencerNodeOperation; 