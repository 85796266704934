
import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import InsertAudioNodeOperation from "models/audioEditor/sequencerOperation/InsertAudioNodeOperation";
import ReplacementSequencerNode from "models/audioEditor/sequencerNodes/ReplacementSequencerNode";
import StartWrapSequencerNode from "models/audioEditor/sequencerNodes/StartWrapSequencerNode";
import EndWrapSequencerNode from "models/audioEditor/sequencerNodes/EndWrapSequencerNode";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const MoveNodeOperation =  InsertAudioNodeOperation.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        InsertAudioNodeOperation.apply(this, [attributes, options]);
    },

    execute : function(audioNodeToMove, newPreviousSequencerNode, newNextSequencerNode){
        if(audioNodeToMove !== newPreviousSequencerNode && audioNodeToMove !== newNextSequencerNode){
                const oldPreviousSequencerNode = audioNodeToMove.getPrevious();
                const oldNextSequencerNode = audioNodeToMove.getNext();
                this.removeAudioNode(audioNodeToMove);
                if(newPreviousSequencerNode){
                    audioNodeToMove.insertAfter(newPreviousSequencerNode);
                }else{
                    audioNodeToMove.insertBefore(newNextSequencerNode);
                }
                this.getSequencer().getHistoryManagement().addSequencerOperationMoveNodeLog(audioNodeToMove, oldPreviousSequencerNode, oldNextSequencerNode,newPreviousSequencerNode, newNextSequencerNode);
            }
        },


        undo: function(sequencerOperationMoveNodeLog){
            const audioNodeToMove = sequencerOperationMoveNodeLog.getNodeToMove();
            const oldPreviousSequencerNode = sequencerOperationMoveNodeLog.getOldPreviousSequencerNode();
            const oldNextSequencerNode = sequencerOperationMoveNodeLog.getOldNextSequencerNode();
            const newPreviousSequencerNode = sequencerOperationMoveNodeLog.getNewPreviousSequencerNode();
            const newNextSequencerNode = sequencerOperationMoveNodeLog.getNewNextSequencerNode();
            this.removeAudioNode(audioNodeToMove);
            if(oldPreviousSequencerNode){
                audioNodeToMove.insertAfter(oldPreviousSequencerNode);
            }else{
                audioNodeToMove.insertBefore(oldNextSequencerNode);
            }
        },

        redo: function(sequencerOperationMoveNodeLog){
            const audioNodeToMove = sequencerOperationMoveNodeLog.getNodeToMove();
            const oldPreviousSequencerNode = sequencerOperationMoveNodeLog.getOldPreviousSequencerNode();
            const oldNextSequencerNode = sequencerOperationMoveNodeLog.getOldNextSequencerNode();
            const newPreviousSequencerNode = sequencerOperationMoveNodeLog.getNewPreviousSequencerNode();
            const newNextSequencerNode = sequencerOperationMoveNodeLog.getNewNextSequencerNode();
            this.removeAudioNode(audioNodeToMove);
            if(newPreviousSequencerNode){
                audioNodeToMove.insertAfter(newPreviousSequencerNode);
            }else{
                audioNodeToMove.insertBefore(newNextSequencerNode);
            }
        },



    });

export default MoveNodeOperation; 