import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import SongListPageController from "controllers/songListPageController/SongListPageController";
import TrebbleIndexDBHelper from "models/helper/TrebbleIndexDBHelper";
import YoutubeSearchHelper from "models/helper/YoutubeSearchHelper";
import RSVP from "rsvp";

const YoutubeListPageConttroller = SongListPageController.extend({




    getSongListLoadHandler : function(){
        const searchKeyword = this.getSearchKeyword()
        return function(){
            return YoutubeSearchHelper.getInstance().getSongJsonFromVideos(searchKeyword && searchKeyword.trim(), 40).then(function(arrayOfYoutubeVideo){
                const arrayOfSongsWithAlbumAndArtistRef = TrebbleIndexDBHelper.getInstance().getSongsWithAlbumAndArtistInfoFromSongJsonArray(arrayOfYoutubeVideo);
                return arrayOfSongsWithAlbumAndArtistRef
            });
        }
    },
    


});
export default YoutubeListPageConttroller;