import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import SequencerLibrary from "models/audioEditor/SequencerLibrary";
import AudioSegmentFactory from "models/audioEditor/segments/AudioSegmentFactory";
import AudioFilterFactory from "models/audioEditor/filters/AudioFilterFactory";
import SequencerNode from "models/audioEditor/sequencerNodes/SequencerNode";
import DeletedSequencerNode from "models/audioEditor/sequencerNodes/DeletedSequencerNode";
import PauseSequencerNode from "models/audioEditor/sequencerNodes/PauseSequencerNode";
import PunctuationSequencerNode from "models/audioEditor/sequencerNodes/PunctuationSequencerNode";
import UnsupportedAudioSequencerNode from "models/audioEditor/sequencerNodes/UnsupportedAudioSequencerNode";
import WordSequencerNode from "models/audioEditor/sequencerNodes/WordSequencerNode";
import WrapSequencerNode from "models/audioEditor/sequencerNodes/WrapSequencerNode";
import ReplacementSequencerNode from "models/audioEditor/sequencerNodes/ReplacementSequencerNode";
import StartMusicWrapSequencerNode from "models/audioEditor/sequencerNodes/StartMusicWrapSequencerNode";
import EndMusicWrapSequencerNode from "models/audioEditor/sequencerNodes/EndMusicWrapSequencerNode";
import StartWrapSequencerNode from "models/audioEditor/sequencerNodes/StartWrapSequencerNode";
import EndWrapSequencerNode from "models/audioEditor/sequencerNodes/EndWrapSequencerNode";
import AudioSequencerNode from "models/audioEditor/sequencerNodes/AudioSequencerNode";
import VideoSequencerNode from "models/audioEditor/sequencerNodes/VideoSequencerNode";
import NonRetainedDeletedSequencerNode from "models/audioEditor/sequencerNodes/NonRetainedDeletedSequencerNode";
import RSVP from "rsvp";


const sequencerNodeContructors = [SequencerNode,
    DeletedSequencerNode,
    PauseSequencerNode,
    PunctuationSequencerNode,
    UnsupportedAudioSequencerNode,
    WordSequencerNode,
    WrapSequencerNode,
    ReplacementSequencerNode,
    StartMusicWrapSequencerNode,
    EndMusicWrapSequencerNode,
    StartWrapSequencerNode,
    EndWrapSequencerNode,
    AudioSequencerNode,
    VideoSequencerNode,
    NonRetainedDeletedSequencerNode];
const SequencerNodeFactory =  Backbone.Model.extend({
    idAttribute: "id",


    createSequencerNodesFromNodeCidToSerializedDataMap : function(cloneCidToSequencerNodeSerializedData, sequencer){
        const promises = [];
        const nodeCidToSequencerNode = {};
        for(let nodeCid in cloneCidToSequencerNodeSerializedData){
            const sequencerNodeSerializedData = cloneCidToSequencerNodeSerializedData[nodeCid]; 
            for(let i =0; i < sequencerNodeContructors.length; i++){
                const contructorClass = sequencerNodeContructors[i];
                if(sequencerNodeSerializedData.type === contructorClass.CLASSNAME){
                    const sequencerNodeInstance = new contructorClass({sequencer});
                    nodeCidToSequencerNode[nodeCid] = sequencerNodeInstance;
                    promises.push(sequencerNodeInstance.restoreFromSerializedData(sequencerNodeSerializedData, AudioSegmentFactory.getInstance(), AudioFilterFactory.getInstance(), sequencer.getAdditionalSpeakerInforArray()));
                    break;
                }
            }
        }

        return RSVP.Promise.all(promises).then(function(){
            for(let nodeCid in nodeCidToSequencerNode){
                const sequencerNode = nodeCidToSequencerNode[nodeCid];
                const serializedData = cloneCidToSequencerNodeSerializedData[nodeCid];
                sequencerNode.afterAllInstancesCreatedFromSerializedData(serializedData, nodeCidToSequencerNode);
                sequencerNode.setSequencer(sequencer);
            }
            return nodeCidToSequencerNode;
        })
    },




    


});


const sequencerNodeFactoryInstance = new SequencerNodeFactory();

export default {
    getInstance : function() {
        return sequencerNodeFactoryInstance;
    }
}; 