import { useEffect, useState } from 'react';

const useBackboneModel = (model, convertToJSON, specificPropertyToListenTo) => {
  const getDataFromModel = ()=>{
    return convertToJSON? convertToJSON(model):(model?model.toJSON():null)
  }
  const [data, setData] = useState(getDataFromModel());

  useEffect(() => {
    const handleChange = () => {
      setData(getDataFromModel());
    };
    if(model){
        model.on(specificPropertyToListenTo? `change:${specificPropertyToListenTo}`: 'change', handleChange);
    }

    return () => {
        if(model){
          model.off(specificPropertyToListenTo? `change:${specificPropertyToListenTo}`: 'change', handleChange);
        }
    };
  }, [model, convertToJSON]);

  return data;
};

export default useBackboneModel;