import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import AlbumOptionMenuView from "views/albumOptionMenu/AlbumOptionMenuView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utils from "models/helper/Utils";
import ti18n from "i18n!nls/AlbumOptionMenui18n";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";

const AlbumOptionMenuController = Chaplin.Controller.extend({

    initialize : function(album) {
        this.model = album
        _.extend(this, Backbone.Events);
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    addToQueue : function(){
        const songs = this.model.get("songs");
        const remoteAndLocalSongs = [];
        if(songs)
        {
            for(let songIndex in songs ){
                const songJson =  songs[songIndex];
                let songFound = PersistentModels.getInstance().getSongModelBySongIdFromMyLibraryOrFavorites( songJson.uri);
                if(!songFound)
                {
                    songFound = Utils.getInstance().getModelFromSongJson(songJson)
                }
                if(songFound){
                    remoteAndLocalSongs.push(songFound)
                }
            }
        }
        PlayerService.getInstance().addSongsToQueue(remoteAndLocalSongs);
    },
    
    playSimilarFromLibrary : function(){
        $.mobile.loading( 'show', {
            //text: "Generating Playlist ...",
            textVisible: false,
            theme: "t",
            textonly: false,
            // html: html
        });
        const isMusicCurrentlyPlaying =  PlayerService.getInstance().isMusicPlaying();
        PlayerService.getInstance().generateSimilarLocalSongPlaylistAndAddToQueue(this.model).then(function(){
            if(!isMusicCurrentlyPlaying)
            {
                PlayerService.getInstance().playFirstSongInQueue();
            }
            $.mobile.loading( "hide" );
        }).catch(function(error){
            window.alertErrorMessage(error);
            $.mobile.loading( "hide" );
        });
        
        
    },
    
    playSimilarFromOtherUserLibraries : function(){
        $.mobile.loading( 'show', {
            //text: "Generating Playlist ...",
            textVisible: false,
            theme: "t",
            textonly: false,
            // html: html
        });
        const isMusicCurrentlyPlaying =  PlayerService.getInstance().isMusicPlaying();
        PlayerService.getInstance().generateSimilarSongPlaylistFromOtherUsersAndAddToQueue(this.model, true).then(function(){
            if(!isMusicCurrentlyPlaying)
            {
                PlayerService.getInstance().playFirstSongInQueue();
            }
            $.mobile.loading( "hide" );
        }).catch(function(error){
            window.alertErrorMessage(error);
            $.mobile.loading( "hide" );
        });
        
        
    },
    
    _buildPlaylistNameFromLocalPlaylistGeneratedFromAlbum : function(){
        let playlistName =  "";
        if(this.model)
        {
            playlistName  = window.getI18n(ti18n,"LOCAL_SONGS_SIMILAR_TO")+" " + this.model.get("album");
        }else{
            playlistName  = window.getI18n(ti18n,"LOCAL_SONGS_SIMILAR_TO_ALBUM");
        }

        return playlistName;
    },
    
    browseAlbum : function(){
        PersistentModels.getInstance().getRouter().showAlbumDetailPage(this.model.get("albumId"), this.model, true);
    },
    
    browseArtist : function(){
        PersistentModels.getInstance().getRouter().showArtistFullDetailPage(this.model.get("artistJson").artistId, this.model.get("artistJson").artistName, this.model.get("artistJson"), true);
    },
    
    onViewRemoved:function(){
        this.stopListening();
    },
    
    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                
                this.view = new AlbumOptionMenuView({
                    "model" : this.model
                });
                this.listenTo(this.view, "addToQueue",this.addToQueue);
                this.listenTo(this.view, "playSimilar",this.playSimilarFromLibrary);
                this.listenTo(this.view, "playSimilarFromFollowers",this.playSimilarFromOtherUserLibraries);
                this.listenTo(this.view, "browseAlbum",this.browseAlbum);
                this.listenTo(this.view, "browseArtist",this.browseArtist);
                this.listenTo(this.view, "remove", this.onViewRemoved);
                this.view.render();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default AlbumOptionMenuController;