import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import SequencerNodeUtils from 'models/audioEditor/SequencerNodeUtils';
import RSVP from "rsvp";

const SEARCH_TYPE = {
    "TEXT_SEARCH":"textSearch",
    "DISFLUENCY_SEARCH":"disfluenySearch",
    "FILLER_WORDS":"fillerWordSearch",
    "SILENCES":"silenceSearch",
    "EDIT_BOUNDARIES":"editBoundaries"
}
const TIMEOUT_DELAY = 100;
const SequencerSearchWidget =  Backbone.Model.extend({

    constructor: function(attributes, options) {

        Backbone.Model.apply(this, [attributes, options]);
        //this._nodesInClipboard =  new Backbone.Collection();
        this._sequencer = attributes.sequencer;
        this.set("searchParams", null);
        this._indexOfNodeInFocus = -1;
        this.set("allArraysOfNodesMatchingResults", []);
        this.set("allArraysOfNodesToHiglights", []);
        this.listenTo(this._sequencer, "add", (this.onNodeAddedToSequencer).bind(this));
        this.listenTo(this._sequencer, "transcriptionCorrection", (this.onTranscriptionCorrection).bind(this));
        this.listenTo(this._sequencer, "remove", (this.onNodeRemovedFromSequencer).bind(this));
        this.SEARCH_TYPE = SEARCH_TYPE;
        this.setSearchType(SEARCH_TYPE.TEXT_SEARCH);
        
    },

    onNodeAddedToSequencer : function(){
        if(this.isSearchOn()){
            this._triggerSearchRefresh();
        }
    },

    onTranscriptionCorrection: function(){
        if(this.isSearchOn()){
            this._triggerSearchRefresh();
        }
    },

    getSearchParams : function(){
        return this.get("searchParams");
    },

    _triggerSearchRefresh: function(){
        if (this._pendingSearchWidgetUpdateHandlerId) {
            clearTimeout(this._pendingSearchWidgetUpdateHandlerId);
            this._pendingSearchWidgetUpdateHandlerId = null;
        }
        this._pendingSearchWidgetUpdateHandlerId = setTimeout((function(){
            this._pendingSearchWidgetUpdateHandlerId = null;
            const searchType = this.getSearchType();
            switch(searchType){
                case SEARCH_TYPE.DISFLUENCY_SEARCH:
                    this.applyDisfluencySearch(this._disfluencySequencerNodeInfoArray, true, true)
                    break;
                case SEARCH_TYPE.TEXT_SEARCH:
                    this.applyTextSearch(this.getSearchParams(), true, true);
                    break;
                case SEARCH_TYPE.FILLER_WORDS:
                    this.applyFillerWordsSearch(true, true)
                    break;
                case SEARCH_TYPE.EDIT_BOUNDARIES:
                    this.applyEditBoundariesSearch(true, true)
                    break;
                case SEARCH_TYPE.SILENCES:
                    this.applySearchResultsForSilencesToTrim(this.getSearchParams().maxSilenceInMilliseconds, true, true);
                    break;
                default:
                    this.applyTextSearch(this.getSearchParams(), true, true);
                    break;                       
            }
        }).bind(this), TIMEOUT_DELAY)
    },

    onNodeRemovedFromSequencer : function(){
        if(this.isSearchOn()){
            this._triggerSearchRefresh();
        }
    },

    setSearchType : function(searchType){
        this.set("searchType",searchType);
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("sequencer", 'changeSearchType', 'Change search type', null,{searchType});
        }
    },

    getSearchType : function(searchType){
        return this.get("searchType");
    },


    getSequencer : function(){
        return this._sequencer;
    },

    clearSearch : function(){
        this._clearSearch();
        this.setSearchType(SEARCH_TYPE.TEXT_SEARCH);
        this.setSearchOn(false);
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("sequencer", 'clearSearchWidget', 'Clear search widget');
        }
    },

    setSearchOn : function(searchOn){
        if(this._pendingSearchWidgetUpdateHandlerId){
            setTimeout(()=>{
                this.set("searchOn", searchOn);
            },TIMEOUT_DELAY)
        }else{
            this.set("searchOn", searchOn);
        }
    },

    setPlaybackOn : function(playbackOn){
        if(this._pendingSearchWidgetUpdateHandlerId){
            setTimeout(()=>{
                this.set("playbackOn", playbackOn);
            },TIMEOUT_DELAY)
        }else{
            this.set("playbackOn", playbackOn);
        }
    },


    isSearchOn : function(){
        return this.get("searchOn");
    },

    getPlaybackOn : function(){
        return this.get("playbackOn");
    },

    _clearSearch : function(){
        this.getAllArraysOfNodesToHiglights().forEach(function(arrayOfNodes){
            if(arrayOfNodes){
                arrayOfNodes.forEach(function(node){
                    node.setSearchHightlighted(false);
                })
            }
        })
        this.set("nodesInFocus",null);
        this.set("allArraysOfNodesMatchingResults", []);
        this.set("allArraysOfNodesToHiglights", []);
        this._indexOfNodeInFocus = -1;
        this.set("searchParams", null);
        this._cachedSearchResults = null;
    },

    getCachedSearchResults : function(){
        return this._cachedSearchResults;
    },

    /*applySearch : function(searchParams, forceReset){
        if(forceReset || (this._searchParams !== searchParams? searchParams.trim().toLowerCase(): "")){
            this._clearSearch();
            this.setSearchOn(true);
            this._searchParams = searchParams? searchParams.trim().toLowerCase(): "";
            const nodesMatchingResults = [];
            if(this._searchParams){
                const node = this.getSequencer().getFirstNode();
                while(node){
                    if(Utils.getInstance().isWordSequencerNodeInstance(node) && this.getSequencer().isSequencerNodeVisible(node)){
                        const context = node.getAudioSegment().getContent();
                        if(context && context.search(new RegExp(this._searchParams, "i")) !== -1){
                            this._addNodeToArrayOfMatchingNodes(node);
                        }
                    }
                    node = node.getNext();
                }
                this.moveToNextFoundNode();
            }
        }
    },*/
    applyDisfluencySearch : function(disfluencySequencerNodeInfoArray,forceReset, keepCurrentIndexInFocus){
        this.setSearchType(SEARCH_TYPE.DISFLUENCY_SEARCH);
        const sequencerNodesWithDisfluenciesCidToDisfluencyInfo = {};
        this._disfluencySequencerNodeInfoArray =  disfluencySequencerNodeInfoArray;
        disfluencySequencerNodeInfoArray.map((disfluencyInfoArray)=>{
            disfluencyInfoArray.map((disfluencyInfo)=>{
                sequencerNodesWithDisfluenciesCidToDisfluencyInfo[disfluencyInfo.sequencerNode.getCid()] = disfluencyInfo;
            })
        })

        const isMatchingSearchFunction = function(node,currentNodesMatchingResults, searchParams, sequencer, onlyMatchVisible, context){
            const results = {"isMatching": false, "shouldContinueMatching": false};
            if(Utils.getInstance().isWordSequencerNodeInstance(node) && (!onlyMatchVisible || sequencer.isSequencerNodeVisible(node))){
                const disfluencyInfo = sequencerNodesWithDisfluenciesCidToDisfluencyInfo[node.getCid()];
                if(disfluencyInfo){
                    results.isMatching = true;
                    results.shouldContinueMatching = true;//disfluencyInfo.isLinkedWithNextDisfluency;
                    context.isMatching = results.isMatching;
                    context.shouldContinueMatching = results.shouldContinueMatching;
                    context.isLinkedWithNextDisfluency = disfluencyInfo.isLinkedWithNextDisfluency;
                    return results;
                }else{
                    context.isMatching = results.isMatching;
                    context.shouldContinueMatching = results.shouldContinueMatching;
                    context.isLinkedWithNextDisfluency = false;
                    return results;
                }
            }else{
                if(context.isMatching && context.shouldContinueMatching && context.isLinkedWithNextDisfluency){
                    results.isMatching = true;
                    results.shouldContinueMatching = true;
                    context.isMatching = results.isMatching;
                    context.shouldContinueMatching = results.shouldContinueMatching;
                    context.isLinkedWithNextDisfluency = false;
                    return results;
                }else{ 
                    context.isMatching = results.isMatching;
                    context.shouldContinueMatching = results.shouldContinueMatching;
                    context.isLinkedWithNextDisfluency = false;
                    return results;
                }
                context.isMatching = results.isMatching;
                context.shouldContinueMatching = results.shouldContinueMatching;
                context.isLinkedWithNextDisfluency = false;
                return results;
            }
            return results;
        }

        const shouldSearchResultsBeingValidated = function(currentNodesMatchingResults, currentNodesToHighlights, searchParams, sequencer, context){
            return currentNodesToHighlights && currentNodesToHighlights.length > 0;
        }
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("sequencer", 'turnOnDisfluencySearch', 'Turn on disfluency search');
        }
        return this.applySearchGeneric({searchType:"DISFLUENCY"}, isMatchingSearchFunction,shouldSearchResultsBeingValidated, ()=>true, forceReset, keepCurrentIndexInFocus);
    },

    applyTextSearch : function(searchParams, forceReset, keepCurrentIndexInFocus){
        this.setSearchType(SEARCH_TYPE.TEXT_SEARCH);
        const getWordsArrayFromSearchParams = function(search){
            if(!search || !search.trim()){
                return [];
            }
            // Split the sentence into words using spaces as a delimiter
            var words = search.trim().split(/\s+/);

            // Check if the last word has trailing spaces and convert to lowercase
            if (search.endsWith(" ")) {
                words[words.length - 1] = words[words.length - 1].toLowerCase() + " ";
            } else {
                // Convert all words to lowercase
                words = words.map(function (word) {
                return word.toLowerCase();
                });
            }

            return words;
        }

        const isMatchingSearchFunction = function(node,currentNodesMatchingResults, searchParams, sequencer, onlyMatchVisible, context){
            const results = {"isMatching": false, "shouldContinueMatching": false};
            if(searchParams && typeof searchParams === "string" && searchParams.trim().toLowerCase()){
                const arrayOfWords = getWordsArrayFromSearchParams(searchParams);
                if(arrayOfWords.length > currentNodesMatchingResults.length){
                    const wordToMatchAgainst = arrayOfWords[currentNodesMatchingResults.length];
                    if(Utils.getInstance().isWordSequencerNodeInstance(node) && (!onlyMatchVisible || sequencer.isSequencerNodeVisible(node))){
                        const content = node.getAudioSegment().getContent();
                        if(currentNodesMatchingResults.length + 1 === arrayOfWords.length){
                            //Last word
                            results.isMatching = content && content.search(new RegExp(Utils.getInstance().escapeRegExp(wordToMatchAgainst.trim())+(wordToMatchAgainst.endsWith(" ")?"\\b":""), "i")) !== -1;
                            results.shouldContinueMatching = results.isMatching;
                            return results;
                        }else{
                            results.isMatching = content.trim().toLowerCase() === wordToMatchAgainst.trim().toLowerCase();
                            results.shouldContinueMatching = results.isMatching;
                            return results;
                        }
                    }else{
                        results.shouldContinueMatching = currentNodesMatchingResults.length > 0?true: false;
                        return results;
                    }
                }else{
                    return results;
                }
            }
            return results;
        }

        const shouldSearchResultsBeingValidated = function(currentNodesMatchingResults, currentNodesToHighlights, searchParams, sequencer, context){
            const arrayOfWords = getWordsArrayFromSearchParams(searchParams);
            return currentNodesMatchingResults && arrayOfWords &&  currentNodesMatchingResults.length === arrayOfWords.length;
        }

        const isSearchParamsChanged = function(currentSearchParams, newSearchParams){
            return (!currentSearchParams && newSearchParams && newSearchParams.toLowerCase())
            ||(!newSearchParams && currentSearchParams && currentSearchParams.toLowerCase()) 
            ||(currentSearchParams && newSearchParams && newSearchParams.toLowerCase() != currentSearchParams.toLowerCase())
        }

        return this.applySearchGeneric(searchParams, isMatchingSearchFunction,shouldSearchResultsBeingValidated, isSearchParamsChanged, forceReset, keepCurrentIndexInFocus);
    },

    applyFillerWordsSearch : function(forceReset, keepCurrentIndexInFocus){
        this.setSearchType(SEARCH_TYPE.FILLER_WORDS);
        const {isMatchingSearchFunction,shouldSearchResultsBeingValidated,isSearchParamsChanged } = this._getFilleWordSearchParams();
        return this.applySearchGeneric({searchType:"FILLER_WORDS"}, isMatchingSearchFunction, shouldSearchResultsBeingValidated, isSearchParamsChanged, forceReset, keepCurrentIndexInFocus);
    },

    _getFilleWordSearchParams : function(){
        const isMatchingSearchFunction = function(node,currentNodesMatchingResults, searchParams, sequencer, onlyMatchVisible, context){
            const results = {"isMatching": false, "shouldContinueMatching": false};
            if(Utils.getInstance().isWordSequencerNodeInstance(node) && (!onlyMatchVisible || sequencer.isSequencerNodeVisible(node))){
                const content = node.getAudioSegment().getContent();
                if(content && content.trim().toLowerCase()){
                    const regexToTestFillerWords =  new RegExp(`^(${sequencer.getFillerWords().join("|")})+[.,]*$`,"i");
                    results.isMatching = regexToTestFillerWords.test(content.trim().toLowerCase());
                    results.shouldContinueMatching = results.isMatching;
                }
                return results;
            }else{
                results.shouldContinueMatching = false;//currentNodesMatchingResults.length > 0?true: false;
                return results;
            }
            return results;
        }

        const shouldSearchResultsBeingValidated = function(currentNodesMatchingResults, currentNodesToHighlights, searchParams, sequencer, context){
            return currentNodesMatchingResults && currentNodesMatchingResults.length > 0;
        }

        const isSearchParamsChanged = function(currentSearchParams, newSearchParams){
            return true;
        }

        return {isMatchingSearchFunction,shouldSearchResultsBeingValidated,isSearchParamsChanged };
    },

    applyEditBoundariesSearch : function(forceReset, keepCurrentIndexInFocus){
        this.setSearchType(SEARCH_TYPE.EDIT_BOUNDARIES);
        const {isMatchingSearchFunction,shouldSearchResultsBeingValidated,isSearchParamsChanged } = this._getEditBoundariesSearchParams();
        return this.applySearchGeneric({searchType:"EDIT_BOUNDARIES"}, isMatchingSearchFunction, shouldSearchResultsBeingValidated, isSearchParamsChanged, forceReset, keepCurrentIndexInFocus);
    },


    _getEditBoundariesSearchParams : function(){
        const isMatchingSearchFunction = function(node,currentNodesMatchingResults, searchParams, sequencer, onlyMatchVisible, context){
            const results = {"isMatching": false, "shouldContinueMatching": false};
            if(!node.isThereContinuityOnLeft()){
                results.isMatching = true;
                results.shouldContinueMatching = true;
                return results;
            }else{
                results.shouldContinueMatching = false;//currentNodesMatchingResults.length > 0?true: false;
                return results;
            }
            return results;
        }

        const shouldSearchResultsBeingValidated = function(currentNodesMatchingResults, currentNodesToHighlights, searchParams, sequencer, context){
            return currentNodesMatchingResults && currentNodesMatchingResults.length > 0;
        }

        const isSearchParamsChanged = function(currentSearchParams, newSearchParams){
            return true;
        }

        return {isMatchingSearchFunction,shouldSearchResultsBeingValidated,isSearchParamsChanged };
    },

    getSearhResultsForFillerWords : function(){
        const {isMatchingSearchFunction,shouldSearchResultsBeingValidated,isSearchParamsChanged } = this._getFilleWordSearchParams();
        return this.getSearchResultsGeneric(null, isMatchingSearchFunction, shouldSearchResultsBeingValidated, isSearchParamsChanged, false, null, null);
    },

    _getSearchResultsForSilencesToTrimParams : function(maxSilenceInMilliseconds, doubleVerifyWithAudioClassification){
        const isMatchingSearchFunction = function(node,currentNodesMatchingResults, searchParams, sequencer, onlyMatchVisible, context){
            const results = {"isMatching": false, "shouldContinueMatching": false};
            if(Utils.getInstance().isPauseSequencerNodeInstance(node) && (!onlyMatchVisible || sequencer.isSequencerNodeVisible(node)) && (!doubleVerifyWithAudioClassification || /*SequencerNodeUtils.getInstance().doesSequencerNodeOnlyContainsBackgroundNoise(node)*/ !SequencerNodeUtils.getInstance().doesSequenerNodeContainsVoiceActivity(node))){
                const duration = node.getAudioSegment().getDuration();
                if(!context.lastCountedDuration){
                    context.lastCountedDuration = 0;
                }
                
                context.lastCountedDuration = context.lastCountedDuration + duration;
                const countedDuration = context.lastCountedDuration;
                if(countedDuration > maxSilenceInMilliseconds){
                    results.isMatching = true;
                    results.shouldContinueMatching = true;
                }
                return results;
            }else{
                if(context.lastCountedDuration > 0){
                    if(Utils.getInstance().isPauseSequencerNodeInstance(node) 
                        || Utils.getInstance().isStartMusicWrapSequencerNodeInstance(node)
                        || Utils.getInstance().isEndMusicWrapSequencerNodeInstance(node)
                        || Utils.getInstance().isAudioSequencerNodeInstance(node)
                        || Utils.getInstance().isVideoSequencerNodeInstance(node)
                        || Utils.getInstance().isWordSequencerNodeInstance(node)
                        || Utils.getInstance().isWordSequencerNodeInstance(node)
                        || Utils.getInstance().isUnsupportedAudioSequencerNodeInstance(node)
                         || (node.getAudioSegment() && node.getAudioSegment().getDuration() > 0)){
                        context.lastCountedDuration =  0;
                    }else{
                        // keep the lastCountedDuration the same
                        if(context.lastCountedDuration > maxSilenceInMilliseconds){
                            results.isMatching = true;
                            results.shouldContinueMatching = true;
                        }
                    }
                    return results;
                }else{
                    context.lastCountedDuration =  0;
                    return results;
                }
                
            }
            return results;
        }

        const shouldSearchResultsBeingValidated = function(currentNodesMatchingResults, currentNodesToHighlights, searchParams, sequencer, context){
            return currentNodesMatchingResults && currentNodesMatchingResults.length > 0;
        }

        const isSearchParamsChanged = function(currentSearchParams, newSearchParams){
            return true;
        }
        return {isMatchingSearchFunction,shouldSearchResultsBeingValidated,isSearchParamsChanged };

    },

    applySearchResultsForSilencesToTrim : function(maxSilenceInMilliseconds,forceReset, keepCurrentIndexInFocus){
        this.setSearchType(SEARCH_TYPE.SILENCES);
        const {isMatchingSearchFunction,shouldSearchResultsBeingValidated,isSearchParamsChanged } = this._getSearchResultsForSilencesToTrimParams(maxSilenceInMilliseconds, true);
        return this.applySearchGeneric({searchType:"SILENCES", maxSilenceInMilliseconds}, isMatchingSearchFunction, shouldSearchResultsBeingValidated, isSearchParamsChanged,forceReset, keepCurrentIndexInFocus);
    },

    getSearchResultsForSilencesToTrim : function(maxSilenceInMilliseconds){
        const {isMatchingSearchFunction,shouldSearchResultsBeingValidated,isSearchParamsChanged } = this._getSearchResultsForSilencesToTrimParams(maxSilenceInMilliseconds, true);
        return this.getSearchResultsGeneric(null, isMatchingSearchFunction, shouldSearchResultsBeingValidated, isSearchParamsChanged, false, null, null);
    },

    getSearchResultsGeneric : function(searchParams, isMatchingSearchFunction, shouldSearchResultsBeingValidated, isSearchParamsChanged, onlyMatchVisible, firstNode, lastNode){
        const allArraysOfNodesMatchingResults = [];
        const allArraysOfNodesToHiglights = [];
        let currentNodesMatchingResults = [];
        let currentNodesToHighlights = [];
        const context = {};
        let results = {};
        
        let node = firstNode? firstNode: this.getSequencer().getFirstNode();
        while(node){
            results = isMatchingSearchFunction(node,currentNodesMatchingResults, searchParams, this.getSequencer(),onlyMatchVisible,  context);
            if(results.isMatching){
                currentNodesMatchingResults.push(node);
            }
            if(results.shouldContinueMatching){
                currentNodesToHighlights.push(node);
            }
            if(!results.isMatching && !results.shouldContinueMatching){
                if(currentNodesMatchingResults.length > 0){
                    if(shouldSearchResultsBeingValidated(currentNodesMatchingResults, currentNodesToHighlights, searchParams, this.getSequencer(), context)){
                        allArraysOfNodesMatchingResults.push(currentNodesMatchingResults);
                        allArraysOfNodesToHiglights.push(currentNodesToHighlights);
                    }	
                }
                currentNodesMatchingResults = [];
                currentNodesToHighlights = [];
            }
            if(lastNode && node === lastNode){
                node = null;
            }else{
                node = node.getNext();
            }
        }

        if(currentNodesMatchingResults.length > 0){
            if(shouldSearchResultsBeingValidated(currentNodesMatchingResults, currentNodesToHighlights, searchParams, this.getSequencer(), context)){
                allArraysOfNodesMatchingResults.push(currentNodesMatchingResults);
                allArraysOfNodesToHiglights.push(currentNodesToHighlights);
            }	
        }
        
        
        return {"allArraysOfNodesMatchingResults": allArraysOfNodesMatchingResults,"allArraysOfNodesToHiglights": allArraysOfNodesToHiglights };
        
    },

    applySearchGeneric : function(searchParams, isMatchingSearchFunction, shouldSearchResultsBeingValidated, isSearchParamsChanged, forceReset, keepCurrentIndexInFocus){
        if(forceReset || isSearchParamsChanged(this.getSearchParams(), searchParams)){
            const currentIndexOfNodeInFocus = this.getIndexOfNodeInFocus();
            this._clearSearch();
            this.set("searchParams", searchParams);
            this.setSearchOn(true);
            if(this.getSearchParams()){
                const searchResults =  this.getSearchResultsGeneric(searchParams, isMatchingSearchFunction, shouldSearchResultsBeingValidated, isSearchParamsChanged, null, null);
                const allArraysOfNodesMatchingResults = searchResults.allArraysOfNodesMatchingResults;
                const allArraysOfNodesToHiglights = searchResults.allArraysOfNodesToHiglights;
                allArraysOfNodesToHiglights.forEach((function(currentNodesToHighlights){
                    currentNodesToHighlights.forEach(function(nodeToHighlight){
                        nodeToHighlight.setSearchHightlighted(true);
                    })
                }).bind(this));
                this.set("allArraysOfNodesMatchingResults", allArraysOfNodesMatchingResults);
                this.set("allArraysOfNodesToHiglights", allArraysOfNodesToHiglights);
                this.moveToNextFoundNode(keepCurrentIndexInFocus, currentIndexOfNodeInFocus);
                this._cachedSearchResults = searchResults;
                return searchResults;
            }
        }
    },

    getSearchResultsForDisfluencies : function(){

    },


    getAllArraysOfNodesMatchingResults: function(){
        return this.get("allArraysOfNodesMatchingResults");
    },

    getAllArraysOfNodesToHiglights: function(){
        return this.get("allArraysOfNodesToHiglights");
    },


    moveToNextFoundNode : function(forceIndexOfNodeInFocus, newIndeOfNodeInFocus){
        if(this.isSearchOn()){
            if(forceIndexOfNodeInFocus && newIndeOfNodeInFocus < this.getAllArraysOfNodesToHiglights().length){
                if(newIndeOfNodeInFocus < 0){
                    newIndeOfNodeInFocus =  0;
                }
                this._indexOfNodeInFocus = newIndeOfNodeInFocus;
                this.set("nodesInFocus", this.getAllArraysOfNodesToHiglights()[this._indexOfNodeInFocus]);
            }else{
                if(this._indexOfNodeInFocus + 1 < this.getAllArraysOfNodesToHiglights().length){
                    this._indexOfNodeInFocus = this._indexOfNodeInFocus + 1;
                    this.set("nodesInFocus", this.getAllArraysOfNodesToHiglights()[this._indexOfNodeInFocus]);
                }else{
                    if(this.getAllArraysOfNodesToHiglights().length == 0){
                        this.set("nodesInFocus", null);
                    }
                }
            }
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("sequencer", 'moveToNextSearchFound', 'Move to next search found');
            }
        }
    },

    /*getSequencerNodeCurrentlyInFocus : function(){
        if(this.isSearchOn()){
            return this.get("nodeInFocus")
        }else{
            return null;
        }
    },*/

    getSequencerNodesCurrentlyInFocus : function(){
        if(this.isSearchOn()){
            return this.get("nodesInFocus")
        }else{
            return null;
        }
    },

    /*getIndexOfNodeInFocus : function(){
        return this._indexOfNodeInFocus;
    },*/

    moveToPreviousFoundNode : function(){
        if(this.isSearchOn()){
            if(this._indexOfNodeInFocus - 1  >= 0){
                this._indexOfNodeInFocus = this._indexOfNodeInFocus - 1;
                this.set("nodesInFocus", this.getAllArraysOfNodesToHiglights()[this._indexOfNodeInFocus]);
            }else{
                if(this.getAllArraysOfNodesToHiglights().length == 0){
                    this.set("nodesInFocus", null);
                }
            }
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("sequencer", 'moveToPreviousSearchFound', 'Move to previous search found');
            }
        }
    },

    /*_addNodeToArrayOfMatchingNodes : function(matchingNodeToAdd){
        if(matchingNodeToAdd){
            matchingNodeToAdd.setSearchHightlighted(true);
            this._allArraysOfNodesMatchingResults.push(matchingNodeToAdd);
        }
    },*/

    getNumberOfSearchNodesFound: function(){
        return this.getAllArraysOfNodesMatchingResults().length;
    },

    getIndexOfNodeInFocus: function(){
        return this.getNumberOfSearchNodesFound() > 0? this._indexOfNodeInFocus: -1;
    },

    canMoveToNextResult: function(){
        return this.getNumberOfSearchNodesFound() > 0 &&  this.getIndexOfNodeInFocus() + 1 < this.getNumberOfSearchNodesFound();
    },

    canMoveToPreviousResult: function(){
        return this.getNumberOfSearchNodesFound() > 0 &&  this.getIndexOfNodeInFocus() > 0;
    },

});

SequencerSearchWidget.SEARCH_TYPE = SEARCH_TYPE;

export default SequencerSearchWidget; 