import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import SongOptionView from "views/myLibrary/SongOptionView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import AudioRecorderHelper from "models/helper/AudioRecorderHelper";
import ti18n from "i18n!nls/MyLibraryi18n";
import TrebbleIndexDBHelper from "models/helper/TrebbleIndexDBHelper";
import RSVP from "rsvp";

const SongOptionController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.model = options? options.songModel : null;
        this.songRefSummary = options? options.songRefSummary : null;
        this.context = options? options.context : null;
        this._onActionExecutedOnSongHandler  = options? options.onActionExecutedOnSongHandler: null;
        _.extend(this, Backbone.Events);
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    addToQueue : function(){
        PlayerService.getInstance().addSongsToQueue(this.model);
        this._notifyListener("addToQueue");
    },
    
    _notifyListener : function(action, customData){
        if(this._onActionExecutedOnSongHandler){
            this._onActionExecutedOnSongHandler(this.model, action, customData);
        }
    },
    
    playFromYoubube : function(){
        PlayerService.getInstance().playFromYoubube(this.model);
        this._notifyListener("playFromYoubube");
    },
    
    playSimilarFromLibrary : function(){

        $.mobile.loading( 'show');
        const isMusicCurrentlyPlaying =  PlayerService.getInstance().isMusicPlaying();
        PlayerService.getInstance().generateSimilarLocalSongPlaylistAndAddToQueue(this.model).then((function(){
            if(!isMusicCurrentlyPlaying)
            {
                PlayerService.getInstance().playFirstSongInQueue();
            }
            this._notifyListener("playSimilarFromLibrary");
             $.mobile.loading( "hide" );
        }).bind(this)).catch(function(error){
            window.alertErrorMessage(error);
             $.mobile.loading( "hide" );
        });

    },
    
    playSimilar : function(){

        $.mobile.loading( 'show');
        const isMusicCurrentlyPlaying =  PlayerService.getInstance().isMusicPlaying();
        PlayerService.getInstance().generateSimilarSongPlaylistAndAddToQueue(this.model).then((function(){
            if(!isMusicCurrentlyPlaying)
            {
                PlayerService.getInstance().playFirstSongInQueue();
            }
            this._notifyListener("playSimilar");
             $.mobile.loading( "hide" );
        }).bind(this)).catch(function(error){
            window.alertErrorMessage(error);
             $.mobile.loading( "hide" );
        });

    },
    
    
    playSimilarFromOtherUserLibraries : function(){

        $.mobile.loading( 'show');
        const isMusicCurrentlyPlaying =  PlayerService.getInstance().isMusicPlaying();
        PlayerService.getInstance().generateSimilarSongPlaylistFromOtherUsersAndAddToQueue(this.model, true).then((function(){
            if(!isMusicCurrentlyPlaying)
            {
                PlayerService.getInstance().playFirstSongInQueue();
            }
            this._notifyListener("playSimilarFromOtherUserLibraries");
             $.mobile.loading( "hide" );
        }).bind(this)).catch(function(error){
            window.alertErrorMessage(error);
             $.mobile.loading( "hide" );
        });

    },
    
    
    
    
    browseAlbum : function(){
        PersistentModels.getInstance().getRouter().showAlbumDetailPage(this.model.get("albumJson").albumId, this.model.get("albumJson"), true);
        this._notifyListener("browseAlbum");
    },
    
    browseArtist : function(){
        PersistentModels.getInstance().getRouter().showArtistFullDetailPage(this.model.get("albumJson").artistJson.artistId,this.model.get("albumJson").artistJson.artistName, this.model.get("albumJson").artistJson, true);
        this._notifyListener("browseArtist");
    },
    
    showSongComment : function(){
        PersistentModels.getInstance().getRouter().showCommentsForSong(this.model,false,null, this.context, this.songRefSummary);
        this._notifyListener("showSongComment");
    },
    
    rate : function(){
        PersistentModels.getInstance().getRouter().showSongTagManagerPopup(this.model, this.context);
        this._notifyListener("rate");
    },	

    
    addToRadio : function(){
        return PersistentModels.getInstance().addSongToDefaultRadio(this.model, this.context, this.songRefSummary).then((function(){
            this._notifyListener("addToRadio");
        }).bind(this));
    },
    
    
    removeFromRadio : function(){
        return PersistentModels.getInstance().removeSongFromDefaultRadio(this.model, this.context, this.songRefSummary).then((function(){
            this._notifyListener("removeFromRadio");
        }).bind(this));
    },
    
    deleteSongConfirm : function(){
        const buttonLabels = ["No", "Yes"];
        const confirmCallback = (function(buttonIndex){
            if(buttonIndex ==  2){
                this.deleteSong();
            }
        }).bind(this);
        const message = window.getI18n(ti18n,"ARE_YOU_SURE_YOU_TO_DELETE_THIS_SONG_FROM_YOUR_LIBRARY")
        navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n,"DELETE_SONG"), buttonLabels,null , null,window.getI18n(ti18n,"ALL_CAPSULES_AND_COMMENTS_WILL_BE_DELETED_AS_WELL"));
    } ,
    
    
    deleteSong : function(){
        const songTitle = this.model.get("title");
        return this.removeFromRadio().then((function(){
            return TrebbleIndexDBHelper.getInstance().deleteSong(this.model.toJSON());
        }).bind(this)).then((function(){
            PersistentModels.getInstance().removeSongFromSongCollectionAndFavoriteCollection(this.model);
            Utils.getInstance().showLongToastMessage("\""+ songTitle+"\" "+ window.getI18n(ti18n,"WAS_SUCCESSFULLY_DELETED"));
            this._notifyListener("delete");
        }).bind(this)).catch((function(error){
            Utils.getInstance().showWarningNotification(window.getI18n(ti18n,"AN_ERROR_OCCURED_WHILE_DELETING")+" \""+ songTitle +"\". "+window.getI18n(ti18n,"ERROR") + error);
        }).bind(this));
    },
    
    showCapsules : function(){
        return PersistentModels.getInstance().getRouter().showCapsulesForSong(this.model, this.context, this.songRefSummary);
    },

    createCapsule: function(){

        if(window.device && window.device.platform =="browser"){
            AudioRecorderHelper.getInstance().initUserMedia().then((function(allowed){
                if(allowed){
                    return PersistentModels.getInstance().getRouter().showAddAudioHightlightPageNew(this.model,null, this.context, null,true, true);
                }else{
                    return PersistentModels.getInstance().getRouter().showAddAudioHightlightPageNew(this.model,null, this.context, null,false, true);
                }
            }).bind(this)).catch((function(e){
                window.navigator.trebbleNotification.alert(e, null, null, "warning"); 
                return PersistentModels.getInstance().getRouter().showAddAudioHightlightPageNew(this.model,null, this.context, null,false, true);
            }).bind(this));

        }else{
            return PersistentModels.getInstance().getRouter().showAddAudioHightlightPageNew(this.model,null, this.context, null,true, true);
        }
        
    },
    

    shareSong : function(){
        PersistentModels.getInstance().getRouter().showSimpleSetSongSharePopup(this.model, this.context);
        this._notifyListener("shareSong");
    },

    showStatistics : function(){
        PersistentModels.getInstance().getRouter().showSongStatisticsPage(this.model, this.context);
        this._notifyListener("showStatistics");
    },

    onViewRemoved:function(){
        this.stopListening();
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                const songModelInUserCollection =  PersistentModels.getInstance().getSongModelBySongIdFromMyLibraryOrFavorites(this.model.get("uri"));
                const isInUserCollection = !!songModelInUserCollection;
                this.view = new SongOptionView({
                    "model" : this.model,
                    "isInUserCollection" :isInUserCollection
                });
                this.listenTo(this.view, "addToQueue",this.addToQueue);
                this.listenTo(this.view, "playSimilarFromLibrary",this.playSimilarFromLibrary);
                this.listenTo(this.view, "playSimilar",this.playSimilar);
                this.listenTo(this.view, "playSimilarFromFollowers",this.playSimilarFromOtherUserLibraries);
                this.listenTo(this.view, "browseAlbum",this.browseAlbum);
                this.listenTo(this.view, "browseArtist",this.browseArtist);
                this.listenTo(this.view, "playFromYoubube",this.playFromYoubube);
                this.listenTo(this.view, "addToRadio",this.addToRadio);
                this.listenTo(this.view, "removeFromRadio",this.removeFromRadio);
                this.listenTo(this.view, "deleteSong",this.deleteSongConfirm);
                this.listenTo(this.view, "commentOnSong",this.showSongComment);
                this.listenTo(this.view, "rate",this.rate);
                this.listenTo(this.view, "showCapsules", this.showCapsules);
                this.listenTo(this.view, "createCapsule", this.createCapsule);
                this.listenTo(this.view, "share", this.shareSong);
                this.listenTo(this.view, "showStatistics", this.showStatistics);
                this.listenTo(this.view, "remove", this.onViewRemoved.bind(this));
                
                this.view.render();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default SongOptionController;