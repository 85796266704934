import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utils from "models/helper/Utils";
import PersistentModels from "services/PersistentModels";
import OperationProgressPageView from "views/common/OperationProgressPageView";
import OperationProgressPopupView from "views/common/OperationProgressPopupView";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import PurchaseManager from "models/helper/PurchaseManager";
import FeatureRolloutHelper from "models/helper/FeatureRolloutHelper";
import ti18n from "i18n!nls/PurchaseJourneyPagei18n";
import RSVP from "rsvp";

const Rollout = FeatureRolloutHelper.getInstance();

const SHOW_DIALOG_SUCCESS_WHEN_POPUP_DISPLAYED_DURING_ORDER = Rollout.getFeatureVariable(Rollout.FEATURES.JOURNEY_PURCHASE_CONFIG, Rollout.FEATURES.JOURNEY_PURCHASE_CONFIG.variables.show_success_popup_when_order_is_displayed_during_order, true);


const PurchaseJourneyPageController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this._journeyRadioId = options.journeyRadioId;
        this._displayAsPopup =  options.displayAsPopup;
        this._onJourneyPurchaseSuccessHandler = options?options.onJourneyPurchaseSuccessHandler : null;
        this._onJourneyPurchaseFailureHandler = options?options.onJourneyPurchaseFailureHandler : null;
        //this._showThankYouMessage =  options? options.showThankYouMessage : false;
        
        this.listenTo(PurchaseManager.getInstance(), PurchaseManager.getInstance().eventTypes.JOURNEY_PURCHASE_COMPLETED, this._onJourneyPurchaseCompletedOrInterrupted);
        this.listenTo(PurchaseManager.getInstance(), PurchaseManager.getInstance().eventTypes.JOURNEY_PURCHASE_INTERUPTED, this._onJourneyPurchaseCompletedOrInterrupted);
        this.listenTo(PurchaseManager.getInstance(), PurchaseManager.getInstance().eventTypes.PURCHASE_OF_JOURNEY_COMPLETED_AND_PAID, this._onJourneyPurchaseCompletedOrInterrupted);
        this.listenTo(PurchaseManager.getInstance(), PurchaseManager.getInstance().eventTypes.PURCHASE_OF_JOURNEY_FULLFILLED, this._onJourneyPurchaseCompletedOrInterrupted);
        this.listenTo(PurchaseManager.getInstance(), PurchaseManager.getInstance().eventTypes.PURCHASE_OF_JOURNEY_PAYMENT_FAILED, this._onJourneyPurchaseCompletedOrInterrupted);
        this.listenTo(PurchaseManager.getInstance(), PurchaseManager.getInstance().eventTypes.JOURNEY_PURCHASE_BEING_FULLFILLED, this._onJourneyPurchaseBeingFullfilled);

    },

    getPageId : function(){
        return "OperationProgressPage";
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },
    

    _onJourneyPurchaseCompletedOrInterrupted : function(journeyRadioId){
        if(this.model.getLoaderKey() == journeyRadioId){
            this.updateDialog();
        }
    },

    _onJourneyPurchaseBeingFullfilled : function(journeyRadioId){
        if(this.model.getLoaderKey() == journeyRadioId){
            this.view.setLoadingMessage(Rollout.getFeatureVariable(Rollout.FEATURES.JOURNEY_PURCHASE_CONFIG, "label_completing_your_order_"+window.getAppUsedLanguage(),window.getI18n(ti18n, "COMPLETING_YOUR_ORDER")));
        }
    },
    
    updateDialog : function(){
        return TrebbleClientAPIHelper.getInstance().hasPurchasedJourney(this.model.getLoaderKey()).then((function(hasPurchased){
            if(hasPurchased){
                const headerMessage =  Rollout.getFeatureVariable(Rollout.FEATURES.JOURNEY_PURCHASE_CONFIG, "label_thank_you_for_purchasing_"+window.getAppUsedLanguage(),window.getI18n(ti18n, "THANKS_FOR_PURCHASING")) + " '"+this.model.getName()+"'" ;
                const subHeaderMessage = Rollout.getFeatureVariable(Rollout.FEATURES.JOURNEY_PURCHASE_CONFIG, "label_thank_you_for_purchasing_submessage_"+window.getAppUsedLanguage(),"");
                this.showSuccessMessage(headerMessage, subHeaderMessage);
                if(this._onTrebbleSubscriptionSucessHandler){
                    this._onTrebbleSubscriptionSucessHandler();
                }
            }else{
                const orderWasNotCompletedMessage = window.getI18n(ti18n, "THE_ORDER_FOR")+" '"+this.model.getName()+"' "+window.getI18n(ti18n, "WAS_NOT_COMPLETED");
                this.view.setErrorMessage(orderWasNotCompletedMessage);
                if(this._onTrebbleSubscriptionFailureHandler){
                    this._onTrebbleSubscriptionFailureHandler(orderWasNotCompletedMessage);
                }
            }

        }).bind(this)).catch((function(error){
            const anErrorOccuredMessage = window.getI18n(ti18n, "AN_ERROR_OCCURED_WHEN_TRYING_TO_ORDER");
            this.view.setErrorMessage(anErrorOccuredMessage);
            if(this._onTrebbleSubscriptionFailureHandler){
                this._onTrebbleSubscriptionFailureHandler(anErrorOccuredMessage);
            }
        }).bind(this))

    },

    showSuccessMessage : function(headerMessage, subHeaderMessage){
        if(this._displayAsPopup && SHOW_DIALOG_SUCCESS_WHEN_POPUP_DISPLAYED_DURING_ORDER){
            this.view.close();
            PersistentModels.getInstance().getRouter().showThankYouMessageForPurchasingJourneyPopup(this.model);
        }else{
            this.view.setSuccessMessage(headerMessage, subHeaderMessage);
        }
    },


    initiateJourneyPurchase : function(){
        this.view.setLoadingMessage(window.getI18n(ti18n, "RETRIEVING_JOURNEY_INFORMATION"));
        return TrebbleClientAPIHelper.getInstance().getJourneyInfoByJourneyRadioId(this._journeyRadioId).then((function(radioJsonInfo){
            if(radioJsonInfo){
                this.view.setLoadingMessage(Rollout.getFeatureVariable(Rollout.FEATURES.JOURNEY_PURCHASE_CONFIG, "label_waiting_for_your_order_to_complete_"+window.getAppUsedLanguage(),window.getI18n(ti18n, "WATITING_FOR_YOUR_ORDER")));
                const journeySummaryModel = Utils.getInstance().buildJourneySummaryFromJson(radioJsonInfo);
                this.model = journeySummaryModel;
                return PurchaseManager.getInstance().initiateJourneyPurchase(journeySummaryModel);
            }else{
                this.view.setErrorMessage(window.getI18n(ti18n, "WE_COULDNT_FIND_THE_SPECIFIED_JOURNEY"));
                if(this._onTrebbleSubscriptionFailureHandler){
                    this._onTrebbleSubscriptionFailureHandler(window.getI18n(ti18n, "WE_COULDNT_FIND_THE_SPECIFIED_JOURNEY"));
                }
            }
        }).bind(this));

    },

    onViewRemoved:function(){
        this.stopListening();
    },


    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                if(this._displayAsPopup){
                    this.view = new OperationProgressPopupView();
                }else{
                    this.view = new OperationProgressPageView();
                }
                this.listenTo(this.view, "remove", this.onViewRemoved.bind(this));
                this.listenTo(this.view, "onPopupClosed", this.onViewRemoved.bind(this));
                this.view.render();
                this.initiateJourneyPurchase();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default PurchaseJourneyPageController;