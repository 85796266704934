import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import BasicInspectorNodeParamView from "views/audioEditor/sequencerNodeInspectorSection/paramView/BasicInspectorNodeParamView";
import CustomObjectParamTemplate from 'text!../../../../../templates/audioEditor/sequencerNodeInspectorSection/CustomObjectParamTemplate.html';
import RSVP from 'rsvp';


const CustomObjectParamView = BasicInspectorNodeParamView.extend({

    constructor : function(attributes, options) {
        BasicInspectorNodeParamView.apply(this, [attributes, options]);
        this.compiledTemlate =  _.template(CustomObjectParamTemplate);
        this._onValueEditOperationRequested = attributes.onValueEditOperationRequested;
        this._getDisplayedStringFromValueFunction = attributes.getDisplayedStringFromValueFunction;
    },	

    events: {
        "click .edit_btn":"_onEditButtonClicked",
    },	

    isDisabled: function(){
        return this._disabled;
    },

    setDisabled : function(disabled){
        this._disabled = disabled;
        if(this.valueContainer$el){
            if(!this._disabled){
                this.valueContainer$el.removeAttr("disabled");
            }else{
                this.valueContainer$el.attr("disabled", !!disabled);
            }
        }
    },

    getValueString: function(){
        if(this._getDisplayedStringFromValueFunction){
            return this._getDisplayedStringFromValueFunction(this.getValue());
        }
        return this.getValue();
    },

    
    updateViewOnValueChange : function(newValue){
        if(this.customObjStringValue$el){
            const stringValue = this._getDisplayedStringFromValueFunction? this._getDisplayedStringFromValueFunction(newValue): newValue;
            this.customObjStringValue$el.html(stringValue);
        }
    },

    _onEditButtonClicked : function(){
        if(!this._disabled && this._onValueEditOperationRequested){
            const onValueUpdateSuccessfullyChanged = (function(newValue){
                this.updateViewOnValueChange(newValue);
                this.onViewValueChanged(newValue);
            }).bind(this)

            const onValueUpdateCancelled = (function(){
                
            }).bind(this)

            this._onValueEditOperationRequested(this.getValue(), onValueUpdateSuccessfullyChanged, onValueUpdateCancelled);

        }
    },



    render : function(){
        this.$el.html(this.compiledTemlate({ "viewObj": this}));
        this.setElement(this.$el.find(".basic_inpector_param_view").first());
        this.customObjStringValue$el = this.$el.find(".custom_obj_string_value");
        this.valueContainer$el = this.$el.find(".value_container");
        this.updateViewOnValueChange(this.getValue());
    }
});
export default CustomObjectParamView;