import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utils from "models/helper/Utils";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import ti18n from "i18n!nls/RecordAudioHightlightPagei18n";
import EditShortcastInfoPageView from "views/capsule/EditShortcastInfoPageView";


const EditShortcastInfoPageController =  Chaplin.Controller.extend({


    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = null;
        this._pageId = options.pageId;
        this._shortcastId = options.shortcastId;
        this._onShortcastInfoChanged =  options.onShortcastInfoChanged;
        
    },
    
    getModel : function(){
        return this.model;
    },
    
    getView : function(){
        return this.view;
    },

    validatTitle : function(title){
        let errorMessage =  null;
        if(!title){
            errorMessage  = window.getI18n(ti18n, "TITLE_CANNOT_BE_EMPTY");
        }
        const isValid = !errorMessage;
        this.view.setTitleValid(isValid, errorMessage);
        return isValid;
    },


    
    saveChanges : function(dataToChange){
        $.mobile.loading("show");
        return TrebbleClientAPIHelper.getInstance().updateCapsuleTitleAndNotes(this._shortcastId, dataToChange.title, dataToChange.notes).then((function(operationResult){
            $.mobile.loading("hide");
            if(operationResult.capsuleWasUpdated && operationResult.updateCapsuleJson){
                const updatedShortcastInfo  = operationResult.updateCapsuleJson;
                PersistentModels.getInstance().onShortcastInfoUpdated(this._shortcastId, updatedShortcastInfo);
                if(this._onShortcastInfoChanged){ 
                    this._onShortcastInfoChanged(updatedShortcastInfo);
                }
                this.view.navigateToPreviousPage();
                window.alertSuccessMessage(window.getI18n(ti18n, "YOUR_SHORTCSAT_INFO_WAS_UPDATED"));

            }else{
                throw window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHEN_SAVING_YOUR_CHANGES");
            }
        }).bind(this)).catch((function(error){
            $.mobile.loading("hide");
            this.view.setSaveButtonEnabled(true);
            const errorMessage = error? error:window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHEN_SAVING_YOUR_CHANGES");
            window.alertErrorMessage(errorMessage);
        }).bind(this));
    },

    validateForm : function(){
        const formData = this.view.getFormData();
        this.view.setSaveButtonEnabled(false);
        this.view.setFormBeingValidated(true);
        const promiseHash = {};

        promiseHash["validatTitle"] = this.validatTitle(formData.title);
        return RSVP.hash(promiseHash).then((function(results){
            const formValidation = results.validatTitle;
            this.view.setFormBeingValidated(false);
            this.view.setSaveButtonEnabled(formValidation);
            return formValidation;
        }).bind(this)).catch(function(error){
            this.view.setFormBeingValidated(false);
            this.view.setSaveButtonEnabled(false);
            window.alertErrorMessage(error? error:window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHEN_SAVING_YOUR_CHANGES"));
        });


    },

    _retrieveShortcastInfo : function(){
        return TrebbleClientAPIHelper.getInstance().getCapsuleInfoByCapsuleId(this._shortcastId);
    },


    buildModelAndRenderView : function() {

        return this._retrieveShortcastInfo().then((function(shortcastInfoJson){
            const pageViewParams= {};
            pageViewParams.pageId = this._pageId;
            pageViewParams.shortcastInfoJson = shortcastInfoJson;
            this.model = Utils.getInstance().getModelFromCapsuleJson(pageViewParams.shortcastInfoJson);
            this.view = new EditShortcastInfoPageView(pageViewParams);
            this.view.render();
            this.listenTo(this.view, "saveChanges", this.saveChanges);
            this.listenTo(this.view, "validateForm", this.validateForm);
        }).bind(this))


    }


});
export default EditShortcastInfoPageController;