import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import YoutubeSearchHelper from "models/helper/YoutubeSearchHelper";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import CordovaHelper from "models/helper/CordovaHelper";
import Utils from "models/helper/Utils";
import ti18n from "i18n!nls/helperi18n";
import RSVP from "rsvp";

const mediaStatuses = {
    MEDIA_NONE : 0,
    MEDIA_STARTING : 1,
    MEDIA_RUNNING :2,
    MEDIA_PAUSED : 3,
    MEDIA_STOPPED : 4,
};


const YOUTUBE_SONG_URI_PREFIX = "youtube:"

let fixedVideoEl  = null;
const useOneVideEl = true;
let lastYoutubeBrowserMedia = null;
const is_safari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
const useNoCookieUrl = false;//(is_safari && window.isMobileBrowser) || true;

const globalOnVideoReadyToPlay = function(){
    if(lastYoutubeBrowserMedia){
        lastYoutubeBrowserMedia._onVideoReadyToPlay();
    }
};
const globalOnPlayerStateChange = function(e){
    if(lastYoutubeBrowserMedia){
        lastYoutubeBrowserMedia._onPlayerStateChange(e);
    }
};
const globalOnError = function(err){
    if(lastYoutubeBrowserMedia){
        lastYoutubeBrowserMedia._onError(err);
    }
};

let mobileQuirkExecutedForMobileVideo = false;

const YoutubeBrowserMedia =  Backbone.Model.extend({
    "default": {
        "DEFAULT_MEDIA_STATUS" :mediaStatuses,
        "src": "",
        "mediaObjectCreatedSuccess":"",
        "mediaObjectCreatedFailure":"",
        "soundObject" :"",
        "mediaSuccess": "",
        "mediaError": "",
        "mediaStatus": "",
    },

    initialize : function(options, onSucces, onError, onStatus) {
        this._loading = true;
        this.trigger("loading");
        this.youtubeDirectVideoUrl = options.directURL;
        this.videoPlaceHolderEl = options.videoPlaceHolderEl;
        this.songModel = options.songModel;
        this.emptyPlaceHolder = options.emptyPlaceHolder;
        this.onSuccessHandler = options.onSuccess;
        this.onErrorHandler = options.onError;
        this._mediaFunctionHandlerContext = options.handlerContext;
        this.uriBroken = options.uriBroken;
        this.onStatusHandler = options.onStatus;
        this.onFailedLoadingHandler = options.onFailedLoading;
        if(window._currentPlayingYoutubeMedia){
            window._currentPlayingYoutubeMedia.release();
        }
        window._currentPlayingYoutubeMedia = this;
        this._createVideoElement();
        lastYoutubeBrowserMedia = this;
        if(useOneVideEl && fixedVideoEl){
            this.videoEl = fixedVideoEl;
        }
        
    },
    
    isLoading : function(){
        return this._loading;
    },
    
    getLoadingError : function(){
        return this._loadingError;
    },
    
    
    _createVideoElement : function(){
        if(!useOneVideEl){
            if(this.videoPlaceHolderEl && this.videoPlaceHolderEl.childNodes && this.videoPlaceHolderEl.childNodes.length > 0){
                //Remove all video elements in document
                for(let i =  0; i< this.videoPlaceHolderEl.childNodes.length; i++)
                {
                    const oldIframe = this.videoPlaceHolderEl.childNodes[i];
                    if(oldIframe.clearVideo){
                        oldIframe.clearVideo();
                        window.log("Current video element being cleared");
                    }
                    this.videoPlaceHolderEl.removeChild(oldIframe);
                    
                }
            }
        }	
        this.videoEl = (this.videoPlaceHolderEl && this.videoPlaceHolderEl.childNodes && this.videoPlaceHolderEl.childNodes.length >0) ? this.videoPlaceHolderEl.childNodes[0]:null;
        
        this.className = "musicVideo";
        
        window.log("about to start searching for videos");
        this._searchAndLoadVideoPromise = this._searchYoutubeVideoUrlAndLoadVideo();
        
        this._playerReadyPromise = new RSVP.Promise((function(resolve, reject){
            this._playerReadyPromiseSuccessFunc = resolve;
            this._playerReadyPromiseFailureFunc = reject;
        }).bind(this))
        
        
        
    },
    
    _initYTPlayer :function(videoId){
        if(!this._isReleased){
            if(!this.videoEl){
                const videoElementId = this.videoPlaceHolderEl.id +"VideoEl"+ (new Date()).getTime();
                const divEl = document.createElement("div");
                divEl.id= videoElementId;
                this.videoPlaceHolderEl.appendChild(divEl);
                let evObj = null;
                if(useOneVideEl){
                    evObj = {
                        'onReady': globalOnVideoReadyToPlay,
                        'onStateChange': globalOnPlayerStateChange,
                        'onError' : globalOnError
                    };
                }else{
                    evObj = {
                        'onReady': this._onVideoReadyToPlay.bind(this),
                        'onStateChange': this._onPlayerStateChange.bind(this),
                        'onError' : this._onError.bind(this),
                    }
                }
                this.videoEl = new window.YT.Player(divEl.id , {
                    "videoId": videoId,
                    "playerVars" : {"controls" : 0, "modestbranding": 0,"playsinline": 1, "autoplay": 0 , "showinfo": 1,"rel": 0,"iv_load_policy": 3},
                    "events": evObj
                });
                if (useNoCookieUrl ) {
                    const nocookieURL = this.videoEl.getIframe().src.replace("youtube.com","youtube-nocookie.com");
                    this.videoEl.getIframe().src = nocookieURL;
                }
            }else{
                if(useNoCookieUrl && !this.videoEl.cueVideoById){
                    const ytUrl = this.videoEl.getIframe().src;
                    let oldVideo_id = ytUrl.split('v=')[1];
                    const ampersandPosition = oldVideo_id.indexOf('&');
                    if(ampersandPosition != -1) {
                        oldVideo_id = oldVideo_id.substring(0, ampersandPosition);
                        const newYtUrl = this.videoEl.getIframe().src.replace(oldVideo_id,videoId);
                        this.videoEl.getIframe().src  = newYtUrl;
                    }
                }else{
                    this.videoEl.cueVideoById(videoId);
                }
                //this.videoEl.getIframe().style.display = "block";
                globalOnVideoReadyToPlay();
            }
            this._videoId = videoId;
            if(useOneVideEl){
                fixedVideoEl = this.videoEl;
            }
            if(this.videoEl  && this.videoEl.getIframe){
                this.videoEl.getIframe().style.height ="100%";
                this.videoEl.getIframe().style.width ="100%";
            }
        }
        
    },
    
    _onPlayerStateChange : function(event){
        if (event.data == window.YT.PlayerState.PLAYING) {
            this._loading = false;
            this._onVideoPlaying();
            return;
        }
        if (event.data == window.YT.PlayerState.PAUSED) {
            this._loading = false;
            this._onVideoPaused()
            return;
        }
        if (event.data == window.YT.PlayerState.ENDED) {
            this._loading = false;
            this._onVideoFinishedPlaying()
            return;
        }
        if (event.data == window.YT.PlayerState.CUED) {
            this._loading = false;
            this._onVideoStop();
            return;
        }
        if (event.data == window.YT.PlayerState.BUFFERING) {
            this._loading = false;
            this._onDownloadingVideoFrames();
            return;
        }
        if (event.data == -1) {
            //unstarted
            return;
        }
    },
    
    _retriveYoutubeVideoInfo : function(songModel, uriBroken){
        return (new RSVP.Promise((function(resolve, reject){
            try{
                if(!uriBroken && songModel.get("uri").indexOf(YOUTUBE_SONG_URI_PREFIX) > -1)
                {
                    const songUri = songModel.get("uri");
                    const videoId = songUri.substr(YOUTUBE_SONG_URI_PREFIX.length );
                    return YoutubeSearchHelper.getInstance().getVideoInfoFromVideoId(videoId).then((function(youtubeVideoJsonInfo){
                        if(youtubeVideoJsonInfo){
                            return youtubeVideoJsonInfo;
                        }else{
                            window.log("youtube uri is probably broken. uri:"+ songUri);
                            const q = songModel.get("artist") + " - " + songModel.get("title") ;
                            return YoutubeSearchHelper.getInstance().getMostViewVideoInfo(q, true);
                        }
                    }).bind(this)).then(function(youtubeVideoJsonInfo){
                        resolve(youtubeVideoJsonInfo);
                    }).catch(function(error){
                        reject(error);
                    });
                }else{
                    const q = songModel.get("artist") + " - " + songModel.get("title") ;
                    return YoutubeSearchHelper.getInstance().getMostViewVideoInfo(q, true).then((function(youtubeVideoJsonInfo){
                        resolve(youtubeVideoJsonInfo);
                    }).bind(this)).catch(function(error){
                        reject(error);
                    });
                }
            }catch(err){
                reject(err);
            }
        }).bind(this))).then((function(youtubeVideoJsonInfo){
if(youtubeVideoJsonInfo)
{
const promiseArray = [];
let videoId = youtubeVideoJsonInfo.videoId;
if(!videoId && youtubeVideoJsonInfo.id){
    videoId = youtubeVideoJsonInfo.id
}
return youtubeVideoJsonInfo;
}else{

throw new Error(window.getI18n(ti18n, "COULDNT_FIND_THE_MATCHING_VIDEO"));
}
}).bind(this));
    },
    
    _loadYoutubeIframeAPI : function(){
        return new Promise((resolve, reject) => {
            window._loadScriptipt('https://www.youtube.com/iframe_api?noext')
              .then(() => {
                // Assuming that _onYoutubeIframeAPIReadyPromise is a global variable
                resolve(window._onYoutubeIframeAPIReadyPromise);
              })
              .catch(reject);
          });

        },
    
    
    _searchYoutubeVideoUrlAndLoadVideo : function(){
        window.log("About to start searching for youtube video url");
        if(this.songModel){
            const uriOfTheCurrentLoadedSong = this.songModel.get("uri");
            return this._loadYoutubeIframeAPI().then((function(){
                return this._retriveYoutubeVideoInfo(this.songModel, this.uriBroken);
            }).bind(this)).then((function(youtubeVideoInfo){
                if(youtubeVideoInfo )
                {
                    if( uriOfTheCurrentLoadedSong == this.songModel.get("uri"))
                    {
                        let videoId = youtubeVideoInfo.videoId;
                        if(!videoId && youtubeVideoInfo.id){
                            videoId = youtubeVideoInfo.id
                        }
                        this._initYTPlayer(videoId);
                    }else{
                        window.log("Song to be loaded has changed. Chances are a different video is currently being loaded so do not load the video");
                    }
                    this._searchAndLoadVideoPromise = null;
                    window.log("Finished searching for videos");
                    this.trigger("loaded");
                }else{
                    this._searchAndLoadVideoPromise = null;
                    window.log("Finished searching for videos");
                    this._loading = false;
                    this._loadingError = window.getI18n(ti18n, "FAILED_LOADING_VIDEO");
                    this.trigger("failedLoading",window.getI18n(ti18n, "FAILED_LOADING_VIDEO"));
                    console.error("Couldn't retrieve direct youtube video for video id");
                    if(this.onFailedLoadingHandler){
                        //call handler for failed loading
                        this.onFailedLoadingHandler.call(this._mediaFunctionHandlerContext ,this.songModel? this.songModel.get("uri"): null);
                    }else{
                        //act like the song finish playing,
                        //player will probably just play the next song
                        this._onVideoFinishedPlaying();
                    }
                }
            }).bind(this)).catch((function(err){
                this._searchAndLoadVideoPromise = null;
                window.log("Finished searching for videos");
                this._loading = false;
                this._loadingError = window.getI18n(ti18n, "FAILED_LOADING_VIDEO")
                this.trigger("failedLoading",window.getI18n(ti18n, "FAILED_LOADING_VIDEO"));
                console.error("Error trying to load youtube video. Err:"+ err);
                if(this.onFailedLoadingHandler){
                    //call handler for failed loading
                    this.onFailedLoadingHandler.call(this._mediaFunctionHandlerContext, this.songModel? this.songModel.get("uri"): null);
                }else{
                    //act like the song finish playing,
                    //player will probably just play the next song
                    this._onVideoFinishedPlaying();
                }
            }).bind(this));
        }else{
            this._loading = false;
            this._loadingError = window.getI18n(ti18n, "FAILED_LOADING_VIDEO")
            this.trigger("failedLoading",window.getI18n(ti18n, "FAILED_LOADING_VIDEO"));
            console.error("No song info found to load  video for.");
            if(this.onFailedLoadingHandler){
                //call handler for failed loading
                this.onFailedLoadingHandler.call(this._mediaFunctionHandlerContext);
            }else{
                //act like the song finish playing,
                //player will probably just play the next song
                this._onVideoFinishedPlaying();
            }
            return RSVP.Promise.resolve();
        }
        
    },
    

    play: function(){
        if(!this._isReleased){
            this.wasPaused =false;
            this.wasStopped =false;
            this._loading = true;
            this.trigger("loading");
            if(this._playerReadyPromise){
                this._playerReadyPromise.then((function(){
                    if(!this._isReleased){
                        this._loading = false;
                        this.trigger("loaded");
                        if(window.isMobileBrowser && window.device && window.device.platform == "browser" && !mobileQuirkExecutedForMobileVideo){
                            window.log("Pausing video notify user that playing need his/her action");
                            this.pause();
                            this._onPlayerStateChange({"data": window.YT.PlayerState.PAUSED});
                            mobileQuirkExecutedForMobileVideo = true;
                        }else{
                            window.log("about to start playing since player ready");
                            this.videoEl.playVideo();
                            //}
                            
                            this._onDurationchange();
                            if(!this._gettingReadyToPlayPromise){
                                const gettingReadyToPlayPromise = new RSVP.Promise((function(resolve, reject){
                                    this._gettingReadyToPlayPromiseResolve = resolve;
                                    this._gettingReadyToPlayPromiseReject = reject;
                                }).bind(this));
                                this._gettingReadyToPlayPromise = gettingReadyToPlayPromise;
                            }
                        }

                        }
                    }).bind(this));
            }else{
                this._loading = false;
                this.trigger("loaded");
                window.log("about to start playing");
                this.videoEl.playVideo();
                this._onDurationchange();
                if(!this._gettingReadyToPlayPromise){
                    const gettingReadyToPlayPromise = new RSVP.Promise((function(resolve, reject){
                        this._gettingReadyToPlayPromiseResolve = resolve;
                        this._gettingReadyToPlayPromiseReject = reject;
                    }).bind(this));
                    this._gettingReadyToPlayPromise = gettingReadyToPlayPromise;
                }
            }
        }
    },
    
    _onError : function(err){
        if(this.onErrorHandler)
        {
            this.onErrorHandler.call(this._mediaFunctionHandlerContext, err );
        }
        if(this._playerReadyPromiseFailureFunc){
            this._playerReadyPromiseFailureFunc(err);
        }
        window.log("Error on video element: "+err);
        this._loading = false;
        this._loadingError = window.getI18n(ti18n, "FAILED_LOADING_VIDEO");
        this.trigger("failedLoading",window.getI18n(ti18n, "FAILED_LOADING_VIDEO"));
        console.error("Error trying to load youtube video. Err:"+ err);
        if(this.onFailedLoadingHandler){
            //call handler for failed loading
            this.onFailedLoadingHandler.call(this._mediaFunctionHandlerContext,this.songModel? this.songModel.get("uri"): null);
        }else{
            //act like the song finish playing,
            //player will probably just play the next song
            this._onVideoFinishedPlaying();
        }
    },
    

    pause: function(){
        if(!this._isReleased){
            if(this._playerReadyPromise){
                this._playerReadyPromise.then((function(){
                    this.videoEl.pauseVideo();
                }).bind(this));
            }else{
                this.videoEl.pauseVideo();
            }
            this.wasPaused = true;
            this.wasStopped = false;
        }
        if(this._gettingReadyToPlayPromise){
            this._gettingReadyToPlayPromise.then((function(){
                if(this.videoEl){
                    this.videoEl.pauseVideo();
                }
            }).bind(this));
        }
    },


    stop: function(){
        if(!this._isReleased){
            if(this._playerReadyPromise){
                this._playerReadyPromise.then((function(){
                    this.videoEl.stopVideo();
                }).bind(this));
            }else{
                this.videoEl.stopVideo();
            }
            this.wasPaused = false;
            this.wasStopped = true;
        }
        if(this._gettingReadyToPlayPromise){
            this._gettingReadyToPlayPromise.then((function(){
                if(this.videoEl){
                    this.videoEl.stopVideo();
                }
            }).bind(this));
        }
    },

    release: function(){
        let p = null;
        if(window._currentPlayingYoutubeMedia == this){
            window._currentPlayingYoutubeMedia = null;
        }
        if(this._searchAndLoadVideoPromise){
            p = this._searchAndLoadVideoPromise;
        }
        if(this._playerReadyPromise){
            p = this._playerReadyPromise;
        }
        if(!useOneVideEl)
        {
            if(p){
                p.then((function(){
                    if(this.videoEl  && this.videoEl.getIframe && this.videoEl.getIframe() && this.videoEl.getIframe().parentNode)
                    {
                        this.videoEl.getIframe().parentNode.removeChild(this.videoEl.getIframe());
                    }
                }).bind(this))
            }else{
                if(this.videoEl  && this.videoEl.getIframe && this.videoEl.getIframe() && this.videoEl.getIframe().parentNode)
                {
                    
                    this.videoEl.getIframe().parentNode.removeChild(this.videoEl.getIframe());
                }
            }
        }
        this._isReleased = true;
        window.log("Video set released");
        
    },


    seekTo: function(position){
        if(!this._isReleased){
            if(position){
                if(this._playerReadyPromise){
                    this._playerReadyPromise.then((function(){
                        let videoTime =  position?Math.round(position/1000): position ;
                        this.videoEl.seekTo(videoTime, true);
                    }).bind(this));
                }else{
                    let videoTime =  position?Math.round(position/1000): position ;
                    this.videoEl.seekTo(videoTime, true);
                }
                
            }
        }
    },

    startRecord: function(position){
        window.alertErrorMessage(window.getI18n(ti18n, "NOT_SUPPORTED_YET"));
    },

    stopRecord: function(position){
        window.alertErrorMessage(window.getI18n(ti18n, "NOT_SUPPORTED_YET"));
    },

    getDuration: function(){
        if(!this._isReleased){
            return this.videoEl?this.videoEl.getDuration()*1000:0; // in milliseconds
        }else{
            return 0;
        }
    },

    getCurrentPosition: function(onSuccess, onFailure){
        if(!this._isReleased){
            try{
                if(this._playerReadyPromise){
                    this._playerReadyPromise.then((function(){
                        onSuccess(this.videoEl.getCurrentTime());
                    }).bind(this)).catch((function(error){
                        onFailure(error);
                    }).bind(this))
                }else{
                    onSuccess(this.videoEl.getCurrentTime());
                }
                
            }catch(err){
                onFailure(err);
            }
        }else{
            onFailure(window.getI18n(ti18n, "COULDNT_FIND_THE_VIDEO_CURRENT_TIME_PROGRES"));
        }
    },

    _onVideoFinishedPlaying : function(){
        if(!this._isReleased){
            //this._exitFullscreen();
            if(this.videoEl ){
                
                if(this.videoEl  && this.videoEl.clearVideo  ){
                    this.videoEl.clearVideo();
                }
                /*if(this.videoEl  && this.videoEl.getIframe &&  this.videoEl.getIframe() ){
                    this.videoEl.getIframe().style.display = "none";
                }*/
            }
            if(this.onSuccessHandler){
                this.onSuccessHandler.call(this._mediaFunctionHandlerContext);
            }
        }
        window.log("Video finished playing");
    },
    
    _onVideoReadyToPlay : function(){
        if(!this._isReleased){
            this._playerReadyPromise = null;
            this._playerReadyPromiseSuccessFunc();
            window.log("Video is ready to play");
            this._loading = false;
            this.trigger("loaded");
            this._onDurationchange();
        }
    },
    
    _onDurationchange : function(){
        if(this.videoEl.getDuration()){
            this.trigger("durationChange",this.videoEl.getDuration()*1000);
        }else{
            if(!this._isReleased){
                setTimeout(this._onDurationchange.bind(this),1000);
            }
        }
    },
    
    _onVideoStalled : function(){
        window.log("Video stalled. ");
        this.trigger("videoStalled");
    },
    
    _onDownloadingVideoFrames : function(){
        window.log("Video is downloading frames. ");
        window.log("video progress "+this.videoEl.getVideoLoadedFraction());
        this.trigger("downloading",this.videoEl.getVideoLoadedFraction());
    },
    
    _onLoadedmetadata : function(){
        window.log("Loaded Video Meta data. ");
        this.trigger("loadedMetaData");
    },
    
    _isVideoFullScreen : function(){
        const fullscreenElement = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement;
        return fullscreenElement == this.videoEl;
    },
    
    _showVideoFullScreen : function(){
        this._launchFullscreen(this.videoEl);
    },
    
    _launchFullscreen : function (element) {
        if(element.requestFullscreen) {
            element.requestFullscreen();
        } else if(element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if(element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
        } else if(element.msRequestFullscreen) {
            element.msRequestFullscreen();
        }
    },
    
     _exitFullscreen: function () {
        try{
            if(document.exitFullscreen) {
                document.exitFullscreen();
            } else if(document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if(document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        }catch(error){
            console.error("Failed exiting fullscreen. Error:"+error);
        }
    },

    _onVideoPlaying : function(){
        if(this.onStatusHandler){
            this.onStatusHandler.call(this._mediaFunctionHandlerContext, mediaStatuses.MEDIA_RUNNING, this);
        }
        window.log("Video playing");
        try{
            if(this._gettingReadyToPlayPromise){
                this._gettingReadyToPlayPromiseResolve();
                this._gettingReadyToPlayPromise = null;
            }
        }catch(error){
            console.error(error);
            this._gettingReadyToPlayPromise = null;
        }
        if(this._isReleased){
            if(this.videoEl){
                this.videoEl.stopVideo();
                this.videoEl.clearVideo();
            }
        }
    },
    
    _onVideoStop : function(){
        if(this.onStatusHandler){
            this.onStatusHandler.call(this._mediaFunctionHandlerContext, mediaStatuses.MEDIA_STOPPED, this);
        }
        window.log('Video Stopped');
    },

    _onVideoPaused : function() {
        if(this.onStatusHandler){
            this.onStatusHandler.call(this._mediaFunctionHandlerContext, mediaStatuses.MEDIA_PAUSED, this);
        }
        window.log('Video Paused');
    },
    

});
export default YoutubeBrowserMedia;