var amdi18n={"__root":{"ADD_SONGS_FROM_YOUR_FAVORITE_WEBSITES":"Add Songs From Your Favorite Websites","CHROME_EXTENSION_DESCRIPTION":"Our chrome browser extension lets you easily add to your content channel any music you come across on the web. Once you download the extension, you will get an \"Add To Trebble\" button, wherever you come across music from a supported source. All you have to do is click on the button to add the music to your content channel, grade it and voila. The extension supports Soundcloud, Youtube and lots of other sources. ","INSTALL_CHROME_EXTENSION":"Install Chrome Extension"},"__fr":{"ADD_SONGS_FROM_YOUR_FAVORITE_WEBSITES":"Ajouter des chansons de vos sites Web préférés","CHROME_EXTENSION_DESCRIPTION":"Notre extension pour le navigateur chrome vous permet d'ajouter facilement à votre chaîne toute musique que vous rencontrez sur le Web. Une fois l'extension téléchargée, vous obtiendrez un bouton \"Ajouter à Trebble\" partout où vous rencontrerez de la musique provenant d'une source supporté. Tout ce que vous avez à faire est de cliquer sur le bouton pour ajouter de la musique à votre chaîne, la classée et le tour est joué. L'extension supporte Soundcloud, Youtube et de nombreuses autres sources.","INSTALL_CHROME_EXTENSION":"Installer l'extension Chrome"},"__en":{"ADD_SONGS_FROM_YOUR_FAVORITE_WEBSITES":"Add Songs From Your Favorite Websites","CHROME_EXTENSION_DESCRIPTION":"Our chrome browser extension lets you easily add to your content channel any music you come across on the web. Once you download the extension, you will get an \"Add To Trebble\" button, wherever you come across music from a supported source. All you have to do is click on the button to add the music to your content channel, grade it and voila. The extension supports Soundcloud, Youtube and lots of other sources. ","INSTALL_CHROME_EXTENSION":"Install Chrome Extension"}};amdi18n.init=function(language){
		// get the default language
		if(!language){
			if(typeof window !== 'undefined' && window._i18n && window._i18n.locale){
				language = window._i18n.locale;
			}else if(typeof document !== 'undefined' && document.documentElement.lang){
				language = document.documentElement.lang;
			}else if(typeof navigator !== 'undefined' && (navigator.languages || navigator.language || navigator.userLanguage)){
				// navigator.languages does not exist in IE 11
				language = (navigator.languages && navigator.languages[0]) ||
					navigator.language ||
					navigator.userLanguage;
				language = (language || 'root').toLowerCase();
			}else{
				language = 'root';
			}
		}
		var target = this['__' + language] || this.__root;

		// copy definition to root level
		if (target) {
			for (var name in target) {
				this[name] = target[name];
			}
		}

		// fallback to root
		var recursiveFallback = function(target, source){
			for(var name in source){
				switch(typeof target[name]){
				case 'undefined':
					target[name] = source[name];
					break;
				case 'object':
					recursiveFallback(target[name], source[name]);
				}
			}
		};
		recursiveFallback(this, this.__root);
	};amdi18n.init();module.exports=amdi18n;