import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerOperationLog from "models/audioEditor/sequencerOperationLog/SequencerOperationLog";
import RSVP from "rsvp";

const SequencerOperationInsertAudioNodeLog =  SequencerOperationLog.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperationLog.apply(this, [attributes, options]);
        
    },

    getOperationName : function(){
        return "Insert Audio Node";
    },

    setAudioSequencerNode : function(audioSequencerNode){
        this.set("audioSequencerNode", audioSequencerNode);
    },

    getAudioSequencerNode : function(){
        return this.get("audioSequencerNode");
    },

    setSequencerNodeToInsertNextTo : function(sequencerNodeToInsertNextTo){
        this.set("sequencerNodeToInsertNextTo", sequencerNodeToInsertNextTo);
    },

    getSequencerNodeToInsertNextTo : function(){
        return this.get("sequencerNodeToInsertNextTo");
    },

    setInsertBefore : function(insertBefore){
        this.set("insertBefore", insertBefore);
    },

    getInsertBefore : function(){
        return this.get("insertBefore");
    },



});

export default SequencerOperationInsertAudioNodeLog; 