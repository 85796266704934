import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import CustomSearchFieldView from "views/common/CustomSearchFieldView";
import PersistentModels from "services/PersistentModels";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import ti18n from "i18n!nls/MyLibraryi18n";
import pageTemplate from "text!../../../templates/mylibrary/myLibraryPageTemplate.html";

const MyLibraryPageView = Backbone.View.extend({
  template: _.template(pageTemplate),

  pageName: "My Library",
  events: {
    "click #analyseAudioBtn": "onAnalyseAudioBtnTapped",
    "click .songTabWrapper": "selectSongsTab",
    "click [aria-labelledby=albumTab]": "onAlbumTabSelected",
    "click [aria-labelledby=artistTab]": "onArtistTabSelected",
    "click [aria-labelledby=moreLibraryTab]": "onMoreTabSelected",
    "click #homeHeaderButton": "onHomeButtonSelected",
    "click [aria-labelledby=favoriteTab]": "onSubTabSelected",
    "click [aria-labelledby=genreTab]": "onSubTabSelected",
    "click [aria-labelledby=ratingTab]": "onSubTabSelected",
    "click .capsuleTabsWrapper": "onCapsuleTabSelected",
    "click .myCapsulesTabWrapper": "selectActiveCapsuleTab",
    "click .myOffAirCapsulesTabWrapper": "selectExpiredCapsuleTab",
    "click .myDraftCapsulesTabWrapper": "selectDraftCapsuleTab",
    "click .myReceivedCapsulesTabWrapper": "selectReceivedCapsuleTab",
    "click .myScheduledCapsulesTabWrapper": "selectScheduledCapsuleTab",
    "click #createNewCapsuleButton": "createCapsule",
    "click  #filterButton": "filterList",
    "click #fabAddButtonContainer.expanded #addSongFabBtn": "showGlobalSearch",
    "click #fabAddButtonContainer.expanded #addCapsuleFabBtn": "createCapsule",
    "click #createNewCapsuleFloatingBtnWrapper #addCapsuleFabBtn": "createCapsule",
    "click #whatsacapsuleButton": "showAndPlayWhatsACapsuleCapsule",
    "click #addContentFabBtn": "showOrHideAddContentButtons",
  },

  initialize: function (options) {
    this.showCapsuleTab = options.showCapsuleTab;
    this.songsTabContentView = options.songsTabContentView;
    this.artistsTabContentView = options.artistsTabContentView;
    this.albumsTabContentView = options.albumsTabContentView;
    this.genreTabContentView = options.genreTabContentView;
    this.ratedTabContentView = options.ratedTabContentView;
    this.activeTabContentView = options.activeTabContentView;
    this.archiveTabContentView = options.archiveTabContentView;
    this.draftTabContentView = options.draftTabContentView;
    this.receivedTabContentView = options.receivedTabContentView;
    this.scheduledTabContentView = options.scheduledTabContentView;

    this.infoBoxViewForSongTab = options.infoBoxViewForSongTab;
    this.infoBoxViewForAlbumTab = options.infoBoxViewForAlbumTab;
    this.infoBoxViewForArtistTab = options.infoBoxViewForArtistTab;
    this.infoBoxViewForGenreTab = options.infoBoxViewForGenreTab;
    this.infoBoxViewForRatedTab = options.infoBoxViewForRatedTab;
    this.infoBoxViewForActiveCapsuleTab = options.infoBoxViewForActiveCapsuleTab;
    this.infoBoxViewForArchivedCapsuleTab = options.infoBoxViewForArchivedCapsuleTab;
    this.infoBoxViewForDraftCapsuleTab = options.infoBoxViewForDraftCapsuleTab;
    this.infoBoxViewForReceivedCapsuleTab = options.infoBoxViewForReceivedCapsuleTab;
    this.infoBoxViewForScheduledCapsuleTab = options.infoBoxViewForScheduledCapsuleTab;
    this.selectedTab = "capsuleTabs";
    this.subSelectedTab = "myCapsulesTab";
    this.subSecondSelectedTab = "myCapsulesTab";
    this._defaultSubSelectedTab = "capsuleTabs";
    this._defaultSecondSubSelectedTab = "myCapsulesTab";

    this._onKeyUpInSearchFieldHandler = options.onKeyUpInSearchFieldHandler;
    this._onSearchFieldClearedHandler = options.onSearchFieldClearedHandler;

    PersistentModels.getInstance()
      .getRouter()
      .registerForViewportHeightChange("myLibraryPageView", this._onViewportHeightChanged.bind(this));
  },
  _onViewportHeightChanged: function (newViewPortHeigh, forceResizing) {
    if (PersistentModels.getInstance().getRouter().isEqualToCurrentPageUrl("#mylibrary") || forceResizing) {
      if (this.el) {
        const tabHeight = 46;
        const subtabHeight = 44;
        const adjustedListContainerHeight = newViewPortHeigh - tabHeight - subtabHeight;
        if (!this.showCapsuleTab) {
          if (this.$placeHolderGenreListEl) {
            this.$placeHolderGenreListEl.css("height", adjustedListContainerHeight + "px");
          }
          if (this.$placeHolderRatingListEl) {
            this.$placeHolderRatingListEl.css("height", adjustedListContainerHeight + "px");
          }
        } else {
          if (this.$placeHolderActiveCapsuleListEl) {
            this.$placeHolderActiveCapsuleListEl.css("height", adjustedListContainerHeight + "px");
          }
          if (this.$placeHolderArchiveCapsuleListEl) {
            this.$placeHolderArchiveCapsuleListEl.css("height", adjustedListContainerHeight + "px");
          }
          if (this.$placeHolderDraftCapsuleListEl) {
            this.$placeHolderDraftCapsuleListEl.css("height", adjustedListContainerHeight + "px");
          }
          if (this.$placeHolderReceivedCapsuleListEl) {
            this.$placeHolderReceivedCapsuleListEl.css("height", adjustedListContainerHeight + "px");
          }
          if (this.$placeHolderScheduledCapsuleListEl) {
            this.$placeHolderScheduledCapsuleListEl.css("height", adjustedListContainerHeight + "px");
          }
        }
      }
    }
  },

  onSongTabSelected: function () {
    this.selectedTab = "songTab";
    this.showApplicableHeaderButtons();
  },

  filterList: function () {
    this.trigger("filter");
  },

  showGlobalSearch: function () {
    this.trigger("showGlobalSearchPage");
  },

  showAndPlayWhatsACapsuleCapsule: function () {
    this.trigger("showAndPlayWhatsACapsuleCapsule");
  },

  showHideWhatACapsuleButton: function (show) {
    if (show) {
      this.$el
        .find("#whatsacapsuleButton")
        .attr("style", "top: 170px;z-index: 9999;display: -webkit-flex; display: flex");
    } else {
      this.$el.find("#whatsacapsuleButton").hide();
    }
  },

  showApplicableHeaderButtons: function () {
    if (this.showCapsuleTab) {
      if (this.isSongTabSelected()) {
        //this.createCapsuleBtn$el.hide();
        if (this.customSearchFieldView) {
          this.customSearchFieldView.$el.show();
        }
        this.sortFilterBtn$el.show();
      } else {
        //Capsule Tab Selected
        if (this.customSearchFieldView) {
          this.customSearchFieldView.clearSearchField();
          this.customSearchFieldView.$el.hide();
        }
        this.sortFilterBtn$el.hide();
        //this.createCapsuleBtn$el.attr('style', 'display: -webkit-flex; display: flex');
      }
    }
  },

  onSubTabSelected: function (ev) {
    this.subSelectedTab = arguments[0].currentTarget.id;
  },
  onSecondSubTabSelected: function (ev) {
    this.subSecondSelectedTab = arguments[0].currentTarget.id;
  },

  onHomeButtonSelected: function () {
    this.trigger("showHomePopup");
  },

  onAlbumTabSelected: function () {
    this.selectedTab = "albumTab";
  },

  onArtistTabSelected: function () {
    this.selectedTab = "artistTab";
  },

  onMoreTabSelected: function () {
    this.selectedTab = "moreTab";
    if (!this.subSelectedTab) {
      this.subSelectedTab = this._defaultSubSelectedTab;
      this.$el.find("#" + this.subSelectedTab).addClass("ui-btn-active");
    }
  },

  showOrHideAddContentButtons: function () {
    this.fabAddButtonContainer$el.toggleClass("expanded");
  },

  hideAddContentButtons: function () {
    this.fabAddButtonContainer$el.removeClass("expanded");
  },

  showAddContentButtons: function () {
    if (!this.fabAddButtonContainer$el.hasClass("expanded")) {
      this.fabAddButtonContainer$el.addClass("expanded");
    }
  },

  createCapsule: function () {
    this.trigger("createCapsule");
  },

  onCapsuleTabSelected: function () {
    //this.selectedTab = "capsuleTabs";
    if (!this.subSecondSelectedTab) {
      this.subSecondSelectedTab = this._defaultSecondSubSelectedTab;
      const subSecondSelectedTab$el = this.$el.find("#" + this.subSecondSelectedTab);
      if (!subSecondSelectedTab$el.hasClass("ui-btn-active")) {
        subSecondSelectedTab$el.addClass("ui-btn-active");
      }
    }
    this._selectTab("capsuleTabs", true);
    this._selectTab(this.subSecondSelectedTab);
    this.showApplicableHeaderButtons();
  },

  isSongTabSelected: function () {
    return this.selectedTab == "songTab";
  },

  isAlbumTabSelected: function () {
    return this.selectedTab == "albumTab";
  },

  isArtistTabSelected: function () {
    return this.selectedTab == "artistTab";
  },

  isMoreTabSelected: function () {
    return this.selectedTab == "moreTab";
  },

  isCapsuleTabSelected: function () {
    return this.selectedTab == "capsuleTabs";
  },

  isActiveCapsuleTabSelected: function () {
    return this.subSecondSelectedTab == "myCapsulesTab";
  },

  isArchivedTabSelected: function () {
    return this.subSecondSelectedTab == "myOffAirCapsulesTab";
  },

  isDraftTabSelected: function () {
    return this.subSecondSelectedTab == "myDraftCapsulesTab";
  },

  isReceivedTabSelected: function () {
    return this.subSecondSelectedTab == "myReceivedCapsulesTab";
  },

  isScheduledTabSelected: function () {
    return this.subSecondSelectedTab == "myScheduledCapsulesTab";
  },

  onAnalyseAudioBtnTapped: function () {
    this.trigger("analyseAudioOutput");
  },

  _buildSearchHeaderButtonAndToPage: function () {
    const customSearchFieldViewParams = {};
    customSearchFieldViewParams.searchFieldPlaceholderSelector = "#pageHeaderTitleWrapper";
    customSearchFieldViewParams.searchFieldPlaceholderSelectorRootNodeEl = this.el;
    this.customSearchFieldView = new CustomSearchFieldView(customSearchFieldViewParams);
    this.customSearchFieldView.render();
    this.customSearchFieldView.addKeyUpHandlerFunction(this._onKeyUpInSearchField.bind(this));
    this.customSearchFieldView.addOnSearchFieldClearedFunction(this._onSearchFieldCleared.bind(this));
    this.customSearchFieldView.addOnScreenResizeHandlerFunction(this._resizeSearchField.bind(this));
    this.$el.find(".rightHeaderButtonGroup").prepend(this.customSearchFieldView.el);
  },

  getSearchFieldValue: function () {
    return this.customSearchFieldView.getSearchFieldValue();
  },

  _resizeSearchField: function (searchField) {
    const homeButtonWidth = 61;
    const searchButtonWidth = 56;
    const identifyButtonWidth = 56;
    const newSearchFieldWidth =
      window.getDocumentClienWidth() - homeButtonWidth - searchButtonWidth - identifyButtonWidth + "px";
    searchField.width(newSearchFieldWidth);
  },
  _onKeyUpInSearchField: function (searchFieldValue) {
    if (this._onKeyUpInSearchFieldHandler) {
      this._onKeyUpInSearchFieldHandler(searchFieldValue);
    }
  },

  _onSearchFieldCleared: function () {
    if (this._onSearchFieldClearedHandler) {
      this._onSearchFieldClearedHandler();
    }
  },

  onPageBeforeShow: function () {
    this._setCurrentTabWithActiveClass();
    if (window.trebbleAnalyticsHelper) {
      window.trebbleAnalyticsHelper.trackEvent("pageShow", "MyLibraryPageShow", "My Library Page Show");
    }
    this.$el.find(".ui-tabs-anchor.ui-btn-active").removeClass("ui-btn-active");
    this.$el.find("#" + this.selectedTab).addClass("ui-btn-active");
    this.$el.find("#" + this.subSecondSelectedTab).addClass("ui-btn-active");
    //this.$el.find("#"+this.subSelectedTab).addClass("ui-btn-active");
    if (this.isSongTabSelected()) {
      this.$el.find("#songList").css("display", "block");
    }
    if (!this.showCapsuleTab) {
      if (this.isAlbumTabSelected()) {
        this.$el.find("#albumList").css("display", "block");
      }
      if (this.isArtistTabSelected()) {
        this.$el.find("#artistList").css("display", "block");
      }
      if (this.isMoreTabSelected()) {
        this.$el.find("#moreList").css("display", "block");
      }
    } else {
      if (this.isCapsuleTabSelected()) {
        this.$el.find("#capsuleTabs").css("display", "block");
        if (this.isActiveCapsuleTabSelected()) {
          this.$el.find("#myCapsulesList").css("display", "block");
        }
        if (this.isArchivedTabSelected()) {
          this.$el.find("#myOffAirCapsulesList").css("display", "block");
        }
        if (this.isDraftTabSelected()) {
          this.$el.find("#myDraftCapsulesList").css("display", "block");
        }
        if (this.isReceivedTabSelected()) {
          this.$el.find("#myReceivedCapsulesList").css("display", "block");
        }
        if (this.isScheduledTabSelected()) {
          this.$el.find("#myScheduledCapsulesList").css("display", "block");
        }
      }
    }
    this.showApplicableHeaderButtons();
    this.trigger("pageBeforeShow");
  },

  onPageBeforeHide: function () {
    if (this.isSongTabSelected()) {
      this.$el.find("#songList").css("display", "none");
    }
    if (!this.showCapsuleTab) {
      if (this.isAlbumTabSelected()) {
        this.$el.find("#albumList").css("display", "none");
      }
      if (this.isArtistTabSelected()) {
        this.$el.find("#artistList").css("display", "none");
      }
      if (this.isMoreTabSelected()) {
        this.$el.find("#moreList").css("display", "none");
      }
    } else {
      if (this.isCapsuleTabSelected()) {
        this.$el.find("#capsuleTabs").css("display", "none");
        if (this.isActiveCapsuleTabSelected()) {
          this.$el.find("#myCapsulesList").css("display", "none");
        }
        if (this.isArchivedTabSelected()) {
          this.$el.find("#myOffAirCapsulesList").css("display", "none");
        }
        if (this.isDraftTabSelected()) {
          this.$el.find("#myDraftCapsulesList").css("display", "none");
        }
        if (this.isReceivedTabSelected()) {
          this.$el.find("#myReceivedCapsulesList").css("display", "none");
        }
        if (this.isScheduledTabSelected()) {
          this.$el.find("#myScheduledCapsulesList").css("display", "none");
        }
      }
    }
  },

  _setCurrentTabWithActiveClass: function () {
    if (this.isSongTabSelected()) {
      this.$el.find("#songTab").addClass("ui-btn-active");
    }
    if (!this.showCapsuleTab) {
      if (this.isAlbumTabSelected()) {
        this.$el.find("#albumTab").addClass("ui-btn-active");
      }
      if (this.isArtistTabSelected()) {
        this.$el.find("#artistTab").addClass("ui-btn-active");
      }
      if (this.isMoreTabSelected()) {
        this.$el.find("#moreLibraryTab").addClass("ui-btn-active");
      }
    } else {
      if (this.isCapsuleTabSelected()) {
        this.$el.find("#capsuleTabs").addClass("ui-btn-active");
      }
    }
  },

  selectReceivedCapsuleTab: function () {
    this._selectTab("capsuleTabs", true);
    this._selectTab("myReceivedCapsulesTab");
  },

  selectScheduledCapsuleTab: function () {
    this._selectTab("capsuleTabs", true);
    this._selectTab("myScheduledCapsulesTab");
  },

  selectActiveCapsuleTab: function () {
    this._selectTab("capsuleTabs", true);
    this._selectTab("myCapsulesTab");
  },
  selectExpiredCapsuleTab: function () {
    this._selectTab("capsuleTabs", true);
    this._selectTab("myOffAirCapsulesTab");
  },
  selectDraftCapsuleTab: function () {
    this._selectTab("capsuleTabs", true);
    this._selectTab("myDraftCapsulesTab");
  },

  selectSongsTab: function () {
    this._selectTab("songTab", true);
  },

  _selectTab: function (tabId, topTap) {
    const tab$el = this.$el.find("#" + tabId);
    const elementIdToShow = tab$el.attr("data-href");
    if (topTap) {
      const selectedTab$el = this.$el.find("#" + this.selectedTab);
      const elementIdToHide = this.$el.find("#" + this.selectedTab).attr("data-href");
      selectedTab$el.removeClass("ui-btn-active");

      if (elementIdToHide != elementIdToShow) {
        this.$el.find(elementIdToHide).hide();
        this.$el.find(elementIdToShow).show();
        this.selectedTab = tabId;
      }
    } else {
      const subSecondSelectedTab$el = this.$el.find("#" + this.subSecondSelectedTab);
      const elementIdToHide = this.$el.find("#" + this.subSecondSelectedTab).attr("data-href");
      subSecondSelectedTab$el.removeClass("ui-btn-active");
      if (elementIdToHide != elementIdToShow) {
        this.$el.find(elementIdToHide).hide();
        this.$el.find(elementIdToShow).show();
        this.subSecondSelectedTab = tabId;
      }
    }

    /*if(topTap){
            this.selectedTab = tabId;
        }else{
            this.subSecondSelectedTab = tabId;
        }
        tab$el.click();*/
    if (!tab$el.hasClass("ui-btn-active")) {
      tab$el.addClass("ui-btn-active");
    }
  },

  setSearchValue: function (newSearchValue) {
    this.customSearchFieldView.setSearchFieldValue(newSearchValue, true);
  },

  render: function () {
    this.$el.html(this.template({ ti18n: ti18n }));
    this.setElement(this.$el.find("div").first());
    this._buildSearchHeaderButtonAndToPage();

    this.sortFilterBtn$el = this.$el.find("#filterButton");
    this.createCapsuleBtn$el = this.$el.find("#createNewCapsuleButton");
    this.fabAddButtonContainer$el = this.$el.find("#fabAddButtonContainer");
    if (!window.isMobileBrowser) {
      this.fabAddButtonContainer$el.hover(function () {}, this.hideAddContentButtons.bind(this));
      this.$el.find("#addContentFabBtn").hover(this.showAddContentButtons.bind(this), function () {});
    }
    if (!this.showCapsuleTab) {
      this.$el.find("#placeHolderArtistList").append(this.artistsTabContentView.el);
      if (this.infoBoxViewForArtistTab) {
        this.$el.find("#artistList").append(this.infoBoxViewForArtistTab.el);
      }

      this.$el.find("#placeHolderAlbumList").append(this.albumsTabContentView.el);
      if (this.infoBoxViewForAlbumTab) {
        this.$el.find("#albumList").append(this.infoBoxViewForAlbumTab.el);
      }

      this.$placeHolderGenreListEl = this.$el.find("#placeHolderGenreList").first();
      this.$placeHolderGenreListEl.append(this.genreTabContentView.el);
      window.setTimeout(
        function () {
          this.genreTabContentView.load();
        }.bind(this),
        0,
      );
      if (this.infoBoxViewForGenreTab) {
        this.$el.find("#genresList").append(this.infoBoxViewForGenreTab.el);
      }

      this.$placeHolderRatingListEl = this.$el.find("#placeHolderRatingList").first();
      this.$placeHolderRatingListEl.append(this.ratedTabContentView.el);
      window.setTimeout(
        function () {
          this.ratedTabContentView.load();
        }.bind(this),
        0,
      );
      if (this.infoBoxViewForRatedTab) {
        this.$el.find("#ratingList").append(this.infoBoxViewForRatedTab.el);
      }
    } else {
      if (this.songsTabContentView) {
        this.$el.find("#placeHolderSongList").append(this.songsTabContentView.el);
        if (this.infoBoxViewForSongTab) {
          this.$el.find("#songList").append(this.infoBoxViewForSongTab.el);
        }
      }

      this.$placeHolderActiveCapsuleListEl = this.$el.find("#myOnAirCapsulesListWrapper").first();
      this.$placeHolderActiveCapsuleListEl.append(this.activeTabContentView.el);
      if (this.infoBoxViewForActiveCapsuleTab) {
        this.$el.find("#myCapsulesTab").append(this.infoBoxViewForActiveCapsuleTab.el);
      }
      this.$placeHolderArchiveCapsuleListEl = this.$el.find("#myOffAirCapsulesListWrapper").first();
      this.$placeHolderArchiveCapsuleListEl.append(this.archiveTabContentView.el);
      if (this.infoBoxViewForArchivedCapsuleTab) {
        this.$el.find("#myOffAirCapsulesTab").append(this.infoBoxViewForArchivedCapsuleTab.el);
      }
      this.$placeHolderDraftCapsuleListEl = this.$el.find("#myDraftCapsulesListWrapper").first();
      this.$placeHolderDraftCapsuleListEl.append(this.draftTabContentView.el);
      if (this.infoBoxViewForDraftCapsuleTab) {
        this.$el.find("#myDraftCapsulesTab").append(this.infoBoxViewForDraftCapsuleTab.el);
      }
      this.$placeHolderReceivedCapsuleListEl = this.$el.find("#myReceivedCapsulesListWrapper").first();
      this.$placeHolderReceivedCapsuleListEl.append(this.receivedTabContentView.el);
      if (this.infoBoxViewForReceivedCapsuleTab) {
        this.$el.find("#myReceivedCapsulesTab").append(this.infoBoxViewForReceivedCapsuleTab.el);
      }
      this.$placeHolderScheduledCapsuleListEl = this.$el.find("#myScheduledCapsulesListWrapper").first();
      this.$placeHolderScheduledCapsuleListEl.append(this.scheduledTabContentView.el);
      if (this.infoBoxViewForScheduledCapsuleTab) {
        this.$el.find("#myScheduledCapsulesTab").append(this.infoBoxViewForScheduledCapsuleTab.el);
      }
    }

    this.$el.page();

    //manually adding the wrapper necessary by JQM for showing Panel
    //JQM does it on the fly but that can cause flickering on the screen when it is done by jquery mobile
    //the workaround is to do that manually when the page is getting initialized
    this.$el
      .children(".ui-header:not(.ui-header-fixed), .ui-content:not(.ui-popup), .ui-footer:not(.ui-footer-fixed)")
      .wrapAll("<div class='ui-panel-wrapper'></div>");

    this.$el.on("pageshow", this.onPageBeforeShow.bind(this));
    this.$el.on("pagebeforehide", this.onPageBeforeHide.bind(this));
    if (LocalStorageHelper.getInstance().isUserHasCapsuleSchedulingFeature()) {
      this.$el.addClass("showScheduleCapsules");
    }
    this.showApplicableHeaderButtons();
    this._onViewportHeightChanged(PersistentModels.getInstance().getRouter().getViewportHeight(), true);
    return this;
  },
});

export default MyLibraryPageView;
