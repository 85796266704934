import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import TagTemplate from 'text!../../../templates/common/tagTemplate.html';
import RSVP from 'rsvp';

const TAG_VIEW_SELECTED_CLASSNAME = "selected"
const TagItemView = Backbone.View.extend({



    initialize : function(options) {
        this.compiledTemlate =  _.template(TagTemplate);
        this.model = options? options.model : null;
        //this._selected = options? options.selected : false;
        this._doNotShowHashTag = options? options.doNotShowHashTag: false;
            
    },
    
    events : {
        "click": "onClick",
    },

    onClick : function(){
        this.toggleSelected();
    },
    
    toggleSelected : function(silent){
        this.setSelected(!this.isSelected(),silent);
    },
    
    setSelected : function(selected,silent){
        if(this.model){
            this.model.set("selected",selected);
            if(this.$el){
                if(selected){
                    this.$el.addClass(TAG_VIEW_SELECTED_CLASSNAME);
                }else{
                    this.$el.removeClass(TAG_VIEW_SELECTED_CLASSNAME);
                }
            }
        }
        if(!silent){
            this.trigger("selectionChange",this.model, selected);
        }
        
    },
    
    isSelected : function(){
        const selected = (this.model && this.model.get("selected"))?this.model.get("selected") : false;
        return selected;
    },
    
    render : function() {
        const templateParams = {};
        const tagJson  = this.model.toJSON();
        templateParams.label = tagJson.label["EN"];
        templateParams.subLabel = tagJson.subLabel? tagJson.subLabel["EN"]: null;
        templateParams.hasSubLabel = templateParams.subLabel? true: false;
        templateParams.tagId = tagJson.id;
        templateParams.selected = (tagJson && tagJson.selected)? tagJson.selected: false;
        templateParams.selectedClassName = TAG_VIEW_SELECTED_CLASSNAME;
        templateParams.showHashTag = !this._doNotShowHashTag;
        this.$el.html(this.compiledTemlate(templateParams));
        this.setElement(this.$el.find("div").first());
        return this;
    }

});
export default TagItemView;