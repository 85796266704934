import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import ShareTrebblePopupView from "views/shareTrebble/ShareTrebblePopupView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import ti18n from "i18n!nls/shareTrebblePopupi18n";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";
import FacebookHelper from "models/helper/FacebookHelper";

const ShareTrebblePopupController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.model = options.trebblePlaylistSummary;
        this._trebbleId = this.model.getLoaderKey();
        this.context = options.context;
        this._trebbleShareableLink = null;
        this._amazonFlashBriefingRssLink = null;
        this._trebbleSubscribePageLink = null;
        this._googleHomeAndAmazonEchoShareableLink = null;
        this._trebbleWidgetLandscapeLink = null;
        this._trebbleWidgetPortraitLink = null;
        this._initializeLinks(this._trebbleId);
        _.extend(this, Backbone.Events);
    },

    _initializeLinks : function(trebbleId){
        this._googleHomeAndAmazonEchoShareableLink = window.getI18n(ti18n,"YOU_CAN_LISTEN_TO") +" \""+this.model.getName()+"\" "+  window.getI18n(ti18n,"ON_MY_DEVICE_BY_SAYING") +" \""+ window.getI18n(ti18n,"ASK_TREBBLE_TO_PLAY_CAPSULES_FROM") +" "+this.model.getName()+" \". "+ window.getI18n(ti18n,"IF_YOU_NEVER_USED_TREBBLE_FOLLOW_THIS_URL") +" https://www.amazon.com/gp/product/B075XDJTS7";
        this._googleAssistantUniqueTrebbleShareableLink = "https://s.trebble.fm/tg/"+trebbleId;
        this._trebbleShareableLink = "https://s.trebble.fm/p/p/t/"+trebbleId+"?t="+ new Date().getTime();
        this._amazonFlashBriefingRssLink = "https://s.trebble.fm/getShortcast/"+trebbleId+"/flashbriefing.json";
        this._googleNewsFeedRssLink = "https://s.trebble.fm/getShortcast/"+trebbleId+"/googleNews.rss";
        this._trebbleSubscribePageLink = "https://s.trebble.fm/"+(this.model.getCustomURL ?this.model.getCustomURL(): this.model._customURL);
        this._trebbleWidgetLandscapeLink = '<iframe src="https://web.trebble.fm/trebble_embedded_optimized.html#p/l/t/'+trebbleId+'" width="400" height="150" frameBorder="0" allowtransparency="true" allowfullscreen style="box-shadow:rgba(0, 0, 0, 0.6) 0px 0px 50px"></iframe>';
        this._trebbleWidgetPortraitLink = '<iframe src="https://web.trebble.fm/trebble_embedded_optimized.html#p/l/t/'+trebbleId+'" width="300" height="400" frameBorder="0" allowtransparency="true" allowfullscreen style="box-shadow:rgba(0, 0, 0, 0.6) 0px 0px 50px"></iframe>';
        this._trebbleFooterAlwaysVisibleScript = '<script type="text/javascript">function trebbleLoadScript(a,b){const c=document.getElementsByTagName("head")[0],d=document.createElement("script");d.type="text/javascript",d.src=a,d.onreadystatechange=b,d.onload=b,c.appendChild(d)}const TREBBLE_INSTALL_OPTIONS={trebbleId:"'+trebbleId+'",alwaysVisibible:!0,widgetPlaceholderBackgroundColor:"#333333"};trebbleLoadScript("https://web.trebble.fm/js/addTrebbleWidgetToSite.js");</script>'
    },

    _copyToClipboard : function(toCopy, confirmMessage){
        if('cordova' in window && window.cordova && window.cordova.plugins && window.cordova.plugins.clipboard ){
            window.cordova.plugins.clipboard.copy(toCopy);
            Utils.getInstance().showShortToastMessage(confirmMessage);
        }else{
            const isCopySuccessfull = Utils.getInstance().copyTextToClipboard(toCopy);
            if (!window.waitForCordovaToLoad && !isCopySuccessfull) {
                navigator.trebbleNotification.alert(window.getI18n(ti18n, "PLEASE_PRESS_CNTR_C_TO_COPY"));
            }else{
                Utils.getInstance().showShortToastMessage(confirmMessage);
            }
        }
    },

    _shareNatively : function(toShare,url, title){
        if (navigator.share) {
            navigator.share({
                'title': title,
                text: toShare,
                url: url
            });
        }
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    copyShareableLinkToClipboard : function(toCopy){
        this._copyToClipboard(toCopy, window.getI18n(ti18n,"SHAREABLE_LINK_COPIED_TO_CLIPBOARD"));
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Trebble", "TrebbleShareLinkCopied", "Trebble Share Link Copied");
        } 
        
    },

    previewLink: function(linkToPreview){
        const openedWindow = window.open(linkToPreview , "_blank");
        if((!openedWindow || openedWindow.closed || typeof openedWindow.closed=='undefined') && !window.waitForCordovaToLoad){
            window.alertErrorMessage(window.getI18n(ti18n,"PLEASE_ALLOW_POPUPS"));
        }
        return openedWindow;
    },

    copyWidgetLandscapeLinkToClipboard : function(toCopy){
        this._copyToClipboard(toCopy, window.getI18n(ti18n,"EMBED_CODE_COPIED_TO_CLIPBOARD"));
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Trebble", "TrebbleEmbedCodeCopied", "Trebble Embed Code Landscape Copied");
        } 
    },


    copyWidgetPortraitLinkToClipboard : function(toCopy){
        this._copyToClipboard(toCopy, window.getI18n(ti18n,"EMBED_CODE_COPIED_TO_CLIPBOARD"));
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Trebble", "TrebbleEmbedCodeCopied", "Trebble Embed Portrait Code Copied");
        } 
    },

    copyTrebbleFooterPlayerEmbedScriptToClipboard : function(toCopy){
        this._copyToClipboard(toCopy, window.getI18n(ti18n,"SCRIPT_TO_EMBED_PLAYER_AT_BOTTOM_COPIED_TO_CLIPBOARD"));
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Trebble", "TrebbleEmbedCodeCopied", "Trebble Footer Player Embed Code Copied");
        } 
    },

    shareNativelyShareableLinkToClipboard : function(toShare, text){
        this._shareNatively(text, toShare,window.getI18n(ti18n,"TREBBLE_URL"));
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Trebble", "TrebbleShareLinkSharedNatively", "Trebble Share Link Shared Natively");
        }
        
    },

    shareNativelyWidgetLandscapeLinkToClipboard : function(toShare){
        this._shareNatively(toShare, null, window.getI18n(ti18n,"TREBBLE_EMBED_CODE_LANDSCAPE"));
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Trebble", "TrebbleEmbedCodeSharedNatively", "Trebble Embed Code Landscape Shared Natively");
        }
    },


    shareNativelyWidgetPortraitLinkToClipboard : function(toShare){
        this._shareNatively(toShare, null, window.getI18n(ti18n,"TREBBLE_EMBED_PORTRAIT_CODE"));
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Trebble", "TrebbleEmbedCodeSharedNatively", "Trebble Embed Portrait Code Shared Natively");
        } 
    },

    shareNativelyTrebbleFooterPlayerEmbedScriptToClipboard : function(toShare){
        this._shareNatively(toShare, null, window.getI18n(ti18n,"SCRIPT_TO_EMBED_PLAYER_AT_BOTTOM"));
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Trebble", "TrebbleEmbedCodeSharedNatively", "Trebble Footer Player Embed Code Shared Natively");
        } 
    },

    showPlayerLink : function(){
        this.view.showPlayerLink();
    },


    

    shareOnFacebook : function(toShare,quote){
        FacebookHelper.getInstance().showDialog({
            method: 'share',
            href: toShare,
            "quote": quote
        },{"entity":"Trebble", "eventName":"TrebbleShareFacebook", "eventLabel":"Trebble Info On Facebook"} ).catch(function(error){
            window.alertErrorMessage(error);
        });
        /*FB.ui({
            method: 'share',
            href : toShare,
            "quote": quote
        }, function(response){
            if(response && response.error_message){
                window.alertErrorMessage(response.error_message);
            }else{
                if(window.trebbleAnalyticsHelper){
                    window.trebbleAnalyticsHelper.trackEvent("Trebble", "TrebbleShareFacebook", "Trebble URL Shared On Facebook");
                } 
            }
        });*/
},

shareOnTwitter : function(toShare, text){
const w = 550;
const h = 285;
const left = window.getLeftPositionForPopupWindow(w);
const top = window.getTopPositionForPopupWindow(h);
let twitterUrlIntent = "https://twitter.com/intent/tweet?via=trebblefm"
if(toShare){
twitterUrlIntent = twitterUrlIntent +"&url="+encodeURIComponent(toShare);
}
if(text){
twitterUrlIntent = twitterUrlIntent +"&text="+text;
}

const openedWindow = window.open(twitterUrlIntent,"Twitter", "resizable=1,height="+h+",width="+w+",top="+top+",left="+left+"");
if((!openedWindow || openedWindow.closed || typeof openedWindow.closed=='undefined') && !window.waitForCordovaToLoad){
window.alertErrorMessage(window.getI18n(ti18n,"PLEASE_ALLOW_POPUPS"));
}
if(window.trebbleAnalyticsHelper){
window.trebbleAnalyticsHelper.trackEvent("Trebble", "TrebbleShareTwitter", "Trebble URL Shared On Twitter");
} 
},




buildModelAndRenderView : function() {
return new RSVP.Promise((function(resolve, reject) {
try {

    const params = {};
    params.trebbleId = this._trebbleId;
    params.trebbleShareableLink = this._trebbleShareableLink;
    params.shareableLinkHasAShortcast = true;
    params.amazonFlashBriefingRssLink = this._amazonFlashBriefingRssLink;
    params.canGetFlashBriefingRssLink = this.model.canBeEdited();
    params.trebbleSubscribePageLink = this._trebbleSubscribePageLink;
    params.googleHomeAndAmazonEchoShareableLink = this._googleHomeAndAmazonEchoShareableLink;
    params.trebbleWidgetLandscapeLink = this._trebbleWidgetLandscapeLink;
    params.trebbleWidgetPortraitLink = this._trebbleWidgetPortraitLink;
    params.trebbleFooterAlwaysVisibleScript = this._trebbleFooterAlwaysVisibleScript;
    params.showPlayerFooterAlwaysVisibleOption = true;
    params.shareOnSmartSpeakers = true;
    params.shareSubscribePage = true;
    params.canEmbedLandscapePlayer = true;
    params.canEmbedPortraitPlayer = true;
    params.canCopyEmbeddedWidgetLink = this.model.canBeEdited();
    this.view = new ShareTrebblePopupView(params);
    this.listenTo(this.view, "preview",this.previewLink);
    this.listenTo(this.view, "copyLinkToClipboard",this.copyShareableLinkToClipboard);
    this.listenTo(this.view, "copyWidgetLandscapeToClipboard",this.copyWidgetLandscapeLinkToClipboard);
    this.listenTo(this.view, "copyWidgetPortraitToClipboard",this.copyWidgetPortraitLinkToClipboard);
    this.listenTo(this.view, "copyTrebbleFooterPlayerScriptToClipboard",this.copyTrebbleFooterPlayerEmbedScriptToClipboard);
    this.listenTo(this.view, "shareNativelyLinkToClipboard",this.shareNativelyShareableLinkToClipboard);
    this.listenTo(this.view, "shareNativelyWidgetLandscapeToClipboard",this.shareNativelyWidgetLandscapeLinkToClipboard);
    this.listenTo(this.view, "shareNativelyWidgetPortraitToClipboard",this.shareNativelyWidgetLandscapeLinkToClipboard)
    this.listenTo(this.view, "shareNativelyTrebbleFooterPlayerScriptToClipboard",this.shareNativelyTrebbleFooterPlayerEmbedScriptToClipboard);
    this.listenTo(this.view, "shareOnFacebookButtonClicked",this.shareOnFacebook);
    this.listenTo(this.view, "shareOnTwitterButtonClicked",this.shareOnTwitter);

    this.view.render();
    resolve();


} catch (error) {
    reject(error);
}

}).bind(this));

}

});

export default ShareTrebblePopupController;