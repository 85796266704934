import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import AudioSegment from "models/audioEditor/segments/AudioSegment";
import RSVP from "rsvp";

const CLASSNAME = "PauseAudioSegment";
const PauseAudioSegment =  AudioSegment.extend({
    idAttribute: "id",

    constructor: function(attributes, options) {
        AudioSegment.apply(this, [attributes, options]);
        this.set("type",PauseAudioSegment.CLASSNAME);
    },

    clone: function(){
        return new PauseAudioSegment(this.toJSON());
    },

    setEditable : function(isEditable){
        this.set("isEditable",isEditable);
    },


    isEditable : function(){
        return this.get("isEditable");
    },

    setExplicitlyDisplayed : function(explicitlyDisplayed){
        this.set("explicitlyDisplayed",explicitlyDisplayed);
    },

    getContent: function(addSpaceAroundContent){
        if(addSpaceAroundContent){
            return `<span style="font-weight: bold;" class="pause_node" duration_under_ten_milli="${ this.getDuration() <= 10?'true':'false'}" > · </span>`;
        }else{
            return ` <span style="font-weight: bold;" class="pause_node" duration_under_ten_milli="${ this.getDuration() <= 10?'true':'false'}" >·</span> `; 
        }
    },

    isExplicitlyDisplayed : function(){
        return this.get("explicitlyDisplayed");
    },



});


PauseAudioSegment.CLASSNAME = CLASSNAME;
export default PauseAudioSegment; 