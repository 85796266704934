import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import FileUploadHelper from "models/helper/FileUploadHelper";
import SoundManagerMedia from "models/helper/SoundManagerMedia";
import SoundNativeAudio from "models/helper/SoundNativeAudio";
import Recorder from "libs/browserRecorder/recorderNew";
import TrebbleRecorder from "models/helper/TrebbleRecorder";
import FeatureRolloutHelper from "models/helper/FeatureRolloutHelper";
import PersistentModels from "services/PersistentModels";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import Utils from "models/helper/Utils";
import ti18n from "i18n!nls/helperi18n";
import RSVP from "rsvp";
import unmute from "libs/unmute/unmute";

const USE_CORDOVA_MEDIA_FOR_BACKGROUND = FeatureRolloutHelper.getInstance().getFeatureVariable(FeatureRolloutHelper.FEATURES.ALLOW_USE_MUSIC_WHILE_RECORDING_CAPSULE_ON_MOBILE, FeatureRolloutHelper.FEATURES.ALLOW_USE_MUSIC_WHILE_RECORDING_CAPSULE_ON_MOBILE.variables.use_cordova_media, false);
const USE_NATIVE_AUDIO_FOR_BACKGROUND = FeatureRolloutHelper.getInstance().getFeatureVariable(FeatureRolloutHelper.FEATURES.ALLOW_USE_MUSIC_WHILE_RECORDING_CAPSULE_ON_MOBILE, FeatureRolloutHelper.FEATURES.ALLOW_USE_MUSIC_WHILE_RECORDING_CAPSULE_ON_MOBILE.variables.use_native_audio, true);
let INTRO_IN_SEC = FeatureRolloutHelper.getInstance().getFeatureVariable(FeatureRolloutHelper.FEATURES.CAPSULE_RECORDING_UI_DEFAULT_CONFIG, FeatureRolloutHelper.FEATURES.CAPSULE_RECORDING_UI_DEFAULT_CONFIG.variables.background_music_intro_length_in_sec, null);
let FADE_OUT_END_IN_SEC = FeatureRolloutHelper.getInstance().getFeatureVariable(FeatureRolloutHelper.FEATURES.CAPSULE_RECORDING_UI_DEFAULT_CONFIG, FeatureRolloutHelper.FEATURES.CAPSULE_RECORDING_UI_DEFAULT_CONFIG.variables.background_music_fade_out_end_in_sec, null);
let INTRO_FADE_IN_SEC = FeatureRolloutHelper.getInstance().getFeatureVariable(FeatureRolloutHelper.FEATURES.CAPSULE_RECORDING_UI_DEFAULT_CONFIG, FeatureRolloutHelper.FEATURES.CAPSULE_RECORDING_UI_DEFAULT_CONFIG.variables.background_fade_in_outro_in_sec, null);
let OUTRO_FADE_IN_SEC = FeatureRolloutHelper.getInstance().getFeatureVariable(FeatureRolloutHelper.FEATURES.CAPSULE_RECORDING_UI_DEFAULT_CONFIG, FeatureRolloutHelper.FEATURES.CAPSULE_RECORDING_UI_DEFAULT_CONFIG.variables.background_fade_out_intro_in_sec, null);
const BACKGROUND_VOLUME_LEVEL = FeatureRolloutHelper.getInstance().getFeatureVariable(FeatureRolloutHelper.FEATURES.CAPSULE_RECORDING_UI_DEFAULT_CONFIG, FeatureRolloutHelper.FEATURES.CAPSULE_RECORDING_UI_DEFAULT_CONFIG.variables.background_volume_level, 50);
const FADE_INCREMENT = FeatureRolloutHelper.getInstance().getFeatureVariable(FeatureRolloutHelper.FEATURES.CAPSULE_RECORDING_UI_DEFAULT_CONFIG, FeatureRolloutHelper.FEATURES.CAPSULE_RECORDING_UI_DEFAULT_CONFIG.variables.fade_increment, null);
const BACKGROUND_BUFFER_AT_THE_END_IN_SEC = FeatureRolloutHelper.getInstance().getFeatureVariable(FeatureRolloutHelper.FEATURES.CAPSULE_RECORDING_UI_DEFAULT_CONFIG, FeatureRolloutHelper.FEATURES.CAPSULE_RECORDING_UI_DEFAULT_CONFIG.variables.background_music_end_buffer_in_sec, 2);
const ALWAYS_USE_MEDIA_RECORDERJS = FeatureRolloutHelper.getInstance().getFeatureVariable(FeatureRolloutHelper.FEATURES.CAPSULE_RECORDING_UI_DEFAULT_CONFIG, FeatureRolloutHelper.FEATURES.CAPSULE_RECORDING_UI_DEFAULT_CONFIG.variables.always_use_media_recordjs, false);
const AudioRecorderHelper = Backbone.Model.extend({


    initialize: function() {
        this.applyGetUserMediaPatchForOldBrowsers();
        this._recordMediaObjects = {};
        this._recordMediaFilenames = {};
        this._recordFilenameToBlob = {};
        this._isPlayingRecording = false;
        this._recordingFilenameInPlayQueue = [];
        this._backgroundTrackUrl = null;
        this._backgroundTrackMedia = null;
        this._playRecordingRequestId = null;
        this._backgroundTrackLoadingPromise = null;
        this._currentMediaPlaying = null;
        this._currentMediaPlayingStatus = null;
        this._onMediaSuccessfullyStoppedCBs = [];
        this._onMediaFailedBeingStoppedCBs = [];
        this._currentlyLoadedMediaNeedToBeStopped = false;
        this._userHasPermissionToRecordInThisSession = false;
        this._recordingPromise = null;
        this._isRecording = false;
        this.deleteAllBackgroundMusicDownloadedLocally();


    },

    getRecordFilenameToBlob: function() {
        return this._recordFilenameToBlob;
    },

    isAudioContextSuspended: function() {
        return (window._trebbleAudioContext && window._trebbleAudioContext.state === 'suspended');
    },

    resumeCurrentAudioContext: function() {
        return (window._trebbleAudioContext && window._trebbleAudioContext.state === 'suspended') ? window._trebbleAudioContext.resume() : RSVP.Promise.resolve();
    },

    getPermissionToRecord: function() {
        if (window.waitForCordovaToLoad) {
            return this.getPermissionToRecordOnNative();
        } else {
            return this.getPermissionToRecordOnWeb();
        }
    },

    getAudioDevicesAvailable : function(){
        return this.getPermissionToRecord().then((function(){
         if(navigator && navigator.mediaDevices && navigator.mediaDevices.enumerateDevices){
            return navigator.mediaDevices.enumerateDevices().then((function(devices){
                if(devices){
                    let defaultDevice = null;
                    const groupIdToDeviceArray =  {};
                    const inputDevices =  devices.filter(function(device){
                        const audioInput = device.kind === 'audioinput'? device: null;
                        
                        if(audioInput){
                            let deviceArray = groupIdToDeviceArray[audioInput.groupId];
                            if(!deviceArray){
                                deviceArray = [];
                                groupIdToDeviceArray[audioInput.groupId] = deviceArray;
                            }
                            deviceArray.push(audioInput);
                            if(audioInput && audioInput.deviceId === "default"){
                                defaultDevice = audioInput;
                            }
                        }

                        return audioInput;
                    });

                    if(defaultDevice && groupIdToDeviceArray[defaultDevice.groupId].length > 1){
                        inputDevices.splice(inputDevices.indexOf(defaultDevice), 1);
                    }
                    return inputDevices;
                }

            }).bind(this))
        }else{
            return RSVP.Promise.resovle();
        }
    }).bind(this))
        
    },

    getPermissionToRecordOnWeb: function() {
        let waitingForPermission = true;
        let popupMessageViewPromise = null;
        const showWaitPopupIfApplicable = function() {
            if (waitingForPermission && !Utils.getInstance().isJQMPopupOpen()) {
                //show wait popup
                const message = window.getI18n(ti18n, "WE_ARE_WAITING_FOR_PERMISSION_TO_RECORD");
                const subMessage = window.isMobileBrowser ? window.getI18n(ti18n, "PLEASE_FOLLOW_PHONE_INSTRUTION_FOR_PERMISSION") : window.getI18n(ti18n, "PLEASE_FOLLOW_BROWSER_INSTRUTION_FOR_PERMISSION");
                popupMessageViewPromise = PersistentModels.getInstance().getRouter().showMessagePopup(message, subMessage);
            }
        }
        const timeToWaitBeforeShowingWaitPopupInMillisec = 3000;


        setTimeout(showWaitPopupIfApplicable, timeToWaitBeforeShowingWaitPopupInMillisec);
        const preferredAudioDeviceId =  LocalStorageHelper.getInstance().getPreferredDeviceAudioId();
        const userMediaParams =  preferredAudioDeviceId?{ deviceId: preferredAudioDeviceId }: {};
        userMediaParams.echoCancellation = false;
        userMediaParams.noiseSuppression = false;
        userMediaParams.autoGainControl = false;
        userMediaParams.channelCount = 1;
        return navigator.mediaDevices.getUserMedia({
            audio: userMediaParams
        }).then(function(result) {
            waitingForPermission = false;
            if (popupMessageViewPromise) {
                popupMessageViewPromise.then(function(popupView) {
                    popupView.close()
                })
            }
            return result;

        }).catch(function(error) {
            waitingForPermission = false;
            throw error;
        });
    },

    getPermissionToRecordOnNative: function() {
        //return RSVP.Promise.resolve();

        return new RSVP.Promise((function(resolve, reject) {
            if (this._userHasPermissionToRecordInThisSession) {
                return resolve(true);
            } else {
                let waitingForPermission = true;
                let popupMessageViewPromise = null;
                const showWaitPopupIfApplicable = function() {
                    if (waitingForPermission) {
                        //show wait popup
                        const message = window.getI18n(ti18n, "WE_ARE_WAITING_FOR_PERMISSION_TO_RECORD");
                        const subMessage = window.isMobileBrowser ? window.getI18n(ti18n, "PLEASE_FOLLOW_PHONE_INSTRUTION_FOR_PERMISSION") : window.getI18n(ti18n, "PLEASE_FOLLOW_BROWSER_INSTRUTION_FOR_PERMISSION");
                        popupMessageViewPromise = PersistentModels.getInstance().getRouter().showMessagePopup(message, subMessage);
                    }
                }
                const timeToWaitBeforeShowingWaitPopupInMillisec = 3000;
                setTimeout(showWaitPopupIfApplicable, timeToWaitBeforeShowingWaitPopupInMillisec);

                this._recordingPromise = FileUploadHelper.getInstance().getPersistentFileSystemUrl().then((function(persitentFSUrl) {
                    const fileExtension = window.window.isIOSMobileDevice ? "wav" : "aac";
                    const fileName = Utils.getInstance().generateRandomId() + "." + fileExtension;
                    const filePrefix = persitentFSUrl;
                    const audioFileName = filePrefix + fileName;
                    const mediaRec = new window.Media(audioFileName,
                        function() {

                        },
                        function(err) {
                            waitingForPermission = false;
                            console.error(err);
                            reject(err);
                        }, (function(status) {
                            if (waitingForPermission && status == 2) {
                                waitingForPermission = false;
                                this._userHasPermissionToRecordInThisSession = true;
                                if (popupMessageViewPromise) {
                                    popupMessageViewPromise.then(function(popupView) {
                                        popupView.close()
                                    })
                                }
                                resolve();
                                mediaRec.stopRecord();
                                mediaRec.release();
                                FileUploadHelper.getInstance().deleteFileFromPersistentFileSystemUrl(fileName);
                            }
                        }).bind(this)
                        );
                    mediaRec.startRecord();
                }).bind(this));
            }
        }).bind(this))
    },


    _createNewAudioContext: function() {
        window.AudioContext = window.AudioContext || window.webkitAudioContext;
        window._trebbleAudioContext = new AudioContext();
        unmute(window._trebbleAudioContext);
        window._trebbleAudioContext._processorConfig = this._getRecorderProcessorDefaultConfig();
        window._trebbleAudioContext._processor = (window._trebbleAudioContext.createScriptProcessor ||
            window._trebbleAudioContext.createJavaScriptNode).call(window._trebbleAudioContext,
            window._trebbleAudioContext._processorConfig.bufferLen, window._trebbleAudioContext._processorConfig.numChannels, window._trebbleAudioContext._processorConfig.numChannels);
            window._trebbleAudioContext._processor.connect(window._trebbleAudioContext.destination);
            window._trebbleAudioContext.nodeAlreadyConnected = true;
        },

        _closeAudioContext: function() {
            if (window._trebbleAudioContext) {
                window._trebbleAudioContext.close();
                window._trebbleAudioContext._processor.disconnect();
                window._trebbleAudioContext = null;
            }
        },

        startRecording: function(fileName) {
            this._isRecording = true;
            if (window.device && window.device.platform == "browser" && !(window.trebble.useNativeRecording && "Media" in window)) {

                this._recordingPromise = this.getBrowserRecorder().then((function(recorderObj) {
                    recorderObj.record();
                    this._recordMediaObjects[fileName] = recorderObj;
                    return recorderObj;
        //this._recordMediaFilenames[fileName] = filePrefix + fileName;
    }).bind(this));
                return this._recordingPromise;

            } else {
                this._recordingPromise = FileUploadHelper.getInstance().getPersistentFileSystemUrl().then((function(persitentFSUrl) {
                    const filePrefix = /*(device.platform == "iOS" || window.isIOSMobileDevice)? "cdvfile://localhost/persistent/" : */ persitentFSUrl;
                    const mediaRec = new window.Media(filePrefix + fileName,
                        function() {

                        },
                        function(err) {

                        }
                        );
                    mediaRec.startRecord();
                    this._recordMediaObjects[fileName] = mediaRec;
                    this._recordMediaFilenames[fileName] = filePrefix + fileName;
                }).bind(this));
                return this._recordingPromise;
            }
        },

        getBrowserRecorderAndStartRecordingForiOSWeb: function(fileName) {
            this._isRecording = true;
            if (window.device && window.device.platform == "browser" && !(window.trebble.useNativeRecording && "Media" in window)) {

                this._recordingPromise = this.getBrowserRecorderAndStartRecord(fileName);
                return this._recordingPromise;

            } else {
                this._recordingPromise = FileUploadHelper.getInstance().getPersistentFileSystemUrl().then((function(persitentFSUrl) {
                    const filePrefix = /*(device.platform == "iOS" || window.isIOSMobileDevice)? "cdvfile://localhost/persistent/" :*/ persitentFSUrl;
                    const mediaRec = new window.Media(filePrefix + fileName,
                        function() {

                        },
                        function(err) {

                        }
                        );
                    mediaRec.startRecord();
                    this._recordMediaObjects[fileName] = mediaRec;
                    this._recordMediaFilenames[fileName] = filePrefix + fileName;
                }).bind(this));
                return this._recordingPromise;
            }
        },

        isAudioContextCreated: function() {
            return !!window._trebbleAudioContext;
        },


        getAudioContext : function(){
          if (!window._trebbleAudioContext) {
            this._createNewAudioContext();
        }
        return window._trebbleAudioContext;
    },

    getBrowserRecorder: function() {
        const warningMessage = this.getRecordingAlternativeMessageInstruction();
        try {


            window.URL = window.URL || window.webkitURL;
            if (!window._trebbleAudioContext) {
                this._createNewAudioContext();
            }
        } catch (e) {
            if (e && e.message) {
                console.error("Cannot get user media. Error:" + e.message);

            }
            return RSVP.Promise.reject(warningMessage);
        }

        return new RSVP.Promise((function(resolve, reject) {

            try {

                const startUserMedia = function(stream) {
            // window._browserAudioStream = stream;
            try {
                //  const input = window._trebbleAudioContext.createMediaStreamSource(stream);

                const recorder = new TrebbleRecorder({
                    "useMediaRecorderForAudioRecording": !ALWAYS_USE_MEDIA_RECORDERJS && ('MediaRecorder' in window && window.trebble.useMediaRecorderForAudioRecording),
                    "stream": stream,
                    "processor": window._trebbleAudioContext._processor,
                    "nodeAlreadyConnected": window._trebbleAudioContext.nodeAlreadyConnected
                });
                resolve(recorder);
            } catch (e) {
                reject(e);
            }
        }
        if (false && window._browserAudioStream) {
            startUserMedia(window._browserAudioStream.clone());
        } else {
            const preferredAudioDeviceId =  LocalStorageHelper.getInstance().getPreferredDeviceAudioId();
            const userMediaParams =  preferredAudioDeviceId?{ deviceId: preferredAudioDeviceId }: {};
            userMediaParams.echoCancellation = false;
            userMediaParams.noiseSuppression = false;
            navigator.mediaDevices.getUserMedia({
                audio: userMediaParams
            }).then(startUserMedia).catch(function(e) {
                if (e && e.message) {
                    console.error("Cannot get user media. Error:" + e.message);
                    //reject('Due to a new restriction added in Chrome, recording audio is not allowed. We are working on a solution. Please upload an audio file instead or another browser!');
                }
                reject(warningMessage);

            });
        }
    } catch (e) {
        if (e && e.message) {
            console.error("Cannot get user media. Error:" + e.message);

        }
        reject(warningMessage);
    }
}).bind(this));
    },

    _getRecorderProcessorDefaultConfig: function() {
        return {
            bufferLen: 4096,
            numChannels: 2
        };
    },

    getRecordingAlternativeMessageInstruction: function() {
        let warningMessage = window.getI18n(ti18n, "RECORD_USING_FAVORITE_SOFTWARE_THEN_UPLOAD_FROM_BROWSER");
        if (window.isMobileBrowser) {
            if (window.isSafariBrowser) {
                warningMessage = window.getI18n(ti18n, "RECORD_USING_VOICE_MEMO_APP_THEN_UPLOAD");
            } else {
                warningMessage = window.getI18n(ti18n, "RECORD_USING_FAVORITE_SOFTWARE_THEN_UPLOAD_FROM_DEVICE");
            }
        }
        return warningMessage;
    },

    getBrowserRecorderAndStartRecord: function(fileName) {
        const warningMessage = this.getRecordingAlternativeMessageInstruction();

        try {



            window.URL = window.URL || window.webkitURL;
            if (!window._trebbleAudioContext) {
    //CREATE A NEW AUDIO CONTEXT EVEN IF THERE IS ALREADY ONE
    this._createNewAudioContext();
}
} catch (e) {
if (e && e.message) {
    console.error("Cannot get user media. Error:" + e.message);

}
return RSVP.Promise.reject(warningMessage);
}

return new RSVP.Promise((function(resolve, reject) {

try {

    const startUserMedia = (function(stream) {
        // window._browserAudioStream = stream;
        try {
            // const input = window._trebbleAudioContext.createMediaStreamSource(stream);
            const recorder = new TrebbleRecorder({
                "useMediaRecorderForAudioRecording": ('MediaRecorder' in window && window.trebble.useMediaRecorderForAudioRecording),
                "stream": stream,
                "processor": window._trebbleAudioContext._processor,
                "nodeAlreadyConnected": window._trebbleAudioContext.nodeAlreadyConnected
            });
            recorder.record();
            this._recordMediaObjects[fileName] = recorder;
            resolve(recorder);
        } catch (e) {
            reject(e);
        }
    }).bind(this);
    //Always request get user media
    if (false && window._browserAudioStream) {
        startUserMedia(window._browserAudioStream.clone());
    } else {
        const preferredAudioDeviceId =  LocalStorageHelper.getInstance().getPreferredDeviceAudioId();
        const userMediaParams =  preferredAudioDeviceId?{ deviceId: preferredAudioDeviceId }: {};
        userMediaParams.echoCancellation = false;
        userMediaParams.noiseSuppression = false;
        navigator.mediaDevices.getUserMedia({
            audio: userMediaParams
        }).then(startUserMedia).catch(function(e) {
            if (e && e.message) {
                console.error("Cannot get user media. Error:" + e.message);
                //reject('Due to a new restriction added in Chrome, recording audio is not allowed. We are working on a solution. Please upload an audio file instead or another browser!');
            }
            reject(warningMessage);

        });
    }
} catch (e) {
    if (e && e.message) {
        console.error("Cannot get user media. Error:" + e.message);

    }
    reject(warningMessage);
}
}).bind(this));
},

applyGetUserMediaPatchForOldBrowsers: function() {
// Older browsers might not implement mediaDevices at all, so we set an empty object first
if (navigator.mediaDevices === undefined) {
navigator.mediaDevices = {};
}

// Some browsers partially implement mediaDevices. We can't just assign an object
// with getUserMedia as it would overwrite existing properties.
// Here, we will just add the getUserMedia property if it's missing.
if (navigator.mediaDevices.getUserMedia === undefined) {
navigator.mediaDevices.getUserMedia = function(constraints) {

    // First get ahold of the legacy getUserMedia, if present
    const getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

    // Some browsers just don't implement it - return a rejected promise with an error
    // to keep a consistent interface
    if (!getUserMedia) {
        return Promise.reject(new Error(window.getI18n(ti18n, "RECORDING_IS_NOT_SUPPORTED")));
    }

    // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
    return new Promise(function(resolve, reject) {
        getUserMedia.call(navigator, constraints, resolve, reject);
    });
}
}

},

initUserMedia: function() {
// FOR NOW THIS JSUT MAKE SURE AUDIOCONTEXT IS SUPPORTED
return RSVP.Promise.resolve(!!(window.AudioContext || window.webkitAudioContext));

},

isRecording: function() {
return this._isRecording;
},

stopRecording: function(fileName) {
this._isRecording = false;
let promiseWasCompleted =  false;
if (window.device && window.device.platform == "browser" && !(window.trebble.useNativeRecording && "Media" in window)) {
    let recorderObj = null;
return this._recordingPromise.then((function() {
    recorderObj = this._recordMediaObjects[fileName];
    if (recorderObj) {
        recorderObj.stop();
        this._closeAudioContext();
    }
    return this.stopPlayRecordingsIfApplicable();
}).bind(this)).then((function() {
    return new RSVP.Promise((function(resolve, reject) {
        recorderObj && recorderObj.exportWAV((function(blob) {
            if(!promiseWasCompleted){
                //This is done because it looks like this handler can be called multiple times
                promiseWasCompleted = true;

                if (blob && blob.size !== 0 && blob.size > 0) {
                    const url = URL.createObjectURL(blob);
                    try {
                        if (window.trebbleAnalyticsHelper) {
                            window.trebbleAnalyticsHelper.trackEvent("Capsule", "capsuleFinishedBrowserRecording", "Capsule Finish Browser Recording", blob.size);
                        }
                    } catch (error) {
                        console.error("There was an error logging capsuleFinishedRecording Infor. Error:" + error);
                    }
                    if (window.isMobileBrowser) {
                        //Workaround for https://github.com/scottschiller/SoundManager2/issues/100
                        //need to make an xhr request to get a new blob url
                        this.getNewBlobXHRUrl(url).then((function(xhrBlobUrl) {
                            this._recordFilenameToBlob[fileName] = blob;
                            this._recordMediaFilenames[fileName] = xhrBlobUrl;
                        }).bind(this)).catch(reject);
                    } else {
                        this._recordFilenameToBlob[fileName] = blob;
                        this._recordMediaFilenames[fileName] = url;
                        resolve();
                    }

                } else {
                    try {
                        if (window.trebbleAnalyticsHelper) {
                            window.trebbleAnalyticsHelper.trackEvent("Capsule", "errorOccuredDuringCapsuleRendering", "Error During Capsule Rendering", blob && blob.size);
                        }
                    } catch (error) {
                        console.error("There was an error logging than error occured during the capsule rendering. Error:" + error);
                    }
                    reject(window.getI18n(ti18n, "AN_ERROR_OCCURED_WHILE_RENDERING_YOUR_AUDIO_RECORDING"));
                }
            }
        }).bind(this));
        if (!recorderObj) {
            resolve();
        }
    }).bind(this));
}).bind(this));

} else {
return this._recordingPromise.then((function() {
    const mediaRec = this._recordMediaObjects[fileName];
    if (mediaRec) {
        mediaRec.stopRecord();
    }
//return this.stopPlayRecordingsIfApplicable();
}).bind(this));
}

},

getNewBlobXHRUrl: function(blobUrl) {
return new RSVP.Promise(function(resolve, reject) {
let xhrBlobUrl;

const xhr = new XMLHttpRequest();
xhr.open('GET', blobUrl, true);
xhr.responseType = 'blob';
xhr.overrideMimeType('audio/wav');

xhr.onreadystatechange = function() {
    window.log(xhr);
    if (xhr.readyState === 4 && xhr.status == 200) {
        xhrBlobUrl = window.URL.createObjectURL(xhr.response);
        resolve(xhrBlobUrl);
    } else {
        if (xhr.status >= 500) {
            reject(window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_PROCESSING_YOUR_RECORDING"));
        }
    }
};
xhr.onerror = reject;
xhr.send();
});
},

    deleteRecording: function (fileName)
    {
        if (window.device && window.device.platform == "browser" && !(window.trebble.useNativeRecording && "Media" in window))
        {
            return (this._recordingPromise ? this._recordingPromise : RSVP.Promise.resolve()).then((function ()
            {
                const recorderObj = this._recordMediaObjects[ fileName ];
                if (recorderObj)
                {
                    recorderObj.clear();
                }
                delete this._recordMediaObjects[fileName];
                delete this._recordMediaFilenames[fileName];
                delete this._recordFilenameToBlob[fileName];

            }).bind(this));
        } else
        {
            (this._recordingPromise ? this._recordingPromise : RSVP.Promise.resolve()).then((function ()
            {
                const recorderObj = this._recordMediaObjects[ fileName ];
                if (recorderObj)
                {
                    recorderObj.release();
                }
                delete this._recordMediaObjects[fileName];
                delete this._recordMediaFilenames[fileName];
                delete this._recordFilenameToBlob[fileName];

            }).bind(this));
            return FileUploadHelper.getInstance().deleteFileFromPersistentFileSystemUrl(fileName);
        }
    },


playRecordings: function(arrayOfRecordingNames, backgroundTrackUrl, backgroundTrackLoadingHandler, playRecordingRequestId) {
if (window.device && window.device.platform == "browser" && !(window.trebble.useNativeRecording && "Media" in window)) {
return this.stopPlayRecordingsIfApplicable().then((function() {
    this._recordingFilenameInPlayQueue = [];
    for (const index in arrayOfRecordingNames) {
        const recordingName = arrayOfRecordingNames[index];
        const recordingFilename = this._recordMediaFilenames[recordingName];
        const recorderObj = this._recordMediaObjects[recordingName];
        if (recorderObj) {
            this._recordingFilenameInPlayQueue.push(recordingFilename);
        }
    }
    return this._playQueue(backgroundTrackUrl, backgroundTrackLoadingHandler, playRecordingRequestId);
}).bind(this));
} else {
return this.stopPlayRecordingsIfApplicable().then((function() {
    this._recordingFilenameInPlayQueue = [];
    for (const index in arrayOfRecordingNames) {
        const recordingName = arrayOfRecordingNames[index];
        const recordingFilename = this._recordMediaFilenames[recordingName];
        const media = this._recordMediaObjects[recordingName];
        if (media) {
            this._recordingFilenameInPlayQueue.push(recordingFilename);
        }
    }
    return this._playQueue(backgroundTrackUrl, backgroundTrackLoadingHandler, playRecordingRequestId);
}).bind(this));
}

},

_stopAndDestroyBackgroundTrack: function() {
if (this._backgroundTrackMedia && this._backgroundTrackMedia._completelyLoaded) {
this._backgroundTrackMedia.stop();
this._backgroundTrackMedia.release();
}
this._backgroundTrackMedia = null;
this._backgroundTrackUrl = null;
this._playRecordingRequestId = null;
this._backgroundTrackLoadingPromise = null;

},

_downloadBackgroundTrackLocallyIfApplicable: function(backgroundTrackUrl){
return (this.useCordovaMediaObjectForBackgroundMusic() && (USE_NATIVE_AUDIO_FOR_BACKGROUND && window.isIOSMobileDevice) && backgroundTrackUrl)? FileUploadHelper.getInstance().downloadFileLocallyTemporary(backgroundTrackUrl): RSVP.Promise.resolve(backgroundTrackUrl);
},

_loadBackgroundTrackIfApplicable: function(backgroundTrackUrl, requestId) {
window.log("About to start loading background track for url " + backgroundTrackUrl);
if (!backgroundTrackUrl || backgroundTrackUrl !== this._backgroundTrackUrl) {
this._stopAndDestroyBackgroundTrack();

}
this._backgroundTrackUrl = backgroundTrackUrl;
if (!this._backgroundTrackUrl || (this._backgroundTrackMedia && this._backgroundTrackMedia._completelyLoaded)) {
window.log("About to return from loading background track for url " + backgroundTrackUrl);
if (this._backgroundTrackMedia) {
    window.log("Looks like the background audio is already loaded for url " + backgroundTrackUrl);
    const startVolume = this.useCordovaMediaObjectForBackgroundMusic() ? 1 : 100;
    this._backgroundTrackMedia.setVolume(startVolume);
    this._backgroundTrackMedia.seekTo(0);
}
return RSVP.Promise.resolve();
} else {
if (this._backgroundTrackMedia && this._backgroundTrackLoadingPromise) {
    window.log("Looks like the  background track for url " + backgroundTrackUrl + " is loading");
    return this._backgroundTrackLoadingPromise;
}
const p = new RSVP.Promise((function(resolve, reject) {
    let backgroundTrackMedia = null;
    const onBackgroundTrackMediaSuccessPlay = (function() {
        window.log("Successfully finish playing background track ");
    }).bind(this);
    const onBackgroundTrackMediaFailedPlaying = (function(err) {
        window.log("onBackgroundTrackMediaFailedPlaying called for" + backgroundTrackUrl);
        if (this._backgroundTrackLoadingPromise === p) {
            window.log("Error occured playing background track. Error:" + err);
            reject(err);
        }
    }).bind(this);
    const onBackgroundTrackMediaStatusChanged = (function(mediaStatus) {
        window.log("onBackgroundTrackMediaStatusChanged called for" + backgroundTrackUrl);
        if (this._backgroundTrackLoadingPromise === p) {
            window.log("Media status for background track is " + mediaStatus);
            window.log("Media status changed for " + backgroundTrackUrl + " and it is the current background audio");
        }
    }).bind(this);
    const onMediaReadyToPlay = (function() {
        window.log("onMediaReadyToPlay called for" + backgroundTrackUrl);
        if (this.useCordovaMediaObjectForBackgroundMusic() || this._backgroundTrackLoadingPromise === p) {
            //background promise still active
            window.log("background track is ready to play");
            window.log("background track is ready to play changed for " + backgroundTrackUrl);
            resolve();
        }
    }).bind(this);
    if (window.device && window.device.platform == "browser" && !(window.trebble.useNativeRecording && "Media" in window)) {
        backgroundTrackMedia = this._createSoundManagerMedia(this._backgroundTrackUrl, onBackgroundTrackMediaSuccessPlay, onBackgroundTrackMediaFailedPlaying, onBackgroundTrackMediaStatusChanged, onMediaReadyToPlay);
    } else {
        if (this.useCordovaMediaObjectForBackgroundMusic()) {
            backgroundTrackMedia = this._createCordovaMedia(this._backgroundTrackUrl, onBackgroundTrackMediaSuccessPlay, onBackgroundTrackMediaFailedPlaying, onBackgroundTrackMediaStatusChanged, onMediaReadyToPlay);
        } else {

            backgroundTrackMedia = this._createSoundManagerMedia(this._backgroundTrackUrl, onBackgroundTrackMediaSuccessPlay, onBackgroundTrackMediaFailedPlaying, onBackgroundTrackMediaStatusChanged, onMediaReadyToPlay);
        }
    }
    this._backgroundTrackMedia = backgroundTrackMedia;
    window.log("Background audio media was created for " + backgroundTrackUrl);

}).bind(this));
this._backgroundTrackLoadingPromise = p;
p.then((function() {
    this._backgroundTrackMedia._completelyLoaded = true;
    this._backgroundTrackLoadingPromise = null;
    window.log("Background audio media for " + backgroundTrackUrl + " is loaded and ready");
}).bind(this));
return p;
}

},

/*
_getLocalMediaAssetUrl: function(webUrl) {
const TREBBLE_URL_PREFIX = "web.trebble.fm/";
return webUrl.substring(webUrl.indexOf(TREBBLE_URL_PREFIX) + TREBBLE_URL_PREFIX.length);
},
*/

deleteAllBackgroundMusicDownloadedLocally: function(){
FileUploadHelper.getInstance().deleteAllTemporaryDownloadedFiles();
},


_getLocalMediaAssetUrl : function(remoteURL){
return FileUploadHelper.getInstance().getLocalFileURLDownloadedTemporary(remoteURL);
},

_createCordovaMedia: function(audioUrl, onSuccess, onError, onStatus, onMediaReadyToPlay) {
    let backgroundTrackMedia;
if (USE_NATIVE_AUDIO_FOR_BACKGROUND && window.isIOSMobileDevice) {
const soundNativeAudioMediaParams = {};
soundNativeAudioMediaParams.uri = this._getLocalMediaAssetUrl(audioUrl);
soundNativeAudioMediaParams.onSuccess = onSuccess;
soundNativeAudioMediaParams.onError = onError;
soundNativeAudioMediaParams.onStatus = onStatus;
soundNativeAudioMediaParams.onMediaReadyToPlay = onMediaReadyToPlay;
backgroundTrackMedia = new SoundNativeAudio(soundNativeAudioMediaParams);
backgroundTrackMedia.setLooping(true);
return backgroundTrackMedia
} else {
     backgroundTrackMedia = new window.Media(audioUrl, onSuccess, onError, onStatus);
backgroundTrackMedia.successCallback.media = backgroundTrackMedia;
backgroundTrackMedia.errorCallback.media = backgroundTrackMedia;
backgroundTrackMedia.statusCallback.media = backgroundTrackMedia;
onMediaReadyToPlay();
return backgroundTrackMedia;
}
},

_createSoundManagerMedia: function(audioUrl, onSuccess, onError, onStatus, onMediaReadyToPlay) {
const soundManagerMediaParams = {};
soundManagerMediaParams.uri = audioUrl;
soundManagerMediaParams.onSuccess = onSuccess;
soundManagerMediaParams.onError = onError;
soundManagerMediaParams.onStatus = onStatus;
soundManagerMediaParams.onMediaReadyToPlay = onMediaReadyToPlay;
soundManagerMediaParams.autoLoad = true;
const soundManagerMedia =  new SoundManagerMedia(soundManagerMediaParams);
soundManagerMedia.setLooping(true);
return soundManagerMedia;
},

useCordovaMediaObjectForBackgroundMusic: function() {
return (USE_CORDOVA_MEDIA_FOR_BACKGROUND || USE_NATIVE_AUDIO_FOR_BACKGROUND) && window.waitForCordovaToLoad;
},

_playBackgroundTrackIfApplicable: function() {
window.log('Attempt to play background music');
if (this._backgroundTrackMedia && this._backgroundTrackMedia._completelyLoaded) {
//this._backgroundTrackMedia.stop();
window.log('Playing background music');
this._backgroundTrackMedia.play({ numberOfLoops: 9999 });// We are using this to make sure that the content loops until the audio recording is done playing (Supported by Cordova Media only)
}
},

isSameRequestIdMatching: function(requestIdToTestAgainst) {
return !requestIdToTestAgainst || this._playRecordingRequestId == requestIdToTestAgainst;
},

_stopBackgroundTrackIfApplicable: function(requestId) {
if (this._backgroundTrackMedia && this._backgroundTrackMedia._completelyLoaded) {
if (this.isSameRequestIdMatching(requestId)) {
    this._backgroundTrackMedia.stop();
}
}
},



_playQueue: function(backgroundTrackUrl, backgroundTrackLoadingHandler, playRecordingRequestId) {
if (this._recordingFilenameInPlayQueue.length > 0) {
if (backgroundTrackLoadingHandler) {
    backgroundTrackLoadingHandler(true);
}
window.log("About to start playing queue with url " + backgroundTrackUrl);
return this._downloadBackgroundTrackLocallyIfApplicable(backgroundTrackUrl).then((function(){
    return this._loadBackgroundTrackIfApplicable(backgroundTrackUrl, playRecordingRequestId)
}).bind(this)).then((function() {
    if (backgroundTrackLoadingHandler) {
        backgroundTrackLoadingHandler(false);
    }
    window.log("_loadBackgroundTrackIfApplicable for " + backgroundTrackUrl + " completed ");
    return new RSVP.Promise((function(resolve, reject) {

        const filenameToPlay = this._recordingFilenameInPlayQueue[0];
        window.log("About to load media file " + filenameToPlay);
        let mediaToPlay = null;
        const onMediaSuccessPlay = (function() {
            window.log("media with background " + backgroundTrackUrl + "finished playing");
            if (this.isSameRequestIdMatching(playRecordingRequestId)) {
                if (this._currentMediaPlaying && this._currentMediaPlaying.release) {
                    this._currentMediaPlaying.release();
                }
                this._recordingFilenameInPlayQueue.splice(0, 1);
                if (this._recordingFilenameInPlayQueue.length > 0) {
                    window.log("There is more to play so going to the next audio with background " + backgroundTrackUrl);
                    this._stopBackgroundTrackIfApplicable(playRecordingRequestId);
                    this._playQueue(backgroundTrackUrl, backgroundTrackLoadingHandler, playRecordingRequestId).then(resolve).catch(reject);
                } else {
                    if (this.isSameRequestIdMatching(playRecordingRequestId)) {
                        //This might be different if the user changed the background music
                        window.log("Done playing so fading out the background music " + backgroundTrackUrl);
                        this.fadeOutroAndEndBackground(resolve, reject, playRecordingRequestId);
                    } else {
                        window.log("Done playing  background music " + backgroundTrackUrl + "and returning");
                        resolve();
                    }
                }
            }
        }).bind(this);
        const onMediaFailedPlaying = (function(err) {
            window.log("There was an error for media " + filenameToPlay + " with  background music " + backgroundTrackUrl);
            console.error(err);
            this._currentMediaPlayingStatus = null;
            this._stopBackgroundTrackIfApplicable(playRecordingRequestId);
            if (true) {
//Ignore any playback error
window.log("We will ignore playback error for media " + filenameToPlay + " with  background music " + backgroundTrackUrl);
resolve();
} else {
window.log("Throwing the playback error for media " + filenameToPlay + " with  background music " + backgroundTrackUrl);
reject(err);
}
}).bind(this);
        const onMediaStatusChanged = this._onMediaStatusChanged.bind(this);
        if (window.device && window.device.platform == "browser" && !(window.trebble.useNativeRecording && "Media" in window)) {
            const soundManagerMediaParams = {};
            soundManagerMediaParams.uri = filenameToPlay;
            soundManagerMediaParams.onSuccess = onMediaSuccessPlay;
            soundManagerMediaParams.onError = onMediaFailedPlaying;
            soundManagerMediaParams.onStatus = onMediaStatusChanged;
            mediaToPlay = new SoundManagerMedia(soundManagerMediaParams);
        } else {
            mediaToPlay = new window.Media(filenameToPlay, onMediaSuccessPlay, onMediaFailedPlaying, onMediaStatusChanged);
        }
        this._currentMediaPlaying = mediaToPlay;
        this._playRecordingRequestId = playRecordingRequestId;
        this._currentMediaPlayingStatus = 0;
        window.log(" media " + filenameToPlay + " created with  background music " + backgroundTrackUrl);

        this._playBackgroundTrackIfApplicable();
        this._isPlayingRecording = true;
        if (backgroundTrackUrl) {
            if (!INTRO_IN_SEC) {
                INTRO_IN_SEC = 0
            }
            const backgroundTrackMedia = this._backgroundTrackMedia;
            setTimeout((function() {
                if (this.isSameRequestIdMatching(playRecordingRequestId)) {
                    window.log("About to start fading music " + backgroundTrackUrl);
                    this.fadeIntroBackgroundMusic(backgroundTrackMedia, playRecordingRequestId).then((function() {
                        window.log("Fade in for " + backgroundTrackUrl + " completed so about to start media playback");
                        if (this.isSameRequestIdMatching(playRecordingRequestId)) {
                            window.log("Fade in for " + backgroundTrackUrl + " completed  and  starting media playback");
                            if (!this._currentlyLoadedMediaNeedToBeStopped) {
                                mediaToPlay.play();
                            } else {
                                window.log("Current media needed to be stopped so we didn't play it!");
                                this._currentlyLoadedMediaNeedToBeStopped = false;
                                this._stopAndDestroyRecordingMedia(true);
                            }
                        } else {
                            window.log("Stopping background music and cancelling mediaPlay which was planned...");
            //this.stopPlayRecordingsIfApplicable(playRecordingRequestId);
            this._currentlyLoadedMediaNeedToBeStopped = false;
            this._stopAndDestroyRecordingMedia(true);
        }
    }).bind(this));
                } else {
                    window.log("marked current media playing stopped since a new request has been made");
                    this._currentlyLoadedMediaNeedToBeStopped = false;
                    this._stopAndDestroyRecordingMedia(true);
//if urls are different, this means that the user might have change the background music
//In that case, do nothing
}
}).bind(this), INTRO_IN_SEC * 1000);
        } else {
            mediaToPlay.play();
            window.log("Playing media with no background ");
        }



    }).bind(this));
}).bind(this)).catch((function(error) {
console.error(error);
window.alertErrorMessage(window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_PLAYING_YOUR_CAPSULE"));
}).bind(this));
} else {
this._isPlayingRecording = false;
this._currentMediaPlaying = null;
this._currentMediaPlayingStatus = null;
return RSVP.Promise.resolve();
}
},

fadeIntroBackgroundMusic: function(backgroundTrackMediaToFadeIn, requestId) {
if (backgroundTrackMediaToFadeIn) {
if (!INTRO_FADE_IN_SEC) {
    INTRO_FADE_IN_SEC = 0
}
window.log("fadeIntroBackgroundMusic executing...")
if (INTRO_FADE_IN_SEC > 0) {
    let startVolume = this.useCordovaMediaObjectForBackgroundMusic() ? 1.0 : 100;
    let endVolume = this.useCordovaMediaObjectForBackgroundMusic() ? BACKGROUND_VOLUME_LEVEL / 100 : BACKGROUND_VOLUME_LEVEL;
    let increment = this.useCordovaMediaObjectForBackgroundMusic() ? FADE_INCREMENT / 100 : FADE_INCREMENT;
    return Utils.getInstance().fadeMediaTo(backgroundTrackMediaToFadeIn, INTRO_FADE_IN_SEC * 1000, startVolume, endVolume, increment)
} else {
    let endVolume = this.useCordovaMediaObjectForBackgroundMusic() ? BACKGROUND_VOLUME_LEVEL / 100 : BACKGROUND_VOLUME_LEVEL;
    backgroundTrackMediaToFadeIn.setVolume(endVolume);
    return RSVP.Promise.resolve();
}
} else {
window.log("fadeIntroBackgroundMusic skipped")
return RSVP.Promise.resolve();
}
},

fadeOutroBackgroundMusic: function() {
if (this._backgroundTrackMedia) {
if (!OUTRO_FADE_IN_SEC) {
    OUTRO_FADE_IN_SEC = 0
}
window.log("fadeOutroBackgroundMusic executing...")
if (OUTRO_FADE_IN_SEC > 0) {
    let endVolume = this.useCordovaMediaObjectForBackgroundMusic() ? 1.0 : 100;
    let startVolume = this.useCordovaMediaObjectForBackgroundMusic() ? BACKGROUND_VOLUME_LEVEL / 100 : BACKGROUND_VOLUME_LEVEL;
    let increment = this.useCordovaMediaObjectForBackgroundMusic() ? FADE_INCREMENT / 100 : FADE_INCREMENT;
    return Utils.getInstance().fadeMediaTo(this._backgroundTrackMedia, OUTRO_FADE_IN_SEC * 1000, startVolume, endVolume, increment)
} else {
    let endVolume = this.useCordovaMediaObjectForBackgroundMusic() ? 1.0 : 100;
    this._backgroundTrackMedia.setVolume(endVolume);
    return RSVP.Promise.resolve();
}
} else {
window.log("fadeOutroBackgroundMusic skipped")
return RSVP.Promise.resolve();
}
},

endBackgroundMusicWithFadeOut: function(requestId) {
if (this._backgroundTrackMedia && this.isSameRequestIdMatching(requestId)) {
if (!FADE_OUT_END_IN_SEC) {
    FADE_OUT_END_IN_SEC = 0
}
window.log("endBackgroundMusicWithFadeOut executing...")
if (OUTRO_FADE_IN_SEC > 0) {
    const startVolume = this.useCordovaMediaObjectForBackgroundMusic() ? 1.0 : 100;
    const endVolume = 0;
    const increment = this.useCordovaMediaObjectForBackgroundMusic() ? FADE_INCREMENT / 100 : FADE_INCREMENT;
    return Utils.getInstance().fadeMediaTo(this._backgroundTrackMedia, FADE_OUT_END_IN_SEC * 1000, startVolume, endVolume, increment)
} else {
    this._backgroundTrackMedia.setVolume(0);
    return RSVP.Promise.resolve();
}
} else {
window.log("endBackgroundMusicWithFadeOut skipped")
return RSVP.Promise.resolve();
}
},

fadeOutroAndEndBackground: function(resolve, reject, requestId) {
window.log("fadeOutroAndEndBackground executing for " + requestId + " ...")
return this.fadeOutroBackgroundMusic().then((function() {
return new RSVP.Promise((function(resolve, reject) {
    setTimeout(resolve, BACKGROUND_BUFFER_AT_THE_END_IN_SEC * 1000);
}).bind(this))
}).bind(this)).then((function() {
if (this.isSameRequestIdMatching(requestId)) {
    return this.endBackgroundMusicWithFadeOut(requestId);
} else {
    window.log("ignore endBackgroundMusicWithFadeOut commmand for " + requestId + " ...")
}
}).bind(this)).then((function() {
if (this.isSameRequestIdMatching(requestId)) {
    return this._stopBackgroundTrackIfApplicable(requestId);
} else {
    window.log("ignore _stopBackgroundTrackIfApplicable commmand for " + requestId + " ...")
}
}).bind(this)).then(resolve).catch(reject);
},

_stopAndDestroyRecordingMedia: function(releaseOnly) {
try {
if (this._currentMediaPlaying) {
    if (!releaseOnly) {
        this._currentMediaPlaying.stop();
    }
    this._currentMediaPlaying.release();
}

this._isPlayingRecording = false;
this._currentMediaPlaying = null;
for (let i in this._onMediaSuccessfullyStoppedCBs) {
    let cb = this._onMediaSuccessfullyStoppedCBs[i];
    cb();
}
this._onMediaSuccessfullyStoppedCBs = [];
this._onMediaFailedBeingStoppedCBs = [];
this._currentMediaPlayingStatus = null;
window.log("Media was stopped and destroy .");
} catch (error) {
for (let i in this._onMediaFailedBeingStoppedCBs) {
    let cb = this._onMediaFailedBeingStoppedCBs[i];
    cb(error);
}
console.error("Something went wrong when stopping media .Error: " + error);
}
},

_onMediaStatusChanged: function(mediaStatus) {
window.log("Media status change " + mediaStatus);
this._currentMediaPlayingStatus = mediaStatus;
if (this._currentlyLoadedMediaNeedToBeStopped) {
window.log("Media need to stopped so stopping ...  Media status: " + mediaStatus);
this._currentlyLoadedMediaNeedToBeStopped = false;
const isPlaying = mediaStatus == 2;
this._stopAndDestroyRecordingMedia(!isPlaying)

}
},

_onSongReadyToBeStopped: function() {
if (this._currentMediaPlayingStatus != 0) {
if (this._currentlyLoadedMediaNeedToBeStopped) {
    this._currentMediaPlaying.stop();
    this._isPlayingRecording = false;
    this._currentMediaPlaying = null;
    this._currentlyLoadedMediaNeedToBeStopped = false;
    for (const i in this._onMediaSuccessfullyStoppedCBs) {
        const cb = this._onMediaSuccessfullyStoppedCBs[i];
        cb();
    }
    this._onMediaSuccessfullyStoppedCBs = [];
    this._onMediaFailedBeingStoppedCBs = [];
    this._onMediaSuccessfullyStoppedCBs = [];
    this._onMediaFailedBeingStoppedCBs = [];
    this._currentMediaPlayingStatus = null;
    //notify all listeners
}
}
},


isPlayingRecording: function() {
return this._isPlayingRecording;
},

stopPlayRecordingsIfApplicable: function() {
window.log(" stopPlayRecordingsIfApplicable  executing ...");
//if (this._isPlayingRecording) {
this._stopAndDestroyBackgroundTrack();
if (this._currentMediaPlayingStatus == 0) {
    window.log(" stopPlayRecordingsIfApplicable  waiting for current media status to change to stop");
    return new RSVP.Promise((function(resolve, reject) {
        this._onMediaSuccessfullyStoppedCBs.push(resolve);
        this._onMediaFailedBeingStoppedCBs.push(reject);
        this._currentlyLoadedMediaNeedToBeStopped = true;
    }).bind(this)).then((function() {
        /*if(this._currentMediaPlaying ){
          this._currentMediaPlaying.pause();
          this._currentMediaPlaying.release? this._currentMediaPlaying.release(): null;
      }
      this._isPlayingRecording = false;
      this._currentMediaPlaying = null ;
      this._currentMediaPlayingStatus = null ;*/
      this._stopAndDestroyRecordingMedia();
  }).bind(this));
} else {
    /*if(this._currentMediaPlaying){
      this._currentMediaPlaying.stop();
      this._currentMediaPlaying.release? this._currentMediaPlaying.release(): null;
  }
  this._isPlayingRecording = false;
  this._currentMediaPlaying = null ;
  this._currentMediaPlayingStatus = null ;*/
  this._stopAndDestroyRecordingMedia();
  window.log(" stopPlayRecordingsIfApplicable  stopped right away");
  return RSVP.Promise.resolve();
}
/*} else {
  console.error(" stopPlayRecordingsIfApplicable  nothing playing so go ahead");
  this._stopBackgroundTrackIfApplicable(); 
  return RSVP.Promise.resolve();
}*/
},

});

const AudioRecorderHelperInstance = new AudioRecorderHelper();

export default {
    getInstance: function() {
        return AudioRecorderHelperInstance;
    }
};