
import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import SoundEnhancerSettingsView from "views/audioEditor/SoundEnhancerSettingsView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const SoundEnhancerSettingsController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.model =  options.sequencer;
        _.extend(this, Backbone.Events);
        
    },


    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    onVoiceEnhancerChange: function(){
        this.model.setSequencerSettings(this.model.getSequencerSettings().getChangeableSettings().VOICE_ENHANCER_ON, this.view.isVoiceEnhancerOn());
    },

    onMusicEnhancerChange: function(){
        this.model.setSequencerSettings(this.model.getSequencerSettings().getChangeableSettings().MUSIC_ENHANCER_ON, this.view.isMusicEnhancerOn());
    },

    onSoundEnhancerChange: function(){
        this.model.setSequencerSettings(this.model.getSequencerSettings().getChangeableSettings().SOUND_FX_ENHANCER_ON, this.view.isSoundEnhancerOn());
    },


    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {

                const params = {};
                params.model = this.model.getSequencerSettings();
                this.view = new SoundEnhancerSettingsView(params);
                this.view.render();

                this.listenTo(this.view, "voiceEnhancerChange", this.onVoiceEnhancerChange);
                this.listenTo(this.view, "musicEnhancerChange", this.onMusicEnhancerChange);
                this.listenTo(this.view, "soundEnhancerChange", this.onSoundEnhancerChange);

                resolve();

            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default SoundEnhancerSettingsController;