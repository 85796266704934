import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import CustomSearchFieldView from "views/common/CustomSearchFieldView";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import Sortable from "libs/sortablejs/Sortable";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import ti18n from 'i18n!nls/MyJourneysPagei18n';
import pageTemplate from 'text!../../../templates/myJourneys/MyJourneysPageTemplate.html';

const Rollout = RolloutHelper.getInstance();


const MyJourneysPageView = Backbone.View.extend({

    template : _.template(pageTemplate),

    events : {
        "click #homeHeaderButton" : "onHomeButtonSelected",
        "click #helpHeaderButton" : "onHelpHeaderButtonClicked",
        "click  #createJourneyBtn":"onCreateJourneyBtnSeclected",

    },

    initialize : function(options) {
        
        this.myJourneysListView = options.myJourneysListView;
        this._isPageDisplayed = false;
        this._onKeyUpInSearchFieldHandler = options.onKeyUpInSearchFieldHandler;
        this._onSearchFieldClearedHandler = options.onSearchFieldClearedHandler;
        this._scrollPositionSaved = null;
        PersistentModels.getInstance().getRouter().registerForViewportHeightChange("myJourneysPageView",this._onViewportHeightChanged.bind(this));
    },
    _onViewportHeightChanged : function(newViewPortHeigh, forceResizing) {
        if(PersistentModels.getInstance().getRouter().isEqualToCurrentPageUrl("#myJourneys") || forceResizing){
            if (this.el) {
                //const tabHeight = 46;
                //const adjustedListContainerHeight = (newViewPortHeigh - tabHeight ) ;
                
                if(this.journeyList$el){
                    this.journeyList$el.css("height", newViewPortHeigh + "px")
                }
                if(this.$placeHolderMyFeedListEl){
                    this.$placeHolderMyFeedListEl.css("height", newViewPortHeigh + "px")
                }

            }
        }
    },

    loadImages : function(){
        this.$el.find("#journeyList #collection_repeat_container").trigger("scrollstop");
    },

    
    isFeedTabSelected: function(){
        return this.selectedTab == "feedTab";
    },

    filterList : function(){
        this.trigger("filter");
    },

    onCreateJourneyBtnSeclected : function() {
        this.trigger("createJourney");
    },


    onHomeButtonSelected: function(){
        this.trigger("showHomePopup");
    },

    onHelpHeaderButtonClicked : function(){
        this.trigger("showHelpPage");
    },

    saveScrollPosition : function(){
        this._scrollPositionSaved = this.journeyList$el.get(0).scrollTop;
    },

    restoreScrollPosition : function(){
        if(this._scrollPositionSaved){
            this.journeyList$el.get(0).scrollTop = this._scrollPositionSaved;
        }

    },


    _buildSearchHeaderButtonAndToPage: function(){
        const customSearchFieldViewParams = {};
        customSearchFieldViewParams.searchFieldPlaceholderSelector = "#pageHeaderTitleWrapper";
        customSearchFieldViewParams.searchFieldPlaceholderSelectorRootNodeEl  = this.el;
        this.customSearchFieldView = new CustomSearchFieldView(customSearchFieldViewParams);
        this.customSearchFieldView.render();
        this.customSearchFieldView.addKeyUpHandlerFunction(this._onKeyUpInSearchField.bind(this));
        this.customSearchFieldView.addOnSearchFieldClearedFunction(this._onSearchFieldCleared.bind(this));
        this.customSearchFieldView.addOnScreenResizeHandlerFunction(this._resizeSearchField.bind(this))
        this.$el.find(".rightHeaderButtonGroup").prepend(this.customSearchFieldView.el);
    },

    getSearchFieldValue : function(){
        return this.customSearchFieldView.getSearchFieldValue();
    },

    _resizeSearchField : function(searchField){
        const homeButtonWidth = 61;
        const searchButtonWidth = 56;
        const identifyButtonWidth = 56;
        const newSearchFieldWidth = (window.getDocumentClienWidth() - homeButtonWidth - searchButtonWidth - identifyButtonWidth) + "px";
        searchField.width(newSearchFieldWidth);
    },
    _onKeyUpInSearchField : function(searchFieldValue){
        if(this._onKeyUpInSearchFieldHandler){
            this._onKeyUpInSearchFieldHandler(searchFieldValue);
        }
    },


    _onSearchFieldCleared : function(){
        if(this._onSearchFieldClearedHandler){
            this._onSearchFieldClearedHandler();
        }
    },


    onPageBeforeShow : function(){

        this._isPageDisplayed = true;
        this.trigger("pageBeforeShow");
    },

    isPageDisplayed : function(){
        return this._isPageDisplayed;
    },	


    onPageBeforeHide : function(){
        this._isPageDisplayed = false;
    },


    



    setSearchValue : function(newSearchValue){
        this.customSearchFieldView.setSearchFieldValue(newSearchValue, true);
    },

    

    setLoadingJourneys : function(loadingSubscriptions){
        this._loadingSubscriptions =  loadingSubscriptions;
        if(this._loadingSubscriptions){
            if(!this.$el.hasClass("loading_journeys")){
                this.$el.addClass("loading_journeys");
            }
        }else{
            this.$el.removeClass("loading_journeys");
        }
    },

    setNoJourneys : function(noSubscription){
        this._noSubscription =  noSubscription;
        if(this._noSubscription){
            if(!this.$el.hasClass("no_journey")){
                this.$el.addClass("no_journey");
            }
        }else{
            this.$el.removeClass("no_journey");
        }
    },

    hasNoJourneys : function(){
        return this._noSubscription;
    },

    render : function() {
        this.$el.html(this.template({"ti18n": ti18n}));
        this.setElement(this.$el.find("div").first());
        //this._buildSearchHeaderButtonAndToPage();
        
        
        
        this.$placeHolderMyJourneysListEl = this.$el.find("#myJourneysListWrapper").first();
        this.journeyList$el = this.$el.find("#journeyList");
        this.$placeHolderMyJourneysListEl.append(this.myJourneysListView.el);

        this.$el.page();



        Utils.getInstance().initJqueryLazyLoadingPluginOnElemNew(this.$el.find("#journeyList #collection_repeat_container"), "img.lazy", this.$el.find("#journeyList"));


        //manually adding the wrapper necessary by JQM for showing Panel
        //JQM does it on the fly but that can cause flickering on the screen when it is done by jquery mobile
        //the workaround is to do that manually when the page is getting initialized
        this.$el.children( ".ui-header:not(.ui-header-fixed), .ui-content:not(.ui-popup), .ui-footer:not(.ui-footer-fixed)" ).wrapAll( "<div class='ui-panel-wrapper'></div>" )

        this.$el.on("pageshow", this.onPageBeforeShow.bind(this));
        this.$el.on("pagebeforehide", this.onPageBeforeHide.bind(this));
        
        

        this._onViewportHeightChanged(PersistentModels.getInstance().getRouter().getViewportHeight(), true);
        return this;
    }

});

export default MyJourneysPageView;