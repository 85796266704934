
import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import AudioFilter from "models/audioEditor/filters/AudioFilter";
import RSVP from "rsvp";

const PRESETS ={};

const PRESET_IDS = {
    "GENERIC_VOICE_EQ":"genericVoiceEq",
    "GENERIC_VOICE_EQ_V1":"genericVoiceEqV1",
    "GENERIC_VOICE_EQ_V2":"genericVoiceEqV2",
    "GENERIC_VOICE_EQ_V3":"genericVoiceEqV3",
};

const createParametricEqFilterConfigPreset = function(highpassFilterParams, lowpassFilterParams, peakFiltersParamsArray){
    return {"highpassFilterParams": highpassFilterParams, "lowpassFilterParams": lowpassFilterParams, "peakFiltersParamsArray": peakFiltersParamsArray};
};
const createNodeParams = function(freq, Q , gain){
    return {frequency: freq, gain: gain, Q:Q};
};



PRESETS[PRESET_IDS.GENERIC_VOICE_EQ_V1] = createParametricEqFilterConfigPreset(createNodeParams(70, 0.71), null, 
[createNodeParams(71,1,0),createNodeParams(144,1.6,4), createNodeParams(450,0.48,-8), 
createNodeParams(1140,1.60,-3), createNodeParams(6300,0.64,0),createNodeParams(7000,0.60,4)]);//For voice peaking -12db high bass old

PRESETS[PRESET_IDS.GENERIC_VOICE_EQ_V2] = createParametricEqFilterConfigPreset(createNodeParams(70, 0.71), null, 
[createNodeParams(75,1,0),createNodeParams(95,1.6,4), createNodeParams(450,0.48,-8), 
createNodeParams(1140,1.60,-3), createNodeParams(6300,0.64,0),createNodeParams(7000,0.60,4)]);//For voice peaking -12db high bass

PRESETS[PRESET_IDS.GENERIC_VOICE_EQ_V3] = createParametricEqFilterConfigPreset(createNodeParams(70, 0.71), null, 
[createNodeParams(75,1,0),createNodeParams(95,1.6,2), createNodeParams(450,0.48,-8), 
createNodeParams(1140,1.60,-3), createNodeParams(6300,0.64,0),createNodeParams(7000,0.60,4)]);//For voice peaking -12db low bass


PRESETS[PRESET_IDS.GENERIC_VOICE_EQ] = PRESETS[PRESET_IDS.GENERIC_VOICE_EQ_V3];

const CLASSNAME = "ParametricEqFilter";

const ParametricEqFilter =  AudioFilter.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        AudioFilter.apply(this, [attributes, options]);
        this.setPresetIdToConfigMap(PRESETS);
        this.set("type", ParametricEqFilter.CLASSNAME);
        
    },

    
    setLowpassFilterParams : function(lowpassFilterParams){
        this.set("lowpassFilterParams",lowpassFilterParams);
    },


    getLowpassFilterParams : function(){
        return this.get("lowpassFilterParams");
    },

    setHighpassFilterParams : function(highpassFilterParams){
        this.set("highpassFilterParams",highpassFilterParams);
    },


    getHighpassFilterParams : function(){
        return this.get("highpassFilterParams");
    },

    setPeakFiltersParamsArray : function(peakFiltersParamsArray){
        this.set("peakFiltersParamsArray",peakFiltersParamsArray);
    },

    getPeakFiltersParamsArray : function(){
        return this.get("peakFiltersParamsArray");
    },


    isEqual : function(filter){
        if(filter instanceof ParametricEqFilter){
            return (this.isDisabled() == filter.isDisabled() && this.setLowpassFilterParams() === filter.setLowpassFilterParams() && this.getHighpassFilterParams() === filter.getHighpassFilterParams() && this.getPeakFiltersParamsArray() === filter.getPeakFiltersParamsArray() ) 
        }
        return false;
    },

    clone : function(){
        const cloneFilter = new ParametricEqFilter();
        cloneFilter.setDisabled(this.isDisabled());
        cloneFilter.setLowpassFilterParams(this.getLowpassFilterParams());
        cloneFilter.setHighpassFilterParams(this.getHighpassFilterParams());
        cloneFilter.setPeakFiltersParamsArray(this.getPeakFiltersParamsArray());
        return cloneFilter;
    },

    needToPrepareAudioContext: function(){
        return false;
    },

    prepareAudioContext: function(audioContext){
        return RSVP.Promise.resolve(audioContext);
    },

    applyFilterToNode : function(audioNode, audioContext, arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime, sequencerRendererTracksInfo, createdFilterNode ){
        if(!this.isDisabled()){
            const highpassFilterParams = this.getHighpassFilterParams();
            const lowpassFilterParams = this.getLowpassFilterParams();
            const peakFiltersParamsArray = this.getPeakFiltersParamsArray();

            let lastNodeCreated =  audioNode;

            if(highpassFilterParams){
                const highShelf = audioContext.createBiquadFilter();
                highShelf.type = "highpass";
                highShelf.frequency.value = highpassFilterParams.frequency
                highShelf.Q.value = highpassFilterParams.Q;
                lastNodeCreated.connect(highShelf);
                if(arrayOfAudioNodesUsed){
                    arrayOfAudioNodesUsed.push(highShelf);
                }
                lastNodeCreated =  highShelf;
            }

            if(peakFiltersParamsArray && peakFiltersParamsArray.length > 0){
                for(let i in peakFiltersParamsArray){
                    const peakFilterParams  = peakFiltersParamsArray[i];
                    const peakFilter = audioContext.createBiquadFilter();
                    if(i === 0){
                        peakFilter.type = "lowshelf";
                    }else{
                        if(i + 1 === peakFiltersParamsArray.length){
                            peakFilter.type = "highshelf";
                        }else{
                            peakFilter.type = "peaking";
                        }
                    }
                
                peakFilter.frequency.value = peakFilterParams.frequency
                peakFilter.gain.value = peakFilterParams.gain;
                peakFilter.Q.value = peakFilterParams.Q;
                lastNodeCreated.connect(peakFilter);
                if(arrayOfAudioNodesUsed){
                    arrayOfAudioNodesUsed.push(peakFilter);
                }
                lastNodeCreated =  peakFilter;

                }

            }

            if(lowpassFilterParams){
                const lowshelf = audioContext.createBiquadFilter();
                lowshelf.type = "lowpass";
                lowshelf.frequency.value = lowpassFilterParams.frequency
                lowshelf.Q.value = lowpassFilterParams.Q;
                lastNodeCreated.connect(lowshelf);
                if(arrayOfAudioNodesUsed){
                    arrayOfAudioNodesUsed.push(lowshelf);
                }
                lastNodeCreated =  lowshelf;
            }
            
            return lastNodeCreated;

        }else{
            return audioNode;
        }
    },

    applyFilter : function(previousOutputNode, audioContext, arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime, sequencerRendererTracksInfo, audioBuffer){
        if(!this.isDisabled()){
            if(!previousOutputNode){
                previousOutputNode = audioContext.createBufferSource();
                previousOutputNode.buffer = audioBuffer;
                if(arrayOfAudioNodesUsed){
                    arrayOfAudioNodesUsed.push(previousOutputNode);
                }
            }
            return RSVP.Promise.resolve(this.applyFilterToNode(previousOutputNode, audioContext,arrayOfAudioNodesUsed, audioBufferStartTime,audioBufferEndTime, sequencerRendererTracksInfo, null));
        }else{
            return RSVP.Promise.resolve(previousOutputNode);
        }
    },


});

ParametricEqFilter.PRESET_IDS = PRESET_IDS;
ParametricEqFilter.PRESETS = PRESETS;
ParametricEqFilter.CLASSNAME = CLASSNAME;

export default ParametricEqFilter; 