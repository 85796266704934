import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import OptionPopupView from "views/common/OptionPopupView";
import popupTemplate from 'text!../../../templates/user/UserOptionContent.html';
import ti18n from "i18n!nls/UserProfileDetailsPagei18n";

const UserOptionView = OptionPopupView.extend({

    


    initialize : function(options) {
        const viewOptions =  {};
        this._userModel = options.model;
        this.elementSource$el = options.elementSource$el;
        const contentView = {};
        const contentViewTemplate = _.template(popupTemplate);
        const templateParams = {"model" : this._userModel, "ti18n": ti18n};
        contentView.$el  = $(contentViewTemplate(templateParams));
        viewOptions.contentView = contentView;
        viewOptions.contentPosition = "center";
        viewOptions.elementSource$el = this.elementSource$el;
        viewOptions.showAsMenuIfInBrowser = true;
        viewOptions.hideHeading = true;
        viewOptions.id = "UserOptionPopup";
        OptionPopupView.prototype.initialize.call(this, viewOptions);
    },
    
    afterRendering : function(){
        this.$el.find("#editDetailsBtn").click(this._onEditDetailsButtonClicked.bind(this));
        this.$el.find("#followBtn").click(this._onFollowButtonClicked.bind(this));
        this.$el.find("#UnfollowBtn").click(this._onUnfollowButtonClicked.bind(this));
        this.$el.find("#updateCoverArtBtn").click(this._onUpdateCoverArtButtonClicked.bind(this));
        this.$el.find("#changePasswordBtn").click(this._onChangePasswordButtonClicked.bind(this));
        this.$el.find("#logOutBtn").click(this._logOut.bind(this));
    },

    _logOut : function(){
        this.trigger("logOut", this._userModel);
        this.close();
    },
    
    _onUpdateCoverArtButtonClicked :function(){
        this.trigger("updateProfileImage", this._userModel);
        this.close();
    },

    _onChangePasswordButtonClicked :function(){
        this.trigger("changePassword", this._userModel);
        this.close();
    },
    
    _onFollowButtonClicked :function(){
        this.trigger("follow", this._userModel);
        this.close();
    },
    
    _onUnfollowButtonClicked :function(){
        this.trigger("unfollow", this._userModel);
        this.close();
    },
    
    _onEditDetailsButtonClicked : function(){
        this.trigger("showEditUserDetails", this._userModel);
        this.close();
    },
    

    onAfterPageShow : function(){

    },



});

export default UserOptionView;