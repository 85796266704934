import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import SequencerNodeMoreOptionView from "views/audioEditor/SequencerNodeMoreOptionView";
import SequencerNodeMoreOptionContentView from "views/audioEditor/SequencerNodeMoreOptionContentView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import ti18n from "i18n!nls/Sequenceri18n";
import RSVP from "rsvp";

const SequencerNodeMoreOptionController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.model = options? options.sequencerController : null;
        
        this.context = options? options.context : null;
        this.elementSource$el = options? options.elementSource$el : null;
        this._popupPosition = options? options.popupPosition: null;
        this._onMenuClosedHandler = options? options.onMenuClose: null;
        this._onMenuItemClickedHandler = options? options.onMenuItemClicked: null;
        _.extend(this, Backbone.Events);
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    cutAndCopySelectionToClipboard : function(){
        if(this._onMenuItemClickedHandler){
            this._onMenuItemClickedHandler();
        }
        this.close();
        return this.model.cutAndCopySelectionToClipboard()
    },

    copySelectionToClipboard : function(){
        if(this._onMenuItemClickedHandler){
            this._onMenuItemClickedHandler();
        }
        this.close();
        return this.model.copySelectionToClipboard()
    },

    paste : function(){
        if(this._onMenuItemClickedHandler){
            this._onMenuItemClickedHandler();
        }
        this.close();
        return this.model.pasteClipboardBeforeSelection()
    },

    clip : function(){
        if(this._onMenuItemClickedHandler){
            this._onMenuItemClickedHandler();
        }
        this.close();
        return this.model.saveAsClip()
    },

    download: function(){
        if(this._onMenuItemClickedHandler){
            this._onMenuItemClickedHandler();
        }
        this.close();
        return this.model.downloadSelectionAsClip()
    },

    close: function(){
        return this.view.close();
    },

    onMenuClosed: function(){
        if(this._onMenuClosedHandler){
            this._onMenuClosedHandler();
        }
    },

    onCorrectTranscript : function(){
        this.close();
        return this.model.showContextMenuForCorrectingTranscriptsIfApplicable();
    },

    onDeleteAllExceptSelectedNodes : function(){
        this.close();
        return this.model.deleteAllExceptSelectedNodes();
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                const contentParams = {
                    "model" : this.model,
                    "elementSource$el": this.elementSource$el,
                    "clippingAvailable": this.model.isClippingActionAvailable(),
                    "cutAvailable": this.model.isCutActionAvailable(),
                    "copyAvailable": this.model.isCopyActionAvailable(), 
                    "pasteAvailable": this.model.isPasteActionAvailable(),
                    "transcriptCorrectionAvailable": this.model.isTranscriptCorrectionAvailable(),
                    "downloadClipAvailble": this.model.isDownloadClipAvailable(),
                    "popupPosition": this._popupPosition
                };
                const contentView = new SequencerNodeMoreOptionContentView(contentParams);
                contentView.render();
                this.listenTo(contentView, "cutAndCopySelectionToClipboard",this.cutAndCopySelectionToClipboard);
                this.listenTo(contentView, "copySelectionToClipboard", this.copySelectionToClipboard);
                this.listenTo(contentView, "paste",this.paste);
                this.listenTo(contentView, "clip",this.clip);
                this.listenTo(contentView, "download",this.download);
                this.listenTo(contentView, "correctTranscript", this.onCorrectTranscript);
                this.listenTo(contentView, "deleteAllExceptSelectedNodes", this.onDeleteAllExceptSelectedNodes)
                if(window.isMobileBrowser){
                    //const bottomSheet = Utils.getInstance().createBottomSheet(this._sequencerNodeInspectorSectionController.getView().$el.get(0), resolve, this._onPopupClosed.bind(this));
                    const onBottomSheetDisplayed = function(){

                    }
                    const bottomSheet = Utils.getInstance().createBottomSheet(contentView.$el.get(0), onBottomSheetDisplayed, this.onMenuClosed.bind(this), true);
                    bottomSheet.activate();
                    bottomSheet.close = function(){
                        bottomSheet.deactivate();
                    }
                    this.view = bottomSheet;
                    resolve();
                }else{
                    contentParams.contentView = contentView;
                    this.view = new SequencerNodeMoreOptionView(contentParams);
                    this.listenTo(this.view, "closed", this.onMenuClosed);
                    this.view.render();
                    resolve();
                }
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default SequencerNodeMoreOptionController;