import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import BasicInspectorNodeParamTemplate from 'text!../../../../../templates/audioEditor/sequencerNodeInspectorSection/BasicInspectorNodeParamTemplate.html';
import RSVP from 'rsvp';


const BasicInspectorNodeParamView = Backbone.View.extend({



    constructor : function(attributes, options) {
        Backbone.View.apply(this, [attributes, options]);
        this.compiledTemlate =  _.template(BasicInspectorNodeParamTemplate);
        this._label = null;
        this._value = null;
    },

    getLabel : function(label){
        return this._label;
    },

    setLabel : function(label){
        this._label = label;
        if(this.$el){
            this.$el.find(".param_label").html(label);
        }
    },
    
    getValue : function(){
        return this._value;
    },

    getValueString: function(){
        return this._value;
    },

    isDisabled: function(){
        return this._disabled;
    },

    setDisabled : function(disabled){
        this._disabled = disabled;
    },

    setValue : function(value, silent){
        this._value = value;
        this.updateViewOnValueChange(value);
        if(!silent){
            this.trigger("valueChanged", value);
        }
    },

    updateViewOnValueChange : function(newValue){
        throw new Error("Need to be implemented in subclass!");
    },


    onViewValueChanged : function(changedValue){
        this._value = changedValue;
        this.trigger("valueChanged", changedValue);
    },

    

    render : function(){
        this.$el.html(this.compiledTemlate({"label": this.getLabel() }));
        this.setElement(this.$el.find(".basic_inpector_param_view").first());
    }
    

});
export default BasicInspectorNodeParamView;