import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import TrebbleAudio from "models/TrebbleAudio";

const TrebbleVideo =  TrebbleAudio.extend({

    getWidth : function(){
        return this.get("width");
    },

    getHeight : function(){
        return this.get("height");
    },

    getThumbnailWidth : function(){
        return this.get("thumbnailWidth");
    },

    getThumbnailHeight : function(){
        return this.get("thumbnailHeight");
    },

    getThumbnail: function(){
        return this.get("thumbnail");
    },

})

export default TrebbleVideo;