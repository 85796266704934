import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import AudioSegment from "models/audioEditor/segments/AudioSegment";
import RSVP from "rsvp";

const CLASSNAME = "MusicAudioSegment";
const MusicAudioSegment =  AudioSegment.extend({
    idAttribute: "id",

    constructor: function(attributes, options) {
        AudioSegment.apply(this, [attributes, options]);
        this.set("type",MusicAudioSegment.CLASSNAME);
        
    },

    clone: function(){
        return new MusicAudioSegment(this.toJSON());
    },


});

MusicAudioSegment.CLASSNAME = CLASSNAME;
export default MusicAudioSegment; 