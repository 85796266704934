import SpeakerInfo from 'models/audioEditor/SpeakerInfo';
import ti18n from 'i18n!nls/Sequenceri18n';

const UnknownSpeakerInfo = SpeakerInfo.extend({

    constructor: function(attributes, options) {
        SpeakerInfo.apply(this, [attributes, options]);
        this.set("editable",false);
        this.set("speakerId",window.getI18n(ti18n, "UNKNOWN"));
        this.set("initialSpeakerLabel",window.getI18n(ti18n, "UNKNOWN"));
    },

  

});
export default UnknownSpeakerInfo;