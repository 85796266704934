import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import QueueOptionMenuView from "views/queueOptionMenu/QueueOptionMenuView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import RSVP from "rsvp";

const QueueOptionMenuController = Chaplin.Controller.extend({

    initialize : function(queue) {
        this.model = queue
        _.extend(this, Backbone.Events);
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    emptyQueue : function(){
        //PlayerService.getInstance().addSongsToQueue(arrayOfAlbumSongs);
    },
    

    saveAsPlaylist : function(){
        
    },

    onViewRemoved:function(){
        this.stopListening();
    },
    
    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
            
                this.view = new QueueOptionMenuView({
                    "model" : this.model
                });
                this.listenTo(this.view, "emptyQueue",this.emptyQueue);
                this.listenTo(this.view, "saveAsPlaylist",this.saveAsPlaylist);
                this.listenTo(this.view, "remove", this.onViewRemoved.bind(this));
                this.view.render();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default QueueOptionMenuController;