import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import ShareTrebblePopupView from "views/shareTrebble/ShareTrebblePopupView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import ti18n from "i18n!nls/shareTrebblePopupi18n";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";
import FacebookHelper from "models/helper/FacebookHelper";

const ShareCMailUrlPopupController = Chaplin.Controller.extend({

    initialize : function(options) {
        this._username = options.username;
        this._userId = options.userId;
        this.context = options.context;
        this._cmailUrlLink = null;

        this._initializeLinks(this._userId , this._username);
        _.extend(this, Backbone.Events);
    },

    _initializeLinks : function(userId, username){
        if(userId){
            this._cmailUrlLink = "https://s.trebble.fm/send_cmail/"+ userId;
        }else{
            this._cmailUrlLink = "https://s.trebble.fm/ask/"+ username;
        }
        
    },


    _copyToClipboard : function(toCopy, confirmMessage){
        if('cordova' in window && window.cordova && window.cordova.plugins && window.cordova.plugins.clipboard ){
            window.cordova.plugins.clipboard.copy(toCopy);
            Utils.getInstance().showShortToastMessage(confirmMessage);
        }else{
            const isCopySuccessfull = Utils.getInstance().copyTextToClipboard(toCopy);
            if (!window.waitForCordovaToLoad && !isCopySuccessfull) {
                navigator.trebbleNotification.alert(window.getI18n(ti18n, "PLEASE_PRESS_CNTR_C_TO_COPY"));
            }else{
                Utils.getInstance().showShortToastMessage(confirmMessage);
            }
        }
    },

    _shareNatively : function(toShare,url, title){
        if (navigator.share) {
            navigator.share({
                'title': title,
                text: toShare,
                url: url
            });
        }
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    copyShareableLinkToClipboard : function(toCopy){
        this._copyToClipboard(toCopy, window.getI18n(ti18n,"SHAREABLE_LINK_COPIED_TO_CLIPBOARD"));
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Cmail", "CmailShareLinkCopied", "C-Mail Share Link Copied");
        } 
        
    },

    shareNativelyhareableLinkToClipboard : function(toShare){
        this._shareNatively(null, toShare,window.getI18n(ti18n,"URL_TO_SEND_CAPSULE"));
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Cmail", "CmailShareLinkSharedNatively", "C-Mail Share Link Shared Natively");
        } 
        
    },


    shareOnFacebook : function(toShare){
        FacebookHelper.getInstance().showDialog({
            method: 'share',
            href: toShare,
        },{"entity":"Cmail", "eventName":"CmailUrlShareFacebook", "eventLabel":"C-Mail URL Shared On Facebook"} ).catch(function(error){
            window.alertErrorMessage(error);
        });
        /*FB.ui({
            method: 'share',
            href: toShare,
        }, function(response){
            if(response && response.error_message){
                window.alertErrorMessage(response.error_message);
            }else{
                if(window.trebbleAnalyticsHelper){
                    window.trebbleAnalyticsHelper.trackEvent("Cmail", "CmailUrlShareFacebook", "C-Mail URL Shared On Facebook");
                } 
            }
        });*/
},

shareOnTwitter : function(toShare){
const w = 550;
const h = 285;
const left = window.getLeftPositionForPopupWindow(w);
const top = window.getTopPositionForPopupWindow(h);
const openedWindow = window.open("https://twitter.com/intent/tweet?via=trebblefm&url="+encodeURIComponent(toShare),"Twitter", "resizable=1,height="+h+",width="+w+",top="+top+",left="+left+"");
if((!openedWindow || openedWindow.closed || typeof openedWindow.closed=='undefined') && !window.waitForCordovaToLoad){
window.alertErrorMessage(window.getI18n(ti18n, "PLEASE_ALLOW_POPUPS"));
}
if(window.trebbleAnalyticsHelper){
window.trebbleAnalyticsHelper.trackEvent("Cmail", "CmailUrlShareTwitter", "C-Mail URL Shared On Twitter");
}
},

previewLink: function(linkToPreview){
const openedWindow = window.open(linkToPreview , "_blank");
if((!openedWindow || openedWindow.closed || typeof openedWindow.closed=='undefined') && !window.waitForCordovaToLoad){
window.alertErrorMessage(window.getI18n(ti18n, "PLEASE_ALLOW_POPUPS"));
}
return openedWindow;
},

buildModelAndRenderView : function() {
return new RSVP.Promise((function(resolve, reject) {
try {

    const params = {};
    params.trebbleShareableLink = this._cmailUrlLink;
    params.canCopyEmbeddedWidgetLink = false;
    params.shareOnSmartSpeakers = false;
    params.shareSubscribePage = false;
    params.doNotTrebblePlayeableLink = true;
    params.shareableLinkHasAShortcast = false;
    params.sendCapsulePageLink = this._cmailUrlLink;
    params.canPeopleSendCapsuleLink = true;
    this.view = new ShareTrebblePopupView(params);
    this.listenTo(this.view, "preview",this.previewLink);
    this.listenTo(this.view, "copyLinkToClipboard",this.copyShareableLinkToClipboard);
    this.listenTo(this.view, "shareNativelyLinkToClipboard",this.shareNativelyhareableLinkToClipboard);
    this.listenTo(this.view, "shareOnFacebookButtonClicked",this.shareOnFacebook);
    this.listenTo(this.view, "shareOnTwitterButtonClicked",this.shareOnTwitter);

    this.view.render();
    resolve();


} catch (error) {
    reject(error);
}

}).bind(this));

}

});

export default ShareCMailUrlPopupController;