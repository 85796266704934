import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import PlayerModel from "models/player/PlayerModel";
import PlayerPageView from "views/player/PlayerPageView";
import PlayerEmbeddedPageView from "views/player/PlayerEmbeddedPageView";
import Playlist from "collections/Playlist";//"controllers/queue/QueueController",
import PersistentModels from "services/PersistentModels";
import PlayerService from "services/PlayerService";
import CommentWidgetController from "controllers/comment/CommentWidgetController";
import ArtistWidgetController from "controllers/artistDetail/ArtistWidgetController";
import Utils from "models/helper/Utils";
import TrebbleIndexDBHelper from "models/helper/TrebbleIndexDBHelper";
import TrebbleClientAPI from "models/helper/TrebbleClientAPI";
import ti18n from "i18n!nls/PlayerPagei18n";
import RSVP from "rsvp";

const PlayerController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if (options) {
            if (options.view) {
                this.view = options.view;
            }
            if (options.model) {
                this.model = options.model;
            }
        }

        //this.commentWidgetController = new  CommentWidgetController();
        //this.artistWidgetController = new  ArtistWidgetController();

    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    showQueuePage : function(){
        PersistentModels.getInstance().getRouter().showQueuePage(true);
    },

    showMenuOptionPopup : function(){
        let songRefSummary = null;
        return this.model.getCurrentSongRefSummaryIfApplicable().then((function(refSummary){
            if(refSummary){
                songRefSummary = refSummary;
            }
            return this.model.getCurrentSong();
        }).bind(this)).then((function(currentSong){
            if(currentSong)
            {
                const uri = currentSong.get("uri");
                if(Utils.getInstance().isCapsuleUri(uri)){
                    return PersistentModels.getInstance().getPlayer().getCapsuleInfoCurrentlyPlaying().then((function(capsuleJson){
                        if(capsuleJson){
                            const capsuleModel = Utils.getInstance().getModelFromCapsuleJson(capsuleJson);
                            PersistentModels.getInstance().getRouter().showSimpleSetCapsuleSharePopup(capsuleModel, PersistentModels.getInstance().getPlayer().getQueueContext());
                            //if(trackAction){
                                PersistentModels.getInstance().getPlayer().saveHistoryToServer("shareCapsulePageLaunched");
                            //}
                            return;
                        }else{
                            return null;
                        }
                    }).bind(this));
                    
                }
                if(Utils.getInstance().isJingleUri(uri) || Utils.getInstance().isGreaterUri(uri) ||  Utils.getInstance().isIntroUri(uri)  ||  Utils.getInstance().isOutroUri(uri)|| Utils.getInstance().isEarconUri(uri) || Utils.getInstance().isTrebbleGeneratedSpeechUri(uri)){
                    return ;
                }
                const context = this.getQueueContext();
                PersistentModels.getInstance().getRouter().showSongMenuOption(currentSong, context,songRefSummary, this.onActionExecutedOnSongHandler.bind(this));
            }
        }).bind(this));

},

showMoreInformationPopup: function(){
this.showCapsules();
},

onActionExecutedOnSongHandler : function(songModel, action){
if(action == "delete" || action == "removeFromRadio" || action == "removeToFavorite"){
//TODO: should probably remove it from the queue and go to the next song
}
},



getQueueContext : function(){
const context = this.model.getPlaylist()? this.model.getPlaylist().getContext(): null;
return context;
},


addSongToRadio : function(){
const songModelPlaying =  null;
const context = this.getQueueContext();
let songRefSummary = null;
this.view.setSongBeingAddedOrRemovedFromTrebble(true, false);
return this.model.getCurrentSongRefSummaryIfApplicable().then((function(refSummary){
if(refSummary){
    songRefSummary = refSummary;

}
return this.model.getCurrentSong();
}).bind(this)).then((function(currentSong){
if(currentSong){
    return PersistentModels.getInstance().addSongToDefaultRadio(currentSong, context, songRefSummary).then((function(){
        this.view.setSongBeingAddedOrRemovedFromTrebble(false, true);
        //this.view._toggleAddedToRadioBtnClass(true);
    }).bind(this));
}else{
    this.view.setSongBeingAddedOrRemovedFromTrebble(false, false);
    return null;
}
}).bind(this)).catch((function(error){
this.view.setSongBeingAddedOrRemovedFromTrebble(false, false);
window.alertErrorMessage(error);
}).bind(this));
},


addCapsuleToRadio : function(){
const songModelPlaying =  null;
const context = this.getQueueContext();
const songRefSummary = null;
this.view.setSongBeingAddedOrRemovedFromTrebble(true, false);
return this.model.getCapsuleInfoCurrentlyPlaying().then((function(capsuleJson){
if(capsuleJson){
    const capsuleModel = Utils.getInstance().getModelFromCapsuleJson(capsuleJson);
    return PersistentModels.getInstance().getRouter().addCapsuleToTrebbleViaPopup(capsuleModel,this.context).then((function(capsuleCreated){
        this.view.setSongBeingAddedOrRemovedFromTrebble(false,true);
        return this.model.getCurrentSong().then((function(currentSong){
            currentSong.set("airingOnRadios", capsuleModel.getAiringOnRadiosInfo());
        }).bind(this));
        //this.view._toggleAddedToRadioBtnClass(true);
    }).bind(this));
}else{
    this.view.setSongBeingAddedOrRemovedFromTrebble(false, false);
    return null;
}
}).bind(this)).catch((function(error){
this.view.setSongBeingAddedOrRemovedFromTrebble(false,false);
window.alertErrorMessage(error);
}).bind(this));
},


removeFromSongFromRadio : function(){
const songModelPlaying =  null;
const context = this.getQueueContext();
let songRefSummary = null;
this.view.setSongBeingAddedOrRemovedFromTrebble(true, true);
return this.model.getCurrentSongRefSummaryIfApplicable().then((function(refSummary){
if(refSummary){
    songRefSummary = refSummary;

}
return this.model.getCurrentSong();
}).bind(this)).then((function(currentSong){
if(currentSong){
    return PersistentModels.getInstance().removeSongFromDefaultRadio(currentSong, context, songRefSummary).then((function(){
        this.view.setSongBeingAddedOrRemovedFromTrebble(false, false);
        //this.view._toggleAddedToRadioBtnClass(false);
    }).bind(this));
}else{
    this.view.setSongBeingAddedOrRemovedFromTrebble(false, true);
    return null;
}
}).bind(this)).catch((function(error){
this.view.setSongBeingAddedOrRemovedFromTrebble(false,true);
window.alertErrorMessage(error);
}).bind(this));
},

removeCapsuleFromRadio : function(){
const songModelPlaying =  null;
const context = this.getQueueContext();
const songRefSummary = null;
this.view.setSongBeingAddedOrRemovedFromTrebble(true, true);
return this.model.getCapsuleInfoCurrentlyPlaying().then((function(capsuleJson){
if(capsuleJson){
    const capsuleModel = Utils.getInstance().getModelFromCapsuleJson(capsuleJson);
    return PersistentModels.getInstance().removeCapsuleFromRadio(capsuleModel,PersistentModels.getInstance().getAllMyPersonalRadioIds(),this.context).then((function(){
        this.view.setSongBeingAddedOrRemovedFromTrebble(false,false);
        return this.model.getCurrentSong().then((function(currentSong){
            currentSong.set("airingOnRadios", capsuleModel.getAiringOnRadiosInfo());
        }).bind(this));
        //this.view._toggleAddedToRadioBtnClass(true);
    }).bind(this));
}else{
    this.view.setSongBeingAddedOrRemovedFromTrebble(false, true);
    return null;
}
}).bind(this)).catch((function(error){
this.view.setSongBeingAddedOrRemovedFromTrebble(false, true);
window.alertErrorMessage(error);
}).bind(this));
},

showTagManagerForSongPlaying : function(){
return this.model.getCurrentSong().then((function(currentSong){
if(currentSong){
    const context = this.getQueueContext();
    PersistentModels.getInstance().getRouter().showSongTagManagerPopup(currentSong, context);
}else{
    return null;
}
}).bind(this));
},

onUserMoveSlider : function(newSliderValue){
this.model.seekTo(newSliderValue/100);
},

showSongInfo : function(){
window.alertErrorMessage(window.getI18n(ti18n, "COMING_SOON_THIS_IS_WHERE_BLAH"));
},

showConversationOnSong : function(){
    let songRefSummary = null;
return this.model.getCurrentSongRefSummaryIfApplicable().then((function(refSummary){
if(refSummary){
    songRefSummary = refSummary;

}
return this.model.getCurrentSong();
}).bind(this)).then((function(currentSong){
if(currentSong){
    const context = this.getQueueContext();
    if(window.isEmbeddedInIframe && !this.view.isFullscreen()){
        const uri = currentSong.get("uri");
        if(Utils.getInstance().isCapsuleUri(uri)){
            return PersistentModels.getInstance().getPlayer().getCapsuleInfoCurrentlyPlaying().then((function(capsuleJson){
                if(capsuleJson){
                    const capsuleModel = Utils.getInstance().getModelFromCapsuleJson(capsuleJson);
                    return this._openPopup(window.trebble.supportPushState ? location.origin + window.getAppBaseUrl() + "capsuleComments/"+capsuleModel.getCapsuleId() : location.origin + window.getAppBaseUrl() + "#" + "capsuleComments/"+capsuleModel.getCapsuleId());
                }else{
                    return null;
                }
            }).bind(this));

        }
        if(Utils.getInstance().isJingleUri(uri) || Utils.getInstance().isGreaterUri(uri) ||  Utils.getInstance().isIntroUri(uri)  ||  Utils.getInstance().isOutroUri(uri)|| Utils.getInstance().isEarconUri(uri) || Utils.getInstance().isTrebbleGeneratedSpeechUri(uri)){
            return ;
        }
        return this._openPopup(window.trebble.supportPushState ? location.origin + window.getAppBaseUrl() + "songComments/"+currentSong.get("id") : location.origin + window.getAppBaseUrl() + "#" + "songComments/"+currentSong.get("id"))
        /*const newPageUrl = ;
        const w = 500;
        const h = 740;
        const left = window.getLeftPositionForPopupWindow(w);
        const top = window.getTopPositionForPopupWindow(h);
        const openedWindow = window.open(newPageUrl, "_blank", "toolbar=no, scrollbars=yes, resizable=yes, height="+h+",width="+w+",top="+top+",left="+left+"");
        if((!openedWindow || openedWindow.closed || typeof openedWindow.closed=='undefined') && !window.waitForCordovaToLoad){
            window.alertErrorMessage(window.getI18n(ti18n, "PLEASE_ALLOW_POPUPS_FOR_TREBBLE"));
        }*/
    }else{
        return PersistentModels.getInstance().getRouter().showCommentsForSong(currentSong,false,null, context,songRefSummary, true);
    }
}
}).bind(this));
},


_openPopup: function(newPageUrl){
const w = 500;
const h = 740;
const left = window.getLeftPositionForPopupWindow(w);
const top = window.getTopPositionForPopupWindow(h);
const openedWindow = window.open(newPageUrl, "_blank", "toolbar=no, scrollbars=yes, resizable=yes, height="+h+",width="+w+",top="+top+",left="+left+"");
if((!openedWindow || openedWindow.closed || typeof openedWindow.closed=='undefined') && !window.waitForCordovaToLoad){
window.alertErrorMessage(window.getI18n(ti18n, "PLEASE_ALLOW_POPUPS_FOR_TREBBLE"));
}
},
_showCapsuleById: function(capsuleId){
return TrebbleClientAPI.getInstance().getCapsuleInfoByCapsuleId(capsuleId? capsuleId: this.capsuleId).then((capsuleJson) =>{
if(capsuleJson){
    return Utils.getInstance().getModelFromCapsuleJson(capsuleJson);
}else{
    return null;
}
}).then((function(capsuleModel){
if(capsuleModel){
    if(window.isEmbeddedInIframe && !this.view.isFullscreen()){
        const newPageUrl = window.trebble.supportPushState ? location.origin + window.getAppBaseUrl() + "capsule/" + capsuleModel.getCapsuleId() : location.origin + window.getAppBaseUrl() + "#" + "capsule/" + capsuleModel.getCapsuleId();
        const w = 500;
        const h = 740;
        const left = window.getLeftPositionForPopupWindow(w);
        const top = window.getTopPositionForPopupWindow(h);
        const openedWindow = window.open(newPageUrl, "_blank", "toolbar=no, scrollbars=yes, resizable=yes, height="+h+",width="+w+",top="+top+",left="+left+"");
        if((!openedWindow || openedWindow.closed || typeof openedWindow.closed=='undefined') && !window.waitForCordovaToLoad){
            window.alertErrorMessage(window.getI18n(ti18n, "PLEASE_ALLOW_POPUPS_FOR_TREBBLE"));
        }
    }else{
        return PersistentModels.getInstance().getRouter().showCapsuleInPopup(capsuleModel, this.getQueueContext(), false, true, true );
    }
}else{
    return this._showCapsulesForCurrentLoadedSong();
}
}).bind(this));
},

_showCapsulesForCurrentLoadedSong: function(){
    let songRefSummary = null;
return this.model.getCurrentSongRefSummaryIfApplicable().then((function(refSummary){
songRefSummary = refSummary;
return this.model.getCurrentSong();
}).bind(this)).then((function(currentSong){
const context = this.getQueueContext();
const capsuleUID = songRefSummary.capsuleRefUID;
const songUID = songRefSummary.songRefUID;
if(capsuleUID){
    //Current Song is a Capsule
    this._showCapsuleById(capsuleUID);
}else{
    if(songUID){
        if(window.isEmbeddedInIframe && !this.view.isFullscreen()){
            const newPageUrl = window.trebble.supportPushState ? location.origin + window.getAppBaseUrl() +  "songCapsules/"+songUID: location.origin + window.getAppBaseUrl() + "#" + "songCapsules/"+songUID;
            const w = 500;
            const h = 740;
            const left = window.getLeftPositionForPopupWindow(w);
            const top = window.getTopPositionForPopupWindow(h);
            const openedWindow = window.open(newPageUrl, "_blank", "toolbar=no, scrollbars=yes, resizable=yes, height="+h+",width="+w+",top="+top+",left="+left+"");
            if((!openedWindow || openedWindow.closed || typeof openedWindow.closed=='undefined') && !window.waitForCordovaToLoad){
                window.alertErrorMessage(window.getI18n(ti18n, "PLEASE_ALLOW_POPUPS_FOR_TREBBLE"));
            }
        }else{
            return PersistentModels.getInstance().getRouter().showCapsulesForSong(currentSong, context, songRefSummary);
        }
    }
}
}).bind(this));
},

showCapsules : function(){
const currentloadedSong = null;
$.mobile.loading( 'show');

return this.model.getCurrentCapsuleSummaryIfApplicable().then((function(capsuleSummary){
$.mobile.loading( 'hide');
if(capsuleSummary && capsuleSummary.activeCapsuleId){
    return this._showCapsuleById(capsuleSummary.activeCapsuleId);
}else{
    return this._showCapsulesForCurrentLoadedSong();
}
}).bind(this)).then((function(){
$.mobile.loading( 'hide');
}).bind(this)).catch((function(error){
$.mobile.loading( 'hide');
window.alertErrorMessage(error);
}).bind(this));
/*this.model.getCurrentSong().then((function(currentSongModel){
currentloadedSong = currentSongModel;
if(currentSongModel){
    const songJsonForBackend = Utils.getInstance().getSongJsonForBackendFromSongModel(currentSongModel);
    return TrebbleClientAPI.getInstance().getCapsulesInfoForSong(songJsonForBackend);
}
}).bind(this)).then((function(capsuleJsonInfoArray){
$.mobile.loading( 'hide');
if(capsuleJsonInfoArray && capsuleJsonInfoArray.length > 0){
    const firstCapsuleJson = capsuleJsonInfoArray[0];
    const capsuleModel =  Utils.getInstance().getModelFromCapsuleJson(firstCapsuleJson);
    PersistentModels.getInstance().getRouter().showCapsuleByInfoEditPage(capsuleModel,capsuleModel.getCapsuleId(),null, true );
}else{
    window.alertErrorMessage("No Capsule was found for this song. Be the first to create one :)")
}
}).bind(this)).catch((function(error){
$.mobile.loading( 'hide');
window.alertErrorMessage(error);
}).bind(this));*/
},

showQueuetuning  : function(){
PersistentModels.getInstance().getRouter().showQueueTuningPopup(this.model.getQueue());
},

notifyUserOfUnnotifiedOwnerComments : function(){
    let songRefSummary = null;
return this.model.getCurrentSongRefSummaryIfApplicable().then((function(refSummary){
songRefSummary = refSummary;
return this.model.getCurrentSong();
}).bind(this)).then((function(currentSong){
const context = this.getQueueContext();
return PersistentModels.getInstance().notifyUserOfUnnotifiedOwnerComments(currentSong, context, songRefSummary);
}).bind(this));
},

showUserProfileWithUsername : function(username){
if(username){
PersistentModels.getInstance().getRouter().showUserProfilePageByUsername(username,null, null, true );
}
},

liveLikeCurrentSong : function(){
this.model.liveLikeCurrentSong();
},

buildCommentWidgetController : function(){
if(true || window.isEmbeddedInIframe || window.isMobileBrowser){
return RSVP.Promise.resolve();
}else{
return PersistentModels.getInstance().getRouter().require(["controllers/comment/CommentWidgetController"]).then((function(CommentWidgetController){
    this.commentWidgetController = new  CommentWidgetController({"setHeightToFitContainer": true});
    return this.commentWidgetController.buildModelAndRenderView();
}).bind(this));
}
},

showCommentWidget : function(elementHeight){
if(this.commentWidgetController){
this.commentWidgetController.setVisible(true, elementHeight);
}
},

hideCommentWidget : function(){
if(this.commentWidgetController){
this.commentWidgetController.setVisible(false);
}
},

buildArtistWidgetController : function(){
if(true || window.isEmbeddedInIframe || window.isMobileBrowser){
return RSVP.Promise.resolve();
}else{
return PersistentModels.getInstance().getRouter().require(["controllers/artistDetail/ArtistWidgetController"]).then((function(ArtistWidgetController){
    this.artistWidgetController = new  ArtistWidgetController();
    return this.artistWidgetController.buildModelAndRenderView();
}).bind(this));
}
},

showArtistWidget : function(elementHeight){
if(this.artistWidgetController){
this.artistWidgetController.setVisible(true, elementHeight);
}
},

hideArtistWidget : function(){
if(this.artistWidgetController){
this.artistWidgetController.setVisible(false);
}
},

onSongChange : function(songModel){
    let songRefSummary = null;
let capsuleRefSummary = null;
return this.model.getCurrentSongRefSummaryIfApplicable().then((function(refSummary){
if(refSummary){
    songRefSummary = refSummary;

}
return this.model.getCurrentCapsuleSummaryIfApplicable();
}).bind(this)).then((function(currentCapsuleRefSummary){
capsuleRefSummary = currentCapsuleRefSummary;
return this.model.getCurrentSong();
}).bind(this)).then((function(currentSong){
if(currentSong){
    let showCapsuleInfoOnly =false;
    let capsuleId = (capsuleRefSummary && capsuleRefSummary.activeCapsuleId)? capsuleRefSummary.activeCapsuleId: null;
    if(!capsuleId && songRefSummary && songRefSummary.capsuleRefUID){
        capsuleId = songRefSummary.capsuleRefUID;
        showCapsuleInfoOnly =  true;
    }
    this.artistId = currentSong.get("albumJson").artistJson.artistId;
    this.artistName = currentSong.get("albumJson").artistJson.artistName;
    const artistInfoCanBeDisplayedForThisAudio = Utils.getInstance().artistInfoCanBeDisplayedForThisAudio(currentSong);
    const commentsCanBeDisplayedForThisAudio = Utils.getInstance().commentsCanBeDisplayedForThisAudio(currentSong);
    if(this.commentWidgetController){
        if(commentsCanBeDisplayedForThisAudio){
            this.showCommentWidget();
            this.commentWidgetController.setSongModel(currentSong, songRefSummary, this.model.getQueueContext());
        }else{
            this.hideCommentWidget();
        }
    }
    const uri = currentSong.get("uri");
    if(this.artistWidgetController){
        if(artistInfoCanBeDisplayedForThisAudio){
            this.showArtistWidget();
            this.artistWidgetController.setArtistInfo(this.artistId, null,this.artistName, this.model.getQueueContext(), capsuleId, showCapsuleInfoOnly);
        }else{
            this.hideArtistWidget();
        }
    }
}
}).bind(this));
},

updateArtistSummaryWidget : function(){
if(this.artistWidgetController){
this.artistWidgetController.updateSwiperWidget();
}
},
showSkipCurrentPlayingWithReasonPopup : function(sourceElement$el){
PersistentModels.getInstance().getRouter().showSkipCurrentPlayingWithReasonPopup(sourceElement$el);
},

onQueueFinishedPlaying : function(){
if(this.getQueueContext()){
if(this.getQueueContext().type == Utils.getInstance().getSongCollectionContexts().SIMPLE_CAPSULE_SET){
    this._showAutoPlayTrebblePopupByCapsuleOwnerIfApplicable();	
}else{
    if(this.getQueueContext().type == Utils.getInstance().getSongCollectionContexts().SIMPLE_SONG_SET){
        this._showAutoPlayTrebblePopupBySongOwnerIfApplicable();	
    }else{
        if(this.getQueueContext().type == Utils.getInstance().getSongCollectionContexts().RADIO){
            this._showTrebbleSubscribedPopupIfApplicable();
        }
    }
}
}else{

}
},

_isEmebeddeAndWindowHeightIsLargeEnoughForPopup : function(){
return !(window.isEmbeddedInIframe && window.getDocumentClienHeight() < 300);
},

_showAutoPlayTrebblePopupByCapsuleOwnerIfApplicable : function(){
$.mobile.loading("show");
let capsuleOwnerInfoJson =  null;
let trebbleInfoJson =  null;
let capsuleModel = null;
//We assume the playlist is a capsule simple set
const capsuleId = this.model.getPlaylist().getLoaderKey();
if(capsuleId){
return TrebbleClientAPI.getInstance().getCapsuleInfoByCapsuleId(capsuleId).then((function(capsuleJson){
    $.mobile.loading( 'hide');
    if(capsuleJson){
        capsuleModel=   Utils.getInstance().getModelFromCapsuleJson(capsuleJson);
        capsuleOwnerInfoJson = capsuleModel.getCreatorInfoJson();
        const capsuleOwnerUsername = capsuleModel.getCreatorUsername();
        const retrieveTrebbleInfoPromise = TrebbleClientAPI.getInstance().getUserTrebblesForUserByUserIdOrUsername(null, capsuleOwnerUsername, 1).then(function(treblePlaylistSummaryJsonArray){
            trebbleInfoJson = treblePlaylistSummaryJsonArray && treblePlaylistSummaryJsonArray.length > 0? treblePlaylistSummaryJsonArray[0]: null;
        });
        const promises = [];

        promises.push(retrieveTrebbleInfoPromise);
        return RSVP.Promise.all(promises);
    }else{
        return null;
    }
}).bind(this)).then((function(){
    if(trebbleInfoJson && capsuleOwnerInfoJson){
        return PersistentModels.getInstance().getRouter().showAutoPlayTrebblePopup(capsuleOwnerInfoJson,trebbleInfoJson,capsuleId );
    }
}).bind(this)).catch((function(error){
    $.mobile.loading( 'hide');
    window.alertErrorMessage(error);
}).bind(this));
}else{
//do nothing
}
},

_showAutoPlayTrebblePopupBySongOwnerIfApplicable : function(){
$.mobile.loading("show");
let songOwnerInfoJson =  null;
let trebbleInfoJson =  null;
let songModel = null;
//We assume the playlist is a song simple set
const songId = this.model.getPlaylist().getLoaderKey();
if(songId){
return TrebbleClientAPI.getInstance().getSongInfoBySongId(songId).then((function(songJson){
    $.mobile.loading( 'hide');
    if(songJson){
        songModel =   Utils.getInstance().getModelFromSongJson(songJson);
        const songOwnerUID = songJson.ownerUserUID;
        const retrieveTrebbleInfoPromise = TrebbleClientAPI.getInstance().getUserTrebblesForUserByUserIdOrUsername(songOwnerUID, null, 1).then(function(treblePlaylistSummaryJsonArray){
            trebbleInfoJson = treblePlaylistSummaryJsonArray && treblePlaylistSummaryJsonArray.length > 0? treblePlaylistSummaryJsonArray[0]: null;
        });
        const retrieveUserInfoPromise = TrebbleClientAPI.getInstance().getUserProfileInfoByUserUniqueId(songOwnerUID).then(function(userInfoJson){
            songOwnerInfoJson = userInfoJson;
        });
        const promises = [];

        promises.push(retrieveTrebbleInfoPromise);
        promises.push(retrieveUserInfoPromise);
        return RSVP.Promise.all(promises);
    }else{
        return null;
    }
}).bind(this)).then((function(){
    if(trebbleInfoJson && songOwnerInfoJson){
        return PersistentModels.getInstance().getRouter().showAutoPlayTrebblePopup(songOwnerInfoJson,trebbleInfoJson,null, songId );
    }
}).bind(this)).catch((function(error){
    $.mobile.loading( 'hide');
    window.alertErrorMessage(error);
}).bind(this));
}else{
//do nothing
}
},

_showTrebbleSubscribedPopupIfApplicable : function(){
$.mobile.loading("show");
const radioId =  this.model.getPlaylist().getLoaderKey();
if(radioId){
return TrebbleClientAPI.getInstance().getMyTrebbleInfoByTrebbleId(radioId).then((function(radioJsonInfo){
    $.mobile.loading( 'hide');
    if(radioJsonInfo){
        const personalRadioSummaryModel = Utils.getInstance().buildPlaylistSummaryFromJson(radioJsonInfo);
        return PersistentModels.getInstance().getRouter().showTrebbleSubscribeSuggestionPopup(personalRadioSummaryModel );
    }else{
        //window.alertErrorMessage(window.getI18n(ti18n, "UNABLE_TO_FIND_THIS_TREBBLE"));
    }
}).bind(this)).catch((function(error){
    $.mobile.loading( 'hide');
    window.alertErrorMessage(error);
}).bind(this));

}else{
//do nothing
}
},
showSendCapsuleToHost : function(){

if(this.model.getPlayerType() == PlayerModel.types.TREBBLE_PLAYER){
const trebbleId = this.model.getPlaylist().getLoaderKey();
if(trebbleId){
    return TrebbleClientAPI.getInstance().getHostUserIdByTrebbleId(trebbleId).then((function(hostUserId){
        if(hostUserId){
            if(window.isEmbeddedInIframe && !this.view.isFullscreen()){
                return PersistentModels.getInstance().getRouter().showSendCapsuleToUserByUserIdIntroPage(hostUserId,null,null,false, true );
            }else{
                return PersistentModels.getInstance().getRouter().showSendCapsuleToUserByUserIdIntroPage(hostUserId,null,null,true );
            }
        }
    }).bind(this));
}
}else{
if(this.model.getPlayerType() == PlayerModel.types.CAPSULE_FEED_PLAYER || this.model.getPlayerType() == PlayerModel.types.CATEGORY_SHORTCAST_RADIO){
    return this.model.getCurrentSong().then((function(currentSong){
        if(currentSong){
            const trebbleId = this.model.getPlaylist().getRadioIdForSongWitUri(currentSong.get("uri"));
            if(trebbleId){
                return TrebbleClientAPI.getInstance().getHostUserIdByTrebbleId(trebbleId).then((function(hostUserId){
                    if(hostUserId){
                        if(window.isEmbeddedInIframe && !this.view.isFullscreen()){
                            return PersistentModels.getInstance().getRouter().showSendCapsuleToUserByUserIdIntroPage(hostUserId,null,null,false, true );
                        }else{
                            return PersistentModels.getInstance().getRouter().showSendCapsuleToUserByUserIdIntroPage(hostUserId,null,null,true );
                        }
                    }
                }).bind(this));
            }
        }
    }).bind(this));
}

}
},

subscribe : function(){
return TrebbleClientAPI.getInstance()._doLogin(false, true).then((function(){
$.mobile.loading( 'show');
return this.model.subscribe().then((function(){
    $.mobile.loading( 'hide');
}).bind(this)).catch((function(error){
    $.mobile.loading( 'hide');
    window.alertErrorMessage(error);
}).bind(this));
}).bind(this));

},

unsubscribe : function(){
$.mobile.loading( 'show');
return this.model.unsubscribe().then((function(){
$.mobile.loading( 'hide');
}).bind(this)).catch((function(error){
$.mobile.loading( 'hide');
window.alertErrorMessage(error);
}).bind(this));

},

showSetupInstructionsToListenOnVoiceEnabledDevices : function(){
PersistentModels.getInstance().getRouter().showSetupVoiceEnabledDevicePopup();
},

buildModelAndRenderView : function() {
return new RSVP.Promise((function(resolve, reject) {
try {

    const promises =  [];
    promises.push(this.buildCommentWidgetController());
    promises.push(this.buildArtistWidgetController());
    RSVP.Promise.all(promises).then((function(){
        const commentWidgetView = this.commentWidgetController? this.commentWidgetController.getView(): null;
        const artistWidgetView = this.artistWidgetController? this.artistWidgetController.getView(): null;
        if(!this.model)
        {
            //this.model = new PlayerModel();
        }
        if(window.isEmbedded){
            this.view = new PlayerEmbeddedPageView({"model" : this.model});
        }else{
            this.view = new PlayerPageView({"model" : this.model, "commentWidgetView": commentWidgetView,"artistWidgetView": artistWidgetView});
        }
        this.listenTo(this.model, 'songChanged',this.onSongChange);
        this.listenTo(this.model, 'onQueueFinishedPlaying',this.onQueueFinishedPlaying);
        this.listenTo(this.view,"onQueueButtonTapped",this.showQueuePage);
        this.listenTo(this.view, "onPlayerMenuButtonTapped", this.showMenuOptionPopup);
        this.listenTo(this.view, "onPlayerMoreInformationButtonTapped", this.showMoreInformationPopup);
        this.listenTo(this.view, "addSongToRadio", this.addSongToRadio);
        this.listenTo(this.view, "addCapsuleToRadio", this.addCapsuleToRadio);
        this.listenTo(this.view, "removeFromSongFromRadio", this.removeFromSongFromRadio);
        this.listenTo(this.view, "removeCapsuleFromRadio", this.removeCapsuleFromRadio);
        this.listenTo(this.view, "showTagManagerForSongPlaying", this.showTagManagerForSongPlaying);

        this.listenTo(this.view, "userMoveSlider", this.onUserMoveSlider);

        this.listenTo(this.view, "showSongInfo", this.showSongInfo);
        this.listenTo(this.view, "showConversationOnSong", this.showConversationOnSong);
        this.listenTo(this.view, "showCapsules", this.showCapsules);
        this.listenTo(this.view, "showSendCapsuleToHost", this.showSendCapsuleToHost);
        this.listenTo(this.view, "showQueuetuning", this.showQueuetuning);
        this.listenTo(this.view, "notifiedUserOfUnnotifiedOwnerComments", this.notifyUserOfUnnotifiedOwnerComments);
        this.listenTo(this.view, "showUserProfileWithUsername", this.showUserProfileWithUsername);
        this.listenTo(this.view, "liveLikeButtonTapped",this.liveLikeCurrentSong);
        this.listenTo(this.view, "showCommentWidget",this.showCommentWidget);
        this.listenTo(this.view, "hideCommentWidget",this.hideCommentWidget);
        this.listenTo(this.view, "showArtistWidget",this.showArtistWidget);
        this.listenTo(this.view, "hideArtistWidget",this.hideArtistWidget);
        this.listenTo(this.view, "updateArtistSummaryWidget", this.updateArtistSummaryWidget);
        this.listenTo(this.view, "skipCurrentSongWithReason",this.showSkipCurrentPlayingWithReasonPopup)
        this.listenTo(this.view, "subscribe",this.subscribe);
        this.listenTo(this.view, "unsubscribe",this.unsubscribe);
        this.listenTo(this.view,"listenOnVoiceEnabledDeviceBtnClicked",this.showSetupInstructionsToListenOnVoiceEnabledDevices);
        this.view.render().then((function(){
            this.view.preloadBackgroundImage().then(function(){
                resolve();
            }).catch(function(err){
                console.error("Failed preloading Player page background image. Error:" +err);
                resolve();
            })
        }).bind(this));
    }).bind(this));
} catch (error) {
reject(error);
}

}).bind(this));

}

});

export default PlayerController;