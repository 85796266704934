import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import CommentView from "views/comment/CommentView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const CommentController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.model = options.songModel;
        this.context = options.context;
        _.extend(this, Backbone.Events);
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    destroy: function(){
        try{
            this.stopListening();
            if(this.view && this.view.remove){
                this.view.remove();
            }
        }catch(error){
            console.error(error);
        }
    },

    postComment : function(commentToPost){
        if(commentToPost){
            commentToPost = commentToPost.trim();
        }
        const songJsonForBackend = Utils.getInstance().getSongJsonForBackendFromSongModel(this.model);
        TrebbleClientAPIHelper.getInstance().postComment(songJsonForBackend, commentToPost, this.context).catch(function(error){
            console.error(error);
            Utils.getInstance().showErrorNotification(error);
        });
        if(window.trebbleAnalyticsHelper && window.trebbleAnalyticsHelper.trebbleHelperTrackComment){
            window.trebbleAnalyticsHelper.trebbleHelperTrackComment();
        }
    },
    
    
    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                const params = {};
                params.model = this.model;
                params.followersUsersInfo = PersistentModels.getInstance().getMyFollowingsInfo();
                this.view = new CommentView(params);
                this.listenTo(this.view, "postComment",this.postComment);
                this.view.render();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default CommentController;