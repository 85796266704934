import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import pageTemplate from 'text!../../../templates/listPage/ListPageViewTemplate.html';
import PersistentModels from "services/PersistentModels";
import CordovaHelper from "models/helper/CordovaHelper";

const ListPageView = Backbone.View.extend({

    template : _.template(pageTemplate),

    
    
    events : {
        "click #header-back-button": "navigateToPreviousPage",
    },
    navigateToPreviousPage : function(){
        PersistentModels.getInstance().getRouter().navigateToPreviousPage();
    },
    
    initialize : function(options) {
        this.model = options.model;
        if (options.listView) {
            this._listView = options.listView;
        }
        if (options.pageTitle) {
            this._pageTitle = options.pageTitle;
        }else{
            this._pageTitle = "";
        }
        
        if (options.pageId) {
            this._pageId = options.pageId;
        }else{
            this._pageId = "ListPageId";
        }
    },



    render : function() {
        this.$el.html(this.template({"pageTitle" : this._pageTitle, "pageId": this._pageId}));
        this.setElement(this.$el.find("div").first());
        this.$el.find(".ui-content:first > div:first").first().append(this._listView.el);
        return this;
    },
    



});

export default ListPageView;