import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import ti18n from "i18n!nls/helperi18n";
import RSVP from "rsvp";

const mediaStatuses = {

    PLAYING : 2,
    PAUSED : 3,
    STOPPED : 4,

};

const SOUNDCLOUD_URI_PREFIX = "soundcloud:";
const MediaSoundcloud =  Backbone.Model.extend({
    
    "default": {
        "DEFAULT_MEDIA_STATUS" :mediaStatuses,
        "src": "",
        "soundObject" :"",
        "mediaSuccess": "",
        "mediaError": "",
        "mediaStatus": "",

    },

    initialize : function(options) {
        this.src = options.uri;
        this.onMediaSuccess = options.onSuccess;
        this.onMediaError = options.onError;
        this.onMediaStatus = options.onStatus;
        this.onMediaFailedLoading = options.onFailedLoading;
        this._mediaFunctionHandlerContext = options.handlerContext;
        this.soundObjectIsReadyPromise = new RSVP.Promise((function(resolve,reject){
            try{

                const onStreamCompleted = function(soundObject){
                    this.soundObject = soundObject;
                    if(this.soundObject)
                    {
                        //success
                        this.soundObject.load(
                        {
                            onplay : this._onSongPlay.bind(this),
                            onfinish : this._onSongFinishedPlaying.bind(this),
                            onresume : this._onSongResume.bind(this),
                            onstop : this._onSongStop.bind(this),
                            onload: this._onSongReadyToPlay.bind(this),
                            onpause : this._onSongpause.bind(this),
                            //onsuspend : this._onSongSuspend.bind(this),
                            whileloading : this._onSongDownloading.bind(this),
                            onid3 : this._onLoadedmetadata.bind(this),

                        });
                        resolve(this.soundObject);
                    }else{
                        //failure
                        reject(window.getI18n(ti18n, "SOUND_OBJECT_COULDNT_BE_CREATED"));
                    }

                };
                window.SC.stream("/tracks/"+this.src, onStreamCompleted.bind(this));

            }catch(err){
                reject(err);
            }

        }).bind(this));

        
        
    },


    getSoundObjectIsReadyPromise : function(){
        return new RSVP.Promise((function(resolve,reject){
            try{
                /*SC.initialize({
                    client_id: 'e1ddc5a48279cd6d15c35595c26c7b05'
                });
        SC._baseUrl = "http://connect.soundcloud.com";*/
        const onStreamCompleted = function(soundObject){
            this.soundObject = soundObject;
            if(this.soundObject)
            {
                //success
                this.soundObject.load(
                {
                    onplay : this._onSongPlay.bind(this),
                    onfinish : this._onSongFinishedPlaying.bind(this),
                    onresume : this._onSongResume.bind(this),
                    onstop : this._onSongStop.bind(this),
                    onload: this._onSongReadyToPlay.bind(this),
                    onpause : this._onSongpause.bind(this),
                    onsuspend : this._onSongSuspend.bind(this),
                    whileloading : this._onSongDownloading.bind(this),
                    onid3 : this._onLoadedmetadata.bind(this),

                });
                resolve(this.soundObject);
            }else{
                //failure
                reject(window.getI18n(ti18n, "SOUND_OBJECT_COULDNT_BE_CREATED"));
            }

        };
        window.SC.stream("/tracks/"+this.src, onStreamCompleted.bind(this));

    }catch(err){
        reject(err);
    }

}).bind(this));

},

_getSoundObject : function(){
if(this.soundObject)
{
return new RSVP.resolve(this.soundObject);
}else{
return this.soundObjectIsReadyPromise;
}
},

isLoading : function(){
return this._loading;
},

getLoadingError : function(){
return this._loadingError;
},

play: function(){
if(!this._released ){
//this._exitFullscreen();
if(this.soundObject)
{
    if(this.soundObject.readyState == 1 || this.soundObject.readyState == 3 || (window.device.platform == "iOS"  && this.soundObject.readyState != 2 ) || (window.isSafariBrowser && window.isMobileBrowser))
    {
        this._loading = false;
        this.trigger("loaded");
        this.soundObject.play();
        this._isWaitingForSongToBeReadyToPlay = false;
    }else{
        this._loading = true;
        this.trigger("loading");
        this._isWaitingForSongToBeReadyToPlay = true;
    }
    if(!this._gettingReadyToPlayPromise){
        const gettingReadyToPlayPromise = new RSVP.Promise((function(resolve, reject){
            this._gettingReadyToPlayPromiseResolve = resolve;
            this._gettingReadyToPlayPromiseReject = reject;
        }).bind(this));
        this._gettingReadyToPlayPromise = gettingReadyToPlayPromise;
    }

}else{
    this.soundObjectIsReadyPromise = this.soundObjectIsReadyPromise.then((function(){
        //this.soundObject.play();
        if(!this._released ){
            if(this.soundObject.readyState == 3 ||  this.soundObject.readyState == 1)
            {
                this._loading = false;
                this.trigger("loaded");
                this.soundObject.play();
                this._isWaitingForSongToBeReadyToPlay = false;
            }else{
                this._loading = true;
                this.trigger("loading");
                this._isWaitingForSongToBeReadyToPlay = true;
            }
            if(!this._gettingReadyToPlayPromise){
                const gettingReadyToPlayPromise = new RSVP.Promise((function(resolve, reject){
                    this._gettingReadyToPlayPromiseResolve = resolve;
                    this._gettingReadyToPlayPromiseReject = reject;
                }).bind(this));
                this._gettingReadyToPlayPromise = gettingReadyToPlayPromise;
            }
        }
    }).bind(this));
}
}
window.log("soundcloud play uri:"+ this.src);

},

pause: function(){
if(!this._released ){
if(this.soundObject)
{
    this.soundObject.pause();
}else{
    this.soundObjectIsReadyPromise = this.soundObjectIsReadyPromise.then((function(){
        if(!this._released ){
            this.soundObject.pause();
        }	
    }).bind(this));
}
if(this._gettingReadyToPlayPromise){
    this._gettingReadyToPlayPromise.then((function(){
        if(this.soundObject){
            this.soundObject.pause();
        }
    }).bind(this));
}
}
window.log("soundcloud pause uri"+ this.src);
},


stop: function(){
if(!this._released ){
if(this.soundObject)
{
    this.soundObject.stop();
    this.soundObject.setPosition(0);
}else{
    this.soundObjectIsReadyPromise = this.soundObjectIsReadyPromise.then((function(){
        if(!this._released ){
            this.soundObject.stop();
            this.soundObject.setPosition(0);
        }
    }).bind(this));
}
if(this._gettingReadyToPlayPromise){
    this._gettingReadyToPlayPromise.then((function(){
        if(this.soundObject){
            this.soundObject.stop();
            this.soundObject.setPosition(0);
        }
    }).bind(this));
}
}
window.log("soundcloud stop uri:"+ this.src);

},

release: function(){
if(this.soundObject)
{
this.soundObject.destruct();
}else{
this.soundObjectIsReadyPromise = this.soundObjectIsReadyPromise.then((function(){
    this.soundObject.destruct();
}).bind(this));
}
this._released = true;
this._isWaitingForSongToBeReadyToPlay = false;
if(this._gettingReadyToPlayPromise){
this._gettingReadyToPlayPromise.then((function(){
    if(this.soundObject){
        this.soundObject.destruct();
    }
}).bind(this));
}
window.log("soundcloud release uri:"+ this.src);
},


seekTo: function(position){
if(!this._released ){
if(this.soundObject)
{
    this.soundObject.setPosition(position);
}else{
    this.soundObjectIsReadyPromise = this.soundObjectIsReadyPromise.then((function(){
        if(!this._released ){
            this.soundObject.setPosition(position);
        }
    }).bind(this));
}
}

},

startRecord: function(position){
window.alertErrorMessage(window.getI18n(ti18n, "NOT_SUPPORTED_YET"));
},

stopRecord: function(position){
window.alertErrorMessage(window.getI18n(ti18n, "NOT_SUPPORTED_YET"));
},

getDuration: function(position){
if(!this._released){
return this.soundObject? this.soundObject.durationEstimate: 0;// in milliseconds
}else{
return 0;
}
},

getCurrentPosition: function(onSuccess, onFailure){
if(this.soundObject)
{
if(this.soundObject.position)
{
    onSuccess(this.soundObject.position/1000);
}else{
    onSuccess(0);
}
}else{
onFailure(window.getI18n(ti18n, "COULDNT_FIND_THE_AUDIO_CURRENT_TIME_PROGRES"));
}
},

_onSongFinishedPlaying : function(){
this.onMediaSuccess.call(this._mediaFunctionHandlerContext);
},

_onSongResume : function(){
this.onMediaStatus.call(this._mediaFunctionHandlerContext, mediaStatuses.PLAYING, this);
window.log("Song resumed");
},

_onSongPlay : function(){
this.onMediaStatus.call(this._mediaFunctionHandlerContext, mediaStatuses.PLAYING, this);
window.log("Song playing"+ this.src);
try{
if(this._gettingReadyToPlayPromise){
    this._gettingReadyToPlayPromiseResolve();
    this._gettingReadyToPlayPromise = null;
}
}catch(error){
console.error(error);
this._gettingReadyToPlayPromise = null;
}
if(this._released){
if(this.soundObject){
    this.soundObject.stop();
    this.soundObject.setPosition(0);
    this.soundObject.destruct();
}
}

},

_onSongDownloading :  function(){
if(  this.soundObject && this.soundObject.buffered  && this.soundObject.buffered.length > 0 && this.soundObject.buffered[0].end > 0 && !this._isFirstLoadingEvent ){
//TODO: THIS HACK WAS ADDED TO RESOLVE A PRESUMABLY BUG WITH SOUNDMANAGER
this._isFirstLoadingEvent = true;
window.log("first loading");
return;
}
if( this.soundObject && this.soundObject.buffered && this.soundObject.buffered.length > 0){
const percentageDownloaded = 100 *  this.soundObject.buffered[0].end/ this.soundObject.durationEstimate;
window.log("video progress "+percentageDownloaded);
this.trigger("downloading",percentageDownloaded);
this._onDurationchange();
}

},


_onLoadedmetadata : function(){
window.log("Loaded Video Meta data. ");
this.trigger("loadedMetaData");
this._onDurationchange();
},

_onSongStop : function(){
this._loading = false;
this.trigger("loaded");
this.onMediaStatus.call(this._mediaFunctionHandlerContext, mediaStatuses.STOPPED, this);
window.log("Song Stopped");
},
_onSongReadyToPlay: function(isReady){
if(isReady){
if(this._isWaitingForSongToBeReadyToPlay){
    this._loading = false;
    this.trigger("loaded");
    this.play();
    this._isWaitingForSongToBeReadyToPlay = false;
}else{
    //do nothing
}
}else{
if(this.soundObject){
    this.soundObject.destruct();
}
this._loading = false;
this._loadingError = window.getI18n(ti18n, "AUDIO_FAILED_LOADING");
this.trigger("failedLoading",window.getI18n(ti18n, "AUDIO_FAILED_LOADING"));
console.error("Soundcloud Failed Loading");
if(this.onMediaFailedLoading){
    //call handler for failed loading
    this.onMediaFailedLoading.call(this._mediaFunctionHandlerContext,SOUNDCLOUD_URI_PREFIX + this.src);
}else{
    //act like the song finish playing,
    //player will probably just play the next song
    this.onMediaSuccess.call(this._mediaFunctionHandlerContext);
}

}
this._onDurationchange();
},

_onDurationchange : function(){
if(this.soundObject && this.soundObject.durationEstimate){
this.trigger("durationChange",this.soundObject.durationEstimate);
}else{
if(!this._released){
    setTimeout(this._onDurationchange.bind(this),1000);
}
}
},

_exitFullscreen: function () {
try{
if(document.exitFullscreen) {
    document.exitFullscreen();
} else if(document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
} else if(document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
}
}catch(error){
console.error("Failed exiting fullscreen. Error:"+error);
}
},

_onSongpause : function() {
this._loading = false;
this.trigger("loaded");
this.onMediaStatus.call(this._mediaFunctionHandlerContext, mediaStatuses.PAUSED, this);
window.log('Song Paused');
},

_onSongSuspend  : function() {
this.pause();
this.soundObjectIsReadyPromise = this.getSoundObjectIsReadyPromise();
this._loading = false;
this.trigger("loaded");
window.log('Song suspended');
},


});
export default MediaSoundcloud;