import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import popupTemplate from 'text!../../../templates/addMusicFromYoutube/AddMusicFromYoutubePopup.html';

const AddMusicFromYoutubeView = Backbone.View.extend({

    template : _.template(popupTemplate),

    

    initialize : function(options) {
        this.isTrebblePlayingMusic = options.isTrebblePlayingMusic;
    },


    events : {
        "click #browseAlbumBtn" : "browseAlbum",
        "click #browseArtistBtn" : "browseArtist",
        "click #addToFavoritesBtn" : "addSongAsFavorite",
        "click #addSongToDefaultRadioBtn" : "addSongToDefaultRadio",
        "click #playFromYoutubeBtn" : "playFromYoutube",
        "click #updateLocalSongInfoBtn" : "updateSongOfCurrentPlayingSong",
        "click #closeBtn" : "_close"
    },
    
    _close: function(){
        window.history.back();
    },

    addSongAsFavorite : function(){
        window.history.back();
        this.trigger("addSongAsFavorite", this.model);
    },
    
    addSongToDefaultRadio : function(){
        window.history.back();
        this.trigger("addSongToDefaultRadio", this.model);
    },


    browseAlbum : function(){
        window.history.back();
        this.trigger("browseAlbum", this.model);
    },
    
    browseArtist : function(){
        window.history.back();
        this.trigger("browseArtist", this.model);
    },
    
    playFromYoutube : function(){
        window.history.back();
        this.trigger("playFromYoutube", this.model);
    },
    
    updateSongOfCurrentPlayingSong: function(){
        window.history.back();
        this.trigger("updateSongOfCurrentPlayingSong", this.model);
    },


    

    render : function() {
        this.$el.html(this.template({"model":this.model.toJSON(),"isTrebblePlayingMusic": this.isTrebblePlayingMusic}));
        this.setElement(this.$el.find("div").first());
        const elementId = this.$el.attr('id');
        this.$el.on({
            popupbeforeposition: (function() {
                //  const maxHeight = $( window ).height() - 60 + "px";
                const width = $( window ).width() - 0 + "px";
                // this.$el.css( "height", maxHeight );
                this.$el.css( "width", width );
            }).bind(this)
        });
        this.$el.on({
            popupafterclose: (function() {
                this.remove();
                this.undelegateEvents();
                
            }).bind(this)});
        $.mobile.pageContainer.prepend(this.$el);
        this.$el.popup({transition:"fade", dismissible: false,  tolerance: "0,0"});
        if(elementId)
        {
            const screenPopup$el = $("#"+elementId + "-screen");
            $.mobile.pageContainer.remove("#"+elementId + "-screen");
            $.mobile.pageContainer.prepend(screenPopup$el);
        }
        
        this.$el.enhanceWithin();
        Utils.getInstance().closeCurrentJQMPopup().then((function(){
            this.$el.popup('open' );
        }).bind(this));
        return this;
    }

});

export default AddMusicFromYoutubeView;