	import $ from 'jquery';
    import _ from 'underscore';
    import Backbone from "backbone";
    import LocalStorageHelper from "models/helper/LocalStorageHelper";
    import FeatureRolloutHelper from "models/helper/FeatureRolloutHelper";
    import RSVP from "rsvp";



    const IntercomHelper = Backbone.Model.extend({
        initialize : function(options) {
            if("Intercom" in window){
                window.Intercom("onShow", this._onIntercomShow.bind(this));
                window.Intercom("onHide", this._onIntercomHide.bind(this));
                window.Intercom('onUnreadCountChange', this._onUnreadMessagesChange.bind(this));
                this._cachedUnreadMessagesCount = 0;
            }
            

        },

        _onIntercomShow : function(){
            this.trigger("show");
        },

        _onIntercomHide : function(){
            this.trigger("hide");
        },

        _onUnreadMessagesChange : function(numberOfUnreadMessages){
            this._cachedUnreadMessagesCount = numberOfUnreadMessages;
            this.trigger("unreadMessagesCountChange",numberOfUnreadMessages)
        },

        isAvailable : function(){
            return true;//return "intercom" in window || "Intercom" in window;
        },
        
        isUnreadConversationCountAvailable :function(){
            return "intercom" in window && window.intercom.unreadConversationCount;
        },

        getUnreadConversationCount: function(){
            if("intercom" in window){
                return new RSVP.Promise(function(resolve, reject){
                    window.intercom.unreadConversationCount(resolve);
                })
            }else{
                return RSVP.Promise.resolve(this._cachedUnreadMessagesCount);
            }
        },

        
        registerIdentifiedUser : function(userId, userEmail, firstName, lastName, profileImageUrl, creationDate, allUserFeatures){
            if("intercom" in window){
                window.intercom.registerIdentifiedUser({"userId": userId, "email":userEmail});
                const intercomHash =  LocalStorageHelper.getInstance().getIntercomHash();
                if(intercomHash){
                    window.intercom.setUserHash(intercomHash);
                }
            }else{
                if("Intercom" in window){
                    let userInfoAndAllFeatures =  allUserFeatures;
                    if(!userInfoAndAllFeatures){
                        userInfoAndAllFeatures = {};
                    }
                    userInfoAndAllFeatures.app_id = window.intercomSettings.app_id;
                    userInfoAndAllFeatures.email = userEmail;
                    userInfoAndAllFeatures.user_id = userId;
                    userInfoAndAllFeatures.name = firstName+" "+lastName;
                    userInfoAndAllFeatures.avatar =  {
                        "type": "avatar", 
                        "image_url" :profileImageUrl
                    };
                    userInfoAndAllFeatures.avatar = profileImageUrl;
                    userInfoAndAllFeatures.signed_up_at = creationDate;
                    userInfoAndAllFeatures.user_hash = LocalStorageHelper.getInstance().getIntercomHash();
                    window.Intercom('boot',userInfoAndAllFeatures);

                }
            }
        },

        registerForPush : function(){
            if("intercom" in window){
                window.intercom.registerForPush();
            }
        },

        updateUser : function(userEmail, firstName, lastName, profileImageUrl, creationDate, allUserFeatures){
            if("intercom" in window){
                let userInfoAndAllFeatures =  allUserFeatures;
                if(!userInfoAndAllFeatures){
                    userInfoAndAllFeatures = {};
                }
                userInfoAndAllFeatures.email = userEmail;
                userInfoAndAllFeatures.name = firstName+" "+lastName;
                userInfoAndAllFeatures.avatar = profileImageUrl;
                userInfoAndAllFeatures.signed_up_at = creationDate;
                window.intercom.updateUser(userInfoAndAllFeatures);
            }
        },

        updateUserAttributes :function(userAttributeUpdates){
            if("intercom" in window && userAttributeUpdates){
                window.intercom.updateUser(userAttributeUpdates);
            }
        },

        

        _stopRecordingFromSmartlookIfApplicable : function(){
            //this function here is just there to work around an issue where the app becomes unresponsive on android if smartlook was running
            return new RSVP.Promise(function(resolve, reject){
                const smartLookEnabled = FeatureRolloutHelper.getInstance().isFeatureEnabled(FeatureRolloutHelper.FEATURES.ENABLE_RECORDING_VIA_SMARTLOOK);
                if(smartLookEnabled && "cordova" in window && window.cordova.plugins && window.cordova.plugins.SmartlookPlugin && window.cordova.plugins.SmartlookPlugin){
                    window.cordova.plugins.SmartlookPlugin.isRecording(
                        function(isRecording){
                            if(isRecording){
                                window.cordova.plugins.SmartlookPlugin.stopRecording(resolve, reject);
                            }else{
                                resolve();
                            }
                        })
                }else{
                    resolve();
                }


            })

        },

        trackEvent : function(eventName, additionalParams){
            if("intercom" in window){
                window.intercom.logEvent(eventName, additionalParams);
            }else{
                if("Intercom" in window){
                    window.Intercom('trackEvent', eventName,additionalParams);
                }
            }
        },

        displayMessenger :function(){
            return this._stopRecordingFromSmartlookIfApplicable().then(function(){
                if("intercom" in window){
                    window.intercom.displayMessenger();
                }else{
                    if("Intercom" in window){
                        window.Intercom('show');
                    }
                }
            })
        },

        displayMessageComposer: function() {
            return this._stopRecordingFromSmartlookIfApplicable().then(function(){
                if("intercom" in window){
                    window.intercom.displayMessageComposer(function(){},function(error){console.error(error)});
                }else{
                    if("Intercom" in window){
                        window.Intercom('showNewMessage');
                    }
                }
            })
        },

        displayMessageComposerWithInitialMessage: function(initialMessage) {
            return this._stopRecordingFromSmartlookIfApplicable().then(function(){
                if("intercom" in window){
                    window.intercom.displayMessageComposerWithInitialMessage(initialMessage, function(){},function(error){console.error(error)});
                }else{
                    if("Intercom" in window){
                        window.Intercom('showNewMessage', initialMessage);
                    }
                }
            })
        },

        displayMobileCarousel: function(carouselId) {
            return this._stopRecordingFromSmartlookIfApplicable().then(function(){
                if("intercom" in window && window.intercom.displayCarousel){
                    window.intercom.displayCarousel(carouselId);
                }else{
                    if("Intercom" in window){
                        
                    }
                }
            })
        },


        hideMessenger :function(){
            if("intercom" in window){
                window.intercom.hideMessenger()
            }else{
                if("Intercom" in window){
                    window.Intercom('hide');
                }
            }

        },

        logout : function(){
            if("intercom" in window){
                window.intercom.logout();
            }else{
                if("Intercom" in window){
                    window.Intercom('shutdown');
                }
            }
        }



    });

    const IntercomHelperInstance = new IntercomHelper();
    window.trebbleIntercomHelper = IntercomHelperInstance;

    export default {
		getInstance : function() {
			return IntercomHelperInstance;
		}
	};