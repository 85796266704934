import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import SequencerNodeView from "views/audioEditor/sequencerNodeView/SequencerNodeView";
import ti18n from 'i18n!nls/Sequenceri18n';
import RSVP from 'rsvp';


const SequencerEndWrapNodeView = SequencerNodeView.extend({


    constructor : function(attributes, options) {
        SequencerNodeView.apply(this, [attributes, options]);
    },
    
    events : _.extend({
        "click": "onNodeClick",
        "mouseenter": "onMouseEnter",
        "mouseleave": "onMouseLeave",
    }, SequencerNodeView.prototype.events),
    
    onNodeClick : function(){
        this.trigger("endWrapNodeClick", this);
    },

    onMouseEnter: function(){
        this.model.setAllNodesHoverHighlighted(true);
    },

    onMouseLeave: function(){
        this.model.setAllNodesHoverHighlighted(false);
    },

    _onDragStart : function(e){
        SequencerNodeView.prototype._onDragStart.apply(this, [e]);
        this.model.setAllNodesHoverHighlighted(false);
    },
    
    render : function() {
        this.afterRendering();
        return this;
    }

});
export default SequencerEndWrapNodeView;