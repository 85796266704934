import $ from 'jquery';
import _ from 'underscore';
import OptionPopupView from "views/common/OptionPopupView";
import ChooseTranscriptionLanguagePopupContentTemplate from 'text!../../../templates/audioEditor/ChooseTranscriptionLanguagePopupContentTemplate.html';
import ti18n from 'i18n!nls/Sequenceri18n';
import moment from 'moment';


const ChooseTranscriptionLanguagePopupView = OptionPopupView.extend({


    initialize: function (options)
    {
        const viewOptions = {};
        const contentView = {};
        const contentViewTemplate = _.template(ChooseTranscriptionLanguagePopupContentTemplate);
        const templateParams = {};
        templateParams.ti18n = ti18n;
        templateParams.moment = moment;
        contentView.$el = $(contentViewTemplate(templateParams));
        viewOptions.contentView = contentView;
        viewOptions.addContentAfterEnhancement = false;
        viewOptions.contentPosition = "center";
        viewOptions.displayTitle = true;
        this._supportedTranscriptionLanguagesCollection = options.supportedTranscriptionLanguagesCollection;
        this._selectedDefaultTranscriptionLanguage = options.selectedDefaultTranscriptionLanguage;
        if(!this._selectedDefaultTranscriptionLanguage){
            this._selectedDefaultTranscriptionLanguage = "en-US";
        }

        viewOptions.titleToDisplay = window.getI18n(ti18n, "TRANSCRIBE");
        this._popupUniqueId = "ChooseTranscriptionLanguagePopupView";
        viewOptions.id = this._popupUniqueId;

        OptionPopupView.prototype.initialize.call(this, viewOptions);

    },

    _addTranscriptioneSupportedLanguageToView: function ()
    {
        const selectSupportedTranscriptionLanguage$El = this.$el.find("#languageUsedInAudioFld");
        const selectSupportedTranscriptionLanguageLabel$El = this.$el.find("#languageUsedInAudioFld-button > span");
        if (this._supportedTranscriptionLanguagesCollection)
        {
            this._supportedTranscriptionLanguagesCollection.each((function (languageListItemModel)
            {
                if (this._selectedDefaultTranscriptionLanguage == languageListItemModel.getId())
                {
                    selectSupportedTranscriptionLanguage$El.append("<option value='" + languageListItemModel.getId() + "' selected='selected'>" + languageListItemModel.getLabel() + "</option>");
                    selectSupportedTranscriptionLanguageLabel$El.html(languageListItemModel.getLabel());
                } else
                {
                    selectSupportedTranscriptionLanguage$El.append("<option value='" + languageListItemModel.getId() + "'>" + languageListItemModel.getLabel() + "</option>");
                }
            }).bind(this));
        }
    },


    _close: function ()
    {
        this.$el.popup('close');
    },

    getLanguageCode: function ()
    {
        return this.$el.find("#languageUsedInAudioFld option:selected").val();
    },

    onPopupClosed: function ()
    {
        OptionPopupView.prototype.onPopupClosed.call(this);
        this.trigger("onPopupClosed");
    },

    _addArrowToSelectWidget: function ()
    {
        this.$el.find("#languageUsedInAudioFld-button").append('<i class="ion-chevron-down" style="display: inline-block;"></i>');
    },


    afterRendering: function ()
    {
        const publishButton$el = this.$el.find("#transcribe_btn");
        this._addTranscriptioneSupportedLanguageToView();
        this._addArrowToSelectWidget();
        this.$el.find("#languageUsedInAudioFld").selectmenu("refresh");
        publishButton$el.on("click", (function ()
        {
            this.trigger("transcribe");
        }).bind(this));
    },



});

export default ChooseTranscriptionLanguagePopupView;
