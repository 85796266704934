import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";
import NotificationCardView from "views/common/NotificationCardView";

const NotificationCardController =  Chaplin.Controller.extend({


    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = options.model;
        this._customEventHandler = options? options.customEventHandler : null;
        this._showActionButton = options? options.showActionButton : false;
        this._actionButtonLabel = options? options.actionButtonLabel : null;
        this._actionButtonClickHandler = options? options.actionButtonClickHandler : null;
        this._customTemplate = options? options.customTemplate: null;
    },
    
    getModel : function(){
        return this.model;
    },
    
    getView : function(){
        return this.view;
    },

    destroy: function(){
        try{
            this.stopListening();
            if(this.view && this.view.remove){
                this.view.remove();
            }
        }catch(error){
            console.error(error);
        }
    },
    
    onActionButtonClicked : function(model){
        if(this._actionButtonClickHandler){
            this._actionButtonClickHandler(model);
        }
    },

    
    _getDefaultEventMap  : function(){
        return {"click #actionButton": this.onActionButtonClicked.bind(this)};
    },
    
    
    getEventMap : function(){
        const eventMap = this._getDefaultEventMap();
        $.extend(eventMap, this._customEventHandler);
        return eventMap;
    },
    
    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
            
                const contentViewParams = {};
                contentViewParams.model  = this.model;
                contentViewParams.showActionButton = this._showActionButton;
                contentViewParams.actionButtonLabel = this._actionButtonLabel;
                contentViewParams.actionButtonClickHandler = this._actionButtonClickHandler;
                contentViewParams.customTemplate = this._customTemplate ;
                contentViewParams.events =  this.getEventMap();
                const notificationCardView = new  NotificationCardView(contentViewParams);
                notificationCardView.render();
                this.view = notificationCardView;
                this.listenTo(this.view,"actionButtonClicked", this.onActionButtonClicked);
            
                
                resolve(this);
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }
    

});
export default NotificationCardController;