import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import SongSelectorListController from "controllers/common/SongSelectorListController";
import Utils from "models/helper/Utils";
import SongSelectorPopupView from "views/common/SongSelectorPopupView";
import ListItemCollection from "collections/ListItemCollection";
import ti18n from "i18n!nls/Commoni18n";
import RSVP from "rsvp";

const SongSelectorPopupController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = options.songCollection;
        this._selectedFilterOption = options.selectedFilterOption;
        this._selectedSortOption = options.selectedSortOption;
        this._singleSelect = options.singleSelect;
        this._title = options.title;
        this._onPopupClose = options.onPopupClose;
        this._infoBoxMessageOnFieldEmpty = options.infoBoxMessageOnFieldEmpty;
        this._infoBoxHelperMessageOnFieldEmpty = options.infoBoxHelperMessageOnFieldEmpty;
        this._customInfoBoxInfoParams = options.customInfoBoxInfoParams;
        this._songWithAudioPreviewOnly = options.songWithAudioPreviewOnly;
        this.songSelectorListController = this._buildSongSelectorListController();
        this._noSongSelectionOptionAllowed = options.noSongSelectionOptionAllowed;
        this._noSongSelectionButtonLabel = options.noSongSelectionButtonLabel;
        this.context = options.context;
        this.selectedUriToSongModel = {};
    },
    
    _onSelectionChanged : function(songModels){
        if(songModels){
            this.selectedUriToSongModel = {};
            if(this._singleSelect){
                if(songModels){
                    const songModel = songModels;
                    this.selectedUriToSongModel[songModel.get("uri")] = songModel;
                    this.view.close();
                }
            }else{
                for(let index in songModels){
                    const songModel = songModels[index];
                    this.selectedUriToSongModel[songModel.get("uri")] = songModel;
                }
                
            }
        }
    },
    
    _buildSongSelectorInfoBoxParams:function(){
        if(!this._customInfoBoxInfoParams){
            return Utils.getInstance().buildInfoBoxParams(null, "ion-ios7-musical-notes", null, window.getI18n(ti18n,"NO_SONG_FOUND"), window.getI18n(ti18n,"PLEASE_TRY_DIFFERENT_KEYWORDS"), null, null);
        }else{
            return this._customInfoBoxInfoParams;
        }
    },
    
    _buildSongSelectorInfoBoxParamsForEmptySearchField:function(){
        let message= this._infoBoxMessageOnFieldEmpty;
        if(!message){
            if(this._singleSelect){
                message = window.getI18n(ti18n,"PICK_A_SONG");
            }else{
                message = window.getI18n(ti18n,"PICK_SONGS");
            }
        } 
        const helperMessage = this._infoBoxHelperMessageOnFieldEmpty?this._infoBoxHelperMessageOnFieldEmpty: "";
        return Utils.getInstance().buildInfoBoxParams(null, null, null, message, helperMessage, null, null);
    },
    
    _buildSongSelectorListController : function(){
        const songSelectorListControllerParams = {};
        songSelectorListControllerParams.selectedFilterOption = this._selectedFilterOption;
        songSelectorListControllerParams.onSongSelectionChanged = this._onSelectionChanged.bind(this);
        songSelectorListControllerParams.singleSelect = this._singleSelect;
        songSelectorListControllerParams.customInfoBoxInfoParams =  this._buildSongSelectorInfoBoxParams();
        songSelectorListControllerParams.customInfoBoxInfoEmptySearchParams =  this._buildSongSelectorInfoBoxParamsForEmptySearchField();
        songSelectorListControllerParams.selectedSortOption = this._selectedSortOption;
        
        songSelectorListControllerParams.songCollection = this.model;
        songSelectorListControllerParams.songWithAudioPreviewOnly = this._songWithAudioPreviewOnly;
        return  new SongSelectorListController(songSelectorListControllerParams);
    },

    getSelectedSongModels : function(){
        const selectedModels = [];
        for(let songUri in this.selectedUriToSongModel){
            selectedModels.push(this.selectedUriToSongModel[songUri]);
        }
        if(this._singleSelect ){
            return selectedModels && selectedModels.length > 0? selectedModels[0]: null;
        }else{
            return selectedModels;
        }
    },

    onPopupClose : function(noSongSelectedButtonClicked){
        if(this._onPopupClose){
            this._onPopupClose(this.getSelectedSongModels(), noSongSelectedButtonClicked);
        }
    },
    
    
    buildModelAndRenderView : function() {
        $.mobile.loading("show");
        return new RSVP.Promise((function(resolve, reject) {
            try {
                const promises = [];
                promises.push(this.songSelectorListController.buildModelAndRenderView());
                RSVP.Promise.all(promises).then((function(){
                    const viewParams ={};
                    this.songSelectorListView  = this.songSelectorListController.getView();
                    viewParams.songSelectorListView = this.songSelectorListView;
                    viewParams.title = this._title;
                    viewParams.noSongSelectionOptionAllowed = this._noSongSelectionOptionAllowed;
                    viewParams.noSongSelectionButtonLabel = this._noSongSelectionButtonLabel;
                    const songSelectorPopupView = new SongSelectorPopupView(viewParams);
                    songSelectorPopupView.render();
                    $.mobile.loading("hide");
                    this.view = songSelectorPopupView;
                    this.listenTo(this.view,"popupClosed",this.onPopupClose.bind(this))
                    
                }).bind(this)).catch((function(error){
                    $.mobile.loading("hide");
                    reject(error);
                }).bind(this));
                
            } catch (error) {
                $.mobile.loading("hide");
                reject(error);
            }

        }).bind(this));

    }

});

export default SongSelectorPopupController;