
import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import DeleteSelectedNodesOperation from "models/audioEditor/sequencerOperation/DeleteSelectedNodesOperation";
import StartWrapSequencerNode from "models/audioEditor/sequencerNodes/StartWrapSequencerNode";
import EndWrapSequencerNode from "models/audioEditor/sequencerNodes/EndWrapSequencerNode";
import DeletedSequencerNode from "models/audioEditor/sequencerNodes/DeletedSequencerNode";
import SequencerOperationUtils from "models/audioEditor/sequencerOperation/SequencerOperationUtils";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

function InterruptedBecauseOfWrapNodeOrphanError(message = "") {
    this.name = "InterruptedBecauseOfWrapNodeOrphanError";
    this.message = message;
}
InterruptedBecauseOfWrapNodeOrphanError.prototype = Error.prototype;


function SelectedNodeNeedToBeNextToEachotherError(message = "") {
    this.name = "SelectedNodeNeedToBeNextToEachotherError";
    this.message = message;
}
SelectedNodeNeedToBeNextToEachotherError.prototype = Error.prototype;

const DeleteBulkSelectedNodesOperation =  DeleteSelectedNodesOperation.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        DeleteSelectedNodesOperation.apply(this, [attributes, options]);
    },

    execute : function(arrayOfNodePairToDelete){
        const {arrayOfNodeArrayToReplace, arrayOfDeleteNode} = this.deleteArrayOfNodePair(arrayOfNodePairToDelete);
        this.getSequencer().getHistoryManagement().addCreateNodeDeletedBulkLog(arrayOfNodeArrayToReplace, arrayOfDeleteNode);

        return {"arrayOfNodeArrayToReplace": arrayOfNodeArrayToReplace, "arrayOfDeleteNode": arrayOfDeleteNode };
    },

    deleteArrayOfNodePair : function(arrayOfNodePairToDelete){
        const arrayOfNodeArrayToReplace = [];
        const arrayOfDeleteNode =[];
        arrayOfNodePairToDelete.forEach((function(nodePairArray){
            const startSequencerNodeToDelete = nodePairArray[0];
            const endSequencerNodeToDelete = nodePairArray[1];
            if(Utils.getInstance().doesSelectedNodesContainOrphanWrapNode(startSequencerNodeToDelete, endSequencerNodeToDelete)){
                throw new DeleteSelectedNodesOperation.InterruptedBecauseOfWrapNodeOrphanError();
            }
            const nodeArrayToReplace = SequencerOperationUtils.getInstance().getNodeArray(startSequencerNodeToDelete, endSequencerNodeToDelete);
            if(SequencerOperationUtils.getInstance().areNodeToReplaceConnectedToEachother(nodeArrayToReplace)){
                arrayOfNodeArrayToReplace.push(nodeArrayToReplace);
            }else{
                throw new SelectedNodeNeedToBeNextToEachotherError();
            }
        }).bind(this))
        arrayOfNodeArrayToReplace.forEach((function(nodeArrayToReplace){
            const deleteNode = new DeletedSequencerNode({"audioSegment": Utils.getInstance().getDeletedAudioSegment(), "sequencer": this.getSequencer()});
            this.replaceSequencerNodes(deleteNode, nodeArrayToReplace);
            arrayOfDeleteNode.push(deleteNode);
        }).bind(this))

        return {arrayOfNodeArrayToReplace, arrayOfDeleteNode}

    },




    undo : function(sequencerOperationDeleteNodesLog ){
        const arrayOfDeleteNode = sequencerOperationDeleteNodesLog.getArrayOfDeleteNode();
        arrayOfDeleteNode.forEach((function(deleteNode){
            this.revertReplacement(deleteNode);
        }).bind(this))
    },

    redo: function(sequencerOperationDeleteNodesLog){
        const arrayOfNodeArrayToReplace = sequencerOperationDeleteNodesLog.getArrayOfNodeArrayToDelete();
        const arrayOfDeleteNode = sequencerOperationDeleteNodesLog.getArrayOfDeleteNode();
        arrayOfDeleteNode.forEach((function(deleteNode, index){
            const nodeArrayToReplace = arrayOfNodeArrayToReplace[index];
            this.replaceSequencerNodes(deleteNode, nodeArrayToReplace);
        }).bind(this))
    },

});

DeleteBulkSelectedNodesOperation.InterruptedBecauseOfWrapNodeOrphanError = InterruptedBecauseOfWrapNodeOrphanError;
DeleteBulkSelectedNodesOperation.SelectedNodeNeedToBeNextToEachotherError = SelectedNodeNeedToBeNextToEachotherError;
export default DeleteBulkSelectedNodesOperation; 