import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import CustomSearchFieldView from "views/common/CustomSearchFieldView";
import PersistentModels from "services/PersistentModels";
import pageTemplate from 'text!../../../templates/common/SongSelectorListTemplate.html';

const SongSelectorListView = Backbone.View.extend({


    template : _.template(pageTemplate),

    events : {

    },

    initialize : function(options) {
        this._songsListView = options.songsListView;
        this._title = options.title;
        this._onKeyUpInSearchFieldHandler = options.onKeyUpInSearchFieldHandler;
        this._onSearchFieldClearedHandler = options.onSearchFieldClearedHandler;
        this._widgetHeight = options.widgetHeight;

        //PersistentModels.getInstance().getRouter().registerForViewportHeightChange("myLibraryPageView",this._onViewportHeightChanged.bind(this));
    },
    _onViewportHeightChanged : function(newViewPortHeigh, forceResizing) {
        if(PersistentModels.getInstance().getRouter().isEqualToCurrentPageUrl("#mylibrary")  || forceResizing){
            if (this.el) {
                /*const tabHeight = 46;
                const subtabHeight = 44;
                const adjustedListContainerHeight = (newViewPortHeigh - tabHeight - subtabHeight) ;
                if(this.$placeHolderGenreListEl){
                    this.$placeHolderGenreListEl.css("height", adjustedListContainerHeight + "px")
                }
                if(this.$placeHolderRatingListEl){
                    this.$placeHolderRatingListEl.css("height", adjustedListContainerHeight + "px")
                }*/
            }
        }
    },

    _onSearchFieldShowedOrHidden : function(searchFieldDisplayed){
        if(searchFieldDisplayed){

        }else{

        }
    },


    _buildSearchHeaderButtonAndToPage: function(){
        const customSearchFieldViewParams = {};
        customSearchFieldViewParams.searchFieldPlaceholderSelector = "#widgetTitle";
        customSearchFieldViewParams.searchFieldPlaceholderSelectorRootNodeEl  = this.el;
        this.customSearchFieldView = new CustomSearchFieldView(customSearchFieldViewParams);
        this.customSearchFieldView.render();
        this.customSearchFieldView.addKeyUpHandlerFunction(this._onKeyUpInSearchField.bind(this));
        this.customSearchFieldView.addOnSearchFieldClearedFunction(this._onSearchFieldCleared.bind(this));
        this.customSearchFieldView.addOnScreenResizeHandlerFunction(this._resizeSearchField.bind(this))
        this.customSearchFieldView.addOnSearchFieldShowedFunctionHandlers(this._onSearchFieldShowedOrHidden.bind(this));
        this.$el.find(".searchButtonWrapper").prepend(this.customSearchFieldView.el);
    },

    getSearchFieldValue : function(){
        return this.customSearchFieldView.getSearchFieldValue();
    },

    _resizeSearchField : function(searchField){
        /*const homeButtonWidth = 61;
        const searchButtonWidth = 56;
        const newSearchFieldWidth = (window.getDocumentClienWidth() - homeButtonWidth - searchButtonWidth ) + "px";
        searchField.width(newSearchFieldWidth);*/
    },
    _onKeyUpInSearchField : function(searchFieldValue){
        if(this._onKeyUpInSearchFieldHandler){
            this._onKeyUpInSearchFieldHandler(searchFieldValue);
        }
    },


    _onSearchFieldCleared : function(){
        if(this._onSearchFieldClearedHandler){
            this._onSearchFieldClearedHandler();
        }
    },

    focusOnSearchField :function(){
        setTimeout((function(){
            if(this.customSearchFieldView.getSearchFieldValue()){
                this.customSearchFieldView.selectAllText();
            }else{
                this.customSearchFieldView.focusSearchField();
            }
        }).bind(this), 50);
    },

    setSearchValue : function(newSearchValue){
        this.customSearchFieldView.setSearchFieldValue(newSearchValue, true);
    },


    render : function() {
        this.$el.html(this.template({"title": this._title}));
        this.setElement(this.$el.find("div").first());
        this._buildSearchHeaderButtonAndToPage();
        this.$el.find("#listContainer").append(this._songsListView.el);
        this.customSearchFieldView.showSearchField(true);
        this.$el.find(".searchButtonWrapper").hide();

        //this._onViewportHeightChanged(PersistentModels.getInstance().getRouter().getViewportHeight(), true);
        return this;
    }

});

export default SongSelectorListView;