import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import CustomSearchFieldView from "views/common/CustomSearchFieldView";
import selectionListTemplate from 'text!../../../templates/selectionList/selectionListTemplate.html';
import RSVP from 'rsvp';

const SelectionListView = Backbone.View.extend({

    template : _.template(selectionListTemplate),



    initialize : function(options) {
        this._listView = options.listView;
        this._enableSearch = options.enableSearch;
        this._delayInMilliSecondOnSearchQueryExecution = 500;
        
    },


    getSearchFieldValue : function(){
        return this.customSearchFieldView.getSearchFieldValue();
    },

    _resizeSearchField : function(searchField){
        /*const homeButtonWidth = 61;
        const searchButtonWidth = 56;
        const newSearchFieldWidth = (window.getDocumentClienWidth() - homeButtonWidth - searchButtonWidth ) + "px";
        searchField.width(newSearchFieldWidth);*/
    },

    _onKeyUpInSearchField : function(searchFieldValue){
        
        if(this._pendingSearchQueryId)
        {
            clearTimeout(this._pendingSearchQueryId);
        }
        //wait a certain amount of time before sending search event
        this._pendingSearchQueryId = setTimeout((function(){
            this.trigger("keyUpOnSearchField",searchFieldValue);
        }).bind(this), this._delayInMilliSecondOnSearchQueryExecution);
    },

    _onSearchFieldCleared : function(){
        this.trigger("searchFieldCleared");
    },

    focusOnSearchField :function(){
        setTimeout((function(){
            if(this.customSearchFieldView.getSearchFieldValue()){
                this.customSearchFieldView.selectAllText();
            }else{
                this.customSearchFieldView.focusSearchField();
            }
        }).bind(this), 50);
    },

    setSearchValue : function(newSearchValue){
        this.customSearchFieldView.setSearchFieldValue(newSearchValue, true);
    },
    
    _buildSearchHeaderButtonAndToPage: function(){
        const customSearchFieldViewParams = {};
        customSearchFieldViewParams.searchFieldPlaceholderSelector = ".widgetHeader";
        customSearchFieldViewParams.searchFieldPlaceholderSelectorRootNodeEl  = this.el;
        this.customSearchFieldView = new CustomSearchFieldView(customSearchFieldViewParams);
        this.customSearchFieldView.render();
        this.customSearchFieldView.addKeyUpHandlerFunction(this._onKeyUpInSearchField.bind(this));
        this.customSearchFieldView.addOnSearchFieldClearedFunction(this._onSearchFieldCleared.bind(this));
        this.customSearchFieldView.addOnScreenResizeHandlerFunction(this._resizeSearchField.bind(this))
        this.$el.find(".searchButtonWrapper").prepend(this.customSearchFieldView.el);
        this.$el.addClass("search_field_displayed");
    },

    focusOnSearchFieldIfApplicable : function(){
        if(this.customSearchFieldView){
            this.customSearchFieldView.focusSearchField();
        }
    },

    setSearchFieldValueIfApplicable : function(searchValue, triggerSeaarch){
        if(this.customSearchFieldView){
            this.customSearchFieldView.setSearchFieldValue(searchValue, triggerSeaarch);
        }
    },
    
    render : function() {
        this.$el.html(this.template({"title":""}));
        this.setElement(this.$el.find("div").first());
        this.$el.find(".listContainer").append(this._listView.$el);
        if(this._enableSearch){
            this._buildSearchHeaderButtonAndToPage();
        }
        if(this.customSearchFieldView){
            this.customSearchFieldView.showSearchField(true);
        }
        return this;
    }

});
export default SelectionListView;