
import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import AudioFilter from "models/audioEditor/filters/AudioFilter";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import RSVP from "rsvp";

const PRESETS ={};

const PRESET_IDS = {
    "SIDECHAIN_COMPRESSOR_WITH_VOICE_TRACK":"defaultSidechainCompressor"
};

const Rollout = RolloutHelper.getInstance();
const CACHING_AUDIO_NODE_ON_CHROME = Rollout.getFeatureVariable(Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR, Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR.variables.apply_chrome_workaround_by_resuing_audio_worklet_processor, true);
const USE_SCRIPT_PROCESSOR_IN_SAFARI = Rollout.getFeatureVariable(Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR, Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR.variables.apply_safari_workaround_for_audioworklet_incomplete_impl, true);


const PARAMS ={};
PARAMS.ATTACK = "/SIDECHAIN_COMPRESSOR/0x00/Compression_Response/Attack";
PARAMS.RELEASE = "/SIDECHAIN_COMPRESSOR/0x00/Compression_Response/Release";
PARAMS.RATIO = "/SIDECHAIN_COMPRESSOR/0x00/Compression_Control/Ratio";
PARAMS.THRESHOLD = "/SIDECHAIN_COMPRESSOR/0x00/Compression_Control/Threshold";
PARAMS.GAIN = "/SIDECHAIN_COMPRESSOR/Makeup_Gain";
PARAMS.PRE_GAIN = "PRE_GAIN";


const createSidechainCompressorConfigPreset =function(preGain, threshold, ratio, attack, release, gain){
    const presetParams = {};
    presetParams[PARAMS.THRESHOLD] = threshold;
    presetParams[PARAMS.RATIO] = ratio;
    presetParams[PARAMS.ATTACK] = attack;
    presetParams[PARAMS.RELEASE] = release;
    presetParams[PARAMS.GAIN] = gain;
    presetParams[PARAMS.PRE_GAIN] = preGain;
    return presetParams;
};

PRESETS[PRESET_IDS.SIDECHAIN_COMPRESSOR_WITH_VOICE_TRACK] = createSidechainCompressorConfigPreset(0.25, -40, 4, 0.01, 2000, 1);

const CLASSNAME = "SidechainCompressorFilter";
const SidechainCompressorFilter =  AudioFilter.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        AudioFilter.apply(this, [attributes, options]);
        this.setPresetIdToConfigMap(PRESETS);
        this.set("type", SidechainCompressorFilter.CLASSNAME);
        
    },
    
    setAttack : function(attack){
        this.set(PARAMS.ATTACK,attack);
    },

    getAttack : function(){
        return this.get(PARAMS.ATTACK);
    },

    setThreshold : function(threshold){
        this.set(PARAMS.THRESHOLD,threshold);
    },

    getThreshold : function(){
        return this.get(PARAMS.THRESHOLD);
    },

    setRelease : function(release){
        this.set(PARAMS.RELEASE,release);
    },

    getRelease : function(){
        return this.get(PARAMS.RELEASE);
    },

    setRatio : function(ratio){
        this.set(PARAMS.RATIO,ratio);
    },

    getRatio : function(){
        return this.get(PARAMS.RATIO);
    },

    setGain : function(gain){
        this.set(PARAMS.GAIN,gain);
    },

    getGain : function(){
        return this.get(PARAMS.GAIN);
    },

    setPreGain : function(preGain){
        this.set(PARAMS.PRE_GAIN,preGain);
    },

    getPreGain : function(){
        return this.get(PARAMS.PRE_GAIN);
    },

    


    isEqual : function(filter){
        if(filter instanceof SidechainCompressorFilter){
            return (this.isDisabled() == filter.isDisabled() && this.getAttack() === filter.getAttack() && this.getThreshold() === filter.getThreshold() && this.getRelease() === filter.getRelease() && this.getRatio() === filter.getRatio() && this.getGain() === filter.getGain() && this.getPreGain() === filter.getPreGain() ) 
        }
        return false;
    },

    clone : function(){
        const cloneFilter = new SidechainCompressorFilter();
        cloneFilter.setDisabled(this.isDisabled());
        cloneFilter.setThreshold(this.getThreshold());
        cloneFilter.setRelease(this.getRelease());
        cloneFilter.setRatio(this.getRatio());
        cloneFilter.setGain(this.getGain());
        cloneFilter.setPreGain(this.getPreGain());

        return cloneFilter;
    },

    needToPrepareAudioContext: function(){
        return true;
    },

    prepareAudioContext: function(audioContext){
        return RSVP.Promise.resolve(audioContext);
    },

    _applyParameterOnNode:function(sidechainCompressorNode,paramName){
        sidechainCompressorNode.setParamValue(paramName,this.get(paramName));
    },

    applyFilterToNode : function(audioNode, audioContext, arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime, sequencerRendererTracksInfo, createdFilterNode  , sourceNodeToMonitor){
        if(!this.isDisabled()){
            
            // Create a compressor node
            const sidechainCompressorNode = createdFilterNode;
            this._applyParameterOnNode(sidechainCompressorNode, PARAMS.RATIO);
            this._applyParameterOnNode(sidechainCompressorNode, PARAMS.ATTACK);
            this._applyParameterOnNode(sidechainCompressorNode, PARAMS.RELEASE);
            this._applyParameterOnNode(sidechainCompressorNode, PARAMS.THRESHOLD);
            this._applyParameterOnNode(sidechainCompressorNode, PARAMS.GAIN);

            

            
            if(!sourceNodeToMonitor){
                if(sequencerRendererTracksInfo){
                    if(PRESET_IDS.SIDECHAIN_COMPRESSOR_WITH_VOICE_TRACK === this.getPreset()){
                        sourceNodeToMonitor = sequencerRendererTracksInfo.getEndNodeOnVoiceTrack();
                    }
                }
            }


            const preGainForSourceToModulateNode =  audioContext.createGain();
            preGainForSourceToModulateNode.gain.value = this.getPreGain(); 
            const audioNodeMerger = audioContext.createChannelMerger(2);
            audioNode.connect(preGainForSourceToModulateNode);
            preGainForSourceToModulateNode.connect(audioNodeMerger, 0, 0);
            sourceNodeToMonitor.connect(audioNodeMerger, 0, 1);

            audioNodeMerger.connect(sidechainCompressorNode);


            if(arrayOfAudioNodesUsed){
                arrayOfAudioNodesUsed.push(audioNodeMerger);
                arrayOfAudioNodesUsed.push(preGainForSourceToModulateNode);
                arrayOfAudioNodesUsed.push(sidechainCompressorNode);
            }
            return sidechainCompressorNode;

        }else{
            return audioNode;
        }
    },

    _createSidechainCompressorNode : function(audioContext){
        return new RSVP.Promise(function(resolve, reject){
            require(["libs/sidechainCompressor/SidechainCompressor", "libs/sidechainCompressor/SidechainCompressorScriptProcessor"],function({SidechainCompressor}, {SidechainCompressorScriptProcessor}){
                if((window.isSafariBrowser || window.isIOSMobileDevice) && USE_SCRIPT_PROCESSOR_IN_SAFARI){
                    return SidechainCompressorScriptProcessor.createDSP(audioContext, 1024, "js/libs/sidechainCompressor").then(resolve).catch(reject);
                }else{
                    const SidechainCompressorFactory = new SidechainCompressor(audioContext,"js/libs/sidechainCompressor");
                    if(window.chrome && CACHING_AUDIO_NODE_ON_CHROME){
                        if(audioContext._sidechainCompressors){
                            const sidechainCompressorAvailable = audioContext._sidechainCompressors.find(function(sidechainComp){
                                return !sidechainComp.isConnected;
                            })
                            if(sidechainCompressorAvailable){
                                return resolve(sidechainCompressorAvailable);
                            }
                        }
                    }
                    return SidechainCompressorFactory.load().then(resolve).catch(reject);
                }	
            })

        })
    },

    applyFilter : function(previousOutputNode, audioContext, arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime,  sequencerRendererTracksInfo,audioBuffer){
        if(!this.isDisabled()){
            if(!previousOutputNode){
                previousOutputNode = audioContext.createBufferSource();
                previousOutputNode.buffer = audioBuffer;
                if(arrayOfAudioNodesUsed){
                    arrayOfAudioNodesUsed.push(previousOutputNode);
                }
            }
            return this._createSidechainCompressorNode(audioContext).then((function(sidechainCompressorNode){
                if(window.chrome && CACHING_AUDIO_NODE_ON_CHROME && sidechainCompressorNode){
                    if(!audioContext._sidechainCompressors){
                        audioContext._sidechainCompressors = [];
                    }
                    if(audioContext._sidechainCompressors && !audioContext._sidechainCompressors.includes(sidechainCompressorNode)){
                        audioContext._sidechainCompressors.push(sidechainCompressorNode);
                    }
                }
                sidechainCompressorNode.isConnected = true;
                return this.applyFilterToNode(previousOutputNode, audioContext,arrayOfAudioNodesUsed, audioBufferStartTime,audioBufferEndTime, sequencerRendererTracksInfo, sidechainCompressorNode);
            }).bind(this));


        }else{
            return RSVP.Promise.resolve(previousOutputNode);
        }
    },


});

SidechainCompressorFilter.PRESET_IDS = PRESET_IDS;
SidechainCompressorFilter.PRESETS = PRESETS;
SidechainCompressorFilter.CLASSNAME = CLASSNAME;

export default SidechainCompressorFilter; 