

import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerOperation from "models/audioEditor/sequencerOperation/SequencerOperation";
import VideoSequencerNode from "models/audioEditor/sequencerNodes/VideoSequencerNode";
import AudioBufferHelper from "models/audioEditor/AudioBufferHelper";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const InsertVideoNodeOperation =  SequencerOperation.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperation.apply(this, [attributes, options]);
    },

    execute : function(trebbleVideo, sequencerNodeToInsertNextTo, insertBefore, introPaddingInMilliseconds, outroPaddingInMilliseconds, introFadeInMilliseconds,outroFadeInMilliseconds, volumePercentage, silent){
        const videoSequencerNode = new VideoSequencerNode({"audioSegment":Utils.getInstance().getCloudVideoSegment(trebbleVideo.getAudioUrl(),null, null,null, null, trebbleVideo.getWidth(), trebbleVideo.getHeight()), "sequencer": this.getSequencer()})
        videoSequencerNode.setVideo(trebbleVideo);
        videoSequencerNode.setIntroFadeInMilliseconds(introFadeInMilliseconds);
        videoSequencerNode.setOutroFadeInMilliseconds(outroFadeInMilliseconds);
        videoSequencerNode.setIntroPaddingInMilliseconds(introPaddingInMilliseconds);
        videoSequencerNode.setOutroPaddingInMilliseconds(outroPaddingInMilliseconds);
        videoSequencerNode.setMainVolumePercentage(volumePercentage);
        //this.insertVideoNode(videoSequencerNode, sequencerNodeToInsertNextTo, insertBefore);
        this.insertAudioNodeArray([videoSequencerNode], sequencerNodeToInsertNextTo, insertBefore);
        this.getSequencer().getAudioBufferCache().loadAudioBufferFromUrl(trebbleVideo.getAudioUrl(), trebbleVideo.getAudioUrl(), AudioBufferHelper.getInstance().getAudioTypes().INSERT, this.getSequencer().getSequencerSettings());
        if(!silent){
            this.getSequencer().getHistoryManagement().addCreateInsertAudioNodeLog(videoSequencerNode,sequencerNodeToInsertNextTo , insertBefore);
        }
        return videoSequencerNode;
    },

    /*insertVideoNode : function(audioSequencerNodeToInsert, sequencerNodeToInsertNextTo, insertBefore ){
        if(sequencerNodeToInsertNextTo){
            if(insertBefore){
                audioSequencerNodeToInsert.insertBefore(sequencerNodeToInsertNextTo);
                if(sequencerNodeToInsertNextTo == this.getSequencer().getFirstNode()){
                    this.getSequencer().setFirstNode(audioSequencerNodeToInsert);
                }
            }else{
                audioSequencerNodeToInsert.insertAfter(sequencerNodeToInsertNextTo);
                if(sequencerNodeToInsertNextTo == this.getSequencer().getLastNode()){
                    this.getSequencer().setLastNode(audioSequencerNodeToInsert);
                }
            }
        }else{
            //this is to handle the unique case where the sequencer is empty
            audioSequencerNodeToInsert.addToSequencer();
            this.getSequencer().setFirstNode(audioSequencerNodeToInsert);
            this.getSequencer().setLastNode(audioSequencerNodeToInsert);
        }
    },

    removeVideoNode : function(audioSequencerNode){
        const nextNode =  audioSequencerNode.getNext();
        const previousNode =  audioSequencerNode.getPrevious();

        audioSequencerNode.clearNext();
        if(previousNode){
            previousNode.clearNext();
        }
        if(previousNode && nextNode){
            previousNode.setNext(nextNode);
        }
        if(audioSequencerNode == this.getSequencer().getFirstNode()){
            this.getSequencer().setFirstNode(nextNode);
        }

        if(audioSequencerNode == this.getSequencer().getLastNode()){
            this.getSequencer().setLastNode(previousNode);
        }

        audioSequencerNode.removeFromSequencer();
    },*/

    undo: function(insertVideoNodeLog){
        this.removeAudioNode(insertVideoNodeLog.getVideoSequencerNode());
    },

    redo: function(insertVideoNodeLog){
        this.insertAudioNodeArray([insertVideoNodeLog.getVideoSequencerNode()], insertVideoNodeLog.getSequencerNodeToInsertNextTo(), insertVideoNodeLog.getInsertBefore());
    },





});

export default InsertVideoNodeOperation; 