import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import CordovaHelper from "models/helper/CordovaHelper";
import Song from "models/Song";
import AddMusicFromYoutubeView from "views/addMusicFromYoutube/AddMusicFromYoutubeView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import TrebbleIndexDBHelper from "models/helper/TrebbleIndexDBHelper";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const AddMusicFromYoutube = Chaplin.Controller.extend({

    initialize : function(options) {
        this.model = options.model;
        this.isTrebblePlayingMusic = options.isTrebblePlayingMusic;
        _.extend(this, Backbone.Events);
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },


    
    
    addSongToRadio : function(){
        $.mobile.loading( 'show');
        const songJSONInfoToAdd = Utils.getInstance().getTrebblesSongJsonFromYoutubeVideoInfo(this.model.toJSON());
        const songModel = Utils.getInstance().getModelFromSongJson(songJSONInfoToAdd);
        const context = Utils.getInstance().buildContextForSongCollectionFromYoutubeApp();
        return PersistentModels.getInstance().addSongToDefaultRadio(songModel, context).then(function(){
            window.log("song added to radio");
            $.mobile.loading( 'hide');
            return CordovaHelper.getInstance().executePluginOperation("MusicFileReaderPlugin","triggerBackButtonPressed", []);
        }).catch(function(error){
            $.mobile.loading( 'hide');
            window.alertErrorMessage(error);
        });
    },
    
    onViewRemoved:function(){
        this.stopListening();
    },


    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                
                this.view = new AddMusicFromYoutubeView({
                    "model" : this.model,
                    "isTrebblePlayingMusic": this.isTrebblePlayingMusic,
                });
                this.listenTo(this.view, "addSongAsFavorite",this.addSongAsFavorite);
                this.listenTo(this.view, "addSongToDefaultRadio",this.addSongToRadio);
                this.listenTo(this.view, "remove", this.onViewRemoved);
                this.listenTo(this.view, "updateSongOfCurrentPlayingSong",this.updateSongOfCurrentPlayingSong);
                //this.listenTo(this.view, "playSimilar",this.playSimilarFromLibrary);
                //this.listenTo(this.view, "browseArtist",this.browseArtist);
                this.view.render();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default AddMusicFromYoutube;