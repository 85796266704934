import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import CordovaHelper from "models/helper/CordovaHelper";
import GlobalSearchPageView from "views/globalSearch/GlobalSearchPageView";
import SongListController from "controllers/common/SongListController2";
import AlbumListController from "controllers/common/AlbumListController2";
import ArtistListController from "controllers/common/ArtistListController2";
import UserListController from "controllers/common/UserListController";
import PlaylistListController from "controllers/common/PlaylistListController";
import JourneyCollection from "collections/JourneyCollection";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import SoundcloudSearchHelper from "models/helper/SoundcloudSearchHelper";
import YoutubeSearchHelper from "models/helper/YoutubeSearchHelper";
import TrebbleIndexDBHelper from "models/helper/TrebbleIndexDBHelper";
import InfoBoxController from "controllers/common/InfoBoxController";
import SongIdentifiedDetailPopupController from "controllers/songIdentifiedDetail/SongIdentifiedDetailPopupController";
import Utils from "models/helper/Utils";
import SongIdentificationService from "services/SongIdentificationService";
import ti18n from "i18n!nls/GlobalSearchi18n";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";

const DEFAULT_SEACH_DEALY_IN_MS = 300;
const DEFAULT_NUMBER_OF_RESULTS = (window.window.device && window.device.platform =="browser")? 4 : 4;
const GlobalSearchController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if (options) {
            this.model = options.searchKeyword;
        }


    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    _searchForSongsAlbumsArtists : function(searchFieldValue){

        this.searchForMyLibrarySongs(searchFieldValue);
        if(this._idOfLastRequest){
            this._idOfLastRequest = this._idOfLastRequest + 1;
        }else{
            this._idOfLastRequest =  1;
        }

        this.songListController.cancelPendingImageLoading();
        this.albumListController.cancelPendingImageLoading();
        this.artistListController.cancelPendingImageLoading();
        TrebbleClientAPIHelper.getInstance().searchForSongsAlbumsArtists(searchFieldValue.trim(), DEFAULT_NUMBER_OF_RESULTS).then((function(results){
            results = results? results: {"songs" : [], "albums": [], "artists": [] };
            let shouldBeUpdated = false;


            if(!this.self._idOfLastRequestResolve ||  (this.self._idOfLastRequestResolve && this.requestId > this.self._idOfLastRequestResolve))
            {
                shouldBeUpdated = true;
                this.self._idOfLastRequestResolve = this.requestId;
            }
            if(shouldBeUpdated){
                this.self.songListController.updateListOfSongs(results.songs);
                this.self.albumListController.updateListOfAlbums(results.albums);
                this.self.artistListController.updateListOfArtists(results.artists);
            }
        }).bind({"self" :this, "requestId" : this._idOfLastRequest}));
        this.searchForSoundcloudSongs(searchFieldValue);
        this.searchForYoutubeVideos(searchFieldValue);
        this.searchForUsers(searchFieldValue);
        this.searchForTrebbles(searchFieldValue);
        this.searchForJourneys(searchFieldValue);
    },

    searchForSoundcloudSongs : function(searchFieldValue){
        if(this._idOfSoundcloudLastRequest){
            this._idOfSoundcloudLastRequest = this._idOfSoundcloudLastRequest + 1;
        }else{
            this._idOfSoundcloudLastRequest =  1;
        }

        this.soundcloudSongListController.cancelPendingImageLoading();
        SoundcloudSearchHelper.getInstance().searchSongs(searchFieldValue.trim(), DEFAULT_NUMBER_OF_RESULTS).then((function(arrayOfSoundcloudSongs){
            let shouldBeUpdated = false;

            if(!this.self._idOfSoundcloudLastRequestResolve ||  (this.self._idOfSoundcloudLastRequestResolve && this.requestId > this.self._idOfSoundcloudLastRequestResolve))
            {
                shouldBeUpdated = true;
                this.self._idOfSoundcloudLastRequestResolve = this.requestId;
            }
            if(shouldBeUpdated){
                const arrayOfSoundcloudSongsWithAlbumAndArtistRef = TrebbleIndexDBHelper.getInstance().getSongsWithAlbumAndArtistInfoFromSongJsonArray(arrayOfSoundcloudSongs);
                this.self.soundcloudSongListController.updateListOfSongs(arrayOfSoundcloudSongsWithAlbumAndArtistRef);
            }
        }).bind({"self" :this, "requestId" : this._idOfSoundcloudLastRequest}));

    },

    searchForYoutubeVideos : function(searchFieldValue){
        if(this._idOfYoutubeLastRequest){
            this._idOfYoutubeLastRequest = this._idOfYoutubeLastRequest + 1;
        }else{
            this._idOfYoutubeLastRequest =  1;
        }

        YoutubeSearchHelper.getInstance().getSongJsonFromVideos(searchFieldValue.trim(), DEFAULT_NUMBER_OF_RESULTS).then((function(arrayOfYoutubeVideoInfo){
            let shouldBeUpdated = false;

            if(!this.self._idOfYoutubeLastRequestResolve ||  (this.self._idOfYoutubeLastRequestResolve && this.requestId > this.self._idOfYoutubeLastRequestResolve))
            {
                shouldBeUpdated = true;
                this.self._idOfYoutubeLastRequestResolve = this.requestId;
            }
            if(shouldBeUpdated){
                const arrayOfYoutubeVideosWithAlbumAndArtistRef = TrebbleIndexDBHelper.getInstance().getSongsWithAlbumAndArtistInfoFromSongJsonArray(arrayOfYoutubeVideoInfo);
                this.self.youtubeVideoSongListController.updateListOfSongs(arrayOfYoutubeVideosWithAlbumAndArtistRef);
            }
        }).bind({"self" :this, "requestId" : this._idOfYoutubeLastRequest}));
        this.youtubeVideoSongListController.cancelPendingImageLoading();
    },

    searchForMyLibrarySongs : function(searchFieldValue){
        if(this._idOfMyLibraryLastRequest){
            this._idOfMyLibraryLastRequest = this._idOfMyLibraryLastRequest + 1;
        }else{
            this._idOfMyLibraryLastRequest =  1;
        }

        setTimeout((function(){
            const songModelsInLibraryFound = PersistentModels.getInstance().getSongCollection().searchOnTitleAndArtist(searchFieldValue, DEFAULT_NUMBER_OF_RESULTS);
            if(songModelsInLibraryFound && songModelsInLibraryFound.length < DEFAULT_NUMBER_OF_RESULTS){
                const favortieSongSearchResultSize = (songModelsInLibraryFound && songModelsInLibraryFound.length < DEFAULT_NUMBER_OF_RESULTS)? DEFAULT_NUMBER_OF_RESULTS - songModelsInLibraryFound : DEFAULT_NUMBER_OF_RESULTS;
            }
            let shouldBeUpdated = false;

            if(!this.self._idOfMyLibraryLastRequestResolve ||  (this.self._idOfMyLibraryLastRequestResolve && this.requestId > this.self._idOfMyLibraryLastRequestResolve))
            {
                shouldBeUpdated = true;
                this.self._idOfMyLibraryLastRequestResolve = this.requestId;
            }
            if(shouldBeUpdated){
                const arrayOfSongJson = [];
                if(songModelsInLibraryFound){
                    for(let index in songModelsInLibraryFound)
                    {
                        const songModelFound = songModelsInLibraryFound[index];
                        arrayOfSongJson.push(songModelFound.toJSON());
                    }
                }
                this.self.myLibrarySongListController.updateListOfSongs(arrayOfSongJson, this.searchKeyword);
            }
        }).bind({"self" :this, "requestId" : this._idOfMyLibraryLastRequest,"searchKeyword": searchFieldValue}),DEFAULT_SEACH_DEALY_IN_MS);
},

searchForUsers : function(searchFieldValue){
if(this._idOfUsersLastRequest){
this._idOfUsersLastRequest = this._idOfUsersLastRequest + 1;
}else{
this._idOfUsersLastRequest =  1;
}

this.usersListController.cancelPendingImageLoading();
TrebbleClientAPIHelper.getInstance().searchForUsers(searchFieldValue.trim(), DEFAULT_NUMBER_OF_RESULTS).then((function(arrayOfUsersFound){
let shouldBeUpdated = false;

if(!this.self._idOfUserSearchLastRequestResolve ||  (this.self._idOfUserSearchLastRequestResolve && this.requestId > this.self._idOfUserSearchLastRequestResolve))
{
    shouldBeUpdated = true;
    this.self._idOfUserSearchLastRequestResolve = this.requestId;
}
if(shouldBeUpdated){
    this.self.usersListController.updateListOfUsers(arrayOfUsersFound);
}
}).bind({"self" :this, "requestId" : this._idOfUsersLastRequest}));

},

searchForTrebbles : function(searchFieldValue){
if(this._idOfTrebblesLastRequest){
this._idOfTrebblesLastRequest = this._idOfTrebblesLastRequest + 1;
}else{
this._idOfTrebblesLastRequest =  1;
}

this.playlistListController.cancelPendingImageLoading();
TrebbleClientAPIHelper.getInstance().searchForTrebbles(searchFieldValue.trim(), DEFAULT_NUMBER_OF_RESULTS).then((function(arrayOfTrebbleFound){
    let shouldBeUpdated = false;

if(!this.self._idOfTrebbleSearchLastRequestResolve ||  (this.self._idOfTrebbleSearchLastRequestResolve && this.requestId > this.self._idOfTrebbleSearchLastRequestResolve))
{
    shouldBeUpdated = true;
    this.self._idOfTrebbleSearchLastRequestResolve = this.requestId;
}
if(shouldBeUpdated){
    this.self.playlistListController.updateListOfPlaylists(arrayOfTrebbleFound);
}
}).bind({"self" :this, "requestId" : this._idOfTrebblesLastRequest}));

},


searchForJourneys : function(searchFieldValue){
if(this._idOfJourneysLastRequest){
this._idOfJourneysLastRequest = this._idOfJourneysLastRequest + 1;
}else{
this._idOfJourneysLastRequest =  1;
}

this.journeyListController.cancelPendingImageLoading();
TrebbleClientAPIHelper.getInstance().searchForJourneys(searchFieldValue.trim(), DEFAULT_NUMBER_OF_RESULTS).then((function(arrayOfJourneyFound){
    let shouldBeUpdated = false;

if(!this.self._idOfJourneySearchLastRequestResolve ||  (this.self._idOfJourneySearchLastRequestResolve && this.requestId > this.self._idOfJourneySearchLastRequestResolve))
{
    shouldBeUpdated = true;
    this.self._idOfJourneySearchLastRequestResolve = this.requestId;
}
if(shouldBeUpdated){
    this.self.journeyListController.updateListOfPlaylists(arrayOfJourneyFound);
}
}).bind({"self" :this, "requestId" : this._idOfJourneysLastRequest}));

},

showHomePage : function(){
PersistentModels.getInstance().getRouter().showHomePage();
},

showAllSongsForSearchKeyword : function(searchKeyword){
PersistentModels.getInstance().getRouter().showSongListPage(searchKeyword.trim(), true);
},

showAllAlbumsForSearchKeyword : function(searchKeyword){
PersistentModels.getInstance().getRouter().showAlbumListPage(searchKeyword.trim(), true);
},

showAllArtistsForSearchKeyword : function(searchKeyword){
PersistentModels.getInstance().getRouter().showArtistListPage(searchKeyword.trim(), true);
},

showAllSoundcloudSongsForSearchKeyword : function(searchKeyword){
PersistentModels.getInstance().getRouter().showSoundcloudSongListPage(searchKeyword.trim(), true);
},

showAllYoutubeVideoForSearchKeyword : function(searchKeyword){
PersistentModels.getInstance().getRouter().showYoutubeVideoListPage(searchKeyword.trim(), true);
},

showAllSongInMyLibraryForSearchKeyword : function(searchKeyword){
PersistentModels.getInstance().getRouter().showMyLibraryPage(searchKeyword, null, true);
},

showAllJourneysMatchingCriteria: function(searchKeyword){
PersistentModels.getInstance().getRouter().showJourneyListPage(searchKeyword, true);
},

showAllUsersMatchingCriteria : function(searchKeyword){
PersistentModels.getInstance().getRouter().showUserListPage(searchKeyword, true);
},

showAllTrebblesMatchingCriteria : function(searchKeyword){
PersistentModels.getInstance().getRouter().showTrebbleListPage(searchKeyword, true);
},

_buildInfoBoxController : function(){
const params = {};
params.iconClass = "pe-7s-search";
params.message =  window.getI18n(ti18n, "SEARCH_TREBBLE_FM");
params.helpMessage = window.trebble.doNotShowMusicInSearchPage ? window.getI18n(ti18n, "FIND_USERS_TREBBLES") :window.getI18n(ti18n, "FIND_SONGS_ALBUMS_ARTISTS_USERS");
const infoBoxController = new InfoBoxController(params);
return infoBoxController;
},

setSearchValue : function(searchFieldValue){
this.getView().setSearchValue(searchFieldValue,true);
},

onActionExecutedOnSongHandler : function(songModel, action){
if(action == "delete" || action == "removeFromRadio" || action == "removeToFavorite"){
this.myLibrarySongListController.removeSongFromCollection(songModel);
}
},

analyseAudioOutput : function(){
$.mobile.loading( 'show', {
text: window.getI18n(ti18n, "ANALYSING_AUDIO_OUTPUT"),
textVisible: false,
theme: "t",
textonly: false,
// html: html
});
CordovaHelper.getInstance().executePluginOperation("MusicFileReaderPlugin","analyseAudioOutput", []).then((function(result){
if(result && result != "OK" && result.length >0 && result[0] && result[0] != "OK")
{
    let songIdentifiedModel = result[0].idifiedSongInfo;
    const basicSongInfo = result[0].basicSongInfo;

    //NEED TO CALL SERVER TO TRY TO GET AN APPROXIMATE RELEASE DATE
    return SongIdentificationService.getInstance().addPossibleReleaseDateToIdifiedInfo(songIdentifiedModel).then((function(updatedIdifiedSongInfo){
        songIdentifiedModel = updatedIdifiedSongInfo;
        $.mobile.loading( "hide" );
        return this._showSongIdentifiedDetails(songIdentifiedModel);
    }).bind(this)).catch((function(error){
        //Should never get there anyway
        $.mobile.loading( "hide" );
        return this._showSongIdentifiedDetails(songIdentifiedModel);
    }).bind(this));

}else{
    $.mobile.loading( "hide" );
    if(result && result.operationCancelled){
        //operation was cancelled so do nothing
    }else{
        window.alertErrorMessage(window.getI18n(ti18n, "THE_SONG_PLAYING_COULDNT_BE_MATCHED"));
    }

}
}).bind(this)).catch(function(error){
$.mobile.loading( "hide" );
window.alertErrorMessage(JSON.stringify(error));
});
},

_showSongIdentifiedDetails: function(songIdentifiedModel){
const songIdentifiedDetailControllerParams = {};
songIdentifiedDetailControllerParams.model =songIdentifiedModel;
songIdentifiedDetailControllerParams.isTrebblePlayingMusic = PlayerService.getInstance().isMusicPlaying();
if(songIdentifiedDetailControllerParams.isTrebblePlayingMusic){
songIdentifiedDetailControllerParams.context = PlayerService.getInstance().getPlayerQueueContext();
}else{
songIdentifiedDetailControllerParams.context = Utils.getInstance().buildContextForSongCollectionOverTheAir();
}
const songIdentifiedDetailPopupController = new SongIdentifiedDetailPopupController(songIdentifiedDetailControllerParams);
return songIdentifiedDetailPopupController.buildModelAndRenderView();
},

onPlaylistSelected : function(playlistSummarySelectedModel){
PersistentModels.getInstance().getRouter().showPublicRadioDetailPage(playlistSummarySelectedModel, true);
},

onJourneySelected : function(journeySummarySelectedModel){
PersistentModels.getInstance().getRouter().showPublicJourneyDetailPage(journeySummarySelectedModel, true);
},

onPlaylistPressedHandler : function(playlistSummarySelectedModel){
if(playlistSummarySelectedModel){
const audioPreviewUrl = playlistSummarySelectedModel.getAudioPreviewUrl();
if(audioPreviewUrl){
    PersistentModels.getInstance().getPlayer().playAudioPreview(audioPreviewUrl);
}else{
    Utils.getInstance().showShortToastMessage(window.getI18n(ti18n, "NO_PREVIEWS_AVAILABLE") + window.getI18n(ti18n, "PREVIEWS_ARE_AUTOMATICALLY_CREATED_EVERY_X_HOURS"));
}
}
},

onPlaylistPressedUpHandler : function(playlistSummarySelectedModel){
PersistentModels.getInstance().getPlayer().terminateAudioPreviewAndResumePlayback();
},

_onPersonalRadioEditButtonClicked : function(playlistSummarySelectedModel){
PersistentModels.getInstance().getRouter().showTrebbleInfoEditPage(playlistSummarySelectedModel, true);
},

_onJourneyEditButtonClicked : function(journeySummarySelectedModel){
PersistentModels.getInstance().getRouter().showJourneyInfoEditPage(journeySummarySelectedModel, true);
},

_onPersonalRadioShareButtonClicked : function(playlistSummarySelectedModel){
PersistentModels.getInstance().getRouter().showTrebbleSharePopup(playlistSummarySelectedModel, true);
},

_onJourneyShareButtonClicked : function(journeySummarySelectedModel){
PersistentModels.getInstance().getRouter().showShareJourneyPopup(journeySummarySelectedModel.getLoaderKey(), true);
},

onPlaylistPlayButtonTapedHandler  : function(playlistSummarySelectedModel){
if(PlayerService.getInstance().isPlaylistCurrentlyLoaded(playlistSummarySelectedModel)){
if(PlayerService.getInstance().isMusicPlaying()){
    PlayerService.getInstance().pauseMusicPlaying();
}else{
    PlayerService.getInstance().resumeMusicPlaying();
}
}else{
PlayerService.getInstance().playTrebble(playlistSummarySelectedModel);
}
},

getCustomMapFunctionToConvertJourneyJsonObjToTemplateData : function(){
return function(journeyRadioJSON){
const journeySummaryModel = Utils.getInstance().buildJourneySummaryFromJson(journeyRadioJSON);
let journeySummaryHtml = 	'<span class="journey_stats"><span class="radio_type_tag journey_tag"><i class="pe-7s-sun journeyIcon" ></i>'+  window.getI18n(ti18n, "JOURNEY");
if(journeySummaryModel.getJourneyLengthInDays()){
    journeySummaryHtml = journeySummaryHtml + '<span class="journey_length_label">'+' · '+ '<i class="fontello-icon-list journeyOultlineListIcon" ></i>' + journeySummaryModel.getJourneyLengthInDays()+ " "+ window.getI18n(ti18n, "DAYS")+'</span></span>';
}
if(journeySummaryModel.getJourneyDurationInMinutes()){
    journeySummaryHtml = journeySummaryHtml + '<span class="journey_duration_label">'+' · '+ '<i class="ion-ios7-time-outline journeyOultlineListIcon" ></i>' + journeySummaryModel.getJourneyDurationInMinutesToDisplay()+'</span></span>';
}
if(journeySummaryModel.getAverageRatings() > 0){
    journeySummaryHtml  = journeySummaryHtml + '<span class="journey_rating_label">'+' · '+ '<i class="ion-ios7-star journeyOultlineListIcon" ></i>' + Math.round(journeySummaryModel.getAverageRatings(),1 )+'</span></span>';
}

const journeyName = journeySummaryModel.getName();
const journeyStatSummary = journeySummaryHtml;
const coverArtUrl = journeySummaryModel.getCoverArtUrl()?window.trebble.globalHelpers.getResizeImageUrl(journeySummaryModel.getCoverArtUrl(), 100, 100): "";
const templateData = {playlistCoverArtUrl: coverArtUrl,firstLineText: journeyName,secondLineText: journeyStatSummary ,model : journeyRadioJSON, doNotShowSubscribeButton: true,doNotShowGradeButton: true,doNotShowOptionButton: true, doNotShowCoverImage: false };
return templateData;

};
},

getJourneyCustomItemViewUpdateDuringScrollingHandlerForExtendedView : function(){
return function(elem,templateData){
try{
    const coverArtImageElem = elem.querySelector("#coverArtImage");
    coverArtImageElem.src = window.getResourceURLAccordingToLocationProtocol("/img/videoLoading.png");
    coverArtImageElem.setAttribute("data-original", templateData.coverArtUrl);
    if(!coverArtImageElem.className ||  coverArtImageElem.className.indexOf("lazy") == -1){
        coverArtImageElem.className = (!coverArtImageElem.className)? "lazy" :  coverArtImageElem.className +" lazy";
    }
    const playpauseBtnElem = elem.querySelector(".playButton");
    if(playpauseBtnElem){
        if(templateData.isPlaying){
            playpauseBtnElem.className = "ion-ios7-pause-outline playButton";
        }else{
            playpauseBtnElem.className = "ion-ios7-play-outline playButton";
        }
    }

    const previewBtn = elem.querySelector(".previewBtn");
    if(previewBtn){
        if(previewBtn && templateData.audioPreviewUrl){
            previewBtn.className = previewBtn.className.replace( /(?:^|\s)notAvailable(?!\S)/g , '' );
        }else{
            if(!previewBtn.className.indexOf("notAvailable") != -1){
                previewBtn.className = previewBtn.className + " " + "notAvailable";
            }
        }
    }


    const playlistItemElem = elem;//.querySelector(".playlistItem");
    playlistItemElem.id = templateData.playlistId? "playlistId"+templateData.playlistId :  "playlistId";
    const titleElem = elem.querySelector(".title");
    titleElem.textContent = templateData.name? templateData.name :  "";
    const descriptionElem = elem.querySelector(".description");
    descriptionElem.textContent = templateData.description? templateData.description: "";
    const summaryElem = elem.querySelector(".summary");
    summaryElem.textContent = templateData.artistSample? templateData.artistSample: "";
    const authorElem = elem.querySelector(".author");
    authorElem.textContent = templateData.author? templateData.author: "";
    const lastUpdateTimeElem = elem.querySelector(".lastUpdateTime");
    lastUpdateTimeElem.textContent = templateData.lastModificationDate? "Last updated on "+ templateData.lastModificationDate: "";
    const numberOfSongsElem = elem.querySelector("#numberOfSongs");
    numberOfSongsElem.textContent = templateData.numberOfSongs? templateData.numberOfSongs : 0;
    const totalNumberOfPlaysElem = elem.querySelector("#totalNumberOfPlays");
    totalNumberOfPlaysElem.textContent = templateData.totalNumberOfPlays? templateData.totalNumberOfPlays : 0;
    const numberOfSongsCommentsElem = elem.querySelector("#numberOfSongsComments");
    numberOfSongsCommentsElem.textContent = templateData.numberOfSongsComments? templateData.numberOfSongsComments : 0;
}catch(error){
    window.alertErrorMessage(error);
}
}
},

getJourneyCustomItemViewUpdateDuringScrollingHandler : function(){
return function(elem,templateData){
const imageEl = elem.querySelector(".songCoverArt");
imageEl.src = window.getResourceURLAccordingToLocationProtocol("/img/videoLoading.png");
imageEl.setAttribute("data-original", templateData.playlistCoverArtUrl);
if(!imageEl.className ||  imageEl.className.indexOf("lazy") == -1){
    imageEl.className = (!imageEl.className)? "lazy" :  imageEl.className +" lazy";
}
const firstItemElem = elem.querySelector(".songTitleLibrary");
firstItemElem.textContent = templateData.firstLineText;
const secondItemElem = elem.querySelector(".songArtistLibrary");
secondItemElem.innerHTML = templateData.secondLineText;
};
},



buildModelAndRenderView : function() {
return new RSVP.Promise((function(resolve,reject){
try{
    
    const defaultParams  = {};
    defaultParams.doNotSortDisplayedCollection = true;
    defaultParams.resizePlaceHolderElem = true;
    defaultParams.displaySongOnDeviceOnFirstLoading = false;
    defaultParams.showEmtptyInfoBox = false;
    const context =  Utils.getInstance().buildContextForSongCollectionGlobalSearch();
    defaultParams.context = context;
    const myLibraryControllerParams = JSON.parse(JSON.stringify(defaultParams));
    myLibraryControllerParams.actionExecutedOnSongHandler = this.onActionExecutedOnSongHandler.bind(this);
    this.myLibrarySongListController = new SongListController(myLibraryControllerParams);
    this.songListController = new SongListController(defaultParams);
    this.artistListController =  new ArtistListController(defaultParams);
    this.albumListController =  new AlbumListController(defaultParams);
    const soundcloudControllerParams = JSON.parse(JSON.stringify(defaultParams));
    soundcloudControllerParams.showSongCoverImage = true;
    soundcloudControllerParams.actionItemButtonClass = "pe-7s-more";//"ion-ios7-more";
    this.soundcloudSongListController = new SongListController(soundcloudControllerParams);
    const youtubeControllerParams = JSON.parse(JSON.stringify(defaultParams));
    youtubeControllerParams.showSongCoverImage = true;
    youtubeControllerParams.actionItemButtonClass = "pe-7s-more";//"ion-ios7-more";
    this.youtubeVideoSongListController = new SongListController(youtubeControllerParams);
    const usersListControllerParams = JSON.parse(JSON.stringify(defaultParams));
    usersListControllerParams.doNotInitializeLazyLoadingAtRender = true;
    this.usersListController =  new UserListController(usersListControllerParams);
    const playlistListControllerParams = JSON.parse(JSON.stringify(defaultParams));
    playlistListControllerParams.onPlaylistSeclectedHandler =  this.onPlaylistSelected.bind(this);
    playlistListControllerParams.onPlaylistPressedHandler =  this.onPlaylistPressedHandler.bind(this);
    playlistListControllerParams.onPlaylistPressedUpHandler =  this.onPlaylistPressedUpHandler.bind(this);
    playlistListControllerParams.onPlaylistPlayButtonTapedHandler = this.onPlaylistPlayButtonTapedHandler.bind(this);
    playlistListControllerParams.onPlaylistEditButtonTapedHandler = this._onPersonalRadioEditButtonClicked.bind(this);
    playlistListControllerParams.onPlaylistShareButtonTapedHandler = this._onPersonalRadioShareButtonClicked.bind(this);
    playlistListControllerParams.doNotInitializeLazyLoadingAtRender = true;
    playlistListControllerParams.extendedView = false;
    this.playlistListController =  new PlaylistListController(playlistListControllerParams);

    const journeyListControllerParams = JSON.parse(JSON.stringify(defaultParams));
    journeyListControllerParams.model = new JourneyCollection();
    journeyListControllerParams.customCollectionConstructor = JourneyCollection;
    journeyListControllerParams.onPlaylistSeclectedHandler =  this.onJourneySelected.bind(this);
    journeyListControllerParams.onPlaylistPressedHandler = function(){};
    journeyListControllerParams.onPlaylistPressedUpHandler =  function(){};
    journeyListControllerParams.onPlaylistPlayButtonTapedHandler = function(){};
    journeyListControllerParams.onPlaylistEditButtonTapedHandler = this._onJourneyEditButtonClicked.bind(this);
    journeyListControllerParams.onPlaylistShareButtonTapedHandler = this._onJourneyShareButtonClicked.bind(this);
    journeyListControllerParams.doNotInitializeLazyLoadingAtRender = true;
    journeyListControllerParams.extendedView = false;
    journeyListControllerParams.customMapFunctionToConvertJsonObjToTemplateData = this.getCustomMapFunctionToConvertJourneyJsonObjToTemplateData();
    journeyListControllerParams.customItemViewUpdateDuringScrollingHandler = this.getJourneyCustomItemViewUpdateDuringScrollingHandler();
    journeyListControllerParams.customItemViewUpdateDuringScrollingHandlerForExtendedView = this.getJourneyCustomItemViewUpdateDuringScrollingHandlerForExtendedView();
    this.journeyListController =  new PlaylistListController(journeyListControllerParams);

    this.infoBoxController = this._buildInfoBoxController();
    const promiseArray = [];
    promiseArray.push(this.myLibrarySongListController.buildModelAndRenderView());
    promiseArray.push(this.songListController.buildModelAndRenderView());
    promiseArray.push(this.albumListController.buildModelAndRenderView());
    promiseArray.push(this.artistListController.buildModelAndRenderView());
    promiseArray.push(this.soundcloudSongListController.buildModelAndRenderView());
    promiseArray.push(this.youtubeVideoSongListController.buildModelAndRenderView());
    promiseArray.push(this.usersListController.buildModelAndRenderView());
    promiseArray.push(this.playlistListController.buildModelAndRenderView());
    promiseArray.push(this.journeyListController.buildModelAndRenderView());
    promiseArray.push(this.infoBoxController.buildModelAndRenderView());
    RSVP.Promise.all(promiseArray).then((function(){
        this.myLibrarySongListView = this.myLibrarySongListController.getView();
        this.albumsListView = this.albumListController.getView();
        this.artistsListView = this.artistListController.getView();
        this.songsListView = this.songListController.getView();
        this.infoBoxView = this.infoBoxController.getView();
        this.soundcloudSongListView = this.soundcloudSongListController.getView();
        this.youtubeVideoListView = this.youtubeVideoSongListController.getView()
        this.usersListView = this.usersListController.getView()
        this.playlistListView = this.playlistListController.getView();
        this.journeyListView = this.journeyListController.getView();
        this.myLibrarySongCollection = this.myLibrarySongListController.getModel();
        this.albumsCollection = this.albumListController.getModel();
        this.artistsCollection = this.artistListController.getModel();
        this.songsCollection = this.songListController.getModel();
        this.soundcloudSongsCollection = this.youtubeVideoSongListController.getModel();
        this.youtubeVideoCollection = this.youtubeVideoSongListController.getModel();
        this.youtubeVideoCollection = this.youtubeVideoSongListController.getModel();
        this.playlistListCollection = this.playlistListController.getModel();
        this.journeyListCollection = this.journeyListController.getModel();
        this.usersListCollection = this.usersListController.getModel();
        const globalPageViewParams  = {};
        globalPageViewParams.myLibrarySongsListView = this.myLibrarySongListView;
        globalPageViewParams.songsListView = this.songsListView;
        globalPageViewParams.artistsListView = this.artistsListView;
        globalPageViewParams.albumsListView = this.albumsListView;
        globalPageViewParams.soundcloudSongsListView = this.soundcloudSongListView;
        globalPageViewParams.youtubeVideoListView = this.youtubeVideoListView;
        globalPageViewParams.usersListView = this.usersListView;
        globalPageViewParams.playlistListView = this.playlistListView;
        globalPageViewParams.journeyListView = this.journeyListView;
        globalPageViewParams.infoBoxView =  this.infoBoxView;
        this.view =  new GlobalSearchPageView(globalPageViewParams);
        this.view.render();
        this.listenTo(this.view,"onKeyUpInSearchField", this._searchForSongsAlbumsArtists);
        this.listenTo(this.view, "showAllSongs", this.showAllSongsForSearchKeyword);
        this.listenTo(this.view, "showHomePage", this.showHomePage);

        this.listenTo(this.view, "showAllAlbums", this.showAllAlbumsForSearchKeyword);
        this.listenTo(this.view, "showAllArtists", this.showAllArtistsForSearchKeyword);
        this.listenTo(this.view, "showAllSouncloudSongs", this.showAllSoundcloudSongsForSearchKeyword);
        this.listenTo(this.view, "showAllYoutubeVideo", this.showAllYoutubeVideoForSearchKeyword);
        this.listenTo(this.view, "showAllSongsInLibrary", this.showAllSongInMyLibraryForSearchKeyword);
        this.listenTo(this.view, "showAllUsersMatchingCriteria", this.showAllUsersMatchingCriteria);
        this.listenTo(this.view, "showAllTrebblesMatchingCriteria", this.showAllTrebblesMatchingCriteria);
        this.listenTo(this.view, "showAllJourneysMatchingCriteria", this.showAllJourneysMatchingCriteria);
        this.listenTo(this.view, "analyseAudioOutput", this.analyseAudioOutput);
        resolve();
    }).bind(this)).catch(reject);
}catch(error){
reject(error);
}

}).bind(this));


}

});

export default GlobalSearchController;