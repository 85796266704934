
import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import InsertAudioNodeOperation from "models/audioEditor/sequencerOperation/InsertAudioNodeOperation";
import RSVP from "rsvp";


const DeleteAudioSequencerNodeOperation =  InsertAudioNodeOperation.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        InsertAudioNodeOperation.apply(this, [attributes, options]);
    },

    execute : function(audioSequencerNode){
        const nextNode =  audioSequencerNode.getNext();
        const previousNode =  audioSequencerNode.getPrevious();
        this.removeAudioNode(audioSequencerNode);
        this.getSequencer().getHistoryManagement().addDeleteAudioSequencerNodeLog(audioSequencerNode, previousNode? previousNode: nextNode, previousNode?false: true);
    },

    undo : function(sequencerDeleteAudioSequencerNodeLog ){
        this.insertAudioNode(sequencerDeleteAudioSequencerNodeLog.getAudioSequencerNode(), sequencerDeleteAudioSequencerNodeLog.getSequencerNodeToInsertNextTo(), sequencerDeleteAudioSequencerNodeLog.getInsertBefore());
    },

    redo: function(sequencerDeleteAudioSequencerNodeLog){
        this.removeAudioNode(sequencerDeleteAudioSequencerNodeLog.getAudioSequencerNode(), sequencerDeleteAudioSequencerNodeLog.getSequencerNodeToInsertNextTo(), sequencerDeleteAudioSequencerNodeLog.getInsertBefore());
    },

});


export default DeleteAudioSequencerNodeOperation; 