import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerOperationLog from "models/audioEditor/sequencerOperationLog/SequencerOperationLog";
import RSVP from "rsvp";

const SequencerOperationRenameSpeakerLabelLog =  SequencerOperationLog.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperationLog.apply(this, [attributes, options]);
        
    },

    getOperationName : function(){
        return "Rename Speaker Label";
    },

    setSpeakerInfo : function(speakerInfo){
        this.set("speakerInfo", speakerInfo);
    },

    getSpeakerInfo : function(){
        return this.get("speakerInfo");
    },

    setInitialSpeakerLabel : function(initialSpeakerLabel){
        this.set("initialSpeakerLabel", initialSpeakerLabel);
    },

    getInitialSpeakerLabel : function(){
        return this.get("initialSpeakerLabel");
    },

    setOldModifiedSpeakerLabel : function(oldModifiedSpeakerLabel){
        this.set("oldModifiedSpeakerLabel", oldModifiedSpeakerLabel);
    },

    getOldModifiedSpeakerLabel : function(){
        return this.get("oldModifiedSpeakerLabel");
    },

    setNewModifiedSpeakerLabel : function(newModifiedSpeakerLabel){
        this.set("newModifiedSpeakerLabel", newModifiedSpeakerLabel);
    },

    getNewModifiedSpeakerLabel : function(){
        return this.get("newModifiedSpeakerLabel");
    },




});

export default SequencerOperationRenameSpeakerLabelLog; 