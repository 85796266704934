	import $ from 'jquery';
    import _ from 'underscore';
    import Backbone from "backbone";
    import Song from "models/Song";
    import SongCollection from "collections/Songs";
    import Playlist from "collections/Playlist";

    const echonestAPIKey = "VXBXP5WZSIJUY3O4Y"
    const EchonestModel = Backbone.Model.extend({
        "default" : {
            
        },

        initialize : function() {

        },

        createTasteProfile : function(username, onSuccess, onFailure) {
            $.post(window.trebble.usedProtocolPrefix+"developer.echonest.com/api/v4/catalog/create", {
                api_key : "VXBXP5WZSIJUY3O4Y",
                format : "json",
                type : "general",
                name : username
            }).done(function(data) {
                window.log("Echonest taste profile  with id " + data.response.id+"creation was successful.");

                if(data.response.status.code != 0)
                {
                    
                        //taste profile was already created
                        $.get(window.trebble.usedProtocolPrefix+"developer.echonest.com/api/v4/catalog/profile", {
                            api_key : "VXBXP5WZSIJUY3O4Y",
                            name : username,
                            format : "json"
                            
                        }).done(function(data) {
                            
                            if(data.response.status.code != 0)
                            {
                                        //taste profile was already created
                                        window.log("Echonest taste profile  with id " + data.response.id+" couldn't be  retrieved.");
                                        onSuccess(data.response.catalog.id, data);
                                    }else{
                                        window.log("Echonest taste profile  with id " + data.response.id+" was successfully retrieved.");
                                        onSuccess(data.response.catalog.id, data);
                                    }
                                }).fail(function(dataError) {
                                    window.log("Echonest taste profile couldn't be retrieved. Error :"+JSON.stringify(dataError));
                                    onFailure(dataError);
                                });

                            }else{
                                onSuccess(data.response.id, data);
                            }
                        }).fail(function(dataError) {
                            window.log("Echonest taste profile creation failed. Error :"+JSON.stringify(dataError));
                            onFailure(dataError);
                        });
                    },


                    updateTasteProfileWithSongLibrary : function(tasteProfileId,songsCollection, onSuccess, onFailure){
                        const echonestItems = [];
                        if(tasteProfileId){
                            songsCollection.each( function( songModel) {
                                echonestItems.push({
                                    "action":"update",
                                    "item":
                                    { 
                                        "item_id":window.ID(),
                                        "song_name":songModel.get("title"),
                                        "artist_name":songModel.get("artist")
                                    }	
                                }
                                );
                            });
                            const postData = {
                                api_key:echonestAPIKey, 
                                data_type:"json", 
                                format:"json",
                                data:JSON.stringify(echonestItems),
                                id:tasteProfileId };
                                $.post(window.trebble.usedProtocolPrefix+"developer.echonest.com/api/v4/catalog/update",postData)
                                .done(
                                    function(data){
                                        window.log("echonest catalog was updated sccessfully" + tasteProfileId);
                                    })
                                .fail(function(dataError){
                                    window.log("echonest catalog update failed. Error: "+ dataError);
                                });
                            }
                        },



                        generatePlaylistFromTasteProfileWithSongLibrary : function(tasteProfileId,songsCollection, onSuccess, onFailure){
                            const echonestItems = [];
                            if(tasteProfileId){

                                const postData = {
                                    api_key:echonestAPIKey, 
                                    format:"json",
                                
                                    results:20,
                                    type:"song-radio",
                                    artist_pick:"song_hotttnesss",
                                    seed_catalog:"CAQFIXE1406E9832F3",
                                    song_min_hotttnesss:0.7,
                                    dmca:true,
                                    song_id:"CAQFIXE1406E9832F3:song:_00afdifeq"

                                };
                                $.get(window.trebble.usedProtocolPrefix+"developer.echonest.com/api/v4/playlist/static",postData)
                                .done(
                                    function(data){
                            
                                        const songArray = [];
                                    //	songColl = new SongCollection();

                                        for(let i in data.response.songs)
                                        {
                                            const songEchoNestMeta = data.response.songs[i];
                                            const songInfoJSON = {
                                                    "title": songEchoNestMeta.title,
                                                    "artist": songEchoNestMeta.artist_name,
                                                    "uri": "",
                                                    "coverArtFound": false,
                                                    };
                                        //	songColl.add(new Song(songInfoJSON));
                                            songArray.push(songInfoJSON);
                                        }
                                        const playlist = new Playlist(
                                                songArray);
                                        onSuccess(playlist);
                                        window.log("echonest static playlist successfully generated " + tasteProfileId);
                                    })
                                .fail(function(dataError){
                                    window.alertErrorMessage(JSON.stringify(dataError));
                                    window.log("echonest static playlist generation failed. Error: "+ dataError);
                                });
                            }
                        },


                    });

    const Echonest = new EchonestModel();

    export default {
		getInstance : function() {
			return Echonest;
		}
	};