import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import RSVP from "rsvp";

const JourneyOutlineItem =  Backbone.Model.extend({
    idAttribute: "capsuleId",

    getCapsuleId : function(){
        return this.get("capsuleId");
    },

    getTitle : function(){
        return this.get("title");
    },

    setTitle : function(newTitle){
        return this.set("title", newTitle);
    },

    getText : function(){
        return this.get("text");
    },

    setText : function(newText){
        return this.set("text", newText);
    },

    getDurationInSeconds : function(){
        return this.get("durationInSeconds");
    },

    getRadioId : function(){
        return this.get("radioId");
    },

    setRadioId : function(radioId){
        return this.set("radioId", radioId);
    },

    getCreationDate : function(){
        return new Date(this.get("creationDate"));
    },


    setAllowedToPlay : function(allowedToPlay){
        this.set("allowedToPlay", allowedToPlay);
    },

    isAllowedToPlay : function(){
        return this.get("allowedToPlay");
    },

    setIsPartOfPaidJourney : function(isPartOfPaidJourney){
        this.set("isPartOfPaidJourney", isPartOfPaidJourney);
    },

    isPartOfPaidJourney : function(){
        return this.get("isPartOfPaidJourney");
    },


    getAudioUrl : function(){
        const processedAudioInfo =  this.get("auphonicProcessedAudio");
        const concatenatedAudioInfo  =  this.get("processedAudio");
        if(processedAudioInfo && processedAudioInfo.uri){
            return processedAudioInfo.uri
        }else{
            if(concatenatedAudioInfo && concatenatedAudioInfo.uri){
                return concatenatedAudioInfo.uri
            }else{
                return null;
            }
        }
    },

    isPlayable : function(){
        return ((this.get("doNotTriggerAudioProcessing") || window.trebble.allowProcessedAudioToPlay) && !!this.get("processedAudio"))|| !!this.get("auphonicProcessedAudio") ;
    },

    isBeingProcessed : function(){
        return !this.isPlayable();
    },


    getLastUpdateDate :function(){
        return this.get("lastUpdateDate");
    },



    getCanBeEdited : function(){
        return this.get("canBeEdited");
    },

    setCanBeEdited :function(canBeEdited){
        return this.set("canBeEdited",canBeEdited);
    },

    canBeEdited : function(){
        return this.get("canBeEdited");
    },
    
    isConsumed : function(){
        return this.get("isConsumed");
    },



    getImageArtUrl : function(){
        return this.get("imageArtUrl");
    },

    getCreatorInfoJson : function(){
        return this.get("creatorInfoJson");
    },

    getCreatorUsername : function(){
        return this.getCreatorInfoJson()? this.getCreatorInfoJson().username : null;
    },


    getDayNumber : function(){
        return this.get("dayNumber");
    },

    setDayNumber : function(dayNumber){
        return this.set("dayNumber", dayNumber);
    },

    isLocked : function(){
        return this.get("locked");
    },

    setLocked : function(locked){
        return this.set("locked", locked);
    },

    isCompleted : function(){
        return this.get("completed");
    },

    setCompleted : function(completed){
        return this.set("completed", completed);
    },
    
});
export default JourneyOutlineItem;