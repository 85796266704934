import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import Pusher from "pusher-js";
import RSVP from "rsvp";

//IMPORTANT . THIS IS SHARED BETWEEN THE CLIENT AND THE NODE.JS SERVER SO CHANGES HERE SHOULD BE PORTED TO THE NODE.JS SERVER
const eventTypes = {
    "WEB_PUSH_NOTIFICATION" : "webPushNotification"
}
const personalChanelTypes  = {
    "BROWSER_PUSH_NOTIFICATION" :"browserPushNotification"
}
const CHANNEL_NAME_SEPARATOR  = "@"
const PusherHelper = Backbone.Model.extend({


    initialize: function() {
        this._channelNameSubscribedTo = null;
        this._isSubscribedToAChannel = false;
        this.listenTo(LocalStorageHelper.getInstance(), LocalStorageHelper.getInstance().eventTypes.USER_INFO_UPDATED,this.subscribeToPushNotification.bind(this));
        const pusher = new Pusher('397dd96a902cfde4c670',{cluster: 'mt1'});
        this._pusher = pusher;
        this._pusherReady = RSVP.Promise.resolve();
        this.subscribeToPushNotification();
        //this._pusherReady = TrebbleClientAPIHelper.getInstance()._doLogin().then((function(){
            //    const tokenInfo = LocalStorageHelper.getInstance().retrieveAuthentificationToken();
            //    const pusher = new Pusher('397dd96a902cfde4c670');
            //    this._pusher = pusher;

            //}).bind(this));

},

isReady : function(){
return this._pusherReady;
},

getChannelName : function(channelType){
const tokenInfo = LocalStorageHelper.getInstance().retrieveAuthentificationToken();
const userInfo = (tokenInfo && tokenInfo.userInfo)? tokenInfo.userInfo: {};
const userUID = userInfo._id;
return personalChanelTypes.BROWSER_PUSH_NOTIFICATION + CHANNEL_NAME_SEPARATOR+ userUID;
},

isUserLoggedIn : function(){
return !!LocalStorageHelper.getInstance().retrieveAuthentificationToken();
},

subscribeToPushNotification : function(){
if(window.device && window.device.platform == "browser"){
return this.isReady().then((function(){
    if(this._isSubscribedToAChannel){
        try{
            this._pusher.unsubscribe(this._channelNameSubscribedTo);
            this._channelNameSubscribedTo = null;
            this._isSubscribedToAChannel = false;
        }catch(error){
            console.error(error);
        }
    }
    if(this.isUserLoggedIn()){
        window.log("About to subscribe to push!");
        this._browserPushNotificationName = this.getChannelName(personalChanelTypes.BROWSER_PUSH_NOTIFICATION);
        this._browserPushNotificationPusherChannel = this._pusher.subscribe(this._browserPushNotificationName);
        this._channelNameSubscribedTo = this._browserPushNotificationName;
        this._isSubscribedToAChannel = true;
        this._browserPushNotificationPusherChannel.bind(eventTypes.WEB_PUSH_NOTIFICATION ,this._onWebPushNotificationEventReceived.bind(this),this);
        window.log("Successfully subscribed to push! Channel name:"+ this._browserPushNotificationName);
    }else{
        //User is not logged in yet
    }
}).bind(this));
}else{
return RSVP.Promise.resolve();  
}
},

_onWebPushNotificationEventReceived : function(data){
this.trigger(eventTypes.WEB_PUSH_NOTIFICATION,data)
},




});

const PusherHelperInstance = new PusherHelper();
PusherHelperInstance.eventTypes = eventTypes;
PusherHelperInstance.personalChanelTypes = personalChanelTypes;

export default {
    getInstance: function() {
        return PusherHelperInstance;
    }
};