import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import DynamicContentView from "views/common/DynamicContentView";
import ti18n from "i18n!nls/UserReferrali18n";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import UserReferralContentViewTemplate from 'text!../../../templates/referral/UserReferralContentTemplate.html';
import RSVP from "rsvp";

const UserReferralContentView = DynamicContentView.extend({


    initialize : function(options) {

        const viewOptions = {};
        const template = _.template(UserReferralContentViewTemplate);
        let templateParams = options;
        if(!templateParams){
            templateParams= {};
        }
        templateParams.ti18n =  ti18n;
        templateParams.referralUrl =  options.referralUrl;
        templateParams.limitDate = options.limitDate;
        templateParams.costPerReferral = options.costPerReferral;
        this.template = template;
        this.templateParams =templateParams;
        
        DynamicContentView.prototype.initialize.call(this, {"canBeAppendedBeforeLoad": true});
    },

    load : function(){
        if(!this._initialized){
            this.$el.html(this.template(this.templateParams));
            this.$el.enhanceWithin();
            this._initialized = true;
            this.$el.find(".send_sms_button").on("click",this.onSendSMSButtonClicked.bind(this));
            this.$el.find(".copy_link_button").on("click",this.onCopyLinkRefferalButtonClicked.bind(this));
            this.$el.find(".more_button").on("click",this.onMoreButtonClicked.bind(this));
            

        }
        return RSVP.Promise.resolve();
    },

    onSendSMSButtonClicked : function(){
        this.trigger("sendReferralUrlViaSMSClicked");
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("sendReferralUrlViaSMSClicked", 'sendReferralUrlViaSMSClicked', 'Send Referral Url Via SMS Button clicked');
        }
    },

    onCopyLinkRefferalButtonClicked : function(){
        this.trigger("copyLinkRefferalButtonClicked");
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("copyLinkRefferalButtonClicked", 'copyLinkRefferalButtonClicked', 'Copy Referral Link Url Button clicked');
        }
    },

    onMoreButtonClicked : function(){
        this.trigger("moreButtonToShareReferralURLClicked");
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("moreButtonToShareReferralURLClicked", 'moreButtonToShareReferralURLClicked', 'More Button to share Referral Url Button clicked');
        }
    },



    render : function() {

        return this;
    }

});

export default UserReferralContentView;