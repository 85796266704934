import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import PlaylistListController from "controllers/common/PlaylistListController";
import DynamicPageView from "views/common/DynamicPageView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import CapsuleCardController from "controllers/capsule/CapsuleCardController";
import Utils from "models/helper/Utils";
import PlayerService from "services/PlayerService";
import Capsules from "collections/Capsules";
import CollectionRepeatController from "controllers/common/CollectionRepeatController";
import PageTemplate from 'text!../../../templates/common/userListPageViewTemplate.html';
import ti18n from "i18n!nls/CapsuleListPagei18n";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";

const CapsuleListPageController = Chaplin.Controller.extend({


    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if (options) {
            if (options.capsuleJsonArray) {
                this._capsuleJsonArray = options.capsuleJsonArray;
            }
            if (options.pageId) {
                this.pageId = options.pageId;
            }
            this._capsulesListLoader = options && options.capsulesListLoader? options.capsulesListLoader: null;
            if(this._capsuleJsonArray){
                this._capsulesListLoader = this._getCapsuletListLoaderForCapsuleCollection(this._capsuleJsonArra);
            }
            this._pageTitle =  options.pageTitle? options.pageTitle: window.getI18n(ti18n, "CAPSULES");
            this._context = options && options.context? options.context: null;
        }
        this._initializeCapsuleController();
        

    },

    _initializeCapsuleController : function(){
        const controllerParams = {};
        controllerParams.showActiveFlagInView = false;
        controllerParams.context = this._context;
        controllerParams.onActionExecutedOnUserHandler = this._onActionExecutedByUserOnDisplayedCapsule.bind(this);
        const collectionRepeatControllerParams = {};
        collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildInfoBoxParams();
        collectionRepeatControllerParams.showEmtptyInfoBox = true;
        collectionRepeatControllerParams.controllerOption = controllerParams;
        collectionRepeatControllerParams.itemControllerConstructor = CapsuleCardController;
        collectionRepeatControllerParams.modelLoadHandler =  this._capsulesListLoader.bind(this);
        collectionRepeatControllerParams.resizePlaceHolderElem = true;
        collectionRepeatControllerParams.heightMargin = 52 + 39 + 33;
        collectionRepeatControllerParams.context = this._context;
        this.capsuleCollectionRepeatController = new CollectionRepeatController(collectionRepeatControllerParams);
        return this.capsuleCollectionRepeatController;
    },

    _buildInfoBoxParams : function(){
        const params = {};
        params.iconClass = "ion-contrast";
        params.message = window.getI18n(ti18n, "NO_CAPSULE");
        params.helpMessage = "";

        return params;

    },

    _onActionExecutedByUserOnDisplayedCapsule : function(actionName, capsuleJson, capsuleCardControllerInstance){
        /*this.onAirCollectionRepeatController.reload();
        this.offAirCollectionRepeatController.reload();
        this.receivedCollectionRepeatController.reload();*/
    },

    getModel : function(){
        return this.model;
    },
    
    getView : function(){
        return this.view;
    },
    
    navigateToPreviousPage : function(){
        PersistentModels.getInstance().getRouter().navigateToPreviousPage();
    },

    _getCapsuletListLoaderForCapsuleCollection : function(capsuleJsonArray){
        return (function(){
            this.capsulesCollection = new Capsules(capsuleJsonArray);
            return this.capsulesCollection.models;
        }).bind(this);
    },
    
    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve,reject){
            try{
                this.capsuleCollectionRepeatController.buildModelAndRenderView().then((function(){
                    const capsuleListView = this.capsuleCollectionRepeatController.getView();
                    const pageTemplateOptions=  {};
                    pageTemplateOptions.pageTitle = this._pageTitle;
                    const pageViewParams= {};
                    pageViewParams.pageId = this.pageId;
                    pageViewParams.containerSelector = ".ui-content";
                    pageViewParams.contentView = capsuleListView;
                    pageViewParams.pageViewTemplate = PageTemplate;
                    pageViewParams.addContentLeftRightPadding = true;
                    pageViewParams.pageTemplateOptions = pageTemplateOptions;
                    this.view = new DynamicPageView(pageViewParams);
                    this.view.render();
                    this.view.$el.find(".ui-content").css("background-color","#f9f9f9");
                    this.view.$el.on("click","#header-back-button", this.navigateToPreviousPage.bind(this));
                    this.view.$el.find(".ui-content > div").addClass("left-right-content-padding");
                    resolve(this);
                }).bind(this)).catch(reject);

            }catch(error){
                reject(error);
            }
            
        }).bind(this));
}

});
export default CapsuleListPageController;