import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Typeahead from "typeahead";
import Mention from "mention";
import ti18n from "i18n!nls/Commenti18n";
import CommentBoxTemplate from 'text!../../../templates/comment/CommentBoxTemplate.html';
import Mousetrap from 'mousetrap';

const CommentBoxView = Backbone.View.extend({


    initialize : function(options) {
        this._songModel = options.model;
        this._followersUsersInfo = options.followersUsersInfo;
        this.compiledTemlate =  _.template(CommentBoxTemplate);
        this._mousetrapObj = null;
    },
    
    afterRendering : function(){
        //add mention plugin to text area field
        
        //first, build array of username 
        const users =  [];
        if(this._followersUsersInfo ){
            for(let index in this._followersUsersInfo ){
                const userInfo = this._followersUsersInfo[index];
                users.push({"username": userInfo.username});
            }
        }
        const params = {};
        params.delimiter = "@";
        params.users = users;
        //const anchorPosition = {"top":this.$el[0].offsetTop,"left": this.$el[0].offsetLeft};
        params.typeaheadOpts = {"positionedBeforeField": true,"menuContainer$el" : this.$el, "anchor$el" :this.$el};
        this._mentionObj = this.comment$el.mention(params);
    },
    
    _onCommentSendButtonClicked : function(){
        if(!this._sendButtonDisabled){
            this.trigger("postComment", this.$el.find("#comment").val())
        }
    },
    
    clearAndBlur : function(){
        this.comment$el.val("");
        this.comment$el.blur();
    },
    
    _onCommentValueChange : function(){
        const value = this.comment$el.val();
        if(value && value != ""){
            this.$el.find("#sendButton").removeClass("disabled");
            this._sendButtonDisabled = false;
        }else{
            this.$el.find("#sendButton").addClass("disabled");
            this._sendButtonDisabled = true;
        }
    },

    _initEnterKeyListener : function(){
        this._mousetrapObj = Mousetrap(this.comment$el[0]).bind('shift+enter', (function(e, combo) {
            this._onCommentSendButtonClicked();
        }).bind(this));
        this.comment$el.on("remove",(function(){
            this._mousetrapObj.unbind("shift+enter");
        }).bind(this));
    },

    render: function(){
        this.$el.html(this.compiledTemlate({"ti18n": ti18n}));
        this.setElement(this.$el.find("div").first());
        this.$el.find("#sendButton").on("click",this._onCommentSendButtonClicked.bind(this));
        this.comment$el = this.$el.find("#comment");
        this.comment$el.val("");
        this.comment$el[0].addEventListener("keyup", this._onCommentValueChange.bind(this));
        this.afterRendering();
        this._initEnterKeyListener();
        this._onCommentValueChange();
        
        return this;
    }



});

export default CommentBoxView;