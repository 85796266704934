import React from "react";
import { Avatar } from "antd";
import ConfigProvider from "./ConfigProvider";
import useDefaultTrebbleAntDTokenConfig from "../helper/useDefaultTrebbleAntDTokenConfig";
import PropTypes from "prop-types";

const TrebbleAvatar = ({useEditorTheme, ...restProps}) => {
  const themeConfig = useDefaultTrebbleAntDTokenConfig({useEditorTheme});

  return (
    <ConfigProvider theme={themeConfig}>
      <Avatar {...restProps} />
    </ConfigProvider>
  );
};

TrebbleAvatar.propTypes = {
  useEditorTheme: PropTypes.bool
}
export default TrebbleAvatar;
