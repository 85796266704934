import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import RSVP from "rsvp";

const City =  Backbone.Model.extend({
    idAttribute: "place_id",

    getPlaceId : function(){
        return this.get("place_id");
    },

    
    getDescription : function(){
        return this.get("description");
    },
    
    
    
    
});
export default City;