import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerOperationLog from "models/audioEditor/sequencerOperationLog/SequencerOperationLog";
import RSVP from "rsvp";

const SequencerOperationChangeSpeakerForParagraphOperationLog =  SequencerOperationLog.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperationLog.apply(this, [attributes, options]);
        
    },

    getOperationName : function(){
        return "Change Speaker Paragraph";
    },
    
    setArrayOfSequencerNodeToChange : function(arrayOfSequencerNodeToChange){
        this.set("arrayOfSequencerNodeToChange", arrayOfSequencerNodeToChange);
    },

    getArrayOfSequencerNodeToChange : function(){
        return this.get("arrayOfSequencerNodeToChange");
    },

    setNewSpeakerInfo : function(newSpeakerInfo){
        this.set("newSpeakerInfo", newSpeakerInfo);
    },

    getNewSpeakerInfo : function(){
        return this.get("newSpeakerInfo");
    },

    setOldSpeakerInfo : function(oldSpeakerInfo){
        this.set("oldSpeakerInfo", oldSpeakerInfo);
    },

    getOldSpeakerInfo : function(){
        return this.get("oldSpeakerInfo");
    },

    setParagraphSequencerNodeView : function(paragraphSequencerNodeView){
        this.set("paragraphSequencerNodeView", paragraphSequencerNodeView);
    },

    getParagraphSequencerNodeView : function(){
        return this.get("paragraphSequencerNodeView");
    },


});

export default SequencerOperationChangeSpeakerForParagraphOperationLog; 