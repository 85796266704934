import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import RSVP from "rsvp";

const CLASSNAME = "AudioFilter";
const AudioFilter =  Backbone.Model.extend({
    idAttribute: "id",

    constructor: function(attributes, options) {
        Backbone.Model.apply(this, [attributes, options]);
        this.set("type",AudioFilter.CLASSNAME);
    },

    applyFilter : function(previousOutputNode, audioContext, arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime, sequencerRendererTracksInfo, audioBuffer){
        //return a new audioBuffer with the audio filter applied to it
        return RSVP.Promise.resolve(audioBuffer);
    },

    applyFilterToNode : function(audioNode, audioContext,arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime,  sequencerRendererTracksInfo, alreadyCreatedFilterNode){
        //return a new audioBuffer with the audio filter applied to it
        return audioNode;
    },

    getSerializedData: function(){
        const stateToReturn  = this.toJSON();
        stateToReturn._cid = this.cid;
        return stateToReturn;
    },

    needToPrepareAudioContext: function(){
        return false;
    },

    prepareAudioContext: function(audioContext){
        return RSVP.Promise.resolve(audioContext);
    },

    setPreset: function(presetId){
        const presetConfig = this.getPresetConfig(presetId);
        if(presetConfig){
            presetConfig.presetId = presetId;
            this.set(presetConfig);
        }
    },

    setName : function(name){
        this.set("name", name);
    },

    getName : function(){
        return this.get("name");
    },

    getPresetConfig : function(presetId){
        const presetIdToConfigMap = this.getPresetIdToConfigMap();
        return presetIdToConfigMap? presetIdToConfigMap[presetId]: null;
    },

    _updateConfigProperties : function(presetIdToConfigMap){
        this._configProperties = [];
        for(let presetId  in presetIdToConfigMap){
            const config = presetIdToConfigMap[presetId];
            if(config){
                for(let configProperty in config){
                    if(this._configProperties.indexOf(configProperty) == -1){
                        this._configProperties.push(configProperty);
                    }

                }
            }
        }
    },

    setPresetIdToConfigMap : function(presetIdToConfigMap){
        this.set("presetIdToConfigMap",presetIdToConfigMap);
        this._updateConfigProperties(presetIdToConfigMap);
    },

    getPresetIdToConfigMap : function(presetIdToConfigMap){
        return this.get("presetIdToConfigMap")
    },

    getPreset: function(){
        return this.get("presetId");
    },

    setDisabled : function(disabled){
        this.set("disabled",disabled);
    },

    isDisabled : function(){
        return this.get("disabled");
    },

    isEqual : function(filter){
        if(filter instanceof AudioFilter){
            return true
        }
        return false;
    },

    clone : function(){
        return new AudioFilter();
    },

    getMaxAudioBufferDuration : function(audioBuffer){
        return audioBuffer && audioBuffer._durationCalculatedByMediaElement && audioBuffer._durationCalculatedByMediaElement > audioBuffer.duration? audioBuffer._durationCalculatedByMediaElement: audioBuffer.duration;
    },




});


AudioFilter.CLASSNAME = CLASSNAME;

export default AudioFilter; 