import React, { useState, useEffect } from "react";
import { notification, theme } from "antd";
import ConfigProvider from "./ConfigProvider";
import { createRoot } from "react-dom/client";
import useDarkModeStatus from "../helper/useDarkModeStatus";
import PropTypes from "prop-types";
import useDefaultTrebbleAntDTokenConfig from "../helper/useDefaultTrebbleAntDTokenConfig";


const notificationContextContainer = document.createElement("div");
document.body.appendChild(notificationContextContainer);
const root = createRoot(notificationContextContainer);

const NotificationContext = (props) => {
  const darkModeStatus = useDarkModeStatus();
  const themeConfig = useDefaultTrebbleAntDTokenConfig();
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const [api, contextHolder] = notification.useNotification({ getContainer: () => document.body, stack: 10, top: 90 });
  useEffect(() => {
    if (props.destroy) {
      api.destroy(props.uniqueId);
    } else {
      setIsDarkTheme(props.darkTheme);
      if (props.type) {
        api[props.type]({
          ...props,
          className: 'trebble-custom-react-notification',
          key: props.uniqueId,
          getContainer: () => document.body,
          icon: props.iconInnerHTML ? <span dangerouslySetInnerHTML={{ __html: props.iconInnerHTML }}></span> : null,
        });
      } else {
        api.open({
          ...props,
          className: 'trebble-custom-react-notification',
          key: props.uniqueId,
          getContainer: () => document.body,
          icon: props.iconInnerHTML ? <span dangerouslySetInnerHTML={{ __html: props.iconInnerHTML }}></span> : null,
        });
      }
    }
  }, [props.uniqueId, props.darkTheme, props.destroy, props.timestamp, darkModeStatus]);

  return (
    <ConfigProvider theme={{ ...themeConfig, algorithm: isDarkTheme ? theme.darkAlgorithm : theme.defaultAlgorithm }}>
      {contextHolder}
    </ConfigProvider>
  );
};

NotificationContext.propTypes = {
  uniqueId: PropTypes.string,
  destroy: PropTypes.bool,
  darkTheme: PropTypes.bool,
  timestamp: PropTypes.number.isRequired,
  iconInnerHTML: PropTypes.string,
  type: PropTypes.string,
};

class TrebbleNotificationManager {
  constructor() {}

  createCustomNotification(notificationUniqueId, title, description, params) {
    root.render(<NotificationContext uniqueId={notificationUniqueId} message={title} description={description} {...params} timestamp={new Date().getTime()} />);
  }

  #createDefaultNotification(notificationUniqueId, title, description, params, type) {
    root.render(<NotificationContext uniqueId={notificationUniqueId} message={title} description={description} {...params} timestamp={new Date().getTime()} type={type} />);
  }

  createSuccessNotification(notificationUniqueId, title, description, params) {
    this.#createDefaultNotification(notificationUniqueId, title, description, params,"success");
  }

  createInfoNotification(notificationUniqueId, title, description, params) {
    this.#createDefaultNotification(notificationUniqueId, title, description, params,"info");
  }

  createWarningNotification(notificationUniqueId, title, description, params) {
    this.#createDefaultNotification(notificationUniqueId, title, description, params,"warning");
  }

  createErrorNotification(notificationUniqueId, title, description, params) {
    this.#createDefaultNotification(notificationUniqueId,  title, description, params,"error");
  }

  createLoadingNotification(notificationUniqueId, title, description, params) {
    const iconInnerHTML = `<div id="loadingImg" style="height: 20px;width: 20px;"></div>`;
    params.iconInnerHTML = iconInnerHTML;
    this.createCustomNotification(notificationUniqueId,  title, description, params);
  }

  destroyNotification(notificationUniqueId) {
    root.render(<NotificationContext destroy={true} uniqueId={notificationUniqueId} timestamp={new Date().getTime()} />);
  }
}

const trebbleNotificationInstance = Object.freeze(new TrebbleNotificationManager());

export default trebbleNotificationInstance;
