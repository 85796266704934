import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import FreeTranscriptionMinLeftWarningView from "views/common/FreeTranscriptionMinLeftWarningView";
import TranscriptionManager from "models/helper/TranscriptionManager";
import SubscriptionManager from "models/helper/SubscriptionManager";
import StripeHelper from "models/helper/StripeHelper";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import RSVP from "rsvp";

const Rollout = RolloutHelper.getInstance();
const SHOW_WARNING_WITH_NUMBER_OF_FREE_HOURS_LEFT_OF_TRANSCRIPTION = Rollout.isFeatureEnabled(Rollout.FEATURES.SHOW_WARNING_WITH_NUMBER_OF_FREE_HOURS_LEFT_OF_TRANSCRIPTION, true);
const SHOW_LINK_TO_UPGRADE = Rollout.getFeatureVariable(Rollout.FEATURES.SHOW_WARNING_WITH_NUMBER_OF_FREE_HOURS_LEFT_OF_TRANSCRIPTION, Rollout.FEATURES.SHOW_WARNING_WITH_NUMBER_OF_FREE_HOURS_LEFT_OF_TRANSCRIPTION.variables.show_link_to_upgrade, false);

const FreeTranscriptionMinLeftWarningController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.listenTo(TranscriptionManager.getInstance(),TranscriptionManager.getInstance().eventTypes.TRANSCRIPTION_MINUTES_CHANGED, this._onTranscriptionMinutesPossibleChanged.bind(this));
        this.listenTo(LocalStorageHelper.getInstance(),LocalStorageHelper.getInstance().eventTypes.USER_INFO_UPDATED, this.fectchTrasncriptionMinutesInfoAndUpdate.bind(this));

    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    _onTranscriptionMinutesPossibleChanged : function(transcriptionInfo){
        if(transcriptionInfo && LocalStorageHelper.getInstance().isCurrentLoggedUserNotAVisitor() && !LocalStorageHelper.getInstance().isLoggedInUserAListener()){
            let transcriptionMinutesLeft = null;
            let totalMinutesAvailablePerMonth = null;
            let hasActivePaidSubscription = null;
            if(transcriptionInfo){
                transcriptionMinutesLeft = transcriptionInfo.transcriptionMinutesLeft;
                totalMinutesAvailablePerMonth = transcriptionInfo.totalMinutesAvailablePerMonth;
                hasActivePaidSubscription = transcriptionInfo.hasActivePaidSubscription;
            }
            if(hasActivePaidSubscription){
                this.view.showWarning(false);
            }else{
                this.view.showWarning(true);
                this.view.setMinutesOfFreeTranscriptionLeft(transcriptionMinutesLeft< 0? 0: transcriptionMinutesLeft);
            }
        }else{
            this.view.showWarning(false);
        }

    },

    onLinkToUpgradeClicked : function(){
        PersistentModels.getInstance().getRouter().showUpgradeCreatorSubscriptionPopup();
    },

    fectchTrasncriptionMinutesInfoAndUpdate : function(){
        if(LocalStorageHelper.getInstance().isCurrentLoggedUserNotAVisitor() && !LocalStorageHelper.getInstance().isLoggedInUserAListener()){
            return TrebbleClientAPIHelper.getInstance().getNumberOfFreeMinutesOfTranscriptionLeft().then(this._onTranscriptionMinutesPossibleChanged.bind(this));
        }else{
            this.view.showWarning(false);
        }
    },


    isVisible : function(){
        return this.view.isVisible();
    },

    getHeight : function(){
        return this.view.getHeight();
    },

    onVisibilityChange : function(){
        this.trigger("warningTranscriptionLeftVisibilityChange")
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {

                this.view = new FreeTranscriptionMinLeftWarningView({"showLinkToUpgrade": SHOW_LINK_TO_UPGRADE});
                this.listenTo(this.view, "linkToUpgradeClicked",this.onLinkToUpgradeClicked);
                this.listenTo(this.view, "visibilityChange",this.onVisibilityChange);
                this.view.showWarning(false);
                this.view.render();
                this.fectchTrasncriptionMinutesInfoAndUpdate();


                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default FreeTranscriptionMinLeftWarningController;