import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import DynamicContentView from "views/common/DynamicContentView";
import ti18n from "i18n!nls/WriteAReviewForJourneyPagei18n";
import JourneyContentViewTemplate from 'text!../../../templates/journey/JourneyContentViewTemplate.html';
import WriteAReviewForJourneyPageContentViewTemplate from 'text!../../../templates/journey/WriteAReviewForJourneyPageContentViewTemplate.html';
import RSVP from "rsvp";



const STAR_UNSELECTED_CLASSNAME = "ion-ios7-star-outline";
const STAR_SELECTED_CLASSNAME = "ion-ios7-star";

const WriteAReviewForJourneyPageContentView = DynamicContentView.extend({


    initialize : function(options) {
        DynamicContentView.prototype.initialize.call(this, {"canBeAppendedBeforeLoad": true});
        this._creatingNewReview = false;
    },

    events : {
        "click .star_rating_btn" : "onStarRatingClicked",
        "click #createReviewBtn:not(.disabled)": "onCreateReviewBtnClicked",
        "click #updateReviewBtn:not(.disabled)": "onUpdateReviewBtnClicked",
    },

    onCreateReviewBtnClicked : function(){	
        this.trigger("createReview");
    },

    onUpdateReviewBtnClicked : function(){	
        this.trigger("updateReview");
    },

    setCreatingNewReview : function(creatingNewReview){
        this._creatingNewReview = creatingNewReview;
        this.$el.attr("creating-new-review", creatingNewReview? "true": "false");
    },

    onStarRatingClicked: function(event){
        const ratingSelected = $(event.currentTarget).attr("rating-value");
        if(ratingSelected ){
            const numberOfStars = parseInt(ratingSelected);
            this.setNumnberOfStars(numberOfStars);
            this.trigger("ratingSelected", numberOfStars);
            this.onDataChanged();
        }
    },

    setNumnberOfStars : function(numberOfStars){
        let numberOfStarsMarked = 0;
        this.$el.find(".rating_stars").children().each((function(index, item){
            const starElem$el =  $(item);
            if(numberOfStarsMarked < numberOfStars){
                starElem$el.removeClass(STAR_UNSELECTED_CLASSNAME)
                starElem$el.removeClass(STAR_SELECTED_CLASSNAME)
                starElem$el.addClass(STAR_SELECTED_CLASSNAME)
                starElem$el.attr("rating-selected", "true");
                numberOfStarsMarked= numberOfStarsMarked + 1;
            }else{
                starElem$el.removeClass(STAR_UNSELECTED_CLASSNAME)
                starElem$el.removeClass(STAR_SELECTED_CLASSNAME)
                starElem$el.addClass(STAR_UNSELECTED_CLASSNAME)
                starElem$el.attr("rating-selected", "false");
            }
        }).bind(this))

    },

    onDataChanged :function(){
        const numberOfStars =  this.getNumberOfStars();
        const textReview = this.getTextReview();
        if(numberOfStars && textReview && textReview.trim()){
            this.$el.attr("form-completed", "true");
            this.$el.find("#createReviewBtn").removeClass("disabled");
            this.$el.find("#updateReviewBtn").removeClass("disabled");
        }else{
            this.$el.attr("form-completed", "false");
            if(!this.$el.find("#createReviewBtn").hasClass("disabled")){
                this.$el.find("#createReviewBtn").addClass("disabled")
            }
            if(!this.$el.find("#updateReviewBtn").hasClass("disabled")){
                this.$el.find("#updateReviewBtn").addClass("disabled")
            }
        }
    },

    getNumberOfStars : function(){
        let numberOfStarsMarked = 0;
        this.$el.find(".rating_stars").children().each((function(index, item){
            const starElem$el =  $(item);
            if(starElem$el.attr("rating-selected") == "true"){
                numberOfStarsMarked = numberOfStarsMarked + 1;
            }

        }).bind(this))
        return numberOfStarsMarked;
    },

    setTextReview : function(textReview){
        this.userTextReviewField$el.val(textReview);
    },

    getTextReview : function(){
        return this.userTextReviewField$el.val();
    },

    setJourneyTitle : function(journeyTitle){
        this.$el.find(".journey_title").html(journeyTitle);
    },

    setJourneyCoverArt : function(coverArtUrl){
        this.$el.find(".cover_art_wrapper img").attr("src",coverArtUrl);
    },

    
    render : function() {
        const templateParams = {};
        this.$el.html(_.template(WriteAReviewForJourneyPageContentViewTemplate)({ "ti18n": ti18n}))
        //this.journeySummaryInfoWrapper$el = this.$el.find(".journey_info_summary");
        //this.journeySummaryInfoWrapper$el.html(_.template(JourneyContentViewTemplate)({ "ti18n": ti18n}))
        this.userTextReviewField$el = this.$el.find("#userTextReviewField");
        this.userTextReviewField$el.on("keyup input",this.onDataChanged.bind(this));

        return this;
    }

});


export default WriteAReviewForJourneyPageContentView;