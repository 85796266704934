import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import Utils from "models/helper/Utils";
import CollectionRepeatView from "views/common/CollectionRepeatView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import RSVP from "rsvp";

const IGNORE_SUBSEQUENT_LOADING_REQUEST_WHEN_LOADING_IS_EXECUTING =  true;
const CollectionRepeatController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if(options)
        {
            this._customInfoBoxInfo  = options.customInfoBoxInfo? options.customInfoBoxInfo: null;
            this._customInfoBoxInfoParams  = options.customInfoBoxInfoParams? options.customInfoBoxInfoParams: null;
            this._actionButtonHandlerFunction =  options.actionButtonHandlerFunction? options.actionButtonHandlerFunction: null;
            this._modelLoadHandler  = options.modelLoadHandler? options.modelLoadHandler: null;
            this._modelLoadMoreHandler  = options.modelLoadMoreHandler? options.modelLoadMoreHandler: null;
            this._getOptionsForModelFunctionHandler  = options.getOptionsForModelFunctionHandler? options.getOptionsForModelFunctionHandler: null;
            this._getModelIdFunction = options.getModelIdFunction ? options.getModelIdFunction : this._getDefaultModelId.bind(this);
            this._showEmtptyInfoBox = options.showEmtptyInfoBox === false? false : true;
            this._scrollToTheTopOnDisplay = options.scrollToTheTopOnDisplay === false? false : true;
            this._resizePlaceHolderElem =  options  ? options.resizePlaceHolderElem :  false;
            this._heightMargin= (options && options.heightMargin)  ? options.heightMargin :  null;
            this._setHeightToFitContainer =  (options && options.setHeightToFitContainer)  ? options.setHeightToFitContainer :  false;
            this._setHeightContainerAuto =  (options && options.setHeightContainerAuto)  ? options.setHeightContainerAuto :  false;
            this._displayCollectionInline =  (options && options.displayCollectionInline)  ? options.displayCollectionInline :  false;
            this._loadOnScroll =  (options && options.loadOnScroll)  ? options.loadOnScroll :  false;
            this._numberOfResultsPerPage =  (options && options.numberOfResultsPerPage)  ? options.numberOfResultsPerPage :  100;
            this._loadOnLoadMoreButtonClick =  (options && options.loadOnLoadMoreButtonClick)  ? options.loadOnLoadMoreButtonClick :  false;
            this._loadMoreButtonLabel =  (options && options.loadMoreButtonLabel)  ? options.loadMoreButtonLabel :  false;
            this._addPaddingBlock =  (options && options.addPaddingBlock)  ? options.addPaddingBlock :  false;
            this._paddingBlockHeight =  (options && options.paddingBlockHeight)  ? options.paddingBlockHeight :  null;
            this._noMoreDataToLoad = false;
            this._lastPageIndexLoaded = -1;

            this.models = [];
            this._controllerOptions = options.controllerOption?options.controllerOption: {};
            this.idToControllers = [];
            this.idToViews = [];
            this._itemControllerConstructor = options.itemControllerConstructor? options.itemControllerConstructor: null;
            this._loadingPromise = null;
            this._filterForLoadingPending =  null;

        }
    },

    getModel : function() {
        return this.models;
    },

    getView : function() {
        return this.view;
    },

    onActionButtonPressed : function(){
        if(this._actionButtonHandlerFunction){
            this._actionButtonHandlerFunction();
        }
    },

    _getDefaultModelId : function(model){
        return model?model.id : null
    },



    _buildItemsControllersAndView : function(modelArray){
        const promises = [];
        const resultsToReturn = {};
        resultsToReturn.idToControllers = {};
        resultsToReturn.idToViews = {};
        for(let index in modelArray){
            const m = modelArray[index];
            promises.push(this._buildSingleItemControllerAndViewNew(m, resultsToReturn));
        }
        return RSVP.Promise.all(promises).then((function(){
            return resultsToReturn;
        }).bind(this));
    },

    scrollToTheTop : function(){
        this.view.scrollToTheTop();
    },

    scollToBottom : function(){
        this.view.scollToBottom();
    },

    updateControllerOptions :function(newControllerOptions){
        this._controllerOptions =  newControllerOptions;
    },

    updateModelHander : function(newModelHandler){
        this._modelLoadHandler = newModelHandler;
    },

    updateLoadMoreModelHander : function(newModelLoadMoreHandler){
        this._modelLoadMoreHandler = newModelLoadMoreHandler;
    },



    _buildSingleItemControllerAndView : function(model){
        const c = this._itemControllerConstructor;
        const controllerParams = this._getOptionsForModelFunctionHandler? this._getOptionsForModelFunctionHandler(model):Utils.getInstance().cloneJson(this._controllerOptions);
        controllerParams.model = model;
        const controller = new  c(controllerParams);
        this.idToControllers[this._getModelIdFunction(model)] = controller;
        return controller.buildModelAndRenderView().then((function(){
            this.self.idToViews[this.self._getModelIdFunction(this.model)] = this._controller.getView();
        }).bind({"self":this, "_controller": controller,"model": model}))
    },

    _buildSingleItemControllerAndViewNew : function(model, resultsToReturn){
        const c = this._itemControllerConstructor;
        const controllerParams = this._getOptionsForModelFunctionHandler? this._getOptionsForModelFunctionHandler(model):Utils.getInstance().cloneJson(this._controllerOptions);
        controllerParams.model = model;
        const controller = new  c(controllerParams);
        resultsToReturn.idToControllers[this._getModelIdFunction(model)] = controller;
        return controller.buildModelAndRenderView().then((function(){
            this.resultsToReturn.idToViews[this.self._getModelIdFunction(this.model)] = this._controller.getView();
        }).bind({"self":this, "_controller": controller,"model": model, "resultsToReturn": resultsToReturn}))
    },

    getControllerByModelId : function(modelId){
        return this.idToControllers[modelId];
    },

    isLoading : function(){
        return this.view.isLoading();
    },

    setFilter : function(filter){
        this._filter = filter;
        return this.reload();
    },

    clearFilter : function(){
        this._filter = null;
        return this.reload();
    },

    getFilter :function(){
        return this._filter;
    },

    setUpdating : function(updating){
        this.view.setUpdating(updating);
    },

    /*
    _load : function(){
        if(this._modelLoadHandler){
            this.view.setUpdating(true);
            this.trigger("startLoading");
            return this._modelLoadHandler(this.getFilter()).then((function(arrayOfModels){
                if(arrayOfModels){
                    this.models = arrayOfModels;
                    return this._buildItemsControllersAndView(this.models);
                }

            }).bind(this)).then((function(resultsToReturn){
                if(resultsToReturn){
                    this.idToViews = resultsToReturn.idToViews;
                    this.idToControllers = resultsToReturn.idToControllers;

                    for(let id in resultsToReturn.idToViews){
                        const singleView = resultsToReturn.idToViews[id];
                        this.view.addViewItem(singleView);
                    }
                }

                this.view.setUpdating(false);
                if(this._scrollToTheTopOnDisplay){
                    this.scrollToTheTop();
                }else{
                    this.scollToBottom();
                }
                this.trigger("finishLoading");
            }).bind(this)).catch((function(error){
                this.view.setUpdating(false);
                this.view.setErrorMessage(error);
            }).bind(this));
}else{
return RSVP.Promise.resolve();
}
},*/

reset : function(){
this.view.setUpdating(true);
for(let id in this.idToControllers){
const singleController = this.idToControllers[id];
if(singleController.destroy){
    singleController.destroy();
}
}
for(let id in this.idToViews){
const singleView = this.idToViews[id];
this.view.deleteViewItem(singleView);
}

delete this.idToViews;
delete this.idToControllers;
delete this.models;
this.idToControllers = [];
this.idToViews = [];
this.models = [];
this._noMoreDataToLoad = false;
this.view.setNoMoreDataToLoad(false);
this._lastPageIndexLoaded = -1;
this.view.setUpdating(false);
},

reload : function(force){
if(this._loadingPromise && this._filterForLoadingPending == this.getFilter() &&  !force){
return this._loadingPromise ;
}else{
const possiblePendingReloadPromise = this._loadingPromise && force? this._loadingPromise: RSVP.Promise.resolve();
return possiblePendingReloadPromise.catch((function(error){
    console.error(error);
}).bind(this)).then((function(){	
    this._filterForLoadingPending = this.getFilter();	
    this._loadingPromise = new RSVP.Promise((function(resolve, reject) {
        try {
            this.reset();
            this.view.setUpdating(true);
            this._loadMore().then((function(){
                resolve();
                this.view.setUpdating(false);
                this._loadingPromise = null;
                this._filterForLoadingPending = null;
                this.loadImages();
            }).bind(this)).catch((function(e){
                reject(e);
                this.view.setUpdating(false);
                this._loadingPromise = null;
                this._filterForLoadingPending = null;
            }).bind(this));

        } catch (error) {
            reject(error);
        }

    }).bind(this));
    return this._loadingPromise ;
}).bind(this));

}
},

getNumberOfModels : function(){
return this.models?this.models.length :0;
},

addItemToBottom : function(itemModel, doNotFocus){
return this._buildSingleItemControllerAndView(itemModel).then((function(){
const modelId =  this._getModelIdFunction(itemModel);
const newCreatedView = this.idToViews[modelId];
if(newCreatedView){
    this.view.addViewItem(newCreatedView);
    //this.view.scollToBottom();
    if(!doNotFocus){
        this.focusOnItem(itemModel);
    }
}
}).bind(this));
},


focusOnItem : function(itemModel){
const modelId =  this._getModelIdFunction(itemModel);
const itemView = this.idToViews[modelId];
if(itemView){
this.view.focusOnItem(itemView);
}
},

setFitContainerHeight : function(fitContainerHeight, autoHeight){
this._setHeightToFitContainer =  fitContainerHeight;
this._setHeightContainerAuto = autoHeight;
if(this.view){
this.setFitContainerHeight(fitContainerHeight, autoHeight);
}
},

loadImages : function(){
this.view.loadImages();
},

_setNoMoreDataToLoad : function(){
this._noMoreDataToLoad = true;
this.view.setNoMoreDataToLoad(true);
},

_loadMore : function(forceLoading){
if(forceLoading ||  !this._loadOnScroll || !this._loadOnLoadMoreButtonClick  ||  !this._noMoreDataToLoad){
if(this._dataBeingLoadedPromise  && IGNORE_SUBSEQUENT_LOADING_REQUEST_WHEN_LOADING_IS_EXECUTING){
    console.log("Loading request was ignore for collection repeat controller")
    return RSVP.Promise.resolve();
}
const afterLoadingInProgressCompletePromise = this._dataBeingLoadedPromise ? this._dataBeingLoadedPromise : RSVP.Promise.resolve();
return afterLoadingInProgressCompletePromise.then((function(){
    const dataLoadingPromise = this._modelLoadMoreHandler? this._modelLoadMoreHandler(this.getFilter(), this._lastPageIndexLoaded+1, this._numberOfResultsPerPage): this._modelLoadHandler(this.getFilter());
    if(!this._loadOnScroll && !this._loadOnLoadMoreButtonClick){
        this.view.setUpdating(true);
    }
    this.trigger("startLoading");
    this._dataBeingLoadedPromise =  dataLoadingPromise.then((function(arrayOfModels){
        if(arrayOfModels && arrayOfModels.length > 0){
            this.models = this.models.concat(arrayOfModels);
            this._lastPageIndexLoaded = this._lastPageIndexLoaded + 1;
            if(arrayOfModels.length < this._numberOfResultsPerPage){
                this._setNoMoreDataToLoad();
            }
            return this._buildItemsControllersAndView(arrayOfModels);
        }else{
            this._setNoMoreDataToLoad();
        }

    }).bind(this)).then((function(resultsToReturn){
        if(resultsToReturn){
            this.idToViews = Utils.getInstance().mergeObjects(this.idToViews, resultsToReturn.idToViews);
            this.idToControllers = Utils.getInstance().mergeObjects(this.idToControllers, resultsToReturn.idToControllers);

            for(let id in resultsToReturn.idToViews){
                const singleView = resultsToReturn.idToViews[id];
                this.view.addViewItem(singleView);
            }
        }
        if(!this._loadOnScroll &&  !this._loadOnLoadMoreButtonClick){
            this.view.setUpdating(false);
            if(this._scrollToTheTopOnDisplay){
                this.scrollToTheTop();
            }else{
                this.scollToBottom();
            }
        }
        
        this.trigger("finishLoading");
        this._dataBeingLoadedPromise = null;
    }).bind(this)).catch((function(error){
        if(!this._loadOnScroll &&  !this._loadOnLoadMoreButtonClick){
            this.view.setUpdating(false);
        }
        this.view.setErrorMessage(error);
        this._dataBeingLoadedPromise = null;
    }).bind(this));

    return this._dataBeingLoadedPromise;

}).bind(this))


}

},

setScrollContainer :function(scrollContainer$el){
this.view.setScrollContainer(scrollContainer$el);
},



buildModelAndRenderView : function() {
return new RSVP.Promise((function(resolve, reject) {
try {
    const viewOptions = {};
    viewOptions.customInfoBoxInfoParams = this._customInfoBoxInfoParams;
    viewOptions.customInfoBoxInfo = this._customInfoBoxInfo;
    viewOptions.showEmtptyInfoBox = this._showEmtptyInfoBox;
    viewOptions.resizePlaceHolderElem = this._resizePlaceHolderElem;
    viewOptions.setHeightToFitContainer =  this._setHeightToFitContainer;
    viewOptions.setHeightContainerAuto =  this._setHeightContainerAuto;
    viewOptions.displayCollectionInline = this._displayCollectionInline;
    viewOptions.loadOnScroll =  this._loadOnScroll;
    viewOptions.heightMargin = this._heightMargin;
    viewOptions.loadMoreButtonLabel = this._loadMoreButtonLabel;
    viewOptions.loadOnLoadMoreButtonClick = this._loadOnLoadMoreButtonClick;
    viewOptions.addPaddingBlock =  this._addPaddingBlock;
    viewOptions.paddingBlockHeight =  this._paddingBlockHeight;
    this.view = new CollectionRepeatView(viewOptions);
    this.listenTo(this.view, "infoBoxButtonPressed",this.onActionButtonPressed);
    this.listenTo(this.view, "loadMoreData", this._loadMore);
    this.view.render();

    resolve();
    this.reload();


} catch (error) {
    reject(error);
}

}).bind(this));

}

});

export default CollectionRepeatController;