import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import SequencerEndWrapNodeView from "views/audioEditor/sequencerNodeView/SequencerEndWrapNodeView";
import SequencerMusicWrapperNodeTemplate from 'text!../../../../templates/audioEditor/sequencerNodeView/SequencerMusicWrapperNodeTemplate.html';
import ti18n from 'i18n!nls/Sequenceri18n';
import RSVP from 'rsvp';


const SequencerMusicWrapperNodeView = SequencerEndWrapNodeView.extend({



    constructor : function(attributes, options) {
        SequencerEndWrapNodeView.apply(this, [attributes, options]);
        this.compiledTemlate =  _.template(SequencerMusicWrapperNodeTemplate);
    },
    
    events : _.extend({
        
    }, SequencerEndWrapNodeView.prototype.events),

    
    render : function() {

        this.$el.html(this.compiledTemlate({"model": this.model}));
        this.setElement(this.$el.find("span").first());
        this.afterRendering();

        return this;
    }

});
export default SequencerMusicWrapperNodeView;