import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Song from "models/Song";
import TrebbleListView from "views/common/TrebbleListView";
import SongOptionMenuModel from "models/SongOptionMenuModel";
import SongOptionMenuView from "views/SongOptionMenuView";
import ti18n from 'i18n!nls/CapsuleCardi18n';
import CapsuleCardItemTemplate from "text!../../../templates/common/CapsuleCardTemplate.html";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const TrebbleCapsuleListView = TrebbleListView.extend({
    
    
    
    
    
    initialize : function(options) {
        if(!options){
            options= {};
        }
        if(!options.rightSwipeRevealOption){
            options.rightSwipeRevealOption = this._buildItemRightOption()
        }
        if(!options.leftSwipeRevealOption){
            options.leftSwipeRevealOption = this._buildItemLeftOption()
        }

        this.collection = options.collection
        options.modelIdAttribute  = "capsuleId";
        options.listItemTemplatetI18n = ti18n;
        options.listItemTemplate = CapsuleCardItemTemplate;
        const self = TrebbleListView.prototype.initialize.call(this, options);
        this.setModelTransformHandlerBeforeTemplatePiping(this._modelTransformHandlerBeforeTemplatePiping);
        this.setItemViewUpdateDuringScrollingHandler(this._getItemViewUpdateDuringScrollingHandler);
        return self;
                    
    },
    
    _buildItemRightOption : function(){
        const optionConfig = [];
        //optionConfig.push( {"optionLabel" : "..."});
        //optionConfig.push( {"optionLabel" : "Add To My Trebble","name": "addToRadio", "validationHandler": this._canBeAddedToRadio.bind(this), "onOptionSelected": this._onAddSongToRadioMenuSelected.bind(this)});//"<i class='fontello-icon-list-add'></i>","name": "addToQueue"};// "Add To Queue"};
        //optionConfig.push( {"optionLabel" : "Rate Or Tag","name": "rateOrTag" , "validationHandler": null, "onOptionSelected": this._onTagSongOptionMenuSelected.bind(this)});//"<i class='ion-radio-waves'></i>","name": "playSimilarInLibrary"};//: "Play Similar In Library"};
        return optionConfig;
    },
    
    _buildItemLeftOption : function(){
        const optionConfig = [];
        //optionConfig.push( {"optionLabel" : "..."});
        //optionConfig.push( {"optionLabel": "Show Options","name": "showOptions", "validationHandler": null, "onOptionSelected": this._onShowSongOptionMenuSelected.bind(this)});//"<i class='fontello-icon-list-add'></i>"};// "Add To Queue"};
        //optionConfig.push( {"optionLabel": "Remove From My Trebble","name": "removeFromRadio", "validationHandler":  this._canBeRemovedToRadio.bind(this), "onOptionSelected": this._onRemoveSongToRadioMenuSelected.bind(this)});//"<i class='ion-radio-waves'></i>"};// : "Play Similar In Library"};
        return optionConfig;
    },
    
    _getDefaultItemEventHandler : function(){
        let defaultItemEventHandler =  TrebbleListView.prototype._getDefaultItemEventHandler.call(this);
        defaultItemEventHandler = defaultItemEventHandler? defaultItemEventHandler : [];
        return defaultItemEventHandler;
    },


    
    getContext : function(){
        return this.collection? this.collection.getContext(): null;
    },
     
    
     _getCapsuleModelFromCapsuleJson : function(capsuleJson){
        return this.collection.get(capsuleJson.capsuleId);
      },
    
        _modelTransformHandlerBeforeTemplatePiping: function(capsuleJson) {
        
        const templateData = {model: capsuleJson};
        return templateData;
    },

    _getItemViewUpdateDuringScrollingHandler : function(elem,capsuleJson){
        /*const firstItemElem = elem.querySelector(".songTitleLibrary");
        firstItemElem.textContent = "Created on " + new Date(capsuleJson.createdDate);
        const secondItemElem = elem.querySelector(".songArtistLibrary");
        secondItemElem.textContent = capsuleJson.creatorInfoJson? 'by '+ capsuleJson.creatorInfoJson.firstName :'';
        */
    },


});

export default TrebbleCapsuleListView;
