import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import WelcomeNewListenerPopupView from "views/welcomeNewUser/WelcomeNewListenerPopupView";
import PersistentModels from "services/PersistentModels";
import WelcomeNewListenerTemplate from 'text!../../../templates/welcomeNewUser/WelcomeNewListenerTemplate.html';
import ti18n from "i18n!nls/WelcomeNewListeneri18n";
import RSVP from "rsvp";

const WelcomeNewListenerPopupController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.template = WelcomeNewListenerTemplate;
        this.context = options? options.context: null;
        _.extend(this, Backbone.Events);
    },



    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    _onPopupClosed : function(){

    },

    showExplorePage : function(){
        PersistentModels.getInstance().getRouter().showExplorePage(null, true);
    },

    
    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                if(this.template){
                    const params = {};
                    params.radioInfo = this._radioInfo;
                    params.template = this.template;
                    params.ti18n = ti18n;
                    const welcomeNewListenerPopupView = new WelcomeNewListenerPopupView(params);
                    welcomeNewListenerPopupView.render();
                    this.view = welcomeNewListenerPopupView;
                    this.listenTo(this.view, "onPopupClosed", this._onPopupClosed.bind(this))
                    this.listenTo(this.view, "showExplorePage", this.showExplorePage.bind(this))
                    
                }
                resolve();


            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default WelcomeNewListenerPopupController;