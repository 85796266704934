import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utils from "models/helper/Utils";
import CapsuleOptionController from "controllers/capsule/CapsuleOptionController";

//"controllers/comment/CommentSummaryController",
//"models/helper/AudioRecorderHelper",
import PersistentModels from "services/PersistentModels";

import RSVP from "rsvp";
import ti18n from 'i18n!nls/CapsuleCardi18n';
import CapsuleCardView from "views/capsule/CapsuleCardView";
const CapsuleCardController =  Chaplin.Controller.extend({


    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = options.model;
        this.context = options.context;
        this._showActiveFlagInView = options.showActiveFlagInView;
        this._showCommentInLine = options.showCommentInLine;
        this._doNotShowSongContext =  options.doNotShowSongContext;
        this._doNotShowUrlPreview =  options.doNotShowUrlPreview;
        this._doNotCapsuleSocialInfo =  options.doNotCapsuleSocialInfo;
        this._previewMultipleURLS = options.previewMultipleURLS;
        this._doNotAllowToBeAddedToTrebble = options.doNotAllowToBeAddedToTrebble;
        this._doNotAllowToBePlayed = options.doNotAllowToBePlayed;
        this._doNotPlayButton =  options.doNotPlayButton;
        this._onActionExecutedOnUserHandler = options.onActionExecutedOnUserHandler;
        this._listenToPlayerEvents();
    },
    
    getModel : function(){
        return this.model;
    },
    
    getView : function(){
        return this.view;
    },

    destroy: function(){
        try{
            this.stopListening();
            if(this.view && this.view.remove){
                this.view.remove();
            }
        }catch(error){
            console.error(error);
        }
    },

    _listenToPlayerEvents: function() {
        //this.listenTo(PersistentModels.getInstance().getPlayer(), "songPreviewLoaded", this.onAudioPreviewPlaying.bind(this));
        this.listenTo(PersistentModels.getInstance().getPlayer(), "songPreviewLoading", this.onAudioPreviewPlaying.bind(this));
        this.listenTo(PersistentModels.getInstance().getPlayer(), "songPreviewStartedPlaying", this.onAudioPreviewPlaying.bind(this));
        
        this.listenTo(PersistentModels.getInstance().getPlayer(), "songPreviewFailedLoading", this.onAudioPreviewStopped.bind(this));

        this.listenTo(PersistentModels.getInstance().getPlayer(), "songPreviewCancelled", this.onAudioPreviewStopped.bind(this));
        this.listenTo(PersistentModels.getInstance().getPlayer(), "songPreviewUnLoaded", this.onAudioPreviewStopped.bind(this));


        this.listenTo(PersistentModels.getInstance().getPlayer(), "songStartedPlaying", this.onAudioPlaying.bind(this));
        this.listenTo(PersistentModels.getInstance().getPlayer(), "songLoading", this.onAudioPlaying.bind(this));
        //this.listenTo(PersistentModels.getInstance().getPlayer(), "playerLoading", this.updatePlaybackButton.bind(this));
        //this.listenTo(PersistentModels.getInstance().getPlayer(), "playerFinishLoading", this.updatePlaybackButton.bind(this));
        //this.listenTo(PersistentModels.getInstance().getPlayer(), "songLoaded", this.updatePlaybackButton.bind(this));
        this.listenTo(PersistentModels.getInstance().getPlayer(), "songFailedLoading", this.onAudioStopped.bind(this));
        this.listenTo(PersistentModels.getInstance().getPlayer(), "songPaused", this.onAudioStopped.bind(this));
        if(this.model){
            this.listenTo(PersistentModels.getInstance(),"shortcastInfoUpdated"+this.model.getCapsuleId(), this._onCapsuleInfoUpdated.bind(this));
        }
    },

    onAudioPreviewPlaying : function(currentSongModel){
        const audioUrl  = PersistentModels.getInstance().getPlayer().getAudioPreviewURL();
        if(audioUrl == this.model.getAudioUrl()){
            this.view.setIsPlaying(true);
        }
    },

    onAudioPreviewStopped : function(currentSongModel){
        const audioUrl  = PersistentModels.getInstance().getPlayer().getAudioPreviewURL();
        if(audioUrl == this.model.getAudioUrl()){
            this.view.setIsPlaying(false);
        }
    },

    onAudioPlaying : function(currentSongModel){
        const audioUrl  = PersistentModels.getInstance().getPlayer().getAudioMediaURL();
        if(audioUrl == this.model.getAudioUrl()){
            this.view.setIsPlaying(true);
        }
        /*
        const getSongModelPromise =  currentSongModel? RSVP.Promise.resolve(currentSongModel):PersistentModels.getInstance().getPlayer().getCurrentSong();
        getSongModelPromise.then((function(songModel){
            if(songModel.get("uri") == this.model.getAudioUrl()){
                this.view.setIsPlaying(true);
            }
        }).bind(this));*/

},

onAudioStopped : function(currentSongModel){
const audioUrl  = PersistentModels.getInstance().getPlayer().getAudioMediaURL();
if(audioUrl == this.model.getAudioUrl()){
this.view.setIsPlaying(false);
}
/*const getSongModelPromise =  currentSongModel? RSVP.Promise.resolve(currentSongModel):PersistentModels.getInstance().getPlayer().getCurrentSong();
getSongModelPromise.then((function(songModel){
if(songModel.get("uri") == this.model.getAudioUrl()){
    this.view.setIsPlaying(false);
}
}).bind(this));*/

},



saveChanges : function(){
/*if(this.isEditedInfoValid()){
const editedData = this.getEditedTrebbleInfo();
const name = editedData.name;
name = Utils.getInstance().capitalizeFirstLetter(name);
const description = editedData.description;
return TrebbleClientAPIHelper.getInstance().updateTrebbleInfoByTrebbleId(this.model.getLoaderKey(),
    name, description).then((function(){
        this.view.navigateToPreviousPage();
    }).bind(this)).catch((function(error){
        window.alertErrorMessage("Something went wrong went saving your changes. Error:"+ error);
    }).bind(this));
}
*/
},


isEditedInfoValid : function(){
/*const editedData = this.getEditedTrebbleInfo();
if(!editedData.name){
console.error("trebble name cannot be empty");
window.alertErrorMessage("You cannot have a trebble with no name!");
return false;
}else{
if(editedData.name && editedData.name.length >= 100){
    console.error("A trebble name cannot be more than 100 characters");
    window.alertErrorMessage("A trebble name cannot be more than 100 characters!");
    return false;
}
}
if(!editedData.description){
console.error("trebble description cannot be empty");
window.alertErrorMessage("You cannot have a trebble with no name!");
return false;
}else{
if(editedData.description && editedData.description.length >= 300){
    console.error("A trebble description cannot be more than 300 characters");
    window.alertErrorMessage("A trebble description cannot be more than 300 characters!");
    return false;
}
}*/
return true;
},


startPlayingCapsule : function(){
if(this.model){
const audioUrl = this.model.getAudioUrl();
const successCB = (function(){
    this.trigger("finishPlaying");
    this.view.setIsPlaying(false);
}).bind(this);
const failureCB = (function(){
    this.trigger("failedPlaying");
    this.view.setIsPlaying(false);
}).bind(this);
if(audioUrl){
    this.trigger("startPlaying");
    PersistentModels.getInstance().getPlayer().playAudioPreview(audioUrl, successCB, failureCB);
}else{
    Utils.getInstance().showShortToastMessage(window.getI18n(ti18n, "NO_AUDIO_FOUND_TO_PLAY"));
}
}
},

stopPlayingCapsule : function(){
if(this.model){
const audioUrl = this.model.getAudioUrl();
if(audioUrl){
    this.trigger("stopPlaying");
    PersistentModels.getInstance().getPlayer().terminateAudioPreviewAndResumePlayback();
}else{
    Utils.getInstance().showShortToastMessage(window.getI18n(ti18n, "NO_AUDIO_FOUND_TO_PLAY"));
}
}
},

editCapsuleDetails : function(){
if(this.model){
const capsuleId =  this.model.getCapsuleId();
/*const actionCallback = (function(actionName, model){
    if(this._onActionExecutedOnUserHandler){
        this._onActionExecutedOnUserHandler(actionName, model, this);
    }
}).bind(this);*/
//PersistentModels.getInstance().getRouter().showCapsuleByInfoEditPage(this.model, capsuleId, true, true ,actionCallback);
PersistentModels.getInstance().getRouter().showCapsuleInfoEditPage(capsuleId, null,  true );
}
},

_onCapsuleInfoUpdated : function(shortcastInfoJson){
this.model.set(shortcastInfoJson);
this.view.refreshCapsuleTitle();
this.view.refreshCapsuleNote();
},

deleteCapsuleConfirm : function(){
const buttonLabels = [window.getI18n(ti18n, "NO"), window.getI18n(ti18n, "YES")];
const confirmCallback = (function(buttonIndex){
if(buttonIndex ==  2){
    this.deleteCapsule();
}
}).bind(this);
const message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_CAPSULE");
navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n, "DELETE_CAPSULE"), buttonLabels,null , null);
} ,

deleteCapsule : function(){
$.mobile.loading('show');
if(this.model){
const capsuleId =  this.model.getCapsuleId();
const actionCallback = (function(actionName, model){
    if(this._onActionExecutedOnUserHandler){
        this._onActionExecutedOnUserHandler(actionName, model, this);
    }
}).bind(this);
TrebbleClientAPIHelper.getInstance().deleteSongCapsule(capsuleId).then((function(){
    if(this._onActionExecutedOnUserHandler){
        this._onActionExecutedOnUserHandler("deleteCapsule", this.model, this);
    }
    PersistentModels.getInstance().onCapsuleDeleted({"capsuleUID": this.model.getCapsuleId()});
    $.mobile.loading('hide');
}).bind(this)).catch((function(error){
    $.mobile.loading('hide');
    window.alertErrorMessage(error?error :window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHEN_DELETING_CAPSULE"));
}).bind(this));

}
},

shareCapsule : function(){
if(this.model){
if(Utils.getInstance().isJQMPopupOpen()){
    Utils.getInstance().closeCurrentJQMPopup().then((function(){
        return PersistentModels.getInstance().getRouter().showSimpleSetCapsuleSharePopup(this.model, this.context);
    }).bind(this))
}else{
    PersistentModels.getInstance().getRouter().showSimpleSetCapsuleSharePopup(this.model, this.context);
}

}
},

shareSong : function(){
if(this.model){
if(Utils.getInstance().isJQMPopupOpen()){
    Utils.getInstance().closeCurrentJQMPopup().then((function(){
        return PersistentModels.getInstance().getRouter().showSimpleSetCapsuleSharePopup(this.model, this.context);
    }).bind(this))
}else{
    PersistentModels.getInstance().getRouter().showSimpleSetCapsuleSharePopup(this.model, this.context);
}

}
},

showComments : function(){
if(this.model){
if(Utils.getInstance().isJQMPopupOpen()){
    Utils.getInstance().closeCurrentJQMPopup().then((function(){
        //return PersistentModels.getInstance().getRouter().showCommentsForCapsule(this.model,false,null, this.context, null);
        return PersistentModels.getInstance().getRouter().showPageCommentsForCapsule(this.model,false,null, this.context, true)
    }).bind(this))
}else{
    //PersistentModels.getInstance().getRouter().showCommentsForCapsule(this.model,false,null, this.context, null);
    return PersistentModels.getInstance().getRouter().showPageCommentsForCapsule(this.model,false,null, this.context, true)
}
}
},

createCapsuleReply : function(){
if(this.model){
const capsuleId =  this.model.getCapsuleId();
const actionCallback = (function(){
    if(this._onActionExecutedOnUserHandler){
        this._onActionExecutedOnUserHandler("capsuleReplyCreated", this.model, this);
    }
}).bind(this);


if(window.device && window.device.platform =="browser"){
    require(["models/helper/AudioRecorderHelper"], (function(AudioRecorderHelper){
        AudioRecorderHelper.getInstance().initUserMedia().then((function(allowed){
            if(allowed){
                return PersistentModels.getInstance().getRouter().showAddAudioHightlightPageNew(null,capsuleId,  this.context , actionCallback, true, true);
            }else{
                return PersistentModels.getInstance().getRouter().showAddAudioHightlightPageNew(null,capsuleId,  this.context , actionCallback, false, true);
            }
        }).bind(this)).catch((function(e){
            window.navigator.trebbleNotification.alert(e, null, null, "warning");
            return PersistentModels.getInstance().getRouter().showAddAudioHightlightPageNew(null,capsuleId,  this.context , actionCallback, false, true);
        }).bind(this));
    }).bind(this));
    

}else{
    return PersistentModels.getInstance().getRouter().showAddAudioHightlightPageNew(null,capsuleId,  this.context , actionCallback, true, true);
}

}
},

clickOnPlayButton : function(){
this.view.clickOnPlayButton();
},


onClickOnUrlPreview : function(url){
//send data to the backend to track that the url was clicked in this capsule
const capsuleId =  this.model.getCapsuleId();
const trebbleUrlPrefix = "web.trebble.fm";
if(!window.isEmbeddedInIframe && url && url.indexOf(trebbleUrlPrefix) != -1){
const urlFragment = url.substr(url.indexOf(trebbleUrlPrefix)+trebbleUrlPrefix.length);
if(urlFragment.charAt(0) == "#"){
    location.hash = urlFragment; 
}else{
    location.pathname = urlFragment; 
}

}else{
const openedWindow = window.open(Utils.getInstance().addHttpsToURLIfApplicable(url), "_blank");
if((!openedWindow || openedWindow.closed || typeof openedWindow.closed=='undefined') && !window.waitForCordovaToLoad){
    window.alertErrorMessage(window.getI18n(ti18n, "PLEASE_ALLOW_POPUPS_FOR_TREBBLE"));
}else{
    if(openedWindow.focus){
        openedWindow.focus();
    }
}
}
TrebbleClientAPIHelper.getInstance().logUserClickedOnUrlPreviewInCapsule(capsuleId, url, this.context );
},

addCapsuleToTrebble : function(){

return Utils.getInstance().closeCurrentJQMPopup().then((function(){
return PersistentModels.getInstance().getRouter().addCapsuleToTrebbleViaPopup(this.model,this.context);
}).bind(this)).then((function(capsuleAdded){

}).bind(this)).catch((function(error){
window.alertErrorMessage(error);
}).bind(this));

},

showAnalytics : function(){
return PersistentModels.getInstance().getRouter().showCapsuleStatisticsPage(this.model, true);
},

removeCapsuleFromTrebble : function(){
return PersistentModels.getInstance().removeCapsuleFromRadio(this.model, PersistentModels.getInstance().getAllMyPersonalRadioIds(),  this.context).then((function(){
$.mobile.loading("hide");
}).bind(this)).catch(function(error){
$.mobile.loading("hide");
window.alertErrorMessage(error);
});
},

_wasAddedToDom : function(){
this.view._wasAddedToDom();
},

changeScheduledDate : function(){
return Utils.getInstance().closeCurrentJQMPopup().then((function(){
const capsuleScheduleTime = PersistentModels.getInstance().getCapsuleAddedToUserTrebbles(this.model)? new Date(PersistentModels.getInstance().getCapsuleAddedToUserTrebbles(this.model)) : null;
const capsuleExpirationDate = PersistentModels.getInstance().getCapsuleExpirationDateForUserTrebbles(this.model)? new Date(PersistentModels.getInstance().getCapsuleExpirationDateForUserTrebbles(this.model)):null;
const A_DAY_IN_MILLI_SEC= 24 * 60 * 60 *1000;
const capsuleOnAirDurationInDays =  (capsuleScheduleTime && capsuleExpirationDate)? Math.round((capsuleExpirationDate.getTime()- capsuleScheduleTime.getTime())/A_DAY_IN_MILLI_SEC): null;
const capsuleSCheduleTimezone =  PersistentModels.getInstance().getCapsuleAddedToUserTrebblesTimezone(this.model);
return PersistentModels.getInstance().getRouter().addCapsuleToTrebbleViaPopup(this.model,this.context, capsuleScheduleTime, true, capsuleOnAirDurationInDays, capsuleSCheduleTimezone);
}).bind(this)).then((function(capsuleAdded){

}).bind(this)).catch((function(error){
window.alertErrorMessage(error);
}).bind(this));
},

showCapsuleOptionMenu : function(){
return PersistentModels.getInstance().getRouter().showCapsuleMenuOption(this.model, this.context, this.view.getMoreOptionButton$el(), this._onActionExecutedOnUserHandler, null, CapsuleOptionController);
},



buildModelAndRenderView : function() {
return new RSVP.Promise((function(resolve, reject) {
try {
    
    const contentViewParams = {};
    contentViewParams.model  = this.model;
    contentViewParams.showActiveFlag = this._showActiveFlagInView;
    contentViewParams.showCommentInLine = this._showCommentInLine;
    contentViewParams.doNotShowSongContext =  this._doNotShowSongContext;
    contentViewParams.doNotShowUrlPreview  = this._doNotShowUrlPreview;
    contentViewParams.doNotCapsuleSocialInfo = this._doNotCapsuleSocialInfo;
    contentViewParams.doNotPlayButton =  this._doNotPlayButton;
    contentViewParams.doNotAllowToBeAddedToTrebble = this._doNotAllowToBeAddedToTrebble;
    contentViewParams.doNotAllowToBePlayed = this._doNotAllowToBePlayed;
    const capsuleCardView = new  CapsuleCardView(contentViewParams);
    capsuleCardView.render();
    this.view = capsuleCardView;
    this.listenTo(this.view,"startPlaying", this.startPlayingCapsule);
    this.listenTo(this.view, "stopPlaying",this.stopPlayingCapsule);
    this.listenTo(this.view, "editCapsuleDetails",this.editCapsuleDetails);
    this.listenTo(this.view, "deleteCapsule",this.deleteCapsuleConfirm);
    this.listenTo(this.view, "addCapsuleToTrebble",this.addCapsuleToTrebble);
    this.listenTo(this.view, "removeCapsuleFromTrebble",this.removeCapsuleFromTrebble);
    this.listenTo(this.view, "share",this.shareCapsule);
    this.listenTo(this.view, "showComments",this.showComments);
    this.listenTo(this.view, "createCapsuleReply",this.createCapsuleReply);
    this.listenTo(this.view, "clickOnUrlPreview", this.onClickOnUrlPreview);
    this.listenTo(this.view, "changeScheduledDate", this.changeScheduledDate);
    this.listenTo(this.view, "showAnalytics", this.showAnalytics);
    this.listenTo(this.view, "showCapsuleOptionMenu", this.showCapsuleOptionMenu);
    



    
    
    if(this._showCommentInLine){

        require(["controllers/comment/CommentSummaryController"], (function(CommentSummaryController){
            const commentSummaryControllerParams = {};
            commentSummaryControllerParams.model = this.model;
            commentSummaryControllerParams.disableReply = false;
            commentSummaryControllerParams.showContext = false;
            commentSummaryControllerParams.idOfCommentToFocusOn =  null;
            commentSummaryControllerParams.songRefSummary = null;
            commentSummaryControllerParams.context = null;
            commentSummaryControllerParams.doNotShowEmptyBox = true;
            this.commentSummaryController = new CommentSummaryController(commentSummaryControllerParams);
            this.commentSummaryController.buildModelAndRenderView().then((function(){
                this.view.setCommentWidgetView(this.commentSummaryController.getView());
                resolve(this);
            }).bind(this)).catch(reject);
        }).bind(this))
        
    }else{
        resolve(this);
    }
    
    
} catch (error) {
    reject(error);
}

}).bind(this));

}


});
export default CapsuleCardController;