import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import PersistentModels from "services/PersistentModels";
import UrlPreviewView from "views/common/UrlPreviewView";
import Utils from "models/helper/Utils";
import ti18n from 'i18n!nls/CapsuleCardi18n';
import owlcarousel from "owlcarousel";
import CapsuleCardTemplate from 'text!../../../templates/common/CapsuleCardTemplate.html';
import RSVP from 'rsvp';
import moment from 'moment';
import Swiper from 'swiper';

const SHOW_URL_PREVIEW_IN_CAROUSEL = false;
const USE_THE_FIRST_EXTRACTED_URL = true;
const USE_OWL_CAROUSEL =  false;
const CapsuleCardView = Backbone.View.extend({



    initialize : function(options) {
        this.compiledTemlate =  _.template(CapsuleCardTemplate);
        this.model = options? options.model : null;
        this._showActiveFlag = options? options.showActiveFlag : false;
        this._showCommentInLine = options? options.showCommentInLine : false;
        this._doNotShowSongContext =  options.doNotShowSongContext;
        this._doNotShowUrlPreview =  true;//options.doNotShowUrlPreview;
        this._doNotAllowToBeAddedToTrebble = options.doNotAllowToBeAddedToTrebble;
        this._doNotAllowToBePlayed = options.doNotAllowToBePlayed;
        this._doNotCapsuleSocialInfo =  options.doNotCapsuleSocialInfo;
        this._previewMultipleURLS = options.previewMultipleURLS;
        this._doNotPlayButton =  options.doNotPlayButton;
        this._isPlaying = false;
        this._isBeingProcessed = this.model.isBeingProcessed();
        this.listenTo(this.model,"change:beingAddedToTrebble", this.onCapsuleBeingAddedOrRemovedFromTrebble);
        this.listenTo(this.model,"change:beingRemovedFromTrebble", this.onCapsuleBeingAddedOrRemovedFromTrebble);
        
    },
    
    events : {
        "click #playCapsuleBtn": "playPauseCapsule",
        "click #createCapsuleReplyBtn": "createCapsuleReply",
        "click #shareButton": "shareCapsule",
        "click #editButton": "editCapsule",
        "click #deleteButton":"deleteCapsule",
        "click #commentButton": "showComments",
        "click #numberOfComments": "showComments",
        "click #schedulingInfo" : "changeScheduledDate",
        "click #analyticsButton": "showAnalytics",
        "click #addToTrebbleOptionButtuon": "addOrRemoveCapsuleFromTrebble",
        "click #showCapsuleOptionMenu":"showCapsuleOptionMenu",
    },
    
    changeScheduledDate : function(){
        this.trigger("changeScheduledDate");
    },

    deleteCapsule : function(){
        this.trigger("deleteCapsule");
    },
    
    shareCapsule : function(){
        this.trigger("share");
    },

    showAnalytics : function(){
        this.trigger("showAnalytics");
    },
    
    editCapsule : function(){
        this.trigger("editCapsuleDetails");
    },

    showComments : function(){
        this.trigger("showComments");
    },

    createCapsuleReply : function(){
        this.trigger("createCapsuleReply");
    },

    addCapsuleToTrebble : function(){
        this.trigger("addCapsuleToTrebble");
    },
    removeCapsuleFromTrebble : function(){
        this.trigger("removeCapsuleFromTrebble");
    },

    showCapsuleOptionMenu : function(){
        this.trigger("showCapsuleOptionMenu");
    },

    

    addOrRemoveCapsuleFromTrebble : function(evnt){
        if(evnt.currentTarget.className.indexOf("ion-ios7-plus-outline") != -1){
            this.addCapsuleToTrebble();
        }else{
            this.removeCapsuleFromTrebble();
        }

    },

    onCapsuleBeingAddedOrRemovedFromTrebble : function(model, beingAddedOrRemovedToTrebble){
        const capsuleStatusWrapper$El = this.$el.find(".ownerInfo > .buttonGroup > #status");
        const addToTrebbleOptionButtonWrapper$el = this.$el.find("#addToTrebbleOptionButtonWrapper");
        const addOrRemoveFromTrebbleBtnEl = this.$el.find("#addToTrebbleOptionButtuon").get(0);
        const expirationDate$el = this.$el.find("#expirationDate");
        const addedToRadio = PersistentModels.getInstance().isCapsuleAddedToUserTrebbles(this.model);
        const expirationDate = PersistentModels.getInstance().getCapsuleExpirationDateForUserTrebbles(this.model);
        if(addOrRemoveFromTrebbleBtnEl){
            if(beingAddedOrRemovedToTrebble){
                if(addOrRemoveFromTrebbleBtnEl.className.indexOf(" ion-loading-c animate-spin") == -1){
                    addOrRemoveFromTrebbleBtnEl.className = addOrRemoveFromTrebbleBtnEl.className + " ion-loading-c animate-spin";
                }
                capsuleStatusWrapper$El.removeAttr("data-capsule-status");
                if(addedToRadio){
                    addToTrebbleOptionButtonWrapper$el.attr("data-hint",window.getI18n(ti18n, "CAPSULE_BEING_REMOVED_FROM_TREBBLE"));
                }else{
                    addToTrebbleOptionButtonWrapper$el.attr("data-hint",window.getI18n(ti18n, "CAPSULE_BEING_ADDED_FROM_TREBBLE"));
                }
                
            }else{
                if(addOrRemoveFromTrebbleBtnEl.className.indexOf(" ion-loading-c animate-spin") != -1){
                    addOrRemoveFromTrebbleBtnEl.className = addOrRemoveFromTrebbleBtnEl.className.replace(" ion-loading-c animate-spin", "");
                }
                if(addedToRadio){
                    if(addOrRemoveFromTrebbleBtnEl.className.indexOf("ion-ios7-checkmark-outline") == -1){
                        addOrRemoveFromTrebbleBtnEl.className = addOrRemoveFromTrebbleBtnEl.className.replace("ion-ios7-plus-outline", "");
                        addOrRemoveFromTrebbleBtnEl.className = addOrRemoveFromTrebbleBtnEl.className + " ion-ios7-checkmark-outline";
                    }
                    const capsuleProcessing = this.model.isBeingProcessed();
                    addToTrebbleOptionButtonWrapper$el.attr("data-hint",window.getI18n(ti18n, "REMOVE_FROM_TREBBLE"));
                    capsuleStatusWrapper$El.attr("data-capsule-status", capsuleProcessing?"processing":"onair");
                    capsuleStatusWrapper$El.html(capsuleProcessing?window.getI18n(ti18n, "PROCESSING"):window.getI18n(ti18n, "ON_AIR"));
                    expirationDate$el.html(expirationDate?window.getI18n(ti18n, "EXPIRES_IN")+" " + moment.duration(moment() - moment(expirationDate)).humanize():window.getI18n(ti18n, "DO_NOT_EXPIRE"));
                    expirationDate$el.show();
                    capsuleStatusWrapper$El.attr("data-hint", capsuleProcessing?window.getI18n(ti18n, "CAPSULE_IS_CURRENTLY_BEING_PROCESSING"):window.getI18n(ti18n, "CAPSULE_WILL_PLAY_IN_YOUR_TREBBLE"));
                }else{
                    if(addOrRemoveFromTrebbleBtnEl.className.indexOf("ion-ios7-plus-outline") == -1){
                        addOrRemoveFromTrebbleBtnEl.className = addOrRemoveFromTrebbleBtnEl.className.replace("ion-ios7-checkmark-outline", "");
                        addOrRemoveFromTrebbleBtnEl.className = addOrRemoveFromTrebbleBtnEl.className + " ion-ios7-plus-outline";
                    }
                    expirationDate$el.hide();
                    addToTrebbleOptionButtonWrapper$el.attr("data-hint",window.getI18n(ti18n, "ADD_TO_TREBBLE"));
                    capsuleStatusWrapper$El.removeAttr("data-capsule-status");
                }
                
            }
        }
    },


    setIsPlaying : function(isPlaying){
        this._isPlaying = isPlaying;
        if(!this._isBeingProcessed){
            const playCapsuleBtn  =this.$el.find("#playCapsuleBtn");
            const playCapsuleIcon  =this.$el.find("#playCapsuleBtn  i");
            const playCapsuleLabel  =this.$el.find("#playCapsuleBtn  label");
            if(this._isPlaying){
                //playCapsuleBtn.removeClass("fontello-icon-play-1");
                playCapsuleIcon.removeClass("ion-ios7-play");
                playCapsuleIcon.addClass("fontello-icon-stop");
                playCapsuleBtn.css("data-hint", window.getI18n(ti18n, "STOP_CAPSULE"));
                playCapsuleLabel.html(window.getI18n(ti18n, "STOP_CAPSULE"));
                
            }else{
                playCapsuleIcon.removeClass("fontello-icon-stop");
                //playCapsuleBtn.addClass("fontello-icon-play-1");
                playCapsuleIcon.addClass("ion-ios7-play");
                playCapsuleBtn.css("data-hint", window.getI18n(ti18n, "PLAY_CAPSULE"));
                playCapsuleLabel.html(window.getI18n(ti18n, "PLAY_CAPSULE"));
                
            }
        }
    },


    playPauseCapsule : function(){
        if(!this._isBeingProcessed){
            if(this._isPlaying){
                this.trigger("stopPlaying");
                this.setIsPlaying(false);
            }else{
                this.trigger("startPlaying");
                this.setIsPlaying(true);
            }
        }
    },
    
    _buildPreviewIfApplicable : function(){
        if( this.model){
            if(SHOW_URL_PREVIEW_IN_CAROUSEL){
                const urls = Utils.getInstance().getUrlsFromString(this.model.getText());
                const previewPlaceHolder$el =  this.$el.find("#urlPreviewsPlaceholder");
                const swiperContainer$el = $("<div class='swiper-container'><div class='"+ "swiper-wrapper carouselWrapper"+"'></div></div>");
                const swiperWrapper$el =$(swiperContainer$el.find(".swiper-wrapper").get(0));
                this.swiperContainer$el  = swiperContainer$el;

                previewPlaceHolder$el.append(swiperContainer$el);
                for(let index in urls){
                    const urlPreviewOption ={};
                    urlPreviewOption.url =urls[index];
                    const previewView = new UrlPreviewView(urlPreviewOption);
                    previewView.render();
                    const swiperSlide$el = $("<div class='carouselItem  swiper-slide'></div>");
                    swiperSlide$el.append(previewView.$el);
                    swiperWrapper$el.append(swiperSlide$el);
                    this._listenForClickEventOnUrlPreviewEl(previewView.$el, urlPreviewOption.url);
                }
                
                if(!urls || urls.length == 0){
                    previewPlaceHolder$el.hide();
                    const currentNoteHeigth = 158;
                    const urlPlaceholder = 76;
                    const newHeigth = urlPlaceholder + currentNoteHeigth;
                    const note$el = this.$el.find(".note");
                    note$el.addClass("withoutUrlPreview")
                    
                }
                if(urls && urls.length > 1){
                    
                    if(USE_OWL_CAROUSEL){
                        this._urlPreviewSwiperObj = swiperWrapper$el.owlCarousel({
                            navigation: false, // Show next and prev buttons
                            pagination: true,
                            slideSpeed: 300,
                            paginationSpeed: 400,

                            touchDrag: true,
                            mouseDrag: true,
                            singleItem: true
                        });
                    }else{
                        swiperContainer$el.append(this._buildNavigationButtons());
                        this._swiperPagination$el = swiperContainer$el.find(".swiper-pagination");
                        const urlPreviewSwiperParams  =  {
                            //speed: window.isMobileBrowser?200: 300,
                            //longSwipesRatio : window.isMobileBrowser?0.2: 0.4,
                            //watchSlidesProgress: true,
                            //preloadImages: false,
                            //watchSlidesVisibility: true,
                            slidesPerView: 1,
                            centeredSlides: true,
                            //paginationHide: false,
                            //slideToClickedSlide: true,
                            //lazyLoading: true,
                            //lazyLoadingInPrevNext: true,
                            pagination: {
                                el: this._swiperPagination$el.get(0),
                                //	dynamicBullets: true
                            },
                            paginationClickable: true
                        };

                        

                        if(window.device && window.device.platform =="browser" && !window.isMobileBrowser){
                            //urlPreviewSwiperParams.nextButton = '.swiper-button-next';
                            //urlPreviewSwiperParams.prevButton = '.swiper-button-prev';
                            urlPreviewSwiperParams.keyboardControl = true;
                            urlPreviewSwiperParams.mousewheelControl = true;
                            urlPreviewSwiperParams.mousewheel =  {
                                enabled: true
                            };
                            urlPreviewSwiperParams.keyboard =  {
                                enabled: true,
                                onlyInViewport: false
                            };


                        }
                        this._urlPreviewSwiperParams =urlPreviewSwiperParams;
                        this._urlPreviewSwiperObj = new Swiper(swiperContainer$el.get(0),urlPreviewSwiperParams);
                    }
                }
            }else{
                let urls = Utils.getInstance().getUrlsFromString(this.model.getText());
                //ONLY USE THE FIRST EXACTRACTED URL FOR NOW
                if(USE_THE_FIRST_EXTRACTED_URL){
                    urls = urls && urls.length > 1? urls.slice(0,1): urls
                }
                const previewPlaceHolder$el =  this.$el.find("#urlPreviewsPlaceholder");
                if(urls){
                    for(let index in urls){
                        const urlPreviewOption ={};
                        urlPreviewOption.url =urls[index];
                        const previewView = new UrlPreviewView(urlPreviewOption);
                        previewView.render();
                        previewPlaceHolder$el.append(previewView.$el);
                        this._listenForClickEventOnUrlPreviewEl(previewView.$el, urlPreviewOption.url);
                    }
                }

                if(!urls || urls.length == 0){
                    previewPlaceHolder$el.hide();
                    const currentNoteHeigth = 158;
                    const urlPlaceholder = 76;
                    const newHeigth = urlPlaceholder + currentNoteHeigth;
                    const note$el = this.$el.find(".note");
                    note$el.addClass("withoutUrlPreview")
                    /*(note$el.css("flex","0 0 "+newHeigth + "px");
                        note$el.css("-ms-flex","0 0 "+newHeigth + "px");
                        note$el.css("-moz-flex","0 0 "+newHeigth + "px");
                        note$el.css("-webkit-flex","0 0 "+newHeigth + "px");*/
                    }
                }
            }

        },

        _buildNavigationButtons : function(){
            if(((window.device && window.device.platform != "browser") || window.isMobileBrowser)){
                return  "<div class='swiper-pagination'></div>";
            }else{
                return      "<div class='swiper-pagination'></div>";//'<div class="swiper-button-next ion-ios7-arrow-right"></div> <div class="swiper-button-prev ion-ios7-arrow-left"></div>';
            }
        },

        _listenForClickEventOnUrlPreviewEl : function($elToListTo, url){
            if($elToListTo){
                $elToListTo.on("click", (function(){
                    this.self.trigger("clickOnUrlPreview",this.url);
                }).bind({"self": this, "url": url}));
            }
        },

        _listenForClickEventOnCapsuleNote : function(){
            if(this.$el){
                this.$el.find(".note").on("click", (function(e){
                    e.preventDefault();
                    const hrefValue = e.target.href;
                    if(hrefValue){
                        this.self.trigger("clickOnUrlPreview", hrefValue);
                    }
                }).bind({"self": this}));
            }
        },

        setCommentLoading : function(loading){
            if(loading){
                this.$el.find("#loadingImg").show();
            }else{
                this.$el.find("#loadingImg").hide();
            }
        },

        refreshCapsuleTitle : function(){
            this.$el.find(".title > span").html(this.model.getTitle());
        },

        refreshCapsuleNote : function(){
            this.$el.find(".note").html(this.model.getText());
        },

        setCommentWidgetView : function(commentWidgetView){
            this.commentWidgetView = commentWidgetView;
            if(this.commentWidgetView){
                this.setCommentLoading(false);
                this.$el.find("#commentsListWrapper").append(this.commentWidgetView.$el);
            }
        },

        clickOnPlayButton:  function(){
            this.$el.find("#playCapsuleBtn").click();
        },

        _wasAddedToDom : function(){
            if(!USE_OWL_CAROUSEL && this._urlPreviewSwiperObj){
                this._urlPreviewSwiperObj.update();
            }
        },

        getMoreOptionButton$el(){
            return this.moreOptionButton$el;
        },


        render : function() {
            const templateParams = {};
            const capsuleJson  = this.model.toJSON();
            //capsuleJson.text = Utils.getInstance().replaceUrlsByLinksInString(capsuleJson.text);
            this.$el.html(this.compiledTemlate({model: capsuleJson,
                "showActiveFlag": this._showActiveFlag, 
                "showCommentInLine": this._showCommentInLine,
                "isBeingProcessed" : this._isBeingProcessed,
                "doNotAllowToBeAddedToTrebble":this._doNotAllowToBeAddedToTrebble,
                "doNotAllowToBePlayed": this._doNotAllowToBePlayed,
                "moment": moment,
                "ti18n": ti18n
            }));

            this.moreOptionButton$el  =this.$el.find("#showCapsuleOptionMenu");
            if(this._doNotShowSongContext){
                this.$el.find(".songInfo").hide();
            }
            if(this._doNotShowUrlPreview){
                this.$el.find("#urlPreviewsPlaceholder").hide();
            }
            if(this._doNotCapsuleSocialInfo){
                this.$el.find(".capsuleSocialInfo").hide();
            }
            if(this._doNotPlayButton){
                this.$el.find("#playCapsuleWrapper").hide();
            }
            this.setElement(this.$el.find("div").first());
            this._listenForClickEventOnCapsuleNote();
            //this._buildPreviewIfApplicable();
            return this;
        }

    });
export default CapsuleCardView;
