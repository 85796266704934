// worker.js
const wavData = [];
let totalLength = 0;
let numChannels;
let sampleRate;

function encodeChunk(audioData) {
  const bufferLength = audioData.length;
  const chunk = new Uint8Array(bufferLength * 2);
  let offset = 0;

  for (let i = 0; i < bufferLength; i++) {
    let value = audioData[i] * 0x7FFF;
    value = Math.max(-32768, Math.min(32767, value));
    chunk[offset++] = value & 0xFF;
    chunk[offset++] = value >> 8;
  }

  return chunk;
}

function writeString(view, offset, str) {
  for (let i = 0; i < str.length; i++) {
    view.setUint8(offset + i, str.charCodeAt(i));
  }
}

self.onmessage = function (e) {
  if (e.data.init) {
    numChannels = e.data.numChannels;
    sampleRate = e.data.sampleRate;
  } else if (e.data.audioData) {
    const audioData = new Float32Array(e.data.audioData);
    totalLength += audioData.length / numChannels;
    wavData.push(encodeChunk(audioData));

    // Signal progress to the main thread
    self.postMessage({ progress: true });
  } else if (e.data.finalize) {
    const wavHeader = new Uint8Array(44);
    const view = new DataView(wavHeader.buffer);

    writeString(view, 0, 'RIFF');
    view.setUint32(4, 32 + totalLength * numChannels * 2, true);
    writeString(view, 8, 'WAVE');
    writeString(view, 12, 'fmt ');
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, numChannels, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, sampleRate * 2 * numChannels, true);
    view.setUint16(32, 2 * numChannels, true);
    view.setUint16(34, 16, true);
    writeString(view, 36, 'data');
    view.setUint32(40, totalLength * 2 * numChannels, true);

    // Concatenate the header and data
    const wavFile = new Uint8Array(44 + totalLength * 2 * numChannels);
    wavFile.set(wavHeader, 0);
    let offset = 44;
    for (const chunk of wavData) {
      wavFile.set(chunk, offset);
      offset += chunk.length;
    }

    self.postMessage({ wavFile: wavFile });
  }
};
