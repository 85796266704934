import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import BasicInspectorNodeParamView from "views/audioEditor/sequencerNodeInspectorSection/paramView/BasicInspectorNodeParamView";
import SwitchInputParamTemplate from 'text!../../../../../templates/audioEditor/sequencerNodeInspectorSection/paramView/SwitchInputParamTemplate.html';
import RSVP from 'rsvp';


const SwitchInputParamView = BasicInspectorNodeParamView.extend({

    constructor : function(attributes, options) {
        BasicInspectorNodeParamView.apply(this, [attributes, options]);
        this.compiledTemlate =  _.template(SwitchInputParamTemplate);
    },			


    _setSwitchValue : function(newValue){
        if(this.checkboxInput$el){
            this.checkboxInput$el.prop('checked', newValue);
        }
    },

    _getSwitchValue : function(){
        if(this.checkboxInput$el){
            return this.checkboxInput$el.prop('checked');
        }
    },

    
    updateViewOnValueChange : function(newValue){
        this._setSwitchValue(!!newValue);
    },

    render : function(){
        this.$el.html(this.compiledTemlate({ "viewObj": this}));
        this.setElement(this.$el.find(".basic_inpector_param_view").first());
        this.checkboxInput$el = this.$el.find("input");
        this.$el.enhanceWithin();
        this._setSwitchValue(this.getValue());
        this.checkboxInput$el.on("change", (function(){
            this.onViewValueChanged(this._getSwitchValue())
        }).bind(this))
    }

});
export default SwitchInputParamView;