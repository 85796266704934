import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import TranscribedAudio from "models/audioEditor/TranscribedAudio";
import SequencerNodeCloner from "models/audioEditor/sequencerNodes/SequencerNodeCloner";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const SequencerClipboard =  Backbone.Model.extend({

    constructor: function(attributes, options) {

        Backbone.Model.apply(this, [attributes, options]);
        //this._nodesInClipboard =  new Backbone.Collection();
        this.init();
    },

    init: function(){
        this._startNode = null;
        this._endNode = null;
        this._shouldBeClonedWhenRetrieved = false;
    },


    updateClipboard: function(startNode, endNode, shouldBeClonedWhenRetrieved){
        this._shouldBeClonedWhenRetrieved = shouldBeClonedWhenRetrieved;
        this._startNode = startNode;
        this._endNode = endNode;
        /*if(arrayOfSequencerNodes && arrayOfSequencerNodes.length > 0){
            this._nodesInClipboard.reset();
            arrayOfSequencerNodes.forEach((function(sequencernode){
                this._nodesInClipboard.add(sequencernode)
            }).bind(this))
        }*/
        Utils.getInstance().copyTextToClipboard(this.getSelectedNodesTextContent(startNode, endNode, true));
        this.trigger("clipboardUpdated");
    },



getSelectedNodesTextContent: function(startNode, endNode, includeSpeaker){
    let textContent = "";
    let sequencerNode = startNode;
    let currentSpeakerLabel = null;
    const UNKNOWN_SPEAKER_LABEL  = "Unknown";
    while(sequencerNode){
        if(Utils.getInstance().isWordSequencerNodeInstance(sequencerNode)){
            if(includeSpeaker && currentSpeakerLabel !== sequencerNode.getAudioSegment().getSpeakerLabel()){
                currentSpeakerLabel = sequencerNode.getAudioSegment().getSpeakerLabel() || UNKNOWN_SPEAKER_LABEL;
                textContent += "\n\n"+currentSpeakerLabel+": ";
            }
            if(textContent === ""){
                textContent = sequencerNode.getAudioSegment().getContent();
            }else{
                textContent =  textContent + " " + sequencerNode.getAudioSegment().getContent();
            }

        }
        if(sequencerNode === endNode){
            sequencerNode = null;
        }else{
            sequencerNode =  sequencerNode.getNextVisibleSequencerNode();
        }
    }
    return textContent;
},

    clear : function(){
        this.init();
    },

    /*updateClipboardOld: function(arrayOfSequencerNodes, shouldBeClonedWhenRetrieved){
        this._shouldBeClonedWhenRetrieved = shouldBeClonedWhenRetrieved;
        if(arrayOfSequencerNodes && arrayOfSequencerNodes.length > 0){
            this._nodesInClipboard.reset();
            arrayOfSequencerNodes.forEach((function(sequencernode){
                this._nodesInClipboard.add(sequencernode)
            }).bind(this))
        }
        this.trigger("clipboardUpdated");
    },*/

    /*getSequencerNodesInClipboard : function(){
        if(this._shouldBeClonedWhenRetrieved){
            const cloneSequencerNodesArray = [];
            this._nodesInClipboard.each(function(sequencerNodeToClone) {
                cloneSequencerNodesArray.push(sequencerNodeToClone.clone());
            });
            return cloneSequencerNodesArray;
        }else{
            return this._nodesInClipboard.toArray();
        }
    },*/


    getSequencerNodesInClipboard: function(){
        const startNodeToReturn  = this.getStartNodeInClipboard();
        const cloneSequencerNodesArray = [];
        let reachEnd = false;
        let nodeToAdd  = startNodeToReturn;
        while(!reachEnd){
            cloneSequencerNodesArray.push(nodeToAdd);
            const nextNodeToAdd = nodeToAdd.getNext();
            nodeToAdd.clearNext();
            nodeToAdd.clearPrevious();
            if(nextNodeToAdd){
                nodeToAdd = nextNodeToAdd;
            }else{
                reachEnd = true;
            }
        }
        return cloneSequencerNodesArray;
    },

    isClipboardEmpty: function(){
        return !this.getStartNodeInClipboard();
    },

    getStartNodeInClipboard : function(){
        if(this._shouldBeClonedWhenRetrieved){
            return SequencerNodeCloner.getInstance().cloneLinkedSequencerNodes(this._startNode, this._endNode);
        }else{
            return this._startNode;
        }
    },




});



export default SequencerClipboard; 