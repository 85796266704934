import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import ti18n from "i18n!nls/Commoni18n";
import freeTranscriptionMinLeftWarningTemplate from "text!../../../templates/common/FreeTranscriptionMinLeftWarningTemplate.html";
import RSVP from "rsvp";

const FreeTranscriptionMinLeftWarningView = Backbone.View.extend({


    initialize : function(options) {
        this.defaultTemplate =  _.template(freeTranscriptionMinLeftWarningTemplate);
        if(options){
            this._showLinkToUpgrade = options && options.showLinkToUpgrade? options.showLinkToUpgrade : false;
        }
    },

    events : {
        "click .upgrade_message_link" : "onLinkToUpgradeClicked",
    },

    onLinkToUpgradeClicked: function(){
        this.trigger("linkToUpgradeClicked");
    },

    setMinutesOfFreeTranscriptionLeft: function(minutesOfTranscriptionLeft){
        this.$el.find(".warning_message").html(window.getI18n(ti18n, "YOU_HAVE")+ " "+minutesOfTranscriptionLeft+" "+window.getI18n(ti18n, "MINUTES_OF_TRANSCRIPTION_REMAINING"));
        this.$el.find(".upgrade_message_link").html(window.getI18n(ti18n, "UPGRADE_TO_INCREASE_YOUR_LIMIT"));
    },

    isVisible : function(){
        return this._isVisible;
    },

    showWarning : function(show){
        this._isVisible = show;
        $(document.body).attr("display_transcription_minutes_left_warning", show?"true": "false");
        this.trigger("visibilityChange");
    },

    getHeight: function(){
        return this.isVisible()? this.$el.get(0).offsetHeight: 0;
    },

    render : function(){
        const params = {};
        params.showLinkToUpgrade = this._showLinkToUpgrade;
        this.$el.html(this.defaultTemplate(params));
        this.setElement(this.$el.find("header"));
        
        if(document.body.childNodes.length > 0){
            document.body.insertBefore(this.$el.get(0), document.body.childNodes[0]);
        }else{
            document.append(this.$el.get(0));
        }

        return this;
    },


});

export default FreeTranscriptionMinLeftWarningView;
