import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import SelectionListController from "controllers/selectionList/SelectionListController";
import Utils from "models/helper/Utils";
import LibrarySortAndFilterOptionView from "views/myLibrary/LibrarySortAndFilterOptionView";
import ListItemCollection from "collections/ListItemCollection";
import RSVP from "rsvp";

const LibrarySortAndFilterOptionController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = options.model;
        this._selectedFilterOption = options.selectedFilterOption;
        this._selectedSortOption = options.selectedSortOption;
        this._onPopupClose = options.onPopupClose;
        this.filterSelectionListController = this._buildFilterSelectionListController();
        this.sortSelectionListController = this._buildSortSelectionListController();
        this.context = options.context;
    },
    
    _buildFilterSelectionListController : function(){
        const selectionListControllerParams = {};
        selectionListControllerParams.singleSelect =  true;
        selectionListControllerParams.propertyFunction = "getLabel";
        selectionListControllerParams.showEmtptyInfoBox  = false;
        selectionListControllerParams.loadHandler = this.getFilterOptionsListItemCollection.bind(this);
        return new SelectionListController(selectionListControllerParams);
    },
    
    _buildSortSelectionListController : function(){
        const selectionListControllerParams = {};
        selectionListControllerParams.singleSelect =  true;
        selectionListControllerParams.propertyFunction = "getLabel";
        selectionListControllerParams.showEmtptyInfoBox  = false;
        selectionListControllerParams.loadHandler = this.getSortOptionsListItemCollection.bind(this);
        return new SelectionListController(selectionListControllerParams);
    },
    

    onPopupClose : function(){
        if(this._onPopupClose){
            this._onPopupClose(this.filterSelectionListController.getSelectedModel(), this.sortSelectionListController.getSelectedModel());
        }
    },
    
    getFilterOptionsListItemCollection : function(){
        const itemModelArray = [];
        const listItemCollection = new ListItemCollection();
        const songLibraryFilterOptions = Utils.getInstance().getSongLibraryFilterOptions();
        for(let itemId  in songLibraryFilterOptions){
            listItemCollection.add(Utils.getInstance().getListItemModel(itemId,  songLibraryFilterOptions[itemId]));
        }
        listItemCollection.sort();
        return RSVP.Promise.resolve(listItemCollection.models);
    },
    
    getSortOptionsListItemCollection : function(){
        const itemModelArray = [];
        const listItemCollection = new ListItemCollection();
        const songLibrarySortOptions = Utils.getInstance().getSongLibrarySortOptions();
        for(let itemId  in songLibrarySortOptions){
            listItemCollection.add(Utils.getInstance().getListItemModel(itemId,  songLibrarySortOptions[itemId]));
        }
        listItemCollection.sort();
        return RSVP.Promise.resolve(listItemCollection.models);
    },
    

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    buildModelAndRenderView : function() {
        $.mobile.loading("show");
        return new RSVP.Promise((function(resolve, reject) {
            try {
                const promises = [];
                promises.push(this.filterSelectionListController.buildModelAndRenderView());
                promises.push(this.sortSelectionListController.buildModelAndRenderView());
                RSVP.Promise.all(promises).then((function(){
                    const librarySortAndFilterOptionViewParams ={};
                    librarySortAndFilterOptionViewParams.filterSelectionListView = this.filterSelectionListController.getView();
                    this.filterSelectionListController.setSelected(this._selectedFilterOption, true);
                    librarySortAndFilterOptionViewParams.sortSelectionListView = this.sortSelectionListController.getView();
                    this.sortSelectionListController.setSelected(this._selectedSortOption, true);
                    const librarySortAndFilterOptionView = new LibrarySortAndFilterOptionView(librarySortAndFilterOptionViewParams);
                    librarySortAndFilterOptionView.render();
                    $.mobile.loading("hide");
                    this.view = librarySortAndFilterOptionView;
                    this.listenTo(this.view,"popupClosed",this.onPopupClose.bind(this))
                    
                }).bind(this)).catch((function(error){
                    $.mobile.loading("hide");
                    reject(error);
                }).bind(this));
                
            } catch (error) {
                $.mobile.loading("hide");
                reject(error);
            }

        }).bind(this));

    }

});

export default LibrarySortAndFilterOptionController;