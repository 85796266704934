import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import OptionPopupView from "views/common/OptionPopupView";
import ti18n from 'i18n!nls/Sequenceri18n';
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import CustomSearchFieldView from "views/common/CustomSearchFieldView";
import Utils from "models/helper/Utils";

const Rollout = RolloutHelper.getInstance();

const SelectAudioRegionPopupView = OptionPopupView.extend({

    "pageName": "Select Audio Region",

    initialize: function(options) {
        const viewOptions = {};

        const contentView = {};
        this._audioBufferRegionSelectorView =  options.audioBufferRegionSelectorView;
        contentView.$el = options.audioBufferRegionSelectorView.$el;
        viewOptions.contentView = contentView;
        viewOptions.contentPosition = "top";
        viewOptions.addContentAfterEnhancement = true;
        viewOptions.displayTitle = false;
        viewOptions.titleToDisplay = ""

        viewOptions.id = "SelectAudioRegionPopup";
        OptionPopupView.prototype.initialize.call(this, viewOptions);
    },



    afterRendering: function() {

    },

    onPopupClosed: function() {

    },

    onAfterPageShow: function() {
        this._audioBufferRegionSelectorView.renderAudioWaveform();
    },  



});

export default SelectAudioRegionPopupView;
