import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";

const Tag=  Backbone.Model.extend({
    "default": {
        "id" : null,
        "label" : {"EN": null},
        "tagGroupId" : null,
        "selected" : false,
    },
    
    constructor: function (attributes, options){
         Backbone.Model.prototype.constructor.call(this, attributes,options);

        },

});
export default Tag;