import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import StatisticsPageController from "controllers/trebbleStatistics/StatisticsPageController";
import TrebbleStatisticsContentView from "views/trebbleStatistics/TrebbleStatisticsContentView";
import CustomSelectFieldController from "controllers/common/CustomSelectFieldController";
import DynamicPageView from "views/common/DynamicPageView";
import ti18n from "i18n!nls/StatsPagei18n";
import RSVP from "rsvp";

const dataInSecondsToBeConverted = [];
const  dataTypeWithBarChart = [];
const actionTypeToUnsupportedContext = {"comments": ["executedByCapsuleOwnerContext"],"likes": ["executedByCapsuleOwnerContext"]};
const contextTypeToContextLabel = {"trebbleContext": window.getI18n(ti18n,"IN_A_TREBBLE"),"sharedCapsuleContext": window.getI18n(ti18n,"VIA_CAPSULE_SHARED_LINK_OR_EMBED")/*,"sharedSongContext":window.getI18n(ti18n,"VIA_SONG_SHARED_LINK_OR_EMBED"),"executedByCapsuleOwnerContext": window.getI18n(ti18n,"BY_ME")*/};
const supportedActionContext = ["sharedCapsuleContext","trebbleContext"/*,"sharedSongContext","executedByCapsuleOwnerContext"*/ ];
const dataTypes = [];

const dataTypeToLabel = {"plays": window.getI18n(ti18n,"PLAYS"),
"completePlays": window.getI18n(ti18n,"FINISHED_PLAYS"),
"skips": window.getI18n(ti18n,"SKIPS"),
//"shares": "Shared", 
"comments": window.getI18n(ti18n,"COMMENTS"),
"likes": window.getI18n(ti18n,"LIKES"),
//	"views": "Views",
//	"repicked": "Re-picked"
};

for(let dataType  in dataTypeToLabel){
    dataTypes.push(dataType);
}
const dataTypeToFontIcon = {
//"listeningTime": "ion-ios7-play-outline",
"plays": "ion-ios7-play-outline",
"completePlays": "ion-ios7-play-outline",
"skips": "ion-ios7-skipforward-outline",
"likes": "ion-ios7-skipforward-outline", 
"comments": "ion-ios7-chatbubble-outline"};
const CapsuleStatsPageController = StatisticsPageController.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = options.model;
        this._pageId = options.pageId;
        const statisticsPageControllerParams =  {};
        statisticsPageControllerParams.model = this.model;
        statisticsPageControllerParams.pageId = this._pageId;
        statisticsPageControllerParams.dataStatsId = this.model.getCapsuleId();
        statisticsPageControllerParams.supportedActionContext = supportedActionContext;
        statisticsPageControllerParams.getStatisticsLoadFunction = this._getStatistics;
        statisticsPageControllerParams.dataInSecondsToBeConverted = dataInSecondsToBeConverted;
        statisticsPageControllerParams.dataTypeWithBarChart =  dataTypeWithBarChart;
        statisticsPageControllerParams.actionTypeToUnsupportedContext = actionTypeToUnsupportedContext;
        statisticsPageControllerParams.contextTypeToContextLabel = contextTypeToContextLabel;
        statisticsPageControllerParams.dataTypes = dataTypes;
        statisticsPageControllerParams.dataTypeToLabel =  dataTypeToLabel;
        statisticsPageControllerParams.dataTypeToFontIcon =  dataTypeToFontIcon;
        statisticsPageControllerParams.entityName = window.getI18n(ti18n,"CAPSULE");
        
        const c = StatisticsPageController.prototype.initialize.call(this, statisticsPageControllerParams);

        return c;
    },



    _getStatistics : function(dataStatsId){
        return TrebbleClientAPIHelper.getInstance().getStatisticsForCapsule(dataStatsId);
    },



});

export default CapsuleStatsPageController;