var amdi18n={"__root":{"ALL_MY_MUSIC_FROM":"All My Music From","COMING_SOON":"Coming Soon!","RELATED_ARTISTS":"Related Artists","SINGLES":"Singles","COMPILATIONS":"Compilations","ALBUMS":"Albums","APPEARS_ON":"Appears On","POPULAR_SONGS":"Popular Songs","SEE_ALL_ALBUMS":"See All Albums","SEE_ALL_SINGLES":"See All Singles","SEE_ALL_APPEARS_ON":"See All Appearances","SEE_ALL_COMPILATIONS":"See All Compilations","BIOGRAPHY":"Biography","SEE_ALL_RELATED_ARTISTS":"See All Related Artists","YOUTUBE_VIDEOS":"Youtube Videos","MORE":"More","SORT_AND_FILTER":"Sort & Filter"},"__fr":{"ALL_MY_MUSIC_FROM":"Toute ma musique de","COMING_SOON":"Arrive bientôt!","RELATED_ARTISTS":"Artistes apparentés","SINGLES":"Singles","COMPILATIONS":"Compilations","ALBUMS":"Albums","APPEARS_ON":"Apparaît sur","POPULAR_SONGS":"Chansons populaires","SEE_ALL_ALBUMS":"Voir tous les albums","SEE_ALL_SINGLES":"Voir tous les singles","SEE_ALL_APPEARS_ON":"Voir toutes les apparences","SEE_ALL_COMPILATIONS":"Voir toutes les compilations","BIOGRAPHY":"Biographie","SEE_ALL_RELATED_ARTISTS":"Voir tous les artistes liés","YOUTUBE_VIDEOS":"Vidéos youtube","MORE":"Plus","SORT_AND_FILTER":"Trier et filtrer"},"__en":{"ALL_MY_MUSIC_FROM":"All My Music From","COMING_SOON":"Coming Soon!","RELATED_ARTISTS":"Related Artists","SINGLES":"Singles","COMPILATIONS":"Compilations","ALBUMS":"Albums","APPEARS_ON":"Appears On","POPULAR_SONGS":"Popular Songs","SEE_ALL_ALBUMS":"See All Albums","SEE_ALL_SINGLES":"See All Singles","SEE_ALL_APPEARS_ON":"See All Appearances","SEE_ALL_COMPILATIONS":"See All Compilations","BIOGRAPHY":"Biography","SEE_ALL_RELATED_ARTISTS":"See All Related Artists","YOUTUBE_VIDEOS":"Youtube Videos","MORE":"More","SORT_AND_FILTER":"Sort & Filter"}};amdi18n.init=function(language){
		// get the default language
		if(!language){
			if(typeof window !== 'undefined' && window._i18n && window._i18n.locale){
				language = window._i18n.locale;
			}else if(typeof document !== 'undefined' && document.documentElement.lang){
				language = document.documentElement.lang;
			}else if(typeof navigator !== 'undefined' && (navigator.languages || navigator.language || navigator.userLanguage)){
				// navigator.languages does not exist in IE 11
				language = (navigator.languages && navigator.languages[0]) ||
					navigator.language ||
					navigator.userLanguage;
				language = (language || 'root').toLowerCase();
			}else{
				language = 'root';
			}
		}
		var target = this['__' + language] || this.__root;

		// copy definition to root level
		if (target) {
			for (var name in target) {
				this[name] = target[name];
			}
		}

		// fallback to root
		var recursiveFallback = function(target, source){
			for(var name in source){
				switch(typeof target[name]){
				case 'undefined':
					target[name] = source[name];
					break;
				case 'object':
					recursiveFallback(target[name], source[name]);
				}
			}
		};
		recursiveFallback(this, this.__root);
	};amdi18n.init();module.exports=amdi18n;