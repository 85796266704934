import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import BasicInspectorNodeParamView from "views/audioEditor/sequencerNodeInspectorSection/paramView/BasicInspectorNodeParamView";
import WordParamTemplate from 'text!../../../../../templates/audioEditor/sequencerNodeInspectorSection/paramView/WordParamTemplate.html';
import RSVP from 'rsvp';


const WordParamView = BasicInspectorNodeParamView.extend({

    constructor : function(attributes, options) {
        BasicInspectorNodeParamView.apply(this, [attributes, options]);
        this.compiledTemlate =  _.template(WordParamTemplate);
    },			
    

    updateViewOnValueChange : function(newValue){
        if(this.textContent$el){
            this.textContent$el.html(newValue);
        }
    },

    render : function(){
        this.$el.html(this.compiledTemlate({ "viewObj": this}));
        this.setElement(this.$el.find(".basic_inpector_param_view").first());
        this.textContent$el = this.$el.find(".text_content");
    }

});
export default WordParamView;