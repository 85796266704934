
import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import OptionPopupView from "views/common/OptionPopupView";
import owlcarousel from "owlcarousel";
import PersistentModels from "services/PersistentModels";

const SequencerViewConfigSettingsPopupView = OptionPopupView.extend({

    
    initialize: function(options) {
        const viewOptions = {};
        viewOptions.contentView = options.sequencerViewConfigSettingsView;
        viewOptions.addContentAfterEnhancement = true;
        viewOptions.contentPosition = "top";
        this._popupUniqueId = "sequencerViewConfigSettingsPopupView";
        viewOptions.id = this._popupUniqueId;
        viewOptions.customClass = "sequencerViewConfigSettingsPopupView";
        viewOptions.elementSource$el = options.elementSource$el;
        viewOptions.alwaysShowAsFloatingMenu = true;
        OptionPopupView.prototype.initialize.call(this, viewOptions);
        PersistentModels.getInstance().getRouter().registerForViewportHeightChange(this._popupUniqueId,this.onOrientationChangeFunction.bind(this), true);
    },
    
    onOrientationChangeFunction  : function(newViewPortHeigh, forceResizing) {
        if(this.$el.is(":visible") || forceResizing){
            if (this.el) {
                const adjustedListContainerHeight = (newViewPortHeigh) ;
                this.$el.find(".ui-content").css("height", adjustedListContainerHeight + "px");
            }
        }
    },

    afterRendering: function() {

    },


    
    onPopupClosed: function() {
        OptionPopupView.prototype.onPopupClosed.call(this);
        PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._popupUniqueId, true);
        this.trigger("onPopupClosed");
    },

    
    onAfterPageShow: function() {

 
    },



});

export default SequencerViewConfigSettingsPopupView;
