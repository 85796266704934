import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import OptionPopupView from "views/common/OptionPopupView";
import PersistentModels from "services/PersistentModels";
import ti18n from "i18n!nls/Commenti18n";
import popupTemplate from 'text!../../../templates/comment/CommentListOptionTemplate.html';

const CommentListOptionView = OptionPopupView.extend({

    
    initialize: function(options) {
        const viewOptions = {};
        const contentViewTemplate = _.template(popupTemplate);
        const templateParams = {};
        templateParams.ti18n = ti18n;
        const contentView = {};
        contentView.$el = $(contentViewTemplate(templateParams));
        this._listView = options.commentListView?options.commentListView: null;
        this._commentBoxView = options.commentBoxView?options.commentBoxView: null;
        this._onAfterShow = options.onAfterShow?options.onAfterShow: null;
        this._commentContextView = options.commentContextView? options.commentContextView: null;
        if(this._commentContextView){
            $(contentView.$el[2]).append(this._commentContextView.$el.first());
        }
        if(this._listView){
            $(contentView.$el[2]).append(this._listView.$el.first());
        }
        if(this._commentBoxView){
            $(contentView.$el[2]).append(this._commentBoxView.$el);
        }
        viewOptions.contentView = contentView;
        viewOptions.addContentAfterEnhancement = true;
        viewOptions.contentPosition = "top";
        this._popupUniqueId = "CommentListOptionViewPopup";
        viewOptions.id = this._popupUniqueId;
        OptionPopupView.prototype.initialize.call(this, viewOptions);
        PersistentModels.getInstance().getRouter().registerForViewportHeightChange(this._popupUniqueId,this.onOrientationChangeFunction.bind(this), true);
        
    },
    
    onOrientationChangeFunction  : function(newViewPortHeigh, forceResizing) {
        if(this.$el.is(":visible")|| forceResizing){
            if (this.el) {
                const adjustedListContainerHeight = (newViewPortHeigh) ;
                const contextHeight = this._commentContextView?this._commentContextView.$el.height(): 0;
                const commentBoxHeigh = 121;
                this.$el.find(".ui-content").css("height", adjustedListContainerHeight + "px");
                this.$el.find("#collection_repeat_container").css("height", adjustedListContainerHeight - commentBoxHeigh - contextHeight + "px");
                this._listView._displayInfoBoxIfApplicable();
                this._listView._onRootElementResize();
            }
        }
    },

    afterRendering: function() {
       this.onOrientationChangeFunction(PersistentModels.getInstance().getRouter().getViewportHeight());
    },

    
    onPopupClosed: function() {
        OptionPopupView.prototype.onPopupClosed.call(this);
        PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._popupUniqueId, true);
    },

    
    onAfterPageShow: function() {
    //this.$el.find("#comment").focus();
        this.onOrientationChangeFunction(PersistentModels.getInstance().getRouter().getViewportHeight(), true);
        if(this._onAfterShow){
            this._onAfterShow(this);
        }
    },



});

export default CommentListOptionView;
