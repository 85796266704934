import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Song from "models/Song";
import TrebbleListView from "views/common/TrebbleListView";
import ti18n from "i18n!nls/Commoni18n";
import SongListItemTemplate from "text!../../../templates/common/SongListItemTemplate2.html";
import SongListItemTemplate2X from "text!../../../templates/common/SongListItemTemplate2X.html";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";

const disableSwipeOnListItem = true;
const TrebbleSongListView2 = TrebbleListView.extend({
    
    
    
    
    
    initialize : function(options) {
        if(!options){
            options= {};
        }
        if(!options.rightSwipeRevealOption){
            options.rightSwipeRevealOption = this._buildItemRightOption()
        }
        if(!options.leftSwipeRevealOption){
            options.leftSwipeRevealOption = this._buildItemLeftOption()
        }
        
        this._actionItemButtonClass = options.actionItemButtonClass;
        this._doNotShowSongCoverImage = options.doNotShowSongCoverImage;
        this._onPostTemplateDataCreated = options.onPostTemplateDataCreated;
        this._onPostItemRendered = options.onPostItemRendered;
        this._doNotShowAddToRadioButton = options.doNotShowAddToRadioButton;
        this._doNotShowGradeSongButton = options.doNotShowGradeSongButton;
        this._showInfoButton = options.showInfoButton;
        this._showCommentButton = options.showCommentButton;
        this._numberOfItemsToDisplayPerRow = options.numberOfItemsToDisplayPerRow;

        this.collection = options.collection
        options.modelIdAttribute  = "uri";
        options.listItemTemplate = SongListItemTemplate;
        /*if(this._numberOfItemsToDisplayPerRow != 1  || (window.device && window.device.platform =="browser")){
            options.listItemTemplate = SongListItemTemplate2X;
        }*/
        const self = TrebbleListView.prototype.initialize.call(this, options);
        this.setModelTransformHandlerBeforeTemplatePiping(this._modelTransformHandlerBeforeTemplatePiping);
        this.setItemViewUpdateDuringScrollingHandler(this._getItemViewUpdateDuringScrollingHandler);
        return self;
        
    },
    
    _getDefaultItemEventHandler : function(){
        let defaultItemEventHandler =  TrebbleListView.prototype._getDefaultItemEventHandler.call(this);
        defaultItemEventHandler = defaultItemEventHandler? defaultItemEventHandler : [];
        delete defaultItemEventHandler["click div.songOptionBtnWrapper > #songLibraryOptionButtuon"];
        delete defaultItemEventHandler["click div.capsuleBtnWrapper > #songCapsuleOptionButtuon"];
        delete defaultItemEventHandler["click div.showInfoBtnWrapper > #showInfoOptionButton"];
        delete defaultItemEventHandler["click div.showCommentBtnWrapper > #showCommentOptionButton"];
        return defaultItemEventHandler;
    },
    reload : function(){
        this.setUpdating(true);
        try{
            const displayedSongJSONArray = this.collection? this.collection.toJSON(): [];
            this.updateModels(displayedSongJSONArray);
            this.setUpdating(false);
        }catch(error){
            this.setErrorMessage(error);
        }
    },
    
    _getInternalOnCapsuleIconTapHandler : function(model,evnt, item) {
        const songModel = this._getSongModelFromSongJson(model);
        const context = this.getContext();
        if(songModel){
            return PersistentModels.getInstance().getRouter().showCapsulesForSong(songModel, context);
        }else{
            return RSVP.Promise.reject(window.getI18n(ti18n,"SONG_COULDNT_BE_FOUND"));
        }	
    },

    
_buildItemRightOption : function(){
    const optionConfig = [];
    if(!disableSwipeOnListItem && (((window.device && window.device.platform != "browser") || window.isMobileBrowser))){
        optionConfig.push( {"optionLabel" : "..."});
        optionConfig.push( {"optionLabel" : window.getI18n(ti18n,"ADD_TO_MY_TREBBLE")+"<i class='ion-ios7-plus-outline'></i>","name": "addToRadio", "validationHandler": this._canBeAddedToRadio.bind(this), "onOptionSelected": this._onAddSongToRadioMenuSelected.bind(this)});//"<i class='fontello-icon-list-add'></i>","name": "addToQueue"};// "Add To Queue"};
        optionConfig.push( {"optionLabel" : window.getI18n(ti18n,"GRADE")+"<i class='ion-ios7-pricetags-outline'></i>","name": "rateOrTag" , "validationHandler": null, "onOptionSelected": this._onTagSongOptionMenuSelected.bind(this)});//"<i class='ion-radio-waves'></i>","name": "playSimilarInLibrary"};//: "Play Similar In Library"};
    }
    return optionConfig;
},

_buildItemLeftOption : function(){
    const optionConfig = [];
    if(!disableSwipeOnListItem && (((window.device && window.device.platform != "browser") || window.isMobileBrowser))){
        optionConfig.push( {"optionLabel" : "..."});
        optionConfig.push( {"optionLabel": "<i class='pe-7s-more'></i>"+ window.getI18n(ti18n,"GRADE"),"name": "showOptions", "validationHandler": null, "onOptionSelected": this._onShowSongOptionMenuSelected.bind(this)});//"<i class='fontello-icon-list-add'></i>"};// "Add To Queue"};
        optionConfig.push( {"optionLabel": "<i class='ion-ios7-minus-outline'></i>"+  window.getI18n(ti18n,"REMOVE_FROM_MY_TREBBLE"),"name": "removeFromRadio", "validationHandler":  this._canBeRemovedToRadio.bind(this), "onOptionSelected": this._onRemoveSongToRadioMenuSelected.bind(this)});//"<i class='ion-radio-waves'></i>"};// : "Play Similar In Library"};
    }
    return optionConfig;
},

_canBeAddedToRadio : function(songJson){
    const songModel = this._getSongModelFromSongJson(songJson);
    return songModel?!songModel.get("addedToRadio"): true;
    
},

_canBeRemovedToRadio : function(songJson){
    const songModel = this._getSongModelFromSongJson(songJson);
    return songModel?songModel.get("addedToRadio"): false;
    
},

_onAddSongToRadioMenuSelected : function(songJson){
    const songModel = this._getSongModelFromSongJson(songJson);
    const context = this.getContext();
    if(songModel){
        const p =  PersistentModels.getInstance().addSongToDefaultRadio(songModel, context);
        this.trigger("songAddedToTrebble", songModel);
        return p;
    }else{
        return RSVP.Promise.reject(window.getI18n(ti18n,"SONG_TO_ADD_COULDNT_BE_FOUND"));
    }
    
},

_onRemoveSongToRadioMenuSelected : function(songJson){
    const songModel = this._getSongModelFromSongJson(songJson);
    const context = this.getContext();
    if(songModel){
        const  p = PersistentModels.getInstance().removeSongFromDefaultRadio(songModel, context);
        this.trigger("songRemovedFromTrebble", songModel);
        return p;
    }else{
        return RSVP.Promise.reject(window.getI18n(ti18n,"SONG_TO_ADD_COULDNT_BE_FOUND"));
    }
},

_onShowSongOptionMenuSelected : function(songJson){
    const songModel = this._getSongModelFromSongJson(songJson);
    if(songModel){
        const context = this.getContext();
        return PersistentModels.getInstance().getRouter().showSongMenuOption(songModel, context,null, this.onActionExecutedOnSongHandler.bind(this));
    }else{
        return RSVP.Promise.reject(window.getI18n(ti18n,"SONG_TO_ADD_COULDNT_BE_FOUND"));
    }
},

onActionExecutedOnSongHandler : function(songModel, action){
    this.trigger("actionExecutedOnSongHandler", songModel, action);
    /*if(action == "delete" || action == "removeFromRadio" || action == "removeToFavorite"){
        
    }*/
},


_onTagSongOptionMenuSelected : function(songJson){
    const songModel = this._getSongModelFromSongJson(songJson);
    if(songModel){
        const context = this.getContext();
        return PersistentModels.getInstance().getRouter().showSongTagManagerPopup(songModel, context);
    }else{
        return RSVP.Promise.reject(window.getI18n(ti18n,"SONG_TO_ADD_COULDNT_BE_FOUND"));
    }
},

getContext : function(){
    return this.collection? this.collection.getContext(): null;
},


_getSongModelFromSongJson : function(songJson){
    return this.collection.get(songJson.uri);
},


    
_modelTransformHandlerBeforeTemplatePiping: function(songJson) {
    let artistToDisplay;
    let titleToDisplay;
    let albumNameToDisplay;
    if (songJson.idified && (songJson.idified === "true" || songJson.idified === true) && (songJson.idifiedSuccess === "true" || songJson.idifiedSuccess === true)) 
    {
        if (window.trebble.config.showIdifiedArtistName) 
        {
            const featuredArtists = songJson.idifiedFeaturedArtists;
            let featuredArtistsString = null;
            if(featuredArtists && featuredArtists.length > 0){
                let songTitle =  songJson.title;
                if( songJson.idifiedTitle && window.trebble.config.showIdifiedSongTitle)
                {
                    songTitle = songJson.title;
                }
                for(let index in featuredArtists){
                    const featuredArtist = featuredArtists[index];
                    if(!songTitle || (songTitle && songTitle.toUpperCase().indexOf(featuredArtist.artist.toUpperCase()) == -1)){	
                        if(!featuredArtistsString)
                        {
                            featuredArtistsString =   featuredArtist.artist;
                        }else{
                            featuredArtistsString =  featuredArtistsString + ", " + featuredArtist.artist;
                        }
                    }
                }
            }
            const mainArtist = songJson.idifiedArtist;
            
            const allArtists = featuredArtistsString? mainArtist + ", " +featuredArtistsString : mainArtist;
            artistToDisplay = allArtists;
        }else{
            artistToDisplay = songJson.artist;
        }
        if (window.trebble.config.showIdifiedSongTitle) 
        {
            titleToDisplay = songJson.idifiedTitle;
        }else{
            titleToDisplay = songJson.title;
        }
        if(window.trebble.config.showIdifiedAlbumTitle){
            albumNameToDisplay = songJson.idifiedAlbum;
        }else{
            albumNameToDisplay = songJson.album;
        }
    } else {
        artistToDisplay = songJson.artist;
        titleToDisplay = songJson.title;
        albumNameToDisplay = songJson.album;
    }
    if (!artistToDisplay) 
    {
        artistToDisplay = "";
    }
    if (!titleToDisplay) 
    {
        titleToDisplay = "";
    }
    
    if (!albumNameToDisplay) 
    {
        albumNameToDisplay = "";
    }
    const explicit = songJson.idifiedExplicit == !!"true" || songJson.explicit == !!"true"? true : false;
    const hasCapsule = songJson.hasCapsules;
    const songTagIds = songJson.tagIds;
    const radioUIDs = songJson.radioUIDs;
    const addedToRadio = (radioUIDs && radioUIDs.length > 0)? true: false;
    const beingAddedToTrebble = songJson.beingAddedToTrebble;
    const beingRemovedFromTrebble = songJson.beingRemovedFromTrebble;
    const numberOfTagsAttachedToSong = (songTagIds && songTagIds.length > 0)?songTagIds.length : 0;
    const albumArtUrl = (songJson.albumArtUrl && !this._doNotShowSongCoverImage)?window.trebble.globalHelpers.getResizeImageUrl(songJson.albumArtUrl, 100, 100, songJson.albumArtImages): "";
    let templateData = {"albumArtUrl": albumArtUrl,"addedToRadio": addedToRadio,"numberOfTagsAttachedToSong": numberOfTagsAttachedToSong , "hasCapsule": hasCapsule,"titleToDisplay": titleToDisplay,"artistToDisplay": artistToDisplay + " · "+ albumNameToDisplay ,"actionButtonIconClass" : this._actionItemButtonClass,"model" : songJson, "doNotShowSongCoverImage": this._doNotShowSongCoverImage,"doNotShowAddToRadioButton":this._doNotShowAddToRadioButton,"doNotShowGradeSongButton":this._doNotShowGradeSongButton,"showInfoButton":this._showInfoButton,"showCommentButton":this._showCommentButton,"beingAddedToTrebble": beingAddedToTrebble,"beingRemovedFromTrebble": beingRemovedFromTrebble,"explicit": explicit};
    if(this._onPostTemplateDataCreated){
        templateData =  this._onPostTemplateDataCreated(songJson, templateData);
    }
    return templateData;
},

_getItemViewUpdateDuringScrollingHandler : function(elem,templateData){
    
    if(!this._doNotShowSongCoverImage){
        const coverArtElem = elem.querySelector(".songCoverArt");
        coverArtElem.src = window.getResourceURLAccordingToLocationProtocol("/img/videoLoading.png");
        coverArtElem.setAttribute("data-original", templateData.albumArtUrl);
        if(!coverArtElem.className ||  coverArtElem.className.indexOf("lazy") == -1){
            coverArtElem.className = (!coverArtElem.className)? "lazy" :  coverArtElem.className +" lazy";
        }
    }
    const firstItemElem = elem.querySelector(".songName.itemTitle");
    firstItemElem.textContent = templateData.titleToDisplay;
    const secondItemElem = elem.querySelector(".artistName.itemSubtitle");
    secondItemElem.textContent = templateData.artistToDisplay;
    
    const capsuleDivWrapper = elem.querySelector("#songCapsuleOptionButton");
    
    const explicitIconWrapper = elem.querySelector(".explicitIconWrapper");
    if(explicitIconWrapper){
        if(templateData.explicit){
            if(explicitIconWrapper.style.display != "-webkit-flex"){
                explicitIconWrapper.style.display  = "-webkit-flex";
                if(explicitIconWrapper.style.display != "-webkit-flex"){
                    explicitIconWrapper.style.display  = "flex";
                }
            }
        }else{
            explicitIconWrapper.style.display  = "none";
        }
    }
    
    if(templateData.hasCapsules){
        
        capsuleDivWrapper.className = "actionIcon enabled "+"ion-contrast";
        capsuleDivWrapper.style.display = "block";
    }else{
        capsuleDivWrapper.className = "actionIcon";
        capsuleDivWrapper.style.display = "none";
    }
    
    const actionButtonElem = elem.querySelector("#actionButton");
    if(templateData.actionButtonIconClass){
        
        actionButtonElem.className = "actionIcon enabled "+templateData.actionButtonIconClass;
        actionButtonElem.style.display = "flex";
        if(actionButtonElem.style.display != "flex"){
            actionButtonElem.style.display = "-webkit-flex";
        }
    }else{
        actionButtonElem.className = "actionIcon";
        actionButtonElem.style.display = "none";
    }
    if(!this._doNotShowAddToRadioButton){
        const addToTrebbleBtnWrapper = elem.querySelector(".addToTrebbleBtnWrapper");
        if(addToTrebbleBtnWrapper){
            if(templateData.beingAddedToTrebble || templateData.beingRemovedFromTrebble){
                if(addToTrebbleBtnWrapper.children[0].className.indexOf(" ion-loading-c animate-spin") == -1){
                    addToTrebbleBtnWrapper.children[0].className = addToTrebbleBtnWrapper.children[0].className + " ion-loading-c animate-spin";
                }
            }else{
                if(addToTrebbleBtnWrapper.children[0].className.indexOf(" ion-loading-c animate-spin") != -1){
                    addToTrebbleBtnWrapper.children[0].className = addToTrebbleBtnWrapper.children[0].className.replace(" ion-loading-c animate-spin", "");
                }
                if(templateData.addedToRadio){
                    if(addToTrebbleBtnWrapper.children[0].className.indexOf("ion-ios7-checkmark-outline") == -1){
                        addToTrebbleBtnWrapper.children[0].className = addToTrebbleBtnWrapper.children[0].className.replace("ion-ios7-plus-outline", "");
                        addToTrebbleBtnWrapper.children[0].className = addToTrebbleBtnWrapper.children[0].className + " ion-ios7-checkmark-outline";
                    }
                }else{
                    if(addToTrebbleBtnWrapper.children[0].className.indexOf("ion-ios7-plus-outline") == -1){
                        addToTrebbleBtnWrapper.children[0].className = addToTrebbleBtnWrapper.children[0].className.replace("ion-ios7-checkmark-outline", "");
                        addToTrebbleBtnWrapper.children[0].className = addToTrebbleBtnWrapper.children[0].className + " ion-ios7-plus-outline";
                    }
                }
            }
        }
    }
    
    if(!this._doNotShowGradeSongButton){
        const gradesBtnWrapper = elem.querySelector(".gradesBtnWrapper");
        if(gradesBtnWrapper){
            if(!templateData.numberOfTagsAttachedToSong || templateData.numberOfTagsAttachedToSong == 0){
                if(gradesBtnWrapper.children[0].className.indexOf("ion-ios7-pricetags-outline") == -1){
                    gradesBtnWrapper.children[0].className = gradesBtnWrapper.children[0].className.replace("ion-ios7-pricetags graded", "");
                    gradesBtnWrapper.children[0].className = gradesBtnWrapper.children[0].className + " ion-ios7-pricetags-outline";
                }
            }else{
                if(gradesBtnWrapper.children[0].className.indexOf("ion-ios7-pricetags graded")  == -1){
                    gradesBtnWrapper.children[0].className = gradesBtnWrapper.children[0].className.replace("ion-ios7-pricetags-outline", "");
                    gradesBtnWrapper.children[0].className = gradesBtnWrapper.children[0].className + " ion-ios7-pricetags graded ";
                }
            }
        }
    }
    if(this._showCommentButton){
            const showCommentBtnWrapper = elem.querySelector(".showCommentBtnWrapper");
            if(showCommentBtnWrapper){
                if(window.trebble.globalHelpers.Utils.commentsCanBeDisplayedForThisAudioUri(templateData.model.uri) && window.trebble.globalHelpers.Utils.canCommentsBeRetrieveFromAudioObjJson(templateData.model)){
                    if(showCommentBtnWrapper.style.display != "-webkit-flex"){
                        showCommentBtnWrapper.style.display  = "-webkit-flex";
                        if(showCommentBtnWrapper.style.display != "-webkit-flex"){
                            showCommentBtnWrapper.style.display  = "flex";
                        }
                    }
                }else{
                    if(showCommentBtnWrapper.style.display != "none"){
                        showCommentBtnWrapper.style.display  = "none";
                    }
                }
            }
        }
        if(this._showInfoButton){
            const showInfoBtnWrapper = elem.querySelector(".showInfoBtnWrapper");
            if(showInfoBtnWrapper){
                if(window.trebble.globalHelpers.Utils.artistInfoCanBeDisplayedForThisAudioUri(templateData.model.uri) && window.trebble.globalHelpers.Utils.canAudioInfoBeRetrieveFromAudioObjJson(templateData.model)){
                    if(showInfoBtnWrapper.style.display != "-webkit-flex"){
                        showInfoBtnWrapper.style.display  = "-webkit-flex";
                        if(showInfoBtnWrapper.style.display != "-webkit-flex"){
                            showInfoBtnWrapper.style.display  = "flex";
                        }
                    }
                }else{
                    if(showInfoBtnWrapper.style.display != "none"){
                        showInfoBtnWrapper.style.display  = "none";
                    }
                }
            }
        }
    
    /*const songLibraryWrapperElem = elem.querySelector(".songLibraryWrapper");
    if(songLibraryWrapperElem){
        if(window._isSongPlaying && window._playerLoadedSongUri == templateData.model.uri){
            if(songLibraryWrapperElem.className.indexOf("currentlyPlaying") == -1){
                songLibraryWrapperElem.className = songLibraryWrapperElem.className + " currentlyPlaying ";
            }
        }else{
            if(songLibraryWrapperElem.className.indexOf("currentlyPlaying") != -1){
                songLibraryWrapperElem.className = songLibraryWrapperElem.className.replace("currentlyPlaying", "");
            }
        }
    }*/
    if(this._onPostItemRendered){
         this._onPostItemRendered(elem,templateData);
    }
    
},


});

export default TrebbleSongListView2;
