import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import RecordAudioHightlightPageNewView from "views/audioHighlight/RecordAudioHightlightPageNewView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import AudioRecorderHelper from "models/helper/AudioRecorderHelper";
import SongSelectorListController from "controllers/common/SongSelectorListController";
import SingleSongSelectorInputController from "controllers/common/SingleSongSelectorInputController";
import YTSCRemoteSongCollection from "collections/YTSCRemoteSongCollection";
import SongContextController from "controllers/common/SongContextController";
import ListItemCollection from "collections/ListItemCollection";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import ProgressBarController from "controllers/common/ProgressBarController";
import CustomSelectFieldController from "controllers/common/CustomSelectFieldController";
import Songs from "collections/Songs";
import FeatureRolloutHelper from "models/helper/FeatureRolloutHelper";
import ti18n from "i18n!nls/RecordAudioHightlightPagei18n";
import FacebookEventTrackingHelper from "models/helper/FacebookEventTrackingHelper";
import TranscriptionManager from "models/helper/TranscriptionManager";
import SequencerController from "controllers/audioEditor/SequencerController";
import PeakMeterWidgetController from "controllers/common/PeakMeterWidgetController";
import TrebbleAudio from "models/TrebbleAudio";
import TrebbleVideo from "models/TrebbleVideo";
import FileUploadHelper from "models/helper/FileUploadHelper";
import moment from 'moment';
import RSVP from "rsvp";

const REMEMBER_UPLOAD_SETTING_IN_CURRENT_SESSION = true;
const CREATION_MODE = {"FROM_FILE":"fromFile", "FROM_RECORDING":"fromRecording","FROM_TEXT":"fromText", "FROM_VOICE_ACTOR":"fromVoiceActor"};
const IS_CAPSULE_CREATION_VIA_TEXT_AVAILABLE = FeatureRolloutHelper.getInstance().isFeatureEnabled(FeatureRolloutHelper.FEATURES.CREATE_CAPSULE_FROM_TEXT);
const IS_CAPSULE_CREATION_VIA_VOICE_ACTOR = FeatureRolloutHelper.getInstance().isFeatureEnabled(FeatureRolloutHelper.FEATURES.CREATE_CAPSULE_USING_VOICE_ACTOR);
const CONFIGURE_BACKGROUND_MUSIC = FeatureRolloutHelper.getInstance().isFeatureEnabled(FeatureRolloutHelper.FEATURES.CONFIGURE_BACKGROUND_MUSIC);

const ALLOW_USER_TO_RECORD_MULTIPLE_AUDIO_SEGMENT = false;
const ONLY_SHOW_COUNTER_ANIMATION_FOR_FIRST_SEGMENT_RECORDING = false;
const TREBBLE_TEXT_BASED_AUDIO_EDITOR = FeatureRolloutHelper.getInstance().isFeatureEnabled(FeatureRolloutHelper.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR, true);
const LIMIT_MAX_FILE_RENDERED = false;
const SAVE_AUDIO_WAV_FILE_FROM_AUDIO_EDITOR = false;// FeatureRolloutHelper.getInstance().getFeatureVariable(FeatureRolloutHelper.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR, FeatureRolloutHelper.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR.variables.save_wav_file_when_saving_shortcast, true);
const RENDER_VIDEO_ON_BACKEND = FeatureRolloutHelper.getInstance().isFeatureEnabled(FeatureRolloutHelper.FEATURES.RENDER_VIDEO_IN_CLOUD, true);
const RECORD_PAGE_ONBOARDING = FeatureRolloutHelper.getInstance().isFeatureEnabled(FeatureRolloutHelper.FEATURES.RECORD_PAGE_ONBOARDING, true);
const RecordAudioHightlightPageNewController = Chaplin.Controller.extend({

    initialize : function(options) {
        this._defaultSongToPlayAfterCapsule = options.songModel;
        this.capsuleId = options.capsuleId;
        this.context = options.context;
        this._pageId = options.pageId;
        this._receiverUserId = options.receiverUserId;
        this._createFromTranscriptionId =  options.transcriptionId;
        this._createFromProjectId = options.projectIdToDuplicate;
        this._editProjectId = options.projectIdToEdit;
        this._capsuleIdToEdit = options.capsuleIdToEdit;
        this._capsuleInfoToEdit = null;
        this._audioRecordingSupported = options.audioRecordingSupported;
        this._onCapsuleSuccessfullyCreated = options.onCapsuleSuccessfullyCreated;
        this._onCapsuleSuccessfullyUpdated =  options.onCapsuleSuccessfullyUpdated;
        this._onCapsuleCreationPageClosed = options.onCapsuleCreationPageClosed;
        this._addBackgroundMusicToUploadedAudio = options.addBackgroundMusicToUploadedAudio ;
        this._backgroundTrackUrl =  options.backgroundMusicUri;
        this._onAllAudioRecorded = options.onAllAudioRecorded;
        this._recordOnly = options.recordOnly;
        this._returnToDefaultAfterCreation = options.returnToDefaultAfterCreation;
        this._customPageTitle = options.customPageTitle;
        this._fileNameOfCurrentRecording = null;
        this._currentRecordingCounter = null;
        this._creationMode = CREATION_MODE.FROM_RECORDING;
        this._saveAsDraft = options.saveAsDraft;
        this._createAShortcast = options.createAShortcast;
        this._startCreationInFileUploadMode = options.startCreationInFileUploadMode;
        this._canSaveOnlyAfterTranscriptionAndEditing = options.canSaveOnlyAfterTranscriptionAndEditing;
        this._buildCreationModeListItemModels();
        this.creationModeSelectorController = this._buildCreationModeSelectFieldController();
        this._buildCreationModeFlatListView();

        this._recordingCounterArray  = [];
        this._filenameArray = [];
        this._recordingCounterToFilename = {};
        this._processQueueStarted = false;
        this._uploadedAudioPartInfoForTranscriptionAndEditing = null;
        this._audioUriToTrebbleAudio = null;
        this._lastTranscriptionResults = null;
        this._sequencerController = null;
        this._lastRenderWavFileBlob = null;

            //this.songSelectorListController = this._buildSongSelectorListController();
        this.songAttachedToCapsuleSongSelectorInputController = this._buildSongAttachedToCapsuleSongSelectorInputControllerNew();
        this.backgroundSongSelectorInputController = this._buildBackgroundSongSelectorInputController();
        this.peakMeterWidgetController = this._buildPeakMeterWidgetController();
        this.capsuleSchedulingAvailable = LocalStorageHelper.getInstance().isUserHasCapsuleSchedulingFeature();
        this.capsuleInfoProgressBarController = new ProgressBarController();
        this.audioEditorProgressBarController = new ProgressBarController();
        _.extend(this, Backbone.Events);
    },

    _buildCreationModeListItemModels : function(){
        this._creationFromRecordingListItemModel  = Utils.getInstance().getListItemModel(CREATION_MODE.FROM_RECORDING, "<span class='icon pe-7s-micro'></span>" +this._getRecordCreationOptionLabel());
        this._creationFromVoiceActorListItemModel  = Utils.getInstance().getListItemModel(CREATION_MODE.FROM_VOICE_ACTOR,   "<span class='icon pe-7s-headphones'></span>" +window.getI18n(ti18n, "USE_VOICE_ACTOR"));
            this._creationFromFileListItemModel  = Utils.getInstance().getListItemModel(CREATION_MODE.FROM_FILE,   "<span class='icon pe-7s-upload'></span>" + window.getI18n(ti18n, "IMPORT"));//IMPORT_AUDIO
            this._creationFromTextListItemModel  = Utils.getInstance().getListItemModel(CREATION_MODE.FROM_TEXT,   "<span class='icon pe-7s-news-paper'></span>" +window.getI18n(ti18n, "USE_TEXT"));
        },

        _buildSongSelectorInfoBoxParams:function(){
            return Utils.getInstance().buildInfoBoxParams(null, "ion-ios7-musical-notes", null, window.getI18n(ti18n,"NO_YOUTUBE_VIDEOS_FOUND"), window.getI18n(ti18n,"PLEASE_TRY_DIFFERENT_KEYWORD"), null, null);
        },

        _buildSongSelectorInfoBoxParamsForEmptySearchField:function(){
            return Utils.getInstance().buildInfoBoxParams(null, null, null, window.getI18n(ti18n,"CHOOSE_YOUTUBE_VIDEO_TO_USE_AS_BACKGROUND_MUSIC"), null, null, null);
        },

        _buildCreationModeSelectFieldController : function(){
            const controllerParams = {};
            controllerParams.singleSelect =  true;
            controllerParams.propertyFunction = "getLabel";
            controllerParams.propertyIdFunction = "getId";
            controllerParams.showEmtptyInfoBox  = true;
            controllerParams.loadHandler = this.getCreationModesAvailable.bind(this);
            controllerParams.defaultText = window.getI18n(ti18n, "MODE");
            const customSelectFieldController = new CustomSelectFieldController(controllerParams);
            
            return customSelectFieldController;
        },

        _getRecordCreationOptionLabel :function(){
            let recordingOptionLabel = window.getI18n(ti18n, "RECORD_FROM_THIS_BROWSER");
            if(window.isMobileBrowser){
                recordingOptionLabel = window.getI18n(ti18n, "RECORD_FROM_THIS_PHONE");
            }
            return  window.getI18n(ti18n, "RECORD");
            return recordingOptionLabel;
        },

        _buildCreationModeFlatListView : function(){
            this._creationModeFlatListViewContainer$el =  $("<div  id='creation_mode_flat_list_container' class=' tag_button_list_wrapper size_large no_max_width display_on_desktop'></div>");
            if(this._audioRecordingSupported){
                this._recordModeItemViewButton$el = $("<div class='list_tag_button'>"+"<span class='icon pe-7s-micro'></span>" +this._getRecordCreationOptionLabel()+"</div>")
                this._creationModeFlatListViewContainer$el.append(this._recordModeItemViewButton$el);
                this._recordModeItemViewButton$el.on("click", (function(){
                    //this.showSelectedCreationMode(this._recordModeItemViewButton$el);
                    this._onCreationModeSelectionChanged(this._creationFromRecordingListItemModel);
                    /*if(this.creationModeSelectorController){
                        this.creationModeSelectorController.setSelected(this._creationFromRecordingListItemModel, true, true);
                    }*/
                }).bind(this));
            }
            this._creationFromFileModeItemViewButton$el = $("<div class='list_tag_button'>"+  "<span class='icon pe-7s-upload'></span>" +window.getI18n(ti18n, "IMPORT") +"</div>")
            if(this._startCreationInFileUploadMode){
                this._creationModeFlatListViewContainer$el.prepend(this._creationFromFileModeItemViewButton$el);	
            }else{
                this._creationModeFlatListViewContainer$el.append(this._creationFromFileModeItemViewButton$el);
            }
            this._creationFromFileModeItemViewButton$el.on("click", (function(){
                    //this.showSelectedCreationMode(this._creationFromFileModeItemViewButton$el);
                this._onCreationModeSelectionChanged(this._creationFromFileListItemModel);
                    /*if(this.creationModeSelectorController){
                        this.creationModeSelectorController.setSelected(this._creationFromFileListItemModel, true, true);
                    }*/
            }).bind(this));
            if(IS_CAPSULE_CREATION_VIA_VOICE_ACTOR){
                this._creationViaVoiceActorModeItemViewButton$el = $("<div class='list_tag_button'>"+"<span class='icon pe-7s-headphones'></span>" +window.getI18n(ti18n, "USE_VOICE_ACTOR")+"</div>")
                this._creationModeFlatListViewContainer$el.append(this._creationViaVoiceActorModeItemViewButton$el);
                this._creationViaVoiceActorModeItemViewButton$el.on("click", (function(){
                    //this.showSelectedCreationMode(this._creationViaVoiceActorModeItemViewButton$el);
                    this._onCreationModeSelectionChanged(this._creationFromVoiceActorListItemModel);
                    /*if(this.creationModeSelectorController){
                        this.creationModeSelectorController.setSelected(this._creationFromVoiceActorListItemModel, true, true);
                    }*/
                }).bind(this));
            }
            if(IS_CAPSULE_CREATION_VIA_TEXT_AVAILABLE){
                this._creationFromTextModeItemViewButton$el = $("<div class='list_tag_button'>"+ "<span class='icon pe-7s-news-paper'></span>" +window.getI18n(ti18n, "USE_TEXT") +"</div>")
                this._creationModeFlatListViewContainer$el.append(this._creationFromTextModeItemViewButton$el);
                this._creationFromTextModeItemViewButton$el.on("click", (function(){
                    //this.showSelectedCreationMode(this._creationFromTextModeItemViewButton$el);
                    this._onCreationModeSelectionChanged(this._creationFromTextListItemModel);
                    /*if(this.creationModeSelectorController){
                        this.creationModeSelectorController.setSelected(this._creationFromTextListItemModel, true, true);
                    }*/
                }).bind(this));
            }
        },

        _setCreationMode$elAsSelected : function(list_tag_button$el){
            if(this._selectedCreationModeButton$el){
                this._selectedCreationModeButton$el.removeClass("selected");
            }
            this._selectedCreationModeButton$el = list_tag_button$el;
            this._selectedCreationModeButton$el.addClass("selected");

        },

        showSelectedCreationMode: function(creationModeListModel ){
            let creationModeListTagButton$el = null;
            if(creationModeListModel === this._creationFromRecordingListItemModel){
                creationModeListTagButton$el = this._recordModeItemViewButton$el;
            }
            if(creationModeListModel === this._creationFromVoiceActorListItemModel){
                creationModeListTagButton$el = this._creationViaVoiceActorModeItemViewButton$el;
            }
            if(creationModeListModel === this._creationFromFileListItemModel){
                creationModeListTagButton$el = this._creationFromFileModeItemViewButton$el;
            }
            if(creationModeListModel === this._creationFromTextListItemModel){
                creationModeListTagButton$el = this._creationFromTextModeItemViewButton$el;
            }
            if(creationModeListTagButton$el){
                this._setCreationMode$elAsSelected(creationModeListTagButton$el);
            }
        },

        getCreationModesAvailable : function(){
            const listItemCollection = new ListItemCollection();
            if(this._audioRecordingSupported){
                listItemCollection.add(this._creationFromRecordingListItemModel);
            }
            if(IS_CAPSULE_CREATION_VIA_VOICE_ACTOR){
                listItemCollection.add(this._creationFromVoiceActorListItemModel);	
            }
            listItemCollection.add(this._creationFromFileListItemModel);
            if(IS_CAPSULE_CREATION_VIA_TEXT_AVAILABLE){
                listItemCollection.add(this._creationFromTextListItemModel);
            }
            
            return RSVP.Promise.resolve(listItemCollection.models);
        },

        getNarrationParams : function(){
            if(IS_CAPSULE_CREATION_VIA_VOICE_ACTOR){
                const narrationLanguage =  this.view.getNarrationLanguage();
                const voiceProfile =  this.view.getVoiceProfile();
                const voiceTone =  this.view.getVoiceTone();
                const textToNarrate = this.view.getTextToNarrate();
                const narrationInstructions = this.view.getNarrationInstructions();
                return {"narrationLanguage": narrationLanguage, "voiceProfile": voiceProfile,"voiceTone": voiceTone, "textToNarrate": textToNarrate, "narrationInstructions": narrationInstructions};
            }else{
                return null;
            }
        },
        
        _buildSongAttachedToCapsuleSongSelectorInputController : function(){
            const params = {};
            params.songCollectionToChooseFrom = new Songs( PersistentModels.getInstance().getSongCollection().searchOnTitleAndArtist(""));
            params.selectionSongPopupTitle = window.getI18n(ti18n,"CHOOSE_SONG");
            params.infoBoxMessageInSongSelectionPopupOnEmptySearchField = window.getI18n(ti18n,"SEARCH_SONG_LIBRARY");
            params.helperinfoBoxMessageInSongSelectionPopupOnEmptySearchField = window.getI18n(ti18n,"CHOOSE_A_SONG_TO_PLAY_AFTER_CAPSULE");
            params.songWithAudioPreviewOnly = true;
            return new SingleSongSelectorInputController(params);
        },

        _buildSongAttachedToCapsuleSongSelectorInputControllerNew : function(){
            const params = {};
            const sourceLabelToSongCollectionToChooseFrom = {};
            if(!LocalStorageHelper.getInstance().getUserInfo().temporary){
                sourceLabelToSongCollectionToChooseFrom[window.getI18n(ti18n,"MY_LIBRARY")] = new Songs( PersistentModels.getInstance().getSongCollection().searchOnTitleAndArtist(""));
            }
            const trebbleSearchSongCollection = new YTSCRemoteSongCollection(); 
            trebbleSearchSongCollection.setSource(YTSCRemoteSongCollection.sourceTypes.TREBBLE);
            sourceLabelToSongCollectionToChooseFrom[window.getI18n(ti18n,"TREBBLE")] = trebbleSearchSongCollection;
            const soundcloudSearchSongCollection = new YTSCRemoteSongCollection(); 
            soundcloudSearchSongCollection.setSource(YTSCRemoteSongCollection.sourceTypes.SOUNDCLOUD);
            sourceLabelToSongCollectionToChooseFrom[window.getI18n(ti18n,"SOUNDCLOUD")] = soundcloudSearchSongCollection;
            const youtubeSearchSongCollection = new YTSCRemoteSongCollection(); 
            youtubeSearchSongCollection.setSource(YTSCRemoteSongCollection.sourceTypes.YOUTUBE);
            sourceLabelToSongCollectionToChooseFrom[window.getI18n(ti18n,"YOUTUBE")] = youtubeSearchSongCollection;
            //params.songCollectionToChooseFrom = new Songs( PersistentModels.getInstance().getSongCollection().searchOnTitleAndArtist(""));
            params.labelToSongCollectionToChooseFrom  = sourceLabelToSongCollectionToChooseFrom;
            params.selectionSongPopupTitle = window.getI18n(ti18n,"CHOOSE_SONG");
            const sourceLabelToInfoBoxMessageInSongSelectionPopupOnEmptySearchField = {};
            sourceLabelToInfoBoxMessageInSongSelectionPopupOnEmptySearchField[window.getI18n(ti18n,"MY_LIBRARY")] = window.getI18n(ti18n,"SEARCH_SONG_LIBRARY");
            sourceLabelToInfoBoxMessageInSongSelectionPopupOnEmptySearchField[window.getI18n(ti18n,"TREBBLE")] = window.getI18n(ti18n,"SEARCH_TREBBLE");
            sourceLabelToInfoBoxMessageInSongSelectionPopupOnEmptySearchField[window.getI18n(ti18n,"SOUNDCLOUD")] = window.getI18n(ti18n,"SEARCH_SOUNDCLOUD");
            sourceLabelToInfoBoxMessageInSongSelectionPopupOnEmptySearchField[window.getI18n(ti18n,"YOUTUBE")] = window.getI18n(ti18n,"SEARCH_YOUTUBE");
            params.sourceLabelToInfoBoxMessageInSongSelectionPopupOnEmptySearchField = sourceLabelToInfoBoxMessageInSongSelectionPopupOnEmptySearchField;
            const sourceLabelToInfoBoxHelplerMessageInSongSelectionPopupOnEmptySearchField = {};
            const defaultHelperMessage  = window.getI18n(ti18n,"CHOOSE_SONG_TO_PLAY_AFTER_CAPSULE");
            sourceLabelToInfoBoxHelplerMessageInSongSelectionPopupOnEmptySearchField[window.getI18n(ti18n,"MY_LIBRARY")] = defaultHelperMessage;
            sourceLabelToInfoBoxHelplerMessageInSongSelectionPopupOnEmptySearchField[window.getI18n(ti18n,"TREBBLE")]  =  defaultHelperMessage;
            sourceLabelToInfoBoxHelplerMessageInSongSelectionPopupOnEmptySearchField[window.getI18n(ti18n,"SOUNDCLOUD")] =  defaultHelperMessage;
            sourceLabelToInfoBoxHelplerMessageInSongSelectionPopupOnEmptySearchField[window.getI18n(ti18n,"YOUTUBE")]  =  defaultHelperMessage;
            params.sourceLabelToInfoBoxHelplerMessageInSongSelectionPopupOnEmptySearchField = sourceLabelToInfoBoxHelplerMessageInSongSelectionPopupOnEmptySearchField;
            params.songWithAudioPreviewOnly = true;
            return new SingleSongSelectorInputController(params);
        },

        _buildBackgroundSongSelectorInputController : function(){
            const params = {};
            params.songCollectionToChooseFrom = new YTSCRemoteSongCollection();
            params.songCollectionToChooseFrom.setSource(YTSCRemoteSongCollection.sourceTypes.YOUTUBE);
            params.selectionSongPopupTitle = window.getI18n(ti18n,"CHOOSE_BACKGROUND_MUSIC");
            params.customInfoBoxInfoParams =  this._buildSongSelectorInfoBoxParams();
            params.infoBoxMessageOnFieldEmpty =  this._buildSongSelectorInfoBoxParamsForEmptySearchField();
            params.helperMessageInSongSelectionPopup = window.getI18n(ti18n,"CHOOSE_YOUTUBE_VIDEO_TO_USER_AS_BACKGROUND_MUSIC");
            params.songWithAudioPreviewOnly = true;
            return new SingleSongSelectorInputController(params);
        },

        _buildPeakMeterWidgetController : function(){
            const params = {};
            return new PeakMeterWidgetController(params);
        },

        getModel : function() {
            return null;
        },

        getView : function() {
            return this.view;
        },

        getSongModelToPlayAfterCapsule : function(){
            return this.songAttachedToCapsuleSongSelectorInputController? this.songAttachedToCapsuleSongSelectorInputController.getSelectedSongModel(): null;
        },

        checkRecordingPermissionAndRecord : function(){
            let userConfirmedRecording = false;
            return AudioRecorderHelper.getInstance().getPermissionToRecord().then((function(){
                if(ALLOW_USER_TO_RECORD_MULTIPLE_AUDIO_SEGMENT){
                    return true;
                }else{
                    return this.view.confirmUserWantToDeleteExistingRecordingIfApplicable();
                }
            }).bind(this)).then((function(contiuneWithRecording){
                userConfirmedRecording = contiuneWithRecording;
                if(userConfirmedRecording && (!ONLY_SHOW_COUNTER_ANIMATION_FOR_FIRST_SEGMENT_RECORDING || (!this._filenameArray || this._filenameArray.length == 0))){
                    return this.view.showCounterAnimation(3);
                }
            }).bind(this)).then((function(){
                if(userConfirmedRecording){
                    return this.view.onUserStartRecording();
                }
            }).bind(this)).catch((function(error){
                console.error(error);
                window.alertErrorMessage(window.getI18n(ti18n, "RECORDING_IS_NOT_ALLOWED" ), AudioRecorderHelper.getInstance().getRecordingAlternativeMessageInstruction());
            }).bind(this))
        },

        setMagicSoundEnhancer : function(on, doNoRecordInHistory){
            if(this._sequencerController){
                this._sequencerController.setMagicSoundEnhancer(on, doNoRecordInHistory);
            }
        },

        startPeakMeter : function(trebbleRecorderInstance){
            if(trebbleRecorderInstance && trebbleRecorderInstance.getStream && trebbleRecorderInstance.getStream() && !window.waitForCordovaToLoad ){
                this.peakMeterWidgetController.startMeter(trebbleRecorderInstance.getStream(), AudioRecorderHelper.getInstance().getAudioContext());
            }
        },

        startRecording : function(recordingCounter){
            //THIS FUNCTION WAS REWRITTEN WITH A LOT OF IF/ELSE CLAUSE TO AVOID USING PROMISE WHEN UNCESSARY AS THIS SEEM TO CAUSE ISSUES ON IOS11
            let permissionToRecordWasDenided = false;
            return AudioRecorderHelper.getInstance().getPermissionToRecord().catch((function(error){
                console.error(error);
                permissionToRecordWasDenided =  true;
            }).bind(this)).then((function(){
                if(!permissionToRecordWasDenided){

                    const fileExtension =/* device.platform == "iOS"? "wav":"wav"*/(window.trebble.useNativeRecording?(window.window.isIOSMobileDevice?"wav":"aac"):Utils.getInstance().getAudioRecordedFileExtention());
                    const fileName = this.view.getFileRecordingKey()+"_"+recordingCounter + "."+ fileExtension;
                    this._fileNameOfCurrentRecording = fileName;
                    this._filenameArray.push(fileName);
                    this._currentRecordingCounter = recordingCounter;
                    this._recordingCounterArray.push(recordingCounter);
                    this._recordingCounterToFilename[this._currentRecordingCounter] = this._fileNameOfCurrentRecording;
                    if(window.isSafariBrowser){
                        //We will assume audio is alredy stopped so we will not try to stop as we will need to add an async process which will prevent user to record after because of iOS limitation
                        //We will also not try to get the browser recorder/record permission because the code which handles safari browser will do it all at once
                        return this._startRecordingProcess();
                    }else{
                        return this.stopPlayingRecordings().then((function(){
                            return this._startRecordingProcess();
                        }).bind(this)).catch((function(error){
                            console.error(error);
                            window.alertErrorMessage(window.getI18n(ti18n,"SOMETHING_WENT_WRONG_WHEN_GETTING_READY_TO_RECORD"));
                        }).bind(this));
                    }
                }else{
                    window.alertErrorMessage(window.getI18n(ti18n, "RECORDING_IS_NOT_ALLOWED" ), AudioRecorderHelper.getInstance().getRecordingAlternativeMessageInstruction());
                }	

            }).bind(this))
        },

        _startRecordingProcess : function(){
            this.setAudioRecordingSupported(true);
            if( window.isSafariBrowser){
                AudioRecorderHelper.getInstance().getBrowserRecorderAndStartRecordingForiOSWeb(this._fileNameOfCurrentRecording).then((function(trebbleRecorderInstance){
                    this.view.startRecordTimer();
                    this.startPeakMeter(trebbleRecorderInstance)
                }).bind(this)).catch((function(error){
                    this.setAudioRecordingSupported(false);

                    console.error(error);
                    window.alertErrorMessage(window.getI18n(ti18n,"SOMETHING_WENT_WRONG_WHEN_GETTING_READY_TO_RECORD"));
                }).bind(this))
                if(window.trebbleAnalyticsHelper && !this._analyticsRecordedForCapsuleAudioRecording){
                    window.trebbleAnalyticsHelper.trackEvent("Capsule", "CapsuleAudioRecorded", "Capsule Audio Recorded");
                    this._analyticsRecordedForCapsuleAudioRecording = true;
                }
                FacebookEventTrackingHelper.getInstance().trackCapsuleRecorded();
            }else{
                AudioRecorderHelper.getInstance().startRecording(this._fileNameOfCurrentRecording).then((function(trebbleRecorderInstance){
                    this.view.startRecordTimer();
                    this.startPeakMeter(trebbleRecorderInstance);
                }).bind(this)).catch((function(error){
                    this.setAudioRecordingSupported(false);
                    console.error(error);
                    window.alertErrorMessage(window.getI18n(ti18n,"SOMETHING_WENT_WRONG_WHEN_GETTING_READY_TO_RECORD"));
                }).bind(this))
                if(window.trebbleAnalyticsHelper && !this._analyticsRecordedForCapsuleAudioRecording){
                    window.trebbleAnalyticsHelper.trackEvent("Capsule", "CapsuleAudioRecorded", "Capsule Audio Recorded");
                    this._analyticsRecordedForCapsuleAudioRecording = true;
                }
                FacebookEventTrackingHelper.getInstance().trackCapsuleRecorded();
            }
        },

        setAudioRecordingSupported : function(audioRecordingSupported){
            this._audioRecordingSupported = audioRecordingSupported;
            this.view.setAudioRecordingSupported(this._audioRecordingSupported);
        },


        stopRecording : function(recordingCounter){
            if(AudioRecorderHelper.getInstance().isRecording()){
                return AudioRecorderHelper.getInstance().stopRecording(this._fileNameOfCurrentRecording).catch((function(error){
                    window.alertErrorMessage(error);
                }).bind(this));
            }else{
                return RSVP.Promise.resolve();
            }

        },

        deleteRecording : function(recordingCounter){
            this.resetTranscriptionAndAudioEdits();
            const recordingToDeleteIndex  = this._recordingCounterArray.indexOf(recordingCounter);
            this._recordingCounterArray.splice(recordingToDeleteIndex, 1);
            const recordingFilenameToDelete = this._recordingCounterToFilename[recordingCounter];
            delete this._recordingCounterToFilename[recordingCounter];
            const filenameIndex =  this._filenameArray.indexOf(recordingFilenameToDelete);
            this._filenameArray.splice(filenameIndex, 1);
            if(this._uploadingPromise){
                return this._uploadingPromise.then((function(result){
                    return AudioRecorderHelper.getInstance().deleteRecording(recordingFilenameToDelete);
    }).bind(this))/*.catch((function(error){
        return AudioRecorderHelper.getInstance().deleteRecording(recordingFilenameToDelete);
    }).bind(this))*/;
                }else{
                    return AudioRecorderHelper.getInstance().deleteRecording(recordingFilenameToDelete);
                }


            },

            deleteAllRecordings : function(){
                AudioRecorderHelper.getInstance().deleteAllBackgroundMusicDownloadedLocally();
                const stopRecordingIfApplicablePromise = AudioRecorderHelper.getInstance().isRecording()? AudioRecorderHelper.getInstance().stopRecording(this._fileNameOfCurrentRecording): RSVP.Promise.resolve();
                return stopRecordingIfApplicablePromise.then((function(){
                    return this.stopPlayingRecordings()
                }).bind(this)).catch((function(error){
                    console.error(error);
                }).bind(this)).then((function(){
                    const promises = [];
                    const recordingCounterArrayCopy = this._recordingCounterArray.slice();
                    for(let index in recordingCounterArrayCopy){
                        const recordingCounter =  recordingCounterArrayCopy[index];
                        promises.push(this.deleteRecording(recordingCounter));
                    } 
                    return RSVP.Promise.all(promises);
                }).bind(this));

            },

            playRecordings : function(){
                const backgroundTrackLoadingHandler = (function(isLoading){
                    this.view.setIsPlaybackLoading(isLoading);
                }).bind(this);
                const backgroudTrackUrl = this._backgroundTrackUrl;
                this._playRecordingRequestId = Utils.getInstance().generateRandomId();
                const currentPlayRecordingRequestId = this._playRecordingRequestId;
                const p =  AudioRecorderHelper.getInstance().playRecordings(this._filenameArray, this._backgroundTrackUrl, backgroundTrackLoadingHandler, currentPlayRecordingRequestId).then((function(){
                    if(currentPlayRecordingRequestId == this._playRecordingRequestId){
        //the background audio was not interupted by another playback
                        this.view.setIsPlaying(false);
                    }
                }).bind(this)).catch((function(error){
                    if(currentPlayRecordingRequestId == this._playRecordingRequestId){
        //the background audio was not interupted by another playback
                        this.view.setIsPlaying(false);
                    }
                }).bind(this));
                this.view.setIsPlaying(true);
                return p;
            },

            stopPlayingRecordings: function(){
                return AudioRecorderHelper.getInstance().stopPlayRecordingsIfApplicable().then((function(){
                    this.view.setIsPlaying(false);
                }).bind(this));
            },

            uploadRecordings : function(){
                const songUri = this.getSongModelToPlayAfterCapsule()? this.getSongModelToPlayAfterCapsule().get("uri"): null;
                let capsuleRefIdOrSongRefUri = this.capsuleId;
                let isCapsuleReply = true; 
                if(!capsuleRefIdOrSongRefUri){
                    capsuleRefIdOrSongRefUri = songUri;
                    isCapsuleReply = false;
                }
                if(this.view.isAudioWasTranscribedAndEdited()){
                    if(this.capsuleInfoProgressBarController){
                        this.capsuleInfoProgressBarController.setProgressMessage(window.getI18n(ti18n,"RENDERING_AUDIO"));
                        this.capsuleInfoProgressBarController.setProgressSubMessage(window.getI18n(ti18n, "THIS_MIGHT_TAKE_A_FEW_MINUTES")+ " "+ window.getI18n(ti18n, "DO_NOT_REFRESH")+ " "+window.getI18n(ti18n, "PLEASE_HOLD_ON_TIGHT"));
                    }
                    return this._uploadRenderedEditedAudioFromBrowser(null, this.capsuleInfoProgressBarController, false, false, true, window.getI18n(ti18n,"UPLOADING_YOUR_AUDIO"));
                }else{
                    if (window.device && window.device.platform == "browser"  && !(window.trebble.useNativeRecording && "Media" in window && this.isInRecordMode())) {
                        if(this.isInRecordMode()){
                            return this._uploadRecordedAudioFromBrowser(null, this.capsuleInfoProgressBarController);
                        }else{
                            let apiMethodToCall =  "getSignatureForCapsuleUpload";
                            if(this.capsuleId){
                                apiMethodToCall =  "getSignatureForCapsuleReplyUpload";
                            }
                            if(!capsuleRefIdOrSongRefUri){
                                apiMethodToCall = "getSignatureForCapsuleWithNoSongUpload";
                            }

                            return this._uploadProvidedAudioFileFromBrowser(apiMethodToCall, this.capsuleInfoProgressBarController);
                        }
                    }else{

                        return this._uploadFileProvidedFromNative();
                    }
                }
            },

            _uploadRenderedEditedAudioFromBrowser: function(functionNameToRetrieveUploadSignature, progressBarController, renderWavFile, asVideo, updateProgressBarDuringRendering, defaultProgresMessageOnUpload){
                const songUri = this.getSongModelToPlayAfterCapsule()? this.getSongModelToPlayAfterCapsule().get("uri"): null;
                let capsuleRefIdOrSongRefUri = this.capsuleId;
                let isCapsuleReply = true; 
                if(!capsuleRefIdOrSongRefUri){
                    capsuleRefIdOrSongRefUri = songUri;
                    isCapsuleReply = false;
                }
                const blobToUploadArray = [];
                let onRenderingProgressReported = null;
                if(updateProgressBarDuringRendering && progressBarController){
                    onRenderingProgressReported = function(progress, operationInProgress){
                        if(progress){
                            progressBarController.setIndeterminate(false);
                            progressBarController.setProgressMessage(operationInProgress?operationInProgress:defaultProgresMessageOnUpload);
                            progressBarController.setProgressSubMessage(window.getI18n(ti18n, "THIS_MIGHT_TAKE_A_FEW_MINUTES")+ " "+ window.getI18n(ti18n, "DO_NOT_REFRESH")+ " "+window.getI18n(ti18n, "PLEASE_HOLD_ON_TIGHT"));
                            progressBarController.setPercentageProgress(Math.round(progress)+ "%");
                        }else{
                            progressBarController.setIndeterminate(true);
                            progressBarController.setProgressMessage(operationInProgress?operationInProgress:defaultProgresMessageOnUpload);
                            progressBarController.setProgressSubMessage(window.getI18n(ti18n, "THIS_MIGHT_TAKE_A_FEW_MINUTES")+ " "+ window.getI18n(ti18n, "DO_NOT_REFRESH")+ " "+window.getI18n(ti18n, "PLEASE_HOLD_ON_TIGHT"));
                        }
                    }
                }
                if(LIMIT_MAX_FILE_RENDERED && this._sequencerController.getDurationInMilliseconds() > 30 * 60 * 100){
                    return RSVP.Promise.reject(asVideo?window.getI18n(ti18n,"UNFORTUNATELY_TREBBLE_CANNOT_RENDER_30_MIN_VIDEO"): window.getI18n(ti18n,"UNFORTUNATELY_TREBBLE_CANNOT_RENDER_30_MIN_AUDIO"));
                }
                this._uploadingPromise  = this._sequencerController.getRenderedAudioBlob(renderWavFile, asVideo, onRenderingProgressReported, this._lastRenderWavFileBlob).then((function(blob){
                    if(renderWavFile && !asVideo){
                        this._lastRenderWavFileBlob =  blob;
                    }
                    if(defaultProgresMessageOnUpload && progressBarController){
                        progressBarController.setIndeterminate(false);
                        progressBarController.setProgressMessage(defaultProgresMessageOnUpload);
                        progressBarController.setProgressSubMessage(window.getI18n(ti18n, "THIS_MIGHT_TAKE_A_FEW_MINUTES")+ " "+ window.getI18n(ti18n, "DO_NOT_REFRESH")+ " "+window.getI18n(ti18n, "PLEASE_HOLD_ON_TIGHT"));
                        progressBarController.setPercentageProgress("0%");
                        progressBarController.setIndeterminate(true);
                    }
                    return FileUploadHelper.getInstance().uploadAudioHigightsInWebBrowser([blob], capsuleRefIdOrSongRefUri, this.view.$el, isCapsuleReply, blob.size, progressBarController, functionNameToRetrieveUploadSignature, true, true);
                }).bind(this)).then((function(result){
                    this._uploadingPromise = null;
                    return result;
                }).bind(this)).catch(function(error){
                    try{
                        if(window.trebbleAnalyticsHelper){
                            window.trebbleAnalyticsHelper.trackEvent("Capsule", "errorOccuredDuringCapsuleUploadingToServer", "Error During Capsule Uploading To Server", error);
                        }
                    }catch(error){
                        console.error("There was an error logging than error occured during the capsule uploading to server. Error:"+error);
                    }
                    if(error){
                        throw window.getI18n(ti18n,"UPLOADING_FAILED_ERROR") + error
                    }else{
                        throw window.getI18n(ti18n,"UPLOADING_FAILED");
                    }
                }); 

                return this._uploadingPromise;
            },

            saveSequencerProjectIfApplicable : function(progressBarController){
                if(this.isInRecordMode() || this.isInUploadMode()){
                    progressBarController.setIndeterminate(true);
                    progressBarController.setProgressMessage(window.getI18n(ti18n,"SAVING_PROJECT"));
                    progressBarController.setProgressSubMessage(window.getI18n(ti18n, "THIS_MIGHT_TAKE_A_FEW_MINUTES")+ " "+ window.getI18n(ti18n, "DO_NOT_REFRESH")+ " "+window.getI18n(ti18n, "PLEASE_HOLD_ON_TIGHT"));
                    if(this.view.isAudioWasTranscribedAndEdited()){
                        return this._sequencerController.saveProject();
                    }
                }
                return RSVP.Promise.resolve();
            },


            uploadHQRenderedEditedAudioIfApplicable : function(progressBarController){
                if(this.isInRecordMode() || this.isInUploadMode()){
                    const defaultProgresMessageOnUpload = this._createAShortcast? window.getI18n(ti18n,"UPLOADING_YOUR_SHORTCAST_UNCOMPRESSED"): window.getI18n(ti18n,"UPLOADING_YOUR_CAPSULE_UNCOMPRESSED");
                    if(this.view.isAudioWasTranscribedAndEdited()){
                        if(progressBarController){
                            progressBarController.setIndeterminate(false);
                            progressBarController.setProgressMessage(window.getI18n(ti18n,"RENDERING_AUDIO_UNCOMPRESSED"));
                            progressBarController.setProgressSubMessage(window.getI18n(ti18n, "THIS_MIGHT_TAKE_A_FEW_MINUTES")+ " "+ window.getI18n(ti18n, "DO_NOT_REFRESH")+ " "+window.getI18n(ti18n, "PLEASE_HOLD_ON_TIGHT"));
                            progressBarController.setPercentageProgress("0%");
                            progressBarController.setIndeterminate(true);
                        }
                        return this._uploadRenderedEditedAudioFromBrowser(null, this.capsuleInfoProgressBarController, true, false, true, defaultProgresMessageOnUpload).then(function(audioPartsInfo){
                            if(audioPartsInfo && audioPartsInfo.length > 0){
                                return audioPartsInfo[0];
                            }

                        });
                    }
                }
                return RSVP.Promise.resolve();
            },
            

            uploadVideoRenderedEditedIfApplicable : function(progressBarController){
                if(this.isInRecordMode() || this.isInUploadMode()){
                    if(this.view.isAudioWasTranscribedAndEdited() && this.isEditedContentContainsVideo()){
                        if(progressBarController){
                            progressBarController.setProgressMessage(window.getI18n(ti18n,"RENDERING_VIDEO_FILE"));
                            progressBarController.setProgressSubMessage(window.getI18n(ti18n, "THIS_MIGHT_TAKE_A_FEW_MINUTES")+ " "+ window.getI18n(ti18n, "DO_NOT_REFRESH")+ " "+window.getI18n(ti18n, "PLEASE_HOLD_ON_TIGHT"));
                        }
                        return this._uploadRenderedEditedAudioFromBrowser("getSignatureForCapsuleVideoUpload", this.capsuleInfoProgressBarController, true, true, true, window.getI18n(ti18n,"UPLOADING_YOUR_VIDEO")).then(function(audioPartsInfo){
                            if(audioPartsInfo && audioPartsInfo.length > 0){
                                return audioPartsInfo[0];
                            }

                        });
                    }
                }
                return RSVP.Promise.resolve();
            },


            _uploadRecordedAudioFromBrowser : function(functionNameToRetrieveUploadSignature, progressBarController){
                const songUri = this.getSongModelToPlayAfterCapsule()? this.getSongModelToPlayAfterCapsule().get("uri"): null;
                let capsuleRefIdOrSongRefUri = this.capsuleId;
                let isCapsuleReply = true; 
                if(!capsuleRefIdOrSongRefUri){
                    capsuleRefIdOrSongRefUri = songUri;
                    isCapsuleReply = false;
                }
                const blobToUploadArray = [];
                const recordFilenameToBlog =  AudioRecorderHelper.getInstance().getRecordFilenameToBlob();
                let totalUploadSizeInBytes = 0;
                for(let index in this._filenameArray){
                    const fileName = this._filenameArray[index];
                    const blob = recordFilenameToBlog[fileName];
                    if(blob && blob.size  !== 0 && blob.size > 0){
                        blobToUploadArray.push(blob);
                        totalUploadSizeInBytes = totalUploadSizeInBytes + blob.size; 
                        blob._filename = fileName;
                    }else{
                        try{
                            if(window.trebbleAnalyticsHelper){
                                window.trebbleAnalyticsHelper.trackEvent("Capsule", "errorOccuredDuringCapsuleUploadingProcess", "Error During Capsule Uploading Process", blob && blob.size);
                            }
                        }catch(error){
                            console.error("There was an error logging than error occured during the capsule uploading process. Error:"+error);
                        }
                        return RSVP.Promise.reject(window.getI18n(ti18n,"OOPS_ERROR_WHILE_RENDERING_YOUR_RECORDING"));
                    }
                }
                this._uploadingPromise  = FileUploadHelper.getInstance().uploadAudioHigightsInWebBrowser(blobToUploadArray, capsuleRefIdOrSongRefUri, this.view.$el, isCapsuleReply, totalUploadSizeInBytes, progressBarController, functionNameToRetrieveUploadSignature, true, true).then((function(result){
                    this._uploadingPromise = null;
                    return result;
                }).bind(this)).catch(function(error){
                    try{
                        if(window.trebbleAnalyticsHelper){
                            window.trebbleAnalyticsHelper.trackEvent("Capsule", "errorOccuredDuringCapsuleUploadingToServer", "Error During Capsule Uploading To Server", error);
                        }
                    }catch(error){
                        console.error("There was an error logging than error occured during the capsule uploading to server. Error:"+error);
                    }

                    if(error){
                        throw window.getI18n(ti18n,"UPLOADING_FAILED_ERROR") + error
                    }else{
                        throw window.getI18n(ti18n,"UPLOADING_FAILED");
                    }  
                }); 

                return this._uploadingPromise;
            },

            _uploadProvidedAudioFileFromBrowser : function(functionNameToRetrieveUploadSignature, progressBarController){
                const songUri = this.getSongModelToPlayAfterCapsule()? this.getSongModelToPlayAfterCapsule().get("uri"): null;
                let capsuleRefIdOrSongRefUri = this.capsuleId;
                let isCapsuleReply = true; 
                if(!capsuleRefIdOrSongRefUri){
                    capsuleRefIdOrSongRefUri = songUri;
                    isCapsuleReply = false;
                }
                const fname = this._capsuleUploadDropzoneObj.getAcceptedFiles()[0].name;
                this._capsuleUploadDropzoneObj.getAcceptedFiles()[0].status = "queued";
                const extension = fname.substr(fname.lastIndexOf("."));
                const nameOfFileToUpload = extension? "userCreatedCapsule_"+(new Date()).getTime()+extension : "userCreatedCapsule_"+(new Date()).getTime();

                const onBytesSentHanlder = (function(file, progressInPercentage, byteSent) {
                    if(progressBarController){
                        progressBarController.setPercentageProgress(Math.round(progressInPercentage) + "%");   
                    }
                }).bind(this);
                this._capsuleUploadDropzoneObj.on("uploadprogress",onBytesSentHanlder );
                this._signatureValidationStarted  = true;
                FileUploadHelper.getInstance().validateAndSignFunctionHanlder(this._capsuleUploadDropzoneObj, nameOfFileToUpload, FileUploadHelper.getInstance().getAudioFileTypeValidationFunction(), functionNameToRetrieveUploadSignature ,capsuleRefIdOrSongRefUri, true).then((function(){
                    this._processQueueStarted = true;
                    this._signatureValidationStarted  = false;
                    this._capsuleUploadDropzoneObj.processQueue();
                }).bind(this)).catch(this._dropzonePromiseReject);

                this._userUploadedCapsulePromise = new RSVP.Promise((function(userUploadedCapsulePromiseResolve, userUploadedCapsulePromiseReject){
                    this._userUploadedCapsulePromiseResolve =  userUploadedCapsulePromiseResolve;
                    this._userUploadedCapsulePromiseReject =  userUploadedCapsulePromiseReject;
                }).bind(this));

                return this._userUploadedCapsulePromise;
            },


            _uploadFileProvidedFromNative : function(functionNameToRetrieveUploadSignature){
                const songUri = this.getSongModelToPlayAfterCapsule()? this.getSongModelToPlayAfterCapsule().get("uri"): null;
                let capsuleRefIdOrSongRefUri = this.capsuleId;
                let isCapsuleReply = true; 
                if(!capsuleRefIdOrSongRefUri){
                    capsuleRefIdOrSongRefUri = songUri;
                    isCapsuleReply = false;
                }
                this._uploadingPromise = FileUploadHelper.getInstance().getPersistentFileSystemUrl().then((function(persitentFSUrl){
                    const filenameInfoToUploadArray = [];

    const filePrefix = /*device.platform == "iOS"? "cdvfile://localhost/persistent/":*/ persitentFSUrl;
                    for(let index in this._filenameArray){
                        const fileName = this._filenameArray[index];
                        const fileNameInfoToUpload = {};
                        fileNameInfoToUpload.name = fileName;

                        fileNameInfoToUpload.url = filePrefix + fileName;
                        filenameInfoToUploadArray.push(fileNameInfoToUpload);	
                    }
                    return FileUploadHelper.getInstance().uploadAudioHigightsForSong(filenameInfoToUploadArray, capsuleRefIdOrSongRefUri, isCapsuleReply, functionNameToRetrieveUploadSignature);
                }).bind(this)).then((function(result){
                    this._uploadingPromise = null;
                    return result;
                }).bind(this)).catch(function(error){
                    if(error){
                        throw window.getI18n(ti18n,"UPLOADING_FAILED_ERROR") + error
                    }else{
                        throw window.getI18n(ti18n,"UPLOADING_FAILED");
                    }
                }); 
                return this._uploadingPromise;
            },


            uploadRecordingsInUserAudioLibrary : function(functionNameToRetrieveUploadSignature){
                const songUri = this.getSongModelToPlayAfterCapsule()? this.getSongModelToPlayAfterCapsule().get("uri"): null;
                let capsuleRefIdOrSongRefUri = this.capsuleId;
                let isCapsuleReply = true; 
                if(!capsuleRefIdOrSongRefUri){
                    capsuleRefIdOrSongRefUri = songUri;
                    isCapsuleReply = false;
                }
                if (window.device && window.device.platform == "browser"  && !(window.trebble.useNativeRecording && "Media" in window && this.isInRecordMode())) {
                    if(this.isInRecordMode()){

                        return this._uploadRecordedAudioFromBrowser(functionNameToRetrieveUploadSignature, this.audioEditorProgressBarController);
                    }else{
                        let apiMethodToCall =  "getSignatureForCapsuleUpload";
                        if(this.capsuleId){
                            apiMethodToCall =  "getSignatureForCapsuleReplyUpload";
                        }
                        if(!capsuleRefIdOrSongRefUri){
                            apiMethodToCall = "getSignatureForCapsuleWithNoSongUpload";
                        }

                        return this._uploadProvidedAudioFileFromBrowser(apiMethodToCall, this.audioEditorProgressBarController);
                    }
                }else{

                    return this._uploadFileProvidedFromNative(functionNameToRetrieveUploadSignature);
                }
            },

            saveCapsuleConfirm : function(){
                if(this.getSongModelToPlayAfterCapsule() && !this.getSongModelToPlayAfterCapsule().get("addedToRadio")){
                    if(!this._receiverUserId){
                        const addSongToTrebbleAndSaveCapsule = (function(buttonIndex){
                            if(buttonIndex == 2){
                                $.mobile.loading("show");
                                return PersistentModels.getInstance().addSongToDefaultRadio(this.getSongModelToPlayAfterCapsule(), Utils.getInstance().buildContextForCapsuleCreation(), null, true).then((function(){
                                    $.mobile.loading("hide");
                                    return this.saveCapsule();
                                }).bind(this)).catch(function(error){
                                    $.mobile.loading("hide");
                                    window.alertErrorMessage(window.getI18n(ti18n,"ADDING_MUSIC_TO_YOUR_TREBBLE_FAILED") + error);
                                });
                            }
                        }).bind(this);
                        navigator.trebbleNotification.confirm(window.getI18n(ti18n,"THE_MUSIC_NEEDS_TO_ADDED_TO_YOUR_LIBRARY"), addSongToTrebbleAndSaveCapsule, window.getI18n(ti18n,"ADD_SONG_TO_YOUR_TREBBLE"), [ window.getI18n(ti18n,"NO"), window.getI18n(ti18n,"YES")]);
                    }else{
        //Do not add to the trebble if the song is gonna be sent to another user
                        $.mobile.loading("show");
                        return PersistentModels.getInstance().addSongToDefaultRadio(this.getSongModelToPlayAfterCapsule(), Utils.getInstance().buildContextForCapsuleCreation(), null, true).then((function(){
                            $.mobile.loading("hide");
                            return this.saveCapsule();
                        }).bind(this)).catch(function(error){
                            $.mobile.loading("hide");
                            window.alertErrorMessage( window.getI18n(ti18n,"ADDING_MUSIC_TO_YOUR_TREBBLE_FAILED")+ error);
                        });
                    }
                }else{
                    return this.saveCapsule();
                }
            },

            uploadRecordingToAudioLibraryForEditingIfApplicable : function(progressBarController){
                if(this.isInRecordMode() || this.isInUploadMode()){
                    progressBarController.setIndeterminate(false);
                    progressBarController.setProgressMessage(this._createAShortcast? window.getI18n(ti18n,"UPLOADING_YOUR_AUDIO"): window.getI18n(ti18n,"UPLOADING_YOUR_AUDIO"));
                    progressBarController.setProgressSubMessage(window.getI18n(ti18n, "THIS_MIGHT_TAKE_A_FEW_MINUTES")+ " "+ window.getI18n(ti18n, "DO_NOT_REFRESH")+ " "+window.getI18n(ti18n, "PLEASE_HOLD_ON_TIGHT"));
                    progressBarController.setPercentageProgress("0%");
                    if(window.trebbleAnalyticsHelper){
                        window.trebbleAnalyticsHelper.trackEvent("audioEditing", "Upload audio for editing", "Upload audio for editing");
                    }
                    return this.uploadRecordingsInUserAudioLibrary("getSignatureForTrebbleUserAudioRecordingUpload");
                }else{

                    return RSVP.Promise.resolve();
                }
            },


            uploadRecordingIfApplicable : function(progressBarController){
                if(this.isInRecordMode() || this.isInUploadMode()){
                    progressBarController.setIndeterminate(false);
                    progressBarController.setProgressMessage(this._createAShortcast? window.getI18n(ti18n,"UPLOADING_YOUR_SHORTCAST"): window.getI18n(ti18n,"UPLOADING_YOUR_CAPSULE"));
                    progressBarController.setProgressSubMessage(window.getI18n(ti18n, "THIS_MIGHT_TAKE_A_FEW_MINUTES")+ " "+ window.getI18n(ti18n, "DO_NOT_REFRESH")+ " "+window.getI18n(ti18n, "PLEASE_HOLD_ON_TIGHT"));
                    progressBarController.setPercentageProgress("0%");
                    progressBarController.setIndeterminate(true);
                    return this.uploadRecordings();
                }else{

                    return RSVP.Promise.resolve();
                }
            },

            getProjectId:function(){
                return this._sequencerController? this._sequencerController.getProjectId():null;
            },

            updateCapsule : async function(){
                try{
                    this.view.showHideUploadingViewInCapsuleInfo(true);
                    let wasTranscribedAndEditedInTrebble = false;
                    let hqAudioInfo = null;
                    let videoInfo = null;
                    let capsuleUpdateResults = null;
                    await  this.saveSequencerProjectIfApplicable(this.capsuleInfoProgressBarController);
                    if(SAVE_AUDIO_WAV_FILE_FROM_AUDIO_EDITOR){
                        hqAudioInfo  = await this.uploadHQRenderedEditedAudioIfApplicable(this.capsuleInfoProgressBarController);
                    }
                    if(window.chrome && !RENDER_VIDEO_ON_BACKEND){
                        //Only allow video rendering on chrome
                        videoInfo = await this.uploadVideoRenderedEditedIfApplicable(this.capsuleInfoProgressBarController);
                        window.alertSuccessMessage(window.getI18n("YOUR_VIDEO_IS_BEING_PROCESSED"),window.getI18n("WE_WILL_NOTIFY_WHEN_YOUR_VIDEO_IS_READY_TO_DOWNLOAD"));
                    }
                    const audioPartsInfo  = await this.uploadRecordingIfApplicable(this.capsuleInfoProgressBarController);
                    const audioInfo = audioPartsInfo && audioPartsInfo.length > 0? audioPartsInfo[0]: audioPartsInfo;
                    this.capsuleInfoProgressBarController.setIndeterminate(true);
                    this.capsuleInfoProgressBarController.setProgressMessage(this._createAShortcast?window.getI18n(ti18n,"SAVING_YOUR_SHORTCAST_INFO"): window.getI18n(ti18n,"SAVING_YOUR_CAPSULE_INFO"));
                    this.capsuleInfoProgressBarController.setProgressSubMessage("");
                    let title = this.view.getTitle();                  
                    const comment = this.view.getComment();    
                    if(TREBBLE_TEXT_BASED_AUDIO_EDITOR && this.view.isAudioWasTranscribedAndEdited()){    
                        wasTranscribedAndEditedInTrebble = true;
                    }
                    capsuleUpdateResults =  await PersistentModels.getInstance().updateSongCapsule(this._capsuleIdToEdit, audioPartsInfo, title,comment, null, this.context, this._receiverUserId,wasTranscribedAndEditedInTrebble, hqAudioInfo, this.getProjectId(), videoInfo, audioInfo );
                        
                    if(RENDER_VIDEO_ON_BACKEND  && this.isEditedContentContainsVideo() && capsuleUpdateResults && capsuleUpdateResults.playableAudioUrl){
                        await this._sequencerController.startVideoRenderingInCloudForLaterDownload(null, null , capsuleUpdateResults.playableAudioUrl, this._capsuleIdToEdit);
                    }
                    this._hideAllWhatsACapsuleButtons();
                    if(this._onCapsuleSuccessfullyUpdated){
                        this._onCapsuleSuccessfullyUpdated(capsuleUpdateResults);
                    }
                    if(this._returnToDefaultAfterCreation){
                        PersistentModels.getInstance().getRouter().showMyLibraryPage(null, null, true).then((function(){
                            this.view.removePage();
                        }).bind(this));
                    }else{
                        this.view.navigateToPreviousPage().then((function(){
                            this.view.removePage();
                        }).bind(this));
                    }

                    if(window.trebbleAnalyticsHelper){
                        window.trebbleAnalyticsHelper.trackEvent("Capsule", "capsuleUpdated", "Capsule Updated");
                        if(window.trebbleAnalyticsHelper.trebbleHelperTrackCapsuleUpdated){
                            window.trebbleAnalyticsHelper.trebbleHelperTrackCapsuleUpdated();
                        }
                    } 
                    FacebookEventTrackingHelper.getInstance().trackCapsuleUpdated();
                    return capsuleUpdateResults;
                }catch(error){
                    console.error(error);
                    this.view.showHideUploadingViewInCapsuleInfo(false);
                    window.alertErrorMessage((typeof error === "string")? error : window.getI18n(ti18n,"UPLOADING_FAILED"));
                    try{
                        window.sendErrorToRaygun("Failed creating capsule. Error:"+ error);
                    }catch(err){
                        console.error(err);
                    }
                }
            },

            saveCapsule : async function(){
                if(this._capsuleInfoToEdit){
                    return await this.updateCapsule();
                }
                let isPrivate = false;
                let doNotAddOnAir = false;
                this.view.showHideUploadingViewInCapsuleInfo(true);
                let doNotTriggerAudioProcessing = false;
                let isCapsuleScheduled = false;
                let scheduleStartDate = null;
                let timezone = null;
                let lifeSpan = null;
                let wasTranscribedAndEditedInTrebble = false;
                let hqAudioInfo = null;
                let videoInfo = null;
                let capsuleCreationResults = null;
                let ffmpegParamsInfoArrayToRunOnServer = null;
                try{
                    await this.saveSequencerProjectIfApplicable(this.capsuleInfoProgressBarController);
                    if(SAVE_AUDIO_WAV_FILE_FROM_AUDIO_EDITOR){
                        hqAudioInfo = await this.uploadHQRenderedEditedAudioIfApplicable(this.capsuleInfoProgressBarController);
                    }
        
                    if(window.chrome && !RENDER_VIDEO_ON_BACKEND){
                            //Only allow video rendering on chrome
                            videoInfo = await this.uploadVideoRenderedEditedIfApplicable(this.capsuleInfoProgressBarController);
                        }
        
                    const audioPartsInfo = await this.uploadRecordingIfApplicable(this.capsuleInfoProgressBarController)
                
                    this.capsuleInfoProgressBarController.setIndeterminate(true);
                    this.capsuleInfoProgressBarController.setProgressMessage(this._createAShortcast?window.getI18n(ti18n,"SAVING_YOUR_SHORTCAST_INFO"): window.getI18n(ti18n,"SAVING_YOUR_CAPSULE_INFO"));
                    this.capsuleInfoProgressBarController.setProgressSubMessage("");
                    lifeSpan = this.view.getLifeSpan();

                    if(!lifeSpan || lifeSpan == ""){
                        lifeSpan = null
                    }
                    const categoryId = this.view.getCategoryId();
                    const categoryLabel = this.view.getCategoryLabel();
                    const songJsonForBackend = Utils.getInstance().getSongJsonForBackendFromSongModel(this.getSongModelToPlayAfterCapsule());
                    let title = this.view.getTitle();
                    if(!title){
                        title = categoryLabel;
                    }
                    const comment = this.view.getComment();
                    doNotAddOnAir =  this.view.getDoNotAddOnAir();
                    isCapsuleScheduled = this.view.isScheduled();
                    scheduleStartDate =  isCapsuleScheduled ? this.view.getScheduleStartDate(): null;
                    timezone =  isCapsuleScheduled ? this.view.getTimezone():  Intl.DateTimeFormat().resolvedOptions().timeZone;
                    const language = this.getCapsuleLanguage();
                    isPrivate = this.view.isPrivate();
                    const backgroundMusicModel = this.backgroundSongSelectorInputController.getSelectedSongModel();
                    let backgroundMusicUri = backgroundMusicModel?backgroundMusicModel.get("uri"):this._backgroundTrackUrl;
                    if(!this.isInRecordMode() && !this._addBackgroundMusicToUploadedAudio){
                        backgroundMusicUri = null;
                    }

                    doNotTriggerAudioProcessing = false;
                    if(TREBBLE_TEXT_BASED_AUDIO_EDITOR && this.view.isAudioWasTranscribedAndEdited()){
                        backgroundMusicUri = null;
                        doNotTriggerAudioProcessing = true;
                        wasTranscribedAndEditedInTrebble = true;
                    }
                    const addToRadioWitIds = (!isPrivate && !this._receiverUserId)? PersistentModels.getInstance().getAllMyPersonalRadioIds(): null;
                    capsuleCreationResults = await PersistentModels.getInstance().createSongCapsule(this.getSongModelToPlayAfterCapsule(), this.capsuleId, audioPartsInfo,lifeSpan,title, comment,null,backgroundMusicUri, doNotTriggerAudioProcessing,categoryId, isPrivate, this.context, this._receiverUserId, doNotAddOnAir,isCapsuleScheduled , scheduleStartDate, timezone, language, this.isInTTSMode()?this.view.getTextToConvert(): null, null, this.isInTTSMode()?this.view.getTTSLanguage():null, this.getNarrationParams(), wasTranscribedAndEditedInTrebble, hqAudioInfo, this.getProjectId(), videoInfo);

                    if(RENDER_VIDEO_ON_BACKEND && this.isEditedContentContainsVideo() && capsuleCreationResults && capsuleCreationResults.capsuleInfo && capsuleCreationResults.capsuleInfo.playableAudioUrl){
                        await this._sequencerController.startVideoRenderingInCloudForLaterDownload(null, null , capsuleCreationResults.capsuleInfo.playableAudioUrl, capsuleCreationResults.capsuleInfo._id);
                    }
                
                    this._hideAllWhatsACapsuleButtons();
                    if(this.getSongModelToPlayAfterCapsule()){
                        const songTitle = this.getSongModelToPlayAfterCapsule().get("title");
                        Utils.getInstance().showShortToastMessage(window.getI18n(ti18n,"CAPSULE_FOR")+" '"+songTitle+"' "+ window.getI18n(ti18n,"WAS_SUCCESSFULLY_UPLOADED"));
                    }

                    
                    if(this._onCapsuleSuccessfullyCreated){
                        this._onCapsuleSuccessfullyCreated(capsuleCreationResults);
                    }
                    if(this._returnToDefaultAfterCreation){
                        PersistentModels.getInstance().getRouter().showMyLibraryPage(null, null, true).then((function(){
                            this.view.removePage();
                        }).bind(this));
                    }else{
                        this.view.navigateToPreviousPage().then((function(){
                            this.view.removePage();
                        }).bind(this));
                    }

                    let messageTitle = window.getI18n(ti18n,"CAPSULE_CREATED");

                    const detailMessage = window.getI18n(ti18n,"YOU_CAN_FIND_THE_CAPSULE_IN_LIBRARY");
                    if(isPrivate){
                        //detailMessage = window.getI18n(ti18n,"YOUR_CAPSULE_WILL_NOT_AIR_BUT_CAN_BE_SHARED");
                    }
                    if(this._receiverUserId){
                        messageTitle = window.getI18n(ti18n,"CAPSULE_SENT");
                        //detailMessage = window.getI18n(ti18n,"YOUR_CAPSULE_CAN_BE_FOUND_UNDER_ARCHIVED_TAB");
                    }
                    if(doNotAddOnAir){
                        //detailMessage = window.getI18n(ti18n,"YOU_CAN_FIND_THE_CAPSULE_UNDER_DRAFTS");
                    }
                    if(isCapsuleScheduled){
                        messageTitle = window.getI18n(ti18n,"CAPSULE_SCHEDULED");
                        //detailMessage = window.getI18n(ti18n,"YOU_CAN_FIND_THE_CAPSULE_UNDER_SCHEDULED");
                    }

                    if(REMEMBER_UPLOAD_SETTING_IN_CURRENT_SESSION){
                        if(!window._lastCapsuleUploadSettings){
                            window._lastCapsuleUploadSettings = {};
                        }
                        if(isCapsuleScheduled){
                            window._lastCapsuleUploadSettings.timezone = timezone;
                            window._lastCapsuleUploadSettings.capsuleLastScheduleDurationInCurrentSession = lifeSpan;
                            window._lastCapsuleUploadSettings.capsuleLastScheduleDateInCurrentSession = scheduleStartDate;
                        }
                    }
                    const onCapsuleCreatedPopupAcknowledged = null;
                    if(!this._createAShortcast){
                        if(!window._capsuleCreatedInThisSession && (messageTitle || detailMessage)){
                            window.alertSuccessMessage(messageTitle,detailMessage, onCapsuleCreatedPopupAcknowledged);
                        }
                    }
                    window._capsuleCreatedInThisSession = true;
                    if(!isPrivate && !window._capsuleCreatedAndProcessedInThisSession /*&& doNotTriggerAudioProcessing*/ && !this._receiverUserId){
                        window._capsuleCreatedAndProcessedInThisSession = true;
                        //onCapsuleCreatedPopupAcknowledged =  (function(){ setTimeout((function(){PersistentModels.getInstance().getRouter().showSuggestionPopupToShareTrebbleIfApplicable()}).bind(this), 300);}).bind(this);
                    }
                    

                if(window.trebbleAnalyticsHelper){
                    window.trebbleAnalyticsHelper.trackEvent("Capsule", "capsuleCreated", "Capsule Created");
                    if(window.trebbleAnalyticsHelper.trebbleHelperTrackCapsuleCreated){
                        window.trebbleAnalyticsHelper.trebbleHelperTrackCapsuleCreated();
                    }
                } 
                FacebookEventTrackingHelper.getInstance().trackCapsuleCreated();
                return capsuleCreationResults;
            }catch(error){
                console.error(error);
                this.view.showHideUploadingViewInCapsuleInfo(false);
    //$.mobile.loading("hide");
                window.alertErrorMessage((typeof error === "string")? error : window.getI18n(ti18n,"UPLOADING_FAILED"));
                try{
                    window.sendErrorToRaygun("Failed creating capsule. Error:"+ error);
                }catch(err){
                    console.error(err);
                }
            }
        },

        _hideAllWhatsACapsuleButtons : function(){
            LocalStorageHelper.getInstance().setWhatsACapsuleCapsulePlayed();
$(document).find("#whatsacapsuleButton").hide(); //This is dirty but it is easy. This should be cleaned
},


initCapsuleUploadIfApplicable : function(){
this._uploadEementId = this.view.getUploadElementId();
this._nameOfFileToUpload =  "userCreatedCapsule_"+(new Date()).getTime()+".mp3";
let filedUploaded =  false;
if(false && !LocalStorageHelper.getInstance().isWhatsACapsuleCapsulePlayed() && !PersistentModels.getInstance().hasCapsuleCreated()){
    this.view.showWhatsACapsuleButton();
}else{
    this.view.hideWhatsACapsuleButton();
}

this._userUploadedCapsulePromise = new RSVP.Promise((function(userUploadedCapsulePromiseResolve, userUploadedCapsulePromiseReject){
    this._userUploadedCapsulePromiseResolve =  userUploadedCapsulePromiseResolve;
    this._userUploadedCapsulePromiseReject =  userUploadedCapsulePromiseReject;

}).bind(this));

this._dropzonePromiseResolve = (function(result){
        //this will only be call if the upload was successfull
    this._processQueueStarted = false;
    this._userUploadedCapsulePromiseResolve(result)
}).bind(this);
this._dropzonePromiseReject = (function(error){
    if(this._processQueueStarted){
        this._processQueueStarted = false;
        this._userUploadedCapsulePromiseReject(error)
    }else{
        if(this._signatureValidationStarted){
            this._signatureValidationStarted = false;
            this._userUploadedCapsulePromiseReject(error)
        }else{
                //User didn't start uploading the file so do not propaggate the error as this promise is meant to fail only if the upload process started
        }
    }
}).bind(this);
const onFileAccepted = (function(fileAdded, done){
    if(fileAdded){
        filedUploaded = true;
            //if(this._capsuleUploadDropzoneObj.getAcceptedFiles().length == 1){
        if(fileAdded){
                    const file = fileAdded;//this._capsuleUploadDropzoneObj.getAcceptedFiles()[0];
                    const url = URL.createObjectURL(file);
                    return Utils.getInstance().getAudioDuration(url).then((function(duration){
                        console.log("file name",file.name);
                        console.log("file type",file.type);
                        console.log("file duration",duration);
                        if(window.trebbleAnalyticsHelper){
                            window.trebbleAnalyticsHelper.trackEvent("Capsule", "fileNameForShortcastCreation", "Name of the audio file uploaded for shortcast creation", file.name);
                            window.trebbleAnalyticsHelper.trackEvent("Capsule", "fileTypeForShortcastCreation", "Type of the audio file uploaded for shortcast creation", file.type);
                            window.trebbleAnalyticsHelper.trackEvent("Capsule", "fileDurationForShortcastCreation", "Duration of the audio file uploaded for shortcast creation", duration);
                        }
                        let capsuleMaxDuration = LocalStorageHelper.getInstance().getCustomMaxCapsuleLimitInSeconds() ?  LocalStorageHelper.getInstance().getCustomMaxCapsuleLimitInSeconds() : 5400; //1h30
                        if(LocalStorageHelper.getInstance().getCustomMaxCapsuleLimitInSecondsOnUpload && LocalStorageHelper.getInstance().getCustomMaxCapsuleLimitInSecondsOnUpload()){
                            capsuleMaxDuration =   LocalStorageHelper.getInstance().getCustomMaxCapsuleLimitInSecondsOnUpload();
                        }
                        capsuleMaxDuration = 5400; //1h30min
                        if(file.type && file.type.includes("video/")){
                            capsuleMaxDuration = 60 * 90 //1h30min
                        }
                        if(!duration || duration > capsuleMaxDuration){
                            const validationError = window.getI18n(ti18n,"UPLOADED_FILE_IS_LONGER_THAN")+" "+ Math.round(capsuleMaxDuration/60) +" "+window.getI18n(ti18n,"MINUTES");
                            this.view.setUploadedFileAdded(false);
                            //window.alertErrorMessage(validationError);
                            if(done){
                                done(validationError);
                            }
                        }else{
                            this.view.setUploadedFileAdded(true);
                            if(done){
                                done();
                            }
                        }

                    }).bind(this))
                }
                //this.view.setUploadedFileAdded(true);
            }

        }).bind(this);
this._capsuleUploadDropzoneObj =  FileUploadHelper.getInstance().uploadCapsuleInWebBrowser(this._nameOfFileToUpload,"#"+ this._uploadEementId,this._dropzonePromiseResolve,this._dropzonePromiseReject, onFileAccepted);
//this._capsuleUploadDropzoneObj.on("addedfile", onFileAccepted);
this._capsuleUploadDropzoneObj.on("removedfile", (function(fileRemoved){
    filedUploaded = false;
    this.resetTranscriptionAndAudioEdits();
    if(this._capsuleUploadDropzoneObj.getAcceptedFiles().length == 1){
        this.view.setUploadedFileAdded(true);
    }else{
        this.view.setUploadedFileAdded(false);
    }

}).bind(this));

this._capsuleUploadDropzoneObj.on("error", (function(file, error){
    window.alertErrorMessage(error);
    this.view.setUploadedFileAdded(false);
}).bind(this));

},
setRecordMode : function(){
this._creationMode = CREATION_MODE.FROM_RECORDING;
this.showOnboardingIfApplicable();
},

setFileUploadMode : function(){
this._creationMode = CREATION_MODE.FROM_FILE;
this.showOnboardingIfApplicable();
},

setFromTextMode : function(){
this._creationMode = CREATION_MODE.FROM_TEXT;
},

setFromVoiceActor : function(){
this._creationMode = CREATION_MODE.FROM_VOICE_ACTOR;
},

isInRecordMode :function(){
return this._creationMode == CREATION_MODE.FROM_RECORDING;
},

isInUploadMode :function(){
return this._creationMode == CREATION_MODE.FROM_FILE;
},

isInTTSMode :function(){
return this._creationMode == CREATION_MODE.FROM_TEXT;
},

isInVoiceActorMode :function(){
return this._creationMode == CREATION_MODE.FROM_VOICE_ACTOR;
},

_onCreationModeSelectionChanged : function(modeSelectedModel){
if(modeSelectedModel){
    this.view.setCreationMode(modeSelectedModel.getId());

    this.showSelectedCreationMode(modeSelectedModel);
    if(this.creationModeSelectorController){
        this.creationModeSelectorController.setSelected(modeSelectedModel, true, true);
    }
}
},

shouldOnboardingBeDisplayed: function(){
    return RECORD_PAGE_ONBOARDING && !window.isMobileBrowser && (PersistentModels.getInstance().getRouter().didSignUpOccuredInCurrentSession() ||  !LocalStorageHelper.getInstance().isRecordPageOnboardingWasCompletedOrDismissed());
  },

  showOnboardingIfApplicable: function(){
    if(this.shouldOnboardingBeDisplayed() && this.view){
        this.view.showRecordPageOnboardingInstruction();
    }
  },

  onRecordPageOnboardingTourCompleted: function () {
    LocalStorageHelper.getInstance().setRecordPageOnboardingWasCompletedOrDismissed();
  },

  onRecordPageOnboardingTourCancelled: function () {
    LocalStorageHelper.getInstance().setRecordPageOnboardingWasCompletedOrDismissed();
  },

  onSaveOrEditPageOnboardingTourCompleted: function () {
    LocalStorageHelper.getInstance().setSaveOrEditPageOnboardingWasCompletedOrDismissed();
  },

  onSaveOrEditPageOnboardingTourCancelled: function () {
    LocalStorageHelper.getInstance().setSaveOrEditPageOnboardingWasCompletedOrDismissed();
  },

  

showAndPlayWhatsACapsuleCapsule: function(){
PersistentModels.getInstance().getRouter().showAndPlayWhatACapusleCapsule().then((function(capsuleDetailsPopupController){
    capsuleDetailsPopupController.playCapsule();
    this.listenTo(capsuleDetailsPopupController,"finishPlaying", (function(){
        LocalStorageHelper.getInstance().setWhatsACapsuleCapsulePlayed();
    }).bind(this))
}).bind(this));
},

getCapsuleLanguage : function(){
const trebbleDefauftLanguages = PersistentModels.getInstance().getAllMyPersonalRadioLanguages();
const trebbleDefaultLanguage  = trebbleDefauftLanguages && trebbleDefauftLanguages.length> 0? trebbleDefauftLanguages[0]: "en";
return trebbleDefaultLanguage;
},

onBackgroundTrackChanged : function(backgroundTrackUrl){
this._backgroundTrackUrl =  backgroundTrackUrl;
if(!CONFIGURE_BACKGROUND_MUSIC || this.view.isPlaying()){
    if(this._filenameArray && this._filenameArray.length > 0 && !AudioRecorderHelper.getInstance().isRecording()){
        const exitingBackgroundChangePromise =  this._backgroundTrackChangeInProgressPromise? this._backgroundTrackChangeInProgressPromise: RSVP.Promise.resolve();
        const  p  = exitingBackgroundChangePromise.then((function(){
            return this.stopPlayingRecordings();
        }).bind(this)).then((function(){
            this.playRecordings();
            this._backgroundTrackChangeInProgressPromise = null;
        }).bind(this));
        this._backgroundTrackChangeInProgressPromise = p;
        return this._backgroundTrackChangeInProgressPromise;
    }
}
},

onViewRemoved:function(){

this.stopListening();
this.deleteAllRecordings();
this._destroySequencerNodeController();
if(this._onCapsuleCreationPageClosed){
    this._onCapsuleCreationPageClosed();
}
},

showRecordingSettings : function(){
PersistentModels.getInstance().getRouter().showRecordingSettingsPopup();
},

addUploadedAudioRecordingToUserLibrary : function(){
return TrebbleClientAPIHelper.getInstance().addUploadedAudioRecordingArrayToUserLibrary(this._uploadedAudioPartInfoForTranscriptionAndEditing);
},

isTransciptionProcessFast :function(){
const FAST_LANGUAGE_TRANSCRIBED = ["no","pl", "uk"];
if(this.view.getLanguageUsedInAudio()){
    return FAST_LANGUAGE_TRANSCRIBED.indexOf(this.view.getLanguageUsedInAudio()) != -1;
}
return false;
},

startTranscriptionOfUploadedAudioRecording : function(){
return new RSVP.Promise((function(resolve, reject){
    const trebbleAudioArray = [];
    for(let audioUri in this._audioUriToTrebbleAudio){
        const trebbleAudioJson = this._audioUriToTrebbleAudio[audioUri];
        const trebbleAudioOrVideoModel = trebbleAudioJson.isVideo?  new TrebbleVideo(trebbleAudioJson):  new TrebbleAudio(trebbleAudioJson);
        trebbleAudioArray.push(trebbleAudioOrVideoModel)
    }
    const onTranscriptionCompleted = (function(results){
        if(results){
            this._lastTranscriptionResults = results;
            resolve(this._areAllTranscriptionStatusCompleted(results));
        }else{
            reject(window.getI18n(ti18n, "TRANSCRIPTION_FAILED"));
        }
    }).bind(this)

    const  onTranscriptionFailedStartingBecuaseNotEnoughMinutes = function(){
        reject(window.getI18n(ti18n, "YOU_DONT_HAVE_ENOUGH_MINUTES_TO_EXECUTE_THIS_TRANSCRIPTION"))
    };
    const onTranscriptionFailedStartingForOtherReason = function(error){
        reject(error);
    }
    TranscriptionManager.getInstance().startBulklTranscription(trebbleAudioArray, onTranscriptionCompleted, this.view.getLanguageUsedInAudio(), onTranscriptionFailedStartingBecuaseNotEnoughMinutes, onTranscriptionFailedStartingForOtherReason);
}).bind(this))
},

_areAllTranscriptionStatusCompleted : function(results){
for(let i = 0; i < results.length; i++){
    const r  = results[i];
    if(r.status != Utils.getInstance().getTrebbleJobStatus().COMPLETED){
        return false;
    }
}
return true;
},

_destroySequencerNodeController : function(){
if(this._sequencerController){
    this._sequencerController.stopPlaybackIfApplicable();
    this._sequencerController.clearSequencer(true);
    this._sequencerController.closeAudioContext();
    this.stopListening(this._sequencerController.getModel())
    if(this._sequencerController.getView() && this._sequencerController.getView().$el){
        this._sequencerController.getView().$el.remove();
    }
    this._sequencerController =  null;
    this._lastRenderWavFileBlob = null;
}
},

_onSequencerProjectIdChange : function(projectId){
if(projectId){
    PersistentModels.getInstance().getRouter().updateWindowLocationWithNewProjectId(projectId);
}
},

initialiazedAudioEditorWithLatestTranscriptions : function(audioFileUrl, transcriptionFileLocation, transcriptionId, isVideo, width, height){
this._destroySequencerNodeController();
this._sequencerController =  new SequencerController();
if(window.trebbleAnalyticsHelper){
    window.trebbleAnalyticsHelper.trackEvent("audioEditor", "Initialize Audio Editor", "Initialize Audio Editor");
}
this.listenTo(this._sequencerController.getModel(), "projectIdChange",this._onSequencerProjectIdChange.bind(this));
this.view.setContentLoading(false);
this.view.showHideUploadingViewInAudioEditor(true);
this.audioEditorProgressBarController.setIndeterminate(true);
this.audioEditorProgressBarController.setProgressMessage(window.getI18n(ti18n,"INITIALIZING_EDITOR"));
const initSequencerControllerPromise = (audioFileUrl && transcriptionFileLocation)? this._initSequencerControllerWithTranscriptionInfo(audioFileUrl, transcriptionFileLocation, transcriptionId, isVideo, width, height): this._addLatestTranscriptionsToAudioEditor(0);
return initSequencerControllerPromise.then((function(){
    if(this._sequencerController){
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("audioEditor", "Transcription Added To Audio Editor", "Transcription Added To Audio Editor");
        }
        return this._sequencerController.buildModelAndRenderView();
    }
}).bind(this)).then((function(){
    if(this._sequencerController){
        //this._sequencerController.setHeightMargin(170);
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("audioEditor", "Audio Editor Built And Rendered", "Audio Editor Built And Rendered");
        }
        this._sequencerController.setJqueryPageElWhereSequencerWillBeAdded(this.view.$el);
        this._sequencerController.setHeightMargin(10);
        this.view.setContentLoading(false);
        this.view.showHideUploadingViewInAudioEditor(false);
        return this.view.setAudioEditorView(this._sequencerController.getView());
    }
}).bind(this));
},

initialiazedAudioEditor : function(sequencerControllerInitializationFunction){
this._destroySequencerNodeController();
this._sequencerController =  new SequencerController();
if(window.trebbleAnalyticsHelper){
    window.trebbleAnalyticsHelper.trackEvent("audioEditor", "Initialize Audio Editor", "Initialize Audio Editor");
}
this.listenTo(this._sequencerController.getModel(), "projectIdChange",this._onSequencerProjectIdChange.bind(this));
this.view.setContentLoading(true);	
const initSequencerControllerPromise = sequencerControllerInitializationFunction(this._sequencerController);
return initSequencerControllerPromise.then((function(){
    if(this._sequencerController){
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("audioEditor", "Transcription Added To Audio Editor", "Transcription Added To Audio Editor");
        }
        
        return this._sequencerController.buildModelAndRenderView();
    }
}).bind(this)).then((function(){
    if(this._sequencerController){
        this.view.showHideUploadingViewInAudioEditor(false); //hide loading progress
        //this._sequencerController.setHeightMargin(170);
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("audioEditor", "Audio Editor Built And Rendered", "Audio Editor Built And Rendered");
        }
        this._sequencerController.setJqueryPageElWhereSequencerWillBeAdded(this.view.$el);
        this._sequencerController.setHeightMargin(10);
        this.view.setContentLoading(false);
        return this.view.setAudioEditorView(this._sequencerController.getView());
    }
}).bind(this));
},


_addLatestTranscriptionsToAudioEditor : function(startIndex){
if(this._sequencerController && this._lastTranscriptionResults && this._lastTranscriptionResults.length > startIndex){
    const r = this._lastTranscriptionResults[startIndex];
    const transcriptionId = r.transcriptionId;
    const trebbleAudio = r.trebbleAudio; 
    const status = r.status;
    const isVideo = r.isVideo;
    const transcriptionFileLocation = r.transcriptionFileLocation;
    return this._initSequencerControllerWithTranscriptionInfo(trebbleAudio.getAudioUrl(), transcriptionFileLocation, transcriptionId, isVideo).then((function(){
        return this._addLatestTranscriptionsToAudioEditor(startIndex + 1);
    }).bind(this));
}
},

_initSequencerControllerWithTranscriptionInfo: async function(audioFileUrl, transcriptionFileLocation, transcriptionId, isVideo, languageCode){
    let videoWidth = null;
    let videoHeight = null;
    if(isVideo){
        const videoMetadata =  await Utils.getInstance().getVideoMetadata(audioFileUrl);
        if(videoMetadata){
            videoWidth = videoMetadata.width;
            videoHeight = videoMetadata.height;
        }
    }
    return  this._sequencerController.addTranscribeAudio(audioFileUrl, transcriptionFileLocation, transcriptionId, null, isVideo, this.getOnEditorLoadingProgressReported(), languageCode, videoWidth, videoHeight);
},

resetTranscriptionAndAudioEdits : function(){
if(TREBBLE_TEXT_BASED_AUDIO_EDITOR && this.view.isAudioWasTranscribedAndEdited()){
    this.view.setAudioWasTranscribedAndEdited(false);
    this._destroySequencerNodeController();
    this._uploadedAudioPartInfoForTranscriptionAndEditing = null;
    this._audioUriToTrebbleAudio = null;
    this.view.showHideSequncerNodeWrapper(false);
    this.view.showHideUploadingViewInAudioEditor(false);
    this.view.showHideMessageToStartTranscriptionBox(true);
    if(window.trebbleAnalyticsHelper){
        window.trebbleAnalyticsHelper.trackEvent("audioEditor", "Reset transcription and audio edits", "Reset transcription and audio edits");
    }
}
},

transcribeAndEditAudio : function(){
//this.view.setAudioWasTranscribedAndEdited(false);
this.view.showHideMessageToStartTranscriptionBox(false);
this.view.showHideSequncerNodeWrapper(false);
this.view.showHideUploadingViewInAudioEditor(true);
return this.uploadRecordingToAudioLibraryForEditingIfApplicable(this.audioEditorProgressBarController).then((function(audioPartsInfo){
    this._uploadedAudioPartInfoForTranscriptionAndEditing = audioPartsInfo;
    window.audioPartsInfo = audioPartsInfo;
    return this.addUploadedAudioRecordingToUserLibrary();
}).bind(this)).then((function(audioUriToTrebbleAudio){
    this._audioUriToTrebbleAudio = audioUriToTrebbleAudio;
    this.audioEditorProgressBarController.setIndeterminate(true);
    this.audioEditorProgressBarController.setProgressMessage(window.getI18n(ti18n, "TRANSCRIBING_YOUR_AUDIO"));
    const transcriptionProgressSubmessage = this.isTransciptionProcessFast()? window.getI18n(ti18n, "TRANSCRIPTION_MIGHT_TAKE_SEVERAL_MINUTES")+ " "+ window.getI18n(ti18n, "DO_NOT_REFRESH")+ " "+window.getI18n(ti18n, "PLEASE_HOLD_ON_TIGHT") : window.getI18n(ti18n, "THIS_MIGHT_TAKE_A_FEW_MINUTES_WE_WILL_SEND_YOU_AN_EMAIL");
    this.audioEditorProgressBarController.setProgressSubMessage(transcriptionProgressSubmessage);
    return this.startTranscriptionOfUploadedAudioRecording();
}).bind(this)).then((function(transcriptionSuccessfullyCompleted){
    this.view.showHideUploadingViewInAudioEditor(false);
    if(transcriptionSuccessfullyCompleted){
        this.view.setAudioWasTranscribedAndEdited(true);
        this.view.showHideSequncerNodeWrapper(true);
        return this.initialiazedAudioEditorWithLatestTranscriptions();
    }else{
        this.view.setAudioWasTranscribedAndEdited(true);
        this.view.showHideMessageToStartTranscriptionBox(true);
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("audioEditor", "Transcription failed", "Transcription failed");
        }
        window.alertErrorMessage(window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_TRANSCRIBING"))
    }
}).bind(this)).catch((function(error){
    this.view.showHideUploadingViewInAudioEditor(false);
    this.view.showHideMessageToStartTranscriptionBox(true);
    if(error && error === window.getI18n(ti18n, "YOU_DONT_HAVE_ENOUGH_MINUTES_TO_EXECUTE_THIS_TRANSCRIPTION")){
        const onUserClickedOnUpgradeNowButton = function(){
            return PersistentModels.getInstance().getRouter().showUpgradeCreatorSubscriptionPopup(PersistentModels.getInstance().getRouter().getConfirmUpgradeCreatorSubscriptionAndRedirectToCreateShortcastPageURL(), PersistentModels.getInstance().getRouter().getConfirmPaymentUpdateAndRedirectToCreateShortcastPageURL());
        }
        window.alertErrorMessage(window.getI18n(ti18n, "YOU_DONT_HAVE_ENOUGH_MINUTES_TO_EXECUTE_THIS_TRANSCRIPTION"), null, onUserClickedOnUpgradeNowButton, window.getI18n(ti18n, "UPGRADE_NOW"));
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("audioEditor", "User doesn't have enough transcription minutes", "User doesn't have enough transcription minutes");
        }
    }else{
        console.error(error);
        window.sendErrorToRaygun(error, true);
        window.alertErrorMessage(window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_TRANSCRIBING"))
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("audioEditor", "Something went wrong during the initialization of audio editor", "Something went wrong during the initialization of audio editor", null,{"error": error });
        }
    }
}).bind(this))
},

getOnEditorLoadingProgressReported : function(){
const defaultProgresMessageOnUpload = window.getI18n(ti18n,"INITIALIZING_EDITOR");
    return (function(progress, operationInProgress){
        if(progress){
            this.audioEditorProgressBarController.setIndeterminate(false);
            this.audioEditorProgressBarController.setProgressMessage(operationInProgress?operationInProgress:defaultProgresMessageOnUpload);
            this.audioEditorProgressBarController.setProgressSubMessage("");
            this.audioEditorProgressBarController.setPercentageProgress(Math.round(progress)+ "%");
        }else{
            this.audioEditorProgressBarController.setIndeterminate(true);
            this.audioEditorProgressBarController.setProgressMessage(operationInProgress?operationInProgress:defaultProgresMessageOnUpload);
            this.audioEditorProgressBarController.setProgressSubMessage("");
        }
    }).bind(this)
},

fetchCapsuleInfoIfApplicable : async function(){
    if(this._capsuleIdToEdit){
        const capsuleJson = await TrebbleClientAPIHelper.getInstance().getCapsuleInfoByCapsuleId(this._capsuleIdToEdit);
        if(capsuleJson){
            this._capsuleInfoToEdit = Utils.getInstance().getModelFromCapsuleJson(capsuleJson);
        }
    }
},
updateFormFromCapsuleIdInfoIfApplicable : function(){
    if(this._capsuleInfoToEdit){
        this.view.setCapsuleInfoToEdit(this._capsuleInfoToEdit);
    }
},

shouldJumpToAudioEditor :function(){
    return this._createFromTranscriptionId || this._createFromProjectId|| this._editProjectId;
},
jumpToAudioEditorIfApplicable : function(){
if(this.shouldJumpToAudioEditor()){
    const defaultProgresMessageOnUpload = window.getI18n(ti18n,"INITIALIZING_EDITOR");

    const onEditorLoadingProgressReported = this.getOnEditorLoadingProgressReported();

    let sequencerControllerInitializationFunction = (function(sequencerController){
        return this._addLatestTranscriptionsToAudioEditor(0);
    }).bind(this);
    if(this._createFromTranscriptionId){
        sequencerControllerInitializationFunction =((function(sequencerController){
            return TrebbleClientAPIHelper.getInstance().getTranscriptionInfo(this.transcriptionId).then((async function(transactionInfo){
                if(transactionInfo){
                    const audioFileUrl = transactionInfo.urlOfTranscribedAudio;
                    const transcriptionFileLocation = transactionInfo.transcriptionFileLocation;
                    const transcriptionId = this.transcriptionId;
                    const isVideo = transactionInfo.isVideo;
                    const languageCode =  transactionInfo.languageCode;
                    this.self.view.setContentLoading(false);
                    this.self.view.showHideUploadingViewInAudioEditor(true);
                    this.self.audioEditorProgressBarController.setIndeterminate(true);
                    this.self.audioEditorProgressBarController.setProgressMessage(defaultProgresMessageOnUpload);

                    let videoWidth = null;
                    let videoHeight = null;
                    if(isVideo){
                        const videoMetadata =  await Utils.getInstance().getVideoMetadata(audioFileUrl);
                        if(videoMetadata){
                            videoWidth = videoMetadata.width;
                            videoHeight = videoMetadata.height;
                        }
                    }
                    return (audioFileUrl && transcriptionFileLocation)? sequencerController.addTranscribeAudio(audioFileUrl, transcriptionFileLocation, transcriptionId, false, isVideo, onEditorLoadingProgressReported, languageCode, videoWidth, videoHeight): this.self._addLatestTranscriptionsToAudioEditor(0);
                }
            }).bind(this));
        }).bind({"self":this, "transcriptionId": this._createFromTranscriptionId}));
    }else{
        if(this._createFromProjectId || this._editProjectId){
            sequencerControllerInitializationFunction = ((function(sequencerController){
                this.self.view.setContentLoading(false);
                this.self.view.showHideUploadingViewInAudioEditor(true);
                this.self.audioEditorProgressBarController.setIndeterminate(true);
                this.self.audioEditorProgressBarController.setProgressMessage(defaultProgresMessageOnUpload);
                return sequencerController.setAndLoadProject(this.projectId, this.duplicateProject, onEditorLoadingProgressReported);
            }).bind({"self":this, "projectId": this._editProjectId? this._editProjectId: this._createFromProjectId, duplicateProject: this._createFromProjectId? true: false }));
        }
    }
    this.view.setContentLoading(false);
    this.initialiazedAudioEditor(sequencerControllerInitializationFunction);
    this.view.setAudioWasTranscribedAndEdited(true);
    this.view.showHideMessageToStartTranscriptionBox(false);
    this.view.showHideSequncerNodeWrapper(true);
    //this.view.showHideUploadingViewInAudioEditor(false);
    this.view.setInCreationFromTranscriptMode();
}

},		

stopAudioEditorPlaybackIfApplicable: function(){
if(this._sequencerController){
    this._sequencerController.stopPlaybackIfApplicable();
}
},

isEditedContentContainsVideo :function(){
if(this._sequencerController){
    return this._sequencerController.getHasVideoSegment();
}
return false;
},

onRecordingCompleted : async function(){
    if(this._recordOnly){
        const blobArray = [];
        const recordFilenameToBlog =  AudioRecorderHelper.getInstance().getRecordFilenameToBlob();
        for(let index in this._filenameArray){
            const fileName = this._filenameArray[index];
            const blob = recordFilenameToBlog[fileName];
            blob.name = fileName;
            blobArray.push(blob);
        }
        await this._onAllAudioRecorded(blobArray);
        this.view.navigateToPreviousPage();
    }

},


buildModelAndRenderView : function() {
return new RSVP.Promise((function(resolve, reject) {
    try {
        const promises = [];

        //promises.push(this.songSelectorListController.buildModelAndRenderView());
        promises.push(this.songAttachedToCapsuleSongSelectorInputController.buildModelAndRenderView());
        promises.push(this.backgroundSongSelectorInputController.buildModelAndRenderView());
        promises.push(this.capsuleInfoProgressBarController.buildModelAndRenderView());
        promises.push(this.audioEditorProgressBarController.buildModelAndRenderView());
        promises.push(this.creationModeSelectorController.buildModelAndRenderView());
        promises.push(this.peakMeterWidgetController.buildModelAndRenderView());
        promises.push(this.fetchCapsuleInfoIfApplicable())

        RSVP.Promise.all(promises).then((function(){
            const params = {};
            params.model = this.getSongModelToPlayAfterCapsule();
            params.followersUsersInfo = PersistentModels.getInstance().getMyFollowingsInfo();
            params.recordKey  = `${window.getI18n(ti18n, "RECORDING")}_${moment().format('YYYY-MM-DD_HH-mm-ss')}`;
            params.pageId = this._pageId;
            params.creationModeSelectorView = this.creationModeSelectorController.getView();
            params.creationModeFlatListViewContainer$el = this._creationModeFlatListViewContainer$el;
            //this.creationModeSelectorController.setSelected(Utils.getInstance().getListItemModel(CREATION_MODE.FROM_RECORDING,   "<span class='icon pe-7s-micro'></span>" +this._getRecordCreationOptionLabel()), true, true);

            //params.songSelectorListView = this.songSelectorListController.getView();
            params.songAttachedToCapsuleSongSelectorInputView = this.songAttachedToCapsuleSongSelectorInputController.getView();
            params.backgroundSongSelectorInputView = this.backgroundSongSelectorInputController.getView();
            this.songAttachedToCapsuleSongSelectorInputController.setSelectedSongModel(this._defaultSongToPlayAfterCapsule);
            params.capsuleCategoryCollection = new ListItemCollection(Utils.getInstance().getCapsuleCategoryListItemArray());
            params.selectedDefaultCategoryId = Utils.getInstance().getDefaultSelectedCapsuleCategoryId();
            params.timezoneCollection = new ListItemCollection(Utils.getInstance().getTimezoneListItemArray());
            params.selectedDefaultTranscriptionLanguage  = LocalStorageHelper.getInstance().getLastLanguageUsedDuringTranscription();
            params.supportedTranscriptionLanguagesCollection = new ListItemCollection(Utils.getInstance().getSupportedTranscribedLanguage());
            const trebbleDefauftTimezones = PersistentModels.getInstance().getAllMyPersonalRadioTimezones();
            const trebbleDefaultTimezone  = trebbleDefauftTimezones && trebbleDefauftTimezones.length> 0? trebbleDefauftTimezones[0]: Intl.DateTimeFormat().resolvedOptions().timeZone;
            params.selectedDefaultTimezone = trebbleDefaultTimezone;
            params.capsuleInfoProgressBarView = this.capsuleInfoProgressBarController.getView();
            params.audioEditorProgressBarView = this.audioEditorProgressBarController.getView();
            params.audioRecordingSupported = this._audioRecordingSupported;
            params.receiverUserId = this._receiverUserId;
            params.capsuleSchedulingAvailable =  this.capsuleSchedulingAvailable;
            params.saveAsDraft = this._saveAsDraft;
            params.customPageTitle = this._customPageTitle;
            params.createAShortcast = this._createAShortcast;
            params.peakMeterView = this.peakMeterWidgetController.getView();
            params.canSaveOnlyAfterTranscriptionAndEditing =  this._canSaveOnlyAfterTranscriptionAndEditing;
            params.recordOnly = this._recordOnly;
            this.view = new RecordAudioHightlightPageNewView(params);
            this.listenTo(this.creationModeSelectorController, "selectionChanged",this._onCreationModeSelectionChanged.bind(this) );
            this.listenTo(this.view, "startRecording",this.startRecording);
            this.listenTo(this.view, "stopRecording",this.stopRecording);
            this.listenTo(this.view, "deleteRecording",this.deleteRecording);
            this.listenTo(this.view, "deleteAllRecordings",this.deleteAllRecordings);
            this.listenTo(this.view, "playRecordings",this.playRecordings);
            this.listenTo(this.view, "stopPlayingRecordings",this.stopPlayingRecordings);
            this.listenTo(this.view, "saveRecording",this.saveCapsuleConfirm);
            this.listenTo(this.view, "setRecordMode", this.setRecordMode);
            this.listenTo(this.view, "setFileUploadMode", this.setFileUploadMode);
            this.listenTo(this.view, "setFromTextMode", this.setFromTextMode);
            this.listenTo(this.view, "setFromVoiceActor", this.setFromVoiceActor);
            this.listenTo(this.view, "onWhatsACapsuleButton", this.showAndPlayWhatsACapsuleCapsule.bind(this));
            this.listenTo(this.view, "backgroundTrackChange", this.onBackgroundTrackChanged);
            this.listenTo(this.view, "checkRecordingPermissionAndRecord",this.checkRecordingPermissionAndRecord)
            this.listenTo(this.view, "showRecordingSettings", this.showRecordingSettings)
            this.listenTo(this.view, "transcribeAndEditAudio", this.transcribeAndEditAudio)
            this.listenTo(this.view, "resetTranscriptionAndAudioEdits", this.resetTranscriptionAndAudioEdits)
            this.listenTo(this.view, "navigatingToNextPageAFterAudioEditorPage", this.stopAudioEditorPlaybackIfApplicable)
            this.listenTo(this.view, "recordPageOnboardingTourCompleted", this.onRecordPageOnboardingTourCompleted);
            this.listenTo(this.view, "recordPageOnboardingTourCancelled", this.onRecordPageOnboardingTourCancelled);
            this.listenTo(this.view, "saveorEditPageOnboardingTourCompleted", this.onSaveOrEditPageOnboardingTourCompleted);
            this.listenTo(this.view, "saveorEditPageOnboardingTourCancelled", this.onSaveOrEditPageOnboardingTourCancelled);
            this.listenTo(this.view, "recordingCompleted", this.onRecordingCompleted)
            

            this.listenTo(this.view, "pageHide",this.onViewRemoved)
            this.view.$el.on( "remove", this.onViewRemoved.bind(this));
            this.view.render();
            this.setAudioRecordingSupported(this._audioRecordingSupported);
            this.view.$el.on("pageAppendedToBody", (function(){
                this.initCapsuleUploadIfApplicable();
                if(!this._audioRecordingSupported || this._startCreationInFileUploadMode){
                    this._onCreationModeSelectionChanged(this._creationFromFileListItemModel);
                }else{
                    this._onCreationModeSelectionChanged(this._creationFromRecordingListItemModel);
                }
                if(!this.shouldJumpToAudioEditor()){
                    setTimeout(()=>{
                        this.showOnboardingIfApplicable();
                    },1000)
                    
                }
                this.jumpToAudioEditorIfApplicable();
                this.updateFormFromCapsuleIdInfoIfApplicable();
            }).bind(this));


            resolve(this);
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("Capsule", "CapsuleCreationPageOpened", "Capsule Creation Page Opened");
            }
        }).bind(this))

} catch (error) {
reject(error);
}

}).bind(this));

}

});

export default RecordAudioHightlightPageNewController;