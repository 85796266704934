import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import ti18n from "i18n!nls/PlaylistDetailsi18n";
import pageTemplate from 'text!../../../templates/playlistPodcasts/PlaylistDetails.html';
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";

const PlaylistDetailPageView = Backbone.View.extend({

    template : _.template(pageTemplate),
    "pageName" : "Playlist Detail",
    
    events : {
        "click #menuOptionButton" : "onOptionMenuButtonTapped",
        "click #followBtn" : "follow",
        "click #followingBtn" : "unfollow",
        "click #showStatistics":"showStatistics",
        "click #shareTrebbleBtn":"showShareTrebblePopup",
    },

    initialize : function(options) {
        this.model = options.model;
        this.listenTo(this.model,"change:isFollowing", this.updateFollowButton.bind(this));
    },
    

    navigateToPreviousPage : function(){
        PersistentModels.getInstance().getRouter().navigateToPreviousPage();
    },
    
    
    onOptionMenuButtonTapped : function(){
        this.trigger("showArtistMenuOptionPopup",this.model);
    },
    


    render : function() {
        this.$el.html(this.template({"model" :this.model.toJSON(), "options":{"ti18n": ti18n}}));
        this.setElement(this.$el.find("div").first());
        this.$el.page();
        this.$el.addClass("lighttext");
        //this.$el.find(".topsticky").fixedsticky();
        return this;
    },
    
    
    follow : function(){
        //this.setFollow();
        this.trigger("follow");
    },
    
    unfollow : function(){
        //this.setUnfollow();
        this.trigger("unfollow");
    },

    showStatistics : function(){
        this.trigger("showStatistics");
    },

    showShareTrebblePopup : function(){
        this.trigger("showShareTrebblePopup");
    },
    

    updateFollowButton : function(){
        if(!this.model.canBeFollowed()){
            this.$el.find("#followingBtn").css("display","none");
            this.$el.find("#followBtn").css("display","none");
        }else{
            if(this.model.isFollowing()){
                this.$el.find("#followingBtn").css("display","block");
                this.$el.find("#followBtn").css("display","none");
            }else{
                this.$el.find("#followingBtn").css("display","none");
                this.$el.find("#followBtn").css("display","block");
            }
        }
        //this.$el.find("#followingsFollowedInfoBox > .value").html(this.model.getNumberOfFollowings());
        //this.$el.find("#followedFollowedInfoBox > .value").html(this.model.getNumberOfFollowers());
        this.updateSocialInfo();
    },
    
    updateSocialInfo : function(){
        let socialInfoInnerHtml = (this.model.canBeFollowed() || this.model.get("numberOfFollowers") > 0)?this.model.get("numberOfFollowers") + " <i class='ion-person' /> · ":"" ;
        socialInfoInnerHtml = socialInfoInnerHtml + this.model.get("numberOfSongs") + " <i class='ion-ios7-musical-notes'style='font-size: 1.0625em;'/>";
        socialInfoInnerHtml = socialInfoInnerHtml + this.model.get("totalNumberOfPlays") + " <i class='ion-ios7-play' style='font-size: 1.125em;'/>";
        socialInfoInnerHtml = socialInfoInnerHtml + this.model.get("numberOfCapsules") + " <i class='ion-contrast' style='font-size: 0.875em;'/>";
        socialInfoInnerHtml = socialInfoInnerHtml + this.model.get("numberOfSongsComments") + " <i class='ion-chatbox' style='font-size: 1em;'></i>";
        this.$el.find(".socialInfos").html(socialInfoInnerHtml);
    },
    

    
    onBeforeRemoveFromPage : function(){
        this.trigger("beforeRemoveFromPage", this);
    },
    
    onRemovedFromPage : function(){
         this.undelegateEvents();
         this.$el.removeData().unbind(); 
         this.trigger("removedFromPage", this);
    },



});

export default PlaylistDetailPageView;