import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import Chaplin from "chaplin";
import CordovaHelper from "models/helper/CordovaHelper";
import CapsuleCardController from "controllers/capsule/CapsuleCardController";
import AllMyCapsuleWidgetController from "controllers/capsule/AllMyCapsuleWidgetController";
import CollectionRepeatController from "controllers/common/CollectionRepeatController";
import InfoBoxController from "controllers/common/InfoBoxController";
import PlaylistListController from "controllers/common/PlaylistListController";
import LibrarySortAndFilterOptionController from "controllers/myLibrary/LibrarySortAndFilterOptionController";
import Utils from "models/helper/Utils";
import Capsules from "collections/Capsules";
import PlaylistHelper from "models/helper/PlaylistHelper";
import AudioRecorderHelper from "models/helper/AudioRecorderHelper";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import ti18n from "i18n!nls/MyLibraryi18n";
import MyLibraryPageView from "views/myLibrary/MyLibraryNewPageView";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import RSVP from "rsvp";

const SHOW_CAPSULE_TABS = true;
const Rollout = RolloutHelper.getInstance();
const MY_LIBRARY_ONBOARDING = Rollout.isFeatureEnabled(Rollout.FEATURES.MY_LIBRARY_ONBOARDING, true);

const MyLibraryPageController = Chaplin.Controller.extend({
  initialize: function (options) {
    _.extend(this, Backbone.Events);
    if (options) {
      if (options.view) {
        this.view = options.view;
      }
      if (options.model) {
        this.model = options.model;
      }
    }
    this.context = Utils.getInstance().buildContextForSongCollectionMyLibrary();
    this._selectedFilterOption = null;
    this._searchFieldValue = "";
    this._selectedSortOption = null;
    this.listenTo(
      PersistentModels.getInstance(),
      "capsuleProcessingCompleted",
      this._onCapsuleSuccessfullyCreated.bind(this),
    );
    this.listenTo(
      PersistentModels.getInstance(),
      "capsuleAddedOrRemoved",
      this._onCapsuleSuccessfullyCreated.bind(this),
    );
  },

  getModel: function () {
    return this.model;
  },

  getView: function () {
    return this.view;
  },

  _showGlobalSearchPage: function () {
    let searchFieldValue = null;
    if (this.getView()) {
      searchFieldValue = this.getView().getSearchFieldValue();
    }

    PersistentModels.getInstance().getRouter().showGlobalSearchPage(searchFieldValue, true);
  },

  showHomePopup: function () {
    PersistentModels.getInstance().getRouter().showHomePage();
  },

  setSearchValue: function (searchValue) {
    if (this.getView()) {
      this.getView().setSearchValue(searchValue);
    }
  },

  setSelectedTab: function (selectedTab) {
    if (this.getView() && selectedTab) {
      switch (selectedTab) {
        case "receivedcapsules":
          this.view.selectReceivedCapsuleTab();
          break;

        case "activecapsules":
          this.view.selectActiveCapsuleTab();
          break;
        case "draftcapsules":
          this.view.selectDraftCapsuleTab();
          break;
        case "inactivecapsules":
          this.view.selectExpiredCapsuleTab();
          break;

        case "mysongs":
          this.view.selectSongsTab();
          break;
      }
    }
  },

  createCapsuleNew: function () {
    $.mobile.loading("show");
    if (window.device && window.device.platform == "browser") {
      AudioRecorderHelper.getInstance()
        .initUserMedia()
        .then(
          function (allowed) {
            $.mobile.loading("hide");
            if (allowed) {
              return PersistentModels.getInstance()
                .getRouter()
                .showAddAudioHightlightPageNew(
                  null,
                  null,
                  this.context,
                  null,
                  true,
                  true,
                  null,
                  null,
                  null,
                  null,
                  null,
                  true,
                  null,
                  null,
                  true
                );
            } else {
              return PersistentModels.getInstance()
                .getRouter()
                .showAddAudioHightlightPageNew(
                  null,
                  null,
                  this.context,
                  null,
                  false,
                  true,
                  null,
                  null,
                  null,
                  null,
                  null,
                  true,
                  null,
                  null,
                  true
                );
            }
          }.bind(this),
        )
        .catch(
          function (e) {
            $.mobile.loading("hide");
            window.navigator.trebbleNotification.alert(e, null, null, "warning");
            return PersistentModels.getInstance()
              .getRouter()
              .showAddAudioHightlightPageNew(
                null,
                null,
                this.context,
                null,
                false,
                true,
                null,
                null,
                null,
                null,
                null,
                true,
                null,
                null,
                true
              );
          }.bind(this),
        );
    } else {
      $.mobile.loading("hide");
      return PersistentModels.getInstance()
        .getRouter()
        .showAddAudioHightlightPageNew(null, null, this.context, null, true, true, null, null, null, null, null, true, null, null, true);
    }
  },

  _onCapsuleSuccessfullyCreated: function () {
    const finishCurrentLoadingIfApplicablePromise = this._tabsLoadingPromise
      ? this._tabsLoadingPromise
      : RSVP.Promise.resolve();
    return finishCurrentLoadingIfApplicablePromise.then(
      function () {
        const promises = [];
        if (this.allMyCapsuleWidgetController.getView()) {
          promises.push(this.allMyCapsuleWidgetController.reload());
        }
        this._tabsLoadingPromise = RSVP.allSettled(promises).then(
          function () {
            this._tabsLoadingPromise = null;
          }.bind(this),
        );
        return this._tabsLoadingPromise;
      }.bind(this),
    );
  },

  _onFilterOptionChanged: function (filterOption) {
    this._selectedFilterOption = filterOption;
    this.onKeyUpInSearchFieldHandler(this.view.getSearchFieldValue());
  },

  _onSortOptionChanged: function (sortOption) {
    this._selectedSortOption = sortOption;
    this.onKeyUpInSearchFieldHandler(this.view.getSearchFieldValue());
  },

  showFilterAndSortOptionPopup: function () {
    const controllerParams = {};
    const onPopupCloseFunction = function (selectedFilterOption, selectedSortOption) {
      $.mobile.loading("show");
      this._onFilterOptionChanged(selectedFilterOption);
      this._onSortOptionChanged(selectedSortOption);
      $.mobile.loading("hide");
    }.bind(this);
    controllerParams.onPopupClose = onPopupCloseFunction;
    controllerParams.selectedFilterOption = this._selectedFilterOption;
    controllerParams.selectedSortOption = this._selectedSortOption;
    const librarySortAndFilterOptionController = new LibrarySortAndFilterOptionController(controllerParams);
    return librarySortAndFilterOptionController.buildModelAndRenderView();
  },

  filterList: function (filters) {
    //window.alertErrorMessage("Coming Soon! this is where you will able to filter to see your entire song library or only the ones you have on your device");
    this.showFilterAndSortOptionPopup();
  },

  getSelectedFilterId: function () {
    return this._selectedFilterOption ? this._selectedFilterOption.getId() : null;
  },

  getSelectedSortId: function () {
    return this._selectedSortOption ? this._selectedSortOption.getId() : null;
  },

  onKeyUpInSearchFieldHandler: function (searchFieldValue) {
    this._searchFieldValue = searchFieldValue;
    this.allMyCapsuleWidgetController.setSearckKeyword(this._searchFieldValue);
    if (this.allMyCapsuleWidgetController.getView()) {
      this.allMyCapsuleWidgetController.reload();
    }
    this.view.onSearchValueChanged(searchFieldValue);
  },

  onSearchFieldClearedHandler: function () {
    this.onKeyUpInSearchFieldHandler("");
  },

  shouldOnboardingBeDisplayed: function(){
    return MY_LIBRARY_ONBOARDING && !window.isMobileBrowser && (PersistentModels.getInstance().getRouter().didSignUpOccuredInCurrentSession() ||  !LocalStorageHelper.getInstance().isMyLibraryOnboardingWasCompletedOrDismissed());
  },

  showOnboardingIfApplicable: function(){
    if(this.shouldOnboardingBeDisplayed() && this.view){
        this.view.showOnboardingInstruction();
    }
  },

  onOnboardingTourCompleted :function(){
    LocalStorageHelper.getInstance().setMyLibraryOnboardingWasCompletedOrDismissed();
  },

  onOnboardingTourCancelled:function(){
      LocalStorageHelper.getInstance().setMyLibraryOnboardingWasCompletedOrDismissed();
  },

  onMyLibraryPageBeforeShow: function () {
    if (this.allMyCapsuleWidgetController.getView()) {
      this.allMyCapsuleWidgetController.reload();
    }
    if (
      false &&
      !LocalStorageHelper.getInstance().isWhatsACapsuleCapsulePlayed() &&
      !PersistentModels.getInstance().hasCapsuleCreated()
    ) {
      this.view.showHideWhatACapsuleButton(true);
    } else {
      this.view.showHideWhatACapsuleButton(false);
    }
    setTimeout(()=>{
      this.showOnboardingIfApplicable();
    }, 500)
    
  },

  _getPreselectedCategoryId: function () {
    const isLoggedInUserChannelOwner = LocalStorageHelper.getInstance().isLoggedInUserACreator();
    return isLoggedInUserChannelOwner ? null : Utils.getInstance().getCapsuleStatuses().ALL;
  },

  _initializeCapsuleWidgetController: function () {
    const params = {};
    params.heightMargin = 0;
    params.preselectedCategoryId = this._getPreselectedCategoryId();
    this.allMyCapsuleWidgetController = new AllMyCapsuleWidgetController(params);
  },

  buildModelAndRenderView: function () {
    return new RSVP.Promise(
      function (resolve, reject) {
        try {
          this._initializeCapsuleWidgetController();
          const promiseArray = [];
          promiseArray.push(this.allMyCapsuleWidgetController.buildModelAndRenderView());
          RSVP.Promise.all(promiseArray)
            .then(
              function () {
                const libraryPageParams = {};
                libraryPageParams.allMyCapsuleWidgetView = this.allMyCapsuleWidgetController.getView();
                libraryPageParams.onKeyUpInSearchFieldHandler = this.onKeyUpInSearchFieldHandler.bind(this);
                libraryPageParams.onSearchFieldClearedHandler = this.onSearchFieldClearedHandler.bind(this);
                this.view = new MyLibraryPageView(libraryPageParams);
                this.listenTo(this.view, "showHomePopup", this.showHomePopup);
                this.listenTo(this.view, "filter", this.filterList);
                this.listenTo(this.view, "pageBeforeShow", this.onMyLibraryPageBeforeShow);
                this.listenTo(this.view, "createCapsule", this.createCapsuleNew.bind(this));
                this.listenTo(this.view, "showGlobalSearchPage", this._showGlobalSearchPage.bind(this));
                this.listenTo(this.view, "myLibraryPageOnboardingTourCompleted", this.onOnboardingTourCompleted);
                this.listenTo(this.view, "myLibraryPageOnboardingTourCancelled", this.onOnboardingTourCancelled);

                this.view.render();
                if (!this._getPreselectedCategoryId()) {
                  this.view.hideHeaderBarShadow();
                }
                this.allMyCapsuleWidgetController.setScrollContainer(
                  this.view.$el.find("#capsuleList #collection_repeat_container"),
                );

                resolve();
              }.bind(this),
            )
            .catch(reject);
        } catch (error) {
          reject(error);
        }
      }.bind(this),
    );
  },
});

export default MyLibraryPageController;
