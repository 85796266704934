

import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import ChooseUploadsWidgetView from "views/chooseUploads/ChooseUploadsWidgetView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import CollectionRepeatController from "controllers/common/CollectionRepeatController";
import TrebbleAudioListItemController from "controllers/common/TrebbleAudioListItemController";
import PlaylistHelper from "models/helper/PlaylistHelper";
import TrebbleAudio from "models/TrebbleAudio";
import TrebbleVideo from "models/TrebbleVideo";
import FileUploadHelper from "models/helper/FileUploadHelper";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import ti18n from "i18n!nls/ConfigureBackgroundMusici18n";
import FacebookEventTrackingHelper from "models/helper/FacebookEventTrackingHelper";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";
import swal from 'sweetalert';

const Rollout = RolloutHelper.getInstance();

//const CONFIGURE_BACKGROUND_MUSIC = Rollout.isFeatureEnabled(Rollout.FEATURES.CONFIGURE_BACKGROUND_MUSIC);

const ChooseUploadsWidgetController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this._selectedTrebbleAudioOrVideo = null;
        this._numberOfResultsPerPage  = options.numberOfResultsPerPage;
        this._onTrebbleAudioSelected = options.onTrebbleAudioSelected;
        this._onTrebbleAudioUnselected = options.onTrebbleAudioUnselected;
        this._onTrebbleVideoSelected = options.onTrebbleVideoSelected;
        this._onTrebbleVideoUnselected = options.onTrebbleVideoUnselected;
        this._searchWord =  "";
        this._uploadedBackgroundMusicTrebbleAudioArray = [];
        this._getTrebbleAudiosLoadHandler = options.getTrebbleAudiosLoadHandler;
        this._getTrebbleVideosLoadHandler = options.getTrebbleVideosLoadHandler;
        this._videoOnly = options.videoOnly;
        this._marginTop =  options.marginTop;
    },


    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },


    setSelectedTrebbleAudio : function(trebbleAudioSelected){
        this.onTrebbleAudioSelected(trebbleAudioSelected);
    },

    setViewLoading :function(isLoading){
        this.view.isLoading(isLoading);
    },

    _buildInfoBoxParamsForTrebbleAudioListController : function(){
        const params = {};
        params.iconClass = this._videoOnly?"pe-7s-film": "pe-7s-music";
        params.message = window.getI18n(ti18n, this._videoOnly?"NO_VIDEO_UPLOADED": "NO_AUDIO_UPLOADED");
        return params;
    },

    getSelectedTrebbleAudio : function(){
        return this._selectedTrebbleAudioOrVideo;
    },

    getSelectedTrebbleVideo : function(){
        return this.isTrebbleVideoInstance(this._selectedTrebbleAudioOrVideo)? this._selectedTrebbleAudioOrVideo: null;
    },

    _buildTrebbleAudioListController: function() {
        const controllerParams = {};

        controllerParams.customImageUrlProperty = "data-original";
        controllerParams.notTransparent = false;
        controllerParams.canBeSelected = true;
        controllerParams.doNotShowArtistName = !this._videoOnly;
        controllerParams.covertArtNotDisplayed = false;
        controllerParams.showUploadDate = true;
        controllerParams.showVideo = this._videoOnly;
        controllerParams.onTrebbleAudioSelected = this.onTrebbleAudioSelected.bind(this);
        controllerParams.onTrebbleAudioUnselected = this.onTrebbleAudioUnselected.bind(this);
        controllerParams.onTrebbleAudioRequestToBeDeleted = this.onTrebbleAudioRequestToBeDeletedConfirm.bind(this);

        const collectionRepeatControllerParams = {};
        collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildInfoBoxParamsForTrebbleAudioListController();
        collectionRepeatControllerParams.showEmtptyInfoBox = true;
        collectionRepeatControllerParams.controllerOption = controllerParams;
        collectionRepeatControllerParams.itemControllerConstructor = TrebbleAudioListItemController;
        collectionRepeatControllerParams.context = this.context;
        collectionRepeatControllerParams.resizePlaceHolderElem = true;
        collectionRepeatControllerParams.heightMargin = 52 + 39 + 50; //Page Header + Close Button + tab section

        collectionRepeatControllerParams.modelLoadMoreHandler = this._getTrebbleAudiosLoadHandler ? this._getTrebbleAudiosToDisplayWithPaging.bind(this) : this.getDefaultTrebbleAudiosLoadHandler.bind(this);
        collectionRepeatControllerParams.loadOnScroll = true;
        collectionRepeatControllerParams.numberOfResultsPerPage = this._numberOfResultsPerPage? this._numberOfResultsPerPage: 21;

        this.trebbleAudioListController = new CollectionRepeatController(collectionRepeatControllerParams);


    },

    _getTrebbleAudiosToDisplayWithPaging  : function(filter, offset, numberOfItemToLoad){
        return this._getTrebbleAudiosLoadHandler(this._searchWord, offset, numberOfItemToLoad).then((function(trebbleAudioOrVideoModelArray){
            if(trebbleAudioOrVideoModelArray && trebbleAudioOrVideoModelArray.length){
                for(let i =0; i < trebbleAudioOrVideoModelArray.length; i++){
                    const trebbleAudioOrVideoModel = trebbleAudioOrVideoModelArray[i];
                    const categoryId =  trebbleAudioOrVideoModel.getCategoryId();
                    if(this._selectedTrebbleAudioOrVideo && this._selectedTrebbleAudioOrVideo.getAudioId() === trebbleAudioOrVideoModel.getAudioId()){
                        trebbleAudioOrVideoModel.setSelected(true);
                    }
                }
            }
            return trebbleAudioOrVideoModelArray;
        }).bind(this));
    },

    onTrebbleAudioPlaybackStateChange: function(trebbleAudioMode, isPlaying, isLoading){
        this._isBackgroundMusicAudioPreviewPlaying = isPlaying || isLoading;
    },

    isTrebbleVideoInstance : function(trebbleAudioOrVideoModel){
        return trebbleAudioOrVideoModel instanceof TrebbleVideo;
    },

    isBackgroundMusicAudioPreviewPlaying : function(){
        return this._isBackgroundMusicAudioPreviewPlaying
    },

    onTrebbleAudioSelected : function(trebbleAudioOrVideoSelected){
        if(this._selectedTrebbleAudioOrVideo){
            this._selectedTrebbleAudioOrVideo.setSelected(false);
        }
        if(trebbleAudioOrVideoSelected){
            trebbleAudioOrVideoSelected.setSelected(true);
        }
        this._selectedTrebbleAudioOrVideo = trebbleAudioOrVideoSelected;
        if(this._onTrebbleAudioSelected){
            this._onTrebbleAudioSelected(this._selectedTrebbleAudioOrVideo)
        }
        if(this._onTrebbleVideoSelected && this.isTrebbleVideoInstance(this._selectedTrebbleAudioOrVideo)){
            this._onTrebbleVideoSelected(this._selectedTrebbleAudioOrVideo);
        }
    },

    onTrebbleAudioUnselected : function(trebbleAudioOrVideoUnselected){
        trebbleAudioOrVideoUnselected.setSelected(false);
        this._selectedTrebbleAudioOrVideo = null;
        if(this._onTrebbleAudioUnselected){
            this._onTrebbleAudioUnselected(trebbleAudioOrVideoUnselected)
        }
        if(this._onTrebbleAudioUnselected && this.isTrebbleVideoInstance(trebbleAudioOrVideoUnselected)){
            this._onTrebbleAudioUnselected(trebbleAudioOrVideoUnselected);
        }
    },

    
    setScrollContainer : function(scrollContainer){
        this.trebbleAudioListController.setScrollContainer(scrollContainer);
    },

    _onSearchValueChanged : function(searchFieldValue){
        this._searchWord = searchFieldValue;
        if(this.trebbleAudioListController){
            this.trebbleAudioListController.reload();
        }
    },

    _onSearchFieldCleared : function(){
        this.onSearchValueChanged("");
    },

    getDefaultTrebbleAudiosLoadHandler : async function(filter, offset, numberOfItemToLoad){
        if(this._uploadedBackgroundMusicTrebbleAudioArray && this._uploadedBackgroundMusicTrebbleAudioArray.length > 0){
            const startIndex  = numberOfItemToLoad * offset;
            const endIndex = this._uploadedBackgroundMusicTrebbleAudioArray.length >= ((offset+1) * numberOfItemToLoad) ? ((offset+1) * numberOfItemToLoad)  : (this._uploadedBackgroundMusicTrebbleAudioArray.length);
            return RSVP.Promise.resolve(this._uploadedBackgroundMusicTrebbleAudioArray.slice(startIndex, endIndex));
        }else{
            await this.retrieveAndUpdateUserUploadedMedia()
            const startIndex  = numberOfItemToLoad * offset;
            const endIndex = this._uploadedBackgroundMusicTrebbleAudioArray.length >= ((offset+1) * numberOfItemToLoad) ? ((offset+1) * numberOfItemToLoad)  : (this._uploadedBackgroundMusicTrebbleAudioArray.length);
            return this._uploadedBackgroundMusicTrebbleAudioArray.slice(startIndex, endIndex);
        }
    },

    retrieveAndUpdateUserUploadedMedia : async function(){
        const userBackgroundMusicTrebbleAudioArray = this._videoOnly? await TrebbleClientAPIHelper.getInstance().getUserUploadedVideo(): await TrebbleClientAPIHelper.getInstance().getUserUploadedBackgroundMusic()
            this._uploadedBackgroundMusicTrebbleAudioArray = [];
            if(userBackgroundMusicTrebbleAudioArray && userBackgroundMusicTrebbleAudioArray.length){
                for(let i =0; i < userBackgroundMusicTrebbleAudioArray.length; i++){
                    const trebbleAudioJSON = userBackgroundMusicTrebbleAudioArray[i];
                    const trebbleAudioOrVideoModel = trebbleAudioJSON.isVideo?  new TrebbleVideo(trebbleAudioJSON):  new TrebbleAudio(trebbleAudioJSON);
                    this._uploadedBackgroundMusicTrebbleAudioArray.push(trebbleAudioOrVideoModel);
                }
            }
            Utils.getInstance().sortTrebbleAudioModelArray(this._uploadedBackgroundMusicTrebbleAudioArray);
        
    },

    onTrebbleAudioRequestToBeDeleted : function(trebbleAudioOrVideoToBeDeleted){
        return TrebbleClientAPIHelper.getInstance().removeUserUploadedMediaToUserLibrary(trebbleAudioOrVideoToBeDeleted.getAudioId()).then((function(){
            return this.retrieveAndUpdateUserUploadedMedia();
        }).bind(this)).then((function(){
            if(this.trebbleAudioListController){
                this.trebbleAudioListController.reload();
            }
        }).bind(this));
    },

    onTrebbleAudioRequestToBeDeletedConfirm : function(trebbleAudioOrVideoToBeDeleted){
        const buttonLabels = [window.getI18n(ti18n, "NO"), window.getI18n(ti18n, "YES")];
        const confirmCallback = (function(buttonIndex){
            if(buttonIndex ==  2){
                this.onTrebbleAudioRequestToBeDeleted(trebbleAudioOrVideoToBeDeleted);
            }
        }).bind(this);
        const message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_FILE");
        navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n, "DELETE_UPLOAD"), buttonLabels,null , null);
    } ,


    showImportYourOwnMusicPopup : function(){

        const loggedInUserUID = LocalStorageHelper.getInstance().getUserInfo()._id;
        const fileSizeValid = true;
        let fileName = null;
        return new  RSVP.Promise((function(resolve, reject){
            const uploadEementId = "uploadWrapper_"+((new Date()).getTime());
            let nameOfFileToUpload =  this._videoOnly? "userVideo_"+(new Date()).getTime()+".mp4":"userAudio_"+(new Date()).getTime()+".mp3";
            let uploadInstruction = window.isMobileBrowser ? window.getI18n(ti18n, "TAP_HERE_TO_IMPORT_AN_AUDIO_FILE"): window.getI18n(ti18n, "DRAG_AUDIO_FILE_TO_IMPORT");
            if(this._videoOnly){
                uploadInstruction = window.isMobileBrowser ? window.getI18n(ti18n, "TAP_HERE_TO_IMPORT_A_VIDEO_FILE"): window.getI18n(ti18n, "DRAG_VIDEO_FILE_TO_IMPORT");
            }
            const uploadZoneHtml=  "<span class='uploadZonePlaceHolder' id='"+uploadEementId +"'><div class='pe-7s-upload uploadIcon' style='font-size:40px'></div><span  class='dz-message'>"+ uploadInstruction +"</span></span>";
            const buttonLabels = [window.getI18n(ti18n,"CANCEL"), window.getI18n(ti18n,"IMPORT")];
            let dropZoneObj =  null;
            const confirmCallback = (function(buttonIndex){
                if(buttonIndex ==  2){
                    if(dropZoneObj){
                        if(dropZoneObj.getQueuedFiles().length == 0){
                            setTimeout(function(){swal.enableButtons()}, 300);
                        }else{
                            if(fileSizeValid){
                                const fname = dropZoneObj.getQueuedFiles()[0].name;
                                const extension = fname.substr(fname.lastIndexOf("."));
                                nameOfFileToUpload = "userFile_"+(new Date()).getTime()+extension;
                                const signatureFunctionName = this._videoOnly? "getSignatureForTrebbleUserVideoUpload":"getSignatureForTrebbleUserAudioUpload";
                                const fileValidationFunction =  this._videoOnly? FileUploadHelper.getInstance().getVideoFileTypeValidationFunction(): FileUploadHelper.getInstance().getAudioFileTypeValidationFunction();
                                FileUploadHelper.getInstance().validateAndSignFunctionHanlder(dropZoneObj, nameOfFileToUpload,fileValidationFunction, signatureFunctionName ,loggedInUserUID).then(function(){
                                    dropZoneObj.processQueue();
                                }).catch(function(error){
                                    navigator.trebbleNotification.alert(error, null, false, "error");
                                })
                            }

                        }
                    }
                }
            }).bind(this);
            navigator.trebbleNotification.confirm(uploadZoneHtml , confirmCallback, window.getI18n(ti18n,"IMPORT_MUSIC"), buttonLabels, true, null, null, true);
            const handlers = Utils.getInstance().getConfirmDialogDeferredHandlers(resolve, reject, this);
            const onFileAccepted = (function(fileAdded, done){
                if(fileAdded){
                    const file = dropZoneObj.files[0];
                    fileName = file.name
                    const url = URL.createObjectURL(file);
                    done();

                }

            }).bind(this)
            if(this._videoOnly){
                dropZoneObj =  FileUploadHelper.getInstance().uploadUserVideoInWebBrowser(nameOfFileToUpload,"#"+ uploadEementId,handlers.resolve,handlers.reject, onFileAccepted);
            }else{
                dropZoneObj =  FileUploadHelper.getInstance().uploadUserAudioInWebBrowser(nameOfFileToUpload,"#"+ uploadEementId,handlers.resolve,handlers.reject, onFileAccepted);
            }
           

            dropZoneObj.on("error", (function(file, error){
                //Do no throw error on screen as the promise which was to make this call will throw an error as well. This handler should not be remove as the default handler will unhide error message as soon as it is displayed
                setTimeout(function(){
                    window.alertErrorMessage(error);
                }, 500);
            }).bind(this));
        }).bind(this)).then((function(uploadedFileURL){
            if(uploadedFileURL){
                return Utils.getInstance().getMediaMetadata(uploadedFileURL).then((async function(metadata){
                    const {duration} = metadata;
                    return TrebbleClientAPIHelper.getInstance().addUploadedMediaToUserLibrary(fileName, uploadedFileURL, {duration, uri: uploadedFileURL, lastUdpdatedDate: new Date()}, metadata.isVideo, metadata);
                }).bind(this))
            }
        }).bind(this)).then((function(){
            return this.retrieveAndUpdateUserUploadedMedia();
        }).bind(this)).then((function(){
            if(this.trebbleAudioListController){
                this.trebbleAudioListController.reload();
            }
        }).bind(this));
    },

    buildModelAndRenderView : function() {
        this._buildTrebbleAudioListController();
        return this.trebbleAudioListController.buildModelAndRenderView().then((function(){
            const params = {};
            params.trebbleAudioListView = this.trebbleAudioListController.getView();
            params.marginTop = this._marginTop;
            this.view = new ChooseUploadsWidgetView(params);

            this.listenTo(this.view, "keyUpOnSearchField", this._onSearchValueChanged);
            this.listenTo(this.view, "searchFieldCleared", this._onSearchFieldCleared);
            this.listenTo(this.view, "showImportYourOwnMusicPopup",this.showImportYourOwnMusicPopup);


            this.view.render();
        }).bind(this))


    }

});

export default ChooseUploadsWidgetController;