import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Song from "models/Song";
import SoundcloudSearchHelper from "models/helper/SoundcloudSearchHelper";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utilsi18n from "i18n!nls/Utilsi18n";
import YoutubeSearchHelper from "models/helper/YoutubeSearchHelper";
import TrebbleIndexDBHelper from "models/helper/TrebbleIndexDBHelper";
import RSVP from "rsvp";

//THIS SONG COLLECTION WILL USE NOT USE EXISTING SONG MODEL 
//THIS COLLECTION SHOULD ONLY BE USED FOR LOCAL OF FAVORITE SONGS OF THE USER
var YOUTUBE_SOURCE = "YOUTUBE_SOURCE";
var SOUNDCLOUD_SOURCE = "SOUNDCLOUD_SOURCE";
var TREBBLE_SOURCE = "TREBBLE_SOURCE";
var YTSCRemoteSongCollection =  Backbone.Collection.extend({
    model: Song,


    initialize: function (models, options){
        this._sort_key =null;
        this._descending = false;
    },

    url: function() {
        return '/myOwnSongLibrary'; 
    },

    setSource : function(source){
        this._source = source;
    },

    searchOnTitleAndArtist : function(searchFieldValue, resultSize, customFilter, songWithAudioPreviewOnly){
        if(!searchFieldValue){
            searchFieldValue = "";
        }
        if(!resultSize){
            resultSize = 20;
        }
        if(!this._source || this._source == YOUTUBE_SOURCE){
            return YoutubeSearchHelper.getInstance().getSongJsonFromVideos(searchFieldValue.trim(), resultSize).then((function(arrayOfYoutubeVideoInfo){
                var arrayOfYoutubeVideosWithAlbumAndArtistRef = TrebbleIndexDBHelper.getInstance().getSongsWithAlbumAndArtistInfoFromSongJsonArray(arrayOfYoutubeVideoInfo);
                this.reset(arrayOfYoutubeVideosWithAlbumAndArtistRef, {"silent": true, sort : false});
                return this.models;

            }).bind(this));
        }else{
            if(!this._source || this._source == SOUNDCLOUD_SOURCE){
                return SoundcloudSearchHelper.getInstance().searchSongs(searchFieldValue.trim(), resultSize).then((function(arrayOfSoundcloudSongs){
                    var arrayOfSoundcloudSongsWithAlbumAndArtistRef = TrebbleIndexDBHelper.getInstance().getSongsWithAlbumAndArtistInfoFromSongJsonArray(arrayOfSoundcloudSongs);
                    this.reset(arrayOfSoundcloudSongsWithAlbumAndArtistRef,{"silent": true, sort: false});
                    return this.models;
                }).bind(this));
            }else{
                if(this._source || this._source == TREBBLE_SOURCE){
                    return TrebbleClientAPIHelper.getInstance().searchForSongsAlbumsArtists(searchFieldValue.trim(), resultSize, songWithAudioPreviewOnly).then((function(results){
                        results = results? results: {"songs" : [], "albums": [], "artists": [] };
                        this.reset(results.songs,{"silent": true, sort: false});
                        return this.models;

                    }).bind(this));
                }
            }
        }
    },

    getSongsWithURI : function(arrayOfURIs){
        var songModelsFound = [];
        if(arrayOfURIs){
            for(let index in arrayOfURIs){
                var uri = arrayOfURIs[index];
                var songFound = this.get(uri);
                if(songFound){
                    songModelsFound.push(songFound);
                }
            }
        }
        return songModelsFound;

    },

    comparatorOld: function(item) {
        var data = item.get(this._sort_key);
        if(data && ( typeof data == "string" || data instanceof String))
        {

            data = data.trim().toLowerCase();
        }
        if(data && ( data instanceof Date))
        {
            data = data.getTime();
        }
        if(this._descending ){
            return  -data;
        }else{
            return  data;
        }
    },

    getCompareValue : function(item){
        var data = item.get(this._sort_key);
        if(data && ( typeof data == "string" || data instanceof String))
        {

            data = data.trim().toLowerCase();
        }
        if(data && ( data instanceof Date))
        {
            data = data.getTime();
        }

        return  data;
    },

    comparator: function(itemA , itemB) {
        var dataA = this.getCompareValue(itemA);
        var dataB = this.getCompareValue(itemB);

        if(!dataA && dataB){
            if(this._descending){
                return 1;
            }else{
                return -1;
            }
        }
        if(!dataB && dataA){
            if(this._descending){
                return -1;
            }else{
                return 1;
            }
        }
        if (dataA > dataB) {
            if(this._descending){
                return -1;
            }else{
                return 1;
            }
        }
        if (dataB > dataA){
            if(this._descending){
                return 1;
            }else{
                return -1;
            }
        }
        return 0;
        
    },


    sortByField: function(fieldName, descending) {
        if(fieldName){
            if(fieldName == "TITLE_ASCENDING"){
                this._sort_key = "title";
                this._descending = false;
            }else{
                if(fieldName == "TITLE_DESCENDING"){
                    this._sort_key = "title";
                    this._descending = true;
                }else{
                    if(fieldName == "RECENTLY_ADDED"){
                        this._sort_key = "dateAdded";
                        this._descending = true;
                    }else{ 
                        if(fieldName == "FIRST_ADDED"){
                            this._sort_key = "dateAdded";
                            this._descending = false;
                        }else{
                            this._sort_key = fieldName? fieldName : this._sort_key;
                            this._descending = descending?descending :false;
                        }
                    }
                }
            }

            this.sort();
        }
    },

    getSongsAfter : function(songModel) {
        const songIndex = this.indexOf(songModel);
        if (songIndex > -1) {
            const arrayOfSongs = this.slice(songIndex + 1,
                this.length);
            return arrayOfSongs;
        } else {
            window.alertErrorMessage(window.getI18n(Utilsi18n, "SONG_NOT_FOUND_IN_COLLECTION") + songIndex);
            return [];
        }
    },

    getContext : function(){
        return this._context;
    },

    updateContext : function(newContext){
        this._context = newContext;
    },

    save: function(attrs, options){
        window.alertErrorMessage("attrs" + JSON.stringify(attrs));
        window.alertErrorMessage("options" + JSON.stringify(options));

        Backbone.sync('create', this, {
            success: function() {
                window.log('Saved!');
            },
            failure: function(){
                window.alertErrorMessage("savin..6");
            }
        });
    }
});
YTSCRemoteSongCollection.sourceTypes = {"YOUTUBE":YOUTUBE_SOURCE,"SOUNDCLOUD": SOUNDCLOUD_SOURCE, "TREBBLE": TREBBLE_SOURCE};
export default YTSCRemoteSongCollection;