import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import PlaylistPodcastsView from "views/playlistPodcast/PlaylistPodcastsView";
import PlaylistListController from "controllers/common/PlaylistListController";
import PersistentModels from "services/PersistentModels";
import PlayerService from "services/PlayerService";
import FollowersPersonalRadioController from "controllers/playlistPodcast/FollowersPersonalRadioController";
import ti18n from "i18n!nls/PlaylistPodcasti18n";
import MyPersonalRadiosController from "controllers/playlistPodcast/MyPersonalRadiosController";
import RSVP from "rsvp";

const PlaylistPodcastController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if (options) {
            this.playlists = options.playlists;
            this.podcasts = options.podcasts;
            this.model = options;
        }

    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    playRecentFromAll : function(){
        $.mobile.loading( 'show');
        const isMusicCurrentlyPlaying =  PlayerService.getInstance().isMusicPlaying();
        PlayerService.getInstance().playRecentFromAll().then(function(){
            if(!isMusicCurrentlyPlaying)
            {
                PlayerService.getInstance().playFirstSongInQueue();
            }
            $.mobile.loading( "hide" );
        }).catch(function(error){
            window.log("Error playing recent songs from all others. Error:"+ error);
        window.alertErrorMessage(window.getI18n(ti18n,"ERROR_PLAYING_RECENT_SONGS")+ error);
            $.mobile.loading( "hide" );
        });
    },
    
    playRecentlyAddedOrFavoritedByAll : function(){
        $.mobile.loading( 'show', {
          //text: "Generating Playlist ...",
          textVisible: false,
          theme: "t",
          textonly: false,
         // html: html
        });
        const isMusicCurrentlyPlaying =  PlayerService.getInstance().isMusicPlaying();
        PlayerService.getInstance().playRecentlyAddedOrFavoritedByAll().then(function(){
            if(!isMusicCurrentlyPlaying)
            {
                PlayerService.getInstance().playFirstSongInQueue();
            }
            $.mobile.loading( "hide" );
        }).catch(function(error){
            window.log("Error playing recently added or favorited songs by all other users. Error:"+ error);
        window.alertErrorMessage(window.getI18n(ti18n,"ERROR_PLAYING_RECENTLY_ADDED_OR_FAVORITED")+ error);
            $.mobile.loading( "hide" );
        });
    },
    
    _getAutoPlaylistLoadFunction : function(){
        $.mobile.loading( 'show');
        return (function(){
            return  new RSVP.Promise(function(resolve, reject){
                try{
                
                const autoPlaylistSummaryCollection = PersistentModels.getInstance().getAutoPlaylistSummariesCollection();
                $.mobile.loading( "hide" );
                return resolve(autoPlaylistSummaryCollection? autoPlaylistSummaryCollection.toJSON(): []);
            }catch(err){
                $.mobile.loading( "hide" );
                reject(err);
            }
        });
            
        }).bind(this);
    },
    
    onAutoPlaylistSelected : function(playlistSummarySelectedModel){
        PersistentModels.getInstance().getRouter().showMyAutoRadioDetailPage(playlistSummarySelectedModel, true);
    },
    
    
    _onPlaylistPressedHandler : function(playlistSummarySelectedModel){
    window.alertErrorMessage(window.getI18n(ti18n,"PLAY_PREVIEW"));
    },

    getSelectedFilterId : function(){
        return null;
    },
    
    getSelectedSortId : function(){
        return null;
    },
    
    onKeyUpInSearchFieldHandler : function(searchFieldValue){
        if(this.view.isMyTrebblesTabSelected()){
            this._myPersonalRadiosController.filterList(searchFieldValue, this.getSelectedFilterId(), this.getSelectedSortId());
        }else{
            window.setTimeout((function(){
                this._myPersonalRadiosController.filterList(searchFieldValue, this.getSelectedFilterId(), this.getSelectedSortId());
            }).bind(this),0)
        }
        if(this.view.isFollowersTabSelected()){
            this._followersPersonalRadioController.filterList(searchFieldValue, this.getSelectedFilterId(), this.getSelectedSortId());
        }else{
            window.setTimeout((function(){
                this._followersPersonalRadioController.filterList(searchFieldValue, this.getSelectedFilterId(), this.getSelectedSortId());
            }).bind(this),0)
        }
        if(this.view.isMyTrebblotsTabSelected()){
            this._autoPlaylistListController.filterList(searchFieldValue, this.getSelectedFilterId(), this.getSelectedSortId());
        }else{
            window.setTimeout((function(){
                this._autoPlaylistListController.filterList(searchFieldValue, this.getSelectedFilterId(), this.getSelectedSortId());
            }).bind(this),0)
        }
    },
    
    onSearchFieldClearedHandler : function(){
        this.onKeyUpInSearchFieldHandler("");
    },


showHomePopupView : function(){
    PersistentModels.getInstance().getRouter().showHomePage();
},

buildModelAndRenderView : function() {
    return new RSVP.Promise((function(resolve, reject) {
        try {
            const autoPlaylistJsonArray = null;
            const playlistListControllerParams = {};
            playlistListControllerParams.loadHandler =  this._getAutoPlaylistLoadFunction();
            playlistListControllerParams.model  = autoPlaylistJsonArray;
            playlistListControllerParams.resizePlaceHolderElem =true;
            playlistListControllerParams.extendedView = true;
            playlistListControllerParams.onPlaylistSeclectedHandler =  this.onAutoPlaylistSelected.bind(this);
            playlistListControllerParams.onPlaylistPressedHandler =  this._onPlaylistPressedHandler.bind(this);
            
            const autoPlaylistListController = new PlaylistListController(playlistListControllerParams);
            this._autoPlaylistListController = autoPlaylistListController;

            const followersPersonalRadioController =  new FollowersPersonalRadioController();
            this._followersPersonalRadioController = followersPersonalRadioController;
            
            const myPersonalRadiosController = new MyPersonalRadiosController();
            this._myPersonalRadiosController = myPersonalRadiosController;
            
            const promiseArray = [];
            promiseArray.push(this._autoPlaylistListController.buildModelAndRenderView());
            promiseArray.push(this._followersPersonalRadioController.buildModelAndRenderView());
            promiseArray.push(this._myPersonalRadiosController.buildModelAndRenderView());
            

            RSVP.Promise.all(promiseArray).then((function(){
                const myPersonalTrebbleCollection = this._myPersonalRadiosController.getModel();
                PersistentModels.getInstance().setMyPersonalTrebbleCollection(myPersonalTrebbleCollection);
                const myFollowedTrebbleCollection = this._followersPersonalRadioController.getModel();
                PersistentModels.getInstance().setMyFollowedTrebbleCollection(myFollowedTrebbleCollection);
                this._autoPlaylistListExtendedView =  this._autoPlaylistListController.getView();
                this._autoPlaylistListCollection =  this._autoPlaylistListController.getModel();
                
                this._followersPersonalRadioListView =  this._followersPersonalRadioController.getView();
                this._followersPersonalRadioListCollection =  this._followersPersonalRadioController.getModel();
                
                this._myPersonalRadiosListView =  this._myPersonalRadiosController.getView();
                this._myPersonalRadiosListCollection =  this._myPersonalRadiosController.getModel();
        
                this.view = new PlaylistPodcastsView(
                    {"model" : this.model,
                    "autoPlaylistView": this._autoPlaylistListExtendedView,
                    "onKeyUpInSearchFieldHandler" : this.onKeyUpInSearchFieldHandler.bind(this),
                    "onSearchFieldClearedHandler" : this.onSearchFieldClearedHandler.bind(this),
                //			"genrePlaylistView": this._genrePlaylistListView,
                //			"myRadioPlaylistView": this._myRadioPlaylistListView,
                "myFollowersPersonalRadioView": this._followersPersonalRadioListView,
                "myPersonalRadioView": this._myPersonalRadiosListView,
            });
                this.listenTo(this.view, "playRecentFromAll", this.playRecentFromAll);
                this.listenTo(this.view, "playRecentlyAddedOrFavoritedByAll", this.playRecentlyAddedOrFavoritedByAll);
                this.listenTo(this.view, "showHomePopupView", this.showHomePopupView);
                this.view.render().then((function(){
                    resolve();
                }).bind(this));
                
            }).bind(this)).catch(reject);

} catch (error) {
reject(error);
}

}).bind(this));

}

});

export default PlaylistPodcastController;