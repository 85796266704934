import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";


const WasmWavEncoderHelper = Backbone.Model.extend({
    "default" : {
        
    },

    initialize : function() {
        
    },

    encodeWav : function(left_buffer, right_buffer, sample_rate, first_buffer){
        /*return YoutubeBrowserMedia.js('js/libs/wasmWavEncoder/wasm_wav_encoder_bg.wasm').then(function(){
            return wasm_bindgen.export_wav(left_buffer, right_buffer, sample_rate, first_buffer);
        })*/
        
    },

    
});

const wasmWavEncoderHelperInstance = new WasmWavEncoderHelper();

export default {
    getInstance : function() {
        return wasmWavEncoderHelperInstance;
    }
};