import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SongOptionMenuModel from "models/SongOptionMenuModel";
import AlbumsTabContentCollection from "collections/myLibrary/AlbumsTabContentCollection";
import SongOptionMenuView from "views/SongOptionMenuView";
import ti18n from "i18n!nls/MyLibraryi18n";
import PersistentModels from "services/PersistentModels";
import TrebbleListView from "views/common/TrebbleListView";

const AlbumsTabContentView = Backbone.View.extend({


    initialize : function() {
        this.displayedCollection = this.collection;
        this.collection.bind("add", this._onAlbumAddedOrRemovedInLibrary, this);
        this.collection.bind("change", this._onAlbumUpdatedInLibrary, this);
        this.collection.bind("remove", this._onAlbumAddedOrRemovedInLibrary, this);
        this.collection.bind("reset", this._onAlbumAddedOrRemovedInLibrary, this);
            
    
        PersistentModels.getInstance().getRouter().registerForViewportHeightChange("albumTabListView",this._onOrientationChangeFunction.bind(this));
                
    },
    
    _onOrientationChangeFunction : function(newViewPortHeigh, forceResizing) {
        if(PersistentModels.getInstance().getRouter().isEqualToCurrentPageUrl("#mylibrary") || forceResizing){
            if (this.el) {
                const tabHeight = 46;
                this.el.style.height = (newViewPortHeigh - tabHeight) + "px"; 
            }
            this.infiniteListView._onRootElementResize();
        }
    },
    
     filterList: function(searchCharaterers) {
            
            this.appliedSearchCharacters = searchCharaterers;
            const searchFunction = (function() {
                $.mobile.loading("show");
                this.infiniteListView.setUpdating(true);
                this._changeAlbumList(new AlbumsTabContentCollection(this.collection.searchOnAlbumTitleAndArtistname(this.appliedSearchCharacters)));
                this.pendingSearchFunctionTimerId = null;
                $.mobile.loading("hide");
            }).bind(this);
            if (this.pendingSearchFunctionTimerId) 
            {
                window.clearTimeout(this.pendingSearchFunctionTimerId);
            }
            this.pendingSearchFunctionTimerId = window.setTimeout(searchFunction, 1000);
        
        },
        
        clearSearchfilter: function() {
            
            this.appliedSearchCharacters = null;
            const searchFunction = (function() {
                this.infiniteListView.setUpdating(true);
                this._changeAlbumList(new AlbumsTabContentCollection(this.collection.searchOnAlbumTitleAndArtistname("")));
                this.pendingSearchFunctionTimerId = null;
            }).bind(this);
            if (this.pendingSearchFunctionTimerId) 
            {
                window.clearTimeout(this.pendingSearchFunctionTimerId);
            }
            this.pendingSearchFunctionTimerId = window.setTimeout(searchFunction, 0);
        
        },
        
        _getAlbumModelFromAlbumJson : function(albumJson){
            return this.collection.get(albumJson.albumId );
        },


    
        _changeAlbumList: function(newDisplayedAlbumModels) {
            this.displayedCollection = newDisplayedAlbumModels;
            this.displayedCollection.sort();
            this.infiniteListView.updateModels(this.displayedCollection.toJSON());
            this.infiniteListView.setUpdating(false);
            this._onDisplayedCollectionChanged();
        },
        
        _updateAlbumInAlbumList: function(updateAlbumModel) {
            this.infiniteListView.updateModel(updateAlbumModel);
            this.infiniteListView.setUpdating(false);
            this._onDisplayedCollectionChanged();
        },
        

    _getOnListItemTapHandler : function(){
        return  (function(albumJson,evnt, item) {
            this.trigger("itemTaped",this._getAlbumModelFromAlbumJson(albumJson));		
        }).bind(this);
    },
    
    _getOnListItemPressHandler : function(){
        return  (function(albumJson,evnt, item) {
            this.trigger("itemPressed",this._getAlbumModelFromAlbumJson(albumJson));		
        }).bind(this);
    },
    
    _getOnListItemPressUpHandler : function(){
        return  (function(albumJson,evnt, item) {
            this.trigger("itemPressedUp",this._getAlbumModelFromAlbumJson(albumJson));		
        }).bind(this);
    },

    _onItemSwipeRight : function(){
        return  (function(albumJson,evnt, item){
    
            this.trigger("itemSwipedRight",this._getAlbumModelFromAlbumJson(albumJson));
        }).bind(this);
    },
    

    _modelTransformHandlerBeforeTemplatePiping : function(albumJson){

        let artistToDisplay = albumJson.artist;
        let albumToDisplay = albumJson.album;
        if(!artistToDisplay)
        {
            artistToDisplay = "";
        }
        if(!albumToDisplay)
        {
            albumToDisplay = "";
        }
        const templateData = {firstLineText : albumToDisplay, secondLineText : artistToDisplay, "model" : albumJson};
            return templateData;
    },

    _getOnListButtonTappedHandler :function(){
        return  (function(albumJson,evnt, item) {
            this.trigger("itemOptionBtnTapped",this._getAlbumModelFromAlbumJson(albumJson));		
        }).bind(this);
    },
    
    isSearchApplied : function(){
        return this.appliedSearchCharacters != null;
    },
    
    getAppliedSearchCharacters : function(){
        return this.appliedSearchCharacters;
    }, 
    _onDisplayedCollectionChanged :function(){
        this.trigger("modelChange", this.displayedCollection);
    },
    
    _onAlbumAddedOrRemovedInLibrary : function(){
        if(this.isSearchApplied())
        {
            this.filterList(this.getAppliedSearchCharacters());
        }else{
            this.infiniteListView.setUpdating(true);
            this._changeAlbumList(this.collection);
        }
        this.trigger("modelChange", this.displayedCollection);
    },
    
    _onAlbumUpdatedInLibrary : function(albumUpdateModel){
        if(this.isSearchApplied())
        {
            this.filterList(this.getAppliedSearchCharacters());
        }else{
            this.infiniteListView.setUpdating(true);
            this._updateAlbumInAlbumList(albumUpdateModel);
        }
        this.trigger("modelChange", this.displayedCollection);
    },
    
    isCollectionEmpty : function(){
        if(this.displayedCollection)
        {
            return this.displayedCollection.length == 0;
        }else{
            return true;
        }
    },
    
    show : function(){
        this.$el.css("display", "");
    },
    
    hide : function(){
        this.$el.css("display", "none");
    },
    _buildInfoBoxViewParams : function(){
        const params = {};
        params.iconClass = "ion-ios7-albums";
        params.actionButtonIconClass = "fontello-icon-search";
        params.message = window.getI18n(ti18n, "NO_ALBUM");
        params.helpMessage = "";
        params.actionButtonLabel = window.getI18n(ti18n,"SEARCH");
        return params;
    },
    
    _onEmptyInfoBoxButtonPressed : function(){
        this.trigger("infoBoxButtonPressed");
    },
    
    render : function() {
        const tabHeight = 46;
        const contentHeight = PersistentModels.getInstance().getRouter().getViewportHeight() - tabHeight;
        this.$el.html(' <div id="albumListWrapper"  style="height:'+ contentHeight.toString() +'px;overflow:hidden;width:100%;"></div>');
        this.setElement(this.$el.find("div").first());
         const listParams ={};
         this.displayedCollection.sort();
           listParams.model = this.displayedCollection.toJSON();
             listParams.listRootElement = this.el;
             listParams.defaultNumberOfElements = 80;
             listParams.defaultHeightElement = 60;
             listParams.placeHolderHeight = contentHeight;
             listParams.modelIdAttribute  = "albumId";
             listParams.customInfoBoxInfoParams = this._buildInfoBoxViewParams();
             listParams.doNotShowAddToRadioButton = true;
             listParams.doNotShowGradeSongButton = true;
            const infiniteListView =  new TrebbleListView(listParams);
            this.listenTo(infiniteListView, "infoBoxButtonPressed", this._onEmptyInfoBoxButtonPressed);
            this.infiniteListView = infiniteListView;
            infiniteListView.setModelTransformHandlerBeforeTemplatePiping(this._modelTransformHandlerBeforeTemplatePiping);
            infiniteListView.addOnItemTapHandler(this._getOnListItemTapHandler());
            infiniteListView.addOnItemPressHandler(this._getOnListItemPressHandler());
            infiniteListView.addOnItemPressUpHandler(this._getOnListItemPressUpHandler());
            infiniteListView.addOnOptionButtonTappedHandlers(this._getOnListButtonTappedHandler());
            infiniteListView.addOnSwipeRightRevealHandler(this._onItemSwipeRight());
            infiniteListView.render();
            this._onOrientationChangeFunction(PersistentModels.getInstance().getRouter().getViewportHeight(), true);
        return this;
    }

});
export default AlbumsTabContentView;