 



/*
class Limiter {
    constructor(sampleRate, preGain, postGain, attackTime, releaseTime, threshold, lookAheadTime) {
        this.sampleRate = sampleRate || 44100; // Hz
        this.preGain = preGain || 0; // dB
        this.postGain = postGain || 0; // dB
        this.attackTime = attackTime || 0; // s
        this.releaseTime = releaseTime || 3; // s
        this.threshold = threshold || -0.05; // dB
        this.lookAheadTime = lookAheadTime || 0.05; // s
        this.delayBuffer = [];
        this.envelopeSample = 0;
    }

    getEnvelope(data, attackTime, releaseTime, sampleRate) {
        const attackGain = Math.exp(-1 / (sampleRate * attackTime));
        const releaseGain = Math.exp(-1 / (sampleRate * releaseTime));

        const envelope = new Float32Array(data.length);

        for(let i = 0; i < data.length; i++) {
            const envIn = Math.abs(data[i]);

            if(this.envelopeSample < envIn) {
                this.envelopeSample = envIn + attackGain * (this.envelopeSample - envIn);
            } else {
                this.envelopeSample = envIn + releaseGain * (this.envelopeSample - envIn);
            }

            envelope[i] = this.envelopeSample;
        }

        return envelope;
    }

    getMaxEnvelope(envelope, channels, index) {
        const max = envelope[0][index];

        for(let channel = 0; channel < channels; channel++) {
            if(envelope[channel][index] > max) {
                max = envelope[channel][index];
            }
        }

        return max;
    }

    ampToDB(value) {
        return 20 * Math.log10(value);
    }

    dBToAmp(db) {
        return Math.pow(10, db / 20);
    }

    limit(audioProcessingEvent) {
        const inputBuffer = audioProcessingEvent.inputBuffer;
        const outputBuffer = audioProcessingEvent.outputBuffer;
        const envelopeData = [];

        // transform db to amplitude value
        const postGainAmp = this.dBToAmp(this.preGain);
        const preGainAmp = this.dBToAmp(this.preGain);

        // apply pre gain to signal
        // compute the envelope for each channel
        for(let channel = 0; channel < outputBuffer.numberOfChannels; channel++) {
            const inp = inputBuffer.getChannelData(channel);
            const out = outputBuffer.getChannelData(channel);

            // create a delay buffer
            if(this.delayBuffer[channel] == null) {
                this.delayBuffer[channel] = new DelayBuffer(this.lookAheadTime * this.sampleRate);
            }

            // apply pre gain to signal
            for(let k = 0; k < inp.length; ++k) {
                out[k] = preGainAmp * inp[k];
            }

            // compute the envelope
            envelopeData[channel] = this.getEnvelope(out, this.attackTime, this.releaseTime, this.sampleRate, channel);
        }

        for(let channel = 0; channel < outputBuffer.numberOfChannels; channel++) {
            const inp = inputBuffer.getChannelData(channel);
            const out = outputBuffer.getChannelData(channel);

            if(this.lookAheadTime > 0) {
                // write signal into buffer and read delayed signal
                for(let i = 0; i < out.length; i++) {
                    this.delayBuffer[channel].push(out[i]);
                    out[i] = this.delayBuffer[channel].read();
                }
            }

            // limiter mode: slope is 1
            const slope = 1;

            for(let i = 0; i < inp.length; i++) {
                const gainDB = slope * (this.threshold - this.ampToDB(this.getMaxEnvelope(envelopeData, outputBuffer.numberOfChannels, i))); // max gain

                // is gain below zero?
                gainDB = Math.min(0, gainDB);
                const gain = this.dBToAmp(gainDB);
                out[i] *= (gain * postGainAmp);
            }
        }
    }

    reset() {
        for(let i = 0; i < this.delayBuffer.length; i++) {
            if(this.delayBuffer[i] != null) {
                this.delayBuffer[i].reset();
            }
        }

        this.envelopeSample = 0;
    }
}
*/

class DelayBuffer {
    constructor(n) {
        this.n = Math.floor(n);
        this.init();
    }

    init() {
        this._array = new Float32Array(2 * this.n);
        this.length = this._array.length;
        this.readPointer = 0;
        this.writePointer = this.n - 1;

        for(let i = 0; i < this.length; i++) {
            this._array[i] = 0;
        }
    }

    read() {
        const value = this._array[this.readPointer % this.length];
        this.readPointer++;
        return value;
    }

    push(v) {
        this._array[this.writePointer % this.length] = v;
        this.writePointer++;
    }

    reset() {
        this.init();
    }
}



class CompressorWithSidechainProcessor extends AudioWorkletProcessor {

    static get parameterDescriptors() {
        return [
        {
          name: 'sampleRate',
          defaultValue: 44100
      },
      {
          name: 'preGain',
          defaultValue: 0
      },
      {
          name: 'postGain',
          defaultValue: 0
      },
      {
          name: 'attackTime',
          defaultValue: 0
      },
      {
          name: 'releaseTime',
          defaultValue: 3
      },
      {
          name: 'threshold',
          defaultValue: -35
      },
      {
          name: 'knee',
          defaultValue: 0
      },
      {
          name: 'ratio',
          defaultValue: 4
      },
      {
          name: 'lookAheadTime',
          defaultValue: 3
      },
      ];
  }

  delayBuffer = [];
  envelopeSample = 0;

  constructor() { 
    super(); 
    this.delayBuffer = [];
    this.envelopeSample = 0;
}


getEnvelope (data, attackTime, releaseTime, sampleRate) {
    const attackGain = Math.exp(-1 / (sampleRate * attackTime));
    const releaseGain = Math.exp(-1 / (sampleRate * releaseTime));

    const envelope = new Float32Array(data.length);

    for(let i = 0; i < data.length; i++) {
        const envIn = Math.abs(data[i]);

        if(this.envelopeSample < envIn) {
            this.envelopeSample = envIn + attackGain * (this.envelopeSample - envIn);
        } else {
            this.envelopeSample = envIn + releaseGain * (this.envelopeSample - envIn);
        }

        envelope[i] = this.envelopeSample;
    }

    return envelope;
}

getMaxEnvelope(envelope, channels, index) {
    let max = envelope[0][index];

    for(let channel = 0; channel < channels; channel++) {
        if(envelope[channel][index] > max) {
            max = envelope[channel][index];
        }
    }

    return max;
}

ampToDB(value) {
    return 20 * Math.log10(value);
}

dBToAmp(db) {
    return Math.pow(10, db / 20);
}


reset() {
    for(let i = 0; i < this.delayBuffer.length; i++) {
        if(this.delayBuffer[i] != null) {
            this.delayBuffer[i].reset();
        }
    }

    this.envelopeSample = 0;
}

static getMatchingBufferChannelData (buffer, otherBufferNumberOfChannels, channelNumber){
    if(buffer.length > channelNumber){
        return buffer[channelNumber];
    }else{
        return buffer[Math.ceil((channelNumber + 1) * buffer.length/otherBufferNumberOfChannels) - 1];
    }
}


process (inputs, outputs, parameters) {

    const sampleRate = parameters.sampleRate[0] || 44100; // Hz
    const preGain = parameters.preGain[0] || 0; // dB
    const postGain = parameters.postGain[0] || 0; // dB
    const attackTime = parameters.attackTime[0] || 0; // s
    const releaseTime = parameters.releaseTime[0] || 3; // s
    const ratio = parameters.ratio[0] || 4; // dB
    const knee = parameters.knee[0] || 0; // dB
    const threshold = parameters.threshold[0] || -30; // dB
    const lookAheadTime = parameters.lookAheadTime[0] || 0; // s
    const isInLimiterMode = parameters.mode == "limiter";



    const inputBufferToModulate = inputs[0];
    const inputBufferSourceToMonitor = inputs[1];
    //const inputBuffer = audioProcessingEvent.inputBuffer;
    const outputBuffer = outputs[0];
    const envelopeDataForAllChannels = [];

    // transform db to amplitude value
    const postGainAmp = this.dBToAmp(postGain);
    const preGainAmp = this.dBToAmp(preGain);
    let noInputToModulate = true;

    // apply pre gain to signal
    // compute the envelope for each channel
    for(let channel = 0; channel < outputBuffer.length; channel++) {
        const inpToModulate = CompressorWithSidechainProcessor.getMatchingBufferChannelData(inputBufferToModulate, outputBuffer.length, channel);
        const inpToMonitor = CompressorWithSidechainProcessor.getMatchingBufferChannelData(inputBufferSourceToMonitor, outputBuffer.length, channel);
        const out = outputBuffer[channel];
        const dataForEnveloppeCalculation = new Float32Array(out.length);

        // create a delay buffer
        if(this.delayBuffer[channel] == null) {
            this.delayBuffer[channel] = new DelayBuffer(lookAheadTime * sampleRate);
        }

        // apply pre gain to signal
        if(inpToMonitor){
            for(let k = 0; k < inpToMonitor.length; ++k) {
                dataForEnveloppeCalculation[k] = preGainAmp * inpToMonitor[k];
            }
        }

        // apply pre gain to signal to modulate
        if(inpToModulate){
            noInputToModulate = false;
            for(let k = 0; k < inpToModulate.length; ++k) {
                out[k] = preGainAmp * inpToModulate[k];

            }
        }

        // compute the envelope
        envelopeDataForAllChannels[channel] = this.getEnvelope(dataForEnveloppeCalculation, attackTime, releaseTime, sampleRate, channel);
    }



    for(let channel = 0; channel < outputBuffer.length; channel++) {
       const inpToMonitor = CompressorWithSidechainProcessor.getMatchingBufferChannelData(inputBufferSourceToMonitor, outputBuffer.length, channel);
       const out = outputBuffer[channel];

       if(lookAheadTime > 0) {
        // write signal into buffer and read delayed signal
        for(let i = 0; i < out.length; i++) {
            this.delayBuffer[channel].push(out[i]);
            out[i] = this.delayBuffer[channel].read();
        }
    }

    const kneeWidth = threshold * knee * -1;
    const lowerKneeBound = threshold - (kneeWidth/2);
    const upperKneeBound = threshold + (kneeWidth/2);
    // limiter mode: slope is 1
    let slope = isInLimiterMode? 1: ( 1- (1/ratio));
    let gainDB;
    if(inpToMonitor){
        for(let i = 0; i < inpToMonitor.length; i++) {
            const envValueDB = this.ampToDB(this.getMaxEnvelope(envelopeDataForAllChannels, outputBuffer.length, i));
            if(!isInLimiterMode && (kneeWidth > 0 && envValueDB > lowerKneeBound && envValueDB < upperKneeBound)){
               slope *= ( ((envValueDB - lowerKneeBound) / kneeWidth) * 0.5 );
               gainDB = slope * (lowerKneeBound - envValueDB);
           }else{
            gainDB = slope * (threshold - envValueDB); // max gain
            // is gain below zero?
            gainDB = Math.min(0, gainDB);
        }

        const gain = this.dBToAmp(gainDB);
        out[i] *= (gain * postGainAmp);
    }
}
}


return !noInputToModulate;
}

}

registerProcessor('compressor-with-sidechain', CompressorWithSidechainProcessor)









