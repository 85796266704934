//NOT_USED_ANYMORE
import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import DynamicContentView from "views/common/DynamicContentView";
import ListItemCollection from "collections/ListItemCollection";
//import ChartlistAsync from "libs/chartlist/chartist.min";
import RSVP from "rsvp";
import moment from 'moment';
import {BarChart, Interpolation, Svg}  from 'chartist';
import Swiper from 'swiper';

const SEVEN_DAYS_STATS_KEY = "7daysStats";
const FOURTEEN_DAYS_STATS_KEY = "14daysStats";
const THIRTY_DAYS_STATS_KEY = "30daysStats";
const TWELVE_MONTHS_STATS_KEY = "12MonthsStats";
const dataTypeToLabel = {"load": "Trebble Plays",
//"listeningTime": "Total Listening Time",
"capsulePlayed": "Capsule Plays",
"capsuleSkipped": "Capsule Skips",
"gainedFollowers": "New Subscribers",
"lostFollowers": "Lost Subscribers",
"firstTimeListeners": "First Time Listeners",
"songPlayed": "Song Plays",
"songSkipped": "Song Skips",
"songPickedByListeners":"Song Picks",
"comments": "Comments"
};// First time listeners should be in the same graph as trebble plays
const dataTypeToFontIcon = {"load": "ion-ios7-play-outline",
//"listeningTime": "ion-ios7-play-outline",
"capsulePlayed": "ion-ios7-play-outline",
"capsuleSkipped": "ion-ios7-skipforward-outline",
"songPlayed": "ion-ios7-play-outline",
"songSkipped": "ion-ios7-skipforward-outline",
"songPickedByListeners":"ion-ios7-plus-outline",
"comments": "ion-ios7-chatbubble-outline",
"gainedFollowers": "ion-ios7-personadd-outline",
"lostFollowers": "ion-ios7-person-outline",
"firstTimeListeners": "ion-ios7-play-outline"};
const dataTypeWithBarChart = [];//["load","listeningTime","songPlayed","songSkipped","capsulePlayed","capsuleSkipped","songPickedByListeners","comments","gainedFollowers","lostFollowers","firstTimeListeners"];//["listeningTime"];
const dataInSecondsToBeConverted = ["listeningTime"];
const OWNER_COUNT_ALWAYS_DISPLAYED  = true;
const arrayOfDataWhereOwnerCountIsNotApplicable = ["gainedFollowers","lostFollowers","firstTimeListeners"];
const TrebbleStatisticsContentView = DynamicContentView.extend({


    initialize : function(options) {
        this._trebbleStats = options.trebbleStats;
        this._periodSelectorView = options.periodSelectorView;
        this._statsGraphData = null;
        this._statsTotalCount = null;
        this._trebbleId = options.trebbleId;
        this._selectedTimePeriodKey =null;
        this._collectionContainerId =  "trebbleStatisticCollectionWrapper" + (new Date()).getTime();
        this._getStatisticsLoadFunction = options.getStatisticsLoadFunction;
        this._chartElementsArray =[];
        DynamicContentView.prototype.initialize.call(this, {"canBeAppendedBeforeLoad": true});
    },
    
    buildDaysStatsData : function(){
        if(!this._statsGraphData){
            this._statsGraphData = {};
            this._statsTotalCount = {};
        }
        if(this._trebbleStats){
            for(let dataType in this._trebbleStats){
                const data = this._trebbleStats[dataType];
                const generalSeries = [];
                const ownerSeries =[];
                let totalCountSevenDays = 0;
                let totalCountFourteenDays = 0;
                let totalCountThirtyDays = 0;
                let ownerCountWasProvided = false || OWNER_COUNT_ALWAYS_DISPLAYED;
                if(arrayOfDataWhereOwnerCountIsNotApplicable.indexOf(dataType) != -1){
                    ownerCountWasProvided = false;
                }
                const sevenDaysGraphStats = {"labels": [],"series":[]};
                const fourteenDaysGraphStats = {"labels": [],"series":[]};
                const thirtyDaysGraphStats = {"labels": [],"series":[]};
                if(data.last30Days){
                    for(let i in data.last30Days){
                        const dailyData = data.last30Days[i];
                        const m = moment(new Date(dailyData.time)).utc();
                        let generalCount = null;
                        if("count" in dailyData){
                            generalCount = dailyData.count;
                        }else{
                            if("total" in dailyData){
                                generalCount = dailyData.total;
                            }
                        }
                        if(!generalCount){
                            generalCount =0;
                        }
                        generalSeries.push(generalCount);
                        let ownerCount = null;
                        /*if(i == 0 && ("ownerCount" in dailyData || "ownerTotal" in dailyData)){
                            ownerCountWasProvided = true;
                        }*/
                        if( "ownerCount" in dailyData){								
                            ownerCount = dailyData.ownerCount;
                        }else{
                            if("ownerTotal" in dailyData){
                                ownerCount = dailyData.ownerTotal;
                            }
                        }
                        if(ownerCountWasProvided && !ownerCount){
                            ownerCount = 0;
                            
                        }
                        if(ownerCountWasProvided){
                            ownerSeries.push(ownerCount);
                        }
                        if(i < 7){
                            sevenDaysGraphStats.labels.push(m.format("ddd"));
                            totalCountSevenDays = totalCountSevenDays + generalCount + ownerCount;
                        }
                        if(i < 14){
                            if(i%2 == 1 && ((window.device && window.device.platform != "browser") || window.isMobileBrowser)){
                                fourteenDaysGraphStats.labels.push(" ");
                            }else{
                                fourteenDaysGraphStats.labels.push(m.format("MMM DD"));
                            }
                            totalCountFourteenDays = totalCountFourteenDays + generalCount + ownerCount;
                        }
                        if(i < 30){
                            if(i%3 != 0 && ((window.device && window.device.platform != "browser") || window.isMobileBrowser)){
                                thirtyDaysGraphStats.labels.push(" ");
                            }else{
                                thirtyDaysGraphStats.labels.push(m.format("MMM DD"));
                            }
                            totalCountThirtyDays = totalCountThirtyDays + generalCount+ ownerCount;
                        }
                        
                        

                    }
                    sevenDaysGraphStats.labels.reverse();
                    const sevenDaySeries = generalSeries.slice(0, sevenDaysGraphStats.labels.length);
                    sevenDaySeries.reverse();
                    sevenDaysGraphStats.series.push(sevenDaySeries);
                    if(ownerCountWasProvided){
                        const sevenDayOwnerSeries = ownerSeries.slice(0, sevenDaysGraphStats.labels.length);
                        sevenDayOwnerSeries.reverse();
                        sevenDaysGraphStats.series.push(sevenDayOwnerSeries);
                    }
                    
                    fourteenDaysGraphStats.labels.reverse();
                    const fourteenDaySeries = generalSeries.slice(0, fourteenDaysGraphStats.labels.length);
                    fourteenDaySeries.reverse();
                    fourteenDaysGraphStats.series.push(fourteenDaySeries);
                    if(ownerCountWasProvided){
                        const fourteenDayOwnerSeries = ownerSeries.slice(0, fourteenDaysGraphStats.labels.length);
                        fourteenDayOwnerSeries.reverse();
                        fourteenDaysGraphStats.series.push(fourteenDayOwnerSeries);
                    }
                    
                    thirtyDaysGraphStats.labels.reverse();
                    const thirtyDaySeries = generalSeries.slice(0, thirtyDaysGraphStats.labels.length);
                    thirtyDaySeries.reverse();
                    thirtyDaysGraphStats.series.push(thirtyDaySeries);
                    if(ownerCountWasProvided){
                        const thirtyDayOwnerSeries = ownerSeries.slice(0, thirtyDaysGraphStats.labels.length);
                        thirtyDayOwnerSeries.reverse();
                        thirtyDaysGraphStats.series.push(thirtyDayOwnerSeries);
                    }
                }
                if(!this._statsGraphData[dataType]){
                    this._statsGraphData[dataType] = {};
                    this._statsTotalCount[dataType] = {}
                    
                } 
                this._statsGraphData[dataType][SEVEN_DAYS_STATS_KEY] = sevenDaysGraphStats;
                this._statsGraphData[dataType][FOURTEEN_DAYS_STATS_KEY] = fourteenDaysGraphStats;
                this._statsGraphData[dataType][THIRTY_DAYS_STATS_KEY] = thirtyDaysGraphStats;
                this._statsTotalCount[dataType][SEVEN_DAYS_STATS_KEY] = totalCountSevenDays;
                this._statsTotalCount[dataType][FOURTEEN_DAYS_STATS_KEY] = totalCountFourteenDays;
                this._statsTotalCount[dataType][THIRTY_DAYS_STATS_KEY] = totalCountThirtyDays;
            }
        }
    },
    
    getAllSupportedTinePeriods : function(){
        const listItemCollection = new ListItemCollection();
        listItemCollection.add(Utils.getInstance().getListItemModel(SEVEN_DAYS_STATS_KEY,  "Last 7 Days"));
        listItemCollection.add(Utils.getInstance().getListItemModel(FOURTEEN_DAYS_STATS_KEY,  "Last 14 Days"));
        listItemCollection.add(Utils.getInstance().getListItemModel(THIRTY_DAYS_STATS_KEY,  "Last 30 Days"));
        listItemCollection.add(Utils.getInstance().getListItemModel(TWELVE_MONTHS_STATS_KEY,  "Last 12 Months"));
        
        return RSVP.Promise.resolve(listItemCollection.models);

    },
    
    buildMonthStatsData : function(){
        if(!this._statsGraphData){
            this._statsGraphData = {};
            this._statsTotalCount = {};
        }
        if(this._trebbleStats){
            for(let dataType in this._trebbleStats){
                const data = this._trebbleStats[dataType];
                const generalSeries = [];
                const ownerSeries =[];
                let totalCount12Months = 0;
                let ownerCountWasProvided = false || OWNER_COUNT_ALWAYS_DISPLAYED;
                if(arrayOfDataWhereOwnerCountIsNotApplicable.indexOf(dataType) != -1){
                    ownerCountWasProvided = false;
                }
                const twelveMonthsGraphStats = {"labels": [],"series":[]};
                if(data.last12Months){
                    for(let i in data.last12Months){
                        const monthData = data.last12Months[i];
                        const m = moment(new Date(monthData.time)).utc();
                        twelveMonthsGraphStats.labels.push(m.format("MMM YY"));
                        let generalCount = null;
                        
                        if("count" in monthData){
                            generalCount = monthData.count;
                        }else{
                            if("total" in monthData){
                                generalCount = monthData.total;
                            }
                        }

                        if(!generalCount){
                            generalCount = 0;
                        }
                        let ownerCount = null;
                        /*if(i == 0 && ("ownerCount" in monthData || "ownerTotal" in monthData)){
                            ownerCountWasProvided = true;
                        }*/
                        if( "ownerCount" in monthData){
                            ownerCount = monthData.ownerCount;
                        }else{
                            if("ownerTotal" in monthData){
                                ownerCount = monthData.ownerTotal;
                            }
                        }
                        if(ownerCountWasProvided && !ownerCount){
                            ownerCount = 0;
                            
                        }
                        if(ownerCountWasProvided){
                            ownerSeries.push(ownerCount);
                        }
                        totalCount12Months = totalCount12Months + generalCount + ownerCount;
                        generalSeries.push(generalCount);
                        
                    }
                    twelveMonthsGraphStats.labels.reverse();
                    const twelveMonthSeries = generalSeries.slice(0, twelveMonthsGraphStats.labels.length);
                    twelveMonthSeries.reverse();
                    twelveMonthsGraphStats.series.push(twelveMonthSeries);
                    if(ownerCountWasProvided){
                        const twelveMonthOwnerSeries = ownerSeries.slice(0, twelveMonthsGraphStats.labels.length);
                        twelveMonthOwnerSeries.reverse();
                        twelveMonthsGraphStats.series.push(twelveMonthOwnerSeries);
                    }

                }
                if(!this._statsGraphData[dataType]){
                    this._statsGraphData[dataType] = {};
                    this._statsTotalCount[dataType] = {};
                } 
                this._statsGraphData[dataType][TWELVE_MONTHS_STATS_KEY] = twelveMonthsGraphStats;
                this._statsTotalCount[dataType][TWELVE_MONTHS_STATS_KEY] = totalCount12Months;
            }
        }
    },
    
    
    load : function(){
        $.mobile.loading("show");
        try{
            if(this._trebbleStats){
                this.buildDaysStatsData();
                this.buildMonthStatsData();
                this._initView();
                $.mobile.loading("hide");
                return RSVP.Promise.resolve();
            }else{
                $.mobile.loading("show");
                return this._getStatisticsLoadFunction(this._trebbleId).then((function(trebbleStats){
                    $.mobile.loading("show");
                    this._trebbleStats = trebbleStats;
                    this.buildDaysStatsData();
                    this.buildMonthStatsData();
                    this._initView();
                    $.mobile.loading("hide");
                }).bind(this)).catch((function(error){
                    $.mobile.loading("hide");
                    throw error;
                }).bind(this));
            }
        }catch(error){
            $.mobile.loading("hide");
            return RSVP.Promise.reject(error);
        }
    },
    
    _destroyCurrentDisplayedStatisticsView : function(){
        if(this._tabSwiperObj){
            this._tabSwiperObj.destroy();
            this.swiperTabContainer$el.remove();
            this._tabSwiperObj = null;
            this.swiperTabContainer$el =  null;
        }
        if(this._statsSwiperObj){
            this._statsSwiperObj.destroy();
            this.swiperStatsContainer$el.remove();
            this._tabSwiperObj = null;
            this.swiperStatsContainer$el =  null;
        }
        for(let index in this._chartElementsArray){
            const chartElement = this._chartElementsArray[index];
            chartElement.detach();
        }
        this._chartElementsArray = [];
    },
    
    setStatisticsPeriod : function(statisticPeriod){
        
        this._selectedTimePeriodKey = statisticPeriod;
        if(this._initialized){
            this._destroyCurrentDisplayedStatisticsView();
            if(this._selectedTimePeriodKey ){
                this._swiperStatsContainerId = "trebbleStatisticSwiperContainer" + (new Date()).getTime();
                this._swiperTabContainerId = "trebbleTabSwiperContainer" + (new Date()).getTime();
                this.collectionContainer$el.append("<div id='"+this._swiperTabContainerId+"'><div class='"+ "swiper-wrapper"+"'></div></div><div id='"+this._swiperStatsContainerId+"'><div class='"+ "swiper-wrapper"+"'> </div>"+ this._buildNavigationButtons()+"</div>");
                this.swiperStatsContainer$el = this.$el.find("#"+this._swiperStatsContainerId);
                this.swiperTabContainer$el = this.$el.find("#"+this._swiperTabContainerId);
                this.swiperWrapperStatsContainer$el = this.swiperStatsContainer$el.find(".swiper-wrapper");
                this.swiperWrapperTabContainer$el = this.swiperTabContainer$el.find(".swiper-wrapper");
                
                for(let dataType in dataTypeToLabel){
                    const allGraphData  = this._statsGraphData[dataType];
                    const graphData = allGraphData?allGraphData[this._selectedTimePeriodKey] : null;
                    if(graphData ){
                        this.swiperWrapperTabContainer$el.append($(this._buildHtmlTabForDataType(dataType)));
                        const chartUniqueElementId  = "chart_"+dataType+"_id_"+(new Date().getTime());
                        this.swiperWrapperStatsContainer$el.append($(this._buildChartWrapperHtml(chartUniqueElementId)));
                        this._buildChartHtml("#"+chartUniqueElementId, graphData,dataType, dataTypeToLabel[dataType] );
                    }
                    
                }
                const chartSwiperParams  =  {
                    speed: window.isMobileBrowser?200: 300,
                    longSwipesRatio : window.isMobileBrowser?0.2: 0.4,
                    watchSlidesProgress: true,
                    preloadImages: false,
                    watchSlidesVisibility: true,
                    slidesPerView: 1,
                    centeredSlides: true,
                    paginationHide: true,
                    slideToClickedSlide: true,
                    lazyLoading: true,
                    lazyLoadingInPrevNext: true,
                    lazy: {
                        loadPrevNext: true
                    }
                };
                if(window.device && window.device.platform =="browser" && !window.isMobileBrowser){
                    chartSwiperParams.nextButton = '.swiper-button-next';
                    chartSwiperParams.prevButton = '.swiper-button-prev';
                    chartSwiperParams.navigation = {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    };
                    chartSwiperParams.keyboardControl = true;
                    chartSwiperParams.mousewheelControl = true;
                    chartSwiperParams.mousewheel =  {
                        enabled: true
                    };
                    chartSwiperParams.keyboard =  {
                        enabled: true,
                        onlyInViewport: false
                    };

                }
                this._statsSwiperObj = new Swiper("#"+this._swiperStatsContainerId,chartSwiperParams);
                const tabSwiperParams  =  {
                    speed: window.isMobileBrowser?200: 300,
                    longSwipesRatio : window.isMobileBrowser?0.2: 0.4,
                    watchSlidesProgress: true,
                    preloadImages: false,
                    watchSlidesVisibility: true,
                    slidesPerView: "auto",
                    centeredSlides: true,
                    paginationHide: true,
                    slideToClickedSlide: true,
                    controller : {
                        control : this._statsSwiperObj
                    },
                    lazyLoading: true,
                    lazyLoadingInPrevNext: true,
                    lazy: {
                        loadPrevNext: true
                    }
                };
                if(window.device && window.device.platform =="browser" && !window.isMobileBrowser){
                    //tabSwiperParams.keyboardControl = true;
                    //tabSwiperParams.mousewheelControl = true;
                }
                this._tabSwiperObj = new Swiper("#"+this._swiperTabContainerId,tabSwiperParams);
                this._tabSwiperObj.params.control = this._statsSwiperObj;
                this._statsSwiperObj.params.control = this._tabSwiperObj;
            }
        }
    },

    getDurationStringFromTimeInSeconds : function(timeInSeconds, shortLabel){
        const m = moment.duration(timeInSeconds * 1000);

        const mDays = m.asDays();
        const mHours = m.asHours();
        const mMinutes = m.asMinutes();
        const mSeconds = m.asSeconds();
        if(mDays >= 1){
            if(shortLabel){
                return mDays.toFixed(2) + "d";
            }else{
                return mDays.toFixed(2) + " Days";
            }
        }else{
            if(mHours >= 1){
                if(shortLabel){
                    return mHours.toFixed(2) + "h";
                }else{
                    return mHours.toFixed(2) + " Hours";
                }
                
            }else{
                if(mMinutes >= 1){
                    if(shortLabel){
                        return mMinutes.toFixed(2) + "m";
                    }else{
                        return mMinutes.toFixed(2) + " Min";
                    }
                    
                }else{
                    if(shortLabel){
                        return mSeconds.toFixed(2) + "s";
                    }else{
                        return mSeconds.toFixed(2) + " Sec";
                    }
                    
                }
            }
        }
    },
    
    _buildHtmlTabForDataType : function(dataType){
        if(dataTypeWithBarChart.indexOf(dataType) > -1){
            return "<div class='slideItemWrapper swiper-slide' style='width:auto;'><div class='statsTabInfo'><div class='totalCount'>"+ this.getDurationStringFromTimeInSeconds(this._statsTotalCount[dataType][this._selectedTimePeriodKey] ) +" </div><div class='tabLabel'><span class='"+ dataTypeToFontIcon[dataType] +"'></span>"+dataTypeToLabel[dataType] +"</div></div></div>";
        }else{
            if(dataInSecondsToBeConverted.indexOf(dataType) > -1){
                return "<div class='slideItemWrapper swiper-slide' style='width:auto;'><div class='statsTabInfo'><div class='totalCount'>"+this.getDurationStringFromTimeInSeconds(this._statsTotalCount[dataType][this._selectedTimePeriodKey] ) +"</div><div class='tabLabel'><span class='"+ dataTypeToFontIcon[dataType] +"'></span>"+dataTypeToLabel[dataType] +"</div></div></div>";
            }else{
                return "<div class='slideItemWrapper swiper-slide' style='width:auto;'><div class='statsTabInfo'><div class='totalCount'>"+this._statsTotalCount[dataType][this._selectedTimePeriodKey]+"</div><div class='tabLabel'><span class='"+ dataTypeToFontIcon[dataType] +"'></span>"+dataTypeToLabel[dataType] +"</div></div></div>";
            }
        }
    },
    
    _buildChartWrapperHtml : function(chartId){
        return "<div class='slideItemWrapper swiper-slide' style='width:100%;'><div class='statsWrapper' id='"+chartId +"' ></div></div>";
    },
    
    _buildChartHtml : function(chartContainerSelector, chartData, dataType, label){
        let chartObj  = null;
        let isBarChar = false;
        let isDataInSecondsToBeConverted = false;
        let labelInterpolationFncY =  null;
        const scaleMinSpaceInPx = (window.device && window.device.platform == "browser" && !window.isMobileBrowser)? 50: 25;
        if(dataTypeWithBarChart.indexOf(dataType) > -1){
            isBarChar = true;
            labelInterpolationFncY = (function(value){
                return this.getDurationStringFromTimeInSeconds(value, true);
            }).bind(this);
            isDataInSecondsToBeConverted = true;
            chartObj = new BarChart(chartContainerSelector, chartData, {stackBars: true,"height": window.getDocumentClienHeight() - (80 +80 +60) +"px",fullWidth: true, showArea: true,lineSmooth: Interpolation.none(), axisY: { onlyInteger: true, labelInterpolationFnc: labelInterpolationFncY, scaleMinSpace : scaleMinSpaceInPx}, axisX : {scaleMinSpace : scaleMinSpaceInPx}});
        }else{
            if(dataInSecondsToBeConverted.indexOf(dataType) > -1){
                isDataInSecondsToBeConverted = true;
                labelInterpolationFncY = (function(value){
                    return this.getDurationStringFromTimeInSeconds(value, true);
                }).bind(this);
            }
            const axisYConfig =  { onlyInteger: true, scaleMinSpace : scaleMinSpaceInPx };
            if(labelInterpolationFncY){
                axisYConfig.labelInterpolationFnc = labelInterpolationFncY;
            }
            const axisXConfig = { scaleMinSpace : scaleMinSpaceInPx };
            isBarChar = true;
            chartObj = new BarChart(chartContainerSelector, chartData, {stackBars: true,"height": window.getDocumentClienHeight() - (80 +80 +60) +"px",fullWidth: true, showArea: true,lineSmooth: Interpolation.none(), axisY: axisYConfig , axisX : axisXConfig});
        }
        chartObj.on('draw', function(data) {
            if(data.type === 'line' || data.type === 'area') {
                data.element.animate({
                    d: {
                        begin: 1000 * data.index,
                        dur: 1000,
                        from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
                        to: data.path.clone().stringify(),
                        easing: Svg.Easing.easeOutQuint
                    }
                });
            }
        });
        const selectorClass = isBarChar? ".ct-bar":'.ct-point';
        const $chart =  $(chartContainerSelector);
        this._chartElementsArray.push(chartObj);
        const $toolTip = $chart.append('<div class="tooltip"></div>').find('.tooltip').hide();

        const f = this.getDurationStringFromTimeInSeconds;
        $chart.on('mouseenter', selectorClass, function() {
            let $point = $(this),
            value = $point.attr('ct:value'),
            seriesName = label;
            if(!value){
                value = 0;
            }
            if(isDataInSecondsToBeConverted){
                value = f(value);
            }
            // $toolTip.html(seriesName + '<br>' + value).show();
            $toolTip.html(value + " " +seriesName ).show();
        });


        $chart.on('mouseleave',selectorClass, function() {
            $toolTip.hide();
        });

        $chart.on('mousemove', function(event) {
            $toolTip.css({
                left: (event.offsetX || event.originalEvent.layerX) - $toolTip.width() / 2 - 10,
                top: (event.offsetY || event.originalEvent.layerY) - $toolTip.height() - 40
            });
        });
    },
    
    _initView : function(){
        if(!this._initialized){
            this._initialized = true;
            this.setStatisticsPeriod(this._selectedTimePeriodKey);
        }
    },
    
    _buildNavigationButtons : function(){
        if(((window.device && window.device.platform != "browser") || window.isMobileBrowser)){
            return  "";
        }else{
            return      '<div class="swiper-button-next ion-ios7-arrow-right"></div> <div class="swiper-button-prev ion-ios7-arrow-left"></div>';
        }
    },

    _buildChartLegend : function(){
        return '<div id="chartLegend"><div class="legenItem" ><span>My Listeners</span><div class="legendColor" style="background-color: white;"></div></div><div class="legenItem"><span>Me</span><div class="legendColor" style="background-color: rgba(255,255,255,0.5);"></div></div></div>';
    },


    events : {


    },


    
    render : function() {
        this.$el.append("<div  id='"+this._collectionContainerId+"'><div id='chartGeneralInfoWrapper'></div></div>");
        this.collectionContainer$el = this.$el.find("#"+this._collectionContainerId);
        this.chartGeneralInfoWrapper$el = this.$el.find("#chartGeneralInfoWrapper");
        if(this._periodSelectorView){
            this.chartGeneralInfoWrapper$el.append(this._periodSelectorView.$el);
            this.chartGeneralInfoWrapper$el.append($(this._buildChartLegend()));
        }
        return this;
    }

});
TrebbleStatisticsContentView.DEFAULT_TIME_PERIOD_KEY = SEVEN_DAYS_STATS_KEY;
TrebbleStatisticsContentView.DEFAULT_TIME_PERIOD_LABEL = "Last 7 Days";
export default TrebbleStatisticsContentView;