import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import PersistentModels from "services/PersistentModels";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlaylistHelper from "models/helper/PlaylistHelper";
import Utils from "models/helper/Utils";
import PlayerService from "services/PlayerService";
import ti18n from "i18n!nls/PlaylistPodcasti18n";
import PlaylistListController from "controllers/common/PlaylistListController";
import RSVP from "rsvp";

const MyPersonalRadiosController = Chaplin.Controller.extend({
    
    
    initialize: function(options) {
        _.extend(this, Backbone.Events);
        if (options) {
            
        }
        this._radioSummaryModelArray = null;
        this._idToRadioSummaryModelMap = {};
        this.listenTo(PersistentModels.getInstance(),"songsRemovedToRadios", this._onSongsRemovedToRadios.bind(this));
        this.listenTo(PersistentModels.getInstance(), "songsAddedToRadios", this._onSongsAddedToRadios.bind(this));
        
    },
    
    getModel: function() {
        return this.model;
    },
    
    getView: function() {
        return this.view;
    },

    _onSongsRemovedToRadios : function(radioIdToNumberOfSongRemoved){
        if(radioIdToNumberOfSongRemoved){
            for(let radioId in radioIdToNumberOfSongRemoved){
                const numberOfSongRemoved  = radioIdToNumberOfSongRemoved[radioId];
                const playlistSummaryModel = this._idToRadioSummaryModelMap[radioId];
                if(playlistSummaryModel){
                    let newNumberOfSongs = playlistSummaryModel.getNumberOfSongs() - numberOfSongRemoved;
                    if(newNumberOfSongs < 0){
                        newNumberOfSongs = 0;
                    }
                    playlistSummaryModel.setNumberOfSongs(newNumberOfSongs);
                }
            }
        }
    },
    
    _onSongsAddedToRadios : function(radioIdToNumberOfSongAdded){
        if(radioIdToNumberOfSongAdded){
            for(let radioId in radioIdToNumberOfSongAdded){
                const numberOfSongAdded  = radioIdToNumberOfSongAdded[radioId];
                const playlistSummaryModel = this._idToRadioSummaryModelMap[radioId];
                if(playlistSummaryModel){
                    playlistSummaryModel.setNumberOfSongs(playlistSummaryModel.getNumberOfSongs() + numberOfSongAdded);
                }
            }
        }

    },


    _getMyPersonalRadioLoadFunction : function(){
        $.mobile.loading( 'show');
        return (function(){
            return TrebbleClientAPIHelper.getInstance().getMyPersonalRadios().then((function(myPersonalRadiosArray){
                $.mobile.loading( 'hide');
                this._radioSummaryModelArray = [];
                if(myPersonalRadiosArray){
                    this._myPersonalRadioSummaryModelArray = [];
                    for(let i= 0; i<myPersonalRadiosArray.length; i++){
                        const playlistSummary = Utils.getInstance().buildPlaylistSummaryFromJson(myPersonalRadiosArray[i]);
                        this._radioSummaryModelArray.push(playlistSummary);
                        this._idToRadioSummaryModelMap[playlistSummary.getLoaderKey()] = playlistSummary;
                    }
                }
                return this._radioSummaryModelArray;
            }).bind(this)).catch((function(error){
                $.mobile.loading( 'hide');
                window.alertErrorMessage(error);
            }).bind(this));
        }).bind(this);
    },
    
    _onPersonalRadioSelected : function(playlistSummarySelectedModel){
        PersistentModels.getInstance().getRouter().showMyPersonalRadioDetailPage(playlistSummarySelectedModel, true);
    },

    _onPersonalRadioEditButtonClicked : function(playlistSummarySelectedModel){
        PersistentModels.getInstance().getRouter().showTrebbleInfoEditPage(playlistSummarySelectedModel, true);
    },

    _onPersonalRadioShareButtonClicked : function(playlistSummarySelectedModel){
        PersistentModels.getInstance().getRouter().showTrebbleSharePopup(playlistSummarySelectedModel, true);
    },
    
    _onPlaylistPressedHandler : function(playlistSummarySelectedModel){
        if(playlistSummarySelectedModel){
            const audioPreviewUrl = playlistSummarySelectedModel.getAudioPreviewUrl();
            if(audioPreviewUrl){
                PersistentModels.getInstance().getPlayer().playAudioPreview(audioPreviewUrl);
            }else{
                Utils.getInstance().showShortToastMessage(window.getI18n(ti18n,"NO_PREVIEW_YET_AVAILEBLE_PREVIEW_ARE_AUTOMATICALLY_CREATED"));
            }
        }
    },

    _onPlaylistPlayButtonTapedHandler : function(playlistSummarySelectedModel){
        if(PersistentModels.getInstance().getPlayer().isPlaylistCurrentlyLoaded(playlistSummarySelectedModel)){
            if(PersistentModels.getInstance().getPlayer().isPlaying()){
                PersistentModels.getInstance().getPlayer().pauseAudio();
            }else{
                PersistentModels.getInstance().getPlayer().playAudio();
            }
        }else{
            if(PlaylistHelper.getInstance().isTrebblePlayable(playlistSummarySelectedModel) || (window._capsuleCreatedInThisSession && playlistSummarySelectedModel.canBeEdited())){//The second clause here is more of a around where if the user created a capsule in the current user session, we will try to play his trebble anyway. We are simply doing this because the numberof capsules on Trebble is not updated until the user refreshes. this is a bug

                $.mobile.loading( 'show');
                let playlistFound = null;
                PlaylistHelper.getInstance().getPersonalUserRadioPlaylistById(playlistSummarySelectedModel).then((function(playlist){
                    playlistFound = playlist;
                    return PersistentModels.getInstance().getPlayer().setPlaylist(playlist, true);
                }).bind(this)).then((function(){
                    $.mobile.loading( 'hide');
                    
                }).bind(this)).catch((function(error){
                    $.mobile.loading( 'hide');
                    if(PersistentModels.getInstance().getPlayer().isNoContentToPlayError(error)){
                        this._onPlaylistNotBeingAbleToPlay(playlistSummarySelectedModel);
                        PersistentModels.getInstance().getPlayer().clearQueue();
                    }else{
                        console.error("Coudn't load playlist.Error:"+ error);
                        window.alertErrorMessage(window.getI18n(ti18n,"COULDNT_LOAD_PLAYLIST")+ error);
                    }
                }).bind(this));
            }else{
                this._onPlaylistNotBeingAbleToPlay(playlistSummarySelectedModel);
                PersistentModels.getInstance().getPlayer().clearQueue();
            }
        }
        
    },

    _onPlaylistNotBeingAbleToPlay : function(playlistSummarySelectedModel){
        if(playlistSummarySelectedModel.canBeEdited()){
            //const buttonLabels = ["Play Demo Trebble","Add Songs Now"];
            //const buttonLabels = ["Add Music", "Create Capsule"];
            const buttonLabels = [window.getI18n(ti18n,"OK"), window.getI18n(ti18n,"CREATE_CAPSULE")];
            const confirmCallback = (function(buttonIndex){
                if(buttonIndex ==  2){
                    setTimeout((function(){
                        PersistentModels.getInstance().getRouter().showCreateAShortcastPageToPublishToChannel(null);
                    }).bind(this), 100);

                }else{

                }
            }).bind(this);
            const minimumOfSongsNeeded  =  Utils.getInstance().getDefaultNumberOfSongsRequiredToPlayATrebble();
            const message = window.getI18n(ti18n,"TREBBLE_HAS_NO_CONTENT");
            const subMessage = window.getI18n(ti18n,"YOU_CAN_ADD_CONTENT_BY_CREATING_CAPSULES");
            navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n,"NEED_TO_HAVE_MINIMUM_OF_SONGS"), buttonLabels, null, null, subMessage);
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("onboarding", "Discover Mininum Song Number For Trebble", "Discover Mininum Song Number For Trebble");
            } 
        }else{
            navigator.trebbleNotification.alert(window.getI18n(ti18n,"THIS_TREBBLE_HAS_NO_ACTIVE_CAPSULE"), null, false);
        }
    },
    
    
    _onPlaylistPressedUpHandler : function(playlistSummarySelectedModel){
        PersistentModels.getInstance().getPlayer().terminateAudioPreviewAndResumePlayback();
    },
    
    
    _onMoreButtonForPlaylistSeclectedHandler  : function(playlistSummarySelectedModel){
        PersistentModels.getInstance().getRouter().showMyPersonalRadioMenuOption(playlistSummarySelectedModel, true);
    },
    

filterList: function(searchCharaterers, filterOptions, sortOptions){
if(this._myPersonalRadioSummaryController){
this._myPersonalRadioSummaryController.filterList(searchCharaterers,null, filterOptions, sortOptions);
}
},

clearSearchfilter : function(){
if(this._myPersonalRadioSummaryController){
this._myPersonalRadioSummaryController.clearSearchfilter();
}
},

getCustomInfoBoxParams : function(){
const params = {};
params.iconClass = "pe-7s-radio";
params.message = window.getI18n(ti18n,"NO_TREBBLE");
params.helpMessage = window.getI18n(ti18n,"THIS_IS_WHERE_YOU_WILL_SEE_THE_LIST_OF_YOUR_TREBBLES");
return params;
},

getCustomEmptyInfoBoxParams : function(){
const params = {};
params.iconClass = "pe-7s-radio";
params.message = window.getI18n(ti18n, "NO_TREBBLE_FOUND");
params.helpMessage = window.getI18n(ti18n, "PLEASE_TRY_A_DIFFERENT_SEARCH_KEYWORD");
return params;
},

buildModelAndRenderView : function() {
return new RSVP.Promise((function(resolve, reject) {
try {
    const autoPlaylistJsonArray = null;
    const myPersonalRadioSummaryControllerParams = {};
    const tabHeight = 46;
    const subTabHeight = 44;
    myPersonalRadioSummaryControllerParams.loadHandler =  this._getMyPersonalRadioLoadFunction();
    myPersonalRadioSummaryControllerParams.model  = null;
    myPersonalRadioSummaryControllerParams.resizePlaceHolderElem =false;
    myPersonalRadioSummaryControllerParams.extendedView = true;
    myPersonalRadioSummaryControllerParams.onPlaylistSeclectedHandler =  this._onPersonalRadioSelected.bind(this);
    myPersonalRadioSummaryControllerParams.onPlaylistPressedHandler =  this._onPlaylistPressedHandler.bind(this);
    myPersonalRadioSummaryControllerParams.onPlaylistPressedUpHandler =  this._onPlaylistPressedUpHandler.bind(this);
    myPersonalRadioSummaryControllerParams.onPlaylistPlayButtonTapedHandler = this._onPlaylistPlayButtonTapedHandler.bind(this);
    myPersonalRadioSummaryControllerParams.onPlaylistEditButtonTapedHandler = this._onPersonalRadioEditButtonClicked.bind(this);
    myPersonalRadioSummaryControllerParams.onPlaylistShareButtonTapedHandler = this._onPersonalRadioShareButtonClicked.bind(this);
    myPersonalRadioSummaryControllerParams.customInfoBoxParams = this.getCustomEmptyInfoBoxParams();
    myPersonalRadioSummaryControllerParams.customEmptyInfoBoxParams = this.getCustomEmptyInfoBoxParams();
    
    myPersonalRadioSummaryControllerParams.heightMargin = tabHeight + subTabHeight  ;
    myPersonalRadioSummaryControllerParams.onMoreButtonForPlaylistSeclectedHandler =  this._onMoreButtonForPlaylistSeclectedHandler.bind(this);
    const myPersonalRadioSummaryController = new PlaylistListController(myPersonalRadioSummaryControllerParams);
    this._myPersonalRadioSummaryController = myPersonalRadioSummaryController;
    
    
    
    const promiseArray = [];
    promiseArray.push(this._myPersonalRadioSummaryController.buildModelAndRenderView());

    RSVP.Promise.all(promiseArray).then((function(){
        this.view =  this._myPersonalRadioSummaryController.getView();
        this.model =  this._myPersonalRadioSummaryController.getModel();

        
        resolve();
    }).bind(this)).catch(reject);
    
} catch (error) {
    reject(error);
}

}).bind(this));

}

});


export default MyPersonalRadiosController;
