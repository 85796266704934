import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import InfoBoxView from "views/common/InfoBoxView";
import ti18n from "i18n!nls/Commoni18n";
import defaultlistItemTemplate from 'text!../../../templates/common/playlistItemTemplate.html';
import defaultlistItemTemplate3X from 'text!../../../templates/common/playlistItemTemplate3X.html';
import infiniteListAdapter from 'collections/savewrapper/infiniteListView2';

const trebblePlaylistListView = Backbone.View.extend({


    initialize : function(options) {
        this.defaultTemplate =  _.template(defaultlistItemTemplate,null, {"variable": "model"});
        this.defaultTemplate3X =  _.template(defaultlistItemTemplate3X,null, {"variable": "model"});
        
        this.defaultOverrideSingleItemStyle = {};
        
        this.defaultOverrideMainContainerStyle = {};
        
        this.onItemTapHandlers = [];
        this.onItemTapEditHandlers = [];
        this.onItemTapShareHandlers = [];
        this.onItemTapPlayHandlers = [];
        this.onItemPressHandlers = [];
        this.onItemPressUpHandlers = [];
        this.onSwipeRightRevealHandlers = [];
        this.onOptionButtonTappedHandlers = [];
        this.swipeRightRevealEnabled = false,
        this.beforeTemplateHandler = null;
        this.itemViewUpdateDuringScrollingHandler = null;
        this._showOnePlaylistPerLine =  options.showOnePlaylistPerLine;
        this.model = options.model;
        this.listRootElement =  options.listRootElement;
        this._modelIdAttribute = options.modelIdAttribute ? options.modelIdAttribute : null ;
        this.defaultNumberOfElements = options.defaultNumberOfElements;
        this.defaultHeightElement = options.defaultHeightElement?options.defaultHeightElement : 170;
        this.resizePlaceHolderElem = options.resizePlaceHolderElem ? options.resizePlaceHolderElem : false ;
        this.placeHolderHeight = options.placeHolderHeight ? options.placeHolderHeight : null ;
        this._container$elForImageLazyLoad = options.container$elForImageLazyLoad ? options.container$elForImageLazyLoad: null;
        this._itemSlideLimit = 0.8;
        this._numberOfOptionsOnPanRight = 2;
        this._numberOfOptionsOnPanLeft = 2;
        this._optionBoxWidth =100;
        this._itemRightOptionConfig = this._buildItemRightOption();
        this._itemLeftOptionConfig = this._buildItemLeftOption();
        this.itemOptionWidth = 100;
        this._optionButtonWith = 50
        this._modelWithPressedEventOnElement = [];
        this._modelWithLastPressedUpEventOnElement = [];
        this._timestampWithLastPressedUpEventOnElement = [];
        this._doNotInitializeLazyLoadingAtRender= options.doNotInitializeLazyLoadingAtRender? options.doNotInitializeLazyLoadingAtRender:false; 
        
        this._infoBoxInfoParams  = options.customInfoBoxParams? options.customInfoBoxParams: this._buildDefaultCustomBoxParams(); 
        this._customInfoBoxInfo  = options.customInfoBoxInfo? options.customInfoBoxInfo: null;
        this._showEmtptyInfoBox = (options.showEmtptyInfoBox == false)? false: true;
        this._emptyInfoBox = null;
        this._emptyInfoBoxInfoParams  = options.customEmptyInfoBoxParams? options.customEmptyInfoBoxParams: this._buildDefaultCustomEmptyBoxParams(); 
        //this.onSwipeRightRevealHandlers.push(this._getOnSwipeRightAnimationHandler());
    },
    _buildItemRightOption : function(){
        const optionConfig = {};
        optionConfig[0] = {"optionLabel" : "..."};
        optionConfig[1] = {"optionLabel" : window.getI18n(ti18n, "ADD_TO_MY_TREBBLE"),"name": "addToQueue"};//"<i class='fontello-icon-list-add'></i>","name": "addToQueue"};// "Add To Queue"};
        optionConfig[2] = {"optionLabel" : window.getI18n(ti18n, "PLAY_SIMILAR_IN_LIBRARY"),"name": "playSimilarInLibrary"};//"<i class='ion-radio-waves'></i>","name": "playSimilarInLibrary"};//: "Play Similar In Library"};
        return optionConfig;
    },
    
    _buildItemLeftOption : function(){
        const optionConfig = {};
        optionConfig[0] = {"optionLabel" : "..."};
        optionConfig[1] = {"optionLabel": window.getI18n(ti18n, "SHOW_OPTIONS"),"name": "showOptions"};//"<i class='fontello-icon-list-add'></i>"};// "Add To Queue"};
        optionConfig[2] = {"optionLabel": window.getI18n(ti18n, "DELETE"),"name": "delete"};//"<i class='ion-radio-waves'></i>"};// : "Play Similar In Library"};
        return optionConfig;
    },
    
    _buildDefaultCustomBoxParams : function(){
        const params = {};
        params.iconClass = "pe-7s-radio";
        params.message = window.getI18n(ti18n, "NO_SONG");
        params.helpMessage = null;
        return params;
    },
    _buildDefaultCustomEmptyBoxParams : function(){
        const params = {};
        params.iconClass = "pe-7s-radio";
        params.message = window.getI18n(ti18n, "NO_SONG_FOUND");
        params.helpMessage = window.getI18n(ti18n, "PLEASE_TRY_DIFFERENT_KEYWORDS");
        return params;
    },
    
    _onDefaultInfoboxButtonCalled : function(){
        this.trigger("infoBoxButtonPressed");
    },

    
    _getOnSwipeRightAnimationHandler : function(){
        return   (function(model,evnt, item) {
            if(this.swipeRightRevealEnabled){
                item.querySelector(".songLibraryWrapper > .playlistItem").style["-webkit-animation"] = "songGettingAddedPlaylistAnimation1 800ms ease forwards";		
            }		
        }).bind(this);
    },
    _onDragStart : function(){
        
            return  (function(model,evnt, item){
                //if(!this.infiniteList.isScrolling()){
                    const libElem = item.querySelector(".songLibraryWrapper > .playlistItem");
                    const optionElem = item.querySelector("#songAddToQueueTooltip > div:first-child");
                    libElem._clientWidth = item.clientWidth;
                    item._optionWrapperWidth = optionElem.clientWidth;
                    libElem._isDraggingRight = false;
                    libElem._isDraggingLeft = false;
                //}
            }).bind(this);
        
    },
    
    _onDragRight : function(){
        
            return  (function(model,evnt, item){
                //if(!this.infiniteList.isScrolling()){
                    const libElem = item.querySelector(".songLibraryWrapper > .playlistItem");
                    if(!libElem._isDraggingRight && !libElem._isDraggingLeft)
                    {
                        libElem._isDraggingRight = true;
                        libElem._isDraggingLeft = false;
                        const optionWrapperDiv$el = $(item.querySelector("#songAddToQueueTooltip"));
                        optionWrapperDiv$el.addClass("optionOnPanRight");
                    }
                    if(libElem._isDraggingLeft || (libElem._isDraggingRight && this._numberOfOptionsOnPanRight > 0))
                    {
                        libElem.style["-webkit-transition"] = "";//"-webkit-transform .3s";
                        if(evnt.deltaX <= libElem._clientWidth *this._itemSlideLimit )
                        {
                            //do nothing
                            libElem.style["-webkit-transform"] = "translateX("+ (evnt.deltaX).toString() +"px)";
                            const optionElem = item.querySelector("#songAddToQueueTooltip > div:first-child");
                            const dragDirection= libElem._isDraggingLeft ? "left" : "right";
                            if(dragDirection == "right")
                            {
                                optionElem.style["-webkit-transform"] = "translateX("+ (evnt.deltaX - item._optionWrapperWidth).toString() +"px)";
                            }else{
                                optionElem.style["-webkit-transform"] = "translateX("+ (evnt.deltaX + item._optionWrapperWidth - this._optionButtonWith).toString() +"px)";
                            }
                            
                            libElem._dragXPosition=evnt.deltaX;
                            
                            this._updateOptionStateIfApplicable(item, evnt, dragDirection);
                        }
            
                    }else{
                        //do not pan
                    }
                //}
            }).bind(this);
    
},

_onDragLeft : function(){
    
        return  (function(model,evnt, item){
            //if(!this.infiniteList.isScrolling()){
                const libElem = item.querySelector(".songLibraryWrapper > .playlistItem");
                if(!libElem._isDraggingRight && !libElem._isDraggingLeft)
                {
                    libElem._isDraggingRight = false;
                    libElem._isDraggingLeft = true;
                    const optionWrapperDiv$el = $(item.querySelector("#songAddToQueueTooltip"));
                    optionWrapperDiv$el.addClass("optionOnPanLeft");
                }
                if(libElem._isDraggingRight || (libElem._isDraggingLeft && this._numberOfOptionsOnPanLeft > 0))
                {
                    libElem.style["-webkit-transition"] = "";//"-webkit-transform .3s";
                    if(evnt.deltaX <= libElem._clientWidth *this._itemSlideLimit )
                    {
                        //do nothing
                        libElem.style["-webkit-transform"] = "translateX("+ (evnt.deltaX).toString() +"px)";
                        const optionElem = item.querySelector("#songAddToQueueTooltip > div:first-child");
                        const dragDirection= libElem._isDraggingLeft ? "left" : "right";
                        if(dragDirection == "right")
                        {
                            optionElem.style["-webkit-transform"] = "translateX("+ (evnt.deltaX - item._optionWrapperWidth).toString() +"px)";
                        }else{
                            optionElem.style["-webkit-transform"] = "translateX("+ (evnt.deltaX + item._optionWrapperWidth - this._optionButtonWith).toString() +"px)";
                        }
                        libElem._dragXPosition=evnt.deltaX;
    
                        this._updateOptionStateIfApplicable(item, evnt, dragDirection);
                    }
                }else{
                    //do not pan
                }
            //}
        }).bind(this);
    
},

_updateOptionStateIfApplicable : function(item, evnt, dragDirection){
    const xDragPosition =  evnt.deltaX;
    let numberOfPossibleOptions = null;
    if(dragDirection == "left" && this._numberOfOptionsOnPanLeft> 0){
        numberOfPossibleOptions = this._numberOfOptionsOnPanLeft + 1;
        const libElem = item.querySelector(".songLibraryWrapper > .playlistItem");
        const newOptionLevel = Math.floor(Math.abs(xDragPosition * numberOfPossibleOptions/(libElem._clientWidth * this._itemSlideLimit)));
        if(item._optionLevel != newOptionLevel){
            //need to update optionLevel
            const optionWrapperDiv$el = $(item.querySelector("#songAddToQueueTooltip"));
            const optionElem = item.querySelector("#songAddToQueueTooltip > div:first-child");
            optionElem.innerHTML = (this._itemLeftOptionConfig &&  this._itemLeftOptionConfig[newOptionLevel])? this._itemLeftOptionConfig[newOptionLevel].optionLabel : "";

            optionWrapperDiv$el.removeClass(this._getClassnameForOptionLevel(item._optionLevel));
            optionWrapperDiv$el.addClass(this._getClassnameForOptionLevel(newOptionLevel));
            item._optionLevel = newOptionLevel;
            item._optionWrapperWidth = optionElem.clientWidth;
        }
    }
    if(dragDirection == "right" && this._numberOfOptionsOnPanRight> 0){
        numberOfPossibleOptions = this._numberOfOptionsOnPanRight + 1;
        const libElem = item.querySelector(".songLibraryWrapper > .playlistItem");
        const newOptionLevel = Math.floor(Math.abs(xDragPosition * numberOfPossibleOptions/(libElem._clientWidth * this._itemSlideLimit)));
        if(item._optionLevel != newOptionLevel){
            //need to update optionLevel
            const optionWrapperDiv$el = $(item.querySelector("#songAddToQueueTooltip"));
            const optionElem = item.querySelector("#songAddToQueueTooltip > div:first-child");
        
            optionElem.innerHTML = (this._itemRightOptionConfig &&  this._itemRightOptionConfig[newOptionLevel])? this._itemRightOptionConfig[newOptionLevel].optionLabel : "";
        
            optionWrapperDiv$el.removeClass(this._getClassnameForOptionLevel(item._optionLevel));
            optionWrapperDiv$el.addClass(this._getClassnameForOptionLevel(newOptionLevel));
            item._optionLevel = newOptionLevel;
            item._optionWrapperWidth = optionElem.clientWidth;
        }
    }
    
    
},

_getClassnameForOptionLevel : function(optionLevel){
    return "optionLevel_"+optionLevel;
},

_getMaxDragXPosition : function(dragDirection, optionLevel, itemMaxWidth, itemSlideLimit, numberOfPossibleOptions){
    return (itemMaxWidth * itemSlideLimit *(optionLevel +1))/(numberOfPossibleOptions+1);
},

_onRelease: function(){
    
        return  (function(model,evnt, item){
        //	if(!this.infiniteList.isScrolling()){
                 // more then 50% moved, navigate
                const libElem = item.querySelector(".songLibraryWrapper > .playlistItem");
                const optionElem = item.querySelector("#songAddToQueueTooltip > div:first-child");
              //  if(Math.abs(libElem._dragXPosition) > libElem._clientWidth * (this._itemSlideLimit /(this._numberOfOptionsOnPanRight + 1))) {
                if(item._optionLevel  && item._optionLevel  > 0)
                {            	
                    item._isOptionSelected = true;
                    let onCancellationPeriodEnd =  function(){
                        const optionWrapperDiv = item.querySelector("#songAddToQueueTooltip");
                        const optionWrapperDiv$el = $(optionWrapperDiv);
    
                        const animationEndListener = this._onReleaseAnimationEndFunction({"self":this, "itemElem":item});
                        optionWrapperDiv.addEventListener("webkitTransitionEnd", animationEndListener, false);
                        libElem._animationEndListener = animationEndListener;
                        if(item._isOptionSelected)
                        {
                            const dragDirection= libElem._isDraggingLeft ? "left" : "right";
                            let maxNumberOfPossibleOptions = null;
                            if(libElem._isDraggingLeft ){
                                maxNumberOfPossibleOptions= this._numberOfOptionsOnPanLeft;
                            }else{
                                maxNumberOfPossibleOptions= this._numberOfOptionsOnPanRight;
                            }
                            let dragPosForLibElem = (this._getMaxDragXPosition(dragDirection,item._optionLevel, libElem._clientWidth,this._itemSlideLimit, maxNumberOfPossibleOptions))-10;
                            if(dragDirection != "right")
                            {
                                dragPosForLibElem = -dragPosForLibElem;
                            }
                            let dragPosForOptionElem = null;
                            if(dragDirection == "right")
                            {
                                dragPosForOptionElem =  (dragPosForLibElem - item._optionWrapperWidth);
                            }else{
                                dragPosForOptionElem =  (dragPosForLibElem + item._optionWrapperWidth - this._optionButtonWith);
                            }
                            optionWrapperDiv$el.addClass("optionSelected");
                            libElem.style["-webkit-transform"] = "translateX("+ (dragPosForLibElem).toString() +"px)";
                            optionElem.style["-webkit-transform"] = "translateX("+ (dragPosForOptionElem).toString() +"px)";
                        }
                    
    
                    };
                    onCancellationPeriodEnd = onCancellationPeriodEnd.bind(this);
                    window.setTimeout(onCancellationPeriodEnd, 0);
                }else {
                    //libElem.style["-webkit-transform"] = "translateX(0px)";
                    libElem._dragXPosition = 0;
                    item._optionLevel = null;
                    item._isOptionSelected = false;
                    libElem._clientWidth = null;
                    libElem._isDraggingRight = false;
                    libElem._isDraggingLeft = false;
                    libElem.style["-webkit-animation"] = "";
                    optionElem.style["-webkit-animation"] = "";
                    libElem.style["-webkit-transform"] = "";
                    optionElem.style["-webkit-transform"] = "";
                    const optionWrapperDiv$el = $(item.querySelector("#songAddToQueueTooltip"));
                    optionWrapperDiv$el.removeClass("optionOnPanLeft");
                    optionWrapperDiv$el.removeClass("optionOnPanRight");
                }
            //}
        }).bind(this);
    
},
_onReleaseAnimationEndFunction : function(context){
    return  (function(evnt){
        //if(evnt.animationName == "optionLevel_1Selected" || evnt.animationName == "optionLevel_2Selected")

            const libElem = this.itemElem.querySelector(".songLibraryWrapper > .playlistItem");
            const libElem$el = $(libElem);
            const optionWrapperDiv = this.itemElem.querySelector("#songAddToQueueTooltip");
            const optionWrapperDiv$el = $(optionWrapperDiv);
            const optionElem = this.itemElem.querySelector("#songAddToQueueTooltip > div:first-child");
            const optionElem$el = $(optionElem);
            const animationCompleted = optionElem$el.hasClass("animateToZeroXPosition") || libElem$el.hasClass("animateToZeroXPosition");
            window.log("Animation COmpleted. :"+animationCompleted);
            if(animationCompleted){
                
                
                libElem.style["-webkit-animation"] = "";
                optionElem.style["-webkit-animation"] = "";
                libElem.style["-webkit-transform"] = "";
                optionElem.style["-webkit-transform"] = "";
                optionWrapperDiv$el.removeClass("optionSelected");
                libElem$el.removeClass("animateToZeroXPosition");
                optionElem$el.removeClass("animateToZeroXPosition");
                optionWrapperDiv.removeEventListener("webkitTransitionEnd", libElem._animationEndListener);
                optionWrapperDiv$el.removeClass(this.self._getClassnameForOptionLevel(this.itemElem._optionLevel))
                optionWrapperDiv$el.removeClass("optionOnPanLeft");
                optionWrapperDiv$el.removeClass("optionOnPanRight");
                window.log("ccccc");
                this.itemElem._optionLevel = null;
                if(this.itemElem._isOptionSelected){
                    //const optionWrapperDiv$el = $(this.itemElem.querySelector("#songAddToQueueTooltip"));	
                    //optionWrapperDiv$el.removeClass("optionSelected")
                    this.itemElem._isOptionSelected = false;
                }
                libElem._clientWidth = this.itemElem.clientWidth;
                libElem._isDraggingRight = false;
                libElem._isDraggingLeft = false;
                
            }else{
                window.log("aaaaa");
                const ff = function(){
                const dragDirection= libElem._isDraggingLeft ? "left" : "right";
                const optionLevel = this.itemElem._optionLevel;
                this.self.trigger("optionSelected",[dragDirection,optionLevel])
                  libElem$el.addClass("animateToZeroXPosition");
                  window.log("bbb");
                  optionElem$el.addClass("animateToZeroXPosition");
                  optionElem.style["-webkit-transform"] = "";
                  libElem.style["-webkit-transform"] = "";
                };
                setTimeout(ff.bind(this),500);
                  
            }

    }).bind(context);
},
    
    
    _getInfiniteListTemplateFuntion :function(){
        return  (function(model)
                  {
                    let templateData;
                    if(this.beforeTemplateHandler)
                    {
                        templateData = this.beforeTemplateHandler(model);
                    }else{
                        templateData = model;//{firstLineText : model.firstLineText, secondLineText : model.secondLineText, "model" : model};
                    }
                    if(window.device && window.device.platform == "browser" && !window.isMobileBrowser && !this._showOnePlaylistPerLine){
                        return this.defaultTemplate3X(templateData);
                    }else{
                        return this.defaultTemplate(templateData);
                    }
                    
                  }).bind(this);
    },
        
    _onListItemUpdateHandlerFunction :function(){ 
        return (function(elemRoot,models){
            //NB render template need to be wrap in an one element
            const isArrayParameter =(models instanceof Array)? true: false;
            const modelArray =(models instanceof Array)?models: [models];
            const templateDataArray = [];
            for(let index  = 0 ; index < modelArray.length;index++ ){
                const model =  modelArray[index];
                let templateData;
                if(this.beforeTemplateHandler)
                {
                    templateData = this.beforeTemplateHandler(model);
                }else{
                    templateData = model;//{firstLineText : model.firstLineText, secondLineText : model.secondLineText, "model" : model};
                }
                if(index < elemRoot.children.length ){
                    // use existing render object and update it since it is faster than rerendering the entire model
                    const elem = elemRoot.children[index];
                    if(this.itemViewUpdateDuringScrollingHandler)
                    {
                        this.itemViewUpdateDuringScrollingHandler(elem,templateData);
                    }else{
                        this._getInternalItemViewUpdateDuringScrollingHandler(elem,templateData);
                    }
                }else{
                    //no root node so just render the entire model and add it to the innerHtml raw
                    if(window.device && window.device.platform == "browser" && !window.isMobileBrowser){
                        elemRoot.innerHTML = elemRoot.innerHTML +  this.defaultTemplate3X(templateData);
                    }else{
                        elemRoot.innerHTML = elemRoot.innerHTML +  this.defaultTemplate(templateData);
                    }
                    
                }
            }
            if( modelArray.length < elemRoot.children.length){
                const nodeToDeletes = [];
                for(let index = modelArray.length;  index < elemRoot.children.length; index ++){
                    nodeToDeletes.push(elemRoot.children[index]);
                }
                for(let index = 0; index < nodeToDeletes.length;  index++ ){
                    //deleting unsued nodes
                    const nodeToDelete = nodeToDeletes[index];
                    nodeToDelete.parentNode.removeChild(nodeToDelete);
                }
                
            }
        }).bind(this);
    },
    
    _getInternalItemViewUpdateDuringScrollingHandler : function(elem,templateData){
        try{
            const coverArtImageElem = elem.querySelector("#coverArtImage");
            coverArtImageElem.src = window.getResourceURLAccordingToLocationProtocol("/img/videoLoading.png");
            coverArtImageElem.setAttribute("data-original", templateData.coverArtUrl);
            if(!coverArtImageElem.className ||  coverArtImageElem.className.indexOf("lazy") == -1){
                coverArtImageElem.className = (!coverArtImageElem.className)? "lazy" :  coverArtImageElem.className +" lazy";
            }
            const playpauseBtnElem = elem.querySelector(".playButton");
            if(playpauseBtnElem){
                if(templateData.isPlaying){
                    playpauseBtnElem.className = "ion-ios7-pause-outline playButton";
                }else{
                    playpauseBtnElem.className = "ion-ios7-play-outline playButton";
                }
            }

            const previewBtn = elem.querySelector(".previewBtn");
            if(previewBtn){
                if(previewBtn && templateData.audioPreviewUrl){
                    previewBtn.className = previewBtn.className.replace( /(?:^|\s)notAvailable(?!\S)/g , '' );
                }else{
                    if(!previewBtn.className.indexOf("notAvailable") != -1){
                        previewBtn.className = previewBtn.className + " " + "notAvailable";
                    }
                }
            }
            

            const playlistItemElem = elem;//.querySelector(".playlistItem");
            playlistItemElem.id = templateData.playlistId? "playlistId"+templateData.playlistId :  "playlistId";
            const titleElem = elem.querySelector(".title");
            titleElem.textContent = templateData.name? templateData.name :  "";
            const descriptionElem = elem.querySelector(".description");
            descriptionElem.textContent = templateData.description? templateData.description: "";
            const summaryElem = elem.querySelector(".summary");
            summaryElem.textContent = templateData.artistSample? templateData.artistSample: "";
            const authorElem = elem.querySelector(".author");
            authorElem.textContent = templateData.author? templateData.author: "";
            const lastUpdateTimeElem = elem.querySelector(".lastUpdateTime");
            lastUpdateTimeElem.textContent = templateData.lastModificationDate? "Last updated on "+ templateData.lastModificationDate: "";
            const numberOfSongsElem = elem.querySelector("#numberOfSongs");
            numberOfSongsElem.textContent = templateData.numberOfSongs? templateData.numberOfSongs : 0;
            const totalNumberOfPlaysElem = elem.querySelector("#totalNumberOfPlays");
            totalNumberOfPlaysElem.textContent = templateData.totalNumberOfPlays? templateData.totalNumberOfPlays : 0;
            const numberOfSongsCommentsElem = elem.querySelector("#numberOfSongsComments");
            numberOfSongsCommentsElem.textContent = templateData.numberOfSongsComments? templateData.numberOfSongsComments : 0;
        }catch(error){
            window.alertErrorMessage(error);
        }
    },
    

    
    _getInternalOnListItemTapHandler : function(){
        return  (function(model,evnt, item) {
            const ii  = this._modelWithLastPressedUpEventOnElement.indexOf(model)
            let timeDiff = null;
            if(ii  > -1)
            {
                 timeDiff = (new Date()).getTime() - this._timestampWithLastPressedUpEventOnElement[ii];
                 //this is event is probably trigger because of the presshold on ios so ignore if timestamp is less than 500ms
                 window.log("this is event is probably trigger because of the presshold on ios so ignore if timestamp is less than 500ms");
                 this._modelWithLastPressedUpEventOnElement.splice(ii,1);
                 this._timestampWithLastPressedUpEventOnElement.splice(ii,1);
                 
            }
            if(ii == -1 || !timeDiff || (timeDiff > 500) ){
                 for(let index in this.onItemTapHandlers){
                 const handler = this.onItemTapHandlers[index];
                 handler(model,evnt, item);
                 }
            }
                 
        }).bind(this);
    },
    
    _getInternalOnListItemTapPlayHandler : function(){
        return  (function(model,evnt, item) {

                 for(let index in this.onItemTapPlayHandlers){
                     const handler = this.onItemTapPlayHandlers[index];
                     handler(model,evnt, item);
                 }
   
                 
        }).bind(this);
    },

    _getInternalOnListItemTapEditHandler : function(){
        return  (function(model,evnt, item) {

                 for(let index in this.onItemTapEditHandlers){
                     const handler = this.onItemTapEditHandlers[index];
                     handler(model,evnt, item);
                 }
   
                 
        }).bind(this);
    },

    _getInternalOnListItemTapShareHandler : function(){
        return  (function(model,evnt, item) {

                 for(let index in this.onItemTapShareHandlers){
                     const handler = this.onItemTapShareHandlers[index];
                     handler(model,evnt, item);
                 }
   
                 
        }).bind(this);
    },
    
    _getInternalOnPressListItemTapHandler : function(){
        return  (function(model,evnt, item) {
            for(let index in this.onItemPressHandlers){
                const handler = this.onItemPressHandlers[index];
                handler(model,evnt, item);
            }
            if(model){
                this._modelWithPressedEventOnElement.push(model);
            }
        }).bind(this);
    },
    
    _getInternalOnPressUpListItemTapHandler : function(){
        return  (function(model,evnt, item) {
            const i  = this._modelWithPressedEventOnElement.indexOf(model);
            if(i > -1){
                for(let index in this.onItemPressUpHandlers){
                    const handler = this.onItemPressUpHandlers[index];
                    handler(model,evnt, item);
                }
                this._modelWithPressedEventOnElement.splice(i,1);
                 const ii  = this._modelWithLastPressedUpEventOnElement.indexOf(model)
                 if(ii  > -1)
                 {
                    this._modelWithLastPressedUpEventOnElement.splice(ii,1);
                    this._timestampWithLastPressedUpEventOnElement.splice(ii,1);
                 }
                this._modelWithLastPressedUpEventOnElement.push(model);
                this._timestampWithLastPressedUpEventOnElement.push((new Date()).getTime());
            }
        }).bind(this);
    },

    _getInternalOnItemSwipeRight : function(){
        return  (function(model,evnt, item) {
            for(let index in this.onSwipeRightRevealHandlers){
                const handler = this.onSwipeRightRevealHandlers[index];
                handler(model,evnt, item);
            }		
        }).bind(this);
    },
    
    _getInternalOnOptionButtonTapped : function(){
        return  (function(model,evnt, item) {
            for(let index in this.onOptionButtonTappedHandlers){
                const handler = this.onOptionButtonTappedHandlers[index];
                handler(model,evnt, item);
            }		
        }).bind(this);
    },
    /*
    _onAnimationEnd : function(){
        return  (function(model,evnt, item){
            if(evnt.animationName == "songGettingAddedPlaylistAnimation1")
                {
                optionEl = item.querySelector("#songAddToQueueTooltip");
                optionEl.innerHTML = "<div>Added<br>To<br>Queue</div>";
                optionEl.className += " optionSelected";
                songInfoBlockEl = item.querySelector(".songLibraryWrapper > .playlistItem");
                songInfoBlockEl.style["-webkit-animation"] = "songGettingAddedPlaylistAnimation2 500ms ease forwards";
                }else{
                    if(evnt.animationName == "songGettingAddedPlaylistAnimation2")
                    {
                        optionEl = item.querySelector("#songAddToQueueTooltip");
                        optionEl.innerHTML = "<div>Add<br>To<br>Queue</div>";
                        optionEl.className = optionEl.className.replace( /(?:^|\s)optionSelected(?!\S)/g , '' );
                        songInfoBlockEl = item.querySelector(".songLibraryWrapper > .playlistItem");
                        songInfoBlockEl.style["-webkit-animation"] = "";
                        //this.trigger("itemSwipedRight",model);
                        this._getInternalOnItemSwipeRight()(model,evnt, item);
                    }else{
                        songInfoBlockEl = item.querySelector(".songLibraryWrapper > .playlistItem");
                        songInfoBlockEl.style["-webkit-animation"] = "";
                    }
                }
        
        }).bind(this);
        
    },
    */
    
    _getDefaultItemEventHandler : function(){
        const handlers = {};
        handlers["click .info"] = this._getInternalOnListItemTapHandler();
        handlers["click #editButton"] = this._getInternalOnListItemTapEditHandler();
        handlers["click #shareTrebbleBtn"] = this._getInternalOnListItemTapShareHandler();
        handlers["click .itemContent > .coverArt > div > img"]= this._getInternalOnListItemTapHandler();
        handlers["click .playButton"] = this._getInternalOnListItemTapPlayHandler();
        handlers["press "] = this._getInternalOnPressListItemTapHandler();
        handlers["mouseover .previewBtn"] = this._getInternalOnPressListItemTapHandler();
        handlers["mouseout .previewBtn"] = this._getInternalOnPressUpListItemTapHandler();
        handlers["pressup "] = this._getInternalOnPressUpListItemTapHandler();
        handlers["touchend "] = this._getInternalOnPressUpListItemTapHandler();
        handlers["mouseup "] = this._getInternalOnPressUpListItemTapHandler();
        //handlers["panright a:first-child"] = this._getOnSwipeRightAnimationHandler();
        //handlers["panstart a:first-child"] = this._onDragStart();
    //	handlers["panright a:first-child"] = this._onDragRight();
    //	handlers["panleft a:first-child"] = this._onDragLeft();
    //	handlers["panend a:first-child"] = this._onRelease();
        //handlers["click div.songOptionBtnWrapper > a"] = this._getInternalOnOptionButtonTapped();
    //	handlers["webkitAnimationEnd a:first-child"] =  this._onAnimationEnd();
    //	handlers["oanimationend a:first-child"] =  this._onAnimationEnd();
    //	handlers["msAnimationEnd a:first-child"] =  this._onAnimationEnd();
    //	handlers["animationend a:first-child"] =  this._onAnimationEnd();
        return handlers;
    },
    
    setItemViewUpdateDuringScrollingHandler : function(handler){
        this.itemViewUpdateDuringScrollingHandler = handler;
    },

    
    addOnItemTapHandler : function(handler){
        this.onItemTapHandlers.push(handler);
    },

    addOnItemTapEditHandler : function(handler){
        this.onItemTapEditHandlers.push(handler);
    },

    addOnItemTapShareHandler : function(handler){
        this.onItemTapShareHandlers.push(handler);
    },
    
    addOnItemTapPlayHandler : function(handler){
        this.onItemTapPlayHandlers.push(handler);
    },
    
    addOnItemPressHandler : function(handler){
        this.onItemPressHandlers.push(handler);
    },
    
    addOnItemPressUpHandler : function(handler){
        this.onItemPressUpHandlers.push(handler);
    },
    
    
    addOnSwipeRightRevealHandler : function(handler){
        this.onSwipeRightRevealHandlers.push(handler);
    },
    
    setSwipeRightRevealEnabled : function( value){
        this.swipeRightRevealEnabled = value;
    },
    
    addOnOptionButtonTappedHandlers : function(handler){
        this.onOptionButtonTappedHandlers.push(handler);
    },
    
    setModelTransformHandlerBeforeTemplatePiping: function(beforeTemplateHandler){
        this.beforeTemplateHandler = beforeTemplateHandler;
    },
    
    _onRootElementResize : function(){
        if(this._emptyInfoBox && this._emptyInfoBox.$el){
            const root$el = $( this.listRootElement );
            const rootElInnerHeight =root$el.innerHeight() ;
            if( rootElInnerHeight != 0){
                this._emptyInfoBox.$el.css("height",rootElInnerHeight + "px");
            }
        }
        
        
    },
    

    
    updateModels : function(newModels){
        this.model = newModels;
        this.infiniteList.updateModels(newModels);
        this._displayInfoBoxIfApplicable();
        this.lazyLoadImages();
    },
    
    updateModel : function(newModel, silent){
        this.infiniteList.updateModel(newModel, silent);
        this._displayInfoBoxIfApplicable();
        this.lazyLoadImages();
    },

    initializeImageLazyLoadPlugin : function(container$elForImageLazyLoad){
        if(!this.lazyLoadingPluginInitialized){
            this._container$elForImageLazyLoad = container$elForImageLazyLoad;
            const scrollContainer$el = this._container$elForImageLazyLoad? this._container$elForImageLazyLoad: this.$el.find(".infiniteListOuterContainer")
            Utils.getInstance().initJqueryLazyLoadingPluginOnElemNew($(this.listRootElement), "img.lazy", scrollContainer$el);
            this.lazyLoadingPluginInitialized = true;
        }
    },
    
    lazyLoadImages : function(){
        const scrollContainer$el = this._container$elForImageLazyLoad? this._container$elForImageLazyLoad: this.$el.find(".infiniteListOuterContainer");
        this.cancelPendingImageLoading();
        window.log("Scheduling Lazy Images Loading");
        const pendingImageLazyLoading  = window.setTimeout((function(){
            this.self._pendingImageLazyLoading = null;
            window.log("Trigger Lazy Images Loading");
            this.scrollContainer$el.trigger("scrollstop");
        }).bind({"scrollContainer$el": scrollContainer$el, "self": this},this._imageLoadingDelayInMs));
        this._pendingImageLazyLoading = pendingImageLazyLoading;
    },
    
    cancelPendingImageLoading : function(){
        if(this._pendingImageLazyLoading){
            clearTimeout(this._pendingImageLazyLoading);
            window.log("Cancelled Current Lazy Images Loading");
        }
    },

    setUpdating : function(updating){
        this._isUpdating = updating;
        if(this._showEmtptyInfoBox && this._emptyInfoBox){
            if(this._isUpdating){
                this._emptyInfoBox.showLoading();
                this._emptyInfoBox.show();
                this.$el.find(".infiniteListOuterContainer").hide();
            }else{
                this._emptyInfoBox.hideLoading();
                this._displayInfoBoxIfApplicable();
                this.$el.find(".infiniteListOuterContainer").show();
            }
        }
    },
    
    setErrorMessage : function(errorMessage, helpErrorMessage, errorActionButtonLabel){
        if(this._showEmtptyInfoBox && this._emptyInfoBox){
            if(errorMessage){
                this._emptyInfoBox.showErrorMessage(errorMessage, helpErrorMessage, errorActionButtonLabel);
                this._emptyInfoBox.show();
                this.$el.find(".infiniteListOuterContainer").hide();
            }else{
                this._emptyInfoBox.hideErrorMessage();
                this._displayInfoBoxIfApplicable();
                this.$el.find(".infiniteListOuterContainer").show();
            }
        }
    },

    _initInfoBoxIfApplicable : function(){
        if(this._showEmtptyInfoBox ){
            if(!this._customInfoBoxInfo){
                this._emptyInfoBox =  new InfoBoxView(this._infoBoxInfoParams);
                this._emptyInfoBox.render();
                this.listRootElement.appendChild(this._emptyInfoBox.el);
            }else{
                this._emptyInfoBox = this._customInfoBoxInfo;
            }
            this.listenTo(this._emptyInfoBox, "actionButtonPressed",this._onDefaultInfoboxButtonCalled.bind(this));
        }
        this._displayInfoBoxIfApplicable();
    },
    
    updateInfoBoxIfApplicable : function(iconClass,message,helpMessage,infoBoxClassName,actionButtonLabel,actionButtonIconClass){
        if(this._showEmtptyInfoBox && this._emptyInfoBox){
            this._emptyInfoBox.update(iconClass,message,helpMessage,infoBoxClassName,actionButtonLabel,actionButtonIconClass);
            this._displayInfoBoxIfApplicable();
        }
    },
    
    _displayInfoBoxIfApplicable : function(){
        if(this._showEmtptyInfoBox && this._emptyInfoBox){
            if(this.model && this.model.length > 0){
                this._emptyInfoBox.hide();
            }else{
                this._emptyInfoBox.show();
            }
        }
        
    },
    
    render : function() {
        this.setElement(this.listRootElement);
        this._initInfoBoxIfApplicable();
        const numberOfItemsToDisplay = (window.device && window.device.platform == "browser" && !window.isMobileBrowser)?3: 1;
        this.infiniteList = new infiniteListAdapter(this.listRootElement ,
                this._getInfiniteListTemplateFuntion(), this.model, this._getDefaultItemEventHandler(),
                this.defaultNumberOfElements, this.defaultHeightElement, 
                this._onListItemUpdateHandlerFunction(), this.defaultOverrideMainContainerStyle,
                this.defaultOverrideSingleItemStyle, this.resizePlaceHolderElem, this.placeHolderHeight,
                null, this._modelIdAttribute, numberOfItemsToDisplay);
        //$(this.listRootElement).css("background-color","white");
        if(!this._doNotInitializeLazyLoadingAtRender){
            const scrollContainer$el = this._container$elForImageLazyLoad? this._container$elForImageLazyLoad: this.$el.find(".infiniteListOuterContainer")
            Utils.getInstance().initJqueryLazyLoadingPluginOnElemNew($(this.listRootElement), "img.lazy", scrollContainer$el);
            this.lazyLoadingPluginInitialized = true;
        }
            return this;
    }

});
trebblePlaylistListView.defaultItemHeight = 364;//190;
export default trebblePlaylistListView;