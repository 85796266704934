import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import ti18n from "i18n!nls/Commoni18n";
import defaultlistItemTemplate from 'text!../../../templates/mylibrary/songListItem.html';
import defaultlistItemTemplate2X from 'text!../../../templates/mylibrary/songListItem2x.html';
import InfoBoxView from "views/common/InfoBoxView";
import infiniteListAdapter from 'collections/savewrapper/infiniteListView2';
import RSVP from "rsvp";

const OPTION_SELECTED_VALID_SUFFIX = "valid";
const OPTION_SELECTED_INVALID_SUFFIX = "invalid";
const trebbleListView = Backbone.View.extend({


    initialize : function(options) {

        this._numberOfItemsToDisplayPerRow = options.numberOfItemsToDisplayPerRow? options.numberOfItemsToDisplayPerRow: null;
        if(!this._numberOfItemsToDisplayPerRow){
            this._numberOfItemsToDisplayPerRow = (window.device && window.device.platform == "browser")?1: 1;
        }
        if(this._numberOfItemsToDisplayPerRow == 2){
            this.defaultTemplatei18n =  (options && options.listItemTemplatetI18n)?options.listItemTemplatetI18n: ti18n;
            this.defaultTemplate = (options && options.listItemTemplate)?_.template(options.listItemTemplate): _.template(defaultlistItemTemplate2X);
        }else{
            this.defaultTemplatei18n =  (options && options.listItemTemplatetI18n)?options.listItemTemplatetI18n: ti18n;
            this.defaultTemplate = (options && options.listItemTemplate)?_.template(options.listItemTemplate): _.template(defaultlistItemTemplate);
        }



        this.defaultOverrideSingleItemStyle = {
            "display": "-webkit-box",
            "overflow":"hidden"
        };

        this.defaultOverrideMainContainerStyle = {};

        this.onItemTapHandlers = [];
        this.onItemPressHandlers = [];
        this.onItemPressUpHandlers = [];
        this.onSwipeRightRevealHandlers = [];
        this.onOptionButtonTappedHandlers = [];
        this.swipeRightRevealEnabled = true,
        this.beforeTemplateHandler = null;
        this.itemViewUpdateDuringScrollingHandler = null;
        this.model = options.model;
        this._doNotShowAddToRadioButton = options.doNotShowAddToRadioButton;
        this._doNotShowGradeSongButton = options.doNotShowGradeSongButton;
        this._doNotShowMoreInfoButton = options.doNotShowMoreInfoButton;
        this._showInfoButton = options.showInfoButton;
        this._showCommentButton = options.showCommentButton;
        this._showCoverArt = options.showCoverArt;
        this.listRootElement =  options.listRootElement;
        this._container$elForImageLazyLoad = options.container$elForImageLazyLoad;
        this.defaultNumberOfElements = options.defaultNumberOfElements;
        this.defaultHeightElement = options.defaultHeightElement;
        this.resizePlaceHolderElem = options.resizePlaceHolderElem ? options.resizePlaceHolderElem : false ;
        this.placeHolderHeight = options.placeHolderHeight ? options.placeHolderHeight : null ;
        this._modelIdAttribute = options.modelIdAttribute ? options.modelIdAttribute : null ;
        this._itemSlideLimit = 0.8;
        this._infoBoxInfoParams  = options.customInfoBoxParams? options.customInfoBoxParams: this._buildDefaultCustomBoxParams(); 
        this._emptyInfoBoxParams  = options.customEmptyInfoBoxParams? options.customEmptyInfoBoxParams: this._buildDefaultEmptyCustomBoxParams(); 
        this._customInfoBoxInfo  = options.customInfoBoxInfo? options.customInfoBoxInfo: null;
        this._showEmtptyInfoBox = (options.showEmtptyInfoBox == false)? false: true;
        this._emptyInfoBox = null;
        this._customItemEventHandler = options.itemEventHandlers? options.itemEventHandlers: null;


        //this._optionBoxWidth =100;
        this._itemRightOptionConfig =  options.rightSwipeRevealOption ? options.rightSwipeRevealOption : null ;
        this._itemLeftOptionConfig = options.leftSwipeRevealOption ? options.leftSwipeRevealOption : null ;
        this._numberOfOptionsOnPanRight = this._itemRightOptionConfig? this._itemRightOptionConfig.length -1: -1;
        this._numberOfOptionsOnPanLeft =  this._itemLeftOptionConfig? this._itemLeftOptionConfig.length -1: -1;
        //this.itemOptionWidth = 100;
        this._optionButtonWith = 50;
        this._modelWithPressedEventOnElement = [];
        this._modelWithLastPressedUpEventOnElement = [];
        this._timestampWithLastPressedUpEventOnElement = [];
        this._doNotInitializeLazyLoadingAtRender= options.doNotInitializeLazyLoadingAtRender? options.doNotInitializeLazyLoadingAtRender:false;
        this._isUpdating = false;
        this._imageLoadingDelayInMs= 5000;
        //this.onSwipeRightRevealHandlers.push(this._getOnSwipeRightAnimationHandler());
    },

    _buildDefaultCustomBoxParams : function(){
        const params = {};
        params.iconClass = "ion-ios7-musical-notes";
        params.message = window.getI18n(ti18n, "NO_SONG");
        params.helpMessage = window.getI18n(ti18n, "YOU_CAN_PLAY_THIS_SONG_IN_SHUFFLE_IF_YOU_ADD_IT_TO_YOUR_LIBRARY");
        return params;
    },

    _buildDefaultEmptyCustomBoxParams : function(){
        const params = {};
        params.iconClass = "ion-ios7-musical-notes";
        params.message = window.getI18n(ti18n, "NO_SONG_FOUND");
        params.helpMessage = window.getI18n(ti18n, "PLEASE_TRY_DIFFERENT_KEYWORDS");
        return params;
    },

    _onDefaultInfoboxButtonCalled : function(){
        this.trigger("infoBoxButtonPressed");
    },

    _buildItemRightOption : function(){
        const optionConfig = [];
        optionConfig.push( {"optionLabel" : "..."});
        optionConfig.push( {"optionLabel" : window.getI18n(ti18n, "ADD_TO_MY_TREBBLE"),"name": "addToRadio", "validationHandler": null, "onOptionSelected": function(){window.alertErrorMessage("okk")}});//"<i class='fontello-icon-list-add'></i>","name": "addToQueue"};// "Add To Queue"};
        optionConfig.push( {"optionLabel" : window.getI18n(ti18n, "RATE_OR_TAG"),"name": "rateOrTag" , "validationHandler": null, "onOptionSelected": null});//"<i class='ion-radio-waves'></i>","name": "playSimilarInLibrary"};//: "Play Similar In Library"};
        return optionConfig;
    },

    _buildItemLeftOption : function(){
        const optionConfig = [];
        optionConfig.push( {"optionLabel" : "..."});
        optionConfig.push( {"optionLabel": window.getI18n(ti18n, "SHOW_OPTIONS"),"name": "showOptions", "validationHandler": null, "onOptionSelected": null});//"<i class='fontello-icon-list-add'></i>"};// "Add To Queue"};
        optionConfig.push( {"optionLabel": window.getI18n(ti18n, "REMOVE_FROM_MY_TREBBLE"),"name": "removeFromRadio", "validationHandler": null, "onOptionSelected": null});//"<i class='ion-radio-waves'></i>"};// : "Play Similar In Library"};
        return optionConfig;
    },



    _getOnSwipeRightAnimationHandler : function(){
        return   (function(model,evnt, item) {
            if(this.swipeRightRevealEnabled){
                item.querySelector(".songLibraryWrapper").style["-webkit-animation"] = "songGettingAddedPlaylistAnimation1 800ms ease forwards";		
            }		
        }).bind(this);
    },
    _onDragStart : function(){

        return  (function(model,evnt, item){
            //if(!this.infiniteList.isScrolling()){
                const libElem = item.querySelector(".songLibraryWrapper");
                const optionElem = item.querySelector("#songAddToQueueTooltip > div:first-child");
                libElem._clientWidth = item.clientWidth;
                item._optionWrapperWidth = optionElem.clientWidth;
                libElem._isDraggingRight = false;
                libElem._isDraggingLeft = false;
                //}
            }).bind(this);

    },

    _onDragRight : function(){

        return  (function(model,evnt, item){
            //if(!this.infiniteList.isScrolling()){
                const libElem = item.querySelector(".songLibraryWrapper");
                if(!libElem._isDraggingRight && !libElem._isDraggingLeft)
                {
                    libElem._isDraggingRight = true;
                    libElem._isDraggingLeft = false;
                    const optionWrapperDiv$el = $(item.querySelector("#songAddToQueueTooltip"));
                    optionWrapperDiv$el.addClass("optionOnPanRight");
                }
                if(libElem._isDraggingLeft || (libElem._isDraggingRight && this._numberOfOptionsOnPanRight > 0))
                {
                    libElem.style["-webkit-transition"] = "";//"-webkit-transform .3s";
                    if(evnt.deltaX <= libElem._clientWidth *this._itemSlideLimit )
                    {
                        //do nothing
                        libElem.style["-webkit-transform"] = "translateX("+ (evnt.deltaX).toString() +"px)";
                        const optionElem = item.querySelector("#songAddToQueueTooltip > div:first-child");
                        const dragDirection= libElem._isDraggingLeft ? "left" : "right";
                        if(dragDirection == "right")
                        {
                            optionElem.style["-webkit-transform"] = "translateX("+ (evnt.deltaX - item._optionWrapperWidth).toString() +"px)";
                        }else{
                            optionElem.style["-webkit-transform"] = "translateX("+ (evnt.deltaX + item._optionWrapperWidth).toString() +"px)";
                        }
                        
                        libElem._dragXPosition=evnt.deltaX;
                        
                        this._updateOptionStateIfApplicable(item, evnt, dragDirection, model);
                    }

                }else{
                    //do not pan
                }
                //}
            }).bind(this);

},

_onDragLeft : function(){

return  (function(model,evnt, item){
//if(!this.infiniteList.isScrolling()){
    const libElem = item.querySelector(".songLibraryWrapper");
    if(!libElem._isDraggingRight && !libElem._isDraggingLeft)
    {
        libElem._isDraggingRight = false;
        libElem._isDraggingLeft = true;
        const optionWrapperDiv$el = $(item.querySelector("#songAddToQueueTooltip"));
        optionWrapperDiv$el.addClass("optionOnPanLeft");
    }
    if(libElem._isDraggingRight || (libElem._isDraggingLeft && this._numberOfOptionsOnPanLeft > 0))
    {
        libElem.style["-webkit-transition"] = "";//"-webkit-transform .3s";
        if(evnt.deltaX <= libElem._clientWidth *this._itemSlideLimit )
        {
            //do nothing
            libElem.style["-webkit-transform"] = "translateX("+ (evnt.deltaX).toString() +"px)";
            const optionElem = item.querySelector("#songAddToQueueTooltip > div:first-child");
            const dragDirection= libElem._isDraggingLeft ? "left" : "right";
            if(dragDirection == "right")
            {
                optionElem.style["-webkit-transform"] = "translateX("+ (evnt.deltaX - item._optionWrapperWidth).toString() +"px)";
            }else{
                optionElem.style["-webkit-transform"] = "translateX("+ (evnt.deltaX + item._optionWrapperWidth).toString() +"px)";
            }
            libElem._dragXPosition=evnt.deltaX;

            this._updateOptionStateIfApplicable(item, evnt, dragDirection, model);
        }
    }else{
        //do not pan
    }
    //}
}).bind(this);

},

_updateOptionStateIfApplicable : function(item, evnt, dragDirection, model){
const xDragPosition =  evnt.deltaX;
let numberOfPossibleOptions = null;
let libElem, newOptionLevel, optionWrapperDiv, optionWrapperDiv$el, optionElem;
if(dragDirection == "left" && this._numberOfOptionsOnPanLeft> 0){
numberOfPossibleOptions = this._numberOfOptionsOnPanLeft + 1;
libElem = item.querySelector(".songLibraryWrapper");
newOptionLevel = Math.floor(Math.abs(xDragPosition * numberOfPossibleOptions/(libElem._clientWidth * this._itemSlideLimit)));
if(item._optionLevel != newOptionLevel){
    //need to update optionLevel
    optionWrapperDiv = item.querySelector("#songAddToQueueTooltip");
    optionWrapperDiv$el = $(optionWrapperDiv);
    optionElem = item.querySelector("#songAddToQueueTooltip > div:first-child");
    optionElem.innerHTML = (this._itemLeftOptionConfig &&  this._itemLeftOptionConfig[newOptionLevel])? this._itemLeftOptionConfig[newOptionLevel].optionLabel : "";
    if(optionWrapperDiv._addedClassName)
    {
        optionWrapperDiv$el.removeClass(optionWrapperDiv._addedClassName);
        optionWrapperDiv._addedClassName =  null;
    }
    optionWrapperDiv._addedClassName = this._getClassnameForOptionLevel(newOptionLevel, model, dragDirection);
    optionWrapperDiv$el.addClass(optionWrapperDiv._addedClassName);
    item._optionLevel = newOptionLevel;
    item._optionWrapperWidth = optionElem.clientWidth;
}
}
if(dragDirection == "right" && this._numberOfOptionsOnPanRight> 0){
numberOfPossibleOptions = this._numberOfOptionsOnPanRight + 1;
libElem = item.querySelector(".songLibraryWrapper");
newOptionLevel = Math.floor(Math.abs(xDragPosition * numberOfPossibleOptions/(libElem._clientWidth * this._itemSlideLimit)));
if(item._optionLevel != newOptionLevel){
    //need to update optionLevel
    optionWrapperDiv = item.querySelector("#songAddToQueueTooltip");
    optionWrapperDiv$el = $(optionWrapperDiv);
    optionElem = item.querySelector("#songAddToQueueTooltip > div:first-child");
    
    optionElem.innerHTML = (this._itemRightOptionConfig &&  this._itemRightOptionConfig[newOptionLevel])? this._itemRightOptionConfig[newOptionLevel].optionLabel : "";
    
    if(optionWrapperDiv._addedClassName)
    {
        optionWrapperDiv$el.removeClass(optionWrapperDiv._addedClassName);
        optionWrapperDiv._addedClassName =  null;
    }
    optionWrapperDiv._addedClassName = this._getClassnameForOptionLevel(newOptionLevel, model, dragDirection);
    optionWrapperDiv$el.addClass(optionWrapperDiv._addedClassName);
    item._optionLevel = newOptionLevel;
    item._optionWrapperWidth = optionElem.clientWidth;
}
}


},
_validateOptionSelectionForModel : function(dragDirection, optionLevelSelected, model){
let optionConfig = null;
if(dragDirection == "right"){
optionConfig = this._getRightOptionConfig(optionLevelSelected);
}else{
optionConfig = this._getLeftOptionConfig(optionLevelSelected);
}
if(optionConfig && optionConfig.validationHandler){
return optionConfig.validationHandler(model);
}
return true;
},


_getRightOptionConfig : function(optionLevelSelected){
if(this._itemRightOptionConfig && this._itemRightOptionConfig.length > optionLevelSelected ){
return this._itemRightOptionConfig[optionLevelSelected];
}
return null;
},

_getLeftOptionConfig : function(optionLevelSelected){
if(this._itemLeftOptionConfig && this._itemLeftOptionConfig.length > optionLevelSelected ){
return this._itemLeftOptionConfig[optionLevelSelected];
}
return null;
},

selectOption : function(dragDirection,optionLevelSelected, model){
    let optionConfig = null;
if(dragDirection == "right"){
optionConfig = this._getRightOptionConfig(optionLevelSelected);
}else{
optionConfig = this._getLeftOptionConfig(optionLevelSelected);
}
if(optionConfig && optionConfig.onOptionSelected){
return optionConfig.onOptionSelected(model);
}
return RSVP.Promise.resolve();
},



_getClassnameForOptionLevel : function(optionLevel, model, dragDirection){
const isValid = this._validateOptionSelectionForModel(dragDirection, optionLevel, model);
let className = "optionLevel_"+optionLevel ;
if(isValid){
className =  className + "_" +OPTION_SELECTED_VALID_SUFFIX;
}else{
className =  className + "_" +OPTION_SELECTED_INVALID_SUFFIX
}
return className;
},

_getMaxDragXPosition : function(dragDirection, optionLevel, itemMaxWidth, itemSlideLimit, numberOfPossibleOptions){
return (itemMaxWidth * itemSlideLimit *(optionLevel +1))/(numberOfPossibleOptions+1);
},

/*_onRelease: function(){

return  (function(model,evnt, item){
//	if(!this.infiniteList.isScrolling()){
    // more then 50% moved, navigate
    const libElem = item.querySelector(".songLibraryWrapper");
    const dragDirection= libElem._isDraggingLeft ? "left" : "right";
    const optionElem = item.querySelector("#songAddToQueueTooltip > div:first-child");
    //  if(Math.abs(libElem._dragXPosition) > libElem._clientWidth * (this._itemSlideLimit /(this._numberOfOptionsOnPanRight + 1))) {
        if(item._optionLevel  && item._optionLevel  > 0 this._validateOptionSelectionForModel(dragDirection,item._optionLevel, model ))
        {            	
            item._isOptionSelected = true;
            const onCancellationPeriodEnd =  function(){
                const optionWrapperDiv = item.querySelector("#songAddToQueueTooltip");
                const optionWrapperDiv$el = $(optionWrapperDiv);

                const animationEndListener = this._onOptionSelectedHandlerFunction({"self":this, "itemElem":item, "model": model});
                optionWrapperDiv.addEventListener("webkitTransitionEnd", animationEndListener, false);
                libElem._animationEndListener = animationEndListener;
                if(item._isOptionSelected)
                {

                    const maxNumberOfPossibleOptions = null;
                    if(libElem._isDraggingLeft ){
                        maxNumberOfPossibleOptions= this._numberOfOptionsOnPanLeft;
                    }else{
                        maxNumberOfPossibleOptions= this._numberOfOptionsOnPanRight;
                    }
                    const dragPosForLibElem = (this._getMaxDragXPosition(dragDirection,item._optionLevel, libElem._clientWidth,this._itemSlideLimit, maxNumberOfPossibleOptions))-10;
                    if(dragDirection != "right")
                    {
                        dragPosForLibElem = -dragPosForLibElem;
                    }
                    const dragPosForOptionElem = null;
                    if(dragDirection == "right")
                    {
                        dragPosForOptionElem =  (dragPosForLibElem - item._optionWrapperWidth);
                    }else{
                        dragPosForOptionElem =  (dragPosForLibElem + item._optionWrapperWidth);
                    }
                    optionWrapperDiv$el.addClass("optionSelected");
                    libElem.style["-webkit-transform"] = "translateX("+ (dragPosForLibElem).toString() +"px)";
                    optionElem.style["-webkit-transform"] = "translateX("+ (dragPosForOptionElem).toString() +"px)";
                }
                

            };
            onCancellationPeriodEnd = onCancellationPeriodEnd.bind(this);
            window.setTimeout(onCancellationPeriodEnd, 0);
        }else {
            //libElem.style["-webkit-transform"] = "translateX(0px)";
            libElem._dragXPosition = 0;
            item._optionLevel = null;
            item._isOptionSelected = false;
            libElem._clientWidth = null;
            libElem._isDraggingRight = false;
            libElem._isDraggingLeft = false;
            libElem.style["-webkit-animation"] = "";
            optionElem.style["-webkit-animation"] = "";
            libElem.style["-webkit-transform"] = "";
            optionElem.style["-webkit-transform"] = "";
            const optionWrapperDiv$el = $(item.querySelector("#songAddToQueueTooltip"));
            optionWrapperDiv$el.removeClass("optionOnPanLeft");
            optionWrapperDiv$el.removeClass("optionOnPanRight");
        }
        //}
    }).bind(this);

},

*/

_onRelease: function(){

return  (function(model,evnt, item){
//	if(!this.infiniteList.isScrolling()){
    // more then 50% moved, navigate
    const libElem = item.querySelector(".songLibraryWrapper");
    const dragDirection= libElem._isDraggingLeft ? "left" : "right";
    const optionElem = item.querySelector("#songAddToQueueTooltip > div:first-child");
    //  if(Math.abs(libElem._dragXPosition) > libElem._clientWidth * (this._itemSlideLimit /(this._numberOfOptionsOnPanRight + 1))) {
        if(item._optionLevel  && item._optionLevel  > 0 && this._validateOptionSelectionForModel(dragDirection,item._optionLevel, model ))
        {            	
            item._isOptionSelected = true;
            let onCancellationPeriodEnd =  function(){
                const optionWrapperDiv = item.querySelector("#songAddToQueueTooltip");
                const optionWrapperDiv$el = $(optionWrapperDiv);

                const animationEndListener = this._onOptionSelectedHandlerFunction({"self":this,"dragDirection": dragDirection, "itemElem":item, "model": model , "optionLevelSelected": item._optionLevel});
                optionWrapperDiv.addEventListener("webkitTransitionEnd", animationEndListener, false);
                libElem._animationEndListener = animationEndListener;
                if(item._isOptionSelected)
                {
                    
                    let maxNumberOfPossibleOptions = null;
                    if(libElem._isDraggingLeft ){
                        maxNumberOfPossibleOptions= this._numberOfOptionsOnPanLeft;
                    }else{
                        maxNumberOfPossibleOptions= this._numberOfOptionsOnPanRight;
                    }
                    let dragPosForLibElem = (this._getMaxDragXPosition(dragDirection,item._optionLevel, libElem._clientWidth,this._itemSlideLimit, maxNumberOfPossibleOptions))-10;
                    if(dragDirection != "right")
                    {
                        dragPosForLibElem = -dragPosForLibElem;
                    }
                    let dragPosForOptionElem = null;
                    if(dragDirection == "right")
                    {
                        dragPosForOptionElem =  (dragPosForLibElem - item._optionWrapperWidth);
                    }else{
                        dragPosForOptionElem =  (dragPosForLibElem + item._optionWrapperWidth);
                    }
                    //optionWrapperDiv$el.addClass("animate");
                    optionWrapperDiv$el.addClass("optionSelected");
                    libElem.style["-webkit-transform"] = "translateX("+ (dragPosForLibElem).toString() +"px)";
                    optionElem.style["-webkit-transform"] = "translateX("+ (dragPosForOptionElem).toString() +"px)";
                }


            };
            onCancellationPeriodEnd = onCancellationPeriodEnd.bind(this);
            window.setTimeout(onCancellationPeriodEnd, 0);
        }else {
            //libElem.style["-webkit-transform"] = "translateX(0px)";
            libElem._dragXPosition = 0;
            item._optionLevel = null;
            item._isOptionSelected = false;
            libElem._clientWidth = null;
            libElem._isDraggingRight = false;
            libElem._isDraggingLeft = false;
            libElem.style["-webkit-animation"] = "";
            optionElem.style["-webkit-animation"] = "";
            libElem.style["-webkit-transform"] = "";
            optionElem.style["-webkit-transform"] = "";
            const optionWrapperDiv = item.querySelector("#songAddToQueueTooltip");
            const optionWrapperDiv$el = $(optionWrapperDiv);
            if(optionWrapperDiv._addedClassName)
            {
                optionWrapperDiv$el.removeClass(optionWrapperDiv._addedClassName);
                optionWrapperDiv._addedClassName =  null;
            }
            optionWrapperDiv$el.removeClass("optionOnPanLeft");
            optionWrapperDiv$el.removeClass("optionOnPanRight");
        }
        //}
    }).bind(this);

},

_onOptionSelectedHandlerFunction : function (context) {
    return function () {
        // Removing animationend listener
        const libElem = this.itemElem.querySelector(".songLibraryWrapper");
        const animationEndListener = libElem._animationEndListener;
        this.itemElem.querySelector("#songAddToQueueTooltip ").removeEventListener("webkitTransitionEnd", animationEndListener);
        libElem._animationEndListener = null;
        const iconElem = this.itemElem.querySelector("#songAddToQueueTooltip > div:first-child > i");

        if (iconElem && iconElem.className.indexOf(" ion-loading-c animate-spin") == -1) {
            iconElem.className = iconElem.className + " ion-loading-c animate-spin";
        }

        this.self.selectOption(this.dragDirection, this.optionLevelSelected, this.model).then(function () {
            if (iconElem && iconElem.className.indexOf(" ion-loading-c animate-spin") != -1) {
                iconElem.className = iconElem.className.replace(" ion-loading-c animate-spin", "");
            }
            const libElem$el = $(libElem);
            const optionWrapperDiv = this.itemElem.querySelector("#songAddToQueueTooltip");
            //const optionWrapperDiv$el = $(optionWrapperDiv);
            const optionElem = this.itemElem.querySelector("#songAddToQueueTooltip > div:first-child");
            const optionElem$el = $(optionElem);

            const ff = function () {
                const handler = this.self._cleanDataAfterAllAnimationCompletedOnElementFunc(this);
                libElem._animationEndListener = handler;
                optionWrapperDiv.addEventListener("webkitTransitionEnd", handler, false);

                const dragDirection = libElem._isDraggingLeft ? "left" : "right";
                const optionLevel = this.itemElem._optionLevel;

                this.self.trigger("optionSelected", [dragDirection, optionLevel]);
                libElem$el.addClass("animateToZeroXPosition");
                optionElem$el.addClass("animateToZeroXPosition");
                optionElem.style["-webkit-transform"] = "";
                libElem.style["-webkit-transform"] = "";
            }.bind(this);

            setTimeout(ff, 0);
        }).catch(function (error) {
            if (iconElem && iconElem.className.indexOf(" ion-loading-c animate-spin") != -1) {
                iconElem.className = iconElem.className.replace(" ion-loading-c animate-spin", "");
            }
            Utils.getInstance().showLongToastMessage(error && error.message ? error.message : error);

            const libElem$el = $(libElem);
            const optionWrapperDiv = this.itemElem.querySelector("#songAddToQueueTooltip");
            optionWrapperDiv.removeEventListener("webkitTransitionEnd", animationEndListener);
            const optionWrapperDiv$el = $(optionWrapperDiv);
            optionWrapperDiv$el.removeClass("optionSelected");
            optionWrapperDiv$el.addClass("optionSelectedFailed");
            const optionElem = this.itemElem.querySelector("#songAddToQueueTooltip > div:first-child");
            const optionElem$el = $(optionElem);

            const ff = function () {
                const handler = this.self._cleanDataAfterAllAnimationCompletedOnElementFunc(this);
                libElem._animationEndListener = handler;
                optionWrapperDiv.addEventListener("webkitTransitionEnd", handler, false);

                const dragDirection = libElem._isDraggingLeft ? "left" : "right";
                const optionLevel = this.itemElem._optionLevel;

                this.self.trigger("optionSelected", [dragDirection, optionLevel]);
                libElem$el.addClass("animateToZeroXPosition");
                optionElem$el.addClass("animateToZeroXPosition");
                optionElem.style["-webkit-transform"] = "";
                libElem.style["-webkit-transform"] = "";
            }.bind(this);

            setTimeout(ff, 200);
        });
    }.bind(context);
},

/*
_onOptionSelectedHandlerFunction : function(context){
return  (function(evnt){
//if(evnt.animationName == "optionLevel_1Selected" || evnt.animationName == "optionLevel_2Selected")
this.self.selectOption(this.dragDirection, this.optionLevelSelected, this.model).then((function(){
    const libElem = this.itemElem.querySelector(".songLibraryWrapper");
    const libElem$el = $(libElem);
    const optionWrapperDiv = this.itemElem.querySelector("#songAddToQueueTooltip");
    const optionWrapperDiv$el = $(optionWrapperDiv);
    //optionWrapperDiv$el.addClass("optionSelected");
    const optionElem = this.itemElem.querySelector("#songAddToQueueTooltip > div:first-child");
    const optionElem$el = $(optionElem);
    const animationCompleted = optionElem$el.hasClass("animateToZeroXPosition") || libElem$el.hasClass("animateToZeroXPosition");
    window.log("Animation COmpleted. :"+animationCompleted);
    if(animationCompleted){
        

        libElem.style["-webkit-animation"] = "";
        optionElem.style["-webkit-animation"] = "";
        libElem.style["-webkit-transform"] = "";
        optionElem.style["-webkit-transform"] = "";
        optionWrapperDiv$el.removeClass("animate");
        optionWrapperDiv$el.removeClass("optionSelected");
        libElem$el.removeClass("animateToZeroXPosition");
        optionElem$el.removeClass("animateToZeroXPosition");
        optionWrapperDiv.removeEventListener("webkitTransitionEnd", libElem._animationEndListener);
        optionWrapperDiv$el.removeClass(optionWrapperDiv._addedClassName)
        optionWrapperDiv$el.removeClass("optionOnPanLeft");
        optionWrapperDiv$el.removeClass("optionOnPanRight");
        window.log("ccccc");
        this.itemElem._optionLevel = null;
        if(this.itemElem._isOptionSelected){
            //const optionWrapperDiv$el = $(this.itemElem.querySelector("#songAddToQueueTooltip"));	
            //optionWrapperDiv$el.removeClass("optionSelected")
            this.itemElem._isOptionSelected = false;
        }
        libElem._clientWidth = this.itemElem.clientWidth;
        libElem._isDraggingRight = false;
        libElem._isDraggingLeft = false;
        
    }else{
        window.log("aaaaa");
        const ff = function(){
            const dragDirection= libElem._isDraggingLeft ? "left" : "right";
            const optionLevel = this.itemElem._optionLevel;
            this.self.trigger("optionSelected",[dragDirection,optionLevel])
            libElem$el.addClass("animateToZeroXPosition");
            window.log("bbb");
            optionElem$el.addClass("animateToZeroXPosition");
            optionElem.style["-webkit-transform"] = "";
            libElem.style["-webkit-transform"] = "";
        };
        setTimeout(ff.bind(this),0);

    }
}).bind(this)).catch((function(error){
    Utils.getInstance().showLongToastMessage(error);
    const libElem = this.itemElem.querySelector(".songLibraryWrapper");
    const libElem$el = $(libElem);
    const optionWrapperDiv = this.itemElem.querySelector("#songAddToQueueTooltip");
    const optionWrapperDiv$el = $(optionWrapperDiv);
    optionWrapperDiv$el.removeClass("optionSelected");
    optionWrapperDiv$el.addClass("optionSelectedFailed");
    const optionElem = this.itemElem.querySelector("#songAddToQueueTooltip > div:first-child");
    const optionElem$el = $(optionElem);
    const animationCompleted = optionElem$el.hasClass("animateToZeroXPosition") || libElem$el.hasClass("animateToZeroXPosition");
    window.log("Animation COmpleted. :"+animationCompleted);
    if(animationCompleted){
        

        libElem.style["-webkit-animation"] = "";
        optionElem.style["-webkit-animation"] = "";
        libElem.style["-webkit-transform"] = "";
        optionElem.style["-webkit-transform"] = "";
        optionWrapperDiv$el.removeClass("animate");
        optionWrapperDiv$el.removeClass("optionSelectedFailed");
        libElem$el.removeClass("animateToZeroXPosition");
        optionElem$el.removeClass("animateToZeroXPosition");
        optionWrapperDiv.removeEventListener("webkitTransitionEnd", libElem._animationEndListener);
        optionWrapperDiv$el.removeClass(optionWrapperDiv._addedClassName);
        optionWrapperDiv$el.removeClass("optionOnPanLeft");
        optionWrapperDiv$el.removeClass("optionOnPanRight");
        window.log("ccccc");
        this.itemElem._optionLevel = null;
        if(this.itemElem._isOptionSelected){
            //const optionWrapperDiv$el = $(this.itemElem.querySelector("#songAddToQueueTooltip"));	
            //optionWrapperDiv$el.removeClass("optionSelected")
            this.itemElem._isOptionSelected = false;
        }
        libElem._clientWidth = this.itemElem.clientWidth;
        libElem._isDraggingRight = false;
        libElem._isDraggingLeft = false;
        
    }else{
        window.log("aaaaa");
        const ff = function(){
            const dragDirection= libElem._isDraggingLeft ? "left" : "right";
            const optionLevel = this.itemElem._optionLevel;
            this.self.trigger("optionSelected",[dragDirection,optionLevel])
            libElem$el.addClass("animateToZeroXPosition");
            window.log("bbb");
            optionElem$el.addClass("animateToZeroXPosition");
            optionElem.style["-webkit-transform"] = "";
            libElem.style["-webkit-transform"] = "";
        };
        setTimeout(ff.bind(this),200);
    }
}).bind(this))


}).bind(context);
},
*/
_cleanDataAfterAllAnimationCompletedOnElementFunc : function(context){
return  (function(){
const libElem = this.itemElem.querySelector(".songLibraryWrapper");
const libElem$el = $(libElem);
const optionWrapperDiv = this.itemElem.querySelector("#songAddToQueueTooltip");
optionWrapperDiv.removeEventListener("webkitTransitionEnd", libElem._animationEndListener);
libElem._animationEndListener = null;
const optionWrapperDiv$el = $(optionWrapperDiv);
const optionElem = this.itemElem.querySelector("#songAddToQueueTooltip > div:first-child");
const optionElem$el = $(optionElem);
libElem.style["-webkit-animation"] = "";
optionElem.style["-webkit-animation"] = "";
libElem.style["-webkit-transform"] = "";
optionElem.style["-webkit-transform"] = "";
optionWrapperDiv$el.removeClass("animate");
optionWrapperDiv$el.removeClass("optionSelected");
optionWrapperDiv$el.removeClass("optionSelectedFailed");
libElem$el.removeClass("animateToZeroXPosition");
optionElem$el.removeClass("animateToZeroXPosition");
optionWrapperDiv$el.removeClass(optionWrapperDiv._addedClassName)
optionWrapperDiv$el.removeClass("optionOnPanLeft");
optionWrapperDiv$el.removeClass("optionOnPanRight");
this.itemElem._optionLevel = null;
if(this.itemElem._isOptionSelected){
    this.itemElem._isOptionSelected = false;
}
libElem._clientWidth = this.itemElem.clientWidth;
libElem._isDraggingRight = false;
libElem._isDraggingLeft = false;


}).bind(context);
},

/*
_onOptionSelectedHandlerFunction : function(context){
return  (function(evnt){
//if(evnt.animationName == "optionLevel_1Selected" || evnt.animationName == "optionLevel_2Selected")

const libElem = this.itemElem.querySelector(".songLibraryWrapper");
const libElem$el = $(libElem);
const optionWrapperDiv = this.itemElem.querySelector("#songAddToQueueTooltip");
const optionWrapperDiv$el = $(optionWrapperDiv);
const optionElem = this.itemElem.querySelector("#songAddToQueueTooltip > div:first-child");
const optionElem$el = $(optionElem);
const animationCompleted = optionElem$el.hasClass("animateToZeroXPosition") || libElem$el.hasClass("animateToZeroXPosition");
window.log("Animation COmpleted. :"+animationCompleted);
if(animationCompleted){


    libElem.style["-webkit-animation"] = "";
    optionElem.style["-webkit-animation"] = "";
    libElem.style["-webkit-transform"] = "";
    optionElem.style["-webkit-transform"] = "";
    optionWrapperDiv$el.removeClass("optionSelected");
    libElem$el.removeClass("animateToZeroXPosition");
    optionElem$el.removeClass("animateToZeroXPosition");
    optionWrapperDiv.removeEventListener("webkitTransitionEnd", libElem._animationEndListener);
    optionWrapperDiv$el.removeClass(this.self._getClassnameForOptionLevel(this.itemElem._optionLevel))
    optionWrapperDiv$el.removeClass("optionOnPanLeft");
    optionWrapperDiv$el.removeClass("optionOnPanRight");
    window.log("ccccc");
    this.itemElem._optionLevel = null;
    if(this.itemElem._isOptionSelected){
        //const optionWrapperDiv$el = $(this.itemElem.querySelector("#songAddToQueueTooltip"));	
        //optionWrapperDiv$el.removeClass("optionSelected")
        this.itemElem._isOptionSelected = false;
    }
    libElem._clientWidth = this.itemElem.clientWidth;
    libElem._isDraggingRight = false;
    libElem._isDraggingLeft = false;

}else{
    window.log("aaaaa");
    const ff = function(){
        const dragDirection= libElem._isDraggingLeft ? "left" : "right";
        const optionLevel = this.itemElem._optionLevel;
        this.self.trigger("optionSelected",[dragDirection,optionLevel])
        libElem$el.addClass("animateToZeroXPosition");
        window.log("bbb");
        optionElem$el.addClass("animateToZeroXPosition");
        optionElem.style["-webkit-transform"] = "";
        libElem.style["-webkit-transform"] = "";
    };
    setTimeout(ff.bind(this),500);

}

}).bind(context);
},

*/
_getInfiniteListTemplateFuntion :function(){
return  (function(model)
{
    let templateData;
if(this.beforeTemplateHandler)
{
    templateData = this.beforeTemplateHandler(model);
}else{
    templateData = {firstLineText : model.firstLineText, secondLineText : model.secondLineText, "model" : model, "ti18n": this.defaultTemplatei18n};
}
if(templateData){
    templateData.doNotShowAddToRadioButton = this._doNotShowAddToRadioButton;
    templateData.doNotShowGradeSongButton = this._doNotShowGradeSongButton;
    templateData.doNotShowMoreInfoButton = this._doNotShowMoreInfoButton;
    templateData.showInfoButton = this._showInfoButton;
    templateData.showCommentButton = this._showCommentButton;
    templateData.showCoverArt = this._showCoverArt;
}
return this.defaultTemplate(templateData);
}).bind(this);
},

_onListItemUpdateHandlerFunction :function(){ 
return (function(elemRoot,models){
//NB render template need to be wrap in an one element
//const isArrayParameter =(models instanceof Array)? true: false;
const modelArray =(models instanceof Array)?models: [models];
//const templateDataArray = [];
let templateData;
for(let index  = 0 ; index < modelArray.length;index++ ){
    const model =  modelArray[index];
    if(this.beforeTemplateHandler)
    {
        templateData = this.beforeTemplateHandler(model);
    }else{
        templateData = {firstLineText : model.firstLineText, secondLineText : model.secondLineText, "model" : model, "ti18n": this.defaultTemplatei18n};
    }
    if(templateData){
        templateData.doNotShowAddToRadioButton = this._doNotShowAddToRadioButton;
        templateData.doNotShowGradeSongButton = this._doNotShowGradeSongButton;
        templateData.doNotShowMoreInfoButton = this._doNotShowMoreInfoButton;
        templateData.showInfoButton = this._showInfoButton;
        templateData.showCommentButton = this._showCommentButton;
        templateData.showCoverArt = this._showCoverArt;
    }
    if(index < elemRoot.children.length ){
        // use existing render object and update it since it is faster than rerendering the entire model
        const elem = elemRoot.children[index];
        if(this.itemViewUpdateDuringScrollingHandler)
        {
            this.itemViewUpdateDuringScrollingHandler(elem,templateData);
        }else{
            this._getInternalItemViewUpdateDuringScrollingHandler(elem,templateData);
        }
    }else{
        //no root node so just render the entire model and add it to the innerHtml raw
        elemRoot.innerHTML = elemRoot.innerHTML +  this.defaultTemplate(templateData);
    }
}
if( modelArray.length < elemRoot.children.length){
    const nodeToDeletes = [];
    for(let index = modelArray.length;  index < elemRoot.children.length; index ++){
        nodeToDeletes.push(elemRoot.children[index]);
    }
    for(let index = 0; index < nodeToDeletes.length;  index++ ){
        //deleting unsued nodes
        const nodeToDelete = nodeToDeletes[index];
        nodeToDelete.parentNode.removeChild(nodeToDelete);
    }

}
}).bind(this);
},

_getInternalItemViewUpdateDuringScrollingHandler : function(elem,templateData){
const firstItemElem = elem.querySelector(".songTitleLibrary");
firstItemElem.textContent = templateData.firstLineText;
const secondItemElem = elem.querySelector(".songArtistLibrary");
secondItemElem.textContent = templateData.secondLineText;
},



_getInternalOnListItemTapHandler : function(){
return  (function(model,evnt, item) {
const ii  = this._modelWithLastPressedUpEventOnElement.indexOf(model)
let timeDiff = null;
if(ii  > -1)
{
    timeDiff = (new Date()).getTime() - this._timestampWithLastPressedUpEventOnElement[ii];
    //this is event is probably trigger because of the presshold on ios so ignore if timestamp is less than 500ms
    window.log("this is event is probably trigger because of the presshold on ios so ignore if timestamp is less than 500ms");
    this._modelWithLastPressedUpEventOnElement.splice(ii,1);
    this._timestampWithLastPressedUpEventOnElement.splice(ii,1);

}
if(ii == -1 || !timeDiff || (timeDiff > 500) ){
    for(let index in this.onItemTapHandlers){
        const handler = this.onItemTapHandlers[index];
        handler(model,evnt, item);
    }
}

}).bind(this);
},

_getInternalOnPressListItemTapHandler : function(){
return  (function(model,evnt, item) {
for(let index in this.onItemPressHandlers){
    const handler = this.onItemPressHandlers[index];
    handler(model,evnt, item);
}
if(model){
    this._modelWithPressedEventOnElement.push(model);
}
}).bind(this);
},

_getInternalOnPressUpListItemTapHandler : function(){
return  (function(model,evnt, item) {
const i  = this._modelWithPressedEventOnElement.indexOf(model);
if(i > -1){
    for(let index in this.onItemPressUpHandlers){
        const handler = this.onItemPressUpHandlers[index];
        handler(model,evnt, item);
    }
    this._modelWithPressedEventOnElement.splice(i,1);
    const ii  = this._modelWithLastPressedUpEventOnElement.indexOf(model)
    if(ii  > -1)
    {
        this._modelWithLastPressedUpEventOnElement.splice(ii,1);
        this._timestampWithLastPressedUpEventOnElement.splice(ii,1);
    }
    this._modelWithLastPressedUpEventOnElement.push(model);
    this._timestampWithLastPressedUpEventOnElement.push((new Date()).getTime());
}
}).bind(this);
},

_getInternalOnItemSwipeRight : function(){
return  (function(model,evnt, item) {
for(let index in this.onSwipeRightRevealHandlers){
    const handler = this.onSwipeRightRevealHandlers[index];
    handler(model,evnt, item);
}		
}).bind(this);
},

_getInternalOnOptionButtonTapped : function(){
return  (function(model,evnt, item) {
for(let index in this.onOptionButtonTappedHandlers){
    const handler = this.onOptionButtonTappedHandlers[index];
    handler(model,evnt, item);
}		
}).bind(this);
},

_onAnimationEnd : function(){
return  (function(model,evnt, item){
    let optionEl, songInfoBlockEl;
if(evnt.animationName == "songGettingAddedPlaylistAnimation1")
{
    optionEl = item.querySelector("#songAddToQueueTooltip");
    optionEl.innerHTML = "<div>Added<br>To<br>Queue</div>";
    optionEl.className += " optionSelected";
    songInfoBlockEl = item.querySelector(".songLibraryWrapper");
    songInfoBlockEl.style["-webkit-animation"] = "songGettingAddedPlaylistAnimation2 500ms ease forwards";
}else{
    if(evnt.animationName == "songGettingAddedPlaylistAnimation2")
    {
        optionEl = item.querySelector("#songAddToQueueTooltip");
        optionEl.innerHTML = "<div>Add<br>To<br>Queue</div>";
        optionEl.className = optionEl.className.replace( /(?:^|\s)optionSelected(?!\S)/g , '' );
        songInfoBlockEl = item.querySelector(".songLibraryWrapper");
        songInfoBlockEl.style["-webkit-animation"] = "";
        //this.trigger("itemSwipedRight",model);
        this._getInternalOnItemSwipeRight()(model,evnt, item);
    }else{
        songInfoBlockEl = item.querySelector(".songLibraryWrapper");
        songInfoBlockEl.style["-webkit-animation"] = "";
    }
}

}).bind(this);

},

getItemEventHandler : function(){
const eventHandler = $.extend( this._getDefaultItemEventHandler(),this._customItemEventHandler);
return eventHandler;
},

_getDefaultItemEventHandler : function(){
const handlers = {};
handlers["click .infiniteListItem:first-child"] = this._getInternalOnListItemTapHandler();
handlers["press .infiniteListItem:first-child"] = this._getInternalOnPressListItemTapHandler();
handlers["pressup .infiniteListItem:first-child"] = this._getInternalOnPressUpListItemTapHandler();
handlers["touchend .infiniteListItem:first-child"] = this._getInternalOnPressUpListItemTapHandler();
handlers["mouseup .infiniteListItem:first-child"] = this._getInternalOnPressUpListItemTapHandler();
//handlers["panright .infiniteListItem:first-child"] = this._getOnSwipeRightAnimationHandler();
handlers["panstart .infiniteListItem:first-child"] = this._onDragStart();
handlers["panright .infiniteListItem:first-child"] = this._onDragRight();
handlers["panleft .infiniteListItem:first-child"] = this._onDragLeft();
handlers["panend .infiniteListItem:first-child"] = this._onRelease();
handlers["click div.songOptionBtnWrapper > #songLibraryOptionButtuon"] = this._getInternalOnOptionButtonTapped();
//	handlers["webkitAnimationEnd .infiniteListItem:first-child"] =  this._onAnimationEnd();
//	handlers["oanimationend .infiniteListItem:first-child"] =  this._onAnimationEnd();
//	handlers["msAnimationEnd .infiniteListItem:first-child"] =  this._onAnimationEnd();
//	handlers["animationend .infiniteListItem:first-child"] =  this._onAnimationEnd();
return handlers;
},

setItemViewUpdateDuringScrollingHandler : function(handler){
this.itemViewUpdateDuringScrollingHandler = handler;
},


addOnItemTapHandler : function(handler){
this.onItemTapHandlers.push(handler);
},

addOnItemPressHandler : function(handler){
this.onItemPressHandlers.push(handler);
},

addOnItemPressUpHandler : function(handler){
this.onItemPressUpHandlers.push(handler);
},

addOnSwipeRightRevealHandler : function(handler){
this.onSwipeRightRevealHandlers.push(handler);
},

setSwipeRightRevealEnabled : function( value){
this.swipeRightRevealEnabled = value;
},

addOnOptionButtonTappedHandlers : function(handler){
this.onOptionButtonTappedHandlers.push(handler);
},

setModelTransformHandlerBeforeTemplatePiping: function(beforeTemplateHandler){
this.beforeTemplateHandler = beforeTemplateHandler;
},

isUpdating : function(){
return this._isUpdating;
},

setUpdating : function(updating){
this._isUpdating = updating;
if(this._showEmtptyInfoBox && this._emptyInfoBox){
if(this._isUpdating){
    this._emptyInfoBox.showLoading();
    this._emptyInfoBox.show();
    this.$el.find(".infiniteListOuterContainer").hide();
}else{
    this._emptyInfoBox.hideLoading();
    this._displayInfoBoxIfApplicable();
    this.$el.find(".infiniteListOuterContainer").show();
}
}
},

setErrorMessage : function(errorMessage, helpErrorMessage, errorActionButtonLabel){
if(this._showEmtptyInfoBox && this._emptyInfoBox){
if(errorMessage){
    this._emptyInfoBox.showErrorMessage(errorMessage, helpErrorMessage, errorActionButtonLabel);
    this._emptyInfoBox.show();
    this.$el.find(".infiniteListOuterContainer").hide();
}else{
    this._emptyInfoBox.hideErrorMessage();
    this._displayInfoBoxIfApplicable();
    this.$el.find(".infiniteListOuterContainer").show();
}
}
},

_onRootElementResize : function(){
if(this._emptyInfoBox && this._emptyInfoBox.$el){
const root$el = $( this.listRootElement );
const rootElInnerHeight =root$el.innerHeight() ;
if( rootElInnerHeight != 0){
    this._emptyInfoBox.$el.css("height",rootElInnerHeight + "px");
}
}


},

initializeImageLazyLoadPlugin : function(container$elForImageLazyLoad){
if(!this.lazyLoadingPluginInitialized){
this._container$elForImageLazyLoad = container$elForImageLazyLoad;
const scrollContainer$el = this._container$elForImageLazyLoad? this._container$elForImageLazyLoad: this.$el.find(".infiniteListOuterContainer")
Utils.getInstance().initJqueryLazyLoadingPluginOnElemNew($(this.listRootElement), "img.lazy", scrollContainer$el);
this.lazyLoadingPluginInitialized = true;
}
},

lazyLoadImages : function(){
const scrollContainer$el = this._container$elForImageLazyLoad? this._container$elForImageLazyLoad: this.$el.find(".infiniteListOuterContainer");
this.cancelPendingImageLoading();
window.log("Scheduling Lazy Images Loading");
const pendingImageLazyLoading  = window.setTimeout((function(){
this.self._pendingImageLazyLoading = null;
window.log("Trigger Lazy Images Loading");
this.scrollContainer$el.trigger("scrollstop");
}).bind({"scrollContainer$el": scrollContainer$el, "self": this},this._imageLoadingDelayInMs));
this._pendingImageLazyLoading = pendingImageLazyLoading;
},

cancelPendingImageLoading : function(){
if(this._pendingImageLazyLoading){
clearTimeout(this._pendingImageLazyLoading);
window.log("Cancelled Current Lazy Images Loading");
}
},

updateModels : function(newModels){
this.model = newModels;
this.infiniteList.updateModels(newModels);
this._displayInfoBoxIfApplicable();
this.lazyLoadImages();

},

updateModel : function(newModel, silent){
this.infiniteList.updateModel(newModel, silent);
this._displayInfoBoxIfApplicable();
this.lazyLoadImages();
},

_initInfoBoxIfApplicable : function(){
if(this._showEmtptyInfoBox ){
if(!this._customInfoBoxInfo){
    this._emptyInfoBox =  new InfoBoxView(this._infoBoxInfoParams);
    this._emptyInfoBox.render();
    this.listRootElement.appendChild(this._emptyInfoBox.el);
}else{
    this._emptyInfoBox = this._customInfoBoxInfo;
}
this.listenTo(this._emptyInfoBox, "actionButtonPressed",this._onDefaultInfoboxButtonCalled.bind(this));
}
this._displayInfoBoxIfApplicable();
},

updateInfoBoxIfApplicable : function(iconClass,message,helpMessage,infoBoxClassName,actionButtonLabel,actionButtonIconClass){
if(this._showEmtptyInfoBox && this._emptyInfoBox){
this._emptyInfoBox.update(iconClass,message,helpMessage,infoBoxClassName,actionButtonLabel,actionButtonIconClass);
this._displayInfoBoxIfApplicable();
}
},

_displayInfoBoxIfApplicable : function(){
if(this._showEmtptyInfoBox && this._emptyInfoBox){
if(this.model && this.model.length > 0){
    this._emptyInfoBox.hide();
}else{
    this._emptyInfoBox.show();
}
}

},


render : function() {
this.setElement(this.listRootElement);
this._initInfoBoxIfApplicable();


this.infiniteList = new infiniteListAdapter(this.listRootElement ,
this._getInfiniteListTemplateFuntion(), this.model, this.getItemEventHandler(),
this.defaultNumberOfElements, this.defaultHeightElement, 
this._onListItemUpdateHandlerFunction(), this.defaultOverrideMainContainerStyle,
this.defaultOverrideSingleItemStyle, this.resizePlaceHolderElem, this.placeHolderHeight, null, this._modelIdAttribute,this._numberOfItemsToDisplayPerRow );
//$(this.listRootElement).css("background-color","white");
if(!this._doNotInitializeLazyLoadingAtRender){
const scrollContainer$el = this._container$elForImageLazyLoad? this._container$elForImageLazyLoad: this.$el.find(".infiniteListOuterContainer");
Utils.getInstance().initJqueryLazyLoadingPluginOnElemNew($(this.listRootElement), "img.lazy", scrollContainer$el);
this.lazyLoadingPluginInitialized = true;
}
return this;
}

});
trebbleListView.defaultItemHeight = 60;
export default trebbleListView;