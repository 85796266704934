import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import Utils from "models/helper/Utils";
import PlaylistCollection from "collections/PlaylistCollection";
import PlaylistListView from "views/common/PlaylistListView";
import PersistentModels from "services/PersistentModels";
import PlaylistListExtendedView from "views/common/PlaylistListExtendedView";
import ti18n from "i18n!nls/Commoni18n";
import RSVP from "rsvp";
import PlayerService from "services/PlayerService";

const PlaylistListController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if(options)
        {
            if(options.model)
            {
                if(Utils.getInstance().is("Array",options.model))
                {
                    const playlistJsonArray = options.model;
                    this.model = new PlaylistCollection(playlistJsonArray);
                    /*for(let index in playlistJsonArray){
                        const playlistJson = playlistJsonArray[index];
                        this.model.add(new FixedPlaylist(playlistJson));
                    }*/
                }else{
                        this.model = options.model;
                }
                
            }
            if(options.extendedView){
                this._extendedView = options.extendedView;
            }else{
                this._extendedView = false;
            }
            
            if(options.resizePlaceHolderElem)
            {
                this._resizePlaceHolderElem = options.resizePlaceHolderElem;
            }else{
                this._resizePlaceHolderElem = false;
            }
            
            if(options.loadHandler)
            {
                this._loadHandler = options.loadHandler;
            }
            
            if(options.onPlaylistSeclectedHandler)
            {
                this._onPlaylistSeclectedHandler = options.onPlaylistSeclectedHandler;
            }
            if(options.onPlaylistPressedHandler)
            {
                this._onPlaylistPressedHandler = options.onPlaylistPressedHandler;
            }
            if(options.onPlaylistPressedUpHandler)
            {
                this._onPlaylistPressedUpHandler = options.onPlaylistPressedUpHandler;
            }
            if(options.onPlaylistPlayButtonTapedHandler){
                this._onPlaylistPlayButtonTapedHandler = options.onPlaylistPlayButtonTapedHandler;
            }
            if(options.onPlaylistEditButtonTapedHandler){
                this._onPlaylistEditButtonTapedHandler = options.onPlaylistEditButtonTapedHandler;
            }
            if(options.onPlaylistShareButtonTapedHandler){
                this._onPlaylistShareButtonTapedHandler = options.onPlaylistShareButtonTapedHandler;
            }
            if(options.onMoreButtonForPlaylistSeclectedHandler){
                this._onMoreButtonForPlaylistSeclectedHandler =  options.onMoreButtonForPlaylistSeclectedHandler;
            }
            if(options.customCollectionConstructor){
                this._customCollectionConstructor =  options.customCollectionConstructor;
            }

            if(options.customItemViewUpdateDuringScrollingHandler){
                this._customItemViewUpdateDuringScrollingHandler =  options.customItemViewUpdateDuringScrollingHandler
            }

            if(options.customItemViewUpdateDuringScrollingHandlerForExtendedView){
                this._customItemViewUpdateDuringScrollingHandlerForExtendedView =  options.customItemViewUpdateDuringScrollingHandlerForExtendedView;
            }

            if(options.customMapFunctionToConvertJsonObjToTemplateData){
                this._customMapFunctionToConvertJsonObjToTemplateData = options.customMapFunctionToConvertJsonObjToTemplateData;
            }
            
            if(options.heightMargin){
                this._heightMargin = options.heightMargin;
            }
            if(options.customInfoBoxParams){
                this._customInfoBoxParams = options.customInfoBoxParams;
            }
            if(options.customEmptyInfoBoxParams){
                this._customEmptyInfoBoxParams = options.customEmptyInfoBoxParams;
            }
            if(options.onInfoBoxButtonPressed){
                this._onInfoBoxButtonPressed = options.onInfoBoxButtonPressed;
            }
            if(options.container$elForImageLazyLoad){
                this._container$elForImageLazyLoad = options.container$elForImageLazyLoad;
            }
            if(options.doNotInitializeLazyLoadingAtRender){
                this._doNotInitializeLazyLoadingAtRender = options.doNotInitializeLazyLoadingAtRender;
            }
            if(options.showOnePlaylistPerLine){
                this._showOnePlaylistPerLine = options.showOnePlaylistPerLine;
            }
        }
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },
    
    
    updateListOfPlaylists : function(arrayOfPlaylistsJson){
        this.model.reset(arrayOfPlaylistsJson/*, {silent: true}*/);
    },

    reload : function(){
        this.view.load();
    },
    
    onPlaylistSelected : function(selectedPlaylistModel){
        if(this._onPlaylistSeclectedHandler){
            this._onPlaylistSeclectedHandler(selectedPlaylistModel);
        }else{
            if(selectedPlaylistModel){
                PersistentModels.getInstance().getRouter().showPublicRadioDetailPage(selectedPlaylistModel, true);
            }
        }
    },

    onPlaylistEditButtonClicked : function(selectedPlaylistModel){
        if(this._onPlaylistEditButtonTapedHandler){
            this._onPlaylistEditButtonTapedHandler(selectedPlaylistModel);
        }else{
            if(selectedPlaylistModel){
                PersistentModels.getInstance().getRouter().showTrebbleInfoEditPage(selectedPlaylistModel, true);
            }
        }
    },

    onPlaylistShareButtonClicked : function(selectedPlaylistModel){
        if(this._onPlaylistShareButtonTapedHandler){
            this._onPlaylistShareButtonTapedHandler(selectedPlaylistModel);
        }else{
            if(selectedPlaylistModel){
                PersistentModels.getInstance().getRouter().showTrebbleSharePopup(selectedPlaylistModel, true);
            }
        }
    },

    onPlaylistPlayButtonTaped : function(playlistSummarySelectedModel){
        if(this._onPlaylistPlayButtonTapedHandler){
            this._onPlaylistPlayButtonTapedHandler(playlistSummarySelectedModel);
        }else{
            if(PlayerService.getInstance().isPlaylistCurrentlyLoaded(playlistSummarySelectedModel)){
                if(PlayerService.getInstance().isMusicPlaying()){
                    PlayerService.getInstance().pauseMusicPlaying();
                }else{
                    PlayerService.getInstance().resumeMusicPlaying();
                }
            }else{
                PlayerService.getInstance().playTrebble(playlistSummarySelectedModel);
            }
            
        }
    },
    
    onActionPressedOnPlaylist : function(playlistSummarySelectedModel){
        if(this._onPlaylistPressedHandler){
            this._onPlaylistPressedHandler(playlistSummarySelectedModel);
        }else{
            if(playlistSummarySelectedModel){
                const audioPreviewUrl = playlistSummarySelectedModel.getAudioPreviewUrl();
                if(audioPreviewUrl){
                    PersistentModels.getInstance().getPlayer().playAudioPreview(audioPreviewUrl);
                }else{
                    Utils.getInstance().showShortToastMessage(window.getI18n(ti18n,"NO_PREVIEW_AVAILABLE_PREVIEW_ARE_AUTOMATICALLY_CREATED_EVERY_X_HOURS"));
                }
            }
        }
    },
    onActionPressedUpOnPlaylist : function(selectedPlaylistModel){
        if(this._onPlaylistPressedUpHandler){
            this._onPlaylistPressedUpHandler(selectedPlaylistModel);
        }else{
            PersistentModels.getInstance().getPlayer().terminateAudioPreviewAndResumePlayback();
        }
    },
    onMoreButtonForPlaylistSelected : function(selectedPlaylistModel){
        if(this._onMoreButtonForPlaylistSeclectedHandler){
            this._onMoreButtonForPlaylistSeclectedHandler(selectedPlaylistModel);
        }
    },
    
    _onDefaultInfoboxButtonCalled : function(){
        if(this._onInfoBoxButtonPressed){
            this._onInfoBoxButtonPressed()
        }
    },
    
    initializeImageLazyLoadPlugin : function(container$elForImageLazyLoad){
        this.view.initializeImageLazyLoadPlugin(container$elForImageLazyLoad);
    },
    
    cancelPendingImageLoading : function(){
        this.view.cancelPendingImageLoading();
    },
    
    filterList: function(searchCharaterers, filterOptions, sortOptions){
         if(this.view){
             this.view.filterList(searchCharaterers,null, filterOptions, sortOptions);
         }
     },
     
     clearSearchfilter : function(){
         if(this.view){
             this.view.clearSearchfilter();
         }
     },


    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                if(!this.model)
                {
                    this.model = new PlaylistCollection();
                }
                if(this._extendedView){
                    this.view = new PlaylistListExtendedView({
                        "collection" : this.model,
                        "delayOnCollectionUpdate" : 1000,
                        "resizeToAvailableScreenSizeOnOrientationChange" : true,
                        "resizePlaceHolderElem" : this._resizePlaceHolderElem,
                        "loadHandler" : this._loadHandler,
                        "heightMargin" : this._heightMargin,
                        "customInfoBoxParams" : this._customInfoBoxParams,
                        "customEmptyInfoBoxParams": this._customEmptyInfoBoxParams,
                        "container$elForImageLazyLoad": this._container$elForImageLazyLoad,
                        "doNotInitializeLazyLoadingAtRender" : this._doNotInitializeLazyLoadingAtRender,
                        "showOnePlaylistPerLine": this._showOnePlaylistPerLine,
                        "customCollectionConstructor": this._customCollectionConstructor,
                        "customMapFunctionToConvertJsonObjToTemplateData": this._customMapFunctionToConvertJsonObjToTemplateData,
                        "customItemViewUpdateDuringScrollingHandler":this._customItemViewUpdateDuringScrollingHandlerForExtendedView
                        
                    });
                }else{
                    this.view = new PlaylistListView({
                        "collection" : this.model,
                        "delayOnCollectionUpdate" : 1000,
                        "resizeToAvailableScreenSizeOnOrientationChange" : true,
                        "resizePlaceHolderElem" : this._resizePlaceHolderElem,
                        "loadHandler" : this._loadHandler,
                        "heightMargin" : this._heightMargin,
                        "customInfoBoxParams" : this._customInfoBoxParams,
                        "customEmptyInfoBoxParams": this._customEmptyInfoBoxParams,
                        "container$elForImageLazyLoad": this._container$elForImageLazyLoad,
                        "doNotInitializeLazyLoadingAtRender" : this._doNotInitializeLazyLoadingAtRender,
                        "showOnePlaylistPerLine": this._showOnePlaylistPerLine,
                        "customCollectionConstructor": this._customCollectionConstructor,
                        "customMapFunctionToConvertJsonObjToTemplateData": this._customMapFunctionToConvertJsonObjToTemplateData,
                        "customItemViewUpdateDuringScrollingHandler":this._customItemViewUpdateDuringScrollingHandler
                    });
                }
                this.listenTo(this.view, "itemTaped",this.onPlaylistSelected);
                this.listenTo(this.view, "itemTapedPlay",this.onPlaylistPlayButtonTaped);
                this.listenTo(this.view, "itemTapedEdit",this.onPlaylistEditButtonClicked);
                this.listenTo(this.view, "itemTapedShare",this.onPlaylistShareButtonClicked);
                this.listenTo(this.view, "itemPressed",this.onActionPressedOnPlaylist);
                this.listenTo(this.view, "itemPressedUp",this.onActionPressedUpOnPlaylist);
                 this.listenTo(this.view, "infoBoxButtonPressed",this._onDefaultInfoboxButtonCalled.bind(this));
                 this.listenTo(this.view, "itemOptionBtnTapped",this.onMoreButtonForPlaylistSelected.bind(this));
                this.view.render();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default PlaylistListController;