import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleAudioCompilationListItemView from "views/common/TrebbleAudioCompilationListItemView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import ti18n from 'i18n!nls/TrebbleAudioListItem18n';
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const TrebbleAudioListItemController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.model = options.model;
        this.context = options.context;
        this._customImageUrlProperty = options.customImageUrlProperty? options.customImageUrlProperty : null;
        this._notTransparent = options.notTransparent ? options.notTransparent: false;
        this._notPlayable =  options.notPlayable ? options.notPlayable: false;
        this._canBeSelected =  options.canBeSelected ? options.canBeSelected: false;
        this._canBeDeleted = options.canBeDeleted ? options.canBeDeleted : false;
        this._covertArtNotDisplayed = options.covertArtNotDisplayed ? options.covertArtNotDisplayed: false;
        this._onTrebbleAudioCompilationSelected = options.onTrebbleAudioCompilationSelected ? options.onTrebbleAudioCompilationSelected:null;
        this._onTrebbleAudioCompilationUnselected = options.onTrebbleAudioCompilationUnselected ? options.onTrebbleAudioCompilationUnselected:null;
        this._onTrebbleAudioCompilationRequestToBeDeleted = options.onTrebbleAudioCompilationRequestToBeDeleted ? options.onTrebbleAudioCompilationRequestToBeDeleted:null;
        this._onMoreRequestToShowMoreOptions = options.onMoreRequestToShowMoreOptions ? options.onMoreRequestToShowMoreOptions:null;
        this._onPlaybackStateChange = options.onPlaybackStateChange ;

        _.extend(this, Backbone.Events);
        this._listenToPlayerEvents();
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    destroy: function(){
        try{
            this.stopListening();
            if(this.view && this.view.remove){
                this.view.remove();
            }
        }catch(error){
            console.error(error);
        }
    },

    _listenToPlayerEvents : function(){
        if(!this._notPlayable){
            this.listenTo(PersistentModels.getInstance().getPlayer(),"songPreviewStartedPlaying",this.updatePlaybackButton.bind(this));
            this.listenTo(PersistentModels.getInstance().getPlayer(),"songPreviewUnLoaded",this.updatePlaybackButton.bind(this));
            this.listenTo(PersistentModels.getInstance().getPlayer(),"songPreviewChanged",this.updatePlaybackButton.bind(this));
            this.listenTo(PersistentModels.getInstance().getPlayer(),"songPreviewLoading",this.updatePlaybackButton.bind(this));
            this.listenTo(PersistentModels.getInstance().getPlayer(),"songPreviewLoaded",this.updatePlaybackButton.bind(this));
            this.listenTo(PersistentModels.getInstance().getPlayer(),"errorPreview",this.updatePlaybackButton.bind(this));
            this.listenTo(PersistentModels.getInstance().getPlayer(),"songPreviewFailedLoading",this.updatePlaybackButton.bind(this));
            this.listenTo(PersistentModels.getInstance().getPlayer(),"songPreviewPaused",this.updatePlaybackButton.bind(this));
            this.listenTo(PersistentModels.getInstance().getPlayer(),"songPreviewCancelled",this.updatePlaybackButton.bind(this));
        }
    },



    updatePlaybackButton : function(){
        const playerModel =  PersistentModels.getInstance().getPlayer();
        if(this.model.getAudioUrl() == playerModel.getAudioPreviewURL()){
            if(playerModel.isPreviewLoading() ){
                this.view.setPlaybackState(false, true);
                this._notifyPlaybackStateListener(false, true);
                
            }else{
                if (playerModel.isPreviewPlaying()) {
                    this.view.setPlaybackState(true, false);
                    this._notifyPlaybackStateListener(true, false);
                } else {
                    this.view.setPlaybackState(false, false);
                    this._notifyPlaybackStateListener(false, false);
                }
            }
        }else{
            this.view.setPlaybackState(false, false);
            this._notifyPlaybackStateListener(false, false);
        }

    },

    setAudioIsLoading  : function(){
        this.view.setPlaybackState(false, true);
    },

    _notifyPlaybackStateListener : function(isPlaying, isLoading){
        if(this._onPlaybackStateChange){
            this._onPlaybackStateChange(this.model,isPlaying,  isLoading);
        }
    },


    stopPreviewAndResumePlayback : function(){
        PersistentModels.getInstance().getPlayer().terminateAudioPreviewAndResumePlayback();
    },


    playAudio: function(){
        PersistentModels.getInstance().getPlayer().playAudioPreview(this.model.getAudioUrl());
    },


    stopAudio: function(){
        PersistentModels.getInstance().getPlayer().terminateAudioPreviewAndResumePlayback();
    },

    audioCompilationSelected: function(){
        if(this._onTrebbleAudioCompilationSelected){
            this._onTrebbleAudioCompilationSelected(this.model)
        }
    },

    audioCompilationUnselected: function(){
        if(this._onTrebbleAudioCompilationUnselected){
            this._onTrebbleAudioCompilationUnselected(this.model)
        }
    },

    addedToFavorites: function(){

    },

    removeFromFavorites: function(){

    },

    showOptionMenu: function(){
        if(this._onMoreRequestToShowMoreOptions){
            this._onMoreRequestToShowMoreOptions(this.model)
        }
    },

    onDeleteButtonClicked: function(){
        if(this._onTrebbleAudioCompilationRequestToBeDeleted){
            this._onTrebbleAudioCompilationRequestToBeDeleted(this.model)
        }
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                const params = {};
                params.model = this.model;
                params.customImageUrlProperty = this._customImageUrlProperty;
                params.notTransparent = this._notTransparent;
                params.canBeSelected = this._canBeSelected;
                params.covertArtNotDisplayed  = this._covertArtNotDisplayed;
                params.notPlayable = this._notPlayable;
                params.doNotShowArtistName = this._doNotShowArtistName;
                params.canBeDeleted = this.model.getCanBeDeleted() ;
                this.view = new TrebbleAudioCompilationListItemView(params);

                this.listenTo(this.view, "playAudio",this.playAudio);
                this.listenTo(this.view, "stopAudio",this.stopAudio);
                this.listenTo(this.view, "audioCompilationSelected",this.audioCompilationSelected);
                this.listenTo(this.view, "audioCompilationUnselected",this.audioCompilationUnselected);
                this.listenTo(this.view, "addedToFavorites",this.addedToFavorites);
                this.listenTo(this.view, "removeFromFavorites",this.removeFromFavorites);
                this.listenTo(this.view, "showOptionMenu",this.showOptionMenu);
                this.listenTo(this.view, "onDeleteButtonClicked" , this.onDeleteButtonClicked)
                
                this.view.render();
                this.updatePlaybackButton();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

}

});

export default TrebbleAudioListItemController;