import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import ArtistListController from "controllers/common/ArtistListController2";
import PageView from "views/common/PageView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import RSVP from "rsvp";

const ArtistListPageController = Chaplin.Controller.extend({


    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if (options) {
            if (options.artistCollection) {
                this.model = options.artistCollection;
                this.artistCollection = options.artistCollection;
            }
            if (options.pageTitle) {
                this.pageTitle = options.pageTitle;
            }
            if (options.pageId) {
                this.pageId = options.pageId;
            }
            if (options.searchKeyword) {
                this.searchKeyword = options.searchKeyword;
            }
            
        }
        const artisrtListControllerParams = {};
        artisrtListControllerParams.sortField = "artistName";
        artisrtListControllerParams.resizePlaceHolderElem = false;
        artisrtListControllerParams.loadHandler = (options && options.artistListLoadHandler)?options.artistListLoadHandler: this.getArtistListLoadHandler().bind(this);
        this.artistListController = new ArtistListController(artisrtListControllerParams);
                    
    },
    getSearchKeyword :function(){
        return this.searchKeyword;
    },
    
    getModel : function(){
        return this.model;
    },
    
    getView : function(){
        return this.view;
    },
    
    updateListOfArtists : function(arrayOfArtistsJson){
        this.model.reset(arrayOfArtistsJson);
    },
    



    
    getArtistListLoadHandler : function(){
        const searchKeyword = this.searchKeyword;
        return function(){
            return TrebbleClientAPIHelper.getInstance().searchForAllArtists(searchKeyword.trim(), 100, 1).then(function(results){
                return results.artists;
            });
        }
    },

    onViewRemoved:function(){
        this.stopListening();
    },
    
    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve,reject){
            try{
                this.artistListController.buildModelAndRenderView().then((function(){
                    const artistListView = this.artistListController.getView();
                    const pageViewParams= {};
                    pageViewParams.pageId = this.pageId;
                    pageViewParams.pageTitle = this.pageTitle;
                    pageViewParams.contentView = artistListView;
                    pageViewParams.addContentLeftRightPadding = true;
                    this.view = new PageView(pageViewParams);
                    this.view.render();
                    this.listenTo(this.view, "remove", this.onViewRemoved.bind(this));
                    resolve(this);
                }).bind(this)).catch(reject);

            }catch(error){
                reject(error);
            }
            
        }).bind(this));

        
    }

});
export default ArtistListPageController;