
import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerOperation from "models/audioEditor/sequencerOperation/SequencerOperation";
import RSVP from "rsvp";


const DeleteSequencerNodeOperation =  SequencerOperation.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperation.apply(this, [attributes, options]);
    },

    execute : function(sequencerNode){
        const nextNode =  sequencerNode.getNext();
        const previousNode =  sequencerNode.getPrevious();
        this.removeAudioNode(sequencerNode);
        this.getSequencer().getHistoryManagement().addDeleteSequencerNodeLog(sequencerNode, previousNode? previousNode: nextNode, previousNode?false: true);
    },

    undo : function(sequencerDeleteSequencerNodeLog ){
        if(sequencerDeleteSequencerNodeLog.getPreviousNode()){
            this.insertAudioNodeArray([sequencerDeleteSequencerNodeLog.getSequencerNode()], sequencerDeleteSequencerNodeLog.getPreviousNode());
        }
        if(sequencerDeleteSequencerNodeLog.getNextNode()){
            this.insertAudioNodeArray([sequencerDeleteSequencerNodeLog.getSequencerNode()], sequencerDeleteSequencerNodeLog.getNextNode(), true);
        }
        this.insertAudioNodeArray([sequencerDeleteSequencerNodeLog.getSequencerNode()]);   
    },

    redo: function(sequencerDeleteSequencerNodeLog){
        this.removeAudioNode(sequencerDeleteSequencerNodeLog.getSequencerNode());
    },

});


export default DeleteSequencerNodeOperation; 