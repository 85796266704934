import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PusherHelper from "models/helper/PusherHelper";
import FacebookEventTrackingHelper from "models/helper/FacebookEventTrackingHelper";
import LiveChatWidgetHelper from "models/helper/LiveChatWidgetHelper";
import MixpanelHelper from "models/helper/MixpanelHelper";
import NotificationDefaultTemplate from "text!../../../templates/common/NotificationDefaultListItemTemplate.html";
import songPickedFromTrebbleNotificationTemplate from 'text!../../../templates/common/SongPickedFromTrebbleNotificationTemplate.html';
import SongLiveLikeFromTrebbleNotificationTemplate from 'text!../../../templates/common/SongLiveLikeFromTrebbleNotificationTemplate.html';
import UserFollowingYouNotificationTemplate from 'text!../../../templates/common/UserFollowingYouNotificationTemplate.html';
import UserFollowingYourTrebbleNotificationTemplate from 'text!../../../templates/common/UserFollowingYourTrebbleNotificationTemplate.html';
import ti18n from "i18n!nls/helperi18n";
import RSVP from "rsvp";

const TREBBLE_GOOGLE_APP_NUMBER = "143968598373";
const CUSTOM_TRACKING = "FROM_PUSH_NOTIFICATION";
const TREBBLE_WEB_APP_BASE_URL ="https://web.trebble.fm/"
const PushNotificationHelperModel = Backbone.Model.extend({
  "default": {

  },

  initialize: function() {
    this._unconsumedNotificationEvent = null;
    this._context = Utils.getInstance().buildContextForPushNotification();
    this._nativeRegistrationId = null;
    this._nativeRegistrationType = null;
    this._nativePushInitiliazed = false;
    this._usernameId = null;

    this.listenTo( PusherHelper.getInstance(),PusherHelper.getInstance().eventTypes.WEB_PUSH_NOTIFICATION, this._processNotificationEventWeb.bind(this));
    this.listenTo(LocalStorageHelper.getInstance(),LocalStorageHelper.getInstance().eventTypes.BEFORE_LOGOUT,this.unregisterNativePushNotification.bind(this));
  },



  _getNativePushObject : function(){
    return this._nativePush;
  },

  initNativePushNotification : function(){
    if(window.waitForCordovaToLoad && window.PushNotification){
      return new RSVP.Promise((function(resolve, reject){
        try{
          this._nativePush = window.PushNotification.init({
            android: {},
            browser: {
              pushServiceURL: "http://push.api.phonegap.com/v1/push"
            },
            ios: {
             alert: "true",
             badge: "true",
             sound: "true"
           },
           windows: {}
         });
          this._getNativePushObject().on("registration", (function(data){
            try{
              this._onNativePushNotificationRegistration(data);
              this._nativePushInitiliazed = true;
              this.trigger("onDailyNotificationRegistrationChange",this._nativePushInitiliazed);
              resolve();
            }catch(error){
              reject(error);
            }
          }).bind(this));
          this._getNativePushObject().on("notification", this._onNativePushNotificationReceived.bind(this));
          this._getNativePushObject().on("error", this._onNativePushNotificationError.bind(this));
        }catch(error){
          reject(error);
        }
      }).bind(this));

}else{
return RSVP.Promise.resolve();
}

},

isUserRegisteredForDailyNotification : function(){
return this._nativePushInitiliazed;
},

_onNativePushNotificationRegistration : function(data){
const registrationId = data.registrationId;
const registrationType = data.registrationType;
this._nativeRegistrationId = registrationId;
this._nativeRegistrationType = registrationType;

this._updateThirdPartyServiceOnRegistrationIdChanged(registrationId, registrationType);


LocalStorageHelper.getInstance().savePushNotificationRegistrationId(registrationId);
LocalStorageHelper.getInstance().savePushNotificationRegistrationType(registrationType);
return Utils.getInstance().getUniqueDevideId(true).then((function(uniqueDeviceId){
this._uniqueDeviceId = uniqueDeviceId;
return TrebbleClientAPIHelper.getInstance().sendPushNotificationRegistrationId(registrationId, Utils.getInstance().getDevicePlatform(), registrationType, uniqueDeviceId, window.getAppUsedLanguage(), Intl.DateTimeFormat().resolvedOptions().timeZone);
}).bind(this)).then((function(){
this._usernameId = LocalStorageHelper.getInstance().getUserInfo()._id;
}).bind(this));


},

_updateThirdPartyServiceOnRegistrationIdChanged : function(registrationId, registrationType){
try{
if(registrationId && window.plugins && window.plugins.appsFlyer ){
  if(registrationType == "APNS"){
    window.plugins.appsFlyer.registerUninstall(registrationId);
  }else{
    window.plugins.appsFlyer.updateServerUninstallToken(registrationId);
  }
}

MixpanelHelper.getInstance().people.setPushId(registrationId);
//LiveChatWidgetHelper.getInstance().registerForPush();

}catch(err){
console.error(err);
}
},

_deletePushRegistrationInfoOnServer : function(){
const registrationId = this._nativeRegistrationId;
const registrationType = this._nativeRegistrationType;
const uniqueDeviceId = this._uniqueDeviceId;
const userId =  this._usernameId;
let p = RSVP.Promise.resolve();
if(registrationId && registrationType && uniqueDeviceId && userId){
p = TrebbleClientAPIHelper.getInstance().deletePushNotificationRegistrationId(userId, registrationId, Utils.getInstance().getDevicePlatform(), registrationType, uniqueDeviceId).then(function(){

});
}
return p.then((function(){
LocalStorageHelper.getInstance().savePushNotificationRegistrationId(null);
LocalStorageHelper.getInstance().savePushNotificationRegistrationType(null);
}).bind(this))
},

_onNativePushNotificationReceived : function(data){
if (window.trebble.routerReady) {
this._processNotificationEvent(data.additionalData);
} else {
this._unconsumedNotificationEvent = data.additionalData;
}
},

_onNativePushNotificationError: function(error){
if(error && error.message == "TOO_MANY_REGISTRATIONS"){
if(window.trebbleAnalyticsHelper){
  window.trebbleAnalyticsHelper.trackEvent("Notification", 'TooManyRegistrationNotificationSetupError', 'Too Many Registrations Notification Error');
}
}else{
console.error("Error Setting up notifications. Error"+error.message);
if(window.trebbleAnalyticsHelper){
  window.trebbleAnalyticsHelper.trackEvent("Notification", 'ErrorSettingUpNotification', 'Error Setting Up Notification');
}
}
},

unregisterNativePushNotification :function(){
if(this._getNativePushObject()){
this._deletePushRegistrationInfoOnServer().then((function(){
  return new RSVP.Promise((function(resolve, reject){
    this._getNativePushObject().unregister((function(){
      this._nativePush = null;
      this._nativePushInitiliazed = false;
      this._nativeRegistrationId = null;
      this._nativeRegistrationType = null;
      this._usernameId = null;
      this.trigger("onDailyNotificationRegistrationChange",this._nativePushInitiliazed);
      resolve();
    }).bind(this), reject);

  }).bind(this));

}).bind(this))

}else{
return RSVP.Promise.resolve(true);
}
},   

isPushNotificationLibraryInstalled: function(){
return window.waitForCordovaToLoad && window.PushNotification;
},


hasNativePushNotificationPermissionGranted : function(){
if(window.waitForCordovaToLoad && window.PushNotification){
return new RSVP.Promise((function(resolve, reject){
 window.PushNotification.hasPermission(function(data) {
  if (data.isEnabled) {
    return true;
  }
  return false;
});

}).bind(this))

}else{
return RSVP.Promise.resolve(false);
}
},




getCustomHandler : function(customPlayloadModel){
  const pageBeingDisplayed = PersistentModels.getInstance().getRouter().isDefaultPageBeingDisplayed()? PersistentModels.getInstance().getRouter().getisDefaultPageBeingDisplayedPromise() : RSVP.Promise.resolve();
switch(customPlayloadModel.getNotificationType())
{
case 'UserMentionedInCapsule':
this.showCapsuleCardInPopup(customPlayloadModel.getNotificationData());
break;
case 'commentOnACapsule':
this.showCommentInPopup(customPlayloadModel.getNotificationData());
break;
case 'CapusleProcessingCompleted':
this.showCapsuleCardInPopup(customPlayloadModel.getNotificationData());
break;
case 'CapusleProcessingFailed':
this.showCapsuleCardInPopup(customPlayloadModel.getNotificationData());
break;
case 'commentWasLiked':
this.showCommentInPopup(customPlayloadModel.getNotificationData());
break;
case 'mentionedInComment':
this.showCommentInPopup(customPlayloadModel.getNotificationData());
break;
case 'replyToComment':
this.showCommentInPopup(customPlayloadModel.getNotificationData());
break;
case 'commentInASongWhileListeningToTrebble':
this.showCommentInPopup(customPlayloadModel.getNotificationData());
break;
case 'commentInASongWhileListeningToCapsuleSimpleSet':
this.showCommentInPopup(customPlayloadModel.getNotificationData());
break;
case 'commentInASongWhileListeningToSongSimpleSet':
this.showCommentInPopup(customPlayloadModel.getNotificationData());
break;
case 'SongFromTrebbleAddedFromListener':
this.showSongPickedFromTrebble(customPlayloadModel);
break;
case 'SongFromTrebbleLiveLikedFromListener':
this.showSongLiveLikeFromTrebble(customPlayloadModel);
break;
case 'UserStartedFollowingYou':
this.showUserStartedFollowingYou(customPlayloadModel);
break;
case 'UserStartedFollowingYourTrebble':
this.showUserStartedFollowingYourTrebble(customPlayloadModel);
break;	
case 'capsuleReplyWhileListeningToTrebble':
PersistentModels.getInstance().getRouter().showNotificationInPopup(customPlayloadModel, null, false);
break;
case 'capsuleReplyWhileListeningToCapsuleSimpleSet':
PersistentModels.getInstance().getRouter().showNotificationInPopup(customPlayloadModel, null, false);
break;
case 'dailyCapsuleFeedIsReady':

pageBeingDisplayed.then((function(){
PersistentModels.getInstance().getRouter().playCapsuleFeedAndShowPlayer(null, null, CUSTOM_TRACKING);
}).bind(this))

if(window.trebbleAnalyticsHelper){
window.trebbleAnalyticsHelper.trackEvent("Notification", 'DailyCapsuleFeedReadyNotificationClicked', 'Daily Capsule Feed Ready Notification Clicked');
}
FacebookEventTrackingHelper.getInstance().trackDailyNotificationToListenToCapsuleFeedClicked();
break;

default:
PersistentModels.getInstance().getRouter().showNotificationInPopup(customPlayloadModel, null, false);
break;
}

},

showSongPickedFromTrebble :function(customPlayloadModel){
const songsInfoJson  = customPlayloadModel.getNotificationData().songsInfo;
const songsInfoModel = [];
for(let index in songsInfoJson){
songsInfoModel.push(Utils.getInstance().getModelFromSongJson(songsInfoJson[index]));
}
customPlayloadModel.getNotificationData().songsInfoModel =  songsInfoModel;
PersistentModels.getInstance().getRouter().showNotificationInPopup(customPlayloadModel, songPickedFromTrebbleNotificationTemplate, false);
},

showSongLiveLikeFromTrebble :function(customPlayloadModel){
const songsInfoJson  = customPlayloadModel.getNotificationData().songsInfo;
const songsInfoModel = [];
for(let index in songsInfoJson){
songsInfoModel.push(Utils.getInstance().getModelFromSongJson(songsInfoJson[index]));
}
customPlayloadModel.getNotificationData().songsInfoModel =  songsInfoModel;
PersistentModels.getInstance().getRouter().showNotificationInPopup(customPlayloadModel, SongLiveLikeFromTrebbleNotificationTemplate, false);
},

showUserStartedFollowingYou :function(customPlayloadModel){
const userJson  = customPlayloadModel.getNotificationData().userJson;
const userModel = Utils.getInstance().getModelFromUserJson(userJson);
customPlayloadModel.getNotificationData().userInfoModel =  userModel;
const customViewEventHandler = {};
const onUsernameCliqued = function(){
PersistentModels.getInstance().getRouter().showUserProfilePageByUsername(userModel.getUsername(),null, userModel, true);
}
customViewEventHandler["click .highlight.usernameFollowing"] = onUsernameCliqued;
PersistentModels.getInstance().getRouter().showNotificationInPopup(customPlayloadModel, UserFollowingYouNotificationTemplate, false,customViewEventHandler);
},

showUserStartedFollowingYourTrebble :function(customPlayloadModel){
const userJson  = customPlayloadModel.getNotificationData().userJson;
const userModel = Utils.getInstance().getModelFromUserJson(userJson);
customPlayloadModel.getNotificationData().userInfoModel =  userModel;
const customViewEventHandler = {};
const onUsernameCliqued = function(){
PersistentModels.getInstance().getRouter().showUserProfilePageByUsername(userModel.getUsername(),null, userModel, true);
}
customViewEventHandler["click .highlight.usernameFollowing"] = onUsernameCliqued;
PersistentModels.getInstance().getRouter().showNotificationInPopup(customPlayloadModel, UserFollowingYourTrebbleNotificationTemplate, false,customViewEventHandler);
},

showCapsuleCardInPopup : function(notificationData){
const capsuleId =  notificationData.capsuleId; 
$.mobile.loading("show");
return TrebbleClientAPIHelper.getInstance().getCapsuleInfoByCapsuleId(capsuleId).then((function(capsuleJson){
$.mobile.loading("hide");
const capsuleModel  = Utils.getInstance().getModelFromCapsuleJson(capsuleJson);
if(capsuleModel){
return PersistentModels.getInstance().getRouter().showCapsuleInPopup(capsuleModel,this._context, false, true, false);
}else{
throw window.getI18n(ti18n,"OOPS_CAPSULE_REFERENCED_HERE_COUNDNT_BE_FOUND_AND_MIGHT_HAVE_BEEN_DELETE");
}
}).bind(this)).catch((function(error){
$.mobile.loading("hide");
throw error;
}).bind(this));
},

showCommentInPopup : function(notificationData){
const songRefId =  notificationData.songRefId; 
const songUri =  notificationData.songUri; 
const commentRefId = notificationData.commentRefId;
let capsuleRefId = notificationData.capsuleRefId;
const commentId = notificationData.commentId;
let songJson = notificationData.songJson;
const hasCapsuleRef = Utils.getInstance().isAudioObjHasCapsuleRef(songJson);
if(hasCapsuleRef){
capsuleRefId = Utils.getInstance().getCapsuleReferenceFromAudioObj(songJson);
if(capsuleRefId){
songJson = null;
}
}
if(songRefId){
$.mobile.loading("show");
return TrebbleClientAPIHelper.getInstance().getSongInfoBySongId(songRefId).then((function(songJson){
$.mobile.loading("hide");
const songModel  = Utils.getInstance().getModelFromSongJson(songJson);
return PersistentModels.getInstance().getRouter().showPageCommentsForSong(songModel,false,commentId, this._context, true);
}).bind(this)).catch((function(error){
$.mobile.loading("hide");
throw error;
}).bind(this));
}else{
if(commentRefId){
$.mobile.loading("show");
return TrebbleClientAPIHelper.getInstance().getCommentInfoByCommentId(commentRefId).then((function(commentJson){
  $.mobile.loading("hide");
  const commentModel  = Utils.getInstance().getModelFromCommentJson(commentJson);
  return PersistentModels.getInstance().getRouter().showPageCommentReplies(commentModel,false,commentId, this._context, true);
}).bind(this)).catch((function(error){
  $.mobile.loading("hide");
  throw error;
}).bind(this));
}else{
if(songJson){
 const songModel  = Utils.getInstance().getModelFromSongJson(songJson);
 return PersistentModels.getInstance().getRouter().showPageCommentsForSong(songModel,false, commentId,this._context, true);
}else{
if(capsuleRefId){
  $.mobile.loading("show");
  return TrebbleClientAPIHelper.getInstance().getCapsuleInfoByCapsuleId(capsuleRefId).then((function(capsuleJson){
    $.mobile.loading("hide");
    const capsuleModel  = Utils.getInstance().getModelFromCapsuleJson(capsuleJson);
    return PersistentModels.getInstance().getRouter().showPageCommentsForCapsule(capsuleModel,false,commentId, this._context, true);
  }).bind(this)).catch((function(error){
    $.mobile.loading("hide");
    throw error;
  }).bind(this));
}else{
  if(commentId){
    //This case is a temporary case to handle the case where the user commented on a song that is not in the trebble user catalog
    //should be handled properly
    $.mobile.loading("show");
    return TrebbleClientAPIHelper.getInstance().getCommentInfoByCommentId(commentId).then((function(commentJson){
      $.mobile.loading("hide");
      const commentModel  = Utils.getInstance().getModelFromCommentJson(commentJson);
      return PersistentModels.getInstance().getRouter().showPageCommentReplies(commentModel,true,commentId, this._context, true);
    }).bind(this)).catch((function(error){
      $.mobile.loading("hide");
      throw error;
    }).bind(this));
  }else{
    window.alertErrorMessage(window.getI18n(ti18n,"NOTIFICATION_ARE_NOT_YET_SUPPORTED"));
  }
}
}
}

}

},

isUnconsumedNotificationNeedRouterPageRedirection: function() {
if (this._unconsumedNotificationEvent ) {
const notificationType = this._unconsumedNotificationEvent.notificationType;
const notificationTypeWithPageDisplay = ["UserMentionedInCapsule" ,"CapusleProcessingCompleted","commentWasLiked","mentionedInComment","replyToComment","commentInASongWhileListeningToTrebble"];
return (notificationTypeWithPageDisplay.indexOf(notificationType) != -1);
} else {
return false;
}
},


_processFCMNotificationData: function(additionalData) {
const isForeground = additionalData.foreground == true;
if(!isForeground){
if(!additionalData.notificationId && this.isMixpanelNotification(additionalData)){
return this._processMixpanelNotificationData(additionalData);
}else{
//IGNORING FOREGROUND NOTIFICATION BECAUSE THE SAME NOTIFICATION HAS PROBABLY BE SENT VIA THE WEB NOTIFICATION
if(window.trebbleAnalyticsHelper){
  window.trebbleAnalyticsHelper.trackEvent("Notification", 'CordovaFCMNotiicationBeingProcessed', 'Cordova FCM notification being processed', null ,{"notificationId": additionalData.notificationId});
}
return  TrebbleClientAPIHelper.getInstance().getNotificationCustomPayloadByNotificationId(additionalData.notificationId).then((function(customPlayloadJson){
 const customPlayloadModel =  Utils.getInstance().getModelFromCustomNotificationPayload(customPlayloadJson);
 /* if(isForeground){
  Utils.getInstance().showPushNotification(customPlayloadModel, null, this.getCustomHandler.bind(this));
  this.onNotificationProcessed(customPlayloadModel);
}else{*/
  this.getCustomHandler(customPlayloadModel);
  if(window.trebbleAnalyticsHelper){
    window.trebbleAnalyticsHelper.trackEvent("Notification", 'CordovaFCMNotiicationSuccessProcessed', 'Cordova FCM notification Success processed', null ,{"notificationId": additionalData.notificationId});
  }
  //}
}).bind(this))
}
}else{
return RSVP.Promise.resolve();
}

},
isMixpanelNotification : function(additionalData){
return additionalData && additionalData.mp && additionalData.mp.distinct_id;
},

_getPromiveIfPageBingAlreadyBeingLoaded : function(){
  let p = RSVP.Promise.resolve();
if(window._trebblePageBeingRenderPromise){
p = window._trebblePageBeingRenderPromise;
}else{
if(window._trebbleIsNavatigatingToPagePromise){
  p = window._trebbleIsNavatigatingToPagePromise
}else{
  if(window._trebbleDefaultPageIsBeingDisplayed){
    p = window._trebbleDefaultPageIsBeingDisplayed;
  }
}    
}

return p;
},

_launchAppByUsingPathAsHash : function(webUrl){
if(webUrl){
const path = webUrl.substr(webUrl.indexOf(TREBBLE_WEB_APP_BASE_URL)+TREBBLE_WEB_APP_BASE_URL.length);
if(path){

  this._getPromiveIfPageBingAlreadyBeingLoaded().catch().then((function(){
    if(window.trebble && window.trebble.routerReady){

      Backbone.history.navigate(path,{trigger:true});
    }else{
     document.addEventListener('routerReady', (function(){ 
      this._getPromiveIfPageBingAlreadyBeingLoaded().catch().then((function(){
       Backbone.history.navigate(path,{trigger:true})
     }).bind(this))
    }).bind(this), false);
   }

 }).bind(this))
}
}
},

_processMixpanelNotificationData : function(additionalData){
if(additionalData.mp_ontap && additionalData.mp_ontap.uri){
const webUrl = additionalData.mp_ontap.uri;
if(window.trebbleAnalyticsHelper){
  window.trebbleAnalyticsHelper.trackEvent("Notification", 'MixpanelFCMNotiicationBeingProcessed', 'Mixpanel FCM notification being processed', null ,{"webUrl": webUrl});
}
this._launchAppByUsingPathAsHash(webUrl)
}
return RSVP.Promise.resolve();
},


_processNotificationEventWeb: function(event, wasDeferred) {
const isForeground = true;
return  TrebbleClientAPIHelper.getInstance().getNotificationCustomPayloadByNotificationId(event.notificationId).then((function(customPlayloadJson){
const customPlayloadModel =  Utils.getInstance().getModelFromCustomNotificationPayload(customPlayloadJson);
if(isForeground){
  Utils.getInstance().showPushNotification(customPlayloadModel, null, this.getCustomHandler.bind(this));
  this.onNotificationProcessed(customPlayloadModel);
}else{
  this.getCustomHandler(customPlayloadModel);
}
}).bind(this))

},

processedUnconsumedNotificationEvent: function() {
const unconsumedNotificationEvent = this._unconsumedNotificationEvent;
this._unconsumedNotificationEvent = null;
return this._processNotificationEvent(unconsumedNotificationEvent, true);
},

_processNotificationEvent: function(additionalData, wasDeferred) {
if (additionalData) {
if(PersistentModels.getInstance() && PersistentModels.getInstance().getRouter() && PersistentModels.getInstance().getRouter().getHomeMenuPanelController()){
  PersistentModels.getInstance().getRouter().getHomeMenuPanelController().setAllNotificationRead(true);
}
if(window.waitForCordovaToLoad){
  return this._processFCMNotificationData(additionalData);
}else{
  return this._processNotificationEventWeb(additionalData,wasDeferred);
}
} else {
return  RSVP.Promise.resolve();
}
},

onNotificationProcessed : function(customPlayloadModel){
if(customPlayloadModel.getNotificationType() === "CapusleProcessingCompleted"){
const capsuleId =  customPlayloadModel.getNotificationData() && customPlayloadModel.getNotificationData()? customPlayloadModel.getNotificationData().capsuleId: null; 
if(capsuleId){               
  TrebbleClientAPIHelper.getInstance().getCapsuleInfoByCapsuleId(capsuleId).then((function(capsuleJson){
    const newCapsuleCreatedModel  =capsuleJson ? Utils.getInstance().getModelFromCapsuleJson(capsuleJson):null;
    if(PersistentModels.getInstance().isCapsuleAddedToUserTrebbles(newCapsuleCreatedModel)){
      PersistentModels.getInstance().getRouter().showSuggestionPopupToShareTrebbleIfApplicable(newCapsuleCreatedModel);  
    }
  }).bind(this));
}
PersistentModels.getInstance().onCapsuleProcessingCompleted();

}
},

});

const pushNotificationHelper = new PushNotificationHelperModel();

export default {
  getInstance: function() {
    return pushNotificationHelper;
  }
};
