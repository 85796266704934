import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import ti18n from "i18n!nls/PaidAccountSubscriptionPopupi18n";
import popupTemplate from 'text!../../../templates/paidAccountSubscriptionPopup/PaidAccountSubscriptionPopup.html';

const Rollout = RolloutHelper.getInstance();
const POPUP_CONTENT_STATUS = {
    "LOADING": "loading",
    "ERROR":"error",
    "RETRIEVED": "content_loaded"
}
const PaidAccountSubscriptionPopupView = Backbone.View.extend({

    template : _.template(popupTemplate),

    "pageName" : "Queue Menu",

    initialize : function(options) {
        
    },


    events : {
        "click #upgradeToTrebblePlusMonthlyBtn[canPurchase=true]" : "upgradeToTrebblePlusMonthly",
        "click #upgradeToTrebblePlusYearlyBtn[canPurchase=true]" : "upgradeToTrebblePlusYearly",
        "click #restorePurchaseBtn" : "restorePurchases",
        "click #showTermsAndConditionsBtn" : "showTermsAndConditions",
        "click #closeBtn" : "close"
    },

    upgradeToTrebblePlusMonthly : function(){
        
        this.trigger("upgradeToTrebblePlusMonthly", this.model);
    },
    
    close : function(){
        if(!this._isClose){
            $.mobile.loading("hide");
            try{
                this.$el.popup('close');
                this._isClose = true;
            }catch(error){
                //This might occur if the popup is already close
                console.error(error);
            }
        }else{
            console.warn("the paid account subscription popup is already close")
        }
    },

    upgradeToTrebblePlusYearly : function(){
        this.trigger("upgradeToTrebblePlusYearly", this.model);	
    },
    
    restorePurchases : function(){
        this.trigger("restorePurchases", this.model);	
    },

    showTermsAndConditions : function(){
        this.trigger("showTermsAndConditions", this.model);	
    },

    setRetrievingAccountSubscriptionProducts : function(){
        this.paidAccountSubsPopupWrapper$el.attr("state",POPUP_CONTENT_STATUS.LOADING);
        this.$el.popup("reposition",{positionTo: "window"});
    },


    setErrorOnFeedBoxLoading : function(error){
        this.paidAccountSubsPopupWrapper$el.attr("state",POPUP_CONTENT_STATUS.ERROR);
        this.paidAccountSubsPopupWrapper$el.find(".error_message").html(error);
        this.$el.popup("reposition",{positionTo: "window"});
    },

    setOrderBeingProcessed : function(orderBeingProcessed){
        if(orderBeingProcessed){
            $.mobile.loading("show");
        }else{
            $.mobile.loading("hide");
        }
    },

    setPurchaseBeingRestored : function(purchaseBeingRestored){
        if(purchaseBeingRestored){
            $.mobile.loading("show");
        }else{
            $.mobile.loading("hide");
        }
    },
    
    setFeedBoxContent : function(trebblePlusYearlyProductInfo,  trebblePlusMonthlyProductInfo){
        if((!trebblePlusYearlyProductInfo &&  !trebblePlusMonthlyProductInfo) ||(!trebblePlusYearlyProductInfo.valid && !trebblePlusMonthlyProductInfo.valid)){
            this.setErrorOnFeedBoxLoading(window.getI18n(ti18n,"YOU_CANNOT_MAKE_PURCHASES"));
            return;
        }else{
            if(trebblePlusYearlyProductInfo){
                this.upgradeToTrebblePlusYearlyBtn$el.find(".cost").html(trebblePlusYearlyProductInfo.price);
                this.upgradeToTrebblePlusYearlyBtn$el.attr("canPurchase",trebblePlusYearlyProductInfo.canPurchase);
            }
            if(trebblePlusMonthlyProductInfo){
                this.upgradeToTrebblePlusMonthlyBtn$el.find(".cost").html(trebblePlusMonthlyProductInfo.price);
                this.upgradeToTrebblePlusMonthlyBtn$el.attr("canPurchase",trebblePlusMonthlyProductInfo.canPurchase);
            }
            this.paidAccountSubsPopupWrapper$el.attr("state",POPUP_CONTENT_STATUS.RETRIEVED);
            this.$el.popup("reposition",{positionTo: "window"});
            return ;
        }
        
    },
    
    

    render : function() {
        const popupTitle = Rollout.getFeatureVariable(Rollout.FEATURES.ENABLE_TREBBLE_PLUS_SUB, "popup_title_to_upgrade_to_trebble_plus_"+window.getAppUsedLanguage(), window.getI18n(ti18n,"REMOVE_ALL_DISPLAYED_ADS"));
        const popupSubtitle = Rollout.getFeatureVariable(Rollout.FEATURES.ENABLE_TREBBLE_PLUS_SUB, "popup_subtitle_to_upgrade_to_trebble_plus_"+window.getAppUsedLanguage(),"");
        const yearlySubPromoMessage = Rollout.getFeatureVariable(Rollout.FEATURES.ENABLE_TREBBLE_PLUS_SUB, "promo_message_for_yearly_sub_"+window.getAppUsedLanguage(), window.getI18n(ti18n,"BEST_VALUE"));

        this.$el.html(this.template({"model":this.model, "ti18n": ti18n,"popupTitle": popupTitle,"popupSubtitle": popupSubtitle,"yearlySubPromoMessage": yearlySubPromoMessage}));
        this.setElement(this.$el.find("div").first());
        const elementId = this.$el.attr('id');
        this.$el.on({
            popupbeforeposition: (function() {
                // const maxHeight = $( window ).height() - 60 + "px";
                const width = $( window ).width() - 0 + "px";
                // this.$el.css( "height", maxHeight );
                this.$el.css( "width", width );
            }).bind(this)
        });
        this.$el.on({
            popupafterclose: (function() {
                this.remove();
                this.trigger("remove");
                this.undelegateEvents();
                
            }).bind(this)});
        $.mobile.pageContainer.prepend(this.$el);
        this.$el.popup({transition:"slideup", dismissible: false,  tolerance: "0,0"});
        if(elementId)
        {
            const screenPopup$el = $("#"+elementId + "-screen");
            $.mobile.pageContainer.remove("#"+elementId + "-screen");
            $.mobile.pageContainer.prepend(screenPopup$el);
        }
        
        this.$el.enhanceWithin();
        Utils.getInstance().closeCurrentJQMPopup().then((function(){
            this.$el.popup('open' );
        }).bind(this));
        this.paidAccountSubsPopupWrapper$el = this.$el.find("#paidAccountSubsPopupWrapper");
        this.upgradeToTrebblePlusYearlyBtn$el = this.$el.find("#upgradeToTrebblePlusYearlyBtn");
        this.upgradeToTrebblePlusMonthlyBtn$el = this.$el.find("#upgradeToTrebblePlusMonthlyBtn");
        this.restorePurchaseBtn$el = this.$el.find("#restorePurchaseBtn");
        this.showTermsAndConditionsBtn$el = this.$el.find("#showTermsAndConditionsBtn");
        this.setRetrievingAccountSubscriptionProducts()
        return this;
    }

});

export default PaidAccountSubscriptionPopupView;