import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import UserGivesFeedbackPopupView from "views/userFivesFeedback/UserGivesFeedbackPopupView";
import PersistentModels from "services/PersistentModels";
import UserGivesFeedbackTemplate from 'text!../../../templates/userFivesFeedback/UserGivesFeedbackTemplate.html';
import ti18n from "i18n!nls/UserGivesFeedbacki18n";
import RSVP from "rsvp";

const UserGivesFeedbackPopupController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.template = UserGivesFeedbackTemplate;
        this.context = options? options.context: null;
        this._headline = options.headline? options.headline: window.getI18n(ti18n, "HOW_CAN_WE_DO_BETTER");
        const customImageUrl = "css/images/Undecided.gif";
        const customSize = window.getDocumentClienWidth() <= 450 ?"200x80":"383x138";
        this._imageUrl = options.imageUrl && options.imageUrl? options.imageUrl: customImageUrl;
        this._feedbackType = options.feedbackType;
        this._buttonLabel = options.buttonLabel ? options.buttonLabel: window.getI18n(ti18n, "SEND");
        this._textareaPlaceholderText = options.textareaPlaceholderText ? options.textareaPlaceholderText: window.getI18n(ti18n, "FEEDBACK_PLACEHOLDER_TEXT");
        _.extend(this, Backbone.Events);
    },



    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    _onPopupClosed : function(){

    },

    sendFeedback : function(userFeedback){
        this.view.close();
        return TrebbleClientAPIHelper.getInstance().saveUserFeedback(userFeedback, this._feedbackType).then((function(){
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("appSuggestion", "SuggestionOnContentSentFor_"+this._feedbackType, "Suggestion On Content Sent for "+this._feedbackType, null, {"userFeedback": userFeedback});
            } 
        }).bind(this))
        //PersistentModels.getInstance().getRouter().showExplorePage(null, true);
    },

    
    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                if(this.template){
                    const params = {};
                    params.radioInfo = this._radioInfo;
                    params.template = this.template;
                    params.headline = this._headline;
                    params.subHeadline = this._subHeadline;
                    params.buttonLabel = this._buttonLabel;
                    params.imageUrl = this._imageUrl;
                    params.textareaPlaceholderText = this._textareaPlaceholderText;
                    params.ti18n = ti18n;
                    const userGivesFeedbackPopupView = new UserGivesFeedbackPopupView(params);
                    userGivesFeedbackPopupView.render();
                    this.view = userGivesFeedbackPopupView;
                    this.listenTo(this.view, "onPopupClosed", this._onPopupClosed.bind(this))
                    this.listenTo(this.view, "sendFeedback", this.sendFeedback.bind(this))
                    
                }
                resolve();


            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default UserGivesFeedbackPopupController;