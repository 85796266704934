import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utils from "models/helper/Utils";
import SequencerNodeInspectorSectionController from "controllers/audioEditor/sequencerNodeInspectorSection/SequencerNodeInspectorSectionController";
import SequencerNodeInspectorSectionPopupView from "views/audioEditor/sequencerNodeInspectorSection/SequencerNodeInspectorSectionPopupView";
import BottomSheet from "libs/bottomSheet/BottomSheet";
import RSVP from "rsvp";

const SequencerNodeInspectorSectionPopupController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.context = options? options.context: null;
        this.model = options.sequencer;
        this._selectionInfoForInitialization =  options.selectionInfoForInitialization;
        _.extend(this, Backbone.Events);
    },


    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    _onPopupClosed : function(){

    },


    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                this._sequencerNodeInspectorSectionController = new SequencerNodeInspectorSectionController({"sequencer":  this.model, "selectionInfoForInitialization": this._selectionInfoForInitialization});

                this._sequencerNodeInspectorSectionController.buildModelAndRenderView().then((function(){
                    if(true){
                        //const bottomSheet = Utils.getInstance().createBottomSheet(this._sequencerNodeInspectorSectionController.getView().$el.get(0), resolve, this._onPopupClosed.bind(this));
                        const bottomSheet = new BottomSheet(this._sequencerNodeInspectorSectionController.getView().$el.get(0), resolve, this._onPopupClosed.bind(this), true);
                        bottomSheet.activate();
                    }else{
                        const params = {"sequencerNodeInspectorSectionView": this._sequencerNodeInspectorSectionController.getView()};
                        this.view = new SequencerNodeInspectorSectionPopupView(params);
                        this.view.render();
                        this.listenTo(this.view, "onPopupClosed", this._onPopupClosed.bind(this))
                    }
                }).bind(this)).then(resolve).catch(reject);

            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default SequencerNodeInspectorSectionPopupController;