
import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerOperationLog from "models/audioEditor/sequencerOperationLog/SequencerOperationLog";
import RSVP from "rsvp";

const SequencerOperationDeleteNodeLog =  SequencerOperationLog.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperationLog.apply(this, [attributes, options]);
        
    },

    getOperationName : function(){
        return "Delete Sequencer Node";
    },

    setSequencerNode : function(sequencerNode){
        this.set("sequencerNode", sequencerNode);
    },

    getSequencerNode : function(){
        return this.get("sequencerNode");
    },

    setPreviousNode : function(previousNode){
        this.set("previousNode", previousNode);
    },

    getPreviousNode : function(){
        return this.get("previousNode");
    },

    setNextNode : function(nextNode){
        this.set("nextNode", nextNode);
    },

    getNextNode : function(){
        return this.get("nextNode");
    },

    setInsertBefore : function(insertBefore){
        this.set("insertBefore", insertBefore);
    },

    getInsertBefore : function(){
        return this.get("insertBefore");
    },



});

export default SequencerOperationDeleteNodeLog; 