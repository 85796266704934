import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";
import PeakMeterWidgetView from "views/common/PeakMeterWidgetView";

const PeakMeterWidgetController =  Chaplin.Controller.extend({


    initialize : function(options) {
        _.extend(this, Backbone.Events);

    },
    
    getModel : function(){
        return this.model;
    },
    
    getView : function(){
        return this.view;
    },
    
    startMeter : function(stream, audioContext){
        this.stream = stream;
        this.audioContext = audioContext;
        this.view.startMeter(stream, audioContext)
    },

    stopMeter: function(){
        this.view.stopMeter()
    },

    isStarted : function(){
        return this.view.isStarted();
    },


    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
            
                const viewParams = {};
                const peakMeterWidgetView = new  PeakMeterWidgetView(viewParams);
                peakMeterWidgetView.render();
                this.view = peakMeterWidgetView;
                resolve(this);
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }
    

});
export default PeakMeterWidgetController;