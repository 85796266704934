
import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerOperation from "models/audioEditor/sequencerOperation/SequencerOperation";
import ReplacementSequencerNode from "models/audioEditor/sequencerNodes/ReplacementSequencerNode";
import RSVP from "rsvp";

const ReplaceSelectedNodesOperation =  SequencerOperation.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperation.apply(this, [attributes, options]);
    },

    execute : function(params){
        throw new Error("Need to be implemented in subclass!");
    },

    undo: function(operationLog){
        throw new Error("Need to be implemented in subclass!");
    },

    redo: function(operationLog){
        throw new Error("Need to be implemented in subclass!");
    },


    replaceSequencerNodes : function(replacementNode, arrayOfNodeToReplace){
        replacementNode.replaceNodes(arrayOfNodeToReplace);
        return replacementNode;
    },

    revertReplacement : function(replacementSequencerNode){
        if(replacementSequencerNode instanceof ReplacementSequencerNode){
            replacementSequencerNode.revertReplacement();
            //replacementSequencerNode.removeFromSequencer();
            return replacementSequencerNode;
        }
    },

});

export default ReplaceSelectedNodesOperation; 