

import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerOperation from "models/audioEditor/sequencerOperation/SequencerOperation";


const InsertNodeArrayOperation =  SequencerOperation.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperation.apply(this, [attributes, options]);
    },

    execute : function(arrayOfSequencerNodes, sequencerNodeToInsertNextTo, insertBefore){

        this.insertAudioNodeArray(arrayOfSequencerNodes, sequencerNodeToInsertNextTo, insertBefore );
        this.getSequencer().getHistoryManagement().addSequencerOperationInsertNodeArrayOperationLog(arrayOfSequencerNodes, sequencerNodeToInsertNextTo, insertBefore);
    },


    undo: function(insertNodeArrayOperationLog){
        const arrayOfSequencerNodes = insertNodeArrayOperationLog.getArrayOfSequencerNodes();
        this.removeAudioNodeArray(arrayOfSequencerNodes);
    },

    redo: function(insertNodeArrayOperationLog){
        this.insertAudioNodeArray(insertNodeArrayOperationLog.getArrayOfSequencerNodes(), insertNodeArrayOperationLog.getSequencerNodeToInsertNextTo(), insertNodeArrayOperationLog.getInsertBefore());
    },





});

export default InsertNodeArrayOperation; 