import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import SongListController from "controllers/common/SongListController";
import DynamicPageView from "views/common/DynamicPageView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlaylistHelper from "models/helper/PlaylistHelper";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import Utils from "models/helper/Utils";
import owlcarousel from "owlcarousel";
import ti18n from "i18n!nls/PlaylistDetailsi18n";
import PersistentModels from "services/PersistentModels";
import PlayerService from "services/PlayerService";
import RSVP from "rsvp";
import DefaultPlaylistDetailsTemplate from 'text!../../../templates/playlistPodcasts/PlaylistDetails.html';

const MIN_SCROLL_POSITION_SHOW_SUBSCRIBE_FLOATING_BUTTON =  500;
const DO_SHOW_SUBSCRIBE_BUTTON_IN_HEADER_WHILE_SCROLLING = true;
const DO_NO_MATCH_BACKGROUND_WITH_IMAGE = false;
const PlaylistDetailsControllerCommon = Chaplin.Controller.extend({


    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if (options) {
            if (options.playlistSummary) {
                this.model = options.playlistSummary;
                this.playlistSummary = options.playlistSummary;
            }
            if (options.pageId) {
                this.pageId = options.pageId;
            }
            if(options.getAllSongsInPlaylistHandler){
                this._getAllSongsInPlaylistHandler = options.getAllSongsInPlaylistHandler;
            }
            
            if(options.eventHandlerMap){
                this._eventHandlerMap = options.eventHandlerMap;
            }
            if(options.pageTemplate){
                this._pageTemplate = options.pageTemplate;
                this._setupEventForDefaultPageTemplate();
            }else{
                this._pageTemplate = DefaultPlaylistDetailsTemplate;
                this._setupEventForDefaultPageTemplate();
            }
            if(options.customInfoBoxInfoParams){
                this._customInfoBoxInfoParams= options.customInfoBoxInfoParams;
            }
            if(options.onInfoButtonPressed){
                this._onInfoButtonPressed= options.onInfoButtonPressed;
            }
            if(options.doNotRemoveFromDocument){
                this._doNotRemoveFromDocument = options.doNotRemoveFromDocument;
            }
            if(options.pageTemplateOptions){
                this._pageTemplateOptions = options.pageTemplateOptions;
            }
            if(options.isPublicPage){
                this._isPublicPage = options.isPublicPage;
            }
            
            this._doNotAlphabecticallySort = options.doNotAlphabecticallySort?options.doNotAlphabecticallySort : false;

        }

        this._subscribeButtonDisplayed = this.model.canBeFollowed() && !this.model.isFollowing();
        const songListControllerParams = {};
        songListControllerParams.resizePlaceHolderElem = false;
        songListControllerParams.heightMargin = 52;// footer player height
        songListControllerParams.doNotSortDisplayedCollection = this._doNotAlphabecticallySort;
        songListControllerParams.loadHandler = this._getAllSongsInPlaylistHandler;
        songListControllerParams.showEmtptyInfoBox = true;
        songListControllerParams.sortField = "dateAdded";
        songListControllerParams.sortDescending = true;
        songListControllerParams.customInfoBoxInfoParams = this._customInfoBoxInfoParams;
        songListControllerParams.onInfoButtonPressed = this._onInfoButtonPressed;
        songListControllerParams.actionExecutedOnSongHandler= this.onActionExecutedOnSong.bind(this);
        if(options.context){
            songListControllerParams.context = options.context;
        }
        this.songListController = new SongListController(songListControllerParams);
        this._defaultBackgroundColor = "#111111";
        this.listenTo(PersistentModels.getInstance(),"songsRemovedToRadios", this._onSongsRemovedToRadios.bind(this));
        this.listenTo(PersistentModels.getInstance(), "songsAddedToRadios", this._onSongsAddedToRadios.bind(this));
        this.listenTo(PersistentModels.getInstance(), "playlistSummarySubsciptionChanged", this.onPlaylistSummarySubsciptionChanged);
        if(this.model){
            this.listenTo(this.model,"change:isFollowing", this.updateFollowButton.bind(this));
            this.listenTo(this.model,"change:numberOfFollowers", this.updateFollowButton.bind(this));
            
        }
    },



    _onSongListFinishLoading : function(){
        //if(!this.model.canBeEdited()){
            //FOR NOW , ALWAYS SHOW CAPSULES TAB AND ALWAYS HIDE MUSIC TAB, MIGHT NOT EVEN NEED TO LOAD IT
            this.view.$el.removeClass("songOrCapsuleLoading");
            if(false && this.songListController.getModel() && this.songListController.getModel().length > 0){
                //When looking at a Trebble that is not yours, always show capsules for now
                this.view.$el.removeClass("showCapsulesOnly");
                this.view.$el.addClass("showSongsOnly");
                this.view.$el.find("#albumList .listHeader").html(window.getI18n(ti18n, "MUSIC"));
            }else{
                this.view.$el.removeClass("showSongsOnly");
                this.view.$el.addClass("showCapsulesOnly");
                this.view.$el.find("#albumList .listHeader").html(window.getI18n(ti18n, "ON_AIR_CAPSULES"));

            }

            //}
        },

        _onSongsRemovedToRadios : function(radioIdToNumberOfSongRemoved){
            if(radioIdToNumberOfSongRemoved){
                for(let radioId in radioIdToNumberOfSongRemoved){
                    const numberOfSongRemoved  = radioIdToNumberOfSongRemoved[radioId];
                    if(this.model && this.model.getLoaderKey() == radioId){
                        let newNumberOfSongs = this.model.getNumberOfSongs() - numberOfSongRemoved;
                        if(newNumberOfSongs < 0){
                            newNumberOfSongs = 0;
                        }
                        this.model.setNumberOfSongs(newNumberOfSongs);
                        this.view.$el.find(".socialInfos > #numberOfSongs").html(newNumberOfSongs);
                    }
                }
            }
        },

        _onSongsAddedToRadios : function(radioIdToNumberOfSongAdded){
            if(radioIdToNumberOfSongAdded){
                for(let radioId in radioIdToNumberOfSongAdded){
                    const numberOfSongAdded  = radioIdToNumberOfSongAdded[radioId];
                    if(this.model && this.model.getLoaderKey() == radioId){
                        const newNumberOfSongs = this.model.getNumberOfSongs() + numberOfSongAdded;
                        this.model.setNumberOfSongs(newNumberOfSongs);
                        this.view.$el.find(".socialInfos > #numberOfSongs").html(newNumberOfSongs);
                    }
                }
            }

        },
        
        onActionExecutedOnSong : function(songModel , action){

        },

        onPlaylistSummarySubsciptionChanged : function(playlistSummaryModel, subscribed){
            if(this.model && playlistSummaryModel && this.model.getLoaderKey() === playlistSummaryModel.getLoaderKey()){
                this.model.setFollowing(subscribed);
                /*if(subscribed){
                    this.onActionExecutedOnPlaylist(this.model,"followTrebble");
                }else{
                    this.onActionExecutedOnPlaylist(this.model,"unfollowTrebble");
                }*/
            }
        },
        
        onActionExecutedOnPlaylist : function(playlistModel , action){
            switch(action){
                case "followTrebble":
                PersistentModels.getInstance().onTrebbleFollowed(playlistModel);
                break;
                case "unfollowTrebble":
                PersistentModels.getInstance().onTrebbleUnfollowed(playlistModel);
                break;
                
            }
        },
        
        _setupEventForDefaultPageTemplate : function(){
            if(!this._eventHandlerMap){
                this._eventHandlerMap = {};
            }
            if(!this._eventHandlerMap["click #header-back-button"]){
                this._eventHandlerMap["click #header-back-button"] = this.navigateToPreviousPage.bind(this);
            }
            if(!this._eventHandlerMap["click #followBtn"]){
                this._eventHandlerMap["click #followBtn"] = this.follow.bind(this);
            }
            if(!this._eventHandlerMap["click #followBtn .subscribe_tooltip"]){
                this._eventHandlerMap["click #followBtn .subscribe_tooltip"] = this.onSubscribeTooltipIfApplicableClickedOn.bind(this);
            }
            if(!this._eventHandlerMap["click #followingBtn"]){
                this._eventHandlerMap["click #followingBtn"] = this.unfollowConfirm.bind(this);
            }
            if(!this._eventHandlerMap["click .loggedInUserAvatar"]){
                this._eventHandlerMap["click .loggedInUserAvatar"] = this.launchAppInNewTab.bind(this);
            }
            return this._eventHandlerMap;
        },

        isSubscribeTooltipTheTargetOfEvent : function(event){
            const subscribe_tooltip$el = $(event.currentTarget).find(".subscribe_tooltip");
            const subscribe_tooltipEl = subscribe_tooltip$el.get(0);
            return subscribe_tooltipEl && (subscribe_tooltipEl == event.target || subscribe_tooltipEl.contains(event.target));
        },


        launchAppInNewTab : function(){
            PersistentModels.getInstance().getRouter().launchAppInNewTab();
        },
        
        navigateToPreviousPage : function(){
            PersistentModels.getInstance().getRouter().navigateToPreviousPage();
        },
        
        

        
        getModel : function(){
            return this.model;
        },
        
        getView : function(){
            return this.view;
        },
        
        afterPageRendered : function(){
            
        },

        onPageShow : function(){

        },
        
        updateTrebblePictureCoverUrlOnView : function(pictureCoverUrl, pictureCoverUrlBlur){
            const backgroundBlurredImageHeight = (window.device && window.device.platform == "browser")?500:300;
            this.view.$el.attr("do-not-match-coverart-color-with-background",DO_NO_MATCH_BACKGROUND_WITH_IMAGE?"true":"false");
            if(!window.trebble.config.disableZoomInCoverArtInMobileApp  && (window.device && window.device.platform !="browser" || !pictureCoverUrl)){
                let pictureCoverUrlFittedSize = Utils.getInstance().getResizeImageUrl(pictureCoverUrl, backgroundBlurredImageHeight, backgroundBlurredImageHeight);
                if(!DO_NO_MATCH_BACKGROUND_WITH_IMAGE){
                    this.view.$el.find(".artistDetailCoverArtWrapper").css("background-image","url("+pictureCoverUrlFittedSize+")");
                    this._updatePageBackground(pictureCoverUrlFittedSize);
                }else{
                    return RSVP.Promise.resolve();
                }
            }else{
                const resizeImageUrlForBackground = pictureCoverUrlBlur? Utils.getInstance().getResizeImageUrl(pictureCoverUrlBlur, backgroundBlurredImageHeight, backgroundBlurredImageHeight): null;
                let pictureCoverUrlFittedSize = pictureCoverUrl? Utils.getInstance().getResizeImageUrl(pictureCoverUrl, backgroundBlurredImageHeight, backgroundBlurredImageHeight): null;
                const  fullCoverArtImage$el = this.view.$el.find("#fullCoverArtImage") ;
                
                if(pictureCoverUrlFittedSize)
                {
                    fullCoverArtImage$el.removeClass("nocoverimage");
                    fullCoverArtImage$el.css("background-image","url("+pictureCoverUrlFittedSize+")").addClass("withShadow");
                }
                if(!DO_NO_MATCH_BACKGROUND_WITH_IMAGE){
                    let retrieveBlurryImageDataPromise = null;
                    if(pictureCoverUrlBlur){
                        retrieveBlurryImageDataPromise = Utils.getInstance().preloadImageUsingUrl(resizeImageUrlForBackground);
                    }else{
                        retrieveBlurryImageDataPromise = Utils.getInstance().retrieveImageBlurryFromUrl(pictureCoverUrl, 1, 1).then((function(scaledCoverArtInfo){
                            if(scaledCoverArtInfo){
                                return Utils.getInstance().getReadyForDisplayBase64Image(scaledCoverArtInfo.albumArtBase64BlurScreenSize);
                            }else{
                                return null;
                            }
                        }).bind(this)); 
                    }
                    return retrieveBlurryImageDataPromise.then((function(blurryImageData){
                        if(blurryImageData){
                            this.view.$el.find(".artistDetailCoverArtWrapper").css("background-image","url(" +blurryImageData +")");
                            return this._updatePageBackground(blurryImageData);
                        }
                    }).bind(this)).catch(function(error){
                        window.alertErrorMessage(error);
                    });
                }else{
                    return RSVP.Promise.resolve();
                }
            }
        },

        _initializeCarousels : function(){
            this.carousel$el = this.view.$el.find(".carouselWrapper").owlCarousel({

                navigation : false, // Show next and prev buttons
                pagination :true,
                slideSpeed : 300,
                paginationSpeed : 400,
                afterInit : function(elem){
                    const that = this
                    that.owlControls.prependTo(elem)
                },
                afterAction : this._onCarouselMove.bind(this),
                singleItem:true
            });
        },

        updateTrebbleNameAndDescription : function(newName, newDescription){
            if(this.model){
                this.model.setName(newName);
                this.model.setDescription(newDescription);
                this.view.$el.find("#pageHeaderTitleWrapper").html(this.model.getName());
                this.view.$el.find(".trebbleName").html(this.model.getName());
                this.view.$el.find(".playlistDescription").html(this.model.getDescription());
            }
            
        },

        _updatePageBackground : function(pictureCoverUrl){
            if(pictureCoverUrl || this.model.getCoverArtUrl() && !DO_NO_MATCH_BACKGROUND_WITH_IMAGE){
                const url = pictureCoverUrl? pictureCoverUrl: this.model.getCoverArtUrl();
                Utils.getInstance().getMatchingColorByImageUrl(url).then((function(result){
                    const yiq =  Utils.getInstance()._getContrastYIQ(result.backgroundColorArray);
                    const coefficientToIncreaseDarkness = (yiq - 15)/100*-1;
                    const colorString = yiq > 30 ? Utils.getInstance().shadeRGBColor(result.backgroundColorArray, coefficientToIncreaseDarkness).join(","): result.backgroundColorArray.join(",");
                    const newRgbColorString = "rgb("+colorString+")";

                    this.view.$el.css("background-color", newRgbColorString);
                    this._defaultBackgroundColor = newRgbColorString;
                    if(result.shouldUserLightColorText){
                        this.view.$el.addClass("lighttext");
                        this.view.$el.removeClass("darktext");
                    }else{
                        this.view.$el.removeClass("lighttext");
                        this.view.$el.addClass("darktext");
                    }
                    this.view.$el.find("#albumList").css("background-color",newRgbColorString);
                    if(this.listView){
                        this.listView.$el.css("background-color",newRgbColorString);
                    }
                    

                    const  basicArtistInfo$el = this.view.$el.find(".basicArtistInfo");
                    const  cornerDecorator$el = this.view.$el.find(".cornerDecorator");
                    const  ui_header$el = this.view.$el.find(".ui-header");
                    this.view.$el.find("#followBtn").css({"color":  newRgbColorString + " !important"});
                    this.view.$el.find("#followingBtn").css({"color": newRgbColorString  + " !important"});
                    this.view.$el.find("#fullCoverArtImage").css({"background-color": "rgb("+ colorString +")"});
                    basicArtistInfo$el.css({"background-image": "-webkit-linear-gradient(top,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                    cornerDecorator$el.css({"background-image": "-webkit-linear-gradient(left,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                    ui_header$el.css({"background-image": "-webkit-linear-gradient(top,rgba("+ colorString + ",0.5) ,rgba("+ colorString + ",0) 100%)"});
                    basicArtistInfo$el.css({"background-image": "-moz-linear-gradient(top,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                    cornerDecorator$el.css({"background-image": "-moz-linear-gradient(left,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                    ui_header$el.css({"background-image": "-moz-linear-gradient(top,rgba("+ colorString + ",0.5) ,rgba("+ colorString + ",0) 100%)"});
                    basicArtistInfo$el.css({"background-image": "-ms-linear-gradient(top,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                    cornerDecorator$el.css({"background-image": "-ms-linear-gradient(left,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                    ui_header$el.css({"background-image": "-ms-linear-gradient(top,rgba("+ colorString + ",0.5) ,rgba("+ colorString + ",0) 100%)"});
                    basicArtistInfo$el.css({"background-image": "-o-linear-gradient(top,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                    cornerDecorator$el.css({"background-image": "-o-linear-gradient(left,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                    ui_header$el.css({"background-image": "-o-linear-gradient(top,rgba("+ colorString + ",0.5) ,rgba("+ colorString + ",0) 100%)"});
                    basicArtistInfo$el.css({"background-image": "linear-gradient(top,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                    cornerDecorator$el.css({"background-image": "linear-gradient(left,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                    ui_header$el.css({"background-image": "linear-gradient(top,rgba("+ colorString + ",0.5) ,rgba("+ colorString + ",0) 100%)"});
                    basicArtistInfo$el.css({"background-image": "progid:DXImageTransform.Microsoft.gradient(startColorstr="+ colorString + ", endColorstr="+ colorString + ")"});
                    cornerDecorator$el.css({"background-image": "progid:DXImageTransform.Microsoft.gradient(startColorstr="+ colorString + ", endColorstr="+ colorString + ")"});
                    ui_header$el.css({"background-image": "progid:DXImageTransform.Microsoft.gradient(startColorstr="+ colorString + ", endColorstr="+ colorString + ")"});
                }).bind(this));
}
},

_onCarouselMove : function(){

},

follow : function(event){
if(!this.isSubscribeTooltipTheTargetOfEvent(event)){
if(this.model && this.model.canBeFollowed()){
    this.hideSubscribeTooltipIfApplicable();
    return TrebbleClientAPIHelper.getInstance()._doLogin(false, true).then((function(){
        if(true){
            const onTrebbleSubscriptionSucessHandler =  (function(){

            }).bind(this);
            const onTrebbleSubscriptionFailureHandler =  (function(error){

            }).bind(this);

            return PersistentModels.getInstance().getRouter().showSubscribeToTrebblePopupIfApplicable(this.model.getLoaderKey(), onTrebbleSubscriptionSucessHandler, onTrebbleSubscriptionFailureHandler, true);
        }else{
            return PersistentModels.getInstance().subscribe(this.model);
        }
    }).bind(this));
}else{
    return RSVP.Promise.resolve();
}
}
},

unfollowConfirm : function(){
return new RSVP.Promise((function(resolve, reject){
try{
    const buttonLabels = [window.getI18n(ti18n, "NO"),window.getI18n(ti18n, "YES")];
    const confirmCallback = (function(buttonIndex){
        if(buttonIndex ==  2){
            return this.unfollow().then(resolve).catch(reject);
        }else{
            resolve();
        }
    }).bind(this);
    const message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_UNSUBSCRIBE_FROM") +" \""+ this.model.getName()+"\"?"
    navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n, "UNSUBSCRIBE_FROM_TREBBLE"), buttonLabels,null , null);
}catch(error){
    reject(error);
}
}).bind(this));
} ,


unfollow : function(){
if(this.model){
return PersistentModels.getInstance().unsubscribe(this.model);
}else{
return RSVP.Promise.resolve();
}
},


updateFollowButton : function(){
if(!this.model.canBeFollowed()){
this.view.$el.find("[id=followingBtn]").css("display","none");
this.view.$el.find("[id=followBtn]").css("display","none");
this._subscribeButtonDisplayed = false;
}else{
if(this.model.isFollowing()){
    this.view.$el.find("[id=followingBtn]").css("display","flex");
    this.view.$el.find("[id=followBtn]").css("display","none");
    this._subscribeButtonDisplayed = false;
}else{
    this.view.$el.find("[id=followingBtn]").css("display","none");
    this.view.$el.find("[id=followBtn]").css("display","flex");
    this._subscribeButtonDisplayed = true;
}
}
//this.$el.find("#followingsFollowedInfoBox > .value").html(this.model.getNumberOfFollowings());
//this.$el.find("#followedFollowedInfoBox > .value").html(this.model.getNumberOfFollowers());
this.updateSocialInfo();
},

showHideSubscribeButtonInPageHeaderOnScroll: function(){
if(this._subscribeButtonDisplayed){
const showHideSubscribeButtonFunc = (function(){
    if(this._subscribeButtonDisplayed){
        this._pendingshowHideSubscribeButtonFuncExecutionId = null;
        let oneSubscribeButtonVisible = false;
        this._subscribeButtonsInPageContentArray.each(function(){
            if($( this ).visible()){
                oneSubscribeButtonVisible = true;
            }
        });
        /*this._subscribeButtonTooltipsInPageContentArray.each(function(){
            if($( this ).visible()){
                oneSubscribeButtonVisible = true;
            }
        });*/
const subscribeButtonInPageHeader$el = this.view.$el.find("[data-role=header] #followBtn");
if(DO_SHOW_SUBSCRIBE_BUTTON_IN_HEADER_WHILE_SCROLLING  &&  (!oneSubscribeButtonVisible && this.contentWrapper$el.get(0).scrollTop > MIN_SCROLL_POSITION_SHOW_SUBSCRIBE_FLOATING_BUTTON)){
    subscribeButtonInPageHeader$el.removeClass("hide");
}else{
    if(!subscribeButtonInPageHeader$el.hasClass("hide")){
        subscribeButtonInPageHeader$el.addClass("hide");
    }
}
}
}).bind(this);
/*if(this._pendingshowHideSubscribeButtonFuncExecutionId)
{
    window.clearTimeout(this._pendingshowHideSubscribeButtonFuncExecutionId);
}
this._pendingshowHideSubscribeButtonFuncExecutionId = window.setTimeout( showHideSubscribeButtonFunc,500);*/
showHideSubscribeButtonFunc();
}
},

updateSocialInfo : function(){
    let socialInfoInnerHtml = (this.model.canBeFollowed() || this.model.get("numberOfFollowers") > 0)?this.model.get("numberOfFollowers") + " <i class='ion-person' /> ":"" ;
socialInfoInnerHtml = socialInfoInnerHtml + this.model.get("numberOfSongs") + " <i class='ion-ios7-musical-notes'style='font-size: 1.0625em;'/>";
socialInfoInnerHtml = socialInfoInnerHtml + this.model.get("totalNumberOfPlays") + " <i class='ion-ios7-play' style='font-size: 1.125em;'/>";
socialInfoInnerHtml = socialInfoInnerHtml + this.model.get("numberOfCapsules") + " <i class='ion-contrast' style='font-size: 0.875em;'/>";
socialInfoInnerHtml = socialInfoInnerHtml + this.model.get("numberOfSongsComments") + " <i class='ion-chatbox' style='font-size: 1em;'></i>";
this.view.$el.find(".socialInfos").html(socialInfoInnerHtml);
},

onSubscribeTooltipIfApplicableClickedOn : function(){
this.hideSubscribeTooltipIfApplicable();
},

hideSubscribeTooltipIfApplicable: function(noNeedToUpdateLocalStorageFlag){
const subscribe_tooltip$el = this.view.$el.find("[id=followBtn] .subscribe_tooltip");
if(!subscribe_tooltip$el.hasClass("hide")){
subscribe_tooltip$el.addClass("hide");
if(!noNeedToUpdateLocalStorageFlag){
    window.trebble.subscribeTooltipWasDiscardedByUserInCurrentSession = true;
    const loggedInUserInfo = LocalStorageHelper.getInstance().getUserInfo();
    const isTemporaryUser = loggedInUserInfo ? loggedInUserInfo.temporary : false;
    if(!isTemporaryUser){
        LocalStorageHelper.getInstance().setSubscribreTooltipDiscardedByUser();
    }
}
this.view.$el.find(".listeningInstructionsBox.transparentChatbubble").each(function(){
    $( this ).removeClass( "transparentChatbubble" );
});
}
},


_onBeforeRemoveFromPage : function(){
this.stopListening();
},

buildModelAndRenderView : function() {
return new RSVP.Promise((function(resolve,reject){
try{
    this.songListController.buildModelAndRenderView().then((function(){
        const songListView = this.songListController.getView();
        this.listenTo(songListView,"finishLoading", this._onSongListFinishLoading.bind(this));
        const pageViewParams= {};
        pageViewParams.pageId = this.pageId;
        pageViewParams.pageViewTemplate = this._pageTemplate;
        pageViewParams.model = this.model;
        pageViewParams.containerSelector = "#songListWrapper";
        pageViewParams.contentView = songListView;
        pageViewParams.events = this._eventHandlerMap;
        pageViewParams.onPageShow = this.onPageShow.bind(this);
        pageViewParams.headerFullscren = true;
        pageViewParams.pageTemplateOptions = this._pageTemplateOptions;
        if(!this.pageTemplateOptions){
            this.pageTemplateOptions = {};
        }
        if(!this.pageTemplateOptions.ti18n){
            this.pageTemplateOptions.ti18n = ti18n;
        }
        pageViewParams.persistent = this._doNotRemoveFromDocument;
        this.view = new DynamicPageView(pageViewParams);
        this.listView = songListView;
        this.listenTo(this.view ,"beforeRemoveFromPage", this._onBeforeRemoveFromPage.bind(this));
        this.listView.$el.css("background-color",this._defaultBackgroundColor);
        this.view.render();
        if(LocalStorageHelper.getInstance().isSubscribreTooltipDiscardedByUser() || window.trebble.subscribeTooltipWasDiscardedByUserInCurrentSession){
            this.hideSubscribeTooltipIfApplicable(true);
        }else{
            if(this.model.canBeFollowed() || this._isPublicPage){
                this.view.$el.find(".listeningInstructionsBox:not(.transparentChatbubble)").each(function(){
                    $( this ).addClass( "transparentChatbubble" );
                });
            }
        }
        //this._initializeCarousels();
        if(!window.trebble.config.disableZoomInCoverArtInMobileApp && (window.device && window.device.platform != "browser")){
            this._updatePageBackground();
        }else{
            this.updateTrebblePictureCoverUrlOnView(this.model.getCoverArtUrl(), Utils.getInstance().isBackgroundBlurImageDisabled()? null: this.model.getCoverArtUrlBlur());
        }
        this.contentWrapper$el = this.view.$el.find("#contentWrapper");
        this._subscribeButtonsInPageContentArray = this.view.$el.find("#contentWrapper [id=followBtn]");
        this._subscribeButtonTooltipsInPageContentArray = this.view.$el.find("#contentWrapper [id=followBtn] .subscribe_tooltip");
        $(this.view.$el.find("#contentWrapper")).on("resize scroll", (function() {
            this.showHideSubscribeButtonInPageHeaderOnScroll()
        }).bind(this));

        this.afterPageRendered();

        resolve(this);
    }).bind(this)).catch(reject);

}catch(error){
reject(error);
}

}).bind(this));


}

});
export default PlaylistDetailsControllerCommon;