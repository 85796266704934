import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerOperationLog from "models/audioEditor/sequencerOperationLog/SequencerOperationLog";
import RSVP from "rsvp";

const SequencerOperationCutAndCopyToClipboardOperationLog =  SequencerOperationLog.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperationLog.apply(this, [attributes, options]);
        
    },

    getOperationName : function(){
        return "Cut And Copy To Clipboard";
    },

    setStartSequencerNodeToCut : function(startSequencerNodeToCut){
        this.set("startSequencerNodeToCut", startSequencerNodeToCut);
    },

    getStartSequencerNodeToCut : function(){
        return this.get("startSequencerNodeToCut");
    },

    setEndSequencerNodeToCut : function(endSequencerNodeToCut){
        this.set("endSequencerNodeToCut", endSequencerNodeToCut);
    },

    getEndSequencerNodeToCut : function(){
        return this.get("endSequencerNodeToCut");
    },

    setArrayOfSequencerNodeToCut : function(arrayOfSequencerNodeToCut){
        this.set("arrayOfSequencerNodeToCut", arrayOfSequencerNodeToCut);
    },

    getArrayOfSequencerNodeToCut : function(){
        return this.get("arrayOfSequencerNodeToCut");
    },

    setSequencerNodeToInsertNextTo : function(sequencerNodeToInsertNextTo){
        this.set("sequencerNodeToInsertNextTo", sequencerNodeToInsertNextTo);
    },

    getSequencerNodeToInsertNextTo : function(){
        return this.get("sequencerNodeToInsertNextTo");
    },




});

export default SequencerOperationCutAndCopyToClipboardOperationLog; 