import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import OptionPopupView from "views/common/OptionPopupView";
import PersistentModels from "services/PersistentModels";
import PageTemplate from 'text!../../../templates/common/OperationProgressPopupTemplate.html';

const pendingOperationStateAttributeName =  "pending-operation-state";
const OPERATION_STATE = {
    "PENDNG": "pending",
    "SUCCESS": "success",
    "ERROR": "error",
}
const OperationProgressPopupView = OptionPopupView.extend({

    


    initialize : function() {

       const viewOptions = {};
       const contentView = {};
       const contentViewTemplate = _.template(PageTemplate);
       contentView.$el = $(contentViewTemplate({}));
       viewOptions.contentView = contentView;
       viewOptions.addContentAfterEnhancement = true;
       viewOptions.contentPosition = "center";
       this._popupUniqueId = "OperationProgressPopupView";
       viewOptions.id = this._popupUniqueId;
       viewOptions.customClass = "operationProgressPopup";
       OptionPopupView.prototype.initialize.call(this, viewOptions);
       PersistentModels.getInstance().getRouter().registerForViewportHeightChange(this._popupUniqueId,this.onOrientationChangeFunction.bind(this), true);

   },

   getPageId : function(){
    return this._popupUniqueId;
},

onOrientationChangeFunction  : function(newViewPortHeigh, forceResizing) {
    if(this.$el.is(":visible") || forceResizing){
        if (this.el) {
            const adjustedListContainerHeight = (newViewPortHeigh) ;
            this.$el.find(".ui-content").css("height", adjustedListContainerHeight + "px");
        }
    }
},


afterRendering: function() {
    this.contentView$el =  this.$el.find(".operationProgressPopupContent");

},

close : function(){
    this.onPopupClosed();
},

onPopupClosed: function() {
    OptionPopupView.prototype.onPopupClosed.call(this);
    PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._popupUniqueId, true);
    this.trigger("onPopupClosed");
},


onAfterPageShow: function() {
    this.onOrientationChangeFunction(PersistentModels.getInstance().getRouter().getViewportHeight(), true);
},



setLoadingMessage : function(message){
    this.$el.find("#loadingBoxWrapper .message").html(message);
    this.contentView$el.attr(pendingOperationStateAttributeName, OPERATION_STATE.PENDNG);
},

setSuccessMessage : function(message, messageDetails){
    this.$el.find("#successBoxWrapper .message").html(message);
    this.$el.find("#successBoxWrapper .messageDetails").html(messageDetails);
    this.contentView$el.attr(pendingOperationStateAttributeName, OPERATION_STATE.SUCCESS);
},


setErrorMessage : function(message, messageDetails){
    this.$el.find("#errorBoxWrapper .message").html(message);
    this.$el.find("#errorBoxWrapper .messageDetails").html(messageDetails);
    this.contentView$el.attr(pendingOperationStateAttributeName, OPERATION_STATE.ERROR);
},

onBeforeRemoveFromPage : function(){
    this.trigger("beforeRemoveFromPage", this);
},

onRemovedFromPage : function(){
    this.undelegateEvents();
    this.$el.removeData().unbind(); 
    this.trigger("removedFromPage", this);
},

});
export default OperationProgressPopupView;