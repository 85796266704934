import { useEffect } from 'react';

function useWindowEvent(eventName, eventHandler) {
  useEffect(() => {
    // Add the event listener when the component mounts
    window.addEventListener(eventName, eventHandler);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener(eventName, eventHandler);
    };
  }, [eventName, eventHandler]); // Re-run the effect if eventName or eventHandler changes
}

export default useWindowEvent;
