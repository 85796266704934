
import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import BasicSequencerNodeInspectorView from "views/audioEditor/sequencerNodeInspectorSection/BasicSequencerNodeInspectorView";
import TitleParamView from "views/audioEditor/sequencerNodeInspectorSection/paramView/TitleParamView";
import WordParamView from "views/audioEditor/sequencerNodeInspectorSection/paramView/WordParamView";
import AudioSegmentParamView from "views/audioEditor/sequencerNodeInspectorSection/paramView/AudioSegmentParamView";
import AudioBufferHelper from "models/audioEditor/AudioBufferHelper";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import ti18n from 'i18n!nls/Sequenceri18n';
import RSVP from 'rsvp';

const Rollout = RolloutHelper.getInstance();
const ENABLE_RESET_ON_WORD_AND_PAUSE_TRIM =  Rollout.getFeatureVariable(Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR, Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR.variables.enable_reset_on_word_and_pause_trim, false);
const ENABLE_TRIMMING_ON_NODES = Rollout.getFeatureVariable(Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR, Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR.variables.enable_triming_on_nodes, true);


const PunctuationSequencerNodeInspectorView = BasicSequencerNodeInspectorView.extend({


    constructor : function(attributes, options) {
        BasicSequencerNodeInspectorView.apply(this, [attributes, options]);

    },

    listenToChangeEventsOnModel :function(){
        const sequencerNode =  this.getSequencerNode();

        this.listenTo(sequencerNode, "change:audioSegment", (function(model){
            const audioSegmentJson = model.getAudioSegmentJson();
            if(ENABLE_TRIMMING_ON_NODES){

                if((!audioSegmentJson && this._audioSegmentParamView.getValue())||
                    (audioSegmentJson && !this._audioSegmentParamView.getValue()) ||
                    (audioSegmentJson && this._audioSegmentParamView.getValue() && (audioSegmentJson.startTime != this._audioSegmentParamView.getValue().startTime
                        || audioSegmentJson.endTime != this._audioSegmentParamView.getValue().endTime))){
                    this._audioSegmentParamView.setValue(audioSegmentJson, true);
            }
        }
    }).bind(this));
    },

    renderAllParamViews : function(){
        const paramViewArray  = [];
        const titleParamView =  new TitleParamView();
        titleParamView.setValue(window.getI18n(ti18n, "PUNCTUATION"));
        titleParamView.render();
        paramViewArray.push(titleParamView);
        const wordParamView =  new WordParamView();
        wordParamView.setLabel(window.getI18n(ti18n, "CONTENT") +": ");
        wordParamView.setValue(this.getSequencerNode() && this.getSequencerNode().getAudioSegment() && this.getSequencerNode().getAudioSegment().getContent());
        wordParamView.render();
        paramViewArray.push(wordParamView);

        if(ENABLE_TRIMMING_ON_NODES){

            const sequencerNodeHasInitialStartAndEndTime = typeof this.getSequencerNode().getAudioSegment().getInitialStartTime() == 'number' &&  typeof  this.getSequencerNode().getAudioSegment().getInitialEndTime()  == 'number';
            const getDefaultTrimSectionValuesFunction = (function(cb){
                cb(this.getSequencerNode().getAudioSegment().getInitialStartTime(), this.getSequencerNode().getAudioSegment().getInitialEndTime());
            }).bind(this);
            const audioSegmentParamView =  new AudioSegmentParamView({
                "sequencerSettings": this.getSequencerNode().getSequencer().getSequencerSettings(),
                "audioType": AudioBufferHelper.getInstance().getAudioTypes().VOICE,
                "getDefaultTrimSectionValuesFunction": sequencerNodeHasInitialStartAndEndTime && ENABLE_RESET_ON_WORD_AND_PAUSE_TRIM? getDefaultTrimSectionValuesFunction: null
            });
            this._audioSegmentParamView = audioSegmentParamView;
            audioSegmentParamView.setLabel(window.getI18n(ti18n, "TRIMMED_FROM") +": ");
            audioSegmentParamView.setValue(this.getSequencerNode().getAudioSegmentJson());
            this.listenToChangeEventFromView(audioSegmentParamView, "audioSegmentChanged");
            audioSegmentParamView.render();
            paramViewArray.push(audioSegmentParamView);
        }


        return paramViewArray;
    }


});
export default PunctuationSequencerNodeInspectorView;