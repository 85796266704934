import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { List } from "antd";
import PropTypes from "prop-types";
import TrebbleAvatar from "./Avatar";
import TrebbleSkeleton from "./Skeleton";
import ConfigProvider from "./ConfigProvider";
import useDefaultTrebbleAntDTokenConfig from "../helper/useDefaultTrebbleAntDTokenConfig";

const TrebbleInfiniteList = (props) => {  
  const themeConfig = useDefaultTrebbleAntDTokenConfig();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [containerHeight, setContainerHeight] = useState(
    props.containerHeightMargin ? window.innerHeight - props.containerHeightMargin : window.innerHeight,
  );
  const loadMoreData = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const itemsFetched = await props.fetchData(data ? Math.round(data.length / props.pageSize) : 0, props.pageSize);
      if (itemsFetched && itemsFetched.length > 0) {
        setData([...data, ...itemsFetched]);
        if (itemsFetched.length < props.pageSize) {
          setHasMore(false);
        }
      } else {
        setHasMore(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setContainerHeight(
        props.containerHeightMargin ? window.innerHeight - props.containerHeightMargin : window.innerHeight,
      );
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    loadMoreData();
  }, []);

  return (
    <ConfigProvider theme={themeConfig}>
      <div
        id={props.containerId}
        style={{ height: containerHeight, ...props.containerStyle }}
        className={props.containerClassname}
      >
        <InfiniteScroll
          dataLength={data.length}
          next={loadMoreData}
          hasMore={hasMore}
          loader={props.loader}
          scrollableTarget={props.containerId}
        >
          {!((!data || data.length === 0) && loading) && (
            <List dataSource={data} header={props.header} renderItem={props.renderItem} locale={props.locale} />
          )}
        </InfiniteScroll>
      </div>
    </ConfigProvider>
  );
};

TrebbleInfiniteList.propTypes = {
  containerId: PropTypes.string.isRequired,
  containerStyle: PropTypes.object,
  loader: PropTypes.element.isRequired,
  renderItem: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  fetchData: PropTypes.func.isRequired,
  containerHeightMargin: PropTypes.number,
  header: PropTypes.element,
  containerClassname: PropTypes.string,
  locale: PropTypes.object,
};

export const ListItem = List.Item;
export const ListItemMeta = List.Item.Meta;
export const Skeleton = TrebbleSkeleton;
export const Avatar = TrebbleAvatar;
export default TrebbleInfiniteList;
