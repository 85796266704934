import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import AudioBufferRegionSelectorView from "views/audioEditor/AudioBufferRegionSelectorView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import FileUploadHelper from "models/helper/FileUploadHelper";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const AudioBufferRegionSelectorController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.model =  options.audioSegmentJson;
        this.audioUniqueId = options.audioUniqueId;
        this.audioType = options.audioType;
        this.sequencerSettings = options.sequencerSettings;
        this._onRegionChangeSaved = options.onRegionChangeSaved;
        _.extend(this, Backbone.Events);
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    _onSaveTrimButtonClicked : function(){
        if(this._onRegionChangeSaved){
            this._onRegionChangeSaved(true);
        }
    },

    getStartTime : function(){
        return this.view.getStartTime();
    },

    getEndTime : function(){
        return this.view.getEndTime();
    },


    stopPlayback : function(){
        return this.view.stopPlayback();
    },

    destroyWaveSurferInstance : function(){
        this.view.destroyWaveSurferInstance();
    },

    renderAudioWaveform : function(){
        this.view.renderAudioWaveform();
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {

                const params = {};
                params.model = this.model ;
                params.audioUniqueId = this.audioUniqueId;
                params.audioType = this.audioType;
                params.sequencerSettings= this.sequencerSettings;
                const promiseArray = [];
                this.view = new AudioBufferRegionSelectorView(params);

                this.view.render();
                this.listenTo(this.view , "saveTrim", this._onSaveTrimButtonClicked.bind(this));

                RSVP.Promise.all(promiseArray).then(resolve).catch(reject);


            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default AudioBufferRegionSelectorController;