
import _ from 'underscore';
import ReplaceSelectedNodesOperation from "models/audioEditor/sequencerOperation/ReplaceSelectedNodesOperation";
import TranscriptionManager from "models/helper/TranscriptionManager";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utils from "models/helper/Utils";
import DeletedSequencerNode from "models/audioEditor/sequencerNodes/DeletedSequencerNode";


const TranscribeAndReplaceOperation =  ReplaceSelectedNodesOperation.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        ReplaceSelectedNodesOperation.apply(this, [attributes, options]);
    },

    getUrlToTranscribe: function(sequencerNode){
        if(Utils.getInstance().isAudioSequencerNodeInstance(sequencerNode)){
            return sequencerNode.getAudio().getAudioUrl()
        }
        if(Utils.getInstance().isVideoSequencerNodeInstance(sequencerNode)){
            return sequencerNode.getVideo().getAudioUrl()
        }
        throw "Invalid node to delete"
    },

    getTrebbleAudioOrVideoObj: function(sequencerNode){
        if(Utils.getInstance().isAudioSequencerNodeInstance(sequencerNode)){
            return sequencerNode.getAudio();
        }
        if(Utils.getInstance().isVideoSequencerNodeInstance(sequencerNode)){
            return sequencerNode.getVideo();
        }
        throw "Invalid node to delete"
    },

    execute : async function(sequencerNode, languageCode, onTranscriptionStarted, onTranscriptionEnded, onReplacementStarted, onReplacementEnded){
        let isSequencerAlreadyBeingProcessed = sequencerNode.getBeingProcessed();
        try{
            if(!isSequencerAlreadyBeingProcessed){
                sequencerNode.setBeingProcessed(true);
            }
            const urlToTranscribe = this.getUrlToTranscribe(sequencerNode)
            const transcriptionInfo = await TrebbleClientAPIHelper.getInstance().findTranscriptionInfoForAudio(urlToTranscribe,languageCode);
            let results = null;
            if(transcriptionInfo){
                results = transcriptionInfo
                }else{
                    if(onTranscriptionStarted){
                        onTranscriptionStarted()
                    }
                    //const actionToExecute = async ()=>{
                        results =  await TranscriptionManager.getInstance().startTranscriptionAsync(this.getTrebbleAudioOrVideoObj(sequencerNode), languageCode);
                    //}
                   // await PersistentModels.getInstance().getRouter().executeFunctionAndShowProgressBarPopup(actionToExecute, `${window.getI18n(ti18n, "TRANSCRIBING")}...`)
                   if(onTranscriptionEnded){
                        onTranscriptionEnded();
                   }
                 }

            if(onReplacementStarted){
                onReplacementStarted()
            }
            const transcriptionId = results.transcriptionId;
            const trebbleAudio = results.trebbleAudio; 
            const status = results.status;
            const isVideo = results.isVideo;
            const transcriptionFileLocation = results.transcriptionFileLocation;
            let videoWidth = null;
            let videoHeight = null;
            if(isVideo){
                const videoMetadata =  await Utils.getInstance().getVideoMetadata(urlToTranscribe);
                if(videoMetadata){
                    videoWidth = videoMetadata.width;
                    videoHeight = videoMetadata.height;
                }
            }
            const transcribedAudio = await this.getSequencer().addTranscribeAudio(urlToTranscribe, transcriptionFileLocation, transcriptionId, true, isVideo, null, null, null, languageCode, videoWidth, videoHeight);
            const  arrayOfSequencerNodesToAdd = this.getSequencer().createSequencerNodesFromTransribedAudio(transcribedAudio);
            this.replaceWithArrayOfSequencerNodes(arrayOfSequencerNodesToAdd, sequencerNode);
            const {startTime, endTime} =  Utils.getInstance().isVideoSequencerNodeInstance(sequencerNode)? sequencerNode.getVideoAndVideoSegmentStartTimeAndEndTime(): sequencerNode.getAudioAndAudioSegmentStartTimeAndEndTime();
            const arrayOfSequencerBeforeSelectedRangeToDelete = [];
            const arrayOfSequencerAfterSelectedRangeToDelete = [];
            let arrayOfSequencerToKeep = [];
            if(startTime && endTime > 0){
                arrayOfSequencerNodesToAdd.map((sequencerNode)=>{
                    if(sequencerNode && sequencerNode.getAudioSegment() && sequencerNode.getAudioSegment().getEndTime() && sequencerNode.getAudioSegment().getEndTime() < startTime){
                        arrayOfSequencerBeforeSelectedRangeToDelete.push(sequencerNode);
                        return;
                    }
                    if(sequencerNode && sequencerNode.getAudioSegment() && sequencerNode.getAudioSegment().getStartTime() && sequencerNode.getAudioSegment().getStartTime() > endTime){
                        arrayOfSequencerAfterSelectedRangeToDelete.push(sequencerNode);
                        return;
                    }
                    arrayOfSequencerToKeep.push(sequencerNode);
                   
                })
            }else{
                arrayOfSequencerToKeep = arrayOfSequencerToKeep.concat(arrayOfSequencerNodesToAdd);
            }
            let arrayOfSequencerNodeKept = [];
            let deleteNodeBefore = null;
            if(arrayOfSequencerBeforeSelectedRangeToDelete.length > 0){
                deleteNodeBefore = new DeletedSequencerNode({"audioSegment": Utils.getInstance().getDeletedAudioSegment(), "sequencer": this.getSequencer()});
                this.replaceSequencerNodes(deleteNodeBefore, arrayOfSequencerBeforeSelectedRangeToDelete);
                arrayOfSequencerNodeKept.push(deleteNodeBefore);
            }
            arrayOfSequencerNodeKept = arrayOfSequencerNodeKept.concat(arrayOfSequencerToKeep);
            let deleteNodeAfter = null;
            if(arrayOfSequencerAfterSelectedRangeToDelete.length > 0){
                deleteNodeAfter = new DeletedSequencerNode({"audioSegment": Utils.getInstance().getDeletedAudioSegment(), "sequencer": this.getSequencer()});
                this.replaceSequencerNodes(deleteNodeAfter, arrayOfSequencerAfterSelectedRangeToDelete);
                arrayOfSequencerNodeKept.push(deleteNodeAfter)
            }

            this.getSequencer().getHistoryManagement().addSequencerOperationTranscribeAndReplaceSequencerNodeLog(sequencerNode, languageCode, arrayOfSequencerNodeKept);
            
            if(onReplacementEnded){
                onReplacementEnded()
            }
            if(!isSequencerAlreadyBeingProcessed){
                sequencerNode.setBeingProcessed(false);
            }
        }catch(error){
            if(!isSequencerAlreadyBeingProcessed){
                sequencerNode.setBeingProcessed(false);
            }
            window.alertErrorMessage(error);
        }
    
    },

    replaceWithArrayOfSequencerNodes : function(arrayOfSequencerNodesToAdd, sequencerNode){
        this.insertAudioNodeArray(arrayOfSequencerNodesToAdd, sequencerNode );
        this.removeAudioNode(sequencerNode);
    },

    
    undo : function(sequencerOperationTranscribeAndReplaceOperationsLog ){
        const sequencerNode = sequencerOperationTranscribeAndReplaceOperationsLog.getSequencerNode();
        const arrayOfSequencerNodesToAdd = sequencerOperationTranscribeAndReplaceOperationsLog.getArrayOfSequencerNodesToAdd();
        const firstSequencerNodeAdded = arrayOfSequencerNodesToAdd[0];
        this.insertAudioNodeArray([sequencerNode], firstSequencerNodeAdded, true );
        this.removeAudioNodeArray(arrayOfSequencerNodesToAdd);
    },

    redo: function(sequencerOperationTranscribeAndReplaceOperationsLog){
        const sequencerNode = sequencerOperationTranscribeAndReplaceOperationsLog.getSequencerNode();
        const arrayOfSequencerNodesToAdd = sequencerOperationTranscribeAndReplaceOperationsLog.getArrayOfSequencerNodesToAdd();
        return this.replaceWithArrayOfSequencerNodes(arrayOfSequencerNodesToAdd, sequencerNode)
    },

});


export default TranscribeAndReplaceOperation; 