import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlaylistHelper from "models/helper/PlaylistHelper";
import Utils from "models/helper/Utils";
import Hammer from 'hammerjs';
import swal from 'sweetalert';

import PersistentModels from "services/PersistentModels";
import FileUploadHelper from "models/helper/FileUploadHelper";

import PlayerService from "services/PlayerService";
import RSVP from "rsvp";
import PlaylistDetailsController from 'controllers/common/PlaylistDetailsController';
import CapsuleCardController from "controllers/capsule/CapsuleCardController";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import CollectionRepeatController from "controllers/common/CollectionRepeatController";

//"controllers/playlistPodcast/PersonalTrebbleOptionController",
import Capsules from "collections/Capsules";

import ti18n from "i18n!nls/MyPersonalRadiosi18n";
import PageTemplate from 'text!../../../templates/playlistPodcasts/RemoteRadioDetails.html';

const SHOW_WHAT_S_A_CAPSULE_FLOATING_BUTTON = false;
const MARK_TREBBLE_AS_NOT_PLAYABLE =  true;
const MAX_NUMBER_OF_ARCHIVED_CAPSULES_TO_LOAD_IN_QUICK_START_MODE = 20;
const MyPersonalRadioDetailsController = PlaylistDetailsController.extend({


    initialize : function(options) {
        options.getAllSongsInPlaylistHandler  = this.loadPlaylistDetails.bind(this);
        options.pageTemplate = PageTemplate;
        this._isPublicPage = options.isPublicPage;
        this._showBackButton = options.showBackButton;
        this._doNotRemoveFromDocument = options.doNotRemoveFromDocument;
        this._customCapsuleLoadHandler = options.customCapsuleLoadHandler;
        this._customCapsuleTitleHeader = options.customCapsuleTitleHeader;

        const eventHandlerMap = PlaylistDetailsController.prototype._setupEventForDefaultPageTemplate.call(this);
        this._detailedPlaylistInfoRetrieved = false;
        eventHandlerMap["click #startRadioBtn:not(.notplayable)"] = this.playTrebble.bind(this);
        eventHandlerMap["click #pauseRadioBtn"] = this.pauseTrebble.bind(this);
        eventHandlerMap["click #playbTrebbletn"] = this.playTrebble.bind(this);
        eventHandlerMap["click #stopTrebbletn"] = this.pauseTrebble.bind(this);
        eventHandlerMap["click div[data-role=header] #menuOptionButton"] = this.showOptionPopup.bind(this);
        eventHandlerMap["click div[data-role=header] #editButton"] = this.showEditPage.bind(this);
        eventHandlerMap["click #pageHeaderTitleWrapper > .editable"] = this.showEditPage.bind(this);
        eventHandlerMap["click .playlistDescription.editable"] = this.showEditPage.bind(this);

        eventHandlerMap["click #addSongsBtn"] = this.showChooseCapsuleFromLibraryAndAddToChannel.bind(this);
        eventHandlerMap["click #fabAddButtonContainer.expanded #addSongFabBtn"] = this.showChooseCapsuleFromLibraryAndAddToChannel.bind(this);
        eventHandlerMap["click #fabAddButtonContainer.expanded #addCapsuleFabBtn"] = this.showCreateACapsulePage.bind(this);
        eventHandlerMap["click #createNewCapsuleFloatingBtnWrapper #addCapsuleFabBtn"] = this.showCreateACapsulePage.bind(this);
        eventHandlerMap["click #addContentFabBtn"] = this.showOrHideAddContentButtons.bind(this);
        eventHandlerMap["click #showStatistics"] = this.showTrebbleStatistics.bind(this);
        eventHandlerMap["click #shareTrebbleBtn > *:not(.share_my_trebble_tooltip)"] = this.showShareTrebblePopup.bind(this);
        eventHandlerMap["click #shareTrebbleBtn .share_my_trebble_tooltip"] = this.onShareMyTrebbleTooltipIfApplicableClickedOn.bind(this);
        eventHandlerMap["click #exploreTrebblesBtn"] = this.showExplorePage.bind(this);
        eventHandlerMap["taphold .artistDetailCoverArtWrapper.editable #fullCoverArtImage"] = this.changeCoverArtConfirm.bind(this);
        eventHandlerMap["click #editTrebbleCoverPicBtn"] = this.upadteCoverArt.bind(this);
        eventHandlerMap["click #whatsacapsuleButton"] = this.showAndPlayWhatsACapsuleCapsule.bind(this);
        eventHandlerMap["click #whatsacapsuleinfobtn"] = this.showAndPlayWhatsACapsuleCapsule.bind(this);
        eventHandlerMap["click #homeHeaderButton"] = this.showHomeMenuPanel.bind(this);
        
        options.eventHandlerMap = eventHandlerMap;
        options.customInfoBoxInfoParams = this._buildCustomInfoBoxParams();
        options.onInfoButtonPressed = this._onAddMusicButtonPressed.bind(this);
        options.context = Utils.getInstance().buildContextForSongCollectionUserRadio(options.playlistSummary.get("id"));
        const loggedInUserInfo = LocalStorageHelper.getInstance().getUserInfo();
        const isTemporaryUser = loggedInUserInfo ? loggedInUserInfo.temporary : false;
        options.pageTemplateOptions = {"persistentPage": options.doNotRemoveFromDocument,"isPublicPage": this._isPublicPage,"showBackButton":this._showBackButton, "loggedInUserInfo": !isTemporaryUser?  loggedInUserInfo: null,"isTemporaryUser": isTemporaryUser, "instructionHtmlGeneratorFunction": Utils.getInstance().getHtmlTrebbleListeningInstructionsNew, "ti18n":ti18n, "showCustomCapsuleList": this._customCapsuleLoadHandler,"customCapsuleTitleHeader": this._customCapsuleTitleHeader};
        this._context = options.context;
        this._openEditPageController = [];
        
        const c = PlaylistDetailsController.prototype.initialize.call(this, options);
        if(this._customCapsuleLoadHandler){
            this._buildCustomCapsuleListController(options.playlistSummary.get("id"));
        }else{
            this._buildCapsuleListController(options.playlistSummary.get("id"));
            if(this._isPublicPage){
                this._buildArchivedCapsuleListController(options.playlistSummary.get("id"));
            }
        }
        
        
        this.listenTo(this.songListController,"finishLoading", this._updateStartTrebbleButtonDisplay.bind(this));
        if(this.capsuleCollectionRepeatController){
            this.listenTo(this.capsuleCollectionRepeatController, "finishLoading", this._updateStartTrebbleButtonDisplay.bind(this));
        }
        this.listenTo(PersistentModels.getInstance(),"songsRemovedToRadios", this._onSongsAddedOrRemovedToRadios.bind(this));
        this.listenTo(PersistentModels.getInstance(), "songsAddedToRadios", this._onSongsAddedOrRemovedToRadios.bind(this));
        this.listenTo(PersistentModels.getInstance(), "userSongLibraryInitialized", this._onSongsAddedOrRemovedToRadios.bind(this));
        this.listenTo(PersistentModels.getInstance(), "capsuleProcessingCompleted", this._onCapsuleSuccessfullyCreated.bind(this));
        this.listenTo(PersistentModels.getInstance(), "capsuleAddedOrRemoved", this._onCapsuleSuccessfullyCreated.bind(this));
        this.listenTo(PersistentModels.getInstance().getRouter(), "editPageOpened", this._onEditPageOpened.bind(this));
        this.listenTo(PersistentModels.getInstance().getRouter(), "editPageClosed", this._onEditPageClosed.bind(this));
        this._listenToPlayerEvents();
        return c;


    },


    onArchivedCapsuleListFinishLoading : function(){
        if(this._isPublicPage){
            this.archivedCapsuleListRenderingPromise.then((function(){
                this.view.$el.find("#myArchivedCapsulesListWrapper").append(this.archivedCapsuleListView.$el);
                if(!this.archivedCapsuleCollectionRepeatController.getModel() || this.archivedCapsuleCollectionRepeatController.getModel().length === 0){
                    this.view.$el.find(".archivedCapsulesBlock").hide();
                }
            }).bind(this));
        }
    },

    pauseTrebble : function(){
        if(PersistentModels.getInstance().getPlayer().isPlaylistCurrentlyLoaded(this.model)){
            PersistentModels.getInstance().getPlayer().pauseAudio();
        }
    },

    _onEditPageOpened : function(editPageController){
        if(this.model && this.model.canBeEdited()){
            if(this._openEditPageController.indexOf(editPageController) == -1){
                this._openEditPageController.push(editPageController);
            }
        }

    },

    _onEditPageClosed : function(editPageController){
        if(this.model && this.model.canBeEdited()){
            const controllerIndex = this._openEditPageController.indexOf(editPageController); 
            if(controllerIndex!= -1){
                this._openEditPageController.splice(controllerIndex, 1);
            }
        }

    },

    playTrebble : function(){
        if(PersistentModels.getInstance().getPlayer().isPlaylistCurrentlyLoaded(this.model)){
            PersistentModels.getInstance().getPlayer().playAudio();
        }else{
            this.onStartRadioButtonTapped();
        }
    },
    updatePlaybackButton : function(){
        const playerModel =  PersistentModels.getInstance().getPlayer();
        if(playerModel.isPlaylistCurrentlyLoaded(this.model)){
            if(playerModel.isLoading() || playerModel.isPlayerLoading()){
                this.view.$el.removeClass("isCurrentlyPlaying");
                this.view.$el.addClass("isCurrentlyLoading");
            }else{
                this.view.$el.removeClass("isCurrentlyLoading");
                if (playerModel.isPlaying()) {
                    this.view.$el.addClass("isCurrentlyPlaying");
                } else {
                    this.view.$el.removeClass("isCurrentlyPlaying");
                }
            }
        }else{
            this.view.$el.removeClass("isCurrentlyPlaying");
            this.view.$el.removeClass("isCurrentlyLoading");
        }

    },

    _listenToPlayerEvents : function(){

        
        this.listenTo(PersistentModels.getInstance().getPlayer(),"songStartedPlaying",this.updatePlaybackButton.bind(this));
        this.listenTo(PersistentModels.getInstance().getPlayer(),"songLoading",this.updatePlaybackButton.bind(this));
        this.listenTo(PersistentModels.getInstance().getPlayer(),"playerLoading",this.updatePlaybackButton.bind(this));
        this.listenTo(PersistentModels.getInstance().getPlayer(),"playerFinishLoading",this.updatePlaybackButton.bind(this));
        this.listenTo(PersistentModels.getInstance().getPlayer(),"songLoaded",this.updatePlaybackButton.bind(this));
        this.listenTo(PersistentModels.getInstance().getPlayer(),"songFailedLoading",this.updatePlaybackButton.bind(this));
        this.listenTo(PersistentModels.getInstance().getPlayer(),"songPaused",this.updatePlaybackButton.bind(this));
    },



    _onAddMusicButtonPressed :function(){
        this.showGlobalSearch();
    },

    showHomeMenuPanel : function(){
        PersistentModels.getInstance().getRouter().showHomePage();
    },

    _buildCapsuleListController : function(radioId){
        const controllerParams = {};
        controllerParams.showActiveFlagInView = true;
        controllerParams.context = Utils.getInstance().buildContextForMyCapsulePage();
        controllerParams.onActionExecutedOnUserHandler = this._onActionExecutedByUserOnDisplayedCapsule.bind(this);
        const collectionRepeatControllerParams = {};
        collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildCapsuleInfoBoxParams();
        collectionRepeatControllerParams.showEmtptyInfoBox = true;
        collectionRepeatControllerParams.controllerOption = controllerParams;
        collectionRepeatControllerParams.itemControllerConstructor = CapsuleCardController;
        collectionRepeatControllerParams.modelLoadHandler = this._getMyOnAirCapsulesLoadHandler(radioId);
        collectionRepeatControllerParams.modelLoadMoreHandler = this._getMyOnAirCapsulesLoadWithPagingHandler(radioId).bind(this);
        collectionRepeatControllerParams.loadOnScroll = true;
        collectionRepeatControllerParams.numberOfResultsPerPage = 21;
        collectionRepeatControllerParams.context = this._context;
        collectionRepeatControllerParams.resizePlaceHolderElem = true;
        collectionRepeatControllerParams.heightMargin = 52;
        this._capuleListLoaded = false;
        collectionRepeatControllerParams.actionButtonHandlerFunction = this.showCreateACapsulePage.bind(this);
        $.mobile.loading("show");
        this.capsuleCollectionRepeatController = new CollectionRepeatController(collectionRepeatControllerParams);
        this.capsuleListRenderingPromise = this.capsuleCollectionRepeatController.buildModelAndRenderView().then((function(){
            this.capsuleListView = this.capsuleCollectionRepeatController.getView();
            $.mobile.loading("hide");
        }).bind(this)).catch((function(error){
            $.mobile.loading("hide");
            window.alertErrorMessage(error);
        }).bind(this));
    },

    _buildCustomCapsuleListController : function(radioId){
        const controllerParams = {};
        controllerParams.showActiveFlagInView = true;
        controllerParams.context = Utils.getInstance().buildContextForMyCapsulePage();
        controllerParams.onActionExecutedOnUserHandler = this._onActionExecutedByUserOnDisplayedCapsule.bind(this);
        const collectionRepeatControllerParams = {};
        collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildCustomCapsuleInfoBoxParams();
        collectionRepeatControllerParams.showEmtptyInfoBox = true;
        collectionRepeatControllerParams.controllerOption = controllerParams;
        collectionRepeatControllerParams.itemControllerConstructor = CapsuleCardController;
        collectionRepeatControllerParams.modelLoadHandler = this._getMyCustomCapsulesLoadHandler();
        collectionRepeatControllerParams.context = this._context;
        collectionRepeatControllerParams.resizePlaceHolderElem = true;
        collectionRepeatControllerParams.heightMargin = 52;
        this._customCapuleListLoaded = false;
        $.mobile.loading("show");
        this.customCapsuleCollectionRepeatController = new CollectionRepeatController(collectionRepeatControllerParams);
        this.customCapsuleListRenderingPromise = this.customCapsuleCollectionRepeatController.buildModelAndRenderView().then((function(){
            this.customCapsuleListView = this.customCapsuleCollectionRepeatController.getView();
            $.mobile.loading("hide");
        }).bind(this)).catch((function(error){
            $.mobile.loading("hide");
            window.alertErrorMessage(error);
        }).bind(this));
    },

    _buildArchivedCapsuleListController : function(radioId){
        const controllerParams = {};
        controllerParams.showActiveFlagInView = true;
        controllerParams.context = Utils.getInstance().buildContextForMyCapsulePage();
        controllerParams.onActionExecutedOnUserHandler = this._onActionExecutedByUserOnDisplayedCapsule.bind(this);
        const collectionRepeatControllerParams = {};
        collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildArchivedCapsuleInfoBoxParams();
        collectionRepeatControllerParams.showEmtptyInfoBox = true;
        collectionRepeatControllerParams.controllerOption = controllerParams;
        collectionRepeatControllerParams.itemControllerConstructor = CapsuleCardController;
        collectionRepeatControllerParams.modelLoadHandler = this._getMyArchivedCapsulesLoadHandler(radioId);
        collectionRepeatControllerParams.modelLoadMoreHandler = this._getMyArchivedCapsulesLoadWithPagingHandler(radioId).bind(this);
        collectionRepeatControllerParams.loadOnScroll = true;
        collectionRepeatControllerParams.numberOfResultsPerPage = 21;
        
        collectionRepeatControllerParams.context = this._context;
        collectionRepeatControllerParams.resizePlaceHolderElem = true;
        collectionRepeatControllerParams.heightMargin = 52;
        this._archivedCapsuleListLoaded = false;
        $.mobile.loading("show");
        this.archivedCapsuleCollectionRepeatController = new CollectionRepeatController(collectionRepeatControllerParams);
        this.listenTo( this.archivedCapsuleCollectionRepeatController, "finishLoading",this.onArchivedCapsuleListFinishLoading);
        this.archivedCapsuleListRenderingPromise = this.archivedCapsuleCollectionRepeatController.buildModelAndRenderView().then((function(){
            this.archivedCapsuleListView = this.archivedCapsuleCollectionRepeatController.getView();
            $.mobile.loading("hide");
        }).bind(this)).catch((function(error){
            $.mobile.loading("hide");
            window.alertErrorMessage(error);
        }).bind(this));
    },


    _onSongsAddedOrRemovedToRadios : function(){
        this.songListController.reload();
        
    },

    _onActionExecutedByUserOnDisplayedCapsule : function(actionName, capsuleJson, capsuleCardControllerInstance){
        if(this.capsuleCollectionRepeatController){
            this.capsuleCollectionRepeatController.reload();
        }
        if(this.archivedCapsuleCollectionRepeatController){
            this.archivedCapsuleCollectionRepeatController.reload();
        }
        if(this.customCapsuleCollectionRepeatController){
            this.customCapsuleCollectionRepeatController.reload();
        }
    },

    _onCapsuleSuccessfullyCreated : function(){
        if(this.capsuleCollectionRepeatController){
            this.capsuleCollectionRepeatController.reload();
        }
        if(this.archivedCapsuleCollectionRepeatController){
            this.archivedCapsuleCollectionRepeatController.reload();
        }
        if(this.customCapsuleCollectionRepeatController){
            this.customCapsuleCollectionRepeatController.reload();
        }
    },

    _buildCustomCapsuleInfoBoxParams : function(){
        const params = {};
        params.iconClass = "ion-contrast";
        params.message = window.getI18n(ti18n, "NO_CAPSULE");
        return params;
    },

    _buildCapsuleInfoBoxParams : function(){
        if(this.model.canBeEdited() && !this._isPublicPage){
            const params = {};
            params.iconClass = "ion-contrast";
            params.message = window.getI18n(ti18n, "NO_CAPSULE_ON_AIR");
            params.helpMessage = window.getI18n(ti18n, "CREATE_CAPSULES_TO_TALK_TO_YOUR_LISTENERS_AND_EXPRESS_YOURSELF")
            params.actionButtonLabel = window.getI18n(ti18n, "CREATE_CAPSULE");
            return params;
        }else{
            const params = {};
            params.iconClass = "ion-contrast";
            params.message = window.getI18n(ti18n, "NO_CAPSULE");
            params.helpMessage = window.getI18n(ti18n, "THERE_ARE_CURRENTLY_NO_CAPSULE_AVAILABLE");
            //params.actionButtonLabel = "Create Capsule";
            return params;
        }

    },


    _buildArchivedCapsuleInfoBoxParams : function(){
        const params = {};
        params.iconClass = "ion-contrast";
        params.message = window.getI18n(ti18n, "NO_ARCHIVED_CAPSULE");
        return params;
    },


    showAndPlayWhatsACapsuleCapsule: function(){
        PersistentModels.getInstance().getRouter().showAndPlayWhatACapusleCapsule().then(((capsuleDetailsPopupController) =>{
            capsuleDetailsPopupController.playCapsule();
            this.listenTo(capsuleDetailsPopupController,"finishPlaying", (function(){
                LocalStorageHelper.getInstance().setWhatsACapsuleCapsulePlayed();
                this.view.$el.find("#whatsacapsuleButton").hide();
            }).bind(this))
        }).bind(this));
    },


    _getMyOnAirCapsulesLoadHandler: function(radioId){
        return (function(){
            return TrebbleClientAPIHelper.getInstance().getOnAirCapsulesForRadioId(radioId).then((capsulesInfoArray) =>{
                if(capsulesInfoArray && capsulesInfoArray.length > 0){
                    this.capsulesCollection = new Capsules(capsulesInfoArray);
                    return this.capsulesCollection.models;
                }else{
                    return  [];
                }
            });
        }).bind(this);
    },

    _getMyOnAirCapsulesLoadWithPagingHandler: function(radioId){
        return (function(filter, pageIndex, numberOfResults){
            return TrebbleClientAPIHelper.getInstance().getOnAirCapsulesForRadioId(radioId, numberOfResults, pageIndex).then((capsulesInfoArray) =>{
                if(capsulesInfoArray && capsulesInfoArray.length > 0){
                    this.capsulesCollection = new Capsules(capsulesInfoArray);
                    return this.capsulesCollection.models;
                }else{
                    return  [];
                }
            });
        }).bind(this);
    },

    _getMyArchivedCapsulesLoadHandler: function(radioId){
        return (function(){
            const numberOfCapsulesToLoad = MAX_NUMBER_OF_ARCHIVED_CAPSULES_TO_LOAD_IN_QUICK_START_MODE;// window.trebble.appRunningInQuickStartMode? MAX_NUMBER_OF_ARCHIVED_CAPSULES_TO_LOAD_IN_QUICK_START_MODE: null;
            return TrebbleClientAPIHelper.getInstance().getArchivedCapsulesForRadioId(radioId, numberOfCapsulesToLoad).then((capsulesInfoArray) =>{
                if(capsulesInfoArray && capsulesInfoArray.length > 0){
                    this.capsulesCollection = new Capsules(capsulesInfoArray);
                    return this.capsulesCollection.models;
                }else{
                    return  [];
                }
            });
        }).bind(this);
    },

    _getMyArchivedCapsulesLoadWithPagingHandler: function(radioId){
        return (function(filter, pageIndex, numberOfResults){
            numberOfResults = numberOfResults? numberOfResults: MAX_NUMBER_OF_ARCHIVED_CAPSULES_TO_LOAD_IN_QUICK_START_MODE;// window.trebble.appRunningInQuickStartMode? MAX_NUMBER_OF_ARCHIVED_CAPSULES_TO_LOAD_IN_QUICK_START_MODE: null;
            return TrebbleClientAPIHelper.getInstance().getArchivedCapsulesForRadioId(radioId,  numberOfResults, pageIndex).then((capsulesInfoArray) =>{
                if(capsulesInfoArray && capsulesInfoArray.length > 0){
                    this.capsulesCollection = new Capsules(capsulesInfoArray);
                    return this.capsulesCollection.models;
                }else{
                    return  [];
                }
            });
        }).bind(this);
    },


    _getMyCustomCapsulesLoadHandler: function(){
        return (function(){
            
            return this._customCapsuleLoadHandler().then(function(capsulesInfoArray){
                if(capsulesInfoArray && capsulesInfoArray.length > 0){
                    this.capsulesCollection = new Capsules(capsulesInfoArray);
                    return this.capsulesCollection.models;
                }else{
                    return  [];
                }
            });
        }).bind(this);
    },




    showOrHideAddContentButtons : function(){
        this.fabAddButtonContainer$el.toggleClass("expanded");
    },

    hideAddContentButtons : function(){
        this.fabAddButtonContainer$el.removeClass("expanded");
    },

    showAddContentButtons : function(){
        if(!this.fabAddButtonContainer$el.hasClass("expanded")){
            this.fabAddButtonContainer$el.addClass("expanded");
        }
    },

    showCreateACapsulePage : function(){
        this.hideAddContentButtons();
        const onCapsuleSuccessfullyCreated = (function(){

        }).bind(this);
        return PersistentModels.getInstance().getRouter().showCreateAShortcastPageToPublishToChannel(null, onCapsuleSuccessfullyCreated);
    },
    
    _buildCustomInfoBoxParams  : function(){
        const params = {};
        params.iconClass = "ion-ios7-musical-notes";
        params.message = window.getI18n(ti18n, "START_ADDING_MUSIC");
        params.helpMessage = window.getI18n(ti18n, "GO_TO_SEARCH_PAGE_INSTRUCTION");
        params.actionButtonLabel = window.getI18n(ti18n, "ADD_MUSIC");
        return params;
    },
    
    onActionExecutedOnSong : function(songModel, action){
        if((action == "songRemovedFromTrebble"  || action == "delete" )&& songModel){
            this.songListController.removeSongFromCollection(songModel);
        }
    },
    
    
    afterPageRendered : function(){
        const editButton$El = this.view.$el.find("#playPreviewBtn");
        const editButtonEl = editButton$El.get(0);
        if(editButtonEl){
            Hammer(editButtonEl, {drag_lock_to_axis: true}).on("press", this.onPreviewButtonPressed.bind(this), false);
            Hammer(editButtonEl, {drag_lock_to_axis: true}).on("pressUp", this.onPreviewButtonPressedUp.bind(this), false);
            //editButton$El.on("touchend",this.onPreviewButtonPressedUp.bind(this));
            //editButton$El.on("mouseover",this.onPreviewButtonPressed.bind(this));
            //editButton$El.on("mouseup",this.onPreviewButtonPressedUp.bind(this));
            editButton$El.hoverIntent(this.onPreviewButtonPressed.bind(this),this.onPreviewButtonPressedUp.bind(this));
        }
        this.fabAddButtonContainer$el = this.view.$el.find("#fabAddButtonContainer");
        if(!window.isMobileBrowser){
            this.fabAddButtonContainer$el.hover( function(){}, this.hideAddContentButtons.bind(this));
            this.view.$el.find("#addContentFabBtn").hover(this.showAddContentButtons.bind(this), function(){});
        }
        if(this.capsuleListRenderingPromise){
            this.capsuleListRenderingPromise.then((function(){
                this.view.$el.find("#myCapsulesListWrapper").append(this.capsuleListView.$el);
            }).bind(this));
        }
        if(this.customCapsuleListRenderingPromise){
            this.customCapsuleListRenderingPromise.then((function(){
                this.view.$el.find("#myCustomCapsulesListWrapper").append(this.customCapsuleListView.$el);
            }).bind(this));
        }
        this.view.$el.on("click",".tabheader > div#songTab", this._showSongTabClicked.bind(this));
        this.view.$el.on("click",".tabheader > div#capsuleTab", this._showCapsuleTabClicked.bind(this));
        this._selectedTabId = "capsuleTab";
        if(SHOW_WHAT_S_A_CAPSULE_FLOATING_BUTTON && this._doNotRemoveFromDocument && !LocalStorageHelper.getInstance().isWhatsACapsuleCapsulePlayed() && !PersistentModels.getInstance().hasCapsuleCreated()){
            this.view.$el.find("#whatsacapsuleButton").attr('style', 'display: -webkit-flex; display: flex');
        }else{
            this.view.$el.find("#whatsacapsuleButton").hide();
        }
        if(this.capsuleCollectionRepeatController){
            this.capsuleCollectionRepeatController.setScrollContainer(this.view.$el.find("#contentWrapper"));
        }
        if(this.archivedCapsuleCollectionRepeatController){
            this.archivedCapsuleCollectionRepeatController.setScrollContainer(this.view.$el.find("#contentWrapper"));
        }
    },

    _showSongTabClicked : function(){
        if(this._selectedTabId != "songTab"){
            this._hideCurrentTab();
            this._showTab("songTab");
        }
    },

    _hideCurrentTab : function(){
        const selectedTab$el = this.view.$el.find("#"+ this._selectedTabId);
        selectedTab$el.removeClass("selected");
        this.view.$el.find(selectedTab$el.attr("href")).hide();
    },

    _showTab : function(tabId){
        this._selectedTabId = tabId;
        const selectedTab$el = this.view.$el.find("#"+ this._selectedTabId);
        selectedTab$el.addClass("selected");
        this.view.$el.find(selectedTab$el.attr("href")).show();
    },



    _showCapsuleTabClicked : function(){
        if(this._selectedTabId != "capsuleTab"){
            this._hideCurrentTab();
            this._showTab("capsuleTab");

        }
    },

    onPageShow : function(){
        if(this.capsuleListView){
            this.capsuleCollectionRepeatController.reload();
        }
        if(this.archivedCapsuleListView){
            this.archivedCapsuleCollectionRepeatController.reload();
        }
        if(this.customCapsuleListView){
            this.customCapsuleCollectionRepeatController.reload();
        }
        if(this._isPublicPage && this.archivedCapsuleListView){
            this.archivedCapsuleCollectionRepeatController.reload();
        }
        const userInfo = LocalStorageHelper.getInstance().getUserInfo();
        if(PersistentModels.getInstance().getRouter().isCurrentPageHasSameUrlAsFirstPage()){
            this.view.$el.removeClass("showBackButton");
        }else{
            if(this.view.$el.hasClass("showBackButton")){
                this.view.$el.addClass("showBackButton");
            }
        }
        this.updatePlaybackButton();
    },
    
    onPreviewButtonPressed : function(){
        this._previewButtonPressed = true;
        this.playPreview();
    },
    
    onPreviewButtonPressedUp : function(){
        if(this._previewButtonPressed){
            this._previewButtonPressed = false;
            this.stopPreview();
        }
    },
    
    showTrebbleStatistics : function(){
        return PersistentModels.getInstance().getRouter().showTrebbleStatisticsPage(this._playlist, true);
    },

    showExplorePage : function(){
        return PersistentModels.getInstance().getRouter().showExplorePage(null, true);
    },
    

    showShareTrebblePopup : function(){
        PersistentModels.getInstance().getRouter().showTrebbleSharePopup(this._playlist, null, this.isTrebblePlayable.bind(this));
        this.onShareMyTrebbleTooltipIfApplicableClickedOn();
    },
    
    showOptionPopup : function(){
        PersistentModels.getInstance().getRouter().showMyPersonalRadioMenuOption(this._playlist, true, this._onActionExecutedOnTrebbleHandler.bind(this), this.view.$el.find("#menuOptionButton")/*, PersonalTrebbleOptionController*/);
    },
    

    
    _onActionExecutedOnTrebbleHandler : function(trebble, action, data){
        let editPageController;
        if(action == "updateCoverArt"){
            if(data){
                const newCoverArtUrl = data.coverArtUrl;
                const newCoverArtUrlBlur = data.coverArtUrlBlur;
                this.updateTrebblePictureCoverUrlOnView(newCoverArtUrl, newCoverArtUrlBlur);
                PersistentModels.getInstance().onPersonalTrebbleCreatedOrEdited(this._playlist.getPlaylistSummary().toJSON());
                if(this._openEditPageController.length > 0){
                    for(let index in this._openEditPageController){
                        editPageController = this._openEditPageController[index];
                        editPageController.setCoverArtInfo(newCoverArtUrl);
                    }
                }
            }
        }else{
            if(action == "setSweeper"){
                if(data){
                    const jingleInfo = data.jingleInfo;
                    if(this._openEditPageController.length > 0){
                        for(let index in this._openEditPageController){
                            editPageController = this._openEditPageController[index];
                            editPageController.setJingleInfo(jingleInfo);
                        }
                    }
                }
            }else{
                if(action == "unsetCurrentSweeper"){
                    if(this._openEditPageController.length > 0){
                        for(let index in this._openEditPageController){
                            editPageController = this._openEditPageController[index];
                            editPageController.setJingleInfo(null);
                        }
                    }
                }else{
                    if(action == "editTrebbleInfo"){	
                        if(data){
                            this.model.set(data);
                            this.updateTrebbleNameAndDescription(data.name, data.description);
                            this._playlist = PlaylistHelper.getInstance().generatePersonalUserRadioPlaylist(this.model);
                            this.upadteCoverArt(true);
                            if(this._openEditPageController.length > 0){
                                for(let index in this._openEditPageController){
                                    editPageController = this._openEditPageController[index];
                                    editPageController.updateTrebbleNameAndDescription(data.name, data.description);
                                }
                            }

                        }

                    }else{
                        if(action == "setGreater"){
                            if(data){
                                const greaterInfo = data.greaterInfo;
                                if(this._openEditPageController.length > 0){
                                    for(let index in this._openEditPageController){
                                        editPageController = this._openEditPageController[index];
                                        editPageController.setGreaterInfo(greaterInfo);
                                    }
                                }
                            }
                        }else{
                            if(action == "unsetCurrentGreater"){
                                if(this._openEditPageController.length > 0){
                                    for(let index in this._openEditPageController){
                                        editPageController = this._openEditPageController[index];
                                        editPageController.setGreaterInfo(null);
                                    }
                                }
                            }else{
                                if(action == "setIntro"){
                                    if(data){
                                        const introInfo = data.introInfo;
                                        if(this._openEditPageController.length > 0){
                                            for(let index in this._openEditPageController){
                                                editPageController = this._openEditPageController[index];
                                                editPageController.setIntroInfo(introInfo);
                                            }
                                        }
                                    }
                                }else{
                                    if(action == "unsetCurrentIntro"){
                                        if(this._openEditPageController.length > 0){
                                            for(let index in this._openEditPageController){
                                                editPageController = this._openEditPageController[index];
                                                editPageController.setIntroInfo(null);
                                            }
                                        }
                                    }else{
                                        if(action == "setOutro"){
                                            if(data){
                                                const outroInfo = data.outroInfo;
                                                if(this._openEditPageController.length > 0){
                                                    for(let index in this._openEditPageController){
                                                        editPageController = this._openEditPageController[index];
                                                        editPageController.setOutroInfo(outroInfo);
                                                    }
                                                }
                                            }
                                        }else{
                                            if(action == "unsetCurrentOutro"){
                                                if(this._openEditPageController.length > 0){
                                                    for(let index in this._openEditPageController){
                                                        editPageController = this._openEditPageController[index];
                                                        editPageController.setOutroInfo(null);
                                                    }
                                                }
                                            }else{
                                                
                                                if(action == "capsuleCreated"){

                                                }else{

                                                }
                                                
                                            }
                                        }

                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    },




    showEditPage : function(){
        const handlers = {};
        handlers.onCoverPictureChangeTrigger = this.upadteCoverArt.bind(this);
        handlers.onSweeperChangeTrigger = this.changeSweeper.bind(this);
        handlers.onSweeperChangeTriggerConfirm = this.changeSweeperConfirm.bind(this);
        handlers.onDeleteSweeperTrigger = this.deleteCurrentSweeperConfirm.bind(this);
        handlers.onGreaterChangeTrigger = this.changeGreater.bind(this);
        handlers.onIntroChangeTrigger = this.changeIntro.bind(this);
        handlers.onOutroChangeTrigger = this.changeOutro.bind(this);
        handlers.onGreaterChangeTriggerConfirm = this.changeGreaterConfirm.bind(this);
        handlers.onIntroChangeTriggerConfirm = this.changeIntroConfirm.bind(this);
        handlers.onOutroChangeTriggerConfirm = this.changeOutroConfirm.bind(this);
        handlers.onDeleteGreaterTrigger = this.deleteCurrentGreaterConfirm.bind(this);
        handlers.onDeleteIntroTrigger = this.deleteCurrentIntroConfirm.bind(this);
        handlers.onDeleteOutroTrigger = this.deleteCurrentOutroConfirm.bind(this);
        handlers.onActionExecutedOnTrebbleHandler = this._onActionExecutedOnTrebbleHandler.bind(this);
        const editPageController = PersistentModels.getInstance().getRouter().showTrebbleInfoEditPage(this._playlist, true, handlers).then((function(editPageController){
            if(editPageController){
                this._openEditPageController.push(editPageController);
            }
        }).bind(this)).catch(function(error){
            navigator.trebbleNotification.alert(error, null, false, "error");
        });

    },

    changeSweeper : function(){
        const params = {};
        params.trebbleModel = this._playlist;
        params.context = this._context;
        params.onActionExecutedOnTrebbleHandler = this._onActionExecutedOnTrebbleHandler.bind(this);
        return PersistentModels.getInstance().getRouter().require(["controllers/playlistPodcast/PersonalTrebbleOptionController"]).then((function(PersonalTrebbleOptionController){
            const personalTrebbleOptionController = new PersonalTrebbleOptionController(params);
            personalTrebbleOptionController.changeSweeper();
        }).bind(this)).catch(function(error){
            navigator.trebbleNotification.alert(error, null, false, "error");
        });
    },

    changeGreater : function(){
        const params = {};
        params.trebbleModel = this._playlist;
        params.context = this._context;
        params.onActionExecutedOnTrebbleHandler = this._onActionExecutedOnTrebbleHandler.bind(this);
        return PersistentModels.getInstance().getRouter().require(["controllers/playlistPodcast/PersonalTrebbleOptionController"]).then((function(PersonalTrebbleOptionController){
            const personalTrebbleOptionController = new PersonalTrebbleOptionController(params);
            personalTrebbleOptionController.changeGreater();
        }).bind(this)).catch(function(error){
            navigator.trebbleNotification.alert(error, null, false, "error");
        });
    },

    changeIntro : function(){
        const params = {};
        params.trebbleModel = this._playlist;
        params.context = this._context;
        params.onActionExecutedOnTrebbleHandler = this._onActionExecutedOnTrebbleHandler.bind(this);
        return PersistentModels.getInstance().getRouter().require(["controllers/playlistPodcast/PersonalTrebbleOptionController"]).then((function(PersonalTrebbleOptionController){
            const personalTrebbleOptionController = new PersonalTrebbleOptionController(params);
            personalTrebbleOptionController.changeIntro();
        }).bind(this)).catch(function(error){
            navigator.trebbleNotification.alert(error, null, false, "error");
        });
    },

    changeOutro : function(){
        const params = {};
        params.trebbleModel = this._playlist;
        params.context = this._context;
        params.onActionExecutedOnTrebbleHandler = this._onActionExecutedOnTrebbleHandler.bind(this);
        return PersistentModels.getInstance().getRouter().require(["controllers/playlistPodcast/PersonalTrebbleOptionController"]).then((function(PersonalTrebbleOptionController){
            const personalTrebbleOptionController = new PersonalTrebbleOptionController(params);
            personalTrebbleOptionController.changeOutro();
        }).bind(this)).catch(function(error){
            navigator.trebbleNotification.alert(error, null, false, "error");
        });
    },

    changeSweeperConfirm : function(){
        const buttonLabels = [window.getI18n(ti18n, "NO"),window.getI18n(ti18n, "YES")];
        const confirmCallback = (function(buttonIndex){
            if(buttonIndex ==  2){
                setTimeout((function(){
                    this.changeSweeper();
                }).bind(this), 100);

            }
        }).bind(this);
        const message = "Are you sure you want change this sweeper?"
        navigator.trebbleNotification.confirm(message , confirmCallback, "Change Sweeper", buttonLabels);
    },

    changeGreaterConfirm : function(){
        const buttonLabels = [window.getI18n(ti18n, "NO"),window.getI18n(ti18n, "YES")];
        const confirmCallback = (function(buttonIndex){
            if(buttonIndex ==  2){
                setTimeout((function(){
                    this.changeGreater();
                }).bind(this), 100);

            }
        }).bind(this);
        const message = "Are you sure you want change this greeter?"
        navigator.trebbleNotification.confirm(message , confirmCallback, "Change Greeter", buttonLabels);
    },

    changeIntroConfirm : function(){
        const buttonLabels = [window.getI18n(ti18n, "NO"),window.getI18n(ti18n, "YES")];
        const confirmCallback = (function(buttonIndex){
            if(buttonIndex ==  2){
                setTimeout((function(){
                    this.changeIntro();
                }).bind(this), 100);

            }
        }).bind(this);
        const message = "Are you sure you want change this audio intro?"
        navigator.trebbleNotification.confirm(message , confirmCallback, "Change Intro", buttonLabels);
    },

    changeOutroConfirm : function(){
        const buttonLabels = [window.getI18n(ti18n, "NO"),window.getI18n(ti18n, "YES")];
        const confirmCallback = (function(buttonIndex){
            if(buttonIndex ==  2){
                setTimeout((function(){
                    this.changeOutro();
                }).bind(this), 100);

            }
        }).bind(this);
        const message = "Are you sure you want change this audio outro?"
        navigator.trebbleNotification.confirm(message , confirmCallback, "Change Outro", buttonLabels);
    },

    deleteCurrentSweeper : function(){
        const params = {};
        params.trebbleModel = this._playlist;
        params.context = this._context;
        params.onActionExecutedOnTrebbleHandler = this._onActionExecutedOnTrebbleHandler.bind(this);
        return PersistentModels.getInstance().getRouter().require(["controllers/playlistPodcast/PersonalTrebbleOptionController"]).then((function(PersonalTrebbleOptionController){
            const personalTrebbleOptionController = new PersonalTrebbleOptionController(params);
            personalTrebbleOptionController.deleteCurrentSweeper();
        }).bind(this)).catch(function(error){
            navigator.trebbleNotification.alert(error, null, false, "error");
        });
    },

    deleteCurrentGreater : function(){
        const params = {};
        params.trebbleModel = this._playlist;
        params.context = this._context;
        params.onActionExecutedOnTrebbleHandler = this._onActionExecutedOnTrebbleHandler.bind(this);
        return PersistentModels.getInstance().getRouter().require(["controllers/playlistPodcast/PersonalTrebbleOptionController"]).then((function(PersonalTrebbleOptionController){
            const personalTrebbleOptionController = new PersonalTrebbleOptionController(params);
            personalTrebbleOptionController.deleteCurrentGreater();
        }).bind(this)).catch(function(error){
            navigator.trebbleNotification.alert(error, null, false, "error");
        });
    },

    deleteCurrentIntro : function(){
        const params = {};
        params.trebbleModel = this._playlist;
        params.context = this._context;
        params.onActionExecutedOnTrebbleHandler = this._onActionExecutedOnTrebbleHandler.bind(this);
        return PersistentModels.getInstance().getRouter().require(["controllers/playlistPodcast/PersonalTrebbleOptionController"]).then((function(PersonalTrebbleOptionController){
            const personalTrebbleOptionController = new PersonalTrebbleOptionController(params);
            personalTrebbleOptionController.deleteCurrentIntro();
        }).bind(this)).catch(function(error){
            navigator.trebbleNotification.alert(error, null, false, "error");
        });
    },

    deleteCurrentOutro : function(){
        const params = {};
        params.trebbleModel = this._playlist;
        params.context = this._context;
        params.onActionExecutedOnTrebbleHandler = this._onActionExecutedOnTrebbleHandler.bind(this);
        return PersistentModels.getInstance().getRouter().require(["controllers/playlistPodcast/PersonalTrebbleOptionController"]).then((function(PersonalTrebbleOptionController){
            const personalTrebbleOptionController = new PersonalTrebbleOptionController(params);
            personalTrebbleOptionController.deleteCurrentOutro();
        }).bind(this)).catch(function(error){
            navigator.trebbleNotification.alert(error, null, false, "error");
        });
    },

    deleteCurrentSweeperConfirm : function(){
        const buttonLabels = [window.getI18n(ti18n, "NO"),window.getI18n(ti18n, "YES")];
        const confirmCallback = (function(buttonIndex){
            if(buttonIndex ==  2){
                setTimeout((function(){
                    this.deleteCurrentSweeper();
                }).bind(this), 100);

            }
        }).bind(this);
        const message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_CHANGE_SWEEPER");
        navigator.trebbleNotification.confirm(message , confirmCallback,window.getI18n(ti18n, "CHANGE_SWEEPER"), buttonLabels);
    },

    deleteCurrentGreaterConfirm : function(){
        const buttonLabels = [window.getI18n(ti18n, "NO"),window.getI18n(ti18n, "YES")];
        const confirmCallback = (function(buttonIndex){
            if(buttonIndex ==  2){
                setTimeout((function(){
                    this.deleteCurrentGreater();
                }).bind(this), 100);

            }
        }).bind(this);
        const message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_CHANGE_GREETER")
        navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n, "CHANGE_GREETER"), buttonLabels);
    },

    deleteCurrentIntroConfirm : function(){
        const buttonLabels = [window.getI18n(ti18n, "NO"),window.getI18n(ti18n, "YES")];
        const confirmCallback = (function(buttonIndex){
            if(buttonIndex ==  2){
                setTimeout((function(){
                    this.deleteCurrentIntro();
                }).bind(this), 100);

            }
        }).bind(this);
        const message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_CHANGE_INTRO")
        navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n, "CHANGE_INTRO"), buttonLabels);
    },


    deleteCurrentOutroConfirm : function(){
        const buttonLabels = [window.getI18n(ti18n, "NO"),window.getI18n(ti18n, "YES")];
        const confirmCallback = (function(buttonIndex){
            if(buttonIndex ==  2){
                setTimeout((function(){
                    this.deleteCurrentOutro();
                }).bind(this), 100);

            }
        }).bind(this);
        const message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_CHANGE_OUTRO")
        navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n, "CHANGE_OUTRO"), buttonLabels);
    },

    playPreview : function(){
        PersistentModels.getInstance().getPlayer().playAudioPreview(this._playlist.getAudioPreviewUrl());
    },

    stopPreview : function(){
        PersistentModels.getInstance().getPlayer().terminateAudioPreviewAndResumePlayback();
    },


    showGlobalSearch : function(){
        this.hideAddContentButtons();
        PersistentModels.getInstance().getRouter().showGlobalSearchPage(null, true);
    },

    /*showDraftCapsulesInMyLibrary : function(){
        this.hideAddContentButtons();
        PersistentModels.getInstance().getRouter().showMyLibraryPage(null, "draftcapsules", true);
    },*/

    showChooseCapsuleFromLibraryAndAddToChannel : function(){
        this.hideAddContentButtons();
        return PersistentModels.getInstance().getRouter().showChooseCapsuleFromLibraryPopup((function(capsuleModelSelected){
            if(capsuleModelSelected){
                return PersistentModels.getInstance().getRouter().addCapsuleToTrebbleViaPopup(capsuleModelSelected,this.context);
            }
        }).bind(this), window.getI18n(ti18n, "ADD_FROM_DRAFT"), Utils.getInstance().getCapsuleStatuses().DRAFTS);
    },


    changeCoverArtConfirm : function(){
        const buttonLabels = [window.getI18n(ti18n, "NO"),window.getI18n(ti18n, "YES")];
        const confirmCallback = (function(buttonIndex){
            if(buttonIndex ==  2){
                $.mobile.loading("show");
                setTimeout((function(){
                    $.mobile.loading("hide");
                    this.upadteCoverArt();
                }).bind(this), 1000);

            }
        }).bind(this);
        const message = window.getI18n(ti18n, "DO_YOU_WANT_TO_CHANGE_TREBBLE_COVER");
        navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n, "CHANGE_COVER"), buttonLabels);
    } ,

    upadteCoverArt : function(noNeedToUpload){
        const trebbleId = this._playlist.getLoaderKey();
        let coverArtUrl = null;
        let radioInfo =  null;
        let uploadPromise = null;
        if(noNeedToUpload){
            uploadPromise = TrebbleClientAPIHelper.getInstance().getMyTrebbleInfoByTrebbleId(trebbleId).then((function(radioJsonInfo){
                radioInfo = radioJsonInfo;
            }).bind(this));
        }else{
            if(window.trebble.config.disableZoomInCoverArtInMobileApp || (window.device && window.device.platform =="browser")){
                uploadPromise =  this.uploadTrebbleCoverArtFromWebBrowser();
            }else{
                uploadPromise =  this.uploadTrebbleCoverArtFromMobile();
            }
        }
        return uploadPromise.then((function(uploadedImageFileUrl){
            $.mobile.loading('show');
            if(uploadedImageFileUrl && !noNeedToUpload){
                coverArtUrl = uploadedImageFileUrl;
                return TrebbleClientAPIHelper.getInstance().updateRadioCoverArtByRadioId(uploadedImageFileUrl,trebbleId).then((function(coverArtUrlBlur){
                    return Utils.getInstance().preloadImageUsingUrl(coverArtUrlBlur);
                }).bind(this));
            }

        }).bind(this)).then((function(coverArtUrlBlur){
            if(coverArtUrl || noNeedToUpload){
                if(noNeedToUpload){
                    coverArtUrl = radioInfo.coverArtUrl;
                    coverArtUrlBlur = radioInfo.coverArtUrlBlur;
                }
                this._playlist.setCoverArtUrl(coverArtUrl);
                this._playlist.setCoverArtUrlBlur(coverArtUrlBlur);
                //this is done to force the browser to reload the image
                const coverArtUrlWithRandomString = false?coverArtUrl + "?random="+ (new Date()).getTime(): coverArtUrl;
                const coverArtUrlWithRandomStringBlur = false?coverArtUrlBlur + "?random="+ (new Date()).getTime(): coverArtUrlBlur;
                this.updateTrebblePictureCoverUrlOnView(coverArtUrlWithRandomString, coverArtUrlWithRandomStringBlur);
                PersistentModels.getInstance().onPersonalTrebbleCreatedOrEdited(this._playlist.getPlaylistSummary().toJSON());
                if(this._openEditPageController.length > 0){
                    for(let index in this._openEditPageController){
                        const editPageController = this._openEditPageController[index];
                        editPageController.setCoverArtInfo(coverArtUrl);
                    }
                }
            }
            $.mobile.loading('hide');
            if(!noNeedToUpload){
                navigator.trebbleNotification.alert(window.getI18n(ti18n, "YOUR_TREBBLE_COVER_WAS_CHANGED"), null, false, "success");
            }
            return coverArtUrl;
        }).bind(this)).catch((function(error){
            $.mobile.loading('hide');
            if(!noNeedToUpload){
                navigator.trebbleNotification.alert(error, null, false, "error");
            }
        }).bind(this));
    },


    uploadTrebbleCoverArtFromWebBrowser : function(){
        const trebbleId = this._playlist.getLoaderKey();
        return Utils.getInstance().requireFileUploader(["models/helper/FileUploadHelper"]).then((function(FileUploadHelper){
            return new  RSVP.Promise((function(resolve, reject){
                const uploadEementId = "uploadWrapper_"+((new Date()).getTime());
                const nameOfFileToUpload =  "trebbleCoverArt_"+(new Date()).getTime()+".jpg";
                const text = window.isMobileBrowser?window.getI18n(ti18n, "TAP_HERE_TO_SELECT_IMAGE"): window.getI18n(ti18n, "CLICK_HERE_TO_SELECT_IMAGE");
                const uploadZoneHtml=  "<span class='uploadZonePlaceHolder' id='"+uploadEementId +"'><div class='pe-7s-upload uploadIcon' style='font-size:40px'></div><span  class='dz-message'>"+ text +"</span></span>";
                const buttonLabels = [window.getI18n(ti18n, "CANCEL"),window.getI18n(ti18n, "SAVE")];
                let dropZoneObj =  null;
                const confirmCallback = (function(buttonIndex){
                    if(buttonIndex ==  2){
                        if(dropZoneObj){
                            if(dropZoneObj.getQueuedFiles().length == 0){
                                setTimeout(function(){swal.enableButtons()}, 300);
                            }else{
                                FileUploadHelper.getInstance().validateAndSignFunctionHanlder(dropZoneObj, nameOfFileToUpload, FileUploadHelper.getInstance().getImageFileTypeValidationFunction(), "getSignatureForTrebbleCoverArtUpload" ,trebbleId).then(function(){
                                    dropZoneObj.processQueue();
                                }).catch(function(error){
                                    navigator.trebbleNotification.alert(error, null, false, "error");
                                })

                            }
                        }
                    }
                }).bind(this);
                navigator.trebbleNotification.confirm(uploadZoneHtml , confirmCallback, window.getI18n(ti18n, "CHANGE_TREBBLE_IMAGE_COVER"), buttonLabels, true, null , null, true);
                const handlers = Utils.getInstance().getConfirmDialogDeferredHandlers(resolve, reject, this);
                dropZoneObj =  FileUploadHelper.getInstance().uploadTrebbleCoverImageInWebBrowser(nameOfFileToUpload,"#"+ uploadEementId,handlers.resolve,handlers.reject);
            }).bind(this));
}).bind(this));

},

uploadTrebbleCoverArtFromMobile : function(){
const trebbleId = this._playlist.getLoaderKey();
return Utils.getInstance().getUserToPickAPicture().then((function(imageLocationUrl){
$.mobile.loading('show');
return FileUploadHelper.getInstance().uploadCoverArtForTrebble(imageLocationUrl, trebbleId);
}).bind(this))
},


_onSongListFinishLoading : function(){
//if(!this.model.canBeEdited()){
//FOR NOW , ALWAYS SHOW CAPSULES TAB AND ALWAYS HIDE MUSIC TAB, MIGHT NOT EVEN NEED TO LOAD IT
this.view.$el.removeClass("songOrCapsuleLoading");
if(false && this.songListController.getModel() && this.songListController.getModel().length > 0){
    this.view.$el.removeClass("showCapsulesOnly");
    this.view.$el.addClass("showSongsOnly");
    this.view.$el.find("#albumList .listHeader").html("MUSIC");
}else{
    this.view.$el.removeClass("showSongsOnly");
    this.view.$el.addClass("showCapsulesOnly");
    //this.view.$el.find("#albumList .listHeader").html("ON-AIR CAPSULES");

}

//}
},

isTrebblePlayable: function(){
    let numberOfSongs  = 0;
let numberOfCapsules = 0;
let numberOfActiveCapsules = 0;

if( this.songListController && !this.songListController.isLoading() && this.songListController.getModel()){
    numberOfSongs = this.songListController.getModel().length ;
}


if(this.capsuleCollectionRepeatController &&  !this.capsuleCollectionRepeatController.isLoading() && this.capsuleCollectionRepeatController.getModel()){
    numberOfCapsules = this.capsuleCollectionRepeatController.getModel().length;

    let numberOfBeingProcessedCapsule = 0;
    if(numberOfCapsules > 0){	
        for(let i = 0 ; i < this.capsuleCollectionRepeatController.getModel().length; i++){
            const capsuleModel = this.capsuleCollectionRepeatController.getModel()[i];
            const isBeingProcessed = capsuleModel.isBeingProcessed();
            if(isBeingProcessed){
                numberOfBeingProcessedCapsule = numberOfBeingProcessedCapsule + 1;
            }
        }
    }
    numberOfActiveCapsules =  numberOfCapsules - numberOfBeingProcessedCapsule;
}


return (this.capsuleCollectionRepeatController && this.capsuleCollectionRepeatController.isLoading()) || this.songListController.isLoading() || !this._playlist || !this._playlist.canBeEdited() || PlayerService.getInstance().isTrebblePlayableWithSongsAndCapsules(numberOfSongs, numberOfActiveCapsules);
},

_updateStartTrebbleButtonDisplay : function(){
    let numberOfSongs  = 0;
let numberOfCapsules = 0;
let numberOfActiveCapsules = 0;

if( this.songListController && !this.songListController.isLoading() && this.songListController.getModel()){
    numberOfSongs = this.songListController.getModel().length ;
}


if(this.capsuleCollectionRepeatController &&  !this.capsuleCollectionRepeatController.isLoading() && this.capsuleCollectionRepeatController.getModel()){
    numberOfCapsules = this.capsuleCollectionRepeatController.getModel().length;

    let numberOfBeingProcessedCapsule = 0;
    if(numberOfCapsules > 0){	
        for(let i = 0 ; i < this.capsuleCollectionRepeatController.getModel().length; i++){
            const capsuleModel = this.capsuleCollectionRepeatController.getModel()[i];
            const isBeingProcessed = capsuleModel.isBeingProcessed();
            if(isBeingProcessed){
                numberOfBeingProcessedCapsule = numberOfBeingProcessedCapsule + 1;
            }
        }
    }
    numberOfActiveCapsules =  numberOfCapsules - numberOfBeingProcessedCapsule;
}


//const trebblePlayable = (!this._playlist.canBeEdited() || (this.capsuleCollectionRepeatController && !this.capsuleCollectionRepeatController.isLoading())) && ( (this.capsuleCollectionRepeatController && this.capsuleCollectionRepeatController.isLoading()) || (this.songListController && this.songListController.isLoading()) || !this._playlist || !this._playlist.canBeEdited() || PlayerService.getInstance().isTrebblePlayableWithSongsAndCapsules(numberOfSongs, numberOfActiveCapsules));
const trebblePlayable = (this.capsuleCollectionRepeatController && !this.capsuleCollectionRepeatController.isLoading() &&  PlayerService.getInstance().isTrebblePlayableWithSongsAndCapsules(numberOfSongs, numberOfActiveCapsules))
if(MARK_TREBBLE_AS_NOT_PLAYABLE){
    if(trebblePlayable){
        this.view.$el.find("[id=startRadioBtn]").removeClass("notplayable");
    }else{
        this.view.$el.find("[id=startRadioBtn]").each(function() {
            const startBtn$el = $(this);
            if(!startBtn$el.hasClass("notplayable")){
                startBtn$el.addClass("notplayable");
            }
        });

    }
}
if(trebblePlayable){
    this.view.$el.find("#trebblewarning").removeClass("show");
    this.showHideShareMyTrebbleTooltipIfApplicable(this._playlist.canBeEdited());
    if(this._trebbleWasNotPlayableDuringCurrentSession){

    }
}else{
    this._trebbleWasNotPlayableDuringCurrentSession = trebblePlayable;
    const numberOfSongsLeftToStream =  (numberOfSongs >= Utils.getInstance().getDefaultNumberOfSongsRequiredToPlayATrebble())? 0 :Utils.getInstance().getDefaultNumberOfSongsRequiredToPlayATrebble() - numberOfSongs;
    //const numberOfCapsules =  PlayerService.getInstance().getNumberOfCapsules(this._playlist);
    let message;
    if(numberOfSongsLeftToStream > 0){
        message = window.getI18n(ti18n, "YOUR_TREBBLE_WILL_BE_READY_TO_STREAM_CAPSULE_PROCESSING");
    }else{
        /*if(numberOfSongsLeftToStream > 1){
            const message = "Your Trebble is almost ready to stream. Try creating a capsule or add "+ numberOfSongsLeftToStream +" more songs!";
        }else{
            const message = "Your Trebble is almost ready to stream. Try creating a capsule or add one more song!";
        }*/
        message = window.getI18n(ti18n, "TREBBLE_HAS_NO_CAPSULE");
    }
    this.view.$el.find("#trebblewarning > #message").html(message);
    const trebblewarning$el =  this.view.$el.find("#trebblewarning");
    if(!trebblewarning$el.hasClass("show")){
        trebblewarning$el.addClass("show");
    }
    this.showHideShareMyTrebbleTooltipIfApplicable(false);
    if(!this._isPageCurrentlyDisplayed()){
        //Utils.getInstance().showTrebbleReadyNotification(message);
    }

}
},

_isPageCurrentlyDisplayed : function(){
return this.view.$el.hasClass("ui-page-active");
},

/*_updateTrebbleWarningMessageIfApplicable : function(){
const trebblePlayable =  !this._playlist || !this._playlist.canBeEdited() || (PlayerService.getInstance().isTrebblePlayable(this._playlist) || (window._capsuleCreatedInThisSession && this._playlist.canBeEdited()));
if(trebblePlayable){
    this.view.$el.find("#trebblewarning").removeClass("show");
}else{
    const numberOfSongsLeftToStream =  PlayerService.getInstance().getNumberOfSongsLeftToStream(this._playlist);
    const numberOfCapsules =  PlayerService.getInstance().getNumberOfCapsules(this._playlist);
    const message = "Your Trebble is almost ready to stream. Try creating a capsule or add "+ numberOfSongsLeftToStream +" more songs!";
    this.view.$el.find("#trebblewarning > #message").html(message);
    const trebblewarning$el =  this.view.$el.find("#trebblewarning");
    if(!trebblewarning$el.hasClass("show")){
        trebblewarning$el.addClass("show");
    }
}
},*/

onStartRadioButtonTapped : function(){
if(this.isTrebblePlayable() || (window._capsuleCreatedInThisSession && this._playlist.canBeEdited())){//The second clause here is more of a around where if the user created a capsule in the current user session, we will try to play his trebble anyway. We are simply doing this because the numberof capsules on Trebble is not updated until the user refreshes. this is a bug


    $.mobile.loading( 'show', {
        text: window.getI18n(ti18n, "LOADING_TREBBLE"),
        textVisible: false,
        theme: "t",
        textonly: false,
        // html: html
    });

    this._retrieveDetailedPlaylistInfo().then((function(){
        return PersistentModels.getInstance().getPlayer().setPlaylist(this._playlist, true);
    }).bind(this)).then((function(){
        $.mobile.loading( 'hide');
        /*if(this._playlist){
            const loaderKey = this._playlist.getLoaderKey();
            const name = this._playlist.getName();
            const context = Utils.getInstance().buildContextForFollowingRadioDetail(loaderKey);
            TrebbleClientAPIHelper.getInstance().addTebbleLoadActionToMyHistory(loaderKey,name ,new Date(), null,context, null);
        }*/
    }).bind(this)).catch((function(error){
        $.mobile.loading( 'hide');
        if(PersistentModels.getInstance().getPlayer().isNoContentToPlayError(error)){
            this._onPlaylistNotBeingAbleToPlay();
            PersistentModels.getInstance().getPlayer().clearQueue();
        }else{
            console.error("Coudn't load playlist.Error:"+ error);
            window.alertErrorMessage(window.getI18n(ti18n, "COULDNT_LOAD_PLAYLIST")+ error);
        }
    }).bind(this));
}else{
    this._onPlaylistNotBeingAbleToPlay();
    PersistentModels.getInstance().getPlayer().clearQueue();
}
},

_onPlaylistNotBeingAbleToPlay : function(){
if(this._playlist.canBeEdited()){

    const buttonLabels = [window.getI18n(ti18n, "OK"), window.getI18n(ti18n, "CREATE_CAPSULE")];
    const confirmCallback = (function(buttonIndex){
        if(buttonIndex ==  2){
            setTimeout((function(){
                PersistentModels.getInstance().getRouter().showCreateACapsulePageWithoutContext(null);
            }).bind(this), 100);

        }else{

        }
    }).bind(this);
    const minimumOfSongsNeeded  =  Utils.getInstance().getDefaultNumberOfSongsRequiredToPlayATrebble();
    //const message = "A Trebble needs to contain 1 capsule or "+ minimumOfSongsNeeded +" songs minimum to be played."
    const message = window.getI18n(ti18n, "TREBBLE_HAS_NO_CONTENT");
    const subMessage = window.getI18n(ti18n, "YOU_CAN_ADD_CONTENT_BY_CREATING_CAPSULES");
    navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n, "NEED_TO_HAVE_MINIMUM_OF_SONGS"), buttonLabels, null,  null, subMessage);
    if(window.trebbleAnalyticsHelper){
        window.trebbleAnalyticsHelper.trackEvent("onboarding", "Discover Mininum Song Number For Trebble", "Discover Mininum Song Number For Trebble");
    } 
}else{
    navigator.trebbleNotification.alert(window.getI18n(ti18n, "THIS_TREBBLE_HAS_NO_ACTIVE_CAPSULE"), null, false);
}
},

_retrieveDetailedPlaylistInfo : function(){
//if(!this._playlist){
    if(this._playlistRetrievalPendingPromise){
        return this._playlistRetrievalPendingPromise;
    }else{
        const p = PlaylistHelper.getInstance().getPersonalUserRadioPlaylistById(this.model).then((function(playlist){
            this._detailedPlaylistInfoRetrieved = true; 
            this._playlistRetrievalPendingPromise = null;
            this._playlist = playlist;
            return this._playlist;
        }).bind(this));
        this._playlistRetrievalPendingPromise =  p;
        return this._playlistRetrievalPendingPromise;
    }
    /*	}else{
        return RSVP.Promise.resolve(this._playlist);
    }*/
},

loadPlaylistDetails : function(){
    return this._retrieveDetailedPlaylistInfo().then((function(){
        if(this._playlist){
            if(this._playlist.load){
                return this._playlist.retrieveAllAvailableSongsInJson();
            }else{
                return this._playlist.toJSON();
            }
        }else{
            return [];
        }

    }).bind(this)).catch(function(error){
        console.error("Coudn't load playlist.Error:"+ error);
        window.alertErrorMessage(window.getI18n(ti18n, "COULDNT_LOAD_PLAYLIST")+ error);
    });

},

onShareMyTrebbleTooltipIfApplicableClickedOn : function(){
    this.showHideShareMyTrebbleTooltipIfApplicable(false, true);
},

showHideShareMyTrebbleTooltipIfApplicable: function(show, wasAcknowledgeByUser){
    const share_my_trebble_tooltip$el = this.view.$el.find("#shareTrebbleBtn .share_my_trebble_tooltip");
    if(!this._shareMyTrebbleTooltipWasAcknoledgeByUser && show){
        share_my_trebble_tooltip$el.removeClass("hide");
    }else{
        if(!share_my_trebble_tooltip$el.hasClass("hide")){
            share_my_trebble_tooltip$el.addClass("hide");
            this._shareMyTrebbleTooltipWasAcknoledgeByUser = wasAcknowledgeByUser;
        }
    }
},



});
export default MyPersonalRadioDetailsController;