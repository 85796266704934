import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import TagItemView from "views/common/TagItemView";
import AllTagItemView from "views/common/AllTagItemView";
import Utils from "models/helper/Utils";
import TagCollectionTemplate from 'text!../../../templates/common/tagCollectionTemplate.html';

/*const ALL_OPTION_TAG_ID =  "#all";
const ALL_OPTION_TAG_LABEL =  "All";
const ALL_OPTION_TAG_VALUE =  "All";*/
const TagsCollectionView = Backbone.View.extend({



    initialize : function(options) {
        this.compiledTemlate =  _.template(TagCollectionTemplate);
        //this.tags = options? options.tags : [];
        this.tagGroup = options? options.tagGroup : null;
        this._allOptionTagAvalailable =   options? options.allOptionTagAvalailable : false;
        //this._selectedTagIds =  options? options.selectedTagIds : [];
        //this._exclusive =  options? options.exclusive :false;
        this._tagViews = {};
        this._allTagView =  null;
        this._viewElementId  = options? options.viewElementId : null;
        this._doNotShowHashTag = options? options.doNotShowHashTag : false;
            
    },
    
    getTagGroupId : function(){
        if(this.tagGroup){
            return this.tagGroup.getId();
        }else{
            return null;
        }
    },
    
    _buildTagItemViews : function(){
        if(!this.tagGroup.isExclusive() && this._allOptionTagAvalailable)
        {
            this._buildAllOptionTagItemView();
        }
        const selectedTagIds  = [];
        const unselectedTagIds = [];
        for(let index = 0;index < this.tagGroup.length;index++ ){
            const tag =  this.tagGroup.at(index);
            if(tag.get("selected")){
                selectedTagIds.push(tag.get("id"));
            }else{
                unselectedTagIds.push(tag.get("id"));
            }
            const tagItemView =  new TagItemView({"model" : tag, "doNotShowHashTag": this._doNotShowHashTag});
            tagItemView.render();
            this._tagsPlaceholder.append(tagItemView.$el);
            this.listenTo( tagItemView, "selectionChange", this.onTagClicked.bind(this));
            this._tagViews[tag.get("id")] = tagItemView;
            
        }
        if(unselectedTagIds.length == 0){
            this._allTagView.setSelected(true, true);
            for(let tagId in this._tagViews){
                const tagItemView = this._tagViews[tagId];
                tagItemView.setSelected(false, true);
            }
            this.tagGroup.setSelectedTagIds(selectedTagIds);
        }
    },
    
    _buildAllOptionTagItemView : function(){
        //const allOptionTag = Utils.getInstance().buildTag(ALL_OPTION_TAG_ID, ALL_OPTION_TAG_VALUE, ALL_OPTION_TAG_LABEL, null, false );
        const tagItemView =  new AllTagItemView();
        tagItemView.render();
        this._allTagPlaceholder.append(tagItemView.$el);
        this.listenTo( tagItemView, "selectionChange", this.onAllTagClicked.bind(this));
        this._allTagView	= tagItemView;
    },
    
    onTagClicked : function(tag, selected){
        if(this._allTagView && this._allTagView.isSelected()){
            this._allTagView.setSelected(false, true);
            const selectedTagId = tag.get("id");
            for(let tagId in this._tagViews){
                const tagItemView = this._tagViews[tagId];
                if(selectedTagId != tagId){
                    if(tagItemView.isSelected()){
                        tagItemView.setSelected(false, true);
                    }
                }else{
                    tagItemView.setSelected(true,true);
                }
            }
            this.tagGroup.setSelectedTagIds([selectedTagId]);
        }else{
            if(this.tagGroup.isExclusive() && selected){
                
                const selectedTagId = tag.get("id");
                for(let tagId in this._tagViews){
                    const tagItemView = this._tagViews[tagId];
                    if(selectedTagId != tagId){
                        if(tagItemView.isSelected()){
                            tagItemView.setSelected(false, true);
                        }
                    }
                }
                this.tagGroup.setSelectedTagIds([selectedTagId]);
                
            }
        }
        this.trigger("selectedTagIdsChange",this.tagGroup.getSelectedTagIds());
    },
    
    onAllTagClicked : function(tag, selected){
        const allTagIds = [];
        if(selected){
            for(let tagId in this._tagViews){
                allTagIds.push(tagId);
                const tagItemView = this._tagViews[tagId];
                tagItemView.setSelected(false, true);
            }
            this.tagGroup.setSelectedTagIds(allTagIds);
        }else{
            for(let tagId in this._tagViews){
                allTagIds.push(tagId);
                const tagItemView = this._tagViews[tagId];
                tagItemView.setSelected(false, true);
            }
            this.tagGroup.setSelectedTagIds([]);
        }
        
    },
    
    render : function() {
        const templateParams = {};
        this.$el.html(this.compiledTemlate(templateParams));
        this.setElement(this.$el.find("div").first());
        this._allTagPlaceholder = this.$el.find(".allTagPlaceHolder").first();
        this._tagsPlaceholder = this.$el.find(".tagPlaceholder").first();
        this._buildTagItemViews();
        if(this._viewElementId){
            this.$el.attr("id",this._viewElementId);
        }
        return this;
    }

});
export default TagsCollectionView;