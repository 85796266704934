import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import PlaylistListController from "controllers/common/PlaylistListController";
import DynamicPageView from "views/common/DynamicPageView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utils from "models/helper/Utils";
import PlayerService from "services/PlayerService";
import CollectionRepeatController from "controllers/common/CollectionRepeatController";
import JourneyCardController from "controllers/journey/JourneyCardController";
import PageTemplate from 'text!../../../templates/common/userListPageViewTemplate.html';
import ti18n from "i18n!nls/PlaylistPodcasti18n";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";

const JourneyListPageController = Chaplin.Controller.extend({


    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if (options) {
            if (options.journeyCollection) {
                this.model = options.journeyCollection;
                this.journeyCollection = options.journeyCollection;
            }
            if (options.pageId) {
                this.pageId = options.pageId;
            }
            if (options.searchKeyword) {
                this.searchKeyword = options.searchKeyword;
            }
            if(options.displayedInPageWithNoTab){
                this._displayedInPageWithNoTab = options.displayedInPageWithNoTab;
            }
            this._pageTitle =  options.pageTitle? options.pageTitle: window.getI18n(ti18n,"JOURNEYS");
            
        }

        const controllerParams = {};

        controllerParams.customImageUrlProperty = "data-original";
        controllerParams.notTransparent = true;
        controllerParams.smallcardsize = true;
        controllerParams.notPlayable = true;
        controllerParams.showEditablePageIfApplicable = false;


        const collectionRepeatControllerParams = {};
        collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildInfoBoxParamsForMySubscriptions();
        collectionRepeatControllerParams.showEmtptyInfoBox = true;
        collectionRepeatControllerParams.controllerOption = controllerParams;
        collectionRepeatControllerParams.displayCollectionInline = false;
        collectionRepeatControllerParams.itemControllerConstructor = JourneyCardController;
        collectionRepeatControllerParams.modelLoadHandler =   this.getJourneyListLoadHandler().bind(this);
        collectionRepeatControllerParams.context =  (options && options.context)?options.context:null;//Utils.getInstance().buildContextForSongCollectionGlobalSearch(this.searchKeyword);
        collectionRepeatControllerParams.resizePlaceHolderElem = true;

        collectionRepeatControllerParams.modelLoadMoreHandler = this.getJourneyListLoadHandlerPerPage().bind(this);
        collectionRepeatControllerParams.loadOnScroll = true;
        collectionRepeatControllerParams.numberOfResultsPerPage = 21;
        
        collectionRepeatControllerParams.heightMargin = 52 + (this._displayedInPageWithNoTab? 0: 39); //Page Header + Tab Header
        
        this.journeyListController = new CollectionRepeatController(collectionRepeatControllerParams);
        this.listenTo(this.journeyListController, "finishLoading", this.onPageShow);
        

    },

    _buildInfoBoxParamsForMySubscriptions : function(){
        const params = {};
        params.iconClass = "pe-7s-sun";
        params.message = window.getI18n(ti18n,"NO_JOURNEYS");
        params.helpMessage = null;
        params.actionButtonLabel = null;
        return params;

    },
    

    
    getModel : function(){
        return this.model;
    },
    
    getView : function(){
        return this.view;
    },
    
    navigateToPreviousPage : function(){
        PersistentModels.getInstance().getRouter().navigateToPreviousPage();
    },
    
    

    getJourneyListLoadHandler : function(){
        const searchKeyword = this.searchKeyword;
        return (function(){
            return TrebbleClientAPIHelper.getInstance().searchForJourneys(this.searchKeyword && this.searchKeyword.trim(), 100, 0).then((function(journeyJsonArray){
                const journeySummaryModelArray = [];
                if(journeyJsonArray){
                    for(let i = 0; i <journeyJsonArray.length ; i++){
                        journeySummaryModelArray.push(Utils.getInstance().buildJourneySummaryFromJson(journeyJsonArray[i]));
                    }
                }
                return journeySummaryModelArray;
            }).bind(this));
        }).bind(this);
        //return this.getJourneyListLoadHandlerPerPage(null, 0, 100);
    },

    getJourneyListLoadHandlerPerPage : function(){
        const searchKeyword = this.searchKeyword;
        return (function(filter, pageIndex, pageResults){
            return TrebbleClientAPIHelper.getInstance().searchForJourneys(this.searchKeyword && this.searchKeyword.trim(), pageResults, pageIndex).then((function(journeyJsonArray){
                const journeySummaryModelArray = [];
                if(journeyJsonArray){
                    for(let i = 0; i <journeyJsonArray.length ; i++){
                        journeySummaryModelArray.push(Utils.getInstance().buildJourneySummaryFromJson(journeyJsonArray[i]));
                    }
                }
                return journeySummaryModelArray;
            }).bind(this));
        }).bind(this);
    },


    onPageShow : function(){
        if(this.view.$el){
            this.view.$el.find("#collection_repeat_container").trigger("scrollstop");
        }
    },
    
    
    
    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve,reject){
            try{
                this.journeyListController.buildModelAndRenderView().then((function(){
                    const journeyListView = this.journeyListController.getView();
                    const pageTemplateOptions=  {};
                    pageTemplateOptions.pageTitle = this._pageTitle;
                    const pageViewParams= {};
                    pageViewParams.pageId = this.pageId;
                    pageViewParams.containerSelector = ".ui-content";
                    pageViewParams.contentView = journeyListView;
                    pageViewParams.pageViewTemplate = PageTemplate;
                    pageViewParams.addContentLeftRightPadding = true;
                    pageViewParams.ignoreDefaultPageUIContentPadding = true;
                    pageViewParams.pageTemplateOptions = pageTemplateOptions;
                    this.view = new DynamicPageView(pageViewParams);
                    this.view.render();
                    this.listenTo(this.view, "pageShowing", this.onPageShow);
                    //this.view.$el.find(".ui-content").css("background-color","#ffffff");

                    this.journeyListController.setScrollContainer(this.view.$el.find("#collection_repeat_container"));
                    const collection_repeat_container$el = this.view.$el.find(".ui-content #collection_repeat_container");
                    Utils.getInstance().initJqueryLazyLoadingPluginOnElemNew(collection_repeat_container$el, "img.lazy", collection_repeat_container$el);
                    this.view.$el.on("click","#header-back-button", this.navigateToPreviousPage.bind(this));
                    this.view.$el.find(".ui-content > div").addClass("left-right-content-padding");
                    resolve(this);
                }).bind(this)).catch(reject);

}catch(error){
reject(error);
}

}).bind(this));


}

});
export default JourneyListPageController;