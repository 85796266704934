import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerNode from "models/audioEditor/sequencerNodes/SequencerNode";
import AudioBufferUtils from "models/audioEditor/AudioBufferUtils";
import TrebbleAudioFactory from "models/helper/TrebbleAudioFactory";
import TrebbleVideoFactory from "models/helper/TrebbleVideoFactory";
import AudioFilterFactory from "models/audioEditor/filters/AudioFilterFactory";
import RSVP from "rsvp";

const BUILT_IN_AUDIO_FILTERS_NAMES = {"VOLUME":"volume","FADE_START":"fadeStart","FADE_END":"fadeEnd"};

const CLASSNAME = "VideoSequencerNode";
const VideoSequencerNode =  SequencerNode.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerNode.apply(this, [attributes, options]);
        this._initBuiltInDefaultFilters();
    },

    getConstructor : function(){
        return VideoSequencerNode;
    },

    afterInstanceCreatedFromConstructorForCloning : function(createdClonedInstance, sequencerNodeCidToCloneNodeMap){

    },

    getSerializedData : function(){
        const serializedData = SequencerNode.prototype.getSerializedData.call(this);
        serializedData.video = serializedData.video.toJSON();
        return serializedData;
    },

    restoreFromSerializedData: function(serializedData, audioSegmentFactoryInstance, audioFilterFactoryInstance, additionalSpeakerInfoArray){
        return SequencerNode.prototype.restoreFromSerializedData.call(this, serializedData, audioSegmentFactoryInstance, audioFilterFactoryInstance, additionalSpeakerInfoArray);
    },

    afterAllInstancesCreatedFromSerializedData : function(serializedData, sequencerNodeCidToSequencerNodeMap){
        SequencerNode.prototype.afterAllInstancesCreatedFromSerializedData.call(this, serializedData, sequencerNodeCidToSequencerNodeMap);
        this.setVideo(TrebbleVideoFactory.getInstance().createTrebbleVideoFromSerializedData(serializedData.video));
        this.getAudioSegment().set(serializedData.audioSegment);
    },


    cloneJSON: function(originalSequencerNodeToCloneMap){
        const objJson = SequencerNode.prototype.cloneJSON.call(this);
        objJson.video = this.get("video");
        objJson.type = VideoSequencerNode.CLASSNAME;
        objJson.mainVolumePercentage = this.get("mainVolumePercentage");
        objJson.introFadeInMilliseconds = this.get("introFadeInMilliseconds");
        objJson.outroFadeInMilliseconds = this.get("outroFadeInMilliseconds");
        objJson.introPaddingInMilliseconds = this.get("introPaddingInMilliseconds");
        objJson.outroPaddingInMilliseconds = this.get("outroPaddingInMilliseconds");
        return objJson;
    },

    clone: function(originalSequencerNodeToCloneMap){
        const existingClone  = originalSequencerNodeToCloneMap ? this.originalSequencerNodeToCloneMap[this]: null;
        return existingClone? existingClone: new VideoSequencerNode(this.cloneJSON(originalSequencerNodeToCloneMap));
    },

    

    _initBuiltInDefaultFilters: function(){
        const builtInGainFilter = AudioFilterFactory.getInstance().createGainFilter(1);
        builtInGainFilter.setName(BUILT_IN_AUDIO_FILTERS_NAMES.VOLUME)
        this.addBuiltInAudioFilter(builtInGainFilter.getName(), builtInGainFilter);
        const builtInFadeStartFiler = AudioFilterFactory.getInstance().createFadeStartFilter(0.1, 1, 0);
        builtInFadeStartFiler.setName(BUILT_IN_AUDIO_FILTERS_NAMES.FADE_START);
        this.addBuiltInAudioFilter(builtInFadeStartFiler.getName(), builtInFadeStartFiler);
        const builtInFadeEndFiler = AudioFilterFactory.getInstance().createFadeEndFilter(1, 0.1, 0);
        builtInFadeEndFiler.setName(BUILT_IN_AUDIO_FILTERS_NAMES.FADE_END);
        this.addBuiltInAudioFilter(builtInFadeEndFiler.getName(), builtInFadeEndFiler );
        this.listenTo(this.getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.VOLUME),"change:gainValue", (function(gainFilter){
            this.set("mainVolumePercentage",gainFilter.getGainValue());
        }).bind(this));
        this.listenTo(this.getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.FADE_START),"change:fadeDuration", (function(fadeFilter){
            this.set("introFadeInMilliseconds",fadeFilter.getFadeDuration());
        }).bind(this));
        this.listenTo(this.getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.FADE_END),"change:fadeDuration", (function(fadeFilter){
            this.set("outroFadeInMilliseconds",fadeFilter.getFadeDuration());
        }).bind(this));
    },

    setVideo : function(trebbleVideo){
        this.set("video",trebbleVideo);
        this.getAudioSegment().setAudioUrl(trebbleVideo.getAudioUrl());
    },

    getVideo :function(){
        return this.get("video");
    },

    _convertGainToPercentage : function(gainValue){
        const minDb = -40;
        const dB = (1 - gainValue) * minDb;
        const percentage =  AudioBufferUtils.getInstance().calcPerc(dB, minDb, 0);
        //return percentage /100;
        return gainValue;
    },

    _convertPercentageToGain : function(percentage){
        const minDb = -40;
        const gainValue = 1 - AudioBufferUtils.getInstance().calcDb(percentage * 100, minDb, 0)/minDb;
        //return gainValue;
        return percentage;
    },


    setVideoAndVideoSegmentStartTimeAndEndTime : function(audioAndAudioSegmentStartTimeAndEndTime){
        this.set("video",audioAndAudioSegmentStartTimeAndEndTime.trebbleVideo);
        this.getAudioSegment().setAudioUrl(audioAndAudioSegmentStartTimeAndEndTime.trebbleVideo.getAudioUrl());
        this.getAudioSegment().setStartTime(audioAndAudioSegmentStartTimeAndEndTime.startTime);
        this.getAudioSegment().setEndTime(audioAndAudioSegmentStartTimeAndEndTime.endTime);
    },

    getVideoAndVideoSegmentStartTimeAndEndTime :function(){
        const audioAndAudioSegmentStartTimeAndEndTime = {};
        audioAndAudioSegmentStartTimeAndEndTime.trebbleVideo = this.get("video");
        audioAndAudioSegmentStartTimeAndEndTime.startTime = this.getAudioSegment().getStartTime();
        audioAndAudioSegmentStartTimeAndEndTime.endTime = this.getAudioSegment().getEndTime();
        return audioAndAudioSegmentStartTimeAndEndTime;
    },

    setMainVolumePercentage : function(mainVolumePercentage){
        if(this.getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.VOLUME)){
            this.getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.VOLUME).setGainValue(this._convertPercentageToGain(mainVolumePercentage));
        }
    },

    getMainVolumePercentage : function(){
        if(this.getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.VOLUME)){
            return this._convertGainToPercentage(this.getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.VOLUME).getGainValue());
        }else{
            return 1;
        }
    },
    setIntroPaddingInMilliseconds : function(introPaddingInMilliseconds){
        this.set("introPaddingInMilliseconds",introPaddingInMilliseconds);
    },

    getIntroPaddingInMilliseconds : function(){
        return this.get("introPaddingInMilliseconds");
    },

    setOutroPaddingInMilliseconds : function(outroPaddingInMilliseconds){
        this.set("outroPaddingInMilliseconds",outroPaddingInMilliseconds);
    },

    getOutroPaddingInMilliseconds : function(){
        return this.get("outroPaddingInMilliseconds");
    },

    setIntroFadeInMilliseconds : function(introFadeInMilliseconds){
        if(this.getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.FADE_START)){
            this.getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.FADE_START).setFadeDuration(introFadeInMilliseconds);
        }
    },

    getIntroFadeInMilliseconds : function(){
        if(this.getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.FADE_START)){
            return this.getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.FADE_START).getFadeDuration();
        }else{
            return 0;
        }
    },

    setOutroFadeInMilliseconds : function(outroFadeInMilliseconds){
        if(this.getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.FADE_END)){
            this.getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.FADE_END).setFadeDuration(outroFadeInMilliseconds);
        }
    },

    getOutroFadeInMilliseconds : function(){
        if(this.getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.FADE_END)){
            return this.getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.FADE_END).getFadeDuration();
        }else{
            return 0;
        }
    },

    getContent:function(){
        return `<span>  <span class="icon pe-7s-film"></span>  </span>`;
    },



});

VideoSequencerNode.CLASSNAME =  CLASSNAME;
export default VideoSequencerNode; 