import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import RSVP from "rsvp";


const TruePeakHelper =  Backbone.Model.extend({
    idAttribute: "id",

    constructor: function(attributes, options) {
        Backbone.Model.apply(this, [attributes, options]);

    },


    getTruePeakData : function(buffer){
        return new RSVP.Promise((function(resolve, reject){
            try{


                const channel_count = buffer.numberOfChannels;

                const untouched_buffers = [];
                
                const leftChannel_untouched = buffer.getChannelData(0);

                const lengthInSeconds = leftChannel_untouched.length / buffer.sampleRate;

                const targetSampleRate = 192000;
                const OAC_TP = new OfflineAudioContext(channel_count, lengthInSeconds * targetSampleRate, targetSampleRate);
                const source = OAC_TP.createBufferSource();
                source.buffer = buffer;
                source.start();

                const gain = OAC_TP.createGain();
                gain.gain.value = 0.5;

                const lp_filter = OAC_TP.createBiquadFilter()
                lp_filter.type = "lowpass";
                lp_filter.frequency.value = 20000;

                source.connect(gain);
                gain.connect(lp_filter);
                lp_filter.connect(OAC_TP.destination);
                let worker_progress = 0;

                OAC_TP.startRendering().then((function(renderedBuffer) {
                    const buffers = [renderedBuffer.getChannelData(0)];
                    if (renderedBuffer.channelCount > 1){
                        buffers.push(renderedBuffer.getChannelData(1));
                    }
                    const tp_worker = new Worker("js/libs/loudEv/workers/true-peak-worker.js");
                    tp_worker.postMessage({
                        buffers: buffers,
                        width: window.innerWidth
                    });

                    tp_worker.onmessage = function(e) {
                        const data = e.data;
                        let max_true_peak = null;
                        let true_peak = null;
                        if (data.type == "finished"){
                            console.log('True peak: Worker has finished');
                            console.log("Maximum detected value: " + data.max + " dBTP");
                            max_true_peak = data.max;
                            true_peak = data.true_peak;
                            resolve({"maxTruePeak": max_true_peak, "truePeak": true_peak});
                            tp_worker.terminate();
                        }
                        if (data.type == "progress"){
                            worker_progress = data.progress;
                        }
                    };
                }).bind(this));

            }catch(error){
                reject(error);
            }
        }).bind(this))

    }


});

const TruePeakHelperInstance = new TruePeakHelper();

export default {
    getInstance : function() {
        return TruePeakHelperInstance;
    }
}; 