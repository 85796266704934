import React from "react";
import ConfigProvider from "./ConfigProvider";
import { Card } from "antd";
import useDefaultTrebbleAntDTokenConfig from "../helper/useDefaultTrebbleAntDTokenConfig";
import PropTypes from "prop-types";

const TrebbleCard = ({useEditorTheme, ...restProps}) => {
  const themeConfig = useDefaultTrebbleAntDTokenConfig({useEditorTheme});

  return (
    <ConfigProvider theme={themeConfig}>
      <Card {...restProps} />
    </ConfigProvider>
  );
};
TrebbleCard.propTypes = {
  useEditorTheme: PropTypes.bool
}
export default TrebbleCard;
