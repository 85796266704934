import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import ti18n from "i18n!nls/ConfigureBackgroundMusici18n";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import CustomSearchFieldView from "views/common/CustomSearchFieldView";
import Utils from "models/helper/Utils";
import ChooseMusicSoundsAndUploadsContentTemplate from 'text!../../../templates/chooseMusicSoundsAndUploads/ChooseMusicSoundsAndUploadsContentTemplate.html';

const Rollout = RolloutHelper.getInstance();

const ChooseMusicSoundsAndUploadsWidgetView = Backbone.View.extend({

    "pageName": "Choose Background Music",

    initialize: function(options) {
        this.contentViewTemplate = _.template(ChooseMusicSoundsAndUploadsContentTemplate);
        this.musicWidgetView = options.musicWidgetView;
        this.soundWidgetView = options.soundWidgetView;
        this.uploadsWidgetView = options.uploadsWidgetView;
        this.bigBankSoundWidgetView = options.bigBankSoundWidgetView;
        this._selectedTab$el =  null;
    },




    isLoading : function(isLoading){
        this.$el.find(".music_sounds_and_upload_widget").attr("isLoading",isLoading)
    },

    afterRendering: function() {
        if(this.musicWidgetView){
            this.musicWidgetWrapper$el.append(this.musicWidgetView.$el);
        }
        if(this.soundWidgetView){
            this.soundsWidgetWrapper$el.append(this.soundWidgetView.$el);
        }
        if(this.uploadsWidgetView){
            this.uploadsWidgetWrapper$el.append(this.uploadsWidgetView.$el);
        }
        if(this.bigBankSoundWidgetView){
            this.bigBankSoundsWidgetWrapper$el.append(this.bigBankSoundWidgetView.$el);
        }
    },

    _setSelectedTab: function(selectedTab$el){
        if( this._selectedTab$el){
           this._selectedTab$el.removeClass("selected");
       }
       this._selectedTab$el  = selectedTab$el;
       this._selectedTab$el.addClass("selected")
   },

   showMusicTab : function(){
       this._setSelectedTab(this.musicTab$el);
       this.soundsWidgetWrapper$el.hide();
       this.uploadsWidgetWrapper$el.hide();
       this.bigBankSoundsWidgetWrapper$el.hide();
       this.musicWidgetWrapper$el.css("display", "flex");
   },

   showSoundsTab : function(){
    this._setSelectedTab(this.soundTab$el);
    this.musicWidgetWrapper$el.hide();
    this.uploadsWidgetWrapper$el.hide();
    this.bigBankSoundsWidgetWrapper$el.hide();
    this.soundsWidgetWrapper$el.css("display", "flex");
},

showBigBankSoundsTab : function(){
    this._setSelectedTab(this.bigBankSoundTab$el);
    this.musicWidgetWrapper$el.hide();
    this.uploadsWidgetWrapper$el.hide();
    this.soundsWidgetWrapper$el.hide();
    this.bigBankSoundsWidgetWrapper$el.css("display", "flex");
},

showUploadsTab : function(){
    this._setSelectedTab(this.uploadsTab$el);
    this.soundsWidgetWrapper$el.hide();
    this.musicWidgetWrapper$el.hide();
    this.bigBankSoundsWidgetWrapper$el.hide();
    this.uploadsWidgetWrapper$el.css("display", "flex");
},

hideMusicTab: function(){
    this.musicTab$el.hide();
},

hideUploadab: function(){
    this.uploadsTab$el.hide();
},

moveBigBankSoundTabFirst : function(){
     this.bigBankSoundTab$el.insertBefore(this.$el.find(".trebble_tab_group > div:first-child"));
},


render : function() {
    const  templateParams = {};
    templateParams.ti18n =  ti18n;
    templateParams.Rollout = Rollout;
    this.$el.html(this.contentViewTemplate(templateParams));
    this.setElement(this.$el.find(".music_sounds_and_upload_widget").first());
    this.musicTab$el =  this.$el.find("#music_tab");
    this.soundTab$el =  this.$el.find("#sound_tab");
    this.bigBankSoundTab$el =  this.$el.find("#big_bank_sound_tab");
    this.uploadsTab$el =  this.$el.find("#uploads_tab");
    this.tabContentWrapper$el =  this.$el.find(".tab_content");
    this.musicWidgetWrapper$el =  this.$el.find(".music_widget_wrapper");
    this.soundsWidgetWrapper$el =  this.$el.find(".sounds_widget_wrapper");
    this.bigBankSoundsWidgetWrapper$el =  this.$el.find(".big_bank_sounds_widget_wrapper");
    this.uploadsWidgetWrapper$el =  this.$el.find(".uploads_widget_wrapper");

    this.musicTab$el.on("click", this.showMusicTab.bind(this));
    this.soundTab$el.on("click", this.showSoundsTab.bind(this));
    this.bigBankSoundTab$el.on("click", this.showBigBankSoundsTab.bind(this));
    this.uploadsTab$el.on("click", this.showUploadsTab.bind(this));

    this.afterRendering();
    return this;
}



});

export default ChooseMusicSoundsAndUploadsWidgetView;
