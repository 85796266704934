import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import Utils from "models/helper/Utils";
import PublishOrScheduleJourneyPopupView from "views/journey/PublishOrScheduleJourneyPopupView";
import PersistentModels from "services/PersistentModels";
import ListItemCollection from "collections/ListItemCollection";
import ti18n from "i18n!nls/MyPersonalRadiosi18n";
import RSVP from "rsvp";
import moment from 'moment';

const PublishOrScheduleJourneyPopupController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.model = options? options.journeyRadioId : null;
        this._journeyRadioId = options? options.journeyRadioId : null;
        this._journeyName = options? options.journeyName : null;
        this.context = options? options.context : null;
        this._changeScheduleTime = options  && options.changeScheduleTime? options.changeScheduleTime : null;
        this._presetScheduledTime = options  && options.presetScheduledTime? options.presetScheduledTime : null;
        this._presetTimezone = options  && options.presetTimezone? options.presetTimezone : null;
        this._onJourneyPublishHandler = options? options.onJourneyPublishHandler: null;
        this._onJourneyPublisErrorhHandler = options? options.onJourneyPublisErrorhHandler : null;
        this._onPopupCloseHanlder = options? options.onPopupClose : null;
        this._journeyWasPublishedOrScheduled = false;
        this._errorPublishingJourney = null;
        _.extend(this, Backbone.Events);
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    changePublishScheduledTime :function(){
        return this.publishJourneyOrScheduled(true);
    },


    changeScheduledJourneyConfirm : function(){

        const buttonLabels = [window.getI18n(ti18n, "NO"),window.getI18n(ti18n, "YES")];
        const confirmCallback = (function(buttonIndex){
            if(buttonIndex ==  2){
                setTimeout((function(){
                    this.publishJourneyOrScheduled(true);
                }).bind(this), 100);

            }
        }).bind(this);
        const isJourneyScheduled = this.view.isScheduled();
        const scheduleStartDate =   isJourneyScheduled? this.view.getScheduleStartDate(): null;
        const dateReadable = scheduleStartDate ? moment(scheduleStartDate).format('LLL') : null;
        const message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_CHANGE_SCHEDULED_DATE");
        const detailedMessage = scheduleStartDate? window.getI18n(ti18n, "AFTER_THI_JOURNEY_IS_MADE_AVAILABLE_ON") + " " + dateReadable + window.getI18n(ti18n, "YOU_WILL_NOT_BE_ABLE_TO_CHANGE_CONTENT"): window.getI18n(ti18n, "YOU_WILL_NOT_BE_ABLE_TO_EDIT_AFTER_ITS_AVAILABLE");
        navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n, "PUBLISH"), buttonLabels, null, null, detailedMessage);

    },

    publishJourneyConfirm : function(scheduled){
        
        const buttonLabels = [window.getI18n(ti18n, "NO"),window.getI18n(ti18n, "YES")];
        const confirmCallback = (function(buttonIndex){
            if(buttonIndex ==  2){
                setTimeout((function(){
                    this.publishJourneyOrScheduled();
                }).bind(this), 100);

            }
        }).bind(this);
        const isJourneyScheduled = this.view.isScheduled();
        const scheduleStartDate =   isJourneyScheduled? this.view.getScheduleStartDate(): null;
        const dateReadable = scheduleStartDate ? moment(scheduleStartDate).format('LLL') : null;
        
        const detailedMessage = scheduleStartDate? window.getI18n(ti18n, "AFTER_THI_JOURNEY_IS_MADE_AVAILABLE_ON") + " " + dateReadable + window.getI18n(ti18n, "YOU_WILL_NOT_BE_ABLE_TO_CHANGE_CONTENT"): window.getI18n(ti18n, "YOU_WILL_NOT_BE_ABLE_TO_EDIT_AFTER_ITS_AVAILABLE");

        const message = this.view.getScheduleStartDate()? window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_SCHEDULE_JOURNEY"):  window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_PUBLISH_JOURNEY");
        navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n, "PUBLISH"), buttonLabels, null, null, detailedMessage);

    },


    publishJourneyOrScheduled : function(scheduled){
        $.mobile.loading("show");

        const isJourneyScheduled = this.view.isScheduled();
        const scheduleStartDate =  (scheduled || isJourneyScheduled)? this.view.getScheduleStartDate(): null;
        const timezone = this.view.getTimezone();
        let publishingOperationResult =  null;
        const actionPromise = scheduled?PersistentModels.getInstance().changeJourneyPublishedDate(this._journeyRadioId, scheduleStartDate, timezone, this.context) : PersistentModels.getInstance().publishJourney(this._journeyRadioId, scheduleStartDate, timezone, this.context);
        return actionPromise.then((function(operationResult){
            publishingOperationResult = operationResult;
            this._journeyWasPublishedOrScheduled = scheduled? publishingOperationResult.journeyScheduledDateWasChanged: publishingOperationResult.journeyWasPublished;
            $.mobile.loading("hide");
            return this.view._close();	
        }).bind(this)).then((function(){
            if(this._journeyWasPublishedOrScheduled){
                return PersistentModels.getInstance().getRouter().showCongratulationsForJourneyPublishedOrScheduledPopup(this._journeyRadioId, this._journeyName, !!scheduleStartDate);
            }else{
                if(publishingOperationResult.isPaidJourney && !publishingOperationResult.userPaymentAccountIsSuccessfullySetup ){
                    if(scheduled){
                        this.showSchedulingBecauseAccountPaymentSetupIncomplete();
                    }else{
                        this.showPublishingBecauseAccountPaymentSetupIncomplete();
                    }
                }
            }
        }).bind(this)).catch((function(error){
            $.mobile.loading("hide");
            this._errorPublishingJourney = error;
            if(!this._errorPublishingJourney){
                window.alertErrorMessage(error);
            }
            this.view._close();
        }).bind(this));

    },

    showPublishingBecauseAccountPaymentSetupIncomplete : function(){
        window.alertErrorMessage(window.getI18n(ti18n, "YOUR_JOURNEY_COULDNT_BE_PUBLISHED"), window.getI18n(ti18n, "PLEASE_CONNECT_YOUR_BANKING_INFO_BEFORE_PUBLISHING"), function(){})
    },

    showSchedulingBecauseAccountPaymentSetupIncomplete : function(){
        window.alertErrorMessage(window.getI18n(ti18n, "YOUR_JOURNEY_COULDNT_BE_SCHEDULED"), window.getI18n(ti18n, "PLEASE_CONNECT_YOUR_BANKING_INFO_BEFORE_SCHEDULING"), function(){})
    },
    

    _onPopupClosed : function(){
        if(this._onPopupCloseHanlder){
            this._onPopupCloseHanlder();
        }
        if(this._onJourneyPublishHandler && !this._errorPublishingJourney){
            this._onJourneyPublishHandler(this._journeyWasPublishedOrScheduled, this.model);
        }
        if(this._onJourneyPublisErrorhHandler && this._errorPublishingJourney){
            this._onJourneyPublisErrorhHandler(this._errorPublishingJourney);
        }
    },



    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                const params = {};
                params.timezoneCollection = new ListItemCollection(Utils.getInstance().getTimezoneListItemArray());
                const trebbleDefauftTimezones = PersistentModels.getInstance().getAllMyPersonalRadioTimezones();
                const trebbleDefaultTimezone  = trebbleDefauftTimezones && trebbleDefauftTimezones.length> 0? trebbleDefauftTimezones[0]: Intl.DateTimeFormat().resolvedOptions().timeZone;
                params.selectedDefaultTimezone = trebbleDefaultTimezone;
                params.changeScheduleTime =  this._changeScheduleTime;
                params.presetScheduledTime =  this._presetScheduledTime;
                params.presetTimezone =  this._presetTimezone;
                const publishOrScheduleJourneyPopupView = new PublishOrScheduleJourneyPopupView(params);
                publishOrScheduleJourneyPopupView.render();
                this.view = publishOrScheduleJourneyPopupView;
                this.listenTo(this.view, "onPopupClosed", this._onPopupClosed.bind(this));
                this.listenTo(this.view, "publishJourney", this._changeScheduleTime? this.changeScheduledJourneyConfirm.bind(this): this.publishJourneyConfirm.bind(this));
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

}

});

export default PublishOrScheduleJourneyPopupController;