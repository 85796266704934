import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import SongContextViewTemplate from 'text!../../../templates/common/SongContextViewTemplate.html';
import RSVP from 'rsvp';

const SongContextView = Backbone.View.extend({



    initialize : function(options) {
        this.compiledTemlate =  _.template(SongContextViewTemplate);
        this._doNotShowCoverArt = options.doNotShowCoverArt;
        this._doNotShowArtistName = options.doNotShowArtistName;
        this.model = options? options.model : null;
    },
    
    events : {
        
    },
    
    render : function() {
        const templateParams = {"doNotShowArtistName": this._doNotShowArtistName,"doNotShowCoverArt": this._doNotShowCoverArt};
        templateParams.model  = this.model;
        this.$el.html(this.compiledTemlate(templateParams));
        this.setElement(this.$el.find("div").first());
        return this;
    }

});
export default SongContextView;