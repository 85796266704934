import { useState, useEffect } from 'react';
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import useBackboneModel from "./useBackboneModel";

const useDarkModeStatus = () => {
  const d = useBackboneModel(LocalStorageHelper.getInstance(),(model) => {
      return {appInDarkMode: model.isAppConfiguredToBeInDarkMode(), date: new Date()};
  },"appDarkMode");

  return d.appInDarkMode;
}

export default useDarkModeStatus;
