import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import searchButtonTemplate from 'text!../../../templates/common/searchButton.html';
import searchFieldTemplate from 'text!../../../templates/common/searchFieldEnhanced.html';
import ti18n from "i18n!nls/CustomSearchFieldi18n";

const trebbleCustomSearchFieldView = Backbone.View.extend({
    
    searchButtonClass  : "pe-7s-search",

    hintSearchButtonMessage : window.getI18n(ti18n, "SEARCH"),
    
    cancelButtonClass  : "ion-ios7-close-empty",

    hintCancelButtonMessage : window.getI18n(ti18n, "CLEAR_SEARCH"),
    
    searchFieldWrapperClass : "searchFieldWrapper",
    
    initialize : function(options) {
        this.searchFieldPlaceholderSelector = options.searchFieldPlaceholderSelector;
        this.searchFieldPlaceholderSelectorRootNodeEl = options.searchFieldPlaceholderSelectorRootNodeEl;
        this._placeholderText =  options.placeholderText;
        this.onSearchFieldChangeEventHandlers = [];
        this.onSearchFieldClearedFunctionHandlers = [];
        this.onScreenResizeHandlerFunction = [];
        this.onSearchFieldShowedFunctionHandlers = [];
        this.isButtonStateSearch = true;
        window.addEventListener('orientationchange', this._onScreenResize.bind(this));
        window.addEventListener('resize', this._onScreenResize.bind(this));
    },
    
    events : {
        "click .headerSearchBtn" : "onSearchButtonTapped",
    },
    
    _onScreenResize : function(){
        if(this.isSearchFieldDisplayed()){
            for(let handlerIndex in this.onScreenResizeHandlerFunction){
                const screenResizeHandler = this.onScreenResizeHandlerFunction[handlerIndex];
                screenResizeHandler(this.tempSearchFieldInput$Elem);
            }
        }
    },
    
    onButtonTapped: function(){
        if(this.isButtonStateSearch)
        {
            this.onSearchButtonTapped();
        }else{
            const searchFieldValue = this.tempSearchFieldInput$Elem.get(0).value;
            if( searchFieldValue == null || searchFieldValue =="")
            {
                this.onCancelButtonTapped();
            }else{
                this.tempSearchFieldInput$Elem.get(0).value = "";
                for(let handlerIndex in this.onSearchFieldChangeEventHandlers){
                    const keyUpHandler = this.onSearchFieldChangeEventHandlers[handlerIndex];
                    keyUpHandler("");
                }
            }
        }
    },
    
    onSearchButtonTapped : function(){
        let placeHolderElem;
        if(this.searchFieldPlaceholderSelectorRootNodeEl)
        {
            placeHolderElem = this.searchFieldPlaceholderSelectorRootNodeEl.querySelector(this.searchFieldPlaceholderSelector);
        }else{
            placeHolderElem = document.querySelector(this.searchFieldPlaceholderSelector);
        }
        
        if(placeHolderElem)
        {
            const tempSearchFieldForm$Elem = this._buildSearch$Form();
            const tempSearchFieldInput$Elem = tempSearchFieldForm$Elem.find("input").first();
            if(this._placeholderText){
                tempSearchFieldInput$Elem.attr("placeholder", this._placeholderText);
            }
            this.tempSearchFieldInput$Elem = tempSearchFieldInput$Elem;
            this.tempSearchFieldForm$Elem = tempSearchFieldForm$Elem;
            this.replacedNodeFirstChild = placeHolderElem.firstChild;
            $(placeHolderElem).parent().addClass(this.searchFieldWrapperClass);
            this.replacedNodeFirstChild? placeHolderElem.replaceChild(this.tempSearchFieldForm$Elem.get(0),this.replacedNodeFirstChild):placeHolderElem.appendChild(this.tempSearchFieldForm$Elem.get(0));
            this.setButtonIconToCancelIcon();
            this._onScreenResize();
            this.tempSearchFieldInput$Elem.trigger("click");
            
        }
    },
    
    showSearchField : function(makeSearchFieldUnclearable){
        if(!this.isSearchFieldDisplayed()){
            this.onSearchButtonTapped();
        }else{
            this.tempSearchFieldInput$Elem.trigger("click");
        }
        if(makeSearchFieldUnclearable){
            this.$el.find("t").hide();
        }
    },
    
    isSearchFieldDisplayed : function(){
        if(this.tempSearchFieldInput$Elem)
        {
            return true;
        }else{
            return false;
        }
    }, 
    
    getSearchFieldValue : function(){
        if(this.tempSearchFieldInput$Elem)
        {
            return this.tempSearchFieldInput$Elem.get(0).value;
        }else{
            return null;
        }
    },

    selectAllText : function(){
        this.tempSearchFieldInput$Elem.select();
    },
    
    setSearchFieldValue : function(newSearchFieldValue, triggerSeaarch){
        if(this.tempSearchFieldInput$Elem)
        {
            this.tempSearchFieldInput$Elem.get(0).value = newSearchFieldValue;
            if(triggerSeaarch){
                this.applySearch();
            }
        }else{
            //return null;
        }
    },

    clearSearchField : function(){
        if(!this.isButtonStateSearch)
        {
            this.onCancelButtonTapped();
        }
    },

    onCancelButtonTapped : function(){
        let placeHolderElem;
        if(this.searchFieldPlaceholderSelectorRootNodeEl)
        {
            placeHolderElem = this.searchFieldPlaceholderSelectorRootNodeEl.querySelector(this.searchFieldPlaceholderSelector);
        }else{
            placeHolderElem = document.querySelector(this.searchFieldPlaceholderSelector);
        }
        
        if(placeHolderElem)
        {
            $(placeHolderElem).parent().removeClass(this.searchFieldWrapperClass);
            this.replacedNodeFirstChild? placeHolderElem.replaceChild(this.replacedNodeFirstChild,this.tempSearchFieldForm$Elem.get(0)):placeHolderElem.removeChild(this.tempSearchFieldForm$Elem.get(0));
            this.replacedNodeFirstChild = null;
            this.tempSearchFieldInput$Elem = null;
            this.setButtonIconToSearchIcon();
            this._notifyListenersOnSearchFieldCleared();
        }
    },
    
    setButtonIconToCancelIcon : function(){
        this.$el.find("t").removeClass(this.searchButtonClass).addClass(this.cancelButtonClass);
        this.$el.attr("data-hint", this.hintCancelButtonMessage);
        this.isButtonStateSearch = false;
        this._notifySearchFieldShowedoOrHidden(true);
    },
    
    setButtonIconToSearchIcon : function(){
        this.$el.find("t").removeClass(this.cancelButtonClass).addClass(this.searchButtonClass);
        this.$el.attr("data-hint", this.hintSearchButtonMessage);
        this.isButtonStateSearch = true;
        this._notifySearchFieldShowedoOrHidden(false);
    },
    
    
    _buildSearch$Form : function(){
        const searchButtonCompiledTemplate = (_.template(searchFieldTemplate))({"ti18n": ti18n});
        //	const dummyDiv$El = $("div");
        //dummyDiv$El.html(searchButtonCompiledTemplate);
        const searchFieldForm$Elem  = $(searchButtonCompiledTemplate);
        const searchFieldInput$Elem = searchFieldForm$Elem.find("input").first();
        searchFieldInput$Elem.on("keyup input",this._notifyListenersOnKeyUp.bind(this))
        searchFieldForm$Elem.on("submit",this._onFormSubmit.bind(this));
        searchFieldInput$Elem.click(function(e){
            $(this).focus(); 
        });
        return searchFieldForm$Elem;
    },

    _getSearchFieldPlaceHolderElem : function(){
        if(this.searchFieldPlaceholderSelectorRootNodeEl)
        {
            return this.searchFieldPlaceholderSelectorRootNodeEl.querySelector(this.searchFieldPlaceholderSelector);
        }else{
            return document.querySelector(this.searchFieldPlaceholderSelector);
        }
    },


    focusSearchField: function(){
        if(this.isSearchFieldDisplayed()){
            const searchFieldInput$Elem = $(this._getSearchFieldPlaceHolderElem()).find("input");
            searchFieldInput$Elem.focus();
        }
    },
    
    _notifyListenersOnKeyUp : function(e){
        for(let handlerIndex in this.onSearchFieldChangeEventHandlers){
            const keyUpHandler = this.onSearchFieldChangeEventHandlers[handlerIndex];
            keyUpHandler(e.target.value);
        }
    },
    
    applySearch : function(){
        for(let handlerIndex in this.onSearchFieldChangeEventHandlers){
            const keyUpHandler = this.onSearchFieldChangeEventHandlers[handlerIndex];
            keyUpHandler(this.getSearchFieldValue());
        }
    },
    
    _notifyListenersOnSearchFieldCleared: function(){
        for(let handlerIndex in this.onSearchFieldClearedFunctionHandlers){
            const searchFieldClearedHandler = this.onSearchFieldClearedFunctionHandlers[handlerIndex];
            searchFieldClearedHandler();
        }
    },
    
    _notifySearchFieldShowedoOrHidden: function(displayed){
        for(let handlerIndex in this.onSearchFieldShowedFunctionHandlers){
            const searchFieldShowedFunctionHandler = this.onSearchFieldShowedFunctionHandlers[handlerIndex];
            searchFieldShowedFunctionHandler(displayed);
        }
    },
    
    
    addKeyUpHandlerFunction : function(handlerFunctionToAdd){
        this.onSearchFieldChangeEventHandlers.push(handlerFunctionToAdd);
    },
    
    addOnSearchFieldClearedFunction : function(handlerFunctionToAdd){
        this.onSearchFieldClearedFunctionHandlers.push(handlerFunctionToAdd);
    },
    
    addOnScreenResizeHandlerFunction : function(handlerFunctionToAdd){
        this.onScreenResizeHandlerFunction.push(handlerFunctionToAdd);
    },
    
    addOnSearchFieldShowedFunctionHandlers : function(handlerFunctionToAdd){
        this.onSearchFieldShowedFunctionHandlers.push(handlerFunctionToAdd);
    },
    _onFormSubmit : function(){
        this.applySearch();
        this.tempSearchFieldInput$Elem.blur();
        return false;
    },
    render : function() {
        this.$el.html((_.template(searchButtonTemplate))({"ti18n": ti18n}));
        this.setElement(this.$el.find("a").first());
        this.$el.on("click",this.onButtonTapped.bind(this));
        this.$el.find("form").on("submit",this._onFormSubmit.bind(this));
        this.$el.find("t").addClass(this.searchButtonClass) ;
        this.$el.attr("data-hint", this.hintSearchButtonMessage);
        this.isButtonStateSearch = true;
        return this;
    },
    
});

export default trebbleCustomSearchFieldView;