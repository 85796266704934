import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import RSVP from "rsvp";

const SequencerOperationLog =  Backbone.Model.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        Backbone.Model.apply(this, [attributes, options]);
        
    },

    getOperationName : function(){

    },


});

export default SequencerOperationLog; 