import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import ArtistSummaryController from "controllers/artistDetail/ArtistSummaryController";
import DynamicContentView from "views/common/DynamicContentView";
import RSVP from "rsvp";

const ArtistWidgetController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);

        this.artistId = null
        this.artistModel = null
        this.artistName = null
        this.capsuleRefId = null;
        this._visible = true;
        this._widgetHeight = null;
        this.context = null;
        this.displayedArtistSummaryController = null;
        
    },
    
    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    setHeight : function(height){
        this._widgetHeight = height;
    },

    setArtistInfo : function(artistId, artistModel,artistName, context, capsuleRefId, showCapsuleInfoOnly){
        this.artistId =  artistId;
        this.artistModel =  artistModel;
        this.artistName =  artistName;
        this.capsuleRefId = capsuleRefId;
        this.context =  context;
        this._updated = false;
        this._showCapsuleInfoOnly = showCapsuleInfoOnly;
        if(this._visible){
            return this._updateArtistSummary();
        }else{
            return RSVP.Promise.resolve();
        }
    },
    
    _updateArtistSummary : function(){
        this._updated = true;
        const previousArtistSummaryController = this.displayedArtistSummaryController;
        if(previousArtistSummaryController && previousArtistSummaryController.getView() && previousArtistSummaryController.getView().$el){
            previousArtistSummaryController.getView().$el.remove();
        }
        if(this.artistId || this.artistModel  || this.artistName || this.capsuleRefId){
            const artistSummaryControllerParams = {};
            artistSummaryControllerParams.artistId = this.artistId;
            artistSummaryControllerParams.artistModel = this.artistModel;
            artistSummaryControllerParams.artistName = this.artistName;
            artistSummaryControllerParams.capsuleRefId =  this.capsuleRefId;
            artistSummaryControllerParams.context =  this.context;
            artistSummaryControllerParams.widgetHeight = this._widgetHeight;
            artistSummaryControllerParams.showCapsuleInfoOnly = this._showCapsuleInfoOnly;
            this.displayedArtistSummaryController = new ArtistSummaryController(artistSummaryControllerParams);
            return this.displayedArtistSummaryController.buildModelAndRenderView().then((function(){
                if(this.self.displayedArtistSummaryController == this.controller){
                    this.self.view.$el.append(this.controller.getView().$el);
                }
                this.self.updateSwiperWidget();
            }).bind({"self": this, "controller": this.displayedArtistSummaryController }));
        }else{
            return RSVP.Promise.resolve();
        }
        
    },
    
    setVisible : function(visible,elementHeight){
        this._visible = visible;
        if(elementHeight){
            this.view.$el.css("height",elementHeight +"px");
        }
        if(this.displayedArtistSummaryController && this.displayedArtistSummaryController.getView() && this.displayedArtistSummaryController.getView().$el){
            if(elementHeight){
                this.displayedArtistSummaryController.getView().$el.css("height",elementHeight +"px");
            }
        }
        if(visible){
            this.view.$el.show();
            if(!this._updated){
                return this._updateArtistSummary();
            }else{
                return RSVP.Promise.resolve(); 
            }
        }else{
            this.view.$el.hide();
            return RSVP.Promise.resolve(); 
        }
        
        
    },
    
    updateSwiperWidget : function(){
        if(this.displayedArtistSummaryController && this.displayedArtistSummaryController.getView() && this.displayedArtistSummaryController.getView().$el){
            this.displayedArtistSummaryController.updateSwiperWidget();
        }
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                const contentView = new DynamicContentView();
                contentView.render();
                this.view = contentView;
                this.view.$el.css("width","100%");
                this.view.setHeight = this.setHeight.bind(this);
                resolve();
            } catch (error) {
                reject(error);
            }
        }).bind(this));

    }
});

export default ArtistWidgetController;