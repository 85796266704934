import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import ti18n from "i18n!nls/ArtistOptionMenui18n";
import popupTemplate from 'text!../../../templates/artistOptionMenu/ArtistOptionMenuPopup.html';

const ArtistOptionMenuView = Backbone.View.extend({

    template : _.template(popupTemplate),

    "pageName" : "Artist Menu",

    initialize : function(options) {
        
    },


    events : {


        "click #addToQueueBtn" : "addAllSongsToQueue",
        "click #playSimilarBtn" : "playSimilar",
        "click #playSimilarFromFollowersBtn" : "playSimilarFromFollowers",
        "click #browseArtistBtn" : "browseArtist",
        "click #closeBtn" : "_close",
        /*	"click #playSimilarFromFollowersBtn" : "playSimilarFromFollowers",
        "click #addToPlaylistBtn" : "addToPlaylist",
        "click #shareBtn" : "share",
        "click #DeleteBtn" : "delete",*/
        
    },

    addAllSongsToQueue : function(){
        this._close();
        this.trigger("addAllSongsToQueue", this.model);
    },
    
    _close : function(){
        this.$el.popup('close');
    },

    playSimilar : function(){
        this._close();
        this.trigger("playSimilar", this.model);	
    },
    
    playSimilarFromFollowers : function(){
        this._close();
        this.trigger("playSimilarFromFollowers", this.model);	
    },
    
    
    browseArtist : function(){
        this._close();
        this.trigger("browseArtist", this.model);				
    },

    

    render : function() {
        this.$el.html(this.template({"model":this.model.toJSON(), "ti18n": ti18n}));
        this.setElement(this.$el.find("div").first());
        const elementId = this.$el.attr('id');
        this.$el.on({
            popupbeforeposition: (function() {
                const maxHeight = $( window ).height() - 60 + "px";
                const width = $( window ).width() - 0 + "px";
                this.$el.css( "height", maxHeight );
                this.$el.css( "width", width );
            }).bind(this)
        });
        this.$el.on({
            popupafterclose: (function() {
                this.remove();
                this.undelegateEvents();
                
            }).bind(this)});
        $.mobile.pageContainer.prepend(this.$el);
        this.$el.popup({transition:"fade", dismissible: false,  tolerance: "0,0"});
        if(elementId)
        {
            const screenPopup$el = $("#"+elementId + "-screen");
            $.mobile.pageContainer.remove("#"+elementId + "-screen");
            $.mobile.pageContainer.prepend(screenPopup$el);
        }
        
        this.$el.enhanceWithin();
        Utils.getInstance().closeCurrentJQMPopup().then((function(){
            this.$el.popup('open' );
        }).bind(this));
        return this;
    }

});

export default ArtistOptionMenuView;