import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import GooglePlusHelper from "models/helper/GooglePlusHelper";
import owlcarousel from "owlcarousel";
import pageTemplate from 'text!../../../templates/SignUp/FullSignUpPageTemplate.html';
import ti18n from "i18n!nls/FullSignupPagei18n";
import RSVP from 'rsvp';
import Mousetrap from 'mousetrap';

const ACCESS_CODE_REQUIRED = true;
const DISABLE_PASSWORD_CONFIRM = true;
const FORM_VALIDATION_DELAY_IN_MILLISEC = 1000;
let BUILT_IN_ACCESS_CODE_FOR_LISTENERS = "7B2-DXWC-EMP1";
//const BUILT_IN_ACCESS_CODE_FOR_LISTENERS = "SAMS-UNG-KKGE";
let BUILT_IN_ACCESS_CODE_FOR_CREATORS = "7B2-DXWC-EMP1";
//const BUILT_IN_ACCESS_CODE_FOR_CREATORS = "SAMS-UNG-KKGE";
if(!window.waitForCordovaToLoad){
    BUILT_IN_ACCESS_CODE_FOR_LISTENERS = "WEBB-58D2-V8VT";
    BUILT_IN_ACCESS_CODE_FOR_CREATORS = "WEBB-58D2-V8VT";
}
const ALLOW_LISTERNERS_TO_SIGNUP_WITHOUT_ACCESS_CODE = true;
const ALLOW_CREATORS_TO_SIGNUP_WITHOUT_ACCESS_CODE = true;
const USE_NEW_GOOGE_API_ON_WEB = true;
const USE_CAPTCHA_VALIDATION = true;
const CAPTCHA_SITE_KEY = "6LfInWknAAAAACtY26KHdXIImyAdvLZHq_MCt-Nz";
const PROFILE_TYPE = Utils.getInstance().getProfileTypes();
const SLIDE_INDEXES ={
    "WELCOME_MESSAGE" : 0,
    "CHOOSE_PROFILE_TYPE" : 1,
    "CHOOSE_TREBBLE_NAME" : 2,
    "CHOOSE_TREBBLE_DESCRIPTION" : 3,
    "CHOOSE_TREBBLE_CATEGORY" : 4,
    "CHOOSE_TREBBLE_LANGUAGE" : 5,
    "CHOOSE_TREBBLE_URL" : 6,
    "PROVIDE_FLASH_BRIEFING_RSS_FEED" : 7,
    "FINISH_USER_SIGNUP" : 8
}

const SignUpPageView = Backbone.View.extend({

    template : _.template(pageTemplate),

    initialize : function(options) {
        this._removeOnHide = true;
        this.isUserpreviouslyLoggedInAsGuest = options? options.isUserpreviouslyLoggedInAsGuest: false;
        this._accessCode = options? options.accessCode: null;
        this._citySelectFieldView =  options.citySelectFieldView;
        if(this._accessCode == "undefined"){
            this._accessCode = "WEBB-58D2-V8VT";
        }
        this._hideBackButton = options? options.hideBackButton : false;
        this._mousetrapObj = null;
        this._mousetrapOnTrebbleNameObj = null;
        this._mousetrapOnTrebbleDescObj = null;
        this._enabledColor = "#e9605d";
        this._disabledColor = "#cccccc";
        this._allowUserToChooseProfileType = true;
        this._isCustomUrlUserEdited = false;
        this._userProfileType = PROFILE_TYPE.CREATOR;//options.isListener ?PROFILE_TYPE.LISTENER : PROFILE_TYPE.CREATOR;
        this._alreadyHasAFlashBriefing = false;
        this._selectedUserProfileType = null;
        this._displayWelcomeMessage = !this._allowUserToChooseProfileType && this._userProfileType === PROFILE_TYPE.CREATOR;
    },

    events : {
        "click #header-back-button" : "navigateToPreviousPage",
        "click #SignUpBtn.enabled" : "onSignUpBtnTapped",
        "click #SignUpWithFacebookBtn" : "onSignUpWithFacebookBtnTapped",
        "click #SignUpWithAppleBtn" : "onSignUpWithAppleBtnTapped",
        "click #showExternalSignupOptionsBtn": "onShowExternalSignupOptionsBtnTapped",
        "click #signUpWithEmailBtn" : "onSignUpWithEmailBtn",
        "click #nextButton:not(.trebble_disabled)" : "onNextButtonClicked",
        "click #previousButton:not(.trebble_disabled)" : "onPreviousButtonClicked",
        "click #iAmACuratorBtn" :"onIAmACuratorBtnClicked",
        "click #iAmAJourneyCreatorBtn" :"onIAmAJourneyCreatorBtnClicked",
        "click #iAmAShortcastCreatorBtn" :"onIAmAShortcastCreatorBtnClicked",
        "click #iAmACuratorWithFlashBriefingBtn" : "oniAmACuratorWithFlashBriefingBtnClicked",
        "click #iAmAListenerBtn" :"onIAmAListenerBtnClicked",
        "click #chooseProfileTypeBtn": "onChooseProfileTypeBtnClicked"
    },

    navigateToPreviousPage : function(){
        /*if(PersistentModels && PersistentModels.getInstance().getRouter()  ){
            PersistentModels.getInstance().getRouter().navigateToPreviousPage();
        }else{*/
            window.history.back();  
            //}

        },

        onSignUpBtnTapped : function(){

            const username  = this.usernameField$el.val();
            const password  =  this.newPassswordField$el.val();
            const email = this.emailField$el.val();
            //const firstName = this.firstNameField$el.val();
            //const lastName = this.lastNameField$el.val();
            //const yearBirthday = this.$el.find("#yearBirthdayField").val();
            const fullName = this.fullNameField$el.val();
            const accessCode = this.accessCodeField$el.val();
            const data = {};
            data.username =  username;
            data.password =  password;
            data.email =  email;
            data.fullName = fullName;
            //data.firstName =  firstName;
            //data.lastName =  lastName;
            data.profileType = this._selectedUserProfileType;

            //data.yearOfBirth =  yearBirthday;
            data.accessCode = accessCode;
            if(this._selectedUserProfileType == PROFILE_TYPE.CREATOR){
                data.trebbleName = this.trebbleNameField$el.val();
                data.trebbleDescription = this.trebbleDescriptionField$el.val();
                data.trebbleCategoryId = this.trebbleCategoryField$el.val();
                data.trebbleCustomUrl =  this.trebbleCustomUrlField$el.val();
                data.trebbleCityId = this._citySelectFieldView.getSelectedId();
                data.rssFeedUrlContentSource = this._alreadyHasAFlashBriefing ? this.rssFeedUrlField$el.val() : null;
                data.trebbleTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
                data.trebbleLanguage = this.getTrebbleLanguageCodeValue();
            }
            this.trigger("signUpBtnTapped", data);
        },

        onSignUpWithFacebookBtnTapped: function(){
            const data = {"accessCode": this.accessCodeField$el.val(),
            "trebbleName": this.trebbleNameField$el.val(),
            "trebbleDescription": this.trebbleDescriptionField$el.val() ,
            "trebbleCategoryId": this.trebbleCategoryField$el.val(),
            "trebbleCityId" : this._citySelectFieldView.getSelectedId(),
            "trebbleTimezone" : Intl.DateTimeFormat().resolvedOptions().timeZone,
            "trebbleLanguage" : this.getTrebbleLanguageCodeValue(),
            "trebbleCustomUrl": this.trebbleCustomUrlField$el.val(),
            "profileType": this._selectedUserProfileType,
            "rssFeedUrlContentSource": this._alreadyHasAFlashBriefing ? this.rssFeedUrlField$el.val() : null
        };
        this.trigger("signUpWithFacebookBtnTapped", data);
    },


    onSignUpWithAppleBtnTapped: function(){
        const data = {"accessCode": this.accessCodeField$el.val(),
        "trebbleName": this.trebbleNameField$el.val(),
        "trebbleDescription": this.trebbleDescriptionField$el.val() ,
        "trebbleCategoryId": this.trebbleCategoryField$el.val(),
        "trebbleCityId" : this._citySelectFieldView.getSelectedId(),
        "trebbleTimezone" : Intl.DateTimeFormat().resolvedOptions().timeZone,
        "trebbleLanguage" : this.getTrebbleLanguageCodeValue(),
        "trebbleCustomUrl": this.trebbleCustomUrlField$el.val(),
        "profileType": this._selectedUserProfileType,
        "rssFeedUrlContentSource": this._alreadyHasAFlashBriefing ? this.rssFeedUrlField$el.val() : null
    };
    this.trigger("signUpWithAppleBtnTapped", data);
},


_onGoogleSignupuccess : function(googleUser){
    const data = {"accessCode": this.accessCodeField$el.val(),
    "trebbleName": this.trebbleNameField$el.val(),
    "trebbleDescription": this.trebbleDescriptionField$el.val() ,
    "trebbleCategoryId": this.trebbleCategoryField$el.val(),
    "trebbleCityId" : this._citySelectFieldView.getSelectedId(),
    "trebbleTimezone" : Intl.DateTimeFormat().resolvedOptions().timeZone,
    "trebbleLanguage" : this.getTrebbleLanguageCodeValue(),
    "googleUser": googleUser,
    "trebbleCustomUrl": this.trebbleCustomUrlField$el.val(),
    "profileType": this._selectedUserProfileType,
    "rssFeedUrlContentSource": this._alreadyHasAFlashBriefing ? this.rssFeedUrlField$el.val() : null
};
this.trigger("signUpWithGoogleBtnSuccess", data);
},

_onGoogleSignupFailed : function(error){
this.trigger("signUpWithGoogleBtnFailed",error);
},
onSignUpWithEmailBtn : function(){
this.showOrHideSignupOptions(false);
},

setAccessCode :function(accessCode){
if(accessCode){

    this.accessCodeField$el.val(accessCode);
    this.accessCodeField$el.hide();
}
},

clearAccessCode : function(){
this.accessCodeField$el.val(null);
this.accessCodeField$el.show();
},

onShowExternalSignupOptionsBtnTapped : function(){
this.showOrHideSignupOptions(true);
},

getTrebbleLanguageCodeValue : function(){
let languageCode = this.trebbleLanguageField$el.val();
if(!languageCode){
    languageCode = "en";
}
return languageCode;
},

onIAmACuratorBtnClicked : function(){
if(this.carousel$el && this.carousel$el.data('owlCarousel')){
    const carouselData = this.carousel$el.data('owlCarousel');
    //Go to trebble Name Slide
    this.onboardingSignupFormWrapper$el.removeClass("listenerOnboardinOnly");
    if(!this.onboardingSignupFormWrapper$el.hasClass("creatorWithoutFlashBriefing")){
        this.onboardingSignupFormWrapper$el.addClass("creatorWithoutFlashBriefing");
    }
    carouselData.goTo(SLIDE_INDEXES.CHOOSE_TREBBLE_NAME);
    if(ALLOW_LISTERNERS_TO_SIGNUP_WITHOUT_ACCESS_CODE){
        if(!this._accessCode){
            this.clearAccessCode();
        }
    }
    if(ALLOW_CREATORS_TO_SIGNUP_WITHOUT_ACCESS_CODE){
        this.setAccessCode(BUILT_IN_ACCESS_CODE_FOR_CREATORS);
    }

}
this._selectedUserProfileType = PROFILE_TYPE.CREATOR;
this._alreadyHasAFlashBriefing = false;
},

onIAmAJourneyCreatorBtnClicked : function(){
if(this.carousel$el && this.carousel$el.data('owlCarousel')){
    const carouselData = this.carousel$el.data('owlCarousel');
    //Go to signup form slide
    if(ALLOW_LISTERNERS_TO_SIGNUP_WITHOUT_ACCESS_CODE){
        this.setAccessCode(BUILT_IN_ACCESS_CODE_FOR_LISTENERS);
    }
    if(!this.onboardingSignupFormWrapper$el.hasClass("listenerOnboardinOnly")){
        this.onboardingSignupFormWrapper$el.addClass("listenerOnboardinOnly");
    }
    carouselData.goTo(carouselData.maximumItem);

}
this._selectedUserProfileType = PROFILE_TYPE.JOURNEY_CREATOR;
this._alreadyHasAFlashBriefing = false;
},

onIAmAShortcastCreatorBtnClicked : function(){
if(this.carousel$el && this.carousel$el.data('owlCarousel')){
    const carouselData = this.carousel$el.data('owlCarousel');
    //Go to signup form slide
    if(ALLOW_LISTERNERS_TO_SIGNUP_WITHOUT_ACCESS_CODE){
        this.setAccessCode(BUILT_IN_ACCESS_CODE_FOR_LISTENERS);
    }
    if(!this.onboardingSignupFormWrapper$el.hasClass("listenerOnboardinOnly")){
        this.onboardingSignupFormWrapper$el.addClass("listenerOnboardinOnly");
    }
    carouselData.goTo(carouselData.maximumItem);

}
this._selectedUserProfileType = PROFILE_TYPE.SHORTCAST_CREATOR;
this._alreadyHasAFlashBriefing = false;
},

oniAmACuratorWithFlashBriefingBtnClicked  : function(){
if(this.carousel$el && this.carousel$el.data('owlCarousel')){
    const carouselData = this.carousel$el.data('owlCarousel');
    //Go to trebble Name Slide
    this.onboardingSignupFormWrapper$el.removeClass("listenerOnboardinOnly");
    carouselData.goTo(SLIDE_INDEXES.CHOOSE_TREBBLE_NAME);
    if(ALLOW_LISTERNERS_TO_SIGNUP_WITHOUT_ACCESS_CODE){
        if(!this._accessCode){
            this.clearAccessCode();
        }
    }
    if(ALLOW_CREATORS_TO_SIGNUP_WITHOUT_ACCESS_CODE){
        this.setAccessCode(BUILT_IN_ACCESS_CODE_FOR_CREATORS);
    }

}
this._selectedUserProfileType = PROFILE_TYPE.CREATOR;
this._alreadyHasAFlashBriefing = true;
},

onIAmAListenerBtnClicked : function(){
if(this.carousel$el && this.carousel$el.data('owlCarousel')){
    const carouselData = this.carousel$el.data('owlCarousel');
    //Go to signup form slide
    if(ALLOW_LISTERNERS_TO_SIGNUP_WITHOUT_ACCESS_CODE){
        this.setAccessCode(BUILT_IN_ACCESS_CODE_FOR_LISTENERS);
    }
    if(!this.onboardingSignupFormWrapper$el.hasClass("listenerOnboardinOnly")){
        this.onboardingSignupFormWrapper$el.addClass("listenerOnboardinOnly");
    }
    carouselData.goTo(carouselData.maximumItem);

}
this._selectedUserProfileType = PROFILE_TYPE.LISTENER;
this._alreadyHasAFlashBriefing = false;
},

onChooseProfileTypeBtnClicked  : function(){
if(this.carousel$el && this.carousel$el.data('owlCarousel')){
    const carouselData = this.carousel$el.data('owlCarousel');
    //Go to Choose profile type Slide
    carouselData.goTo(SLIDE_INDEXES.CHOOSE_PROFILE_TYPE);

}
},


showOrHideSignupOptions : function(show){
const fieldsToHideOrShow = "#emailDecorator, #firstNameField, #lastNameField,#fullNameField, #usernameField, #passwordDecId, #reenterPasswordDecId, #SignUpBtn, #captchaFieldDec"
if(show){
    this.$el.find(fieldsToHideOrShow).hide();
    this.$el.find("#showExternalSignupInfoWrapper").hide();
    this.$el.find("#signUpWithEmailBtn").css("display","block");
    this.$el.find("#SignUpWithFacebookBtn").css("display","flex");
    this.$el.find("#SignUpWithAppleBtn").css("display","flex");
    if(USE_NEW_GOOGE_API_ON_WEB){
        this.$el.find(".google_button_container").css("display","flex");
    }else{
        this.$el.find("#SignUpWithGoogleBtn").css("display","flex");
    }
}else{
    this.$el.find("#signUpWithEmailBtn").hide();
    this.$el.find("#SignUpWithFacebookBtn").hide();
    this.$el.find("#SignUpWithAppleBtn").hide();
    if(USE_NEW_GOOGE_API_ON_WEB){
        this.$el.find(".google_button_container").hide();
    }else{
        this.$el.find("#SignUpWithGoogleBtn").hide();
    }
    this.$el.find("#showExternalSignupInfoWrapper").css("display","block");
    this.$el.find(fieldsToHideOrShow).show();
    this.emailField$el.focus();
}
},



onBeforeRemoveFromPage : function(){
this.trigger("beforeRemoveFromPage", this);
if(this._mousetrapObj){
    this._mousetrapObj.unbind("enter");
}
if(this._mousetrapOnTrebbleDescObj){
    this._mousetrapOnTrebbleDescObj.unbind("tab");
}
if(this._mousetrapOnTrebbleNameObj){
    this._mousetrapOnTrebbleNameObj.unbind("tab");
}

},

onRemovedFromPage : function(){
this.undelegateEvents();
window.removeEventListener('resize', window[this._onViewportHeightChangedFunctionRef] );
this.$el.removeData().unbind(); 
this.trigger("removedFromPage", this);
},

onFieldValueChanged : function(event){
if(this.isAllMandatoryFieldsFilled()){
    this.trebbleLogo$el.css("color",this._enabledColor);
    this.signUpBtn$el.removeClass("disabled").addClass("enabled");
}else{
    this.trebbleLogo$el.css("color",this._disabledColor);
    this.signUpBtn$el.removeClass("enabled").addClass("disabled");
}
this.applyWorkAroundToBugWhereFormDissapearOnIOS();
},

applyWorkAroundToBugWhereFormDissapearOnIOS : function(){
//We were able to find out how to resolve that supposedly bug on iOS by observing the page via the Safari inspector and doing some testing
if(window.isIOSMobileDevice){	
    setTimeout((function(){
        this.pageHeader$el.removeClass("ui-fixed-hidden");
        this.pageHeader$el.addClass("ui-fixed-hidden");
    }).bind(this), 2000);
    setTimeout((function(){
        this.pageHeader$el.removeClass("ui-fixed-hidden");
    }).bind(this), 4000);
}
},
isAllMandatoryFieldsFilled : function(){
return this.usernameField$el.val() && this.validateUsername(this.usernameField$el.val())&& this.validatePasswordField() 
//&& this.firstNameField$el.val() && this.emailField$el.val()
&& this.fullNameField$el.val() && this.emailField$el.val()
&& this.validateEmail(this.emailField$el.val())
&& this.getCaptchaReponse()
//&& this.lastNameField$el.val() //&& this.$el.find("#yearBirthdayField").val() 
&& (!ACCESS_CODE_REQUIRED  || this.accessCodeField$el.val());
},

onUserNameFieldKeyUp :function(){
this.usernameField$el.val( this.usernameField$el.val().replace(/ /g, "_").toLowerCase());
},


_onViewportHeightChanged : function(){
const headerHeight = 54 ;
this.$el.find(".ui-content").css("height",(window.getDocumentClienHeight() -headerHeight)+ "px");
},

onTrebbleNameChanged : function(){
this.disableNextButton(true);
this.trigger("trebbleNameChanged");
if(this._validationTrebbleNameTriggerFunctionTimeout){
    clearTimeout(this._validationTrebbleNameTriggerFunctionTimeout);
}
this._validationTrebbleNameTriggerFunctionTimeout = setTimeout((function(){
    this.setTrebbleNameBeingValidate();
}).bind(this), FORM_VALIDATION_DELAY_IN_MILLISEC);

},

onTrebbleCustomUrlChanged : function(){
this.disableNextButton(true);
this._isCustomUrlUserEdited = true;
this.trebbleCustomUrlField$el.val( this.trebbleCustomUrlField$el.val().replace(/ /g, "-").toLowerCase());
const newCustomUrlValue  = this.trebbleCustomUrlField$el.val();
this.trigger("trebbleCustomUrlChanged");
if(this._validationTrebbleCustomUrlTriggerFunctionTimeout){
    clearTimeout(this._validationTrebbleCustomUrlTriggerFunctionTimeout);
}
this._validationTrebbleCustomUrlTriggerFunctionTimeout = setTimeout((function(){
    this.setTrebbleCustomURlBeingValidate();
}).bind(this), FORM_VALIDATION_DELAY_IN_MILLISEC);

},

onRssFeedURLChanged : function(){
this.disableNextButton(true);
const rssFeedValue  = this.rssFeedUrlField$el.val();
this.trigger("rssFeedUrlChanged");
if(this._validationRssFeedUrlTriggerFunctionTimeout){
    clearTimeout(this._validationRssFeedUrlTriggerFunctionTimeout);
}
this._validationRssFeedUrlTriggerFunctionTimeout = setTimeout((function(){
    this.setRSSFeedURlBeingValidate();
}).bind(this), FORM_VALIDATION_DELAY_IN_MILLISEC);

},

onTrebbleDescriptionChanged  : function(){
this.disableNextButton(true);
const trebbleDescription = this.trebbleDescriptionField$el.val();
if(!trebbleDescription){
    //empty field
    this.trebbleDescriptionfoWrapper$el.removeClass("successValidation");
    this.trebbleDescriptionfoWrapper$el.removeClass("failValidation");
    this.trebbleDescriptionfoWrapper$el.removeClass("showWaitMessage");
    this._trebbleDescriptionValid  = false;
}else{
    this.trigger("validateTrebbleDescription", this.trebbleDescriptionField$el.val());
}

},

onTrebbleCategoryChanged  : function(){
const trebbleCategory = this.trebbleCategoryField$el.val();
this.hideShowCityFieldIfApplicable();
if(!trebbleCategory){
    this._trebbleCategoryValid  = false;
    this.disableNextButton(true);
}else{
    this._trebbleCategoryValid  = true;
    if(trebbleCategory == Utils.getInstance().getLocalCategoryId()){
        if(this._citySelectFieldView.getSelectedId()){
            this.disableNextButton(false);
        }else{
            this.disableNextButton(true);
        }
    }else{
        this.disableNextButton(false);
    }
}
},

hideShowCityFieldIfApplicable : function(){
const trebbleCategory = this.trebbleCategoryField$el.val();
if(trebbleCategory == Utils.getInstance().getLocalCategoryId()){
    this._citySelectFieldView.$el.show();
}else{
    this._citySelectFieldView.$el.hide();
}

},

onTrebbleLanguageChanged  : function(){
const trebbleLanguage = this.trebbleLanguageField$el.val();
if(!trebbleLanguage){
    this._trebbleLanguageValid  = false;
    this.disableNextButton(true);
}else{
    this._trebbleLanguageValid  = true;
    this.disableNextButton(false);
}
},

getCustomUrl : function(){
return this.trebbleCustomUrlField$el.val();
},

getRssFeedUrl : function(){
return this.rssFeedUrlField$el.val();
},


isCustomUrlUserEdited : function(){
return this._isCustomUrlUserEdited;
},

setCustomUrl : function(customUrl){
return this.trebbleCustomUrlField$el.val(customUrl);
},

setRssFeedUrl : function(rssFeedUrl){
return this.rssFeedUrlField$el.val(rssFeedUrl);
},


setTrebbleNameBeingValidate : function(){
this.disableNextButton(true);
const trebbleName = this.trebbleNameField$el.val();
if(!trebbleName){
    //empty field
    this.trebbleNameInfoWrapper$el.removeClass("successValidation");
    this.trebbleNameInfoWrapper$el.removeClass("failValidation");
    this.trebbleNameInfoWrapper$el.removeClass("showWaitMessage");
    this._trebbleNameValid  = false;
}else{
    this.trebbleNameInfoWrapper$el.removeClass("successValidation");
    this.trebbleNameInfoWrapper$el.removeClass("failValidation");
    this.trebbleNameInfoWrapper$el.addClass("showWaitMessage");
    this.trigger("validateTrebbleName", this.trebbleNameField$el.val());
}
},

setTrebbleNameValid : function(isValid, errorMessage){
this._trebbleNameValid = isValid;
if(!errorMessage){
    errorMessage = window.getI18n(ti18n,"TREBBLE_NAME_VALIDATION_ERROR");
}
this.trebbleNameInfoWrapper$el.removeClass("showWaitMessage");
if(this._trebbleNameValid){
    this.trebbleNameInfoWrapper$el.removeClass("failValidation");
    this.trebbleNameInfoWrapper$el.addClass("successValidation");
    this.disableNextButton(false);
}else{
    this.trebbleNameInfoWrapper$el.removeClass("successValidation");
    this.trebbleNameInfoWrapper$el.find(".failValidationMessageBox .label").html(errorMessage);
    this.trebbleNameInfoWrapper$el.addClass("failValidation");
    this.disableNextButton(true);
}

},

setTrebbleCustomURlBeingValidate : function(){
this.disableNextButton(true);
const trebbleCustomUrl = this.trebbleCustomUrlField$el.val();
if(!trebbleCustomUrl){
    //empty field
    this.trebbleCustomUrlInfoWrapper$el.removeClass("successValidation");
    this.trebbleCustomUrlInfoWrapper$el.removeClass("failValidation");
    this.trebbleCustomUrlInfoWrapper$el.removeClass("showWaitMessage");
    this._trebbleCustomUrlValid  = false;
}else{
    this.trebbleCustomUrlInfoWrapper$el.removeClass("successValidation");
    this.trebbleCustomUrlInfoWrapper$el.removeClass("failValidation");
    this.trebbleCustomUrlInfoWrapper$el.addClass("showWaitMessage");
    this.trigger("validateTrebbleCustomUrl", trebbleCustomUrl);
}
},

setRSSFeedURlBeingValidate : function(){
this.disableNextButton(true);
const rssFeedValue = this.rssFeedUrlField$el.val();
if(!rssFeedValue){
    //empty field
    this.rssFeedURLInfoWrapper$el.removeClass("successValidation");
    this.rssFeedURLInfoWrapper$el.removeClass("failValidation");
    this.rssFeedURLInfoWrapper$el.removeClass("showWaitMessage");
    this._rssFeedValueValid  = true;
    this.trigger("validateRSSFeedURL", rssFeedValue);
}else{
    this.rssFeedURLInfoWrapper$el.removeClass("successValidation");
    this.rssFeedURLInfoWrapper$el.removeClass("failValidation");
    this.rssFeedURLInfoWrapper$el.addClass("showWaitMessage");
    this.trigger("validateRSSFeedURL", rssFeedValue);
}
},

setTrebbleCustomUrlValid : function(isValid, errorMessage){
this._trebbleCustomUrlValid = isValid;
if(!errorMessage){
    errorMessage = window.getI18n(ti18n,"CUSTOM_URL_ERROR_MESSAGE");
}
this.trebbleCustomUrlInfoWrapper$el.removeClass("showWaitMessage");
if(this._trebbleCustomUrlValid){
    this.trebbleCustomUrlInfoWrapper$el.removeClass("failValidation");
    this.trebbleCustomUrlInfoWrapper$el.addClass("successValidation");
    this.disableNextButton(false);
}else{
    this.trebbleCustomUrlInfoWrapper$el.removeClass("successValidation");
    this.trebbleCustomUrlInfoWrapper$el.find(".failValidationMessageBox .label").html(errorMessage);
    this.trebbleCustomUrlInfoWrapper$el.addClass("failValidation");
    this.disableNextButton(true);
}

},

setRSSFeedUrlValid : function(isValid, errorMessage){
this._rssFeedUrlValid = isValid;
if(!errorMessage){
    errorMessage = window.getI18n(ti18n,"THE_URL_PROVIDED_IS_NOT_SUPPORTED");
}
this.rssFeedURLInfoWrapper$el.removeClass("showWaitMessage");
if(this._rssFeedUrlValid){
    this.rssFeedURLInfoWrapper$el.removeClass("failValidation");
    if(this.rssFeedUrlField$el.val()){
        this.rssFeedURLInfoWrapper$el.addClass("successValidation");
    }

    this.disableNextButton(false);
}else{
    this.rssFeedURLInfoWrapper$el.removeClass("successValidation");
    this.rssFeedURLInfoWrapper$el.find(".failValidationMessageBox .label").html(errorMessage);
    this.rssFeedURLInfoWrapper$el.addClass("failValidation");
    this.disableNextButton(true);
}

},


setTrebbleDescriptionValid : function(isValid, errorMessage){
this._trebbleDescriptionValid = isValid;
if(!errorMessage){
    errorMessage = window.getI18n(ti18n,"SORRY_BUT_TREBBLE_DESCRIPTION_IS_BETWEEN_X_CHAR");
}
this.trebbleDescriptionfoWrapper$el.removeClass("showWaitMessage");
if(this._trebbleDescriptionValid){
    this.trebbleDescriptionfoWrapper$el.removeClass("failValidation");
    this.trebbleDescriptionfoWrapper$el.addClass("successValidation");
    this.disableNextButton(false);
}else{
    this.trebbleDescriptionfoWrapper$el.removeClass("successValidation");
    this.trebbleDescriptionfoWrapper$el.find(".failValidationMessageBox .label").html(errorMessage);
    this.trebbleDescriptionfoWrapper$el.addClass("failValidation");
    this.disableNextButton(true);

}

},


validateEmail : function(email) {
const re = Utils.getInstance().getEmailRegexValidation();
return re.test(email? email.trim(): email);
},


validateUsername : function(username){
const re = Utils.getInstance().getUsernameRegexValidation();
return re.test(username);
},

validateReenterPasswordField : function(){
const passwordDecorator = this.newPassswordFieldDec$el.get(0);
const passwordInput = this.newPassswordField$el.get(0);
const reenterPasswordDecorator = this.reenterNewPassswordFieldDec$el.get(0);
const reenterPasswordInput =  this.reenterNewPassswordField$el.get(0);
const pass2 = reenterPasswordInput.value;
const pass1 = passwordInput.value;
const arePasswordTheSame = pass1 === pass2;
if (!arePasswordTheSame) {
    const errorMessage = window.getI18n(ti18n,"PASSWORD_DONT_MATCH");
    //reenterPasswordInput.setCustomValidity(errorMessage);
    this.reenterNewPassswordFieldDec$el.find("label").attr("data-error",errorMessage);
    if(!this.reenterNewPassswordField$el.hasClass("invalid"))
    {
        this.reenterNewPassswordField$el.addClass("invalid");
    }
} else {
    // empty string means no validation error
    this.reenterNewPassswordField$el.removeClass("invalid");
    //reenterPasswordInput.setCustomValidity('');
    this.reenterNewPassswordFieldDec$el.find("label").attr("data-error","");
}
//passwordDecorator.isInvalid = !passwordInput.validity.valid;
//reenterPasswordDecorator.isInvalid = !reenterPasswordInput.validity.valid;
return arePasswordTheSame;
},

validatePasswordField : function(){
const passwordDecorator = this.newPassswordFieldDec$el.get(0);
const passwordInput = this.newPassswordField$el.get(0);
const pass1 = passwordInput.value;
const re = Utils.getInstance().getUserPasswordRegexValidation();
const isNewPasswordValid = re.test(pass1);
if (!isNewPasswordValid) {
    const errorMessage = window.getI18n(ti18n,"PASSWORD_MUST_BE_BETWEEN_X_CHAR");
    //passwordInput.setCustomValidity(errorMessage);
    this.newPassswordFieldDec$el.find("label").attr("data-error",errorMessage);
    if(!this.newPassswordField$el.hasClass("invalid"))
    {
        this.newPassswordField$el.addClass("invalid");
    }
} else {
    // empty string means no validation error
    this.newPassswordField$el.removeClass("invalid");
    //passwordInput.setCustomValidity('');
    this.newPassswordFieldDec$el.find("label").attr("data-error","");
}
//passwordDecorator.isInvalid = !passwordInput.validity.valid;
const reenterValid = DISABLE_PASSWORD_CONFIRM ?true: this.validateReenterPasswordField();
return isNewPasswordValid && reenterValid;
},

_onPageShow : function(){
this.afterRendering();
if(this._hideBackButton){

    this.$el.find("#header-back-button").hide();
}


},

_updateHeaderButtonsVisibility : function(){

if(this.carousel$el && this.carousel$el.data('owlCarousel')){

    const carouselData = this.carousel$el.data('owlCarousel');

    if(carouselData.currentItem +1 > carouselData.maximumItem){
        this.nextButton$el.hide();
        //this.$el.find("#saveButtonIcon").show();
    }else{
        this.nextButton$el.show();
        //this.$el.find("#saveButtonIcon").hide();
    }
    if(carouselData.currentItem > 0){
        this.previousButton$el.show();
        this.hideBackButton();
    }else{
        this.previousButton$el.hide();
        this.showBackButton();
    }

    this._carouselPosition = carouselData.currentItem;
    if(this._carouselPosition === SLIDE_INDEXES.WELCOME_MESSAGE || this._carouselPosition === SLIDE_INDEXES.CHOOSE_PROFILE_TYPE){
        //user profile type choice slide is displayed or welcome Page is displayed
        this.disableNextButton(true);
        this.previousButton$el.hide();
        this.nextButton$el.hide();
        this.showBackButton();

    }
    if(this._carouselPosition === SLIDE_INDEXES.CHOOSE_TREBBLE_NAME){
        //Trebble Name Field is being displayed
        this.disableNextButton(true);
        this.nextButton$el.show();
        if(this._allowUserToChooseProfileType){
            this.previousButton$el.show();
            this.hideBackButton();
        }else{
            //We are assuming the user has the curator profile
            this.previousButton$el.hide();
            this.showBackButton();
        }
        return this.setTrebbleNameBeingValidate();

    }
    if(this._carouselPosition === SLIDE_INDEXES.CHOOSE_TREBBLE_DESCRIPTION){
        //Trebble Description Field is being displayed
        this.disableNextButton(true);
        this.nextButton$el.show();
        this.previousButton$el.show();
        this.hideBackButton();
        return this.onTrebbleDescriptionChanged();

    }
    if(this._carouselPosition === SLIDE_INDEXES.CHOOSE_TREBBLE_CATEGORY){
        //Trebble category Field is being displayed
        this.disableNextButton(true);
        this.nextButton$el.show();
        this.previousButton$el.show();
        this.hideBackButton();
        return this.onTrebbleCategoryChanged();

    }
    if(this._carouselPosition === SLIDE_INDEXES.CHOOSE_TREBBLE_LANGUAGE){
        //Trebble language Field is being displayed
        this.disableNextButton(true);
        this.nextButton$el.show();
        this.previousButton$el.show();
        this.hideBackButton();
        return this.onTrebbleLanguageChanged();

    }
    if(this._carouselPosition === SLIDE_INDEXES.CHOOSE_TREBBLE_URL){
        //Trebble custom  URL Field is being displayed
        this.disableNextButton(true);
        this.nextButton$el.show();
        this.previousButton$el.show();
        this.hideBackButton();
        return this.setTrebbleCustomURlBeingValidate();

    }
    if(this._carouselPosition === SLIDE_INDEXES.PROVIDE_FLASH_BRIEFING_RSS_FEED){
        //Existing RSS Feed is being displayed
        this.disableNextButton(true);
        this.nextButton$el.show();
        this.previousButton$el.show();
        this.hideBackButton();
        return this.setRSSFeedURlBeingValidate();

    }
    if(this._carouselPosition === SLIDE_INDEXES.FINISH_USER_SIGNUP){
        //Sign up form is being displayed
        this.disableNextButton(false);
        this.nextButton$el.hide();
        if(this._allowUserToChooseProfileType){
            this.previousButton$el.show();
            this.hideBackButton();
        }else{
            if(this._userProfileType === PROFILE_TYPE.LISTENER || this._userProfileType === PROFILE_TYPE.JOURNEY_CREATOR || this._userProfileType === PROFILE_TYPE.SHORTCAST_CREATOR){
                //Listener profile
                this.previousButton$el.hide();
                this.showBackButton();	
            }else{
                //Creator profile
                this.previousButton$el.show();
                this.hideBackButton();

            }


        }
        return this.onTrebbleDescriptionChanged();

    }
}
},

hideBackButton : function(){
this.headerBackButton$el.hide();
},

showBackButton : function(){
this.headerBackButton$el.show();
},



onNextButtonClicked: function() {
if(this.carousel$el && this.carousel$el.data('owlCarousel')){
    const carouselData = this.carousel$el.data('owlCarousel');
    if(this._allowUserToChooseProfileType && this._selectedUserProfileType == PROFILE_TYPE.CREATOR &&  carouselData.currentItem === SLIDE_INDEXES.CHOOSE_TREBBLE_URL  && !this._alreadyHasAFlashBriefing ){
        carouselData.goTo(SLIDE_INDEXES.FINISH_USER_SIGNUP);
        return;
    }
    if(carouselData.currentItem + 1 <= carouselData.maximumItem){
        carouselData.next();
    }
}
},

_initializeCarousels: function() {


this.carousel$el = this.$el.find(".carouselWrapper");
this.carousel$el.owlCarousel({

    navigation: false, // Show next and prev buttons
    pagination: false,
    slideSpeed: 300,
    paginationSpeed: 400,
    afterAction: this._onCarouselMove.bind(this),
    touchDrag: false,
    mouseDrag: false,
    singleItem: true
});
const carouselData = this.carousel$el.data('owlCarousel');
this._numberOfCarouselItems = 4;
this._currentCarouselPositon = 0;
if(this._allowUserToChooseProfileType && !this._displayWelcomeMessage){
    //leave the carousel at the first screen
    carouselData.goTo(SLIDE_INDEXES.CHOOSE_PROFILE_TYPE);
    //Also hide all the next slides like the user was in listener onboarding mode
    this.onboardingSignupFormWrapper$el.addClass("listenerOnboardinOnly");
}else{
    if(this._displayWelcomeMessage){
        carouselData.goTo(SLIDE_INDEXES.WELCOME_MESSAGE);
    }else{
        if(this._userProfileType === PROFILE_TYPE.LISTENER || this._userProfileType === PROFILE_TYPE.JOURNEY_CREATOR || this._userProfileType === PROFILE_TYPE.SHORTCAST_CREATOR){
            //jump to the last slide
            //carouselData.goTo(carouselData.maximumItem);
            carouselData.goTo(SLIDE_INDEXES.FINISH_USER_SIGNUP);
        }else{
            //jump to the third slide (Choose your trebble Name)
            carouselData.goTo(SLIDE_INDEXES.CHOOSE_TREBBLE_NAME);
        }
    }
}
this._onCarouselMove();
/*
const swiperParams  =  {
    speed: window.isMobileBrowser?200: 300,
    longSwipesRatio : window.isMobileBrowser?0.2: 0.4,
    watchSlidesProgress: true,
    preloadImages: false,
    watchSlidesVisibility: true,
    slidesPerView: 1,
    centeredSlides: true,
    paginationHide: true,
    slideToClickedSlide: false,
    lazyLoading: true,
    lazyLoadingInPrevNext: true,
    lazy: {
        loadPrevNext: true
    }
};

this._swiperObj = new Swiper(".swiper-container",swiperParams);
this._swiperObj.allowSlideNext =  false;
*/



},



_onCarouselMove: function() {
/*
if(this.carousel$el && this.carousel$el.data('owlCarousel')){
    const carouselData = this.carousel$el.data('owlCarousel');
    this._carouselPosition = carouselData.currentItem;
    if(this._carouselPosition === 0){
        //user profile type choice slide is displayed
        this.disableNextButton(true);

    }
    if(this._carouselPosition === 1){
        //Trebble Name Field is being displayed
        this.disableNextButton(true);
        return this.setTrebbleNameBeingValidate();

    }
    if(this._carouselPosition === 2){
        //Trebble Description Field is being displayed
        this.disableNextButton(true);
        return this.onTrebbleDescriptionChanged();

    }
    if(this._carouselPosition === 3){
        //Sign up form is being displayed
        this.disableNextButton(false);
        return this.onTrebbleDescriptionChanged();

    }
}*/
this._updateHeaderButtonsVisibility();
if(window.isIOSMobileDevice){
    const carouselData = this.carousel$el.data('owlCarousel');
    if(carouselData && carouselData.currentItem === SLIDE_INDEXES.FINISH_USER_SIGNUP){
        window.log("user is at finish setup section");
        //User is at the sign up page so disable the iOS scrolling on the entire page to make it easy for the user to scroll on the form
        if(!this.$el.find(".ui-content").hasClass("disableIOSScrolling")){
            this.$el.find(".ui-content").addClass("disableIOSScrolling");
        }
    }else{
        this.$el.find(".ui-content").removeClass("disableIOSScrolling");
    }
}
},



onPreviousButtonClicked: function() {
if(this.carousel$el && this.carousel$el.data('owlCarousel')){

    const carouselData = this.carousel$el.data('owlCarousel');
    if(!this._allowUserToChooseProfileType && carouselData.currentItem === SLIDE_INDEXES.CHOOSE_TREBBLE_NAME){
        //user is at the trebble name slide
        //Do not allow user to go back to first screen
        return;
    }
    if(this._allowUserToChooseProfileType && this._selectedUserProfileType == PROFILE_TYPE.CREATOR &&  carouselData.currentItem === SLIDE_INDEXES.FINISH_USER_SIGNUP  && !this._alreadyHasAFlashBriefing ){
        //If user choose to be creator and he/she is on the last step, jump to the page to choose an url
        carouselData.goTo(SLIDE_INDEXES.CHOOSE_TREBBLE_URL);
        return;
    }
    if(this._allowUserToChooseProfileType && carouselData.currentItem === SLIDE_INDEXES.CHOOSE_TREBBLE_NAME){
        this.onboardingSignupFormWrapper$el.removeClass("creatorWithoutFlashBriefing");
    }
    if(this._allowUserToChooseProfileType && carouselData.currentItem === SLIDE_INDEXES.FINISH_USER_SIGNUP){
        //user is at the signup form
        //go back to first slide
        if(this._selectedUserProfileType == PROFILE_TYPE.LISTENER || this._selectedUserProfileType == PROFILE_TYPE.JOURNEY_CREATOR  || this._selectedUserProfileType == PROFILE_TYPE.SHORTCAST_CREATOR){
            carouselData.goTo(SLIDE_INDEXES.CHOOSE_PROFILE_TYPE);
            return;
        }


    }
    carouselData.prev();
}

},

_initializeCityField : function(){
this.$el.find(".categoryWrapper").append(this._citySelectFieldView.$el);
this.hideShowCityFieldIfApplicable();

},

afterRendering: function() {
this._initializeTrebbleCategoryField();
this._initializeTrebbleLanguageField();
this._initializeCityField();
this._initializeCarousels();
this.disableNextButton(true);

},
disableNextButton: function(forceDisable) {
this._nextButtonEnable = false;
if (!forceDisable) {
    this._nextButtonEnable = true;
    this.nextButton$el.removeClass("trebble_disabled");
} else {
    this._nextButtonEnable = false;
    if(!this.nextButton$el.hasClass("trebble_disabled")){
        this.nextButton$el.addClass("trebble_disabled");
    }
}

},

disablePreviousButton: function(forceDisable) {
this._previousButtonEnable = false;
if (!forceDisable) {
    this._previousButtonEnable = true;
    this.previousButton$el.removeClass("trebble_disabled");
} else {
    this._previousButtonEnable = false;
    if(!this.previousButton$el.hasClass("trebble_disabled")){
        this.previousButton$el.addClass("trebble_disabled");
    }
}

},

_initializeTrebbleCategoryField : function(){
const categoryListItemArray  = Utils.getInstance().getCapsuleCategoryListItemArray();
const categorySelect$el  = this.$el.find("#trebbleCategoryField");
categoryListItemArray.map(function(listItem, index){
    categorySelect$el.append("<option value='"+listItem.getId()+"'>"+ listItem.getLabel()+"</option>");
});
categorySelect$el.after('<i class="ion-chevron-down" style="display: inline-block;"></i>');
},

_initializeTrebbleLanguageField : function(){
const languageListItemArray  = Utils.getInstance().getLanguageListItemArray();
const languageSelect$el  = this.$el.find("#trebbleLanguageField");
const defaultLanguageCode =  Utils.getInstance().isBrowserLanguageAvailableInLanguageList()? navigator.language: Utils.getInstance().getDefaultLanguageCode();
languageListItemArray.map(function(listItem, index){
    if(defaultLanguageCode == listItem.getId()){
        languageSelect$el.append("<option selected=\"seleced\" value='"+listItem.getId()+"'>"+ listItem.getLabel()+"</option>");
    }else{
        languageSelect$el.append("<option value='"+listItem.getId()+"'>"+ listItem.getLabel()+"</option>");
    }
});
languageSelect$el.after('<i class="ion-chevron-down" style="display: inline-block;"></i>');
languageSelect$el.selectmenu('refresh');
},


/*_loadGAPI : function(){
return new Promise((resolve, reject) => {
    window._loadScriptipt('https://apis.google.com/js/client')
      .then(() => {
        // Check if the Google API client is available
        if (typeof gapi !== 'undefined') {
          // Assuming _onGAuthLoadedPromise is a global variable or defined elsewhere
          // You can return it if it's available
          resolve(_onGAuthLoadedPromise);
        } else {
          reject(new Error('Failed to load Google API client'));
        }
      })
      .catch(reject);
  });

},*/

onCaptchaSuccessfull : function(captchaToken){
this.trigger("captchaSuccess", captchaToken);
this.onFieldValueChanged();
},

onCaptchaExpired : function(){
this.trigger("captchaExpired");
this.onFieldValueChanged();
},

onCaptchaError : function(){

},

getCaptchaReponse : function(){
if(USE_CAPTCHA_VALIDATION){
    return (window.googleCaptchaLoaded && window.grecaptcha)? !!window.grecaptcha.getResponse(this._captchaWidgetId): null;
}else{
    return true;
}
},

_getCaptchaWidgetId : function(){
return this._captchaWidgetId;
},

_initCaptcha : function(){
if(USE_CAPTCHA_VALIDATION && window.googleCaptchaLoaded && window.grecaptcha){
    this._captchaWidgetId = window.grecaptcha.render(this.captchaContainer$el.get(0), {
        'sitekey' : CAPTCHA_SITE_KEY,
        'callback' : this.onCaptchaSuccessfull.bind(this),
        'expired-callback	' : this.onCaptchaExpired.bind(this),
              //'error-callback' : this.onCaptchaError.bind(this),
        'theme' : 'light'
    });
}
},



render : function(){
const params ={};
params.isUserpreviouslyLoggedInAsGuest = this.isUserpreviouslyLoggedInAsGuest;
params.accessCode =  this._accessCode;
params.allowUserToChooseProfileType =  this._allowUserToChooseProfileType;
params.displayWelcomeMessage = this._displayWelcomeMessage;
params.isListener = this._userProfileType === PROFILE_TYPE.LISTENER ;
params.ti18n = ti18n;
this.$el.html(this.template(params));
this.setElement(this.$el.find("div").first());

this.$el.page();
this._carouselPosition = 0;
this._trebbleNameValid = false;
this._trebbleCustomUrlValid = false;
this._trebbleDescriptionValid = false;
this._trebbleCategoryValid = false;
this._trebbleLanguageValid = true;

const functionUniqueGlobalName = "onViewportHeightChangeForSignupPage"+ (new Date()).getTime();
window[functionUniqueGlobalName] =  this._onViewportHeightChanged.bind(this)
this._onViewportHeightChangedFunctionRef = functionUniqueGlobalName;
window.addEventListener('resize', window[functionUniqueGlobalName]  );
this.onboardingSignupFormWrapper$el = this.$el.find(".onboardingSignupFormWrapper");
this.usernameField$el = this.$el.find("#usernameField");
this.headerBackButton$el  = this.$el.find("#header-back-button");
this.newPassswordField$el = this.$el.find("#passwordField");
this.reenterNewPassswordField$el = this.$el.find("#reenterPasswordField");
this.newPassswordFieldDec$el = this.$el.find("#passwordDecId");
this.reenterNewPassswordFieldDec$el = this.$el.find("#reenterPasswordDecId");
this.emailField$el = this.$el.find("#emailField");
this.firstNameField$el = this.$el.find("#firstNameField");
this.lastNameField$el = this.$el.find("#lastNameField");
this.fullNameField$el = this.$el.find("#fullNameField");
this.fullNameFielDecorator$el = this.$el.find("#fullNameFieldDec");
this.accessCodeField$el = this.$el.find("#accessCodeField");
this.trebbleLogo$el =  this.$el.find(".icon-trebble-icon-2ton");
this.signUpBtn$el = this.$el.find("#SignUpBtn");
this.usernameField$el.on(" keyup input", this.onUserNameFieldKeyUp.bind(this));
this.$el.find(".formField").on("click blur keyup input", this.onFieldValueChanged.bind(this));
this.trebbleNameField$el = this.$el.find("#trebbleNameField");
this.trebbleNameField$el.on(" keyup input", this.onTrebbleNameChanged.bind(this));
this.trebbleCustomUrlField$el = this.$el.find("#trebbleCustomURLField");
this.captchaContainer$el =  this.$el.find("#google-captcha-element");
this.captchaContainerDec$el = this.$el.find("#captchaFieldDec");

this.rssFeedUrlField$el = this.$el.find("#rssFeedUrlField");
this.trebbleCustomUrlField$el.on(" keyup input", this.onTrebbleCustomUrlChanged.bind(this));
this.rssFeedUrlField$el.on(" keyup input", this.onRssFeedURLChanged.bind(this));
this.trebbleDescriptionField$el = this.$el.find("#trebbleDescriptionField");
this.trebbleDescriptionField$el.on(" keyup input", this.onTrebbleDescriptionChanged.bind(this));
//this.$el.find("#SignUpWithFacebookBtn").hide();
if(window.waitForCordovaToLoad ){
    if(window.isIOSMobileDevice){
        this.$el.find("#SignUpWithAppleBtn").insertBefore(this.$el.find("#SignUpWithFacebookBtn"))
    }else{
        this.$el.find("#SignUpWithAppleBtn").hide();
        this.$el.find("#SignUpWithGoogleBtn").insertBefore(this.$el.find("#SignUpWithFacebookBtn"))
    }
}
this.trebbleLogo$el.css("color",this._disabledColor);
this.signUpBtn$el.removeClass("enabled").addClass("disabled");
this.nextButton$el = this.$el.find("#nextButton");
this.previousButton$el = this.$el.find("#previousButton");
this.trebbleNameInfoWrapper$el = this.$el.find(".trebbleNameInfoWrapper");
this.trebbleCustomUrlInfoWrapper$el = this.$el.find(".trebbleCustomUrlInfoWrapper");
this.trebbleDescriptionfoWrapper$el = this.$el.find(".trebbleDescriptionfoWrapper");
this.rssFeedURLInfoWrapper$el = this.$el.find(".rssFeedURLInfoWrapper");
this.trebbleCategoryField$el = this.$el.find("#trebbleCategoryField");
this.trebbleLanguageField$el = this.$el.find("#trebbleLanguageField");
this.pageHeader$el =  this.$el.find(".ui-header");
this.trebbleCategoryField$el.change(this.onTrebbleCategoryChanged.bind(this));
this.trebbleLanguageField$el.change(this.onTrebbleLanguageChanged.bind(this));
this.$el.on("pagehide", this._onPageHide.bind(this));
this.$el.on("pageshow", this._onPageShow.bind(this));
this._mousetrapObj = Mousetrap(this.$el.find("#SignUpForm").get(0)).bind('enter', (function(e, combo) {
    if(this.signUpBtn$el.hasClass("enabled")){
        this.onSignUpBtnTapped();
    }
}).bind(this));
this.$el.find("#SignUpWithGoogleBtn").hide();
/*this._loadGAPI().then(function(){
    return _onGAuthLoadedPromise;
}).then((function(){
    if(window.auth2 && window.auth2.attachClickHandler){
        window.auth2.attachClickHandler(this.$el.find("#SignUpWithGoogleBtn").get(0), {},this._onGoogleSignupuccess.bind(this), this._onGoogleSignupFailed.bind(this));
        this.$el.find("#SignUpWithGoogleBtn").show();
    }
}).bind(this));*/

GooglePlusHelper.getInstance().attachClickHandlerToElementForLoginIntent(this.$el.find("#SignUpWithGoogleBtn").get(0), {},this._onGoogleSignupuccess.bind(this), this._onGoogleSignupFailed.bind(this), USE_NEW_GOOGE_API_ON_WEB, this.$el.find(".google_button_container").get(0),{text:"signup_with"}).then((function(){
if(!USE_NEW_GOOGE_API_ON_WEB || window.isInMobileAppContext()){
this.$el.find("#SignUpWithGoogleBtn").show();
}
}).bind(this));


this._mousetrapOnTrebbleNameObj = Mousetrap(this.$el.find("#trebbleNameField").get(0)).bind('tab', (function(e, combo) {
e.preventDefault();
}).bind(this));
this._mousetrapOnTrebbleDescObj = Mousetrap(this.$el.find("#trebbleDescriptionField").get(0)).bind('tab', (function(e, combo) {
e.preventDefault();
}).bind(this));
this._mousetrapOnTrebbleDescObj = Mousetrap(this.$el.find("#trebbleCategoryField").get(0)).bind('tab', (function(e, combo) {
e.preventDefault();
}).bind(this));
this._mousetrapOnTrebbleDescObj = Mousetrap(this.$el.find("#trebbleLanguageField").get(0)).bind('tab', (function(e, combo) {
e.preventDefault();
}).bind(this));

//this.$el.find("#reenterPasswordDecId").get(0).validate = this.validateReenterPasswordField.bind(this);
//this.$el.find("#passwordDecId").get(0).validate = this.validatePasswordField.bind(this);

this._initCaptcha();

//Fill in accessCode if available and hide field
if(this._accessCode){
this.accessCodeField$el.hide();
}
this.showOrHideSignupOptions(true);
this.$el.on("pageAppendedToBody", (function(){

}).bind(this));

//this.$el.find("#emailField").focus();

return this;
},
setRemoveOnHide : function(removeOnHide){
this._removeOnHide =removeOnHide;
},

_onPageHide : function(){

if(this._removeOnHide)
{
//PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._listenerViewportKey);
this.onBeforeRemoveFromPage();
this.remove();
this.onRemovedFromPage();
}
},



});
export default SignUpPageView;