import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import StatisticsPageController from "controllers/trebbleStatistics/StatisticsPageController";
import TrebbleStatisticsContentView from "views/trebbleStatistics/TrebbleStatisticsContentView";
import CustomSelectFieldController from "controllers/common/CustomSelectFieldController";
import DynamicPageView from "views/common/DynamicPageView";
import ti18n from "i18n!nls/StatsPagei18n";
import RSVP from "rsvp";

const dataInSecondsToBeConverted = [];
const  dataTypeWithBarChart = [];
const actionTypeToUnsupportedContext = {"comments": ["alexaEnvironment","googleAssistantEnvironment","amazonFlashBriefingEnvironment" , "googleNewsFeedEnvironment","mobileEnvironment"],
"likes": ["alexaEnvironment","googleAssistantEnvironment","amazonFlashBriefingEnvironment", "googleNewsFeedEnvironment","mobileEnvironment"],
"songPickedByListeners": ["alexaEnvironment","googleAssistantEnvironment","amazonFlashBriefingEnvironment", "googleNewsFeedEnvironment","mobileEnvironment"],
"lostFollowers": ["alexaEnvironment","googleAssistantEnvironment","amazonFlashBriefingEnvironment", "googleNewsFeedEnvironment","mobileEnvironment"],
"capsulePlayed": ["amazonFlashBriefingEnvironment", "googleNewsFeedEnvironment","mobileEnvironment"],
"capsuleSkipped": ["amazonFlashBriefingEnvironment", "googleNewsFeedEnvironment","mobileEnvironment"],
"gainedFollowers": ["amazonFlashBriefingEnvironment", "googleNewsFeedEnvironment","mobileEnvironment"],
"firstTimeListeners": ["amazonFlashBriefingEnvironment", "googleNewsFeedEnvironment","mobileEnvironment"],
"songPlayed": ["amazonFlashBriefingEnvironment", "googleNewsFeedEnvironment","mobileEnvironment"],
"songSkipped": ["amazonFlashBriefingEnvironment", "googleNewsFeedEnvironment","mobileEnvironment"],
};
const contextTypeToContextLabel = {"webEnvironment": window.getI18n(ti18n, "WEB"),"alexaEnvironment": window.getI18n(ti18n, "TREBBLE_ON_ALEXA"),"googleAssistantEnvironment": window.getI18n(ti18n, "TREBBLE_ON_GOOGLE_ASSISTANT"),"amazonFlashBriefingEnvironment":window.getI18n(ti18n, "ALEXA_FLASH_BRIEFING"),"googleNewsFeedEnvironment":window.getI18n(ti18n, "GOOGLE_NEWS"),"mobileEnvironment":window.getI18n(ti18n,"MOBILE")};
const supportedActionContext = ["webEnvironment","googleAssistantEnvironment","alexaEnvironment",/*"amazonFlashBriefingEnvironment",*/ "googleNewsFeedEnvironment","mobileEnvironment"];
const dataTypes = [];

const dataTypeToLabel = {"load": window.getI18n(ti18n, "TREBBLE_PLAYS"),
//"listeningTime": "Total Listening Time",
"capsulePlayed": window.getI18n(ti18n, "CAPSULE_PLAYS"),
"capsuleSkipped": window.getI18n(ti18n, "CAPSULE_SKIPS"),
"gainedFollowers": window.getI18n(ti18n, "NEW_SUBS"),
"lostFollowers": window.getI18n(ti18n, "LOST_SUBS"),
"firstTimeListeners": window.getI18n(ti18n, "FIRST_TIME_LISTENERS"),
"songPlayed":window.getI18n(ti18n, "SONG_PLAYS"),
"songSkipped": window.getI18n(ti18n, "SONG_SKIPS"),
"songPickedByListeners": window.getI18n(ti18n, "SONG_PICKS"),
"comments":window.getI18n(ti18n, "COMMENTS")
};// First time listeners should be in the same graph as trebble plays

for(let dataType  in dataTypeToLabel){
    dataTypes.push(dataType);
}
const dataTypeToFontIcon = {"load": "ion-ios7-play-outline",
//"listeningTime": "ion-ios7-play-outline",
"capsulePlayed": "ion-ios7-play-outline",
"capsuleSkipped": "ion-ios7-skipforward-outline",
"songPlayed": "ion-ios7-play-outline",
"songSkipped": "ion-ios7-skipforward-outline",
"songPickedByListeners":"ion-ios7-plus-outline",
"comments": "ion-ios7-chatbubble-outline",
"gainedFollowers": "ion-ios7-personadd-outline",
"lostFollowers": "ion-ios7-person-outline",
"firstTimeListeners": "ion-ios7-play-outline"};
const TrebbleStatsPageController = StatisticsPageController.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = options.model;
        this._pageId = options.pageId;
        const statisticsPageControllerParams =  {};
        statisticsPageControllerParams.model = this.model;
        statisticsPageControllerParams.pageId = this._pageId;
        statisticsPageControllerParams.dataStatsId = this.model.getLoaderKey();
        statisticsPageControllerParams.supportedActionContext = supportedActionContext;
        statisticsPageControllerParams.getStatisticsLoadFunction = this._getStatistics;
        statisticsPageControllerParams.dataInSecondsToBeConverted = dataInSecondsToBeConverted;
        statisticsPageControllerParams.dataTypeWithBarChart =  dataTypeWithBarChart;
        statisticsPageControllerParams.actionTypeToUnsupportedContext = actionTypeToUnsupportedContext;
        statisticsPageControllerParams.contextTypeToContextLabel = contextTypeToContextLabel;
        statisticsPageControllerParams.dataTypes = dataTypes;
        statisticsPageControllerParams.dataTypeToLabel =  dataTypeToLabel;
        statisticsPageControllerParams.dataTypeToFontIcon =  dataTypeToFontIcon;
        statisticsPageControllerParams.entityName = window.getI18n(ti18n,"TREBBLE");
        
        const c = StatisticsPageController.prototype.initialize.call(this, statisticsPageControllerParams);

        return c;
    },



    _getStatistics : function(dataStatsId){
        return TrebbleClientAPIHelper.getInstance().getStatsForMyTrebble(dataStatsId);
    },



});

export default TrebbleStatsPageController;