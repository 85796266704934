import _ from 'underscore';
import BasicSequencerNodeInspectorController from "controllers/audioEditor/sequencerNodeInspectorSection/BasicSequencerNodeInspectorController";
import DeletedSequencerNodeInspectorView from "views/audioEditor/sequencerNodeInspectorSection/DeletedSequencerNodeInspectorView";
import RSVP from "rsvp";

const DeletedSequencerNodeInspectorController = BasicSequencerNodeInspectorController.extend({


    listenToEventsOnView: function(){

    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                const params = {};
                params.sequencerNode = this.getModel();
                this.view = new DeletedSequencerNodeInspectorView(params);
                this.view.render();
                this.listenToEventsOnView();
                resolve();

            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default DeletedSequencerNodeInspectorController;