import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import OptionPopupView from "views/common/OptionPopupView";
import PersistentModels from "services/PersistentModels";
import popupTemplate from 'text!../../../templates/common/SongSelectorPopupViewTemplate.html';

const SongSelectorPopupView = OptionPopupView.extend({

    
    initialize: function(options) {
        const viewOptions = {};
        const contentViewTemplate = _.template(popupTemplate);
        const templateParams = {};
        templateParams.popupTitle = options.title? options.title: "";
        this._noSongSelectionOptionAllowed = options.noSongSelectionOptionAllowed;
        this._noSongSelectionButtonLabel = options.noSongSelectionButtonLabel;
        templateParams.noSongSelectionOptionAllowed = this._noSongSelectionOptionAllowed;
        templateParams.noSongSelectionButtonLabel = this._noSongSelectionButtonLabel;
        const contentView = {};
        contentView.$el = $(contentViewTemplate(templateParams));
        this._songSelectorListView = options.songSelectorListView?options.songSelectorListView: null;
      
        viewOptions.contentView = contentView;
        viewOptions.addContentAfterEnhancement = true;
        viewOptions.contentPosition = "top";
        this._popupUniqueId = "SongSelectorPopupView" + (new Date()).getTime();
        viewOptions.id = this._popupUniqueId;
        OptionPopupView.prototype.initialize.call(this, viewOptions);
        this._noSongSelectionOptionAllowedSelected = false;
        PersistentModels.getInstance().getRouter().registerForViewportHeightChange(this._popupUniqueId,this.onOrientationChangeFunction.bind(this), true);
    },
    
    onOrientationChangeFunction  : function(newViewPortHeigh, forceResizing) {
        if(this.$el.is(":visible") || forceResizing){
            if (this.el) {
                const adjustedListContainerHeight = (newViewPortHeigh) ;
                this.$el.find(".ui-content").css("height", adjustedListContainerHeight + "px");
            }
        }
    },

    afterRendering: function() {
       
    },

    
    onPopupClosed: function() {
        OptionPopupView.prototype.onPopupClosed.call(this);
        PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._popupUniqueId, true);
        this.trigger("popupClosed", this._noSongSelectionOptionAllowedSelected);
    },

    onNoSongButtonClicked : function(){
        if(this._noSongSelectionOptionAllowed ){
            this._noSongSelectionOptionAllowedSelected = true;
            this.close();
        }

        
    },

    
    onAfterPageShow: function() {
    //this.$el.find("#comment").focus();
        this.onOrientationChangeFunction(PersistentModels.getInstance().getRouter().getViewportHeight(), true);
          if(this._songSelectorListView){
            this.$el.find("#widgetPlaceHolder").append(this._songSelectorListView.$el);
            this._songSelectorListView.focusOnSearchField();
        }
        this.$el.find("#noSongSelectionBtn").on("click", this.onNoSongButtonClicked.bind(this));
    },



});

export default SongSelectorPopupView;
