import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SongOptionMenuModel from "models/SongOptionMenuModel";
import AlbumsTabContentCollection from "collections/myLibrary/AlbumsTabContentCollection";
import SongOptionMenuView from "views/SongOptionMenuView";
import ti18n from "i18n!nls/Commoni18n";
import PersistentModels from "services/PersistentModels";
import TrebbleListView from "views/common/TrebbleListView";
import RSVP from "rsvp";

const AlbumListView = Backbone.View.extend({


    initialize : function(options) {
        this.displayedCollection = this.collection;
        this.collection.bind("add", this._onAlbumAddedOrRemovedInLibrary, this);
        this.collection.bind("change", this._onAlbumDataChanged, this);
        this.collection.bind("remove", this._onAlbumAddedOrRemovedInLibrary, this);
        this.collection.bind("reset", this._onAlbumAddedOrRemovedInLibrary, this);
        this._delayOnCollectionUpdate = (options && options.delayOnCollectionUpdate) ? options.delayOnCollectionUpdate :  1000;
        this._resizeToAvailableScreenSizeOnOrientationChange = (options) ? options.resizeToAvailableScreenSizeOnOrientationChange :  true;
        this._loadHandler= (options && options.loadHandler)  ? options.loadHandler :  null;
        this._heightMargin= (options && options.heightMargin)  ? options.heightMargin :  null;
        this._resizePlaceHolderElem =  options  ? options.resizePlaceHolderElem :  false;
        this._doNotSortDisplayedCollection =  options  ? options.doNotSortDisplayedCollection :  true;
        this._listenerViewportKey = "albumListView" + (new Date()).getTime();
       
        PersistentModels.getInstance().getRouter().registerForViewportHeightChange(this._listenerViewportKey,this._onOrientationChangeFunction.bind(this));
            
    },
    _onOrientationChangeFunction  : function(newViewPortHeigh, forceResizing) {
        if(this.$el.is(":visible")|| forceResizing){
            if (!this._resizePlaceHolderElem && this.el) {
                if(this._heightMargin){
                    newViewPortHeigh = newViewPortHeigh - this._heightMargin;
                }
                this.el.style.height = (newViewPortHeigh ) + "px"; 
            }
            this.infiniteListView._onRootElementResize();
        }
    },
     filterList: function(searchCharaterers) {
            
            this.appliedSearchCharacters = searchCharaterers;
            const searchFunction = (function() {
                $.mobile.loading("show");
                this.infiniteListView.setUpdating(true);
                const r = new AlbumsTabContentCollection(this.collection.searchOnAlbumTitleAndArtistname(this.appliedSearchCharacters));
                if(r instanceof RSVP.Promise){
                    r.then((function(arrayOfResults){
                        this._changeAlbumList(arrayOfResults);
                        this.pendingSearchFunctionTimerId = null;
                        $.mobile.loading("hide");
                    }).bind(this)).catch((function(error){
                        $.mobile.loading("hide");
                        window.alertErrorMessage(error);
                    }).bind(this));
                }else{
                        this._changeAlbumList(r);
                        this.pendingSearchFunctionTimerId = null;
                        $.mobile.loading("hide");
                }
          
            }).bind(this);
            if (this.pendingSearchFunctionTimerId) 
            {
                window.clearTimeout(this.pendingSearchFunctionTimerId);
            }
            this.pendingSearchFunctionTimerId = window.setTimeout(searchFunction, this._delayOnCollectionUpdate);
        
        },
        
        clearSearchfilter: function() {
            
            this.appliedSearchCharacters = null;
            const searchFunction = (function() {
                this.infiniteListView.setUpdating(true);
                this._changeAlbumList(new AlbumsTabContentCollection(this.collection.searchOnAlbumTitleAndArtistname("")));
                this.pendingSearchFunctionTimerId = null;
            }).bind(this);
            if (this.pendingSearchFunctionTimerId) 
            {
                window.clearTimeout(this.pendingSearchFunctionTimerId);
            }
            this.pendingSearchFunctionTimerId = window.setTimeout(searchFunction, 0);
        
        },
        
        _getAlbumModelFromAlbumJson : function(albumJson){
            return this.collection.get(albumJson.albumId );
        },


    _changeAlbumList: function(newDisplayedAlbumModels) {
            this.displayedCollection = newDisplayedAlbumModels;
            if(!this._doNotSortDisplayedCollection)
            {
                this.displayedCollection.sort();
            }
            this.infiniteListView.updateModels(this.displayedCollection.toJSON());
            this.infiniteListView.setUpdating(false);
    },
    
    _updateAlbumInAlbumList: function(updateAlbumModel) {
        this.infiniteListView.updateModel(updateAlbumModel.toJSON());
        this.infiniteListView.setUpdating(false);
    },

    _getOnListItemTapHandler : function(){
        return  (function(albumJson,evnt, item) {
            this.trigger("itemTaped",this._getAlbumModelFromAlbumJson(albumJson));		
        }).bind(this);
    },
    
    _getOnListItemPressHandler : function(){
        return  (function(albumJson,evnt, item) {
            this.trigger("itemPressed",this._getAlbumModelFromAlbumJson(albumJson));		
        }).bind(this);
    },
    
    _getOnListItemPressUpHandler : function(){
        return  (function(albumJson,evnt, item) {
            this.trigger("itemPressedUp",this._getAlbumModelFromAlbumJson(albumJson));		
        }).bind(this);
    },

    _onItemSwipeRight : function(){
        return  (function(albumJson,evnt, item){
    
            this.trigger("itemSwipedRight",this._getAlbumModelFromAlbumJson(albumJson));
        }).bind(this);
    },
    

    _modelTransformHandlerBeforeTemplatePiping : function(albumJson){

        let artistToDisplay = albumJson.artist;
        let albumToDisplay = albumJson.album;
        if(!artistToDisplay)
        {
            artistToDisplay = "";
        }
        if(!albumToDisplay)
        {
            albumToDisplay = "";
        }
        const templateData = {firstLineText : albumToDisplay, secondLineText : artistToDisplay, model: albumJson};
            return templateData;
    },

    _getOnListButtonTappedHandler :function(){
        return  (function(albumJson,evnt, item) {
            this.trigger("itemOptionBtnTapped",this._getAlbumModelFromAlbumJson(albumJson));		
        }).bind(this);
    },
    
    isSearchApplied : function(){
        return this.appliedSearchCharacters != null;
    },
    
    getAppliedSearchCharacters : function(){
        return this.appliedSearchCharacters;
    }, 
    
    _onAlbumAddedOrRemovedInLibrary : function(){
        if(this.isSearchApplied())
        {
            this.filterList(this.getAppliedSearchCharacters());
        }else{
            this.infiniteListView.setUpdating(true);
            this._changeAlbumList(this.collection);
        }
        this.trigger("modelChange", this.collection);
    },
    
    _onAlbumDataChanged : function(albumModel, changes){
        if(this.isSearchApplied())
        {
            this.filterList(this.getAppliedSearchCharacters());
        }else{
            this.infiniteListView.setUpdating(true);
            this._updateAlbumInAlbumList(albumModel);
        }
        this.trigger("modelChange", this.collection);
    },
    
    isCollectionEmpty : function(){
        if(this.collection)
        {
            return this.collection.length == 0;
        }else{
            return true;
        }
    },
    
    load : function(params){
        if(this._loadHandler){
            this.infiniteListView.setUpdating(true);
            return this._loadHandler(params).then((function(arrayOfSongs){
                this.collection.reset(arrayOfSongs);
                this.infiniteListView.setUpdating(false);
            }).bind(this)).catch((function(error){
                this.infiniteListView.setErrorMessage(error);
            }).bind(this));
        }else{
            return RSVP.Promise.resolve();
        }
    },
    
    _buildInfoBoxViewParams : function(){
        const params = {};
        params.iconClass = "ion-ios7-albums";
        params.message = window.getI18n(ti18n, "NO_ALBUM_WAS_FOUND");
        params.helpMessage = "";
        return params;
    },
    
    _onNodeRemovedFromDom : function(){
        this._removeFromDom  = true;
        PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._listenerViewportKey);
    },
    
    cancelPendingImageLoading : function(){
        this.infiniteListView.cancelPendingImageLoading();
    },
    
    render : function() {
    
        let newViewPortHeigh = PersistentModels.getInstance().getRouter().getViewportHeight();
        if(this._heightMargin){
            newViewPortHeigh = newViewPortHeigh - this._heightMargin;
        }
        this.$el.html(' <div id="albumListWrapper"  style="height:'+(newViewPortHeigh + "px")  +';overflow:hidden;width:100%;"></div>');
        this.setElement(this.$el.find("div").first());
         const listParams ={};
         if(!this._doNotSortDisplayedCollection)
            {
                this.displayedCollection.sort();
            }
           listParams.model = this.displayedCollection.toJSON();
             listParams.listRootElement = this.el;
             listParams.defaultNumberOfElements = 80;
             listParams.defaultHeightElement = 60;
             listParams.placeHolderHeight =  PersistentModels.getInstance().getRouter().getViewportHeight();
             listParams.resizePlaceHolderElem =  this._resizePlaceHolderElem;
             listParams.modelIdAttribute  = "albumId";
             listParams.customInfoBoxInfoParams = this._buildInfoBoxViewParams();
             listParams.doNotShowAddToRadioButton = true;
               listParams.doNotShowGradeSongButton = true;
             const infiniteListView =  new TrebbleListView(listParams);
            this.infiniteListView = infiniteListView;
            infiniteListView.setModelTransformHandlerBeforeTemplatePiping(this._modelTransformHandlerBeforeTemplatePiping);
            infiniteListView.addOnItemTapHandler(this._getOnListItemTapHandler());
            infiniteListView.addOnItemPressHandler(this._getOnListItemPressHandler());
            infiniteListView.addOnItemPressUpHandler(this._getOnListItemPressUpHandler());
            infiniteListView.addOnOptionButtonTappedHandlers(this._getOnListButtonTappedHandler());
            infiniteListView.addOnSwipeRightRevealHandler(this._onItemSwipeRight());
            infiniteListView.render();
            this._onOrientationChangeFunction(PersistentModels.getInstance().getRouter().getViewportHeight(), true);
            this.$el.on("remove",this._onNodeRemovedFromDom.bind(this));
            return this;
    }

});
export default AlbumListView;