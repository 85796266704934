	import $ from 'jquery';
    import Backbone from "backbone";
    import jwt_decode from "libs/jwtDecode/jwt-decode";
    import RSVP from "rsvp";

    const WEB_CLIENT_ID = "854293262794-3j2qnhv5imrks7m4cpdeqd26tf1lsoh1.apps.googleusercontent.com";
    const GOOGLE_LOGIN_ERROR_CODES = {
        SIGN_IN_CANCELLED : 12501, // cancelled by the user, flow exited, oauth consent denied
        SIGN_IN_FAILED : 12500,//  sign in attempt didn't succeed with the current account
        SIGN_IN_REQUIRED : 4, // Sign in is needed to access API but the user is not signed in
        INTERNAL_ERROR : 8,
        NETWORK_ERROR : 7
    }


    const GooglePlusHelper = Backbone.Model.extend({


        initialize : function() {

        },

        getErrorCodes : function(){
            return GOOGLE_LOGIN_ERROR_CODES;
        },

         
        /*
        _loadGAPI : function () {
            return new Promise((resolve, reject) => {
                window._loadScript('https://apis.google.com/js/client')
                  .then(() => {
                    // Check if the Google API client is available
                    if (typeof gapi !== 'undefined') {
                      // Assuming _onGAuthLoadedPromise is a global variable or defined elsewhere
                      // You can return it if it's available
                      resolve(_onGAuthLoadedPromise);
                    } else {
                      reject(new Error('Failed to load Google API client'));
                    }
                  })
                  .catch(reject);
              });
          },*/

        _loadGAPINew : function () {
            return new Promise((resolve, reject) => {
                window._loadScript('https://accounts.google.com/gsi/client?noext')
                  .then(resolve)
                  .catch(reject);
              });
          },

        logout : function(){
            return new RSVP.Promise(function(resolve, reject){
                if(window.waitForCordovaToLoad){
                    if(window.plugins && !window.plugins.googleplus && window.googleplus){
                        window.plugins.googleplus =	window.googleplus;
                    }
                    if(window.plugins && window.plugins.googleplus){
                        try{
                            window.plugins.googleplus.disconnect(resolve);
                        }catch(error){
                            reject(error);
                        }
                    }
                }else{
                    if(window.auth2){
                        try{
                            window.auth2.disconnect();
                            resolve();
                        }catch(error){
                            reject(error);
                        }
                    }else{
                        resolve();
                    }
                }

            });
        },

        renderGoogleButton : function(htmlContainerEl,customTheme, successFunc, failureFunc ){
            return this._loadGAPINew().then((function(){
                    const handleCredentialResponse = (function(response){
                        if(response.credential){
                            try{
                                const googleUserInfo = jwt_decode(response.credential);
                                googleUserInfo.id_token = response.credential;
                                googleUserInfo.getAuthResponse = function(){
                                    return googleUserInfo;
                                }
                                successFunc(googleUserInfo);

                            }catch(error){
                                failureFunc(error)
                            }
                        }else{
                            failureFunc(response);
                        }
                    }).bind(this);
                    window.google.accounts.id.initialize({
                        client_id: WEB_CLIENT_ID,
                        callback: handleCredentialResponse
                    });
                    if(!customTheme){
                        customTheme = {}
                    }
                    customTheme.theme = "outline";
                    customTheme.size = "large";
                    customTheme.logo_alignment = "center";
                    customTheme.shape = "square";
                    window.google.accounts.id.renderButton(htmlContainerEl, customTheme);
            }).bind(this)).catch(failureFunc);
        },
        
        attachClickHandlerToElementForLoginIntent : function(htmlEl, options, successFunc, failureFunc, useNewGoogleAPI, htmlContainerEl, customTheme){
            if(window.plugins && !window.plugins.googleplus && window.googleplus){
                window.plugins.googleplus =	window.googleplus;
            }
            if(window.plugins && window.plugins.googleplus){
                const onClickHandler =  function(){
                    if(!options){
                        options = {};
                    }
                    options.webClientId = WEB_CLIENT_ID;
                    const onLoginSuccessful = function(obj){
                        const objToReturn = {};
                        objToReturn.getAuthResponse = function(){
                            if(obj){
                                obj.id_token = obj.idToken;
                            }
                            return obj;
                        }
                        successFunc(objToReturn);
                    }
                    window.plugins.googleplus.login(options,onLoginSuccessful, failureFunc);
                }
                $(htmlEl).on("click",onClickHandler);
                return RSVP.Promise.resolve();
            }else{
                if(useNewGoogleAPI){
                    return this.renderGoogleButton(htmlContainerEl? htmlContainerEl: htmlEl.parentElement,customTheme, successFunc, failureFunc, options )
                }else{
                    /*return this._loadGAPI().then((function(){
                        return window.auth2.attachClickHandler(htmlEl, options,successFunc, failureFunc);
                    }).bind(this))*/
                }
                
            }

        },

    });



    const GooglePlusHelperInstance = new GooglePlusHelper();

    export default {
		getInstance : function() {
			return GooglePlusHelperInstance;
		}
	};