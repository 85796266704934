import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import StripeHelper from "models/helper/StripeHelper";
import Utils from "models/helper/Utils";
import ti18n from "i18n!nls/Commoni18n";
import StripeInvoiceTemplate from 'text!../../../templates/common/StripeInvoiceTemplate.html';
import RSVP from 'rsvp';
import moment from 'moment';

const TAG_VIEW_SELECTED_CLASSNAME = "selected"
const StripeInvoiceView = Backbone.View.extend({



    initialize : function(options) {
        this.compiledTemlate =  _.template(StripeInvoiceTemplate);
        this.model = options? options.model : null;
            
    },
    
    events : {
        
    },


    
    render : function() {
        const priceIdToProrationPlanLabel ={};
        const monthly_label = "monthly";
        const yearly_label = "yearly";
        
        const priceIdToPlanLabel ={};
        priceIdToPlanLabel[StripeHelper.getInstance().getCreatorProPriceId()] = "Trebble "+Utils.getInstance().getCreatorFirstPaidTierName();
        priceIdToPlanLabel[StripeHelper.getInstance().getCreatorProPlusPriceId()] = "Trebble "+Utils.getInstance().getCreatorSecondPaidTierName();
        priceIdToPlanLabel[StripeHelper.getInstance().getCreatorProYearlyPriceId()] = "Trebble "+Utils.getInstance().getCreatorFirstPaidTierName() +" x 12";
        priceIdToPlanLabel[StripeHelper.getInstance().getCreatorProPlusYearlyPriceId()] = "Trebble "+Utils.getInstance().getCreatorSecondPaidTierName() +" x 12";

        const templateParams = {"ti18n":ti18n,"invoice": this.model, "priceIdToProrationPlanLabel": priceIdToProrationPlanLabel, "priceIdToPlanLabel": priceIdToPlanLabel, "moment": moment};
        
        this.$el.html(this.compiledTemlate(templateParams));
        //this.setElement(this.$el.find("div").first());
        return this;
    }

});
export default StripeInvoiceView;