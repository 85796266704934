	import $ from 'jquery';
    import _ from 'underscore';
    import Backbone from "backbone";



    const GoogleAnalyticsEvents = Backbone.Model.extend({


        CATEGORY : {
            "INSTALL_PROGRESSIVE_WEB_APP" : {
                "name":"Install Progressive Web App",
                "actions": {
                    "ACCEPTED_PWA_INSTALL": 
                    {
                        "name": "Accepted PWA Install",
                        "label": "Accepted PWA Install"
                    },
                    "REJECTED_PWA_INSTALL": 
                    {
                        "name": "Rejected PWA Install",
                        "label": "Rejected PWA Install"
                    },
                }
            },
        }
        
    });



    const GoogleAnalyticsEventsInstance = new GoogleAnalyticsEvents();

    export default {
        getInstance : function() {
            return GoogleAnalyticsEventsInstance;
        }
    };