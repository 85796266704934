import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import OptionPopupView from "views/common/OptionPopupView";
import owlcarousel from "owlcarousel";
import PersistentModels from "services/PersistentModels";

const WhatsNewPopupView = OptionPopupView.extend({

    
    initialize: function(options) {
        const viewOptions = {};
        const contentView = {};
        const contentViewTemplate = _.template(options.template);
        const templateParams = {};
        contentView.$el = $(contentViewTemplate(templateParams));
        viewOptions.contentView = contentView;
        viewOptions.addContentAfterEnhancement = true;
        viewOptions.contentPosition = "top";
        this._popupUniqueId = "whatsnewPopup";
        viewOptions.id = this._popupUniqueId;
        viewOptions.customClass = "whatsnew";
        OptionPopupView.prototype.initialize.call(this, viewOptions);
        PersistentModels.getInstance().getRouter().registerForViewportHeightChange(this._popupUniqueId,this.onOrientationChangeFunction.bind(this), true);
    },
    
    onOrientationChangeFunction  : function(newViewPortHeigh, forceResizing) {
        if(this.$el.is(":visible") || forceResizing){
            if (this.el) {
                const adjustedListContainerHeight = (newViewPortHeigh) ;
                this.$el.find(".ui-content").css("height", adjustedListContainerHeight + "px");
            }
        }
    },

    afterRendering: function() {
        this.carousel$el = this.$el.find(".carouselWrapper").owlCarousel({
             
              navigation : false, // Show next and prev buttons
              pagination :true,
              slideSpeed : 300,
              paginationSpeed : 400,
              afterInit : function(elem){
                  const that = this
                  that.owlControls.prependTo(elem)
                },
              afterAction : this._onCarouselMove.bind(this),
              singleItem:true
            });
        this.$el.find("#closeBtn").removeClass("ion-ios7-close-empty").addClass("ion-ios7-close");
    },
    
    _onCarouselMove : function(){
        
    },

    
    onPopupClosed: function() {
        OptionPopupView.prototype.onPopupClosed.call(this);
        PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._popupUniqueId, true);
        this.trigger("onPopupClosed");
    },

    
    onAfterPageShow: function() {
    //this.$el.find("#comment").focus();
        this.onOrientationChangeFunction(PersistentModels.getInstance().getRouter().getViewportHeight(), true);
        this.carousel$el.data('owlCarousel').reload();
        const getStartedBtn$el = this.$el.find("#getStartedBtn");
        getStartedBtn$el.on("click", (function(){
            this.trigger("showGetStartedPage");
        }).bind(this));
    },



});

export default WhatsNewPopupView;
