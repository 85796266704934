import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";
import { getNumberOrUndefined } from 'chartist';

const SOUND_GROUP_TO_SOUND_CLASSES = {
    SPEECH: ["Speech"],
    SPEECH_RELATED_SOUNDS: [
        "Conversation",
        "Narration, monologue",
        "Whispering",
        "Speech synthesizer"
    ],
    BACKGROUND_NOISES: [
        "Silence",
        "Environmental noise",
        "Static",
        "Mains hum"
    ],
    HUMAN_SOUNDS: [
        "Laughter",
        "Giggle",
        "Chuckle",
        "Coughing",
        "Sneezing",
        "Throat clearing",
        "Breathing",
        "Sniffing"
    ],
    EMOTIONAL_OR_REACTIVE_SOUNDS: [
        "Crying, sobbing",
        "Whimper",
        "Yelling",
        "Screaming"
    ],
    AUDITORY_CUES_FOR_VIDEO_CONTENT: [
        "Applause",
        "Cheering",
        "Footsteps"
    ],
    DISTURBANCES_AND_UNWANTED_NOISES: [
        "Echo",
        "Reverberation",
        "Background music",
        "Music overlap"
    ],
    SPECIFIC_CONTENT_TYPES: [
        "Music",
        "Singing",
        "Musical instrument",
        "Animal",
        "Dog",
        "Bark",
        "Cat",
        "Purr",
        "Meow"
    ],
    TECHNICAL_SOUNDS: [
        "Beep",
        "Ringtone",
        "Alarm",
        "Camera shutter",
        "Typing",
        "Clicking"
    ],
    MISCELLANEOUS_SOUNDS:[
        "Tick",
        "Creak"
    ]
};

const ALLOWED_SOUND_CLASSES = Object.values(SOUND_GROUP_TO_SOUND_CLASSES).reduce((acc, arrayOfSoundClasses)=>{return acc.concat(arrayOfSoundClasses)},[]);
const SOUND_GROUPS = Object.keys(SOUND_GROUP_TO_SOUND_CLASSES).reduce((acc, sound_group_key)=>{acc[sound_group_key] = sound_group_key; return acc},{});


const SequencerNodeUtils =  Backbone.Model.extend({
    idAttribute: "id",

    constructor: function(attributes, options) {
        Backbone.Model.apply(this, [attributes, options]);

    },

    getArrayOfSequencerNodesFromStartToEnd : function(startNode, endNode){
        const arrayOfSequencerNodes = [];
        let reachEndNode = false;
        let cursorNode = startNode;
        while(!reachEndNode && cursorNode){
            arrayOfSequencerNodes.push(cursorNode);
            if(cursorNode === endNode){
                reachEndNode = true;
            }
            cursorNode = cursorNode.getNext();
        }
        return arrayOfSequencerNodes;
    },

    getPreviousWordSequencerNodeAtBeginningOfSentence : function(sequencerNode, nTimes, returnCurrentNodeIfNoNodeIsMatching){
        const previousWordSequencerAtEndOfSentence = this.getPreviousWordSequencerNodeAtEndOfSentence(sequencerNode, nTimes, returnCurrentNodeIfNoNodeIsMatching);
        if(previousWordSequencerAtEndOfSentence && previousWordSequencerAtEndOfSentence.getNext() && sequencerNode !=  previousWordSequencerAtEndOfSentence.getNext()){
            return previousWordSequencerAtEndOfSentence.getNext();
        }else{
            return previousWordSequencerAtEndOfSentence;
        }
    },

    getPreviousWordSequencerNodeAtEndOfSentence : function(sequencerNode, nTimes, returnCurrentNodeIfNoNodeIsMatching){
        const previousWordSequencer = this.getPreviousNWordSequencerNode(sequencerNode, nTimes, returnCurrentNodeIfNoNodeIsMatching);
        const isAWordNodeAtAtEndOfSentenceFunction = (function(node){
            if(!node){
                return null;
            }
            return Utils.getInstance().isWordSequencerNodeInstance(node) &&  Utils.getInstance().isValidEndOfSentence(node.getContent())
        })
        if(previousWordSequencer){
            return previousWordSequencer.getPreviousNodeMatchingCondition(isAWordNodeAtAtEndOfSentenceFunction.bind(previousWordSequencer), 0, returnCurrentNodeIfNoNodeIsMatching);
        }else{
            return null;
        }

    },

    getPreviousNWordSequencerNode : function(sequencerNode, nTimes, returnCurrentNodeIfNoNodeIsMatching){
        const isAWordNodeFunction = (function(node){
            return Utils.getInstance().isWordSequencerNodeInstance(node);
        })
        return sequencerNode.getPreviousNodeMatchingCondition(isAWordNodeFunction.bind(sequencerNode), nTimes, returnCurrentNodeIfNoNodeIsMatching);

    },

    getNextNWordSequencerNode : function(sequencerNode, nTimes, returnCurrentNodeIfNoNodeIsMatching){
        const isAWordNodeFunction = (function(node){
            return Utils.getInstance().isWordSequencerNodeInstance(node);
        })
        return sequencerNode.getNextNodeMatchingCondition(isAWordNodeFunction.bind(sequencerNode), nTimes, returnCurrentNodeIfNoNodeIsMatching);

    },

    getNextWordSequencerNodeAtEndOfSentence : function(sequencerNode, nTimes, returnCurrentNodeIfNoNodeIsMatching){
        const nextWordSequencer = this.getNextNWordSequencerNode(sequencerNode, nTimes, returnCurrentNodeIfNoNodeIsMatching);
        const isAWordNodeAtAtEndOfSentenceFunction = (function(node){
            if(!node){
                return null;
            }
            return Utils.getInstance().isWordSequencerNodeInstance(node) &&  Utils.getInstance().isValidEndOfSentence(node.getContent())
        })
        if(nextWordSequencer){
            return nextWordSequencer.getNextNodeMatchingCondition(isAWordNodeAtAtEndOfSentenceFunction.bind(nextWordSequencer), 0, returnCurrentNodeIfNoNodeIsMatching);
        }else{
            return null;
        }

    },
    
    getNumberOfClassifications: function(audioCategories){
        return audioCategories.reduce((acc,r)=>{ return acc+ r.classifications.length}, 0);
    },

    doesSequencerNodeMatchesAudioClassCondition :function(sequencerNode, audioClassConditionFunction, returnIfNoClassAvailable){
        const audioCategories = sequencerNode.getClassifiedAudioCategories();
        if(audioCategories && audioCategories.length > 0 && this.getNumberOfClassifications(audioCategories) > 0){
            return audioCategories.filter(audioClassConditionFunction).length > 0
        }else{
            if(returnIfNoClassAvailable){
                return true;
            }else{
                return false;
            }
        }
        return false;
    },

    getSoundClassesForSoundGroups : function(arrayOfSoundGroups){
        return arrayOfSoundGroups.reduce((acc, soundGroup)=>{
            const soundClasses = SOUND_GROUP_TO_SOUND_CLASSES[soundGroup];
            if(soundClasses && soundClasses.length){
                return acc.concat(soundClasses);
            }
            return acc;
        },[]);
    },

    doesSequencerNodeContainsAnyOfAudioClasses :function(sequencerNode, arrayOfAudioClasses, returnIfNoClassAvailable,minTreshold){
        const audioClassConditionFunction = (audioClassificationAtTimestamp)=>{
            if(audioClassificationAtTimestamp && audioClassificationAtTimestamp.classifications){
                for(let i =0; i < audioClassificationAtTimestamp.classifications.length; i++){
                    if(arrayOfAudioClasses.includes(audioClassificationAtTimestamp.classifications[i].categoryName) && (!minTreshold || audioClassificationAtTimestamp.classifications[i].categoryName >= minTreshold)){
                        return true;
                    }
                }
                return false;
            }else{
                return false;
            }
        }
        return this.doesSequencerNodeMatchesAudioClassCondition(sequencerNode, audioClassConditionFunction, returnIfNoClassAvailable)
    },

    doesSequencerNodeOnlyContainsOneOfAudioClasses :function(sequencerNode, arrayOfAudioClasses, returnIfNoClassAvailable, minTreshold){
        const audioClassConditionFunction = (audioClassificationAtTimestamp)=>{
            if(audioClassificationAtTimestamp && audioClassificationAtTimestamp.classifications){
                for(let i =0; i < audioClassificationAtTimestamp.classifications.length; i++){
                    if(!arrayOfAudioClasses.includes(audioClassificationAtTimestamp.classifications[i].categoryName) && (!minTreshold || audioClassificationAtTimestamp.classifications[i].categoryName >= minTreshold)){
                        return true;
                    }
                }
                return false;
            }else{
                return false;
            }
        }
        return this.doesSequencerNodeMatchesAudioClassCondition(sequencerNode, audioClassConditionFunction, returnIfNoClassAvailable)
    },

    doesSequencerNodeContainsSpeechOrOtherHumanSounds :function(sequencerNode){
        return this.doesSequencerNodeContainsAnyOfAudioClasses(sequencerNode, this.getSoundClassesForSoundGroups([SOUND_GROUPS.SPEECH, SOUND_GROUPS.SPEECH_RELATED_SOUNDS, SOUND_GROUPS.HUMAN_SOUNDS]), true)
    },

    doesSequencerNodeOnlyContainsBackgroundNoise :function(sequencerNode){
        return this.doesSequencerNodeContainsAnyOfAudioClasses(sequencerNode, this.getSoundClassesForSoundGroups([SOUND_GROUPS.BACKGROUND_NOISES]), true)
    },

    doesSequenerNodeContainsVoiceActivity : function(sequencerNode, returnTrueIfVoiceActivityInfo){
        if(!sequencerNode.getVoiceAcitivityInfo()){
            return !!returnTrueIfVoiceActivityInfo;
        }
        return sequencerNode.containsVoiceActivity();
    },


    getDefaultAllowedCategoryClassesForAudioClassifier : function(){
        return ALLOWED_SOUND_CLASSES;
    },

    getSoundGroups: function(){
        return SOUND_GROUPS;
    },
});


const sequencerNodeUtilsInstance = new SequencerNodeUtils();

export default {
	getInstance : function() {
		return sequencerNodeUtilsInstance;
	}
}; 