import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import PersistentModels from "services/PersistentModels";
import ti18n from "i18n!nls/FooterMenuBari18n";
import PlayerService from "services/PlayerService";
import PlayerFooterTemplate from 'text!../../../templates/footerMenuBar/FooterMenuBarTemplate.html';
import RSVP from "rsvp";

const ENABLE_MARQUEE = true;
const FooterMenuBarView = Backbone.View.extend({

    template : _.template(PlayerFooterTemplate),

    initialize : function(options) {
        this.canBeShownOnActivePage = (options && options.canBeShownOnActivePage) || false;
    },

    events : {
        "click #myLibraryBtn" : "showLibraryPage",
        "click #searchBtn" : "showGlobalSearch",
        "click #mySubscriptionsBtn" : "showMySubscriptionsPage",
        "click #homeBtn" : "showHomePage",
        "click #myProfileBtn" : "showLoggedInUserPage",
        "click #exploreBtn" : "showExplorePage"
    },

    deselectSelectedMenu : function(){
        this._updateSelectedMenuByMenuId(null);
    },

    _updateSelectedMenu : function(event){
        const newSelectedMenuId = event.currentTarget.id;
        this._updateSelectedMenuByMenuId(newSelectedMenuId);
    },

    _updateSelectedMenuByMenuId : function(newSelectedMenuId){
        this.$el.find("#"+this._selectedMenuId).removeClass("selected");
        if(newSelectedMenuId){
            this.$el.find("#"+newSelectedMenuId).addClass("selected");
        }
        $("body").attr("selected_home_menu",newSelectedMenuId);
        this._selectedMenuId = newSelectedMenuId;
    },



    showLibraryPage : function(ev){
        this._updateSelectedMenu(ev);
        this.trigger("showLibraryPage");
    },




    updateUserInfo : function(newUserInfo, doNotAddTimestampToUrl){
        this._userInfo  = newUserInfo;
        if(this.$el){
            const usernameString = newUserInfo?newUserInfo.getUsername(): window.getI18n(ti18n, "SIGN_IN");
            this.$el.find(".username.itemTitle").html(usernameString);
            const profileImageUrl = newUserInfo? newUserInfo.getProfileImageUrl(): null;
            //profileImageUrl =  (profileImageUrl && !doNotAddTimestampToUrl)?profileImageUrl + "?random="+ (new Date()).getTime(): profileImageUrl;
            if(profileImageUrl){
                //const resizeImageUrl = window.trebble.globalHelpers.getResizeImageUrl(profileImageUrl, USER_PROFILE_IMAGE_WIDTH, USER_PROFILE_IMAGE_HEIGHT);
                this.$el.find("#userProfileImage").css("background-image","url("+window.trebble.globalHelpers.getResizeImageUrl(profileImageUrl, 50,50 )+")");
                this.$el.find("#userProfileImage > .pe-7s-smile").hide();
            }else{
                this.$el.find("#userProfileImage").css("background-image","url(none)");
                this.$el.find("#userProfileImage > .pe-7s-smile").show();
            }
        }
    },



    showGlobalSearch : function(ev){
        //this._updateSelectedMenu(ev);
        //this.trigger("showGlobalSearch");
    },



    showExplorePage : function(ev){
        this._updateSelectedMenu(ev);
        this.trigger("showExplorePage");
    },



    showMySubscriptionsPage : function(ev){
        this._updateSelectedMenu(ev);
        this.trigger("showMySubscriptionsPage");
    },

    showHomePage : function(ev){
        this._updateSelectedMenu(ev);
        this.trigger("showHomePage");
    },

    showLoggedInUserPage : function(ev){
        //this._updateSelectedMenu(ev);
        //this.trigger("showLoggedInUserPage");
    },

    

    showSettingsPage : function(ev){
        //this._updateSelectedMenu(ev);
        //this.trigger("showSettingsPage");
    },


    onBeforeRemoveFromPage : function(){
        this.trigger("beforeRemoveFromPage", this);
    },

    onRemovedFromPage : function(){
        this.undelegateEvents();
        this.$el.removeData().unbind(); 
        this.trigger("removedFromPage", this);
    },

    

    setMyLibraryPageAsSelectedMenu : function(){
        //this._updateSelectedMenuByMenuId("myLibraryBtn");
    },





    setSearchPageAsSelectedMenu : function(){
        //this._updateSelectedMenuByMenuId("searchBtn");
    },





    setHomePageAsSelectedMenu : function(){
        this._updateSelectedMenuByMenuId("homeBtn");
    },


    setUserInfoPageAsSelectedMenu : function(){
        //this._updateSelectedMenuByMenuId("loggedInUserBtn");
    },

    setExplorePageAsSelectedMenu : function(){
        this._updateSelectedMenuByMenuId("exploreBtn");
    },

    setMySubsciptionsPageAsSelectedMenu : function(){
        this._updateSelectedMenuByMenuId("mySubscriptionsBtn");
    },


    setMyProfileAsSelectedMenu :function(){
        //this._updateSelectedMenuByMenuId("myProfileBtn");
    },

    setCanBeShownOnActivePage : function(canBeShownOnActivePage, transparent){
        this.canBeShownOnActivePage = canBeShownOnActivePage;
        let isDisplayed = null;
        if(this.canBeShownOnActivePage )
        {
            this.$el.show();
            isDisplayed = true;
            
        }else{
            this.$el.hide();
            isDisplayed = false;
        }
        $.mobile.resetActivePageHeight(canBeShownOnActivePage? window.getDocumentClienHeight() +60: null); // work around to resolve issue where with the footer transparent, the current might not be tall enough

        if(transparent){
            this.$el.addClass("transparent");
        }else{
            this.$el.removeClass("transparent");
        }
        this.trigger("onFooterMenuHiddenOrDisplayed", isDisplayed);

    },

    isFooterMenuViewDisplayed : function(){
        return this.$el.is(":visible") ;
    },

    getCanBeShownOnActivePage :  function(){
        return this.canBeShownOnActivePage;
    }, 


    render : function(){
        this.$el.html(this.template({"ti18n": ti18n}));
        this.setElement(this.$el.find("div").first());
        this.$el.hide();
        this.$el.toolbar({"updatePagePadding": false});
        $("#featurePage").css("padding","0px");	
        this._updateSelectedMenuByMenuId("homeBtn");	
        //this.updateUserInfo(this._userInfo, true);
        //this.$el.find("#homeBtn").addClass("selected");
        //this._selectedMenuId = "homeBtn";
        this.setCanBeShownOnActivePage(this.getCanBeShownOnActivePage());
        return RSVP.Promise.resolve(this);


    },

});
export default FooterMenuBarView;