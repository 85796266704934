var amdi18n={"__root":{"YOU_CANNOT_MAKE_PURCHASES":"You cannot make purchases at this stage. Try again in a moment. Make sure you didn't enable In-App-Purchases restrictions on your phone.","REMOVE_ALL_DISPLAYED_ADS":"Remove all displayed ads with Trebble Plus","YEARLY":"Yearly","MONTHLY":"Monthly","RECURRING_BILLING_CANCEL_ANYTIME":"Recurring billing. Cancel anytime.","YEAR":"year","MONTH":"month","RESTORE_PURCHASE":"Restore Purchase","TERMS_AND_CONDITIONS":"Terms & Conditions","BEST_VALUE":"Best Value"},"__fr":{"YOU_CANNOT_MAKE_PURCHASES":"Vous ne pouvez pas effectuer d'achats à ce stade. Réessayez dans un instant. Assurez-vous que vous n'avez pas activé des restrictions d'achats intégrés sur votre téléphone.","REMOVE_ALL_DISPLAYED_ADS":"Supprimer toutes les publicités affichées avec Trebble Plus","YEARLY":"Annuel","MONTHLY":"Mensuel","RECURRING_BILLING_CANCEL_ANYTIME":"Facturation récurrente. Annulez à tout moment.","YEAR":"an","MONTH":"mois","RESTORE_PURCHASE":"Restaurer l'achat","TERMS_AND_CONDITIONS":"Termes et conditions","BEST_VALUE":"Meilleur offre"},"__en":{"YOU_CANNOT_MAKE_PURCHASES":"You cannot make purchases at this stage. Try again in a moment. Make sure you didn't enable In-App-Purchases restrictions on your phone.","REMOVE_ALL_DISPLAYED_ADS":"Remove all displayed ads with Trebble Plus","YEARLY":"Yearly","MONTHLY":"Monthly","RECURRING_BILLING_CANCEL_ANYTIME":"Recurring billing. Cancel anytime.","YEAR":"year","MONTH":"month","RESTORE_PURCHASE":"Restore Purchase","TERMS_AND_CONDITIONS":"Terms & Conditions","BEST_VALUE":"Best Value"}};amdi18n.init=function(language){
		// get the default language
		if(!language){
			if(typeof window !== 'undefined' && window._i18n && window._i18n.locale){
				language = window._i18n.locale;
			}else if(typeof document !== 'undefined' && document.documentElement.lang){
				language = document.documentElement.lang;
			}else if(typeof navigator !== 'undefined' && (navigator.languages || navigator.language || navigator.userLanguage)){
				// navigator.languages does not exist in IE 11
				language = (navigator.languages && navigator.languages[0]) ||
					navigator.language ||
					navigator.userLanguage;
				language = (language || 'root').toLowerCase();
			}else{
				language = 'root';
			}
		}
		var target = this['__' + language] || this.__root;

		// copy definition to root level
		if (target) {
			for (var name in target) {
				this[name] = target[name];
			}
		}

		// fallback to root
		var recursiveFallback = function(target, source){
			for(var name in source){
				switch(typeof target[name]){
				case 'undefined':
					target[name] = source[name];
					break;
				case 'object':
					recursiveFallback(target[name], source[name]);
				}
			}
		};
		recursiveFallback(this, this.__root);
	};amdi18n.init();module.exports=amdi18n;