

import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import ChooseMusicWidgetView from "views/configureBackgroundMusic/ChooseMusicWidgetView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import CollectionRepeatController from "controllers/common/CollectionRepeatController";
import TrebbleAudioListItemController from "controllers/common/TrebbleAudioListItemController";
import PlaylistHelper from "models/helper/PlaylistHelper";
import TrebbleAudio from "models/TrebbleAudio";
import FileUploadHelper from "models/helper/FileUploadHelper";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import ti18n from "i18n!nls/ConfigureBackgroundMusici18n";
import FacebookEventTrackingHelper from "models/helper/FacebookEventTrackingHelper";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const Rollout = RolloutHelper.getInstance();
const CACHE_MUSIC_BY_CATEGORIES =  true;

const ChooseMusicWidgetController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this._categoryIdToCategoryLabelMap = {}
        this._categoryToTrebbleAudioMap = {};
        this._uploadedBackgroundMusicTrebbleAudioArray = [];
        this._selectedTrebbleAudio = null;
        this._selectedCategoryId = null;

        this._onTrebbleAudioSelected = options.onTrebbleAudioSelected;
        this._onTrebbleAudioUnselected = options.onTrebbleAudioUnselected;
        this._getTrebbleAudioModelArrayByCategoryFunction = options.getTrebbleAudioModelArrayByCategoryFunction;
        this._getCategoryIdsFunction = options.getCategoriesFunction;
        this._getCategoryMapFunction = options.getCategoriesMapFunction;
        this._heightMargin  = options.heightMargin? options.heightMargin: 0;
    },


    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },


    setViewLoading :function(isLoading){
        this.view.isLoading(isLoading);
    },

    getSelectedTrebbleAudio : function(){
        return this._selectedTrebbleAudio;
    },

    _getTrebbleAudiosToDisplay : function(){
        const trebbleAudioModelArray = [];
        if(CACHE_MUSIC_BY_CATEGORIES && this._categoryToTrebbleAudioMap[this._selectedCategoryId]){
            return RSVP.Promise.resolve(this._categoryToTrebbleAudioMap[this._selectedCategoryId]);
        }
        const categoryId = this._selectedCategoryId;
        return this._getTrebbleAudioModelArrayByCategoryFunction(categoryId).then((function(trebbleAudioModelArray){
            if(CACHE_MUSIC_BY_CATEGORIES){
                this._categoryToTrebbleAudioMap[categoryId] = trebbleAudioModelArray;
            }
            return trebbleAudioModelArray;
        }).bind(this))
    },

    _buildInfoBoxParamsForTrebbleAudioListController : function(){
        const params = {};
        params.iconClass = "pe-7s-music";
        params.message = window.getI18n(ti18n, "NO_BACKGROUND_MUSIC_IMPORTED");

        return params;
    },

    _buildTrebbleAudioListController: function() {

        const controllerParams = {};

        controllerParams.customImageUrlProperty = "data-original";
        controllerParams.notTransparent = false;
        controllerParams.canBeSelected = true;
        controllerParams.doNotShowArtistName = true;
        controllerParams.covertArtNotDisplayed = false;
        controllerParams.onTrebbleAudioSelected = this.onTrebbleAudioSelected.bind(this);
        controllerParams.onTrebbleAudioUnselected = this.onTrebbleAudioUnselected.bind(this);

        const collectionRepeatControllerParams = {};
        collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildInfoBoxParamsForTrebbleAudioListController();
        collectionRepeatControllerParams.showEmtptyInfoBox = true;
        collectionRepeatControllerParams.controllerOption = controllerParams;
        collectionRepeatControllerParams.itemControllerConstructor = TrebbleAudioListItemController;
        collectionRepeatControllerParams.modelLoadHandler = this._getTrebbleAudiosToDisplay.bind(this);
        collectionRepeatControllerParams.context = this.context;
        collectionRepeatControllerParams.resizePlaceHolderElem = true;
        collectionRepeatControllerParams.heightMargin = 52 + 39 + 50 + this._heightMargin; //Page Header + Close Button + tab section

        this.trebbleAudioListController = new CollectionRepeatController(collectionRepeatControllerParams);
    },



    onTrebbleAudioSelected : function(trebbleAudioSelected){
        if(this._selectedTrebbleAudio){
            this._selectedTrebbleAudio.setSelected(false);
        }
        if(trebbleAudioSelected){
            trebbleAudioSelected.setSelected(true);
        }
        this._selectedTrebbleAudio = trebbleAudioSelected;
        if(this._onTrebbleAudioSelected){
            this._onTrebbleAudioSelected(this._selectedTrebbleAudio)
        }
    },

    onTrebbleAudioUnselected : function(trebbleAudioUnselected){
        trebbleAudioUnselected.setSelected(false);
        this._selectedTrebbleAudio = null;
        if(this._onTrebbleAudioUnselected){
            this._onTrebbleAudioUnselected(trebbleAudioUnselected)
        }
    },

    
    retrieveMusicCategories : function(){
        if(this._getCategoryMapFunction){
            return this._getCategoryMapFunction().then((function(resultMap){
                this._categoryIdToCategoryLabelMap = resultMap;
                return this._categoryIdToCategoryLabelMap;
            }).bind(this))
        }else{
            return this._getCategoryIdsFunction().then((function(categoryIds){
                for(let i =0; i < categoryIds.length; i++){

                    const categoryId =  categoryIds[i];
                    this._categoryIdToCategoryLabelMap[categoryId] = Utils.getInstance().getBackgroundMusicCategoryLabel(categoryId);	
                }
            }).bind(this));
        }

    },

    _getDefaultCategoryIdToDisplay : function(){
        let categoryIdToDisplay = null;
        if(this._categoryIdToCategoryLabelMap){

            for(let categoryId in this._categoryIdToCategoryLabelMap){
                categoryIdToDisplay =  categoryId;
                break;
            }
        }
        return categoryIdToDisplay;
    },

    retrieveAndDisplayMusic : function(){
        this.setViewLoading(true);
        const retrieveMusicCategoriesPromise = this.retrieveMusicCategories();
        const promises = [];
        promises.push(retrieveMusicCategoriesPromise);
        return  RSVP.Promise.all(promises).then((function(){
            this.view.setCategoryIdToCategorylabelMapAvailable(this._categoryIdToCategoryLabelMap);
            this.view.showFirstCategory();
            this.view._onCategorySelectionChangedOnSelectField();
            this.setViewLoading(false);
        }).bind(this)).catch((function(error){
            this.setViewLoading(false);
            throw error;
        }).bind(this));

    },

    _onBackgroundMusicCategorySelected : function(selectedCategoryId){
        this._selectedCategoryId = selectedCategoryId;
        if(this.trebbleAudioListController){
            this.trebbleAudioListController.reload(true);
        }
    },


    buildModelAndRenderView : function() {
        this._buildTrebbleAudioListController();
        return this.trebbleAudioListController.buildModelAndRenderView().then((function(){
            const params = {};
            params.trebbleAudioListView = this.trebbleAudioListController.getView()
            this.view = new ChooseMusicWidgetView(params);
            this.listenTo(this.view, "backgroundMusicCategorySelected",this._onBackgroundMusicCategorySelected);
            this.retrieveAndDisplayMusic();
            this.view.render();
        }).bind(this))


    }

});

export default ChooseMusicWidgetController;