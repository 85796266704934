import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import Utils from "models/helper/Utils";
import Capsules from "collections/Capsules";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import CapsuleListView from "views/common/CapsuleListView";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";

const CapsuleListController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if(options)
        {
            if(options.model)
            {
                if(Utils.getInstance().is("Array",options.model))
                {
                    const capsulesJsonArray = options.model;
                    this.model = new Capsules(capsulesJsonArray);
                }else{
                        this.model = options.model;
                }
                
            }
            
            if(options.resizePlaceHolderElem)
            {
                this.resizePlaceHolderElem = options.resizePlaceHolderElem;
            }else{
                this.resizePlaceHolderElem = false;
            }
            if(options.showEmtptyInfoBox){
                this._showEmtptyInfoBox = options.showEmtptyInfoBox;
            }
            if(options.loadHandler)
            {
                this._loadHandler = options.loadHandler;
            }
            if(options.context){
                if(this.model instanceof Capsules){
                    this.model.updateContext(options.context);
                }
                this._modelContext = options.context;
            }
            if(options.customInfoBoxInfoParams){
                this._customInfoBoxInfoParams = options.customInfoBoxInfoParams;
            }
        }
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },
    
    
    updateListOfCapsules : function(arrayOfCapsulesJson){
        this.model.reset(arrayOfCapsulesJson/*, {silent: true}*/);
    },
    
    showCapsuleDetails : function(model){
        PersistentModels.getInstance().getRouter().showCapsuleByInfoEditPage(model,model.getCapsuleId(),null, true );
    },
    
    
     updateInfoBoxIfApplicable : function(iconClass,message,helpMessage,infoBoxClassName,actionButtonLabel,actionButtonIconClass){
            if(this.view){
                this.view.update(iconClass,message,helpMessage,infoBoxClassName,actionButtonLabel,actionButtonIconClass);
            }
    },
    
    
    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                if(!this.model)
                {
                    this.model = new Capsules([]);
                    if(this._modelContext){
                        this.model.updateContext(this._modelContext);
                    }
                }
                this.view = new CapsuleListView({
                    "collection" : this.model,
                    "delayOnCollectionUpdate" : 1000,
                    "resizeToAvailableScreenSizeOnOrientationChange" : false,
                    "resizePlaceHolderElem" : this.resizePlaceHolderElem,
                    "doNotShowLoadingWidgetDuringListLoading": true,
                    "loadHandler" : this._loadHandler,
                    "showEmtptyInfoBox" :this._showEmtptyInfoBox ,
                    "customInfoBoxInfoParams": this._customInfoBoxInfoParams,
                });
                this.listenTo(this.view, "itemTaped",this.showCapsuleDetails);
                //this.listenTo(this.view, "itemSwipedRight",this.addSongToQueue);
                //this.listenTo(this.view, "itemOptionBtnTapped", this.showMenuOption);
                this.view.render();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default CapsuleListController;