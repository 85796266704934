import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";
import SelectionListItemView from "views/selectionList/SelectionListItemView";

const SelectionListItemController =  Chaplin.Controller.extend({


    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = options.model;
        this._propertyFunction = options? options.propertyFunction : null;
        this._propertyName = options? options.propertyName : null;
        this._onItemSelectedHandler  = (options && options.onItemSelected)? options.onItemSelected : null;
    },
    
    getModel : function(){
        return this.model;
    },
    
    getView : function(){
        return this.view;
    },

    destroy: function(){
        try{
            this.stopListening();
            if(this.view && this.view.remove){
                this.view.remove();
            }
        }catch(error){
            console.error(error);
        }
    },
    

    onItemSelectedByUser:  function(selected){
        if(this._onItemSelectedHandler){
            this._onItemSelectedHandler(selected, this);
        }
    },
    
    setSelected : function(selected, unSelectedPreviousSelection){
        this.view.setSelection(selected, unSelectedPreviousSelection);
    },


    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
            
                const viewParams = {};
                viewParams.model  = this.model;
                viewParams.propertyFunction = this._propertyFunction;
                viewParams.propertyName = this._propertyName;
                const selectionListItemView = new  SelectionListItemView(viewParams);
                selectionListItemView.render();
                this.view = selectionListItemView;
                this.listenTo(this.view,"selected", this.onItemSelectedByUser);
                resolve(this);
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }
    

});
export default SelectionListItemController;