import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import BasicSequencerNodeInspectorController from "controllers/audioEditor/sequencerNodeInspectorSection/BasicSequencerNodeInspectorController";
import WrapWithMusicSequencerNodeInspectorView from "views/audioEditor/sequencerNodeInspectorSection/WrapWithMusicSequencerNodeInspectorView";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const WrapWithMusicSequencerNodeInspectorController = BasicSequencerNodeInspectorController.extend({


    listenToEventsOnView: function(){
        this.listenTo(this.view, "volumeChanged", (function(newVolumePercentage){
            this._sequencer.updateSequencerNodeProperty(this.getModel(), "setMainVolumePercentage", "getMainVolumePercentage", newVolumePercentage);
        }).bind(this));
        this.listenTo(this.view, "audioChanged", (function(newAudio){
            this._sequencer.updateSequencerNodeProperty(this.getModel(), "setAudioAndAudioSegmentStartTimeAndEndTime", "getAudioAndAudioSegmentStartTimeAndEndTime", newAudio);
        }).bind(this));

        this.listenTo(this.view, "loopToMatchDurationChanged", (function(loopToMatchDuration){
            this._sequencer.updateSequencerNodeProperty(this.getModel(), "setLoopToMatchWrapNodeDuration", "getLoopToMatchWrapNodeDuration", loopToMatchDuration);
        }).bind(this));

        this.listenTo(this.view, "audioSourceChanged", (function(newAudioSegmentJson){
            this._sequencer.updateSequencerNodeProperty(this.getModel(), "setAudioAndAudioSegmentStartTimeAndEndTime", "getAudioAndAudioSegmentStartTimeAndEndTime", newAudioSegmentJson);
        }).bind(this));

        this.listenTo(this.view, "duckingChanged", (function(ducking){
            this._sequencer.updateSequencerNodeProperty(this.getModel(), "setDucking", "getDucking", ducking);
        }).bind(this));

        this.listenTo(this.view, "introPaddingChanged", (function(introPaddingInSeconds){
            this._sequencer.updateSequencerNodeProperty(this.getModel(), "setIntroPaddingInMilliseconds", "getIntroPaddingInMilliseconds", introPaddingInSeconds * 1000);
        }).bind(this));

        this.listenTo(this.view, "introFadeChanged", (function(introFadeInSeconds){
            this._sequencer.updateSequencerNodeProperty(this.getModel(), "setIntroFadeInMilliseconds", "getIntroFadeInMilliseconds", introFadeInSeconds * 1000);
        }).bind(this));

        this.listenTo(this.view, "outroPaddingChanged", (function(outroPaddingInSeconds){
            this._sequencer.updateSequencerNodeProperty(this.getModel(), "setOutroPaddingInMilliseconds", "getOutroPaddingInMilliseconds", outroPaddingInSeconds * 1000);
        }).bind(this));

        this.listenTo(this.view, "outroFadeChanged", (function(outroFadeInSeconds){
            this._sequencer.updateSequencerNodeProperty(this.getModel(), "setOutroFadeInMilliseconds", "getOutroFadeInMilliseconds", outroFadeInSeconds * 1000);
        }).bind(this));
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                const params = {};
                params.sequencerNode = this.getModel();
                this.view = new WrapWithMusicSequencerNodeInspectorView(params);
                this.view.render();
                this.listenToEventsOnView();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default WrapWithMusicSequencerNodeInspectorController;