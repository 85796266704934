		import $ from 'jquery';
        import _ from 'underscore';
        import Backbone from "backbone";
        import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
        import RolloutHelper from "models/helper/FeatureRolloutHelper";
        import RSVP from "rsvp";

        
        const STRIPE_API_KEY =  RolloutHelper.getInstance().getFeatureVariable(RolloutHelper.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION, RolloutHelper.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION.variables.stripe_api_key, "pk_live_51JK76kBWZJXJWh3jbSHPv5H02RX16Ux0GW7gRtgKZimoD5u2zIwpQruEAokgfhfwxJ08DEi2tRjQQKEoA17lNWZ400SjGJolXn");
        const PRO_SUBS_PRODUCT_ID = RolloutHelper.getInstance().getFeatureVariable(RolloutHelper.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION, RolloutHelper.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION.variables.pro_subscription_product_id,"prod_LOuBoeZVg5dt8w");
        const PRO_PLUS_SUBS_PRODUCT_ID = RolloutHelper.getInstance().getFeatureVariable(RolloutHelper.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION, RolloutHelper.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION.variables.pro_plus_subscription_product_id, "prod_LOuBhZbIKiuH0H");
        const PRO_SUBS_PRICE_ID = RolloutHelper.getInstance().getFeatureVariable(RolloutHelper.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION, RolloutHelper.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION.variables.pro_subscription_price_id,"price_1Ki6MmBWZJXJWh3j2kFavG9h");
        const PRO_SUBS_YEARLY_PRICE_ID = RolloutHelper.getInstance().getFeatureVariable(RolloutHelper.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION, RolloutHelper.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION.variables.pro_subscription_price_yearly_id,"price_1Ki6MmBWZJXJWh3jlDls3ihn");
        const PRO_PLUS_SUBS_PRICE_ID = RolloutHelper.getInstance().getFeatureVariable(RolloutHelper.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION, RolloutHelper.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION.variables.pro_plus_subscription_price_id,"price_1Ki6MeBWZJXJWh3jjgk606Sh");
        const PRO_PLUS_YEARLY_SUBS_PRICE_ID = RolloutHelper.getInstance().getFeatureVariable(RolloutHelper.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION, RolloutHelper.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION.variables.pro_plus_subscription_price_yearly_id,"price_1Ki6MeBWZJXJWh3jOynBsUI2");

        const PAYMENT_INTENT_STATUS = {"SUCCEEDED": "succeeded", "PROCESSING": "processing", "REQUIRES_PAYMENT_METHOD": "requires_payment_method"}
        const PAYMENT_SETUP_INTENT_STATUS = {"SUCCEEDED": "succeeded", "PROCESSING": "processing", "REQUIRES_PAYMENT_METHOD": "requires_payment_method"}
        const defaultAppeareanceForStripeElements =  {
            theme: 'flat',
            variables: {
                borderRadius: '0px',
                colorBackground: '#ffffff',
                colorText:  "#444444",
                colorTextSecondary:"rgba(139, 139, 139,0.7)",
                fontFamily: '"Montserrat",sans-serif',
                spacingUnit: '4px',
            },
            rules: {
                '.Tab': {
                    border: '0px solid transparent'
                }
            }
        };

        const StripeHelper = Backbone.Model.extend({
            initialize : function() {


            },

            init : function(){
                this._stripeInstance =  window.Stripe(STRIPE_API_KEY);
            },

            getStripeInstance: function(){
                if(!this._stripeInstance){
                    this._stripeInstance =  window.Stripe(STRIPE_API_KEY);
                }
                return this._stripeInstance;
            },

            _createPaymentElementUsingSubscriptionInfo : function(elementContainer, subscriptionInfo){
                if(subscriptionInfo){
                    const options = {
                        clientSecret: subscriptionInfo.clientSecret,
                        appearance: defaultAppeareanceForStripeElements
                    };
                    const elements = this.getStripeInstance().elements(options);
                    const paymentElement = elements.create('payment');
                    paymentElement.mount(elementContainer);
                    paymentElement.collapse();
                    return {"stripeElements": elements,"paymentElement":paymentElement,"invoice": subscriptionInfo.latestInvoice};
                }
            },

            _createPaymentElementsForStripePaymentSetup: function(elementContainer, clientSecret){
                if(clientSecret){
                    const options = {
                        clientSecret: clientSecret,
                        appearance: defaultAppeareanceForStripeElements
                    };
                    const elements = this.getStripeInstance().elements(options);
                    const paymentElement = elements.create('payment');
                    paymentElement.mount(elementContainer);
                    paymentElement.collapse();
                    return {"stripeElements": elements,"paymentElement":paymentElement};
                }
            },

            retrieveDisplayablePaymentMethodStringUsedForCreatorSubscription :function(){
                this.retrievePaymentMethodUsedForCreatorSubscription().then(this.getDisplayableStringForPaymentMethod);
            },

            retrievePaymentMethodUsedForCreatorSubscription : function(){
                return TrebbleClientAPIHelper.getInstance().retrievePaymentMethodUsedForCreatorSubscription();
            },

            getDisplayableStringForPaymentMethod: function(paymentMethod){
                if(paymentMethod){
                    const type = paymentMethod.type;
                    const info = paymentMethod[type];
                    if(info){
                        const brandName = info["brand"] || info["bank_name"];
                        const last_four_digits  = info.last4;
                        if(last_four_digits){
                            return (brandName || type) +" •••• "+ last_four_digits;
                        }else{
                            return type;
                        }
                    }
                }

            },

            retriveUpcomingInvoiceForCreatorSubscriptionUpgradeToProPlan: function(yearlyBilling) {
                return TrebbleClientAPIHelper.getInstance().retriveUpcomingInvoiceForCreatorSubscriptionUpgradeToProPlan(yearlyBilling);
            },

            retriveUpcomingInvoiceForCreatorSubscriptionUpgradeToProPlusPlan: function(yearlyBilling) {
                return TrebbleClientAPIHelper.getInstance().retriveUpcomingInvoiceForCreatorSubscriptionUpgradeToProPlusPlan(yearlyBilling);
            },

            completePayment: function(stripeElementInstance, returnUrl){
                return new RSVP.Promise((function(resolve, reject){
                    if(stripeElementInstance){
                        return this.getStripeInstance().confirmPayment({
                            elements: stripeElementInstance,
                            confirmParams: {
                                return_url: returnUrl,
                            }
                        }).then((function(error){
                            if(error){
                                reject(error && error.error? error.error: error);
                            }else{
                                resolve();
                            }

                        }).bind(this))
                    }else{
                        reject(new Error("Payment Failed!"));
                    }
                }).bind(this))
            },

            completePaymentSetup : function(stripeElementInstance, returnUrl){
                return new RSVP.Promise((function(resolve, reject){
                    if(stripeElementInstance){
                        return this.getStripeInstance().confirmSetup({
                            elements: stripeElementInstance,
                            confirmParams: {
                                return_url: returnUrl,
                            }
                        }).then((function(error){
                            if(error){
                                reject(error && error.error? error.error: error);
                            }else{
                                resolve();
                            }

                        }).bind(this))
                    }else{
                        reject(new Error("Payment Setup Failed!"));
                    }
                }).bind(this))
            },



            getPaymentIntentStatus :function(clientSecret){
                return this.getStripeInstance().retrievePaymentIntent(clientSecret).then((function(response){
                    return response && response.paymentIntent? response.paymentIntent.status: null;
                }).bind(this))
            },

            getPaymentSetupIntentStatus :function(clientSecret){
                return this.getStripeInstance().retrieveSetupIntent(clientSecret).then((function(response){
                    return response && response.setupIntent? response.setupIntent.status: null;
                }).bind(this))
            },

            showPaymentFormForCreatorProSubscription: function(elementContainer, yearly){
                const onSubscriptionCreated = (function(subscriptionInfo){
                    return this._createPaymentElementUsingSubscriptionInfo(elementContainer, subscriptionInfo);
                }).bind(this);
                if(yearly){
                    return TrebbleClientAPIHelper.getInstance().createSubscriptionForCreatorProYearly().then(onSubscriptionCreated);
                }else{
                    return TrebbleClientAPIHelper.getInstance().createSubscriptionForCreatorPro().then(onSubscriptionCreated);
                }
            },

            showPaymentFormForStripeSetupIntent: function(elementContainer){
                return TrebbleClientAPIHelper.getInstance().getSecretToSetupPaymentIntent().then((function(clientSecret){
                    if(clientSecret){
                        return this._createPaymentElementsForStripePaymentSetup(elementContainer, clientSecret);
                    }
                }).bind(this))
            },


            showPaymentFormForCreatorProPlusSubscription: function(elementContainer, yearly){
                const onSubscriptionCreated = (function(subscriptionInfo){
                    return this._createPaymentElementUsingSubscriptionInfo(elementContainer, subscriptionInfo);
                }).bind(this);
                if(yearly){
                    return TrebbleClientAPIHelper.getInstance().createSubscriptionForCreatorProPlusYearly().then(onSubscriptionCreated);
                }else{
                    return TrebbleClientAPIHelper.getInstance().createSubscriptionForCreatorProPlus().then(onSubscriptionCreated);
                }
            },


            getPriceIdFrommSubscriptionInfo : function(subscriptionInfo){
                const priceIdLatestInvoice = this.getPriceIdFromSubscriptionInfoLatestInvoice(subscriptionInfo);
                if(!priceIdLatestInvoice){
                    return subscriptionInfo && subscriptionInfo.items &&  subscriptionInfo.items.data && subscriptionInfo.items.data.length > 0 && subscriptionInfo.items.data[0] && subscriptionInfo.items.data[0].price && subscriptionInfo.items.data[0].price.id? subscriptionInfo.items.data[0].price.id: null
                }

                return subscriptionInfo && subscriptionInfo.latest_invoice  && subscriptionInfo.latest_invoice.lines && subscriptionInfo.latest_invoice.lines.data &&  subscriptionInfo.latest_invoice.lines.data.length > 0 && subscriptionInfo.latest_invoice.lines.data[0]  &&  subscriptionInfo.latest_invoice.lines.data[0].price && subscriptionInfo.latest_invoice.lines.data[0].price.id? subscriptionInfo.latest_invoice.lines.data[0].price.id: null
            },

            getPriceIdFromSubscriptionInfoLatestInvoice : function(subscriptionInfo){
                return subscriptionInfo && subscriptionInfo.latest_invoice  && subscriptionInfo.latest_invoice.lines && subscriptionInfo.latest_invoice.lines.data &&  subscriptionInfo.latest_invoice.lines.data.length > 0 && subscriptionInfo.latest_invoice.lines.data[0]  &&  subscriptionInfo.latest_invoice.lines.data[0].price && subscriptionInfo.latest_invoice.lines.data[0].price.id? subscriptionInfo.latest_invoice.lines.data[0].price.id: null
            },

            getProductIdFrommSubscriptionInfo : function(subscriptionInfo){
                const priceIdLatestInvoice = this.getProductIdFromSubscriptionInfoLatestInvoice(subscriptionInfo);
                if(!priceIdLatestInvoice){
                    return subscriptionInfo && subscriptionInfo.items &&  subscriptionInfo.items.data && subscriptionInfo.items.data.length > 0 && subscriptionInfo.items.data[0] && subscriptionInfo.items.data[0].price && subscriptionInfo.items.data[0].price.product? subscriptionInfo.items.data[0].price.product: null
                }
                return subscriptionInfo && subscriptionInfo.latest_invoice  && subscriptionInfo.latest_invoice.lines && subscriptionInfo.latest_invoice.lines.data &&  subscriptionInfo.latest_invoice.lines.data.length > 0 && subscriptionInfo.latest_invoice.lines.data[0]  &&  subscriptionInfo.latest_invoice.lines.data[0].price && subscriptionInfo.latest_invoice.lines.data[0].price.product? subscriptionInfo.latest_invoice.lines.data[0].price.product: null
            },

            getProductIdFromSubscriptionInfoLatestInvoice : function(subscriptionInfo){
                return subscriptionInfo && subscriptionInfo.latest_invoice  && subscriptionInfo.latest_invoice.lines && subscriptionInfo.latest_invoice.lines.data &&  subscriptionInfo.latest_invoice.lines.data.length > 0 && subscriptionInfo.latest_invoice.lines.data[0]  &&  subscriptionInfo.latest_invoice.lines.data[0].price && subscriptionInfo.latest_invoice.lines.data[0].price.product? subscriptionInfo.latest_invoice.lines.data[0].price.product: null
            },

            isSubscriptionActiveForCreatorPro : function(subscriptionInfo){
                return this._iSubscriptionActiveForProductId(subscriptionInfo, this.getCreatorProProductId());
            },

            isSubscriptionActiveForCreatorProPlus : function(subscriptionInfo){
                return this._iSubscriptionActiveForProductId(subscriptionInfo, this.getCreatorProPlusProductId());
            },

            isSubscriptionActiveForCreatorProMonthly : function(subscriptionInfo){
                return this._iSubscriptionActiveForPriceId(subscriptionInfo, this.getCreatorProPriceId());
            },

            isSubscriptionActiveForCreatorProYearly : function(subscriptionInfo){
                return this._iSubscriptionActiveForPriceId(subscriptionInfo, this.getCreatorProYearlyPriceId());
            },

            isSubscriptionActiveForCreatorProPlusMonthly : function(subscriptionInfo){
                return this._iSubscriptionActiveForPriceId(subscriptionInfo, this.getCreatorProPlusPriceId());
            },

            isSubscriptionActiveForCreatorProPlusYearly : function(subscriptionInfo){
                return this._iSubscriptionActiveForPriceId(subscriptionInfo, this.getCreatorProPlusYearlyPriceId());
            },

            getSubscriptionNextBillingdDate : function(subscriptionInfo){
                return !this.isSubscriptionCanceled(subscriptionInfo)? this.getSubscriptionEndDate(subscriptionInfo): null;
            },

            getSubscriptionEndDate : function(subscriptionInfo){
                return new Date(subscriptionInfo.current_period_end * 1000);
            },

            getSubscriptionStartDate : function(subscriptionInfo){
                return new Date(subscriptionInfo.current_period_start* 1000);
            },

            isSubscriptionCanceled : function(subscriptionInfo){
                return subscriptionInfo.cancel_at_period_end;
            },

            getSubscriptionCancelEndDate : function(subscriptionInfo){
                return subscriptionInfo.cancel_at?new Date(subscriptionInfo.cancel_at* 1000): null;
            },


            _iSubscriptionActiveForPriceId :function(subscriptionInfo, priceId){
                return subscriptionInfo && (subscriptionInfo.status === "active" || subscriptionInfo.status === "trialing" || subscriptionInfo.status === "past_due") && this.getPriceIdFrommSubscriptionInfo(subscriptionInfo) === priceId;
            },

            _iSubscriptionActiveForProductId :function(subscriptionInfo, productId){
                return subscriptionInfo && (subscriptionInfo.status === "active" || subscriptionInfo.status === "trialing" ||  subscriptionInfo.status === "past_due") && this.getProductIdFrommSubscriptionInfo(subscriptionInfo) === productId;
            },

            getSubscriptionPlanType : function(subscriptionInfo){
                if(this.isSubscriptionActiveForCreatorProMonthly(subscriptionInfo)){
                    return this.getAvailableCreatorPlanTypes().PRO_PLAN_MONTHLY;
                }
                if(this.isSubscriptionActiveForCreatorProYearly(subscriptionInfo)){
                    return this.getAvailableCreatorPlanTypes().PRO_PLAN_YEARLY;
                }
                if(this.isSubscriptionActiveForCreatorProPlusMonthly(subscriptionInfo)){
                    return this.getAvailableCreatorPlanTypes().PRO_PLUS_PLAN_MONTHLY;
                }
                if(this.isSubscriptionActiveForCreatorProPlusYearly(subscriptionInfo)){
                    return this.getAvailableCreatorPlanTypes().PRO_PLUS_PLAN_YEARLY;
                }

            },

            getAvailableCreatorPlanTypes : function(){
                return {"PRO_PLAN_MONTHLY": this.getCreatorProPriceId(), "PRO_PLAN_YEARLY": this.getCreatorProYearlyPriceId(), "PRO_PLUS_PLAN_MONTHLY": this.getCreatorProPlusPriceId(), "PRO_PLUS_PLAN_YEARLY": this.getCreatorProPlusYearlyPriceId()}
            },

            getCreatorProPriceId : function(){
                return PRO_SUBS_PRICE_ID;
            },

            getCreatorProYearlyPriceId : function(){
                return PRO_SUBS_YEARLY_PRICE_ID;
            },

            getCreatorProPlusPriceId : function(){
                return PRO_PLUS_SUBS_PRICE_ID;
            },

            getCreatorProPlusYearlyPriceId : function(){
                return PRO_PLUS_YEARLY_SUBS_PRICE_ID;
            },

            getCreatorProProductId : function(){
                return PRO_SUBS_PRODUCT_ID;
            },

            getCreatorProPlusProductId : function(){
                return PRO_PLUS_SUBS_PRODUCT_ID;
            },


        });

        const stripeHelperInstance = new StripeHelper();
        stripeHelperInstance.PAYMENT_INTENT_STATUS =  PAYMENT_INTENT_STATUS;
        stripeHelperInstance.PAYMENT_SETUP_INTENT_STATUS =  PAYMENT_SETUP_INTENT_STATUS;

        export default {
			getInstance : function() {
				return stripeHelperInstance;
			}
		};