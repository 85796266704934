
import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import WrapSelectedNodesWithAudioOperation from "models/audioEditor/sequencerOperation/WrapSelectedNodesWithAudioOperation";
import StartWrapSequencerNode from "models/audioEditor/sequencerNodes/StartWrapSequencerNode";
import RSVP from "rsvp";


const DeleteWrapWithAudioNodesOperation =  WrapSelectedNodesWithAudioOperation.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        WrapSelectedNodesWithAudioOperation.apply(this, [attributes, options]);
    },

    execute : function(startOrEndMusicWrapSequencerNode){
            const startWrapMusicNode = startOrEndMusicWrapSequencerNode.getStartWrappedNode();
            const endWrapMusicNode = startOrEndMusicWrapSequencerNode.getEndWrappedNode();
            let startSequencerNodeToWrap = null;
            let endSequencerNodeToWrap = null;
            if(startOrEndMusicWrapSequencerNode instanceof StartWrapSequencerNode){
                startSequencerNodeToWrap = startOrEndMusicWrapSequencerNode;
                endSequencerNodeToWrap = startOrEndMusicWrapSequencerNode.getEndWrapSequencerNode();
            }else{
                startSequencerNodeToWrap = startOrEndMusicWrapSequencerNode.getStartWrapSequencerNode();
                endSequencerNodeToWrap = startOrEndMusicWrapSequencerNode;
            }
            this.revertWrapNodeCreation(startOrEndMusicWrapSequencerNode);
            this.getSequencer().getHistoryManagement().addDeleteWrapWithMusicLog(startWrapMusicNode, endWrapMusicNode, startSequencerNodeToWrap, endSequencerNodeToWrap);
    },


    undo : function(sequencerOperationDeleteWrapWithMusicLog ){
        return WrapSelectedNodesWithAudioOperation.prototype.redo.apply(this, [sequencerOperationDeleteWrapWithMusicLog]);
    },

    redo: function(sequencerOperationDeleteWrapWithMusicLog){
        return WrapSelectedNodesWithAudioOperation.prototype.undo.apply(this, [sequencerOperationDeleteWrapWithMusicLog]);
    },

});


export default DeleteWrapWithAudioNodesOperation; 