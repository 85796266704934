import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import AudioSegment from "models/audioEditor/segments/AudioSegment";
import RSVP from "rsvp";

const CLASSNAME = "UnsupportedAudioSegment";
const UnsupportedAudioSegment =  AudioSegment.extend({
    idAttribute: "id",

    constructor: function(attributes, options) {
        AudioSegment.apply(this, [attributes, options]);
        this.set("type", UnsupportedAudioSegment.CLASSNAME);
    },

    
    clone: function(){
        return new UnsupportedAudioSegment(this.toJSON());
    },

});

UnsupportedAudioSegment.CLASSNAME = CLASSNAME;
export default UnsupportedAudioSegment; 