
import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import AudioFilter from "models/audioEditor/filters/AudioFilter";
import RSVP from "rsvp";

const PRESETS ={};

const PRESET_IDS = {
    "DEESSER":"de-esser"
};

const createMultibandCompressorConfigPreset = function(threshold, attack, release, hold){
    return {"threshold": threshold, "attack": attack, "release": release, "hold": hold};
};

PRESETS[PRESET_IDS.DEESSER] = createMultibandCompressorConfigPreset(-30, 10, 100, 200);

const CLASSNAME = "MultibandCompressorFilter";
const MultibandCompressorFilter =  AudioFilter.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        AudioFilter.apply(this, [attributes, options]);
        this.setPresetIdToConfigMap(PRESETS);
        this.set("type", MultibandCompressorFilter.CLASSNAME);
        
        
    },

    
    setAttack : function(attack){
        this.set("attack",attack);
    },


    getAttack : function(){
        return this.get("attack");
    },

    setThreshold : function(threshold){
        this.set("threshold",threshold);
    },

    getThreshold : function(){
        return this.get("threshold");
    },

    setRelease : function(release){
        this.set("release",release);
    },

    getRelease : function(){
        return this.get("release");
    },

    setHold : function(hold){
        this.set("hold",hold);
    },

    getHold : function(){
        return this.get("hold");
    },

    isEqual : function(filter){
        if(filter instanceof MultibandCompressorFilter){
            return (this.isDisabled() == filter.isDisabled() && this.getAttack() === filter.getAttack() && this.getThreshold() === filter.getThreshold() && this.getRelease() === filter.getRelease() && this.getHold() === filter.getHold()) 
        }
        return false;
    },

    clone : function(){
        const cloneFilter = new MultibandCompressorFilter();
        cloneFilter.setDisabled(this.isDisabled());
        cloneFilter.setAttack(this.getAttack());
        cloneFilter.setThreshold(this.getThreshold());
        cloneFilter.setHold(this.getHold());
        return cloneFilter;
    },

    needToPrepareAudioContext: function(){
        return true;
    },

    prepareAudioContext: function(audioContext){
        return RSVP.Promise.resolve(audioContext);
    },

    applyFilterToNode : function(audioNode, audioContext, arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime, sequencerRendererTracksInfo, createdFilterNode ){
        if(!this.isDisabled()){
            const attack = this.getAttack();
            const threshold = this.getThreshold();
            const release = this.getRelease();
            const hold = this.getHold();

            

            // Create a compressor node
            const multibandCompressorNode = createdFilterNode;
    

            audioNode.connect(multibandCompressorNode);
            if(arrayOfAudioNodesUsed){
                arrayOfAudioNodesUsed.push(multibandCompressorNode);
            }
            return multibandCompressorNode;

        }else{
            return audioNode;
        }
    },

    _createMultibandCompressorNode : function(audioContext){
        return new RSVP.Promise(function(resolve, reject){
            require(["libs/multibandCompressor/MultibandCompressor"],function({MultibandCompressor}){
                const multibandCompressorFactory = new MultibandCompressor(audioContext,"js/libs/multibandCompressor")
                return multibandCompressorFactory.load().then(resolve).catch(reject);	
            })

        })
    },

    applyFilter : function(previousOutputNode, audioContext, arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime, sequencerRendererTracksInfo, audioBuffer){
        if(!this.isDisabled()){
            if(!previousOutputNode){
                previousOutputNode = audioContext.createBufferSource();
                previousOutputNode.buffer = audioBuffer;
                if(arrayOfAudioNodesUsed){
                    arrayOfAudioNodesUsed.push(previousOutputNode);
                }
            }
            return this._createMultibandCompressorNode(audioContext).then((function(multibandCompressorNode){
                return this.applyFilterToNode(previousOutputNode, audioContext,arrayOfAudioNodesUsed, audioBufferStartTime,audioBufferEndTime, sequencerRendererTracksInfo, multibandCompressorNode);
            }).bind(this));

            
        }else{
            return RSVP.Promise.resolve(previousOutputNode);
        }
    },


});

MultibandCompressorFilter.PRESET_IDS = PRESET_IDS;
MultibandCompressorFilter.PRESETS = PRESETS;
MultibandCompressorFilter.CLASSNAME =  CLASSNAME;

export default MultibandCompressorFilter; 