	import $ from 'jquery';
    import _ from 'underscore';
    import Backbone from "backbone";
    import LocalStorageHelper from "models/helper/LocalStorageHelper";
    import FeatureRolloutHelper from "models/helper/FeatureRolloutHelper";
    import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
    import RSVP from "rsvp";



    const TawkToHelperInstance = Backbone.Model.extend({
        initialize : function(options) {
            window.Tawk_API = window.Tawk_API || {};
            window.Tawk_API.onChatMinimized = this._onTawkToChatHide.bind(this);
            window.Tawk_API.onChatMaximized = this._onTawkToChatShow.bind(this);
            this._cachedUnreadMessagesCount = 0;
            this._isReadyPromise = this.isAvailable()? RSVP.Promise.resolve(): new RSVP.Promise(function(resolve, reject){
                window.Tawk_API.onAPIReady = resolve;
            })

        },

        _onTawkToChatShow : function(){
            this.trigger("show");
        },

        _onTawkToChatHide : function(){
            this.trigger("hide");
            this.hideMessenger();
        },

        _onUnreadMessagesChange : function(numberOfUnreadMessages){
            this._cachedUnreadMessagesCount = numberOfUnreadMessages;
            this.trigger("unreadMessagesCountChange",numberOfUnreadMessages)
        },

        isAvailable : function(){
            return window.Tawk_API &&  window.Tawk_API.onLoaded;
        },
        
        isUnreadConversationCountAvailable :function(){
            return window.Tawk_API &&  window.Tawk_API.onLoaded; this._cachedUnreadMessagesCount;
        },

        getUnreadConversationCount: function(){
            return this._isReadyPromise.then((function(){
                return this._cachedUnreadMessagesCount;
            }).bind(this))
        },

        
        registerIdentifiedUser : function(userId, userEmail, firstName, lastName, profileImageUrl, creationDate, allUserFeatures){
            return this._isReadyPromise.then((function(){
                return TrebbleClientAPIHelper.getInstance().getTawkToToken().then((function(tokenInfo){
                    if(tokenInfo && tokenInfo.token){
                        window.Tawk_API.setAttributes({
                            'userId'  : userId,
                            'firstName'  : firstName,
                            'lastName'  : lastName,
                            'name':`${firstName} ${lastName}`,
                            'email' : userEmail,
                            'profileImageUrl':profileImageUrl,
                            'creationDate':creationDate,
                            'hash'  : tokenInfo.token
                        }, function(error){console.error("Failed registering user to TawkTo. Error", error);});

                    }
                }).bind(this))
            }).bind(this))
        },

        registerForPush : function(){
            
        },

        updateUser : function(userEmail, firstName, lastName, profileImageUrl, creationDate, allUserFeatures){
            /*if("zE" in window){
                return TrebbleClientAPIHelper.getInstance().getZendeskToken().then((function(tokenInfo){
                    if(tokenInfo && tokenInfo.token){
                        zE("messenger", "loginUser", function (callback) {
                            callback(tokenInfo.token)
                        })
                    }
                }).bind(this))
            }*/
        },

        updateUserAttributes :function(userAttributeUpdates){
            /*if("zE" in window){
                return TrebbleClientAPIHelper.getInstance().getZendeskToken().then((function(tokenInfo){
                    if(tokenInfo && tokenInfo.token){
                        zE("messenger", "loginUser", function (callback) {
                            callback(tokenInfo.token)
                        })
                    }
                }).bind(this))
            }*/
        },


        trackEvent : function(eventName, additionalParams){
            return this._isReadyPromise.then((function(){
                window.Tawk_API.addEvent(eventName,additionalParams, function(error){console.error("Failed sending event to TawkTo. Error", error);});
            }).bind(this))
        },

        displayMessenger :function(){
            return this._isReadyPromise.then((function(){
                window.Tawk_API.showWidget();
                window.Tawk_API.maximize();
            }).bind(this))
        },

        displayMessageComposer: function() {
            this.displayMessenger();
        },

        displayMessageComposerWithInitialMessage: function(initialMessage) {
            this.displayMessenger();
        },

        displayMobileCarousel: function(carouselId) {
            return RSVP.Promise.resolve();
        },


        hideMessenger :function(){
            return this._isReadyPromise.then((function(){
                window.Tawk_API.minimize();
                window.Tawk_API.hideWidget();
            }).bind(this))
        },

        logout : function(){
            return this._isReadyPromise.then((function(){
                window.Tawk_API.endChat();
            }).bind(this))
            /*return this._isReadyPromise.then((function(){
                window.Tawk_API.setAttributes({
                            'userId'  : null,
                            'firstName'  : null,
                            'lastName'  : null,
                            'name':null,
                            'email' : null,
                            'profileImageUrl':null,
                            'creationDate':null,
                            'hash'  : null
                }, function(error){
                    console.error("Failed logout user on TawkTo. Error", error);
                });
            }).bind(this))*/
        }



    });

    const tawkToHelperInstance = new TawkToHelperInstance();
    window.trebbleTawkToHelper = tawkToHelperInstance;

    export default {
		getInstance : function() {
			return tawkToHelperInstance;
		}
	};