import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import AudioFilter from "models/audioEditor/filters/AudioFilter";
import RSVP from "rsvp";

const PRESETS ={};

const PRESET_IDS = {

}


const CLASSNAME = "RNNoiseFilter";

const RNNoiseFilter =  AudioFilter.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        AudioFilter.apply(this, [attributes, options]);
        this.setPresetIdToConfigMap(PRESETS);
        this.set("type", RNNoiseFilter.CLASSNAME);
    },

    isEqual : function(filter){
        if(filter instanceof RNNoiseFilter){
            return (this.isDisabled() == filter.isDisabled()); 
        }
        return false;
    },

    clone : function(){
        const cloneFilter = new RNNoiseFilter();
        cloneFilter.setDisabled(this.isDisabled());
        return cloneFilter;
    },

    
    needToPrepareAudioContext: function(){
        return false;
    },

    prepareAudioContext: function(audioContext){
        return RSVP.Promise.resolve(audioContext);
    },

    _createRNNoiseNode: function(audioContext){
        if(window.RNNoiseNode){
            return window.RNNoiseNode.register(audioContext).catch((function(error){
                console.error("Error registering Rnnoise to audiocontext. Error:"+audioContext);
            })).then(function(){
                return new window.RNNoiseNode(audioContext);
            })
        }else{
            //create a gain node if rnnoise is not available
            return RSVP.Promise.resolve(audioContext.createGain());
        }
    },

    applyFilterToNode : function(audioNode, audioContext, arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime, sequencerRendererTracksInfo, rnnoiseNode ){
        if(!this.isDisabled()){
            audioNode.connect(rnnoiseNode);
            if(arrayOfAudioNodesUsed){
                arrayOfAudioNodesUsed.push(rnnoiseNode);
            }
            return rnnoiseNode;
        }else{
            return audioNode;
        }
    },
    
    applyFilter : function(previousOutputNode, audioContext, arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime, sequencerRendererTracksInfo, audioBuffer){
        if(!this.isDisabled()){
            if(!previousOutputNode){
                previousOutputNode = audioContext.createBufferSource();
                previousOutputNode.buffer = audioBuffer;
                if(arrayOfAudioNodesUsed){
                    arrayOfAudioNodesUsed.push(previousOutputNode);
                }
            }
            return this._createRNNoiseNode(audioContext).then((function(rnnoiseNode){
                return this.applyFilterToNode(previousOutputNode, audioContext,arrayOfAudioNodesUsed, audioBufferStartTime,audioBufferEndTime, sequencerRendererTracksInfo, rnnoiseNode);
            }).bind(this));

            
        }else{
            return RSVP.Promise.resolve(previousOutputNode);
        }
    },


});

RNNoiseFilter.CLASSNAME = CLASSNAME;

export default RNNoiseFilter; 