import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import CustomSearchFieldView from "views/common/CustomSearchFieldView";
import PersistentModels from "services/PersistentModels";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import ti18n from "i18n!nls/MyLibraryi18n";
import pageTemplate from "text!../../../templates/mylibrary/myLibraryNewPageTemplate.html";
import React from "react";
import { createRoot } from "react-dom/client";
import TrebbleClientAPI from "../../models/helper/TrebbleClientAPI";
import Shepherd from 'shepherd.js';
import { offset } from '@floating-ui/dom';
import UserTranscribedFileList from "components/UserTranscribedFileList";
import UserEditHistoryList from "components/UserEditHistoryList";

const MyLibraryPageView = Backbone.View.extend({
  template: _.template(pageTemplate),

  pageName: "My Library",
  events: {
    "click #homeHeaderButton": "onHomeButtonSelected",
    "click #createNewCapsuleButton": "createCapsule",
    "click  #filterButton": "filterList",
    "click #fabAddButtonContainer.expanded #addSongFabBtn": "showGlobalSearch",
    "click #fabAddButtonContainer.expanded #addCapsuleFabBtn": "createCapsule",
    "click #createNewCapsuleFloatingBtnWrapper #addCapsuleFabBtn": "createCapsule",
    "click #whatsacapsuleButton": "showAndPlayWhatsACapsuleCapsule",
    "click #addContentFabBtn": "showOrHideAddContentButtons",
    "click #capsuleTabs": "onCapsuleTabClicked",
    "click #transribeListTabs": "onTranscribedFilesTabClicked",
    "click #editHistoryTabs": "onEditsHistoryTabClicked",
  },

  initialize: function (options) {
    this._pendingSearchQueryId = null;
    this._delayInMilliSecondOnSearchQueryExecution = 500;
    this.allMyCapsuleWidgetView = options.allMyCapsuleWidgetView;
    this._onKeyUpInSearchFieldHandler = options.onKeyUpInSearchFieldHandler;
    this._onSearchFieldClearedHandler = options.onSearchFieldClearedHandler;
    PersistentModels.getInstance()
      .getRouter()
      .registerForViewportHeightChange("myLibraryPageView", this._onViewportHeightChanged.bind(this));
  },

  _onViewportHeightChanged: function (newViewPortHeigh, forceResizing) {
    if (PersistentModels.getInstance().getRouter().isEqualToCurrentPageUrl("#mylibrary") || forceResizing) {
      if (this.el) {
        const tabHeight = 0;
        const subtabHeight = 0;
        const adjustedListContainerHeight = newViewPortHeigh - tabHeight - subtabHeight;
        if (this.$placeHolderCapsuleListEl) {
          this.$placeHolderCapsuleListEl.css("height", adjustedListContainerHeight + "px");
        }
      }
    }
  },

  onSearchValueChanged: function (searchValue) {
    this._addTranscribedFileList();
    this._addSequencerProjectList();
  },

  filterList: function () {
    this.trigger("filter");
  },

  showGlobalSearch: function () {
    this.trigger("showGlobalSearchPage");
  },

  onCapsuleTabClicked: function () {
    this.showHideCreateCapsuleFloatingButton(true);
  },

  onTranscribedFilesTabClicked: function () {
    this.showHideCreateCapsuleFloatingButton(false);
  },

  onEditsHistoryTabClicked: function () {
    this.showHideCreateCapsuleFloatingButton(false);
  },

  showAndPlayWhatsACapsuleCapsule: function () {
    this.trigger("showAndPlayWhatsACapsuleCapsule");
  },

  showHideWhatACapsuleButton: function (show) {
    if (show) {
      this.$el
        .find("#whatsacapsuleButton")
        .attr("style", "top: 170px;z-index: 9999;display: -webkit-flex; display: flex");
    } else {
      this.$el.find("#whatsacapsuleButton").hide();
    }
  },

  showApplicableHeaderButtons: function () {
    /*if(this.customSearchFieldView){
            this.customSearchFieldView.clearSearchField()
            this.customSearchFieldView.$el.hide();
        }*/
    this.sortFilterBtn$el.hide();
  },

  onHomeButtonSelected: function () {
    this.trigger("showHomePopup");
  },

  showOrHideAddContentButtons: function () {
    this.fabAddButtonContainer$el.toggleClass("expanded");
  },

  hideAddContentButtons: function () {
    this.fabAddButtonContainer$el.removeClass("expanded");
  },

  showAddContentButtons: function () {
    if (!this.fabAddButtonContainer$el.hasClass("expanded")) {
      this.fabAddButtonContainer$el.addClass("expanded");
    }
  },

  createCapsule: function () {
    this.trigger("createCapsule");
  },

  _buildSearchHeaderButtonAndToPage: function () {
    const customSearchFieldViewParams = {};
    customSearchFieldViewParams.searchFieldPlaceholderSelector = "#pageHeaderTitleWrapper";
    customSearchFieldViewParams.searchFieldPlaceholderSelectorRootNodeEl = this.el;
    this.customSearchFieldView = new CustomSearchFieldView(customSearchFieldViewParams);
    this.customSearchFieldView.render();
    this.customSearchFieldView.addKeyUpHandlerFunction(this._onKeyUpInSearchField.bind(this));
    this.customSearchFieldView.addOnSearchFieldClearedFunction(this._onSearchFieldCleared.bind(this));
    this.customSearchFieldView.addOnScreenResizeHandlerFunction(this._resizeSearchField.bind(this));
    this.$el.find(".rightHeaderButtonGroup").prepend(this.customSearchFieldView.el);
  },

  getSearchFieldValue: function () {
    return this.customSearchFieldView.getSearchFieldValue();
  },

  _resizeSearchField: function (searchField) {
    const homeButtonWidth = 51;
    const searchButtonWidth = 52;
    const paddingLeft = 14;
    const newSearchFieldWidth =
      window.getDocumentClienWidth() - homeButtonWidth - searchButtonWidth - paddingLeft + "px";
    searchField.width(newSearchFieldWidth);
  },

  _onKeyUpInSearchField: function (searchFieldValue) {
    if (this._pendingSearchQueryId) {
      clearTimeout(this._pendingSearchQueryId);
    }
    //wait a certain amount of time before sending search event
    this._pendingSearchQueryId = setTimeout(
      function () {
        if (this._onKeyUpInSearchFieldHandler) {
          this._onKeyUpInSearchFieldHandler(searchFieldValue);
        }
      }.bind(this),
      this._delayInMilliSecondOnSearchQueryExecution,
    );
  },

  _onSearchFieldCleared: function () {
    if (this._onSearchFieldClearedHandler) {
      this._onSearchFieldClearedHandler();
    }
  },

  onPageBeforeShow: function () {
    if (window.trebbleAnalyticsHelper) {
      window.trebbleAnalyticsHelper.trackEvent("pageShow", "MyLibraryPageShow", "My Library Page Show");
    }
    this.showApplicableHeaderButtons();
    this.trigger("pageBeforeShow");
    this.$el.find("#capsuleTabs").click();
    this._addTranscribedFileList();
    this._addSequencerProjectList();
  },

  _fetchTranscribedFiles: function (pageIndex, pageSize) {
    return TrebbleClientAPI.getInstance().getTranscribedFiles(this.getSearchFieldValue(), pageIndex, pageSize);
  },

  _fetchSequencerProjects: function (pageIndex, pageSize) {
    return TrebbleClientAPI.getInstance().getSequencerProjects(this.getSearchFieldValue(), pageIndex, pageSize, false);
  },

  _addTranscribedFileList: function () {
    const root =  this._transcribedFileListWrapperReactRoot?  this._transcribedFileListWrapperReactRoot:  createRoot(this.$el.find("#transcribedFileListWrapper").get(0));
    root.render(<UserTranscribedFileList containerHeightMargin={145} pageSize={30} searchFieldValue={this.getSearchFieldValue()}/>);
    this._transcribedFileListWrapperReactRoot = root;
  },

  _addSequencerProjectList: function () {
    const root = this._editHistoryListWrapperReactRoot? this._editHistoryListWrapperReactRoot: createRoot(this.$el.find("#editHistoryListWrapper").get(0));
    root.render(<UserEditHistoryList containerHeightMargin={145} pageSize={30} searchFieldValue={this.getSearchFieldValue()}/>);
    this._editHistoryListWrapperReactRoot = root;
  },

  hideHeaderBarShadow: function () {
    this.$el.find("[data-role=header]").addClass("hideShadow");
  },

  onPageBeforeHide: function () {},

  setSearchValue: function (newSearchValue) {
    this.customSearchFieldView.setSearchFieldValue(newSearchValue, true);
  },

  showHideCreateCapsuleFloatingButton: function (show) {
    if (show) {
      this.$el.find("#createNewCapsuleFloatingBtnWrapper").removeClass("hide");
    } else {
      this.$el.find("#createNewCapsuleFloatingBtnWrapper").addClass("hide");
    }
  },

  getOnboardingCurrentStepIdDisplayed: function () {
    return this._onboardingTour && this._onboardingTour.getCurrentStep()
      ? this._onboardingTour.getCurrentStep().id
      : null;
  },

  _createTourStepCreateContentButton : function(tour){
    return {
      title: window.getI18n(ti18n, "ONBOARD_STEP_3_TITLE_CREATE_BUTTON"),
      text:  window.getI18n(ti18n, "ONBOARD_STEP_3_DESC_CREATE_BUTTON"),
      attachTo: {
          element: this.$el.find("#addCapsuleFabBtn").get(0),
          on: 'left'
      },
      arrow: true,
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 20, crossAxis: 0 })]
      },
      modalOverlayOpeningPadding: 10,
      modalOverlayOpeningRadius:45,
      scrollTo: false,
      buttons: [
      
      {
          action: (function(){
              if(this.$el.is(":visible")){
                  return tour.next();
              }else{
                if (window.trebbleAnalyticsHelper) {
                  window.trebbleAnalyticsHelper.trackEvent(
                    "myLibraryPage",
                    "myLibraryPageNotVisibleToContinueOnboarding",
                    "My Library page not visible to continue onboarding",
                  );
                }
              }
          }).bind(this),
          text: window.getI18n(ti18n, "GOT_IT")
      }
      ],
      id: 'onboarding_automated_actions_step'
      };
  },

  _createTourStepForMyLibraryTabs: function(tour){
    return {
      title: window.getI18n(ti18n, "ONBOARD_STEP_2_TITLE_MY_LIBRARY_PAGE_TABS"),
      text: window.getI18n(ti18n, "ONBOARD_STEP_2_DESC_MY_LIBRARY_PAGE_TABS"),
      attachTo: {
        element: this.$el.find("#tabs ul.ui-grid-b").get(0),
        on: "bottom",
      },
      arrow: true,
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 20, crossAxis: 0 })]
      },
      modalOverlayOpeningPadding: 0,
      buttons: [
        {
          action() {
            return tour.cancel();
          },
          classes: "shepherd-button-secondary",
          text: window.getI18n(ti18n, "SKIP_TOUR"),
        },
        {
          action: function () { 
                if (this.$el.is(":visible")) {
                  tour.addStep(this._createTourStepCreateContentButton(tour));
                  return tour.next();
                } else {
                  if (window.trebbleAnalyticsHelper) {
                    window.trebbleAnalyticsHelper.trackEvent(
                      "myLibraryPage",
                      "myLibraryPageNotVisibleToContinueOnboarding",
                      "My Library page not visible to continue onboarding",
                    );
                  }
                }
            
          }.bind(this),
          text: window.getI18n(ti18n, "NEXT"),
        },
      ],
      id: "onboarding_my_library_page_tabs_step",
    };
  },

  _createTourStepForWelcomeToMyLibraryPage: function (tour) {
    return {
      title: window.getI18n(ti18n, "ONBOARD_STEP_1_TITLE_MY_LIBRARY_PAGE"),
      text: window.getI18n(ti18n, "ONBOARD_STEP_1_DESC_MY_LIBRARY_PAGE"),
      attachTo: {
        on: "auto",
      },
      arrow: true,
      modalOverlayOpeningPadding: 0,
      buttons: [
        {
          action() {
            return tour.cancel();
          },
          classes: "shepherd-button-secondary",
          text: window.getI18n(ti18n, "SKIP_TOUR"),
        },
        {
          action: function () {
                if (this.$el.is(":visible")) {
                  tour.addStep(this._createTourStepForMyLibraryTabs(tour));
                  return tour.next();
                } else {
                  if (window.trebbleAnalyticsHelper) {
                    window.trebbleAnalyticsHelper.trackEvent(
                      "myLibraryPage",
                      "myLibraryPageNotVisibleToContinueOnboarding",
                      "My Library page not visible to continue onboarding",
                    );
                  }
                }
           
          }.bind(this),
          text: window.getI18n(ti18n, "NEXT"),
        },
      ],
      id: "onboarding_welcome_my_library_page_step",
    };
  },

  _onPageHide: function(){
    if(this._onboardingTour){
      this._onboardingTour.cancel();
    }
    this.trigger("pagehide");
  },

  _onPageShow: function(){
    this.trigger("pageshow");
  },

  showOnboardingInstruction: function () {
    if (!this._onboardingTour && this.$el.is(":visible")) {
      try {
        const tour = new Shepherd.Tour({
          useModalOverlay: true,
          keyboardNavigation: false,
          defaultStepOptions: {
            cancelIcon: {
              enabled: false,
            },

            classes: "sequencer-onboarding-popup",
            scrollTo: { behavior: "smooth", block: "center" },
          },
        });
        //this._selectFourLinesOfTextIfContextMenuIsNotDisplayed();
        tour.addStep(this._createTourStepForWelcomeToMyLibraryPage(tour));

        tour.start();
        this._onboardingTour = tour;
        tour.once(
          "start",
          function () {
            this.trigger("myLibraryPageOnboardingTourStarted");
            if (window.trebbleAnalyticsHelper) {
              window.trebbleAnalyticsHelper.trackEvent(
                "myLibraryPage",
                "myLibraryPageOnboardingStarted",
                "My Library Page Onboarding Started ",
              );
            }
          }.bind(this),
        );
        tour.once(
          "complete",
          function () {
            this.trigger("myLibraryPageOnboardingTourCompleted");
            this._onboardingTour = null;
            if (window.trebbleAnalyticsHelper) {
              window.trebbleAnalyticsHelper.trackEvent(
                "myLibraryPage",
                "myLibraryPageOnboardingCompleted",
                "My Library Page Onboarding Completed ",
              );
            }
          }.bind(this),
        );
        tour.once(
          "cancel",
          function () {
            this.trigger("myLibraryPageOnboardingTourCancelled");
            this._onboardingTour = null;
            if (window.trebbleAnalyticsHelper) {
              window.trebbleAnalyticsHelper.trackEvent(
                "myLibraryPage",
                "myLibraryPageOnboardingCancelled",
                "My Library Page Onboarding Cancelled ",
              );
            }
          }.bind(this),
        );

        tour.on(
          "show",
          function () {
            this.trigger("myLibraryPageOnOnboardingStepDisplayed");
            const stepDisplayedId = this.getOnboardingCurrentStepIdDisplayed();
            if (window.trebbleAnalyticsHelper) {
              window.trebbleAnalyticsHelper.trackEvent(
                "myLibraryPage",
                "myLibraryPageOnboardingStepDisplayed",
                "My Library Page Onboarding Step Displayed ",
                null,
                { stepDisplayedId: stepDisplayedId },
              );
            }
          }.bind(this),
        );
      } catch (error) {
        console.error(error);
        if (window.trebbleAnalyticsHelper) {
          window.trebbleAnalyticsHelper.trackEvent(
            "myLibraryPage",
            "FailedToCreateMyLibraryPageOnboarding",
            "Failed to create My Library page onboarding ",
            null,
            { error: error },
          );
        }
      }
    } else {
      if (!this._onboardingTour && window.trebbleAnalyticsHelper) {
        window.trebbleAnalyticsHelper.trackEvent(
          "myLibraryPage",
          "myLibraryPageNotVisibleToContinueOnboarding",
          "My Library page not visible to continue onboarding",
        );
      }
    }
  },

  onPageRemoved : function(){
    this.undelegateEvents();
    this.$el.removeData().unbind(); 
    this.stopListening();
    if(this._transcribedFileListWrapperReactRoot){
        this._transcribedFileListWrapperReactRoot.unmount();
        this._transcribedFileListWrapperReactRoot = null;
    }
    if(this._editHistoryListWrapperReactRoot){
        this._editHistoryListWrapperReactRoot.unmount();
        this._editHistoryListWrapperReactRoot = null;
    }
  },

  render: function () {
    this.$el.html(this.template({ ti18n: ti18n }));
    this.setElement(this.$el.find("div").first());

    this._buildSearchHeaderButtonAndToPage();

    this.sortFilterBtn$el = this.$el.find("#filterButton");
    this.createCapsuleBtn$el = this.$el.find("#createNewCapsuleButton");
    this.fabAddButtonContainer$el = this.$el.find("#fabAddButtonContainer");
    if (!window.isMobileBrowser) {
      this.fabAddButtonContainer$el.hover(function () {}, this.hideAddContentButtons.bind(this));
      this.$el.find("#addContentFabBtn").hover(this.showAddContentButtons.bind(this), function () {});
    }
    this.$placeHolderCapsuleListEl = this.$el.find("#capsuleListWrapper").first();
    this.$placeHolderCapsuleListEl.append(this.allMyCapsuleWidgetView.el);
    this.$el.page();

    //manually adding the wrapper necessary by JQM for showing Panel
    //JQM does it on the fly but that can cause flickering on the screen when it is done by jquery mobile
    //the workaround is to do that manually when the page is getting initialized
    this.$el
      .children(".ui-header:not(.ui-header-fixed), .ui-content:not(.ui-popup), .ui-footer:not(.ui-footer-fixed)")
      .wrapAll("<div class='ui-panel-wrapper'></div>");

    this.$el.on("pageshow", this.onPageBeforeShow.bind(this));
    this.$el.on("pagebeforehide", this.onPageBeforeHide.bind(this));
    this.$el.on("remove", this.onPageRemoved.bind(this));
    if (LocalStorageHelper.getInstance().isUserHasCapsuleSchedulingFeature()) {
      this.$el.addClass("showScheduleCapsules");
    }
    this.$el.on("pageshow",this._onPageShow.bind(this));
    this.$el.on("pagehide",this._onPageHide.bind(this));
    this.showApplicableHeaderButtons();
    this._onViewportHeightChanged(PersistentModels.getInstance().getRouter().getViewportHeight(), true);

    return this;
  },
});

export default MyLibraryPageView;
