import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import ShareTrebblePopupView from "views/shareTrebble/ShareTrebblePopupView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import ti18n from "i18n!nls/shareTrebblePopupi18n";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";
import FacebookHelper from "models/helper/FacebookHelper";

const ShareSimpleSongSetPopupController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.model = options.song;
        this._trebbleSongId = this.model.getTrebbleSongId();
        this.context = options.context;
        this._songShareableLink = null;
        this._songWidgetLandscapeLink = null;
        this._trebbleWidgetPortraitLink = null;
        this._initializeLinks(this._trebbleSongId);
        _.extend(this, Backbone.Events);
    },

    _initializeLinks : function(trebbleSongId){
        this._songShareableLink = "https://s.trebble.fm/p/p/s/"+trebbleSongId;
        this._songWidgetLandscapeLink = '<iframe src="https://web.trebble.fm/trebble_embedded_optimized.html#p/l/s/'+trebbleSongId+'" height="150" frameBorder="0" allowtransparency="true" allowfullscreen style="box-shadow:rgba(0, 0, 0, 0.6) 0px 0px 50px"></iframe>';
        this._songWidgetPortraitLink = '<iframe src="https://web.trebble.fm/trebble_embedded_optimized.html#p/l/s/'+trebbleSongId+'" width="300" height="400" frameBorder="0" allowtransparency="true" allowfullscreen style="box-shadow:rgba(0, 0, 0, 0.6) 0px 0px 50px"></iframe>';
    },


    _copyToClipboard : function(toCopy, confirmMessage){
        if('cordova' in window && window.cordova && window.cordova.plugins && window.cordova.plugins.clipboard ){
            window.cordova.plugins.clipboard.copy(toCopy);
            Utils.getInstance().showShortToastMessage(confirmMessage);
        }else{
            const isCopySuccessfull = Utils.getInstance().copyTextToClipboard(toCopy);
            if (!window.waitForCordovaToLoad && !isCopySuccessfull) {
                navigator.trebbleNotification.alert(window.getI18n(ti18n, "PLEASE_PRESS_CNTR_C_TO_COPY"));
            }else{
                Utils.getInstance().showShortToastMessage(confirmMessage);
            }
        }
    },

    _shareNatively : function(toShare,url, title){
        if (navigator.share) {
            navigator.share({
                'title': title,
                text: toShare,
                url: url
            });
        }
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    copyShareableLinkToClipboard : function(toCopy){
        this._copyToClipboard(toCopy, window.getI18n(ti18n,"SHAREABLE_LINK_COPIED_TO_CLIPBOARD"));
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Song", "SongShareLinkCopied", "Song Share Link Copied");
        } 
        
    },

    copyWidgetLandscapeLinkToClipboard : function(toCopy){
        this._copyToClipboard(toCopy, window.getI18n(ti18n,"EMBED_CODE_COPIED_TO_CLIPBOARD"));
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Song", "SongEmbedCodeCopied", "Song Embed Code Landscape Copied");
        } 
    },


    copyWidgetPortraitLinkToClipboard : function(toCopy){
        this._copyToClipboard(toCopy, window.getI18n(ti18n,"EMBED_CODE_COPIED_TO_CLIPBOARD"));
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Song", "SongEmbedCodeCopied", "Song Embed Portrait Code Copied");
        } 
    },


    shareNativelyShareableLinkToClipboard : function(toShare){
        this._shareNatively(null, toShare, window.getI18n(ti18n, "SONG_URL"));
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Song", "SongShareLinkSharedNatively", "Song Share Link Shared Natively");
        }
        
    },

    shareNativelyWidgetLandscapeLinkToClipboard : function(toShare){
        this._shareNatively(toShare, null, window.getI18n(ti18n, "SONG_EMBEDDED_CODE_LANDSCAPE"));
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Song", "SongEmbedCodeSharedNatively", "Song Embed Code Landscape Shared Natively");
        }
    },


    shareNativelyWidgetPortraitLinkToClipboard : function(toShare){
        this._shareNatively(toShare, null, window.getI18n(ti18n, "SONG_EMBEDDED_CODE_PORTRAIT"));
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Song", "SongEmbedCodeSharedNatively", "Song Embed Portrait Code Shared Natively");
        } 
    },

    shareOnFacebook : function(toShare){
        FacebookHelper.getInstance().showDialog({
            method: 'share',
            href: toShare,
        },{"entity":"Song", "eventName":"SongShareFacebook", "eventLabel":"Song URL Shared On Facebook"} ).catch(function(error){
            window.alertErrorMessage(error);
        });
        /*FB.ui({
            method: 'share',
            href: toShare,
        }, function(response){
            if(response && response.error_message){
                window.alertErrorMessage(response.error_message);
            }else{
                if(window.trebbleAnalyticsHelper){
                    window.trebbleAnalyticsHelper.trackEvent("Song", "SongShareFacebook", "Song URL Shared On Facebook");
                } 
            }
        });*/
},

shareOnTwitter : function(toShare){
const w = 550;
const h = 285;
const left = window.getLeftPositionForPopupWindow(w);
const top = window.getTopPositionForPopupWindow(h);
const openedWindow = window.open("https://twitter.com/intent/tweet?via=trebblefm&url="+encodeURIComponent(toShare),"Twitter", "resizable=1,height="+h+",width="+w+",top="+top+",left="+left+"");
if((!openedWindow || openedWindow.closed || typeof openedWindow.closed=='undefined') && !window.waitForCordovaToLoad){
window.alertErrorMessage(window.getI18n(ti18n, "PLEASE_ALLOW_POPUPS"));
}
if(window.trebbleAnalyticsHelper){
window.trebbleAnalyticsHelper.trackEvent("Song", "SongShareTwitter", "Song URL Shared On Twitter");
}
},

previewLink: function(linkToPreview){
const openedWindow =  window.open(linkToPreview , "_blank");
if((!openedWindow || openedWindow.closed || typeof openedWindow.closed=='undefined') && !window.waitForCordovaToLoad){
window.alertErrorMessage(window.getI18n(ti18n, "PLEASE_ALLOW_POPUPS"));
}
return openedWindow;
},


buildModelAndRenderView : function() {
return new RSVP.Promise((function(resolve, reject) {
try {

    const params = {};
    params.trebbleId = this._trebbleSongId;
    params.trebbleShareableLink = this._songShareableLink;
    params.trebbleWidgetLandscapeLink = this._songWidgetLandscapeLink;
    params.trebbleWidgetPortraitLink = this._songWidgetPortraitLink;
    params.canCopyEmbeddedWidgetLink = true;
    params.shareableLinkHasAShortcast = false;
    params.shareOnSmartSpeakers = false;
    params.shareSubscribePage = false;
    params.canEmbedLandscapePlayer = false;
    params.canEmbedPortraitPlayer = false;
    this.view = new ShareTrebblePopupView(params);
    this.listenTo(this.view, "preview",this.previewLink);
    this.listenTo(this.view, "copyLinkToClipboard",this.copyShareableLinkToClipboard);
    this.listenTo(this.view, "copyWidgetLandscapeToClipboard",this.copyWidgetLandscapeLinkToClipboard);
    this.listenTo(this.view, "copyWidgetPortraitToClipboard",this.copyWidgetPortraitLinkToClipboard);
    this.listenTo(this.view, "shareNativelyLinkToClipboard",this.shareNativelyShareableLinkToClipboard);
    this.listenTo(this.view, "shareNativelyWidgetLandscapeToClipboard",this.shareNativelyWidgetLandscapeLinkToClipboard);
    this.listenTo(this.view, "shareNativelyWidgetPortraitToClipboard",this.shareNativelyWidgetLandscapeLinkToClipboard)
    this.listenTo(this.view, "shareOnFacebookButtonClicked",this.shareOnFacebook);
    this.listenTo(this.view, "shareOnTwitterButtonClicked",this.shareOnTwitter);

    this.view.render();
    resolve();


} catch (error) {
    reject(error);
}

}).bind(this));

}

});

export default ShareSimpleSongSetPopupController;