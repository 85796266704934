import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import owlCarousel from "owlcarousel";
import DynamicPageView from "views/common/DynamicPageView";
import Utils from "models/helper/Utils";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import ti18n from "i18n!nls/OnboardingListenerPagei18n";
import PersistentModels from "services/PersistentModels";
import OnboardingPageTemplate from 'text!../../../templates/onboarding/OnboardingListenerPageTemplate.html';

const DISPLAY_AUTO_PREVIEW_TOOLTIP = false;
const DEFAULT_BACKGROUND_IMAGE_URL = window.getResourceURLAccordingToLocationProtocol("/img/blackWhitebackgroundplayer.jpg");
const Rollout = RolloutHelper.getInstance();

const CSS_CLASS_TO_SHOW_WELCOME_MESSAGE = "showWelcomeMessage";
const CSS_CLASS_TO_SHOW_NO_CONTENT = "showNoContent"



const LISTEN_TO_SCROLLS_ON_LIST_VIEW = Rollout.isFeatureEnabled(Rollout.FEATURES.TRACK_SCROLLS_IN_BROWSE_PAGE_LIST);
const GUIDE_MESSAGE_BOX_COLOR = Rollout.getFeatureVariable(Rollout.FEATURES.DISPLAY_FEED_SETUP_AFTER_SIGNUP, Rollout.FEATURES.DISPLAY_FEED_SETUP_AFTER_SIGNUP.variables.instruction_box_color, null);
const OnboardingPageView = DynamicPageView.extend({

    //template : _.template(OnboardingPageTemplate),

    initialize : function(options) {
        const userInfo = LocalStorageHelper.getInstance().getUserInfo();
        const loggedInUserFirstName =  userInfo && !userInfo.temporary && userInfo.firstName? userInfo.firstName: "";
        const dynamicPageOptions = {};
        this.model =  options? options.model: {};
        this.genreSelectFieldView =  options? options.genreSelectFieldView : null;
        this.langaugeSelectFieldView =  options? options.langaugeSelectFieldView : null;
        this.citySelectFieldView = options? options.citySelectFieldView : null;
        this._pageId = "Browse";
        this._showListView = false;
        dynamicPageOptions.pageViewTemplate = OnboardingPageTemplate;
        dynamicPageOptions.model = this.model;
        dynamicPageOptions.pageId = this._pageId;
        dynamicPageOptions.headerFullscren = true;
        dynamicPageOptions.persistent = true;
        dynamicPageOptions.onPageShow = this.onPageShow.bind(this);
        dynamicPageOptions.onPageHide = this.onPageHide.bind(this);
        dynamicPageOptions.pageTemplateOptions = {"ti18n":ti18n, "firstName": loggedInUserFirstName,"Rollout":Rollout};
        this._turnOnAutoPreviewWasAcknoledgeByUser = false;
        PersistentModels.getInstance().getRouter().registerForViewportHeightChange(this._pageId,this._onViewportHeightChanged.bind(this));
        return DynamicPageView.prototype.initialize.call(this, dynamicPageOptions);  
    },

    events : {
        "click #homeHeaderButton" : "onHomeHeaderButtonClicked",
        "click #showCarouselViewButton" : "onShowCarouselViewButtonClicked",
        "click #showListViewButton" : "onShowListViewButtonClicked",
        "click #startSetupBtn":"showSetupView",
        "click #playMyFeedForFirstTimeButton:not(.disabled)": "onPlayMyFeedForFirstTimeButtonClicked",
    },

    showSetupView : function(){
        if(this.$el.hasClass(CSS_CLASS_TO_SHOW_WELCOME_MESSAGE)){
            this.setEnablePlayMyFeedForFirstTimeButton(false);
            
            return Utils.getInstance().applyAnimationCssClass(this.userWelcomeMessageBoxWrapper$el, "fadeOutUp", "0.3s").then((function(){
                this.$el.removeClass(CSS_CLASS_TO_SHOW_WELCOME_MESSAGE);
                return Utils.getInstance().applyAnimationCssClass(this.onboardingShortcastSelectionBoxWrapper$el, "fadeInUp", "0.5s")
            }).bind(this)).then((function(){
                this._onViewportHeightChanged(PersistentModels.getInstance().getRouter().getViewportHeight(), true);
                this.trigger("setupViewDisplayed");
            }).bind(this));
        }
    },

    showWelcomeView : function(){
        this.$el.removeClass(CSS_CLASS_TO_SHOW_NO_CONTENT);
        if(!this.$el.hasClass(CSS_CLASS_TO_SHOW_WELCOME_MESSAGE)){
            this.$el.addClass(CSS_CLASS_TO_SHOW_WELCOME_MESSAGE);
            return Utils.getInstance().applyAnimationCssClass(this.userWelcomeMessageBoxWrapper$el, "fadeInUp", "0.3s");
            
        }
    },

    
    onHomeHeaderButtonClicked : function(){
        this.trigger("showHomePopup");
    },

    onShowCarouselViewButtonClicked : function(){
        this.trigger("showCarouselView");
    },

    onPlayMyFeedForFirstTimeButtonClicked : function(){
        this.trigger("playMyFeedForFirstTime");
    },

    onShowListViewButtonClicked : function(){
        this.trigger("showListView");
    },

    _listenForScrollEventOnListView: function(){
        let isScrolling = null;
        this._scrollTopBeforeSCrolling = null;
        this.trebbleListWrapper$el.find("#collection_repeat_container").scroll((function ( event ) {
            if(this._scrollTopBeforeSCrolling === null){
                this._scrollTopBeforeSCrolling = this.trebbleListWrapper$el.scrollTop();
            }
            window.clearTimeout( isScrolling );
            isScrolling = setTimeout((function() {
                const amountOfSroll = this.trebbleListWrapper$el.scrollTop() - this._scrollTopBeforeSCrolling;
                this._scrollTopBeforeSCrolling = null;
                this.trigger("userScrolledListView", amountOfSroll);
            }).bind(this), 66);

        }).bind(this))
    },

    _onViewportHeightChanged : function(newViewPortHeigh, forceResizing) {
        //if(PersistentModels.getInstance().getRouter().isEqualToCurrentPageUrl("#Onboard") || forceResizing){
            if (this.el) {
                const headerHeight = 50;
                const instructionBoxHeight = 107;
                const PLAY_MY_FEED_FOR_THE_FIRST_TIME_WRAPPER_HEIGHT = 80;
                if(window.isIOSMobileDevice ){
                    //For some reason on iPhone 10 the clientHeight vs innerHeight is not accurate and looks like setting the bottom value works fine
                    this.playMyFeedForFirstTimeButtonWrapper$el.css("bottom", "20px");
                    //this.playMyFeedForFirstTimeButtonWrapper$el.css("top",(window.getDocumentClienHeight() - PLAY_MY_FEED_FOR_THE_FIRST_TIME_WRAPPER_HEIGHT) + "px");
                }else{
                    const buttonWrapperPosition =  window.getDocumentClienHeight() - PLAY_MY_FEED_FOR_THE_FIRST_TIME_WRAPPER_HEIGHT; 
                    this.playMyFeedForFirstTimeButtonWrapper$el.css("top",buttonWrapperPosition + "px");
                }
                
                this.userWelcomeMessageBoxWrapper$el.css("height",window.getDocumentClienHeight()+"px");
                const optionsHeight = 60;
                const adjustedListContainerHeight = (newViewPortHeigh - optionsHeight - instructionBoxHeight  ) ;
                if(this.trebbleListWrapper$el){
                    this.trebbleListWrapper$el.css("height", adjustedListContainerHeight + "px")
                }

            }
            //}
        },

        preloadBackgroundImage : function(){
            return 	Utils.getInstance().preloadImageUsingUrl(DEFAULT_BACKGROUND_IMAGE_URL);
        },

        navigateToPreviousPage : function(){
            PersistentModels.getInstance().getRouter().navigateToPreviousPage();
        },

        onBeforePageInitialized : function(){
            this.$el.addClass(CSS_CLASS_TO_SHOW_NO_CONTENT);
        },

        hideInfoHelperBox:function(){
            this.$el.find("#explorePlaylistsWrapper").hide();
        },

        hideAllSubcribeTooltips : function(){
            if(!this.$el.hasClass("do_not_show_subscribe_tooltip")){
                this.$el.addClass("do_not_show_subscribe_tooltip");
            }
        },

        setCarouselView : function(carouselView){
            this.hideInfoHelperBox();
            if(this.carouselView ){
                this.carouselView.destroy();
            }
            this.carouselView = carouselView;
            if(this.carouselView ){
                this.$el.find("#trebbleCarouselWrapper").first().append(this.carouselView.$el);
                this.carouselView.initSwiperContainer();
            }
        },

        setListView : function(listView){
            this.hideInfoHelperBox();
            this.listView = listView;
            if(this.listView ){
                this.trebbleListWrapper$el.first().append(this.listView.$el);
                if(LISTEN_TO_SCROLLS_ON_LIST_VIEW){
                    this._listenForScrollEventOnListView();
                }
            }
        },

        showListView : function(showListView){
            this._showListView =  !!showListView;
            this.$el.attr("showListView",this._showListView?"true":"false");
            if(this._showListView){
                //this.$el.removeClass("lighttext");
                this.$el.removeClass("transparentBackground");
                PersistentModels.getInstance().getRouter().setCanPlayerFooterBeShowOnActivePage(false, true);
            }else{
                /*if(!this.$el.hasClass("lighttext")){
                    this.$el.addClass("lighttext");
                }*/
                if(!this.$el.hasClass("transparentBackground")){
                    this.$el.addClass("transparentBackground");
                }
                PersistentModels.getInstance().getRouter().setCanPlayerFooterBeShowOnActivePage(false, true);
            }
        },

        isPageCurrentlyDisplayed : function(){
            return this.$el.hasClass("ui-page-active");
        },

        onTurnOnAutoPreviewTooltipClickedOn : function(){
            this.showHideTurnOnAutoPreviewTooltipIfApplicable(false, true);
        },

        showHideTurnOnAutoPreviewTooltipIfApplicable: function(show, wasAcknowledgeByUser){
            const turn_explore_auto_preview_tooltip$el = this.$el.find(".turn_explore_auto_preview_tooltip");
            if(DISPLAY_AUTO_PREVIEW_TOOLTIP && (!LocalStorageHelper.getInstance().isAutoPreviewTooltipDiscardedByUser() && !this._turnOnAutoPreviewWasAcknoledgeByUser && show)){
                turn_explore_auto_preview_tooltip$el.removeClass("hide");
            }else{
                if(!turn_explore_auto_preview_tooltip$el.hasClass("hide")){
                    turn_explore_auto_preview_tooltip$el.addClass("hide");
                    this._turnOnAutoPreviewWasAcknoledgeByUser = wasAcknowledgeByUser;
                    LocalStorageHelper.getInstance().setAutoPreviewTooltipDiscardedByUser();
                }
            }
        },

        setThreeSelectorDisplayed : function(threeSelectorDisplayed){
            if(threeSelectorDisplayed){
                if(!this.$el.hasClass("threeSelectorDisplayed")){
                    this.$el.addClass("threeSelectorDisplayed")
                }
            }else{
                if(this.$el.hasClass("threeSelectorDisplayed")){
                    this.$el.removeClass("threeSelectorDisplayed")
                }
            }
        },

        setBackgroundImage : function(imageUrl){

            if(!imageUrl){
                this.$el.find("#backgroundBlurImage").css("background-image","url("+window.getResourceURLAccordingToLocationProtocol("/img/blackWhitebackgroundplayer.jpg")+")");
            }else{
                //const bludImageUrl = window.trebble.globalHelpers.getBlurImageUrl(imageUrl)
                const bludImageUrlWithLimitedSize = window.trebble.globalHelpers.getResizeImageUrl(imageUrl, 640,640 );
                //this.$el.find("#backgroundBlurImage").css("background-image","url("+ bludImageUrlWithLimitedSize+")");
                this._currentBackgroundImageBlur = bludImageUrlWithLimitedSize;
                Utils.getInstance().preloadImageUsingUrl(bludImageUrlWithLimitedSize).then((function(){
                    if(this.self._currentBackgroundImageBlur == this.bludImageUrlWithLimitedSize){
                        this.self.$el.find("#backgroundBlurImage").css("background-image","url("+ bludImageUrlWithLimitedSize+")");
                    }
                }).bind({"self":this, "bludImageUrlWithLimitedSize": bludImageUrlWithLimitedSize}));


            }

        },

        onAutoPreviewFieldChanged : function(elem, args, notTriggerByUserInteration){
            const autoPreviewValueString = this.autoPreviewField$el.val();
            const autoPreviewValueBoolean = autoPreviewValueString && autoPreviewValueString ===  "true"?true:false;
            if(autoPreviewValueBoolean && !notTriggerByUserInteration){
                this.onTurnOnAutoPreviewTooltipClickedOn();
            }else{
                this.showHideTurnOnAutoPreviewTooltipIfApplicable(!autoPreviewValueBoolean, false);
            }
            this.trigger("autoPreviewEnabled",autoPreviewValueBoolean);
        },

        forceLoadTrebbleCoverImage:function(){
            this.trebbleListWrapper$el.trigger("scrollstop");
        },

        forceTrebbleListToScrollToTop : function(){
            this.trebbleListWrapper$el.find("#collection_repeat_container").scrollTop(0);
        },

        setLoadingTrebbleList : function(loadingTrebbleList){
            this._loadingTrebbleList = loadingTrebbleList;
            if(this._loadingTrebbleList){
                if(!this.$el.hasClass("loading_trebble_list")){
                    this.$el.addClass("loading_trebble_list");
                }
            }else{
                this.$el.removeClass("loading_trebble_list");
            }
        },

        setEnablePlayMyFeedForFirstTimeButton : function(enable){
            if(enable){
                if(this.playMyFeedForFirstTimeButton$el.hasClass("disabled")){
                    this.playMyFeedForFirstTimeButton$el.removeClass("disabled")
                }
            }else{
                if(!this.playMyFeedForFirstTimeButton$el.hasClass("disabled")){
                    this.playMyFeedForFirstTimeButton$el.addClass("disabled")
                }
            }
        },

        updateGuidedMessageBox : function(numberOfSubscription, numberOfSubscriptionRequired){
            if(!numberOfSubscription){
                this.guideMessageBox$el.html(Rollout.getFeatureVariable(Rollout.FEATURES.DISPLAY_FEED_SETUP_AFTER_SIGNUP, "label_setup_instructions_"+window.getAppUsedLanguage(),window.getI18n(ti18n, "SETUP_INSTRUCTIONS")));
            }else{
                if(numberOfSubscription < numberOfSubscriptionRequired){
                    const numberOfSubsLeft = numberOfSubscriptionRequired - numberOfSubscription;
                    const yayOnlyLabel = Rollout.getFeatureVariable(Rollout.FEATURES.DISPLAY_FEED_SETUP_AFTER_SIGNUP, "label_yay_only_"+window.getAppUsedLanguage(), window.getI18n(ti18n, "YAY_ONLY"));
                    const leftToSubscribeLabel = Rollout.getFeatureVariable(Rollout.FEATURES.DISPLAY_FEED_SETUP_AFTER_SIGNUP, "label_left_to_subscribe_to_continue_"+window.getAppUsedLanguage(), window.getI18n(ti18n, "LEFT_TOSUBS_TO_CONTINUE"));
                    this.guideMessageBox$el.html(yayOnlyLabel+ " "+numberOfSubsLeft + " "+ leftToSubscribeLabel);
                }else{
                    const buttonDoneLabel = Rollout.getFeatureVariable(Rollout.FEATURES.DISPLAY_FEED_SETUP_AFTER_SIGNUP, "label_setup_feed_complete_button_"+window.getAppUsedLanguage(),window.getI18n(ti18n, "SETUP_COMPLETE_BUTTON_LABEL"));
                    const tapOnLabel = Rollout.getFeatureVariable(Rollout.FEATURES.DISPLAY_FEED_SETUP_AFTER_SIGNUP, "label_tap_on_"+window.getAppUsedLanguage(), window.getI18n(ti18n, "TAP_ON"));
                    const toContinueLabel = Rollout.getFeatureVariable(Rollout.FEATURES.DISPLAY_FEED_SETUP_AFTER_SIGNUP, "label_to_continue_"+window.getAppUsedLanguage(), window.getI18n(ti18n, "TO_CONTINUE"));
                    this.guideMessageBox$el.html(tapOnLabel + buttonDoneLabel + toContinueLabel);
                }
            }
        },

        onAfterPageInitialized : function(){
            //this._initializeCarousels();
            this.autoPreviewField$el = this.$el.find("#autoPreviewField");
            this.playMyFeedForFirstTimeButton$el = this.$el.find("#playMyFeedForFirstTimeButton");
            this.autoPreviewField$el.on("change", this.onAutoPreviewFieldChanged.bind(this));
            if(this.langaugeSelectFieldView){
                this.$el.find("#filtersWrapper").first().append(this.langaugeSelectFieldView.$el);
            }
            if(this.genreSelectFieldView){
                this.$el.find("#filtersWrapper").first().append(this.genreSelectFieldView.$el);
            }
            if(this.citySelectFieldView){
                this.$el.find("#filtersWrapper").first().append(this.citySelectFieldView.$el);
            }
            this.$el.find(".turn_explore_auto_preview_tooltip").on("click", this.onTurnOnAutoPreviewTooltipClickedOn.bind(this));
            this.onAutoPreviewFieldChanged(null, null,true);
            this._fixedBugWithAutoPreviewField();
            this.showListView(this._showListView);

            this.trebbleListWrapper$el = this.$el.find("#trebbleListWrapper");
            this.playMyFeedForFirstTimeButtonWrapper$el =  this.$el.find("#playMyFeedForFirstTimeButtonWrapper");
            this.onboardingShortcastSelectionBoxWrapper$el = this.$el.find(".ui-content");
            this.userWelcomeMessageBoxWrapper$el = this.$el.find("#userWelcomeMessageBoxWrapper");
            this.guideMessageBox$el = this.$el.find("#guideMessageBox");

            Utils.getInstance().initJqueryLazyLoadingPluginOnElemNew(this.trebbleListWrapper$el, "img.lazy", this.trebbleListWrapper$el,"scrollstop");
            this._onViewportHeightChanged(PersistentModels.getInstance().getRouter().getViewportHeight(), true);

            if(GUIDE_MESSAGE_BOX_COLOR){
                this.guideMessageBox$el.css("background-color", GUIDE_MESSAGE_BOX_COLOR);
            }


            this.$el.find("#showCarouselViewButton").hide();

        },

        _fixedBugWithAutoPreviewField : function(){
            this.$el.find("a.ui-flipswitch-on").removeAttr("href");
        },
        onPageShow : function(){
            if(this._showListView){
                PersistentModels.getInstance().getRouter().setCanPlayerFooterBeShowOnActivePage(false, true);
            }else{
                PersistentModels.getInstance().getRouter().setCanPlayerFooterBeShowOnActivePage(false, true);
            }
            this.showWelcomeView();
            this.trigger("pageShow");
        },

        onPageHide : function(){
            this.trigger("pageHide");
        },
        onBeforeRemoveFromPage : function(){
            DynamicPageView.prototype.onBeforeRemoveFromPage.call(this); 
            PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._pageId);
        },

    });
export default OnboardingPageView;