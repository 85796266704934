import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import WriteAReviewForJourneyPageContentView from "views/journey/WriteAReviewForJourneyPageContentView";
import CustomSelectFieldController from "controllers/common/CustomSelectFieldController";
import DynamicPageView from "views/common/DynamicPageView";
import FeatureRolloutHelper from "models/helper/FeatureRolloutHelper";
import ti18n from "i18n!nls/WriteAReviewForJourneyPagei18n";
import PageTemplate from 'text!../../../templates/journey/WriteAReviewPageTemplate.html';
import RSVP from "rsvp";

const WARNING_MESSAGE_LOADING_CLASS = "loading";
const WARNING_MESSAGE_NO_DATA_CLASS = "nodata";
const Rollout = FeatureRolloutHelper.getInstance();
const MIN_NUMBER_OF_SHORTCAST_LISTENED_TO_REVIEW = Rollout.getFeatureVariable(Rollout.FEATURES.CREATE_JOURNEY_USER_REVIEWS, Rollout.FEATURES.CREATE_JOURNEY_USER_REVIEWS.variables.number_of_shortcast_listened_to_leave_review, 4);
const WriteAReviewForJourneyPageController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = options.model;
        this._pageId = options.pageId;
        this._radioId = options.radioId;
        this._entityName = "journey";
    },

    _onPageShow : function(){

    },



    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },



    setViewLoading : function(){

        const warningMesasgeWrapper$el = this.view.$el.find(".warningMessageWrapper");
        this.view.$el.find(".review_form_content").hide();
        if(warningMesasgeWrapper$el.hasClass(WARNING_MESSAGE_NO_DATA_CLASS)){
            warningMesasgeWrapper$el.removeClass(WARNING_MESSAGE_NO_DATA_CLASS);
        }
        if(!warningMesasgeWrapper$el.hasClass(WARNING_MESSAGE_LOADING_CLASS)){
            warningMesasgeWrapper$el.addClass(WARNING_MESSAGE_LOADING_CLASS);
        }
    },

    setViewHasNoData : function(){

        const warningMesasgeWrapper$el = this.view.$el.find(".warningMessageWrapper");
        this.view.$el.find(".review_form_content").hide();
        if(warningMesasgeWrapper$el.hasClass(WARNING_MESSAGE_LOADING_CLASS)){
            warningMesasgeWrapper$el.removeClass(WARNING_MESSAGE_LOADING_CLASS);
        }
        if(!warningMesasgeWrapper$el.hasClass(WARNING_MESSAGE_NO_DATA_CLASS)){
            warningMesasgeWrapper$el.addClass(WARNING_MESSAGE_NO_DATA_CLASS);
        }
    },

    setViewHasData : function(){

        const warningMesasgeWrapper$el = this.view.$el.find(".warningMessageWrapper");
        if(warningMesasgeWrapper$el.hasClass(WARNING_MESSAGE_LOADING_CLASS)){
            warningMesasgeWrapper$el.removeClass(WARNING_MESSAGE_LOADING_CLASS);
        }
        if(warningMesasgeWrapper$el.hasClass(WARNING_MESSAGE_NO_DATA_CLASS)){
            warningMesasgeWrapper$el.removeClass(WARNING_MESSAGE_NO_DATA_CLASS);
        }
        this.view.$el.find(".review_form_content").show();
    },

    setViewFailedLoading : function(error){

        const warningMesasgeWrapper$el = this.view.$el.find(".warningMessageWrapper");
        if(warningMesasgeWrapper$el.hasClass(WARNING_MESSAGE_LOADING_CLASS)){
            warningMesasgeWrapper$el.removeClass(WARNING_MESSAGE_LOADING_CLASS);
        }
        if(warningMesasgeWrapper$el.hasClass(WARNING_MESSAGE_NO_DATA_CLASS)){
            warningMesasgeWrapper$el.removeClass(WARNING_MESSAGE_NO_DATA_CLASS);
        }
        this.view.$el.find(".review_form_content").hide();
    },

    retrieveExistingReview : function(){
        this.setViewLoading();
        return TrebbleClientAPIHelper.getInstance().getUserReviewOnJourney(this._radioId).then((function(operationResult){
            if(operationResult && operationResult.userReview && operationResult.journeyRadioInfo){
                this.setViewHasData();
                const userReviewJSON  = operationResult.userReview;
                this.writeAReviewForJourneyPageContentView.setCreatingNewReview(false);
                this.userReviewModel =  Utils.getInstance().getModelFromUserReviewJson(userReviewJSON);
                this.journeyRadioInfoModel = Utils.getInstance().buildJourneySummaryFromJson(operationResult.journeyRadioInfo);
                this.writeAReviewForJourneyPageContentView.setJourneyTitle(this.journeyRadioInfoModel.getName());
                this.writeAReviewForJourneyPageContentView.setJourneyCoverArt(this.journeyRadioInfoModel.getCoverArtUrl());
                this.writeAReviewForJourneyPageContentView.setNumnberOfStars(this.userReviewModel.getNumberOfStars())
                this.writeAReviewForJourneyPageContentView.setTextReview(this.userReviewModel.getTextReview())
            }else{
                if(operationResult && operationResult.journeyRadioInfo){
                    this.setViewHasData();
                    this.journeyRadioInfoModel = Utils.getInstance().buildJourneySummaryFromJson(operationResult.journeyRadioInfo);
                    this.writeAReviewForJourneyPageContentView.setJourneyTitle(this.journeyRadioInfoModel.getName());
                    this.writeAReviewForJourneyPageContentView.setJourneyCoverArt(this.journeyRadioInfoModel.getCoverArtUrl());
                    this.writeAReviewForJourneyPageContentView.setCreatingNewReview(true);
                }else{
                    this.setViewHasNoData();
                }
            }
        }).bind(this)).catch((function(error){
            this.setViewFailedLoading(error);
        }).bind(this))
    },

    createReview : function(){
        const radioId = this._radioId;
        const journeyId = this.journeyRadioInfoModel.getPublishedJourneyBlueprintId();
        const textReview = this.writeAReviewForJourneyPageContentView.getTextReview();
        const numberOfStars = this.writeAReviewForJourneyPageContentView.getNumberOfStars();
        $.mobile.loading("show");
        return TrebbleClientAPIHelper.getInstance().createUserJourneyReview(radioId, journeyId, textReview, numberOfStars).then((function(operationResult){
            $.mobile.loading("hide");
            if(operationResult.userReviewCreated){
                const onAcknowledgement = (function(){
                    return PersistentModels.getInstance().getRouter().navigateToPreviousPage();
                }).bind(this)
                return window.alertSuccessMessage(window.getI18n(ti18n,"THANK_YOU_FOR_THE_REVIEW"),null, onAcknowledgement);	
            }else{
                if(operationResult.userActivelySubscribedToJourney){
                    return window.alertErrorMessage(window.getI18n(ti18n,"YOU_NEED_TO_LISTEN_TO") +" "+MIN_NUMBER_OF_SHORTCAST_LISTENED_TO_REVIEW+ " "+ window.getI18n(ti18n,"AT_LEAST_TO_SUBMIT_A_REVIEW"));
                }
                if(operationResult.userHasListenToEnoughToReview){
                    return window.alertErrorMessage(window.getI18n(ti18n,"YOU_NEED_TO_LISTEN_TO") +" "+MIN_NUMBER_OF_SHORTCAST_LISTENED_TO_REVIEW+ " "+ window.getI18n(ti18n,"AT_LEAST_TO_SUBMIT_A_REVIEW"));
                }
                if(operationResult.userReviewAlreadyExist){
                    return window.alertErrorMessage(window.getI18n(ti18n,"YOU_HAVE_ALREADY_CREATED_A_REVIEW"));
                }
                if(operationResult.invalidNumberOfStars){
                    return window.alertErrorMessage(window.getI18n(ti18n,"SELECT_STARS_RATING_BEFORE_SUBMITTING_YOUR_REVIEW"));
                }
                if(operationResult.invalidTextReview){
                    return window.alertErrorMessage(window.getI18n(ti18n,"PLEASE_WRITE_A_REVIEW_BEFORE_SUBMITTING"));
                }
                return window.alertErrorMessage(window.getI18n(ti18n,"YOU_NEED_TO_LISTEN_TO") +" "+MIN_NUMBER_OF_SHORTCAST_LISTENED_TO_REVIEW+ " "+ window.getI18n(ti18n,"AT_LEAST_TO_SUBMIT_A_REVIEW"));
            }
        }).bind(this)).catch((function(error){
            $.mobile.loading("hide");
            window.alertErrorMessage(error);
        }).bind(this));			
    },

    updateReview : function(){
        const radioId = this._radioId;
        const journeyId = this.journeyRadioInfoModel.getPublishedJourneyBlueprintId();
        const textReview = this.writeAReviewForJourneyPageContentView.getTextReview();
        const numberOfStars = this.writeAReviewForJourneyPageContentView.getNumberOfStars();
        const reviewId = this.userReviewModel.getReviewId();
        $.mobile.loading("show");
        return TrebbleClientAPIHelper.getInstance().updateUserJourneyReview(reviewId, textReview, numberOfStars).then((function(operationResult){
            $.mobile.loading("hide");
            if(operationResult.userReviewWasUpdated){
                const onAcknowledgement = (function(){
                    return PersistentModels.getInstance().getRouter().navigateToPreviousPage();
                }).bind(this)
                return window.alertSuccessMessage(window.getI18n(ti18n,"THANK_YOU_FOR_THE_REVIEW"),null, onAcknowledgement);
            }else{
                if(operationResult.userReviewAlreadyExist){
                    return window.alertErrorMessage(window.getI18n(ti18n,"YOU_HAVE_ALREADY_CREATED_A_REVIEW"));
                }
                if(operationResult.invalidNumberOfStars){
                    return window.alertErrorMessage(window.getI18n(ti18n,"SELECT_STARS_RATING_BEFORE_SUBMITTING_YOUR_REVIEW"));
                }
                if(operationResult.invalidTextReview){
                    return window.alertErrorMessage(window.getI18n(ti18n,"PLEASE_WRITE_A_REVIEW_BEFORE_SUBMITTING"));
                }
                return window.alertErrorMessage(window.getI18n(ti18n,"YOU_NEED_TO_LISTEN_TO") +" "+MIN_NUMBER_OF_SHORTCAST_LISTENED_TO_REVIEW+ " "+ window.getI18n(ti18n,"AT_LEAST_TO_SUBMIT_A_REVIEW"));
            }
        }).bind(this)).catch((function(error){
            $.mobile.loading("hide");
            window.alertErrorMessage(error);
        }).bind(this));		
    },

    buildModelAndRenderView : function() {


        const params = {};

        const writeAReviewForJourneyPageContentView =  new WriteAReviewForJourneyPageContentView(params);

        writeAReviewForJourneyPageContentView.render();
        this.writeAReviewForJourneyPageContentView = writeAReviewForJourneyPageContentView;
        this.listenTo(this.writeAReviewForJourneyPageContentView , "createReview", this.createReview);
        this.listenTo(this.writeAReviewForJourneyPageContentView , "updateReview", this.updateReview);

        const pageViewParams= {};
        pageViewParams.pageId = this._pageId;
        pageViewParams.pageViewTemplate = PageTemplate;
        pageViewParams.containerSelector = ".ui-content";
        pageViewParams.contentView = writeAReviewForJourneyPageContentView;
        pageViewParams.onPageShow = this._onPageShow.bind(this);
        pageViewParams.pageTemplateOptions = {"ti18n":ti18n};
        pageViewParams.ignoreDefaultPageUIContentPadding = true;

        this.view = new DynamicPageView(pageViewParams);
        this.view.render();
        this.view.$el.on("click", "#header-back-button", this.view.navigateToPreviousPage.bind(this.view));
        this.retrieveExistingReview();

        $.mobile.loading("hide");
        

        return RSVP.Promise.resolve();

    }

});

export default WriteAReviewForJourneyPageController;