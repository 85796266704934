import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Song from "models/Song";
import ti18n from "i18n!nls/Commoni18n";
import TrebbleListView from "views/common/TrebbleListView";
import SongOptionMenuModel from "models/SongOptionMenuModel";
import SongOptionMenuView from "views/SongOptionMenuView";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";

const disableSwipeOnListItem = true;
const TrebbleSongListView = TrebbleListView.extend({
    
    
    
    
    
    initialize : function(options) {
        if(!options){
            options= {};
        }
        if(!options.rightSwipeRevealOption){
            options.rightSwipeRevealOption = this._buildItemRightOption()
        }
        if(!options.leftSwipeRevealOption){
            options.leftSwipeRevealOption = this._buildItemLeftOption()
        }

        this.collection = options.collection
        options.modelIdAttribute  = "uri";
        //options.doNotShowAddToRadioButton = false;//(window.device && window.device.platform == "browser" && !window.isMobileBrowser)?false: true;
        //options.doNotShowGradeSongButton = (window.device && window.device.platform == "browser" && !window.isMobileBrowser)?false: true;
        this._doNotShowAddToRadioButton =options.doNotShowAddToRadioButton;
        this._doNotShowGradeSongButton =options.doNotShowGradeSongButton;
        this._doNotShowMoreInfoButton = options.doNotShowMoreInfoButton;
        this._showInfoButton = options.showInfoButton;
        this._showCommentButton = options.showCommentButton;
        this._showCoverArt = options.showCoverArt;
        this._numberOfItemsToDisplayPerRow = options.numberOfItemsToDisplayPerRow;
        const self = TrebbleListView.prototype.initialize.call(this, options);
        this.setModelTransformHandlerBeforeTemplatePiping(this._modelTransformHandlerBeforeTemplatePiping);
        this.setItemViewUpdateDuringScrollingHandler(this._getItemViewUpdateDuringScrollingHandler);
        return self;
        
    },
    
    _getDefaultItemEventHandler : function(){
        let defaultItemEventHandler =  TrebbleListView.prototype._getDefaultItemEventHandler.call(this);
        defaultItemEventHandler = defaultItemEventHandler? defaultItemEventHandler : [];
        defaultItemEventHandler["click div.capsuleBtnWrapper > #songCapsuleOptionButtuon"] = this._getInternalOnCapsuleIconTapHandler.bind(this);
        if(this._showInfoButton){
            defaultItemEventHandler["click div.showInfoBtnWrapper > #showInfoOptionButton"] = this._getOnShowInfoButtonTappedHanlder.bind(this);
        }
        if(this._showCommentButton){
            defaultItemEventHandler["click div.showCommentBtnWrapper > #showCommentOptionButton"] = this._getOnShowCommentsButtonTappedHanlder.bind(this);
        }
        if(!this._doNotShowAddToRadioButton){
            defaultItemEventHandler["click div.addToTrebbleBtnWrapper > #addToTrebbleOptionButtuon"] = this._getOnAddRemoveSongToTrebbleButtonTappedHanlder.bind(this);
        }
        //defaultItemEventHandler["click div.addToTrebbleBtnWrapper > #addToTrebbleOptionButtuon.ion-ios7-checkmark-outline"] = this._getOnRemoveSongToTrebbleButtonTappedHanlder.bind(this);
        if(!this._doNotShowGradeSongButton){
            defaultItemEventHandler["click div.gradesBtnWrapper > #gradeOptionButtuon"] = this._getOnTagSongButtonTappedHanlder.bind(this);
        }
        return defaultItemEventHandler;
    },
    reload : function(){
        this.setUpdating(true);
        try{
            const displayedSongJSONArray = this.collection? this.collection.toJSON(): [];
            this.updateModels(displayedSongJSONArray);
            this.setUpdating(false);
        }catch(error){
            this.setErrorMessage(error);
        }
    },
    
    _getInternalOnCapsuleIconTapHandler : function(model,evnt, item) {
        const songModel = this._getSongModelFromSongJson(model);
        const context = this.getContext();
        if(songModel){
            return PersistentModels.getInstance().getRouter().showCapsulesForSong(songModel, context);
        }else{
            return RSVP.Promise.reject("Song couldn't be found");
        }	
    },
    
    _getOnAddRemoveSongToTrebbleButtonTappedHanlder : function(songJson,evnt, item){
        if(evnt.currentTarget.className.indexOf("ion-ios7-plus-outline") != -1){
            this.trigger("addToRadioButtonTapped", this._getSongModelFromSongJson(songJson));
        }else{
            this.trigger("removeFromRadioButtonTapped", this._getSongModelFromSongJson(songJson));
        }
    },


    _getOnShowCommentsButtonTappedHanlder : function(songJson,evnt, item){
        const context = this.getContext();
        return PersistentModels.getInstance().getRouter().showCommentsFromAudioObjJson(songJson, false, context, true);
    },

    _getOnShowInfoButtonTappedHanlder : function(songJson,evnt, item){
        const context = this.getContext();
        return PersistentModels.getInstance().getRouter().showInfoPopupFromAudioObjJson(songJson, context);
    },
    
    

    
    _getOnTagSongButtonTappedHanlder : function(songJson,evnt, item){
        this.trigger("gradeButtonTapped", this._getSongModelFromSongJson(songJson));
    },

    
    _buildItemRightOption : function(){
        const optionConfig = [];
        if(!disableSwipeOnListItem && (((window.device && window.device.platform != "browser") || window.isMobileBrowser))){
            optionConfig.push( {"optionLabel" : "..."});
            optionConfig.push( {"optionLabel" : window.getI18n(ti18n,"ADD_TO_MY_TREBBLE")+"<i class='ion-ios7-plus-outline'></i>","name": "addToRadio", "validationHandler": this._canBeAddedToRadio.bind(this), "onOptionSelected": this._onAddSongToRadioMenuSelected.bind(this)});//"<i class='fontello-icon-list-add'></i>","name": "addToQueue"};// "Add To Queue"};
            optionConfig.push( {"optionLabel" : window.getI18n(ti18n,"GRADE")+"<i class='ion-ios7-pricetags-outline'></i>","name": "rateOrTag" , "validationHandler": null, "onOptionSelected": this._onTagSongOptionMenuSelected.bind(this)});//"<i class='ion-radio-waves'></i>","name": "playSimilarInLibrary"};//: "Play Similar In Library"};
        }
        return optionConfig;
    },

    _buildItemLeftOption : function(){
        const optionConfig = [];
        if(!disableSwipeOnListItem && (((window.device && window.device.platform != "browser") || window.isMobileBrowser))){
            optionConfig.push( {"optionLabel" : "..."});
            optionConfig.push( {"optionLabel": "<i class='pe-7s-more'></i>"+window.getI18n(ti18n,"SHOW_OPTIONS"),"name": "showOptions", "validationHandler": null, "onOptionSelected": this._onShowSongOptionMenuSelected.bind(this)});//"<i class='fontello-icon-list-add'></i>"};// "Add To Queue"};
            optionConfig.push( {"optionLabel": "<i class='ion-ios7-minus-outline'></i>"+window.getI18n(ti18n,"REMOVE_FROM_MY_TREBBLE"),"name": "removeFromRadio", "validationHandler":  this._canBeRemovedToRadio.bind(this), "onOptionSelected": this._onRemoveSongToRadioMenuSelected.bind(this)});//"<i class='ion-radio-waves'></i>"};// : "Play Similar In Library"};
        }
        return optionConfig;
    },

    _canBeAddedToRadio : function(songJson){
        const songModel = this._getSongModelFromSongJson(songJson);
        return songModel?!songModel.get("addedToRadio"): true;

    },

    _canBeRemovedToRadio : function(songJson){
        const songModel = this._getSongModelFromSongJson(songJson);
        return songModel?songModel.get("addedToRadio"): false;

    },

    _onAddSongToRadioMenuSelected : function(songJson){
        const songModel = this._getSongModelFromSongJson(songJson);
        const context = this.getContext();
        if(songModel){
            const p =  PersistentModels.getInstance().addSongToDefaultRadio(songModel, context);
            this.trigger("songAddedToTrebble", songModel);
            return p;
        }else{
            return RSVP.Promise.reject(window.getI18n(ti18n,"SONG_TO_ADD_COULDNT_BE_FOUND"));
        }

    },

    _onRemoveSongToRadioMenuSelected : function(songJson){
        const songModel = this._getSongModelFromSongJson(songJson);
        const context = this.getContext();
        if(songModel){
            const  p = PersistentModels.getInstance().removeSongFromDefaultRadio(songModel, context);
            this.trigger("songRemovedFromTrebble", songModel);
            return p;
        }else{
            return RSVP.Promise.reject(window.getI18n(ti18n,"SONG_TO_ADD_COULDNT_BE_FOUND"));
        }
    },

    _onShowSongOptionMenuSelected : function(songJson){
        const songModel = this._getSongModelFromSongJson(songJson);
        if(songModel){
            const context = this.getContext();
            return PersistentModels.getInstance().getRouter().showSongMenuOption(songModel, context,null, this.onActionExecutedOnSongHandler.bind(this));
        }else{
            return RSVP.Promise.reject(window.getI18n(ti18n,"SONG_TO_ADD_COULDNT_BE_FOUND"));
        }
    },

    onActionExecutedOnSongHandler : function(songModel, action){
        this.trigger("actionExecutedOnSongHandler", songModel, action);
        /*if(action == "delete" || action == "removeFromRadio" || action == "removeToFavorite"){

        }*/
    },


    _onTagSongOptionMenuSelected : function(songJson){
        const songModel = this._getSongModelFromSongJson(songJson);
        if(songModel){
            const context = this.getContext();
            return PersistentModels.getInstance().getRouter().showSongTagManagerPopup(songModel, context);
        }else{
            return RSVP.Promise.reject(window.getI18n(ti18n,"SONG_TO_ADD_COULDNT_BE_FOUND"));
        }
    },

    getContext : function(){
        return this.collection? this.collection.getContext(): null;
    },


    _getSongModelFromSongJson : function(songJson){
        return this.collection.get(songJson.uri);
    },


    _modelTransformHandlerBeforeTemplatePiping: function(songJson) {
        let artistToDisplay = null;
        let titleToDisplay = null;
        let albumNameToDisplay = null;
        let templateData =  null;
        if (songJson.idified && (songJson.idified === "true" || songJson.idified === true) && (songJson.idifiedSuccess === "true" || songJson.idifiedSuccess === true)) 
        {
            if (window.trebble.config.showIdifiedArtistName) 
            {
                const featuredArtists = songJson.idifiedFeaturedArtists;
                let featuredArtistsString = null;
                if(featuredArtists && featuredArtists.length > 0){
                    let songTitle =  songJson.title;
                    if( songJson.idifiedTitle && window.trebble.config.showIdifiedSongTitle)
                    {
                        songTitle = songJson.title;
                    }
                    for(let index in featuredArtists){
                        const featuredArtist = featuredArtists[index];
                        if(!songTitle || (songTitle && songTitle.toUpperCase().indexOf(featuredArtist.artist.toUpperCase()) == -1)){	
                            if(!featuredArtistsString)
                            {
                                featuredArtistsString =   featuredArtist.artist;
                            }else{
                                featuredArtistsString =  featuredArtistsString + ", " + featuredArtist.artist;
                            }
                        }
                    }
                }
                const mainArtist = songJson.idifiedArtist;

                const allArtists = featuredArtistsString? mainArtist + ", " +featuredArtistsString : mainArtist;
                artistToDisplay = allArtists;
            }else{
                artistToDisplay = songJson.artist;
            }
            if (window.trebble.config.showIdifiedSongTitle) 
            {
                titleToDisplay = songJson.idifiedTitle;
            }else{
                titleToDisplay = songJson.title;
            }
            if(window.trebble.config.showIdifiedAlbumTitle){
                albumNameToDisplay = songJson.idifiedAlbum;
            }else{
                albumNameToDisplay = songJson.album;
            }
        } else {
            artistToDisplay = songJson.artist;
            titleToDisplay = songJson.title;
            albumNameToDisplay = songJson.album;
        }
        if (!artistToDisplay) 
        {
            artistToDisplay = "";
        }
        if (!titleToDisplay) 
        {
            titleToDisplay = "";
        }

        if (!albumNameToDisplay) 
        {
            albumNameToDisplay = "";
        }

        templateData = {firstLineText: titleToDisplay,secondLineText: artistToDisplay + " · "+ albumNameToDisplay ,model : songJson};
        return templateData;
    },

    _getItemViewUpdateDuringScrollingHandler : function(elem,templateData){

        const firstItemElem = elem.querySelector(".songTitleLibrary");
        firstItemElem.textContent = templateData.firstLineText;
        const secondItemElem = elem.querySelector(".songArtistLibrary");
        secondItemElem.textContent = templateData.secondLineText;
        const capsuleDivWrapper = elem.querySelector(".capsuleBtnWrapper");
        const explicitIconWrapper = elem.querySelector(".explicitIconWrapper");
        if(explicitIconWrapper){
            const explicit = templateData.model.idifiedExplicit == !!"true" || templateData.model.explicit == !!"true"? true : false;
            if(explicit){
                if(explicitIconWrapper.style.display != "-webkit-flex"){
                    explicitIconWrapper.style.display  = "-webkit-flex";
                    if(explicitIconWrapper.style.display != "-webkit-flex"){
                        explicitIconWrapper.style.display  = "flex";
                    }
                }
            }else{
                explicitIconWrapper.style.display  = "none";
            }
        }
        if(templateData.model && templateData.model.hasCapsules){
            if(capsuleDivWrapper.style.display != "-webkit-flex"){
                capsuleDivWrapper.style.display  = "-webkit-flex";
                if(capsuleDivWrapper.style.display != "-webkit-flex"){
                    capsuleDivWrapper.style.display  = "flex";
                }
            }
        }else{
            if(capsuleDivWrapper.style.display != "none"){
                capsuleDivWrapper.style.display  = "none";
            }
        }
        if(!this._doNotShowAddToRadioButton){
            const addToTrebbleBtnWrapper = elem.querySelector(".addToTrebbleBtnWrapper");
            if(addToTrebbleBtnWrapper){
                if(templateData.model.beingAddedToTrebble || templateData.model.beingRemovedFromTrebble){
                    if(addToTrebbleBtnWrapper.children[0].className.indexOf(" ion-loading-c animate-spin") == -1){
                        addToTrebbleBtnWrapper.children[0].className = addToTrebbleBtnWrapper.children[0].className + " ion-loading-c animate-spin";
                    }
                }else{
                    if(addToTrebbleBtnWrapper.children[0].className.indexOf(" ion-loading-c animate-spin") != -1){
                        addToTrebbleBtnWrapper.children[0].className = addToTrebbleBtnWrapper.children[0].className.replace(" ion-loading-c animate-spin", "");
                    }
                    const radioUIDs = templateData.model.radioUIDs;
                    const addedToRadio = (radioUIDs && radioUIDs.length > 0)? true: false;
                    if(addedToRadio){
                        if(addToTrebbleBtnWrapper.children[0].className.indexOf("ion-ios7-checkmark-outline") == -1){
                            addToTrebbleBtnWrapper.children[0].className = addToTrebbleBtnWrapper.children[0].className.replace("ion-ios7-plus-outline", "");
                            addToTrebbleBtnWrapper.children[0].className = addToTrebbleBtnWrapper.children[0].className + " ion-ios7-checkmark-outline";
                        }
                    }else{
                        if(addToTrebbleBtnWrapper.children[0].className.indexOf("ion-ios7-plus-outline") == -1){
                            addToTrebbleBtnWrapper.children[0].className = addToTrebbleBtnWrapper.children[0].className.replace("ion-ios7-checkmark-outline", "");
                            addToTrebbleBtnWrapper.children[0].className = addToTrebbleBtnWrapper.children[0].className + " ion-ios7-plus-outline";
                        }
                    }
                }
            }
        }
        if(!this._doNotShowGradeSongButton){
            const gradesBtnWrapper = elem.querySelector(".gradesBtnWrapper");
            if(gradesBtnWrapper){
                const songTagIds = templateData.model.tagIds;
                const numberOfTagsAttachedToSong = (songTagIds && songTagIds.length > 0)?songTagIds.length : 0;
                if(numberOfTagsAttachedToSong == 0){
                    if(gradesBtnWrapper.children[0].className.indexOf("ion-ios7-pricetags-outline") == -1){
                        gradesBtnWrapper.children[0].className = gradesBtnWrapper.children[0].className.replace("ion-ios7-pricetags graded", "");
                        gradesBtnWrapper.children[0].className = gradesBtnWrapper.children[0].className + " ion-ios7-pricetags-outline";
                    }
                }else{
                    if(gradesBtnWrapper.children[0].className.indexOf("ion-ios7-pricetags graded")  == -1){
                        gradesBtnWrapper.children[0].className = gradesBtnWrapper.children[0].className.replace("ion-ios7-pricetags-outline", "");
                        gradesBtnWrapper.children[0].className = gradesBtnWrapper.children[0].className + " ion-ios7-pricetags graded ";
                    }
                }
            }
        }
        if(this._showCommentButton){
            const showCommentBtnWrapper = elem.querySelector(".showCommentBtnWrapper");
            if(showCommentBtnWrapper){
                if(window.trebble.globalHelpers.Utils.commentsCanBeDisplayedForThisAudioUri(templateData.model.uri) && window.trebble.globalHelpers.Utils.canCommentsBeRetrieveFromAudioObjJson(templateData.model)){
                    if(showCommentBtnWrapper.style.display != "-webkit-flex"){
                        showCommentBtnWrapper.style.display  = "-webkit-flex";
                        if(showCommentBtnWrapper.style.display != "-webkit-flex"){
                            showCommentBtnWrapper.style.display  = "flex";
                        }
                    }
                }else{
                    if(showCommentBtnWrapper.style.display != "none"){
                        showCommentBtnWrapper.style.display  = "none";
                    }
                }
            }
        }
        if(this._showInfoButton){
            const showInfoBtnWrapper = elem.querySelector(".showInfoBtnWrapper");
            if(showInfoBtnWrapper){
                if(window.trebble.globalHelpers.Utils.artistInfoCanBeDisplayedForThisAudioUri(templateData.model.uri) && window.trebble.globalHelpers.Utils.canAudioInfoBeRetrieveFromAudioObjJson(templateData.model)){
                    if(showInfoBtnWrapper.style.display != "-webkit-flex"){
                        showInfoBtnWrapper.style.display  = "-webkit-flex";
                        if(showInfoBtnWrapper.style.display != "-webkit-flex"){
                            showInfoBtnWrapper.style.display  = "flex";
                        }
                    }
                }else{
                    if(showInfoBtnWrapper.style.display != "none"){
                        showInfoBtnWrapper.style.display  = "none";
                    }
                }
            }
        }
        if(this._showCoverArt){
            const coverArtElem = elem.querySelector(".songCoverArt");
            if(coverArtElem){
                const albumArtUrl = (templateData.model.albumArtUrl && !this._doNotShowSongCoverImage)?window.trebble.globalHelpers.getResizeImageUrl(templateData.model.albumArtUrl, 100, 100, templateData.model.albumArtImages): "";
                coverArtElem.src = window.getResourceURLAccordingToLocationProtocol("/img/videoLoading.png");
                coverArtElem.setAttribute("data-original", albumArtUrl);
                if(!coverArtElem.className ||  coverArtElem.className.indexOf("lazy") == -1){
                    coverArtElem.className = (!coverArtElem.className)? "lazy" :  coverArtElem.className +" lazy";
                }
                
            }
        }
        /*const songLibraryWrapperElem = elem.querySelector(".songLibraryWrapper");
        if(songLibraryWrapperElem){
            if(window._isSongPlaying && window._playerLoadedSongUri == templateData.model.uri){
                if(songLibraryWrapperElem.className.indexOf("currentlyPlaying") == -1){
                    songLibraryWrapperElem.className = songLibraryWrapperElem.className + " currentlyPlaying ";
                }
            }else{
                if(songLibraryWrapperElem.className.indexOf("currentlyPlaying") != -1){
                    songLibraryWrapperElem.className = songLibraryWrapperElem.className.replace("currentlyPlaying", "");
                }
            }
        }*/

    },


});

export default TrebbleSongListView;