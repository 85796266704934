import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import DynamicPageView from "views/common/DynamicPageView";
import Utils from "models/helper/Utils";
import SoundManagerMedia from "models/helper/SoundManagerMedia";
import SoundNativeAudio from "models/helper/SoundNativeAudio";
import ti18n from "i18n!nls/RecordAudioHightlightPagei18n";
import PersistentModels from "services/PersistentModels";
import SoundboardViewTemplate from 'text!../../../templates/audioHighlight/SoundboardViewTemplate.html';

const SoundboardView = DynamicPageView.extend({

    //template : _.template(ExplorePageTemplate),

    initialize : function(options) {
        const dynamicPageOptions = {};
        this.model =  options? options.model: {};
        
        
        this._isRecording = options.isRecording;
        this._isViewRendered =  false;
        this._soundboardItemArray = [];
        this._soundboardItemMediaArray =  [];
        
        return DynamicPageView.prototype.initialize.call(this, dynamicPageOptions);  
    },

    events : {
        
    },

    setSoundboardItemArray : function(soundboardItemArray){
        this._soundboardItemArray = soundboardItemArray;
    },

    _loadSoundboardItems : function(soundboardItemArray){
        if(this._soundboardItemMediaArray && this._soundboardItemMediaArray.length > 0){
            for(let i  = 0; i < this._soundboardItemMediaArray.length; i++ ){
                this._soundboardItemMediaArray[i].release();
            }
        }
        this._soundboardItemMediaArray =  [];
        for(let i =0; i  < soundboardItemArray.length; i++){
            const soundboardItem = soundboardItemArray[i];
            this._soundboardItemMediaArray.push(this.getSoundMedia(soundboardItem.getUrl()));
        }
    },

    getSoundMedia : function(soundboardItem){
        if(window.waitForCordovaToLoad){
            const soundManagerMediaParams = {};
            soundManagerMediaParams.uri = soundboardItem.getUrl();
            soundManagerMediaParams.onSuccess = function(){};
            soundManagerMediaParams.onError = function(){};
            soundManagerMediaParams.onStatus = function(){};

            const media = new SoundNativeAudio(soundManagerMediaParams);
            return media;
        }else{
            const soundNativeAudioMediaParams = {};
            soundNativeAudioMediaParams.uri =  soundboardItem.getUrl();
            soundNativeAudioMediaParams.onSuccess = function(){};
            soundNativeAudioMediaParams.onError = function(){};
            soundNativeAudioMediaParams.onStatus = function(){};
            soundNativeAudioMediaParams.onMediaReadyToPlay = function(){};
            const media = new SoundNativeAudio(soundNativeAudioMediaParams);
            return media;
        }
    },

    setRecording : function(isRecording){
        this._isRecording = isRecording;
    },


    render : function(){
        const params = {};
        this.$el.html("<div></div>");
        this._isViewRendered =  true;
        return this;
    },
    

});
export default SoundboardView;