import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrimSilencesContextMenuView from "views/audioEditor/TrimSilencesContextMenuView";
import TrimSilencesContextMenuContentView from "views/audioEditor/TrimSilencesContextMenuContentView";
import Utils from "models/helper/Utils";
import PersistentModels from "services/PersistentModels";
import ti18n from "i18n!nls/Sequenceri18n";
import RSVP from "rsvp";

const TrimSilencesContextMenuController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.model = options? options.sequencer : null;
        
        this.context = options? options.context : null;
        this.elementSource$el = options? options.elementSource$el : null;
        this._popupPosition = options? options.popupPosition: null;
        this._onMenuClosedHandler = options? options.onMenuClose: null;
        this._applySearch = options.applySearch;
        _.extend(this, Backbone.Events);
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    trimSilences : function(){
        if(this._applySearch){
            const maxSilenceInMilliseconds = this.contentView.getNumberOfPauses() * Utils.getInstance().getPauseDurationInMilliSecondsThatShouldBeExplicitlyDisplayed();
            const onAnalysisPogressFunction =  async function(actionToExecute, defaultProgressMessage){
                await PersistentModels.getInstance().getRouter().executeFunctionAndShowProgressBarPopup(actionToExecute, defaultProgressMessage);
            }
            
            this.model.findSilences(maxSilenceInMilliseconds, onAnalysisPogressFunction);
            this.trigger("silenceFindExecuted");
        }else{
            const numberOfSilencesRemoved = this.model.trimSilences(this.contentView.getNumberOfPauses() * Utils.getInstance().getPauseDurationInMilliSecondsThatShouldBeExplicitlyDisplayed());
            this.trigger("silenceTrimExecuted", numberOfSilencesRemoved);
        }
    },		

    close: function(){
        return this.view.close();
    },

    onMenuClosed: function(){
        if(this._onMenuClosedHandler){
            this._onMenuClosedHandler();
        }
        this.stopListening();
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                const contentParams = {
                    "model" : this.model,
                    "elementSource$el": this.elementSource$el,
                    "popupPosition": this._popupPosition,
                    "findSilences": this._applySearch
                };
                const contentView = new TrimSilencesContextMenuContentView(contentParams);
                contentView.render();
                this.contentView =  contentView;
                this.listenTo(contentView, "trimSilences",this.trimSilences);
                this._isUsingBottomSheet = false;
                if(window.isMobileBrowser){
                    //const bottomSheet = Utils.getInstance().createBottomSheet(this._sequencerNodeInspectorSectionController.getView().$el.get(0), resolve, this._onPopupClosed.bind(this));
                    const onBottomSheetDisplayed = function(){

                    }
                    const bottomSheet = Utils.getInstance().createBottomSheet(contentView.$el.get(0), onBottomSheetDisplayed, this.onMenuClosed.bind(this), true);
                    bottomSheet.activate();
                    bottomSheet.close = function(){
                        bottomSheet.deactivate();
                    }
                    this.view = bottomSheet;
                    this._isUsingBottomSheet = true;
                    resolve();
                }else{
                    contentParams.contentView = contentView;
                    this.view = new TrimSilencesContextMenuView(contentParams);
                    this.listenTo(this.view, "closed", this.onMenuClosed);
                    this.view.render();
                    resolve();
                }
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default TrimSilencesContextMenuController;