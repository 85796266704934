import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import CapsuleCardController from "controllers/capsule/CapsuleCardController";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import Utils from "models/helper/Utils";
import ListItemCollection from "collections/ListItemCollection";
import OptionPopupView from "views/common/OptionPopupView";
import RSVP from "rsvp";

const CapsuleDetailsPopupController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.model = options.model;
        this.context = options.context;
        const capsuleCardControllerParams = {};
        capsuleCardControllerParams.model = this.model;
        capsuleCardControllerParams.showCommentInLine = options.showCommentInLine;
        capsuleCardControllerParams.doNotAllowToBeAddedToTrebble = options.doNotAllowToBeAddedToTrebble;
        capsuleCardControllerParams.doNotAllowToBePlayed = options.doNotAllowToBePlayed;
        capsuleCardControllerParams.previewMultipleURLS =  true;
        capsuleCardControllerParams.context = this.context;
        this.capsuleCardController = new CapsuleCardController(capsuleCardControllerParams);
        _.extend(this, Backbone.Events);
        this.listenTo(this.capsuleCardController,"finishPlaying",(function(){
            this.trigger("finishPlaying")
        }).bind(this));
        this.listenTo(this.capsuleCardController,"startPlaying",(function(){
            this.trigger("startPlaying")
        }).bind(this));
        this.listenTo(this.capsuleCardController,"failedPlaying",(function(){
            this.trigger("failedPlaying")
        }).bind(this));
        this.listenTo(this.capsuleCardController,"stopPlaying",(function(){
            this.trigger("stopPlaying")
        }).bind(this));
        
    },



    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    playCapsule : function(){
        this.capsuleCardController.clickOnPlayButton();
    },

    _onPopupClosed : function(){
        this.capsuleCardController.stopPlayingCapsule();
    },

    _onCapsuleCardAddedToPage : function(){
        this.capsuleCardController._wasAddedToDom();
    },

    onViewRemoved:function(){
        this.stopListening();
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {

                this.capsuleCardController.buildModelAndRenderView().then((function(){
                    const params= {};
                    params.contentView = this.capsuleCardController.getView();
                    params.contentPosition = "center";
                    params.id = "capsuleDetailsPopup";
                    params.addContentAfterEnhancement = true;
                    params.capsuleCategoryCollection = new ListItemCollection(Utils.getInstance().getCapsuleCategoryListItemArray());
                    this.view = new OptionPopupView(params);
                    this.listenTo(this.view, "closed", this._onPopupClosed.bind(this))
                    this.listenTo(this.view, "remove", this.onViewRemoved.bind(this));
                    this.listenTo(this.view, "popupContentWasAdded", this._onCapsuleCardAddedToPage.bind(this))
                    this.view.render();
                    resolve();
                }).bind(this)).catch(reject);

            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default CapsuleDetailsPopupController;