import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import OptionPopupView from "views/common/OptionPopupView";
import Typeahead from "typeahead";
import Mention from "mention";
import ti18n from "i18n!nls/Commenti18n";
import popupTemplate from 'text!../../../templates/comment/commentTemplate.html';

const CommentView = OptionPopupView.extend({

    

    "pageName" : "Comment Page",

    initialize : function(options) {
        const viewOptions =  {};
        this._songModel = options.model;
        this._followersUsersInfo = options.followersUsersInfo;
        const contentView = {};
        const contentViewTemplate = _.template(popupTemplate);
        const templateParams = {"model" : this._songModel.toJSON()};
        contentView.$el  = $(contentViewTemplate(templateParams));
        viewOptions.contentView = contentView;
        viewOptions.contentPosition = "center";
        viewOptions.id = "CommentViewPopup";
        OptionPopupView.prototype.initialize.call(this, viewOptions);
    },
    
    afterRendering : function(){
        //add mention plugin to text area field
        
        //first, build array of username 
        const users =  [];
        if(this._followersUsersInfo ){
            for(let index in this._followersUsersInfo ){
                const userInfo = this._followersUsersInfo[index];
                users.push({"username": userInfo.username});
            }
        }
        const params = {};
        params.delimiter = "@";
        params.users = users;
        this._mentionObj = this.$el.find("#comment").mention(params);
        this.$el.find(".commentForm").on("submit", this._onFormSubmit.bind(this));
        this.$el.find("#commentBtn").click(this._onCommentButtonClicked.bind(this));
    },
    
    _onCommentButtonClicked : function(){
        this.trigger("postComment", this.$el.find("#comment").val())
        this.close();
    },
    
    _onFormSubmit : function(){
        return false;
    },
    
    onAfterPageShow : function(){
        this.$el.find("#comment").focus();
    },



});

export default CommentView;