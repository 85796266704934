import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlaylistHelper from "models/helper/PlaylistHelper";
import Utils from "models/helper/Utils";
import PersistentModels from "services/PersistentModels";
import PlayerService from "services/PlayerService";
import RSVP from "rsvp";
import PlaylistDetailsController from 'controllers/common/PlaylistDetailsController';
import ti18n from "i18n!nls/PlaylistPodcasti18n";
import PageTemplate from 'text!../../../templates/playlistPodcasts/RemoteRadioDetails.html';
import Hammer from 'hammerjs';

const MyAutoRadioDetailsController = PlaylistDetailsController.extend({


    initialize : function(options) {
        options.getAllSongsInPlaylistHandler  = this.loadGenrePlaylistDetails.bind(this);
        options.pageTemplate = PageTemplate;
        options.doNotAlphabecticallySort = true;
        const eventHandlerMap = {};
        this._detailedPlaylistInfoRetrieved = false;
        eventHandlerMap["click #startRadioBtn"] = this.onStartRadioButtonTapped.bind(this);
        eventHandlerMap["click div[data-role=header] #menuOptionButton"] = this.showOptionPopup.bind(this);
        eventHandlerMap["click div[data-role=header] #editButton"] = this.showEditPage.bind(this);
        eventHandlerMap["click #addSongsBtn"] = this.showGlobalSearch.bind(this);
        eventHandlerMap["click #shareTrebbleBtn"] = this.showShareTrebblePopup.bind(this);
        options.eventHandlerMap = eventHandlerMap;
        options.pageTemplateOptions = {"persistentPage": false, "ti18n": ti18n};
        options.customInfoBoxInfoParams = this._buildCustomInfoBoxParams();
        options.context = Utils.getInstance().buildContextForSongCollectionAutoRadio(options.playlistSummary.get("id"));
        return PlaylistDetailsController.prototype.initialize.call(this, options);
    
                    
    },
    
    _buildCustomInfoBoxParams  : function(){
        const params = {};
        params.iconClass = "ion-ios7-musical-notes";
        params.message = window.getI18n(ti18n, "THIS_TREBBLE_HAS_NO_SONG_YET");
        
        return params;
    },
    
    afterPageRendered : function(){
        const editButton$El = this.view.$el.find("#playPreviewBtn");
        const editButtonEl = editButton$El.get(0);
        if(editButtonEl){
            Hammer(editButtonEl, {drag_lock_to_axis: true}).on("press", this.onPreviewButtonPressed.bind(this), false);
            Hammer(editButtonEl, {drag_lock_to_axis: true}).on("pressUp", this.onPreviewButtonPressedUp.bind(this), false);
            //editButton$El.on("touchend",this.onPreviewButtonPressedUp.bind(this));
            //editButton$El.on("mouseover",this.onPreviewButtonPressed.bind(this));
            //editButton$El.on("mouseup",this.onPreviewButtonPressedUp.bind(this));
            editButton$El.hoverIntent(this.onPreviewButtonPressed.bind(this),this.onPreviewButtonPressedUp.bind(this));
        }
    },
    
    onPreviewButtonPressed : function(){
        this._previewButtonPressed = true;
        this.playPreview();
    },
    
    onPreviewButtonPressedUp : function(){
        if(this._previewButtonPressed){
            this._previewButtonPressed = false;
            this.stopPreview();
        }
    },
    showOptionPopup : function(){
        PersistentModels.getInstance().getRouter().showMyPersonalRadioMenuOption(this._playlist, true);
    },
    
    
    showEditPage : function(){
        PersistentModels.getInstance().getRouter().showTrebbleInfoEditPage(this._playlist, true);
    },
    
    playPreview : function(){
        PersistentModels.getInstance().getPlayer().playAudioPreview(this._playlist.getAudioPreviewUrl());
    },
    
    showGlobalSearch : function(){
        PersistentModels.getInstance().getRouter().showGlobalSearchPage(null, true);
    },
    
    showShareTrebblePopup : function(){
        return PersistentModels.getInstance().getRouter().showTrebbleSharePopup(this._playlist, null);
    },
    
    onStartRadioButtonTapped : function(){
        $.mobile.loading( 'show');
        
        this._retrieveDetailedPlaylistInfo().then((function(){
            return PersistentModels.getInstance().getPlayer().setPlaylist(this._playlist, true);
        }).bind(this)).then((function(){
            $.mobile.loading( 'hide');
            /*if(this._playlist){
                const loaderKey = this._playlist.getLoaderKey();
                const name = this._playlist.getName();
                const context = Utils.getInstance().buildContextForFollowingRadioDetail(loaderKey);
                TrebbleClientAPIHelper.getInstance().addTebbleLoadActionToMyHistory(loaderKey,name ,new Date(), null,context, null);
            }*/
        }).bind(this)).catch(function(error){
            $.mobile.loading( 'hide');
            console.error("Coudn't load playlist.Error:"+ error);
        window.alertErrorMessage(window.getI18n(ti18n,"COULDNT_LOAD_PLAYLIST")+ error);
        });
    },
    
    _retrieveDetailedPlaylistInfo : function(){
    //	if(!this._playlist){
            if(this._playlistRetrievalPendingPromise){
                return this._playlistRetrievalPendingPromise;
            }else{
                const p = PlaylistHelper.getInstance().getAutoPlaylistById(this.model.get("id")).then((function(playlist){
                    this._detailedPlaylistInfoRetrieved = true; 
                    this._playlistRetrievalPendingPromise = null;
                    this._playlist = playlist;
                    return this.playlist;
                }).bind(this));
                this._playlistRetrievalPendingPromise =  p;
                return this._playlistRetrievalPendingPromise;
            }
    /*	}else{
            return RSVP.Promise.resolve(this._playlist);
        }*/
    },
    
    loadGenrePlaylistDetails : function(){
        return this._retrieveDetailedPlaylistInfo().then((function(){
            if(this._playlist){
                if(this._playlist.load){
                    return this._playlist.retrieveAllAvailableSongsInJson();
                }else{
                    return this._playlist.toJSON();
                }
            }else{
                return [];
            }
            
        }).bind(this)).catch((function(error){
            console.error(window.getI18n(ti18n,"COULDNT_FIND_ANY_PLAYLIST_BY_ID"), error);
        window.alertErrorMessage(window.getI18n(ti18n,"COULDNT_FIND_ANY_PLAYLIST_BY_ID")+" '"+this._playlist.getLoaderKey() + "'. "+window.getI18n(ti18n,"ERROR") +error);
        }).bind(this));
        
    },

    

});
export default MyAutoRadioDetailsController;