import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import Levenshtein from "libs/levenshtein/levenshtein";
import TrebbleAudio from "models/TrebbleAudio";
import ti18n from "i18n!nls/helperi18n";
import RSVP from "rsvp";

const client_id = 'AMfhsXogG0lPV25fcTJU';
const client_secret = "9hmgw6HtTqd1UfMxZOglwUumMU6sYSzjJIDacCGE"
const DEFAULT_LEVENSTEIN_DISTANCE_ACCEPTABLE= 0.8;
const ACCEPTABLE_TIME_DIFFERENCE_IN_MILLISEC= 3000;
const FREESOUND_ID_PREFIX = "freesound:";
const FreesoundHelper =  Backbone.Model.extend({

    initialize : function() {

    },

    searchForSounds : function(query, pageIndex, numberOfresults){
        const params = {};
        params.query = query;
        params.page_size = numberOfresults;
        params.page = pageIndex + 1;
        params.fields = "id,url,name,tags,description,created,license,type,duration,username,previews";
        params.filter = "license:\"Creative Commons 0\"";
        return this._makeRequestToFreeSoundApi("/apiv2/search/text/", params).then((function(response){
            const trebbleAudioArray = [];
            if(response && response.count > 0){
                const results = response.results;
                for(let i =0; i < response.results.length; i++){
                    trebbleAudioArray.push(this._createTrebbleAudioFromFreesoundData(response.results[i]));
                }
            }
            return trebbleAudioArray;
        }).bind(this))
    },


    _createTrebbleAudioFromFreesoundData : function(freesoundData){
        const audioId = FREESOUND_ID_PREFIX + freesoundData.id;
        const title = freesoundData.description;
        const artist = freesoundData.username;
        const creationDate = new Date(freesoundData.created);
        const audioUrl = freesoundData.previews && freesoundData.previews["preview-hq-mp3"];
        return new TrebbleAudio({
            "audioId": audioId,
            "audioType": Utils.getInstance().getTrebbleAudioType().FREESOUND_SOUND,
            "title": title,
            "artist": artist,
            "creationDate": creationDate,
            "audioUrl": audioUrl
        })

    },


    _makeRequestToFreeSoundApi : function(apiEndpoint,params ){
        return new Promise(function(resolve, reject){
            try{
                const url =  "https://freesound.org"+apiEndpoint;
                if(!params){
                    params = {};
                }
                params.token = client_secret;
                $.ajax({
                    url: url,
                    type: 'GET',
                    data: params,
                    // Fetch the stored token from localStorage and set in the header
                    headers: {"Authorization": ""}
                }).done(resolve).fail(reject);
    }catch(error){
        reject(error);
    }
});
},




});
const freesoundHelperInstance = new FreesoundHelper();

export default {
	getInstance : function() {
		return freesoundHelperInstance;
	}
};
