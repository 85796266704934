import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";
import SongContextView from "views/common/SongContextView";

const SongContextController =  Chaplin.Controller.extend({


    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = options.model;
        this._doNotShowCoverArt = options.doNotShowCoverArt;
        this._doNotShowArtistName = options.doNotShowArtistName;
    },
    
    getModel : function(){
        return this.model;
    },
    
    getView : function(){
        return this.view;
    },
    


    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
            
                const viewParams = {"doNotShowArtistName": this._doNotShowArtistName,"doNotShowCoverArt": this._doNotShowCoverArt};
                viewParams.model  = this.model;
                const songContextView = new  SongContextView(viewParams);
                songContextView.render();
                this.view = songContextView;
                resolve(this);
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }
    

});
export default SongContextController;