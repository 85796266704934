import React from "react";
import { Skeleton } from "antd";
import ConfigProvider from "./ConfigProvider";
import useDefaultTrebbleAntDTokenConfig from "../helper/useDefaultTrebbleAntDTokenConfig";
import PropTypes from "prop-types";

const TrebbleSkeleton = ({useEditorTheme, ...restProps}) => {
  const themeConfig = useDefaultTrebbleAntDTokenConfig({useEditorTheme});

  return (
    <ConfigProvider theme={themeConfig}>
      <Skeleton {...restProps} />
    </ConfigProvider>
  );
};

TrebbleSkeleton.propTypes = {
  useEditorTheme: PropTypes.bool
}
export default TrebbleSkeleton;
