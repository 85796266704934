import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import SequencerLibrary from "models/audioEditor/SequencerLibrary";
import PauseAudioSegment from "models/audioEditor/segments/PauseAudioSegment";
import WordAudioSegment from "models/audioEditor/segments/WordAudioSegment";
import DeletedAudioSegment from "models/audioEditor/segments/DeletedAudioSegment";
import CloudAudioSegment from "models/audioEditor/segments/CloudAudioSegment";
import CloudVideoSegment from "models/audioEditor/segments/CloudVideoSegment";
import PunctuationAudioSegment from "models/audioEditor/segments/PunctuationAudioSegment";
import UnsupportedAudioSegment from "models/audioEditor/segments/UnsupportedAudioSegment";
import MusicAudioSegment from "models/audioEditor/segments/MusicAudioSegment";
import RSVP from "rsvp";


const audioSegmentContructors = [PauseAudioSegment,
    WordAudioSegment,
    DeletedAudioSegment,
    CloudAudioSegment,
    CloudVideoSegment,
    PunctuationAudioSegment,
    UnsupportedAudioSegment,
    MusicAudioSegment];
const AudioSegmentFactory =  Backbone.Model.extend({
    idAttribute: "id",


    createAudioSegmentFromSerializedData : function(audioSegmentJSON, additionalSpeakerInfoArray, generateSpeakerLabelIfDuplicate){
        const existingAudioSegment = SequencerLibrary.getInstance().getExistingAudioSegmentFromJSON(audioSegmentJSON);
        if(existingAudioSegment){
            return RSVP.Promise.resolve(existingAudioSegment);
        }else{
            const retrieveTranscribeAudioInstanceIfApplicablePromise = audioSegmentJSON.transcribedAudioInstance ? SequencerLibrary.getInstance().getTranscribeAudio(audioSegmentJSON.transcribedAudioInstance.audioUrl, audioSegmentJSON.transcribedAudioInstance.transcriptJsonURL, audioSegmentJSON.transcribedAudioInstance.audioBufferDurationInMilliseconds, audioSegmentJSON.transcribedAudioInstance.transcriptionId, audioSegmentJSON.transcribedAudioInstance.hasVideo, audioSegmentJSON.transcribedAudioInstance.speakerInfoSerializedDataArray, additionalSpeakerInfoArray, generateSpeakerLabelIfDuplicate, audioSegmentJSON.transcribedAudioInstance.languageCode, audioSegmentJSON.transcribedAudioInstance.videoWidth, audioSegmentJSON.transcribedAudioInstance.videoHeight): RSVP.Promise.resolve();
            return retrieveTranscribeAudioInstanceIfApplicablePromise.then((function(transcriptionInstance){
                if(transcriptionInstance){
                    audioSegmentJSON.transcribedAudioInstance = transcriptionInstance;
                }
                for(let i =0; i < audioSegmentContructors.length; i++){
                    const contructorClass = audioSegmentContructors[i];
                    if(audioSegmentJSON.type === contructorClass.CLASSNAME){
                        const newAudioSegmentInstance =  new contructorClass(audioSegmentJSON);
                        newAudioSegmentInstance.restoreFromSerializedData(audioSegmentJSON, additionalSpeakerInfoArray);
                        return newAudioSegmentInstance;
                    }
                }
            }).bind(this));
        }

    },

    


});


const audioSegmentFactoryInstance = new AudioSegmentFactory();

export default {
    getInstance : function() {
        return audioSegmentFactoryInstance;
    }
}; 