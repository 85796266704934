import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import Utils from "models/helper/Utils";
import TagsCollectionView from "views/common/TagsCollectionView";
import ti18n from "i18n!nls/Commoni18n";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PersistentModels from "services/PersistentModels";
import PlayerService from "services/PlayerService";
import RSVP from "rsvp";

const SongTagCollectionController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if(options)
        {
            if(options.model)
            {
                this.model = options.model;
            }
        }
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },
    onTagSelectedChange : function(selectedTagsId){
        this.trigger("selectedTagIdsChange",selectedTagsId);
    },
    

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                if(!this.model)
                {
                    throw window.getI18n(ti18n,"SONG_INFO_ARE_MISSING");
                }
                const allDefaultTagSetArray = PersistentModels.getInstance().getAllDefaultTagSetArray();
                const genreTagGroupId = PersistentModels.getInstance().getGeneralRatingTagGroupId();
                
                let genreTagGroup = null;
                for(let index in allDefaultTagSetArray){
                    const tagSet =allDefaultTagSetArray[index];
                    if(tagSet.getId() == genreTagGroupId){
                        genreTagGroup =tagSet;
                    }
                }
                const selectedIds =  this.model.get("tagIds")? this.model.get("tagIds") : [];
                genreTagGroup.setSelectedTagIds(selectedIds);
                this.view = new TagsCollectionView({
                    "tagGroup" : genreTagGroup,
                    "viewElementId":"songGradingView",
                    "allOptionTagAvalailable": true,
                    "doNotShowHashTag": true,
                    //"exclusive" : true,
                    //"selectedTagIds" : this.model.get("tagIds")? this.model.get("tagIds") : [],
                });
                this.listenTo(this.view, "selectedTagIdsChange",this.onTagSelectedChange);

                this.view.render();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default SongTagCollectionController;