import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import RSVP from "rsvp";

const TrebbleAudioCompilation =  Backbone.Model.extend({
    idAttribute: "audioId",

    getAudioCompilationId : function(){
        return this.get("audioCompilationId");
    },

    getTitle : function(){
        return this.get("title");
    },

    setTitle : function(newTitle){
        return this.set("title", newTitle);
    },

    getDescription : function(){
        return this.get("description");
    },

    setDescription : function(description){
        return this.set("description", description);
    },

    getCreationDate : function(){
        return new Date(this.get("creationDate"));
    },

    getAudioCompilationType : function(){
        return this.get("audioCompilationType");
    },

    isActive : function(){
        return this.get("active");
    },

    getAudioUrl : function(){
        return this.get("audioUrl");
    },

    getImageArtUrl : function(){
        return this.get("imageArtUrl");
    },

    getAlbumArt: function(){
        return this.get("imageArtUrl");
    },

    getAlbumArtImages: function(){
        return null;
    },


    getCanBeDeleted : function(){
        return this.get("canBeDeleted");
    },

    getCanBeFavorited : function(){
        return this.get("canBeFavorited");
    },

    setSelected :function(isSelected){
        this.set("selected",isSelected);
    },

    isSelected :function(){
        return this.get("selected")
    },	

    setModelSourceReference: function(modelSourceReference){
            this.set("modelSourceReference",modelSourceReference);
    },

    getModelSourceReference: function(){
            return this.get("modelSourceReference");
    },



    
});
export default TrebbleAudioCompilation;