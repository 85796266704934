
import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import TranscriptionMinutesRemaningWidgetView from "views/common/TranscriptionMinutesRemaningWidgetView";
import TranscriptionManager from "models/helper/TranscriptionManager";
import SubscriptionManager from "models/helper/SubscriptionManager";
import StripeHelper from "models/helper/StripeHelper";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import RSVP from "rsvp";

const Rollout = RolloutHelper.getInstance();

const TranscriptionMinutesRemaningWidgetController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this._transcriptionInfo = null;
        this.listenTo(TranscriptionManager.getInstance(),TranscriptionManager.getInstance().eventTypes.TRANSCRIPTION_MINUTES_CHANGED, this._onTranscriptionMinutesPossibleChanged.bind(this));
        this.listenTo(LocalStorageHelper.getInstance(),LocalStorageHelper.getInstance().eventTypes.USER_INFO_UPDATED, this.fetchTranscriptionInfoAndUpdate.bind(this));

    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    fetchTranscriptionInfoAndUpdate : function(){
        if(LocalStorageHelper.getInstance().isCurrentLoggedUserNotAVisitor() && !LocalStorageHelper.getInstance().isLoggedInUserAListener()){
            return TrebbleClientAPIHelper.getInstance().getNumberOfMinutesOfTranscriptionLeft().then(this._onTranscriptionMinutesPossibleChanged.bind(this));
        }else{
            this._onTranscriptionMinutesPossibleChanged();
        }
    },
    
    getTranscriptionMinutesLeft: function(){
        return this._transcriptionInfo && this._transcriptionInfo.transcriptionMinutesLeft;
    },

    getTotalMinutesAvailablePerMonth: function(){
        return this._transcriptionInfo && this._transcriptionInfo.totalMinutesAvailablePerMonth;
    },


    hasActivePaidSubscription: function(){
        return this._transcriptionInfo && this._transcriptionInfo.hasActivePaidSubscription;
    },

    _onTranscriptionMinutesPossibleChanged : function(transcriptionInfo){
        if(transcriptionInfo && LocalStorageHelper.getInstance().isCurrentLoggedUserNotAVisitor() && !LocalStorageHelper.getInstance().isLoggedInUserAListener()){
            this._transcriptionInfo = transcriptionInfo;
            this.view.showTranscriptionMinutesRemaning(true, transcriptionInfo.transcriptionMinutesLeft, transcriptionInfo.totalMinutesAvailablePerMonth,transcriptionInfo.hasActivePaidSubscription);
            this.trigger("transcriptionInfoUpdated");
        }else{
            this.view.showTranscriptionMinutesRemaning(false);
        }
    },


    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {

                this.view = new TranscriptionMinutesRemaningWidgetView({});
                this.view.showTranscriptionMinutesRemaning(false);
                this.view.render();
                this.fetchTranscriptionInfoAndUpdate();

                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default TranscriptionMinutesRemaningWidgetController;