import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import popupTemplate from 'text!../../../templates/audioEditor/TrimSilencesContextMenuTemplate.html';
import ti18n from "i18n!nls/Sequenceri18n";
import Mousetrap from 'mousetrap';

const TrimSilencesContextMenuContentView = Backbone.View.extend({


    initialize : function(options) {
        
        this.compiledTemlate =  _.template(popupTemplate);
        this.model = options? options.model : null;
        this.findSilences = options? options.findSilences: false;
        this._templateParams = {"model" : this.model, "ti18n": ti18n ,findSilences: this.findSilences};
    },
    
    events : {
    
    },

    setNumberOfPauses : function(numberOfPauses){
        return this.numberOfPauseInput$el.val(numberOfPauses);
    },

    getNumberOfPauses : function(){
        return this.numberOfPauseInput$el.val();
    },

    onBeforeDestroyed : function(){
        if(this._mousetrapObj){
            this._mousetrapObj.unbind("enter");
        }
        return true;
    },

    onNumberOfPauseChange : function(){
        let pauseIconsString = "";
        let numberOfPauses = this.getNumberOfPauses();
        if(numberOfPauses< 0){
            numberOfPauses = 0;
            this.numberOfPauseInput$el.val(numberOfPauses);
        }
        for(let i =0; i < numberOfPauses; i++){
            pauseIconsString = pauseIconsString + " · ";
        }
        this.pauseIconsDisplayContainer$el.html(pauseIconsString);
    },

    onTrimSilencesButtonClicked :function(event){
        if(!$(event.target).hasClass("disabled")){
            this.trigger("trimSilences");
        }
    },
    
    getModel: function(){
        return this.model;
    },

    render : function() {
        
        this.$el.html(this.compiledTemlate(this._templateParams));
        this.setElement(this.$el.find("section.trim_silences_context_menu_container").first());

        this.pauseIconsDisplayContainer$el =  this.$el.find("#pause_icons_display_container");
        this.trimSilenceBtn$el = this.$el.find("#trim_silence_btn");
        this.numberOfPauseInput$el =  this.$el.find("input#number_of_pauses_input");
        this.numberOfPauseInput$el.on("change", this.onNumberOfPauseChange.bind(this));
        this.trimSilenceBtn$el.on("click", this.onTrimSilencesButtonClicked.bind(this));
        this.setNumberOfPauses(3);
        this.onNumberOfPauseChange();
        this._mousetrapObj = Mousetrap(this.$el.find(".trim_silences_context_menu_container").get(0)).bind('enter', (function(e, combo) {
            this.onTrimSilencesButtonClicked(e);
        }).bind(this));

        return this;
    }

    
    

});
export default TrimSilencesContextMenuContentView;