

import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import StartCategoryShortcastRadioPopupView from "views/startCategoryShortcastRadio/StartCategoryShortcastRadioPopupView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import PlaylistHelper from "models/helper/PlaylistHelper";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import FacebookEventTrackingHelper from "models/helper/FacebookEventTrackingHelper";
import Utils from "models/helper/Utils";
import ListItemCollection from "collections/ListItemCollection";
import RSVP from "rsvp";

const Rollout = RolloutHelper.getInstance();
const USE_APP_LANGUAGE = Rollout.getFeatureVariable(Rollout.FEATURES.CREATE_SHORTCAST_CATEGORY_RADIO,Rollout.FEATURES.CREATE_SHORTCAST_CATEGORY_RADIO.variables.use_app_language, false);
const MIN_NUM_OF_SHORTCAST_PER_CATEGORY_TO_DISPLAY = Rollout.getFeatureVariable(Rollout.FEATURES.CREATE_SHORTCAST_CATEGORY_RADIO,Rollout.FEATURES.CREATE_SHORTCAST_CATEGORY_RADIO.variables.minimum_number_of_shortcast_available_per_category_to_display, 4);
const CATEGORY_IDS_TO_EXCLUDE_STRING = Rollout.getFeatureVariable(Rollout.FEATURES.CREATE_SHORTCAST_CATEGORY_RADIO,Rollout.FEATURES.CREATE_SHORTCAST_CATEGORY_RADIO.variables.category_ids_to_exclude,"Local|Astrology");
const CATEGORY_IDS_SEPARATOR =   Rollout.getFeatureVariable(Rollout.FEATURES.CREATE_SHORTCAST_CATEGORY_RADIO,Rollout.FEATURES.CREATE_SHORTCAST_CATEGORY_RADIO.variables.category_ids_separator,"|");
const CATEGORY_IDS_TO_EXCLUDE_ARRAY = CATEGORY_IDS_TO_EXCLUDE_STRING? CATEGORY_IDS_TO_EXCLUDE_STRING.split(CATEGORY_IDS_SEPARATOR): [];
const StartCategoryShortcastRadioPopupController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        //this._categoriesSeedArray = [];
        this._languageToCategoryIdToNumberOfShortcastMap = {};
        this._categoryListItemCollectionToDisplay = null;
    },


    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },


    onPopupClosed : function(){

    },


    addCategoryToSeedArray : function(category){
        this._categoriesSeedArray.push(category);
    },
    
    removeCategoryFromSeedArray : function(category){
        const categoryIndex =  this._categoriesSeedArray.indexOf(category);
        if(categoryIndex > -1){
            this._categoriesSeedArray.splice(categoryIndex, 1);
        }
    },

    startCategoryShortcastRadio : function(categoryId){

        if(categoryId){
            this.setViewLoading(true);
            FacebookEventTrackingHelper.getInstance().trackCategoryShortcastRadioPlayed(categoryId);
            if (window.trebbleAnalyticsHelper) {
                window.trebbleAnalyticsHelper.trackEvent("categoryShortcastRadio", 'CategoryShortcastRadioPlayed', 'Category Shortcast Radio Played', null, {"categoryId":categoryId});
                if(window.trebbleAnalyticsHelper.trebbleHelperTrackPlayedShortcastCategoryPlayed){
                    window.trebbleAnalyticsHelper.trebbleHelperTrackPlayedShortcastCategoryPlayed();
                }
            }


            const selectedLanguage = USE_APP_LANGUAGE? window.getAppUsedLanguage(): "en";
            return PersistentModels.getInstance().getPlayer().setPlaylist(PlaylistHelper.getInstance().generateCategoryShortcastRadioPlaylist(categoryId, selectedLanguage), true).then((function() {
                //this.setViewLoading(false);
                this.view.close();
            }).bind(this)).catch((function(error) {
                this.setViewLoading(false);
                window.alertErrorMessage(error);
            }).bind(this));
        }
    },

    setViewLoading :function(isLoading){
        this.view.isLoading(isLoading);
    },

    _getCategoryIdsAvailableForShortcastRadio : function(language){
        //THIS FUNCTION WILL CACHED THE RESULT AND WILL USE IT IN THE SAME SESSION UNTIL THE APP IS RELOADED. THIS DONE FOR PERFORMANCE REASON
        if(!this._languageToCategoryIdToNumberOfShortcastMap[language]){
            return TrebbleClientAPIHelper.getInstance().getCategoryIdsAvailableForShortcastRadio(language).then((function(categoryIdToNumberOfTrebbleMap){
                this._languageToCategoryIdToNumberOfShortcastMap[language] = categoryIdToNumberOfTrebbleMap;
                return categoryIdToNumberOfTrebbleMap;
            }).bind(this));
        }else{
            return RSVP.Promise.resolve(this._languageToCategoryIdToNumberOfShortcastMap[language]);
        }
    },

    retrieveAndDisplayCategories : function(){
        this.setViewLoading(true);

        const selectedLanguage = USE_APP_LANGUAGE? window.getAppUsedLanguage(): "en";
        return this._getCategoryIdsAvailableForShortcastRadio(selectedLanguage).then((function(categoryIdToNumberOfShortcastMap){
            const availableCategories = [];
            const allCategoryListItemArray = Utils.getInstance().getCapsuleCategoryListItemArray();
            for(let i =0; i <  allCategoryListItemArray.length; i++){
                const categoryItem = allCategoryListItemArray[i];
                if(categoryItem.getId() in categoryIdToNumberOfShortcastMap  && CATEGORY_IDS_TO_EXCLUDE_ARRAY.indexOf(categoryItem.getId()) == -1){
                    const numberOfShortcasts  = categoryIdToNumberOfShortcastMap[categoryItem.getId()];
                    if(numberOfShortcasts >= MIN_NUM_OF_SHORTCAST_PER_CATEGORY_TO_DISPLAY){
                        availableCategories.push(categoryItem);
                    }
                }
            }
            
            const listItemCollection = new ListItemCollection(availableCategories);
            listItemCollection.sort();
            this._categoryListItemCollectionToDisplay = listItemCollection; 
            this.view.setCategoriesListItemCollection(this._categoryListItemCollectionToDisplay);
            this.setViewLoading(false);

        }).bind(this)).catch((function(error){
            this.setViewLoading(false);
            throw error;
        }).bind(this));



    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {

                const params = {};
                this.view = new StartCategoryShortcastRadioPopupView(params);

                this.listenTo(this.view, "closed",this.onPopupClosed);
                //this.listenTo(this.view, "addCategoryToSeedArray",this.addCategoryToSeedArray);
                //this.listenTo(this.view, "removeCategoryFromSeedArray",this.removeCategoryFromSeedArray);
                this.listenTo(this.view, "startCategoryShortcastRadio",this.startCategoryShortcastRadio);

                this.retrieveAndDisplayCategories();
                this.view.render();
                
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default StartCategoryShortcastRadioPopupController;