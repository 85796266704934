import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import DynamicPageView from "views/common/DynamicPageView";
import Utils from "models/helper/Utils";
import ti18n from "i18n!nls/SendCapsuleToUserIntroPagei18n";
import PersistentModels from "services/PersistentModels";
import SendCapsuleToUserIntroPageTemplate from 'text!../../../templates/audioHighlight/SendCapsuleToUserIntroPageTemplate.html';

const SendCapsuleToUserIntroPageView = DynamicPageView.extend({

    //template : _.template(ExplorePageTemplate),

    initialize : function(options) {
        const dynamicPageOptions = {};
        this.model =  options? options.model: {};
        this._pageId = (options && options.pageId)? options.pageId:"EditUserInfoPage";
        dynamicPageOptions.pageViewTemplate = SendCapsuleToUserIntroPageTemplate;
        dynamicPageOptions.pageId = this._pageId;
        dynamicPageOptions.headerFullscren = true;
        dynamicPageOptions.persistent = true;
        dynamicPageOptions.pageTemplateOptions = {"ti18n": ti18n}
        dynamicPageOptions.onPageShow = this.onPageShow.bind(this);
        this._alwaysShowBackButton = options.alwaysShowBackButton;
        //PersistentModels.getInstance().getRouter().registerForViewportHeightChange(this._pageId,this._onViewportHeightChanged.bind(this));
        return DynamicPageView.prototype.initialize.call(this, dynamicPageOptions);  
    },

    events : {
        "click #header-back-button" : "navigateToPreviousPage",
        "click #startrecordingcapsuleBtn" : "showCreateCapsulePage",
    },
    
    showCreateCapsulePage : function(){
        this.trigger("showCreateCapsulePage");
    },

    setCapsuleSuccessfullyCreated : function(){
        this.$el.find(".capsuleSendIntroMessage").hide();
        this.$el.find("#progressBoxWrapper").hide();
    },

    setCapsuleBeingCreated : function(){
        this.$el.find(".capsuleSendIntroMessage").hide();
        this.$el.find("#progressBoxWrapper").show();
    },

    setIntroMessageForUser : function(userModel){
        this.model =  userModel;
        if(this.model.getProfileImageUrl()){
            this.$el.find("#userProfileImage").css("background-image","url('"+this.model.getProfileImageUrl()+"')");
        }
        if(this.model.getProfileImageUrlBlur()){
            this.$el.css("background-image","url('"+this.model.getProfileImageUrlBlur()+"')");
            this.$el.css("background-size","cover");
        }
        
        this.$el.find("[id=username]").html(this.model.getUsername());
        this.$el.find("#loadingImg").hide();
        this.$el.find(".capsuleSendIntroMessage").show();
    },	
    
    onPageShow : function(){
        this.trigger("pageShow");
        if(!this._alwaysShowBackButton && (PersistentModels.getInstance().getRouter().isCurrentPageHasSameUrlAsFirstPage())){
            //PLAYER WAS LAUNCH USING A SHARED TREBBLE/SONG/CAPSULE URL
            this.$el.find("#header-back-button").hide();
        }else{
            this.$el.find("#header-back-button").show();
        }
        if(!this._alwaysShowBackButton && (window.trebble.referrer == Utils.getInstance().buildContextForHomeScreen().type)){
            //PLAYER WAS LAUNCH FROM THE HOME SCREEN
            this.$el.find("#header-queue-button").hide();
        }
    },

    onBeforeRemoveFromPage : function(){
        DynamicPageView.prototype.onBeforeRemoveFromPage.call(this); 
    },

});
export default SendCapsuleToUserIntroPageView;