
import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerOperation from "models/audioEditor/sequencerOperation/SequencerOperation";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";


const TurnMagicSoundEnhancerOnOperation =  SequencerOperation.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperation.apply(this, [attributes, options]);
    },

    execute : function(turnMagicSoundEnhancerOn){
        this.getSequencer().getSequencerSettings().setMagicSoundEnhancerOn(turnMagicSoundEnhancerOn);
        this.getSequencer().getHistoryManagement().addTurnMagicSoundEnhancerOnOperationLog(turnMagicSoundEnhancerOn);
    },

    undo : function(sequencerOperationTurnMagicSoundEnhancerOnOperationLog ){
        this.getSequencer().getSequencerSettings().setMagicSoundEnhancerOn(!sequencerOperationTurnMagicSoundEnhancerOnOperationLog.getMagicSoundEnhancerOn());
    },

    redo: function(sequencerOperationTurnMagicSoundEnhancerOnOperationLog){
        this.getSequencer().getSequencerSettings().setMagicSoundEnhancerOn(sequencerOperationTurnMagicSoundEnhancerOnOperationLog.getMagicSoundEnhancerOn());
    },

});


export default TurnMagicSoundEnhancerOnOperation; 