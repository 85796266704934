var amdi18n={"__root":{"COULDNT_RETRIEVE_YOUTUBE_VIDEO_ID_FROM":"Couldn't retrieving video identifier from","RETRIEVING_SONG_INFO":"Retrieving Song Info ...","ADDING_SONG_TO_TREBBLE":"Adding Song To Your Channel ...","GRADING_SONG":"Grading Song ...","SONG_ADDED_TO_TREBBLE":"Song Added To Your Channel","SOUNDCLOUD_AND_YOUTUBE":"Soundcloud and Youtube!","ONLY_YOUTUBE_AND_SOUNDCLOUD_CAN_BE_ADDED":"Only Youtube videos and Soundcloud music can be added to your channel at the moment :(","OOPS_SOMETHING_WENT_WRONGD":"Oops. Something went wrong!"},"__fr":{"COULDNT_RETRIEVE_YOUTUBE_VIDEO_ID_FROM":"Impossible de récupérer l'identifiant de la vidéo youtube à partir de","RETRIEVING_SONG_INFO":"Récupération des informations sur la chanson ...","ADDING_SONG_TO_TREBBLE":"Ajout de la chanson à votre chaîne en cours ...","GRADING_SONG":"Chanson en cours de classement ...","SONG_ADDED_TO_TREBBLE":"Chanson ajoutée à cette chaîne","SOUNDCLOUD_AND_YOUTUBE":"Soundclound et youtube!","ONLY_YOUTUBE_AND_SOUNDCLOUD_CAN_BE_ADDED":"Seules les vidéos Youtube et la musique Soundcloud peuvent être ajoutées à votre chaîne pour le moment :(","OOPS_SOMETHING_WENT_WRONGD":"Oops. Quelque chose a mal tourné!"},"__en":{"COULDNT_RETRIEVE_YOUTUBE_VIDEO_ID_FROM":"Couldn't retrieving video identifier from","RETRIEVING_SONG_INFO":"Retrieving Song Info ...","ADDING_SONG_TO_TREBBLE":"Adding Song To Your Channel ...","GRADING_SONG":"Grading Song ...","SONG_ADDED_TO_TREBBLE":"Song Added To Your Channel","SOUNDCLOUD_AND_YOUTUBE":"Soundcloud and Youtube!","ONLY_YOUTUBE_AND_SOUNDCLOUD_CAN_BE_ADDED":"Only Youtube videos and Soundcloud music can be added to your channel at the moment :(","OOPS_SOMETHING_WENT_WRONGD":"Oops. Something went wrong!"}};amdi18n.init=function(language){
		// get the default language
		if(!language){
			if(typeof window !== 'undefined' && window._i18n && window._i18n.locale){
				language = window._i18n.locale;
			}else if(typeof document !== 'undefined' && document.documentElement.lang){
				language = document.documentElement.lang;
			}else if(typeof navigator !== 'undefined' && (navigator.languages || navigator.language || navigator.userLanguage)){
				// navigator.languages does not exist in IE 11
				language = (navigator.languages && navigator.languages[0]) ||
					navigator.language ||
					navigator.userLanguage;
				language = (language || 'root').toLowerCase();
			}else{
				language = 'root';
			}
		}
		var target = this['__' + language] || this.__root;

		// copy definition to root level
		if (target) {
			for (var name in target) {
				this[name] = target[name];
			}
		}

		// fallback to root
		var recursiveFallback = function(target, source){
			for(var name in source){
				switch(typeof target[name]){
				case 'undefined':
					target[name] = source[name];
					break;
				case 'object':
					recursiveFallback(target[name], source[name]);
				}
			}
		};
		recursiveFallback(this, this.__root);
	};amdi18n.init();module.exports=amdi18n;