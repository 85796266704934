import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";
import CapsuleContextView from "views/common/CapsuleContextView";

const CapsuleContextController =  Chaplin.Controller.extend({


    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = options.model;
    },
    
    getModel : function(){
        return this.model;
    },
    
    getView : function(){
        return this.view;
    },

    retrieveAndUpdateCoverArtUrl : function(){
        if(this.model.getCapsuleId()){
            return TrebbleClientAPIHelper.getInstance().getCapsuleCoverArtUrl(this.model.getCapsuleId()).then((function(coverArtUrl){
                if(coverArtUrl){
                    this.view.setCoverArtUrl(coverArtUrl);
                }
            }).bind(this))
        }else{
            return RSVP.Promise.resolve();
        }
    },
    


    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
            
                const viewParams = {};
                viewParams.model  = this.model;
                const capsuleContextView = new  CapsuleContextView(viewParams);
                capsuleContextView.render();
                this.retrieveAndUpdateCoverArtUrl();
                this.view = capsuleContextView;
                resolve(this);
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }
    

});
export default CapsuleContextController;