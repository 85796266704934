import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import Marquee from "libs/marquee/marquee";
import Blur from "libs/blur/blur";
import Player from "models/player/PlayerModel";

/*"models/helper/youtubeIframeAPI",*/
import Utils from "models/helper/Utils";

import KeyboardKeyHandlerHelper from "models/helper/KeyboardKeyHandlerHelper";
import PlayerService from "services/PlayerService";
import owlcarousel from "owlcarousel";
import PersistentModels from "services/PersistentModels";
import noUiSlider from "nouislider";
import RSVP from "rsvp";
import ti18n from "i18n!nls/PlayerPagei18n";
import songSocialInfoTemplate from 'text!../../../templates/common/songSocialInfo.html';
import playerTemplate from 'text!../../../templates/myplayer/playerEmbeddedPage.html';

const PRESS_HOLD_TRESHOLD = 1000;
const SLIDER_MAX_VALUE  = 100;
const HOVER_TRESHOLD = 1500;
const ENABLE_MARQUEE = true;
const PLAYER_NOT_READY_TO_PLAY_CLASS_NAME = "notReady";
const DEFAULT_BACKGROUND_IMAGE_URL = window.getResourceURLAccordingToLocationProtocol("/img/blackWhitebackgroundplayer.jpg");
const PlayerPageView = Backbone.View.extend({
    template : _.template(playerTemplate),

    events : {
        "click #playButton" : "playPauseCurrentSong",
        "click #nextButton" : "playNextSongInPlaylist",
        "click #previousButton" : "playPreviousSongInPlaylist",
        "click #loopButton": "togglePLayerLoopCurrentSong",
        "click #shuffleButton": "toggleShufflePlayerPLaylist",
        "click #playerSongMoreButton" : "onPlayerMenuButtonTapped",
        "click #playerMoreInformationButton" : "onPlayerMoreInformationButtonTapped",
        "click #resizeVideoFullBtn" : "onMakeVideoFullscreenBtnTapped",
        "click #header-queue-button": "showPlayerQueue",
        "click #songIsFavoriteBtn.addToTrebble" : "addSongToRadio",
        "click #songIsFavoriteBtn.removeFromTrebble" : "removeFromRadio",
        "click #header-back-button": "navigateToPreviousPage",
        "click  #tagsBtn" : "onTagButtonTapped",
        "click #infoButton" : "_onInfoButtonTapped",
        "click #liveLikeButton" : "_onLiveLikeButtonTapped",
        "click #converseButton" : "_onConversationButtonTapped",
        "click #capsuleButton" : "_onCapsuleButtonTapped",
        "click #sendCapsuleToHostButton" : "_onSendCapsuleToHostButtonTapped",
        "click #tuninButton" : "_onTuningButtonTapped",
        "click #songSocialInfoWrapper": "_onSongSocialInfoClicked",
        "click #viewCapsuleDetailsBtn" : "_onCapsuleButtonTapped",
        "click #exitVideoFullscreenBtn": "exitFullscreen",
        "click #mediaSourceBtn": "_onMediaSourceButtonClicked",
        "click #shareBtn": "showShareCurrentPlayingSongPopup",
        "click #shareSongBtn" :"showShareCurrentPlayingSongPopup",

    },

    initialize : function() {
        
        if (this.model) {
            this.listenTo(this.model, 'songChanged',this.onSongChange);
            this.listenTo(this.model,'songCurrentPositionChanged',this.onSongCurrentPositionChanged);
            this.listenTo(this.model, 'songStartedPlaying', this.onSongStartedPlaying);
            this.listenTo(this.model, 'onAlbumCoverUpdate', this.onAlbumCoverUpdate);
            //this.listenTo(this.model, 'youtubeSongStartedPlaying', this.onYoutubeSongStartedPlaying);
            this.listenTo(this.model, 'queuePlaylistChanged', this.onPlaylistChanged);
            this.listenTo(this.model, 'queuePlaylistNameChanged', this.onPlaylistNameChanged);
            this.listenTo(this.model, 'songPaused', this.updatePlayPauseButtonClass);
            this.listenTo(this.model, 'songTagsChanged', this.onSongTagsChanged);
            this.listenTo(this.model, 'songLoading', this._onSongLoading);
            this.listenTo(this.model, 'playerLoading', this.updatePlayPauseButtonClass);
            this.listenTo(this.model, 'playerFinishLoading', this.updatePlayPauseButtonClass);
            this.listenTo(this.model, 'songPreviewLoaded', this._onSongPreviewLoaded);
            this.listenTo(this.model, 'songPreviewUnLoaded', this._onSongPreviewUnLoaded);
            this.listenTo(this.model, 'songPreviewCancelled', this._onSongPreviewUnLoaded);
            this.listenTo(this.model, 'songLoaded', this._onSongFinishLoading);
            this.listenTo(this.model, 'songFailedLoading', this._onSongFailedLoading);
            this.listenTo(this.model, 'failedLoading', this._onPlayerFailedLoading);
            this.listenTo(this.model, 'durationChange', this._onDurationChanged);
            this.listenTo(this.model, 'downloading', this._onDownloading);
            this.listenTo(this.model, 'liveLikeButtonIncremented', this._onLiveLikeButtonIncremented);
            
            this.listenTo(this.model, 'error', this._onPlayerError);
            this.listenTo(this.model, "playerTypeChanged", this._onPlayerTypeChanged);
            
            this._currentSongPlayingCached = null;
            this._songTypeLabel = null;
            this._registerForKeyboardEventIfApplicable();
            this._setupHandlerOnExitFullscreen();
            this._liveLikeButton$el = null;
            this._playerType =  Player.types.SIMPLE_PLAYER;

        }
        const onOrientationChangeFunction  = function(){
            let isLandscape = false;
            switch(window.orientation) 
            {  
                case -90:
                case 90:
                //  window.alertErrorMessage('landscape');
                isLandscape = true;

                break; 
                default:
                //  window.alertErrorMessage('portrait');
                isLandscape = false;
                break; 
            }
            const coverArtPlayerWrapperElem = this.$el.find("#coverArtPlayerWrapper").get(0);
            const albumArtImage$el = this.$el.find("#albumArtImage");
            let coverArtWidth = 0;
            let coverArtHeight = 0;
            if(ENABLE_MARQUEE){
                this.applyMarqueeOnTitleIfApplicable(this.$el.find("#playerCurrentSongTitle"));
                this.applyMarqueeOnTitleIfApplicable(this.$el.find("#playerCurrentArtist")); 
                this.applyMarqueeOnTitleIfApplicable(this.$el.find("#playlistNameWrapper"));
            }
            if(coverArtPlayerWrapperElem){
                if(window.getDocumentClienHeight() * 2 < window.getDocumentClienWidth()){
                    coverArtHeight = (window.getDocumentClienHeight() - 20);
                    coverArtWidth = (window.getDocumentClienHeight() - 20);
                    coverArtPlayerWrapperElem.style.width = coverArtWidth +"px" ;//(window.getDocumentClienWidth() ) +"px";
                    
                    coverArtPlayerWrapperElem.style["flex"] = "0 0  "+ coverArtHeight +"px";
                    coverArtPlayerWrapperElem.style["-webkit-flex"] = "0 0  "+  coverArtHeight +"px";
                    coverArtPlayerWrapperElem.style.height = "100%";
                }else{
                    coverArtWidth = window.getDocumentClienWidth();
                    coverArtHeight =  (window.getDocumentClienHeight() -127 -20);
                    coverArtPlayerWrapperElem.style.width = "100%" ;//(window.getDocumentClienWidth() ) +"px";
                    coverArtPlayerWrapperElem.style["flex"] = "0 0 "+ coverArtHeight +"px";
                    coverArtPlayerWrapperElem.style["-webkit-flex"] = "0 0  "+ coverArtHeight +"px";
                    coverArtPlayerWrapperElem.style.height =  coverArtHeight +"px";
                }
                const smallerBetweenCoverWidthAndCoverHeight = coverArtHeight < coverArtWidth? coverArtHeight: coverArtWidth;
                albumArtImage$el.css("width",smallerBetweenCoverWidthAndCoverHeight +"px");
                albumArtImage$el.css("height",smallerBetweenCoverWidthAndCoverHeight +"px");
                
                const coverArtImageElem  = coverArtPlayerWrapperElem.querySelector("#albumArtImage");
                if(coverArtImageElem)
                {
                    if(isLandscape)
                    {
                        coverArtImageElem.className = "playerCoverArtImage-landscape";
                    }else{
                        coverArtImageElem.className = "playerCoverArtImage-portrait";
                    }
                }
            }
        };
        window.addEventListener('orientationchange', onOrientationChangeFunction.bind(this));
        window.addEventListener('resize', onOrientationChangeFunction.bind(this));
        this._onOrientationChangeFunction = onOrientationChangeFunction.bind(this);

        this._currentSongSocialInfo = null;

    },

    exitFullscreen : function(){
        try{
            if(document.exitFullscreen) {
                document.exitFullscreen();
            } else if(document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if(document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        }catch(error){
            console.error("Failed exiting fullscreen. Error:"+error);
        }
    },

    preloadBackgroundImage : function(){
        return 	Utils.getInstance().preloadImageUsingUrl(DEFAULT_BACKGROUND_IMAGE_URL);
    },
    
    _registerForKeyboardEventIfApplicable : function(){
        /*KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent("shift+left",(function(e, eventName){
            this.playPreviousSongInPlaylist();
        }).bind(this));
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent("shift+right",(function(e, eventName){
            this.playNextSongInPlaylist();
        }).bind(this));
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent("shift+c",(function(e, eventName){
            this._onConversationButtonTapped();
        }).bind(this));
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent("shift+a",(function(e, eventName){
            this.addToRadio();
        }).bind(this));
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent("shift+r",(function(e, eventName){
            this.removeFromRadio();
        }).bind(this));
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent("shift+g",(function(e, eventName){
            this.onTagButtonTapped();
        }).bind(this));
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent("shift+m",(function(e, eventName){
            this.onPlayerMenuButtonTapped();
        }).bind(this));
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent("shift+i",(function(e, eventName){
            this._onInfoButtonTapped();
        }).bind(this));
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent("shift+l",(function(e, eventName){
            this._onLiveLikeButtonTapped();
        }).bind(this));
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent("shift+q",(function(e, eventName){
            this.showPlayerQueue();
        }).bind(this));
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent("shift+t",(function(e, eventName){
            this._onTuningButtonTapped();
        }).bind(this));
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent("space",(function(e, eventName){
            this.playPauseCurrentSong();
        }).bind(this));*/
    },

    _setupHandlerOnExitFullscreen : function(){

        if (document.addEventListener)
        {
            document.addEventListener('webkitfullscreenchange', this._onExitFullscreen.bind(this), false);
            document.addEventListener('mozfullscreenchange', this._onExitFullscreen.bind(this), false);
            document.addEventListener('fullscreenchange', this._onExitFullscreen.bind(this), false);
            document.addEventListener('MSFullscreenChange', this._onExitFullscreen.bind(this), false);
        }
    },

    _onExitFullscreen : function(event){
        if(!this.isFullscreen()){
            this.$el.addClass("fullscreen");
        }else{
            this.$el.removeClass("fullscreen");
        }
    },

    isFullscreen : function(){
        return this.$el.hasClass("fullscreen");
    },



    _setupTapAndPressHanlderOnSkipButton: function(){
        Utils.getInstance().addTapAndPressHandler(this.$el.find("#nextButton").get(0), PRESS_HOLD_TRESHOLD, null, this._onPressOnSkipButton.bind(this), HOVER_TRESHOLD, this._onPressOnSkipButton.bind(this));
    },
    
    _onPressOnSkipButton : function(){
        if(this.model.getPlayerType() == Player.types.TREBBLE_PLAYER || this.model.getPlayerType() == Player.types.CAPSULE_FEED_PLAYER || this.model.getPlayerType() == Player.types.CATEGORY_SHORTCAST_RADIO){
            this.trigger("skipCurrentSongWithReason", this.$el.find("#nextButton"));
        }
    },

    _onSongSocialInfoClicked : function(){
        if(this._currentSongSocialInfo){
            if(this._currentSongSocialInfo && this._currentSongSocialInfo.user && this._currentSongSocialInfo.user){
                this.trigger("showUserProfileWithUsername",this._currentSongSocialInfo.user.username);
            }
        }
    },
    

    _updateMediaSourceButton : function(){
        let mediaType = "";
        if(this.model.isCurrentMediaTypeSoundcloud()){
            mediaType = window.getI18n(ti18n, "VIA_SOUNDCLOUD");
        }
        if(this.model.isCurrentMediaTypeYoutubeVideo()){
            mediaType = window.getI18n(ti18n, "VIA_YOUTUBE");
        }
        const mediaSourceBtn$el = this.$el.find("[id=mediaSourceBtn]");
        mediaSourceBtn$el.html(mediaType);
        if(mediaType == ""){
            mediaSourceBtn$el.hide();
        }else{
            //mediaSourceBtn$el.show();
            mediaSourceBtn$el.hide();
        }
    },
    
    _onMediaSourceButtonClicked : function(){
        this.model.openExternalSongPage();
    },
    
    
    

    _onUserSliderPosition: function(formatedValues,handleNumber, unformattedValues ){
        const newValue = unformattedValues && unformattedValues.length > 0? unformattedValues[0]: this._playerSliderEl.noUiSlider.get();
        this.trigger("userMoveSlider", newValue);
    },
    _onPlayerFailedLoading : function(failedLoadingSong, tryToLoadSongAgain){
        let errorMessage = null;
        if(failedLoadingSong){
            errorMessage = window.getI18n(ti18n, "THE_SONG")+" \"" +failedLoadingSong.get("title") + " - " + failedLoadingSong.get("artist") + "\" "+window.getI18n(ti18n, "FAILED_LOADING");
        }else{
            errorMessage = window.getI18n(ti18n, "SONG_FAILED_LOADING");
        }
        const isSoundcloudUri = Utils.getInstance().isSoundcloudUri(failedLoadingSong.get("uri"));
        const isYoutubeUri = Utils.getInstance().isYoutubeUri(failedLoadingSong.get("uri"));

        if(tryToLoadSongAgain){
            if(isSoundcloudUri){
                errorMessage = errorMessage + window.getI18n(ti18n, "THIS_SOUNDCLOUD_LINK_IS_NOT_AVAILABLE_ANYMORE");
            }
            if(isYoutubeUri){
                errorMessage = errorMessage + window.getI18n(ti18n, "THIS_YOUTUBE_LINK_IS_NOT_AVAILABLE_ANYMORE");
            }
            errorMessage = errorMessage + window.getI18n(ti18n, "TRYING_TO_FIND_ANOTHER_LINK");
            Utils.getInstance().showWarningNotification(errorMessage);
        }else{
            if(!isYoutubeUri && !isSoundcloudUri){
                errorMessage = errorMessage + window.getI18n(ti18n, "WE_COULDNT_FIND_A_PLAYABLE_LINK");
            }
            errorMessage = errorMessage + window.getI18n(ti18n, "LETS_SKIP_TO_THE_NEXT_SONG");
            Utils.getInstance().showErrorNotification(errorMessage);
        }
        
        //$.notify(errorMessage, {"position":"top","className" : "error"});
    },
    
    _onInfoButtonTapped : function(){
        this.trigger("showSongInfo");
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent('Player Page Events', 'Song Info Button Tapped', 'Song Info Button Tapped', null, this.model.getQueueContext());
        }
    },
    
    _onLiveLikeButtonTapped : function(){
        this.trigger("liveLikeButtonTapped");
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent('Player Page Events', 'Live Like Button Tapped', 'Live Like Button Tapped',null, this.model.getQueueContext());
        }
    },
    
    _onLiveLikeButtonIncremented : function(increment){
        if(this._liveLikeButton$el){
            Utils.getInstance().addVolatileCounterToElement(this._liveLikeButton$el.get(0), "pe-7s-like",increment);
        }
    },
    
    _onConversationButtonTapped : function(){
        this.trigger("showConversationOnSong");
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent('Player Page Events', 'Coversation Button Tapped', 'Coversation Button Tapped',null, this.model.getQueueContext());
        }
    },
    
    _onCapsuleButtonTapped : function(){
        this.trigger("showCapsules");
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent('Player Page Events', 'Capsule Button Tapped', 'Capsule Button Tapped',null, this.model.getQueueContext());
        }
    },

    _onSendCapsuleToHostButtonTapped : function(){
        this.trigger("showSendCapsuleToHost");
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent('Player Page Events', 'Send Capsule To Host Button Tapped', 'Send Capsule To Host  Button Tapped', null,this.model.getQueueContext());
        }
    },
    
    _onTuningButtonTapped : function(){
        this.trigger("showQueuetuning");
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent('Player Page Events', 'Queue Tuning Button Tapped', 'Queue Tuning Button Tapped',null, this.model.getQueueContext());
        }
    },
    _onPlayerError : function(error, songLoaded){
        const errorMessage = error;
        Utils.getInstance().showErrorNotification(errorMessage);
        //$.notify(errorMessage, {"position":"top","className" : "error"});
    },
    _onSongLoading : function(){
        this.updatePlayPauseButtonClass();
    },
    
    _onSongPreviewLoaded : function(){

        //Add class name to body
        if(document.body.className  && document.body.className.indexOf("isPreviewPlaying") == -1){
            document.body.className = document.body.className  + " isPreviewPlaying ";
        }
    },

    _triggerEventOnBody : function(eventName, data){
        try{
            document.body.dispatchEvent(new Event(eventName, {"data": data}));
        }catch(e){
            console.error(e);
        }
    },
    
    _onPlayerTypeChanged : function(newPlayerType){
        let playerMode = "defaultPlayer";
        switch(newPlayerType){
            case Player.types.TREBBLE_PLAYER:
            playerMode = "trebblePlayer";
            break;
            case Player.types.CAPSULE_SET_PLAYER:
            playerMode = "capsulePlayer";
            break;
            case Player.types.CAPSULE_FEED_PLAYER:
            playerMode = "capsuleFeedPlayer";
            break;
            case Player.types.CATEGORY_SHORTCAST_RADIO:
            playerMode = "categoryShortcastRadio";
            break;
            case Player.types.SONG_SET_PLAYER:
            playerMode = "songPlayer";
            break;
            case Player.types.SIMPLE_PLAYER:
            playerMode = "defaultPlayer";
            break;
        }
        this.$el.attr("playermode", playerMode);
        /*if(newPlayerType == Player.types.TREBBLE_PLAYER){
            //this.$el.find("#infoButton , #converseButton , #capsuleButton  ").show();
            this.$el.find("#liveLikeButton , #converseButton , #capsuleButton  ").show();
            this.$el.find("#loopButton, #previousButton,#shareBtn, #shuffleButton").hide();
        }else{
            if(newPlayerType == Player.types.SONG_SET_PLAYER){
                this.$el.find("#liveLikeButton , #converseButton , #shareBtn, #previousButton  ").show();
                this.$el.find("#loopButton, #capsuleButton , #shuffleButton, #nextButton").hide();
            }else{
                if(newPlayerType == Player.types.CAPSULE_SET_PLAYER){
                    this.$el.find("#liveLikeButton , #converseButton ,#shareBtn ").show();
                    this.$el.find("#loopButton, #previousButton, #capsuleButton,#shuffleButton").hide();
                }else{
                    if(newPlayerType == Player.types.SIMPLE_PLAYER){
                        //this.$el.find("#infoButton , #converseButton , #capsuleButton  ").hide();
                        this.$el.find("#liveLikeButton , #converseButton , #capsuleButton  , #shareBtn").hide();
                        this.$el.find("#loopButton, #previousButton, #shuffleButton").show();
                    }else{
                        //this.$el.find("#infoButton , #converseButton , #capsuleButton  ").hide();
                        this.$el.find("#liveLikeButton , #converseButton , #capsuleButton,#shareBtn  ").hide();
                        this.$el.find("#loopButton, #previousButton, #shuffleButton").show();
                    }
                }
            }
        }*/
        this._playerType = newPlayerType;
        this._onOrientationChangeFunction();
        this._triggerEventOnBody("playerTypeChange",{"playerType": newPlayerType});
    },
    
    _onSongPreviewUnLoaded : function(){
        //$.mobile.loading( 'hide');
        //remove class name to body
        if(document.body.className  && document.body.className.indexOf("isPreviewPlaying") != -1){
            $(document.body).removeClass("isPreviewPlaying");
        }
    },


    
    _onSongFinishLoading : function(){
        this.updatePlayPauseButtonClass();
    },
    
    _onSongFailedLoading : function(error){
        this.updatePlayPauseButtonClass();
    },
    
    navigateToPreviousPage : function(){
        PersistentModels.getInstance().getRouter().navigateToPreviousPage();
    },
    
    onMakeVideoFullscreenBtnTapped  : function(){
        const elementToBringFullscreen = document.body;
        const webkitRequestFullscreenFunc = elementToBringFullscreen.requestFullscreen || elementToBringFullscreen.webkitRequestFullscreen || elementToBringFullscreen.mozRequestFullScreen || elementToBringFullscreen.msRequestFullscreen;
        if(webkitRequestFullscreenFunc){
            webkitRequestFullscreenFunc.bind(elementToBringFullscreen)();
        }

    },


    setSongBeingAddedOrRemovedFromTrebble : function(songBeingAddedOrRemoved, addedToTrebble){
        const addedToRadioIconBtn = this.$el.find("#songIsFavoriteBtn > t");
        const addedToRadioIconWrapperBtn = this.$el.find("#songIsFavoriteBtn");
        const playerSongMoreBtn = this.$el.find("#playerSongMoreButton");
        
        if(songBeingAddedOrRemoved){
            if(songBeingAddedOrRemoved != addedToRadioIconBtn.hasClass("ion-loading-c"))
            {
                addedToRadioIconBtn.addClass("ion-loading-c");
                if(addedToRadioIconBtn.hasClass("ion-ios7-checkmark-outline")){
                    addedToRadioIconWrapperBtn.attr("data-hint", window.getI18n(ti18n, "REMOVING_FROM_TREBBLE"));
                }else{
                    addedToRadioIconWrapperBtn.attr("data-hint", window.getI18n(ti18n, "ADDING_TO_TREBBLE"));
                }
            }
        }else{
            addedToRadioIconBtn.removeClass("ion-loading-c");
            if(addedToTrebble){
                addedToRadioIconBtn.removeClass("ion-ios7-plus-empty");
                addedToRadioIconWrapperBtn.removeClass("addToTrebble");
                addedToRadioIconWrapperBtn.attr("data-hint", window.getI18n(ti18n, "REMOVE_FROM_TREBBLE"));
                if(!addedToRadioIconWrapperBtn.hasClass("removeFromTrebble")){
                    addedToRadioIconWrapperBtn.addClass("removeFromTrebble");
                }
                if(!addedToRadioIconBtn.hasClass("ion-ios7-checkmark-outline")){
                    addedToRadioIconBtn.addClass("ion-ios7-checkmark-outline");
                }
            }else{
                addedToRadioIconBtn.removeClass("ion-ios7-checkmark-outline");
                addedToRadioIconWrapperBtn.removeClass("removeFromTrebble");
                addedToRadioIconWrapperBtn.attr("data-hint", window.getI18n(ti18n, "ADD_TO_TREBBLE"));
                if(!addedToRadioIconWrapperBtn.hasClass("addToTrebble")){
                    addedToRadioIconWrapperBtn.addClass("addToTrebble");
                }
                if(!addedToRadioIconBtn.hasClass("ion-ios7-plus-empty")){
                    addedToRadioIconBtn.addClass("ion-ios7-plus-empty");
                }
            }
        }
        
        if(this._songTypeLabel === "Song" ||this._songTypeLabel === "Capsule"){
            addedToRadioIconWrapperBtn.show();
        }else{
            addedToRadioIconWrapperBtn.hide();
        }

        /*if(this._songTypeLabel === "Song"){
            playerSongMoreBtn.show();
        }else{
            playerSongMoreBtn.hide();
        }*/
    },
    
    addSongToRadio : function(){
        this.trigger("addSongToRadio");
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent('Player Page Events', 'Add Song To Radio Button Tapped', 'Add Song To Radio Button Tapped',null, this.model.getQueueContext());
        }
    },

    addCapsuleToRadio : function(){
        this.trigger("addCapsuleToRadio");
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent('Player Page Events', 'Add Capsule To Radio Button Tapped', 'Add Caspsule To Radio Button Tapped',null, this.model.getQueueContext());
        }
    },
    
    
    
    removeSongFromRadio : function(){
        this.trigger("removeFromSongFromRadio");
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent('Player Page Events', 'Remove Song To Radio Button Tapped', 'Remove Song To Radio Button Tapped',null, this.model.getQueueContext());
        }
    },

    removeCapsuleFromRadio : function(){
        this.trigger("removeCapsuleFromRadio");
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent('Player Page Events', 'Remove Capsule To Radio Button Tapped', 'Remove Capsule To Radio Button Tapped',null, this.model.getQueueContext());
        }
    },
    
    onAndroidRemoteControlButtonPressed : function(action){
        if(action == "next")
        {
            this.playNextSongInPlaylist();
        }else{
            if(action  == "previous")
            {
                this.playPreviousSongInPlaylist();
            }else{
                if(action == "playpause")
                {
                    this.playPauseCurrentSong(null,true);
                }
            }
        }
    },
    

    getPlaylist : function() {
        return this.model.getPlaylist();
    },

    onPlaylistChanged : function(newPlaylist) {
        this.trigger("queuePlaylistChanged", newPlaylist);
        this._setPlayerViewReadyToPlay(newPlaylist? true: false);
        this.$el.find("#playlistNameWrapper").html(newPlaylist?newPlaylist.getName(): null);
        this._triggerEventOnBody("playlistChange",{"playlistName": newPlaylist?newPlaylist.getName(): ""});
        this.$el.attr("canbesubscribedto", newPlaylist ?newPlaylist.canBeFollowed() : false);
        this.$el.attr("subscribed", newPlaylist?newPlaylist.isFollowing(): false);

    },
    
    onPlaylistNameChanged : function(newPlaylistName) {
        this.trigger("queuePlaylistNameChanged", newPlaylistName);
        this.$el.find("#playlistNameWrapper").html(newPlaylistName);
        this._triggerEventOnBody("playlistChange",{"playlistName": newPlaylistName});

    },

    
    onPlayerMenuButtonTapped : function(){
        this.trigger("onPlayerMenuButtonTapped");
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent('Player Page Events', 'Song Menu Option Button Tapped', 'Song Menu Option Button Tapped',null, this.model.getQueueContext());
        }
    },

    onPlayerMoreInformationButtonTapped : function(){
        this.trigger("onPlayerMoreInformationButtonTapped");
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent('Player Page Events', 'More Information  Option Button Tapped', 'More Information Button Tapped',null, this.model.getQueueContext());
        }
    },

    onTagButtonTapped : function(){
        this.trigger("showTagManagerForSongPlaying");
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent('Player Page Events', 'Song Grading Button Tapped', 'Song Grading Button Tapped',null, this.model.getQueueContext());
        }
    },
    onSongCurrentPositionChanged : function(newSongCurrentPosition) {
        if (this.$currentTrackPositionEl && this.model.isPlaying()) {
            this.model.getCurrentSongDuration().then((function(songDuration){
                if(songDuration == -1){
                    Utils.getInstance().showWarningNotification(window.getI18n(ti18n, "NO_SONG_TO_PLAY_IN_THE_CURRENT_POSITION"));
                    songDuration = 0
                }else{
                    this._onDurationChanged(songDuration);
                }
                songDuration = this._cachedCurrentSongDuration? this._cachedCurrentSongDuration: songDuration;
                if(songDuration && !this._playerSliderEl._isUserDragged){
                    const sliderVal = newSongCurrentPosition * SLIDER_MAX_VALUE / songDuration*1000; 
                    //this.$el.find(".ui-slider-input").val(sliderVal);
                    //this.$el.find(".ui-slider-input").slider('refresh');
                    //this._playerSlider$el.val(sliderVal) //cannot be use with MDL library
                    /*if(this._playerSlider$el[0].MaterialSlider){
                        this._playerSlider$el[0].MaterialSlider.change(sliderVal);
                    }else{
                        this._playerSlider$el.val(sliderVal)
                    }*/
                    this._playerSliderEl.noUiSlider.set(sliderVal);
                    this.$el.find("#songCurrentPosition").html(this.formatPositionToString(newSongCurrentPosition));	
                }

            }).bind(this));

}

},

showPlayerQueue : function(){
this.trigger("onQueueButtonTapped");
},

showShareCurrentPlayingSongPopup : function(){
return PlayerService.getInstance().showShareCurrentPlayingSongPopup(true);
},

onSongStartedPlaying : function() {
this.model.getCurrentSong().then((function(currentSong){
//this._onDurationChanged(currentSong.get("duration"));
//this.$el.find("#trackDuration").html(this.formatPositionToString(currentSong.get("duration")/1000));
this.updatePlayPauseButtonClass();
this._updateMediaSourceButton();
}).bind(this));


},

_onDurationChanged : function(newSongDuration){
if(newSongDuration && newSongDuration != -1){
this._cachedCurrentSongDuration = newSongDuration;
this.$el.find("#trackDuration").html(this.formatPositionToString(newSongDuration/1000));
}
},

_onDownloading : function(percentageDownload){
this._playerSlider$el[0].secondaryProgress = percentageDownload;
},
/*
onYoutubeSongStartedPlaying : function (videoJsonInfo){
if(document.getElementById("albumArtImage"))
{
document.getElementById("albumArtImage").style.display = "none";
}
const videosEls =  document.getElementsByTagName("video");
if(videosEls && videosEls.length >0){
for(let index in videosEls){
    const videoEl =videosEls[index];
    videoEl.style.display = "block";
}
}
if(document.getElementById("youtubeVideoLink"))
{
document.getElementById("youtubeVideoLink").style.display = "block";
}

const youtubeVideoLinkWrapper = document.getElementById("youtubeVideoLinkWrapper");
while (youtubeVideoLinkWrapper.firstChild) {
youtubeVideoLinkWrapper.removeChild(youtubeVideoLinkWrapper.firstChild);
}
youtubeVideoDiv = document.createElement('div');
youtubeVideoDiv.id = "youtubeVideoLink";
youtubeVideoLinkWrapper.appendChild(youtubeVideoDiv);

PersistentModels.getInstance().getRouter().showPlayerPage(true);

youtubeIframeAPI.getInstance().createYoutubePlayer(youtubeVideoLink, videoJsonInfo.videoId,"100%", "100%",true);

},
*/
formatPositionToString : function(trackPositionInSec) {
if (trackPositionInSec >= 0) {
const trackPositionInSecRounded = Math
.floor(trackPositionInSec);
const minutes = Math
.floor(trackPositionInSecRounded / 60);
const seconds = trackPositionInSecRounded - minutes
* 60;
let currentpositionString = "";
currentpositionString = currentpositionString
+ minutes + ":";
if (seconds < 10) {
    currentpositionString = currentpositionString
    + "0" + seconds;
} else {
    currentpositionString = currentpositionString
    + seconds;
}
return currentpositionString;
} else {
return "-:--";
}
},


addToRadio : function(){
if(this._songTypeLabel === "Capsule"){
return this.addCapsuleToRadio();
}
if(this._songTypeLabel === "Song"){
return this.addSongToRadio();
}
},

removeFromRadio : function(){
if(this._songTypeLabel === "Capsule"){
return this.removeCapsuleFromRadio();
}
if(this._songTypeLabel === "Song"){
return this.removeSongFromRadio();
}
},

onSongChange : function() {

    let currentSongPlaying = null;
    let currentArtistPlaying = null;
    let albumArtData = null;
    let songTypeLabel = null;
this._onDownloading(0);
this._onDurationChanged(0);
let albumArtSrc = window.getResourceURLAccordingToLocationProtocol("/img/Beyonce-4-Deluxe-Edition-2011-Album-Cover.png");
const p1=  this.model.getCurrentSong().then((function(currentSong){
    let isAddedToARadio = false;
this._currentSongPlayingCached = currentSong;
if(currentSong){
    const uri = currentSong.get("uri");
    songTypeLabel = "Song";
    const isSongACapsule = Utils.getInstance().isCapsuleUri(uri);
    if(isSongACapsule){
        songTypeLabel = "Capsule";
    }
    const isSongAJingle = Utils.getInstance().isJingleUri(uri);
    if(isSongAJingle){
        songTypeLabel = "Jingle";
    }
    const isSongAGreater = Utils.getInstance().isGreaterUri(uri);
    if(isSongAGreater){
        songTypeLabel = "Greater";
    }
    const isSongAIntro = Utils.getInstance().isIntroUri(uri);
    if(isSongAIntro){
        songTypeLabel = "Intro";
    }

    const isSongAOutro = Utils.getInstance().isOutroUri(uri);
    if(isSongAOutro){
        songTypeLabel = "Outro";
    }
    const isSongAEarcon = Utils.getInstance().isEarconUri(uri);
    if(isSongAEarcon){
        songTypeLabel = "Earcon";
    }
    const isSongATrebbleGeneratedSpeech = Utils.getInstance().isTrebbleGeneratedSpeechUri(uri);
    if(isSongATrebbleGeneratedSpeech){
        songTypeLabel = "TrebbleGeneratedSpeech";
    }

    

    this._songTypeLabel = songTypeLabel;
    this.$el.attr("audio_type_loaded", this._songTypeLabel);
    this.$el.attr("audio_can_be_interacted_with", (this._songTypeLabel === "Song" || this._songTypeLabel === "Capsule")? "true":"false");
}
if (currentSong != null) {
    this.$el.attr("no_audio_loaded", "false");
    currentSongPlaying = currentSong.get("title");
    currentArtistPlaying = currentSong.get("artist");
    isAddedToARadio = PersistentModels.getInstance().isSongModelInPlayerQueueAddedToUserTrebbles(currentSong);
    albumArtData = currentSong.get("albumArtData");
    if (albumArtData) {
        albumArtSrc = "data:image/png;base64,"+ albumArtData;
    } else {
        albumArtSrc = "";
    }
}else{
    this.$el.attr("no_audio_loaded", "true");
    currentSongPlaying = "";
    currentArtistPlaying = "";
    albumArtSrc = "";
}
this.setSongBeingAddedOrRemovedFromTrebble(false,isAddedToARadio);
if(currentSong){
    this.onSongTagsChanged(currentSong);
}
this.$el.find("#playerCurrentSongTitle").html(currentSongPlaying);
this.$el.find("#playerCurrentArtist").html(currentArtistPlaying);
const resizeBtn$el = $("#player * #resizeVideoFullBtn");
const videosEls =  document.getElementsByTagName("video");
if(this.model.isYoutubeVideoLoaded()){
    if(document.getElementById("albumArtImage"))
    {
        document.getElementById("albumArtImage").style.display = "none";
    }
    
    if(videosEls && videosEls.length >0){
        for(let index = 0;index++; index< videosEls.length){
            let videoEl =videosEls[index];
            videoEl.style.display = "block";
        }
    }
    if(document.getElementById("youtubeVideoLink"))
    {
        document.getElementById("youtubeVideoLink").style.display = "block";
    }
    if(!resizeBtn$el.hasClass("showOnHover")){
        resizeBtn$el.addClass("showOnHover");
    }
    /*if(document.getElementById("resizeVideoFullBtn"))
    {
        document.getElementById("resizeVideoFullBtn").style.display = "block";
    }*/
}else{
    if(document.getElementById("youtubeVideoLink"))
    {
        document.getElementById("youtubeVideoLink").style.display = "none";
    }
    resizeBtn$el.removeClass("showOnHover");
    /*if(document.getElementById("resizeVideoFullBtn"))
    {
        document.getElementById("resizeVideoFullBtn").style.display = "none";
    }*/
    if(videosEls && videosEls.length >0){
        for(let index = 0;index++; index< videosEls.length){
            let videoEl =videosEls[index];
            videoEl.style.display = "none";
        }
    }
    if(document.getElementById("albumArtImage"))
    {
        document.getElementById("albumArtImage").style.display = "block";
    }
}
if(ENABLE_MARQUEE){
    this.applyMarqueeOnTitleIfApplicable(this.$el.find("#playerCurrentSongTitle"));
    this.applyMarqueeOnTitleIfApplicable(this.$el.find("#playerCurrentArtist"));
    this.applyMarqueeOnTitleIfApplicable(this.$el.find("#playlistNameWrapper"));
}
//const duration = currentSong.get("duration");
//const formattedDurationString = this.formatPositionToString(duration/1000);
//this.$el.find("#trackDuration").html(formattedDurationString);

this.model.getCurrentSongDuration().then((function(duration){
    if(duration && duration!= -1){
        this._onDurationChanged(duration);
    }
    const formattedDurationString = this.formatPositionToString(duration/1000);
    this.$el.find("#trackDuration").html(formattedDurationString);
}).bind(this));
this._triggerEventOnBody("playerSongChange",{"songTypeLabel": songTypeLabel, "songAddedToRadio": isAddedToARadio, "songTitle": currentSongPlaying, "artistName": currentArtistPlaying,"albumArtSrc": albumArtSrc });
}).bind(this));
this._updateMediaSourceButton();
const p2 = this._updateSocialInfoIfApplicable();
const p3 = this._updateCapsuleInfoIfApplicable();
const p4 = this._updateCommentInfoIfApplicable();
const promiseArray = [];
promiseArray.push(p1);
promiseArray.push(p2);
promiseArray.push(p3);
promiseArray.push(p4);
return RSVP.Promise.all(promiseArray);

},

_updateCapsuleButton : function(hasActiveCapsule, numberOfCapsules){
if(hasActiveCapsule){
this.$el.find("#reponsivePlayerContent").addClass("activeCapsule");
this.$el.find("#capsuleButton > .ownerDataBubble").addClass("unconsumed").show();
}else{
this.$el.find("#reponsivePlayerContent").removeClass("activeCapsule");
if(numberOfCapsules && numberOfCapsules > 0){
    this.$el.find("#capsuleButton > .ownerDataBubble").removeClass("unconsumed").show();
}else{
    this.$el.find("#capsuleButton > .ownerDataBubble").hide();
}
}
},


_updateCommentButton : function(hasUnotifiedOwnerComments, numberOfComments){
if(hasUnotifiedOwnerComments){
this.$el.find("#converseButton > .ownerDataBubble").addClass("unconsumed").show();
}else{
if(numberOfComments && numberOfComments > 0){
    this.$el.find("#converseButton > .ownerDataBubble").removeClass("unconsumed").show();
}else{
    this.$el.find("#converseButton > .ownerDataBubble").hide();
}
}
},

_notifiedUserOfUnnotifiedOwnerComments: function(){
this.trigger("notifiedUserOfUnnotifiedOwnerComments");
},

onSongTagsChanged : function(song){
const songTagIds = song.get("tagIds"); 
const numberOfTagsAttachedToSong = (songTagIds && songTagIds.length > 0)?songTagIds.length : 0;
this._toggleTagsBtnClass(numberOfTagsAttachedToSong);
this._showShareSongBtnIfApplicable();
this._triggerEventOnBody("songTagChange",{"numberOfTagsAttachedToSong": numberOfTagsAttachedToSong});
},

_updateSocialInfoIfApplicable : function(){
return this.model.getCurrentSongSocialInfoIfApplicable().then((function(currentSongSocialInfo){
//Update reference to current Song Social Info displayed
this._currentSongSocialInfo = currentSongSocialInfo;
if(currentSongSocialInfo)
{

    const readableText = Utils.getInstance().buildTextToDisplayFromSocialInfoJson(currentSongSocialInfo);

    if(!this._socialInfoCarouselItemAdded)
    {
        const templateParam = {"socialInfoText" : readableText, "ti18n":ti18n}
        const compiledTemplate = _.template(songSocialInfoTemplate)(templateParam);
        this._$songAndPlaylistCarouselEl.data('owlCarousel').addItem(compiledTemplate);
        this._socialInfoCarouselItemAdded = true;
    }else{
        this.$el.find("#songSocialInfoWrapper").html(readableText);
    }
    this._showSocialInfoTemporarly();
}else{
    if(this._socialInfoCarouselItemAdded)
    {
        this._$songAndPlaylistCarouselEl.data('owlCarousel').removeItem(1);
        this._socialInfoCarouselItemAdded = false;
    }
}
}).bind(this));
},

_updateCapsuleInfoIfApplicable : function(){
return this.model.getCurrentCapsuleSummaryIfApplicable().then((function(currentSongCapsuleSummary){
const activeCapsuleId = null;
let numberOfCapsules = 0;
let hasActiveCapsule = false;
if(currentSongCapsuleSummary)
{
    //activeCapsuleId = currentSongCapsuleSummary.activeCapsuleId;
    numberOfCapsules = currentSongCapsuleSummary.numberOfCapsules;
    hasActiveCapsule = currentSongCapsuleSummary.hasActiveCapsule;

}
this._updateCapsuleButton(hasActiveCapsule, numberOfCapsules);
}).bind(this));
},



_updateCommentInfoIfApplicable : function(){
return this.model.getCurrentCommentSummaryIfApplicable().then((function(currentSongCommentSummary){
//let activeCommentsIds = [];
let numberOfComments = 0;
//const hasActiveComments = false;
let hasUnotifiedOwnerComments = null;
if(currentSongCommentSummary)
{
    numberOfComments = currentSongCommentSummary.numberOfComments;
    hasUnotifiedOwnerComments = currentSongCommentSummary.hasUnotifiedOwnerComments;

}
if(hasUnotifiedOwnerComments){
    this._notifiedUserOfUnnotifiedOwnerComments();
}
this._updateCommentButton(hasUnotifiedOwnerComments, numberOfComments);
}).bind(this));
},


_toggleFavoriteBtnClass : function(favorite){
if(favorite != this._isCurrentSongFavorite)
{
const favoriteIconBtn = this.$el.find("#songIsFavoriteBtn > t");
if(this._isCurrentSongFavorite && (this._isCurrentSongFavorite== true ||  this._isCurrentSongFavorite== "true")){
    favoriteIconBtn.removeClass("ion-ios7-plus-empty");
}else{
    favoriteIconBtn.removeClass("ion-ios7-checkmark-outline");
}
if(favorite && (favorite== true ||  favorite== "true")){
    favoriteIconBtn.addClass("ion-ios7-plus-empty");
}else{
    favoriteIconBtn.addClass("ion-ios7-checkmark-outline");
}
this._isCurrentSongFavorite = favorite;
}
},
/*
_toggleAddedToRadioBtnClass : function(addedToRadio){
const addedToRadioIconBtn = this.$el.find("#songIsFavoriteBtn > t");
const addedToRadioIconWrapperBtn = this.$el.find("#songIsFavoriteBtn");
if(addedToRadio != this._isCurrentSongAddedToRadio)
{

if(this._isCurrentSongAddedToRadio && (this._isCurrentSongAddedToRadio== true ||  this._isCurrentSongAddedToRadio== "true")){
    addedToRadioIconBtn.removeClass("ion-ios7-checkmark-outline");
    addedToRadioIconWrapperBtn.removeClass("addToTrebble");
    addedToRadioIconWrapperBtn.attr("data-hint","Remove From Trebble");
}else{
    addedToRadioIconBtn.removeClass("ion-ios7-plus-empty");
    addedToRadioIconWrapperBtn.removeClass("removeFromTrebble");
    addedToRadioIconWrapperBtn.attr("data-hint","Add To Trebble");
}
if(addedToRadio && (addedToRadio== true ||  addedToRadio== "true")){
    addedToRadioIconBtn.addClass("ion-ios7-checkmark-outline");
    addedToRadioIconWrapperBtn.attr("data-hint","Remove From Trebble");
    if(!addedToRadioIconWrapperBtn.hasClass("removeFromTrebble")){
        addedToRadioIconWrapperBtn.addClass("removeFromTrebble");
    }
}else{
    addedToRadioIconBtn.addClass("ion-ios7-plus-empty");
    addedToRadioIconWrapperBtn.attr("data-hint","Add To Trebble");
    if(!addedToRadioIconWrapperBtn.hasClass("addToTrebble")){
        addedToRadioIconWrapperBtn.addClass("addToTrebble");
    }
}
this._isCurrentSongAddedToRadio = addedToRadio;
}
if(this._songTypeLabel === "Song" ||this._songTypeLabel === "Capsule"){
addedToRadioIconWrapperBtn.show();
}else{
addedToRadioIconWrapperBtn.hide();
}
},
*/


_toggleTagsBtnClass : function(numberOfTags){
const hasTagsAttachedClassName= "ion-ios7-pricetags";
const noTagAttachedClassName = "ion-ios7-pricetags-outline";
const tagsIconBtn = this.$el.find("#tagsBtn > t");
const tagsIconWrapperBtn = this.$el.find("#tagsBtn");

if(numberOfTags && numberOfTags > 0)
{
//has tags attached
if(!tagsIconBtn.hasClass(hasTagsAttachedClassName)){
    tagsIconBtn.removeClass(noTagAttachedClassName);
    tagsIconBtn.addClass(hasTagsAttachedClassName);
    tagsIconWrapperBtn.attr("data-hint",window.getI18n(ti18n, "GRADED"));
}
}else{
//has no tags attached
if(!tagsIconBtn.hasClass(noTagAttachedClassName)){
    tagsIconBtn.removeClass(hasTagsAttachedClassName);
    tagsIconBtn.addClass(noTagAttachedClassName);
    tagsIconWrapperBtn.attr("data-hint",window.getI18n(ti18n, "GRADE"));
}
}
if(this._songTypeLabel == "Song" && this._playerType == Player.types.SIMPLE_PLAYER){
tagsIconWrapperBtn.parent().show();
}else{
tagsIconWrapperBtn.parent().hide();
}

},


_showShareSongBtnIfApplicable : function(){
const shareSongBtn = this.$el.find("#shareSongBtn");
if(this._songTypeLabel == "Song" && (this._playerType == Player.types.TREBBLE_PLAYER || this._playerType == Player.types.CAPSULE_FEED_PLAYER || this._playerType == Player.types.CATEGORY_SHORTCAST_RADIO)){
shareSongBtn.parent().show();
}else{
shareSongBtn.parent().hide();
}
},



onAlbumCoverUpdate: function(isIdifiedAlbumArt, idifiedAlbumArtBase64,idifiedAlbumArtBase64Blur, idifiedAlbumArtURL,songURI) {
return this.model.getCurrentSong().then((function(currentSong){
if(!songURI || (currentSong && songURI == currentSong.get("uri")))
{
    const playerElem = document.getElementById("player");
    const tempImg2 = new Image();
    if (!idifiedAlbumArtBase64) {
        document.getElementById("albumArtImage").style["background-image"]="url('"+ window.getResourceURLAccordingToLocationProtocol("/img/videoLoading.png") +"')";
        //	document.getElementById("albumArtImage").setAttribute("src", window.getResourceURLAccordingToLocationProtocol("/img/noCoverArt2.png"));
        
        tempImg2.onload  = function(){
            playerElem.style["background-image"] = "url("+window.getResourceURLAccordingToLocationProtocol("/img/blackWhitebackgroundplayer.jpg")+")";
        }
        tempImg2.src = window.getResourceURLAccordingToLocationProtocol("/img/blackWhitebackgroundplayer.jpg");
        //playerElem.style.backgroundColor = "#111111";
    }else{
        const albumArtSrc = "data:image/png;base64,"+ idifiedAlbumArtBase64;
        const albumArtSrcBlur = "data:image/png;base64,"+ idifiedAlbumArtBase64Blur;
        tempImg2.onload  = function(){
            const albumdata = tempImg2.src;
            document.getElementById("albumArtImage").style["background-image"]="url('"+albumdata+"')";
        }
        tempImg2.src = albumArtSrc;
        const tempImg = new Image();
        tempImg.onload  = function(){
            const blurredData = tempImg.src;
            document.getElementById("player").style["background-image"]="url('"+blurredData+"')";
        }
        tempImg.src = albumArtSrcBlur;
    }
}else{
    //user probaly changed song already before cover art got retrieve so ignore
}
}).bind(this));


},

playPauseCurrentSong : function(event, doNotLooseAndroidAudioFocusOnPause) {
if (this.model.isPlaying()) {
this.model.pauseAudio(doNotLooseAndroidAudioFocusOnPause);
} else {
this.model.playAudio();
}

const playButtonEl = document.getElementById("playButton > t");
if(playButtonEl){
this.updatePlayPauseButtonClass();
}
this.trigger("onSongPlayPaused");
},

updatePlayPauseButtonClass : function(){
const pauseClass =  "ion-ios7-pause";//window.isEmbedded?"ion-ios7-pause-outline":"ion-ios7-pause";//"fontello-icon-pause-1";
const playClass =  "ion-ios7-play";//window.isEmbedded?"pe-7s-play":"ion-ios7-play";//"fontello-icon-play-1";
const loadingClass = "ion-loading-c";
const playButtonEl = this.$el.find("#playButton  t");
const playButtonWrapperEl = this.$el.find("#playButton");
if(this.model.isLoading() || this.model.isPlayerLoading()){
playButtonEl.removeClass(playClass);
playButtonEl.removeClass(pauseClass);
playButtonEl.addClass(loadingClass);
playButtonWrapperEl.attr("data-hint",window.getI18n(ti18n, "LOADING"));
this._triggerEventOnBody("playerPlayState",{"loading": true,"playing": true});
}else{
playButtonEl.removeClass(loadingClass);
if (this.model.isPlaying()) {
    playButtonEl.removeClass(playClass);
    playButtonEl.addClass(pauseClass);
    playButtonWrapperEl.attr("data-hint",window.getI18n(ti18n, "PAUSE"));
    this._triggerEventOnBody("playerPlayState",{"loading": false,"playing": true});
} else {
    playButtonEl.removeClass(pauseClass);
    playButtonEl.addClass(playClass);
    playButtonWrapperEl.attr("data-hint",window.getI18n(ti18n, "PLAY"));
    this._triggerEventOnBody("playerPlayState",{"loading": false,"playing": false});
}
}

},

playNextSongInPlaylist : function() {
this.model.playNextSongInPlaylist(true);
if(window.trebbleAnalyticsHelper){
window.trebbleAnalyticsHelper.trackEvent('Music Playback Events', 'Next Button Tapped'  , 'Next Button Tapped',null, this.model.getQueueContext());
}
},

playPreviousSongInPlaylist : function() {
this.model.playPreviousSongInPlaylist(true);
if(window.trebbleAnalyticsHelper){
window.trebbleAnalyticsHelper.trackEvent('Music Playback Events', 'Previous Button Tapped', 'Previous Button Tapped',null, this.model.getQueueContext());
}
},

togglePLayerLoopCurrentSong : function(){
const isCurrentSongLoop = this.model.toggleLoopCurrentSong();
const loopButtonEl = this.$el.find("#loopButton > t")[0];
if(isCurrentSongLoop && loopButtonEl)
{
loopButtonEl.style.color = "#ba4c4a";
if(window.trebbleAnalyticsHelper){
    window.trebbleAnalyticsHelper.trackEvent('Music Playback Events', 'Current Song Loop Enabled', 'Current Song Loop Enabled',null, this.model.getQueueContext());
}
}else{
loopButtonEl.style.color = "rgba(255,255,255,0.2)";
if(window.trebbleAnalyticsHelper){
    window.trebbleAnalyticsHelper.trackEvent('Music Playback Events', 'Current Song Loop Disabled', 'Current Song Loop Disabled',null, this.model.getQueueContext());
}
}
},

toggleShufflePlayerPLaylist : function(){
const isCurrentPlaylistShuffle = this.model.toggleShuffle();
const shuffleButtonEl = this.$el.find("#shuffleButton > t")[0];
if(isCurrentPlaylistShuffle && shuffleButtonEl)
{
shuffleButtonEl.style.color = "#ba4c4a";
if(window.trebbleAnalyticsHelper){
    window.trebbleAnalyticsHelper.trackEvent('Music Playback Events', 'Current Song Shuffle Enabled', 'Current Song Shuffle Enabled',null, this.model.getQueueContext());
}
}else{
shuffleButtonEl.style.color = "rgba(255,255,255,0.2)";
if(window.trebbleAnalyticsHelper){
    window.trebbleAnalyticsHelper.trackEvent('Music Playback Events', 'Current Song Shuffle Disabled', 'Current Song Shuffle Disabled',null, this.model.getQueueContext());
}
}
},
_initSongAndPlaylistCarouselEl : function(){
const songAndQueueInfoWrapper = this.$el.find("#songAndPlaylistInfoWrapper");
this._socialInfoCarouselItemAdded = false;
this._$songAndPlaylistCarouselEl = songAndQueueInfoWrapper.owlCarousel({

navigation : false, // Show next and prev buttons
pagination :true,
slideSpeed : 500,
paginationSpeed : 800,
addClassActive : true,
responsive: true,
singleItem:true
});
},
_showSocialInfoTemporarly : function(){
this._showSocialInfoIfNotVisible(3000, this._showPlaylistInfoIfNotVisible, this, 4000);
},

_onPageShow : function(){
this._showSocialInfoTemporarly();
this.trigger("updateArtistSummaryWidget");	
if(ENABLE_MARQUEE){
//trigger the marquee rendering 100 ms to make sure that the element is already visible
setTimeout((function(){
    this.applyMarqueeOnTitleIfApplicable(this.$el.find("#playerCurrentSongTitle"));
    this.applyMarqueeOnTitleIfApplicable(this.$el.find("#playerCurrentArtist")); 
    this.applyMarqueeOnTitleIfApplicable(this.$el.find("#playlistNameWrapper")); 
}).bind(this), 1500)

}
},

applyMarqueeOnTitleIfApplicable : function(element$el){
element$el.removeClass("marquee");
if(this.isJqueryElementOverflow(element$el)){
this.wrapContentsInMarquee(element$el.get(0));
}
},

isJqueryElementOverflow : function(element$el){
return element$el.width() && (element$el.get(0).scrollWidth > element$el.width())
},


wrapContentsInMarquee: function(element) {
const marquee = document.createElement('span'),
contents = element.innerText;

marquee.innerText = contents;
element.innerHTML = '';
element.appendChild(marquee);
const element$el = $(element);
if(!element$el.hasClass("marquee")){
element$el.addClass("marquee");
}
},


_showSocialInfoIfNotVisible : function(delay, callbackAfterAnimation, context, callbackParams){
this._clearPendingTimeoutAnimation();
const timeoutId = setTimeout((function(){
if(!this._isSocialInfoVisible())
{
    const carousel = this._$songAndPlaylistCarouselEl.data('owlCarousel');
    carousel.goTo(1);
    if(callbackAfterAnimation)
    {
        this._setPendingTimeoutAnimation(null);
        callbackAfterAnimation.call(context, callbackParams);
        return ;
    }
}else{
    if(callbackAfterAnimation)
    {
        this._setPendingTimeoutAnimation(null);
        callbackAfterAnimation.call(context, callbackParams);
        return ;
    }
}

}).bind(this),delay);
this._setPendingTimeoutAnimation(timeoutId);

},

_clearPendingTimeoutAnimation : function(){
if(this._pendingTimeoutAnimation)
{
clearTimeout(this._pendingTimeoutAnimation);
}
this._pendingTimeoutAnimation = null;
},

_setPendingTimeoutAnimation : function(timeoutId){
this._pendingTimeoutAnimation = timeoutId;
},

_showPlaylistInfoIfNotVisible : function(delay, callbackAfterAnimation, context, callbackParams){
this._clearPendingTimeoutAnimation();
const timeoutId = setTimeout((function(){
if(!this._isPlaylistInfoVisible())
{
    const carousel = this._$songAndPlaylistCarouselEl.data('owlCarousel');
    carousel.goTo(0);
    if(callbackAfterAnimation)
    {
        this._setPendingTimeoutAnimation(null);
        callbackAfterAnimation.call(context, callbackParams);
        return ;
    }
}else{
    if(callbackAfterAnimation)
    {
        this._setPendingTimeoutAnimation(null);
        callbackAfterAnimation.call(context, callbackParams);
        return ;
    }
}

}).bind(this),delay);
this._setPendingTimeoutAnimation(timeoutId);
},

_isSocialInfoVisible : function(){
return this._isCarouselItemWithIdVisible("songSocialInfoWrapper");
},

_isPlaylistInfoVisible : function(){
return this._isCarouselItemWithIdVisible("songInfoWrapper");
},

_isCarouselItemWithIdVisible : function(carouselItemId){
const activeItems = this._$songAndPlaylistCarouselEl.find(".active > #"+carouselItemId+"");
if(activeItems && activeItems.length >0)
{
return true
}
return false;
},

_setPlayerViewReadyToPlay : function(readyToPlay){
if(readyToPlay){
this.$el.removeClass(PLAYER_NOT_READY_TO_PLAY_CLASS_NAME);
}else{
if(!this.$el.hasClass(PLAYER_NOT_READY_TO_PLAY_CLASS_NAME)){
    this.$el.addClass(PLAYER_NOT_READY_TO_PLAY_CLASS_NAME);
}
}
},

_initPlayerSlider : function(){
this.__noUiSliderObj = noUiSlider.create(this._playerSliderEl, {
start: 0,
connect: [true, false],
orientation: 'horizontal', // 'horizontal' or 'vertical'
range: {
    'min': 0,
    'max': SLIDER_MAX_VALUE
},
tooltips: false,
format: {
    to: this._getSliderToFormat.bind(this),
    from: this._getSliderFromFormat.bind(this)
}
});
this._playerSliderEl.noUiSlider.on('change',this._onUserSliderPosition.bind(this));
this._playerSliderEl.noUiSlider.on('start',this._onUserStartedDraggingSlider.bind(this));
this._playerSliderEl.noUiSlider.on('end',this._onUserStoppedDraggingSlider.bind(this));
},

_getSliderToFormat : function(value){
if(this._cachedCurrentSongDuration){
return this.formatPositionToString(value * this._cachedCurrentSongDuration/(100 * 1000));
}else{
return " ";
}
},

_getSliderFromFormat : function(value){
/*if("-:--" === value){
return 0
}else{
if(this._cachedCurrentSongDuration){
    try{
        const minuteAndSeconds = value.split(":");
        const timeInSeconds = parseInt(minuteAndSeconds[0])*60 +  parseInt(minuteAndSeconds[1]);
        return timeInSeconds * SLIDER_MAX_VALUE / this._cachedCurrentSongDuration;
    }catch(error){
        return 0;
    }
}
}
return 0*/
return parseFloat(value);
},

_onUserStartedDraggingSlider : function(){
this._playerSliderEl._isUserDragged = true;
},
_onUserStoppedDraggingSlider : function(){
this._playerSliderEl._isUserDragged = false;
},


render : function() {
return this.model.getCurrentSong().then((function(currentSong){
/*	if (!currentSong) {
    currentSong = {};
    currentSong.title = "Track Name";
    currentSong.artist = "Artist Name";
    currentSong.coverArtFound = false;
    currentSong.albumArtData = null;
}*/
this.$el.html(this.template({"ti18n":ti18n}));
this.setElement(this.$el.find("div").first());
this.$el.page();
this.$el.attr("no_audio_loaded", "true");
this._setPlayerViewReadyToPlay(false);
this.$el.on("pageshow",this._onPageShow.bind(this));
this._initSongAndPlaylistCarouselEl();
this._playerSlider$el = this.$el.find("#playerSongPositionSlider");
this._playerSliderEl= this._playerSlider$el.get(0);
this._initPlayerSlider();
this.$currentTrackPositionEl = this.$el.find("#songCurrentPosition").first();
this._liveLikeButton$el = this.$el.find("#liveLikeButton");
this._onOrientationChangeFunction();
this._setupTapAndPressHanlderOnSkipButton();
return this;
}).bind(this));

}

});
export default PlayerPageView;