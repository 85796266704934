import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import SequencerHelpContextOptionView from "views/audioEditor/SequencerHelpContextOptionView";
import SequencerHelpContextOptionContentView from "views/audioEditor/SequencerHelpContextOptionContentView";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const SequencerHelpContextOptionController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.model = options? options.sequencer : null;
        
        this.context = options? options.context : null;
        this.elementSource$el = options? options.elementSource$el : null;
        this._popupPosition = options? options.popupPosition: null;
        this._onMenuClosedHandler = options? options.onMenuClose: null;
        _.extend(this, Backbone.Events);
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    showHelpCenter : function(){
        PersistentModels.getInstance().getRouter().showHelpCenterPage();
        this.close();
    },

    showFeatureRequests : function(){
        PersistentModels.getInstance().getRouter().showFeatureRequestsPage();
        this.close();
    },

    showBookTour : function(){
        PersistentModels.getInstance().getRouter().showBookOneOnOneSessionPage();
        this.close();
    },

    showInAppChatWidget : function(){
        PersistentModels.getInstance().getRouter().showHelpAndFeedback(null, true);
        this.close();
    },

    

    close: function(){
        return this.view.close();
    },

    onMenuClosed: function(){
        if(this._onMenuClosedHandler){
            this._onMenuClosedHandler();
        }
        this.stopListening();
    },

    
    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {

                const contentParams = {
                    "model" : this.model,
                    "elementSource$el": this.elementSource$el,
                    "popupPosition": this._popupPosition
                };
                const contentView = new SequencerHelpContextOptionContentView(contentParams);
                contentView.render();
                this.listenTo(contentView, "showHelpCenter",this.showHelpCenter);
                this.listenTo(contentView, "showBookTour", this.showBookTour);
                this.listenTo(contentView, "showFeatureRequests",this.showFeatureRequests);
                this.listenTo(contentView, "showInAppChatWidget", this.showInAppChatWidget);
                if(window.isMobileBrowser){
                    //const bottomSheet = Utils.getInstance().createBottomSheet(this._sequencerNodeInspectorSectionController.getView().$el.get(0), resolve, this._onPopupClosed.bind(this));
                    const onBottomSheetDisplayed = function(){

                    }
                    const bottomSheet = Utils.getInstance().createBottomSheet(contentView.$el.get(0), onBottomSheetDisplayed, this.onMenuClosed.bind(this), true);
                    bottomSheet.activate();
                    bottomSheet.close = function(){
                        bottomSheet.deactivate();
                    }
                    this.view = bottomSheet;
                    resolve();
                }else{
                    contentParams.contentView = contentView;
                    this.view = new SequencerHelpContextOptionView(contentParams);
                    this.listenTo(this.view, "closed", this.onMenuClosed);
                    this.view.render();
                    resolve();
                }

            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default SequencerHelpContextOptionController;