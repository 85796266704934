var amdi18n={"__root":{"LOCAL_SONGS_SIMILAR_TO":"Local Songs Similar To","LOCAL_SONGS_SIMILAR_TO_ARTIST":"Local Songs Similar To artist","PLAY_SIMILAR":"Play Similar ...","REMOVE_FROM_MY_TREBBLE":"Remove From My Channel","ADD_TO_MY_TREBBLE":"Add To My Channel","SHARE":"Share","BROWSE_ARTIST":"Browse Artist","DELETE_ALL_ALBUMS":"Delete All Albums"},"__fr":{"LOCAL_SONGS_SIMILAR_TO":"Chansons locales similaires à","LOCAL_SONGS_SIMILAR_TO_ARTIST":"Chansons locales similaires à l'artiste","PLAY_SIMILAR":"Jouer similaire ...","REMOVE_FROM_MY_TREBBLE":"Retirer de ma chaîne","ADD_TO_MY_TREBBLE":"Ajouter à ma chaîne","SHARE":"Partager","BROWSE_ARTIST":"Parcourir l'artiste","DELETE_ALL_ALBUMS":"Supprimer tous les albums"},"__en":{"LOCAL_SONGS_SIMILAR_TO":"Local Songs Similar To","LOCAL_SONGS_SIMILAR_TO_ARTIST":"Local Songs Similar To artist","PLAY_SIMILAR":"Play Similar ...","REMOVE_FROM_MY_TREBBLE":"Remove From My Channel","ADD_TO_MY_TREBBLE":"Add To My Channel","SHARE":"Share","BROWSE_ARTIST":"Browse Artist","DELETE_ALL_ALBUMS":"Delete All Albums"}};amdi18n.init=function(language){
		// get the default language
		if(!language){
			if(typeof window !== 'undefined' && window._i18n && window._i18n.locale){
				language = window._i18n.locale;
			}else if(typeof document !== 'undefined' && document.documentElement.lang){
				language = document.documentElement.lang;
			}else if(typeof navigator !== 'undefined' && (navigator.languages || navigator.language || navigator.userLanguage)){
				// navigator.languages does not exist in IE 11
				language = (navigator.languages && navigator.languages[0]) ||
					navigator.language ||
					navigator.userLanguage;
				language = (language || 'root').toLowerCase();
			}else{
				language = 'root';
			}
		}
		var target = this['__' + language] || this.__root;

		// copy definition to root level
		if (target) {
			for (var name in target) {
				this[name] = target[name];
			}
		}

		// fallback to root
		var recursiveFallback = function(target, source){
			for(var name in source){
				switch(typeof target[name]){
				case 'undefined':
					target[name] = source[name];
					break;
				case 'object':
					recursiveFallback(target[name], source[name]);
				}
			}
		};
		recursiveFallback(this, this.__root);
	};amdi18n.init();module.exports=amdi18n;