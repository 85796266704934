import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import PersistentModels from "services/PersistentModels";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import JourneyCardTemplate from 'text!../../../templates/journey/JourneyCardTemplate.html';
import ti18n from 'i18n!nls/JourneyCardi18n';
import Hammer from 'hammerjs';
import RSVP from 'rsvp';

const TIME_IN_MILLISECONDS_TO_HOLD_TO_TRIGGER_PREVIEW = 2000;
const JourneyCardView = Backbone.View.extend({



    initialize : function(options) {
        this.compiledTemlate =  _.template(JourneyCardTemplate);
        this.model = options? options.model : null;
        this._customImageUrlProperty = options.customImageUrlProperty? options.customImageUrlProperty : null;
        this._isPlaying = false;
        this._notTransparent = options.notTransparent;
        this._smallcardsize = options.smallcardsize;
        this._notPlayable = options.notPlayable;
        this._notDetailable = options.notDetailable;
        this._doNotShowSubscribeButton =  options.doNotShowSubscribeButton;
        
        //Hack to prevent issue when tap-holding
        this._modelWithPressedEventOnElement = [];
        this._modelWithLastPressedUpEventOnElement = [];
        this._timestampWithLastPressedUpEventOnElement = [];
        this._isPressed = false;
        this._lastPressUpOccurenceTimestamp = 0;

    },
    
    events : {
        //"tap .info" : "onInfoClick",
        "click #followBtn" : "onFollowBtnClick",
        "click #buyNowBtn" : "onBuyNowBtnClick",
        "click #followingBtn" : "onUnfollowBtnClick",
        "click #followBtn .subscribe_tooltip" : "onSubscribeTooltipIfApplicableClickedOn",
        "click #buyNowBtn .subscribe_tooltip" : "onSubscribeTooltipIfApplicableClickedOn",
        "click #editButton" : "onEditButtonClick",
        "click #shareTrebbleBtn" : "onShareTrebbleBtnClick",
        //"click .itemContent > .coverArt > div > img" : "onInfoClick",
        //"click .itemContent *:not(#followingBtn):not(#followBtn):not(.buttonBar):not(.followingBtnLabel):not(.followBtnLabel)" : "onInfoClick",
        //"click .journey_goal_wrapper" : "onInfoClick",
        /*"click .info .title" : "onInfoClick",
        "click .info .description" : "onInfoClick",
        "click .info .summary" : "onInfoClick",
        "click .info .socialInfos" : "onInfoClick",
        "click .info .footer" : "onInfoClick",*/
        "click .playButton" : "onPlayButtonClick",
        "click .stopButton" : "onStopButtonClick",
        
        //"touchend .info" : "onPressUp",
        //"taphold .info" : "onUserTapHolding", 
        //"mouseover .previewBtn" : "onPress", 
        //"mouseout .previewBtn" : "onPressUp", 
        //"mouseup .info" : "onPressUp",
    },

    isSubscribeTooltipTheTargetOfEvent : function(event){
        const subscribe_tooltip$el = $(event.currentTarget).find(".subscribe_tooltip");
        const subscribe_tooltipEl = subscribe_tooltip$el.get(0);
        return subscribe_tooltipEl && (subscribe_tooltipEl == event.target || subscribe_tooltipEl.contains(event.target));
    },

    

    onFollowBtnClick: function(event){
        event.stopImmediatePropagation();
        if(!this.isSubscribeTooltipTheTargetOfEvent(event)){
            this.trigger("follow", this.model);	
        }
        
    },

    onBuyNowBtnClick: function(event){
        event.stopImmediatePropagation();
        if(!this.isSubscribeTooltipTheTargetOfEvent(event)){
            this.trigger("buyNow", this.model);	
        }
        
    },

    setBuyNowRefreshing : function(refreshing){
        const buyNowBtn$el = this.$el.find("[id=buyNowBtn]");
        if(refreshing){
            if(!buyNowBtn$el.hasClass("loading")){
                buyNowBtn$el.addClass("loading")
            }
        }else{
            buyNowBtn$el.removeClass("loading");
            this.updateFollowAndBuyNowButton();
        }
    },


    onUnfollowBtnClick: function(event){
        event.stopImmediatePropagation();
        this.trigger("unfollow", this.model);
    },

    onSubscribeTooltipIfApplicableClickedOn : function(event){
        event.stopImmediatePropagation();
        this.trigger("subscribeTooltipIfApplicableClickedOn", this.model);
    },

    onPlayButtonClick : function(){
        this.trigger("playTrebble", this.model);
    },

    onStopButtonClick : function(){
        this.trigger("pauseTrebble", this.model);
    },
    
    onEditButtonClick : function(){
        this.trigger("showEditJourneyPage", this.model);
    },

    onShareTrebbleBtnClick : function(){
        this.trigger("showShareJourneyPopup", this.model);
    },

    onInfoClick : function(event){
        if(this._notDetailable){
            return ;
        }
        if(event && event.target)
        {
            const followButton$el = this.$el.find("#followBtn");
            const followingButton$el = this.$el.find("#followingBtn");
            const editButton$el = this.$el.find("#editButton");
            const shareButton$el = this.$el.find("#shareTrebbleBtn");
            const dragBtn$el = this.$el.find(".dragBtn");
            if(event.target == followButton$el.get(0) || event.target == followingButton$el.get(0) || event.target == editButton$el.get(0) || event.target == shareButton$el.get(0) || event.target == dragBtn$el.get(0)){
                return ;
            }
            if(followButton$el && followButton$el.get(0) && $.contains(this.$el.find("#followBtn").get(0), event.target)){
                //this event should be ignored as it is most likely the user cliquing on the tooltip inviting him to subscribe
                return;
            }
        }	
        if((new Date()).getTime() - this._lastPressUpOccurenceTimestamp > 1000){
            this.trigger("showJourneyDetailsPage", this.model);
        }
    },
    
    onPress : function(e){
        this.trigger("playTrebblePreview", this.model);
        this._isPressed = true;
        if(e){
            if(e.stopPropagation){
                e.stopPropagation();
            }
            if(e.srcEvent && e.srcEvent.stopPropagation){
                e.srcEvent.stopPropagation();
            }
        }

    },
    
    onPressUp : function(e){
        this.$el.removeClass("previewLoading");
        if(this._isPressed){
            this._lastPressUpOccurenceTimestamp =  (new Date()).getTime();
            this._isPressed = false;
            this.trigger("stopPreviewAndResumePlayback", this.model);
            if(e){
                if(e.stopPropagation){
                    e.stopPropagation();
                }
                if(e.srcEvent && e.srcEvent.stopPropagation){
                    e.srcEvent.stopPropagation();
                }
            }
        }else{
            this._lastPressUpOccurenceTimestamp =  0;
        }
    },

    
    _addHammerEventListener : function(htmlElement, eventName, handlerFuncBinded){
        const hammerTime = Hammer(htmlElement, {drag_lock_to_axis: true}).on(eventName, handlerFuncBinded, false);
    },

    onUserTapHolding :function(){
        if(!this.$el.hasClass("previewLoading")){
            this.$el.addClass("previewLoading");
        }
    },

    updateFollowAndBuyNowButton : function(){
        /*if(!this.model.canBeFollowed()){
            this.$el.find("[id=followingBtn]").css("display","none");
            this.$el.find("[id=followBtn]").css("display","none");
        }else{
            if(this.model.isFollowing()){
                this.$el.find("[id=followingBtn]").css("display","block");
                this.$el.find("[id=followBtn]").css("display","none");
            }else{
                this.$el.find("[id=followingBtn]").css("display","none");
                this.$el.find("[id=followBtn]").css("display","block");
            }
        }*/

        if(!this.model.canBeFollowed()){
            this.$el.find("[id=followingBtn]").css("display","none");
            this.$el.find("[id=followBtn]").css("display","none");
            this.$el.find("[id=buyNowBtn]").css("display","none");
            this.$el.find("[id=purchasedBtn]").css("display","none");

        }else{

            if(this.model.isPaid()){

                if(this.model.hasPurchased()){

                    this.$el.find("[id=purchasedBtn]").css("display","flex");
                    this.$el.find("[id=buyNowBtn]").css("display","none");

                }else{

                    this.$el.find("[id=purchasedBtn]").css("display","none");
                    this.$el.find("[id=buyNowBtn]").css("display","flex");

                }
            }else{

                this.$el.find("[id=purchasedBtn]").css("display","none");
                this.$el.find("[id=buyNowBtn]").css("display","none");

            }

            if(this.model.isFollowing()){

                this.$el.find("[id=followingBtn]").css("display","flex");
                this.$el.find("[id=followBtn]").css("display","none");

            }else{

                this.$el.find("[id=followingBtn]").css("display","none");
                if(this.model.isPaid() && !this.model.hasPurchased()){
                    this.$el.find("[id=followBtn]").css("display","none");

                }else{
                    this.$el.find("[id=followBtn]").css("display","flex");

                }
            }
        }
    },


    _getStatusLabel : function(status){
        return window.getI18n(ti18n, status);
    },

    updateCardView : function(journeySummaryModelUpdated){
        this.$el.find(".info .title").html(journeySummaryModelUpdated.getName());
        this.$el.find(".info .description").html(journeySummaryModelUpdated.getGoal());
        this.$el.find(".info .journey_goal_wrapper span").html(journeySummaryModelUpdated.getGoal());
        this.$el.find(".journey_status_tag").html(this._getStatusLabel(journeySummaryModelUpdated.getStatus()));
        this.$el.attr("journey_status",journeySummaryModelUpdated.getStatus());
        this.$el.attr("can_be_edited",journeySummaryModelUpdated.canBeEdited());
        
        this.$el.find(".info .summary").html(journeySummaryModelUpdated.getSummary());
        this.$el.find("#coverArtImage").attr("class", "lazy "+ ( journeySummaryModelUpdated.getCoverArtUrl()?'swiper-lazy' : '' ) );
        this.$el.find("#coverArtImage").attr("src", ( journeySummaryModelUpdated.getCoverArtUrl() ? window.trebble.globalHelpers.getResizeImageUrl(journeySummaryModelUpdated.getCoverArtUrl(), 400, 400) : journeySummaryModelUpdated.getCoverArtUrl()));
        if(journeySummaryModelUpdated.getJourneyLengthInDays() > 0){
            this.$el.find(".footer .lastUpdateTime .journey_length_label").html( " · "+ "<i class='fontello-icon-list journeyOultlineListIcon' ></i>" + journeySummaryModelUpdated.getJourneyLengthInDays() +" "+window.getI18n(ti18n, "DAYS"));
        }else{
            this.$el.find(".footer .lastUpdateTime .journey_length_label").html("");
        }
        this.updateFollowAndBuyNowButton();
    },

    updatePlaybackButton : function(){
        const playerModel =  PersistentModels.getInstance().getPlayer();
        if(playerModel.isPlaylistCurrentlyLoaded(this.model)){
            if(playerModel.isLoading() || playerModel.isPlayerLoading()){
                this.$el.removeClass("isCurrentlyPlaying");
                this.$el.addClass("isCurrentlyLoading");
            }else{
                this.$el.removeClass("isCurrentlyLoading");
                if (playerModel.isPlaying()) {
                    this.$el.addClass("isCurrentlyPlaying");
                } else {
                    this.$el.removeClass("isCurrentlyPlaying");
                }
            }
        }else{
            this.$el.removeClass("isCurrentlyPlaying");
            this.$el.removeClass("isCurrentlyLoading");
        }

    },

    hideSubscribeTooltipIfApplicable: function(noNeedToUpdateLocalStorageFlag){
        const subscribe_tooltip$el = this.$el.find("[id=followBtn] .subscribe_tooltip");
        if(!subscribe_tooltip$el.hasClass("hide")){
            subscribe_tooltip$el.addClass("hide");
            if(!noNeedToUpdateLocalStorageFlag){
                window.trebble.subscribeToJourneyTooltipWasDiscardedByUserInCurrentSession = true;
                const loggedInUserInfo = LocalStorageHelper.getInstance().getUserInfo();
                const isTemporaryUser = loggedInUserInfo ? loggedInUserInfo.temporary : false;
                if(!isTemporaryUser){
                    LocalStorageHelper.getInstance().setSubscribreToJourneyTooltipDiscardedByUser();
                }
            }
            this.$el.find(".listeningInstructionsBox.transparentChatbubble").each(function(){
                $( this ).removeClass( "transparentChatbubble" );
            });
        }
    },

    setTrebbleIsLoading  : function(){
        if(!this.$el.hasClass("isCurrentlyLoading")){
            this.$el.addClass("isCurrentlyLoading");
        }
    },



    /*onUserStopTapHolding :function(){
        this.$el.removeClass("previewLoading");
    },*/

    onCardClick : function(event){
        if(event.target == this.$el.get(0)){
            this.onInfoClick();
        }
    },
    
    render : function() {
        const templateParams = {};
        const trebbleJson  = this.model.toJSON();
        this.$el.html(this.compiledTemlate({model: this.model, "customImageUrlProperty": this._customImageUrlProperty, "transparent": !this._notTransparent, "smallcardsize": this._smallcardsize, "notPlayable": this._notPlayable,"notDetailable":this._notDetailable, "ti18n": ti18n, "doNotShowSubscribeButton": this._doNotShowSubscribeButton}));
        this.info$el =this.$el.find(".info");
        this.setElement(this.$el.find("o").first());
        this.$el.click(this.onInfoClick.bind(this));
        //this.info$el.on("mouseup", this.onPressUp.bind(this));
        //this.info$el.on("click", this.onInfoClick.bind(this));
        //this._addHammerEventListener(this.el, "pressup", this.onPressUp.bind(this));
        //this._addHammerEventListener(this.el, "press", this.onPress.bind(this));
        //Utils.getInstance().addTapAndPressHandler(this.info$el.get(0), TIME_IN_MILLISECONDS_TO_HOLD_TO_TRIGGER_PREVIEW, null, this.onPress.bind(this), null, null);
        return this;
    }

});
export default JourneyCardView;