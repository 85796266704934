import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Song from "models/Song";
import TrebbleSongListView from "views/common/TrebbleSongListView";
import SongOptionMenuModel from "models/SongOptionMenuModel";
import SongsCollection from "collections/myLibrary/SongsCollection";
import SongOptionMenuView from "views/SongOptionMenuView";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";

SongsCollection.prototype.model = PersistentModels.getInstance().getSongContructorFunctionForCollection();
const SongListView = Backbone.View.extend({
    
    
    
    
    
    initialize: function(options) {
        this.displayedCollection = this.collection;
        this.collection.bind("add", this._onSongAddedOrRemovedInLibrary, this);
        this.collection.bind("change", this._onSongUpdatedInLibrary, this);
        this.collection.bind("remove", this._onSongAddedOrRemovedInLibrary, this);
        this.collection.bind("reset", this._onSongAddedOrRemovedInLibrary, this);
        this._delayOnCollectionUpdate = (options && options.delayOnCollectionUpdate) ? options.delayOnCollectionUpdate :  1000;
        this._resizeToAvailableScreenSizeOnOrientationChange = options  ? options.resizeToAvailableScreenSizeOnOrientationChange :  true;
        this._showHeader = options  ? options.showHeader :  false;
        this._headerText= options  ? options.headerText :  "";
        this._showFooter= options  ? options.showFooter :  false;
        this._footerText= options  ? options.footerText :  "";
        this._customInfoBoxInfoParams = options ? options.customInfoBoxInfoParams : null;
        this._onInfoButtonPressed = options ? options.onInfoButtonPressed : null;
        this._loadHandler= (options && options.loadHandler)  ? options.loadHandler :  null;
        this._heightMargin= (options && options.heightMargin)  ? options.heightMargin :  null;
        this._showEmtptyInfoBox = (options && options.showEmtptyInfoBox)  ? options.showEmtptyInfoBox :  null;
        this._defaultHeightElement = (options && options.defaultHeightElement)? options.defaultHeightElement: 60;
        this._customListClass = (options && options.customListClass)? options.customListClass: "";
        this._resizePlaceHolderElem =  options  ? options.resizePlaceHolderElem :  false;
        this._doNotSortDisplayedCollection =  options  ? options.doNotSortDisplayedCollection :  true;
        this._doNotShowLoadingWidgetDuringListLoading = options  ? options.doNotShowLoadingWidgetDuringListLoading :  false;
        this._songWithAudioPreviewOnly = options  ? options.songWithAudioPreviewOnly :  false;
        this._listenerViewportKey = "songListView" + (new Date()).getTime();
        PersistentModels.getInstance().getRouter().registerForViewportHeightChange(this._listenerViewportKey,this._onOrientationChangeFunction.bind(this));
        
    },
    
_onOrientationChangeFunction : function(newViewPortHeigh, forceResizing) {
    if(this.$el.is(":visible") || forceResizing){
        if(this._heightMargin){
            newViewPortHeigh = newViewPortHeigh - this._heightMargin;
        }
       if (!this._resizePlaceHolderElem && this.$el) {
        this.$el.css("height" , (newViewPortHeigh ) + "px"); 
       }
       this.infiniteListView._onRootElementResize();
    }
},

isLoading :function(){
  return this.infiniteListView.isUpdating();
},


filterList: function(searchCharaterers, resultSize, customFilter,customSort) {
 if(!this._removeFromDom){
    this.appliedSearchCharacters = searchCharaterers;
    this._resultSize = resultSize;
    this._customFilter = customFilter;
    this._customSort = customSort;
    const searchFunction = (function() {
        //$.mobile.loading("show");
        this.infiniteListView.setUpdating(true);
        const r  = this.collection.searchOnTitleAndArtist(this.appliedSearchCharacters, resultSize, customFilter);
        if(r instanceof RSVP.Promise){
            r.then((function(arrayOfResult){
                const songCollection = new SongsCollection();
                songCollection.sortByField(customSort);
                songCollection.add(arrayOfResult);
                songCollection.updateContext(this.displayedCollection.getContext());
                this._changeSongList(songCollection);
                this.pendingSearchFunctionTimerId = null;
                //$.mobile.loading("hide");
            }).bind(this)).catch((function(error){
                 //$.mobile.loading("hide");
                window.alertErrorMessage(error);
            }).bind(this))
        }else{
            const songCollection = new SongsCollection();
            songCollection.sortByField(customSort);
            songCollection.add(r);
            songCollection.updateContext(this.displayedCollection.getContext());
            this._changeSongList(songCollection);
            this.pendingSearchFunctionTimerId = null;
            //$.mobile.loading("hide");
        }
    }).bind(this);
    if (this.pendingSearchFunctionTimerId) 
    {
        window.clearTimeout(this.pendingSearchFunctionTimerId);
    }
    this.pendingSearchFunctionTimerId = window.setTimeout(searchFunction,  this._delayOnCollectionUpdate);
}
},

clearSearchfilter: function(resultSize, customFilter,customSort) {
if(!this._removeFromDom){
    this.appliedSearchCharacters = null;
    this._resultSize = resultSize;
    this._customFilter = customFilter;
    this._customSort = customSort;
    const searchFunction = (function() {
        this.infiniteListView.setUpdating(true);
        const songCollection = new SongsCollection(this.collection.searchOnTitleAndArtist("",resultSize, customFilter, this._songWithAudioPreviewOnly));
        songCollection.updateContext(this.displayedCollection.getContext());
        this._changeSongList(songCollection);
        this.pendingSearchFunctionTimerId = null;
    }).bind(this);
    if (this.pendingSearchFunctionTimerId) 
    {
        window.clearTimeout(this.pendingSearchFunctionTimerId);
    }
    this.pendingSearchFunctionTimerId = window.setTimeout(searchFunction, 0);
}

},


_changeSongList: function(newSongModels) {
if(!this._removeFromDom){
    this.displayedCollection = newSongModels;
    if(!this._doNotSortDisplayedCollection)
    {
        this.displayedCollection.sort();
    }
    this.infiniteListView.updateModels(this.displayedCollection.toJSON());
    this.infiniteListView.setUpdating(false);
}
},

_updateSongInSongList: function(songUpdateModel) {
if(!this._removeFromDom){
    this.infiniteListView.updateModel(songUpdateModel.toJSON());
    this.infiniteListView.setUpdating(false);
}
},

_getOnListItemTapHandler: function() {
return (function(songJson, evnt, item) {
    this.trigger("itemTaped", this._getSongModelFromSongJson(songJson) ,this.displayedCollection );
}).bind(this);
},

_onItemSwipeRight: function() {
return (function(songJson, evnt, item) {
   this.trigger("itemSwipedRight", this._getSongModelFromSongJson(songJson));

}).bind(this);
},

_getSongModelFromSongJson : function(songJson){
return this.collection.get(songJson.uri);

},



_modelTransformHandlerBeforeTemplatePiping: function(songJson) {
    let artistToDisplay;
    let titleToDisplay;
    let albumNameToDisplay;
if (songJson.idified && (songJson.idified === "true" || songJson.idified === true) && (songJson.idifiedSuccess === "true" || songJson.idifiedSuccess === true)) 
{
if (window.trebble.config.showIdifiedArtistName) 
{
    const featuredArtists = songJson.idifiedFeaturedArtists;
    let featuredArtistsString = null;
    if(featuredArtists && featuredArtists.length > 0){
       let songTitle =  songJson.title;
       if( songJson.idifiedTitle && window.trebble.config.showIdifiedSongTitle)
       {
          songTitle = songJson.title;
      }
      for(let index in featuredArtists){
          const featuredArtist = featuredArtists[index];
          if(!songTitle || (songTitle && songTitle.toUpperCase().indexOf(featuredArtist.artist.toUpperCase()) == -1)){	
             if(!featuredArtistsString)
             {
                featuredArtistsString =   featuredArtist.artist;
            }else{
                featuredArtistsString =  featuredArtistsString + ", " + featuredArtist.artist;
            }
        }
    }
}
const mainArtist = songJson.idifiedArtist;

const allArtists = featuredArtistsString? mainArtist + ", " +featuredArtistsString : mainArtist;
artistToDisplay = allArtists;
}else{
    artistToDisplay = songJson.artist;
}
if (window.trebble.config.showIdifiedSongTitle) 
{
    titleToDisplay = songJson.idifiedTitle;
}else{
    titleToDisplay = songJson.title;
}
if(window.trebble.config.showIdifiedAlbumTitle){
    albumNameToDisplay = songJson.idifiedAlbum;
}else{
     albumNameToDisplay = songJson.album;
}
} else {
     artistToDisplay = songJson.artist;
     titleToDisplay = songJson.title;
     albumNameToDisplay = songJson.album;
}
if (!artistToDisplay) 
{
artistToDisplay = "";
}
if (!titleToDisplay) 
{
titleToDisplay = "";
}

if (!albumNameToDisplay) 
{
albumNameToDisplay = "";
}
/*const explicit = songJson.idified? songJson.idifiedExplicit : songJson.explicit;
if(explicit){
titleToDisplay = titleToDisplay + "<e class='trebbleExplicitIcon'></e>";
}*/
const templateData = {firstLineText: titleToDisplay,secondLineText: artistToDisplay + " · "+ albumNameToDisplay , model : songJson};
return templateData;
},

_getOnListButtonTappedHandler: function() {
return (function(songJson, evnt, item) {
this.trigger("itemOptionBtnTapped", this._getSongModelFromSongJson(songJson));
}).bind(this);
},


isSearchApplied : function(){
return this.appliedSearchCharacters != null;
},

getAppliedSearchCharacters : function(){
return this.appliedSearchCharacters;
}, 

_onSongAddedOrRemovedInLibrary : function(){
if(!this._removeFromDom){
if(this.isSearchApplied())
{
  this.filterList(this.getAppliedSearchCharacters(), this._resultSize,this._customFilter,this._customSort);
}else{
  this.infiniteListView.setUpdating(true);
  this._changeSongList(this.collection);
}
this.trigger("modelChange", this.collection);
}
},

updateModels : function(newModels){
this.infiniteListView.updateModels(newModels);
},

updateModel : function(newModel, silent){
this.infiniteListView.updateModel(newModel,silent);
},

_onSongUpdatedInLibrary : function(songUpdateModel){
if(!this._removeFromDom){
this.infiniteListView.updateModel(songUpdateModel?songUpdateModel.toJSON():null);
this.trigger("modelChange", this.displayedCollection);
}
},


isCollectionEmpty : function(){
if(this.collection)
{
return this.collection.length == 0;
}else{
return true;
}
},

_onContentLoading : function(){
if(!this._doNotShowLoadingWidgetDuringListLoading){
$.mobile.loading( 'show');
}
},

_onContentLoaded : function(){
if(!this._doNotShowLoadingWidgetDuringListLoading){
$.mobile.loading( "hide" );
}
},

_onContentFailedLoading : function(error){
if(!this._doNotShowLoadingWidgetDuringListLoading){
$.mobile.loading( "hide" );
console.error("Page content failed loading. Error:"+error);
}
},

load : function(params){
if(!this._removeFromDom){
if(this._loadHandler){
  this._onContentLoading();
  try{
     this.infiniteListView.setUpdating(true);
     return this._loadHandler(params).then((function(arrayOfSongs){
         if(!this._removeFromDom){
            this.collection.reset(arrayOfSongs);
            this.infiniteListView.setUpdating(false);
        }
        this._onContentLoaded();
        this.trigger("finishLoading");
    }).bind(this)).catch((function(error){
        this._onContentFailedLoading(error);
        this.infiniteListView.setErrorMessage(error);
    }).bind(this));
}catch(error){
 this._onContentFailedLoading(error);
 this.infiniteListView.setErrorMessage(error);
}
}else{
this._onSongAddedOrRemovedInLibrary();
this.trigger("finishLoading");
return RSVP.Promise.resolve();
}
}
},

updateInfoBoxIfApplicable : function(iconClass,message,helpMessage,infoBoxClassName,actionButtonLabel,actionButtonIconClass){
if(this.infiniteListView){
this.infiniteListView.updateInfoBoxIfApplicable(iconClass,message,helpMessage,infoBoxClassName,actionButtonLabel,actionButtonIconClass);
}
},

_onNodeRemovedFromDom : function(){
this._removeFromDom  = true;
PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._listenerViewportKey);
},

onSongRemovedFromTrebble : function(songModel){
this.trigger("songRemovedFromTrebble",songModel);
},

onActionExecutedOnSongHandler : function( songModel, action){
this.trigger("actionExecutedOnSongHandler",songModel, action);
},

onAddToRadioButtonTapped : function(songModel){
this.trigger("addToRadioButtonTapped",songModel);
},

onRemoveFromRadioButtonTapped : function(songModel){
this.trigger("removeFromRadioButtonTapped",songModel);
},

onSongGradeButtonTapped : function(songModel){
this.trigger("gradeButtonTapped",songModel);
},


onInfoBoxButtonPressed : function(){
if(this._onInfoButtonPressed){
this._onInfoButtonPressed();
}
},

cancelPendingImageLoading : function(){
this.infiniteListView.cancelPendingImageLoading();
},
render: function() {

    let newViewPortHeigh = PersistentModels.getInstance().getRouter().getViewportHeight();
if(this._heightMargin){
newViewPortHeigh = newViewPortHeigh - this._heightMargin;
}
this.$el.html(' <div id="maliste"  style="height:' +(newViewPortHeigh + "px") + ';overflow:hidden;width:100%"  class="'+ this._customListClass+'"></div>');
this.setElement(this.$el.find("div").first());
const listParams = {};
if(!this._doNotSortDisplayedCollection)
{
this.displayedCollection.sort();
}
listParams.model = this.displayedCollection.toJSON();
listParams.collection = this.displayedCollection;
listParams.listRootElement = this.el;
listParams.defaultNumberOfElements = 80;
listParams.defaultHeightElement = this._defaultHeightElement;
listParams.placeHolderHeight =  PersistentModels.getInstance().getRouter().getViewportHeight();
listParams.resizePlaceHolderElem =  this._resizePlaceHolderElem;
listParams.showEmtptyInfoBox = this._showEmtptyInfoBox;
listParams.customInfoBoxParams = this._customInfoBoxInfoParams;
const infiniteListView = new TrebbleSongListView(listParams);
this.infiniteListView = infiniteListView;
    //infiniteListView.setModelTransformHandlerBeforeTemplatePiping(this._modelTransformHandlerBeforeTemplatePiping);
    infiniteListView.addOnItemTapHandler(this._getOnListItemTapHandler());
    infiniteListView.addOnOptionButtonTappedHandlers(this._getOnListButtonTappedHandler());
    infiniteListView.addOnSwipeRightRevealHandler(this._onItemSwipeRight());
    infiniteListView.render();
    this._onOrientationChangeFunction(PersistentModels.getInstance().getRouter().getViewportHeight(), true);
    this.$el.on("remove",this._onNodeRemovedFromDom.bind(this));
this.listenTo(this.infiniteListView, "songRemovedFromTrebble",this.onSongRemovedFromTrebble.bind(this));
this.listenTo(this.infiniteListView, "actionExecutedOnSongHandler",this.onActionExecutedOnSongHandler.bind(this));
this.listenTo(this.infiniteListView, "addToRadioButtonTapped",this.onAddToRadioButtonTapped.bind(this));
this.listenTo(this.infiniteListView, "removeFromRadioButtonTapped",this.onRemoveFromRadioButtonTapped.bind(this));
this.listenTo(this.infiniteListView, "gradeButtonTapped",this.onSongGradeButtonTapped.bind(this));
this.listenTo(this.infiniteListView, "infoBoxButtonPressed", this.onInfoBoxButtonPressed.bind(this));
    return this;
}

});

export default SongListView;
