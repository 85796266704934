define(['jquery', "pickadate",
	"pickadateDate",
	"pickadateTime","rsvp"], function($, Pickadate,PickadateDate, PickadateTime, RSVP){
		var navigatorLanguage =  null;
		if (navigator.languages && navigator.languages.length) {
			navigatorLanguage =  navigator.languages[0];
		} else {
			navigatorLanguage = navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
		}

		var genericNavigatorLanguage = navigatorLanguage? navigatorLanguage.split("-")[0]: null;
		var isGenericNavigatorLanguage = window.trebble.supporteLanguages.indexOf(genericNavigatorLanguage) != -1;
		var appUsedLanguage = isGenericNavigatorLanguage? genericNavigatorLanguage : "en";
		if(appUsedLanguage != "en"){
			console.log("picker.translated "+appUsedLanguage);
			return new RSVP.Promise(function(resolve, reject){
				try{
					console.log("about to load picker.translated ++++++ "+appUsedLanguage);
					require(["libs/pickadate/translations/"+appUsedLanguage+"_"+appUsedLanguage.toUpperCase()],function(loadedFiled){
						resolve(loadedFiled);
						console.log("about to load picker.translated success  +++++"+appUsedLanguage);

					});
				}catch(error){
					reject(error);
					console.log("about to load picker.translated failure ++++ "+appUsedLanguage);
				}
			})
		}
	})