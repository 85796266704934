import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import AudioFilter from "models/audioEditor/filters/AudioFilter";
import AudioBufferUtils from "models/audioEditor/AudioBufferUtils";
import IntegratedLoudnessHelper from "models/audioEditor/IntegratedLoudnessHelper";
import RSVP from "rsvp";

const PRESETS ={};

const PRESET_IDS = {

}


const CLASSNAME = "EBUNormalizeFilter";
const EBUNormalizeFilter =  AudioFilter.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        AudioFilter.apply(this, [attributes, options]);
        this.setPresetIdToConfigMap(PRESETS);
        this.set("type", EBUNormalizeFilter.CLASSNAME);

    },

    setTargetLoudness : function(targetLoudness){
        this.set("targetLoudness",targetLoudness);
    },

    getTargetLoudness : function(){
        return this.get("targetLoudness");
    },

    isEqual : function(filter){
        if(filter instanceof EBUNormalizeFilter){
            return (this.isDisabled() == filter.isDisabled() && this.getTargetLoudness() === filter.getTargetLoudness()); 
        }
        return false;
    },

    clone : function(){
        const cloneFilter = new EBUNormalizeFilter();
        cloneFilter.setDisabled(this.isDisabled());
        cloneFilter.setTargetLoudness(this.getTargetLoudness());
        return cloneFilter;
    },

    needToPrepareAudioContext: function(){
        return false;
    },

    prepareAudioContext: function(audioContext){
        return RSVP.Promise.resolve(audioContext);
    },

    applyFilter : function(previousOutputNode, audioContext, arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime, sequencerRendererTracksInfo, audioBuffer){
        if(!this.isDisabled()){
            return IntegratedLoudnessHelper.getInstance().getIntegratedLoudness(audioBuffer).then((function(measuredLoundess){
                const targetLoudness = this.getTargetLoudness();
                if(measuredLoundess != targetLoudness && !isNaN(measuredLoundess)){

                    if(!previousOutputNode){
                        previousOutputNode = audioContext.createBufferSource();
                        previousOutputNode.buffer = audioBuffer;
                        if(arrayOfAudioNodesUsed){
                            arrayOfAudioNodesUsed.push(previousOutputNode);
                        }
                    }

                    const gainNode = audioContext.createGain();
                    gainNode.gain.value = AudioBufferUtils.getInstance().getAmpFromDb(targetLoudness - measuredLoundess); 
                    previousOutputNode.connect(gainNode);
                    if(arrayOfAudioNodesUsed){
                        arrayOfAudioNodesUsed.push(gainNode);
                    }

                    return gainNode;
                }else{
                    if(isNaN(measuredLoundess)){
                        console.error("measuredLoundess value is "+measuredLoundess);
                    }
                    return previousOutputNode;
                }
            }).bind(this));
        }else{
            return RSVP.Promise.resolve(previousOutputNode);
        }
    },

});

EBUNormalizeFilter.CLASSNAME =  CLASSNAME;

export default EBUNormalizeFilter; 