import _ from 'underscore';
import Backbone from "backbone";

//THIS IS BASED OF RECORDERJS. THE MAIN DIFFERENCE IS THAT IS NOT A SERVICE WORKER	
let WavAudioEncoder;
(function(self) {
    const min = Math.min,
    max = Math.max;

    const setString = function(view, offset, str) {
        const len = str.length;
        for (let i = 0; i < len; ++i)
            view.setUint8(offset + i, str.charCodeAt(i));
    };

    const Encoder = function(sampleRate, numChannels) {
        this.sampleRate = sampleRate;
        this.numChannels = numChannels;
        this.numSamples = 0;
        this.dataViews = [];
    };

    Encoder.prototype.encode = function(buffer) {
        let len = buffer[0].length,
        nCh = this.numChannels,
        view = new DataView(new ArrayBuffer(len * nCh * 2)),
        offset = 0;
        for (let i = 0; i < len; ++i)
            for (let ch = 0; ch < nCh; ++ch) {
                const x = buffer[ch][i] * 0x7fff;
                view.setInt16(offset, x < 0 ? max(x, -0x8000) : min(x, 0x7fff), true);
                offset += 2;
            }
            this.dataViews.push(view);
            this.numSamples += len;
        };

        Encoder.prototype.finish = function(mimeType) {
            const dataSize = this.numChannels * this.numSamples * 2,
            view = new DataView(new ArrayBuffer(44));
            setString(view, 0, 'RIFF');
            view.setUint32(4, 36 + dataSize, true);
            setString(view, 8, 'WAVE');
            setString(view, 12, 'fmt ');
            view.setUint32(16, 16, true);
            view.setUint16(20, 1, true);
            view.setUint16(22, this.numChannels, true);
            view.setUint32(24, this.sampleRate, true);
            view.setUint32(28, this.sampleRate * 4, true);
            view.setUint16(32, this.numChannels * 2, true);
            view.setUint16(34, 16, true);
            setString(view, 36, 'data');
            view.setUint32(40, dataSize, true);
            this.dataViews.unshift(view);
            const blob = new Blob(this.dataViews, { type: 'audio/wav' });
            this.cleanup();
            return blob;
        };

        Encoder.prototype.cancel = Encoder.prototype.cleanup = function() {
            delete this.dataViews;
        };

        self.WavAudioEncoder = Encoder;
        WavAudioEncoder = Encoder;
    })(self);



const TrebbleRecorderRTC =  Backbone.Model.extend({

    initialize : function(source, config) {
        this.recLength = 0;
        this.recBuffers = [];
        this.config = config;
        this.source = source;
        this.sampleRate =  config.sampleRate|| 4096;
        this.numChannels = config.numChannels|| 2;
        this.recording = false;
        this.context = source.context;
        this.node = config && config.processor? config.processor: (this.context.createScriptProcessor || this.context.createJavaScriptNode).call(this.context,this.sampleRate, this.numChannels, this.numChannels);
        this.initBuffers();
        this.node.onaudioprocess = this._onAudioProcess.bind(this);
        this.encoder = new WavAudioEncoder(this.sampleRate, this.numChannels);
        this.useWavAudioEncoder = true;
        this.source.connect(this.node);
        if(!this.config || !this.config.nodeAlreadyConnected){
            this.node.connect(this.context.destination);    //this should not be necessary
        }
    },

    initBuffers :function(){
        for (let channel = 0; channel < this.numChannels; channel++) {
            this.recBuffers[channel] = [];
        }
    },

    _onAudioProcess :function(e){
        if (!this.recording) return;
        const buffer = [];
        if(this.useWavAudioEncoder){
            for (let channel = 0; channel < this.numChannels; channel++) {
                buffer.push(e.inputBuffer.getChannelData(channel));
            }
            this.encoder.encode(buffer);
        }else{
            for (let channel = 0; channel < this.numChannels; channel++){
                this.recBuffers[channel].push(e.inputBuffer.getChannelData(channel));

            }
            this.recLength += e.inputBuffer.getChannelData(0).length;
        }
    },

    record : function(){
        this.recording = true;
    },

    stop : function(){
        this.recording = false;
    },

    clear: function() {
        this.recLength = 0;
        this.recBuffers = [];
        this.initBuffers();
    },

    exportWAV : function(cb, type){
        const currCallback = cb || this.config.callback;
        let audioBlob;
        type = type || this.config.type || 'audio/wav';
        if (!currCallback) throw new Error('Callback not set');
        if(this.useWavAudioEncoder){
            audioBlob  = this.encoder.finish();
        }else{
            const buffers = [];
            for (let channel = 0; channel < this.numChannels; channel++) {
                buffers.push(this._mergeBuffers(this.recBuffers[channel], this.recLength));
            }
            let interleaved = undefined;
            if (this.numChannels === 2) {
                interleaved = this._interleave(buffers[0], buffers[1]);
            } else {
                interleaved = buffers[0];
            }
            const dataview = this._encodeWAV(interleaved);
            audioBlob = new Blob([dataview], { type: type });

        }
        currCallback(audioBlob);
    },

    _mergeBuffers: function(recBuffers, recLength) {
        const result = new Float32Array(recLength);
        let offset = 0;
        for (let i = 0; i < recBuffers.length; i++) {
            result.set(recBuffers[i], offset);
            offset += recBuffers[i].length;
        }
        return result;
    },

    _interleave: function(inputL, inputR) {
        const length = inputL.length + inputR.length;
        const result = new Float32Array(length);

        let index = 0,
        inputIndex = 0;

        while (index < length) {
            result[index++] = inputL[inputIndex];
            result[index++] = inputR[inputIndex];
            inputIndex++;
        }
        return result;
    },

    _floatTo16BitPCM: function(output, offset, input) {
        for (let i = 0; i < input.length; i++, offset += 2) {
            const s = Math.max(-1, Math.min(1, input[i]));
            output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
        }
    },

    _writeString: function(view, offset, string) {
        for (let i = 0; i < string.length; i++) {
            view.setUint8(offset + i, string.charCodeAt(i));
        }
    },

    _encodeWAV: function(samples) {
        const buffer = new ArrayBuffer(44 + samples.length * 2);
        const view = new DataView(buffer);

        /* RIFF identifier */
        this._writeString(view, 0, 'RIFF');
        /* RIFF chunk length */
        view.setUint32(4, 36 + samples.length * 2, true);
        /* RIFF type */
        this._writeString(view, 8, 'WAVE');
        /* format chunk identifier */
        this._writeString(view, 12, 'fmt ');
        /* format chunk length */
        view.setUint32(16, 16, true);
        /* sample format (raw) */
        view.setUint16(20, 1, true);
        /* channel count */
        view.setUint16(22, this.numChannels, true);
        /* sample rate */
        view.setUint32(24, this.sampleRate, true);
        /* byte rate (sample rate * block align) */
        view.setUint32(28, this.sampleRate * 4, true);
        /* block align (channel count * bytes per sample) */
        view.setUint16(32, this.numChannels * 2, true);
        /* bits per sample */
        view.setUint16(34, 16, true);
        /* data chunk identifier */
        this._writeString(view, 36, 'data');
        /* data chunk length */
        view.setUint32(40, samples.length * 2, true);

        this._floatTo16BitPCM(view, 44, samples);

        return view;
    },





});
export default TrebbleRecorderRTC;