import _ from 'underscore';
import BasicSequencerNodeInspectorView from "views/audioEditor/sequencerNodeInspectorSection/BasicSequencerNodeInspectorView";
import WordParamView from "views/audioEditor/sequencerNodeInspectorSection/paramView/WordParamView";
import TitleParamView from "views/audioEditor/sequencerNodeInspectorSection/paramView/TitleParamView";
import InfoBoxView from "views/common/InfoBoxView";
import ti18n from 'i18n!nls/Sequenceri18n';


const DeletedSequencerNodeInspectorView = BasicSequencerNodeInspectorView.extend({


    constructor : function(attributes, options) {
        BasicSequencerNodeInspectorView.apply(this, [attributes, options]);
        this._numberOfElementsSelected =  attributes.numberOfElementsSelected;

    },

    listenToChangeEventsOnModel :function(){

    },

    getCustomCssClass : function(){
        return "deleted_content_inpector_container";
    },

    renderAllParamViews : function(){
        const paramViewArray  = [];
        /*const titleParamView =  new TitleParamView();
        titleParamView.setValue(window.getI18n(ti18n, "DELETED_CONTENT"));
        titleParamView.render();
        paramViewArray.push(titleParamView);*/
        /*const wordParamView =  new WordParamView();
        wordParamView.setLabel(window.getI18n(ti18n, "NUMBER_OF_DELETED_ELEMENTS") +": ");
        wordParamView.setValue(this.getSequencerNode() && this.getSequencerNode().getArrayOfNodeReplaced() &&  this.getSequencerNode().getArrayOfNodeReplaced().length);
        wordParamView.render();
        paramViewArray.push(wordParamView);*/
        const numberOfElementsDeleted = this.getSequencerNode() && this.getSequencerNode().getArrayOfNodeReplaced() &&  this.getSequencerNode().getArrayOfNodeReplaced().length;
        const infoBoxView =  new InfoBoxView({
            "iconClass" :"soundwave-multi-select",
            "message" : `${numberOfElementsDeleted} ${numberOfElementsDeleted === 1?window.getI18n(ti18n, "ELEMENT_DELETED"):window.getI18n(ti18n, "ELEMENTS_DELETED")}`,
        });

        infoBoxView.render();
        paramViewArray.push(infoBoxView);


        return paramViewArray;
    }


});
export default DeletedSequencerNodeInspectorView;