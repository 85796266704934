import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerOperationLog from "models/audioEditor/sequencerOperationLog/SequencerOperationLog";
import RSVP from "rsvp";

const SequencerOperationLoadProjectLog =  SequencerOperationLog.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperationLog.apply(this, [attributes, options]);
        
    },

    getOperationName : function(){
        return "Load Project";
    },


    setProjectId : function(projectId){
        this.set("projectId", projectId);
    },

    getProjectId : function(){
        return this.get("projectId");
    },

    setArrayOfSequencerNodes : function(arrayOfSequencerNodes){
        this.set("arrayOfSequencerNodes", arrayOfSequencerNodes);
    },

    getArrayOfSequencerNodes : function(){
        return this.get("arrayOfSequencerNodes");
    },

    setSequencerNodeToInsertNextTo : function(sequencerNodeToInsertNextTo){
        this.set("sequencerNodeToInsertNextTo", sequencerNodeToInsertNextTo);
    },

    getSequencerNodeToInsertNextTo : function(){
        return this.get("sequencerNodeToInsertNextTo");
    },

    setInsertBefore : function(insertBefore){
        this.set("insertBefore", insertBefore);
    },

    getInsertBefore : function(){
        return this.get("insertBefore");
    },




});

export default SequencerOperationLoadProjectLog; 