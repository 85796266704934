import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import RSVP from "rsvp";

const TrebbleAudio =  Backbone.Model.extend({
    idAttribute: "audioId",

    getAudioId : function(){
        return this.get("audioId");
    },

    getTitle : function(){
        return this.get("title");
    },

    setTitle : function(newTitle){
        return this.set("title", newTitle);
    },

    getArtist : function(){
        return this.get("artist");
    },

    setArtist : function(artist){
        return this.set("artist", artist);
    },

    getCreationDate : function(){
        return new Date(this.get("creationDate"));
    },

    getAudioType : function(){
        return this.get("audioType");
    },

    isActive : function(){
        return this.get("active");
    },

    isVideo : function(){
        return this.get("isVideo");
    },

    getDuration: function(){
        return this.get("duration")//in seconds
    },


    getAudioUrl : function(){
        return this.get("audioUrl");
    },

    setFetchAudioUrlRetrievalFunction : function(retrievalFunction){
        this.set("audioUrlRetrievalFunction", retrievalFunction);
    },

    canFetchAudioUrl : function(){
        return !!this.get("audioUrlRetrievalFunction");
    },

    isNotStorable : function(){
        return !!this.get("notStorable");
    },


    fetchAudioUrl : function(){
        const retrievalFunction  = this.get("audioUrlRetrievalFunction");
        return retrievalFunction().then((function(fetchedAudioUrl){
            this.set("audioUrl", fetchedAudioUrl);
            return fetchedAudioUrl;
        }).bind(this));
    },

    notifyUsage : function(){
        const notifyUsageFunction  = this.get("notifyUsageFunction");
        if(notifyUsageFunction){
            return notifyUsageFunction();
        }else{
            return RSVP.Promise.resolve();
        }
    },


    getImageArtUrl : function(){
        return this.get("imageArtUrl");
    },

    getAlbumArt: function(){
        return this.get("imageArtUrl");
    },

    getAlbumArtImages: function(){
        return null;
    },


    getCategoryId : function(){
        return this.get("categoryId");
    },

    getCanBeDeleted : function(){
        return this.get("canBeDeleted");
    },

    getCanBeFavorited : function(){
        return this.get("canBeFavorited");
    },

    setSelected :function(isSelected){
        this.set("selected",isSelected);
    },

    isSelected :function(){
        return this.get("selected")
    },	

    setModelSourceReference: function(modelSourceReference){
        this.set("modelSourceReference",modelSourceReference);
    },

    getModelSourceReference: function(){
        return this.get("modelSourceReference");
    },



    
});
export default TrebbleAudio;