import _ from 'underscore';
import SequencerOperationLog from "models/audioEditor/sequencerOperationLog/SequencerOperationLog";

const SequencerOperationInsertAudioOrVideoNodesInBulkLog =  SequencerOperationLog.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperationLog.apply(this, [attributes, options]);
        
    },

    getOperationName : function(){
        return "Insert Audio Or Video Nodes In Bulk";
    },

    setAudioOrVideoSequencerNodeArray : function(audioOrVideoSequencerNodeArray){
        this.set("audioOrVideoSequencerNodeArray", audioOrVideoSequencerNodeArray);
    },

    getAudioOrVideoSequencerNodeArray : function(){
        return this.get("audioOrVideoSequencerNodeArray");
    },

    setSequencerNodeToInsertNextTo : function(sequencerNodeToInsertNextTo){
        this.set("sequencerNodeToInsertNextTo", sequencerNodeToInsertNextTo);
    },

    getSequencerNodeToInsertNextTo : function(){
        return this.get("sequencerNodeToInsertNextTo");
    },

    setInsertBefore : function(insertBefore){
        this.set("insertBefore", insertBefore);
    },

    getInsertBefore : function(){
        return this.get("insertBefore");
    },





});

export default SequencerOperationInsertAudioOrVideoNodesInBulkLog; 