import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import AudioBufferHelper from "models/audioEditor/AudioBufferHelper";
import WAAClock from "libs/waaclock/WAAClock";
import AudioFilterFactory from "models/helper/Utils";
import Utils from "rsvp";

const AudioContextScheduler =  Backbone.Model.extend({
    idAttribute: "id",

    constructor: function(attributes, options) {
        Backbone.Model.apply(this, [attributes, options]);
        this._audioContext = attributes.audioContext;
        this._offlineAudioContext = null;
        if(this._audioContext instanceof OfflineAudioContext){
            this._offlineAudioContext = this._audioContext;
        }else{
            this._waaClock =  new WAAClock(this._audioContext);
        }

    },

    getAudioContext: function(){
        return this._audioContext;
    },

    isOfflineContext : function(){
        return !this._waaClock;
    },

    start: function(){
        if(!this.isOfflineContext()){
            return this._waaClock.start(); 
        }
    },

    callbackAtTime: function(functionToExecute, deadline, toleranceValues){
        if(this.isOfflineContext()){
            return functionToExecute();
        }else{
            const event = this._waaClock.callbackAtTime(functionToExecute, deadline); 
            if(toleranceValues){
                event.tolerance(toleranceValues);
            }
            return event;
        }
    },

    stop: function(){
        if(!this.isOfflineContext()){
            return this._waaClock.stop(); 
        }
    },

    startRendering: function(){
        if(this.isOfflineContext()){
            return this._audioContext.startRendering();
        }
        
    },





});

export default AudioContextScheduler; 