import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import DynamicContentView from "views/common/DynamicContentView";
import ti18n from "i18n!nls/SetupVoiceEnabledDevicei18n";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import SetupVoiceEnabledDeviceContentTemplate from 'text!../../../templates/setupVoiceEnabledDevices/SetupVoiceEnabledDeviceContentTemplate.html';
import RSVP from "rsvp";

const SetupVoiceEnabledDeviceContentView = DynamicContentView.extend({


    initialize : function(options) {

        const viewOptions = {};
        const template = _.template(SetupVoiceEnabledDeviceContentTemplate);
        let templateParams = options;
        if(!templateParams){
            templateParams= {};
        }
        templateParams.ti18n =  ti18n;
        this.template = template;
        this.templateParams =templateParams;
        //contentView.$el = $(contentViewTemplate(templateParams));
        // viewOptions.contentView = contentView;
        // viewOptions.contentPosition = "center";

        //viewOptions.id = "SetupVoiceEnabledDevicesPopup";




        DynamicContentView.prototype.initialize.call(this, {"canBeAppendedBeforeLoad": true});
    },

    load : function(){
        if(!this._initialized){
            this.$el.html(this.template(this.templateParams));
            this.$el.enhanceWithin();
            this._initialized = true;
            this.$el.find("#listenOnAlexaBtn").on("click",this.onAlexaOptionChosen.bind(this));
            this.$el.find("#listenOnGoogleBtn").on("click",this.onGoogleOptionChosen.bind(this));
            this.$el.find("#listenOnSiriBtn").on("click",this.onSiriOptionChosen.bind(this));
            this.$el.find("#listenOnBixbyBtn").on("click",this.onBixbyOptionChosen.bind(this));
            this.$el.find(".try_on_google_assistant_btn").on("click",this.onTryOnGoogleAssistantButtonTapped.bind(this));
            this.$el.find(".add_to_siri_btn").on("click",this.addShortcutToSiri.bind(this));
            this.$el.find(".modify_siri_phare_btn").on("click",this.modifySiriPhrase.bind(this));
            this.$el.find(".open_trebble_skill_page_btn").on("click",this.onOpenTrebbleSkillPageBtnClicked.bind(this));
            this.$el.find(".open_trebble_google_action_page_btn").on("click",this.onOpenTrebbleGoogleActionPageBtnClicked.bind(this));
            this.$el.find(".open_samsung_marketplace_page_btn").on("click",this.onOpenTrebbleCapsulePageBtnClicked.bind(this));
            this.showSiriOptionIfAvailable();
        }
        return RSVP.Promise.resolve();
    },

    onTryOnGoogleAssistantButtonTapped : function(){
        this.trigger("tryPlayFeedOnGoogleAssistantPage");
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("setupVoiceEnabledDevice", 'tryPlayFeedOnGoogleAssistant', 'Try Play Capsule Feed on Google Assistant');
        }
    },

    addShortcutToSiri : function(){
        this.trigger("addShortcutToSiri");
    },

    showSiriOptionIfAvailable:function(){
        if('cordova' in window && window.cordova && window.cordova.plugins && window.cordova.plugins.SiriShortcuts && window.isIOSMobileDevice){
            //Siri available
            if(LocalStorageHelper.getInstance().isShortcutToPlayCapsuleFeedWasAddedToSiri() && LocalStorageHelper.getInstance().getShortcutPhraseToPlayCapsuleFeedWasAddedToSiri()){
                this.$el.find(".custom_phrase").html(LocalStorageHelper.getInstance().getShortcutPhraseToPlayCapsuleFeedWasAddedToSiri());
                this.$el.find(".siri_instructions").attr("state","shorcut_phrase_instructions");
            }else{
                this.$el.find(".siri_instructions").attr("state","no_shortcut_setup");
            }
        }else{
            if(!this.$el.find(".setup_voice_enabled_device_wrapper").hasClass("siri_not_available")){
                this.$el.find(".setup_voice_enabled_device_wrapper").addClass("siri_not_available")
            }
        }
    },

    onOpenTrebbleSkillPageBtnClicked : function(){
        this.trigger("openTrebbleSkillPage");
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("setupVoiceEnabledDevice", 'showTrebbleSkillPage', 'Show Trebble Skill Page');
        }
    },  

    onOpenTrebbleGoogleActionPageBtnClicked : function(){
        this.trigger("openTrebbleGoogleActionPage");
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("setupVoiceEnabledDevice", 'showTrebbleActionPage', 'Show Trebble Action Page');
        }
    },  

    onOpenTrebbleCapsulePageBtnClicked  : function(){
        this.trigger("openTrebbleBixbyCapsule");
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("setupVoiceEnabledDevice", 'showTrebbleBixbyCapsulePage', 'Show Trebble Bixby Capsule Page');
        }
    },  

    onAlexaOptionChosen : function(){
        this.$el.find(".setup_voice_enabled_device_wrapper").attr("step","show_alexa_instructions");
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("setupVoiceEnabledDevice", 'showSetupForAlexa', 'Show Setup For Alexa');
        }

    },

    isSpecificInstuctionDisplayed : function(){
        return this.$el.find(".setup_voice_enabled_device_wrapper").attr("step") !== "choose_from_device_list";
    },

    showAllInstructionOptions : function(){
        return !!this.$el.find(".setup_voice_enabled_device_wrapper").attr("step","choose_from_device_list")
    },



    modifySiriPhrase: function(){
        this.trigger("modifySiriPhrase");
    },

    setSiriShortcutWasSuccessfullyChanged :function(){
        this.$el.find(".custom_phrase").html(LocalStorageHelper.getInstance().getShortcutPhraseToPlayCapsuleFeedWasAddedToSiri());
        this.$el.find(".siri_instructions").attr("state","shortcut_succesfully_setup");
    },

    onGoogleOptionChosen : function(){
        this.$el.find(".setup_voice_enabled_device_wrapper").attr("step","show_google_instructions");
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("setupVoiceEnabledDevice", 'showSetupForGoogleAssistant', 'Show Setup For Google Assistant');
        }

    },

    onSiriOptionChosen : function(){
        this.$el.find(".setup_voice_enabled_device_wrapper").attr("step","show_siri_instructions");
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("setupVoiceEnabledDevice", 'showSetupForSiri', 'Show Setup For Siri');
        }

    },

    onBixbyOptionChosen : function(){
        this.$el.find(".setup_voice_enabled_device_wrapper").attr("step","show_bixby_instructions");
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("setupVoiceEnabledDevice", 'showSetupForBixby', 'Show Setup For Bixby');
        }

    },

    render : function() {

        return this;
    }

});

export default SetupVoiceEnabledDeviceContentView;