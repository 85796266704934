
import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import ti18n from "i18n!nls/UpgradeCreatorSubscriptionWidgeti18n";
import ReplacePaymentForCreatorSubscriptionWidgetTemplate from 'text!../../../templates/upgradeCreatorSubscription/ReplacePaymentForCreatorSubscriptionWidgetTemplate.html';
import RSVP from 'rsvp';

const USE_CAPTCHA_VALIDATION = true;
const CAPTCHA_SITE_KEY = "6LfInWknAAAAACtY26KHdXIImyAdvLZHq_MCt-Nz";
const ReplacePaymentForCreatorSubscriptionWidgetView = Backbone.View.extend({



    initialize : function(options) {
        this.compiledTemlate =  _.template(ReplacePaymentForCreatorSubscriptionWidgetTemplate);
    },
    
    events : {
        
    },

    
    setContentLoading : function(isLoading){
        if(isLoading){
            this.$el.attr("is_content_loading", "true");
        }else{
            this.$el.attr("is_content_loading", "false");
        }
    },

    displayNoPaymentError: function(){
        this._displayPaymentError(false);
    },

    _displayPaymentError: function(displayError, errorMessage){
        this.$el.attr("display_payment_error", displayError?"true": "false");
        this.$el.find("#payment-error-message").html(Utils.getInstance().getErrorMessageFromObject(errorMessage));
    },

    displayPaymentError: function(errorMessage){
        this._displayPaymentError(true, errorMessage);
    },


    onUpdatePaymentMethodClicked : function(){
        this.trigger("updatePaymentMethod");
    },

    getPaymentFormContainer$el: function(){
        return this.paymentElement$el;
    },

    onChangeCancelBtnClicked : function(){
        this.trigger("cancelChange");
    },

    getCaptchaReponse : function(){
        if(USE_CAPTCHA_VALIDATION){
            return (window.googleCaptchaLoaded && window.grecaptcha)? !!window.grecaptcha.getResponse(this._captchaWidgetId): null;
        }else{
            return true;
        }
    },

    _getCaptchaWidgetId : function(){
        return this._captchaWidgetId;
    },

    _initCaptcha : function(){
        if(USE_CAPTCHA_VALIDATION && window.googleCaptchaLoaded && window.grecaptcha){
            this._captchaWidgetId = window.grecaptcha.render(this.captchaContainer$el.get(0), {
              'sitekey' : CAPTCHA_SITE_KEY,
              'callback' : this.onCaptchaSuccessfull.bind(this),
              'expired-callback	' : this.onCaptchaExpired.bind(this),
              //'error-callback' : this.onCaptchaError.bind(this),
              'theme' : 'light'
            });
        }
    },

    onCaptchaSuccessfull : function(captchaToken){
        this.enableDisableCompletePaymentButton(true);
        this.trigger("captchaSuccess", captchaToken);	
    },

    onCaptchaExpired : function(){
        this.enableDisableCompletePaymentButton(false);
        this.trigger("captchaExpired");
    },

    enableDisableCompletePaymentButton :function(enable){
        if(enable){
            this.$el.find(".updatePaymentMethodBtn").removeClass("disabled")
            this.$el.find(".updatePaymentMethodBtn").prop( "disabled", false );
        }else{
            this.$el.find(".updatePaymentMethodBtn").addClass("disabled")
            this.$el.find(".updatePaymentMethodBtn").prop( "disabled", true );
        }
    },


    render : function() {
        const templateParams = {};
        this.$el.html(this.compiledTemlate(templateParams));
        this.setElement(this.$el.find("section").first());
        this.paymentElement$el =  this.$el.find("#payment-element");
        this.captchaContainer$el =  this.$el.find("#google-captcha-element");
        this.$el.find(".updatePaymentMethodBtn:not(.disabled)").click(this.onUpdatePaymentMethodClicked.bind(this));
        this.$el.find(".cancelBtn").click(this.onChangeCancelBtnClicked.bind(this));
        this._initCaptcha();
        
        return this;
    }

});
export default ReplacePaymentForCreatorSubscriptionWidgetView;