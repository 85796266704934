import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import PeakMeterWidgetViewTemplate from 'text!../../../templates/common/PeakMeterWidgetViewTemplate.html';
import RSVP from 'rsvp';

const PeakMeterWidgetView = Backbone.View.extend({



    initialize : function(options) {
        this.compiledTemlate =  _.template(PeakMeterWidgetViewTemplate);
        this._isMeterRunning = false;
        this._meterValue = null;
    },
    
    events : {
        
    },

    isStarted : function(){
        return this._isMeterRunning;
    },

    startMeter: function(stream, audioContext){
        this.stream = stream;
        this.audioContext = audioContext;
        const mediaStreamAudioSourceNode = audioContext.createMediaStreamSource(stream);
        const analyserNode = audioContext.createAnalyser();
        mediaStreamAudioSourceNode.connect(analyserNode);



        analyserNode.fftSize = 2048;
        const sampleBuffer = new Float32Array(analyserNode.fftSize);

        const onFrame = (function(){
            if(this.stream === stream && this.audioContext === audioContext){
                if(audioContext.state === "suspended"|| audioContext.state === "closed"){
                    this._updateMeterValueWithDBValue(-60, -60);
                    if(this._isMeterRunning){
                        window.requestAnimationFrame(onFrame);
                    }
                }else{
                    analyserNode.getFloatTimeDomainData(sampleBuffer);


                    let sumOfSquares = 0;
                    for (let i = 0; i < sampleBuffer.length; i++) {
                        sumOfSquares +=  Math.pow(sampleBuffer[i], 2);
                    }
                    const avgPowerDecibels = 10 * Math.log10(sumOfSquares / sampleBuffer.length);

                    let peakInstantaneousPower = 0;
                    for (let i = 0; i < sampleBuffer.length; i++) {
                        const power = Math.pow(sampleBuffer[i], 2);
                        peakInstantaneousPower = Math.max(power, peakInstantaneousPower);
                    }
                    const peakInstantaneousPowerDecibels = 10 * Math.log10(peakInstantaneousPower);

                    this._updateMeterValueWithDBValue(avgPowerDecibels, -60);
                    if(this._isMeterRunning){
                        window.requestAnimationFrame(onFrame);
                    }
                }
            }
        }).bind(this);
        window.requestAnimationFrame(onFrame);


        this._isMeterRunning = true;
    },

    stopMeter : function(){
        this._isMeterRunning = false;
    },



    _updateMeterValueWithDBValue : function(currentDbValue, floorDbValue){
        if(floorDbValue > currentDbValue){
            currentDbValue = floorDbValue;
        }
        const meterValue  = (floorDbValue * (-1) + currentDbValue) * 100 /Math.abs(floorDbValue)
        this._meterValue = meterValue;
        const pid$els = this.$el.find(".pid")
        const numberOfPids = pid$els.length;
        const numberOfPidsToColor = Math.round(meterValue * numberOfPids/100);
        this.$el.find(".pid").each(function(index){
            let activeClassName  = "";
            if(index < (numberOfPids * 14/30))
            {
                activeClassName = "activeGreen";
            }else{
                if(index <= (numberOfPids * 26/30))
                {
                    activeClassName = "activeYellow";
                }else{
                    activeClassName = "activeRed";
                }
            }
            if(index < numberOfPidsToColor){
                $(this).addClass(activeClassName);
            }else{
                $(this).removeClass(activeClassName);
            }

        })

    },



    render : function() {
        const templateParams = {};
        this.$el.html(this.compiledTemlate(templateParams));
        this.setElement(this.$el.find("div").first());
        return this;
    }

});
export default PeakMeterWidgetView;