import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerOperationLog from "models/audioEditor/sequencerOperationLog/SequencerOperationLog";
import RSVP from "rsvp";

const SequencerOperationCorrectTranscriptSingleSequencerNodeOperation =  SequencerOperationLog.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperationLog.apply(this, [attributes, options]);
        
    },

    getOperationName : function(){
        return "Correct Transcript On Word Sequencer Node";
    },

    setWordSequencerNode : function(wordSequencerNode){
        this.set("wordSequencerNode", wordSequencerNode);
    },

    getWordSequencerNode : function(){
        return this.get("wordSequencerNode");
    },

    setOldTranscriptText : function(oldTranscriptText){
        this.set("oldTranscriptText", oldTranscriptText);
    },

    getOldTranscriptText : function(){
        return this.get("oldTranscriptText");
    },

    setNewTranscriptText : function(newTranscriptText){
        this.set("newTranscriptText", newTranscriptText);
    },

    getNewTranscriptText : function(){
        return this.get("newTranscriptText");
    },




});

export default SequencerOperationCorrectTranscriptSingleSequencerNodeOperation; 