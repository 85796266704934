import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import AudioFilter from "models/audioEditor/filters/AudioFilter";
import RSVP from "rsvp";

const PRESETS ={};

const PRESET_IDS = {

}


const CLASSNAME = "NormalizeFilter";

const NormalizeFilter =  AudioFilter.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        AudioFilter.apply(this, [attributes, options]);
        this.setPresetIdToConfigMap(PRESETS);
        this.set("type", NormalizeFilter.CLASSNAME);
    },

    isEqual : function(filter){
        if(filter instanceof NormalizeFilter){
            return (this.isDisabled() == filter.isDisabled()); 
        }
        return false;
    },

    clone : function(){
        const cloneFilter = new NormalizeFilter();
        cloneFilter.setDisabled(this.isDisabled());
        return cloneFilter;
    },

    
    needToPrepareAudioContext: function(){
        return false;
    },

    prepareAudioContext: function(audioContext){
        return RSVP.Promise.resolve(audioContext);
    },

    
    
    applyFilter : function(previousOutputNode, audioContext, arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime, sequencerRendererTracksInfo, audioBuffer){
        if(!this.isDisabled()){
            
            if(!previousOutputNode){
                previousOutputNode = audioContext.createBufferSource();
                previousOutputNode.buffer = audioBuffer;
                if(arrayOfAudioNodesUsed){
                    arrayOfAudioNodesUsed.push(previousOutputNode);
                }
            }

            const decodedBuffer = audioBuffer.getChannelData(0);
            const sliceLen = Math.floor(audioBuffer.sampleRate * 0.05);
            const averages = [];
            let sum = 0.0;
            for (let i = 0; i < decodedBuffer.length; i++) {
                sum += decodedBuffer[i] * 2;
                if (i % sliceLen === 0) {
                    sum = Math.sqrt(sum / sliceLen);
                    averages.push(sum);
                    sum = 0;
                }
            }
            // Ascending sort of the averages array
            averages.sort(function(a, b) { return a - b; });
            // Take the average at the 95th percentile
            const a = averages[Math.floor(averages.length * 0.95)];

            let gain = 1.0 / a;
            // Perform some clamping
            // gain = Math.max(gain, 0.02);
            // gain = Math.min(gain, 100.0);

            // ReplayGain uses pink noise for this one one but we just take
            // some arbitrary value... we're no standard
            // Important is only that we don't output on levels
            // too different from other websites
            gain = gain / 10.0;

            const gainNode = audioContext.createGain();
            gainNode.gain.value = gain; 
            previousOutputNode.connect(gainNode);

            if(arrayOfAudioNodesUsed){
                arrayOfAudioNodesUsed.push(gainNode);
            }

            return RSVP.Promise.resolve(gainNode);

        }else{
            return RSVP.Promise.resolve(previousOutputNode);
        }
    },


});

NormalizeFilter.CLASSNAME = CLASSNAME;

export default NormalizeFilter; 