import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import JourneyCardView from "views/journey/JourneyCardView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import FeatureRolloutHelper from "models/helper/FeatureRolloutHelper";
import PurchaseManager from "models/helper/PurchaseManager";
import ti18n from 'i18n!nls/JourneyCardi18n';
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const Rollout = FeatureRolloutHelper.getInstance();
const USE_POPUP_WHEN_WAITING_FOR_STRIPE_PURCHASE_TO_COMPLETE =  Rollout.getFeatureVariable(Rollout.FEATURES.JOURNEY_PURCHASE_CONFIG, Rollout.FEATURES.JOURNEY_PURCHASE_CONFIG.variables.use_popup_when_waiting_for_purchase_to_complete, true);



const JourneyCardController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.model = options.model;
        this.context = options.context;
        this.tuneOptions =  options.tuneOptions;
        this._showEditablePageIfApplicable = options.showEditablePageIfApplicable;
        this._customImageUrlProperty = options.customImageUrlProperty? options.customImageUrlProperty : null;
        this._notTransparent = options.notTransparent ? options.notTransparent: false;
        this._notPlayable =  options.notPlayable ? options.notPlayable: false;
        this._notDetailable =  options.notDetailable ? options.notDetailable: false;
        this._smallcardsize = options.smallcardsize ? options.smallcardsize: false;
        this._doNotShowSubscribeButton = options.doNotShowSubscribeButton ? options.doNotShowSubscribeButton : false;
        _.extend(this, Backbone.Events);
        this._listenToPlayerEvents();
        this.listenTo(PurchaseManager.getInstance(), PurchaseManager.getInstance().eventTypes.JOURNEY_PURCHASE_INITIATED, this._onJourneyPurchaseInitiatied);
        this.listenTo(PurchaseManager.getInstance(), PurchaseManager.getInstance().eventTypes.JOURNEY_PURCHASE_INTERUPTED, this._onJourneyPurchaseCompletedOrInterrupted);
        this.listenTo(PurchaseManager.getInstance(), PurchaseManager.getInstance().eventTypes.JOURNEY_PURCHASE_COMPLETED, this._onJourneyPurchaseCompletedOrInterrupted);
        if(this.model){
            this.listenTo(this.model,"change:isFollowing", this.updateFollowAndBuyNowButton.bind(this));
            this.listenTo(this.model,"change:hasPurchased", this.updateFollowAndBuyNowButton.bind(this));
            this.listenTo(this.model,"change:numberOfFollowers", this.updateFollowAndBuyNowButton.bind(this));
        }
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    destroy: function(){
        try{
            this.stopListening();
            if(this.view && this.view.remove){
                this.view.remove();
            }
        }catch(error){
            console.error(error);
        }
    },

    _listenToPlayerEvents : function(){
        if(!this._notPlayable){
            this.listenTo(PersistentModels.getInstance().getPlayer(),"songStartedPlaying",this.updatePlaybackButton.bind(this));
            this.listenTo(PersistentModels.getInstance().getPlayer(),"songLoading",this.updatePlaybackButton.bind(this));
            this.listenTo(PersistentModels.getInstance().getPlayer(),"playerLoading",this.updatePlaybackButton.bind(this));
            this.listenTo(PersistentModels.getInstance().getPlayer(),"playerFinishLoading",this.updatePlaybackButton.bind(this));
            this.listenTo(PersistentModels.getInstance().getPlayer(),"songLoaded",this.updatePlaybackButton.bind(this));
            this.listenTo(PersistentModels.getInstance().getPlayer(),"songFailedLoading",this.updatePlaybackButton.bind(this));
            this.listenTo(PersistentModels.getInstance().getPlayer(),"songPaused",this.updatePlaybackButton.bind(this));
            
        }
        this.listenTo(PersistentModels.getInstance(),"journeyInfoUpdatedWithId"+this.model.getLoaderKey(),this.onJourneyInfoUpdated.bind(this));
        this.listenTo(PersistentModels.getInstance(), "journeySummarySubsciptionChanged", this.onPlaylistSummarySubsciptionChanged);
    },

    playTrebblePreview : function(){
        if(this.model){
            const genreId =  (this.tuneOptions &&  this.tuneOptions.length > 0)?this.tuneOptions[0]: null;
            let audioPreviewUrl = genreId?this.model.getAudioPreviewUrlByGenreId(genreId): null;
            if(!audioPreviewUrl){
                audioPreviewUrl = this.model.getAudioPreviewUrl();
            }
            if(audioPreviewUrl){
                PersistentModels.getInstance().getPlayer().playAudioPreview(audioPreviewUrl);
            }else{
                Utils.getInstance().showShortToastMessage(window.getI18n(ti18n, "NO_PREVIEW_YET_AVAILABLE"));
            }
        }
    },

    onJourneyInfoUpdated : function(journeySummaryModelUpdated){
        this.model.set(journeySummaryModelUpdated.toJSON());
        this.view.updateCardView(journeySummaryModelUpdated);
        /*this.view.$el.find(".info .title").html(journeySummaryModelUpdated.getName());
        this.view.$el.find(".info .description").html(journeySummaryModelUpdated.getGoal());
        this.view.$el.find(".info .journey_goal_wrapper span").html(journeySummaryModelUpdated.getGoal());
        this.view.$el.attr("journey_status",journeySummaryModelUpdated.getStatus());
        
        this.view.$el.find(".info .summary").html(journeySummaryModelUpdated.getSummary());
        this.view.$el.find("#coverArtImage").attr("class", "lazy "+ ( journeySummaryModelUpdated.getCoverArtUrl()?'swiper-lazy' : '' ) );
        this.view.$el.find("#coverArtImage").attr("src", ( journeySummaryModelUpdated.getCoverArtUrl() ? window.trebble.globalHelpers.getResizeImageUrl(journeySummaryModelUpdated.getCoverArtUrl(), 400, 400) : journeySummaryModelUpdated.getCoverArtUrl()));
        if(journeySummaryModelUpdated.getJourneyLengthInDays() > 0){
            this.view.$el.find(".footer .lastUpdateTime .journey_length_label").html( " · "+ "<i class='fontello-icon-list journeyOultlineListIcon' ></i>" + journeySummaryModelUpdated.getJourneyLengthInDays() +" "+window.getI18n(ti18n, "DAYS"));
        }else{
            this.view.$el.find(".footer .lastUpdateTime .journey_length_label").html("");
        }*/

    },

    _onJourneyPurchaseInitiatied: function(journeyRadioId){
        if(this.model.getLoaderKey() == journeyRadioId){
            this.view.setBuyNowRefreshing(true);
        }
    },

    _onJourneyPurchaseCompletedOrInterrupted : function(journeyRadioId){
        if(this.model.getLoaderKey() == journeyRadioId){
            this.view.setBuyNowRefreshing(false);
        }
    },

    onPlaylistSummarySubsciptionChanged : function(playlistSummaryModel, subscribed){
        if(this.model && playlistSummaryModel && this.model.getLoaderKey() === playlistSummaryModel.getLoaderKey()){
            this.model.setFollowing(subscribed);
            this.view.updateFollowAndBuyNowButton();
        }
    },

    updateFollowAndBuyNowButton : function(){
        this.view.updateFollowAndBuyNowButton();
    },

    
    onSubscribeTooltipIfApplicableClickedOn : function(){
        if(this.view){
            this.view.hideSubscribeTooltipIfApplicable();
        }
    },

    

    follow : function(){
        if(this.model && !this.model.isFollowing() && this.model.canBeFollowed() && (!this.model.isPaid() || (this.model.isPaid() && this.model.hasPurchased()))){
            this.view.hideSubscribeTooltipIfApplicable();
            return TrebbleClientAPIHelper.getInstance()._doLogin(false, true).then((function(){
                
                return PersistentModels.getInstance().subscribeToJourney(this.model);
                
            }).bind(this));
        }else{
            return RSVP.Promise.resolve();
        }
    },

    buyNow : function(){
        if(this.model && !this.model.isFollowing() && this.model.isPaid() && !this.model.hasPurchased()){
            this.view.hideSubscribeTooltipIfApplicable();
            return TrebbleClientAPIHelper.getInstance()._doLogin(false, true).then((function(){
                if(USE_POPUP_WHEN_WAITING_FOR_STRIPE_PURCHASE_TO_COMPLETE){
                    const onTrebbleSubscriptionSucessHandler =  (function(){

                    }).bind(this);
                    const onTrebbleSubscriptionFailureHandler =  (function(error){

                    }).bind(this);

                    return PersistentModels.getInstance().getRouter().showPurchaseJourneyPopup(this.model.getLoaderKey(), onTrebbleSubscriptionSucessHandler, onTrebbleSubscriptionFailureHandler, true);
                }else{
                    return PurchaseManager.getInstance().initiateJourneyPurchase(this.model());
                }
            }).bind(this));
        }else{
            return RSVP.Promise.resolve();
        }
    },

    unfollowConfirm : function(){
        return new RSVP.Promise((function(resolve, reject){
            try{
                const buttonLabels = [window.getI18n(ti18n, "NO"), window.getI18n(ti18n, "YES")];
                const confirmCallback = (function(buttonIndex){
                    if(buttonIndex ==  2){
                        return this.unfollow().then(resolve).catch(reject);
                    }else{
                        resolve();
                    }
                }).bind(this);
                const message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_UNSUBSCRIBE_FROM") +" \""+ this.model.getName()+"\"?"
                navigator.trebbleNotification.confirm(message , confirmCallback,  window.getI18n(ti18n, "UNSCUBSCRIBE_FROM_TREBBLE"), buttonLabels,null , null);
            }catch(error){
                reject(error);
            }
        }).bind(this));
    } ,


    unfollow : function(){
        if(this.model){
            return PersistentModels.getInstance().unsubscribeFromJourney(this.model);
        }else{
            return RSVP.Promise.resolve();
        }
    },


    updatePlaybackButton : function(){
        if(this.view){
            this.view.updatePlaybackButton();
        }
    },

    pauseTrebble : function(){
        if(PersistentModels.getInstance().getPlayer().isPlaylistCurrentlyLoaded(this.model)){
            PersistentModels.getInstance().getPlayer().pauseAudio();
        }
    },

    stopPreviewAndResumePlayback : function(){
        PersistentModels.getInstance().getPlayer().terminateAudioPreviewAndResumePlayback();
    },

    playTrebble  : function(){
        if(PlayerService.getInstance().isPlaylistCurrentlyLoaded(this.model)){
            if(PlayerService.getInstance().isMusicPlaying()){
                PlayerService.getInstance().pauseMusicPlaying();
            }else{
                PlayerService.getInstance().resumeMusicPlaying();
            }
        }else{
            this.view.setTrebbleIsLoading();
            PlayerService.getInstance().playTrebble(this.model, this.tuneOptions);
        }
    },


    showJourneyDetailsPage  : function(){
        PersistentModels.getInstance().getRouter().showPublicJourneyDetailPage(this.model, true, this._showEditablePageIfApplicable);
    },

    showEditJourneyPage : function(){
        PersistentModels.getInstance().getRouter().showJourneyInfoEditPage(this.model, true);
    },

    showShareJourneyPopup : function(){
        PersistentModels.getInstance().getRouter().showShareJourneyPopup(this.model.getLoaderKey(), true);
    },



    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                const params = {};
                params.model = this.model;
                params.customImageUrlProperty = this._customImageUrlProperty;
                params.notTransparent = this._notTransparent;
                params.smallcardsize = this._smallcardsize;
                params.notDetailable  = this._notDetailable;
                params.notPlayable = this._notPlayable;
                params.doNotShowSubscribeButton = this._doNotShowSubscribeButton;
                this.view = new JourneyCardView(params);
                this.listenTo(this.view, "showJourneyDetailsPage",this.showJourneyDetailsPage);
                this.listenTo(this.view, "showEditJourneyPage",this.showEditJourneyPage);
                this.listenTo(this.view, "showShareJourneyPopup",this.showShareJourneyPopup);
                this.listenTo(this.view, "playTrebble",this.playTrebble);
                this.listenTo(this.view, "stopPreviewAndResumePlayback",this.stopPreviewAndResumePlayback);
                this.listenTo(this.view, "playTrebblePreview",this.playTrebblePreview);
                this.listenTo(this.view, "pauseTrebble", this.pauseTrebble);
                this.listenTo(this.view, "follow", this.follow);
                this.listenTo(this.view, "buyNow", this.buyNow);
                this.listenTo(this.view, "unfollow", this.unfollowConfirm);
                this.listenTo(this.view, "subscribeTooltipIfApplicableClickedOn", this.onSubscribeTooltipIfApplicableClickedOn);
                this.view.render();
                this.view.updatePlaybackButton();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

}

});

export default JourneyCardController;