import React from "react";
import ConfigProvider from "./common/ConfigProvider";
import PropTypes from "prop-types";
import useBackboneModel from "./helper/useBackboneModel";
import { useEffect, useState, useRef , forwardRef} from 'react';
import ti18n from 'i18n!nls/Sequenceri18n';
import SequencerSearchWidget from "../models/audioEditor/SequencerSearchWidget";
import useDefaultTrebbleAntDTokenConfig from "./helper/useDefaultTrebbleAntDTokenConfig";
import Tooltip from "./common/Tooltip";
import SequencerEditBoundariesClipDownloadUtils from "../models/audioEditor/SequencerEditBoundariesClipDownloadUtils";

const SEARCH_TYPE = SequencerSearchWidget.SEARCH_TYPE;
const EditorSearchContextPanel = (props) =>
{
    const themeConfig = useDefaultTrebbleAntDTokenConfig({useEditorTheme: true});
    const searchWidgetData = useBackboneModel(props.searchWidgetModel, (model) => {
        model.toJSON()
        return {
            searchResultIndexInFocus: model.getIndexOfNodeInFocus(),
            numberOfSearchNodesFound: model.getNumberOfSearchNodesFound(),
            arrayOfSequencerNodeInFocus : model.getSequencerNodesCurrentlyInFocus(),
            canMoveToPreviousResult:model.canMoveToPreviousResult(),
            canMoveToNextResult:model.canMoveToNextResult(),
            searchType: model.getSearchType(),
            searchParams: model.getSearchParams()
        }
    });

    const buttonRef = useRef();

    const [hasResults, setHasResults] = useState(searchWidgetData.arrayOfSequencerNodeInFocus && searchWidgetData.arrayOfSequencerNodeInFocus.length > 0);

    useEffect(() => {
        setHasResults(searchWidgetData.arrayOfSequencerNodeInFocus && searchWidgetData.arrayOfSequencerNodeInFocus.length > 0);
    }, [searchWidgetData.arrayOfSequencerNodeInFocus]);


    const onInputValueChange = (e) => {
        console.log(e.target.value);
        props.searchWidgetModel.applyTextSearch(e.target.value);
    }

    const searchInputRef = useRef(null);

    const onApplyAllButtonClick = async() => {
        if(searchWidgetData.searchType == SEARCH_TYPE.EDIT_BOUNDARIES){
            return await SequencerEditBoundariesClipDownloadUtils.getInstance().downloadAllClipWithEditBoundaries(props.sequencer, props.searchWidgetModel);
        }
        if(props.onApplyAllButtonClick){
            props.onApplyAllButtonClick();
        }else{
            props.sequencer.deleteAllSearchResults();
            props.onPanelCloseButtonClick();
        }
    }

   

    useEffect(() =>
    {
        if(props.panelOpened && searchInputRef && searchInputRef.current){
            searchInputRef.current.focus();
        }
    }, [props.panelOpened, searchWidgetData.searchType]);

    const [searchPanelTitle, setSearchPanelTitle] = useState("SEARCH");

    const applyAllButtonLabel = props.applyAllActionButtonLabel || ( searchWidgetData.searchType == SEARCH_TYPE.EDIT_BOUNDARIES ? window.getI18n(ti18n, "DOWNLOAD_ALL"): window.getI18n(ti18n, "REMOVE_ALL"))

    useEffect(() =>
    {
        switch(searchWidgetData.searchType){
            case SEARCH_TYPE.TEXT_SEARCH:
                setSearchPanelTitle("SEARCH");
                break;
            case SEARCH_TYPE.FILLER_WORDS:
                setSearchPanelTitle("FILLER WORD SEARCH");
                break;
            case SEARCH_TYPE.EDIT_BOUNDARIES:
                setSearchPanelTitle("EDIT BOUNDARIES SEARCH");
                break;
            case SEARCH_TYPE.SILENCES:
                setSearchPanelTitle("SILENCE SEARCH");
                break;
            case SEARCH_TYPE.DISFLUENCY_SEARCH:
                setSearchPanelTitle("SPEECH ANOMALIES SEARCH");
                break;
        }
    }, [searchWidgetData.searchType]);

    const [resultFoundText, setResultFoundText] = useState("Result Found");

    useEffect(() =>
    {
        switch(searchWidgetData.searchType){
            case SEARCH_TYPE.TEXT_SEARCH:
                setResultFoundText("Text");
                break;
            case SEARCH_TYPE.FILLER_WORDS:
                setResultFoundText(window.getI18n(ti18n, "FILLER_WORDS"));
                break;
            case SEARCH_TYPE.EDIT_BOUNDARIES:
                setResultFoundText(window.getI18n(ti18n, "EDIT_BOUNDARIES"));
                break;
            case SEARCH_TYPE.DISFLUENCY_SEARCH:
                setResultFoundText(window.getI18n(ti18n, "DISFLUENCIES"));
                break;
            case SEARCH_TYPE.SILENCES:
                setResultFoundText(`${searchWidgetData.searchParams && searchWidgetData.searchParams.maxSilenceInMilliseconds? window.getI18n(ti18n, "SILENCES_LONGER_THAN") + " "+ searchWidgetData.searchParams.maxSilenceInMilliseconds/1000+"s": window.getI18n(ti18n, "SILENCES_FOUND")}`);
                break;
        }
    }, [searchWidgetData.searchType, searchWidgetData.searchParams]);


 

    return (
        <ConfigProvider theme={ themeConfig }>
            <div className="flex flex-col box-border p-2 search_context_panel">
                <div className="flex flex-row">
                    <b className="flex flex-col">
                        {props.title || searchPanelTitle}
                    </b>
                </div>
                 <div className="flex flex-row items-center">
                    <div className="flex flex-col flex-auto items-start w-full">
                        <div className="w-full text-left flex">{ searchWidgetData.searchType === SEARCH_TYPE.TEXT_SEARCH?   <input ref={searchInputRef} className="search_input mousetrap" onChange={(e)=>onInputValueChange(e)} placeholder={ window.getI18n(ti18n, "SEARCH_FOR_A_WORD")}></input>: <span className="py-2">{resultFoundText}</span> }</div>
                    </div>
                </div>
                <b className="py-2 text-left">{props.searchDisplayTitle}</b>
                <div className="flex flex-row justify-center items-center">
                    <div className="flex flex-col">
                        <div>{hasResults? `${searchWidgetData.searchResultIndexInFocus + 1} of ${ searchWidgetData.numberOfSearchNodesFound }`: "0 of 0"}</div>
                    </div>
                    <div className="flex flex-col">
                        <Tooltip title={window.getI18n(ti18n,"PREVIOUS_RESULT")} placement="bottom" align="center">
                            <button onClick={()=>props.searchWidgetModel.moveToPreviousFoundNode()}  disabled={!searchWidgetData.canMoveToPreviousResult} className={`${!searchWidgetData.canMoveToPreviousResult && "disabled"}`}>
                                <span className="icon ion-ios7-arrow-thin-up text-3xl"></span>
                            </button>
                        </Tooltip>
                    </div>
                    <div className="flex flex-col">
                        <Tooltip title={window.getI18n(ti18n,"NEXT_RESULT")} placement="bottom" align="center">
                            <button onClick={()=>props.searchWidgetModel.moveToNextFoundNode()} disabled={!searchWidgetData.canMoveToNextResult} className={`${!searchWidgetData.canMoveToNextResult && "disabled"}`}>
                                <span className="icon ion-ios7-arrow-thin-down text-3xl"></span>
                            </button>
                        </Tooltip>
                    </div>
                    <div className="flex flex-col">
                        <button disabled={!hasResults} className={`${!hasResults && "disabled"} button_with_label `} onClick={onApplyAllButtonClick}  ><label>{applyAllButtonLabel}</label></button>
                    </div>
                </div>
            </div>
        </ConfigProvider>
    );
};
EditorSearchContextPanel.propTypes = {
    title: PropTypes.string,
    showSearchInput: PropTypes.bool,
    searchDisplayTitle: PropTypes.string.isRequired,
    searchWidgetModel: PropTypes.object,
    onPanelCloseButtonClick: PropTypes.func,
    panelOpened: PropTypes.bool,
    resultCursor: PropTypes.number,
    totalNumberOfResults: PropTypes.number,
    applyActionButtonLabel: PropTypes.string,
    applyAllActionButtonLabel : PropTypes.string,
    onApplyButtonClick: PropTypes.func,
    onApplyAllButtonClick: PropTypes.func,
    locale: PropTypes.object,
    sequencer:PropTypes.object
};


export default EditorSearchContextPanel;
