import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import Wavesurfer from 'wavesurfer.js'
import WavesurferRegions from 'wavesurfer.js/dist/plugins/regions.esm.js'
import WavesurferMinimap from 'wavesurfer.js/dist/plugins/minimap.esm.js'
import WavesurferTimeline from 'wavesurfer.js/dist/plugins/timeline.esm.js'
import AudioBufferRegionSelectorTemplate from 'text!../../../templates/audioEditor/AudioBufferRegionSelectorTemplate.html';
import ti18n from 'i18n!nls/Sequenceri18n';
import RSVP from 'rsvp';

const Rollout = RolloutHelper.getInstance();
const SELECTED_REGION_ID = "selectedRegion";

Wavesurfer.regions = WavesurferRegions;

const DO_NOT_SHOW_MINIMAP = false;
const AudioBufferRegionSelectorView = Backbone.View.extend({



    initialize: function (options)
    {
        this.model = options.model;
        this.audioUniqueId = options.audioUniqueId;
        this.audioType = options.audioType;
        this.sequencerSettings = options.sequencerSettings;
        this._zoomLevel = (!this.model.endTime && !this.model.startTime)? 1 : 4;
        this._defaultBasePxPerSec = 0;
        this._defaultMinPxPerSec = 0;
        this._defaultMaxPxPerSec = 8000;
        this.compiledTemlate = _.template(AudioBufferRegionSelectorTemplate);

    },

    events: {
        "click #playRegionBtn": "onPlayRegionButtonClicked",
        "click #pauseRegionBtn": "stopPlayback",
        "click #increaseZoomBtn:not(.disabled)": "onIncreaseZoomButtonClicked",
        "click #decreaseZoomBtn:not(.disabled)": "onDecreaseZoomButtonClicked",
        "click #saveTrimBtn": "onSaveTrimButtonClicked",

    },

    playRegion: function ()
    {
        let currentTimeInMillisec = this.wavesurferInstance.getCurrentTime() * 1000;
        //currentTimeInMillisec = this.getStartTime();
        if (currentTimeInMillisec < this.getStartTime() || currentTimeInMillisec >= this.getEndTime())
        {
            currentTimeInMillisec = this.getStartTime();

        }
        if (currentTimeInMillisec >= this.getStartTime() && currentTimeInMillisec <= this.getEndTime())
        {
            this.wavesurferInstance.setTime(currentTimeInMillisec / 1000)

            const checkTime = () =>
            {
                if (this.wavesurferInstance.getCurrentTime() >= this.getEndTime() / 1000)
                {
                    this.wavesurferInstance.pause()
                    this.wavesurferInstance.setTime(this.getStartTime() / 1000)
                } else
                {
                    requestAnimationFrame(checkTime);
                }
            }

            this.wavesurferInstance.once("play", () =>
            {
                requestAnimationFrame(checkTime);
            })

            this.wavesurferInstance.play();

        }

    },

    onPlayRegionButtonClicked: function ()
    {
        this.playRegion();
    },

    onIncreaseZoomButtonClicked: function ()
    {
        this._zoomLevel = this._zoomLevel + 1;
        this._updateZoom();
    },

    setZoomLevel: function (zoomLevel)
    {
        this._zoomLevel = zoomLevel;
        this._updateZoom();
    },

    _getMinPxPerSec: function ()
    {
        let pxPerSec = this._zoomLevel > 0 ? this._defaultBasePxPerSec + (this._zoomLevel * 100) : this._defaultBasePxPerSec + (this._zoomLevel * 40);
        if (this._zoomLevel > 5)
        {
            pxPerSec = this._defaultBasePxPerSec + (this._zoomLevel * 200)
        }
        if (this._zoomLevel > 7)
        {
            pxPerSec = this._defaultBasePxPerSec + (this._zoomLevel * 400)
        }
        if (this._zoomLevel > 10)
        {
            pxPerSec = this._defaultBasePxPerSec + (this._zoomLevel * 500)
        }
        if (pxPerSec <= this._defaultMinPxPerSec)
        {
            pxPerSec = this._defaultMinPxPerSec;
        }
        if (pxPerSec >= this._defaultMaxPxPerSec)
        {
            pxPerSec = this._defaultMaxPxPerSec;
        }
        return pxPerSec;
    },

    _updateZoom: function ()
    {
        let pxPerSec = this._getMinPxPerSec();
        if (pxPerSec <= this._defaultMinPxPerSec)
        {
            pxPerSec = this._defaultMinPxPerSec;
            this.decreaseZoomBtn$el.addClass("disabled");
        } else
        {
            this.decreaseZoomBtn$el.removeClass("disabled");
        }
        if (pxPerSec >= this._defaultMaxPxPerSec)
        {
            pxPerSec = this._defaultMaxPxPerSec;
            this.increaseZoomBtn$el.addClass("disabled");
        } else
        {
            this.increaseZoomBtn$el.removeClass("disabled");
        }
        if (this.wavesurferInstance)
        {
            $.mobile.loading("show");
            setTimeout((function () { this.wavesurferInstance.zoom(pxPerSec); }).bind(this), 0); // setTimeout is used to make sure that loading icon has enough time to be displaye
        }
    },



    onDecreaseZoomButtonClicked: function ()
    {
        this._zoomLevel = this._zoomLevel - 1;
        this._updateZoom();
    },

    onWavesurferInstanceReady: function ()
    {
        this.initWavesurferRegionInstance();

        if (this._onWavesurferInstanceReadyPromiseResolve)
        {
            this._onWavesurferInstanceReadyPromiseResolve();
            this._onWavesurferInstanceReadyPromiseResolve = null;
        }
    },

    onWavesurferInstanceError: function (errorMessage)
    {
        if (this._onWavesurferInstanceReadyPromiseReject)
        {
            this._onWavesurferInstanceReadyPromiseReject(new Error(errorMessage));
        }
    },

    onWavesurferInstanceRendered: function ()
    {
        this.$el.removeClass("loading");
    },

    getModelStartTime: function(){
        return this.model.endTime ? this.model.startTime / 1000 : 0;
    },


    getModelEndTime: function(){
        return this.model.endTime ? this.model.endTime / 1000 : this.wavesurferInstance.getDuration();
    },

    initWavesurferRegionInstance: function ()
    {
        const startTime = this.getModelStartTime();
        const endTime = this.getModelEndTime();
        const wsRegions = this.wavesurferInstance.registerPlugin(WavesurferRegions.create())
        this.wsRegions = wsRegions;
        this.wsRegions.addRegion({
            id: SELECTED_REGION_ID,
            start: startTime,
            end: endTime,
            loop: false,
            resize: true,
            drag: false,
            hideCursor: true,
            //color: 'rgba(245, 213, 212,0.5)'
            //color: 'rgba(233, 96, 93,0.5)'
            color: "rgba(246, 186, 184, 0.5)"
        });
        this.wavesurferInstance.seekTo(startTime / this.wavesurferInstance.getDuration());
        if (!DO_NOT_SHOW_MINIMAP)
        {
            this._startTimeMinimapLabel$el.html(Utils.getInstance().formatPlaybackPositionToString(0));
            this._endTimeMinimapLabel$el.html(Utils.getInstance().formatPlaybackPositionToString(this.wavesurferInstance.getDuration()));
        }
        this._getRegionWithId(SELECTED_REGION_ID).on("update", this.onRegionUpdated.bind(this));

        this.onRegionUpdated();
        this._customizeHandleBars();
        this._changeStylesOfRegionHandles();
        if (this._isRegionTooSmall(endTime, startTime, this.wavesurferInstance.getDuration()))
        {
            //this.setZoomLevel(4);
        }

    },

    _changeStylesOfRegionHandles: function ()
    {
        const regionHandleRight = this.$el.get(0).querySelector(".audio_buffer_waveform_container > div").shadowRoot.querySelector('div[part="region-handle region-handle-right"]');
        regionHandleRight.style.backgroundColor = '#e9605d';
        regionHandleRight.style.height = '100%';
        regionHandleRight.style.width = '8px';
        regionHandleRight.innerHTML = `<handle-delimiter-up class="" style="
        position: absolute;
        background-color: #e9605d;
        height: 8px;
        width: 16px;
        top: 0px;
        right: 0px;
    "></handle-delimiter-up><handle-delimiter-down class="" style="
        position: absolute;
        background-color: #e9605d;
        height: 8px;
        width: 16px;
        bottom: 0px;
        right: 0px;
    "></handle-delimiter-down>`;
        const regionHandleLeft = this.$el.get(0).querySelector(".audio_buffer_waveform_container > div").shadowRoot.querySelector('div[part="region-handle region-handle-left"]');
        regionHandleLeft.style.backgroundColor = '#e9605d';
        regionHandleLeft.style.height = '100%';
        regionHandleLeft.style.width = '8px';
        regionHandleLeft.innerHTML = `<handle-delimiter-up class="" style="
        position: absolute;
        background-color: #e9605d;
        height: 8px;
        width: 16px;
        top: 0px;
        left: 0px;
    "></handle-delimiter-up><handle-delimiter-down class="" style="
        position: absolute;
        background-color: #e9605d;
        height: 8px;
        width: 16px;
        bottom: 0px;
        left: 0px;
    "></handle-delimiter-down>`
    },

    _getRegionWithId: function (regionId)
    {
        const regions = this.wsRegions.getRegions();
        for (let i = 0; i < regions.length; i++)
        {
            const region = regions[ i ];
            if (region.id === regionId)
            {
                return region;
            }
        }
    },

    _isRegionTooSmall: function (regionEndTimeInSec, regionStartTimeInSec, totalDurationInSeconds)
    {
        if (totalDurationInSeconds * 0.02 > (regionEndTimeInSec - regionStartTimeInSec))
        {
            return true;
        } else
        {
            return false;
        }

    },

    _onZoomCompleted: function ()
    {
        $.mobile.loading("hide");
    },

    getStartTime: function ()
    {
        return this._getRegionWithId(SELECTED_REGION_ID).start * 1000;
    },

    getEndTime: function ()
    {
        return this._getRegionWithId(SELECTED_REGION_ID).end * 1000;
    },

    onRegionUpdated: function ()
    {
        this._startTimeLabel$el.html(Utils.getInstance().formatPlaybackPositionToString(this.getStartTime() / 1000, true));
        this._endTimeLabel$el.html(Utils.getInstance().formatPlaybackPositionToString(this.getEndTime() / 1000, true));
    },


    onAudioStartedPlaying: function ()
    {
        this.$el.addClass("region_playing");
    },


    onAudioStoppedPlaying: function ()
    {
        this.$el.removeClass("region_playing");
    },

    destroyWaveSurferInstance: function ()
    {
        this.wavesurferInstance.destroy();
    },

    /**
    * Use timeInterval to set the period between notches, in seconds,
    * adding notches as the number of pixels per second increases.
    *
    * Note that if you override the default function, you'll almost
    * certainly want to override formatTimeCallback, primaryLabelInterval
    * and/or secondaryLabelInterval so they all work together.
    *
    * @param: pxPerSec
    */
    timeInterval: function (pxPerSec)
    {
        let retval = 1;
        if (pxPerSec >= 25 * 100)
        {
            retval = 0.01;
        } else if (pxPerSec >= 25 * 40)
        {
            retval = 0.025;
        } else if (pxPerSec >= 25 * 10)
        {
            retval = 0.1;
        } else if (pxPerSec >= 25 * 4)
        {
            retval = 0.25;
            /*} else if (pxPerSec >= 25) {
            retval = 1;*/
        } else if (pxPerSec * 5 >= 25)
        {
            retval = 5;
        } else if (pxPerSec * 15 >= 25)
        {
            retval = 15;
        } else
        {
            retval = Math.ceil(0.5 / pxPerSec) * 60;
        }
        return retval;
    },

    /**
    * Return the cadence of notches that get labels in the primary color.
    * EG, return 2 if every 2nd notch should be labeled,
    * return 10 if every 10th notch should be labeled, etc.
    *
    * Note that if you override the default function, you'll almost
    * certainly want to override formatTimeCallback, primaryLabelInterval
    * and/or secondaryLabelInterval so they all work together.
    *
    * @param pxPerSec
    */
    primaryLabelInterval: function (pxPerSec)
    {
        let retval = 1;
        if (pxPerSec >= 25 * 100)
        {
            retval = 10;
        } else if (pxPerSec >= 25 * 40)
        {
            retval = 4;
        } else if (pxPerSec >= 25 * 10)
        {
            retval = 10;
        } else if (pxPerSec >= 25 * 4)
        {
            retval = 4;
        } else if (pxPerSec >= 25)
        {
            retval = 1;
        } else if (pxPerSec * 5 >= 25)
        {
            retval = 5;
        } else if (pxPerSec * 15 >= 25)
        {
            retval = 15;
        } else
        {
            retval = Math.ceil(0.5 / pxPerSec) * 60;
        }
        return retval;
    },

    /**
    * Return the cadence of notches to get labels in the secondary color.
    * EG, return 2 if every 2nd notch should be labeled,
    * return 10 if every 10th notch should be labeled, etc.
    *
    * Secondary labels are drawn after primary labels, so if
    * you want to have labels every 10 seconds and another color labels
    * every 60 seconds, the 60 second labels should be the secondaries.
    *
    * Note that if you override the default function, you'll almost
    * certainly want to override formatTimeCallback, primaryLabelInterval
    * and/or secondaryLabelInterval so they all work together.
    *
    * @param pxPerSec
    */
    secondaryLabelInterval: function (pxPerSec)
    {
        // draw one every 10s as an example
        return Math.floor(5 / this.timeInterval(pxPerSec));
    },

    /*formatTimeCallback : function(timeInSec, pxPerSec){
    return Utils.getInstance().formatPlaybackPositionToString(timeInSec, pxPerSec > this._defaultBasePxPerSec ? true: false);
    },*/


    formatTimeCallback: function (seconds)
    {
        let pxPerSec = this._getMinPxPerSec();
        seconds = Number(seconds);
        const minutes = Math.floor(seconds / 60);
        seconds = seconds % 60;

        // fill up seconds with zeroes
        let secondsStr = Math.round(seconds).toString();
        if (pxPerSec >= 25 * 40)
        {
            secondsStr = seconds.toFixed(2);
        } else if (pxPerSec >= 25 * 1)
        {
            secondsStr = seconds.toFixed(0);
        }

        if (minutes > 0)
        {
            if (seconds < 10)
            {
                secondsStr = '0' + secondsStr;
            }
            return `${minutes}:${secondsStr}`;
        }
        return `${minutes}:${secondsStr}`;
    },

    renderAudioWaveform: function ()
    {
        //this.wavesurferInstance.load(this.model.audioUrl);
        return this.sequencerSettings.getSequencer().getAudioBufferCache().loadAudioBufferFromUrl(this.model.audioUrl, this.audioUniqueId, this.audioType, this.sequencerSettings).then((function (audioBuffer)
        {
            const peaks = [];
            for (let i = 0; i < audioBuffer.numberOfChannels; i++)
            {
                peaks.push(audioBuffer.getChannelData(i));
            }
            const duration = audioBuffer.duration
            this.wavesurferInstance.load(this.model.audioUrl, peaks, duration);
        }).bind(this))
    },

    initWavesurferInstance: function ()
    {
        const pluginsArray = [ WavesurferRegions.create(),
        WavesurferTimeline.create(
            {
                height: 30,
                timeInterval: 0.1,
                primaryLabelInterval: 1,
                style: {
                    fontWeight: 'bold',
                    fontSize: '14px',
                    color: '#111',
                },
            }) ];
        if (!DO_NOT_SHOW_MINIMAP)
        {
            pluginsArray.push(WavesurferMinimap.create({
                container: this.$el.find(".audio_buffer_waveform_minimap_container").get(0),
                showRegions: true,
                cursorColor: "#6327f5",
                overlayColor: "rgba(246, 186, 184, 0.5)"

            }))
        }
        this.wavesurferInstance = Wavesurfer.create({
            container: this.$el.find(".audio_buffer_waveform_container").get(0),

            //	barWidth: 1,
            //	barRadius: 0,
            //	barGap: 4,
            cursorWidth: 4,
            height: 200,
            pixelRatio: 1,
            backend: "WebAudio",
            autoCenter: true,
            minPxPerSec: this._getMinPxPerSec(),
            //cursorColor: "#111",//"white",//"#111",
            progressColor: "#434343",//"white",//"#111",
            waveColor: "#434343",//"white",//"#111",
            cursorColor: "#6327f5",
            plugins: pluginsArray
        });

        this._onWavesurferInstanceReadyPromise = new RSVP.Promise((function (resolve, reject)
        {
            this._onWavesurferInstanceReadyPromiseResolve = resolve;
            this._onWavesurferInstanceReadyPromiseReject = reject;

        }).bind(this))
        this.wavesurferInstance.on('ready', this.onWavesurferInstanceReady.bind(this));
        this.wavesurferInstance.on('play', this.onAudioStartedPlaying.bind(this));
        this.wavesurferInstance.on('pause', this.onAudioStoppedPlaying.bind(this));
        this.wavesurferInstance.on('finish', this.onAudioStoppedPlaying.bind(this));
        this.wavesurferInstance.on('error', this.onWavesurferInstanceError.bind(this));
        this.wavesurferInstance.on('redrawcomplete', this.onWavesurferInstanceRendered.bind(this));
        this.wavesurferInstance.on("zoom", this._onZoomCompleted.bind(this));
        //this._updateZoom();



    },

    _getInnerHandlebarHtml: function (isStart)
    {
        let innerHtml = "<handle-bar class='handle-component wavesurfer-handle-" + (isStart ? 'start' : 'end') + "'></handle-bar>";
        innerHtml = innerHtml + "<handle-delimiter-up class='handle-component  wavesurfer-handle-" + (isStart ? 'start' : 'end') + "'></handle-delimiter-up >";
        innerHtml = innerHtml + "<handle-delimiter-down class='handle-component  wavesurfer-handle-" + (isStart ? 'start' : 'end') + "'></handle-delimiter-down >";
        return innerHtml;
    },
    _customizeHandleBars: function ()
    {
        this.$el.find(".audio_buffer_waveform_container   handle.wavesurfer-handle-start").html(this._getInnerHandlebarHtml(true));
        this.$el.find(".audio_buffer_waveform_container   handle.wavesurfer-handle-end").html(this._getInnerHandlebarHtml(false));
    },


    onSaveTrimButtonClicked: function ()
    {
        this.trigger("saveTrim");
    },
    stopPlayback: function ()
    {
        this.wavesurferInstance.pause();
    },

    render: function ()
    {

        this.$el.html(this.compiledTemlate({ "ti18n": ti18n }));
        this.setElement(this.$el.find("section").first());
        this._startTimeLabel$el = this.$el.find(".trim_from_info");
        this._endTimeLabel$el = this.$el.find(".trim_to_info");

        this._startTimeMinimapLabel$el = this.$el.find(".minimap_start_time");
        this._endTimeMinimapLabel$el = this.$el.find(".minimap_end_time");
        this.decreaseZoomBtn$el = this.$el.find("#decreaseZoomBtn");
        this.increaseZoomBtn$el = this.$el.find("#increaseZoomBtn");
        this.saveTrimBtn$el = this.$el.find("#saveTrimBtn");
        this.$el.addClass("loading");
        this.initWavesurferInstance();

        return this;
    }

});
export default AudioBufferRegionSelectorView;