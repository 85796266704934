import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import HomeMenuTemplate from 'text!../../../templates/homeMenuPanel/HomeMenuPanelTemplate.html';
import ti18n from 'i18n!nls/HomeMenuPaneli18n';

const USER_PROFILE_IMAGE_WIDTH = 50;
const USER_PROFILE_IMAGE_HEIGHT = 50;
const HomeMenuPanelView = Backbone.View.extend({

    template : _.template(HomeMenuTemplate),

    initialize : function(options) {
        this._selectedMenuId  = null;
        this._userInfo =options? options.userInfo : null;
        this._isUserAllowToSetupTrebble = options? options.isUserAllowToSetupTrebble: false;
        this._isUserAllowToCreateFirstJourney = options? options.isUserAllowToCreateFirstJourney: false;
        this._isOpen = false
    },

    events : {
        "click #myLibraryBtn" : "showLibraryPage",
        "click #searchBtn" : "showGlobalSearch",
        "click #helpAndFeedbackBtn" : "showHelpAndFeedback",
        "click #trebblesBtn" : "showTrebblesPage",
        "click #mySubscriptionsBtn" : "showMySubscriptionsPage",
        "click #myJourneysBtn" : "showMyJourneysPage",
        "click #homeBtn" : "showHomePage",
        "click #onAirBtn" : "showOnAirPage",
        "click .loggedInUserInfoBox > .userCoverArtWrapper" : "showLoggedInUserPage",
        "click #feedsAndNotificationBtn" : "showfeedsAndNotificationPage",
        "click #settingBtn" : "showSettingsPage",
        "click #exploreBtn" : "showExplorePage",
        "click #capsulesBtn" : "showCapsulesPage",
        "click #referBtn": "showReferPage",
        "click #downloadExtensionBtn": "installChromeExtension",
        "click #createMyOwnTrebbleBtn":"createMyOwnTrebble",
        "click #createMyFirstJourneyBtn":"createMyFirstJourney",
        "click #installPWABtn": "installWPA",
        "click #upgradeBtn": "showUpgradeToPaidSubscriptionPopup",
        "click #helpBtn" : "showHelpPage",
    },

    toggle : function(){
        /*if(this._isOpen){
            this.$el.panel("close");
        }else{
            this.$el.panel("open");
        }*/
        this.$el.panel("toggle");
    },

    onAnalyseAudioPlayingBtnTapped : function(ev){
        this.trigger("analyseAudioOutput");
    },

    showLibraryPage : function(ev){
        this._updateSelectedMenu(ev);
        this.trigger("showLibraryPage");
    },


    showReferPage : function(ev){
        this._updateSelectedMenu(ev);
        this.trigger("showReferPage");
    },

    installChromeExtension: function(){
        this.trigger("installChromeExtension");
        this.$el.panel("close");
    },

    installWPA: function(){
        this.trigger("installWPA");
        this.$el.panel("close");
    },
    
    showUpgradeToPaidSubscriptionPopup : function(){
        this.trigger("showUpgradeToPaidSubscriptionPopup");
        this.$el.panel("close");
    },

    createMyOwnTrebble : function(){
        this.trigger("createMyOwnTrebble");
        this.$el.panel("close");
    },

    createMyFirstJourney : function(){
        this.trigger("createMyFirstJourney");
        this.$el.panel("close");
    },

    showUpgradeBtn : function(){
        this.$el.find("#upgradeBtn").css('display', 'flex');
    },

    hideUpgradeBtn : function(){
        this.$el.find("#upgradeBtn").css('display', 'none');
    },

    showMyJourneysBtn : function(){
        this.$el.find("#myJourneysBtn").css('display', 'flex');
    },

    hideMyJourneysBtn : function(){
        this.$el.find("#myJourneysBtn").css('display', 'none');
    },

    showInstallWPABtn : function(){
        this.$el.find("#installPWABtn").show();
    },

    hideInstallWPABtn : function(){
        this.$el.find("#installPWABtn").hide();
    },

    showReferUserMenuBtn :function(){
        this.$el.find("#referBtn").hide();
    },

    hideReferUserMenuBtn :function(){
        this.$el.find("#referBtn").hide();
    },

    

    hideHelpAndFeedbackMenuBtn :function(){
        this.$el.find("#helpAndFeedbackBtn").hide();
    },

    updateUserInfo : function(newUserInfo, doNotAddTimestampToUrl){
        this._userInfo  = newUserInfo;
        if(this.$el){
            const usernameString = newUserInfo?newUserInfo.getUsername(): window.getI18n(ti18n, "SIGN_IN");
            this.$el.find(".username.itemTitle").html(usernameString);
            const profileImageUrl = newUserInfo? newUserInfo.getProfileImageUrl(): null;
            //profileImageUrl =  (profileImageUrl && !doNotAddTimestampToUrl)?profileImageUrl + "?random="+ (new Date()).getTime(): profileImageUrl;
            if(profileImageUrl){
                //const resizeImageUrl = window.trebble.globalHelpers.getResizeImageUrl(profileImageUrl, USER_PROFILE_IMAGE_WIDTH, USER_PROFILE_IMAGE_HEIGHT);
                this.$el.find("#userProfileImage").css("background-image","url("+window.trebble.globalHelpers.getResizeImageUrl(profileImageUrl, 50,50 )+")");
                this.$el.find("#userProfileImage > .pe-7s-smile").hide();
            }else{
                this.$el.find("#userProfileImage").css("background-image","url(none)");
                this.$el.find("#userProfileImage > .pe-7s-smile").show();
            }
        }
    },

    setAllNotificationRead : function(allNotificationRead){
        if(allNotificationRead){
            this.$el.find(".menuIcon.pe-7s-bell > .ownerDataBubble").removeClass("unread");
        }else{
            this.$el.find(".menuIcon.pe-7s-bell > .ownerDataBubble").addClass("unread");
        }
    },

    setHelpDisplayedAtLeastOnceToUser: function(helpDisplayedOnceToUser){
        if(helpDisplayedOnceToUser){
            this.$el.find(".menuIcon.pe-7s-help2 > .ownerDataBubble").removeClass("unread");
        }else{
            this.$el.find(".menuIcon.pe-7s-help2 > .ownerDataBubble").addClass("unread");
        }
    },

    setUnreadMessagesInHelpSection: function(unreadMessages){
        if(unreadMessages){
            this.$el.find(".menuIcon.pe-7s-help1 > .ownerDataBubble").addClass("unread");
        }else{
            this.$el.find(".menuIcon.pe-7s-help1 > .ownerDataBubble").removeClass("unread");
        }
    },


    showGlobalSearch : function(ev){
        this._updateSelectedMenu(ev);
        this.trigger("showGlobalSearch");
    },


    showHelpAndFeedback : function(ev){
        //this._updateSelectedMenu(ev);
        this.trigger("showHelpAndFeedback");
        this.$el.panel("close");
    },

    showExplorePage : function(ev){
        this._updateSelectedMenu(ev);
        this.trigger("showExplorePage");
    },

    showTrebblesPage : function(ev){
        this._updateSelectedMenu(ev);
        this.trigger("showTrebblesPage");
    },

    showMySubscriptionsPage : function(ev){
        this._updateSelectedMenu(ev);
        this.trigger("showMySubscriptionsPage");
    },

    showMyJourneysPage : function(ev){
        this._updateSelectedMenu(ev);
        this.trigger("showMyJourneysPage");
    },

    showHomePage : function(ev){
        this._updateSelectedMenu(ev);
        this.trigger("showHomePage");
    },
    
    /*
    showHomePage : function(ev){
        //this._updateSelectedMenu(ev);
        this.trigger("showHomePage");
    },*/

    showHelpPage : function(ev){
        //this._updateSelectedMenu(ev);
        this.trigger("showHelpPage");
    },


    showOnAirPage : function(ev){
        //this._updateSelectedMenu(ev);
        this.trigger("showOnAirPage");
    },

    showLoggedInUserPage : function(ev){
        //this._updateSelectedMenu(ev);
        this.trigger("showLoggedInUserPage");
    },

    showfeedsAndNotificationPage  : function(ev){
        //this._updateSelectedMenu(ev);
        this.trigger("showfeedsAndNotificationPage");
    },

    showCapsulesPage : function(ev){
        //this._updateSelectedMenu(ev);
        this.trigger("showCapsulesPage");
    },

    showSettingsPage : function(ev){
        //this._updateSelectedMenu(ev);
        this.trigger("showSettingsPage");
    },


    onBeforeRemoveFromPage : function(){
        this.trigger("beforeRemoveFromPage", this);
    },

    onRemovedFromPage : function(){
        this.undelegateEvents();
        this.$el.removeData().unbind(); 
        this.trigger("removedFromPage", this);
    },

    _updateSelectedMenu : function(event){
        const newSelectedMenuId = event.currentTarget.id;
        this._updateSelectedMenuByMenuId(newSelectedMenuId);
    },

    _updateSelectedMenuByMenuId : function(newSelectedMenuId){
        this.$el.find("#"+this._selectedMenuId).removeClass("selected");
        this.$el.find("#"+newSelectedMenuId).addClass("selected");
        this._selectedMenuId = newSelectedMenuId;
    },

    setMyLibraryPageAsSelectedMenu : function(){
        this._updateSelectedMenuByMenuId("myLibraryBtn");
    },

    setReferPageAsSelectedMenu : function(){
        this._updateSelectedMenuByMenuId("referBtn");
    },

    setTrebblesPageAsSelectedMenu : function(){
        this._updateSelectedMenuByMenuId("trebblesBtn");
    },

    setSearchPageAsSelectedMenu : function(){
        this._updateSelectedMenuByMenuId("searchBtn");
    },

    setHelpAndFeedbackPageAsSelectedMenu : function(){
        this._updateSelectedMenuByMenuId("helpAndFeedbackBtn");
    },

    hideHomeMenu : function(){
        this.$el.find("#homeBtn").hide();
    },


    setOnAirPageAsSelectedMenu : function(){
        this._updateSelectedMenuByMenuId("onAirBtn");
    },

    setSettingsPageAsSelectedMenu : function(){
        this._updateSelectedMenuByMenuId("settingBtn");
    },

    setUserInfoPageAsSelectedMenu : function(){
        this._updateSelectedMenuByMenuId("loggedInUserBtn");
    },

    setExplorePageAsSelectedMenu : function(){
        this._updateSelectedMenuByMenuId("exploreBtn");
    },

    setMySubsciptionsPageAsSelectedMenu : function(){
        this._updateSelectedMenuByMenuId("mySubscriptionsBtn");
    },

    setMyJourneysPageAsSelectedMenu : function(){
        this._updateSelectedMenuByMenuId("myJourneysBtn");
    },

    setHomePageAsSelectedMenu : function(){
        this._updateSelectedMenuByMenuId("homeBtn");
    },

    setFeedAndNotificationsPageAsSelectedMenu : function(){
        this._updateSelectedMenuByMenuId("feedsAndNotificationBtn");
    },

    setCapsulesPageAsSelectedMenu : function(){
        this._updateSelectedMenuByMenuId("capsulesBtn");
    },

    setHelpPageAsSelectedMenu : function(){
        this._updateSelectedMenuByMenuId("helpBtn");
    },
    _onPanelOpen : function(){
        this._isOpen = true;
    },

    _onPanelClose : function(){
        this._isOpen = false;
        this.trigger("panelClosed");
    },
    render : function(){
        this.$el.html(this.template({"ti18n": ti18n}));
        this.setElement(this.$el.find("div").first());
        const panelStyle = "overlay";
        /*if(window.device && window.device.platform == "browser"){
            panelStyle ="push";
        }*/
        $('body').append(this.$el);
        this.$el.panel({ open: this._onPanelOpen.bind(this),  close: this._onPanelClose.bind(this), "display": panelStyle });
        /*if(window.device && window.device.platform == "browser"){
            this.$el.panel("open");
            this._isOpen = true;
        }*/
        if(this._isUserAllowToSetupTrebble){
            //Adding this class will allow the button to be displayed because of the css
            //this.$el.find("#createMyOwnTrebbleBtn").addClass("forceDisplay");
        }
        if(this._isUserAllowToCreateFirstJourney){
            //Adding this class will allow the button to be displayed because of the css
            this.$el.find("#createMyFirstJourneyBtn").addClass("forceDisplay");
        }
        this.updateUserInfo(this._userInfo, true);
        this.$el.find("#trebblesBtn").addClass("selected");
        this._selectedMenuId = "trebblesBtn";
        return this;
    },

});
export default HomeMenuPanelView;