
import _ from 'underscore';
import Backbone from "backbone";
import { LanguageDetector, FilesetResolver } from "@mediapipe/tasks-text";




const LanguageDetectionHelper = Backbone.Model.extend({
    idAttribute: "id",

    constructor: function(attributes, options) {
        Backbone.Model.apply(this, [attributes, options]);
        this._languageDetectorInstance =  null;
        this._languageDetectorInstanceBeingCreated =  null;
    },  

    _createLanguageDetector : async function(){
        const textFiles = await FilesetResolver.forTextTasks(
            "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-text@0.10.0/wasm"
        );
      
        const languageDetector = await LanguageDetector.createFromOptions(textFiles, {
          baseOptions: {
            modelAssetPath:
            new URL('shared/models/language_detector.tflite', import.meta.url),
          },
          scoreThreshold: 0.2,
        });
        return languageDetector;
      },

      _cleanupResults : function(languageDetectionResultArray){
        if(languageDetectionResultArray && languageDetectionResultArray.languages && languageDetectionResultArray.languages.length > 0){
            const languageResult =  languageDetectionResultArray.languages.reduce((currentBestResult, result)=>{
                if(!currentBestResult){
                    return result;
                }else{
                    if(result.probability > currentBestResult.probability){
                        return result;
                    }else{
                        return currentBestResult;
                    }
                }
            }, null);
            if(languageResult && languageResult.languageCode){
                return languageResult.languageCode
            }else{
                return null;
            }
        }else{
            return null;
        }
  

      },

      getLanguageDetector : async function(){
        if(this._languageDetectorInstance){
            return this._languageDetectorInstance;
        }
        if(this._languageDetectorInstanceBeingCreated){
            return await this._languageDetectorInstanceBeingCreated;
        }else{
            this._languageDetectorInstanceBeingCreated = this._createLanguageDetector();
            this._languageDetectorInstance = await this._languageDetectorInstanceBeingCreated;
            this._languageDetectorInstanceBeingCreated = null;
            return this._languageDetectorInstance;
        }
      },

      detect : async function (inputText){
        const languageDetector = await this.getLanguageDetector();
        const results = languageDetector.detect(inputText);
        return this._cleanupResults(results);
      },


      


});



const LanguageDetectionHelperInstance = new LanguageDetectionHelper();

export default {
    getInstance: function ()
    {
        return LanguageDetectionHelperInstance;
    }
}; 