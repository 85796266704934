import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import InstallChromeExtensionPopupView from "views/installChromeExtensionHelper/InstallChromeExtensionHelperPopupView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const InstallChromeExtensionPopupController = Chaplin.Controller.extend({

    initialize : function(options) {

        this.context = options.context;
        _.extend(this, Backbone.Events);
    },




    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },


    downloadExtension : function(){
        
            if( window.chrome||  window.chrome.webstore ||  window.chrome.webstore.install){
                const downloadExtensionUrl = "https://chrome.google.com/webstore/detail/lklapkcgbghemabgngmgfblhoffnbgmd";
                window.chrome.webstore.install(downloadExtensionUrl, function(){}, function(e){throw e;});
            }else{
                return ;	
            }
     

    },



    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {

                const params = {};
                this.view = new InstallChromeExtensionPopupView(params);
                this.listenTo(this.view, "downloadExtension",this.downloadExtension);
                this.view.render();
                resolve();


            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default InstallChromeExtensionPopupController;