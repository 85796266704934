

import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import ChooseMyDraftJourneyWidgetController from "controllers/journey/ChooseMyDraftJourneyWidgetController";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import CollectionRepeatController from "controllers/common/CollectionRepeatController";
import TrebbleAudioListItemController from "controllers/common/TrebbleAudioListItemController";
import PlaylistHelper from "models/helper/PlaylistHelper";
import TrebbleAudio from "models/TrebbleAudio";
import FileUploadHelper from "models/helper/FileUploadHelper";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import ti18n from "i18n!nls/ConfigureBackgroundMusici18n";
import FacebookEventTrackingHelper from "models/helper/FacebookEventTrackingHelper";
import OptionPopupView from "views/common/OptionPopupView";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const Rollout = RolloutHelper.getInstance();


const ChooseMyDraftJourneyPopupController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        
        this._selectedJourneyModel = null;
        this._popupTitle = options.popupTitle;
        
        this._onJourneySelected = options.onJourneySelected;
    },


    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },


    onPopupClosed : function(){
        this._onJourneySelected(this._selectedJourneyModel);
    },


    _onDraftJourneySelectedViaWidget : function(journeyModel){
        this._selectedJourneyModel = journeyModel;
        this.view.close();
    },


    _buildChooseMyDraftJourneyWidgetController : function(){
        const params = {};
        params.onJourneySelected = this._onDraftJourneySelectedViaWidget.bind(this);
        this.chooseMyDraftJourneyWidgetController = new ChooseMyDraftJourneyWidgetController(params);
    },

    buildModelAndRenderView : function() {
        this._buildChooseMyDraftJourneyWidgetController();
        return this.chooseMyDraftJourneyWidgetController.buildModelAndRenderView().then((function(){
            const params = {};
            params.id = "chooseMyDrafJourneysPopup";
            params.customClass = "chooseMyDrafJourneysPopup";
            params.contentView =  this.chooseMyDraftJourneyWidgetController.getView();
            params.contentPosition = "center";
            params.addContentAfterEnhancement = true;
            params.displayTitle = true;
            params.titleToDisplay = this._popupTitle;
            this.view = new OptionPopupView(params);
            this.listenTo(this.view, "closed",this.onPopupClosed);
            this.view.render();
            this.chooseMyDraftJourneyWidgetController.setScrollContainer(this.view.$el.find(".selected_content_wrapper"));
        }).bind(this))


    }

});

export default ChooseMyDraftJourneyPopupController;