import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import DeletedSequencerNode from "models/audioEditor/sequencerNodes/DeletedSequencerNode";
import RSVP from "rsvp";

const CLASSNAME = "NonRetainedDeletedSequencerNode";
const NonRetainedDeletedSequencerNode =  DeletedSequencerNode.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        DeletedSequencerNode.apply(this, [attributes, options]);

        
    },

    getConstructor : function(){
        return NonRetainedDeletedSequencerNode;
    },

    cloneJSON: function(){
        const objJson = DeletedSequencerNode.prototype.cloneJSON.call(this);
        objJson.type = NonRetainedDeletedSequencerNode.CLASSNAME;
        return objJson;
    },

    getSerializedData : function(){
        const serializedData = DeletedSequencerNode.prototype.getSerializedData.call(this);
        return serializedData;
    },

    restoreFromSerializedData: function(serializedData, audioSegmentFactoryInstance, audioFilterFactoryInstance, additionalSpeakerInfoArray){
        return DeletedSequencerNode.prototype.restoreFromSerializedData.call(this, serializedData, audioSegmentFactoryInstance, audioFilterFactoryInstance, additionalSpeakerInfoArray);
    },

    afterAllInstancesCreatedFromSerializedData : function(serializedData, sequencerNodeCidToSequencerNodeMap){
        DeletedSequencerNode.prototype.afterAllInstancesCreatedFromSerializedData.call(this, serializedData, sequencerNodeCidToSequencerNodeMap);
    },

    setDeletedAudioUrlReference : function(audioUrlReference){
        this.set("audioUrlReference", audioUrlReference)
    },

    getDeletedAudioUrlReference : function(){
        return this.get("audioUrlReference")
    },




});

NonRetainedDeletedSequencerNode.CLASSNAME =  CLASSNAME;
export default NonRetainedDeletedSequencerNode; 