import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import CordovaHelper from "models/helper/CordovaHelper";
import InfoBoxController from "controllers/common/InfoBoxController";
import PlaylistListController from "controllers/common/PlaylistListController";
import Utils from "models/helper/Utils";
import Capsules from "collections/Capsules";
import PlaylistHelper from "models/helper/PlaylistHelper";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import CollectionRepeatController from "controllers/common/CollectionRepeatController";
import TrebbleCardController from "controllers/playlistPodcast/TrebbleCardController";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import CapsuleCardController from "controllers/capsule/CapsuleCardController";
import ti18n from 'i18n!nls/Homei18n';
import PushNotificationHelper from "models/helper/PushNotificationHelper";
import FacebookEventTrackingHelper from "models/helper/FacebookEventTrackingHelper";
import SubscriptionManager from "models/helper/SubscriptionManager";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import HomePageView from "views/home/HomePageView";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";

const Rollout = RolloutHelper.getInstance();

const ENABLE_TREBBLE_PLUS_SUB = Rollout.isFeatureEnabled(Rollout.FEATURES.ENABLE_TREBBLE_PLUS_SUB);


const MySubscriptionsPageController = Chaplin.Controller.extend({


    initialize: function(options) {
        _.extend(this, Backbone.Events);
        if (options) {
            if (options.view) {
                this.view = options.view;
            }
            if (options.model) {
                this.model = options.model;
            }
        }

        this.context = Utils.getInstance().buildContextForHomeScreen();
        const playlist = PlaylistHelper.getInstance().generateMyCapsuleFeedPlaylist(LocalStorageHelper.getInstance().getUserInfo()._id);
        this._playlist = playlist;
        this._numberOfSubscriptions = 0;
        this._isFirstLoad = false;
        this._listenToPlayerEvents();
        
        this.listenTo(SubscriptionManager.getInstance(),SubscriptionManager.getInstance().eventTypes.ON_SUBSCRIPTION_UPDATED, this.onUserSubscriptionChanged.bind(this));
        document.addEventListener('playingCapsuleFeed', this.onPlayingCapsuleFeed, false);
    },

    getModel: function() {
        return this.model;
    },

    getView: function() {
        return this.view;
    },

    onUserSubscriptionChanged : function(productInfo){
        if(ENABLE_TREBBLE_PLUS_SUB && SubscriptionManager.getInstance().isPaidAccountSubscriptionOrderAvailable()){
            return SubscriptionManager.getInstance().isCurrentUserSubscribedToTrebblePlus(false, productInfo? true:false).then((function(isSubscribedToTrebblePlus){
                if(isSubscribedToTrebblePlus){
                    this.view.hideUpgradeBtn();
                }else{
                    this.view.showUpgradeBtn();
                }
            }).bind(this));
        }else{
            this.view.hideUpgradeBtn();
        }
    },


    onPlayingCapsuleFeed: function() {
        const TIME_IN_SECONDS_TO_WAIT_BEFORE_PROMPTING_USER_TO_RATE = Rollout.getFeatureVariable(Rollout.FEATURES.PROMPT_USER_TO_RATE_APP, Rollout.FEATURES.PROMPT_USER_TO_RATE_APP.variables.number_of_seconds_to_wait_before_prompting_user_to_rate, 15);
        const functionToExecute = function(){
            PersistentModels.getInstance().getRouter().showAppRatingRequestIfApplicable();
        };
        Utils.getInstance().setTimeoutAndShowAsSoonAsAppGoesInForeground(functionToExecute, TIME_IN_SECONDS_TO_WAIT_BEFORE_PROMPTING_USER_TO_RATE * 1000);

    },


    showExplorePage: function() {
        return PersistentModels.getInstance().getRouter().showExplorePage(null, true);
    },

    _listenToPlayerEvents: function() {

        this.listenTo(PersistentModels.getInstance().getPlayer(), "songStartedPlaying", this.updatePlaybackButton.bind(this));
        this.listenTo(PersistentModels.getInstance().getPlayer(), "songLoading", this.updatePlaybackButton.bind(this));
        this.listenTo(PersistentModels.getInstance().getPlayer(), "playerLoading", this.updatePlaybackButton.bind(this));
        this.listenTo(PersistentModels.getInstance().getPlayer(), "playerFinishLoading", this.updatePlaybackButton.bind(this));
        this.listenTo(PersistentModels.getInstance().getPlayer(), "songLoaded", this.updatePlaybackButton.bind(this));
        this.listenTo(PersistentModels.getInstance().getPlayer(), "songFailedLoading", this.updatePlaybackButton.bind(this));
        this.listenTo(PersistentModels.getInstance().getPlayer(), "songPaused", this.updatePlaybackButton.bind(this));
        this.listenTo(PersistentModels.getInstance(), "subscriptionChanged", this.onSubscriptionChanged);
    },




    getNumbrOfSubscriptions: function() {
        return this._numberOfSubscriptions;
    },

    onSubscriptionChanged: function() {
        this.onPageBeforeShow(true);
    },

    isUserStartedTheSessionWithNoSubscription: function() {
        return this._userStartedTheSessionWithNoSubscription;
    },



    updatePlaybackButton: function() {
        if (this._playlist) {
            if (PersistentModels.getInstance().getPlayer().isPlaylistCurrentlyLoaded(this._playlist)) {
                if (PersistentModels.getInstance().getPlayer().isLoading() || PersistentModels.getInstance().getPlayer().isPlayerLoading()) {
                    this.view.setPlaying(false, true);
                } else {
                    if (PersistentModels.getInstance().getPlayer().isPlaying()) {
                        this.view.setPlaying(true, false);
                    } else {
                        this.view.setPlaying(false, false);
                    }
                }
            } else {
                this.view.setPlaying(false, false);
            }
        } else {
            this.view.setPlaying(false, false);
        }

    },



    onPlaylistPlayButtonTapedHandler: function(playlistSummarySelectedModel) {
        if (PlayerService.getInstance().isPlaylistCurrentlyLoaded(playlistSummarySelectedModel)) {
            if (PlayerService.getInstance().isMusicPlaying()) {
                PlayerService.getInstance().pauseMusicPlaying();
            } else {
                PlayerService.getInstance().resumeMusicPlaying();
            }
        } else {
            PlayerService.getInstance().playTrebble(playlistSummarySelectedModel);
        }
    },



    _initializeMyCapsuleFeed: function() {
        const controllerParams = {};
        controllerParams.showActiveFlagInView = false;
        controllerParams.context = this.context;
        const collectionRepeatControllerParams = {};
        //collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildInfoBoxParamsForOffAirCapsules();
        collectionRepeatControllerParams.showEmtptyInfoBox = true;
        collectionRepeatControllerParams.controllerOption = controllerParams;
        collectionRepeatControllerParams.itemControllerConstructor = CapsuleCardController;
        collectionRepeatControllerParams.modelLoadHandler = this._getMyCapsuleFeedLoadHandler.bind(this);
        collectionRepeatControllerParams.context = this.context;
        collectionRepeatControllerParams.resizePlaceHolderElem = true;
        collectionRepeatControllerParams.heightMargin = 52 + 39; //Page Header + Tab Header
        this._myCapsuleFeedListLoaded = false;
        this.myCapsuleFeedCollectionRepeatController = new CollectionRepeatController(collectionRepeatControllerParams);
        return this.myCapsuleFeedCollectionRepeatController;
    },


    _buildInfoBoxParamsForMyCapsuleFeed: function() {
        const params = {};
        params.iconClass = "ion-contrast";
        params.message = window.getI18n(ti18n, "YOUR_CAPSULE_FEED_IS_EMPTY");


        return params;

    },


    _getMyCapsuleFeedLoadHandler: function() {

        return TrebbleClientAPIHelper.getInstance().getMyCapsuleFeed().then(function(capsulesInfoArray) {
            if (capsulesInfoArray && capsulesInfoArray.length > 0) {
                this.capsulesCollection = new Capsules(capsulesInfoArray);
                return this.capsulesCollection.models;
            } else {
                return [];
            }
        });
    },


    updateOnFeedTodayBox: function() {
        if (!this.view.isOnYourFeedTodayBoxHidden()) {
            this.view.setFeedBoxLoading();
            return TrebbleClientAPIHelper.getInstance().getTrebbleNamesAndLatestHeadlinesInUserSubsription().then((function(trebbleNamesAndLatestHeadlinesInUserSubsription) {
                if (trebbleNamesAndLatestHeadlinesInUserSubsription && trebbleNamesAndLatestHeadlinesInUserSubsription.length > 0) {
                    this.view.setFeedBoxContent(trebbleNamesAndLatestHeadlinesInUserSubsription);
                } else {
                    this.view.setFeedBoxHasNoContent();
                }
                this.view.showHideFloatingPlayButtonOnScrollIfApplicable();
            }).bind(this)).catch((function(error) {
                this.view.setErrorOnFeedBoxLoading(error);

            }).bind(this));
        }
    },

    onPageBeforeShow: function(pageRefreshWasTriggerBecauseOfSubscriptionChange) {

     if (this.myCapsuleFeedCollectionRepeatController && this.myCapsuleFeedCollectionRepeatController.getView()) {
        this.myCapsuleFeedCollectionRepeatController.reload().then((function() {

            

        }).bind(this));
    }


    this.updatePlaybackButton();
    this.updateOnFeedTodayBox();


},




playCapsuleFeed: function() {
    if (this.view.hasNoSuscription()) {
        this.onPlayMyFeedWithNoSubscriptions();
    } else {
        if (this._playlist && PersistentModels.getInstance().getPlayer().isPlaylistCurrentlyLoaded(this._playlist)) {
            if (PersistentModels.getInstance().getPlayer().isLoading()) {

                return;
            } else {
                if (PersistentModels.getInstance().getPlayer().isPlaying()) {
                    return PersistentModels.getInstance().getPlayer().pauseAudio();
                } else {
                    return PersistentModels.getInstance().getPlayer().playAudio();
                }
            }
        } else {
            this.view.setPlaying(false, true);
            FacebookEventTrackingHelper.getInstance().trackCapsuleFeedPlayed();
            if (window.trebbleAnalyticsHelper) {
                window.trebbleAnalyticsHelper.trackEvent("MySubscriptions", 'CapsuleFeedPlayed', 'Capsule Feed Played');
            }
            return PersistentModels.getInstance().getPlayer().setPlaylist(this._playlist, true).then((function() {

            }).bind(this)).catch((function(error) {
                this.view.setPlaying(false, false);
            }).bind(this));
        }
    }

},


showHomePopup: function() {
    PersistentModels.getInstance().getRouter().showHomePage();
},


onCapsuleListStartLoading: function() {
    if (this.view) {
        this.view.setLoadingSubscriptions(true);
    }
},

onCapsuleListFinishLoading: function() {
    if (this.view) {
        this.view.setLoadingSubscriptions(false);
    }
    this.view.$el.find("#subscriptionList #collection_repeat_container").trigger("scrollstop");
},




onPlayMyFeedWithNoSubscriptions: function() {
    if (window.trebbleAnalyticsHelper) {
        window.trebbleAnalyticsHelper.trackEvent("MySubscriptions", 'playMyFeedWithNoSubscriptions', 'User tried to play capsule feed with no subscription');
    }

    const buttonLabels = [window.getI18n(ti18n, "GOT_IT"), window.getI18n(ti18n, "ADD_SUBSCRIPTIONS")];
    const confirmCallback = (function(buttonIndex) {

     this.showExplorePage();
 }).bind(this);

    const message = window.getI18n(ti18n, "NOTHING_TO_PLAY_YET_SUBSCRIBE_FIRST");

    navigator.trebbleNotification.confirm(message, confirmCallback, null, [window.getI18n(ti18n, "ADD_SUBSCRIPTIONS")]);
},



showUpgradeToPaidSubscriptionPopup: function() {
    PersistentModels.getInstance().getRouter().showUpgradeToPaidSubscriptionPopup();
},

showSettingsPage: function(){
    PersistentModels.getInstance().getRouter().showSettingsPage(true);
},

showHelpPage : function(){
    PersistentModels.getInstance().getRouter().showHelpAndFeedback(null, true);
},

startCategoryShortcastRadio : function(){
    PersistentModels.getInstance().getRouter().showStartCategoryShortcastRadioPopup();
},



buildModelAndRenderView: function() {
    return new RSVP.Promise((function(resolve, reject) {
        try {
            this._initializeMyCapsuleFeed();


            const promiseArray = [];
            // promiseArray.push(this.myCapsuleFeedCollectionRepeatController.buildModelAndRenderView());


            RSVP.Promise.all(promiseArray).then((function() {
             // this.myCapsuleFeedListView = this.myCapsuleFeedCollectionRepeatController.getView();



             const homePageParams = {};
             homePageParams.myCapsuleFeedListView = this.myCapsuleFeedListView;

             this.view = new HomePageView(homePageParams);

             this.listenTo(this.view, "pageBeforeShow", this.onPageBeforeShow);
             this.listenTo(this.view, "showHomePopup", this.showHomePopup);
             this.listenTo(this.view, "showHelpPage", this.showHelpPage);
             
             this.listenTo(this.view, "playMyFeed", this.playCapsuleFeed.bind(this));
             this.listenTo(this.view, "showExplorePage", this.showExplorePage.bind(this));
             this.listenTo(this.view, "addToSubscriptions", this.showExplorePage);
             this.listenTo(this.view, "showSettingsPage", this.showSettingsPage);
             this.listenTo(this.view, "startCategoryShortcastRadio", this.startCategoryShortcastRadio);
             
             

             this.listenTo(this.view, "showUpgradeToPaidSubscriptionPopup", this.showUpgradeToPaidSubscriptionPopup.bind(this));


             this.view.render();



             this.onUserSubscriptionChanged();
             resolve();
         }).bind(this)).catch(reject);
} catch (error) {
reject(error);
}

}).bind(this));


}

});

export default MySubscriptionsPageController;