import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import OptionPopupView from "views/common/OptionPopupView";
import popupTemplate from 'text!../../../templates/audioEditor/SequencerNodeBasicContextOptionContent.html';
import ti18n from "i18n!nls/Sequenceri18n";

const SequencerNodeBasicContextOptionView = OptionPopupView.extend({

    initialize : function(options) {
        const viewOptions =  {};
        this.model = options.model;
        this.elementSource$el = options.elementSource$el;
        const contentView = {};
        const contentViewTemplate = _.template(popupTemplate);
        const templateParams = {"model" : this.model, 
        "ti18n": ti18n, 
        "deleteAvailable": options.deleteAvailable,
         "wrapWithBackgroundMusicAvailable":options.wrapWithBackgroundMusicAvailable,
         "playFromHereAvailable": options.playFromHereAvailable,  
         "insertAvailable": options.insertAvailable,
        "moreOptionAvailable":  options.moreOptionAvailable,
        "downloadClipAvailable":options.downloadClipAvailable
        };
        contentView.$el  = $(contentViewTemplate(templateParams));
        viewOptions.contentView = contentView;
        viewOptions.contentPosition = "center";
        viewOptions.elementSource$el = this.elementSource$el;
        viewOptions.showAsMenuIfInBrowser = true;
        viewOptions.alwaysShowAsFloatingMenu = true;
        viewOptions.customPopupPosition = options.popupPosition? options.popupPosition: "top middle";
        viewOptions.id = "SequencerNodeBasicContextOptionPopup";
        viewOptions.hideHeading = true;
        viewOptions.customClass = "sequencerNodeBasicContextMenu";

        OptionPopupView.prototype.initialize.call(this, viewOptions);
    },

    afterRendering : function(){
        this.$el.find("#ignoreSelectedNodesBtn").click(this._onIgnoreSelectedNodesBtnClicked.bind(this));
        this.$el.find("#wrapWithMusicBtn").click(this._onWrapWithMusicBtnClicked.bind(this));
        this.$el.find("#insertBtn").click(this._onInsertBtnClicked.bind(this));
        this.$el.find("#playFromBtn").click(this._onPlayFromBtnClicked.bind(this));
        this.$el.find("#downloadClipBtn").click(this._onDownloadClipBtnClicked.bind(this));
        this.$el.find("#moreOptionBtn").click(this._onShowMoreOptionBtnClicked.bind(this));
        
        
    },

    getMoreOptionBtn$el : function(){
        return this.$el.find("#moreOptionBtn");
    },

    _onIgnoreSelectedNodesBtnClicked :function(event){
        if(!$(event.target).hasClass("disabled")){
            this.trigger("ignoreSelectedNodes");
            this.close();
        }
    },

    _onWrapWithMusicBtnClicked :function(event){
        if(!$(event.target).hasClass("disabled")){
            this.trigger("wrapWithMusic");
            this.close();
        }
    },


    _onInsertBtnClicked :function(event){
        if(!$(event.target).hasClass("disabled")){
            this.trigger("insert");
            this.close();
        }
    },

    _onPlayFromBtnClicked :function(event){
        if(!$(event.target).hasClass("disabled")){
            this.trigger("playFromHere");
            this.close();
        }
    },

    _onDownloadClipBtnClicked :function(event){
        if(!$(event.target).hasClass("disabled")){
            this.trigger("downloadClip");
            this.close();
        }
    },


    _onShowMoreOptionBtnClicked :function(event){
        if(!$(event.target).hasClass("disabled")){
            this.trigger("showMoreOptions");
        }
    },

    onAfterPageShow : function(){
        this.$el.addClass("SequencerNodeBasicContextOptionPopup");
    },



});

export default SequencerNodeBasicContextOptionView;