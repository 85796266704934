import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerNode from "models/audioEditor/sequencerNodes/SequencerNode";
import RSVP from "rsvp";

const CLASSNAME = "WrapSequencerNode";
const WrapSequencerNode =  SequencerNode.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerNode.apply(this, [attributes, options]);
        
    },

    getConstructor : function(){
        return WrapSequencerNode;
    },

    
    afterInstanceCreatedFromConstructorForCloning : function(createdClonedInstance, sequencerNodeCidToCloneNodeMap){
        
    },

    cloneJSON: function(){
        const objJson = SequencerNode.prototype.cloneJSON.call(this);
        objJson.type = WrapSequencerNode.CLASSNAME;
        return objJson;
    },

    getSerializedData : function(){
        const serializedData = SequencerNode.prototype.getSerializedData.call(this);
        return serializedData;
    },

    restoreFromSerializedData: function(serializedData, audioSegmentFactoryInstance, audioFilterFactoryInstance, additionalSpeakerInfoArray){
        return SequencerNode.prototype.restoreFromSerializedData.call(this, serializedData, audioSegmentFactoryInstance, audioFilterFactoryInstance, additionalSpeakerInfoArray);
    },

    afterAllInstancesCreatedFromSerializedData : function(serializedData, sequencerNodeCidToSequencerNodeMap){
        return SequencerNode.prototype.afterAllInstancesCreatedFromSerializedData.call(this, serializedData, sequencerNodeCidToSequencerNodeMap);
    },

    _copyStartAndEndWrapNodes : function(sequencerNodeToCopy){
        if(sequencerNodeToCopy){
            this._startWrapNodes = sequencerNodeToCopy._startWrapNodes.slice(0);
            this._endWrapNodes = sequencerNodeToCopy._endWrapNodes.slice(0);
            const myStartNodeIndex = this._startWrapNodes.indexOf(this.getStartWrapSequencerNode());
            if (myStartNodeIndex > -1) {
              this._startWrapNodes.splice(myStartNodeIndex, 1);
            }
            const myEndNodeIndex = this._endWrapNodes.indexOf(this.getEndWrapSequencerNode());
            if (myEndNodeIndex > -1) {
              this._endWrapNodes.splice(myEndNodeIndex, 1);
            }
        }
    },

    isModifyingAudioBufferFromWrappedNodeAudioSegment : function(){
        throw new Error("Need to be implemented in subclass!");
    },

    isCreatingAudioSegmentThatWillBeMergeWithWrappedNodeAudioSegment : function(){
        throw new Error("Need to be implemented in subclass!");
    },

    getCurrentNodeMargin : function(){
        throw new Error("Need to be implemented in subclass!");
    },

    wrapTo :function(startNodeWrapped, endNodeWrapped ){
        throw new Error("Need to be implemented in subclass!");
    },

    unwrap : function(){
        throw new Error("Need to be implemented in subclass!");
    },

    getStartWrapSequencerNode : function(){
        throw new Error("Need to be implemented in subclass!");
    },

    getEndWrapSequencerNode : function(){
        throw new Error("Need to be implemented in subclass!");
    },

    getStartWrappedNode : function(){
        return this.getStartWrapSequencerNode().getNext();
    },

    getEndWrappedNode : function(){
        return this.getEndWrapSequencerNode().getPrevious();
    },


    /*getStartWrappedNode : function(){
        return this._startNodeWrapped;
    },

    getEndWrappedNode : function(){
        return this._endNodeWrapped;
    },

    setStartWrappedNode : function(startNodeWrapped){
        if(this._startNodeWrapped){
            this.stopListening(this._startNodeWrapped);
            this._startNodeWrapped = null;
        }
        if(startNodeWrapped){
            this._startNodeWrapped = startNodeWrapped;
            this.listenTo(this._startNodeWrapped, "change:replacementNode", (function(node, replacementNode){
                this._startNodeWrapped = replacementNode? replacementNode: node;
            }).bind(this))
        }
    },

    setEndWrappedNode : function(endNodeWrapped){
        if(this._endNodeWrapped){
            this.stopListening(this._endNodeWrapped);
            this._endNodeWrapped = null;
        }
        if(endNodeWrapped){
            this._endNodeWrapped = endNodeWrapped;
            this.listenTo(this._endNodeWrapped, "change:replacementNode", (function(node, replacementNode){
                this._endNodeWrapped = replacementNode? replacementNode: node;
            }).bind(this))
        }
    },*/

    canWrapTo : function(startNodeWrapped, endNodeWrapped){
        startNodeWrapped = this.getSelectableStartNodeWrappedIfApplicable(startNodeWrapped);
        endNodeWrapped = this.getSelectableEndNodeWrappedIfApplicable(endNodeWrapped);
        if(this.getStartWrapSequencerNode() === startNodeWrapped){
            return false;
        }
        if(this.getEndWrapSequencerNode() === endNodeWrapped){
            return false;
        }
        let nodeToWrap = startNodeWrapped;
        while(nodeToWrap){
            if(nodeToWrap == endNodeWrapped){
                return true;
            }else{
                nodeToWrap = nodeToWrap.getNext();
            }
        }
        return false;
    },

    setAllNodesHoverHighlighted : function(hoverHighlighted){
        let nodeToAddAsWrapNode = this.getStartWrappedNode();
        while(nodeToAddAsWrapNode){
            nodeToAddAsWrapNode.setHoverHighlighted(hoverHighlighted);
            if(nodeToAddAsWrapNode == this.getEndWrappedNode()){
                nodeToAddAsWrapNode = null;
            }else{
                nodeToAddAsWrapNode = nodeToAddAsWrapNode.getNext();
            }
        }
        this.getStartWrapSequencerNode().setHoverHighlighted(hoverHighlighted);
        this.getEndWrapSequencerNode().setHoverHighlighted(hoverHighlighted);
    },
    
    getSelectableStartNodeWrappedIfApplicable : function(startNodeWrapped){
        if(startNodeWrapped.getSelectable()){
            return startNodeWrapped;
        }else{
            const nextSelectable = startNodeWrapped.getNextSelectable();
            return nextSelectable? nextSelectable: startNodeWrapped;
        }
    },

    getSelectableEndNodeWrappedIfApplicable : function(endNodeWrapped){
        if(endNodeWrapped.getSelectable()){
            return endNodeWrapped;
        }else{
            const previousSelectable = endNodeWrapped.getPreviousSelectable();
            return previousSelectable? previousSelectable: endNodeWrapped;
        }
    },





});

WrapSequencerNode.CLASSNAME = CLASSNAME;

export default WrapSequencerNode; 