import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import AudioFilter from "models/audioEditor/filters/AudioFilter";
import RSVP from "rsvp";

const PRESETS ={};

const PRESET_IDS = {
    "LIGHT_VOLUME_BOOST":"lightVolumeBost",
    "HEAVY_VOLUME_BOOST":"heavyVolumeBost",
    "CLASSIC_VOICE_OVER":"classicVoiceOver",
    "GENTLE_MUSIC":"gentleMusic",
    "LOW_END_FOCUS":"lowEndFocus",
    "TRANSIENT_PRESS":"transientPress",
    "MAIN_BUSS_COMPRESSION":"mainBussCompression",
    "EASY_BASS":"easyBass",
    "NEED_SUNGLASSES":"needSunglasses",
    "SOUND_FX": "soundFx",
    "TREBBLE_MASTER_COMPRESSION": "trebbleMasterCompression"
}

const createCompressorConfigPreset = function(threshold, ratio, attack, release, knee){
    return {"threshold": threshold, "ratio": ratio,  "attack": attack, "release": release, "knee": knee};
}

PRESETS[PRESET_IDS.LIGHT_VOLUME_BOOST] = createCompressorConfigPreset(-20, 5, 0.05, 0.1, 0);
PRESETS[PRESET_IDS.HEAVY_VOLUME_BOOST] = createCompressorConfigPreset(-30, 18, 0.1, 0.4, 0);
PRESETS[PRESET_IDS.CLASSIC_VOICE_OVER] = createCompressorConfigPreset(-24, 1.5, 0.15, 0.4, 10);
PRESETS[PRESET_IDS.GENTLE_MUSIC] = createCompressorConfigPreset(-20, 3, 0.1, 0.25, 10);
PRESETS[PRESET_IDS.LOW_END_FOCUS] = createCompressorConfigPreset(-19.1, 4, 0.009, 0.2, 0);
PRESETS[PRESET_IDS.TRANSIENT_PRESS] = createCompressorConfigPreset(-18, 2, 0, 0.2, 0.3);
PRESETS[PRESET_IDS.MAIN_BUSS_COMPRESSION] = createCompressorConfigPreset(-15, 2, 0.003, 0.05, 0.2);
PRESETS[PRESET_IDS.EASY_BASS] = createCompressorConfigPreset(-25, 2.5, 0.006, 0.1, 0.5);
PRESETS[PRESET_IDS.NEED_SUNGLASSES] = createCompressorConfigPreset(-6, 2, 0.003, 0.3, 1);
PRESETS[PRESET_IDS.SOUND_FX] = createCompressorConfigPreset(-18, 4, 0.03, 0.15, 5);
PRESETS[PRESET_IDS.TREBBLE_MASTER_COMPRESSION] = createCompressorConfigPreset(-15, 1.5, 0.2, 0.35, 0.2);

const CLASSNAME = "CompressorFilter";
const CompressorFilter =  AudioFilter.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        AudioFilter.apply(this, [attributes, options]);
        this.setPresetIdToConfigMap(PRESETS);
        this.set("AudioFilter",AudioFilter.CLASSNAME);
        
    },

    
    setAttack : function(attack){
        this.set("attack",attack);
    },


    getAttack : function(){
        return this.get("attack");
    },

    setThreshold : function(threshold){
        this.set("threshold",threshold);
    },


    getThreshold : function(){
        return this.get("threshold");
    },


    setRelease : function(release){
        this.set("release",release);
    },


    getRelease : function(){
        return this.get("release");
    },

    setKnee : function(knee){
        this.set("knee",knee);
    },


    getKnee : function(){
        return this.get("knee");
    },

    setRatio : function(ratio){
        this.set("ratio",ratio);
    },


    getRatio : function(){
        return this.get("ratio");
    },

    isEqual : function(filter){
        if(filter instanceof CompressorFilter){
            return (this.isDisabled() == filter.isDisabled() && this.getAttack() === filter.getAttack() && this.getThreshold() === filter.getThreshold() && this.getRelease() === filter.getRelease() && this.getKnee() === this.getKnee() && this.getRatio() === filter.getRatio()) 
        }
        return false;
    },

    clone : function(){
        const cloneFilter = new CompressorFilter();
        cloneFilter.setDisabled(this.isDisabled());
        cloneFilter.setAttack(this.getAttack());
        cloneFilter.setThreshold(this.getThreshold());
        cloneFilter.setKnee(this.getKnee());
        cloneFilter.setRatio(this.getRatio());
        return cloneFilter;
    },

    needToPrepareAudioContext: function(){
        return false;
    },

    prepareAudioContext: function(audioContext){
        return RSVP.Promise.resolve(audioContext);
    },

    applyFilterToNode : function(audioNode, audioContext,arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime, sequencerRendererTracksInfo){
        if(!this.isDisabled()){
            const attack = this.getAttack();
            const threshold = this.getThreshold();
            const release = this.getRelease();
            const knee = this.getKnee();
            const ratio = this.getRatio();

            

            // Create a compressor node
            const compressorNode = audioContext.createDynamicsCompressor();

            if(threshold >= compressorNode.threshold.minValue
                && threshold <= compressorNode.threshold.maxValue
                && attack >= compressorNode.attack.minValue
                && attack <= compressorNode.attack.maxValue
                && release >= compressorNode.threshold.minValue
                && release <= compressorNode.release.maxValue
                && knee >= compressorNode.knee.minValue
                && knee <= compressorNode.knee.maxValue
                && ratio >= compressorNode.ratio.minValue
                && ratio <= compressorNode.ratio.maxValue){

                compressorNode.threshold.setValueAtTime(threshold, audioBufferStartTime? audioBufferStartTime: audioContext.currentTime);
            compressorNode.knee.setValueAtTime(knee, audioBufferStartTime? audioBufferStartTime: audioContext.currentTime);
            compressorNode.ratio.setValueAtTime(ratio, audioBufferStartTime? audioBufferStartTime: audioContext.currentTime);
            compressorNode.attack.setValueAtTime(attack, audioBufferStartTime? audioBufferStartTime: audioContext.currentTime);
            compressorNode.release.setValueAtTime(release, audioBufferStartTime? audioBufferStartTime: audioContext.currentTime);


            audioNode.connect(compressorNode);
            if(arrayOfAudioNodesUsed){
                arrayOfAudioNodesUsed.push(compressorNode);
            }
            return compressorNode;
        }else{
            return audioNode;
        }
    }else{
        return audioNode;
    }
},

applyFilter : function(previousOutputNode, audioContext, arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime, sequencerRendererTracksInfo, audioBuffer){
    if(!this.isDisabled()){
        if(!previousOutputNode){
            previousOutputNode = audioContext.createBufferSource();
            previousOutputNode.buffer = audioBuffer;
            if(arrayOfAudioNodesUsed){
                arrayOfAudioNodesUsed.push(previousOutputNode);
            }
        }
        return RSVP.Promise.resolve(this.applyFilterToNode(previousOutputNode, audioContext,arrayOfAudioNodesUsed, audioBufferStartTime ));
            
    }else{
        return RSVP.Promise.resolve(previousOutputNode);
    }
},


});

CompressorFilter.PRESET_IDS = PRESET_IDS;
CompressorFilter.PRESETS = PRESETS;
CompressorFilter.CLASSNAME =  CLASSNAME;

export default CompressorFilter; 