import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import AudioSegment from "models/audioEditor/segments/AudioSegment";
import RSVP from "rsvp";

const CLASSNAME = "WordAudioSegment";
const WordAudioSegment =  AudioSegment.extend({
    idAttribute: "id",

    constructor: function(attributes, options) {
        AudioSegment.apply(this, [attributes, options]);
        this.set("type", WordAudioSegment.CLASSNAME);
    },

    canBeAssociactedWithAnySpeaker: function(){
        return false;
    },

    needToBeAssociactedWithASpeaker : function(){
        return true;
    },

    clone: function(){
        return new WordAudioSegment(this.toJSON());
    },


});

WordAudioSegment.CLASSNAME = CLASSNAME;
export default WordAudioSegment; 