import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";

const SongOptionMenuView = Backbone.View.extend({

    model : null,
    
    /*initialize : function(songOptionMenuElementId, songOptionMenuModel) {
        this.setElement(document.getElementById(songOptionMenuElementId));
        this.$el = $("#"+songOptionMenuElementId);
        this.model = songOptionMenuModel;
    },*/

    constructor: function (attributes, options){
     Backbone.View.prototype.constructor.call(this,options);
    if(attributes && attributes.songOptionMenuElementId)
    {
        this.songOptionMenuElementId = attributes.songOptionMenuElementId;
        this.setElement(document.getElementById(attributes.songOptionMenuElementId));
        //this.delegateEvents(this.events);
        //this.$el = $("#"+attributes.songOptionMenuElementId);		
    }

    if(attributes && attributes.songOptionMenuModel)
    {
        this.model = attributes.songOptionMenuModel;		
    }
},
    
    events : {


        "click #addToQueueBtn" : "addToQueue",
        "click #playSimilarBtn" : "playSimilar",
        "click #playSimilarFromFollowersBtn" : "playSimilarFromFollowers",
        "click #addToPlaylistBtn" : "addToPlaylist",
        "click #shareBtn" : "share",
        "click #goToAlbumBtn" : "goToAlbum",
        "click #goToArtistBtn" : "goToArtist",
        "click #DeleteBtn" : "delete",
        
    },

    show :  function(){
        this.$el.popup( "open" );
    },

    close : function(){
        this.undelegateEvents();
        this.$el.popup( "close" );
    //	this.remove();
    },

    addToQueue : function(){
        const self = this;
        this.model.addToQueue().then(function(){
            self.close();
        }).catch(function(error){
            window.alertErrorMessage(error);
            self.close();
        });
    },

    playSimilar : function(){
        const self = this;
        this.model.playSimilar().then(function(){
            self.close();
        }).catch(function(error){
            window.alertErrorMessage(error);
            self.close();
        });
    },

    playSimilarFromFollowers : function(){
        const self = this;
        this.model.playSimilarFromFollowers().then(function(){
            self.close();
        }).catch(function(error){
            window.alertErrorMessage(error);
            self.close();
        });
    },

    addToPlaylist : function(){
        const self = this;
        this.model.addToPlaylist().then(function(){
            self.close();
        }).catch(function(error){
            window.alertErrorMessage(error);
            self.close();
        });
    },

    share : function(){
        const self = this;
        this.model.share().then(function(){
            self.close();
        }).catch(function(error){
            window.alertErrorMessage(error);
            self.close();
        });
    },


    goToAlbum : function(){
        const self = this;
        this.model.goToAlbum().then(function(){
            self.close();
        }).catch(function(error){
            window.alertErrorMessage(error);
            self.close();
        });
    },

    goToArtist : function(){
        const self = this;
        this.model.goToArtist().then(function(){
            self.close();
        }).catch(function(error){
            window.alertErrorMessage(error);
            self.close();
        });
    },


    delete : function(){
        const self = this;
        this.model.delete().then(function(){
            self.close();
        }).catch(function(error){
            window.alertErrorMessage(error);
            self.close();
        });
    },



    
    
});
export default SongOptionMenuView;