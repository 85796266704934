import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import RSVP from "rsvp";
import TagsCollectionView from "views/common/TagsCollectionView";
import Utils from "models/helper/Utils";
import ti18n from "i18n!nls/QueueTuningi18n";
import QueueTuningTemplate from 'text!../../../templates/queue/queueTuningTemplate.html';

const QueueTuningView = Backbone.View.extend({



    initialize : function(options) {
        this.compiledTemlate =  _.template(QueueTuningTemplate);
        this.queue = options? options.queue :null;
        this._selectionChanged =  false;
        this._tagGroupViews = [];
         this._tagGroupsArray =  null;
    },
    
    
    loadAndRenderTuneOptions : function(){
        $.mobile.loading( 'show');
        return new RSVP.Promise((function(resolve, reject){
            try{
                const allAvailableTagGroupsArray = this.queue.getAvailableTagGroupFilters(); 
                this._tagGroupsArray = allAvailableTagGroupsArray;
                for(let index in allAvailableTagGroupsArray){
                    const tagGroup = allAvailableTagGroupsArray[index];
                    const tagGroupView = new TagsCollectionView({
                        "tagGroup" : tagGroup,
                        "allOptionTagAvalailable": true
                        });
                    tagGroupView.render();
                    this.listenTo(tagGroupView, "selectedTagIdsChange", this._onSelectionChange);
                    this._tagGroupViews.push(tagGroupView);
                    this._addTagGroupViewToPage(tagGroupView);
                    
                }
                resolve(this);
                $.mobile.loading( 'hide');
            }catch(error){
                $.mobile.loading( 'hide');
                reject(error);	
                window.alertErrorMessage(error);
            }
        }).bind(this));
    },
    
    getTagGroupsArray : function(){
        return this._tagGroupsArray;
    },
    
    _onSelectionChange : function(){
        this._selectionChanged = true;
    },
    
    isSelectionChanged : function(){
        return this._selectionChanged;
    },
    
    _addTagGroupViewToPage : function(tagGroupView){
        if(tagGroupView ){
            const tagGroupId = tagGroupView.getTagGroupId();
            if(tagGroupId == Utils.getInstance().getGenreTagGroupId()){
                this._addGenreTagGroupViewToPage(tagGroupView);
                return;
            }
            if(tagGroupId == Utils.getInstance().getEraTagGroupId()){
                this._addEraTagGroupViewToPage(tagGroupView);
                return;
            }
        }
        
    },
    
    _addGenreTagGroupViewToPage : function(tagGroupView){
        this.$genreTagWrapper.append(tagGroupView.$el);
    },
    
    _addEraTagGroupViewToPage : function(tagGroupView){
        this.$eraTagWrapper.append(tagGroupView.$el);
    },
    
    
    render : function() {
        const templateParams = {"ti18n": ti18n};
        this.$el.html(this.compiledTemlate(templateParams));
        //this.setElement(this.$el.find("div").first());
        this.$genreTagWrapper = this.$el.find("#genresTagWrapper");
        this.$eraTagWrapper = this.$el.find("#eraTagWrapper");
        this.loadAndRenderTuneOptions();
        return this;
    }

});
export default QueueTuningView;