// AudioEncoderWorker.js

let encoder;

self.onmessage = function(event) {
  const { type, data } = event.data;

  switch (type) {
    case 'encodeAudio':
      encodeAudio(data);
      break;
  }
};

function encodeAudio({ audioBuffers, sampleRate, codec }) {
  const numberOfChannels = audioBuffers.length;
  
  const config = {
    codec: codec,
    sampleRate: sampleRate,
    numberOfChannels: numberOfChannels,
  };

  encoder = new window.AudioEncoder({
    output: handleEncodedChunk,
    error: error => { 
      self.postMessage({ type: 'error', data: error.message });
    }
  });

  encoder.configure(config);

  const frameSize = 1024;
  const totalFrames = audioBuffers[0].length / frameSize;
  
  for (let i = 0, frameNumber = 1; i < audioBuffers[0].length; i += frameSize, frameNumber++) {
    let pcmData = [];
    for (let channel = 0; channel < numberOfChannels; channel++) {
      pcmData.push(...audioBuffers[channel].slice(i, i + frameSize));
    }

    if (numberOfChannels === 2) {
      pcmData = interleaveChannels(pcmData, frameSize);
    }

    encoder.encode(pcmData);

    // Report progress
    const progress = (frameNumber / totalFrames) * 100;
    self.postMessage({ type: 'progress', data: progress });
  }

  encoder.flush();
  self.postMessage({ type: 'finished' });
}

function handleEncodedChunk(chunk) {
  self.postMessage({ type: 'encodedChunk', data: chunk });
}

function interleaveChannels(data, frameSize) {
  let interleaved = [];
  for (let i = 0; i < frameSize; i++) {
    interleaved.push(data[i]);
    interleaved.push(data[i + frameSize]);
  }
  return interleaved;
}
