import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import Utils from "models/helper/Utils";
import CapsuleLifeSpanListSelectionController from "controllers/capsule/CapsuleLifeSpanListSelectionController";
import AddCapsuleToTrebblePopupView from "views/capsule/AddCapsuleToTrebblePopup";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";

const AddCapsuleToTrebblePopupController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.model = options? options.capsuleModel : null;
        this.radioIdsToAddCapsuleTo = options? options.radioIdsToAddCapsuleTo : null;
        this.context = options? options.context : null;
        this._onCapsuleAddedHandler = options? options.onCapsuleAddedHandler: null;
        this._onCapsuleAddedErrorHandler = options? options.onCapsuleAddedErrorHandler : null;
        this._onPopupCloseHanlder = options? options.onPopupClose : null;
        this._newSelectedTagIds = null;
        this._doNotCloseOnTagSelected = false;
        this._capsuleWasAdded = false;
        this._errorAddingCapsule = null;
        _.extend(this, Backbone.Events);
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    onCapsuleLifeSpanChanged : function(selectedCapsuleLifespanValue){
        this._selectedCapsuleLifespanValue = selectedCapsuleLifespanValue;
        this.addCapsuleToTrebble();

    },

    addCapsuleToTrebble : function(){
        this.view.setAddingCapsuleToTrebble(true);
        PersistentModels.getInstance().addCapsuleToRadio(this.model, this.radioIdsToAddCapsuleTo, this._selectedCapsuleLifespanValue,null, null,null, this.context).then((function(){
            this._capsuleWasAdded = true;
            this.view.setAddingCapsuleToTrebble(false);
            this.view._close();	
        }).bind(this)).catch((function(error){
            this._errorAddingCapsule = error;
            this.view.setAddingCapsuleToTrebble(false);
            if(!this._errorAddingCapsule){
                window.alertErrorMessage(error);
            }
            this.view._close();
        }).bind(this));

    },

    onPopupClosed : function(){
        if(this._onPopupCloseHanlder){
            this._onPopupCloseHanlder();
        }
        if(this._onCapsuleAddedHandler && !this._errorAddingCapsule){
            this._onCapsuleAddedHandler(this._capsuleWasAdded, this.model);
        }
        if(this._onCapsuleAddedErrorHandler && this._errorAddingCapsule){
            this._onCapsuleAddedHandler(this._errorAddingCapsule);
        }
    },


    onViewRemoved:function(){
        this.stopListening();
    },


    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                const capsuleLifeSpanListSelectionControllerParams =  {};
                capsuleLifeSpanListSelectionControllerParams.model = this.model;
                capsuleLifeSpanListSelectionControllerParams.radioIdsToAddCapsuleTo = this.radioIdsToAddCapsuleTo;
                const capsuleLifeSpanListSelectionController  = new CapsuleLifeSpanListSelectionController(capsuleLifeSpanListSelectionControllerParams);
                this.listenTo(capsuleLifeSpanListSelectionController, "capsuleLifeSpanChanged", this.onCapsuleLifeSpanChanged.bind(this))
                this._capsuleLifeSpanListSelectionController = capsuleLifeSpanListSelectionController;
                const promise =  capsuleLifeSpanListSelectionController.buildModelAndRenderView().then((function(){
                    const capsuleLifeSpanListSelectionView = capsuleLifeSpanListSelectionController.getView();
                    const params= {};
                    params.contentView = capsuleLifeSpanListSelectionView;
                    params.capsuleModel = this.model;
                    this.view = new AddCapsuleToTrebblePopupView(params);
                    this.listenTo(this.view , "closed",this.onPopupClosed.bind(this));
                    this.listenTo(this.view, "remove", this.onViewRemoved);
                    this.view.render();
                    return null;
                }).bind(this))
                promise.then(resolve).catch(reject);
            } catch (error) {
                reject(error);
            }

        }).bind(this));

}

});

export default AddCapsuleToTrebblePopupController;