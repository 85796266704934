import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import CordovaHelper from "models/helper/CordovaHelper";
import InfoBoxController from "controllers/common/InfoBoxController";
import PlaylistListController from "controllers/common/PlaylistListController";
import Utils from "models/helper/Utils";
import PlaylistHelper from "models/helper/PlaylistHelper";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import CollectionRepeatController from "controllers/common/CollectionRepeatController";
import JourneyCardController from "controllers/journey/JourneyCardController";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import ti18n from 'i18n!nls/MyJourneysPagei18n';
import PushNotificationHelper from "models/helper/PushNotificationHelper";
import FacebookEventTrackingHelper from "models/helper/FacebookEventTrackingHelper";
import SubscriptionManager from "models/helper/SubscriptionManager";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import MyJourneysPageView from "views/myJourneys/MyJourneysPageView";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";

const Rollout = RolloutHelper.getInstance();

const MyJourneysPageController = Chaplin.Controller.extend({


    initialize: function(options) {
        _.extend(this, Backbone.Events);
        if (options) {
            if (options.view) {
                this.view = options.view;
            }
            if (options.model) {
                this.model = options.model;
            }
        }

        this.context = Utils.getInstance().buildContextForMyJourneys();


        this._numberOfJourneys = 0;
        this._listOfSubscriptionWasAlreadyFetched = false;
        this._isFirstLoad = false;
        
    },

    getModel: function() {
        return this.model;
    },

    getView: function() {
        return this.view;
    },




    _buildMyJourneysListController: function() {



        const controllerParams = {};

        controllerParams.customImageUrlProperty = "data-original";
        controllerParams.notTransparent = true;
        controllerParams.smallcardsize = true;
        controllerParams.notPlayable = true;
        controllerParams.showEditablePageIfApplicable = true;
        controllerParams.doNotShowSubscribeButton = true;

        const collectionRepeatControllerParams = {};
        collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildInfoBoxParamsForMyJourneys();
        collectionRepeatControllerParams.showEmtptyInfoBox = true;
        collectionRepeatControllerParams.controllerOption = controllerParams;
        collectionRepeatControllerParams.displayCollectionInline = false;
        collectionRepeatControllerParams.itemControllerConstructor = JourneyCardController;
        collectionRepeatControllerParams.modelLoadHandler = this.getMyJourneysListLoadHandler().bind(this);
        collectionRepeatControllerParams.context = this.context;
        collectionRepeatControllerParams.resizePlaceHolderElem = true;
        collectionRepeatControllerParams.actionButtonHandlerFunction = this.showCreateJourneyPage.bind(this);
        collectionRepeatControllerParams.heightMargin = 52 + 39; //Page Header + Tab Header

        this.myJourneysListController = new CollectionRepeatController(collectionRepeatControllerParams);
        this.listenTo(this.myJourneysListController, "startLoading", this.onJourneyListStartLoading.bind(this));
        this.listenTo(this.myJourneysListController, "finishLoading", this.onJourneyListFinishLoading.bind(this));
        return this.myJourneysListController;
    },

    showCreateJourneyPage: function() {
        return PersistentModels.getInstance().getRouter().showJourneyCreationWizardPage(true);
    },


    getMyJourneysListLoadHandler: function() {
        return (function() {

            return TrebbleClientAPIHelper.getInstance().getMyJourneys().then((function(journeyJsonArray) {
                const journeySummaryModelArray = [];
                //PersistentModels.getInstance().setMyFollowedRadioInfoArray(trebbleJsonArray);
                if (journeyJsonArray) {
                    for (let i = 0; i < journeyJsonArray.length; i++) {
                        journeySummaryModelArray.push(Utils.getInstance().buildJourneySummaryFromJson(journeyJsonArray[i]));
                    }
                }
                if (!this._listOfSubscriptionWasAlreadyFetched) {

                    this._listOfSubscriptionWasAlreadyFetched = true;
                }
                this._numberOfJourneys = journeySummaryModelArray.length;
                return journeySummaryModelArray;
            }).bind(this));
        }).bind(this);
    },


    getNumbrOfJourneys: function() {
        return this._numberOfJourneys;
    },

    _buildInfoBoxParamsForMyJourneys: function() {
        const params = {};
        params.iconClass = "pe-7s-sun";
        params.message = window.getI18n(ti18n, "NO_JOURNEY_CREATED");
        //params.helpMessage = window.getI18n(ti18n, "SUBSCRIBE_TO_TREBBLE_YOU_LIKE");
        params.actionButtonLabel = window.getI18n(ti18n, "CREATE_JOURNEY");
        return params; 
    },

    showHomePopup: function() {
        PersistentModels.getInstance().getRouter().showHomePage();
    },


    onPageBeforeShow: function(pageRefreshWasTriggerBecauseOfSubscriptionChange) {
        if (this.myJourneysListController && this.myJourneysListController.getView() && !this.myJourneysListController.isLoading()) {
            if(this.view){
                this.view.saveScrollPosition();
            }
            this.myJourneysListController.reload().then((function() {
                if(this.view){
                    this.view.restoreScrollPosition();
                }
            }).bind(this));
        }

    },

    onJourneyListStartLoading: function() {
        if (this.view) {
            this.view.setLoadingJourneys(true);
        }
    },

    onJourneyListFinishLoading: function() {
        if (this.view) {
            this.view.setLoadingJourneys(false);
        }
        if (this.myJourneysListController) {
            this.view.setNoJourneys(!this.myJourneysListController.getNumberOfModels());
        }
        this.view.loadImages();
    },


    showHelpPage : function(){
        PersistentModels.getInstance().getRouter().showHelpAndFeedback(null, true);
    },


    buildModelAndRenderView: function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                this._buildMyJourneysListController();


                const promiseArray = [];
                promiseArray.push(this.myJourneysListController.buildModelAndRenderView());


                RSVP.Promise.all(promiseArray).then((function() {
                    this.myJourneysListView = this.myJourneysListController.getView();



                    const myJourneysPageParams = {};
                    myJourneysPageParams.myJourneysListView = this.myJourneysListView;

                    this.view = new MyJourneysPageView(myJourneysPageParams);

                    this.listenTo(this.view, "pageBeforeShow", this.onPageBeforeShow);

                    this.listenTo(this.view, "showHomePopup", this.showHomePopup);
                    this.listenTo(this.view, "showHelpPage", this.showHelpPage);
                    this.listenTo(this.view, "createJourney", this.showCreateJourneyPage);

                    this.view.render();
                    

                    resolve();
                }).bind(this)).catch(reject);
            } catch (error) {
                reject(error);
            }

        }).bind(this));


}

});

export default MyJourneysPageController;