
import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import PersistentModels from "services/PersistentModels";
import BasicSequencerNodeInspectorView from "views/audioEditor/sequencerNodeInspectorSection/BasicSequencerNodeInspectorView";
import TitleParamView from "views/audioEditor/sequencerNodeInspectorSection/paramView/TitleParamView";
import WordParamView from "views/audioEditor/sequencerNodeInspectorSection/paramView/WordParamView";
import DurationInSecondsParamView from "views/audioEditor/sequencerNodeInspectorSection/paramView/DurationInSecondsParamView";
import DurationInSecondsWithSliderParamView from "views/audioEditor/sequencerNodeInspectorSection/paramView/DurationInSecondsWithSliderParamView";
import SliderInputParamView from "views/audioEditor/sequencerNodeInspectorSection/paramView/SliderInputParamView";
import SwitchInputParamView from "views/audioEditor/sequencerNodeInspectorSection/paramView/SwitchInputParamView";
import GroupParamsView from "views/audioEditor/sequencerNodeInspectorSection/paramView/GroupParamsView";
import CustomObjectParamView from "views/audioEditor/sequencerNodeInspectorSection/paramView/CustomObjectParamView";
import VideoSourceParamView from "views/audioEditor/sequencerNodeInspectorSection/paramView/VideoSourceParamView";
import AudioBufferHelper from "models/audioEditor/AudioBufferHelper";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import ti18n from 'i18n!nls/Sequenceri18n';
import RSVP from 'rsvp';

const Rollout = RolloutHelper.getInstance();
const ENABLE_TRIMMING_ON_NODES = Rollout.getFeatureVariable(Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR, Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR.variables.enable_triming_on_nodes, true);
const USE_SLIDER_FOR_DURATION_INPUT =  Rollout.getFeatureVariable(Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR, Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR.variables.use_slider_input_for_duration, true);
const DO_NOT_SHOW_PADDING_CONFIGURATION = true;
const VideoSequencerNodeInspectorView = BasicSequencerNodeInspectorView.extend({


    constructor : function(attributes, options) {
        BasicSequencerNodeInspectorView.apply(this, [attributes, options]);

    },

    _listenToChangeEventFromView : function(viewToListenTo, eventNameToTrigger, convertValueFuction){
        this.listenTo(viewToListenTo, "valueChanged", (function(newValue){
            this.trigger(eventNameToTrigger, convertValueFuction ? convertValueFuction(newValue): newValue);
        }).bind(this));
    },

    listenToChangeEventsOnModel :function(){
        const sequencerNode =  this.getSequencerNode();

        this.listenTo(sequencerNode, "change:mainVolumePercentage", (function(model){
            const newValue = model.getMainVolumePercentage();
            if(newValue * 100 != this._mainVolumePercentageView.getValue()){
                this._mainVolumePercentageView.setValue(newValue * 100 , true);
            }
        }).bind(this));
        

        this.listenTo(sequencerNode, "change:introFadeInMilliseconds", (function(model){
            const introFadeInMilliseconds = model.getIntroFadeInMilliseconds();
            if(introFadeInMilliseconds/1000 != this._introFadeDurationView.getValue()){
                this._introFadeDurationView.setValue(introFadeInMilliseconds/1000, true);
            }
        }).bind(this));

        this.listenTo(sequencerNode, "change:outroFadeInMilliseconds", (function(model){
            const outroFadeInMilliseconds = model.getOutroFadeInMilliseconds();
            if(outroFadeInMilliseconds/1000 != this._outroFadeDurationView.getValue()){
                this._outroFadeDurationView.setValue(outroFadeInMilliseconds/1000, true);
            }
        }).bind(this));

        if(!DO_NOT_SHOW_PADDING_CONFIGURATION){
            this.listenTo(sequencerNode, "change:introPaddingInMilliseconds", (function(model){
                const introPaddingInMilliseconds = model.getIntroPaddingInMilliseconds();
                if(introPaddingInMilliseconds/1000 != this._introPaddingView.getValue()){
                    this._introPaddingView.setValue(introPaddingInMilliseconds/1000, true);
                }
            }).bind(this));

            this.listenTo(sequencerNode, "change:outroPaddingInMilliseconds", (function(model){
                const outroPaddingInMilliseconds = model.getOutroPaddingInMilliseconds();
                if(outroPaddingInMilliseconds/1000 != this._outroPaddingView.getValue()){
                    this._outroPaddingView.setValue(outroPaddingInMilliseconds/1000, true);
                }
            }).bind(this));
        }



        this.listenTo(sequencerNode, "change:video", (function(model){
            const trebbleVideo = model.getVideo();


            if((!trebbleVideo && this._videoSourceParamView  && this._videoSourceParamView.getValue() && this._videoSourceParamView.getValue().trebbleVideo)||
                (trebbleVideo && this._videoSourceParamView  && !this._videoSourceParamView.getValue()) ||
                (trebbleVideo && this._videoSourceParamView  && this._videoSourceParamView.getValue() && this._videoSourceParamView.getValue().trebbleVideo && trebbleVideo.getAudioUrl() != this._videoSourceParamView.getValue().trebbleVideo.getAudioUrl())){
                this._videoSourceParamView.setValue(model.getVideoAndVideoSegmentStartTimeAndEndTime(), true);
        }
    }).bind(this));


        this.listenTo(sequencerNode, "change:audioSegment", (function(model){
            const audioSegmentJson = model.getAudioSegmentJson();

            if((!audioSegmentJson && this._videoSourceParamView  && this._videoSourceParamView.getValue())||
                (audioSegmentJson && this._videoSourceParamView  && !this._videoSourceParamView.getValue()) ||
                (audioSegmentJson && this._videoSourceParamView  && this._videoSourceParamView.getValue() && (audioSegmentJson.startTime != this._videoSourceParamView.getValue().startTime
                    || audioSegmentJson.endTime != this._videoSourceParamView.getValue().endTime))){
                this._videoSourceParamView.setValue(model.getVideoAndVideoSegmentStartTimeAndEndTime(), true);
        }
    }).bind(this));
    },

    renderAllParamViews : function(){
        const paramViewArray  = [];
        const titleParamView =  new TitleParamView();
        titleParamView.setValue(window.getI18n(ti18n, "VIDEO"));
        titleParamView.render();
        paramViewArray.push(titleParamView);

        const getDisplayedVolumePercentageLabelFromValueFunction = function(value){
            return value? Utils.getInstance().roundNumberTo(value, 2) +"%": "0%";
        }

        const getValueFromDisplayedVolumePercentageFunction = function(displayedValue){
            return Number(displayedValue.replace('%', ''));
        }
        const mainVolumePercentageView =  new SliderInputParamView({"getDisplayedLabelFromValueFunction": getDisplayedVolumePercentageLabelFromValueFunction, "getValueFromDisplayedLabelFunction": getValueFromDisplayedVolumePercentageFunction});
        this._mainVolumePercentageView = mainVolumePercentageView;
        mainVolumePercentageView.setLabel(window.getI18n(ti18n, "VOLUME") +": ");
        mainVolumePercentageView.setMinValue(0);
        mainVolumePercentageView.setMaxValue(100);
        mainVolumePercentageView.setStep(1);
        mainVolumePercentageView.setValue(this.getSequencerNode().getMainVolumePercentage()* 100, true);
        this._listenToChangeEventFromView(mainVolumePercentageView, "volumeChanged", function(newValue){ return newValue/100; });
        mainVolumePercentageView.render();
        paramViewArray.push(mainVolumePercentageView);

        const onChangeVideoFileOperationRequested = function(currentValue, onVideoFileUpdateSuccessfullyChanged, onVideoFileUpdateCancelled){
            const onTrebbleVideoSelected = (function(selectedTrebbleVideo){
                if(selectedTrebbleVideo){
                    onVideoFileUpdateSuccessfullyChanged(selectedTrebbleVideo)
                }else{
                    onVideoFileUpdateCancelled();
                }
            }).bind(this);
            return PersistentModels.getInstance().getRouter().showChooseUploadedVideosPopup(onTrebbleVideoSelected);
        }
        const videoSourceParamView =  new VideoSourceParamView({
            "onChangeVideoFileOperationRequested": onChangeVideoFileOperationRequested,
            "sequencerSettings": this.getSequencerNode().getSequencer().getSequencerSettings(),
            "audioType": AudioBufferHelper.getInstance().getAudioTypes().INSERT
        });
        this._videoSourceParamView = videoSourceParamView;
        videoSourceParamView.setLabel(window.getI18n(ti18n, "TRIMMED_FROM") +": ");
        videoSourceParamView.setValue(this.getSequencerNode().getVideoAndVideoSegmentStartTimeAndEndTime());
        this._listenToChangeEventFromView(videoSourceParamView, "videoSourceChanged");
        videoSourceParamView.render();
        paramViewArray.push(videoSourceParamView);

        
        const introParamsGroup = new GroupParamsView();
        introParamsGroup.setTitle(window.getI18n(ti18n, "INTRO"));
        paramViewArray.push(introParamsGroup);

        const introFadeDurationView = USE_SLIDER_FOR_DURATION_INPUT?  new DurationInSecondsWithSliderParamView() : new DurationInSecondsParamView();
        this._introFadeDurationView = introFadeDurationView;
        introFadeDurationView.setLabel(window.getI18n(ti18n, "FADE") +": ");
        introFadeDurationView.setMinValueInSeconds(0);
        introFadeDurationView.setMaxValueInSeconds(10);

        if(!USE_SLIDER_FOR_DURATION_INPUT){
            introFadeDurationView.setEditable(true);
        }else{
            introFadeDurationView.setStep(1);
        }
        introFadeDurationView.setValue(this.getSequencerNode().getIntroFadeInMilliseconds()/1000, true);
        this._listenToChangeEventFromView(introFadeDurationView, "introFadeChanged");
        introFadeDurationView.render();
        introParamsGroup.addRenderedParamView(introFadeDurationView);
        //paramViewArray.push(introFadeDurationView);
        


        if(!DO_NOT_SHOW_PADDING_CONFIGURATION){
            const introPaddingView = USE_SLIDER_FOR_DURATION_INPUT?  new DurationInSecondsWithSliderParamView() : new DurationInSecondsParamView();
            this._introPaddingView = introPaddingView;
            introPaddingView.setLabel(window.getI18n(ti18n, "PADDING") +": ");
            introPaddingView.setMinValueInSeconds(0);
            introPaddingView.setMaxValueInSeconds(10);
            if(!USE_SLIDER_FOR_DURATION_INPUT){
                introPaddingView.setEditable(true);
            }else{
                introPaddingView.setStep(1);
            }
            introPaddingView.setValue(this.getSequencerNode().getIntroPaddingInMilliseconds()/1000, true);
            this._listenToChangeEventFromView(introPaddingView, "introPaddingChanged");
            introPaddingView.render();
            introParamsGroup.addRenderedParamView(introPaddingView);
        }

        introParamsGroup.render();

        const outroParamsGroup = new GroupParamsView();
        outroParamsGroup.setTitle(window.getI18n(ti18n, "OUTRO"));
        paramViewArray.push(outroParamsGroup);

        const outroFadeDurationView = USE_SLIDER_FOR_DURATION_INPUT?  new DurationInSecondsWithSliderParamView() : new DurationInSecondsParamView();
        this._outroFadeDurationView = outroFadeDurationView;
        outroFadeDurationView.setLabel(window.getI18n(ti18n, "FADE") +": ");
        outroFadeDurationView.setMinValueInSeconds(0);
        outroFadeDurationView.setMaxValueInSeconds(10);
        if(!USE_SLIDER_FOR_DURATION_INPUT){
            outroFadeDurationView.setEditable(true);
        }else{
            outroFadeDurationView.setStep(1);
        }
        outroFadeDurationView.setValue(this.getSequencerNode().getOutroFadeInMilliseconds()/1000, true);
        this._listenToChangeEventFromView(outroFadeDurationView, "outroFadeChanged");
        outroFadeDurationView.render();
        outroParamsGroup.addRenderedParamView(outroFadeDurationView);
        //paramViewArray.push(outroFadeDurationView);
        

        if(!DO_NOT_SHOW_PADDING_CONFIGURATION){
            const outroPaddingView = USE_SLIDER_FOR_DURATION_INPUT?  new DurationInSecondsWithSliderParamView() : new DurationInSecondsParamView();
            this._outroPaddingView = outroPaddingView;
            outroPaddingView.setLabel(window.getI18n(ti18n, "PADDING") +": ");
            outroPaddingView.setMinValueInSeconds(0);
            outroPaddingView.setMaxValueInSeconds(10);
            if(!USE_SLIDER_FOR_DURATION_INPUT){
                outroPaddingView.setEditable(true);
            }else{
                outroPaddingView.setStep(1);
            }
            outroPaddingView.setValue(this.getSequencerNode().getOutroPaddingInMilliseconds()/1000, true);
            this._listenToChangeEventFromView(outroPaddingView, "outroPaddingChanged");
            outroPaddingView.render();
            outroParamsGroup.addRenderedParamView(outroPaddingView);
        }

        outroParamsGroup.render();


        return paramViewArray;
    }


});
export default VideoSequencerNodeInspectorView;