import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import WelcomeNewUserPopupView from "views/welcomeNewUser/WelcomeNewUserPopupView";
import PersistentModels from "services/PersistentModels";
import WelcomeNewUserTemplate from 'text!../../../templates/welcomeNewUser/WelcomeNewUserTemplate.html';
import ti18n from "i18n!nls/WelcomeNewUseri18n";
import RSVP from "rsvp";

const WelcomeNewUserPopupController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.template = WelcomeNewUserTemplate;
        this.context = options? options.context: null;
        const myPersonalRadioIdToRadioInfo = PersistentModels.getInstance().getAllMyPersonalRadioIdToRadioInfo();
        this._radioInfo  =  null;
        for(let radioId in myPersonalRadioIdToRadioInfo){
            this._radioInfo = myPersonalRadioIdToRadioInfo[radioId];
            break;
        }
        _.extend(this, Backbone.Events);
    },



    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    _onPopupClosed : function(){

    },

    createMyFirstCapsule : function(){
        PersistentModels.getInstance().getRouter().showCreateACapsulePageWithoutContext();
    },

    whatsACapsule : function(){
        return this.view.close().then((function(){
            return PersistentModels.getInstance().getRouter().showAndPlayWhatACapusleCapsule();
        }).bind(this))
        
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                if(this.template){
                    const params = {};
                    params.radioInfo = this._radioInfo;
                    params.template = this.template;
                    params.ti18n = ti18n;
                    const welcomeNewUserPopupView = new WelcomeNewUserPopupView(params);
                    welcomeNewUserPopupView.render();
                    this.view = welcomeNewUserPopupView;
                    this.listenTo(this.view, "onPopupClosed", this._onPopupClosed.bind(this))
                    this.listenTo(this.view, "createMyFirstCapsule", this.createMyFirstCapsule.bind(this))
                    this.listenTo(this.view, "whatsacapsule", this.whatsACapsule.bind(this))
                    
                }
                resolve();


            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default WelcomeNewUserPopupController;