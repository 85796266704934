import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Song from "models/Song";
import TrebbleSongListView from "views/common/TrebbleSongListView";
import SongOptionMenuModel from "models/SongOptionMenuModel";
import SongsTabContentCollection from "collections/myLibrary/SongsTabContentCollection";
import SongOptionMenuView from "views/SongOptionMenuView";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import ti18n from "i18n!nls/MyLibraryi18n";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";

const DISPLAY_ALBUM_NAME = false;
const SongTabContentView = Backbone.View.extend({
    
    
    
    
    
    initialize: function() {
        this.displayedCollection = this.collection;
        this.collection.bind("add", this._onSongAddedOrRemovedInLibrary, this);
        this.collection.bind("change", this._onSongUpdatedInLibrary, this);
        this.collection.bind("remove", this._onSongAddedOrRemovedInLibrary, this);
        this.collection.bind("reset", this._onSongAddedOrRemovedInLibrary, this);

      
        PersistentModels.getInstance().getRouter().registerForViewportHeightChange("songTabListView",this._onOrientationChangeFunction.bind(this));
    },
    _onOrientationChangeFunction : function(newViewPortHeigh,forceResizing) {
        if(PersistentModels.getInstance().getRouter().isEqualToCurrentPageUrl("#mylibrary") || forceResizing){
            if (this.el) {
                const tabHeight = 46;
                  this.el.style.height = (newViewPortHeigh - tabHeight) + "px"; 
              }
            this.infiniteListView._onRootElementResize();
        }
      },
    
    
    filterList: function(searchCharaterers,  resultSize, customFilter, sortOptions) {
        
        this.appliedSearchCharacters = searchCharaterers;
        const searchFunction = (function() {
            $.mobile.loading("show");
            this.infiniteListView.setUpdating(true);
            const filteredCollection  = new SongsTabContentCollection(this.collection.searchOnTitleAndArtist(this.appliedSearchCharacters,resultSize, customFilter));
            filteredCollection.sortByField(sortOptions);
            filteredCollection.updateContext(Utils.getInstance().buildContextForSongCollectionMyLibrary(this.appliedSearchCharacters));
            this._changeSongList(filteredCollection);
            this.pendingSearchFunctionTimerId = null;
            $.mobile.loading("hide");
        }).bind(this);
        if (this.pendingSearchFunctionTimerId) 
        {
            window.clearTimeout(this.pendingSearchFunctionTimerId);
        }
        this.pendingSearchFunctionTimerId = window.setTimeout(searchFunction, 1000);
    
    },
    
    clearSearchfilter: function(resultSize, customFilter, sortOptions) {
        
        this.appliedSearchCharacters = null;
        const searchFunction = (function() {
            this.infiniteListView.setUpdating(true);
            const filteredCollection  = new SongsTabContentCollection(this.collection.searchOnTitleAndArtist("",resultSize, customFilter));
            filteredCollection.updateContext(Utils.getInstance().buildContextForSongCollectionMyLibrary());
            this._changeSongList(filteredCollection);
            this.pendingSearchFunctionTimerId = null;
        }).bind(this);
        if (this.pendingSearchFunctionTimerId) 
        {
            window.clearTimeout(this.pendingSearchFunctionTimerId);
        }
        this.pendingSearchFunctionTimerId = window.setTimeout(searchFunction, 0);
    
    },
  
    
    _changeSongList: function(newSongModels) {
        this.displayedCollection = newSongModels;
        this.displayedCollection.sort();
        this.infiniteListView.updateModels(this.displayedCollection.toJSON());
        this.infiniteListView.setUpdating(false);
        this._onDisplayedCollectionChanged();
    },
    
    _updateSongInSongList: function(songUpdateModel) {
        this.infiniteListView.updateModel(songUpdateModel.toJSON());
        this.infiniteListView.setUpdating(false);
        this._onDisplayedCollectionChanged();
    },
    
    
    _getOnListItemTapHandler: function() {
        return (function(songJson, evnt, item) {
            this.trigger("itemTaped", this._getSongModelFromSongJson(songJson) ,this.displayedCollection );
        }).bind(this);
    },
    
    _onItemSwipeRight: function() {
        return (function(songJson, evnt, item) {
            this.trigger("itemSwipedRight", this._getSongModelFromSongJson(songJson));

        }).bind(this);
    },
    
    _getSongModelFromSongJson : function(songJson){
        return this.collection.get(songJson.uri );
    },
    
    
    
    
    
    
    _modelTransformHandlerBeforeTemplatePiping: function(songJson) {
        let titleToDisplay = "", artistToDisplay= "", albumNameToDisplay = "";
        if (songJson.idified && (songJson.idified === "true" || songJson.idified === true) && (songJson.idifiedSuccess === "true" || songJson.idifiedSuccess === true)) 
        {
            if (window.trebble.config.showIdifiedArtistName) 
            {
                artistToDisplay = songJson.idifiedArtist;
            }else{
                 artistToDisplay = songJson.artist;
            }
            if (window.trebble.config.showIdifiedSongTitle) 
            {
                titleToDisplay = songJson.idifiedTitle;
            }else{
                titleToDisplay = songJson.title;
            }
            if(window.trebble.config.showIdifiedAlbumTitle){
                albumNameToDisplay = songJson.idifiedAlbum;
            }else{
                 albumNameToDisplay = songJson.album;
            }
        } else {
            artistToDisplay = songJson.artist;
            titleToDisplay = songJson.title;
            albumNameToDisplay = songJson.album;
        }
        if (!artistToDisplay) 
        {
            artistToDisplay = "";
        }
        if (!titleToDisplay) 
        {
            titleToDisplay = "";
        }
        
        if (!albumNameToDisplay) 
        {
            albumNameToDisplay = "";
        }
        let templateData =  null;
        /*const explicit = songJson.idified? songJson.idifiedExplicit : songJson.explicit;
        if(explicit){
            titleToDisplay = titleToDisplay + "<e class='trebbleExplicitIcon'></e>";
        }*/
        if(DISPLAY_ALBUM_NAME){
             templateData = {firstLineText: titleToDisplay,secondLineText: artistToDisplay + " · "+ albumNameToDisplay ,model : songJson};
        }else{
             templateData = {firstLineText: titleToDisplay,secondLineText: artistToDisplay ,model : songJson};
        }
       
        return templateData;
    },
    
    _getOnListButtonTappedHandler: function() {
        return (function(songJson, evnt, item) {
            this.trigger("songOptionTapped", this._getSongModelFromSongJson(songJson));
        }).bind(this);
    },
    
    
    isSearchApplied : function(){
        return this.appliedSearchCharacters != null;
    },
    
    getAppliedSearchCharacters : function(){
        return this.appliedSearchCharacters;
    }, 
    
    _onDisplayedCollectionChanged :function(){
        this.trigger("modelChange", this.displayedCollection);
    },
    
    _onSongAddedOrRemovedInLibrary : function(){
        if(this.isSearchApplied())
        {
            this.filterList(this.getAppliedSearchCharacters(),  this._resultSize,this._customFilter,this._customSort);
        }else{
            this.infiniteListView.setUpdating(true);
            this._changeSongList(this.collection);
        }
        this.trigger("modelChange", this.displayedCollection);
    },
    
    _onSongUpdatedInLibrary : function(songUpdateModel){
        if(this.isSearchApplied())
        {
            this.filterList(this.getAppliedSearchCharacters(),  this._resultSize,this._customFilter,this._customSort);
        }else{
            this.infiniteListView.setUpdating(true);
            this._updateSongInSongList(songUpdateModel);
        }
        this.trigger("modelChange", this.displayedCollection);
    },
    
    isCollectionEmpty : function(){
        if(this.displayedCollection)
        {
            return this.displayedCollection.length == 0;
        }else{
            return true;
        }
    },
    
    show : function(){
        this.$el.css("display", "");
    },
    
    hide : function(){
        this.$el.css("display", "none");
    },
    
    
    _buildInfoBoxViewParams : function(){
        const params = {};
        params.iconClass = "ion-ios7-musical-notes";
        params.actionButtonIconClass = "fontello-icon-search";
        params.message = window.getI18n(ti18n,"NO_SONG");
        params.helpMessage = window.getI18n(ti18n,"THIS_IS_WHERE_YOU_WILL_SEE_SONGS_ADDED_TO_YOUR_TREBBLE");
        params.actionButtonLabel = window.getI18n(ti18n,"SEARCH");
        return params;
    },
   
    _onEmptyInfoBoxButtonPressed : function(){
        this.trigger("infoBoxButtonPressed");
    },
    
    onActionExecutedOnSong : function(songModel, action){
        if(action == "delete" || action == "removeFromRadio" || action == "removeToFavorite"){
            this.displayedCollection.remove(songModel);
            this.infiniteListView.reload();
        }
    },
    
    onSongGradeButtonTapped : function(songModel){
        
        return this.trigger("gradeButtonTapped",songModel);
        
    },
    
    
    onAddToRadioButtonTapped : function(songModel){
        return this.trigger("addToRadioButtonTapped",songModel);
    },
    

    
    onRemoveFromRadioButtonTapped : function(songModel){
        return this.trigger("removeFromRadioButtonTapped",songModel);

    },
    
    
    render: function() {
        const tabHeight = 46;
        const contentHeight = PersistentModels.getInstance().getRouter().getViewportHeight() - tabHeight;
        if(contentHeight && contentHeight != 0){
            this.$el.html(' <div id="maliste"  style="height:' + contentHeight.toString() + 'px;overflow:hidden;width:100%"></div>');
        }else{
            this.$el.html(' <div id="maliste"  style="overflow:hidden;width:100%"></div>');
        }
        this.setElement(this.$el.find("div").first());
        const listParams = {};
        this.displayedCollection.sort();
        listParams.model = this.displayedCollection.toJSON();
        listParams.collection = this.displayedCollection;
        listParams.listRootElement = this.el;
        listParams.defaultNumberOfElements = 80;
        listParams.defaultHeightElement = 60;
        listParams.placeHolderHeight = contentHeight;
        listParams.customInfoBoxParams = this._buildInfoBoxViewParams();
        listParams.numberOfItemsToDisplayPerRow = 1;
        const infiniteListView = new TrebbleSongListView(listParams);
        this.listenTo(infiniteListView, "infoBoxButtonPressed", this._onEmptyInfoBoxButtonPressed);
        this.infiniteListView = infiniteListView;
        //infiniteListView.setModelTransformHandlerBeforeTemplatePiping(this._modelTransformHandlerBeforeTemplatePiping);
        infiniteListView.addOnItemTapHandler(this._getOnListItemTapHandler());
        infiniteListView.addOnOptionButtonTappedHandlers(this._getOnListButtonTappedHandler());
        infiniteListView.addOnSwipeRightRevealHandler(this._onItemSwipeRight());
        infiniteListView.render();
        this.listenTo(infiniteListView, "actionExecutedOnSongHandler", this.onActionExecutedOnSong.bind(this));
        this.listenTo(infiniteListView, "addToRadioButtonTapped", this.onAddToRadioButtonTapped);
        this.listenTo(infiniteListView, "removeFromRadioButtonTapped", this.onRemoveFromRadioButtonTapped);
        this.listenTo(infiniteListView, "gradeButtonTapped", this.onSongGradeButtonTapped);
        this._onOrientationChangeFunction(PersistentModels.getInstance().getRouter().getViewportHeight(), true);
        return this;
    }

});

export default SongTabContentView;
