var amdi18n={"__root":{"RECIPIENT_OF_THE_CAPSULE_COULDNT_BE_FOUND":"Recipient of the content couldn't be found!","SOMEHTING_WENT_WRONG_WHILE_RETRIEVING_USER_INFO":"Something went wrong while retrieving user information","SEND_A_CAPSULE_TO":"Send a content to","YOU_WILL_NEED_TO_GIVE_THE_PERMISSION_ACCESS_TO_YOUR_MIC":"You will need to give the permission to access your microphone.","RECORD_YOUR_CAPSULE":"Record Your Content","RECORD":"Record","SENDING_YOUR_CAPSULE":"Sending your content"},"__fr":{"RECIPIENT_OF_THE_CAPSULE_COULDNT_BE_FOUND":"Le destinataire de le contenu n'a pas pu être trouvé!","SOMEHTING_WENT_WRONG_WHILE_RETRIEVING_USER_INFO":"Une erreur s'est produite lors de la récupération des informations de l'utilisateur.","SEND_A_CAPSULE_TO":"Envoyer un contenu à","YOU_WILL_NEED_TO_GIVE_THE_PERMISSION_ACCESS_TO_YOUR_MIC":"Vous devrez donner la permission d'accéder à votre microphone.","RECORD_YOUR_CAPSULE":"Enregistrez votre contenu","RECORD":"Enregistrez","SENDING_YOUR_CAPSULE":"Envoi de votre contenu"},"__en":{"RECIPIENT_OF_THE_CAPSULE_COULDNT_BE_FOUND":"Recipient of the content couldn't be found!","SOMEHTING_WENT_WRONG_WHILE_RETRIEVING_USER_INFO":"Something went wrong while retrieving user information","SEND_A_CAPSULE_TO":"Send a content to","YOU_WILL_NEED_TO_GIVE_THE_PERMISSION_ACCESS_TO_YOUR_MIC":"You will need to give the permission to access your microphone.","RECORD_YOUR_CAPSULE":"Record Your Content","RECORD":"Record","SENDING_YOUR_CAPSULE":"Sending your content"}};amdi18n.init=function(language){
		// get the default language
		if(!language){
			if(typeof window !== 'undefined' && window._i18n && window._i18n.locale){
				language = window._i18n.locale;
			}else if(typeof document !== 'undefined' && document.documentElement.lang){
				language = document.documentElement.lang;
			}else if(typeof navigator !== 'undefined' && (navigator.languages || navigator.language || navigator.userLanguage)){
				// navigator.languages does not exist in IE 11
				language = (navigator.languages && navigator.languages[0]) ||
					navigator.language ||
					navigator.userLanguage;
				language = (language || 'root').toLowerCase();
			}else{
				language = 'root';
			}
		}
		var target = this['__' + language] || this.__root;

		// copy definition to root level
		if (target) {
			for (var name in target) {
				this[name] = target[name];
			}
		}

		// fallback to root
		var recursiveFallback = function(target, source){
			for(var name in source){
				switch(typeof target[name]){
				case 'undefined':
					target[name] = source[name];
					break;
				case 'object':
					recursiveFallback(target[name], source[name]);
				}
			}
		};
		recursiveFallback(this, this.__root);
	};amdi18n.init();module.exports=amdi18n;