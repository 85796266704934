import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import CordovaHelper from "models/helper/CordovaHelper";
import SongsTabContentController from "controllers/myLibrary/SongsTabContentController";
import ArtistsTabContentController from "controllers/myLibrary/ArtistsTabContentController";
import AlbumsTabContentController from "controllers/myLibrary/AlbumsTabContentController";
import MoreTabContentController from "controllers/myLibrary/MoreTabContentController";
import CapsuleCardController from "controllers/capsule/CapsuleCardController";
import CollectionRepeatController from "controllers/common/CollectionRepeatController";
import InfoBoxController from "controllers/common/InfoBoxController";
import PlaylistListController from "controllers/common/PlaylistListController";
import LibrarySortAndFilterOptionController from "controllers/myLibrary/LibrarySortAndFilterOptionController";
import Utils from "models/helper/Utils";
import Capsules from "collections/Capsules";
import PlaylistHelper from "models/helper/PlaylistHelper";
import AudioRecorderHelper from "models/helper/AudioRecorderHelper";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import SongSelectorPopupController from "controllers/common/SongSelectorPopupController";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import ti18n from "i18n!nls/MyLibraryi18n";
import MyLibraryPageView from "views/myLibrary/myLibraryPageView";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";

const SHOW_CAPSULE_TABS = true;

const MyLibraryPageController = Chaplin.Controller.extend({


    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if (options) {
            if (options.view) {
                this.view = options.view;
            }
            if (options.model) {
                this.model = options.model;
            }
        }
        this.context = Utils.getInstance().buildContextForSongCollectionMyLibrary();
        this.songs = options.songs;
        this.albums = options.albums;
        this.artists = options.artists;
        this.favoriteSongs = options.favoriteSongs;
        this._selectedFilterOption = null;
        this._selectedSortOption = null;
        this._capsulesInfoTypeToCapsulesArray = {};
        this._capsulesInfoTypeToDataNedToBeRetrievedMap = {};
        this.listenTo(PersistentModels.getInstance(), "capsuleProcessingCompleted", this._onCapsuleSuccessfullyCreated.bind(this));
        this.listenTo(PersistentModels.getInstance(), "capsuleAddedOrRemoved", this._onCapsuleSuccessfullyCreated.bind(this));
    },
    
    getModel : function(){
        return this.model;
    },
    
    getView : function(){
        return this.view;
    },
    
    _buildInfoBoxParamsForGenreTab : function(){
        const params = {};
        params.iconClass = "ion-ios7-star";
        params.actionButtonIconClass = "fontello-icon-search";
        params.message = "No genre was found matching your critria";
        params.helpMessage = "Find songs you like in the wide search and tap on the star to add them to your favorites :)";
params.actionButtonLabel = "Search";
params.actionButtonHandlerFunction = this._showGlobalSearchPage.bind(this);
return params;
},



_buildInfoBoxParamsForRatedTab : function(){
const params = {};
params.iconClass = "ion-ios7-star";
params.actionButtonIconClass = "fontello-icon-search";
params.message = "No Rated group was found matching your critria";
params.helpMessage = "Find songs you like in the wide search and tap on the star to add them to your favorites :)";
params.actionButtonLabel = "Search";
params.actionButtonHandlerFunction = this._showGlobalSearchPage.bind(this);
return params;
},

_showGlobalSearchPage : function(){
let searchFieldValue = null;
if(this.getView()){
searchFieldValue = this.getView().getSearchFieldValue();
}

PersistentModels.getInstance().getRouter().showGlobalSearchPage(searchFieldValue,true);
},

showHomePopup : function(){
PersistentModels.getInstance().getRouter().showHomePage();
},

setSearchValue : function(searchValue){
if(this.getView()){
this.getView().setSearchValue(searchValue);
}

},

setSelectedTab : function(selectedTab){
if(this.getView() && selectedTab){
switch(selectedTab){
    case "receivedcapsules":
    this.view.selectReceivedCapsuleTab();
    break;

    case "activecapsules":
    this.view.selectActiveCapsuleTab();
    break;
    case "draftcapsules":
    this.view.selectDraftCapsuleTab();
    break;
    case "inactivecapsules":
    this.view.selectExpiredCapsuleTab();
    break;

    case "mysongs":
    this.view.selectSongsTab();
    break;

}
}

},

_buildGenreListController : function(){
const genrePlaylistJsonArray = null;
const genrePlaylistListControllerParams = {};
genrePlaylistListControllerParams.loadHandler =  this._getGenrePlaylistLoadFunction();
genrePlaylistListControllerParams.resizePlaceHolderElem =false;
const tabHeight = 46;
const subTabHeight = 44;
genrePlaylistListControllerParams.heightMargin = tabHeight + subTabHeight;
genrePlaylistListControllerParams.extendedView = false;
genrePlaylistListControllerParams.onPlaylistSeclectedHandler =  this.onGenrePlaylistSelected.bind(this);
genrePlaylistListControllerParams.customInfoBoxParams = this._buildInfoBoxParamsForGenreTab()
genrePlaylistListControllerParams.onInfoBoxButtonPressed =  this._showGlobalSearchPage.bind(this);
const genrePlaylistListController = new PlaylistListController(genrePlaylistListControllerParams);
return genrePlaylistListController;
},

_initializeUserOwnedCapsules : function(){
const controllerParams = {};
controllerParams.showActiveFlagInView = true;
controllerParams.context = this.context;
controllerParams.onActionExecutedOnUserHandler = this._onActionExecutedByUserOnDisplayedCapsule.bind(this);
const collectionRepeatControllerParams = {};
collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildInfoBoxParamsForOnAirCapsules();
collectionRepeatControllerParams.showEmtptyInfoBox = true;
collectionRepeatControllerParams.controllerOption = controllerParams;
collectionRepeatControllerParams.itemControllerConstructor = CapsuleCardController;
collectionRepeatControllerParams.modelLoadHandler = this._getMyOnAirCapsulesLoadHandler.bind(this);
collectionRepeatControllerParams.resizePlaceHolderElem = true;
collectionRepeatControllerParams.heightMargin = 52 + 39 + 33;
collectionRepeatControllerParams.context = this.context;

collectionRepeatControllerParams.modelLoadMoreHandler = this._getMyOnAirCapsulesLoadHandlerWithPaging.bind(this);
collectionRepeatControllerParams.loadOnScroll = true;
collectionRepeatControllerParams.numberOfResultsPerPage = this._numberOfResultsPerPage? this._numberOfResultsPerPage: 21;


this._capuleListLoaded = false;
collectionRepeatControllerParams.actionButtonHandlerFunction = this.createCapsuleNew.bind(this);
this.onAirCollectionRepeatController = new CollectionRepeatController(collectionRepeatControllerParams);
return this.onAirCollectionRepeatController;
},

_initializeUserScheduledCapsules : function(){
const controllerParams = {};
controllerParams.showActiveFlagInView = true;
controllerParams.context = this.context;
controllerParams.onActionExecutedOnUserHandler = this._onActionExecutedByUserOnDisplayedCapsule.bind(this);
const collectionRepeatControllerParams = {};
collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildInfoBoxParamsForScheduledCapsules();
collectionRepeatControllerParams.showEmtptyInfoBox = true;
collectionRepeatControllerParams.controllerOption = controllerParams;
collectionRepeatControllerParams.itemControllerConstructor = CapsuleCardController;
collectionRepeatControllerParams.modelLoadHandler = this._getMyScheduledCapsulesLoadHandler.bind(this);
collectionRepeatControllerParams.resizePlaceHolderElem = true;
collectionRepeatControllerParams.heightMargin = 52 + 39 + 33;
collectionRepeatControllerParams.context = this.context;

collectionRepeatControllerParams.modelLoadMoreHandler = this._getMyScheduledCapsulesLoadHandlerWithPaging.bind(this);
collectionRepeatControllerParams.loadOnScroll = true;
collectionRepeatControllerParams.numberOfResultsPerPage = this._numberOfResultsPerPage? this._numberOfResultsPerPage: 21;

this._capuleListLoaded = false;
collectionRepeatControllerParams.actionButtonHandlerFunction = this.createCapsuleNew.bind(this);
this.scheduledCollectionRepeatController = new CollectionRepeatController(collectionRepeatControllerParams);
return this.scheduledCollectionRepeatController;
},

_initializeUserReceivedCapsules : function(){
const controllerParams = {};
controllerParams.showActiveFlagInView = false;
controllerParams.context = this.context;
controllerParams.onActionExecutedOnUserHandler = this._onActionExecutedByUserOnDisplayedCapsule.bind(this);
const collectionRepeatControllerParams = {};
collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildInfoBoxParamsForReceivedCapsules();
collectionRepeatControllerParams.showEmtptyInfoBox = true;
collectionRepeatControllerParams.controllerOption = controllerParams;
collectionRepeatControllerParams.itemControllerConstructor = CapsuleCardController;
collectionRepeatControllerParams.modelLoadHandler =  this._getMyReceivedCapsulesLoadHandler.bind(this);
collectionRepeatControllerParams.resizePlaceHolderElem = true;
collectionRepeatControllerParams.heightMargin = 52 + 39 + 33;
collectionRepeatControllerParams.context = this.context;

collectionRepeatControllerParams.modelLoadMoreHandler = this._getMyReceivedCapsulesLoadHandlerWithPaging.bind(this);
collectionRepeatControllerParams.loadOnScroll = true;
collectionRepeatControllerParams.numberOfResultsPerPage = this._numberOfResultsPerPage? this._numberOfResultsPerPage: 21;


this._receivedCapuleListLoaded = false;
collectionRepeatControllerParams.actionButtonHandlerFunction = this.createCapsuleNew.bind(this);
this.receivedCollectionRepeatController = new CollectionRepeatController(collectionRepeatControllerParams);
return this.receivedCollectionRepeatController;
},

_initializeDraftCapsules : function(){
const controllerParams = {};
controllerParams.showActiveFlagInView = false;
controllerParams.context = this.context;
controllerParams.onActionExecutedOnUserHandler = this._onActionExecutedByUserOnDisplayedCapsule.bind(this);
const collectionRepeatControllerParams = {};
collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildInfoBoxParamsForDraftCapsules();
collectionRepeatControllerParams.showEmtptyInfoBox = true;
collectionRepeatControllerParams.controllerOption = controllerParams;
collectionRepeatControllerParams.itemControllerConstructor = CapsuleCardController;
collectionRepeatControllerParams.modelLoadHandler =  this._getMyDraftCapsulesLoadHandler.bind(this);
collectionRepeatControllerParams.context = this.context;
collectionRepeatControllerParams.resizePlaceHolderElem = true;
collectionRepeatControllerParams.heightMargin = 52 + 39 + 33;

collectionRepeatControllerParams.modelLoadMoreHandler = this._getMyDraftCapsulesLoadHandlerWithPaging.bind(this);
collectionRepeatControllerParams.loadOnScroll = true;
collectionRepeatControllerParams.numberOfResultsPerPage = this._numberOfResultsPerPage? this._numberOfResultsPerPage: 21;


this._draftCapuleListLoaded = false;
collectionRepeatControllerParams.actionButtonHandlerFunction = this.createCapsuleNew.bind(this);
this.draftCollectionRepeatController = new CollectionRepeatController(collectionRepeatControllerParams);
return this.draftCollectionRepeatController;
},

_initializeOffAirCapsules : function(){
const controllerParams = {};
controllerParams.showActiveFlagInView = false;
controllerParams.context = this.context;
controllerParams.onActionExecutedOnUserHandler = this._onActionExecutedByUserOnDisplayedCapsule.bind(this);
const collectionRepeatControllerParams = {};
collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildInfoBoxParamsForOffAirCapsules();
collectionRepeatControllerParams.showEmtptyInfoBox = true;
collectionRepeatControllerParams.controllerOption = controllerParams;
collectionRepeatControllerParams.itemControllerConstructor = CapsuleCardController;
collectionRepeatControllerParams.modelLoadHandler =  this._getMyOffAirCapsulesLoadHandler.bind(this);
collectionRepeatControllerParams.context = this.context;
collectionRepeatControllerParams.resizePlaceHolderElem = true;
collectionRepeatControllerParams.heightMargin = 52 + 39 + 33;

collectionRepeatControllerParams.modelLoadMoreHandler = this._getMyOffAirCapsulesLoadHandlerWithPaging.bind(this);
collectionRepeatControllerParams.loadOnScroll = true;
collectionRepeatControllerParams.numberOfResultsPerPage = this._numberOfResultsPerPage? this._numberOfResultsPerPage: 21;

this._offAirCapuleListLoaded = false;
collectionRepeatControllerParams.actionButtonHandlerFunction = this.createCapsuleNew.bind(this);
this.offAirCollectionRepeatController = new CollectionRepeatController(collectionRepeatControllerParams);
return this.offAirCollectionRepeatController;
},

createCapsuleNew :function(){
this._onSongSelectedForCapsuleCreation(null, true);
},

_onSongSelectedForCapsuleCreation: function(songSelected, noSongSelectedButtonClicked){
if(songSelected || noSongSelectedButtonClicked){
$.mobile.loading("show");
if(window.device && window.device.platform =="browser"){
    AudioRecorderHelper.getInstance().initUserMedia().then((function(allowed){
        $.mobile.loading("hide");
        if(allowed){
            return PersistentModels.getInstance().getRouter().showAddAudioHightlightPageNew(songSelected,null, this.context,null/* this._onCapsuleSuccessfullyCreated.bind(this)*/,true, true);
        }else{
            return PersistentModels.getInstance().getRouter().showAddAudioHightlightPageNew(songSelected,null, this.context, null/*this._onCapsuleSuccessfullyCreated*/.bind(this),false, true);
        }
    }).bind(this)).catch((function(e){
        $.mobile.loading("hide");
        window.navigator.trebbleNotification.alert(e, null, null, "warning"); 
        return PersistentModels.getInstance().getRouter().showAddAudioHightlightPageNew(songSelected,null, this.context, null/*this._onCapsuleSuccessfullyCreated*/,false, true);
    }).bind(this));
}else{
    $.mobile.loading("hide");
    return PersistentModels.getInstance().getRouter().showAddAudioHightlightPageNew(songSelected, null, this.context, null/*this._onCapsuleSuccessfullyCreated*/,true, true);
}
}
},


_buildInfoBoxParamsForOnAirCapsules : function(){
const params = {};
params.iconClass = "ion-contrast";
params.message = window.getI18n(ti18n,"NO_CAPSULE_ON_AIR");
params.helpMessage = window.getI18n(ti18n,"CREATE_A_CAPSULE_BY_RECORDING_YOURSELF");
params.actionButtonLabel = window.getI18n(ti18n,"CREATE_CAPSULE");
return params;

},

_buildInfoBoxParamsForScheduledCapsules : function(){
const params = {};
params.iconClass = "ion-contrast";
params.message = window.getI18n(ti18n,"NO_SCHEDULED_CAPSULE");
params.helpMessage = window.getI18n(ti18n,"THIS_IS_WHERE_YOU_WILL_SEE_THE_LIST_OF_ALL_CAPSULES");
return params;

},

_buildInfoBoxParamsForOffAirCapsules : function(){
const params = {};
params.iconClass = "ion-contrast";
params.message = window.getI18n(ti18n,"NO_ARCHIVED_CAPSULE");
params.helpMessage = window.getI18n(ti18n,"THIS_IS_WHERE_YOU_WILL_ARCHIVED_CAPSULES");
return params;

},

_buildInfoBoxParamsForDraftCapsules : function(){
const params = {};
params.iconClass = "ion-contrast";
params.message = window.getI18n(ti18n,"NO_DRAFT_CAPSULE");
params.helpMessage = window.getI18n(ti18n,"THIS_IS_WHERE_YOU_WILL_SEE_DRAFT_CAPSULES");
return params;

},

_buildInfoBoxParamsForReceivedCapsules : function(){
const params = {};
params.iconClass = "ion-contrast";
params.message = window.getI18n(ti18n,"NO_RECEIVED_CAPSULE");
params.helpMessage = window.getI18n(ti18n,"THIS_IS_WHERE_YOU_WILL_SEE_RECEIVED_CAPSULE");
return params;

},

_getAllCapsulesInfo : function(capsulesInfoType){
if(this._capsulesInfoTypeToCapsulesArray && this._capsulesInfoTypeToCapsulesArray[capsulesInfoType] && !this._capsulesInfoTypeToDataNedToBeRetrievedMap[capsulesInfoType]){
this._capsulesInfoTypeToDataNedToBeRetrievedMap[capsulesInfoType] = true;
const result = this._capsulesInfoTypeToCapsulesArray[capsulesInfoType];
return RSVP.Promise.resolve(result);
}else{
if(this._isCapsuleRetrievalPending){
    return this._isCapsuleRetrievalPending.then((function(){
        this._capsulesInfoTypeToDataNedToBeRetrievedMap[capsulesInfoType] = true;
        return this._capsulesInfoTypeToCapsulesArray[capsulesInfoType];
    }).bind(this));
}else{
    this._isCapsuleRetrievalPending = TrebbleClientAPIHelper.getInstance().getAllCapsulesByType(500).then((function(result){
        for(let p in result){
            this._capsulesInfoTypeToCapsulesArray[p] = result[p];
        }
        this._isCapsuleRetrievalPending = null;
        this._capsulesInfoTypeToDataNedToBeRetrievedMap = {};
        this._capsulesInfoTypeToDataNedToBeRetrievedMap[capsulesInfoType] = true;
        return this._capsulesInfoTypeToCapsulesArray[capsulesInfoType];
    }).bind(this))
    return this._isCapsuleRetrievalPending;
}
}
},

_getMyOnAirCapsulesLoadHandler: function(){

return this._getAllCapsulesInfo("onAirCapsules").then(function(capsulesInfoArray){
if(capsulesInfoArray && capsulesInfoArray.length > 0){
    this.capsulesCollection = new Capsules(capsulesInfoArray);
    return this.capsulesCollection.models;
}else{
    return  [];
}
});
},

_getMyOnAirCapsulesLoadHandlerWithPaging:  function(filter, offset, numberOfItemToLoad){
return TrebbleClientAPIHelper.getInstance().getOnAirCapsulesWithPaging(null, offset, numberOfItemToLoad).then((function(results){
const capsuleModelArray = [];
if(results && results.length > 0){
    for(let i =0; i < results.length; i++){
        capsuleModelArray.push(Utils.getInstance().getModelFromCapsuleJson(results[i]));
    }
}
return capsuleModelArray;
}).bind(this))
},


_getMyScheduledCapsulesLoadHandler: function(){

return this._getAllCapsulesInfo("onAirScheduledCapsules").then(function(capsulesInfoArray){
if(capsulesInfoArray && capsulesInfoArray.length > 0){
    this.capsulesCollection = new Capsules(capsulesInfoArray);
    return this.capsulesCollection.models;
}else{
    return  [];
}
});
},

_getMyScheduledCapsulesLoadHandlerWithPaging:  function(filter, offset, numberOfItemToLoad){
return TrebbleClientAPIHelper.getInstance().getScheduledCapsulesWithPaging(null, offset, numberOfItemToLoad).then((function(results){
const capsuleModelArray = [];
if(results && results.length > 0){
    for(let i =0; i < results.length; i++){
        capsuleModelArray.push(Utils.getInstance().getModelFromCapsuleJson(results[i]));
    }
}
return capsuleModelArray;
}).bind(this))
},


_getMyReceivedCapsulesLoadHandler: function(){

return this._getAllCapsulesInfo("receivedCapsules").then(function(receivedCapsulesInfoArray){
if(receivedCapsulesInfoArray && receivedCapsulesInfoArray.length > 0){
    this.receivedCapsulesCollection = new Capsules(receivedCapsulesInfoArray);
    return this.receivedCapsulesCollection.models;
}else{
    return  [];
}
});
},

_getMyReceivedCapsulesLoadHandlerWithPaging:  function(filter, offset, numberOfItemToLoad){
return TrebbleClientAPIHelper.getInstance().getReceivedCapsulesWithPaging(null, offset, numberOfItemToLoad).then((function(results){
const capsuleModelArray = [];
if(results && results.length > 0){
    for(let i =0; i < results.length; i++){
        capsuleModelArray.push(Utils.getInstance().getModelFromCapsuleJson(results[i]));
    }
}
return capsuleModelArray;
}).bind(this))
},

_getMyOffAirCapsulesLoadHandler: function(){

return this._getAllCapsulesInfo("offAirCapsules").then(function(offAirCapsulesInfoArray){
if(offAirCapsulesInfoArray && offAirCapsulesInfoArray.length > 0){
    this.offAirCapsulesCollection = new Capsules(offAirCapsulesInfoArray);
    return this.offAirCapsulesCollection.models;
}else{
    return  [];
}
});
},

_getMyOffAirCapsulesLoadHandlerWithPaging:  function(filter, offset, numberOfItemToLoad){
return TrebbleClientAPIHelper.getInstance().getOffAirCapsulesWithPaging(null, offset, numberOfItemToLoad).then((function(results){
const capsuleModelArray = [];
if(results && results.length > 0){
    for(let i =0; i < results.length; i++){
        capsuleModelArray.push(Utils.getInstance().getModelFromCapsuleJson(results[i]));
    }
}
return capsuleModelArray;
}).bind(this))
},

_getMyDraftCapsulesLoadHandler: function(){

return this._getAllCapsulesInfo("draftCapsules").then(function(draftCapsulesInfoArray){
if(draftCapsulesInfoArray && draftCapsulesInfoArray.length > 0){
    this.draftCapsulesCollection = new Capsules(draftCapsulesInfoArray);
    return this.draftCapsulesCollection.models;
}else{
    return  [];
}
});
},

_getMyDraftCapsulesLoadHandlerWithPaging:  function(filter, offset, numberOfItemToLoad){
return TrebbleClientAPIHelper.getInstance().getDraftCapsulesWithPaging(null, offset, numberOfItemToLoad).then((function(results){
const capsuleModelArray = [];
if(results && results.length > 0){
    for(let i =0; i < results.length; i++){
        capsuleModelArray.push(Utils.getInstance().getModelFromCapsuleJson(results[i]));
    }
}
return capsuleModelArray;
}).bind(this))
},

_onActionExecutedByUserOnDisplayedCapsule : function(actionName, capsuleJson, capsuleCardControllerInstance){
/*this.onAirCollectionRepeatController.reload();
this.offAirCollectionRepeatController.reload();
this.draftCollectionRepeatController.reload();
this.receivedCollectionRepeatController.reload();*/
},

_onCapsuleSuccessfullyCreated : function(){

const finishCurrentLoadingIfApplicablePromise = this._tabsLoadingPromise? this._tabsLoadingPromise: RSVP.Promise.resolve(); 
return finishCurrentLoadingIfApplicablePromise.then((function(){
const promises = []
promises.push(this.onAirCollectionRepeatController.reload());
promises.push(this.receivedCollectionRepeatController.reload());
promises.push(this.scheduledCollectionRepeatController.reload());
promises.push(this.offAirCollectionRepeatController.reload());
promises.push(this.draftCollectionRepeatController.reload());
this._tabsLoadingPromise = RSVP.allSettled(promises).then((function(){
    this._tabsLoadingPromise = null;
}).bind(this));
return this._tabsLoadingPromise;

}).bind(this))


},


_getGenrePlaylistLoadFunction : function(){
return (function(){
return  new RSVP.Promise(function(resolve, reject){
    try{
        const genrePlaylistSummaryCollection = PersistentModels.getInstance().getGenrePlaylistSummariesCollection();
        return resolve(genrePlaylistSummaryCollection? genrePlaylistSummaryCollection.toJSON(): []);
    }catch(err){
        reject(err);
    }
});

}).bind(this);
},


onGenrePlaylistSelected : function(playlistSummarySelectedModel){
PersistentModels.getInstance().getRouter().showLocalGenrePlaylistDetailPage(playlistSummarySelectedModel, true);
},



_buildRatedListController : function(){
const ratedPlaylistListControllerParams = {};
ratedPlaylistListControllerParams.loadHandler =  this._getRatedPlaylistLoadFunction();
ratedPlaylistListControllerParams.resizePlaceHolderElem =false;
const tabHeight = 46;
const subTabHeight = 44;
ratedPlaylistListControllerParams.heightMargin = tabHeight + subTabHeight;
ratedPlaylistListControllerParams.extendedView = false;
ratedPlaylistListControllerParams.onPlaylistSeclectedHandler =  this.onRatedPlaylistSelected.bind(this);
ratedPlaylistListControllerParams.customInfoBoxParams = this._buildInfoBoxParamsForRatedTab()
ratedPlaylistListControllerParams.onInfoBoxButtonPressed =  this._showGlobalSearchPage.bind(this);
const ratedPlaylistListController = new PlaylistListController(ratedPlaylistListControllerParams);
return ratedPlaylistListController;
},

_getRatedPlaylistLoadFunction : function(){
return (function(){
return  new RSVP.Promise(function(resolve, reject){
    try{
        const ratedPlaylistSummaryCollection = PersistentModels.getInstance().getMyRatedPlaylistSummariesCollection();
        return resolve(ratedPlaylistSummaryCollection? ratedPlaylistSummaryCollection.toJSON(): []);
    }catch(err){
        reject(err);
    }
});

}).bind(this);
},


_onFilterOptionChanged : function(filterOption){
this._selectedFilterOption  = filterOption;
this.onKeyUpInSearchFieldHandler(this.view.getSearchFieldValue());
},

_onSortOptionChanged : function(sortOption){
this._selectedSortOption  = sortOption;
this.onKeyUpInSearchFieldHandler(this.view.getSearchFieldValue());
},

showFilterAndSortOptionPopup : function(){
const controllerParams ={};
const onPopupCloseFunction = (function(selectedFilterOption, selectedSortOption){
$.mobile.loading("show");
this._onFilterOptionChanged(selectedFilterOption);
this._onSortOptionChanged(selectedSortOption);
$.mobile.loading("hide");
}).bind(this);
controllerParams.onPopupClose = onPopupCloseFunction;
controllerParams.selectedFilterOption = this._selectedFilterOption;
controllerParams.selectedSortOption = this._selectedSortOption;
const librarySortAndFilterOptionController = new LibrarySortAndFilterOptionController(controllerParams);
return librarySortAndFilterOptionController.buildModelAndRenderView();
},

onRatedPlaylistSelected : function(playlistSummarySelectedModel){
PersistentModels.getInstance().getRouter().showLocalRatedPlaylistDetailPage(playlistSummarySelectedModel, true);
},

filterList : function(filters){
//window.alertErrorMessage("Coming Soon! this is where you will able to filter to see your entire song library or only the ones you have on your device");
this.showFilterAndSortOptionPopup();
},

getSelectedFilterId : function(){
return this._selectedFilterOption?this._selectedFilterOption.getId(): null;
},

getSelectedSortId : function(){
return this._selectedSortOption?this._selectedSortOption.getId(): null;
},

onKeyUpInSearchFieldHandler : function(searchFieldValue){

if(!SHOW_CAPSULE_TABS){
if(this.view.isSongTabSelected()){
    this.songsTabContentController.filterList(searchFieldValue, this.getSelectedFilterId(), this.getSelectedSortId());
}else{
    window.setTimeout((function(){
        this.songsTabContentController.filterList(searchFieldValue, this.getSelectedFilterId(), this.getSelectedSortId());
    }).bind(this),0)
}
if(this.view.isAlbumTabSelected()){
    this.albumsTabContentController.filterList(searchFieldValue, this.getSelectedFilterId(), this.getSelectedSortId());
}else{
    window.setTimeout((function(){
        this.albumsTabContentController.filterList(searchFieldValue, this.getSelectedFilterId(), this.getSelectedSortId());
    }).bind(this),0)
}
if(this.view.isArtistTabSelected()){
    this.artistsTabContentController.filterList(searchFieldValue, this.getSelectedFilterId(), this.getSelectedSortId());
}else{
    window.setTimeout((function(){
        this.artistsTabContentController.filterList(searchFieldValue, this.getSelectedFilterId(), this.getSelectedSortId());
    }).bind(this),0)
}
}
},

onSearchFieldClearedHandler : function(){
this.onKeyUpInSearchFieldHandler("");
},

onMyLibraryPageBeforeShow :function(){
if(SHOW_CAPSULE_TABS){
if(this.onAirCollectionRepeatController.getView()){
    this.onAirCollectionRepeatController.reload();
}
if(this.receivedCollectionRepeatController.getView()){
    this.receivedCollectionRepeatController.reload();
}
if(this.scheduledCollectionRepeatController.getView()){
    this.scheduledCollectionRepeatController.reload();
}
if(this.offAirCollectionRepeatController.getView()){
    this.offAirCollectionRepeatController.reload();
}
if(this.draftCollectionRepeatController.getView()){
    this.draftCollectionRepeatController.reload();
}
if(false && !LocalStorageHelper.getInstance().isWhatsACapsuleCapsulePlayed() && !PersistentModels.getInstance().hasCapsuleCreated()){
    this.view.showHideWhatACapsuleButton(true);
}else{
    this.view.showHideWhatACapsuleButton(false);
}
}
},

buildModelAndRenderView : function() {
return new RSVP.Promise((function(resolve,reject){
try{

    const songsTabContentControllerParams = {};
    songsTabContentControllerParams.model =  PersistentModels.getInstance().getSongCollection();
    this.songsTabContentController = new SongsTabContentController(songsTabContentControllerParams);
    if(!SHOW_CAPSULE_TABS){
        const albumsTabContentControllerParams = {};
        albumsTabContentControllerParams.model =  PersistentModels.getInstance().getAlbumCollection();
        this.albumsTabContentController = new AlbumsTabContentController(albumsTabContentControllerParams);
        const artistsTabContentControllerParams = {};
        artistsTabContentControllerParams.model =  PersistentModels.getInstance().getArtistCollection();
        this.artistsTabContentController =  new ArtistsTabContentController(artistsTabContentControllerParams);
        this._genreListController =  this._buildGenreListController();
        this._ratedListController =  this._buildRatedListController();
    }else{
        this._initializeUserOwnedCapsules();
        this._initializeUserScheduledCapsules();
        this._initializeOffAirCapsules();
        this._initializeDraftCapsules();
        this._initializeUserReceivedCapsules();
    }

    const promiseArray = [];
    
    if(!SHOW_CAPSULE_TABS){
        promiseArray.push(this.songsTabContentController.buildModelAndRenderView());
        promiseArray.push(this.albumsTabContentController.buildModelAndRenderView());
        promiseArray.push(this.artistsTabContentController.buildModelAndRenderView());

        promiseArray.push(this._genreListController.buildModelAndRenderView());
        promiseArray.push(this._ratedListController.buildModelAndRenderView());
    }else{
        promiseArray.push(this.onAirCollectionRepeatController.buildModelAndRenderView());
        promiseArray.push(this.offAirCollectionRepeatController.buildModelAndRenderView());
        promiseArray.push(this.draftCollectionRepeatController.buildModelAndRenderView());
        promiseArray.push(this.receivedCollectionRepeatController.buildModelAndRenderView());
        promiseArray.push(this.scheduledCollectionRepeatController.buildModelAndRenderView());
    }

    RSVP.Promise.all(promiseArray).then((function(){
        
        if(!SHOW_CAPSULE_TABS){
            this.songsTabContentView = this.songsTabContentController.getView();
            this.songsTabContentCollection = this.songsTabContentController.getModel();
            this.albumsTabContentView = this.albumsTabContentController.getView();
            this.artistsTabContentView = this.artistsTabContentController.getView();

            this.genreTabContentView = this._genreListController.getView();
            this.ratedTabContentView = this._ratedListController.getView();



            this.albumsTabContentCollection = this.albumsTabContentController.getModel();
            this.artistsTabContentCollection = this.artistsTabContentController.getModel();
        }

        const libraryPageParams  = {};

        if(!SHOW_CAPSULE_TABS){
            libraryPageParams.artistsTabContentView = this.artistsTabContentView;
            this.listenTo(this.artistsTabContentView, "infoBoxButtonPressed",this._showGlobalSearchPage.bind(this));
            libraryPageParams.albumsTabContentView = this.albumsTabContentView;
            this.listenTo(this.albumsTabContentView, "infoBoxButtonPressed",this._showGlobalSearchPage.bind(this));
            libraryPageParams.genreTabContentView = this.genreTabContentView;
            this.listenTo(this.genreTabContentView, "infoBoxButtonPressed",this._showGlobalSearchPage.bind(this));
            libraryPageParams.ratedTabContentView = this.ratedTabContentView;
            this.listenTo(this.ratedTabContentView, "infoBoxButtonPressed",this._showGlobalSearchPage.bind(this));
            libraryPageParams.songsTabContentView = this.songsTabContentView;
            this.listenTo(this.songsTabContentView, "infoBoxButtonPressed",this._showGlobalSearchPage.bind(this));
        }else{
            libraryPageParams.activeTabContentView = this.onAirCollectionRepeatController.getView();
            libraryPageParams.archiveTabContentView = this.offAirCollectionRepeatController.getView();
            libraryPageParams.draftTabContentView = this.draftCollectionRepeatController.getView();
            libraryPageParams.receivedTabContentView = this.receivedCollectionRepeatController.getView();
            libraryPageParams.scheduledTabContentView = this.scheduledCollectionRepeatController.getView();
        }
        libraryPageParams.onKeyUpInSearchFieldHandler = this.onKeyUpInSearchFieldHandler.bind(this);
        libraryPageParams.onSearchFieldClearedHandler = this.onSearchFieldClearedHandler.bind(this);


        libraryPageParams.showCapsuleTab =SHOW_CAPSULE_TABS;
        this.view =  new MyLibraryPageView(libraryPageParams);
        this.listenTo(this.view, "showHomePopup", this.showHomePopup);
        this.listenTo(this.view, "filter", this.filterList);
        this.listenTo(this.view, "pageBeforeShow",this.onMyLibraryPageBeforeShow);
        this.listenTo(this.view, "createCapsule", this.createCapsuleNew.bind(this));
        this.listenTo(this.view, "showGlobalSearchPage", this._showGlobalSearchPage.bind(this));

        this.view.render();

        this.onAirCollectionRepeatController.setScrollContainer(this.view.$el.find("#myOnAirCapsulesListWrapper #collection_repeat_container"));
        this.offAirCollectionRepeatController.setScrollContainer(this.view.$el.find("#myOffAirCapsulesListWrapper #collection_repeat_container"));
        this.draftCollectionRepeatController.setScrollContainer(this.view.$el.find("#myDraftCapsulesList #collection_repeat_container"));
        this.receivedCollectionRepeatController.setScrollContainer(this.view.$el.find("#myReceivedCapsulesListWrapper #collection_repeat_container"));
        this.scheduledCollectionRepeatController.setScrollContainer(this.view.$el.find("#myScheduledCapsulesListWrapper #collection_repeat_container"));

        resolve();
    }).bind(this)).catch(reject);
}catch(error){
reject(error);
}

}).bind(this));


}

});

export default MyLibraryPageController;