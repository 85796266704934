	import $ from 'jquery';
    import _ from 'underscore';
    import Backbone from "backbone";
    import PlayerService from "services/PlayerService";
    import SongIdentifiedDetailPopupController from "controllers/songIdentifiedDetail/SongIdentifiedDetailPopupController";
    import Levenshtein from "libs/levenshtein/levenshtein";
    import TrebbleIndexDBHelper from "models/helper/TrebbleIndexDBHelper";
    import TrebbleClientAPI from "models/helper/TrebbleClientAPI";
    import CordovaHelper from "models/helper/CordovaHelper";
    import Utils from "models/helper/Utils";
    import RSVP from "rsvp";


    var SongIdentificationService = Backbone.Model.extend({
        

        initialize : function(options) {
            this.model = options? options.model : null;
            this.context = options? options.context : null;
            this._isIdentifyingSong =false;
            this._pendingIdentificationPromise = null;
        },
        
        isReadyToIdentify : function(){
            if(!this._isIdentifyingSong)
                {
                    window.log("It is ready to identifiedright away");
                    return RSVP.Promise.resolve(true);
                }else{
                    window.log("IThis got to wait away");
                    return this._pendingIdentificationPromise;
                }
        },
        
        listenToOnSongChangePlayerEvent: function(playerModel){
            //this.listenTo(playerModel, "songStartedPlaying", this._onPlayerSongChanged);
        },
        
        
        _onPlayerSongChanged : function(){
            var idifiedOverWifiAllowed = window.trebble.config.idifiedSongInMyLibraryThatArePlayingOnWifi;
            var idifiedOnDataPlanAllowed = window.trebble.config.idifiedSongInMyLibraryThatArePlayingOnDataPlan;
            var shoudIdfied  = (idifiedOverWifiAllowed && Utils.getInstance().isOverWifi()? true : false) || (idifiedOnDataPlanAllowed && Utils.getInstance().isOverDataPlan()? true : false);
            if(shoudIdfied)
            {
                return PlayerService.getInstance().getCurrentLoadedSong().then((function(currentLoadedSong){
                    if(currentLoadedSong && currentLoadedSong.get("_buildOnServer") != true )
                    {
                        if(!currentLoadedSong.get("idified")){
                            this.analyseAudioOutput(false,false,false, true);
                        }
                        
                    }
                }).bind(this)).catch(function(error){
                    window.alertErrorMessage(error);
                });
            }else{
                return Promise.resolve();
            }
        },
        
        
        analyseAudioOutput : function(showLoading, updateSongOnUserActionOnly, doNotUseLevensteinDistance, repeatProcessIfFailure){
            var isMusicPlaying = PlayerService.getInstance().isMusicPlaying();
            var songPlayingNow = null;
            this._pendingIdentificationPromise = this.isReadyToIdentify().then((function(){
                return PlayerService.getInstance().getCurrentLoadedSong();
            }).bind(this)).then((function(currentLoadedSong){
                songPlayingNow = currentLoadedSong;
            }).bind(this)).then((function(){
                this._isIdentifyingSong = true;
                
                return new RSVP.Promise((function(resolve, reject){
                    if(showLoading)
                    {
                        $.mobile.loading( 'show');
                    }
                        window.log("Analysing Audio Output ...");
                    CordovaHelper.getInstance().executePluginOperation("MusicFileReaderPlugin","analyseAudioOutput", []).then((function(result){
                
                        if(result && result != "OK" && result.length >0 && result[0] && result[0] != "OK")
                        {
                            var songIdentifiedModel = result[0].idifiedSongInfo;
                            var basicSongInfo = result[0].basicSongInfo;
                            //NEED TO CALL SERVER TO TRY TO GET AN APPROXIMATE RELEASE DATE
                            return SongIdentificationService.getInstance().addPossibleReleaseDateToIdifiedInfo(songIdentifiedModel).then((function(updatedIdifiedSongInfo){
                                songIdentifiedModel = updatedIdifiedSongInfo;
                                if(showLoading)
                                {
                                    $.mobile.loading( "hide" );
                                }
                                if(updateSongOnUserActionOnly)
                                {
                                    this._isIdentifyingSong = false;
                                    resolve(songIdentifiedModel);
                                    return this._showSongIdentifiedDetails(songIdentifiedModel,songPlayingNow, isMusicPlaying);
                                }else{
                                    this._isIdentifyingSong = false;
                                    resolve(songIdentifiedModel);
                                    return this._automaticallyUpdateSongIdentified(songIdentifiedModel,songPlayingNow, doNotUseLevensteinDistance);
                                }
                            }).bind(this)).catch((function(error){
                                //Should never get there anyway
                                if(showLoading)
                                {
                                    $.mobile.loading( "hide" );
                                }
                                if(updateSongOnUserActionOnly)
                                {
                                    this._isIdentifyingSong = false;
                                    resolve(songIdentifiedModel);
                                    return this._showSongIdentifiedDetails(songIdentifiedModel,songPlayingNow, isMusicPlaying);
                                }else{
                                    this._isIdentifyingSong = false;
                                    resolve(songIdentifiedModel);
                                    return this._automaticallyUpdateSongIdentified(songIdentifiedModel,songPlayingNow, doNotUseLevensteinDistance);
                                }
                            }).bind(this));
                        }else{
                            if(showLoading)
                            {
                                $.mobile.loading( "hide" );
                            }
                            if(result && result.operationCancelled){
                                //operation was cancelled so do nothing
                                console.warn("Song identification was cancelled");
                                resolve();
                            }else{
                                if(updateSongOnUserActionOnly)
                                {
                                    this._isIdentifyingSong = false;
                                    window.alertErrorMessage("The song playing couldn't be matched :(");
                                    resolve();
                                }else{
                                    this._isIdentifyingSong = false;
                                    console.warn("The song playing couldn't be matched :(");
                                    if(repeatProcessIfFailure )
                                    {
                                        window.log("trying a  second time");
                                        this.analyseAudioOutput(showLoading, updateSongOnUserActionOnly, doNotUseLevensteinDistance, false).then((function(){
                                            resolve();
                                        }).bind(this));
                                        
                                    }else{
                                        
                                        this._automaticallyUpdateSongIdentified(null,songPlayingNow, doNotUseLevensteinDistance);
                                        resolve();
                                        return;
                                    }
                                }
                            }
                        }
                    }).bind(this)).catch(function(error){
                        
                        if(showLoading)
                        {
                            $.mobile.loading( "hide" );
                            window.alertErrorMessage(JSON.stringify(error));
                        }else{
                            //console.error("The song playing couldn't be matched :( . Error :"+JSON.stringify(error))
                            window.alertErrorMessage("The song playing couldn't be matched :( . Error :"+JSON.stringify(error))
                        }
                        this._isIdentifyingSong = false;
                        resolve();
                    });
                    
                }).bind(this));
            }).bind(this));
            return this._pendingIdentificationPromise;
            
        },
        
        addPossibleReleaseDateToIdifiedInfo : function(idifiedSongInfo){
            if(idifiedSongInfo && idifiedSongInfo != "OK"){
                //NEED TO CALL SERVER TO TRY TO GET AN APPROXIMATE RELEASE DATE
                var songTitle  = idifiedSongInfo.title;
                var artistName  = idifiedSongInfo.artist;
                var keyword = "";
                keyword = songTitle? (songTitle +  keyword): keyword;
                keyword = artistName? (artistName + " " + keyword): keyword;
                if(keyword){
                    return TrebbleClientAPI.getInstance().searchForSongsAlbumsArtists(keyword, 1).then((function(searchResults){
                        if(searchResults && searchResults.songs && searchResults.songs.length > 0){
                            var songJsonFound = searchResults.songs[0];
                            if(songJsonFound.idifiedInfo.year == idifiedSongInfo.year){
                                idifiedSongInfo.releaseDateString = songJsonFound.idifiedInfo.releaseDateString;
                                idifiedSongInfo.releaseDate = songJsonFound.idifiedInfo.releaseDate;
                                window.log("Trebble was able to successfully find a possible release date for the local idified song");
                            }else{
                                window.log("Trebble was not able to successfully find a possible release date for the local idified song");
                            }
                        }
                        return idifiedSongInfo;
                    }).bind(this)).catch((function(error){
                        console.error("Something went wrong while contacting the trebble server to try to find a possible release date for the local idified song.Error: "+ error);
                        return idifiedSongInfo;
                    }).bind(this));
                }else{
                    return RSVP.Promise.resolve(idifiedSongInfo);
                }
            }else{
                return RSVP.Promise.resolve(idifiedSongInfo);
            }
        },
        
        _showSongIdentifiedDetails: function(songIdentifiedModel,exisintingSongModel, isMusicPlaying){
            var songIdentifiedDetailControllerParams = {};
            songIdentifiedDetailControllerParams.model =songIdentifiedModel;
            if(isMusicPlaying == true)
            {
                songIdentifiedDetailControllerParams.isTrebblePlayingMusic = true;
            }else{
                if(isMusicPlaying == false)
                {
                    songIdentifiedDetailControllerParams.isTrebblePlayingMusic = false;
                }else{
                    songIdentifiedDetailControllerParams.isTrebblePlayingMusic =  PlayerService.getInstance().isMusicPlaying();
                }
            }
            if(songIdentifiedDetailControllerParams.isTrebblePlayingMusic){
                songIdentifiedDetailControllerParams.context = PlayerService.getInstance().getPlayerQueueContext();
            }else{
                songIdentifiedDetailControllerParams.context = Utils.getInstance().buildContextForSongCollectionOverTheAir();
            }
            var songIdentifiedDetailPopupController = new SongIdentifiedDetailPopupController(songIdentifiedDetailControllerParams);
            return songIdentifiedDetailPopupController.buildModelAndRenderView();
        },
    
        
        _addSongAsFavorite : function(){
            var context = null;
            return TrebbleIndexDBHelper.getInstance().saveSongFromIdifiedInfo(this.model, this.context).then(function(){
                window.log("song added to library as favorite");
            }).catch(function(error){
                window.alertErrorMessage(error);
            });
        },
    
        _automaticallyUpdateSongIdentified : function(idifiedInfo ,songModelInPlayerWhenIDProcessStarted, doNotUseLevensteinDistance){
            return PlayerService.getInstance().getCurrentLoadedSong().then((function(currentLoadedSong){
                if(songModelInPlayerWhenIDProcessStarted && currentLoadedSong == songModelInPlayerWhenIDProcessStarted )
                {
                    currentLoadedSong = songModelInPlayerWhenIDProcessStarted;
                    if(idifiedInfo && idifiedInfo != "OK")
                    {	
                        //Identification succceeded
                        let shouldBeUpdated = true;
                        if(!doNotUseLevensteinDistance)
                        {
                            const titleArtistIdified = (idifiedInfo.title? idifiedInfo.title : "") + " "+(idifiedInfo.artist? idifiedInfo.artist : "");
                            const titleArtistFromSongMeta = (currentLoadedSong.get("titleFromFileID3Tags")? currentLoadedSong.get("titleFromFileID3Tags"):  "") + " "+ (currentLoadedSong.get("artistFromFileID3Tags")? currentLoadedSong.get("artistFromFileID3Tags") : "");
                            const l= new Levenshtein(titleArtistFromSongMeta? titleArtistFromSongMeta.toUpperCase(): titleArtistFromSongMeta, titleArtistIdified? titleArtistIdified.toUpperCase(): titleArtistIdified);
                            const distance = l.distance;
                            const biggerLength = Math.max(titleArtistIdified.length, titleArtistFromSongMeta.length);
                            const pct = (biggerLength - distance) / biggerLength
                            shouldBeUpdated = pct >0.5? true : false;
                        }
                        if(shouldBeUpdated)
                        {
                            const songJsonUpdated = currentLoadedSong.toJSON();
                            songJsonUpdated.idified = true
                            songJsonUpdated.idifiedInfo =  null;
                            songJsonUpdated.idified = true;
                            songJsonUpdated.idifiedString = "true"
                            songJsonUpdated.idifiedInfo =  idifiedInfo;
                            songJsonUpdated.idifiedSuccess = true;
                            songJsonUpdated.idifiedSuccessString = "true";
                            songJsonUpdated.idifiedDate = new Date();
                            const context = PlayerService.getInstance().getPlayerQueueContext();
                            return TrebbleIndexDBHelper.getInstance().saveSong(songJsonUpdated, context);
                        }else{
                            return RSVP.Promise.resolve();
                        }
                    }else{
                        //Identification Failed
                        const songJsonUpdated = currentLoadedSong.toJSON();
                        songJsonUpdated.idified = "true"
                        songJsonUpdated.idifiedInfo =  null;
                        songJsonUpdated.idified = true;
                        songJsonUpdated.idifiedString = "true"
                        songJsonUpdated.idifiedInfo =  this.model;
                        songJsonUpdated.idified =  false;
                        songJsonUpdated.idifiedSuccess = false;
                        songJsonUpdated.idifiedSuccessString = "false";
                        songJsonUpdated.idifiedDate = new Date();
                        const context = PlayerService.getInstance().getPlayerQueueContext();
                        return TrebbleIndexDBHelper.getInstance().saveSong(songJsonUpdated, context);
                    }
                }else{
                    return RSVP.Promise.resolve();
                }
            }).bind(this)).then(function(){
                window.log("song updated in library as favorite");
            }).catch(function(error){
                window.alertErrorMessage(error);
            });
        
        },

    

    });

    var songIdentificationServiceInstance = new SongIdentificationService();

    export default {
		getInstance : function() {
			return songIdentificationServiceInstance;
		}
	};