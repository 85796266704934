import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import SelectionListItemTemplate from 'text!../../../templates/selectionList/SelectionListItemTemplate.html';
import RSVP from 'rsvp';

const SelectionListItemView = Backbone.View.extend({



    initialize : function(options) {
        this.compiledTemlate =  _.template(SelectionListItemTemplate);
        this.model = options? options.model : null;
        this._propertyFunction = options? options.propertyFunction : null;
        this._propertyName = options? options.propertyName : null;
        this._isSelected  = false;
    },
    
    events : {
        "click .itemLabel": "toggleSelection",
    },
    
    toggleSelection : function(){
        const isSelected = this.selectionButton$el.hasClass("enabled");
        this.setSelection(!isSelected, true);
        
    },
    
    setSelection : function(selected, triggerSelectedEvent){
        this.selectionButton$el.removeClass(selected?"disabled": "enabled");
        this.selectionButton$el.addClass(selected?"enabled": "disabled");
        if(selected){
            this.$el.addClass("selected");
        }else{
            this.$el.removeClass("selected");
        }
        if(triggerSelectedEvent){
            this.trigger("selected",selected);
        }
    },
    
    
    render : function() {
        const templateParams = {};
        templateParams.model  = this.model;
        const options = {};
        options.propertyFunction = this._propertyFunction;
        options.propertyName = this._propertyName;
        templateParams.options = options;
        this.$el.html(this.compiledTemlate(templateParams));
        this.setElement(this.$el.find("div").first());
        this.selectionButton$el =  this.$el.find("#selectionButton");
        return this;
    }

});
export default SelectionListItemView;