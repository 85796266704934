import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import BasicSequencerNodeInspectorTemplate from 'text!../../../../templates/audioEditor/sequencerNodeInspectorSection/BasicSequencerNodeInspectorTemplate.html';
import ti18n from 'i18n!nls/Sequenceri18n';
import RSVP from 'rsvp';


const BasicSequencerNodeInspectorView = Backbone.View.extend({


    constructor : function(attributes, options) {
        Backbone.View.apply(this, [attributes, options]);
        this._sequencerNode =  attributes.sequencerNode;
        this.compiledTemlate =  _.template(BasicSequencerNodeInspectorTemplate);
        this._paramRenderedViewArray = [];
    },


    getSequencerNode : function(){
        return this._sequencerNode;
    },

    addRenderedParamView : function(renderedParamView){
        this._paramRenderedViewArray.push(renderedParamView);
        if(renderedParamView && renderedParamView.$el && this.paramsList$el){
            this.paramsList$el.append(renderedParamView.$el);
        }
    },

    getParamRenderedViewArray :function(){
        return this._paramRenderedViewArray;
    },

    renderAllParamViews : function(){

    },

    listenToChangeEventsOnModel: function(){

    },

    getCustomCssClass : function(){

    },

    listenToChangeEventFromView : function(viewToListenTo, eventNameToTrigger, convertValueFuction){
        this.listenTo(viewToListenTo, "valueChanged", (function(newValue){
            this.trigger(eventNameToTrigger, convertValueFuction ? convertValueFuction(newValue): newValue);
        }).bind(this));
    },

    render : function(){
        this.$el.html(this.compiledTemlate());
        this.setElement(this.$el.find(".sequencer_node_inpestor_details").first());
        this._paramRenderedViewArray = this.renderAllParamViews();
        this.listenToChangeEventsOnModel();
        if(!this._paramRenderedViewArray){
            this._paramRenderedViewArray = [];
        }
        this.paramsList$el = this.$el.find(".params_list");
        for(let i =0; i < this._paramRenderedViewArray.length; i++){
            const paramView =  this._paramRenderedViewArray[i];
            this.paramsList$el.append(paramView.$el);
        }
        if(this.getCustomCssClass()){
            this.$el.addClass(this.getCustomCssClass());
        }
    }
    

});
export default BasicSequencerNodeInspectorView;