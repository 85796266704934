import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import PlayerModel from "models/player/PlayerModel";
import PlayerFooterView from "views/playerFooter/PlayerFloatingView";
import PersistentModels from "services/PersistentModels";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import RSVP from "rsvp";

const PlayerFloatingController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if (options) {
            if (options.view) {
                this.view = options.view;
            }
            if (options.model) {
                this.model = options.model;
            }
        }
        this.canBeShownOnActivePage = (options && options.canBeShownOnActivePage) || false;
        this.transparent = (options && options.transparent) || false;

    },

    getModel : function() {
        return this.model;
    },
    
    setCanBeShownOnActivePage : function(canBeShownOnActivePage, transparent){
        this.canBeShownOnActivePage = canBeShownOnActivePage;
        this.transparent = transparent;
        if(this.getView())
        {
            this.getView().setCanBeShownOnActivePage(this.canBeShownOnActivePage, transparent);
        }
        if(canBeShownOnActivePage){
            this.view.showHideTooltipToExpandFloatingPlayer(!LocalStorageHelper.getInstance().isTooltipToExpandFloatingPlayerWasDismissed());
        }
    },

    onTooltipToExpandFloatingPlayerDismissed : function(){
        LocalStorageHelper.getInstance().setTooltipToExpandFloatingPlayerWasDismissed();
    },

    
    isPlayerFloatingViewDisplayed : function(){
        if(this.getView())
        {
            return this.getView().isPlayerFloatingViewDisplayed();
        }
        return false;
    },

    isPlayerFloatingViewTransparent : function(){
        return this.transparent;
    },

    getView : function() {
        return this.view;
    },

    showPlayerPage :function(){
        PersistentModels.getInstance().getRouter().showPlayerPage(true, true);
        this.view.dismissTooltipToExpandFloatingPlayer();
    },
    
    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                if(!this.model)
                {
                    //this.model = new PlayerModel();
                }
                this.view = new PlayerFooterView({"model" : this.model, "canBeShownOnActivePage" : this.canBeShownOnActivePage });
                this.listenTo(this.view, "showPlayerPage", this.showPlayerPage);
                this.listenTo(this.view, "tooltipToExpandFloatingPlayerDismissed", this.onTooltipToExpandFloatingPlayerDismissed);
                this.view.render().then(resolve);
            //	resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default PlayerFloatingController;