
import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerOperation from "models/audioEditor/sequencerOperation/SequencerOperation";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";


const TurnNoiseCancellationOnOperation =  SequencerOperation.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperation.apply(this, [attributes, options]);
    },

    execute : function(turnNoiseCancellationOn){
        this.getSequencer().getSequencerSettings().setNoiseCancellationOn(turnNoiseCancellationOn);
        this.getSequencer().getHistoryManagement().addTurnNoiseCancellationOnOperationLog(turnNoiseCancellationOn);
    },

    undo : function(sequencerOperationTurnNoiseCancellationOnOperationLog ){
        this.getSequencer().getSequencerSettings().setNoiseCancellationOn(!sequencerOperationTurnNoiseCancellationOnOperationLog.getNoiseCancellationOn());
    },

    redo: function(sequencerOperationTurnNoiseCancellationOnOperationLog){
        this.getSequencer().getSequencerSettings().setNoiseCancellationOn(sequencerOperationTurnNoiseCancellationOnOperationLog.getNoiseCancellationOn());
    },

});


export default TurnNoiseCancellationOnOperation; 