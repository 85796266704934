import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import DynamicPageView from "views/common/DynamicPageView";
import Utils from "models/helper/Utils";
import ti18n from "i18n!nls/RedeemCodei18n";
import PersistentModels from "services/PersistentModels";
import ChangePasswordPageTemplate from 'text!../../../templates/user/RedeemCodePageTemplate.html';
import Mousetrap from 'mousetrap';

const RedeemCodePageView = DynamicPageView.extend({

    //template : _.template(ExplorePageTemplate),

initialize : function(options) {
    const dynamicPageOptions = {};
    this.model =  options? options.model: {};
    this._defaultCodeToRedeem = options? options.redeemCode: null;
    this._pageId = (options && options.pageId)? options.pageId:"redeem";
    dynamicPageOptions.pageViewTemplate = ChangePasswordPageTemplate;
    dynamicPageOptions.model = this.model;
    dynamicPageOptions.pageId = this._pageId;
    dynamicPageOptions.headerFullscren = true;
    dynamicPageOptions.persistent = true;
    dynamicPageOptions.onPageShow = this.onPageShow.bind(this);
    dynamicPageOptions.pageTemplateOptions = {"ti18n": ti18n};
    //PersistentModels.getInstance().getRouter().registerForViewportHeightChange(this._pageId,this._onViewportHeightChanged.bind(this));
    return DynamicPageView.prototype.initialize.call(this, dynamicPageOptions);  
},

events : {
    "click #header-back-button" : "navigateToPreviousPage",
    "click #redeemButton:not(.disabled)" : "saveChanges",
},

saveChanges : function(){
    this.trigger("redeem", this.getCode());
    this.setRedeemedButtonEnabled(false);
},


getCode: function(){
    return this.codeField$el.get(0).value;
},

onFieldValueChanged : function(event){
    if(this.isAllMandatoryFieldsFilled()){
        this.setRedeemedButtonEnabled(true);
    }else{
        this.setRedeemedButtonEnabled(false);
    }
},

isAllMandatoryFieldsFilled : function(){
    const currentPasswordValid = this.validateCurrentPasswordField();
    return currentPasswordValid;
    
},


validateCurrentPasswordField : function(){
    const code = this.getCode();
    if (!code) {
        //const errorMessage = window.getI18n(ti18n, "CURRENT_PASSWORD_CANNOT_BE_EMPTY");
        //this.codeFieldDec$el.find("label").attr("data-error",errorMessage);
        if(!this.codeField$el.hasClass("invalid"))
        {
            this.codeField$el.addClass("invalid");
        }
    } else {
        // empty string means no validation error
        this.codeField$el.removeClass("invalid");
        this.codeFieldDec$el.find("label").attr("data-error","");
    }
    return code;
},


navigateToPreviousPage : function(){
    PersistentModels.getInstance().getRouter().navigateToPreviousPage();
},

onBeforePageInitialized : function(){

},

setRedeemedButtonEnabled : function(enabled){
    if(enabled){
        this.redeemButton$el.removeClass("disabled");
    }else{
        if(!this.redeemButton$el.hasClass("disabled")){
            this.redeemButton$el.addClass("disabled");
        }
    }
},


onAfterPageInitialized : function(){

    this.redeemButton$el  = this.$el.find("#redeemButton")
    this.codeField$el = this.$el.find("#codeField");
    this.codeField$el.get(0).value = this._defaultCodeToRedeem;
    this.codeFieldDec$el = this.$el.find("#codeFieldFieldDecId");
    this.$el.find("#redeemFormWrapper").on("click blur keyup input", this.onFieldValueChanged.bind(this));	
    this.setRedeemedButtonEnabled(false);
    this.onFieldValueChanged();
    this.$el.on("pagehide", this._onPageHide.bind(this));
    this._mousetrapObj = Mousetrap(this.$el.find("#redeemFormWrapper").get(0)).bind('enter', (function(e, combo) {
        this.saveChanges();
    }).bind(this));


},

onPageShow : function(){
    this.trigger("pageShow");
},
onBeforeRemoveFromPage : function(){
    DynamicPageView.prototype.onBeforeRemoveFromPage.call(this); 
    PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._pageId);
},

    });
export default RedeemCodePageView;