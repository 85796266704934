import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import AudioFilter from "models/audioEditor/filters/AudioFilter";
import RSVP from "rsvp";

const CLASSNAME = "FadeStartFilter";
const FadeStartFilter =  AudioFilter.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        AudioFilter.apply(this, [attributes, options]);
        this.set("type", FadeStartFilter.CLASSNAME);
    },

    setFadeFrom : function(fadeFrom){
        this.set("fadeFrom",fadeFrom);
    },

    setEqualPowerFade : function(equalPowerFade){
        this.set("equalPowerFade",equalPowerFade);
    },

    isEqualPowerFade : function(){
        return this.get("equalPowerFade")
    },


    getFadeFrom : function(){
        return this.get("fadeFrom");
    },

    setFadeTo : function(fadeTo){
        this.set("fadeTo",fadeTo);
    },


    getFadeTo : function(){
        return this.get("fadeTo");
    },

    setFadeDuration : function(fadeDuration){
        this.set("fadeDuration",fadeDuration);
    },


    getFadeDuration : function(){
        return this.get("fadeDuration");
    },

    getEqualPowerCurveIn : function(startVolume, endVolume){
        const curve = new Float32Array(64);
        const range = endVolume - startVolume;
        curve[0] = startVolume;
        for (let i = 1; i < curve.length; i++)
            curve[i] = startVolume + range * Math.cos((1 - i / (curve.length-1)) * 0.5*Math.PI);
        return curve;
    },

    isEqual : function(filter){
        if(filter instanceof FadeStartFilter){
            return (this.isDisabled() == filter.isDisabled() && this.isEqualPowerFade() === filter.isEqualPowerFade() && this.getFadeFrom() === filter.getFadeFrom() && this.getFadeTo() === filter.getFadeTo() && this.getFadeDuration() === this.getFadeDuration()); 
        }
        return false;
    },

    
    clone : function(){
        const cloneFilter = new FadeStartFilter();
        cloneFilter.setDisabled(this.isDisabled());
        cloneFilter.setEqualPowerFade(this.isEqualPowerFade());
        cloneFilter.setFadeFrom(this.getFadeFrom());
        cloneFilter.setFadeTo(this.getFadeTo());
        cloneFilter.setFadeDuration(this.getFadeDuration());
        return cloneFilter;
    },

    needToPrepareAudioContext: function(){
        return false;
    },

    prepareAudioContext: function(audioContext){
        return RSVP.Promise.resolve(audioContext);
    },


    applyFilterToNode : function(audioNode, audioContext, arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime,sequencerRendererTracksInfo ){
        if(!this.isDisabled()){
            let fromValue = this.getFadeFrom();
            let toValue = this.getFadeTo();
            const fadeDurationInMilliseconds = this.getFadeDuration();
            if(fadeDurationInMilliseconds > 0){
                if(!fromValue){
                    fromValue = 0.01; //This is because the gain will throw an error if the value is 0
                }
                if(!toValue){
                    toValue = 0.01; //This is because the gain will throw an error if the value is 0
                }
                
                

                const gainNode = audioContext.createGain();
                let startTime = audioBufferStartTime ? audioBufferStartTime: audioContext.currentTime;
                const endTime = startTime + (fadeDurationInMilliseconds / 1000);
                if(endTime < audioContext.currentTime){
                    return audioNode;
                }
                if(startTime < audioContext.currentTime){
                    /*Adjust the fromvalue to match request as close as possible*/
                    fromValue = fromValue + ((audioContext.currentTime - startTime) * (toValue - fromValue)/(endTime - startTime));
                    startTime = audioContext.currentTime;
                }
                if(this.isEqualPowerFade()){
                    gainNode.gain.setValueCurveAtTime(this.getEqualPowerCurveIn(fromValue, toValue), startTime, (fadeDurationInMilliseconds / 1000));
                }else{
                    gainNode.gain.setValueAtTime(fromValue, startTime);
                    gainNode.gain.exponentialRampToValueAtTime(toValue, endTime);	
                }


                audioNode.connect(gainNode);
                if(arrayOfAudioNodesUsed){
                    arrayOfAudioNodesUsed.push(gainNode);
                }

                return gainNode;
            }else{
                return audioNode;
            }
        }else{
            return audioNode;
        }
    },




    applyFilter : function(previousOutputNode, audioContext, arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime, sequencerRendererTracksInfo, audioBuffer){
        if(!this.isDisabled()){
            if(!previousOutputNode){
                previousOutputNode = audioContext.createBufferSource();
                previousOutputNode.buffer = audioBuffer;
                if(arrayOfAudioNodesUsed){
                    arrayOfAudioNodesUsed.push(previousOutputNode);
                }
            }
            return RSVP.Promise.resolve(this.applyFilterToNode(previousOutputNode, audioContext,arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime? audioBufferEndTime: (audioContext.currentTime + this.getMaxAudioBufferDuration(audioBuffer)) ,sequencerRendererTracksInfo));
        }else{
            return RSVP.Promise.resolve(previousOutputNode);
        }
    },


});

FadeStartFilter.CLASSNAME = CLASSNAME;
export default FadeStartFilter; 