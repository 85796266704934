
import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerOperation from "models/audioEditor/sequencerOperation/SequencerOperation";
import ReplacementSequencerNode from "models/audioEditor/sequencerNodes/ReplacementSequencerNode";
import StartWrapSequencerNode from "models/audioEditor/sequencerNodes/StartWrapSequencerNode";
import EndWrapSequencerNode from "models/audioEditor/sequencerNodes/EndWrapSequencerNode";
import RSVP from "rsvp";

const WrapSelectedNodesOperation =  SequencerOperation.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperation.apply(this, [attributes, options]);
    },

    execute : function(params){
        throw new Error("Need to be implemented in subclass!");
    },

    undo: function(operationLog){
        throw new Error("Need to be implemented in subclass!");
    },

    redo: function(operationLog){
        throw new Error("Need to be implemented in subclass!");
    },

    wrapSequencerNodes : function(startOrEndWrapSequencerNode, startSequencerNodeToWrap, endSequencerNodeToWrap){
        let startWrapSequencerNode = startOrEndWrapSequencerNode.getStartWrapSequencerNode();
        let endWrapSequencerNode = startWrapSequencerNode.getEndWrapSequencerNode();
        startWrapSequencerNode = startOrEndWrapSequencerNode.getSelectableStartNodeWrappedIfApplicable(startWrapSequencerNode);
        endWrapSequencerNode = startOrEndWrapSequencerNode.getSelectableEndNodeWrappedIfApplicable(endWrapSequencerNode);
        if(startWrapSequencerNode !== startSequencerNodeToWrap && startWrapSequencerNode !== endSequencerNodeToWrap && endWrapSequencerNode !== startSequencerNodeToWrap && endWrapSequencerNode !== endSequencerNodeToWrap){
            startWrapSequencerNode.wrapTo(startSequencerNodeToWrap, endSequencerNodeToWrap);
            return {"startWrapSequencerNode":startWrapSequencerNode, "endWrapSequencerNode":endWrapSequencerNode};
        }
    },


    revertWrapNodeCreation: function(startOrEndWrapSequencerNode){
        if(startOrEndWrapSequencerNode instanceof StartWrapSequencerNode || startOrEndWrapSequencerNode instanceof EndWrapSequencerNode ){
            let startWrapSequencerNode = null;
            let endWrapSequencerNode = null;
            if(startOrEndWrapSequencerNode instanceof StartWrapSequencerNode){
                startWrapSequencerNode = startOrEndWrapSequencerNode;
                endWrapSequencerNode = startWrapSequencerNode.getEndWrapSequencerNode();
            }

            if(startOrEndWrapSequencerNode instanceof EndWrapSequencerNode){
                endWrapSequencerNode = startOrEndWrapSequencerNode;
                startWrapSequencerNode = endWrapSequencerNode.getStartWrapSequencerNode();
            }

            const startWrappedNode = startOrEndWrapSequencerNode.getStartWrappedNode();
            const endWrappedNode = startOrEndWrapSequencerNode.getEndWrappedNode();
            startOrEndWrapSequencerNode.setAllNodesHoverHighlighted(false);
            startOrEndWrapSequencerNode.unwrap();
            //startWrapSequencerNode.removeFromSequencer();
            //endWrapSequencerNode.removeFromSequencer();
            return null;
        }
    },


});

export default WrapSelectedNodesOperation; 