

import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerOperation from "models/audioEditor/sequencerOperation/SequencerOperation";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import SequencerNodeFactory from "models/audioEditor/sequencerNodes/SequencerNodeFactory";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const LoadProjectOperation =  SequencerOperation.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperation.apply(this, [attributes, options]);
    },

    execute : function(projectId, sequencerNodeToInsertNextTo, insertBefore, clearExistingProject, duplicateProject, silent, progressReportFunction){
        return TrebbleClientAPIHelper.getInstance().getSerializedDataFileLocation(projectId).then((function(serializedDataLocation){
            if(serializedDataLocation){
                return Utils.getInstance().fetchJson(serializedDataLocation);
            }
        }).bind(this)).catch((function(error){
            return TrebbleClientAPIHelper.getInstance().getSerializedDataFileLocation(projectId, true).then((function(serializedDataLocation){
                if(serializedDataLocation){
                    return Utils.getInstance().fetchJson(serializedDataLocation);
                }
            }).bind(this));
        }).bind(this)).then((function(serializedDataToRestore){
            return this.loadSequencerData(projectId, serializedDataToRestore, sequencerNodeToInsertNextTo, insertBefore, clearExistingProject, duplicateProject, silent, progressReportFunction);
        }).bind(this)).catch(window.alertErrorMessage);
    },


    loadSequencerData: function(projectId, serializedDataToRestore, sequencerNodeToInsertNextTo, insertBefore, clearExistingProject, duplicateProject, silent, progressReportFunction){
        if(serializedDataToRestore && projectId){
            
            const clearExistingProjectPromise = !clearExistingProject? RSVP.Promise.resolve(): this.getSequencer().clearSequencer().then((function(){
                if(!duplicateProject){
                    this.getSequencer().setProjectId(projectId);
                }
                return this.getSequencer()._sequencerSettings.restoreFromSerializedData(serializedDataToRestore.sequencerSettings);
            }).bind(this))
            
            return clearExistingProjectPromise.then((function(){
                if(serializedDataToRestore.additionalSpeakerInfoArray && serializedDataToRestore.additionalSpeakerInfoArray.length > 0){
                    serializedDataToRestore.additionalSpeakerInfoArray.map((serializedData)=>{
                        this.getSequencer().createAdditionalSpeakerInfoFromSerializedData(serializedData)
                    })
                }
                if(serializedDataToRestore.transcribedAudioFiles){
                    const promisesArray = serializedDataToRestore.transcribedAudioFiles.map((async function(transcribedAudioFileInfo){
                        const speakerInfoSerializedDataArray = serializedDataToRestore.transcriptionJSONUrlToSpeakerInfoData && serializedDataToRestore.transcriptionJSONUrlToSpeakerInfoData[transcribedAudioFileInfo.transcriptionFileLocation];
                        let videoWidth =  transcribedAudioFileInfo.videoWidth;
                        let videoHeight =  transcribedAudioFileInfo.videoHeight;
                        if(transcribedAudioFileInfo.isVideo && !videoWidth && !videoHeight){
                            const videoMetadata =  await Utils.getInstance().getVideoMetadata(transcribedAudioFileInfo.audioFileUrl);
                            if(videoMetadata){
                                videoWidth = videoMetadata.width;
                                videoHeight = videoMetadata.height;
                            }
                        }
                        return this.getSequencer().addTranscribeAudio(transcribedAudioFileInfo.audioFileUrl, transcribedAudioFileInfo.transcriptionFileLocation, transcribedAudioFileInfo.transcriptionId, true, transcribedAudioFileInfo.isVideo, progressReportFunction, speakerInfoSerializedDataArray, this.getSequencer().getAdditionalSpeakerInforArray(), transcribedAudioFileInfo.languageCode, videoWidth, videoHeight);
                    }).bind(this));
                    return RSVP.Promise.all(promisesArray);
                }	
            }).bind(this)).then((function(){
                return SequencerNodeFactory.getInstance().createSequencerNodesFromNodeCidToSerializedDataMap(serializedDataToRestore.cloneCidToSequencerNodeSerializedData, this.getSequencer());
            }).bind(this)).then((function(nodeCidToSequencerNode){
                const firstNodeCid = serializedDataToRestore.firstNodeCid;
                const arrayOfSequencerNodes = [];
                let sequencerNode = nodeCidToSequencerNode[firstNodeCid];
                while(sequencerNode){
                    arrayOfSequencerNodes.push(sequencerNode);
                    sequencerNode =  sequencerNode.getNext();
                }
                arrayOfSequencerNodes.forEach(function(sequencerNodeToDisconnect){
                    sequencerNodeToDisconnect.clearPrevious();
                    sequencerNodeToDisconnect.clearNext();
                })
                this.insertAudioNodeArray(arrayOfSequencerNodes, sequencerNodeToInsertNextTo, insertBefore);
                if(!silent){
                    return this.getSequencer().getHistoryManagement().addSequencerOperationLoadProjectLog(projectId, arrayOfSequencerNodes, sequencerNodeToInsertNextTo, insertBefore);
                }
            }).bind(this))
            
        }
        return RSVP.Promise.resolve();
    },



    undo: function(sequencerOperationLoadProjectLog){
        const arrayOfSequencerNodes = sequencerOperationLoadProjectLog.getArrayOfSequencerNodes();
        this.removeAudioNodeArray(arrayOfSequencerNodes);
    },

    redo: function(sequencerOperationLoadProjectLog){
        this.insertAudioNodeArray(sequencerOperationLoadProjectLog.getArrayOfSequencerNodes(), sequencerOperationLoadProjectLog.getSequencerNodeToInsertNextTo(), sequencerOperationLoadProjectLog.getInsertBefore());
    },





});

export default LoadProjectOperation; 