import React from "react";
import { Empty } from "antd";
import ConfigProvider from "./ConfigProvider";
import useDefaultTrebbleAntDTokenConfig from "../helper/useDefaultTrebbleAntDTokenConfig";
import PropTypes from "prop-types";

const TrebbleEmpty = ({useEditorTheme, ...restProps}) => {
  const themeConfig = useDefaultTrebbleAntDTokenConfig({useEditorTheme});

  return (
    <ConfigProvider theme={themeConfig}>
      <Empty {...restProps} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </ConfigProvider>
  );
};

TrebbleEmpty.propTypes = {
  useEditorTheme: PropTypes.bool
}
export default TrebbleEmpty;
