import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import OptionPopupView from "views/common/OptionPopupView";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import popupTemplate from 'text!../../../templates/capsule/CapsuleOptionContent.html';
import ti18n from "i18n!nls/CapsuleCardi18n";

const CapsuleOptionView = OptionPopupView.extend({




    initialize : function(options) {
        const viewOptions =  {};
        this.model = options.model;
        this.elementSource$el = options.elementSource$el;
        const contentView = {};
        const contentViewTemplate = _.template(popupTemplate);
        const templateParams = {"model" : this.model, "ti18n": ti18n, "canCreateJourney": LocalStorageHelper.getInstance().isLoggedInUserAJourneyCreator() || LocalStorageHelper.getInstance().isLoggedInUserACreator()};
        contentView.$el  = $(contentViewTemplate(templateParams));
        viewOptions.contentView = contentView;
        viewOptions.contentPosition = "center";
        viewOptions.elementSource$el = this.elementSource$el;
        viewOptions.showAsMenuIfInBrowser = true;
        viewOptions.id = "CapsuleOptionPopup";
        viewOptions.hideHeading = true;
        OptionPopupView.prototype.initialize.call(this, viewOptions);
    },

    afterRendering : function(){
        this.$el.find("#editButton").click(this._onEditDetailsButtonClicked.bind(this));
        this.$el.find("#editAudio").click(this._onEditAudioButtonClicked.bind(this));
        this.$el.find("#duplicatedAndEditAudio").click(this._onDuplicateAndEditAudioButtonClicked.bind(this));
        this.$el.find("#analyticsButton").click(this._onAnalyticsButtonClicked.bind(this));
        this.$el.find("#deleteButton").click(this._onDeleteButtonClicked.bind(this));
        this.$el.find("#commentButton").click(this._onShowCommentButtonClicked.bind(this));
        this.$el.find("#shareButton").click(this._onShareButtonClicked.bind(this));
        this.$el.find("#addToChannel").click(this._onAddToChannelClicked.bind(this));
        this.$el.find("#removeFromChannel").click(this._onRemoveFromChannelClicked.bind(this));
        this.$el.find("#downloadAudioFileButton").click(this._ondDownloadAudioFileButtonClicked.bind(this));
        this.$el.find("#addToDraftJourney").click(this._onAddToDraftJourneyButtonClicked.bind(this));
        this.$el.find("#downloadTranscriptFileButton").click(this._onDownloadTranscriptButtonClicked.bind(this))
        this.$el.find("#downloadVideoFileButton").click(this._onDownloadVideoButtonClicked.bind(this))
        this.$el.find("#videoCurrentlyBeingRenderedBtn").click(this._onVideoCurrentlyBeingRenderedButtonClicked.bind(this))
        
    },

    _onAddToChannelClicked :function(){
        this.trigger("addToChannel");
        this.close();
    },

    _onRemoveFromChannelClicked :function(){
        this.trigger("removeFromChannel");
        this.close();
    },


    _onShareButtonClicked :function(){
        this.trigger("share");
        this.close();
    },

    _onShowCommentButtonClicked :function(){
        this.trigger("showComments");
        this.close();
    },

    _onAnalyticsButtonClicked :function(){
        this.trigger("showAnalytics");
        this.close();
    },

    _onDeleteButtonClicked :function(){
        this.trigger("deleteCapsule");
        this.close();
    },

    _onEditDetailsButtonClicked : function(){
        if(this.model.canAudioBeEdited()){
            this.trigger("editAudio");
        }else{
            this.trigger("editDetails");
        }
        this.close();
    },

    _onEditAudioButtonClicked : function(){
        this.trigger("editAudio");
        this.close();
    },

    _onDuplicateAndEditAudioButtonClicked : function(){
        this.trigger("duplicatedAndEditAudio");
        this.close();
    },

    _ondDownloadAudioFileButtonClicked : function(){
        this.trigger("downloadAudioFile", this.$el.find("#downloadAudioFileButton"));
    },

    _onDownloadTranscriptButtonClicked : function(){
        this.trigger("downloadTranscriptionFile", this.$el.find("#downloadTranscriptFileButton"));
    },

    _onDownloadVideoButtonClicked : function(){
        this.trigger("downloadVideoFile");
        this.close();
    },

    _onVideoCurrentlyBeingRenderedButtonClicked : function(){
        this.trigger("videoCurrentlyBeingRendered");
        this.close();
    },

    _onAddToDraftJourneyButtonClicked : function(){
        this.trigger("selectAndAddToDraftJourney");
        this.close();
    },
    


    onAfterPageShow : function(){
        this.$el.addClass("CapsuleOptionPopup");
    },



});

export default CapsuleOptionView;