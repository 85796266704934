import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import WrapSequencerNode from "models/audioEditor/sequencerNodes/WrapSequencerNode";
import EndWrapSequencerNode from "models/audioEditor/sequencerNodes/EndWrapSequencerNode";
import RSVP from "rsvp";

const CLASSNAME = "StartWrapSequencerNode";
//This class is missing method that should be implented
const StartWrapSequencerNode =  WrapSequencerNode.extend({
    idAttribute: "id",


    constructor: function(attributes, options, createdFromClone, objBeingCloned, sequencerNodeCidToCloneNodeMap) {
        WrapSequencerNode.apply(this, [attributes, options]);
        attributes.startWrapSequencerNode = this;
        this._endWrapSequencerNode = this.createEndWrapSequencerNode(attributes, options, createdFromClone, objBeingCloned, sequencerNodeCidToCloneNodeMap);
        this.on("nodeInsertedAfter", this._onNodeInsertedAfter.bind(this));
    },

    getConstructor : function(){
        return StartWrapSequencerNode;
    },

    _onNodeInsertedAfter: function(sequencerNodeInserted){
        if(sequencerNodeInserted){
            sequencerNodeInserted.addStartWrapNode(this);
            sequencerNodeInserted.addEndWrapNode(this.getEndWrapSequencerNode());
        }
    },
    
    afterInstanceCreatedFromConstructorForCloning : function(createdClonedInstance, sequencerNodeCidToCloneNodeMap){
        const endNodeCid = this.getEndWrapSequencerNode()? this.getEndWrapSequencerNode().cid: null;
        const cloneEndWrapSequencerNode = endNodeCid ? sequencerNodeCidToCloneNodeMap[endNodeCid]: null;
        if(cloneEndWrapSequencerNode){
            createdClonedInstance._endWrapSequencerNode = cloneEndWrapSequencerNode;
            cloneEndWrapSequencerNode._startWrapSequencerNode = createdClonedInstance;
        }
        if(this.getEndWrapSequencerNode()){
            this.getEndWrapSequencerNode().afterInstanceCreatedFromConstructorForCloning(createdClonedInstance.getEndWrapSequencerNode(), sequencerNodeCidToCloneNodeMap);
        }

        let wrappedNode = this.getStartWrappedNode();
        while(wrappedNode){
            const wrappedNodeClone = sequencerNodeCidToCloneNodeMap[wrappedNode.cid];
            if(wrappedNodeClone){
                wrappedNodeClone.addStartWrapNode(createdClonedInstance);
                wrappedNodeClone.addEndWrapNode(createdClonedInstance.getEndWrapSequencerNode());
            }
            if(wrappedNode === this.getEndWrappedNode()){
                wrappedNode = null;
            }else{
                wrappedNode = wrappedNode.getNext();
            }
        }
    },

    cloneJSON: function(){
        const objJson = WrapSequencerNode.prototype.cloneJSON.call(this);
        objJson.type = StartWrapSequencerNode.CLASSNAME;
        return objJson;
    },

    getSerializedData: function(){
        const serializedData = WrapSequencerNode.prototype.getSerializedData.call(this);
        serializedData._startWrapSequencerNodeCid = this.getStartWrapSequencerNode()? this.getStartWrapSequencerNode().cid: null;
        serializedData._endWrapSequencerNodeCid = this.getEndWrapSequencerNode()? this.getEndWrapSequencerNode().cid: null;
        return serializedData;
    },

    restoreFromSerializedData: function(serializedData, audioSegmentFactoryInstance, audioFilterFactoryInstance, additionalSpeakerInfoArray){
        return WrapSequencerNode.prototype.restoreFromSerializedData.call(this, serializedData, audioSegmentFactoryInstance, audioFilterFactoryInstance, additionalSpeakerInfoArray);
    },

    afterAllInstancesCreatedFromSerializedData : function(serializedData, sequencerNodeCidToSequencerNodeMap){
        WrapSequencerNode.prototype.afterAllInstancesCreatedFromSerializedData.call(this, serializedData, sequencerNodeCidToSequencerNodeMap);
        this._endWrapSequencerNode = sequencerNodeCidToSequencerNodeMap[serializedData._endWrapSequencerNodeCid];
    },



    wrapTo :function(startNodeWrapped, endNodeWrapped , checkIfWrapIsValidBeforeWrapping){
        startNodeWrapped =  this.getSelectableStartNodeWrappedIfApplicable(startNodeWrapped);
        endNodeWrapped =  this.getSelectableEndNodeWrappedIfApplicable(endNodeWrapped);
        let wrapingIsValid = false;
        if(checkIfWrapIsValidBeforeWrapping){
            wrapingIsValid = this.canWrapTo(startNodeWrapped, endNodeWrapped);
        }else{
            wrapingIsValid = true;
        }
        if(wrapingIsValid){
            if(this.getStartWrappedNode() && this.getEndWrappedNode()){
                this.unwrap();
            }
            this._setupAsStartWrapNodeTo(startNodeWrapped, endNodeWrapped );
            this._endWrapSequencerNode._setupAsEndWrapNodeTo(startNodeWrapped, endNodeWrapped);
            if(this.getStartWrappedNode() == this.getSequencer().getFirstNode()){
                //this could be probably replace by the sequencer listening to node added to the sequencer and checking when a previous node is added/changed
                this.getSequencer().setFirstNode(this);
            }
            if(this.getEndWrappedNode() == this.getSequencer().getLastNode()){
                //this could be probably replace by the sequencer listening to node added to the sequencer and checking when a next node is added/changed
                this.getSequencer().setLastNode(this._endWrapSequencerNode);
            }
        }

    },

    _setupAsStartWrapNodeTo : function(startNodeWrapped, endNodeWrapped ){
        //this.setStartWrappedNode(startNodeWrapped);
        //this.setEndWrappedNode(endNodeWrapped);
        this.insertBefore(startNodeWrapped);
        this._addToWrapNodeAsStartWrapNode(startNodeWrapped, endNodeWrapped);
    },

    _tearDownAsStartWrapNodeTo :function(startWrappedNode, endWrappedNode){
        const previousNodeToStartWrap =  this.getPrevious();
        this.clearPrevious();
        startWrappedNode.clearPrevious();
        if(previousNodeToStartWrap){
            previousNodeToStartWrap.setNext(startWrappedNode);
        }
        this._removeFromWrapNodeAsStartWrapNode(startWrappedNode, endWrappedNode);
        //this.setStartWrappedNode(null);
        //this.setEndWrappedNode(null);
        this.removeFromSequencer();
    },


    createEndWrapSequencerNode : function(attributes, options, createdFromClone, objBeingCloned, sequencerNodeCidToCloneNodeMap){
        if(createdFromClone && objBeingCloned && sequencerNodeCidToCloneNodeMap &&  objBeingCloned.getEndWrapSequencerNode() && objBeingCloned.getEndWrapSequencerNode().cid && sequencerNodeCidToCloneNodeMap[objBeingCloned.getEndWrapSequencerNode().cid]){
            return sequencerNodeCidToCloneNodeMap[objBeingCloned.getEndWrapSequencerNode().cid];
        }
        return new EndWrapSequencerNode(attributes, options);
    },


    _addToWrapNodeAsStartWrapNode : function(startNodeWrapped, endNodeWrapped){
        let nodeToAddAsWrapNode = startNodeWrapped;
        while(nodeToAddAsWrapNode){
            nodeToAddAsWrapNode.addStartWrapNode(this);
            if(nodeToAddAsWrapNode == endNodeWrapped){
                nodeToAddAsWrapNode = null;
            }else{
                nodeToAddAsWrapNode = nodeToAddAsWrapNode.getNext();
            }
        }
    },

    _removeFromWrapNodeAsStartWrapNode : function(startWrappedNode, endWrappedNode){
        let nodeToRemoveFromAsWrapNode = startWrappedNode;
        while(nodeToRemoveFromAsWrapNode){
            nodeToRemoveFromAsWrapNode.removeStartWrapNode(this);
            if(nodeToRemoveFromAsWrapNode == endWrappedNode){
                nodeToRemoveFromAsWrapNode = null;
            }else{
                nodeToRemoveFromAsWrapNode = nodeToRemoveFromAsWrapNode.getNext();
            }
        }
    },

    getStartWrapSequencerNode : function(){
        return this;
    },

    getEndWrapSequencerNode : function(){
        return this._endWrapSequencerNode;
    },



    shrinkWrapTo : function(newStartNodeToWrapTo){
        const currentEndNode = this.getEndWrappedNode();
        const newEndNodeToWrapTo = currentEndNode;
        this.wrapTo(newStartNodeToWrapTo, newEndNodeToWrapTo);
    },

    changeStartNodeToWrapTo : function(newStartNodeToWrapTo){
        const currentEndNode = this.getEndWrappedNode();
        const newEndNodeToWrapTo = currentEndNode;
        this.wrapTo(newStartNodeToWrapTo, newEndNodeToWrapTo);
    },

    unwrap : function(){
        const startWrappedNode = this.getStartWrappedNode();
        const endWrappedNode = this.getEndWrappedNode();
        this._tearDownAsStartWrapNodeTo(startWrappedNode, endWrappedNode);
        this._endWrapSequencerNode._tearDownAsEndWrapNodeTo(startWrappedNode, endWrappedNode);
        if(this == this.getSequencer().getFirstNode()){
            this.getSequencer().setFirstNode(startWrappedNode);
        }
        if(this._endWrapSequencerNode == this.getSequencer().getLastNode()){
            this.getSequencer().setLastNode(endWrappedNode);
        }
    },

    _onEndWrapNodeShrinkTo: function(newEndNodeToWrapTo){
        let nodeToRemoveFromAsWrapNode = this.getEndWrappedNode();
        while(nodeToRemoveFromAsWrapNode == newEndNodeToWrapTo){
            nodeToRemoveFromAsWrapNode.removeStartWrapNode(this);
            if(nodeToRemoveFromAsWrapNode == this.getStartWrappedNode()){
                nodeToRemoveFromAsWrapNode = null;
            }else{
                nodeToRemoveFromAsWrapNode = nodeToRemoveFromAsWrapNode.getPrevious();
            }
        }
        this.setEndWrappedNode(newEndNodeToWrapTo);
        return nodeToRemoveFromAsWrapNode == newEndNodeToWrapTo;
    },




});

StartWrapSequencerNode.CLASSNAME =  CLASSNAME;

export default StartWrapSequencerNode; 