import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Song from "models/Song";
import Utils from "models/helper/Utils";
import Playlist from "collections/Playlist";
import Utilsi18n from "i18n!nls/Utilsi18n";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";

var DynamicPlaylist =  Playlist.extend({
    model: PersistentModels.getInstance().getSongContructorFunctionForCollection(),
    

    /*constructor : function ( options ) {

        Playlist.apply( this, [null,options] );

    },*/

    initialize: function (models, options){
        this._type = Playlist.types.DYNAMIC;
        this._name = options? options.name : window.getI18n(Utilsi18n, "RADIO_PLAYLIST");
        this._playlistNameGenerator = options? options.playlistNameGenerator : null;
        this._creator =  null;
        this._context =  (options && options.context)? options.context :null;
        this._categoryId =  (options && options.categoryId)? options.categoryId :null;
        this._cityId =  (options && options.cityId)? options.cityId :null;
        this._timezone =  (options && options.timezone)? options.timezone :null;
        this._language =  (options && options.language)? options.language :null;
        this._creationDate =  (options && options.creationDate)?options.creationDate: new Date();
        this._lastModificationDate =(options &&  options.lastModificationDate)? options.lastModificationDate : this._creationDate;
        this._description = options? options.description : null;
        this._seedData = options? options.seedData : null;
        this._isLoaded = false;
        this._isLoading = false;
        this._allAvailableSongsRetrieved =  false;
        this._allAvailableSongsJsonArray = null;
        this._onRefresh = options? options.onRefresh :null;
        this._onRetrieveAllAvailableSonsInJson = options? options.onRetrieveAllAvailableSonsInJson :null;
        this._shuffle = false;
        this._originalCollectionClone = null;
        this._loaderKey =  options? options.loaderKey :null;
        this._audioPreviewUrl =  (options && options.audioPreviewUrl)? options.audioPreviewUrl :null;
        this._genreIdToAudioPreviewUrl = ( options && options.genreIdToAudioPreviewUrl)? options.genreIdToAudioPreviewUrl :null;
        this._coverArtUrl = ( options && options.coverArtUrl)? options.coverArtUrl :null;
        this._coverArtUrlBlur = ( options && options.coverArtUrlBlur)? options.coverArtUrlBlur :null;
        this._coverArtUrlInfo = ( options && options.coverArtUrlInfo)? options.coverArtUrlInfo :null;
        this._coverArtUrlBlurInfo = ( options && options.coverArtUrlBlurInfo)? options.coverArtUrlBlurInfo :null;
        this._coverArtBase64 = ( options && options.coverArtBase64)? options.coverArtBase64 :null;
        this._isFollowing = ( options && options.isFollowing)? options.isFollowing :false;
        this._hasPurchased = ( options && options.hasPurchased)? options.hasPurchased :false;
        this._canBeFollowed = ( options && options.canBeFollowed)? options.canBeFollowed :false;
        this._canBeEdited = ( options && options.canBeEdited)? options.canBeEdited :false;
        this._numberOfSongs =  options? options.numberOfSongs :null;
        this._numberOfFollowers =  options? options.numberOfFollowers :null;
        this._numberOfPurchasers =  options? options.numberOfPurchasers :null;
        this._totalNumberOfPlays =  options? options.totalNumberOfPlays :null;
        this._ownerNumberOfPlays =  options? options.ownerNumberOfPlays :null;
        this._playSuccessRate =  options? options.playSuccessRate :null;
        this._numberOfSongsFavorited =  options? options.numberOfSongsFavorited :null;
        this._numberOfCapsules =  options? options.numberOfCapsules :null;
        this._numberOfSongsComments =  options? options.numberOfSongsComments :null;
        this._customURL = options? options.customURL: null;
        this._artistSample = options? this.artistSameple : null;
        this._isPaid =  options? options.isPaid :null;
        this._costInUSD =  options? options.costInUSD :null;
        this._isInitialLoading = false;
    },
    
    getSeedData : function(){
        return this._seedData;
    },
    
    refresh : function(){
        if(this._isInitialLoading && this._loadingPromise){
            return this._loadingPromise;
        }else{
            this.trigger("startedLoading");
            this._isLoading = true;
            var loadingPromise =  this._onRefresh(this._seedData).then((function(songArray){
                this.reset(null/*, {silent:true}*/);
                this.add(songArray);
                this._isLoaded = true;
                this._isLoading = false;
                this._lastModificationDate = new Date();
                this.trigger("finishLoading");
                this._loadingPromise = null;
                return songArray;
            }).bind(this)).catch((function(error){
                if(!error){
                    error =  window.getI18n(Utilsi18n, "LOADING_FAILED");
                }
                this.trigger("failedLoading", error);
                throw error;
            }).bind(this));
            this._loadingPromise = loadingPromise;
            return this._loadingPromise;
        }
    },
    
    load : function(){
        this._isInitialLoading = true;
        return this.refresh().then((function(r){
            this._isInitialLoading = false;
            return r;
        }).bind(this)).catch(function(error){
            this._isInitialLoading = false;
            throw error;
        });
    },

    
    
    
    getLoaderKey : function(){
        return this._loaderKey;
    },
    
    _loadIfNeccessary : function(){
        if(this._isLoaded)
        {
            return RSVP.Promise.resolve(true);
        }else{
            if(this._loadingPromise){
                return this._loadingPromise;
            }else{
                return this.load();
            }
            
        }
    },
    
    getSongAtPosition : function(position){
        return this._loadIfNeccessary().then((function(){
            return RSVP.Promise.resolve(this.at(position));
        }).bind(this));
        
    },
    
    getSize : function(){
        return this._loadIfNeccessary().then((function(){
            return RSVP.Promise.resolve(this.length);
        }).bind(this));
        
    },
    
    getType : function(){
        return RSVP.Promise.resolve(this._type);	
    },
    
    getArtistSample : function(){
        return RSVP.Promise.resolve(this._artistSample);
    },
    
    getName : function(){
        if(this._playlistNameGenerator)
        {
            return this._playlistNameGenerator(this._seedData);
        }else{
            return this._name;
        }
        
    },
    
    setSeedData : function(newSeedData){
        console.error("This is not supported yet");
    },

    setName : function(newPlaylistName){
        return new RSVP.Promise((function(resolve, reject){
            try{
                this._name = newPlaylistName;
                this.trigger("playlistNameChanged",this._name);
                resolve(true);
            }catch(error){
                reject(error);
            }
            
        }).bind(this));
    },
    
    getCreator : function(){
        return RSVP.Promise.resolve(this._creator);
    },
    
    getCreationDate : function(){
        return RSVP.Promise.resolve(this._creationDate);
    },
    
    getLastModificationDate : function(){
        return RSVP.Promise.resolve(this._lastModificationDate);
    },
    
    getDescription : function(){
        return RSVP.Promise.resolve(this._description);
    },
    
    setDescription : function(newDescription){
        this._description = newDescription;
    },
    
    
    addSongs : function(songArray){
        return this._loadIfNeccessary().then((function(){
            return RSVP.Promise.resolve(this.add(songArray));
        }).bind(this));
        
    },
    
    _getSongsWithURI : function(arrayOfURIs){
        return this._loadIfNeccessary().then((function(){
            return this.filter(function(data) {
                return  (arrayOfURIs.indexOf(data.get("uri")) > -1);
                
            });
        }).bind(this));
        
    },
    
    removeSongsWithUri : function(arrayOfSongUri){
        return this._loadIfNeccessary().then((function(){
            return RSVP.Promise.resolve(this.remove(this._getSongsWithURI(arrayOfSongUri)));
        }).bind(this));
        
    },
    
    getAllLoadedSongs : function(){
        return this._loadIfNeccessary().then((function(){
            return RSVP.Promise.resolve(this.toJSON());
        }).bind(this));
        
    },

    setFollowing : function(following){
        this._isFollowing =  following;
    },
    shuffleOn : function(){
        
        if(!this._originalCollectionClone){
            this._originalCollectionClone = this.clone();
        }
        var originalCollectionClone = this._originalCollectionClone;
        this.reset(this.shuffle()/*,{silent: true}*/);
        this._shuffle = true;
        
    },
    
    shuffleOff : function(){
        if(this._originalCollectionClone){
            this.reset(this._originalCollectionClone.models/*,{silent: true}*/);
            this._shuffle = true;
        }
        
    },
    
    getContext : function(){
        return this._context;
    },
    
    updateContext : function(newContext){
        this._context = newContext
    },
    
    canRetrieveAllAvailableSongsInJson : function(){
        if(this._onRetrieveAllAvailableSonsInJson){
            return true;
        }else{
            return false;
        }
    },
    
    retrieveAllAvailableSongsInJson : function(){
        /*if(this._allAvailableSongsRetrieved){
            return RSVP.Promise.resolve(this._allAvailableSongsJsonArray);
        }else{*/
            if(this.canRetrieveAllAvailableSongsInJson()){
                this.trigger("retrievingAllSongs");
                return this._onRetrieveAllAvailableSonsInJson(this._seedData).then((function(songJsonArray){
                    this._allAvailableSongsRetrieved = true;
                    this._allAvailableSongsJsonArray = songJsonArray;
                    this.trigger("allSongsRetrieved");
                    return this._allAvailableSongsJsonArray;
                }).bind(this)).catch((function(error){
                    this.trigger("failedRetrievingAllSongs", error);
                }).bind(this));
            }else{
                return RSVP.Promise.reject("Retrieving all available songs is not available");
            }
            //}
            
        },
        
        isAudioPreviewAvailable: function(){
            return false;
        },
        
        getAudioPreviewUrl : function(){
            return this._audioPreviewUrl;
        },
        
        getCategoryId : function(){
            return this._categoryId;
        },

        setCategoryId : function(categoryId){
            return this._categoryId = categoryId;
        },

        getCityId : function(){
            return this._cityId;
        },

        setCityId : function(cityId){
            return this._cityId = cityId;
        },
        
        getTimezone : function(){
            return this._timezone;
        },

        setTimezone : function(timezone){
            return this._timezone = timezone;
        },

        getLanguage : function(){
            return this._language;
        },

        setLanguage : function(language){
            return this._language = language;
        },
        
        
        getGenreIdToAudioPreviewUrl : function(){
            return this._genreIdToAudioPreviewUrl;
        },
        
        getAudioPreviewUrlByGenreId : function(genreId){
            return this._genreIdToAudioPreviewUrl? this._genreIdToAudioPreviewUrl[genreId]:null;
        },
        
        getCoverArtUrl : function(){
            return this._coverArtUrl;
        },
        
        setCoverArtUrl : function(newCoverArtUrl){
            this._coverArtUrl = newCoverArtUrl;
        },
        
        getCoverArtUrlBlur : function(){
            return this._coverArtUrlBlur;
        },
        
        
        setCoverArtUrlBlur : function(newCoverArtUrlBlur){
            this._coverArtUrlBlur = newCoverArtUrlBlur;
        },
        
        getCoverArtUrlInfo : function(){
            return this._coverArtUrlInfo;
        },
        
        
        setCoverArtUrlInfo : function(newCoverArtUrlInfo){
            this._coverArtUrlInfo = newCoverArtUrlInfo;
        },
        
        getCoverArtUrlBlurInfo : function(){
            return this._coverArtUrlBlurInfo;
        },
        
        
        setCoverArtUrlBlurInfo : function(newCoverArtUrlBlurInfo){
            this._coverArtUrlBlurInfo = newCoverArtUrlBlurInfo;
        },
        
        getCoverArtBase64 : function(){
            return this._coverArtBase64;
        },
        
        setCoverArtBase64 : function(newCoverArtBase64){
            this.this._coverArtBase64 = newCoverArtBase64;
        },
        
        isFollowing : function(){
            return this._isFollowing;
        },
        
        canBeFollowed : function(){
            return this._canBeFollowed;
        },
        
        
        canBeEdited : function(){
            return this._canBeEdited;
        },
        
        getNumberOfSongs : function(){
            return this._numberOfSongs;
        },
        

        getNumberOfFollowers : function(){
            return this._numberOfFollowers;
        },
        
        addFollower : function(){
            var numberOfFollowers = this._numberOfFollowers;
            numberOfFollowers =  numberOfFollowers? numberOfFollowers: 0;
            numberOfFollowers = numberOfFollowers + 1;
            this._numberOfFollowers = numberOfFollowers;
        },
        
        removeFollower : function(){
            var numberOfFollowers = this._numberOfFollowers;
            numberOfFollowers =  numberOfFollowers? numberOfFollowers: 0;
            if(numberOfFollowers != 0){
                numberOfFollowers = numberOfFollowers - 1;
            }
            this._numberOfFollowers = numberOfFollowers;
        },
        
        getTotalNumberOfPlays : function(){
            return this._totalNumberOfPlays;
        },
        
        getOwnerNumberOfPlays : function(){
            return this._ownerNumberOfPlays;
        },
        
        getPlaySuccessRate : function(){
            return this._playSuccessRate;
        },
        
        getNumberOfSongsFavorited : function(){
            return this._numberOfSongsFavorited;
        },
        
        getNumberOfCapsules : function(){
            return this._numberOfCapsules;
        },
        
        getNumberOfSongsComments : function(){
            return this._numberOfSongsComments;
        },
        
        
        setPlaying : function(isPlaying){
            this._isPlaying = isPlaying;
            this.trigger("isPlayingChanged",this._isPlaying );
        },
        
        isPlaying : function(isPlaying){
            return this._isPlaying;
        },
        
        
        getPlaylistSummary : function(){
            var playlistSummaryModel = Utils.getInstance().buildPlaylistSummary(this._loaderKey, this._name, this._description, 
                this._loaderKey,this._creator,this._categoryId, this._cityId, this._artistSample,
                this._lastModificationDate,this._audioPreviewUrl,this._coverArtUrl,this._coverArtUrlBlur,this._coverArtUrlInfo,this.newCoverArtUrlBlurInfo,this._coverArtBase64, this._isFollowing, this._hasPurchased, this._canBeFollowed,this._canBeEdited,
                this._numberOfSongs ,this._numberOfFollowers, this._numberOfPurchasers, this._totalNumberOfPlays,  this._ownerNumberOfPlays, this._playSuccessRate,
                this._numberOfSongsFavorited, this._numberOfCapsules, this._numberOfSongsComments, this._genreIdToAudioPreviewUrl, this._customURL, this._timezone, this._language,this._isPaid, this._costInUSD);
            this._registernToChangeEvents(playlistSummaryModel, "isPlaying");
            return playlistSummaryModel;
        },
        
        
    });
export default DynamicPlaylist;