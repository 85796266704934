import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleSubscribeSuggestionPopupView from "views/trebbleSubscribeSuggestion/TrebbleSubscribeSuggestionPopupView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import ti18n from "i18n!nls/TrebbleSubscribeSuggestioni18n";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";
import FacebookHelper from "models/helper/FacebookHelper";

const TrebbleSubscribeSuggestionPopupController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.personalRadioSummaryModel =  options.personalRadioSummaryModel;
        _.extend(this, Backbone.Events);
    },

    _getShareableLink : function(){
        return "https://s.trebble.fm/p/p/t/"+this.personalRadioSummaryModel.getLoaderKey()+"?t="+ new Date().getTime();
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    shareOnFacebook : function(){
        FacebookHelper.getInstance().showDialog({
            method: 'share',
            href: this._getShareableLink(),
        },{"entity":"Trebble", "eventName":"TrebbleShareFacebook", "eventLabel":"Trebble Player URL Shared On Facebook"} ).catch(function(error){
            window.alertErrorMessage(error);
        });
        /*FB.ui({
            method: 'share',
            href: this._getShareableLink(),
        }, function(response){
            if(response && response.error_message){
                window.alertErrorMessage(response.error_message);
            }
        });*/
    },

    shareOnTwitter : function(){
        const w = 550;
        const h = 285;
        const left = window.getLeftPositionForPopupWindow(w);
        const top = window.getTopPositionForPopupWindow(h);
        const openedWindow = window.open("https://twitter.com/intent/tweet?via=trebblefm&url="+encodeURIComponent(this._getShareableLink()),"Twitter", "height="+h+",width="+w+",resizable=1,top="+top+",left="+left+"");
        if((!openedWindow || openedWindow.closed || typeof openedWindow.closed=='undefined') && !window.waitForCordovaToLoad){
            window.alertErrorMessage(window.getI18n(ti18n, "PLEASE_ALLOW_POPUPS"));
        }
    },
    


    subscribeToTrebble  : function(){
        return TrebbleClientAPIHelper.getInstance()._doLogin(false, true).then((function(){
            return this.view.close();
        }).bind(this)).then((function(){
            const onTrebbleSubscriptionSucessHandler = function(){

            };
            const onTrebbleSubscriptionFailureHandler = function(error){
                window.alertErrorMessage(error);
            };
            return PersistentModels.getInstance().getRouter().showSubscribeToTrebblePopupIfApplicable(this.personalRadioSummaryModel.getLoaderKey(), onTrebbleSubscriptionSucessHandler, onTrebbleSubscriptionFailureHandler);
        }).bind(this));
    },


    onPopupClosed : function(){

    },


    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {

                const params = {};
                params.entityNameShared = window.getI18n(ti18n,"TREBBLE");
                params.personalRadioSummaryModel =  this.personalRadioSummaryModel;
                params.disableAutoplay = true;
                params.autoplayTimeInSeconds= 8;
                params.toPlayNextTitle = "";
                this.view = new TrebbleSubscribeSuggestionPopupView(params);
                this.listenTo(this.view, "shareTrebbleOnFacebook",this.shareOnFacebook);
                this.listenTo(this.view, "shareTrebbleOnTwitter",this.shareOnTwitter);
                this.listenTo(this.view, "subscribeToTrebble",this.subscribeToTrebble);
                this.listenTo(this.view, "closed",this.onPopupClosed);
                this.view.render();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default TrebbleSubscribeSuggestionPopupController;