import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utils from "models/helper/Utils";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import ti18n from "i18n!nls/Useri18n";
import SendRecoveryPasswordEmailView from "views/user/SendRecoveryPasswordEmailView";

const SendRecoveryPasswordEmailController =  Chaplin.Controller.extend({


    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = null;
        this._pageId = options.pageId;


    },
    
    getModel : function(){
        return this.model;
    },
    
    getView : function(){
        return this.view;
    },
    
    sendRecoveryPasswordEmail : function(email){

        $.mobile.loading("show");
        return TrebbleClientAPIHelper.getInstance().sendRecoveryPasswordEmail(email).then((function(){
            this.view.navigateToPreviousPage();
            window.alertSuccessMessage(window.getI18n(ti18n, "A_LINK_TO_RESET_YOUR_PASSWORD_HAS_BEEN_SENT_TO") +" "+ email);
            $.mobile.loading("hide");
        }).bind(this)).catch((function(error){
            $.mobile.loading("hide");
            const errorMessage = error? error: window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_SENDING_RESET_PASSWORD_LINK");
            window.alertErrorMessage(errorMessage);
        }).bind(this));
        
        
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                const pageViewParams= {};
                pageViewParams.pageId = this._pageId;
                this.view = new SendRecoveryPasswordEmailView(pageViewParams);
                this.view.render();
                this.listenTo(this.view, "sendRecoveryPasswordEmail", this.sendRecoveryPasswordEmail);
                resolve(this);
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }
    

});
export default SendRecoveryPasswordEmailController;