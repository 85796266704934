import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import Utils from "models/helper/Utils";
import QueueTuningController from "controllers/queue/QueueTuningController";
import OptionPopupView from "views/common/OptionPopupView";
import RSVP from "rsvp";

const QueueTuningPopupController = Chaplin.Controller.extend({

    initialize : function(queue) {
        this.model = queue;
        _.extend(this, Backbone.Events);
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },
    
    onPopupClose : function(){
        $.mobile.loading( 'show');
        try{
            if(this._contentView.isSelectionChanged())
            {
                this.model.setSelectedTagFilter(this._contentView.getTagGroupsArray());
            }
            $.mobile.loading( 'hide');
        }catch(error){
            $.mobile.loading( 'hide');
            window.alertErrorMessage(error);
        }
        
    },

    onViewRemoved:function(){
        this.stopListening();
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                const queueTuningControllerParams =  {};
                queueTuningControllerParams.model = this.model;
                const queueTuningController  = new QueueTuningController(queueTuningControllerParams);
                this._queueTuningController = queueTuningController;
                const promise =  queueTuningController.buildModelAndRenderView().then((function(){
                    const queueTuningView = queueTuningController.getView();
                    const params= {};
                    params.id = "queueTuningPopup";
                    params.contentView = queueTuningView;
                    params.contentPosition = "top";
                    params.song = this.model;
                    this._contentView = queueTuningView;
                    this.view = new OptionPopupView(params);
                    this.listenTo(this.view,"closed", this.onPopupClose.bind(this));
                    this.listenTo(this.view, "remove", this.onViewRemoved.bind(this));
                    this.view.render();
                    return null;
                }).bind(this))
                promise.then(resolve).catch(reject);
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default QueueTuningPopupController;