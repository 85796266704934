import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import Utils from "models/helper/Utils";
import TagGroup from "collections/TagGroup";
import ti18n from "i18n!nls/CapsuleLifeSpanListSelectioni18n";
import TagsCollectionView from "views/common/TagsCollectionView";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";

const CapsuleLifeSpanListSelectionController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if(options)
        {
            if(options.model)
            {
                this.model = options.model;
            }
            if(options.radioIdsToAddCapsuleTo){
                this.radioIdsToAddCapsuleTo = options.radioIdsToAddCapsuleTo;
            }
        }
        this._capsuleLifespanLabelToValueInDays = Utils.getInstance().getCapsuleLifeSpanLabelToValueInDays();
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    onCapsuleLifeSpanChange : function(lifespanLabelsSelected){
        if(lifespanLabelsSelected && lifespanLabelsSelected.length > 0){
            const lifespanLabel = lifespanLabelsSelected[0];
            const lifespanValue = this._capsuleLifespanLabelToValueInDays[lifespanLabel];
            this.trigger("capsuleLifeSpanChanged",lifespanValue);
        }
    },
    

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                if(!this.model)
                {
                    throw window.getI18n(ti18n,"PLEASE_SELECT_CAPSULE_FIRST");
                }
                const lifespanTagArray = [];
                const lifespanGroupId = "lifespanGroupId"
                for(let lifeSpanLabel in this._capsuleLifespanLabelToValueInDays){
                    const lifeSpanValue = this._capsuleLifespanLabelToValueInDays[lifeSpanLabel];
                    lifespanTagArray.push(Utils.getInstance().buildTag(lifeSpanLabel, lifeSpanValue,{"EN":lifeSpanLabel}, lifespanGroupId, false));
                }
                const lifeSpanTagGroup = new TagGroup(lifespanTagArray);
                lifeSpanTagGroup.setExclusive(true);
                //const selectedIds =  this.model.get("tagIds")? this.model.get("tagIds") : [];
                //lifeSpanTagGroup.setSelectedTagIds(selectedIds);
                this.view = new TagsCollectionView({
                    "tagGroup" : lifeSpanTagGroup,
                    "viewElementId":"lifespanSelectionView",
                    "allOptionTagAvalailable": false,
                    "doNotShowHashTag": true
                });
                this.listenTo(this.view, "selectedTagIdsChange",this.onCapsuleLifeSpanChange);

                this.view.render();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default CapsuleLifeSpanListSelectionController;