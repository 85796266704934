import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import JourneyStatsPageContentView from "views/journey/JourneyStatsPageContentView";
import CustomSelectFieldController from "controllers/common/CustomSelectFieldController";
import DynamicPageView from "views/common/DynamicPageView";
import ti18n from "i18n!nls/StatsPagei18n";
import PageTemplate from 'text!../../../templates/trebbleStatistics/StatisticsPageTemplate.html';
import RSVP from "rsvp";

const WARNING_MESSAGE_LOADING_CLASS = "loading";
const WARNING_MESSAGE_NO_DATA_CLASS = "nodata";
const JourneyStatsPageController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = options.model;
        this._pageId = options.pageId;
        this._radioId = options.radioId;
        this._entityName = "journey";
    },

    _onPageShow : function(){

    },



    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },



    setViewLoading : function(){

        const warningMesasgeWrapper$el = this.view.$el.find(".warningMessageWrapper");
        this.view.$el.find("#statisticContent").hide();
        if(warningMesasgeWrapper$el.hasClass(WARNING_MESSAGE_NO_DATA_CLASS)){
            warningMesasgeWrapper$el.removeClass(WARNING_MESSAGE_NO_DATA_CLASS);
        }
        if(!warningMesasgeWrapper$el.hasClass(WARNING_MESSAGE_LOADING_CLASS)){
            warningMesasgeWrapper$el.addClass(WARNING_MESSAGE_LOADING_CLASS);
        }
    },

    setViewHasNoData : function(){

        const warningMesasgeWrapper$el = this.view.$el.find(".warningMessageWrapper");
        this.view.$el.find("#statisticContent").hide();
        if(warningMesasgeWrapper$el.hasClass(WARNING_MESSAGE_LOADING_CLASS)){
            warningMesasgeWrapper$el.removeClass(WARNING_MESSAGE_LOADING_CLASS);
        }
        if(!warningMesasgeWrapper$el.hasClass(WARNING_MESSAGE_NO_DATA_CLASS)){
            warningMesasgeWrapper$el.addClass(WARNING_MESSAGE_NO_DATA_CLASS);
        }
    },

    setViewHasData : function(){

        const warningMesasgeWrapper$el = this.view.$el.find(".warningMessageWrapper");
        if(warningMesasgeWrapper$el.hasClass(WARNING_MESSAGE_LOADING_CLASS)){
            warningMesasgeWrapper$el.removeClass(WARNING_MESSAGE_LOADING_CLASS);
        }
        if(warningMesasgeWrapper$el.hasClass(WARNING_MESSAGE_NO_DATA_CLASS)){
            warningMesasgeWrapper$el.removeClass(WARNING_MESSAGE_NO_DATA_CLASS);
        }
        this.view.$el.find("#statisticContent").show();
    },

    setViewFailedLoading : function(error){

        const warningMesasgeWrapper$el = this.view.$el.find(".warningMessageWrapper");
        if(warningMesasgeWrapper$el.hasClass(WARNING_MESSAGE_LOADING_CLASS)){
            warningMesasgeWrapper$el.removeClass(WARNING_MESSAGE_LOADING_CLASS);
        }
        if(warningMesasgeWrapper$el.hasClass(WARNING_MESSAGE_NO_DATA_CLASS)){
            warningMesasgeWrapper$el.removeClass(WARNING_MESSAGE_NO_DATA_CLASS);
        }
        this.view.$el.find("#statisticContent").hide();
    },

    retrieveJourneyStats : function(){
        this.setViewLoading();
        return TrebbleClientAPIHelper.getInstance().getJourneyStats(this._radioId).then((function(journeyStatsInfo){
            if(journeyStatsInfo && journeyStatsInfo.radioStats){
                this.setViewHasData();
                const radioStats  = journeyStatsInfo.radioStats;
                this.journeyStatsPageContentView.setJourneyTitle(radioStats.name);
                this.journeyStatsPageContentView.setJourneyCoverArt(radioStats.coverArtUrl);
                this.journeyStatsPageContentView.setRadioStats(radioStats.numberOfPageVisitors, radioStats.numberOfSubscriptions, radioStats.numberOfMinutesListened, radioStats.revenueGenerated, radioStats.revenueEarnedByCreator,radioStats.applicationFee);
                this.journeyStatsPageContentView.setOutlineProgress(journeyStatsInfo.outlineStats);
            }else{
                this.setViewHasNoData();
            }

        }).bind(this)).catch((function(error){
            this.setViewFailedLoading(error);
        }).bind(this))
    },

    buildModelAndRenderView : function() {


            const params = {};

            const journeyStatsPageContentView =  new JourneyStatsPageContentView(params);
            
            journeyStatsPageContentView.render();
            this.journeyStatsPageContentView = journeyStatsPageContentView;
            
            const pageViewParams= {};
            pageViewParams.pageId = this._pageId;
            pageViewParams.pageViewTemplate = PageTemplate;
            pageViewParams.containerSelector = ".ui-content";
            pageViewParams.contentView = journeyStatsPageContentView;
            pageViewParams.onPageShow = this._onPageShow.bind(this);
            pageViewParams.pageTemplateOptions = {"ti18n":ti18n};
            pageViewParams.ignoreDefaultPageUIContentPadding = true;

            this.view = new DynamicPageView(pageViewParams);
            this.view.render();
            this.view.$el.on("click", "#header-back-button", this.view.navigateToPreviousPage.bind(this.view));
            this.retrieveJourneyStats();

            $.mobile.loading("hide");
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent(this._entityName, this._entityName+"Statistics", "View "+this._entityName+" Statistics");
            }

        return RSVP.Promise.resolve();

    }

});

export default JourneyStatsPageController;