import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import DynamicContentView from "views/common/DynamicContentView";
import ti18n from "i18n!nls/StatsPagei18n";
import JourneyContentViewTemplate from 'text!../../../templates/journey/JourneyContentViewTemplate.html';
import RSVP from "rsvp";





const JourneyStatsPageContentView = DynamicContentView.extend({


    initialize : function(options) {
        this._collectionContainerId =  "joruneyStatisticCollectionWrapper" + (new Date()).getTime();
        DynamicContentView.prototype.initialize.call(this, {"canBeAppendedBeforeLoad": true});
    },

    setJourneyTitle : function(journeyTitle){
        this.$el.find(".journey_title").html(journeyTitle);
    },

    setJourneyCoverArt : function(coverArtUrl){
        this.$el.find(".cover_art_wrapper img").attr("src",coverArtUrl);
    },


    addRevenueToMap : function(revenueArray, revenueLabel, revenueType, dataMap){
        if(revenueArray && revenueArray.length> 0){
            for(let i =0; i < revenueArray.length; i++){
                const revenueElement = revenueArray[i];
                let currency = revenueElement.currency? revenueElement.currency.toUpperCase():"";
                const amount = revenueElement.amount;
                if(currency == "USD"){
                    currency = "$"
                }
                if(!dataMap[currency]){
                    dataMap[currency] = {};
                }
                dataMap[currency][revenueType] = {"amount":amount};

            }
        }
    },

    displayRevenuMap :function(revenueDataMap, isCurrencyDisplayedBefore){
        if(revenueDataMap){
            const revenueTypeToLabel = {
                "total_revenue_stats": window.getI18n(ti18n, "TOTAL_REVENUE"),
                "total_your_revenue_stats": window.getI18n(ti18n, "CREATOR_REVENUE"),
                "total_trebble_share_stats": window.getI18n(ti18n, "TREBBLE_SHARE"),
            }
            for(let currency in revenueDataMap){
                for(let revenuType in revenueTypeToLabel){
                    const revenueLabel = revenueTypeToLabel[revenuType];
                    const revenueStatsData = revenueDataMap[currency][revenuType]
                    const amount = revenueStatsData? revenueStatsData.amount: 0;
                    this.addRevenueBoxStats(currency,  revenueLabel, revenuType, amount, isCurrencyDisplayedBefore);
                }
            }
        }

    },

    addRevenueBoxStats : function(currency, revenueLabel, revenueType, amount, isCurrencyDisplayedBefore){
        if(!amount){
            amount = 0;
        }else{
            amount = Utils.getInstance().formatNumber(amount, 1);
        }
        let elementToAddHTML = '<div class="specific_journey_stats_summary '+revenueType+'" currency="'+currency+'" >';
        elementToAddHTML = elementToAddHTML +'<div class="stats_number">'+(isCurrencyDisplayedBefore? currency.toUpperCase() +amount: amount+currency.toUpperCase())+'</div>';
        elementToAddHTML = elementToAddHTML + '<div class="stats_label">'+revenueLabel+'</div>';
        elementToAddHTML = elementToAddHTML + '</div>';
        const elementToAdd$el = $(elementToAddHTML);
        this.$el.find(".generic_journey_stats").append(elementToAdd$el);
    },

    setRadioStats: function(numberOfPageVisitors, numberOfSubscriptions, numberOfMinutesListened, revenueGenerated, creatorRevenue, trebbleShare, currencySymbol, isCurrencyDisplayedBefore){
        this.$el.find("#page_views_stats .stats_number").html(numberOfPageVisitors ?Utils.getInstance().formatNumber(numberOfPageVisitors, 1) : 0);
        this.$el.find("#subs_stats .stats_number").html(numberOfSubscriptions? Utils.getInstance().formatNumber(numberOfSubscriptions, 1): 0);
        this.$el.find("#minutes_played_stats .stats_number").html(numberOfMinutesListened? Utils.getInstance().formatNumber(numberOfMinutesListened, 1): 0);
        
        const mapCurrencyToData = {};
        this.addRevenueToMap(revenueGenerated, window.getI18n(ti18n, "TOTAL_REVENUE"), "total_revenue_stats", mapCurrencyToData);
        this.addRevenueToMap(creatorRevenue, window.getI18n(ti18n, "CREATOR_REVENUE"), "total_your_revenue_stats", mapCurrencyToData);
        this.addRevenueToMap(trebbleShare, window.getI18n(ti18n, "TREBBLE_SHARE"), "total_trebble_share_stats", mapCurrencyToData);

        this.displayRevenuMap(mapCurrencyToData, isCurrencyDisplayedBefore);

    },

    setOutlineProgress : function(outlineStats){
        if(outlineStats && outlineStats.length > 0){
            const divTable$el =  this.$el.find(".outline_journey_stats .outline_stats_table");
            for(let i =0; i < outlineStats.length; i++){
                const outlineItemStats = outlineStats[i];
                const shortcastTitle = outlineItemStats.title;
                const playCompleted = outlineItemStats.playCompleted;
                const playStarted = outlineItemStats.playStarted;
                const skips = outlineItemStats.skips;
                divTable$el.append($(this._getRowHtmlForShortcastStats(i+1, shortcastTitle, Utils.getInstance().formatNumber(playCompleted,1))));
            }
        }

    },

    _getRowHtmlForShortcastStats : function(dayNumber, shortcastTitle, playCompleted){
        return "<div class='outline_stats'><div class='day_number'>"+dayNumber+"</div><div class='shortcast_title'>"+shortcastTitle+"</div><div class='number_of_plays'>"+playCompleted+"</div></div>"
    },

    render : function() {
        const templateParams = {};
        this.$el.append("<div  id='"+this._collectionContainerId+"'><div id='journeyStatsInfoWrapper'></div></div>");
        this.collectionContainer$el = this.$el.find("#"+this._collectionContainerId);
        this.journeyStatsInfoWrapper$el = this.$el.find("#journeyStatsInfoWrapper");
        this.journeyStatsInfoWrapper$el.html(_.template(JourneyContentViewTemplate)({ "ti18n": ti18n}))

        return this;
    }

});


export default JourneyStatsPageContentView;