import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SongOptionMenuModel from "models/SongOptionMenuModel";
import ti18n from "i18n!nls/MyLibraryi18n";
import ArtistsTabContentCollection from "collections/myLibrary/ArtistsTabContentCollection";
import SongOptionMenuView from "views/SongOptionMenuView";
import PersistentModels from "services/PersistentModels";
import TrebbleListView from "views/common/TrebbleListView";

const ArtistTabContentView = Backbone.View.extend({



    initialize : function() {
        this.displayedCollection = this.collection;
        this.collection.bind("add", this._onArtistAddedOrRemovedInLibrary, this);
        this.collection.bind("change", this._onArtistUpdayedInLibrary, this);
        this.collection.bind("remove", this._onArtistAddedOrRemovedInLibrary, this);
        this.collection.bind("reset", this._onArtistAddedOrRemovedInLibrary, this);
        

        PersistentModels.getInstance().getRouter().registerForViewportHeightChange("artistTabListView",this._onOrientationChangeFunction.bind(this));
                
    },
    
    
_onOrientationChangeFunction : function(newViewPortHeigh, forceResizing) {
        if(PersistentModels.getInstance().getRouter().isEqualToCurrentPageUrl("#mylibrary") || forceResizing){
        if (this.el) {
            const tabHeight = 46;
              this.el.style.height = (newViewPortHeigh - tabHeight) + "px"; 
          }
        this.infiniteListView._onRootElementResize();
        }
  },

    filterList: function(searchCharaterers) {
        
        this.appliedSearchCharacters = searchCharaterers;
        const searchFunction = (function() {
            $.mobile.loading("show");
            this.infiniteListView.setUpdating(true);
            this._changeArtistList(new ArtistsTabContentCollection(this.collection.searchOnArtistname(this.appliedSearchCharacters)));
            this.pendingSearchFunctionTimerId = null;
            $.mobile.loading("hide");
        }).bind(this);
        if (this.pendingSearchFunctionTimerId) 
        {
            window.clearTimeout(this.pendingSearchFunctionTimerId);
        }
        this.pendingSearchFunctionTimerId = window.setTimeout(searchFunction, 1000);
    
    },
    
    clearSearchfilter: function() {
        
        this.appliedSearchCharacters = null;
        const searchFunction = (function() {
            this.infiniteListView.setUpdating(true);
            this._changeArtistList(new ArtistsTabContentCollection(this.collection.searchOnArtistname("")));
            this.pendingSearchFunctionTimerId = null;
        }).bind(this);
        if (this.pendingSearchFunctionTimerId) 
        {
            window.clearTimeout(this.pendingSearchFunctionTimerId);
        }
        this.pendingSearchFunctionTimerId = window.setTimeout(searchFunction, 0);
    
    },
    
    _getArtistModelFromArtistJson : function(artistJson){
        return this.collection.get(artistJson.artistId);
    },
    
     _changeArtistList: function(newDisplayedArtistModels) {
            this.displayedCollection = newDisplayedArtistModels;
            this.displayedCollection.sort();
            this.infiniteListView.updateModels(this.displayedCollection.toJSON());
            this.infiniteListView.setUpdating(false);
            this._onDisplayedCollectionChanged();
        },
        
     _updateArtistInArtistList: function(artistUpdateModel) {
            this.infiniteListView.updateModel(artistUpdateModel.toJSON());
            this.infiniteListView.setUpdating(false);
            this._onDisplayedCollectionChanged();
        },
    
    
    _getOnListItemTapHandler : function(){
        return  (function(artistJson,evnt, item) {
            this.trigger("itemTaped",this._getArtistModelFromArtistJson(artistJson));		
        }).bind(this);
    },
    
    _getOnListItemPressHandler : function(){
        return  (function(albumJson,evnt, item) {
            this.trigger("itemPressed",this._getArtistModelFromArtistJson(albumJson));		
        }).bind(this);
    },
    
    _getOnListItemPressUpHandler : function(){
        return  (function(albumJson,evnt, item) {
            this.trigger("itemPressedUp",this._getArtistModelFromArtistJson(albumJson));		
        }).bind(this);
    },

    _onItemSwipeRight : function(){
        return  (function(artistJson,evnt, item){
    
            this.trigger("itemSwipedRight",this._getArtistModelFromArtistJson(artistJson));
        }).bind(this);
    },
    

    _modelTransformHandlerBeforeTemplatePiping : function(artistJson){

        let artistToDisplay = artistJson.artistName;
        let numberOfAlbumsToDisplay = artistJson.albums.length;
        if(!artistToDisplay)
        {
            artistToDisplay = "";
        }
        if(!numberOfAlbumsToDisplay)
        {
            numberOfAlbumsToDisplay = "0";
        }else{
            numberOfAlbumsToDisplay  = numberOfAlbumsToDisplay + " album";
            if(numberOfAlbumsToDisplay > 1)
            {
                numberOfAlbumsToDisplay  = numberOfAlbumsToDisplay + "s";
            }

        }
            const templateData = {firstLineText : artistToDisplay, secondLineText : numberOfAlbumsToDisplay, "model" : artistJson};
            return templateData;
    },

    _getOnListButtonTappedHandler :function(){
        return  (function(artistJson,evnt, item) {
            this.trigger("itemOptionBtnTapped",this._getArtistModelFromArtistJson(artistJson));		
        }).bind(this);
    },
    
    isSearchApplied : function(){
        return this.appliedSearchCharacters != null;
    },
    
    getAppliedSearchCharacters : function(){
        return this.appliedSearchCharacters;
    }, 
    
    _onDisplayedCollectionChanged :function(){
        this.trigger("modelChange", this.displayedCollection);
    },
    
    _onArtistAddedOrRemovedInLibrary : function(){
        if(this.isSearchApplied())
        {
            this.filterList(this.getAppliedSearchCharacters());
        }else{
            this.infiniteListView.setUpdating(true);
            this._changeArtistList(this.collection);
        }
        this.trigger("modelChange", this.displayedCollection);
    },
    
    _onArtistUpdayedInLibrary : function(artistUpdateModel){
        if(this.isSearchApplied())
        {
            this.filterList(this.getAppliedSearchCharacters());
        }else{
            this.infiniteListView.setUpdating(true);
            this._updateArtistInArtistList(artistUpdateModel);
        }
        this.trigger("modelChange", this.displayedCollection);
    },
    
    isCollectionEmpty : function(){
        if(this.displayedCollection)
        {
            return this.displayedCollection.length == 0;
        }else{
            return true;
        }
    },
    
    show : function(){
        this.$el.css("display", "");
    },
    
    hide : function(){
        this.$el.css("display", "none");
    },

    
    _onEmptyInfoBoxButtonPressed : function(){
        this.trigger("infoBoxButtonPressed");
    },
    
    _buildInfoBoxViewParams : function(){
        const params = {};
        params.iconClass = "ion-android-social-user";
        params.actionButtonIconClass = "fontello-icon-search";
        params.message = window.getI18n(ti18n, "NO_ARTIST");
        params.helpMessage = "";
        params.actionButtonLabel = window.getI18n(ti18n,"SEARCH");
        return params;
    },
    
    
    
    render : function() {
    
        const tabHeight = 46;
        const contentHeight = PersistentModels.getInstance().getRouter().getViewportHeight() - tabHeight;
        this.$el.html(' <div id="artistListWrapper"  style="height:'+ contentHeight.toString() +'px;overflow:hidden;width:100%;"></div>');
        this.setElement(this.$el.find("div").first());
         const listParams ={};
         this.displayedCollection.sort();
           listParams.model = this.displayedCollection.toJSON();
             listParams.listRootElement = this.el;
             listParams.defaultNumberOfElements = 80;
             listParams.defaultHeightElement = 60;
             listParams.placeHolderHeight = contentHeight;
             listParams.modelIdAttribute  = "artistId";
             listParams.customInfoBoxInfoParams = this._buildInfoBoxViewParams();
             listParams.doNotShowAddToRadioButton = true;
             listParams.doNotShowGradeSongButton = true;
             const infiniteListView =  new TrebbleListView(listParams);
              this.listenTo(infiniteListView, "infoBoxButtonPressed", this._onEmptyInfoBoxButtonPressed);
           
            this.infiniteListView = infiniteListView;
            infiniteListView.setModelTransformHandlerBeforeTemplatePiping(this._modelTransformHandlerBeforeTemplatePiping);
            infiniteListView.addOnItemTapHandler(this._getOnListItemTapHandler());
            infiniteListView.addOnItemPressHandler(this._getOnListItemPressHandler());
            infiniteListView.addOnItemPressUpHandler(this._getOnListItemPressUpHandler());
            infiniteListView.addOnOptionButtonTappedHandlers(this._getOnListButtonTappedHandler());
            infiniteListView.addOnSwipeRightRevealHandler(this._onItemSwipeRight());
            infiniteListView.render();
            this._onOrientationChangeFunction(PersistentModels.getInstance().getRouter().getViewportHeight(), true);
        return this;
    }

});
export default ArtistTabContentView;