import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import MusicAudioSegment from "models/audioEditor/segments/MusicAudioSegment";
import StartWrapSequencerNode from "models/audioEditor/sequencerNodes/StartWrapSequencerNode";
import EndMusicWrapSequencerNode from "models/audioEditor/sequencerNodes/EndMusicWrapSequencerNode";
import AudioBufferUtils from "models/audioEditor/AudioBufferUtils";
import TrebbleAudioFactory from "models/helper/TrebbleAudioFactory";
import AudioFilterFactory from "models/audioEditor/filters/AudioFilterFactory";
import RSVP from "rsvp";

const CLASSNAME = "StartMusicWrapSequencerNode";
const BUILT_IN_AUDIO_FILTERS_NAMES = {"VOLUME":"volume","FADE_START":"fadeStart","FADE_END":"fadeEnd", "SIDECHAIN_COMPRESSOR":"sidechainCompressor"};
const StartMusicWrapSequencerNode =  StartWrapSequencerNode.extend({
    idAttribute: "id",


    constructor: function(attributes, options, createdFromClone, objBeingCloned, sequencerNodeCidToCloneNodeMap) {
        StartWrapSequencerNode.apply(this, [attributes, options, createdFromClone, objBeingCloned, sequencerNodeCidToCloneNodeMap]);		
        this._initBuiltInDefaultFilters();
    },


    _initBuiltInDefaultFilters: function(){

        const builtInGainFilter = AudioFilterFactory.getInstance().createGainFilter((this.get("mainVolumePercentage") || this.get("mainVolumePercentage") === 0)?this.get("mainVolumePercentage") : 1)
        builtInGainFilter.setName(BUILT_IN_AUDIO_FILTERS_NAMES.VOLUME)
        this.addBuiltInAudioFilter(builtInGainFilter.getName(), builtInGainFilter);
        const builtInFadeStartFiler = AudioFilterFactory.getInstance().createFadeStartFilter(0.1, 1, this.get("introFadeInMilliseconds") || 0);
        builtInFadeStartFiler.setName(BUILT_IN_AUDIO_FILTERS_NAMES.FADE_START);
        this.addBuiltInAudioFilter(builtInFadeStartFiler.getName(), builtInFadeStartFiler);
        const builtInFadeEndFiler = AudioFilterFactory.getInstance().createFadeEndFilter(1, 0.1, this.get("outroFadeInMilliseconds") || 0);
        builtInFadeEndFiler.setName(BUILT_IN_AUDIO_FILTERS_NAMES.FADE_END);
        this.addBuiltInAudioFilter(builtInFadeEndFiler.getName(), builtInFadeEndFiler );
        const ducking = this.get("ducking");
        const builtInSidechainCompressor = AudioFilterFactory.getInstance().createSidechainCompressorFilter(AudioFilterFactory.getInstance().getSidechainCompressorFilterPresets().SIDECHAIN_COMPRESSOR_WITH_VOICE_TRACK);
        builtInSidechainCompressor.setDisabled(!ducking);
        builtInSidechainCompressor.setName(BUILT_IN_AUDIO_FILTERS_NAMES.SIDECHAIN_COMPRESSOR);
        this.addBuiltInAudioFilter(builtInSidechainCompressor.getName(), builtInSidechainCompressor );


        this.listenTo(this.getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.VOLUME),"change:gainValue", (function(gainFilter){
            this.set("mainVolumePercentage",gainFilter.getGainValue());
        }).bind(this));
        this.listenTo(this.getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.FADE_START),"change:fadeDuration", (function(fadeFilter){
            this.set("introFadeInMilliseconds",fadeFilter.getFadeDuration());
        }).bind(this));
        this.listenTo(this.getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.FADE_END),"change:fadeDuration", (function(fadeFilter){
            this.set("outroFadeInMilliseconds",fadeFilter.getFadeDuration());
        }).bind(this));
        this.listenTo(this.getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.FADE_END),"change:disabled", (function(sidechainCompressorFilter){
            this.set("ducking",!sidechainCompressorFilter.isDisabled());
        }).bind(this));
    },

    getConstructor : function(){
        return StartMusicWrapSequencerNode;
    },


    cloneJSON: function(){
        const objJson = StartWrapSequencerNode.prototype.cloneJSON.call(this);
        objJson.audio = this.get("audio");
        objJson.type = StartMusicWrapSequencerNode.CLASSNAME;
        objJson.mainVolumePercentage = this.get("mainVolumePercentage");
        objJson.introFadeInMilliseconds = this.get("introFadeInMilliseconds");
        objJson.outroFadeInMilliseconds = this.get("outroFadeInMilliseconds");
        objJson.introPaddingInMilliseconds = this.get("introPaddingInMilliseconds");
        objJson.outroPaddingInMilliseconds = this.get("outroPaddingInMilliseconds");
        objJson.loopToMatchWrapNodeDuration = this.get("loopToMatchWrapNodeDuration");
        objJson.ducking = this.get("ducking");

        return objJson;
    },

    getSerializedData: function(){
        const serializedData = StartWrapSequencerNode.prototype.getSerializedData.call(this);
        serializedData.audio = serializedData.audio.toJSON();
        return serializedData;
    },

    restoreFromSerializedData: function(serializedData, audioSegmentFactoryInstance, audioFilterFactoryInstance, additionalSpeakerInfoArray){
        return StartWrapSequencerNode.prototype.restoreFromSerializedData.call(this, serializedData, audioSegmentFactoryInstance, audioFilterFactoryInstance, additionalSpeakerInfoArray);
    },

    afterAllInstancesCreatedFromSerializedData : function(serializedData, sequencerNodeCidToSequencerNodeMap){
        StartWrapSequencerNode.prototype.afterAllInstancesCreatedFromSerializedData.call(this, serializedData, sequencerNodeCidToSequencerNodeMap);
        this.setAudio(TrebbleAudioFactory.getInstance().createTrebbleAudioFromSerializedData(serializedData.audio));
        this.getAudioSegment().set(serializedData.audioSegment);
        this.getEndWrapSequencerNode().getAudioSegment().set(serializedData.audioSegment);
    },


    createEndWrapSequencerNode : function(attributes, options, createdFromClone, objBeingCloned, sequencerNodeCidToCloneNodeMap){
        if(createdFromClone && objBeingCloned && sequencerNodeCidToCloneNodeMap &&  objBeingCloned.getEndWrapSequencerNode() && objBeingCloned.getEndWrapSequencerNode().cid && sequencerNodeCidToCloneNodeMap[objBeingCloned.getEndWrapSequencerNode().cid]){
            return sequencerNodeCidToCloneNodeMap[objBeingCloned.getEndWrapSequencerNode().cid];
        }
        return new EndMusicWrapSequencerNode(attributes, options);
    },

    setAudio : function(trebbleAudio){
        this.set("audio",trebbleAudio);
        this.getEndWrapSequencerNode().set("audio",trebbleAudio);
        this.getAudioSegment().setAudioUrl(trebbleAudio.getAudioUrl());
        this.getEndWrapSequencerNode().getAudioSegment().setAudioUrl(trebbleAudio.getAudioUrl());
    },

    getAudio :function(){
        return this.get("audio");
    },

    setAudioAndAudioSegmentStartTimeAndEndTime : function(audioAndAudioSegmentStartTimeAndEndTime){
        this.set("audio",audioAndAudioSegmentStartTimeAndEndTime.trebbleAudio);
        this.getEndWrapSequencerNode().set("audio",audioAndAudioSegmentStartTimeAndEndTime.trebbleAudio);
        this.getAudioSegment().setAudioUrl(audioAndAudioSegmentStartTimeAndEndTime.trebbleAudio.getAudioUrl());
        this.getEndWrapSequencerNode().getAudioSegment().setAudioUrl(audioAndAudioSegmentStartTimeAndEndTime.trebbleAudio.getAudioUrl());
        this.getAudioSegment().setStartTime(audioAndAudioSegmentStartTimeAndEndTime.startTime);
        this.getEndWrapSequencerNode().getAudioSegment().setStartTime(audioAndAudioSegmentStartTimeAndEndTime.startTime);
        this.getAudioSegment().setEndTime(audioAndAudioSegmentStartTimeAndEndTime.endTime);
        this.getEndWrapSequencerNode().getAudioSegment().setEndTime(audioAndAudioSegmentStartTimeAndEndTime.endTime);
    },

    getAudioAndAudioSegmentStartTimeAndEndTime :function(){
        const audioAndAudioSegmentStartTimeAndEndTime = {};
        audioAndAudioSegmentStartTimeAndEndTime.trebbleAudio = this.get("audio");
        audioAndAudioSegmentStartTimeAndEndTime.startTime = this.getAudioSegment().getStartTime();
        audioAndAudioSegmentStartTimeAndEndTime.endTime = this.getAudioSegment().getEndTime();
        return audioAndAudioSegmentStartTimeAndEndTime;
    },

    isModifyingAudioBufferFromWrappedNodeAudioSegment : function(){
        return false;
    },

    isCreatingAudioSegmentThatWillBeMergeWithWrappedNodeAudioSegment : function(){
        return true;
    },

    getCurrentNodeMargin : function(){
        return this.getIntroPaddingInMilliseconds();
    },

    setIntroPaddingInMilliseconds : function(introPaddingInMilliseconds){
        this.set("introPaddingInMilliseconds",introPaddingInMilliseconds);
        this.getEndWrapSequencerNode().set("introPaddingInMilliseconds",introPaddingInMilliseconds);
    },

    getIntroPaddingInMilliseconds : function(){
        return this.get("introPaddingInMilliseconds");
    },

    setOutroPaddingInMilliseconds : function(outroPaddingInMilliseconds){
        this.set("outroPaddingInMilliseconds",outroPaddingInMilliseconds);
        this.getEndWrapSequencerNode().set("outroPaddingInMilliseconds",outroPaddingInMilliseconds);
    },

    _convertGainToPercentage : function(gainValue){
        const minDb = -40;
        const dB = (1 - gainValue) * minDb;
        const percentage =  AudioBufferUtils.getInstance().calcPerc(dB, minDb, 0);
        //return percentage /100;
        //return gainValue*4;
        return gainValue;
    },

    _convertPercentageToGain : function(percentage){
        const minDb = -40;
        const gainValue = 1 - AudioBufferUtils.getInstance().calcDb(percentage * 100, minDb, 0)/minDb;
        //return gainValue;
        //return percentage/4;
        return percentage;
    },

    getOutroPaddingInMilliseconds : function(){
        return this.get("outroPaddingInMilliseconds");
    },

    setIntroFadeInMilliseconds : function(introFadeInMilliseconds){
        if(this.getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.FADE_START)){
            this.getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.FADE_START).setFadeDuration(introFadeInMilliseconds);
        }
        if(this.getEndWrapSequencerNode().getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.FADE_START)){
            this.getEndWrapSequencerNode().getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.FADE_START).setFadeDuration(introFadeInMilliseconds);
        }
        this.set("introFadeInMilliseconds",introFadeInMilliseconds);
        this.getEndWrapSequencerNode().set("introFadeInMilliseconds",introFadeInMilliseconds);
    },

    getIntroFadeInMilliseconds : function(){
        if(this.getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.FADE_START)){
            return this.getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.FADE_START).getFadeDuration();
        }else{
            return 0;
        }
    },

    setOutroFadeInMilliseconds : function(outroFadeInMilliseconds){
        if(this.getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.FADE_END)){
            this.getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.FADE_END).setFadeDuration(outroFadeInMilliseconds);
        }
        if(this.getEndWrapSequencerNode().getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.FADE_END)){
            this.getEndWrapSequencerNode().getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.FADE_END).setFadeDuration(outroFadeInMilliseconds);
        }
        this.set("outroFadeInMilliseconds",outroFadeInMilliseconds);
        this.getEndWrapSequencerNode().set("outroFadeInMilliseconds",outroFadeInMilliseconds);
    },

    getOutroFadeInMilliseconds : function(){
        if(this.getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.FADE_END)){
            return this.getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.FADE_END).getFadeDuration();
        }else{
            return 0;
        }
    },

    setMainVolumePercentage : function(mainVolumePercentage){
        if(this.getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.VOLUME)){
            this.getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.VOLUME).setGainValue(this._convertPercentageToGain(mainVolumePercentage));
        }
        if(this.getEndWrapSequencerNode().getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.VOLUME)){
            this.getEndWrapSequencerNode().getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.VOLUME).setGainValue(this._convertPercentageToGain(mainVolumePercentage));
        }
        this.set("mainVolumePercentage",mainVolumePercentage);
        this.getEndWrapSequencerNode().set("mainVolumePercentage",mainVolumePercentage);
    },

    getMainVolumePercentage : function(){
        if(this.getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.VOLUME)){
            return this._convertGainToPercentage(this.getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.VOLUME).getGainValue());
        }else{
            return 1;
        }
    },

    setLoopToMatchWrapNodeDuration : function(loopToMatchWrapNodeDuration){
        this.set("loopToMatchWrapNodeDuration",loopToMatchWrapNodeDuration);
        this.getEndWrapSequencerNode().set("loopToMatchWrapNodeDuration",loopToMatchWrapNodeDuration);
    },

    getLoopToMatchWrapNodeDuration : function(){
        return this.get("loopToMatchWrapNodeDuration");
    },

    setDucking : function(ducking){
        if(this.getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.SIDECHAIN_COMPRESSOR)){
            this.getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.SIDECHAIN_COMPRESSOR).setDisabled(!ducking);
        }
        if(this.getEndWrapSequencerNode().getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.SIDECHAIN_COMPRESSOR)){
            this.getEndWrapSequencerNode().getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.SIDECHAIN_COMPRESSOR).setDisabled(!ducking);
        }
        this.set("ducking",ducking);
        this.getEndWrapSequencerNode().set("ducking",ducking);
    },

    getDucking : function(){
        //return this.get("ducking");
        if(this.getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.SIDECHAIN_COMPRESSOR)){
            return !this.getBuiltInAudioFilterByName(BUILT_IN_AUDIO_FILTERS_NAMES.SIDECHAIN_COMPRESSOR).isDisabled();
        }else{
            return false;
        }
    },

    getContent:function(){
        return `<span style="display: inline-flex;align-items: center;">  <span class="icon pe-7s-music"></span> <span class="icon soundwave-drag-indicator drag_icon" style="font-size: 70%"></span>  </span>`;
    },

    





});

StartMusicWrapSequencerNode.CLASSNAME = CLASSNAME;

export default StartMusicWrapSequencerNode; 