import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import ArtistOptionMenuView from "views/artistOptionMenu/ArtistOptionMenuView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import ti18n from "i18n!nls/ArtistOptionMenui18n";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const ArtistOptionMenuController = Chaplin.Controller.extend({

    initialize : function(artist) {
        this.model = artist
        _.extend(this, Backbone.Events);
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    addAllSongsToQueue : function(){
        const songUris = [];
        const albums = this.model.get("albums");
        for(let albumIndex in albums){
            const albumModel =  albums[albumIndex];
            for(let songIndex in albumModel.songs){
                const songJson =  albumModel.songs[songIndex];
                songUris.push(songJson.uri);
                
            }
        }
        const arrayOfAlbumSongs = PersistentModels.getInstance().getSongCollection().getSongsWithURI(songUris);
        PlayerService.getInstance().addSongsToQueue(arrayOfAlbumSongs);
    },
    
    addSongToQueue : function(){
        const remoteAndLocalSongs = [];
        const albumJsonArray = this.model.get("albums");
        if(albumJsonArray)
        {	
            for(let albumIndex in albumJsonArray){
                const albumJson =  albumJsonArray[albumIndex];
                if(albumJson)
                {
                    for(let songIndex in albumJson.songs ){
                        const songJson =  albumJson.songs[songIndex];
                        if(songJson)
                        {	
                            let songFound = PersistentModels.getInstance().getSongModelBySongIdFromMyLibraryOrFavorites( songJson.uri);
                            if(!songFound)
                            {
                                songFound = Utils.getInstance().getModelFromSongJson(songJson);
                            }
                            if(songFound){
                                remoteAndLocalSongs.push(songFound);
                            }
                        }
                        
                    }
                }
            }
        }
        PlayerService.getInstance().addSongsToQueue(remoteAndLocalSongs);
    },
    
    playSimilarFromLibrary : function(){
        $.mobile.loading( 'show');
        const isMusicCurrentlyPlaying =  PlayerService.getInstance().isMusicPlaying();
        PlayerService.getInstance().generateSimilarLocalSongPlaylistAndAddToQueue(this.model).then(function(){
            if(!isMusicCurrentlyPlaying)
            {
                PlayerService.getInstance().playFirstSongInQueue();
            }
             $.mobile.loading( "hide" );
        }).catch(function(error){
            window.alertErrorMessage(error);
             $.mobile.loading( "hide" );
        });
        
        
    },
    
    playSimilarFromOtherUserLibraries : function(){
        $.mobile.loading( 'show');
        const isMusicCurrentlyPlaying =  PlayerService.getInstance().isMusicPlaying();
        PlayerService.getInstance().generateSimilarSongPlaylistFromOtherUsersAndAddToQueue(this.model, true).then(function(){
            if(!isMusicCurrentlyPlaying)
            {
                PlayerService.getInstance().playFirstSongInQueue();
            }
             $.mobile.loading( "hide" );
        }).catch(function(error){
            window.alertErrorMessage(error);
             $.mobile.loading( "hide" );
        });
        
        
    },
    
    _buildPlaylistNameFromLocalPlaylistGeneratedFromArtist : function(){
        let playlistName =  "";
        if(this.model)
        {
            playlistName  = window.getI18n(ti18n,"LOCAL_SONGS_SIMILAR_TO")+" " + this.model.get("artistName");
        }else{
            playlistName  = window.getI18n(ti18n,"LOCAL_SONGS_SIMILAR_TO_ARTIST");
        }

        return playlistName;
    },
    

    
    browseArtist : function(){
        PersistentModels.getInstance().getRouter().showArtistFullDetailPage(this.model.get("artistId") ,  this.model.get("artistName"), this.model, true);
    },
    

    onViewRemoved:function(){
        this.stopListening();
    },

    

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
            
                this.view = new ArtistOptionMenuView({
                    "model" : this.model
                });
                this.listenTo(this.view, "addAllSongsToQueue",this.addAllSongsToQueue);
                this.listenTo(this.view, "playSimilar",this.playSimilarFromLibrary);
                this.listenTo(this.view, "playSimilarFromFollowers",this.playSimilarFromOtherUserLibraries);
                this.listenTo(this.view, "browseArtist",this.browseArtist);
                this.listenTo(this.view, "remove", this.onViewRemoved);
                this.view.render();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default ArtistOptionMenuController;