import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Capsule from "models/Capsule";
import TrebbleCapsuleListView from "views/common/TrebbleCapsuleListView";
import SongOptionMenuModel from "models/SongOptionMenuModel";
import Capsules from "collections/Capsules";
import SongOptionMenuView from "views/SongOptionMenuView";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";

const CapsuleListView = Backbone.View.extend({
    
    
    
    
    
    initialize: function(options) {
        this.displayedCollection = this.collection;
        this.collection.bind("add", this._onCapsuleAddedOrRemovedInLibrary, this);
        this.collection.bind("change", this._onCapsuleUpdatedInLibrary, this);
        this.collection.bind("remove", this._onCapsuleAddedOrRemovedInLibrary, this);
        this.collection.bind("reset", this._onCapsuleAddedOrRemovedInLibrary, this);
        this._delayOnCollectionUpdate = (options && options.delayOnCollectionUpdate) ? options.delayOnCollectionUpdate :  1000;
        this._resizeToAvailableScreenSizeOnOrientationChange = options  ? options.resizeToAvailableScreenSizeOnOrientationChange :  true;
        this._showHeader = options  ? options.showHeader :  false;
        this._headerText= options  ? options.headerText :  "";
        this._showFooter= options  ? options.showFooter :  false;
        this._footerText= options  ? options.footerText :  "";
        this._customInfoBoxInfoParams = options ? options.customInfoBoxInfoParams : null;
        this._loadHandler= (options && options.loadHandler)  ? options.loadHandler :  null;
        this._heightMargin= (options && options.heightMargin)  ? options.heightMargin :  null;
        this._showEmtptyInfoBox = (options && options.showEmtptyInfoBox)  ? options.showEmtptyInfoBox :  null;
        this._resizePlaceHolderElem =  options  ? options.resizePlaceHolderElem :  false;
        this._doNotSortDisplayedCollection =  options  ? options.doNotSortDisplayedCollection :  true;
        this._doNotShowLoadingWidgetDuringListLoading = options  ? options.doNotShowLoadingWidgetDuringListLoading :  false;
        this._listenerViewportKey = "capsuleListView" + (new Date()).getTime();
        PersistentModels.getInstance().getRouter().registerForViewportHeightChange(this._listenerViewportKey,this._onOrientationChangeFunction.bind(this));
 
    },
    
    _onOrientationChangeFunction : function(newViewPortHeigh, forceResizing) {
        if(this.$el.is(":visible") || forceResizing){
            if(this._heightMargin){
                newViewPortHeigh = newViewPortHeigh - this._heightMargin;
            }
            if (!this._resizePlaceHolderElem && this.el) {
                this.el.style.height = (newViewPortHeigh ) + "px"; 
            }
            this.infiniteListView._onRootElementResize();
        }
    },
    
    
   filterList: function(searchCharaterers) {
        /*
        this.appliedSearchCharacters = searchCharaterers;
        searchFunction = (function() {
        $.mobile.loading("show");
            this.infiniteListView.setUpdating(true);
            const songCollection = new SongsCollection(this.collection.searchOnTitleAndArtist(this.appliedSearchCharacters));
            songCollection.updateContext(this.displayedCollection.getContext());
            this._changeSongList(songCollection);
            this.pendingSearchFunctionTimerId = null;
            $.mobile.loading("hide");
        }).bind(this);
        if (this.pendingSearchFunctionTimerId) 
        {
            window.clearTimeout(this.pendingSearchFunctionTimerId);
        }
        this.pendingSearchFunctionTimerId = window.setTimeout(searchFunction,  this._delayOnCollectionUpdate);
    */
    },
    
    clearSearchfilter: function() {
        /*
        this.appliedSearchCharacters = null;
        searchFunction = (function() {
            this.infiniteListView.setUpdating(true);
            const songCollection = new SongsCollection(this.collection.searchOnTitleAndArtist(""));
            songCollection.updateContext(this.displayedCollection.getContext());
            this._changeSongList(songCollection);
            this.pendingSearchFunctionTimerId = null;
        }).bind(this);
        if (this.pendingSearchFunctionTimerId) 
        {
            window.clearTimeout(this.pendingSearchFunctionTimerId);
        }
        this.pendingSearchFunctionTimerId = window.setTimeout(searchFunction, 0);
    */
    },
  
    
    _changeCapsuleList: function(newCapsuleModels) {
        this.displayedCollection = newCapsuleModels;
        if(!this._doNotSortDisplayedCollection)
        {
            this.displayedCollection.sort();
        }
        this.infiniteListView.updateModels(this.displayedCollection.toJSON());
        this.infiniteListView.setUpdating(false);
    },
    
    _updateCapsuleInCapsuleList: function(capsuleUpdateModel) {
        this.infiniteListView.updateModel(capsuleUpdateModel.toJSON());
        this.infiniteListView.setUpdating(false);
    },
    
    _getOnListItemTapHandler: function() {
        return (function(capsuleJson, evnt, item) {
            this.trigger("itemTaped", this._getCapsuleModelFromCapsuleJson(capsuleJson) ,this.displayedCollection );
        }).bind(this);
    },
    

    
    _getCapsuleModelFromCapsuleJson : function(capsuleJson){
        return this.collection.get(capsuleJson.capsuleId);
    
    },
    
    
    _getOnListButtonTappedHandler: function() {
        return (function(capsuleJson, evnt, item) {
            this.trigger("itemOptionBtnTapped", this._getCapsuleModelFromCapsuleJson(capsuleJson));
        }).bind(this);
    },
    
    
    isSearchApplied : function(){
        return this.appliedSearchCharacters != null;
    },
    
    getAppliedSearchCharacters : function(){
        return this.appliedSearchCharacters;
    }, 
    
    _onCapsuleAddedOrRemovedInLibrary : function(){
        if(this.isSearchApplied())
        {
            this.filterList(this.getAppliedSearchCharacters());
        }else{
            this.infiniteListView.setUpdating(true);
            this._changeCapsuleList(this.collection);
        }
        this.trigger("modelChange", this.collection);
    },
    
    _onCapsuleUpdatedInLibrary : function(capsuleUpdateModel){
        if(this.isSearchApplied())
        {
            this.filterList(this.getAppliedSearchCharacters());
        }else{
            this.infiniteListView.setUpdating(true);
            this._updateCapsuleInCapsuleList(capsuleUpdateModel);
        }
        this.trigger("modelChange", this.displayedCollection);
    },
    
    
    isCollectionEmpty : function(){
        if(this.collection)
        {
            return this.collection.length == 0;
        }else{
            return true;
        }
    },
    
    _onContentLoading : function(){
        if(!this._doNotShowLoadingWidgetDuringListLoading){
            $.mobile.loading( 'show');
        }
    },
    
    _onContentLoaded : function(){
        if(!this._doNotShowLoadingWidgetDuringListLoading){
            $.mobile.loading( "hide" );
        }
    },
    
    _onContentFailedLoading : function(error){
        if(!this._doNotShowLoadingWidgetDuringListLoading){
            $.mobile.loading( "hide" );
            console.error("Page content failed loading. Error:"+error);
        }
    },
    
    load : function(params){
        if(this._loadHandler){
            this._onContentLoading();
            try{
                this.infiniteListView.setUpdating(true);
                return this._loadHandler(params).then((function(arrayOfCapsules){
                    this.collection.reset(arrayOfCapsules);
                    this.infiniteListView.setUpdating(false);
                    this._onContentLoaded();
                }).bind(this)).catch((function(error){
                    this._onContentFailedLoading(error);
                    this.infiniteListView.setErrorMessage(error);
                }).bind(this));
            }catch(error){
                this._onContentFailedLoading(error);
                this.infiniteListView.setErrorMessage(error);
            }
        }else{
            return RSVP.Promise.resolve();
        }
    },
    
    updateInfoBoxIfApplicable : function(iconClass,message,helpMessage,infoBoxClassName,actionButtonLabel,actionButtonIconClass){
        if(this.infiniteListView){
            this.infiniteListView.update(iconClass,message,helpMessage,infoBoxClassName,actionButtonLabel,actionButtonIconClass);
        }
    },
   
    _onNodeRemovedFromDom : function(){
        this._removeFromDom  = true;
        PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._listenerViewportKey);
    },
    
    render: function() {

        let newViewPortHeigh = PersistentModels.getInstance().getRouter().getViewportHeight();
        if(this._heightMargin){
            newViewPortHeigh = newViewPortHeigh - this._heightMargin;
        }
        this.$el.html(' <div id="capsuleList"  style="height:' +(newViewPortHeigh + "px") + ';overflow:hidden;width:100%"></div>');
        this.setElement(this.$el.find("div").first());
        const listParams = {};
        if(!this._doNotSortDisplayedCollection)
        {
            this.displayedCollection.sort();
        }
        listParams.model = this.displayedCollection.toJSON();
        listParams.collection = this.displayedCollection;
        listParams.listRootElement = this.el;
        listParams.defaultNumberOfElements = 80;
        listParams.defaultHeightElement = 354;
        listParams.placeHolderHeight =  PersistentModels.getInstance().getRouter().getViewportHeight();
        listParams.resizePlaceHolderElem =  this._resizePlaceHolderElem;
        listParams.showEmtptyInfoBox = this._showEmtptyInfoBox;
        listParams.customInfoBoxInfoParams = this._customInfoBoxInfoParams;
        const infiniteListView = new TrebbleCapsuleListView(listParams);
        this.infiniteListView = infiniteListView;
        infiniteListView.addOnItemTapHandler(this._getOnListItemTapHandler());
        infiniteListView.render();
        this._onOrientationChangeFunction(PersistentModels.getInstance().getRouter().getViewportHeight(), true);
        this.$el.on("remove",this._onNodeRemovedFromDom.bind(this));
        return this;
    }

});

export default CapsuleListView;
