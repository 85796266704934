import Backbone from "backbone";

const Artist=  Backbone.Model.extend({
     idAttribute: "artistId",
     
    "default": {
        "title": "",
        "artist": "",
        "idifiedTitle": "",
        "idifiedArtist": false,
        "idified": false,
        "idifiedSuccess": false,
    },



     get: function (attr) {

            if(attr ==="title" && window.trebble.config.showIdifiedAlbumTitle )
            {
                return Backbone.Model.prototype.get.call(this, "idifiedTitle");
            }
            if(attr ==="artist" && window.trebble.config.showIdifiedArtistName)
            {
                return Backbone.Model.prototype.get.call(this, "idifiedArtist");
            }

        return Backbone.Model.prototype.get.call(this, attr);
    },



});
export default Artist;