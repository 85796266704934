import Backbone from "backbone";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const Queue = Backbone.Model.extend({
    
    initialize : function(options){
        this._currentCursorPosition = 0;
        this._shuffle = false;
        this._loop = false;
        this._playlist = null;
    },



    getCurrentCursorPostionPromise : function(){
        return RSVP.Promise.resolve(this._currentCursorPosition);
    },
    
    getCurrentSongSocialInfoIfApplicable : function(){
        if(this._playlist && this._playlist.getSocialInfoForSongWitUri){
            return this._playlist.getSongAtPosition(this._currentCursorPosition).then((function(currentSong){
                const currentSongUri = currentSong? currentSong.get("uri") : null;
                return this._playlist.getSocialInfoForSongWitUri(currentSongUri);	
            }).bind(this));

        }else{
            return RSVP.Promise.resolve(null);
        }
    },
    
    getCurrentCommentSummaryIfApplicable : function(){
        if(this._playlist && this._playlist.getCommentSummaryForSongWitUri){
            return this._playlist.getSongAtPosition(this._currentCursorPosition).then((function(currentSong){
                const currentSongUri = currentSong? currentSong.get("uri") : null;
                return this._playlist.getCommentSummaryForSongWitUri(currentSongUri);	
            }).bind(this));

        }else{
            return RSVP.Promise.resolve(null);
        }
    },
    
    getCurrentSongRefSummaryIfApplicable : function(){
        if(this._playlist && this._playlist.getSongRefSummaryForSongWitUri){
            return this._playlist.getSongAtPosition(this._currentCursorPosition).then((function(currentSong){
                const currentSongUri = currentSong? currentSong.get("uri") : null;
                return this._playlist.getSongRefSummaryForSongWitUri(currentSongUri);	
            }).bind(this));

        }else{
            return RSVP.Promise.resolve(null);
        }
    },
    
    getCurrentCapsuleSummaryIfApplicable : function(){
        if(this._playlist && this._playlist.getCapsuleSummaryForSongWitUri){
            return this._playlist.getSongAtPosition(this._currentCursorPosition).then((function(currentSong){
                const currentSongUri = currentSong? currentSong.get("uri") : null;
                if(Utils.getInstance().isCapsuleUri(currentSongUri)){
                    //current audio playing is a capsule
                    return currentSong;
                }else{
                    return this._playlist.getCapsuleSummaryForSongWitUri(currentSongUri);	
                }
                
            }).bind(this));

        }else{
            return RSVP.Promise.resolve(null);
        }
    },
    
    getSongOnCurrentCursorPositionPromise : function(){
        if(this._playlist){
            return this._playlist.getSongAtPosition(this._currentCursorPosition);
        }else{
            return RSVP.Promise.resolve(null);
        }
    },
    
    getSongOnNextCursorPositionPromise : function(increment){
        if(this._playlist){ 
            const currentCursorPosition = this._currentCursorPosition? this._currentCursorPosition: 0;

            if(this._currentCursorPosition +  1 + increment < this._playlist.length){
                return this._playlist.getSongAtPosition(currentCursorPosition + 1 + increment);
            }else{
                return RSVP.Promise.resolve(null);
            }

        }else{
            return RSVP.Promise.resolve(null);
        }
    },
    
    getSongOnPreviousCursorPositionPromise : function(increment){
        if(this._playlist){ 
            const currentCursorPosition = this._currentCursorPosition? this._currentCursorPosition: 0;
            if(this._currentCursorPosition - 1 - increment >= 0){
                return this._playlist.getSongAtPosition(currentCursorPosition - 1 - increment);
            }else{
                return RSVP.Promise.resolve(null);
            }
        }else{
            return RSVP.Promise.resolve(null);
        }
    },
    
    moveCursorToNextPositionPromise : function(ignoreLoopState){
        if(ignoreLoopState || (!ignoreLoopState && !this._loop))
        {
            return this._playlist.getSize().then((function(playlistSize){
                if(playlistSize == -1){
                    this._setCurrentCursorPosition(this._currentCursorPosition + 1);
                    return this._playlist.getSongAtPosition(this._currentCursorPosition)
                }else{
                    if(this._currentCursorPosition + 1 < playlistSize)
                    {
                        this._setCurrentCursorPosition(this._currentCursorPosition + 1);
                        return	this._playlist.getSongAtPosition(this._currentCursorPosition)
                    }else{
                        return RSVP.Promise.resolve(null);
                    }
                }
            }).bind(this));
        }else{
            return this._playlist.getSongAtPosition(this._currentCursorPosition);
        }
    },
    
    moveCursorToPreviousPositionPromise : function(){
        if(this._currentCursorPosition != 0)
        {
            this._setCurrentCursorPosition(this._currentCursorPosition - 1);
            return	this._playlist.getSongAtPosition(this._currentCursorPosition)
        }else{
            return	this._playlist.getSongAtPosition(this._currentCursorPosition);
        }
    },
    
    moveCursorToPositionPromise : function(cursorNewPosition){
        return this._playlist.getSize().then((function(playlistSize){
            if(playlistSize == -1){
                this._setCurrentCursorPosition(cursorNewPosition);
                return this._playlist.getSongAtPosition(this._currentCursorPosition)
            }else{
                if(cursorNewPosition < playlistSize)
                {
                    this._setCurrentCursorPosition(cursorNewPosition);
                    return	this._playlist.getSongAtPosition(this._currentCursorPosition)
                }else{
                    return RSVP.Promise.resolve(null);
                }
            }
        }).bind(this));
    },
    
    moveCursorToSongWithUriPromise : function(songUri){
        const songsFound =  this.getPlaylist().where({uri: songUri});
        const songModel =  (songsFound && songsFound.length > 0) ? songsFound[0] : null;
        const songIndex =  this.getPlaylist().indexOf(songModel);
        if(songIndex > -1)
        {
            return this.moveCursorToPositionPromise(songIndex)
        }else{
            return RSVP.Promise.resolve();
        }
    },
    
    setPlaylist : function(newPlaylist, refresh){
        this._playlist = newPlaylist;
        this._currentCursorPosition = 0;
        this.stopListening();
        this.listenTo(newPlaylist,"onSongsAdded", this._onSongsAddedToPlaylist.bind(this) );
        this.listenTo(newPlaylist,"playlistNameChanged", this._onPlaylistNameChange.bind(this) );
        this.listenTo(newPlaylist,"onSongsRemoved", this._onSongsRemovedToPlaylist.bind(this) );
        this.listenTo(newPlaylist,"startedLoading", this._onPlaylistStartedLoading.bind(this) );
        this.listenTo(newPlaylist,"finishLoading", this._onPlaylistFinishLoading.bind(this) );
        this.listenTo(newPlaylist,"reset", this._onPlaylistReset.bind(this) );
        this.trigger("playlistChanged", newPlaylist);
        this.trigger("newSongAddedToPlayerQueue");
        if(refresh && this._playlist && this._playlist.refresh){
            return this._playlist.refresh();
        }else{
            return RSVP.Promise.resolve();
        }
    },


    _onPlaylistStartedLoading: function(){
        this.trigger("playlistStartedLoading",this._playlist)
    },

    _onPlaylistFinishLoading: function(){
        this.trigger("playlistFinishLoading",this._playlist)
    },

    clear : function(){
        this._playlist = null;
        this._currentCursorPosition = 0;
        this.stopListening();
        this.trigger("playlistChanged", null);
    },
    
    getPlaylistName : function(){
        return this._playlist.getName();
    },
    
    refresh : function(){
        if(this._playlist.refresh)
        {
            this.trigger("refreshing");
            return this._playlist.refresh().then((function(){
                this.trigger("refreshed",this._playlist);
            }).bind(this)).catch(function(error){
                this.trigger("failedRefreshing", error);
                throw error;
            });
        }else{
            return RSVP.Promise.resolve();
        }
    },
    
    canBeRefresh : function(){
        if(this._playlist.refresh)
        {
            return true;
        }else{
            return false;
        }
    },
    
    canBeTuned : function(){
        if(this._playlist.tune)
        {
            return true;
        }else{
            return false;
        }
    },
    
    getAvailableTagGroupFilters : function(){
        return this._playlist.getAvailableTagGroupFilters();
    },
    
    
    _onPlaylistNameChange : function(newPlaylistName)
    {
        this.trigger("playlistNameChanged", newPlaylistName);
    },
    
    _onSongsAddedToPlaylist : function(songsAdded){
        this.trigger("onSongsAddedToQueuePlaylist", songsAdded);
    },
    
    _onSongsRemovedToPlaylist : function(songsRemoved){
        this.trigger("onSongsRemovedToQueuePlaylist", songsRemoved);
    },
    
    _onPlaylistReset : function(){
        this.trigger("playlistReset");
    },
    
    getPlaylist : function(){
        return this._playlist;
    },
    
    getAllLoadedSongs : function(){
        return this._playlist.getAllLoadedSongs();
    },
    
    setShuffleMode : function(shuffle){
        this._shuffle = shuffle;
        if(this._playlist)
        {
            if(this._shuffle){
                this._playlist.shuffleOn();
            }else{
                this._playlist.shuffleOff();
            }	
        }
    },
    
    getContext: function(options){
        const context  = (this._playlist && this._playlist.getContext)? this._playlist.getContext(options): null;
        return context;
    },
    
    getShuffleMode : function(){
        return this._shuffle;
    },
    
    setLoopMode : function(loop){
        this._loop = loop;
    },
    
    getLoopMode : function(){
        return this._loop;
    },
    
    _setCurrentCursorPosition : function(newPosition){
        this._currentCursorPosition = newPosition;
    },
    
    getSelectedTagFilters : function(){
        if(this._playlist){
            return this._playlist.getSelectedTagFilters();
        }
    },
    
    setSelectedTagFilter : function(tagGroupsArrayFilter){
        if(this._playlist){
            return this._playlist.setSelectedTagFilter(tagGroupsArrayFilter);
        }
    },
    
    tune : function(tuneOptions){
        if(this._playlist){
            return this._playlist.tune(tuneOptions);
        }
    },
    
    
});

export default Queue;