import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import OptionPopupView from "views/common/OptionPopupView";
import popupTemplate from 'text!../../../templates/audioEditor/TrimSilencesContextMenuTemplate.html';
import ti18n from "i18n!nls/Sequenceri18n";
import Mousetrap from 'mousetrap';

const TrimSilencesContextMenuView = OptionPopupView.extend({

    initialize : function(options) {
        const viewOptions =  {};
        this.model = options.model;
        this.elementSource$el = options.elementSource$el;
        this.contentView =  options.contentView;
        viewOptions.contentView = this.contentView;
        viewOptions.contentPosition = "center";
        viewOptions.elementSource$el = this.elementSource$el;
        viewOptions.showAsMenuIfInBrowser = true;
        viewOptions.alwaysShowAsFloatingMenu = true;
        viewOptions.customPopupPosition = options.popupPosition? options.popupPosition: "right middle";
        viewOptions.id = "SequencerAutomatedOperationsPopup";
        viewOptions.customDropObjClass = "SequencerAutomatedOperationsPopupDrop";
        viewOptions.hideHeading = true;
        viewOptions.onBeforeClose = this.onBeforeClose.bind(this);
        OptionPopupView.prototype.initialize.call(this, viewOptions);
    },

    afterRendering : function(){

    },

    onBeforeClose : function(){
        if(this._mousetrapObj){
            this._mousetrapObj.unbind("enter");
        }
        return true;
    },

    onAfterPageShow : function(){
        this.$el.addClass("SequencerAutomatedOperationsPopup");
    },



});

export default TrimSilencesContextMenuView;