import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import OptionPopupView from "views/common/OptionPopupView";
import ti18n from "i18n!nls/ConfigureBackgroundMusici18n";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import Utils from "models/helper/Utils";
import ConfigureBackgroundMusicPopupContentTemplate from 'text!../../../templates/configureBackgroundMusic/ConfigureBackgroundMusicPopupContentTemplate.html';

const Rollout = RolloutHelper.getInstance();
const SHOW_YOUR_IMPORT_OPTION_VALUE ="showYourImports";
const ConfigureBackgroundMusicPopupView = OptionPopupView.extend({

    "pageName": "Choose Background Music",

    initialize: function(options) {
        const viewOptions = {};

        const contentView = {};
        const contentViewTemplate = _.template(ConfigureBackgroundMusicPopupContentTemplate);
        let templateParams = options;
        if(!templateParams){
            templateParams= {};
        }
        templateParams.ti18n =  ti18n;
        templateParams.Rollout = Rollout;
        this.trebbleAudioListView = options.trebbleAudioListView;
        contentView.$el = $(contentViewTemplate(templateParams));
        viewOptions.contentView = contentView;
        viewOptions.contentPosition = "center";
        this._categoryListItemCollectionToDisplay = null;

        this._selectedCategoryButton$el = null;

        viewOptions.id = "ChooseBackgroundMusicPopup";


        OptionPopupView.prototype.initialize.call(this, viewOptions);
    },

    setCategoryIdToCategorylabelMapAvailable : function(categoryIdToCategoryLabelMap){
        this.background_music_category_fld$el.html("");
        this.backgroundMusicCategoryList$el.append(this._getUserUploadCategoryButton())
        this.background_music_category_fld$el.append("<option value='"+SHOW_YOUR_IMPORT_OPTION_VALUE+"'    >"+window.getI18n(ti18n, "YOUR_IMPORTS")+"</option>");
        const categoryLabelsArray = [];
        const categoryLabelToCategoryId = {}
        for(let categoryId in categoryIdToCategoryLabelMap){
            const categoryLabel = categoryIdToCategoryLabelMap[categoryId];
            categoryLabelsArray.push(categoryLabel);
            categoryLabelToCategoryId[categoryLabel]= categoryId;
        }
        categoryLabelsArray.sort();
        for(let i =0; i < categoryLabelsArray.length; i++){
            const categoryLabel = categoryLabelsArray[i];
            const categoryId = categoryLabelToCategoryId[categoryLabel];
            const categoryMusicTagButton$el = this._getHtmlForCategory(categoryId, categoryLabel)
            this.backgroundMusicCategoryList$el.append(categoryMusicTagButton$el)
            this.background_music_category_fld$el.append("<option value='"+categoryId+"'    >"+categoryLabel+"</option>");
        }
    },
    showUserImports : function(){
        this.showSelectedCategory(this.$el.find("#your_imports_tag_button"), true);
        //this.background_music_category_fld$el.val(SHOW_YOUR_IMPORT_OPTION_VALUE);
        Utils.getInstance().setJQMSelectFieldValue(this.background_music_category_fld$el, SHOW_YOUR_IMPORT_OPTION_VALUE);
        //this.background_music_category_fld$el.find("[value="+SHOW_YOUR_IMPORT_OPTION_VALUE+"]").attr("selected", selected)
        this.trigger("userUploadsSelected");
    },

    _setUserUploadDisplayed : function(userUploadDisplayed){
        this.$el.find(".choose_background_music_popup_content_wrapper").attr("userUploadDisplayed",userUploadDisplayed);
    },

    _setCategory$elAsSelected : function(list_tag_button$el){
        if(this._selectedCategoryButton$el){
            this._selectedCategoryButton$el.removeClass("selected");
        }
        this._selectedCategoryButton$el = list_tag_button$el;
        this._selectedCategoryButton$el.addClass("selected");

    },

    showSelectedCategory: function(categoryListTagButton$el, userUploadDisplayed ){
       this._setCategory$elAsSelected(categoryListTagButton$el);
       this._setUserUploadDisplayed(userUploadDisplayed);
   },


   _getUserUploadCategoryButton: function(){
    const categor$el = $("<div class='list_tag_button' id='your_imports_tag_button'><div class='itemLabel'>"+window.getI18n(ti18n, "YOUR_IMPORTS")+"</div></div>");
    categor$el.on("click", (function(){
        this.trigger("userUploadsSelected");
        this.showSelectedCategory(categor$el, true);

    }).bind(this));
    return categor$el;
},

_getHtmlForCategory: function(categoryId, categoryLabel){
    const categor$el = $("<div class='list_tag_button' ><div class='itemLabel'>"+categoryLabel+"</div></div>");
    categor$el.on("click", (function(){
        this.trigger("backgroundMusicCategorySelected", categoryId);
        this.showSelectedCategory(categor$el, false);
    }).bind(this));
    return categor$el;
},


isLoading:function(isLoading){
    this.$el.find(".choose_background_music_popup_content_wrapper").attr("isLoading",isLoading)
},

_onCategorySelectionChangedOnSelectField : function(){
    const categoryId =  this.$el.find("#background_music_category_fld option:selected").val();
    if(categoryId == SHOW_YOUR_IMPORT_OPTION_VALUE){
        this.trigger("userUploadsSelected");
        this._setUserUploadDisplayed(true);
    }else{
        this._setUserUploadDisplayed(false);
        this.trigger("backgroundMusicCategorySelected", categoryId);    
    }

},



onShowImportYourOwnMusicButtonClicked : function(){
    this.trigger("showImportYourOwnMusicPopup");
},

afterRendering: function() {
    this.backgroundMusicList$el = this.$el.find(".background_music_list");
    this.backgroundMusicCategoryList$el = this.$el.find(".tag_button_list_wrapper");
    this.backgroundMusicList$el.append(this.trebbleAudioListView.$el);
    this.showImportYourOwnMusicButton$el = this.$el.find(".showImportYourOwnMusicButton");
    this.background_music_category_fld$el = this.$el.find("#background_music_category_fld");
    this.showImportYourOwnMusicButton$el.on("click", this.onShowImportYourOwnMusicButtonClicked.bind(this));
    this.background_music_category_fld$el.on("change", this._onCategorySelectionChangedOnSelectField.bind(this));
    this.$el.find("#background_music_category_fld-button").append('<i class="ion-chevron-down" ></i>');
},


onPopupClosed: function() {

},

onAfterPageShow: function() {

},



});

export default ConfigureBackgroundMusicPopupView;
