	import $ from 'jquery';
    import _ from 'underscore';
    import Backbone from "backbone";


    const YoutubeIframeAPIModel = Backbone.Model.extend({
        "default" : {
            "player" : ""
        },

        initialize : function() {
            

        },
        
        createYoutubePlayer: function(htmlElementIdToReplace, youtubeVideoId, videoHeight, videoWidth, playVideoAfterPlayerCreation){
          let player;
            const onPlayerReady = function(event){
                if(playVideoAfterPlayerCreation)
                    {
                    event.target.loadVideoByUrl("//www.youtube.com/embed/"+youtubeVideoId +"?feature=player_embedded" );
                        event.target.playVideo();
                    }
            };
            
            
            /*const stopVideo = function() {
                player.stopVideo();
              }*/
            
             let done = false;
             const onPlayerStateChange = function (event) {
                if (event.data == window.YT.PlayerState.PLAYING && !done) {
                //  setTimeout(stopVideo, 6000);
                  done = true;
                }
              };
            
            player = new window.YT.Player(htmlElementIdToReplace, {
                height: videoHeight,
                width: videoWidth,
                videoId: youtubeVideoId,
                events: {
                  'onReady': onPlayerReady,
                  'onStateChange': onPlayerStateChange
                }
              });
            
            this.set("player",player);
            
            
        },

        
                    });

    const YoutubeIframeAPI = new YoutubeIframeAPIModel();

    export default {
        getInstance : function() {
            return YoutubeIframeAPI;
        }
    };
	