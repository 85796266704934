import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";

const Song =  Backbone.Model.extend({
     idAttribute: "uri",
     
    "default": {
        "title": "",
        "artist": "",
        "uri": "",
        "coverArtFound": false,
    },

    constructor: function (attributes, options){
     Backbone.Model.prototype.constructor.call(this, attributes,options);
    //	window.alertErrorMessage("albumArtData inistiiit  " + this.get("albumArtData"));
        if(this.get("albumArtData"))
        {

            this.albumArtData = this.get("albumArtData");
            this.set("albumArtData",null);
        }

        //window.alertErrorMessage("albumArtData result" + this.get("albumArtData"));
        //window.alertErrorMessage("albumArtData Copy" + this.albumArtData);
    },

    getTrebbleSongId : function(){
        return this.get("trebbleSongId");
    },
    


     get: function (attr) {
         
        if (this.attributes.idified  && (this.attributes.idified ==="true" || this.attributes.idified ===true) && (this.attributes.idifiedSuccess ==="true" || this.attributes.idifiedSuccess === true ))
        {
            if(attr ==="title" && window.trebble.config.showIdifiedSongTitle)
            {
                return Backbone.Model.prototype.get.call(this, "idifiedTitle");
            }
            if(attr ==="artist" && window.trebble.config.showIdifiedArtistName)
            {
                const featuredArtists = Backbone.Model.prototype.get.call(this, "idifiedFeaturedArtists");
                let featuredArtistsString = null;
                
                if(featuredArtists && featuredArtists.length > 0){
                    const songTitle =  this.get("title");
                    for(let index in featuredArtists){
                        const featuredArtist = featuredArtists[index];
                        if(!songTitle || (songTitle && songTitle.toUpperCase().indexOf(featuredArtist.artist.toUpperCase()) == -1)){
                            if(!featuredArtistsString)
                            {
                                featuredArtistsString =   featuredArtist.artist;
                            }else{
                                featuredArtistsString =  featuredArtistsString + ", " + featuredArtist.artist;
                            }
                        }
                    }
                }
                const mainArtist = Backbone.Model.prototype.get.call(this, "idifiedArtist");
                
                const allArtists = featuredArtistsString? mainArtist + ", " +featuredArtistsString : mainArtist;
                return allArtists;
            }
            if(attr ==="album" && window.trebble.config.showIdifiedAlbumTitle)
            {
                return Backbone.Model.prototype.get.call(this, "idifiedAlbum");
            }
            
          
        }
        if(attr ==="titleFromFileID3Tags" )
        {
            return Backbone.Model.prototype.get.call(this, "title");
        }
        if(attr ==="artistFromFileID3Tags" )
        {
            return Backbone.Model.prototype.get.call(this, "artist");
        }
        if(attr ==="albumFromFileID3Tags" )
        {
            return Backbone.Model.prototype.get.call(this, "album");
        }
        if(attr === "linkedAlbumId"){
            const idifiedAlbumId = Backbone.Model.prototype.get.call(this, "idifiedAlbumId");
            const albumId = Backbone.Model.prototype.get.call(this, "albumId");
            return idifiedAlbumId? idifiedAlbumId: albumId;
        }
        if(attr === "linkedArtistId"){
            const idifiedArtistId = Backbone.Model.prototype.get.call(this, "idifiedArtistId");
            const artistId = Backbone.Model.prototype.get.call(this, "artistId");
            return idifiedArtistId? idifiedArtistId: artistId;
        }
        if(attr == "dateAdded" || attr == "dateModified" || attr == "generatedReleaseDate"
             || attr == "idifiedDate"){
            const dateValue = Backbone.Model.prototype.get.call(this, attr);
            if(dateValue && ( typeof dateValue == "string" || dateValue instanceof String)){
                return new Date(dateValue);
            }else{
                return dateValue;
            }
        }

        return Backbone.Model.prototype.get.call(this, attr);
    },
    
    updateGenreAndOtherInfo : function(idifiedInfo){
        const infoToUpdate = {}
        const idifiedToUpdate = {};
        const currentObjIdifiedInfoObj =  this.get("idifiedInfo");
        for(let propertyName in idifiedInfo){
            const propertyValue =idifiedInfo[propertyName];
            let idifiedObjPropertyName = "idified" + propertyName.charAt(0).toUpperCase() + propertyName.substring(1) ; 
            if(idifiedObjPropertyName == "idifiedAlbumArtUrl"){
                idifiedObjPropertyName = "idifiedAlbumArtURL";
            }
            const objPropertyValue = this.get(idifiedObjPropertyName);
            if(!objPropertyValue && propertyValue && objPropertyValue != propertyValue){
                this.set(idifiedObjPropertyName , propertyValue);
            }
            
            if(!currentObjIdifiedInfoObj[propertyName]  && propertyValue && currentObjIdifiedInfoObj[propertyName]  != propertyValue){
                currentObjIdifiedInfoObj[propertyName] = propertyValue;
            }
        }

    },

    hasActiveCapsule : function(){
        return this.get("hasActiveCapsule");
    },
    
    getActiveCapsuleId : function(){
        return this.get("activeCapsuleId");
    },
    
    getAlbumArt : function(){
        let albumArt = this.get("idifiedAlbumArtURL");
        if(!albumArt)
        {
            albumArt = this.get("albumArtUrl");
        }
        return albumArt;
    },
    
    getAlbumArtImages : function(){
        let albumArtImages = this.get("idifiedAlbumArtImages");
        if(!albumArtImages)
        {
            albumArtImages = this.get("albumArtImages");
        }
        return albumArtImages;
    },


    getUrl : function(){
        const url = this.get("uri");
        const patt=  /^\/mnt\//;
        if(url && patt.test(url))
            {
                return url.substr(12);
            }else{
                if(url)
                    {
                    return url;
                    }else{
                        //null url
                        return null;
                    }
            }
    }
});
export default Song;