import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerNode from "models/audioEditor/sequencerNodes/SequencerNode";
import RSVP from "rsvp";

const CLASSNAME = "PunctuationSequencerNode";
const PunctuationSequencerNode =  SequencerNode.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerNode.apply(this, [attributes, options]);
        this.setSelectable(false);
        
    },


    getConstructor : function(){
        return PunctuationSequencerNode;
    },

    
    afterInstanceCreatedFromConstructorForCloning : function(createdClonedInstance, sequencerNodeCidToCloneNodeMap){
        
    },

    restoreFromSerializedData: function(serializedData, audioSegmentFactoryInstance, audioFilterFactoryInstance, additionalSpeakerInfoArray){
        return SequencerNode.prototype.restoreFromSerializedData.call(this, serializedData, audioSegmentFactoryInstance, audioFilterFactoryInstance, additionalSpeakerInfoArray);
    },

    afterAllInstancesCreatedFromSerializedData : function(serializedData, sequencerNodeCidToSequencerNodeMap){
        return SequencerNode.prototype.afterAllInstancesCreatedFromSerializedData.call(this, serializedData, sequencerNodeCidToSequencerNodeMap);
    },

    cloneJSON: function(){
        const objJson = SequencerNode.prototype.cloneJSON.call(this);
        objJson.type = PunctuationSequencerNode.CLASSNAME;
        return objJson;
    },

    getSerializedData : function(){
        const serializedData = SequencerNode.prototype.getSerializedData.call(this);
        return serializedData;
    },


});

PunctuationSequencerNode.CLASSNAME = CLASSNAME;
export default PunctuationSequencerNode; 