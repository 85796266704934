import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import PlaylistListPageController from "controllers/playlistPodcast/PlaylistListPageController";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utils from "models/helper/Utils";
import ti18n from "i18n!nls/PlaylistPodcasti18n";
import RSVP from "rsvp";

const FollowedTrebbleListPageController = PlaylistListPageController.extend({


    initialize : function(options) {
        options = options? options:{};
        options.pageTitle = window.getI18n(ti18n, "SUBSCRIPTIONS");
        this.username =  options.username;
        return PlaylistListPageController.prototype.initialize.call(this, options);
    },
    

    getPlaylistListLoadHandler : function(){
        return (function(){
            return TrebbleClientAPIHelper.getInstance().getFollowedTrebblesForUserWithUsername(this.username).then((function(trebbleJsonArray){
                const playlistSummaryModelArray = [];
                if(trebbleJsonArray){
                    for(let i = 0; i <trebbleJsonArray.length ; i++){
                        playlistSummaryModelArray.push(Utils.getInstance().buildPlaylistSummaryFromJson(trebbleJsonArray[i]));
                    }
                }
                return playlistSummaryModelArray;
            }).bind(this));
        }).bind(this);
    },

});
export default FollowedTrebbleListPageController;