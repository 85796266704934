import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Song from "models/Song";
import TrebbleListView from "views/common/TrebbleListView";
import AlbumListItemTemplate from "text!../../../templates/common/albumListItemTemplate.html";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";

const TrebbleAlbumListView = TrebbleListView.extend({
    
    
    
    
    
    initialize : function(options) {
        if(!options){
            options= {};
        }
        if(!options.rightSwipeRevealOption){
            options.rightSwipeRevealOption = {};//this._buildItemRightOption()
        }
        if(!options.leftSwipeRevealOption){
            options.leftSwipeRevealOption = {};//this._buildItemLeftOption()
        }

        this.collection = options.collection
        options.modelIdAttribute  = "uri";
        options.listItemTemplate = AlbumListItemTemplate;
        options.doNotShowAddToRadioButton = true;
        options.doNotShowGradeSongButton = true;
        const self = TrebbleListView.prototype.initialize.call(this, options);
        this.setModelTransformHandlerBeforeTemplatePiping(this._modelTransformHandlerBeforeTemplatePiping);
        this.setItemViewUpdateDuringScrollingHandler(this._getItemViewUpdateDuringScrollingHandler);
        return self;
        
    },
    
    _getDefaultItemEventHandler : function(){
        let defaultItemEventHandler =  TrebbleListView.prototype._getDefaultItemEventHandler.call(this);
        defaultItemEventHandler = defaultItemEventHandler? defaultItemEventHandler : [];
        delete defaultItemEventHandler["click div.songOptionBtnWrapper > #songLibraryOptionButtuon"];
        return defaultItemEventHandler;
    },
    reload : function(){
        this.setUpdating(true);
        try{
            const displayedAlbumJSONArray = this.collection? this.collection.toJSON(): [];
            this.updateModels(displayedAlbumJSONArray);
            this.setUpdating(false);
        }catch(error){
            this.setErrorMessage(error);
        }
    },
    
    /*_getInternalOnCapsuleIconTapHandler : function(model,evnt, item) {
        const songModel = this._getAlbumModelFromSongJson(model);
        const context = this.getContext();
        if(songModel){
            return PersistentModels.getInstance().getRouter().showCapsulesForSong(songModel, context);
        }else{
            return RSVP.Promise.reject("Song couldn't be found");
        }	
    },*/

    
/*_buildItemRightOption : function(){
        const optionConfig = [];
        optionConfig.push( {"optionLabel" : "..."});
    optionConfig.push( {"optionLabel" : "Add To My Trebble<i class='ion-ios7-plus-outline'></i>","name": "addToRadio", "validationHandler": this._canBeAddedToRadio.bind(this), "onOptionSelected": this._onAddSongToRadioMenuSelected.bind(this)});//"<i class='fontello-icon-list-add'></i>","name": "addToQueue"};// "Add To Queue"};
    optionConfig.push( {"optionLabel" : "Grade<i class='ion-ios7-pricetags-outline'></i>","name": "rateOrTag" , "validationHandler": null, "onOptionSelected": this._onTagSongOptionMenuSelected.bind(this)});//"<i class='ion-radio-waves'></i>","name": "playSimilarInLibrary"};//: "Play Similar In Library"};
    return optionConfig;
},

_buildItemLeftOption : function(){
    const optionConfig = [];
    optionConfig.push( {"optionLabel" : "..."});
    optionConfig.push( {"optionLabel": "<i class='pe-7s-more'></i>Show Options","name": "showOptions", "validationHandler": null, "onOptionSelected": this._onShowSongOptionMenuSelected.bind(this)});//"<i class='fontello-icon-list-add'></i>"};// "Add To Queue"};
    optionConfig.push( {"optionLabel": "<i class='ion-ios7-minus-outline'></i>Remove From My Trebble","name": "removeFromRadio", "validationHandler":  this._canBeRemovedToRadio.bind(this), "onOptionSelected": this._onRemoveSongToRadioMenuSelected.bind(this)});//"<i class='ion-radio-waves'></i>"};// : "Play Similar In Library"};
    return optionConfig;
},*/

/*	_canBeAddedToRadio : function(songJson){
    const songModel = this._getAlbumModelFromSongJson(songJson);
    return songModel?!songModel.get("addedToRadio"): true;
    
},

_canBeRemovedToRadio : function(songJson){
    const songModel = this._getAlbumModelFromSongJson(songJson);
    return songModel?songModel.get("addedToRadio"): false;
    
},

_onAddSongToRadioMenuSelected : function(songJson){
    const songModel = this._getAlbumModelFromSongJson(songJson);
    const context = this.getContext();
    if(songModel){
        const p =  PersistentModels.getInstance().addSongToDefaultRadio(songModel, context);
        this.trigger("songAddedToTrebble", songModel);
        return p;
    }else{
        return RSVP.Promise.reject("Song to add couldn't be found");
    }
    
},

_onRemoveSongToRadioMenuSelected : function(songJson){
    const songModel = this._getAlbumModelFromSongJson(songJson);
    const context = this.getContext();
    if(songModel){
        const  p = PersistentModels.getInstance().removeSongFromDefaultRadio(songModel, context);
        this.trigger("songRemovedFromTrebble", songModel);
        return p;
    }else{
        return RSVP.Promise.reject("Song to add couldn't be found");
    };
},

_onShowSongOptionMenuSelected : function(songJson){
    const songModel = this._getAlbumModelFromSongJson(songJson);
    if(songModel){
        const context = this.getContext();
        return PersistentModels.getInstance().getRouter().showSongMenuOption(songModel, context,null, this.onActionExecutedOnSongHandler.bind(this));
    }else{
        return RSVP.Promise.reject("Song to add couldn't be found");
    };
},
*/
/*onActionExecutedOnAlbumHandler : function(albumModel, action){
    this.trigger("actionExecutedOnAlbumHandler", onArtistActionButtonTapped, action);

},
*/


/*_onTagSongOptionMenuSelected : function(songJson){
    const songModel = this._getAlbumModelFromSongJson(songJson);
    if(songModel){
        const context = this.getContext();
        return PersistentModels.getInstance().getRouter().showSongTagManagerPopup(songModel, context);
    }else{
        return RSVP.Promise.reject("Song to add couldn't be found");
    };
},*/

getContext : function(){
    return this.collection? this.collection.getContext(): null;
},


_getAlbumModelFromSongJson : function(albumJson){
    return this.collection.get(albumJson.albumId);
},

_modelTransformHandlerBeforeTemplatePiping: function(albumJson) {
    let artistToDisplay = albumJson.artist;
    let albumToDisplay = albumJson.album;
    if(!artistToDisplay)
    {
        artistToDisplay = "";
    }
    if(!albumToDisplay)
    {
        albumToDisplay = "";
    }
    const albumArtUrl = albumJson.albumArtURL?window.trebble.globalHelpers.getResizeImageUrl(albumJson.albumArtURL, 100, 100, albumJson.albumArtImages): "";
    const templateData = {"albumArtUrl": albumArtUrl, "albumName": albumToDisplay, "artistName": artistToDisplay, "actionButtonIconClass": "pe-7s-more"};
    return templateData;
},

_getItemViewUpdateDuringScrollingHandler : function(elem,templateData){
    const profileImageElem = elem.querySelector(".songCoverArt");
    profileImageElem.src = window.getResourceURLAccordingToLocationProtocol("/img/videoLoading.png");
    profileImageElem.setAttribute("data-original", templateData.albumArtUrl);
    if(!profileImageElem.className ||  profileImageElem.className.indexOf("lazy") == -1){
        profileImageElem.className = (!profileImageElem.className)? "lazy" :  profileImageElem.className +" lazy";
    }
    
    const albumNameElem = elem.querySelector(".songName");
    albumNameElem.textContent = templateData.albumName;
    
    const artistNameElem = elem.querySelector(".artistName");
    artistNameElem.textContent = templateData.artistName;
    

    const actionButtonElem = elem.querySelector("#actionButton");
    if(templateData.actionButtonIconClass){
        
        actionButtonElem.className = "actionIcon enabled"+templateData.actionButtonIconClass;
        actionButtonElem.style.display = "flex";
        if(actionButtonElem.style.display != "flex"){
            actionButtonElem.style.display = "-webkit-flex";
        }
    }else{
        actionButtonElem.className = "actionIcon";
        actionButtonElem.style.display = "none";
    }
},


});

export default TrebbleAlbumListView;
