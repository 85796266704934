import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import SignupPageView from "views/signup/SignupPageView";
import FacebookHelper from "models/helper/FacebookHelper";
import AppleIDHelper from "models/helper/AppleIDHelper";
import FacebookEventTrackingHelper from "models/helper/FacebookEventTrackingHelper";
import Utils from "models/helper/Utils";
import ti18n from "i18n!nls/signupPagei18n";
import RSVP from "rsvp";

const SignupController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.onSignupSuccessfullyCompleted =  options? options.onSignupSuccessfullyCompleted:null;
        this.signupApi =  options? options.signupApi:null;
        this.signupWithFacebookApi =  options? options.signupWithFacebookApi:null;
        this.signupWithGoogleApi =  options? options.signupWithGoogleApi:null;
        this.signupWithAppleApi =  options? options.signupWithAppleApi:null;
        this.signupAsShortcastCreator =  options ? options.signupAsShortcastCreator : false;
        this._accessCode = options? options.accessCode : null;
        this._hideBackButton = options? options.hideBackButton : false;
        if(!this._accessCode && window.trebble.presetAccessCode){
            this._accessCode = window.trebble.presetAccessCode;
        }
        this._forwardAfterSignup = options? options.forwardAfterSignup: false;
        this.isUserpreviouslyLoggedInAsGuest =  options? options.isUserpreviouslyLoggedInAsGuest:false;

    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },


    doSignup : function(data){
        $.mobile.loading("show");
        if(this.signupAsShortcastCreator){
            data.profileType = Utils.getInstance().getProfileTypes().SHORTCAST_CREATOR;
        }
        FacebookEventTrackingHelper.getInstance().trackLeadWithIntentToSignup();
        this.signupApi(data).then((function(operationResult){
            if(operationResult && operationResult.userCreated){
                if(this.onSignupSuccessfullyCompleted){

                    this.onSignupSuccessfullyCompleted(data);
                }
                if(window.trebbleAnalyticsHelper){
                    window.trebbleAnalyticsHelper.trackEvent("Onboarding", "SignupCompleted", "Sign-up Completed");
                }
                FacebookEventTrackingHelper.getInstance().trackRegistration(false, false); 
            }else{
                if(window.trebbleAnalyticsHelper){
                    window.trebbleAnalyticsHelper.trackEvent("Onboarding", "SignupFailed", "Sign-up Failed");
                } 
                if(operationResult.userAlreadyExist){
                    throw (window.getI18n(ti18n, "USER_WITH_USERNAME")+" '"+ data.username  + "' "+ window.getI18n(ti18n, "ALREADY_EXISTS")+".");
                }else{
                    if(operationResult.emailAlreadyUsed){
                        throw (window.getI18n(ti18n, "USER_WITH_EMAIL")+" '"+ data.email  + "' "+ window.getI18n(ti18n, "ALREADY_EXISTS")+".");
                    }
                }
                throw window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_CREATING_NEW_ACCOUNT");
            }
        }).bind(this)).then((function(){
            $.mobile.loading("hide");
            if(!this._forwardAfterSignup){
                window.history.back();  
            }
        }).bind(this)).catch((function(error){
            $.mobile.loading("hide");
            if(error === window.getI18n(ti18n, "INVALID_ACCESS_CODE")){
                this.showErrorMessageForAccessCode(window.getI18n(ti18n, "INVALID_ACCESS_CODE"));
            }else{
                window.alertErrorMessage(error);
            }
        }).bind(this));
    },


    doLoginViaFacebookPopupWindow : function(){
        return FacebookHelper.getInstance().login();
        /*return new RSVP.Promise(function(resolve, reject){
            FB.login(function(response){
                if (response.status === 'connected') {
                    // Logged into your app and Facebook.
                    resolve(response.authResponse);
                } else {
                    // The person is not logged into this app or we are unable to tell.
                    resolve(null); 
                    //resolve({"accessToken":"EAAQekcVgZBPgBAOPHZCYG2aX6eC0VztgdGJIXWnab4DH3nItnBDZAwrMivPJHGeY2DLCgojxWX8kVdByG5GEi6dK7GzGJWaG5u9qqRZAw2S51OSU6yNH7j9kZBlKpYZCX7rZBYdT3vjdWaQQuUrAC41ZAPKfLFZASOZCxKtHl9sTX3VofUVFSKSRArXuC9tkAmq2QBecBwT7sN2gZDZD"});
                }
            }, {scope: 'public_profile,email'})
})*/
},


doSignupThroughFacebook : function(accessCode){

if(accessCode){
$.mobile.loading("show");
let facebookAuthInfo = null;
FacebookEventTrackingHelper.getInstance().trackLeadWithIntentToSignup();
return this.doLoginViaFacebookPopupWindow().then((function(authInfo){
    facebookAuthInfo = authInfo;
    if(facebookAuthInfo){
        return this.signupWithFacebookApi(facebookAuthInfo.accessToken, accessCode,null, null, null, null,null, null, null, null, null, this.signupAsShortcastCreator?Utils.getInstance().getProfileTypes().SHORTCAST_CREATOR:null);
    }else{
        throw window.getI18n(ti18n, "PLEASE_LOGIN_FACEBOOK_TO_CREATE_ACCOUNT");
    }
}).bind(this)).then((function(operationResult){
    if(operationResult && operationResult.userCreated){
        if(this.onSignupSuccessfullyCompleted){
            this.onSignupSuccessfullyCompleted({"externalProfileSignup":"Facebook" ,"facebookAccessToken": facebookAuthInfo.accessToken});
        }
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Onboarding", "SignupCompleted", "Sign-up Completed");
        } 
        FacebookEventTrackingHelper.getInstance().trackRegistration(false, false);
    }else{
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Onboarding", "SignupFailed", "Sign-up Failed");
        } 
        if(operationResult.userAlreadyExist){
            throw (window.getI18n(ti18n, "USER_WITH_FACEBOOK_ACCOUNT_ALREADY_EXISTS"))
        }else{
            if(operationResult.emailAlreadyUsed){
                throw (window.getI18n(ti18n, "USER_WITH_EMAIL_ALREADY_EXISTS"));
            }
        }
        throw window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_CREATING_NEW_ACCOUNT");
    }
}).bind(this)).then((function(){
    $.mobile.loading("hide");
    window.history.back();  
}).bind(this)).catch((function(error){
    $.mobile.loading("hide");
    window.alertErrorMessage(error);
}).bind(this));
}else{
//window.alertErrorMessage("Please enter your access code");
this.showErrorMessageForAccessCode(window.getI18n(ti18n, "PLEASE_ENTER_ACCESS_CODE"));

}
},

doLoginViaApplePopupWindow : function(){
return AppleIDHelper.getInstance().login();			
},

doSignupThroughApple : function(accessCode){

if(accessCode){
$.mobile.loading("show");
let appleAuthInfo = null;
FacebookEventTrackingHelper.getInstance().trackLeadWithIntentToSignup();
return this.doLoginViaApplePopupWindow().then((function(authInfo){
    appleAuthInfo = authInfo;
    if(appleAuthInfo){
        return this.signupWithAppleApi(appleAuthInfo.authorization.id_token, accessCode, null, null, null,null, null, null, null, null, null, this.signupAsShortcastCreator?Utils.getInstance().getProfileTypes().SHORTCAST_CREATOR:null);
    }else{
        throw window.getI18n(ti18n, "PLEASE_LOGIN_APPLE_TO_CREATE_ACCOUNT");
    }
}).bind(this)).then((function(operationResult){
    if(operationResult && operationResult.userCreated){
        if(this.onSignupSuccessfullyCompleted){
            this.onSignupSuccessfullyCompleted({"externalProfileSignup":"Apple" ,"appleAccessToken": appleAuthInfo.authorization.id_token});
        }
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Onboarding", "SignupCompleted", "Sign-up Completed");
        } 
        FacebookEventTrackingHelper.getInstance().trackRegistration(false, false);
    }else{
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Onboarding", "SignupFailed", "Sign-up Failed");
        } 
        if(operationResult.userAlreadyExist){
            throw (window.getI18n(ti18n, "USER_WITH_APPLE_ACCOUNT_ALREADY_EXISTS"))
        }else{
            if(operationResult.emailAlreadyUsed){
                throw (window.getI18n(ti18n, "USER_WITH_EMAIL_ALREADY_EXISTS"));
            }
        }
        throw window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_CREATING_NEW_ACCOUNT");
    }
}).bind(this)).then((function(){
    $.mobile.loading("hide");
    window.history.back();  
}).bind(this)).catch((function(error){
    $.mobile.loading("hide");
    window.alertErrorMessage(error);
}).bind(this));
}else{
//window.alertErrorMessage("Please enter your access code");
this.showErrorMessageForAccessCode(window.getI18n(ti18n, "PLEASE_ENTER_ACCESS_CODE"));

}
},

doSignupThroughGoogle : function(googleUser, accessCode){

if(accessCode){
$.mobile.loading("show");
FacebookEventTrackingHelper.getInstance().trackLeadWithIntentToSignup();
return this.signupWithGoogleApi(googleUser.getAuthResponse().id_token, accessCode,null, null, null, null,null, null, null, null, null, this.signupAsShortcastCreator?Utils.getInstance().getProfileTypes().SHORTCAST_CREATOR:null).then((function(operationResult){
    if(operationResult && operationResult.userCreated){
        if(this.onSignupSuccessfullyCompleted){
            this.onSignupSuccessfullyCompleted({"externalProfileSignup":"Google" ,"googleAccessToken": googleUser.getAuthResponse().id_token, "googleUser": googleUser});
        }
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Onboarding", "SignupCompleted", "Sign-up Completed");
        } 
        FacebookEventTrackingHelper.getInstance().trackRegistration(false, false);
    }else{
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Onboarding", "SignupFailed", "Sign-up Failed");
        } 
        if(operationResult.userAlreadyExist){
            throw (window.getI18n(ti18n, "USER_WITH_GOOGLE_ACCOUNT_ALREADY_EXISTS"))
        }else{
            if(operationResult.emailAlreadyUsed){
                throw (window.getI18n(ti18n, "USER_WITH_EMAIL_ALREADY_EXISTS"))
            }
        }
        throw window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_CREATING_NEW_ACCOUNT");
    }
}).bind(this)).then((function(){
    $.mobile.loading("hide");
    window.history.back();  
}).bind(this)).catch((function(error){
    $.mobile.loading("hide");
    window.alertErrorMessage(error);
}).bind(this));
}else{
//window.alertErrorMessage("Please enter your access code");
this.showErrorMessageForAccessCode(window.getI18n(ti18n, "PLEASE_ENTER_ACCESS_CODE"));

}
},

onGoogleLoginFailed :function(error){
window.alertErrorMessage(window.getI18n(ti18n, "PLEASE_LOGIN_GOOGLE_TO_CREATE_ACCOUNT"));
},

showErrorMessageForAccessCode : function(errorMessage){
const buttonLabels =  [window.getI18n(ti18n, "OK"), window.getI18n(ti18n, "REQUEST_ONE")];
const confirmCallback = (function(buttonIndex){
if(buttonIndex ==  2){
    this.openRequesInvitePage();
}
}).bind(this);
window.navigator.trebbleNotification.confirm(errorMessage, confirmCallback,  null,buttonLabels, null, "error");
},

onViewRemovedFromDocument : function(){
if(this.onSignupSuccessfullyCompleted){
this.onSignupSuccessfullyCompleted(false);
}
},

openRequesInvitePage : function(){
const openedWindow = window.open("https://trebble.typeform.com/to/JXo8tX", "_blank");
if((!openedWindow || openedWindow.closed || typeof openedWindow.closed=='undefined') && !window.waitForCordovaToLoad){
window.alertErrorMessage(window.getI18n(ti18n, "PLEASE_ALLOW_POPUPS_FOR_TREBBLE"));
}else{
if(openedWindow.focus){
    openedWindow.focus();
}
}

},

onViewRemoved:function(){
this.stopListening();
},

buildModelAndRenderView : function() {
return new RSVP.Promise((function(resolve, reject) {
try {
    const options = {};
    options.isUserpreviouslyLoggedInAsGuest = this.isUserpreviouslyLoggedInAsGuest;
    options.accessCode = this._accessCode;
    options.hideBackButton  = this._hideBackButton;
    this.view = new SignupPageView(options);
    this.view.render();

    this.listenTo(this.view, "signUpBtnTapped", this.doSignup);
    this.listenTo(this.view, "signUpWithFacebookBtnTapped", this.doSignupThroughFacebook);
    this.listenTo(this.view, "signUpWithAppleBtnTapped", this.doSignupThroughApple);
    this.listenTo(this.view, "signUpWithGoogleBtnSuccess", this.doSignupThroughGoogle);
    this.listenTo(this.view, "signUpWithGoogleBtnFailed",this.onGoogleLoginFailed)
    this.listenTo(this.view, "removedFromPage", this.onViewRemovedFromDocument);
    this.listenTo(this.view, "remove", this.onViewRemoved.bind(this));

    resolve();
    if(window.trebbleAnalyticsHelper){
        window.trebbleAnalyticsHelper.trackEvent("Onboarding", "SignupPageOpened", "Sign-up Page Opened");
    } 
} catch (error) {
    reject(error);
}

}).bind(this));

}

});

export default SignupController;