	import $ from 'jquery';
    import _ from 'underscore';
    import Backbone from "backbone";
    import FixedPlaylist from "collections/FixedPlaylist";
    import AutoFixedPlaylist from "collections/AutoFixedPlaylist";
    import DynamicPlaylist from "collections/DynamicPlaylist";
    import SocialDynamicPlaylist from "collections/SocialDynamicPlaylist";
    import TrebbleSetDynamicPlaylist from "collections/TrebbleSetDynamicPlaylist";
    import PersistentModels from "services/PersistentModels";
    import Utils from "models/helper/Utils";
    import RSVP from "rsvp";
    import ti18n from "i18n!nls/PlaylistHelperi18n";
    import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";

    const AUTO_PLAYLIST_IDS = {};
    AUTO_PLAYLIST_IDS.MY_RECENTLY_ADDED_OR_FAVORITED = "MyRecentlyAddedOrFavorited";
    AUTO_PLAYLIST_IDS.RECENTLY_ADDED_OR_FAVORITED_BY_FOLLOWINGS = "recentlyAddedOrFavoritedByFollowings";
    AUTO_PLAYLIST_IDS.I_AM_FEELING_LUCKY = "feelingLucky";
    AUTO_PLAYLIST_IDS.RECENTLY_PLAYED_BY_FOLLOWINGS = "recentlyPlayedByFollowings";
    AUTO_PLAYLIST_IDS.STUCK_IN_MY_HEAD = "StuckInMHead";
    AUTO_PLAYLIST_IDS.STUCK_IN_MY_HEAD_FOLLOWINGS = "StuckInMyFollowingsHead";
    AUTO_PLAYLIST_IDS.TRENDING_NEAR_BY = "trendingNearBy";
    AUTO_PLAYLIST_IDS.MY_RECENTLY_ADDED = "myRecentlyAdded";

    const PlaylistHelper = Backbone.Model.extend({
        

        initialize : function() {
            

        },
        
        generateEmptyPlaylist : function() {
            const fixedPlaylist = new FixedPlaylist();
            fixedPlaylist.updateContext(Utils.getInstance().buildContextForSongCollectionUserCustom());
            return fixedPlaylist;
        },
        
        
        generatePlaylistContainingSong : function(songModels, context) {
            const fixedPlaylist = this.generateEmptyPlaylist();
            if(context){
                fixedPlaylist.updateContext(context);
            }
            fixedPlaylist.add(songModels);
            return fixedPlaylist;
        },
        
        
        generatePlaylistOfLocalSongs : function(songSeed){
            const params = {};
            params.seedData = songSeed;
            params.name = window.getI18n(ti18n, "LOCAL_SIMILAR_SONGS");
            params.description = window.getI18n(ti18n, "LOCAL_SONGS_SIMILAR_TO_SELECTED");
            params.categoryId =  Utils.getInstance().getTrebbleMusicCategoryId();
            params.onRefresh = this._retrieveLocalSongsSimilarToSongSeed;
            params.playlistNameGenerator = this._buildPlaylistNameForPLaylistOfSimilarLocalSong;
            const generatedPlaylist = new DynamicPlaylist([], params);
            const songUri = songSeed.get("uri");
            const context =  Utils.getInstance().buildContextForSongCollectionAutoSongRadio(songUri);
            generatedPlaylist.updateContext(context);
            return generatedPlaylist;
        },
        
        generatePlaylistOfSimilarSongs : function(songSeed){
            const params = {};
            params.seedData = songSeed;
            params.name = window.getI18n(ti18n, "SIMILAR_SONGS");
            params.description = window.getI18n(ti18n, "SIMILAR_SONGS_TO_SELECTED");
            params.categoryId =  Utils.getInstance().getTrebbleMusicCategoryId();
            params.onRefresh = this._retrieveSongsSimilarToSongSeed;
            params.playlistNameGenerator = this._buildPlaylistNameForPLaylistOfSimilarLocalSong;
            const generatedPlaylist = new DynamicPlaylist([], params);
            const songUri = songSeed.get("uri");
            const context =  Utils.getInstance().buildContextForSongCollectionAutoSongRadio(songUri);
            generatedPlaylist.updateContext(context);
            return generatedPlaylist;
        },
        
        generatePlaylistOfSongsBasedOnOtherUsers : function(songSeed){
            const params = {};
            params.seedData = songSeed;
            params.name = window.getI18n(ti18n, "SIMILAR_SONGS_FROM_OTHERS_PEOPLE_LIBRARY");
            params.description = window.getI18n(ti18n, "SONGS_RECENTLY_PLAYED_BY_OTHER_USERS");
            params.categoryId =  Utils.getInstance().getTrebbleMusicCategoryId();
            params.onRefresh = this._retrieveSongsSimilarToSongSeedFromOtherUsers;
            params.playlistNameGenerator = this._buildPlaylistNameForPLaylistOfSongFromAllOtherUsers;
            const generatedPlaylist = new DynamicPlaylist([], params);
            const songUri = songSeed.get("uri");
            const context =  Utils.getInstance().buildContextForSongCollectionAutoSongRadio(songUri);
            generatedPlaylist.updateContext(context);
            return generatedPlaylist;
        },
        /*
        _retrieveLocalSongsSimilarToSongSeed : function(songModelSeed){
            return TrebbleClientAPIHelper.getInstance().generateLocalPlaylistFromSong(songModelSeed.toJSON()).then((function(arrayOfURIS){
                const arrayOfSongModels = [];
                for(let index in arrayOfURIS){
                    const songUri = arrayOfURIS[index];
                    const songFound = PersistentModels.getInstance().getSongModelBySongIdFromMyLibraryOrFavorites(songUri);
                    if(songFound)
                    {
                        arrayOfSongModels.push(songFound);
                    }
                }
                return arrayOfSongModels;
            }).bind(this)).catch((function(error){
                window.log("Error generating song radio :"+ error);
                window.alertErrorMessage("Error generating song radio :"+ error);
            }).bind(this));
},*/
isPlaylistOfTrebbleType : function(playlist){
if(playlist instanceof DynamicPlaylist ||
playlist instanceof SocialDynamicPlaylist){
return true;
}
return false;
},

isTrebblePlayable :  function(playlist){
if(playlist){
//const numberOfSongs = playlist.getNumberOfSongs? playlist.getNumberOfSongs(): playlist.numberOfSongs;
//const numberOfCapsules = playlist.getNumberOfCapsules? playlist.getNumberOfCapsules(): playlist.numberOfCapsules;
//return (numberOfSongs >= Utils.getInstance().getDefaultNumberOfSongsRequiredToPlayATrebble()) || numberOfCapsules > 0;
return true;
}else{
return false;
}

},


getNumberOfSongsLeftToStream : function(playlist){
if(playlist){
const numberOfSongs = playlist.getNumberOfSongs? playlist.getNumberOfSongs(): playlist.numberOfSongs;

return (numberOfSongs >= Utils.getInstance().getDefaultNumberOfSongsRequiredToPlayATrebble())? 0 :Utils.getInstance().getDefaultNumberOfSongsRequiredToPlayATrebble() - numberOfSongs;

}else{
return Utils.getInstance().getDefaultNumberOfSongsRequiredToPlayATrebble();
}

},

isTrebblePlayableWithSongsAndCapsules : function(numberOfSongs, numberOfCapsules){
return (numberOfSongs >= Utils.getInstance().getDefaultNumberOfSongsRequiredToPlayATrebble() && PersistentModels.getInstance().hasAtLeastOneMusicCategory()) || numberOfCapsules > 0;
},

getNumberOfCapsules : function(playlist){
return  playlist.getNumberOfCapsules? playlist.getNumberOfCapsules(): playlist.numberOfCapsules;
},


_retrieveLocalSongsSimilarToSongSeed : function(songModelSeed){
return TrebbleClientAPIHelper.getInstance().generatePlaylistOfSimilarSongs(songModelSeed.toJSON(), false);
},

_retrieveSongsSimilarToSongSeedFromOtherUsers : function(songModelSeed){
return TrebbleClientAPIHelper.getInstance().generatePlaylistFromSongBasedOnAllUsersData(songModelSeed.toJSON());
},

_retrieveSongsSimilarToSongSeed : function(songModelSeed){
return TrebbleClientAPIHelper.getInstance().generatePlaylistOfSimilarSongs(songModelSeed.toJSON(), true);
},

_buildPlaylistNameForPLaylistOfSimilarLocalSong : function(songSeed){
let playlistName =  "";
if(songSeed)
{
playlistName  =  window.getI18n(ti18n, "LOCAL_SONGS_TO") +" \"" + songSeed.get("title") + "\" "+ window.getI18n(ti18n, "FROM")+" " + songSeed.get("artist")  ;
}else{
playlistName  =  window.getI18n(ti18n, "LOCAL_SONGS_SIMILAR_TO_SONG") ;
}
return playlistName;
},

_buildPlaylistNameForPLaylistOfSongFromAllOtherUsers : function(songSeed){
    let playlistName =  "";
if(songSeed)
{
playlistName  =  window.getI18n(ti18n, "LOCAL_SONGS_TO") +" \"" + songSeed.get("title") + "\" "+ window.getI18n(ti18n, "FROM")+" " + songSeed.get("artist")  +" "+ window.getI18n(ti18n, "FROM_OTHER_USER_LIBRARIES")  ;
}else{
playlistName  =  window.getI18n(ti18n, "LOCAL_SONGS_SIMILAR_TO_SONG") +" "+ window.getI18n(ti18n, "FROM_OTHER_USER_LIBRARIES")  ;
}
return playlistName;
},



generatePlaylistOfSongsRecentlyPlayedByOtherUsers : function(songSeed){
const params = {};
params.seedData = songSeed;
params.name = window.getI18n(ti18n, "RECENTLY_PLAYED_BY_OTHERS");
params.description = window.getI18n(ti18n, "SONGS_RECENTLY_PLAYED_BY_OTHER_USERS");
params.categoryId =  Utils.getInstance().getTrebbleMusicCategoryId();
params.onRefresh = this._retrieveSongsRecentlyPlayedByOtherUsers;
//TODO:should add UI instead to tell user that content is not available
params.onRetrieveAllAvailableSonsInJson = this._retrieveSongsRecentlyPlayedByOtherUsers;
const generatedPlaylist = new SocialDynamicPlaylist([],params);
const context = Utils.getInstance().buildContextForSongCollectionAutoRadio();
generatedPlaylist.updateContext(context);
return generatedPlaylist;
},


generatePlaylistOfSongsRecentlyAddedOrFavoritedByOtherUsers : function(songSeed){
const params = {};
params.seedData = songSeed;
params.name = window.getI18n(ti18n, "RECENTLY_ADDED_BY_OTHERS");
params.description = window.getI18n(ti18n, "SONGS_RECENTLY_ADDED_BY_OTHERS");
params.onRefresh = this._retrieveSongsRecentlyAddedOrFavoritedByOtherUsers;
params.categoryId =  Utils.getInstance().getTrebbleMusicCategoryId();
//TODO:should add UI instead to tell user that content is not available
params.onRetrieveAllAvailableSonsInJson = this._retrieveSongsRecentlyAddedOrFavoritedByOtherUsers;
const generatedPlaylist = new SocialDynamicPlaylist([], params);
const context = Utils.getInstance().buildContextForSongCollectionAutoRadio();
generatedPlaylist.updateContext(context);
return generatedPlaylist;
},

generatePlaylistOfMyRecentlyAddedSongs : function(){
const params = {};
params.seedData = null;
params.name = window.getI18n(ti18n, "MY_RECENTLY_ADDED_SONGS");
params.description = window.getI18n(ti18n, "THIS_IS_PLAYLIST_OF_THE_SONGS_YOU_ADDED");
params.categoryId =  Utils.getInstance().getTrebbleMusicCategoryId();
params.onRefresh = this._retrieveMyRecentlyAddedSongs;
//TODO:should add UI instead to tell user that content is not available
params.onRetrieveAllAvailableSonsInJson = this._retrieveMyRecentlyAddedSongs;
const generatedPlaylist = new AutoFixedPlaylist([], params);
const context = Utils.getInstance().buildContextForSongCollectionAutoRadio();
generatedPlaylist.updateContext(context);
return generatedPlaylist;
},

generatePersonalUserRadioPlaylist : function(personalUserRadioSummary){
const playlistSummaryJson = personalUserRadioSummary.toJSON();
const params = JSON.parse(JSON.stringify(playlistSummaryJson));
params.seedData = personalUserRadioSummary;
params.onRefresh = this._retrieveSongsForPersonalUserRadioId(personalUserRadioSummary.get("id"));
params.categoryId =  personalUserRadioSummary.getCategoryId();
params.cityId =  personalUserRadioSummary.getCityId();
params.onRetrieveAllAvailableSonsInJson = this._retrieveAllSongsForPersonalUserRadioId(personalUserRadioSummary.get("id"));
const generatedPlaylist = new SocialDynamicPlaylist([],params);
const context = Utils.getInstance().buildContextForSongCollectionUserRadio(params.loaderKey);
generatedPlaylist.updateContext(context);
this._registerPlaylistSummaryToPlaylistIsPlayingChangeEvent(personalUserRadioSummary, generatedPlaylist);
return generatedPlaylist;
},

generateJourneyDayPlaylist : function(capsuleId, journeySummary){
const playlistSummaryJson = journeySummary.toJSON();
const params = JSON.parse(JSON.stringify(playlistSummaryJson));
params.seedData = journeySummary;
params.onRefresh = this._retrieveShortcastToPlayNextForJourneyRadioId(capsuleId, journeySummary.get("id"));
const generatedPlaylist = new SocialDynamicPlaylist([],params);
const context = Utils.getInstance().buildContextForSongCollectionUserRadio(params.loaderKey);
generatedPlaylist.updateContext(context);
this._registerPlaylistSummaryToPlaylistIsPlayingChangeEvent(journeySummary, generatedPlaylist);
return generatedPlaylist;
},

generateCapsuleSimpleSetPlaylist : function(personalUserRadioSummary){
const playlistSummaryJson = personalUserRadioSummary.toJSON();
const params = JSON.parse(JSON.stringify(playlistSummaryJson));
params.seedData = personalUserRadioSummary;
params.categoryId =  personalUserRadioSummary.getCategoryId();
params.cityId =  personalUserRadioSummary.getCityId();
params.onRefresh = this._retrieveSimpleSetForCapsuleId(personalUserRadioSummary.get("id"));
params.onRetrieveAllAvailableSonsInJson = this._retrieveAllSongsInSimpleSetForCapsuleId(personalUserRadioSummary.get("id"));
const generatedPlaylist = new SocialDynamicPlaylist([],params);
const capsuleId = personalUserRadioSummary.get("id");
const context = Utils.getInstance().buildContextForSimpleCapsuleSet(capsuleId);
generatedPlaylist.updateContext(context);
this._registerPlaylistSummaryToPlaylistIsPlayingChangeEvent(personalUserRadioSummary, generatedPlaylist);
return generatedPlaylist;
},

generateSongSimpleSetPlaylist : function(personalUserRadioSummary){
const playlistSummaryJson = personalUserRadioSummary.toJSON();
const params = JSON.parse(JSON.stringify(playlistSummaryJson));
params.seedData = personalUserRadioSummary;
params.categoryId =  personalUserRadioSummary.getCategoryId();
params.cityId =  personalUserRadioSummary.getCityId();
params.onRefresh = this._retrieveSimpleSetForSongId(personalUserRadioSummary.get("id"));
params.onRetrieveAllAvailableSonsInJson = this._retrieveAllSongsInSimpleSetForSongId(personalUserRadioSummary.get("id"));
const generatedPlaylist = new SocialDynamicPlaylist([],params);
const songId = personalUserRadioSummary.get("id");
const context = Utils.getInstance().buildContextForSimpleSongSet(songId);
generatedPlaylist.updateContext(context);
this._registerPlaylistSummaryToPlaylistIsPlayingChangeEvent(personalUserRadioSummary, generatedPlaylist);
return generatedPlaylist;
},

generateMyCapsuleFeedPlaylist : function(userId){
const personalUserRadioSummary = Utils.getInstance().buildMyCapsuleFeedRadioSummary(userId);
const playlistSummaryJson = personalUserRadioSummary.toJSON();
const params = JSON.parse(JSON.stringify(playlistSummaryJson));
params.seedData = personalUserRadioSummary;
params.onRefresh = this._retrieveCapsuleFeedForUserId(personalUserRadioSummary.get("id"));
params.categoryId =  personalUserRadioSummary.getCategoryId();
params.cityId =  personalUserRadioSummary.getCityId();
//params.onRetrieveAllAvailableSonsInJson = this._retrieveAllSongsForPersonalUserRadioId(personalUserRadioSummary.get("id"));
const generatedPlaylist = new TrebbleSetDynamicPlaylist([],params);
const context = Utils.getInstance().buildContextForUserCapsuleFeed(params.loaderKey);
generatedPlaylist.updateContext(context);
this._registerPlaylistSummaryToPlaylistIsPlayingChangeEvent(personalUserRadioSummary, generatedPlaylist);
return generatedPlaylist;
},

generateCategoryShortcastRadioPlaylist : function(categoryId, language){
const categoryShortcastRadioSummary = Utils.getInstance().buildCategoryShortcastRadioSummary(categoryId);
const playlistSummaryJson = categoryShortcastRadioSummary.toJSON();
const params = JSON.parse(JSON.stringify(playlistSummaryJson));
params.seedData = categoryShortcastRadioSummary;
params.onRefresh = this._retrieveCapsulePlaylistForCategories(categoryId, language);
params.categoryId =  categoryId;
params.language =  language;
const generatedPlaylist = new TrebbleSetDynamicPlaylist([],params);
const context = Utils.getInstance().buildContextForCategoryShortcatRadio(categoryId);
generatedPlaylist.updateContext(context);
this._registerPlaylistSummaryToPlaylistIsPlayingChangeEvent(categoryShortcastRadioSummary, generatedPlaylist);
return generatedPlaylist;
},


_registerPlaylistSummaryToPlaylistIsPlayingChangeEvent : function(playlistSummaryModel, playlistModel){
playlistModel.on("isPlayingChanged",(function(newIsPlayingValue){
this.playlistSummaryModel.setPlaying(newIsPlayingValue);
}).bind({"playlistSummaryModel": playlistSummaryModel,"playlistModel": playlistModel }));
},

getAutoPlaylistById : function(autoPlaylistId){

return  new RSVP.Promise((function(resolve, reject){
try{
    let playlistToReturn = null;
    if(autoPlaylistId == AUTO_PLAYLIST_IDS.RECENTLY_PLAYED_BY_FOLLOWINGS){
        playlistToReturn = this.generatePlaylistOfSongsRecentlyPlayedByOtherUsers();
    }
    if(autoPlaylistId == AUTO_PLAYLIST_IDS.RECENTLY_ADDED_OR_FAVORITED_BY_FOLLOWINGS){
        playlistToReturn = this.generatePlaylistOfSongsRecentlyAddedOrFavoritedByOtherUsers();
    }
    if(autoPlaylistId == AUTO_PLAYLIST_IDS.MY_RECENTLY_ADDED){
        playlistToReturn = this.generatePlaylistOfMyRecentlyAddedSongs();
    }
    resolve(playlistToReturn);
}catch(err){
    reject(err);
}
}).bind(this));

},

getPersonalUserRadioPlaylistById : function(personalUserRadioSummary){

return  new RSVP.Promise((function(resolve, reject){
try{
    const playlistToReturn = this.generatePersonalUserRadioPlaylist(personalUserRadioSummary);
    resolve(playlistToReturn);
}catch(err){
    reject(err);
}
}).bind(this));

},


getJourneyPlaylistForCapsuleId : function(capsuleId, journeySummary){

return  new RSVP.Promise((function(resolve, reject){
try{
    const playlistToReturn = this.generateJourneyDayPlaylist(capsuleId, journeySummary);
    resolve(playlistToReturn);
}catch(err){
    reject(err);
}
}).bind(this));

},


getGenrePlaylistById : function(genrePlaylistId){

return  new RSVP.Promise((function(resolve, reject){
try{
    const genreInfo  = PersistentModels.getInstance().getGenreIdToGenreInfo()[genrePlaylistId];
    const options =  {};
    options.name = genreInfo.genre;
    options.description = "";
    options.loaderKey =  genreInfo.genre;
    options.id =  genreInfo.genreId;
    options.categoryId =  Utils.getInstance().getTrebbleMusicCategoryId();
    options.onRefresh = this._buildGenrePlaylistLoader(genrePlaylistId);
    const playlist =  new AutoFixedPlaylist([], options);
    const context = Utils.getInstance().buildContextForSongCollectionMyLocalAutoRadio();
    playlist.updateContext(context);
    resolve(playlist);
}catch(err){
    reject(err);
}
}).bind(this));

},

_buildGenrePlaylistLoader : function(genrePlalistId){
return (function(){
return RSVP.Promise.resolve(PersistentModels.getInstance().getGenreIdToGenreInfo()[this.genrePlalistId].songs);
}).bind({"self": this, "genrePlalistId": genrePlalistId });
},

getMyRadioPlaylistById : function(myRadioPlaylistId){

return  new RSVP.Promise((function(resolve, reject){
try{
    const songModelsInPlaylist  = PersistentModels.getInstance().getSongsForTagId(myRadioPlaylistId);
    const playlistToReturn = this.generatePlaylistContainingSong(songModelsInPlaylist);
    playlistToReturn.updateContext(Utils.getInstance().buildContextForSongCollectionUserRadio(myRadioPlaylistId));
    resolve(playlistToReturn);
}catch(err){
    reject(err);
}
}).bind(this));

},

getDefaultGrouTags : function(){
const allGroupTags = [];
const ratingGroupTag = {};
ratingGroupTag.id = PersistentModels.getInstance().getGeneralRatingTagGroupId();
ratingGroupTag.label = {"EN": "Rating"};
ratingGroupTag.exclusive = true;
allGroupTags.push(ratingGroupTag);
return allGroupTags;
},


getDefaultTags : function(){
const allTags = [];
const generalRatingTagGroup = PersistentModels.getInstance().getGeneralRatingTagGroupId();
/*	const allTimeFavoriteTag = {
"id": "ratingId:10",
"exclusive" : true,
"label": {"EN": "All Star"},
"value" : "All Star",
"groupId" : generalRatingTagGroup,
};
allTags.push(allTimeFavoriteTag);*/
const gradeATag = {
"id":"ratingId:9",
"exclusive" : true,
"label": {"EN": window.getI18n(ti18n, "A_GRADE")+ this._getStarsInHtml(3)},
"subLabel": {"EN": window.getI18n(ti18n, "PLAYS_FREQUENTLY")},
"value" : "A Grade "+ this._getStarsInHtml(3),
"groupId" : generalRatingTagGroup,
};
allTags.push(gradeATag);
const gradeBTag = {
"id": "ratingId:8",
"exclusive" : true,
"label": {"EN": window.getI18n(ti18n, "B_GRADE")+ this._getStarsInHtml(2)},
"subLabel": {"EN": window.getI18n(ti18n, "PLAYS_SOMETIMES")},
"value" : "B Grade "+ this._getStarsInHtml(2),
"groupId" : generalRatingTagGroup,
};
allTags.push(gradeBTag);
const gradeCTag = {
"id":"ratingId:7",
"exclusive" : true,
"label": {"EN": window.getI18n(ti18n, "C_GRADE")+ this._getStarsInHtml(1)},
"subLabel": {"EN": window.getI18n(ti18n, "PLAYS_RARELY")},
"value" : "C Grade "+ this._getStarsInHtml(1),
"groupId" : generalRatingTagGroup,
};
allTags.push(gradeCTag);
/*const needToListenAgainTag = {
"id": "ratingId:6",
"exclusive" : true,
"label": {"EN": "Not Sure Yet"},
"value" : "Not Sure Yet",
"groupId" : generalRatingTagGroup,
};
allTags.push(needToListenAgainTag);
*/


return allTags;


},

_getStarsInHtml : function(numberOfStars){
    let html = "";
for(let index =0 ; index < numberOfStars; index++){
html = html + "<i class='ion-ios7-star'></i>";
}
return html;
},

getDefaultAutoPlaylistSummaries : function(){
const playlists = [];

const iamFeelingLuckyPlaylistParams = {};
iamFeelingLuckyPlaylistParams.name  = window.getI18n(ti18n, "I_M_FEELING_LUCKY");
iamFeelingLuckyPlaylistParams.description  = window.getI18n(ti18n, "BASED_ON_THE_MUSIC_YOU_LISTENED_TO");
iamFeelingLuckyPlaylistParams.loaderKey  = AUTO_PLAYLIST_IDS.I_AM_FEELING_LUCKY;
iamFeelingLuckyPlaylistParams.author  = null;
iamFeelingLuckyPlaylistParams.artistSample =  null;
iamFeelingLuckyPlaylistParams.categoryId =  Utils.getInstance().getTrebbleMusicCategoryId();
iamFeelingLuckyPlaylistParams.lastUpdateDateString =  null;
iamFeelingLuckyPlaylistParams.id = iamFeelingLuckyPlaylistParams.loaderKey;
iamFeelingLuckyPlaylistParams.audioPreviewUrl = null;
iamFeelingLuckyPlaylistParams.coverArtUrl = null;
iamFeelingLuckyPlaylistParams.coverArtUrlBlur = null;
iamFeelingLuckyPlaylistParams.coverArtUrlInfo = null;
iamFeelingLuckyPlaylistParams.coverArtUrlBlurInfo = null;
iamFeelingLuckyPlaylistParams.coverArtBase64 = null;
const iamFeelingLuckyPlaylist = Utils.getInstance().buildPlaylistSummaryFromJson(iamFeelingLuckyPlaylistParams );

const myRecentlyAddedPlaylistParams = {};
myRecentlyAddedPlaylistParams.name  = window.getI18n(ti18n, "MY_RECENTLY_ADDED_SONGS");
myRecentlyAddedPlaylistParams.description  = window.getI18n(ti18n, "THIS_IS_PLAYLIST_OF_THE_SONGS_YOU_ADDED");
myRecentlyAddedPlaylistParams.loaderKey  = AUTO_PLAYLIST_IDS.MY_RECENTLY_ADDED;
myRecentlyAddedPlaylistParams.author  = null;
myRecentlyAddedPlaylistParams.artistSample =  null;
myRecentlyAddedPlaylistParams.categoryId =  Utils.getInstance().getTrebbleMusicCategoryId();
myRecentlyAddedPlaylistParams.lastUpdateDateString =  null;
myRecentlyAddedPlaylistParams.id = myRecentlyAddedPlaylistParams.loaderKey;
myRecentlyAddedPlaylistParams.audioPreviewUrl = null
myRecentlyAddedPlaylistParams.coverArtUrl = null;
myRecentlyAddedPlaylistParams.coverArtUrlBlur = null;
myRecentlyAddedPlaylistParams.coverArtUrlInfo = null;
myRecentlyAddedPlaylistParams.coverArtUrlBlurInfo = null;
myRecentlyAddedPlaylistParams.coverArtBase64 = null;
const myRecentlyAddedPlaylist = Utils.getInstance().buildPlaylistSummaryFromJson(myRecentlyAddedPlaylistParams );

const recentlyAddedPlaylistByFollowingParams = {};
recentlyAddedPlaylistByFollowingParams.name  = window.getI18n(ti18n, "RECENTLY_ADDED_SONGS_OR_FAVORITED_BY_FOLLOWINGS");
recentlyAddedPlaylistByFollowingParams.description  = window.getI18n(ti18n, "HEAR_THE_NEW_MUSIC_FROM_PEOPLE_YOU_FOLLOW");
recentlyAddedPlaylistByFollowingParams.loaderKey  = AUTO_PLAYLIST_IDS.RECENTLY_ADDED_OR_FAVORITED_BY_FOLLOWINGS;
recentlyAddedPlaylistByFollowingParams.author  = null;
recentlyAddedPlaylistByFollowingParams.artistSample =  null;
recentlyAddedPlaylistByFollowingParams.categoryId =  Utils.getInstance().getTrebbleMusicCategoryId();
recentlyAddedPlaylistByFollowingParams.lastUpdateDateString =  null;
recentlyAddedPlaylistByFollowingParams.id = recentlyAddedPlaylistByFollowingParams.loaderKey;
recentlyAddedPlaylistByFollowingParams.audioPreviewUrl = null;
recentlyAddedPlaylistByFollowingParams.coverArtUrl = null;
recentlyAddedPlaylistByFollowingParams.coverArtUrlBlur = null;
recentlyAddedPlaylistByFollowingParams.coverArtUrlInfo = null;
recentlyAddedPlaylistByFollowingParams.coverArtUrlBlurInfo = null;
recentlyAddedPlaylistByFollowingParams.coverArtBase64 = null;

const recentlyAddedPlaylistByFollowing = Utils.getInstance().buildPlaylistSummaryFromJson(recentlyAddedPlaylistByFollowingParams  );

const recentlyAddedPlayedByFollowingParams = {};
recentlyAddedPlayedByFollowingParams.name  = window.getI18n(ti18n, "RECENTLY_PLAYED_BY_FOLLOWINGS");
recentlyAddedPlayedByFollowingParams.description  = window.getI18n(ti18n, "HEAR_MUSIC_RECENTLY_PLAYED_BY_FOLLOWINGS");
recentlyAddedPlayedByFollowingParams.loaderKey  = AUTO_PLAYLIST_IDS.RECENTLY_PLAYED_BY_FOLLOWINGS;
recentlyAddedPlayedByFollowingParams.author  = null;
recentlyAddedPlayedByFollowingParams.artistSample =  null;
recentlyAddedPlayedByFollowingParams.categoryId =  Utils.getInstance().getTrebbleMusicCategoryId();
recentlyAddedPlayedByFollowingParams.lastUpdateDateString =  null;
recentlyAddedPlayedByFollowingParams.id = recentlyAddedPlayedByFollowingParams.loaderKey;
recentlyAddedPlayedByFollowingParams.audioPreviewUrl = null;
recentlyAddedPlayedByFollowingParams.coverArtUrl = null;
recentlyAddedPlayedByFollowingParams.coverArtUrlBlur = null;
recentlyAddedPlayedByFollowingParams.coverArtUrlInfo = null;
recentlyAddedPlayedByFollowingParams.coverArtUrlBlurInfo = null;
recentlyAddedPlayedByFollowingParams.coverArtBase64 = null;

const recentlyAddedPlayedByFollowing = Utils.getInstance().buildPlaylistSummaryFromJson(recentlyAddedPlayedByFollowingParams  );


const stuckInMyHeadParams = {};
stuckInMyHeadParams.name  = window.getI18n(ti18n, "STUCK_IN_MY_HEAD");
stuckInMyHeadParams.description  = window.getI18n(ti18n, "LISTEN_TO_MUSIC_YOU_LISTENED_IN_LOOP");
stuckInMyHeadParams.loaderKey  = AUTO_PLAYLIST_IDS.STUCK_IN_MY_HEAD;
stuckInMyHeadParams.author  = null;
stuckInMyHeadParams.artistSample =  null;
stuckInMyHeadParams.categoryId =  Utils.getInstance().getTrebbleMusicCategoryId();
stuckInMyHeadParams.lastUpdateDateString =  null;
stuckInMyHeadParams.id = stuckInMyHeadParams.loaderKey;
stuckInMyHeadParams.audioPreviewUrl = null;
stuckInMyHeadParams.coverArtUrl = null;
stuckInMyHeadParams.coverArtUrlBlur = null;
stuckInMyHeadParams.coverArtUrlInfo = null;
stuckInMyHeadParams.coverArtUrlBlurInfo = null;
stuckInMyHeadParams.coverArtBase64 = null;
const stuckInMyHead = Utils.getInstance().buildPlaylistSummaryFromJson(stuckInMyHeadParams);


const stuckInMyFollowingHeadParams = {};
stuckInMyFollowingHeadParams.name  = window.getI18n(ti18n, "STUCK_IN_FOLLOWINGS_HEAD");
stuckInMyFollowingHeadParams.description  = window.getI18n(ti18n, "LISTEN_TO_MUSIC_FOLLOWINGS_LISTENED_IN_LOOP");
stuckInMyFollowingHeadParams.loaderKey  = AUTO_PLAYLIST_IDS.STUCK_IN_MY_HEAD_FOLLOWINGS;
stuckInMyFollowingHeadParams.author  = null;
stuckInMyFollowingHeadParams.artistSample =  null;
stuckInMyFollowingHeadParams.categoryId =  Utils.getInstance().getTrebbleMusicCategoryId();
stuckInMyFollowingHeadParams.lastUpdateDateString =  null;
stuckInMyFollowingHeadParams.id = stuckInMyFollowingHeadParams.loaderKey;
stuckInMyFollowingHeadParams.audioPreviewUrl = null;
stuckInMyFollowingHeadParams.coverArtUrl = null;
stuckInMyFollowingHeadParams.coverArtUrlBlur = null;
stuckInMyFollowingHeadParams.coverArtUrlInfo = null;
stuckInMyFollowingHeadParams.coverArtUrlBlurInfo = null;
stuckInMyFollowingHeadParams.coverArtBase64 = null;
const stuckInMyFollowingHead = Utils.getInstance().buildPlaylistSummaryFromJson(stuckInMyFollowingHeadParams );


const trendingNearByParams = {};
trendingNearByParams.name  = window.getI18n(ti18n, "TRENDING_NEAR_BY");
trendingNearByParams.description  = window.getI18n(ti18n, "LISTEN_TO_TRENDING_NEAR_BY");
trendingNearByParams.loaderKey  = AUTO_PLAYLIST_IDS.TRENDING_NEAR_BY;
trendingNearByParams.author  = null;
trendingNearByParams.artistSample =  null;
trendingNearByParams.categoryId =  Utils.getInstance().getTrebbleMusicCategoryId();
trendingNearByParams.lastUpdateDateString =  null;
trendingNearByParams.id = trendingNearByParams.loaderKey;
trendingNearByParams.audioPreviewUrl =  null;
trendingNearByParams.coverArtUrl = null;
trendingNearByParams.coverArtUrlBlur = null;
trendingNearByParams.coverArtUrlInfo = null;
trendingNearByParams.coverArtUrlBlurInfo = null;
trendingNearByParams.coverArtBase64 = null;
const trendingNearBy = Utils.getInstance().buildPlaylistSummaryFromJson(trendingNearByParams  );



playlists.push(iamFeelingLuckyPlaylist);
playlists.push(myRecentlyAddedPlaylist);
playlists.push(stuckInMyHead);
playlists.push(recentlyAddedPlaylistByFollowing);
playlists.push(recentlyAddedPlayedByFollowing);
playlists.push(stuckInMyFollowingHead);
playlists.push(trendingNearBy);
return playlists;
},

_retrieveSongsRecentlyPlayedByOtherUsers: function(options){
return TrebbleClientAPIHelper.getInstance().getListOfSongRecentlyPlayedByAllOthers(options);
},

_retrieveSongsRecentlyAddedOrFavoritedByOtherUsers: function(options){
return TrebbleClientAPIHelper.getInstance().getListOfSongRecentlyAddedOrFavoritedByAllOthers(options);
},

_retrieveSongsForPersonalUserRadioId: function(personalUserRadioId){
return function(options){
return TrebbleClientAPIHelper.getInstance().getListOfSongFromPersonalUserId(personalUserRadioId, options);
};

},

_retrieveShortcastToPlayNextForJourneyRadioId: function(capsuleId, journeyRadioId){
return function(options){
return TrebbleClientAPIHelper.getInstance().getPlaylistOfNextShortcastToPlayForJourney(capsuleId, journeyRadioId, options);
};
},

_retrieveCapsuleFeedForUserId: function(userId){
return function(options){
if(!options){
    options = {};

}
options.doNotUseTextToSpeech = Utils.getInstance().isAudioAnnouncementsDisabledLocallyForUser();
return TrebbleClientAPIHelper.getInstance().getCapsuleFeedForUserId(userId, options);
};

},
_retrieveCapsulePlaylistForCategories: function(categoryId, language){
return function(options){
if(!options){
    options = {};

}
options.doNotUseTextToSpeech = Utils.getInstance().isAudioAnnouncementsDisabledLocallyForUser();
return TrebbleClientAPIHelper.getInstance().getCapsulePlaylistForCategories([categoryId], language, options);
};

},

_retrieveAllSongsForPersonalUserRadioId: function(personalUserRadioId){
return function(options){
return TrebbleClientAPIHelper.getInstance().getAllSongInPersonalUserRadio(personalUserRadioId);
};

},

_retrieveMostRecentSongsForPersonalUserRadioId: function(personalUserRadioId, numberOfSongs){
return function(options){
return TrebbleClientAPIHelper.getInstance().getMostRecentSongsInPersonalUserRadio(personalUserRadioId, numberOfSongs);
};

},



_retrieveSimpleSetForCapsuleId: function(capsuleId){
return function(options){
return TrebbleClientAPIHelper.getInstance().getSimpleSetForCapsuleId(capsuleId);
};

},

_retrieveSimpleSetForSongId: function(songId){
return function(options){
return TrebbleClientAPIHelper.getInstance().getSimpleSetForSongId(songId);
};

},
_retrieveAllSongsInSimpleSetForCapsuleId: function(capsuleId){
return function(options){
return TrebbleClientAPIHelper.getInstance().getSimpleSetForCapsuleId(capsuleId).then(function(socialDynamicPlaylist){
    if(socialDynamicPlaylist){
        return socialDynamicPlaylist.songs
    }
    return null;
});
};

},

_retrieveAllSongsInSimpleSetForSongId: function(songId){
return function(options){
return TrebbleClientAPIHelper.getInstance().getSimpleSetForSongId(songId).then(function(socialDynamicPlaylist){
    if(socialDynamicPlaylist){
        return socialDynamicPlaylist.songs
    }
    return null;
});
};

},



_retrieveMyRecentlyAddedSongs : function(options){
const songCollection = PersistentModels.getInstance().getSongCollection();
const allSongsArray = songCollection? songCollection.toJSON(): [];
const sortedSongArray = _.sortBy(allSongsArray, function(songJson) {
    let dateAdded = songJson.dateAdded;
if(dateAdded && ( dateAdded instanceof Date))
{
    dateAdded = dateAdded.getTime();
}
if(dateAdded && ( typeof dateAdded == "string"))
{
    dateAdded = new Date(dateAdded).getTime();
}
return  dateAdded;
});
sortedSongArray.reverse();
return RSVP.Promise.resolve(sortedSongArray);
},





});

    const PlaylistHelperInstance = new PlaylistHelper();

    export default {
		getInstance : function() {
			return PlaylistHelperInstance;
		}
	};