import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import Levenshtein from "libs/levenshtein/levenshtein";
import ti18n from "i18n!nls/helperi18n";
import RSVP from "rsvp";


const DEFAULT_MUSIC_CATEGORY_ID = "10";

const DEFAULT_LEVENSTEIN_DISTANCE_ACCEPTABLE= 0.4;
const ACCEPTABLE_TIME_DIFFERENCE_IN_MILLISEC= 3000
const SENSIBLE_KEY_WORDS = [ "cover","chopped","screwed"];

const YoutubeSearchHelper =  Backbone.Model.extend({

    initialize : function() {

    },
    _searchYoutube : function(query, onlyAllowMusicCategory){
        return new RSVP.Promise(function(resolve, reject){
            try{
                const params = {
                    q: query,
                    // order : "viewCount",
                    type: "video",
                    part: 'snippet'
                };
                if(onlyAllowMusicCategory){
                    params.videoCategoryId = DEFAULT_MUSIC_CATEGORY_ID;
                }
                const request = window.gapi.client.youtube.search.list(params);
                request.execute(function(response) {
                    if(response && response.result)
                    {
                        resolve(response.result)
                    }else{
                        reject(window.getI18n(ti18n, "FAILED_SEARCHING_FOR_YOUTUBE_VIDEOS"));
                    }


                });
            }catch(err){
                reject(err);
            }
        });
    },

    getSongJsonFromVideos : function(query, numberOfResults, doNotParseVideoTitleToExtractSongTitle){
        return this._loadGAPI().then(function(){
            return window._onYoutubeLoadedPromise;
        }).then((function(){
            return new RSVP.Promise(function(resolve, reject){
                try{

                    const request = window.gapi.client.youtube.search.list({
                        q: query,
                        type: "video",
                        part: 'snippet',
                        maxResults : numberOfResults
                    });
                    request.execute(function(response) {
                        if(response && response.result)
                        {
                            const arrayOfSongJson = [];
                            if(response.result && response.result.items  && response.result.items.length > 0)
                            {
                                for(let index  in response.result.items)
                                {
                                    const youtubeVideoInfo = response.result.items[index];
                                    if(youtubeVideoInfo.id && youtubeVideoInfo.id.videoId)
                                    {
                                        youtubeVideoInfo.id  = youtubeVideoInfo.id.videoId;
                                    }
                                    const trebbleSongJson = Utils.getInstance().getTrebblesSongJsonFromYoutubeVideoInfo(youtubeVideoInfo, doNotParseVideoTitleToExtractSongTitle);
                                    arrayOfSongJson.push(trebbleSongJson);
                                }

                            }
                            resolve(arrayOfSongJson);
                        }else{
                            reject(window.getI18n(ti18n, "FAILED_SEARCHING_FOR_YOUTUBE_VIDEOS"));
                        }


                    });
                }catch(err){
                    reject(err);
                }
            });
}).bind(this));
},

_getVideoInfoFromVideoId : function(videoId){
return new RSVP.Promise(function(resolve, reject){
try{
    
    const request = window.gapi.client.youtube.videos.list({
        id: videoId,
        // order : "viewCount",
        type: "video",
        part: 'snippet'
    });
    request.execute(function(response) {
        if(response && response.result)
        {
            resolve(response.result)
        }else{
            console.error("Failed locating youtube video info for youtube video id:"+videoId);
            reject(window.getI18n(ti18n, "FAILED_SEARCHING_FOR_YOUTUBE_VIDEOS"));
        }


    });
}catch(err){
    reject(err);
}
});
},

_loadGAPI : function(){
return new Promise((resolve, reject) => {
window._loadScript('https://apis.google.com/js/client')
  .then(() => {
    // Check if the Google API client is available
    if (typeof window.gapi !== 'undefined') {
      // Assuming _onGAuthLoadedPromise is a global variable or defined elsewhere
      // You can return it if it's available
      resolve(window._onGAuthLoadedPromise);
    } else {
      reject(new Error('Failed to load Google API client'));
    }
  })
  .catch(reject);
});

},

getMostViewVideoInfo_old: function(searchValue, onlyAllowMusicCategory){
return this._loadGAPI().then(function(){
return window._onYoutubeLoadedPromise;
}).then((function(){
if(window.gapi && window.gapi.client && window.gapi.client.youtube)
{
    return this._searchYoutube(searchValue, onlyAllowMusicCategory).then(function(result){
        if(result && result.items && result.items.length >0)
        {
            return result.items[0].id;
        }

    });


}else{
    window.log("Youtube API not ready");

    return RSVP.Promise.reject(window.getI18n("YOUTUBE_API_NOT_READY"));
}
}).bind(this))

},

getMostViewVideoInfo: function(searchValue, onlyAllowMusicCategory){
return this._loadGAPI().then(function(){
return window._onYoutubeLoadedPromise;
}).then((function(){
if(window.gapi && window.gapi.client && window.gapi.client.youtube)
{
    return this._searchYoutube(searchValue, onlyAllowMusicCategory).then((function(result){
        if(result && result.items && result.items.length >0)
        {
            let youtubeSongId = null;

            for(let index = 0; index <  result.items.length; index++){
                const jsonSong =  result.items[index];
                const artistAndTitle = jsonSong.snippet.title ;
                if(this.isStringMatchSensibleKeyWord(searchValue, artistAndTitle)){
                    const l= new Levenshtein(searchValue? searchValue.toUpperCase(): searchValue, artistAndTitle? artistAndTitle.toUpperCase():artistAndTitle)
                    const distance = l.distance;
                    const biggerLength = Math.max(searchValue.length, artistAndTitle.length);
                    const pct = (biggerLength - distance) / biggerLength
                    const canYoutubeVideoBeUsed = pct >DEFAULT_LEVENSTEIN_DISTANCE_ACCEPTABLE? true : false;

                    if(canYoutubeVideoBeUsed){
                        youtubeSongId = jsonSong.id;
                        break;
                    }
                }
            }
            return youtubeSongId;
        }
    }).bind(this));
}else{
    window.log("Youtube API not ready");

    return RSVP.Promise.reject(window.getI18n("YOUTUBE_API_NOT_READY"));
}
}).bind(this));

},

isStringMatchSensibleKeyWord : function(word1, word2){
if(!word1 || !word1 ){
return true;
}else{
const word1lowerCase = word1.toLowerCase();
const word2lowerCase = word2.toLowerCase();
for(let index in SENSIBLE_KEY_WORDS){
    const sensibleKeyWord = SENSIBLE_KEY_WORDS[index];
    const word1ContainsKeyword = word1lowerCase.indexOf(sensibleKeyWord) > -1;
    const word2ContainsKeyword = word2lowerCase.indexOf(sensibleKeyWord) > -1;
    if((!word1ContainsKeyword && word2ContainsKeyword) || (word1ContainsKeyword && !word2ContainsKeyword)){
        return false;
    }
}
}
return true;

},


getVideoInfoFromVideoId: function(videoId){

return this._loadGAPI().then(function(){
return window._onYoutubeLoadedPromise;
}).then((function(){
if(window.gapi && window.gapi.client && window.gapi.client.youtube)
{
    return this._getVideoInfoFromVideoId(videoId).then(function(result){
        if(result && result.items && result.items.length >0)
        {
            return result.items[0];
        }

    });


}else{
    window.log("Youtube API not ready");
    return new RSVP.Promise((function(resolve, reject){

        window._onYoutubeLoadedPromise.then((function(){
            this._getVideoInfoFromVideoId(videoId).then(function(result){

                if(result && result.items && result.items.length >0)
                {
                    return resolve(result.items[0]);
                }else{
                    return reject(window.getI18n(ti18n, "COULDNT_FIND_YOUTUBE_VIDEO_WITH_ID"));
                }

            }).catch(reject);
        }).bind(this));

    }).bind(this));

}
}).bind(this));

},
});
const youtubeSearchHelperInstance = new YoutubeSearchHelper();

export default {
	getInstance : function() {
		return youtubeSearchHelperInstance;
	}
};