import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import OptionPopupView from "views/common/OptionPopupView";
import Utils from "models/helper/Utils";
import AddOrScheduleCapsuleToTrebblePopupTemplate from 'text!../../../templates/capsule/AddOrScheduleCapsuleToTrebblePopupTemplate.html';
import ti18n from "i18n!nls/CapsuleDetailsi18n";
import PersistentModels from "services/PersistentModels";
import moment from 'moment';
import {tz as momentTimezone} from 'moment-timezone';


const PUBLISHED_SETTING_VALUES = {"NOW":"now","SCHEDULED":"scheduled"};
const AddOrScheduleCapsuleToTrebblePopupView = OptionPopupView.extend({


    initialize: function(options) {
        const viewOptions = {};
        const contentView = {};
        const contentViewTemplate = _.template(AddOrScheduleCapsuleToTrebblePopupTemplate);
        const templateParams = {};
        templateParams.ti18n = ti18n;
        contentView.$el = $(contentViewTemplate(templateParams));
        viewOptions.contentView = contentView;
        viewOptions.addContentAfterEnhancement = false;
        viewOptions.contentPosition = "center";
        viewOptions.displayTitle = true;
        viewOptions.changeScheduleTime = options && options.changeScheduleTime?options.changeScheduleTime : false;
        viewOptions.presetOnAirDurationInDays = options && options.presetOnAirDurationInDays?options.presetOnAirDurationInDays : false;
        viewOptions.presetScheduledTime = options && options.presetScheduledTime? options.presetScheduledTime: null; 
        viewOptions.titleToDisplay = options && options.changeScheduleTime? window.getI18n(ti18n,"CHANGE_PUBLISH_TIME"): window.getI18n(ti18n,"ADD_CAPSULE_TO_TREBBLE");
        this._popupUniqueId = "AddOrScheduleCapsuleToTrebblePopupView";
        viewOptions.id = this._popupUniqueId;
        this._timezoneCollection = options && options.timezoneCollection? options.timezoneCollection: null;
        this._selectedDefaultTimezone = options && options.selectedDefaultTimezone? options.selectedDefaultTimezone: null;
        this._presetScheduledTime = options && options.presetScheduledTime? options.presetScheduledTime: null; 
        this._presetTimezone = options && options.presetTimezone? options.presetTimezone: null; 
        this._presetOnAirDurationInDays = options && options.presetOnAirDurationInDays? options.presetOnAirDurationInDays: null;
        //viewOptions.customClass = "whatsnew";
        OptionPopupView.prototype.initialize.call(this, viewOptions);
        //PersistentModels.getInstance().getRouter().registerForViewportHeightChange(this._popupUniqueId,this.onOrientationChangeFunction.bind(this), true);
    },

    /* onOrientationChangeFunction  : function(newViewPortHeigh, forceResizing) {
     if(this.$el.is(":visible") || forceResizing){
      if (this.el) {
         const adjustedListContainerHeight = (newViewPortHeigh) ;
         this.$el.find(".ui-content").css("height", adjustedListContainerHeight + "px");
     }
 }
},*/


getLifeSpan: function() {
return this.$el.find("#capsuleLifeSpanFld option:selected").val();
},

_initpublishingSettingField :function(){
this.$el.find("#publishingSettingFld").on("change",this._hideShowSchedulingDateFieldIfApplicable.bind(this));
},

_getTheRestOfTheDayCapsuleLifeSpanOption$El : function(){
const optionArray = this.$el.find("#capsuleLifeSpanFld").children();
for(let i =0; i < optionArray.length; i++){
    const option$el = $(optionArray[i]);
    const value = option$el.val();
    if(value && Utils.getInstance().isFloat(parseFloat(value))){
        //this is most likely the rest of the day option
        return option$el;
    }
}
},




_close : function(){
this.$el.popup('close' );
},
_initSchedulingDatePicker : function(){
const tomorrow = new Date();
tomorrow.setDate(new Date().getDate()+1);
tomorrow.setHours(0,0,0,0);
const presetScheduledTime = this._presetScheduledTime;
const presetScheduledTimeAdjustedToTimezone = this._presetTimezone? new Date(moment(presetScheduledTime).tz(this._presetTimezone).format("D MMM, YYYY HH:mm")): presetScheduledTime;
const startAtPresetTime = (this._presetScheduledTime && new Date().getTime() <= this._presetScheduledTime.getTime()); 

this.schedulingSettingFld$el.pickadate({
    selectMonths: true, // Creates a dropdown to control month
    selectYears: 10,
    min: tomorrow,
    formatSubmit: 'yyyy/mm/dd',
    today: window.getI18n(ti18n,"TODAY"),
    clear: window.getI18n(ti18n,"CLEAR"),
    close: window.getI18n(ti18n,"OK"),
    onStart: function ()
    {
        const date = startAtPresetTime? presetScheduledTimeAdjustedToTimezone: new Date();
        this.set('select', [date.getFullYear(), date.getMonth() , startAtPresetTime?date.getDate() :(date.getDate())+ 1]);
    },
    onClose: function() {
        $(document.activeElement).blur();
    },
    closeOnSelect: false, // Close upon selecting a date,
    container: this.schedulingSettingWrapper$el,
    containerHidden: this.schedulingSettingWrapper$el,
});

if(startAtPresetTime){
    this.$el.find("#publishingSettingFld").find(":selected").removeAttr("selected");
    this.$el.find("#publishingSettingFld").find("[value='"+PUBLISHED_SETTING_VALUES.SCHEDULED+"']").attr("selected","selected");
    this.$el.find("#publishingSettingFld").val(PUBLISHED_SETTING_VALUES.SCHEDULED).change();
    if(this._presetOnAirDurationInDays){
        this.$el.find("#capsuleLifeSpanFld").find(":selected").removeAttr("selected");
        this.$el.find("#capsuleLifeSpanFld").find("[value='"+this._presetOnAirDurationInDays+"']").attr("selected","selected");
        this.$el.find("#capsuleLifeSpanFld").val(this._presetOnAirDurationInDays).change();
    }
    if(this._presetTimezone){
        this.$el.find("#schedulingTimezoneFld").find(":selected").removeAttr("selected");
        this.$el.find("#schedulingTimezoneFld").find("[value='"+this._presetTimezone+"']").attr("selected","selected");
        this.$el.find("#schedulingTimezoneFld").val(this._presetTimezone).change();
    }
    this._hideShowSchedulingDateFieldIfApplicable();
}
},


_initSchedulingTimePicker : function(){
const presetScheduledTime = this._presetScheduledTime;
const presetScheduledTimeAdjustedToTimezone = this._presetTimezone? new Date(moment(presetScheduledTime).tz(this._presetTimezone).format("D MMM, YYYY HH:mm")): presetScheduledTime;
const startAtPresetTime = (this._presetScheduledTime && new Date().getTime() <= this._presetScheduledTime.getTime()); 


let startTimeString = "00:00";
if(startAtPresetTime){
    const hours = presetScheduledTimeAdjustedToTimezone.getHours();
    const minutes = presetScheduledTimeAdjustedToTimezone.getMinutes();
    startTimeString = ""+((hours< 10)?"0"+hours:hours)+":"+ ((minutes< 10)?"0"+minutes:minutes);
}

this.schedulingTimeFld$el.pickatime({
    default: startTimeString,
    twelvehour: false, // Use AM/PM or 24-hour format
    donetext: window.getI18n(ti18n,"OK"), // text for done-button
    cleartext: window.getI18n(ti18n,"CLEAR"), // text for clear-button
    canceltext: window.getI18n(ti18n,"CANCEL"), // Text for cancel-button,
    container: this.schedulingTimeWrapper$el,
    containerHidden: this.schedulingTimeWrapper$el,
    autoclose: true, // automatic close timepicker
    ampmclickable: true, // make AM PM clickable
    onClose: function() {
        $(document.activeElement).blur();
    },
    

});
this.schedulingTimeFld$el.val(startTimeString);
},


isScheduled : function(){
return  PUBLISHED_SETTING_VALUES.SCHEDULED === this.getPublishedSetting();
},


getPublishedSetting : function(){
return this.$el.find("#publishingSettingFld option:selected").val();
},

getScheduleStartDate : function(){
if( this.isScheduled()){
    const dateString = this.schedulingSettingFld$el.val(); 
    const dateStringSubmitFormat =  this.schedulingSettingWrapper$el.find("input[name=schedulingSettingFld_submit]").val();
    const timeString = this.schedulingTimeFld$el.val();
    //return dateString ? new Date(dateString): dateString;
    //return dateString ? momentTimezone(dateString + " "+ timeString,"D MMM, YYYY HH:mm", this.getTimezone()).toDate(): dateString;
    return dateStringSubmitFormat ? momentTimezone(dateStringSubmitFormat + " "+ timeString,"YYYY/MM/DD HH:mm", this.getTimezone()).toDate(): dateStringSubmitFormat;
}else{
    return null;
}
},



onPopupClosed: function() {
OptionPopupView.prototype.onPopupClosed.call(this);
// PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._popupUniqueId, true);
this.trigger("onPopupClosed");
},

_addArrowToSelectWidget: function() {
this.$el.find("#capsuleLifeSpanFld-button").append('<i class="ion-chevron-down" style="display: inline-block;"></i>');
this.$el.find("#schedulingTimezoneFld-button").append('<i class="ion-chevron-down" style="display: inline-block;"></i>');
this.$el.find("#privacySettingFld-button").append('<i class="ion-chevron-down" style="display: inline-block;"></i>');
this.$el.find("#publishingSettingFld-button").append('<i class="ion-chevron-down" style="display: inline-block;"></i>');

},


_addTimezonesToView : function(){
const selectTimezone$El =  this.$el.find("#schedulingTimezoneFld");
const selectTimezoneLabel$El = this.$el.find("#schedulingTimezoneFld-button > span");
if(this._timezoneCollection){
this._timezoneCollection.each((function(timezoneListItemModel){
    if(this._selectedDefaultTimezone == timezoneListItemModel.getId()){
        selectTimezone$El.append("<option value='"+timezoneListItemModel.getId()+"' selected='selected'>"+ timezoneListItemModel.getLabel()+"</option>");
        selectTimezoneLabel$El.html(timezoneListItemModel.getLabel());
    }else{
        selectTimezone$El.append("<option value='"+timezoneListItemModel.getId()+"'>"+ timezoneListItemModel.getLabel()+"</option>");
    }
}).bind(this));
}
},


getTimezone: function() {
return this.$el.find("#schedulingTimezoneFld option:selected").val();
},

getTimeLeftForTheRestOfTheDayBasedOnTimezone :function(){
const trebbleDefaultTimezone  = this.getTimezone();
const nextMidnightDate = momentTimezone(trebbleDefaultTimezone).set("hour",0).set("minute",0).set("second",0).add(1, "days").toDate();
const aDayInMilliseconds = 24 *60 *60 * 1000;
const timeInDaysUntilTheEndOfTheDay = (nextMidnightDate.getTime() - (new Date()).getTime())/aDayInMilliseconds; 
return timeInDaysUntilTheEndOfTheDay;
},

_onTimezoneEdited : function(){
const timezone = this.getTimezone();
const theRestOfTheDayOption$el = this._getTheRestOfTheDayCapsuleLifeSpanOption$El();
theRestOfTheDayOption$el.val(this.getTimeLeftForTheRestOfTheDayBasedOnTimezone());

},

_hideShowSchedulingDateFieldIfApplicable : function(){
const theRestOfTheDayOption$el = this._getTheRestOfTheDayCapsuleLifeSpanOption$El();
const theRestOfTheDayOptionIsSelected = theRestOfTheDayOption$el.is(':selected');
if(this.isScheduled()){
theRestOfTheDayOption$el.prop("disabled","disabled");
theRestOfTheDayOption$el.removeAttr("selected");
this.$el.find("#capsuleLifeSpanFld").selectmenu('refresh');
this.schedulingSettingWrapper$el.show();
this.schedulingTimeWrapper$el.show();
this.schedulingTimezoneWrapper$el.show();
this.capsuleConfigWrapper$el.insertAfter(this.scheduleInfoWrapper$el);
}else{
theRestOfTheDayOption$el.removeAttr("disabled");
this.schedulingSettingWrapper$el.hide();
this.schedulingTimeWrapper$el.hide();
if(theRestOfTheDayOptionIsSelected){
    this.capsuleConfigWrapper$el.prependTo(this.scheduleInfoWrapper$el);
    this.schedulingTimezoneWrapper$el.show();
}else{  
    this.capsuleConfigWrapper$el.insertAfter(this.scheduleInfoWrapper$el);
    this.schedulingTimezoneWrapper$el.hide();   
}
}
},

afterRendering: function() {
//this.onOrientationChangeFunction(PersistentModels.getInstance().getRouter().getViewportHeight(), true);
//this.$el.enhanceWithin();
this.schedulingSettingWrapper$el =  this.$el.find(".schedulingSettingWrapper");
this.schedulingTimeWrapper$el =  this.$el.find(".schedulingTimeWrapper");
this.publishingSettingWrapper$el =  this.$el.find(".publishingSettingWrapper");
this.capsuleConfigWrapper$el = this.$el.find(".capsuleConfigWrapper");
this.schedulingSettingFld$el = this.$el.find("#schedulingSettingFld");
this.schedulingTimeFld$el = this.$el.find("#schedulingTimeFld");
this.capsuleLifeSpanFld$el = this.$el.find("#capsuleLifeSpanFld");
this.schedulingTimezoneWrapper$el =  this.$el.find(".schedulingTimezoneWrapper");
const addToTrebbleBtn$el = this.$el.find("#addToTrebbleBtn");
this.schedulingTimezoneFld$el = this.$el.find("#schedulingTimezoneFld");
this.scheduleInfoWrapper$el =  this.$el.find(".scheduleInfoWrapper");
this._addTimezonesToView();
this._initpublishingSettingField();
this._initSchedulingDatePicker();
this._initSchedulingTimePicker();
this._addArrowToSelectWidget();
this.schedulingTimezoneFld$el.on("change", this._onTimezoneEdited.bind(this));
this.capsuleLifeSpanFld$el.change(this._hideShowSchedulingDateFieldIfApplicable.bind(this));


addToTrebbleBtn$el.on("click", (function(){
this.trigger("addToTrebble");
}).bind(this));


},



});

export default AddOrScheduleCapsuleToTrebblePopupView;
