import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PersistentModels from "services/PersistentModels";
import ti18n from "i18n!nls/FeedAndAcitivyPagei18n";
import pageTemplate from 'text!../../../templates/feedAndActivities/FeedAndActivitiesPageTemplate.html';
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const FeedAndAcitivitiesPageView = Backbone.View.extend({

    template : _.template(pageTemplate),
    "pageName" : "Artist Detail",

    events : {
        "click #homeHeaderButton": "showHomePage",
        "click #header-back-button": "navigateToPreviousPage",
    },

    initialize : function(options) {
        this._notificationListView = options.notificationListView;
    },
    
    showHomePage : function(){
        this.trigger("showHomePage")
    },

    navigateToPreviousPage : function(){
        PersistentModels.getInstance().getRouter().navigateToPreviousPage();
    },
    
    
    setNotificationListView : function(notificationListView){
        this.$el.find("#myNotificationsListWrapper > #loadingImg").remove();
        this._notificationListView =  notificationListView;
        if(this._notificationListView){
            this.$el.find("#myNotificationsListWrapper").append(this._notificationListView.$el);
            this._notificationListView.getCollectionContainer$el().css("display","-webkit-flex");
            this._notificationListView.getCollectionContainer$el().css("display","-moz-flex");
            this._notificationListView.getCollectionContainer$el().css("display","-ms-flex");
            this._notificationListView.getCollectionContainer$el().css("display","flex");
        }
    },

    getNotificationListView : function(){
        return this._notificationListView;
    },


    _onPageShow : function(){
        this.trigger("pageShow");
    },

    render : function() {
        this.$el.html(this.template({"ti18n":ti18n}));
        this.setElement(this.$el.find("div").first());
        this.$el.page();
    
        //manually adding the wrapper necessary by JQM for showing Panel
        //JQM does it on the fly but that can cause flickering on the screen when it is done by jquery mobile
        //the workaround is to do that manually when the page is getting initialized
        this.$el.children( ".ui-header:not(.ui-header-fixed), .ui-content:not(.ui-popup), .ui-footer:not(.ui-footer-fixed)" ).wrapAll( "<div class='ui-panel-wrapper'></div>" )
        
        this.$el.on("pagehide", this._onPageHide.bind(this));
        this.$el.on("pageshow", this._onPageShow.bind(this));
        if(this._notificationListView){
            this.$el.find("#myNotificationsListWrapper").append(this._notificationListView.$el);
            this._notificationListView.getCollectionContainer$el().css("display","-webkit-flex");
            this._notificationListView.getCollectionContainer$el().css("display","-moz-flex");
            this._notificationListView.getCollectionContainer$el().css("display","-ms-flex");
            this._notificationListView.getCollectionContainer$el().css("display","flex");
        }
        return this;
    },
    
    _onPageHide : function(){
        
        if(PersistentModels.getInstance().getRouter().isBrowserHistoryGoingBack())
        {
            //PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._listenerViewportKey);
            this.onBeforeRemoveFromPage();
            this.remove();
            this.onRemovedFromPage();
        }
    },
    
    
    
    onBeforeRemoveFromPage : function(){
        this.trigger("beforeRemoveFromPage", this);
    },
    
    onRemovedFromPage : function(){
         this.undelegateEvents();
         this.$el.removeData().unbind(); 
         this.trigger("removedFromPage", this);
    },
    


    

});

export default FeedAndAcitivitiesPageView;