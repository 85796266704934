import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import CommentSummaryController from "controllers/comment/CommentSummaryController";
import DynamicContentView from "views/common/DynamicContentView";
import RSVP from "rsvp";

const CommentWidgetController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.songModel = null;
        this.displayedCommentSummaryController = null;
        this._setHeightToFitContainer = options && options.setHeightToFitContainer? options.setHeightToFitContainer: false;
        this._visible = true;
        this._lastWidgetHeight = null;
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    setSongModel : function(songModel, songRefSummary, context){
        this.songModel =  songModel;
        this.songRefSummary =  songRefSummary;
        this.context =  context;
        this._updated = false;
        if(this._visible){
            return this._updateCommentSummary();
        }else{
            return RSVP.Promise.resolve();
        }
    },

    _updateCommentSummary : function(){
        this._updated = true;
        const previousCommentSummaryController = this.displayedCommentSummaryController;
        if(previousCommentSummaryController && previousCommentSummaryController.getView() && previousCommentSummaryController.getView().$el){
            previousCommentSummaryController.getView().$el.remove();
        }
        if(this.songModel){
            const commentSummaryControllerParams = {};
            commentSummaryControllerParams.model = this.songModel;
            commentSummaryControllerParams.disableReply = false;
            commentSummaryControllerParams.songRefSummary = this.songRefSummary;
            commentSummaryControllerParams.songRefSummary = this.songRefSummary;
            commentSummaryControllerParams.setHeightToFitContainer = this._setHeightToFitContainer;
            commentSummaryControllerParams.doNotShowEmptyBox = false;
            commentSummaryControllerParams.context =  this.context;
            this.displayedCommentSummaryController = new CommentSummaryController(commentSummaryControllerParams);
            return this.displayedCommentSummaryController.buildModelAndRenderView().then((function(){
                if(this.self.displayedCommentSummaryController == this.controller){
                    this.self.view.$el.append(this.controller.getView().$el);
                }
                this.self.refreshViewSize();
            }).bind({"self": this, "controller": this.displayedCommentSummaryController }));
        }else{
            return RSVP.Promise.resolve();
        }
    },

    refreshViewSize : function(){
        if(this._lastWidgetHeight){
            this.setVisible(this._visible,this._lastWidgetHeight );
        }
    },

    setVisible : function(visible,elementHeight){
        this._visible = visible;
        if(elementHeight){
            this._lastWidgetHeight = elementHeight;
        }
        if(this.displayedCommentSummaryController && this.displayedCommentSummaryController.getView() && this.displayedCommentSummaryController.getView().$el){
            if(elementHeight){
                this.displayedCommentSummaryController.getView().$el.css("height",elementHeight +"px");
            }
            const commentBoxHeigh = window.trebble.defaultValues.commentBoxHeigth;
            const contextViewHeight = (this.displayedCommentSummaryController.getContextController() && this.displayedCommentSummaryController.getContextController().getView() && this.displayedCommentSummaryController.getContextController().getView().$el)? this.displayedCommentSummaryController.getContextController().getView().$el.height(): 0;
            if(elementHeight){
                this.displayedCommentSummaryController.getView().$el.find("#collection_repeat_container").css("height", elementHeight - commentBoxHeigh - contextViewHeight + "px");
            }
        }
        if(elementHeight){
            this.view.$el.css("height",elementHeight +"px");
        }
        if(visible){
            this.view.$el.show();
            if(!this._updated){
                return this._updateCommentSummary();
            }else{
                return RSVP.Promise.resolve(); 
            }
        }else{
            this.view.$el.hide();
            return RSVP.Promise.resolve(); 
        }


    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                const contentView = new DynamicContentView();
                contentView.render();
                this.view = contentView;
                this.view.$el.css("width","100%");
                resolve();
            } catch (error) {
                reject(error);
            }
        }).bind(this));

    }
});

export default CommentWidgetController;