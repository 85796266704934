import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Gainfilter from "models/audioEditor/filters/GainFilter";
import FadeStartFilter from "models/audioEditor/filters/FadeStartFilter";
import FadeEndFilter from "models/audioEditor/filters/FadeEndFilter";
import CompressorFilter from "models/audioEditor/filters/CompressorFilter";
import LowPassFilter from "models/audioEditor/filters/LowPassFilter";
import HighPassFilter from "models/audioEditor/filters/HighPassFilter";
import LimiterFilter from "models/audioEditor/filters/LimiterFilter";
import NormalizeFilter from "models/audioEditor/filters/NormalizeFilter";
import EBULoudnessNormalizationFilter from "models/audioEditor/filters/EBULoudnessNormalizationFilter";
import NoiseGateFilter from "models/audioEditor/filters/NoiseGateFilter";
import DeesserFilter from "models/audioEditor/filters/DeesserFilter";
import SidechainCompressorFilter from "models/audioEditor/filters/SidechainCompressorFilter";
import ParametricEqFilter from "models/audioEditor/filters/ParametricEqFilter";
import CompressorWithGainFilter from "models/audioEditor/filters/CompressorWithGainFilter";
import RNNoiseFilter from "models/audioEditor/filters/RNNoiseFilter";
import RSVP from "rsvp";

const filterConstructorArray = [Gainfilter, FadeStartFilter, FadeEndFilter, CompressorFilter, 
LowPassFilter,  HighPassFilter, LimiterFilter, NormalizeFilter, EBULoudnessNormalizationFilter,
NoiseGateFilter,DeesserFilter,SidechainCompressorFilter, ParametricEqFilter];

const AudioFilterFactory =  Backbone.Model.extend({
    idAttribute: "id",

    createGainFilter : function(gainValue){
        return new Gainfilter({"gainValue": gainValue});
    },

    createFadeStartFilter : function(fadeFrom, fadeTo, fadeDuration, equalPowerFade){
        return new FadeStartFilter({"fadeFrom": fadeFrom, "fadeTo": fadeTo, "fadeDuration": fadeDuration, "equalPowerFade": equalPowerFade});
    },

    createFadeEndFilter : function(fadeFrom, fadeTo, fadeDuration, equalPowerFade){
        return new FadeEndFilter({"fadeFrom": fadeFrom, "fadeTo": fadeTo, "fadeDuration": fadeDuration, "equalPowerFade": equalPowerFade});
    },

    createAudioFilterFromSerializedData : function(serializedData){
        for(let i=0; i < filterConstructorArray.length; i++){
            const filterContructor = filterConstructorArray[i];
            if(filterContructor.CLASSNAME === serializedData.type){
                return new filterContructor(serializedData);
            }
        }
    },

    prepareAudioContext : function(audioContext){
        const promises = filterConstructorArray.map(function(filterContructor){
            const filterInstance = new filterContructor();
            if(filterInstance.needToPrepareAudioContext()){
                return filterInstance.prepareAudioContext(audioContext);
            }
        });
        return RSVP.Promise.all(promises);
    },

    createCompressorFilter : function(presetId){
        const compressorFilter = new CompressorFilter();
        if(presetId){
            compressorFilter.setPreset(presetId);
        }
        return compressorFilter;
    },

    createCompressorWithGainFilter : function(presetId){
        const compressorWithGainFilter = new CompressorWithGainFilter();
        if(presetId){
            compressorWithGainFilter.setPreset(presetId);
        }
        return compressorWithGainFilter;
    },


    createNoiseGateFilter : function(presetId){
        const noiseGateFilter = new NoiseGateFilter();
        if(presetId){
            noiseGateFilter.setPreset(presetId);
        }
        return noiseGateFilter;
    },

    createRNNoiseFilter : function(presetId){
        const rnnoiseFilter = new RNNoiseFilter();
        if(presetId){
            rnnoiseFilter.setPreset(presetId);
        }
        return rnnoiseFilter;
    },

    createDeesserFilter : function(presetId){
        const deesserFilter = new DeesserFilter();
        if(presetId){
            deesserFilter.setPreset(presetId);
        }
        return deesserFilter;
    },

    createSidechainCompressorFilter : function(presetId){
        const sidechainCompressorFilter = new SidechainCompressorFilter();
        if(presetId){
            sidechainCompressorFilter.setPreset(presetId);
        }
        return sidechainCompressorFilter;
    },


    createLimiterFilter : function(presetId){
        const limiterFilter = new LimiterFilter();
        if(presetId){
            limiterFilter.setPreset(presetId);
        }
        return limiterFilter;
    },

    createLowPassFilter : function(presetId){
        const lowPassFilter = new LowPassFilter();
        if(presetId){
            lowPassFilter.setPreset(presetId);
        }
        return lowPassFilter;
    },

    createHighPassFilter : function(presetId){
        const highPassFilter = new HighPassFilter();
        if(presetId){
            highPassFilter.setPreset(presetId);
        }
        return highPassFilter;
    },

    createNormalizeFilter :function(){
        return new NormalizeFilter();
    },

    createEBULoudnessNormalizationFilter : function(targetLoudness){
        const ebuLoudnessNormalizationFilter = new EBULoudnessNormalizationFilter();
        ebuLoudnessNormalizationFilter.setTargetLoudness(targetLoudness);
        return ebuLoudnessNormalizationFilter;
    },

    createParametricEqFilter : function(presetId){
        const parametricEqFilter = new ParametricEqFilter();
        if(presetId){
            parametricEqFilter.setPreset(presetId);
        }
        return parametricEqFilter;
    },


    getCompressorFilterPresets : function(){
        return CompressorFilter.PRESET_IDS;
    },

    getCompressorWithGainFilterPresets : function(){
        return CompressorWithGainFilter.PRESET_IDS;
    },

    getParametricEqFilterPresets : function(){
        return ParametricEqFilter.PRESET_IDS;
    },
    
    getNoiseGateFilterPresets : function(){
        return NoiseGateFilter.PRESET_IDS;
    },

    getDeesserFilterPresets : function(){
        return DeesserFilter.PRESET_IDS;
    },

    getSidechainCompressorFilterPresets : function(){
        return SidechainCompressorFilter.PRESET_IDS;
    },

    getLimiterFilterPresets : function(){
        return LimiterFilter.PRESET_IDS;
    },

    getLowPassFilterPresets : function(){
        return LowPassFilter.PRESET_IDS;
    },

    getHighPassFilterPresets : function(){
        return HighPassFilter.PRESET_IDS;
    },

    isGainFilterInstance : function(filter){
        return filter instanceof Gainfilter;
    },

    isFadeStartFilterInstance : function(filter){
        return filter instanceof FadeStartFilter;
    },

    isFadeEndFilterInstance : function(filter){
        return filter instanceof FadeEndFilter;
    },

    isCompressorFilterInstance : function(filter){
        return filter instanceof CompressorFilter;
    },

    isCompressorWithGainFilterInstance : function(filter){
        return filter instanceof CompressorWithGainFilter;
    },

    isLowPassFilterInstance : function(filter){
        return filter instanceof LowPassFilter;
    },

    isHighPassFilterInstance : function(filter){
        return filter instanceof HighPassFilter;
    },

    isLimiterFilterInstance : function(filter){
        return filter instanceof LimiterFilter;
    },

    isNormalizeFilterInstance : function(filter){
        return filter instanceof NormalizeFilter;
    },

    isEBULoudnessNormalizationFilterInstance : function(filter){
        return filter instanceof EBULoudnessNormalizationFilter;
    },


});


const audioFilterFactoryInstance = new AudioFilterFactory();

export default {
    getInstance : function() {
        return audioFilterFactoryInstance;
    }
}; 