import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import RSVP from "rsvp";

const DEFAULT_ID_COLUMN_NAME = "uid";
const DEFAULT_JSON_VALUE_COLUMN_NAME = "jsonData";
const songEntitySchema = {
        entityName : "Song",
        primaryKeyField : { jsonFieldProperty : 'uri'},
        indexes : {
                uri : { 
                        jsonFieldProperty : 'uri',
                        unique: true 
                        },
            deleted : { 
                        jsonFieldProperty : 'deleted',
                        unique: false, 
                        },
            title : { 
                        jsonFieldProperty : 'title',
                        unique: false, 
                    },
            idifiedTitle : { 
                        jsonFieldProperty : 'idifiedTitle',
                        unique: false, 
                    },
            idified : { 
                        jsonFieldProperty : 'idified',
                        unique: false, 
                        },
            synced : { 
                        jsonFieldProperty : 'synced',
                        unique: false, 
                        },							
            
            type : { 
                        jsonFieldProperty : 'type',
                        unique: false, 
                        },		
                            
            favoriteString : { 
                        jsonFieldProperty : 'favoriteString',
                        unique: false, 
                        },
                                
            idifiedSuccess : { 
                        jsonFieldProperty : 'idifiedSuccess',
                        unique: false, 
                        },		
            
        }
};

const albumEntitySchema = {
        entityName : "Album",
        primaryKeyField : { jsonFieldProperty : 'albumId'},
        indexes : {
            albumId : { 
                        jsonFieldProperty : 'albumId',
                        unique: true 
                        },
            album : { 
                        jsonFieldProperty : 'album',
                        unique: false, 
                        },
            year : { 
                        jsonFieldProperty : 'year',
                        unique: false, 
                        },	
            
        }
};

const artistEntitySchema = {
        entityName : "Artist",
        primaryKeyField : { jsonFieldProperty : 'artistId'},
        indexes : {
            artistId : { 
                        jsonFieldProperty : 'artistId',
                        unique: true 
                        },
            artistName : { 
                        jsonFieldProperty : 'artistName',
                        unique: false, 
                        },	
            
        }
};


const logEntitySchema = {
        entityName : "Log",
        primaryKeyField : { jsonFieldProperty : 'logId'},
        indexes : {
            logId : { 
                        jsonFieldProperty : 'logId',
                        unique: true 
                        },
            type : { 
                        jsonFieldProperty : 'type',
                        unique: false, 
                        },	
            target : { 
                        jsonFieldProperty : 'target',
                        unique: false, 
                        },
            additionalData : { 
                        jsonFieldProperty : 'additionalData',
                        unique: false, 
                    },
            timestamp : { 
                        jsonFieldProperty : 'timestamp',
                        unique: false, 
                    },
            synced : { 
                        jsonFieldProperty : 'synced',
                        unique: false, 
                    },
            
        }
};

const ENTITIES_SCHEMA = {
        "song" : songEntitySchema,
        "album" : albumEntitySchema,
        "artist" : artistEntitySchema,
        "log" : logEntitySchema
};

const TrebbleDBHelper = Backbone.Model.extend({
    

    initialize : function() {
          this.db = openDatabase("trebbleSqlDB", '1.0', 'my first database', 20 * 1024 * 1024);
          this._initializeTables().catch(function(err){
              window.log("SQLLite Table failed initialization. Error :"+ err);
          });
    },
    
    _initializeTables : function(){
        return this._getTransaction().then((function(transaction){
            const promiseAray = [];
            for(let entityName in ENTITIES_SCHEMA)
            {
                const entitySchema = ENTITIES_SCHEMA[entityName];
                promiseAray.push(transaction.executeSql(this._buildCreateSQLStatement(entitySchema)));
            }
            return RSVP.Promise.all(promiseAray);
        
        }).bind(this));
    },
    
    _getTransaction : function(){
        return new RSVP.Promise((function(resolve,reject){
            try{
                this.db.transaction(function(tx) {
                    const promiseTx = {};
                    promiseTx._tx = tx;
                    promiseTx.executeSql = (function(sqlToExecute, sqlParams){
                        return new RSVP.Promise((function(otherResolve,otherReject){
                            try{
                                const cbFunction = function(exisitingTx, res){
                                    otherResolve({"tx": exisitingTx,"results": res});
                                };
                                window.log("SQL to be executed: "+ sqlToExecute );
                                const errorCB = function(transaction,err){
                                    window.log("Error executing SQL. Error: "+ err );
                                    otherReject(err);
                                }
                                this._tx.executeSql(sqlToExecute, sqlParams, cbFunction, errorCB);
                            }catch(err){
                                otherReject(err);
                            }
                            
                        }).bind(promiseTx));
                        
                    }).bind(promiseTx);
                    resolve(promiseTx);
            }, reject);
            }catch(err){
                reject(err);
            }
        }).bind(this));
    },
    
    
    
    _buildCreateSQLStatement : function(entitySchema)
    {
        let createTableStatement = "CREATE TABLE IF NOT EXISTS " + entitySchema["entityName"] ;
        createTableStatement = createTableStatement + "(";
        const primaryKeyInfo = entitySchema["primaryKeyField"];
        let primaryKeyType = "TEXT";
        if(primaryKeyInfo.type)
        {
            primaryKeyType = primaryKeyInfo.type;
        }
        createTableStatement = createTableStatement + " "+ DEFAULT_ID_COLUMN_NAME+" " + primaryKeyType +"  ";
        createTableStatement = createTableStatement + " , "+ DEFAULT_JSON_VALUE_COLUMN_NAME + " TEXT ";
        if(entitySchema["indexes"])
        {
            const indexes = entitySchema["indexes"];
            for(let indexName in indexes){
                const indexInfo = indexes[indexName];
                
                let createIndexSQL = ", "+ indexName ;
                if(indexInfo.type){
                    createIndexSQL = createIndexSQL + " " + indexInfo.type;
                }else{
                    createIndexSQL = createIndexSQL + " TEXT" ;
                }
                if(indexInfo.unique){
                    createIndexSQL = createIndexSQL + " UNIQUE" ;
                }
                createIndexSQL = createIndexSQL + " ";
                createTableStatement = createTableStatement + createIndexSQL;
            }
        }
        createTableStatement = createTableStatement + ")";//",timestamp REAL)";
        return createTableStatement;
    },
    
    put : function(entityName, jsonObj){
        const objUID = this._getUIDFromJsonObj(entityName, jsonObj);
        return this.isKeyExist(entityName, objUID).then((function(exist){
            if(exist)
            {
                return this._update(entityName,jsonObj);
            }else{
                return this._add(entityName,jsonObj);
            }
        }).bind(this));
        
        
    },
    
    _add : function(entityName, jsonObj){
            this._getTransaction().then((function(transaction){
                const insertSqlStatement = this._getInsertSQLStatement(entityName);
                const dataArray = this._getArrayOfDataToInsert(entityName, jsonObj);
                return transaction.executeSql(insertSqlStatement, dataArray);
            }).bind(this));

    },
    
    _update : function(entityName, jsonObj){
            this._getTransaction().then((function(transaction){
                const updateSqlStatement = this._getUpdateSQLStatement(entityName);
                const dataArray = this._getArrayOfDataToUpdate(entityName, jsonObj);
                return transaction.executeSql(updateSqlStatement, dataArray);
            }).bind(this));		
    },
    
    
    putAll : function(entityName, jsonObjArray){
        if(jsonObjArray.length > 600)
        {
            const promiseArray = [];
            let partialJsonObjArray = [];
            let counter = 0;
            for(let index in jsonObjArray)
            {
                partialJsonObjArray.push(jsonObjArray[index]);
                counter++;
                if(counter == 600){
                    promiseArray.push(this.putAllBatch(entityName,partialJsonObjArray));
                    partialJsonObjArray = [];
                    counter = 0;
                }
            }
            if(partialJsonObjArray.length > 0)
            {
                promiseArray.push(this.putAllBatch(entityName,partialJsonObjArray));
                partialJsonObjArray = [];
            }
            return RSVP.Promise.all(promiseArray);
        }else{
            return this.putAllBatch(entityName,jsonObjArray);
        }
    },
    
    addAll : function(entityName, jsonObjArray){
        if(jsonObjArray.length > 600)
        {
            const promiseArray = [];
            let partialJsonObjArray = [];
            let counter = 0;
            for(let index in jsonObjArray)
            {
                partialJsonObjArray.push(jsonObjArray[index]);
                counter++;
                if(counter == 600){
                    promiseArray.push(this.addAllBatch(entityName,partialJsonObjArray));
                    partialJsonObjArray = [];
                    counter = 0;
                }
            }
            if(partialJsonObjArray.length > 0)
            {
                promiseArray.push(this.addAllBatch(entityName,partialJsonObjArray));
                partialJsonObjArray = [];
            }
            return RSVP.Promise.all(promiseArray);
        }else{
            return this.addAllBatch(entityName,jsonObjArray);
        }
    },
    
    putAllBatch : function(entityName, jsonObjArray){
        if(!Array.isArray(jsonObjArray))
        {
            return this.put(entityName, jsonObjArray);
        }
        const uidArray = this._getUIDArrayFromJsonObjArray(entityName, jsonObjArray);
        let uidsToUpdate  = null;
        const uidToJsonObjMap = this._getUIDToJsonObjMapFromJsonObjArray(entityName, jsonObjArray);
        return this.isKeyExist(entityName, uidArray).then((function(existingUids){
            uidsToUpdate = existingUids;
            return this._getTransaction();
        }).bind(this)).then((function(transaction){
            const promiseArray = [];
            for(let uid in uidToJsonObjMap)
            {
                if(uidsToUpdate.indexOf(uid) > -1 )
                {
                    //Update
                    const jsonObjToUpdate = uidToJsonObjMap[uid];
                    const updateSqlStatement = this._getUpdateSQLStatement(entityName);
                    let dataArray = this._getArrayOfDataToUpdate(entityName, jsonObjToUpdate);
                    promiseArray.push(transaction.executeSql(updateSqlStatement, dataArray));
                }else{
                    //Insert
                    const jsonObjToInsert = uidToJsonObjMap[uid];
                    const insertSqlStatement = this._getInsertSQLStatement(entityName);
                    let dataArray = this._getArrayOfDataToInsert(entityName, jsonObjToInsert);
                    promiseArray.push(transaction.executeSql(insertSqlStatement, dataArray));
                }
            }
            return RSVP.Promise.all(promiseArray);
            
        }).bind(this));
    },
    
    addAllBatch : function(entityName, jsonObjArray){
        if(!Array.isArray(jsonObjArray))
        {
            return this.put(entityName, jsonObjArray);
        }
        const uidArray = this._getUIDArrayFromJsonObjArray(entityName, jsonObjArray);
        let uidsToUpdate  = null;
        const uidToJsonObjMap = this._getUIDToJsonObjMapFromJsonObjArray(entityName, jsonObjArray);
        return this.isKeyExist(entityName, uidArray).then((function(existingUids){
            uidsToUpdate = existingUids;
            return this._getTransaction();
        }).bind(this)).then((function(transaction){
            const promiseArray = [];
            for(let uid in uidToJsonObjMap)
            {
                if(uidsToUpdate.indexOf(uid) > -1 )
                {
                    //Update
                    //do nothing if already exists
                    continue;
                }else{
                    //Insert
                    const jsonObjToInsert = uidToJsonObjMap[uid];
                    const insertSqlStatement = this._getInsertSQLStatement(entityName);
                    const dataArray = this._getArrayOfDataToInsert(entityName, jsonObjToInsert);
                    promiseArray.push(transaction.executeSql(insertSqlStatement, dataArray));
                }
            }
            return RSVP.Promise.all(promiseArray);
            
        }).bind(this));
    },
    
    getAll : function(entityName, filterOrFilterArray, sortingIndexOrSortingIndexArray, numberOfResults){
        const entityTableName = this._getEntityTableName(entityName);
        return this._getTransaction().then((function(transaction){
            const filterStatement = this._buildFilterQuery(filterOrFilterArray);
            const sortingStatement = this._buildSortingQuery(sortingIndexOrSortingIndexArray);
            let limitStatement = "";
            if(numberOfResults && numberOfResults > 0)
            {
                limitStatement = "LIMIT "+ numberOfResults.toString();
            }
            const selectSQL = "SELECT "+ DEFAULT_JSON_VALUE_COLUMN_NAME + " FROM " + entityTableName +" "+ filterStatement + " " + sortingStatement + " " + limitStatement; 
            return transaction.executeSql(selectSQL, []);
        }).bind(this)).then(function(res){
            const jsonObjArray = [];
            if(res && res.results && res.results.rows && res.results.rows.length > 0)
            {
                let index = 0;
                while(index < res.results.rows.length)
                {
                    const jsonObjString = res.results.rows.item(index)[DEFAULT_JSON_VALUE_COLUMN_NAME];
                    jsonObjArray.push(JSON.parse(jsonObjString));
                    index = index +1;
                }
            }
            return jsonObjArray;
        });
    },
    
    find : function(entityName, uid){
        const entityTableName = this._getEntityTableName(entityName);
        return this._getTransaction().then(function(transaction){
            const selectSQL = "SELECT "+ DEFAULT_JSON_VALUE_COLUMN_NAME + " FROM " + entityTableName +"  WHERE "+ DEFAULT_ID_COLUMN_NAME + "=?"; 
            return transaction.executeSql(selectSQL, [uid.toString()]);
        }).then(function(res){
            let jsonObjFound = null;
            if(res && res.results && res.results.rows &&  res.results.rows.length > 0)
            {
                const jsonObjString = res.results.rows.item(0)[DEFAULT_JSON_VALUE_COLUMN_NAME];
                jsonObjFound = JSON.parse(jsonObjString);
            }
            return jsonObjFound;
        });
    },
    
    deleteAll : function(entityName, filterOrFilterArray){
        const entityTableName = this._getEntityTableName(entityName);
        return this._getTransaction().then(function(transaction){
            const filterStatement = this._buildFilterQuery(filterOrFilterArray);
            if(filterStatement && filterStatement != "")
            {
                const selectSQL = "DELETE "+ DEFAULT_JSON_VALUE_COLUMN_NAME + " FROM " + entityTableName +" "+ filterStatement ; 
                return transaction.executeSql(selectSQL, []);
            }else{
                return ;
            }			
        });
    },
    
    _buildFilterQuery : function(filterOrFilterArray){
        let filterQ = "";
        
        if(filterOrFilterArray)
        {
            
            
            let firstFilterAddedToQuery = false;
            for(let filterColumn in filterOrFilterArray)
            {
                const filterValueOrArrayOfFilterValues  = filterOrFilterArray[filterColumn];
                if(firstFilterAddedToQuery)
                {
                    filterQ = filterQ + " AND "
                }else{
                    firstFilterAddedToQuery= true;
                    filterQ = filterQ + " WHERE ";
                }
                if(Array.isArray(filterValueOrArrayOfFilterValues))
                {
                    filterQ = filterQ + " " + filterColumn + " IN ('" + filterValueOrArrayOfFilterValues.join("','") + "')";
                }else{
                    filterQ = filterQ + " " + filterColumn + " = '" + filterValueOrArrayOfFilterValues + "' ";
                }
                
            }
            
        }
        return filterQ;
        
    },
    
    _buildSortingQuery : function(sortingIndexOrSortingIndexArray){
        let sortingQ = "";
        if(sortingIndexOrSortingIndexArray)
        {
                if(Array.isArray(sortingIndexOrSortingIndexArray))
                {
                    sortingQ =   " ORDER BY  COALESCE(" + sortingIndexOrSortingIndexArray.join(",") + ")";
                }else{
                    sortingQ =   " ORDER BY " + sortingIndexOrSortingIndexArray + " ";
                }
            
        }
        return sortingQ;
        
    },
    
    _getUIDFromJsonObj : function(entityName, jsonObj){
        const entitySchema = this._getEntitySchema(entityName);
        return this._convertToString(jsonObj[entitySchema.primaryKeyField.jsonFieldProperty]);
    },
    
    _getUIDToJsonObjMapFromJsonObjArray : function(entityName, jsonObjArray){
        const uidToJsonObjMap = {};
        const entitySchema = this._getEntitySchema(entityName);
        for(let objIndex in jsonObjArray)
        {
            const jsonObj = jsonObjArray[objIndex];
            const uid = this._convertToString(jsonObj[entitySchema.primaryKeyField.jsonFieldProperty]);
            uidToJsonObjMap[uid] = jsonObj;
        }
        
        return uidToJsonObjMap;
    },
    
    _getUIDArrayFromJsonObjArray : function(entityName, jsonObjArray){
        const uidArray = [];
        const entitySchema = this._getEntitySchema(entityName);
        for(let objIndex in jsonObjArray)
        {
            const jsonObj = jsonObjArray[objIndex];
            const uid = this._convertToString(jsonObj[entitySchema.primaryKeyField.jsonFieldProperty]);
            uidArray.push(uid);
        }
        
        return uidArray;
    },
    
    
    isKeyExist : function(entityName, keyorArrayOfKey){
        
            return this._getTransaction().then((function(transaction){
                if (Array.isArray(keyorArrayOfKey) && keyorArrayOfKey.length > 0) {
                    let existQuery = "SELECT "+ DEFAULT_ID_COLUMN_NAME + " from " + this._getEntityTableName(entityName) +" where "+DEFAULT_ID_COLUMN_NAME + " IN (? " + Array(keyorArrayOfKey.length).join(", ?") + ")";
                     return  transaction.executeSql(existQuery, keyorArrayOfKey);
                }else{
                    let existQuery = "SELECT "+ DEFAULT_ID_COLUMN_NAME + " from " + this._getEntityTableName(entityName) +" where "+DEFAULT_ID_COLUMN_NAME +"=  ? ";
                     return  transaction.executeSql(existQuery, [keyorArrayOfKey]);
                }
                
            }).bind(this)).then(function(rs){
                const results = rs.results;
                if (Array.isArray(keyorArrayOfKey)) {
                    let index = 0;
                    const keysFound = [];
                    while(index < results.rows.length)
                    {
                        keysFound.push(results.rows.item(index)[DEFAULT_ID_COLUMN_NAME])
                        index++;
                    }
                    return keysFound;
                }else{
                    if(results && results.rows && results.rows.length > 0)
                    {
                        return true;
                    }else{
                        return false;
                    }
                }
                
                
            });
        
        
    },
    
    _getEntitySchema : function(entityName){
        return ENTITIES_SCHEMA[entityName];
        
    },
    
    _getEntityTableName : function(entityName){
        const entitySchema = this._getEntitySchema(entityName);
        if(entitySchema){
            return entitySchema.entityName;
        }else{
            return null;
        }
        
    },
    
    _getEntityColumnNames : function(entityName){
        const entitySchema = this._getEntitySchema(entityName);
        const columnNames = [];
        if(entitySchema && !entitySchema._columnNames){
            columnNames.push(DEFAULT_ID_COLUMN_NAME);
            columnNames.push(DEFAULT_JSON_VALUE_COLUMN_NAME);
            const indexes =  entitySchema["indexes"];
            for(let indexName in indexes)
            {
                columnNames.push(indexName);
            }
            entitySchema._columnNames = columnNames;
        }
        
        return entitySchema._columnNames;
        
    },
    
    _getInsertSQLStatement : function(entityName){
        const entitySchema = this._getEntitySchema(entityName);
        let insertStatement  = "INSERT INTO "+ this._getEntityTableName(entityName) ;
        const columnNames = this._getEntityColumnNames(entityName);
        insertStatement = insertStatement + " (" + columnNames.join(", ") + " ) ";
        insertStatement = insertStatement  + " VALUES (";
        if(columnNames.length > 0)
        {
            insertStatement = insertStatement  + " ? ";	
            if(columnNames.length > 1)
            {
                insertStatement = insertStatement + Array(columnNames.length).join(", ?");
            }
        }
        insertStatement = insertStatement  + " )";
        return insertStatement;
        
    },
    
    _getUpdateSQLStatement : function(entityName){
        const entitySchema = this._getEntitySchema(entityName);
        let updateStatement  = "UPDATE "+ this._getEntityTableName(entityName) + " SET ";
        const columnNames = this._getEntityColumnNames(entityName);
        let index = 0;
        for(let columnNameIndex in columnNames)
        {
            const columnName = columnNames[columnNameIndex]
            if(columnName != DEFAULT_ID_COLUMN_NAME)
            {
                if(index == 0)
                {
                    updateStatement  = updateStatement + " "+columnName+"=?";
                }else{
                    updateStatement  =  updateStatement + " ,"+ columnName + "=?";
                }
                index = index + 1;
            }
        }
        updateStatement = updateStatement + " WHERE " + DEFAULT_ID_COLUMN_NAME + "=?";
        return updateStatement;
        
    },
    
    
    _getArrayOfDataToInsert : function(entityName, jsonObj){
        const entitySchema = this._getEntitySchema(entityName);
        const columnNames = this._getEntityColumnNames(entityName);
        const dataArray = [];
        const indexes = entitySchema.indexes;
        for(let columnNameIndex in columnNames){
            const columnName = columnNames[columnNameIndex];
            if(columnName == DEFAULT_ID_COLUMN_NAME)
            {
                dataArray.push(this._convertToString(jsonObj[entitySchema.primaryKeyField.jsonFieldProperty]));
                continue;
            }
            if(columnName == DEFAULT_JSON_VALUE_COLUMN_NAME)
            {
                dataArray.push(JSON.stringify(jsonObj));
                continue;
            }
            const jsonPropertyName = indexes[columnName].jsonFieldProperty;
            let jsonPropertyValue = jsonObj[jsonPropertyName];
            if(!jsonPropertyValue && indexes[columnName].defaultValueIfNull )
            {
                jsonPropertyValue = indexes[columnName].defaultValueIfNull;
            }
            dataArray.push(this._convertToString(jsonPropertyValue));
        }
        return dataArray;
        
    },
    
    _convertToString : function(valueToConvert){
        if(valueToConvert)
            {
                return valueToConvert.toString();
            }else{
                return valueToConvert;
            }
    },
    
    _getArrayOfDataToUpdate : function(entityName, jsonObj){
        const entitySchema = this._getEntitySchema(entityName);
        const columnNames = this._getEntityColumnNames(entityName);
        const dataArray = [];
        const indexes = entitySchema.indexes;
        for(let columnNameIndex in columnNames){
            const columnName = columnNames[columnNameIndex];
            if(columnName == DEFAULT_ID_COLUMN_NAME)
            {
                //dataArray.push(jsonObj[entitySchema.primaryKeyField.jsonFieldProperty]);
                continue;
            }
            if(columnName == DEFAULT_JSON_VALUE_COLUMN_NAME)
            {
                dataArray.push(JSON.stringify(jsonObj));
                continue;
            }
            const jsonPropertyName = indexes[columnName].jsonFieldProperty;
            let jsonPropertyValue = jsonObj[jsonPropertyName];
            if(!jsonPropertyValue && indexes[columnName].defaultValueIfNull )
            {
                jsonPropertyValue = indexes[columnName].defaultValueIfNull;
            }
            dataArray.push(this._convertToString(jsonPropertyValue));
        }
        dataArray.push(this._convertToString(jsonObj[entitySchema.primaryKeyField.jsonFieldProperty]));
        return dataArray;
        
    },
    
    
    
    
    
    
    
    /*remove : function(entityName, jsonObj){
        return new RSVP.Promise((function(resolve,reject){
            
        }).bind(this));
        
    },*/


});

const trebbleDBHelperSingleton = new TrebbleDBHelper();

export default {
    getInstance : function() {
        return trebbleDBHelperSingleton;
    }
};