	import $ from 'jquery';
    import _ from 'underscore';
    import Backbone from "backbone";
    import RSVP from "rsvp";

    const TRACK_FACEBOOK_EVENT_ON_APPFLYER = true;

    const FacebookEventTrackingHelper = Backbone.Model.extend({


        initialize : function() {

        },
        
        trackRegistration: function(isCreator, hasRSSFeed, accessCode){
            const params = {};
            params.isCreator = !!isCreator;
            params.hasRSSFeed = !!hasRSSFeed;
            params.accessCode = accessCode;
            params.appversionUnixCode =  window.trebbleAppVersion;
            params.platformContext =  this._getPlatformContext();
            params.adReference = window.trebble && window.trebble.adReference? window.trebble.adReference: null;
            if(window.waitForCordovaToLoad && window.facebookConnectPlugin){
                this._logEventFromCordovaFacebookPlugin("fb_mobile_complete_registration", params);
            }else{
                if ('fbq' in window) {
                    this._trackFacebookEvent("CompleteRegistration", params)

                }
            }
        },

        _getPlatformContext : function(){
            if(window.waitForCordovaToLoad){
                return window.isIOSMobileDevice?"ios":"android";
            }else{
                return "browser";
            }
        },

        trackSubscriptionToAJourney : function(journeyIdWhoWasSubscribedTo){
            const params = {};
            params.journeyIdWhoWasSubscribedTo = journeyIdWhoWasSubscribedTo;
            if(window.waitForCordovaToLoad && window.facebookConnectPlugin){
                this._logEventFromCordovaFacebookPlugin("SubscribeToAJourney", params);
            }else{
                if ('fbq' in window) {
                    this._trackFacebookEvent("SubscribeToAJourney", params, true);
                }
            }
        },

            trackSubscriptionToATrebble : function(trebbleIdWhoWasSubscribedTo){
            const params = {};
            params.trebbleIdWhoWasSubscribedTo = trebbleIdWhoWasSubscribedTo;
            if(window.waitForCordovaToLoad && window.facebookConnectPlugin){
                this._logEventFromCordovaFacebookPlugin("SubscribeToATrebble", params);
            }else{
                if ('fbq' in window) {
                    this._trackFacebookEvent("SubscribeToATrebble", params, true);
                }
            }
        },

        trackUnsubscribeToATrebble : function(trebbleIdWhoWasUnsubscribed){
            const params = {};
            params.trebbleIdWhoWasUnsubscribed = trebbleIdWhoWasUnsubscribed;
            if(window.waitForCordovaToLoad && window.facebookConnectPlugin){
                this._logEventFromCordovaFacebookPlugin("UnsubscribeToATrebble", params);
            }else{
                if ('fbq' in window) {
                    this._trackFacebookEvent("UnsubscribeToATrebble", params, true);
                }
            }
        },

                trackUnsubscribeToAJourney : function(journeyIdWhoWasUnsubscribed){
            const params = {};
            params.journeyIdWhoWasUnsubscribed = journeyIdWhoWasUnsubscribed;
            if(window.waitForCordovaToLoad && window.facebookConnectPlugin){
                this._logEventFromCordovaFacebookPlugin("UnsubscribeToAJourney", params);
            }else{
                if ('fbq' in window) {
                    this._trackFacebookEvent("UnsubscribeToAJourney", params, true);
                }
            }
        },


        trackAddedShortcutToSiri : function(){
            if(window.waitForCordovaToLoad && window.facebookConnectPlugin){
                this._logEventFromCordovaFacebookPlugin("AddShortcutToSiri");
            }else{
                if ('fbq' in window) {
                    this._trackFacebookEvent("AddShortcutToSiri",null, true);
                }
            }
        },

        trackModifiedShortcutToSiri : function(){
            if(window.waitForCordovaToLoad && window.facebookConnectPlugin){
                this._logEventFromCordovaFacebookPlugin("ModifyShortcutToSiri");
            }else{
                if ('fbq' in window) {
                    this._trackFacebookEvent("ModifyShortcutToSiri", null, true);
                }
            }
        },

        trackDeletedShortcutToSiri : function(){
            if(window.waitForCordovaToLoad && window.facebookConnectPlugin){
                this._logEventFromCordovaFacebookPlugin("DeleteShortcutToSiri");
            }else{
                if ('fbq' in window) {
                    this._trackFacebookEvent("DeleteShortcutToSiri", null, true);
                }
            }
        },

        trackCapsuleFeedPlayed : function(){
            if(window.waitForCordovaToLoad && window.facebookConnectPlugin){
                this._logEventFromCordovaFacebookPlugin("CapsuleFeedPlayed");
            }else{
                if ('fbq' in window) {
                    this._trackFacebookEvent("CapsuleFeedPlayed", null, true);
                }
            }
        },

        trackCategoryShortcastRadioPlayed : function(categoryId){
            if(window.waitForCordovaToLoad && window.facebookConnectPlugin){
                this._logEventFromCordovaFacebookPlugin("CategoryShortcastRadioPlayed",{"categoryId": categoryId});
            }else{
                if ('fbq' in window) {
                    this._trackFacebookEvent("CategoryShortcastRadioPlayed", {"categoryId": categoryId}, true);
                }
            }
        },

        trackSubscriptionPlayBackOrderSaved : function(){
            if(window.waitForCordovaToLoad && window.facebookConnectPlugin){
                this._logEventFromCordovaFacebookPlugin("SubscriptionPlayBackOrderSaved");
            }else{
                if ('fbq' in window) {
                    this._trackFacebookEvent("SubscriptionPlayBackOrderSaved", null, true);
                }
            }
        },

        trackUserManuallyAgreedToTurnOnNotification: function(){
            if(window.waitForCordovaToLoad && window.facebookConnectPlugin){
                this._logEventFromCordovaFacebookPlugin("UserManuallyAgreedToTurnOnNotification");
            }else{
                if ('fbq' in window) {
                    this._trackFacebookEvent("UserManuallyAgreedToTurnOnNotification", null, true);
                }
            }
        },

        trackUserDidNotAgreeToTurnOnNotification: function(){
            if(window.waitForCordovaToLoad && window.facebookConnectPlugin){
                this._logEventFromCordovaFacebookPlugin("UserDidNotAgreeToTurnOnNotification");
            }else{
                if ('fbq' in window) {
                    this._trackFacebookEvent("UserDidNotAgreeToTurnOnNotification",null, true);
                }
            }
        },

        trackLeadWithIntentToSignup : function(){
            if(window.waitForCordovaToLoad && window.facebookConnectPlugin){
                this._logEventFromCordovaFacebookPlugin("LeadToSignup");
            }else{
                if ('fbq' in window) {
                    this._trackLead("signup");
                }
            }
        },

        trackPurchase : function(productInfo, isSandbox){
            if(productInfo){
                const value = productInfo.priceMicros/1000000;
                const currency = productInfo.currency;
                if(window.waitForCordovaToLoad && window.facebookConnectPlugin){
                    
                    this._logPurchaseFromCordovaFacebookPlugin( productInfo, value , currency, isSandbox);
                }else{
                    if ('fbq' in window) {
                        this._trackFacebookEvent("Purchase", {"value": value, "currency": currency});
                    }
                }
            }
        },

        trackCapsuleRecorded : function(){
            if(window.waitForCordovaToLoad && window.facebookConnectPlugin){
                this._logEventFromCordovaFacebookPlugin("CapsuleRecorded");
            }else{
                if ('fbq' in window) {
                    this._trackFacebookEvent("CapsuleRecorded", null, true);
                }
            }
        },

        trackWasAwaredTrebblePlusFreeTrial  : function(numberOfDays){
            const params = {};
            params.numberOfDays = numberOfDays;
            if(window.waitForCordovaToLoad && window.facebookConnectPlugin){
                this._logEventFromCordovaFacebookPlugin("wasAwaredTrebblePlusFreeTrial",params);
            }else{
                if ('fbq' in window) {
                    this._trackFacebookEvent("wasAwaredTrebblePlusFreeTrial", params, true);
                }
            }
        },

        trackCapsuleCreated : function(){
            if(window.waitForCordovaToLoad && window.facebookConnectPlugin){
                this._logEventFromCordovaFacebookPlugin("CapsuleCreated");
            }else{
                if ('fbq' in window) {
                    this._trackFacebookEvent("CapsuleCreated", null, true);
                }
            }
        },

        trackCapsuleUpdated : function(){
            if(window.waitForCordovaToLoad && window.facebookConnectPlugin){
                this._logEventFromCordovaFacebookPlugin("CapsuleUpdated");
            }else{
                if ('fbq' in window) {
                    this._trackFacebookEvent("CapsuleUpdated", null, true);
                }
            }
        },

        trackUserRefusedToRateApp: function(){
            if(window.waitForCordovaToLoad && window.facebookConnectPlugin){
                this._logEventFromCordovaFacebookPlugin("UserRefusedToRateApp");
            }else{
                if ('fbq' in window) {
                    this._trackFacebookEvent("UserRefusedToRateApp", null, true);
                }
            }
        },

        trackUserSentSuggestionToImproveApp: function(){
            if(window.waitForCordovaToLoad && window.facebookConnectPlugin){
                this._logEventFromCordovaFacebookPlugin("UserSentSuggestionToImproveApp");
            }else{
                if ('fbq' in window) {
                    this._trackFacebookEvent("UserSentSuggestionToImproveApp", null, true);
                }
            }
        },

        trackUserPostponedAppRating: function(){
            if(window.waitForCordovaToLoad && window.facebookConnectPlugin){
                this._logEventFromCordovaFacebookPlugin("UserPostponedAppRating");
            }else{
                if ('fbq' in window) {
                    this._trackFacebookEvent("UserPostponedAppRating", null, true);
                }
            }
        },

        trackNumberOfTimesUserPostponedAppRating: function(numberOfTimes){
            if(window.waitForCordovaToLoad && window.facebookConnectPlugin){
                this._logEventFromCordovaFacebookPlugin("userPostponedRatingTheApp"+numberOfTimes+"times");
            }else{
                if ('fbq' in window) {
                    this._trackFacebookEvent("userPostponedRatingTheApp"+numberOfTimes+"times", null , true);
                }
            }
        },

        trackUserRatedApp : function(){
            if(window.waitForCordovaToLoad && window.facebookConnectPlugin){
                this._logEventFromCordovaFacebookPlugin("UserRatedApp");
            }else{
                if ('fbq' in window) {
                    this._trackFacebookEvent("UserRatedApp", null , true);
                }
            }
        },


        trackNumberOfDaysCapsuleFeedWasPlayed: function(numberOfDays){
            const params = {};
            params.numberOfDays = numberOfDays;
            if(window.waitForCordovaToLoad && window.facebookConnectPlugin){
                this._logEventFromCordovaFacebookPlugin("numberOfDaysUserPlayedCapsuleFeed", params);
            }else{
                if ('fbq' in window) {
                    this._trackFacebookEvent("numberOfDaysUserPlayedCapsuleFeed", params, true);
                }
            }
        },


        trackDailyNotificationToListenToCapsuleFeedClicked : function(){
            if(window.waitForCordovaToLoad && window.facebookConnectPlugin){
                this._logEventFromCordovaFacebookPlugin("DailyCapsuleFeedReadyNotificationClicked");
            }else{
                if ('fbq' in window) {
                    this._trackFacebookEvent("DailyCapsuleFeedReadyNotificationClicked",null, true);
                }
            }
        },

        _trackLead : function( intent, intentParams){
            if(window.waitForCordovaToLoad && window.facebookConnectPlugin){

            }else{
                if ('fbq' in window) {
                    const params = {};
                    params.intent = intent;
                    params.intentParams = intentParams;
                    this._trackFacebookEvent("Lead", params);

                }
            }
        },

        _logPurchaseFromCordovaFacebookPlugin : function( purchaseInfo, value , currency, isSandbox){
            try{
                const p =  new RSVP.Promise(function(resolve, reject){
                    if(window.facebookConnectPlugin && window.facebookConnectPlugin.logPurchase){
                        window.facebookConnectPlugin.logPurchase(value, currency, resolve, reject);
                    }else{
                        resolve();
                    }
                })
                if(TRACK_FACEBOOK_EVENT_ON_APPFLYER && window.plugins && window.plugins.appsFlyer && purchaseInfo){
                    window.plugins.appsFlyer.logEvent("purchaseTrebblePremiumSubscription", purchaseInfo ? purchaseInfo: {});
                }
                /*
                if(TRACK_FACEBOOK_EVENT_ON_APPFLYER && window.plugins && window.plugins.appsFlyer && purchaseInfo){
                    if(isSandbox){
                        window.plugins.appsFlyer.setUseReceiptValidationSandbox(true); // iOS -> for testing in sandbox environment
                    }
                    window.plugins.appsFlyer.validateAndLogInAppPurchase(purchaseInfo, function(){}, function(e){console.error(e)});
                }*/
                return p;
            }catch(error){
                console.error(error);
            }
        },

        _logEventFromCordovaFacebookPlugin : function( name,  params, valueToSum){
            try{
                const p =  new RSVP.Promise(function(resolve, reject){
                    if(window.facebookConnectPlugin && window.facebookConnectPlugin.logEvent){
                        window.facebookConnectPlugin.logEvent(name, params, valueToSum, resolve, reject);
                    }else{
                        resolve();
                    }
                })
                if(TRACK_FACEBOOK_EVENT_ON_APPFLYER && window.plugins && window.plugins.appsFlyer){
                    window.plugins.appsFlyer.logEvent(name, params ? params: {});
                }
                return p;
            }catch(error){
                console.error(error);
            }
        },

        _trackFacebookEvent : function(eventName, params, isCustom){
            try{

                window.fbq(isCustom?'trackCustom':'track', eventName,params);
                if(TRACK_FACEBOOK_EVENT_ON_APPFLYER && window.plugins && window.plugins.appsFlyer){
                    window.plugins.appsFlyer.logEvent(eventName, params ? params: {});
                }
            }catch(error){
                console.error(error);
            }
        },
        

        

    });

    const FacebookEventTrackingHelperInstance = new FacebookEventTrackingHelper();

    export default {
		getInstance : function() {
			return FacebookEventTrackingHelperInstance;
		}
	};