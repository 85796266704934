import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import SequencerViewConfigurationTemplate from 'text!../../../templates/audioEditor/SequencerViewConfigurationTemplate.html';
import ti18n from 'i18n!nls/Sequenceri18n';
import RSVP from 'rsvp';

const TRACK_BUTTON_IDS = {"SHOW_IGNORED_CONTENT": "show_ignored_content_btn","SHOW_GAPS_BETWEEN_WORDS":"show_gaps_between_words_btn", "SHOW_EDIT_BOUNDARIES":"show_edit_boundaries_btn", "AUTOSCROLL":"autoscroll_btn","SHOW_DARK_THEME":"show_dark_theme","REVEAL_DELETED_CONTENT":"reveal_deleted_content_btn"};
const track_settings_array =  [];
const createSettingsParams = function(buttonId, title){
    return {"buttonId": buttonId, "title": title };
}
track_settings_array.push(createSettingsParams(TRACK_BUTTON_IDS.SHOW_DARK_THEME, window.getI18n(ti18n, "SHOW_DARK_THEME")));
track_settings_array.push(createSettingsParams(TRACK_BUTTON_IDS.SHOW_IGNORED_CONTENT, window.getI18n(ti18n, "SHOW_IGNORED_CONTENT")));
track_settings_array.push(createSettingsParams(TRACK_BUTTON_IDS.REVEAL_DELETED_CONTENT, window.getI18n(ti18n, "REVEAL_DELETED_CONTENT")));
track_settings_array.push(createSettingsParams(TRACK_BUTTON_IDS.SHOW_GAPS_BETWEEN_WORDS, window.getI18n(ti18n, "SHOW_GAPS_BETWEEN_WORDS")));
track_settings_array.push(createSettingsParams(TRACK_BUTTON_IDS.SHOW_EDIT_BOUNDARIES, window.getI18n(ti18n, "SHOW_EDIT_BOUNDARIES")));
track_settings_array.push(createSettingsParams(TRACK_BUTTON_IDS.AUTOSCROLL, window.getI18n(ti18n, "AUTOSCROLL")));


const SequencerViewConfigSettingsView = Backbone.View.extend({



    initialize : function(options) {
        this.compiledTemlate =  _.template(SequencerViewConfigurationTemplate);
        this.model = options? options.model : null;
        this._sequencer = this.model;
    },
    
    events : {

    },

    _getButtonSwitchClassNameByButtonId: function(buttonId){
        return  buttonId +"_switch";
    },

    _getSettingValueByButtonId : function(buttonId){
        return this.$el.find(".switch."+ this._getButtonSwitchClassNameByButtonId(buttonId) +" input").prop('checked');
    },

    _setSettingValueByButtonId : function(buttonId, turnOn){
        this.$el.find(".switch."+ this._getButtonSwitchClassNameByButtonId(buttonId) +"  input").prop('checked', turnOn);
    },

    _listenToClickOnSettingButton : function(buttonId, handler){
        this.$el.find(".switch."+ this._getButtonSwitchClassNameByButtonId(buttonId) +"  input").on("click", (function(event){
            handler(event);
            document.scrollingElement.scrollTop = 0;// fix a bug where the scroll position change when the last switch is toggled
        }).bind(this));
    },

    setShowIgnoredContentOn : function(turnOn){
        this._setSettingValueByButtonId(TRACK_BUTTON_IDS.SHOW_IGNORED_CONTENT, turnOn);
    },

    isShowIgnoredContentOn : function(){
        return this._getSettingValueByButtonId(TRACK_BUTTON_IDS.SHOW_IGNORED_CONTENT);
    },

    onShowDeletedNodesBtnClicked : function(){
        this.trigger("deletedNodesDisplayedChange", this.isShowIgnoredContentOn());
    },

    setRevealDeletedContentOn : function(turnOn){
        this._setSettingValueByButtonId(TRACK_BUTTON_IDS.REVEAL_DELETED_CONTENT, turnOn);
    },

    isRevealDeletedContentOn : function(){
        return this._getSettingValueByButtonId(TRACK_BUTTON_IDS.REVEAL_DELETED_CONTENT);
    },

    onRevealDeletedContentOn : function(){
        this.trigger("revealDeletedContentChange", this.isRevealDeletedContentOn());
    },

    setShowGapNodesDisplayedOn : function(turnOn){
        this._setSettingValueByButtonId(TRACK_BUTTON_IDS.SHOW_GAPS_BETWEEN_WORDS, turnOn);
    },

    isShowGapNodesDisplayedOn : function(){
        return this._getSettingValueByButtonId(TRACK_BUTTON_IDS.SHOW_GAPS_BETWEEN_WORDS);
    },

    onShowGapNodesBtnClicked : function(){
        this.trigger("gapNodesDisplayedChange", this.isShowGapNodesDisplayedOn())
    },

    setShowEditBoundariesDisplayedOn : function(turnOn){
        this._setSettingValueByButtonId(TRACK_BUTTON_IDS.SHOW_EDIT_BOUNDARIES, turnOn);
    },

    isShowEditBoundariesDisplayedOn : function(){
        return this._getSettingValueByButtonId(TRACK_BUTTON_IDS.SHOW_EDIT_BOUNDARIES);
    },

    onShowEditBoundariesBtnClicked : function(){
        this.trigger("editBoundaryDisplayedChange", this.isShowEditBoundariesDisplayedOn())
    },

    setShowDarkThemeOn : function(turnOn){
        this._setSettingValueByButtonId(TRACK_BUTTON_IDS.SHOW_DARK_THEME, turnOn);
    },

    isShowDarkThemeOn : function(){
        return this._getSettingValueByButtonId(TRACK_BUTTON_IDS.SHOW_DARK_THEME);
    },

    onShowDarkThemeBtnClicked : function(){
        this.trigger("showDarkThemeChange", this.isShowDarkThemeOn())
    },

    setAutoscrollOn : function(turnOn){
        this._setSettingValueByButtonId(TRACK_BUTTON_IDS.AUTOSCROLL, turnOn);
    },

    isAutoscrolldOn : function(){
        return this._getSettingValueByButtonId(TRACK_BUTTON_IDS.AUTOSCROLL);
    },

    onAutoscrollBtnClicked : function(){
        this.trigger("autoscrollChange", this.isAutoscrolldOn())
    },

    onShowGapNodesSettingsChange : function(){
        this.setShowGapNodesDisplayedOn(this.model.isShowGapsBetweenWords());
    },

    onShowDeletedNodesSettingsChange : function(){
        this.setShowIgnoredContentOn(this.model.isShowIgnoredContent());
    },

    onRevealDeletedContentSettingsChange : function(){
        this.setRevealDeletedContentOn(this.model.isRevealDeletedContentOn());
    },

    onShowEditBoundariesSettingsChange : function(){
        this.setShowEditBoundariesDisplayedOn(this.model.isShowEditBoundaries());
    },

    onShowDarkThemeSettingsChange : function(){
        this.setShowDarkThemeOn(this.model.isShowDarkTheme());
    },

    onAutoscrollChange : function(){
        this.setAutoscrollOn(this.model.isAutoscroll());
    },
    
    initSwitches : function(){
        this.setShowIgnoredContentOn(this.model.isShowIgnoredContent());
        this.setRevealDeletedContentOn(this.model.isRevealDeletedContentOn());
        this.setShowGapNodesDisplayedOn(this.model.isShowGapsBetweenWords());
        this.setShowEditBoundariesDisplayedOn(this.model.isShowEditBoundaries());
        this.setShowDarkThemeOn(this.model.isShowDarkTheme());
        this.setAutoscrollOn(this.model.isAutoscroll());
    },

    getModel: function(){
        return this.model;
    },

    render : function() {
        this.$el.html(this.compiledTemlate({"track_settings_array": track_settings_array, "ti18n": ti18n}));
        this.setElement(this.$el.find(".sequencer_settings").first());
        this.initSwitches();

        this._listenToClickOnSettingButton(TRACK_BUTTON_IDS.SHOW_IGNORED_CONTENT, this.onShowDeletedNodesBtnClicked.bind(this));
        this._listenToClickOnSettingButton(TRACK_BUTTON_IDS.REVEAL_DELETED_CONTENT, this.onRevealDeletedContentOn.bind(this));
        this._listenToClickOnSettingButton(TRACK_BUTTON_IDS.SHOW_GAPS_BETWEEN_WORDS, this.onShowGapNodesBtnClicked.bind(this));
        this._listenToClickOnSettingButton(TRACK_BUTTON_IDS.SHOW_EDIT_BOUNDARIES, this.onShowEditBoundariesBtnClicked.bind(this));
        this._listenToClickOnSettingButton(TRACK_BUTTON_IDS.SHOW_DARK_THEME, this.onShowDarkThemeBtnClicked.bind(this));
        this._listenToClickOnSettingButton(TRACK_BUTTON_IDS.AUTOSCROLL, this.onAutoscrollBtnClicked.bind(this));
        this.listenTo(this.model, "change:showIgnoredContent",  this.onShowDeletedNodesSettingsChange.bind(this));
        this.listenTo(this.model, "change:revealDeletedContent",  this.onRevealDeletedContentSettingsChange.bind(this));
        this.listenTo(this.model, "change:showGapsBetweenWords",  this.onShowGapNodesSettingsChange.bind(this));
        this.listenTo(this.model, "change:showEditBoundaries",  this.onShowEditBoundariesSettingsChange.bind(this));
        this.listenTo(this.model, "change:showDarkTheme",  this.onShowDarkThemeSettingsChange.bind(this));
        
        this.listenTo(this.model, "change:autoscroll",  this.onAutoscrollChange.bind(this));

        
        return this;
    }

    
    

});
export default SequencerViewConfigSettingsView;