import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerOperationLog from "models/audioEditor/sequencerOperationLog/SequencerOperationLog";
import RSVP from "rsvp";

const SequencerOperationCreateWrapWithMusicLog =  SequencerOperationLog.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperationLog.apply(this, [attributes, options]);
        
    },

    getOperationName : function(){
        return "Create Wrap With Music Node";
    },

    setStartSequencerNodeToWrap : function(startSequencerNodeToWrap){
        this.set("startSequencerNodeToWrap", startSequencerNodeToWrap);
    },

    getStartSequencerNodeToWrap : function(){
        return this.get("startSequencerNodeToWrap");
    },

    setEndSequencerNodeToWrap : function(endSequencerNodeToWrap){
        this.set("endSequencerNodeToWrap", endSequencerNodeToWrap);
    },

    getEndSequencerNodeToWrap : function(){
        return this.get("endSequencerNodeToWrap");
    },

    setStartWrapMusicNode : function(startWrapMusicNode){
        this.set("startWrapMusicNode", startWrapMusicNode);
    },

    getStartWrapMusicNode : function(){
        return this.get("startWrapMusicNode");
    },

    setEndWrapMusicNode : function(endWrapMusicNode){
        this.set("endWrapMusicNode", endWrapMusicNode);
    },

    getEndWrapMusicNode : function(){
        return this.get("endWrapMusicNode");
    },




});

export default SequencerOperationCreateWrapWithMusicLog; 