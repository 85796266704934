import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utils from "models/helper/Utils";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import SubscriptionManager from "models/helper/SubscriptionManager";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import PushNotificationHelper from "models/helper/PushNotificationHelper";
import TranscriptionMinutesRemaningWidgetController from "controllers/common/TranscriptionMinutesRemaningWidgetController";
import StripeHelper from "models/helper/StripeHelper";
import ti18n from "i18n!nls/SettingsPagei18n";
import SettingsPageView from "views/settingsPage/SettingsPageView";
import {tz as momentTimezone} from 'moment-timezone';

const Rollout = RolloutHelper.getInstance();
const ENABLE_TREBBLE_PLUS_SUB = Rollout.isFeatureEnabled(Rollout.FEATURES.ENABLE_TREBBLE_PLUS_SUB, true);
const DARK_MODE_FEATURE_AVAILABLE =Rollout.isFeatureEnabled(Rollout.FEATURES.DARK_MODE, true);

const DARK_MODE_AVAILABLE_IN_APP = Rollout.getFeatureVariable(Rollout.FEATURES.DARK_MODE, Rollout.FEATURES.DARK_MODE.variables.available_in_app, true);
const DARK_MODE_AVAILABLE_ON_WEB = Rollout.getFeatureVariable(Rollout.FEATURES.DARK_MODE, Rollout.FEATURES.DARK_MODE.variables.available_on_web, true);
const AUDIO_ANNOUNCEMENTS_CONFIG_AVAIALBLE = Rollout.isFeatureEnabled(Rollout.FEATURES.CONFIGURE_AUDIO_ANNOUNCEMENTS);
const ENABLE_JOURNEY_CREATION = Rollout.isFeatureEnabled(Rollout.FEATURES.ENABLE_JOURNEY_CREATION);
const ALLOW_USER_TO_CONFIGURE_REMINDER_TIME = Rollout.isFeatureEnabled(Rollout.FEATURES.ALLOW_USER_TO_CONFIGURE_REMINDER_TIME);
const DEFAULT_TIME_IN_HOUR_FOR_REMINDER = Rollout.getFeatureVariable(Rollout.FEATURES.ALLOW_USER_TO_CONFIGURE_REMINDER_TIME, Rollout.FEATURES.ALLOW_USER_TO_CONFIGURE_REMINDER_TIME.variables.default_time_in_hour, 7);
const DEFAULT_TIMEZONE_FOR_REMINDER = Rollout.getFeatureVariable(Rollout.FEATURES.ALLOW_USER_TO_CONFIGURE_REMINDER_TIME, Rollout.FEATURES.ALLOW_USER_TO_CONFIGURE_REMINDER_TIME.variables.default_timezone,"America/Toronto");
const ALLOW_USERS_TO_DISABLE_EMAIL_FEED_REMINDERS = Rollout.isFeatureEnabled(Rollout.FEATURES.ALLOW_USERS_TO_DISABLE_EMAIL_FEED_REMINDERS, true);
const ALLOW_CREATORS_TO_SETUP_BANKING_INFO = Rollout.isFeatureEnabled(Rollout.FEATURES.ALLOW_CREATORS_TO_SETUP_BANKING_INFO, true);
const ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION = Rollout.isFeatureEnabled(Rollout.FEATURES.ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION, true);
const SHOW_WARNING_WITH_NUMBER_OF_FREE_HOURS_LEFT_OF_TRANSCRIPTION = Rollout.isFeatureEnabled(Rollout.FEATURES.SHOW_WARNING_WITH_NUMBER_OF_FREE_HOURS_LEFT_OF_TRANSCRIPTION, true);
const NUMBER_OF_FREE_MINUTES_OF_TRANSCRIPTIONS  = Rollout.getFeatureVariable(Rollout.FEATURES.SHOW_WARNING_WITH_NUMBER_OF_FREE_HOURS_LEFT_OF_TRANSCRIPTION, Rollout.FEATURES.SHOW_WARNING_WITH_NUMBER_OF_FREE_HOURS_LEFT_OF_TRANSCRIPTION.variables.number_of_free_minutes_of_transcription, 30);

const SettingsPageController =  Chaplin.Controller.extend({


    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = null;
        this._pageId = options.pageId;
        this.listenTo(SubscriptionManager.getInstance(),SubscriptionManager.getInstance().eventTypes.ON_SUBSCRIPTION_UPDATED, this.onUserSubscriptionChanged.bind(this));
        this.listenTo(SubscriptionManager.getInstance(),SubscriptionManager.getInstance().eventTypes.ON_CREATOR_SUBSCRIPTION_UPDATED, this.onCreatorSubscriptionChanged.bind(this));
        this.listenTo(PushNotificationHelper.getInstance(), "onDailyNotificationRegistrationChange", this.onUserRegistrationForDailyNotificationChanged.bind(this));
        this.listenTo(LocalStorageHelper.getInstance(), LocalStorageHelper.getInstance().eventTypes.USER_INFO_UPDATED, this.showUserInfoIfApplicable.bind(this));
    },
    
    getModel : function(){
        return this.model;
    },
    
    getView : function(){
        return this.view;
    },


    fetchCreatorSubscriptionAndUpdate : function(){
        $.mobile.loading("show");
        return SubscriptionManager.getInstance().getTrebbeCreatorSubscriptionUserIsSubscribedTo(true).then((this.onCreatorSubscriptionChanged.bind(this)).bind(this)).catch((function(error){
            $.mobile.loading("hide");
            window.alertErrorMessage(error);
        }).bind(this));
    },

    onCreatorSubscriptionChanged : function(subscriptionInfo){
        $.mobile.loading("hide");
        if(LocalStorageHelper.getInstance().isCurrentLoggedUserNotAVisitor() && !LocalStorageHelper.getInstance().isLoggedInUserAListener() && ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION){
            this.view.showCreatorSubscriptionSection();
            if(subscriptionInfo){
                let planName =  "Trebble ";
                let costInUSD =  null;
                let billedYearly = false;
                if(StripeHelper.getInstance().isSubscriptionActiveForCreatorPro(subscriptionInfo)){
                    planName = planName + Utils.getInstance().getCreatorFirstPaidTierName();
                    billedYearly = StripeHelper.getInstance().isSubscriptionActiveForCreatorProYearly(subscriptionInfo);
                    costInUSD = billedYearly? Utils.getInstance().getCreatorFirstPaidTierCostInUSDPerMonth(billedYearly)*12 : Utils.getInstance().getCreatorFirstPaidTierCostInUSDPerMonth(billedYearly);
                    //this.view.setCreatorSubscribedToFirstPaidPlan(StripeHelper.getInstance().isSubscriptionActiveForCreatorProYearly(subscriptionInfo), StripeHelper.getInstance().getSubscriptionNextBillingdDate(subscriptionInfo), StripeHelper.getInstance().getSubscriptionCancelEndDate(subscriptionInfo));
                }
                if(StripeHelper.getInstance().isSubscriptionActiveForCreatorProPlus(subscriptionInfo)){
                    planName = planName + Utils.getInstance().getCreatorSecondPaidTierName();
                    billedYearly = StripeHelper.getInstance().isSubscriptionActiveForCreatorProPlusYearly(subscriptionInfo);
                    costInUSD = billedYearly?Utils.getInstance().getCreatorSecondPaidTierCostInUSDPerMonth(billedYearly)*12: Utils.getInstance().getCreatorSecondPaidTierCostInUSDPerMonth(billedYearly);
                //	this.view.setCreatorSubscribedToSecondPaidPlan(StripeHelper.getInstance().isSubscriptionActiveForCreatorProPlusYearly(subscriptionInfo), StripeHelper.getInstance().getSubscriptionNextBillingdDate(subscriptionInfo), StripeHelper.getInstance().getSubscriptionCancelEndDate(subscriptionInfo));
            }
            this.view.setCreatorSubscriptionPlanInfo(planName, billedYearly, StripeHelper.getInstance().getSubscriptionNextBillingdDate(subscriptionInfo), StripeHelper.getInstance().getSubscriptionCancelEndDate(subscriptionInfo), costInUSD);
        }else{
            this.view.setCreatorSubscribedToFreePaidPlan(this.isUserOnCustomFreePlan(this._transcriptionMinutesRemaningWidgetController? this._transcriptionMinutesRemaningWidgetController.getTotalMinutesAvailablePerMonth(): 0));
        }
    }else{
        this.view.hideCreatorSubscriptionSection();
    }
    this.view.setPaymentMethodInfo(subscriptionInfo && subscriptionInfo.displayablePaymentMethod);
},


onUserSubscriptionChanged : function(productInfo){
    if(!SubscriptionManager.getInstance().isProductionInformationAvailable()  || !ENABLE_TREBBLE_PLUS_SUB){
        this.view.hideSubscriptionSection();
    }else{
        this.view.showSubscriptionSection();
        this.view.setOrderSubscriptionButtonsAvailable(SubscriptionManager.getInstance().isPaidAccountSubscriptionOrderAvailable());
        $.mobile.loading("show");
        SubscriptionManager.getInstance().isCurrentUserSubscribedToTrebblePlus(false, productInfo? true:false).then((function(isSubscribedToTrebblePlus){
            if(isSubscribedToTrebblePlus){
                this.view.hideUpgradeBtn();
                this.view.showButtonsToManageSubscriptionsAndBilling();
                return SubscriptionManager.getInstance().getTrebbePlusSubscriptionUserIsSubscribedTo().then((function(subscriptionProductInfo){
                    if(subscriptionProductInfo){
                        this.view.setMembershipType(subscriptionProductInfo.title);
                    }else{
                        this.view.setMembershipType(null);
                    }
                }).bind(this))
            }else{
                if(SubscriptionManager.getInstance().isPaidAccountSubscriptionOrderAvailable()){
                    this.view.showUpgradeBtn();
                    this.view.hideButtonsToManageSubscriptionsAndBilling();
                }
                this.view.setMembershipType(null);
                return;
            }
        }).bind(this)).then((function(){
            $.mobile.loading("hide");
        }).bind(this)).catch((function(error){
            $.mobile.loading("hide");
        }).bind(this));
    }
},


showEditProfilePage: function(){
    PersistentModels.getInstance().getRouter().showUserEditProfileInfo(true);
},

showChangePasswordPage : function(){
    PersistentModels.getInstance().getRouter().showUserChangePasswordPage(true);
},

showChangeProfilePicture : function(){
    return PersistentModels.getInstance().getRouter().changeProfileImage().then(function(){

    });
},

showReferAndEarnPage: function(){
    PersistentModels.getInstance().getRouter().showReferralPage(null, true);
},

upgradeNow : function(){
    PersistentModels.getInstance().getRouter().showUpgradeToPaidSubscriptionPopup();
},

restorePurchase: function(){
    this.view.setPurchaseBeingRestored(true);
    return SubscriptionManager.getInstance().restorePurchases().then((function(){
        this.view.setPurchaseBeingRestored(false);
    }).bind(this)).catch((function(){
        this.view.setPurchaseBeingRestored(false);
    }).bind(this));
},

showTermsOfService: function(){
    if(window.waitForCordovaToLoad){
        window.openOrignalFunction(window.getI18n(ti18n, "TERM_OF_SERVICE_URL"), "_blank");
    }else{
        window.open(window.getI18n(ti18n, "TERM_OF_SERVICE_URL"), "_blank");
    }
},

showPrivacyPolicy: function(){
    if(window.waitForCordovaToLoad){
        window.openOrignalFunction(window.getI18n(ti18n, "PRIVACY_POLICY_URL"), "_blank");
    }else{
        window.open(window.getI18n(ti18n, "PRIVACY_POLICY_URL"), "_blank");
    }
},

showHelpAndFeedback: function(){
    PersistentModels.getInstance().getRouter().showHelpAndFeedback(null, true);
},

rateTrebble : function(){
    PersistentModels.getInstance().getRouter().navigateToTheAppStoreToRateTheApp();
},

showFollowOnFacebook : function(){
    if(window.waitForCordovaToLoad){
        window.openOrignalFunction(window.getI18n(ti18n, "TREBBLE_FACEBOOK_PAGE_URL"), "_blank");
    }else{
        window.open(window.getI18n(ti18n, "TREBBLE_FACEBOOK_PAGE_URL"), "_blank");
    }
},

showFollowOnTwitter : function(){
    if(window.waitForCordovaToLoad){
        window.openOrignalFunction(window.getI18n(ti18n, "TREBBLE_TWITTER_PAGE_URL"), "_blank");
    }else{
        window.open(window.getI18n(ti18n, "TREBBLE_TWITTER_PAGE_URL"), "_blank");
    }
},

logOut: function(){
    PersistentModels.getInstance().getRouter().logOut();
},

updateAppVersion : function(){
    const appVersionWithUnixTimestamp ="("+window.trebbleAppVersion+")";
    this.view.setAppVersion(appVersionWithUnixTimestamp);
    if(window.waitForCordovaToLoad && window.cordova && window.cordova.getAppVersion && window.cordova.getAppVersion.getVersionNumber){
        window.cordova.getAppVersion.getVersionNumber().then((function(version){
            this.view.setAppVersion(version+ " "+appVersionWithUnixTimestamp);
        }).bind(this));
    }

},

showReferLinkIfApplicable : function(){
    if(!Rollout.isFeatureEnabled(Rollout.FEATURES.USER_REFERRAL) || !Utils.getInstance().canReferralLinkBeGenerated()){
        this.view.hideReferUserSettingsLink();
    }
},


showCreateJourneyLinkIfApplicable: function(){
    const userInfoJson= LocalStorageHelper.getInstance().getUserInfo();
    const userInfoModel =  userInfoJson?Utils.getInstance().getModelFromUserJson(userInfoJson):null;
    if(userInfoModel && (!userInfoModel.getProfileType() || userInfoModel.getProfileType() == Utils.getInstance().getProfileTypes().LISTENER) && ENABLE_JOURNEY_CREATION){
        this.view.showCreateFirstJourneyLink();
    }else{
        this.view.hideCreateFirstJourneyLink();
    }
},

showRateTrebbleLinkIfApplicable : function(){
    if(Utils.getInstance().isAppRatingAvailable()){
        const appRateSectionDescription = window.getI18n(ti18n, "ENJOYING_THE_APP_LET_US_KNOW") + " "+(window.isIOSMobileDevice? window.getI18n(ti18n, "APPLE_APP_STORE"): window.getI18n(ti18n, "GOOGLE_PLAY")) ;
        this.view.setAppRateSectionDescription(appRateSectionDescription);
        this.view.showRateTrebbleLink();
    }else{
        this.view.hideRateTrebbleLink();
    }
},

    /*showNotificationSectionIfApplicable: function(){
        if(PushNotificationHelper.getInstance().isPushNotificationLibraryInstalled()){
            this.view.showNotificationSection();
            this.view.setDailyFeedReadyNotification(PushNotificationHelper.getInstance().isUserRegisteredForDailyNotification());
        }else{
            this.view.hideNotificationSection();
        }
    },*/

    showPushNotificationSettingsIfApplicable: function(){
        if(PushNotificationHelper.getInstance().isPushNotificationLibraryInstalled()){
            this.view.showPushNotificationSettings();
            this.view.setDailyFeedReadyNotification(PushNotificationHelper.getInstance().isUserRegisteredForDailyNotification());
        }else{
            this.view.hidePushNotificationSettings();
        }
    },

    shouldContentSectionBeDisplayed : function(){
        return AUDIO_ANNOUNCEMENTS_CONFIG_AVAIALBLE || DARK_MODE_FEATURE_AVAILABLE;
    },

    showDarkModeOptionIfApplicable : function(){
        if(DARK_MODE_FEATURE_AVAILABLE){
            if((window.waitForCordovaToLoad && DARK_MODE_AVAILABLE_IN_APP)||(!window.waitForCordovaToLoad && DARK_MODE_AVAILABLE_ON_WEB)){
                this.view.showContentSection();
                this.view.setDarkModeSwitchValue(LocalStorageHelper.getInstance().isAppConfiguredToBeAlwaysInDarkMode());
                return;
            }
        }
        if(!this.shouldContentSectionBeDisplayed()){
            this.view.hideContentSection();
        }
    },

    showAudioAnnouncementsConfigOptionIfApplicable : function(){
        if(AUDIO_ANNOUNCEMENTS_CONFIG_AVAIALBLE){
            this.view.showContentSection();
            this.view.setPlayAudioAnnouncementsSwitchValue(!Utils.getInstance().isAudioAnnouncementsDisabledLocallyForUser());
            return;
            
        }
        if(!this.shouldContentSectionBeDisplayed()){
            this.view.hideContentSection();
        }
    },

    showTimeReminderSettingsIfApplicable : function(){
        if(ALLOW_USER_TO_CONFIGURE_REMINDER_TIME){
            this.view.showTimeReminderSettings();
            let hourtoRunNotification = LocalStorageHelper.getInstance().getTimeReminderForNotifications();
            let timezoneToRunNotification = LocalStorageHelper.getInstance().getTimezoneReminderForNotifications();
            if(!hourtoRunNotification){
                hourtoRunNotification = DEFAULT_TIME_IN_HOUR_FOR_REMINDER? DEFAULT_TIME_IN_HOUR_FOR_REMINDER : 7;
                timezoneToRunNotification = DEFAULT_TIMEZONE_FOR_REMINDER? DEFAULT_TIMEZONE_FOR_REMINDER : "America/Toronto";
            }
            const currentTime = new Date();
            const m  = momentTimezone(currentTime.getFullYear()+"-"+(currentTime.getMonth()+1)+"-"+(currentTime.getDate())+" "+hourtoRunNotification+":00","YYYY-M-D H:mm", timezoneToRunNotification);
            const timeToRunNotificationAccordingToLocalTimezone = m.hours();
            this.view.setTimeReminder(timeToRunNotificationAccordingToLocalTimezone);
        }else{
            this.view.hideTimeReminderSettings();
        }
    },

    onTimeRemiderChanged : function(newTimeInHours){
        return TrebbleClientAPIHelper.getInstance().updateUserSetTimeForNotification(newTimeInHours, Intl.DateTimeFormat().resolvedOptions().timeZone).then((function(){
            return TrebbleClientAPIHelper.getInstance().getUserProfileInfoByUsername(LocalStorageHelper.getInstance().getUserInfo().username);
        }).bind(this)).then((function(updatedUserInfo){
            return LocalStorageHelper.getInstance().updateUserInfo(updatedUserInfo);
        }).bind(this))
    },

    onDarkModeChange : function(isDarkMode){
        LocalStorageHelper.getInstance().setAppConfiguredToBeAlwaysInDarkMode(isDarkMode);
        PersistentModels.getInstance().getRouter().setAppropriateAppTheme();
    },	

    onPlayAudioAnnouncementsChange : function(audioAnnouncementEnabled){
        LocalStorageHelper.getInstance().setAudioAnnouncementsDisabled(!audioAnnouncementEnabled);
        LocalStorageHelper.getInstance().setAudioAnnouncementsManuallyChanged(true);
    },	

    onDailyFeedNotificationChange : function(dailyFeedNotificationTurnedOn){
        LocalStorageHelper.getInstance().setUserManuallyEnabledDisabledDailyFeedNotification(dailyFeedNotificationTurnedOn);
        if(dailyFeedNotificationTurnedOn){
            PushNotificationHelper.getInstance().initNativePushNotification();
        }else{
            PushNotificationHelper.getInstance().unregisterNativePushNotification();
        }
    },

    initEmailFeedReadyNotificationSetting : function(){
        if(ALLOW_USERS_TO_DISABLE_EMAIL_FEED_REMINDERS){
            this.view.showNotificationSection();
            this.view.setEmailFeedReadyNotification(!LocalStorageHelper.getInstance().isEmailDailyDigestDisabled());
        }else{
            this.view.hideNotificationSection();
        }
    },

    onEmailFeedNotificationChange: function(emailFeedNotificationTurnedOn){
        const updatePromise = emailFeedNotificationTurnedOn? TrebbleClientAPIHelper.getInstance().enableEmailDigestNotification(): TrebbleClientAPIHelper.getInstance().disableEmailDigestNotification();
        return updatePromise.then((function(){
            return TrebbleClientAPIHelper.getInstance().getUserProfileInfoByUsername(LocalStorageHelper.getInstance().getUserInfo().username);
        }).bind(this)).then((function(updatedUserInfo){
            LocalStorageHelper.getInstance().updateUserInfo(updatedUserInfo);
        }).bind(this))
    },

    onUserRegistrationForDailyNotificationChanged : function(notificationTurnedOn){
        this.view.setDailyFeedReadyNotification(notificationTurnedOn)
    },

    showManageSubscription : function(){
        return SubscriptionManager.getInstance().manageSubscriptions();
    },

    showManageBilling : function(){
        return SubscriptionManager.getInstance().manageBilling();
    },

    showLoggedInUserInfo: function(){
        const userInfoJson = LocalStorageHelper.getInstance().getUserInfo();
        if(userInfoJson){
            const userModel = userInfoJson?Utils.getInstance().getModelFromUserJson(userInfoJson):null;
            PersistentModels.getInstance().getRouter().showUserProfilePageByUsername(null, userModel.getUserId(), null, true);
        }
    },

    showUserInfoIfApplicable: function(){
        const userInfoJson = LocalStorageHelper.getInstance().getUserInfo();
        if(userInfoJson){
            this.view.showUserInfo();
            const userModel = userInfoJson?Utils.getInstance().getModelFromUserJson(userInfoJson):null;
            this.view.setUserInfo(userModel.getFullName(), userModel.getProfileImageUrl());
        }else{
            this.view.hideUserInfo();
        }
    },

    showCreateYourFirstJourney : function(){
        return PersistentModels.getInstance().getRouter().showJourneyCreationWizardPage(true, (function(model, action, data){
            if(action == "newJourneyInfo" && data){
                return TrebbleClientAPIHelper.getInstance().getUserProfileInfoByUsername(LocalStorageHelper.getInstance().getUserInfo().username).then((function(updatedUserInfo){
                    LocalStorageHelper.getInstance().updateUserInfo(updatedUserInfo);
                }).bind(this))
            }

        }).bind(this));
    },


    initPayoutSectionIfApplicable : function(){
        const userInfoJson= LocalStorageHelper.getInstance().getUserInfo();
        const userInfoModel =  userInfoJson?Utils.getInstance().getModelFromUserJson(userInfoJson):null;
        
        if(ALLOW_CREATORS_TO_SETUP_BANKING_INFO && userInfoModel && userInfoModel.getProfileType() != Utils.getInstance().getProfileTypes().LISTENER){
            this.view.showPayoutSection();
            this.refreshPayoutInfo();
        }else{
            this.view.hidePayoutSection();
        }

    },

    refreshPayoutInfo: function(){
        this.view.setRetrievingStripeAccountStatus();
        return TrebbleClientAPIHelper.getInstance().isStripeAccountFullySetupToReceivePayments().then((function(isStripeConnected){
            return this.view.setStripeAccountConnectionStatus(isStripeConnected);
        }).bind(this));
    },

    connectToStripe : function(){
        this.view.setRetrievingStripeAccountStatus();
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("stripe_setup", "Attempt to connect to Stripe account", "Attempt to connect to Stripe account");
        } 
        const forwardUrlOnOnboardingCompleted = PersistentModels.getInstance().getRouter().getOnStripeOnboardingCompletedURL();
        const forwardUrlOnOnboardingInterupted = PersistentModels.getInstance().getRouter().getOnStripeOnboardingInteruptedURL();
        return TrebbleClientAPIHelper.getInstance().getUrlToSetupStripe(forwardUrlOnOnboardingCompleted, forwardUrlOnOnboardingInterupted).then((function(urlToConnectToStripe){
            const openedWindowRef =  window.open(urlToConnectToStripe);
            const onWindowCloseCallback = (function(){
                this.refreshPayoutInfo();
            }).bind(this);
            Utils.getInstance().listenOnWindowClose(openedWindowRef, onWindowCloseCallback);
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("stripe_setup", "Connection to Stripe account successfull", "Connection to Stripe account successfull");
            } 
        }).bind(this)).catch(function(error){
            window.alertErrorMessage(error);
            this.refreshPayoutInfo();
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("stripe_setup", "Connection to Stripe account failed", "Connection to Stripe account failed");
            } 
        });
    },

    showStripeDashboard : function(){
        $.mobile.loading("show");
        return TrebbleClientAPIHelper.getInstance().getUrlToStripeDashboard().then((function(stripeDashboardUrl){
            $.mobile.loading("hide");
            const openedWindowRef =  window.open(stripeDashboardUrl);
        }).bind(this)).catch(function(error){
            $.mobile.loading("hide");
            window.alertErrorMessage(error);
        });
    },
    
    confirmDisconnectFromStripe : function(){
        Utils.getInstance().confirmAction(window.getI18n(ti18n, "DISCONNECT_FROM_STRIPE"), window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_DISCONNECT_YOUR_STRIPE_ACCOUNT"), (function(){this.disconnectFromStripe();}).bind(this));
    },

    confirmDeleteStripeAccount: function(){
        Utils.getInstance().confirmAction(window.getI18n(ti18n, "DELETE_STRIPE_ACCOUNT"), window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_DELETE_YOUR_STRIPE_ACCOUNT"), (function(){this.deleteStripeAccount();}).bind(this));
    },

    disconnectFromStripe: function(){
        this.view.setRetrievingStripeAccountStatus();
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("stripe_setup", "Attempt to Disconnect from Stripe account", "Attempt to  Disconnect from Stripe account");
        } 
        return TrebbleClientAPIHelper.getInstance().disconnectFromStripe().then((function(){
            this.refreshPayoutInfo();
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("stripe_setup", "Disconnection from Stripe account successfull", "Disconnection from Stripe account successfull");
            } 
        }).bind(this)).catch(function(error){
            window.alertErrorMessage(error);
            this.refreshPayoutInfo();
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("stripe_setup", "Disconnection from Stripe account failed", "Disconnection from Stripe account failed");
            } 
        });
    },

    deleteStripeAccount: function(){
        this.view.setRetrievingStripeAccountStatus();
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("stripe_setup", "Attempt to delete Stripe account", "Attempt to delete Stripe account");
        } 
        return TrebbleClientAPIHelper.getInstance().deleteStripeAccount().then((function(){
            this.refreshPayoutInfo();
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("stripe_setup", "Deletion of Stripe account successfull", "Deletion of Stripe account successfull");
            } 
        }).bind(this)).catch(function(error){
            window.alertErrorMessage(error);
            this.refreshPayoutInfo();
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("stripe_setup", "Deletion of Stripe account failed", "Deletion of Stripe account failed");
            } 
        });
    },
    
    changeCreatorPlan: function(){
        PersistentModels.getInstance().getRouter().showUpgradeCreatorSubscriptionPopup();
    },

    updatePaymentMethod: function(){
        return PersistentModels.getInstance().getRouter().showReplacePaymentForCreatorSubscriptionPopup(PersistentModels.getInstance().getRouter().getCompletePaymentSetupAndRediredtToSettingsPageURL());
        $.mobile.loading("show");
        return TrebbleClientAPIHelper.getInstance().getStripeCreatorCustomerPortalUrl(window.location.href,true, false).then((function(creatorStripeCustomerPortalUrl){
            $.mobile.loading("hide");
            if(window.waitForCordovaToLoad){
                const openedWindowRef =  window.open(creatorStripeCustomerPortalUrl);
            }else{
                window.location = creatorStripeCustomerPortalUrl;
            }
        }).bind(this)).catch(function(error){
            $.mobile.loading("hide");
            window.alertErrorMessage(error);
        });
    },

    cancelCreatorSubscriptionConfirm : function(){
        Utils.getInstance().confirmAction("", "Are you sure you want to cancel your creator subscription?", (function(){this.cancelCreatorSubscription();}).bind(this));
    },

    cancelCreatorSubscription: function(){
        $.mobile.loading("show");
        return SubscriptionManager.getInstance().cancelCreatorSubscription().then((function(){
            $.mobile.loading("hide");
            return this.fetchCreatorSubscriptionAndUpdate();
        }).bind(this)).catch(function(error){
            $.mobile.loading("hide");
            window.alertErrorMessage(error);
        })
    },

    viewCreatorStripeCustomerPortal: function(){
        $.mobile.loading("show");
        return TrebbleClientAPIHelper.getInstance().getStripeCreatorCustomerPortalUrl(window.location.href,false, true).then((function(creatorStripeCustomerPortalUrl){
            $.mobile.loading("hide");
            const openedWindowRef =  window.open(creatorStripeCustomerPortalUrl);
        }).bind(this)).catch(function(error){
            $.mobile.loading("hide");
            window.alertErrorMessage(error);
        });
    },

    upgradeCreatorPlan : function(){
        PersistentModels.getInstance().getRouter().showUpgradeCreatorSubscriptionPopup();
    },
    
    reactivateCreatorSubscriptionConfirm : function(){
        Utils.getInstance().confirmAction("", "Are you sure you want to reactivate your creator subscription?", (function(){this.reactivateCreatorSubscription();}).bind(this));
    },

    reactivateCreatorSubscription: function(){
        $.mobile.loading("show");
        return TrebbleClientAPIHelper.getInstance().reactivateCreatorSubscription().then((function(){
            $.mobile.loading("hide");
            return this.fetchCreatorSubscriptionAndUpdate();
        }).bind(this)).catch(function(error){
            $.mobile.loading("hide");
            window.alertErrorMessage(error);
        })
    },

    changePaymentMethodFromStripe: function(){
        $.mobile.loading("show");
        return TrebbleClientAPIHelper.getInstance().getStripeCreatorCustomerPortalUrl(window.location.href,true, false).then((function(creatorStripeCustomerPortalUrl){
            $.mobile.loading("hide");
            if(window.waitForCordovaToLoad){
                const openedWindowRef =  window.open(creatorStripeCustomerPortalUrl);
            }else{
                window.location = creatorStripeCustomerPortalUrl;
            }
        }).bind(this)).catch(function(error){
            $.mobile.loading("hide");
            window.alertErrorMessage(error);
        });

    },

    _onTranscriptionInfoUpdated: function(){
        if(!this._transcriptionMinutesRemaningWidgetController.hasActivePaidSubscription()){
            this.view.setCreatorSubscribedToFreePaidPlan(this.isUserOnCustomFreePlan(this._transcriptionMinutesRemaningWidgetController.getTotalMinutesAvailablePerMonth()));
        }
    },

    isUserOnCustomFreePlan : function(totalMinutesAvailablePerMonth){
        return NUMBER_OF_FREE_MINUTES_OF_TRANSCRIPTIONS < totalMinutesAvailablePerMonth;
    },

    initTranscriptionRemainingLeftWidget : function(){
        if(SHOW_WARNING_WITH_NUMBER_OF_FREE_HOURS_LEFT_OF_TRANSCRIPTION || ALLOW_USER_TO_PAY_FOR_CREATOR_SUBSCRIPTION){
            this._transcriptionMinutesRemaningWidgetController = new TranscriptionMinutesRemaningWidgetController();
            this.listenTo(this._transcriptionMinutesRemaningWidgetController,"transcriptionInfoUpdated",this._onTranscriptionInfoUpdated.bind(this));
            this._transcriptionMinutesRemaningWidgetController.buildModelAndRenderView().then((function(){
                this.view.setTranscriptionRemaingLeftWidgetView(this._transcriptionMinutesRemaningWidgetController.getView());
                this.view.showTranscriptionRemaingLeftWidget(true);
                this._onTranscriptionInfoUpdated();
            }).bind(this))
        }
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                
                const pageViewParams= {};
                pageViewParams.pageId = this._pageId;
                pageViewParams.userInfoJson = LocalStorageHelper.getInstance().getUserInfo();
                this.view = new SettingsPageView(pageViewParams);
                this.view.render();
                this.listenTo(this.view, "editProfile", this.showEditProfilePage);
                this.listenTo(this.view, "changePassword", this.showChangePasswordPage);
                this.listenTo(this.view, "changeProfilePicture", this.showChangeProfilePicture);
                this.listenTo(this.view, "referAndEarn", this.showReferAndEarnPage);
                this.listenTo(this.view, "upgradeNow", this.upgradeNow);
                this.listenTo(this.view, "restorePurchase", this.restorePurchase);

                this.listenTo(this.view, "showTermsOfService", this.showTermsOfService);
                this.listenTo(this.view, "showPrivacyPolicy", this.showPrivacyPolicy);
                this.listenTo(this.view, "showHelpAndFeedback", this.showHelpAndFeedback);
                this.listenTo(this.view, "rateTrebble", this.rateTrebble);
                this.listenTo(this.view, "showFollowOnFacebook", this.showFollowOnFacebook);
                this.listenTo(this.view, "showFollowOnTwitter", this.showFollowOnTwitter);
                this.listenTo(this.view, "showManageSubscription", this.showManageSubscription);
                this.listenTo(this.view, "showManageBilling", this.showManageBilling);
                this.listenTo(this.view, "showLoggedInUserInfo", this.showLoggedInUserInfo);
                this.listenTo(this.view, "onDailyFeedNotificationChange", this.onDailyFeedNotificationChange);
                this.listenTo(this.view, "onEmailFeedNotificationChange", this.onEmailFeedNotificationChange);
                this.listenTo(this.view, "onDarkModeChange", this.onDarkModeChange);
                this.listenTo(this.view, "onPlayAudioAnnouncementsChange", this.onPlayAudioAnnouncementsChange);
                this.listenTo(this.view, "showCreateYourFirstJourney", this.showCreateYourFirstJourney);
                this.listenTo(this.view, "onTimeRemiderChanged", this.onTimeRemiderChanged);
                this.listenTo(this.view, "connectToStripe", this.connectToStripe);
                this.listenTo(this.view, "confirmDisconnectFromStripe", this.confirmDisconnectFromStripe);
                this.listenTo(this.view, "confirmDeleteStripeAccount", this.confirmDeleteStripeAccount);
                this.listenTo(this.view, "showStripeDashboard", this.showStripeDashboard)
                this.listenTo(this.view, "upgradeCreatorPlan", this.upgradeCreatorPlan)
                this.listenTo(this.view, "changeCreatorPlan", this.changeCreatorPlan)
                this.listenTo(this.view, "updatePaymentMethod", this.updatePaymentMethod)
                this.listenTo(this.view, "cancelCreatorSubscription", this.cancelCreatorSubscriptionConfirm)
                this.listenTo(this.view, "viewCreatorStripeCustomerPortal", this.viewCreatorStripeCustomerPortal)
                this.listenTo(this.view, "reactivateCreatorSubscription", this.reactivateCreatorSubscriptionConfirm)
                this.listenTo(this.view, "changePaymentMethodFromStripe", this.changePaymentMethodFromStripe)

                
                
                
                this.listenTo(this.view, "logOut", this.logOut);

                this.onUserSubscriptionChanged();
                this.fetchCreatorSubscriptionAndUpdate();
                this.updateAppVersion();
                this.showReferLinkIfApplicable();
                this.showPushNotificationSettingsIfApplicable();
                this.showDarkModeOptionIfApplicable();
                this.showAudioAnnouncementsConfigOptionIfApplicable();
                this.showRateTrebbleLinkIfApplicable();
                this.showUserInfoIfApplicable();
                this.showCreateJourneyLinkIfApplicable();
                this.initEmailFeedReadyNotificationSetting();
                this.initTranscriptionRemainingLeftWidget();
                this.showTimeReminderSettingsIfApplicable();
                this.initPayoutSectionIfApplicable();
                

                resolve(this);
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }


});
export default SettingsPageController;