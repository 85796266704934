import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import AudioFilter from "models/audioEditor/filters/AudioFilter";
import RSVP from "rsvp";

const CLASSNAME = "GainFilter";
const GainFilter =  AudioFilter.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        AudioFilter.apply(this, [attributes, options]);
        this.set("type", GainFilter.CLASSNAME);
    },

    setGainValue : function(gainValue){
        this.set("gainValue",gainValue);
    },


    getGainValue : function(){
        return this.get("gainValue");
    },

    isEqual : function(filter){
        if(filter instanceof GainFilter){
            return (this.isDisabled() == filter.isDisabled() && this.getGainValue() === filter.getGainValue()); 
        }
        return false;
    },


    clone : function(){
        const cloneFilter = new GainFilter();
        cloneFilter.setDisabled(this.isDisabled());
        cloneFilter.setGainValue(this.getGainValue());
        return cloneFilter;
    },

    needToPrepareAudioContext: function(){
        return false;
    },

    prepareAudioContext: function(audioContext){
        return RSVP.Promise.resolve(audioContext);
    },

    applyFilterToNode : function(audioNode, audioContext, arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime ,sequencerRendererTracksInfo ){
        if(!this.isDisabled()){
            const gainValue = this.getGainValue();
            const gainNode = audioContext.createGain();
            gainNode.gain.value = gainValue; 
            audioNode.connect(gainNode);

            if(arrayOfAudioNodesUsed){
                arrayOfAudioNodesUsed.push(gainNode);
            }

            return gainNode;
        }else{
            return audioNode;
        }
    },

    applyFilter : function(previousOutputNode, audioContext, arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime, sequencerRendererTracksInfo, audioBuffer){
        if(!this.isDisabled()){
            if(!previousOutputNode){
                previousOutputNode = audioContext.createBufferSource();
                previousOutputNode.buffer = audioBuffer;
                if(arrayOfAudioNodesUsed){
                    arrayOfAudioNodesUsed.push(previousOutputNode);
                }
            }

            return RSVP.Promise.resolve(this.applyFilterToNode(previousOutputNode, audioContext, arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime, sequencerRendererTracksInfo ));
        }else{
            return RSVP.Promise.resolve(previousOutputNode);
        }
    },


});

GainFilter.CLASSNAME = CLASSNAME;

export default GainFilter; 