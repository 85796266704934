import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import SequencerSettingsView from "views/audioEditor/SequencerSettingsView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const SequencerSettingsController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.model =  options.sequencerSettings;
        _.extend(this, Backbone.Events);
        
    },


    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    onReduceRumbleChange: function(){
        this.model.setRumbleReductionOn(this.view.isReduceRumbleOn());
    },

    onReduceHarshEssChangeChange: function(){
        this.model.setRemoveHarshEssOn(this.view.isReduceHarsEssOn());
    },

    onVoiceLevelingChangeChange: function(){
        this.model.setVoiceVolumeLevelingOn(this.view.isVoiceLevelingOn());
    },

    onSpeechClarityChange: function(){
        this.model.setImproveSpeechClarityOn(this.view.isSpeechClarityOn());
    },

    onAutomaticCrossfadeChange: function(){
        this.model.setAutoCrossfadingBetweenWordsClosesTogetherInReferenceAudio(this.view.isAutomaticCrossfadeOn());
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {

                const params = {};
                params.model = this.model ;
                this.view = new SequencerSettingsView(params);
                this.view.render();

                this.listenTo(this.view, "reduceRumbleChange", this.onReduceRumbleChange);
                this.listenTo(this.view, "reduceHarshEssChange", this.onReduceHarshEssChangeChange);
                this.listenTo(this.view, "voiceLevelingChange", this.onVoiceLevelingChangeChange);
                this.listenTo(this.view, "speechClarityChange", this.onSpeechClarityChange);
                this.listenTo(this.view, "automaticCrossfadeChange", this.onAutomaticCrossfadeChange);

                resolve();

            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default SequencerSettingsController;