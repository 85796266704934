import $ from 'jquery';
import _ from 'underscore';
import PureReactTestComponent from './PureReactTestComponent';
import DynamicContentView from "views/common/DynamicContentView";
import { createRoot } from 'react-dom/client';
import React from 'react';
import RSVP from 'rsvp';


const ReactTestContentView = DynamicContentView.extend({


    initialize : function(options) {
        DynamicContentView.prototype.initialize.call(this, {"canBeAppendedBeforeLoad": true});
    },
    load: function(){
        const root = createRoot(this.$el.get(0));
        root.render(<PureReactTestComponent/>);
        return RSVP.Promise.resolve();
    },

    render : function() {
        
        return this;
    }

});

export default ReactTestContentView;