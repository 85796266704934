import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import BasicSequencerNodeInspectorView from "views/audioEditor/sequencerNodeInspectorSection/BasicSequencerNodeInspectorView";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const BasicSequencerNodeInspectorController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.model =  options.sequencerNode;
        this._sequencer = options.sequencer;
        _.extend(this, Backbone.Events);
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    destroyView : function(){
        this.stopListening(this.view);
        this.view.remove();
    },


    listenToEventsOnView: function(){
        
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                const params = {};
                params.sequencerNode = this.getModel();
                this.view = new BasicSequencerNodeInspectorView(params);
                this.view.render();
                this.listenToEventsOnView();
                resolve();

            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default BasicSequencerNodeInspectorController;