import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleAudioListItemView from "views/common/TrebbleAudioListItemView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import ti18n from 'i18n!nls/TrebbleAudioListItem18n';
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const TrebbleAudioListItemController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.model = options.model;
        this.context = options.context;
        this._customImageUrlProperty = options.customImageUrlProperty? options.customImageUrlProperty : null;
        this._notTransparent = options.notTransparent ? options.notTransparent: false;
        this._notPlayable =  options.notPlayable ? options.notPlayable: false;
        this._canBeSelected =  options.canBeSelected ? options.canBeSelected: false;
        this._doNotShowArtistName = options.doNotShowArtistName? options.doNotShowArtistName : false;
        this._canBeDeleted = options.canBeDeleted ? options.canBeDeleted : false;
        this._covertArtNotDisplayed = options.covertArtNotDisplayed ? options.covertArtNotDisplayed: false;
        this._onTrebbleAudioSelected = options.onTrebbleAudioSelected ? options.onTrebbleAudioSelected:null;
        this._onTrebbleAudioUnselected = options.onTrebbleAudioUnselected ? options.onTrebbleAudioUnselected:null;
        this._onTrebbleAudioRequestToBeDeleted = options.onTrebbleAudioRequestToBeDeleted ? options.onTrebbleAudioRequestToBeDeleted:null;
        this._onMoreRequestToShowMoreOptions = options.onMoreRequestToShowMoreOptions ? options.onMoreRequestToShowMoreOptions:null;
        this._showUploadDate = options.showUploadDate ? options.showUploadDate: null;
        this._onPlaybackStateChange = options.onPlaybackStateChange ;
        this._showVideo = options.showVideo;

        this._cachedPlayedAudioUrl = null;

        _.extend(this, Backbone.Events);
        this._listenToPlayerEvents();
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    destroy: function(){
        try{
            this.stopListening();
            if(this.view && this.view.remove){
                this.view.remove();
            }
        }catch(error){
            console.error(error);
        }
    },

    _listenToPlayerEvents : function(){
        if(!this._notPlayable){
            this.listenTo(PersistentModels.getInstance().getPlayer(),"songPreviewStartedPlaying",this.updatePlaybackButton.bind(this));
            this.listenTo(PersistentModels.getInstance().getPlayer(),"songPreviewUnLoaded",this.updatePlaybackButton.bind(this));
            this.listenTo(PersistentModels.getInstance().getPlayer(),"songPreviewChanged",this.updatePlaybackButton.bind(this));
            this.listenTo(PersistentModels.getInstance().getPlayer(),"songPreviewLoading",this.updatePlaybackButton.bind(this));
            this.listenTo(PersistentModels.getInstance().getPlayer(),"songPreviewLoaded",this.updatePlaybackButton.bind(this));
            this.listenTo(PersistentModels.getInstance().getPlayer(),"errorPreview",this.updatePlaybackButton.bind(this));
            this.listenTo(PersistentModels.getInstance().getPlayer(),"songPreviewFailedLoading",this.updatePlaybackButton.bind(this));
            this.listenTo(PersistentModels.getInstance().getPlayer(),"songPreviewPaused",this.updatePlaybackButton.bind(this));
            this.listenTo(PersistentModels.getInstance().getPlayer(),"songPreviewCancelled",this.updatePlaybackButton.bind(this));
        }
    },



    updatePlaybackButton : function(){
        const playerModel =  PersistentModels.getInstance().getPlayer();
        if(this.model.getAudioUrl() == playerModel.getAudioPreviewURL() || this._cachedPlayedAudioUrl == playerModel.getAudioPreviewURL()){
            if(playerModel.isPreviewLoading() ){
                this.view.setPlaybackState(false, true);
                this._notifyPlaybackStateListener(false, true);
                
            }else{
                if (playerModel.isPreviewPlaying()) {
                    this.view.setPlaybackState(true, false);
                    this._notifyPlaybackStateListener(true, false);
                } else {
                    this.view.setPlaybackState(false, false);
                    this._notifyPlaybackStateListener(false, false);
                }
            }
        }else{
            this.view.setPlaybackState(false, false);
            this._notifyPlaybackStateListener(false, false);
        }

    },

    setAudioIsLoading  : function(){
        this.view.setPlaybackState(false, true);
    },

    _notifyPlaybackStateListener : function(isPlaying, isLoading){
        if(this._onPlaybackStateChange){
            this._onPlaybackStateChange(this.model,isPlaying,  isLoading);
        }
    },


    stopPreviewAndResumePlayback : function(){
        PersistentModels.getInstance().getPlayer().terminateAudioPreviewAndResumePlayback();
    },


    playAudio: function(){
        if(this.model.getAudioUrl()){
            PersistentModels.getInstance().getPlayer().playAudioPreview(this.model.getAudioUrl());
        }else{
            if(this.model.canFetchAudioUrl()){
                return this.model.fetchAudioUrl().then((function(audioUrl){
                    if(audioUrl){
                        this._cachedPlayedAudioUrl =  audioUrl;
                        PersistentModels.getInstance().getPlayer().playAudioPreview(audioUrl);
                    }
                }).bind(this))
            }
        }
    },


    stopAudio: function(){
        PersistentModels.getInstance().getPlayer().terminateAudioPreviewAndResumePlayback();
    },

    audioSelected: function(){
        if(this._onTrebbleAudioSelected){
            if(this.model.canFetchAudioUrl()){
                return this.model.fetchAudioUrl().then((function(audioUrl){
                    this._onTrebbleAudioSelected(this.model);
                }).bind(this))
            }else{
                this._onTrebbleAudioSelected(this.model);
            }
        }
    },

    audioUnselected: function(){
        if(this._onTrebbleAudioUnselected){
            this._onTrebbleAudioUnselected(this.model)
        }
    },

    addedToFavorites: function(){

    },

    removeFromFavorites: function(){

    },

    showOptionMenu: function(){
        if(this._onMoreRequestToShowMoreOptions){
            this._onMoreRequestToShowMoreOptions(this.model)
        }
    },

    onDeleteButtonClicked: function(){
        if(this._onTrebbleAudioRequestToBeDeleted){
            this._onTrebbleAudioRequestToBeDeleted(this.model)
        }
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                const params = {};
                params.model = this.model;
                params.customImageUrlProperty = this._customImageUrlProperty;
                params.notTransparent = this._notTransparent;
                params.canBeSelected = this._canBeSelected;
                params.covertArtNotDisplayed  = this._covertArtNotDisplayed;
                params.notPlayable = this._notPlayable;
                params.doNotShowArtistName = this._doNotShowArtistName;
                params.showVideo = this._showVideo;
                params.showUploadDate = this._showUploadDate;
                params.canBeDeleted = this.model.getCanBeDeleted() ;
                this.view = new TrebbleAudioListItemView(params);

                this.listenTo(this.view, "playAudio",this.playAudio);
                this.listenTo(this.view, "stopAudio",this.stopAudio);
                this.listenTo(this.view, "audioSelected",this.audioSelected);
                this.listenTo(this.view, "audioUnselected",this.audioUnselected);
                this.listenTo(this.view, "addedToFavorites",this.addedToFavorites);
                this.listenTo(this.view, "removeFromFavorites",this.removeFromFavorites);
                this.listenTo(this.view, "showOptionMenu",this.showOptionMenu);
                this.listenTo(this.view, "onDeleteButtonClicked" , this.onDeleteButtonClicked)
                
                this.view.render();
                this.updatePlaybackButton();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default TrebbleAudioListItemController;