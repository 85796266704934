import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";

/*"models/Song",
/* "collections/Playlist",
"collections/FixedPlaylist",
"collections/AutoFixedPlaylist",
"collections/DynamicPlaylist",
"collections/SocialDynamicPlaylist",*/import RSVP from "rsvp";

const JOURNEY_STATUS  = {"UNKNOWN":"UNKNOWN","DRAFT":"DRAFT","AVAILABLE":"AVAILABLE","SCHEDULED":"SCHEDULED","ARCHIVED":"ARCHIVED"};
const PlaylistSummary =  Backbone.Model.extend({
    idAttribute: "id",


    getLoaderKey : function(){
        return this.get("loaderKey");
    },
    

    
    getName : function(){
        return this.get("name");
    },
    
    
    getCreator : function(){
        return this.get("author");
    },
    
    
    getSummary : function(){
        return this.get("artistSample");
    },
    
    
    getlastUpdateDateString : function(){
        return this.get("lastUpdateDateString");
    },
    getDescription : function(){
        return this.get("description");
    },
    
    getAudioPreviewUrl : function(){
        return this.get("audioPreviewUrl");
    },
    
    getGenreIdToAudioPreviewUrl : function(){
        return this.get("genreIdToAudioPreviewUrl");
    },
    
    getAudioPreviewUrlByGenreId : function(genreId){
        return this.getGenreIdToAudioPreviewUrl()? this.getGenreIdToAudioPreviewUrl()[genreId]:null;
    },
    
    isFollowing : function(){
        return this.get("isFollowing");
    },

    hasPurchased : function(){
        return this.get("hasPurchased");
    },


    setHasPurchased : function(hasPurchased){
        return this.set("hasPurchased",hasPurchased);
    },

    isPaid : function(){
        return this.get("isPaid");
    },

    getCostInUSD : function(){
        return this.get("costInUSD");
    },
    

    getGoal : function(){
        return this.get("goal");
    },
    
    getCategoryId: function(){
        return this.get("categoryId");
    },

    getCityId: function(){
        return this.get("cityId");
    },

    getCoverArtUrl: function(){
        return this.get("coverArtUrl");
    },


    setCoverArtUrl : function(newCoverArtUrl){
        return this.set("coverArtUrl",newCoverArtUrl);
    },
    
    getCoverArtUrlBlur : function(){
        return this.get("coverArtUrlBlur");
    },
    
    setCoverArtUrlBlur : function(newCoverArtUrlBlur){
        return this.set("coverArtUrlBlur",newCoverArtUrlBlur);
    },
    
    getCoverArtUrlInfo : function(){
        return this.get("coverArtUrlInfo");
    },
    
    setCoverArtUrlInfo : function(newCoverArtUrlBlur){
        return this.set("coverArtUrlInfo",newCoverArtUrlBlur);
    },

    setCustomURL : function(newCustomURL){
        return this.set("customURL",newCustomURL);
    },
    
    getCustomURL : function(){
        return this.get("customURL");
    },

    getCoverArtUrlBlurInfo : function(){
        return this.get("coverArtUrlBlurInfo");
    },
    
    setCoverArtUrlBlurInfo : function(newCoverArtUrlBlurInfo){
        return this.set("coverArtUrlBlurInfo",newCoverArtUrlBlurInfo);
    },
    
    getCoverArtBase64 : function(){
        return this.get("coverArtBase64");
    },
    
    canBeFollowed : function(){
        return true;//return this.get("canBeFollowed");
    },

    canBeArchived : function(){
        return this.canBeEdited() && this.getStatus() == JOURNEY_STATUS.AVAILABLE;
    },
    

    canBeRevertedToDraft : function(){
        return this.canBeEdited() && this.getStatus() == JOURNEY_STATUS.SCHEDULED;
    },

    canBeRemovedFromArchive : function(){
        return this.canBeEdited() && this.getStatus() == JOURNEY_STATUS.ARCHIVED;
    },

    isInDraftStatus : function(){
        return this.canBeEdited() && this.getStatus() == JOURNEY_STATUS.DRAFT;
    },
    
    canBeEdited : function(){
        return this.get("canBeEdited");
    },

    canBeEditedInState : function(){
        return this.canBeEdited() && (this.getStatus() == JOURNEY_STATUS.UNKNOWN || this.getStatus() == JOURNEY_STATUS.DRAFT);
    },

    canViewAnalytics : function(){
        return this.canBeEdited() && (this.getStatus() == JOURNEY_STATUS.AVAILABLE || this.getStatus() == JOURNEY_STATUS.SCHEDULED || this.getStatus() == JOURNEY_STATUS.ARCHIVED);
    },
    
    getNumberOfFollowers : function(){
        return this.get("numberOfFollowers");
    },


    getNumberOfPurchasers : function(){
        return this.get("numberOfPurchasers");
    },
    
    getNumberOfSongs : function(){
        return this.get("numberOfSongs");
    },

    setNumberOfSongs : function(numberOfSongs){
        return this.set("numberOfSongs",numberOfSongs);
    },
    
    getTotalNumberOfPlays : function(){
        return this.get("totalNumberOfPlays");
    },
    
    getOwnerNumberOfPlays : function(){
        return this.get("ownerNumberOfPlays");
    },
    
    getPlaySuccessRate : function(){
        return this.get("playSuccessRate");
    },
    
    getNumberOfSongsFavorited : function(){
        return this.get("numberOfSongsFavorited");
    },
    
    getNumberOfCapsules : function(){
        return this.get("numberOfCapsules");
    },
    
    getNumberOfSongsComments : function(){
        return this.get("numberOfSongsComments");
    },

    getAvailabilityDate : function(){
        const availabilityDate = this.get("availabilityDate");
        if(availabilityDate  && typeof(availabilityDate) == "string"){
            return new Date(availabilityDate);
        }
        return availabilityDate;
    },

    isAvailableToListen : function(){
        return !(this.getAvailabilityDate() && this.getAvailabilityDate().getTime() > (new Date()).getTime());
    },

    getAvailabilityDateTimezone : function(){
        return this.get("availabilityDateTimezone");
    },

    canBeShared : function(){
        return this.getStatus() == JOURNEY_STATUS.AVAILABLE || this.getStatus() == JOURNEY_STATUS.SCHEDULED;
    },

    canBeDeleted : function(){
        return this.canBeEdited() && this.getStatus() == JOURNEY_STATUS.DRAFT;
    },


    setName: function(name){
        this.set("name", name);
    },
    
    setDescription : function(description){
        this.set("description", description);
    },
    
    setFollowing : function(following){
        const isFollowingOldValue = this.get("isFollowing");
        this.set("isFollowing", following);
        if(following !== isFollowingOldValue){
            if(following){
                this.addFollower();
            }else{
                this.removeFollower();
            }
        }
    },

    getJourneyLengthInDays: function(){
        return this.get("journeyLengthInDays");
    },

    getJourneyDurationInMinutes : function(){
        return this.get("journeyDurationInMinutes");
    },

    getJourneyDurationInMinutesToDisplay : function(){
        const durationInMinutes = this.getJourneyDurationInMinutes();
        let toDisplay = "";
        let numberOfHours = 0;
        let numberOfMinutes = 0;
        if(durationInMinutes){
            if(durationInMinutes >= 60){
                numberOfHours = Math.floor(durationInMinutes/60);

                if(numberOfHours* 60!= durationInMinutes){
                    numberOfMinutes = durationInMinutes - (numberOfHours* 60);
                }

            }else{
                numberOfMinutes =durationInMinutes;
            }
            if(numberOfHours> 0){
                toDisplay = toDisplay + numberOfHours + " h";
                if(numberOfMinutes > 0){
                    toDisplay = toDisplay + numberOfMinutes + " min";
                }
            }else{
                if(numberOfMinutes > 0){
                    toDisplay = toDisplay + numberOfMinutes + " min";
                }
            }
            return toDisplay;
        }else{
            return toDisplay;
        }
    },
    
    setPlaying : function(isPlaying){
        if(this.isPlaying() != isPlaying){
            this.set("isPlaying", isPlaying);
        }
    },
    
    isPlaying : function(isPlaying){
        return this.get("isPlaying");
    },

    isInDraftMode : function(){
        return this.get("isInDraftMode");
    },

    isArchived : function(){
        return this.get("isArchived");
    },

    isPublished : function(){
        return this.get("isPublished");
    },


    isScheduled : function(){
        return this.get("isScheduled");
    },


    getDraftJourneyBlueprintId: function(){
        return this.get("draftJourneyRefUID");
    },

    getPublishedJourneyBlueprintId: function(){
        return this.get("publishedJourneyRefUID");
    },

    getNumberOf5StarRatings : function(){
        return this.get("numberOf5StarsReviews")? this.get("numberOf5StarsReviews"): 0;
    },

    getNumberOf4StarRatings : function(){
        return this.get("numberOf4StarsReviews")? this.get("numberOf4StarsReviews"): 0;
    },
    getNumberOf3StarRatings : function(){
        return this.get("numberOf3StarsReviews")? this.get("numberOf3StarsReviews"): 0;
    },
    getNumberOf2StarRatings : function(){
        return this.get("numberOf2StarsReviews")? this.get("numberOf2StarsReviews"): 0;
    },

    getNumberOf1StarRatings : function(){
        return this.get("numberOf1StarsReviews")? this.get("numberOf1StarsReviews"): 0;
    },

    getTotalNumberOfRatings : function(){
        return this.getNumberOf1StarRatings()+ this.getNumberOf2StarRatings()+ this.getNumberOf3StarRatings()+this.getNumberOf4StarRatings()+this.getNumberOf5StarRatings();
    },

    getPercentageOf5StarRatings : function(){
        const totalNumberOfRatings = this.getTotalNumberOfRatings();
        return totalNumberOfRatings != 0? this.getNumberOf5StarRatings()*100/totalNumberOfRatings: 0;
    },

    getPercentageOf4StarRatings : function(){
        const totalNumberOfRatings = this.getTotalNumberOfRatings();
        return totalNumberOfRatings != 0? this.getNumberOf4StarRatings()*100/totalNumberOfRatings: 0;
    },

    getPercentageOf3StarRatings : function(){
        const totalNumberOfRatings = this.getTotalNumberOfRatings();
        return totalNumberOfRatings != 0? this.getNumberOf3StarRatings()*100/totalNumberOfRatings: 0;
    },

    getPercentageOf2StarRatings : function(){
        const totalNumberOfRatings = this.getTotalNumberOfRatings();
        return totalNumberOfRatings != 0? this.getNumberOf2StarRatings()*100/totalNumberOfRatings: 0;
    },

    getPercentageOf1StarRatings : function(){
        const totalNumberOfRatings = this.getTotalNumberOfRatings();
        return totalNumberOfRatings != 0? this.getNumberOf1StarRatings()*100/totalNumberOfRatings: 0;
    },

    getAverageRatings: function(){
        const totalNumberOfRatings = this.getTotalNumberOfRatings();
        return totalNumberOfRatings != 0?((this.getNumberOf1StarRatings()*1 + this.getNumberOf2StarRatings()*2 + this.getNumberOf3StarRatings()*3 + this.getNumberOf4StarRatings()*4 +this.getNumberOf5StarRatings()*5 )/totalNumberOfRatings): 0;
    },

    getStatus : function(){
        if(this.isArchived()){
            return JOURNEY_STATUS.ARCHIVED
        }
        if(this.isPublished()){
            if(this.getAvailabilityDate() && this.getAvailabilityDate().getTime() > (new Date()).getTime()){
                return JOURNEY_STATUS.SCHEDULED;
            }else{
                return JOURNEY_STATUS.AVAILABLE
            }
        }
        if(this.isInDraftMode() && this.canBeEdited()){
            return JOURNEY_STATUS.DRAFT
        }
        return JOURNEY_STATUS.UNKNOWN;
    },


    addFollower : function(){
        let numberOfFollowers = this.get("numberOfFollowers");
        numberOfFollowers =  numberOfFollowers? numberOfFollowers: 0;
        numberOfFollowers = numberOfFollowers + 1;
        this.set("numberOfFollowers", numberOfFollowers);
    },
    
    removeFollower : function(){
        let numberOfFollowers = this.get("numberOfFollowers");
        numberOfFollowers =  numberOfFollowers? numberOfFollowers: 0;
        if(numberOfFollowers != 0){
            numberOfFollowers = numberOfFollowers - 1;
        }
        this.set("numberOfFollowers",numberOfFollowers);
    },


});

PlaylistSummary.JOURNEY_STATUS = JOURNEY_STATUS;
export default PlaylistSummary;