import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import SongListPageController from "controllers/songListPageController/SongListPageController";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utils from "models/helper/Utils";
import ti18n from "i18n!nls/MyLibraryi18n";
import RSVP from "rsvp";

const SongInUserLibraryPageController = SongListPageController.extend({


    initialize : function(options) {
        options = options? options:{};
        options.pageTitle = window.getI18n(ti18n, "SONGS");
        this.username =  options.username;
        return SongListPageController.prototype.initialize.call(this, options);
    },

    getSongListLoadHandler : function(){
        return (function(){
            return TrebbleClientAPIHelper.getInstance().getAllSongInUserLibrary(this.username);
        }).bind(this);
    },

});
export default SongInUserLibraryPageController;