import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import CommentListItemController from "controllers/comment/CommentListItemController";
import CommentBoxController from "controllers/comment/CommentBoxController";
import CollectionRepeatController from "controllers/common/CollectionRepeatController";
import SongContextController from "controllers/common/SongContextController";
import CapsuleContextController from "controllers/common/CapsuleContextController";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import Comments from "collections/Comments";
import DynamicContentView from "views/common/DynamicContentView";
import DynamicPageView from "views/common/DynamicPageView";
import PageTemplate from 'text!../../../templates/comment/CommentListPageTemplate.html';
import ti18n from "i18n!nls/Commenti18n";
import RSVP from "rsvp";

const CommentListPageController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = options.model;
        this._disableReply = options.disableReply;
        this._idOfCommentToFocusOn = options.idOfCommentToFocusOn;
        this.songRefSummary = options.songRefSummary;
        this._pageId = options.pageId;
        this.commentsCollection = null;
        this.context = options.context;
        const controllerParams = {};
        controllerParams.disableReply = this._disableReply;
        controllerParams.context = this.context;
        const collectionRepeatControllerParams = {};
        collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildInfoBoxParams();
        collectionRepeatControllerParams.showEmtptyInfoBox = true;
        collectionRepeatControllerParams.controllerOption = controllerParams;
        collectionRepeatControllerParams.resizePlaceHolderElem = true;
        collectionRepeatControllerParams.setHeightToFitContainer = true;
        collectionRepeatControllerParams.itemControllerConstructor = CommentListItemController;
        collectionRepeatControllerParams.modelLoadHandler = options.loadHandler?options.loadHandler: this._getCommentsForSongLoadHandler.bind(this);
        if(options.context){
            collectionRepeatControllerParams.context = options.context;
        }
        this.collectionRepeatController = new CollectionRepeatController(collectionRepeatControllerParams);
        //this.listenTo(this.collectionRepeatController,"finishLoading", this._onCollecctionRepeaterFinishLoading.bind(this));
        const commentBoxControllerParams =  {};
        commentBoxControllerParams.commentContext = this.model;
        commentBoxControllerParams.songRefSummary =  this.songRefSummary;
        this._commentBoxController = new CommentBoxController(commentBoxControllerParams);
        this._contextController  = null;
        let contextControllerParams = {};
        if(Utils.getInstance().isSongInstance(this.model)){
            contextControllerParams = {};
            contextControllerParams.model = this.model;
            this._contextController = new SongContextController(contextControllerParams);
        }else{
            if(Utils.getInstance().isCommentInstance(this.model)){
                contextControllerParams = {};
                contextControllerParams.model = this.model;
                contextControllerParams.disableReply = true;
                this._contextController = new CommentListItemController(contextControllerParams);
            }else{
                if(Utils.getInstance().isCapsuleInstance(this.model)){
                    contextControllerParams = {};
                    contextControllerParams.model = this.model;
                    contextControllerParams.disableReply = false;
                    this._contextController = new CapsuleContextController(contextControllerParams);
                }else{

                }
            }
        }
        this.listenTo(this.collectionRepeatController,"finishLoading", this._onCommentFinishedRendering.bind(this));

    },

    _onCommentFinishedRendering : function(){
        this.focusOnPredefinedCommentIfApplicable();
    },

    _onPageShow : function(){
        this.focusOnPredefinedCommentIfApplicable();
    },

    focusOnPredefinedCommentIfApplicable : function(){
        if(this.model && Utils.getInstance().isCommentInstance(this.model) && this.model.getCommentId() === this._idOfCommentToFocusOn){
            this._contextController.focus();//visually focus on context view
            this._idOfCommentToFocusOn = null;
        }
        if(this.commentsCollection && this._idOfCommentToFocusOn){
            this.focusOnCommentWithId(this._idOfCommentToFocusOn);
            this._idOfCommentToFocusOn = null;
        }

    },

    _buildInfoBoxParams : function(){
        const params = {};
        params.iconClass = "ion-chatbox";
        params.message = window.getI18n(ti18n,"NO_COMMENT");
        params.helpMessage = window.getI18n(ti18n,"POST_A_COMMENT_TO_START_A_DISCUSSION");
        return params;

    },

    _getCommentsForSongLoadHandler: function(){
        if(this.model ){
            if(Utils.getInstance().isSongInstance(this.model)){
                const songJsonForBackend = Utils.getInstance().getSongJsonForBackendFromSongModel(this.model);
                return TrebbleClientAPIHelper.getInstance().getCommentsInfoForSong(songJsonForBackend, this.songRefSummary).then((function(commentsArray){
                    this.commentsCollection = new Comments(commentsArray);
                    return this.commentsCollection.models;
                }).bind(this));
            }else{
                if(Utils.getInstance().isCommentInstance(this.model)){
                    const commentId = this.model.getCommentId();
                    return TrebbleClientAPIHelper.getInstance().getRepliesForComment(commentId).then((function(commentsArray){
                        this.commentsCollection = new Comments(commentsArray);
                        return this.commentsCollection.models;
                    }).bind(this));
                }else{
                    if(Utils.getInstance().isCapsuleInstance(this.model)){
                        const capsuleId = this.model.getCapsuleId();
                        return TrebbleClientAPIHelper.getInstance().getCommentsInfoForCapsule(capsuleId, this.songRefSummary).then((function(commentsArray){
                            this.commentsCollection = new Comments(commentsArray);
                            return this.commentsCollection.models;
                        }).bind(this));
                    }else{
                        return RSVP.Promise.resolve();
                    }
                }
            }
        }else{
            return RSVP.Promise.resolve();
        }
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    _onNewCommentPosted : function(newCommentModel){
        if(newCommentModel){
            this.collectionRepeatController.addItemToBottom(newCommentModel);
        }
    },

    _notifyCommentConsumed : function(){
        if(this.model ){
            if(Utils.getInstance().isSongInstance(this.model)){
                return PersistentModels.getInstance().notifyUserOfUnconsumedOwnerComments(this.model, this.context, this.songRefSummary);
            }else{
                if(Utils.getInstance().isCommentInstance(this.model)){
                    const commentId =  this.model.getCommentId();
                    return TrebbleClientAPIHelper.getInstance().markCommentRepliesAsConsumed(commentId);
                }else{
                    if(Utils.getInstance().isCapsuleInstance(this.model)){
                        const capsuleId = this.model.getCapsuleId();
                        return PersistentModels.getInstance().notifyUserOfUnconsumedCapsuleComments(capsuleId, this.context, this.songRefSummary);
                    }else{
                        return RSVP.Promise.resolve();
                    }
                }
            }
        }else{
            return RSVP.Promise.resolve();
        }
    },

    focusOnCommentWithId : function(commentId){
        const commentModel  = this.commentsCollection ? this.commentsCollection.get(commentId): null;
        if(commentModel){
            this.collectionRepeatController.focusOnItem(commentModel);
        }
    },


    buildModelAndRenderView : function() {
        $.mobile.loading("show");
        return new RSVP.Promise((function(resolve, reject) {
            try {
                const promises = [];
                this.listenTo(this._commentBoxController, "newCommentCreated", this._onNewCommentPosted.bind(this));
                promises.push(this._commentBoxController.buildModelAndRenderView());
                promises.push(this.collectionRepeatController.buildModelAndRenderView());
                if(this._contextController){
                    promises.push(this._contextController.buildModelAndRenderView());
                }
                RSVP.Promise.all(promises).then((function(result){
                    const contentView =new DynamicContentView();
                    contentView.render();
                    if(this._contextController){
                        contentView.$el.append(this._contextController.getView().$el);
                    }
                    const contextHeighInPx = 60;
                    const commentFieldBoxHeighInPx = 61;
                    const paddingLeftInPX = 40;
                    this.collectionRepeatController.getView().$el.css("height","calc(100% - "+(contextHeighInPx+commentFieldBoxHeighInPx)+"px)");
                    //this.collectionRepeatController.getView().$el.css("width","calc(100% - "+(commentFieldBoxHeighInPx)+"px)");
                    this.collectionRepeatController.getView().$el.css("width","100%");
                    contentView.$el.append(this.collectionRepeatController.getView().$el);
                    contentView.$el.append(this._commentBoxController.getView().$el);
                    if(Utils.getInstance().isCommentInstance(this.model)){
                        this.collectionRepeatController.getView().$el.css("padding-left",paddingLeftInPX+"px");
                    }
                    const pageViewParams= {};
                    pageViewParams.pageId = this._pageId;
                    pageViewParams.pageViewTemplate = PageTemplate;
                    pageViewParams.containerSelector = ".ui-content";
                    pageViewParams.contentView = contentView;
                    pageViewParams.pageTemplateOptions ={"ti18n": ti18n};
                    pageViewParams.onPageShow = this._onPageShow.bind(this);
                    pageViewParams.ignoreDefaultPageUIContentPadding =  true;
                    pageViewParams.onOrientationChangeCustomHandler = (function(containerContentHeight, page$el){
                        const commentBoxHeigh = window.trebble.defaultValues.commentBoxHeigth;
                        const contextViewHeight = this._contextController? this._contextController.getView().$el.height(): 0;
                        page$el.find("#collection_repeat_container").css("height", containerContentHeight - commentBoxHeigh - contextViewHeight + "px");
                        this.collectionRepeatController.getView()._displayInfoBoxIfApplicable();
                    }).bind(this);
                    this.view = new DynamicPageView(pageViewParams);
                    this.view.render();
                    this.view.$el.on("click", "#header-back-button", this.view.navigateToPreviousPage.bind(this.view));
                    this._notifyCommentConsumed();
                    $.mobile.loading("hide");
                    resolve();
                }).bind(this)).catch((function(error){
                    $.mobile.loading("hide");
                    reject(error);
                }).bind(this));

            } catch (error) {
                $.mobile.loading("hide");
                reject(error);
            }

        }).bind(this));

}

});

export default CommentListPageController;