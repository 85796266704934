import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import ShareTrebblePopupView from "views/shareTrebble/ShareTrebblePopupView";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import ti18n from "i18n!nls/shareTrebblePopupi18n";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";
import FacebookHelper from "models/helper/FacebookHelper";

const Rollout = RolloutHelper.getInstance();
const SHARE_JOURNEY_TRAILER = Rollout.isFeatureEnabled(Rollout.FEATURES.SHARE_JOURNEY_TRAILER, true);
const ShareJourneyPopupController = Chaplin.Controller.extend({

    initialize : function(options) {
        this._journeyRadioId = options.journeyRadioId;
        this.context = options.context;
        this._songShareableLink = null;
        this._songWidgetLandscapeLink = null;
        this._trebbleWidgetPortraitLink = null;
        this._initializeLinks(this._journeyRadioId);
        _.extend(this, Backbone.Events);
    },

    _initializeLinks : function(journeyRadioId){
        this._journeyShareableLink = "https://s.trebble.fm/j/"+journeyRadioId;
        this._playJourneyTrailerShareableLink = "https://s.trebble.fm/p/p/j/"+journeyRadioId;
    },


    _copyToClipboard : function(toCopy, confirmMessage){
        if('cordova' in window && window.cordova && window.cordova.plugins && window.cordova.plugins.clipboard ){
            window.cordova.plugins.clipboard.copy(toCopy);
            Utils.getInstance().showShortToastMessage(confirmMessage);
        }else{
            const isCopySuccessfull = Utils.getInstance().copyTextToClipboard(toCopy);
            if (!window.waitForCordovaToLoad && !isCopySuccessfull) {
                navigator.trebbleNotification.alert(window.getI18n(ti18n, "PLEASE_PRESS_CNTR_C_TO_COPY"));
            }else{
                Utils.getInstance().showShortToastMessage(confirmMessage);
            }
        }
    },

    _shareNatively : function(toShare,url, title){
        if (navigator.share) {
            navigator.share({
                'title': title,
                text: toShare,
                url: url
            });
        }
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    copyShareableLinkToClipboard : function(toCopy){
        this._copyToClipboard(toCopy, window.getI18n(ti18n,"SHAREABLE_LINK_COPIED_TO_CLIPBOARD"));
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Journey", "JourneyShareLinkCopied", "Journey Share Link Copied");
        } 
        
    },



    shareNativelyShareableLinkToClipboard : function(toShare){
        this._shareNatively(null, toShare, window.getI18n(ti18n, "JOURNEY_URL"));
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Journey", "JourneyShareLinkSharedNatively", "Journey Share Link Shared Natively");
        }
        
    },

    shareOnFacebook : function(toShare){
        FacebookHelper.getInstance().showDialog({
            method: 'share',
            href: toShare,
        },{"entity":"Journey", "eventName":"JourneyShareFacebook", "eventLabel":"Journey URL Shared On Facebook"} ).catch(function(error){
            console.error(error);
            window.alertErrorMessage(error);
        });

    },

    shareOnTwitter : function(toShare){
        const w = 550;
        const h = 285;
        const left = window.getLeftPositionForPopupWindow(w);
        const top = window.getTopPositionForPopupWindow(h);
        const openedWindow = window.open("https://twitter.com/intent/tweet?via=trebblefm&url="+encodeURIComponent(toShare),"Twitter", "resizable=1,height="+h+",width="+w+",top="+top+",left="+left+"");
        if((!openedWindow || openedWindow.closed || typeof openedWindow.closed=='undefined') && !window.waitForCordovaToLoad){
            window.alertErrorMessage(window.getI18n(ti18n, "PLEASE_ALLOW_POPUPS"));
        }
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Journey", "JourneyShareTwitter", "Journey URL Shared On Twitter");
        }
    },

    previewLink: function(linkToPreview){
        const openedWindow =  window.open(linkToPreview , "_blank");
        if((!openedWindow || openedWindow.closed || typeof openedWindow.closed=='undefined') && !window.waitForCordovaToLoad){
            window.alertErrorMessage(window.getI18n(ti18n, "PLEASE_ALLOW_POPUPS"));
        }
        return openedWindow;
    },


    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {

                const params = {};
                params.trebbleId = this._journeyRadioId;
                params.trebbleSubscribePageLink = this._journeyShareableLink;
                params.trebbleShareableLink = this._playJourneyTrailerShareableLink;
                params.canCopyEmbeddedWidgetLink = false;
                params.shareableLinkHasAShortcast = false;
                params.shareOnSmartSpeakers = false;
                params.shareSubscribePage = true;
                params.canEmbedLandscapePlayer = false;
                params.canEmbedPortraitPlayer = false;
                params.doNotTrebblePlayeableLink = SHARE_JOURNEY_TRAILER ?false: true;
                params.customTextToInviteToSubscribe = window.getI18n(ti18n, "SHARE_THE_URL_BELOW_FOR_DETAILS_ON_JOURNEY");
                params.customTextToInviteToListenOnWeb = window.getI18n(ti18n, "SHARE_A_LINK_TO_INVITE_TO_LISTEN_TO_TRAILER_ON_WEB");
                params.customTextToInviteToListenOnWebDescription = window.getI18n(ti18n, "SHARE_THE_URL_BELOW_TO_INVITE_TO_LISTEN_TO_TRAILER");
                this.view = new ShareTrebblePopupView(params);
                this.listenTo(this.view, "preview",this.previewLink);
                this.listenTo(this.view, "copyLinkToClipboard",this.copyShareableLinkToClipboard);
                this.listenTo(this.view, "shareNativelyLinkToClipboard",this.shareNativelyShareableLinkToClipboard);
                this.listenTo(this.view, "shareOnFacebookButtonClicked",this.shareOnFacebook);
                this.listenTo(this.view, "shareOnTwitterButtonClicked",this.shareOnTwitter);

                this.view.render();
                resolve();


            } catch (error) {
                reject(error);
            }

        }).bind(this));

}

});

export default ShareJourneyPopupController;