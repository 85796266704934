import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import Utils from "models/helper/Utils";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import RecordingSettingsPopupView from "views/recordingSettings/RecordingSettingsPopupView";
import PersistentModels from "services/PersistentModels";
import ListItemCollection from "collections/ListItemCollection";
import RSVP from "rsvp";

const RecordingSettingsPopupController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.context = options? options.context : null;
        this._onPopupCloseHanlder = options? options.onPopupClose : null;
        _.extend(this, Backbone.Events);
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },


    saveChanges : function(){
        $.mobile.loading("show");
        const audioDeviceId = this.view.getAudioDeviceIdSelected();
        LocalStorageHelper.getInstance().setPreferredDeviceAudioId(audioDeviceId);
        $.mobile.loading("hide");
        this.view._close();	
    },

    _onPopupClosed : function(){
        if(this._onPopupCloseHanlder){
            this._onPopupCloseHanlder();
        }
    },



    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                const params = {};
                params.preferredAudioDeviceId = LocalStorageHelper.getInstance().getPreferredDeviceAudioId();
                const recordingSettingsPopupView = new RecordingSettingsPopupView(params);
                recordingSettingsPopupView.render();
                this.view = recordingSettingsPopupView;
                this.listenTo(this.view, "onPopupClosed", this._onPopupClosed.bind(this));
                this.listenTo(this.view, "saveChanges", this.saveChanges.bind(this));
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

}

});

export default RecordingSettingsPopupController;