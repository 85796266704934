import _ from 'underscore';
import OptionPopupView from "views/common/OptionPopupView";

const SequencerAutomatedOperationsOptionView = OptionPopupView.extend({

    initialize : function(options) {
        const viewOptions =  {};
        this.model = options.model;
        this.elementSource$el = options.elementSource$el;
        this.contentView = options.contentView;
        viewOptions.contentView = options.contentView;
        viewOptions.contentPosition = "center";
        viewOptions.elementSource$el = this.elementSource$el;
        viewOptions.showAsMenuIfInBrowser = true;
        viewOptions.alwaysShowAsFloatingMenu = true;
        viewOptions.showCloseButton = true;
        viewOptions.customPopupPosition = options.popupPosition? options.popupPosition: "bottom right";
        viewOptions.id = "SequencerAutomatedOperationsPopup";
        viewOptions.customDropObjClass = "SequencerAutomatedOperationsPopupDrop";
        viewOptions.hideHeading = true;
        viewOptions.onBeforeClose = this.onBeforeClose.bind(this);
        OptionPopupView.prototype.initialize.call(this, viewOptions);
    },

    afterRendering : function(){
        
    },

    onBeforeClose : function(event){
        return this.contentView.canBeDestroyed(event);
    },

   
    onAfterPageShow : function(){
        this.$el.addClass("SequencerAutomatedOperationsPopup");
    },



});

export default SequencerAutomatedOperationsOptionView;