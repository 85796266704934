	import $ from 'jquery';
    import _ from 'underscore';
    import Backbone from "backbone";
    import PersistentModels from "services/PersistentModels";
    import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
    import Utils from "models/helper/Utils";
    import PlaylistHelper from "models/helper/PlaylistHelper";
    import ti18n from "i18n!nls/PlayerServicei18n";
    import RSVP from 'rsvp';


    var PlayerServiceModel = Backbone.Model.extend({
        "default" : {
            "player" : null,
        },

        initialize : function() {

        },



        addSongsToQueue : function(songModelsArray, doNotChangeQueueContext){
            return PersistentModels.getInstance().getPlayer().addSongsToCurrentPlaylist(songModelsArray, doNotChangeQueueContext);
        },

        clearQueueAndSongToQueue : function(songModelsArray,playlistName, context){
            var createdPlaylist = PlaylistHelper.getInstance().generatePlaylistContainingSong(songModelsArray, context);
            PersistentModels.getInstance().getPlayer().setPlaylist(createdPlaylist).then((function(){
                if(playlistName){
                    createdPlaylist.setName(playlistName);
                } 
            }).bind(this));


        },


        generateSimilarLocalSongPlaylistAndAddToQueue : function(songSeed, playFirstInQueue){
            var generatedPlaylist =  PlaylistHelper.getInstance().generatePlaylistOfLocalSongs(songSeed);
            /*return generatedPlaylist.load().then(function(){
                return PersistentModels.getInstance().getPlayer().setPlaylist(generatedPlaylist,playFirstInQueue);
            })*/
return PersistentModels.getInstance().getPlayer().setPlaylist(generatedPlaylist,playFirstInQueue);
},

generateSimilarSongPlaylistAndAddToQueue : function(songSeed, playFirstInQueue){
var generatedPlaylist =  PlaylistHelper.getInstance().generatePlaylistOfSimilarSongs(songSeed);
/*return generatedPlaylist.load().then(function(){
return PersistentModels.getInstance().getPlayer().setPlaylist(generatedPlaylist,playFirstInQueue);
})*/
return PersistentModels.getInstance().getPlayer().setPlaylist(generatedPlaylist,playFirstInQueue);
},

generateSimilarLocalSongPlaylistAndAddToQueueFromLocalSongUri : function(songUri,playFirstInQueue){
var songModel =  PersistentModels.getInstance().getSongModelBySongIdFromMyLibraryOrFavorites(songUri);
if(songModel){
this.generateSimilarLocalSongPlaylistAndAddToQueue(songModel,playFirstInQueue);
}
},

generateSimilarSongPlaylistFromOtherUsersAndAddToQueue : function(songSeed, playFirstInQueue){
var generatedPlaylist =  PlaylistHelper.getInstance().generatePlaylistOfSongsBasedOnOtherUsers(songSeed);
return PersistentModels.getInstance().getPlayer().setPlaylist(generatedPlaylist,playFirstInQueue);
},

playRecentFromAll : function(){
var generatedPlaylist =  PlaylistHelper.getInstance().generatePlaylistOfSongsRecentlyPlayedByOtherUsers();
return PersistentModels.getInstance().getPlayer().setPlaylist(generatedPlaylist,true);
},

playRecentlyAddedOrFavoritedByAll : function(){
var generatedPlaylist =  PlaylistHelper.getInstance().generatePlaylistOfSongsRecentlyAddedOrFavoritedByOtherUsers();
/*return generatedPlaylist.load().then(function(){
return PersistentModels.getInstance().getPlayer().setPlaylist(generatedPlaylist);
})*/
return PersistentModels.getInstance().getPlayer().setPlaylist(generatedPlaylist, true);
},


playFromYoubube : function(songModel){
var playlistName = "";
if( songModel.get("title"))
{
playlistName = playlistName + songModel.get("title");
playlistName = playlistName + " "+ window.getI18n(ti18n, "BY") +" " + songModel.get("artist");
playlistName = playlistName + " "+window.getI18n(ti18n, "FROM_YOUTUBE") ;
}else{
playlistName =  window.getI18n(ti18n, "SONG_FROM_YOUTUBE") ;
}
var isMusicCurrentlyPlaying =  this.isMusicPlaying();
var context = Utils.getInstance().buildContextForSongCollectionUserCustom();
this.clearQueueAndSongToQueue(songModel, playlistName, context);
//if(!isMusicCurrentlyPlaying)
{
PersistentModels.getInstance().getPlayer().playFirstFromPlaylistAsYoutubeVideo();
}
},

isTrebblePlayable : function(playlistSummaryModel){
return PlaylistHelper.getInstance().isTrebblePlayable(playlistSummaryModel);
},

isTrebblePlayableWithSongsAndCapsules : function(numberOfSongs, numberOfCapsules){
return PlaylistHelper.getInstance().isTrebblePlayableWithSongsAndCapsules(numberOfSongs, numberOfCapsules);
},


getNumberOfSongsLeftToStream : function(playlist){
return PlaylistHelper.getInstance().getNumberOfSongsLeftToStream(playlist);

},

getNumberOfCapsules : function(playlist){
return  PlaylistHelper.getInstance().getNumberOfCapsules(playlist);
},

playOrLoadTrebbleByTrebbleId : function(trebbleId, loadOnly, contextType ,contextId ){
$.mobile.loading("show");
return TrebbleClientAPIHelper.getInstance().getMyTrebbleInfoByTrebbleId(trebbleId).then((function(trebbleInfoJson){
$.mobile.loading("hide");
if(trebbleInfoJson){
    var playSummaryModel = Utils.getInstance().buildPlaylistSummaryFromJson(trebbleInfoJson);
    var context = null;
    if(contextType && contextId){
        context = {"type": contextType, "id": contextId};
    }
    return this.playTrebble(playSummaryModel, null, loadOnly, context);
}
}).bind(this)).catch((function(error){
$.mobile.loading("hide");
console.error("Failed playing Trebble. Error:"+ error);
window.alertErrorMessage(window.getI18n(ti18n, "FAILED_PLAYING_TREBBLE"));
}).bind(this));

},

playTrebble : function(playlistSummarySelectedModel, tuneOptions, loadOnly, context){
if(PlaylistHelper.getInstance().isTrebblePlayable(playlistSummarySelectedModel)){

if(!loadOnly){
    $.mobile.loading( 'show', {
        text: window.getI18n(ti18n, "LOADING_RADIO"),
        textVisible: false,
        theme: "t",
        textonly: false,
        // html: html
    });
}
var playlistFound = null;
return PlaylistHelper.getInstance().getPersonalUserRadioPlaylistById(playlistSummarySelectedModel).then((function(playlist){
    playlistFound = playlist;
    if(tuneOptions && playlistFound.tune){
        playlistFound.tune(tuneOptions);
    }
    if(context && playlistFound.updateContext){
        playlistFound.updateContext(context);
    }
    return PersistentModels.getInstance().getPlayer().setPlaylist(playlist, !loadOnly);
}).bind(this)).then((function(){
    if(!loadOnly){
        $.mobile.loading( 'hide');
    }
    /*if(playlistFound && !loadOnly){
        var loaderKey = playlistFound.getLoaderKey();
        var name = playlistFound.getName();
        var context = Utils.getInstance().buildContextForFollowingRadioDetail(loaderKey);
        TrebbleClientAPIHelper.getInstance().addTebbleLoadActionToMyHistory(loaderKey,name ,new Date(), null,context, null);
    }*/
}).bind(this)).catch((function(error){
    if(!loadOnly){
        $.mobile.loading( 'hide');
    }

    
    if(PersistentModels.getInstance().getPlayer().isNoContentToPlayError(error)){
        this._onPlaylistNotBeingAbleToPlay(playlistSummarySelectedModel);
        PersistentModels.getInstance().getPlayer().clearQueue();
    }else{
        console.error("Coudn't load playlist.Error:"+ error);
        window.alertErrorMessage(window.getI18n(ti18n, "COULDNT_LOAD_PLAYLIST_ERROR")+ error);
    }
}).bind(this));
}else{
return this._onPlaylistNotBeingAbleToPlay(playlistSummarySelectedModel);
}
},

_onPlaylistNotBeingAbleToPlay : function(playlistSummarySelectedModel){
var minimumOfSongsNeeded  =  Utils.getInstance().getDefaultNumberOfSongsRequiredToPlayATrebble();
if(playlistSummarySelectedModel.canBeEdited()){
//var buttonLabels = ["Play Demo Trebble","Add Songs Now"];
//var buttonLabels = ["Add Music", "Create Capsule"];
var buttonLabels = [window.getI18n(ti18n, "OK"), window.getI18n(ti18n, "CREATE_CAPSULE")];
var confirmCallback = (function(buttonIndex){
    if(buttonIndex ==  2){
        setTimeout((function(){
            PersistentModels.getInstance().getRouter().showCreateACapsulePageWithoutContext(null);
        }).bind(this), 100);

    }else{

    }
}).bind(this);
//var message = "A Trebble needs to contain 1 capsule or "+ minimumOfSongsNeeded +" songs minimum to be played."
var message =  window.getI18n(ti18n, "YOUR_TREBBLE_CURRENTLY_HAS_NO_AUDIO_CONTENT");
var subMessage =  window.getI18n(ti18n, "YOU_CAN_ADD_CONTENT_BY_CREATING_CAPSULE");
navigator.trebbleNotification.confirm(message , confirmCallback, "Need To Have A Certain Minimum Of Songs To Be Played", buttonLabels, null, null, subMessage);
if(window.trebbleAnalyticsHelper){
    window.trebbleAnalyticsHelper.trackEvent("onboarding", "Discover Mininum Song Number For Trebble", "Discover Mininum Song Number For Trebble");
} 
}else{
navigator.trebbleNotification.alert( window.getI18n(ti18n, "THIS_TREBBLE_DOESNT_HAVE_ACTIVE_CAPSULE_TO_PLAY"), null, false);
}
return RSVP.Promise.resolve();
},

playSimpleCapsuleSet : function(playlistSummarySelectedModel, tuneOptions, loadOnly, context){
if(!loadOnly){
$.mobile.loading( 'show', {
    text: window.getI18n(ti18n, "LOADING_RADIO"),
    textVisible: false,
    theme: "t",
    textonly: false,
    // html: html
});
}
var playlist = PlaylistHelper.getInstance().generateCapsuleSimpleSetPlaylist(playlistSummarySelectedModel);
if(tuneOptions && playlist.tune){
playlist.tune(tuneOptions);
}
if(context && playlist.updateContext){
playlist.updateContext(context);
}
return PersistentModels.getInstance().getPlayer().setPlaylist(playlist, !loadOnly).then((function(){
if(!loadOnly){
    $.mobile.loading( 'hide');
}
}).bind(this)).catch(function(error){
if(!loadOnly){
    $.mobile.loading( 'hide');
}
console.error("Coudn't load playlist.Error:"+ error);
window.alertErrorMessage(window.getI18n(ti18n, "COULDNT_LOAD_PLAYLIST_ERROR")+ error);
});
},

playSimpleSongSet : function(playlistSummarySelectedModel, tuneOptions, loadOnly, context){
if(!loadOnly){
$.mobile.loading( 'show', {
    text: window.getI18n(ti18n, "LOADING_RADIO"),
    textVisible: false,
    theme: "t",
    textonly: false,
    // html: html
});
}
var playlist = PlaylistHelper.getInstance().generateSongSimpleSetPlaylist(playlistSummarySelectedModel);
if(tuneOptions && playlist.tune){
playlist.tune(tuneOptions);
}
if(context && playlist.updateContext){
playlist.updateContext(context);
}
return PersistentModels.getInstance().getPlayer().setPlaylist(playlist, !loadOnly).then((function(){
if(!loadOnly){
    $.mobile.loading( 'hide');
}
}).bind(this)).catch(function(error){
if(!loadOnly){
    $.mobile.loading( 'hide');
}
console.error("Coudn't load playlist.Error:"+ error);
window.alertErrorMessage(window.getI18n(ti18n, "COULDNT_LOAD_PLAYLIST_ERROR")+ error);
});
},




playFirstSongInQueue : function(){
PersistentModels.getInstance().getPlayer().playFirstFromPlaylist();
},

pauseMusicPlaying  : function(){
PersistentModels.getInstance().getPlayer().pauseAudio();
},

resumeMusicPlaying  : function(){
PersistentModels.getInstance().getPlayer().playAudio();
},

isPlaylistCurrentlyLoaded: function(playlistSummarySelectedModel){
return PersistentModels.getInstance().getPlayer().isPlaylistCurrentlyLoaded(playlistSummarySelectedModel);
},

_buildPlaylistNameFromLocalPlaylistGeneratedFromSong : function(songSeed){
var playlistName =  "";
if(songSeed)
{
playlistName  = window.getI18n(ti18n, "LOCAL_SONGS_TO") +" " + songSeed.get("title") + " "+ window.getI18n(ti18n, "FROM") +" " + songSeed.get("artist")  ;
}else{
playlistName  = window.getI18n(ti18n, "LOCAL_SONGS_SIMILAR_TO_SONG") ;
}

return playlistName;
},

isMusicPlaying : function(){
return PersistentModels.getInstance().getPlayer().isPlaying();
},

getCurrentLoadedSong : function(){
return PersistentModels.getInstance().getPlayer().getCurrentSong();
},

getPlayerQueueContext : function(){
return PersistentModels.getInstance().getPlayer().getQueueContext();
},

playDemoTrebble : function(){
this.playOrLoadTrebbleByTrebbleId("58ebe9a9227fe2000c015dc7");
if(window.trebbleAnalyticsHelper){
window.trebbleAnalyticsHelper.trackEvent("onboarding", "Play Demo Trebble", "Play Demo Trebble"); 
}
},

showShareCurrentPlayingSongPopup : function(trackAction){
return PersistentModels.getInstance().getPlayer().getCurrentSong().then((function(currentSong){
if(currentSong)
{

    var uri = currentSong.get("uri");
    if(Utils.getInstance().isCapsuleUri(uri)){
        return PersistentModels.getInstance().getPlayer().getCapsuleInfoCurrentlyPlaying().then((function(capsuleJson){
            if(capsuleJson){
                var capsuleModel = Utils.getInstance().getModelFromCapsuleJson(capsuleJson);
                PersistentModels.getInstance().getRouter().showSimpleSetCapsuleSharePopup(capsuleModel, PersistentModels.getInstance().getPlayer().getQueueContext());
                if(trackAction){
                    PersistentModels.getInstance().getPlayer().saveHistoryToServer("shareCapsulePageLaunched");
                }
                return;
            }else{
                return null;
            }
        }).bind(this));
        
    }
    if(Utils.getInstance().isJingleUri(uri) || Utils.getInstance().isGreaterUri(uri) ||  Utils.getInstance().isIntroUri(uri)  ||  Utils.getInstance().isOutroUri(uri) ||  Utils.getInstance().isEarconUri(uri) ||  Utils.getInstance().isTrebbleGeneratedSpeechUri(uri)){
        return ;
    }
    PersistentModels.getInstance().getRouter().showSimpleSetSongSharePopup(currentSong, PersistentModels.getInstance().getPlayer().getQueueContext());
    if(trackAction){
        PersistentModels.getInstance().getPlayer().saveHistoryToServer("shareSongPageLaunched");
    }
    return;

}
}).bind(this));
},



});

    var playerService = new PlayerServiceModel();

    export default {
		getInstance : function() {
			return playerService;
		}
	};