import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import CordovaHelper from "models/helper/CordovaHelper";
import SongListController2 from "controllers/common/SongListController2";
import InfoBoxController from "controllers/common/InfoBoxController";
import Utils from "models/helper/Utils";
import PlayerService from "services/PlayerService";
import ti18n from "i18n!nls/Commoni18n";
import SongSelectorListView from "views/common/SongSelectorListView";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";

const SongSelectorListController = Chaplin.Controller.extend({


    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if (options) {
            if (options.view) {
                this.view = options.view;
            }
            if (options.model) {
                this.model = options.model;
            }
        }
        this.songCollection = options.songCollection;
        this._selectedFilterOption = options.selectedFilterOption;
        this._selectedSortOption = options.selectedSortOption;
        this._onSongSelectionChanged = options.onSongSelectionChanged;
        this._customHeightMargin = options.customHeightMargin;
        this._customInfoBoxInfoParams = options.customInfoBoxInfoParams;
        this._customInfoBoxInfoEmptySearchParams = options.customInfoBoxInfoEmptySearchParams;
        this._songWithAudioPreviewOnly = options.songWithAudioPreviewOnly;
        this._songSelection = [];
        this._arrayOfSongUriSelected = [];
        this._previousSearchFieldWasEmpty = true;
        this._singleSelect = options.singleSelect ;
        this._title = options.title;
    },
    
    getModel : function(){
        return this.model;
    },
    
    
    
    getView : function(){
        return this.view;
    },
    

    
    _showGlobalSearchPage : function(){
        let searchFieldValue = null;
        if(this.getView()){
            searchFieldValue = this.getView().getSearchFieldValue();
        }
        
        PersistentModels.getInstance().getRouter().showGlobalSearchPage(searchFieldValue,true);
    },
    
    showHomePopup : function(){
        PersistentModels.getInstance().getRouter().showHomePage();
    },
    
    setSearchValue : function(searchValue){
        if(this.getView()){
            this.getView().setSearchValue(searchValue);
        }
        
    },

    getSelectedFilterId : function(){
        return this._selectedFilterOption?this._selectedFilterOption.getId(): null;
    },
    
    getSelectedSortId : function(){
        return this._selectedSortOption?this._selectedSortOption.getId(): null;
    },
    
    onKeyUpInSearchFieldHandler : function(searchFieldValue){
        this.songsListController.filterList(searchFieldValue, this.getSelectedFilterId(), this.getSelectedSortId());
        this._updateInfoBoxMessageIfApplicable();
    },
    
    onSearchFieldClearedHandler : function(){
        this.onKeyUpInSearchFieldHandler("");
    },
    
    onSongSelectionChange : function(newSelection){
        if(this._singleSelect){
            const isSongSelectedAlreadySelected = this._arrayOfSongUriSelected.indexOf(newSelection.get("uri")) != -1;
            if(this._arrayOfSongUriSelected.length  > 0 ){
                const songModelDeselect = this._songSelection[0];
                this._songSelection.splice(0,1);
                this._arrayOfSongUriSelected.splice(0,1);
                if(this._onSongSelectionChanged){
                    this._onSongSelectionChanged(null);
                }
                if(songModelDeselect && songModelDeselect.trigger){
                    songModelDeselect.trigger("change", songModelDeselect);
                }
            }
            if(!isSongSelectedAlreadySelected){
                this._songSelection.push(newSelection);
                this._arrayOfSongUriSelected.push(newSelection.get("uri"));
                if(this._onSongSelectionChanged){
                    this._onSongSelectionChanged(newSelection);
                }
            }
        }else{
            if(this._arrayOfSongUriSelected.length  > 0 && this._arrayOfSongUriSelected.indexOf(newSelection.get("uri")) > -1){
                const songToDeselectIndex =  this._arrayOfSongUriSelected.indexOf(newSelection.get("uri"));
                this._songSelection.splice(songToDeselectIndex, 1);
                this._arrayOfSongUriSelected.splice(songToDeselectIndex, 1);
                if(this._onSongSelectionChanged){
                    this._onSongSelectionChanged(this._songSelection);
                }
            }else{
                this._songSelection.push(newSelection);
                this._arrayOfSongUriSelected.push(newSelection.get("uri"));
                if(this._onSongSelectionChanged){
                    this._onSongSelectionChanged(this._songSelection);
                }
            }
        }
        if(newSelection && newSelection.trigger){
            newSelection.trigger("change", newSelection);
        }
    },
    
    getSongSelection : function(){
        return this._songSelection;
    },
    
    onSongActionButtonTapped: function(songModelTapped){
        this.onSongSelectionChange(songModelTapped);
    },
    onItemTapped: function(songModelTapped){
        if(!PlayerService.getInstance().isMusicPlaying()){
            PlayerService.getInstance().clearQueueAndSongToQueue([songModelTapped],window.getI18n(ti18n, "SONG_SELECTED"), this.context);
            PlayerService.getInstance().playFirstSongInQueue();
        }else{
            PlayerService.getInstance().getCurrentLoadedSong().then((function(songLoaded){
                if(songLoaded.get("uri") == songModelTapped.get("uri")){
                    PlayerService.getInstance().pauseMusicPlaying();
                }else{
                    PlayerService.getInstance().clearQueueAndSongToQueue([songModelTapped],window.getI18n(ti18n, "SONG_SELECTED"), this.context);
                }
            }).bind(this))
        }
        //this.onSongSelectionChange(songModelTapped);
    },
    onCapsuleIconTapped: function(songModel){
        //doNothing
    },
    
    onPostItemRenderedHandler : function(elem, templateData){
        
            const actionButtonElem = elem.querySelector("#actionButton");
            if(actionButtonElem){
                    if(this._arrayOfSongUriSelected.indexOf(templateData.model.uri) > -1){
                        if(actionButtonElem.className.indexOf("ion-ios7-checkmark-outline") == -1){
                            actionButtonElem.className = actionButtonElem.className.replace("ion-ios7-plus-outline", "");
                            actionButtonElem.className = actionButtonElem.className + " ion-ios7-checkmark-outline";
                        }
                    }else{
                        if(actionButtonElem.className.indexOf("ion-ios7-plus-outline") == -1){
                            actionButtonElem.className = actionButtonElem.className.replace("ion-ios7-checkmark-outline", "");
                            actionButtonElem.className = actionButtonElem.className + " ion-ios7-plus-outline";
                        }
                    }
            }
            
        
    },
    
    _updateInfoBoxMessageIfApplicable : function(){
        if(this.view.getSearchFieldValue() && this._previousSearchFieldWasEmpty ){
            this._previousSearchFieldWasEmpty = false;
            if(this._customInfoBoxInfoParams){
            this.songsListController.updateInfoBoxIfApplicable(this._customInfoBoxInfoParams.iconClass,
                    this._customInfoBoxInfoParams.message,
                    this._customInfoBoxInfoParams.helpMessage,
                    this._customInfoBoxInfoParams.infoBoxClassName,
                    this._customInfoBoxInfoParams.actionButtonLabel,
                    this._customInfoBoxInfoParams.actionButtonIconClass);
            }
        }else{
            this._previousSearchFieldWasEmpty = true;
            if(this._customInfoBoxInfoEmptySearchParams){
                this.songsListController.updateInfoBoxIfApplicable(this._customInfoBoxInfoEmptySearchParams.iconClass,
                        this._customInfoBoxInfoEmptySearchParams.message,
                        this._customInfoBoxInfoEmptySearchParams.helpMessage,
                        this._customInfoBoxInfoEmptySearchParamsinfoBoxClassName,
                        this._customInfoBoxInfoEmptySearchParams.actionButtonLabel,
                        this._customInfoBoxInfoEmptySearchParams.actionButtonIconClass);
            }
        }
    },

    focusOnSearchField : function(){
        if(this.view){
            this.view.focusOnSearchField();
        }
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve,reject){
            try{
                const songListControllerParams = {};
                songListControllerParams.model =  this.songCollection? this.songCollection: PersistentModels.getInstance().getSongCollection();
                songListControllerParams.showSongCoverImage = false;
                songListControllerParams.onSongActionButtonTapped = this.onSongActionButtonTapped.bind(this);
                songListControllerParams.onItemTapped = this.onItemTapped.bind(this);
                songListControllerParams.onCapsuleIconTapped = this.onCapsuleIconTapped.bind(this);
                songListControllerParams.userNewSongList = true;
                songListControllerParams.customInfoBoxInfoParams = this._customInfoBoxInfoParams;
                songListControllerParams.onPostItemRenderedHandler = this.onPostItemRenderedHandler.bind(this);
                songListControllerParams.doNotShowAddToRadioButton =true;
                songListControllerParams.doNotShowGradeSongButton = true;
                songListControllerParams.songWithAudioPreviewOnly = this._songWithAudioPreviewOnly;
                const searchFieldHeight = 53;
                const marginTop = 40;
                const marginBottom = 40;
                songListControllerParams.heightMargin = (this._customHeightMargin)?this._customHeightMargin:marginTop +marginBottom;
                songListControllerParams.actionItemButtonClass = "ion-ios7-plus-outline";
                this.songsListController = new SongListController2(songListControllerParams);
                const promiseArray = [];
                promiseArray.push(this.songsListController.buildModelAndRenderView());
                RSVP.Promise.all(promiseArray).then((function(){
                    this.songsListView = this.songsListController.getView();
                    this.songsListView.infiniteListView.$el.css("background-color", "transparent");
                    const viewParams  = {};
                    viewParams.songsListView = this.songsListView;
                    viewParams.onKeyUpInSearchFieldHandler = this.onKeyUpInSearchFieldHandler.bind(this);
                    viewParams.onSearchFieldClearedHandler = this.onSearchFieldClearedHandler.bind(this);
                    viewParams.title = this._title? this._title: "";
                    this.view =  new SongSelectorListView(viewParams);
                    this.listenTo(this.view, "selectionChange", this.onSongSelectionChange);
                    
                    this.view.render();
                    this._updateInfoBoxMessageIfApplicable();
                    resolve();
                }).bind(this)).catch(reject);
            }catch(error){
                reject(error);
            }
            
        }).bind(this));

        
    }

});

export default SongSelectorListController;