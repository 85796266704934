import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import StatisticsContentView from "views/trebbleStatistics/StatisticsContentView";
import CustomSelectFieldController from "controllers/common/CustomSelectFieldController";
import DynamicPageView from "views/common/DynamicPageView";
import ti18n from "i18n!nls/StatsPagei18n";
import PageTemplate from 'text!../../../templates/trebbleStatistics/StatisticsPageTemplate.html';
import RSVP from "rsvp";

const WARNING_MESSAGE_LOADING_CLASS = "loading";
const WARNING_MESSAGE_NO_DATA_CLASS = "nodata";
const StatisticPageController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = options.model;
        this._dataStatsId = options.dataStatsId;
        this._getStatisticsLoadFunction = options.getStatisticsLoadFunction;
        this._dataInSecondsToBeConverted = options.dataInSecondsToBeConverted;
        this._dataTypeWithBarChart = options.dataTypeWithBarChart;
        this._actionTypeToUnsupportedContext = options.actionTypeToUnsupportedContext;
        this._contextTypeToContextLabel = options.contextTypeToContextLabel;
        this._entityName = options.entityName;
        this._pageId = options.pageId;
        this._dataTypes = options.dataTypes;
        this._dataTypeToLabel = options.dataTypeToLabel;
        this._dataTypeToFontIcon = options.dataTypeToFontIcon;
        this._supportedActionContext  = options.supportedActionContext;
    },

    _onPageShow : function(){

    },



    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    _buildDurationPeriodSelectFieldController : function(){
        const controllerParams = {};
        controllerParams.singleSelect =  true;
        controllerParams.propertyFunction = "getLabel";
        controllerParams.propertyIdFunction = "getId";
        controllerParams.showEmtptyInfoBox  = false;
        controllerParams.loadHandler = StatisticsContentView.prototype.getAllSupportedTinePeriods;
        controllerParams.defaultText = window.getI18n(ti18n, "SELECT_A_TIME_PERIOD");
        return new CustomSelectFieldController(controllerParams);
    },
    _onTimePeriodChanged : function(timePeriodInfoListItemModel){
        this._currentSelectedTimePeriodModel = timePeriodInfoListItemModel
        this.statisticsContentView.setStatisticsPeriod(timePeriodInfoListItemModel?timePeriodInfoListItemModel.getId():null);
    },

    setViewLoading : function(){

        const warningMesasgeWrapper$el = this.view.$el.find(".warningMessageWrapper");
        this.view.$el.find("#statisticContent").hide();
        if(warningMesasgeWrapper$el.hasClass(WARNING_MESSAGE_NO_DATA_CLASS)){
            warningMesasgeWrapper$el.removeClass(WARNING_MESSAGE_NO_DATA_CLASS);
        }
        if(!warningMesasgeWrapper$el.hasClass(WARNING_MESSAGE_LOADING_CLASS)){
            warningMesasgeWrapper$el.addClass(WARNING_MESSAGE_LOADING_CLASS);
        }
    },

    setViewHasNoData : function(){

        const warningMesasgeWrapper$el = this.view.$el.find(".warningMessageWrapper");
        this.view.$el.find("#statisticContent").hide();
        if(warningMesasgeWrapper$el.hasClass(WARNING_MESSAGE_LOADING_CLASS)){
            warningMesasgeWrapper$el.removeClass(WARNING_MESSAGE_LOADING_CLASS);
        }
        if(!warningMesasgeWrapper$el.hasClass(WARNING_MESSAGE_NO_DATA_CLASS)){
            warningMesasgeWrapper$el.addClass(WARNING_MESSAGE_NO_DATA_CLASS);
        }
    },

    setViewHasData : function(){

        const warningMesasgeWrapper$el = this.view.$el.find(".warningMessageWrapper");
        if(warningMesasgeWrapper$el.hasClass(WARNING_MESSAGE_LOADING_CLASS)){
            warningMesasgeWrapper$el.removeClass(WARNING_MESSAGE_LOADING_CLASS);
        }
        if(warningMesasgeWrapper$el.hasClass(WARNING_MESSAGE_NO_DATA_CLASS)){
            warningMesasgeWrapper$el.removeClass(WARNING_MESSAGE_NO_DATA_CLASS);
        }
        this.view.$el.find("#statisticContent").show();
    },

    setViewFailedLoading : function(error){

        const warningMesasgeWrapper$el = this.view.$el.find(".warningMessageWrapper");
        if(warningMesasgeWrapper$el.hasClass(WARNING_MESSAGE_LOADING_CLASS)){
            warningMesasgeWrapper$el.removeClass(WARNING_MESSAGE_LOADING_CLASS);
        }
        if(warningMesasgeWrapper$el.hasClass(WARNING_MESSAGE_NO_DATA_CLASS)){
            warningMesasgeWrapper$el.removeClass(WARNING_MESSAGE_NO_DATA_CLASS);
        }
        this.view.$el.find("#statisticContent").hide();
    },

    buildModelAndRenderView : function() {
        $.mobile.loading("show");
        this.durationFieldSelectorController = this._buildDurationPeriodSelectFieldController();
        this.listenTo(this.durationFieldSelectorController, "selectionChanged",this._onTimePeriodChanged.bind(this));
        const defaultTimePeriodListItemModel = Utils.getInstance().getListItemModel(StatisticsContentView.DEFAULT_TIME_PERIOD_KEY,StatisticsContentView.DEFAULT_TIME_PERIOD_LABEL);

        return this.durationFieldSelectorController.buildModelAndRenderView().then((function(){

            const params = {};
            params.dataStatsId = this._dataStatsId;
            params.periodSelectorView = this.durationFieldSelectorController.getView();
            params.getStatisticsLoadFunction = this._getStatisticsLoadFunction;
            params.dataTypeWithBarChart = this._dataTypeWithBarChart;
            params.dataInSecondsToBeConverted = this._dataInSecondsToBeConverted;
            params.dataTypeWithBarChart = this._dataTypeWithBarChart;
            params.supportedActionContext = this._supportedActionContext;
            params.actionTypeToUnsupportedContext = this._actionTypeToUnsupportedContext;
            params.contextTypeToContextLabel = this._contextTypeToContextLabel;
            params.dataTypes = this._dataTypes;
            params.dataTypeToLabel = this._dataTypeToLabel;
            params.dataTypeToFontIcon = this._dataTypeToFontIcon;
            const statisticsContentView =  new StatisticsContentView(params);
            this.listenTo(statisticsContentView, StatisticsContentView.EVENTS.CHART_LOADING, this.setViewLoading);
            this.listenTo(statisticsContentView, StatisticsContentView.EVENTS.NO_DATA_FOR_CHART, this.setViewHasNoData);
            this.listenTo(statisticsContentView, StatisticsContentView.EVENTS.CHART_READY, this.setViewHasData);
            this.listenTo(statisticsContentView, StatisticsContentView.EVENTS.ERROR_BUILDING_CHART, this.setViewFailedLoading);
            statisticsContentView.render();
            this.statisticsContentView = statisticsContentView;
            this.durationFieldSelectorController.setSelected(defaultTimePeriodListItemModel, true);
            const pageViewParams= {};
            pageViewParams.pageId = this._pageId;
            pageViewParams.pageViewTemplate = PageTemplate;
            pageViewParams.containerSelector = ".ui-content";
            pageViewParams.contentView = statisticsContentView;
            pageViewParams.onPageShow = this._onPageShow.bind(this);
            pageViewParams.pageTemplateOptions = {"ti18n":ti18n};
            pageViewParams.ignoreDefaultPageUIContentPadding = true;

            this.view = new DynamicPageView(pageViewParams);
            this.view.render();
            this.view.$el.on("click", "#header-back-button", this.view.navigateToPreviousPage.bind(this.view));

            $.mobile.loading("hide");
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent(this._entityName, this._entityName+"Statistics", "View "+this._entityName+" Statistics");
            }

        }).bind(this)).catch(function(error){
$.mobile.loading("hide");
throw error;
});

    }

});

export default StatisticPageController;