import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import RSVP from 'rsvp';

const CustomSelectFieldView = Backbone.View.extend({



    initialize : function(options) {
        this._defaultText = options.defaultText?  options.defaultText: "None";
        this._elementId = "CustomSelectFieldView"+(new Date().getTime());	
    },
    
    events : {
        "click": "onClick",
    },

    onClick : function(){
        this.trigger("selectFieldClicked");
    },
    
    
    setSelected : function(selected, selectedElementLabel, selectedElementId){
        selectedElementLabel = !selected? null:selectedElementLabel;
        this.$el.find(".labelText").html(selectedElementLabel?selectedElementLabel : this._defaultText );
        this._selectedElementLabel = selectedElementLabel;
        this._selectedElementId = selectedElementId;
        
    },
    
    _getArrowHtml : function(){
        return "<span class='ion-chevron-down'></span>";
    },
    
    isSelected : function(){
        return this._selectedElementLabel ;
    },

    getSelectedId : function(){
        return this._selectedElementId;
    },

    getSelectedLabel : function(){
        return this._selectedElementLabel;
    },
    
    render : function() {
        this.$el.html("<div id='"+ this._elementId+"' class='CustomSelectFieldView'><div class='labelText'>"+ this._defaultText+"</div>" +this._getArrowHtml()+"</div>");
        this.setElement(this.$el.find("div").first());
        return this;
    }

});
export default CustomSelectFieldView;