import Backbone from "backbone";
import YoutubeMedia from "models/helper/YoutubeMedia";
import YoutubeBrowserMedia from "models/helper/YoutubeBrowserMedia";
import Utils from "models/helper/Utils";
import SoundcloudMedia from "models/helper/SoundCloudMedia";
import SoundManagerMedia from "models/helper/SoundManagerMedia";

//"models/helper/SoundcloudSearchHelper",
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";

import PersistentModels from "services/PersistentModels";

/*"models/helper/ImageStoreHelper",*/
import Playlist from "collections/Playlist";

import Queue from "models/queue/Queue";
import PlaylistHelper from "models/helper/PlaylistHelper";
//import MediaIOS from "models/helper/mediaIOS";
import ti18n from "i18n!nls/PlayerPagei18n";
import FeatureRolloutHelper from "models/helper/FeatureRolloutHelper";
import AdHelper from "models/helper/AdHelper";
import CordovaHelper from "models/helper/CordovaHelper";
import SoundManager from 'soundmanager2';
import soundManagerInitialazeFunction from 'soundManagerSetup';
import RSVP from "rsvp";
import $ from 'jquery';



soundManagerInitialazeFunction(SoundManager);

const MEDIA_TYPE = {
    "PHONEGAP": 0,
    "SOUNDCLOUD": 1,
    "YOUTUBE": 2,
    "IOS": 3,
    "HTML5_MEDIA": 4,
};

const PLAYER_TYPES = {
    "SIMPLE_PLAYER": 0,
    "TREBBLE_PLAYER": 1,
    "SYNC_TREBBLE_PLAYER": 2,
    "SONG_SET_PLAYER": 3,
    "CAPSULE_SET_PLAYER": 4,
    "CAPSULE_FEED_PLAYER": 5,
    "CATEGORY_SHORTCAST_RADIO": 6,
};

window._isSongPlaying = false; // GLOBAL VARIABLE TRUE IF SONG IS PLAYING
window._playerLoadedSongUri = null; // GLOBAL VARIABLE FOR URI OF SONG LOADED
window._playerLoadedSongModel;
const DISABLE_PREFETCHING = true;
const LIVE_LIKE_ACTION_COMPLETION_DELAY = 1500;
const MAX_LIVE_LIKE_VALUE_FOR_DESKTOP = 50;
let mobileQuirkExecutedForMobileVideo = false;
//const ENABLE_PLAYER_EVENT_TRACKING = false;

const Rollout = FeatureRolloutHelper.getInstance();

function NoContentToPlayError(message) {
    this.name = "NoContentToPlayError";
    this.message = (message || "");
}
NoContentToPlayError.prototype = Error.prototype;
const isMobileQuirkNeedToBeExecutedForMobileVideo = window.isMobileBrowser && window.device && window.device.platform == "browser" && !mobileQuirkExecutedForMobileVideo;
const PlayerModel = Backbone.Model.extend({
    "default": {
        "currentSong": null,
        "media": null,
        "mediaTimer": null,
        "self": this,
        "isPlaying": false,
        "shuffleMode": false,
        "loopMode": false,
        "loading": false,
        "playerType": PLAYER_TYPES.SIMPLE_PLAYER
    },

    initialize: function() {
        this.queue = new Queue();
        this.set("previewsToBeStopped", {});
        this.set("idToMediaPreviewHandlerMap", {});
        this.set("mediaIdPreviewToSuccessCB", {});
        this.set("mediaIdPreviewToFailureCB", {});
        this.set("idToMediaHandlerMap", {});
        this.set("mediaIdToSuccessCB", {});
        this.set("mediaIdToFailureCB", {});
        this.set("mediaIdToStatusCB", {});
        this.listenTo(this.queue, "playlistChanged", this.onQueuePlaylistChange);
        this.listenTo(this.queue, "playlistNameChanged", this.onQueuePlaylistNameChange);
        this.listenTo(this.queue, "refreshed", this.onQueuePlaylistRefreshed);
        this.listenTo(this.queue, "playlistReset", this.onQueuePlaylistResetted);
        this.listenTo(this.queue, "playlistStartedLoading", this._onPlaylistStartedLoading);
        this.listenTo(this.queue, "playlistFinishLoading", this._onPlaylistFinishLoading);
        this.listenTo(PersistentModels.getInstance(), "playlistSummarySubsciptionChanged", this.onPlaylistSummarySubsciptionChanged);
        this.listenTo(PersistentModels.getInstance(), "journeySummarySubsciptionChanged", this.onPlaylistSummarySubsciptionChanged);
        this._defaultAlbumArtUrl = null;
        this._currentSongLiveLikeCounter = 0;
        this._timerTracking = {};
        this._arrayOfAudioURIPrefretched = [];
        this.executedAudioHTML5Quirk();
        this._setupEventHandlerForClickOnPreviewPlayingButton();
        this._setupOnAudioInterruptionListenerIfApplicable();
    },

    _setupEventHandlerForClickOnPreviewPlayingButton: function() {
        $("body #previewPlayingButton").on("click", this.stopAudioPreviewAndRelease.bind(this));
    },

    executedAudioHTML5Quirk: function() {
        if ( /*(device.platform == "iOS" || window.isMobileBrowser )*/ this.isAudioHTML5QuirkRequired() && !window.trebble.config.iosAudioQuirkExcetuded && !window._audioHTML5QuirkFunction) {
            //Always execute this as autoplay is not guraranteed
            window._audioHTML5QuirkFunction = this._playSilentMp3FromAssets;
            document.body.addEventListener("click", window._audioHTML5QuirkFunction, true);
        }
    },

    _setupOnAudioInterruptionListenerIfApplicable: function(){
        if("AudioInterruption" in window){
            try{
                window.AudioInterruption.addListener((function(status) {
                    switch (status) {
                        case 'INTERRUPTION_BEGIN':
                        if (this.isPlaying()) {
                            this._playingWasInterrupted = true;
                            this.pauseAudio(true);
                            this.pauseAudio(true);
                            this._applyIOSQuirkForHTML5AudioIfApplicable();
                        } 
                        break;
                        case 'INTERRUPTION_ENDED':
                        if (this._playingWasInterrupted) {
                          this._playingWasInterrupted = false;
                          this.playAudio();
                      }
                      break;
                  }
              }).bind(this));
            }catch(error){
                console.error("Setup of audio interruption listener failed. Error:"+error, error);
            }
        }
    },

    isAudioHTML5QuirkRequired: function() {
        //return window.isMobileBrowser;
        //Always execute this as autoplay is not guraranteed
        
        return true && !window.isCordovaNativeApp();
    },

    isAudioHTML5QuirkExecuted: function() {
        return window.trebble.config.iosAudioQuirkExcetuded;
    },

    _playSilentMp3FromAssets: function() {
        try {
            SoundManager.createSound({
                id: 'trebbleSilentAudio',
                url: 'https://web.trebble.fm/audioassets/quartersec.mp3'
            });
            SoundManager.play('trebbleSilentAudio');
            window.trebble.config.iosAudioQuirkExcetuded = true;
            document.body.removeEventListener("click", window._audioHTML5QuirkFunction, true);
            window.log("quirk for HTML5 audion in mobile browser was successfully executed");
        } catch (e) {
            console.error("Failed executing quirk for HTML5 audion in mobile browser" + e);
        }
    },


    setDefaultAlbumArtUrl: function(defaultAlbumArtUrl) {
        this._defaultAlbumArtUrl = defaultAlbumArtUrl;
    },

    getDefaultAlbumArtUrl: function() {
        return this._defaultAlbumArtUrl;
    },
    getPlayerType: function() {
        return this.get("playerType");
    },

    _setPlayerType: function(newPlayerType) {
        if (newPlayerType == PLAYER_TYPES.TREBBLE_PLAYER || newPlayerType == PLAYER_TYPES.CAPSULE_FEED_PLAYER || newPlayerType == PLAYER_TYPES.CATEGORY_SHORTCAST_RADIO || newPlayerType == PLAYER_TYPES.CAPSULE_SET_PLAYER || newPlayerType == PLAYER_TYPES.SONG_SET_PLAYER) {
            this.setLoopMode(false);
            this.setShuffleMode(false);
        }
        const oldPlayerType = this.getPlayerType();
        this.set("playerType", newPlayerType);
        if (oldPlayerType != newPlayerType) {
            this.trigger("playerTypeChanged", newPlayerType);
        }
    },

    /*onSuccess: function(mediaRef) {
        window.log("Player Status. Successfully finish playing song ");
        if(!mediaRef && arguments.callee.caller && arguments.callee.caller == window.Media.onStatus && arguments.callee.caller.arguments && arguments.callee.caller.arguments.length > 0){
            mediaRef = window.Media.get(arguments.callee.caller.arguments[0])
        }
        if ((!mediaRef || mediaRef == this.getMedia()) && this.getMedia()) {
            this.getMedia().release();
            //document.getElementById("albumArtImage").style.display = "block";

            this.set("previousMediaType", this.get("mediaType"));

            this.set("media", null);
            this.setMediaStatus(-1);
            if (!this.get("isManuallyStopping") && ((window.device && window.device.platform == "browser") || this.isPlaying())) {
                if( !this.get("playHistorySaved")){
                    this.saveHistoryToServer("play");
                }
                return this.saveHistoryToServer("finishPlaying").then((function() {
                    return this.playNextSongInPlaylist();
                }).bind(this));
            }
        } else {
            //window.alertErrorMessage("oops too late");
            //Audio is not the one currently playing but attempt to release it regardless
            if(mediaRef){
                mediaRef.release();
            }
        }
        return RSVP.Promise.resolve();
    },*/

    onSuccessNew: function() {
        window.log("Player Status. Successfully finish playing song ");
        const mediaRef = this.self.getMediaByMediaId(this.mediaId);
        if ((!mediaRef || mediaRef == this.self.getMedia()) && this.self.getMedia()) {
            this.self.getMedia().release();
            //document.getElementById("albumArtImage").style.display = "block";

            this.self.set("previousMediaType", this.self.get("mediaType"));

            this.self.set("media", null);
            this.self.setMediaStatus(-1);
            if (!this.self.get("isManuallyStopping") && ((window.device && window.device.platform == "browser") || this.self.isPlaying())) {
                if( !this.self.get("playHistorySaved")){
                    this.self.saveHistoryToServer("play");
                }
                return this.self.saveHistoryToServer("finishPlaying").then((function() {
                    return this.self.playNextSongInPlaylist();
                }).bind(this));
            }
        } else {
            //window.alertErrorMessage("oops too late");
            //Audio is not the one currently playing but attempt to release it regardless
            if(mediaRef){
                mediaRef.release();
            }
        }
        this.self._unregisterMediaCallbacks(this.mediaId);
        return RSVP.Promise.resolve();
    },

    isPreviousMediaTypeYoutubeVideo: function() {
        return this.get("previousMediaType") == MEDIA_TYPE.YOUTUBE;
    },

    isCurrentMediaTypeYoutubeVideo: function() {
        return this.get("mediaType") == MEDIA_TYPE.YOUTUBE;
    },

    isCurrentMediaTypeSoundcloud: function() {
        return this.get("mediaType") == MEDIA_TYPE.SOUNDCLOUD;
    },

    getMediaType: function() {
        return this.get("mediaType");
    },

    getMediaByMediaId: function(mediaId) {
        let idToMediaHandlerMap = this.get("idToMediaHandlerMap");
        if (!idToMediaHandlerMap) {
            idToMediaHandlerMap = {};
            this.set("idToMediaHandlerMap", idToMediaHandlerMap);
        }
        return idToMediaHandlerMap[mediaId];
    },

    setMediaByMediaId: function(mediaId, media) {
        let idToMediaHandlerMap = this.get("idToMediaHandlerMap");
        if (!idToMediaHandlerMap) {
            idToMediaHandlerMap = {};
            this.set("idToMediaHandlerMap", idToMediaHandlerMap);
        }
        idToMediaHandlerMap[mediaId] = media;
    },


    getMediaPreviewByMediaId: function(mediaId) {
        let idToMediaPreviewHandlerMap = this.get("idToMediaPreviewHandlerMap");
        if (!idToMediaPreviewHandlerMap) {
            idToMediaPreviewHandlerMap = {};
            this.set("idToMediaPreviewHandlerMap", idToMediaPreviewHandlerMap);
        }
        window.log("number of media previews ::" + idToMediaPreviewHandlerMap.ii);
        return idToMediaPreviewHandlerMap[mediaId];
    },

    setMediaPreviewByMediaId: function(mediaId, mediaPreview) {
        let idToMediaPreviewHandlerMap = this.get("idToMediaPreviewHandlerMap");
        if (!idToMediaPreviewHandlerMap) {
            idToMediaPreviewHandlerMap = {};
            this.set("idToMediaPreviewHandlerMap", idToMediaPreviewHandlerMap);
        }
        idToMediaPreviewHandlerMap[mediaId] = mediaPreview;
        idToMediaPreviewHandlerMap.ii = !idToMediaPreviewHandlerMap.ii ? 1 : idToMediaPreviewHandlerMap.ii + 1;
        window.log("number of media previews" + idToMediaPreviewHandlerMap.ii);
    },

    _registerMediaPreviewCallbacks: function(mediaId, successCB, failureCB) {
        const mediaIdPreviewToSuccessCB = this.get("mediaIdPreviewToSuccessCB");
        const mediaIdPreviewToFailureCB = this.get("mediaIdPreviewToFailureCB");
        if (successCB) {
            mediaIdPreviewToSuccessCB[mediaId] = successCB;
        }
        if (failureCB) {
            mediaIdPreviewToFailureCB[mediaId] = failureCB;
        }
    },

    _registerMediaCallbacks: function(mediaId, successCB, failureCB, statusCB) {
        const mediaIdToSuccessCB = this.get("mediaIdToSuccessCB");
        const mediaIdToFailureCB = this.get("mediaIdToFailureCB");
        const mediaIdToStatusCB = this.get("mediaIdToStatusCB");
        if (successCB) {
            mediaIdToSuccessCB[mediaId] = successCB;
        }
        if (failureCB) {
            mediaIdToFailureCB[mediaId] = failureCB;
        }
        if (statusCB) {
            mediaIdToStatusCB[mediaId] = failureCB;
        }
    },

    _unregisterMediaCallbacks : function(mediaId){
        const mediaIdToSuccessCB = this.get("mediaIdToSuccessCB");
        const mediaIdToFailureCB = this.get("mediaIdToFailureCB");
        const mediaIdToStatusCB = this.get("mediaIdToStatusCB");
        delete mediaIdToSuccessCB[mediaId];
        delete mediaIdToFailureCB[mediaId];
        delete mediaIdToStatusCB[mediaId];
    },

    _notifyListenersForPreviewAndClear: function(mediaId, success, error) {
        const mediaIdPreviewToSuccessCB = this.get("mediaIdPreviewToSuccessCB");
        const mediaIdPreviewToFailureCB = this.get("mediaIdPreviewToFailureCB");
        if (success && mediaIdPreviewToSuccessCB[mediaId]) {
            mediaIdPreviewToSuccessCB[mediaId]();

        }

        if (!success && mediaIdPreviewToFailureCB[mediaId]) {
            mediaIdPreviewToFailureCB[mediaId](error);

        }
        delete mediaIdPreviewToSuccessCB[mediaId];
        delete mediaIdPreviewToFailureCB[mediaId];
    },


    getRadioIdPlayingIfApplicable : function(){
        if (this.getPlayerType() == PLAYER_TYPES.CAPSULE_FEED_PLAYER || this.getPlayerType() == PLAYER_TYPES.CATEGORY_SHORTCAST_RADIO)
        {
            if(this.getPlaylist() && this.getPlaylist().getRadioIdForSongWitUri){
                return  this.getCurrentSong().then((function(songLoaded){ 
                    const radioId = songLoaded? this.getPlaylist().getRadioIdForSongWitUri(songLoaded.get("uri")): null 
                    return radioId;
                }).bind(this));
            }else{
                return RSVP.Promise.resolve(null);
            }
        }

        if (this.getPlayerType() == PLAYER_TYPES.TREBBLE_PLAYER)
        {
            if(this.getPlaylist() && this.getPlaylist().getLoaderKey()){
                return RSVP.Promise.resolve(this.getPlaylist().getLoaderKey());
            }
        }
        return RSVP.Promise.resolve(null);
    },

    subscribe: function() {
        let radioId = null;
        radioId = (this.getPlaylist() && this.getPlaylist().canBeFollowed())?this.getPlaylist().getLoaderKey() : null;
        let retrieveRadioIdPromise = null;
        if(!radioId && this.getPlaylist() && this.getPlaylist().getRadioIdForSongWitUri){
            retrieveRadioIdPromise = this.getCurrentSong().then((function(songLoaded){ 
                radioId = songLoaded? this.getPlaylist().getRadioIdForSongWitUri(songLoaded.get("uri")): null 
            }).bind(this));
        }else{
            retrieveRadioIdPromise = RSVP.Promise.resolve();
        }
        return retrieveRadioIdPromise.then((function(){
            if (radioId ) {
                const onTrebbleSubscriptionSucessHandler = (function() {

                }).bind(this);
                const onTrebbleSubscriptionFailureHandler = (function(error) {
                    console.error(error);
                }).bind(this);
                return PersistentModels.getInstance().getRouter().showSubscribeToTrebblePopupIfApplicable(radioId, onTrebbleSubscriptionSucessHandler, onTrebbleSubscriptionFailureHandler);
            } else {
                return RSVP.Promise.resolve();
            }
        }).bind(this))

    },

    unsubscribe: function(radioId) {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                const buttonLabels = [window.getI18n(ti18n, "NO"), window.getI18n(ti18n, "YES")];
                const confirmCallback = (function(buttonIndex) {
                    if (buttonIndex == 2) {
                        return this.unsubscribeConfirmed(radioId).then(resolve).catch(reject);
                    } else {
                        resolve();
                    }
                }).bind(this);
                const message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_UNSUBSCRIBE_FROM") + " \"" + this.getPlaylist().getName() + "\" ?"
                navigator.trebbleNotification.confirm(message, confirmCallback, window.getI18n(ti18n, "UNSUBSCRIBE_FROM_TREBBLE"), buttonLabels, null, null);
            } catch (error) {
                reject(error);
            }
        }).bind(this));

    },


    unsubscribeConfirmed: function() {
        let radioId = null;
       radioId = (this.getPlaylist() && this.getPlaylist().canBeFollowed())?this.getPlaylist().getLoaderKey() : null;
       let retrieveRadioIdPromise = null;
       if(!radioId && this.getPlaylist() && this.getPlaylist().getRadioIdForSongWitUri){
        retrieveRadioIdPromise = this.getCurrentSong().then((function(songLoaded){ 
            radioId = songLoaded? this.getPlaylist().getRadioIdForSongWitUri(songLoaded.get("uri")): null 
        }).bind(this));
    }else{
        retrieveRadioIdPromise = RSVP.Promise.resolve();
    }
    return retrieveRadioIdPromise.then((function(){
        if (radioId ) {
          const onTrebbleUnSubscriptionSucessHandler = (function() {

          }).bind(this);
          const onTrebbleUnSubscriptionFailureHandler = (function(error) {
            console.error(error);
          }).bind(this);

          return PersistentModels.getInstance().getRouter().showUnsubscribeToTrebblePopupIfApplicable(radioId, onTrebbleUnSubscriptionSucessHandler, onTrebbleUnSubscriptionFailureHandler);
      } else {
        return RSVP.Promise.resolve();
    }
}).bind(this));

},

onPlaylistSummarySubsciptionChanged: function(playlistSummaryModel, subscribed) {
    if (this.getPlaylist() && playlistSummaryModel && this.getPlaylist().getLoaderKey() === playlistSummaryModel.getLoaderKey()) {
        this.getPlaylist().setFollowing(subscribed);
        this.trigger("queuePlaylistChanged", this.getPlaylist());
    }
    this.getCurrentSong().then((function(currentSong){
        if(currentSong){
            const songUri = currentSong.get("uri");
            this._updateSubscriptionInfoIfApplicable(songUri);
        }
    }).bind(this))

},


clearMediaPreviewByMediaId: function(mediaId) {
    let idToMediaPreviewHandlerMap = this.get("idToMediaPreviewHandlerMap");
    if (!idToMediaPreviewHandlerMap) {
        idToMediaPreviewHandlerMap = {};
        this.set("idToMediaPreviewHandlerMap", idToMediaPreviewHandlerMap);
    }
    idToMediaPreviewHandlerMap[mediaId] = null;
    delete idToMediaPreviewHandlerMap[mediaId];
    idToMediaPreviewHandlerMap.ii = idToMediaPreviewHandlerMap.ii + 1;
    window.log("number of media previews" + idToMediaPreviewHandlerMap.ii);
    return
},
onPreviewSuccess: function() {
    const subjectMedia = this.self.getMediaPreviewByMediaId(this.mediaId);
    const isSubjectPreviewCurrentLoaded = subjectMedia == this.self.getMediaPreview();
    window.log("Player Status. Successfully finish playing song preview ");
    if (subjectMedia) {
        this.self.clearMediaPreviewByMediaId(this.mediaId);
        this.self._notifyListenersForPreviewAndClear(this.mediaId, true);
        subjectMedia.release();
        //document.getElementById("albumArtImage").style.display = "block";
        if (isSubjectPreviewCurrentLoaded) {
            this.self.set("mediaPreview", null);
            this.self.setMediaPreviewStatus(-1);
            if (!this.self.get("isManuallyStoppingPreview") && this.self.isPreviewPlaying()) {
                this.self._setPreviewPlaying(false);
                this.self.trigger("songPreviewUnLoaded");
                try {
                    //this.saveHistoryToServer("finishPlayingPreview");
                } catch (error) {
                    console.error("Error saving player history to server for action 'finishPlayingPreview'. Error:" + error);
                }
                return this.self.resumePlayAfterPreview();
            }else{
                this.self.trigger("songPreviewUnLoaded");
            }
        }
    }
    return RSVP.Promise.resolve();
},

resumePlayAfterPreview: function() {
    if (this.get("shouldSongBeResumeAfterPreviewEnd")) {
        this.set("shouldSongBeResumeAfterPreviewEnd", null);
        this.playAudio();
    }
},

onFailedLoadingUri: function(uri) {
    console.error("Failed loading uri. ");
    const tryToLoadSongAgain = true;
    if (window.trebbleAnalyticsHelper) {
        window.trebbleAnalyticsHelper.trackEvent('Music Playback Failure', 'Song Failed Playing', 'Song Failed Playing. Broken Link', null, {"audioUriWithFailure": uri});
    }
    this.trigger("failedLoading", this._loadedSong, tryToLoadSongAgain);
    this.stopAudioAndRelease(true);
    if (this.getPlaylist()) {
        let tryToReplayTheSongAgain = true;
        return this.getQueue().getSongOnCurrentCursorPositionPromise().then((function(songAtCurentCursorPosition) {
            if (!uri || uri == songAtCurentCursorPosition.get("uri")) {
                return this._loadSong(songAtCurentCursorPosition, false, true);
            } else {
                //THIS COULD HAPPEN IF THE USER IS QUICKLY SWITCHING BETWEEN SONGS
                tryToReplayTheSongAgain = false;
            }
        }).bind(this)).then((function() {
            if (tryToReplayTheSongAgain) {
                return this.playAudio(true);
            }
        }).bind(this)).catch(function(err) {
            console.error(err);
            window.alertErrorMessage(window.getI18n(ti18n, "FAILED_TO_REPLAY_AUDIO"));
        });
    } else {
        return RSVP.Promise.resolve();
    }
},

/*onStatus: function(mediaStatus, mediaRef) {
    if(!mediaRef && arguments.callee.caller && arguments.callee.caller == window.Media.onStatus && arguments.callee.caller.arguments && arguments.callee.caller.arguments.length > 0){
        mediaRef = window.Media.get(arguments.callee.caller.arguments[0])
    }
    if (!mediaRef || mediaRef == this.getMedia()) {
        this.setMediaStatus(mediaStatus);
        window.log("playAudio999():Audio ddddddddddSuccess .Status " + mediaStatus);
        if (mediaStatus == 4) {
            this.onSongStopped();
            //TrebblePlayer.getMedia().release();
        } else {
            if (mediaStatus == 2) {
                if (this.get("mediaType") == MEDIA_TYPE.PHONEGAP) {
                 if (mediaRef) {
                    this._onSongFinishLoading(mediaRef._songModel);
                } else {
                    this.getCurrentSong().then((function(currentSong) {
                        this._onSongFinishLoading(currentSong);
                    }).bind(this));
                }
            }
            //if(mediaRef && mediaRef instanceof window.Media){
              //  this._onSongDurationChanged(mediaRef.getDuration());
           // }
            if (mediaRef) {
                this.onSongStartedPlaying(mediaRef._songModel);
            } else {
                this.getCurrentSong().then((function(currentSong) {
                    this.onSongStartedPlaying(currentSong);
                }).bind(this));
            }
        } else {
            if (mediaStatus == 3) {
                this.onSongPaused();
            } else {
                if (mediaStatus == 1) {
                    if (window.waitForCordovaToLoad) {
                        //for native iOS and android
                        if (mediaRef) {
                            this._onSongLoading(mediaRef._songModel);
                        } else {
                            this.getCurrentSong().then((function(currentSong) {
                                this._onSongLoading(currentSong);
                            }).bind(this));
                        }
                    }

                }
            }
        }
    }
} else {
    //window.alertErrorMessage("oops too late");
}
},*/

onStatusNew: function(mediaStatus) {
    const mediaRef = this.self.getMediaByMediaId(this.mediaId);
    if (!mediaRef || mediaRef == this.self.getMedia()) {
        this.self.setMediaStatus(mediaStatus);
        window.log("playAudio999():Audio ddddddddddSuccess .Status " + mediaStatus);
        if (mediaStatus == 4) {
            this.self.onSongStopped();
            //TrebblePlayer.getMedia().release();
        } else {
            if (mediaStatus == 2) {
                if (this.self.get("mediaType") == MEDIA_TYPE.PHONEGAP) {
                 if (mediaRef) {
                    this.self._onSongFinishLoading(mediaRef._songModel);
                } else {
                    this.self.getCurrentSong().then((function(currentSong) {
                        this.self._onSongFinishLoading(currentSong);
                    }).bind(this));
                }
            }
            /*if(mediaRef && mediaRef instanceof window.Media){
                this.self._onSongDurationChanged(mediaRef.getDuration());
            }*/
            if (mediaRef) {
                this.self.onSongStartedPlaying(mediaRef._songModel);
            } else {
                this.self.getCurrentSong().then((function(currentSong) {
                    this.self.onSongStartedPlaying(currentSong);
                }).bind(this));
            }
        } else {
            if (mediaStatus == 3) {
                this.self.onSongPaused();
            } else {
                if (mediaStatus == 1) {
                    if (window.waitForCordovaToLoad) {
                        //for native iOS and android
                        if (mediaRef) {
                            this.self._onSongLoading(mediaRef._songModel);
                        } else {
                            this.self.getCurrentSong().then((function(currentSong) {
                                this.self._onSongLoading(currentSong);
                            }).bind(this));
                        }
                    }

                }
            }
        }
    }
} else {
    //window.alertErrorMessage("oops too late");
}
},

openExternalSongPage: function() {
if (this.getMedia()) {
    if (this.isCurrentMediaTypeSoundcloud()) {
        let soundcloudSongPageUrl = this.getMedia()._externalPageUrl;
        let win = window.open(soundcloudSongPageUrl, '_blank');
        if ((!win || win.closed || typeof win.closed == 'undefined') && !window.waitForCordovaToLoad) {
            window.alertErrorMessage(window.getI18n(ti18n, "PLEASE_ALLOW_POPUPS_FOR_TREBBLE"));
        } else {
            if (win.focus) {
                win.focus();
            }
        }
        this.saveHistoryToServer("viewExternalMediaSourcePage", null, null, null, soundcloudSongPageUrl);
        this.pauseAudio();
    } else {
        if (this.isCurrentMediaTypeYoutubeVideo() && this.getMedia()) {
            let youtubeVideoId = this.getMedia()._videoId;
            let youtubeVideoUrl = "https://www.youtube.com/watch?v=" + youtubeVideoId;
            let win = window.open(youtubeVideoUrl, '_blank');
            if ((!win || win.closed || typeof win.closed == 'undefined') && !window.waitForCordovaToLoad) {
                window.alertErrorMessage(window.getI18n(ti18n, "PLEASE_ALLOW_POPUPS_FOR_TREBBLE"));
            } else {
                if (win.focus) {
                    win.focus();
                }
            }
            this.saveHistoryToServer("viewExternalMediaSourcePage", null, null, null, youtubeVideoUrl);
            this.pauseAudio();
        } else {
            //window.alertErrorMessage("The song page could't be found!");
        }
    }
} else {
    //window.alertErrorMessage("The song page could't be found!");
}


},

onPreviewStatus: function(mediaStatus) {
const subjectMedia = this.self.getMediaPreviewByMediaId(this.mediaId);
const isSubjectPreviewCurrentLoaded = subjectMedia == this.self.getMediaPreview();
if (isSubjectPreviewCurrentLoaded) {
    this.self.setMediaPreviewStatus(mediaStatus);
}
window.log("Preview status " + mediaStatus);
if (mediaStatus == 4) {
    if (isSubjectPreviewCurrentLoaded) {
        this.self.onSongPreviewStopped();
    }
    //TrebblePlayer.getMedia().release();
} else {
    if (mediaStatus == 2) {
        if (isSubjectPreviewCurrentLoaded) {
          this.self.onSongPreviewStartedPlaying();
      }
  } else {
    if (mediaStatus == 3) {
        if (isSubjectPreviewCurrentLoaded) {
            this.self.onSongPreviewPaused();
        }
    } else {
        //state 1
    }
}
}
if (this.self._isPreviewNeedToBeStopped(subjectMedia)) {
window.log("Current preview is about to stopped from playing");
this.self._removePreviewToListOfPreviewsToBeStopped(subjectMedia);
if (subjectMedia) {
    if (subjectMedia instanceof window.Media || subjectMedia.release) {
        subjectMedia.stop();
        subjectMedia.release();

    } else {
        subjectMedia.stop();
    }
    if (isSubjectPreviewCurrentLoaded) {
        this.self.set("isPreviewPlaying", false);
        this.self.set("mediaPreview", null);
        this.self.trigger("songPreviewUnLoaded");
    }
    this.self.clearMediaPreviewByMediaId(this.mediaId);
    this.self._notifyListenersForPreviewAndClear(this.mediaId, true);
}
}

},



/*onError: function(error, mediaRef) {
if(!mediaRef && arguments.callee.caller && arguments.callee.caller == window.Media.onStatus && arguments.callee.caller.arguments && arguments.callee.caller.arguments.length > 0){
mediaRef = window.Media.get(arguments.callee.caller.arguments[0])
}
if (mediaRef && mediaRef.onFailedLoading) {
mediaRef.onFailedLoading();
} else {
const audioUriOfTheAudioWithFailurePlayback = this._loadedSong ? Utils.getInstance().getSuggestedUri(this._loadedSong) : null;
const audioTitle = this._loadedSong ? this._loadedSong.get("title") : null;
const audioCreator = this._loadedSong ? this._loadedSong.get("artist") : null;
const localizedErrorMessage = window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_PLAYING_AUDIO");
if (audioTitle) {
localizedErrorMessage = window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_PLAYING") + " \"" + audioTitle + "\"";
}
if (window.trebbleAnalyticsHelper) {
const additionalDataToSendThroughAnalytics = null;
if (audioUriOfTheAudioWithFailurePlayback || audioTitle || audioCreator) {
    additionalDataToSendThroughAnalytics = {
        "audioUriWithFailure": audioUriOfTheAudioWithFailurePlayback,
        "audioTitle": audioTitle,
        "audioCreator": audioCreator,
        "playbackErrorMessage": Utils.getInstance().getErrorMessageFromObject(error)
    };
}
window.trebbleAnalyticsHelper.trackEvent('Audio Playback Failure', ' Audio Playback Error', 'Audio Playback Failed', null, additionalDataToSendThroughAnalytics);
}
console.error("Failed playing the current song!");
try {
window.sendErrorToRaygun("Failed playing audio. Error:" + Utils.getInstance().getString(error));
} catch (err) {
console.log(err);
}
const currentMedia = this.getMedia();
if (!currentMedia.isUsedAsFallback) {
console.error("Try to play the audio using the fallback media object which is SoundManager");
this.stopAudioAndRelease(true, true);
if (Rollout.isFeatureEnabled(Rollout.FEATURES.FALLBACK_TO_WEB_AUDIO_IF_CORDOVA_MEDIA_PLAYBACK_FAILS)) {
    this.playAudio(true, true);
    return;
}

}
this.trigger("error", localizedErrorMessage, this._loadedSong);
if (this.get("mediaType") == MEDIA_TYPE.PHONEGAP) {
this._onSongFailedLoading(localizedErrorMessage);

}
if (window.waitForCordovaToLoad && Utils.getInstance().isInterntetConnectionIsAvailable() && Rollout.isFeatureEnabled(Rollout.FEATURES.AUTOMATICALLY_SKIP_TO_THE_NEXT_AUDIO_PLAYBACK_FAILS_ON_MOBILE)) {
this.playNextSongInPlaylist(null, "AUDIO_PLAYBACK_ERROR_FAILURE")
} else {
this.pauseAudio(true, true);
}
return;


}
},*/

onErrorNew: function (error) {
    const mediaRef = this.self.getMediaByMediaId(this.mediaId);
    if (mediaRef && mediaRef.onFailedLoading) {
      mediaRef.onFailedLoading();
    } else {
      const audioUriOfTheAudioWithFailurePlayback = this.self._loadedSong? Utils.getInstance().getSuggestedUri(this.self._loadedSong): null;
      const audioTitle = this.self._loadedSong ? this.self._loadedSong.get("title") : null;
      const audioCreator = this.self._loadedSong? this.self._loadedSong.get("artist"): null;
      let localizedErrorMessage = window.getI18n(ti18n,"SOMETHING_WENT_WRONG_WHILE_PLAYING_AUDIO");
      if (audioTitle) {
        localizedErrorMessage = window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_PLAYING") +' "' +audioTitle +'"';
      }
      if (window.trebbleAnalyticsHelper) {
        let additionalDataToSendThroughAnalytics = null;
        if (
          audioUriOfTheAudioWithFailurePlayback ||
          audioTitle ||
          audioCreator
        ) {
          additionalDataToSendThroughAnalytics = {
            audioUriWithFailure: audioUriOfTheAudioWithFailurePlayback,
            audioTitle: audioTitle,
            audioCreator: audioCreator,
            playbackErrorMessage:
              Utils.getInstance().getErrorMessageFromObject(error),
          };
        }
        window.trebbleAnalyticsHelper.trackEvent(
          "Audio Playback Failure",
          " Audio Playback Error",
          "Audio Playback Failed",
          null,
          additionalDataToSendThroughAnalytics
        );
      }
      console.error("Failed playing the current song!");
      try {
        window.sendErrorToRaygun(
          "Failed playing audio. Error:" + Utils.getInstance().getString(error)
        );
      } catch (err) {
        console.error(err);
      }
      this.self._unregisterMediaCallbacks(this.self.media);
      const currentMedia = this.self.getMedia();
      if (!currentMedia.isUsedAsFallback) {
        console.error(
          "Try to play the audio using the fallback media object which is SoundManager"
        );
        this.self.stopAudioAndRelease(true, true);
        if (
          Rollout.isFeatureEnabled(
            Rollout.FEATURES
              .FALLBACK_TO_WEB_AUDIO_IF_CORDOVA_MEDIA_PLAYBACK_FAILS
          )
        ) {
          this.self.playAudio(true, true);
          return;
        }
      }
      this.self.trigger("error", localizedErrorMessage, this.self._loadedSong);
      if (this.self.get("mediaType") == MEDIA_TYPE.PHONEGAP) {
        this.self._onSongFailedLoading(localizedErrorMessage);
      }
      if (
        window.waitForCordovaToLoad &&
        Utils.getInstance().isInterntetConnectionIsAvailable() &&
        Rollout.isFeatureEnabled(
          Rollout.FEATURES
            .AUTOMATICALLY_SKIP_TO_THE_NEXT_AUDIO_PLAYBACK_FAILS_ON_MOBILE
        )
      ) {
        this.self.playNextSongInPlaylist(null, "AUDIO_PLAYBACK_ERROR_FAILURE");
      } else {
        this.self.pauseAudio(true, true);
      }
      return;
    }
  },

onPreviewError: function(error) {
window.alertErrorMessage(window.getI18n(ti18n, "FAILED_PLAYING_PREVIEW"));
this.self.trigger("errorPreview", error, null);
this.self._notifyListenersForPreviewAndClear(this.mediaId, false, error);
},

getCurrentSong: function() {
return this.getQueue().getSongOnCurrentCursorPositionPromise();
},

getNextSong: function(increment) {
return this.getQueue().getSongOnNextCursorPositionPromise(increment);
},

getPreviousSong: function() {
return this.getQueue().getSongOnPreviousCursorPositionPromise();
},

getCurrentSongSocialInfoIfApplicable: function() {
return this.getQueue().getCurrentSongSocialInfoIfApplicable();
},

getCurrentCapsuleSummaryIfApplicable: function() {
return this.getQueue().getCurrentCapsuleSummaryIfApplicable();
},

getCurrentCommentSummaryIfApplicable: function() {
return this.getQueue().getCurrentCommentSummaryIfApplicable();
},

getCurrentSongRefSummaryIfApplicable: function() {
return this.getQueue().getCurrentSongRefSummaryIfApplicable();
},

getMedia: function() {
return this.get("media");
},

getMediaPreview: function() {
return this.get("mediaPreview");
},

getMediaStatus: function() {
return this.get("mediaStatus");
},

setMediaStatus: function(newMediaStatus) {
this.set("mediaStatus", newMediaStatus);
},


getMediaPreviewStatus: function() {
return this.get("mediaPreviewStatus");
},

setMediaPreviewStatus: function(newMediaStatus) {
this.set("mediaPreviewStatus", newMediaStatus);
},

getMediaCurentPostionUpateFunctionId: function() {
return this.get("mediaCurentPostionUpateFunctionId");
},

getQueue: function() {
return this.queue;
},

getPlaylist: function() {
return this.getQueue().getPlaylist();
},

isPlaylistCurrentlyLoaded: function(playlistSummaryModelToCheck) {
if (playlistSummaryModelToCheck && this.getQueue() && this.getQueue().getPlaylist() && playlistSummaryModelToCheck.getLoaderKey() == this.getQueue().getPlaylist().getLoaderKey()) {
return true;
} else {
return false;
}
},

triggerEventFromDocument: function(eventName, params) {
try {
document.dispatchEvent(new CustomEvent(eventName, params));

} catch (error) {
console.error(error);
}
},

onQueuePlaylistRefreshed: function(newPlaylist) {
if (this.isPlaying()) {
//window.alertErrorMessage("onQueuePlaylistRefreshed");
this.stopAudioAndRelease(false);
this._stopMusicPlaybackTimerIfApplicable();
this.playFirstFromPlaylist();
}
this.trigger("queuePlaylistRefreshed", newPlaylist);
},

onQueuePlaylistResetted: function(newPlaylist) {

if (this.isPlaying()) {
//window.alertErrorMessage("onQueuePlaylistResetted");
this.stopAudioAndRelease(false);
this._stopMusicPlaybackTimerIfApplicable();
this.playFirstFromPlaylist();
}
this.trigger("queuePlaylistResetted", newPlaylist);
},

_onPlaylistStartedLoading: function(playlistLoading) {
this.trigger("playlistStartedLoading", playlistLoading)
},

_onPlaylistFinishLoading: function(playlistFinishLoading) {
this.trigger("playlistFinishLoading", playlistFinishLoading)
},

setShuffleMode: function(shuffleMode) {
return this.getQueue().setShuffleMode(shuffleMode);
},

getShuffleMode: function() {
return this.getQueue().getShuffleMode();
},

setLoopMode: function(loopMode) {
this.getQueue().setLoopMode(loopMode);
window.log("About to record listening history to server");
},

getLoopMode: function() {
return this.getQueue().getLoopMode();
},

onQueuePlaylistChange: function(newPlaylist) {
if (this.isPlaying()) {
//window.alertErrorMessage("onQueuePlaylistChange");
this.stopAudioAndRelease(false);
this._saveMusicPlaybackTrackedTimeIfApplicable();
this.playFirstFromPlaylist();
} else {
this.releaseCurrentMediaIfApplicable(false);
this._saveMusicPlaybackTrackedTimeIfApplicable();
}

this.trigger("queuePlaylistChanged", newPlaylist);
},

onQueuePlaylistNameChange: function(newPlaylistName) {
this.trigger("queuePlaylistNameChanged", newPlaylistName);
},


setPlaylist: function(playlist, playFirstInQueue) {
const isMusicCurrentlyPlaying = this.isPlaying();
this._setAudioWasPlayedGoogleAnalyticsEventSent(false);
if (isMusicCurrentlyPlaying) {
if (this.getPlaylist()) {
    this.getPlaylist().setPlaying(false);
}
this.pauseAudio();
this._onPlayerLoading();
}
const oldPLayerType = this.getPlayerType();
const context = playlist.getContext();
const isTrebble = PlaylistHelper.getInstance().isPlaylistOfTrebbleType(playlist);
const isSongSimpleSet = Utils.getInstance().isContextTypeSimpleSongSet(context);
const isCapsuleSimpleSet = Utils.getInstance().isContextTypeSimpleCapsuleSet(context);
const isCapsuleFeed = Utils.getInstance().isContextTypeCapsuleFeed(context);
const isCategoryShortcastRadioPlaylist = Utils.getInstance().isContextTypeCategoryShortcastRadio(context);
if (isSongSimpleSet) {
this._setPlayerType(PLAYER_TYPES.SONG_SET_PLAYER);
} else {
if (isCapsuleSimpleSet) {
    this._setPlayerType(PLAYER_TYPES.CAPSULE_SET_PLAYER);
} else {
    if (isCapsuleFeed) {
        this._setPlayerType(PLAYER_TYPES.CAPSULE_FEED_PLAYER);
    } else {
     if(isCategoryShortcastRadioPlaylist){
        this._setPlayerType(PLAYER_TYPES.CATEGORY_SHORTCAST_RADIO)
    }else{
        if (isTrebble) {
            this._setPlayerType(PLAYER_TYPES.TREBBLE_PLAYER);
        } else {
            this._setPlayerType(PLAYER_TYPES.SIMPLE_PLAYER);
        }
    }
}
}
}
if (playFirstInQueue) {
if (isCapsuleFeed) {
try {
    document.dispatchEvent(new CustomEvent("playingCapsuleFeed", {
        "detail": playlist._loaderKey
    }));
} catch (error) {
    console.error(error);
}
}
if(isCategoryShortcastRadioPlaylist){
try {
document.dispatchEvent(new CustomEvent("playingCategoryShortcastRadio", {
    "detail": playlist._loaderKey
}));
} catch (error) {
console.error(error);
}
}
}

return this.getQueue().setPlaylist(playlist, true).then((function() {

//this._saveMusicPlaybackTrackedTimeIfApplicable();
if ((playFirstInQueue || isMusicCurrentlyPlaying) && (!this.isAudioHTML5QuirkRequired() || this.isAudioHTML5QuirkExecuted())) {
//window.alertErrorMessage("playlist was playing so playing also");
//this._updateManifestInformationInPageHeader(playlist);
this.trigger("playlistSet", playlist);
this._onPlayerFinishLoading();
return this.showOrPlayAdIfApplicable().then((function(){
    this.playFirstFromPlaylist();
}).bind(this));
} else {
if (isMobileQuirkNeedToBeExecutedForMobileVideo || (this.isAudioHTML5QuirkRequired() && !this.isAudioHTML5QuirkExecuted())) {
    mobileQuirkExecutedForMobileVideo = true;
    this._onPlayerFinishLoading();
    return this.showOrPlayAdIfApplicable().then((function(){
        return this._loadSongAtCursorPosition(0);
    }).bind(this)).then((function() {
        //this._updateManifestInformationInPageHeader(playlist);
        this.trigger("playlistSet", playlist);
        return true;
    }).bind(this));
} else {
    //this._updateManifestInformationInPageHeader(playlist);
    this.trigger("playlistSet", playlist);
    this._onPlayerFinishLoading();
    return this.getPlaylist().getSize().then((function(playlistSize) {
        if (playlistSize > 0) {
            return true;
        } else {
            throw new NoContentToPlayError();
        }
    }).bind(this))

}
}
//this._updateManifestInformationInPageHeader(playlist);
//this.trigger("playlistSet", playlist);
}).bind(this)).catch((function(error) {
this._onPlayerFinishLoading();
if (!this.isNoContentToPlayError(error)) {
this._setPlayerType(oldPLayerType);
Utils.getInstance().showErrorNotification(error);
} else {
throw error;
}
}).bind(this));

},

showOrPlayAdIfApplicable : function(){
try{
const playlist  = this.getQueue().getPlaylist();
const context = playlist.getContext();
/*const isTrebble = PlaylistHelper.getInstance().isPlaylistOfTrebbleType(playlist);
const isSongSimpleSet = Utils.getInstance().isContextTypeSimpleSongSet(context);
const isCapsuleSimpleSet = Utils.getInstance().isContextTypeSimpleCapsuleSet(context);*/
const isCapsuleFeed = Utils.getInstance().isContextTypeCapsuleFeed(context);
const isCategoryShortcastRadioPlaylist = Utils.getInstance().isContextTypeCategoryShortcastRadio(context);

if(FeatureRolloutHelper.getInstance().isFeatureEnabled(FeatureRolloutHelper.FEATURES.SHOW_INTERSTITIAL_ADS)){
    const displayInterstitialOnMobile = Rollout.getFeatureVariable(Rollout.FEATURES.SHOW_INTERSTITIAL_ADS, Rollout.FEATURES.SHOW_INTERSTITIAL_ADS.variables.show_interstitials_in_app, false);
    let showVideoInAd = Rollout.getFeatureVariable(Rollout.FEATURES.SHOW_INTERSTITIAL_ADS, Rollout.FEATURES.SHOW_INTERSTITIAL_ADS.variables.show_before_feed_show_video, false);  
    const showInterstitialBeforeFeed = Rollout.getFeatureVariable(Rollout.FEATURES.SHOW_INTERSTITIAL_ADS, Rollout.FEATURES.SHOW_INTERSTITIAL_ADS.variables.show_before_feed, false);
    //const showInterstitialWhenAppGoesForeground = Rollout.getFeatureVariable(Rollout.FEATURES.SHOW_INTERSTITIAL_ADS, Rollout.FEATURES.SHOW_INTERSTITIAL_ADS.variables.show_when_app_comes_foreground, false);
    const muteAds = Rollout.getFeatureVariable(Rollout.FEATURES.SHOW_INTERSTITIAL_ADS, Rollout.FEATURES.SHOW_INTERSTITIAL_ADS.variables.mute_audio, false); 
    if(muteAds){
        showVideoInAd = true;
    }
    if(window.waitForCordovaToLoad && (isCapsuleFeed || isCategoryShortcastRadioPlaylist) && displayInterstitialOnMobile && showInterstitialBeforeFeed){
        return AdHelper.getInstance().showInterstitialAd(showVideoInAd, muteAds);
    }
}
return RSVP.Promise.resolve();
}catch(e){
console.error("Failed to show or play ad. Error: "+e);
}
return RSVP.Promise.resolve();
},

clearQueue: function() {
this.getQueue().clear();
this.onSongPaused();
this.trigger("songChanged");
},

//THIS SHOULD NEVER THROW AN EXCEPTION
/*_updateManifestInformationInPageHeader : function(playlistLoadedInQueue){
try{
const isLoadedPlaylistATrebble = false;
const existingManifestElements = document.querySelectorAll("link[rel=manifest]");
for(let i = 0; i < existingManifestElements.length; i++){
    const linkRefNode = existingManifestElements[i];
    linkRefNode.parentNode.removeChild(linkRefNode);
}

const existingManifestEl = document.createElement('link');
existingManifestEl.setAttribute('rel', "manifest");
document.getElementsByTagName('head')[0].appendChild(existingManifestEl); 

if(existingManifestEl){
    isLoadedPlaylistATrebble =  PlaylistHelper.getInstance().isPlaylistOfTrebbleType(playlistLoadedInQueue);
    const manifestHref = "/manifest.json";
    //Using the "window.trebble.entryPointUrlHashLoadedPlayerQueue" to make sure to only use a trebble customized manifest
    //if the application was loaded because of a link shared to play a specific trebble, capsule,or song
    //It is assumed that the owner of the trebble, capsule, or song is the same owner of the trebble
    //that will be used to create the manifest file
    if(isLoadedPlaylistATrebble && window.trebble.entryPointUrlHashLoadedPlayerQueue){
        const trebbleId = playlistLoadedInQueue.getLoaderKey();
        //manifestHref = "http://s.trebble.fm/getManifest/byTrebbleId/"+trebbleId+"/manifest.json";
        const manifestHref = "https://s.trebble.fm/getManifest/byTrebbleId/"+trebbleId+"/manifest.json";


    }

    existingManifestEl.setAttribute('href', manifestHref);
}

if(window.isIOSMobileDevice){
    const playlistCoverArtUrl =  isLoadedPlaylistATrebble? playlistLoadedInQueue.getCoverArtUrl() : "/android-chrome-192x192.png";
    const logoUrl = playlistCoverArtUrl;
    const appName = isLoadedPlaylistATrebble? playlistLoadedInQueue.getName(): "Trebble.fm";
    const predominantColor =   isLoadedPlaylistATrebble ? Utils.getInstance().getMostPredominantColorFromCloudinaryImageInfo(playlistLoadedInQueue.getCoverArtUrlInfo()): "#333333";
    if(!predominantColor){
        predominantColor = "#333333";
    }
    const linkRelElementSelectorToUpdateMapWithUpdateValue = {"apple-touch-icon": logoUrl,
    "apple-touch-startup-image": playlistCoverArtUrl};
    for(let relValue : linkElementSelectorToUpdateMapWithUpdateValue){
        const value = linkElementSelectorToUpdateMapWithUpdateValue[relValue];
        const existingLinkElements = document.querySelectorAll("link[rel="+ relValue+"]");

        for(let i = 0; i < existingLinkElements.length; i++){
            const linkRefNode = existingLinkElements[i];
            linkRefNode.parentNode.removeChild(linkRefNode);
        }

        const linkElemToUpdate = document.createElement('link');
        linkElemToUpdate.setAttribute('rel', relValue);
        document.getElementsByTagName('head')[0].appendChild(linkElemToUpdate); 
        linkElemToUpdate.setAttribute('href', value);

    }
    const metaNameElementSelectorToUpdateMapWithUpdateValue = {"apple-mobile-web-app-title": appName,
    "apple-mobile-web-app-status-bar-style": predominantColor};
    for(let metaName : metaNameElementSelectorToUpdateMapWithUpdateValue){
        const value = metaNameElementSelectorToUpdateMapWithUpdateValue[metaName];
        const existingMetaElements = document.querySelectorAll("meta[name="+ metaName+"]");

        for(let i = 0; i < existingMetaElements.length; i++){
            const metaRefNode = existingMetaElements[i];
            metaRefNode.parentNode.removeChild(metaRefNode);
        }

        const metaElemToUpdate = document.createElement('meta');
        metaElemToUpdate.setAttribute('name', metaName);
        document.getElementsByTagName('head')[0].appendChild(metaElemToUpdate); 
        metaElemToUpdate.setAttribute('content', value);

    }
}


}catch(e){
console.error("Failed updating manifest information in meta tag. Error:"+ e);
}
},*/


_startMusicPlaybackTimer: function(currentSongModel) {

if (this.getPlayerType() == PLAYER_TYPES.CAPSULE_FEED_PLAYER || this.getPlayerType() == PLAYER_TYPES.CATEGORY_SHORTCAST_RADIO) {

//First Stopping the idle timer
if (this._radioBeingTracked && this._currentAccumulatedIdleStartTime) {
    let accumatedIdleTime = this._timerTracking[this._radioBeingTracked]._accumatedIdleTime;
    if (!accumatedIdleTime) {
        accumatedIdleTime = 0;
    }
    this._timerTracking[this._radioBeingTracked]._accumatedIdleTime = accumatedIdleTime + ((new Date()).getTime() - this._currentAccumulatedIdleStartTime);
    this._accumatedIdleTime = this._accumatedIdleTime + this._timerTracking[this._radioBeingTracked]._accumatedIdleTime;
    this._currentAccumulatedIdleStartTime = 0;
}

//saving the radio id about to be tracked

this._radioBeingTracked = this.getQueueContext(currentSongModel).id;
if (!this._timerTracking[this._radioBeingTracked]) {
    this._timerTracking[this._radioBeingTracked] = {};
}


if (this._radioBeingTracked) {
    //Now starting the playback timer
    if (!this._currentMusicPlayingStartTime) {
        this._currentMusicPlayingStartTime = (new Date()).getTime();
        this._currentMusicPlayingContext = this.getQueueContext(currentSongModel);
        this._currentPlaylistLoaderKey = this._currentMusicPlayingContext.id;
        this._playerTypeToSaveWithAnalytics = this.getPlayerType();

        if (!this._timerTracking[this._radioBeingTracked]._accumatedIdleTime) {
            const loaderKey = this._radioBeingTracked;
            const context = this._currentMusicPlayingContext;
            TrebbleClientAPIHelper.getInstance().addTebbleLoadActionToMyHistory(loaderKey, null, new Date(), null, context, null);
        }

    } else {
        //Timer probably already started so ignore
    }
}


} else {
this._accumatedIdleTime = this._accumatedIdleTime ? this._accumatedIdleTime : 0;
//First Stopping the idle timer
if (this._currentAccumulatedIdleStartTime) {
    this._accumatedIdleTime = this._accumatedIdleTime + ((new Date()).getTime() - this._currentAccumulatedIdleStartTime);
    this._currentAccumulatedIdleStartTime = 0;
}
//Now starting the playback timer
if (!this._currentMusicPlayingStartTime) {
    this._currentMusicPlayingStartTime = (new Date()).getTime();
    this._currentMusicPlayingContext = this.getQueueContext();
    this._currentPlaylistLoaderKey = this.getQueue().getPlaylist().getLoaderKey();
    this._currentPlaylistName = this.getQueue().getPlaylist().getName();
    this._playerTypeToSaveWithAnalytics = this.getPlayerType();
    let context, loaderKey;
    if (!this._accumatedIdleTime) {
        //Because there was no accumulated time, that means this plays for the first time
        if (this.getQueue() && this.getQueue().getPlaylist() && (this.getPlayerType() == PLAYER_TYPES.TREBBLE_PLAYER || this.getPlayerType() == PLAYER_TYPES.SONG_SET_PLAYER || this.getPlayerType() == PLAYER_TYPES.CAPSULE_SET_PLAYER)) {
            loaderKey = this.getQueue().getPlaylist().getLoaderKey();
            const name = this.getQueue().getPlaylist().getName();
            if (this.getPlayerType() == PLAYER_TYPES.TREBBLE_PLAYER) {
                context = Utils.getInstance().buildContextForSongCollectionUserRadio(loaderKey);
                TrebbleClientAPIHelper.getInstance().addTebbleLoadActionToMyHistory(loaderKey, name, new Date(), null, context, null);
            } else {
                if (this.getPlayerType() == PLAYER_TYPES.SONG_SET_PLAYER) {
                    context = Utils.getInstance().buildContextForSimpleSongSet(loaderKey);
                    TrebbleClientAPIHelper.getInstance().addSimpleSongLoadActionToMyHistory(loaderKey, new Date(), null, context, null);
                } else {
                    if (this.getPlayerType() == PLAYER_TYPES.CAPSULE_SET_PLAYER) {
                        context = Utils.getInstance().buildContextForSimpleCapsuleSet(loaderKey);
                        TrebbleClientAPIHelper.getInstance().addSimpleCapsuleLadActionToMyHistory(loaderKey, new Date(), null, context, null);
                    }
                }
            }
        }
    }
} else {
    //Timer probably already started so ignore
}

}
},

_stopMusicPlaybackTimerIfApplicable: function() {

if (this.getPlayerType() == PLAYER_TYPES.CAPSULE_FEED_PLAYER || this.getPlayerType() == PLAYER_TYPES.CATEGORY_SHORTCAST_RADIO) {

if (this._radioBeingTracked && this._currentMusicPlayingStartTime) {
    let accumatedMusicPlaybackTime = this._timerTracking[this._radioBeingTracked]._accumatedMusicPlaybackTime;
    if (!accumatedMusicPlaybackTime) {
        accumatedMusicPlaybackTime = 0;
    }
    this._timerTracking[this._radioBeingTracked]._accumatedMusicPlaybackTime = accumatedMusicPlaybackTime + ((new Date()).getTime() - this._currentMusicPlayingStartTime);
    this._accumatedMusicPlaybackTime = this._accumatedMusicPlaybackTime + this._timerTracking[this._radioBeingTracked]._accumatedMusicPlaybackTime;
    this._currentMusicPlayingStartTime = 0;
}
if (!this._currentAccumulatedIdleStartTime) {
    this._currentAccumulatedIdleStartTime = (new Date()).getTime();
    //this._accumatedMusicPlaybackTime = null;
} else {
    //Stop timer probably already started
}
} else {
this._accumatedMusicPlaybackTime = this._accumatedMusicPlaybackTime ? this._accumatedMusicPlaybackTime : 0;
if (this._currentMusicPlayingStartTime) {
    this._accumatedMusicPlaybackTime = this._accumatedMusicPlaybackTime + ((new Date()).getTime() - this._currentMusicPlayingStartTime);
    this._currentMusicPlayingStartTime = 0;
}
if (!this._currentAccumulatedIdleStartTime) {
    this._currentAccumulatedIdleStartTime = (new Date()).getTime();
    //this._accumatedMusicPlaybackTime = null;
} else {
    //Stop timer probably already started
}
}
},

_sendAnalyticsEventOfMusicPlaybackTime: function(sync, clearDataAfterSaving) {
if (this._currentMusicPlayingStartTime) {
const listenedTimeInMilliseconds = ((new Date()).getTime() - this._currentMusicPlayingStartTime);
if (window.trebbleAnalyticsHelper && listenedTimeInMilliseconds > 0) {
   //let label = "Music Playback Duration Time with Player in simple mode";
    if (this._playerTypeToSaveWithAnalytics == PLAYER_TYPES.TREBBLE_PLAYER) {
        //label = "Music Playback Duration Time with Player in Trebble Mode";
        if (window.trebbleAnalyticsHelper.trebbleHelperTrackTrebbleListeningTime) {
            window.trebbleAnalyticsHelper.trebbleHelperTrackTrebbleListeningTime(listenedTimeInMilliseconds);
        }
    }
    if (this._playerTypeToSaveWithAnalytics == PLAYER_TYPES.CAPSULE_FEED_PLAYER) {
        //label = "Music Playback Duration Time with Player in MY Capsule Trebble Feed Mode";
        if (window.trebbleAnalyticsHelper.trebbleHelperTrackCaspsuleTrebbleFeedListeningTime) {
            window.trebbleAnalyticsHelper.trebbleHelperTrackCaspsuleTrebbleFeedListeningTime(listenedTimeInMilliseconds);
        }
    }
    if (this._playerTypeToSaveWithAnalytics == PLAYER_TYPES.CATEGORY_SHORTCAST_RADIO) {
        //label = "Music Playback Duration Time with Player in MY Capsule Trebble Feed Mode";
        if (window.trebbleAnalyticsHelper.trebbleHelperTrackCaspsuleCategoryShortcastRadioListeningTime) {
            window.trebbleAnalyticsHelper.trebbleHelperTrackCaspsuleCategoryShortcastRadioListeningTime(listenedTimeInMilliseconds);
        }
    }
    if (this._playerTypeToSaveWithAnalytics == PLAYER_TYPES.SONG_SET_PLAYER) {
        //label = "Music Playback Duration Time with Player in Single Song Mode";
        if (window.trebbleAnalyticsHelper.trebbleHelperTrackSongSharedListeningTime) {
            window.trebbleAnalyticsHelper.trebbleHelperTrackSongSharedListeningTime(listenedTimeInMilliseconds);
        }
    }
    if (this._playerTypeToSaveWithAnalytics == PLAYER_TYPES.CAPSULE_SET_PLAYER) {
        //label = "Music Playback Duration Time with Player in Single Capsule Mode";
        if (window.trebbleAnalyticsHelper.trebbleHelperTrackCapsuleSharedListeningTime) {
            window.trebbleAnalyticsHelper.trebbleHelperTrackCapsuleSharedListeningTime(listenedTimeInMilliseconds);
        }
    }
}
}

if (this._playerTypeToSaveWithAnalytics == PLAYER_TYPES.CAPSULE_FEED_PLAYER || this._playerTypeToSaveWithAnalytics == PLAYER_TYPES.CATEGORY_SHORTCAST_RADIO) {


if ((this._timerTracking[this._radioBeingTracked]._accumatedMusicPlaybackTime || this._currentMusicPlayingStartTime) && this._radioBeingTracked) {
    //Saving Playback Time

    if (this._currentMusicPlayingStartTime) {
        let accumatedMusicPlaybackTime = this._timerTracking[this._radioBeingTracked]._accumatedMusicPlaybackTime;
        if (!accumatedMusicPlaybackTime) {
            accumatedMusicPlaybackTime = 0;
        }
        this._timerTracking[this._radioBeingTracked]._accumatedMusicPlaybackTime = accumatedMusicPlaybackTime + ((new Date()).getTime() - this._currentMusicPlayingStartTime);
        this._accumatedMusicPlaybackTime = this._accumatedMusicPlaybackTime + this._timerTracking[this._radioBeingTracked]._accumatedMusicPlaybackTime;
        this._currentMusicPlayingStartTime = (new Date()).getTime();
    }

    //Saving Idle Time
    if (this._currentAccumulatedIdleStartTime) {
        let accumatedIdleTime = this._timerTracking[this._radioBeingTracked]._accumatedIdleTime;
        if (!accumatedIdleTime) {
            accumatedIdleTime = 0;
        }
        this._timerTracking[this._radioBeingTracked]._accumatedIdleTime = accumatedIdleTime + ((new Date()).getTime() - this._currentAccumulatedIdleStartTime);
        this._accumatedIdleTime = this._accumatedIdleTime + this._timerTracking[this._radioBeingTracked]._accumatedIdleTime;
        this._currentAccumulatedIdleStartTime = (new Date()).getTime();
    }
    let loaderKey = this._radioBeingTracked;
    let existingLogEntryIdToUpdate = this._timerTracking[this._radioBeingTracked]._playingDataLogEntryIdToUpdate;

    let context = this._currentMusicPlayingContext;
    let additionalData = {
        "playbackTime": this._timerTracking[this._radioBeingTracked]._accumatedMusicPlaybackTime
    };
    if (this._timerTracking[this._radioBeingTracked]._accumatedIdleTime) {
        additionalData.idleTime = this._timerTracking[this._radioBeingTracked]._accumatedIdleTime;
    }
    TrebbleClientAPIHelper.getInstance().addTebbleFinishPlayingActionToMyHistory(loaderKey, null, new Date(), additionalData, context, null, sync, existingLogEntryIdToUpdate).then((function(logEntryId) {
        if (!clearDataAfterSaving && logEntryId) {
            this.self._timerTracking[this.radioBeingTracked]._playingDataLogEntryIdToUpdate = logEntryId;
        }
    }).bind({
        "self": this,
        "radioBeingTracked": this._radioBeingTracked
    }));

}
} else {
if ((this._accumatedMusicPlaybackTime || this._currentMusicPlayingStartTime) && this._currentPlaylistLoaderKey && (this._playerTypeToSaveWithAnalytics == PLAYER_TYPES.TREBBLE_PLAYER /*|| this._playerTypeToSaveWithAnalytics == PLAYER_TYPES.SONG_SET_PLAYER*/ )) {
    if (this._currentMusicPlayingStartTime) {
        this._accumatedMusicPlaybackTime = this._accumatedMusicPlaybackTime + ((new Date()).getTime() - this._currentMusicPlayingStartTime);
        this._currentMusicPlayingStartTime = (new Date()).getTime();
    }
    if (this._currentAccumulatedIdleStartTime) {
        this._accumatedIdleTime = this._accumatedIdleTime + ((new Date()).getTime() - this._currentAccumulatedIdleStartTime);
        this._currentAccumulatedIdleStartTime = (new Date()).getTime();
    }
    let loaderKey = this._currentPlaylistLoaderKey;
    let existingLogEntryIdToUpdate = this._playingDataLogEntryIdToUpdate;
    let name = this._currentPlaylistName;
    let context = null;
    if (this._playerTypeToSaveWithAnalytics == PLAYER_TYPES.TREBBLE_PLAYER) {
        context = Utils.getInstance().buildContextForSongCollectionUserRadio(loaderKey);
    }
    if (this._playerTypeToSaveWithAnalytics == PLAYER_TYPES.SONG_SET_PLAYER) {
        context = Utils.getInstance().buildContextForSimpleSongSet(loaderKey);
    }
    if (this._playerTypeToSaveWithAnalytics == PLAYER_TYPES.CAPSULE_SET_PLAYER) {
        context = Utils.getInstance().buildContextForSimpleCapsuleSet(loaderKey);
    }
    let additionalData = {
        "playbackTime": this._accumatedMusicPlaybackTime
    };
    if (this._accumatedIdleTime) {
        additionalData.idleTime = this._accumatedIdleTime;
    }
    TrebbleClientAPIHelper.getInstance().addTebbleFinishPlayingActionToMyHistory(loaderKey, name, new Date(), additionalData, context, null, sync, existingLogEntryIdToUpdate).then((function(logEntryId) {
        if (!clearDataAfterSaving && logEntryId) {
            this._playingDataLogEntryIdToUpdate = logEntryId;
        }
    }).bind(this));
}
}


if (clearDataAfterSaving) {
this._currentMusicPlayingStartTime = null;
this._currentMusicPlayingContext = null;
this._playerTypeToSaveWithAnalytics = null;
this._accumatedMusicPlaybackTime = 0;
this._currentPlaylistLoaderKey = null;
this._currentPlaylistName = null;
this._accumatedIdleTime = 0;
this._currentAccumulatedIdleStartTime = null;
this._playingDataLogEntryIdToUpdate = null;

this._radioBeingTracked = null;
this._timerTracking = {};
}
},

_saveMusicPlaybackTrackedTimeIfApplicable: function(sync) {
this._stopMusicPlaybackTimerIfApplicable();
this._sendAnalyticsEventOfMusicPlaybackTime(sync, true);
},

toggleLoopCurrentSong: function() {
let isLoopCurrentSong = this.getLoopMode();
if (isLoopCurrentSong) {
isLoopCurrentSong = false;
} else {
isLoopCurrentSong = true;
}
this.setLoopMode(isLoopCurrentSong);
return isLoopCurrentSong;
},

toggleShuffle: function() {
let isToggleShuffle = this.getShuffleMode();
if (isToggleShuffle) {
isToggleShuffle = false;
} else {
isToggleShuffle = true;
}
this.setShuffleMode(isToggleShuffle);
return isToggleShuffle;
},

addSongsToCurrentPlaylist: function(arrayOfSongModels, doNotChangeContext) {
    let currentPlaylist = this.getQueue().getPlaylist();
if (!currentPlaylist) {
currentPlaylist = PlaylistHelper.getInstance().generateEmptyPlaylist();
this.getQueue().setPlaylist(currentPlaylist);
}
if (arrayOfSongModels && arrayOfSongModels.length > 0) {
currentPlaylist.addSongs(arrayOfSongModels);
}
if (!doNotChangeContext) {
currentPlaylist.updateContext(Utils.getInstance().buildContextForSongCollectionUserCustom());
}
this.trigger("playlistChanged", currentPlaylist);
this.trigger("newSongAddedToPlayerQueue");
},

getQueueContext: function(options) {
return this.getQueue().getContext(options);
},

getCurrentPlayingIndexInPlaylist: function() {
return this.getQueue().getCurrentCursorPostionPromise();
},

setCurrentPlayingIndexInPlaylist: function(currentPlayingIndexInPlaylist) {
return this.getQueue().moveCursorToPositionPromise(currentPlayingIndexInPlaylist);
},

_applyIOSQuirkForHTML5AudioIfApplicable: function(/*cursorPosition*/) {
/*if (false && (window.device.platform == "iOS" || window.isMobileBrowser) && !window.trebble.config.iosAudioQuirkExcetuded) {

if (SC._version.charAt(0) > 2) {
    //USING SOUNDCLOUD VERSION AFTER VERSION 2 SO IT USING PROMISES
    SC.stream("/tracks/169803714").then(function(songLoaded) {
        if (songLoaded) {
            window.trebble.config.iosAudioQuirkExcetuded = true;
        }
    });

} else {
    SC.stream("/tracks/169803714", function(songLoaded) {
        if (songLoaded) {
            window.trebble.config.iosAudioQuirkExcetuded = true;
        }
    });
}
//}
}*/
},


loadFirstSong: function() {
return this._loadSongAtCursorPosition(0);
},

_loadSongAtCursorPosition: function(cursorPosition, playFromYoutube) {
this.stopAudioAndRelease(true);
this._onPlayerLoading();
this._applyIOSQuirkForHTML5AudioIfApplicable(cursorPosition);
if (this.getPlaylist()) {
return this.getQueue().moveCursorToPositionPromise(cursorPosition).then((function(songAtCursorPosition) {
    this._onPlayerFinishLoading();
    if (songAtCursorPosition) {
        return this._loadSong(songAtCursorPosition, playFromYoutube);
    } else {
        if (cursorPosition == 0) {
            console.warn("The playing queue is empty");
            Utils.getInstance().showWarningNotification("The playing queue is empty");
        } else {
            console.warn("No song to play in queue at current index position");
            Utils.getInstance().showWarningNotification("No song to play in queue at current index position");
        }
    }
}).bind(this)).then((function(newMediaCreated) {
    window.log("Media about to be played" + newMediaCreated);
    return newMediaCreated;
}).bind(this)).catch(function(err) {
    console.error(err);
    //window.alertErrorMessage("Error Setting current Song.Error: "+err);
    Utils.getInstance().showErrorNotification(window.getI18n(ti18n, "ERROR_PLAYING_CURRENT_SONG"));
});
} else {
this._onPlayerFinishLoading();
console.warn("No playlist loaded in queue.");
Utils.getInstance().showWarningNotification(window.getI18n(ti18n, "NO_PLAYLIST_IN_QUEUE"));
return RSVP.Promise.resolve();
}
},


reloadAndPlayCurrentSong: function() {
console.log("Audio reloaded and played");
return Utils.getInstance().showWarningNotification("Audio reloaded and played");
/*this.set("audioAlreadyReloaded", true);
return this.getCurrentPlayingIndexInPlaylist().then((function(currentCursorPosition) {
return this._playSongAtCursonPosition(currentCursorPosition);
}).bind(this));*/
},

_playSongAtCursonPosition: function(cursorPosition, playFromYoutube) {
this.stopAudioAndRelease(true);
this._onPlayerLoading();
this._applyIOSQuirkForHTML5AudioIfApplicable(cursorPosition);
if (this.getPlaylist()) {
return this.getQueue().moveCursorToPositionPromise(cursorPosition).then((function(songAtCursorPosition) {
    this._onPlayerFinishLoading();
    if (songAtCursorPosition) {
        return this._loadSong(songAtCursorPosition, playFromYoutube);
    } else {
        if (cursorPosition == 0) {
            //console.warn("The playing queue is empty");
            throw new NoContentToPlayError();
            //Utils.getInstance().showWarningNotification("The playing queue is empty");
        } else {
            //console.warn("No song to play in queue at current index position");
            //Utils.getInstance().showWarningNotification("No song to play in queue at current index position");
            throw new NoContentToPlayError();
        }
    }
}).bind(this)).then((function(newMediaCreated) {
    window.log("Media about to be played" + newMediaCreated);
    if (newMediaCreated && newMediaCreated == this.get("media")) { //the last comaprison is done to avoid playing a song mistakenly when the user skips quickly
        return this.playAudio();
    } else {
        console.warn("No media object was created");
    }
}).bind(this)).catch((function(err) {
    //window.alertErrorMessage("Error Setting current Song.Error: "+err);
    if (!this.isNoContentToPlayError(err)) {

        Utils.getInstance().showErrorNotification(window.getI18n(ti18n, "ERROR_PLAYING_CURRENT_SONG"));
    } else {
        throw err;
    }
}).bind(this));
} else {
//console.warn("No playlist loaded in queue.");
//Utils.getInstance().showWarningNotification("No playlist loaded in queue.");
//return RSVP.Promise.resolve();
this._onPlayerFinishLoading();
return new RSVP.Promise(function(resolve, reject) {
    reject(new NoContentToPlayError());
});
}
},


/*_prefetchAudioFileIfApplicableOld: function(songModelToFetch) {
if (DISABLE_PREFETCHING) {
return RSVP.Promise.resolve();
} else {
return new RSVP.Promise((function(resolve, reject) {
    let songUri = songModelToFetch.get("uri");
    let suggestedUri = Utils.getInstance().getSuggestedUri(songModelToFetch);
    let uri = songUri;
    if (!Utils.getInstance().isLocalMediaFileUri(songUri) && !Utils.getInstance().isHttpOrHttpsUrl(songUri) && suggestedUri) {
        uri = suggestedUri;
    }
    if (uri && songModelToFetch && this._arrayOfAudioURIPrefretched.indexOf(uri) == -1) {
        let onSuccess = function() {

        }
        let onError = function(error) {
            reject(error);
        }
        let onStatus = function(mediaStatus) {
            window.log("status of audio prefetched " + mediaStatus);
            if (mediaStatus > 1) {
                resolve();
            }
        }

        console.log("Song prefecth  uri" + uri);
        if (window.waitForCordovaToLoad) {
            let m = new window.Media(uri, onSuccess, onError, onStatus);
            onSuccess.media = m;
            onError.media = m;
            onStatus.media = m;
            m.seekTo(1);
            //m.setVolume(0);
            //m.play();
            //m.pause();
        } else {
            
            if (Utils.getInstance().isSoundcloudUri(uri)) {
                let soundcloudMediaParams = {};
                let soundcloudUriWithPrefix = songModelToFetch.get("uri");
                if (Utils.getInstance().isSuggestedUriAvaliable(songModelToFetch)) {
                    soundcloudUriWithPrefix = Utils.getInstance().getSuggestedUri(songModelToFetch)
                }
                let soundcloudPrefix = "soundcloud:"
                soundcloudMediaParams.uri = soundcloudUriWithPrefix.substring(soundcloudPrefix.length);
                soundcloudMediaParams.onSuccess = onSuccess;
                soundcloudMediaParams.onError = onError;
                soundcloudMediaParams.onStatus = onStatus;
                soundcloudMediaParams.handlerContext = this;
                let m = new SoundcloudMedia(soundcloudMediaParams);
                soundcloudMediaParams.onSuccess.media = m;
                soundcloudMediaParams.onError.media = m;
                soundcloudMediaParams.onStatus.media = m;
            } else {
                let soundManagerMediaParams = {};
                soundManagerMediaParams.uri = uri;
                soundManagerMediaParams.onSuccess = onSuccess;
                soundManagerMediaParams.onError = onError;
                soundManagerMediaParams.onStatus = onStatus;
                soundManagerMediaParams.handlerContext = this;

                let m = new SoundManagerMedia(soundManagerMediaParams);
                soundManagerMediaParams.onSuccess.media = m;
                soundManagerMediaParams.onError.media = m;
                soundManagerMediaParams.onStatus.media = m;
            }
        }
    } else {
        resolve();
    }
}).bind(this));
}

},*/

_prefetchAudioFileIfApplicable: function(songModelToFetch) {
    if (DISABLE_PREFETCHING) {
    return RSVP.Promise.resolve();
    } else {
    return new RSVP.Promise((function(resolve, reject) {
        let songUri = songModelToFetch.get("uri");
        let suggestedUri = Utils.getInstance().getSuggestedUri(songModelToFetch);
        let uri = songUri;
        if (!Utils.getInstance().isLocalMediaFileUri(songUri) && !Utils.getInstance().isHttpOrHttpsUrl(songUri) && suggestedUri) {
            uri = suggestedUri;
        }
        if (uri && songModelToFetch && this._arrayOfAudioURIPrefretched.indexOf(uri) == -1) {
            let onSuccess = function() {
    
            }
            let onError = function(error) {
                reject(error);
            }
            let onStatus = function(mediaStatus) {
                window.log("status of audio prefetched " + mediaStatus);
                if (mediaStatus > 1) {
                    resolve();
                }
            }
    
            console.log("Song prefecth  uri" + uri);
            if (window.waitForCordovaToLoad) {
                let m = new window.Media(uri, onSuccess, onError, onStatus);
                onSuccess.media = m;
                onError.media = m;
                onStatus.media = m;
                m.seekTo(1);
                //m.setVolume(0);
                //m.play();
                //m.pause();
            } else {
                let context = {
                    "self": this
                };
                let mediaId = Utils.getInstance().generateRandomId();
                context.mediaId = mediaId;
            
                if (Utils.getInstance().isSoundcloudUri(uri)) {
                    let soundcloudMediaParams = {};
                    let soundcloudUriWithPrefix = songModelToFetch.get("uri");
                    if (Utils.getInstance().isSuggestedUriAvaliable(songModelToFetch)) {
                        soundcloudUriWithPrefix = Utils.getInstance().getSuggestedUri(songModelToFetch)
                    }
                    let soundcloudPrefix = "soundcloud:"
                    soundcloudMediaParams.uri = soundcloudUriWithPrefix.substring(soundcloudPrefix.length);
                    soundcloudMediaParams.onSuccess = this.onSuccessNew.bind(context);
                    soundcloudMediaParams.onError = this.onErrorNew.bind(context);
                    soundcloudMediaParams.onStatus = this.onStatusNew.bind(context);
                    soundcloudMediaParams.handlerContext = this;
                    let m = new SoundcloudMedia(soundcloudMediaParams);
                    this._registerMediaCallbacks(mediaId,  soundcloudMediaParams.onSuccess, soundcloudMediaParams.onError, soundcloudMediaParams.onStatus);
                } else {
                    let soundManagerMediaParams = {};
                    soundManagerMediaParams.uri = uri;
                    soundManagerMediaParams.onSuccess = this.onSuccessNew.bind(context);
                    soundManagerMediaParams.onError = this.onErrorNew.bind(context);
                    soundManagerMediaParams.onStatus = this.onStatusNew.bind(context);
                    soundManagerMediaParams.handlerContext = this;
    
                    let m = new SoundManagerMedia(soundManagerMediaParams);
                    this._registerMediaCallbacks(mediaId,  soundManagerMediaParams.onSuccess, soundManagerMediaParams.onError, soundManagerMediaParams.onStatus);
                }
            }
        } else {
            resolve();
        }
    }).bind(this));
    }
    
    },

_loadPreviewSong: function(successCB, failureCB) {
return new RSVP.Promise((function(resolve, reject) {
try {
    window.log("Preview loaddeddddddd");
    this.trigger("songPreviewLoaded");
    this._stopToSoundCloudMediaEventsIfApplicable();
    let mediaPreview = null;
    let songUri = this.get("previewInfo");
    this._onSongPreviewLoading();
    if (Utils.getInstance().isHttpOrHttpsUrl(songUri)) {
        if (window.device && window.device.platform != "browser") {
            let context = {
                "self": this
            };
            let mediaId = Utils.getInstance().generateRandomId();
            context.mediaId = mediaId;
            let successFunc = (this.onPreviewSuccess).bind(context);
            let errorFunc = (this.onPreviewError).bind(context);
            let statusFunc = (this.onPreviewStatus).bind(context);
            mediaPreview = new window.Media(songUri,
                successFunc, errorFunc,
                statusFunc);
            this.setMediaPreviewByMediaId(mediaId, mediaPreview);
            this._registerMediaPreviewCallbacks(mediaId, successCB, failureCB);
            successFunc.mediaPreviewSelf = mediaPreview;
            errorFunc.mediaPreviewSelf = mediaPreview;
            statusFunc.mediaPreviewSelf = mediaPreview;
            this.set("mediaPreview", mediaPreview);
            this.set("mediaPreviewType", MEDIA_TYPE.PHONEGAP);
            this._listenToPreviewMediaEvents(mediaPreview, MEDIA_TYPE.PHONEGAP);
            this.trigger("songPreviewChanged");
        } else {
            let context = {
                "self": this
            };
            let mediaId = Utils.getInstance().generateRandomId();
            context.mediaId = mediaId;
            let soundManagerMediaParams = {};
            soundManagerMediaParams.uri = songUri;
            soundManagerMediaParams.onSuccess = (this.onPreviewSuccess).bind(context);
            soundManagerMediaParams.onError = (this.onPreviewError).bind(context);
            soundManagerMediaParams.onStatus = (this.onPreviewStatus).bind(context);
            soundManagerMediaParams.handlerContext = context;
            mediaPreview = new SoundManagerMedia(soundManagerMediaParams);
            this.setMediaPreviewByMediaId(mediaId, mediaPreview);
            this._registerMediaPreviewCallbacks(mediaId, successCB, failureCB);
            soundManagerMediaParams.onSuccess.media = mediaPreview;
            soundManagerMediaParams.onError.media = mediaPreview;
            soundManagerMediaParams.onStatus.media = mediaPreview;

            this.set("mediaPreview", mediaPreview);
            this.set("mediaPreviewType", MEDIA_TYPE.HTML5_MEDIA);
            this._listenToPreviewMediaEvents(mediaPreview, MEDIA_TYPE.HTML5_MEDIA);
            this.trigger("songPreviewChanged");
        }
    }
    this._onSongPreviewFinishLoading();
    resolve(mediaPreview);
} catch (error) {
    reject(error)
}
}).bind(this));
},

playFirstFromPlaylist: function() {
//this._startMusicPlaybackTimer();
return this._playSongAtCursonPosition(0, false);
},

playFirstFromPlaylistAsYoutubeVideo: function() {
//this._startMusicPlaybackTimer();
return this._playSongAtCursonPosition(0, true);
},


playSongInQueue: function(song) {
this.stopAudioAndRelease(true);
if (this.getPlaylist() && song) {
return this.getQueue().moveCursorToSongWithUriPromise(song.uri).then((function(songToCurrentCursorPosition) {
    return this._loadSong(songToCurrentCursorPosition);
}).bind(this)).then((function() {
    return this.playAudio();
}).bind(this)).catch(function(err) {
    console.error(err);
    window.alertErrorMessage(window.getI18n(ti18n, "ERROR_PLAYING_CURRENT_SONG"));
});
} else {
return RSVP.Promise.resolve();
}
},



isPlaying: function() {
return this.get("isPlaying");
},

isPreviewPlaying: function() {
return this.get("isPreviewPlaying");
},

getCurrentSongDuration: function() {
if (this.getMedia() && this.getMedia().getDuration() && this.getMedia().getDuration() != -1) {
return RSVP.Promise.resolve(this.getMedia().getDuration());
} else {
return this.getCurrentSong().then((function(currentSong) {
    if (currentSong) {
        let songDuration = currentSong.get("duration");
        if (!songDuration && this.get("loadedSongDuration")) {
            songDuration = this.get("loadedSongDuration");
        }
        return songDuration;
    } else {

        return -1;
    }

}).bind(this));
}
},

_onSongLoading: function(songModel) {
this.set("loading", true);
//this._stopMusicPlaybackTimerIfApplicable();
this.trigger("songLoading", songModel);
},

_onSongPreviewLoading: function() {
this.set("previewLoading", true);
//this._stopMusicPlaybackTimerIfApplicable();
this.trigger("songPreviewLoading");
},

_onPlayerLoading: function() {
this.set("playerLoading", true);
//this._stopMusicPlaybackTimerIfApplicable();
this.trigger("playerLoading");
},

_onPlayerFinishLoading: function() {
this.set("playerLoading", false);
//this._startMusicPlaybackTimer();
this.trigger("playerFinishLoading");
},

_onSongFinishLoading: function(songModel) {
this.set("loading", false);
//this._startMusicPlaybackTimer();
this.trigger("songLoaded", songModel);
},

_onSongPreviewFinishLoading: function() {
this.set("previewLoading", false);
//this._startMusicPlaybackTimer();
this.trigger("songPreviewLoaded");
},


_onSongFailedLoading: function(err) {
this.set("loading", false);
//this._stopMusicPlaybackTimerIfApplicable();
this.trigger("songFailedLoading", err);
},

_onSongPreviewFailedLoading: function(err) {
this.set("previewLoading", false);
//this._stopMusicPlaybackTimerIfApplicable();
this.trigger("songPreviewFailedLoading", err);
},

isLoading: function() {
return this.get("loading");
},

isPreviewLoading: function() {
return this.get("previewLoading");
},

isPlayerLoading: function() {
return this.get("playerLoading");
},

isSongLoaded: function() {
return this._loadedSong;
},


getCapsuleInfoCurrentlyPlaying: function() {
return this.getCurrentSong().then((function(currentSong) {
if (currentSong) {
    const uri = currentSong.get("uri");
    if (Utils.getInstance().isCapsuleUri(uri)) {
        return TrebbleClientAPIHelper.getInstance().getCapsuleForSongCreatedFromCapsuleInfo(Utils.getInstance().getSongJsonForBackendFromSongModel(currentSong));
    } else {
        return null;
    }
} else {
    return null
}

}).bind(this))
},

updateCoverArts: function() {
return this.getCurrentSong().then((function(currentSong) {
if (currentSong) {

    let albumId = currentSong.get("albumJson").albumId;
    const isSongLocatedOnDevice = Utils.getInstance().isLocalMediaFileUri(currentSong.get("uri"));
    const saveCoverArtInLocalDB = isSongLocatedOnDevice;
    if (currentSong.get("albumJson").doNotUseIdifiedUrl == true) {
        albumId = currentSong.get("albumJson").preferredAlbumIdForCoverArt;
    }
    const p = CordovaHelper.getInstance().executePluginOperation("MusicFileReaderPlugin", "getAlbumArtInfoFromImageStore", [albumId]);
    //const p = ImageStoreHelper.getInstance().getAlbumImageByImageId(albumId);
    p.then((function(albumImageInfo) {
        if (albumImageInfo) {
            const albumArtBase64Stored = albumImageInfo.originalBase64Image;
            const albumArtBase64BlurStored = albumImageInfo.originalBlurBase64Image;
            if (albumArtBase64Stored && albumArtBase64Stored != "null") {
                //TODO: Null string check is to by pass error that need investigation
                this._currentSongAlbumArtBase64 = albumArtBase64Stored;
                this._currentSongAlbumArtBase64Blur = albumArtBase64BlurStored;
                //if(!window.isCordovaNativeApp()){
                    this._updateLockScreen();
                    //}
                    this.trigger('thumbnailAlbumCoverUpdate', true, albumArtBase64Stored, currentSong.get('albumJson').albumArtURL, currentSong.get("uri"));
                    this.trigger('onAlbumCoverUpdate', true, albumArtBase64Stored,
                        albumArtBase64BlurStored, currentSong.get('albumJson').albumArtURL, currentSong.get("uri"));
                } else {
                    //try to retrieve from native 
                    this.retrieveScaledCoverArts(saveCoverArtInLocalDB);
                }
            } else {
                this.retrieveScaledCoverArts(saveCoverArtInLocalDB);
            }

        }).bind(this)).catch((function(error) {
            console.error("error when retrieving album art . Error:" + error);
            this._currentSongAlbumArtBase64 = null;
            this._currentSongAlbumArtBase64Blur = null;
            //if(!window.isCordovaNativeApp()){
                this._updateLockScreen();
                //}
                this.trigger('thumbnailAlbumCoverUpdate', false, null, null, albumId);
                this.trigger('onAlbumCoverUpdate', false, null, null, currentSong.get('albumJson').albumArtURL, currentSong.get("uri"));
                throw error;
            }).bind(this));

        //}
    }
}).bind(this));


},

liveLikeCurrentSong: function() {
if ((window.device && window.device.platform != "browser" && this.getPlaylist() && (this.getPlayerType() != PLAYER_TYPES.TREBBLE_PLAYER || this.getPlayerType() != PLAYER_TYPES.CAPSULE_FEED_PLAYER || this.getPlayerType() != PLAYER_TYPES.CATEGORY_SHORTCAST_RADIO || this.getPlayerType() != PLAYER_TYPES.SONG_SET_PLAYER || this.getPlayerType() != PLAYER_TYPES.CAPSULE_SET_PLAYER)) || (this._currentSongLiveLikeCounter + 1 <= MAX_LIVE_LIKE_VALUE_FOR_DESKTOP)) {
this._currentSongLiveLikeCounter = this._currentSongLiveLikeCounter + 1;
this.trigger("liveLikeButtonIncremented", this._currentSongLiveLikeCounter);
if (this._loadedSong) {
    if (this._pendingLiveLikeActionHandlerId) {
        clearTimeout(this._pendingLiveLikeActionHandlerId);
        this._pendingLiveLikeActionHandlerId = null;
    }
    //wait a certain amount of time before sending search event
    this._pendingLiveLikeActionHandlerId = setTimeout((function() {
        this.self.saveHistoryToServer("liveLike", this.weight, this.self.get("numberOfSecondsPlaying"));
        this.self._currentSongLiveLikeCounter = 0;
    }).bind({
        "self": this,
        "songToLiveLike": this._loadedSong,
        "weight": this._currentSongLiveLikeCounter
    }), LIVE_LIKE_ACTION_COMPLETION_DELAY);
}
}
},

retrieveScaledCoverArts: function(saveCoverArtInLocalDB) {
const screenWidth = window.getDocumentClienWidth();
const screenHeight = window.getDocumentClienHeight();
const regularCovertArtWidth = window.getDocumentClienWidth() - 10;
const regularCovertArtHeight = window.getDocumentClienHeight() - 220;
const thumbnailWidth = 64;
const thumbnailHeight = 64;
//const isBasicOrIdifedCoverArt = false;
let mp3AlbumId = null;
let basicOrIdifiedCoverArtUrl = null;
let albumArtImages = null;
let songNeedingCoverArtRetrieval = null;
let songUri = null;
return this.getCurrentSong().then((function(currentSong) {
if (currentSong) {
    songNeedingCoverArtRetrieval = currentSong;
    songUri = songNeedingCoverArtRetrieval ? songNeedingCoverArtRetrieval.get("uri") : null;
    if (window.device.platform == "iOS") {
        mp3AlbumId = currentSong.get("linkedAlbumId") ? currentSong.get("linkedAlbumId").toString() : "";
    } else {
        mp3AlbumId = parseInt(currentSong.get("linkedAlbumId"));
    }
    basicOrIdifiedCoverArtUrl = currentSong.get("albumArtUrl");
    albumArtImages = currentSong.get("albumArtImages");
    //const albumJson = currentSong.get("albumJson");
    if (currentSong.get("idifiedAlbumArtURL") || currentSong.get('albumJson').albumArtURL) {
        basicOrIdifiedCoverArtUrl = currentSong.get('albumJson').albumArtURL ? currentSong.get('albumJson').albumArtURL : currentSong.get("idifiedAlbumArtURL");
        albumArtImages = currentSong.get('albumJson').albumArtURL ? currentSong.get('albumJson').albumArtImages : currentSong.get("idifiedAlbumArtImages");
    }
}
/*if(albumJson.doNotUseIdifiedUrl == true)
{
    mp3AlbumId = albumJson.preferredAlbumIdForCoverArt;
    basicOrIdifiedCoverArtUrl = albumJson.preferredAlbumArtURL;
}*/

/*if (basicOrIdifiedCoverArtUrl) {
    isBasicOrIdifedCoverArt = true;
} else {*/
    if (!albumArtImages && !basicOrIdifiedCoverArtUrl) {
        basicOrIdifiedCoverArtUrl = this.getDefaultAlbumArtUrl();
    }
//}
}).bind(this)).then((function() {
const params = [mp3AlbumId, screenWidth, screenHeight,
regularCovertArtWidth, regularCovertArtHeight,
thumbnailWidth, thumbnailHeight, basicOrIdifiedCoverArtUrl, albumArtImages
];
return CordovaHelper.getInstance().executePluginOperation("MusicFileReaderPlugin", "retrieveScaledCoverArtImages", params);
}).bind(this)).then((function(scaledCovertArts) {
if (scaledCovertArts) {
    this._currentSongAlbumArtBase64 = scaledCovertArts.albumArtBase64ThumbnailSize;
    this._currentSongAlbumArtBase64Blur = scaledCovertArts.albumArtBase64BlurThumbnailSize;
    //if(!window.isCordovaNativeApp()){
        this._updateLockScreen();
        //}
        this.trigger('thumbnailAlbumCoverUpdate', scaledCovertArts.isIdifedCoverArt, scaledCovertArts.albumArtBase64ThumbnailSize, scaledCovertArts.albumArtBase64BlurThumbnailSize, songUri, basicOrIdifiedCoverArtUrl);
        this.trigger('onAlbumCoverUpdate', scaledCovertArts.isIdifedCoverArt, scaledCovertArts.albumArtBase64RegularSize, scaledCovertArts.albumArtBase64BlurScreenSize, basicOrIdifiedCoverArtUrl, songUri, basicOrIdifiedCoverArtUrl);
        if (saveCoverArtInLocalDB) {


            CordovaHelper.getInstance().executePluginOperation("MusicFileReaderPlugin", "saveAlbumArtInfoToImageStore", [songNeedingCoverArtRetrieval.get('albumJson').albumId, basicOrIdifiedCoverArtUrl, scaledCovertArts.albumArtBase64RegularSize, scaledCovertArts.albumArtBase64BlurScreenSize]);
        }
    } else {
        this.trigger('thumbnailAlbumCoverUpdate', false, null, null, songUri, basicOrIdifiedCoverArtUrl);
        this.trigger('onAlbumCoverUpdate', false, null, null, basicOrIdifiedCoverArtUrl, songUri, basicOrIdifiedCoverArtUrl);
    }
}).bind(this)).catch((function(error) {
    console.error("Failed Retriving covert art for song with uri :'" + songUri + "'. Error :" + error);
    this.trigger('thumbnailAlbumCoverUpdate', false, null, null, songUri);
    this.trigger('onAlbumCoverUpdate', false, null, null, basicOrIdifiedCoverArtUrl, songUri, basicOrIdifiedCoverArtUrl);
    throw error;
}).bind(this));

},

_onCurrentSongInfoChanged: function() {
this.trigger("songChanged");
this.updateCoverArts();
this._updateLockScreen();
},

_updateSubscriptionInfoIfApplicable :function(songUri){
const queueContext = this.getQueueContext();
//const isSongSimpleSet = Utils.getInstance().isContextTypeSimpleSongSet(queueContext);
//const isCapsuleFeed = Utils.getInstance().isContextTypeCapsuleFeed(queueContext);
const isCapsuleSimpleSet = Utils.getInstance().isContextTypeSimpleCapsuleSet(queueContext);
const isCategoryShortcastRadioPlaylist = Utils.getInstance().isContextTypeCategoryShortcastRadio(queueContext);
let canBeSubscribedTo = false;
let isSubscribeTo = false;
if(isCapsuleSimpleSet || isCategoryShortcastRadioPlaylist){
    if(isCategoryShortcastRadioPlaylist){
        if(this.getPlaylist().getRadioIdForSongWitUri){
            const radioId = this.getPlaylist().getRadioIdForSongWitUri(songUri);
            if(radioId){
                canBeSubscribedTo = true;
                isSubscribeTo = PersistentModels.getInstance().isLoggedInUserSubscribeToRadio(radioId);
            }
        }
    }
    if(isCapsuleSimpleSet){
        canBeSubscribedTo  = this.getPlaylist().canBeFollowed();
        isSubscribeTo  = this.getPlaylist().isFollowing();
    }
    this.trigger("onSubscriptionInfoUpdated", canBeSubscribedTo, isSubscribeTo);
}else{
    this.trigger("onSubscriptionInfoUpdated", canBeSubscribedTo, isSubscribeTo);
}
},

_getSoundcloudSearchHelperInstance: function() {
return PersistentModels.getInstance().getRouter().require(["models/helper/SoundcloudSearchHelper"]).then((function(SoundcloudSearchHelper) {
    return SoundcloudSearchHelper.getInstance();
}).bind(this));
},

_findMatchingSoundcloudUriIfApplicable: function(songModel, playFromYoutube, playbackCausedByInvalidUrl) {
return new RSVP.Promise((function(resolve, reject) {
    const songUri = songModel.get("uri");
    const isMatchingSoundcloudUriNeeded = playbackCausedByInvalidUrl || !(playFromYoutube || this._getSongModelAudioType(songModel) != Utils.getInstance().getAudioTypes().SONG || Utils.getInstance().isLocalMediaFileUri(songUri) || Utils.getInstance().isHttpOrHttpsUrl(songUri) || Utils.getInstance().isSoundcloudUri(songUri) || Utils.getInstance().isYoutubeUri(songUri));
    if (!isMatchingSoundcloudUriNeeded) {
        return resolve(songModel);
    } else {
        this._getSoundcloudSearchHelperInstance().then((function(soundcloudSearchHelperInstance) {
            soundcloudSearchHelperInstance.findSoundcloudSongInfoForSong(songModel).then((function(songJsonInfo) {
                if (songJsonInfo) {
                    //songModel.set("suggestedUri",soundCloudUri);
                    Utils.getInstance().setSuggestedUri(songModel, songJsonInfo.uri);
                    Utils.getInstance().setSuggestedExternalSongPage(songModel, songJsonInfo.externalPageUrl);
                } else {
                    Utils.getInstance().setSuggestedUri(songModel, null);
                    Utils.getInstance().setSuggestedExternalSongPage(songModel, null);
                }
                return resolve(songModel);
            }).bind(this)).catch(reject);
        }).bind(this)).catch(reject);
        /*SoundcloudSearchHelper.getInstance().findSoundcloudUriForSong(songModel).then((function(soundCloudUri){
            if(soundCloudUri){
                //songModel.set("suggestedUri",soundCloudUri);
                Utils.getInstance().setSuggestedUri(songModel,soundCloudUri);
            }else{
                Utils.getInstance().setSuggestedUri(songModel,null);
            }
            return resolve(songModel);
        }).bind(this)).catch(reject);*/

}
}).bind(this));
},
_listenToMediaEvents: function(media, mediaType) {
if (media && mediaType && (mediaType == MEDIA_TYPE.SOUNDCLOUD || mediaType == MEDIA_TYPE.YOUTUBE || mediaType == MEDIA_TYPE.HTML5_MEDIA)) {
if (mediaType == MEDIA_TYPE.YOUTUBE) {
    this._isYoutubeVideoLoaded = true;
} else {
    this._isYoutubeVideoLoaded = false;
}
this.listenTo(media, "loading", this._onSongLoading);
this.listenTo(media, "loaded", this._onSongFinishLoading);
this.listenTo(media, "failedLoading", this._onSongFailedLoading);
this.listenTo(media, "durationChange", this._onSongDurationChanged);
this.listenTo(media, "downloading", this._onDownloading);

if (media.isLoading()) {
    this._onSongLoading();
} else {
    if (!media.getLoadingError()) {
        this._onSongFinishLoading();
    } else {
        this._onSongFailedLoading(media.getLoadingError());
    }
}
} else {
this._isYoutubeVideoLoaded = false;
}

},


_listenToPreviewMediaEvents: function(media, mediaType) {
if (media && mediaType && (mediaType == MEDIA_TYPE.SOUNDCLOUD || mediaType == MEDIA_TYPE.YOUTUBE || mediaType == MEDIA_TYPE.HTML5_MEDIA)) {

this.listenTo(media, "loading", this._onSongPreviewLoading);
this.listenTo(media, "loaded", this._onSongPreviewFinishLoading);
this.listenTo(media, "failedLoading", this._onSongPreviewFailedLoading);

if (media.isLoading()) {
    this._onSongPreviewLoading();
} else {
    if (!media.getLoadingError()) {
        this._onSongPreviewFinishLoading();
    } else {
        this._onSongPreviewFailedLoading(media.getLoadingError());
    }
}
} 

},


isNoContentToPlayError: function(err) {
return err instanceof NoContentToPlayError;
},

_onSongDurationChanged: function(newSongDuration) {
if (newSongDuration) {
this.set("loadedSongDuration", newSongDuration);
}
this.trigger("durationChange", newSongDuration);
},

_onDownloading: function(downloadPercentage) {
this.trigger("downloading", downloadPercentage);
},

_stopToSoundCloudMediaEventsIfApplicable: function() {
if (this.get("media") && (this.get("mediaType") == MEDIA_TYPE.SOUNDCLOUD || this.get("mediaType") == MEDIA_TYPE.YOUTUBE || this.get("mediaType") == MEDIA_TYPE.HTML5_MEDIA)) {
this.stopListening(this.get("media"));
}
},

_hideAllVideoEls: function() {
const videosEls = document.getElementsByTagName("video");
if (videosEls && videosEls.length > 0) {
for (let index = 0; index++; index < videosEls.length) {
    const videoEl = videosEls[index];
    videoEl.style.display = "none";
}
}
},

_showAllVideoEls: function() {
const videosEls = document.getElementsByTagName("video");
if (videosEls && videosEls.length > 0) {
for (let index = 0; index++; index < videosEls.length) {
    const videoEl = videosEls[index];
    videoEl.style.display = "block";
}
}
},

_setAudioWasPlayedGoogleAnalyticsEventSent: function(trueOrFalse) {
this.set("audioWasPlayedGoogleAnalyticsEventSent", trueOrFalse);
},

_isAudioWasPlayedGoogleAnalyticsEventSent: function() {
return this.get("audioWasPlayedGoogleAnalyticsEventSent");
},


_sendGoogleAnayticsEventThatAudioWasPlayedIfApplicable: function() {
if (window.trebbleAnalyticsHelper) {
//const playlist = this.getPlaylist();
const audioWasPlayedGoogleAnalyticsEventSent = this._isAudioWasPlayedGoogleAnalyticsEventSent();
if (!audioWasPlayedGoogleAnalyticsEventSent) {
    if (this.getPlayerType() == PLAYER_TYPES.TREBBLE_PLAYER) {
        const trebbleId = this.getPlaylist().getLoaderKey();
        this._setAudioWasPlayedGoogleAnalyticsEventSent(true)
        return window.trebbleAnalyticsHelper.trackEvent('Player Events', "TrebbleWasPlayed", "Trebble Station was played", null, {
            "playlistName": this.getPlaylist().getName(),
            "trebbleId": trebbleId,
            "customTrackingKey": window.trebble.customTrackingKey
        });
    }
    if (this.getPlayerType() == PLAYER_TYPES.CAPSULE_FEED_PLAYER) {
        this._setAudioWasPlayedGoogleAnalyticsEventSent(true)
        const userFeedId = this.getPlaylist().getLoaderKey();
        TrebbleClientAPIHelper.getInstance().addUserFeedPlayedActionToHistory(userFeedId, new Date());
        return window.trebbleAnalyticsHelper.trackEvent('Player Events', "UserFeedWasPlayed", "User feed was played", null, {
            "userFeedId": userFeedId,
            "customTrackingKey": window.trebble.customTrackingKey
        });
    }
    if (this.getPlayerType() == PLAYER_TYPES.CATEGORY_SHORTCAST_RADIO) {
        this._setAudioWasPlayedGoogleAnalyticsEventSent(true)
        const categoryId = this.getPlaylist().getLoaderKey();
        TrebbleClientAPIHelper.getInstance().addCategoryShortcastRadioPlayedActionToHistory(categoryId, new Date());
        return window.trebbleAnalyticsHelper.trackEvent('Player Events', "CategoryShortcastRadioPlayed", "Category Shortcast Radio was played", null, {
            "categoryId": categoryId,
            "customTrackingKey": window.trebble.customTrackingKey
        });
    }
    if (this.getPlayerType() == PLAYER_TYPES.CAPSULE_SET_PLAYER) {
        this._setAudioWasPlayedGoogleAnalyticsEventSent(true)
        return window.trebbleAnalyticsHelper.trackEvent('Player Events', "CapsuleSetWasPlayed", "Capsule set was played");
    }
    if (this.getPlayerType() == PLAYER_TYPES.SONG_SET_PLAYER) {
        this._setAudioWasPlayedGoogleAnalyticsEventSent(true)
        return window.trebbleAnalyticsHelper.trackEvent('Player Events', "SongSetWasPlayed", "Song set was played");
    }
    if (this.getPlayerType() == PLAYER_TYPES.SIMPLE_PLAYER) {
        this._setAudioWasPlayedGoogleAnalyticsEventSent(true)
        return window.trebbleAnalyticsHelper.trackEvent('Player Events', "SimplePlayerWasPlayed", "Simple player was played");
    }
}
}
},

_getplayableUrlFromArray: function(urlArray) {
for (const i in urlArray) {
const url = urlArray[i];
if (Utils.getInstance().isLocalMediaFileUri(url) || Utils.getInstance().isHttpOrHttpsUrl(url)) {
    return url;
}
}
return null;
},

_getMediaAndMediaType: function(songToLoad, playableUrl, playbackCausedByInvalidUrl, useFallbackMediaObject, playFromYoutube) {
let media = null;
    if (playFromYoutube) {
media = this._loadAudioUsingYoutubeMedia(songToLoad, playbackCausedByInvalidUrl);
return {
    "mediaType": MEDIA_TYPE.YOUTUBE,
    "media": media,
    "showYoutuveVideo": true
};
}
if (playableUrl && !playbackCausedByInvalidUrl) {
if (window.waitForCordovaToLoad && !useFallbackMediaObject) {
    media = this._loadAudioUsingPhonegapMediaDefault(songToLoad, playableUrl);
    return {
        "mediaType": MEDIA_TYPE.PHONEGAP,
        "media": media,
        "showYoutuveVideo": false
    };
} else {
    media = this._loadAudioUsingSoundManagerMedia(songToLoad, playableUrl, playbackCausedByInvalidUrl, useFallbackMediaObject);
    return {
        "mediaType": MEDIA_TYPE.PHONEGAP,
        "media": media,
        "showYoutuveVideo": false
    };
}
}
if (Utils.getInstance().isSoundcloudUri(songToLoad.get("uri")) && !playbackCausedByInvalidUrl) {
if (window.waitForCordovaToLoad) {
    playableUrl = this._getSoundcloudPlayableUrl(songToLoad);
    if (!useFallbackMediaObject) {
        media = this._loadAudioUsingPhonegapMediaDefault(songToLoad, playableUrl);
        return {
            "mediaType": MEDIA_TYPE.PHONEGAP,
            "media": media,
            "showYoutuveVideo": false
        };
    } else {
        media = this._loadAudioUsingSoundManagerMedia(songToLoad, playableUrl, playbackCausedByInvalidUrl, useFallbackMediaObject);
        return {
            "mediaType": MEDIA_TYPE.HTML5_MEDIA,
            "media": media,
            "showYoutuveVideo": false
        };
    }
} else {
    media = this._loadAudioUsingSoundcloudMedia(songToLoad, playbackCausedByInvalidUrl);
    return {
        "mediaType": MEDIA_TYPE.SOUNDCLOUD,
        "media": media,
        "showYoutuveVideo": false
    };
}
}

media = this._loadAudioUsingYoutubeMedia(songToLoad, playbackCausedByInvalidUrl);
return {
"mediaType": MEDIA_TYPE.YOUTUBE,
"media": media,
"showYoutuveVideo": true
};

},

_loadSong: function(songModel, playFromYoutube, playbackCausedByInvalidUrl, useFallbackMediaObject) {
    let media = null;
if (songModel) {
this._onSongLoading(songModel);
const findMatchingPlayableUrlIfApplicablePromise = this._getSongModelAudioType(songModel) != Utils.getInstance().getAudioTypes().SONG ? RSVP.Promise.resolve(songModel): this._findMatchingSoundcloudUriIfApplicable(songModel, playFromYoutube, playbackCausedByInvalidUrl);
return findMatchingPlayableUrlIfApplicablePromise.then((function(songToLoad) {
    return new RSVP.Promise((function(resolve, reject) {
        try {
            if (songToLoad) {
                if (this._loadedSong) {
                    this.stopListening(this._loadedSong);
                }
                const songUri = songToLoad.get("uri");
                this.listenTo(songToLoad, "change", this._onCurrentSongInfoChanged);
                this._updateSubscriptionInfoIfApplicable(songUri)
                this._stopToSoundCloudMediaEventsIfApplicable();
                this._loadedSong = songToLoad;
                this.set("loadedSongDuration", songToLoad.get("duration"));
                
                let mediaType = null;
                this._currentSongLiveLikeCounter = 0;
                this._currentSongAlbumArtBase64 = null;
                this._currentSongAlbumArtBase64Blur = null;
                this.set("numberOfSecondsPlaying", 0);
                this.set("nextSongPreloaded", false);
                this.set("historySaved", false);
                this.set("playHistorySaved", false);
                let showYoutuveVideo = false;
                
                window._playerLoadedSongUri = songUri;
                window._playerLoadedSongModel = songToLoad;
                const suggestedUri = Utils.getInstance().getSuggestedUri(songToLoad);
                const playableUrl = this._getplayableUrlFromArray([suggestedUri, songUri]);
                const mediaInfo = this._getMediaAndMediaType(songToLoad, playableUrl, playbackCausedByInvalidUrl, useFallbackMediaObject, playFromYoutube);
                media = mediaInfo.media;
                mediaType = mediaInfo.mediaType;
                showYoutuveVideo = mediaInfo.showYoutuveVideo;

                media._songModel = songToLoad;
                this._listenToMediaEvents(media, mediaType);
                this.set("media", media);
                this.set("mediaType", mediaType);
                this.trigger("songChanged");
                if (showYoutuveVideo) {
                    this.trigger("showYoutubeVideo");
                } else {
                    this.trigger("hideYoutubeVideo");
                }
                if (!playbackCausedByInvalidUrl) {
                    //Do not update cover art if the song is try to be reload because uri
                    //is broken or something like. This avoid reusing bandwith 
                    //to download the same image
                    this.updateCoverArts();
                }

            }
            this._onSongFinishLoading(songToLoad);
            resolve(media);
        } catch (error) {
            this._onSongFinishLoading(songToLoad);
            reject(error);
        }

    }).bind(this));
}).bind(this))
} else {
console.warn("No Song To Load!!!");
return RSVP.Promise.resolve();
}

},

_loadAudioUsingPhonegapMediaDefault: function(songToLoad, suggestedUri) {
let uri = null;
if (suggestedUri) {
uri = suggestedUri;
} else {
const songUri = songToLoad.get("uri");
uri = songUri;
if (!Utils.getInstance().isLocalMediaFileUri(songUri) && !Utils.getInstance().isHttpOrHttpsUrl(songUri) && suggestedUri) {
    uri = suggestedUri;
}
}
const onSuccessBinded = (this.onSuccess).bind(this);
const onErrorBinded = (this.onError).bind(this);
const onStatusBinded = (this.onStatus).bind(this);
const media = new window.Media(uri,
onSuccessBinded, onErrorBinded,
onStatusBinded);
media.successCallback.media = media;
media.errorCallback.media = media;
media.statusCallback.media = media;
onSuccessBinded.media = media;
onErrorBinded.media = media;
onStatusBinded.media = media;
media._getDurationFromCordova = media.getDuration.bind(media);
media.getDuration = (function() {
//since  getDuration From the Media object from cordova returns time in seconds compare to HTML5 which returns time in millisec , let convert this in millisec
const durationInSec = this._getDurationFromCordova();
if (durationInSec == -1) {
    return durationInSec;
} else {
    return durationInSec * 1000;
}
}).bind(media)
return media;

},



/*_loadAudioUsingSoundManagerMediaOld: function(songToLoad, suggestedUri, playbackCausedByInvalidUrl, useFallbackMediaObject) {
let uri = null;
if (suggestedUri) {
uri = suggestedUri;
} else {
let songUri = songToLoad.get("uri");
uri = songUri;
if (!Utils.getInstance().isLocalMediaFileUri(songUri) && !Utils.getInstance().isHttpOrHttpsUrl(songUri) && suggestedUri) {
    uri = suggestedUri;
}
}

let soundManagerMediaParams = {};
soundManagerMediaParams.uri = uri;
soundManagerMediaParams.onSuccess = (this.onSuccess).bind(this);
soundManagerMediaParams.onError = (this.onError).bind(this);
soundManagerMediaParams.onStatus = (this.onStatus).bind(this);
soundManagerMediaParams.handlerContext = this;
if (!playbackCausedByInvalidUrl) {
//provide handler to replay using a different link in case it fails
soundManagerMediaParams.onFailedLoading = (this.onFailedLoadingUri).bind(this);
} else {
//since it is replaying using  a different link, just the media object handle it
//chances are it will just act like the song finished playing
}
const media = new SoundManagerMedia(soundManagerMediaParams);
media.isUsedAsFallback = useFallbackMediaObject;
soundManagerMediaParams.onSuccess.media = media;
soundManagerMediaParams.onError.media = media;
soundManagerMediaParams.onStatus.media = media;
return media;

},*/

_loadAudioUsingSoundManagerMedia: function(songToLoad, suggestedUri, playbackCausedByInvalidUrl, useFallbackMediaObject) {
    let uri = null;
    if (suggestedUri) {
    uri = suggestedUri;
    } else {
    let songUri = songToLoad.get("uri");
    uri = songUri;
    if (!Utils.getInstance().isLocalMediaFileUri(songUri) && !Utils.getInstance().isHttpOrHttpsUrl(songUri) && suggestedUri) {
        uri = suggestedUri;
    }
    }
    let context = {
        "self": this
    };
    let mediaId = Utils.getInstance().generateRandomId();
    context.mediaId = mediaId;

    let soundManagerMediaParams = {};
    soundManagerMediaParams.uri = uri;
    soundManagerMediaParams.onSuccess = (this.onSuccessNew).bind(context);
    soundManagerMediaParams.onError = (this.onErrorNew).bind(context);
    soundManagerMediaParams.onStatus = (this.onStatusNew).bind(context);
    soundManagerMediaParams.handlerContext = this;
    if (!playbackCausedByInvalidUrl) {
    //provide handler to replay using a different link in case it fails
    soundManagerMediaParams.onFailedLoading = (this.onFailedLoadingUri).bind(this);
    } else {
    //since it is replaying using  a different link, just the media object handle it
    //chances are it will just act like the song finished playing
    }
    const media = new SoundManagerMedia(soundManagerMediaParams);
    media.isUsedAsFallback = useFallbackMediaObject;
    this._registerMediaCallbacks(mediaId,  soundManagerMediaParams.onSuccess, soundManagerMediaParams.onError, soundManagerMediaParams.onStatus);

    return media;
    
    },

/*_loadAudioUsingYoutubeMediaOld: function(songToLoad, playbackCausedByInvalidUrl) {
    
const media = null;
const youtubeMediaParams = {};
youtubeMediaParams.directURL = songToLoad.get("youtubeDirectUrl");
youtubeMediaParams.songModel = songToLoad;
youtubeMediaParams.videoPlaceHolderEl = document.getElementById("youtubeVideoLink");
youtubeMediaParams.emptyPlaceHolder = false;
youtubeMediaParams.uriBroken = playbackCausedByInvalidUrl;
youtubeMediaParams.onSuccess = (this.onSuccess).bind(this);
youtubeMediaParams.onError = (this.onError).bind(this);
youtubeMediaParams.onStatus = (this.onStatus).bind(this);
youtubeMediaParams.handlerContext = this;
if (!playbackCausedByInvalidUrl) {
//provide handler to replay using a different link in case it fails
youtubeMediaParams.onFailedLoading = (this.onFailedLoadingUri).bind(this);
} else {
//since it is replaying using  a different link, just the media object handle it
//chances are it will just act like the song finished playing
}
if (window.device.platform != "browser") {
media = new YoutubeMedia(youtubeMediaParams);
} else {
media = new YoutubeBrowserMedia(youtubeMediaParams);
}
youtubeMediaParams.onSuccess.media = media;
youtubeMediaParams.onError.media = media;
youtubeMediaParams.onStatus.media = media;
return media;

},*/

_loadAudioUsingYoutubeMedia: function(songToLoad, playbackCausedByInvalidUrl) {
    let context = {
        "self": this
    };
    let mediaId = Utils.getInstance().generateRandomId();
    context.mediaId = mediaId;

    let media = null;
    const youtubeMediaParams = {};
    youtubeMediaParams.directURL = songToLoad.get("youtubeDirectUrl");
    youtubeMediaParams.songModel = songToLoad;
    youtubeMediaParams.videoPlaceHolderEl = document.getElementById("youtubeVideoLink");
    youtubeMediaParams.emptyPlaceHolder = false;
    youtubeMediaParams.uriBroken = playbackCausedByInvalidUrl;
    youtubeMediaParams.onSuccess = (this.onSuccessNew).bind(context);
    youtubeMediaParams.onError = (this.onErrorNew).bind(context);
    youtubeMediaParams.onStatus = (this.onStatusNew).bind(context);
    youtubeMediaParams.handlerContext = this;
    if (!playbackCausedByInvalidUrl) {
    //provide handler to replay using a different link in case it fails
    youtubeMediaParams.onFailedLoading = (this.onFailedLoadingUri).bind(this);
    } else {
    //since it is replaying using  a different link, just the media object handle it
    //chances are it will just act like the song finished playing
    }
    if (window.device.platform != "browser") {
    media = new YoutubeMedia(youtubeMediaParams);
    } else {
    media = new YoutubeBrowserMedia(youtubeMediaParams);
    }
    this._registerMediaCallbacks(mediaId,  youtubeMediaParams.onSuccess, youtubeMediaParams.onError, youtubeMediaParams.onStatus);
    return media;
    
    },

_getSoundcloudPlayableUrl: function(songToLoad) {
    let soundcloudUriWithPrefix = songToLoad.get("uri");
if (Utils.getInstance().isSuggestedUriAvaliable(songToLoad)) {
soundcloudUriWithPrefix = Utils.getInstance().getSuggestedUri(songToLoad)
}
const soundcloudPrefix = "soundcloud:"
const trackId = soundcloudUriWithPrefix.substring(soundcloudPrefix.length);
return window.trebble.usedProtocolPrefix + "api.soundcloud.com/tracks/" + trackId + "/stream?client_id=e1ddc5a48279cd6d15c35595c26c7b05";
},

_loadAudioFromSoundcloudUsingPhonegapMedia: function(songToLoad, playbackCausedByInvalidUrl) {
    let media = null;
//const soundcloudMediaParams = {};
let soundcloudUriWithPrefix = songToLoad.get("uri");
if (Utils.getInstance().isSuggestedUriAvaliable(songToLoad)) {
soundcloudUriWithPrefix = Utils.getInstance().getSuggestedUri(songToLoad)
}
const soundcloudPrefix = "soundcloud:"
const trackId = soundcloudUriWithPrefix.substring(soundcloudPrefix.length);
const uri = window.trebble.usedProtocolPrefix + "api.soundcloud.com/tracks/" + trackId + "/stream?client_id=e1ddc5a48279cd6d15c35595c26c7b05"
const onSuccessBinded = (this.onSuccess).bind(this);
const onErrorBinded = (this.onError).bind(this);
const onStatusBinded = (this.onStatus).bind(this);
media = new window.Media(uri,
onSuccessBinded, onErrorBinded,
onStatusBinded);
media.successCallback.media = media;
media.errorCallback.media = media;
media.statusCallback.media = media;
if (!playbackCausedByInvalidUrl) {
//provide handler to replay using a different link in case it fails
media.onFailedLoading = (this.onFailedLoadingUri).bind(this);
} else {
//since it is replaying using  a different link, just the media object handle it
//chances are it will just act like the song finished playing
}
return media;
},

/*_loadAudioUsingSoundcloudMediaOld: function(songToLoad, playbackCausedByInvalidUrl) {
const media = null;
const soundcloudMediaParams = {};
const soundcloudUriWithPrefix = songToLoad.get("uri");
if (Utils.getInstance().isSuggestedUriAvaliable(songToLoad)) {
soundcloudUriWithPrefix = Utils.getInstance().getSuggestedUri(songToLoad)
}
const soundcloudPrefix = "soundcloud:"
soundcloudMediaParams.uri = soundcloudUriWithPrefix.substring(soundcloudPrefix.length);
soundcloudMediaParams.onSuccess = (this.onSuccess).bind(this);
soundcloudMediaParams.onError = (this.onError).bind(this);
soundcloudMediaParams.onStatus = (this.onStatus).bind(this);
soundcloudMediaParams.handlerContext = this;
if (!playbackCausedByInvalidUrl) {
//provide handler to replay using a different link in case it fails
soundcloudMediaParams.onFailedLoading = (this.onFailedLoadingUri).bind(this);
} else {
//since it is replaying using  a different link, just the media object handle it
//chances are it will just act like the song finished playing
}
media = new SoundcloudMedia(soundcloudMediaParams);
media._externalPageUrl = Utils.getInstance().getSuggestedExternalSongPage(songToLoad);
soundcloudMediaParams.onSuccess.media = media;
soundcloudMediaParams.onError.media = media;
soundcloudMediaParams.onStatus.media = media;
return media;
},*/

_loadAudioUsingSoundcloudMedia: function(songToLoad, playbackCausedByInvalidUrl) {
    let context = {
        "self": this
    };
    let mediaId = Utils.getInstance().generateRandomId();
    context.mediaId = mediaId;

    let media = null;
    const soundcloudMediaParams = {};
    let soundcloudUriWithPrefix = songToLoad.get("uri");
    if (Utils.getInstance().isSuggestedUriAvaliable(songToLoad)) {
    soundcloudUriWithPrefix = Utils.getInstance().getSuggestedUri(songToLoad)
    }
    const soundcloudPrefix = "soundcloud:"
    soundcloudMediaParams.uri = soundcloudUriWithPrefix.substring(soundcloudPrefix.length);
    soundcloudMediaParams.onSuccess = (this.onSuccessNew).bind(context);
    soundcloudMediaParams.onError = (this.onErrorNew).bind(context);
    soundcloudMediaParams.onStatus = (this.onStatusNew).bind(context);
    soundcloudMediaParams.handlerContext = this;
    if (!playbackCausedByInvalidUrl) {
    //provide handler to replay using a different link in case it fails
    soundcloudMediaParams.onFailedLoading = (this.onFailedLoadingUri).bind(this);
    } else {
    //since it is replaying using  a different link, just the media object handle it
    //chances are it will just act like the song finished playing
    }
    media = new SoundcloudMedia(soundcloudMediaParams);
    media._externalPageUrl = Utils.getInstance().getSuggestedExternalSongPage(songToLoad);
    this._registerMediaCallbacks(mediaId,  soundcloudMediaParams.onSuccess, soundcloudMediaParams.onError, soundcloudMediaParams.onStatus);

    return media;
    },


playNextSongInPlaylist: function(isManual, skipReasonId) {
//console.log("playNextSongInPlaylist 1");
let saveHistoryToServerPromise = RSVP.Promise.resolve();
if (isManual) {
try {
    saveHistoryToServerPromise = this.saveHistoryToServer("skip", null, null, skipReasonId);
} catch (error) {
    console.error("An error occured while saving skip history to server.Error:" + error);
}
}
this.stopAudioAndRelease(true);
if (this.getPlaylist()) {
return saveHistoryToServerPromise.then((function() {
    //console.log("playNextSongInPlaylist 2");
    return this.getQueue().moveCursorToNextPositionPromise()
}).bind(this)).then((function(songAtNewCursorPosition) {
    //console.log("playNextSongInPlaylist 3");
    if (songAtNewCursorPosition || songAtNewCursorPosition == 0) {
        return this._loadSong(songAtNewCursorPosition);
    } else {
        return null;
    }
}).bind(this)).then((function(newMediaCreated) {
    //console.log("playNextSongInPlaylist 4");
    if (newMediaCreated && newMediaCreated == this.get("media")) { //the last comaprison is done to avoid playing a song mistakenly when the user skips quickly
        return this.playAudio();
    } else {
        //probably reach the end of the queue
        this.trigger("onQueueFinishedPlaying");
        this.triggerEventFromDocument("onQueueFinishedPlaying");
        if (window.trebbleAnalyticsHelper) {
            window.trebbleAnalyticsHelper.trackEvent('Player Events', "onQueueFinishedPlaying", "On Queue Finished Playing");
        }

        return this.pauseAudio();
    }
}).bind(this)).catch(function(error) {
    //console.log("playNextSongInPlaylist 5.Error"+error);
    console.error(error);
    window.alertErrorMessage(window.getI18n(ti18n, "ERROR_PLAYING_CURRENT_SONG"));
});
} else {
return saveHistoryToServerPromise.then((function() {
    return false;
}).bind(this)).catch((function(error) {
    console.error(error);
    return false;
}).bind(this));
}

},

/*playCurrentSongInPlaylistFromStart : function() {
this.stopAudioAndRelease(true);
if (this.getPlaylist()) {
return this.getQueue().getSongOnCurrentCursorPositionPromise().then((function(songAtCurentCursorPosition){
    if(songAtCurentCursorPosition)
    {
        return this._playSongAtCursonPosition(songAtCurentCursorPosition, false);
    }else{
        console.warn("No song to play in queue at current index position");
    }
}).bind(this)).catch(function(error){
window.alertErrorMessage("error getting current Song.Error: "+error);
});     
}else{
return RSVP.Promise.resolve();
}

},*/

isPreviousCommandAllowed : function(){
return this.getPlaylist() && this.getPlayerType() != PLAYER_TYPES.TREBBLE_PLAYER && this.getPlayerType() != PLAYER_TYPES.CAPSULE_FEED_PLAYER && this.getPlayerType() != PLAYER_TYPES.CATEGORY_SHORTCAST_RADIO
},

playPreviousSongInPlaylist: function(/*isManual*/) {

if (this.isPreviousCommandAllowed()) {
this.stopAudioAndRelease(true);
return this.getQueue().moveCursorToPreviousPositionPromise().then((function(songAtNewCursorPosition) {
    if (songAtNewCursorPosition || songAtNewCursorPosition == 0) {
        return this._loadSong(songAtNewCursorPosition);
    } else {
        return null;
    }
}).bind(this)).then((function(newMediaCreated) {
    if (newMediaCreated && newMediaCreated == this.get("media")) { //the last comaprison is done to avoid playing a song mistakenly when the user skips quickly
        return this.playAudio();
    } else {
        //probably reach the begining of the queue
        return this.pauseAudio();
    }
}).bind(this)).catch(function(error) {
    console.error(error);
    window.alertErrorMessage(window.getI18n(ti18n, "ERROR_PLAYING_CURRENT_SONG"));
});
} else {
return RSVP.Promise.resolve();
}

},

playAudioPreview: function(previewInfo, onSuccessPreviewPlayCallback, onFailurePreviewPlayCallback) {
if (previewInfo) {
const shouldSongBeResumeAfterPreviewEnd = this.isPlaying();
this.pauseAudio(true);
this._applyIOSQuirkForHTML5AudioIfApplicable();
this.set("shouldSongBeResumeAfterPreviewEnd", shouldSongBeResumeAfterPreviewEnd);
this.set("previewInfo", previewInfo);
return this._loadPreviewSong(onSuccessPreviewPlayCallback, onFailurePreviewPlayCallback).then((function(mediaPreview) {
    if (mediaPreview && mediaPreview == this.get("mediaPreview")) { //the last comaprison is done to avoid playing a song mistakenly when the user skips quickly
        return this.playCurrentAudioPreview();
    } else {
        this.resumePlayAfterPreview();
    }
}).bind(this));
} else {
return RSVP.Promise.resolve();
}
},

getAudioPreviewURL : function(){
return this.get("previewInfo")
},

getAudioMediaURL : function(){
if(this.getMedia()){
if(this.getMedia()  instanceof window.Media){
    return this.getMedia().src;
}
if(this.getMedia()  instanceof SoundManagerMedia){
    return this.getMedia().src;
}
if(this.getMedia()  instanceof SoundcloudMedia){
    return this.getMedia().src;
}
return null;
}
},

pauseAudio: function(doNotLooseAndroidAudioFocus, underliningAudioObjectUnavailable) {
if (this.getMedia() && this.get("isPlaying")) {
if (!underliningAudioObjectUnavailable) {
    this.getMedia().pause();
}
this._stopMusicPlaybackTimerIfApplicable();
}
this._setPlaying(false);
if (this.getPlaylist()) {
this.getPlaylist().setPlaying(false);
}
this.stopAudioPreviewAndRelease(doNotLooseAndroidAudioFocus);
this.triggerEventFromDocument("audioPaused");
if (!doNotLooseAndroidAudioFocus) {

return this._abandonAudioFocus();
}

},

/* : function(doNotLooseAndroidAudioFocus) {
if (this.getMediaPreview() && this.get("isPreviewPlaying")) {
this.getMediaPreview().pause();
this._setPreviewPlaying(false);
if(!doNotLooseAndroidAudioFocus)
{
    return this._abandonAudioFocus();
}           
}
},*/



stopAudioAndRelease: function(doNotLooseAndroidAudioFocus, underliningAudioObjectUnavailable) {
this.set("isManuallyStopping", true);
let currentMedia ;
if (this.getMedia() && this.get("isPlaying")) { //&& this.getMediaStatus() != 4&& this.getMediaStatus() != -1) {
try {
if (!underliningAudioObjectUnavailable) {
    currentMedia = this.getMedia();

    if (currentMedia && (currentMedia instanceof window.Media || currentMedia.release)) {

        currentMedia.stop();
        currentMedia.release();
    } else {
        currentMedia.stop();
    }
}
this.set("previousMediaType", this.get("mediaType"));
this.set("media", null);
window.log("Setting media to null");
} catch (e) {
console.error("Something when wrong when stopping/releasing the current media. Error:" + e);
}
} else {
if (this.getMedia()) {
try {
    currentMedia = this.getMedia();
    if (currentMedia && (currentMedia instanceof window.Media || currentMedia.release)) {
        currentMedia.release();
        this.set("previousMediaType", this.get("mediaType"));
        this.set("media", null);
        window.log("Setting media to null");
    }
} catch (e) {
    console.error("Something when wrong when stopping/releasing the current media. Error:" + e);
}
}
}
//this.set("isManuallyStopping",false);


if (this.getPlaylist() && !doNotLooseAndroidAudioFocus) {
this.getPlaylist().setPlaying(false);
}



this.stopAudioPreviewAndRelease(doNotLooseAndroidAudioFocus);
this.triggerEventFromDocument("audioStopped");

if (!doNotLooseAndroidAudioFocus) {
return this._abandonAudioFocus();
}
},


releaseCurrentMediaIfApplicable: function(doNotLooseAndroidAudioFocus) {
if (this.getMedia() && this.get("isPlaying")) {
this.stopAudioAndRelease(doNotLooseAndroidAudioFocus);
} else {
try {
    const currentMedia = this.getMedia();
    if (currentMedia && (currentMedia instanceof window.Media || currentMedia.release)) {
        currentMedia.release();
        this.set("previousMediaType", this.get("mediaType"));
        this.set("media", null);
        window.log("Setting media to null");
    }
} catch (e) {
    console.error("Something when wrong when releasing the current media. Error:" + e);
}
}
this.releaseCurrentAudioPreviewIfApplicable(doNotLooseAndroidAudioFocus);

if (!doNotLooseAndroidAudioFocus) {
return this._abandonAudioFocus();
}
},

_addPreviewToListOfPreviewsToBeStopped: function(mediaPreview) {
if (mediaPreview) {
    let previewsToBeStopped = this.get("previewsToBeStopped");
if (!previewsToBeStopped) {
    previewsToBeStopped = {};
    this.set("previewsToBeStopped", previewsToBeStopped);
}
previewsToBeStopped[mediaPreview.id] = mediaPreview;
}

},

_addCurrentPreviewToListOfPreviewsToBeStopped: function() {
this._addPreviewToListOfPreviewsToBeStopped(this.get("mediaPreview"));

},

_isPreviewNeedToBeStopped: function(mediaPreview) {
if (mediaPreview) {
const previewsToBeStopped = this.get("previewsToBeStopped");
return !!previewsToBeStopped[mediaPreview.id];
} else {
return false;
}
},

_isCurrentPreviewNeedToBeStopped: function() {
return this._isPreviewNeedToBeStopped(this.get("mediaPreview"));
},

_removePreviewToListOfPreviewsToBeStopped: function(mediaPreview) {
if (mediaPreview) {
    let previewsToBeStopped = this.get("previewsToBeStopped");
if (!previewsToBeStopped) {
    previewsToBeStopped = {};
    this.set("previewsToBeStopped", previewsToBeStopped);
}
previewsToBeStopped[mediaPreview.id] = null;
delete previewsToBeStopped[mediaPreview.id];
return true;

}
},

_removeCurrentPreviewToListOfPreviewsToBeStopped: function(/*previewInfo*/) {
return this._removePreviewToListOfPreviewsToBeStopped(this.get("previewInfo"));
},

stopAudioPreviewAndRelease: function(doNotLooseAndroidAudioFocus) {
this.set("isManuallyStoppingPreview", true);
if (this.getMediaPreview() && this.get("isPreviewPlaying") && this.getMediaPreviewStatus() != 4 && this.getMediaPreviewStatus() != -1) {
const mediaPreviewRef = this.getMediaPreview();
if (mediaPreviewRef && (mediaPreviewRef instanceof window.Media || mediaPreviewRef.release)) {
    mediaPreviewRef.stop();
    mediaPreviewRef.release();
} else {
    mediaPreviewRef.stop();
}
this.set("isPreviewPlaying", false);
this.set("mediaPreview", null);
this.trigger("songPreviewUnLoaded");
} else {
if (this.getMediaPreview()) {
    //Preview is probably loading so add this preview to the list of preview to be stopped while loaded
    this._addCurrentPreviewToListOfPreviewsToBeStopped();
}

}
this.set("isManuallyStoppingPreview", false);
if (!doNotLooseAndroidAudioFocus) {
return this._abandonAudioFocus();
}
},

releaseCurrentAudioPreviewIfApplicable: function(doNotLooseAndroidAudioFocus) {
if (this.getMediaPreview() && this.get("isPreviewPlaying") && this.getMediaPreviewStatus() != 4 && this.getMediaPreviewStatus() != -1) {
this.stopAudioPreviewAndRelease(doNotLooseAndroidAudioFocus);
} else {
if (this.getMediaPreview()) {
    try {
        const mediaPreviewRef = this.getMediaPreview();
        if (mediaPreviewRef && (mediaPreviewRef instanceof window.Media || mediaPreviewRef.release)) {
            mediaPreviewRef.release();

            this.set("isPreviewPlaying", false);
            this.set("mediaPreview", null);
            this.trigger("songPreviewUnLoaded");
        }
    } catch (e) {
        console.error("Something when wrong when releasing the current media preview. Error:" + e);
    }
}

}
if (!doNotLooseAndroidAudioFocus) {
return this._abandonAudioFocus();
}
},

/*  getCurrentSongPlayingPosition : function() {
this.getMedia().getCurrentPosition(
// success callback
function(position) {
    if (position > -1) {
        window.log((position) + " sec");
    }
},

// error callback
function(e) {
    window.log("Error getting pos=" + e);
});
},*/

_updateLockScreen: function(/*isPlaying*/) {
return this.getCurrentSong().then((function(currentSong) {
if (currentSong) {
    const songTitle = currentSong.get("title");
    const artistName = currentSong.get("artist");
    const albumJson = currentSong.get("albumJson");
    let mp3AlbumId = parseInt(currentSong.get("linkedAlbumId"));
    let albumURL = null;

    if (isNaN(mp3AlbumId)) {
        mp3AlbumId = -1;
    }
    let coverArtBase64 = this._currentSongAlbumArtBase64;
    if (!coverArtBase64) {
        coverArtBase64 = "";
    }
    if (window.device && window.device.platform == "browser" && currentSong.getAlbumArtImages()) {
        if (currentSong.getAlbumArtImages() && currentSong.getAlbumArtImages().large) {
            albumURL = currentSong.getAlbumArtImages().large.url;
        }
    }
    if (!albumURL && currentSong.get('albumJson')) {
        albumURL = currentSong.get('albumJson').albumArtURL;
    }
    if (!albumURL && currentSong.get("albumArtUrl")) {
        albumURL = currentSong.get("albumArtUrl");
    }
    if (!albumURL && this.getDefaultAlbumArtUrl()) {
        albumURL = this.getDefaultAlbumArtUrl();
    }
    if (!albumURL) {
        albumURL = Utils.getInstance().getDefaultTrebbleSquareLogoURL();
    }
    if (!coverArtBase64 && albumURL) {
        coverArtBase64 = albumURL;
    }
    const params = [songTitle, artistName, albumJson.album, mp3AlbumId, coverArtBase64, albumURL, this.get("isPlaying")];
    /*const queueContext = this.getQueueContext();
    const isSongSimpleSet = Utils.getInstance().isContextTypeSimpleSongSet(queueContext);
    const isCapsuleFeed = Utils.getInstance().isContextTypeCapsuleFeed(queueContext);
    const isCapsuleSimpleSet = Utils.getInstance().isContextTypeSimpleCapsuleSet(queueContext);
    const isCategoryShortcastRadioPlaylist = Utils.getInstance().isContextTypeCategoryShortcastRadio(queueContext);*/
    //if(!(window.device && window.device.platform == "browser" && !isPlaying) && !isSongSimpleSet && !isCapsuleSimpleSet){
        setTimeout((function() {
            CordovaHelper.getInstance().executePluginOperation("MusicFileReaderPlugin", "updateMetaData", params);
        }).bind(this), 0)

        //}
    }
}).bind(this));
},

_getMediaCurentPostionUpateFunction: function() {
if (!this.getMedia()) {
clearInterval(this.getMediaCurentPostionUpateFunctionId());
} else {
const onPositionSuccessfullyRetievedFunction = (function(position) {
    const previousSongPosition = this.get("previousSongPostion");
    if (!previousSongPosition && position > 0) {
        //It is necessary to update the lockscreen as updating the lock screen might not work is the audio has not started playing and it is still buffering
        //Triggering it when the previous value was 0 or null the new value is bigger than 0 (song is actually playing), ensure that lock screen will get updated

        //this._updateLockScreen();

    }
    //window.log("previousSongPostion position: "+previousSongPosition);
    //window.log("Song position: "+position);
    if (previousSongPosition && position && (position - previousSongPosition) > 0) {
        this.set("numberOfSecondsPlaying", this.get("numberOfSecondsPlaying") + (position - previousSongPosition));
    }
    if (window.trebble.config.prefetchNextSongUrl && !this.get("nextSongPreloaded") && this.get("loadedSongDuration") > 0) {
        //const numberOfSecondLeftForSongToEnd = this.get("loadedSongDuration") ? (this.get("loadedSongDuration") / 1000 - position) : 0;
        //if(numberOfSecondLeftForSongToEnd <= window.trebble.config.numberOfSecondsLeftOnSongForPrefetchNextSong){
            if (position > window.trebble.config.numberOfSecondsElapsedForPrefetchNextSong) {
                this._preloadNextSong(0, 0);
                this.set("nextSongPreloaded", true);
            }
        }
        //window.log("Song numberOfSecondsPlaying: "+this.get("numberOfSecondsPlaying"));
        this.set("previousSongPostion", position);
        if (!this.get("historySaved") && this.get("numberOfSecondsPlaying") >= window.trebble.config.secondsOfPLayingRequiredForScrobble) {
            this.saveHistoryToServer("play", null, this.get("numberOfSecondsPlaying"));
            this.set("playHistorySaved", true);
            this.set("historySaved", true);
        }
        this.trigger("songCurrentPositionChanged", position);
        this.getCurrentSongDuration().then((function(duration) {
            if (duration && duration != -1) {
                this.self.triggerEventFromDocument("audioElapsedTimeChanged", {
                    "detail": {
                        "position": this.position * 1000,
                        "duration": duration
                    }
                });
            }
        }).bind({
            "self": this,
            "position": position
        }));

        if (!this.get("isPlaying")) {
            clearInterval(this.getMediaCurentPostionUpateFunctionId());
            this.set("mediaCurentPostionUpateFunctionId", null);
        }
    }).bind(this);
const onPositionFailedToBeRetievedFunction = (function(e) {
window.log("Error getting pos=" + e);
clearInterval(this.getMediaCurentPostionUpateFunctionId());
this.set("mediaCurentPostionUpateFunctionId", null);
}).bind(this);
this.getMedia().getCurrentPosition(onPositionSuccessfullyRetievedFunction, onPositionFailedToBeRetievedFunction);

}

},

_preloadNextSong: function(increment, numberOfElementInQueueToLoadUntilFindAnAudio) {
if (increment >= numberOfElementInQueueToLoadUntilFindAnAudio) {
return RSVP.Promise.resolve();
}
let nextSongModel = null;
let soundcloudUriBroken = false;
this.getNextSong(increment).then((function(nextSong) {
nextSongModel = nextSong;
if (nextSong) {
    const songUri = nextSongModel.get("uri");
    if (Utils.getInstance().isSoundcloudUri(songUri)) {
        //check that the url is not broken
        const soundcloudTrackId = Utils.getInstance().getSoundcloudTrackUriFromTrebbleSongUri(songUri);
        return this._getSoundcloudSearchHelperInstance().then((function(soundcloudSearchHelperInstance) {
            return soundcloudSearchHelperInstance.getSoundcloudTrackInfo(soundcloudTrackId).then(function(trackInfo) {
                if (!trackInfo) {
                    soundcloudUriBroken = true;
                } else {
                    //preload soundcloud uri
                    //if(device.platform != "iOS"){
                        //  SC.stream("/tracks/"+soundcloudTrackId, function(){window.log("soundcloud done being prefetch")});
                        //}
                        //commenting this out because loading the track force the current song playing to stop playing if the song is a souncloud song
                    }
                }).catch(function(error) {
                    console.error(error);
                    soundcloudUriBroken = true;
                });
            }).bind(this));
    }
}
}).bind(this)).then((function() {
if (nextSongModel && this._getSongModelAudioType(nextSongModel) == Utils.getInstance().getAudioTypes().SONG) {
    return this._findMatchingSoundcloudUriIfApplicable(nextSongModel, false, soundcloudUriBroken);
}
}).bind(this)).then(function() {
if (nextSongModel) {
    const songUri = nextSongModel.get("uri");
    if ((!Utils.getInstance().isLocalMediaFileUri(songUri) && !Utils.getInstance().isHttpOrHttpsUrl(songUri) && !Utils.getInstance().isSoundcloudUri(songUri)) || (soundcloudUriBroken && !Utils.getInstance().isSuggestedUriAvaliable(nextSongModel))) {
        //prefetch youtube direct url
        if (window.device && window.device.platform != "browser") {
            return YoutubeMedia.prototype._retrieveDirectUrlForSongModel.call(YoutubeMedia.prototype, nextSongModel).then(function(directVideoUrl) {
                if (directVideoUrl) {
                    Utils.getInstance().setPrefetchedVideoUrl(nextSongModel, directVideoUrl);
                }
            });
        } else {
            //do not try to load prefetch direct url video
        }
    }
}
}).then((function() {
if (nextSongModel) {
    this._prefetchAudioFileIfApplicable(nextSongModel);
    const songUri = nextSongModel.get("uri");
    if (Utils.getInstance().isEarconUri(songUri) || Utils.getInstance().isOutroUri(songUri) || Utils.getInstance().isIntroUri(songUri) || Utils.getInstance().isGreaterUri(songUri) || Utils.getInstance().isJingleUri(songUri) || Utils.getInstance().isTrebbleGeneratedSpeechUri(songUri)) {
        return this._preloadNextSong(increment + 1, numberOfElementInQueueToLoadUntilFindAnAudio);
    }

}
}).bind(this));
},

seekTo: function(timePercentage) {
//const songDuration = null;
return this.getCurrentSongDuration().then((function(totalSongDuration) {
//songDuration = totalSongDuration;
if (this.getMedia()) {
    this.getMedia().seekTo(timePercentage * totalSongDuration);
}
}).bind(this));
/*return this.getCurrentSong().then((function(currentSong) {
if (!songDuration && currentSong.get("duration")) {
    songDuration = currentSong.get("duration");
}
if (!songDuration && this.get("loadedSongDuration")) {
    songDuration = this.get("loadedSongDuration");
}
if (songDuration && songDuration > 0) {
    const newTimePosition = songDuration * timePercentage;
    if (this.getMedia()) {
        this.getMedia().seekTo(newTimePosition);
    }
}

}).bind(this));*/
},

onSongStartedPlaying: function(songModel) {
this._setPlaying(true);
this._startMusicPlaybackTimer(songModel);
this.trigger("songStartedPlaying", songModel);
const mediCurentPostionUpateFunction = this._getMediaCurentPostionUpateFunction.bind(this);
const mediaCurentPostionUpateFunctionId = setInterval(mediCurentPostionUpateFunction, 100);
this.set("mediaCurentPostionUpateFunctionId", mediaCurentPostionUpateFunctionId);
this._updateLockScreen();
this.getCurrentSongDuration().then((function(duration) {
this._onSongDurationChanged(duration);
}).bind(this));
if (this._isYoutubeVideoLoaded) {
this._showAllVideoEls();
} else {
this._hideAllVideoEls();
}
},

onSongPreviewStartedPlaying: function() {

this._setPreviewPlaying(true);
this.trigger("songPreviewStartedPlaying");
/*const mediPreviewCurentPostionUpateFunction = this._getMediaPreviewCurentPostionUpateFunction.bind(this);
const mediaCurentPostionUpateFunctionId = setInterval(mediPreviewCurentPostionUpateFunction, 1000);
this.set("mediaPreviewCurentPostionUpateFunctionId", mediaCurentPostionUpateFunctionId);*/
},

onSongStopped: function() {
const mediaCurentPostionUpateFunctionId = this.getMediaCurentPostionUpateFunctionId();
if (this.isPlaying()) {
if (mediaCurentPostionUpateFunctionId) {
    clearInterval(mediaCurentPostionUpateFunctionId);
    this.set("mediaCurentPostionUpateFunctionId", null);
}
//this._setPlaying(false);
} else {
if (mediaCurentPostionUpateFunctionId) {
    clearInterval(mediaCurentPostionUpateFunctionId);
    this.set("mediaCurentPostionUpateFunctionId", null);
}
}
this._stopMusicPlaybackTimerIfApplicable();

},

onSongPreviewStopped: function() {
//const mediaCurentPostionUpateFunctionId = this.getMediaCurentPostionUpateFunctionId();
if (this.isPreviewPlaying()) {
/*  if (mediaCurentPostionUpateFunctionId) {
    clearInterval(mediaCurentPostionUpateFunctionId);
    this.set("mediaCurentPostionUpateFunctionId", null);
}*/
//this._setPlaying(false);
} else {
/*if (mediaCurentPostionUpateFunctionId) {
    clearInterval(mediaCurentPostionUpateFunctionId);
    this.set("mediaCurentPostionUpateFunctionId", null);
}*/
}

},


onSongPaused: function() {
const mediaCurentPostionUpateFunctionId = this.getMediaCurentPostionUpateFunctionId();
if (this.isPlaying()) {
if (mediaCurentPostionUpateFunctionId) {
    clearInterval(mediaCurentPostionUpateFunctionId);
    this.set("mediaCurentPostionUpateFunctionId", null);
}
this._setPlaying(false);
} else {
if (mediaCurentPostionUpateFunctionId) {
    clearInterval(mediaCurentPostionUpateFunctionId);
    this.set("mediaCurentPostionUpateFunctionId", null);
}
}
this._stopMusicPlaybackTimerIfApplicable();
this.trigger("songPaused");
},


onSongPreviewPaused: function() {
//const mediaCurentPostionUpateFunctionId = this.getMediaCurentPostionUpateFunctionId();
if (this.isPreviewPlaying()) {
/*if (mediaCurentPostionUpateFunctionId) {
    clearInterval(mediaCurentPostionUpateFunctionId);
    this.set("mediaCurentPostionUpateFunctionId", null);
}*/
this._setPreviewPlaying(false);
} else {
/*if (mediaCurentPostionUpateFunctionId) {
    clearInterval(mediaCurentPostionUpateFunctionId);
    this.set("mediaCurentPostionUpateFunctionId", null);
}*/
}
this.trigger("songPreviewPaused");
},

_getSongModelAudioType : function(songModel){
if(songModel){
const uri = songModel.get("uri");
let songTypeLabel = Utils.getInstance().getAudioTypes().SONG;
const isSongACapsule = Utils.getInstance().isCapsuleUri(uri);
if (isSongACapsule) {
    songTypeLabel = Utils.getInstance().getAudioTypes().CAPSULE;
}
const isSongAJingle = Utils.getInstance().isJingleUri(uri);
if (isSongAJingle) {
    songTypeLabel = Utils.getInstance().getAudioTypes().JINGLE;
}
const isSongAGreater = Utils.getInstance().isGreaterUri(uri);
if (isSongAGreater) {
    songTypeLabel = Utils.getInstance().getAudioTypes().GREATER;
}
const isSongAIntro = Utils.getInstance().isIntroUri(uri);
if (isSongAIntro) {
    songTypeLabel = Utils.getInstance().getAudioTypes().INTRO;
}
const isSongAOutro = Utils.getInstance().isOutroUri(uri);
if (isSongAOutro) {
    songTypeLabel = Utils.getInstance().getAudioTypes().OUTRO;
}
const isSongAEarcon = Utils.getInstance().isEarconUri(uri);
if (isSongAEarcon) {
    songTypeLabel = Utils.getInstance().getAudioTypes().EARCON;
}
const isSongATrebbleGeneratedSpeech = Utils.getInstance().isTrebbleGeneratedSpeechUri(uri);
if (isSongATrebbleGeneratedSpeech) {
    songTypeLabel = Utils.getInstance().getAudioTypes().TREBBLE_GENERATED_SPEECH;
}

return songTypeLabel;
}else{
return null;
}
},

saveHistoryToServer: function(action, weight, playbackElapsedTime, skipReasonId, mediaSourceExternalUrl) {

    let currentSong = null;
let songRefSummary = null;
let schedulingPosition = null;
let context = null;
return this.getCurrentSongRefSummaryIfApplicable().then((function(refSummary) {
songRefSummary = refSummary;
return this.getCurrentSong();
}).bind(this)).then((function(song) {
currentSong = song;
schedulingPosition = this.getQueue()._currentCursorPosition;
context = this.getQueueContext(song);
return this.getPlaylist().getType();
}).bind(this)).then((function(playlistType) {
    let isFixedPlaylist = true;
if (playlistType != Playlist.types.FIXED && playlistType != Playlist.types.AUTOFIXED) {
    isFixedPlaylist = false;
}
const previousSongPlayed = this.get("previousSongPlayed");
let loopHistoryShouldBeSavedToDB = false;
const numberOfTimePreviousSongWasPlayed = this.get("numberOfTimePreviousSongWasPlayed");
if (previousSongPlayed && action == "play") {
    if (previousSongPlayed.get("uri") == currentSong.get("uri")) {
        this.set("numberOfTimePreviousSongWasPlayed", numberOfTimePreviousSongWasPlayed + 1);
    } else {

        if (numberOfTimePreviousSongWasPlayed > 1) {
            //save loop event to DB
            loopHistoryShouldBeSavedToDB = true;

        }
        //just clear history if the previous song is different than current song
        this.set("previousSongPlayed", currentSong);
        this.set("numberOfTimePreviousSongWasPlayed", 1);

    }

} else {
    //First time initialization
    if (currentSong) {
        this.set("previousSongPlayed", currentSong);
        this.set("numberOfTimePreviousSongWasPlayed", 1);
    } else {
        //do nothing
    }

}

if (loopHistoryShouldBeSavedToDB && previousSongPlayed) {
    const previousSongTitle = previousSongPlayed.get("title");
    const previousSongArtist = previousSongPlayed.get("artist");
    const previousSongUri = previousSongPlayed.get("uri");
    if (window.trebble.config.scrobbleListeningHistoryToDb && navigator.connection.type != window.Connection.NONE) {
        TrebbleClientAPIHelper.getInstance().addLoopActionToMyHistory(previousSongTitle, previousSongArtist, previousSongUri, new Date(), previousSongPlayed.toJSON(), numberOfTimePreviousSongWasPlayed, context, songRefSummary, schedulingPosition, playbackElapsedTime, saveHistoryCallback);
    } else {
        this._logPlayerActionToLocalDB("loop", previousSongUri, {
            "numberOfTimePreviousSongWasPlayed": numberOfTimePreviousSongWasPlayed
        }, context);
    }
}

const saveHistoryCallback = {};
saveHistoryCallback.onSuccess = function() {
    //do nothing
};
saveHistoryCallback.onFailure = function() {
    //do nothing
};


if (currentSong) {
    const songTitle = currentSong.get("title");
    const artist = currentSong.get("artist");
    const uri = currentSong.get("uri");
    let songTypeLabel = Utils.getInstance().getAudioTypes().SONG;
    const isSongACapsule = Utils.getInstance().isCapsuleUri(uri);
    if (isSongACapsule) {
        songTypeLabel = Utils.getInstance().getAudioTypes().CAPSULE;
    }
    const isSongAJingle = Utils.getInstance().isJingleUri(uri);
    if (isSongAJingle) {
        songTypeLabel = Utils.getInstance().getAudioTypes().JINGLE;
    }
    const isSongAGreater = Utils.getInstance().isGreaterUri(uri);
    if (isSongAGreater) {
        songTypeLabel = Utils.getInstance().getAudioTypes().GREATER;
    }
    const isSongAIntro = Utils.getInstance().isIntroUri(uri);
    if (isSongAIntro) {
        songTypeLabel = Utils.getInstance().getAudioTypes().INTRO;
    }
    const isSongAOutro = Utils.getInstance().isOutroUri(uri);
    if (isSongAOutro) {
        songTypeLabel = Utils.getInstance().getAudioTypes().OUTRO;
    }
    const isSongAEarcon = Utils.getInstance().isEarconUri(uri);
    if (isSongAEarcon) {
        songTypeLabel = Utils.getInstance().getAudioTypes().EARCON;
    }
    const isSongATrebbleGeneratedSpeech = Utils.getInstance().isTrebbleGeneratedSpeechUri(uri);
    if (isSongATrebbleGeneratedSpeech) {
        songTypeLabel = Utils.getInstance().getAudioTypes().TREBBLE_GENERATED_SPEECH;
    }


    if (window.trebble.config.scrobbleListeningHistoryToDb && navigator.connection.type != window.Connection.NONE) {
        if (action == "play") {
            if (isFixedPlaylist) {
                TrebbleClientAPIHelper.getInstance().addPlayActionToMyHistory(songTitle, artist, uri, new Date(), currentSong.toJSON(), context, songRefSummary, schedulingPosition, playbackElapsedTime, saveHistoryCallback);
                this._trackEventOnAnalyticsSystem(songTitle, artist, songTypeLabel, "Play", songRefSummary, context, playbackElapsedTime, schedulingPosition);
            } else {
                TrebbleClientAPIHelper.getInstance().addIndirectPlayActionToMyHistory(songTitle, artist, uri, new Date(), currentSong.toJSON(), context, songRefSummary, schedulingPosition, playbackElapsedTime, saveHistoryCallback);
                this._trackEventOnAnalyticsSystem(songTitle, artist, songTypeLabel, "IndirectPlay", songRefSummary, context, playbackElapsedTime, schedulingPosition);
            }
        } else {
            if (action == "skip") {
                if (isFixedPlaylist) {
                    TrebbleClientAPIHelper.getInstance().addSkipActionToMyHistory(songTitle, artist, uri, new Date(), currentSong.toJSON(), context, songRefSummary, schedulingPosition, playbackElapsedTime, saveHistoryCallback);
                    this._trackEventOnAnalyticsSystem(songTitle, artist, songTypeLabel, "Skip", songRefSummary, context, playbackElapsedTime, schedulingPosition);
                } else {
                    TrebbleClientAPIHelper.getInstance().addIndirectSkipActionToMyHistory(songTitle, artist, uri, new Date(), currentSong.toJSON(), context, songRefSummary, schedulingPosition, playbackElapsedTime, skipReasonId, saveHistoryCallback);
                    this._trackEventOnAnalyticsSystem(songTitle, artist, songTypeLabel, "IndirectSkip", songRefSummary, context, playbackElapsedTime, schedulingPosition);
                }

                try {
                    this._sendAnalyticsEventOfMusicPlaybackTime(false, false);
                } catch (error) {
                    console.error("Failed send playback analytics to DB. Error:" + error);
                }
            } else {
                if (action == "finishPlaying") {
                    if (isFixedPlaylist) {
                        TrebbleClientAPIHelper.getInstance().addFinishPlayingActionToMyHistory(songTitle, artist, uri, new Date(), currentSong.toJSON(), context, songRefSummary, schedulingPosition, playbackElapsedTime, saveHistoryCallback);
                        this._trackEventOnAnalyticsSystem(songTitle, artist, songTypeLabel, "FinishPlay", songRefSummary, context, playbackElapsedTime, schedulingPosition);
                    } else {
                        TrebbleClientAPIHelper.getInstance().addFinishIndirectPlayingActionToMyHistory(songTitle, artist, uri, new Date(), currentSong.toJSON(), context, songRefSummary, schedulingPosition, playbackElapsedTime, saveHistoryCallback);
                        this._trackEventOnAnalyticsSystem(songTitle, artist, songTypeLabel, "IndirectFinishPlay", songRefSummary, context, playbackElapsedTime, schedulingPosition);
                    }
                    try {
                        this._sendAnalyticsEventOfMusicPlaybackTime(false, false);
                    } catch (error) {
                        console.error("Failed send playback analytics to DB. Error:" + error);
                    }

                } else {
                    if (action == "liveLike") {

                        if (!isFixedPlaylist && weight) {
                            TrebbleClientAPIHelper.getInstance().addLiveLikeActionToMyHistory(songTitle, artist, uri, new Date(), currentSong.toJSON(), context, songRefSummary, saveHistoryCallback, weight, playbackElapsedTime);
                            if (window.trebbleAnalyticsHelper && window.trebbleAnalyticsHelper.trebbleHelperTrackNumberOfLiveLikes) {
                                window.trebbleAnalyticsHelper.trebbleHelperTrackNumberOfLiveLikes(weight);
                            }
                        }
                    } else {

                        if (action == "viewExternalMediaSourcePage") {
                            if (isFixedPlaylist) {
                                TrebbleClientAPIHelper.getInstance().addViewExternalMediaSourcePageActionToMyHistory(songTitle, artist, uri, new Date(), currentSong.toJSON(), mediaSourceExternalUrl, context, songRefSummary, schedulingPosition, playbackElapsedTime, saveHistoryCallback);
                            } else {
                                TrebbleClientAPIHelper.getInstance().addIndirectViewExternalMediaSourcePageActionToMyHistory(songTitle, artist, uri, new Date(), currentSong.toJSON(), mediaSourceExternalUrl, context, songRefSummary, schedulingPosition, playbackElapsedTime, saveHistoryCallback);
                            }
                            if (window.trebbleAnalyticsHelper) {
                                window.trebbleAnalyticsHelper.trackEvent('View External media Source Page', "viewExternalMediaSourcePage", "View External Media Source Page");
                            }


                        } else {
                            if (action == "shareSongPageLaunched") {
                                TrebbleClientAPIHelper.getInstance().addShareSongPageLaunchedActionToMyHistory(songTitle, artist, uri, new Date(), currentSong.toJSON(), context, songRefSummary, schedulingPosition, playbackElapsedTime, saveHistoryCallback);
                                if (window.trebbleAnalyticsHelper) {
                                    window.trebbleAnalyticsHelper.trackEvent('Share Song Page Launched', "shareSongPageLaunched", "Share Song Page Launched");
                                }
                            } else {
                                console.error("Unrecognised history action");
                            }
                        }
                    }
                }

            }
        }
    } else {
        if (currentSong) {
            this._logPlayerActionToLocalDB(action, currentSong.get("uri"), null, context);
        }
    }

}

}).bind(this)).catch((function(error) {
console.error("Failed Saving User player history to DB. Error:" + error + ". Silently continue.");
}).bind(this));

},

_trackEventOnAnalyticsSystem: function(audioTitle, author, audioType, action, audioRef, actionContext, playbackElapsedTime, schedulingPosition) {
if (window.trebbleAnalyticsHelper) {
const params = Utils.getInstance().flattenObject(actionContext, "context", true);
params.audioTitle = audioTitle;
params.author = author;
params.audioType = audioType;
params.action = action;
const audioRefId = Utils.getInstance().getAudioIdFromAudioRefSummary(audioType, audioRef);
if (audioRefId) {
    params.audioRefId = audioRefId;
}
if (playbackElapsedTime) {
    params.playbackElapsedTime = playbackElapsedTime;
}
if (schedulingPosition) {
    params.schedulingPosition = schedulingPosition;
}
this.trigger("playerEvent_"+action);
if(action == "FinishPlay" || action == "IndirectFinishPlay"){
    this.getRadioIdPlayingIfApplicable().then((function(radioIdPlaying){
        if(radioIdPlaying){
            this.trigger("audioFinishedPlayingForRadioWitId_"+radioIdPlaying);
        }
    }).bind(this))
}
window.trebbleAnalyticsHelper.trackEvent('Playback Events', action, action, null, params);
}
},

_logPlayerActionToLocalDB: function(actionToLog, songuri, additionalData, context) {
const historyToLog = {};
historyToLog.uniqueId = (new Date()).getTime();
historyToLog.target = songuri;
historyToLog.action = actionToLog;
historyToLog.additionalData = additionalData;
historyToLog.context = context;
historyToLog.synced = false;
//TrebbleDBHelper.getInstance().savePlayerHistoryLog( historyToLog);
},

isYoutubeVideoLoaded: function() {
return this._isYoutubeVideoLoaded;
},




playAudio: function(autoplay, useFallbackMediaObject) {
//console.log("playAudio 1");
/*if(window.waitForCordovaToLoad && !this.get("previousSongPostion") && !this.get("audioAlreadyReloaded")){
return this.reloadAndPlayCurrentSong();
}
this.set("audioAlreadyReloaded", false);*/
return this.getCurrentSong().then((function(currentSong) {
//console.log("playAudio 2");
if (currentSong) {
    this._startMusicPlaybackTimer(currentSong);
    this._sendGoogleAnayticsEventThatAudioWasPlayedIfApplicable();
    if (!this.getMedia()) {
        //console.log("playAudio 21");
        return this._loadSong(currentSong, null, null, useFallbackMediaObject).then((function(/*newMediaCreated*/) {
            //console.log("playAudio 211"); 
            const allowMixinWithOtherSources = this.isCurrentMediaTypeYoutubeVideo() || this.isPreviousMediaTypeYoutubeVideo(); //Only necessary in IOS because of a AVSession Issue
            return this._gainAudioFocus(allowMixinWithOtherSources);
        }).bind(this)).then((function() {
            //console.log("playAudio 212");
            const allowMixinWithOtherSources = this.isPreviousMediaTypeYoutubeVideo(); //Only necessary in IOS because of a AVSession Issue
            this.getMedia().play({
                playAudioWhenScreenIsLocked: true,
                "allowMixingWithOtherSources": allowMixinWithOtherSources
            });
            this.set("isManuallyStopping", false);
            this._setPlaying(true);
            if (this.get("mediaType") != MEDIA_TYPE.PHONEGAP && (this.get("mediaType") != MEDIA_TYPE.YOUTUBE && window.device && window.device.platform != "browser")) {
                this._onSongLoading(currentSong);
            }
            //this._setPlaying( true);
            return this._updateLockScreen(!autoplay);
        }).bind(this)).catch(function(error) {
            //console.log("playAudio 213. Error:"+error);
            window.alertErrorMessage("Couldn't play current song. Error: " + error);
        });
    } else {
        //console.log("playAudio 22");
        window.log("Media ready " + this.getMedia());
        const allowMixinWithOtherSources = this.isCurrentMediaTypeYoutubeVideo() || this.isPreviousMediaTypeYoutubeVideo(); //Only necessary in IOS because of a AVSession Issue
        this._gainAudioFocus(allowMixinWithOtherSources).then((function() {
            //console.log("playAudio 221");
            const allowMixinWithOtherSources = this.isPreviousMediaTypeYoutubeVideo(); //Only necessary in IOS because of a AVSession Issue
            this.getMedia().play({
                playAudioWhenScreenIsLocked: true,
                "allowMixingWithOtherSources": allowMixinWithOtherSources
            });
            this.set("isManuallyStopping", false);
            this._setPlaying(true);
            if (this.get("mediaType") != MEDIA_TYPE.PHONEGAP && (this.get("mediaType") != MEDIA_TYPE.YOUTUBE && window.device && window.device.platform != "browser")) {
                this._onSongLoading(currentSong);
            }
            //this._setPlaying(true);
            return this._updateLockScreen(!autoplay);
        }).bind(this)).catch(function(error) {
            //console.log("playAudio 222. Error:"+error);
            window.alertErrorMessage("Couldn't play current song. Error: " + error);
        });
    }
}
}).bind(this)).then((function() {
//console.log("playAudio 3");
if (this.getPlaylist()) {
    this.getPlaylist().setPlaying(true);
}
if (this._isYoutubeVideoLoaded && window.waitForCordovaToLoad) {
    //Youtube Playback is not supported to skip to the next song right away
    this.playNextSongInPlaylist();
}
}).bind(this));
},

terminateAudioPreviewAndResumePlayback: function() {
window.log("Current preview should be terminated");
this.stopAudioPreviewAndRelease(true);
this.trigger("songPreviewCancelled");
return this.resumePlayAfterPreview();
},

playCurrentAudioPreview: function() {
return new RSVP.Promise((function(resolve, reject) {
try {
    if (this._isCurrentPreviewNeedToBeStopped()) {
        window.log("Current preview is about to stopped from playing");
        this._removeCurrentPreviewToListOfPreviewsToBeStopped();
        if (this.getMediaPreview() && this.get("isPreviewPlaying") && this.getMediaPreviewStatus() != 4 && this.getMediaPreviewStatus() != -1) {
            if (this.getMediaPreview() instanceof window.Media || this.getMediaPreview().release) {
                this.getMediaPreview().stop();
                this.getMediaPreview().release();
            } else {
                this.getMediaPreview().stop();
            }
            this.set("isPreviewPlaying", false);
            this.set("mediaPreview", null);
            this.trigger("songPreviewUnLoaded");
        } else {
            this.set("isPreviewPlaying", false);
            this.set("mediaPreview", null);
            this.trigger("songPreviewUnLoaded");
        }
        resolve();
    } else {
        if (!this.getMediaPreview()) {
            console.warn("no media preview to play");
            this.resumePlayAfterPreview();
            return resolve();
        } else {
            const allowMixinWithOtherSources = this.isCurrentMediaTypeYoutubeVideo() || this.isPreviousMediaTypeYoutubeVideo(); //Only necessary in IOS because of a AVSession Issue
            this._gainAudioFocus(allowMixinWithOtherSources).then((function() {
                Utils.getInstance().triggerShortVibration();
                const allowMixinWithOtherSources = this.isPreviousMediaTypeYoutubeVideo(); //Only necessary in IOS because of a AVSession Issue
                this.getMediaPreview().play({
                    playAudioWhenScreenIsLocked: true,
                    "allowMixingWithOtherSources": allowMixinWithOtherSources
                });
                resolve();
            }).bind(this)).catch(function(error) {
                reject(error);
                window.alertErrorMessage(window.getI18n(ti18n, "ERROR_PLAYING_CURRENT_SONG"));
            });
        }
    }
} catch (error) {
    reject(error);
}
}).bind(this));
},



_setPlaying: function(isPlaying) {
this.set("isPlaying", isPlaying);
window._isSongPlaying = isPlaying;
if (window._playerLoadedSongModel) {
window._playerLoadedSongModel.set("isPlaying", isPlaying);
}
//let songRefSummary = null;
this.getCurrentSongRefSummaryIfApplicable().then((function() {
//songRefSummary = refSummary;
return this.getCurrentSong();
}).bind(this)).then((function(currentSong) {
if (currentSong) {
    //TrebbleSockJSHelper.getInstance().sendPlayerStatus(currentSong.toJSON(),this.get("isPlaying"), songRefSummary);
}
}).bind(this));

},

_setPreviewPlaying: function(isPlaying) {
this.set("isPreviewPlaying", isPlaying);
/*this.getCurrentSong().then((function(currentSong){
if(currentSong)
{
    TrebbleSockJSHelper.getInstance().sendPlayerStatus(currentSong.toJSON(),this.get("isPlaying"));
}
}).bind(this));*/

},

_gainAudioFocus: function(allowMixingWithOtherSources) {
return CordovaHelper.getInstance().executePluginOperation("MusicFileReaderPlugin", "gainAudioFocus", [{
"allowMixingWithOtherSources": allowMixingWithOtherSources
}]);
},

_abandonAudioFocus: function() {
return CordovaHelper.getInstance().executePluginOperation("MusicFileReaderPlugin", "abandonAudioFocus", []);
},


});
PlayerModel.types = PLAYER_TYPES;
PlayerModel.mediaTypes = MEDIA_TYPE;
export default PlayerModel;