import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerOperationLog from "models/audioEditor/sequencerOperationLog/SequencerOperationLog";
import SequencerOperationCreateDeleteNodesLog from "models/audioEditor/sequencerOperationLog/SequencerOperationCreateDeleteNodesLog";
import SequencerOperationDeleteDeleteNodesLog from "models/audioEditor/sequencerOperationLog/SequencerOperationDeleteDeleteNodesLog";
import SequencerOperationCreateWrapWithMusicLog from "models/audioEditor/sequencerOperationLog/SequencerOperationCreateWrapWithMusicLog";
import SequencerOperationDeleteWrapWithMusicLog from "models/audioEditor/sequencerOperationLog/SequencerOperationDeleteWrapWithMusicLog";
import SequencerOperationChangeSequencerNodePropertyLog from "models/audioEditor/sequencerOperationLog/SequencerOperationChangeSequencerNodePropertyLog";
import SequencerOperationMoveWrapNodesLog from "models/audioEditor/sequencerOperationLog/SequencerOperationMoveWrapNodesLog";
import SequencerOperationInsertAudioNodeLog from "models/audioEditor/sequencerOperationLog/SequencerOperationInsertAudioNodeLog";
import SequencerOperationInsertVideoNodeLog from "models/audioEditor/sequencerOperationLog/SequencerOperationInsertVideoNodeLog";
import SequencerOperationDeleteAudioNodeLog from "models/audioEditor/sequencerOperationLog/SequencerOperationDeleteAudioNodeLog";
import SequencerOperationTurnNoiseCancellationOnOperationLog from "models/audioEditor/sequencerOperationLog/SequencerOperationTurnNoiseCancellationOnOperationLog";
import SequencerOperationTurnMagicSoundEnhancerOnOperationLog from "models/audioEditor/sequencerOperationLog/SequencerOperationTurnMagicSoundEnhancerOnOperationLog";
import SequencerOperationMoveNodeLog from "models/audioEditor/sequencerOperationLog/SequencerOperationMoveNodeLog";
import SequencerOperationInsertNodeArrayOperationLog from "models/audioEditor/sequencerOperationLog/SequencerOperationInsertNodeArrayOperationLog";
import SequencerOperationCutAndCopyToClipboardOperationLog from "models/audioEditor/sequencerOperationLog/SequencerOperationCutAndCopyToClipboardOperationLog";
import SequencerOperationSettingsChangeLog from "models/audioEditor/sequencerOperationLog/SequencerOperationSettingsChangeLog";
import SequencerOperationLoadProjectLog from "models/audioEditor/sequencerOperationLog/SequencerOperationLoadProjectLog";
import SequencerOperationCorrectTranscriptSingleSequencerNodeOperation from "models/audioEditor/sequencerOperationLog/SequencerOperationCorrectTranscriptSingleSequencerNodeOperation";
import SequencerOperationCreateDeleteNodesBulkLog from "models/audioEditor/sequencerOperationLog/SequencerOperationCreateDeleteNodesBulkLog";
import SequencerOperationRenameSpeakerLabelLog  from "models/audioEditor/sequencerOperationLog/SequencerOperationRenameSpeakerLabelLog";
import SequencerOperationCreateNewSpeakerAndAssignToSequencerNodesOperationLog from "models/audioEditor/sequencerOperationLog/SequencerOperationCreateNewSpeakerAndAssignToSequencerNodesOperationLog";
import SequencerOperationChangeSpeakerForParagraphOperationLog from "models/audioEditor/sequencerOperationLog/SequencerOperationChangeSpeakerForParagraphOperationLog";
import SequencerOperationTranscribeAndReplaceSequencerNodeLog from "models/audioEditor/sequencerOperationLog/SequencerOperationTranscribeAndReplaceSequencerNodeLog";
import SequencerOperationDeleteNodeLog from "models/audioEditor/sequencerOperationLog/SequencerOperationDeleteNodeLog";
import SequencerOperationDeleteAllExceptSelectedNodesLog from "models/audioEditor/sequencerOperationLog/SequencerOperationDeleteAllExceptSelectedNodesLog";
import SequencerOperationInsertAudioOrVideoNodesInBulkLog from "models/audioEditor/sequencerOperationLog/SequencerOperationInsertAudioOrVideoNodesInBulkLog";

import RSVP from "rsvp";


const SequencerHistoryManagement =  Backbone.Model.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        Backbone.Model.apply(this, [attributes, options]);
        this._sequencer = attributes.sequencer;
        this.init();
    },

    init: function(){
        this._operationArray = [];
        this._indexOfNextOperationToUndo = -1;
    },

    clear: function(){
        this.init();
        this.trigger("historyCleared");
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("audioEditor", "historyCleared", "historyCleared");
        }
    },

    addCreateNodeDeletedLog :function(nodeArrayToDelete, deleteNode){
        const sequencerOperationCreateDeleteNodesLog = new SequencerOperationCreateDeleteNodesLog();
        sequencerOperationCreateDeleteNodesLog.setNodeArrayToDelete(nodeArrayToDelete);
        sequencerOperationCreateDeleteNodesLog.setDeleteNode(deleteNode);
        this._addOperationLog(sequencerOperationCreateDeleteNodesLog);
    },

    addCreateNodeDeletedBulkLog :function(arrayOfNodeArrayToDelete, arrayOfDeleteNode){
        const sequencerOperationCreateDeleteNodesBulkLog = new SequencerOperationCreateDeleteNodesBulkLog();
        sequencerOperationCreateDeleteNodesBulkLog.setArrayOfNodeArrayToDelete(arrayOfNodeArrayToDelete);
        sequencerOperationCreateDeleteNodesBulkLog.setArrayOfDeleteNode(arrayOfDeleteNode);
        this._addOperationLog(sequencerOperationCreateDeleteNodesBulkLog);
    },



    addDeleteNodeDeletedLog :function(nodeArrayToDelete, deleteNode){
        const sequencerOperationDeleteDeleteNodesLog = new SequencerOperationDeleteDeleteNodesLog();
        sequencerOperationDeleteDeleteNodesLog.setNodeArrayToDelete(nodeArrayToDelete);
        sequencerOperationDeleteDeleteNodesLog.setDeleteNode(deleteNode);
        this._addOperationLog(sequencerOperationDeleteDeleteNodesLog);
    },


    addCreateWrapWithMusicLog :function(startSequencerNodeToWrap, endSequencerNodeToWrap, startWrapMusicNode, endWrapMusicNode){
        const sequencerOperationCreateWrapWithMusicLog = new SequencerOperationCreateWrapWithMusicLog();
        sequencerOperationCreateWrapWithMusicLog.setStartSequencerNodeToWrap(startSequencerNodeToWrap);
        sequencerOperationCreateWrapWithMusicLog.setEndSequencerNodeToWrap(endSequencerNodeToWrap);
        sequencerOperationCreateWrapWithMusicLog.setStartWrapMusicNode(startWrapMusicNode);
        sequencerOperationCreateWrapWithMusicLog.setEndWrapMusicNode(endWrapMusicNode);
        this._addOperationLog(sequencerOperationCreateWrapWithMusicLog);
    },

    addDeleteWrapWithMusicLog :function(startSequencerNodeToWrap, endSequencerNodeToWrap, startWrapMusicNode, endWrapMusicNode){
        const sequencerOperationDeleteWrapWithMusicLog = new SequencerOperationDeleteWrapWithMusicLog();
        sequencerOperationDeleteWrapWithMusicLog.setStartSequencerNodeToWrap(startSequencerNodeToWrap);
        sequencerOperationDeleteWrapWithMusicLog.setEndSequencerNodeToWrap(endSequencerNodeToWrap);
        sequencerOperationDeleteWrapWithMusicLog.setStartWrapMusicNode(startWrapMusicNode);
        sequencerOperationDeleteWrapWithMusicLog.setEndWrapMusicNode(endWrapMusicNode);
        this._addOperationLog(sequencerOperationDeleteWrapWithMusicLog);
    },


    addUpdatePropertyOnSequencerNodeLog : function(sequencerNode, updatePropertyFunctionName, oldValue, newValue){
        const sequencerOperationChangeSequencerNodePropertyLog = new SequencerOperationChangeSequencerNodePropertyLog();
        sequencerOperationChangeSequencerNodePropertyLog.setSequencerNode(sequencerNode);
        sequencerOperationChangeSequencerNodePropertyLog.setUpdatePropertyFunctionName(updatePropertyFunctionName);
        sequencerOperationChangeSequencerNodePropertyLog.setOldValue(oldValue);
        sequencerOperationChangeSequencerNodePropertyLog.setNewValue(newValue);
        this._addOperationLog(sequencerOperationChangeSequencerNodePropertyLog);
    },

    addSequencerOperationMoveWrapNodesLog : function( startOrEndWrapSequencerNodeToMove, changeStartNode, oldStartWrappedNode,oldEndWrappedNode,newStartWrappedNode, newEndWrappedNode){
        const sequencerOperationMoveWrapNodesLog = new SequencerOperationMoveWrapNodesLog();
        sequencerOperationMoveWrapNodesLog.setStartOrEndWrapSequencerNodeToMove(startOrEndWrapSequencerNodeToMove);
        sequencerOperationMoveWrapNodesLog.setChangeStartNode(changeStartNode);
        sequencerOperationMoveWrapNodesLog.setOldStartWrappedNode(oldStartWrappedNode);
        sequencerOperationMoveWrapNodesLog.setOldEndWrappedNode(oldEndWrappedNode);
        sequencerOperationMoveWrapNodesLog.setNewStartWrappedNode(newStartWrappedNode);
        sequencerOperationMoveWrapNodesLog.setNewEndWrappedNode(newEndWrappedNode);
        this._addOperationLog(sequencerOperationMoveWrapNodesLog);
    },

    addSequencerOperationMoveNodeLog : function(audioNodeToMove, oldPreviousSequencerNode, oldNextSequencerNode,newPreviousSequencerNode, newNextSequencerNode){
        const sequencerOperationMoveNodeLog = new SequencerOperationMoveNodeLog();
        sequencerOperationMoveNodeLog.setNodeToMove(audioNodeToMove);
        sequencerOperationMoveNodeLog.setOldPreviousSequencerNode(oldPreviousSequencerNode);
        sequencerOperationMoveNodeLog.setOldNextSequencerNode(oldNextSequencerNode);
        sequencerOperationMoveNodeLog.setNewPreviousSequencerNode(newPreviousSequencerNode);
        sequencerOperationMoveNodeLog.setNewNextSequencerNode(newNextSequencerNode);
        this._addOperationLog(sequencerOperationMoveNodeLog);
    },


    addCreateInsertAudioNodeLog: function(videoSequencerNode,sequencerNodeToInsertNextTo , insertBefore){
        const sequencerOperationInsertVideoNodeLog = new SequencerOperationInsertVideoNodeLog();
        sequencerOperationInsertVideoNodeLog.setVideoSequencerNode(videoSequencerNode);
        sequencerOperationInsertVideoNodeLog.setSequencerNodeToInsertNextTo(sequencerNodeToInsertNextTo);
        sequencerOperationInsertVideoNodeLog.setInsertBefore(insertBefore);
        this._addOperationLog(sequencerOperationInsertVideoNodeLog);
    },

    addDeleteAudioSequencerNodeLog: function(audioSequencerNode,sequencerNodeToInsertNextTo , insertBefore){
        const sequencerOperationDeleteAudioNodeLog = new SequencerOperationDeleteAudioNodeLog();
        sequencerOperationDeleteAudioNodeLog.setAudioSequencerNode(audioSequencerNode);
        sequencerOperationDeleteAudioNodeLog.setSequencerNodeToInsertNextTo(sequencerNodeToInsertNextTo);
        sequencerOperationDeleteAudioNodeLog.setInsertBefore(insertBefore);
        this._addOperationLog(sequencerOperationDeleteAudioNodeLog);
    },

    addDeleteSequencerNodeLog: function(sequencerNode, previousSequencerNode , nextSequencerNode, insertBefore){
        const sequencerOperationDeleteNodeLog = new SequencerOperationDeleteNodeLog();
        sequencerOperationDeleteNodeLog.setSequencerNode(sequencerNode);
        sequencerOperationDeleteNodeLog.setPreviousNode(previousSequencerNode);
        sequencerOperationDeleteNodeLog.setNextNode(nextSequencerNode);
        sequencerOperationDeleteNodeLog.setInsertBefore(insertBefore);
        this._addOperationLog(sequencerOperationDeleteNodeLog);
    },

    addTurnNoiseCancellationOnOperationLog: function(turnNoiseCancellationOn){
        const sequencerOperationTurnNoiseCancellationOnOperationLog = new SequencerOperationTurnNoiseCancellationOnOperationLog();
        sequencerOperationTurnNoiseCancellationOnOperationLog.setNoiseCancellationOn(turnNoiseCancellationOn);
        this._addOperationLog(sequencerOperationTurnNoiseCancellationOnOperationLog);
    },

    addTurnMagicSoundEnhancerOnOperationLog: function(turnMagicSoundEnhancerOn){
        const sequencerOperationTurnMagicSoundEnhancerOnOperationLog = new SequencerOperationTurnMagicSoundEnhancerOnOperationLog();
        sequencerOperationTurnMagicSoundEnhancerOnOperationLog.setMagicSoundEnhancerOn(turnMagicSoundEnhancerOn);
        this._addOperationLog(sequencerOperationTurnMagicSoundEnhancerOnOperationLog);
    },

    addSequencerOperationInsertNodeArrayOperationLog : function(arrayOfSequencerNodes, sequencerNodeToInsertNextTo, insertBefore){
        const sequencerOperationInsertNodeArrayOperationLog = new SequencerOperationInsertNodeArrayOperationLog();
        sequencerOperationInsertNodeArrayOperationLog.setArrayOfSequencerNodes(arrayOfSequencerNodes);
        sequencerOperationInsertNodeArrayOperationLog.setSequencerNodeToInsertNextTo(sequencerNodeToInsertNextTo);
        sequencerOperationInsertNodeArrayOperationLog.setInsertBefore(insertBefore);
        this._addOperationLog(sequencerOperationInsertNodeArrayOperationLog);
    },

    addSequencerOperationLoadProjectLog : function(projectId, arrayOfSequencerNodes, sequencerNodeToInsertNextTo, insertBefore){
        const sequencerOperationLoadProjectLog = new SequencerOperationLoadProjectLog();
        sequencerOperationLoadProjectLog.setProjectId(projectId);
        sequencerOperationLoadProjectLog.setArrayOfSequencerNodes(arrayOfSequencerNodes);
        sequencerOperationLoadProjectLog.setSequencerNodeToInsertNextTo(sequencerNodeToInsertNextTo);
        sequencerOperationLoadProjectLog.setInsertBefore(insertBefore);
        this._addOperationLog(sequencerOperationLoadProjectLog);
    },

    addSequencerOperationCorrectTranscriptSingleSequencerNodeLog: function(wordSequencerNode, oldTranscriptText, newTranscriptText){
        const sequencerOperationCorrectTranscriptSingleSequencerNodeOperation = new SequencerOperationCorrectTranscriptSingleSequencerNodeOperation();
        sequencerOperationCorrectTranscriptSingleSequencerNodeOperation.setWordSequencerNode(wordSequencerNode);
        sequencerOperationCorrectTranscriptSingleSequencerNodeOperation.setOldTranscriptText(oldTranscriptText);
        sequencerOperationCorrectTranscriptSingleSequencerNodeOperation.setNewTranscriptText(newTranscriptText);
        this._addOperationLog(sequencerOperationCorrectTranscriptSingleSequencerNodeOperation);
    },

    addSequencerOperationRenameSpeakerLabelLog: function(speakerInfo, initialSpeakerLabel, oldModifiedSpeakerLabel, newModifiedSpeakerLabel){
        const sequencerOperationRenameSpeakerLabelLog = new SequencerOperationRenameSpeakerLabelLog();
        sequencerOperationRenameSpeakerLabelLog.setSpeakerInfo(speakerInfo);
        sequencerOperationRenameSpeakerLabelLog.setInitialSpeakerLabel(initialSpeakerLabel);
        sequencerOperationRenameSpeakerLabelLog.setOldModifiedSpeakerLabel(oldModifiedSpeakerLabel);
        sequencerOperationRenameSpeakerLabelLog.setNewModifiedSpeakerLabel(newModifiedSpeakerLabel);
        this._addOperationLog(sequencerOperationRenameSpeakerLabelLog);
    },

    addSequencerOperationCutAndCopyToClipboardOperationLog : function(arrayOfSequencerNodeToCut, sequencerNodeBeforeStartNode){
        const sequencerOperationCutAndCopyToClipboardOperationLog = new SequencerOperationCutAndCopyToClipboardOperationLog();
        sequencerOperationCutAndCopyToClipboardOperationLog.setArrayOfSequencerNodeToCut(arrayOfSequencerNodeToCut);
        sequencerOperationCutAndCopyToClipboardOperationLog.setSequencerNodeToInsertNextTo(sequencerNodeBeforeStartNode);
        this._addOperationLog(sequencerOperationCutAndCopyToClipboardOperationLog);
    },

    addSequencerOperationSettingsChangeLog: function(settingToChange, oldValue, newValue){
        const sequencerOperationSettingsChangeLog = new SequencerOperationSettingsChangeLog();
        sequencerOperationSettingsChangeLog.setSettingToChange(settingToChange);
        sequencerOperationSettingsChangeLog.setOldValue(oldValue);
        sequencerOperationSettingsChangeLog.setNewValue(newValue);
        this._addOperationLog(sequencerOperationSettingsChangeLog);
    },

    addSequencerOperationCreateNewSpeakerAndAssignToSequencerNodesOperationLog: function(arrayOfSequencerNodeToAssign, speakerLabel, paragraphSequencerNodeView, oldSpeakerInfo, sequencerNodeCidToOldSpeakerId, newSpeakerWasAdded){
        const sequencerOperationCreateNewSpeakerAndAssignToSequencerNodesOperationLog = new SequencerOperationCreateNewSpeakerAndAssignToSequencerNodesOperationLog();
        sequencerOperationCreateNewSpeakerAndAssignToSequencerNodesOperationLog.setArrayOfSequencerNodeToAssign(arrayOfSequencerNodeToAssign);
        sequencerOperationCreateNewSpeakerAndAssignToSequencerNodesOperationLog.setSpeakerLabel(speakerLabel);
        sequencerOperationCreateNewSpeakerAndAssignToSequencerNodesOperationLog.setSequencerNodeCidToOldSpeakerId(sequencerNodeCidToOldSpeakerId);
        sequencerOperationCreateNewSpeakerAndAssignToSequencerNodesOperationLog.setNewSpeakerWasAdded(newSpeakerWasAdded);
        sequencerOperationCreateNewSpeakerAndAssignToSequencerNodesOperationLog.setParagraphSequencerNodeView(paragraphSequencerNodeView);
        sequencerOperationCreateNewSpeakerAndAssignToSequencerNodesOperationLog.setOldSpeakerInfo(oldSpeakerInfo);
        this._addOperationLog(sequencerOperationCreateNewSpeakerAndAssignToSequencerNodesOperationLog);
    },

    addSequencerOperationChangeSpeakerForParagraphOperationLog : function(arrayOfSequencerNodeToChange, oldSpeakerInfo, newSpeakerInfo, paragraphSequencerNodeView){
        const sequencerOperationChangeSpeakerForParagraphOperationLog = new SequencerOperationChangeSpeakerForParagraphOperationLog();
        sequencerOperationChangeSpeakerForParagraphOperationLog.setArrayOfSequencerNodeToChange(arrayOfSequencerNodeToChange);
        sequencerOperationChangeSpeakerForParagraphOperationLog.setOldSpeakerInfo(oldSpeakerInfo);
        sequencerOperationChangeSpeakerForParagraphOperationLog.setNewSpeakerInfo(newSpeakerInfo);
        sequencerOperationChangeSpeakerForParagraphOperationLog.setParagraphSequencerNodeView(paragraphSequencerNodeView);
        this._addOperationLog(sequencerOperationChangeSpeakerForParagraphOperationLog);
    },

    addSequencerOperationTranscribeAndReplaceSequencerNodeLog: function(sequencerNode, languageCode, arrayOfSequencerNodesToAdd){
        const sequencerOperationTranscribeAndReplaceSequencerNodeLog = new SequencerOperationTranscribeAndReplaceSequencerNodeLog();
        sequencerOperationTranscribeAndReplaceSequencerNodeLog.setSequencerNode(sequencerNode);
        sequencerOperationTranscribeAndReplaceSequencerNodeLog.setLanguageCode(languageCode);
        sequencerOperationTranscribeAndReplaceSequencerNodeLog.setArrayOfSequencerNodesToAdd(arrayOfSequencerNodesToAdd);
        this._addOperationLog(sequencerOperationTranscribeAndReplaceSequencerNodeLog);
    },

    addDeleteAllExceptSelectedNodesOperationLog : function(startSequencerNodeToKeep, endSequencerNodeToKeep, arrayOfNodeToDeleteBefore, deleteNodeBefore, deleteNodeAfter, arrayOfNodeToDeleteAfter){
        const sequencerOperationDeleteAllExceptSelectedNodesLog =  new SequencerOperationDeleteAllExceptSelectedNodesLog();
        sequencerOperationDeleteAllExceptSelectedNodesLog.setStartSequencerNodeToKeep(startSequencerNodeToKeep);
        sequencerOperationDeleteAllExceptSelectedNodesLog.setEndSequencerNodeToKeep(endSequencerNodeToKeep);
        sequencerOperationDeleteAllExceptSelectedNodesLog.setArrayOfNodeToDeleteBefore(arrayOfNodeToDeleteBefore);
        sequencerOperationDeleteAllExceptSelectedNodesLog.setDeleteNodeBefore(deleteNodeBefore);
        sequencerOperationDeleteAllExceptSelectedNodesLog.setArrayOfNodeToDeleteAfter(arrayOfNodeToDeleteAfter);
        sequencerOperationDeleteAllExceptSelectedNodesLog.setDeleteNodeAfter(deleteNodeAfter);
        this._addOperationLog(sequencerOperationDeleteAllExceptSelectedNodesLog);
    },
    addInsertAudioOrVideoNodesInBulk : function(arrayOfAudioOrVideoSequencerNodesAdded, sequencerNodeToInsertNextTo , insertBefore){
        const sequencerOperationInsertAudioOrVideoNodesInBulkLog =  new SequencerOperationInsertAudioOrVideoNodesInBulkLog();
        sequencerOperationInsertAudioOrVideoNodesInBulkLog.setAudioOrVideoSequencerNodeArray(arrayOfAudioOrVideoSequencerNodesAdded);
        sequencerOperationInsertAudioOrVideoNodesInBulkLog.setSequencerNodeToInsertNextTo(sequencerNodeToInsertNextTo);
        sequencerOperationInsertAudioOrVideoNodesInBulkLog.setInsertBefore(insertBefore);
        this._addOperationLog(sequencerOperationInsertAudioOrVideoNodesInBulkLog);
    },
    

    isSequencerOperationCreateDeleteNodesLogInstance : function(obj){
        return obj instanceof SequencerOperationCreateDeleteNodesLog;
    },	

    isSequencerOperationCreateDeleteNodesBulkLogInstance : function(obj){
        return obj instanceof SequencerOperationCreateDeleteNodesBulkLog;
    },	


    isSequencerOperationDeleteDeleteNodesLogInstance : function(obj){
        return obj instanceof SequencerOperationDeleteDeleteNodesLog;
    },

    isSequencerOperationCreateWrapWithMusicLogInstance : function(obj){
        return obj instanceof SequencerOperationCreateWrapWithMusicLog
    },

    isSequencerOperationDeleteWrapWithMusicLogInstance: function(obj){
        return obj instanceof SequencerOperationDeleteWrapWithMusicLog
    },


    isSequencerOperationChangeSequencerNodePropertyLogInstance: function(obj){
        return obj instanceof SequencerOperationChangeSequencerNodePropertyLog
    },

    isSequencerOperationMoveWrapNodesLogInstance: function(obj){
        return obj instanceof SequencerOperationMoveWrapNodesLog
    },

    isSequencerOperationInsertAudioNodeLogInstance: function(obj){
        return obj instanceof SequencerOperationInsertAudioNodeLog
    },

    isSequencerOperationInsertVideoNodeLogInstance: function(obj){
        return obj instanceof SequencerOperationInsertVideoNodeLog
    },

    isSequencerOperationDeleteAudioNodeLogInstance: function(obj){

        return obj instanceof SequencerOperationDeleteAudioNodeLog
    },


    isSequencerOperationMoveNodeLogInstance: function(obj){

        return obj instanceof SequencerOperationMoveNodeLog
    },

    isSequencerOperationTurnNoiseCancellationOnOperationLogInstance: function(obj){

        return obj instanceof SequencerOperationTurnNoiseCancellationOnOperationLog
    },

    isSequencerOperationTurnMagicSoundEnhancerOnOperationLogInstance: function(obj){

        return obj instanceof SequencerOperationTurnMagicSoundEnhancerOnOperationLog
    },

    isSequencerOperationInsertNodeArrayOperationLogInstance: function(obj){

        return obj instanceof SequencerOperationInsertNodeArrayOperationLog
    },

    isSequencerOperationCutAndCopyToClipboardOperationLogInstance: function(obj){

        return obj instanceof SequencerOperationCutAndCopyToClipboardOperationLog
    },

    isSequencerOperationSettingsChangeLogInstance: function(obj){

        return obj instanceof SequencerOperationSettingsChangeLog
    },

    isSequencerOperationLoadProjectLogInstance: function(obj){

        return obj instanceof SequencerOperationLoadProjectLog
    },

    isSequencerOperationCorrectTranscriptSingleSequencerNodeOperationLogInstance: function(obj){

        return obj instanceof SequencerOperationCorrectTranscriptSingleSequencerNodeOperation
    },

    isSequencerOperationRenameSpeakerLabelLogInstance: function(obj){

        return obj instanceof SequencerOperationRenameSpeakerLabelLog;
    },

    isSequencerOperationCreateNewSpeakerAndAssignToSequencerNodesOperationLogInstance: function(obj){
        return obj instanceof SequencerOperationCreateNewSpeakerAndAssignToSequencerNodesOperationLog;
    },

    isSequencerOperationChangeSpeakerForParagraphOperationLogInstance: function(obj){
        return obj instanceof SequencerOperationChangeSpeakerForParagraphOperationLog;
    },

    isSequencerOperationTranscribeAndReplaceSequencerNodeLogInstance: function(obj){
        return obj instanceof SequencerOperationTranscribeAndReplaceSequencerNodeLog;
    },

    isSequencerOperationDeleteNodeLogInstance: function(obj){
        return obj instanceof SequencerOperationDeleteNodeLog;
    },

    isSequencerOperationDeleteAllExceptSelectedNodesLog: function(obj){
        return obj instanceof SequencerOperationDeleteAllExceptSelectedNodesLog;
    },

    isSequencerOperationInsertAudioOrVideoNodesInBulkLog: function(obj){
        return obj instanceof SequencerOperationInsertAudioOrVideoNodesInBulkLog;
    },



    undoGroupOperationOnSequencer : function(arrayOfOperationLogsToUndo){

    },

    undoOperationOnSequencer: function(operationLogToUndo){
        if(this.isSequencerOperationCreateDeleteNodesLogInstance(operationLogToUndo)){
            const sequencerOperationDeleteNodesLog = operationLogToUndo;
            this._sequencer.undoDeleteSelectedNodes(sequencerOperationDeleteNodesLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationCreateDeleteNodesBulkLogInstance(operationLogToUndo)){
            const sequencerOperationCreateDeleteNodesBulkLog = operationLogToUndo;
            this._sequencer.undoDeleteSelectedNodesBulk(sequencerOperationCreateDeleteNodesBulkLog);
            return RSVP.Promise.resolve(true);
        }				
        if(this.isSequencerOperationCreateWrapWithMusicLogInstance(operationLogToUndo)){
            const sequencerOperationCreateWrapWithMusicLog = operationLogToUndo;
            this._sequencer.undoWrapSelectedNodesWithMusic(sequencerOperationCreateWrapWithMusicLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationDeleteDeleteNodesLogInstance(operationLogToUndo)){
            const sequencerOperationDeleteDeleteNodesLog = operationLogToUndo;
            this._sequencer.undoRevertDeletedNodeCreation(sequencerOperationDeleteDeleteNodesLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationDeleteWrapWithMusicLogInstance(operationLogToUndo)){
            const sequencerOperationDeleteWrapWithMusicLog = operationLogToUndo;
            this._sequencer.undoDeleteMusicWrapNodeCreation(sequencerOperationDeleteWrapWithMusicLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationChangeSequencerNodePropertyLogInstance(operationLogToUndo)){
            const sequencerOperationChangeSequencerNodePropertyLog = operationLogToUndo;
            this._sequencer.undoUpdatePropertyOnSequencerNode(sequencerOperationChangeSequencerNodePropertyLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationMoveWrapNodesLogInstance(operationLogToUndo)){
            const sequencerOperationMoveWrapNodesLog = operationLogToUndo;
            this._sequencer.undoMoveWrapNode(sequencerOperationMoveWrapNodesLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationInsertAudioNodeLogInstance(operationLogToUndo)){
            const sequencerOperationInsertAudioNodeLog = operationLogToUndo;
            this._sequencer.undoInsertAudioNode(sequencerOperationInsertAudioNodeLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationInsertVideoNodeLogInstance(operationLogToUndo)){
            const sequencerOperationInsertVideoNodeLog = operationLogToUndo;
            this._sequencer.undoInsertVideoNode(sequencerOperationInsertVideoNodeLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationDeleteAudioNodeLogInstance(operationLogToUndo)){
            const sequencerOperationDeleteAudioNodeLogInstance = operationLogToUndo;
            this._sequencer.undoDeleteAudioSequencerNodeCreated(sequencerOperationDeleteAudioNodeLogInstance);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationMoveNodeLogInstance(operationLogToUndo)){
            const sequencerOperationMoveNodeLogInstance = operationLogToUndo;
            this._sequencer.undoMoveNode(sequencerOperationMoveNodeLogInstance);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationTurnNoiseCancellationOnOperationLogInstance(operationLogToUndo)){
            const sequencerOperationTurnNoiseCancellationOnOperationLog = operationLogToUndo;
            this._sequencer.undoToggleNoiseCancellation(sequencerOperationTurnNoiseCancellationOnOperationLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationTurnMagicSoundEnhancerOnOperationLogInstance(operationLogToUndo)){
            const sequencerOperationTurnMagicSoundEnhancerOnOperationLog = operationLogToUndo;
            this._sequencer.undoToggleMagicSoundEnhancer(sequencerOperationTurnMagicSoundEnhancerOnOperationLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationInsertNodeArrayOperationLogInstance(operationLogToUndo)){
            const sequencerOperationInsertNodeArrayOperationLog = operationLogToUndo;
            this._sequencer.undoInsertNodeArray(sequencerOperationInsertNodeArrayOperationLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationCutAndCopyToClipboardOperationLogInstance(operationLogToUndo)){
            const sequencerOperationCutAndCopyToClipboardOperationLog = operationLogToUndo;
            this._sequencer.undoCutAndCopyToClipboard(sequencerOperationCutAndCopyToClipboardOperationLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationSettingsChangeLogInstance(operationLogToUndo)){
            const sequencerOperationSettingsChangeLog = operationLogToUndo;
            this._sequencer.undoSequencerSettingChange(sequencerOperationSettingsChangeLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationLoadProjectLogInstance(operationLogToUndo)){
            const sequencerOperationLoadProjectLog = operationLogToUndo;
            this._sequencer.undoLoadProject(sequencerOperationLoadProjectLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationCorrectTranscriptSingleSequencerNodeOperationLogInstance(operationLogToUndo)){
            const sequencerOperationCorrectTranscriptSingleSequencerNodeOperationLog = operationLogToUndo;
            this._sequencer.undoCorrectTextOnSequencerNodes(sequencerOperationCorrectTranscriptSingleSequencerNodeOperationLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationRenameSpeakerLabelLogInstance(operationLogToUndo)){
            const sequencerOperationRenameSpeakerLabelLog = operationLogToUndo;
            this._sequencer.undoRenameSpeakerInfo(sequencerOperationRenameSpeakerLabelLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationCreateNewSpeakerAndAssignToSequencerNodesOperationLogInstance(operationLogToUndo)){
            const sequencerOperationCreateNewSpeakerAndAssignToSequencerNodesOperationLog = operationLogToUndo;
            this._sequencer.undoCreateNewSpeakerInfoAndAssign(sequencerOperationCreateNewSpeakerAndAssignToSequencerNodesOperationLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationChangeSpeakerForParagraphOperationLogInstance(operationLogToUndo)){
            const sequencerOperationChangeSpeakerForParagraphOperationLog = operationLogToUndo;
            this._sequencer.undoChangeSpeakerParagraph(sequencerOperationChangeSpeakerForParagraphOperationLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationTranscribeAndReplaceSequencerNodeLogInstance(operationLogToUndo)){
            const sequencerOperationTranscribeAndReplaceSequencerNodeLog =  operationLogToUndo;
            this._sequencer.undoTransribeAndReplaceSequencerNode(sequencerOperationTranscribeAndReplaceSequencerNodeLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationDeleteNodeLogInstance(operationLogToUndo)){
            const sequencerOperationDeleteNodeLog =  operationLogToUndo;
            this._sequencer.undoDeleteSequencerNode(sequencerOperationDeleteNodeLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationDeleteAllExceptSelectedNodesLog(operationLogToUndo)){
            const sequencerOperationDeleteAllExceptSelectedNodesLog =  operationLogToUndo;
            this._sequencer.undoDeleteAllExceptSelectedNodes(sequencerOperationDeleteAllExceptSelectedNodesLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationInsertAudioOrVideoNodesInBulkLog(operationLogToUndo)){
            const sequencerOperationInsertAudioOrVideoNodesInBulkLog =  operationLogToUndo;
            this._sequencer.undoInsertAudioOrVideoNodesBulk(sequencerOperationInsertAudioOrVideoNodesInBulkLog);
            return RSVP.Promise.resolve(true);
        }
        
        return RSVP.Promise.resolve(true);

    },

    redoGroupOperationOnSequencer : function(arrayOfOperationLogsToRedo){

    },


    redoOperationOnSequencer: function(operationLogToRedo){
        if(this.isSequencerOperationCreateDeleteNodesLogInstance(operationLogToRedo)){
            const sequencerOperationDeleteNodesLog = operationLogToRedo;
            this._sequencer.redoDeleteSelectedNodes(sequencerOperationDeleteNodesLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationCreateDeleteNodesBulkLogInstance(operationLogToRedo)){
            const sequencerOperationCreateDeleteNodesBulkLog = operationLogToRedo;
            this._sequencer.redoDeleteSelectedNodesBulk(sequencerOperationCreateDeleteNodesBulkLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationCreateWrapWithMusicLogInstance(operationLogToRedo)){
            const sequencerOperationCreateWrapWithMusicLog = operationLogToRedo;
            this._sequencer.redoWrapSelectedNodesWithMusic(sequencerOperationCreateWrapWithMusicLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationDeleteDeleteNodesLogInstance(operationLogToRedo)){
            const sequencerOperationDeleteDeleteNodesLog = operationLogToRedo;
            this._sequencer.redoRevertDeletedNodeCreation(sequencerOperationDeleteDeleteNodesLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationDeleteWrapWithMusicLogInstance(operationLogToRedo)){
            const sequencerOperationDeleteWrapWithMusicLog = operationLogToRedo;
            this._sequencer.redoDeleteMusicWrapNodeCreation(sequencerOperationDeleteWrapWithMusicLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationChangeSequencerNodePropertyLogInstance(operationLogToRedo)){
            const sequencerOperationChangeSequencerNodePropertyLog = operationLogToRedo;
            this._sequencer.redoUpdatePropertyOnSequencerNode(sequencerOperationChangeSequencerNodePropertyLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationMoveWrapNodesLogInstance(operationLogToRedo)){
            const sequencerOperationMoveWrapNodesLog = operationLogToRedo;
            this._sequencer.redoMoveWrapNode(sequencerOperationMoveWrapNodesLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationInsertAudioNodeLogInstance(operationLogToRedo)){
            const sequencerOperationInsertAudioNodeLog = operationLogToRedo;
            this._sequencer.redoInsertAudioNode(sequencerOperationInsertAudioNodeLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationInsertVideoNodeLogInstance(operationLogToRedo)){
            const sequencerOperationInsertVideoNodeLog = operationLogToRedo;
            this._sequencer.redoInsertVideoNode(sequencerOperationInsertVideoNodeLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationDeleteAudioNodeLogInstance(operationLogToRedo)){
            const sequencerOperationDeleteAudioNodeLogInstance = operationLogToRedo;
            this._sequencer.redoDeleteAudioSequencerNodeCreated(sequencerOperationDeleteAudioNodeLogInstance);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationMoveNodeLogInstance(operationLogToRedo)){
            const sequencerOperationMoveNodeLogInstance = operationLogToRedo;
            this._sequencer.redoMoveNode(sequencerOperationMoveNodeLogInstance);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationTurnNoiseCancellationOnOperationLogInstance(operationLogToRedo)){
            const sequencerOperationTurnNoiseCancellationOnOperationLog = operationLogToRedo;
            this._sequencer.redoToggleNoiseCancellation(sequencerOperationTurnNoiseCancellationOnOperationLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationTurnMagicSoundEnhancerOnOperationLogInstance(operationLogToRedo)){
            const sequencerOperationTurnMagicSoundEnhancerOnOperationLog = operationLogToRedo;
            this._sequencer.redoToggleMagicSoundEnhancer(sequencerOperationTurnMagicSoundEnhancerOnOperationLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationInsertNodeArrayOperationLogInstance(operationLogToRedo)){
            const sequencerOperationInsertNodeArrayOperationLog = operationLogToRedo;
            this._sequencer.redoInsertNodeArray(sequencerOperationInsertNodeArrayOperationLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationCutAndCopyToClipboardOperationLogInstance(operationLogToRedo)){
            const sequencerOperationCutAndCopyToClipboardOperationLog = operationLogToRedo;
            this._sequencer.redoCutAndCopyToClipboard(sequencerOperationCutAndCopyToClipboardOperationLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationSettingsChangeLogInstance(operationLogToRedo)){
            const sequencerOperationSettingsChangeLog = operationLogToRedo;
            this._sequencer.redoSequencerSettingChange(sequencerOperationSettingsChangeLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationLoadProjectLogInstance(operationLogToRedo)){
            const sequencerOperationLoadProjectLog = operationLogToRedo;
            this._sequencer.redoLoadProject(sequencerOperationLoadProjectLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationCorrectTranscriptSingleSequencerNodeOperationLogInstance(operationLogToRedo)){
            const sequencerOperationCorrectTranscriptSingleSequencerNodeOperationLog = operationLogToRedo;
            this._sequencer.redoCorrectTextOnSequencerNodes(sequencerOperationCorrectTranscriptSingleSequencerNodeOperationLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationRenameSpeakerLabelLogInstance(operationLogToRedo)){
            const sequencerOperationRenameSpeakerLabelLog = operationLogToRedo;
            this._sequencer.redoRenameSpeakerInfo(sequencerOperationRenameSpeakerLabelLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationCreateNewSpeakerAndAssignToSequencerNodesOperationLogInstance(operationLogToRedo)){
            const sequencerOperationCreateNewSpeakerAndAssignToSequencerNodesOperationLog = operationLogToRedo;
            this._sequencer.redoCreateNewSpeakerInfoAndAssign(sequencerOperationCreateNewSpeakerAndAssignToSequencerNodesOperationLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationChangeSpeakerForParagraphOperationLogInstance(operationLogToRedo)){
            const sequencerOperationChangeSpeakerForParagraphOperationLog = operationLogToRedo;
            this._sequencer.redoChangeSpeakerParagraph(sequencerOperationChangeSpeakerForParagraphOperationLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationTranscribeAndReplaceSequencerNodeLogInstance(operationLogToRedo)){
            const sequencerOperationTranscribeAndReplaceSequencerNodeLog = operationLogToRedo;
            this._sequencer.redoTransribeAndReplaceSequencerNode(sequencerOperationTranscribeAndReplaceSequencerNodeLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationDeleteNodeLogInstance(operationLogToRedo)){
            const sequencerOperationDeleteNodeLog =  operationLogToRedo;
            this._sequencer.redoDeleteSequencerNode(sequencerOperationDeleteNodeLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationDeleteAllExceptSelectedNodesLog(operationLogToRedo)){
            const sequencerOperationDeleteAllExceptSelectedNodesLog =  operationLogToRedo;
            this._sequencer.redoDeleteAllExceptSelectedNodes(sequencerOperationDeleteAllExceptSelectedNodesLog);
            return RSVP.Promise.resolve(true);
        }
        if(this.isSequencerOperationInsertAudioOrVideoNodesInBulkLog(operationLogToRedo)){
            const sequencerOperationInsertAudioOrVideoNodesInBulkLog =  operationLogToRedo;
            this._sequencer.redoInsertAudioOrVideoNodesBulk(sequencerOperationInsertAudioOrVideoNodesInBulkLog);
            return RSVP.Promise.resolve(true);
        }
        
        return RSVP.Promise.resolve(true);

    },



    _addOperationLog: function(operationLog){
        if(this._indexOfNextOperationToUndo + 1 < this._operationArray.length){
            //this means there are operations that can be redone so loose those before adding a new one
            this._deleteHistoryStartingAtIndex(this._indexOfNextOperationToUndo + 1)
        }
        this._operationArray.push(operationLog);
        this._indexOfNextOperationToUndo = this._indexOfNextOperationToUndo + 1;
        this.trigger("historyUpdated", operationLog && operationLog.getOperationName && operationLog.getOperationName());
        if(window.trebbleAnalyticsHelper && operationLog){
            window.trebbleAnalyticsHelper.trackEvent("audioEditor", operationLog.getOperationName(), operationLog.getOperationName());
        }
    },

    _addGroupOperationLog: function(groupOperationLogArrayToAdd){
        if(this._indexOfNextOperationToUndo + 1 < this._operationArray.length){
            //this means there are operations that can be redone so loose those before adding a new one
            this._deleteHistoryStartingAtIndex(this._indexOfNextOperationToUndo + 1)
        }
        this._operationArray.push(groupOperationLogArrayToAdd);
        this._indexOfNextOperationToUndo = this._indexOfNextOperationToUndo + 1;
        this.trigger("historyUpdated");
    },

    canUndoOperation : function(){
        return this._indexOfNextOperationToUndo  > -1
    },

    canRedoOperation : function(){
        return this._indexOfNextOperationToUndo + 1 < this._operationArray.length;
    },

    _deleteHistoryStartingAtIndex : function(startingHistoryIndexToDelete){
        this._operationArray = this._operationArray.slice(0, startingHistoryIndexToDelete);
    },


    undo : function(){
        if(this._indexOfNextOperationToUndo > -1){
            const operationLogToUndo  = this._operationArray[this._indexOfNextOperationToUndo];
            if(Array.isArray(operationLogToUndo)){
                //group operation to undo
                return this.undoGroupOperationOnSequencer(operationLogToUndo).then((function(){
                    this._indexOfNextOperationToUndo = this._indexOfNextOperationToUndo - 1;
                    this.trigger("historyUpdated");
                    if(window.trebbleAnalyticsHelper && operationLogToUndo){
                        window.trebbleAnalyticsHelper.trackEvent("audioEditor", "Undo "+operationLogToUndo.getOperationName(), "Undo "+operationLogToUndo.getOperationName());
                    }
                }).bind(this));
            }else{
                //single operation to undo
                this.undoOperationOnSequencer(operationLogToUndo).then((function(){
                    this._indexOfNextOperationToUndo = this._indexOfNextOperationToUndo - 1;
                    this.trigger("historyUpdated");
                    if(window.trebbleAnalyticsHelper && operationLogToUndo){
                        window.trebbleAnalyticsHelper.trackEvent("audioEditor", "Undo "+operationLogToUndo.getOperationName(), "Undo "+operationLogToUndo.getOperationName());
                    }
                }).bind(this));
            }
        }
    },

    redo : function(){
        if(this._indexOfNextOperationToUndo + 1 < this._operationArray.length){
            const operationLogToRedo  = this._operationArray[this._indexOfNextOperationToUndo + 1];
            if(operationLogToRedo){
                if(Array.isArray(operationLogToRedo)){
                    //group operation to redo
                    return this.redoGroupOperationOnSequencer(operationLogToRedo).then((function(){
                        this._indexOfNextOperationToUndo = this._indexOfNextOperationToUndo + 1;
                        this.trigger("historyUpdated");
                        if(window.trebbleAnalyticsHelper && operationLogToRedo){
                            window.trebbleAnalyticsHelper.trackEvent("audioEditor", "Redo "+operationLogToRedo.getOperationName(), "Redo "+operationLogToRedo.getOperationName());
                        }
                    }).bind(this));
                }else{
                    //single operation to redo
                    this.redoOperationOnSequencer(operationLogToRedo).then((function(){
                        this._indexOfNextOperationToUndo = this._indexOfNextOperationToUndo + 1;
                        this.trigger("historyUpdated");
                        if(window.trebbleAnalyticsHelper && operationLogToRedo){
                            window.trebbleAnalyticsHelper.trackEvent("audioEditor", "Redo "+operationLogToRedo.getOperationName(), "Redo "+operationLogToRedo.getOperationName());
                        }
                    }).bind(this));
                }
            }
        }
    },


});

export default SequencerHistoryManagement; 