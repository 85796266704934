import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import TrebbleCardTemplate from 'text!../../../templates/playlistPodcasts/TrebbleCardTemplate.html';
import ti18n from 'i18n!nls/TrebbleCardi18n';
import RSVP from 'rsvp';
import Hammer from 'hammerjs';
import moment from 'moment';

const TIME_IN_MILLISECONDS_TO_HOLD_TO_TRIGGER_PREVIEW = 2000;
const TrebbleCardView = Backbone.View.extend({



    initialize : function(options) {
        this.compiledTemlate =  _.template(TrebbleCardTemplate);
        this.model = options? options.model : null;
        this._customImageUrlProperty = options.customImageUrlProperty? options.customImageUrlProperty : null;
        this._isPlaying = false;
        this._notTransparent = options.notTransparent;
        this._smallcardsize = options.smallcardsize;
        this._notPlayable = options.notPlayable;
        this._notDetailable = options.notDetailable;
        
        //Hack to prevent issue when tap-holding
        this._modelWithPressedEventOnElement = [];
        this._modelWithLastPressedUpEventOnElement = [];
        this._timestampWithLastPressedUpEventOnElement = [];
        this._isPressed = false;
        this._lastPressUpOccurenceTimestamp = 0;

    },
    
    events : {
        //"tap .info" : "onInfoClick",
        "click #followBtn > *:not(.subscribe_tooltip)" : "onFollowBtnClick",
        "click #followingBtn" : "onUnfollowBtnClick",
        "click #followBtn .subscribe_tooltip" : "onSubscribeTooltipIfApplicableClickedOn",
        "click #editButton" : "onEditButtonClick",
        "click #shareTrebbleBtn" : "onShareTrebbleBtnClick",
        "click .itemContent > .coverArt > div > img" : "onInfoClick",
        "click .info *:not(#followingBtn):not(#followBtn):not(.buttonBar):not(.followingBtnLabel):not(.followBtnLabel)" : "onInfoClick",
        /*"click .info .title" : "onInfoClick",
        "click .info .description" : "onInfoClick",
        "click .info .summary" : "onInfoClick",
        "click .info .socialInfos" : "onInfoClick",
        "click .info .footer" : "onInfoClick",*/
        "click .playButton" : "onPlayButtonClick",
        "click .stopButton" : "onStopButtonClick",
        
        //"touchend .info" : "onPressUp",
        //"taphold .info" : "onUserTapHolding", 
        //"mouseover .previewBtn" : "onPress", 
        //"mouseout .previewBtn" : "onPressUp", 
        //"mouseup .info" : "onPressUp",
    },
    

    onFollowBtnClick: function(event){
        event.stopImmediatePropagation();
        this.trigger("follow", this.model);
    },

    onUnfollowBtnClick: function(event){
        event.stopImmediatePropagation();
        this.trigger("unfollow", this.model);
    },

    onSubscribeTooltipIfApplicableClickedOn : function(event){
        event.stopImmediatePropagation();
        this.trigger("subscribeTooltipIfApplicableClickedOn", this.model);
    },

    onPlayButtonClick : function(){
        this.trigger("playTrebble", this.model);
    },

    onStopButtonClick : function(){
        this.trigger("pauseTrebble", this.model);
    },
    
    onEditButtonClick : function(){
        this.trigger("showEditTrebblePage", this.model);
    },

    onShareTrebbleBtnClick : function(){
        this.trigger("showShareTrebblePopup", this.model);
    },

    onInfoClick : function(event){
        if(this._notDetailable){
            return ;
        }
        if(event && event.target)
        {
            const followButton$el = this.$el.find("#followBtn");
            if(followButton$el && followButton$el.get(0) && $.contains(this.$el.find("#followBtn").get(0), event.target)){
                //this event should be ignored as it is most likely the user cliquing on the tooltip inviting him to subscribe
                return;
            }
        }	
        if((new Date()).getTime() - this._lastPressUpOccurenceTimestamp > 1000){
            this.trigger("showTrebbleDetailsPage", this.model);
        }
    },
    
    onPress : function(e){
        this.trigger("playTrebblePreview", this.model);
        this._isPressed = true;
        if(e){
            if(e.stopPropagation){
                e.stopPropagation();
            }
            if(e.srcEvent && e.srcEvent.stopPropagation){
                e.srcEvent.stopPropagation();
            }
        }

    },
    
    onPressUp : function(e){
        this.$el.removeClass("previewLoading");
        if(this._isPressed){
            this._lastPressUpOccurenceTimestamp =  (new Date()).getTime();
            this._isPressed = false;
            this.trigger("stopPreviewAndResumePlayback", this.model);
            if(e){
                if(e.stopPropagation){
                    e.stopPropagation();
                }
                if(e.srcEvent && e.srcEvent.stopPropagation){
                    e.srcEvent.stopPropagation();
                }
            }
        }else{
            this._lastPressUpOccurenceTimestamp =  0;
        }
    },

    
    _addHammerEventListener : function(htmlElement, eventName, handlerFuncBinded){
        const hammerTime = Hammer(htmlElement, {drag_lock_to_axis: true}).on(eventName, handlerFuncBinded, false);
    },

    onUserTapHolding :function(){
        if(!this.$el.hasClass("previewLoading")){
            this.$el.addClass("previewLoading");
        }
    },


    /*onUserStopTapHolding :function(){
        this.$el.removeClass("previewLoading");
    },*/
    
    render : function() {
        const templateParams = {};
        const trebbleJson  = this.model.toJSON();
        this.$el.html(this.compiledTemlate({model: this.model, "customImageUrlProperty": this._customImageUrlProperty, "transparent": !this._notTransparent, "smallcardsize": this._smallcardsize, "notPlayable": this._notPlayable,"notDetailable":this._notDetailable, "ti18n": ti18n, 'moment':moment}));
        this.info$el =this.$el.find(".info");
        this.setElement(this.$el.find("o").first());
        //this.info$el.on("mouseup", this.onPressUp.bind(this));
        //this.info$el.on("click", this.onInfoClick.bind(this));
        //this._addHammerEventListener(this.el, "pressup", this.onPressUp.bind(this));
        //this._addHammerEventListener(this.el, "press", this.onPress.bind(this));
        //Utils.getInstance().addTapAndPressHandler(this.info$el.get(0), TIME_IN_MILLISECONDS_TO_HOLD_TO_TRIGGER_PREVIEW, null, this.onPress.bind(this), null, null);
        return this;
    }

});
export default TrebbleCardView;