import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import SequencerNodeView from "views/audioEditor/sequencerNodeView/SequencerNodeView";
import SequencerUnsupportedNodeTemplate from 'text!../../../../templates/audioEditor/sequencerNodeView/SequencerUnsupportedNodeTemplate.html';
import ti18n from 'i18n!nls/Sequenceri18n';
import RSVP from 'rsvp';


const SequencerUnsupportedNodeView = SequencerNodeView.extend({



    constructor : function(attributes, options) {
        SequencerNodeView.apply(this, [attributes, options]);
        this.compiledTemlate =  _.template(SequencerUnsupportedNodeTemplate);
    },
    
    events : _.extend({
        
    }, SequencerNodeView.prototype.events),


    render : function() {

        this.$el.html(this.compiledTemlate({"model": this.model}));
        this.setElement(this.$el.find("span").first());
        this.afterRendering();

        return this;
    }

});
export default SequencerUnsupportedNodeView;