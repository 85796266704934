import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import ti18n from "i18n!nls/ArtistFullDetailsi18n";
import pageTemplate from 'text!../../../templates/artistDetail/ArtsistFullDetailPage.html';
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import TrebbleListView from "views/common/TrebbleListView";
import listHeaderTemplate from "text!../../../templates/common/listHeaderTemplate.html";
import listFooterTemplate from 'text!../../../templates/common/listFooterTemplate.html';
import EllipsisText from 'text!../../../templates/common/EllipsisText.html';
import RSVP from "rsvp";

const ArtistFullDetailPageView = Backbone.View.extend({

    template : _.template(pageTemplate),
    "pageName" : "Artist Detail",
    listHeaderTemplateCompiled : _.template(listHeaderTemplate),
    listFooterTemplateCompiled : _.template(listFooterTemplate),
    ellipsisTextCompiled : _.template(EllipsisText),
    events : {
        "click #shuffleAlbumBtn" : "onShuffleBtnTapped",
        "click #menuOptionButton" : "onOptionMenuButtonTapped",
        "click #header-back-button": "navigateToPreviousPage",
        "click  #filterButton" : "filterList",
    },

    initialize : function(options) {
        this.artistName = options.artistName;
        this.artistId = options.artistId;
        this.pageId =  options.pageId;
        this.artistModel = options.artistModel;
        this.topSongsListView = options.topSongsListView;
        this.relatedArtistListView = options.relatedArtistListView;
        this.singleAlbumListView = options.singleAlbumListView;
        this.albumListView = options.albumListView;
        this.compilationAlbumListView = options.compilationAlbumListView;
        this.appearOnAlbumListView = options.appearOnAlbumListView;
        this.youtubeVideoListView = options.youtubeVideoListView;
        
        
        this._defaultBackgroundColor = "#111111";
    },
    
    
    filterList : function(){
        this.trigger("filter");
    },
    

    navigateToPreviousPage : function(){
        PersistentModels.getInstance().getRouter().navigateToPreviousPage();
    },
    
    
    onShuffleBtnTapped : function(){
        this.trigger("shuffleAllSongFromArtist",this.model);
    },
    
    onOptionMenuButtonTapped : function(){
        this.trigger("showArtistMenuOptionPopup",this.model);
    },
    
    
    _initTopSongListView : function(){
        this.$topSongResultContentHolder = $(document.createElement('div'));
        this.$topSongResultContentHolder.append(this.listHeaderTemplateCompiled({"id" : "topSongListHeader","text" : window.getI18n(ti18n, "POPULAR_SONGS")}));
        this.$topSongResultContentHolder.append(this.topSongsListView.el);
        this.topSongsListView.$el.css("background-color","#111111");
        this.$topSongResultContentHolder.addClass("left-right-content-padding");
        this.$albumAndTracksSummaryWrapper.append(this.$topSongResultContentHolder);
        this.listenTo(this.topSongsListView, "modelChange",this._onTopSongCollectionChange );
        this._onTopSongCollectionChange();
    },
    

    
    _onTopSongCollectionChange : function(newAlbumCollection){
        if(this.topSongsListView.isCollectionEmpty()){
            this.$topSongResultContentHolder.hide();
        }else{
            this.$topSongResultContentHolder.show();
        }
        this._displayInfoMessageIfNecessary();
    },
    
    

    _initAlbumListView : function(){
        this.$albumResultContentHolder = $(document.createElement('div'));
        this.$albumResultContentHolder.append(this.listHeaderTemplateCompiled({"id" : "albumListHeader","text" : window.getI18n(ti18n, "ALBUMS")}));
        this.$albumResultContentHolder.append(this.albumListView.el);
        this.albumListView.$el.css("background-color","#111111");
        this.$albumListFooter = $(this.listFooterTemplateCompiled({"id" : "albumListFooter","text" : window.getI18n(ti18n, "SEE_ALL_ALBUMS")})).first();
        this.$albumListFooter.addClass("transparent");
        this.$albumResultContentHolder.append(this.$albumListFooter);
        this.$albumListFooter.on("click",this._onSeeAllAlbumsTapped.bind(this));
        this.$albumResultContentHolder.addClass("left-right-content-padding");
        this.$albumAndTracksSummaryWrapper.append(this.$albumResultContentHolder);
        this.listenTo(this.albumListView, "modelChange",this._onAlbumCollectionChange );
        this._onAlbumCollectionChange();
    },
    
    _onSeeAllAlbumsTapped : function(){
        this.trigger("showAllAlbums");
    },
    
    _onAlbumCollectionChange : function(newAlbumCollection){
        if(this.albumListView.isCollectionEmpty()){
            this.$albumResultContentHolder.hide();
        }else{
            this.$albumResultContentHolder.show();
        }
        this._displayInfoMessageIfNecessary();
    },
    
    _initSingleAlbumListView : function(){
        this.$singleAlbumResultContentHolder = $(document.createElement('div'));
        this.$singleAlbumResultContentHolder.append(this.listHeaderTemplateCompiled({"id" : "singleAlbumListHeader","text" : window.getI18n(ti18n, "SINGLES")}));
        this.$singleAlbumResultContentHolder.append(this.singleAlbumListView.el);
        this.singleAlbumListView.$el.css("background-color","#111111");
        this.$singleAlbumListFooter = $(this.listFooterTemplateCompiled({"id" : "singleAlbumListFooter","text" : window.getI18n(ti18n, "SEE_ALL_SINGLES")})).first();
        this.$singleAlbumResultContentHolder.append(this.$singleAlbumListFooter);
        this.$singleAlbumListFooter.on("click",this._onSeeAllSingleAlbumsTapped.bind(this));
        this.$singleAlbumListFooter.addClass("transparent");
        this.$singleAlbumResultContentHolder.addClass("left-right-content-padding");
        this.$albumAndTracksSummaryWrapper.append(this.$singleAlbumResultContentHolder);
        this.listenTo(this.singleAlbumListView, "modelChange",this._onSingleAlbumCollectionChange );
        this._onSingleAlbumCollectionChange();
    },

    _onSeeAllSingleAlbumsTapped : function(){
        this.trigger("showAllSingleAlbums");
    },

    _onSingleAlbumCollectionChange : function(newAlbumCollection){
        if(this.singleAlbumListView.isCollectionEmpty()){
            this.$singleAlbumResultContentHolder.hide();
        }else{
            this.$singleAlbumResultContentHolder.show();
        }
        this._displayInfoMessageIfNecessary();
    },
    
    _initAppearAlbumListView : function(){
        this.$appearAlbumResultContentHolder = $(document.createElement('div'));
        this.$appearAlbumResultContentHolder.append(this.listHeaderTemplateCompiled({"id" : "appearAlbumListHeader","text" : window.getI18n(ti18n, "APPEARS_ON")}));
        this.$appearAlbumResultContentHolder.append(this.appearOnAlbumListView.el);
        this.appearOnAlbumListView.$el.css("background-color","#111111");
        this.$appearAlbumListFooter = $(this.listFooterTemplateCompiled({"id" : "appearAlbumListFooter","text" : window.getI18n(ti18n, "SEE_ALL_APPEARS_ON")})).first();
        this.$appearAlbumResultContentHolder.append(this.$appearAlbumListFooter);
        this.$appearAlbumListFooter.on("click",this._onSeeAllAppearOnAlbumsTapped.bind(this));
        this.$appearAlbumListFooter.addClass("transparent");
        this.$appearAlbumResultContentHolder.addClass("left-right-content-padding");
        this.$albumAndTracksSummaryWrapper.append(this.$appearAlbumResultContentHolder);
        this.listenTo(this.appearOnAlbumListView, "modelChange",this._onAppearOnAlbumCollectionChange );
        this._onAppearOnAlbumCollectionChange();
    },

    _onSeeAllAppearOnAlbumsTapped : function(){
        this.trigger("showAllAppearsOnAlbums");
    },

    _onAppearOnAlbumCollectionChange : function(newAlbumCollection){
        if(this.appearOnAlbumListView.isCollectionEmpty()){
            this.$appearAlbumResultContentHolder.hide();
        }else{
            this.$appearAlbumResultContentHolder.show();
        }
        this._displayInfoMessageIfNecessary();
    },
    
    _initCompilationAlbumListView : function(){
        this.$compilationAlbumResultContentHolder = $(document.createElement('div'));
        this.$compilationAlbumResultContentHolder.append(this.listHeaderTemplateCompiled({"id" : "compilationAlbumListHeader","text" : window.getI18n(ti18n, "COMPILATIONS")}));
        this.$compilationAlbumResultContentHolder.append(this.compilationAlbumListView.el);
        this.compilationAlbumListView.$el.css("background-color","#111111");
        this.$compilationAlbumListFooter = $(this.listFooterTemplateCompiled({"id" : "compilationAlbumListFooter","text" : window.getI18n(ti18n, "SEE_ALL_COMPILATIONS")})).first();
        this.$compilationAlbumResultContentHolder.append(this.$compilationAlbumListFooter);
        this.$compilationAlbumListFooter.on("click",this._onSeeAllCompilationAlbumsTapped.bind(this));
        this.$compilationAlbumListFooter.addClass("transparent");
        this.$compilationAlbumResultContentHolder.addClass("left-right-content-padding");
        this.$albumAndTracksSummaryWrapper.append(this.$compilationAlbumResultContentHolder);
        this.listenTo(this.compilationAlbumListView, "modelChange",this._onCompilationAlbumCollectionChange );
        this._onCompilationAlbumCollectionChange();
    },

    _onSeeAllCompilationAlbumsTapped : function(){
        this.trigger("showAllCompilationsAlbums");
    },

    _onCompilationAlbumCollectionChange : function(newAlbumCollection){
        if(this.compilationAlbumListView.isCollectionEmpty()){
            this.$compilationAlbumResultContentHolder.hide();
        }else{
            this.$compilationAlbumResultContentHolder.show();
        }
        this._displayInfoMessageIfNecessary();
    },
    

    setBiographyView : function(biographyInfo){
        if(biographyInfo && biographyInfo.text){
            this.$bioView = $(this.ellipsisTextCompiled({"text":biographyInfo.text}));
            this.$bioView.attr("id","bioContainer");
            this.$bioView.on("click",this._collapseOrExplandBioView.bind(this));
            const customClass = "customClass"+ (new Date().getTime());
            this._bioViewCustomClass =  customClass;
            this.$bioView.addClass(customClass);
            this.$bioContentHolder.append(this.$bioView);
            if(this.$bioView && this.backgroundColorInfo){
                const bgColor = this.backgroundColorInfo.backgroundColor;
                this.$bioView.css("background-color",this.backgroundColorInfo.backgroundColor);
                $( this._createInlineStyleForEllipsis(bgColor, this._bioViewCustomClass ) ).appendTo(this.$bioView );
            }
            
            this.$bioContentHolder.show();
        }
        
    },
    
    _collapseOrExplandBioView : function(){
        this.$bioView.toggleClass("ellipsis");
    },
    
    _createInlineStyleForEllipsis : function(bgColor, customClass){
        const gradientCss = "to right, rgba(255, 255, 255, 0), " +bgColor+" 15%, " +bgColor;
        return "<style> ." +customClass+".ellipsis:after{ background : -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(" +bgColor+"), color-stop(15%, "+ bgColor+"));  background : -moz-linear-gradient("+gradientCss+");  background : -o-linear-gradient("+gradientCss+"); background : -ms-linear-gradient("+gradientCss+");  background :  linear-gradient("+gradientCss+");}</style>"
    },
    
    _initBiographyView : function(){
        this.$bioContentHolder = $(document.createElement('div'));
        this.$bioContentHolder.append(this.listHeaderTemplateCompiled({"id" : "biographyHeader","text" : window.getI18n(ti18n, "BIOGRAPHY")}));

        this.$bioContentHolder.addClass("left-right-content-padding");
        this.$artistBioAndSocialInfoWrapper.append(this.$bioContentHolder);
        this.$bioContentHolder.hide();
        
    },
    
    _initRelatedArtistListView : function(){
        this.$relatedArtistResultContentHolder = $(document.createElement('div'));
        this.$relatedArtistResultContentHolder.append(this.listHeaderTemplateCompiled({"id" : "relatedArtistListHeader","text" : window.getI18n(ti18n, "RELATED_ARTISTS")}));
        this.$relatedArtistResultContentHolder.append(this.relatedArtistListView.el);
        this.relatedArtistListView.$el.css("background-color","#111111");
        this.$relatedArtistListFooter = $(this.listFooterTemplateCompiled({"id" : "relatedArtistListFooter","text" : window.getI18n(ti18n, "SEE_ALL_RELATED_ARTISTS")})).first();
        this.$relatedArtistResultContentHolder.append(this.$relatedArtistListFooter);
        this.$relatedArtistListFooter.on("click",this._onSeeAllRelatedArtistsTapped.bind(this));
        this.$relatedArtistListFooter.addClass("transparent");
        this.$relatedArtistResultContentHolder.addClass("left-right-content-padding");
        this.$artistBioAndSocialInfoWrapper.append(this.$relatedArtistResultContentHolder);
        this.listenTo(this.relatedArtistListView, "modelChange",this._onRelatedArtistCollectionChange );
        this._onRelatedArtistCollectionChange();
    },

    _onSeeAllRelatedArtistsTapped : function(){
        this.trigger("showAllRelatedArtists");
    },
    
    _onRelatedArtistCollectionChange : function(newArtistCollection){
        if(this.relatedArtistListView.isCollectionEmpty()){
            this.$relatedArtistResultContentHolder.hide();
        }else{
            this.$relatedArtistResultContentHolder.show();
        }
        this._displayInfoMessageIfNecessary();
    },
    
    _initYoutubeVideoListView : function(){
        this.$youtubeVideotResultContentHolder = $(document.createElement('div'));
        this.$youtubeVideotResultContentHolder.append(this.listHeaderTemplateCompiled({"id" : "youtubeVideoListHeader","text" : window.getI18n(ti18n, "YOUTUBE_VIDEOS")}));
        this.$youtubeVideotResultContentHolder.append(this.youtubeVideoListView.el);
        this.youtubeVideoListView.$el.css("background-color","#111111");
        //	this.$youtubeVideoListFooter = $(this.listFooterTemplateCompiled({"id" : "youtubeVideoListFooter","text" : "See All Youtube Videos"})).first();
        //	this.$youtubeVideotResultContentHolder.append(this.$youtubeVideoListFooter);
        //	this.$youtubeVideoListFooter.on("click",this._onSeeAllRelatedArtistsTapped.bind(this));
        //	this.youtubeVideoListFooter.addClass("transparent");
        this.$youtubeVideotResultContentHolder.addClass("left-right-content-padding");
        this.$artistBioAndSocialInfoWrapper.append(this.$youtubeVideotResultContentHolder);
        this.listenTo(this.youtubeVideoListView, "modelChange",this._onYoutubeVideoCollectionChange );
        this._onYoutubeVideoCollectionChange();
    },

    _onSeeAllYouubeVideosTapped : function(){
        this.trigger("showAllYoutubeVideos");
    },
    
    _onYoutubeVideoCollectionChange : function(newArtistCollection){
        if(this.youtubeVideoListView.isCollectionEmpty()){
            this.$youtubeVideotResultContentHolder.hide();
        }else{
            this.$youtubeVideotResultContentHolder.show();
        }
        this._displayInfoMessageIfNecessary();
    },

    render : function() {
        this.$el.html(this.template({"artistName" :this.artistName, "ti18n": ti18n}));
        this.setElement(this.$el.find("div").first());
        this.$el.page();
        if(this.pageId){
            this.$el.attr("id", this.pageId);
        }
        this.$albumAndTracksSummaryWrapper =  this.$el.find("#albumAndTracksSummaryWrapper:first").first();
        this.$artistBioAndSocialInfoWrapper = this.$el.find("#artistBioAndSocialInfoWrapper:first").first();
        this.$el.addClass("lighttext");
        //this.loadArtistImage();
        
        this._initTopSongListView();
        
        this._initSingleAlbumListView();
        this._initAlbumListView();
        this._initAppearAlbumListView();
        this._initCompilationAlbumListView();
        this._initBiographyView();
        this._initRelatedArtistListView();
        this._initYoutubeVideoListView();
        this.updateBackgroundImageAndTextColor();
        this.$el.on("pagehide", this._onPageHide.bind(this));
        return this;
    },
    
    _onPageHide : function(){
        
        if(PersistentModels.getInstance().getRouter().isBrowserHistoryGoingBack())
        {
            //PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._listenerViewportKey);
            this.onBeforeRemoveFromPage();
            this.remove();
            this.onRemovedFromPage();
        }
    },
    
    setArtistModel : function(artistModel){
        this.artistModel = artistModel;
        this.updateBackgroundImageAndTextColor();
    },
    
    _displayInfoMessageIfNecessary : function(){
        /*if(this._infoBoxView.$el)
        {
            if( this._songsListView.isCollectionEmpty()
                && this._albumsListView.isCollectionEmpty() 
                && this._artistsListView.isCollectionEmpty()
                && this._soundcloudSongsListView.isCollectionEmpty()
                && this._usersListView.isCollectionEmpty()
                && this._playlistListView.isCollectionEmpty()
                && this._youtubeVideoListView.isCollectionEmpty()){
if(this.customSearchFieldView.getSearchFieldValue()){
this._setNoResultFounMessagedOnOnInfoBox();
}else{
this._setHelpMessageOnInfoBox();
}
if(!this.customSearchFieldView.isSearchFieldDisplayed()){
this._setHelpMessageOnInfoBox();
}
this._infoBoxView.$el.css("display", "");
}else{
this._infoBoxView.$el.css("display", "none");
}
}*/
//this._scrollSearchContainerToTop();
},




updateBackgroundImageAndTextColor : function(){
const artistName = this.artistModel?this.artistModel.get("artistName"):null;
const artistArtURL = this.artistModel?this.artistModel.get("artistArtURL"):null;
const artistArtImages = this.artistModel?this.artistModel.get("artistArtImages"):null;

const screenWidth = window.getDocumentClienWidth();
const screenHeight = window.getDocumentClienHeight();
const regularCovertArtWidth = window.getDocumentClienWidth() - 10;
const regularCovertArtHeight = window.getDocumentClienHeight() -220;
const thumbnailWidth = 64;
const thumbnailHeight = 64;



return Utils.getInstance().retrieveImageBlurryFromUrl(artistArtURL, 1, 1, artistArtImages).then((function(scaledCoverArtInfo){
if(scaledCoverArtInfo && scaledCoverArtInfo.albumArtBase64BlurScreenSize){
    return Utils.getInstance().getReadyForDisplayBase64Image(scaledCoverArtInfo.albumArtBase64BlurScreenSize);
}else{
    return null;
}
}).bind(this)).then((function(base64AlbumArtBlur){
const artistImageElem = this.el.querySelector("#artistImage");
//artistImageElem.style.height = (window.device && window.device.platform == "browser")?"500px":"200px";
const base64ImageDataBlur = "url('"+ base64AlbumArtBlur + "')";

const tempImg = new Image();
tempImg.onload  = (function(){
    this.elem.style["background-image"] = "url('"+ this.tempImg.src + "')";
    let backgroundInageBase64 = null;
    const fullCoverArtImage$el = this.self.$el.find("#fullCoverArtImage");
    if(window.trebble.config.disableZoomInCoverArtInMobileApp || (window.device && window.device.platform == "browser")){
        backgroundInageBase64 = this.tempImg.src ;
        this.elem.style["background-image"] = base64ImageDataBlur;
        fullCoverArtImage$el.css("background-image","url('"+ this.tempImg.src + "')").addClass("withShadow inFront");
    }else{
        backgroundInageBase64 = this.tempImg.src ;
        this.elem.style["background-image"] = "url('"+ this.tempImg.src + "')";
    }
    Utils.getInstance().getMatchingColorByImageUrl(window.isSafariBrowser? artistArtURL :backgroundInageBase64).then((function(result){
        this.self.backgroundColorInfo = result;
        this.self.$el.css("background-color",result.backgroundColor);
        this.self._defaultBackgroundColor = result.backgroundColor;
        if(result.shouldUserLightColorText){
            this.self.$el.removeClass("darktext");
            this.self.$el.addClass("lighttext");
        }else{
            this.self.$el.removeClass("lighttext");
            this.self.$el.addClass("darktext");
        }
        this.self.$el.find("#contentWrapper").css("background-color",result.backgroundColor);
        if(this.self.topSongsListView){
            this.self.topSongsListView.$el.css("background-color",result.backgroundColor);
        }
        if(this.self.relatedArtistListView){
            this.self.relatedArtistListView.$el.css("background-color",result.backgroundColor);
        }
        if(this.self.singleAlbumListView){
            this.self.singleAlbumListView.$el.css("background-color",result.backgroundColor);
        }
        if(this.self.albumListView){
            this.self.albumListView.$el.css("background-color",result.backgroundColor);
        }
        if(this.self.compilationAlbumListView){
            this.self.compilationAlbumListView.$el.css("background-color",result.backgroundColor);
        }
        if(this.self.appearOnAlbumListView){
            this.self.appearOnAlbumListView.$el.css("background-color",result.backgroundColor);
        }
        if(this.self.youtubeVideoListView){
            this.self.youtubeVideoListView.$el.css("background-color",result.backgroundColor);
        }
        if(this.self.$bioView){
            const bgColor = result.backgroundColor;
            this.self.$bioView.css("background-color",result.backgroundColor);
            $( this.self._createInlineStyleForEllipsis(bgColor, this.self._bioViewCustomClass ) ).appendTo(this.self.$bioView );
        }
        const colorString = result.backgroundColorArray;
        
        const  basicArtistInfo$el = this.self.$el.find(".basicArtistInfo");
        const  ui_header$el = this.self.$el.find(".ui-header");
        fullCoverArtImage$el.css({"background-color": "rgb(" + colorString+")"});
        basicArtistInfo$el.css({"background-image": "-webkit-linear-gradient(top,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
        ui_header$el.css({"background-image": "-webkit-linear-gradient(top,rgba("+ colorString + ",0.5) ,rgba("+ colorString + ",0) 100%)"});
        basicArtistInfo$el.css({"background-image": "-moz-linear-gradient(top,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
        ui_header$el.css({"background-image": "-moz-linear-gradient(top,rgba("+ colorString + ",0.5) ,rgba("+ colorString + ",0) 100%)"});
        basicArtistInfo$el.css({"background-image": "-ms-linear-gradient(top,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
        ui_header$el.css({"background-image": "-ms-linear-gradient(top,rgba("+ colorString + ",0.5) ,rgba("+ colorString + ",0) 100%)"});
        basicArtistInfo$el.css({"background-image": "-o-linear-gradient(top,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
        ui_header$el.css({"background-image": "-o-linear-gradient(top,rgba("+ colorString + ",0.5) ,rgba("+ colorString + ",0) 100%)"});
        basicArtistInfo$el.css({"background-image": "linear-gradient(top,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
        ui_header$el.css({"background-image": "linear-gradient(top,rgba("+ colorString + ",0.5) ,rgba("+ colorString + ",0) 100%)"});
        basicArtistInfo$el.css({"background-image": "progid:DXImageTransform.Microsoft.gradient(startColorstr="+ colorString + ", endColorstr="+ colorString + ")"});
        ui_header$el.css({"background-image": "progid:DXImageTransform.Microsoft.gradient(startColorstr="+ colorString + ", endColorstr="+ colorString + ")"});
        if(Utils.getInstance().isBackgroundBlurImageDisabled()){
            basicArtistInfo$el.css({"background-image": "none"});
        }
    }).bind(this));
}).bind({"elem":artistImageElem,  "tempImg": tempImg, "self": this});
if(artistArtURL){
tempImg.src = artistArtURL;
}else{
tempImg.onload();
}
}).bind(this));





},




onBeforeRemoveFromPage : function(){
this.trigger("beforeRemoveFromPage", this);
},

onRemovedFromPage : function(){
this.undelegateEvents();
this.$el.removeData().unbind(); 
this.trigger("removedFromPage", this);
},





});

export default ArtistFullDetailPageView;