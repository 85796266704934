import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import OptionPopupView from "views/common/OptionPopupView";
import ti18n from "i18n!nls/InstallChromeExtensionHelperi18n";
import installChromeExtensionContent from 'text!../../../templates/installChromeExtensionHelper/InstallChromeExtensionContent.html';

const InstallChromeExtensionHelperPopupView = OptionPopupView.extend({
    
    
    
    "pageName": "",
    
    initialize: function(options) {
        const viewOptions = {};
        const contentView = {};
        const contentViewTemplate = _.template(installChromeExtensionContent);
        const templateParams = options;
        templateParams.ti18n = ti18n;
        contentView.$el = $(contentViewTemplate(templateParams));
        viewOptions.contentView = contentView;
        viewOptions.contentPosition = "center";
        viewOptions.id = "installChromeExtenisionHelperPopup";
        OptionPopupView.prototype.initialize.call(this, viewOptions);
    },
    
    
    
    afterRendering: function() {
         this.$el.find("#downloadExtensionBtn").click((function(){
            this.trigger("downloadExtension");
         }).bind(this));
    },

  
    onPopupClosed: function() {
   
    },
    
  

    
    
    onAfterPageShow: function() {

    },



});

export default InstallChromeExtensionHelperPopupView;
