		import $ from 'jquery';
        import _ from 'underscore';
        import Backbone from "backbone";
        import MixpanelJS from "mixpanel";
        import PusherHelper from "models/helper/PusherHelper";
        import StripeHelper from "models/helper/StripeHelper";
        import Utils from "models/helper/Utils";
        import LocalStorageHelper from "models/helper/LocalStorageHelper";
        import FeatureRolloutHelper from "models/helper/FeatureRolloutHelper";
        import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
        import RSVP from "rsvp";

        const TREBBLE_PLUS_MONTHLY_PRODUCT_ID = "trebble_plus_monthly_2.49";
        const TREBBLE_PLUS_YEARLY_PRODUCT_ID = "trebble_plus_yearly_21.99";

        const STORE_VALIDATION_URL = "https://validator.fovea.cc/v1/validate?appName=fm.trebble&apiKey=876d3517-e18f-471c-8a79-7a0d1a1d65ae";
        const SEPARATOR_CHARACTER_IN_ORDER_ID = ":";

        const SUBS_INFO_AVAILABLE = {
            "trebblePlusMonthly":{
                "id": TREBBLE_PLUS_MONTHLY_PRODUCT_ID
            },
            "trebblePlusYearly":{
                "id": TREBBLE_PLUS_YEARLY_PRODUCT_ID
            }
        };

        const eventTypes = {
            "ON_ERROR" : "onError",
            "ON_SUBSCRIPTION_UPDATED": "onSubscriptionUpdated",
            "ON_CREATOR_SUBSCRIPTION_UPDATED":"onCreatorSubscriptionUpdated",
            "SUBSCRIPTION_ORDER_CANCELLED": "subscriptionOrderCancelled",
            "SUBSCRIPTION_ORDER_COMPLETED": "subscriptionOrderCompleted",
            "SUBSCRIPTION_ORDER_FINISHED": "subscriptionOrderFinished",
            "SUBSCRIPTION_ORDER_REFUNDED": "subscriptionOrderRefunded",
            "SUBSCRIPTION_ORDER_EXPIRED": "subscriptionOrderExpired",
            "TREBBLE_PLUS_MONTHLY_ORDER_FAILED":"trebblePlusMonthlyOrderFailed",
            "TREBBLE_PLUS_YEARLY_ORDER_FAILED":"trebblePlusYearlyOrderFailed",
            "SUBSCRIPTION_ERROR":"subscriptionError",
            "JOURNEY_PURCHASE_INITIATED":"journeyPurchaseInitiated",
            "JOURNEY_PURCHASE_APPROVED":"journeyPurchaseApproved",
            "JOURNEY_PURCHASE_VERIFIED":"journeyPurchaseVerified",
            "JOURNEY_PURCHASE_OWNED":"journeyPurchaseOwned",
            "JOURNEY_PURCHASE_CANCELLED":"journeyPurchaseOwned",
        }

        const Rollout = FeatureRolloutHelper.getInstance();
        const IS_TREBBLE_PLUS_ENABLED =  Rollout.isFeatureEnabled(Rollout.FEATURES.ENABLE_TREBBLE_PLUS_SUB, true);
        const WAIT_FOR_STORE_TO_BE_READY = Rollout.getFeatureVariable(Rollout.FEATURES.ENABLE_TREBBLE_PLUS_SUB, Rollout.FEATURES.ENABLE_TREBBLE_PLUS_SUB.variables.wait_for_store_to_be_ready, false);
        const DOUBLE_CHECK_SUBS_VIA_SERVER = Rollout.getFeatureVariable(Rollout.FEATURES.ENABLE_TREBBLE_PLUS_SUB, Rollout.FEATURES.ENABLE_TREBBLE_PLUS_SUB.variables.doublecheck_subscription_via_server_req, false);

        const MIN_COST_JOURNEY_IN_USD = Rollout.getFeatureVariable(Rollout.FEATURES.JOURNEY_PURCHASE_CONFIG,Rollout.FEATURES.JOURNEY_PURCHASE_CONFIG.variables.min_cost_of_journey_in_usd, 9.99);
        const COST_INCREMENT = Rollout.getFeatureVariable(Rollout.FEATURES.JOURNEY_PURCHASE_CONFIG,Rollout.FEATURES.JOURNEY_PURCHASE_CONFIG.variables.cost_increment_in_usd, 5);
        const MAX_COST_JOURNEY_IN_USD = Rollout.getFeatureVariable(Rollout.FEATURES.JOURNEY_PURCHASE_CONFIG,Rollout.FEATURES.JOURNEY_PURCHASE_CONFIG.variables.max_cost_of_journey_in_usd, 199.99);


        const REGISTER_JOURNEY_CONSUMABLES_ON_ANDROID = Rollout.getFeatureVariable(Rollout.FEATURES.JOURNEY_PURCHASE_CONFIG,Rollout.FEATURES.JOURNEY_PURCHASE_CONFIG.variables.register_journey_consumables_on_android, true);
        const REGISTER_JOURNEY_CONSUMABLES_ON_IOS = Rollout.getFeatureVariable(Rollout.FEATURES.JOURNEY_PURCHASE_CONFIG,Rollout.FEATURES.JOURNEY_PURCHASE_CONFIG.variables.register_journey_consumables_on_ios, true);

        const JOURNEY_PRODUCT_ID_PREFIX = Rollout.getFeatureVariable(Rollout.FEATURES.JOURNEY_PURCHASE_CONFIG,Rollout.FEATURES.JOURNEY_PURCHASE_CONFIG.variables.journey_product_id_prefix, "trebble_journey_with_usd_price_prod_");
        const SubscriptionManager = Backbone.Model.extend({
            initialize : function(options) {
                this._initialized = false;
                this._userIdUsedDuingLastStoreRefresh = null;
                this._lastValueOfIsSubscribedToTrebblePlusFetchFromServer = null;
                this._orderInProgress =  false;
                try{
                    this.listenTo( PusherHelper.getInstance(),PusherHelper.getInstance().eventTypes.WEB_PUSH_NOTIFICATION, this._processNotificationEventWeb.bind(this));
                }catch(e){
                    console.error("Failed initializing Subscription Manager. Error:"+e);
                }
            },

            init : function(){
                try{
                    if(window.waitForCordovaToLoad && IS_TREBBLE_PLUS_ENABLED){
                        if("store" in window){
                            return new RSVP.Promise((function(resolve, reject){
                                if(!WAIT_FOR_STORE_TO_BE_READY){
                                    window.store.ready(true);
                                }
                                //store.autoFinishTransactions = true;
                                window.store.ready((function(){
                                    const productToRegister = [{
                                        id:    SUBS_INFO_AVAILABLE.trebblePlusMonthly.id,
                                        type:   window.store.PAID_SUBSCRIPTION,
                                    }, {
                                        id:    SUBS_INFO_AVAILABLE.trebblePlusYearly.id,
                                        type:   window.store.PAID_SUBSCRIPTION,
                                    }];
                                    if(window.isIOSMobileDevice ){
                                        if(REGISTER_JOURNEY_CONSUMABLES_ON_IOS){
                                            let min_cost_of_journey_registered = MIN_COST_JOURNEY_IN_USD;
                                            while(min_cost_of_journey_registered <= MAX_COST_JOURNEY_IN_USD){
                                                productToRegister.push({
                                                    id:    this._getProductIdFromJourneyCostInUSD(min_cost_of_journey_registered),
                                                    type:   window.store.CONSUMABLE
                                                })
                                                min_cost_of_journey_registered = parseFloat((min_cost_of_journey_registered + COST_INCREMENT).toFixed(2));
                                                
                                            }
                                        }
                                    }else{
                                        if(REGISTER_JOURNEY_CONSUMABLES_ON_ANDROID){
                                            let min_cost_of_journey_registered = MIN_COST_JOURNEY_IN_USD;
                                            while(min_cost_of_journey_registered <= MAX_COST_JOURNEY_IN_USD){
                                                productToRegister.push({
                                                    id:    this._getProductIdFromJourneyCostInUSD(min_cost_of_journey_registered),
                                                    type:   window.store.CONSUMABLE
                                                })
                                                min_cost_of_journey_registered = parseFloat((min_cost_of_journey_registered + COST_INCREMENT).toFixed(2));
                                                
                                            }
                                        }
                                    }


                                    window.store.register(productToRegister);
                                    window.store.applicationUsername = this._getAuthenticatedUserId.bind(this);
                                    window.store.validator = STORE_VALIDATION_URL;
                                    if(!this._initialized){
                                        this.listenTo(LocalStorageHelper.getInstance(), LocalStorageHelper.getInstance().eventTypes.USER_INFO_UPDATED, (function(){
                                            const newUserId = this._getAuthenticatedUserId();
                                            if(newUserId !== this._userIdUsedDuingLastStoreRefresh){
                                                this.refreshSubscriptionsInfoAndUserOwnership();
                                            }
                                        }).bind(this));
                                        window.store.error(this._onError);
                                        window.store.when('subscription').updated(this._onSubscriptionUpdated.bind(this));
                                        window.store.when('subscription').owned(this._onSubscriptionOrderCompleted.bind(this));
                                        window.store.when('subscription').cancelled(this._onSubscriptionOrderCancelled.bind(this));
                                        window.store.when('subscription').refunded(this._onSubscriptionOrderRefunded.bind(this));
                                        window.store.when('subscription').expired(this._onSubscriptionOrderExpired.bind(this));
                                        window.store.when('subscription').error(this._onErrorDuringOrder.bind(this));
                                        window.store.when(SUBS_INFO_AVAILABLE.trebblePlusMonthly.id).error(this._onTrebblePlusMonthlyOrderFailed.bind(this));
                                        window.store.when(SUBS_INFO_AVAILABLE.trebblePlusYearly.id).error(this._onTrebblePlusYearlyOrderFailed.bind(this));
                                        window.store.when('subscription').approved(function(product) {
                                            product.verify();
                                        });
                                        window.store.when('subscription').verified(function(product) {
                                            product.finish();
                                        });
                                    }
                                    this._initialized =  true;

                                    this._userIdUsedDuingLastStoreRefresh = this._getAuthenticatedUserId.bind(this);
                                    window.store.refresh().completed(resolve).failed(reject).cancelled(reject);
                                }).bind(this));
                            }).bind(this));
                        }
                    }else{

                    }
                }catch(e){
                    console.error("Failed initializing Subscription Manager. Error:"+e);
                }
                return RSVP.Promise.resolve();
            },

            _getAuthenticatedUserId : function(){
                const userInfo = LocalStorageHelper.getInstance().getAuthenticatedUserInfo();
                return userInfo? userInfo._id: null;

            },

            _processNotificationEventWeb: function(event, wasDeferred) {
                const isForeground = true;
                return  TrebbleClientAPIHelper.getInstance().getNotificationCustomPayloadByNotificationId(event.notificationId).then((function(customPlayloadJson){
                    const customPlayloadModel =  Utils.getInstance().getModelFromCustomNotificationPayload(customPlayloadJson);
                    const notiticationType =  customPlayloadModel.getNotificationType();

                    if(notiticationType == Utils.getInstance().getNotificationTypes().CREATOR_SUBSCRIPTION_STATUS_CHANGED){
                        return this._onCreatorSubscriptionStatusChanged(customPlayloadModel.getNotificationData())
                    }
                    if(notiticationType == Utils.getInstance().getNotificationTypes().CREATOR_SUBSCRIPTION_DELETED){
                        return this._onCreatorSubscriptionStatusDeleted(customPlayloadModel.getNotificationData())
                    }

                }).bind(this))
            },

            _onCreatorSubscriptionStatusChanged : function(subscriptionInfo){
                this.getTrebbeCreatorSubscriptionUserIsSubscribedTo(true).then((function(subscriptionInfo){
                    this.trigger(eventTypes.ON_CREATOR_SUBSCRIPTION_UPDATED, subscriptionInfo);
                }).bind(this))
            },

            _onCreatorSubscriptionStatusActive : function(subscriptionInfo){

            },

            _onCreatorSubscriptionStatusCancelled : function(subscriptionInfo){

            },

            _onCreatorSubscriptionStatusDeleted : function(subscriptionInfo){
                this.trigger(eventTypes.ON_CREATOR_SUBSCRIPTION_UPDATED, null);
            },

            _onCreatorSubscriptionStatusFailed : function(subscriptionInfo){

            },

            _whenReady : function(forceRefresh){
                try{
                    if(window.waitForCordovaToLoad && IS_TREBBLE_PLUS_ENABLED){
                        if("store" in window){
                            if(window.store.ready()){
                                if(forceRefresh){
                                    return new RSVP.Promise((function(resolve, reject){
                                        this._userIdUsedDuingLastStoreRefresh = this._getAuthenticatedUserId.bind(this);
                                        window.store.refresh().completed(resolve).failed(reject).cancelled(reject);
                                    }).bind(this)).then((function(){
                                        this._waitForProductsToBeInStableState();
                                    }).bind(this));
                                }else{
                                    return this._waitForProductsToBeInStableState();
                                }
                            }else{
                                return this.init().then((function(){
                                    this._waitForProductsToBeInStableState();
                                }).bind(this));
                            }
                        }
                    }else{

                    }
                }catch(e){
                    console.error("Failed initializing Subscription Manager. Error:"+e);
                }
                return RSVP.Promise.resolve();
            },

            _onError : function(errorInfo){
                this.trigger(eventTypes.ON_ERROR,errorInfo);
                if (window.trebbleAnalyticsHelper) {
                    window.trebbleAnalyticsHelper.trackEvent("PaidAccountSubscription", 'ErrorOccuredDuringSubscriptionProcess', 'Error occured during subscription process',null, errorInfo);
                }
                window.sendErrorToRaygun(errorInfo.message);
            },

            _onSubscriptionUpdated : function(product){
                this.trigger(eventTypes.ON_SUBSCRIPTION_UPDATED, product);
            },

            _onSubscriptionOrderCancelled : function(product){
                this.trigger(eventTypes.SUBSCRIPTION_ORDER_FINISHED, product);
                this.trigger(eventTypes.SUBSCRIPTION_ORDER_CANCELLED, product);
            },

            _onSubscriptionOrderCompleted : function(product){
                this.trigger(eventTypes.SUBSCRIPTION_ORDER_FINISHED, product);
                this.trigger(eventTypes.SUBSCRIPTION_ORDER_COMPLETED, product);

            },


            _onSubscriptionOrderRefunded : function(product){
                this.trigger(eventTypes.SUBSCRIPTION_ORDER_REFUNDED, product);
            },

            _onSubscriptionOrderExpired : function(product){
                this.trigger(eventTypes.SUBSCRIPTION_ORDER_EXPIRED, product);
            },

            _onTrebblePlusMonthlyOrderFailed : function(error){
                this.trigger(eventTypes.TREBBLE_PLUS_MONTHLY_ORDER_FAILED, error);
            },

            _onTrebblePlusYearlyOrderFailed : function(error){
                this.trigger(eventTypes.TREBBLE_PLUS_YEARLY_ORDER_FAILED, error);
            },

            _onErrorDuringOrder : function(error){
                this._orderInProgress = false;
                if(error.code == window.store.ERR_PAYMENT_CANCELLED){
                    this.trigger(eventTypes.SUBSCRIPTION_ORDER_FINISHED, null);
                    return this.trigger(eventTypes.SUBSCRIPTION_ORDER_CANCELLED, null);
                }
                this.trigger(eventTypes.SUBSCRIPTION_ERROR, error);
            },

            isOrderInProgress : function(){
                return this._orderInProgress;
            },

            isPaidAccountSubscriptionOrderAvailable : function(){
                return window.waitForCordovaToLoad && IS_TREBBLE_PLUS_ENABLED && window.store ? true : false;
            },

            canUserSubscriptionAlwaysBeRetrieved: function(){
                return DOUBLE_CHECK_SUBS_VIA_SERVER;
            },


            getTrebblePlusMonthlyInfo : function(){
                return this._getSubscriptionInfo(SUBS_INFO_AVAILABLE.trebblePlusMonthly.id);
            },

            getTrebblePlusYearlyInfo : function(){
                return this._getSubscriptionInfo(SUBS_INFO_AVAILABLE.trebblePlusYearly.id);
            },
            _isProductCanBePurchased : function(productId){
                const productInfo = this._getSubscriptionInfo(productId);
                return productInfo && productInfo.canPurchase;
            },




            _orderSubscriptionProduct : function(productId){
                if(this._orderInProgress){
                    return RSVP.Promise.resolve();
                }else{
                    this._orderInProgress = true;
                    const onOrderCancelled = (function(){
                        this._orderInProgress = false;
                        window.store.off(onOrderCancelled);
                        window.store.off(onOrderOwned);
                    }).bind(this)
                    const onOrderOwned = (function(){
                        this._orderInProgress = false;
                        window.store.off(onOrderCancelled);
                        window.store.off(onOrderOwned);
                    }).bind(this)

                    window.store.once(productId).cancelled(onOrderCancelled);
                    window.store.once(productId).owned(onOrderOwned);
                    try{
                        if(window.waitForCordovaToLoad && IS_TREBBLE_PLUS_ENABLED){
                            if("store" in window){
                                return this._whenReady().then((function(){
                                    if(this._isProductCanBePurchased(productId)){
                                        return new RSVP.Promise((function(resolve, reject){
                                            window.store.order(productId).then(resolve).error(reject);
                                        }).bind(this));
                                    }else{
                                        return null;
                                    }
                                }).bind(this))
                            }
                        }else{
                        }
                    }catch(e){
                        console.error("Failed to order Trebble Plus Monthly. Error:"+e);
                    }
                    return RSVP.Promise.resolve();
                }
            },


            orderTrebblePlusMonthlyInfo : function(){
                return this._orderSubscriptionProduct(SUBS_INFO_AVAILABLE.trebblePlusMonthly.id);
            },

            _isProductInStableState :function(productId){
                if(window.waitForCordovaToLoad && IS_TREBBLE_PLUS_ENABLED){
                    if("store" in window){
                        if(window.store.ready()){
                            const productInfo = window.store.get(productId);
                            return productInfo && (productInfo.state === window.store.INVALID || productInfo.state === window.store.VALID || productInfo.state === window.store.OWNED)? true: false;
                        }else{
                            return false;
                        }
                    }
                }else{
                    return true;
                }
                return true;
            },

            _isTrebblePlusSubscriptionInStableState: function(){
                return this._isProductInStableState(SUBS_INFO_AVAILABLE.trebblePlusMonthly.id) || this._isProductInStableState(SUBS_INFO_AVAILABLE.trebblePlusYearly.id);
            },

            manageSubscriptions :function(){
                if(window.waitForCordovaToLoad && IS_TREBBLE_PLUS_ENABLED){
                    if("store" in window){
                        window.store.manageSubscriptions();
                    }
                }else{
                    return null;
                }
            },

            manageBilling :function(){
                if(window.waitForCordovaToLoad && IS_TREBBLE_PLUS_ENABLED){
                    if("store" in window){
                        window.store.manageBilling();
                    }
                }else{
                    return null;
                }
            },

            _waitForProductsToBeInStableState : function(){
                if(window.waitForCordovaToLoad && IS_TREBBLE_PLUS_ENABLED){
                    if("store" in window){
                        if(this._isProductInStableState(SUBS_INFO_AVAILABLE.trebblePlusMonthly.id) && this._isProductInStableState(SUBS_INFO_AVAILABLE.trebblePlusYearly.id)){
                            return RSVP.Promise.resolve();
                        }else{
                            return new RSVP.Promise((function(resolve, reject){
                                const onSubscriptionUpdateListenerFunction = function(){
                                    const trebblePlusMonthlyProductInfo = window.store.get(SUBS_INFO_AVAILABLE.trebblePlusMonthly.id);
                                    const trebblePlusMonthlyProductInfoInStableState = trebblePlusMonthlyProductInfo && (trebblePlusMonthlyProductInfo.state === window.store.INVALID || trebblePlusMonthlyProductInfo.state === window.store.VALID || trebblePlusMonthlyProductInfo.state === window.store.OWNED)? true: false;
                                    const trebblePlusYearlyProductInfo = window.store.get(SUBS_INFO_AVAILABLE.trebblePlusYearly.id);
                                    const trebblePlusYearlyProductInfoInStableState = trebblePlusYearlyProductInfo && (trebblePlusYearlyProductInfo.state === window.store.INVALID || trebblePlusYearlyProductInfo.state === window.store.VALID || trebblePlusYearlyProductInfo.state === window.store.OWNED)? true: false;
                                    if(trebblePlusMonthlyProductInfoInStableState && trebblePlusYearlyProductInfoInStableState){
                                        resolve();
                                        window.store.off(onSubscriptionUpdateListenerFunction);
                                    }
                                }
                                window.store.when('subscription').updated(onSubscriptionUpdateListenerFunction);

                            }).bind(this))


                        }
                    }
                }
                return RSVP.Promise.resolve();
            },

            _checkUserSubscriptionViaRESTAPI : function(){
/*return TrebbleClientAPIHelper.getInstance().isTrebblePaidSubscriptionValid().then((function(result){
this._lastValueOfIsSubscribedToTrebblePlusFetchFromServer =  result && result.userHasAValidPurchase;
return this._lastValueOfIsSubscribedToTrebblePlusFetchFromServer;
}).bind(this));*/
return this._getValidTrebblePaidSubscriptionFromServer(false).then((function(subscriptionProductInfo){
return subscriptionProductInfo && subscriptionProductInfo.owned;
}).bind(this))
},

_getValidTrebblePaidSubscriptionFromServer : function(triggerUpdateEvent){
return TrebbleClientAPIHelper.getInstance().getValidTrebblePaidSubscription().then((function(result){
const validTrebblePaidSubscriptionFromServer = result && result.trebblePlusValidOrderInfo?this._getProductInfoFromProductOrderId(result.trebblePlusValidOrderInfo.productId): null;
const valueChanged = this._lastValueOfIsSubscribedToTrebblePlusFetchFromServer !== !!validTrebblePaidSubscriptionFromServer;
this._lastValueOfIsSubscribedToTrebblePlusFetchFromServer = !!validTrebblePaidSubscriptionFromServer;
if(valueChanged && triggerUpdateEvent){
    this.trigger(eventTypes.ON_SUBSCRIPTION_UPDATED, validTrebblePaidSubscriptionFromServer);
}
return validTrebblePaidSubscriptionFromServer;
}).bind(this));
},

orderTrebblePlusYearlyInfo : function(){
return this._orderSubscriptionProduct(SUBS_INFO_AVAILABLE.trebblePlusYearly.id);
},

updateSubscriptionsInfo : function(){
window.log("Refreshing Subscription Info");
if(window.waitForCordovaToLoad && IS_TREBBLE_PLUS_ENABLED){
if("store" in window){
    if(window.store.ready()){
        return new RSVP.Promise((function(resolve, reject){
            this._userIdUsedDuingLastStoreRefresh = this._getAuthenticatedUserId.bind(this);
            window.store.update();//.completed(resolve).failed(reject).cancelled(reject);
            resolve()
        }).bind(this));
    }else{
        return this.init();
    }
}
}else{

}
return RSVP.Promise.resolve();

},

refreshSubscriptionsInfoAndUserOwnership : function(){
window.log("Refreshing Subscription Info");
if(window.waitForCordovaToLoad && IS_TREBBLE_PLUS_ENABLED){
if("store" in window){
    if(window.store.ready()){
        return new RSVP.Promise((function(resolve, reject){
            this._userIdUsedDuingLastStoreRefresh = this._getAuthenticatedUserId.bind(this);
            window.store.refresh().completed(resolve).failed(reject).cancelled(reject);
        }).bind(this));
    }else{
        return this.init();
    }
}
}else{

}
return RSVP.Promise.resolve();

},

isSandbox : function(){
if(window.waitForCordovaToLoad && IS_TREBBLE_PLUS_ENABLED){
if("store" in window){
    return window.store.sandbox;
}
}else{

}
return false;
},

_getSubscriptionInfo : function(productId){
if(window.waitForCordovaToLoad && IS_TREBBLE_PLUS_ENABLED){
if("store" in window){
    if(window.store.ready()){
        const productInfo = window.store.get(productId);
        return productInfo && (productInfo.state !== window.store.REGISTERED && productInfo.state !== window.store.INVALID)? productInfo: null;
    }else{
        return null;
    }
}
}else{

}
return null;

},

_getProductInfoFromProductOrderId : function(productOrderId){
return productOrderId? this._getSubscriptionInfo(productOrderId.substr(productOrderId.lastIndexOf(SEPARATOR_CHARACTER_IN_ORDER_ID)+1)): RSVP.Promise.resolve(null);
},

isProductionInformationAvailable: function(){
return window.waitForCordovaToLoad && window.store;
},

isCurrentUserSubscribedToTrebblePlus : function(forceRefresh, doNotFetchFromBackend){
try{
if(window.waitForCordovaToLoad && IS_TREBBLE_PLUS_ENABLED){
    if("store" in window){
        return this._whenReady(forceRefresh).then((function(){
            const trebblePlusMonthlySubInfo = this.getTrebblePlusMonthlyInfo();
            const trebblePlusYearlySubInfo = this.getTrebblePlusYearlyInfo();
            return trebblePlusMonthlySubInfo && trebblePlusMonthlySubInfo.owned ||  trebblePlusYearlySubInfo && trebblePlusYearlySubInfo.owned;
        }).bind(this)).then((function(isSubscribedToTrebblePlus){
            if(!isSubscribedToTrebblePlus && DOUBLE_CHECK_SUBS_VIA_SERVER){
                if(!doNotFetchFromBackend ){
                    return this._checkUserSubscriptionViaRESTAPI();
                }else{
                    return this._lastValueOfIsSubscribedToTrebblePlusFetchFromServer;
                }
            }
            return isSubscribedToTrebblePlus;
        }).bind(this))
    }
}else{
    return this._checkUserSubscriptionViaRESTAPI(this._getAuthenticatedUserId());
}
}catch(e){
console.error("Failed checking if the user is subscribed to Trebble plus. Error:"+e);
}
return RSVP.Promise.resolve(false);
},

isCurrentUserSubscribedToTrebbleCreator : function(){
return TrebbleClientAPIHelper.getInstance().getUserActiveCreatorPaidSubscription().then((function(stripeSubscriptionInfo){
    return !!stripeSubscriptionInfo;
}).bind(this))
},

getTrebbeCreatorSubscriptionUserIsSubscribedTo : function(includeDisplayablePaymentMethod){
return TrebbleClientAPIHelper.getInstance().getUserActiveCreatorPaidSubscription(includeDisplayablePaymentMethod).then(function(stripeSubscriptionInfo){
    return stripeSubscriptionInfo;
})
},


getTrebbeCreatorSubscriptionUserThatHaBeenProcessed : function(){
return TrebbleClientAPIHelper.getInstance().getUserProcessedCreatorPaidSubscription().then(function(stripeSubscriptionInfo){
    return stripeSubscriptionInfo;
})
},

changeSubscriptionToCreatorPro : function(){
return TrebbleClientAPIHelper.getInstance().changeSubscriptionToCreatorPro().then(function(stripeSubscriptionInfo){
    return stripeSubscriptionInfo;
})
},


changeSubscriptionToCreatorProPlus : function(){
return TrebbleClientAPIHelper.getInstance().changeSubscriptionToCreatorProPlus().then(function(stripeSubscriptionInfo){
    return stripeSubscriptionInfo;
})
},


changeSubscriptionToCreatorProYearly : function(){
return TrebbleClientAPIHelper.getInstance().changeSubscriptionToCreatorProYearly().then(function(stripeSubscriptionInfo){
    return stripeSubscriptionInfo;
})
},


changeSubscriptionToCreatorProPlusYearly : function(){
return TrebbleClientAPIHelper.getInstance().changeSubscriptionToCreatorProPlusYearly().then(function(stripeSubscriptionInfo){
    return stripeSubscriptionInfo;
})
},

cancelCreatorSubscription: function(){
return TrebbleClientAPIHelper.getInstance().cancelCreatorSubscription().then(function(stripeSubscriptionInfo){
    return !!stripeSubscriptionInfo;
})
},

getTrebbePlusSubscriptionUserIsSubscribedTo : function(){
try{
if(window.waitForCordovaToLoad && IS_TREBBLE_PLUS_ENABLED){
    if("store" in window){
        return this._whenReady().then((function(){
            const trebblePlusMonthlySubInfo = this.getTrebblePlusMonthlyInfo();
            if(trebblePlusMonthlySubInfo && trebblePlusMonthlySubInfo.owned){
                return trebblePlusMonthlySubInfo;
            }
            const trebblePlusYearlySubInfo = this.getTrebblePlusYearlyInfo();
            if(trebblePlusYearlySubInfo && trebblePlusYearlySubInfo.owned){
                return trebblePlusYearlySubInfo;
            }
        }).bind(this)).then((function(trebblePlusSubInfo){
            if(!trebblePlusSubInfo && DOUBLE_CHECK_SUBS_VIA_SERVER){
                return this._getValidTrebblePaidSubscriptionFromServer(true);
            }
            return trebblePlusSubInfo;
        }).bind(this))
    }
}else{

}
}catch(e){
console.error("Failed retrieving subscription user is subscribed to. Error:"+e);
}
return RSVP.Promise.resolve(null);
},

isProductBeingProcessed : function(product){
return product && (product.state === window.store.REQUESTED || product.state === window.store.INITIATED || product.state === window.store.APPROVED || product.state === window.store.DOWNLOADED || product.state === window.store.DOWNLOADING )
},

initiateJourneyPurchase : function(journeySummaryModel, onOrderCompleted, onOrderCancelled, onOrderError, onOrderBeingFullfilled, onOrderBeingVerified){
if(window.waitForCordovaToLoad && "store" in window){
const productId = this._getProductIdFromJourneySummaryModel(journeySummaryModel);
const journeyRadioId =  journeySummaryModel.getLoaderKey();
if(this._orderJourneyInProgress){
    return RSVP.Promise.reject("Journey order currently in progress");
}else{
    //this._orderJourneyInProgress = true;
    try{
        return this._whenReady().then((function(){
            return window.store.get(productId);
        }).bind(this)).then((function(productLoaded){
            if(productLoaded.canPurchase || productLoaded.state === window.store.APPROVED ){

                return new RSVP.Promise((function(resolve, reject){
                    let storeErrorHandler =  null;
                    if(onOrderError){
                        storeErrorHandler = window.store.error(onOrderError);
                    }
                    const safelyExecuteCallback = function(callbackToExecute){
                        try{
                            if(callbackToExecute){
                                callbackToExecute();
                            }
                        }catch(error){
                            console.error(error)
                        }
                    }

                    const cleanHandlers = function(){
                        window.store.off(onOrderCancelled);
                        //window.store.off(onOrderOwned);
                        window.store.off(onOrderJourneyApproved);
                        window.store.off(onOrderJourneyVerified);
                        if(storeErrorHandler){
                            window.store.off(onOrderError);
                        }
                    }

                    const onOrderJourneyCancelled = (function(product){
                        this.trigger(eventTypes.JOURNEY_PURCHASE_CANCELLED, journeyRadioId);
                        this._orderJourneyInProgress = false;
                        cleanHandlers();
                        safelyExecuteCallback(onOrderCancelled);
                    }).bind(this)

                    const onOrderJourneyOwned = (function(product){
                        this.trigger(eventTypes.JOURNEY_PURCHASE_OWNED, journeyRadioId);
                        this._orderJourneyInProgress = false;
                        cleanHandlers();
                        safelyExecuteCallback(onOrderCompleted);
                    }).bind(this)

                    const onOrderJourneyApproved = (function(product){
                        this.trigger(eventTypes.JOURNEY_PURCHASE_APPROVED, journeyRadioId);
                        safelyExecuteCallback(onOrderBeingVerified);
                        TrebbleClientAPIHelper.getInstance().onMobileJourneyPurchaseApproved(JSON.parse(JSON.stringify(product)), journeyRadioId).then(function(){
                            product.verify();
                        })
                        
                    }).bind(this)

                    const onOrderJourneyVerified = (function(product){
                        this.trigger(eventTypes.JOURNEY_PURCHASE_VERIFIED, journeyRadioId);
                        safelyExecuteCallback(onOrderBeingFullfilled);
                        return TrebbleClientAPIHelper.getInstance().onMobileJourneyPurchaseVerified(JSON.parse(JSON.stringify(product)), journeyRadioId).then(function(purchaseFullfilled){
                            if(purchaseFullfilled){
                                product.finish();
                            }
                        })
                    }).bind(this);



                    window.store.once(productId).cancelled(onOrderCancelled);
                    window.store.once(productId).owned(onOrderJourneyOwned);
                    window.store.once(productId).approved(onOrderJourneyApproved);
                    window.store.once(productId).verified(onOrderJourneyVerified);
                    
                    if(productLoaded.state === window.store.APPROVED ){
                        onOrderJourneyApproved(productLoaded);
                    }else{
                        window.store.order(productId).then(resolve).error(reject);
                    }
                    this.trigger(eventTypes.JOURNEY_PURCHASE_INITIATED, journeyRadioId);
                }).bind(this));
            }else{
                return null;
            }
        }).bind(this))

    }catch(e){
        console.error("Failed to order Trebble Plus Monthly. Error:"+e);
    }
    return RSVP.Promise.resolve();
}

}
},
/*
_registerNonConsumableProduct: function(productId){
if(window.store.get(productId)){
return RSVP.Promise.resolve(window.store.get(productId));
}else{
return new RSVP.Promise(function(resolve, reject){
    try{
        const onProductUpdated = function(product){
            if(product.loaded){
                if(product.valid){
                    resolve(product);
                    window.store.off(onProductUpdated);
                }else{
                    reject(product);
                    window.store.off(onProductUpdated);
                }
            }
        }
        window.store.when(productId).updated(onProductUpdated);
        window.store.register([{
            id:   productId,
            type:   window.store.CONSUMABLE,
        }]);
store.refresh();
}catch(error){
reject(error);
}
});
}
},
*/


_getProductIdFromJourneyCostInUSD : function(journeyCostInUSD){
return JOURNEY_PRODUCT_ID_PREFIX +journeyCostInUSD;
},

_getProductIdFromJourneySummaryModel : function(journeySummaryModel){
return this._getProductIdFromJourneyCostInUSD(journeySummaryModel.getCostInUSD());
},


restorePurchases : function(){
if (window.trebbleAnalyticsHelper) {
window.trebbleAnalyticsHelper.trackEvent("PaidAccountSubscription", 'AttemptToRestorePurchase', 'Attempt to restore purchase');
}
try{
if(window.waitForCordovaToLoad && IS_TREBBLE_PLUS_ENABLED){
    if("store" in window){
        return this._whenReady().then((function(){
            return new RSVP.Promise((function(resolve, reject){
                this._userIdUsedDuingLastStoreRefresh = this._getAuthenticatedUserId.bind(this);
                window.store.refresh().finished(resolve);
            }).bind(this));
        }).bind(this));
    }
}else{

}
}catch(e){
console.error("Failed restoring purchases. Error:"+e);
}
return RSVP.Promise.resolve();
},




});

        const SubscriptionManagerInstance = new SubscriptionManager();

        SubscriptionManagerInstance.SUBSCRIPTIONS_INFO = SUBS_INFO_AVAILABLE;
        SubscriptionManagerInstance.eventTypes = eventTypes;

        window.SubscriptionManagerInstanceTest = SubscriptionManagerInstance;


        export default {
			getInstance : function() {
				return SubscriptionManagerInstance;
			}
		};