import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerOperationLog from "models/audioEditor/sequencerOperationLog/SequencerOperationLog";
import RSVP from "rsvp";

const SequencerOperationMoveWrapNodesLog =  SequencerOperationLog.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperationLog.apply(this, [attributes, options]);	
    },

    getOperationName : function(){
        return "Move Wrap Node";
    },

    setStartOrEndWrapSequencerNodeToMove : function(startOrEndWrapSequencerNodeToMove){
        this.set("startOrEndWrapSequencerNodeToMove", startOrEndWrapSequencerNodeToMove);
    },

    getStartOrEndWrapSequencerNodeToMove : function(){
        return this.get("startOrEndWrapSequencerNodeToMove");
    },


    setChangeStartNode : function(changeStartNode){
        this.set("changeStartNode", changeStartNode);
    },

    getChangeStartNode : function(){
        return this.get("changeStartNode");
    },

    setOldStartWrappedNode : function(oldStartWrappedNode){
        this.set("oldStartWrappedNode", oldStartWrappedNode);
    },

    getOldStartWrappedNode : function(){
        return this.get("oldStartWrappedNode");
    },

    setOldEndWrappedNode : function(oldEndWrappedNode){
        this.set("oldEndWrappedNode", oldEndWrappedNode);
    },

    getOldEndWrappedNode : function(){
        return this.get("oldEndWrappedNode");
    },

    setNewStartWrappedNode : function(newStartWrappedNode){
        this.set("newStartWrappedNode", newStartWrappedNode);
    },

    getNewStartWrappedNode : function(){
        return this.get("newStartWrappedNode");
    },

    setNewEndWrappedNode : function(newEndWrappedNode){
        this.set("newEndWrappedNode", newEndWrappedNode);
    },

    getNewEndWrappedNode : function(){
        return this.get("newEndWrappedNode");
    },



});

export default SequencerOperationMoveWrapNodesLog; 