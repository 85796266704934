import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import Utils from "models/helper/Utils";
import ProgressBarView from "views/common/ProgressBarView";
import RSVP from "rsvp";

const ProgressBarController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this._uploadedPercentage = 0;

    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    setPercentageProgress : function(percentageProgress){
        this._percentageProgress = percentageProgress;
        this.view.setPercentageProgress(percentageProgress);
    },

    getPercentageProgress : function(){
        return this._percentageProgress;
    },

    setProgressMessage : function(progressMessage){
        this._progressMessage = progressMessage;
        this.view.setProgressMessage(progressMessage);
    },

    setProgressSubMessage: function(progressSubMessage){
        this._progressSubMessage = progressSubMessage;
        this.view.setProgressSubMessage(progressSubMessage);
    },

    setIndeterminate: function(indeterminate){
        this._indeterminate = indeterminate;
        this.view.setIndeterminate(indeterminate);
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                this.view = new ProgressBarView(this._viewOptions);
                this.view.render();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default ProgressBarController;