

import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import ChooseUploadsWidgetController from "controllers/chooseUploads/ChooseUploadsWidgetController";
import ChooseMusicSoundsAndUploadsPopupView from "views/chooseMusicSoundsAndUploads/ChooseMusicSoundsAndUploadsPopupView";
import PersistentModels from "services/PersistentModels";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import ti18n from "i18n!nls/ConfigureBackgroundMusici18n";

const Rollout = RolloutHelper.getInstance();


const ChooseUploadedVideosPopupController = Chaplin.Controller.extend({

    initialize: function (options)
    {
        _.extend(this, Backbone.Events);
        this._selectedTrebbleVideo = null;
        this._onTrebbleVideoSelected = options.onTrebbleVideoSelected;
        this._onTrebbleVideoUnselected = options.onTrebbleVideoUnselected;
        this._numberOfResultsPerPage  = options.numberOfResultsPerPage;

    },

    getModel: function ()
    {
        return this.model;
    },

    getView: function ()
    {
        return this.view;
    },

    onPopupClosed: function ()
    {
        if (this._onTrebbleVideoSelected)
        {
            this._onTrebbleVideoSelected(this.chooseUploadsWidgetController.getSelectedTrebbleVideo());
        }
        PersistentModels.getInstance().getPlayer().releaseCurrentAudioPreviewIfApplicable();
    },

    onTrebbleVideoSelected: function ()
    {
        this.view.close();
    },

    buildModelAndRenderView: async function ()
    {

        const params = {};
        params.onTrebbleVideoSelected = this.onTrebbleVideoSelected.bind(this);
        params.onTrebbleVideoUnselected = this._onTrebbleVideoUnselected;
        params.numberOfResultsPerPage = this._numberOfResultsPerPage;
        params.marginTop = 80;
        params.videoOnly = true;

        const chooseUploadsWidgetController = new ChooseUploadsWidgetController(params);
        this.chooseUploadsWidgetController = chooseUploadsWidgetController;
        await this.chooseUploadsWidgetController.buildModelAndRenderView();
        this.view = new ChooseMusicSoundsAndUploadsPopupView({ musicSoundAndUploadsWidgetView: this.chooseUploadsWidgetController.getView(),displayTitle: true,titleToDisplay: window.getI18n(ti18n, "SELECT_A_VIDEO") });
        this.listenTo(this.view, "closed", this.onPopupClosed);

        this.view.render();

    }

});

export default ChooseUploadedVideosPopupController;