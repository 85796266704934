import React from "react";
import { Button } from "antd";
import ConfigProvider from "./ConfigProvider";
import useDefaultTrebbleAntDTokenConfig from "../helper/useDefaultTrebbleAntDTokenConfig";

const TrebbleButton = (props) => {
  const themeConfig = useDefaultTrebbleAntDTokenConfig();

  return (
    <ConfigProvider theme={themeConfig}>
      <Button {...props} />
    </ConfigProvider>
  );
};

export default TrebbleButton;
