import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import RSVP from "rsvp";
import Utils from "models/helper/Utils";
import ti18n from "i18n!nls/Sequenceri18n";

const DISFLUENCY_SERVER_ENDPOINT = "https://imgazgovh3homcsodaxsscmwmy0qdzof.lambda-url.us-east-1.on.aws/get-transcript-without-disfluencies";
const DisfluencyDetectorHelper = Backbone.Model.extend({
  idAttribute: "id",

  constructor: function (attributes, options) {
    Backbone.Model.apply(this, [attributes, options]);
    this._disfluencyDetectionInProgress = false;
  },

  getWordCount: function (text) {
       // Match sequences of word characters and apostrophes
       const matches = text.match(/\b[\w']+\b/g);
       // Return the number of matches, or 0 if no matches are found
       return matches ? matches.length : 0;
  },

  getSentencesToSequencerNodes: function (startNode, endNode) {
    const sentences = [];
    const sequencerNodesInfoArrays = [];
    let currentSentence = "";
    let currentSequencerNodeInfoArray = [];
    let sequencerNode = startNode;
    while (sequencerNode) {
      if (Utils.getInstance().isWordSequencerNodeInstance(sequencerNode)) {
        let textContent = sequencerNode.getAudioSegment().getContent();
        if (textContent) {
          if (!textContent.includes(".")) {
            currentSentence = currentSentence === "" ? textContent : currentSentence + " " + textContent;
            currentSequencerNodeInfoArray.push({ sequencerNode, numberOfWords: this.getWordCount(textContent) });
          } else {
            const wordAndSentences = textContent.trim().match(/[^.]+[.!?]*\s*/g);
            wordAndSentences.map((wordOrSentence) => {
              currentSentence = currentSentence === "" ? wordOrSentence : currentSentence + " " + wordOrSentence;
              currentSequencerNodeInfoArray.push({ sequencerNode, numberOfWords: this.getWordCount(wordOrSentence) });
              if (wordOrSentence.includes(".")) {
                sentences.push(currentSentence);
                sequencerNodesInfoArrays.push(currentSequencerNodeInfoArray);
                currentSentence = "";
                currentSequencerNodeInfoArray = [];
              }
            });
          }
        }
      }
      if (sequencerNode === endNode) {
        sequencerNode = null;
      } else {
        sequencerNode = sequencerNode.getNextVisibleSequencerNode();
      }
    }
    return { sentences, sequencerNodesInfoArrays };
  },

  isDisfluencyDetectionInProgress: function () {
    return this._disfluencyDetectionInProgress;
  },

  getDisluencySequencerNodes: async function (startNode, endNode) {
    if (this._disfluencyDetectionInProgress) {
      Utils.getInstance().showWarningNotification(
        window.getI18n(ti18n, "DISFLUENCY_DETECTION_IN_PROGRESS_PLEASE_WAIT"),
      );
      return null;
    } else {
     
      this._disfluencyDetectionInProgress = true;
      const { sentences, sequencerNodesInfoArrays } = this.getSentencesToSequencerNodes(startNode, endNode);
      const { error, results } = await this.detectDisfluencies(sentences);
      const disfluencySequencerNodeInfoArray = [];
      if (error) {
        throw window.getI18n(ti18n, "ERROR_WHILE_DETECTING_DISFLUENCIES");
      } else {
        try{
          if (results && results.removed_disfluencies) {
            results.removed_disfluencies.map((disfluencyIndexArray, index) => {
              if (disfluencyIndexArray && disfluencyIndexArray.length > 0) {
                if (sentences.length > index && sequencerNodesInfoArrays.length > index) {
                  disfluencySequencerNodeInfoArray.push(
                    this.matchDisfluencyTextWithSequencerNode(
                      sentences[index],
                      sequencerNodesInfoArrays[index],
                      disfluencyIndexArray,
                    ),
                  );
                }
              }
            });
          }
        }finally {
            this._disfluencyDetectionInProgress = false;
        }
      }
      this._disfluencyDetectionInProgress = false;
      return disfluencySequencerNodeInfoArray;
    }
  },

  matchDisfluencyTextWithSequencerNode: function (sentence, sequencerNodesInfoArray, disfluencyIndexArray) {
    const disfluenciesInfoWithSequencerNodeArray = [];
    disfluencyIndexArray.map((disfuencyInfo, index) => {
      const disfuencyWordIndex = disfuencyInfo.disfluency_word_index_in_sentence;
      let isLinkedWithNextDisfluency = false;
      if(index + 1 < disfluencyIndexArray.length){
        isLinkedWithNextDisfluency = disfluencyIndexArray[index+1].disfluency_word_index_in_sentence === disfuencyWordIndex+ 1;
      }

      let numberOfWordsCursor = -1;
      for (let i = 0; i < sequencerNodesInfoArray.length; i++) {
        numberOfWordsCursor = numberOfWordsCursor + sequencerNodesInfoArray[i].numberOfWords;
        if (disfuencyWordIndex <= numberOfWordsCursor) {
          disfluenciesInfoWithSequencerNodeArray.push({
            disfluency: disfuencyInfo.disfluency,
            disfuencyWordIndex ,
            sentence,
            sequencerNode: sequencerNodesInfoArray[i].sequencerNode,
            isLinkedWithNextDisfluency
          });
          break;
        }
      }
    });
    return disfluenciesInfoWithSequencerNodeArray;
  },

  detectDisfluencies: async function (sentences) {
    return new RSVP.Promise((resolve, reject) => {
      $.ajax({
        url: DISFLUENCY_SERVER_ENDPOINT,
        type: 'POST',
        contentType: 'application/json', // Set the content type to JSON
        data: JSON.stringify({sentences}), // Convert the JSON object to a string
        success: resolve,
        error: reject
      });
    });
  },
});


const disfluencyDetectorHelperInstance = new DisfluencyDetectorHelper();

export default {
	getInstance : function() {
		return disfluencyDetectorHelperInstance;
	}
}; 