import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import AudioBufferHelper from "models/audioEditor/AudioBufferHelper";
import Utils from "models/helper/Utils";
import AudioPosition from "models/audioEditor/AudioPosition";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import RSVP from "rsvp";

const DEFAULT_MIME_TYPE_FOR_AUDIO_RENDER = "audio/wav";
const Rollout = RolloutHelper.getInstance();
const TURN_ON_HACK_IN_AUDIO_EDITOR_TO_PREVENT_STALLED_PLAYBACK = Rollout.isFeatureEnabled(Rollout.FEATURES.TURN_ON_HACK_IN_AUDIO_EDITOR_TO_PREVENT_STALLED_PLAYBACK);
const DO_RENDER_FILE_FOR_PLAYBACK = true;
const PROPERTIES = {"BUFFER_IS_PLAYING":"renderedBufferIsPlaying", "PLAYING_NODE":"currentlyPlayingSequencerNode","PLAYBACK_LOADING":"renderedBufferPlaybackLoading"}
const SequencerRendererInterface =  Backbone.Model.extend({
    idAttribute: "id",

    constructor: function(attributes, options) {
        Backbone.Model.apply(this, [attributes, options]);
        this._sequencer = attributes.sequencer;
        this.set(PROPERTIES.BUFFER_IS_PLAYING, false);
        this.set(PROPERTIES.PLAYING_NODE, null);
        this._sequencer.setRenderedBufferPlaying(false);
        this._initialize();
    },

    getAudioContext: function(){
        return this.getSequencer().getAudioBufferCache()._getAudioContext();
    },

    _initialize: function(){

    },

    getSequencer: function(){
        return this._sequencer;
    },

    playPauseRenderedBuffer: function(){

    },

    isRenderedBufferPlaying: function(){

    },

    stopPlayingRenderedBuffer : function(bufferSourceFinishPlaying){

    },



    getSequencerNodeAtPositionInMilliseconds: function(positionInMilliseconds){

    },

    isPlaying : function(){

    },

    playRenderedBuffer : function(sequencerNodeToStartFrom, stopAfterMilliseconds, nodeToStopPlayAt, onAudioErrorPlayingHandler){

    },

    getFinalRenderDurationInMilliseconds : function(){

    },

    getPlaybackProgressInMilliseconds : function(){

    },

    getPlaybackAudioContextCurrentTime : function(){

    },

    canPlayThrough : function(){

    },

    getRenderedAudioBlob: function(renderWavFile){

    },

    getRenderedAudioBlobClip: function(renderWavFile, applyMasterProcessing, sequencerNodeToStartFrom, sequencerNodeToEndOn){

    },

    renderSequencer : function(applyMasterProcessing){

    },


});

SequencerRendererInterface.PROPERTIES = PROPERTIES;
export default SequencerRendererInterface; 