import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import ArtistFullDetailPageView from "views/artistDetail/ArtistFullDetailPageView";
import SongListController from "controllers/common/SongListController2";
import AlbumListController from "controllers/common/AlbumListController2";
import ArtistListController from "controllers/common/ArtistListController2";
import YoutubeListController from "controllers/common/YoutubeListController";
import CapsuleCardController from "controllers/capsule/CapsuleCardController";
import YoutubeSearchHelper from "models/helper/YoutubeSearchHelper";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import TrebbleIndexDBHelper from "models/helper/TrebbleIndexDBHelper";
import DynamicContentView from "views/common/DynamicContentView";
import listHeaderTemplate from "text!../../../templates/common/listHeaderTemplate.html";
import ArtistBioSummaryTemplate from 'text!../../../templates/artistDetail/ArtistBioSummaryTemplate.html';
import ti18n from "i18n!nls/ArtistSummaryi18n";
import PersistentModels from "services/PersistentModels";
import PlayerService from "services/PlayerService";
import Utils from "models/helper/Utils";
import ClampJS from 'ClampJS'
import RSVP from "rsvp";
import Swiper from 'swiper';

const NUMBER_OF_RESULTS = 3;
const ArtistSummaryController = Chaplin.Controller.extend({

    listHeaderTemplateCompiled : _.template(listHeaderTemplate),
    
    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if (options) {
            if (options.view) {
                this.view = options.view;
            }
            if (options.artistId) {
                this.model = options.artistId;
                this.artistId = options.artistId;
            }
            if(options.artistModel){
                this.artistModel = options.artistModel;
            }
            if (options.artistName) {
                this.artistName = options.artistName;
            }
            if(options.capsuleRefId){
                this.capsuleRefId = options.capsuleRefId;
            }
            if(options.widgetHeight){
                this.widgetHeight =  options.widgetHeight;
            }
            if(options.showCapsuleInfoOnly){
                this.showCapsuleInfoOnly =  options.showCapsuleInfoOnly;
            }
            this.context = options.context;
            this.pageId = options.pageId;
        }
        

    },
    
    getModel : function(){
        return this.model;
    },
    
    getView : function(){
        return this.view;
    },

    
    
    


    
    showArtistMenuOptionPopup : function(){
        PersistentModels.getInstance().getRouter().showArtistOptionPopup(this.artistModel);
    },
    

    

    filterList : function(filters){
        window.alertErrorMessage(window.getI18n(ti18n,"COMING_SOON"));
    },

    retrieveTopTracks : function(){
        return TrebbleClientAPIHelper.getInstance().getTopTracksByArtistIdOrName(this.artistId, this.artistName).then((function(topSongsJsonArray){
            //const arrayOfSongsWithAlbumAndArtistRef = TrebbleIndexDBHelper.getInstance().getSongsWithAlbumAndArtistInfoFromSongJsonArray(topSongsJsonArray);
            this.topSongsJsonArray = topSongsJsonArray;
            let truncatedtTopSongsJsonArray = topSongsJsonArray;
            if(topSongsJsonArray){
                truncatedtTopSongsJsonArray = topSongsJsonArray.slice(0, NUMBER_OF_RESULTS);
            }
            this.topSongsListController.updateListOfSongs(truncatedtTopSongsJsonArray);
        }).bind(this));
        
    },

    retrieveAlbumsForArtist : function(){
        return TrebbleClientAPIHelper.getInstance().getAlbumsForArtist(this.artistId, this.artistName,  "album", NUMBER_OF_RESULTS, 0).then((function(albumJsonArray){
            this.albumJsonArray = albumJsonArray;
            this.albumListController.updateListOfAlbums(albumJsonArray);
        }).bind(this));
    },
    
    retrieveCompilationForArtist : function(){
        return TrebbleClientAPIHelper.getInstance().getAlbumsForArtist(this.artistId, this.artistName,  "compilation", NUMBER_OF_RESULTS, 0).then((function(albumJsonArray){
            this.compilationAlbumListController.updateListOfAlbums(albumJsonArray);
        }).bind(this));
    },
    
    retrieveSinglesForArtist : function(){
        return TrebbleClientAPIHelper.getInstance().getAlbumsForArtist(this.artistId, this.artistName,  "single", NUMBER_OF_RESULTS, 0).then((function(albumJsonArray){
            this.singleAlbumListController.updateListOfAlbums(albumJsonArray);
        }).bind(this));
    },
    
    retrieveAppearOnForArtist : function(){
        return TrebbleClientAPIHelper.getInstance().getAlbumsForArtist(this.artistId, this.artistName,  "appears_on", NUMBER_OF_RESULTS, 0).then((function(albumJsonArray){
            this.appearOnAlbumListController.updateListOfAlbums(albumJsonArray);
        }).bind(this));
    },

    retrieveRelatedArtists : function(){
        return TrebbleClientAPIHelper.getInstance().getRelatedArtistsByArtistIdOrName(this.artistId, this.artistName).then((function(artistJsonArray){
            this.relatedArtistJsonArray = artistJsonArray;
            let truncatedArray = artistJsonArray;
            if(artistJsonArray){
                truncatedArray = artistJsonArray.slice(0, NUMBER_OF_RESULTS);
            }
            this.relatedArtistListController.updateListOfArtists(truncatedArray);
        }).bind(this));
    },

    retrieveCapsule : function(){
        if(this.capsuleRefId){
            return TrebbleClientAPIHelper.getInstance().getCapsuleInfoByCapsuleId(this.capsuleRefId).then((function(capsuleInfo){
                if(capsuleInfo){
                    const params =  {};
                    this.capsuleInfo = capsuleInfo;
                    params.model = Utils.getInstance().getModelFromCapsuleJson(this.capsuleInfo);
                    params.showActiveFlagInView = false;
                    params.showCommentInLine = false;
                    params.doNotShowSongContext = true;
                    params.doNotShowUrlPreview  = false;
                    params.doNotCapsuleSocialInfo = true;
                    params.doNotPlayButton =  true;
                    params.context = this.context;
                    this.capsuleCardController = new CapsuleCardController(params);
                    return this.capsuleCardController.buildModelAndRenderView();
                }
            }).bind(this));
        }else{
            return RSVP.Promise.resolve();
        }
    },
    
    retrieveYoutubeVideos : function(){
        return YoutubeSearchHelper.getInstance().getSongJsonFromVideos(this.artistName, NUMBER_OF_RESULTS,true).then((function(arrayOfYoutubeVideoInfo){
            const arrayOfYoutubeVideosWithAlbumAndArtistRef = TrebbleIndexDBHelper.getInstance().getSongsWithAlbumAndArtistInfoFromSongJsonArray(arrayOfYoutubeVideoInfo);
            this.youtubeVideosJsonArray = arrayOfYoutubeVideosWithAlbumAndArtistRef
            this.youtubeVideoListController.updateListOfSongs(arrayOfYoutubeVideosWithAlbumAndArtistRef);
            
        }).bind(this));
    },
    
    retrieveArtistInfo : function(){
        if(!this.artistModel || !this.artistModel.get("artistId") || this.artistModel.get("artistId").indexOf("spotify:artist") != 0 ){
            return  TrebbleClientAPIHelper.getInstance().getArtistInfoByArtistIdOrName(this.artistId, this.artistName).then((function(artistJson){
                this.artistModel = Utils.getInstance().getModelFromArtistJson(artistJson);
                if(this.artistModel){
                    this.artistId = this.artistModel.get("artistId");
                    this.artistName = this.artistModel.get("artistName");
                }
                //.view.setArtistModel(this.artistModel);
            }).bind(this));
        }else{
            //this.view.setArtistModel(this.artistModel);
            return  RSVP.Pormise.resolve();
        }
    },
    
    retrieveArtistBio : function(){
        
        return 	TrebbleClientAPIHelper.getInstance().getArtistBiographyInfoByArtistIdOrName(this.artistId, this.artistName).then((function(artistBioJson){
            this.artistBioJson = artistBioJson;
            //this.view.setBiographyView(this.artistBioJson);
        }).bind(this));
        
    },
    
    retrieveArtistDetails : function(){
        return this.retrieveArtistInfo().then((function(){
            const promises = [];
            promises.push(this.retrieveTopTracks());
            promises.push(this.retrieveArtistBio());
            promises.push(this.retrieveAlbumsForArtist());
            //this.retrieveCompilationForArtist();
            //this.retrieveSinglesForArtist();
            //this.retrieveAppearOnForArtist();
            promises.push(this.retrieveRelatedArtists());
            promises.push(this.retrieveYoutubeVideos());
            promises.push(this.retrieveCapsule());
            return RSVP.allSettled(promises);
        }).bind(this));
        
        
        
    },
    
    showAllRelatedArtists : function(){
        const loadHandler = (function(){
            return TrebbleClientAPIHelper.getInstance().getRelatedArtistsByArtistIdOrName(this.artistId, this.artistName);
        }).bind(this);
        PersistentModels.getInstance().getRouter().showAllRelatedArtistListPage(this.artistId, loadHandler, window.getI18n(ti18n,"RELATED_ARTISTS"), true);
    },
    
    showAllSinglesArtists : function(){
        const loadHandler = (function(){
            return TrebbleClientAPIHelper.getInstance().getAlbumsForArtist(this.artistId, this.artistName,  "single");
        }).bind(this);
        PersistentModels.getInstance().getRouter().showAllAlbumListPage(this.artistId, loadHandler, window.getI18n(ti18n,"SINGLES"), true);
    },
    
    showAllCompilationsArtists : function(){
        const loadHandler = (function(){
            return TrebbleClientAPIHelper.getInstance().getAlbumsForArtist(this.artistId, this.artistName,  "compilation");
        }).bind(this);
        PersistentModels.getInstance().getRouter().showAllAlbumListPage(this.artistId, loadHandler, window.getI18n(ti18n,"COMPILATIONS"), true);
    },
    
    showAllAlbumsArtists : function(){
        const loadHandler = (function(){
            return TrebbleClientAPIHelper.getInstance().getAlbumsForArtist(this.artistId, this.artistName,  "album");
        }).bind(this);
        PersistentModels.getInstance().getRouter().showAllAlbumListPage(this.artistId, loadHandler, window.getI18n(ti18n,"ALBUMS"), true);
    },


    showAllAppearsOnArtists : function(){
        const loadHandler = (function(){
            return TrebbleClientAPIHelper.getInstance().getAlbumsForArtist(this.artistId, this.artistName,  "appears_on");
        }).bind(this);
        PersistentModels.getInstance().getRouter().showAllAlbumListPage(this.artistId, loadHandler, window.getI18n(ti18n,"APPEARS_ON"), true);
    },

    
    _buildCarousel : function(){
        this.carousel$el =  $("<div></div>");
        this._swiperArtistIdContainerId = "artistCarouselContainer"+ (new Date()).getTime();
        this.carousel$el.append("<div id='"+this._swiperArtistIdContainerId+"' class='swiper-container'><div class='"+ "swiper-wrapper"+"'> </div> <div class='swiper-pagination'></div>"+ this._buildNavigationButtons()+"</div>");
        this.carouselContainer$el = this.carousel$el.find(".swiper-wrapper");
        if(this.capsuleCardController){
            this.capsuleRefInfo$el = $(this._buildCarouselItemHtml("capsuleCarouselItem"));
            this.capsuleRefInfo$el.find(".artistSummaryItem").append(this.listHeaderTemplateCompiled({"id" : "capsuleCarouselItemHeader","text" : window.getI18n(ti18n,"SHORTCAST")}));
            this.capsuleCardController.getView().$el.css("background-color","transparent");
            const capsuleNote$el = this.capsuleCardController.getView().$el.find(".note");
            if(this.widgetHeight){
                const headerHeight =  69;
                const margin = 20;
                const capsuleCardHeight = this.widgetHeight - headerHeight - margin;
                this.capsuleCardController.getView().$el.css("height", capsuleCardHeight +"px");
            }
            capsuleNote$el.css("-webkit-flex","1 1 100%");
            capsuleNote$el.css("-ms-flex","1 1 100%");
            capsuleNote$el.css("-moz-flex","1 1 100%");
            capsuleNote$el.css("flex","1 1 100%");
            this.capsuleRefInfo$el.find(".artistSummaryItem").append(this.capsuleCardController.getView().$el);
            this.carouselContainer$el.append(this.capsuleRefInfo$el);
        }
        if(!this.showCapsuleInfoOnly && this.artistModel && this.artistModel.get("artistName")){
            const artistBioSummaryTemplateParams = {};
            artistBioSummaryTemplateParams.artistName = this.artistModel.get("artistName");
            artistBioSummaryTemplateParams.artistImageUrl = this.artistModel.get("artistArtURL");
            artistBioSummaryTemplateParams.artistArtImages = this.artistModel.get("artistArtImages");
            artistBioSummaryTemplateParams.bio = this.artistBioJson? this.artistBioJson.text : "";
            this.bioCarousel$el = $(this._buildCarouselItemHtml("bioCarouselItem"));
            //this.bioCarousel$el.find(".artistSummaryItem").append(this.listHeaderTemplateCompiled({"id" : "bioCarouselItemHeader","text" : "Biography"}));
            this.bioCarousel$el.find(".artistSummaryItem").append($((_.template(ArtistBioSummaryTemplate))(artistBioSummaryTemplateParams)));
            if(ClampJS){
                //if clamp library is available , clamp text
                ClampJS(this.bioCarousel$el.find(".bio").get(0), {clamp: 4});
            }
            this.carouselContainer$el.append(this.bioCarousel$el);
            if(this.topSongsJsonArray && this.topSongsJsonArray.length > 0){
                this.topSongsCarousel$el = $(this._buildCarouselItemHtml("topSongsCarouselItem"));
                this.topSongsCarousel$el.find(".artistSummaryItem").append(this.listHeaderTemplateCompiled({"id" : "topSongListHeader","text" : window.getI18n(ti18n,"TOP_SONGS")}));
                this.topSongsListView.$el.css("background-color","transparent");
                this.topSongsCarousel$el.find(".artistSummaryItem").append(this.topSongsListView.$el);
                this.carouselContainer$el.append(this.topSongsCarousel$el);
            }
            if(this.albumJsonArray && this.albumJsonArray.length > 0){
                this.albumsCarousel$el = $(this._buildCarouselItemHtml("albumsCarouselItem"));
                this.albumsCarousel$el.find(".artistSummaryItem").append(this.listHeaderTemplateCompiled({"id" : "albumsListHeader","text" : window.getI18n(ti18n,"ALBUMS")}));
                this.albumListView.$el.css("background-color","transparent");
                this.albumsCarousel$el.find(".artistSummaryItem").append(this.albumListView.$el);
                this.carouselContainer$el.append(this.albumsCarousel$el);
            }
            if(this.youtubeVideosJsonArray && this.youtubeVideosJsonArray.length > 0){
                this.youtubeVideosCarousel$el = $(this._buildCarouselItemHtml("youtubeVideosCarouselItem"));
                this.youtubeVideosCarousel$el.find(".artistSummaryItem").append(this.listHeaderTemplateCompiled({"id" : "youtubeVideosListHeader","text" : window.getI18n(ti18n,"YOUTUBE_VIDEOS")}));
                this.youtubeVideoListView.$el.css("background-color","transparent");
                this.youtubeVideosCarousel$el.find(".artistSummaryItem").append(this.youtubeVideoListView.$el);
                this.carouselContainer$el.append(this.youtubeVideosCarousel$el);
            }
            
        }
        const swiperParams  =  {
            speed: window.isMobileBrowser?200: 300,
            longSwipesRatio : window.isMobileBrowser?0.2: 0.4,
            watchSlidesProgress: true,
            preloadImages: false,
            watchSlidesVisibility: true,
            slidesPerView: 1,
            centeredSlides: true,
            paginationHide: false,
            slideToClickedSlide: true,
            lazyLoading: true,
            lazyLoadingInPrevNext: true,
            lazy: {
                loadPrevNext: true,
            },
            pagination: this.carousel$el.find('.swiper-pagination').get(0),
            paginationClickable: true

        };
        if(window.device && window.device.platform =="browser"){
            //swiperParams.nextButton = '.swiper-button-next';
            //swiperParams.prevButton = '.swiper-button-prev';
            
            swiperParams.keyboardControl = true;
            swiperParams.mousewheelControl = false;
            swiperParams.mousewheel =  {
                enabled: true
            };
            swiperParams.keyboard =  {
                enabled: true,
                onlyInViewport: false
            };

        }
        this._artistSummaryCarouselSwiperObj = new Swiper(this.carousel$el.find("#"+this._swiperArtistIdContainerId).get(0),swiperParams);
    },
    
    updateSwiperWidget : function(){
        if(this._artistSummaryCarouselSwiperObj){
            this._artistSummaryCarouselSwiperObj.update();
        }
    },

    
    _buildCarouselItemHtml : function(chartId){
        return "<div class='slideItemWrapper swiper-slide' style='width:100%;'><div class='artistSummaryItem' id='"+chartId +"' ></div></div>";
    },
    
    _buildNavigationButtons : function(){
        if(window.device && window.device.platform != "browser"){
            return  "";
        }else{
            return    ""; // '<div class="swiper-button-next ion-ios7-arrow-right"></div> <div class="swiper-button-prev ion-ios7-arrow-left"></div>';
        }
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve,reject){
            try{
                const defaultParams  = {};
                defaultParams.doNotSortDisplayedCollection = true;
                defaultParams.resizePlaceHolderElem = true;
                defaultParams.displaySongOnDeviceOnFirstLoading = false;
                defaultParams.showEmtptyInfoBox = false;
                const context =  Utils.getInstance().buildContextForSongCollectionArtist(this.artistId);
                defaultParams.context = context;

                this.topSongsListController = new SongListController(defaultParams);
                this.relatedArtistListController =  new ArtistListController(defaultParams);
                this.singleAlbumListController =  new AlbumListController(defaultParams);
                this.albumListController =  new AlbumListController(defaultParams);
                this.appearOnAlbumListController =  new AlbumListController(defaultParams);
                this.compilationAlbumListController =  new AlbumListController(defaultParams);
                this.youtubeVideoListController = new YoutubeListController(defaultParams);

                const promiseArray = [];
                promiseArray.push(this.topSongsListController.buildModelAndRenderView());
                promiseArray.push(this.relatedArtistListController.buildModelAndRenderView());
                promiseArray.push(this.singleAlbumListController.buildModelAndRenderView());
                promiseArray.push(this.albumListController.buildModelAndRenderView());
                promiseArray.push(this.compilationAlbumListController.buildModelAndRenderView());
                promiseArray.push(this.appearOnAlbumListController.buildModelAndRenderView());
                promiseArray.push(this.youtubeVideoListController.buildModelAndRenderView());

                
                
                RSVP.Promise.all(promiseArray).then((function(){
                    
                    this.topSongsListView = this.topSongsListController.getView();
                    this.relatedArtistListView = this.relatedArtistListController.getView();
                    this.singleAlbumListView = this.singleAlbumListController.getView();
                    this.albumListView = this.albumListController.getView();
                    this.compilationAlbumListView = this.compilationAlbumListController.getView();
                    this.appearOnAlbumListView = this.appearOnAlbumListController.getView();
                    this.youtubeVideoListView = this.youtubeVideoListController.getView();
                    
                    this.topSongsCollection = this.topSongsListController.getModel();
                    this.relatedArtistCollection = this.relatedArtistListController.getModel();
                    this.singleAlbumCollection = this.albumListController.getModel();
                    this.albumCollection = this.albumListController.getModel();
                    this.compilationAlbumCollection = this.compilationAlbumListController.getModel();
                    this.appearOnAlbumCollection = this.appearOnAlbumListController.getModel();
                    this.youtubeVideoCollection = this.youtubeVideoListController.getModel();
                    const contentView =new DynamicContentView();
                    this.view = contentView;
                    this.view.$el.css("width","100%");
                    this.view.render();
                    this.retrieveArtistDetails().then((function(){
                        this._buildCarousel();
                        if(this.carousel$el){
                            contentView.$el.append(this.carousel$el);
                        }
                        resolve();
                    }).bind(this)).catch((function(error){
                        reject(error);
                    }).bind(this));

                    


                }).bind(this)).catch(reject);
}catch(error){
reject(error);
}

}).bind(this));


}

});
export default ArtistSummaryController;