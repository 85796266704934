import React from "react";
import ti18n from "i18n!nls/MyLibraryi18n";
import PersistentModels from "../services/PersistentModels";
import Utils from "../models/helper/Utils";
import TrebbleClientAPI from "../models/helper/TrebbleClientAPI";
import useDefaultTrebbleAntDTokenConfig from "./helper/useDefaultTrebbleAntDTokenConfig";
import PropTypes from "prop-types";
import InfiniteList, { ListItem, ListItemMeta, Skeleton, Avatar } from "./common/InfiniteList";
import Button from "./common/Button";
import moment from 'moment';


const UserTranscribedFileList = (props) => {
    
    const {inDarkMode} = useDefaultTrebbleAntDTokenConfig();
    const fetchTranscribedFiles = (pageIndex, pageSize)=>{
        return TrebbleClientAPI.getInstance().getTranscribedFiles(props.searchFieldValue, pageIndex, pageSize);
    };
    return (
        <InfiniteList
        containerId="transcribedFileListContainer"
        containerHeightMargin={props.containerHeightMargin}
        locale={{ emptyText: window.getI18n(ti18n, "NO_TRANSCRIPTION_FOUND") }}
        header={
          <div className="ant-list-item font-semibold">
            <div className="grow">
              <div className="w-9 inline-block" />
              {window.getI18n(ti18n, "NAME")}
            </div>
            <div className="py-0 px-1 w-52 hidden lg:block">{window.getI18n(ti18n, "DATE")}</div>
            <div className="py-0 px-1 w-24 hidden lg:block">{window.getI18n(ti18n, "LANGUAGE")}</div>
            <div className="py-0 px-1 w-24 hidden lg:block">{window.getI18n(ti18n, "DURATION")}</div>
            <div className="py-0 px-1 w-24 hidden lg:block">{window.getI18n(ti18n, "STATUS")}</div>
            <div className="py-0 px-1 w-[114px] ml-12" />
          </div>
        }
        containerClassname={`overflow-auto py-0 px-4 ${inDarkMode? "": "bg-white"} max-w-6xl m-auto`}
        fetchData={fetchTranscribedFiles}
        loader={
          <div>
            <Skeleton className="py-5" paragraph={{ rows: 1, width: "80%" }} active />
            <Skeleton className="py-5" paragraph={{ rows: 1, width: "80%" }} active />
          </div>
        }
        renderItem={(item) => {
          return (
            <ListItem
              key={item._id}
              actions={[
                <Button
                  key={0}
                  type="link"
                  onClick={() => {
                    PersistentModels.getInstance().getRouter().showCreateShortcastPageFromTranscription(item._id,false, true);
                  }}
                >
                  <i className="pe-7s-note text-xl my-0 mx-2.5" />
                  <div className="hidden lg:inline-block">{window.getI18n(ti18n, "EDIT").toUpperCase()}</div>
                </Button>,
              ]}
            >
              <ListItemMeta
                avatar={<i className={item.isVideo ? "pe-7s-video text-xl" : "pe-7s-micro text-xl"} />}
                title={
                  <span className="py-0 px-1">
                    {item.filenameTranscribed
                      ? item.filenameTranscribed
                      : moment(new Date(item.jobCreationDate)).format("MMMM D, YYYY h:mm A")}
                  </span>
                }
              />
              <div className="py-0 px-1 w-52  hidden lg:block">
                {Utils.getInstance().formatDateInAReadableFomat(new Date(item.jobCreationDate))}
              </div>
              <div className="py-0 px-1 w-24  hidden lg:block">
                {Utils.getInstance().getLanguageLabelByLanguageCode(item.languageCode)}
              </div>
              <div className="py-0 px-1 w-24  hidden lg:block">
                {Utils.getInstance().getFormattedTimeDurationForDisplayInPlayer(
                  item.exactAudioDurationInMilliseconds? item.exactAudioDurationInMilliseconds / 1000: item.audioDurationInMilliseconds / 1000,
                )}
              </div>
              <div className="py-0 px-1 w-24  hidden lg:block">
                {Utils.getInstance().getTranscripionJobStatusI18nFromValue(item.trebbleJobStatus)}
              </div>
            </ListItem>
          );
        }}
        pageSize={props.pageSize}
      />
    )
}

UserTranscribedFileList.propTypes = {
    containerHeightMargin: PropTypes.number,
    pageSize: PropTypes.number,
    searchFieldValue: PropTypes.string,
};


export default UserTranscribedFileList;

