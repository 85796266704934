import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlaylistHelper from "models/helper/PlaylistHelper";
import Utils from "models/helper/Utils";
import JourneyOptionController from "controllers/journey/JourneyOptionController";
import Hammer from 'hammerjs';
import FileUploadHelper from "models/helper/FileUploadHelper";

//"models/helper/FileUploadHelper",
import PersistentModels from "services/PersistentModels";

import PlayerService from "services/PlayerService";
import RSVP from "rsvp";
import JourneyDetailsController from 'controllers/common/JourneyDetailsController';
import JourneyOutlineItemController from "controllers/journey/JourneyOutlineItemController";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import CollectionRepeatController from "controllers/common/CollectionRepeatController";
import SingleSongSelectorInputView from "views/common/SingleSongSelectorInputView";
import RatingSummaryCardView from "views/common/RatingSummaryCardView";
import Capsules from "collections/Capsules";
import JourneySummary from "models/JourneySummary";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import Sortable from "libs/sortablejs/Sortable";
import ti18n from "i18n!nls/MyPersonalRadiosi18n";
import PageTemplate from 'text!../../../templates/journey/JourneyDetails.html';
import swal from 'sweetalert';
import moment from 'moment';

const SHOW_WHAT_S_A_CAPSULE_FLOATING_BUTTON = false;
const MARK_TREBBLE_AS_NOT_PLAYABLE =  true;
const MAX_NUMBER_OF_ARCHIVED_CAPSULES_TO_LOAD_IN_QUICK_START_MODE = 20;
const Rollout = RolloutHelper.getInstance();
const MIN_NUMBER_OF_ACTIVE_CAPSULES_TO_PUBLISH = Rollout.getFeatureVariable(Rollout.FEATURES.SHORTCAST_JOURNEY_DEFAULT_CONFIG, Rollout.FEATURES.SHORTCAST_JOURNEY_DEFAULT_CONFIG.variables.min_number_of_shortcast_required, 3);
const MIN_NUMBER_OF_SHORTCAST_LISTENED_TO_REVIEW = Rollout.getFeatureVariable(Rollout.FEATURES.CREATE_JOURNEY_USER_REVIEWS, Rollout.FEATURES.CREATE_JOURNEY_USER_REVIEWS.variables.number_of_shortcast_listened_to_leave_review, 4);
const IS_TRAILER_REQUIRED_FOR_PUBLISHING = Rollout.getFeatureVariable(Rollout.FEATURES.SHORTCAST_JOURNEY_DEFAULT_CONFIG, Rollout.FEATURES.SHORTCAST_JOURNEY_DEFAULT_CONFIG.variables.trailer_required_for_publising, true);
const SHOW_PROGRESS_BAR_ON_JOURNEY_PAGE = Rollout.isFeatureEnabled(Rollout.FEATURES.SHOW_PROGRESS_BAR_ON_JOURNEY_PAGE, true);
const SHOW_COLOURED_PROGRESS_BAR = Rollout.getFeatureVariable(Rollout.FEATURES.SHOW_PROGRESS_BAR_ON_JOURNEY_PAGE, Rollout.FEATURES.SHOW_PROGRESS_BAR_ON_JOURNEY_PAGE.variables.coloured_progress_bar, true);
const MyPersonalJourneyDetailsController = JourneyDetailsController.extend({


    initialize : function(options) {
        options.getAllSongsInPlaylistHandler  = this.loadPlaylistDetails.bind(this);
        options.pageTemplate = PageTemplate;
        this._isPublicPage = options.isPublicPage;
        this._isInPreviewMode = options.isInPreviewMode;
        this._showBackButton = options.showBackButton;
        this._doNotRemoveFromDocument = options.doNotRemoveFromDocument;
        this._customCapsuleLoadHandler = options.customCapsuleLoadHandler;
        this._customCapsuleTitleHeader = options.customCapsuleTitleHeader;
        this._userOwnerModel =  null;
        this._capsuleFinishLoading = false;
        this._trailerCapsuleInfoJSON = null;
        this._trailerCapsuleModel = null;
        

        const eventHandlerMap = JourneyDetailsController.prototype._setupEventForDefaultPageTemplate.call(this);
        this._detailedPlaylistInfoRetrieved = false;
        eventHandlerMap["click #startRadioBtn:not(.notplayable)"] = this.playTrebble.bind(this);
        eventHandlerMap["click #playTrailerBtn"] = this.startPlayingOrPausingTrailerCapsule.bind(this);
        eventHandlerMap["click #pauseRadioBtn"] = this.pauseTrebble.bind(this);
        eventHandlerMap["click #playbTrebbletn"] = this.playTrebble.bind(this);
        eventHandlerMap["click #stopTrebbletn"] = this.pauseTrebble.bind(this);
        eventHandlerMap["click div[data-role=header] #menuOptionButton"] = this.showOptionPopup.bind(this);
        eventHandlerMap["click div[data-role=header] #editButton"] = this.showEditPage.bind(this);
        eventHandlerMap["click #pageHeaderTitleWrapper > .editable"] = this.showEditPage.bind(this);
        eventHandlerMap["click .playlistDescription.editable"] = this.showEditPage.bind(this);
        eventHandlerMap["click .jouney_summary_detail_item.goal_info.editable"] = this.showEditPage.bind(this);

        eventHandlerMap["click #addSongsBtn"] = this.showChooseCapsuleFromLibraryAndAddToJourney.bind(this);
        eventHandlerMap["click #fabAddButtonContainer.expanded #addSongFabBtn"] = this.showChooseCapsuleFromLibraryAndAddToJourney.bind(this);
        eventHandlerMap["click #fabAddButtonContainer.expanded #addCapsuleFabBtn"] = this.createACapsuleAndAddToJourney.bind(this);
        eventHandlerMap["click #createNewCapsuleFloatingBtnWrapper"] = this.createACapsuleAndAddToJourney.bind(this);
        eventHandlerMap["click #addContentFabBtn"] = this.showOrHideAddContentButtons.bind(this);
        eventHandlerMap["click #showStatistics"] = this.showTrebbleStatistics.bind(this);
        eventHandlerMap["click #shareTrebbleBtn > *:not(.share_my_trebble_tooltip)"] = this.showShareTrebblePopup.bind(this);
        eventHandlerMap["click #shareTrebbleBtn .share_my_trebble_tooltip"] = this.onShareMyJourneyTooltipIfApplicableClickedOn.bind(this);
        eventHandlerMap["click #exploreTrebblesBtn"] = this.showExplorePage.bind(this);
        eventHandlerMap["taphold .artistDetailCoverArtWrapper.editable #fullCoverArtImage"] = this.changeCoverArtConfirm.bind(this);
        eventHandlerMap["click #editTrebbleCoverPicBtn"] = this.upadteCoverArt.bind(this);
        eventHandlerMap["click #whatsacapsuleButton"] = this.showAndPlayWhatsACapsuleCapsule.bind(this);
        eventHandlerMap["click #whatsacapsuleinfobtn"] = this.showAndPlayWhatsACapsuleCapsule.bind(this);
        eventHandlerMap["click #homeHeaderButton"] = this.showHomeMenuPanel.bind(this);
        eventHandlerMap["click #createDraftButton"] =  this.createADraftJourney.bind(this);
        eventHandlerMap["click #publishButton:not(.disabled)"] =  this.publishJourney.bind(this);
        eventHandlerMap["click #revertToDraftButton"] = this.revertToDraftConfirm.bind(this);
        eventHandlerMap["click #removeFromArchiveButton"] = this.removeFromArchiveConfirm.bind(this);
        eventHandlerMap["click .ownerInfo .usernameAndFullname"] = this.openJourneyCreatorProfilePage.bind(this);
        eventHandlerMap["click .ownerInfo .userAvatar"] = this.openJourneyCreatorProfilePage.bind(this);
        eventHandlerMap["click #createReviewBtn"] = this.checkIfUserCanPostReviewAndOpenCreateReviewPage.bind(this);
        eventHandlerMap["click #updateReviewBtn"] = this.createOrUpdateReview.bind(this);
        eventHandlerMap["click #previewJourneyButton"] = this.previewJourney.bind(this);
        

        
        
        options.eventHandlerMap = eventHandlerMap;
        options.customInfoBoxInfoParams = this._buildCustomInfoBoxParams();
        options.onInfoButtonPressed = this._onAddMusicButtonPressed.bind(this);
        options.context = Utils.getInstance().buildContextForSongCollectionUserRadio(options.playlistSummary.get("id"));
        const loggedInUserInfo = LocalStorageHelper.getInstance().getUserInfo();
        const isTemporaryUser = loggedInUserInfo ? loggedInUserInfo.temporary : false;
        options.pageTemplateOptions = {"persistentPage": options.doNotRemoveFromDocument,"isPublicPage": this._isPublicPage,"isInPreviewMode": this._isInPreviewMode,"showBackButton":this._showBackButton, "loggedInUserInfo": !isTemporaryUser?  loggedInUserInfo: null,"isTemporaryUser": isTemporaryUser, "instructionHtmlGeneratorFunction": Utils.getInstance().getHtmlTrebbleListeningInstructionsNew, "ti18n":ti18n, "showCustomCapsuleList": this._customCapsuleLoadHandler,"customCapsuleTitleHeader": this._customCapsuleTitleHeader};
        this._context = options.context;
        this._openEditPageController = [];
        
        const c = JourneyDetailsController.prototype.initialize.call(this, options);
        if(this._customCapsuleLoadHandler){
            this._buildCustomCapsuleListController(options.playlistSummary.get("id"));
        }else{
            this._buildCapsuleInDraftListController(options.playlistSummary.get("id"));
            /*if(this._isPublicPage){
                this._buildCapsuleInAvailableJourneyListController(options.playlistSummary.get("id"));
            }*/
        }

        this._retrieveAndDisplayJourneyOwner();
        

        
        
        
        //this.listenTo(this.songListController,"finishLoading", this._onCapsuleListFinishLoading.bind(this));
        if(this.capsuleCollectionRepeatController){
            this.listenTo(this.capsuleCollectionRepeatController, "finishLoading", this._onCapsuleListFinishLoading.bind(this));
        }
        this.listenTo(PersistentModels.getInstance(),"songsRemovedToRadios", this._onSongsAddedOrRemovedToRadios.bind(this));
        this.listenTo(PersistentModels.getInstance(), "songsAddedToRadios", this._onSongsAddedOrRemovedToRadios.bind(this));
        this.listenTo(PersistentModels.getInstance(), "userSongLibraryInitialized", this._onSongsAddedOrRemovedToRadios.bind(this));
        this.listenTo(PersistentModels.getInstance(), "capsuleProcessingCompleted", this._onCapsuleSuccessfullyCreated.bind(this));
        this.listenTo(PersistentModels.getInstance(), "capsuleAddedOrRemoved", this._onCapsuleSuccessfullyCreated.bind(this));
        this.listenTo(PersistentModels.getInstance().getRouter(), "editPageOpened", this._onEditPageOpened.bind(this));
        this.listenTo(PersistentModels.getInstance().getRouter(), "editPageClosed", this._onEditPageClosed.bind(this));
        this.listenTo(PersistentModels.getInstance(),"journeyInfoUpdatedWithId"+this.model.getLoaderKey(),this.onJourneyInfoUpdated.bind(this));
        this.listenTo(PersistentModels.getInstance(),"capsuleBeingAddedToJourneyWithId_"+this.model.getLoaderKey(),this.onCapsuleBeingAddedToJourney.bind(this));
        this.listenTo(PersistentModels.getInstance(),"capsuleAddedToJourneyWithId_"+this.model.getLoaderKey(),this.onCapsuleAddedToJourney.bind(this));
        this.listenTo(PersistentModels.getInstance(),"capsuleBeingSetAsTrailerForJourneyWithId_"+this.model.getLoaderKey(),this.onCapsuleBeingSetAsTrailerForJourney.bind(this));
        this.listenTo(PersistentModels.getInstance(),"capsuleSetAsTrailerForJourneyWithId_"+this.model.getLoaderKey(),this.onCapsuleSetAsTrailerForJourney.bind(this));
        this.listenTo(PersistentModels.getInstance(),"journeyDeletedWithId"+this.model.getLoaderKey(), this.onJourneyDeleted.bind(this))

        PersistentModels.getInstance().listenToFinishPlayingEventForRadioWithId(this,this.model.getLoaderKey(), this._onAudioFinishPlayingInPlayer.bind(this));
        this._listenToPlayerEvents();
        return c;


    },

    _listenToPlayerEvents: function() {
        //this.listenTo(PersistentModels.getInstance().getPlayer(), "songPreviewLoaded", this.onAudioPreviewPlaying.bind(this));
        this.listenTo(PersistentModels.getInstance().getPlayer(), "songPreviewLoading", this.onAudioPreviewPlaying.bind(this));
        this.listenTo(PersistentModels.getInstance().getPlayer(), "songPreviewStartedPlaying", this.onAudioPreviewPlaying.bind(this));
        
        this.listenTo(PersistentModels.getInstance().getPlayer(), "songPreviewFailedLoading", this.onAudioPreviewStopped.bind(this));

        this.listenTo(PersistentModels.getInstance().getPlayer(), "songPreviewCancelled", this.onAudioPreviewStopped.bind(this));
        this.listenTo(PersistentModels.getInstance().getPlayer(), "songPreviewUnLoaded", this.onAudioPreviewStopped.bind(this));


        this.listenTo(PersistentModels.getInstance().getPlayer(), "songStartedPlaying", this.onAudioPlaying.bind(this));
        this.listenTo(PersistentModels.getInstance().getPlayer(), "songLoading", this.onAudioPlaying.bind(this));
        //this.listenTo(PersistentModels.getInstance().getPlayer(), "playerLoading", this.updatePlaybackButton.bind(this));
        //this.listenTo(PersistentModels.getInstance().getPlayer(), "playerFinishLoading", this.updatePlaybackButton.bind(this));
        //this.listenTo(PersistentModels.getInstance().getPlayer(), "songLoaded", this.updatePlaybackButton.bind(this));
        this.listenTo(PersistentModels.getInstance().getPlayer(), "songFailedLoading", this.onAudioStopped.bind(this));
        this.listenTo(PersistentModels.getInstance().getPlayer(), "songPaused", this.onAudioStopped.bind(this));

    },

    onAudioPreviewPlaying : function(currentSongModel){
        if(this._trailerCapsuleModel){
            const audioUrl  = PersistentModels.getInstance().getPlayer().getAudioPreviewURL();
            if(audioUrl == this._trailerCapsuleModel.getAudioUrl()){
                this.setTrailerPlaying(true);
            }
        }
    },

    onAudioPreviewStopped : function(currentSongModel){
        if(this._trailerCapsuleModel){
            const audioUrl  = PersistentModels.getInstance().getPlayer().getAudioPreviewURL();
            if(audioUrl == this._trailerCapsuleModel.getAudioUrl()){
                this.setTrailerPlaying(false);
            }
        }
    },

    onAudioPlaying : function(currentSongModel){
        if(this._trailerCapsuleModel){
            const audioUrl  = PersistentModels.getInstance().getPlayer().getAudioMediaURL();
            if(audioUrl == this._trailerCapsuleModel.getAudioUrl()){
                this.setTrailerPlaying(true);
            }
        }
        /*
        const getSongModelPromise =  currentSongModel? RSVP.Promise.resolve(currentSongModel):PersistentModels.getInstance().getPlayer().getCurrentSong();
        getSongModelPromise.then((function(songModel){
            if(songModel.get("uri") == this.model.getAudioUrl()){
                this.view.setIsPlaying(true);
            }
        }).bind(this));*/

},

onAudioStopped : function(currentSongModel){
if(this._trailerCapsuleModel){
const audioUrl  = PersistentModels.getInstance().getPlayer().getAudioMediaURL();
if(audioUrl == this._trailerCapsuleModel.getAudioUrl()){
    this.setTrailerPlaying(false);
}
}
/*const getSongModelPromise =  currentSongModel? RSVP.Promise.resolve(currentSongModel):PersistentModels.getInstance().getPlayer().getCurrentSong();
getSongModelPromise.then((function(songModel){
if(songModel.get("uri") == this.model.getAudioUrl()){
    this.view.setIsPlaying(false);
}
}).bind(this));*/

},


/*
_listenToPlayerEvents: function() {

this.listenTo(PersistentModels.getInstance().getPlayer(), "songStartedPlaying", this.onAudioPlaying.bind(this));
this.listenTo(PersistentModels.getInstance().getPlayer(), "songLoading", this.onAudioPlaying.bind(this));
//this.listenTo(PersistentModels.getInstance().getPlayer(), "playerLoading", this.updatePlaybackButton.bind(this));
//this.listenTo(PersistentModels.getInstance().getPlayer(), "playerFinishLoading", this.updatePlaybackButton.bind(this));
//this.listenTo(PersistentModels.getInstance().getPlayer(), "songLoaded", this.updatePlaybackButton.bind(this));
this.listenTo(PersistentModels.getInstance().getPlayer(), "songFailedLoading", this.onAudioStopped.bind(this));
this.listenTo(PersistentModels.getInstance().getPlayer(), "songPaused", this.onAudioStopped.bind(this));

},

onAudioPlaying : function(currentSongModel){
if(this._trailerCapsuleModel){
const getSongModelPromise =  currentSongModel? RSVP.Promise.resolve(currentSongModel):PersistentModels.getInstance().getPlayer().getCurrentSong();
getSongModelPromise.then((function(songModel){
    if(this._trailerCapsuleModel && songModel.get("uri") == this._trailerCapsuleModel.getAudioUrl()){
        this.setTrailerPlaying(true);
    }
}).bind(this));
}
},

onAudioStopped : function(currentSongModel){
if(this._trailerCapsuleModel){
const getSongModelPromise =  currentSongModel? RSVP.Promise.resolve(currentSongModel):PersistentModels.getInstance().getPlayer().getCurrentSong();
getSongModelPromise.then((function(songModel){
    if(this._trailerCapsuleModel && songModel.get("uri") == this._trailerCapsuleModel.getAudioUrl()){
        this.setTrailerPlaying(false);
    }
}).bind(this));
}

},
*/


createADraftJourney : function(){
$.mobile.loading("show");
return PersistentModels.getInstance().createAJourneyDraft(this.model.getLoaderKey()).then((function(operationResult){
$.mobile.loading("hide");
}).bind(this)).catch(Utils.getInstance().getDefaultPromiseErrorHandler());
},

onCapsuleBeingAddedToJourney : function(){
$.mobile.loading("show");
this.capsuleCollectionRepeatController.setUpdating(true);
},


onCapsuleBeingSetAsTrailerForJourney : function(){
$.mobile.loading("show");
//this.capsuleCollectionRepeatController.setUpdating(true);
},

onCapsuleSetAsTrailerForJourney : function(){
$.mobile.loading("hide");
this._updateTrailerSelectorField();
//this.capsuleCollectionRepeatController.setUpdating(true);
},

onCapsuleAddedToJourney : function(){
console.log("onCapsuleAddedToJourney ");
$.mobile.loading("hide");
if(this.capsuleCollectionRepeatController){
this.capsuleCollectionRepeatController.setUpdating(false);
this.capsuleCollectionRepeatController.reload(true);
}
if(this.archivedCapsuleCollectionRepeatController){
this.archivedCapsuleCollectionRepeatController.reload(true);
}
if(this.customCapsuleCollectionRepeatController){
this.customCapsuleCollectionRepeatController.reload(true);
}
},

publishJourney: function(){
$.mobile.loading("show");
return PersistentModels.getInstance().getRouter().publishJourneyViaPopup(this.model.getLoaderKey(),this.model.getName(), this.context, null, null, null).then((function(operationResult){
$.mobile.loading("hide");
}).bind(this)).catch(Utils.getInstance().getDefaultPromiseErrorHandler());
},

revertToDraftConfirm : function(){
const buttonLabels = [window.getI18n(ti18n, "NO"),window.getI18n(ti18n, "YES")];
const confirmCallback = (function(buttonIndex){
if(buttonIndex ==  2){
    setTimeout((function(){
        this.revertToDraftButton();
    }).bind(this), 100);

}
}).bind(this);
const message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_REVERT_JOURNEY_DRAFT");
navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n, "REVERT_TO_DRAFT"), buttonLabels);

},

removeFromArchiveConfirm : function(){
const buttonLabels = [window.getI18n(ti18n, "NO"),window.getI18n(ti18n, "YES")];
const confirmCallback = (function(buttonIndex){
if(buttonIndex ==  2){
    setTimeout((function(){
        this.removeFromArchive();
    }).bind(this), 100);

}
}).bind(this);
const message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_REMOVE_JOURNEY_FROM_ARCHIVE");
navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n, "REMOVE_FROM_ARCHIVE"), buttonLabels);

},

previewJourney : function(){
return PersistentModels.getInstance().getRouter().showPreviewPublicJourneyDetailPage(this.model, true);
},

removeFromArchive : function(){
$.mobile.loading("show");
return PersistentModels.getInstance().removeFromArchive(this.model.getLoaderKey()).then((function(operationResult){
$.mobile.loading("hide");
}).bind(this)).catch(Utils.getInstance().getDefaultPromiseErrorHandler());
},

revertToDraftButton : function(){
$.mobile.loading("show");
return PersistentModels.getInstance().revertToDraft(this.model.getLoaderKey()).then((function(operationResult){
$.mobile.loading("hide");
}).bind(this)).catch(Utils.getInstance().getDefaultPromiseErrorHandler());
},

_onAudioFinishPlayingInPlayer :function(){
//Delaying the reload of the list capsule by 2 seconds to make sure that database was updated
window.setTimeout((function(){
if(this.capsuleCollectionRepeatController){
    this.capsuleCollectionRepeatController.reload(true);
}
}).bind(this), 2000)
},

onArchivedCapsuleListFinishLoading : function(){
if(this._isPublicPage){
this.archivedCapsuleListRenderingPromise.then((function(){
    this.view.$el.find("#myArchivedCapsulesListWrapper").append(this.archivedCapsuleListView.$el);
    if(!this.archivedCapsuleCollectionRepeatController.getModel() || this.archivedCapsuleCollectionRepeatController.getModel().length === 0){
        this.view.$el.find(".archivedCapsulesBlock").hide();
    }
}).bind(this));
}
},

pauseTrebble : function(){
if(PersistentModels.getInstance().getPlayer().isPlaylistCurrentlyLoaded(this.model)){
PersistentModels.getInstance().getPlayer().pauseAudio();
}
},

_onEditPageOpened : function(editPageController){
if(this.model && this.model.canBeEdited()){
if(this._openEditPageController.indexOf(editPageController) == -1){
    this._openEditPageController.push(editPageController);
}
}

},

_createShortcastAndSetAsTrailer : function(){
$.mobile.loading("show");
return PersistentModels.getInstance().createACapsuleAndSetItAsTrailerForJourney(this.model.getLoaderKey()).then((function(operationResult){
$.mobile.loading("hide");
}).bind(this)).catch(Utils.getInstance().getDefaultPromiseErrorHandler());
},

_clearCurrentTrailer : function(){
$.mobile.loading("show");
return TrebbleClientAPIHelper.getInstance().clearJourneyTrailer(this.model.getLoaderKey()).then((function(result){
$.mobile.loading("hide");
return this._updateTrailerSelectorField();
}).bind(this)).catch((function(error){
$.mobile.loading("hide");
window.alertErrorMessage(error);
}).bind(this))
},

_updateTrailerSelectorField : function(){
if(this.trailerSelectorView){
this.trailerSelectorView.setUpdating(true);
}
return this._retrieveTrailerCapsule().then((function(){
if(this._trailerCapsuleInfoJSON){
    const outlinetItem = Utils.getInstance().getJourneyOutlineItemFromCapsuleJson(null, false, this.model.getLoaderKey(), false, true, this._trailerCapsuleInfoJSON, true, this.model.isPaid());
    const controllerParams = {};
    controllerParams.showActiveFlagInView = true;
    controllerParams.context = Utils.getInstance().buildContextForMyCapsulePage();
    controllerParams.model = outlinetItem;
    controllerParams.canBeEdited = true;
    controllerParams.journeySummaryJSON = this.model.toJSON();
    controllerParams.playInPreviewMode = true
    controllerParams.functionToRetrieveDefaultImageUrl = this._getFunctionToRetrieveOutlineImageUrl();
    controllerParams.hideMoreButton =  true;
    controllerParams.onActionExecutedOnUserHandler = this._onActionExecutedByUserOnDisplayedCapsule.bind(this);
    this._trailerOutlineController =  new JourneyOutlineItemController(controllerParams);
    return this._trailerOutlineController.buildModelAndRenderView();
}else{
    this._trailerOutlineController = null;
}

}).bind(this)).then((function(){
this.trailerSelectorView.setUpdating(false);
if(this._trailerOutlineController && this._trailerOutlineController.getView()){
    this.view.$el.attr("trailer_available", "true");
    this.trailerSelectorView.setSelectedSongView(this._trailerOutlineController.getView());
}else{
    this.view.$el.attr("trailer_available", "false");
    this.trailerSelectorView.setSelectedSongView(null);
}
}).bind(this)).catch((function(error){
this.trailerSelectorView.setUpdating(false);
window.alertErrorMessage(error);
}).bind(this));

},

_setupTrailerSelectorFieldView : function(){
const singleSongSelectorInputViewParams = {};
singleSongSelectorInputViewParams.noAudioSelectedCustomLabel = "<span class='pe-7s-speaker' style='margin-right: 0.5em;margin-left: 0.5em;font-size: 1.5em;'/>"+window.getI18n(ti18n, "NO_TRAILER");
singleSongSelectorInputViewParams.chooseAudioCustomLabel = window.getI18n(ti18n, "CREATE_TRAILER");
singleSongSelectorInputViewParams.removeAudioCustomLabel = window.getI18n(ti18n, "DELETE_TRAILER");
singleSongSelectorInputViewParams.showWarningBeforeRemoval = true;
singleSongSelectorInputViewParams.warningBeforeRemovalMessage = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_TRAILER");
singleSongSelectorInputViewParams.cannotBeModified = !this.canTrailerBeModified();
const trailerSelectorView = new SingleSongSelectorInputView(singleSongSelectorInputViewParams);
trailerSelectorView.render();
this.trailerSelectorView = trailerSelectorView;
this.listenTo(this.trailerSelectorView ,"deleteCurrentSelectedSong", this._clearCurrentTrailer.bind(this));
this.listenTo(this.trailerSelectorView , "pickSong", this._createShortcastAndSetAsTrailer.bind(this));
this.view.$el.find(".setup_trailer_box_wrapper").append(this.trailerSelectorView.$el);
},

_retrieveAndDisplayJourneyOwner : function(){
return TrebbleClientAPIHelper.getInstance().getJourneyOwner(this.model.getLoaderKey()).then((function(userOwnerJson){
if(userOwnerJson){
    this._userOwnerModel = Utils.getInstance().getModelFromUserJson(userOwnerJson);
}
}).bind(this)).then((function(){
let imageUrl = null;
if(this._userOwnerModel && this._userOwnerModel.getProfileImageUrl()){
    imageUrl = window.trebble.globalHelpers.getResizeImageUrl(this._userOwnerModel.getProfileImageUrl(), 50, 50) ;
}else{
    imageUrl = window.getResourceURLAccordingToLocationProtocol("/img/videoLoading.png");
} 
this.view.$el.find(".ownerInfo .userAvatar").attr("src",imageUrl);
this.view.$el.find(".ownerInfo .usernameAndFullname .username").html(this._userOwnerModel?"@"+ this._userOwnerModel.getUsername(): "");
}).bind(this))
},

openJourneyCreatorProfilePage : function(){
if(this._userOwnerModel){
const userId =  this._userOwnerModel.getUserId();
const username =  this._userOwnerModel.getUsername();
$.mobile.loading("show");
return PersistentModels.getInstance().getRouter().showUserProfilePageByUsername( username, userId,this._userOwnerModel, true).then(function(){
    $.mobile.loading("hide");
}).catch(Utils.getInstance().getDefaultPromiseErrorHandler());
}
},

_onEditPageClosed : function(editPageController){
if(this.model && this.model.canBeEdited()){
const controllerIndex = this._openEditPageController.indexOf(editPageController); 
if(controllerIndex!= -1){
    this._openEditPageController.splice(controllerIndex, 1);
}
}

},

playTrebble : function(){
if(PersistentModels.getInstance().getPlayer().isPlaylistCurrentlyLoaded(this.model)){
PersistentModels.getInstance().getPlayer().playAudio();
}else{
this.onStartRadioButtonTapped();
}
},

/*
updatePlaybackButton : function(){
const playerModel =  PersistentModels.getInstance().getPlayer();
if(playerModel.isPlaylistCurrentlyLoaded(this.model)){
if(playerModel.isLoading() || playerModel.isPlayerLoading()){
    this.view.$el.removeClass("isCurrentlyPlaying");
    this.view.$el.addClass("isCurrentlyLoading");
}else{
    this.view.$el.removeClass("isCurrentlyLoading");
    if (playerModel.isPlaying()) {
        this.view.$el.addClass("isCurrentlyPlaying");
    } else {
        this.view.$el.removeClass("isCurrentlyPlaying");
    }
}
}else{
this.view.$el.removeClass("isCurrentlyPlaying");
this.view.$el.removeClass("isCurrentlyLoading");
}

},*/

/*
_listenToPlayerEvents : function(){


this.listenTo(PersistentModels.getInstance().getPlayer(),"songStartedPlaying",this.updatePlaybackButton.bind(this));
this.listenTo(PersistentModels.getInstance().getPlayer(),"songLoading",this.updatePlaybackButton.bind(this));
this.listenTo(PersistentModels.getInstance().getPlayer(),"playerLoading",this.updatePlaybackButton.bind(this));
this.listenTo(PersistentModels.getInstance().getPlayer(),"playerFinishLoading",this.updatePlaybackButton.bind(this));
this.listenTo(PersistentModels.getInstance().getPlayer(),"songLoaded",this.updatePlaybackButton.bind(this));
this.listenTo(PersistentModels.getInstance().getPlayer(),"songFailedLoading",this.updatePlaybackButton.bind(this));
this.listenTo(PersistentModels.getInstance().getPlayer(),"songPaused",this.updatePlaybackButton.bind(this));

},*/



_onAddMusicButtonPressed :function(){
this.showGlobalSearch();
},

showHomeMenuPanel : function(){
PersistentModels.getInstance().getRouter().showHomePage();
},

onJourneyInfoUpdated: function(journeySummaryModelUpdated){
this.refreshJourneyInfo(journeySummaryModelUpdated);
},

_getStatusLabel : function(status){
return window.getI18n(ti18n, status);
},

refreshJourneyInfo : function(journeySummaryModelUpdated){
this.model.set(journeySummaryModelUpdated.toJSON());
this.view.$el.find("#pageHeaderTitleWrapper  span.journey_title").html(journeySummaryModelUpdated.getName() + this._buildjourneyStatusTagHtml());
this.view.$el.find(".playlistDescription > div").html(journeySummaryModelUpdated.getDescription());
this.view.$el.find(".jouney_summary_detail_item.goal_info .detail").html(journeySummaryModelUpdated.getGoal());
this.view.$el.find(".buy_now_cost").html(journeySummaryModelUpdated.getCostInUSD() + " USD");
this.view.$el.attr("journey_status",journeySummaryModelUpdated.getStatus());
if(this.trailerSelectorView){
this.trailerSelectorView.setCannotBeModified(!this.canTrailerBeModified());
}
this.refreshHasPurchasedPropertyOnModelIfApplicable();
this.updateFollowAndBuyNowButton();
this.updateTrebblePictureCoverUrlOnView(journeySummaryModelUpdated.getCoverArtUrl(), Utils.getInstance().isBackgroundBlurImageDisabled()? null: journeySummaryModelUpdated.getCoverArtUrlBlur());
if(journeySummaryModelUpdated.getJourneyLengthInDays() > 0){
this.view.$el.find(".journey_length_label").html( " · "+ "<i class='fontello-icon-list journeyOultlineListIcon' ></i>" + journeySummaryModelUpdated.getJourneyLengthInDays() +" "+window.getI18n(ti18n, "DAYS"));
}else{
this.view.$el.find(".journey_length_label").html("");
}
if(journeySummaryModelUpdated.getJourneyDurationInMinutes() > 0){
this.view.$el.find(".journey_duration_label").html( " · "+ "<i class='ion-ios7-time-outline journeyOultlineListIcon' ></i>" + journeySummaryModelUpdated.getJourneyDurationInMinutesToDisplay());
}else{
this.view.$el.find(".journey_duration_label").html("");
}

if(journeySummaryModelUpdated.getAverageRatings() > 0){
this.view.$el.find(".journey_rating_label").html( " · "+ "<i class='ion-ios7-star  journeyOultlineListIcon' ></i>" + Math.round(journeySummaryModelUpdated.getAverageRatings(),1 ));
}else{
this.view.$el.find(".journey_rating_label").html("");
}
if(journeySummaryModelUpdated.hasPurchased()){
this.view.$el.attr("has-purchased", "true");
}else{
this.view.$el.attr("has-purchased", "false");
}
if(journeySummaryModelUpdated.isPaid()){
this.view.$el.attr("is-paid", "true");
}else{
this.view.$el.attr("is-paid", "false");
}
if(this.capsuleCollectionRepeatController){
this.capsuleCollectionRepeatController.reload();
}
},

_buildjourneyStatusTagHtml : function(){
return "<span class='journey_status_tag'>"+this._getStatusLabel(this.model.getStatus())+"</span>";
},

_getFunctionToRetrieveOutlineImageUrl : function(){
return (function(){
return this.model.getCoverArtUrl();
}).bind(this)
},

_buildCapsuleInDraftListController : function(radioId){
const controllerParams = {};
controllerParams.showActiveFlagInView = true;
controllerParams.context = Utils.getInstance().buildContextForMyCapsulePage();
controllerParams.canBeEdited = this.model.canBeEdited();
controllerParams.journeySummaryJSON = this.model.toJSON();
controllerParams.playInPreviewMode = !this._isPublicPage && !this._isInPreviewMode;
controllerParams.functionToRetrieveDefaultImageUrl = this._getFunctionToRetrieveOutlineImageUrl();
controllerParams.onActionExecutedOnUserHandler = this._onActionExecutedByUserOnDisplayedCapsule.bind(this);
const collectionRepeatControllerParams = {};
collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildCapsuleInfoBoxParams();
collectionRepeatControllerParams.showEmtptyInfoBox = true;
collectionRepeatControllerParams.controllerOption = controllerParams;
collectionRepeatControllerParams.itemControllerConstructor = JourneyOutlineItemController;
collectionRepeatControllerParams.modelLoadHandler = this._getJourneyOutlineItems(radioId);
collectionRepeatControllerParams.context = this._context;
collectionRepeatControllerParams.resizePlaceHolderElem = true;
collectionRepeatControllerParams.heightMargin = 52;
this._capuleListLoaded = false;
collectionRepeatControllerParams.actionButtonHandlerFunction = this.createACapsuleAndAddToJourney.bind(this);
$.mobile.loading("show");
this.capsuleCollectionRepeatController = new CollectionRepeatController(collectionRepeatControllerParams);
this.capsuleListRenderingPromise = this.capsuleCollectionRepeatController.buildModelAndRenderView().then((function(){
this.capsuleListView = this.capsuleCollectionRepeatController.getView();
this.makeOutlineSortableIfApplicable();
$.mobile.loading("hide");
}).bind(this)).catch((function(error){
$.mobile.loading("hide");
window.alertErrorMessage(error);
}).bind(this));
},

onJourneyDeleted : function(){
return PersistentModels.getInstance().getRouter().navigateToPreviousPage();
},

_retrieveTrailerCapsule :function(){
    let promiseRetriveTrailerCapsule = null;
if(this._isPublicPage && !this._isInPreviewMode){
promiseRetriveTrailerCapsule = TrebbleClientAPIHelper.getInstance().getTrailerForAvailableJourney(this.model.getLoaderKey());
}else{
if(this.model.getStatus() == JourneySummary.JOURNEY_STATUS.DRAFT  || this._isInPreviewMode){
    promiseRetriveTrailerCapsule = TrebbleClientAPIHelper.getInstance().getTrailerForDraftJourney(this.model.getLoaderKey());
}else{
    promiseRetriveTrailerCapsule = TrebbleClientAPIHelper.getInstance().getTrailerForAvailableJourney(this.model.getLoaderKey());
}
}
return promiseRetriveTrailerCapsule.then((function(trailerCapsuleInfo){
this._trailerCapsuleInfoJSON = trailerCapsuleInfo;
if(this._trailerCapsuleInfoJSON){
    this._trailerCapsuleModel = Utils.getInstance().getModelFromCapsuleJson(this._trailerCapsuleInfoJSON);
}else{
    this._trailerCapsuleModel = null;

}
this.updateNotEnoughShortcastToPublishTag();
}).bind(this))
},

_showProgressBarIfApplicable : function(){
if(SHOW_PROGRESS_BAR_ON_JOURNEY_PAGE && this.model.isFollowing() && (this._isPublicPage || this._isInPreviewMode)){
const journeyOutlineItemArray = this.getJourneyOutlineItemArray(); 
let numberOfShortcastsListened = 0;
const totalNumberOfShortcasts = journeyOutlineItemArray? journeyOutlineItemArray.length: 0;
if(totalNumberOfShortcasts){
    for(let i = 0; i < journeyOutlineItemArray.length; i++){
        const outlineItem = journeyOutlineItemArray[i];
        if(outlineItem.isCompleted()){
            numberOfShortcastsListened = numberOfShortcastsListened+1;
        }
    }
}
const percentageListened = totalNumberOfShortcasts > 0 ? Math.trunc(numberOfShortcastsListened * 100/totalNumberOfShortcasts) + "%": "0%";
const progressTextLabel  = numberOfShortcastsListened == totalNumberOfShortcasts ?window.getI18n(ti18n, "COMPLETED") :window.getI18n(ti18n, "DAY") + " "+ (numberOfShortcastsListened+1) + " "+window.getI18n(ti18n, "OF") +" "+ totalNumberOfShortcasts  ;
this.view.$el.find(".journey_progress_info_wrapper .completed_progress_bar").css("width", percentageListened);
this.view.$el.find(".journey_progress_info_wrapper .text_progress_summary_in_percent").html( percentageListened);
this.view.$el.find(".journey_progress_info_wrapper .text_progress_summary_in_days").html( progressTextLabel);
if(SHOW_COLOURED_PROGRESS_BAR){
    if(!this.view.$el.find(".journey_progress_info_wrapper .completed_progress_bar").hasClass("coloured")){
        this.view.$el.find(".journey_progress_info_wrapper .completed_progress_bar").addClass("coloured")
    }
}else{
    this.view.$el.find(".journey_progress_info_wrapper .completed_progress_bar").removeClass("coloured")
}
this.view.$el.find(".journey_progress_info_wrapper").removeClass("hide")

}else{
if(!this.view.$el.find(".journey_progress_info_wrapper").hasClass("hide")){
    this.view.$el.find(".journey_progress_info_wrapper").addClass("hide")
}
}
},


getJourneyOutlineItemArray : function(){
return this.capsuleCollectionRepeatController? this.capsuleCollectionRepeatController.getModel(): this.archivedCapsuleCollectionRepeatController.getModel();
},


_getJourneyOutlineItems : function(radioId){
return (function(){
if(this._isPublicPage || this._isInPreviewMode){
    let promiseRetriveCapsule = null;
    if(this._isInPreviewMode){
        promiseRetriveCapsule = TrebbleClientAPIHelper.getInstance().getCapsulesInDraftJourneyWithProgress(radioId);
    }else{
        promiseRetriveCapsule = TrebbleClientAPIHelper.getInstance().getCapsulesInAvailableJourneyWithProgress(radioId);
    }
    return promiseRetriveCapsule.then((function(result){
        const outlineProgress = result && result.outlineProgress? result.outlineProgress: null;
        const capsuleUIDToProgress = {};
        if(outlineProgress && outlineProgress.length){
            for(let i =0; i < outlineProgress.length; i++){
                const progress = outlineProgress[i];
                capsuleUIDToProgress[progress.capsuleUID] = progress;
            }
        }
        const capsulesInfoArray = result && result.clientCapsuleInfoArray? result.clientCapsuleInfoArray: null;
        if(capsulesInfoArray && capsulesInfoArray.length > 0){
            const journeyOutlineItemArray = [];
            const locked =  !this.model.isFollowing();
            let previousOutlineItemWasCompleted =  this.model.isAvailableToListen();
            let allowedToPlay = this.model.isAvailableToListen();
            const canBeEdited = this.model.canBeEditedInState();
            const isPaidJourney = this.model.isPaid();
            for(let i=0; i < capsulesInfoArray.length; i++){
                const capsuleInfoJson = capsulesInfoArray[i];
                const playbackProgress = capsuleUIDToProgress[capsuleInfoJson.capsuleId];
                const wasCompletlyPlayed =  playbackProgress? playbackProgress.completed:false;
                if(this.model.isAvailableToListen() && previousOutlineItemWasCompleted){
                    allowedToPlay = true;
                }else{
                    allowedToPlay = false;
                }
                previousOutlineItemWasCompleted = wasCompletlyPlayed;
                const outlinetItem = Utils.getInstance().getJourneyOutlineItemFromCapsuleJson(i+1, locked, radioId,wasCompletlyPlayed, allowedToPlay, capsuleInfoJson, canBeEdited, isPaidJourney);
                outlinetItem.setCanBeEdited(false);
                journeyOutlineItemArray.push(outlinetItem);
            }
            return journeyOutlineItemArray;
        }else{
            return  [];
        }
    }).bind(this));

}else{
    let promiseRetriveCapsule = null;
if(this.model.getStatus() == JourneySummary.JOURNEY_STATUS.DRAFT){
promiseRetriveCapsule = TrebbleClientAPIHelper.getInstance().getCapsulesInDraftJourney(radioId);
}else{
promiseRetriveCapsule = TrebbleClientAPIHelper.getInstance().getCapsulesInAvailableJourney(radioId);
}
return promiseRetriveCapsule.then((function(capsulesInfoArray){
if(capsulesInfoArray && capsulesInfoArray.length > 0){
    const journeyOutlineItemArray = [];
    const locked =  !this.model.canBeEdited();
    const isPaidJourney = this.model.isPaid();
    const canBeEdited = this.model.canBeEditedInState();
    for(let i=0; i < capsulesInfoArray.length; i++){
        const capsuleInfoJson = capsulesInfoArray[i];
        const outlinetItem = Utils.getInstance().getJourneyOutlineItemFromCapsuleJson(i+1, locked, radioId, false, true, capsuleInfoJson, canBeEdited, isPaidJourney);
        journeyOutlineItemArray.push(outlinetItem);
    }
    return journeyOutlineItemArray;
}else{
    return  [];
}
}).bind(this));
}
}).bind(this)
},

canTrailerBeModified : function(){
return this.model.getStatus() == JourneySummary.JOURNEY_STATUS.DRAFT;
},

_buildCustomCapsuleListController : function(radioId){
const controllerParams = {};
controllerParams.showActiveFlagInView = true;
controllerParams.context = Utils.getInstance().buildContextForMyCapsulePage();
controllerParams.onActionExecutedOnUserHandler = this._onActionExecutedByUserOnDisplayedCapsule.bind(this);
controllerParams.journeySummaryJSON = this.model.toJSON();
controllerParams.playInPreviewMode = !this._isPublicPage && !this._isInPreviewMode;
const collectionRepeatControllerParams = {};
collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildCustomCapsuleInfoBoxParams();
collectionRepeatControllerParams.showEmtptyInfoBox = true;
collectionRepeatControllerParams.controllerOption = controllerParams;
collectionRepeatControllerParams.itemControllerConstructor = JourneyOutlineItemController;
collectionRepeatControllerParams.modelLoadHandler = this._getMyCustomCapsulesLoadHandler();
collectionRepeatControllerParams.context = this._context;
collectionRepeatControllerParams.resizePlaceHolderElem = true;
collectionRepeatControllerParams.heightMargin = 52;
this._customCapuleListLoaded = false;
$.mobile.loading("show");
this.customCapsuleCollectionRepeatController = new CollectionRepeatController(collectionRepeatControllerParams);
this.customCapsuleListRenderingPromise = this.customCapsuleCollectionRepeatController.buildModelAndRenderView().then((function(){
this.customCapsuleListView = this.customCapsuleCollectionRepeatController.getView();
$.mobile.loading("hide");
}).bind(this)).catch((function(error){
$.mobile.loading("hide");
window.alertErrorMessage(error);
}).bind(this));
},

_buildCapsuleInAvailableJourneyListController : function(radioId){
const controllerParams = {};
controllerParams.showActiveFlagInView = true;
controllerParams.context = Utils.getInstance().buildContextForMyCapsulePage();
controllerParams.onActionExecutedOnUserHandler = this._onActionExecutedByUserOnDisplayedCapsule.bind(this);
controllerParams.journeySummaryJSON = this.model.toJSON();
controllerParams.playInPreviewMode = !this._isPublicPage || !this._isInPreviewMode;
const collectionRepeatControllerParams = {};
collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildArchivedCapsuleInfoBoxParams();
collectionRepeatControllerParams.showEmtptyInfoBox = true;
collectionRepeatControllerParams.controllerOption = controllerParams;
collectionRepeatControllerParams.itemControllerConstructor = JourneyOutlineItemController;
collectionRepeatControllerParams.modelLoadHandler = this._getCapsulesInAvailableJourneyLoadHandler(radioId);
collectionRepeatControllerParams.context = this._context;
collectionRepeatControllerParams.resizePlaceHolderElem = true;
collectionRepeatControllerParams.heightMargin = 52;
this._archivedCapsuleListLoaded = false;
$.mobile.loading("show");
this.archivedCapsuleCollectionRepeatController = new CollectionRepeatController(collectionRepeatControllerParams);
this.listenTo( this.archivedCapsuleCollectionRepeatController, "finishLoading",this.onArchivedCapsuleListFinishLoading);
this.archivedCapsuleListRenderingPromise = this.archivedCapsuleCollectionRepeatController.buildModelAndRenderView().then((function(){
this.archivedCapsuleListView = this.archivedCapsuleCollectionRepeatController.getView();
$.mobile.loading("hide");
}).bind(this)).catch((function(error){
$.mobile.loading("hide");
window.alertErrorMessage(error);
}).bind(this));
},


_onSongsAddedOrRemovedToRadios : function(){
this.songListController.reload();

},

_onActionExecutedByUserOnDisplayedCapsule : function(actionName, capsuleJson, capsuleCardControllerInstance){
if(this.capsuleCollectionRepeatController){
this.capsuleCollectionRepeatController.reload(true);
}
if(this.archivedCapsuleCollectionRepeatController){
this.archivedCapsuleCollectionRepeatController.reload(true);
}
if(this.customCapsuleCollectionRepeatController){
this.customCapsuleCollectionRepeatController.reload(true);
}
},

_onCapsuleSuccessfullyCreated : function(){
console.log("_onCapsuleSuccessfullyCreated");
if(this.capsuleCollectionRepeatController){
this.capsuleCollectionRepeatController.reload(true);
}
if(this.archivedCapsuleCollectionRepeatController){
this.archivedCapsuleCollectionRepeatController.reload(true);
}
if(this.customCapsuleCollectionRepeatController){
this.customCapsuleCollectionRepeatController.reload(true);
}
},

_buildCustomCapsuleInfoBoxParams : function(){
const params = {};
params.iconClass = "fontello-icon-list";
params.message = window.getI18n(ti18n, "NO_SHORTCAST_IN_THIS_JOURNEY");
return params;
},

_buildCapsuleInfoBoxParams : function(){
if(this.model.canBeEdited() && !this._isPublicPage && !this._isInPreviewMode){
let params = {};
params.iconClass = "fontello-icon-list";
params.message = window.getI18n(ti18n, "NO_SHORTCAST_IN_THIS_JOURNEY");
params.helpMessage = window.getI18n(ti18n, "A_JOURNEY_NEEDS_TO_HAVE_AT_LEAST") +" "+ MIN_NUMBER_OF_ACTIVE_CAPSULES_TO_PUBLISH +" " +window.getI18n(ti18n, "SHORTCASTS_TO_BE_PUBLISHED");
params.actionButtonLabel = window.getI18n(ti18n, "ADD_TO_JOURNEY");
return params;
}else{
    let params = {};
params.iconClass = "fontello-icon-list";
params.message = window.getI18n(ti18n, "NO_SHORTCAST_TO_LISTEN_TO");
//params.helpMessage = window.getI18n(ti18n, "A_JOURNEY_NEEDS_TO_HAVE_AT_LEAST") +" "+ MIN_NUMBER_OF_ACTIVE_CAPSULES_TO_PUBLISH +" " +window.getI18n(ti18n, "SHORTCASTS_TO_BE_PUBLISHED");
//params.actionButtonLabel = "Create Capsule";
return params;
}

},


_buildArchivedCapsuleInfoBoxParams : function(){
const params = {};
params.iconClass = "fontello-icon-list";
params.message = window.getI18n(ti18n, "NO_SHORTCAST_TO_LISTEN_TO");
return params;
},


showAndPlayWhatsACapsuleCapsule: function(){
PersistentModels.getInstance().getRouter().showAndPlayWhatACapusleCapsule().then((function(capsuleDetailsPopupController){
capsuleDetailsPopupController.playCapsule();
this.listenTo(capsuleDetailsPopupController,"finishPlaying", (function(){
    LocalStorageHelper.getInstance().setWhatsACapsuleCapsulePlayed();
    this.view.$el.find("#whatsacapsuleButton").hide();
}).bind(this))
}).bind(this));
},



_showCreateUpdateReviewButtonIfApplicable : function(){
if(this._isPublicPage || this._isInPreviewMode){
return TrebbleClientAPIHelper.getInstance().hasUserLeftReviewOnJourney(this.model.getLoaderKey()).then((function(operationResult){
    if(operationResult && operationResult.userHasLeftReview){
        this.view.$el.find("#createReviewBtn").hide();
        this.view.$el.find("#updateReviewBtn").show();
    }else{
        this.view.$el.find("#createReviewBtn").show();
        this.view.$el.find("#updateReviewBtn").hide();
    }
}).bind(this))
}else{
this.view.$el.find("#createReviewBtn").hide();
this.view.$el.find("#updateReviewBtn").hide();
}
},

_displayRatingCardReviewIfApplicable : function(){
if(this._isPublicPage || this._isInPreviewMode){
this._ratingSummaryCardView = new RatingSummaryCardView();
this._ratingSummaryCardView.render();
this._ratingSummaryCardView.setModel(this.model);
this.view.$el.find(".rating_summary_section").append(this._ratingSummaryCardView.$el);

}
},

createOrUpdateReview : function(){
return PersistentModels.getInstance().getRouter().showWriteReviewOnJourneyPageByRadioId(this.model.getLoaderKey(), true);
},

_getCapsulesInDraftLoadHandler: function(radioId){
return (function(){
return TrebbleClientAPIHelper.getInstance().getCapsulesInDraftJourney(radioId).then(function(capsulesInfoArray){
    if(capsulesInfoArray && capsulesInfoArray.length > 0){
        this.capsulesCollection = new Capsules(capsulesInfoArray);
        return this.capsulesCollection.models;
    }else{
        return  [];
    }
});
}).bind(this);
},

_getCapsulesInAvailableJourneyLoadHandler: function(radioId){
return (function(){
return TrebbleClientAPIHelper.getInstance().getCapsulesInAvailableJourney(radioId).then(function(capsulesInfoArray){
    if(capsulesInfoArray && capsulesInfoArray.length > 0){
        this.capsulesCollection = new Capsules(capsulesInfoArray);
        return this.capsulesCollection.models;
    }else{
        return  [];
    }
});
}).bind(this);
},

_getMyCustomCapsulesLoadHandler: function(){
return (function(){

return this._customCapsuleLoadHandler().then(function(capsulesInfoArray){
    if(capsulesInfoArray && capsulesInfoArray.length > 0){
        this.capsulesCollection = new Capsules(capsulesInfoArray);
        return this.capsulesCollection.models;
    }else{
        return  [];
    }
});
}).bind(this);
},



showOrHideAddContentButtons : function(){
this.fabAddButtonContainer$el.toggleClass("expanded");
},

hideAddContentButtons : function(){
this.fabAddButtonContainer$el.removeClass("expanded");
},

showAddContentButtons : function(){
if(!this.fabAddButtonContainer$el.hasClass("expanded")){
this.fabAddButtonContainer$el.addClass("expanded");
}
},

/*showCreateACapsulePage : function(){
this.hideAddContentButtons();
const onCapsuleSuccessfullyCreated = (function(){

}).bind(this);
return PersistentModels.getInstance().getRouter().showCreateACapsulePageWithoutContext(null, onCapsuleSuccessfullyCreated);
},*/

createACapsuleAndAddToJourney : function(){
$.mobile.loading("show");
return PersistentModels.getInstance().createACapsuleAndAddToJourney(this.model.getLoaderKey()).then((function(operationResult){
$.mobile.loading("hide");
}).bind(this)).catch(Utils.getInstance().getDefaultPromiseErrorHandler());
},

createACapsuleAndSetItAsTrailerForJourney : function(){
$.mobile.loading("show");
return PersistentModels.getInstance().createACapsuleAndSetItAsTrailerForJourney(this.model.getLoaderKey()).then((function(operationResult){
$.mobile.loading("hide");
}).bind(this)).catch(Utils.getInstance().getDefaultPromiseErrorHandler());
},

_buildCustomInfoBoxParams  : function(){
const params = {};
params.iconClass = "ion-ios7-musical-notes";
params.message = window.getI18n(ti18n, "START_ADDING_MUSIC");
params.helpMessage = window.getI18n(ti18n, "GO_TO_SEARCH_PAGE_INSTRUCTION");
params.actionButtonLabel = window.getI18n(ti18n, "ADD_MUSIC");
return params;
},

onActionExecutedOnSong : function(songModel, action){
if((action == "songRemovedFromTrebble"  || action == "delete" )&& songModel){
this.songListController.removeSongFromCollection(songModel);
}
},


updateNotEnoughShortcastToPublishTag : function(){
const numberOfActiveCapsules =  this._getNumberOfActiveCapsules();
const isTrailerRequired = IS_TRAILER_REQUIRED_FOR_PUBLISHING;
const hasTrailer = !!this._trailerCapsuleInfoJSON;
const hasEnoughShortcast = MIN_NUMBER_OF_ACTIVE_CAPSULES_TO_PUBLISH <= numberOfActiveCapsules;
const canPublish = hasEnoughShortcast && (!isTrailerRequired || hasTrailer);
this.view.$el.attr("has_enough_to_be_plublish", canPublish);
const publishButton$el = this.view.$el.find("#publishButton"); 
if(canPublish){
publishButton$el.removeClass("disabled");
}else{
if(!publishButton$el.hasClass("disabled")){
    publishButton$el.addClass("disabled");
}
}
},
/*
refreshPurchaseButtonIfApplicable : function(){
if(this.model.isPaid()){
this.setBuyNowRefreshing(true);
return TrebbleClientAPIHelper.getInstance().hasPurchasedJourney(this.model.getLoaderKey()).then((function(hasPurchased){
    this.model.setHasPurchased();
    this.setBuyNowRefreshing(false);
}).bind(this)).catch((function(error){
    window.alertErrorMessage(error);
    this.setBuyNowRefreshing(false);
}).bind(this))
}
},*/

afterPageRendered : function(){
const editButton$El = this.view.$el.find("#playPreviewBtn");
const editButtonEl = editButton$El.get(0);
if(editButtonEl){
Hammer(editButtonEl, {drag_lock_to_axis: true}).on("press", this.onPreviewButtonPressed.bind(this), false);
Hammer(editButtonEl, {drag_lock_to_axis: true}).on("pressUp", this.onPreviewButtonPressedUp.bind(this), false);
//editButton$El.on("touchend",this.onPreviewButtonPressedUp.bind(this));
//editButton$El.on("mouseover",this.onPreviewButtonPressed.bind(this));
//editButton$El.on("mouseup",this.onPreviewButtonPressedUp.bind(this));
editButton$El.hoverIntent(this.onPreviewButtonPressed.bind(this),this.onPreviewButtonPressedUp.bind(this));
}
this.fabAddButtonContainer$el = this.view.$el.find("#fabAddButtonContainer");
if(!window.isMobileBrowser){
this.fabAddButtonContainer$el.hover( function(){}, this.hideAddContentButtons.bind(this));
this.view.$el.find("#addContentFabBtn").hover(this.showAddContentButtons.bind(this), function(){});
}
if(this.capsuleListRenderingPromise){
this.capsuleListRenderingPromise.then((function(){
    this.view.$el.find("#myCapsulesListWrapper").append(this.capsuleListView.$el);
}).bind(this));
}
if(this.customCapsuleListRenderingPromise){
this.customCapsuleListRenderingPromise.then((function(){
    this.view.$el.find("#myCustomCapsulesListWrapper").append(this.customCapsuleListView.$el);
}).bind(this));
}
this.view.$el.on("click",".tabheader > div#songTab", this._showSongTabClicked.bind(this));
this.view.$el.on("click",".tabheader > div#capsuleTab", this._showCapsuleTabClicked.bind(this));
this._selectedTabId = "capsuleTab";
if(SHOW_WHAT_S_A_CAPSULE_FLOATING_BUTTON && this._doNotRemoveFromDocument && !LocalStorageHelper.getInstance().isWhatsACapsuleCapsulePlayed() && !PersistentModels.getInstance().hasCapsuleCreated()){
this.view.$el.find("#whatsacapsuleButton").attr('style', 'display: -webkit-flex; display: flex');
}else{
this.view.$el.find("#whatsacapsuleButton").hide();
}
this._setupTrailerSelectorFieldView();
this._updateTrailerSelectorField();
this._showCreateUpdateReviewButtonIfApplicable();
this._displayRatingCardReviewIfApplicable();
this.refreshHasPurchasedPropertyOnModelIfApplicable();
this.updateFollowAndBuyNowButton();
},

_showSongTabClicked : function(){
if(this._selectedTabId != "songTab"){
this._hideCurrentTab();
this._showTab("songTab");
}
},

_hideCurrentTab : function(){
const selectedTab$el = this.view.$el.find("#"+ this._selectedTabId);
selectedTab$el.removeClass("selected");
this.view.$el.find(selectedTab$el.attr("href")).hide();
},

_showTab : function(tabId){
this._selectedTabId = tabId;
const selectedTab$el = this.view.$el.find("#"+ this._selectedTabId);
selectedTab$el.addClass("selected");
this.view.$el.find(selectedTab$el.attr("href")).show();
},



_showCapsuleTabClicked : function(){
if(this._selectedTabId != "capsuleTab"){
this._hideCurrentTab();
this._showTab("capsuleTab");

}
},

onPageShow : function(){
if(this.capsuleListView){
this.capsuleCollectionRepeatController.reload();
}
if(this.archivedCapsuleListView){
this.archivedCapsuleCollectionRepeatController.reload();
}
if(this.customCapsuleListView){
this.customCapsuleCollectionRepeatController.reload();
}
if((this._isPublicPage  || this._isInPreviewMode )&& this.archivedCapsuleListView){
this.archivedCapsuleCollectionRepeatController.reload();
}
const userInfo = LocalStorageHelper.getInstance().getUserInfo();
if(PersistentModels.getInstance().getRouter().isCurrentPageHasSameUrlAsFirstPage()){
this.view.$el.removeClass("showBackButton");
}else{	
if(!this.view.$el.hasClass("showBackButton")){
    this.view.$el.addClass("showBackButton");
}

}
},

onPreviewButtonPressed : function(){
this._previewButtonPressed = true;
this.playPreview();
},

onPreviewButtonPressedUp : function(){
if(this._previewButtonPressed){
this._previewButtonPressed = false;
this.stopPreview();
}
},

showTrebbleStatistics : function(){
return PersistentModels.getInstance().getRouter().showTrebbleStatisticsPage(this._playlist, true);
},

showExplorePage : function(){
return PersistentModels.getInstance().getRouter().showExplorePage(null, true);
},


showShareTrebblePopup : function(){
PersistentModels.getInstance().getRouter().showShareJourneyPopup(this._playlist.getLoaderKey(), this.context);
this.onShareMyJourneyTooltipIfApplicableClickedOn();
},

showOptionPopup : function(){
PersistentModels.getInstance().getRouter().showJourneyMenuOption(this.model, true, this._onActionExecutedOnTrebbleHandler.bind(this), this.view.$el.find("#menuOptionButton"), JourneyOptionController);
},



_onActionExecutedOnTrebbleHandler : function(trebble, action, data){
    let editPageController;
if(action == "updateCoverArt"){

if(data){
    const newCoverArtUrl = data.coverArtUrl;
    const newCoverArtUrlBlur = data.coverArtUrlBlur;
    this.updateTrebblePictureCoverUrlOnView(newCoverArtUrl, newCoverArtUrlBlur);
    PersistentModels.getInstance().onPersonalTrebbleCreatedOrEdited(this._playlist.getPlaylistSummary().toJSON());
    if(this._openEditPageController.length > 0){
        for(let index in this._openEditPageController){
            editPageController = this._openEditPageController[index];
            editPageController.setCoverArtInfo(newCoverArtUrl);
        }
    }
}
}else{
if(action == "setSweeper"){
    if(data){
        const jingleInfo = data.jingleInfo;
        if(this._openEditPageController.length > 0){
            for(let index in this._openEditPageController){
                editPageController = this._openEditPageController[index];
                editPageController.setJingleInfo(jingleInfo);
            }
        }
    }
}else{
    if(action == "unsetCurrentSweeper"){
        if(this._openEditPageController.length > 0){
            for(let index in this._openEditPageController){
                editPageController = this._openEditPageController[index];
                editPageController.setJingleInfo(null);
            }
        }
    }else{
        if(action == "editTrebbleInfo"){	
            if(data){
                this.model.set(data);
                this.updateTrebbleNameAndDescription(data.name, data.description);
                this._playlist = PlaylistHelper.getInstance().generatePersonalUserRadioPlaylist(this.model);
                this.upadteCoverArt(true);
                if(this._openEditPageController.length > 0){
                    for(let index in this._openEditPageController){
                        const editPageController = this._openEditPageController[index];
                        editPageController.updateTrebbleNameAndDescription(data.name, data.description);
                    }
                }

            }

        }else{
            if(action == "setGreater"){
                if(data){
                    const greaterInfo = data.greaterInfo;
                    if(this._openEditPageController.length > 0){
                        for(let index in this._openEditPageController){
                            editPageController = this._openEditPageController[index];
                            editPageController.setGreaterInfo(greaterInfo);
                        }
                    }
                }
            }else{
                if(action == "unsetCurrentGreater"){
                    if(this._openEditPageController.length > 0){
                        for(let index in this._openEditPageController){
                            editPageController = this._openEditPageController[index];
                            editPageController.setGreaterInfo(null);
                        }
                    }
                }else{
                    if(action == "setIntro"){
                        if(data){
                            const introInfo = data.introInfo;
                            if(this._openEditPageController.length > 0){
                                for(let index in this._openEditPageController){
                                    editPageController = this._openEditPageController[index];
                                    editPageController.setIntroInfo(introInfo);
                                }
                            }
                        }
                    }else{
                        if(action == "unsetCurrentIntro"){
                            if(this._openEditPageController.length > 0){
                                for(let index in this._openEditPageController){
                                    editPageController = this._openEditPageController[index];
                                    editPageController.setIntroInfo(null);
                                }
                            }
                        }else{
                            if(action == "setOutro"){
                                if(data){
                                    const outroInfo = data.outroInfo;
                                    if(this._openEditPageController.length > 0){
                                        for(let index in this._openEditPageController){
                                            editPageController = this._openEditPageController[index];
                                            editPageController.setOutroInfo(outroInfo);
                                        }
                                    }
                                }
                            }else{
                                if(action == "unsetCurrentOutro"){
                                    if(this._openEditPageController.length > 0){
                                        for(let index in this._openEditPageController){
                                            editPageController = this._openEditPageController[index];
                                            editPageController.setOutroInfo(null);
                                        }
                                    }
                                }else{

                                    if(action == "capsuleCreated"){

                                    }else{

                                    }

                                }
                            }

                        }
                    }
                }
            }
        }
    }
}
}
},




showEditPage : function(){
const handlers = {};
handlers.onCoverPictureChangeTrigger = this.upadteCoverArt.bind(this);
handlers.onSweeperChangeTrigger = this.changeSweeper.bind(this);
handlers.onSweeperChangeTriggerConfirm = this.changeSweeperConfirm.bind(this);
handlers.onDeleteSweeperTrigger = this.deleteCurrentSweeperConfirm.bind(this);
handlers.onGreaterChangeTrigger = this.changeGreater.bind(this);
handlers.onIntroChangeTrigger = this.changeIntro.bind(this);
handlers.onOutroChangeTrigger = this.changeOutro.bind(this);
handlers.onGreaterChangeTriggerConfirm = this.changeGreaterConfirm.bind(this);
handlers.onIntroChangeTriggerConfirm = this.changeIntroConfirm.bind(this);
handlers.onOutroChangeTriggerConfirm = this.changeOutroConfirm.bind(this);
handlers.onDeleteGreaterTrigger = this.deleteCurrentGreaterConfirm.bind(this);
handlers.onDeleteIntroTrigger = this.deleteCurrentIntroConfirm.bind(this);
handlers.onDeleteOutroTrigger = this.deleteCurrentOutroConfirm.bind(this);
handlers.onActionExecutedOnTrebbleHandler = this._onActionExecutedOnTrebbleHandler.bind(this);
const editPageController = PersistentModels.getInstance().getRouter().showJourneyInfoEditPage(this._playlist, true, handlers).then((function(editPageController){
if(editPageController){
    this._openEditPageController.push(editPageController);
}
}).bind(this)).catch(function(error){
navigator.trebbleNotification.alert(error, null, false, "error");
});

},

changeSweeper : function(){
const params = {};
params.trebbleModel = this._playlist;
params.context = this._context;
params.onActionExecutedOnTrebbleHandler = this._onActionExecutedOnTrebbleHandler.bind(this);
return PersistentModels.getInstance().getRouter().require(["controllers/playlistPodcast/PersonalTrebbleOptionController"]).then((function(PersonalTrebbleOptionController){
const personalTrebbleOptionController = new PersonalTrebbleOptionController(params);
personalTrebbleOptionController.changeSweeper();
}).bind(this)).catch(function(error){
navigator.trebbleNotification.alert(error, null, false, "error");
});
},

changeGreater : function(){
const params = {};
params.trebbleModel = this._playlist;
params.context = this._context;
params.onActionExecutedOnTrebbleHandler = this._onActionExecutedOnTrebbleHandler.bind(this);
return PersistentModels.getInstance().getRouter().require(["controllers/playlistPodcast/PersonalTrebbleOptionController"]).then((function(PersonalTrebbleOptionController){
const personalTrebbleOptionController = new PersonalTrebbleOptionController(params);
personalTrebbleOptionController.changeGreater();
}).bind(this)).catch(function(error){
navigator.trebbleNotification.alert(error, null, false, "error");
});
},

changeIntro : function(){
const params = {};
params.trebbleModel = this._playlist;
params.context = this._context;
params.onActionExecutedOnTrebbleHandler = this._onActionExecutedOnTrebbleHandler.bind(this);
return PersistentModels.getInstance().getRouter().require(["controllers/playlistPodcast/PersonalTrebbleOptionController"]).then((function(PersonalTrebbleOptionController){
const personalTrebbleOptionController = new PersonalTrebbleOptionController(params);
personalTrebbleOptionController.changeIntro();
}).bind(this)).catch(function(error){
navigator.trebbleNotification.alert(error, null, false, "error");
});
},

changeOutro : function(){
const params = {};
params.trebbleModel = this._playlist;
params.context = this._context;
params.onActionExecutedOnTrebbleHandler = this._onActionExecutedOnTrebbleHandler.bind(this);
return PersistentModels.getInstance().getRouter().require(["controllers/playlistPodcast/PersonalTrebbleOptionController"]).then((function(PersonalTrebbleOptionController){
const personalTrebbleOptionController = new PersonalTrebbleOptionController(params);
personalTrebbleOptionController.changeOutro();
}).bind(this)).catch(function(error){
navigator.trebbleNotification.alert(error, null, false, "error");
});
},

changeSweeperConfirm : function(){
const buttonLabels = [window.getI18n(ti18n, "NO"),window.getI18n(ti18n, "YES")];
const confirmCallback = (function(buttonIndex){
if(buttonIndex ==  2){
    setTimeout((function(){
        this.changeSweeper();
    }).bind(this), 100);

}
}).bind(this);
const message = "Are you sure you want change this sweeper?"
navigator.trebbleNotification.confirm(message , confirmCallback, "Change Sweeper", buttonLabels);
},

changeGreaterConfirm : function(){
const buttonLabels = [window.getI18n(ti18n, "NO"),window.getI18n(ti18n, "YES")];
const confirmCallback = (function(buttonIndex){
if(buttonIndex ==  2){
    setTimeout((function(){
        this.changeGreater();
    }).bind(this), 100);

}
}).bind(this);
const message = "Are you sure you want change this greeter?"
navigator.trebbleNotification.confirm(message , confirmCallback, "Change Greeter", buttonLabels);
},

changeIntroConfirm : function(){
const buttonLabels = [window.getI18n(ti18n, "NO"),window.getI18n(ti18n, "YES")];
const confirmCallback = (function(buttonIndex){
if(buttonIndex ==  2){
    setTimeout((function(){
        this.changeIntro();
    }).bind(this), 100);

}
}).bind(this);
const message = "Are you sure you want change this audio intro?"
navigator.trebbleNotification.confirm(message , confirmCallback, "Change Intro", buttonLabels);
},

changeOutroConfirm : function(){
const buttonLabels = [window.getI18n(ti18n, "NO"),window.getI18n(ti18n, "YES")];
const confirmCallback = (function(buttonIndex){
if(buttonIndex ==  2){
    setTimeout((function(){
        this.changeOutro();
    }).bind(this), 100);

}
}).bind(this);
const message = "Are you sure you want change this audio outro?"
navigator.trebbleNotification.confirm(message , confirmCallback, "Change Outro", buttonLabels);
},

deleteCurrentSweeper : function(){
const params = {};
params.trebbleModel = this._playlist;
params.context = this._context;
params.onActionExecutedOnTrebbleHandler = this._onActionExecutedOnTrebbleHandler.bind(this);
return PersistentModels.getInstance().getRouter().require(["controllers/playlistPodcast/PersonalTrebbleOptionController"]).then((function(PersonalTrebbleOptionController){
const personalTrebbleOptionController = new PersonalTrebbleOptionController(params);
personalTrebbleOptionController.deleteCurrentSweeper();
}).bind(this)).catch(function(error){
navigator.trebbleNotification.alert(error, null, false, "error");
});
},

deleteCurrentGreater : function(){
const params = {};
params.trebbleModel = this._playlist;
params.context = this._context;
params.onActionExecutedOnTrebbleHandler = this._onActionExecutedOnTrebbleHandler.bind(this);
return PersistentModels.getInstance().getRouter().require(["controllers/playlistPodcast/PersonalTrebbleOptionController"]).then((function(PersonalTrebbleOptionController){
const personalTrebbleOptionController = new PersonalTrebbleOptionController(params);
personalTrebbleOptionController.deleteCurrentGreater();
}).bind(this)).catch(function(error){
navigator.trebbleNotification.alert(error, null, false, "error");
});
},

deleteCurrentIntro : function(){
const params = {};
params.trebbleModel = this._playlist;
params.context = this._context;
params.onActionExecutedOnTrebbleHandler = this._onActionExecutedOnTrebbleHandler.bind(this);
return PersistentModels.getInstance().getRouter().require(["controllers/playlistPodcast/PersonalTrebbleOptionController"]).then((function(PersonalTrebbleOptionController){
const personalTrebbleOptionController = new PersonalTrebbleOptionController(params);
personalTrebbleOptionController.deleteCurrentIntro();
}).bind(this)).catch(function(error){
navigator.trebbleNotification.alert(error, null, false, "error");
});
},

deleteCurrentOutro : function(){
const params = {};
params.trebbleModel = this._playlist;
params.context = this._context;
params.onActionExecutedOnTrebbleHandler = this._onActionExecutedOnTrebbleHandler.bind(this);
return PersistentModels.getInstance().getRouter().require(["controllers/playlistPodcast/PersonalTrebbleOptionController"]).then((function(PersonalTrebbleOptionController){
const personalTrebbleOptionController = new PersonalTrebbleOptionController(params);
personalTrebbleOptionController.deleteCurrentOutro();
}).bind(this)).catch(function(error){
navigator.trebbleNotification.alert(error, null, false, "error");
});
},

deleteCurrentSweeperConfirm : function(){
const buttonLabels = [window.getI18n(ti18n, "NO"),window.getI18n(ti18n, "YES")];
const confirmCallback = (function(buttonIndex){
if(buttonIndex ==  2){
    setTimeout((function(){
        this.deleteCurrentSweeper();
    }).bind(this), 100);

}
}).bind(this);
const message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_CHANGE_SWEEPER");
navigator.trebbleNotification.confirm(message , confirmCallback,window.getI18n(ti18n, "CHANGE_SWEEPER"), buttonLabels);
},

deleteCurrentGreaterConfirm : function(){
const buttonLabels = [window.getI18n(ti18n, "NO"),window.getI18n(ti18n, "YES")];
const confirmCallback = (function(buttonIndex){
if(buttonIndex ==  2){
    setTimeout((function(){
        this.deleteCurrentGreater();
    }).bind(this), 100);

}
}).bind(this);
const message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_CHANGE_GREETER")
navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n, "CHANGE_GREETER"), buttonLabels);
},

deleteCurrentIntroConfirm : function(){
const buttonLabels = [window.getI18n(ti18n, "NO"),window.getI18n(ti18n, "YES")];
const confirmCallback = (function(buttonIndex){
if(buttonIndex ==  2){
    setTimeout((function(){
        this.deleteCurrentIntro();
    }).bind(this), 100);

}
}).bind(this);
const message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_CHANGE_INTRO")
navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n, "CHANGE_INTRO"), buttonLabels);
},


deleteCurrentOutroConfirm : function(){
const buttonLabels = [window.getI18n(ti18n, "NO"),window.getI18n(ti18n, "YES")];
const confirmCallback = (function(buttonIndex){
if(buttonIndex ==  2){
    setTimeout((function(){
        this.deleteCurrentOutro();
    }).bind(this), 100);

}
}).bind(this);
const message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_CHANGE_OUTRO")
navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n, "CHANGE_OUTRO"), buttonLabels);
},

playPreview : function(){
PersistentModels.getInstance().getPlayer().playAudioPreview(this._playlist.getAudioPreviewUrl());
},

stopPreview : function(){
PersistentModels.getInstance().getPlayer().terminateAudioPreviewAndResumePlayback();
},


showGlobalSearch : function(){
this.hideAddContentButtons();
PersistentModels.getInstance().getRouter().showGlobalSearchPage(null, true);
},

/*
showDraftCapsulesInMyLibrary : function(){
this.hideAddContentButtons();
PersistentModels.getInstance().getRouter().showMyLibraryPage(null, "draftcapsules", true);
},*/

showChooseCapsuleFromLibraryAndAddToJourney : function(){
this.hideAddContentButtons();
return PersistentModels.getInstance().getRouter().showChooseCapsuleFromLibraryPopup((function(capsuleModelSelected){
if(capsuleModelSelected){
    return PersistentModels.getInstance().addCapsuleToJourney(capsuleModelSelected.getCapsuleId(), this._playlist.getLoaderKey());
}
}).bind(this), window.getI18n(ti18n, "ADD_TO_JOURNEY"))
},


changeCoverArtConfirm : function(){
const buttonLabels = [window.getI18n(ti18n, "NO"),window.getI18n(ti18n, "YES")];
const confirmCallback = (function(buttonIndex){
if(buttonIndex ==  2){
    $.mobile.loading("show");
    setTimeout((function(){
        $.mobile.loading("hide");
        this.upadteCoverArt();
    }).bind(this), 1000);

}
}).bind(this);
const message = window.getI18n(ti18n, "DO_YOU_WANT_TO_CHANGE_TREBBLE_COVER");
navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n, "CHANGE_COVER"), buttonLabels);
} ,

upadteCoverArt : function(noNeedToUpload){
const trebbleId = this._playlist.getLoaderKey();
let coverArtUrl = null;
let radioInfo =  null;
let uploadPromise = null;
if(noNeedToUpload){
uploadPromise = TrebbleClientAPIHelper.getInstance().getMyTrebbleInfoByTrebbleId(trebbleId).then((function(radioJsonInfo){
    radioInfo = radioJsonInfo;
}).bind(this));
}else{
if(window.trebble.config.disableZoomInCoverArtInMobileApp || (window.device && window.device.platform =="browser")){
    uploadPromise =  this.uploadTrebbleCoverArtFromWebBrowser();
}else{
    uploadPromise =  this.uploadTrebbleCoverArtFromMobile();
}
}
return uploadPromise.then((function(uploadedImageFileUrl){
$.mobile.loading('show');
if(uploadedImageFileUrl && !noNeedToUpload){
    coverArtUrl = uploadedImageFileUrl;
    return TrebbleClientAPIHelper.getInstance().updateRadioCoverArtByRadioId(uploadedImageFileUrl,trebbleId).then((function(coverArtUrlBlur){
        return Utils.getInstance().preloadImageUsingUrl(coverArtUrlBlur);
    }).bind(this));
}

}).bind(this)).then((function(coverArtUrlBlur){
if(coverArtUrl || noNeedToUpload){
    if(noNeedToUpload){
        coverArtUrl = radioInfo.coverArtUrl;
        coverArtUrlBlur = radioInfo.coverArtUrlBlur;
    }
    this._playlist.setCoverArtUrl(coverArtUrl);
    this._playlist.setCoverArtUrlBlur(coverArtUrlBlur);
    //this is done to force the browser to reload the image
    const coverArtUrlWithRandomString = false?coverArtUrl + "?random="+ (new Date()).getTime(): coverArtUrl;
    const coverArtUrlWithRandomStringBlur = false?coverArtUrlBlur + "?random="+ (new Date()).getTime(): coverArtUrlBlur;
    this.updateTrebblePictureCoverUrlOnView(coverArtUrlWithRandomString, coverArtUrlWithRandomStringBlur);
    PersistentModels.getInstance().onPersonalTrebbleCreatedOrEdited(this._playlist.getPlaylistSummary().toJSON());
    if(this._openEditPageController.length > 0){
        for(let index in this._openEditPageController){
            const editPageController = this._openEditPageController[index];
            editPageController.setCoverArtInfo(coverArtUrl);
        }
    }
}
$.mobile.loading('hide');
if(!noNeedToUpload){
    navigator.trebbleNotification.alert(window.getI18n(ti18n, "YOUR_TREBBLE_COVER_WAS_CHANGED"), null, false, "success");
}
return coverArtUrl;
}).bind(this)).catch((function(error){
$.mobile.loading('hide');
if(!noNeedToUpload){
    navigator.trebbleNotification.alert(error, null, false, "error");
}
}).bind(this));
},


uploadTrebbleCoverArtFromWebBrowser : function(){
const trebbleId = this._playlist.getLoaderKey();
return Utils.getInstance().requireFileUploader(["models/helper/FileUploadHelper"]).then((function(FileUploadHelper){
return new  RSVP.Promise((function(resolve, reject){
    const uploadEementId = "uploadWrapper_"+((new Date()).getTime());
    const nameOfFileToUpload =  "trebbleCoverArt_"+(new Date()).getTime()+".jpg";
    const text = window.isMobileBrowser?window.getI18n(ti18n, "TAP_HERE_TO_SELECT_IMAGE"): window.getI18n(ti18n, "CLICK_HERE_TO_SELECT_IMAGE");
    const uploadZoneHtml=  "<span class='uploadZonePlaceHolder' id='"+uploadEementId +"'><div class='pe-7s-upload uploadIcon' style='font-size:40px'></div><span  class='dz-message'>"+ text +"</span></span>";
    const buttonLabels = [window.getI18n(ti18n, "CANCEL"),window.getI18n(ti18n, "SAVE")];
    let dropZoneObj =  null;
    const confirmCallback = (function(buttonIndex){
        if(buttonIndex ==  2){
            if(dropZoneObj){
                if(dropZoneObj.getQueuedFiles().length == 0){
                    setTimeout(function(){swal.enableButtons()}, 300);
                }else{
                    FileUploadHelper.getInstance().validateAndSignFunctionHanlder(dropZoneObj, nameOfFileToUpload, FileUploadHelper.getInstance().getImageFileTypeValidationFunction(), "getSignatureForTrebbleCoverArtUpload" ,trebbleId).then(function(){
                        dropZoneObj.processQueue();
                    }).catch(function(error){
                        navigator.trebbleNotification.alert(error, null, false, "error");
                    })

                }
            }
        }
    }).bind(this);
    navigator.trebbleNotification.confirm(uploadZoneHtml , confirmCallback, window.getI18n(ti18n, "CHANGE_TREBBLE_IMAGE_COVER"), buttonLabels, true, null , null, true);
    const handlers = Utils.getInstance().getConfirmDialogDeferredHandlers(resolve, reject, this);
    dropZoneObj =  FileUploadHelper.getInstance().uploadTrebbleCoverImageInWebBrowser(nameOfFileToUpload,"#"+ uploadEementId,handlers.resolve,handlers.reject);
}).bind(this));
}).bind(this));

},

uploadTrebbleCoverArtFromMobile : function(){
const trebbleId = this._playlist.getLoaderKey();
return Utils.getInstance().getUserToPickAPicture().then((function(imageLocationUrl){
$.mobile.loading('show');
return FileUploadHelper.getInstance().uploadCoverArtForTrebble(imageLocationUrl, trebbleId);
}).bind(this))
},


_onSongListFinishLoading : function(){
//if(!this.model.canBeEdited()){
//FOR NOW , ALWAYS SHOW CAPSULES TAB AND ALWAYS HIDE MUSIC TAB, MIGHT NOT EVEN NEED TO LOAD IT
this.view.$el.removeClass("songOrCapsuleLoading");
if(false && this.songListController.getModel() && this.songListController.getModel().length > 0){
    this.view.$el.removeClass("showCapsulesOnly");
    this.view.$el.addClass("showSongsOnly");
    this.view.$el.find("#albumList .listHeader").html("MUSIC");
}else{
    this.view.$el.removeClass("showSongsOnly");
    this.view.$el.addClass("showCapsulesOnly");
    //this.view.$el.find("#albumList .listHeader").html("ON-AIR CAPSULES");

}

//}
},

isTrebblePlayable: function(){
    let numberOfSongs  = 0;
    let numberOfCapsules = 0;
    let numberOfActiveCapsules = 0;

if( this.songListController && !this.songListController.isLoading() && this.songListController.getModel()){
    numberOfSongs = this.songListController.getModel().length ;
}


if(this.capsuleCollectionRepeatController &&  !this.capsuleCollectionRepeatController.isLoading() && this.capsuleCollectionRepeatController.getModel()){
    numberOfCapsules = this.capsuleCollectionRepeatController.getModel().length;

    let numberOfBeingProcessedCapsule = 0;
    if(numberOfCapsules > 0){	
        for(let i = 0 ; i < this.capsuleCollectionRepeatController.getModel().length; i++){
            const capsuleModel = this.capsuleCollectionRepeatController.getModel()[i];
            const isBeingProcessed = capsuleModel.isBeingProcessed();
            if(isBeingProcessed){
                numberOfBeingProcessedCapsule = numberOfBeingProcessedCapsule + 1;
            }
        }
    }
    numberOfActiveCapsules =  numberOfCapsules - numberOfBeingProcessedCapsule;
}


return (this.capsuleCollectionRepeatController && this.capsuleCollectionRepeatController.isLoading()) || this.songListController.isLoading() || !this._playlist || !this._playlist.canBeEdited() || PlayerService.getInstance().isTrebblePlayableWithSongsAndCapsules(numberOfSongs, numberOfActiveCapsules);
},

_getNumberOfActiveCapsules : function(){
    let numberOfSongs  = 0;
let numberOfCapsules = 0;
let numberOfActiveCapsules = 0;

if( this.songListController && !this.songListController.isLoading() && this.songListController.getModel()){
    numberOfSongs = this.songListController.getModel().length ;
}

if(this.capsuleCollectionRepeatController &&  !this.capsuleCollectionRepeatController.isLoading() && this.capsuleCollectionRepeatController.getModel()){
    numberOfCapsules = this.capsuleCollectionRepeatController.getModel().length;

    let numberOfBeingProcessedCapsule = 0;
    if(numberOfCapsules > 0){	
        for(let i = 0 ; i < this.capsuleCollectionRepeatController.getModel().length; i++){
            const capsuleModel = this.capsuleCollectionRepeatController.getModel()[i];
            const isBeingProcessed = capsuleModel.isBeingProcessed();
            if(isBeingProcessed){
                numberOfBeingProcessedCapsule = numberOfBeingProcessedCapsule + 1;
            }
        }
    }
    numberOfActiveCapsules =  numberOfCapsules - numberOfBeingProcessedCapsule;
}
return numberOfActiveCapsules;

},

_onCapsuleListFinishLoading: function(){
this._capsuleFinishLoading = true;
this.updateNotEnoughShortcastToPublishTag();
this._updateJourneyNumberOfDaysOnCapsuleListFinishLoading();
//this._updateStartTrebbleButtonDisplay();
this._showWarningThatNumberShortcastIsNotEnoughToBePublished();
this._updateAvailabilityDateIfApplicable();
this.showHideShareJourneyTooltipIfApplicable(this._playlist.canBeEdited() && !LocalStorageHelper.getInstance().isShareTooltipDiscardedByUser());
this._showProgressBarIfApplicable();
},

_showWarningThatNumberShortcastIsNotEnoughToBePublished : function(){
const numberOfActiveCapsules =  this._getNumberOfActiveCapsules();
const isTrailerRequired = IS_TRAILER_REQUIRED_FOR_PUBLISHING;
const hasTrailer = !!this._trailerCapsuleInfoJSON;
const hasEnoughShortcast = MIN_NUMBER_OF_ACTIVE_CAPSULES_TO_PUBLISH <= numberOfActiveCapsules;
const canPublish = hasEnoughShortcast && (!isTrailerRequired || hasTrailer);

let warningMessage = window.getI18n(ti18n, "A_JOURNEY_NEEDS_TO_HAVE_AT_LEAST") +" "+ MIN_NUMBER_OF_ACTIVE_CAPSULES_TO_PUBLISH +" " +window.getI18n(ti18n, "SHORTCASTS_TO_BE_PUBLISHED");
if(IS_TRAILER_REQUIRED_FOR_PUBLISHING){
    warningMessage = window.getI18n(ti18n, "A_JOURNEY_NEEDS_TO_HAVE_AT_LEAST") +" "+ MIN_NUMBER_OF_ACTIVE_CAPSULES_TO_PUBLISH +" " +window.getI18n(ti18n, "SHORTCASTS_AND_A_TRAILER_TO_BE_PUBLISHED");
}
this.view.$el.find("#trebblewarning > #message").html(warningMessage);
const trebblewarning$el =  this.view.$el.find("#trebblewarning");
if(!canPublish && this.model.getStatus() == JourneySummary.JOURNEY_STATUS.DRAFT){
    if(!trebblewarning$el.hasClass("show")){
        trebblewarning$el.addClass("show");
    }
}else{
    trebblewarning$el.removeClass("show");
}
},

_updateAvailabilityDateIfApplicable: function(){
if(this.model.getAvailabilityDate() && this.model.getAvailabilityDate().getTime() > (new Date().getTime())){
    const dateReadable = moment(this.model.getAvailabilityDate()).format('LLL');
    this.view.$el.find(".schedule_date_message_wrapper .schedule_date").html(dateReadable);
    this.view.$el.attr("schedule_date_warning_should_be_displayed", true);
}else{
    this.view.$el.attr("schedule_date_warning_should_be_displayed", false);
}
},

_updateJourneyNumberOfDaysOnCapsuleListFinishLoading : function(){
const numberOfDays = this._getNumberOfActiveCapsules();
this.view.$el.find(".jouney_summary_detail_item.number_of_days_info  .detail").html(numberOfDays >1 ? numberOfDays + " "+ window.getI18n(ti18n, "DAYS"): numberOfDays + " "+ window.getI18n(ti18n, "DAY"));
},

/*
_updateStartTrebbleButtonDisplay : function(){
if(this._capsuleFinishLoading){
    const numberOfSongs  = 0;
    const numberOfCapsules = 0;
    const numberOfActiveCapsules = 0;

    if( this.songListController && !this.songListController.isLoading() && this.songListController.getModel()){
        numberOfSongs = this.songListController.getModel().length ;
    }


    if(this.capsuleCollectionRepeatController &&  !this.capsuleCollectionRepeatController.isLoading() && this.capsuleCollectionRepeatController.getModel()){
        numberOfCapsules = this.capsuleCollectionRepeatController.getModel().length;

        const numberOfBeingProcessedCapsule = 0;
        if(numberOfCapsules > 0){	
            for(let i = 0 ; i < this.capsuleCollectionRepeatController.getModel().length; i++){
                const capsuleModel = this.capsuleCollectionRepeatController.getModel()[i];
                const isBeingProcessed = capsuleModel.isBeingProcessed();
                if(isBeingProcessed){
                    numberOfBeingProcessedCapsule = numberOfBeingProcessedCapsule + 1;
                }
            }
        }
        numberOfActiveCapsules =  numberOfCapsules - numberOfBeingProcessedCapsule;
    }


    //const trebblePlayable = (!this._playlist.canBeEdited() || (this.capsuleCollectionRepeatController && !this.capsuleCollectionRepeatController.isLoading())) && ( (this.capsuleCollectionRepeatController && this.capsuleCollectionRepeatController.isLoading()) || (this.songListController && this.songListController.isLoading()) || !this._playlist || !this._playlist.canBeEdited() || PlayerService.getInstance().isTrebblePlayableWithSongsAndCapsules(numberOfSongs, numberOfActiveCapsules));
    const trebblePlayable = (this.capsuleCollectionRepeatController && !this.capsuleCollectionRepeatController.isLoading() &&  PlayerService.getInstance().isTrebblePlayableWithSongsAndCapsules(numberOfSongs, numberOfActiveCapsules))
    if(MARK_TREBBLE_AS_NOT_PLAYABLE){
        if(trebblePlayable){
            this.view.$el.find("[id=startRadioBtn]").removeClass("notplayable");
        }else{
            this.view.$el.find("[id=startRadioBtn]").each(function() {
                const startBtn$el = $(this);
                if(!startBtn$el.hasClass("notplayable")){
                    startBtn$el.addClass("notplayable");
                }
            });

}
}
if(trebblePlayable){
this.view.$el.find("#trebblewarning").removeClass("show");

if(this._trebbleWasNotPlayableDuringCurrentSession){

}
}else{
this._trebbleWasNotPlayableDuringCurrentSession = trebblePlayable;
const numberOfSongsLeftToStream =  (numberOfSongs >= Utils.getInstance().getDefaultNumberOfSongsRequiredToPlayATrebble())? 0 :Utils.getInstance().getDefaultNumberOfSongsRequiredToPlayATrebble() - numberOfSongs;
//const numberOfCapsules =  PlayerService.getInstance().getNumberOfCapsules(this._playlist);
if(numberOfBeingProcessedCapsule > 0){
const message = window.getI18n(ti18n, "YOUR_TREBBLE_WILL_BE_READY_TO_STREAM_CAPSULE_PROCESSING");
}else{

const message = window.getI18n(ti18n, "TREBBLE_HAS_NO_CAPSULE");
}
this.view.$el.find("#trebblewarning > #message").html(message);
const trebblewarning$el =  this.view.$el.find("#trebblewarning");
if(!trebblewarning$el.hasClass("show")){
trebblewarning$el.addClass("show");
}
this.showHideShareJourneyTooltipIfApplicable(false);
if(!this._isPageCurrentlyDisplayed()){
//Utils.getInstance().showTrebbleReadyNotification(message);
}

}
}
},*/

_isPageCurrentlyDisplayed : function(){
return this.view.$el.hasClass("ui-page-active");
},

/*_updateTrebbleWarningMessageIfApplicable : function(){
const trebblePlayable =  !this._playlist || !this._playlist.canBeEdited() || (PlayerService.getInstance().isTrebblePlayable(this._playlist) || (window._capsuleCreatedInThisSession && this._playlist.canBeEdited()));
if(trebblePlayable){
this.view.$el.find("#trebblewarning").removeClass("show");
}else{
const numberOfSongsLeftToStream =  PlayerService.getInstance().getNumberOfSongsLeftToStream(this._playlist);
const numberOfCapsules =  PlayerService.getInstance().getNumberOfCapsules(this._playlist);
const message = "Your Trebble is almost ready to stream. Try creating a capsule or add "+ numberOfSongsLeftToStream +" more songs!";
this.view.$el.find("#trebblewarning > #message").html(message);
const trebblewarning$el =  this.view.$el.find("#trebblewarning");
if(!trebblewarning$el.hasClass("show")){
    trebblewarning$el.addClass("show");
}
}
},*/

onStartRadioButtonTapped : function(){
if(this.isTrebblePlayable() || (window._capsuleCreatedInThisSession && this._playlist.canBeEdited())){//The second clause here is more of a around where if the user created a capsule in the current user session, we will try to play his trebble anyway. We are simply doing this because the numberof capsules on Trebble is not updated until the user refreshes. this is a bug


$.mobile.loading( 'show', {
    text: window.getI18n(ti18n, "LOADING_TREBBLE"),
    textVisible: false,
    theme: "t",
    textonly: false,
    // html: html
});

this._retrieveDetailedPlaylistInfo().then((function(){
    return PersistentModels.getInstance().getPlayer().setPlaylist(this._playlist, true);
}).bind(this)).then((function(){
    $.mobile.loading( 'hide');
    /*if(this._playlist){
        const loaderKey = this._playlist.getLoaderKey();
        const name = this._playlist.getName();
        const context = Utils.getInstance().buildContextForFollowingRadioDetail(loaderKey);
        TrebbleClientAPIHelper.getInstance().addTebbleLoadActionToMyHistory(loaderKey,name ,new Date(), null,context, null);
    }*/
}).bind(this)).catch((function(error){
    $.mobile.loading( 'hide');
    if(PersistentModels.getInstance().getPlayer().isNoContentToPlayError(error)){
        this._onPlaylistNotBeingAbleToPlay();
        PersistentModels.getInstance().getPlayer().clearQueue();
    }else{
        console.error("Coudn't load playlist.Error:"+ error);
        window.alertErrorMessage(window.getI18n(ti18n, "COULDNT_LOAD_PLAYLIST")+ error);
    }
}).bind(this));
}else{
this._onPlaylistNotBeingAbleToPlay();
PersistentModels.getInstance().getPlayer().clearQueue();
}
},



_onPlaylistNotBeingAbleToPlay : function(){
if(this._playlist.canBeEdited()){

const buttonLabels = [window.getI18n(ti18n, "OK"), window.getI18n(ti18n, "CREATE_CAPSULE")];
const confirmCallback = (function(buttonIndex){
    if(buttonIndex ==  2){
        setTimeout((function(){
            PersistentModels.getInstance().getRouter().showCreateACapsulePageWithoutContext(null);
        }).bind(this), 100);

    }else{

    }
}).bind(this);
const minimumOfSongsNeeded  =  Utils.getInstance().getDefaultNumberOfSongsRequiredToPlayATrebble();
//const message = "A Trebble needs to contain 1 capsule or "+ minimumOfSongsNeeded +" songs minimum to be played."
const message = window.getI18n(ti18n, "TREBBLE_HAS_NO_CONTENT");
const subMessage = window.getI18n(ti18n, "YOU_CAN_ADD_CONTENT_BY_CREATING_CAPSULES");
navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n, "NEED_TO_HAVE_MINIMUM_OF_SONGS"), buttonLabels, null,  null, subMessage);
if(window.trebbleAnalyticsHelper){
    window.trebbleAnalyticsHelper.trackEvent("onboarding", "Discover Mininum Song Number For Trebble", "Discover Mininum Song Number For Trebble");
} 
}else{
navigator.trebbleNotification.alert(window.getI18n(ti18n, "THIS_TREBBLE_HAS_NO_ACTIVE_CAPSULE"), null, false);
}
},

_retrieveDetailedPlaylistInfo : function(){
//if(!this._playlist){
if(this._playlistRetrievalPendingPromise){
    return this._playlistRetrievalPendingPromise;
}else{
    const p = PlaylistHelper.getInstance().getPersonalUserRadioPlaylistById(this.model).then((function(playlist){
        this._detailedPlaylistInfoRetrieved = true; 
        this._playlistRetrievalPendingPromise = null;
        this._playlist = playlist;
        return this._playlist;
    }).bind(this));
    this._playlistRetrievalPendingPromise =  p;
    return this._playlistRetrievalPendingPromise;
}
/*	}else{
    return RSVP.Promise.resolve(this._playlist);
}*/
},

loadPlaylistDetails : function(){
return this._retrieveDetailedPlaylistInfo().then((function(){
    if(this._playlist){
        if(this._playlist.load){
            return this._playlist.retrieveAllAvailableSongsInJson();
        }else{
            return this._playlist.toJSON();
        }
    }else{
        return [];
    }

}).bind(this)).catch(function(error){
    console.error("Coudn't load playlist.Error:"+ error);
    window.alertErrorMessage(window.getI18n(ti18n, "COULDNT_LOAD_PLAYLIST")+ error);
});

},

onShareMyJourneyTooltipIfApplicableClickedOn : function(){
this.showHideShareJourneyTooltipIfApplicable(false, true);
},

makeOutlineSortableIfApplicable : function(){
if(this.model.getStatus() == JourneySummary.JOURNEY_STATUS.DRAFT){
    const outlineListContainer$el = this.capsuleCollectionRepeatController.getView().$el;
    const collectionRepeaterConainer$el =  outlineListContainer$el.find("#collection_repeat_container");
    if(!collectionRepeaterConainer$el.hasClass("sortable")){
        collectionRepeaterConainer$el.addClass("sortable");
    }
    this._outlineSortableList = new Sortable(collectionRepeaterConainer$el.find(".collection_content_wrapper").get(0), {"animation": 400, "handle":".drag_btn_wrapper", "onUpdate": this._onOutlineSortingChanged.bind(this)});
}
},

getOutlineIdsInOrderInList :function(){
const outlineIds = [];
this.capsuleCollectionRepeatController.getView().$el.find("#collection_repeat_container .collection_content_wrapper").children().each(function(){outlineIds.push(this.getAttribute("outline-item-id"))});
return outlineIds;
},


startPlayingTrailerInPlayer : function(){
$.mobile.loading( 'show');
let playlistFound = null;

//this.setTrailerPlaying(true);
return PlaylistHelper.getInstance().getJourneyPlaylistForCapsuleId(this._trailerCapsuleModel.getCapsuleId(), this.model).then((function(playlist){
    playlistFound = playlist;
    return PersistentModels.getInstance().getPlayer().setPlaylist(playlist, true);
}).bind(this)).then((function(){
    $.mobile.loading( 'hide');

}).bind(this)).catch((function(error){
    $.mobile.loading( 'hide');
    if(PersistentModels.getInstance().getPlayer().isNoContentToPlayError(error)){
        this._onPlaylistNotBeingAbleToPlay(playlistFound);
        PersistentModels.getInstance().getPlayer().clearQueue();
    }else{
        console.error("Coudn't load playlist.Error:"+ error);
        window.alertErrorMessage(window.getI18n(ti18n,"COULDNT_LOAD_PLAYLIST")+ error);
    }
}).bind(this));
},

startPlayingOrPausingTrailerCapsule :function(){
if(!this._isTrailerPlaying){
    this.startPlayingTrailerCapsule();
}else{
    this.pausePlayingTrailerCapsule();
}
},

pausePlayingTrailerCapsule : function(){
PersistentModels.getInstance().getPlayer().stopAudioPreviewAndRelease();
this.setTrailerPlaying(false);
},

startPlayingTrailerCapsule : function(){
if((this._isPublicPage || this._isInPreviewMode) && this.model){
    TrebbleClientAPIHelper.getInstance().logRadioTrailerPlayed(this.model.getLoaderKey());
}
this.startPlayingTrailerInPreviewMode();
//this.startPlayingTrailerInPlayer();
},

startPlayingTrailerInPreviewMode : function(){
if(this._trailerCapsuleModel){
    this.setTrailerPlaying(true);
    const audioUrl = this._trailerCapsuleModel.getAudioUrl();
    const successCB = (function(){
        this.trigger("finishTrailerPlaying");
        this.setTrailerPlaying(false);
    }).bind(this);
    const failureCB = (function(){
        this.trigger("failedTrailerPlaying");
        this.setTrailerPlaying(false);
    }).bind(this);
    if(audioUrl){
        this.trigger("startTrailerPlaying");
        PersistentModels.getInstance().getPlayer().playAudioPreview(audioUrl, successCB, failureCB);
    }else{
        Utils.getInstance().showShortToastMessage(window.getI18n(ti18n, "NO_AUDIO_FOUND_TO_PLAY"));
    }
}
},


setTrailerPlaying : function(isPlaying){
this._isTrailerPlaying = isPlaying;
const playTrailerBtn  = this.view.$el.find("#playTrailerBtn");
if(this._isTrailerPlaying){
    playTrailerBtn.removeClass("ion-ios7-play");
    playTrailerBtn.addClass("fontello-icon-stop");
}else{
    playTrailerBtn.removeClass("fontello-icon-stop");
    playTrailerBtn.addClass("ion-ios7-play");
}
},


_updateOutlineItemIndex : function(){
const outlineIds = this.getOutlineIdsInOrderInList();
const outlineIdToIndex = {};
for(let i =0; i < outlineIds.length; i++){
    outlineIdToIndex[outlineIds[i]] =  i;
}

for(let i =0; i < this.capsuleCollectionRepeatController.models.length; i++){
    const outlineItemModel =  this.capsuleCollectionRepeatController.models[i];
    outlineItemModel.setDayNumber(outlineIdToIndex[outlineItemModel.getCapsuleId()]+ 1);
}
},

_saveOutlineItemsCurrentOrder : function(){

return TrebbleClientAPIHelper.getInstance().saveShortcastOrderInJourney(this.model.getLoaderKey(), this.getOutlineIdsInOrderInList());
},

_onOutlineSortingChanged : function(){
this._updateOutlineItemIndex();
this._saveOutlineItemsCurrentOrder();

},

checkIfUserCanPostReviewAndOpenCreateReviewPage : function(){
$.mobile.loading( 'show');
return TrebbleClientAPIHelper.getInstance().canUserSubmitAJourneyReview(this.model.getLoaderKey()).then((function(operationResult){
    $.mobile.loading('hide');
    if(operationResult.userHasListenToEnoughToReview){
        this.createOrUpdateReview();
    }else{
        const warningSubmessage = window.getI18n(ti18n,"YOU_TO_LISTEN_TO") +" "+MIN_NUMBER_OF_SHORTCAST_LISTENED_TO_REVIEW+ " "+ window.getI18n(ti18n,"AT_LEAST_TO_SUBMIT_A_REVIEW");
        window.alertWarningMessage(window.getI18n(ti18n,"YOU_CANNOT_POST_A_REVIEW_YET"), warningSubmessage, function(){});
    }
}).bind(this)).catch((function(error){
    $.mobile.loading('hide');
    window.alertErrorMessage(error);
}).bind(this))
},

showHideShareJourneyTooltipIfApplicable: function(show, wasAcknowledgeByUser){
const share_my_trebble_tooltip$el = this.view.$el.find("#shareTrebbleBtn .share_my_trebble_tooltip");
if(!this._shareMyTrebbleTooltipWasAcknoledgeByUser && show){
    share_my_trebble_tooltip$el.removeClass("hide");
}else{
    if(!share_my_trebble_tooltip$el.hasClass("hide")){
        share_my_trebble_tooltip$el.addClass("hide");
        this._shareMyTrebbleTooltipWasAcknoledgeByUser = wasAcknowledgeByUser;
    }
}
if(wasAcknowledgeByUser){
    const loggedInUserInfo = LocalStorageHelper.getInstance().getUserInfo();
    const isTemporaryUser = loggedInUserInfo ? loggedInUserInfo.temporary : false;
    if(!isTemporaryUser){
        LocalStorageHelper.getInstance().setShareTooltipDiscardedByUser();
    }
}
},



});
export default MyPersonalJourneyDetailsController;