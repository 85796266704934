//NOT USED ANYMORE
import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Playlist from "collections/Playlist";
import Album from "models/Album";
import songListTemplate from 'text!../../templates/mylibrary/songListTemplate_compiled.html';
import infiniteListAdapter from 'collections/savewrapper/infiniteListView2';

const ArtistCollectionView = Backbone.View.extend({

    template : _.template(songListTemplate),

    initialize : function() {
        this.render();

        const onOrientationChangeFunction  = function(){
             switch(window.orientation) 
            {  
              case -90:
              case 90:
              //  window.alertErrorMessage('landscape');

                break; 
              default:
              //  window.alertErrorMessage('portrait');
                break; 
            }
            if(this.el){
                const headerHeight = 152;
                const footerHeight = 65;
                    this.el.style.height = (window.getDocumentClienHeight() -headerHeight - footerHeight) +"px";
                    //this.el.style.width = window.getDocumentClienWidth()  +"px";
                }
        };
        window.addEventListener('orientationchange', onOrientationChangeFunction.bind(this));
        window.addEventListener('resize', onOrientationChangeFunction.bind(this));
                    
    },

    
    
    render : function() {
    

        this.$el.html(' <div id="artistList"  style="height:700px;overflow:hidden;width:100%;"></div>');
        const listePlaceHolder = this.$el.find("div").first();
        this.setElement(listePlaceHolder);
    

         const _template = function(model)
          {

            let artistToDisplay = model.artistName;
            let numberOfAlbumsToDisplay = model.albums.length;
            if(!artistToDisplay)
            {
                artistToDisplay = "";
            }
            if(!numberOfAlbumsToDisplay)
            {
                numberOfAlbumsToDisplay = "0";
            }else{
                numberOfAlbumsToDisplay  = numberOfAlbumsToDisplay + " album";
                if(numberOfAlbumsToDisplay > 1)
                {
                    numberOfAlbumsToDisplay  = numberOfAlbumsToDisplay + "s";
                }

            }
            return "<div class='songLibraryWrapper'>"+
            " <a  class='ui-btn ui-btn-c ui-btn-up-undefined'> <div class='songTitleLibrary'> "+ 
              artistToDisplay+"</div><div class='songArtistLibrary'> "+
               numberOfAlbumsToDisplay + "</div></a></div><div class='songOptionBtnWrapper'>"+ 
               "<a href='#songPoppupMenu' class='pe-7s-more ui-btn ui-btn-c ui-btn-up-a "
               +"ui-shadow ui-btn-corner-all ui-btn-inline ' id='songLibraryOptionButtuon' ></a></div>";
          };
        
         const artistCollectionView = this;
         const onItemTap =  function(model,evnt, item) {

            
        };

        const onItemSwipeRight = function(model,evnt, item){
        
        };

        const itemHandlerFunction = function(elem,model){

            let artistToDisplay = model.artistName;
            let numberOfAlbumsToDisplay = model.albums.length;
            if(!artistToDisplay)
            {
                artistToDisplay = "";
            }
            if(!numberOfAlbumsToDisplay)
            {
                numberOfAlbumsToDisplay = "0";
            }else{
                numberOfAlbumsToDisplay  = numberOfAlbumsToDisplay + " album";
                if(numberOfAlbumsToDisplay > 1)
                {
                    numberOfAlbumsToDisplay  = numberOfAlbumsToDisplay + "s";
                }

            }
            const albumTitleLibraryElem = elem.querySelector(".songTitleLibrary");
            albumTitleLibraryElem.textContent = artistToDisplay;
            const artistTitleLibraryElem = elem.querySelector(".songArtistLibrary");
            artistTitleLibraryElem.textContent = numberOfAlbumsToDisplay;
        };

        const onOptionBtnTapped =  function(model,event,item)
        {
            $("#songPoppupMenu").popup("open",{"overlay-theme": "b",positionTo: "window" ,dismissible: true, tolerance: "30,5"});
        };

        
        const itemEventHandler = {
            "tap a:first-child" : onItemTap,
            "dragright a:first-child" : onItemSwipeRight,
            "tap div.songOptionBtnWrapper > a" : onOptionBtnTapped,
          };

          const  overrideMainContainerStyle = {
            "-webkit-box-flex" : "1",
            "height" : "inherit"
          };

          const    overrideSingleItemStyle = {
            "display": "-webkit-box",
            "overflow":"hidden"
          };
         new infiniteListAdapter(this.el ,_template, this.collection.toJSON(),itemEventHandler,80,60,itemHandlerFunction,{},overrideSingleItemStyle);


        return this;
    }

});
export default ArtistCollectionView;