import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import owlCarousel from "owlcarousel";
import PageTemplate from 'text!../../../templates/help/GetStartedForCuratorsTemplate.html';

const HelpPageView = Backbone.View.extend({

    template : _.template(PageTemplate),


initialize : function() {

},

  onOrientationChangeFunction : function(newViewPortHeigh) {
    if (this.$el) {
        this.$el.find(".featureBoxWrapper").css("height",  window.getDocumentClienHeight() + "px");
        this.$el.find("#featuresCarousel").css("height",  window.getDocumentClienHeight() + "px");
        
        
    }
},

events : {
    "click #finishButton" : "onFinishButtonClicked",
    "click #nextButton" : "onNextButtonClicked",
    "click #previousButton" : "onPreviousButtonClicked",
    "click #homeHeaderButton" : "onHomeHeaderButtonClicked",
    "click #downloadExtensionBtn" : "ondownloadExtensionButtonClicked",
    "click #creatYourFirstCapsuleBtn": "onCreatYourFirstCapsuleBtnClicked",
    "click #addSongsBtn": "onAddSongsBtnClicked",
},

onHomeHeaderButtonClicked : function(){
    this.trigger("showSidePanel");
},

ondownloadExtensionButtonClicked : function(){
    this.trigger("downloadExtension");
},

onAddSongsBtnClicked : function(){
    this.trigger("addSongs");
},

onFinishButtonClicked : function(){
    //this.trigger("showDefaultPage");
    this.trigger("showPreviousPage");
},

onNextButtonClicked : function(){
    if(this.carousel$el){
        const carouselData =  this.carousel$el.data('owlCarousel');
        carouselData.next();
    }
},

onCreatYourFirstCapsuleBtnClicked : function(){
    this.trigger("createYourFirstCapsule");
},

onPreviousButtonClicked : function(){
    if(this.carousel$el){
        const carouselData =  this.carousel$el.data('owlCarousel');
        carouselData.prev();
    }
},


onBeforeRemoveFromPage : function(){
    this.trigger("beforeRemoveFromPage", this);
},

onRemovedFromPage : function(){
     this.undelegateEvents();
     this.$el.removeData().unbind(); 
     this.removeKeyboardControl();
     window.removeEventListener('resize', window[this._onViewportHeightChangedFunctionRef] );
     this.trigger("removedFromPage", this);
},


_onPageCreated: function(){
    this.$el.css("padding" , "0px");
    if(window.trebble.allowAutoLoginAsGuest){
        this.$el.find("#homeHeaderButton").hide();
    }
},

_onCarouselMove: function(){
    if(this.carousel$el){
        const carouselData =  this.carousel$el.data('owlCarousel');
        if(carouselData && carouselData.owl){
            if(carouselData.owl.currentItem == (carouselData.owl.owlItems.length -1)){
                
                this.$el.find("#nextButton").hide();
                this.$el.find("#finishButton").show();
            }else{
                this.$el.find("#finishButton").hide();
                this.$el.find("#nextButton").show();
            }
            
            if(carouselData.owl.currentItem == 0){
                this.$el.find("#previousButton").hide();
            }else{
                this.$el.find("#previousButton").show();
            }
        }
        if(this.videElPlaying){
            if(this.videElPlaying.currentOperationPromise){
                this.videElPlaying.currentOperationPromise.then((function(){
                    this.videoEl.currentOperationPromise = this.videoEl.pause();
                }).bind({videoEl : this.videElPlaying}));
            }else{
                this.videElPlaying.currentOperationPromise = this.videElPlaying.pause();
            }
        }
        const currentVideoEl$el = this.$el.find("video");
        if(currentVideoEl$el.length > 0 && carouselData.owl.currentItem < currentVideoEl$el.length ){
            this.videElPlaying = currentVideoEl$el.get(carouselData.owl.currentItem);
            if(this.videElPlaying.currentOperationPromise){
                this.videElPlaying.currentOperationPromise.then((function(){
                    this.videoEl.currentOperationPromise = this.videoEl.play();
                }).bind({videoEl : this.videElPlaying}));
            }else{
                this.videElPlaying.currentOperationPromise = this.videElPlaying.play();
            }
        }else{
            this.videElPlaying = null
        }
        
    }
},

addKeyboardControl : function(){
    $(document).on('keydown.#featuresCarousel', (function(e) {
        if(e.keyCode == 37) {
            if(this.$el.find("#previousButton").is(":visible")){
                this.onPreviousButtonClicked();
            }
        }
        if(e.keyCode == 39) {
            if(this.$el.find("#finishButton").is(":visible")){
                this.onFinishButtonClicked();
            }else{
                this.onNextButtonClicked();
            }
           
        }
    }).bind(this));

},
removeKeyboardControl : function(){
    $(document).off('keydown.#featuresCarousel');

},

render : function(){
    this.$el.find("#nextButton").hide();
    this.$el.find("#previousButton").hide();
    this.$el.find("#finishButton").hide();
    this.$el.html(this.template());
    this.setElement(this.$el.find("div").first());
    this.$el.page({create: this._onPageCreated.bind(this)});
    const functionUniqueGlobalName = "onViewportHeightChangeForFeaturePageViewPage"+ (new Date()).getTime();
    window[functionUniqueGlobalName] =  this.onOrientationChangeFunction.bind(this)
    this._onViewportHeightChangedFunctionRef = functionUniqueGlobalName;
    window.addEventListener('resize', window[functionUniqueGlobalName]  );
    this.carousel$el = this.$el.find("#featuresCarousel").first().owlCarousel({
         
          navigation : false, // Show next and prev buttons
          pagination :true,
          slideSpeed : 300,
          paginationSpeed : 400,
          afterAction : this._onCarouselMove.bind(this),
          singleItem:true
        });
    this.onOrientationChangeFunction(window.getDocumentClienHeight());
    this.addKeyboardControl();
    this.$el.on("pagehide", this._onPageHide.bind(this));
    return this;
},

_onPageHide : function(){
        this.onBeforeRemoveFromPage();
        this.remove();
        this.onRemovedFromPage();
    
},
    });
export default HelpPageView;