

import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import ChooseCapsuleWidgetView from "views/capsule/AllMyCapsuleWidgetView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import CollectionRepeatController from "controllers/common/CollectionRepeatController";
import CapsuleCardController from "controllers/capsule/CapsuleCardController";
import PlaylistHelper from "models/helper/PlaylistHelper";
import TrebbleAudio from "models/TrebbleAudio";
import FileUploadHelper from "models/helper/FileUploadHelper";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import ti18n from "i18n!nls/MyLibraryi18n";
import FacebookEventTrackingHelper from "models/helper/FacebookEventTrackingHelper";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const Rollout = RolloutHelper.getInstance();

const CACHE_MUSIC_BY_CATEGORIES =  true;

const AllMyCapsuleWidgetController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this._categoryIdToCategoryLabelMap = {}
        this._preselectedCategoryId = options.preselectedCategoryId;
        this._selectedCategoryId = null;

        this._heightMargin  = options.heightMargin? options.heightMargin: 0;
    },


    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },


    setViewLoading :function(isLoading){
        this.view.isLoading(isLoading);
    },

    

    _buildInfoBoxParamsForAllCapsules : function(){
        const params = {};
        params.iconClass = "ion-contrast";
        params.message = window.getI18n(ti18n,"NO_SHORTCAST");
        params.helpMessage = window.getI18n(ti18n,"CREATE_A_CAPSULE_BY_RECORDING_YOURSELF");
        return params;

    },

    
    _buildInfoBoxParamsForOnAirCapsules : function(){
        const params = {};
        params.iconClass = "ion-contrast";
        params.message = window.getI18n(ti18n,"NO_CAPSULE_ON_AIR");
        params.helpMessage = window.getI18n(ti18n,"CREATE_A_CAPSULE_BY_RECORDING_YOURSELF");
        return params;

    },

    _buildInfoBoxParamsForScheduledCapsules : function(){
        const params = {};
        params.iconClass = "ion-contrast";
        params.message = window.getI18n(ti18n,"NO_SCHEDULED_CAPSULE");
        params.helpMessage = window.getI18n(ti18n,"THIS_IS_WHERE_YOU_WILL_SEE_THE_LIST_OF_ALL_CAPSULES");
        return params;

    },

    _buildInfoBoxParamsForOffAirCapsules : function(){
        const params = {};
        params.iconClass = "ion-contrast";
        params.message = window.getI18n(ti18n,"NO_ARCHIVED_CAPSULE");
        params.helpMessage = window.getI18n(ti18n,"THIS_IS_WHERE_YOU_WILL_ARCHIVED_CAPSULES");
        return params;

    },

    _buildInfoBoxParamsForDraftCapsules : function(){
        const params = {};
        params.iconClass = "ion-contrast";
        params.message = window.getI18n(ti18n,"NO_DRAFT_CAPSULE");
        params.helpMessage = window.getI18n(ti18n,"THIS_IS_WHERE_YOU_WILL_SEE_DRAFT_CAPSULES");
        return params;

    },

    _buildInfoBoxParamsForReceivedCapsules : function(){
        const params = {};
        params.iconClass = "ion-contrast";
        params.message = window.getI18n(ti18n,"NO_RECEIVED_CAPSULE");
        params.helpMessage = window.getI18n(ti18n,"THIS_IS_WHERE_YOU_WILL_SEE_RECEIVED_CAPSULE");
        return params;

    },

    _buildCapsuleListController: function() {


        const controllerParams = {};
        controllerParams.showActiveFlagInView = true;
        controllerParams.context = this.context;
        controllerParams.onActionExecutedOnUserHandler =  null;//this._onActionExecutedByUserOnDisplayedCapsule.bind(this);
        const collectionRepeatControllerParams = {};
        collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildInfoBoxParamsForOnAirCapsules();
        collectionRepeatControllerParams.showEmtptyInfoBox = true;
        collectionRepeatControllerParams.controllerOption = controllerParams;
        collectionRepeatControllerParams.itemControllerConstructor = CapsuleCardController;
        collectionRepeatControllerParams.modelLoadHandler = //this._getMyScheduledCapsulesLoadHandler.bind(this);
        collectionRepeatControllerParams.resizePlaceHolderElem = true;
        collectionRepeatControllerParams.heightMargin = 52 + 39 + 50 + this._heightMargin; //Page Header + Close Button + tab section
        collectionRepeatControllerParams.context = this.context;

        collectionRepeatControllerParams.modelLoadMoreHandler = this._getCapsulesLoadHandlerWithPaging.bind(this);
        collectionRepeatControllerParams.loadOnScroll = true;
        collectionRepeatControllerParams.numberOfResultsPerPage = this._numberOfResultsPerPage? this._numberOfResultsPerPage: 21;

        this._capuleListLoaded = false;
        //collectionRepeatControllerParams.actionButtonHandlerFunction = this.createCapsuleNew.bind(this);
        this.capsuleListController = new CollectionRepeatController(collectionRepeatControllerParams);
        return this.capsuleListController;
    },

    setSearckKeyword : function(searchKeyword){
        this._searchKeyword = searchKeyword;
    },

    _getCapsulesLoadHandlerWithPaging : function(filter, offset, numberOfItemToLoad){
        if(Utils.getInstance().getCapsuleStatuses().ALL == this._selectedCategoryId){
            return this._getAllMyCapsulesLoadHandlerWithPaging(filter, offset, numberOfItemToLoad);
        }
        if(Utils.getInstance().getCapsuleStatuses().ON_AIR == this._selectedCategoryId){
            return this._getMyOnAirCapsulesLoadHandlerWithPaging(filter, offset, numberOfItemToLoad);
        }
        if(Utils.getInstance().getCapsuleStatuses().DRAFTS == this._selectedCategoryId){
            return this._getMyDraftCapsulesLoadHandlerWithPaging(filter, offset, numberOfItemToLoad);
        }
        if(Utils.getInstance().getCapsuleStatuses().SCHEDULED == this._selectedCategoryId){
            return this._getMyScheduledCapsulesLoadHandlerWithPaging(filter, offset, numberOfItemToLoad);
        }
        /*if(Utils.getInstance().getCapsuleStatuses().RECEIVED == this._selectedCategoryId){
            return this._getMyReceivedCapsulesLoadHandlerWithPaging(filter, offset, numberOfItemToLoad);
        }*/
        return this._getAllMyCapsulesLoadHandlerWithPaging(filter, offset, numberOfItemToLoad);
    },


    _getAllMyCapsulesLoadHandlerWithPaging:  function(filter, offset, numberOfItemToLoad){
        return TrebbleClientAPIHelper.getInstance().getAllMyCapsulesWithPaging(this._searchKeyword, offset, numberOfItemToLoad).then((function(results){
            const capsuleModelArray = [];
            if(results && results.length > 0){
                for(let i =0; i < results.length; i++){
                    capsuleModelArray.push(Utils.getInstance().getModelFromCapsuleJson(results[i]));
                }
            }
            return capsuleModelArray;
        }).bind(this))
    },


    _getMyOnAirCapsulesLoadHandlerWithPaging:  function(filter, offset, numberOfItemToLoad){
        return TrebbleClientAPIHelper.getInstance().getOnAirCapsulesWithPaging(this._searchKeyword, offset, numberOfItemToLoad).then((function(results){
            const capsuleModelArray = [];
            if(results && results.length > 0){
                for(let i =0; i < results.length; i++){
                    capsuleModelArray.push(Utils.getInstance().getModelFromCapsuleJson(results[i]));
                }
            }
            return capsuleModelArray;
        }).bind(this))
    },


    _getMyScheduledCapsulesLoadHandlerWithPaging:  function(filter, offset, numberOfItemToLoad){
        return TrebbleClientAPIHelper.getInstance().getScheduledCapsulesWithPaging(this._searchKeyword, offset, numberOfItemToLoad).then((function(results){
            const capsuleModelArray = [];
            if(results && results.length > 0){
                for(let i =0; i < results.length; i++){
                    capsuleModelArray.push(Utils.getInstance().getModelFromCapsuleJson(results[i]));
                }
            }
            return capsuleModelArray;
        }).bind(this))
    },


    _getMyReceivedCapsulesLoadHandlerWithPaging:  function(filter, offset, numberOfItemToLoad){
        return TrebbleClientAPIHelper.getInstance().getReceivedCapsulesWithPaging(this._searchKeyword, offset, numberOfItemToLoad).then((function(results){
            const capsuleModelArray = [];
            if(results && results.length > 0){
                for(let i =0; i < results.length; i++){
                    capsuleModelArray.push(Utils.getInstance().getModelFromCapsuleJson(results[i]));
                }
            }
            return capsuleModelArray;
        }).bind(this))
    },

    _getMyOffAirCapsulesLoadHandlerWithPaging:  function(filter, offset, numberOfItemToLoad){
        return TrebbleClientAPIHelper.getInstance().getOffAirCapsulesWithPaging(this._searchKeyword, offset, numberOfItemToLoad).then((function(results){
            const capsuleModelArray = [];
            if(results && results.length > 0){
                for(let i =0; i < results.length; i++){
                    capsuleModelArray.push(Utils.getInstance().getModelFromCapsuleJson(results[i]));
                }
            }
            return capsuleModelArray;
        }).bind(this))
    },


    _getMyDraftCapsulesLoadHandlerWithPaging:  function(filter, offset, numberOfItemToLoad){
        return TrebbleClientAPIHelper.getInstance().getDraftCapsulesWithPaging(this._searchKeyword, offset, numberOfItemToLoad).then((function(results){
            const capsuleModelArray = [];
            if(results && results.length > 0){
                for(let i =0; i < results.length; i++){
                    capsuleModelArray.push(Utils.getInstance().getModelFromCapsuleJson(results[i]));
                }
            }
            return capsuleModelArray;
        }).bind(this))
    },


    onTrebbleAudioSelected : function(trebbleAudioSelected){
        if(this._selectedTrebbleAudio){
            this._selectedTrebbleAudio.setSelected(false);
        }
        if(trebbleAudioSelected){
            trebbleAudioSelected.setSelected(true);
        }
        this._selectedTrebbleAudio = trebbleAudioSelected;
        if(this._onTrebbleAudioSelected){
            this._onTrebbleAudioSelected(this._selectedTrebbleAudio)
        }
    },

    onTrebbleAudioUnselected : function(trebbleAudioUnselected){
        trebbleAudioUnselected.setSelected(false);
        this._selectedTrebbleAudio = null;
        if(this._onTrebbleAudioUnselected){
            this._onTrebbleAudioUnselected(trebbleAudioUnselected)
        }
    },

    
    retrieveCapsuleCategories : function(){
        this._categoryIdToCategoryLabelMap = Utils.getInstance().getCapsuleStatusIdToLabel();
        return RSVP.Promise.resolve();
    },

    reload : function(force){
        return this.capsuleListController.reload(force);
    },

    retrieveAndDisplayCapsules : function(){
        this.setViewLoading(true);
        const retrieveCapsuleCategoriesPromise = this.retrieveCapsuleCategories();
        const promises = [];
        promises.push(retrieveCapsuleCategoriesPromise);
        return  RSVP.Promise.all(promises).then((function(){
            this.view.setCategoryIdToCategorylabelMapAvailable(this._categoryIdToCategoryLabelMap);
            if(this._preselectedCategoryId){
                this._selectedCategoryId = this._preselectedCategoryId;
                this.view.hideCategoryList();
            }else{
                this.view.showFirstCategory();
            }
            this.view._onCategorySelectionChangedOnSelectField();
            this.setViewLoading(false);
        }).bind(this)).catch((function(error){
            this.setViewLoading(false);
            throw error;
        }).bind(this));

    },

    _onCategorySelected : function(selectedCategoryId){
        this._selectedCategoryId = selectedCategoryId;
        if(this.capsuleListController){
            let customInfoParams = null;
            if(Utils.getInstance().getCapsuleStatuses().ALL == this._selectedCategoryId){
                customInfoParams =  this._buildInfoBoxParamsForAllCapsules();
            }
            if(Utils.getInstance().getCapsuleStatuses().ON_AIR == this._selectedCategoryId){
                customInfoParams =  this._buildInfoBoxParamsForOnAirCapsules();
            }
            if(Utils.getInstance().getCapsuleStatuses().DRAFTS == this._selectedCategoryId){
                customInfoParams =  this._buildInfoBoxParamsForDraftCapsules();
            }
            if(Utils.getInstance().getCapsuleStatuses().SCHEDULED == this._selectedCategoryId){
                customInfoParams =  this._buildInfoBoxParamsForScheduledCapsules();
            }
            /*if(Utils.getInstance().getCapsuleStatuses().RECEIVED == this._selectedCategoryId){
                customInfoParams =  this._buildInfoBoxParamsForReceivedCapsules();
            }*/
            if(customInfoParams){
                this.capsuleListController.getView().updateInfoBoxIfApplicable(customInfoParams.iconClass, customInfoParams.message, customInfoParams.helpMessage);
            }
            this.capsuleListController.reload(true);
        }
    },

    setScrollContainer : function(scrollContainer){
        this.capsuleListController.setScrollContainer(scrollContainer);
    },


    buildModelAndRenderView : function() {
        this._buildCapsuleListController();
        return this.capsuleListController.buildModelAndRenderView().then((function(){
            const params = {};
            params.capsuleListView = this.capsuleListController.getView()
            this.view = new ChooseCapsuleWidgetView(params);
            this.listenTo(this.view, "categorySelected",this._onCategorySelected);
            this.view.render();
            this.retrieveAndDisplayCapsules();
        }).bind(this))


    }

});

export default AllMyCapsuleWidgetController;