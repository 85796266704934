import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import RSVP from "rsvp";

const SongOptionMenuModel =  Backbone.Model.extend({
    songModel : null,

    constructor: function (attributes, options){
     Backbone.Model.prototype.constructor.call(this, attributes,options);
        if(this.get("songModel"))
        {

            this.songModel = this.get("songModel");			
        }
    },

        addToQueue : function(){
            return new RSVP.Promise(function(resolve, reject){
                resolve();
            });
        },

        playSimilar : function(){
            return new RSVP.Promise(function(resolve, reject){
                resolve();
            });
        },

        playSimilarFromFollowers : function(){
            return new RSVP.Promise(function(resolve, reject){
                resolve();
            });
        },

        addToPlaylist : function(){
                return new RSVP.Promise(function(resolve, reject){
                resolve();
            });
        },

        share : function(){
                return new RSVP.Promise(function(resolve, reject){
                resolve();
            });
        },


        goToAlbum : function(){
            return new RSVP.Promise(function(resolve, reject){
                resolve();
            });
        },

        goToArtist : function(){
                return new RSVP.Promise(function(resolve, reject){
                resolve();
            });
        },


        delete : function(){
                return new RSVP.Promise(function(resolve, reject){
                resolve();
            });
        },
     




});
export default SongOptionMenuModel;