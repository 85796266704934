import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import JourneyOptionView from "views/journey/JourneyOptionView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import FileUploadHelper from "models/helper/FileUploadHelper";
import Songs from "collections/Songs";
import YTSCRemoteSongCollection from "collections/YTSCRemoteSongCollection";
import SongSelectorListController from "controllers/common/SongSelectorListController";
import SongSelectorPopupController from "controllers/common/SongSelectorPopupController";
import ti18n from "i18n!nls/Journeyi18n";
import RSVP from "rsvp";
import swal from 'sweetalert';

Songs.prototype.model = PersistentModels.getInstance().getSongContructorFunctionForCollection();
const JourneyOptionController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.model = options? options.journeySummaryModel : null;
        this.context = options? options.context : null;
        this.elementSource$el = options? options.elementSource$el : null;
        this.onActionExecutedOnTrebbleHandler = options? options.onActionExecutedOnTrebbleHandler: null;
        _.extend(this, Backbone.Events);
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },


    showEditTrebbleDetails : function(){
        const handlers = {};
        handlers.onCoverPictureChangeTrigger = this.upadteCoverArt.bind(this);
        handlers.onSweeperChangeTrigger = this.changeSweeper.bind(this);
        handlers.onSweeperChangeTriggerConfirm = this.changeSweeperConfirm.bind(this);
        handlers.onDeleteSweeperTrigger = this.deleteCurrentSweeper.bind(this);
        handlers.onGreaterChangeTrigger = this.changeGreater.bind(this);
        handlers.onIntroChangeTrigger = this.changeIntro.bind(this);
        handlers.onOutroChangeTrigger = this.changeOutro.bind(this);
        handlers.onGreaterChangeTriggerConfirm = this.changeGreaterConfirm.bind(this);
        handlers.onIntroChangeTriggerConfirm = this.changeIntroConfirm.bind(this);
        handlers.onOutroChangeTriggerConfirm = this.changeOutroConfirm.bind(this);
        handlers.onDeleteGreaterTrigger = this.deleteCurrentGreater.bind(this);
        handlers.onDeleteIntroTrigger = this.deleteCurrentIntro.bind(this);
        handlers.onDeleteOutroTrigger = this.deleteCurrentOutro.bind(this);
        handlers.onActionExecutedOnTrebbleHandler = this.onActionExecutedOnTrebbleHandler.bind(this);
        PersistentModels.getInstance().getRouter().showJourneyInfoEditPage(this.model, true, handlers);
    },


    deleteCurrentSweeper : function(){
        const trebbleId = this.model.getLoaderKey();
        return TrebbleClientAPIHelper.getInstance().deleteCurrentJingleFromMyDefaultPersonalRadio(trebbleId).then((function(){
            this.executeActionCallback("unsetCurrentSweeper",{"trebbleId": trebbleId});
        }).bind(this)).catch(function(error){
            navigator.trebbleNotification.alert(error, null, false, "error");
        });

    },


    deleteCurrentGreater : function(){
        const trebbleId = this.model.getLoaderKey();
        return TrebbleClientAPIHelper.getInstance().deleteCurrentGreaterFromMyDefaultPersonalRadio(trebbleId).then((function(){
            this.executeActionCallback("unsetCurrentGreater",{"trebbleId": trebbleId});
        }).bind(this)).catch(function(error){
            navigator.trebbleNotification.alert(error, null, false, "error");
        });

    },

    deleteCurrentIntro : function(){
        const trebbleId = this.model.getLoaderKey();
        return TrebbleClientAPIHelper.getInstance().deleteCurrentIntroFromMyDefaultPersonalRadio(trebbleId).then((function(){
            this.executeActionCallback("unsetCurrentIntro",{"trebbleId": trebbleId});
        }).bind(this)).catch(function(error){
            navigator.trebbleNotification.alert(error, null, false, "error");
        });

    },

    deleteCurrentOutro : function(){
        const trebbleId = this.model.getLoaderKey();
        return TrebbleClientAPIHelper.getInstance().deleteCurrentOutroFromMyDefaultPersonalRadio(trebbleId).then((function(){
            this.executeActionCallback("unsetCurrentOutro",{"trebbleId": trebbleId});
        }).bind(this)).catch(function(error){
            navigator.trebbleNotification.alert(error, null, false, "error");
        });

    },



    deleteCurrentSweeperConfirm : function(){
        const buttonLabels = [window.getI18n(ti18n, "NO"), window.getI18n(ti18n, "YES")];
        const confirmCallback = (function(buttonIndex){
            if(buttonIndex ==  2){
                setTimeout((function(){
                    this.deleteCurrentSweeper();
                }).bind(this), 100);

            }
        }).bind(this);
        const message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_DELETE_SWEEPER");
        navigator.trebbleNotification.confirm(message , confirmCallback,window.getI18n(ti18n, "DELETE_SWEEPER"), buttonLabels);
    },

    deleteCurrentGreaterConfirm : function(){
        const buttonLabels = [window.getI18n(ti18n, "NO"), window.getI18n(ti18n, "YES")];
        const confirmCallback = (function(buttonIndex){
            if(buttonIndex ==  2){
                setTimeout((function(){
                    this.deleteCurrentGreater();
                }).bind(this), 100);

            }
        }).bind(this);
        const message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_DELETE_GREETER");
        navigator.trebbleNotification.confirm(message , confirmCallback,window.getI18n(ti18n, "DELETE_GREETER"), buttonLabels);
    },


    deleteCurrentIntroConfirm : function(){
        const buttonLabels = [window.getI18n(ti18n, "NO"), window.getI18n(ti18n, "YES")];
        const confirmCallback = (function(buttonIndex){
            if(buttonIndex ==  2){
                setTimeout((function(){
                    this.deleteCurrentIntro();
                }).bind(this), 100);

            }
        }).bind(this);
        const message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_DELETE_INTRO");
        navigator.trebbleNotification.confirm(message , confirmCallback,window.getI18n(ti18n, "DELETE_INTRO"), buttonLabels);
    },
    


    deleteCurrentOutroConfirm : function(){
        const buttonLabels = [window.getI18n(ti18n, "NO"), window.getI18n(ti18n, "YES")];
        const confirmCallback = (function(buttonIndex){
            if(buttonIndex ==  2){
                setTimeout((function(){
                    this.deleteCurrentOutro();
                }).bind(this), 100);

            }
        }).bind(this);
        const message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_DELETE_OUTRO");
        navigator.trebbleNotification.confirm(message , confirmCallback,window.getI18n(ti18n, "DELETE_OUTRO"), buttonLabels);
    },
    
    
    
    listenToPreview : function(){
        window.alertErrorMessage(window.getI18n(ti18n,"COMING_SOON"));
    },

    _onSongSelectedForSweeper : function(songSelected){
        if(songSelected){
            let message;
            const duration = songSelected.get("duration");
            this._uploadAudioDuration = duration;
            if(duration > 60000){
                window.alertErrorMessage(window.getI18n(ti18n, "YOUR_SWEEPER_HAS_TO_BE_LESS_THAN_X_SECONDS"));
            }else{
                const buttonLabels = [window.getI18n(ti18n, "NO"), window.getI18n(ti18n, "YES")];
                const confirmCallback = (function(buttonIndex){
                    if(buttonIndex ==  2){
                        this.setSongAsSweeper(songSelected);
                    }
                }).bind(this);
                const songTitleAndArtist = songSelected.get("title") + " - " +songSelected.get("artist");
                if(songTitleAndArtist){
                    message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_SET")+" "+ songTitleAndArtist + " "+ window.getI18n(ti18n, "AS_YOUR_TREBBLE_SWEEPER");
                }else{
                    message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_SET_AS_YOUR_TREBBLE_SWEEPER");
                }
                navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n, "CHANGE_SWEEPER"), buttonLabels);
            }
        }
    },

    _onSongSelectedForGreater : function(songSelected){
        if(songSelected){
            let message;
            const duration = songSelected.get("duration");
            this._uploadAudioDuration = duration;
            if(duration > 60000){
                window.alertErrorMessage(window.getI18n(ti18n, "YOUR_GREETER_HAS_TO_BE_LESS_THAN_X_SECONDS"));
            }else{
                const buttonLabels = [window.getI18n(ti18n, "NO"), window.getI18n(ti18n, "YES")];
                const confirmCallback = (function(buttonIndex){
                    if(buttonIndex ==  2){
                        this.setSongAsGreater(songSelected);
                    }
                }).bind(this);
                const songTitleAndArtist = songSelected.get("title") + " - " +songSelected.get("artist");
                if(songTitleAndArtist){
                    message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_SET")+" "+ songTitleAndArtist + " "+ window.getI18n(ti18n, "AS_YOUR_TREBBLE_GREETER");
                }else{
                    message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_SET_AS_YOUR_TREBBLE_GREETER");
                }
                navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n, "CHANGE_GREETER"), buttonLabels);
            }
        }
    },

    _onSongSelectedForIntro : function(songSelected){
        if(songSelected){
            let message;
            const duration = songSelected.get("duration");
            this._uploadAudioDuration = duration;
            if(duration > 60000){
                window.alertErrorMessage(window.getI18n(ti18n, "YOUR_INTRO_HAS_TO_BE_LESS_THAN_X_SECONDS"));
            }else{
                const buttonLabels = [window.getI18n(ti18n, "NO"), window.getI18n(ti18n, "YES")];
                const confirmCallback = (function(buttonIndex){
                    if(buttonIndex ==  2){
                        this.setSongAsIntro(songSelected);
                    }
                }).bind(this);
                const songTitleAndArtist = songSelected.get("title") + " - " +songSelected.get("artist");
                if(songTitleAndArtist){
                    message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_SET")+" "+ songTitleAndArtist + " "+ window.getI18n(ti18n, "AS_YOUR_AUDIO_INTRO");
                }else{
                    message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_SET_AS_YOUR_AUDIO_INTRO");
                }
                navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n, "CHANGE_INTRO"), buttonLabels);
            }
        }
    },


    _onSongSelectedForOutro : function(songSelected){
        if(songSelected){
            let message;
            const duration = songSelected.get("duration");
            this._uploadAudioDuration = duration;
            if(duration > 60000){
                window.alertErrorMessage(window.getI18n(ti18n, "YOUR_OUTRO_HAS_TO_BE_LESS_THAN_X_SECONDS"));
            }else{
                const buttonLabels = [window.getI18n(ti18n, "NO"), window.getI18n(ti18n, "YES")];
                const confirmCallback = (function(buttonIndex){
                    if(buttonIndex ==  2){
                        this.setSongAsOutro(songSelected);
                    }
                }).bind(this);
                const songTitleAndArtist = songSelected.get("title") + " - " +songSelected.get("artist");
                if(songTitleAndArtist){
                    message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_SET")+" "+ songTitleAndArtist + " "+ window.getI18n(ti18n, "AS_YOUR_AUDIO_OUTRO");
                }else{
                    message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_SET_AS_YOUR_AUDIO_OUTRO");
                }
                navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n, "CHANGE_OUTRO"), buttonLabels);
            }
        }
    },


    setSongAsSweeper : function(songModel){
        const trebbleId = this.model.getLoaderKey();
        $.mobile.loading('show');
        let sweeperUrl  = null;
        return FileUploadHelper.getInstance().uploadSongAsTrebbleSweeper(songModel, trebbleId).then((function(uploadedSweeperUrl){
            if(uploadedSweeperUrl){
                sweeperUrl = uploadedSweeperUrl;
                return TrebbleClientAPIHelper.getInstance().setJingleToMyDefaultPersonalRadio(uploadedSweeperUrl,trebbleId,this._uploadAudioDuration? this._uploadAudioDuration: 60);
            }
        }).bind(this)).then((function(jingleInfo){
            if(jingleInfo){
                this.executeActionCallback("setSweeper",{"jingleInfo": jingleInfo});
            }
            $.mobile.loading('hide');
        }).bind(this)).catch((function(error){
            $.mobile.loading('hide');
        }).bind(this));
    },

    setSongAsGreater : function(songModel){
        const trebbleId = this.model.getLoaderKey();
        $.mobile.loading('show');
        let greaterUrl  = null;
        return FileUploadHelper.getInstance().uploadSongAsTrebbleGreater(songModel, trebbleId).then((function(uploadedGreaterUrl){
            if(uploadedGreaterUrl){
                greaterUrl = uploadedGreaterUrl;
                return TrebbleClientAPIHelper.getInstance().setGreaterToMyDefaultPersonalRadio(uploadedGreaterUrl,trebbleId,this._uploadAudioDuration? this._uploadAudioDuration: 60);
            }
        }).bind(this)).then((function(greaterInfo){
            if(greaterInfo){
                this.executeActionCallback("setGreater",{"greaterInfo": greaterInfo});
            }
            $.mobile.loading('hide');
        }).bind(this)).catch((function(error){
            $.mobile.loading('hide');
        }).bind(this));
    },

    setSongAsIntro : function(songModel){
        const trebbleId = this.model.getLoaderKey();
        $.mobile.loading('show');
        let introUrl  = null;
        return FileUploadHelper.getInstance().uploadSongAsTrebbleIntro(songModel, trebbleId).then((function(uploadedIntroUrl){
            if(uploadedIntroUrl){
                introUrl = uploadedIntroUrl;
                return TrebbleClientAPIHelper.getInstance().setIntroToMyDefaultPersonalRadio(uploadedIntroUrl,trebbleId,this._uploadAudioDuration? this._uploadAudioDuration: 60);
            }
        }).bind(this)).then((function(introInfo){
            if(introInfo){
                this.executeActionCallback("setIntro",{"introInfo": introInfo});
            }
            $.mobile.loading('hide');
        }).bind(this)).catch((function(error){
            $.mobile.loading('hide');
        }).bind(this));
    },

    setSongAsOutro : function(songModel){
        const trebbleId = this.model.getLoaderKey();
        $.mobile.loading('show');
        let outroUrl  = null;
        return FileUploadHelper.getInstance().uploadSongAsTrebbleOutro(songModel, trebbleId).then((function(uploadedOutroUrl){
            if(uploadedOutroUrl){
                outroUrl = uploadedOutroUrl;
                return TrebbleClientAPIHelper.getInstance().setOutroToMyDefaultPersonalRadio(uploadedOutroUrl,trebbleId,this._uploadAudioDuration? this._uploadAudioDuration: 60);
            }
        }).bind(this)).then((function(outroInfo){
            if(outroInfo){
                this.executeActionCallback("setOutro",{"outroInfo": outroInfo});
            }
            $.mobile.loading('hide');
        }).bind(this)).catch((function(error){
            $.mobile.loading('hide');
        }).bind(this));
    },

    changeSweeper : function(){
        if(window.device && window.device.platform =="browser"){
            return this.chanegTrebbleSweeperFromWebBrowser();
        }else{
            const params = {};
            params.songCollection =  new Songs( PersistentModels.getInstance().getSongCollection().searchOnTitleAndArtist("", null,"ON_DEVICE"));//new YTSCRemoteSongCollection();
            //	params.songCollection.setSource(YTSCRemoteSongCollection.sourceTypes.YOUTUBE);
            params.selectedFilterOption = null;//Utils.getInstance().getFilterListItemModel("ON_DEVICE");
            params.singleSelect = false;
            params.title = window.getI18n(ti18n, "CHOOSE_YOUR_SWEEPER");
            params.infoBoxMessageOnFieldEmpty = window.getI18n(ti18n, "PICK_A_SONG_AS_SWEEPER");
            params.onPopupClose = this._onSongSelectedForSweeper.bind(this);
            const songSelectorPopupController = new SongSelectorPopupController(params);
            return songSelectorPopupController.buildModelAndRenderView();
        }
    },

    changeGreater : function(){
        if(window.device && window.device.platform =="browser"){
            return this.chanegTrebbleGreaterFromWebBrowser();
        }else{
            const params = {};
            params.songCollection =  new Songs( PersistentModels.getInstance().getSongCollection().searchOnTitleAndArtist("", null,"ON_DEVICE"));//new YTSCRemoteSongCollection();
            //	params.songCollection.setSource(YTSCRemoteSongCollection.sourceTypes.YOUTUBE);
            params.selectedFilterOption = null;//Utils.getInstance().getFilterListItemModel("ON_DEVICE");
            params.singleSelect = false;
            params.title = window.getI18n(ti18n, "CHOOSE_YOUR_GREETER");
            params.infoBoxMessageOnFieldEmpty = window.getI18n(ti18n, "PICK_A_SONG_AS_GREETER");
            params.onPopupClose = this._onSongSelectedForGreater.bind(this);
            const songSelectorPopupController = new SongSelectorPopupController(params);
            return songSelectorPopupController.buildModelAndRenderView();
        }
    },

    changeIntro : function(){
        if(window.device && window.device.platform =="browser"){
            return this.chanegTrebbleIntroFromWebBrowser();
        }else{
            const params = {};
            params.songCollection =  new Songs( PersistentModels.getInstance().getSongCollection().searchOnTitleAndArtist("", null,"ON_DEVICE"));//new YTSCRemoteSongCollection();
            //	params.songCollection.setSource(YTSCRemoteSongCollection.sourceTypes.YOUTUBE);
            params.selectedFilterOption = null;//Utils.getInstance().getFilterListItemModel("ON_DEVICE");
            params.singleSelect = false;
            params.title = window.getI18n(ti18n, "CHOOSE_YOUR_INTRO");
            params.infoBoxMessageOnFieldEmpty = window.getI18n(ti18n, "PICK_A_SONG_AS_INTRO");
            params.onPopupClose = this._onSongSelectedForIntro.bind(this);
            const songSelectorPopupController = new SongSelectorPopupController(params);
            return songSelectorPopupController.buildModelAndRenderView();
        }
    },

    changeOutro : function(){
        if(window.device && window.device.platform =="browser"){
            return this.chanegTrebbleOutroFromWebBrowser();
        }else{
            const params = {};
            params.songCollection =  new Songs( PersistentModels.getInstance().getSongCollection().searchOnTitleAndArtist("", null,"ON_DEVICE"));//new YTSCRemoteSongCollection();
            //	params.songCollection.setSource(YTSCRemoteSongCollection.sourceTypes.YOUTUBE);
            params.selectedFilterOption = null;//Utils.getInstance().getFilterListItemModel("ON_DEVICE");
            params.singleSelect = false;
            params.title = window.getI18n(ti18n, "CHOOSE_YOUR_OUTRO");
            params.infoBoxMessageOnFieldEmpty = window.getI18n(ti18n, "PICK_A_SONG_AS_OUTRO");
            params.onPopupClose = this._onSongSelectedForOutro.bind(this);
            const songSelectorPopupController = new SongSelectorPopupController(params);
            return songSelectorPopupController.buildModelAndRenderView();
        }
    },

    changeSweeperConfirm : function(){
        const buttonLabels = [window.getI18n(ti18n, "NO"), window.getI18n(ti18n, "YES")];
        const confirmCallback = (function(buttonIndex){
            if(buttonIndex ==  2){
                setTimeout((function(){
                    this.changeSweeper();
                }).bind(this), 100);

            }
        }).bind(this);
        const message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_CHANGE_SWEEPER");
        navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n, "CHANGE_SWEEPER"), buttonLabels);
    },

    changeGreaterConfirm : function(){
        const buttonLabels = [window.getI18n(ti18n, "NO"), window.getI18n(ti18n, "YES")];
        const confirmCallback = (function(buttonIndex){
            if(buttonIndex ==  2){
                setTimeout((function(){
                    this.changeGreater();
                }).bind(this), 100);

            }
        }).bind(this);
        const message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_CHANGE_GREETER");
        navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n, "CHANGE_GREETER"), buttonLabels);
    },


    changeIntroConfirm : function(){
        const buttonLabels = [window.getI18n(ti18n, "NO"), window.getI18n(ti18n, "YES")];
        const confirmCallback = (function(buttonIndex){
            if(buttonIndex ==  2){
                setTimeout((function(){
                    this.changeIntro();
                }).bind(this), 100);

            }
        }).bind(this);
        const message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_CHANGE_INTRO");
        navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n, "CHANGE_INTRO"), buttonLabels);
    },


    changeOutroConfirm : function(){
        const buttonLabels = [window.getI18n(ti18n, "NO"), window.getI18n(ti18n, "YES")];
        const confirmCallback = (function(buttonIndex){
            if(buttonIndex ==  2){
                setTimeout((function(){
                    this.changeOutro();
                }).bind(this), 100);

            }
        }).bind(this);
        const message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_CHANGE_OUTRO");
        navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n, "CHANGE_OUTRO"), buttonLabels);
    },

    chanegTrebbleSweeperFromWebBrowser : function(){
        const trebbleId = this.model.getLoaderKey();
        return this.uploadTrebbleSweeperFromWebBrowser().then((function(uploadedSweeperUrl){
            $.mobile.loading('show');
            if(uploadedSweeperUrl){
                const sweeperUrl = uploadedSweeperUrl;
                return TrebbleClientAPIHelper.getInstance().setJingleToMyDefaultPersonalRadio(uploadedSweeperUrl,trebbleId,0);
            }
        }).bind(this)).then((function(jingleInfo){
            if(jingleInfo){
                this.executeActionCallback("setSweeper",{"jingleInfo": jingleInfo});
            }
            $.mobile.loading('hide');
            navigator.trebbleNotification.alert(window.getI18n(ti18n, "YOUR_SWEEPER_WAS_CHANGED"), null, false, "success");
            return jingleInfo;
        }).bind(this)).catch((function(error){
            $.mobile.loading('hide');
            navigator.trebbleNotification.alert(error, null, false, "error");
        }).bind(this));
    },

    chanegTrebbleGreaterFromWebBrowser : function(){
        const trebbleId = this.model.getLoaderKey();
        return this.uploadTrebbleGreaterFromWebBrowser().then((function(uploadedGreaterUrl){
            $.mobile.loading('show');
            if(uploadedGreaterUrl){
                const greaterUrl = uploadedGreaterUrl;
                return TrebbleClientAPIHelper.getInstance().setGreaterToMyDefaultPersonalRadio(uploadedGreaterUrl,trebbleId,0);
            }
        }).bind(this)).then((function(greaterInfo){
            if(greaterInfo){
                this.executeActionCallback("setGreater",{"greaterInfo": greaterInfo});
            }
            $.mobile.loading('hide');
            navigator.trebbleNotification.alert(window.getI18n(ti18n, "YOUR_GREETER_WAS_CHANGED"), null, false, "success");
            return greaterInfo;
        }).bind(this)).catch((function(error){
            $.mobile.loading('hide');
            navigator.trebbleNotification.alert(error, null, false, "error");
        }).bind(this));
    },

    chanegTrebbleIntroFromWebBrowser : function(){
        const trebbleId = this.model.getLoaderKey();
        return this.uploadTrebbleIntroFromWebBrowser().then((function(uploadedIntroUrl){
            $.mobile.loading('show');
            if(uploadedIntroUrl){
                const introUrl = uploadedIntroUrl;
                return TrebbleClientAPIHelper.getInstance().setIntroToMyDefaultPersonalRadio(uploadedIntroUrl,trebbleId,0);
            }
        }).bind(this)).then((function(introInfo){
            if(introInfo){
                this.executeActionCallback("setIntro",{"introInfo": introInfo});
            }
            $.mobile.loading('hide');
            navigator.trebbleNotification.alert(window.getI18n(ti18n, "YOUR_INTRO_WAS_CHANGED"), null, false, "success");
            return introInfo;
        }).bind(this)).catch((function(error){
            $.mobile.loading('hide');
            navigator.trebbleNotification.alert(error, null, false, "error");
        }).bind(this));
    },

    chanegTrebbleOutroFromWebBrowser : function(){
        const trebbleId = this.model.getLoaderKey();
        return this.uploadTrebbleOutroFromWebBrowser().then((function(uploadedOutroUrl){
            $.mobile.loading('show');
            if(uploadedOutroUrl){
                const outroUrl = uploadedOutroUrl;
                return TrebbleClientAPIHelper.getInstance().setOutroToMyDefaultPersonalRadio(uploadedOutroUrl,trebbleId,0);
            }
        }).bind(this)).then((function(outroInfo){
            if(outroInfo){
                this.executeActionCallback("setOutro",{"outroInfo": outroInfo});
            }
            $.mobile.loading('hide');
            navigator.trebbleNotification.alert(window.getI18n(ti18n, "YOUR_OUTRO_WAS_CHANGED"), null, false, "success");
            return outroInfo;
        }).bind(this)).catch((function(error){
            $.mobile.loading('hide');
            navigator.trebbleNotification.alert(error, null, false, "error");
        }).bind(this));
    },

    uploadTrebbleSweeperFromWebBrowser : function(){
        const trebbleId = this.model.getLoaderKey();
        let fileSizeValid = false;
        return new  RSVP.Promise((function(resolve, reject){
            const uploadEementId = "uploadWrapper_"+((new Date()).getTime());
            let nameOfFileToUpload =  "trebbleSweeper_"+(new Date()).getTime()+".mp3";
            const uploadZoneHtml=  "<span class='uploadZonePlaceHolder' id='"+uploadEementId +"'><div class='pe-7s-upload uploadIcon' style='font-size:40px'></div><span  class='dz-message'>"+ window.getI18n(ti18n,"CLICK_HERE_OR_DRAG_TO_USE_FILE_AS_SWEEPER" )+"</span></span>";
            const buttonLabels = [window.getI18n(ti18n,"CANCEL"), window.getI18n(ti18n,"SAVE")];
            let dropZoneObj =  null;
            const confirmCallback = (function(buttonIndex){
                if(buttonIndex ==  2){
                    if(dropZoneObj){
                        if(dropZoneObj.getQueuedFiles().length == 0){
                            setTimeout(function(){swal.enableButtons()}, 300);
                        }else{
                            if(fileSizeValid){
                                const fname = dropZoneObj.getQueuedFiles()[0].name;
                                const extension = fname.substr(fname.lastIndexOf("."));
                                nameOfFileToUpload = "trebbleSweeper_"+(new Date()).getTime()+extension;
                                FileUploadHelper.getInstance().validateAndSignFunctionHanlder(dropZoneObj, nameOfFileToUpload, FileUploadHelper.getInstance().getAudioFileTypeValidationFunction(), "getSignatureForTrebbleSweeperUpload" ,trebbleId).then(function(){
                                    dropZoneObj.processQueue();
                                }).catch(function(error){
                                    navigator.trebbleNotification.alert(error, null, false, "error");
                                })
                            }else{
                                window.alertErrorMessage(window.getI18n(ti18n,"YOUR_SWEEPER_HAS_TO_BE_LESS_THAN_X_SECONDS"));
                            }

                        }
                    }
                }
            }).bind(this);
navigator.trebbleNotification.confirm(uploadZoneHtml , confirmCallback, window.getI18n(ti18n,"CHANGE_TREBBLE_SWEEPER"), buttonLabels, true, null, null, true);
const handlers = Utils.getInstance().getConfirmDialogDeferredHandlers(resolve, reject, this);
const onFileAccepted = (function(fileAdded, done){
if(fileAdded){
//if(fileAdded && dropZoneObj.files.length > 0){
    const file = dropZoneObj.files[0];
    const url = URL.createObjectURL(file);
    return Utils.getInstance().getAudioDuration(url).then((function(duration){
        this._uploadAudioDuration = duration;
        if(!duration || duration > 60){
            fileSizeValid = false;
            const validationError = window.getI18n(ti18n,"YOUR_SWEEPER_HAS_TO_BE_LESS_THAN_X_SECONDS");
            //window.alertErrorMessage(validationError);
            if(done){
                done(validationError);
            }
        }else{
            fileSizeValid = true;
            if(done){
                done();
            }
        }

    }).bind(this))
    //}
    //this.view.setUploadedFileAdded(true);
}

}).bind(this);
dropZoneObj =  FileUploadHelper.getInstance().uploadTrebbleSweeperInWebBrowser(nameOfFileToUpload,"#"+ uploadEementId,handlers.resolve,handlers.reject, onFileAccepted);

dropZoneObj.on("error", (function(file, error){
//Do no throw error on screen as the promise which was to make this call will throw an error as well. This handler should not be remove as the default handler will unhide error message as soon as it is displayed
setTimeout(function(){window.alertErrorMessage(error);}, 500);
}).bind(this));
/*dropZoneObj.on("addedfile", (function(fileAdded){
if(fileAdded){
if(fileAdded && dropZoneObj.files.length > 0){
    const file = dropZoneObj.files[0];
    const url = URL.createObjectURL(file);
    return Utils.getInstance().getAudioDuration(url).then((function(duration){
        this._uploadAudioDuration = duration;
        if(!duration || duration > 60){
            fileSizeValid = false;
            window.alertErrorMessage(window.getI18n(ti18n,"YOUR_SWEEPER_HAS_TO_BE_LESS_THAN_X_SECONDS"));
        }else{
            fileSizeValid = true;
        }

    }).bind(this))
}
//this.view.setUploadedFileAdded(true);
}

}).bind(this));*/
}).bind(this));
},

uploadTrebbleGreaterFromWebBrowser : function(){
const trebbleId = this.model.getLoaderKey();
let fileSizeValid = false;
return new  RSVP.Promise((function(resolve, reject){
const uploadEementId = "uploadWrapper_"+((new Date()).getTime());
let nameOfFileToUpload =  "trebbleGreater_"+(new Date()).getTime()+".mp3";
const uploadZoneHtml=  "<span class='uploadZonePlaceHolder' id='"+uploadEementId +"'><div class='pe-7s-upload uploadIcon' style='font-size:40px'></div><span  class='dz-message'>"+ window.getI18n(ti18n,"CLICK_HERE_OR_DRAG_TO_USE_FILE_AS_GREETER" )+"</span></span>";
const buttonLabels = [window.getI18n(ti18n,"CANCEL"), window.getI18n(ti18n,"SAVE")];
let dropZoneObj =  null;
const confirmCallback = (function(buttonIndex){
    if(buttonIndex ==  2){
        if(dropZoneObj){
            if(dropZoneObj.getQueuedFiles().length == 0){
                setTimeout(function(){swal.enableButtons()}, 300);
            }else{
                if(fileSizeValid){
                    const fname = dropZoneObj.getQueuedFiles()[0].name;
                    const extension = fname.substr(fname.lastIndexOf("."));
                    nameOfFileToUpload = "trebbleGreater_"+(new Date()).getTime()+extension;
                    FileUploadHelper.getInstance().validateAndSignFunctionHanlder(dropZoneObj, nameOfFileToUpload, FileUploadHelper.getInstance().getAudioFileTypeValidationFunction(), "getSignatureForTrebbleGreaterUpload" ,trebbleId).then(function(){
                        dropZoneObj.processQueue();
                    }).catch(function(error){
                        navigator.trebbleNotification.alert(error, null, false, "error");
                    })
                }else{
                    window.alertErrorMessage(window.getI18n(ti18n,"YOUR_GREETER_HAS_TO_BE_LESS_THAN_X_SECONDS"));
                }

            }
        }
    }
}).bind(this);
navigator.trebbleNotification.confirm(uploadZoneHtml , confirmCallback, window.getI18n(ti18n,"CHANGE_TREBBLE_GREETER"), buttonLabels, true, null, null, true);
const handlers = Utils.getInstance().getConfirmDialogDeferredHandlers(resolve, reject, this);
const onFileAccepted = (function(fileAdded, done){
    if(fileAdded){
        //if(fileAdded && dropZoneObj.files.length > 0){
            const file = dropZoneObj.files[0];
            const url = URL.createObjectURL(file);
            return Utils.getInstance().getAudioDuration(url).then((function(duration){
                this._uploadAudioDuration = duration;
                if(!duration || duration > 60){
                    fileSizeValid = false;
                    const validationError = window.getI18n(ti18n,"YOUR_GREETER_HAS_TO_BE_LESS_THAN_X_SECONDS");
                    //window.alertErrorMessage(validationError);
                    if(done){
                        done(validationError)
                    }
                }else{
                    fileSizeValid = true;
                    if(done){
                        done()
                    }
                }

            }).bind(this))
            //}
            //this.view.setUploadedFileAdded(true);
        }

    }).bind(this)
dropZoneObj =  FileUploadHelper.getInstance().uploadTrebbleGreaterInWebBrowser(nameOfFileToUpload,"#"+ uploadEementId,handlers.resolve,handlers.reject, onFileAccepted);

dropZoneObj.on("error", (function(file, error){
    //Do no throw error on screen as the promise which was to make this call will throw an error as well. This handler should not be remove as the default handler will unhide error message as soon as it is displayed
    setTimeout(function(){window.alertErrorMessage(error);}, 500);
}).bind(this));
/*dropZoneObj.on("addedfile", (function(fileAdded){
    if(fileAdded){
        if(fileAdded && dropZoneObj.files.length > 0){
            const file = dropZoneObj.files[0];
            const url = URL.createObjectURL(file);
            return Utils.getInstance().getAudioDuration(url).then((function(duration){
                this._uploadAudioDuration = duration;
                if(!duration || duration > 60){
                    fileSizeValid = false;
                    window.alertErrorMessage(window.getI18n(ti18n,"YOUR_GREETER_HAS_TO_BE_LESS_THAN_X_SECONDS"));
                }else{
                    fileSizeValid = true;
                }

            }).bind(this))
}
//this.view.setUploadedFileAdded(true);
}

}).bind(this));*/
}).bind(this));
},


uploadTrebbleIntroFromWebBrowser : function(){
const trebbleId = this.model.getLoaderKey();
let fileSizeValid = false;
return new  RSVP.Promise((function(resolve, reject){
const uploadEementId = "uploadWrapper_"+((new Date()).getTime());
let nameOfFileToUpload =  "trebbleIntro_"+(new Date()).getTime()+".mp3";
const uploadZoneHtml=  "<span class='uploadZonePlaceHolder' id='"+uploadEementId +"'><div class='pe-7s-upload uploadIcon' style='font-size:40px'></div><span  class='dz-message'>"+ window.getI18n(ti18n,"CLICK_HERE_OR_DRAG_TO_USE_FILE_AS_INTRO" )+"</span></span>";
const buttonLabels = [window.getI18n(ti18n,"CANCEL"), window.getI18n(ti18n,"SAVE")];
let dropZoneObj =  null;
const confirmCallback = (function(buttonIndex){
    if(buttonIndex ==  2){
        if(dropZoneObj){
            if(dropZoneObj.getQueuedFiles().length == 0){
                setTimeout(function(){swal.enableButtons()}, 300);
            }else{
                if(fileSizeValid){
                    const fname = dropZoneObj.getQueuedFiles()[0].name;
                    const extension = fname.substr(fname.lastIndexOf("."));
                    nameOfFileToUpload = "trebbleIntro_"+(new Date()).getTime()+extension;
                    FileUploadHelper.getInstance().validateAndSignFunctionHanlder(dropZoneObj, nameOfFileToUpload, FileUploadHelper.getInstance().getAudioFileTypeValidationFunction(), "getSignatureForTrebbleIntroUpload" ,trebbleId).then(function(){
                        dropZoneObj.processQueue();
                    }).catch(function(error){
                        navigator.trebbleNotification.alert(error, null, false, "error");
                    })
                }else{
                    window.alertErrorMessage(window.getI18n(ti18n,"YOUR_INTRO_HAS_TO_BE_LESS_THAN_X_SECONDS"));
                }

            }
        }
    }
}).bind(this);
navigator.trebbleNotification.confirm(uploadZoneHtml , confirmCallback,  window.getI18n(ti18n,"CHANGE_TREBBLE_AUDIO_OUTRO"), buttonLabels, true, null, null, true);
const handlers = Utils.getInstance().getConfirmDialogDeferredHandlers(resolve, reject, this);
const onFileAccepted = (function(fileAdded, done){
    if(fileAdded){
        //if(fileAdded && dropZoneObj.files.length > 0){
            const file = fileAdded;//dropZoneObj.files[0];
            const url = URL.createObjectURL(file);
            return Utils.getInstance().getAudioDuration(url).then((function(duration){
                this._uploadAudioDuration = duration;
                if(!duration || duration > 60){
                    fileSizeValid = false;
                    const validationError = window.getI18n(ti18n,"YOUR_INTRO_HAS_TO_BE_LESS_THAN_X_SECONDS");
                    //window.alertErrorMessage(validationError);
                    if(done){
                        done(validationError);
                    }
                }else{
                    fileSizeValid = true;
                    if(done){
                        done();
                    }
                }

            }).bind(this))
            //}
            //this.view.setUploadedFileAdded(true);
        }

    }).bind(this);
dropZoneObj =  FileUploadHelper.getInstance().uploadTrebbleIntroInWebBrowser(nameOfFileToUpload,"#"+ uploadEementId,handlers.resolve,handlers.reject, onFileAccepted);

dropZoneObj.on("error", (function(file, error){
    //Do no throw error on screen as the promise which was to make this call will throw an error as well. This handler should not be remove as the default handler will unhide error message as soon as it is displayed
    setTimeout(function(){window.alertErrorMessage(error);}, 500);
}).bind(this));
/*dropZoneObj.on("addedfile", (function(fileAdded){
    if(fileAdded){
        if(fileAdded && dropZoneObj.files.length > 0){
            const file = dropZoneObj.files[0];
            const url = URL.createObjectURL(file);
            return Utils.getInstance().getAudioDuration(url).then((function(duration){
                this._uploadAudioDuration = duration;
                if(!duration || duration > 60){
                    fileSizeValid = false;
                    window.alertErrorMessage(window.getI18n(ti18n,"YOUR_INTRO_HAS_TO_BE_LESS_THAN_X_SECONDS"));
                }else{
                    fileSizeValid = true;
                }

            }).bind(this))
}
//this.view.setUploadedFileAdded(true);
}

}).bind(this));*/
}).bind(this));
},


uploadTrebbleOutroFromWebBrowser : function(){
const trebbleId = this.model.getLoaderKey();
let fileSizeValid = false;
return new  RSVP.Promise((function(resolve, reject){
const uploadEementId = "uploadWrapper_"+((new Date()).getTime());
let nameOfFileToUpload =  "trebbleOutro_"+(new Date()).getTime()+".mp3";
const uploadZoneHtml=  "<span class='uploadZonePlaceHolder' id='"+uploadEementId +"'><div class='pe-7s-upload uploadIcon' style='font-size:40px'></div><span  class='dz-message'>"+ window.getI18n(ti18n,"CLICK_HERE_OR_DRAG_TO_USE_FILE_AS_OUTRO" )+"</span></span>";
const buttonLabels = [window.getI18n(ti18n,"CANCEL"), window.getI18n(ti18n,"SAVE")];
let dropZoneObj =  null;
const confirmCallback = (function(buttonIndex){
    if(buttonIndex ==  2){
        if(dropZoneObj){
            if(dropZoneObj.getQueuedFiles().length == 0){
                setTimeout(function(){swal.enableButtons()}, 300);
            }else{
                if(fileSizeValid){
                    const fname = dropZoneObj.getQueuedFiles()[0].name;
                    const extension = fname.substr(fname.lastIndexOf("."));
                    nameOfFileToUpload = "trebbleOutro_"+(new Date()).getTime()+extension;
                    FileUploadHelper.getInstance().validateAndSignFunctionHanlder(dropZoneObj, nameOfFileToUpload, FileUploadHelper.getInstance().getAudioFileTypeValidationFunction(), "getSignatureForTrebbleOutroUpload" ,trebbleId).then(function(){
                        dropZoneObj.processQueue();
                    }).catch(function(error){
                        navigator.trebbleNotification.alert(error, null, false, "error");
                    })
                }else{
                    window.alertErrorMessage(window.getI18n(ti18n,"YOUR_OUTRO_HAS_TO_BE_LESS_THAN_X_SECONDS"));
                }

            }
        }
    }
}).bind(this);
navigator.trebbleNotification.confirm(uploadZoneHtml , confirmCallback,  window.getI18n(ti18n,"CHANGE_TREBBLE_AUDIO_OUTRO"), buttonLabels, true, null, null, true);
const handlers = Utils.getInstance().getConfirmDialogDeferredHandlers(resolve, reject, this);
const onFileAccepted = (function(fileAdded, done){
    if(fileAdded){
        //if(fileAdded && dropZoneObj.files.length > 0){
            const file = fileAdded;//dropZoneObj.files[0];
            const url = URL.createObjectURL(file);
            return Utils.getInstance().getAudioDuration(url).then((function(duration){
                this._uploadAudioDuration = duration;
                if(!duration || duration > 60){
                    fileSizeValid = false;
                    const validationError = window.getI18n(ti18n,"YOUR_OUTRO_HAS_TO_BE_LESS_THAN_X_SECONDS");
                    //window.alertErrorMessage(validationError);
                    if(done){
                        done(validationError)
                    }
                }else{
                    fileSizeValid = true;
                    if(done){
                        done()
                    }
                }

            }).bind(this))
            //}
            //this.view.setUploadedFileAdded(true);
        }

    }).bind(this);
dropZoneObj =  FileUploadHelper.getInstance().uploadTrebbleOutroInWebBrowser(nameOfFileToUpload,"#"+ uploadEementId,handlers.resolve,handlers.reject, onFileAccepted);

dropZoneObj.on("error", (function(file, error){
    //Do no throw error on screen as the promise which was to make this call will throw an error as well. This handler should not be remove as the default handler will unhide error message as soon as it is displayed
    setTimeout(function(){window.alertErrorMessage(error);}, 500);
}).bind(this));
/*dropZoneObj.on("addedfile", (function(fileAdded){
    if(fileAdded){
        if(fileAdded && dropZoneObj.files.length > 0){
            const file = dropZoneObj.files[0];
            const url = URL.createObjectURL(file);
            return Utils.getInstance().getAudioDuration(url).then((function(duration){
                this._uploadAudioDuration = duration;
                if(!duration || duration > 60){
                    fileSizeValid = false;
                    window.alertErrorMessage(window.getI18n(ti18n,"YOUR_OUTRO_HAS_TO_BE_LESS_THAN_X_SECONDS"));
                }else{
                    fileSizeValid = true;
                }

            }).bind(this))
}
//this.view.setUploadedFileAdded(true);
}

}).bind(this));*/
}).bind(this));
},

editArtistSummary : function(){
window.alertErrorMessage(window.getI18n(ti18n,"COMING_SOON"));
},


changeSongsSelectionInPreview : function(){
window.alertErrorMessage(window.getI18n(ti18n,"COMING_SOON"));
},

deleteTrebble : function(){
window.alertErrorMessage(window.getI18n(ti18n,"COMING_SOON"));
},

viewStatistics : function(){
return PersistentModels.getInstance().getRouter().showJourneyStatsPageByRadioId(this.model.getLoaderKey(), true);
},

previewJourney : function(){
return PersistentModels.getInstance().getRouter().showPreviewPublicJourneyDetailPage(this.model, true);
},

share : function(){
return PersistentModels.getInstance().getRouter().showShareJourneyPopup(this.model.getLoaderKey());
},

follow : function(){
if(this.model && this.model.canBeFollowed()){
$.mobile.loading( 'show');
return PersistentModels.getInstance().subscribe(this.model).then((function(){
    $.mobile.loading( 'hide');
}).bind(this)).catch((function(error){
    window.alertErrorMessage(error);
}).bind(this));
}else{
return RSVP.Promise.resolve();
}
},

unfollow : function(){
if(this.model){
$.mobile.loading( 'show');
return PersistentModels.getInstance().unsubscribe(this.model).then((function(){
    $.mobile.loading( 'hide');
}).bind(this)).catch((function(error){
    window.alertErrorMessage(error);
}).bind(this));
}else{
return RSVP.Promise.resolve();
}
},

executeActionCallback :function(action, data){
if(this.onActionExecutedOnTrebbleHandler){
this.onActionExecutedOnTrebbleHandler(this.model,action, data);
}
},

upadteCoverArt : function(){
const trebbleId = this.model.getLoaderKey();
let coverArtUrl = null;
let uploadPromise;
if(window.device && window.device.platform =="browser"){
uploadPromise =  this.uploadTrebbleCoverArtFromWebBrowser();
}else{
uploadPromise =  this.uploadTrebbleCoverArtFromMobile();
}
return uploadPromise.then((function(uploadedImageFileUrl){
$.mobile.loading('show');
if(uploadedImageFileUrl){
    coverArtUrl = uploadedImageFileUrl;
    return TrebbleClientAPIHelper.getInstance().updateRadioCoverArtByRadioId(uploadedImageFileUrl,trebbleId).then((function(coverArtUrlBlur){
        return Utils.getInstance().preloadImageUsingUrl(coverArtUrlBlur);
    }).bind(this));
}
}).bind(this)).then((function(coverArtUrlBlur){
if(coverArtUrl){
    this.model.setCoverArtUrl(coverArtUrl);
    this.model.setCoverArtUrlBlur(coverArtUrlBlur);
    this.executeActionCallback("updateCoverArt",{"coverArtUrl": coverArtUrl, "coverArtUrlBlur": coverArtUrlBlur});
}
$.mobile.loading('hide');
navigator.trebbleNotification.alert(window.getI18n(ti18n,"YOUR_COVER_IMAGE_WAS_CHANGED"), null, false, "success");
return coverArtUrl;
}).bind(this)).catch((function(error){
$.mobile.loading('hide');
navigator.trebbleNotification.alert(error, null, false, "error");
}).bind(this));
},

uploadTrebbleCoverArtFromWebBrowser : function(){
const trebbleId = this.model.getLoaderKey();
return new  RSVP.Promise((function(resolve, reject){
const uploadEementId = "uploadWrapper_"+((new Date()).getTime());
const nameOfFileToUpload =  "trebbleCoverArt_"+(new Date()).getTime()+".jpg";
const uploadZoneHtml=  "<span class='uploadZonePlaceHolder' id='"+uploadEementId +"'><div class='pe-7s-upload uploadIcon ' style='font-size:40px'></div><span  class='dz-message'>"+window.getI18n(ti18n,"CLICK_HERE_OR_DRAG_TO_USE_FILE_AS_COVER_IMAGE")+"</span></span>";
const buttonLabels = [window.getI18n(ti18n,"CANCEL"), window.getI18n(ti18n,"SAVE")];
let dropZoneObj =  null;
const confirmCallback = (function(buttonIndex){
    if(buttonIndex ==  2){
        if(dropZoneObj){
            if(dropZoneObj.getQueuedFiles().length == 0){
                setTimeout(function(){swal.enableButtons()}, 300);
            }else{
                FileUploadHelper.getInstance().validateAndSignFunctionHanlder(dropZoneObj, nameOfFileToUpload, FileUploadHelper.getInstance().getImageFileTypeValidationFunction(), "getSignatureForTrebbleCoverArtUpload" ,trebbleId).then(function(){
                    dropZoneObj.processQueue();
                }).catch(function(error){
                    navigator.trebbleNotification.alert(error, null, false, "error");
                })

            }
        }
    }
}).bind(this);
navigator.trebbleNotification.confirm(uploadZoneHtml , confirmCallback, window.getI18n(ti18n,"CHANGE_TREBBLE_IMAGE"), buttonLabels, true, null, null,true);
const handlers = Utils.getInstance().getConfirmDialogDeferredHandlers(resolve, reject, this);
dropZoneObj =  FileUploadHelper.getInstance().uploadTrebbleCoverImageInWebBrowser(nameOfFileToUpload,"#"+ uploadEementId,handlers.resolve,handlers.reject);
}).bind(this));
},

uploadTrebbleCoverArtFromMobile : function(){
const trebbleId = this._playlist.getLoaderKey();
return Utils.getInstance().getUserToPickAPicture().then((function(imageLocationUrl){
$.mobile.loading('show');
return FileUploadHelper.getInstance().uploadCoverArtForTrebble(imageLocationUrl, trebbleId);
}).bind(this))
},


allAllFromLibrary: function(songsToAdd){
$.mobile.loading("show");
return PersistentModels.getInstance().addSongsFromLibraryToTrebble(songsToAdd).then((function(){
$.mobile.loading("hide");
}).bind(this)).catch((function(error){
window.alertErrorMessage(error);
$.mobile.loading("hide");
}).bind(this));
},

archiveJourneyConfirm : function(){
const buttonLabels = [window.getI18n(ti18n, "NO"), window.getI18n(ti18n, "YES")];
const confirmCallback = (function(buttonIndex){
if(buttonIndex ==  2){
    setTimeout((function(){
        this.archiveJourney();
    }).bind(this), 100);

}
}).bind(this);
const message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_ARCHIVE_JOURNEY");
navigator.trebbleNotification.confirm(message , confirmCallback,window.getI18n(ti18n, "ARCHIVE"), buttonLabels);
},

archiveJourney : function(){
$.mobile.loading("show");
return PersistentModels.getInstance().archivePublishedJourney(this.model.getLoaderKey()).then((function(){
$.mobile.loading("hide");
}).bind(this)).catch((function(error){
window.alertErrorMessage(error);
$.mobile.loading("hide");
}).bind(this));
},

revertToDraftConfirm : function(){
const buttonLabels = [window.getI18n(ti18n, "NO"),window.getI18n(ti18n, "YES")];
const confirmCallback = (function(buttonIndex){
if(buttonIndex ==  2){
    setTimeout((function(){
        this.revertToDraftButton();
    }).bind(this), 100);

}
}).bind(this);
const message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_REVERT_JOURNEY_DRAFT");
navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n, "REVERT_TO_DRAFT"), buttonLabels);

},

revertToDraftButton : function(){
return PersistentModels.getInstance().revertToDraft(this.model.getLoaderKey());
},

deleteJourney: function(){
return PersistentModels.getInstance().deleteJourney(this.model.getLoaderKey()).then((function(){
window.alertSuccessMessage(window.getI18n(ti18n, "JOURNEY_WAS_SUCCESSFULLY_DELETED"), "", function(){});
}).bind(this));
},

deleteJourneyConfirm : function(){
const buttonLabels = [window.getI18n(ti18n, "NO"),window.getI18n(ti18n, "YES")];
const confirmCallback = (function(buttonIndex){
if(buttonIndex ==  2){
    setTimeout((function(){
        this.deleteJourney();
    }).bind(this), 100);

}
}).bind(this);
const message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_DELETE_JOURNEY");
navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n, "DELETE_JOURNEY"), buttonLabels);
},

buildModelAndRenderView : function() {
return new RSVP.Promise((function(resolve, reject) {
try {

    this.view = new JourneyOptionView({
        "elementSource$el": this.elementSource$el,
        "model" : this.model
    });
    this.listenTo(this.view, "showEditTrebbleDetails",this.showEditTrebbleDetails);
    this.listenTo(this.view, "listenToPreview",this.listenToPreview);
    this.listenTo(this.view, "changeSweeper",this.changeSweeper);
    this.listenTo(this.view, "changeGreater",this.changeGreater);
    this.listenTo(this.view, "changeIntro",this.changeIntro);
    this.listenTo(this.view, "changeOutro",this.changeOutro);
    this.listenTo(this.view, "editArtistSummary",this.editArtistSummary);
    this.listenTo(this.view, "changeSongsSelectionInPreview",this.changeSongsSelectionInPreview);
    this.listenTo(this.view, "deleteTrebble",this.deleteTrebble);
    this.listenTo(this.view, "viewStatistics",this.viewStatistics);
    this.listenTo(this.view, "previewJourney",this.previewJourney);
    this.listenTo(this.view, "share",this.share);
    this.listenTo(this.view, "follow",this.follow);
    this.listenTo(this.view, "unfollow",this.unfollow);
    this.listenTo(this.view, "upadteCoverArt",this.upadteCoverArt);
    this.listenTo(this.view, "archiveJourney",this.archiveJourneyConfirm);
    this.listenTo(this.view, "revertToDraft",this.revertToDraftConfirm);
    this.listenTo(this.view, "deleteJourney",this.deleteJourneyConfirm);
    
    
    //this.listenTo(this.view, "allAllFromLibrary", this.allAllFromLibraryConfirm);
    this.view.render();
    resolve();
} catch (error) {
    reject(error);
}

}).bind(this));

}

});

export default JourneyOptionController;