import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import PersistentModels from "services/PersistentModels";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import SequencerWordNodeView from "views/audioEditor/sequencerNodeView/SequencerWordNodeView";
import SequencerPauseNodeView from "views/audioEditor/sequencerNodeView/SequencerPauseNodeView";
import SequencerDeletedNodeView from "views/audioEditor/sequencerNodeView/SequencerDeletedNodeView";
//import SequencerMusicWrapperNodeView from "views/audioEditor/sequencerNodeView/SequencerMusicWrapperNodeView";
import SequencerPunctuationNodeView from "views/audioEditor/sequencerNodeView/SequencerPunctuationNodeView";
import SequencerUnsupportedNodeView from "views/audioEditor/sequencerNodeView/SequencerUnsupportedNodeView";
import KeyboardKeyHandlerHelper from "models/helper/KeyboardKeyHandlerHelper";
import SequencerStartMusicWrapNodeView from "views/audioEditor/sequencerNodeView/SequencerStartMusicWrapNodeView";
import SequencerEndMusicWrapdNodeView from "views/audioEditor/sequencerNodeView/SequencerEndMusicWrapdNodeView";
import SequencerAudioNodeView from "views/audioEditor/sequencerNodeView/SequencerAudioNodeView";
import SequencerVideoNodeView from "views/audioEditor/sequencerNodeView/SequencerVideoNodeView";
//import SequencerNodeInspectorSectionView from "views/audioEditor/sequencerNodeInspectorSection/SequencerNodeInspectorSectionView";
import Sortable from "libs/sortablejs/Sortable";
import VideoContext from "libs/videoContext/videoContext";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import SequencerTemplate from 'text!../../../templates/audioEditor/SequencerTemplate.html';
import SequencerNodeContextMenuTemplate from "text!../../../templates/audioEditor/SequencerNodeContextMenuTemplate.html";
import MusicWrapperSequencerNodeContextMenuTemplate from "text!../../../templates/audioEditor/MusicWrapperSequencerNodeContextMenuTemplate.html";
import AudioSequencerNodeContextMenuTemplate from "text!../../../templates/audioEditor/AudioSequencerNodeContextMenuTemplate.html";
import SequencerDeletedNodeContextMenuTemplate from "text!../../../templates/audioEditor/SequencerDeletedNodeContextMenuTemplate.html";
import VideoSequencerNodeContextMenuTemplate from "text!../../../templates/audioEditor/VideoSequencerNodeContextMenuTemplate.html";
import CorrectTranscriptionContextMenuTemplate from "text!../../../templates/audioEditor/CorrectTranscriptionContextMenuTemplate.html";
import SequencerContextMenuDefaultTemplate from "text!../../../templates/audioEditor/SequencerContextMenuDefaultTemplate.html";
import TippyTooltipTemplate from "text!../../../templates/common/TippyTooltipTemplate.html";
import SequencerNodeUtils from 'models/audioEditor/SequencerNodeUtils';
import Mousetrap from 'mousetrap';
//import TippyJS from "tippyJS";
//import TippyJS from 'tippy.js';
import TippyJS, {createSingleton} from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import noUiSlider from "nouislider";
import ti18n from 'i18n!nls/Sequenceri18n';
import RSVP from 'rsvp';
import Shepherd from 'shepherd.js';
import SpeakerParagraphContainerView from 'views/audioEditor/sequencerNodeView/SpeakerParagraphContainerView';
import UnknownSpeakerInfo from "models/audioEditor/UnknownSpeakerInfo";
import UnlabelledParagraphContainerView from 'views/audioEditor/sequencerNodeView/UnlabeledParagraphContainerView';
import EditorSearchPanel from "components/EditorSearchPanel";
import { createRoot } from "react-dom/client";
import React from "react";

const Rollout = RolloutHelper.getInstance();

//const TIME_IN_MILLISECONDS_TO_ALLOW_MOVING_NODES = 500;
const MARGIN_TOP_WHEN_AUTO_SCROLLING = 30 * 2; // 2 lines
const DISPLAY_WARNING_ABOUT_NOISE_CANCELLATION_DURATION = Rollout.getFeatureVariable(Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR, Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR.variables.display_noise_cancellation_warning, true);
const ENABLE_NOISE_CANCELLATION_FEATURE =  Rollout.getFeatureVariable(Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR, Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR.variables.enable_noise_cancellation_feature, true);
const ALLOW_USER_TO_CLIP_SELECTION = Rollout.isFeatureEnabled(Rollout.FEATURES.ALLOW_USER_TO_CLIP_SELECTION_IN_AUDIO_EDITOR,true);
const BREAK_DOWN_TEXT_IN_AUDIO_EDITOR_AUTOMATICALLY = Rollout.isFeatureEnabled(Rollout.FEATURES.BREAK_DOWN_TEXT_IN_AUDIO_EDITOR_AUTOMATICALLY, true);
const CUT_COPY_PASTE_FEATURE_AVAILABLE = Rollout.isFeatureEnabled(Rollout.FEATURES.CUT_COPY_PASTE_IN_AUDIO_EDITOR, true);
const ALLOW_TEXT_SEARCH_IN_AUDIO_EDITOR = Rollout.isFeatureEnabled(Rollout.FEATURES.ALLOW_TEXT_SEARCH_IN_AUDIO_EDITOR, true);
const EDIT_VIDEO_FILES = Rollout.isFeatureEnabled(Rollout.FEATURES.EDIT_VIDEO_FILES, true);

const ALLOW_PLAYBACK_OF_SEARCH_RESULTS = Rollout.getFeatureVariable(Rollout.FEATURES.ALLOW_TEXT_SEARCH_IN_AUDIO_EDITOR, Rollout.FEATURES.ALLOW_TEXT_SEARCH_IN_AUDIO_EDITOR.variables.allow_playback_of_search_result, true);
const CONFIGURE_SOUND_ENHANCING = Rollout.isFeatureEnabled(Rollout.FEATURES.CONFIGURE_SOUND_ENHANCING, false);
const USE_SCRIPT_PROCESSOR_IN_SAFARI = Rollout.getFeatureVariable(Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR, Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR.variables.apply_safari_workaround_for_audioworklet_incomplete_impl, true);

const TURN_ON_HACK_IN_AUDIO_EDITOR_TO_PREVENT_STALLED_PLAYBACK = Rollout.isFeatureEnabled(Rollout.FEATURES.TURN_ON_HACK_IN_AUDIO_EDITOR_TO_PREVENT_STALLED_PLAYBACK);
const TIME_IN_MILLISEC_TO_TRIGGER_RESTART_AFTER_STALLED = Rollout.getFeatureVariable(Rollout.FEATURES.TURN_ON_HACK_IN_AUDIO_EDITOR_TO_PREVENT_STALLED_PLAYBACK, Rollout.FEATURES.TURN_ON_HACK_IN_AUDIO_EDITOR_TO_PREVENT_STALLED_PLAYBACK.variables.time_in_milliseconds_to_trigger_restart_after_stalled, 1000);
const SHOW_SEARCH_WIDGET_ON_LEFT_SECTION_OF_EDITOR = Rollout.isFeatureEnabled(Rollout.FEATURES.SHOW_SEARCH_WIDGET_ON_LEFT_SECTION_OF_EDITOR, true);
const DO_NOT_USE_TIPPYJS_MENU = false;
const DELAY_FOR_EXECUTING_SEARCH_QUERY_IN_MILLISECONDS =  500;
const LINE_HEIGHT = 20;
const USE_PARAGRAPH_SUMMARY_TO_INCREASE_RENDERING_SPEED = false; 

const DESTROY_CONTEXT_MENU_ON_SCROLL = true;
const HIDE_PAUSE_UNDER_10_MILLISECONDS = true;


const SequencerView = Backbone.View.extend({



    initialize : function(options) {
        this.compiledTemlate =  _.template(SequencerTemplate);
        this.compiledContextMenuTemplate =  _.template(SequencerNodeContextMenuTemplate);
        this.compiledMusicWrapperContextMenuTemplate =  _.template(MusicWrapperSequencerNodeContextMenuTemplate);
        this.compiledAudioSequencerNodeContextMenuTemplate =  _.template(AudioSequencerNodeContextMenuTemplate);
        this.compiledSequencerDeletedNodeContextMenuTemplate =  _.template(SequencerDeletedNodeContextMenuTemplate);
        this.compiledVideoSequencerNodeContextMenuTemplate =  _.template(VideoSequencerNodeContextMenuTemplate);
        this.compiledCorrectTranscriptContextMenuTemplate = _.template(CorrectTranscriptionContextMenuTemplate);
        this.compliledContextMenuDefaultTemplate = _.template(SequencerContextMenuDefaultTemplate);
        this.compiledTippyTooltipTemplate =  _.template(TippyTooltipTemplate);
        this.model = options? options.model : null;
        this._sequencerController = options? options.sequencerController : null;
        this._sequencerNodeCidToSequencerNodeViewMap = {};
        this._sequencerNodeElArray = [];
        this._arrayOfSelectedSequencerNodeModels = [];
        this._arrayOfSequencerNodeViewCidBeingListenedTo = [];
        this._arrayOfParagraphContainerViewCidBeingListenedTo = [];
        this._arrayOfParagraphContainerViews = [];
        this._currentlyDisplayedTippyInstance = null;
        this._currentDisplayedCorrectTranscriptsContextMenuInstance = null;
        this._paragraphViewCidToParagraphView = {};
        this._selectedNodeInfo = null;
        this._sortableInstance = null;
        this._sequencerNodeViewCurrentlyHighlightedAsPlaying = null;
        this._isUserManuallyScrolling = false;
        this._warningDisplayedAboutNoiseCancellationDuration = false;
        this._warningDisplayedAboutMagicSoundEnhancerDuration = false;
        this._beautifyMenuDisplayed = false;
        this._keyboardEventsSetup = false;
        this._paragraphObserver = this._initializeParagraphObserver();
        PersistentModels.getInstance().getRouter().registerForViewportHeightChange(this.cid,this.onOrientationChangeFunction.bind(this));
        
        if(this.model){
            this.listenTo(this.model, "add", this._onSequencerNodeAddedToSequencer);
            this.listenTo(this.model, "remove", this._onSequencerNodeRemovedToSequencer);
            this.listenTo(this.model, "reset", this._onSequencerResetted);
            this.listenTo(this.model, "sequencerRenderingStarted", this.onAudioRenderingStarted);
            this.listenTo(this.model, "sequencerRenderingCompleted", this.onAudioRenderingCompleted);
            this.listenTo(this.model, "sequencerRenderingFailed", this.onAudioRenderingFailed);
            this.listenTo(this.model, "change:renderedBufferPlaying", this.onRenderedBufferPlayingChange);
            this.listenTo(this.model, "change:renderingBeingCreated", this.onRenderingBeingCreatedChange);
            this.listenTo(this.model, "change:renderedBufferPlaybackLoading", this.onRenderedBufferPlaybackLoadingChange);
            this.listenTo(this.model, "change:autoscroll", this.onAutoscrollChange);
            this.listenTo(this.model, "change:hasVideoSegment", this.onHasVideoSegmentChanged)
            this.listenTo(this.model.getSequencerSettings(), "change:noiseCancellationOn", this.onNoiseCancellationOnChange);
            this.listenTo(this.model.getSequencerSettings(), "change:showIgnoredContent", this.onShowIgnoredContentSettingChange);
            this.listenTo(this.model.getSequencerSettings(), "change:revealDeletedContent", this.onRevealDeletedContentSettingChange);
            this.listenTo(this.model.getSequencerSettings(), "change:showGapsBetweenWords", this.onShowGapNodesSettingChange);
            this.listenTo(this.model.getSequencerSettings(), "change:showEditBoundaries", this.onShowEditBoundariesSettingChange);
            this.listenTo(this.model.getSequencerSettings(), "change:showDarkTheme", this.onShowDarkThemeChange);
            this.listenTo(this.model.getSequencerSettings(), "change:autoscroll", this.onAutoscrollSettingChange);
            
            this.listenTo(this.model, "change:numberOfRenderingRequestPending", this.onNumberOfRenderRequestPendingChange);
            this.listenTo(this.model, "historyUpdated", this.onHistoryUpdated);
            this.listenTo(this.model.getSearchWidget(), "change:nodesInFocus", this.onNodeSetInSearchFocusChange);
            this.listenTo(this.model.getSearchWidget(), "change:searchOn", this.onSearchModeChange);
            this.listenTo(this.model.getSearchWidget(), "change:playbackOn", this.onPlaybackOnChange);
            this.listenTo(this.model.getSearchWidget(), "change:searchType", this.onSearchTypeChange)

            
            
            
            this.listenTo(this.model, "deleteOperationInteruptedBecauseOfWrapNodeOrphan", this.onDeletionNodeInterruptedBecauseOfWrapOrphanNode);
            this.listenTo(this.model, "selectedNodeNeedToBeNextToEachotherError", this.onSelectedNodeNeedToBeNextToEachotherError);
        }
        this._listenForKeyboardEvents();
    },


    
    onOrientationChangeFunction  : function(newViewPortHeigh, forceResizing) {
        if(this.$el.is(":visible") || forceResizing){
            this.$el.css("height", (this._heightMarginInPx? (newViewPortHeigh - this._heightMarginInPx):newViewPortHeigh)  + "px");
        }
    },
    
    events : {
        "click #ignoreSelectedNodesBtn:not(.disabled)": "onIgnoreSelectedNodesBtnClicked",
        "click #wrapWithMusicBtn:not(.disabled)": "onWrapWithMusicBtnClicked",
        "click #playFromBtn:not(.disabled)": "onPlayFromBtnClicked",
        "click #transcribedSequencerNodeBtn:not(.disabled)":"onTranscribeSequencerNodeBtnClicked",
        "click #viewSettingsForMusicWrapperBtn:not(.disabled)":"onViewSettingForMusicWrapperBtnClicked",
        "click #deleteMusicWrapperBtn:not(.disabled)":"onDeleteMusicWrapperBtn",
        "click #playSequencerBtn:not(.disabled)" : "onPlaySequencerBtnClicked",
        "click #stopSequencerBtn:not(.disabled)" : "onPlaySequencerBtnClicked",
        "click #autoScrollBtn:not(.disabled)" : "onAutoscrollButtonClicked",
        "click #noiseCancellationBtn:not(.disabled)" : "onNoiseCancellationButtonClicked",
        "click #showHelpBtn:not(.disabled)" : "onShowHelpBtnClicked",
        "click #turnSearchOnBtn:not(.disabled)": "onSearchTurnedOn",
        "click #showBeatifyMenuBtn:not(.disabled)":"onSoundEnhancerBtnClicked",
        "click #showAutomatedOperationsMenuBtn:not(.disabled)":"onShowAutomatedOperationsMenuBtnClicked",
        "click #insertBtn:not(.disabled)":"onInsertButtonClicked",
        "click #insertElementBtn:not(.disabled)":"onInsertElementButtonClicked",
        "click #insertVideoBtn:not(.disabled)":"onInsertVideoButtonClicked",
        "click #recordAndInsert:not(.disabled)":"onRecordAndInsertButtonClicked",
        "click #deleteAllExceptSelectedBtn:not(.disabled)":"onDeleteAllExceptSelectedNodesButtonClicked",
        "click #saveAsClipBtn:not(.disabled)": "onSaveAsClipButtonCliked",
        //"click #deleteAudioSequencerNodeBtn:not(.disabled)": "onDeleteAudioSequencerNodeBtnClicked",
        "click #deleteSequencerNodeBtn:not(.disabled)":"onDeleteSequencerNodeBtnClicked",
        "click #moreOptionBtn:not(.disabled)": "onMoreOptionsButtonClicked",
        "click #previousResultBtn:not(.disabled)": "onShowPreviousNodeFoundInSearch",
        "click #nextResultBtn:not(.disabled)": "onShowNextNodeFoundInSearch",
        "click #exit_search_btn:not(.disabled)": "onExitCurrentMode",
        "click #clearSearchInputBtn:not(.disabled)": "onClearSearchInput",
        "click #turnOnPlayOnSearchBtn:not(.disabled)": "onTurnSearchPlaybackOff",
        "click #turnOffPlayOnSearchBtn:not(.disabled)": "onTurnSearchPlaybackOn",
       // "click #correctBtn:not(.disable)": "onCorrectTranscriptBtnClicked",
        "click #editTranscriptBtn:not(.disabled)":"showContextMenuForCorrectingTranscripts",
        "click #configureViewBtn":"onShowConfigureViewPopup",
        "click #downloadClipBtn:not(.disable)":"onDownloadClipButtonClicked",
        "click #playSelectionBtn:not(:disabled)":"onPlaySearchSelectionAndSurroundingClicked",
        "click #playWithoutSelection:not(:disabled)":"onPlaySearchurroundingWithoutSelectionClicked",
        "click #deleteSelectionBtn:not(:disabled)":"onDeleteSelectionClicked",
        "click #removeAllHightlightedBtn:not(:disabled)":"onDeleteAllHighlightedClicked",
        "click #restoreDetetedNodeBtn:not(:disabled)":"onDeleteSequencerNodeRestoredButtonClicked",
        
        
        "click #undoBtn:not(.disabled)": "onUndoShortcutKeyPressed",
        "click #redoBtn:not(.disabled)": "onRedoShortcutKeyPressed",
        "mouseenter #playerSongPositionSlider":"_onUserStartedDraggingSlider",
        "mouseleave #playerSongPositionSlider":"_onUserStoppedDraggingSlider",
        "wheel .sequencer_node_container":"_onWheelScrollInSequencerContainer",
        //"dragover .sequencer_node_container" : "_onDragOver",
        //"dragleave .sequencer_node_container" : "_onDragLeave",
        //"drop .sequencer_node_container" : "_onDrop",

    },

    onShowHelpBtnClicked : function(event){
        this.trigger("showHelp");
    },


    onSearchModeChange: function(){
        const searchOn =  this.model.getSearchWidget().isSearchOn();
        this.$el.attr("search_mode_on", searchOn?"true": "false");
        this.$el.attr("show_search_widget_on_left_side", SHOW_SEARCH_WIDGET_ON_LEFT_SECTION_OF_EDITOR?"true": "false");
        this.onSearchTypeChange();
        if(searchOn){
            this.onClearSearchInput();
            this._updatePreviousAndNextSearchButtons();
            this.onSearchTurnedOn();
        }
    },

    onSearchTypeChange : function(){
        const searchType =  this.model.getSearchWidget().getSearchType();
        const searchParams = this.model.getSearchWidget().getSearchParams();
        switch(searchType){
            case this.model.getSearchWidget().SEARCH_TYPE.DISFLUENCY_SEARCH:
                this.$el.attr("search_type", "disfluency");
                break;
            case this.model.getSearchWidget().SEARCH_TYPE.TEXT_SEARCH:
                this.$el.attr("search_type", "text");
                break;
            case this.model.getSearchWidget().SEARCH_TYPE.FILLER_WORDS:
                this.$el.attr("search_type", "filler_words");
                break;
            case this.model.getSearchWidget().SEARCH_TYPE.SILENCES:
                this.$el.attr("search_type", "silences");
                this.$el.find(".silences_search_label").html(`${searchParams && searchParams.maxSilenceInMilliseconds? window.getI18n(ti18n, "SILENCES_FOUND_OVER") + " "+ searchParams.maxSilenceInMilliseconds/1000+"s": window.getI18n(ti18n, "SILENCES_FOUND")}`)
                break;
        }
    },

    onPlaybackOnChange: function(){
        const playbackOn =  this.model.getSearchWidget().getPlaybackOn();
        this.$el.attr("search_playback_on", playbackOn?"true": "false");
    },

    onHasVideoSegmentChanged : function(){
        if(this.model.getHasVideoSegment() && window.chrome && EDIT_VIDEO_FILES){
            this.$el.attr("do_not_display_video", "false");
        }else{
            this.$el.attr("do_not_display_video", "true");
            if(this.model.getHasVideoSegment() && EDIT_VIDEO_FILES){
                window.alertWarningMessage(window.getI18n(ti18n, "TO_EDIT_VIDEO_PLEASE_USE_CRHOME"));
            }
        }
    },

    _initializeParagraphObserver: function(){
        return  new IntersectionObserver((function(entries) {
            entries.forEach((function(entry){
              if (entry.isIntersecting) {
                // The element is in the viewport
                // You can perform your desired actions here
                const paragraphViewCid = entry.target.getAttribute("view_cid");
                if(paragraphViewCid){
                    const paragraphView = this._paragraphViewCidToParagraphView[paragraphViewCid];
                    if(paragraphView){
                        paragraphView.showHideParagraphSummary(false);
                    }
                }
              } else {
                // The element is not in the viewport
                // You can handle this situation as needed
                const paragraphViewCid = entry.target.getAttribute("view_cid");
                if(paragraphViewCid){
                    const paragraphView = this._paragraphViewCidToParagraphView[paragraphViewCid];
                    if(paragraphView){
                        paragraphView.showHideParagraphSummary(true);
                    }
                }
              }
            }).bind(this));
          }).bind(this));
    },

    _onWheelScrollInSequencerContainer : function(){
        this._isUserManuallyScrolling = true;
    },

    onTurnSearchPlaybackOn: function(){
        if(ALLOW_TEXT_SEARCH_IN_AUDIO_EDITOR){
            this.trigger("turnSearchPlaybackOn");
        }
    },

    onShowConfigureViewPopup : function(){
        this.trigger("showConfigureViewPopup");
    },

    onTurnSearchPlaybackOff: function(){
        if(ALLOW_TEXT_SEARCH_IN_AUDIO_EDITOR){
            this.trigger("turnSearchPlaybackOff");
        }
    },

    onSoundEnhancerBtnClicked: function(){
        if(!this.isOnboardingDisplayed()){
            this.trigger("showSoundEnhancerSettings");
        }
    },

    onShowAutomatedOperationsMenuBtnClicked: function(){
        if(!this.isOnboardingDisplayed()){
            this.trigger("showAutomatedOperationsMenu");
        }
    },

    isSequencerSettingsDisplayed : function(){
        return  this.$el.attr("show_sequencer_settings") === "true"
    },

    setSequencerSettingsDisplayed : function(show){
        this.$el.attr("show_sequencer_settings", show ?"true":"false");
        if(show){
            this.showBeatifyMenuBtn$el.addClass("active");
        }else{
            this.showBeatifyMenuBtn$el.removeClass("active");
        }
    },
    onPlaySearchSelectionAndSurroundingClicked: function(){
      this.trigger("playSelectionAndSurroundings")  
    },

    onPlaySearchurroundingWithoutSelectionClicked: function(){
        this.trigger("playSurroundingsWithoutSelection")  
    },

    onDeleteSelectionClicked: function(){
        this.trigger("deleteSearchSelection")  
    },

    onDeleteAllHighlightedClicked: function(){
        this.trigger("deleteAllSearchSelection")  
    },

    onDeleteSequencerNodeRestoredButtonClicked: function(){
        this.trigger("restoreDeleteNode")  
    },

    onShowSequencerSettingsBtnClicked:function(){
        this.setSequencerSettingsDisplayed(!this.isSequencerSettingsDisplayed());
    },

    isSequencerAutomatedOperationsMenuDisplayed : function(){
        return  this.$el.attr("show_sequencer_automated_operations_menu") === "true"
    },

    setSequencerAutomationOperationsMenuDisplayed : function(show){
        this.$el.attr("show_sequencer_automated_operations_menu", show ?"true":"false");
        if(show){
            this.showBeatifyMenuBtn$el.addClass("active");
        }else{
            this.showBeatifyMenuBtn$el.removeClass("active");
        }
    },

    onShowSequencerAutomationOperationsMenuBtnClicked:function(){
        this.setSequencerAutomationOperationsMenuDisplayed(!this.isSequencerAutomatedOperationsMenuDisplayed());
    },

    getSoundEnhancerButton$el : function(){
        return this.showBeatifyMenuBtn$el;
    },

    getShowAutomatedOperationsMenuBtn$el : function(){
        return this.showAutomatedOperationsMenuBtn$el;
    },

    getShowHelpMenuBtn$el : function(){
        return this.showHelpBtn$el;
    },

    getConfigureViewBtn$el : function(){
        return this.configureViewBtn$el;
    },


    _onSequencerResetted : function(){
        this.unselectCurrentlySelectedSequencerNodes();
        for(let nodeCid in this._sequencerNodeCidToSequencerNodeViewMap){
            const sequencerNodeView = this._sequencerNodeCidToSequencerNodeViewMap[nodeCid];
            if(sequencerNodeView){
                sequencerNodeView.$el.remove();
                this._stoplisteningToEventsOnNodeView(sequencerNodeView)
            }
        }
        this._sequencerNodeCidToSequencerNodeViewMap = {};
        this._arrayOfSequencerNodeViewCidBeingListenedTo = [];
        this._sequencerNodeElArray = [];
        this._currentlyDisplayedTippyInstance = null;
        this._selectedNodeInfo = null;
        this._sortableInstance = null;
        this._sequencerNodeViewCurrentlyHighlightedAsPlaying = null;
        this._isUserManuallyScrolling = false;
        this._warningDisplayedAboutNoiseCancellationDuration = false;
        this._warningDisplayedAboutMagicSoundEnhancerDuration = false;
        this._beautifyMenuDisplayed = false;
    },


    _getClosestHTMLElementInDistance: function(arrayOfElements, clientX, clientY, onlyCompareWithElementOnSameLine){
        let closestEl =  null;
        const textLineHeight = 30;
        let closestElDistance = null;
        if(arrayOfElements){
            arrayOfElements.map(function( index, el){
                if(el){
                    const boundingClientRect = el.getBoundingClientRect();
                    if(boundingClientRect){
                        const a = boundingClientRect.x - clientX;
                        const b = boundingClientRect.y - clientY;
                        if(!onlyCompareWithElementOnSameLine  || textLineHeight *3 >= Math.abs(b)){
                            const distance = Math.sqrt( a*a + b*b );
                            if(!closestEl || distance < closestElDistance){
                                closestEl =  el;
                                closestElDistance = distance;
                            }
                        }
                    }
                }

            });
        }
        return closestEl;
    },

    

    _getClosestSequencerNodeViewToPosition: function(clientX, clientY){
        const elements = document.elementsFromPoint(clientX, clientY);
        let closestSequencerNodeViewFound =  null;
        for(let i =0; i < elements.length; i++){
            const el = elements[i];
            if(el.classList.contains("sequencer_node")){
                const closestSequencerNodeCidFound = el.getAttribute("segment_node_cid");
                closestSequencerNodeViewFound = closestSequencerNodeCidFound?this.getSequencerNodeViewFromSequencerNodeCid(closestSequencerNodeCidFound): null;
                break;
            }
        }
        if(!closestSequencerNodeViewFound){
            const lastElementsBeforeLineBreak = this.$el.find(".sequencer_node_container fullstop.sequencer_node.punctiation_audio_segment_node:nth-of-type(5n), .sequencer_node_container fullstop.sequencer_node.punctiation_audio_segment_node:last-of-type").map((function(index, el){
                const fullstopSequencerNodeCId = el.getAttribute("segment_node_cid");
                const fullstopSequencerNodeView = fullstopSequencerNodeCId?this.getSequencerNodeViewFromSequencerNodeCid(fullstopSequencerNodeCId): null;
                if(fullstopSequencerNodeView){
                    const visibleSequencerNode = fullstopSequencerNodeView.getModel().getPreviousVisibleSequencerNode();
                    if(visibleSequencerNode){
                        const visibleSequencerNodeView = this.getSequencerNodeViewFromSequencerNodeCid(visibleSequencerNode.cid);
                        return visibleSequencerNodeView.$el.get(0);
                    }
                }
            }).bind(this));

            if(!closestSequencerNodeViewFound){
                closestSequencerNodeViewFound = this._getSequencerNodeViewFromEl(this._getClosestHTMLElementInDistance(lastElementsBeforeLineBreak, clientX, clientY, false));
            }
        }

        return closestSequencerNodeViewFound;
    },

    _getSequencerNodeViewFromEl : function(el){
        if(el && el.classList.contains("sequencer_node")){
            const cid = el.getAttribute("segment_node_cid");
            return this.getSequencerNodeViewFromSequencerNodeCid(cid);
        }
    },

    _getClosestSequencerNodeViewFromEvent : function(e){
        return this._getClosestSequencerNodeViewToPosition(e.clientX, e.clientY);
    },

    /*_onDragOver : function(e){
        e.originalEvent.preventDefault();
        if(this.$el.find(".sequencer_node_container .node_drag_over").length == 0){
            const closestSequencerNodeViewFound = this._getClosestSequencerNodeViewFromEvent(e);
            if(closestSequencerNodeViewFound){
                this.$el.find(".sequencer_node_container .node_drag_over").removeClass("node_drag_over");
                this.$el.find(".sequencer_node_container .node_drag_over_after").removeClass("node_drag_over_after");
                $(closestSequencerNodeViewFound).addClass("node_drag_over_after");
            }
        }else{
            this.$el.find(".sequencer_node_container .node_drag_over_after").removeClass("node_drag_over_after");
        }
    },

    _onDragLeave : function(){
        this.$el.find(".sequencer_node_container .node_drag_over_after").removeClass("node_drag_over_after");
    },

    _onDrop : function(e){
        e.originalEvent.preventDefault();
        const sequencerNodeDragAfterTarget$el = this.$el.find(".sequencer_node_container .node_drag_over_after");
        if(sequencerNodeDragAfterTarget$el.length > 0){
            sequencerNodeDragAfterTarget$el.removeClass("node_drag_over_after");
            const sequencerNodeElCid = sequencerNodeDragAfterTarget$el.attr("segment_node_cid");
            const sequencerNodeDragAfterTargetView = this._sequencerNodeCidToSequencerNodeViewMap[sequencerNodeElCid];
            if(sequencerNodeDragAfterTargetView){
                const file = e.originalEvent.dataTransfer.files[0];
                if(e.originalEvent.dataTransfer.files.length > 0){
                    this._onFileInsertedAtTheEnd(file);
                    this.trigger("uploadFileAndInsertAfter",file, sequencerNodeDragAfterTargetView.getModel());
                }else{

                    const dropInfo  = {"modelCidOfSequencerNodeBeingDrop": e.originalEvent.dataTransfer.getData("text/plain"), "sequencerNodeViewTarget": sequencerNodeDragAfterTargetView};
                    this._onSequencerNodeBeingDroppedAfterSequencerNode(dropInfo)
                }
            }

        }
    },*/



    onInsertButtonClicked : function(event){
        this._destroyContextMenuDisplayed();
        this.trigger("insertAudioNodeTriggered", true);
        if(event){
            event.stopPropagation()
        }
    },

    onInsertVideoButtonClicked : function(event){
        this._destroyContextMenuDisplayed();
        this.trigger("insertVideoNodeTriggered", true);
        if(event){
            event.stopPropagation()
        }
    },

    onRecordAndInsertButtonClicked : function(event){
        this._destroyContextMenuDisplayed();
        this.trigger("recordAndInsertTriggered", true);
        if(event){
            event.stopPropagation()
        }
    },

    onDeleteAllExceptSelectedNodesButtonClicked: function(event){
        this._destroyContextMenuDisplayed();
        this.trigger("deleteAllExceptSelectedNodes", true);
        if(event){
            event.stopPropagation()
        }
    },

    setUnsafeToRemove: function(unsafeToRemove){
        this._unsafeToRemove = unsafeToRemove;
    },

    isUnsafeToRemove: function(){
        return this._unsafeToRemove;
    },

    onInsertElementButtonClicked: function(event){
        if(!this._currentInsertElementContextMenuDisplayedInstance){
            const menuInfoArray = [];
            this._destroyContextMenuDisplayed(true);
            if(this.isWrapWithBackgroundMusicActionAvailable()){
                menuInfoArray.push(Utils.getInstance().createMenuInfoItem("wrapSelectionWithMusic", window.getI18n(ti18n,"WRAP_WITH_AUDIO"), (e)=>{this.destroyInsertElementContextMenuDisplayedInstance(); this.onWrapWithMusicBtnClicked(e)}, `<i class="icon pe-7s-music music_icon" ></i>`));
            }
            if(this.isInsertActionAvailable()){
                menuInfoArray.push(Utils.getInstance().createMenuInfoItem("insertMusic", window.getI18n(ti18n,"INSERT"), (e)=>{this.destroyInsertElementContextMenuDisplayedInstance(); this.onInsertButtonClicked(e)}, `<i class="icon pe-7s-volume" ></i>`));
                menuInfoArray.push(Utils.getInstance().createMenuInfoItem("insertVideo", window.getI18n(ti18n,"INSERT_VIDEO_AT_START"), (e)=>{this.destroyInsertElementContextMenuDisplayedInstance(); this.onInsertVideoButtonClicked(e)}, `<i class="icon pe-7s-film" ></i>`));
                menuInfoArray.push(Utils.getInstance().createMenuInfoItem("recordAndInsert", window.getI18n(ti18n,"RECORD_AND_INSERT_BEFORE"), (e)=>{this.destroyInsertElementContextMenuDisplayedInstance(); this.onRecordAndInsertButtonClicked(e)}, `<i class="icon pe-7s-micro" ></i>`));
                
            }
            const contextMenuTippyJSInstance = Utils.getInstance().createContextMenu(menuInfoArray, event.target, this.sequencerNodeContainer$el.get(0), "top", true);
            this._currentInsertElementContextMenuDisplayedInstance = contextMenuTippyJSInstance;
        }
        if(event){
            event.stopPropagation()
        }
    },

    destroyInsertElementContextMenuDisplayedInstance : function(){
        if(this._currentInsertElementContextMenuDisplayedInstance){
            if( this._currentInsertElementContextMenuDisplayedInstance.destroy){
                this._currentInsertElementContextMenuDisplayedInstance.destroy();
            }
            if( this._currentInsertElementContextMenuDisplayedInstance.close){
                this._currentInsertElementContextMenuDisplayedInstance.close();
            }
            this._currentInsertElementContextMenuDisplayedInstance = null;
        }
    },

    destroySubContextMenuViewIfApplicable : function(){
        if(this._subContextMenuView){
            if( this._subContextMenuView.destroy){
                this._subContextMenuView.destroy();
            }
            if( this._subContextMenuView.close){
                this._subContextMenuView.close();
            }
        }
    },

    setSubContextMenuView : function(subContextMenuView){
        this.destroySubContextMenuViewIfApplicable();
        this._subContextMenuView =  subContextMenuView;
    },  


    onMoreOptionsButtonClicked: function(event){
        if($.contains(this.bottomBar$el.get(0), event.target)){
            this.trigger("showMoreOptionMenu", $(event.target), function(){}, "left bottom");
        }else{
            this.trigger("showMoreOptionMenu", $(event.target));
        }
        
        if(event){
            event.stopPropagation()
        }
    },



    onSaveAsClipButtonCliked : function(){
        this._destroyContextMenuDisplayed();
        if(this.isClippingActionAvailable()){
            this.trigger("saveAsClipTriggered", true);
        }
    },

    onCopySelectionToClipboardTriggered: function(){
        this._destroyContextMenuDisplayed();
        if(this.isCopyActionAvailable()){
            this.trigger("copySelectionToClipboard");
        }
    },

    onPasteClipboardBeforeSelectionTriggered: function(){
        this._destroyContextMenuDisplayed();
        if(this.isPasteActionAvailable()){
            this.trigger("pasteClipboardBeforeSelection");
        }
    },

    onCutAndCopySelectionToClipboardTriggered: function(){
        this._destroyContextMenuDisplayed();
        if(this.isCutActionAvailable()){
            this.trigger("cutAndCopySelectionToClipboard");
        }
    },

    onSelectAllTriggered: function(){
        if(this.model.getFirstNode() && this.model.getLastNode()){
            return this._markCurrentlySelectedNodesAsSelectedFromSelectionInfo(this._selectSequencerNodeRangeWithCid( this.model.getLastNode().cid, this.model.getFirstNode().cid), false, true);
        }
    },

    getLastNodeSequencerNodeView: function(){
        if(this.model.getLastNode() && this.model.getLastNode().cid){
            return this.getSequencerNodeViewFromSequencerNodeCid(this.model.getLastNode().cid);
        }
    },

    onDeleteButtonClicked: function(event){
        this._destroyContextMenuDisplayed();
        if(this.isSelectedNodeAStartOrEndMusicWrapNode()){
            this.onDeleteMusicWrapperBtn();
        }else{
            if(this.isSelectedNodeAnAudioNode()){
                this.onDeleteSequencerNodeBtnClicked();
            }
            if(this.isSelectedNodeAnVideoNode()){
                this.onDeleteSequencerNodeBtnClicked();
            }
        }
        if(event){
            event.stopPropagation()
        }
    },

    /*onDeleteAudioSequencerNodeBtnClicked : function(event){
        this._destroyContextMenuDisplayed();
        this.trigger("deleteAudioSequencerNodeTriggered");
        if(event){
            event.stopPropagation()
        }
    },*/

    onDeleteSequencerNodeBtnClicked : function(event){
        this._destroyContextMenuDisplayed();
        this.trigger("deleteSequencerNodeTriggered");
        if(event){
            event.stopPropagation()
        }
    },

    onDownloadClipButtonClicked : function(event){
        this._destroyContextMenuDisplayed();
        this.trigger("downloadClipTriggered");
        if(event){
            event.stopPropagation()
        }
    },


    onNumberOfRenderRequestPendingChange : function(){
        this.$el.attr("is_there_pending_render_request",this.model.isTherePendingRenderingRequest())
    },

    onRenderingBeingCreatedChange : function(){
        this.$el.attr("rendering_being_created",this.model.isRenderingBeingCreated())
        if(!this.model.isRenderingBeingCreated()){
            this.onRenderedBufferPlayingChange();
        }
    },

    onRenderedBufferPlaybackLoadingChange : function(){
        this.$el.attr("rendered_buffer_playback_loading",this.model.isRenderedBufferPlaybackLoading());
        if(!this.model.isRenderedBufferPlaybackLoading()){
            this.onRenderedBufferPlayingChange();
        }
    },

    onHistoryUpdated : function(){
        const canUndoOperation = this.model.getHistoryManagement().canUndoOperation();
        const canRedoOperation = this.model.getHistoryManagement().canRedoOperation();
        if(canUndoOperation){
            this.undoBtn$el.removeClass("disabled");
        }else{
            this.undoBtn$el.addClass("disabled");
        }
        if(canRedoOperation){
            this.redoBtn$el.removeClass("disabled");
        }else{
            this.redoBtn$el.addClass("disabled");
        }
        const searchOn =  this.model.getSearchWidget().isSearchOn();
    },

    onRenderedBufferPlayingChange : function(){
        this.$el.attr("rendering_buffer_playing",this.model.isRenderedBufferPlaying());
        this.$el.attr("rendered_buffer_playback_loading",this.model.isRenderedBufferPlaybackLoading());
        if(this.model.isRenderedBufferPlaying()){
            this._startFunctionToUpdatePlaybackProgress();
        }else{
            this._stopFunctionToUpdatePlaybackProgress();
        }
    },

    onPlaySequencerBtnClicked : function(event){
        this.trigger("playPauseSequencer");
        if(event){
            event.stopPropagation();
        }
    },

    

    _startFunctionToUpdatePlaybackProgress : function(){
        this._updateRequestAnimationFrameId = window.requestAnimationFrame(this._updatePlaybackInfo.bind(this));
    },

    _updatePlaybackInfo : function(){
        const durationInMilliseconds  = this.model.getDurationInMilliseconds();
        const playbackProgressInMilliseconds  = this.model.getPlaybackProgressInMilliseconds();
        this.totalTime$el.html(Utils.getInstance().formatPlaybackPositionToString(durationInMilliseconds/1000));
        this.timeElapsed$el.html(Utils.getInstance().formatPlaybackPositionToString(playbackProgressInMilliseconds/1000));
        const percentageOverHundred = playbackProgressInMilliseconds  * 100 / durationInMilliseconds;
        if(this._playerSliderEl && this._playerSliderEl.noUiSlider && !this._playerSliderEl._isUserDragged){
            this._playerSliderEl.noUiSlider.set(percentageOverHundred);
        }
        this.monitorPlaybackStateInCasePlaybackStalled();
        if(this._updateRequestAnimationFrameId){
            //We are checking for the id to make sure this request was not cancelled already
            this._updateRequestAnimationFrameId = requestAnimationFrame(this._updatePlaybackInfo.bind(this));
        }
    },

    monitorPlaybackStateInCasePlaybackStalled : function(){
        //this might be caused because the audiocontext magically stopped working
        try{
            if(this.model.isPlaying()){
                const timeInMillisecondsToRestartAfterStalled = TIME_IN_MILLISEC_TO_TRIGGER_RESTART_AFTER_STALLED;
                const lastMonitoredPlaybackProgressInMilliseconds = this._lastMonitoredPlaybackProgressInMilliseconds;
                const lastMonitoredPlaybackAudioContextCurrentTime = this._lastMonitoredPlaybackAudioContextCurrentTime;
                const lastMonitoredPlaybackProgressTimestamp = this._lastMonitoredPlaybackProgressTimestamp;
                const playbackProgressInMilliseconds  = this.model.getPlaybackProgressInMilliseconds();
                const playbackAudioContextCurrentTime = this.model.getPlaybackAudioContextCurrentTime();
                if(this.model.canPlayThrough() && (lastMonitoredPlaybackAudioContextCurrentTime === playbackAudioContextCurrentTime || lastMonitoredPlaybackProgressInMilliseconds === playbackProgressInMilliseconds)){
                    if(!this._isFirefoxBrowser()){
                            //not updating the play button because is seems to be creating some flickering in Firefo
                            //that is not bad anyway because the issue doesn't occurs in Firefox
                            //So far it only seems to be a Chrome and Opera issue
                        if(this.$el.attr("rendered_buffer_playback_loading") != "true"){
                            this.$el.attr("rendered_buffer_playback_loading",true);
                        }
                    }
                    console.warn("something might be wrong with audio context");
                    if((new Date()).getTime() - lastMonitoredPlaybackProgressTimestamp > timeInMillisecondsToRestartAfterStalled){


                        if(TURN_ON_HACK_IN_AUDIO_EDITOR_TO_PREVENT_STALLED_PLAYBACK && window.chrome){
                            this.model.restartPlayingSequencerRenderingAndSuspendAudioContextForStalledPlayback();
                        }else{
                            this.model.stopPlayingSequencerRenderingAndSuspendAudioContextForStalledPlayback();
                        }
                        this._lastMonitoredPlaybackProgressInMilliseconds = playbackProgressInMilliseconds;
                        this._lastMonitoredPlaybackAudioContextCurrentTime = playbackAudioContextCurrentTime;
                        this._lastMonitoredPlaybackProgressTimestamp = (new Date()).getTime();
                        console.warn("we paused the playback and suspend audio context. It should be resume on automatically the time user press play");
                    }
                }else{
                    if(!this._isFirefoxBrowser()){
                        if(this.$el.attr("rendered_buffer_playback_loading") != "false"){
                            this.$el.attr("rendered_buffer_playback_loading",false);
                        }
                    }
                    this._lastMonitoredPlaybackProgressInMilliseconds = playbackProgressInMilliseconds;
                    this._lastMonitoredPlaybackAudioContextCurrentTime = playbackAudioContextCurrentTime;
                    this._lastMonitoredPlaybackProgressTimestamp = (new Date()).getTime();
                }
            }
        }catch(error){
            console.error("failed monitoring playback state. Error:"+ error);
        }
    },

    _stopFunctionToUpdatePlaybackProgress : function(){
        if(this._updateRequestAnimationFrameId){
            window.cancelAnimationFrame(this._updateRequestAnimationFrameId);
            this._updateRequestAnimationFrameId = null;
        }

    },

    getModel : function(){
        return this.model;
    },

    onAutoscrollButtonClicked : function(){
        this.getModel().setAutoScroll(!this.getModel().getAutoScroll());
    },

    onNoiseCancellationButtonClicked : function(){
        if(!this.isOnboardingDisplayed()){
            if(DISPLAY_WARNING_ABOUT_NOISE_CANCELLATION_DURATION && !this._warningDisplayedAboutNoiseCancellationDuration && !this.getModel().getSequencerSettings().isNoiseCancellationOn()){
                this._warningDisplayedAboutNoiseCancellationDuration = true;
                Utils.getInstance().showWarningNotification(window.getI18n(ti18n, "NOISE_CANCELLAITON_TIME_WARNING"))
            }
            this.getModel().toggleNoiseCancellation();
        }
    },

    onAutoscrollChange : function(){
        if(this.getModel().getAutoScroll()){
            this.autoScrollBtn$el.addClass("active");
            //Utils.getInstance().showTrebbleReadyNotification("Autoscroll turned on");
        }else{
            this.autoScrollBtn$el.removeClass("active");
            //Utils.getInstance().showTrebbleReadyNotification("Autoscroll turned off");
        }
    },

    _updatePreviousAndNextSearchButtons :function(){
        if(this.model.getSearchWidget().canMoveToNextResult()){
            this.showNextSearchBtn$el.removeClass("disabled");
        }else{
            this.showNextSearchBtn$el.addClass("disabled");
        }

        if(this.model.getSearchWidget().canMoveToPreviousResult()){
            this.showPreviousSearchBtn$el.removeClass("disabled");
        }else{
            this.showPreviousSearchBtn$el.addClass("disabled");
        }

        const arrayOfSequencerNodeInFocus =  this.model.getSearchWidget().getSequencerNodesCurrentlyInFocus();
        if(arrayOfSequencerNodeInFocus && arrayOfSequencerNodeInFocus.length > 0){
            this.playSelectionBtn$el.prop('disabled', false);
            this.playWithoutSelection$el.prop('disabled', false);
            this.deleteSelectionBtn$el.prop('disabled', false);
            this.deleteAllHighligthed$el.prop('disabled', false);
        }else{
            this.playSelectionBtn$el.prop('disabled', true);
            this.playWithoutSelection$el.prop('disabled', true);
            this.deleteSelectionBtn$el.prop('disabled', true);
            this.deleteAllHighligthed$el.prop('disabled', true);
        }
    },


    onNodeSetInSearchFocusChange : function(arrayOfSequencerNodeInFocus){
        arrayOfSequencerNodeInFocus =  this.model.getSearchWidget().getSequencerNodesCurrentlyInFocus();
        if(arrayOfSequencerNodeInFocus && arrayOfSequencerNodeInFocus.length > 0){
            const firstSequencerNodeInFocus = arrayOfSequencerNodeInFocus[0];
            const lastSequencerNodeInFocus = arrayOfSequencerNodeInFocus[arrayOfSequencerNodeInFocus.length - 1];
            const firstSequencerNodeView =  this.getSequencerNodeViewFromSequencerNode(firstSequencerNodeInFocus);
            //const lastSequencerNodeView =  this.getSequencerNodeViewFromSequencerNode(lastSequencerNodeInFocus);
            let firstSequencerNodeToPlay = null;
            let lastSequencerNodeToPlay = null;
            if(firstSequencerNodeView){
                this.scrollToSequencerNodeIfneccessary(firstSequencerNodeView, true);
            }
            if(this.model.getSearchWidget().getPlaybackOn() && ALLOW_PLAYBACK_OF_SEARCH_RESULTS){
                const isAWordNodeFunction = (function(node){
                    return Utils.getInstance().isWordSequencerNodeInstance(node);
                })

                firstSequencerNodeToPlay = firstSequencerNodeInFocus.getPreviousNodeMatchingCondition(isAWordNodeFunction.bind(firstSequencerNodeInFocus), 4);
                if(!firstSequencerNodeToPlay){
                    firstSequencerNodeToPlay = firstSequencerNodeInFocus;
                }

                lastSequencerNodeToPlay = lastSequencerNodeInFocus.getNextNodeMatchingCondition(isAWordNodeFunction.bind(lastSequencerNodeInFocus),4);
                if(!lastSequencerNodeToPlay){
                    lastSequencerNodeToPlay = lastSequencerNodeInFocus;
                }
               

                this.trigger("playfromNode",firstSequencerNodeToPlay, 0, lastSequencerNodeToPlay, SequencerNodeUtils.getInstance().getArrayOfSequencerNodesFromStartToEnd(firstSequencerNodeInFocus, lastSequencerNodeInFocus));
            }

            this._updatePreviousAndNextSearchButtons();
            this.selectSequencerNodes(firstSequencerNodeInFocus, lastSequencerNodeInFocus,  true);
            this.focusSearchIndexLabel$el.html(this.model.getSearchWidget().getIndexOfNodeInFocus()+1);
            this.totalSearchResult$el.html(this.model.getSearchWidget().getNumberOfSearchNodesFound());
        }else{
            this._updatePreviousAndNextSearchButtons();
            this.focusSearchIndexLabel$el.html(0);
            this.totalSearchResult$el.html(0)
            //this.unselectCurrentlySelectedSequencerNodes();
        }

    },

    onNoiseCancellationOnChange : function(){
        if(this.getModel().getSequencerSettings().isNoiseCancellationOn()){
            this.noiseCancellationBtn$el.addClass("active");
        }else{
            this.noiseCancellationBtn$el.removeClass("active");
        }
    },

    onShowIgnoredContentSettingChange : function(){
        this.$el.attr("do_not_show_ignored_content", this.getModel().getSequencerSettings().isShowIgnoredContent()?"false":"true");
    },

    onRevealDeletedContentSettingChange : function(){
        this.$el.attr("delete_node_expanded", this.getModel().getSequencerSettings().isRevealDeletedContentOn()?"true":"false");
    },

    onShowGapNodesSettingChange : function(){
        this.$el.attr("do_not_show_gap_nodes", this.getModel().getSequencerSettings().isShowGapsBetweenWords()?"false":"true");
    },

    onShowEditBoundariesSettingChange : function(){
        this.$el.attr("do_not_show_edit_boundaries", this.getModel().getSequencerSettings().isShowEditBoundaries()?"false":"true");
    },

    onShowDarkThemeChange : function(){
        if(this.getModel().getSequencerSettings().isShowDarkTheme()){
            this.$el.parents(".ui-page").addClass("lighttext transparent");
        }else{
            this.$el.parents(".ui-page").removeClass("lighttext transparent");
        }
    },

    onAutoscrollSettingChange : function(){
        //this.model.setAutoScroll(this.getModel().getSequencerSettings().isAutoscroll(), true);
    },


    onCorrectTranscriptBtnClicked: function(){
        this.model.correctTextOnSequencerNodes(this.getArrayOfWordSequencerNodesSelected(this.getSelectionNode()), this.getCorrectectTextFromTextCorrectionContextMenuInput());
        return true;
    },


    onAudioRenderingStarted : function(){
        this.$el.attr("rendering_status","started");
    },

    onDeletionNodeInterruptedBecauseOfWrapOrphanNode : function(){
        window.alertErrorMessage(window.getI18n(ti18n,"YOUR_SELECTION_CANNOT_BE_DELETED_BECAUSE_ORPHAN_WRAP_NODE"));
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("audioEditor", "Deletion failed because selection contained orphan node ", "Deletion failed because selection contained orphan node ");
        }
    },

    onSelectedNodeNeedToBeNextToEachotherError : function(){
        window.alertErrorMessage(window.getI18n(ti18n,"YOUR_SELECTED_ELEMENTS_NEEDS_TO_BE_NEXT_TO_EACHOTHER"));
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("audioEditor", "The elements you've selected need to be next to each other", "The elements you've selected need to be next to each other");
        }
    },


    onCopySelectionCommandeInterruptedBecauseOfWrapOrphanNode  : function(){
        window.alertErrorMessage(window.getI18n(ti18n,"YOUR_SELECTION_CANNOT_BE_COPIED_BECAUSE_ORPHAN_WRAP_NODE"));
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("audioEditor", "copy selection failed because selection contained orphan node ", "copy selection failed because selection contained orphan node");
        }
    },

    onAudioRenderingCompleted : function(){
        this.$el.attr("rendering_status","completed");
    },

    onAudioRenderingFailed : function(error){
        this.$el.attr("rendering_status","failed");
        this.onRenderingBeingCreatedChange();
        window.alertErrorMessage(error);

    },

    onUndoShortcutKeyPressed : function(){
        //this.unselectCurrentlySelectedSequencerNodes();
        this.reselectCurrentlySelectedSequencerNodes();
        this._destroyContextMenuDisplayed();
        this.trigger("undo");
    },

    onRedoShortcutKeyPressed : function(){
        //this.unselectCurrentlySelectedSequencerNodes();
        this.reselectCurrentlySelectedSequencerNodes();
        this._destroyContextMenuDisplayed();
        this.trigger("redo");
    },

    selectSequencerNode : function(sequencerNode, doNotClearBrowserSelectionToNotLooseFocus){
        if(sequencerNode){
            return this._markCurrentlySelectedNodesAsSelectedFromSelectionInfo(this._selectSequencerNodeWithCid(sequencerNode.cid), doNotClearBrowserSelectionToNotLooseFocus);
        }
    },

    selectSequencerNodes : function(firstSequencerNodeToSelect, lastSequencerNodeToSelect, doNotClearBrowserSelectionToNotLooseFocus){
        if(firstSequencerNodeToSelect && lastSequencerNodeToSelect){
            return this._markCurrentlySelectedNodesAsSelectedFromSelectionInfo(this._selectSequencerNodeRangeWithCid(firstSequencerNodeToSelect.cid, lastSequencerNodeToSelect.cid), doNotClearBrowserSelectionToNotLooseFocus);
        }
    },

    selectNextSequencerNodeUpAfterSelection : function(){
        const nextUpSequencerNode = this.getNextVisibleSequencerNodeUpAfterSelection();
        if(nextUpSequencerNode){
            this.selectSequencerNode(nextUpSequencerNode);
            this.scrollToSequencerNodeIfneccessary(this.getSequencerNodeViewFromSequencerNode(nextUpSequencerNode), false, true, true);
        }
    },

    selectNextSequencerNodeDownAfterSelection : function(){
        const nextDownSequencerNode = this.getNextVisibleSequencerNodeDownAfterSelection();
        if(nextDownSequencerNode){
            this.selectSequencerNode(nextDownSequencerNode);
            this.scrollToSequencerNodeIfneccessary(this.getSequencerNodeViewFromSequencerNode(nextDownSequencerNode), false, true, true);
        }
    },

    selectNextSequencerNodeAfterSelection : function(){
        this.selectSequencerNode(this.getNextVisibleSequencerNodeAfterSelection());
    },

    selectPreviousSequencerNodeAfterSelection : function(){
        this.selectSequencerNode(this.getPreviousVisibleSequencerNodeAfterSelection());
    },

    addNextSequencerNodeToSelection: function(){
        const currentSelectionInfo =  this.getSelectionNode();
        if(currentSelectionInfo && currentSelectionInfo.selectionEndFocusNodeView){
            const endSequencerNodeModel = currentSelectionInfo.selectionEndFocusNodeView.getModel();
            const nextSequencerNodeVisible  = endSequencerNodeModel.getNextVisibleSequencerNode();
            if(nextSequencerNodeVisible){
                if(currentSelectionInfo && currentSelectionInfo.selectionStartAnchorNodeView){
                    const startSequencerNodeModel = currentSelectionInfo.selectionStartAnchorNodeView.getModel();
                    return this._markCurrentlySelectedNodesAsSelectedFromSelectionInfo(this._selectSequencerNodeRangeWithCid(startSequencerNodeModel.cid, nextSequencerNodeVisible.cid));
                }else{
                    return this._markCurrentlySelectedNodesAsSelectedFromSelectionInfo(this._selectSequencerNodeWithCid(nextSequencerNodeVisible.cid));
                }

            }
        }else{
            return this._markCurrentlySelectedNodesAsSelectedFromSelectionInfo(this._selectSequencerNodeWithCid(this.model.getFirstNode().cid));
        }
    },

    addNextDownSequencerNodeToSelection: function(){
        const currentSelectionInfo =  this.getSelectionNode();
        if(currentSelectionInfo && currentSelectionInfo.selectionEndFocusNodeView){
            const endSequencerNodeModel = currentSelectionInfo.selectionEndFocusNodeView.getModel();
            const nextDownSequencerNodeVisible  = this._getClosestSequencerNodeDown(endSequencerNodeModel);
            if(nextDownSequencerNodeVisible){
                this.scrollToSequencerNodeIfneccessary(this.getSequencerNodeViewFromSequencerNode(nextDownSequencerNodeVisible), false, true, true);
                if(currentSelectionInfo && currentSelectionInfo.selectionStartAnchorNodeView){
                    const startSequencerNodeModel = currentSelectionInfo.selectionStartAnchorNodeView.getModel();
                    return this._markCurrentlySelectedNodesAsSelectedFromSelectionInfo(this._selectSequencerNodeRangeWithCid(startSequencerNodeModel.cid, nextDownSequencerNodeVisible.cid));
                }else{
                    return this._markCurrentlySelectedNodesAsSelectedFromSelectionInfo(this._selectSequencerNodeWithCid(nextDownSequencerNodeVisible.cid));
                }

            }
        }else{
            return this._markCurrentlySelectedNodesAsSelectedFromSelectionInfo(this._selectSequencerNodeWithCid(this.model.getFirstNode().cid));
        }
    },

    removeLastSequencerNodeFromSelection: function(){
        const currentSelectionInfo =  this.getSelectionNode();
        if(currentSelectionInfo && currentSelectionInfo.selectionEndFocusNodeView){
            const endSequencerNodeModel = currentSelectionInfo.selectionEndFocusNodeView.getModel();
            const previousSequencerNodeVisible  = endSequencerNodeModel.getPreviousVisibleSequencerNode();
            if(previousSequencerNodeVisible){
                if(currentSelectionInfo && currentSelectionInfo.selectionStartAnchorNodeView){
                    const startSequencerNodeModel = currentSelectionInfo.selectionStartAnchorNodeView.getModel();
                    return this._markCurrentlySelectedNodesAsSelectedFromSelectionInfo(this._selectSequencerNodeRangeWithCid(startSequencerNodeModel.cid, previousSequencerNodeVisible.cid));
                }else{
                    return this._markCurrentlySelectedNodesAsSelectedFromSelectionInfo(this._selectSequencerNodeWithCid(previousSequencerNodeVisible.cid));
                }

            }
        }else{
            return this._markCurrentlySelectedNodesAsSelectedFromSelectionInfo(this._selectSequencerNodeWithCid(this.model.getFirstNode().cid));
        }
    },

    removeUpSequencerNodeFromSelection: function(){
        const currentSelectionInfo =  this.getSelectionNode();
        if(currentSelectionInfo && currentSelectionInfo.selectionEndFocusNodeView){
            const endSequencerNodeModel = currentSelectionInfo.selectionEndFocusNodeView.getModel();
            const nextUpSequencerNodeVisible  = this._getClosestSequencerNodeUp(endSequencerNodeModel);
            if(nextUpSequencerNodeVisible){
                this.scrollToSequencerNodeIfneccessary(this.getSequencerNodeViewFromSequencerNode(nextUpSequencerNodeVisible), false, true, true);
                if(currentSelectionInfo && currentSelectionInfo.selectionStartAnchorNodeView){
                    const startSequencerNodeModel = currentSelectionInfo.selectionStartAnchorNodeView.getModel();
                    return this._markCurrentlySelectedNodesAsSelectedFromSelectionInfo(this._selectSequencerNodeRangeWithCid(startSequencerNodeModel.cid, nextUpSequencerNodeVisible.cid));
                }else{
                    return this._markCurrentlySelectedNodesAsSelectedFromSelectionInfo(this._selectSequencerNodeWithCid(nextUpSequencerNodeVisible.cid));
                }

            }
        }else{
            return this._markCurrentlySelectedNodesAsSelectedFromSelectionInfo(this._selectSequencerNodeWithCid(this.model.getFirstNode().cid));
        }
    },

    onSearchTurnedOn : function(){
        if(ALLOW_TEXT_SEARCH_IN_AUDIO_EDITOR){
            //this.trigger("searchTurnedOn");
            if(SHOW_SEARCH_WIDGET_ON_LEFT_SECTION_OF_EDITOR){
                this._showEditorSearchComponentToLeftPanel();
            }else{
                this.trigger("searchTurnedOn");
            }
        }
    },

    onShowNextNodeFoundInSearch : function(){
        if(ALLOW_TEXT_SEARCH_IN_AUDIO_EDITOR){
            this.trigger("showNextNodeFoundInSearch");
        }
    },

    onShowPreviousNodeFoundInSearch : function(){
        if(ALLOW_TEXT_SEARCH_IN_AUDIO_EDITOR){
            this.trigger("showPreviousNodeFoundInSearch");
            return false;
        }
    },

    onExitCurrentMode : function(){
        if(this.isCorrectTranscriptionContextMenuDisplayed()){
            this._destroyContextMenuCorrectTranscriptDisplayed();
        }else{
            this.trigger("exitCurrentMode");
            this._hideEditorSearchComponentToLeftPanel();
        }
    },


    onClearSearchInput : function(){
        if(ALLOW_TEXT_SEARCH_IN_AUDIO_EDITOR){
            this._clearBrowserSelection();
            this.searchInput$el.val("");
            this.searchInput$el.focus();
        }
    },

    _doNotFireKeyboardEventCallbackIfInSearchMode: function(){
        return this.model.getSearchWidget().isSearchOn() && document.activeElement && document.activeElement.nodeName === "INPUT";
    },

    _listenForKeyboardEvents : function(){
        if(this._keyboardEventsSetup){
            return;
        }
        this._keyboardEventsSetup = true;

        this._undoHandlerId = Utils.getInstance().generateRandomId();
        this._redoHandlerId = Utils.getInstance().generateRandomId();
        this._playPauseHandlerId = Utils.getInstance().generateRandomId();
        this._deleteSelectedHandlerId = Utils.getInstance().generateRandomId();
        this._addNextToSelectionHandlerId =  Utils.getInstance().generateRandomId();
        this._addNextDownToSelectionHandlerId =  Utils.getInstance().generateRandomId();
        this._removeLastFromSelectionHandlerId =  Utils.getInstance().generateRandomId();
        this._removeUpFromSelectionHandlerId =  Utils.getInstance().generateRandomId();

        this._clipSelectionHandlerId =  Utils.getInstance().generateRandomId();
        this._downloadClipSelectionHandlerId =  Utils.getInstance().generateRandomId();
        this._copySelectionToClipboardHandlerId =  Utils.getInstance().generateRandomId();
        this._makeNextUpAsSelectionHandlerId = Utils.getInstance().generateRandomId();
        this._makeNextDownAsSelectionHandlerId = Utils.getInstance().generateRandomId();
        this._makeNextAsSelectionHandlerId = Utils.getInstance().generateRandomId();
        this._makePreviousAsSelectionHandlerId = Utils.getInstance().generateRandomId();
        this._pasteClipboardBeforeSelectionTriggeredHandlerId = Utils.getInstance().generateRandomId();
        this._cutAndCopySelectionToClipboardHandlerId = Utils.getInstance().generateRandomId();
        this._selectAlldHandlerId = Utils.getInstance().generateRandomId();
        this._searchKeywordHandlerId = Utils.getInstance().generateRandomId();
        this._showNextSearchFoundHandlerId = Utils.getInstance().generateRandomId();
        this._onDeleteAllExceptSelectedNodesHandlerId = Utils.getInstance().generateRandomId();
        this._showPreviousSearchFoundHandlerId = Utils.getInstance().generateRandomId();
        this._exitCurrentModeHandlerId = Utils.getInstance().generateRandomId();
        this._wrapWithAudioHandlerId = Utils.getInstance().generateRandomId();
        this._insertAudioHandlerId = Utils.getInstance().generateRandomId();
        this._insertVideoHandlerId = Utils.getInstance().generateRandomId();
        this._playFromHereHandlerId = Utils.getInstance().generateRandomId();
        this._deleteHandlerId = Utils.getInstance().generateRandomId();
        this._correctSingleWordId = Utils.getInstance().generateRandomId();
        
        const doNotFireKeyboardEventCallbackIfInSearchModehandler = this._doNotFireKeyboardEventCallbackIfInSearchMode.bind(this)
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent(['space'],this.onPlaySequencerBtnClicked.bind(this), this._playPauseHandlerId, doNotFireKeyboardEventCallbackIfInSearchModehandler);
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent(['backspace','del'],this.onIgnoreSelectedNodesBtnClicked.bind(this), this._deleteSelectedHandlerId, doNotFireKeyboardEventCallbackIfInSearchModehandler);
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent(['ctrl+z', 'command+z'],this.onUndoShortcutKeyPressed.bind(this), this._undoHandlerId, doNotFireKeyboardEventCallbackIfInSearchModehandler);
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent(['ctrl+shift+z', 'command+shift+z'],this.onRedoShortcutKeyPressed.bind(this), this._redoHandlerId, doNotFireKeyboardEventCallbackIfInSearchModehandler);
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent(['up'],this.selectNextSequencerNodeUpAfterSelection.bind(this), this._makeNextUpAsSelectionHandlerId, doNotFireKeyboardEventCallbackIfInSearchModehandler);
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent(['down'],this.selectNextSequencerNodeDownAfterSelection.bind(this), this._makeNextDownAsSelectionHandlerId, doNotFireKeyboardEventCallbackIfInSearchModehandler);
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent(['right'],this.selectNextSequencerNodeAfterSelection.bind(this), this._makeNextAsSelectionHandlerId, doNotFireKeyboardEventCallbackIfInSearchModehandler);
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent(['left'],this.selectPreviousSequencerNodeAfterSelection.bind(this), this._makePreviousAsSelectionHandlerId, doNotFireKeyboardEventCallbackIfInSearchModehandler);
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent(['shift+up'],this.removeUpSequencerNodeFromSelection.bind(this), this._addNextDownToSelectionHandlerId, doNotFireKeyboardEventCallbackIfInSearchModehandler);
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent(['shift+down'],this.addNextDownSequencerNodeToSelection.bind(this), this._removeUpFromSelectionHandlerId, doNotFireKeyboardEventCallbackIfInSearchModehandler);
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent(['shift+right'],this.addNextSequencerNodeToSelection.bind(this), this._addNextToSelectionHandlerId, doNotFireKeyboardEventCallbackIfInSearchModehandler);
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent(['shift+left'],this.removeLastSequencerNodeFromSelection.bind(this), this._removeLastFromSelectionHandlerId, doNotFireKeyboardEventCallbackIfInSearchModehandler);
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent(['command+shift+c','ctrl+shift+c'],this.onSaveAsClipButtonCliked.bind(this), this._clipSelectionHandlerId, doNotFireKeyboardEventCallbackIfInSearchModehandler);
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent(['command+shift+d','ctrl+shift+d'],this.onDownloadClipButtonClicked.bind(this), this._downloadClipSelectionHandlerId, doNotFireKeyboardEventCallbackIfInSearchModehandler);
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent(['command+c','ctrl+c'],this.onCopySelectionToClipboardTriggered.bind(this), this._copySelectionToClipboardHandlerId, doNotFireKeyboardEventCallbackIfInSearchModehandler);
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent(['command+v','ctrl+v'],this.onPasteClipboardBeforeSelectionTriggered.bind(this), this._pasteClipboardBeforeSelectionTriggeredHandlerId, doNotFireKeyboardEventCallbackIfInSearchModehandler);
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent(['command+x','ctrl+x'],this.onCutAndCopySelectionToClipboardTriggered.bind(this), this._cutAndCopySelectionToClipboardHandlerId, doNotFireKeyboardEventCallbackIfInSearchModehandler);
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent(['command+a','ctrl+a'],this.onSelectAllTriggered.bind(this), this._selectAlldHandlerId, doNotFireKeyboardEventCallbackIfInSearchModehandler);
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent(['command+f','ctrl+f'],this.onSearchTurnedOn.bind(this), this._searchKeywordHandlerId, doNotFireKeyboardEventCallbackIfInSearchModehandler);
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent(['command+g','ctrl+g'],this.onShowNextNodeFoundInSearch.bind(this), this._showNextSearchFoundHandlerId);
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent(['command+k','ctrl+k'],this.onDeleteAllExceptSelectedNodesButtonClicked.bind(this), this._onDeleteAllExceptSelectedNodesHandlerId, doNotFireKeyboardEventCallbackIfInSearchModehandler);
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent(['w'],this.onWrapWithMusicBtnClicked.bind(this), this._wrapWithAudioHandlerId, doNotFireKeyboardEventCallbackIfInSearchModehandler);
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent(['i'],this.onInsertButtonClicked.bind(this), this._insertAudioHandlerId, doNotFireKeyboardEventCallbackIfInSearchModehandler);
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent(['v'],this.onInsertVideoButtonClicked.bind(this), this._insertVideoHandlerId, doNotFireKeyboardEventCallbackIfInSearchModehandler);
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent(['d'],this.onDeleteButtonClicked.bind(this), this._deleteHandlerId, doNotFireKeyboardEventCallbackIfInSearchModehandler);
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent(['e'],this.showContextMenuForCorrectingTranscriptsIfApplicable.bind(this), this._correctSingleWordId, doNotFireKeyboardEventCallbackIfInSearchModehandler);
        //KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent(['option+space','alt+space'],this.onPlayFromBtnClicked.bind(this), this._playFromHereHandlerId);
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent(['command+shift+g','ctrl+shift+g'],this.onShowPreviousNodeFoundInSearch.bind(this), this._showPreviousSearchFoundHandlerId);
        KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent(['esc','escape'],this.onExitCurrentMode.bind(this), this._exitCurrentModeHandlerId);

    },

    _stopListeningForKeyboardEvents: function(){
        KeyboardKeyHandlerHelper.getInstance().unregisterForKeyboardEventByHandlerId(['space'], this._playPauseHandlerId);
        KeyboardKeyHandlerHelper.getInstance().unregisterForKeyboardEventByHandlerId(['backspace','del'], this._deleteSelectedHandlerId);
        KeyboardKeyHandlerHelper.getInstance().unregisterForKeyboardEventByHandlerId(['ctrl+z', 'command+z'], this._undoHandlerId);
        KeyboardKeyHandlerHelper.getInstance().unregisterForKeyboardEventByHandlerId(['ctrl+shift+z', 'command+shift+z'], this._redoHandlerId);
        KeyboardKeyHandlerHelper.getInstance().unregisterForKeyboardEventByHandlerId(['up'], this._makeNextUpAsSelectionHandlerId);
        KeyboardKeyHandlerHelper.getInstance().unregisterForKeyboardEventByHandlerId(['down'], this._makeNextDownAsSelectionHandlerId);
        KeyboardKeyHandlerHelper.getInstance().unregisterForKeyboardEventByHandlerId(['right'], this._makeNextAsSelectionHandlerId);
        KeyboardKeyHandlerHelper.getInstance().unregisterForKeyboardEventByHandlerId(['left'], this._makePreviousAsSelectionHandlerId);
        KeyboardKeyHandlerHelper.getInstance().unregisterForKeyboardEventByHandlerId(['shift+up'],this._addNextDownToSelectionHandlerId);
        KeyboardKeyHandlerHelper.getInstance().unregisterForKeyboardEventByHandlerId(['shift+down'], this._removeUpFromSelectionHandlerId);
        KeyboardKeyHandlerHelper.getInstance().unregisterForKeyboardEventByHandlerId(['shift+right'], this._addNextToSelectionHandlerId);
        KeyboardKeyHandlerHelper.getInstance().unregisterForKeyboardEventByHandlerId(['shift+left'], this._removeLastFromSelectionHandlerId);
        KeyboardKeyHandlerHelper.getInstance().unregisterForKeyboardEventByHandlerId(['command+shift+c','ctrl+shift+c'], this._clipSelectionHandlerId);
        KeyboardKeyHandlerHelper.getInstance().unregisterForKeyboardEventByHandlerId(['command+shift+d','ctrl+shift+d'], this._downloadClipSelectionHandlerId);
        KeyboardKeyHandlerHelper.getInstance().unregisterForKeyboardEventByHandlerId(['command+c','ctrl+c'], this._copySelectionToClipboardHandlerId);
        KeyboardKeyHandlerHelper.getInstance().unregisterForKeyboardEventByHandlerId(['command+v','ctrl+v'], this._pasteClipboardBeforeSelectionTriggeredHandlerId);
        KeyboardKeyHandlerHelper.getInstance().unregisterForKeyboardEventByHandlerId(['command+x','ctrl+x'], this._cutAndCopySelectionToClipboardHandlerId);
        KeyboardKeyHandlerHelper.getInstance().unregisterForKeyboardEventByHandlerId(['command+a','ctrl+a'], this._selectAlldHandlerId);
        KeyboardKeyHandlerHelper.getInstance().unregisterForKeyboardEventByHandlerId(['command+f','ctrl+f'], this._searchKeywordHandlerId);
        KeyboardKeyHandlerHelper.getInstance().unregisterForKeyboardEventByHandlerId(['command+g','ctrl+g'],this._showNextSearchFoundHandlerId);
        KeyboardKeyHandlerHelper.getInstance().unregisterForKeyboardEventByHandlerId(['command+k','ctrl+k'],this._onDeleteAllExceptSelectedNodesHandlerId);
        KeyboardKeyHandlerHelper.getInstance().unregisterForKeyboardEventByHandlerId(['w'], this._wrapWithAudioHandlerId);
        KeyboardKeyHandlerHelper.getInstance().unregisterForKeyboardEventByHandlerId(['i'], this._insertAudioHandlerId);
        KeyboardKeyHandlerHelper.getInstance().unregisterForKeyboardEventByHandlerId(['v'], this._insertVideoHandlerId);
        KeyboardKeyHandlerHelper.getInstance().unregisterForKeyboardEventByHandlerId(['d'], this._deleteHandlerId);
        KeyboardKeyHandlerHelper.getInstance().unregisterForKeyboardEventByHandlerId(['e'], this._correctSingleWordId);
        KeyboardKeyHandlerHelper.getInstance().unregisterForKeyboardEventByHandlerId(['command+shift+g','ctrl+shift+g'],this._showPreviousSearchFoundHandlerId);
        KeyboardKeyHandlerHelper.getInstance().unregisterForKeyboardEventByHandlerId(['esc','escape'], this._exitCurrentModeHandlerId);
        this._keyboardEventsSetup = false;
    },

    stopListening : function(){
        Backbone.View.prototype.stopListening.apply(this);
        this._stopListeningForKeyboardEvents();
    },

    _createParagraphForSequencerNodeView :function(sequencerNodeView){
        let paragraphContentView = null;
        if(sequencerNodeView.getModel() && sequencerNodeView.getModel().getSpeakerInfo()){
            paragraphContentView = new SpeakerParagraphContainerView({model: sequencerNodeView.getModel().getSpeakerInfo()});
        }else{
            if(this.model.getTotalNumberOfSpeakerInfo() > 0){
                if(this._arrayOfParagraphContainerViews.length === 0){
                    //this is the first element of the script which doesn't have a speaker so try to find the next sequencer node with a speaker
                    const nextSequencerNodeWithASpeaker = sequencerNodeView.getModel().getNextNodeMatchingCondition((function(nextNode){
                        return !!nextNode.getSpeakerInfo();
                    }).bind(this));
                    if(nextSequencerNodeWithASpeaker && nextSequencerNodeWithASpeaker.getSpeakerInfo()){
                        paragraphContentView = new SpeakerParagraphContainerView({model: nextSequencerNodeWithASpeaker.getSpeakerInfo()});
                    }
                }
                if(!paragraphContentView){
                    paragraphContentView = new SpeakerParagraphContainerView({model: this.model.getUnknownSpeakerInfo()});
                }
            }else{
                //paragraphContentView = new SpeakerParagraphContainerView({model: this.model.getUnknownSpeakerInfo()});
                paragraphContentView = new UnlabelledParagraphContainerView();
            }
        }
        paragraphContentView.render();
        return paragraphContentView;
    },

    _addSequencerNodeView : function(sequencerNodeViewToAdd, sequencerNode){
        const sequencerNodeBefore = sequencerNode.getPrevious();
        const sequencerNodeViewBeforeAlreadyAdded = this.getSequencerNodeViewFromSequencerNode(sequencerNodeBefore);
        const sequencerNodeAfter = sequencerNode.getNext();
        const sequencerNodeViewAfterAlreadyAdded = this.getSequencerNodeViewFromSequencerNode(sequencerNodeAfter);
        let addedOnTheLeftOfNodeAfter = false;
        let addedOnTheRightOfNodeBefore = false;
        //First try to add the sequencer node using the paragraph container of the previous node or the next node
        if(sequencerNodeViewToAdd.shouldBeAddedToParagraphContainerOfNextNodeFirst()){
            if(sequencerNodeViewAfterAlreadyAdded && sequencerNodeViewAfterAlreadyAdded.getParagraphContainerView()){
                addedOnTheLeftOfNodeAfter = sequencerNodeViewAfterAlreadyAdded.getParagraphContainerView().addSequencerNodeViewAfter(sequencerNodeViewToAdd, sequencerNodeViewAfterAlreadyAdded);
            }
            if(!addedOnTheLeftOfNodeAfter && sequencerNodeViewBeforeAlreadyAdded && sequencerNodeViewBeforeAlreadyAdded.getParagraphContainerView()){
                addedOnTheRightOfNodeBefore = sequencerNodeViewBeforeAlreadyAdded.getParagraphContainerView().addSequencerNodeViewBefore(sequencerNodeViewToAdd, sequencerNodeViewBeforeAlreadyAdded);
            }
        }else{
            if(sequencerNodeViewBeforeAlreadyAdded && sequencerNodeViewBeforeAlreadyAdded.getParagraphContainerView()){
                addedOnTheRightOfNodeBefore = sequencerNodeViewBeforeAlreadyAdded.getParagraphContainerView().addSequencerNodeViewBefore(sequencerNodeViewToAdd, sequencerNodeViewBeforeAlreadyAdded);
            }
            if(!addedOnTheRightOfNodeBefore && sequencerNodeViewAfterAlreadyAdded && sequencerNodeViewAfterAlreadyAdded.getParagraphContainerView()){
                addedOnTheLeftOfNodeAfter = sequencerNodeViewAfterAlreadyAdded.getParagraphContainerView().addSequencerNodeViewAfter(sequencerNodeViewToAdd, sequencerNodeViewAfterAlreadyAdded);
            }
        }
        //If we couldnt add it, we will need to create our own new paragraph matching the sequencer node we are tryingt to add
        if(!addedOnTheLeftOfNodeAfter && !addedOnTheRightOfNodeBefore){
            const newParagraphContentView  = this._createParagraphForSequencerNodeView(sequencerNodeViewToAdd);
            newParagraphContentView.prependSequencerNodeView(sequencerNodeViewToAdd);
            let addedNewParagraphToParagraphArray = false;
            //we can try to add if the previous node is the last node of the paragraph
            if(!addedNewParagraphToParagraphArray && sequencerNodeViewBeforeAlreadyAdded &&  sequencerNodeViewBeforeAlreadyAdded.getParagraphContainerView() && sequencerNodeViewBeforeAlreadyAdded.getParagraphContainerView().isLastSequencerNodeViewInParagraph(sequencerNodeViewBeforeAlreadyAdded)){
                addedNewParagraphToParagraphArray = this._addNewParagraphViewAfterToParagraphArray(newParagraphContentView, sequencerNodeViewBeforeAlreadyAdded.getParagraphContainerView());
            }
            //we can try to add if the next node is the first node of the paragraph
            if(!addedNewParagraphToParagraphArray && sequencerNodeViewAfterAlreadyAdded &&  sequencerNodeViewAfterAlreadyAdded.getParagraphContainerView() && sequencerNodeViewAfterAlreadyAdded.getParagraphContainerView().isFirstSequencerNodeViewInParagraph(sequencerNodeViewAfterAlreadyAdded)){
                addedNewParagraphToParagraphArray = this._addNewParagraphViewBeforeToParagraphArray(newParagraphContentView, sequencerNodeViewAfterAlreadyAdded.getParagraphContainerView());
            }
            //if we still have not been able to add the sequencer node
            if(!addedNewParagraphToParagraphArray){
                if(sequencerNodeViewAfterAlreadyAdded && sequencerNodeViewBeforeAlreadyAdded && sequencerNodeViewBeforeAlreadyAdded.getParagraphContainerView() === sequencerNodeViewAfterAlreadyAdded.getParagraphContainerView() && !sequencerNodeViewBeforeAlreadyAdded.getParagraphContainerView().isLastSequencerNodeViewInParagraph(sequencerNodeViewBeforeAlreadyAdded)){
                    //sequencerNodeView to insert is in the middle of a paragraph that needs to be splitted
                    const paragraphContainerViewToSplit = sequencerNodeViewAfterAlreadyAdded.getParagraphContainerView();
                    const paragraphContainerViewToSplitIndex = this._arrayOfParagraphContainerViews.indexOf(paragraphContainerViewToSplit);
                    const newParagraphContainerViewToShiftToTheRightTo = paragraphContainerViewToSplit.createNewEmptyParagraphContainerView();
                    newParagraphContainerViewToShiftToTheRightTo.render();
                    this._addNewParagraphViewAfterToParagraphArray(newParagraphContainerViewToShiftToTheRightTo, paragraphContainerViewToSplit);
                    paragraphContainerViewToSplit.shiftToTheRight(newParagraphContainerViewToShiftToTheRightTo, sequencerNodeViewAfterAlreadyAdded);
                    addedNewParagraphToParagraphArray  = this._addNewParagraphViewAfterToParagraphArray(newParagraphContentView, paragraphContainerViewToSplit);
                }else{
                    if(this._arrayOfParagraphContainerViews.length === 0){
                        //probably the first paragraph
                        this._preprendNewParagraphView(newParagraphContentView);
                    }else{
                        throw "something went wrong adding new element to sequencer"
                    }
                }
            }
            return true;
        }else{
            return true;
        }
    },

    _preprendNewParagraphView : function(newParagraphView){
        this.sequencerNodeContainer$el.prepend(newParagraphView.$el);
        this._arrayOfParagraphContainerViews.splice(0, 0, newParagraphView);
        this._listenToEventsOnParagraphContainerView(newParagraphView);
        return true;
    },

    _addNewParagraphViewAfterToParagraphArray : function(newParagraphView, previousParagraphViewAlreadyAdded){
        const previousParagraphIndex = this._arrayOfParagraphContainerViews.indexOf(previousParagraphViewAlreadyAdded);
        if(previousParagraphIndex > -1){
            this._arrayOfParagraphContainerViews.splice(previousParagraphIndex + 1, 0, newParagraphView);
            newParagraphView.$el.insertAfter(previousParagraphViewAlreadyAdded.$el);
            this._listenToEventsOnParagraphContainerView(newParagraphView);
            return true;
        }
        return false;
    },

    _addNewParagraphViewBeforeToParagraphArray : function(newParagraphView, afterParagraphViewAlreadyAdded){
        const nextParagraphIndex = this._arrayOfParagraphContainerViews.indexOf(afterParagraphViewAlreadyAdded);
        if(nextParagraphIndex > -1){
            this._arrayOfParagraphContainerViews.splice(nextParagraphIndex, 0, newParagraphView);
            newParagraphView.$el.insertBefore(afterParagraphViewAlreadyAdded.$el);
            this._listenToEventsOnParagraphContainerView(newParagraphView);
            return true;
        }
        return false;
    },

  

    _addToParagraphOld : function(sequencerNodeAdded, sequencerNodeView){
        if(!this._arrayOfParagraphElements){
            this._arrayOfParagraphElements = [];
        }
        const previousNode = sequencerNodeAdded.getPrevious();
        const previousNodeView = this.getSequencerNodeViewFromSequencerNode(previousNode);
        let previousParagraph$el = null;
        let nextParagraph$el = null;
        if(previousNodeView){
            previousParagraph$el = previousNodeView.paragraph$el;
            if(previousParagraph$el && previousParagraph$el.children().length  < 100){
                previousParagraph$el.append(sequencerNodeView.$el);
                sequencerNodeView.paragraph$el = previousParagraph$el;
                return;
            }else{
                const nextNode = sequencerNodeAdded.getNext();
                const nextNodeView = this.getSequencerNodeViewFromSequencerNode(nextNode);
                if(nextNodeView){
                    nextParagraph$el = nextNodeView.paragraph$el;
                    if(nextParagraph$el  && nextParagraph$el.children().length< 100){
                        nextParagraph$el.find(".paragraphContent").prepend(sequencerNodeView.$el);
                        sequencerNodeView.paragraph$el = nextParagraph$el;
                        return ;
                    }
                }
            }
        }
        const newParagraph$el =  $("<div style='content-visibility: auto;'><div>Speaker X</div><div class='paragraphContent'></div></div>");
        
        newParagraph$el.find(".paragraphContent").prepend(sequencerNodeView.$el);
        sequencerNodeView.paragraph$el = newParagraph$el;
        if(previousParagraph$el){
            const previousParagraphIndex = this._arrayOfParagraphElements.indexOf(previousParagraph$el);
            if(previousParagraphIndex> -1){
                this._arrayOfParagraphElements.splice(previousParagraphIndex+ 1, 0, newParagraph$el);
                newParagraph$el.insertAfter(previousParagraph$el);
            }else{
                this.sequencerNodeContainer$el.prepend(newParagraph$el);
                this._arrayOfParagraphElements.splice(0, 0, newParagraph$el);
            }
        }else{
            this.sequencerNodeContainer$el.prepend(newParagraph$el);
            this._arrayOfParagraphElements.splice(0, 0, newParagraph$el);
        }
        
    },

    onSequencerNodeAdded : function(sequencerNodeAdded){
        const sequencerNodeView =  this._createAndRenderSequencerNodeView(sequencerNodeAdded);
        this._listenToEventsOnNodeView(sequencerNodeView);
        //sequencerNodeAdded.on("change:hidden", this._onSequencerNodeHiddenChange.bind(this))
        const previousNode = sequencerNodeAdded.getPrevious();
        /*if(sequencerNodeView.events){
            sequencerNodeView.delegateEvents(sequencerNodeView.events)
        }*/
        const previousNodeView = this.getSequencerNodeViewFromSequencerNode(previousNode);
        //this._addToParagraph(sequencerNodeAdded, sequencerNodeView);
        this._addSequencerNodeView(sequencerNodeView, sequencerNodeAdded);
        if(previousNodeView){
            //sequencerNodeView.$el.insertAfter(previousNodeView.$el);
            const previousNodeIndex = this._sequencerNodeElArray.indexOf(previousNodeView.$el.get(0));
            this._sequencerNodeElArray.splice(previousNodeIndex+ 1, 0, sequencerNodeView.$el.get(0));
        }else{
            //this is probably the first node
            //this.sequencerNodeContainer$el.prepend(sequencerNodeView.$el);
            this._sequencerNodeElArray.splice(0, 0, sequencerNodeView.$el.get(0));
        }
        //this._sequencerNodeVisibleObserver.observe(sequencerNodeView.$el.get(0));
        //this._sequencerNodeHiddenObserver.observe(sequencerNodeView.$el.get(0));

        //this.sequencerNodeContainer$el.append(sequencerNodeView.$el);
        //this._sequencerNodeElArray.push(sequencerNodeView.$el.get(0));

    },

    _listenToEventsOnNodeView : function(sequencerNodeView){
        if(!this._arrayOfSequencerNodeViewCidBeingListenedTo.includes(sequencerNodeView.cid)){
            this.listenTo(sequencerNodeView, "revertDeleteNodeCreation",this._onRequestToRevertDeleteNodeCreation);
            this.listenTo(sequencerNodeView, "tapholdOnWrapNode",this._onTapholdOnWrapNode);
            this.listenTo(sequencerNodeView, "sequencerNodeBeingDroppedBefore",this._onSequencerNodeBeingDroppedBeforeSequencerNode);
            this.listenTo(sequencerNodeView, "fileDroppedBeforeNode",this._onFileBeingDroppedBeforeSequencerNode);
            this.listenTo(sequencerNodeView, "fileDroppedAfterNode",this._onFileBeingDroppedAfterSequencerNode);
            this.listenTo(sequencerNodeView, "sequencerNodeHighlightedAsPlaying",this.scrollToSequencerNodeIfneccessary);
            this._arrayOfSequencerNodeViewCidBeingListenedTo.push(sequencerNodeView.cid)
        }
        //this.listenTo(sequencerNodeView, "onHiddenValueChange",this._onSequencerNodeViewHiddenChange);
    },

    _onParagraphTitleLabelClick : function(paragraphContainerView){

    },

    _listenToEventsOnParagraphContainerView : function(paragraphContainerView){
        if(!this._arrayOfParagraphContainerViewCidBeingListenedTo.includes(paragraphContainerView.cid)){
            this.listenTo(paragraphContainerView, "sequencerNodeBeingDroppedAtTheEndOfParagraph", this._onSequencerNodeBeingDroppedAtTheEndOfParagraph);
            this.listenTo(paragraphContainerView, "fileDroppedAtTheEndOfParagraph", this._onFileBeingDroppedAtTheEndOfParagraph);
            this.listenTo(paragraphContainerView, "onSpeakerLabelClick", this._onSpeakerLabelClick);
            this.listenTo(paragraphContainerView, "onParagraphTitleLabelClick", this._onParagraphTitleLabelClick);
            
            this._arrayOfParagraphContainerViewCidBeingListenedTo.push(paragraphContainerView.cid)
            this._paragraphViewCidToParagraphView[paragraphContainerView.cid]=  paragraphContainerView;
            if(USE_PARAGRAPH_SUMMARY_TO_INCREASE_RENDERING_SPEED){
                this._paragraphObserver.observe(paragraphContainerView.$el.get(0));
            }
        }
    },




    _onChangeSpeakerContextMenuClicked :function(paragraphContainerView, e){
        const ceontextMenuEl = e.currentTarget;
        if(this._speakerContextMenuTippyJSInstanceDisplayed.subMenu){
            this._speakerContextMenuTippyJSInstanceDisplayed.subMenu.destroy();
        }
        const alreadySelectedSpeakerInfo = paragraphContainerView && paragraphContainerView.getModel();
        const allSpeakerInfo =  this.getModel().getAllSpeakerInfo();
        const speakerInfoArrayWithoutSelected =  allSpeakerInfo.reduce((speakerInfoToDisplay, speakerInfo) =>{
            if(speakerInfo !== alreadySelectedSpeakerInfo){
                speakerInfoToDisplay.push(speakerInfo);
            }
            return speakerInfoToDisplay;
        }, [])
        const menuInfoItemArray  = speakerInfoArrayWithoutSelected.map((speakerInfo)=>{
            return Utils.getInstance().createMenuInfoItem(`speakerMenu_${Utils.getInstance().generateRandomId()}`, speakerInfo.getSpeakerLabel(), (e)=>{
                this.model.changeSpeakerParagraph(paragraphContainerView, speakerInfo, paragraphContainerView.getFistSequencerNodeView().getModel(), paragraphContainerView.getLastSequencerNodeView().getModel());
                this._destroySpeakerContextMenuIfApplicable();
            }, null)
        })
        const contextMenuTippyJSInstance = Utils.getInstance().createContextMenu(menuInfoItemArray, ceontextMenuEl, this.sequencerNodeContainer$el.get(0), "right", true);
        this._initializeTippyJsTooltips($(contextMenuTippyJSInstance.popper));
        this._speakerContextMenuTippyJSInstanceDisplayed.subMenu = contextMenuTippyJSInstance;
        
    },

    _onCreateNewSpeakerContextMenuClicked :function(paragraphContainerView){
        this._destroySpeakerContextMenuIfApplicable();
        const onCreateButtonClicked = (newSpeakerName)=>{
            try{
                const newSpeakerInfo = this.model.createNewSpeakerInfoAndAssign(newSpeakerName, paragraphContainerView, paragraphContainerView.getFistSequencerNodeView().getModel(), paragraphContainerView.getLastSequencerNodeView().getModel());
                if(newSpeakerInfo){
                    this._destroySpeakerContextMenuIfApplicable();
                    return true;
                }else{
                    return false;
                }
            }catch(error){
                window.alertErrorMessage(error);
            }
        }
        this._showCorrectTranscriptContextMenuInstance(paragraphContainerView.getSpeakerLabelContainer$el().get(0), this.sequencerNodeContainer$el.get(0), "", onCreateButtonClicked, window.getI18n(ti18n,"CREATE"));
    },


    _onRenameSpeakerSpeakerContextMenuClicked :function(paragraphContainerView){
        this._destroySpeakerContextMenuIfApplicable();
        const onRenameButtonClicked = (newSpeakerName)=>{
            try{
                const speakerWasRenamed = this.model.renameSpeakerInfo(paragraphContainerView.getModel(), newSpeakerName);
                if(speakerWasRenamed){
                    this._destroySpeakerContextMenuIfApplicable();
                }
                return speakerWasRenamed;
            }catch(error){
                window.alertErrorMessage(error);
            }
        }
        this._showCorrectTranscriptContextMenuInstance(paragraphContainerView.getSpeakerLabelContainer$el().get(0), this.sequencerNodeContainer$el.get(0), paragraphContainerView.getModel().getSpeakerLabel(), onRenameButtonClicked, window.getI18n(ti18n,"RENAME"));
    },

    _destroySpeakerContextMenuIfApplicable: function(){
        if(this._speakerContextMenuTippyJSInstanceDisplayed){
            if(this._speakerContextMenuTippyJSInstanceDisplayed.subMenu){
                this._speakerContextMenuTippyJSInstanceDisplayed.subMenu.destroy();
            }
            this._speakerContextMenuTippyJSInstanceDisplayed.destroy();
            this._speakerContextMenuTippyJSInstanceDisplayed = null;
        }
    },

    _onSpeakerLabelClick : function(paragraphContainerView){
        this._destroyContextMenuDisplayed();
        const menuInfoArray = [];
        if(this.model.getTotalNumberOfSpeakerInfo() > 1 || (this.model.getTotalNumberOfSpeakerInfo() === 1 && paragraphContainerView.getModel() instanceof UnknownSpeakerInfo)){
            menuInfoArray.push(Utils.getInstance().createMenuInfoItem("selectOtherSpeaker", window.getI18n(ti18n,"SELECT_OTHER_SPEAKER"), (e)=>{this._onChangeSpeakerContextMenuClicked(paragraphContainerView, e)}));
        }
        if(paragraphContainerView.getModel().isEditable()){
            menuInfoArray.push(Utils.getInstance().createMenuInfoItem("renameCurrentSpeaker",window.getI18n(ti18n,"RENAME_CURRENT_SPEAKER"), (e)=>{this._onRenameSpeakerSpeakerContextMenuClicked(paragraphContainerView, e)}));
        }
        menuInfoArray.push(Utils.getInstance().createMenuInfoItem("createNewSpeaker", window.getI18n(ti18n,"CREATE_NEW_SPEAKER"), (e)=>{this._onCreateNewSpeakerContextMenuClicked(paragraphContainerView, e)}));
        const contextMenuTippyJSInstance = Utils.getInstance().createContextMenu(menuInfoArray, paragraphContainerView.getSpeakerLabelContainer$el().get(0), this.sequencerNodeContainer$el.get(0), "bottom-start", true);
        this._initializeTippyJsTooltips($(contextMenuTippyJSInstance.popper));
        this._speakerContextMenuTippyJSInstanceDisplayed = contextMenuTippyJSInstance;
    },

    _stoplisteningToEventsOnNodeView :function(sequencerNodeView){
        const index = this._arrayOfSequencerNodeViewCidBeingListenedTo.indexOf(sequencerNodeView.cid);
        if(index > -1){
            this._arrayOfSequencerNodeViewCidBeingListenedTo.splice(index, 1);
        }
        Backbone.View.prototype.stopListening.call(this, sequencerNodeView);
    },

    _stoplisteningToEventsOnParagraphContainerView :function(paragraphContainerView){
        const index = this._arrayOfParagraphContainerViewCidBeingListenedTo.indexOf(paragraphContainerView.cid);
        if(index > -1){
            this._arrayOfParagraphContainerViewCidBeingListenedTo.splice(index, 1);
            delete this._paragraphViewCidToParagraphView[paragraphContainerView.cid];
            if(USE_PARAGRAPH_SUMMARY_TO_INCREASE_RENDERING_SPEED){
                this._paragraphObserver.unobserve(paragraphContainerView.$el.get(0));
            }
        }
        Backbone.View.prototype.stopListening.call(this, paragraphContainerView);
    },

    _isSequencerNodeViewInSequencerNodeContainerViewPort : function(sequencerNodeView, respectTopMargin){
        let isVisibleInViewPort =  true;
        if(sequencerNodeView && sequencerNodeView.$el.get(0).clientWidth !=0 &&  sequencerNodeView.$el.get(0).clientHeight != 0){
            //make sure the element is visible
            const el = sequencerNodeView.$el.get(0);
            const parentEl = this.sequencerNodeContainer$el.get(0);
            //const elemntRelativePosition = Utils.getInstance().getElementRelativePositionFromParent(el);
            const elemntRelativePosition = this._getSequencerNoveViewPositionInContainer(sequencerNodeView);
            if(elemntRelativePosition && (elemntRelativePosition.top >= parentEl.scrollTop && elemntRelativePosition.top < (parentEl.clientHeight + parentEl.scrollTop))){
                //Element is visible
                if(respectTopMargin){
                    if(elemntRelativePosition.top >= (parentEl.clientHeight + parentEl.scrollTop - MARGIN_TOP_WHEN_AUTO_SCROLLING)){
                        isVisibleInViewPort = false;
                    }
                }else{
                    if(elemntRelativePosition.top >= (parentEl.clientHeight + parentEl.scrollTop)){
                        isVisibleInViewPort = false;
                    }
                }
            }else{
                //element is not visible
                isVisibleInViewPort = false;
            }
        }
        return isVisibleInViewPort;
    },


    scrollToSequencerNodeIfneccessary : function(sequencerNodeView, forceScrolling, noAnimation, forceScrollingOnlyIfNotVisible){
        this._sequencerNodeViewCurrentlyHighlightedAsPlaying = sequencerNodeView;
        if(forceScrolling || this.getModel().getAutoScroll()){
            this._scrollToSequencerNode(sequencerNodeView, this._isUserManuallyScrolling && !forceScrolling? false: true, forceScrolling, noAnimation, forceScrollingOnlyIfNotVisible);
        }
    },

    _getSequencerNoveViewPositionInContainer : function(sequencerNodeView){
        const el = sequencerNodeView.$el.get(0);
        const paragraphContainerEl = sequencerNodeView.getParagraphContainerView()? sequencerNodeView.getParagraphContainerView().$el.get(0): null;
        const elemntRelativePositionFromParagraph = Utils.getInstance().getElementRelativePositionFromParent(el);
        const paragraphContainerRelativePosition = Utils.getInstance().getElementRelativePositionFromParent(paragraphContainerEl);
        const elemntRelativePosition = elemntRelativePositionFromParagraph;
        if(paragraphContainerRelativePosition && elemntRelativePositionFromParagraph){
            elemntRelativePosition.top = elemntRelativePositionFromParagraph.top + paragraphContainerRelativePosition.top;
            elemntRelativePosition.bottom = elemntRelativePositionFromParagraph.bottom + paragraphContainerRelativePosition.bottom;
            elemntRelativePosition.left = elemntRelativePositionFromParagraph.left + paragraphContainerRelativePosition.left;
            elemntRelativePosition.right = elemntRelativePositionFromParagraph.right + paragraphContainerRelativePosition.right;
        }
        return elemntRelativePosition;
    },

    _scrollToSequencerNode : function(sequencerNodeView,respectTopMargin, forceScrolling, noAnimation, forceScrollingOnlyIfNotVisible){
        this._scrollTopAnimationOnProgress = (this._scrollTopAnimationOnProgress ?this._scrollTopAnimationOnProgress : RSVP.Promise.resolve()) .then((function(){
            return new RSVP.Promise((function(resolve, reject){
                try{
                    if(forceScrolling ||(forceScrollingOnlyIfNotVisible && !this._isSequencerNodeViewInSequencerNodeContainerViewPort(sequencerNodeView, respectTopMargin))|| (this.model.isPlaying() && !this._isUserManuallyScrolling && !this._isSequencerNodeViewInSequencerNodeContainerViewPort(sequencerNodeView, respectTopMargin))){
                        const elemntRelativePosition =  this._getSequencerNoveViewPositionInContainer(sequencerNodeView);
                        if(elemntRelativePosition){
                            this._pendingScrollAnimationResolve = resolve;
                            this._pendingScrollAnimationReject = resolve;
                            const onAnimationCompleted = (function(){
                                this._pendingScrollAnimation =  false;
                                this._pendingScrollAnimationResolve();
                            }).bind(this);
                            if(elemntRelativePosition.top - MARGIN_TOP_WHEN_AUTO_SCROLLING >= 0){
                                if(noAnimation){
                                    this.sequencerNodeContainer$el.scrollTop( elemntRelativePosition.top - MARGIN_TOP_WHEN_AUTO_SCROLLING );
                                    resolve();
                                }else{
                                    this._pendingScrollAnimation =  true;
                                    this.sequencerNodeContainer$el.animate({"scrollTop": elemntRelativePosition.top - MARGIN_TOP_WHEN_AUTO_SCROLLING}, 400, "swing", onAnimationCompleted );
                                }
                            }else{
                                if(noAnimation){
                                    this.sequencerNodeContainer$el.scrollTop( 0);
                                    resolve();
                                }else{
                                    this._pendingScrollAnimation =  true;
                                    this.sequencerNodeContainer$el.animate({"scrollTop":  0}, 400, "swing", onAnimationCompleted);
                                }
                            }
                        }else{
                    //element is probably not addede to the dom
                            resolve();
                        }

                    }else{
                        resolve();
                    }
                }catch(error){
                    reject(error);
                }
            }).bind(this))
        }).bind(this))
    },

    _deactivateAutoscollOnUserManualScrollIfNecessary : function(){
        if(!this._isSequencerNodeViewInSequencerNodeContainerViewPort(this._sequencerNodeViewCurrentlyHighlightedAsPlaying, false)){
            if(this.model.getAutoScroll()){
                this.model.setAutoScroll(false);
                Utils.getInstance().showInfoNotification(`${window.getI18n(ti18n, "AUTOSCROLL_TURNED_OFF")}`);
            }
        }
    },

    _onTapholdOnWrapNode : function(evt){
        if(this._sortableInstance){
            this.sequencerNodeContainer$el.attr("move_mode", "true");
            this._sortableInstance.option("disabled", false);
            this.unselectCurrentlySelectedSequencerNodes();
            this._sortableInstance._onTapStart(evt);
        }
    },

    _onFileBeingDroppedBeforeSequencerNode : function(fileDropInfo){
        const file = fileDropInfo.file;
        const sequencerNodeViewTarget = fileDropInfo.sequencerNodeViewTarget;
        if(sequencerNodeViewTarget.getModel().isSelected()){
            this.trigger("uploadFileAndWrapSelection",file);
        }else{
            //this.unselectCurrentlySelectedSequencerNodes();
            this.trigger("uploadFileAndInsertBefore",file, sequencerNodeViewTarget.getModel());
        }

    },

    _onFileBeingDroppedAfterSequencerNode : function(fileDropInfo){
        const file = fileDropInfo.file;
        const sequencerNodeViewTarget = fileDropInfo.sequencerNodeViewTarget;
        if(sequencerNodeViewTarget.getModel().isSelected()){
            this.trigger("uploadFileAndWrapSelection",file);
        }else{
            //this.unselectCurrentlySelectedSequencerNodes();
            this.trigger("uploadFileAndInsertAfter",file, sequencerNodeViewTarget.getModel());
        }

    },

    _onFileBeingDroppedAtTheEndOfParagraph : function(fileDropInfo){
        const file = fileDropInfo.file;
        const paragraphContainerView = fileDropInfo.paragraphContainerView;
        if(paragraphContainerView.getLastSequencerNodeView()){
            this.trigger("uploadFileAndInsertAfter",file, paragraphContainerView.getLastSequencerNodeView().getModel());
        }
    },

    _onSequencerNodeBeingDroppedBeforeSequencerNode : function(dropInfo){
        this.unselectCurrentlySelectedSequencerNodes();
        const modelCidOfSequencerNodeBeingDrop = dropInfo.modelCidOfSequencerNodeBeingDrop;
        const sequencerNodeViewTarget = dropInfo.sequencerNodeViewTarget;
        if(modelCidOfSequencerNodeBeingDrop && sequencerNodeViewTarget){
            const sequencerNodeContainerEl = this.sequencerNodeContainer$el.get(0);
            const sequencerNodeMovedView = this.getSequencerNodeViewFromSequencerNodeCid(modelCidOfSequencerNodeBeingDrop);
            const sequencerNodeMovedParagraphContainerView = sequencerNodeMovedView? sequencerNodeMovedView.getParagraphContainerView(): null;
            const sequencerNodeMoved = sequencerNodeMovedView? sequencerNodeMovedView.getModel(): null;
            const newNextSequencerNodeView = sequencerNodeViewTarget;
            const newNextSequencerNode = newNextSequencerNodeView? newNextSequencerNodeView.getModel(): null;
            const newNextSequencerNodeParagraphContainerView = newNextSequencerNodeView? newNextSequencerNodeView.getParagraphContainerView(): null;
            const newPreviousSequencerNode = newNextSequencerNode? newNextSequencerNode.getPrevious(): null;
            const newPreviousSequencerNodeView = newPreviousSequencerNode? this.getSequencerNodeViewFromSequencerNodeCid(newPreviousSequencerNode.cid): null;
            const newPreviousSequencerNodeParagraphContainerView = newPreviousSequencerNodeView? newPreviousSequencerNodeView.getParagraphContainerView(): null;
            const oldIndexInSequencer = sequencerNodeMovedView?Array.from(sequencerNodeContainerEl.children).indexOf(sequencerNodeMovedView.$el.get(0)): -1;
            const newIndexInSequencer =  newNextSequencerNodeView? Array.from(sequencerNodeContainerEl.children).indexOf(newNextSequencerNodeView.$el.get(0)): -1;
            const oldIndeInParagraphContainer = sequencerNodeMovedParagraphContainerView.getSequencerNodeViewIndex(sequencerNodeMovedView);
            const newIndeInParagraphContainer = newNextSequencerNodeParagraphContainerView.getSequencerNodeViewIndex(newNextSequencerNodeView);
            if(!(oldIndeInParagraphContainer == newIndeInParagraphContainer && oldIndexInSequencer == newIndexInSequencer) && sequencerNodeMoved){
                //this._revertToInitialPositionBeforeDrag(sequencerNodeMovedView, oldIndexInSequencer, newIndexInSequencer);
                this.executeDragSequencerNode(sequencerNodeMoved,null,  null, newPreviousSequencerNode, newNextSequencerNode);
            }
        }
    },

    _onSequencerNodeBeingDroppedAtTheEndOfParagraph : function(dropInfo){
        this.unselectCurrentlySelectedSequencerNodes();
        const modelCidOfSequencerNodeBeingDrop = dropInfo.modelCidOfSequencerNodeBeingDrop;
        const paragraphContainerViewTarget = dropInfo.paragraphContainerView;
        if(modelCidOfSequencerNodeBeingDrop && paragraphContainerViewTarget){
            const sequencerNodeContainerEl = this.sequencerNodeContainer$el.get(0);
            const sequencerNodeMovedView = this.getSequencerNodeViewFromSequencerNodeCid(modelCidOfSequencerNodeBeingDrop);
            const sequencerNodeMovedParagraphContainerView = sequencerNodeMovedView? sequencerNodeMovedView.getParagraphContainerView(): null;
            const sequencerNodeMoved = sequencerNodeMovedView? sequencerNodeMovedView.getModel(): null;
            const newPreviousSequencerNodeView = paragraphContainerViewTarget.getLastSequencerNodeView();
            if(newPreviousSequencerNodeView){
                const newPreviousSequencerNode = newPreviousSequencerNodeView? newPreviousSequencerNodeView.getModel(): null;
                const oldIndexInSequencer = Array.from(sequencerNodeContainerEl.children).indexOf(sequencerNodeMovedView.$el.get(0));
                const newIndexInSequencer =  Array.from(sequencerNodeContainerEl.children).indexOf(newPreviousSequencerNodeView.$el.get(0));
                const oldIndeInParagraphContainer = sequencerNodeMovedParagraphContainerView.getSequencerNodeViewIndex(sequencerNodeMovedView);
                const newIndeInParagraphContainer = paragraphContainerViewTarget.getSequencerNodeViewIndex(newPreviousSequencerNodeView);
                if(!(oldIndeInParagraphContainer == newIndeInParagraphContainer && oldIndexInSequencer == newIndexInSequencer) && sequencerNodeMoved){
                    //this._revertToInitialPositionBeforeDrag(sequencerNodeMovedView, oldIndexInSequencer, newIndexInSequencer);
                    this.executeDragSequencerNode(sequencerNodeMoved,null,  null, newPreviousSequencerNode, null);
                }
            }else{
                
            }
        }
    },

    _onSequencerNodeBeingDroppedAfterSequencerNode : function(dropInfo){
        this.unselectCurrentlySelectedSequencerNodes();
        const modelCidOfSequencerNodeBeingDrop = dropInfo.modelCidOfSequencerNodeBeingDrop;
        const sequencerNodeViewTarget = dropInfo.sequencerNodeViewTarget;
        if(modelCidOfSequencerNodeBeingDrop){
            const sequencerNodeContainerEl = this.sequencerNodeContainer$el.get(0);
            const sequencerNodeMovedView = this.getSequencerNodeViewFromSequencerNodeCid(modelCidOfSequencerNodeBeingDrop);
            const sequencerNodeMoved = sequencerNodeMovedView? sequencerNodeMovedView.getModel(): null;
            //const newNextSequencerNodeView = null;
            const newNextSequencerNode = null;
            const newPreviousSequencerNode = sequencerNodeViewTarget.getModel();
            const newPreviousSequencerNodeView = sequencerNodeViewTarget;
            if(newPreviousSequencerNodeView){
                const oldIndexInSequencer = Array.from(sequencerNodeContainerEl.children).indexOf(sequencerNodeMovedView.$el.get(0));
                const newIndexInSequencer =  Array.from(sequencerNodeContainerEl.children).indexOf(newPreviousSequencerNodeView.$el.get(0)) + 1;
                if(oldIndexInSequencer != newIndexInSequencer && sequencerNodeMoved){
                    this._revertToInitialPositionBeforeDrag(sequencerNodeMovedView, oldIndexInSequencer, newIndexInSequencer);
                    this.executeDragSequencerNode(sequencerNodeMoved,oldIndexInSequencer,  newIndexInSequencer, newPreviousSequencerNode, newNextSequencerNode);
                }
            }
        }
    },


    _onSequencerNodeBeingDroppedAtTheEnd : function(modelCidOfSequencerNodeBeingDrop){
        this.unselectCurrentlySelectedSequencerNodes();
        if(modelCidOfSequencerNodeBeingDrop){
            const sequencerNodeContainerEl = this.sequencerNodeContainer$el.get(0);
            const sequencerNodeMovedView = this.getSequencerNodeViewFromSequencerNodeCid(modelCidOfSequencerNodeBeingDrop);
            const sequencerNodeMoved = sequencerNodeMovedView? sequencerNodeMovedView.getModel(): null;
            //const newNextSequencerNodeView = null;
            const newNextSequencerNode = null;
            const newPreviousSequencerNode = this.model.getLastNode();
            const newPreviousSequencerNodeView = newPreviousSequencerNode? this.getSequencerNodeViewFromSequencerNodeCid(newPreviousSequencerNode.cid): null;
            if(newPreviousSequencerNodeView){
                const oldIndexInSequencer = Array.from(sequencerNodeContainerEl.children).indexOf(sequencerNodeMovedView.$el.get(0));
                const newIndexInSequencer =  Array.from(sequencerNodeContainerEl.children).indexOf(newPreviousSequencerNodeView.$el.get(0)) + 1;
                if(oldIndexInSequencer != newIndexInSequencer && sequencerNodeMoved){
                    this._revertToInitialPositionBeforeDrag(sequencerNodeMovedView, oldIndexInSequencer, newIndexInSequencer);
                    this.executeDragSequencerNode(sequencerNodeMoved,oldIndexInSequencer,  newIndexInSequencer, newPreviousSequencerNode, newNextSequencerNode);
                }
            }
        }
    },

    
    _onFileInsertedAtTheEnd : function(file){
        if(this.model.getLastNode.isSelected()){
            this.trigger("uploadFileAndWrapSelection",file);
        }else{
            this.trigger("uploadFileAndInsertAtTheEnd",file);
        }
    },

    _onSequencerNodeViewHiddenChange: function(sequencerNodeView){
        this._onSequencerNodeHiddenChange(sequencerNodeView.getModel());
    },

    _onSequencerNodeHiddenChange: function(sequencerNode){
        let sequencerNodeView =  this.getSequencerNodeViewFromSequencerNode(sequencerNode);
        if(!sequencerNodeView){
            sequencerNodeView = this._createAndRenderSequencerNodeView(sequencerNode);
        }
        this._listenToEventsOnNodeView(sequencerNodeView);
        if(sequencerNode.isHidden()){
            sequencerNodeView.$el.remove();
            const nodeIndex = this._sequencerNodeElArray.indexOf(sequencerNodeView.$el.get(0));
            this._sequencerNodeElArray.splice(nodeIndex, 1);
        }else{
            sequencerNodeView.delegateEvents();
            const previousNode = sequencerNode.getPrevious();
            const previousNodeView = this.getSequencerNodeViewFromSequencerNode(previousNode);
            if(previousNodeView){
                sequencerNodeView.$el.insertAfter(previousNodeView.$el);
                const previousNodeIndex = this._sequencerNodeElArray.indexOf(previousNodeView.$el.get(0));
                this._sequencerNodeElArray.splice(previousNodeIndex+ 1, 0, sequencerNodeView.$el.get(0));
            }else{
                //this is probably the first node
                this.sequencerNodeContainer$el.prepend(sequencerNodeView.$el);
                this._sequencerNodeElArray.splice(0, 0, sequencerNodeView.$el.get(0));
            }
        }
    },

    _onSequencerNodeVisible : function(entries){
        entries.forEach((function(entry){
            if(entry.isIntersecting && entry.intersectionRatio > 0){
                entry.target.style.visibility = "visible";	
            }else{
                entry.target.style.visibility = "hidden";
            }
        }).bind(this));
    },

    _onSequencerNodeNotVisible : function(entries){
        entries.forEach((function(entry){
            if(entry.isIntersecting && entry.intersectionRatio > 0){
                entry.target.style.visibility = "visible";
            }else{
                entry.target.style.visibility = "hidden";
            }
        }).bind(this));
    },

    _setupVisibilityObservers: function(){

        this._sequencerNodeVisibleObserver = new IntersectionObserver(this._onSequencerNodeVisible.bind(this), {
            root: this.sequencerNodeContainer$el.get(0),
            rootMargin: "0px",
            threshold: 1.0
        });

        this._sequencerNodeHiddenObserver = new IntersectionObserver(this._onSequencerNodeNotVisible.bind(this), {
            root: this.sequencerNodeContainer$el.get(0),
            rootMargin: "0px",
            threshold: 0
        });


    },

    _onSequencerNodeAddedToSequencer: function(sequencerNode){
        let sequencerNodeView =  this.getSequencerNodeViewFromSequencerNode(sequencerNode);
        if(!sequencerNodeView){
            sequencerNodeView = this._createAndRenderSequencerNodeView(sequencerNode);
        }
        this._listenToEventsOnNodeView(sequencerNodeView);

        sequencerNodeView.delegateEvents();
        const previousNode = sequencerNode.getPrevious();
        const previousNodeView = this.getSequencerNodeViewFromSequencerNode(previousNode);
        this._addSequencerNodeView(sequencerNodeView, sequencerNode);
        if(previousNodeView){
            //sequencerNodeView.$el.insertAfter(previousNodeView.$el);
            const previousNodeIndex = this._sequencerNodeElArray.indexOf(previousNodeView.$el.get(0));
            this._sequencerNodeElArray.splice(previousNodeIndex+ 1, 0, sequencerNodeView.$el.get(0));
        }else{
            //this is probably the first node
            //this.sequencerNodeContainer$el.prepend(sequencerNodeView.$el);
            this._sequencerNodeElArray.splice(0, 0, sequencerNodeView.$el.get(0));
        }
        //this._sequencerNodeVisibleObserver.observe(sequencerNodeView.$el.get(0));
        //this._sequencerNodeHiddenObserver.observe(sequencerNodeView.$el.get(0));

    },

    _removeSequencerNodeViewFromParagraph : function(sequencerNodeView, keepAllListenersAttached){
        const paragraphContainerView = sequencerNodeView.getParagraphContainerView();
        if(paragraphContainerView){
            paragraphContainerView.removeSequencerNodeView(sequencerNodeView, keepAllListenersAttached);
            if(paragraphContainerView.isEmpty()){
                const paragraphContainerViewIndex = this._arrayOfParagraphContainerViews.indexOf(paragraphContainerView);
                paragraphContainerView.$el.remove();
                this._arrayOfParagraphContainerViews.splice(paragraphContainerViewIndex, 1);
                this._stoplisteningToEventsOnParagraphContainerView(paragraphContainerView)
            }
        }
        
    },


    _onSequencerNodeRemovedToSequencer: function(sequencerNode){
        let sequencerNodeView =  this.getSequencerNodeViewFromSequencerNode(sequencerNode);
        if(!sequencerNodeView){
            sequencerNodeView = this._createAndRenderSequencerNodeView(sequencerNode);
        }
        this._listenToEventsOnNodeView(sequencerNodeView);
        
        //sequencerNodeView.$el.remove();
        this._removeSequencerNodeViewFromParagraph(sequencerNodeView, true)
        this._stoplisteningToEventsOnNodeView(sequencerNodeView);
        const nodeIndex = this._sequencerNodeElArray.indexOf(sequencerNodeView.$el.get(0));
        this._sequencerNodeElArray.splice(nodeIndex, 1);
        if(this._arrayOfSelectedSequencerNodeModels.indexOf(sequencerNode) != -1){
            //if sequencer node being remove is being selected, clear selection
            this.unselectCurrentlySelectedSequencerNodes();
        }
        
    },

    _initPlayerSlider : function(){
        this._noUiSliderObj = noUiSlider.create(this._playerSliderEl, {
            start: 0,
            connect: [true, false],
            behaviour :"tap-drag",
            orientation: 'horizontal', // 'horizontal' or 'vertical'
            range: {
                'min': 0,
                'max': 100
            },
            tooltips: false,
            format: {
                to: this._getSliderToFormat.bind(this),
                from: this._getSliderFromFormat.bind(this)
            }
        });
        this._playerSliderEl.noUiSlider.on('change',this._onUserSliderPosition.bind(this));
        this._playerSliderEl.noUiSlider.on('start',this._onUserStartedDraggingSlider.bind(this));
        this._playerSliderEl.noUiSlider.on('slide',this._onUserStartedDraggingSlider.bind(this));
        this._playerSliderEl.noUiSlider.on('end',this._onUserStoppedDraggingSlider.bind(this));
        this._playerSliderEl.noUiSlider.on('update',this._onUserStoppedDraggingSlider.bind(this));
    },

    _onUserStartedDraggingSlider : function(){
        this._playerSliderEl._isUserDragged = true;
    },

    _onUserStoppedDraggingSlider : function(){
        this._playerSliderEl._isUserDragged = false;
    },

    _onUserSliderPosition: function(formatedValues, handleNumber, unformattedValues ){
        const newValue = unformattedValues && unformattedValues.length > 0? unformattedValues[0]: this._playerSliderEl.noUiSlider.get();
        const timeInMilliseconds  = newValue * this.model.getDurationInMilliseconds()/ 100;
        this.trigger("updatePlaybackPositionInMilliseconds", timeInMilliseconds);
    },

    _getSliderToFormat : function(value){
        if(this.model && this.model.getDurationInMilliseconds()){
            return Utils.getInstance().formatPlaybackPositionToString(value * this.model.getDurationInMilliseconds()/ 100);
        }else{
            return " ";
        }
    },

    _getSliderFromFormat : function(value){
        return parseFloat(value);
    },


    _onRequestToRevertDeleteNodeCreation : function(sequencerNodeView){
        if(sequencerNodeView){
            this.trigger("revertDeleteNodeCreated", sequencerNodeView.getModel());
        }
    },

    _destroyContextMenuDisplayed: function(destroySubMenusOnly){
        if(!destroySubMenusOnly){
            if(DO_NOT_USE_TIPPYJS_MENU){
                this._destroyContextMenuDisplayedNew();
            }else{
                if(this._currentlyDisplayedTippyInstance){
                    this._currentlyDisplayedTippyInstance.destroy();
                    this._currentlyDisplayedTippyInstance = null;
                    this._destroyTippyTooltipDisplayed();
                    this.trigger("onContextMenuDestroyed");
                    
                }
            }
        }
        if(this._currentInsertElementContextMenuDisplayedInstance){
            this.destroyInsertElementContextMenuDisplayedInstance();
        }
        if(this._currentDisplayedCorrectTranscriptsContextMenuInstance){
            this._destroyContextMenuCorrectTranscriptDisplayed();
        }

        if(this._speakerContextMenuTippyJSInstanceDisplayed){
            this._destroySpeakerContextMenuIfApplicable();
        }
        if(destroySubMenusOnly){
            this.setSubContextMenuView(null);
        }
    },

    isCorrectTranscriptionContextMenuDisplayed : function(){
        return !!this._currentDisplayedCorrectTranscriptsContextMenuInstance;
    },

    _destroyContextMenuCorrectTranscriptDisplayed: function(){
        if(this._currentDisplayedCorrectTranscriptsContextMenuInstance){
            this._currentDisplayedCorrectTranscriptsContextMenuInstance.mouseTrapObj.unbind('enter');
            this._currentDisplayedCorrectTranscriptsContextMenuInstance.destroy();
            this._currentDisplayedCorrectTranscriptsContextMenuInstance = null;
            this.trigger("onCorrectTranscriptContextMenuDestroyed");

        }
    },

    isContextMenuDisplayed: function(){
        return !!this._currentlyDisplayedTippyInstance;
    },

    _destroyContextMenuDisplayedNew: function(){
        if(this._currentlyDisplayedContextMenuInstance){
            this._currentlyDisplayedContextMenuInstance.close();
            this._currentlyDisplayedContextMenuInstance = null;
            this.trigger("onContextMenuDestroyed");
        }
    },

    isOnboardingDisplayed : function(){
        return !!Shepherd.activeTour;
    },


    onIgnoreSelectedNodesBtnClicked: function(event){
        if(!this.isOnboardingDisplayed()){
            if(!(this.model.getSearchWidget().isSearchOn() && this.searchInput$el.get(0) === document.activeElement)){
                this._destroyContextMenuDisplayed();
                this.trigger("ignoreSelectedNodes");
                if(event){
                    event.stopPropagation();
                }
            }
        }
    },

    setTranscribedAudioBeingAdded : function(transcribedAudioBeingAdded){
        this._transcribedAudioBeingAdded = transcribedAudioBeingAdded;
        this.$el.attr("transcribedAudioBeingAdded", transcribedAudioBeingAdded?"true": "false");
    },

    onWrapWithMusicBtnClicked: function(event){
        if(!this.isOnboardingDisplayed()){
            this._destroyContextMenuDisplayed();
            this.trigger("wrapSelectedNodesWithMusic");
            if(event){
                event.stopPropagation();
            }
        }	
    },

    onPlayFromBtnClicked: function(event){
        this._destroyContextMenuDisplayed();
        this.trigger("playFrom");
        if(event){
            event.stopPropagation();
        }
    },

    onTranscribeSequencerNodeBtnClicked: function(event){
        this._destroyContextMenuDisplayed();
        this.trigger("transcribedSequencerNode");
        if(event){
            event.stopPropagation();
        }
    },


    onViewSettingForMusicWrapperBtnClicked: function(event){
        this._destroyContextMenuDisplayed();
        this.trigger("showSettingsForMusicWrapper");
        if(event){
            event.stopPropagation();
        }
    },

    onDeleteMusicWrapperBtn: function(event){
        this._destroyContextMenuDisplayed();
        this.trigger("deleteMusicWrapper");
        if(event){
            event.stopPropagation();
        }
    },

    setHeightMargin : function(heightMarginInPx){
        this._heightMarginInPx =  heightMarginInPx;
        this.onOrientationChangeFunction(PersistentModels.getInstance().getRouter().getViewportHeight(), true);
    },

    unselectCurrentlySelectedSequencerNodes : function(){
        for(let i = 0; i < this._arrayOfSelectedSequencerNodeModels.length; i++){
            const selectedNode = this._arrayOfSelectedSequencerNodeModels[i];
            selectedNode.setSelected(false);
        }
        this._arrayOfSelectedSequencerNodeModels = [];
        this._selectedNodeInfo = null;
        this._currentBrowserNativeSelection = null;
        this._onSelectionChange();
        this._destroyContextMenuDisplayed();
    },

    reselectCurrentlySelectedSequencerNodes: function(){
        const sectionNode = this.getSelectionNode();
        if(sectionNode && sectionNode.selectionStartAnchorNodeView && sectionNode.selectionEndFocusNodeView){
            const selectionStartAnchorNodeView = sectionNode.selectionStartAnchorNodeView;
            const selectionEndFocusNodeView = sectionNode.selectionEndFocusNodeView;
            this.unselectCurrentlySelectedSequencerNodes();
            this._markCurrentlySelectedNodesAsSelectedFromSelectionInfo(this._selectSequencerNodeRangeWithCid(selectionStartAnchorNodeView.model.cid, selectionEndFocusNodeView.model.cid));
            this._showContextMenuUsingSelectedSequencerNodes();
        }
    },

    _clearBrowserSelection : function(){
        if (window.getSelection) {window.getSelection().removeAllRanges();}
        else if (document.selection) {document.selection.empty();}
    },

    _getSequencerNodeEl : function(element){
        let elementToCheck  = element;
        while(elementToCheck){
            if(elementToCheck && elementToCheck.classList && elementToCheck.classList.contains('sequencer_node')){
                return elementToCheck;
            }else{
                elementToCheck = elementToCheck.parentElement;
            }
        }
        return null;
    },

    _getSelectionInfoUsingTap : function(event){
        if(!this.isClickOccuredOnContextMenu(event) && !this.isClickOccuredOnSpeakerContextMenu(event)){
            const sequencerNodeEl  = this._getSequencerNodeEl(event.target);
            if(sequencerNodeEl){
                const sequencerNodeElCid = sequencerNodeEl.getAttribute("segment_node_cid");
                const sequencerNodeView = this._sequencerNodeCidToSequencerNodeViewMap[sequencerNodeElCid];
                if(sequencerNodeView){
                    const sequencerNodeModel = sequencerNodeView.getModel();
                    return {
                        "startSequencerNodeModel": sequencerNodeModel,
                        "startSequencerNodeView":sequencerNodeView,
                        "endSequencerNodeModel":sequencerNodeModel ,
                        "endSequencerNodeView": sequencerNodeView,
                        "selectionStartAnchorNodeView":sequencerNodeView,
                        "selectionEndFocusNodeView":sequencerNodeView
                    };
                }
            }
        }
    },

    _getSequencerNodePosition : function(sequencerNodeModel){
        const sequencerNodeView = this.getSequencerNodeViewFromSequencerNode(sequencerNodeModel);
        if(sequencerNodeView){
            if(this.model.isSequencerNodeVisible(sequencerNodeModel)){
                //return Utils.getInstance().getElementRelativePositionFromParent(sequencerNodeView.$el.get(0));
                return this._getSequencerNoveViewPositionInContainer(sequencerNodeView);
            }else{
                const previousVisibleNode  = sequencerNodeModel.getPreviousNodeMatchingCondition((function(node){
                    return this.model.isSequencerNodeVisible(node);
                }).bind(this));
                if(previousVisibleNode){
                    const previousVisibleNodeView = this.getSequencerNodeViewFromSequencerNode(previousVisibleNode);
                    //return Utils.getInstance().getElementRelativePositionFromParent(previousVisibleNodeView.$el.get(0));
                    return this._getSequencerNoveViewPositionInContainer(previousVisibleNodeView);
                }else{
                    const nextVisibleNode  = sequencerNodeModel.getNextNodeMatchingCondition((function(node){
                        return this.model.isSequencerNodeVisible(node);
                    }).bind(this));
                    if(nextVisibleNode){
                        const nextVisibleNodeView = this.getSequencerNodeViewFromSequencerNode(nextVisibleNode);
                        //return Utils.getInstance().getElementRelativePositionFromParent(nextVisibleNodeView.$el.get(0));
                        return this._getSequencerNoveViewPositionInContainer(nextVisibleNodeView);
                    }
                }
            }
        }
        return null;
    },


    _getClosestSequencerNodeUp : function(sequencerNodeModel){
        const elemntRelativePosition = this._getSequencerNodePosition(sequencerNodeModel);
        const getSequencerNodeViewFromSequencerNodeFunction = this.getSequencerNodeViewFromSequencerNode.bind(this);
        const getSequencerNodePositionFunction = this._getSequencerNodePosition.bind(this);
        const errorMargin = 5;
        const lastSequencerNodeOnThePreviousLine  =	sequencerNodeModel.getPreviousNodeMatchingCondition((function(node){
            const pos = getSequencerNodePositionFunction(node);
            if(pos){
                return !this._isElementOnSameYPosition(pos, elemntRelativePosition, errorMargin);
            }
            return false;
        }).bind(this));
        const positionOfLastSequencerNodeOnThePreviousLine = this._getSequencerNodePosition(lastSequencerNodeOnThePreviousLine);
        const allSequencerNodesOnThePreviousLine =  [];
        let nodeToIterate = lastSequencerNodeOnThePreviousLine;
        let posYDifferent = false;
        while(nodeToIterate && !posYDifferent){
            const pos = this._getSequencerNodePosition(nodeToIterate);
            if(!pos || !this._isElementOnSameYPosition(pos, positionOfLastSequencerNodeOnThePreviousLine, errorMargin)){
                posYDifferent = true;
            }else{
                allSequencerNodesOnThePreviousLine.push(nodeToIterate);
                nodeToIterate = nodeToIterate.getPreviousVisibleSequencerNode();
            }
        }
        const clientX = elemntRelativePosition.left;
        const clientY = elemntRelativePosition.top;
        return this._getElementWithClosestPosition(clientX, clientY, allSequencerNodesOnThePreviousLine);
    },

    _getElementWithClosestPosition : function(clientX, clientY, arrayOfSequencerNodes){
        let closestNode =  null;
        let closestNodeDistance = null;
        if(arrayOfSequencerNodes && arrayOfSequencerNodes.length > 0){
            arrayOfSequencerNodes.map((function( node, index){
                if(node){
                    const boundingClientRect = this._getSequencerNodePosition(node)
                    if(boundingClientRect){
                        const a = boundingClientRect.left - clientX;
                        const b = boundingClientRect.top - clientY;
                        
                        const distance = Math.sqrt( a*a + b*b );
                        if(!closestNode || distance < closestNodeDistance){
                            closestNode =  node;
                            closestNodeDistance = distance;
                        }
                        
                    }
                }

            }).bind(this));
        }
        return closestNode;
    },

    _isElementOnSameYPosition : function(elPosA, elPosB, errorMargin){
        if(!elPosA || !elPosB){
            return false;
        }
        if(!errorMargin){
            errorMargin = 0
        }
        return Math.abs(elPosA.top - elPosB.top) <= errorMargin;
    },

    _getClosestSequencerNodeDown : function(sequencerNodeModel){
        const elemntRelativePosition = this._getSequencerNodePosition(sequencerNodeModel);
        if(elemntRelativePosition){
            const getSequencerNodeViewFromSequencerNodeFunction = this.getSequencerNodeViewFromSequencerNode.bind(this);
            const getSequencerNodePositionFunction = this._getSequencerNodePosition.bind(this);
            const errorMargin = 5;
            const firstSequencerNodeOnTheNextLine  =	sequencerNodeModel.getNextNodeMatchingCondition((function(node){
                const pos = getSequencerNodePositionFunction(node)
                if(pos){
                    return !this._isElementOnSameYPosition(pos, elemntRelativePosition, errorMargin);
                }
                return false;
            }).bind(this));
            const positionOfFirstSequencerNodeOnTheNextLine = this._getSequencerNodePosition(firstSequencerNodeOnTheNextLine);
            const allSequencerNodesOnTheNextLine =  [];
            let nodeToIterate = firstSequencerNodeOnTheNextLine;
            let posYDifferent = false;
            while(nodeToIterate && !posYDifferent){
                const pos = this._getSequencerNodePosition(nodeToIterate);
                if(!pos || !this._isElementOnSameYPosition(pos, positionOfFirstSequencerNodeOnTheNextLine, errorMargin)){
                    posYDifferent = true;
                }else{
                    allSequencerNodesOnTheNextLine.push(nodeToIterate);
                    nodeToIterate = nodeToIterate.getNextVisibleSequencerNode();
                }
            }

            const clientX = elemntRelativePosition.left;
            const clientY = elemntRelativePosition.top;
            const closestNodeDistance = null;
            return this._getElementWithClosestPosition(clientX, clientY, allSequencerNodesOnTheNextLine);
        }else{
            return null;
        }
    },

    getNextVisibleSequencerNodeUpAfterSelection : function(){
        if(this._selectedNodeInfo){
            const sequencerNodeModel  = this._selectedNodeInfo.endSequencerNodeModel;
            if(sequencerNodeModel){
                return this._getClosestSequencerNodeUp(sequencerNodeModel);
                
            }else{
                return null;
            }
        }else{
            return this.model.getFirstNode();
        }
    },


    getNextVisibleSequencerNodeDownAfterSelection : function(){
        if(this._selectedNodeInfo){
            const sequencerNodeModel  = this._selectedNodeInfo.endSequencerNodeModel;
            if(sequencerNodeModel){
                return this._getClosestSequencerNodeDown(sequencerNodeModel);
            }else{
                return null;
            }
        }else{
            return this.model.getFirstNode();
        }
    },

    getNextVisibleSequencerNodeAfterSelection : function(){
        if(this._selectedNodeInfo){
            const endSequencerNodeModel  = this._selectedNodeInfo.endSequencerNodeModel;
            return endSequencerNodeModel.getNextVisibleSequencerNode();
        }else{
            return this.model.getFirstNode();
        }
    },

    getPreviousVisibleSequencerNodeAfterSelection : function(){
        if(this._selectedNodeInfo){
            const startSequencerNodeModel  = this._selectedNodeInfo.startSequencerNodeModel;
            return startSequencerNodeModel.getPreviousVisibleSequencerNode();
        }else{
            return this.model.getFirstNode();
        }
    },

    _updateSelectionInfoUsingTap : function(event){
        if(!this.isClickOccuredOnContextMenu(event) && !this.isClickOccuredOnSpeakerContextMenu(event)){
            const sequencerNodeEl  = this._getSequencerNodeEl(event.target);
            if(sequencerNodeEl){
                return this._selectSequencerNodeWithCid(sequencerNodeEl.getAttribute("segment_node_cid"))
                /*this.unselectCurrentlySelectedSequencerNodes();
                const sequencerNodeElCid = sequencerNodeEl.getAttribute("segment_node_cid");
                const sequencerNodeView = this._sequencerNodeCidToSequencerNodeViewMap[sequencerNodeElCid];
                if(sequencerNodeView){
                    const sequencerNodeModel = sequencerNodeView.getModel();
                    this._selectedNodeInfo = {
                        "startSequencerNodeModel": sequencerNodeModel,
                        "startSequencerNodeView":sequencerNodeView,
                        "endSequencerNodeModel":sequencerNodeModel ,
                        "endSequencerNodeView": sequencerNodeView,
                        "selectionStartAnchorNodeView":sequencerNodeView,
                        "selectionEndFocusNodeView":sequencerNodeView
                    };
                    this._onSelectionChange();
                    return this._selectedNodeInfo;
                }*/
            }
        }
    },

    _selectSequencerNodeWithCid :function(sequencerNodeCid){
        if(sequencerNodeCid){
            this.unselectCurrentlySelectedSequencerNodes();
            const sequencerNodeView = this._sequencerNodeCidToSequencerNodeViewMap[sequencerNodeCid];
            if(sequencerNodeView){
                const sequencerNodeModel = sequencerNodeView.getModel();
                this._selectedNodeInfo = {
                    "startSequencerNodeModel": sequencerNodeModel,
                    "startSequencerNodeView":sequencerNodeView,
                    "endSequencerNodeModel":sequencerNodeModel ,
                    "endSequencerNodeView": sequencerNodeView,
                    "selectionStartAnchorNodeView":sequencerNodeView,
                    "selectionEndFocusNodeView":sequencerNodeView
                };
                this._onSelectionChange();
                return this._selectedNodeInfo;
            }
        }

    },

    _selectSequencerNodeRangeWithCid :function(startSequencerNodeCid, endSequencerNodeCid){
        if(startSequencerNodeCid && endSequencerNodeCid){
            this.unselectCurrentlySelectedSequencerNodes();

            const startSequencerNodeView = this._sequencerNodeCidToSequencerNodeViewMap[startSequencerNodeCid];
            const endSequencerNodeView = this._sequencerNodeCidToSequencerNodeViewMap[endSequencerNodeCid];
            const selectionStartNodeElIndex = startSequencerNodeView? this._sequencerNodeElArray.indexOf(startSequencerNodeView.$el.get(0)): -1;
            const selectionEndNodeElIndex = endSequencerNodeView? this._sequencerNodeElArray.indexOf(endSequencerNodeView.$el.get(0)): -1;
            let startSequencerNodeModel = null;
            let endSequencerNodeModel = null;
            let startSequencerNodeViewToReturn = null;
            let endSequencerNodeViewToReturn = null;
            if(selectionEndNodeElIndex > selectionStartNodeElIndex){
                endSequencerNodeModel = endSequencerNodeView.getModel();
                startSequencerNodeModel = startSequencerNodeView.getModel();
                startSequencerNodeViewToReturn = startSequencerNodeView;
                endSequencerNodeViewToReturn = endSequencerNodeView;
            }else{
                endSequencerNodeModel = startSequencerNodeView.getModel();
                startSequencerNodeModel = endSequencerNodeView.getModel();
                startSequencerNodeViewToReturn = endSequencerNodeView;
                endSequencerNodeViewToReturn = startSequencerNodeView;

            }
            this._selectedNodeInfo = {"startSequencerNodeModel": startSequencerNodeModel,
            "startSequencerNodeView":startSequencerNodeViewToReturn,
            "endSequencerNodeModel":endSequencerNodeModel ,
            "endSequencerNodeView": endSequencerNodeViewToReturn,
            "selectionStartAnchorNodeView":startSequencerNodeView,
            "selectionEndFocusNodeView":endSequencerNodeView
        };
        this._onSelectionChange();
        return this._selectedNodeInfo;
    }

},

_getCurrentBrowserSelectionParsedOnFirefox: function(){
    const selection = window.getSelection();
    const focusNode = this._getSequencerNodeEl(selection.focusNode);
    let allSelected  = [];
    
    const wordsSelectedArray = window.getSelection().toString().match(/\b([\w'-]+)\b/gim);
    if(wordsSelectedArray && wordsSelectedArray.length > 0){
        allSelected = allSelected.concat(wordsSelectedArray);
    }
    const expliciteltDisplayedPauses = window.getSelection().toString().match(/■/gim);
    if(expliciteltDisplayedPauses && expliciteltDisplayedPauses.length  > 0){
        allSelected = allSelected.concat(expliciteltDisplayedPauses);
    }
    const nonExpliciteltDisplayedPauses = window.getSelection().toString().match(/□/gim);
    if(nonExpliciteltDisplayedPauses && nonExpliciteltDisplayedPauses.length  > 0){
        allSelected = allSelected.concat(nonExpliciteltDisplayedPauses);
    }
    
    if(!allSelected || allSelected.length === 0){
        if(selection.focusNode === selection.anchorNode){
        const sequencerNodeView = this._getSequencerNodeViewFromEl(focusNode);
        if(sequencerNodeView){
            const sequencerNodeModel = sequencerNodeView.getModel();
            return {
                "startSequencerNodeModel": sequencerNodeModel,
                "startSequencerNodeView":sequencerNodeView,
                "endSequencerNodeModel":sequencerNodeModel ,
                "endSequencerNodeView": sequencerNodeView,
                "selectionStartAnchorNodeView":sequencerNodeView,
                "selectionEndFocusNodeView":sequencerNodeView
            };
        }
    }
    return this._selectedNodeInfo;
}
if(focusNode){
    const focusNodeText = focusNode.textContent;
    if(focusNodeText){
            //const isFocusNodeLastNode = focusNodeText.includes(allSelected[allSelected.length-1])? true: false;
            const isFocusNodeLastNode = selection.anchorNode === this.sequencerNodeContainer$el.get(0)? false: true; //This seems to be the way to accuretaly distinguish the direction of the selection
            let startSequencerNodeViewToReturn = null;
            let startSequencerNodeModel = null;
            let endSequencerNodeModel = null;
            let endSequencerNodeViewToReturn = null;
            if(isFocusNodeLastNode){
                endSequencerNodeViewToReturn = this._getSequencerNodeViewFromEl(focusNode);
                if(!endSequencerNodeViewToReturn){
                    return this._selectedNodeInfo;
                }
                endSequencerNodeModel = endSequencerNodeViewToReturn.getModel();
                startSequencerNodeModel = endSequencerNodeModel;

                for(let i = 0; i < allSelected.length - 1;i++){
                    const previousNode = startSequencerNodeModel.getPreviousNodeMatchingCondition((function(node){
                        return Utils.getInstance().isWordSequencerNodeInstance(node) || Utils.getInstance().isPauseSequencerNodeInstance(node);
                    }).bind(startSequencerNodeModel));
                    if(previousNode){
                        startSequencerNodeModel = previousNode;
                    }else{
                        break;
                    }
                }
                startSequencerNodeViewToReturn = startSequencerNodeModel?this.getSequencerNodeViewFromSequencerNode(startSequencerNodeModel): null;
                if(startSequencerNodeViewToReturn && endSequencerNodeViewToReturn){
                    return  {
                        "startSequencerNodeModel": startSequencerNodeModel,
                        "startSequencerNodeView":startSequencerNodeViewToReturn,
                        "endSequencerNodeModel":endSequencerNodeModel ,
                        "endSequencerNodeView": endSequencerNodeViewToReturn,
                        "selectionStartAnchorNodeView":startSequencerNodeViewToReturn,
                        "selectionEndFocusNodeView":endSequencerNodeViewToReturn
                    };
                }
            }else{

                startSequencerNodeViewToReturn = this._getSequencerNodeViewFromEl(focusNode);
                startSequencerNodeModel = startSequencerNodeViewToReturn.getModel();
                endSequencerNodeModel = startSequencerNodeModel;

                for(let u = 0; u < allSelected.length - 1; u++){
                    const nextNode = endSequencerNodeModel.getNextNodeMatchingCondition((function(node){
                        return Utils.getInstance().isWordSequencerNodeInstance(node) || Utils.getInstance().isPauseSequencerNodeInstance(node);
                    }).bind(endSequencerNodeModel));
                    if(nextNode){
                        endSequencerNodeModel = nextNode;
                    }else{
                        break;
                    }
                }
                endSequencerNodeViewToReturn = endSequencerNodeModel?this.getSequencerNodeViewFromSequencerNode(endSequencerNodeModel): null;
                if(startSequencerNodeViewToReturn && endSequencerNodeViewToReturn){
                    return  {
                        "startSequencerNodeModel": startSequencerNodeModel,
                        "startSequencerNodeView":startSequencerNodeViewToReturn,
                        "endSequencerNodeModel":endSequencerNodeModel ,
                        "endSequencerNodeView": endSequencerNodeViewToReturn,
                        "selectionStartAnchorNodeView":startSequencerNodeViewToReturn,
                        "selectionEndFocusNodeView":endSequencerNodeViewToReturn
                    };
                }

            }
        }
    }

    return this._selectedNodeInfo;
},

_isFirefoxBrowser : function(){
    return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
},


_getCurrentBrowserSelectionParsed: function(){
    if(this._isFirefoxBrowser()){
        return this._getCurrentBrowserSelectionParsedOnFirefox();
    }
    const selection = window.getSelection();
    const anchorNode = this._getSequencerNodeEl(selection.anchorNode);
    const focusNode = this._getSequencerNodeEl(selection.focusNode);
    if(selection && anchorNode  && focusNode && Utils.getInstance().isAncestor(this.sequencerNodeContainer$el.get(0), anchorNode)){
        const selectionStartNodeEl = anchorNode;
        const selectionStartNodeElIndex = this._sequencerNodeElArray.indexOf(selectionStartNodeEl);
        const selectionEndNodeEl = focusNode;
        const selectionEndNodeElIndex = this._sequencerNodeElArray.indexOf(selectionEndNodeEl);
        const startSequencerNodeCid = selectionStartNodeEl.getAttribute("segment_node_cid");
        const endSequencerNodeCid = selectionEndNodeEl.getAttribute("segment_node_cid");
        const startSequencerNodeView = this._sequencerNodeCidToSequencerNodeViewMap[startSequencerNodeCid];
        const endSequencerNodeView = this._sequencerNodeCidToSequencerNodeViewMap[endSequencerNodeCid];
        let startSequencerNodeModel = null;
        let endSequencerNodeModel = null;
        let startSequencerNodeViewToReturn = null;
        let endSequencerNodeViewToReturn = null;
        if(selectionEndNodeElIndex > selectionStartNodeElIndex){
            endSequencerNodeModel = endSequencerNodeView.getModel();
            startSequencerNodeModel = startSequencerNodeView.getModel();
            startSequencerNodeViewToReturn = startSequencerNodeView;
            endSequencerNodeViewToReturn = endSequencerNodeView;
        }else{
            endSequencerNodeModel = startSequencerNodeView.getModel();
            startSequencerNodeModel = endSequencerNodeView.getModel();
            startSequencerNodeViewToReturn = endSequencerNodeView;
            endSequencerNodeViewToReturn = startSequencerNodeView;

        }
        return  {"startSequencerNodeModel": startSequencerNodeModel,
        "startSequencerNodeView":startSequencerNodeViewToReturn,
        "endSequencerNodeModel":endSequencerNodeModel ,
        "endSequencerNodeView": endSequencerNodeViewToReturn,
        "selectionStartAnchorNodeView":startSequencerNodeView,
        "selectionEndFocusNodeView":endSequencerNodeView
    };
}else{
        // use the save selection
    return this._selectedNodeInfo;
}
},

_updateSelectionUsingBrowserSelection : function(latestComputedSeletionInfoForFirefox){
if(this._isFirefoxBrowser() && latestComputedSeletionInfoForFirefox){
    return this._selectSequencerNodeRangeWithCid(latestComputedSeletionInfoForFirefox.startSequencerNodeView.$el.get(0).getAttribute("segment_node_cid"), latestComputedSeletionInfoForFirefox.endSequencerNodeView.$el.get(0).getAttribute("segment_node_cid"));
}
const selection = window.getSelection();
const anchorNode = this._getSequencerNodeEl(selection.anchorNode);
const focusNode = this._getSequencerNodeEl(selection.focusNode);
if(selection && anchorNode  && Utils.getInstance().isAncestor(this.sequencerNodeContainer$el.get(0), anchorNode)){
    return this._selectSequencerNodeRangeWithCid(anchorNode.getAttribute("segment_node_cid"), focusNode.getAttribute("segment_node_cid"));
}else{
        // use the save selection
    return this._selectedNodeInfo;
}
},

getSelectionNode: function(){
return this._selectedNodeInfo;
/*
const selection = window.getSelection();
const anchorNode = this._getSequencerNodeEl(selection.anchorNode);
const focusNode = this._getSequencerNodeEl(selection.focusNode);
if(selection && anchorNode  && Utils.getInstance().isAncestor(this.sequencerNodeContainer$el.get(0), anchorNode)){
    this.unselectCurrentlySelectedSequencerNodes();
    const selectionStartNodeEl = anchorNode;
    const selectionStartNodeElIndex = this._sequencerNodeElArray.indexOf(selectionStartNodeEl);
    const selectionEndNodeEl = focusNode;
    const selectionEndNodeElIndex = this._sequencerNodeElArray.indexOf(selectionEndNodeEl);
    const startSequencerNodeCid = selectionStartNodeEl.getAttribute("segment_node_cid");
    const endSequencerNodeCid = selectionEndNodeEl.getAttribute("segment_node_cid");
    const startSequencerNodeView = this._sequencerNodeCidToSequencerNodeViewMap[startSequencerNodeCid];
    const endSequencerNodeView = this._sequencerNodeCidToSequencerNodeViewMap[endSequencerNodeCid];
    const startSequencerNodeModel = null;
    const endSequencerNodeModel = null;
    const startSequencerNodeViewToReturn = null;
    const endSequencerNodeViewToReturn = null;
    if(selectionEndNodeElIndex > selectionStartNodeElIndex){
        endSequencerNodeModel = endSequencerNodeView.getModel();
        startSequencerNodeModel = startSequencerNodeView.getModel();
        startSequencerNodeViewToReturn = startSequencerNodeView;
        endSequencerNodeViewToReturn = endSequencerNodeView;
    }else{
        endSequencerNodeModel = startSequencerNodeView.getModel();
        startSequencerNodeModel = endSequencerNodeView.getModel();
        startSequencerNodeViewToReturn = endSequencerNodeView;
        endSequencerNodeViewToReturn = startSequencerNodeView;

    }
    this._selectedNodeInfo = {"startSequencerNodeModel": startSequencerNodeModel,
    "startSequencerNodeView":startSequencerNodeViewToReturn,
    "endSequencerNodeModel":endSequencerNodeModel ,
    "endSequencerNodeView": endSequencerNodeViewToReturn,
    "selectionStartAnchorNodeView":startSequencerNodeView,
    "selectionEndFocusNodeView":endSequencerNodeView
};
this._onSelectionChange();
return this._selectedNodeInfo;
}else{
// use the save selection
return this._selectedNodeInfo;
}*/
},

_onSelectionChange : function(newSelection){
this.trigger("onSelectionChange", this._selectedNodeInfo);
const noSelection = this._isNoSelection();
if(noSelection){
    this._setElementDisabled(".always_displayed_context_menu button", true);
    this._destroyContextMenuDisplayed();
    return;
}else{
    this._setElementDisabled("#viewSettingsForMusicWrapperBtn", !this._canViewSettingsForSelection());
    this._setElementDisabled("#ignoreSelectedNodesBtn", !this._canSelectionBeIgnored());
    this._setElementDisabled("#deleteMusicWrapperBtn", !this._canSelectedNodeBeDeletedAMusicWrap());
    this._setElementDisabled("#wrapWithMusicBtn", !this._canSelectionBeWrappedWithMusic());
    this._setElementDisabled("#playFromBtn", !this._canPlayFromSelection());
    this._setElementDisabled("#insertBtn", !this._canAudioBeInserted());
    this._setElementDisabled("#insertElementBtn", !this._canElementBeInserted());
    this._setElementDisabled("#moreOptionBtn", !this._canMoreOptionBeDisplayed());
}

},

_canMoreOptionBeDisplayed : function(){
return !this._isNoSelection() && !this._isOnlyOneNodeSeletedAWrapMusicNodeSelected() && !this._isOnlyOneNodeSeletedAnAudioNodeSelected() && !this._isOnlyOneNodeSeletedAnVideoNodeSelected()
},

_isNoSelection : function(){
return !this._selectedNodeInfo || (!this._selectedNodeInfo.startSequencerNodeModel && !this._selectedNodeInfo.endSequencerNodeModel);
},

_isOnlyOneNodeSelected : function(){
return this._selectedNodeInfo && this._selectedNodeInfo.startSequencerNodeModel == this._selectedNodeInfo.endSequencerNodeModel;
},

_isOnlyOneNodeSeletedAWrapMusicNodeSelected : function(){

return this._isOnlyOneNodeSelected() && (Utils.getInstance().isStartMusicWrapSequencerNodeInstance(this._selectedNodeInfo.startSequencerNodeModel) || Utils.getInstance().isEndMusicWrapSequencerNodeInstance(this._selectedNodeInfo.startSequencerNodeModel));
},

_isOnlyOneNodeSeletedAnAudioNodeSelected : function(){
return this._isOnlyOneNodeSelected() &&  Utils.getInstance().isAudioSequencerNodeInstance(this._selectedNodeInfo.startSequencerNodeModel);
},

_isOnlyOneNodeSeletedAnVideoNodeSelected : function(){
    return this._isOnlyOneNodeSelected() &&  Utils.getInstance().isVideoSequencerNodeInstance(this._selectedNodeInfo.startSequencerNodeModel);
},


_canViewSettingsForSelection : function(){
return this._isOnlyOneNodeSelected();
},

_canSelectionBeWrappedWithMusic : function(){
return !this._isOnlyOneNodeSeletedAWrapMusicNodeSelected();
},

_canSelectedNodeBeDeletedAMusicWrap : function(){
return this._isOnlyOneNodeSeletedAWrapMusicNodeSelected();
},

_canPlayFromSelection : function(){
return !this._isNoSelection();
},

_canSelectionBeIgnored : function(){
return !this._isNoSelection();
},

_canAudioBeInserted : function(){
return !this._isNoSelection();
},

_canElementBeInserted : function(){
return this.isInsertElementAvailable();
},

_setElementDisabled : function(elementSelector, disbaled){
if(disbaled){
    this.$el.find(elementSelector).addClass("disabled");
}else{
    this.$el.find(elementSelector).removeClass("disabled");
}
},

isSelectionChange : function(oldSelection, newSelection){
if(oldSelection === newSelection){
    return false;
}
if(!oldSelection && newSelection){
    return true;
}
if(oldSelection && !newSelection){
    return true;
}
if(!oldSelection && !newSelection){
    return false;
}
const oldStartSequencerNodeModel = oldSelection.startSequencerNodeModel;
const oldEndSequencerNodeModel = oldSelection.endSequencerNodeModel;
const newStartSequencerNodeModel = newSelection.startSequencerNodeModel;
const newEndSequencerNodeModel = newSelection.endSequencerNodeModel;
return oldStartSequencerNodeModel != newStartSequencerNodeModel || oldEndSequencerNodeModel != newEndSequencerNodeModel;
},

showContextMenuIfApplicable:function(){
this._markCurrentlySelectedNodesAsSelected();
},

_onShiftClickOnSequencerNodeView : function(sequencerNodeView){
const sequencerNodeContainerEl = this.sequencerNodeContainer$el.get(0);
const sequencerNodeContainerChildrenArray =Array.from(sequencerNodeContainerEl.children);
const selectionBeforeClick  = this.getSelectionNode();
if(selectionBeforeClick){
    const currentSelectionStartIndex  = sequencerNodeContainerChildrenArray.indexOf(selectionBeforeClick.startSequencerNodeView.$el.get(0));
    const currentSelectionEndIndex  = sequencerNodeContainerChildrenArray.indexOf(selectionBeforeClick.endSequencerNodeView.$el.get(0));
    const lastSequencerNodeSelectedIndex = sequencerNodeContainerChildrenArray.indexOf(sequencerNodeView.$el.get(0));
    let lowerIndex = currentSelectionStartIndex;
    let lowerSequencerNodeView = selectionBeforeClick.startSequencerNodeView;
    //const upperIndex = currentSelectionEndIndex;
    let upperSequencerNodeView = selectionBeforeClick.endSequencerNodeView;
    if(currentSelectionStartIndex > currentSelectionEndIndex){
        lowerIndex = currentSelectionEndIndex;
        //upperIndex = currentSelectionStartIndex;
        lowerSequencerNodeView = selectionBeforeClick.endSequencerNodeView;
        upperSequencerNodeView = selectionBeforeClick.startSequencerNodeView;
    }
    if(lastSequencerNodeSelectedIndex < lowerIndex){
//this.scrollToSequencerNodeIfneccessary(sequencerNodeView, false, true, true);
        return this._markCurrentlySelectedNodesAsSelectedFromSelectionInfo(this._selectSequencerNodeRangeWithCid(sequencerNodeView.getModel().cid, upperSequencerNodeView.getModel().cid));
    }else{
//this.scrollToSequencerNodeIfneccessary(sequencerNodeView, false, true, true);
        return this._markCurrentlySelectedNodesAsSelectedFromSelectionInfo(this._selectSequencerNodeRangeWithCid(lowerSequencerNodeView.getModel().cid, sequencerNodeView.getModel().cid));
    }
}else{
    return this._markCurrentlySelectedNodesAsSelectedFromSelectionInfo(this._selectSequencerNodeRangeWithCid(sequencerNodeView.getModel().cid, sequencerNodeView.getModel().cid));
}
},

_markCurrentlySelectedNodesAsSelected : function(event){
try{
    if(!this.isClickOccuredOnContextMenu(event) && !this.isClickOccuredOnSpeakerContextMenu(event)){

        const latestSelectionInfo = event && event.type == "tap"?  this._getSelectionInfoUsingTap(event) : this._getCurrentBrowserSelectionParsed();
        if(event && event.shiftKey && latestSelectionInfo && latestSelectionInfo.startSequencerNodeView &&  latestSelectionInfo.startSequencerNodeView === latestSelectionInfo.endSequencerNodeView){
            return this._onShiftClickOnSequencerNodeView(latestSelectionInfo.startSequencerNodeView );
        }
        if(this.isSelectionChange(this._currentBrowserNativeSelection,latestSelectionInfo)){
            const selectionInfo = event && event.type == "tap"?  this._updateSelectionInfoUsingTap(event) : this._updateSelectionUsingBrowserSelection(latestSelectionInfo);
            if(event && event.altKey){
        //play from selection
                this._markCurrentlySelectedNodesAsSelectedFromSelectionInfo(selectionInfo);
                return this.onPlayFromBtnClicked();
            }else{
                return this._markCurrentlySelectedNodesAsSelectedFromSelectionInfo(selectionInfo);
            }
        }else{
            if(event && event.altKey){
                return this.onPlayFromBtnClicked();
            }
        }
    }
}catch(error){
    window.alertErrorMessage(error);
}
},

_markCurrentlySelectedNodesAsSelectedFromSelectionInfo : function(selectionInfo, doNotClearBrowserSelectionToNotLooseFocus, scheduleSelection){
this._currentBrowserNativeSelection = selectionInfo;
this.trigger("onNativeBrowserSelectionChange", selectionInfo);
if(selectionInfo){
    const startSequencerNodeModel = selectionInfo.startSequencerNodeModel;
    const endSequencerNodeModel = selectionInfo.endSequencerNodeModel;
    const startSequencerNodeView = selectionInfo.startSequencerNodeView;
    const endSequencerNodeView = selectionInfo.endSequencerNodeView;
    const selectionEndFocusNodeView = selectionInfo.selectionEndFocusNodeView;
    this._arrayOfSelectedSequencerNodeModels = [];
    let toMarkAsSelected = startSequencerNodeModel;
    while(toMarkAsSelected){
        if(scheduleSelection){
            setTimeout((function(){
                this.toMarkAsSelected.setSelected(true);
            }).bind({"toMarkAsSelected":toMarkAsSelected}), 0)
        }else{
            toMarkAsSelected.setSelected(true);
        }
        this._arrayOfSelectedSequencerNodeModels.push(toMarkAsSelected);
        if(toMarkAsSelected  == endSequencerNodeModel){
            toMarkAsSelected = null;
        }else{
            toMarkAsSelected = toMarkAsSelected.getNext();
        }
    }

    if(!doNotClearBrowserSelectionToNotLooseFocus){
        this._clearBrowserSelection();
    }
    return this._showContextMenuUsingSelectedSequencerNodes();
}
},

getNumberOfElementsSelected : function(){
if(!this._selectedNodeInfo){
    return 0;
}
const selectionInfo = this._selectedNodeInfo;
let numberOfElementsSelected = 0;
const startSequencerNodeModel = selectionInfo.startSequencerNodeModel;
const endSequencerNodeModel = selectionInfo.endSequencerNodeModel;
let toCountAsSelected = startSequencerNodeModel;
while(toCountAsSelected){
    if(this.model.isSequencerNodeVisible(toCountAsSelected)){
        numberOfElementsSelected = numberOfElementsSelected + 1;
    }
    if(toCountAsSelected  == endSequencerNodeModel){
        toCountAsSelected = null;
    }else{
        toCountAsSelected = toCountAsSelected.getNext();
    }
}
return numberOfElementsSelected;
},

showContextMenuForCorrectingTranscriptsIfApplicable : function(){
if(this.isTranscriptCorrectionAvailable()){
    return this.showContextMenuForCorrectingTranscripts();
}else{
    Utils.getInstance().showErrorNotification(window.getI18n(ti18n, "CANNOT_CORRECT_TRANSCRIPT_OF_SELECTION_WITH_NO_WORD"));
}
},

showContextMenuForCorrectingTranscripts : function(){
const selectionInfo = this.getSelectionNode();
if(selectionInfo){
    const arrayOfSequencerNodeWordModelSelected = this.getArrayOfWordSequencerNodesSelected(selectionInfo);
    if(arrayOfSequencerNodeWordModelSelected && arrayOfSequencerNodeWordModelSelected.length > 0){
        const firstSequencerNodeWordModelSelected  = arrayOfSequencerNodeWordModelSelected[0];
        this._destroyContextMenuDisplayed();
        this._markCurrentlySelectedNodesAsSelectedFromSelectionInfo(this._selectSequencerNodeWithCid(firstSequencerNodeWordModelSelected.cid));
        this._destroyContextMenuDisplayed();
        const updatedSelectionInfo = this.getSelectionNode();
        const selectionStartAnchorNodeView = updatedSelectionInfo.selectionStartAnchorNodeView;
        if(this.shouldContextMenuDisplayed(updatedSelectionInfo)){
            this._showCorrectTranscriptContextMenuInstance(selectionStartAnchorNodeView.$el.get(0), this.sequencerNodeContainer$el.get(0), this.getSelectedNodesTextContent(selectionInfo), ()=>{return this.onCorrectTranscriptBtnClicked()});
        }
    }
}

},

_showCorrectTranscriptContextMenuInstance : function(triggerEl, appendToEl, currentText, onChangesConfirmed, buttonLabel){
    this._destroyContextMenuCorrectTranscriptDisplayed();
    this._currentDisplayedCorrectTranscriptsContextMenuInstance = TippyJS(triggerEl, {appendTo: appendToEl, allowHTML : true,  animation:null, popperOptions: {strategy: 'fixed'},showOnCreate:true, content: this._getCorrectTranscriptContextMenuContent(currentText, buttonLabel) ,interactive :true, ignoreAttributes:true,theme: 'light', maxWidth:	450,onHidden: function(instance){instance.destroy()},trigger: "click", hideOnClick: false});
    this._currentDisplayedCorrectTranscriptsContextMenuInstance.dateCreated = new Date();
    $(this._currentDisplayedCorrectTranscriptsContextMenuInstance.popper).find("input").select();
    const onButtonClick = (event)=>{
        if(onChangesConfirmed){
            let newText =  this.getCorrectectTextFromTextCorrectionContextMenuInput();
            const changedApplied =  onChangesConfirmed(newText, currentText);
            if(changedApplied){
                this._destroyContextMenuCorrectTranscriptDisplayed();
            }
        }
    }
    $(this._currentDisplayedCorrectTranscriptsContextMenuInstance.popper).on("click", "#correctBtn:not(.disable)", onButtonClick);
    this._currentDisplayedCorrectTranscriptsContextMenuInstance.mouseTrapObj = Mousetrap(this._currentDisplayedCorrectTranscriptsContextMenuInstance.popper).bind('enter', (function(e, combo) {
        onButtonClick();
    }).bind(this));
},

getCorrectectTextFromTextCorrectionContextMenuInput : function(){
if(this._currentDisplayedCorrectTranscriptsContextMenuInstance){
    return $(this._currentDisplayedCorrectTranscriptsContextMenuInstance.popper).find("input").val();	
}
},

_showContextMenuUsingSelectedSequencerNodes : function(){
if(DO_NOT_USE_TIPPYJS_MENU){
    return this._showContextMenuUsingSelectedSequencerNodesNew();
}else{
    const selectionInfo = this.getSelectionNode();
    if(selectionInfo){
        const selectionEndFocusNodeView = selectionInfo.selectionEndFocusNodeView;
        if(this.shouldContextMenuDisplayed(selectionInfo)){
            if(this._currentlyDisplayedTippyInstance){
                this._destroyContextMenuDisplayed();
            }
            if(!window.isMobileBrowser){
                this._currentlyDisplayedTippyInstance = TippyJS(selectionEndFocusNodeView.$el.get(0), {allowHTML : true, animation:null, appendTo:this.mainSection$el.get(0),  popperOptions: {strategy: 'fixed'},showOnCreate:true, content: this._getContextMenuContent(selectionInfo) ,interactive :true, ignoreAttributes:true,theme: 'light',followCursor: 'initial', maxWidth: 450,onHidden: function(instance){instance.destroy()},trigger: "click", hideOnClick: false});
                this._initializeTippyJsTooltips($(this._currentlyDisplayedTippyInstance.popper));
                this._currentlyDisplayedTippyInstance.dateCreated = new Date();
            }
        }
    }
}
},

_showContextMenuUsingSelectedSequencerNodesNew : function(){
const selectionInfo = this.getSelectionNode();
if(selectionInfo){
    const selectionEndFocusNodeView = selectionInfo.selectionEndFocusNodeView;
    if(this.shouldContextMenuDisplayed(selectionInfo)){
        if(this._currentlyDisplayedContextMenuInstance){
            this._destroyContextMenuDisplayed();
        }
        if(!window.isMobileBrowser){
            const onMenuClose = (function(){
                this._currentlyDisplayedContextMenuInstance = null;
            }).bind(this)
            return PersistentModels.getInstance().getRouter().showSequencerNodeBasicContextMenuOption(this._sequencerController, selectionEndFocusNodeView.$el, onMenuClose).then((function(contextMenuController){
                this._currentlyDisplayedContextMenuInstance = contextMenuController;
                this._currentlyDisplayedContextMenuInstance.dateCreated = new Date();
            }).bind(this))
        //this._currentlyDisplayedTippyInstance = TippyJS(selectionEndFocusNodeView.$el.get(0), {allowHTML : true, showOnCreate:true, content: this._getContextMenuContent(selectionInfo) ,interactive :true, ignoreAttributes:true,theme: 'light', maxWidth:	450,onHidden: function(instance){instance.destroy()},trigger: "click", hideOnClick: false})
        //this._currentlyDisplayedTippyInstance.dateCreated = new Date();
        }
    }
}
},



isSelectedNodeAStartOrEndMusicWrapNode : function(){
const selectionInfo = this.getSelectionNode();
if(selectionInfo){
    const startSequencerNodeModel = selectionInfo.startSequencerNodeModel;
    const endSequencerNodeModel = selectionInfo.endSequencerNodeModel;
    const startSequencerNodeView = selectionInfo.startSequencerNodeView;
    const endSequencerNodeView = selectionInfo.endSequencerNodeView;
    if(startSequencerNodeModel == endSequencerNodeModel){
        if(Utils.getInstance().isStartMusicWrapSequencerNodeInstance(startSequencerNodeModel) ||
            Utils.getInstance().isEndMusicWrapSequencerNodeInstance(startSequencerNodeModel)){
            return  true;
    }

}
}
return false;
},

isSelectedNodeAnAudioNode : function(){
const selectionInfo = this.getSelectionNode();
if(selectionInfo){
const startSequencerNodeModel = selectionInfo.startSequencerNodeModel;
const endSequencerNodeModel = selectionInfo.endSequencerNodeModel;
const startSequencerNodeView = selectionInfo.startSequencerNodeView;
const endSequencerNodeView = selectionInfo.endSequencerNodeView;
if(startSequencerNodeModel == endSequencerNodeModel){
    if(Utils.getInstance().isAudioSequencerNodeInstance(startSequencerNodeModel)){
        return true;
    }
    
}
}
return false;
},

isSelectedNodeAnVideoNode : function(){
const selectionInfo = this.getSelectionNode();
if(selectionInfo){
const startSequencerNodeModel = selectionInfo.startSequencerNodeModel;
const endSequencerNodeModel = selectionInfo.endSequencerNodeModel;
const startSequencerNodeView = selectionInfo.startSequencerNodeView;
const endSequencerNodeView = selectionInfo.endSequencerNodeView;
if(startSequencerNodeModel == endSequencerNodeModel){
    if(Utils.getInstance().isVideoSequencerNodeInstance(startSequencerNodeModel)){
        return true;
    }
    
}
}
return false;
},


_getContextMenuContent : function(selectionInfo){
if(selectionInfo){
const startSequencerNodeModel = selectionInfo.startSequencerNodeModel;
const endSequencerNodeModel = selectionInfo.endSequencerNodeModel;
const startSequencerNodeView = selectionInfo.startSequencerNodeView;
const endSequencerNodeView = selectionInfo.endSequencerNodeView;
if(startSequencerNodeModel == endSequencerNodeModel){
    if(Utils.getInstance().isStartMusicWrapSequencerNodeInstance(startSequencerNodeModel) ||
        Utils.getInstance().isEndMusicWrapSequencerNodeInstance(startSequencerNodeModel)){
        return this.compiledMusicWrapperContextMenuTemplate({"ti18n": ti18n, "getTooltip": this._getTooltipFunction() });
    }
    if(Utils.getInstance().isAudioSequencerNodeInstance(startSequencerNodeModel)){
        return this.compiledAudioSequencerNodeContextMenuTemplate({"ti18n": ti18n,"moreOptionAvailable": this.isMoreOptionAvailable(),  "getTooltip": this._getTooltipFunction()});
    }
    if(Utils.getInstance().isVideoSequencerNodeInstance(startSequencerNodeModel)){
        return this.compiledVideoSequencerNodeContextMenuTemplate({"ti18n": ti18n,"moreOptionAvailable": this.isMoreOptionAvailable(),  "getTooltip": this._getTooltipFunction()});
    }
    if(Utils.getInstance().isDeletedSequencerNodeInstance(startSequencerNodeModel)){
        return this.compiledSequencerDeletedNodeContextMenuTemplate({"ti18n": ti18n, "getTooltip": this._getTooltipFunction()});
    }
}
}
const curentLoggedInUserIsNotAVisitor = LocalStorageHelper.getInstance().isCurrentLoggedUserNotAVisitor();
return this.compiledContextMenuTemplate({
"deleteAvailable": this.isDeleteActionAvailable(),
"wrapWithBackgroundMusicAvailable":this.isWrapWithBackgroundMusicActionAvailable(), 
"playFromHereAvailable": this.isPlayFromHereActionAvailable(),
"insertAvailable": this.isInsertActionAvailable(), 
"insertElementAvailable": this.isInsertElementAvailable(),
"clippingAvailable": this.isClippingActionAvailable(), 
"moreOptionAvailable": this.isMoreOptionAvailable(),
"getTooltip": this._getTooltipFunction(),
"editTranscriptionAvailable": this.isEditTranscriptionAvailable(),
"downloadClipAvailable": this.isDownloadClipAvailable(),
"ti18n": ti18n})
},

getArrayOfVisibleSequencerNodesSelected : function(selectionInfo){
const startSequencerNodeModel = selectionInfo.startSequencerNodeModel;
const endSequencerNodeModel =  selectionInfo.endSequencerNodeModel;
const nodesToReturn= [];
if(startSequencerNodeModel){
    let nodeToIterate = startSequencerNodeModel;
while(nodeToIterate){
    nodesToReturn.push(nodeToIterate);
    if(nodeToIterate === endSequencerNodeModel){
        nodeToIterate = null;
    }else{
        nodeToIterate =  nodeToIterate.getNextVisibleSequencerNode();
    }
}

}
return nodesToReturn;
},

getArrayOfWordSequencerNodesSelected : function(selectionInfo){
const startSequencerNodeModel = selectionInfo? selectionInfo.startSequencerNodeModel: null;
const endSequencerNodeModel =  selectionInfo? selectionInfo.endSequencerNodeModel: null;
const nodesToReturn= [];
if(startSequencerNodeModel){
    let nodeToIterate = startSequencerNodeModel;
while(nodeToIterate){
    if(Utils.getInstance().isWordSequencerNodeInstance(nodeToIterate)){
        nodesToReturn.push(nodeToIterate);
    }
    if(nodeToIterate === endSequencerNodeModel){
        nodeToIterate = null;
    }else{
        nodeToIterate =  nodeToIterate.getNextVisibleSequencerNode();
    }
}

}
return nodesToReturn;
},



getSelectedNodesTextContent : function(selectionInfo){
    let textContent = "";
if(selectionInfo){
const wordSequencerNodesArray = this.getArrayOfWordSequencerNodesSelected(selectionInfo);
wordSequencerNodesArray.map((function(wordSequencerNode, index){
    if(index === 0){
        textContent = wordSequencerNode.getAudioSegment().getContent();
    }else{
        textContent =  textContent + " " + wordSequencerNode.getAudioSegment().getContent();
    }

}).bind(this));
}
return textContent;
},

_getCorrectTranscriptContextMenuContentOld :function(selectionInfo){
return this.compiledCorrectTranscriptContextMenuTemplate({"ti18n": ti18n, "currentText": this.getSelectedNodesTextContent(selectionInfo)})
},

_getCorrectTranscriptContextMenuContent :function(currentText, buttonLabel){
    return this.compiledCorrectTranscriptContextMenuTemplate({"ti18n": ti18n, currentText, buttonLabel})
    },

isMoreOptionAvailable : function(){
return CUT_COPY_PASTE_FEATURE_AVAILABLE || (ALLOW_USER_TO_CLIP_SELECTION &&  LocalStorageHelper.getInstance().isCurrentLoggedUserNotAVisitor());
},

isDeleteActionAvailable : function(){
return this.getNumberOfElementsSelected() > 0;
},

isWrapWithBackgroundMusicActionAvailable : function(){
return !this.isSelectedNodeAStartOrEndMusicWrapNode() && !this.isSelectedNodeAnAudioNode() && !this.isSelectedNodeAnVideoNode();
},

isPlayFromHereActionAvailable : function(){
return true;
},

isEditTranscriptionAvailable: function(){
return true;
},

isDownloadClipAvailable: function(){
return true;
},


isInsertActionAvailable : function(){
return !this.isSelectedNodeAStartOrEndMusicWrapNode() && !this.isSelectedNodeAnAudioNode() && !this.isSelectedNodeAnVideoNode();
},


isInsertElementAvailable : function(){
    return this.isWrapWithBackgroundMusicActionAvailable() || this.isInsertActionAvailable();
},
    


isClippingActionAvailable : function(){
return this.getNumberOfElementsSelected() > 0 && ALLOW_USER_TO_CLIP_SELECTION &&  LocalStorageHelper.getInstance().isCurrentLoggedUserNotAVisitor();
},

isCutActionAvailable : function(){
return this.getNumberOfElementsSelected() > 0 && CUT_COPY_PASTE_FEATURE_AVAILABLE;
},

isCopyActionAvailable : function(){
return this.getNumberOfElementsSelected() > 0 && CUT_COPY_PASTE_FEATURE_AVAILABLE;
},

isPasteActionAvailable : function(){
return !this.model.isClipboardEmpty()  && CUT_COPY_PASTE_FEATURE_AVAILABLE;
},

isTranscriptCorrectionAvailable : function(){
const wordSequencerNodes = this.getArrayOfWordSequencerNodesSelected(this.getSelectionNode());
return wordSequencerNodes && wordSequencerNodes.length > 0;
},	


shouldContextMenuDisplayed : function(selectionInfo){
if(selectionInfo){
const startSequencerNodeModel = selectionInfo.startSequencerNodeModel;
const endSequencerNodeModel = selectionInfo.endSequencerNodeModel;
const startSequencerNodeView = selectionInfo.startSequencerNodeView;
const endSequencerNodeView = selectionInfo.endSequencerNodeView;
if(startSequencerNodeModel == endSequencerNodeModel){
    if(Utils.getInstance().isUnsupportedAudioSequencerNodeInstance(startSequencerNodeModel) ||
        Utils.getInstance().isPunctuationSequencerNodeInstance(startSequencerNodeModel) /*||
        Utils.getInstance().isDeletedSequencerNodeInstance(startSequencerNodeModel)*/){
        return false;
}
/*if(Utils.getInstance().isPauseSequencerNodeInstance(startSequencerNodeModel) && 
    (startSequencerNodeModel.getAudioSegment() == null || startSequencerNodeModel.getAudioSegment().getDuration() == 0)){
    return false;
}*/
}
return true;
}else{
return false;
}
},

getSequencerNodeViewFromSequencerNode : function(sequencerNode){
return sequencerNode? this.getSequencerNodeViewFromSequencerNodeCid(sequencerNode.cid): null;
},

getSequencerNodeViewFromSequencerNodeCid : function(cid){
return cid? this._sequencerNodeCidToSequencerNodeViewMap[cid]: null;
},

_createAndRenderSequencerNodeView : function(sequencerNode){
const params = {"model": sequencerNode};
const audioSegment = sequencerNode.getAudioSegment();
let s = null;
if(Utils.getInstance().isWordSequencerNodeInstance(sequencerNode)){
s = new SequencerWordNodeView(params);
this._sequencerNodeCidToSequencerNodeViewMap[sequencerNode.cid] = s;
s.render();
return s;
}
if(Utils.getInstance().isPauseSequencerNodeInstance(sequencerNode)){
s = new SequencerPauseNodeView(params);
this._sequencerNodeCidToSequencerNodeViewMap[sequencerNode.cid] = s;
s.render();
return s;
}
if(Utils.getInstance().isPunctuationSequencerNodeInstance(sequencerNode)){
s = new SequencerPunctuationNodeView(params);
this._sequencerNodeCidToSequencerNodeViewMap[sequencerNode.cid] = s;
s.render();
return s;
}
if(Utils.getInstance().isDeletedSequencerNodeInstance(sequencerNode)){
s = new SequencerDeletedNodeView(params);
this._sequencerNodeCidToSequencerNodeViewMap[sequencerNode.cid] = s;
s.render();
return s;
}
if(Utils.getInstance().isStartMusicWrapSequencerNodeInstance(sequencerNode)){
s = new SequencerStartMusicWrapNodeView(params);
this._sequencerNodeCidToSequencerNodeViewMap[sequencerNode.cid] = s;
s.render();
return s;
}

if(Utils.getInstance().isEndMusicWrapSequencerNodeInstance(sequencerNode)){
s = new SequencerEndMusicWrapdNodeView(params);
this._sequencerNodeCidToSequencerNodeViewMap[sequencerNode.cid] = s;
s.render();
return s;
}
if(Utils.getInstance().isAudioSequencerNodeInstance(sequencerNode)){
s = new SequencerAudioNodeView(params);
this._sequencerNodeCidToSequencerNodeViewMap[sequencerNode.cid] = s;
s.render();
return s;
}
if(Utils.getInstance().isVideoSequencerNodeInstance(sequencerNode)){
    s = new SequencerVideoNodeView(params);
    this._sequencerNodeCidToSequencerNodeViewMap[sequencerNode.cid] = s;
    s.render();
    return s;
}
if(Utils.getInstance().isUnsupportedAudioSegmentInstance(audioSegment)){
s = new SequencerUnsupportedNodeView(params);
this._sequencerNodeCidToSequencerNodeViewMap[sequencerNode.cid] = s;
s.render();
return s;
}

},

isClickOccuredOnContextMenu : function(event){
return event && this._currentlyDisplayedTippyInstance && this._currentlyDisplayedTippyInstance.popper && (this._currentlyDisplayedTippyInstance.popper == event.target || $.contains(this._currentlyDisplayedTippyInstance.popper, event.target));
},

isClickOccuredOnSpeakerContextMenu : function(event){
    return event && this._speakerContextMenuTippyJSInstanceDisplayed && this._speakerContextMenuTippyJSInstanceDisplayed.popper && (this._speakerContextMenuTippyJSInstanceDisplayed.popper == event.target || $.contains(this._speakerContextMenuTippyJSInstanceDisplayed.popper, event.target));
},


isClickOccuredOnSequencerView: function(event){
return event && (this.$el.get(0) == event.target || $.contains(this.$el.get(0), event.target));
},

isClickOccuredOnSequencerContainerView: function(event){
return event && (this.sequencerNodeContainer$el.get(0) == event.target || $.contains(this.sequencerNodeContainer$el.get(0), event.target));
},

isClickOccuredOnSequencerInspectorContainerView: function(event){
return event && (this._sequencer_node_inspector_container$el.get(0) == event.target || $.contains(this._sequencer_node_inspector_container$el.get(0), event.target));
},

isClickOccuredOnSequencerSettingsContainerView: function(event){
return event && (this._sequencer_settings_container$el.get(0) == event.target || $.contains(this._sequencer_settings_container$el.get(0), event.target));
},

isClickOccuredOnSequencerBottomBarView: function(event){
return event && (this.bottomBar$el.get(0) == event.target || $.contains(this.bottomBar$el.get(0), event.target));
},

isClickOccuredOnSequencerTopHeaderBarView: function(event){
return event && (this.headerBar$el.get(0) == event.target || $.contains(this.headerBar$el.get(0), event.target));
},

isClickOccuredOnFloatingSearchContextSearchResultMenuView: function(event){
return event && this.searchWidgetFloatingMenu$el.get(0) && (this.searchWidgetFloatingMenu$el.get(0) == event.target || $.contains(this.searchWidgetFloatingMenu$el.get(0), event.target));
},



_initRenderOfSequencer : function(){
if(this.model && this.model.getFirstNode()){
    let nodeToRender = this.model.getFirstNode();
while(nodeToRender){
    this.onSequencerNodeAdded(nodeToRender)
    nodeToRender = nodeToRender.getNext();
}
}
},

initSequencerNodeInspectorView : function(sequencerNodeInspectorView){
this._sequencerNodeInspectorView = sequencerNodeInspectorView;
this._sequencer_node_inspector_container$el.append(this._sequencerNodeInspectorView.$el)
},

initSequencerSettingsView: function(sequencerSettingsView){
this._sequencerSettingsView = sequencerSettingsView;
this._sequencer_settings_container$el.append(this._sequencerSettingsView.$el)
},

executeDragSequencerNode : function(sequencerNodeToMove,oldIndexInSequencer, newIndexInSequencer, newPreviousSequencerNode, newNextSequencerNode){
this.unselectCurrentlySelectedSequencerNodes();
if(Utils.getInstance().isAudioSequencerNodeInstance(sequencerNodeToMove)){
return this.model.moveNode(sequencerNodeToMove, newPreviousSequencerNode, newNextSequencerNode);
}
if(Utils.getInstance().isVideoSequencerNodeInstance(sequencerNodeToMove)){
    return this.model.moveNode(sequencerNodeToMove, newPreviousSequencerNode, newNextSequencerNode);
}
if(Utils.getInstance().isStartMusicWrapSequencerNodeInstance(sequencerNodeToMove) || Utils.getInstance().isEndMusicWrapSequencerNodeInstance(sequencerNodeToMove)){
return this.model.moveWrapNode(sequencerNodeToMove,oldIndexInSequencer, newIndexInSequencer, newPreviousSequencerNode, newNextSequencerNode);
}

},

executeDragAudioSequencerNode : function(sequencerNodeToMove, newPreviousSequencerNode, newNextSequencerNode){
this.unselectCurrentlySelectedSequencerNodes();

},

getSequencerNodeViewAtIndexOrLower : function(sequencerNodeContainerEl, index){
if(index < 0){
return null;
}else{
if(sequencerNodeContainerEl.childNodes.length > index){
    const htmlEl = sequencerNodeContainerEl.childNodes[index];
    if(htmlEl.getAttribute("segment_node_cid")){
        return this.getSequencerNodeViewFromSequencerNodeCid(htmlEl.getAttribute("segment_node_cid"));
    }else{
        return this.getSequencerNodeViewAtIndexOrLower(sequencerNodeContainerEl, index -1)
    }
}else{
    return null;
}
}
},

getSequencerNodeViewAtIndexOrHigher : function(sequencerNodeContainerEl, index){
if(index < 0){
return null;
}else{
if(sequencerNodeContainerEl.childNodes.length > index){
    const htmlEl = sequencerNodeContainerEl.childNodes[index];
    if(htmlEl.getAttribute("segment_node_cid")){
        return this.getSequencerNodeViewFromSequencerNodeCid(htmlEl.getAttribute("segment_node_cid"));

    }else{
        return this.getSequencerNodeViewAtIndexOrHigher(sequencerNodeContainerEl, index + 1)
    }
}else{
    return null;
}
}
},

_revertToInitialPositionBeforeDrag : function(sequencerNodeMovedView, oldIndexInSequencer, newIndexInSequencer){
const sequencerNodeContainerEl = this.sequencerNodeContainer$el.get(0);
if(newIndexInSequencer < oldIndexInSequencer){
if(oldIndexInSequencer + 1 < sequencerNodeContainerEl.children.length){
    sequencerNodeContainerEl.insertBefore(sequencerNodeMovedView.$el.get(0), sequencerNodeContainerEl.children[oldIndexInSequencer + 1]);
}else{
    sequencerNodeContainerEl.appendChild(sequencerNodeMovedView.$el.get(0));
}
}else{
sequencerNodeContainerEl.insertBefore(sequencerNodeMovedView.$el.get(0), sequencerNodeContainerEl.children[oldIndexInSequencer]);
}
},

_onDragStart : function(evt){
const sequencerNodeContainerEl = this.sequencerNodeContainer$el.get(0);
if(evt.oldIndex  < sequencerNodeContainerEl.children.length){
const sequencerNodeBeingMovedEl = sequencerNodeContainerEl.children[evt.oldIndex];
const sequencerNodeBeingMovedView = this.getSequencerNodeViewFromSequencerNodeCid(sequencerNodeBeingMovedEl.getAttribute("segment_node_cid"));
if(sequencerNodeBeingMovedView){
    const sequencerNodeBeingMoved = sequencerNodeBeingMovedView.getModel();
    if(Utils.getInstance().isWrapSequencerNodeInstance(sequencerNodeBeingMoved)){
        sequencerNodeBeingMoved.setAllNodesHoverHighlighted(false);
    }
}
}
},

_onDragEnd : function(evt){
if(this._sortableInstance){
this.sequencerNodeContainer$el.attr("move_mode", "false");
this._sortableInstance.option("disabled", true);
if(evt.item.getAttribute("segment_node_cid")){
    const sequencerNodeContainerEl = this.sequencerNodeContainer$el.get(0);
    const oldIndexInSequencer = evt.oldIndex;
    const newIndexInSequencer = evt.newIndex;
    const sequencerNodeMovedView = this.getSequencerNodeViewFromSequencerNodeCid(evt.item.getAttribute("segment_node_cid"));
    const sequencerNodeMovedParagraphContainerView = sequencerNodeMovedView? sequencerNodeMovedView.getParagraphContainerView(): null;
    const sequencerNodeMoved = sequencerNodeMovedView? sequencerNodeMovedView.getModel(): null;
    const newPreviousSequencerNodeView = this.getSequencerNodeViewAtIndexOrLower(sequencerNodeContainerEl, newIndexInSequencer - 1);
    const newPreviousSequencerNodeParagraphContainerView = newPreviousSequencerNodeView? newPreviousSequencerNodeView.getParagraphContainerView(): null;
    const newPreviousSequencerNode = newPreviousSequencerNodeView? newPreviousSequencerNodeView.getModel(): null;
    const newNextSequencerNodeView = this.getSequencerNodeViewAtIndexOrHigher(sequencerNodeContainerEl, newIndexInSequencer + 1);
    const newNextSequencerNodeParagraphContainerView = newNextSequencerNodeView? newNextSequencerNodeView.getParagraphContainerView(): null;
    const newNextSequencerNode = newNextSequencerNodeView? newNextSequencerNodeView.getModel(): null;
    if(oldIndexInSequencer != newIndexInSequencer && sequencerNodeMoved){
        this._revertToInitialPositionBeforeDrag(sequencerNodeMovedView, oldIndexInSequencer, newIndexInSequencer);
        this.executeDragSequencerNode(sequencerNodeMoved,oldIndexInSequencer,  newIndexInSequencer, newPreviousSequencerNode, newNextSequencerNode);
    }
}
}

},

remove : function(){
this._enableContextMenuForiOS();
Backbone.View.prototype.remove.call(this);
this.stopListening();
if(this._toggleBottomBarVisibilityOnAndroidMobileOnSelectionChangeListener){
document.removeEventListener('selectionchange', this._toggleBottomBarVisibilityOnAndroidMobileOnSelectionChangeListener);
}
},

_disableContextMenuForiOS : function(){
if(window.waitForCordovaToLoad && window.ContextMenuState){
try{
    //make sure to enable to context menu on ios
    window.ContextMenuState.setVisible(false);
}catch(error){
    console.error(error);
}
}
},


_enableContextMenuForiOS : function(){
if(window.waitForCordovaToLoad && window.ContextMenuState){
try{
    //make sure to enable to context menu on ios
    window.ContextMenuState.setVisible(true);
}catch(error){
    console.error(error);
}
}
},



_makeSequencerNodesMovable: function(){
const sortableOptions = {
"animation": 400, 
"disabled":true,
"filter":".not_movable",
"draggable":".sequencer_node",
"dataIdAttr":"segment_node_cid",
"onUpdate": this._onDragEnd.bind(this),
"onStart": this._onDragStart.bind(this)
};
this._sortableInstance = new Sortable(this.sequencerNodeContainer$el.get(0), sortableOptions);
},

_forceSequencerNodeContainerBrowserRepaintOnMobile:function(){
//this function is called because on mobile android chrome the browser make mistake in the rendering when there is a selection
if(window.isMobileBrowser){
this.sequencerNodeContainer$el.get(0).style.display = "none";
this.sequencerNodeContainer$el.get(0).style.display = "";
}
},
_onSequencerNodeContainerScrollingStart : function(){
this._isSequencerNodeContainerScrolling = true;
//this._destroyContextMenuDisplayed();
},

_onSequencerNodeContainerScrollingEnd : function(){
this._isSequencerNodeContainerScrolling = false;
//this._showContextMenuUsingSelectedSequencerNodes();
},

_listenForScrollEventOnSequencerNodeContainer: function(){
    let isScrolling = null;
    let scrollingEndFunc = null;
    this.sequencerNodeContainer$el.scroll((function ( event ) {
    if(DESTROY_CONTEXT_MENU_ON_SCROLL){
        if(!this._isSequencerNodeContainerScrolling){
            this._onSequencerNodeContainerScrollingStart();
        }
        window.clearTimeout( scrollingEndFunc );
        scrollingEndFunc = setTimeout((function() {
            this._onSequencerNodeContainerScrollingEnd();
        }).bind(this), 500);
    }
    if(this._isUserManuallyScrolling){
        if(this.model.isPlaying()){
            if(this._pendingScrollAnimation){
                this._pendingScrollAnimationResolve();
                this._pendingScrollAnimationResolve = null;
                this._pendingScrollAnimationReject = null;
                this._pendingScrollAnimation = false;
                this.sequencerNodeContainer$el.stop();
            }
            window.clearTimeout( isScrolling );
            isScrolling = setTimeout((function() {
                this._deactivateAutoscollOnUserManualScrollIfNecessary();
                this._isUserManuallyScrolling = false;
                //this._forceSequencerNodeContainerBrowserRepaintOnMobile();
            }).bind(this), 2000);
        }else{
            this._isUserManuallyScrolling = false;
        }
    }
    }).bind(this))
},

_listenToSelectionChange: function(evt){
if(this._isSelectionChangingTimeoutFunctionId){
window.clearTimeout( this._isSelectionChangingTimeoutFunctionId );
this._isSelectionChangingTimeoutFunctionId = null;
}
this._isSelectionChangingTimeoutFunctionId = setTimeout((function() {
this._markCurrentlySelectedNodesAsSelected(evt);
this._isSelectionChangingTimeoutFunctionId = null;
}).bind(this), 1000);
},

_onTapOnAndroidChrome : function(event){
if(this._isSelectionChangingTimeoutFunctionId){
window.clearTimeout( this._isSelectionChangingTimeoutFunctionId );
this._isSelectionChangingTimeoutFunctionId = null;
}
this._markCurrentlySelectedNodesAsSelected(event);
},



_hideHeaderOrBottomBarVisibilityOnAndroidMobileOnSelectionChange : function(e){

if(window.isMobileBrowser && !window.isSafariBrowser){
if(window.getSelection() && window.getSelection().anchorNode != window.getSelection().focusNode){
    //more than one node selected


    const selection  = window.getSelection()
    const anchorNode = this._getSequencerNodeEl(selection.anchorNode);
    const focusNode = this._getSequencerNodeEl(selection.focusNode);
    if(anchorNode && focusNode){
        const anchorNodeIndex = this._sequencerNodeElArray.indexOf(anchorNode);
        const focusNodeIndex = this._sequencerNodeElArray.indexOf(focusNode);
        this._lastTimeSelectionChange = (new Date()).getTime();
        if(anchorNodeIndex < focusNodeIndex){
            this._bottomToolbarVisibilityIsHidden =  true;
            this._headerToolbarVisibilityIsHidden =  false;
            this.bottomBar$el.css("visibility", "hidden");
            this.headerBar$el.css("display", "");
            this.sequencerNodeContainer$el.css("margin-top","0px");
        }else{
            this._headerToolbarVisibilityIsHidden =  true;
            this._bottomToolbarVisibilityIsHidden =  false;
            this.headerBar$el.css("display", "none");
            this.bottomBar$el.css("visibility", "visible");
            this.sequencerNodeContainer$el.css("margin-top","56px");
        }
    }
    

}
}
},

_showHeaderOrBottomBarVisibilityOnAndroidMobileOnSelectionChange : function(event){
if(window.isMobileBrowser && !window.isSafariBrowser && (this._headerToolbarVisibilityIsHidden || this._bottomToolbarVisibilityIsHidden)){
if(this._headerToolbarVisibilityIsHidden){
    this._headerToolbarVisibilityIsHidden = false;
    this.headerBar$el.css("display", "");
    this.sequencerNodeContainer$el.css("margin-top","0px");

}else{
    this._bottomToolbarVisibilityIsHidden = false;
    this.bottomBar$el.css("visibility", "visible");
}
this._markCurrentlySelectedNodesAsSelected(event);
}
},

setJqueryPageElWhereSequencerWillBeAdded : function(jqueryEl){
this._jqueryPageElWhereSequencerWillBeAdded$el = jqueryEl;
this._jqueryPageElWhereSequencerWillBeAdded$el.contextmenu((function(event){
event.preventDefault();
event.stopPropagation(); // not necessary in my case, could leave in case stopImmediateProp isn't available? 
event.stopImmediatePropagation();
//this._showHeaderOrBottomBarVisibilityOnAndroidMobileOnSelectionChange(event);
try{
    if(!window.isMobileBrowser){
        this.showContextMenuIfApplicable();
    }
}catch(error){
    console.error(error)
}
return false;
}).bind(this));

this._jqueryPageElWhereSequencerWillBeAdded$el.on("click", this._onUserClickedOutsideOfTheSequencerEditorContainer.bind(this))
},

_onUserClickedOutsideOfTheSequencerEditorContainer : function(event){
if(!this.isClickOccuredOnSequencerContainerView(event) && !this.isClickOccuredOnContextMenu(event) && !this.isClickOccuredOnSpeakerContextMenu(event)  && !this.isClickOccuredOnSequencerInspectorContainerView(event) && !this.isClickOccuredOnSequencerSettingsContainerView(event)  && !this.isClickOccuredOnSequencerBottomBarView(event) && !this.isClickOccuredOnSequencerTopHeaderBarView(event) && !this.isClickOccuredOnFloatingSearchContextSearchResultMenuView(event)){
const wasContextMenuCreatedInLessThanHundredMilliseconds = this._currentlyDisplayedTippyInstance && this._currentlyDisplayedTippyInstance.dateCreated && ((new Date()).getTime() - this._currentlyDisplayedTippyInstance.dateCreated.getTime() < 100)
const wasCorrectContextMenuCreatedInLessThanHundredMilliseconds = this._currentDisplayedCorrectTranscriptsContextMenuInstance && this._currentDisplayedCorrectTranscriptsContextMenuInstance.dateCreated && ((new Date()).getTime() - this._currentDisplayedCorrectTranscriptsContextMenuInstance.dateCreated.getTime() < 100)
if(!wasContextMenuCreatedInLessThanHundredMilliseconds && !wasCorrectContextMenuCreatedInLessThanHundredMilliseconds){
        //this._destroyContextMenuDisplayed();
    this.unselectCurrentlySelectedSequencerNodes();
}
}
},

getSearchInput: function(){
return this.searchInput$el.val()
},

onSearchInputChange : function(){
if(this._pendingSearchQueryId)
{
clearTimeout(this._pendingSearchQueryId);
}
this._pendingSearchQueryId = setTimeout((function(){
this.trigger("searchInputChange")
}).bind(this), DELAY_FOR_EXECUTING_SEARCH_QUERY_IN_MILLISECONDS);
},


_getTooltipFunction(){
return (function(tooltipMessage, arrayOfKeys){
return this.compiledTippyTooltipTemplate({"tooltip":tooltipMessage, "shortcut_keys": arrayOfKeys});
}).bind(this);
},

_destroyTippyTooltipDisplayed: function(){
this._onTippyContextMenuTooltipCreated(null);
},

_onTippyContextMenuTooltipCreated : function(tooltipTippyInstance){
if(this._currentlyDisplayedTooltipTippyInstance){
this._currentlyDisplayedTooltipTippyInstance.destroy();
this._currentlyDisplayedTooltipTippyInstance = null;
}
this._currentlyDisplayedTooltipTippyInstance = tooltipTippyInstance;
},

_initializeTippyJsTooltips : function(el$, noOffset){
if(!window.isMobileBrowser){
const tippjsParams = {"allowHTML": true, 
offset: noOffset?[0,0]: [0, 16], 
arrow: true,
theme: 'tippy-trebble-tooltip',  
placement: 'bottom'};
this._onTippyContextMenuTooltipCreated(createSingleton(TippyJS(el$.find("[data-tippy-content]").toArray(),tippjsParams),tippjsParams ));
}
},

_createTourStepForSelectAudioToModify: function(tour){
return {
title: window.getI18n(ti18n, "ONBOARD_STEP_1_TITLE_EDIT_AUDIO_BY_TEXT"),
text:  window.getI18n(ti18n, "ONBOARD_STEP_1_DESC_EDIT_AUDIO_BY_TEXT"),
attachTo: {
        //element: this.$el.find(".sequencer_node_container").get(0),//this._currentlyDisplayedTippyInstance.popper,//this.$el.find(".sequencer_node_default_context_menu").get(0),
    on: 'auto'
},
arrow: true,
modalOverlayOpeningPadding: 0,
buttons: [
{
    action() {
        return tour.cancel();
    },
    classes: 'shepherd-button-secondary',
    text: window.getI18n(ti18n, "SKIP_TOUR")
},
{
    action : (function(){
        this._selectFourLinesOfTextIfContextMenuIsNotDisplayed();
        setTimeout((function(){
            if(this.$el.is(":visible")){
                tour.addStep(this._createTourStepForDeletingAudio(tour));
                return tour.next();
            }else{
                if(window.trebbleAnalyticsHelper){
                    window.trebbleAnalyticsHelper.trackEvent("audioEditor", "sequencerNotVisibleToContinueOnboarding", "Sequencer not visible to continue onboarding");
                }
            }
        }).bind(this), 200);

    }).bind(this),
    text: window.getI18n(ti18n, "NEXT")
}
],
id: 'onboarding_select_and_modify_audio_step'
};
},

_createTourStepForDeletingAudio: function(tour){
return {
title: window.getI18n(ti18n, "ONBOARD_STEP_2_TITLE_DELETE_AUDIO"),
text:  window.getI18n(ti18n, "ONBOARD_STEP_2_DESC_DELETE_AUDIO"),
attachTo: {
    element: this.$el.find("#ignoreSelectedNodesBtn").get(0),
    on: 'top'
},
arrow: true,
modalOverlayOpeningPadding: 10,
buttons: [
{
    action() {
        return tour.cancel();
    },
    classes: 'shepherd-button-secondary',
    text: window.getI18n(ti18n, "SKIP_TOUR")
},
{
    action : (function(){
            //this._selectFourLinesOfTextIfContextMenuIsNotDisplayed();
        setTimeout((function(){
            if(this.$el.is(":visible")){
                tour.addStep(this._createTourStepAddBackgroundAudio(tour));
                return tour.next();
            }else{
                if(window.trebbleAnalyticsHelper){
                    window.trebbleAnalyticsHelper.trackEvent("audioEditor", "sequencerNotVisibleToContinueOnboarding", "Sequencer not visible to continue onboarding");
                }
            }
        }).bind(this), 200);

    }).bind(this),
    text: window.getI18n(ti18n, "NEXT")
}
],
id: 'onboarding_delete_audio_step'
};
},

_createTourStepAddBackgroundAudio: function(tour){
return {
title: window.getI18n(ti18n, "ONBOARD_STEP_3_TITLE_ADD_BACKGROUND_AUDIO"),
text:  window.getI18n(ti18n, "ONBOARD_STEP_3_DESC_ADD_BACKGROUND_AUDIO"),
attachTo: {
    element: this.$el.find("#insertElementBtn").get(0),
    on: 'top'
},
arrow: true,
modalOverlayOpeningPadding: 10,
buttons: [
{
    action() {
        return tour.cancel();
    },
    classes: 'shepherd-button-secondary',
    text: window.getI18n(ti18n, "SKIP_TOUR")
},
{
    action : (function(){
        if(this.$el.is(":visible")){
            //tour.addStep(this._createTourStepForNoiseReduction(tour));
            tour.addStep(this._createTourStepForAutomatedActionsConfig(tour));
            return tour.next();
        }else{
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("audioEditor", "sequencerNotVisibleToContinueOnboarding", "Sequencer not visible to continue onboarding");
            }
        }
    }).bind(this),
    text: window.getI18n(ti18n, "NEXT")
}
],
id: 'onboarding_add_background_audio_step'
};
},

_createTourStepForNoiseReduction: function(tour){
return {
title: window.getI18n(ti18n, "ONBOARD_STEP_4_TITLE_REMOVE_BACKGROUND_NOISE"),
text:  window.getI18n(ti18n, "ONBOARD_STEP_4_DESC_REMOVE_BACKGROUND_NOISE"),
attachTo: {
    element: this.$el.find("#noiseCancellationBtn").get(0),
    on: 'auto'
},
arrow: true,
buttons: [
{
    action() {
        return tour.cancel();
    },
    classes: 'shepherd-button-secondary',
    text: window.getI18n(ti18n, "SKIP_TOUR")
},
{
    action : (function(){
        if(this.$el.is(":visible")){
            tour.addStep(this._createTourStepForMagicSoundEnhancerConfig(tour));
            return tour.next();
        }else{
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("audioEditor", "sequencerNotVisibleToContinueOnboarding", "Sequencer not visible to continue onboarding");
            }
        }
    }).bind(this),
    text: window.getI18n(ti18n, "NEXT")
}
],
id: 'onboarding_noise_reduction_step'
};
},

_createTourStepForMagicSoundEnhancerConfig: function(tour){
return {
title: window.getI18n(ti18n, "ONBOARD_STEP_5_TITLE_ENHANCE_SOUND"),
text:  window.getI18n(ti18n, "ONBOARD_STEP_5_DESC_ENHANCE_SOUND"),
attachTo: {
    element: this.$el.find("#showBeatifyMenuBtn").get(0),
    on: 'auto'
},
arrow: true,
buttons: [

{
    action: (function(){
        if(this.$el.is(":visible")){
            return tour.next();
        }else{
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("audioEditor", "sequencerNotVisibleToContinueOnboarding", "Sequencer not visible to continue onboarding");
            }
        }
    }).bind(this),
    text: window.getI18n(ti18n, "GOT_IT")
}
],
id: 'onboarding_magic_sound_enhancer_step'
};
},

_createTourStepForAutomatedActionsConfig: function(tour){
return {
title: window.getI18n(ti18n, "ONBOARD_STEP_4_TITLE_AUTOMATED_ACTIONS"),
text:  window.getI18n(ti18n, "ONBOARD_STEP_4_DESC_AUTOMATED_ACTIONS"),
attachTo: {
    element: this.$el.find("#showAutomatedOperationsMenuBtn").get(0),
    on: 'bottom'
},
arrow: true,
buttons: [

{
    action: (function(){
        if(this.$el.is(":visible")){
            return tour.next();
        }else{
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("audioEditor", "sequencerNotVisibleToContinueOnboarding", "Sequencer not visible to continue onboarding");
            }
        }
    }).bind(this),
    text: window.getI18n(ti18n, "GOT_IT")
}
],
id: 'onboarding_automated_actions_step'
};
},


_selectFourLinesOfTextIfContextMenuIsNotDisplayed : function(){
this.unselectCurrentlySelectedSequencerNodes();
this.addNextDownSequencerNodeToSelection();
this.addNextDownSequencerNodeToSelection();
this.addNextDownSequencerNodeToSelection();
this.addNextDownSequencerNodeToSelection();
this.addNextDownSequencerNodeToSelection();
},

getOnboardingCurrentStepIdDisplayed:function(){
return this._onboardingTour &&  this._onboardingTour.getCurrentStep()? this._onboardingTour.getCurrentStep().id:null;
},

showOnboardingInstruction:function(){
if(this.model.getFirstNode()){
setTimeout((function(){
    if(!this._onboardingTour && this.$el.is(":visible")){
        try{
            this._stopListeningForKeyboardEvents();
            const tour = new Shepherd.Tour({
                useModalOverlay: true,
                keyboardNavigation:false,
                defaultStepOptions: {
                    cancelIcon: {
                        enabled: false
                    },

                    classes: 'sequencer-onboarding-popup',
                    scrollTo: { behavior: 'smooth', block: 'center' }
                }
            });
                //this._selectFourLinesOfTextIfContextMenuIsNotDisplayed();
            tour.addStep(this._createTourStepForSelectAudioToModify(tour));


            tour.start();
            this._onboardingTour = tour;
            tour.once("start", (function(){
                this.trigger("onboardingTourStarted");
                if(window.trebbleAnalyticsHelper){
                    window.trebbleAnalyticsHelper.trackEvent("audioEditor", "EditorOnboardingStarted", "Editor Onboarding Started ");
                }
            }.bind(this)))
            tour.once("complete", (function(){
                this.trigger("onboardingTourCompleted");
                this._onboardingTour = null;
                this._listenForKeyboardEvents();
                if(window.trebbleAnalyticsHelper){
                    window.trebbleAnalyticsHelper.trackEvent("audioEditor", "EditorOnboardingCompleted", "Editor Onboarding Completed ");
                }
            }.bind(this)))
            tour.once("cancel", (function(){
                this.trigger("onboardingTourCancelled");
                this._onboardingTour = null;
                this._listenForKeyboardEvents();
                if(window.trebbleAnalyticsHelper){
                    window.trebbleAnalyticsHelper.trackEvent("audioEditor", "EditorOnboardingCancelled", "Editor Onboarding Cancelled ");
                }
            }.bind(this)))

            tour.on("show", (function(){
                this.trigger("onOnboardingStepDisplayed");
                const stepDisplayedId = this.getOnboardingCurrentStepIdDisplayed();
                if(window.trebbleAnalyticsHelper){
                    window.trebbleAnalyticsHelper.trackEvent("audioEditor", "EditorOnboardingStepDisplayed", "Editor Onboarding Step Displayed ", null, {"stepDisplayedId": stepDisplayedId});
                }
            }.bind(this)))

        }catch(error){
            console.error(error);
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("audioEditor", "FailedToCreateAudioEditorOnboarding", "Failed to create audio editor onboarding ", null, {"error": error});
            }
        }
    }else{
        if(!this._onboardingTour && window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("audioEditor", "sequencerNotVisibleToContinueOnboarding", "Sequencer not visible to continue onboarding");
        }
    }
}).bind(this), 200);
}
},

_showWarningToSwitchToChromeIfApplicable: function(){
if(USE_SCRIPT_PROCESSOR_IN_SAFARI && (window.isSafariBrowser || window.isIOSMobileDevice)){
this.$el.attr("show_switch_to_chrome_warning", "true");
if(window.isIOSMobileDevice){
    this.$el.find("#warning_trebble_works_best_in_other_browsers").attr("show_action_link","false");
}
}
},

_hideEditorSearchComponentToLeftPanel : function(){
    if(this._searchEditorPanelReactRoot){
        this.$el.find(".left_section").css("display","none");
        this._searchEditorPanelReactRoot.unmount();
        this._searchEditorPanelReactRoot = null;
    }
},
_showEditorSearchComponentToLeftPanel : function(){
    if(!this._searchEditorPanelReactRoot){
        const onPanelOpen =()=>{
            this.$el.find(".left_section").css("display","block");
            this.trigger("searchTurnedOn");
        }
        const onPanelCloseButtonClick =()=>{
            this.onExitCurrentMode();
        }
        const root = createRoot(this.$el.find(".sequencer_settings_container").get(0));
        
        const props =  {
            searchDisplayTitle:"Results",
            searchWidgetModel: this.model.getSearchWidget(),
            title:"SEARCH",
            onPanelOpen: onPanelOpen,
            onPanelCloseButtonClick: onPanelCloseButtonClick,
            listHeightOffset: 244,
            sequencer: this.model
        }
        root.render(<EditorSearchPanel {...props}  />);
        this._searchEditorPanelReactRoot = root;
    }else{
        if(this.model.getSearchWidget().getSearchType() !== this.model.getSearchWidget().SEARCH_TYPE.TEXT_SEARCH){
            this.model.clearSearch();
            this.model.getSearchWidget().applyTextSearch("");
            this.model.setSearchOn(true);
        }
    }
    
},

onRemovedFromPage: function(){
this.undelegateEvents();
this.$el.removeData().unbind(); 
this.stopListening();
if(this._searchEditorPanelReactRoot){
    this._searchEditorPanelReactRoot.unmount();
    this._searchEditorPanelReactRoot = null;
}
this.trigger("removedFromPage", this);
},

initVideoContext :function(){
const canvasEl =this.$el.find("#sequencer_video_canvas").get(0);
if(canvasEl){
this._videoCtx = new VideoContext(canvasEl, null,
{
    manualUpdate :false,
    endOnLastSourceEnd : false,
    useVideoElementCache : true,
    videoElementCacheSize : 6,
    webglContextAttributes : {}
});
this.model.setVideoContext(this._videoCtx);
}
},

render : function() {	


this.$el.html(this.compiledTemlate({"ti18n": ti18n, "isSearchAllowed": ALLOW_TEXT_SEARCH_IN_AUDIO_EDITOR,"isPlaybackOfSearchResultsAllowed": ALLOW_PLAYBACK_OF_SEARCH_RESULTS, "getTooltip": this._getTooltipFunction(), "CONFIGURE_SOUND_ENHANCING": CONFIGURE_SOUND_ENHANCING, displaySearchWidget: !SHOW_SEARCH_WIDGET_ON_LEFT_SECTION_OF_EDITOR}));
this.setElement(this.$el.find("div").first());
this._playerSlider$el = this.$el.find("#playerSongPositionSlider");
this.timeElapsed$el = this.$el.find("#time_ellapsed");
this.totalTime$el =  this.$el.find("#total_time");
this.autoScrollBtn$el = this.$el.find("#autoScrollBtn");
this.noiseCancellationBtn$el = this.$el.find("#noiseCancellationBtn");
this.configureViewBtn$el = this.$el.find("#configureViewBtn");
this.showBeatifyMenuBtn$el = this.$el.find("#showBeatifyMenuBtn");
this.showAutomatedOperationsMenuBtn$el = this.$el.find("#showAutomatedOperationsMenuBtn");
this.showHelpBtn$el = this.$el.find("#showHelpBtn");
this.bottomBar$el = this.$el.find(".bottom_toolbar");
this.headerBar$el = this.$el.find(".header_toolbar");
this.searchWidgetFloatingMenu$el = this.$el.find(".search_widget.floating_menu");
this.searchInput$el = this.$el.find(".search_input");

this.showNextSearchBtn$el  = this.$el.find("#nextResultBtn");
this.showPreviousSearchBtn$el  = this.$el.find("#previousResultBtn");
this.focusSearchIndexLabel$el  = this.$el.find(".focus_search_index");
this.totalSearchResult$el  = this.$el.find(".total_search_result");
this.mainSection$el = this.$el.find(".main_section");
this.playSelectionBtn$el = this.$el.find("#playSelectionBtn");
this.playWithoutSelection$el = this.$el.find("#playWithoutSelection");
this.deleteSelectionBtn$el = this.$el.find("#deleteSelectionBtn");
this.deleteAllHighligthed$el = this.$el.find("#removeAllHightlightedBtn");

this._playerSliderEl= this._playerSlider$el.get(0);
this._sequencer_node_inspector_container$el = this.$el.find(".sequencer_node_inspector_container");
this._sequencer_settings_container$el = this.$el.find(".sequencer_settings_container");
this._initPlayerSlider();
if(this._sequencerNodeInspectorView){
this._sequencerNodeInspectorView.render();
this._sequencer_node_inspector_container$el.append(this._sequencerNodeInspectorView.$el)
}


if(this._sequencerSettingsView){
this._sequencerSettingsView.render();
this._sequencer_settings_container$el.append(this._sequencerSettingsView.$el);
}
this.sequencerNodeContainer$el = this.$el.find(".sequencer_node_container");
if(!this.model.showNotDisplayedNotExplicitelyDisplayedPause()){
this.sequencerNodeContainer$el.addClass("do_not_show_pause_non_explicitely_displayed");
}

if(BREAK_DOWN_TEXT_IN_AUDIO_EDITOR_AUTOMATICALLY){
this.sequencerNodeContainer$el.addClass("break_down_text_automatically");
}
this.searchInput$el.on("keyup input", this.onSearchInputChange.bind(this));
this._setupVisibilityObservers();
//this._makeSequencerNodesMovable()
this._initRenderOfSequencer();

if(window.isMobileBrowser && !window.isSafariBrowser){
//work around for android chrome around selection
//	this._hideHeaderOrBottomBarVisibilityOnAndroidMobileOnSelectionChangeListener = this._hideHeaderOrBottomBarVisibilityOnAndroidMobileOnSelectionChange.bind(this);
//	document.addEventListener('selectionchange', this._hideHeaderOrBottomBarVisibilityOnAndroidMobileOnSelectionChangeListener);
this._listenToSelectionChangeListener = this._listenToSelectionChange.bind(this);
document.addEventListener('selectionchange', this._listenToSelectionChangeListener);
}

if(window.isMobileBrowser){
this.sequencerNodeContainer$el.on("touchstart", (function(e){
    $("body").one("touchend",this._markCurrentlySelectedNodesAsSelected.bind(this))
}).bind(this));
}else{
$("body").on( "click",this._markCurrentlySelectedNodesAsSelected.bind(this));
}

/*if(this._isFirefoxBrowser()){
this.sequencerNodeContainer$el.on("tap", this._markCurrentlySelectedNodesAsSelected.bind(this));
}*/
if(window.isMobileBrowser){
if(!window.isSafariBrowser){
    //For android chrome
    this.sequencerNodeContainer$el.on("tap", this._onTapOnAndroidChrome.bind(this));
}else{
    this.sequencerNodeContainer$el.on("tap", this._markCurrentlySelectedNodesAsSelected.bind(this));
}
}
this.$el.attr("hide_pauses_under_ten_milliseconds",HIDE_PAUSE_UNDER_10_MILLISECONDS?"true":"false");
this.$el.on("remove", this.onRemovedFromPage.bind(this));
this.undoBtn$el = this.$el.find("#undoBtn");
this.redoBtn$el = this.$el.find("#redoBtn");
this._listenForScrollEventOnSequencerNodeContainer();
this.onHistoryUpdated();
this._onSelectionChange();
this.onAutoscrollChange();
this.onHasVideoSegmentChanged();
this.setSequencerSettingsDisplayed(false);
this.setSequencerAutomationOperationsMenuDisplayed(false);
this._showWarningToSwitchToChromeIfApplicable();
if(ENABLE_NOISE_CANCELLATION_FEATURE){
this.onNoiseCancellationOnChange();
}else{
this.noiseCancellationBtn$el.hide();
}
this.onShowIgnoredContentSettingChange();
this.onRevealDeletedContentSettingChange();
this.onShowGapNodesSettingChange();
this.onShowEditBoundariesSettingChange();
this.onShowDarkThemeChange();
this.onAutoscrollChange();
this.initVideoContext();
this._initializeTippyJsTooltips(this.$el, false);

this._disableContextMenuForiOS();

return this;
}

});
export default SequencerView;