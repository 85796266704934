import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import OptionPopupView from "views/common/OptionPopupView";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";

const UserGivesFeedbackPopupView = OptionPopupView.extend({


  initialize: function(options) {
    const viewOptions = {};
    const contentView = {};
    const contentViewTemplate = _.template(options.template);
    const ti18n = options.ti18n;
    const templateParams = {};
    templateParams.ti18n =  ti18n;
    templateParams.template = options.template;
    templateParams.headline = options.headline;
    templateParams.buttonLabel = options.buttonLabel;
    templateParams.textareaPlaceholderText = options.textareaPlaceholderText;
    templateParams.imageUrl = options.imageUrl;
    this._feedbackFieldValid  = false;

    
    contentView.$el = $(contentViewTemplate(templateParams));
    viewOptions.contentView = contentView;
    viewOptions.addContentAfterEnhancement = true;
    viewOptions.contentPosition = "center";
    this._popupUniqueId = "WelcomeNewListenerPopupView";
    viewOptions.id = this._popupUniqueId;
    viewOptions.customClass = "whatsnew";

    OptionPopupView.prototype.initialize.call(this, viewOptions);
    PersistentModels.getInstance().getRouter().registerForViewportHeightChange(this._popupUniqueId,this.onOrientationChangeFunction.bind(this), true);
  },

  onOrientationChangeFunction  : function(newViewPortHeigh, forceResizing) {
   if(this.$el.is(":visible") || forceResizing){
    if (this.el) {
     const adjustedListContainerHeight = (newViewPortHeigh) ;
     this.$el.find(".ui-content").css("height", adjustedListContainerHeight + "px");
   }
 }
},

afterRendering: function() {


},

onSendFeedbackButtonClicked : function(){
const feedback = this.feedbackField$el.val();
if(feedback){
  this.trigger("sendFeedback",feedback);
}

},

onFeedbackValueChanged: function(){
const feedback = this.feedbackField$el.val();
if(feedback){
  //empty field
  this.sendFeedbackButton$el.removeClass("disabled");
  this._feedbackFieldValid  = true;
}else{
  if(this.sendFeedbackButton$el.hasClass("disabled")){
    this.sendFeedbackButton$el.addClass("disabled");
  }
  this._feedbackFieldValid  = false;
}
},

onPopupClosed: function() {
OptionPopupView.prototype.onPopupClosed.call(this);
PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._popupUniqueId, true);
this.trigger("onPopupClosed");
},


onAfterPageShow: function() {
this.onOrientationChangeFunction(PersistentModels.getInstance().getRouter().getViewportHeight(), true);
this.feedbackField$el =  this.$el.find("#feedbackField");
this.feedbackField$el.on(" keyup input", this.onFeedbackValueChanged.bind(this));
this.feedbackField$el.focus();
this.sendFeedbackButton$el = this.$el.find("#sendFeedbackButton");
this.sendFeedbackButton$el.click(this.onSendFeedbackButtonClicked.bind(this));

},




});

export default UserGivesFeedbackPopupView;
