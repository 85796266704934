
import _ from 'underscore';
import Backbone from "backbone";
import { NonRealTimeVAD } from "@ricky0123/vad-web";
import AudioBufferUtils from "models/audioEditor/AudioBufferUtils";




const SILERO_VAD_TARGET_SAMPLE_RATE = 16000;
const VoiceActivityDetectionHelper = Backbone.Model.extend({
    idAttribute: "id",

    constructor: function(attributes, options) {
        Backbone.Model.apply(this, [attributes, options]);
        this._voiceActivityDetectorInstance =  null;
        this._voiceActivityDetectorInstanceBeingCreated =  null;
    },  

    _createVoiceActivityDetector : async function(){
        const voiceActivityDetector = await NonRealTimeVAD.new({

        });
        return voiceActivityDetector;
      },

      getVoiceActivityDetector : async function(){
        if(this._voiceActivityDetectorInstance){
            return this._voiceActivityDetectorInstance;
        }
        if(this._voiceActivityDetectorInstanceBeingCreated){
            return await this._voiceActivityDetectorInstanceBeingCreated;
        }else{
            this._voiceActivityDetectorInstanceBeingCreated = this._createVoiceActivityDetector();
            this._voiceActivityDetectorInstance = await this._voiceActivityDetectorInstanceBeingCreated;
            this._voiceActivityDetectorInstanceBeingCreated = null;
            return this._voiceActivityDetectorInstance;
        }
      },

      containsVoiceActivity : function(voiceActivityDetectionResultArray, startTimeInMilliseconds, endTimeInMilliseconds){
        if(!voiceActivityDetectionResultArray){
            return false;
        }
        for(let i =0; i < voiceActivityDetectionResultArray.length; i++){
            const startAndEndPeriod = voiceActivityDetectionResultArray[i];
            const startPeriod = startAndEndPeriod[0];
            const endPeriod = startAndEndPeriod[0];
            if(startPeriod <= startTimeInMilliseconds && startPeriod >= endTimeInMilliseconds){
                return true;
            }
            if(endPeriod <= startTimeInMilliseconds && endPeriod >= endTimeInMilliseconds){
                return true;
            }
            if(startTimeInMilliseconds <= startPeriod && endPeriod <= endTimeInMilliseconds){
                return true;
            }
        }
        return false;

      },

      detect : async function (audioBuffer){
        const monoFloat32Array = AudioBufferUtils.getInstance().mixDownToMono(audioBuffer);
        const voiceActivityDetector = await this.getVoiceActivityDetector();
        const results =  [];
        for await (const {audio, start, end} of voiceActivityDetector.run(monoFloat32Array, audioBuffer.sampleRate)) {
          results.push([start, end]);
        }
        return results
      },


      


});



const VoiceActivityDetectionHelperInstance = new VoiceActivityDetectionHelper();

export default {
    getInstance: function ()
    {
        return VoiceActivityDetectionHelperInstance;
    }
}; 