import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import BasicInspectorNodeParamView from "views/audioEditor/sequencerNodeInspectorSection/paramView/BasicInspectorNodeParamView";
import DurationParamTemplate from 'text!../../../../../templates/audioEditor/sequencerNodeInspectorSection/DurationParamTemplate.html';
import RSVP from 'rsvp';


const DurationInSecondsParamView = BasicInspectorNodeParamView.extend({

    constructor : function(attributes, options) {
        BasicInspectorNodeParamView.apply(this, [attributes, options]);
        this.compiledTemlate =  _.template(DurationParamTemplate);
        this._maxValueInSeconds = null;
        this._minValueInSeconds = null;
        this._editable = false;
    },			

    setMaxValueInSeconds : function(maxValueInSeconds){
        this._maxValueInSeconds = maxValueInSeconds;
    },

    getMaxValueInSeconds : function(){
        return this._maxValueInSeconds;
    },

    setMinValueInSeconds : function(minValueInSeconds){
        this._minValueInSeconds = minValueInSeconds;
    },

    getMinValueInSeconds : function(){
        return this._minValueInSeconds;
    },

    setEditable : function(editable){
        this._editable = editable;
        if(this.durationInput$el){
            if(editable){
                this.durationInput$el.removeAttr("disabled");
            }else{
                this.durationInput$el.attr("disabled", "disabled");
            }
        }
    },

    getEditable : function(){
        return this._editable;
    },
    
    updateViewOnValueChange : function(newValue){
        if(this.durationInput$el && (this.getMinValueInSeconds() == null || newValue >= this.getMinValueInSeconds())  && (this.getMaxValueInSeconds() == null || newValue <= this.getMaxValueInSeconds())){
            this.durationInput$el.val(newValue)
        }
    },

    render : function(){
        this.$el.html(this.compiledTemlate({ "viewObj": this}));
        this.setElement(this.$el.find(".basic_inpector_param_view").first());
        this.durationInput$el = this.$el.find(".duration_value input");
        this.$el.enhanceWithin();
        this.durationInput$el.on("change", (function(){
            const newValue = parseFloat(this.durationInput$el.val());
            if(!isNaN(newValue)){
                this.onViewValueChanged(newValue);
            }
        }).bind(this))
    }
});
export default DurationInSecondsParamView;