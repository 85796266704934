import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import PlayerModel from "models/player/PlayerModel";
import PlayerFooterView from "views/playerFooter/PlayerFooterBrowserView";
import PersistentModels from "services/PersistentModels";
import PlayerService from "services/PlayerService";
import Utils from "models/helper/Utils";
import TrebbleClientAPI from "models/helper/TrebbleClientAPI";
import ti18n from "i18n!nls/PlayerPagei18n";
import RSVP from "rsvp";

const PlayerFooterBrowserController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if (options) {
            if (options.view) {
                this.view = options.view;
            }
            if (options.model) {
                this.model = options.model;
            }
        }
        this.canBeShownOnActivePage = (options && options.canBeShownOnActivePage) || false;
        this.transparent = (options && options.transparent) || false;

    },

    getModel : function() {
        return this.model;
    },

    setCanBeShownOnActivePage : function(canBeShownOnActivePage, transparent){
        this.canBeShownOnActivePage = canBeShownOnActivePage;
        this.transparent = transparent;
        if(this.getView())
        {
            this.getView().setCanBeShownOnActivePage(this.canBeShownOnActivePage, transparent);
        }
    },

    updateActivePageHeightToMatchPlayerFooterHeight : function(){
        $.mobile.resetActivePageHeight(this.canBeShownOnActivePage? window.getDocumentClienHeight() +60: null); 
    },

    isPlayerFooterViewDisplayed : function(){
        if(this.getView())
        {
            return this.getView().isPlayerFooterViewDisplayed();
        }
        return false;
    },

    isPlayerFooterViewTransparent : function(){
        return this.transparent;
    },

    getView : function() {
        return this.view;
    },

    showPlayerPage :function(){
        PersistentModels.getInstance().getRouter().showPlayerPage(true, true);
    },

    showQueuePage : function(){
        PersistentModels.getInstance().getRouter().showQueuePage(true);
    },

    showMenuOptionPopup : function(){
        let songRefSummary = null;
        return this.model.getCurrentSongRefSummaryIfApplicable().then((function(refSummary){
            songRefSummary = refSummary;
            return this.model.getCurrentSong();
        }).bind(this)).then((function(currentSong){
            if(currentSong)
            {
                const context = this.getQueueContext();
                PersistentModels.getInstance().getRouter().showSongMenuOption(currentSong, context,songRefSummary, this.onActionExecutedOnSongHandler.bind(this));
            }
        }).bind(this));

    },

    showMoreInformationPopup: function(){
        this.showCapsules();
    },
    onActionExecutedOnSongHandler : function(songModel, action){
        if(action == "delete" || action == "removeFromRadio" || action == "removeToFavorite"){
            //TODO: should probably remove it from the queue and go to the next song
        }
    },


    getQueueContext : function(){
        const context = this.model.getPlaylist()? this.model.getPlaylist().getContext(): null;
        return context;
    },

    addSongToRadio : function(){
        const songModelPlaying =  null;
        const context = this.getQueueContext();
        let songRefSummary = null;
        this.view.setSongBeingAddedOrRemovedFromTrebble(true, false);
        return this.model.getCurrentSongRefSummaryIfApplicable().then((function(refSummary){
            songRefSummary = refSummary;
            return this.model.getCurrentSong();
        }).bind(this)).then((function(currentSong){
            if(currentSong){
                return PersistentModels.getInstance().addSongToDefaultRadio(currentSong, context, songRefSummary).then((function(){
                    this.view.setSongBeingAddedOrRemovedFromTrebble(false, true);
                    //this.view._toggleAddedToRadioBtnClass(true);
                }).bind(this));
            }else{
                this.view.setSongBeingAddedOrRemovedFromTrebble(false, false);
                return null;
            }
        }).bind(this)).catch((function(error){
            this.view.setSongBeingAddedOrRemovedFromTrebble(false, false);
            window.alertErrorMessage(error);
        }).bind(this));
    },

    addCapsuleToRadio : function(){
        const songModelPlaying =  null;
        const context = this.getQueueContext();
        const songRefSummary = null;
        this.view.setSongBeingAddedOrRemovedFromTrebble(true, false);
        return this.model.getCapsuleInfoCurrentlyPlaying().then((function(capsuleJson){
            if(capsuleJson){
                const capsuleModel = Utils.getInstance().getModelFromCapsuleJson(capsuleJson);
                return PersistentModels.getInstance().getRouter().addCapsuleToTrebbleViaPopup(capsuleModel,this.context).then((function(capsuleCreated){
                    this.view.setSongBeingAddedOrRemovedFromTrebble(false,true);
                    return this.model.getCurrentSong().then((function(currentSong){
                        currentSong.set("airingOnRadios", capsuleModel.getAiringOnRadiosInfo());
                    }).bind(this));
                    //this.view._toggleAddedToRadioBtnClass(true);
                }).bind(this));
            }else{
                this.view.setSongBeingAddedOrRemovedFromTrebble(false, false);
                return null;
            }
        }).bind(this)).catch((function(error){
            this.view.setSongBeingAddedOrRemovedFromTrebble(false,false);
            window.alertErrorMessage(error);
        }).bind(this));
    },


    removeFromSongFromRadio : function(){
        const songModelPlaying =  null;
        const context = this.getQueueContext();
        let songRefSummary = null;
        this.view.setSongBeingAddedOrRemovedFromTrebble(true,true);
        return this.model.getCurrentSongRefSummaryIfApplicable().then((function(refSummary){
            songRefSummary = refSummary;
            return this.model.getCurrentSong();
        }).bind(this)).then((function(currentSong){
            if(currentSong){
                return PersistentModels.getInstance().removeSongFromDefaultRadio(currentSong, context, songRefSummary).then((function(){
                    this.view.setSongBeingAddedOrRemovedFromTrebble(false,false);
                    //this.view._toggleAddedToRadioBtnClass(false);
                }).bind(this));
            }else{
                this.view.setSongBeingAddedOrRemovedFromTrebble(false,true);
                return null;
            }
        }).bind(this)).catch((function(error){
            this.view.setSongBeingAddedOrRemovedFromTrebble(false,true);
            window.alertErrorMessage(error);
        }).bind(this));
    },

    removeCapsuleFromRadio : function(){
        const songModelPlaying =  null;
        const context = this.getQueueContext();
        const songRefSummary = null;
        this.view.setSongBeingAddedOrRemovedFromTrebble(true, true);
        return this.model.getCapsuleInfoCurrentlyPlaying().then((function(capsuleJson){
            if(capsuleJson){
                const capsuleModel = Utils.getInstance().getModelFromCapsuleJson(capsuleJson);
                return PersistentModels.getInstance().removeCapsuleFromRadio(capsuleModel,PersistentModels.getInstance().getAllMyPersonalRadioIds(),this.context).then((function(){
                    this.view.setSongBeingAddedOrRemovedFromTrebble(false,false);
                    return this.model.getCurrentSong().then((function(currentSong){
                        currentSong.set("airingOnRadios", capsuleModel.getAiringOnRadiosInfo());
                    }).bind(this));
                    //this.view._toggleAddedToRadioBtnClass(true);
                }).bind(this));
            }else{
                this.view.setSongBeingAddedOrRemovedFromTrebble(false, true);
                return null;
            }
        }).bind(this)).catch((function(error){
            this.view.setSongBeingAddedOrRemovedFromTrebble(false, true);
            window.alertErrorMessage(error);
        }).bind(this));
    },

    showTagManagerForSongPlaying : function(){
        return this.model.getCurrentSong().then((function(currentSong){
            if(currentSong){
                const context = this.getQueueContext();
                PersistentModels.getInstance().getRouter().showSongTagManagerPopup(currentSong, context);
            }else{
                return null;
            }
        }).bind(this));
    },

    onUserMoveSlider : function(newSliderValue){
        this.model.seekTo(newSliderValue/100);
    },

    showSongInfo : function(){
        window.alertErrorMessage(window.getI18n(ti18n, "COMING_SOON_THIS_IS_WHERE_BLAH"));
    },

    showConversationOnSong : function(){
        let songRefSummary = null;
        return this.model.getCurrentSongRefSummaryIfApplicable().then((function(refSummary){
            songRefSummary = refSummary;
            return this.model.getCurrentSong();
        }).bind(this)).then((function(currentSong){
            if(currentSong){
                const context = this.getQueueContext();
                if(window.isEmbedded){
                    const newPageUrl =  window.trebble.supportPushState ? location.origin + window.getAppBaseUrl() + "songComments/"+currentSong.get("id") : location.origin +  window.getAppBaseUrl() + "#" + "songComments/"+currentSong.get("id");
                    const w = 500;
                    const h = 740;
                    const left = window.getLeftPositionForPopupWindow(w);
                    const top = window.getTopPositionForPopupWindow(h);
                    const openedWindow = window.open(newPageUrl, "_blank", "toolbar=no, scrollbars=yes, resizable=yes, height="+h+",width="+w+",top="+top+",left="+left+"");
                    if((!openedWindow || openedWindow.closed || typeof openedWindow.closed=='undefined') && !window.waitForCordovaToLoad){
                        window.alertErrorMessage(window.getI18n(ti18n, "PLEASE_ALLOW_POPUPS_FOR_TREBBLE"));
                    }
                }else{
                    return PersistentModels.getInstance().getRouter().showCommentsForSong(currentSong,false,null, context,songRefSummary, true);
                }
            }
        }).bind(this));
},
_showCapsuleById: function(capsuleId){
return TrebbleClientAPI.getInstance().getCapsuleInfoByCapsuleId(capsuleId? capsuleId: this.capsuleId).then(function(capsuleJson){
if(capsuleJson){
    return Utils.getInstance().getModelFromCapsuleJson(capsuleJson);
}else{
    return null;
}
}).then((function(capsuleModel){
if(capsuleModel){
    if(window.isEmbedded){
        const newPageUrl = window.trebble.supportPushState ? location.origin +  window.getAppBaseUrl() + "showCapsuleForCapsuleId/" + capsuleModel.getCapsuleId() : location.origin +  window.getAppBaseUrl() + "#" + "showCapsuleForCapsuleId/" + capsuleModel.getCapsuleId();
        const w = 500;
        const h = 740;
        const left = window.getLeftPositionForPopupWindow(w);
        const top = window.getTopPositionForPopupWindow(h);
        const openedWindow = window.open(newPageUrl, "_blank", "toolbar=no, scrollbars=yes, resizable=yes, height="+h+",width="+w+",top="+top+",left="+left+"");
        if((!openedWindow || openedWindow.closed || typeof openedWindow.closed=='undefined') && !window.waitForCordovaToLoad){
            window.alertErrorMessage(window.getI18n(ti18n, "PLEASE_ALLOW_POPUPS_FOR_TREBBLE"));
        }
    }else{
        return PersistentModels.getInstance().getRouter().showCapsuleInPopup(capsuleModel, this.getQueueContext() ,false, true, true);
    }
}else{
    return this._showCapsulesForCurrentLoadedSong();
}
}).bind(this));
},

_showCapsulesForCurrentLoadedSong: function(){
    let songRefSummary = null;
return this.model.getCurrentSongRefSummaryIfApplicable().then((function(refSummary){
songRefSummary = refSummary;
return this.model.getCurrentSong();
}).bind(this)).then((function(currentSong){
if(currentSong){
    const context = this.getQueueContext();
    const capsuleUID = songRefSummary.capsuleRefUID;
    const songUID = songRefSummary.songRefUID;
    if(capsuleUID){
        //Current Song is a Capsule
        this._showCapsuleById(capsuleUID);
    }else{
        if(songUID){
            if(window.isEmbedded){
                const newPageUrl = window.trebble.supportPushState ? location.origin +  window.getAppBaseUrl() + "songCapsules/"+currentSong.get("id") :  location.origin +  window.getAppBaseUrl() + "#" + "songCapsules/"+currentSong.get("id");
                const w = 500;
                const h = 740;
                const left = window.getLeftPositionForPopupWindow(w);
                const top = window.getTopPositionForPopupWindow(h);
                const openedWindow = window.open(newPageUrl, "_blank", "toolbar=no, scrollbars=yes, resizable=yes, height="+h+",width="+w+",top="+top+",left="+left+"");
                if((!openedWindow || openedWindow.closed || typeof openedWindow.closed=='undefined') && !window.waitForCordovaToLoad){
                    window.alertErrorMessage(window.getI18n(ti18n, "PLEASE_ALLOW_POPUPS_FOR_TREBBLE"));
                }
            }else{
                return PersistentModels.getInstance().getRouter().showCapsulesForSong(currentSong, context, songRefSummary);
            }

        }
    }
}
}).bind(this));
},

showCapsules : function(){
const currentloadedSong = null;
$.mobile.loading( 'show');

return this.model.getCurrentCapsuleSummaryIfApplicable().then((function(capsuleSummary){
$.mobile.loading( 'hide');
if(capsuleSummary && capsuleSummary.activeCapsuleId){
    return this._showCapsuleById(capsuleSummary.activeCapsuleId);
}else{
    return this._showCapsulesForCurrentLoadedSong();
}
}).bind(this)).then((function(){
$.mobile.loading( 'hide');
}).bind(this)).catch((function(error){
$.mobile.loading( 'hide');
window.alertErrorMessage(error);
}).bind(this));
/*this.model.getCurrentSong().then((function(currentSongModel){
currentloadedSong = currentSongModel;
if(currentSongModel){
    const songJsonForBackend = Utils.getInstance().getSongJsonForBackendFromSongModel(currentSongModel);
    return TrebbleClientAPI.getInstance().getCapsulesInfoForSong(songJsonForBackend);
}
}).bind(this)).then((function(capsuleJsonInfoArray){
$.mobile.loading( 'hide');
if(capsuleJsonInfoArray && capsuleJsonInfoArray.length > 0){
    const firstCapsuleJson = capsuleJsonInfoArray[0];
    const capsuleModel =  Utils.getInstance().getModelFromCapsuleJson(firstCapsuleJson);
    PersistentModels.getInstance().getRouter().showCapsuleByInfoEditPage(capsuleModel,capsuleModel.getCapsuleId(),null, true );
}else{
    window.alertErrorMessage("No Capsule was found for this song. Be the first to create one :)")
}
}).bind(this)).catch((function(error){
$.mobile.loading( 'hide');
window.alertErrorMessage(error);
}).bind(this));*/
},

showQueuetuning  : function(){
PersistentModels.getInstance().getRouter().showQueueTuningPopup(this.model.getQueue());
},

/*notifyUserOfUnnotifiedOwnerComments : function(){
const songRefSummary = null;
return this.model.getCurrentSongRefSummaryIfApplicable().then((function(refSummary){
songRefSummary = refSummary;
return this.model.getCurrentSong();
}).bind(this)).then((function(currentSong){
const context = this.getQueueContext();
return PersistentModels.getInstance().notifyUserOfUnnotifiedOwnerComments(currentSong, context, songRefSummary);
}).bind(this));
},*/

showUserProfileWithUsername : function(username){
if(username){
PersistentModels.getInstance().getRouter().showUserProfilePageByUsername(username,null, null, true );
}
},

liveLikeCurrentSong : function(){
this.model.liveLikeCurrentSong();
},

showSkipCurrentPlayingWithReasonPopup : function(sourceElement$el){
PersistentModels.getInstance().getRouter().showSkipCurrentPlayingWithReasonPopup(sourceElement$el);
},

showSendCapsuleToHost : function(){
if(this.model.getPlaylist() && this.model.getPlayerType() == PlayerModel.types.TREBBLE_PLAYER){
const trebbleId = this.model.getPlaylist().getLoaderKey();
if(trebbleId){
    return TrebbleClientAPI.getInstance().getHostUserIdByTrebbleId(trebbleId).then((function(hostUserId){
        if(hostUserId){
            return PersistentModels.getInstance().getRouter().showSendCapsuleToUserByUserIdIntroPage(hostUserId,null,null,true );
        }
    }).bind(this));
}
}else{
if(this.model.getPlayerType() == PlayerModel.types.CAPSULE_FEED_PLAYER){
    return this.model.getCurrentSong().then((function(currentSong){
        if(currentSong){
            const trebbleId = this.model.getPlaylist().getRadioIdForSongWitUri(currentSong.get("uri"));
            if(trebbleId){
                return TrebbleClientAPI.getInstance().getHostUserIdByTrebbleId(trebbleId).then((function(hostUserId){
                    if(hostUserId){
                        return PersistentModels.getInstance().getRouter().showSendCapsuleToUserByUserIdIntroPage(hostUserId,null,null,true );
                    }
                }).bind(this));
            }
        }
    }).bind(this));
}

}
},


buildModelAndRenderView : function() {
return new RSVP.Promise((function(resolve, reject) {
try {
    if(!this.model)
    {
        //this.model = new PlayerModel();
    }
    this.view = new PlayerFooterView({"model" : this.model, "canBeShownOnActivePage" : this.canBeShownOnActivePage });
    this.listenTo(this.view, "showPlayerPage", this.showPlayerPage);
    this.listenTo(this.view,"onQueueButtonTapped",this.showQueuePage);
    this.listenTo(this.view, "onPlayerMenuButtonTapped", this.showMenuOptionPopup);
    this.listenTo(this.view, "onPlayerMoreInformationButtonTapped", this.showMoreInformationPopup);
    
    this.listenTo(this.view, "addSongToRadio", this.addSongToRadio);
    this.listenTo(this.view, "addCapsuleToRadio", this.addCapsuleToRadio);
    this.listenTo(this.view, "removeFromSongFromRadio", this.removeFromSongFromRadio);
    this.listenTo(this.view, "removeCapsuleFromRadio", this.removeCapsuleFromRadio);
    this.listenTo(this.view, "showTagManagerForSongPlaying", this.showTagManagerForSongPlaying);

    this.listenTo(this.view, "userMoveSlider", this.onUserMoveSlider);

    this.listenTo(this.view, "showSongInfo", this.showSongInfo);
    this.listenTo(this.view, "showConversationOnSong", this.showConversationOnSong);
    this.listenTo(this.view, "showCapsules", this.showCapsules);
    this.listenTo(this.view, "showSendCapsuleToHost", this.showSendCapsuleToHost);
    this.listenTo(this.view, "showQueuetuning", this.showQueuetuning);
    //	this.listenTo(this.view, "notifiedUserOfUnnotifiedOwnerComments", this.notifyUserOfUnnotifiedOwnerComments);
    this.listenTo(this.view, "showUserProfileWithUsername", this.showUserProfileWithUsername);
    this.listenTo(this.view, "liveLikeButtonTapped",this.liveLikeCurrentSong);
    this.listenTo(this.view, "skipCurrentSongWithReason",this.showSkipCurrentPlayingWithReasonPopup)
    this.view.render().then(resolve);
    //	resolve();
} catch (error) {
    reject(error);
}

}).bind(this));

}

});

export default PlayerFooterBrowserController;