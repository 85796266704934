var amdi18n={"__root":{"ALL_MY_MUSIC_FROM":"All My Music From","COMING_SOON":"Coming Soon!","NO_ALBUM_FOUND_FOR_THIS_ARTIST":"No album was found  for this artist","SONG":"Song","SONGS":"Songs","MORE":"More","SORT_AND_FILTER":"Sort & Filter","ALBUMS":"Albums"},"__fr":{"ALL_MY_MUSIC_FROM":"Toute ma musique de","COMING_SOON":"Arrive bientôt!","NO_ALBUM_FOUND_FOR_THIS_ARTIST":"Aucun album n'a été trouvé pour cet artiste","SONG":"Chanson","SONGS":"Chansons","MORE":"Plus","SORT_AND_FILTER":"Trier et filtrer","ALBUMS":"Albums"},"__en":{"ALL_MY_MUSIC_FROM":"All My Music From","COMING_SOON":"Coming Soon!","NO_ALBUM_FOUND_FOR_THIS_ARTIST":"No album was found  for this artist","SONG":"Song","SONGS":"Songs","MORE":"More","SORT_AND_FILTER":"Sort & Filter","ALBUMS":"Albums"}};amdi18n.init=function(language){
		// get the default language
		if(!language){
			if(typeof window !== 'undefined' && window._i18n && window._i18n.locale){
				language = window._i18n.locale;
			}else if(typeof document !== 'undefined' && document.documentElement.lang){
				language = document.documentElement.lang;
			}else if(typeof navigator !== 'undefined' && (navigator.languages || navigator.language || navigator.userLanguage)){
				// navigator.languages does not exist in IE 11
				language = (navigator.languages && navigator.languages[0]) ||
					navigator.language ||
					navigator.userLanguage;
				language = (language || 'root').toLowerCase();
			}else{
				language = 'root';
			}
		}
		var target = this['__' + language] || this.__root;

		// copy definition to root level
		if (target) {
			for (var name in target) {
				this[name] = target[name];
			}
		}

		// fallback to root
		var recursiveFallback = function(target, source){
			for(var name in source){
				switch(typeof target[name]){
				case 'undefined':
					target[name] = source[name];
					break;
				case 'object':
					recursiveFallback(target[name], source[name]);
				}
			}
		};
		recursiveFallback(this, this.__root);
	};amdi18n.init();module.exports=amdi18n;