import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import SequencerNodeView from "views/audioEditor/sequencerNodeView/SequencerNodeView";
import SequencerDeletedNodeTemplate from 'text!../../../../templates/audioEditor/sequencerNodeView/SequencerDeletedNodeTemplate.html';
import ti18n from 'i18n!nls/Sequenceri18n';
import RSVP from 'rsvp';


const SequencerDeletedNodeView = SequencerNodeView.extend({



    constructor : function(attributes, options) {
        SequencerNodeView.apply(this, [attributes, options]);
        this.compiledTemlate =  _.template(SequencerDeletedNodeTemplate);
    },
    
    events : _.extend({
        "click": "onNodeClick",
    }, SequencerNodeView.prototype.events),
    
    onNodeClick : function(){
        //this.trigger("revertDeleteNodeCreation", this);
    },

    shouldBeAddedToParagraphContainerOfNextNodeFirst : function(){
        return true;
    },
    
    render : function() {

        this.$el.html(this.compiledTemlate({"model": this.model, "ti18n": ti18n, expanded: false}));
        this.setElement(this.$el.find("span").first());
        this.afterRendering();
        return this;
    }

});
export default SequencerDeletedNodeView;