import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import OptionPopupView from "views/common/OptionPopupView";
import Utils from "models/helper/Utils";
import AudioRecorderHelper from "models/helper/AudioRecorderHelper";
import RecordingSettingsPageTemplate from 'text!../../../templates/recordingSettings/RecordingSettingsPageTemplate.html';
import ti18n from "i18n!nls/RecordingSettingsi18n";
import PersistentModels from "services/PersistentModels";

const RecordingSettingsPopupView = OptionPopupView.extend({


    initialize: function(options) {
        const viewOptions = {};
        const contentView = {};
        const contentViewTemplate = _.template(RecordingSettingsPageTemplate);
        const templateParams = {};
        templateParams.ti18n = ti18n;
        contentView.$el = $(contentViewTemplate(templateParams));
        viewOptions.contentView = contentView;
        viewOptions.addContentAfterEnhancement = false;
        viewOptions.contentPosition = "center";
        viewOptions.displayTitle = true;
        viewOptions.titleToDisplay = window.getI18n(ti18n,"RECORDING_SETTINGS");
        this._popupUniqueId = "RecordingSettingsPopupView";
        viewOptions.id = this._popupUniqueId;
        this._preferredAudioDeviceId = options && options.preferredAudioDeviceId ?options.preferredAudioDeviceId: null;
        //viewOptions.elementSource$el = $("#showRecordingSettingsButton");
        //viewOptions.showAsMenuIfInBrowser = true
        
        OptionPopupView.prototype.initialize.call(this, viewOptions);
        
    },


    _close : function(){
        this.$el.popup('close' );
    },


    _removeParanthesesFromDeviceLabel : function(deviceLabel){
        return deviceLabel.replace(/ *\([^)]*\) */g, ' ').trim();
    },

    onPopupClosed: function() {
       OptionPopupView.prototype.onPopupClosed.call(this);
       this.trigger("onPopupClosed");
   },

   _addArrowToSelectWidget: function() {
    this.$el.find("#microphoneFld-button").append('<i class="ion-chevron-down" style="display: inline-block;"></i>');
},


_addDevicesAvailableToView : function(){
    const microphoneFld$El =  this.$el.find("#microphoneFld");
    const microphoneFldLabel$El = this.$el.find("#microphoneFld-button > span");
    return AudioRecorderHelper.getInstance().getAudioDevicesAvailable().then((function(audioDevicesAvailable){
      if(audioDevicesAvailable){
        let optionSelected = false;
        for(let i =0; i < audioDevicesAvailable.length; i++ ){
            const audioDevice = audioDevicesAvailable[i];
            if(this._preferredAudioDeviceId == audioDevice.deviceId){
                microphoneFld$El.append("<option value='"+audioDevice.deviceId+"' selected='selected'>"+ this._removeParanthesesFromDeviceLabel(audioDevice.label)+"</option>");
                microphoneFldLabel$El.html(this._removeParanthesesFromDeviceLabel(audioDevice.label));
                optionSelected = true;
            }else{
                microphoneFld$El.append("<option value='"+audioDevice.deviceId+"'>"+ this._removeParanthesesFromDeviceLabel(audioDevice.label)+"</option>");
            }
        }
        if(!optionSelected){
            microphoneFld$El.append("<option value='' disabled selected hidden>"+ "No preferred microphone selected</option>");
                microphoneFldLabel$El.html("No preferred microphone selected");
                optionSelected = true;
        }
    }
}).bind(this)).catch((function(error) {
    console.error(error);
    window.alertErrorMessage(error);
}).bind(this));

},


getAudioDeviceIdSelected: function() {
    return this.$el.find("#microphoneFld option:selected").val();
},


_onPreferredAudioDeviceChanged : function(){
    const deviceId = this.getAudioDeviceIdSelected();
    this.trigger("audioDeviceChanged",deviceId);
},

afterRendering: function() {
   const saveBtn$el = this.$el.find("#saveBtn");
   this.microphoneFld$el = this.$el.find("#microphoneFld");
   this._addDevicesAvailableToView();
   this._addArrowToSelectWidget();
   this.microphoneFld$el.on("change", this._onPreferredAudioDeviceChanged.bind(this));

   saveBtn$el.on("click", (function(){
    this.trigger("saveChanges");
}).bind(this));


},



});

export default RecordingSettingsPopupView;
