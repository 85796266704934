
import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import SequencerViewConfigSettingsView from "views/audioEditor/SequencerViewConfigSettingsView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const SequencerViewConfigSettingsContoller = Chaplin.Controller.extend({

    initialize : function(options) {
        this.model =  options.sequencer;
        _.extend(this, Backbone.Events);
        
    },


    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    onDeletedNodesDisplaedChange: function(){
        this.model.setSequencerSettings(this.model.getSequencerSettings().getChangeableSettings().DELETED_NODES_DISPLAYED, this.view.isShowIgnoredContentOn(), true);
    },

    onRevealDeletedContentChange: function(){
        this.model.setSequencerSettings(this.model.getSequencerSettings().getChangeableSettings().REVEAL_DELETED_CONTENT, this.view.isRevealDeletedContentOn(), true);
    },

    onGapNodesDisplayedChange: function(){
        this.model.setSequencerSettings(this.model.getSequencerSettings().getChangeableSettings().GAP_NODES_DISPLAYED, this.view.isShowGapNodesDisplayedOn(), true);
    },

    onEditBoundaryDisplayedChange: function(){
        this.model.setSequencerSettings(this.model.getSequencerSettings().getChangeableSettings().EDIT_BOUNDARIES_DISPLAYED, this.view.isShowEditBoundariesDisplayedOn(), true);
    },

    onShowDarkThemeChange: function(){
        this.model.setSequencerSettings(this.model.getSequencerSettings().getChangeableSettings().SHOW_DARK_THEME, this.view.isShowDarkThemeOn(), true);
    },

    onAutoscrollChange: function(){
        this.model.setSequencerSettings(this.model.getSequencerSettings().getChangeableSettings().AUTOSCROLL, this.view.isAutoscrolldOn(), true);
    },

    

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {

                const params = {};
                params.model = this.model.getSequencerSettings();
                this.view = new SequencerViewConfigSettingsView(params);
                this.view.render();

                this.listenTo(this.view, "deletedNodesDisplayedChange", this.onDeletedNodesDisplaedChange);
                this.listenTo(this.view, "revealDeletedContentChange", this.onRevealDeletedContentChange);
                this.listenTo(this.view, "gapNodesDisplayedChange", this.onGapNodesDisplayedChange);
                this.listenTo(this.view, "editBoundaryDisplayedChange", this.onEditBoundaryDisplayedChange);
                this.listenTo(this.view, "showDarkThemeChange", this.onShowDarkThemeChange);
                this.listenTo(this.view, "autoscrollChange", this.onAutoscrollChange);
                

                resolve();

            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default SequencerViewConfigSettingsContoller;