import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import DynamicPageView from "views/common/DynamicPageView";
import Utils from "models/helper/Utils";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import ti18n from "i18n!nls/SettingsPagei18n";
import PersistentModels from "services/PersistentModels";
import SettingsPageTemplate from 'text!../../../templates/settingsPage/SettingsPageTemplate.html';
import moment from 'moment';

const DEFAULT_USER_PROFILE_BACKGROUND_IMAGE_URL= window.getResourceURLAccordingToLocationProtocol("/img/blackWhitebackgroundplayer.jpg");
const DEFAULT_USER_PROFILE_IMAGE_URL= "none";

const SettingsPageView = DynamicPageView.extend({

    //template : _.template(ExplorePageTemplate),

    initialize : function(options) {
        const dynamicPageOptions = {};
        this.model =  options? options.model: {};
        this._pageId = (options && options.pageId)? options.pageId:"SettingsPage";
        dynamicPageOptions.pageViewTemplate = SettingsPageTemplate;
        dynamicPageOptions.model = options.userInfoJson;
        dynamicPageOptions.pageId = this._pageId;
        dynamicPageOptions.headerFullscren = true;
        dynamicPageOptions.persistent = false;
        dynamicPageOptions.onPageShow = this.onPageShow.bind(this);
        dynamicPageOptions.pageTemplateOptions = {"ti18n": ti18n};
        //PersistentModels.getInstance().getRouter().registerForViewportHeightChange(this._pageId,this._onViewportHeightChanged.bind(this));
        return DynamicPageView.prototype.initialize.call(this, dynamicPageOptions);  
    },

    events : {
        "click #header-back-button" : "navigateToPreviousPage",
        "click #editProfileLnk": "editProfile",
        "click #changePasswordLnk": "changePassword",
        "click #changeProfilePictureLnk": "changeProfilePicture",
        "click #referAndEarnLnk": "referAndEarn",
        "click #upgradeNowLnk": "upgradeNow",
        "click #upgradeBtn": "upgradeNow",
        "click #restorePurchaseLnk": "restorePurchase",
        "click #cancelSubscriptionLnk": "showManageSubscription",
        "click #updateBillingLnk": "showManageBilling",
        "click #termsOfServiceLnk": "showTermsOfService",
        "click #showPrivacyPolicyLnk" :"showPrivacyPolicy",
        "click #helpAndFeedbackLnk": "showHelpAndFeedback",
        "click #createYourFirstJourneyLnk": "showCreateYourFirstJourney",
        "click #rateTrebbleLnk": "rateTrebble",
        "click #followOnFacebookLnk": "showFollowOnFacebook",
        "click #followOnTwitterLnk": "showFollowOnTwitter",
        "click .userInfoSection":"showLoggedInUserInfo",
        "click #logOutBtn": "logOut",
    },


    editProfile : function(){
        this.trigger("editProfile");
    },

    changePassword : function(){
        this.trigger("changePassword");
    },

    changeProfilePicture : function(){
        this.trigger("changeProfilePicture");
    },

    referAndEarn : function(){
        this.trigger("referAndEarn");
    },

    upgradeNow : function(){
        this.trigger("upgradeNow");
    },

    showLoggedInUserInfo: function(){
        this.trigger("showLoggedInUserInfo");
    },

    showCreateYourFirstJourney: function(){
        this.trigger("showCreateYourFirstJourney");
    },


    restorePurchase : function(){
        this.trigger("restorePurchase");
    },

    showTermsOfService : function(){
        this.trigger("showTermsOfService");
    },

    showPrivacyPolicy : function(){
        this.trigger("showPrivacyPolicy");
    },

    showHelpAndFeedback : function(){
        this.trigger("showHelpAndFeedback");
    },

    rateTrebble : function(){
        this.trigger("rateTrebble");
    },

    showFollowOnFacebook : function(){
        this.trigger("showFollowOnFacebook");
    },

    showFollowOnTwitter : function(){
        this.trigger("showFollowOnTwitter");
    },

    logOut : function(){
        this.trigger("logOut");
    },

    setAppRateSectionDescription : function(appRateSectionDescription){
        this.rateTrebbleLnk$el.find(".itemDescription").html(appRateSectionDescription);
    },

    setPurchaseBeingRestored : function(purchaseBeingRestored){
        if(purchaseBeingRestored){
            $.mobile.loading("show");
        }else{
            $.mobile.loading("hide");
        }
    },

    setUserInfo : function(userFullName, userAvatarImageUrl){
        this.$el.find(".userFullname").html(userFullName);
        if(userAvatarImageUrl){
            this.$el.find(".userDetailCoverArtWrapper").css("background-image","url("+userAvatarImageUrl+")");
        }else{
            this.$el.find(".userDetailCoverArtWrapper").css("background-image","none");
        }
    },

    onDailyFeedReadyNotificationClicked: function(){
        this.trigger("onDailyFeedNotificationChange", this.isDailyFeedReadyNotification());
    },

    setDailyFeedReadyNotification : function(turnOn){
        this.$el.find(".switch.daily_feed_reminder_switch  input").prop('checked', turnOn);
    },

    isDailyFeedReadyNotification : function(){
        return this.$el.find(".switch.daily_feed_reminder_switch  input").prop('checked');
    },


    onEmailFeedReadyNotificationClicked: function(){
        this.trigger("onEmailFeedNotificationChange", this.isEmailFeedReadyNotification());
    },

    setEmailFeedReadyNotification : function(turnOn){
        this.$el.find(".switch.email_feed_reminder_switch  input").prop('checked', turnOn);
    },

    isEmailFeedReadyNotification : function(){
        return this.$el.find(".switch.email_feed_reminder_switch  input").prop('checked');
    },

    setDarkModeSwitchValue : function(darkModeOn){
        this.$el.find(".switch.dark_mode_switch  input").prop('checked', darkModeOn);
    },

    getDarkModeSwitchValue : function(){
        return this.$el.find(".switch.dark_mode_switch  input").prop('checked');
    },

    onDarkModeSwitchClicked: function(){
        this.trigger("onDarkModeChange", this.getDarkModeSwitchValue());
    },

    onConnectToStripeBtnClicked: function(){
        this.trigger("connectToStripe");
    },

    onConnectedToStripeBtnClicked: function(){
        this.trigger("confirmDisconnectFromStripe");
    },

    onShowStripeDashboardClicked: function(){
        this.trigger("showStripeDashboard");
    },

    onDeleteStripeAccountBtnClicked: function(){
        this.trigger("confirmDeleteStripeAccount");
    },

    onChangeCreatorPlanButtonClicked: function(){
        this.trigger("changeCreatorPlan");
    },

    onUpgradeCreatorPlanButtonClicked: function(){
        this.trigger("upgradeCreatorPlan");
    },

    onUpdatePaymentMethodBtnClicked: function(){
        this.trigger("updatePaymentMethod");
    },

    onCancelCreatorSubscriptionLnkClicked: function(){
        this.trigger("cancelCreatorSubscription");
    },

    onReactivateCreatorSubscriptionBtnClicked: function(){
        this.trigger("reactivateCreatorSubscription");
    },

    onViewCreatorStripeCustomerPortalLnkClicked: function(){
        this.trigger("viewCreatorStripeCustomerPortal");
    },

    onChangePaymentMethodButtonClicked: function(){
        this.trigger("changePaymentMethodFromStripe");
    },

    setPlayAudioAnnouncementsSwitchValue : function(darkModeOn){
        this.$el.find(".switch.audio_announcement_switch  input").prop('checked', darkModeOn);
    },

    getPlayAudioAnnouncementsSwitchValue : function(){
        return this.$el.find(".switch.audio_announcement_switch  input").prop('checked');
    },

    onPlayAudioAnnouncementsSwitchClicked: function(){
        this.trigger("onPlayAudioAnnouncementsChange", this.getPlayAudioAnnouncementsSwitchValue());
    },


    navigateToPreviousPage : function(){
        PersistentModels.getInstance().getRouter().navigateToPreviousPage();
    },

    onBeforePageInitialized : function(){

    },

    showManageSubscription : function(){
        this.trigger("showManageSubscription")
    },

    showManageBilling:  function(){
        this.trigger("showManageBilling")
    },

    

    _onViewportHeightChanged : function(){
        const headerHeight = 54 ;
        this.$el.find(".ui-content").css("height",(window.getDocumentClienHeight() -headerHeight)+ "px");
    },

    setMembershipType : function(membershipType){
        this.membershipInfoItem$el.find(".itemDescription").html(membershipType? membershipType: window.getI18n(ti18n, "FREE"));
    },

    setAppVersion : function(appVersion){
        this.appVersionSection$el.find(".itemDescription").html(appVersion);
    },

    showCreatorSubscriptionSection: function(){
        this.creatorSubscriptionSection$el.removeClass("hide");
    },

    hideCreatorSubscriptionSection: function(){
        this.creatorSubscriptionSection$el.addClass("hide");
    },

    showSubscriptionSection: function(){
        if(this.subscriptionSection$el.hasClass("hide")){
            this.subscriptionSection$el.removeClass("hide");
        }
    },

    /*setCreatorSubscribedToFirstPaidPlan : function(billedYearly, nextBillingDate, subscriptionCanceledEndDate){
        this.currentCreatorPlanName$el.html("Trebble " +Utils.getInstance().getCreatorFirstPaidTierName());
        const costInUSD = billedYearly? Utils.getInstance().getCreatorFirstPaidTierCostInUSDPerMonth(billedYearly)*12 : Utils.getInstance().getCreatorFirstPaidTierCostInUSDPerMonth(billedYearly);
        const cost_label =" - "+costInUSD +" USD "+(billedYearly? window.getI18n(ti18n, "PER_YEAR"): window.getI18n(ti18n, "PER_MONTH"));
        this.currentCreatorCostPerrMonth$el.html(cost_label);
        this.viewCreatorStripeCustomerPortalLnk$el.removeClass("hide");
        if(nextBillingDate){
            this.upgradeCreatorPlanBtn$el.addClass("hide");
            this.reactivateCreatorPlanBtn$el.addClass("hide");
            this.cancelCreatorSubscriptionLnk$el.removeClass("hide");
            this.changeCreatorPlanBtn$el.removeClass("hide");
        }else{
            if(subscriptionCanceledEndDate){
                this.reactivateCreatorPlanBtn$el.removeClass("hide");
                this.upgradeCreatorPlanBtn$el.addClass("hide");
                this.cancelCreatorSubscriptionLnk$el.addClass("hide");
                this.changeCreatorPlanBtn$el.addClass("hide");
            }
        }
        if(nextBillingDate){
            this.planRenewalOrCanceledDate$el.html(window.getI18n(ti18n, "YOUR_PLAN_RENEWS_ON") +" "+moment(nextBillingDate).format("MMMM Do YYYY"));
        }else{
            if(subscriptionCanceledEndDate){
                this.planRenewalOrCanceledDate$el.html(window.getI18n(ti18n, "YOUR_PLAN_WILL_BE_CANCELLED_ON") +" "+moment(subscriptionCanceledEndDate).format("MMMM Do YYYY"))
            }else{
                this.planRenewalOrCanceledDate$el.html("");

            }
        }
    },

    setCreatorSubscribedToSecondPaidPlan : function(planName, billedYearly, nextBillingDate, subscriptionCanceledEndDate, costInUSD){
        this.currentCreatorPlanName$el.html("Trebble " +Utils.getInstance().getCreatorSecondPaidTierName());
        const costInUSD = billedYearly?Utils.getInstance().getCreatorSecondPaidTierCostInUSDPerMonth(billedYearly)*12: Utils.getInstance().getCreatorSecondPaidTierCostInUSDPerMonth(billedYearly);
        const cost_label =" - "+costInUSD +" USD "+(billedYearly? window.getI18n(ti18n, "PER_YEAR"): window.getI18n(ti18n, "PER_MONTH"));
        this.currentCreatorCostPerrMonth$el.html(cost_label);
        this.viewCreatorStripeCustomerPortalLnk$el.removeClass("hide");
        if(nextBillingDate){
            this.upgradeCreatorPlanBtn$el.addClass("hide");
            this.reactivateCreatorPlanBtn$el.addClass("hide");
            this.cancelCreatorSubscriptionLnk$el.removeClass("hide");
            this.changeCreatorPlanBtn$el.removeClass("hide");

        }else{
            if(subscriptionCanceledEndDate){
                this.upgradeCreatorPlanBtn$el.addClass("hide");
                this.reactivateCreatorPlanBtn$el.removeClass("hide");
                this.cancelCreatorSubscriptionLnk$el.addClass("hide");
                this.changeCreatorPlanBtn$el.addClass("hide");
            }
        }
        if(nextBillingDate){
            this.planRenewalOrCanceledDate$el.html(window.getI18n(ti18n, "YOUR_PLAN_RENEWS_ON") +" "+moment(nextBillingDate).format("MMMM Do YYYY"))
        }else{
            if(subscriptionCanceledEndDate){
                this.planRenewalOrCanceledDate$el.html(window.getI18n(ti18n, "YOUR_PLAN_WILL_BE_CANCELLED_ON") +" "+moment(subscriptionCanceledEndDate).format("MMMM Do YYYY"))
            }else{
                this.planRenewalOrCanceledDate$el.html("");
            }
        }
    },*/

    showTranscriptionRemaingLeftWidget:function(show){
        if(show){
            this.transcriptionMinutesLeftInfo$el.removeClass("hide");
        }else{
            this.transcriptionMinutesLeftInfo$el.addClass("hide");
        }
    },

    setTranscriptionRemaingLeftWidgetView : function(transcriptionRemaingLeftWidgetView){
        this.transcriptionMinutesLeftInfo$el.find(".input_block").html("");
        this.transcriptionMinutesLeftInfo$el.find(".input_block").append(transcriptionRemaingLeftWidgetView.$el);
    },

    setCreatorSubscriptionPlanInfo : function(planName, billedYearly, nextBillingDate, subscriptionCanceledEndDate, costInUSD){
        this.currentCreatorPlanName$el.html(planName);
        const cost_label =" - "+costInUSD +" USD "+(billedYearly? window.getI18n(ti18n, "PER_YEAR"): window.getI18n(ti18n, "PER_MONTH"));
        this.currentCreatorCostPerrMonth$el.html(cost_label);
        this.viewCreatorStripeCustomerPortalLnk$el.removeClass("hide");
        this.paymentMethodSection$el.removeClass("hide");
        if(nextBillingDate){
            this.upgradeCreatorPlanBtn$el.addClass("hide");
            this.reactivateCreatorPlanBtn$el.addClass("hide");
            this.cancelCreatorSubscriptionLnk$el.removeClass("hide");
            this.changeCreatorPlanBtn$el.removeClass("hide");

        }else{
            if(subscriptionCanceledEndDate){
                this.upgradeCreatorPlanBtn$el.addClass("hide");
                this.reactivateCreatorPlanBtn$el.removeClass("hide");
                this.cancelCreatorSubscriptionLnk$el.addClass("hide");
                this.changeCreatorPlanBtn$el.addClass("hide");
            }
        }
        if(nextBillingDate){
            this.planRenewalOrCanceledDate$el.html(window.getI18n(ti18n, "YOUR_PLAN_RENEWS_ON") +" "+moment(nextBillingDate).format("MMMM Do YYYY"))
        }else{
            if(subscriptionCanceledEndDate){
                this.planRenewalOrCanceledDate$el.html(window.getI18n(ti18n, "YOUR_PLAN_WILL_BE_CANCELLED_ON") +" "+moment(subscriptionCanceledEndDate).format("MMMM Do YYYY"))
            }else{
                this.planRenewalOrCanceledDate$el.html(window.getI18n(ti18n, "YOUR_TRANSCRIPTION_MINUTES_RENEW_THE") +" "+this.getDateOfMinuteRenewalFromUserSignupDay()+" "+window.getI18n(ti18n, "OF_EVERY_MONTH") );
            }
        }
    },

    getDateOfMinuteRenewalFromUserSignupDay : function(){
        if(LocalStorageHelper.getInstance().getUserInfo() && LocalStorageHelper.getInstance().getUserInfo().creationDate){
            return new Date(LocalStorageHelper.getInstance().getUserInfo().creationDate).getDate();
        }
        return 0;
    },

    setPaymentMethodInfo : function(displayablePaymentMethod){
        this.paymentMethodName$el.html(displayablePaymentMethod);
    },

    setCreatorSubscribedToFreePaidPlan : function(customFreePlan){
        this.currentCreatorPlanName$el.html("Trebble " +(customFreePlan?"Custom":Utils.getInstance().getCreatorFreePlanName()));
        this.currentCreatorCostPerrMonth$el.html("");
        this.planRenewalOrCanceledDate$el.html(window.getI18n(ti18n, "YOUR_TRANSCRIPTION_MINUTES_RENEW_THE") +" "+this.getDateOfMinuteRenewalFromUserSignupDay()+" "+window.getI18n(ti18n, "OF_EVERY_MONTH") );
        this.upgradeCreatorPlanBtn$el.removeClass("hide");
        this.cancelCreatorSubscriptionLnk$el.addClass("hide");
        this.reactivateCreatorPlanBtn$el.addClass("hide");
        this.changeCreatorPlanBtn$el.addClass("hide");
        this.viewCreatorStripeCustomerPortalLnk$el.addClass("hide");
        this.paymentMethodSection$el.addClass("hide");
    },


    hideSubscriptionSection: function(){
        if(!this.subscriptionSection$el.hasClass("hide")){
            this.subscriptionSection$el.addClass("hide");
        }
    },


    showPayoutSection: function(){
        if(this.payoutSection$el.hasClass("hide")){
            this.payoutSection$el.removeClass("hide");
        }
    },


    hidePayoutSection: function(){
        if(!this.payoutSection$el.hasClass("hide")){
            this.payoutSection$el.addClass("hide");
        }
    },

    setRetrievingStripeAccountStatus : function(){
        if(!this.connectToStripeBtn$el.hasClass("hide")){
            this.connectToStripeBtn$el.addClass("hide");
        }
        if(!this.connectedToStripeBtn$el.hasClass("hide")){
            this.connectedToStripeBtn$el.addClass("hide");
        }
        if(!this.showStripeDashboardLnk$el.hasClass("hide")){
            this.showStripeDashboardLnk$el.addClass("hide");
        }
        if(this.retrieveingDataFromStripeBtn$el.hasClass("hide")){
            this.retrieveingDataFromStripeBtn$el.removeClass("hide");
        }
        this.deleteStripAccountBtn$el.addClass("hide");
    },

    setStripeAccountConnectionStatus : function(connected){
        if(!this.retrieveingDataFromStripeBtn$el.hasClass("hide")){
            this.retrieveingDataFromStripeBtn$el.addClass("hide");
        }
        if(connected){
            if(this.connectedToStripeBtn$el.hasClass("hide")){
                this.connectedToStripeBtn$el.removeClass("hide");
            }
            if(this.showStripeDashboardLnk$el.hasClass("hide")){
                this.showStripeDashboardLnk$el.removeClass("hide");
            }
            if(!this.connectToStripeBtn$el.hasClass("hide")){
                this.connectToStripeBtn$el.addClass("hide");
            }
            this.deleteStripAccountBtn$el.removeClass("hide");
        }else{
            if(!this.connectedToStripeBtn$el.hasClass("hide")){
                this.connectedToStripeBtn$el.addClass("hide");
            }
            if(!this.showStripeDashboardLnk$el.hasClass("hide")){
                this.showStripeDashboardLnk$el.addClass("hide");
            }
            if(this.connectToStripeBtn$el.hasClass("hide")){
                this.connectToStripeBtn$el.removeClass("hide");
            }
            this.deleteStripAccountBtn$el.addClass("hide");
        }
    },

    showNotificationSection: function(){
        if(this.notificationSection$el.hasClass("hide")){
            this.notificationSection$el.removeClass("hide");
        }
    },

    showPushNotificationSettings: function(){
        if(this.pushNotificationBtn$el.hasClass("hide")){
            this.pushNotificationBtn$el.removeClass("hide");
        }
    },

    hidePushNotificationSettings: function(){
        if(!this.pushNotificationBtn$el.hasClass("hide")){
            this.pushNotificationBtn$el.addClass("hide");
        }
    },

    showTimeReminderSettings: function(){
        if(this.timeReminderBtn$el.hasClass("hide")){
            this.timeReminderBtn$el.removeClass("hide");
        }
    },

    hideTimeReminderSettings: function(){
        if(!this.timeReminderBtn$el.hasClass("hide")){
            this.timeReminderBtn$el.addClass("hide");
        }
    },

    setTimeReminder : function(timeInHour){
        this.timeReminderFld$El.val(timeInHour);
        this.timeReminderFld$El.selectmenu("refresh", true);
    },

    getTimeReminder : function(timeInHour){
        return this.timeReminderFld$El.val();
    },


    onTimeRemiderChanged : function(){
        this.trigger("onTimeRemiderChanged", this.getTimeReminder());
    },

    showContentSection: function(){
        if(this.contentSection$el.hasClass("hide")){
            this.contentSection$el.removeClass("hide");
        }
    },

    showUserInfo : function(){
        if(this.userInfoSection$el.hasClass("hide")){
            this.userInfoSection$el.removeClass("hide");
        }
    },

    hideUserInfo : function(){
        if(!this.userInfoSection$el.hasClass("hide")){
            this.userInfoSection$el.addClass("hide");
        }
    },

    hideNotificationSection: function(){
        if(!this.notificationSection$el.hasClass("hide")){
            this.notificationSection$el.addClass("hide");
        }
    },

    hideContentSection: function(){
        if(!this.contentSection$el.hasClass("hide")){
            this.contentSection$el.addClass("hide");
        }
    },


    showReferUserSettingsLink: function(){
        if(this.referAndEarnLnk$el.hasClass("hide")){
            this.referAndEarnLnk$el.removeClass("hide");
        }
    },

    hideReferUserSettingsLink: function(){
        if(!this.referAndEarnLnk$el.hasClass("hide")){
            this.referAndEarnLnk$el.addClass("hide");
        }
    },

    showCreateFirstJourneyLink : function(){
        if(this.createYourFirstJourneyLnk$el.hasClass("hide")){
            this.createYourFirstJourneyLnk$el.removeClass("hide");
        }
    },

    hideCreateFirstJourneyLink : function(){
        if(!this.createYourFirstJourneyLnk$el.hasClass("hide")){
            this.createYourFirstJourneyLnk$el.addClass("hide");
        }
    },

    showRateTrebbleLink: function(){
        if(this.rateTrebbleLnk$el.hasClass("hide")){
            this.rateTrebbleLnk$el.removeClass("hide");
        }
    },

    hideRateTrebbleLink: function(){
        if(!this.rateTrebbleLnk$el.hasClass("hide")){
            this.rateTrebbleLnk$el.addClass("hide");
        }
    },

    setOrderSubscriptionButtonsAvailable : function(orderIsAvailableOnPlatform){
        if(orderIsAvailableOnPlatform){
            this.showUpgradeBtn();
            this.showRestorePurchaseBtn();
            this.showButtonsToManageSubscriptionsAndBilling();
        }else{
            this.hideUpgradeBtn();
            this.hideRestorePurchaseBtn();
            this.hideButtonsToManageSubscriptionsAndBilling();
        }
    },

    showRestorePurchaseBtn : function(){
        if(this.restorePurchaseLnk$el.hasClass("hide")){
            this.restorePurchaseLnk$el.removeClass("hide");
        }

    },

    hideRestorePurchaseBtn : function(){
        
        if(!this.restorePurchaseLnk$el.hasClass("hide")){
            this.restorePurchaseLnk$el.addClass("hide");
        }
    },


    showUpgradeBtn : function(){
        if(this.upgradeToTrebblePaidSubscriptionBoxWrapper$el.hasClass("hide")){
            this.upgradeToTrebblePaidSubscriptionBoxWrapper$el.removeClass("hide");
        }
        if(this.upgradeNowLnk$el.hasClass("hide")){
            this.upgradeNowLnk$el.removeClass("hide");
        }

    },

    hideUpgradeBtn : function(){
        if(!this.upgradeToTrebblePaidSubscriptionBoxWrapper$el.hasClass("hide")){
            this.upgradeToTrebblePaidSubscriptionBoxWrapper$el.addClass("hide");
        }
        if(!this.upgradeNowLnk$el.hasClass("hide")){
            this.upgradeNowLnk$el.addClass("hide");
        }
    },

    showButtonsToManageSubscriptionsAndBilling : function(){
        if(this.updateBillingLnk$el.hasClass("hide")){
            this.updateBillingLnk$el.removeClass("hide");
        }
        if(this.cancelSubscriptionLnk$el.hasClass("hide")){
            this.cancelSubscriptionLnk$el.removeClass("hide");
        }
    },

    hideButtonsToManageSubscriptionsAndBilling : function(){

        if(!this.updateBillingLnk$el.hasClass("hide")){
            this.updateBillingLnk$el.addClass("hide");
        }
        if(!this.cancelSubscriptionLnk$el.hasClass("hide")){
            this.cancelSubscriptionLnk$el.addClass("hide");
        }
    },


    _initTimeReminderField : function(){
        for(let i =0; i < 24; i++ ){
            this.timeReminderFld$El.append("<option value='"+i+"'>"+ i+":00</option>");
        }
        //this.$el.find("#timeReminderFld-button").append('<i class="ion-chevron-down" style="display: inline-block;"></i>');

    },

    onAfterPageInitialized : function(){
        this.membershipInfoItem$el = this.$el.find("#membershipInfoItem");
        this.upgradeToTrebblePaidSubscriptionBoxWrapper$el = this.$el.find(".upgradeToTrebblePaidSubscriptionBoxWrapper");
        this.upgradeNowLnk$el = this.$el.find("#upgradeNowLnk");
        this.subscriptionSection$el = this.$el.find("#subscriptionSection");
        this.creatorSubscriptionSection$el = this.$el.find("#creatorSubscriptionBillingSection");
        this.payoutSection$el = this.$el.find("#payoutSection");
        this.notificationSection$el = this.$el.find("#notificationSection");
        this.contentSection$el = this.$el.find("#contentSection");
        this.userInfoSection$el = this.$el.find(".userInfoSection");
        this.appVersionSection$el = this.$el.find("#appVersionSection");
        this.referAndEarnLnk$el = this.$el.find("#referAndEarnLnk");
        this.pushNotificationBtn$el = this.$el.find("#dailyReminderBtn");
        this.rateTrebbleLnk$el = this.$el.find("#rateTrebbleLnk");
        this.restorePurchaseLnk$el = this.$el.find("#restorePurchaseLnk");
        this.updateBillingLnk$el = this.$el.find("#updateBillingLnk");
        this.cancelSubscriptionLnk$el = this.$el.find("#cancelSubscriptionLnk");
        this.createYourFirstJourneyLnk$el = this.$el.find("#createYourFirstJourneyLnk");
        this.timeReminderFld$El = this.$el.find("#timeReminderFld");
        this.timeReminderFldLabel$El = this.$el.find("#timeReminderFld-button > span");
        this.timeReminderBtn$el = this.$el.find("#timeReminderBtn");
        this.retrieveingDataFromStripeBtn$el = this.$el.find("#retrieveingDataFromStripeBtn");
        this.connectToStripeBtn$el = this.$el.find("#connectToStripeBtn");
        this.deleteStripAccountBtn$el = this.$el.find("#deleteStripeAccountBtn");
        this.connectedToStripeBtn$el = this.$el.find("#connectedToStripeBtn");
        this.showStripeDashboardLnk$el = this.$el.find("#showStripeDashboardLnk");
        this.changeCreatorPlanBtn$el = this.$el.find("#changeCreatorPlanBtn");
        this.updatePaymentMethodBtn$el = this.$el.find("#updatePaymentMethodBtn");
        this.cancelCreatorSubscriptionLnk$el = this.$el.find("#cancelCreatorSubscriptionLnk");
        this.reactivateCreatorPlanBtn$el = this.$el.find("#reactivateCreatorPlanBtn");
        this.viewCreatorStripeCustomerPortalLnk$el= this.$el.find("#viewCreatorStripeCustomerPortalLnk");
        this.currentCreatorPlanName$el = this.$el.find(".current_creator_plan_name");
        this.paymentMethodName$el = this.$el.find("#paymentMethodBtn .itemDescription");
        this.currentCreatorCostPerrMonth$el = this.$el.find(".current_creator_cost_per_month");
        this.planRenewalOrCanceledDate$el = this.$el.find(".plan_renewal_or_end_date");
        this.paymentMethodDescription$el = this.$el.find("#paymentMethodBtn .itemDescription");
        this.upgradeCreatorPlanBtn$el = this.$el.find("#upgradeCreatorPlanBtn");
        this.changePaymentMethodBtn$el = this.$el.find("#changePaymentMethodBtn");
        this.paymentMethodSection$el = this.$el.find("#paymentMethodBtn");
        this.transcriptionMinutesLeftInfo$el = this.$el.find("#transcriptionMinutesLeftInfo");
        


        this._initTimeReminderField();

        this.$el.find(".switch.daily_feed_reminder_switch  input").on("click",this.onDailyFeedReadyNotificationClicked.bind(this));
        this.$el.find(".switch.email_feed_reminder_switch  input").on("click",this.onEmailFeedReadyNotificationClicked.bind(this));
        this.$el.find(".switch.dark_mode_switch  input").on("click",this.onDarkModeSwitchClicked.bind(this));
        this.$el.find(".switch.audio_announcement_switch  input").on("click",this.onPlayAudioAnnouncementsSwitchClicked.bind(this));
        this.connectToStripeBtn$el.on("click",this.onConnectToStripeBtnClicked.bind(this));
        this.connectedToStripeBtn$el.on("click",this.onConnectedToStripeBtnClicked.bind(this));
        this.deleteStripAccountBtn$el.on("click",this.onDeleteStripeAccountBtnClicked.bind(this));
        this.showStripeDashboardLnk$el.on("click",this.onShowStripeDashboardClicked.bind(this));
        this.changeCreatorPlanBtn$el.on("click",this.onChangeCreatorPlanButtonClicked.bind(this));
        this.upgradeCreatorPlanBtn$el.on("click",this.onUpgradeCreatorPlanButtonClicked.bind(this));
        this.updatePaymentMethodBtn$el.on("click",this.onUpdatePaymentMethodBtnClicked.bind(this));
        this.cancelCreatorSubscriptionLnk$el.on("click",this.onCancelCreatorSubscriptionLnkClicked.bind(this));
        this.reactivateCreatorPlanBtn$el.on("click", this.onReactivateCreatorSubscriptionBtnClicked.bind(this))
        this.viewCreatorStripeCustomerPortalLnk$el.on("click",this.onViewCreatorStripeCustomerPortalLnkClicked.bind(this));
        this.changePaymentMethodBtn$el.on("click", this.onChangePaymentMethodButtonClicked.bind(this));
        this.timeReminderFld$El.on("change",this.onTimeRemiderChanged.bind(this));
    },

    onPageShow : function(){
        this.trigger("pageShow");
    },
    onBeforeRemoveFromPage : function(){
        DynamicPageView.prototype.onBeforeRemoveFromPage.call(this); 
        PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._pageId);
    },

});
export default SettingsPageView;