import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import CustomSearchFieldView from "views/common/CustomSearchFieldView";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import Sortable from "libs/sortablejs/Sortable";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import ti18n from 'i18n!nls/MySubscriptionsPagei18n';
import pageTemplate from 'text!../../../templates/mySubscriptions/mySubscriptionsPageTemplate.html';

const Rollout = RolloutHelper.getInstance();

const SHOULD_FEED_DISPLAYED = Rollout.getFeatureVariable(Rollout.FEATURES.ADD_MY_FEED_PAGE, RolloutHelper.FEATURES.ADD_MY_FEED_PAGE.variables.should_feed_info_displayed_in_subs_page, false);
const ON_FEED_TODAY_BOX_STATUS = {
    "LOADING": "loading",
    "ERROR":"error",
    "NO_NEW_CONTENT": "no_new_content",
    "CONTENT_LOADED": "content_loaded"
}

const ALWAYS_EDIT_MODE_CLASS_NAME ="alwaysInEditMode";
const MyLibraryPageView = Backbone.View.extend({

    template : _.template(pageTemplate),


    events : {
        
        "click #subscriptionTab" : "onSubscriptionTabSelected",
        "click #feedTab" : "onFeedTabSelected",
        "click #homeHeaderButton" : "onHomeButtonSelected",
        "click #helpHeaderButton" : "onHelpHeaderButtonClicked",
        "click  #addTrebbleToSubscriptionsBtn":"onAddToSubscriptionsBtnSeclected",
        
        "click #playMyFeedButton" : "playMyFeed",
        "click  #filterButton" : "filterList",
        "click #showUserRefferalPageBtn": "showUserRefferalpage",
        "click #upgradeBtn": "showUpgradeToPaidSubscriptionPopup",
        //"click .enableDisableDailyNotificationBox .switch": "onDailyNotificationBoxClicked",
        
        "click #playMyFeedFloatingBtnWrapper #playMyFeedBtn .playIconWrapper" : "playMyFeed",
        "click #playMyFeedFloatingBtnWrapper #playMyFeedBtn .pauseIconWrapper" : "playMyFeed",
        "click #play_feed_btn .playIconWrapper" : "playMyFeed",
        "click #play_feed_btn .pauseIconWrapper" : "playMyFeed",
        "click #pauseMyFeedButton": "playMyFeed",
        "click .enableDisableDailyNotificationBox .closeButton":"onEnableNotificationToggleDiscardedByUser",
        "click #listenOnVoiceEnabledDeviceBtn": "onListenOnVoiceEnabledDeviceBtnClicked",
        "click #onYourFeedTodayBoxWrapper .closeButton":"hideOnYourFeedTodayBox",
        
        
    },

    initialize : function(options) {
        
        this.mySubscriptionsListView = options.mySubscriptionsListView;
        //	this.myCapsuleFeedView = options.myCapsuleFeedView;
        
        
        this.selectedTab = "subscriptionTab";
        this._isPageDisplayed = false;
        this._isOnYourFeedTodayBoxHidden = false;
        
        this._onKeyUpInSearchFieldHandler = options.onKeyUpInSearchFieldHandler;
        this._onSearchFieldClearedHandler = options.onSearchFieldClearedHandler;
        this._inAlwaysEditMode = false;

        PersistentModels.getInstance().getRouter().registerForViewportHeightChange("mySubscriptionPageView",this._onViewportHeightChanged.bind(this));
    },
    _onViewportHeightChanged : function(newViewPortHeigh, forceResizing) {
        if(PersistentModels.getInstance().getRouter().isEqualToCurrentPageUrl("#mySubscriptions") || forceResizing){
            if (this.el) {
                const headerHeight = 56;
                const adjustedListContainerHeight = (newViewPortHeigh  ) ;
                
                if(this.subscriptionList$el){
                    this.subscriptionList$el.css("height", adjustedListContainerHeight + "px")
                }
                if(this.$placeHolderMyFeedListEl){
                    this.$placeHolderMyFeedListEl.css("height", adjustedListContainerHeight + "px")
                }

            }
        }
    },

    onSubscriptionTabSelected: function(){
        this.selectedTab = "subscriptionTab";
        this.showApplicableHeaderButtons();
    },
    isSubscriptionTabSelected: function(){
        return this.selectedTab == "subscriptionTab";
    },

    onDailyNotificationBoxClicked : function(){
        this.trigger("onUserEnableDisableDailyNotification", this.isDailyNotificationTurnOn());
    },

    onListenOnVoiceEnabledDeviceBtnClicked: function(){
        this.trigger("listenOnVoiceEnabledDeviceBtnClicked");
    },

    onEnableNotificationToggleDiscardedByUser : function(){
        this.trigger("enableNotificationToggleDiscardedByUser");
    },

    showUpgradeBtn : function(){
        if(this.upgradeToTrebblePaidSubscriptionBoxWrapper$el.hasClass("hide")){
            this.upgradeToTrebblePaidSubscriptionBoxWrapper$el.removeClass("hide");
        }
    },

    hideUpgradeBtn : function(){
        if(!this.upgradeToTrebblePaidSubscriptionBoxWrapper$el.hasClass("hide")){
            this.upgradeToTrebblePaidSubscriptionBoxWrapper$el.addClass("hide");
        }
    },

    showListenOnVoiceEnabledDeviceBtn : function(){
        if(this.listenOnVoiceEnabledDeviceBoxWrapper$el.hasClass("hide")){
            this.listenOnVoiceEnabledDeviceBoxWrapper$el.removeClass("hide");
        }
    },

    hideListenOnVoiceEnabledDeviceBtn : function(){
        if(!this.listenOnVoiceEnabledDeviceBoxWrapper$el.hasClass("hide")){
            this.listenOnVoiceEnabledDeviceBoxWrapper$el.addClass("hide");
        }
    },




    onFeedTabSelected: function(){
        this.selectedTab = "feedTab";
        this.showApplicableHeaderButtons();
    },

    isFeedTabSelected: function(){
        return this.selectedTab == "feedTab";
    },

    filterList : function(){
        this.trigger("filter");
    },

    onAddToSubscriptionsBtnSeclected : function() {
        this.trigger("addToSubscriptions");
    },

    showUserRefferalpage : function(){
        this.trigger("showUserRefferalpage");
    },

    showUpgradeToPaidSubscriptionPopup : function(){
        this.trigger("showUpgradeToPaidSubscriptionPopup");
    },

    setDailyNotification : function(turnOn){
        this.$el.find(".enableDisableDailyNotificationBox .switch  input").prop('checked', turnOn);
    },

    isDailyNotificationTurnOn : function(){
        return this.$el.find(".enableDisableDailyNotificationBox .switch  input").prop('checked');
    },

    showHideReferUserMenuBtn : function(show){
        const userRefferalButtonWrapper$el  = this.$el.find(".showUserRefferalPageBoxWrapper")
        if(show){
            if(userRefferalButtonWrapper$el.hasClass("hide")){
                userRefferalButtonWrapper$el.removeClass("hide");
            }
        }else{
            if(!userRefferalButtonWrapper$el.hasClass("hide")){
                userRefferalButtonWrapper$el.addClass("hide");
            }
        }
    },
    

    showApplicableHeaderButtons : function(){
        /*if(this.showCapsuleTab){
            if(this.isSongTabSelected()){
                this.createCapsuleBtn$el.hide();
                if(this.customSearchFieldView){
                    this.customSearchFieldView.$el.show();
                    
                }
                this.sortFilterBtn$el.show();
            }else{
                //Capsule Tab Selected
                if(this.customSearchFieldView){
                    this.customSearchFieldView.clearSearchField()
                    this.customSearchFieldView.$el.hide();
                    
                }
                this.sortFilterBtn$el.hide();
                this.createCapsuleBtn$el.attr('style', 'display: -webkit-flex; display: flex');
            }
        }*/
    },


    onHomeButtonSelected: function(){
        this.trigger("showHomePopup");
    },

    onHelpHeaderButtonClicked : function(){
        this.trigger("showHelpPage");
    },


    playMyFeed : function(){
        this.trigger("playMyFeed");
    },

    _buildSearchHeaderButtonAndToPage: function(){
        const customSearchFieldViewParams = {};
        customSearchFieldViewParams.searchFieldPlaceholderSelector = "#pageHeaderTitleWrapper";
        customSearchFieldViewParams.searchFieldPlaceholderSelectorRootNodeEl  = this.el;
        this.customSearchFieldView = new CustomSearchFieldView(customSearchFieldViewParams);
        this.customSearchFieldView.render();
        this.customSearchFieldView.addKeyUpHandlerFunction(this._onKeyUpInSearchField.bind(this));
        this.customSearchFieldView.addOnSearchFieldClearedFunction(this._onSearchFieldCleared.bind(this));
        this.customSearchFieldView.addOnScreenResizeHandlerFunction(this._resizeSearchField.bind(this))
        this.$el.find(".rightHeaderButtonGroup").prepend(this.customSearchFieldView.el);
    },

    getSearchFieldValue : function(){
        return this.customSearchFieldView.getSearchFieldValue();
    },

    _resizeSearchField : function(searchField){
        const homeButtonWidth = 61;
        const searchButtonWidth = 56;
        const identifyButtonWidth = 56;
        const newSearchFieldWidth = (window.getDocumentClienWidth() - homeButtonWidth - searchButtonWidth - identifyButtonWidth) + "px";
        searchField.width(newSearchFieldWidth);
    },
    _onKeyUpInSearchField : function(searchFieldValue){
        if(this._onKeyUpInSearchFieldHandler){
            this._onKeyUpInSearchFieldHandler(searchFieldValue);
        }
    },


    _onSearchFieldCleared : function(){
        if(this._onSearchFieldClearedHandler){
            this._onSearchFieldClearedHandler();
        }
    },

    setPlaying : function(playing, loading){
        if(loading){
            this.$el.removeClass("isCurrentlyPlaying");
            this.$el.addClass("isCurrentlyLoading");
        }else{
            this.$el.removeClass("isCurrentlyLoading");
            if(playing) {
                this.$el.addClass("isCurrentlyPlaying");
            } else {
                this.$el.removeClass("isCurrentlyPlaying");

            }
        }
    },

    onPageBeforeShow : function(){
        this._setCurrentTabWithActiveClass();
        this.$el.find(".ui-tabs-anchor.ui-btn-active").removeClass("ui-btn-active");
        this.$el.find("#"+this.selectedTab).addClass("ui-btn-active");
        this.$el.find("#"+this.subSecondSelectedTab).addClass("ui-btn-active");
        //this.$el.find("#"+this.subSelectedTab).addClass("ui-btn-active");
        if(this.isSubscriptionTabSelected()){
            this.$el.find("#subscriptionList").css("display","block");
        }
        if(this.isFeedTabSelected()){
            this.$el.find("#feedList").css("display","block");
        }
        
        this.showApplicableHeaderButtons();
        this.showHideFloatingPlayButtonOnScrollIfApplicable();
        this._isPageDisplayed = true;
        this.trigger("pageBeforeShow");
    },

    isPageDisplayed : function(){
        return this._isPageDisplayed;
    },	


    onPageBeforeHide : function(){
        if(this.isSubscriptionTabSelected()){
            this.$el.find("#subscriptionList").css("display","none");
        }
        if(this.isFeedTabSelected()){
            this.$el.find("#feedList").css("display","block");
        }
        this._isPageDisplayed = false;
    },

    _setCurrentTabWithActiveClass: function(){
        if(this.isSubscriptionTabSelected()){
            this.$el.find("#subscriptionTab").addClass("ui-btn-active");
        }
        if(this.isFeedTabSelected()){
            this.$el.find("#feedTab").addClass("ui-btn-active");
        }
    },

    selectMySubscriptionTab: function(){
        this._selectTab("subscriptionTab");
        
    },

    selectMyFeedTab: function(){
        this._selectTab("feedTab");
        
    },

    showSubscriptionsInChangeOrderMode :function(){
        const mySubscriptionsListViewContainer$el = $(this.mySubscriptionsListView.el).find("#collection_repeat_container");
        if(!mySubscriptionsListViewContainer$el.hasClass("sortable")){
            mySubscriptionsListViewContainer$el.addClass("sortable");
        }
        this._sortableList = new Sortable(mySubscriptionsListViewContainer$el.find(".collection_content_wrapper").get(0), {"animation": 400, "handle":".dragBtn", "onUpdate": this._onSubscriptionSortingChanged.bind(this)});
        this.enableEditMode();
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("MySubscriptions", 'PlaybackOrderChangeModeEnabled', 'Enable mode to change your subscriptions playback order');
        }
    },

    isInEditMode : function(){
        return this.$el.hasClass("editMode");
    },

    enableEditMode : function(){
        const page$el = this.$el;
        if(!page$el.hasClass("editMode")){
            page$el.addClass("editMode");
        }
        const header$el = this.$el.find("[data-role=header]");
        if(!header$el.hasClass("twoButtons")){
            header$el.addClass("twoButtons");
        }
    },

    setFeedBoxLoading : function(){
        this.onYourFeedTodayBoxWrapper$el.attr("state",ON_FEED_TODAY_BOX_STATUS.LOADING);
    },


    setErrorOnFeedBoxLoading : function(error){
        this.onYourFeedTodayBoxWrapper$el.attr("state",ON_FEED_TODAY_BOX_STATUS.ERROR);
        this.onYourFeedTodayBoxWrapper$el.find(".error_message").html(error);
    },

    setFeedBoxHasNoContent : function(error){
        this.onYourFeedTodayBoxWrapper$el.attr("state",ON_FEED_TODAY_BOX_STATUS.NO_NEW_CONTENT);
    },

    setFeedBoxContentOld : function(trebbleNamesAndLatestHeadlinesInUserSubsription){
        this.onYourFeedTodayBoxWrapper$el.attr("state",ON_FEED_TODAY_BOX_STATUS.CONTENT_LOADED);

        if(trebbleNamesAndLatestHeadlinesInUserSubsription && trebbleNamesAndLatestHeadlinesInUserSubsription.length > 0){
            const title =  window.getI18n(ti18n, "ON_YOUR_FEED_TODAY");
            //const headlinesSummary = "<span style='font-weight: bold;'>"+window.getI18n(ti18n, "NEW_CAPSULES_FROM") +" "+trebbleNamesAndLatestHeadlinesInUserSubsription.length +" "+ window.getI18n(ti18n, "OF_YOUR_SUBSCRIPTIONS")+ ":</span> <br>";//+ getI18nForLanguage(language, "LISTEN_NOW_TO_GET_CAUGHT_UP") +"! <br><br>";
            let headlinesSummary  = "";
            //headlinesSummary =headlinesSummary +"<div class='text_summary' >"; //style='padding-left: 20px;'>"
            if(trebbleNamesAndLatestHeadlinesInUserSubsription.length >= 1){
                headlinesSummary = headlinesSummary + "<div class='text_summary_item'>• "+ "<span class='text_summary' ><span style='font-weight: bold;'>" +window.getI18n(ti18n, "FROM_TREBBLE") +" "+trebbleNamesAndLatestHeadlinesInUserSubsription[0].trebbleName+ " :</span> "+ trebbleNamesAndLatestHeadlinesInUserSubsription[0].firstCapsuleHeadline+"" +"</span></div>";
            }
            if(trebbleNamesAndLatestHeadlinesInUserSubsription.length >= 2){
                headlinesSummary = headlinesSummary + "<div class='text_summary_item'>• "+ "<span class='text_summary' ><span style='font-weight: bold;'>" +window.getI18n(ti18n, "FROM_TREBBLE")+" "+trebbleNamesAndLatestHeadlinesInUserSubsription[1].trebbleName+ " :</span> "+ trebbleNamesAndLatestHeadlinesInUserSubsription[1].firstCapsuleHeadline+""+"</span></div>";
            }
            if(trebbleNamesAndLatestHeadlinesInUserSubsription.length == 3){
                headlinesSummary = headlinesSummary + "<div class='text_summary_item'>• "+"<span class='text_summary' ><span style='font-weight: bold;'>" + window.getI18n(ti18n, "FROM_TREBBLE") +" "+trebbleNamesAndLatestHeadlinesInUserSubsription[2].trebbleName+ " :</span> "+ trebbleNamesAndLatestHeadlinesInUserSubsription[2].firstCapsuleHeadline+"" +"</span></div>";
            }
            if(trebbleNamesAndLatestHeadlinesInUserSubsription.length > 3){
                headlinesSummary = headlinesSummary + "<div class='text_summary_item'>• "+"<span class='text_summary' ><span style='font-weight: bold;'>" + window.getI18n(ti18n, "FROM_TREBBLE") +" "+trebbleNamesAndLatestHeadlinesInUserSubsription[2].trebbleName+ " :</span> "+ trebbleNamesAndLatestHeadlinesInUserSubsription[2].firstCapsuleHeadline+"" + "... "+"</span></div>";
            }
            //headlinesSummary = headlinesSummary +"</div>"
            this.onYourFeedTodayBoxWrapper$el.find(".feedOverviewBox .title").html(title);
            this.onYourFeedTodayBoxWrapper$el.find(".feedOverviewBox .feed_description").html(headlinesSummary);

        }
    },

    setFeedBoxContent : function(trebbleNamesAndLatestHeadlinesInUserSubsription){
        this.onYourFeedTodayBoxWrapper$el.attr("state",ON_FEED_TODAY_BOX_STATUS.CONTENT_LOADED);

        if(trebbleNamesAndLatestHeadlinesInUserSubsription && trebbleNamesAndLatestHeadlinesInUserSubsription.length > 0){
            const title =  window.getI18n(ti18n, "ON_YOUR_FEED_TODAY");
            let trebbleCoverArtPreviews = "";
            const addBoldSpanFunction = function(textHtml){
                return  "<span style='font-weight: 700;'>"+textHtml+"</span>";
            }
            for(let i =0 ; i < trebbleNamesAndLatestHeadlinesInUserSubsription.length; i++){
                const trebbleThumnailUrl = trebbleNamesAndLatestHeadlinesInUserSubsription[i].trebbleCoverArtThumbnailUrl;
                trebbleCoverArtPreviews = trebbleCoverArtPreviews + "<img class'trebble_cover_art_preview lazy' width='40'  height='40' src='"+trebbleThumnailUrl +"'/>";
            }
            let headlinesSummary = "";
            if(trebbleNamesAndLatestHeadlinesInUserSubsription.length == 1){
                 headlinesSummary = window.getI18n(ti18n, "NEW_CAPSULE_FROM")+ " "+ addBoldSpanFunction(trebbleNamesAndLatestHeadlinesInUserSubsription[0].trebbleName);

            }

            if(trebbleNamesAndLatestHeadlinesInUserSubsription.length == 2){
                headlinesSummary =  window.getI18n(ti18n, "NEW_CAPSULES_FROM")+ " "+ addBoldSpanFunction(trebbleNamesAndLatestHeadlinesInUserSubsription[0].trebbleName) +" "+  window.getI18n(ti18n, "AND") + " " + addBoldSpanFunction(trebbleNamesAndLatestHeadlinesInUserSubsription[1].trebbleName);

            }

            if(trebbleNamesAndLatestHeadlinesInUserSubsription.length == 3){
                headlinesSummary =  window.getI18n(ti18n, "NEW_CAPSULES_FROM")+ " "+ addBoldSpanFunction(trebbleNamesAndLatestHeadlinesInUserSubsription[0].trebbleName) +", "+addBoldSpanFunction(trebbleNamesAndLatestHeadlinesInUserSubsription[1].trebbleName) +" "+  window.getI18n(ti18n, "AND") + " " + addBoldSpanFunction(trebbleNamesAndLatestHeadlinesInUserSubsription[2].trebbleName);
            }

            if(trebbleNamesAndLatestHeadlinesInUserSubsription.length > 3){
                headlinesSummary =  window.getI18n(ti18n, "NEW_CAPSULES_FROM")+ " "+ addBoldSpanFunction(trebbleNamesAndLatestHeadlinesInUserSubsription[0].trebbleName) +", "+addBoldSpanFunction(trebbleNamesAndLatestHeadlinesInUserSubsription[1].trebbleName) + ", " + addBoldSpanFunction(trebbleNamesAndLatestHeadlinesInUserSubsription[2].trebbleName)+ " " + window.getI18n(ti18n, "AND") +" "+ (trebbleNamesAndLatestHeadlinesInUserSubsription.length - 3) +" "+ window.getI18n(ti18n, "OTHERS") ;
            }
            const trebble_thumnails_preview_box$el =this.onYourFeedTodayBoxWrapper$el.find(".trebble_thumnails_preview_box");
            trebble_thumnails_preview_box$el.html(trebbleCoverArtPreviews);
            this.onYourFeedTodayBoxWrapper$el.find(".feedOverviewBox .title").html(title);
            this.onYourFeedTodayBoxWrapper$el.find(".feedOverviewBox .feed_description").html(headlinesSummary);
            //Utils.getInstance().initJqueryLazyLoadingPluginOnElemNew(trebble_thumnails_preview_box$el, "lazy", scrollContainer$el, customEvent )

        }
    },

    hideOnYourFeedTodayBox : function(){
        this.onYourFeedTodayBoxWrapper$el.addClass("hide");
        this._isOnYourFeedTodayBoxHidden = true;
        this.showHideFloatingPlayButtonOnScrollIfApplicable();
    },

    isOnYourFeedTodayBoxHidden : function(){
        return this._isOnYourFeedTodayBoxHidden;
    },


    showHideEnableDailyNotificationBox : function(show){
        if(show){
            this.enableDisableDailyNotificationBox$el.removeClass("hide")
        }else{
            if(!this.enableDisableDailyNotificationBox$el.hasClass("hide")){
                this.enableDisableDailyNotificationBox$el.addClass("hide")
            }
            
        }
    },

    isDailyNotificationUIVisible : function(){
        return !this.enableDisableDailyNotificationBox$el.hasClass("hide");
    },

    enableSavingModeEditMode : function(){
        const mySubscriptionsListViewContainer$el = $(this.mySubscriptionsListView.el).find("#collection_repeat_container");
        mySubscriptionsListViewContainer$el.removeClass("sortable");
        const page$el = this.$el;
        page$el.removeClass("editMode");
        if(!page$el.hasClass("savingMode")){
            page$el.addClass("savingMode");
        }
        const header$el = this.$el.find("[data-role=header]");
        //header$el.removeClass("twoButtons");
        if(!header$el.hasClass("twoButtons")){
            header$el.addClass("twoButtons");
        }
    },

    cancelSavingModeEditMode : function(){
        const mySubscriptionsListViewContainer$el = $(this.mySubscriptionsListView.el).find("#collection_repeat_container");
        if(!mySubscriptionsListViewContainer$el.hasClass("sortable")){
            mySubscriptionsListViewContainer$el.addClass("sortable");
        }
        const page$el = this.$el;
        page$el.removeClass("savingMode");
        if(!page$el.hasClass("editMode")){
            page$el.addClass("editMode");
        }
        const header$el = this.$el.find("[data-role=header]");
        if(!header$el.hasClass("twoButtons")){
            header$el.addClass("twoButtons");
        }
    },

    disableEditMode : function(){

        const page$el = this.$el;
        page$el.removeClass("editMode");
        page$el.removeClass("savingMode");
        const mySubscriptionsListViewContainer$el = $(this.mySubscriptionsListView.el).find("#collection_repeat_container");
        mySubscriptionsListViewContainer$el.removeClass("sortable");
        if(this._sortableList){
            this._sortableList.destroy();
            this._sortableList = null;
        }
        const header$el = this.$el.find("[data-role=header]");
        //header$el.removeClass("twoButtons");
        if(!header$el.hasClass("twoButtons")){
            header$el.addClass("twoButtons");
        }
    },
    
    _selectTab : function(tabId){
        const tab$el = this.$el.find("#"+tabId);
        this.selectedTab = tabId;
        
        tab$el.click();
        if(!tab$el.hasClass("ui-btn-active")){
            tab$el.addClass("ui-btn-active");
        }
    },

    setSearchValue : function(newSearchValue){
        this.customSearchFieldView.setSearchFieldValue(newSearchValue, true);
    },

    onChangeOrderClicked : function(){
        this.showSubscriptionsInChangeOrderMode();
    },

    onSaveChangesButtonClicked :function(){
        this.trigger("saveOrderChanges");
    },

    onCancelChangesButtonClicked :function(){
        this.trigger("cancelOrderChanges");
    },

    setLoadingSubscriptions : function(loadingSubscriptions){
        this._loadingSubscriptions =  loadingSubscriptions;
        if(this._loadingSubscriptions){
            if(!this.$el.hasClass("loading_subscriptions")){
                this.$el.addClass("loading_subscriptions");
            }
        }else{
            this.$el.removeClass("loading_subscriptions");
        }
    },

    setNoSubscription : function(noSubscription){
        this._noSubscription =  noSubscription;
        if(this._noSubscription){
            if(!this.$el.hasClass("no_subscription")){
                this.$el.addClass("no_subscription");
            }
        }else{
            this.$el.removeClass("no_subscription");
        }
    },

    hasNoSuscription : function(){
        return this._noSubscription;
    },

    getOrderSubscriptionIds :function(){
        const trebbleIds = [];
        $(this.mySubscriptionsListView.el).find(".collection_content_wrapper").children().each(function(){trebbleIds.push(this.getAttribute("trebble-id"))});
        return trebbleIds;
    },

    hidePlayFeedButtons : function(){
        this.playFeedBtnInYourFeedTodayBox$el.hide();
        this.playMyFeedButton$el.hide();
    },

    showHideFloatingPlayButtonOnScrollIfApplicable: function(){
        if(!this._noSubscription && !this._loadingSubscriptions){
            const showHideFloatingPlayButtonFunc = (function(){
                if(!this._noSubscription && !this._loadingSubscriptions){
                    //this._pendingshowHideFloatingPlayuttonFuncExecutionId = null;

                    let floatingPlayButtonShouldBeHidden = false;
                    if(this.playFeedBtnInYourFeedTodayBox$el.visible()){
                        floatingPlayButtonShouldBeHidden = true;
                    }

                    this.$el.attr("play_button_in_on_your_feed_today_box_displayed", (floatingPlayButtonShouldBeHidden || !SHOULD_FEED_DISPLAYED)?"true":"false");


                }
            }).bind(this);
            showHideFloatingPlayButtonFunc();
        }

    },

    isInAlwaysEditMode(){

    },

    setInAlwaysEditMode: function(inAlwaysEditMode, hideInstructions){
        this._inAlwaysEditMode = inAlwaysEditMode;
        if(this._inAlwaysEditMode){
            if(!this.$el.hasClass(ALWAYS_EDIT_MODE_CLASS_NAME)){
                this.$el.addClass(ALWAYS_EDIT_MODE_CLASS_NAME)
                if(hideInstructions){
                    this.$el.find(".changeSubscriptionOrderInstructionBox").hide();
                }
                const mySubscriptionsListViewContainer$el = $(this.mySubscriptionsListView.el).find("#collection_repeat_container");
                if(!mySubscriptionsListViewContainer$el.hasClass("sortable")){
                    mySubscriptionsListViewContainer$el.addClass("sortable");
                }
                this._sortableList = new Sortable(mySubscriptionsListViewContainer$el.find(".collection_content_wrapper").get(0), {"animation": 400, "handle":".dragBtn", "onUpdate": this._onSubscriptionSortingChanged.bind(this)});
            }
        }else{
            this.$el.removeClass(ALWAYS_EDIT_MODE_CLASS_NAME)
        }
    },

    _onSubscriptionSortingChanged : function(event){
        this.trigger("subscriptionSortingChanged");
    },

    render : function() {
        this.$el.html(this.template({"listeningInstructionHtml": Utils.getInstance().getHtmlTrebbleListeningInstructionsNew(null, true, false, true, true, false, true), "ti18n": ti18n}));
        this.setElement(this.$el.find("div").first());
        //this._buildSearchHeaderButtonAndToPage();
        
        this.sortFilterBtn$el = this.$el.find("#filterButton");
        this.playMyFeedButton$el = this.$el.find("#playMyFeedButton");
        this.cancelChangeButton$el = this.$el.find("#cancelChangeButton");
        this.saveChangeButton$el = this.$el.find("#saveChangeButton");
        this.changeOrderButton$el = this.$el.find("#changeOrderButton");
        this.enableDisableDailyNotificationBox$el = this.$el.find(".enableDisableDailyNotificationBox");
        this.onYourFeedTodayBoxWrapper$el =  this.$el.find("#onYourFeedTodayBoxWrapper");
        this.playFeedBtnInYourFeedTodayBox$el = this.$el.find("#play_feed_btn");
        this.upgradeToTrebblePaidSubscriptionBoxWrapper$el = this.$el.find(".upgradeToTrebblePaidSubscriptionBoxWrapper");
        this.listenOnVoiceEnabledDeviceBtn$el = this.$el.find("#listenOnVoiceEnabledDeviceBtn");
        this.listenOnVoiceEnabledDeviceBoxWrapper$el = this.$el.find(".listenOnVoiceEnabledDeviceBoxWrapper");
        
        this.$placeHolderMySubscriptionsListEl = this.$el.find("#mySubscriptionsListWrapper").first();
        this.subscriptionList$el = this.$el.find("#subscriptionList");
        this.$placeHolderMySubscriptionsListEl.append(this.mySubscriptionsListView.el);

        /*this.$placeHolderMySubscriptionsListEl = this.$el.find("#myFeedListWrapper").first();
        this.$placeHolderMySubscriptionsListEl.append(this.myCapsuleFeedView.el);*/
        

        this.$el.page();



        Utils.getInstance().initJqueryLazyLoadingPluginOnElemNew(this.$el.find("#subscriptionList #collection_repeat_container"), "img.lazy", this.$el.find("#subscriptionList"));


        //manually adding the wrapper necessary by JQM for showing Panel
        //JQM does it on the fly but that can cause flickering on the screen when it is done by jquery mobile
        //the workaround is to do that manually when the page is getting initialized
        this.$el.children( ".ui-header:not(.ui-header-fixed), .ui-content:not(.ui-popup), .ui-footer:not(.ui-footer-fixed)" ).wrapAll( "<div class='ui-panel-wrapper'></div>" )

        this.$el.on("pageshow", this.onPageBeforeShow.bind(this));
        this.$el.on("pagebeforehide", this.onPageBeforeHide.bind(this));
        this.changeOrderButton$el.on("click",this.onChangeOrderClicked.bind(this));
        this.$el.find(".enableDisableDailyNotificationBox .switch input").on("click",this.onDailyNotificationBoxClicked.bind(this));
        this.saveChangeButton$el.on("click",this.onSaveChangesButtonClicked.bind(this));
        this.cancelChangeButton$el.on("click",this.onCancelChangesButtonClicked.bind(this));

        $(this.$el.find(".ui-content")).on("resize scroll", (function() {
            this.showHideFloatingPlayButtonOnScrollIfApplicable()
        }).bind(this));

        this.showApplicableHeaderButtons();
        this._onViewportHeightChanged(PersistentModels.getInstance().getRouter().getViewportHeight(), true);
        return this;
    }

});

export default MyLibraryPageView;