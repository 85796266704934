

import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import MergedSequencerNodeSchedulingInfo from "models/audioEditor/MergedSequencerNodeSchedulingInfo";
import AudioBufferHelper from "models/audioEditor/AudioBufferHelper";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const COMPARE_AUDIO_FILTERS = true;

const MergedIncompleteWrapSequencerNodeSchedulingInfo =  MergedSequencerNodeSchedulingInfo.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        MergedSequencerNodeSchedulingInfo.apply(this, [attributes, options]);

    },

    initializeWithStartWrapNode: function(nodeSchedulingInfoForStartWrapNode, newStartTimeOffset, audioContext){
        const startWapNode = nodeSchedulingInfoForStartWrapNode.getSequencerNodeReference();
        const marginInMilliseconds = startWapNode.getCurrentNodeMargin();
        const durationInMillisecondsThatShouldBeSkipped  = newStartTimeOffset - nodeSchedulingInfoForStartWrapNode.getStartTimeOffset();
        const durationInMillisecondsOfAudioBufferToPlay = nodeSchedulingInfoForStartWrapNode.getEndTimeOffsetForSourceBuffer() - nodeSchedulingInfoForStartWrapNode.getStartTimeOffsetForSourceBuffer();
        const shouldBeLoopedToMatchDuration =  startWapNode.getLoopToMatchWrapNodeDuration();
        const durationInMillisecondsLeftToPlay = shouldBeLoopedToMatchDuration?nodeSchedulingInfoForStartWrapNode.getDuration() - durationInMillisecondsThatShouldBeSkipped:  durationInMillisecondsOfAudioBufferToPlay - durationInMillisecondsThatShouldBeSkipped;
        const shouldWrapNodeBeDonePlaying = durationInMillisecondsLeftToPlay <= 0;

        const audioNode =  audioContext.createBufferSource();
        audioNode.buffer = nodeSchedulingInfoForStartWrapNode.getAudioBuffer();
        audioNode.loop = nodeSchedulingInfoForStartWrapNode.getAudioNode().loop;
        audioNode.loopStart = nodeSchedulingInfoForStartWrapNode.getAudioNode().loopStart;
        audioNode.loopEnd = nodeSchedulingInfoForStartWrapNode.getAudioNode().loopEnd;
        this.setAudioNode(audioNode);
        this.setSequencerNodeReference(nodeSchedulingInfoForStartWrapNode.getSequencerNodeReference());
        this.setRelativeStartTime(0);
        this.setRelativeEndTime(nodeSchedulingInfoForStartWrapNode.getRelativeEndTime());
        this.setInitialStartTimeOffset(nodeSchedulingInfoForStartWrapNode.getStartTimeOffset());
        this.setStartTimeOffset(newStartTimeOffset);
        this.setStartTimeOffsetForSourceBuffer(nodeSchedulingInfoForStartWrapNode.getStartTimeOffsetForSourceBuffer());
        this.setEndTimeOffsetForSourceBuffer(nodeSchedulingInfoForStartWrapNode.getEndTimeOffsetForSourceBuffer());
        this.setDuration(nodeSchedulingInfoForStartWrapNode.getDuration() - durationInMillisecondsThatShouldBeSkipped);
        this.setAudioBufferPlaybackDuration(nodeSchedulingInfoForStartWrapNode.getAudioBufferPlaybackDuration() - durationInMillisecondsThatShouldBeSkipped);
        this.getSchedulingNodeToBePlayed().push(nodeSchedulingInfoForStartWrapNode);
        this.setStartTimeOffsetLoopForSourceBuffer((durationInMillisecondsThatShouldBeSkipped% durationInMillisecondsOfAudioBufferToPlay));
        if(shouldWrapNodeBeDonePlaying){
            this.setStartTimeOffsetForSourceBuffer(AudioBufferHelper.getInstance().getMaxAudioBufferDuration(audioNode.buffer)*1000);
        }
    },

    getInitialStartTimeOffset : function(){
        return this.get("initialStartTimeOffset");
    },

    setInitialStartTimeOffset : function(initialStartTimeOffset){
        this.set("initialStartTimeOffset",initialStartTimeOffset);
    },


});

export default MergedIncompleteWrapSequencerNodeSchedulingInfo; 