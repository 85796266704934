import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import SequencerOperationUtils from "models/audioEditor/sequencerOperation/SequencerOperationUtils";
import RSVP from "rsvp";

const SequencerOperation =  Backbone.Model.extend({
    idAttribute: "id",

    constructor: function(attributes, options) {
        Backbone.Model.apply(this, [attributes, options]);
        
    },

    getSequencer : function(){
        return this.get("sequencer");
    },

    execute : function(params){
        throw new Error("Need to be implemented in subclass!");
    },

    undo: function(operationLog){
        throw new Error("Need to be implemented in subclass!");
    },

    redo: function(operationLog){
        throw new Error("Need to be implemented in subclass!");
    },

    getArrayOfSequencerNodes : function(startNode, endNode){
        return SequencerOperationUtils.getInstance().getArrayOfSequencerNodes(startNode, endNode);
    },

    isStartNodeAndEndNodeConnected : function(startNode, endNode){
        return SequencerOperationUtils.getInstance().isStartNodeAndEndNodeConnected(startNode, endNode);
    },

    insertAudioNodeArray : function(arrayOfSequencerNodes, sequencerNodeToInsertNextTo, insertBefore ){
        return SequencerOperationUtils.getInstance().insertAudioNodeArray(this.getSequencer(), arrayOfSequencerNodes, sequencerNodeToInsertNextTo, insertBefore )
    },

    removeAudioNode : function(audioSequencerNode){
        return SequencerOperationUtils.getInstance().removeAudioNode(this.getSequencer(),audioSequencerNode);
    },

    removeAudioNodeArray : function(arrayOfSequencerNodes){
        return SequencerOperationUtils.getInstance().removeAudioNodeArray(this.getSequencer(),arrayOfSequencerNodes);
    },

    

});



export default SequencerOperation; 