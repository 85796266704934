import $ from 'jquery';
//import _ from 'underscore';
import Backbone from "backbone";
import YoutubeSearchHelper from "models/helper/YoutubeSearchHelper";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
//import CordovaHelper from "models/helper/CordovaHelper";
import Utils from "models/helper/Utils";
import YoutubeVideoUrlExtractor from "models/helper/YoutubeVideoUrlExtractor";
import ti18n from "i18n!nls/helperi18n";
import RSVP from "rsvp";

const mediaStatuses = {
        MEDIA_NONE : 0,
        MEDIA_STARTING : 1,
        MEDIA_RUNNING :2,
        MEDIA_PAUSED : 3,
        MEDIA_STOPPED : 4,
        };

        let supportedYoutubeItagFormatOnlyOnWifi = [/*"45","22","37","38"*/ ];
const supportedYoutubeItagFormatOnlyOnWifiIOS = [/*"45","22","37","38"*/ ];
let supportedYoutubeItagFormat = [ "34","35","18","36","5","17", "44","43", "83","82",  "18", "141", "140","139","172","171",  "37", "22","38" ,"84","85" , "45","46","102", "101","100",]; //ordered by quality
const supportedYoutubeItagFormatIOS = [ "34","35","18","36","5","17", "83","82", "18", "141", "140","139","172","171", "37",  "22","38" ,"84","85" ]; //ordered by quality WEBM nnot suported on ios
const YOUTUBE_SONG_URI_PREFIX = "youtube:"
/*const reloadVideoDomNode = function(videoUrl){
    document.getElementsByTagName('video')[0].src = videoUrl;
};

const playVideoDomNode = function(){
    document.getElementsByTagName('video')[0].play();
};

const pauseVideoDomNode = function(){
    document.getElementsByTagName('video')[0].pause();
};*/


const YoutubeMedia =  Backbone.Model.extend({
    "default": {
        "DEFAULT_MEDIA_STATUS" :mediaStatuses,
        "src": "",
        "mediaObjectCreatedSuccess":"",
        "mediaObjectCreatedFailure":"",
        "soundObject" :"",
        "mediaSuccess": "",
        "mediaError": "",
        "mediaStatus": "",
    },

    initialize : function(options/*, onSucces, onError, onStatus*/) {
        this._loading = true;
        this.trigger("loading");
        this.youtubeDirectVideoUrl = options.directURL;
        this.videoPlaceHolderEl = options.videoPlaceHolderEl;
        this.songModel = options.songModel;
        this.emptyPlaceHolder = options.emptyPlaceHolder;
        this.onSuccessHandler = options.onSuccess;
        this.onErrorHandler = options.onError;
        this.uriBroken = options.uriBroken;
        this.onStatusHandler = options.onStatus;
        this.onFailedLoadingHandler = options.onFailedLoading;
        if(window._currentPlayingYoutubeMedia){
            window._currentPlayingYoutubeMedia.release();
        }
        this._createVideoElement();
    },
    
    isLoading : function(){
        return this._loading;
    },
    
    getLoadingError : function(){
        return this._loadingError;
    },
    
        
        _createVideoElement : function(){
            const oldVideoElArray = document.getElementsByTagName("video");
            if(oldVideoElArray && oldVideoElArray.length > 0){
                //Remove all video elements in document
                for(let i =  0; i< oldVideoElArray.length; i++)
                {
                    const oldVideoEl = oldVideoElArray[i];
                    oldVideoEl.autoplay = false;
                    oldVideoEl.pause();
                    oldVideoEl.src = null;
                    oldVideoEl.load();
                    oldVideoEl.parentNode.removeChild(oldVideoEl);
                    //delete oldVideoEl;
                    window.log("Current video element removed from page");
                }
            }
            this.videoEl =  document.createElement('video');
            
            this.className = "musicVideo";
            //this.videoEl.src = this.youtubeDirectVideoUrl;
            
            this._searchYoutubeVideoUrlAndLoadVideo();
            //AndroidApp.loadVideo(this.youtubeDirectVideoUrl);
            
            /* this.videoSourceEl = document.createElement('source');
             this.videoSourceEl.src = this.youtubeDirectVideoUrl;
             this.videoSourceEl.type = "video/mp4";
             this.videoEl.appendChild( this.videoSourceEl);*/
            this.videoEl.style.height ="100%";
            this.videoEl.style.width ="100%";
            this.videoEl.autoplay = true;
            this.videoEl.setAttribute("webkit-playsinline" ,  true);
            this.videoEl.controls = false;
            if(window.device.platform == "iOS"){
                this.videoEl.controls = true;
            }
            this.videoEl.poster = window.getResourceURLAccordingToLocationProtocol("/img/videoLoading.png");
            //this.videoEl.preload = "true";
            if(this.emptyPlaceHolder){
                while (this.videoPlaceHolderEl.firstChild) {
                    this.videoPlaceHolderEl.removeChild(this.videoPlaceHolderEl.firstChild);
                }
            }
        

            this.videoEl.error = this._onError.bind(this);
            this.videoEl.pause  = this._onVideoPaused.bind(this);
            this.videoEl.play = this._onVideoPlaying.bind(this);
            this.videoEl.waiting = this._onVideoStop.bind(this);
            //this.videoEl.onended =  this._onVideoFinishedPlaying.bind(this);
            //this.videoEl.ended =  this._onVideoFinishedPlaying.bind(this);
            this.videoEl.addEventListener('ended',this._onVideoFinishedPlaying.bind(this),false);
            this.videoEl.addEventListener('canplay',this._onVideoReadyToPlay.bind(this),false);
            this.videoEl.addEventListener('durationchange',this._onDurationchange.bind(this),false);
            this.videoEl.addEventListener('stalled',this._onVideoStalled.bind(this),false);
            this.videoEl.addEventListener('loadedmetadata',this._onLoadedmetadata.bind(this),false);
            this.videoEl.addEventListener('progress',this._onDownloadingVideoFrames.bind(this),false);
            //document.getElementById("albumArtImage").style.display = "none";
            //this.videoPlaceHolderEl.style.display = "block";
            //this.videoPlaceHolderEl.style.visibility = "visible";
            if(!this.videoEl.parentNode)
            {
                this.videoPlaceHolderEl.appendChild(this.videoEl);
            }
            $(this.videoEl).on("click", function(){
                const resizeBtn$el = $("#player * #resizeVideoFullBtn");
                if(!resizeBtn$el.hasClass("showOnHover")){
                    resizeBtn$el.addClass("showOnHover");
                }
            })
            
            
    
        },
        
        _retrieveDirectUrlForSongModel : function(songModel, uriBroken){
            return (new RSVP.Promise((function(resolve, reject){
                try{
                    if(!uriBroken && songModel.get("uri").indexOf(YOUTUBE_SONG_URI_PREFIX) > -1)
                    {
                        const songUri = songModel.get("uri");
                        const videoId = songUri.substr(YOUTUBE_SONG_URI_PREFIX.length );
                        return YoutubeSearchHelper.getInstance().getVideoInfoFromVideoId(videoId).then((function(youtubeVideoJsonInfo){
                            if(youtubeVideoJsonInfo){
                                return youtubeVideoJsonInfo;
                            }else{
                                window.log("youtube uri is probably broken. uri:"+ songUri);
                                const q = songModel.get("artist") + " - " + songModel.get("title") ;
                                return YoutubeSearchHelper.getInstance().getMostViewVideoInfo(q, true);
                            }
                        }).bind(this)).then((function(youtubeVideoJsonInfo){
                            resolve(youtubeVideoJsonInfo);
                        }).bind(this)).catch((function(error){
                            reject(error);
                        }).bind(this));
                    }else{
                        const q = songModel.get("artist") + " - " + songModel.get("title") ;
                        return YoutubeSearchHelper.getInstance().getMostViewVideoInfo(q, true).then((function(youtubeVideoJsonInfo){
                            resolve(youtubeVideoJsonInfo);
                        }).bind(this)).catch((function(error){
                            reject(error);
                        }).bind(this));
                    }
                }catch(err){
                    reject(err);
                }
            }).bind(this))).then((function(youtubeVideoJsonInfo){
                if(youtubeVideoJsonInfo)
                {
                    //const promiseArray = [];
                    let videoId = youtubeVideoJsonInfo.videoId;
                    if(!videoId && youtubeVideoJsonInfo.id){
                        videoId = youtubeVideoJsonInfo.id
                    }
                    return this._getDecryptedSongUrl(videoId);
                }else{
                    
                    throw new Error(window.getI18n(ti18n, "COULDNT_FIND_THE_MATCHING_VIDEO"));
                }
            }).bind(this));
        },
        
        _getDecryptedSongUrl : function(videoId){
            let itagToSignatureInfo = null;
            let itagToURlInfo = null
            let jsPlayerUrl = null;
            let youtubeVideoDirectUrlFromServer = null;
            let decryptedSignature = null;
            let oldSignatureEndIndex;
            /* YoutubeVideoUrlExtractor.getInstance().getVideoUrl(videoId).then(function(directVideoInfo){
                window.log("Video Info Found . Yay:"+JSON.stringify(directVideoInfo));
            }).catch(function(error){
                console.error("Failed Retrieving Direct Video Info.Error"+ error);
            })*/
            //return CordovaHelper.getInstance().executePluginOperation("MusicFileReaderPlugin","getSignaturesForYoutubeUrl", [videoId]).then((function(result){
            return YoutubeVideoUrlExtractor.getInstance().getVideoUrl(videoId).then((function(result){
                 window.log("Result of youtube signature decryption. Result:" + JSON.stringify(result));
                itagToSignatureInfo = result.itagToSignature;
                itagToURlInfo = result.itagToUrl;
                jsPlayerUrl =  result.playerJSUrl;
            }).bind(this)).then((function(){
                //if(youtubeVideoDirectUrlFromServer)
                if(itagToURlInfo)
                {
                //	const itagStartIndex = youtubeVideoDirectUrlFromServer.indexOf("itag=");
                //	const itagEndIndex = youtubeVideoDirectUrlFromServer.indexOf("&",itagStartIndex);
                //	const itag = youtubeVideoDirectUrlFromServer.substring(itagStartIndex+5, itagEndIndex);
                let encryptedSig = null;
                    let itag = null;
                    /*for(let pairIndex in itagToURlInfo){
                        const pair = itagToURlInfo[pairIndex];
                        for(let aItag in pair)
                        {
                            if (supportedYoutubeItagFormatOnlyOnWifi.indexOf(aItag) > -1 && navigator.connection.type != Connection.WIFI) {
                                itag = aItag;
                                const url = pair[itag];
                                break;
                            }
                            
                        }
                        if(url && itag)
                        {
                            youtubeVideoDirectUrlFromServer = url;
                            break;
                        }
                    }*/
                    const itagToUrlMap = itagToURlInfo || [];
                    /*for(let pairIndex in itagToURlInfo){
                        const pair = itagToURlInfo[pairIndex];
                        for(let aItag in pair)
                        {
                            itagToUrlMap[aItag] = pair[aItag];									
                        }
                    }*/
                    if(window.device.platform == "iOS"){
                        supportedYoutubeItagFormat = supportedYoutubeItagFormatIOS
                        supportedYoutubeItagFormatOnlyOnWifi = supportedYoutubeItagFormatOnlyOnWifiIOS;
                    }
                    for(let index in supportedYoutubeItagFormat){
                        const anItag = supportedYoutubeItagFormat[index];
                        const itagAllowedOnWifiOnly = supportedYoutubeItagFormatOnlyOnWifi.indexOf(anItag) > -1;
                        if (!itagAllowedOnWifiOnly || (itagAllowedOnWifiOnly && window.Connection &&  navigator.connection.type == window.Connection.WIFI)) {
                            const url = itagToUrlMap[anItag];
                            if(url)
                            {
                                itag = anItag;
                                youtubeVideoDirectUrlFromServer = url;
                                break;
                            }
                        }
                    }
                    if(itag){
                        /*for(let pairIndex in itagToSignatureInfo){
                            const pair = itagToSignatureInfo[pairIndex];
                                encryptedSig = pair[itag];
                                if(encryptedSig)
                                {
                                    break;
                                }
                        }*/
                        encryptedSig = itagToSignatureInfo[itag];
                    }
                    
                    if(encryptedSig)
                    {
                        jsPlayerUrl = "http:" + jsPlayerUrl.replace(/\\\//g, "/").replace('"', "").replace('"', ""); 
                        console.warn("This youtube video needs to be decrypted.:(  Video url:" + jsPlayerUrl);
                        return TrebbleClientAPIHelper.getInstance().getDecryptedYoutubeSignature(encryptedSig, jsPlayerUrl).then((function(decryptedSigs){
                            
                            //find sig
                        /*	for(let pairIndex in itagToURlInfo){
                                const pair = itagToURlInfo[pairIndex];
                                const url = pair[itag];
                                if(url)
                                {
                                    youtubeVideoDirectUrlFromServer = url;
                                    break;
                                }
                            }*/
                            
                            if(decryptedSigs && decryptedSigs.length > 0){
                                
                                decryptedSignature = decryptedSigs[0];
                            }
                            let convertedYoutubeVideoDirectUrl = null;
                            if(decryptedSignature)
                            {
                                console.warn("Here is the decrypted signature :)  Signature:" +decryptedSignature);
                                const oldSignatureStartIndex = youtubeVideoDirectUrlFromServer.indexOf("signature=");
                                const afterOldSignatureStartIndex = oldSignatureStartIndex + (new String("signature=")).length;
                                
                                if(oldSignatureStartIndex > -1)
                                {
                                     oldSignatureEndIndex = youtubeVideoDirectUrlFromServer.indexOf("&", afterOldSignatureStartIndex);
                                }
                                if(oldSignatureStartIndex > -1)
                                {
                                    convertedYoutubeVideoDirectUrl = youtubeVideoDirectUrlFromServer.substr(0, afterOldSignatureStartIndex);
                                    convertedYoutubeVideoDirectUrl = convertedYoutubeVideoDirectUrl + decryptedSignature ;
                                    if(oldSignatureEndIndex && oldSignatureEndIndex > -1)
                                    {
                                        convertedYoutubeVideoDirectUrl = convertedYoutubeVideoDirectUrl + youtubeVideoDirectUrlFromServer.substr(oldSignatureEndIndex);
                                    }
                                }
                            }else{
                                console.warn("Something probably went wrong during the decryption :( . Just send the url as is " +youtubeVideoDirectUrlFromServer);
                                convertedYoutubeVideoDirectUrl = youtubeVideoDirectUrlFromServer;
                            }
                            return convertedYoutubeVideoDirectUrl;
                        }).bind(this));
                    }else{
                        console.warn("This youtube video has no signature so there is no need to decrypt it :)");
                        return youtubeVideoDirectUrlFromServer;
                    }
                }else{
                    console.warn("Itag for youtube video was not found. Probably something went wrong while reading video info");
                }
                
            }).bind(this));
            
        },
        
        _searchYoutubeVideoUrlAndLoadVideo : function(){
            window.log("About to start searching for youtube video url");
            const prefetchedDirectVideoUrl = Utils.getInstance().getPrefetchedVideoUrl(this.songModel);
            if(this.youtubeDirectVideoUrl || prefetchedDirectVideoUrl){
                window.log("Never mind!!! Song already has a youtube video url");
                if(this.youtubeDirectVideoUrl){
                    this.videoEl.src = this.youtubeDirectVideoUrl;
                }else{
                    this.videoEl.src = prefetchedDirectVideoUrl;
                }
                //this.videoEl.load();
                //this.videoEl.play();
                return RSVP.Promise.resolve();
            }else{
                if(this.songModel){
                    const uriOfTheCurrentLoadedSong = this.songModel.get("uri");
                    this._retrieveDirectUrlForSongModel(this.songModel, this.uriBroken).then((function(convertedYoutubeVideoDirectUrl){
                        if(!this._isReleased){
                            if(convertedYoutubeVideoDirectUrl )
                            {
                                window.log("Youtube url was found for song with uri " + uriOfTheCurrentLoadedSong);
                                if( uriOfTheCurrentLoadedSong == this.songModel.get("uri"))
                                {
                                    
                                    this.youtubeVideoDirectUrl = convertedYoutubeVideoDirectUrl;
                                    this.videoEl.src = this.youtubeVideoDirectUrl;
                                    //this.videoEl.load();
                                    window.log("Youtube url was assigned to videoElement");
                                }else{
                                    window.log("Song to be loaded has changed. Chances are a different video is currently being loaded so do not load the video");
                                }
                            
                                this.videoEl.play();
                                this.trigger("loaded");
                            }else{
                                this._loading = false;
                                this._loadingError = window.getI18n(ti18n, "FAILED_LOADING_VIDEO");
                                this.trigger("failedLoading",window.getI18n(ti18n, "FAILED_LOADING_VIDEO"));
                                console.error("Couldn't retrieve direct youtube video for video id");
                                if(this.onFailedLoadingHandler){
                                    //call handler for failed loading
                                    this.onFailedLoadingHandler();
                                }else{
                                    //act like the song finish playing,
                                    //player will probably just play the next song
                                    this._onVideoFinishedPlaying();
                                }
                            }
                        }
                    }).bind(this)).catch((function(err){
                        this._loading = false;
                        this._loadingError = window.getI18n(ti18n, "FAILED_LOADING_VIDEO");
                        this.trigger("failedLoading",window.getI18n(ti18n, "FAILED_LOADING_VIDEO"));
                        console.error("Error trying to load youtube video. Err:"+ err);
                        if(this.onFailedLoadingHandler){
                            //call handler for failed loading
                            this.onFailedLoadingHandler();
                        }else{
                            //act like the song finish playing,
                            //player will probably just play the next song
                            this._onVideoFinishedPlaying();
                        }
                    }).bind(this));
                }else{
                    this._loading = false;
                    this._loadingError = window.getI18n(ti18n, "FAILED_LOADING_VIDEO")
                    this.trigger("failedLoading",window.getI18n(ti18n, "FAILED_LOADING_VIDEO"));
                    console.error("No song info found to load  video for.");
                    if(this.onFailedLoadingHandler){
                        //call handler for failed loading
                        this.onFailedLoadingHandler();
                    }else{
                        //act like the song finish playing,
                        //player will probably just play the next song
                        this._onVideoFinishedPlaying();
                    }
                }
            }
        },
        

        play: function() {
            if (!this._isReleased) {
                if (window.device.platform == "iOS" && this.videoEl.srcBeforePause) {
                    this.videoEl.src = this.videoEl.srcBeforePause;
                    this.videoEl.srcBeforePause = null;
                }
                this.videoEl.autoplay = true;
                this.wasPaused = false;
                this.wasStopped = false;
                this._loading = true;
                this.trigger("loading");
        
                const videoReadyToPlayHandler = () => {
                    if (!this._isReleased) {
                        if (this.videoPositionAtStop) {
                            this.videoEl.currentTime = Math.round(this.videoPositionAtStop);
                            window.log("time " + this.videoPositionAtStop);
                        }
                        this.videoEl.play();
                        this.videoEl.removeEventListener("canplay", videoReadyToPlayHandler);
                        this._loading = false;
                        this.trigger("loaded");
                    }
                };
        
                this.videoEl.addEventListener("canplay", videoReadyToPlayHandler, false);
                this.videoEl.load();
            }
            // AndroidApp.playVideo();
            // AndroidApp.startVideo();
        },
        
        _onError : function(err){
            if(this.onErrorHandler)
            {
                this.onErrorHandler(err);
            }
            window.log("Error on video element: "+err);
        },
        

        pause: function(){
            if(!this._isReleased){
                this.videoEl.autoplay = false;
                this.videoEl.pause();
                this.videoPositionAtStop = this.videoEl.currentTime;
                this.wasPaused = true;
                this.wasStopped = false;
                if(window.device.platform == "iOS"){
                    this.videoEl.srcBeforePause = this.videoEl.src;
                    this.videoEl.src = null;
                    this.videoEl.load();
                }
            }
            //AndroidApp.pauseVideo();
        },


        stop: function(){
            if(!this._isReleased){
                this.videoEl.autoplay = false;
                this.videoEl.pause();
                this.videoPositionAtStop = this.videoEl.currentTime;
                this.wasPaused = false;
                this.wasStopped = true;
            }
            //this.videoEl.load();
        },

        release: function(){
        /*	if(this.videoEl.parentNode)
            {
                this.videoEl.parentNode.removeChild(this.videoEl);
            }*/
            //this.videoPlaceHolderEl.style.visibility = "hidden";
            //this.videoPlaceHolderEl.style.display = "none";
            //document.getElementById("albumArtImage").style.display = "block";
            if(window._currentPlayingYoutubeMedia == this){
                window._currentPlayingYoutubeMedia = null;
            }
            if(this.videoEl.parentNode)
            {
                this.videoEl.parentNode.removeChild(this.videoEl);
            }
            this._isReleased = true;
            
        },


        seekTo: function(position){
            if(!this._isReleased){
                if(position){
                    this.videoEl.currentTime = position?Math.round(position/1000): position ;
                }
            }
        },

        startRecord: function(){
            window.alertErrorMessage(window.getI18n(ti18n, "NOT_SUPPORTED_YET"));
        },

        stopRecord: function(){
                window.alertErrorMessage(window.getI18n(ti18n, "NOT_SUPPORTED_YET"));
        },

        getDuration: function(){
            if(!this._isReleased){
                return this.videoEl.duration*1000;// in milliseconds
            }else{
                return 0;
            }
        },

        getCurrentPosition: function(onSuccess, onFailure){
            if(!this._isReleased){
                try{
                    onSuccess(this.videoEl.currentTime);
                }catch(err){
                    onFailure(err);
                }
            }else{
                onFailure(window.getI18n(ti18n, "COULDNT_FIND_THE_VIDEO_CURRENT_TIME_PROGRES"));
            }
        },

        _onVideoFinishedPlaying : function(){
            /*if(this.onStatusHandler){
                this.onStatusHandler(mediaStatuses.MEDIA_PAUSED);
            }*/
            this._exitFullscreen();
            this.videoEl.src = null;
            this.videoEl.style.display = "none";
            this.videoEl.load();
            if(this.onSuccessHandler){
                this.onSuccessHandler();
            }
            window.log("Video finished playing");
        },
        
        _onVideoReadyToPlay : function(){
        
            window.log("Video is ready to play");
            this._loading = false;
            this.trigger("loaded");
        },
        
        _onDurationchange : function(){
        
            window.log("Video duration changed. New duration: " + this.videoEl.duration);
            this.trigger("durationChange",this.videoEl.duration*1000);
        },
        
        _onVideoStalled : function(){
            window.log("Video stalled. ");
            this.trigger("videoStalled");
        },
        
        _onDownloadingVideoFrames : function(){
            window.log("Video is downloading frames. ");
            let percent = null;

            if (this.videoEl.buffered.length > 0 && this.videoEl.buffered.end && this.videoEl.duration) {
                percent = this.videoEl.buffered.end(0) / this.videoEl.duration;
            } else if (this.videoEl.bytesTotal != undefined && this.videoEl.bytesTotal > 0 && this.videoEl.bufferedBytes != undefined) {
                percent = this.videoEl.bufferedBytes / this.videoEl.bytesTotal;
            }

            if (percent !== null) {
                percent = 100 * Math.min(1, Math.max(0, percent));

                window.log("video progress "+percent);
            }
            this.trigger("downloading",percent);
        },
        
        _onLoadedmetadata : function(){
            window.log("Loaded Video Meta data. ");
            this.trigger("loadedMetaData");
        },
        
        _isVideoFullScreen : function(){
            const fullscreenElement = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement;
            return fullscreenElement == this.videoEl;
        },
        
        _showVideoFullScreen : function(){
            this._launchFullscreen(this.videoEl);
        },
        
        _launchFullscreen : function (element) {
              if(element.requestFullscreen) {
                element.requestFullscreen();
              } else if(element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
              } else if(element.webkitRequestFullscreen) {
                element.webkitRequestFullscreen();
              } else if(element.msRequestFullscreen) {
                element.msRequestFullscreen();
              }
            },
            
        _exitFullscreen: function () {
                  if(document.exitFullscreen) {
                    document.exitFullscreen();
                  } else if(document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                  } else if(document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                  }
                },

        _onVideoPlaying : function(){
            if(this.onStatusHandler){
                this.onStatusHandler(mediaStatuses.MEDIA_RUNNING, this);
            }
            window.log("Video playing");
        },
   
        _onVideoStop : function(){
            if(this.onStatusHandler){
                this.onStatusHandler(mediaStatuses.MEDIA_STOPPED, this);
            }
            window.log('Video Stopped');
        },

        _onVideoPaused : function() {
            if(this.onStatusHandler){
                this.onStatusHandler(mediaStatuses.MEDIA_PAUSED, this);
            }
            window.log('Video Paused');
        },


});
export default YoutubeMedia;