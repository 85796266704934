import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import OptionPopupView from "views/common/OptionPopupView";
import ti18n from "i18n!nls/StartCategoryShortcastRadioi18n";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import StartCategoryShortcastRadioPopupContentTemplate from 'text!../../../templates/startCategoryShortcastRadio/StartCategoryShortcastRadioPopupContentTemplate.html';

const Rollout = RolloutHelper.getInstance();
const StartCategoryShortcastRadioPopupView = OptionPopupView.extend({

    "pageName": "Start Category Shortcast Radio",

    initialize: function(options) {
        const viewOptions = {};

        const contentView = {};
        const contentViewTemplate = _.template(StartCategoryShortcastRadioPopupContentTemplate);
        let templateParams = options;
        if(!templateParams){
            templateParams= {};
        }
        templateParams.ti18n =  ti18n;
        templateParams.Rollout = Rollout;
        contentView.$el = $(contentViewTemplate(templateParams));
        viewOptions.contentView = contentView;
        viewOptions.contentPosition = "center";
        this._categoryListItemCollectionToDisplay = null;

        viewOptions.id = "StartCategoryShortcastRadioPopup";


        OptionPopupView.prototype.initialize.call(this, viewOptions);
    },

    setCategoriesListItemCollection : function(categoryListItemCollectionToDisplay){
        this._categoryListItemCollectionToDisplay = categoryListItemCollectionToDisplay;
        if(this._categoryListItemCollectionToDisplay ){
            this._categoryListItemCollectionToDisplay.each((function(categoryItemModel, index){
                this.categoryWrapper$el.append(this._getHtmlForCategory(categoryItemModel, index));

            }).bind(this))
        }
    },

    _getHtmlForCategory: function(categoryItemModel, index){
        const categor$el = $("<div class='categoryItem selectionListItem' ><div class='itemLabel'>"+categoryItemModel.getLabel()+"</div></div>");
        categor$el.on("click", (function(){
            this.trigger("startCategoryShortcastRadio", categoryItemModel.getId());
        }).bind(this));
        return categor$el;
    },

    
    isLoading:function(isLoading){
        this.$el.find(".start_category_shortcast_radio_content_wrapper").attr("isLoading",isLoading)
    },

    onCategorySelected : function(){

    },

    afterRendering: function() {
        this.categoryWrapper$el = this.$el.find(".category_list_wrapper");
    },


    onPopupClosed: function() {

    },

    onAfterPageShow: function() {

    },



});

export default StartCategoryShortcastRadioPopupView;
