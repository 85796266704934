import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Comment from "models/Comment";

const Comments =  Backbone.Collection.extend({
    model: Comment,


    initialize: function (){
        this._sort_key ="creationDate";

    },

    
     comparator: function(item) {
            return item.get(this.sort_key);
      },
      
      sortByField: function(fieldName) {
            this._sort_key = fieldName? fieldName : this._sort_key;
            this.sort();
      },

    
    getContext : function(){
        return this._context;
    },
    
    updateContext : function(newContext){
        this._context = newContext
    },
    

});
export default Comments;