import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";

const StaticContentView = Backbone.View.extend({
    


});

export default StaticContentView;
