import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerNode from "models/audioEditor/sequencerNodes/SequencerNode";
import RSVP from "rsvp";

const CLASSNAME =  "PauseSequencerNode";
const PauseSequencerNode =  SequencerNode.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerNode.apply(this, [attributes, options]);

        
    },


    getConstructor : function(){
        return PauseSequencerNode;
    },

    
    afterInstanceCreatedFromConstructorForCloning : function(createdClonedInstance, sequencerNodeCidToCloneNodeMap){
        
    },

    restoreFromSerializedData: function(serializedData, audioSegmentFactoryInstance, audioFilterFactoryInstance, additionalSpeakerInfoArray){
        return SequencerNode.prototype.restoreFromSerializedData.call(this, serializedData, audioSegmentFactoryInstance, audioFilterFactoryInstance, additionalSpeakerInfoArray);
    },

    afterAllInstancesCreatedFromSerializedData : function(serializedData, sequencerNodeCidToSequencerNodeMap){
        return SequencerNode.prototype.afterAllInstancesCreatedFromSerializedData.call(this, serializedData, sequencerNodeCidToSequencerNodeMap);
    },

    cloneJSON: function(){
        const objJson = SequencerNode.prototype.cloneJSON.call(this);
        objJson.type = PauseSequencerNode.CLASSNAME;
        return objJson;
    },

    getSerializedData : function(){
        const serializedData = SequencerNode.prototype.getSerializedData.call(this);
        return serializedData;
    },

    needAutoCrossfadeIfNeighborNodesAreDifferentFromOriginal : function(){
        return true;
    },

    canLetFadeIfNeighborNodesAreDifferentFromOriginal : function(){
        return this.getAudioSegment() && this.getAudioSegment().getDuration() > 0;
    },

    getApplyCrossfadeAtTheBegining : function(){
        const applyCrossfadeAtTheBeginingValue = SequencerNode.prototype.getApplyCrossfadeAtTheBegining.apply(this);
        if(applyCrossfadeAtTheBeginingValue == true || applyCrossfadeAtTheBeginingValue == false){
            return applyCrossfadeAtTheBeginingValue;
        }else{
            return this.getSequencer().getSequencerSettings().getAutoCrossfadingBetweenWordsClosesTogetherInReferenceAudio();
        }
    },

    getApplyCrossfadeAtTheEnd : function(){
        const applyCrossfadeAtTheEnd = SequencerNode.prototype.getApplyCrossfadeAtTheEnd.apply(this);
        if(applyCrossfadeAtTheEnd == true || applyCrossfadeAtTheEnd == false){
            return applyCrossfadeAtTheEnd;
        }else{
            return this.getSequencer().getSequencerSettings().getAutoCrossfadingBetweenWordsClosesTogetherInReferenceAudio();
        }
    },


    getApplyCrossfadeAtTheBeginingDurationInMilliseconds : function(){
        const applyCrossfadeAtTheBeginingDurationInMilliseconds = SequencerNode.prototype.getApplyCrossfadeAtTheBeginingDurationInMilliseconds.apply(this);
        if(applyCrossfadeAtTheBeginingDurationInMilliseconds > 0){
            return applyCrossfadeAtTheBeginingDurationInMilliseconds;
        }else{
            return this.getSequencer().getSequencerSettings().getAutoCrossfadingBetweenWordsClosesTogetherInReferenceAudioDurationInMilliseconds();
        }
    },

    getApplyCrossfadeAtTheEndDurationInMilliseconds : function(){
        const applyCrossfadeAtTheEndDurationInMilliseconds = SequencerNode.prototype.getApplyCrossfadeAtTheEndDurationInMilliseconds.apply(this);
        if(applyCrossfadeAtTheEndDurationInMilliseconds > 0){
            return applyCrossfadeAtTheEndDurationInMilliseconds;
        }else{
            return this.getSequencer().getSequencerSettings().getAutoCrossfadingBetweenWordsClosesTogetherInReferenceAudioDurationInMilliseconds();
        }
    },

    



});

PauseSequencerNode.CLASSNAME = CLASSNAME;
export default PauseSequencerNode; 