import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import SetupVoiceEnabledDevicePopupView from "views/setupVoiceEnabledDevices/SetupVoiceEnabledDevicePopupView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";
import ti18n from 'i18n!nls/MySubscriptionsPagei18n';

const SetupVoiceEnabledDevicePopupController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
    },


    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    previewLink: function(linkToPreview){
        const openedWindow = window.open(linkToPreview , "_system");
        if((!openedWindow || openedWindow.closed || typeof openedWindow.closed=='undefined') && !window.waitForCordovaToLoad){
            window.alertErrorMessage(window.getI18n(ti18n,"PLEASE_ALLOW_POPUPS"));
        }
        return openedWindow;
    },

    addShortcutToSiri: function(){
        if(window.SiriIntentHelper && window.SiriIntentHelper.inviteUserToAddAShortcutToPlayCapsuleFeed){
            return new RSVP.Promise((function(resolve, reject){
                window.SiriIntentHelper.inviteUserToAddAShortcutToPlayCapsuleFeed(LocalStorageHelper.getInstance().getUserInfo()._id,resolve,reject);
                if(window.trebbleAnalyticsHelper){
                    window.trebbleAnalyticsHelper.trackEvent("SiriShortcuts", 'InviteUserToAddShortcutToPlayCapsuleFeed', 'Invite User To Add A Shortcut To Play Capsule Feed');
                }
            }).bind(this)).then((function(results){
                let phraseChosenByUser;
                switch (parseInt(results.code)) {
                    case 1:
                    //shortcut created
                    phraseChosenByUser = results.phrase;
                    if(phraseChosenByUser){
                        LocalStorageHelper.getInstance().setShortcutToPlayCapsuleFeedWasAddedToSiri(true);
                        LocalStorageHelper.getInstance().setShortcutPhraseToPlayCapsuleFeedWasAddedToSiri(phraseChosenByUser);
                        this.view.setSiriShortcutWasSuccessfullyChanged();
                    }
                    if(window.trebbleAnalyticsHelper){
                        window.trebbleAnalyticsHelper.trackEvent("SiriShortcuts", 'ShortcutToPlayCapsuleFeedCreated', 'Shortcut to play capsule feed was created');
                    }
                    break;
                    case 2:
                    //shortcut modified
                    phraseChosenByUser = results.phrase;
                    if(phraseChosenByUser){
                        LocalStorageHelper.getInstance().setShortcutToPlayCapsuleFeedWasAddedToSiri(true);
                        LocalStorageHelper.getInstance().setShortcutPhraseToPlayCapsuleFeedWasAddedToSiri(phraseChosenByUser);
                        this.view.setSiriShortcutWasSuccessfullyChanged();

                    }
                    if(window.trebbleAnalyticsHelper){
                        window.trebbleAnalyticsHelper.trackEvent("SiriShortcuts", 'ShortcutToPlayCapsuleFeedModified', 'Shortcut to play capsule feed was modified');
                    }
                    break;
                    case 3:
                    //shortcut deleted
                    LocalStorageHelper.getInstance().setShortcutToPlayCapsuleFeedWasAddedToSiri(false);
                    LocalStorageHelper.getInstance().setShortcutPhraseToPlayCapsuleFeedWasAddedToSiri(null);
                    this.view.showSiriOptionIfAvailable();
                    if(window.trebbleAnalyticsHelper){
                        window.trebbleAnalyticsHelper.trackEvent("SiriShortcuts", 'ShortcutToPlayCapsuleFeedDeleted', 'Shortcut to play capsule feed was deleted');
                    }
                    break;
                    case 4:
                    //shortcut dismissed
                    break;
                    case 5:
                    //internal error
                    break;
                    case 6:
                    //error.Migth not run iOS 12
                    window.alertErrorMessage(window.getI18n(ti18n, "OOPS_REQUIRE_VERSION_X_OF_IOS"));
                    if(window.trebbleAnalyticsHelper){
                        window.trebbleAnalyticsHelper.trackEvent("SiriShortcuts", 'ShortcutToPlayCapsuleFeedError', 'Shortcut was not available on iOS');
                    }
                    break;
                    default:
                    //Do nothing
                    break;
                }
            }).bind(this)).catch((function(error){
                window.alertErrorMessage(window.getI18n(ti18n, "OOPS_SOMETHING_WENT_WRONG_WHEN_SETTING_UP_A_SHORTCUT"));
            }).bind(this))
        }
    },


    onPopupClosed : function(){

    },

    openTrebbleSkillPage : function(){
        this.previewLink("https://www.amazon.com/gp/product/B075XDJTS7")
    },

    openTrebbleGoogleActionPage : function(){
        this.previewLink("https://assistant.google.com/services/a/id/36842f160acca147")
    },

    openBixbyMarketplacePage : function(){
        this.previewLink("bixbyvoice://com.samsung.android.bixby.agent/GoToFeature?featureName=DiscoveryPanel")
    },
    
    tryPlayFeedOnGoogleAssistantPage  : function(){
        this.previewLink("https://assistant.google.com/services/invoke/uid/0000002185327965?intent=Play%20my%20capsules%20feed");
    },


    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {

                const params = {};
                this.view = new SetupVoiceEnabledDevicePopupView(params);

                this.listenTo(this.view, "closed",this.onPopupClosed);
                this.listenTo(this.view, "addShortcutToSiri",this.addShortcutToSiri);
                this.listenTo(this.view, "modifySiriPhrase",this.addShortcutToSiri);
                this.listenTo(this.view, "openTrebbleSkillPage",this.openTrebbleSkillPage);
                this.listenTo(this.view, "openTrebbleGoogleActionPage",this.openTrebbleGoogleActionPage);
                this.listenTo(this.view, "openTrebbleBixbyCapsule",this.openBixbyMarketplacePage);
                this.listenTo(this.view, "tryPlayFeedOnGoogleAssistantPage",this.tryPlayFeedOnGoogleAssistantPage);
                
                this.view.render();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default SetupVoiceEnabledDevicePopupController;