

import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import ChooseMusicSoundsAndUploadsWidgetController from "controllers/chooseMusicSoundsAndUploads/ChooseMusicSoundsAndUploadsWidgetController";
import ChooseMusicSoundsAndUploadsPopupView from "views/chooseMusicSoundsAndUploads/ChooseMusicSoundsAndUploadsPopupView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import PlaylistHelper from "models/helper/PlaylistHelper";
import TrebbleAudio from "models/TrebbleAudio";
import FileUploadHelper from "models/helper/FileUploadHelper";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import TrebbleBackgroundMusicStoreHelper from "models/helper/TrebbleBackgroundMusicStoreHelper";
import ti18n from "i18n!nls/ConfigureBackgroundMusici18n";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const Rollout = RolloutHelper.getInstance();

//const CONFIGURE_BACKGROUND_MUSIC = Rollout.isFeatureEnabled(Rollout.FEATURES.CONFIGURE_BACKGROUND_MUSIC);

const ChooseMusicSoundsAndUploadsPopupController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this._selectedTrebbleAudio = null;
        this._onTrebbleAudioSelected = options.onTrebbleAudioSelected;
        this._onTrebbleAudioUnselected = options.onTrebbleAudioUnselected;
        this._numberOfResultsPerPageForSounds  = options.numberOfResultsPerPageForSounds;
        this._numberOfResultsPerPageForBigBankSounds  = options.numberOfResultsPerPageForBigBankSounds;
        this._getTrebbleAudioSoundLoadHandler = options.getTrebbleAudioSoundLoadHandler;
        this._getTrebbleAudioBigBankSoundLoadHandler = options.getTrebbleAudioBigBankSoundLoadHandler;
        this._hideMusicTab = options.hideMusicTab;
        this._moveBigBankSoundsTabFirst = options.moveBigBankSoundsTabFirst;

        const defaultsMusicLoadFunctions = this.getDefaultsMusicLoadFunctions();
        this._getTrebbleAudioModelMusicArrayByCategoryFunction = options.getTrebbleAudioModelMusicArrayByCategoryFunction? options.getTrebbleAudioModelMusicArrayByCategoryFunction: TrebbleBackgroundMusicStoreHelper.getInstance().getTrebbleAudioArrayForCategoruId.bind(TrebbleBackgroundMusicStoreHelper.getInstance());//defaultsMusicLoadFunctions.defaultGetTrebbleAudioModelMusicArrayByCategoryFunction;
        this._getMusicCategoryMapFunction = options.getMusicCategoriesFunction? options.getMusicCategoriesFunction: TrebbleBackgroundMusicStoreHelper.getInstance().getCategoryIdToCategoryLabel.bind(TrebbleBackgroundMusicStoreHelper.getInstance());//defaultsMusicLoadFunctions.defaultGetMusicCategoriesFunction;
        this._musicWidgetHeightMargin  = options.musicWidgetHeightMargin? options.musicWidgetHeightMargin: 0;
    },


    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    onPopupClosed : function(){
        if(this._onTrebbleAudioSelected){
            this._onTrebbleAudioSelected(this.chooseMusicSoundsAndUploadsWidgetController ? this.chooseMusicSoundsAndUploadsWidgetController.getSelectedTrebbleAudio(): null);
        }
        PersistentModels.getInstance().getPlayer().releaseCurrentAudioPreviewIfApplicable();
    },

    getDefaultsMusicLoadFunctions : function(){
        const categoryToBackgroundMusicTrebbleAudioMap = {};
        const categoryIdArray = [];
        const  p = TrebbleClientAPIHelper.getInstance().getAvailableDefaultBackgroundMusic().then((function(trebbleBackgroundMusicTrebbleAudioArray){
            
            if(trebbleBackgroundMusicTrebbleAudioArray && trebbleBackgroundMusicTrebbleAudioArray.length){
                for(let i =0; i < trebbleBackgroundMusicTrebbleAudioArray.length; i++){
                    const trebbleAudioJSON = trebbleBackgroundMusicTrebbleAudioArray[i];
                    const trebbleAudioModel =  new TrebbleAudio(trebbleAudioJSON);
                    const categoryId =  trebbleAudioModel.getCategoryId();
                    categoryIdArray.push(categoryId);
                    if(!categoryToBackgroundMusicTrebbleAudioMap[categoryId]){
                        categoryToBackgroundMusicTrebbleAudioMap[categoryId] = [];
                    }
                    categoryToBackgroundMusicTrebbleAudioMap[categoryId].push(trebbleAudioModel);

                }
            }
            for( let categoryId in categoryToBackgroundMusicTrebbleAudioMap){
                Utils.getInstance().sortTrebbleAudioModelArray(categoryToBackgroundMusicTrebbleAudioMap[categoryId]);
            }
        }).bind(this));
        const defaultGetMusicCategoriesFunction = function(){
            return p.then(function(){
                return categoryIdArray;
            })
        }

        const defaultGetTrebbleAudioModelMusicArrayByCategoryFunction = function(categoryId){
            return p.then(function(){
                return categoryToBackgroundMusicTrebbleAudioMap[categoryId];
            })
        }
        return {"defaultGetMusicCategoriesFunction": defaultGetMusicCategoriesFunction, "defaultGetTrebbleAudioModelMusicArrayByCategoryFunction": defaultGetTrebbleAudioModelMusicArrayByCategoryFunction};
        
    },

    onTrebbleAudioSelected : function(){
        this.view.close();
    },

    _buildMusicSoundAndUploadsWidgetController : function(){
        const params  = {};
        params.onTrebbleAudioSelected = this.onTrebbleAudioSelected.bind(this);
        params.onTrebbleAudioUnselected = this._onTrebbleAudioUnselected;
        params.numberOfResultsPerPageForSounds = this._numberOfResultsPerPageForSounds;
        params.numberOfResultsPerPageForBigBankSounds = this._numberOfResultsPerPageForBigBankSounds;
        params.getTrebbleAudioSoundLoadHandler = this._getTrebbleAudioSoundLoadHandler;
        params.getTrebbleAudioBigBankSoundLoadHandler = this._getTrebbleAudioBigBankSoundLoadHandler;
        params.getTrebbleAudioModelMusicArrayByCategoryFunction = this._getTrebbleAudioModelMusicArrayByCategoryFunction;
        params.getMusicCategoriesMapFunction = this._getMusicCategoryMapFunction;
        params.musicWidgetHeightMargin = this._musicWidgetHeightMargin;
        params.hideMusicTab = this._hideMusicTab;
        params.moveBigBankSoundsTabFirst = this._moveBigBankSoundsTabFirst;
        
        const chooseMusicSoundsAndUploadsWidgetController = new ChooseMusicSoundsAndUploadsWidgetController(params);
        this.chooseMusicSoundsAndUploadsWidgetController = chooseMusicSoundsAndUploadsWidgetController;
        return this.chooseMusicSoundsAndUploadsWidgetController.buildModelAndRenderView();
    },

    
    buildModelAndRenderView : function() {
        const promises = [];
        promises.push(this._buildMusicSoundAndUploadsWidgetController());
        
        return RSVP.Promise.all(promises).then((function(){
            const params = {};
            params.musicSoundAndUploadsWidgetView = this.chooseMusicSoundsAndUploadsWidgetController.getView();

            this.view = new ChooseMusicSoundsAndUploadsPopupView(params);
            this.listenTo(this.view, "closed", this.onPopupClosed);

            this.view.render();

        }).bind(this))


    }

});

export default ChooseMusicSoundsAndUploadsPopupController;