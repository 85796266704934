
import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import ReplaceSelectedNodesOperation from "models/audioEditor/sequencerOperation/ReplaceSelectedNodesOperation";
import StartWrapSequencerNode from "models/audioEditor/sequencerNodes/StartWrapSequencerNode";
import EndWrapSequencerNode from "models/audioEditor/sequencerNodes/EndWrapSequencerNode";
import DeletedSequencerNode from "models/audioEditor/sequencerNodes/DeletedSequencerNode";
import SequencerOperationUtils from "models/audioEditor/sequencerOperation/SequencerOperationUtils";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

function InterruptedBecauseOfWrapNodeOrphanError(message = "") {
    this.name = "InterruptedBecauseOfWrapNodeOrphanError";
    this.message = message;
}
InterruptedBecauseOfWrapNodeOrphanError.prototype = Error.prototype;


function SelectedNodeNeedToBeNextToEachotherError(message = "") {
    this.name = "SelectedNodeNeedToBeNextToEachotherError";
    this.message = message;
}
SelectedNodeNeedToBeNextToEachotherError.prototype = Error.prototype;

const DeleteSelectedNodesOperation =  ReplaceSelectedNodesOperation.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        ReplaceSelectedNodesOperation.apply(this, [attributes, options]);
    },

    execute : function(startSequencerNodeToDelete, endSequencerNodeToDelete){
        if(!Utils.getInstance().doesSelectedNodesContainOrphanWrapNode(startSequencerNodeToDelete, endSequencerNodeToDelete)){
            const nodeArrayToReplace = SequencerOperationUtils.getInstance().getNodeArray(startSequencerNodeToDelete, endSequencerNodeToDelete);
            if(SequencerOperationUtils.getInstance().areNodeToReplaceConnectedToEachother(nodeArrayToReplace)){
                const deleteNode = new DeletedSequencerNode({"audioSegment": Utils.getInstance().getDeletedAudioSegment(), "sequencer": this.getSequencer()});
                this.replaceSequencerNodes(deleteNode, nodeArrayToReplace);
                this.getSequencer().getHistoryManagement().addCreateNodeDeletedLog(nodeArrayToReplace, deleteNode);
                return deleteNode;
            }else{
                throw new SelectedNodeNeedToBeNextToEachotherError();
            }
        }else{
            throw new InterruptedBecauseOfWrapNodeOrphanError();
        }
    },




    undo : function(sequencerOperationDeleteNodesLog ){
        const deleteNode = sequencerOperationDeleteNodesLog.getDeleteNode();
        this.revertReplacement(deleteNode);
    },

    redo: function(sequencerOperationDeleteNodesLog){
        this.replaceSequencerNodes(sequencerOperationDeleteNodesLog.getDeleteNode(), sequencerOperationDeleteNodesLog.getNodeArrayToDelete());
    },

});

DeleteSelectedNodesOperation.InterruptedBecauseOfWrapNodeOrphanError = InterruptedBecauseOfWrapNodeOrphanError;
DeleteSelectedNodesOperation.SelectedNodeNeedToBeNextToEachotherError = SelectedNodeNeedToBeNextToEachotherError;
export default DeleteSelectedNodesOperation; 