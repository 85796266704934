import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utils from "models/helper/Utils";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import ti18n from "i18n!nls/EditUserInfoi18n";
import EditUserInfoPageView from "views/user/EditUserInfoPageView";


const EditUserInfoPageController =  Chaplin.Controller.extend({


    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = null;
        this._pageId = options.pageId;
        this._onUserPorfileChanged =  options.onUserPorfileChanged;
        
    },
    
    getModel : function(){
        return this.model;
    },
    
    getView : function(){
        return this.view;
    },

    validatUsername : function(username){
        let errorMessage =  null;
        if(!username){
            errorMessage  = window.getI18n(ti18n, "USERNAME_CANNOT_BE_EMPTY");
        }else{
            if(username && username.length > Utils.getInstance().getMaxNumberOfCharForUsername() && username.length < Utils.getInstance().getMinNumberOfCharForUsername()){
                errorMessage = window.getI18n(ti18n, "USERNAME_VALIDATION_ERROR");
            }
            if(!username.match(Utils.getInstance().getUsernameRegexValidation())){
                errorMessage = window.getI18n(ti18n, "USERNAME_CAN_ONLY_CONTAIN_CHARACTERS");
            }
        }
        const isValid = !errorMessage;
        this.view.setUsernameValid(isValid, errorMessage);
        if(isValid){
            return TrebbleClientAPIHelper.getInstance().isUserWithSameUsername(username,this.model.getUserId()).then((function(alreadyExists){
                if(alreadyExists){
                    this.view.setUsernameValid(false, window.getI18n(ti18n, "USERNAME_ALREADY_TAKEN"));
                }
                return !alreadyExists;
            }).bind(this));
        }else{
            return RSVP.Promise.resolve(isValid);
        }
        return isValid;
    },

    validatEmail : function(emailValue){
        const re = Utils.getInstance().getEmailRegexValidation();
        let isValid = re.test(emailValue);
        let errorMessage ;
        if(!re.test(emailValue)) {
            errorMessage = window.getI18n(ti18n, "INVALID_EMAIL");
            
        } 
        isValid = !errorMessage;
        this.view.setEmailValid(isValid, errorMessage);
        if(isValid){
            return TrebbleClientAPIHelper.getInstance().isUserWithSameEmail(emailValue,this.model.getUserId()).then((function(alreadyExists){
                if(alreadyExists){
                    this.view.setEmailValid(false, window.getI18n(ti18n, "EMAIL_ALREADY_TAKEN"));
                }
                return !alreadyExists;
            }).bind(this));
        }else{
            return RSVP.Promise.resolve(isValid);
        }
        return isValid;
    },

    
    saveChanges : function(dataToChange){
        
        $.mobile.loading("show");
        
        return TrebbleClientAPIHelper.getInstance().updateLoggedInUserProfile(dataToChange).then((function(){
            const userInfoJson= LocalStorageHelper.getInstance().getUserInfo();
            return TrebbleClientAPIHelper.getInstance().getUserProfileInfoByUsername(dataToChange.username);
        }).bind(this)).then((function(updatedUserInfo){
            LocalStorageHelper.getInstance().updateUserInfo(updatedUserInfo);
            this._onUserPorfileChanged? this._onUserPorfileChanged(updatedUserInfo): null;
            this.view.navigateToPreviousPage();
            window.alertSuccessMessage(window.getI18n(ti18n, "YOUR_PROFILE_WAS_UPDATED"));
            $.mobile.loading("hide");
        }).bind(this)).catch((function(error){
            $.mobile.loading("hide");
            this.view.setSaveButtonEnabled(true);
            const errorMessage = error? error:window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHEN_SAVING_YOUR_CHANGES");
            window.alertErrorMessage(errorMessage);
        }).bind(this));
        
        
    },

    validateForm : function(){
        const formData = this.view.getFormData();
        this.view.setSaveButtonEnabled(false);
        this.view.setFormBeingValidated(true);
        const promiseHash = {};

        promiseHash["validatUsername"] = this.validatUsername(formData.username);
        promiseHash["validateFullNameField"] = RSVP.Promise.resolve(this.view.validateFullNameField());
        promiseHash["validateBioField"] = RSVP.Promise.resolve(this.view.validateBioField());
        promiseHash["validateWebsiteField"] = RSVP.Promise.resolve(this.view.validateWebsiteField());
        promiseHash["validatEmail"] = this.validatEmail(formData.email);
        return RSVP.hash(promiseHash).then((function(results){
            const formValidation = results.validatUsername &&  results.validateFullNameField && results.validateBioField && results.validateWebsiteField  && results.validatEmail;
            this.view.setFormBeingValidated(false);
            this.view.setSaveButtonEnabled(formValidation);
            return formValidation;
        }).bind(this)).catch(function(error){
            this.view.setFormBeingValidated(false);
            this.view.setSaveButtonEnabled(false);
            window.alertErrorMessage(error? error:window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHEN_SAVING_YOUR_CHANGES"));
        });


    },
    

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                
                const pageViewParams= {};
                pageViewParams.pageId = this._pageId;
                pageViewParams.userInfoJson = LocalStorageHelper.getInstance().getUserInfo();
                this.model = Utils.getInstance().getModelFromUserJson(pageViewParams.userInfoJson);
                this.view = new EditUserInfoPageView(pageViewParams);
                this.view.render();
                this.listenTo(this.view, "saveChanges", this.saveChanges);
                this.listenTo(this.view, "validateForm", this.validateForm);
                resolve(this);
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }
    

});
export default EditUserInfoPageController;