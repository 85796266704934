import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import RSVP from "rsvp";

const CustomNotificationPayload =  Backbone.Model.extend({
     idAttribute: "notificationId",

     getNotificationId : function(){
         return this.get("notificationId");
     },

     
     getNotificationType : function(){
         return this.get("notificationType");
     },
     getNotificationDisplayIconClass : function(){
         return this.get("notificationDisplayIconClass");
     },
     
     getNotificationTilte : function(){
         return this.get("notificationTilte");
     },
     
     getNotificationMessage : function(){
         return this.get("notificationMessage");
     },

     isConsumed : function(){
        return this.get("consumed") == true || this.get("consumed") === "true";
     },
     
     
     getNotificationDetailMessage : function(){
         return this.get("notificationDetailMessage");
     },
     
     getTimestamp : function(){
         return this.get("timestamp");
     },
     
     getNotificationData : function(){
         return this.get("notificationData");
     },
     
     hasNotificationData : function(){
         return !!this.getNotificationData();
     },
     
    
    
});
export default CustomNotificationPayload;