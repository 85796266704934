import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import OptionPopupView from "views/common/OptionPopupView";
import popupTemplate from 'text!../../../templates/audioEditor/SequencerNodeMoreOptionContent.html';
import ti18n from "i18n!nls/Sequenceri18n";

const SequencerNodeMoreOptionView = OptionPopupView.extend({

    initialize : function(options) {
        const viewOptions =  {};
        this.model = options.model;
        this.elementSource$el = options.elementSource$el;
       
        viewOptions.contentView = options.contentView;
        
        viewOptions.contentPosition = "center";
        viewOptions.elementSource$el = this.elementSource$el;
        viewOptions.showAsMenuIfInBrowser = true;
        viewOptions.alwaysShowAsFloatingMenu = true;
        viewOptions.customPopupPosition = options.popupPosition? options.popupPosition: "right middle";
        viewOptions.id = "SequencerNodeMoreOptionPopup";
        viewOptions.customDropObjClass = "sequencerNodeMoreOptionPopupDrop";
        viewOptions.hideHeading = true;
        OptionPopupView.prototype.initialize.call(this, viewOptions);
    },

    afterRendering : function(){
        
        
    },


    onAfterPageShow : function(){
        this.$el.addClass("SequencerNodeMoreOptionPopup");
    },



});

export default SequencerNodeMoreOptionView;