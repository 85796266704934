import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerNode from "models/audioEditor/sequencerNodes/SequencerNode";
import StartWrapSequencerNode from "models/audioEditor/sequencerNodes/StartWrapSequencerNode";
import EndWrapSequencerNode from "models/audioEditor/sequencerNodes/EndWrapSequencerNode";
import SequencerOperationUtils from "models/audioEditor/sequencerOperation/SequencerOperationUtils";
import SequencerNodeCloner from "models/audioEditor/sequencerNodes/SequencerNodeCloner";
import RSVP from "rsvp";

const CLASSNAME = "ReplacementSequencerNode";
const ReplacementSequencerNode =  SequencerNode.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerNode.apply(this, [attributes, options]);
        this._arrayOfNodeReplaced = [];
    },

    getConstructor : function(){
        return ReplacementSequencerNode;
    },



    afterInstanceCreatedFromConstructorForCloning : function(createdClonedInstance, sequencerNodeCidToCloneNodeMap){
        const cloneReplacedNodes = this._arrayOfNodeReplaced.map(function(nodeToClone){
            return SequencerNodeCloner.getInstance().cloneLinkedSequencerNodes(nodeToClone,null, sequencerNodeCidToCloneNodeMap);
        });
        createdClonedInstance._arrayOfNodeReplaced = cloneReplacedNodes;
    },

    cloneJSON: function(){
        const objJson = SequencerNode.prototype.cloneJSON.call(this);
        objJson.type = ReplacementSequencerNode.CLASSNAME;
        return objJson;
    },

    getSerializedData : function(){
        const serializedData = SequencerNode.prototype.getSerializedData.call(this);
        serializedData.arrayOfNodeCidReplaced  = [];
        for(let i =0; i < this._arrayOfNodeReplaced.length; i++ ){
            const nodeReplaced = this._arrayOfNodeReplaced[i];
            //serializedData.arrayOfNodeReplaced.push(nodeReplaced.getSerializedData());
            serializedData.arrayOfNodeCidReplaced.push(nodeReplaced.cid);
        }
        return serializedData;
    },

    restoreFromSerializedData: function(serializedData, audioSegmentFactoryInstance, audioFilterFactoryInstance, additionalSpeakerInfoArray){
        return SequencerNode.prototype.restoreFromSerializedData.call(this, serializedData, audioSegmentFactoryInstance, audioFilterFactoryInstance, additionalSpeakerInfoArray);
    },

    afterAllInstancesCreatedFromSerializedData : function(serializedData, sequencerNodeCidToSequencerNodeMap){
        SequencerNode.prototype.afterAllInstancesCreatedFromSerializedData.call(this, serializedData, sequencerNodeCidToSequencerNodeMap);
        if(serializedData.arrayOfNodeCidReplaced){
            this._arrayOfNodeReplaced =  [];
            serializedData.arrayOfNodeCidReplaced.map((function(nodeCid){
                const replacedNode = sequencerNodeCidToSequencerNodeMap[nodeCid];
                if(replacedNode){
                    this._arrayOfNodeReplaced.push(replacedNode);
                }else{
                    window.alertErrorMessage("Something went wrong during the initialization of this node")
                }
            }).bind(this))
        }
    },


    getArrayOfNodeReplaced: function(){
        return this._arrayOfNodeReplaced;
    },

    replaceNodes : function(arrayOfNodeToReplace){

        if(this.getArrayOfNodeReplaced() && this.getArrayOfNodeReplaced().length > 0){
            this.revertReplacement();
        }
        this._arrayOfNodeReplaced =  arrayOfNodeToReplace;

        const startNode = arrayOfNodeToReplace[0];
        const endNode =  arrayOfNodeToReplace[arrayOfNodeToReplace.length -1];

        const startNodeReplacedInitialPrevious =  startNode.getPrevious();
        const endNodeReplacedInitialNext = endNode.getNext();

        SequencerOperationUtils.getInstance().removeAudioNodeArray(this.getSequencer(), this._arrayOfNodeReplaced);
        
        if(startNodeReplacedInitialPrevious ){
            this.insertAfter(startNodeReplacedInitialPrevious);
        }else{
            if(endNodeReplacedInitialNext){
                this.insertBefore(endNodeReplacedInitialNext);
            }else{
                // first node and end node are null
            }
        }

        this.addToSequencer();
        if(startNode == this.getSequencer().getFirstNode()){
            this.getSequencer().setFirstNode(this);
        }
        if(endNode == this.getSequencer().getLastNode()){
            this.getSequencer().setLastNode(this);
        }
    },


    revertReplacement : function(){
        const previousNode = this.getPrevious();
        const nextNode = this.getNext();

        const startReplacedNode = this.getArrayOfNodeReplaced()[0];
        const endReplacedNode =  this.getArrayOfNodeReplaced()[this.getArrayOfNodeReplaced().length -1];

        SequencerOperationUtils.getInstance().removeAudioNodeArray(this.getSequencer(), [this]);
        this.setHidden(true);
        const sequencerNodeToInsertNextTo = previousNode?previousNode: nextNode;
        const insertBefore =previousNode?false: true;
        SequencerOperationUtils.getInstance().insertAudioNodeArray(this.getSequencer(), this.getArrayOfNodeReplaced(), sequencerNodeToInsertNextTo, insertBefore);
        this._arrayOfNodeReplaced = null;
        if(this == this.getSequencer().getFirstNode()){
            this.getSequencer().setFirstNode(startReplacedNode);
        }
        if(this == this.getSequencer().getLastNode()){
            this.getSequencer().setLastNode(endReplacedNode);
        }
    },

    getContent: function(addSpaceAroundContent){
        const content = this.getArrayOfNodeReplaced().reduce((acc, nodeReplaced)=>{
            if(addSpaceAroundContent){
                return acc +(nodeReplaced.getContent(addSpaceAroundContent)|| "");  
            }else{
                return acc + " " +(nodeReplaced.getContent(addSpaceAroundContent)|| "");    
            }
        }, "");
        /*if(addSpaceAroundContent){
            return ` ${content.trim()} `;
        }else{
            return content;
        }*/
        return content;
    },



    /*_moveAllWrapNodesThatHaveOtherNodeOutsideOfTheListOfNodeToWrap : function(){
        const startWrapNodeToMoveArray = [];
        const endWrapNodeToMoveArray = [];
        const nodeToReplace = this._startNodeReplaced
        while(nodeToReplace){
            if(nodeToReplace instanceof StartWrapSequencerNode){
                startWrapNodeToMoveArray.push(nodeToReplace);
            }else{
                if(nodeToReplace instanceof EndWrapSequencerNode){
                    const matchingStartNode = nodeToReplace.getStartWrapSequencerNode();
                    const indexOfMatchingStartNode = startWrapNodeToMoveArray.indexOf(matchingStartNode);
                    if(indexOfMatchingStartNode == -1){
                        endWrapNodeToMoveArray.push(nodeToReplace);
                    }else{
                        startWrapNodeToMoveArray.splice(indexOfMatchingStartNode, 1);
                    }
                }
            }
            if(nodeToReplace == this._endNodeReplaced){
                nodeToReplace = null;
            }else{
                nodeToReplace = nodeToReplace.getNext();
            }
        }
        const numberOfNodeToMove = startWrapNodeToMoveArray.length +endWrapNodeToMoveArray.length;
        for(let i = 0; i < startWrapNodeToMoveArray.length; i++){
            const startWrapNodeToMove = startWrapNodeToMoveArray[i];
            startWrapNodeToMove.shrinkWrapTo(this._endNodeReplaced.getNext());
        }
        for(let i = 0; i < endWrapNodeToMoveArray.length; i++){
            const endWrapNodeToMove = endWrapNodeToMoveArray[i];
            endWrapNodeToMove.shrinkWrapTo(this._startNodeReplaced.getPrevious());
        }
        return numberOfNodeToMove >0;
    },*/





});

ReplacementSequencerNode.CLASSNAME =  CLASSNAME;
export default ReplacementSequencerNode; 