import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerOperationLog from "models/audioEditor/sequencerOperationLog/SequencerOperationLog";
import RSVP from "rsvp";

const SequencerOperationDeleteDeleteNodesLog =  SequencerOperationLog.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperationLog.apply(this, [attributes, options]);
        
    },

    getOperationName : function(){
        return "Delete Delete Node";
    },

    setNodeArrayToDelete : function(nodeArrayToDelete){
        this.set("nodeArrayToDelete", nodeArrayToDelete);
    },

    getNodeArrayToDelete : function(){
        return this.get("nodeArrayToDelete");
    },

    setDeleteNode : function(deleteNode){
        this.set("deleteNode", deleteNode);
    },

    getDeleteNode : function(){
        return this.get("deleteNode");
    },




});

export default SequencerOperationDeleteDeleteNodesLog; 