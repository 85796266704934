	import $ from 'jquery';
    import _ from 'underscore';
    import Backbone from "backbone";
    import RSVP from "rsvp";

    const GOOGLE_MAP_API_KEY = "AIzaSyApPtRUyKKleykFf6Vg0mYdWfbUGgxPNDg";
    const GOOGLE_MAP_URL_ENDPOINT = "https://maps.googleapis.com/maps/api/place/autocomplete/json";
    const GoogleMapHelper = Backbone.Model.extend({
        "default" : {
            
        },

        initialize : function() {

        },

        searchForCities : function(keyword){

            
            return new RSVP.Promise((function(resolve, reject){ 

                const postData = {
                    key:GOOGLE_MAP_API_KEY, 
                    types:"(cities)",
                    input: keyword,
                    format:"json",
                    language: window.getAppUsedLanguage()

                };
                $.get(GOOGLE_MAP_URL_ENDPOINT,postData)
                .done(
                    function(data){

                        const citiesDataArray = [];
                        if(data && data.predictions && data.predictions.length > 0){

                            for(let i in data.predictions)
                            {
                                const cityInfo = data.predictions[i];
                                const cityData = {
                                    "place_id":cityInfo.place_id,
                                    "description": cityInfo.description,

                                };
                                citiesDataArray.push(cityData);
                            }
                            
                        }
                        resolve(citiesDataArray);
                    })
                
                .fail(function(dataError){
                    reject(dataError)
                });
            }).bind(this))

        },


    });

    const googleMapHelperInstance = new GoogleMapHelper();

    export default {
        getInstance : function() {
            return googleMapHelperInstance;
        }
    };