import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleSetupPageView from "views/trebbleSetup/TrebbleSetupPageView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PersistentModels from "services/PersistentModels";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import Utils from "models/helper/Utils";
import CustomSelectFieldController from "controllers/common/CustomSelectFieldController";
import ListItemCollection from "collections/ListItemCollection";
import ti18n from "i18n!nls/TrebbleSetupi18n";
import RSVP from "rsvp";

const TrebbleSetupController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = options.trebbleModel;
        this._hideBackButton = options? options.hideBackButton : false;				
        this.onActionExecutedOnTrebbleHandler = options? options.onActionExecutedOnTrebbleHandler: null;
        if(!this.onActionExecutedOnTrebbleHandler){
            this.onActionExecutedOnTrebbleHandler = (function(){});
        }
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    executeActionCallback :function(action, data){
        if(this.onActionExecutedOnTrebbleHandler){
            this.onActionExecutedOnTrebbleHandler(this.model,action, data);
        }
    },


    finishTrebbleSetup : function(data){
        

        $.mobile.loading("show");

        let name = data.trebbleName;
        const customURL = data.trebbleCustomUrl;
        const categoryId = data.trebbleCategoryId;
        const timezone = data.trebbleTimezone;
        const language = data.trebbleLanguage;
        const cityId =  data.trebbleCityId;
        const rssFeedUrlContentSource = null;
        const syncWithRssFeed = false;
        name = Utils.getInstance().capitalizeFirstLetter(name);
        const description = data.trebbleDescription;
        return TrebbleClientAPIHelper.getInstance().updateTrebbleInfoByTrebbleId(this.model.getLoaderKey(),
            name, description, customURL, categoryId,cityId, timezone, language,syncWithRssFeed, rssFeedUrlContentSource).then((function(){
                const editedData  = {"id": this.model.getLoaderKey(),"name": data.trebbleName, "description": data.trebbleDescription,"customURL": data.trebbleCustomUrl,"categoryId": data.trebbleCategoryId, "timezone":data.trebbleTimezone,"language":data.trebbleLanguage, "cityId": cityId};
                this.executeActionCallback("editTrebbleInfo", editedData);
                //Manually updating the profileType but it  should be updated in the database anyways
                //This is done here manually for avoiding to have to rerieve more data from the backend
                const currentUserInfo  = LocalStorageHelper.getInstance().getUserInfo();
                currentUserInfo.profileType = "creator";
                LocalStorageHelper.getInstance().updateUserInfo(currentUserInfo);
                PersistentModels.getInstance().onPersonalTrebbleCreatedOrEdited(editedData);
                $.mobile.loading("hide");
                if(window.trebbleAnalyticsHelper){
                    window.trebbleAnalyticsHelper.trackEvent("Onboarding", "TrebbleSetupCompleted", "Trebble Setup Completed");
                } 
                PersistentModels.getInstance().getRouter().setTrebbleWasSetupInCurrentSession(true);
                window.history.back();
                PersistentModels.getInstance().getRouter().showDefaultPageAfterSignup();

            }).bind(this)).catch((function(error){
                $.mobile.loading("hide");
                if(window.trebbleAnalyticsHelper){
                    window.trebbleAnalyticsHelper.trackEvent("Onboarding", "TrebbleSetupFailed", "Trebble Setup Failed");
                } 
                window.alertErrorMessage("Something went wrong went saving your changes. Error:"+ error);
            }).bind(this));

        },

        onViewRemovedFromDocument : function(){

        },


        validateTrebbleName : function(name){
            let errorMessage =  null;
            if(!name){
                errorMessage  = window.getI18n(ti18n, "TREBBLE_CANNOT_BE_EMPTY");
            }else{
                if(name && name.length >= 100){
                    errorMessage = window.getI18n(ti18n, "TREBBLE_NAME_IS_LESS_THAN_X_CHAR");
                }
                if(!name.match(Utils.getInstance().getTrebbleNameRegexValidation())){
                    errorMessage = window.getI18n(ti18n, "TREBBLE_NAME_CAN_ONLY_CONTAIN");
                }
                if(name.indexOf("_") != -1){
                    errorMessage = window.getI18n(ti18n, "TREBBLE_CANNOT_CONTAIN_HYPHENS");
                }
            }
            const isValid = !errorMessage;
            if(isValid){
                return TrebbleClientAPIHelper.getInstance().isRadioWithSameName(name, this.model ? this.model.getLoaderKey(): null).then((function(alreadyExists){
                    if(alreadyExists){
                        this.view.setTrebbleNameValid(false, window.getI18n(ti18n, "TREBBLE_NAME_VALIDATION_ERROR"));
                    }else{
                        if(!this.view.getCustomUrl() || !this.view.isCustomUrlUserEdited()){
                            return TrebbleClientAPIHelper.getInstance().getGeneratedCustomURLFromTrebbleName(name).then((function(generatedCustomURL){
                                this.view.setCustomUrl(generatedCustomURL);
                                this.view.setTrebbleNameValid(true);
                            }).bind(this)).catch((function(error){
                                //something went wrong silently continue
                                this.view.setTrebbleNameValid(true);
                            }).bind(this))
                        }else{
                            this.view.setTrebbleNameValid(true);
                        }

                    }
                    return !alreadyExists;
                }).bind(this)).catch((function(error){
                    this.view.setTrebbleNameValid(false, error);
                }).bind(this));
            }else{
                this.view.setTrebbleNameValid(isValid, errorMessage);
                return RSVP.Promise.resolve(isValid);
            }
            return isValid;
        },

        validateTrebbleCustomUrl : function(customUrl){
            let errorMessage =  null;
            if(!customUrl){
                errorMessage  =  window.getI18n(ti18n, "YOU_NEED_TO_CHOOSE_A_CUSTOM_URL");
            }
            const isValid = !errorMessage;
            if(isValid){
                return TrebbleClientAPIHelper.getInstance().isRadioWithSameCustomURL(customUrl, this.model ? this.model.getLoaderKey(): null).then((function(alreadyExists){
                    if(alreadyExists){
                        this.view.setTrebbleCustomUrlValid(false,  window.getI18n(ti18n, "CUSTOM_URL_ERROR_MESSAGE"));
                    }else{
                        this.view.setTrebbleCustomUrlValid(true);

                    }
                    return !alreadyExists;
                }).bind(this)).catch((function(){
                    this.view.setTrebbleCustomUrlValid(false, errorMessage);
                }).bind(this));
            }else{
                this.view.setTrebbleCustomUrlValid(isValid, errorMessage);
                return RSVP.Promise.resolve(isValid);
            }
            return isValid;
        },

        validateTrebbleDescription : function(description){
            let errorMessage =  null;
            if(!description){
                errorMessage  = window.getI18n(ti18n, "YOUR_TREBBLE_DESCRIPTION_CANNOT_BE_EMPTY");
            }else{
                if(description && description.length >= 400){
                    errorMessage = window.getI18n(ti18n, "SORRY_BUT_TREBBLE_DESCRIPTION_IS_BETWEEN_X_CHAR");
                }
            }
            const isValid = !errorMessage;
            this.view.setTrebbleDescriptionValid(isValid, errorMessage);
            return RSVP.Promise.resolve(isValid);
        },


        getCities : function(keyword){
            if(keyword){
                $.mobile.loading("show");
                return TrebbleClientAPIHelper.getInstance().searchForCities(keyword,window.getAppUsedLanguage()).then((function(cityInfoArray){
                    const cityListModelArray = [];
                    for(let i =0; i <  cityInfoArray.length; i++){
                        const cityInfo = cityInfoArray[i];
                        cityListModelArray.push(Utils.getInstance().getListItemModel(cityInfo.place_id,cityInfo.description));
                    }
                    $.mobile.loading("hide");
                    const listItemCollection = new ListItemCollection(cityListModelArray);
                    return listItemCollection.models;
                }).bind(this)).catch((function(error){
                    $.mobile.loading("hide");
                    throw error;
                }).bind(this));
            }else{
                return RSVP.Promise.resolve([]);
            }

        },

        _buildCitySelectFieldController : function(){
            const controllerParams = {};
            controllerParams.singleSelect =  true;
            controllerParams.propertyFunction = "getLabel";
            controllerParams.propertyIdFunction = "getId";
            controllerParams.showEmtptyInfoBox  = false;
            controllerParams.loadHandler = this.getCities.bind(this);
            controllerParams.defaultText = window.getI18n(ti18n, "CITY");
            controllerParams.enableSearch =  true;
            const customSelectFieldController = new CustomSelectFieldController(controllerParams);
            return customSelectFieldController;
        },

        _initControllers : function(){
            this.citySelectFieldController = this._buildCitySelectFieldController();
            this.listenTo(this.citySelectFieldController, "selectionChanged",this._onCitySelectionChanged.bind(this) );
            const promiseArray = [];

            promiseArray.push(this.citySelectFieldController.buildModelAndRenderView());
            return RSVP.Promise.all(promiseArray);
        },


        _onCitySelectionChanged : function(cityInfoListItemModel){
            this.view.onTrebbleCategoryChanged();
            /*if(this.isGenreDifferentFromCurrentSelected(genreInfoListItemModel)){
                if(window.trebbleAnalyticsHelper){
                    window.trebbleAnalyticsHelper.trackEvent("ExplorePage", 'OnCategorySelectionChanged', genreInfoListItemModel && genreInfoListItemModel.getId? genreInfoListItemModel.getId():null);
                }
                this._didUserChangeCategories = true;
                this._currentGenreInfoListItemModel = genreInfoListItemModel;
                this._updateRadioResults();
            }*/
        },

        onViewRemoved:function(){
            this.stopListening();
        },

        buildModelAndRenderView : function() {
            return this._initControllers().then((function(){
                return new RSVP.Promise((function(resolve, reject) {
                    try {
                        const options = {};
                        options.hideBackButton  = this._hideBackButton;
                        options.citySelectFieldView  = this.citySelectFieldController.getView();
                        this.view = new TrebbleSetupPageView(options);
                        this.view.render();
                        this.listenTo(this.view, "onFinishButtonClicked", this.finishTrebbleSetup);
                        this.listenTo(this.view, "removedFromPage", this.onViewRemovedFromDocument);
                        this.listenTo(this.view, "validateTrebbleName", this.validateTrebbleName);
                        this.listenTo(this.view, "validateTrebbleCustomUrl", this.validateTrebbleCustomUrl);
                        this.listenTo(this.view, "validateTrebbleDescription", this.validateTrebbleDescription);
                        this.listenTo(this.view, "remove", this.onViewRemoved.bind(this));

                        resolve();
                        if(window.trebbleAnalyticsHelper){
                            window.trebbleAnalyticsHelper.trackEvent("Onboarding", "TrebbleSetupPageOpened", "Trebble Setup Page Opened");
                        } 
                    } catch (error) {
                        reject(error);
                    }

                }).bind(this));
            }).bind(this));

}

});

export default TrebbleSetupController;