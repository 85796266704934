import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import ReplacePaymentForCreatorSubscriptionWidgetController from "controllers/upgradeCreatorSubscription/ReplacePaymentForCreatorSubscriptionWidgetController";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import FacebookEventTrackingHelper from "models/helper/FacebookEventTrackingHelper";
import OptionPopupView from "views/common/OptionPopupView";
import ti18n from "i18n!nls/UpgradeCreatorSubscriptionWidgeti18n";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";


const ReplacePaymentForCreatorSubscriptionPopupController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this._returnUrlAfterSetupCompleted = options.returnUrlAfterSetupCompleted
    },


    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },


    onPopupClosed : function(){
        
    },

    onUpdateCompleted: function(){
        this.view.close();
    },

    onUpdateCancelled: function(){
        this.view.close();
    },


    buildModelAndRenderView : function() {
        const params = {};
        params.returnUrlAfterSetupCompleted =  this._returnUrlAfterSetupCompleted;
        this.replacePaymentForCreatorSubscriptionWidgetController = new ReplacePaymentForCreatorSubscriptionWidgetController(params);
        this.listenTo(this.replacePaymentForCreatorSubscriptionWidgetController, "updateCompleted", this.onUpdateCompleted.bind(this));
        this.listenTo(this.replacePaymentForCreatorSubscriptionWidgetController, "cancelChange", this.onUpdateCancelled.bind(this));
        return this.replacePaymentForCreatorSubscriptionWidgetController.buildModelAndRenderView().then((function(){
            params.id = "replacePaymentForCreatorSubscriptionPopup";
            params.customClass = "replacePaymentForCreatorSubscriptionPopup";
            params.contentView =  this.replacePaymentForCreatorSubscriptionWidgetController.getView();
            params.contentPosition = "center";
            params.addContentAfterEnhancement = true;
            params.displayTitle = true;
            params.titleToDisplay = window.getI18n(ti18n,"UPDATE_PAYMENT")
            this.view = new OptionPopupView(params);
            this.listenTo(this.view, "closed",this.onPopupClosed);
            this.view.render();
        }).bind(this));


    }

});

export default ReplacePaymentForCreatorSubscriptionPopupController;