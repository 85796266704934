import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerOperationLog from "models/audioEditor/sequencerOperationLog/SequencerOperationLog";
import RSVP from "rsvp";

const SequencerOperationTurnMagicSoundEnhancerOnOperationLog =  SequencerOperationLog.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperationLog.apply(this, [attributes, options]);	
    },

    getOperationName : function(){
        return "Turn Magic Sound Enhancer On";
    },

    setMagicSoundEnhancerOn : function(magicSoundEnhancerOn){
        this.set("magicSoundEnhancerOn", magicSoundEnhancerOn);
    },

    getMagicSoundEnhancerOn : function(){
        return this.get("magicSoundEnhancerOn");
    },


});

export default SequencerOperationTurnMagicSoundEnhancerOnOperationLog; 