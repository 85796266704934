import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import BasicSequencerNodeInspectorController from "controllers/audioEditor/sequencerNodeInspectorSection/BasicSequencerNodeInspectorController";
import VideoSequencerNodeInspectorView from "views/audioEditor/sequencerNodeInspectorSection/VideoSequencerNodeInspectorView";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const VideoSequencerNodeInspectorController = BasicSequencerNodeInspectorController.extend({


    listenToEventsOnView: function(){
        this.listenTo(this.view, "volumeChanged", (function(newVolumePercentage){
            this._sequencer.updateSequencerNodeProperty(this.getModel(), "setMainVolumePercentage", "getMainVolumePercentage", newVolumePercentage);
        }).bind(this));
        
        this.listenTo(this.view, "videoChanged", (function(newTrebbleVideo){
            this._sequencer.updateSequencerNodeProperty(this.getModel(), "setVideoAndVideoSegmentStartTimeAndEndTime", "getVideoAndVideoSegmentStartTimeAndEndTime", newTrebbleVideo);
        }).bind(this));

        this.listenTo(this.view, "videoSourceChanged", (function(newTrebbleVideoJson){
            this._sequencer.updateSequencerNodeProperty(this.getModel(), "setVideoAndVideoSegmentStartTimeAndEndTime", "getVideoAndVideoSegmentStartTimeAndEndTime", newTrebbleVideoJson);
        }).bind(this));

        this.listenTo(this.view, "introFadeChanged", (function(introFadeInSeconds){
            this._sequencer.updateSequencerNodeProperty(this.getModel(), "setIntroFadeInMilliseconds", "getIntroFadeInMilliseconds", introFadeInSeconds * 1000);
        }).bind(this));

        this.listenTo(this.view, "outroFadeChanged", (function(outroFadeInSeconds){
            this._sequencer.updateSequencerNodeProperty(this.getModel(), "setOutroFadeInMilliseconds", "getOutroFadeInMilliseconds", outroFadeInSeconds * 1000);
        }).bind(this));

        this.listenTo(this.view, "introPaddingChanged", (function(introPaddingInSeconds){
            this._sequencer.updateSequencerNodeProperty(this.getModel(), "setIntroPaddingInMilliseconds", "getIntroPaddingInMilliseconds", introPaddingInSeconds * 1000);
        }).bind(this));

        this.listenTo(this.view, "outroPaddingChanged", (function(outroPaddingInSeconds){
            this._sequencer.updateSequencerNodeProperty(this.getModel(), "setOutroPaddingInMilliseconds", "getOutroPaddingInMilliseconds", outroPaddingInSeconds * 1000);
        }).bind(this));
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                const params = {};
                params.sequencerNode = this.getModel();
                this.view = new VideoSequencerNodeInspectorView(params);
                this.view.render();
                this.listenToEventsOnView();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default VideoSequencerNodeInspectorController;