import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import SongListPageController from "controllers/songListPageController/SongListPageController";
import TrebbleIndexDBHelper from "models/helper/TrebbleIndexDBHelper";
import SoundcloudSearchHelper from "models/helper/SoundcloudSearchHelper";
import RSVP from "rsvp";

const SoundcloudSongListPageController = SongListPageController.extend({




    getSongListLoadHandler : function(){
        const searchKeyword = this.getSearchKeyword();
        return function(){
            return SoundcloudSearchHelper.getInstance().searchSongs(searchKeyword && searchKeyword.trim(), 100).then(function(arrayOfSoundcloudSongs){
                const arrayOfSongsWithAlbumAndArtistRef = TrebbleIndexDBHelper.getInstance().getSongsWithAlbumAndArtistInfoFromSongJsonArray(arrayOfSoundcloudSongs);
                return arrayOfSongsWithAlbumAndArtistRef;
            });
        }
    },
    


});
export default SoundcloudSongListPageController;