import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utils from "models/helper/Utils";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";
import CapsuleDetailsEditContentView from "views/capsule/CapsuleDetailsEditContentView";
import DynamicPageView from "views/common/DynamicPageView";
import ListItemCollection from "collections/ListItemCollection";
import ti18n from "i18n!nls/CapsuleDetailsi18n";
import ViewPageTemplate from 'text!../../../templates/capsule/CapsuleViewDetailsPageTemplate.html';
import EditPageTemplate from 'text!../../../templates/capsule/CapsuleEditDetailsPageTemplate.html';

const CapsuleDetailsController =  Chaplin.Controller.extend({


    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = options.model;
        this._capsuleId = options.capsuleId;
        this._isEditMode = options.editMode;
        this._pageId = options.pageId;
        this._onActionExecutedOnUserHandler = options.onActionExecutedOnUserHandler;
                    
    },
    
    getModel : function(){
        return this.model;
    },
    
    getView : function(){
        return this.view;
    },
    
    saveChangesConfirm : function(){
            const editedData = this.getEditedCapsuleInfo();
            if(!editedData.isActive)
            {
                const buttonLabels = [ window.getI18n(ti18n, "NO"), window.getI18n(ti18n, "YES")];
                const confirmCallback = (function(buttonIndex){
                    this.self.saveChanges(this.capsuleInfoToSave, buttonIndex == 2);
                }).bind({"self": this, "capsuleInfoToSave": editedData});
                const message = window.getI18n(ti18n, "DO_YOU_WANT_TO_MAKE_THIS_AN_ACTIVE_CAPSULE");
                navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n, "MAKE_CAPSULE_ACTIVE"), buttonLabels);
            }else{
                this.saveChanges(editedData, false);
            }

    },
    saveChanges : function(capsuleInfoToSave,makeCapsuleActive){
        this.setSaveButtonEnabled(false);
        return TrebbleClientAPIHelper.getInstance().updateSongCapsule(capsuleInfoToSave.capsuleId,capsuleInfoToSave.title,
                capsuleInfoToSave.notes, capsuleInfoToSave.lifeSpanChanged, 
                capsuleInfoToSave.numberOfDaysToLive, makeCapsuleActive, capsuleInfoToSave.updateCategoryId, capsuleInfoToSave.categoryId, capsuleInfoToSave.private).then((function(){
                    Utils.getInstance().showShortToastMessage(window.getI18n(ti18n, "CHANGES_ON_CAPSULE_WERE_SUCCESSFULLY_SAVED"));

                    this.view.navigateToPreviousPage();
                    if(this._onActionExecutedOnUserHandler){
                        this._onActionExecutedOnUserHandler("edit", capsuleInfoToSave);
                    }
                }).bind(this)).catch((function(error){
                    this.setSaveButtonEnabled(true);
                    window.alertErrorMessage(window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_SAVING_YOUR_CHANGES")+" "+ error);
                }).bind(this));

    },
    getCapsuleInfoLoadFunction : function(capsuleId){
        return (function(params){
            return TrebbleClientAPIHelper.getInstance().getCapsuleInfoByCapsuleId(capsuleId? capsuleId: this.capsuleId).then(function(capsuleJson){
                if(capsuleJson){
                    this.model =   Utils.getInstance().getModelFromCapsuleJson(capsuleJson);
                    return this.model;
                }else{
                    return null;
                }
            });
        }).bind({"capsuleId": capsuleId});
        
    },
    
    getEditedCapsuleInfo : function(){
        return this.contentView? this.contentView.getEditableCapsuleInfo(): null;
    },
    
    isEditedInfoValid : function(){
        /*const editedData = this.getEditedTrebbleInfo();
        if(!editedData.name){
            console.error("trebble name cannot be empty");
        window.alertErrorMessage("You cannot have a trebble with no name!");
            return false;
        }else{
            if(editedData.name && editedData.name.length >= 100){
                console.error("A trebble name cannot be more than 100 characters");
            window.alertErrorMessage("A trebble name cannot be more than 100 characters!");
                return false;
            }
        }
        if(!editedData.description){
            console.error("trebble description cannot be empty");
        window.alertErrorMessage("You cannot have a trebble with no name!");
            return false;
        }else{
            if(editedData.description && editedData.description.length >= 300){
                console.error("A trebble description cannot be more than 300 characters");
            window.alertErrorMessage("A trebble description cannot be more than 300 characters!");
                return false;
            }
        }*/
        return true;
    },
    
    deleteCapsuleConfirm : function(){
        const buttonLabels = [window.getI18n(ti18n, "NO"), window.getI18n(ti18n, "YES")];
        const confirmCallback = (function(buttonIndex){
            if(buttonIndex ==  2){
                this.deleteCapsule();
            }
        }).bind(this);
        const message = window.getI18n(ti18n, "DO_YOU_WANT_TO_DELETE_THIS_CAPSULE");
        navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n, "DELETE_CAPSULE"), buttonLabels, false, null, null, true);
    } ,
    
    deleteCapsule: function(){
        $.mobile.loading("show");
        PersistentModels.getInstance().deleteCapsule(this.model).then((function(){
            this.view.navigateToPreviousPage();
            window.alertSuccessMessage(window.getI18n(ti18n, "CAPSULE_DELETED"));
            if(this._onActionExecutedOnUserHandler){
                this._onActionExecutedOnUserHandler("delete", this.model.toJSON());
            }
            $.mobile.loading("hide");
        }).bind(this)).catch((function(error){
            $.mobile.loading("hide");
            window.alertErrorMessage(error);
        }).bind(this));
    },
    
    startPlayingCapsule : function(){
        if(this.model){
            const audioUrl = this.model.getAudioUrl();
            const successCB = (function(){
                this.contentView.setIsPlaying(false);
            }).bind(this);
            const failureCB = (function(){
                this.contentView.setIsPlaying(false);
            }).bind(this);
            if(audioUrl){
                PersistentModels.getInstance().getPlayer().playAudioPreview(audioUrl, successCB, failureCB);
            }else{
                Utils.getInstance().showShortToastMessage(window.getI18n(ti18n, "NO_AUDIO_TO_PLAY"));
            }
        }
    },
    
    stopPlayingCapsule : function(){
        if(this.model){
            const audioUrl = this.model.getAudioUrl();
            if(audioUrl){
                PersistentModels.getInstance().getPlayer().terminateAudioPreviewAndResumePlayback();
            }else{
                Utils.getInstance().showShortToastMessage(window.getI18n(ti18n, "CAPSULE_DELETED"));
            }
        }
    },

    setSaveButtonEnabled : function(enabled){
        if(enabled){
            this.saveButton$el.removeClass("disabled");
        }else{
            if(!this.saveButton$el.hasClass("disabled")){
                this.saveButton$el.addClass("disabled");
            }
        }
    },
        
    
    
    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
            
                const contentViewParams = {};
                contentViewParams.loadFunction = this.getCapsuleInfoLoadFunction(this._capsuleId);
                contentViewParams.capsuleId =this._capsuleId;
                contentViewParams.capsuleModel  = this.model;
                contentViewParams.editMode = this._isEditMode ;
                contentViewParams.capsuleCategoryCollection = new ListItemCollection(Utils.getInstance().getCapsuleCategoryListItemArray());
                const contentView = new  CapsuleDetailsEditContentView(contentViewParams);
                contentView.render();
                this.contentView = contentView;
                
                const pageViewParams= {};
                pageViewParams.pageId = this._pageId;
                pageViewParams.pageViewTemplate = this._isEditMode? EditPageTemplate : ViewPageTemplate;
                pageViewParams.containerSelector = ".ui-content";
                pageViewParams.contentView = contentView;
                this.view = new DynamicPageView(pageViewParams);
                this.view.render();
                this.saveButton$el = this.view.$el.find("#saveButtonIcon");
                this.view.$el.on("click", "#saveButtonIcon", this.saveChangesConfirm.bind(this));
                this.view.$el.on("click", "#deleteButton", this.deleteCapsuleConfirm.bind(this));
                this.view.$el.on("click", "#header-back-button", this.view.navigateToPreviousPage.bind(this.view));
                this.listenTo(this.contentView,"startPlaying", this.startPlayingCapsule);
                this.listenTo(this.contentView, "stopPlaying",this.stopPlayingCapsule);
                resolve(this);
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }
    

});
export default CapsuleDetailsController;