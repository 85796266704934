var amdi18n={"__root":{"NO_PREVIEW_YET_AVAILABLE":"No audio preview yet available.","YES":"Yes","NO":"No","ARE_YOU_SURE_YOU_WANT_TO_UNSUBSCRIBE_FROM":"Are you sure you want to unsubscribe from","UNSCUBSCRIBE_FROM_TREBBLE":"Unsubscribe From Channel","EDIT":"Edit","SHARE":"Share","HOLD_TO_PREVIEW":"Hold To Preview","PREVIEW":"Preview","SUBSCRIBED":"Subscribed","SUBSCRIBE":"Subscribe","TAP_HERE_TO_EASILY_LISTEN_ON_ALEXA_OR_GOOGLE":"Tap here to subscribe and easily listen on your Amazon Alexa or your Google Assistant Device","TAP_HERE_TO_SUBSCRIBE_AND_TO_YOUR_DAILY_ROUTINE":"Tap here to add this to your daily listen"},"__fr":{"NO_PREVIEW_YET_AVAILABLE":"Aucun aperçu audio disponible pour le moment.","YES":"Oui","NO":"Non","ARE_YOU_SURE_YOU_WANT_TO_UNSUBSCRIBE_FROM":"Êtes-vous sûr de vouloir vous désabonner","UNSCUBSCRIBE_FROM_TREBBLE":"Se désabonner de cette chaîne","EDIT":"Modifier","SHARE":"Partager","HOLD_TO_PREVIEW":"Appuyez pour prévisualiser","PREVIEW":"Aperçu","SUBSCRIBED":"Abonné","SUBSCRIBE":"S'abonner","TAP_HERE_TO_EASILY_LISTEN_ON_ALEXA_OR_GOOGLE":"Appuyez ici pour vous abonner et écouter facilement sur votre Amazon Alexa ou votre appareil Google Assistant.","TAP_HERE_TO_SUBSCRIBE_AND_TO_YOUR_DAILY_ROUTINE":"Appuyez ici pour ajouter ceci à votre écoute quotidienne"},"__en":{"NO_PREVIEW_YET_AVAILABLE":"No audio preview yet available.","YES":"Yes","NO":"No","ARE_YOU_SURE_YOU_WANT_TO_UNSUBSCRIBE_FROM":"Are you sure you want to unsubscribe from","UNSCUBSCRIBE_FROM_TREBBLE":"Unsubscribe From Channel","EDIT":"Edit","SHARE":"Share","HOLD_TO_PREVIEW":"Hold To Preview","PREVIEW":"Preview","SUBSCRIBED":"Subscribed","SUBSCRIBE":"Subscribe","TAP_HERE_TO_EASILY_LISTEN_ON_ALEXA_OR_GOOGLE":"Tap here to subscribe and easily listen on your Amazon Alexa or your Google Assistant Device","TAP_HERE_TO_SUBSCRIBE_AND_TO_YOUR_DAILY_ROUTINE":"Tap here to add this to your daily listen"}};amdi18n.init=function(language){
		// get the default language
		if(!language){
			if(typeof window !== 'undefined' && window._i18n && window._i18n.locale){
				language = window._i18n.locale;
			}else if(typeof document !== 'undefined' && document.documentElement.lang){
				language = document.documentElement.lang;
			}else if(typeof navigator !== 'undefined' && (navigator.languages || navigator.language || navigator.userLanguage)){
				// navigator.languages does not exist in IE 11
				language = (navigator.languages && navigator.languages[0]) ||
					navigator.language ||
					navigator.userLanguage;
				language = (language || 'root').toLowerCase();
			}else{
				language = 'root';
			}
		}
		var target = this['__' + language] || this.__root;

		// copy definition to root level
		if (target) {
			for (var name in target) {
				this[name] = target[name];
			}
		}

		// fallback to root
		var recursiveFallback = function(target, source){
			for(var name in source){
				switch(typeof target[name]){
				case 'undefined':
					target[name] = source[name];
					break;
				case 'object':
					recursiveFallback(target[name], source[name]);
				}
			}
		};
		recursiveFallback(this, this.__root);
	};amdi18n.init();module.exports=amdi18n;