import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerOperationLog from "models/audioEditor/sequencerOperationLog/SequencerOperationLog";
import RSVP from "rsvp";

const SequencerOperationSettingsChangeLog =  SequencerOperationLog.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperationLog.apply(this, [attributes, options]);	
    },

    getOperationName : function(){
        return "Change Sequencer Setting";
    },

    setSettingToChange : function(settingToChange){
        this.set("settingToChange", settingToChange);
    },

    getSettingToChange : function(){
        return this.get("settingToChange");
    },

    getOldValue : function(){
        return this.get("oldValue");
    },

    setOldValue : function(oldValue){
        this.set("oldValue", oldValue);
    },

    getNewValue : function(){
        return this.get("newValue");
    },

    setNewValue : function(newValue){
        this.set("newValue", newValue);
    },


});

export default SequencerOperationSettingsChangeLog; 