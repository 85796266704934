
import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utils from "models/helper/Utils";
import PersistentModels from "services/PersistentModels";
import SubscriptionManager from "models/helper/SubscriptionManager";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import StripeHelper from "models/helper/StripeHelper";
import RSVP from "rsvp";
import ti18n from "i18n!nls/UpgradeCreatorSubscriptionWidgeti18n";
import ReplacePaymentForCreatorSubscriptionWidgetView from "views/upgradeCreatorSubscription/ReplacePaymentForCreatorSubscriptionWidgetView";


const ReplacePaymentForCreatorSubscriptionWidgetController =  Chaplin.Controller.extend({


    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this._returnUrlAfterSetupCompleted =  options.returnUrlAfterSetupCompleted;
        
    },
    
    getModel : function(){
        return this.model;
    },
    
    getView : function(){
        return this.view;
    },

    onUserCreatorSubscriptionChanged : function(subscriptionInfo){
        this.updateSubscriptionSelected(subscriptionInfo);
    },


    fetchStripeSecretAndInitializeForm : function(){
        this.view.setContentLoading(true);
        return StripeHelper.getInstance().showPaymentFormForStripeSetupIntent(this.view.getPaymentFormContainer$el().get(0)).then((function(results){
            this._stripeElementInstance =  results.stripeElements;
            this._stripePaymentElementInstance =  results.paymentElement;
            this._stripePaymentElementInstance.on("ready", (function(){
                this.view.setContentLoading(false);
                this.trigger("formReady");
            }).bind(this))
        }).bind(this));
    },


    updatePaymentMethod: function(){
        $.mobile.loading("show");
        if(!this.view.getCaptchaReponse()){
            $.mobile.loading("hide");
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("upgradeCreatorSubscription", "captchaFailedOnPaymentForm", "captcha failed on payment form");
            }
            return this.view.displayPaymentError(window.getI18n(ti18n, "PLEASE_VERIFY_THAT_YOU_ARE_NOT_A_ROBOT"));
        }
        return StripeHelper.getInstance().completePaymentSetup(this._stripeElementInstance, this._returnUrlAfterSetupCompleted).then((function(){
            $.mobile.loading("hide");
            this.view.displayNoPaymentError();
            this.trigger("updateCompleted");
        }).bind(this)).catch((function(error){
            $.mobile.loading("hide");
            this.view.displayPaymentError(error);
        }).bind(this))
    },

    cancelChange : function(){
        this.trigger("cancelChange");
    },


    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {

                const viewParams = {};
                const replacePaymentForCreatorSubscriptionWidgetView = new  ReplacePaymentForCreatorSubscriptionWidgetView(viewParams);
                replacePaymentForCreatorSubscriptionWidgetView.render();
                this.view = replacePaymentForCreatorSubscriptionWidgetView;
                this.fetchStripeSecretAndInitializeForm();
                this.listenTo(this.view, "updatePaymentMethod", this.updatePaymentMethod.bind(this));
                this.listenTo(this.view, "cancelChange", this.cancelChange.bind(this));
                resolve(this);
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }
    

});
export default ReplacePaymentForCreatorSubscriptionWidgetController;