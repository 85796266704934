import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import TranscribedAudio from "models/audioEditor/TranscribedAudio";
import RSVP from "rsvp";

const SequencerLibrary =  Backbone.Model.extend({
    idAttribute: "id",

    constructor: function(attributes, options) {
    
        this._audioUrlToTranscriptionAudioMap = {};

        Backbone.Model.apply(this, [attributes, options]);
    },


    getTranscribeAudio : function(audioUrl, transcriptionJsonURL, audioBufferDurationInMilliseconds, transcriptionId, isVideo, speakerInfoSerializedDataArray, additionalSpeakerInfoArray, generateSpeakerLabelIfDuplicate, languageCode, videoWidth, videoHeight){
        if(!this._audioUrlToTranscriptionAudioMap[audioUrl]){
            const transcribedAudio = new TranscribedAudio({"audioUrl": audioUrl, "transcriptJsonURL":transcriptionJsonURL, "audioBufferDurationInMilliseconds": audioBufferDurationInMilliseconds, "transcriptionId": transcriptionId, "hasVideo": isVideo,"speakerInfoSerializedDataArray":speakerInfoSerializedDataArray,generateSpeakerLabelIfDuplicate, languageCode, "videoWidth":videoWidth, "videoHeight": videoHeight });
            this._audioUrlToTranscriptionAudioMap[audioUrl] = transcribedAudio;
            return transcribedAudio.loadAndProcessTranscription(additionalSpeakerInfoArray).then((function(){
                return this._audioUrlToTranscriptionAudioMap[audioUrl];
            }).bind(this));
        }
        return RSVP.Promise.resolve(this._audioUrlToTranscriptionAudioMap[audioUrl]);
    },

    clearCachedTranscribeAudio : function(audioUrl){
        delete this._audioUrlToTranscriptionAudioMap[audioUrl];
    },

    getExistingAudioSegmentFromJSON : function(audioSegmentJSON){
        if(this._audioUrlToTranscriptionAudioMap[audioSegmentJSON.audioUrl]){
            return this._audioUrlToTranscriptionAudioMap[audioSegmentJSON.audioUrl].getAudioSegmentMatchingStartAndEndTime(audioSegmentJSON)
        }
    },



});



const SequencerLibraryInstance = new SequencerLibrary();
window.trebble.globalHelpers.SequencerLibrary = SequencerLibraryInstance;

export default {
    getInstance : function() {
        return SequencerLibraryInstance;
    }
}; 