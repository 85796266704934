import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import GooglePlusHelper from "models/helper/GooglePlusHelper";
import Utils from "models/helper/Utils";
import pageTemplate from 'text!../../../templates/SignUp/SignUpPageTemplate.html';
import ti18n from "i18n!nls/signupPagei18n";
import RSVP from "rsvp";
import Mousetrap from 'mousetrap';

const ACCESS_CODE_REQUIRED = true;
const DISABLE_PASSWORD_CONFIRM = true;
const USE_NEW_GOOGE_API_ON_WEB = true;
const SignUpPageView = Backbone.View.extend({

    template : _.template(pageTemplate),

    initialize : function(options) {
        this._removeOnHide = true;
        this.isUserpreviouslyLoggedInAsGuest = options? options.isUserpreviouslyLoggedInAsGuest: false;
        this._accessCode = options? options.accessCode: null;
        if(this._accessCode == "undefined"){
            this._accessCode = "WEBB-58D2-V8V";
        }
        this._hideBackButton = options? options.hideBackButton : false;
        this._mousetrapObj = null;
        this._enabledColor = "#e9605d";
        this._disabledColor = "#cccccc";
    },

    events : {
        "click #header-back-button" : "navigateToPreviousPage",
        "click #SignUpBtn.enabled" : "onSignUpBtnTapped",
        "click #SignUpWithFacebookBtn" : "onSignUpWithFacebookBtnTapped",
        "click #SignUpWithAppleBtn" : "onSignUpWithAppleBtnTapped",
        "click #showExternalSignupOptionsBtn": "onShowExternalSignupOptionsBtnTapped",
        "click #signUpWithEmailBtn" : "onSignUpWithEmailBtn",
    },

    navigateToPreviousPage : function(){
        /*if(PersistentModels && PersistentModels.getInstance().getRouter()  ){
            PersistentModels.getInstance().getRouter().navigateToPreviousPage();
        }else{*/
            window.history.back();  
            //}

        },

        onSignUpBtnTapped : function(){

            const username  = this.usernameField$el.val();
            const password  =  this.newPassswordField$el.val();
            const email = this.emailField$el.val();
            //const firstName = this.firstNameField$el.val();
            //const lastName = this.lastNameField$el.val();
            const fullName = this.fullNameField$el.val();
            //const yearBirthday = this.$el.find("#yearBirthdayField").val();
            const accessCode = this.accessCodeField$el.val();
            const data = {};
            data.username =  username;
            data.password =  password;
            data.email =  email;
            //data.firstName =  firstName;
            //data.lastName =  lastName;
            data.fullName =  fullName;
            //data.yearOfBirth =  yearBirthday;
            data.accessCode = accessCode;
            this.trigger("signUpBtnTapped", data);
        },
        onSignUpWithFacebookBtnTapped: function(){
            this.trigger("signUpWithFacebookBtnTapped", this.accessCodeField$el.val());
        },

        onSignUpWithAppleBtnTapped: function(){
            this.trigger("signUpWithAppleBtnTapped", this.accessCodeField$el.val());
        },
        onSignUpWithEmailBtn : function(){
            this.showOrHideSignupOptions(false);
        },

        onShowExternalSignupOptionsBtnTapped : function(){
            this.showOrHideSignupOptions(true);
        },


        showOrHideSignupOptions : function(show){
            const fieldsToHideOrShow = "#emailDecorator, #firstNameField, #fullNameField, #lastNameField, #usernameField, #passwordDecId, #reenterPasswordDecId, #SignUpBtn"
            if(show){
                this.$el.find(fieldsToHideOrShow).hide();
                this.$el.find("#showExternalSignupInfoWrapper").hide();
                this.$el.find("#signUpWithEmailBtn").css("display","block");
                this.$el.find("#SignUpWithFacebookBtn").css("display","flex");
                if(USE_NEW_GOOGE_API_ON_WEB){
                    this.$el.find(".google_button_container").css("display","flex");
                }else{
                    this.$el.find("#SignUpWithGoogleBtn").css("display","flex");
                }
                this.$el.find("#SignUpWithAppleBtn").css("display","flex");
            }else{
                this.$el.find("#signUpWithEmailBtn").hide();
                this.$el.find("#SignUpWithFacebookBtn").hide();
                if(USE_NEW_GOOGE_API_ON_WEB){
                    this.$el.find(".google_button_container").hide();
                }else{
                    this.$el.find("#SignUpWithGoogleBtn").hide();
                }
                this.$el.find("#SignUpWithAppleBtn").hide();
                this.$el.find("#showExternalSignupInfoWrapper").css("display","block");
                this.$el.find(fieldsToHideOrShow).show();
                this.emailField$el.focus();
            }
        },



        onBeforeRemoveFromPage : function(){
            this.trigger("beforeRemoveFromPage", this);
            if(this._mousetrapObj){
                this._mousetrapObj.unbind("enter");
            }
        },

        onRemovedFromPage : function(){
            this.undelegateEvents();
            window.removeEventListener('resize', window[this._onViewportHeightChangedFunctionRef] );
            this.$el.removeData().unbind(); 
            this.trigger("removedFromPage", this);
        },

        onFieldValueChanged : function(event){
            if(this.isAllMandatoryFieldsFilled()){
                this.trebbleLogo$el.css("color",this._enabledColor);
                this.signUpBtn$el.removeClass("disabled").addClass("enabled");
            }else{
                this.trebbleLogo$el.css("color",this._disabledColor);
                this.signUpBtn$el.removeClass("enabled").addClass("disabled");
            }
        },
        isAllMandatoryFieldsFilled : function(){
            return this.usernameField$el.val() && this.validateUsername(this.usernameField$el.val())&& this.validatePasswordField() 
            //&& this.firstNameField$el.val() && this.emailField$el.val()
            && this.fullNameField$el.val() && this.emailField$el.val()
            && this.validateEmail(this.emailField$el.val())
            //&& this.lastNameField$el.val() //&& this.$el.find("#yearBirthdayField").val() 
            && (!ACCESS_CODE_REQUIRED  || this.accessCodeField$el.val());
        },

        onUserNameFieldKeyUp :function(){
            this.usernameField$el.val( this.usernameField$el.val().replace(/ /g, "_").toLowerCase());
        },
        _onViewportHeightChanged : function(){
            const headerHeight = 54 ;
            this.$el.find(".ui-content").css("height",(window.getDocumentClienHeight() -headerHeight)+ "px");
        },



        validateEmail : function(email) {
            const re = Utils.getInstance().getEmailRegexValidation();
            return re.test(email? email.trim(): email);
        },


        validateUsername : function(username){
            const re = Utils.getInstance().getUsernameRegexValidation();
            return re.test(username);
        },

        validateReenterPasswordField : function(){
            const passwordDecorator = this.newPassswordFieldDec$el.get(0);
            const passwordInput = this.newPassswordField$el.get(0);
            const reenterPasswordDecorator = this.reenterNewPassswordFieldDec$el.get(0);
            const reenterPasswordInput =  this.reenterNewPassswordField$el.get(0);
            const pass2 = reenterPasswordInput.value;
            const pass1 = passwordInput.value;
            const arePasswordTheSame = pass1 === pass2;
            if (!arePasswordTheSame) {
                const errorMessage = window.getI18n(ti18n, "PASSWORD_DONT_MATCH");
                //reenterPasswordInput.setCustomValidity(errorMessage);
                this.reenterNewPassswordFieldDec$el.find("label").attr("data-error",errorMessage);
                if(!this.reenterNewPassswordField$el.hasClass("invalid"))
                {
                    this.reenterNewPassswordField$el.addClass("invalid");
                }
            } else {
                // empty string means no validation error
                this.reenterNewPassswordField$el.removeClass("invalid");
                //reenterPasswordInput.setCustomValidity('');
                this.reenterNewPassswordFieldDec$el.find("label").attr("data-error","");
            }
            //passwordDecorator.isInvalid = !passwordInput.validity.valid;
            //reenterPasswordDecorator.isInvalid = !reenterPasswordInput.validity.valid;
            return arePasswordTheSame;
        },

        validatePasswordField : function(){
            const passwordDecorator = this.newPassswordFieldDec$el.get(0);
            const passwordInput = this.newPassswordField$el.get(0);
            const pass1 = passwordInput.value;
            const re = Utils.getInstance().getUserPasswordRegexValidation();
            const isNewPasswordValid = re.test(pass1);
            if (!isNewPasswordValid) {
                const errorMessage = window.getI18n(ti18n, "PASSWORD_MUST_BE_BETWEEN_X_CHAR");
                //passwordInput.setCustomValidity(errorMessage);
                this.newPassswordFieldDec$el.find("label").attr("data-error",errorMessage);
                if(!this.newPassswordField$el.hasClass("invalid"))
                {
                    this.newPassswordField$el.addClass("invalid");
                }
            } else {
                // empty string means no validation error
                this.newPassswordField$el.removeClass("invalid");
                //passwordInput.setCustomValidity('');
                this.newPassswordFieldDec$el.find("label").attr("data-error","");
            }
            //passwordDecorator.isInvalid = !passwordInput.validity.valid;
            const reenterValid = DISABLE_PASSWORD_CONFIRM ?true: this.validateReenterPasswordField();
            return isNewPasswordValid && reenterValid;
        },

        _onPageShow : function(){
            if(this._hideBackButton){

                this.$el.find("#header-back-button").hide();
            }


        },

        _onGoogleSignupuccess : function(googleUser){
            this.trigger("signUpWithGoogleBtnSuccess",googleUser, this.accessCodeField$el.val());
        },

        _onGoogleSignupFailed : function(error){
            this.trigger("signUpWithGoogleBtnFailed",error);
        },

        /*_loadGAPI : function(){
            return new Promise((resolve, reject) => {
                window._loadScript('https://apis.google.com/js/client')
                  .then(() => {
                    // Check if the Google API client is available
                    if (typeof gapi !== 'undefined') {
                      // Assuming _onGAuthLoadedPromise is a global variable or defined elsewhere
                      // You can return it if it's available
                      resolve(_onGAuthLoadedPromise);
                    } else {
                      reject(new Error('Failed to load Google API client'));
                    }
                  })
                  .catch(reject);
              });

        },*/
        

        render : function(){
            const params ={};
            params.isUserpreviouslyLoggedInAsGuest = this.isUserpreviouslyLoggedInAsGuest;
            params.accessCode =  this._accessCode;
            params.ti18n = ti18n;
            this.$el.html(this.template(params));
            this.setElement(this.$el.find("div").first());

            this.$el.page();

            const functionUniqueGlobalName = "onViewportHeightChangeForSignupPage"+ (new Date()).getTime();
            window[functionUniqueGlobalName] =  this._onViewportHeightChanged.bind(this)
            this._onViewportHeightChangedFunctionRef = functionUniqueGlobalName;
            window.addEventListener('resize', window[functionUniqueGlobalName]  );
            this.usernameField$el = this.$el.find("#usernameField");
            this.newPassswordField$el = this.$el.find("#passwordField");
            this.reenterNewPassswordField$el = this.$el.find("#reenterPasswordField");
            this.newPassswordFieldDec$el = this.$el.find("#passwordDecId");
            this.reenterNewPassswordFieldDec$el = this.$el.find("#reenterPasswordDecId");
            this.emailField$el = this.$el.find("#emailField");
            this.firstNameField$el = this.$el.find("#firstNameField");
            this.lastNameField$el = this.$el.find("#lastNameField");
            this.fullNameField$el = this.$el.find("#fullNameField");
            this.fullNameFielDecorator$el = this.$el.find("#fullNameFieldDec");
            this.accessCodeField$el = this.$el.find("#accessCodeField");
            this.trebbleLogo$el =  this.$el.find(".icon-trebble-icon-2ton");
            this.signUpBtn$el = this.$el.find("#SignUpBtn");
            this.usernameField$el.on("click blur keyup input", this.onUserNameFieldKeyUp.bind(this));
            this.$el.find(".formField").on("click blur keyup input", this.onFieldValueChanged.bind(this));
            //this.$el.find("#SignUpWithFacebookBtn").hide();
            if(window.waitForCordovaToLoad ){
                if(window.isIOSMobileDevice){
                    this.$el.find("#SignUpWithAppleBtn").insertBefore(this.$el.find("#SignUpWithFacebookBtn"))
                }else{
                    this.$el.find("#SignUpWithAppleBtn").hide();
                    this.$el.find("#SignUpWithGoogleBtn").insertBefore(this.$el.find("#SignUpWithFacebookBtn"))
                }
            }
            this.trebbleLogo$el.css("color",this._disabledColor);
            this.signUpBtn$el.removeClass("enabled").addClass("disabled");
            this.$el.on("pagehide", this._onPageHide.bind(this));
            this.$el.on("pageshow", this._onPageShow.bind(this));
            this._mousetrapObj = Mousetrap(this.$el.find("#SignUpForm").get(0)).bind('enter', (function(e, combo) {
                if(this.signUpBtn$el.hasClass("enabled")){
                    this.onSignUpBtnTapped();
                }
            }).bind(this));
            //this.$el.find("#reenterPasswordDecId").get(0).validate = this.validateReenterPasswordField.bind(this);
            //this.$el.find("#passwordDecId").get(0).validate = this.validatePasswordField.bind(this);



            //Fill in accessCode if available and hide field
            if(this._accessCode){
                this.accessCodeField$el.hide();
            }
            this.$el.find("#SignUpWithGoogleBtn").hide();
            /*this._loadGAPI().then(function(){
                return _onGAuthLoadedPromise;
            }).then((function(){
                if(window.auth2 && window.auth2.attachClickHandler){
                    window.auth2.attachClickHandler(this.$el.find("#SignUpWithGoogleBtn").get(0), {},this._onGoogleSignupuccess.bind(this), this._onGoogleSignupFailed.bind(this));
                    this.$el.find("#SignUpWithGoogleBtn").show();
                }
            }).bind(this));*/

GooglePlusHelper.getInstance().attachClickHandlerToElementForLoginIntent(this.$el.find("#SignUpWithGoogleBtn").get(0), {},this._onGoogleSignupuccess.bind(this), this._onGoogleSignupFailed.bind(this), USE_NEW_GOOGE_API_ON_WEB, this.$el.find(".google_button_container").get(0),{text:"signup_with"}).then((function(){
if(USE_NEW_GOOGE_API_ON_WEB || window.isInMobileAppContext()){
this.$el.find("#SignUpWithGoogleBtn").show();
}
}).bind(this));

this.showOrHideSignupOptions(true);
//this.$el.find("#emailField").focus();

return this;
},
setRemoveOnHide : function(removeOnHide){
this._removeOnHide =removeOnHide;
},

_onPageHide : function(){

if(this._removeOnHide)
{
//PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._listenerViewportKey);
this.onBeforeRemoveFromPage();
this.remove();
this.onRemovedFromPage();
}
},



});
export default SignUpPageView;