	import $ from 'jquery';
    import _ from 'underscore';
    import Backbone from "backbone";
    import RSVP from "rsvp";



    const MparticleHelper = Backbone.Model.extend({



        identity : function(){
            if(window.waitForCordovaToLoad){
                return new RSVP.Promise(function(resolve, reject){
                    if( "mparticle" in window){
                        const request = new window.mparticle.IdentityRequest();

                        const identity = new window.mparticle.Identity();
                        identity.identify(request, function (userId) {
                            console.debug(userId);
                            resolve(userId)
                        });
                    }else{
                        resolve();
                    }
                });
            }else{
                return new RSVP.Promise(function(resolve, reject){
                    if("mParticle" in window){
                        const identityCallback = function(result) { 
                            if (result.getUser()) { 
                                //proceed with identify 
                                resolve();
                            }else{
                                reject(new Error("Failed identifying with mParticle"));
                            }

                        };
                        window.mParticle.Identity.identify({}, identityCallback);
                    }else{
                        resolve();
                    }
                });
            }
        },
        
        login : function(userId, userEmail, lastName, firstName, creationDate, userInfo, allUserFeatures){
            if(window.waitForCordovaToLoad){
                return new RSVP.Promise(function(resolve, reject){
                    if( "mparticle" in window){
                        const request = new window.mparticle.IdentityRequest();
                        request.customerId = userId;


                        const identity = new window.mparticle.Identity();
                        identity.login(request, function (mParticleUserId) {
                            

                            const currentUser = new window.mparticle.User(mParticleUserId);
                            currentUser.setUserAttribute(window.mparticle.UserAttributeType.FirstName, firstName);
                            currentUser.setUserAttribute(window.mparticle.UserAttributeType.LastName, lastName);
                            currentUser.setUserAttribute("email", userEmail);
                            currentUser.setUserAttribute("userId", userId);
                            if(creationDate){
                                currentUser.setUserAttribute("created", creationDate.toString());
                            }
                            currentUser.setUserAttribute("username", userInfo.username);
                            if(userInfo.profileImageUrl){
                                currentUser.setUserAttribute("avatar", userInfo.profileImageUrl);
                            }
                            if(userInfo.profileType){
                                currentUser.setUserAttribute("profileType", userInfo.profileType);
                            }
                            if(allUserFeatures){
                                for(let key in allUserFeatures){
                                    if(allUserFeatures[key]){
                                        currentUser.setUserAttribute(key, allUserFeatures[key]);
                                    }
                                }
                            }
                            currentUser.setUserTag(userInfo.profileType);

                            resolve(userId);
                        });
                    }else{
                        resolve();
                    }
                });
}else{

return new RSVP.Promise(function(resolve, reject){
    if("mParticle" in window){
        const identityRequest = {
            userIdentities: {
                customerid: userId    
            }
        };
        const identityCallback = function(result) { 
            if (result.getUser()) { 
                const currentUser  = result.getUser();
                if(firstName){
                    currentUser.setUserAttribute("$FirstName", firstName);
                }
                if(lastName){
                    currentUser.setUserAttribute("$LastName", lastName);
                }
                currentUser.setUserAttribute("email", userEmail);
                currentUser.setUserAttribute("userId", userId);
                if(creationDate){
                    currentUser.setUserAttribute("created", creationDate.toString());
                }
                currentUser.setUserAttribute("username", userInfo.username);
                if(userInfo.profileImageUrl){
                    currentUser.setUserAttribute("avatar", userInfo.profileImageUrl);
                }
                if(userInfo.profileType){
                    currentUser.setUserAttribute("profileType", userInfo.profileType);
                    currentUser.setUserTag(userInfo.profileType);
                }
                
                resolve();
            }else{
                reject(new Error("Failed login with mParticle"));
            }
            
        };
        window.mParticle.Identity.login(identityRequest, identityCallback);
    }else{
        resolve();
    }
});
}

},



logout : function(){
if(window.waitForCordovaToLoad){
    return new RSVP.Promise(function(resolve, reject){
        if( "mparticle" in window){
            const request = new window.mparticle.IdentityRequest();

            const identity = new window.mparticle.Identity();
            identity.logout(request, function (userId) {
                console.debug(userId);
                resolve(userId)
            });
        }else{
            resolve();
        }
    });
}else{
    return new RSVP.Promise(function(resolve, reject){
        if("mParticle" in window){
            const identityCallback = function(result) { 
                if (result.getUser()) { 
                    //proceed with logout 
                    resolve();
                }else{
                    //reject(new Error("Failed logout with mParticle"));
                }
            };
           window.mParticle.Identity.logout({}, identityCallback);
        }else{
            resolve();
        }
    });

}
},


logEvent :function(eventLabel, mParticleEventType, eventDataMap, eventFlagMap){
if(window.waitForCordovaToLoad){
    return new RSVP.Promise(function(resolve, reject){
        if( "mparticle" in window && eventLabel){
            window.mparticle.logEvent(eventLabel,mParticleEventType,eventDataMap? eventDataMap: {},eventFlagMap? eventFlagMap: null);
            resolve();
        }else{
            if(!eventLabel){
                console.error("eventLabel is required to send log event to MParticle");
            }
            resolve();
        }

    });

}else{
    return new RSVP.Promise(function(resolve, reject){
        if( "mParticle" in window && eventLabel){
            window.mParticle.logEvent(eventLabel,mParticleEventType,eventDataMap? eventDataMap: {},eventFlagMap? eventFlagMap: null);
            resolve();
        }else{
            if(!eventLabel){
                console.error("eventLabel is required to send log event to MParticle");
            }
            resolve();
        }

    });
}

},

logPageView :function(eventLabel, eventDataMap, eventFlagMap){
if(window.waitForCordovaToLoad){
    return new RSVP.Promise(function(resolve, reject){
        if( "mparticle" in window && eventLabel){
            window.mparticle.logScreenEvent(eventLabel,eventDataMap? eventDataMap: {},eventFlagMap? eventFlagMap: null);

            resolve();
        }else{
            if(!eventLabel){
                console.error("eventLabel is required to send log page view to MParticle");
            }
            resolve();
        }

    });
}else{
    return new RSVP.Promise(function(resolve, reject){
        if( "mParticle" in window && eventLabel){
            window.mParticle.logPageView(eventLabel,eventDataMap? eventDataMap: {},eventFlagMap? eventFlagMap: null);
            resolve();
        }else{
            if(!eventLabel){
                console.error("eventLabel is required to send log page view to MParticle");
            }
            resolve();
        }

    });
}

},

setUserAttributes : function(keyToValueMap){
if(window.waitForCordovaToLoad){
    return new RSVP.Promise(function(resolve, reject){
        if( "mparticle" in window && keyToValueMap){
            new window.mparticle.Identity().getCurrentUser( function (userID) {
                const currentUser = new window.mparticle.User(userID);
                for(let key in keyToValueMap){
                    const value =  keyToValueMap[key];
                    if(value === null || typeof(value) == "string"){
                        currentUser.setUserAttribute(key, value);
                    }else{
                        if(value instanceof Array){
                            currentUser.setUserAttributeList(key, value);
                        }else{
                            currentUser.setUserAttribute(key, value?value.toString():value);
                        }
                        
                    }
                }
            });
            resolve();
        }else{
            if(!keyToValueMap){
                console.error("keyToValueMap is required to st user attributes via MParticle");
            }
            resolve();
        }
    });
}else{
    return new RSVP.Promise(function(resolve, reject){
        if( "mParticle" in window && keyToValueMap){
            window.mParticle.ready(function(){
                const currentUser = window.mParticle.Identity.getCurrentUser();
                if(currentUser){
                    for(let key in keyToValueMap){
                        const value =  keyToValueMap[key];
                        if(value){
                            if(value === null || typeof(value) == "string"){
                                currentUser.setUserAttribute(key, value);
                            }else{
                                if(value instanceof Array){
                                    currentUser.setUserAttributeList(key, value);
                                }else{
                                    currentUser.setUserAttribute(key, value?value.toString(): value);
                                }
                            }
                        }
                    }
                    resolve();
                }else{
                    //reject(new Error("Failed setting attributes with mParticle"));
                }
            })
        }else{
            if(!keyToValueMap){
                console.error("keyToValueMap is required to st user attributes via MParticle");
            }
            resolve();
        }

    });
}
}

});

    const MparticleHelperInstance = new MparticleHelper();
    MparticleHelperInstance.EventType = {
		Navigation: 1,
		Location: 2,
		Search: 3,
		Transaction: 4,
		UserContent: 5,
		UserPreference: 6,
		Social: 7,
		Other: 8
	}

    export default {
		getInstance : function() {
			return MparticleHelperInstance;
		}
	};