import Backbone from "backbone";
import Utils from "models/helper/Utils";

const MAX_NUMBER_OF_SEQUENCER_NODE_VIEWS = 150;
const USE_PARAGRAPH_SUMMARY_TO_INCREASE_RENDERING_SPEED = false;
const ParagraphContainerView = Backbone.View.extend({



    initialize : function(options) {
        this.model = options? options.model : null;
        this._sequencerNodeViewArray = [];
        this._paragraphSummary = "";
        
    },

    events : {
        "dragover .paragraph_end_delimitation" : "_onDragOverTheEndDelimitation",
        "dragleave .paragraph_end_delimitation" : "_onDragLeaveTheEndDelimitation",
        "drop .paragraph_end_delimitation" : "_onDropAtTheEndOfTheParagraph",
    },

    getMaxNumberOfSequencerNodeViews : function(){
        return MAX_NUMBER_OF_SEQUENCER_NODE_VIEWS;
    },

    getLastSequencerNodeView : function(){
        return this._sequencerNodeViewArray.length >0 ? this._sequencerNodeViewArray[this._sequencerNodeViewArray.length -1]: null;
    },

    getFistSequencerNodeView : function(){
        return this._sequencerNodeViewArray.length >0 ? this._sequencerNodeViewArray[0]: null;
    },

    getParagraphSummary : function(){
        return this._paragraphSummary;
    },

    getModel : function(){
        return this.model;
    },

    showHideParagraphSummary : function(show){
        if(show){
            this.$el.addClass("show_summary");
        }else{
            this.$el.removeClass("show_summary");
        }
    },


    refreshParagraphSummary : function(){
        this._paragraphSummary = this._sequencerNodeViewArray.reduce(function(summary, sequencerNodeView){
            const textContent =  sequencerNodeView.getModel()? sequencerNodeView.getModel().getContent(): null;
            if(textContent){
                return summary  + " " + textContent;
            }else{
                return summary;
            }
        }, "");
        this.getParagraphSummary$el().html(this._paragraphSummary);
    },


    _onDragOverTheEndDelimitation : function(e){
        e.originalEvent.preventDefault();
        this.getEndParagraphDelimitation().addClass("node_drag_over");
        Utils.getInstance().triggerShortVibration();
    },

    _onDragLeaveTheEndDelimitation : function(){
        this.getEndParagraphDelimitation().removeClass("node_drag_over");
    },

    _onDropAtTheEndOfTheParagraph : function(e){
        e.originalEvent.preventDefault();
        this.getEndParagraphDelimitation().removeClass("node_drag_over");
        if(e.originalEvent.dataTransfer.files.length > 0){
            const fileDropInfo  = {"file": e.originalEvent.dataTransfer.files[0], "paragraphContainerView": this};
            this.trigger("fileDroppedAtTheEndOfParagraph", fileDropInfo);
        }else{
            const dropInfo  = {"modelCidOfSequencerNodeBeingDrop": e.originalEvent.dataTransfer.getData("text/plain"), "paragraphContainerView": this};
            this.trigger("sequencerNodeBeingDroppedAtTheEndOfParagraph", dropInfo);
        }
        Utils.getInstance().triggerShortVibration();
    },

    hasReachMaxNumberOfSequencerNodeViews: function(){
        return this._sequencerNodeViewArray.length >= MAX_NUMBER_OF_SEQUENCER_NODE_VIEWS
    },



    canBeAddedBefore : function(nodeViewToAdd, nodeViewAlreadyInserted){
        if(this.hasReachMaxNumberOfSequencerNodeViews() && Utils.getInstance().isWordSequencerNodeInstance(nodeViewToAdd.getModel())){
            return false;
        }
        return true;
    },

    canBeAddedAfter : function(nodeViewToAdd, nodeViewAlreadyInserted){
        if(this.hasReachMaxNumberOfSequencerNodeViews() && Utils.getInstance().isWordSequencerNodeInstance(nodeViewToAdd.getModel())){
            return false;
        }
        return true;
    },

    getParagraphSummary$el : function(){
        return this.$el.find(".paragraph_summary");
    },


    getContentContainer$el : function(){
        return this.$el;
    },

    getStartParagraphDelimitation : function(){
        return this.$el.find(".paragraph_start_delimitation");
    },

    getEndParagraphDelimitation : function(){
        return this.$el.find(".paragraph_end_delimitation");
    },

    getSequencerNodeViewArray: function(){
        return this._sequencerNodeViewArray;
    },

    isEmpty : function(){
        return this._sequencerNodeViewArray.length == 0;
    },

    getSequencerNodeViewIndex : function(sequencerNodeView){
        return this._sequencerNodeViewArray.indexOf(sequencerNodeView);
    },

    removeSequencerNodeView :function(sequencerNodeViewToRemove, keepAllListenersAttached){
        const indexOfSequencerNodeViewToRemove = this._sequencerNodeViewArray.indexOf(sequencerNodeViewToRemove);
        if(sequencerNodeViewToRemove.getParagraphContainerView() === this && indexOfSequencerNodeViewToRemove > -1){
            keepAllListenersAttached? sequencerNodeViewToRemove.$el.detach(): sequencerNodeViewToRemove.$el.remove();
            sequencerNodeViewToRemove.setParagraphContainerView(null);
            this._sequencerNodeViewArray.splice(indexOfSequencerNodeViewToRemove, 1);
            this.onSequencerNodeViewRemoved(sequencerNodeViewToRemove);
            return true;
        }else{
            throw "Oops! this node cannot be removed"
        }
    },

    createNewEmptyParagraphContainerView : function(){
        return new ParagraphContainerView();
    },

    shiftToTheRight : function(paragraphContainerViewOnTheRight, sequencerNodeViewToStartTheShiftFrom){
        const nextSiblings = this.$el.next();
        if(nextSiblings && nextSiblings.length > 0 && nextSiblings.get(0) === paragraphContainerViewOnTheRight.$el.get(0)){
            const indexToShiftFrom = this.getSequencerNodeViewArray().indexOf(sequencerNodeViewToStartTheShiftFrom);
            if(indexToShiftFrom > -1 ){
                //const sequencerNodeArrayToShift = this._sequencerNodeViewArray.splice(indexToShiftFrom, this._sequencerNodeViewArray.length - indexToShiftFrom);
                const sequencerNodeArrayToShift = this._sequencerNodeViewArray.slice(indexToShiftFrom);
                let lastInsertedNodeView =  null;
                for(let i = 0; i < sequencerNodeArrayToShift.length; i++){
                    const sequencerNodeViewToShift = sequencerNodeArrayToShift[i];
                    this.removeSequencerNodeView(sequencerNodeViewToShift, true);
                    if(lastInsertedNodeView){
                        paragraphContainerViewOnTheRight.addSequencerNodeViewBefore(sequencerNodeViewToShift, lastInsertedNodeView);
                    }else{
                        paragraphContainerViewOnTheRight.prependSequencerNodeView(sequencerNodeViewToShift);
                    }
                    
                    /*if(!lastInsertedNodeView){
                        sequencerNodeViewToShift.$el.insertBefore(paragraphContainerViewOnTheRight.getEndParagraphDelimitation())
                    }else{
                        sequencerNodeViewToShift.$el.insertAfter(lastInsertedNodeView.$el);
                    }
                    sequencerNodeViewToShift.setParagraphContainerView(paragraphContainerViewOnTheRight);
                    paragraphContainerViewOnTheRight._sequencerNodeViewArray.push(sequencerNodeViewToShift);
                    this.onSequencerNodeViewRemoved(sequencerNodeViewToShift);
                    paragraphContainerViewOnTheRight.onSequencerNodeViewAdded(sequencerNodeViewToShift);*/
                    lastInsertedNodeView = sequencerNodeViewToShift;
                }
                return true;
            }
        }
        return false;
    },

    isFirstSequencerNodeViewInParagraph : function(sequencerNodeView){
        if(this._sequencerNodeViewArray && this._sequencerNodeViewArray.length > 0){
            return sequencerNodeView === this._sequencerNodeViewArray[0]
        }else{
            return false;
        }
    },
    isLastSequencerNodeViewInParagraph : function(sequencerNodeView){
        if(this._sequencerNodeViewArray && this._sequencerNodeViewArray.length > 0){
            return sequencerNodeView === this._sequencerNodeViewArray[this._sequencerNodeViewArray.length - 1]
        }else{
            return false;
        }
    },

    addSequencerNodeViewAfter : function(sequencerNodeViewToAdd, sequencerNodeViewAfterAlreadyAdded){
        this._throwErrorIfAlreadyAttachToAParagraphContainer(sequencerNodeViewToAdd);
        if(sequencerNodeViewAfterAlreadyAdded && sequencerNodeViewAfterAlreadyAdded.getParagraphContainerView() === this && sequencerNodeViewAfterAlreadyAdded.getParagraphContainerView().canBeAddedBefore(sequencerNodeViewToAdd, sequencerNodeViewAfterAlreadyAdded)){
            const nodeAfterIndex = this._sequencerNodeViewArray.indexOf(sequencerNodeViewAfterAlreadyAdded);
            if(nodeAfterIndex > -1){
                sequencerNodeViewToAdd.$el.insertBefore(sequencerNodeViewAfterAlreadyAdded.$el);
                sequencerNodeViewToAdd.setParagraphContainerView(this);
                this._sequencerNodeViewArray.splice(nodeAfterIndex, 0, sequencerNodeViewToAdd);
                this.onSequencerNodeViewAdded(sequencerNodeViewToAdd);
                return true;
            }
        }
        return false;
    },

    addSequencerNodeViewBefore : function(sequencerNodeViewToAdd, sequencerNodeViewBeforeAlreadyAdded){
        this._throwErrorIfAlreadyAttachToAParagraphContainer(sequencerNodeViewToAdd);
        if(sequencerNodeViewBeforeAlreadyAdded && sequencerNodeViewBeforeAlreadyAdded.getParagraphContainerView() === this && sequencerNodeViewBeforeAlreadyAdded.getParagraphContainerView().canBeAddedAfter(sequencerNodeViewToAdd, sequencerNodeViewBeforeAlreadyAdded)){
            const nodeBeforeIndex = this._sequencerNodeViewArray.indexOf(sequencerNodeViewBeforeAlreadyAdded);
            if(nodeBeforeIndex > -1){
                sequencerNodeViewToAdd.$el.insertAfter(sequencerNodeViewBeforeAlreadyAdded.$el);
                sequencerNodeViewToAdd.setParagraphContainerView(this);
                this._sequencerNodeViewArray.splice(nodeBeforeIndex + 1, 0, sequencerNodeViewToAdd);
                this.onSequencerNodeViewAdded(sequencerNodeViewToAdd);
                return true;
            }
        }
        return false;
    },

    onSequencerNodeViewAdded : function(sequencerNodeViewToAdd){
        if(USE_PARAGRAPH_SUMMARY_TO_INCREASE_RENDERING_SPEED){
        this.refreshParagraphSummary();
        }
    },

    onSequencerNodeViewRemoved : function(sequencerNodeViewToAdd){
        if(USE_PARAGRAPH_SUMMARY_TO_INCREASE_RENDERING_SPEED){
            this.refreshParagraphSummary();
        }
    },

    prependSequencerNodeView : function(sequencerNodeViewToAdd){
        this._throwErrorIfAlreadyAttachToAParagraphContainer(sequencerNodeViewToAdd);
        //this.getContentContainer$el().prepend(sequencerNodeViewToAdd.$el);
        sequencerNodeViewToAdd.$el.insertBefore(this.getEndParagraphDelimitation())
        sequencerNodeViewToAdd.setParagraphContainerView(this);
        this._sequencerNodeViewArray.splice(0, 0, sequencerNodeViewToAdd);
        this.onSequencerNodeViewAdded(sequencerNodeViewToAdd);
    },

    _throwErrorIfAlreadyAttachToAParagraphContainer : function(sequencerNodeViewToAdd){
        if(sequencerNodeViewToAdd.getParagraphContainerView()){
            throw "node already added to a container"
        }
    },

   

    afterRendering:function(){

    },
   
    

});
export default ParagraphContainerView;