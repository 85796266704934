import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utils from "models/helper/Utils";
import ti18n from "i18n!nls/SendCapsuleToUserIntroPagei18n";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";
import SendCapsuleToUserIntroPageView from "views/audioHighlight/SendCapsuleToUserIntroPageView";

const SendCapsuleoUserIntroPageController =  Chaplin.Controller.extend({


    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = null;
        this._pageId = options.pageId;
        this._userId = options.userUniqueId;
        this._username = options.userUsername;
        this._alwaysShowBackButton =  options.alwaysShowBackButton;
                    
    },
    
    getModel : function(){
        return this.model;
    },
    
    getView : function(){
        return this.view;
    },
    
    showCreateCapsulePage : function(){
        //this.view.setCapsuleBeingCreated();
        PersistentModels.getInstance().getRouter().showCreateACapsulePageWithoutContext(this.model.get("id"), this.onCapsuleSuccessfullyCreated.bind(this));
            
        
    },

    onCapsuleSuccessfullyCreated : function(){
        this.view.setCapsuleSuccessfullyCreated();
    },

    retrieveUserInfoModel : function(){
            if(this._userId){
            return TrebbleClientAPIHelper.getInstance().getUserProfileInfoByUserUniqueId(this._userId).then((function(userInfoJson){
                if(userInfoJson){
                    return Utils.getInstance().getModelFromUserJson(userInfoJson);
                }else{
                    return null;
                }
            }).bind(this));
        }else{
            if(this._username){
                return TrebbleClientAPIHelper.getInstance().getUserProfileInfoByUsername(this._username).then((function(userInfoJson){
                if(userInfoJson){
                    return Utils.getInstance().getModelFromUserJson(userInfoJson);
                }else{
                    return null;
                }
            }).bind(this))

            }else{
                return null;
            }
        }
    },


    retrieveUserInfoAndShowIntroMessage : function(){
            return this.retrieveUserInfoModel().then((function(userModel){
                if(userModel){
                    this.model = userModel;
                    this.view.setIntroMessageForUser(userModel);
                }else{
                    window.alertErrorMessage(window.getI18n(ti18n,"RECIPIENT_OF_THE_CAPSULE_COULDNT_BE_FOUND"));
                }
            }).bind(this)).catch((function(error){
                const errorMessage = error? error: window.getI18n(ti18n,"SOMEHTING_WENT_WRONG_WHILE_RETRIEVING_USER_INFO");
                window.alertErrorMessage(errorMessage);
            }).bind(this));
    },



    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
        
                const pageViewParams= {};
                pageViewParams.pageId = this._pageId;
                pageViewParams.alwaysShowBackButton =  this._alwaysShowBackButton;
                this.view = new SendCapsuleToUserIntroPageView(pageViewParams);
                this.view.render();
                this.listenTo(this.view, "showCreateCapsulePage", this.showCreateCapsulePage);
                this.retrieveUserInfoAndShowIntroMessage();
                resolve(this);
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }
    

});
export default SendCapsuleoUserIntroPageController;