import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import Crunker from "libs/crunker/crunker";
import AudioFilterFactory from "models/audioEditor/filters/AudioFilterFactory";
import AudioBufferPlayer from "models/audioEditor/AudioBufferPlayer";
import AudioBufferUtils from "models/audioEditor/AudioBufferUtils";
import FetchRetry from "libs/fetchRetry/fetch-retry.amd";
import Dipper from "libs/soundbankDipper/Dipper";
import LameJS from "libs/lamejs/lame_esm.all";
import RNNoise from "libs/rnnoise/denoise-runtime-helper-wasm";
import unmute from "libs/unmute/unmute";
import PusherHelper from "models/helper/PusherHelper";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";

//"models/helper/WasmWavEncoderHelper",
import RolloutHelper from "models/helper/FeatureRolloutHelper";

import IntegratedLoudnessHelper from "models/audioEditor/IntegratedLoudnessHelper";
import TruePeakHelper from "models/audioEditor/TruePeakHelper";
import Recorder from "libs/browserRecorder/recorderNew";
import Utils from "models/helper/Utils";

//"libs/ffmpeg_wasm/ffmpeg.min",
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util";
import ti18n from "i18n!nls/Sequenceri18n";
import TrebbleNotificationManager from  "components/common/NotificationManager";

import RSVP from "rsvp";
import TrebbleClientAPI from "../helper/TrebbleClientAPI";
const AUDIO_TYPES = {
  VOICE: "voice",
  INSERT: "insert",
  WRAP: "wrap",
  MASTER: "master",
  UNDEFINED: "undefined",
};

const S3_PREFIX_TO_CLOUDFRONT_PREFIX = {
  "https://s3.amazonaws.com/com.trebble.trebble.speechsynthesis": "https://d9cefelymtnnq.cloudfront.net",
  "https://s3.amazonaws.com/com.trebble.tts": "https://d4ewei14y66a3.cloudfront.net",
  "https://s3.amazonaws.com/com.trebble.capsules.replies": "https://d1prtj5fpply6k.cloudfront.net",
  "https://s3.amazonaws.com/com.trebble.songs.hightlights": "https://d8rt3fd3sz1dv.cloudfront.net",
  "https://s3.amazonaws.com/fm.trebble.bigsoundbank": "https://djq8hogjd3s83.cloudfront.net",
  "https://s3.amazonaws.com/com.trebble.trebble.userbackgroundmusic": "https://d3rup12ktram15.cloudfront.net",
  "https://s3.amazonaws.com/com.trebble.capsules.plain": "https://d8rxm027o5hsy.cloudfront.net",
  "https://s3.amazonaws.com/com.trebble.trebble.audio.recordings": "https://d2npgja6i70r1g.cloudfront.net",
  "https://s3.amazonaws.com/com.trebble.trebble.defaultbackgroundmusic": "https://d3qdis5gy6z0kb.cloudfront.net",
};

const fetchWithRetry = FetchRetry(fetch, {
  retries: 5,
  retryOn: [503],
  retryDelay: function (attempt, error, response) {
    return Math.pow(2, attempt) * 1000;
  },
});
const Rollout = RolloutHelper.getInstance();
const DUCKING_DEFAULT_FADE_DURATION_IN_MILLISECONDS = Rollout.getFeatureVariable(
  Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR,
  Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR.variables.default_fade_in_milliseconds_for_ducking,
  0,
);

const NORMALIZE_ALL_AUDIO_BUFFERS = Rollout.getFeatureVariable(
  Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR,
  Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR.variables.normalize_all_audio_buffers,
  true,
);
const APPLY_LOUDNESS_NORMALIZATION_TO_INSERT_SOUND = Rollout.getFeatureVariable(
  Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR,
  Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR.variables.apply_loundness_normalization_to_inserted_sounds,
  true,
);
const INSERT_SOUND_DB_LEVEL =
  -1 *
  Rollout.getFeatureVariable(
    Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR,
    Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR.variables.inserted_sound_db_level,
    18,
  );

const AUDIO_TYPES_TO_NOT_CACHE_IN_FULL = [];
const AUDIO_TYPES_WITH_DEFAULT_EFFECTS_TO_NOT_CACHE_IN_FULL = [];
const AUDIO_TYPES_SLICE_TO_NOT_CACHE = []; // [null, /*AUDIO_TYPES.VOICE,*/ AUDIO_TYPES.INSERT, AUDIO_TYPES.WRAP, AUDIO_TYPES.MASTER];
const AUDIO_TYPES_GENERATED_TO_NOT_CACHE = []; // [null, /*AUDIO_TYPES.VOICE,*/ AUDIO_TYPES.INSERT, AUDIO_TYPES.WRAP, AUDIO_TYPES.MASTER];

const NORMALIZATION_PEAK_TARGET = -1;
const MAX_DURATION_IN_SEC_TO_CACHE = 200; //ten seconds
const APPLY_SEQUENCER_SETTINGS_AT_PLAYBACK = Rollout.getFeatureVariable(
  Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR,
  Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR.variables.apply_sequencer_settings_at_playback,
  true,
);
const MAX_NUMBER_OF_VIDEOS_TO_PROCESS_AT_ONCE_VIA_FFMPEG = 10;
const MAX_NUMBER_OF_VIDEOS_SEGMENTS_TO_PROCESS_AT_ONCE_VIA_FFMPEG = 1000;
const USE_CLOUDFRONT_URLS_INSTEAD_OF_S3_URLS_IN_AUDIO_EDITOR = Rollout.isFeatureEnabled(
  Rollout.FEATURES.USE_CLOUDFRONT_URLS_INSTEAD_OF_S3_URLS_IN_AUDIO_EDITOR,
  true,
);
const EXPORT_MP3_USING_A_WORKER = Rollout.getFeatureVariable(
  Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR,
  Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR.variables.export_mp3_using_a_worker,
  true,
);
const FLUSH_ORGINAL_AUDIO_FILE_AFTER_DENOISE = true; //This is done to keep the memory footprint small and prevent crashing
const MIN_AUDIO_DURATION_TO_FLUSH_AFTER_DENOISE = 60 * 30; // 60min
const USE_RNNOISE_WEB_AUDIO_NODE = window.RNNoiseNode;
const USE_BACKEND_SERVER_FOR_SPEECH_ENHANCEMENT = true;
const AudioBufferFactory = Backbone.Model.extend({
  idAttribute: "id",

  constructor: function (attributes, options) {
    Backbone.Model.apply(this, [attributes, options]);
    this._audioContext = null;
    this._offlineAudioContext = null;
    this._audioUniqueIdToAudioBuffer = {};
    this._audioUniqueIdToAudioBufferWithNoiseCancellation = {};
    this._audioUniqueIdToAudioBufferWithMagicSoundEnhancer = {};
    this._audioUniqueIdSliceToAudioBuffer = {};
    this._audioUniqueIdSliceToAudioBufferWithNoiseCancellation = {};
    this._audioUniqueIdSliceToAudioBufferWithMagicSoundEnhancer = {};
    this._audioUniqueIdGeneratedToAudioBuffer = {};
    this._audioUniqueIdToFetchAudioBufferInProgressPromise = {};
    this._urlToPromiseCachedDownloadedBlobUrl = {};
    this._urlToPromiseCachedDownloadedVideoSize = {};
    this._audioUniqueIdWithAudioTypeToAudioBuffer = {};
    this._sequencerIdToArrayOfAudioInfoLoaded = {};
    this._audioUrlToServerEnhancerAudioFileUrl = {};
    this._audioIdLoadedToSequencers = {};
    this._audioIdToSequencersWaitingForAudioEnhancementCompletion = {};
    this._sequencerIdMapToAudioUrlsToEnhance = {};
    this._sequencerIdToSequencerForWhichEnhancementSettingShouldBeTurnedOn = {};
    try {
      this.listenTo(
        PusherHelper.getInstance(),
        PusherHelper.getInstance().eventTypes.WEB_PUSH_NOTIFICATION,
        this._processNotificationEventWeb.bind(this),
      );
    } catch (e) {
      console.error("Failed to subscribe to web notification in Audio buffer initialization. Error:" + e, e);
    }
  },

  _createContext: function () {
    window.AudioContext = window.AudioContext || window.webkitAudioContext || window.mozAudioContext;
    const contxt = new AudioContext({ sampleRate: 44100 });
    unmute(contxt);
    return contxt;
  },

  _getAudioContext: function () {
    if (!this._audioContext) {
      this._audioContext = this._createContext();
    }
    if (this._audioContext.state === "interrupted" || this._audioContext.state === "suspended") {
      this._audioContext.resume();
      this._audioContext.createGain(); // This is a hack to get the state to be resumed right away instead of waiting from the promise to resolve
      //console.warn("hack to resume audioContext instantly was applied")
    }
    return this._audioContext;
  },

  _getOfflineAudioContext: function () {
    if (!this._offlineAudioContext) {
      this._offlineAudioContext = this._createContext();
    }
    return this._offlineAudioContext;
  },

  _getCrunkerInstance: function () {
    return this._crunkerInstance
      ? this._crunkerInstance
      : new Crunker({ sampleRate: this._getAudioContext().sampleRate });
  },

  _processNotificationEventWeb: async function (event, wasDeferred) {
    const isForeground = true;
    const customPlayloadJson = await TrebbleClientAPIHelper.getInstance().getNotificationCustomPayloadByNotificationId(
      event.notificationId,
    );
    const customPlayloadModel = Utils.getInstance().getModelFromCustomNotificationPayload(customPlayloadJson);
    const notiticationType = customPlayloadModel.getNotificationType();

    if (
      notiticationType == Utils.getInstance().getNotificationTypes().SPEECH_ENHANCEMENT_JOB_COMPLETED ||
      notiticationType == Utils.getInstance().getNotificationTypes().SPEECH_ENHANCEMENT_JOB_FAILED
    ) {
      return this._onSpeechEnhancementProcessCompleted(customPlayloadModel.getNotificationData());
    }
  },

  _onSpeechEnhancementProcessCompleted: function (notificationData) {
    const transcriptionId = notificationData ? notificationData.transcriptionId : null;
    const unenhancedFileUrl = notificationData ? notificationData.unenhancedFileUrl : null;
    const completed = notificationData ? notificationData.completed : null;
    const requestFailed = notificationData ? notificationData.requestFailed : null;
    const enhancedFileUrl = notificationData ? notificationData.enhancedFileUrl : null;

    if (window.trebbleAnalyticsHelper && completed) {
      if (requestFailed) {
        TrebbleNotificationManager.createErrorNotification(unenhancedFileUrl, null, window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_ENHANCING_YOUR_FILE"), { duration: 5 ,darkTheme: false,  placement:"bottomRight"});
        window.trebbleAnalyticsHelper.trackEvent(
          "speech_enhancement",
          "On Speech Enhancement Process Failed",
          "On Speech Enhancement Process Failed",
          null,
          { transcriptionId, unenhancedFileUrl },
        );
      } else {
        TrebbleNotificationManager.createSuccessNotification(unenhancedFileUrl,null, window.getI18n(ti18n, "YAY_YOUR_FILE_HAS_BEEN_ENHANCED")+ " "+ window.getI18n(ti18n, "IT_SHOULD_SOUND_A_LOT_BETTER_NOW"), {duration: 5 ,darkTheme: false,  placement:"bottomRight"});
        this._audioUrlToServerEnhancerAudioFileUrl[unenhancedFileUrl] = enhancedFileUrl;
        this._notifySequencerListeningForSpeechEnhancementCompletion(unenhancedFileUrl,enhancedFileUrl );
        window.trebbleAnalyticsHelper.trackEvent(
          "speech_enhancement",
          "On Speech Enhancement Process Succeeded",
          "On Speech Enhancement Process Succeeded",
          null,
          { transcriptionId, unenhancedFileUrl, enhancedFileUrl },
        );
      }
    }
  },

  resumeAudiContextToKeepHeartbeat: function () {
    try {
      const ctx = this._getAudioContext();
      const ctxWasSuspended = ctx.wasSuspendedBecauseStalledPlayback;
      if (ctxWasSuspended || this._audioContext.state === "interrupted" || this._audioContext.state === "suspended") {
        if (ctxWasSuspended) {
          //console.warn("Resuming context that was suspended because playback stalled");
          ctx.wasSuspendedBecauseStalledPlayback = false;
        }
        const resumePromise = ctx
          .resume()
          .then(function () {
            if (ctxWasSuspended) {
              console.warn("Audio context was successfully resumed because playback stalled");
            } else {
              console.warn("Audio context was successfully resumed");
            }
          })
          .catch(function (error) {
            if (ctxWasSuspended) {
              console.error("Audio context resume because playback stalled failed. Error:" + error);
            } else {
              console.warn("Audio context resume failed. Error" + error);
            }
          });
        ctx.createGain();
        return resumePromise;
      } else {
        console.warn("Audio context already running");
        return RSVP.Promise.resolve();
      }
    } catch (error) {
      console.error("Failed to resume audiContext to keep Heartbeat. Error:" + error);
    }
  },

  _closeAndCreateNextContext: function () {},

  suspendAudioContextInStalledPlayback: function () {
    try {
      const ctx = this._getAudioContext();
      console.warn("Attenpting to suspended audio context because playback stalled");
      const suspendPromise = ctx
        .suspend()
        .then(function () {
          console.warn("Audio context was successfully suspended because playback stalled");
        })
        .catch(function (error) {
          console.error("Audio context suspension because playback stalled failed. Error:" + error);
        });
      ctx.wasSuspendedBecauseStalledPlayback = true;

      return suspendPromise;
    } catch (error) {
      console.error("Failed to suspend audiContext in case of stalled playback. Error:" + error);
    }
  },

  getAudioTypes: function () {
    return AUDIO_TYPES;
  },

  getDefaultNormalizationPeakTargetInDB: function () {
    return NORMALIZATION_PEAK_TARGET;
  },

  isAudioTypeCachingAllowed: function (
    audioTypeToCheck,
    arrayOfAudioTypesNotAllowed,
    resourceIdToCache,
    audioBufferToCache,
  ) {
    if (audioBufferToCache && this.getMaxAudioBufferDuration(audioBufferToCache) > MAX_DURATION_IN_SEC_TO_CACHE) {
      return false;
    }
    const cacheAllowed = !arrayOfAudioTypesNotAllowed || arrayOfAudioTypesNotAllowed.indexOf(audioTypeToCheck) === -1;
    if (!cacheAllowed) {
      //console.warn("The resource "+resourceIdToCache+" with audio type"+audioTypeToCheck+" was not cached");
    }
    return cacheAllowed;
  },

  getEqualPowerCurveIn: function (startVolume, endVolume) {
    const curve = new Float32Array(64);
    const range = endVolume - startVolume;
    curve[0] = startVolume;
    for (let i = 1; i < curve.length; i++)
      curve[i] = startVolume + range * Math.cos((1 - i / (curve.length - 1)) * 0.5 * Math.PI);
    return curve;
  },

  getEqualPowerCurveOut: function (startVolume, endVolume) {
    const curve = new Float32Array(64);
    const range = endVolume - startVolume;
    curve[0] = startVolume;
    for (let i = 1; i < curve.length; i++)
      curve[i] = startVolume + range * Math.cos((1 - i / (curve.length - 1)) * 0.5 * Math.PI);
    return curve;
  },

  _getAudioBufferWithAppliedDefaultEffects: function (
    audioUniqueId,
    orginalAudioBuffer,
    audioType,
    noiseCancellationOn,
    magicSoundEnhancerOn,
    sequencerSettings,
  ) {
    if (orginalAudioBuffer && !APPLY_SEQUENCER_SETTINGS_AT_PLAYBACK) {
      let promise = null;
      if (audioType == this.getAudioTypes().VOICE) {
        promise = this.applyFiltersToAudioBuffer(orginalAudioBuffer, sequencerSettings.getVoiceDefaultFiltersArray());
      }
      if (audioType == this.getAudioTypes().WRAP) {
        promise = this.applyFiltersToAudioBuffer(orginalAudioBuffer, sequencerSettings.getMusicDefaultFiltersArray());
      }
      if (audioType == this.getAudioTypes().INSERT) {
        promise = this.applyFiltersToAudioBuffer(orginalAudioBuffer, sequencerSettings.getSoundDefaultFiltersArray());
      }
      if (audioType == this.getAudioTypes().MASTER) {
        promise = this.applyFiltersToAudioBuffer(orginalAudioBuffer, sequencerSettings.getMasterDefaultFiltersArray());
      }
      if (!promise) {
        return RSVP.Promise.resolve(orginalAudioBuffer);
      } else {
        promise.then(
          function (audioBufferWithDefaultFiltersApplied) {
            const uidWithAudioTypeId = this._getAudioUniqueIdWithAudioTypeId(
              audioUniqueId,
              audioType,
              noiseCancellationOn,
              magicSoundEnhancerOn
            );
            if (
              this.isAudioTypeCachingAllowed(
                audioType,
                AUDIO_TYPES_WITH_DEFAULT_EFFECTS_TO_NOT_CACHE_IN_FULL,
                uidWithAudioTypeId,
              )
            ) {
              this._audioUniqueIdWithAudioTypeToAudioBuffer[uidWithAudioTypeId] = audioBufferWithDefaultFiltersApplied;
            }
            return audioBufferWithDefaultFiltersApplied;
          }.bind(this),
        );
        return promise;
      }
    } else {
      return RSVP.Promise.resolve(orginalAudioBuffer);
    }
  },

  getAudioUniqueIdWithAudioTypeId: function (audioUniqueId, audioType, sequencerSettings) {
    const noiseCancellationOn = audioType == AUDIO_TYPES.VOICE && sequencerSettings.isNoiseCancellationOn();
    const magicSoundEnhancerOn = audioType == AUDIO_TYPES.VOICE && sequencerSettings.isMagicSoundEnhancerOn();
    return this._getAudioUniqueIdWithAudioTypeId(audioUniqueId, audioType, noiseCancellationOn, magicSoundEnhancerOn);
  },

  _getAudioUniqueIdWithAudioTypeId: function (audioUniqueId, audioType, noiseCancellationOn, magicSoundEnhancerOn) {
    if (audioType) {
      if (AUDIO_TYPES.VOICE) {
        return audioUniqueId + "&audioType=" + audioType + "&noiseCancellationOn=" + noiseCancellationOn+"&magicSoundEnhancerOn="+magicSoundEnhancerOn;
      }
      return audioUniqueId + "&audioType=" + audioType;
    } else {
      return audioUniqueId;
    }
  },

  getCloudFrontUrlIfApplicable: function (s3Url) {
    if (s3Url && USE_CLOUDFRONT_URLS_INSTEAD_OF_S3_URLS_IN_AUDIO_EDITOR) {
      for (const s3Prefix in S3_PREFIX_TO_CLOUDFRONT_PREFIX) {
        const cloudfrontPrefix = S3_PREFIX_TO_CLOUDFRONT_PREFIX[s3Prefix];
        if (s3Url.startsWith(s3Prefix)) {
          return s3Url.replace(s3Prefix, cloudfrontPrefix);
        }
      }
    }
    return s3Url;
  },

  fetchFileForFfmpeg: function (blob) {
    return new RSVP.Promise(function (resolve, reject) {
      const fileReader = new FileReader();
      fileReader.onload = function () {
        resolve(new Uint8Array(this.result));
      };
      fileReader.readAsArrayBuffer(blob);
    });
  },

  convertPotentialExponatialNumberToFloatString: function (aNumber, numberOfDecimals) {
    return aNumber.toFixed(numberOfDecimals ? numberOfDecimals : 13);
  },

  renderVideoByConcatenatingVideoUsingFFmpegUsingOverlay: function (
    videoContext,
    audioBlob,
    progressReportFunction,
    srtTextFileContent,
  ) {
    const urlToVideoName = {};
    let numberOfUniqueVideoUrls = 0;
    const c = {};
    const promiseVideoDataFetchesArray = [];
    let audioFileData = null;
    let srtFileData = null;
    const urlToVData = {};
    const audioFilename = "audio.wav";
    const subtitleFilename = "subtitles.srt";
    const renderingStartTimestamp = new Date().getTime();
    const ffmpegProgress = function (p) {
      if (progressReportFunction) {
        progressReportFunction((p.ratio > 1 ? 1 : p.ratio) * 100);
      }
    };
    const ffmpeg = this.createFFmpegInstance();
    videoContext._sourceNodes.map(function (node) {
      if (node._displayName === "VideoNode") {
        const videoUrl = node._element && node._element.src ? node._element.src : node._elementURL;
        if (!Object.prototype.hasOwnProperty.call(urlToVideoName, videoUrl)) {
          numberOfUniqueVideoUrls = numberOfUniqueVideoUrls + 1;
          urlToVideoName[videoUrl] = "video" + numberOfUniqueVideoUrls;
          promiseVideoDataFetchesArray.push(
            fetchFile(videoUrl).then(
              function (d) {
                urlToVData[this.videoUrl] = d;
              }.bind({ videoUrl: videoUrl }),
            ),
          );
        }
      }
    });

    promiseVideoDataFetchesArray.push(
      this.fetchFileForFfmpeg(audioBlob).then(function (d) {
        audioFileData = d;
      }),
    );

    if (srtTextFileContent) {
      promiseVideoDataFetchesArray.push(
        this.fetchFileForFfmpeg(new Blob([srtTextFileContent], { type: "text/plain;charset=utf-8" })).then(function (
          d,
        ) {
          srtFileData = d;
        }),
      );
    }

    return RSVP.Promise.all(promiseVideoDataFetchesArray)
      .then(function () {
        return ffmpeg.load(this.getFFmpegLoadParams(ffmpegProgress, ffmpeg));
      })
      .bind(this)
      .then(
        async function () {
          let numberOfInputFiles = 0;
          await ffmpeg.writeFile(audioFilename, audioFileData);
          if (srtFileData) {
            await ffmpeg.writeFile(subtitleFilename, srtFileData);
          }

          for (const url in urlToVData) {
            await ffmpeg.writeFile(urlToVideoName[url], urlToVData[url]);
          }
          const runParamsArgs = [];
          runParamsArgs.push("-i");
          runParamsArgs.push(audioFilename);

          let trimFilters = `[${numberOfInputFiles}]anull[outa]; `;
          numberOfInputFiles++;

          if (srtFileData) {
            runParamsArgs.push("-i");
            runParamsArgs.push(subtitleFilename);
            numberOfInputFiles++;
          }

          const trimmedInputsArray = [];
          let numberOfOverlayOutputsCreated = 0;
          const sortedSourceNodes = videoContext._sourceNodes.sort(function (nodeA, nodeB) {
            return nodeA._startTime - nodeB.startTime;
          });

          sortedSourceNodes.map(
            function (node, index) {
              const videoUrl = node._element && node._element.src ? node._element.src : node._elementURL;
              const duration = node._stopTime - node._startTime;
              const videoName = urlToVideoName[videoUrl];
              runParamsArgs.push("-i");
              runParamsArgs.push(videoName);
              trimFilters =
                trimFilters +
                `[${numberOfInputFiles}:v]trim=start=${
                  node._sourceOffset
                }:duration=${this.convertPotentialExponatialNumberToFloatString(duration)},setpts=PTS-STARTPTS${
                  ",tpad=start_duration=" + this.convertPotentialExponatialNumberToFloatString(node._startTime)
                }:color=black@0.0[trimmed${numberOfInputFiles}]; `;
              trimmedInputsArray.push(`trimmed${numberOfInputFiles}`);
              numberOfInputFiles++;
            }.bind(this),
          );

          let overlayCommand = "";
          let lastCreatedOverlayOutput = trimmedInputsArray[0];
          if (trimmedInputsArray.length > 1) {
            for (let i = 1; i < trimmedInputsArray.length; i++) {
              const trimmedInput = trimmedInputsArray[i];
              overlayCommand =
                overlayCommand +
                `[${trimmedInput}][${lastCreatedOverlayOutput}]overlay=0:0:eof_action=pass[overlayOutput${numberOfOverlayOutputsCreated}]${
                  i + 1 === trimmedInputsArray.length ? "; " : ", "
                }`;
              lastCreatedOverlayOutput = `overlayOutput${numberOfOverlayOutputsCreated}`;
              numberOfOverlayOutputsCreated++;
            }
          }

          const renameLastOverlayOutput = `[${lastCreatedOverlayOutput}]null[outv]`;
          runParamsArgs.push("-filter_complex");
          runParamsArgs.push(trimFilters + overlayCommand + renameLastOverlayOutput);
          runParamsArgs.push("-c:v");
          runParamsArgs.push("libx264");
          runParamsArgs.push("-c:a");
          runParamsArgs.push("aac");
          if (srtFileData) {
            runParamsArgs.push("-c:s");
            runParamsArgs.push("mov_text");
          }
          runParamsArgs.push("-q:v");
          runParamsArgs.push("1");
          runParamsArgs.push("-map");
          runParamsArgs.push(`[outv]`);
          runParamsArgs.push("-map");
          runParamsArgs.push("[outa]");
          if (srtFileData) {
            runParamsArgs.push("-map");
            runParamsArgs.push("1");
          }
          runParamsArgs.push("-strict");
          runParamsArgs.push("experimental");
          runParamsArgs.push("output.mp4");
          if (window.trebbleAnalyticsHelper) {
            window.trebbleAnalyticsHelper.trackEvent(
              "Rendering video using FFmpeg",
              "Rendering video using FFmpeg",
              "Rendering video using FFmpeg",
              null,
              { params: runParamsArgs.join(" ") },
            );
          }
          return ffmpeg.exec(runParamsArgs);
        }.bind(this),
      )
      .then(async function () {
        const data = await ffmpeg.readFile("output.mp4");
        await ffmpeg.deleteFile(audioFilename);
        if (srtFileData) {
          await ffmpeg.deleteFile(subtitleFilename);
        }
        for (const videoUrl in urlToVData) {
          const videoName = urlToVideoName[videoUrl];
          await ffmpeg.deleteFile(videoName);
        }
        await ffmpeg.terminate();
        if (window.trebbleAnalyticsHelper) {
          window.trebbleAnalyticsHelper.trackTiming(
            "Time to merge audio and video in mp4 using FFmpeg",
            new Date().getTime() - renderingStartTimestamp,
          );
        }
        return new Blob([data.buffer], { type: "video/mp4" });
      })
      .catch(function (error) {
        console.error(error);
        throw error;
      });
  },

  renderVideoByConcatenatingVideoUsingFFmpegUsingOverlay2: function (
    videoFilesToConcatenate,
    audioBlob,
    progressReportFunction,
    srtTextFileContent,
  ) {
    const urlToVideoName = {};
    const urlToInputIndex = {};
    let numberOfUniqueVideoUrls = 0;
    const c = {};
    const promiseVideoDataFetchesArray = [];
    let audioFileData = null;
    let srtFileData = null;
    const urlToVData = {};
    const audioFilename = "audio.wav";
    const subtitleFilename = "subtitles.srt";
    const renderingStartTimestamp = new Date().getTime();
    const preCalculatedVideoDuration = this.calculateVideoDurationFromVideFileInfoArray(videoFilesToConcatenate);
    const ffmpegProgress = function (p) {
      if (progressReportFunction) {
        if (preCalculatedVideoDuration && p.time) {
          progressReportFunction((p.time / 1000000 / preCalculatedVideoDuration) * 100);
        } else {
          progressReportFunction((p.ratio > 1 ? 1 : p.ratio) * 100);
        }
      }
    };
    const ffmpeg = this.createFFmpegInstance();
    videoFilesToConcatenate.map(
      function (fileInfo) {
        const videoUrl = fileInfo.url;
        if (!Object.prototype.hasOwnProperty.call(urlToVideoName, videoUrl)) {
          numberOfUniqueVideoUrls = numberOfUniqueVideoUrls + 1;
          urlToVideoName[videoUrl] = "video" + numberOfUniqueVideoUrls;
          promiseVideoDataFetchesArray.push(
            fetchFile(videoUrl).then(
              function (d) {
                urlToVData[this.videoUrl] = d;
              }.bind({ videoUrl: videoUrl }),
            ),
          );
        }
      }.bind(this),
    );
    if (audioBlob) {
      promiseVideoDataFetchesArray.push(
        this.fetchFileForFfmpeg(audioBlob).then(function (d) {
          audioFileData = d;
        }),
      );
    }
    if (srtTextFileContent) {
      promiseVideoDataFetchesArray.push(
        this.fetchFileForFfmpeg(new Blob([srtTextFileContent], { type: "text/plain;charset=utf-8" })).then(function (
          d,
        ) {
          srtFileData = d;
        }),
      );
    }
    return RSVP.Promise.all(promiseVideoDataFetchesArray)
      .then(
        function () {
          return ffmpeg.load(this.getFFmpegLoadParams(ffmpegProgress, ffmpeg));
        }.bind(this),
      )
      .then(
        async function () {
          let numberOfInputFiles = 0;
          let numberOfTrimmedFiles = 0;
          if (audioFileData) {
            await ffmpeg.writeFile(audioFilename, audioFileData);
          }
          if (srtFileData) {
            await ffmpeg.writeFile(subtitleFilename, srtFileData);
          }

          for (const url in urlToVData) {
            await ffmpeg.writeFile(urlToVideoName[url], urlToVData[url]);
          }
          const runParamsArgs = [];
          let trimFilters = "";
          if (audioFileData) {
            runParamsArgs.push("-i");
            runParamsArgs.push(audioFilename);
            trimFilters = trimFilters + `[${numberOfInputFiles}]anull[outa]; `;
            numberOfInputFiles++;
          }

          if (srtFileData) {
            runParamsArgs.push("-i");
            runParamsArgs.push(subtitleFilename);
            numberOfInputFiles++;
          }

          const trimmedInputsArray = [];
          let numberOfOverlayOutputsCreated = 0;

          videoFilesToConcatenate.map(
            function (videoFileInfo, index) {
              const videoUrl = videoFileInfo.url;
              const duration = videoFileInfo.duration;
              const videoName = urlToVideoName[videoUrl];
              const tPadding = videoFileInfo.startPadding;
              const startOffset = videoFileInfo.startOffset;
              const offset = videoFileInfo.offset;
              if (!Object.prototype.hasOwnProperty.call(urlToInputIndex, videoUrl)) {
                urlToInputIndex[videoUrl] = numberOfInputFiles;
                numberOfInputFiles++;
                runParamsArgs.push("-i");
                runParamsArgs.push(videoName);
              }
              const fileInputIndex = urlToInputIndex[videoUrl];

              if (duration) {
                trimFilters =
                  trimFilters +
                  `[${
                    urlToInputIndex[videoUrl]
                  }:v]trim=start=${startOffset}:duration=${this.convertPotentialExponatialNumberToFloatString(
                    duration,
                  )},setpts=PTS-STARTPTS${
                    offset
                      ? ",tpad=start_duration=" +
                        this.convertPotentialExponatialNumberToFloatString(offset) +
                        ":color=black@0.0"
                      : ""
                  }[trimmed${numberOfTrimmedFiles}]; `;
              } else {
                trimFilters = trimFilters + `[${urlToInputIndex[videoUrl]}:v]null[trimmed${numberOfTrimmedFiles}]; `;
              }
              trimmedInputsArray.push(`trimmed${numberOfTrimmedFiles}`);
              //concatFilterInputs = concatFilterInputs +`[trimmed${numberOfTrimmedFiles}]`;
              numberOfTrimmedFiles++;
            }.bind(this),
          );

          let overlayCommand = "";
          let lastCreatedOverlayOutput = trimmedInputsArray[0];
          if (trimmedInputsArray.length > 1) {
            for (let i = 1; i < trimmedInputsArray.length; i++) {
              const trimmedInput = trimmedInputsArray[i];
              overlayCommand =
                overlayCommand +
                `[${trimmedInput}][${lastCreatedOverlayOutput}]overlay=0:0:eof_action=pass[overlayOutput${numberOfOverlayOutputsCreated}]${
                  i + 1 === trimmedInputsArray.length ? "; " : ", "
                }`;
              lastCreatedOverlayOutput = `overlayOutput${numberOfOverlayOutputsCreated}`;
              numberOfOverlayOutputsCreated++;
            }
          }

          const renameLastOverlayOutput = `[${lastCreatedOverlayOutput}]null[outv]`;
          runParamsArgs.push("-filter_complex");
          runParamsArgs.push(trimFilters + overlayCommand + renameLastOverlayOutput);
          runParamsArgs.push("-c:v");
          runParamsArgs.push("libx264");
          if (audioFileData) {
            runParamsArgs.push("-c:a");
            runParamsArgs.push("aac");
          }
          if (srtFileData) {
            runParamsArgs.push("-c:s");
            runParamsArgs.push("mov_text");
          }
          runParamsArgs.push("-q:v");
          runParamsArgs.push("1");
          runParamsArgs.push("-map");
          runParamsArgs.push(`[outv]`);
          if (audioFileData) {
            runParamsArgs.push("-map");
            runParamsArgs.push("[outa]");
          }
          if (srtFileData) {
            runParamsArgs.push("-map");
            runParamsArgs.push("1");
          }
          runParamsArgs.push("-strict");
          runParamsArgs.push("experimental");
          runParamsArgs.push("output.mp4");
          if (window.trebbleAnalyticsHelper) {
            window.trebbleAnalyticsHelper.trackEvent(
              "Rendering video using FFmpeg",
              "Rendering video using FFmpeg",
              "Rendering video using FFmpeg",
              null,
              { params: runParamsArgs.join(" ") },
            );
          }
          return ffmpeg.exec(runParamsArgs);
        }.bind(this),
      )
      .then(async function () {
        const data = await ffmpeg.readFile("output.mp4");
        if (audioFileData) {
          await ffmpeg.deleteFile(audioFilename);
        }
        if (srtFileData) {
          await ffmpeg.deleteFile(subtitleFilename);
        }
        for (const videoUrl in urlToVData) {
          const videoName = urlToVideoName[videoUrl];
          await ffmpeg.deleteFile(videoName);
        }
        await ffmpeg.terminate();
        if (window.trebbleAnalyticsHelper) {
          window.trebbleAnalyticsHelper.trackTiming(
            "Time to merge audio and video in mp4 using FFmpeg",
            new Date().getTime() - renderingStartTimestamp,
          );
        }
        return new Blob([data.buffer], { type: "video/mp4" });
      })
      .catch(function (error) {
        console.error(error);
        throw error;
      });
  },

  formatTime: function (timeInMilliseconds) {
    const asVtt = false;
    function padString(string, length) {
      return (new Array(length + 1).join("0") + string).slice(-length);
    }
    let seconds = timeInMilliseconds / 1000;
    let hours = 0;
    let minutes = 0;
    hours = Math.floor(seconds / 3600);
    seconds = seconds - hours * 3600;
    minutes = Math.floor(seconds / 60);
    seconds = (seconds - minutes * 60).toFixed(3);
    const response =
      padString(hours, 2) +
      ":" +
      padString(minutes, 2) +
      ":" +
      (asVtt ? padString(seconds, 6) : padString(seconds, 6).replace(".", ","));
    return response;
  },

  renderVideoFileInBatchIfApplicable: function (
    mergedNodeSchedulingInfoArray,
    audioBlob,
    progressReportFunction,
    srtTextFileContent,
  ) {
    const creationParamsInfo = this.createFFMPEGParamsToRenderVideoByContatenation(
      mergedNodeSchedulingInfoArray,
      URL.createObjectURL(audioBlob),
      null,
    );
    const videoFilesToConcatenate = creationParamsInfo.videoFilesToConcatenate;
    const fileInputsArray = creationParamsInfo.fileInputsArray;
    if (
      fileInputsArray.length < MAX_NUMBER_OF_VIDEOS_TO_PROCESS_AT_ONCE_VIA_FFMPEG ||
      videoFilesToConcatenate.length < MAX_NUMBER_OF_VIDEOS_SEGMENTS_TO_PROCESS_AT_ONCE_VIA_FFMPEG
    ) {
      return this.concatenateVideoUsingFFmpeg(
        videoFilesToConcatenate,
        audioBlob,
        progressReportFunction,
        srtTextFileContent,
      );
    } else {
      return this.renderVideoFileInSeries(
        videoFilesToConcatenate,
        audioBlob,
        progressReportFunction,
        srtTextFileContent,
        0,
        [],
      );
    }
  },

  renderVideoFileInSeries: function (
    videoFilesToConcatenate,
    audioBlob,
    progressReportFunction,
    srtTextFileContent,
    videoFileIndexToStartFrom,
    arrayOfVideoBlobToRendered,
  ) {
    let partialProgressFunction;
    if (videoFileIndexToStartFrom < videoFilesToConcatenate.length) {
      const videoFilesToConcatenateSlice = videoFilesToConcatenate.slice(
        videoFileIndexToStartFrom,
        videoFileIndexToStartFrom + MAX_NUMBER_OF_VIDEOS_SEGMENTS_TO_PROCESS_AT_ONCE_VIA_FFMPEG,
      );
      partialProgressFunction = function (progress) {
        if (progressReportFunction) {
          const calculatedProgress =
            ((videoFileIndexToStartFrom + (videoFilesToConcatenateSlice.length * progress) / 100) * 75) /
            videoFilesToConcatenate.length;
          progressReportFunction(calculatedProgress);
        }
      };
      return this.concatenateVideoUsingFFmpeg(videoFilesToConcatenateSlice, null, partialProgressFunction, null).then(
        function (blobCreated) {
          arrayOfVideoBlobToRendered.push(blobCreated);
          return this.renderVideoFileInSeries(
            videoFilesToConcatenate,
            audioBlob,
            progressReportFunction,
            srtTextFileContent,
            videoFileIndexToStartFrom + videoFilesToConcatenateSlice.length,
            arrayOfVideoBlobToRendered,
          );
        }.bind(this),
      );
    } else {
      let videoFilesToConcatenateWithNoDuration = arrayOfVideoBlobToRendered.map(function (blob) {
        return { url: URL.createObjectURL(blob) };
      });
      if (videoFilesToConcatenateWithNoDuration.length < MAX_NUMBER_OF_VIDEOS_SEGMENTS_TO_PROCESS_AT_ONCE_VIA_FFMPEG) {
        let partialProgressFunction = function (progress) {
          if (progressReportFunction) {
            const calculatedProgress = 85 + (progress * 15) / 100;
            progressReportFunction(calculatedProgress);
          }
        };
        return this.concatenateVideoUsingFFmpeg(
          videoFilesToConcatenateWithNoDuration,
          audioBlob,
          partialProgressFunction,
          srtTextFileContent,
        );
      } else {
        partialProgressFunction = function (progress) {
          if (progressReportFunction) {
            const calculatedProgress = 75 + (progress * 10) / 100;
            progressReportFunction(calculatedProgress);
          }
        };
        return this.renderVideoFileInSeries(
          videoFilesToConcatenateWithNoDuration,
          audioBlob,
          partialProgressFunction,
          srtTextFileContent,
          0,
          [],
        );
      }
    }
  },

  _extractPlayInfoInSecondsFromMergeSchedulingNodeWithoutCrossfade: function (mergeNode) {
    const startTimeOffsetForSourceBuffer =
      (mergeNode.getStartTimeOffsetForSourceBuffer() - mergeNode.getRelativeStartTime()) / 1000;
    const bufferNodeDuration =
      (mergeNode.getDuration() < mergeNode.getAudioBufferPlaybackDuration()
        ? mergeNode.getDuration() / 1000
        : mergeNode.getAudioBufferPlaybackDuration() / 1000) +
      (mergeNode.getRelativeEndTime() + mergeNode.getRelativeStartTime()) / 1000;
    const startTime = (mergeNode.getStartTimeOffset() - mergeNode.getRelativeStartTime()) / 1000;
    const endTime = startTime + bufferNodeDuration;
    return {
      startTimeOffsetForSourceBuffer: startTimeOffsetForSourceBuffer,
      bufferNodeDuration: bufferNodeDuration,
      startTime: startTime,
      endTime: endTime,
    };
  },

   secondsToTime: function(seconds) {
    const date = new Date(null);
    date.setSeconds(seconds);
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const secondsStr = date.getUTCSeconds().toString().padStart(2, '0');
    const millisecondsStr = date.getUTCMilliseconds().toString().padStart(3, '0');
    return `${hours}:${minutes}:${secondsStr}.${millisecondsStr}`;
},


  createFFMPEGParamsToRenderVideoByContatenation: function (mergedNodeSchedulingInfoArray, audioUrl, srtFileUrl, useAudioSegment,  audioStartTimeInSeconds, audioEndTimeInSeconds ) {
    const audioFilename = "audio.wav";
    const subtitleFilename = "subtitles.srt";
    const outputFilename = "output.mp4";
    const urlToVideoName = {};
    const urlToInputIndex = {};

    //Preparing Inputs
    const runParamsArgs = [];
    let numberOfInputFiles = 0;
    const DEFAULT_VIDEO_FRAME_RATE = 25;
    let forced_key_frame_params = null;
    let numberOfTrimmedInput = 0;
    const createFileInfo = function (url, name) {
      return { url: url, name: name };
    };
    const fileInputsArray = [];
    let audioFileInputIndex = -1;
    if(audioUrl){
      fileInputsArray.push(createFileInfo(audioUrl, audioFilename));
      /*if(useAudioSegment){
        runParamsArgs.push("-ss");
        runParamsArgs.push(this.secondsToTime(audioStartTimeInSeconds? audioStartTimeInSeconds: 0));
      }*/
      runParamsArgs.push("-i");
      runParamsArgs.push(audioFilename);
      /*if(useAudioSegment){
        runParamsArgs.push("-to");
        runParamsArgs.push(this.secondsToTime(audioEndTimeInSeconds? audioEndTimeInSeconds: 0));
      }*/
      audioFileInputIndex = numberOfInputFiles;
      numberOfInputFiles++;
    }

    let concatFilterInputs = "";
    const TIME_ADJUSTMENT_TO_FORCE_KEY_FRAME_MATCH = 0.00001;

    let trimFilters = "";

    if (srtFileUrl) {
      fileInputsArray.push(createFileInfo(srtFileUrl, subtitleFilename));
      runParamsArgs.push("-i");
      runParamsArgs.push(subtitleFilename);
      numberOfInputFiles++;
    }

    const sortedVideoMergedNodeSchedulingInfoArray = mergedNodeSchedulingInfoArray
      .filter(function (mergeNode) {
        return mergeNode.isSequencerNodeReferenceHasVideo();
      })
      .sort(function (nodeA, nodeB) {
        return nodeA.getStartTimeOffset() - nodeB.getStartTimeOffset();
      });
    const videoFilesToConcatenate = [];

    let totalDuration = 0;
    sortedVideoMergedNodeSchedulingInfoArray.map(
      function (mergeVideoNode, index) {
        let videoUrl = mergeVideoNode.getAudioSegmentMediaUrl();
        let duration = mergeVideoNode.getDuration() / 1000;
        let videoName = urlToVideoName[videoUrl];
        if (!videoName) {
          videoName = "videoInput" + index;
          urlToVideoName[videoUrl] = videoName;
          urlToInputIndex[videoUrl] = numberOfInputFiles;
          fileInputsArray.push(createFileInfo(videoUrl, videoName));
          runParamsArgs.push("-i");
          runParamsArgs.push(videoName);
          numberOfInputFiles++;
        }

        let tPadding = 0;
        let durationAdjustement = 0;
        let currentVideoPlaybackInfo =
          this._extractPlayInfoInSecondsFromMergeSchedulingNodeWithoutCrossfade(mergeVideoNode);
        const startTimeOffsetForSourceBuffer = currentVideoPlaybackInfo.startTimeOffsetForSourceBuffer;
        const bufferNodeDuration = currentVideoPlaybackInfo.bufferNodeDuration;
        if (index > 0) {
          const previousVideoPlaybackInfo = this._extractPlayInfoInSecondsFromMergeSchedulingNodeWithoutCrossfade(
            sortedVideoMergedNodeSchedulingInfoArray[index - 1],
          );
          const previousVideoEndTime = previousVideoPlaybackInfo.endTime;
          if (previousVideoEndTime < currentVideoPlaybackInfo.startTime) {
            tPadding = currentVideoPlaybackInfo.startTime - previousVideoEndTime;
          }
        }
        if (index + 1 < sortedVideoMergedNodeSchedulingInfoArray.length) {
          const nextVideoPlaybackInfo = this._extractPlayInfoInSecondsFromMergeSchedulingNodeWithoutCrossfade(
            sortedVideoMergedNodeSchedulingInfoArray[index + 1],
          );
          const nextVideoStartTime = nextVideoPlaybackInfo.startTime;
          if (nextVideoStartTime < currentVideoPlaybackInfo.endTime) {
            durationAdjustement = currentVideoPlaybackInfo.endTime - nextVideoStartTime;
          }
        }
        const trimmedAudioOutputSegmentName = `[trimmedAudio${numberOfTrimmedInput}]`;
        trimFilters =
          trimFilters +
          `[${
            urlToInputIndex[videoUrl]
          }:v]trim=start=${startTimeOffsetForSourceBuffer}:duration=${this.convertPotentialExponatialNumberToFloatString(
            bufferNodeDuration - durationAdjustement - TIME_ADJUSTMENT_TO_FORCE_KEY_FRAME_MATCH,
          )},setpts='if(lt(PTS,0),PREV_OUTPTS+1/(2*FR)/TB,PTS)',setpts=PTS-STARTPTS${
            tPadding && tPadding > TIME_ADJUSTMENT_TO_FORCE_KEY_FRAME_MATCH
              ? ",tpad=start_duration=" +
                this.convertPotentialExponatialNumberToFloatString(tPadding - TIME_ADJUSTMENT_TO_FORCE_KEY_FRAME_MATCH)
              : ""
          }[trimmed${numberOfTrimmedInput}]; `;
          if(audioUrl){
            trimFilters =
              trimFilters +
              `[${audioFileInputIndex}:a]atrim=start=${useAudioSegment?audioStartTimeInSeconds+ totalDuration: totalDuration}:duration=${useAudioSegment?this.convertPotentialExponatialNumberToFloatString(
                bufferNodeDuration - durationAdjustement,
              ): this.convertPotentialExponatialNumberToFloatString(
                bufferNodeDuration - durationAdjustement,
              )},asetpts='if(lt(PTS,0),PREV_OUTPTS+S-1,PTS)',asetpts=PTS-STARTPTS${
                tPadding ? ",adelay=delays=" + tPadding * 1000 + ":all=1" : ""
              }${trimmedAudioOutputSegmentName};`;
        }
        concatFilterInputs = concatFilterInputs + `[trimmed${numberOfTrimmedInput}]`;
        if(audioUrl){
          concatFilterInputs = concatFilterInputs + `${trimmedAudioOutputSegmentName}`;
        }
        videoFilesToConcatenate.push({
          url: videoUrl,
          offset: totalDuration,
          startOffset: startTimeOffsetForSourceBuffer,
          duration: bufferNodeDuration - durationAdjustement,
          startPadding: tPadding,
        });
        totalDuration = totalDuration + videoFilesToConcatenate[videoFilesToConcatenate.length - 1].duration;
        numberOfTrimmedInput++;
      }.bind(this),
    );

    //Preparing Filters
    runParamsArgs.push("-filter_complex");
    const concatParams =
      trimFilters +
      concatFilterInputs +
      "concat=n=" +
      (sortedVideoMergedNodeSchedulingInfoArray.length + 0) +
      `:v=1${audioUrl?":a=1":""}[outv]${audioUrl?"[outaudio]":""}`;
    runParamsArgs.push(concatParams);

    //Preparing output
    runParamsArgs.push("-c:v");
    runParamsArgs.push("libx264");
    if(audioUrl){
      runParamsArgs.push("-c:a");
      runParamsArgs.push("aac");
      runParamsArgs.push("-b:a");
      runParamsArgs.push("320k");
    }
    if (srtFileUrl) {
      runParamsArgs.push("-c:s");
      runParamsArgs.push("mov_text");
    }
    runParamsArgs.push("-map");
    runParamsArgs.push("[outv]");
    if(audioUrl){
      runParamsArgs.push("-map");
      runParamsArgs.push("[outaudio]");
    }
    if (srtFileUrl) {
      runParamsArgs.push("-map");
      runParamsArgs.push("1");
    }
    runParamsArgs.push("-crf");
    runParamsArgs.push("18");
    runParamsArgs.push("-preset");
    runParamsArgs.push("medium");
    runParamsArgs.push(outputFilename);

    //Preparing results
    const ffmpegParams = {};
    ffmpegParams.outputFileMimeType = "video/mp4";
    ffmpegParams.fileInputsArray = fileInputsArray;
    ffmpegParams.outputFilename = outputFilename;
    ffmpegParams.arrayOfCommands = runParamsArgs;
    ffmpegParams.videoFilesToConcatenate = videoFilesToConcatenate;
    return ffmpegParams;
  },

  changeVideoFramerate: function (videoUrl, newFramerate, progressReportFunction, forced_key_frame_array) {
    const ffmpegProgress = function (p) {
      if (progressReportFunction) {
        progressReportFunction((p.ratio > 1 ? 1 : p.ratio) * 100);
      }
    };

    const forced_key_frame_params = forced_key_frame_array.join(",");
    let videoData = null;
    const ffmpeg = this.createFFmpegInstance();
    return fetchFile(videoUrl)
      .then(
        function (d) {
          videoData = d;
          return ffmpeg.load(this.getFFmpegLoadParams(ffmpegProgress, ffmpeg));
        }.bind(this),
      )
      .bind(this)
      .then(
        async function () {
          await ffmpeg.writeFile("videoToReframe", videoData);
          const runParamsArgs = [];
          runParamsArgs.push("-i");
          runParamsArgs.push("videoToReframe");
          runParamsArgs.push("-r");
          runParamsArgs.push("" + newFramerate);
          if (forced_key_frame_params) {
            runParamsArgs.push("-force_key_frames");
            runParamsArgs.push(forced_key_frame_params);
          }
          runParamsArgs.push("output.mp4");
          return ffmpeg.exec(runParamsArgs);
        }.bind(this),
      )
      .then(async function () {
        const data = await ffmpeg.readFile("output.mp4");

        await ffmpeg.deleteFile("videoToReframe");

        await ffmpeg.terminate();

        return new Blob([data.buffer], { type: "video/mp4" });
      })
      .catch(function (error) {
        console.error(error);
        throw error;
      });
  },

  calculateVideoDurationFromVideFileInfoArray: function (videoFilesToConcatenate) {
    return videoFilesToConcatenate.reduce(function (totalDuration, videoFileInfo) {
      const duration = videoFileInfo.duration;
      const tPadding = videoFileInfo.startPadding;
      return totalDuration + duration + tPadding;
    }, 0);
  },

  concatenateVideoUsingFFmpeg: function (
    videoFilesToConcatenate,
    audioBlob,
    progressReportFunction,
    srtTextFileContent,
  ) {
    //audioBlob = null;
    //srtTextFileContent = null;
    const urlToVideoName = {};
    const urlToInputIndex = {};
    let numberOfUniqueVideoUrls = 0;
    const c = {};
    const DEFAULT_VIDEO_FRAME_RATE = 25;
    const promiseVideoDataFetchesArray = [];
    let audioFileData = null;
    let srtFileData = null;
    const urlToVData = {};
    const audioFilename = "audio.wav";
    const subtitleFilename = "subtitles.srt";
    const TIME_ADJUSTMENT_TO_FORCE_KEY_FRAME_MATCH = 0.00001;
    let audioInputIndex = null;
    const renderingStartTimestamp = new Date().getTime();
    const preCalculatedVideoDuration = this.calculateVideoDurationFromVideFileInfoArray(videoFilesToConcatenate);
    const ffmpegProgress = function (p) {
      if (progressReportFunction) {
        if (preCalculatedVideoDuration && p.time) {
          progressReportFunction((p.time / 1000000 / preCalculatedVideoDuration) * 100);
        } else {
          progressReportFunction((p.ratio > 1 ? 1 : p.ratio) * 100);
        }
      }
    };
    const getFrameAdjustedDuration = function (duration, customFrameRate) {
      if (!customFrameRate) {
        customFrameRate = DEFAULT_VIDEO_FRAME_RATE;
      }
      return (Math.floor(duration / (1 / customFrameRate)) * 1) / customFrameRate;
    };

    const getDurationLeftAfterAdjustment = function (duration, customFrameRate) {
      if (!customFrameRate) {
        customFrameRate = DEFAULT_VIDEO_FRAME_RATE;
      }
      return duration - getFrameAdjustedDuration(duration, customFrameRate);
    };
    const ffmpeg = this.createFFmpegInstance();

    const videoUrl_to_forced_key_frame_array = {};
    videoFilesToConcatenate.map(
      function (videoFileInfo) {
        const videoUrl = videoFileInfo.url;
        if (!Object.prototype.hasOwnProperty.call(videoUrl_to_forced_key_frame_array, videoUrl)) {
          videoUrl_to_forced_key_frame_array[videoUrl] = [];
        }
        const forced_key_frame_array = videoUrl_to_forced_key_frame_array[videoUrl];
        const duration = videoFileInfo.duration;
        const startOffset = videoFileInfo.startOffset;
        const durationFrameAdjusted = getFrameAdjustedDuration(duration, DEFAULT_VIDEO_FRAME_RATE);
        if (durationFrameAdjusted && !forced_key_frame_array.includes(durationFrameAdjusted)) {
          forced_key_frame_array.push(durationFrameAdjusted);
        }
        if (startOffset && !forced_key_frame_array.includes(startOffset)) {
          forced_key_frame_array.push(startOffset);
        }
      }.bind(this),
    );

    videoFilesToConcatenate.map(
      function (fileInfo) {
        const videoUrl = fileInfo.url;
        if (!Object.prototype.hasOwnProperty.call(urlToVideoName, videoUrl)) {
          numberOfUniqueVideoUrls = numberOfUniqueVideoUrls + 1;
          urlToVideoName[videoUrl] = "video" + numberOfUniqueVideoUrls;
          /*promiseVideoDataFetchesArray.push(this.changeVideoFramerate(videoUrl, DEFAULT_VIDEO_FRAME_RATE, null, videoUrl_to_forced_key_frame_array[videoUrl]).then(this.fetchFileForFfmpeg).then((function(d){
                                          urlToVData[this.videoUrl] = d;
                                      }).bind({"videoUrl":videoUrl})))*/
          promiseVideoDataFetchesArray.push(
            fetchFile(videoUrl).then(
              function (d) {
                urlToVData[this.videoUrl] = d;
              }.bind({ videoUrl: videoUrl }),
            ),
          );
        }
      }.bind(this),
    );
    if (audioBlob) {
      promiseVideoDataFetchesArray.push(
        this.fetchFileForFfmpeg(audioBlob).then(function (d) {
          audioFileData = d;
        }),
      );
    }
    if (srtTextFileContent) {
      promiseVideoDataFetchesArray.push(
        this.fetchFileForFfmpeg(new Blob([srtTextFileContent], { type: "text/plain;charset=utf-8" })).then(function (
          d,
        ) {
          srtFileData = d;
        }),
      );
    }
    return RSVP.Promise.all(promiseVideoDataFetchesArray)
      .then(() => {
        return ffmpeg.load(this.getFFmpegLoadParams(ffmpegProgress, ffmpeg));
      })
      .then(
        async function () {
          let numberOfInputFiles = 0;
          let numberOfTrimmedFiles = 0;
          if (audioFileData) {
            await ffmpeg.writeFile(audioFilename, audioFileData);
          }
          if (srtFileData) {
            await ffmpeg.writeFile(subtitleFilename, srtFileData);
          }
          for (const url in urlToVData) {
            await ffmpeg.writeFile(urlToVideoName[url], urlToVData[url]);
          }

          const runParamsArgs = [];
          let concatFilterInputs = "";
          const forced_key_frame_params = null;
          let numberOfConcatFilterInputs = 0;
          let trimFilters = "";
          if (audioFileData) {
            runParamsArgs.push("-i");
            runParamsArgs.push(audioFilename);
            audioInputIndex = numberOfInputFiles;
            //trimFilters = trimFilters+ `[${numberOfInputFiles}]anull[outa]; `;
            numberOfInputFiles++;
          }

          if (srtFileData) {
            runParamsArgs.push("-i");
            runParamsArgs.push(subtitleFilename);
            numberOfInputFiles++;
          }

          const generateComplexFilterForCreatingVideoTrim = function (
            inputName,
            outputName,
            startOffset,
            durationUnderOneSec,
            clone,
          ) {
            //if(clone){
            //return `${inputName}fps=fps=${1/durationUnderOneSec},select=eq(n\\,${Math.floor(startOffset * 1/durationUnderOneSec)}),setpts=PTS-STARTPTS,loop=1${outputName};`;
            //}else{

            //}
            //return `${inputName}fps=fps=${1/durationUnderOneSec},trim=start=${startOffset}:duration=${durationUnderOneSec},setpts=PTS-STARTPTS${outputName};`;
            if (!window.isWebAppInProduction()) {
              console.log("frame =" + 1 / durationUnderOneSec);
              console.log("start_frame =" + Math.round((startOffset * 1) / durationUnderOneSec));
            }
            const numberOfFrames = 1 / durationUnderOneSec > 34 ? 1 : 2;
            //return `${inputName}fps=fps=${1/durationUnderOneSec},trim=start_frame=${Math.round(startOffset * 1/durationUnderOneSec)}:end_frame=${Math.round(startOffset * 1/durationUnderOneSec) + numberOfFrames},setpts=PTS-STARTPTS${outputName};`;
            return `${inputName}fps=fps=${
              1 / durationUnderOneSec
            },select='eq(t,${startOffset})',showinfo,setpts=PTS-STARTPTS${outputName};`;
            //return `${inputName}fps=fps=${1/durationUnderOneSec},trim=start=${startOffset}:duration=${this.convertPotentialExponatialNumberToFloatString(durationUnderOneSec)},setpts=PTS-STARTPTS${outputName};`;
          }.bind(this);
          let totalDuration = 0;
          videoFilesToConcatenate.map(
            function (videoFileInfo, index) {
              const videoUrl = videoFileInfo.url;
              const duration = videoFileInfo.duration;
              const videoName = urlToVideoName[videoUrl];
              const tPadding = videoFileInfo.startPadding;
              const startOffset = videoFileInfo.startOffset;
              if (!Object.prototype.hasOwnProperty.call(urlToInputIndex, videoUrl)) {
                urlToInputIndex[videoUrl] = numberOfInputFiles;
                numberOfInputFiles++;
                runParamsArgs.push("-i");
                runParamsArgs.push(videoName);
                //	runParamsArgs.push("-r");
                //	runParamsArgs.push(""+DEFAULT_VIDEO_FRAME_RATE);
              }

              if (audioFileData) {
                const fileInputIndex = urlToInputIndex[videoUrl];

                if (duration) {
                  const trimmedOutputSegmentName = `[trimmed${numberOfTrimmedFiles}]`;
                  let trimmedAudioOutputSegmentName = null;
                  trimFilters =
                    trimFilters +
                    `[${
                      urlToInputIndex[videoUrl]
                    }:v]trim=start=${startOffset}:duration=${this.convertPotentialExponatialNumberToFloatString(
                      duration - TIME_ADJUSTMENT_TO_FORCE_KEY_FRAME_MATCH,
                    )},setpts='if(lt(PTS,0),PREV_OUTPTS+1/(2*FR)/TB,PTS)',setpts=PTS-STARTPTS${
                      tPadding && tPadding > TIME_ADJUSTMENT_TO_FORCE_KEY_FRAME_MATCH
                        ? ",tpad=start_duration=" + this.convertPotentialExponatialNumberToFloatString(tPadding - 0.01)
                        : ""
                    }${trimmedOutputSegmentName};`;
                  if (audioInputIndex > -1) {
                    trimmedAudioOutputSegmentName = `[trimmedAudio${numberOfTrimmedFiles}]`;
                    trimFilters =
                      trimFilters +
                      `[${audioInputIndex}:a]atrim=start=${totalDuration}:duration=${this.convertPotentialExponatialNumberToFloatString(
                        duration,
                      )},asetpts='if(lt(PTS,0),PREV_OUTPTS+S-1,PTS)',asetpts=PTS-STARTPTS${
                        tPadding ? ",adelay=delays=" + tPadding * 1000 + ":all=1" : ""
                      }${trimmedAudioOutputSegmentName};`;
                  }
                  concatFilterInputs = concatFilterInputs + trimmedOutputSegmentName;
                  numberOfConcatFilterInputs++;
                  if (trimmedAudioOutputSegmentName) {
                    concatFilterInputs = concatFilterInputs + trimmedAudioOutputSegmentName;
                  }
                  totalDuration = totalDuration + duration + tPadding;
                }
              } else {
                const trimmedOutputName = `[trimmed${numberOfTrimmedFiles}]`;
                trimFilters =
                  trimFilters +
                  `[${
                    urlToInputIndex[videoUrl]
                  }:v]trim=start=${startOffset}:duration=${this.convertPotentialExponatialNumberToFloatString(
                    duration - TIME_ADJUSTMENT_TO_FORCE_KEY_FRAME_MATCH,
                  )},setpts='if(lt(PTS,0),PREV_OUTPTS+1/(2*FR)/TB,PTS)',setpts=PTS-STARTPTS${
                    tPadding && tPadding > TIME_ADJUSTMENT_TO_FORCE_KEY_FRAME_MATCH
                      ? ",tpad=start_duration=" +
                        this.convertPotentialExponatialNumberToFloatString(
                          tPadding - TIME_ADJUSTMENT_TO_FORCE_KEY_FRAME_MATCH,
                        )
                      : ""
                  }${trimmedOutputName};`;
                const trimmedAudioOutputName = `[trimmedAudio${numberOfTrimmedFiles}]`;
                trimFilters =
                  trimFilters +
                  `[${
                    urlToInputIndex[videoUrl]
                  }:a]atrim=start=${startOffset}:duration=${this.convertPotentialExponatialNumberToFloatString(
                    duration,
                  )},asetpts='if(lt(PTS,0),PREV_OUTPTS+S-1,PTS)',asetpts=PTS-STARTPTS${
                    tPadding ? ",adelay=delays=" + tPadding * 1000 + ":all=1" : ""
                  }${trimmedAudioOutputName};`;
                concatFilterInputs = concatFilterInputs + trimmedOutputName + trimmedAudioOutputName;
                numberOfConcatFilterInputs++;
                totalDuration = totalDuration + duration + tPadding;
              }

              numberOfTrimmedFiles++;
            }.bind(this),
          );
          if (!window.isWebAppInProduction()) {
            console.log("expected total duration =" + totalDuration);
          }
          let concatParams;
          runParamsArgs.push("-filter_complex");
          if (audioFileData) {
            concatParams =
              trimFilters + concatFilterInputs + "concat=n=" + numberOfConcatFilterInputs + `:v=1:a=1[outv][outaudio]`;
          } else {
            concatParams =
              trimFilters + concatFilterInputs + "concat=n=" + numberOfConcatFilterInputs + `:v=1:a=1[outv][outaudio]`;
            //const concatParams = trimFilters + concatFilterInputs+"concat=n="+(videoFilesToConcatenate.length+0)+`:v=1[outv]`;
          }
          runParamsArgs.push(concatParams);
          runParamsArgs.push("-c:v");
          runParamsArgs.push("libx264");

          runParamsArgs.push("-c:a");
          runParamsArgs.push("aac");

          if (srtFileData) {
            runParamsArgs.push("-c:s");
            runParamsArgs.push("mov_text");
          }
          runParamsArgs.push("-map");
          runParamsArgs.push("[outv]");
          if (audioFileData) {
            runParamsArgs.push("-map");
            //runParamsArgs.push('[outa]');
            runParamsArgs.push("[outaudio]");
          } else {
            runParamsArgs.push("-map");
            runParamsArgs.push("[outaudio]");
          }
          if (srtFileData) {
            runParamsArgs.push("-map");
            //runParamsArgs.push(audioFileData?'1':'0');
            runParamsArgs.push("1");
          }
          //runParamsArgs.push('-shortest');
          runParamsArgs.push("-vsync");
          runParamsArgs.push("2");
          runParamsArgs.push("-crf");
          runParamsArgs.push("21");
          runParamsArgs.push("-preset");
          runParamsArgs.push("fast");

          //runParamsArgs.push('-preset');
          //runParamsArgs.push('veryfast');
          runParamsArgs.push("output.mp4");
          if (window.trebbleAnalyticsHelper) {
            window.trebbleAnalyticsHelper.trackEvent(
              "concatenate video using FFmpeg",
              "concatenate video using FFmpeg",
              "concatenate video using FFmpeg",
              null,
              { params: runParamsArgs.join(" ") },
            );
          }
          return ffmpeg.exec(runParamsArgs);
        }.bind(this),
      )
      .then(async function () {
        const data = await ffmpeg.readFile("output.mp4");
        if (audioFileData) {
          await ffmpeg.deleteFile(audioFilename);
        }
        if (srtFileData) {
          await ffmpeg.deleteFile(subtitleFilename);
        }
        for (const videoUrl in urlToVData) {
          const videoName = urlToVideoName[videoUrl];
          await ffmpeg.deleteFile(videoName);
        }
        await ffmpeg.terminate();
        if (window.trebbleAnalyticsHelper) {
          window.trebbleAnalyticsHelper.trackTiming(
            "Time to concatenate audio and video in mp4 using FFmpeg",
            new Date().getTime() - renderingStartTimestamp,
          );
        }
        return new Blob([data.buffer], { type: "video/mp4" });
      })
      .catch(function (error) {
        console.error(error);
        throw error;
      });
  },

  renderVideoByConcatenatingVideoUsingFFmpegAndContatenation: function (
    videoContext,
    audioBlob,
    progressReportFunction,
    srtTextFileContent,
  ) {
    const urlToVideoName = {};
    let numberOfUniqueVideoUrls = 0;
    const c = {};
    const promiseVideoDataFetchesArray = [];
    let audioFileData = null;
    let srtFileData = null;
    const urlToVData = {};
    const audioFilename = "audio.wav";
    const subtitleFilename = "subtitles.srt";
    const renderingStartTimestamp = new Date().getTime();
    const ffmpegProgress = function (p) {
      if (progressReportFunction) {
        progressReportFunction((p.ratio > 1 ? 1 : p.ratio) * 100);
      }
    };
    const ffmpeg = this.createFFmpegInstance();
    videoContext._sourceNodes.map(function (node) {
      if (node._displayName === "VideoNode") {
        const videoUrl = node._element && node._element.src ? node._element.src : node._elementURL;
        if (!Object.prototype.hasOwnProperty.call(urlToVideoName, videoUrl)) {
          numberOfUniqueVideoUrls = numberOfUniqueVideoUrls + 1;
          urlToVideoName[videoUrl] = "video" + numberOfUniqueVideoUrls;
          promiseVideoDataFetchesArray.push(
            fetchFile(videoUrl).then(
              function (d) {
                urlToVData[this.videoUrl] = d;
              }.bind({ videoUrl: videoUrl }),
            ),
          );
        }
      }
    });
    const sortedSourceNodes = videoContext._sourceNodes.sort(function (nodeA, nodeB) {
      return nodeA._startTime - nodeB.startTime;
    });
    promiseVideoDataFetchesArray.push(
      this.fetchFileForFfmpeg(audioBlob).then(function (d) {
        audioFileData = d;
      }),
    );
    if (srtTextFileContent) {
      promiseVideoDataFetchesArray.push(
        this.fetchFileForFfmpeg(new Blob([srtTextFileContent], { type: "text/plain;charset=utf-8" })).then(function (
          d,
        ) {
          srtFileData = d;
        }),
      );
    }
    return RSVP.Promise.all(promiseVideoDataFetchesArray)
      .then(function () {
        return ffmpeg.load(this.getFFmpegLoadParams(ffmpegProgress, ffmpeg));
      })
      .bind(this)
      .then(
        async function () {
          let numberOfInputFiles = 0;
          await ffmpeg.writeFile(audioFilename, audioFileData);
          if (srtFileData) {
            await ffmpeg.writeFile(subtitleFilename, srtFileData);
          }
          for (const url in urlToVData) {
            await ffmpeg.writeFile(urlToVideoName[url], urlToVData[url]);
          }

          const runParamsArgs = [];
          runParamsArgs.push("-i");
          runParamsArgs.push(audioFilename);
          let concatFilterInputs = "";

          let trimFilters = `[${numberOfInputFiles}]anull[outa]; `;
          numberOfInputFiles++;

          if (srtFileData) {
            runParamsArgs.push("-i");
            runParamsArgs.push(subtitleFilename);
            numberOfInputFiles++;
          }

          sortedSourceNodes.map(
            function (node, index) {
              const videoUrl = node._element && node._element.src ? node._element.src : node._elementURL;
              const duration = node._stopTime - node._startTime;
              const videoName = urlToVideoName[videoUrl];
              let tPadding = 0;
              let durationAdjustement = 0;
              if (index > 0) {
                if (sortedSourceNodes[index - 1]._stopTime < node._startTime) {
                  tPadding = node._startTime - sortedSourceNodes[index - 1]._stopTime;
                }
              }
              if (index + 1 < sortedSourceNodes.length) {
                if (sortedSourceNodes[index + 1]._startTime < node._stopTime) {
                  durationAdjustement = node._stopTime - sortedSourceNodes[index + 1]._startTime;
                }
              }
              runParamsArgs.push("-i");
              runParamsArgs.push(videoName);
              trimFilters =
                trimFilters +
                `[${numberOfInputFiles}:v]trim=start=${
                  node._sourceOffset
                }:duration=${this.convertPotentialExponatialNumberToFloatString(
                  duration - durationAdjustement,
                )},setpts=PTS-STARTPTS${
                  tPadding ? ",tpad=start_duration=" + this.convertPotentialExponatialNumberToFloatString(tPadding) : ""
                }[trimmed${numberOfInputFiles}]; `;
              concatFilterInputs = concatFilterInputs + `[trimmed${numberOfInputFiles}]`;
              numberOfInputFiles++;
            }.bind(this),
          );

          runParamsArgs.push("-filter_complex");
          const concatParams =
            trimFilters + concatFilterInputs + "concat=n=" + (sortedSourceNodes.length + 0) + `:v=1[outv]`;
          runParamsArgs.push(concatParams);
          runParamsArgs.push("-c:v");
          runParamsArgs.push("libx264");
          runParamsArgs.push("-c:a");
          runParamsArgs.push("aac");
          if (srtFileData) {
            runParamsArgs.push("-c:s");
            runParamsArgs.push("mov_text");
          }
          runParamsArgs.push("-map");
          runParamsArgs.push("[outv]");
          runParamsArgs.push("-map");
          runParamsArgs.push("[outa]");
          if (srtFileData) {
            runParamsArgs.push("-map");
            runParamsArgs.push("1");
          }
          runParamsArgs.push("-strict");
          runParamsArgs.push("experimental");
          runParamsArgs.push("output.mp4");
          if (window.trebbleAnalyticsHelper) {
            window.trebbleAnalyticsHelper.trackEvent(
              "Rendering video using FFmpeg",
              "Rendering video using FFmpeg",
              "Rendering video using FFmpeg",
              null,
              { params: runParamsArgs.join(" ") },
            );
          }
          return ffmpeg.exec(runParamsArgs);
        }.bind(this),
      )
      .then(async function () {
        const data = await ffmpeg.readFile("output.mp4");
        await ffmpeg.deleteFile(audioFilename);
        if (srtFileData) {
          await ffmpeg.deleteFile(subtitleFilename);
        }
        for (const videoUrl in urlToVData) {
          const videoName = urlToVideoName[videoUrl];
          await ffmpeg.deleteFile(videoName);
        }
        await ffmpeg.terminate();
        if (window.trebbleAnalyticsHelper) {
          window.trebbleAnalyticsHelper.trackTiming(
            "Time to merge audio and video in mp4 using FFmpeg",
            new Date().getTime() - renderingStartTimestamp,
          );
        }
        return new Blob([data.buffer], { type: "video/mp4" });
      })
      .catch(function (error) {
        console.error(error);
        throw error;
      });
  },

  isFFmpegLibraryAvailable: function () {
    try {
      if ("FFmpeg" in window) {
        //const ffmpegInstance = this.createFFmpegInstance();
        //ffmpegInstance.exit();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },

  getFFmpegLoadParams: function (ffmpegProgress, ffmpegInstance) {
    if (ffmpegInstance) {
      ffmpegInstance.on("log", ({ type, message }) => {
        window.log(`${type}: ${message}`);
      });
      if (ffmpegProgress) {
        ffmpegInstance.on("progress", ({ progress, time }) => {
          ffmpegProgress({ ratio: progress, time });
        });
      }
    }
    const FFMPEG_DIRECTORY = "ffmpeg_wasm_unpkg/umd_0_12_4";
    return {
      log: true,
      coreURL: new URL(`js/libs/${FFMPEG_DIRECTORY}/ffmpeg-core.js`, document.location).href,
      wasmURL: new URL(`js/libs/${FFMPEG_DIRECTORY}/ffmpeg-core.wasm`, document.location).href,
    };
  },

  createFFmpegInstance: function () {
    return new FFmpeg();
  },

  convertToMp3: function (audioBlob, progressReportFunction) {
    const ffmpegProgress = function (p) {
      if (progressReportFunction) {
        progressReportFunction((p.ratio > 1 ? 1 : p.ratio) * 100);
      }
    };
    const ffmpeg = this.createFFmpegInstance();
    let aFileData = null;
    const promises = [];
    promises.push(
      this.fetchFileForFfmpeg(audioBlob).then(function (d) {
        aFileData = d;
      }),
    );
    return RSVP.Promise.all(promises)
      .then(
        function () {
          return ffmpeg.load(this.getFFmpegLoadParams(ffmpegProgress, ffmpeg));
        }.bind(this),
      )
      .then(async function () {
        await ffmpeg.writeFile("audio.wav", aFileData);
        const runParamsArgs = [];
        runParamsArgs.push("-i");
        runParamsArgs.push("audio.wav");
        runParamsArgs.push("-c:a");
        runParamsArgs.push("libmp3lame");
        runParamsArgs.push("-b:a");
        runParamsArgs.push("320k");
        runParamsArgs.push("-strict");
        runParamsArgs.push("experimental");
        runParamsArgs.push("output.mp3");
        return ffmpeg.exec(runParamsArgs);
      })
      .then(async function () {
        const data = await ffmpeg.readFile("output.mp3");
        await ffmpeg.deleteFile("audio.wav");
        await ffmpeg.terminate();
        return new Blob([data.buffer], { type: "audio/mpeg" });
      })
      .catch(function (error) {
        console.error(error);
        throw error;
      });
  },

  convertStreams: function (videoBlob, audioBlob, progressReportFunction, srtTextFileContent, videFileData) {
    const ffmpegProgress = function (p) {
      if (progressReportFunction) {
        progressReportFunction((p.ratio > 1 ? 1 : p.ratio) * 100);
      }
    };
    const ffmpeg = this.createFFmpegInstance();

    let vFileData = null;
    let aFileData = null;
    let srtFileData = null;
    const subtitleFilename = "subtitles.srt";
    const promises = [];
    if (videFileData) {
      vFileData = videFileData;
    } else {
      promises.push(
        this.fetchFileForFfmpeg(videoBlob).then(function (d) {
          vFileData = d;
        }),
      );
    }
    promises.push(
      this.fetchFileForFfmpeg(audioBlob).then(function (d) {
        aFileData = d;
      }),
    );
    if (srtTextFileContent) {
      promises.push(
        this.fetchFileForFfmpeg(new Blob([srtTextFileContent], { type: "text/plain;charset=utf-8" })).then(function (
          d,
        ) {
          srtFileData = d;
        }),
      );
    }
    return RSVP.Promise.all(promises)
      .then(
        function () {
          return ffmpeg.load(this.getFFmpegLoadParams(ffmpegProgress, ffmpeg));
        }.bind(this),
      )
      .then(async function () {
        await ffmpeg.writeFile("video.webm", vFileData);
        await ffmpeg.writeFile("audio.wav", aFileData);
        if (srtFileData) {
          await ffmpeg.writeFile(subtitleFilename, srtFileData);
        }
        const runParamsArgs = [];
        runParamsArgs.push("-i");
        runParamsArgs.push("video.webm");
        runParamsArgs.push("-i");
        runParamsArgs.push("audio.wav");
        if (srtFileData) {
          runParamsArgs.push("-i");
          runParamsArgs.push(subtitleFilename);
        }
        runParamsArgs.push("-c:v");
        runParamsArgs.push("libx264");
        runParamsArgs.push("-c:a");
        runParamsArgs.push("aac");
        if (srtFileData) {
          runParamsArgs.push("-c:s");
          runParamsArgs.push("mov_text");
        }
        runParamsArgs.push("-q:v");
        runParamsArgs.push("1");
        runParamsArgs.push("-map");
        runParamsArgs.push("0:v:0");
        runParamsArgs.push("-map");
        runParamsArgs.push("1:a");
        if (srtFileData) {
          runParamsArgs.push("-map");
          runParamsArgs.push("2");
        }
        runParamsArgs.push("-strict");
        runParamsArgs.push("experimental");
        runParamsArgs.push("output.mp4");
        return ffmpeg.exec(runParamsArgs);
      })
      .then(async function () {
        const data = await ffmpeg.readFile("output.mp4");
        await ffmpeg.deleteFile("video.webm");
        await ffmpeg.deleteFile("audio.wav");
        if (srtFileData) {
          await ffmpeg.deleteFile(subtitleFilename);
        }
        await ffmpeg.terminate();
        return new Blob([data.buffer], { type: "video/mp4" });
      })
      .catch(function (error) {
        console.error(error);
        throw error;
      });
  },

  convertStreamsOld: function (videoBlob, audioBlob) {
    return new RSVP.Promise(
      function (resolve, reject) {
        const startTimeToMergeAudioAndVideo = new Date().getTime();
        try {
          const videoFile = "video.webm";
          let workerPath = "https://web.trebble.fm/js/libs/ffmpeg_asm/ffmpeg_asm.js";
          if (document.domain == "localhost") {
            workerPath = location.href.replace(location.href.split("/").pop(), "") + "js/libs/ffmpeg_asm/ffmpeg_asm.js";
          }

          const processInWebWorker = function () {
            const blob = URL.createObjectURL(
              new Blob(
                [
                  'importScripts("' +
                    workerPath +
                    '");const now = Date.now;function print(text) {postMessage({"type" : "stdout","data" : text});};onmessage = function(event) {const message = event.data;if (message.type === "command") {const Module = {print: print,printErr: print,files: message.files || [],arguments: message.arguments || [],TOTAL_MEMORY: message.TOTAL_MEMORY || false};postMessage({"type" : "start","data" : Module.arguments.join(" ")});postMessage({"type" : "stdout","data" : "Received command: " +Module.arguments.join(" ") +((Module.TOTAL_MEMORY) ? ".  Processing with " + Module.TOTAL_MEMORY + " bits." : "")});const time = now();const result = ffmpeg_run(Module);const totalTime = now() - time;postMessage({"type" : "stdout","data" : "Finished processing (took " + totalTime + "ms)"});postMessage({"type" : "done","data" : result,"time" : totalTime});}};postMessage({"type" : "ready"});',
                ],
                {
                  type: "application/javascript",
                },
              ),
            );

            const worker = new Worker(blob);
            URL.revokeObjectURL(blob);
            return worker;
          };

          let worker;

          let vab;
          let aab;
          let buffersReady;
          let workerReady;
          let posted = false;

          const fileReader1 = new FileReader();
          fileReader1.onload = function () {
            vab = this.result;

            if (aab) buffersReady = true;

            if (buffersReady && workerReady && !posted) postMessage();
          };
          const fileReader2 = new FileReader();
          fileReader2.onload = function () {
            aab = this.result;

            if (vab) buffersReady = true;

            if (buffersReady && workerReady && !posted) postMessage();
          };

          fileReader1.readAsArrayBuffer(videoBlob);
          fileReader2.readAsArrayBuffer(audioBlob);

          if (!worker) {
            worker = processInWebWorker();
          }

          const postMessage = function () {
            posted = true;

            worker.postMessage({
              type: "command",
              TOTAL_MEMORY: 2147483648,
              arguments: [
                "-i",
                videoFile,
                "-i",
                "audio.wav",
                "-c:v",
                "mpeg4",
                "-c:a",
                "aac",
                "-q:v",
                "1",
                "-map",
                "0:v:0",
                "-map",
                "1:a:0",
                "-strict",
                "experimental",
                "output.mp4",
              ],
              files: [
                {
                  data: new Uint8Array(vab),
                  name: videoFile,
                },
                {
                  data: new Uint8Array(aab),
                  name: "audio.wav",
                },
              ],
            });
          };

          worker.onmessage = function (event) {
            const message = event.data;
            if (message.type == "ready") {
              console.log(
                '<a href="' + workerPath + '" download="ffmpeg-asm.js">ffmpeg-asm.js</a> file has been loaded.',
              );
              workerReady = true;
              if (buffersReady) postMessage();
            } else if (message.type == "stdout") {
              console.log(message.data);
            } else if (message.type == "start") {
              console.log(
                '<a href="' + workerPath + '" download="ffmpeg-asm.js">ffmpeg-asm.js</a> file received ffmpeg command.',
              );
            } else if (message.type == "done") {
              const result = message.data[0];
              const blob = new Blob([result.data], {
                type: "video/mp4",
              });
              console.log(
                "Time to merge audio and video in mp4 for sequencer renderer " +
                  (new Date().getTime() - startTimeToMergeAudioAndVideo),
              );
              if (window.trebbleAnalyticsHelper) {
                window.trebbleAnalyticsHelper.trackTiming(
                  "Time to merge audio and video in mp4 for sequencer renderer",
                  new Date().getTime() - startTimeToMergeAudioAndVideo,
                );
              }

              resolve(blob);
              worker.terminate();
            }
          };
        } catch (error) {
          reject(error);
        }
      }.bind(this),
    );
  },

  fetchAudioAndGetAudioDuration: function (audioUrl, progressReportFunction) {
    const audioContext = this._createContext();
    return this.getAudioBufferFromBlobWithoutCaching(audioUrl, progressReportFunction, audioContext).then(
      function (audioBuffer) {
        audioContext.close();
        if (audioBuffer) {
          return audioBuffer.duration;
        }
        return null;
      }.bind(this),
    ).catch((error)=>{
      audioContext.close();
    });
  },

  getAudioBufferFromBlobWithoutCaching: function (audioUrl, progressReportFunction, audioContext) {
    return this.fetchAndCalculateProgress(this.getCloudFrontUrlIfApplicable(audioUrl), progressReportFunction, true)
      .then(
        function (blob) {
          return blob.arrayBuffer();
        }.bind(this),
      )
      .then(
        function (buffer) {
          return new RSVP.Promise(
            function (resolve, reject) {
              return audioContext.decodeAudioData(buffer, resolve, reject);
            }.bind(this),
          );
        }.bind(this),
      )
      .then(
        function (audioBuffer) {
          return audioBuffer;
        }.bind(this),
      );
  },

  fetchAndCalculateProgress: function (url, progressReportFunction, onlyFetchMediaFiles) {
    return new Promise(
      function (resolve, reject) {
        fetchWithRetry(url, { mode: "cors" })
          .then((response) => {
            if (!response.ok) {
              const x_amz_request_id = response.headers.get("X-Amz-Cf-Id");
              const x_amz_id_2 = response.headers.get("X-Amz-Cf-Pop");
              if (window.trebbleAnalyticsHelper) {
                window.trebbleAnalyticsHelper.trackEvent(
                  "Fetch Request For Audio Failed",
                  "Fetch Request For Audio Failed",
                  "Fetch Request For Audio Failed",
                  null,
                  {
                    "X-Amz-Cf-Id": x_amz_request_id,
                    "X-Amz-Cf-Pop": x_amz_id_2,
                    audioUrl: url,
                  },
                );
              }
              throw new Error(`Request failed with status ${response.status}`);
            }
            /*if(onlyFetchMediaFiles){
                        if (response.headers.has('Content-Type') && (!response.headers.get('Content-Type').includes('audio/') && !response.headers.get('Content-Type').includes('video/') )) {
                                console.warn("Attempted to fetch an audio file, but its MIME type is " +
                                    response.headers.get('Content-Type').split(';')[0] +". We'll try and continue anyway. (file: " +url +")");
                                const x_amz_request_id = response.headers.get('X-Amz-Cf-Id');
                                const x_amz_id_2 = response.headers.get('X-Amz-Cf-Pop');
                                if(window.trebbleAnalyticsHelper){
                                    window.trebbleAnalyticsHelper.trackEvent('Fetch Request For Audio Failed', 'Fetch Request For Audio Failed', 'Fetch Request For Audio Failed',null, {"X-Amz-Cf-Id": x_amz_request_id,"X-Amz-Cf-Pop": x_amz_id_2 ,"audioUrl": url});
                                }
                            throw new Error(`File fetch is not a media file`);	
                        }
                    }*/

            const contentLength = response.headers.get("Content-Length");
            let loaded = 0;
            const chunks = [];

            const reader = response.body.getReader();

            function read() {
              reader
                .read()
                .then(({ done, value }) => {
                  if (done) {
                    const blob = new Blob(chunks, {
                      type: response.headers.get("Content-Type"),
                    });
                    reader.releaseLock();
                    resolve(blob);
                    return;
                  }

                  loaded += value.byteLength;
                  chunks.push(value);

                  if (contentLength) {
                    const percentComplete = (loaded / contentLength) * 100;
                    if (progressReportFunction) {
                      progressReportFunction(percentComplete, window.getI18n(ti18n, "DOWNLOADING_FILE"));
                    }
                  }

                  read();
                })
                .catch(function (error) {
                  console.error(error);
                  reject(error);
                });
            }

            read();
          })
          .catch((error) => {
            reject(error);
          });
      }.bind(this),
    );
  },

  _notifySequencerListeningForSpeechEnhancementCompletion : function(unenhancedFileUrl, enhancedFileUrl){
    for (const [sequencerId, arrayOfAudioUrlsToEnhance] of Object.entries(this._sequencerIdMapToAudioUrlsToEnhance)) {
      if(arrayOfAudioUrlsToEnhance.includes(unenhancedFileUrl)){
        arrayOfAudioUrlsToEnhance.splice(arrayOfAudioUrlsToEnhance.indexOf(unenhancedFileUrl), 1);
        if(arrayOfAudioUrlsToEnhance.length === 0){
          this._sequencerIdToSequencerForWhichEnhancementSettingShouldBeTurnedOn[sequencerId].getSequencerSettings().setMagicSoundEnhancerOn(true, false);
          delete this._sequencerIdToSequencerForWhichEnhancementSettingShouldBeTurnedOn[sequencerId];
        }
      }
    }

  },

  getEnhancedSpeechVersionUrlForMediaFile : async function(audioUrl){
    if (this._audioUrlToServerEnhancerAudioFileUrl[audioUrl]) {
      return this._audioUrlToServerEnhancerAudioFileUrl[audioUrl];
    } else {
      const enhancementStatusInfo = await TrebbleClientAPI.getInstance().getEnhancedFileUrlForMediaFile(audioUrl);
      if (enhancementStatusInfo.enhancedFileUrl) {
        this._audioUrlToServerEnhancerAudioFileUrl[audioUrl] = enhancementStatusInfo.enhancedFileUrl;
        return enhancementStatusInfo.enhancedFileUrl;
      } 
      }
      return null;
  },


  _addSequencerForSettingsToBeTurnedOnWhenEnhancementCompletes : function(sequencer, audioUrl){
    const sequencerId = sequencer.getId();
    if(!this._sequencerIdMapToAudioUrlsToEnhance[sequencerId]){
      this._sequencerIdMapToAudioUrlsToEnhance[sequencerId] = [];
    }

    if(!this._sequencerIdMapToAudioUrlsToEnhance[sequencerId].includes(audioUrl)){
      this._sequencerIdMapToAudioUrlsToEnhance[sequencerId].push(audioUrl)
    }
    this._sequencerIdToSequencerForWhichEnhancementSettingShouldBeTurnedOn[sequencer.getId()] = sequencer;
  },

  enhanceTranscribedFilesAndTunOnSetting :  async function(transcribedFileInfoArray, sequencer){
    transcribedFileInfoArray.map(async (transcribedInfo)=>{
      await TrebbleClientAPI.getInstance().enhanceSpeechInFile(transcribedInfo.audioFileUrl, transcribedInfo.transcriptionId);
      this._addSequencerForSettingsToBeTurnedOnWhenEnhancementCompletes(sequencer, transcribedInfo.audioFileUrl);
    })

  },
/*
  getServerEnhancedUrlForAudioUrlAndTriggerProcessingIfNotAvailable: async function (
    audioUrl,
    transcriptionId,
    sequencerSettings,
  ) {
    if (this._audioUrlToServerEnhancerAudioFileUrl[audioUrl]) {
      return this._audioUrlToServerEnhancerAudioFileUrl[audioUrl];
    } else {
      const enhancementStatusInfo = await TrebbleClientAPI.getInstance().getEnhancedFileUrlForMediaFile(audioUrl);
      if (enhancementStatusInfo.enhancedFileUrl) {
        this._audioUrlToServerEnhancerAudioFileUrl[audioUrl] = enhancementStatusInfo.enhancedFileUrl;
        return enhancementStatusInfo.enhancedFileUrl;
      } else {
        if (enhancementStatusInfo.alreadyPendingRequest) {
          Utils.getInstance().showWarningNotification(
            window.getI18n(ti18n, "YOUR_AUDIO_FILE_BEING_ENHANCED_PLEASE_WAIT"),
          );
          this._addSequencerToListenerForSpeechEnhancementCompletion(audioUrl, sequencerSettings.getSequencer());
          return null;
        } else {
          const operationResults = await TrebbleClientAPI.getInstance().enhanceSpeechInFile(audioUrl, transcriptionId);
          if (operationResults.alreadyPendingRequest) {
            Utils.getInstance().showWarningNotification(
              window.getI18n(ti18n, "YOUR_AUDIO_FILE_BEING_ENHANCED_PLEASE_WAIT"),
            );
            this._addSequencerToListenerForSpeechEnhancementCompletion(audioUrl, sequencerSettings.getSequencer());
            return null;
          } else {
            if (operationResults.enhancedRequestWasStarted) {
              Utils.getInstance().showWarningNotification(
                window.getI18n(ti18n, "WE_ARE_WORKING_ON_ENHANCING_YOUR_FILE_PLEASE_WAIT"),
              );
              this._addSequencerToListenerForSpeechEnhancementCompletion(audioUrl, sequencerSettings.getSequencer());
              return null;
            } else {
              if (operationResults.enhancedFileUrl) {
                return operationResults.enhancedFileUrl;
              }
            }
          }
        }
      }
    }
  },*/

  loadAudioBufferFromUrl: async function (
    audioUrl,
    audioUniqueId,
    audioType,
    sequencerSettings,
    progressReportFunction,
  ) {
    if (!audioUniqueId) {
      audioUniqueId = audioUrl;
    }
    let noiseCancellationOn = audioType == AUDIO_TYPES.VOICE && sequencerSettings.isNoiseCancellationOn();
    let magicSoundEnhancerOn = audioType == AUDIO_TYPES.VOICE && sequencerSettings.isMagicSoundEnhancerOn();
    if (noiseCancellationOn && USE_RNNOISE_WEB_AUDIO_NODE) {
      noiseCancellationOn = false; //use orginal file
    }

    const audioUniqueIdWithAudioTypeId = this._getAudioUniqueIdWithAudioTypeId(
      audioUniqueId,
      audioType,
      noiseCancellationOn,
      magicSoundEnhancerOn
    );
    const existingAudioBufferWithDefaultFiltersApplied =
      this._audioUniqueIdWithAudioTypeToAudioBuffer[audioUniqueIdWithAudioTypeId];

    if (existingAudioBufferWithDefaultFiltersApplied) {
      return RSVP.Promise.resolve(existingAudioBufferWithDefaultFiltersApplied);
    }
    let existingAudioBuffer;
    if(noiseCancellationOn && !magicSoundEnhancerOn){
      existingAudioBuffer = this._audioUniqueIdToAudioBufferWithNoiseCancellation[audioUniqueId];
    }
    if(!noiseCancellationOn && magicSoundEnhancerOn){
      existingAudioBuffer = this._audioUniqueIdToAudioBufferWithMagicSoundEnhancer[audioUniqueId];
    }
    if(!noiseCancellationOn && !magicSoundEnhancerOn){
      existingAudioBuffer = this._audioUniqueIdToAudioBuffer[audioUniqueId];
    }
    if (existingAudioBuffer) {
      return this._getAudioBufferWithAppliedDefaultEffects(
        audioUniqueId,
        existingAudioBuffer,
        audioType,
        noiseCancellationOn,
        magicSoundEnhancerOn,
        sequencerSettings,
      );
    }

    if (this._audioUniqueIdToFetchAudioBufferInProgressPromise[audioUniqueId]) {
      return this._audioUniqueIdToFetchAudioBufferInProgressPromise[audioUniqueId];
    } else {
      if (progressReportFunction) {
        progressReportFunction(null, window.getI18n(ti18n, "DOWNLOADING_FILE"));
      }
      let audioUrlToFetch = audioUrl;
      if (magicSoundEnhancerOn && USE_BACKEND_SERVER_FOR_SPEECH_ENHANCEMENT) {

        const audioUrlEnhanced = await this.getEnhancedSpeechVersionUrlForMediaFile(audioUrl);
        if (audioUrlEnhanced) {
          audioUrlToFetch = audioUrlEnhanced;
        }else{
          throw "Audio file needs to enhanced first before being used!";
        }
      }
      if(magicSoundEnhancerOn){
        const audioBufferUntreated = this._audioUniqueIdToAudioBuffer[audioUniqueId];
        if (audioBufferUntreated &&
          FLUSH_ORGINAL_AUDIO_FILE_AFTER_DENOISE &&
          audioBufferUntreated.duration > MIN_AUDIO_DURATION_TO_FLUSH_AFTER_DENOISE
        ) {
          this.unloadAudioBufferFromUrl(
            audioUrl,
            audioUniqueId,
            audioType,
            sequencerSettings,
            true,
            true,
            true,
            !magicSoundEnhancerOn
          );
        }
      }
      const promise = this.fetchAndCalculateProgress(
        this.getCloudFrontUrlIfApplicable(audioUrlToFetch),
        progressReportFunction,
        true,
      )
        .then(
          function (blob) {
            return this.addMediaBlobToCacheForSequenncerAndGetArrayBuffer(
              blob,
              audioUrl,
              audioUniqueId,
              audioType,
              sequencerSettings && sequencerSettings.getSequencer && sequencerSettings.getSequencer(),
              true,
            );
          }.bind(this),
        )
        .then(
          function (buffer) {
            return new RSVP.Promise(
              function (resolve, reject) {
                if (progressReportFunction) {
                  progressReportFunction(null, window.getI18n(ti18n, "DECODING_FILE"));
                }
                return this._getAudioContext().decodeAudioData(buffer, resolve, reject);
              }.bind(this),
            );
          }.bind(this),
        )
        .then(
          function (audioBuffer) {
            /*if(audioBuffer && audioType == AUDIO_TYPES.INSERT){
                                        return this.ebuNormalization(audioBuffer, INSERT_SOUND_DB_LEVEL);
                                    }else{
                                        return audioBuffer;
                                    }*/
            return audioBuffer;
          }.bind(this),
        )
        .then(
          function (audioBuffer) {
            if (noiseCancellationOn && !USE_RNNOISE_WEB_AUDIO_NODE) {
              if (progressReportFunction) {
                progressReportFunction(null, window.getI18n(ti18n, "DENOISING"));
              }
              return this.denoiseAudioBuffer(audioBuffer);
            }
            return audioBuffer;
          }.bind(this),
        )
        .then(
          function (audioBuffer) {
            //return this.resampleAudioBufferToMatchContextSampleRate(audioBuffer);
            return this.loadUrlInBlobCache(audioUrl)
              .then(
                function (blobUrl) {
                  return window.isSafariBrowser ? null : this.getAudioDuration(blobUrl);
                }.bind(this),
              )
              .then(
                function (duration) {
                  audioBuffer._durationCalculatedByMediaElement = duration;
                  return audioBuffer;
                }.bind(this),
              );
          }.bind(this),
        )
        .then(
          function (audioBuffer) {
            if (progressReportFunction) {
              progressReportFunction(null, window.getI18n(ti18n, "NORMALIZING"));
            }
            audioBuffer = NORMALIZE_ALL_AUDIO_BUFFERS
              ? AudioBufferUtils.getInstance().normalize(audioBuffer, null, NORMALIZATION_PEAK_TARGET, null, null, true)
              : audioBuffer;
            if (this.isAudioTypeCachingAllowed(audioType, AUDIO_TYPES_TO_NOT_CACHE_IN_FULL, audioUniqueId)) {
              if (noiseCancellationOn) {
                this._audioUniqueIdToAudioBufferWithNoiseCancellation[audioUniqueId] = audioBuffer;
              } else {
                if (magicSoundEnhancerOn) {
                  this._audioUniqueIdToAudioBufferWithMagicSoundEnhancer[audioUniqueId] = audioBuffer;
                } else {
                  this._audioUniqueIdToAudioBuffer[audioUniqueId] = audioBuffer;
                }
              }
            }
            delete this._audioUniqueIdToFetchAudioBufferInProgressPromise[audioUniqueId];
            //return audioBuffer;
            return this._getAudioBufferWithAppliedDefaultEffects(
              audioUniqueId,
              audioBuffer,
              audioType,
              noiseCancellationOn,
              magicSoundEnhancerOn,
              sequencerSettings,
            );
          }.bind(this),
        )
        .then(
          function (audioBufferToReturn) {
            if (
              FLUSH_ORGINAL_AUDIO_FILE_AFTER_DENOISE &&
              audioBufferToReturn.duration > MIN_AUDIO_DURATION_TO_FLUSH_AFTER_DENOISE
            ) {
              this.unloadAudioBufferFromUrl(
                audioUrl,
                audioUniqueId,
                audioType,
                sequencerSettings,
                true,
                (noiseCancellationOn || magicSoundEnhancerOn),
                !noiseCancellationOn,
                !magicSoundEnhancerOn
              );
            }
            return audioBufferToReturn;
          }.bind(this),
        )
        .catch(
          function (error) {
            delete this._audioUniqueIdToFetchAudioBufferInProgressPromise[audioUniqueId];
            throw error;
          }.bind(this),
        );

      this._audioUniqueIdToFetchAudioBufferInProgressPromise[audioUniqueId] = promise;

      return promise;
    }
  },

  getMaxAudioBufferDuration: function (audioBuffer) {
    return audioBuffer &&
      audioBuffer._durationCalculatedByMediaElement &&
      audioBuffer._durationCalculatedByMediaElement > audioBuffer.duration
      ? audioBuffer._durationCalculatedByMediaElement
      : audioBuffer.duration;
  },

  getAudioDuration: function (audioUrl) {
    return new RSVP.Promise(
      function (resolve, reject) {
        const audioEl = document.createElement("audio");
        audioEl.addEventListener(
          "loadedmetadata",
          function () {
            resolve(isFinite(audioEl.duration) ? audioEl.duration : null);
          },
          false,
        );
        audioEl.addEventListener(
          "error",
          function () {
            reject("Failed loading audio");
          },
          false,
        );
        audioEl.addEventListener(
          "abort",
          function () {
            reject("Failed loading audio");
          },
          false,
        );
        audioEl.src = audioUrl;
      }.bind(this),
    );
  },

  addHTMLResponseBlobToCacheForSequenncerAndGetArrayBuffer: function (
    htmlResponse,
    audioUrl,
    audioUniqueId,
    audioType,
    sequencer,
    onlyCacheMediaFiles,
  ) {
    if (htmlResponse.status === 200) {
      if (
        !onlyCacheMediaFiles ||
        (htmlResponse.headers.has("Content-Type") &&
          (htmlResponse.headers.get("Content-Type").includes("audio/") ||
            htmlResponse.headers.get("Content-Type").includes("video/")))
      ) {
        return htmlResponse
          .blob()
          .then(
            function (blobCreated) {
              this.loadUrlInBlobCache(audioUrl, null, blobCreated);
              this.addUrlAsLoadedBySequencer(audioUrl, audioUniqueId, audioType, sequencer);
              return blobCreated.arrayBuffer();
            }.bind(this),
          )
          .catch(function (error) {
            console.error("failed to convert response in blob. Error" + error);
            return htmlResponse.arrayBuffer();
          });
      }
    }
    return htmlResponse.arrayBuffer();
  },

  addMediaBlobToCacheForSequenncerAndGetArrayBuffer: function (
    blob,
    audioUrl,
    audioUniqueId,
    audioType,
    sequencer,
    onlyCacheMediaFiles,
  ) {
    this.loadUrlInBlobCache(audioUrl, null, blob);
    this.addUrlAsLoadedBySequencer(audioUrl, audioUniqueId, audioType, sequencer);
    return blob.arrayBuffer();
  },

  addUrlAsLoadedBySequencer: function (audioUrl, audioUniqueId, audioType, sequencer) {
    if (!audioUniqueId) {
      audioUniqueId = audioUrl;
    }
    if (sequencer) {
      if (!this._sequencerIdToArrayOfAudioInfoLoaded[sequencer.getId()]) {
        this._sequencerIdToArrayOfAudioInfoLoaded[sequencer.getId()] = [];
      }
      const audioId = this._getAudioUniqueIdWithAudioTypeId(audioUniqueId, audioType, null, null);
      if (!this._audioIdLoadedToSequencers[audioId]) {
        this._audioIdLoadedToSequencers[audioId] = [];
      }
      const audioInfoToSave = {
        audioUrl: audioUrl,
        audioUniqueId: audioUniqueId,
        audioType: audioType,
      };
      if (!this._audioIdLoadedToSequencers[audioId].includes(sequencer.getId())) {
        this._audioIdLoadedToSequencers[audioId].push(sequencer.getId());
      }
      const alreadyExist = this._sequencerIdToArrayOfAudioInfoLoaded[sequencer.getId()].find(function (obj) {
        return (
          obj.audioUrl == audioInfoToSave.audioUrl &&
          obj.audioUniqueId == audioInfoToSave.audioUniqueId &&
          obj.audioType == audioInfoToSave.audioType
        );
      });
      if (!alreadyExist) {
        this._sequencerIdToArrayOfAudioInfoLoaded[sequencer.getId()].push(audioInfoToSave);
      }
    }
  },

  createMediaElement: function (url, isVideo, currentTimeOffset) {
    const mediaElement = document.createElement(isVideo ? "video" : "audio");
    mediaElement.setAttribute("crossorigin", "anonymous");
    mediaElement.setAttribute("webkit-playsinline", "");
    mediaElement.setAttribute("playsinline", "");

    mediaElement.preload = "auto";

    if (window.MediaStream !== undefined && mediaElement instanceof MediaStream) {
      mediaElement.srcObject = url;
    } else {
      mediaElement.src = url;
    }
    mediaElement.currentTime = currentTimeOffset;
    return new RSVP.Promise(function (resolve, reject) {
      mediaElement.addEventListener("canplaythrough", (event) => {
        resolve(mediaElement);
      });
      mediaElement.addEventListener("error", function (e) {
        resolve(mediaElement);
        console.error("Error loading media element", e);
        window.sendErrorToRaygun(e);
      });
    });
  },

  unloadAllUrlsUsedBySequencer: function (sequencer, clearBlobCache) {
    let promises = [];
    const audioUrlsToRemovedFromBlobCache = [];
    if (sequencer) {
      if (!this._sequencerIdToArrayOfAudioInfoLoaded[sequencer.getId()]) {
        this._sequencerIdToArrayOfAudioInfoLoaded[sequencer.getId()] = [];
      }

      promises = this._sequencerIdToArrayOfAudioInfoLoaded[sequencer.getId()].map(
        function (audioInfo) {
          const audioId = this._getAudioUniqueIdWithAudioTypeId(audioInfo.audioUniqueId, audioInfo.audioType, null, null);
          const arrayOfSequencer = this._audioIdLoadedToSequencers[audioId];
          const index = arrayOfSequencer.indexOf(sequencer.getId());
          if (index > -1) {
            arrayOfSequencer.splice(index, 1);
          }
          if (arrayOfSequencer.length == 0) {
            audioUrlsToRemovedFromBlobCache.push(audioInfo.audioUrl);
            return this.unloadAudioBufferFromUrl(
              audioInfo.audioUrl,
              audioInfo.audioUniqueId,
              audioInfo.audioType,
              sequencer.getSequencerSettings(),
            );
          }
          return RSVP.Promise.resolve();
        }.bind(this),
      );
      if (clearBlobCache) {
        audioUrlsToRemovedFromBlobCache.map(
          function (audioUrl) {
            promises.push(this.removeUrlFromBlobCache(audioUrl));
          }.bind(this),
        );
      }
    }
    return RSVP.Promise.all(promises).then(
      function () {
        delete this._sequencerIdToArrayOfAudioInfoLoaded[sequencer.getId()];
      }.bind(this),
    );
  },

  getBlobUrlForUrl: function (url, progressReportFunction) {
    return new RSVP.Promise(function (resolve, reject) {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";

      xhr.onload = function (oEvent) {
        if (xhr.status === 200) {
          const videoBlob = xhr.response;
          resolve(URL.createObjectURL(videoBlob));
        }
      };

      xhr.onerror = function () {
        reject(arguments);
      };

      xhr.onprogress = function (oEvent) {
        if (oEvent.lengthComputable) {
          if (progressReportFunction) {
            progressReportFunction(((oEvent.loaded / oEvent.total) * 100) | 0);
          }
        }
      };

      xhr.send();
    });
  },

  loadUrlInBlobCache: function (url, progressReportFunction, downloadedBlob) {
    if (!this._urlToPromiseCachedDownloadedBlobUrl[url]) {
      this._urlToPromiseCachedDownloadedBlobUrl[url] = downloadedBlob
        ? RSVP.Promise.resolve(URL.createObjectURL(downloadedBlob))
        : this.getBlobUrlForUrl(url, progressReportFunction);
    }
    return this._urlToPromiseCachedDownloadedBlobUrl[url];
  },

  loadUrlInVideoSizeCache: function (url) {
    if (!this._urlToPromiseCachedDownloadedVideoSize[url]) {
      this._urlToPromiseCachedDownloadedVideoSize[url] = this.createMediaElement(url, true, 0).then(
        function (mediaElement) {
          return {
            videoWidth: mediaElement.videoWidth,
            videoHeight: mediaElement.videoHeight,
          };
        }.bind(this),
      );
    }
    return this._urlToPromiseCachedDownloadedVideoSize[url];
  },

  removeUrlFromBlobCache: function (url) {
    if (this._urlToPromiseCachedDownloadedBlobUrl[url]) {
      return this._urlToPromiseCachedDownloadedBlobUrl[url].then(
        function (cachedDownloadedBlobUrl) {
          delete this._urlToPromiseCachedDownloadedBlobUrl[url];
        }.bind(this),
      );
    } else {
      return RSVP.Promise.resolve();
    }
  },

  removeUrlFromVideoSizeCache: function (url) {
    if (this._urlToPromiseCachedDownloadedVideoSize[url]) {
      return this._urlToPromiseCachedDownloadedVideoSize[url].then(
        function (cachedDownloadedBlobUrl) {
          delete this._urlToPromiseCachedDownloadedVideoSize[url];
        }.bind(this),
      );
    } else {
      return RSVP.Promise.resolve();
    }
  },

  unloadAudioBufferFromUrl: function (
    audioUrl,
    audioUniqueId,
    audioType,
    sequencerSettings,
    doNotUnloadAll,
    unloadAudioBuffeUnprocesssed,
    unloadAudioBufferWithNoiseCancellationOnly,
    unloadAudioBufferWithMagicSoundEnhancerOnly,

  ) {
    if (!audioUniqueId) {
      audioUniqueId = audioUrl;
    }
    const audioBufferLoadedPromise = this._audioUniqueIdToFetchAudioBufferInProgressPromise[audioUniqueId]
      ? this._audioUniqueIdToFetchAudioBufferInProgressPromise[audioUniqueId]
      : RSVP.Promise.resolve();

    return audioBufferLoadedPromise.then(
      function () {
        const audioUniqueIdWithAudioTypeId = this._getAudioUniqueIdWithAudioTypeId(audioUniqueId, audioType, false, false);
        const audioUniqueIdWithAudioTypeIdWithNoiseCancellation = this._getAudioUniqueIdWithAudioTypeId(
          audioUniqueId,
          audioType,
          true,
          false
        );
        const audioUniqueIdWithAudioTypeIdWithMagicSoundEnhancer = this._getAudioUniqueIdWithAudioTypeId(
          audioUniqueId,
          audioType,
          false,
          true,
        );

        if(!doNotUnloadAll){
          delete this._audioUniqueIdWithAudioTypeToAudioBuffer[audioUniqueIdWithAudioTypeId];
          this.trigger("audioBufferUnloadedFromCache", audioUniqueIdWithAudioTypeId);
          delete this._audioUniqueIdWithAudioTypeToAudioBuffer[audioUniqueIdWithAudioTypeIdWithNoiseCancellation];
          this.trigger("audioBufferUnloadedFromCache", audioUniqueIdWithAudioTypeIdWithNoiseCancellation);
          delete this._audioUniqueIdWithAudioTypeToAudioBuffer[audioUniqueIdWithAudioTypeIdWithMagicSoundEnhancer];
          this.trigger("audioBufferUnloadedFromCache", audioUniqueIdWithAudioTypeIdWithMagicSoundEnhancer);
          delete this._audioUniqueIdToAudioBuffer[audioUniqueId];
          delete this._audioUniqueIdToAudioBufferWithNoiseCancellation[audioUniqueId];
          delete this._audioUniqueIdToAudioBufferWithMagicSoundEnhancer[audioUniqueId];
        }else{
          if(unloadAudioBuffeUnprocesssed){
            delete this._audioUniqueIdWithAudioTypeToAudioBuffer[audioUniqueIdWithAudioTypeId];
            this.trigger("audioBufferUnloadedFromCache", audioUniqueIdWithAudioTypeId);
            delete this._audioUniqueIdToAudioBuffer[audioUniqueId]; 
          }
   
          if(unloadAudioBufferWithNoiseCancellationOnly){
            delete this._audioUniqueIdWithAudioTypeToAudioBuffer[audioUniqueIdWithAudioTypeIdWithNoiseCancellation];
            this.trigger("audioBufferUnloadedFromCache", audioUniqueIdWithAudioTypeIdWithNoiseCancellation);
            delete this._audioUniqueIdToAudioBufferWithNoiseCancellation[audioUniqueId];
          }
          if(unloadAudioBufferWithMagicSoundEnhancerOnly){
            delete this._audioUniqueIdWithAudioTypeToAudioBuffer[audioUniqueIdWithAudioTypeIdWithMagicSoundEnhancer];
            this.trigger("audioBufferUnloadedFromCache", audioUniqueIdWithAudioTypeIdWithMagicSoundEnhancer);
            delete this._audioUniqueIdToAudioBufferWithMagicSoundEnhancer[audioUniqueId];
          }
        }
      }.bind(this),
    );
  },

  getAudioBufferFromUrl: function (audioUrl, audioUniqueId, audioType, sequencerSettings, progressReportFunction) {
    return this.loadAudioBufferFromUrl(audioUrl, audioUniqueId, audioType, sequencerSettings, progressReportFunction);
  },

  _getUniqueIdForAudioBufferSlice: function (audioUniqueId, beginInMilliseconds, endInMilliseconds, audioType) {
    return (
      "audioUniqueId=" +
      audioUniqueId +
      "&beginInMilliseconds=" +
      beginInMilliseconds +
      "&endInMilliseconds=" +
      endInMilliseconds +
      "&audioType=" +
      audioType
    );
  },

  _getUniqueIdForGeneratedAudioBuffer: function (
    audioUniqueId,
    trimStart,
    trimEnd,
    durationInMilliseconds,
    loopToMatchWrapNodeDuration,
    audioType,
  ) {
    return (
      "audioUniqueId=" +
      audioUniqueId +
      "&trimStart=" +
      trimStart +
      "&trimEnd=" +
      trimEnd +
      "&durationInMilliseconds=" +
      durationInMilliseconds +
      "&loopToMatchWrapNodeDuration=" +
      !!loopToMatchWrapNodeDuration +
      "&audioType=" +
      audioType
    );
  },

  getAudioBufferSlice: function (audioUniqueId, beginInMilliseconds, endInMilliseconds, audioType, sequencerSettings) {
    const audioUniqueIdSlice = this._getUniqueIdForAudioBufferSlice(
      audioUniqueId,
      beginInMilliseconds,
      endInMilliseconds,
      audioType,
    );
    const noiseCancellationOn = audioType == AUDIO_TYPES.VOICE && sequencerSettings.isNoiseCancellationOn();
    const magicSoundEnhancerOn = audioType == AUDIO_TYPES.VOICE && sequencerSettings.isMagicSoundEnhancerOn();
    let existingAudioBufferSlice = this._audioUniqueIdSliceToAudioBuffer[audioUniqueIdSlice];
    if(noiseCancellationOn){
      existingAudioBufferSlice = this._audioUniqueIdSliceToAudioBufferWithNoiseCancellation[audioUniqueIdSlice]
    }
    if(magicSoundEnhancerOn){
      existingAudioBufferSlice = this._audioUniqueIdSliceToAudioBufferWithMagicSoundEnhancer[audioUniqueIdSlice]
    }
    if (existingAudioBufferSlice) {
      return RSVP.Promise.resolve(existingAudioBufferSlice);
    } else {
      return this.getAudioBufferFromUrl(audioUniqueId, null, audioType, sequencerSettings).then(
        function (audioBuffer) {
          const slicedAudioBuffer = this.getAudioBufferSliceFromBuffer(
            audioBuffer,
            beginInMilliseconds,
            endInMilliseconds,
          );
          if (
            this.isAudioTypeCachingAllowed(
              audioType,
              AUDIO_TYPES_SLICE_TO_NOT_CACHE,
              audioUniqueIdSlice,
              slicedAudioBuffer,
            )
          ) {
            if (noiseCancellationOn) {
              this._audioUniqueIdSliceToAudioBufferWithNoiseCancellation[audioUniqueIdSlice] = slicedAudioBuffer;
            } else {
              if (magicSoundEnhancerOn) {
                this._audioUniqueIdSliceToAudioBufferWithMagicSoundEnhancer[audioUniqueIdSlice] = slicedAudioBuffer;
              } else {
                this._audioUniqueIdSliceToAudioBuffer[audioUniqueIdSlice] = slicedAudioBuffer;
              }
            }
          }
          return slicedAudioBuffer;
        }.bind(this),
      );
    }
  },

  getAudioBufferSliceFromBuffer: function (audioBuffer, beginInMilliseconds, endInMilliseconds) {
    const duration = this.getMaxAudioBufferDuration(audioBuffer);
    const channels = audioBuffer.numberOfChannels;
    const rate = audioBuffer.sampleRate;
    if (!endInMilliseconds && endInMilliseconds !== 0) {
      endInMilliseconds = duration * 1000;
    }
    if (!beginInMilliseconds && beginInMilliseconds !== 0) {
      beginInMilliseconds = 0;
    }

    if (endInMilliseconds - beginInMilliseconds === 0) {
      return null;
    }

    const beginInSeconds = beginInMilliseconds / 1000;
    const endInSeconds = endInMilliseconds / 1000;

    const startOffset = rate * beginInSeconds;
    const endOffset = rate * endInSeconds;
    const frameCount = endOffset - startOffset;
    const slicedAudioBuffer = this._getAudioContext().createBuffer(channels, endOffset - startOffset, rate);

    const anotherArray = new Float32Array(frameCount);
    const offset = 0;

    for (let channel = 0; channel < channels; channel++) {
      audioBuffer.copyFromChannel(anotherArray, channel, startOffset);
      slicedAudioBuffer.copyToChannel(anotherArray, channel, offset);
    }

    return slicedAudioBuffer;
  },

  generateAudioBufferSilence: function (durationInMilliseconds, numberOfChannels) {
    return this._getAudioContext().createBuffer(
      numberOfChannels,
      Math.floor((durationInMilliseconds / 1000) * this._getAudioContext().sampleRate),
      this._getAudioContext().sampleRate,
    );
  },

  concatenateAudioBuffers: function (arrayOfAudioBuffers) {
    return RSVP.Promise.resolve(this._getCrunkerInstance().concatAudio(arrayOfAudioBuffers, true));
  },

  mergeMultipleAudioBuffers: function (audioBufferArray) {
    return RSVP.Promise.resolve(this._getCrunkerInstance().mergeAudio(audioBufferArray, true));
  },

  mergeMultipleAudioBuffersWithDuckingAndAddPadding: function (
    audioBufferArray,
    audioBufferToUseAsDuckingSource,
    arrayOfDuckingPropertiesForWrapAudioBuffer,
    arrayOfTotalDurationForWrapAudioBuffer,
    arrayOfDuckingMargin,
  ) {
    const promises = [];
    arrayOfDuckingPropertiesForWrapAudioBuffer.forEach(
      function (duckingTurnedOn, index) {
        const buffer = audioBufferArray[index];

        const audioBufferToMonitorForDucking = audioBufferToUseAsDuckingSource;
        const duckingStartMarginInMilliseconds = duckingTurnedOn ? arrayOfDuckingMargin[index].startMargin : 0;
        const duckingEndMarginInMilliseconds = duckingTurnedOn ? arrayOfDuckingMargin[index].endMargin : 0;
        const audioBufferToModify = audioBufferArray[index];
        const matchingSliceAudioToMonitor = this.getAudioBufferSliceFromBuffer(
          audioBufferToUseAsDuckingSource,
          duckingStartMarginInMilliseconds + arrayOfTotalDurationForWrapAudioBuffer[index],
        );
        const startMarginAudioBuffer =
          duckingStartMarginInMilliseconds > 0
            ? this.getAudioBufferSliceFromBuffer(audioBufferToModify, 0, duckingStartMarginInMilliseconds)
            : null;
        const endMarginAudioBuffer =
          duckingEndMarginInMilliseconds > 0
            ? this.getAudioBufferSliceFromBuffer(
                audioBufferToModify,
                this.getMaxAudioBufferDuration(audioBufferToModify) * 1000 - duckingEndMarginInMilliseconds,
              )
            : null;
        const audioBufferWithoutMarginToModify =
          startMarginAudioBuffer || endMarginAudioBuffer
            ? this.getAudioBufferSliceFromBuffer(
                audioBufferToModify,
                duckingStartMarginInMilliseconds,
                this.getMaxAudioBufferDuration(audioBufferToModify) * 1000 - duckingEndMarginInMilliseconds,
              )
            : audioBufferToModify;
        const duckedOperationPromise = duckingTurnedOn
          ? this.generateDuckedAudioBuffersWithDucking(audioBufferWithoutMarginToModify, matchingSliceAudioToMonitor)
          : RSVP.Promise.resolve(audioBufferArray[index]);
        const p = duckedOperationPromise
          .then(
            function (duckedAudioBufferWithoutMargin) {
              if (startMarginAudioBuffer || endMarginAudioBuffer) {
                const toConcatenate = [];
                if (startMarginAudioBuffer) {
                  toConcatenate.push(startMarginAudioBuffer);
                }
                toConcatenate.push(duckedAudioBufferWithoutMargin);
                if (endMarginAudioBuffer) {
                  toConcatenate.push(endMarginAudioBuffer);
                }
                return this.self.concatenateAudioBuffers(toConcatenate);
              } else {
                return duckedAudioBufferWithoutMargin;
              }
            }.bind({ indexToReplace: index, self: this }),
          )
          .then(
            function (duckedAudioBuffer) {
              audioBufferArray[this.indexToReplace] = duckedAudioBuffer;

              //return this.self.padAudioBuffer(duckedAudioBuffer, arrayOfTotalDurationForWrapAudioBuffer[this.indexToReplace], 0);
              return this.self.padAudioLeft(
                duckedAudioBuffer,
                arrayOfTotalDurationForWrapAudioBuffer[this.indexToReplace],
              );
            }.bind({ indexToReplace: index, self: this }),
          )
          .then(
            function (audioBufferDuckedWithPadding) {
              audioBufferArray[this.indexToReplace] = audioBufferDuckedWithPadding;
            }.bind({ indexToReplace: index }),
          );
        promises.push(p);
      }.bind(this),
    );

    return RSVP.Promise.all(promises).then(
      function () {
        return this.mergeMultipleAudioBuffers(audioBufferArray.concat(audioBufferToUseAsDuckingSource));
      }.bind(this),
    );
    /*return RSVP.Promise.resolve(this._getCrunkerInstance().mergeAudio(audioBufferArray, true)).then((function(audioBufferConcatnatedBeforeDucking){
        return this.mergeTwoAudioBuffersWithDucking( audioBufferToUseAsDuckingSource, audioBufferConcatnatedBeforeDucking);
    }).bind(this));*/
  },

  mergeMultipleAudioBuffersWithDucking: function (
    audioBufferArray,
    audioBufferToUseAsDuckingSource,
    arrayOfDuckingPropertiesForWrapAudioBuffer,
  ) {
    const promises = [];
    arrayOfDuckingPropertiesForWrapAudioBuffer.forEach(
      function (duckingTurnedOn, index) {
        const buffer = audioBufferArray[index];
        if (duckingTurnedOn) {
          const audioBufferToMonitorForDucking = audioBufferToUseAsDuckingSource;
          const p = this.generateDuckedudioBuffersWithDucking(
            audioBufferArray[index],
            audioBufferToUseAsDuckingSource,
          ).then(
            function (duckedAudioBuffer) {
              audioBufferArray[this.indexToReplace] = duckedAudioBuffer;
            }.bind({ indexToReplace: index }),
          );
          promises.push(p);
        }
      }.bind(this),
    );

    return RSVP.Promise.all(promises).then(
      function () {
        return this.mergeMultipleAudioBuffers(audioBufferArray.concat(audioBufferToUseAsDuckingSource));
      }.bind(this),
    );
    /*return RSVP.Promise.resolve(this._getCrunkerInstance().mergeAudio(audioBufferArray, true)).then((function(audioBufferConcatnatedBeforeDucking){
            return this.mergeTwoAudioBuffersWithDucking( audioBufferToUseAsDuckingSource, audioBufferConcatnatedBeforeDucking);
        }).bind(this));*/
  },

  mergeTwoAudioBuffersWithDucking: function (audioBufferToMonitor, audioBufferToModify) {
    return this.generateDuckedAudioBuffersWithDucking(audioBufferToModify, audioBufferToMonitor).then(
      function (duckedAudioBuffer) {
        return this.mergeMultipleAudioBuffers([duckedAudioBuffer, audioBufferToMonitor]);
      }.bind(this),
    );
  },

  padAudioLeft: function (buffer, durationInMilliseconds) {
    if (durationInMilliseconds > 0) {
      return this._getCrunkerInstance().concatAudio(
        [this.generateAudioBufferSilence(durationInMilliseconds, buffer.numberOfChannels), buffer],
        true,
      );
    } else {
      return buffer;
    }
  },

  padAudioRight: function (buffer, durationInMilliseconds) {
    if (durationInMilliseconds > 0) {
      return this._getCrunkerInstance().concatAudio(
        [buffer, this.generateAudioBufferSilence(durationInMilliseconds, buffer.numberOfChannels)],
        true,
      );
    } else {
      return buffer;
    }
  },

  loadAndInstatiateWasmModule: function (wasmLocation) {
    return fetch(wasmLocation)
      .then(
        function (response) {
          return response.arrayBuffer();
        }.bind(this),
      )
      .then(
        function (wasmBuffer) {
          return WebAssembly.instantiate(wasmBuffer);
        }.bind(this),
      );
  },

  denoiseAudioBuffer: function (audioBufferToDenoise) {
    const denoisedBuffer = this._getAudioContext().createBuffer(
      audioBufferToDenoise.numberOfChannels,
      audioBufferToDenoise.length,
      audioBufferToDenoise.sampleRate,
    );

    // Create a Float32Array to hold the original channel data
    const originalChannelData = new Float32Array(audioBufferToDenoise.length);
    audioBufferToDenoise.copyFromChannel(originalChannelData, 0);

    // Denoise the channel data
    const denoisedBufferChannelData = RNNoise(originalChannelData, audioBufferToDenoise.sampleRate);

    // Copy the denoised data into each channel of the new buffer
    for (let channelNumber = 0; channelNumber < denoisedBuffer.numberOfChannels; channelNumber++) {
      denoisedBuffer.copyToChannel(denoisedBufferChannelData, channelNumber);
    }

    return denoisedBuffer;
  },

  generateDuckedAudioBuffersWithDucking: function (
    audioBufferToModify,
    audioBufferToMonitor,
    startMarginInMilliseconds,
    endMargininMilliseconds,
  ) {
    const fadeinAndOutInMilliseconds =
      this.getMaxAudioBufferDuration(audioBufferToModify) * 1000 > DUCKING_DEFAULT_FADE_DURATION_IN_MILLISECONDS * 2
        ? DUCKING_DEFAULT_FADE_DURATION_IN_MILLISECONDS
        : 0;
    const startMarginAudioBuffer =
      startMarginInMilliseconds > 0
        ? this.getAudioBufferSliceFromBuffer(audioBufferToModify, 0, startMarginInMilliseconds)
        : null;
    const endMarginAudioBuffer =
      endMargininMilliseconds > 0
        ? this.getAudioBufferSliceFromBuffer(
            audioBufferToModify,
            this.getMaxAudioBufferDuration(audioBufferToModify) * 1000 - endMargininMilliseconds,
          )
        : null;
    const audioBufferWithoutMargin =
      startMarginAudioBuffer || endMarginAudioBuffer
        ? this.getAudioBufferSliceFromBuffer(
            audioBufferToModify,
            startMarginInMilliseconds,
            this.getMaxAudioBufferDuration(audioBufferToModify) * 1000 - endMargininMilliseconds,
          )
        : audioBufferToModify;
    const lookAheadTimeInSeconds = 0;
    const bufferArray = [audioBufferToMonitor, audioBufferWithoutMargin];
    const outputNumberOfChannels = this._getCrunkerInstance()._maxNumberOfChannels(bufferArray);
    const offlineCtx = new OfflineAudioContext(
      outputNumberOfChannels,
      audioBufferToMonitor.sampleRate *
        (this.getMaxAudioBufferDuration(audioBufferWithoutMargin) + lookAheadTimeInSeconds),
      audioBufferToMonitor.sampleRate,
    );
    let sourceToModulateNode = null;
    let compressorWithSideChainNode = null;
    let sourceNode = null;
    return offlineCtx.audioWorklet
      .addModule("js/models/audioEditor/processors/compressor-with-sidechain-processor.js")
      .catch(
        function (error) {
          //This error probably occurs when the module is already registered
        }.bind(this),
      )
      .then(
        function () {
          const workletNodeParams = {
            numberOfInputs: 2,
            numberOfOutputs: 1,
            outputChannelCount: [outputNumberOfChannels],
            parameterData: { lookAheadTime: lookAheadTimeInSeconds },
          };
          compressorWithSideChainNode = new AudioWorkletNode(
            offlineCtx,
            "compressor-with-sidechain",
            workletNodeParams,
          );

          sourceToModulateNode = offlineCtx.createBufferSource();
          sourceToModulateNode.buffer = this.padAudioRight(audioBufferWithoutMargin, lookAheadTimeInSeconds * 1000);
          sourceToModulateNode.connect(compressorWithSideChainNode, 0, 0);

          sourceNode = offlineCtx.createBufferSource();
          sourceNode.buffer = audioBufferToMonitor;
          sourceNode.connect(compressorWithSideChainNode, 0, 1);

          compressorWithSideChainNode.connect(offlineCtx.destination);
          sourceNode.start();
          sourceToModulateNode.start();

          return offlineCtx.startRendering();
        }.bind(this),
      )
      .then(
        function (renderedAudioBufferWithLookaheadTime) {
          this.disconnectAudioNodesAndCloseContext([
            sourceToModulateNode,
            sourceNode,
            compressorWithSideChainNode,
            offlineCtx.destination,
          ]);
          if (lookAheadTimeInSeconds > 0) {
            const renderedAudioBuffer = this.getAudioBufferSliceFromBuffer(
              renderedAudioBufferWithLookaheadTime,
              lookAheadTimeInSeconds * 1000,
            );
            return renderedAudioBuffer;
          } else {
            return renderedAudioBufferWithLookaheadTime;
          }
        }.bind(this),
      )
      .then(
        function (duckedAudioBuffer) {
          if (startMarginAudioBuffer || endMarginAudioBuffer) {
            const toConcatenate = [];
            if (startMarginAudioBuffer) {
              toConcatenate.push(startMarginAudioBuffer);
            }
            toConcatenate.push(duckedAudioBuffer);
            if (endMarginAudioBuffer) {
              toConcatenate.push(endMarginAudioBuffer);
            }
            return this.concatenateAudioBuffers(toConcatenate);
          } else {
            return duckedAudioBuffer;
          }
        }.bind(this),
      )
      .then(
        function (duckedAudioBufferWihoutFading) {
          if (fadeinAndOutInMilliseconds) {
            return this.applyFadeInAndFadeOutToAudioBuffer(
              duckedAudioBufferWihoutFading,
              0,
              1,
              fadeinAndOutInMilliseconds,
              1,
              0,
              fadeinAndOutInMilliseconds,
              true,
            );
          }
          return duckedAudioBufferWihoutFading;
        }.bind(this),
      );
  },

  padAudioBuffer: function (audioBuffer, paddingInMilliseconds, padStartInMilliseconds) {
    let padStartInSeconds = 0;
    if (padStartInMilliseconds) {
      padStartInSeconds = padStartInMilliseconds / 1000;
    }
    return RSVP.Promise.resolve(
      this._getCrunkerInstance().padAudio(audioBuffer, padStartInSeconds, paddingInMilliseconds / 1000),
    );
  },

  playAudioBuffer: function (
    audioBuffer,
    whenToPlayInMilliseconds,
    offsetStartPositionInMilliseconds,
    onAudioFinishoPlayingHandler,
    onAudioStartingPlayingHandler,
    onAudioErrorPlayingHandler,
  ) {
    const audioBufferPlayer = new AudioBufferPlayer({
      audioBuffer: audioBuffer,
      audioContext: this._getAudioContext(),
      whenToPlayInMilliseconds: whenToPlayInMilliseconds,
      offsetStartPositionInMilliseconds: offsetStartPositionInMilliseconds,
      onAudioFinishPlayingHandler: onAudioFinishoPlayingHandler,
      onAudioStartingPlayingHandler: onAudioStartingPlayingHandler,
      onAudioErrorPlayingHandler: onAudioErrorPlayingHandler,
    });
    audioBufferPlayer.start();
    return audioBufferPlayer;
  },

  playAudioUrl: function (
    audioFileUrl,
    whenToPlayInMilliseconds,
    offsetStartPositionInMilliseconds,
    onAudioFinishoPlayingHandler,
    onAudioStartingPlayingHandler,
    onAudioErrorPlayingHandler,
  ) {
    const audioBufferPlayer = new AudioBufferPlayer({
      audioFileUrl: audioFileUrl,
      audioContext: this._getAudioContext(),
      whenToPlayInMilliseconds: whenToPlayInMilliseconds,
      offsetStartPositionInMilliseconds: offsetStartPositionInMilliseconds,
      onAudioFinishPlayingHandler: onAudioFinishoPlayingHandler,
      onAudioStartingPlayingHandler: onAudioStartingPlayingHandler,
      onAudioErrorPlayingHandler: onAudioErrorPlayingHandler,
    });
    audioBufferPlayer.start();
    return audioBufferPlayer;
  },

  isBufferAtTheSameSampleRateAsAudioContext: function (audioBuffer) {
    return this._getAudioContext().sampleRate === audioBuffer.sampleRate;
  },
  resampleAudioBufferToMatchContextSampleRate: function (audioBuffer) {
    if (this.isBufferAtTheSameSampleRateAsAudioContext(audioBuffer)) {
      return RSVP.Promise.resolve(audioBuffer);
    } else {
      const offlineCtx = new OfflineAudioContext(
        audioBuffer.numberOfChannels,
        this.getMaxAudioBufferDuration(audioBuffer) * this._getAudioContext().sampleRate,
        this._getAudioContext().sampleRate,
      );
      const sourceNode = offlineCtx.createBufferSource();
      sourceNode.buffer = audioBuffer;
      sourceNode.playbackRate.value = offlineCtx.sampleRate / sourceNode.buffer.sampleRate; //Adjust playback speed
      sourceNode.connect(offlineCtx.destination);
      sourceNode.start();
      return offlineCtx.startRendering().then(
        function (renderedAudioBuffer) {
          this.disconnectAudioNodesAndCloseContext([sourceNode, offlineCtx.destination]);
          return renderedAudioBuffer;
        }.bind(this),
      );
      //return sourceNode;
    }
  },

  getPlaybackTimeForSourceNode: function (sourceNode) {
    return sourceNode.getPlaybackTimeInMilliseconds();
  },

  getAudioContextCurrentTimeForSourceNode: function (sourceNode) {
    return sourceNode.getAudioContextCurrentTime();
  },

  canPlayThrough: function (sourceNode) {
    return sourceNode.canPlayThrough();
  },

  applyFiltersToAudioBuffer: function (
    audioBuffer,
    audioFilters,
    filterIndexToApply,
    offlineCtx,
    startNode,
    previousOutputNode,
    arrayOfAudioNodesUsed,
  ) {
    if (!filterIndexToApply) {
      filterIndexToApply = 0;
    }
    if (!audioFilters || audioFilters.length == 0) {
      return RSVP.Promise.resolve(audioBuffer);
    }
    if (filterIndexToApply >= audioFilters.length) {
      previousOutputNode.connect(offlineCtx.destination);
      startNode.start();
      return offlineCtx.startRendering().then(
        function (renderedAudioBuffer) {
          this.disconnectAudioNodesAndCloseContext(arrayOfAudioNodesUsed);
          return renderedAudioBuffer;
        }.bind(this),
      );
    } else {
      if (filterIndexToApply == 0) {
        offlineCtx = new OfflineAudioContext(
          audioBuffer.numberOfChannels,
          audioBuffer.sampleRate * this.getMaxAudioBufferDuration(audioBuffer),
          audioBuffer.sampleRate,
        );
        startNode = offlineCtx.createBufferSource();
        startNode.buffer = audioBuffer;
        previousOutputNode = startNode;
        arrayOfAudioNodesUsed = [startNode, offlineCtx.destination];
      }
      const audioFilter = audioFilters[filterIndexToApply];
      return audioFilter
        .applyFilter(previousOutputNode, offlineCtx, arrayOfAudioNodesUsed, null, null, null, audioBuffer)
        .then(
          function (newOutputNode) {
            return this.applyFiltersToAudioBuffer(
              audioBuffer,
              audioFilters,
              filterIndexToApply + 1,
              offlineCtx,
              startNode,
              newOutputNode,
              arrayOfAudioNodesUsed,
            );
          }.bind(this),
        );
    }
  },

  applyFilterToAudioBuffer: function (audioBuffer, audioFilter) {
    if (AudioFilterFactory.getInstance().isGainFilterInstance(audioFilter)) {
      return this.applyGainFilterToAudioBuffer(audioBuffer, audioFilter);
    }
    if (AudioFilterFactory.getInstance().isFadeStartFilterInstance(audioFilter)) {
      return this.applyFadeStartFilterToAudioBuffer(audioBuffer, audioFilter);
    }
    if (AudioFilterFactory.getInstance().isFadeEndFilterInstance(audioFilter)) {
      return this.applyFadeEndFilterToAudioBuffer(audioBuffer, audioFilter);
    }
    return audioBuffer;
  },

  applyGainFilterToAudioBuffer: function (audioBuffer, gainFilter) {
    if (audioBuffer && gainFilter) {
      const gainValue = gainFilter.getGainValue();
      const offlineCtx = new OfflineAudioContext(
        audioBuffer.numberOfChannels,
        audioBuffer.sampleRate * this.getMaxAudioBufferDuration(audioBuffer),
        audioBuffer.sampleRate,
      );
      const source = offlineCtx.createBufferSource();
      source.buffer = audioBuffer;
      const gainNode = offlineCtx.createGain();
      gainNode.gain.value = gainValue;
      source.connect(gainNode);
      gainNode.connect(offlineCtx.destination);
      source.start();
      return offlineCtx.startRendering().then(
        function (renderedAudioBuffer) {
          this.disconnectAudioNodesAndCloseContext([source, gainNode, offlineCtx.destination]);
          return renderedAudioBuffer;
        }.bind(this),
      );
    } else {
      return RSVP.Promise.resolve(audioBuffer);
    }
  },

  getMeasuredLoudness: function (audioBuffer) {
    return IntegratedLoudnessHelper.getInstance().getIntegratedLoudness(audioBuffer);
  },

  getTruePeakLoudness: function (audioBuffer) {
    return TruePeakHelper.getInstance()
      .getTruePeakData(audioBuffer)
      .then(
        function (truePeakData) {
          return truePeakData && truePeakData.maxTruePeak;
        }.bind(this),
      );
  },

  ebuNormalizationOld: function (audioBuffer, targetDBLevel) {
    const offlineCtx = new OfflineAudioContext(
      audioBuffer.numberOfChannels,
      audioBuffer.sampleRate * this.getMaxAudioBufferDuration(audioBuffer),
      audioBuffer.sampleRate,
    );
    const startNode = offlineCtx.createBufferSource();
    startNode.buffer = audioBuffer;
    const ebuFilter = AudioFilterFactory.getInstance().createEBULoudnessNormalizationFilter(targetDBLevel);
    const arrayOfAudioNodesUsed = [startNode, offlineCtx.destination];
    return ebuFilter
      .applyFilter(startNode, offlineCtx, arrayOfAudioNodesUsed, null, null, null, audioBuffer)
      .then(
        function (outputNode) {
          outputNode.connect(offlineCtx.destination);
          startNode.start();
          return offlineCtx.startRendering();
        }.bind(this),
      )
      .then(
        function (normalizedAudioBuffer) {
          this.disconnectAudioNodesAndCloseContext(arrayOfAudioNodesUsed);
          return normalizedAudioBuffer;
        }.bind(this),
      );
  },

  ebuNormalization: function (audioBuffer, targetLoudness) {
    return this.getMeasuredLoudness(audioBuffer).then(
      function (measuredLoundess) {
        if (measuredLoundess != targetLoudness && !isNaN(measuredLoundess) && isFinite(measuredLoundess)) {
          const newGainAmp = AudioBufferUtils.getInstance().getAmpFromDb(targetLoudness - measuredLoundess);
          const gainFilter = AudioFilterFactory.getInstance().createGainFilter(newGainAmp);
          return this.applyFiltersToAudioBuffer(audioBuffer, [gainFilter]);
        } else {
          return audioBuffer;
        }
      }.bind(this),
    );
  },

  applyFadeInToAudioBuffer: function (audioBuffer, fadeFrom, fadeTo, fadeDuration, isEqualPowerFade) {
    const fadeFilter = AudioFilterFactory.getInstance().createFadeStartFilter(
      fadeFrom,
      fadeTo,
      fadeDuration,
      isEqualPowerFade,
    );
    return this.applyFiltersToAudioBuffer(audioBuffer, [fadeFilter]);
  },

  applyFadeOutToAudioBuffer: function (audioBuffer, fadeFrom, fadeTo, fadeDuration, isEqualPowerFade) {
    const fadeFilter = AudioFilterFactory.getInstance().createFadeEndFilter(
      fadeFrom,
      fadeTo,
      fadeDuration,
      isEqualPowerFade,
    );
    return this.applyFiltersToAudioBuffer(audioBuffer, [fadeFilter]);
  },

  applyFadeInAndFadeOutToAudioBuffer: function (
    audioBuffer,
    fadeFromIn,
    fadeToIn,
    fadeDurationIn,
    fadeFromOut,
    fadeToOut,
    fadeDurationOut,
    isEqualPowerFade,
  ) {
    const fadeFilterIn = AudioFilterFactory.getInstance().createFadeStartFilter(
      fadeFromIn,
      fadeToIn,
      fadeDurationIn,
      isEqualPowerFade,
    );
    const fadeFilterOut = AudioFilterFactory.getInstance().createFadeEndFilter(
      fadeFromOut,
      fadeToOut,
      fadeDurationOut,
      isEqualPowerFade,
    );
    return this.applyFiltersToAudioBuffer(audioBuffer, [fadeFilterIn, fadeFilterOut]);
  },

  applyFadeStartFilterToAudioBuffer: function (audioBuffer, fadeFilter) {
    if (audioBuffer && fadeFilter) {
      let fromValue = fadeFilter.getFadeFrom();
      let toValue = fadeFilter.getFadeTo();
      const fadeDurationInMilliseconds = fadeFilter.getFadeDuration();
      if (!fromValue) {
        fromValue = 0.01; //This is because the gain will throw an error if the value is 0
      }
      if (!toValue) {
        toValue = 0.01; //This is because the gain will throw an error if the value is 0
      }

      const offlineCtx = new OfflineAudioContext(
        audioBuffer.numberOfChannels,
        audioBuffer.sampleRate * this.getMaxAudioBufferDuration(audioBuffer),
        audioBuffer.sampleRate,
      );
      const source = offlineCtx.createBufferSource();
      source.buffer = audioBuffer;
      const gainNode = offlineCtx.createGain();
      let startTime = offlineCtx.currentTime;
      let endTime = startTime + fadeDurationInMilliseconds / 1000;
      if (endTime < 0) {
        endTime = 0;
      }
      if (startTime < 0) {
        startTime = 0;
      }
      gainNode.gain.setValueAtTime(fromValue, startTime);
      gainNode.gain.exponentialRampToValueAtTime(toValue, endTime);
      source.connect(gainNode);
      gainNode.connect(offlineCtx.destination);
      source.start();
      return offlineCtx.startRendering().then(
        function (renderedAudioBuffer) {
          this.disconnectAudioNodesAndCloseContext([source, gainNode, offlineCtx.destination]);
          return renderedAudioBuffer;
        }.bind(this),
      );
    } else {
      return RSVP.Promise.resolve(audioBuffer);
    }
  },

  export: function (buffer, type, progressReportFunction) {
    if (!type) {
      type = "audio/mpeg";
    }
    /*if ('AudioEncoder' in window && false) {
      return this.exportViaWebCodecs(buffer, type, progressReportFunction)
    }*/

    return this.resampleAudioBufferToMatchContextSampleRate(buffer).then(
      function (resampledAudioBuffer) {
        if (type === "audio/mp3" || type === "audio/mpeg") {
          return EXPORT_MP3_USING_A_WORKER
            ? this.exportInMP3UsingWorkerInChunk(buffer, 192, progressReportFunction, false)
            : this.exportInMP3UsingMainThread(buffer, null, progressReportFunction);
        } else {
          //return this.exportInWavUsingRecorder(resampledAudioBuffer, type, progressReportFunction)
          return this.exportInWavFromWebWorkerInChunk(resampledAudioBuffer, progressReportFunction);
          //return this.exportInWavFromWebWorkerAndSharedArrayBuffer(resampledAudioBuffer, progressReportFunction);
          //return this._getCrunkerInstance().export(resampledAudioBuffer, type, 16, progressReportFunction);
        }
      }.bind(this),
    );
  },

  exportViaWebCodecs: function (audioBuffer, mimeType, progressReportFunction, bitrate) {
    return new RSVP.Promise(
      function (resolve, reject) {
        let codec = Utils.getInstance().getFileExtenstionForMimeType(mimeType);
        if (!codec) {
          codec = "mp3";
        }
        if (!bitrate) {
          bitrate = 320;
        }
        const sampleRate = audioBuffer.sampleRate;
        //const codec = mimeType === "audio/mp3" || codec === "audio/mpeg"?"mp3":"wav";
        const encoderWorker = new Worker("js/models/audioEditor/processors/AudioEncoderWorker.js");

        // Prepare audio data for the worker using copyFromChannel
        const audioBuffers = [];
        for (let i = 0; i < audioBuffer.numberOfChannels; i++) {
          const channelData = new Float32Array(audioBuffer.length);
          audioBuffer.copyFromChannel(channelData, i);
          audioBuffers.push(channelData);
        }

        encoderWorker.postMessage({
          type: "encodeAudio",
          data: {
            audioBuffers: audioBuffers,
            sampleRate: sampleRate,
            codec: codec,
            bitrate: bitrate,
          },
        });

        let encodedChunks = [];
        const blobResult = new Blob(encodedChunks, { type: mimeType });

        encoderWorker.onmessage = function (event) {
          const { type, data } = event.data;

          switch (type) {
            case "encodedChunk":
              encodedChunks.push(data);
              break;
            case "progress":
              if (progressReportFunction) {
                progressReportFunction(data);
              }
              break;
            case "error":
              reject(new Error(data));
              encoderWorker.terminate();
              break;
            case "finished":
              resolve({
                blob: blobResult,
                url: this._getCrunkerInstance()._renderURL(blobResult),
                element: this._getCrunkerInstance()._renderAudioElement(blobResult),
              });
              encoderWorker.terminate();
              break;
          }
        };
      }.bind(this),
    );
  },

  exportInMP3UsingWorker: function (audioBuffer, kpbs, progressReportFunction, transferData) {
    return new RSVP.Promise(
      function (resolve, reject) {
        try {
          const sampleRate = audioBuffer.sampleRate;
          const numberOfChannels = audioBuffer.numberOfChannels;
          const mp3Worker = new Worker("js/libs/lamejs/lamejs-worker.js");
          mp3Worker.postMessage({
            cmd: "init",
            config: {
              sampleRate: sampleRate,
              numberOfChannels: numberOfChannels,
              kpbs: kpbs,
              transferRawInput: transferData,
            },
          });
          mp3Worker.onmessage = function (e) {
            if (e.data.cmd == "end") {
              const blob = new Blob(e.data.buf, { type: "audio/mp3" });
              const finalSize = blob.size;
              if (progressReportFunction) {
                progressReportFunction(100);
              }
              resolve({
                blob: blob,
                url: this._getCrunkerInstance()._renderURL(blob),
                element: this._getCrunkerInstance()._renderAudioElement(blob),
              });
              mp3Worker.terminate();
            } else if (e.data.cmd == "progress") {
              if (progressReportFunction) {
                progressReportFunction(e.data.progress);
              }
            } else if (e.data.cmd == "transferRawInput") {
              if (e.data.rawInput) {
                if (e.data.rawInput.ld) {
                  audioBuffer.getChannelData(0).set(e.data.rawInput.ld);
                }
                if (e.data.rawInput.rd) {
                  audioBuffer.getChannelData(1).set(e.data.rawInput.rd);
                }
              }
            } else if (e.data.cmd == "error") {
              reject(e.data);
              mp3Worker.terminate();
            }
          }.bind(this);

          // Create typed arrays to hold the channel data.
          const ld = new Float32Array(audioBuffer.length);
          const rd = numberOfChannels > 1 ? new Float32Array(audioBuffer.length) : null;

          // Copy the channel data from the audioBuffer into the typed arrays.
          audioBuffer.copyFromChannel(ld, 0);
          if (numberOfChannels > 1) {
            audioBuffer.copyFromChannel(rd, 1);
          }

          // Prepare the transferable objects.
          const dataToTransfer = [ld.buffer];
          if (rd) {
            dataToTransfer.push(rd.buffer);
          }

          mp3Worker.postMessage({ cmd: "encode", rawInput: { ld: ld, rd: rd } }, transferData ? dataToTransfer : null);
        } catch (error) {
          reject(error);
        }
      }.bind(this),
    );
  },

  exportInMP3UsingWorkerInChunk: function (audioBuffer, kbps, progressReportFunction, transferData) {
    return new RSVP.Promise(
      function (resolve, reject) {
        try {
          const sampleRate = audioBuffer.sampleRate;
          const numberOfChannels = audioBuffer.numberOfChannels;
          const mp3Worker = new Worker("js/libs/lamejs/lamejs-worker-in-chunks.js");
          mp3Worker.postMessage({
            cmd: "init",
            config: {
              sampleRate: sampleRate,
              numberOfChannels: numberOfChannels,
              kbps: kbps,
              transferRawInput: transferData,
            },
          });

          const chunkSize = 4096; // Choose a suitable chunk size
          const totalChunks = Math.ceil(audioBuffer.length / chunkSize);
          let sequence = 0;

          const processNextChunk = function () {
            if (sequence < totalChunks) {
              const ld = new Float32Array(chunkSize);
              const rd = numberOfChannels > 1 ? new Float32Array(chunkSize) : null;

              // Copy the channel data from the audioBuffer into the typed arrays.
              audioBuffer.copyFromChannel(ld, 0, sequence * chunkSize);
              if (numberOfChannels > 1) {
                audioBuffer.copyFromChannel(rd, 1, sequence * chunkSize);
              }

              // Prepare the transferable objects.
              const dataToTransfer = [ld.buffer];
              if (rd) {
                dataToTransfer.push(rd.buffer);
              }

              mp3Worker.postMessage(
                { cmd: "encode", rawInput: { ld: ld, rd: rd } },
                transferData ? dataToTransfer : null,
              );
              sequence++;

              // Report progress
              if (progressReportFunction) {
                progressReportFunction((sequence * 100) / totalChunks);
              }
            } else {
              // Signal the worker to finalize and encode the MP3
              mp3Worker.postMessage({ cmd: "finalize" });
            }
          };

          mp3Worker.onmessage = function (e) {
            if (e.data.cmd === "end") {
              const blob = new Blob(e.data.buf, { type: "audio/mp3" });
              const finalSize = blob.size;
              if (progressReportFunction) {
                progressReportFunction(100);
              }
              resolve({
                blob: blob,
                url: this._getCrunkerInstance()._renderURL(blob),
                element: this._getCrunkerInstance()._renderAudioElement(blob),
              });
              mp3Worker.terminate();
            } else if (e.data.cmd === "progress") {
              processNextChunk();
            } else if (e.data.cmd === "transferRawInput") {
              if (e.data.rawInput) {
                if (e.data.rawInput.ld) {
                  audioBuffer.getChannelData(0).set(e.data.rawInput.ld);
                }
                if (e.data.rawInput.rd) {
                  audioBuffer.getChannelData(1).set(e.data.rawInput.rd);
                }
              }
            } else if (e.data.cmd === "error") {
              reject(e.data);
              mp3Worker.terminate();
            }
          }.bind(this);

          // Start the encoding process by sending the first chunk
          processNextChunk();
        } catch (error) {
          reject(error);
        }
      }.bind(this),
    );
  },

  exportInWavFromWebWorkerInChunk: function (audioBuffer, progressReportFunction) {
    return new Promise(
      function (resolve, reject) {
        try {
          const numberOfChannels = audioBuffer.numberOfChannels;
          const sampleRate = audioBuffer.sampleRate;
          const worker = new Worker("js/models/audioEditor/processors/progressiveWavEncoder.js"); // Path to the worker file
          const chunkSize = 4096; // Number of frames per chunk
          const totalChunks = Math.ceil(audioBuffer.length / chunkSize);
          let sequence = 0;

          worker.postMessage({
            init: true,
            numChannels: numberOfChannels,
            sampleRate: sampleRate,
          });

          const processNextChunk = function () {
            if (sequence < totalChunks) {
              const startFrame = sequence * chunkSize;
              const endFrame = Math.min(startFrame + chunkSize, audioBuffer.length);
              const chunkLength = (endFrame - startFrame) * numberOfChannels;
              const audioData = new Float32Array(chunkLength);

              for (let i = startFrame; i < endFrame; i++) {
                for (let channel = 0; channel < numberOfChannels; channel++) {
                  audioData[(i - startFrame) * numberOfChannels + channel] = audioBuffer.getChannelData(channel)[i];
                }
              }

              // Post the interleaved audio data to the worker
              worker.postMessage({ audioData: audioData.buffer }, [audioData.buffer]);
              sequence++;

              // Report progress
              if (progressReportFunction) {
                progressReportFunction((sequence * 100) / totalChunks);
              }
            } else {
              // Signal the worker to finalize the WAV file
              worker.postMessage({ finalize: true });
            }
          };

          worker.onmessage = function (e) {
            if (e.data.progress) {
              processNextChunk();
            } else if (e.data.wavFile) {
              const blob = new Blob([e.data.wavFile], { type: "audio/wav" });
              resolve({
                blob: blob,
                url: this._getCrunkerInstance()._renderURL(blob),
                element: this._getCrunkerInstance()._renderAudioElement(blob),
              });
              worker.terminate();
            }
          }.bind(this);

          // Start processing the first chunk
          processNextChunk();
        } catch (error) {
          reject(error);
        }
      }.bind(this),
    );
  },

  exportInWavFromWebWorker: function (audioBuffer, progressFunction, transferData) {
    return new RSVP.Promise(
      function (resolve, reject) {
        // Create the Web Worker

        const channels = [];
        for (let i = 0; i < audioBuffer.numberOfChannels; i++) {
          const channel = new Float32Array(audioBuffer.length);
          audioBuffer.copyFromChannel(channel, i);
          channels.push(channel);
        }

        const worker = new Worker("js/models/audioEditor/processors/wavEncoder.js");

        // Send the audio data to the worker
        worker.postMessage(
          {
            channels,
            sampleRate: audioBuffer.sampleRate,
            length: audioBuffer.length,
            transferChannels: transferData,
          },
          transferData
            ? channels.map(function (channel) {
                return channel.buffer;
              })
            : null,
        );

        // Handle messages from the worker
        worker.onmessage = function (e) {
          if (e.data.progress !== undefined && progressFunction) {
            // Report progress
            if (progressFunction) progressFunction(e.data.progress);
          } else if (e.data.channels) {
            // Copy the returned channels into the new AudioBuffer
            for (let i = 0; i < e.data.channels.length; i++) {
              audioBuffer.getChannelData(i).set(e.data.channels[i]);
            }
          } else {
            if (e.data.wavBlob) {
              // Receive the blob from the worker
              const blob = e.data.wavBlob;

              // Resolve the promise with the blob
              resolve({
                blob: blob,
                url: this._getCrunkerInstance()._renderURL(blob),
                element: this._getCrunkerInstance()._renderAudioElement(blob),
              });

              // Terminate the worker
              worker.terminate();
            }
          }
        }.bind(this);

        // Handle errors from the worker
        worker.onerror = function (error) {
          reject(error);

          // Terminate the worker
          worker.terminate();
        };
      }.bind(this),
    );
  },

  exportInWavFromWebWorkerAndSharedArrayBuffer: function (audioBuffer, progressFunction) {
    return new RSVP.Promise(
      function (resolve, reject) {
        // Create the Web Worker

        const sharedBuffer = new SharedArrayBuffer(audioBuffer.length * audioBuffer.numberOfChannels * 4);
        const sharedArray = new Float32Array(sharedBuffer);

        // Copy the audio data from the AudioBuffer into the SharedArrayBuffer
        for (let channel = 0; channel < audioBuffer.numberOfChannels; channel++) {
          const channelData = new Float32Array(audioBuffer.length);
          audioBuffer.copyFromChannel(channelData, channel);
          sharedArray.set(channelData, channel * audioBuffer.length);
        }

        const worker = new Worker("js/models/audioEditor/processors/wavEncoderWithSharedArrayBuffer.js");

        // Send the audio data to the worker
        worker.postMessage({
          sharedBuffer,
          numberOfChannels: audioBuffer.numberOfChannels,
          sampleRate: audioBuffer.sampleRate,
          length: audioBuffer.length,
        });

        // Handle messages from the worker
        worker.onmessage = function (e) {
          if (e.data.progress !== undefined && progressFunction) {
            // Report progress
            if (progressFunction) progressFunction(e.data.progress);
          } else {
            if (e.data.wavBlob) {
              // Receive the blob from the worker
              const blob = e.data.wavBlob;

              // Resolve the promise with the blob
              resolve({
                blob: blob,
                url: this._getCrunkerInstance()._renderURL(blob),
                element: this._getCrunkerInstance()._renderAudioElement(blob),
              });

              // Terminate the worker
              worker.terminate();
            }
          }
        }.bind(this);

        // Handle errors from the worker
        worker.onerror = function (error) {
          reject(error);

          // Terminate the worker
          worker.terminate();
        };
      }.bind(this),
    );
  },

  exportInWavUsingRecorder: function (audioBuffer, type, progressReportFunction) {
    const recorderInstance = new Recorder(
      {
        connect: function () {},
        context: { sampleRate: audioBuffer.sampleRate },
      },
      {
        processor: {},
        numChannels: audioBuffer.numberOfChannels,
        nodeAlreadyConnected: true,
      },
    );
    return new RSVP.Promise(
      function (resolve, reject) {
        try {
          const channelData = [];
          for (let i = 0; i < audioBuffer.numberOfChannels; i++) {
            const channel = new Float32Array(audioBuffer.length);
            audioBuffer.copyFromChannel(channel, i);
            channelData.push(channel);
          }

          recorderInstance.addBuffer(audioBuffer.numberOfChannels === 1 ? [channelData[0]] : channelData);
          const onExportCompleted = function (blob) {
            try {
              recorderInstance.worker.terminate();
            } catch (error) {
              console.error("Failed to terminate worker during WAV export. Error: " + error);
            }
            return resolve({
              blob: blob,
              url: this._getCrunkerInstance()._renderURL(blob),
              element: this._getCrunkerInstance()._renderAudioElement(blob),
            });
          }.bind(this);
          recorderInstance.exportWAV(onExportCompleted, null, progressReportFunction);
        } catch (error) {
          reject(error);
        }
      }.bind(this),
    );
  },

  exportInMP3UsingMainThread: function (audioBuffer, kpbs, progressReportFunction) {
    const sampleRate = audioBuffer.sampleRate;
    const numberOfChannels = audioBuffer.numberOfChannels;
    const len = audioBuffer.length;
    const ld = new Float32Array(len);
    const rd = numberOfChannels > 1 ? new Float32Array(len) : null;

    // Copy channel data using copyFromChannel
    audioBuffer.copyFromChannel(ld, 0);
    if (numberOfChannels > 1) {
      audioBuffer.copyFromChannel(rd, 1);
    }

    const leftDataAsInt16Array = new Int16Array(len);
    const rightDataAsInt16Array = numberOfChannels > 1 ? new Int16Array(len) : null;
    const convertToInt16Array = function (n) {
      const v = n < 0 ? n * 32768 : n * 32767;
      return Math.max(-32768, Math.min(32768, v));
    };
    let i;
    while (i < len) {
      leftDataAsInt16Array[i] = convertToInt16Array(ld[i++]);
      if (numberOfChannels > 1) {
        rightDataAsInt16Array[i] = convertToInt16Array(rd[i++]);
      }
    }

    const mp3Encoder = new LameJS.Mp3Encoder(
      numberOfChannels,
      sampleRate,
      kpbs ? kpbs : numberOfChannels > 1 ? 256 : 128,
    );

    const blockSize = 1152;
    const blocks = [];
    let mp3Buffer;

    let length = leftDataAsInt16Array.length;
    for (let i = 0; i < length; i += blockSize) {
      if (progressReportFunction) {
        progressReportFunction((i / length) * 100);
      }
      const lc = leftDataAsInt16Array.subarray(i, i + blockSize);
      const rc = rightDataAsInt16Array ? rightDataAsInt16Array.subarray(i, i + blockSize) : null;
      mp3Buffer = mp3Encoder.encodeBuffer(lc, rc);
      if (mp3Buffer.length > 0) blocks.push(mp3Buffer);
    }
    mp3Buffer = mp3Encoder.flush();
    if (mp3Buffer.length > 0) blocks.push(mp3Buffer);
    if (progressReportFunction) {
      progressReportFunction(100);
    }
    const mp3Blob = new Blob(blocks, { type: "audio/mpeg" });

    return {
      blob: mp3Blob,
      url: this._getCrunkerInstance()._renderURL(mp3Blob),
      element: this._getCrunkerInstance()._renderAudioElement(mp3Blob),
    };
  },

  disconnectAudioNodesAndCloseContext: function (arrayOfAudioNodes, contextToClose) {
    try {
      if (arrayOfAudioNodes) {
        arrayOfAudioNodes.forEach(function (audioNodeToDisconnect) {
          if (audioNodeToDisconnect && audioNodeToDisconnect.port) {
            audioNodeToDisconnect.port.close();
          }
          if (audioNodeToDisconnect && audioNodeToDisconnect.disconnect) {
            audioNodeToDisconnect.disconnect();
          }
        });
      }

      if (contextToClose && contextToClose.close) {
        contextToClose.close();
      }
    } catch (error) {
      console.error("Failed to disconnect audio nodes and close context. Error" + error);
    }
  },

  applyFadeEndFilterToAudioBuffer: function (audioBuffer, fadeFilter) {
    if (audioBuffer && fadeFilter) {
      let fromValue = fadeFilter.getFadeFrom();
      let toValue = fadeFilter.getFadeTo();
      if (!fromValue) {
        fromValue = 0.01; //This is because the gain will throw an error if the value is 0
      }
      if (!toValue) {
        toValue = 0.01; //This is because the gain will throw an error if the value is 0
      }
      const fadeDurationInMilliseconds = fadeFilter.getFadeDuration();

      const offlineCtx = new OfflineAudioContext(
        audioBuffer.numberOfChannels,
        audioBuffer.sampleRate * this.getMaxAudioBufferDuration(audioBuffer),
        audioBuffer.sampleRate,
      );
      const source = offlineCtx.createBufferSource();
      source.buffer = audioBuffer;
      const gainNode = offlineCtx.createGain();
      let endTime = offlineCtx.currentTime + this.getMaxAudioBufferDuration(audioBuffer);
      let startTime = endTime - fadeDurationInMilliseconds / 1000;
      if (endTime < 0) {
        endTime = 0;
      }
      if (startTime < 0) {
        startTime = 0;
      }
      gainNode.gain.setValueAtTime(fromValue, startTime);
      gainNode.gain.exponentialRampToValueAtTime(toValue, endTime);
      source.connect(gainNode);
      gainNode.connect(offlineCtx.destination);
      source.start();
      return offlineCtx.startRendering().then(
        function (renderedAudioBuffer) {
          this.disconnectAudioNodesAndCloseContext([source, gainNode, offlineCtx.destination]);
          return renderedAudioBuffer;
        }.bind(this),
      );
    } else {
      return RSVP.Promise.resolve(audioBuffer);
    }
  },

  generateAudioBufferMatchingDuration: function (
    audioUniqueId,
    trimStart,
    trimEnd,
    durationInMilliseconds,
    loopToMatchWrapNodeDuration,
    audioType,
    sequencerSettings,
  ) {
    const audioUniqueIdGenerated = this._getUniqueIdForGeneratedAudioBuffer(
      audioUniqueId,
      trimStart,
      trimEnd,
      durationInMilliseconds,
      loopToMatchWrapNodeDuration,
      audioType,
    );
    const existingAudioBufferGenerated = this._audioUniqueIdGeneratedToAudioBuffer[audioUniqueIdGenerated];
    const useTrimmedAudio = !(trimEnd === undefined || trimEnd === null);
    if (existingAudioBufferGenerated) {
      return RSVP.Promise.resolve(existingAudioBufferGenerated);
    } else {
      let numberOfFullAudioBufferToGenerateBeforeConcateningWithSlicedAudioBuffer = 0;
      let fullAudioBuffer = null;
      let slicedAudioBuffer = null;
      const getBaseAudioBufferPromise = useTrimmedAudio
        ? this.getAudioBufferSlice(audioUniqueId, trimStart, trimEnd, audioType, sequencerSettings)
        : this.getAudioBufferFromUrl(audioUniqueId, null, audioType, sequencerSettings);
      return getBaseAudioBufferPromise
        .then(
          function (audioBuffer) {
            fullAudioBuffer = audioBuffer;
            const duration = this.getMaxAudioBufferDuration(audioBuffer);
            numberOfFullAudioBufferToGenerateBeforeConcateningWithSlicedAudioBuffer = Math.floor(
              durationInMilliseconds / (this.getMaxAudioBufferDuration(audioBuffer) * 1000),
            );
            const slideDurationInMilliseconds =
              durationInMilliseconds -
              numberOfFullAudioBufferToGenerateBeforeConcateningWithSlicedAudioBuffer *
                this.getMaxAudioBufferDuration(audioBuffer) *
                1000;
            const lastSliceStartTime = useTrimmedAudio ? trimStart : 0;
            const lastSliceEndTime = useTrimmedAudio
              ? trimStart + slideDurationInMilliseconds
              : slideDurationInMilliseconds;
            if (numberOfFullAudioBufferToGenerateBeforeConcateningWithSlicedAudioBuffer == 0) {
              return this.getAudioBufferSlice(
                audioUniqueId,
                lastSliceStartTime,
                lastSliceEndTime,
                audioType,
                sequencerSettings,
              );
            } else {
              if (loopToMatchWrapNodeDuration) {
                return slideDurationInMilliseconds > 0
                  ? this.getAudioBufferSlice(
                      audioUniqueId,
                      lastSliceStartTime,
                      lastSliceEndTime,
                      audioType,
                      sequencerSettings,
                    )
                  : null;
              } else {
                //return full audio and padd with silence to match the duration in seconds
                return audioBuffer;
              }
            }
          }.bind(this),
        )
        .then(
          function (slidedAudioBufferToConcatenate) {
            slicedAudioBuffer = slidedAudioBufferToConcatenate;
            if (numberOfFullAudioBufferToGenerateBeforeConcateningWithSlicedAudioBuffer > 0) {
              const audioBuffersToConcatenante = [];
              if (loopToMatchWrapNodeDuration) {
                for (let i = 0; i < numberOfFullAudioBufferToGenerateBeforeConcateningWithSlicedAudioBuffer; i++) {
                  audioBuffersToConcatenante.push(fullAudioBuffer);
                }
                if (slicedAudioBuffer) {
                  audioBuffersToConcatenante.push(slicedAudioBuffer);
                }
              } else {
                audioBuffersToConcatenante.push(slicedAudioBuffer);
                const silencerDurationInMillisecondsToConcatenateWith =
                  durationInMilliseconds - this.getMaxAudioBufferDuration(slicedAudioBuffer) * 1000;
                if (silencerDurationInMillisecondsToConcatenateWith > 0) {
                  const silenceAudioBuffer = this.generateAudioBufferSilence(
                    silencerDurationInMillisecondsToConcatenateWith,
                    slicedAudioBuffer.numberOfChannels,
                  );
                  audioBuffersToConcatenante.push(silenceAudioBuffer);
                }
              }
              return this._getCrunkerInstance().concatAudio(audioBuffersToConcatenante, true);
            } else {
              return slicedAudioBuffer;
            }
          }.bind(this),
        )
        .then(
          function (concatenatedGeneratedAudioBuffer) {
            if (
              this.isAudioTypeCachingAllowed(
                audioType,
                AUDIO_TYPES_GENERATED_TO_NOT_CACHE,
                audioUniqueIdGenerated,
                concatenatedGeneratedAudioBuffer,
              )
            ) {
              this._audioUniqueIdGeneratedToAudioBuffer[audioUniqueIdGenerated] = concatenatedGeneratedAudioBuffer;
            }
            return concatenatedGeneratedAudioBuffer;
          }.bind(this),
        );
    }
  },
});

const audioBufferFactoryInstance = new AudioBufferFactory();

export default {
  getInstance: function () {
    return audioBufferFactoryInstance;
  },
};
