import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import CapsuleCardController from "controllers/capsule/CapsuleCardController";
import CollectionRepeatController from "controllers/common/CollectionRepeatController";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import CapsuleListOptionView from "views/capsule/CapsuleListOptionView";
import Capsules from "collections/Capsules";
import AudioRecorderHelper from "models/helper/AudioRecorderHelper";
import ti18n from "i18n!nls/CapsuleListOptioni18n";
import RSVP from "rsvp";

const CapsuleListOptionControllerNew = Chaplin.Controller.extend({

    initialize : function(options) {
        this.model = options.model;
        this.songRefSummary = options.songRefSummary;
        this.capsulesCollection = null;
        this.context = options.context;
        const controllerParams = {};
        controllerParams.showActiveFlagInView = false;
        controllerParams.showCommentInLine = false;
        controllerParams.context = this.context;
        controllerParams.onActionExecutedOnUserHandler = this._onActionExecutedByUserOnDisplayedCapsule.bind(this);
        const collectionRepeatControllerParams = {};
        collectionRepeatControllerParams.showEmtptyInfoBox = true;
        collectionRepeatControllerParams.controllerOption = controllerParams;
        collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildInfoBoxParams();
        collectionRepeatControllerParams.itemControllerConstructor = CapsuleCardController;
        collectionRepeatControllerParams.resizePlaceHolderElem = true;
        collectionRepeatControllerParams.modelLoadHandler = options.loadHandler?options.loadHandler: this._getCapulesForSongLoadHandler.bind(this);
        if(options.context){
            collectionRepeatControllerParams.context = options.context;
        }
        collectionRepeatControllerParams.actionButtonHandlerFunction = this.createCapsule.bind(this);
        this.collectionRepeatController = new CollectionRepeatController(collectionRepeatControllerParams);
        _.extend(this, Backbone.Events);
    },

    _buildInfoBoxParams : function(){
        const params = {};
        params.iconClass = "ion-contrast";
        params.message = window.getI18n(ti18n,"NO_CAPSULE");
        params.helpMessage = "";//"Share information about a song or an artist by creating a capsule";
        params.actionButtonLabel = window.getI18n(ti18n,"CREATE_CAPSULE");
        return params;

    },

    showAndPlayWhatACapusleCapsule : function(){
        this.view.close().then((function(){
            PersistentModels.getInstance().getRouter().showAndPlayWhatACapusleCapsule();
        }).bind(this)).catch(function(error){
            $.mobile.loading("hide");
            window.alertErrorMessage(error)
        });

    },

    createCapsule :function(){
        $.mobile.loading("show");
        this.view.close().then((function(){
            $.mobile.loading("hide");
            if(window.device && window.device.platform =="browser"){
                AudioRecorderHelper.getInstance().initUserMedia().then((function(allowed){
                    if(allowed){
                        return PersistentModels.getInstance().getRouter().showAddAudioHightlightPageNew(this.model,null, this.context, this._onCapsuleSuccessfullyCreated.bind(this),true, true);
                    }else{
                        return PersistentModels.getInstance().getRouter().showAddAudioHightlightPageNew(this.model,null, this.context, this._onCapsuleSuccessfullyCreated.bind(this),false, true);
                    }
                }).bind(this)).catch((function(e){
                    window.navigator.trebbleNotification.alert(e, null, null, "warning");
                    return PersistentModels.getInstance().getRouter().showAddAudioHightlightPageNew(this.model,null, this.context, this._onCapsuleSuccessfullyCreated.bind(this), false, true); 
                }).bind(this));

            }else{
                return PersistentModels.getInstance().getRouter().showAddAudioHightlightPageNew(this.model,null, this.context, this._onCapsuleSuccessfullyCreated.bind(this),true, true);
            }
        }).bind(this)).catch(function(error){
$.mobile.loading("hide");
window.alertErrorMessage(error)
});

    },

    _onActionExecutedByUserOnDisplayedCapsule : function(){
        this.collectionRepeatController.reload();
    },

    _onCapsuleSuccessfullyCreated : function(){
        this.collectionRepeatController.reload();
    },

    _getCapulesForSongLoadHandler: function(){
        if(this.model){
            const songJsonForBackend = Utils.getInstance().getSongJsonForBackendFromSongModel(this.model);
            return TrebbleClientAPIHelper.getInstance().getCapsulesInfoForSong(songJsonForBackend, this.songRefSummary).then((function(capsulesArray){
                this.capsulesCollection = new Capsules(capsulesArray);
                return this.capsulesCollection.models;
            }).bind(this));
        }else{
            return RSVP.Promise.resolve();
        }
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    _onCapsuleLoaded : function(){
        if(this.collectionRepeatController.getNumberOfModels() > 0){
            this.view? this.view.showCreateCapsuleButton():null;
        }else{
            this.view? this.view.hideCreateCapsuleButton():null;
        }
    },

    buildModelAndRenderView : function() {
        $.mobile.loading("show");
        return new RSVP.Promise((function(resolve, reject) {
            try {

                this.collectionRepeatController.buildModelAndRenderView().then((function(){
                    const capsuleListOptionViewParams = {};
                    capsuleListOptionViewParams.capsuleListView = this.collectionRepeatController.getView();
                    const capsuleListOptionView = new CapsuleListOptionView(capsuleListOptionViewParams);
                    capsuleListOptionView.render();
                    $.mobile.loading("hide");
                    //this.capsuleListController.getView().load();
                    this.view = capsuleListOptionView;
                    this.listenTo(this.view,"createCapsule", this.createCapsule.bind(this))
                    this._onCapsuleLoaded();
                    resolve();
                }).bind(this)).catch((function(error){
                    $.mobile.loading("hide");
                    reject(error);
                }).bind(this));
                this.listenTo(this.collectionRepeatController,"finishLoading", this._onCapsuleLoaded.bind(this));
            } catch (error) {
                $.mobile.loading("hide");
                reject(error);
            }

        }).bind(this));

    }

});

export default CapsuleListOptionControllerNew;