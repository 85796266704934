import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import AudioFilter from "models/audioEditor/filters/AudioFilter";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import RSVP from "rsvp";

const PRESETS ={};

const PRESET_IDS = {
    "DEFAULT_NOISE_GATE":"defaultNoiseGate",
    "DEFAULT_NOISE_GATE_V2":"defaultNoiseGateV2"
};

const PARAMS ={};
PARAMS.ATTACK = "/GATE/0x00/Attack";
PARAMS.RELEASE = "/GATE/0x00/Release";
PARAMS.HOLD = "/GATE/0x00/Hold";
PARAMS.THRESHOLD = "/GATE/0x00/Threshold";



const createNoiseGateConfigPreset = function(threshold, attack, release, hold){
    const presetParams = {};
    presetParams[PARAMS.ATTACK] = attack;
    presetParams[PARAMS.RELEASE] = release;
    presetParams[PARAMS.HOLD] = hold;
    presetParams[PARAMS.THRESHOLD] = threshold;

    return presetParams;
};
const Rollout = RolloutHelper.getInstance();
PRESETS[PRESET_IDS.DEFAULT_NOISE_GATE] = createNoiseGateConfigPreset(-30, 10, 100, 40);
const CACHING_AUDIO_NODE_ON_CHROME = Rollout.getFeatureVariable(Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR, Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR.variables.apply_chrome_workaround_by_resuing_audio_worklet_processor, true);
const USE_SCRIPT_PROCESSOR_IN_SAFARI = Rollout.getFeatureVariable(Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR, Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR.variables.apply_safari_workaround_for_audioworklet_incomplete_impl, true);

const CLASSNAME = "NoiseGateFilter";
const NoiseGateFilter =  AudioFilter.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        AudioFilter.apply(this, [attributes, options]);
        this.setPresetIdToConfigMap(PRESETS);
        this.set("type", NoiseGateFilter.CLASSNAME);
        
    },

    
    setAttack : function(attack){
        this.set(PARAMS.ATTACK,attack);
    },


    getAttack : function(){
        return this.get(PARAMS.ATTACK);
    },

    setThreshold : function(threshold){
        this.set(PARAMS.THRESHOLD,threshold);
    },

    getThreshold : function(){
        return this.get(PARAMS.THRESHOLD);
    },

    setRelease : function(release){
        this.set(PARAMS.RELEASE,release);
    },

    getRelease : function(){
        return this.get(PARAMS.RELEASE);
    },

    setHold : function(hold){
        this.set(PARAMS.HOLD,hold);
    },

    getHold : function(){
        return this.get(PARAMS.HOLD);
    },

    isEqual : function(filter){
        if(filter instanceof NoiseGateFilter){
            return (this.isDisabled() == filter.isDisabled() && this.getAttack() === filter.getAttack() && this.getThreshold() === filter.getThreshold() && this.getRelease() === filter.getRelease() && this.getHold() === filter.getHold()) 
        }
        return false;
    },

    clone : function(){
        const cloneFilter = new NoiseGateFilter();
        cloneFilter.setDisabled(this.isDisabled());
        cloneFilter.setAttack(this.getAttack());
        cloneFilter.setThreshold(this.getThreshold());
        cloneFilter.setHold(this.getHold());
        return cloneFilter;
    },

    needToPrepareAudioContext: function(){
        return true;
    },

    prepareAudioContext: function(audioContext){
        return RSVP.Promise.resolve(audioContext);
    },

    applyFilterToNode : function(audioNode, audioContext, arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime, sequencerRendererTracksInfo, createdFilterNode ){
        if(!this.isDisabled()){
            const attack = this.getAttack();
            const threshold = this.getThreshold();
            const release = this.getRelease();
            const hold = this.getHold();

            

            // Create a compressor node
            const noiseGateNode = createdFilterNode;
            this._applyParameterOnNode(noiseGateNode, PARAMS.ATTACK);
            this._applyParameterOnNode(noiseGateNode, PARAMS.RELEASE);
            this._applyParameterOnNode(noiseGateNode, PARAMS.THRESHOLD);
            this._applyParameterOnNode(noiseGateNode, PARAMS.HOLD);
            

            audioNode.connect(noiseGateNode);
            
            if(arrayOfAudioNodesUsed){
                arrayOfAudioNodesUsed.push(noiseGateNode);
            }
            return noiseGateNode;

        }else{
            return audioNode;
        }
    },

    _applyParameterOnNode:function(noiseGateNode,paramName){
        noiseGateNode.setParamValue(paramName,this.get(paramName));
    },

    _createNoiseGateNode : function(audioContext){
        return new RSVP.Promise(function(resolve, reject){
            require(["libs/noiseGate/NoiseGate", "libs/noiseGate/NoiseGateScriptProcessor"],function({NoiseGate}, {NoiseGateScriptProcessor}){
                if((window.isSafariBrowser || window.isIOSMobileDevice) && USE_SCRIPT_PROCESSOR_IN_SAFARI){
                    return NoiseGateScriptProcessor.createDSP(audioContext, 1024, "js/libs/noiseGate").then(resolve).catch(reject);
                }else{
                    const noiseGateNodeFactory = new NoiseGate(audioContext, "js/libs/noiseGate");
                    if(window.chrome && CACHING_AUDIO_NODE_ON_CHROME && audioContext._lastNoiseGateNodeCreated && !audioContext._lastNoiseGateNodeCreated.isConnected){
                        return resolve(audioContext._lastNoiseGateNodeCreated);
                    }
                    return noiseGateNodeFactory.load().then(resolve).catch(reject);	
                }
            })

        })
    },

    applyFilter : function(previousOutputNode, audioContext, arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime, sequencerRendererTracksInfo, audioBuffer){
        if(!this.isDisabled()){
            if(!previousOutputNode){
                previousOutputNode = audioContext.createBufferSource();
                previousOutputNode.buffer = audioBuffer;
                if(arrayOfAudioNodesUsed){
                    arrayOfAudioNodesUsed.push(previousOutputNode);
                }
            }
            return this._createNoiseGateNode(audioContext).then((function(noiseGateNode){
                if(window.chrome && CACHING_AUDIO_NODE_ON_CHROME && noiseGateNode){
                    audioContext._lastNoiseGateNodeCreated = noiseGateNode;
                    noiseGateNode.isConnected = true;
                }
                return this.applyFilterToNode(previousOutputNode, audioContext,arrayOfAudioNodesUsed, audioBufferStartTime,audioBufferEndTime, sequencerRendererTracksInfo, noiseGateNode);
            }).bind(this));

            
        }else{
            return RSVP.Promise.resolve(previousOutputNode);
        }
    },


});

NoiseGateFilter.PRESET_IDS = PRESET_IDS;
NoiseGateFilter.PRESETS = PRESETS;
NoiseGateFilter.CLASSNAME =  CLASSNAME;

export default NoiseGateFilter; 