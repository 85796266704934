import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import OptionPopupView from "views/common/OptionPopupView";
import ti18n from "i18n!nls/ConfigureBackgroundMusici18n";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import CustomSearchFieldView from "views/common/CustomSearchFieldView";
import Utils from "models/helper/Utils";

const Rollout = RolloutHelper.getInstance();

const ChooseMusicSoundsAndUploadsPopupView = OptionPopupView.extend({

    "pageName": "Choose Music Sounds And Uploads",

    initialize: function(options) {
        const viewOptions = {...options};

        const contentView = {};

        contentView.$el = options.musicSoundAndUploadsWidgetView.$el;
        viewOptions.contentView = contentView;
        viewOptions.contentPosition = "top";
        viewOptions.addContentAfterEnhancement = true;

        viewOptions.id = "ChooseMusicSoundsAndUploadsPopup";
        OptionPopupView.prototype.initialize.call(this, viewOptions);
    },



    afterRendering: function() {
        this.$el.find(".music_widget_wrapper .choose_background_music_popup_content_wrapper .fixed_section").enhanceWithin();
        this.$el.find("#background_music_category_fld-button").append('<i class="ion-chevron-down" ></i>');
    },

    onPopupClosed: function() {

    },

    onAfterPageShow: function() {

    },



});

export default ChooseMusicSoundsAndUploadsPopupView;
