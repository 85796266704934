import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import OptionPopupView from "views/common/OptionPopupView";
import ti18n from "i18n!nls/ConfigureBackgroundMusici18n";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import CustomSearchFieldView from "views/common/CustomSearchFieldView";
import Utils from "models/helper/Utils";
import ChooseSoundPopupContentTemplate from 'text!../../../templates/chooseSound/ChooseSoundPopupContentTemplate.html';

const Rollout = RolloutHelper.getInstance();

const ChooseSoundPopupView = OptionPopupView.extend({

    "pageName": "Choose Background Music",

    initialize: function(options) {
        const viewOptions = {};

        const contentView = {};
        const contentViewTemplate = _.template(ChooseSoundPopupContentTemplate);
        let templateParams = options;
        if(!templateParams){
            templateParams= {};
        }
        templateParams.ti18n =  ti18n;
        templateParams.Rollout = Rollout;
        this.trebbleAudioListView = options.trebbleAudioListView;
        contentView.$el = $(contentViewTemplate(templateParams));
        viewOptions.contentView = contentView;
        viewOptions.contentPosition = "center";

        this._delayInMilliSecondOnSearchQueryExecution = 500;
        viewOptions.id = "ChooseBackgroundMusicPopup";


        OptionPopupView.prototype.initialize.call(this, viewOptions);
    },



    _buildSearchHeaderButtonAndToPopup: function(){
        const customSearchFieldViewParams = {};
        customSearchFieldViewParams.searchFieldPlaceholderSelector = ".search_field_wrapper";
        customSearchFieldViewParams.searchFieldPlaceholderSelectorRootNodeEl  = this.el;
        this.customSearchFieldView = new CustomSearchFieldView(customSearchFieldViewParams);
        this.customSearchFieldView.render();
        this.customSearchFieldView.addKeyUpHandlerFunction(this._onKeyUpInSearchField.bind(this));
        this.customSearchFieldView.addOnSearchFieldClearedFunction(this._onSearchFieldCleared.bind(this));
        this.customSearchFieldView.addOnScreenResizeHandlerFunction(this._resizeSearchField.bind(this));
        //this.customSearchFieldView.focusSearchField();
        //this.customSearchFieldView.onSearchButtonTapped();
        this.$el.find(".search_field_wrapper").prepend(this.customSearchFieldView.el);
    },

    _onKeyUpInSearchField : function(searchFieldValue){

        if(this._pendingSearchQueryId)
        {
            clearTimeout(this._pendingSearchQueryId);
        }
        //wait a certain amount of time before sending search event
        this._pendingSearchQueryId = setTimeout((function(){
            this.trigger("keyUpOnSearchField",searchFieldValue);
        }).bind(this), this._delayInMilliSecondOnSearchQueryExecution);
    },

    _onSearchFieldCleared : function(){
        this.trigger("searchFieldCleared");
    },

    _resizeSearchField : function(searchField){
  
    },

    isLoading:function(isLoading){
        this.$el.find(".choose_background_music_popup_content_wrapper").attr("isLoading",isLoading)
    },

    afterRendering: function() {
        this.backgroundMusicList$el = this.$el.find(".background_music_list");
        this.backgroundMusicCategoryList$el = this.$el.find(".tag_button_list_wrapper");
        this.backgroundMusicList$el.append(this.trebbleAudioListView.$el);
        this._buildSearchHeaderButtonAndToPopup();
    },

    onPopupClosed: function() {

    },

    onAfterPageShow: function() {

    },



});

export default ChooseSoundPopupView;
