	import $ from 'jquery';
    import _ from 'underscore';
    import Backbone from "backbone";
    import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
    import TrebbleAudio from "models/TrebbleAudio";
    import RolloutHelper from "models/helper/FeatureRolloutHelper";
    import Utils from "models/helper/Utils";
    import RSVP from "rsvp";

    const Rollout = RolloutHelper.getInstance();
    const EPIDEMIC_SOUND_ENDPOINT  = Rollout.getFeatureVariable(Rollout.FEATURES.RETRIEVE_MUSIC_FROM_EPIDEMIC_SOUND, Rollout.FEATURES.RETRIEVE_MUSIC_FROM_EPIDEMIC_SOUND.variables.epidemic_sound_endpoint, "https://partner-content-api.epidemicsound.com/");

    const SHOW_MUSIC_BY_GENRE =  Rollout.getFeatureVariable(Rollout.FEATURES.RETRIEVE_MUSIC_FROM_EPIDEMIC_SOUND, Rollout.FEATURES.RETRIEVE_MUSIC_FROM_EPIDEMIC_SOUND.variables.show_music_by_genre, false);
    const TOKEN_EXPIRATION_TIME_IN_SECONDS  = 59 *  60; //using 59 minutes instead of an hour to make sure that we don't try to use a token which has expired
    const EpidemicSoundHelper = Backbone.Model.extend({


        initialize : function() {
            this._categoryIdToCategoryLabel = null;
        },

        getUserAccessToken : function(refreshToken){
            if(this._userAccessToken && (!this._userAccessTokenExpirationDate || (new Date().getTime())  <= this._userAccessTokenExpirationDate.getTime())  && !refreshToken){
                return RSVP.Promise.resolve(this._userAccessToken);
            }else{
                return TrebbleClientAPIHelper.getInstance().getEpidemicSoundToken().then((function(token){
                    this._userAccessToken = token;
                    this._userAccessTokenExpirationDate = new Date((new Date().getTime() + TOKEN_EXPIRATION_TIME_IN_SECONDS* 1000));
                    return this._userAccessToken;
                }).bind(this))
            }
        },

        getTrebbleAudioArrayForCategoryId: function(categoryId){
            if(SHOW_MUSIC_BY_GENRE){
                return this.getTrebbleAudioArrayForCategoryIdByGenre(categoryId);
            }else{
                return this.getTrebbleAudioArrayForCategoryIdByTheme(categoryId);
            }

        },

        createTrebbleAudioFromSerializedData : function(serializedData){
            if(serializedData.audioType === Utils.getInstance().getTrebbleAudioType().EPIDEMIC_SOUND){
                const trackId =  Utils.getInstance().getEpidemicTrackIdFromTrebbleSongUri(serializedData.audioId);
                const audioUrlRetrievalFunction= (function(){
                                return this._makeGetRequestToEpidemicSoundApi("/v0/tracks/"+trackId+"/download",{"format":"mp3"}).then(function(result){
                                    return result && result.url;
                                })
                            }).bind(this);
                serializedData.audioUrlRetrievalFunction  = audioUrlRetrievalFunction;

                return new TrebbleAudio(serializedData);
            }

        },

        getTrebbleAudioArrayForCategoryIdByGenre : function(categoryId){

            return this._makeGetRequestToEpidemicSoundApi("/v0/tracks", {"genre": categoryId,"limit": 100 }).then((function(result){
                const trebbleAudioArray = [];
                if(result && result.tracks){
                    result.tracks.forEach((function(track, i){
                        if(track){
                            const audioId = track.id;
                            const title = track.title;
                            const artist = track.mainArtists;
                            const creationDate = null;
                            const serializedData = {
                                "audioId": Utils.getInstance().getTrebbleSongUriFromEpidemicTrackId(audioId),
                                "audioType":Utils.getInstance().getTrebbleAudioType().EPIDEMIC_SOUND,
                                "title": title,
                                "artist": artist,
                                "creationDate": creationDate
                            };

                            trebbleAudioArray.push(this.getTrebbleAudioFromSerializedData(serializedData));
                        }
                    }).bind(this))
                }
                return trebbleAudioArray;
            }).bind(this));
            
        },

        getDownloadUrlFromTrackId : function(trackId){
            return this._makeGetRequestToEpidemicSoundApi("/v0/tracks/"+trackId+"/download",{"format":"mp3"}).then((function(result){
                return result && result.url;
            }).bind(this));
        },	

        getTrebbleAudioArrayForCategoryIdByTheme : function(categoryId){

            return this._makeGetRequestToEpidemicSoundApi("/v0/collections/"+categoryId).then((function(result){
                const trebbleAudioArray = [];
                if(result && result.tracks){
                    result.tracks.forEach((function(track, i){
                        if(track){
                            const audioId = track.id;
                            const title = track.title;
                            const artist = track.mainArtists;
                            const creationDate = null;
                            const audioUrlRetrievalFunction = (function(){
                                return this._makeGetRequestToEpidemicSoundApi("/v0/tracks/"+track.id+"/download").then(function(result){
                                    return result && result.url;
                                })
                            }).bind(this)

                            trebbleAudioArray.push(new  TrebbleAudio({
                                "audioId": Utils.getInstance().getTrebbleSongUriFromEpidemicTrackId(audioId),
                                "audioType":Utils.getInstance().getTrebbleAudioType().EPIDEMIC_SOUND,
                                "title": title,
                                "artist": artist,
                                "creationDate": creationDate,
                                "audioUrlRetrievalFunction": audioUrlRetrievalFunction
                            }))
                        }
                    }).bind(this))
                }
                return trebbleAudioArray;
            }).bind(this));
            
        },

        getCategoryIdToCategoryLabel : function(){
            if(SHOW_MUSIC_BY_GENRE){
                return this.getCategoryIdToCategoryLabelByGenre();
            }else{
                return this.getCategoryIdToCategoryLabelByTheme();
            }
        },


        getCategoryIdToCategoryLabelByTheme : function(){
            if(this._categoryIdToCategoryLabel){
                return RSVP.Promise.resolve(this._categoryIdToCategoryLabel);
            }else{
                return this._makeGetRequestToEpidemicSoundApi("/v0/collections").then((function(result){
                    this._categoryIdToCategoryLabel = {};
                    if(result && result.collections){
                        result.collections.forEach((function(collection, i){
                            if(collection){
                                this._categoryIdToCategoryLabel[collection.id] = collection.name;
                            }
                        }).bind(this))
                    }
                    return this._categoryIdToCategoryLabel;
                }).bind(this));
            }
        },


        getCategoryIdToCategoryLabelByGenre : function(){
            if(this._categoryIdToCategoryLabel){
                return RSVP.Promise.resolve(this._categoryIdToCategoryLabel);
            }else{
                return this._makeGetRequestToEpidemicSoundApi("/v0/tracks/parameters").then((function(result){
                    this._categoryIdToCategoryLabel = {};
                    if(result && result.genres){
                        result.genres.forEach((function(genre, i){
                            if(genre){
                                this._categoryIdToCategoryLabel[genre.id] = genre.name;
                            }
                        }).bind(this))
                    }
                    return this._categoryIdToCategoryLabel;
                }).bind(this));
            }
        },

        _makeGetRequestToEpidemicSoundApi : function(apiEndpoint,params ){
            return this.getUserAccessToken().then((function(userToken){
                return new Promise(function(resolve, reject){
                    try{
                        const url =  EPIDEMIC_SOUND_ENDPOINT+apiEndpoint;
                        if(!params){
                            params = {};
                        }

                        $.ajax({
                            url: url,
                            type: 'GET',
                            data: params,
                            headers: {"Authorization": "Bearer "+userToken}
                        }).done(resolve).fail(reject);
                    }catch(error){
                        reject(error);
                    }

                });
            }).bind(this))
        },

    });

    const EpidemicSoundHelperInstance = new EpidemicSoundHelper();

    export default {
		getInstance : function() {
			return EpidemicSoundHelperInstance;
		}
	};