import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Playlist from "collections/Playlist";
import TrebbleListView from "views/common/TrebbleListView";
import PlaylistListItemTemplate from 'text!../../../templates/common/PlaylistListItemTemplate.html';
import PlaylistCollection from "collections/PlaylistCollection";
import ti18n from "i18n!nls/Commoni18n";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";

const PlaylistListView = Backbone.View.extend({





  initialize: function(options) {
    this.displayedCollection = this.collection;
    this.collection.bind("add", this._onPlaylistAddedOrRemoved, this);
    this.collection.bind("change", this._onPlaylistUpdated, this);
    this.collection.bind("remove", this._onPlaylistAddedOrRemoved, this);
    this.collection.bind("reset", this._onPlaylistAddedOrRemoved, this);
    this._delayOnCollectionUpdate = (options && options.delayOnCollectionUpdate) ? options.delayOnCollectionUpdate :  1000;
    this._resizeToAvailableScreenSizeOnOrientationChange = options  ? options.resizeToAvailableScreenSizeOnOrientationChange :  true;
    this._showHeader = options  ? options.showHeader :  false;
    this._headerText= options  ? options.headerText :  "";
    this._showFooter= options  ? options.showFooter :  false;
    this._footerText= options  ? options.footerText :  "";
    this._customMapFunctionToConvertJsonObjToTemplateData = options ? options.customMapFunctionToConvertJsonObjToTemplateData : null;
    this._customItemViewUpdateDuringScrollingHandler = options ? options.customItemViewUpdateDuringScrollingHandler : null;
    this._doNotShowCoverImage = options ? options.doNotShowCoverImage : false;
    this._container$elForImageLazyLoad = options? options.container$elForImageLazyLoad: null;
    this._loadHandler= (options && options.loadHandler)  ? options.loadHandler :  null;
    this._heightMargin= (options && options.heightMargin)  ? options.heightMargin :  null;
    this._resizePlaceHolderElem =  options  ? options.resizePlaceHolderElem :  false;
    this._customInfoBoxParams =(options && options.customInfoBoxParams)  ? options.customInfoBoxParams :  null;
    this._customEmptyInfoBoxParams =(options && options.customEmptyInfoBoxParams)  ? options.customEmptyInfoBoxParams :  null;
    this._doNotSortDisplayedCollection =  options  ? options.doNotSortDisplayedCollection :  true;
    this._listenerViewportKey = "playlistListView" + (new Date()).getTime();
    this._doNotInitializeLazyLoadingAtRender = options? options.doNotInitializeLazyLoadingAtRender: false;
    this._customCollectionConstructor = options ? options.customCollectionConstructor: null;
    PersistentModels.getInstance().getRouter().registerForViewportHeightChange(this._listenerViewportKey,this._onOrientationChangeFunction.bind(this));

  },


  _onOrientationChangeFunction : function(newViewPortHeigh, forceResizing) {
    if(this.$el.is(":visible") || forceResizing){
     if (!this._resizePlaceHolderElem && this.$el) {
      if(this._heightMargin){
       newViewPortHeigh = newViewPortHeigh - this._heightMargin;
     }
     this.$el.css("height", (newViewPortHeigh ) + "px"); 
   }
   this.infiniteListView._onRootElementResize();
 }
},


filterList: function(searchCharaterers) {
 if(!this._removeFromDom){
  this.appliedSearchCharacters = searchCharaterers;
  const searchFunction = (function() {
   $.mobile.loading("show");
   this.infiniteListView.setUpdating(true);
   const r = this.collection.searchOnPlaylistName(this.appliedSearchCharacters);
   if(r instanceof RSVP.Promise){
    r.then((function(arrayOfResults){
     const newPlaylistCollection = this._getCollection(arrayOfResults);
     this._changePlaylistList(newPlaylistCollection);
     this.pendingSearchFunctionTimerId = null;
     $.mobile.loading("hide");
   }).bind(this)).catch((function(error){
     $.mobile.loading("hide");
     window.alertErrorMessage(error);
   }).bind(this))
 }else{
  const newPlaylistCollection = this._getCollection(r);
  this._changePlaylistList(newPlaylistCollection);
  this.pendingSearchFunctionTimerId = null;
  $.mobile.loading("hide");
}

}).bind(this);
  if (this.pendingSearchFunctionTimerId) 
  {
    window.clearTimeout(this.pendingSearchFunctionTimerId);
  }
  this.pendingSearchFunctionTimerId = window.setTimeout(searchFunction,  this._delayOnCollectionUpdate);
}
},

clearSearchfilter: function() {
if(!this._removeFromDom){
  this.appliedSearchCharacters = null;
  const searchFunction = (function() {
   this.infiniteListView.setUpdating(true);
   const newPlaylistCollection = this._getCollection(this.collection.searchOnPlaylistName(""));
   this._changePlaylistList(newPlaylistCollection);
   this.pendingSearchFunctionTimerId = null;
 }).bind(this);
  if (this.pendingSearchFunctionTimerId) 
  {
    window.clearTimeout(this.pendingSearchFunctionTimerId);
  }
  this.pendingSearchFunctionTimerId = window.setTimeout(searchFunction, 0);
}
},

_getCollection : function(arrayOfObjects){
if(this._customCollectionConstructor){
  return this._customCollectionConstructor(arrayOfObjects)
}else{
  return new PlaylistCollection(arrayOfObjects);
}
},


_changePlaylistList: function(newPlaylistModels) {
if(!this._removeFromDom){
  this.displayedCollection = newPlaylistModels;
  if(!this._doNotSortDisplayedCollection)
  {
   this.displayedCollection.sort();
 }
 this.infiniteListView.updateModels(this.displayedCollection.toJSON());
 this.infiniteListView.setUpdating(false);
}
},

_updatePlaylistInPlaylistList: function(updatedPlaylistModel) {
if(!this._removeFromDom){
if(!this._doNotSortDisplayedCollection)
{
 this.displayedCollection.sort();
}
this.infiniteListView.updateModel(updatedPlaylistModel.toJSON());
this.infiniteListView.setUpdating(false);
}
},

_getOnListItemTapHandler: function() {
return (function(playlistJson, evnt, item) {
this.trigger("itemTaped", this._getPlaylistModelFromPlaylistJson(playlistJson) );
}).bind(this);
},

_getOnListItemPressHandler : function(){
return  (function(albumJson,evnt, item) {
this.trigger("itemPressed",this._getPlaylistModelFromPlaylistJson(albumJson));		
}).bind(this);
},

_getOnListItemPressUpHandler : function(){
return  (function(albumJson,evnt, item) {
this.trigger("itemPressedUp",this._getPlaylistModelFromPlaylistJson(albumJson));		
}).bind(this);
},

_getOnListButtonTappedHandler :function(){
return  (function(playlistJson,evnt, item) {
this.trigger("itemOptionBtnTapped",this._getPlaylistModelFromPlaylistJson(playlistJson));		
}).bind(this);
},

/*_onItemSwipeRight: function() {
return (function(playlistJson, evnt, item) {
this.trigger("itemSwipedRight", this._getPlaylistModelFromPlaylistJson(playlistJson));

}).bind(this);
},*/

_onItemSwipeRight: function() {
return (function(playlistJson, evnt, item) {
//this.trigger("itemSwipedRight", this._getSongModelFromSongJson(songJson));

}).bind(this);
},

_getPlaylistModelFromPlaylistJson : function(playlistJson){
return this.collection.get(playlistJson.id );
},

_getDefaultItemEventHandler : function(){
let defaultItemEventHandler =  TrebbleListView.prototype._getDefaultItemEventHandler.call(this);
defaultItemEventHandler = defaultItemEventHandler? defaultItemEventHandler : [];
delete defaultItemEventHandler["click div.songOptionBtnWrapper > #songLibraryOptionButtuon"];
return defaultItemEventHandler;
},

_modelTransformHandlerBeforeTemplatePiping: function(playlistJson) {
const playlistName = playlistJson.name;
const playlistDescription = playlistJson.description;
const coverArtUrl = (playlistJson.coverArtUrl && !this._doNotShowCoverImage)?window.trebble.globalHelpers.getResizeImageUrl(playlistJson.coverArtUrl, 100, 100): "";
let templateData = {playlistCoverArtUrl: coverArtUrl,firstLineText: playlistName,secondLineText: playlistDescription ,model : playlistJson, doNotShowSubscribeButton: true,doNotShowGradeButton: true,doNotShowOptionButton: true, doNotShowCoverImage: this._doNotShowCoverImage };
return templateData;
},

_getItemViewUpdateDuringScrollingHandler : function(elem,templateData){
const imageEl = elem.querySelector(".songCoverArt");
imageEl.src = window.getResourceURLAccordingToLocationProtocol("/img/videoLoading.png");
imageEl.setAttribute("data-original", templateData.playlistCoverArtUrl);
if(!imageEl.className ||  imageEl.className.indexOf("lazy") == -1){
imageEl.className = (!imageEl.className)? "lazy" :  imageEl.className +" lazy";
}
const firstItemElem = elem.querySelector(".songTitleLibrary");
firstItemElem.textContent = templateData.firstLineText;
const secondItemElem = elem.querySelector(".songArtistLibrary");
secondItemElem.textContent = templateData.secondLineText;
},




isSearchApplied : function(){
return this.appliedSearchCharacters != null;
},

getAppliedSearchCharacters : function(){
return this.appliedSearchCharacters;
}, 

_onPlaylistAddedOrRemoved : function(){
if(!this._removeFromDom){
if(this.isSearchApplied())
{
this.filterList(this.getAppliedSearchCharacters());
}else{
this.infiniteListView.setUpdating(true);
this._changePlaylistList(this.collection);
}
}
this.trigger("modelChange", this.collection);

},

_onPlaylistUpdated : function(playlistUpdatedModel){
if(!this._removeFromDom){
if(this.isSearchApplied())
{
this.filterList(this.getAppliedSearchCharacters());
}else{
//this.infiniteListView.setUpdating(true);
this._updatePlaylistInPlaylistList(playlistUpdatedModel);
}
}
this.trigger("modelChange", this.collection);

},

isCollectionEmpty : function(){
if(this.collection)
{
return this.collection.length == 0;
}else{
return true;
}
},



load : function(params){
if(!this._removeFromDom){
if(this._loadHandler){
this.infiniteListView.setUpdating(true);
return this._loadHandler(params).then((function(arrayOfPlaylists){
  if(!this._removeFromDom){
   this.collection.reset(arrayOfPlaylists);
   this.infiniteListView.setUpdating(false);
 }
}).bind(this)).catch((function(error){
 this.infiniteListView.setErrorMessage(error);
}).bind(this));
}else{
return RSVP.Promise.resolve();
}
}
},

show : function(){
this.$el.css("display", "");
},

hide : function(){
this.$el.css("display", "none");
},


_buildInfoBoxViewParams : function(){
if(!this._customInfoBoxParams)
{
const params = {};
params.iconClass = "pe-7s-radio";
params.message = window.getI18n(ti18n, "NO_TREBBLE_WAS_FOUND");
params.helpMessage = "";
return params;
}else{
return this._customInfoBoxParams;
}
},

_buildDefaultCustomEmptyBoxParams : function(){
if(!this._customEmptyInfoBoxParams)
{
const params = {};
params.iconClass = "pe-7s-radio";
params.message = window.getI18n(ti18n, "NO_TREBBLE_WAS_FOUND");
params.helpMessage = window.getI18n(ti18n, "PLEASE_TRY_DIFFERENT_KEYWORDS");
return params;
}else{
return this._customEmptyInfoBoxParams;
}

},

_onDefaultInfoboxButtonCalled : function(){
this.trigger("infoBoxButtonPressed");
},


_onNodeRemovedFromDom : function(){
this._removeFromDom  = true;
PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._listenerViewportKey);
},

initializeImageLazyLoadPlugin : function(container$elForImageLazyLoad){
this.infiniteListView.initializeImageLazyLoadPlugin(container$elForImageLazyLoad);
},

cancelPendingImageLoading : function(){
this.infiniteListView.cancelPendingImageLoading();
},

render: function() {
  let newViewPortHeigh = PersistentModels.getInstance().getRouter().getViewportHeight();
if(this._heightMargin){
newViewPortHeigh = newViewPortHeigh - this._heightMargin;
}
this.$el.html(' <div id="playlistWrapper"  style="height:' + (newViewPortHeigh + "px") + ';overflow:hidden;width:100%"></div>');
this.setElement(this.$el.find("div").first());
const listParams = {};
if(!this._doNotSortDisplayedCollection)
{
this.displayedCollection.sort();
}
listParams.model = this.displayedCollection.toJSON();
listParams.listRootElement = this.el;
listParams.defaultNumberOfElements = 80;
listParams.defaultHeightElement = 60;
listParams.placeHolderHeight =  PersistentModels.getInstance().getRouter().getViewportHeight();
listParams.resizePlaceHolderElem =  this._resizePlaceHolderElem;
listParams.modelIdAttribute  = "id";
listParams.customInfoBoxParams = this._buildInfoBoxViewParams();
listParams.customEmptyInfoBoxParams = this._buildDefaultCustomEmptyBoxParams();
listParams.container$elForImageLazyLoad = this._container$elForImageLazyLoad;
listParams.doNotInitializeLazyLoadingAtRender = this._doNotInitializeLazyLoadingAtRender;
listParams.modelIdAttribute = "id";
listParams.doNotShowAddToRadioButton = true;
listParams.doNotShowGradeSongButton = true;
listParams.listItemTemplate = PlaylistListItemTemplate;
const infiniteListView = new TrebbleListView(listParams);
this.listenTo(infiniteListView, "infoBoxButtonPressed",this._onDefaultInfoboxButtonCalled.bind(this));
this.infiniteListView = infiniteListView;
infiniteListView._getDefaultItemEventHandler =  this._getDefaultItemEventHandler;
infiniteListView.setModelTransformHandlerBeforeTemplatePiping(this._customMapFunctionToConvertJsonObjToTemplateData? this._customMapFunctionToConvertJsonObjToTemplateData: this._modelTransformHandlerBeforeTemplatePiping);
infiniteListView.setItemViewUpdateDuringScrollingHandler(this._customItemViewUpdateDuringScrollingHandler? this._customItemViewUpdateDuringScrollingHandler : this._getItemViewUpdateDuringScrollingHandler);
infiniteListView.addOnOptionButtonTappedHandlers(this._getOnListButtonTappedHandler());
infiniteListView.addOnSwipeRightRevealHandler(this._onItemSwipeRight());
infiniteListView.addOnItemTapHandler(this._getOnListItemTapHandler());
infiniteListView.addOnItemPressHandler(this._getOnListItemPressHandler());
infiniteListView.addOnItemPressUpHandler(this._getOnListItemPressUpHandler());
infiniteListView.render();
this._onOrientationChangeFunction(PersistentModels.getInstance().getRouter().getViewportHeight(), true);
this.$el.on("remove",this._onNodeRemovedFromDom.bind(this));

return this;
}

});

export default PlaylistListView;
