import _ from 'underscore';
import ParagraphContainerView from "views/audioEditor/sequencerNodeView/ParagraphContainerView";
import SpeakerParagraphContainerTemplate from 'text!../../../../templates/audioEditor/sequencerNodeView/SpeakerParagraphContainerTemplate.html';
import ti18n from 'i18n!nls/Sequenceri18n';
import Utils from 'models/helper/Utils';
import UnknownSpeakerInfo from "models/audioEditor/UnknownSpeakerInfo";


const SpeakerParagraphContainerView = ParagraphContainerView.extend({



    constructor : function(attributes, options) {
        ParagraphContainerView.apply(this, [attributes, options]);
        this.compiledTemlate =  _.template(SpeakerParagraphContainerTemplate);
        this._listenToModelEvents();
    },
    
    events : _.extend({
        "click .speaker_label": "onSpeakerLabelClick",
    }, ParagraphContainerView.prototype.events),
    
    onSpeakerLabelClick : function(){
        this.trigger("onSpeakerLabelClick", this);
    },

    createNewEmptyParagraphContainerView : function(){
        return new SpeakerParagraphContainerView({model: this.model});
    },

    _listenToModelEvents : function(){
        if(this.model){
            this.listenTo(this.model,"change", this.onSpeakerInfoChange.bind(this))
        }
    },

    _stopListeningToModelEvents : function(){
        if(this.model){
            this.stopListening(this.model);
        }
    },

    replaceSpeakerInfo: function(newSpeakerInfo){
        this._stopListeningToModelEvents()
        this.model = newSpeakerInfo;
        this._listenToModelEvents();
        this.onSpeakerInfoChange();
    },

    canBeAddedBefore : function(nodeViewToAdd, nodeViewAlreadyInserted){
        if(this.hasReachMaxNumberOfSequencerNodeViews() && Utils.getInstance().isWordSequencerNodeInstance(nodeViewToAdd.getModel())){
            return false;
        }
        if(nodeViewToAdd.getModel() && nodeViewToAdd.getModel().canBeAssociactedWithAnySpeaker()  && !nodeViewToAdd.getModel().getSpeakerInfo()){
            return true;
        }
        if(nodeViewToAdd.getModel() && nodeViewToAdd.getModel().getSpeakerInfo()  && nodeViewToAdd.getModel().getSpeakerInfo().getSpeakerId() === this.model.getSpeakerId()){
            return true;
        }
        if((!nodeViewToAdd.getModel() || !nodeViewToAdd.getModel().getSpeakerInfo()) && this.model instanceof UnknownSpeakerInfo){
            return true;
        }
        return false;
    },

    canBeAddedAfter : function(nodeViewToAdd, nodeViewAlreadyInserted){
        if(this.hasReachMaxNumberOfSequencerNodeViews() && Utils.getInstance().isWordSequencerNodeInstance(nodeViewToAdd.getModel())){
            return false;
        }
        if(nodeViewToAdd.getModel() && nodeViewToAdd.getModel().canBeAssociactedWithAnySpeaker() && !nodeViewToAdd.getModel().getSpeakerInfo()){
            return true;
        }
        if(nodeViewToAdd.getModel() && nodeViewToAdd.getModel().getSpeakerInfo()  && nodeViewToAdd.getModel().getSpeakerInfo().getSpeakerId() === this.model.getSpeakerId()){
            return true;
        }
        if((!nodeViewToAdd.getModel() || !nodeViewToAdd.getModel().getSpeakerInfo()) && this.model instanceof UnknownSpeakerInfo){
            return true;
        }
        return false;
    },

    onSpeakerInfoChange:function(){
        this.speakerLabelContainer$el.html(this.model.getSpeakerLabel());
    },

    getSpeakerLabelContainer$el : function(){
        return this.speakerLabelContainer$el;
    },

    getContentContainer$el : function(){
        return this.speakerParagraphContentContainer$el;
    },
    
    render : function() {

        this.$el.html(this.compiledTemlate({"model": this.model, "view":this, "ti18n": ti18n}));
        this.setElement(this.$el.find("div").first());
        this.speakerParagraphContentContainer$el = this.$el.find(".speaker_paragraph_content");
        this.speakerLabelContainer$el = this.$el.find(".speaker_label");
        this.afterRendering();
        return this;
    }

});
export default SpeakerParagraphContainerView;