

import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import UpgradeCreatorSubscriptionWidgetController from "controllers/upgradeCreatorSubscription/UpgradeCreatorSubscriptionWidgetController";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import FacebookEventTrackingHelper from "models/helper/FacebookEventTrackingHelper";
import OptionPopupView from "views/common/OptionPopupView";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const Rollout = RolloutHelper.getInstance();


const UpgradeCreatorSubscriptionPopupController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this._returnUrlOnPaymentMethodUpdated = options? options.returnUrlOnPaymentMethodUpdated: null;
        this._returnUrlOnPaymentUpgradeCompleted = options? options.returnUrlOnPaymentUpgradeCompleted: null;
        
    },


    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },


    onPopupClosed : function(){
        
    },

    onUpgradeCompleted: function(){
        this.view.close();
    },


    buildModelAndRenderView : function() {
        const params = {};
        params.returnUrlOnPaymentUpgradeCompleted = this._returnUrlOnPaymentUpgradeCompleted;
        params.returnUrlOnPaymentMethodUpdated = this._returnUrlOnPaymentMethodUpdated;
        this.upgradeCreatorSubscriptionWidgetController = new UpgradeCreatorSubscriptionWidgetController(params);
        this.listenTo(this.upgradeCreatorSubscriptionWidgetController, "upgradeCompleted", this.onUpgradeCompleted.bind(this));
        return this.upgradeCreatorSubscriptionWidgetController.buildModelAndRenderView().then((function(){
            params.id = "upgradeCreatorSubscriptionPopup";
            params.customClass = "upgradeCreatorSubscriptionPopup";
            params.contentView =  this.upgradeCreatorSubscriptionWidgetController.getView();
            params.contentPosition = "center";
            params.addContentAfterEnhancement = true;
            params.displayTitle = false;
            this.view = new OptionPopupView(params);
            this.listenTo(this.view, "closed",this.onPopupClosed);
            this.view.render();
        }).bind(this))


    }

});

export default UpgradeCreatorSubscriptionPopupController;