import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import ProgressBar from "progressBar";
import SongContextView from "views/common/SongContextView";
import DynamicPageView from "views/common/DynamicPageView";
import PersistentModels from "services/PersistentModels";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import SingleSongSelectorInputView from "views/common/SingleSongSelectorInputView";
import SongContextController from "controllers/common/SongContextController";
import Typeahead from "typeahead";
import Mention from "mention";
import owlcarousel from "owlcarousel";
import Utils from "models/helper/Utils";
import FeatureRolloutHelper from "models/helper/FeatureRolloutHelper";
import RSVP from "rsvp";
import ti18n from "i18n!nls/RecordAudioHightlightPagei18n";
import pageTemplate from 'text!../../../templates/audioHighlight/RecordAudioHighlightPageNewTemplate.html';
import {tz as momentTimezone} from 'moment-timezone';
import moment from 'moment';
import Swiper from 'swiper';
import Shepherd from 'shepherd.js';
import { offset } from '@floating-ui/dom';
import DynamicContentView from "views/common/DynamicContentView"

const CREATION_MODE = {"FROM_FILE":"fromFile", "FROM_RECORDING":"fromRecording","FROM_TEXT":"fromText", "FROM_VOICE_ACTOR":"fromVoiceActor", "FROM_TRANSCRIPT":"fromTranscript"};
const DEFAULT_MAX_RECORD_SIZE_IN_MILLI = LocalStorageHelper.getInstance().getCustomMaxCapsuleLimitInSeconds() ?  LocalStorageHelper.getInstance().getCustomMaxCapsuleLimitInSeconds() * 1000 : 600000;
const DEFAULT_MAX_RECORD_SIZE_IN_MILLI_ON_UPLOAD = 5400000;//LocalStorageHelper.getInstance().getCustomMaxCapsuleLimitInSecondsOnUpload && LocalStorageHelper.getInstance().getCustomMaxCapsuleLimitInSecondsOnUpload() ?  LocalStorageHelper.getInstance().getCustomMaxCapsuleLimitInSecondsOnUpload() * 1000 : 600000;
const TO_BE_DELETED_RECORDING_COLOR = "rgb(217, 30, 24)";
const DEFAULT_TRAIL_COLOR = "rgba(255,255,255,0.1)";
const TO_DELETE_ICON = "ion-ios7-close-empty";
const HINT_DELETE_ICON_MESSAGE = window.getI18n(ti18n,"SELECT_LAST_AUDIO_TO_RECORD");
const READY_TO_DELETED_ICON = "ion-ios7-trash";
const HINT_READY_TO_DELETE_ICON_MESSAGE = window.getI18n(ti18n,"DELETE_SELECTED_AUDIO_RECORDING");

const SHOW_PLAY_BUTTON_INSIDE_CIRCULAR_TIMER =  false;
const DELETE_PREVIOUS_RECORDING_WITHOUT_CONFIRMATION = true;

const ALLOW_USER_TO_RECORD_MULTIPLE_AUDIO_SEGMENT = false;
const SHOW_DECIMAL_IN_RECORDING_TIME = false;

const BACKGROUND_MUSIC_URI_TO_INFO = {};
BACKGROUND_MUSIC_URI_TO_INFO["http://web.trebble.fm/audioassets/284_full_summer-in-brooklyn_0161.mp3"] = {name: "Summer In Brooklyn",artistName  : "Mattjis Muller", downloadUrl : "https://www.premiumbeat.com/royalty_free_music/songs/summer-in-brooklyn"};
BACKGROUND_MUSIC_URI_TO_INFO["http://web.trebble.fm/audioassets/200_full_paper-stars_0147.mp3"] = {name: "Paper Stars",artistName  : "Liam Aidan", downloadUrl : "https://www.premiumbeat.com/royalty-free-tracks/paper-stars"};
BACKGROUND_MUSIC_URI_TO_INFO["http://web.trebble.fm/audioassets/texasradiofish_-_Rey_DM.mp3"] = {name: "Rey_DM",artistName  : "Texasradiofixh", downloadUrl : "http://dig.ccmixter.org/files/texasradiofish/46274"};
BACKGROUND_MUSIC_URI_TO_INFO["http://web.trebble.fm/audioassets/mood-music_715141.mp3"] = {name: "Mood Music",artistName  : "Mike Sears", downloadUrl : "https://www.beatstars.com/beat/mood-music-715141"};
BACKGROUND_MUSIC_URI_TO_INFO["http://web.trebble.fm/audioassets/drunk-texting_592809.mp3"] = {name: "Drunk Texting",artistName  : "Mike Sears", downloadUrl : "https://www.beatstars.com/beat/drunk-texting-592809"};
BACKGROUND_MUSIC_URI_TO_INFO["http://web.trebble.fm/audioassets/moon_795265.mp3"] = {name: "Moon",artistName  : "Lo Life", downloadUrl : "https://www.beatstars.com/beat/moon-795265"};
BACKGROUND_MUSIC_URI_TO_INFO["http://web.trebble.fm/audioassets/millyrock_752134.mp3"] = {name: "MillyRock",artistName  : "Lo Life", downloadUrl : "https://www.beatstars.com/beat/millyrock-752134"};
BACKGROUND_MUSIC_URI_TO_INFO["http://web.trebble.fm/audioassets/afro_trap_beat.mp3"] = {name: "Afro Trap",artistName  : "Winner on the track", downloadUrl : "https://www.youtube.com/watch?v=9uEgMtIPNLY"};
BACKGROUND_MUSIC_URI_TO_INFO["http://web.trebble.fm/audioassets/naza_keblack_type_beat.mp3"] = {name: "AfroBeat",artistName  : "Monedaz", downloadUrl : "https://www.youtube.com/watch?v=unIVBXi8Fo4"};
BACKGROUND_MUSIC_URI_TO_INFO["http://web.trebble.fm/audioassets/9197010_David___Goliath_Extended_Mix.mp3"] = {name: "David & Goliath",artistName  : "Jonas Aden", downloadUrl : "https://www.spinninrecords.com/releases/david-goliath/"};
BACKGROUND_MUSIC_URI_TO_INFO["http://web.trebble.fm/audioassets/9432857_Bubblegum_Original_Mix.mp3"] = {name: "Bubblegum",artistName  : "Sonny Bass, Jordi Rivera", downloadUrl : "https://www.spinninrecords.com/releases/bubblegum/"};
BACKGROUND_MUSIC_URI_TO_INFO["http://web.trebble.fm/audioassets/9710435_Look_Away_Extended_Mix.mp3"] = {name: "Look Away",artistName  : "JLV", downloadUrl : "https://www.spinninrecords.com/releases/look-away/"};
const CHOOSE_BACKGROUND_TRACK_LABEL  = window.isMobileBrowser? '<div >'+window.getI18n(ti18n, "SWIPE_TO_SELECT_BACKGROUND_MUSIC")+'</div>':'<div >'+window.getI18n(ti18n, "TAP_ARROW_TO_SELECT_BACKGROUND_MUSIC")+'</div>';
const NO_BACKGROUND_TRACK  = '<br/><span style="font-weight: bold">'+window.getI18n(ti18n, "NO_BACKGROUND_MUSIC")+'</span>';
const DO_NOT_SHOW_UPLOAD_AUDIO_FILE_IN_PAGE_HEADER = false;
const DO_NOT_SHOW_RECORD_AUDIO_IN_PAGE_HEADER = false;
const PUBLISHED_SETTING_VALUES = {"NOW":"now","SCHEDULED":"scheduled"};
const SHOW_BACKGROUND_TRACK_SELECTOR_OUTSIDE_OF_TIMER = true;
const BACKGROUND_MUSIC_SELECTION_BOX_HEIGHT =  90;
const SHOW_COUNTER_TIMER_CLASS =  "showCountTimer";
const DO_NOT_SHOW_HEADER_BUTTONS_TO_SWITCH_MODES = true;
const ALLOW_CHOOSING_MUSIC_ON_MOBILE_APP = FeatureRolloutHelper.getInstance().isFeatureEnabled(FeatureRolloutHelper.FEATURES.ALLOW_USE_MUSIC_WHILE_RECORDING_CAPSULE_ON_MOBILE);
const CSS_PAGE_BACKGROUND =  FeatureRolloutHelper.getInstance().getFeatureVariable(FeatureRolloutHelper.FEATURES.CAPSULE_RECORDING_UI_DEFAULT_CONFIG,FeatureRolloutHelper.FEATURES.CAPSULE_RECORDING_UI_DEFAULT_CONFIG.variables.css_page_background, null);
const IS_CAPSULE_CREATION_VIA_TEXT_AVAILABLE = FeatureRolloutHelper.getInstance().isFeatureEnabled(FeatureRolloutHelper.FEATURES.CREATE_CAPSULE_FROM_TEXT);
const IS_CAPSULE_CREATION_VIA_VOICE_ACTOR = FeatureRolloutHelper.getInstance().isFeatureEnabled(FeatureRolloutHelper.FEATURES.CREATE_CAPSULE_USING_VOICE_ACTOR);
const ENABLE_ADDING_MUSIC_TO_PLAY_AFTER_CAPSULE = FeatureRolloutHelper.getInstance().isFeatureEnabled(FeatureRolloutHelper.FEATURES.ENABLE_CAPABILITY_TO_PLAY_MUSIC_AFTER_CAPSULE);
const CONFIGURE_BACKGROUND_MUSIC = FeatureRolloutHelper.getInstance().isFeatureEnabled(FeatureRolloutHelper.FEATURES.CONFIGURE_BACKGROUND_MUSIC);
const MAX_NUMBER_OF_WORDS = FeatureRolloutHelper.getInstance().getFeatureVariable(FeatureRolloutHelper.FEATURES.CREATE_CAPSULE_USING_VOICE_ACTOR, FeatureRolloutHelper.FEATURES.CREATE_CAPSULE_USING_VOICE_ACTOR.variables.max_number_of_words_allowed, 500);
const MIN_HOUR_REQUIRED_FOR_NARRATION = FeatureRolloutHelper.getInstance().getFeatureVariable(FeatureRolloutHelper.FEATURES.CREATE_CAPSULE_USING_VOICE_ACTOR, FeatureRolloutHelper.FEATURES.CREATE_CAPSULE_USING_VOICE_ACTOR.variables.min_time_required_for_narration_in_hours, 12);
const USE_DEFAULT_MUSIC_SELECTOR =  false;
const TREBBLE_TEXT_BASED_AUDIO_EDITOR = FeatureRolloutHelper.getInstance().isFeatureEnabled(FeatureRolloutHelper.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR, true);
const DISABLE_CAN_SAVE_SHORTCAST_ONLY_AFTER_EDIT = FeatureRolloutHelper.getInstance().isFeatureEnabled(FeatureRolloutHelper.FEATURES.DISABLE_CAN_SAVE_SHORTCAST_ONLY_AFTER_EDIT, false);
const RECORD_PAGE_ONBOARDING = FeatureRolloutHelper.getInstance().isFeatureEnabled(FeatureRolloutHelper.FEATURES.RECORD_PAGE_ONBOARDING, true);

const RecordAudioHightlightContentView = DynamicContentView.extend({



    "pageName": "Record Audio Hightlight Page",

    initialize: function(options) {
        const viewOptions = {};
        this._songModel = options.model;
        this._pageId = options.pageId;
        this._audioRecordingSupported = options.audioRecordingSupported;
        this._customPageTitle = options.customPageTitle;
        this._capsuleInfoToEdit = null;
        // this._songSelectorListView = options.songSelectorListView;
        this._songAttachedToCapsuleSongSelectorInputView = options.songAttachedToCapsuleSongSelectorInputView;
        this._backgroundSongSelectorInputView = options.backgroundSongSelectorInputView;
        this._capsuleInfoProgressBarView = options.capsuleInfoProgressBarView;
        this._audioEditorProgressBarView = options.audioEditorProgressBarView;
        this._recordKey = options.recordKey;
        this._createAShortcast  = options.createAShortcast;
        this._followersUsersInfo = options.followersUsersInfo;
        this._creationModeSelectorView = options.creationModeSelectorView;
        this._creationModeFlatListViewContainer$el = options.creationModeFlatListViewContainer$el;
        this._receiverUserId = options.receiverUserId;
        this._saveAsDraft = options.saveAsDraft;
        this._selectedDefaultTranscriptionLanguage = options.selectedDefaultTranscriptionLanguage;
        this._capsuleSchedulingAvailable = options.capsuleSchedulingAvailable;
        this._canSaveOnlyAfterTranscriptionAndEditing = options.canSaveOnlyAfterTranscriptionAndEditing? !DISABLE_CAN_SAVE_SHORTCAST_ONLY_AFTER_EDIT: false;
        this._recordMaxSize = (options && options.recordMaxSize) ? options.recordMaxSize : DEFAULT_MAX_RECORD_SIZE_IN_MILLI;
        const contentView = {};
        this.deletionMode = false;
        this.isReadyToBedeleted = false;
        this.template = _.template(pageTemplate);
        this._capsuleCategoryCollection =options.capsuleCategoryCollection;
        this._recordingInstructionsDisplayed = true;
        this._timezoneCollection =options.timezoneCollection;
        this._supportedTranscriptionLanguagesCollection  = options.supportedTranscriptionLanguagesCollection;
        this._selectedDefaultCategoryId = options.selectedDefaultCategoryId;
        this._selectedDefaultTimezone = options.selectedDefaultTimezone;
        this._peakMeterView = options.peakMeterView;
        this._audioEditorView = null;

        this._uploadElementId = "uploadWrapper_"+((new Date()).getTime());
        const songJson = this._songModel? this._songModel.toJSON(): null;
        this._toBeSentToAnotherUser = this._receiverUserId? true: false;
        const templateParams = {"model": songJson, "uploadElementId": this._uploadElementId,"pageId": this._pageId, "toBeSentToAnotherUser": this._toBeSentToAnotherUser , "maxCapsuleLimitInMillisec": DEFAULT_MAX_RECORD_SIZE_IN_MILLI, "maxCapsuleLimitInMillisecOnUpload": DEFAULT_MAX_RECORD_SIZE_IN_MILLI_ON_UPLOAD, "ti18n": ti18n, "customPageTitle": this._customPageTitle, "createAShortcast": this._createAShortcast, "ALLOW_TRANSCRIPTION":TREBBLE_TEXT_BASED_AUDIO_EDITOR};
        this._templateParams = templateParams;
        templateParams.moment = moment;
        //contentView.$el = $(contentViewTemplate(templateParams));





        this._uploadedFileAdded = false;

        this._completedRecordedSnippetArray = [];
        this._nextButtonEnable = true;
        this._currentRecordedSnippet = [];
        this._isRecording = false;
        this._currentRecordedFile = null;
        this._isPlaying = false;
        this._isPlaybackLoading = false;
        DynamicContentView.prototype.initialize.call(this, {"canBeAppendedBeforeLoad": true});
    },

    events : {
        "click #header-back-button" : "navigateToPreviousPageConfirm",
        "click #deleteButton:not(.trebble_disabled)" :"_onDeleteBtnClicked",
        "click #showUploadSectionButton" : "_showUploadSection",
        "click [id=showRecordSectionButton]" : "_showRecordSection",
        "click [id=showTextToSpeechSectionButton]" : "_showTextToSpeechSection",
        "click [id=showVoiceActorNarrationSectionButton]" : "_showUseVoiceActorSection",
        "click #playButton:not(.trebble_disabled)": "_playStopPlayingRecording",
        "click #saveButtonIcon:not(.trebble_disabled)" : "_saveRecording",
        "click #nextButton:not(.trebble_disabled)" : "onNextButtonClicked",
        "click #previousButton:not(.trebble_disabled)" : "onPreviousButtonClicked",
        "click #recordButton" :"_onRecordRecordingButtonClicked",
        "click #stopRecordingButton" :"_onPauseRecordingButtonClicked",
        "click #whatsacapsuleButton" : "onWhatsACapsuleButtonClicked",
        "click #showRecordingSettingsButton" : "_showRecordingSettings",
        "click #transcribe_and_edit_audio_btn" : "showTranscriptionParameters",
        "click #continue_with_no_edit_btn:not(.trebble_disabled)" : "onNextButtonClicked",
        "click #transcribe_btn:not(.trebble_disabled)" : "_onTranscribeAndEditAudioBtnClicked"
        



    },

    _onTranscribeAndEditAudioBtnClicked : function(){
        this.hideTranscriptionParameters();
        this.trigger("transcribeAndEditAudio");

    },

    _addCapsuleCategoriesToView : function(){
        const selectCategory$El =  this.$el.find("#categoryFld");
        const selectedLabel$El = this.$el.find("#categoryFld-button > span");
        if(this._capsuleCategoryCollection){
            this._capsuleCategoryCollection.each((function(categoryListItemModel){
                if(this._selectedDefaultCategoryId == categoryListItemModel.getId()){
                    selectCategory$El.append("<option value='"+categoryListItemModel.getId()+"' selected='selected'>"+ categoryListItemModel.getLabel()+"</option>");
                    selectedLabel$El.html(categoryListItemModel.getLabel());
                }else{
                    selectCategory$El.append("<option value='"+categoryListItemModel.getId()+"'>"+ categoryListItemModel.getLabel()+"</option>");
                }
            }).bind(this));
        }
    },


    _addTimezonesToView : function(){
        const selectTimezone$El =  this.$el.find("#schedulingTimezoneFld");
        const selectTimezoneLabel$El = this.$el.find("#schedulingTimezoneFld-button > span");
        if(this._timezoneCollection){
            this._timezoneCollection.each((function(timezoneListItemModel){
                if(this._selectedDefaultTimezone == timezoneListItemModel.getId()){
                    selectTimezone$El.append("<option value='"+timezoneListItemModel.getId()+"' selected='selected'>"+ timezoneListItemModel.getLabel()+"</option>");
                    selectTimezoneLabel$El.html(timezoneListItemModel.getLabel());
                }else{
                    selectTimezone$El.append("<option value='"+timezoneListItemModel.getId()+"'>"+ timezoneListItemModel.getLabel()+"</option>");
                }
            }).bind(this));
        }
    },

    _addTranscriptioneSupportedLanguageToView : function(){
        const selectSupportedTranscriptionLanguage$El =  this.$el.find("#languageUsedInAudioFld");
        const selectSupportedTranscriptionLanguageLabel$El = this.$el.find("#languageUsedInAudioFld-button > span");
        if(this._supportedTranscriptionLanguagesCollection){
            this._supportedTranscriptionLanguagesCollection.each((function(languageListItemModel){
                if(this._selectedDefaultTranscriptionLanguage == languageListItemModel.getId()){
                    selectSupportedTranscriptionLanguage$El.append("<option value='"+languageListItemModel.getId()+"' selected='selected'>"+ languageListItemModel.getLabel()+"</option>");
                    selectSupportedTranscriptionLanguageLabel$El.html(languageListItemModel.getLabel());
                }else{
                    selectSupportedTranscriptionLanguage$El.append("<option value='"+languageListItemModel.getId()+"'>"+ languageListItemModel.getLabel()+"</option>");
                }
            }).bind(this));
        }
    },

    setAudioRecordingSupported: function(audioRecordingSupported){
        this._audioRecordingSupported = audioRecordingSupported;
        if(this._audioRecordingSupported){
           this._showRecordSection();
       }else{
           this._showUploadSection();
       }
   },
   _onUserTapOnPoupContent: function() {
    if (!this._isRecording) {
        this._cancelSnippetDeletion();
    }
},

showCounterAnimation : function(numberOfSecondsToCount){
    return this.showCounterAnimationRecursive(numberOfSecondsToCount);
},

_createTourStepForWelcomeToRecordPage :function(tour){
    return {
        title: window.getI18n(ti18n, "ONBOARD_STEP_1_TITLE_WELCOME_TO_STUDIO_PAGE"),
        text: window.getI18n(ti18n, "ONBOARD_STEP_1_DESC_WELCOME_TO_STUDIO_PAGE"),
        attachTo: {
            element: this.$el.find("#creation_mode_flat_list_container").get(0),
            on: "bottom",
        },
        arrow: true,
        modalOverlayOpeningPadding: 10,
        floatingUIOptions: {
            middleware: [offset({ mainAxis: 20, crossAxis: 0 })]
          },
        buttons: [
          {
            action: function () {
                  if (this.$el.is(":visible")) {
                    return tour.complete();
                  } else {
                    if (window.trebbleAnalyticsHelper) {
                      window.trebbleAnalyticsHelper.trackEvent(
                        "recordPage",
                        "recordPageeNotVisibleToContinueOnboarding",
                        "My record page not visible to continue onboarding",
                      );
                    }
                  }
             
            }.bind(this),
            text: window.getI18n(ti18n, "OKAY"),
          },
        ],
        id: "onboarding_welcome_my_record_page_step",
      };
},

_createTourStepForSaveOrEditPage: function(tour){
    return {
        title: window.getI18n(ti18n, "ONBOARD_STEP_1_TITLE_SAVE_OR_EDIT_PAGE"),
        text: window.getI18n(ti18n, "ONBOARD_STEP_1_DESC_SAVE_OR_EDIT_PAGE"),
        attachTo: {
            element: this.$el.find(".message_to_start_transcription_box_wrapper > .actionButtonWrapper ").get(0),
            on: "top",
        },
        arrow: true,
        modalOverlayOpeningPadding: 0,
        floatingUIOptions: {
            middleware: [offset({ mainAxis: 20, crossAxis: 0 })]
          },
        buttons: [
          {
            action: function () {
                  if (this.$el.is(":visible")) {
                    return tour.complete();
                  } else {
                    if (window.trebbleAnalyticsHelper) {
                      window.trebbleAnalyticsHelper.trackEvent(
                        "recordPage",
                        "saveOrEditPageeNotVisibleToContinueOnboarding",
                        "Save Or Edit page not visible to continue onboarding",
                      );
                    }
                  }
             
            }.bind(this),
            text: window.getI18n(ti18n, "OKAY"),
          },
        ],
        id: "onboarding_save_or_edit_page_step",
      };
},

showSaveOrEditOnboardingInstruction :function(){
    if (RECORD_PAGE_ONBOARDING && this.messageToStartTranscriptionBox$el.is(":visible") && !LocalStorageHelper.getInstance().isSaveOrEditPageOnboardingWasCompletedOrDismissed() && !this._saveOrEditOnboardingTour && this.$el.is(":visible")) {
        try {
          const tour = new Shepherd.Tour({
            useModalOverlay: true,
            keyboardNavigation: false,
            defaultStepOptions: {
              cancelIcon: {
                enabled: false,
              },
  
              classes: "sequencer-onboarding-popup",
              scrollTo: false,
            },
          });
          //this._selectFourLinesOfTextIfContextMenuIsNotDisplayed();
          tour.addStep(this._createTourStepForSaveOrEditPage(tour));
  
          tour.start();
          this._saveOrEditOnboardingTour = tour;
          tour.once(
            "start",
            function () {
              this.trigger("saveorEditPageOnboardingTourStarted");
              if (window.trebbleAnalyticsHelper) {
                window.trebbleAnalyticsHelper.trackEvent(
                  "recordPage",
                  "saveorEditPageOnboardingStarted",
                  "Save Or Edit Page Onboarding Started ",
                );
              }
            }.bind(this),
          );
          tour.once(
            "complete",
            function () {
              this.trigger("saveorEditPageOnboardingTourCompleted");
              this._saveOrEditOnboardingTour = null;
              if (window.trebbleAnalyticsHelper) {
                window.trebbleAnalyticsHelper.trackEvent(
                  "recordPage",
                  "saveorEditPageOnboardingCompleted",
                  "Save Or Edit Page Onboarding Completed ",
                );
              }
            }.bind(this),
          );
          tour.once(
            "cancel",
            function () {
              this.trigger("saveorEditPageOnboardingTourCancelled");
              this._saveOrEditOnboardingTour = null;
              if (window.trebbleAnalyticsHelper) {
                window.trebbleAnalyticsHelper.trackEvent(
                  "recordPage",
                  "saveorEditPageOnboardingCancelled",
                  "Save Or Edit Page Onboarding Cancelled ",
                );
              }
            }.bind(this),
          );
  
          tour.on(
            "show",
            function () {
              this.trigger("saveorEditPageOnOnboardingStepDisplayed");
              const stepDisplayedId = this.getOnboardingCurrentStepIdDisplayed();
              if (window.trebbleAnalyticsHelper) {
                window.trebbleAnalyticsHelper.trackEvent(
                  "recordPage",
                  "saveorEditPageOnboardingStepDisplayed",
                  "Save Or Edit Page Onboarding Step Displayed ",
                  null,
                  { stepDisplayedId: stepDisplayedId },
                );
              }
            }.bind(this),
          );
        } catch (error) {
          console.error(error);
          if (window.trebbleAnalyticsHelper) {
            window.trebbleAnalyticsHelper.trackEvent(
              "recordPage",
              "FailedToCreaterecordPageOnboarding",
              "Failed to create Save Or Edit page onboarding ",
              null,
              { error: error },
            );
          }
        }
      } else {
        if (!this._saveOrEditOnboardingTour && window.trebbleAnalyticsHelper) {
          window.trebbleAnalyticsHelper.trackEvent(
            "recordPage",
            "saveOrEditPageNotVisibleToContinueOnboarding",
            "Record Page not visible to continue onboarding",
          );
        }
      }
},

showRecordPageOnboardingInstruction :function(){
    if (!this._recordOnboardingTour && this.$el.is(":visible")) {
        try {
          const tour = new Shepherd.Tour({
            useModalOverlay: true,
            keyboardNavigation: false,
            defaultStepOptions: {
              cancelIcon: {
                enabled: false,
              },
  
              classes: "sequencer-onboarding-popup",
              scrollTo: { behavior: "smooth", block: "center" },
            },
          });
          //this._selectFourLinesOfTextIfContextMenuIsNotDisplayed();
          tour.addStep(this._createTourStepForWelcomeToRecordPage(tour));
  
          tour.start();
          this._recordOnboardingTour = tour;
          tour.once(
            "start",
            function () {
              this.trigger("recordPageOnboardingTourStarted");
              if (window.trebbleAnalyticsHelper) {
                window.trebbleAnalyticsHelper.trackEvent(
                  "recordPage",
                  "recordPageOnboardingStarted",
                  "Record Page Onboarding Started ",
                );
              }
            }.bind(this),
          );
          tour.once(
            "complete",
            function () {
              this.trigger("recordPageOnboardingTourCompleted");
              this._recordOnboardingTour = null;
              if (window.trebbleAnalyticsHelper) {
                window.trebbleAnalyticsHelper.trackEvent(
                  "recordPage",
                  "recordPageOnboardingCompleted",
                  "Record Page Onboarding Completed ",
                );
              }
            }.bind(this),
          );
          tour.once(
            "cancel",
            function () {
              this.trigger("recordPageOnboardingTourCancelled");
              this._recordOnboardingTour = null;
              if (window.trebbleAnalyticsHelper) {
                window.trebbleAnalyticsHelper.trackEvent(
                  "recordPage",
                  "recordPageOnboardingCancelled",
                  "Record Page Onboarding Cancelled ",
                );
              }
            }.bind(this),
          );
  
          tour.on(
            "show",
            function () {
              this.trigger("recordPageOnOnboardingStepDisplayed");
              const stepDisplayedId = this.getOnboardingCurrentStepIdDisplayed();
              if (window.trebbleAnalyticsHelper) {
                window.trebbleAnalyticsHelper.trackEvent(
                  "recordPage",
                  "recordPageOnboardingStepDisplayed",
                  "Record Page Onboarding Step Displayed ",
                  null,
                  { stepDisplayedId: stepDisplayedId },
                );
              }
            }.bind(this),
          );
        } catch (error) {
          console.error(error);
          if (window.trebbleAnalyticsHelper) {
            window.trebbleAnalyticsHelper.trackEvent(
              "recordPage",
              "FailedToCreaterecordPageOnboarding",
              "Failed to create Record page onboarding ",
              null,
              { error: error },
            );
          }
        }
      } else {
        if (!this._recordOnboardingTour && window.trebbleAnalyticsHelper) {
          window.trebbleAnalyticsHelper.trackEvent(
            "recordPage",
            "recordPageNotVisibleToContinueOnboarding",
            "Record Page not visible to continue onboarding",
          );
        }
      }
},

showCounterAnimationRecursive : function(numberToShow){
    if(numberToShow == 0){
        this.$el.removeClass(SHOW_COUNTER_TIMER_CLASS);
        this.$el.css("padding-top", "54px");//We are doing this because it seems like the padding gets removed during the animation but it doesn't come back after
        return RSVP.Promise.resolve();
    }else{
        this.counterAnimationBox$el.html("<div id='counterNumber' >"+numberToShow+"</div>");
        const counterNumber$el =  this.counterAnimationBox$el.find("#counterNumber");
        counterNumber$el.html(numberToShow);
        if(!this.$el.hasClass(SHOW_COUNTER_TIMER_CLASS)){
            this.$el.addClass(SHOW_COUNTER_TIMER_CLASS)
        }
        return Utils.getInstance().applyAnimationCssClass(counterNumber$el,"zoomIn", "1s").then((function(){
            return this.showCounterAnimationRecursive(numberToShow - 1);
        }).bind(this)).catch(function(error){
            console.error(error);
        })
    }
    
},

showTranscriptionParameters : function(){
    this.transcribeAndEditBtn$el.hide();
    this.transcriptionParametersBox$el.show();
    this._onTranscriptionLanguageFieldValueChange();
},

hideTranscriptionParameters : function(){
    this.transcriptionParametersBox$el.hide()
    this.transcribeAndEditBtn$el.show();
    this._onTranscriptionLanguageFieldValueChange();
    if(this._canSaveOnlyAfterTranscriptionAndEditing){
        this.showTranscriptionParameters();
    }
},

setIsPlaybackLoading : function(isPlaybackLoading){
    if(!this._isRecording){
        this._isPlaybackLoading = isPlaybackLoading;
    }
    this._updatePlayPauseButtonClass();
},

isPlaying : function(){
    return this._isPlaying;
},

setIsPlaying: function(isPlaying) {
    this._isPlaying = isPlaying;
    this._updatePlayPauseButtonClass();
},

setUploadedFileAdded : function(uploadedFileAdded){
    this._uploadedFileAdded = uploadedFileAdded;
    this._enableNextButtonIfApplicable();
    this._updateHeaderButtons();
},

onTextToConvertToAudioFieldChange : function(){
    this._enableNextButtonIfApplicable();
    this._updateHeaderButtons();
},

onTextToNarrateFieldChange : function(){
    this._isTextToNarrateCountValid();
    this._enableNextButtonIfApplicable();
    this._updateHeaderButtons();
},

_onTranscriptionLanguageFieldValueChange :function(){
    if(this.getLanguageUsedInAudio()){
        this.$el.find("#transcribe_btn").removeClass("trebble_disabled");
    }else{
        this.$el.find("#transcribe_btn").addClass("trebble_disabled");
    }
},

_setSaveAsDraft :function(){
    this.doNotAddOnAirCheckbox$el.get(0).checked = true;
    this.onDoNotAddOnAirCheckboxChange();
    this.$el.attr("saveAsDraft","true");
}, 


_isTextToNarrateCountValid :function(){
    const textToNarrate = this.getTextToNarrate();
    const wordCount = Utils.getInstance().wordCount(textToNarrate);
    const numberOfWordRemaining = MAX_NUMBER_OF_WORDS - wordCount;
    this.wordCountNumber$el.html(numberOfWordRemaining);
    return numberOfWordRemaining >= 0;

},

getUploadElementId : function(){
    return this._uploadElementId;
},

setContentLoading : function(contentLoading){
    this.$el.attr("content_loading", !!contentLoading);
},

setAudioEditorView : function(audioEditorView){
    if(this._audioEditorView){
        this._audioEditorView.$el.remove();
    }
    this._audioEditorView = audioEditorView;
    this.$el.find(".sequencer_node_wrapper").append(this._audioEditorView.$el);
    if(window.trebbleAnalyticsHelper){
        window.trebbleAnalyticsHelper.trackEvent("audioEditor", "Audio Editor was added to page", "Audio Editor was added to page");
    }
},

_updatePlayPauseButtonClass: function() {
    const pauseClass = "ion-ios7-pause";//"fontello-icon-pause-1";
    const pauseHintMessage = "Pause";
    const playClass = "ion-ios7-play";//"fontello-icon-play-1";
    const playHintMessage = "Pause";
    const loadingClass = "ion-loading-c";
    const loadingHintMessage = "Loading";
    const playButtonEl = this.$el.find("#playButton");
    const playButtonElIcon = this.$el.find("#playButton > t");
    if (this._isPlaybackLoading) {
        playButtonElIcon.removeClass(playClass);
        playButtonElIcon.removeClass(pauseClass);
        playButtonElIcon.addClass(loadingClass);
        playButtonEl.attr("data-hint","");
    } else {
        playButtonElIcon.removeClass(loadingClass);
        if (this._isPlaying) {
            playButtonElIcon.removeClass(playClass);
            playButtonElIcon.addClass(pauseClass);
            playButtonEl.attr("data-hint",pauseHintMessage);
        } else {
            playButtonElIcon.removeClass(pauseClass);
            playButtonElIcon.addClass(playClass);
            playButtonEl.attr("data-hint",playHintMessage);
        }
    }

},
_initializeCarousels: function() {
 //this.$el.find("#songSelectorWrapper").append(this._songSelectorListView.$el);
 this.$el.find("#songToPlayAfterCapsuleInputPlaceholder").append(this._songAttachedToCapsuleSongSelectorInputView.$el);
 this.$el.find("#backgroundMusicInputPlaceholder").append(this._backgroundSongSelectorInputView.$el);
 this.carousel$el = this.$el.find(".carouselWrapper").owlCarousel({

    navigation: false, // Show next and prev buttons
    pagination: false,
    slideSpeed: 300,
    paginationSpeed: 400,
    afterAction: this._onCarouselMove.bind(this),
    touchDrag: false,
    mouseDrag: false,
    singleItem: true
});
 this._numberOfCarouselItems = TREBBLE_TEXT_BASED_AUDIO_EDITOR ? 3: 2;
 this._currentCarouselPositon = 0;
 this.setSelectedSongView(null);
},

_addArrowToSelectWidget: function() {
this.$el.find("#capsuleLifeSpanFld-button").append('<i class="ion-chevron-down" style="display: inline-block;"></i>');
this.$el.find("#categoryFld-button").append('<i class="ion-chevron-down" style="display: inline-block;"></i>');
this.$el.find("#schedulingTimezoneFld-button").append('<i class="ion-chevron-down" style="display: inline-block;"></i>');
this.$el.find("#privacySettingFld-button").append('<i class="ion-chevron-down" style="display: inline-block;"></i>');
this.$el.find("#publishingSettingFld-button").append('<i class="ion-chevron-down" style="display: inline-block;"></i>');
this.$el.find("#ttsLanguageFld-button").append('<i class="ion-chevron-down" style="display: inline-block;"></i>');
this.$el.find("#ttsLanguageForVoiceActorFld-button").append('<i class="ion-chevron-down" style="display: inline-block;"></i>');
this.$el.find("#languageUsedInAudioFld-button").append('<i class="ion-chevron-down" style="display: inline-block;"></i>');
this.$el.find("#voiceProfileFld-button").append('<i class="ion-chevron-down" style="display: inline-block;"></i>');
this.$el.find("#voiceToneFld-button").append('<i class="ion-chevron-down" style="display: inline-block;"></i>');

},

_onCarouselMove: function() {

},

setCapsuleInfoToEdit: function(capsuleInfoToEdit){
    this._capsuleInfoToEdit = capsuleInfoToEdit;
    if(this._capsuleInfoToEdit){
        this.titleField$el.val(this._capsuleInfoToEdit.getTitle());
        this.comment$el.val(this._capsuleInfoToEdit.getText());
    }

},

navigateToPreviousPage : function(){
return PersistentModels.getInstance().getRouter().navigateToPreviousPage();
},

_setPageCanBeDeletedIfHidden: function(canBeDeletedIfHidden){
this._canBeDeletedIfHidden = canBeDeletedIfHidden;
},

_canPageBeDeletedIfHidden : function(){
return this._canBeDeletedIfHidden;
},

navigateToPreviousPageConfirm : function(){
if(this.wasAudioContentCreated()){
    const buttonLabels = [window.getI18n(ti18n,"NO"), window.getI18n(ti18n,"YES")];
    const confirmCallback = (function(buttonIndex){
        if(buttonIndex ==  2){
            this._setPageCanBeDeletedIfHidden(true);
            this.navigateToPreviousPage();
        }
    }).bind(this);
    const message = window.getI18n(ti18n,"ARE_YOU_WANT_TO_NAVIGATE_TO_PREVIOUS_SCREEN");
    const subMessage = window.getI18n(ti18n,"ALL_YOUR_RECORDING_AND_EDITS_WILL_BE_LOST");
    navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n,"DELETE_CAPSULE"), buttonLabels,null , null, subMessage);
}else{
    this._setPageCanBeDeletedIfHidden(true);
    this.navigateToPreviousPage();
}
},

_showPauseRecordingButton : function(show){
if(show){
    this.recordButtonWrapper$el.addClass("hidden");
    this.stopRecordingButton$el.removeClass("hidden");

}else{
    this.stopRecordingButton$el.addClass("hidden");
    this.recordButtonWrapper$el.removeClass("hidden");
}
},

_onPauseRecordingButtonClicked : function(){
this._stopRecording();
this._showPauseRecordingButton(false);
},

_onRecordRecordingButtonClicked : function(){
this.trigger("checkRecordingPermissionAndRecord");
},

onUserStartRecording : function(){
this._startRecording();
this.recordingInstruction$el.hide();
this._recordingInstructionsDisplayed  = false;
if(this.trackContainer$el){
    this.trackContainer$el.hide()
}
if(this.trackSlectorBlockSpacer$el){
    this.trackSlectorBlockSpacer$el.css("display", "flex");
}
this._showPauseRecordingButton(true);
},

_initpublishingSettingField :function(){
this.$el.find("#publishingSettingFld").on("change",this._hideShowSchedulingDateFieldIfApplicable.bind(this));
if(!this._capsuleSchedulingAvailable){
    this.publishingSettingWrapper$el.hide();
}
},

_onVoiceActorNarrationSelected : function(){
this.$el.find("#publishingSettingFld option[value="+PUBLISHED_SETTING_VALUES.NOW+"]").attr('disabled', true);
this.$el.find(".narration_schedule_warning .number_of_hours_for_narration").html(MIN_HOUR_REQUIRED_FOR_NARRATION);
this.$el.find(".narration_schedule_warning").show();
if(this.getPublishedSetting() == PUBLISHED_SETTING_VALUES.NOW){
    this.$el.find("#publishingSettingFld").find(":selected").removeAttr("selected");
    this.$el.find("#publishingSettingFld").find("[value="+PUBLISHED_SETTING_VALUES.SCHEDULED+"]").attr("selected","selected");
    this.$el.find("#publishingSettingFld").val(PUBLISHED_SETTING_VALUES.SCHEDULED).change();
}
},

_onVoiceActorNarrationUnselected : function(){
this.$el.find("#publishingSettingFld option[value="+PUBLISHED_SETTING_VALUES.NOW+"]").removeAttr('disabled');
this.$el.find(".narration_schedule_warning").hide();
},

_initSchedulingDatePicker : function(){
const tomorrow = new Date();
tomorrow.setDate(new Date().getDate()+1);
tomorrow.setHours(0,0,0,0);

const useLastCapsuleScheduleDate = window._lastCapsuleUploadSettings && window._lastCapsuleUploadSettings.capsuleLastScheduleDateInCurrentSession && tomorrow.getTime() <= window._lastCapsuleUploadSettings.capsuleLastScheduleDateInCurrentSession.getTime();

this.schedulingSettingFld$el.pickadate({
    selectMonths: true, // Creates a dropdown to control month
    selectYears: 10,
    min: tomorrow,
    container: this.$el,
    containerHidden: this.$el,
    formatSubmit: 'yyyy/mm/dd',
    today: window.getI18n(ti18n,"TODAY"),
    clear: window.getI18n(ti18n,"CLEAR"),
    close: window.getI18n(ti18n,"OK"),
    onClose: function() {
        $(document.activeElement).blur();
    },
    onStart: function ()
    {
        const date = (useLastCapsuleScheduleDate) ? window._lastCapsuleUploadSettings.capsuleLastScheduleDateInCurrentSession : new Date();
        this.set('select', [date.getFullYear(), date.getMonth() , date.getDate()+ 1]);
    },
    closeOnSelect: false, // Close upon selecting a date,
    // container: 'body', // ex. 'body' will append picker to body
});
if(useLastCapsuleScheduleDate){
    this.$el.find("#publishingSettingFld").find(":selected").removeAttr("selected");
    this.$el.find("#publishingSettingFld").find("[value="+PUBLISHED_SETTING_VALUES.SCHEDULED+"]").attr("selected","selected");
    this.$el.find("#publishingSettingFld").val(PUBLISHED_SETTING_VALUES.SCHEDULED).change();
    if(window._lastCapsuleUploadSettings && window._lastCapsuleUploadSettings.capsuleLastScheduleDurationInCurrentSession){
        this.$el.find("#capsuleLifeSpanFld").find(":selected").removeAttr("selected");
        this.$el.find("#capsuleLifeSpanFld").find("[value="+window._lastCapsuleUploadSettings.capsuleLastScheduleDurationInCurrentSession+"]").attr("selected","selected");
        this.$el.find("#capsuleLifeSpanFld").val(window._lastCapsuleUploadSettings.capsuleLastScheduleDurationInCurrentSession).change();
    }
    this._hideShowSchedulingDateFieldIfApplicable();
}
},

_initSchedulingTimePicker : function(){
const tomorrow = new Date();
tomorrow.setDate(new Date().getDate()+1);
tomorrow.setHours(0,0,0,0);

const useLastCapsuleScheduleDate = window._lastCapsuleUploadSettings && window._lastCapsuleUploadSettings.capsuleLastScheduleDateInCurrentSession && tomorrow.getTime() <= window._lastCapsuleUploadSettings.capsuleLastScheduleDateInCurrentSession.getTime();
let startTimeString = "00:00";
if(useLastCapsuleScheduleDate){
window._lastCapsuleUploadSettings.capsuleLastScheduleDateInCurrentSession;
const hours = window._lastCapsuleUploadSettings.capsuleLastScheduleDateInCurrentSession.getHours();
const minutes = window._lastCapsuleUploadSettings.capsuleLastScheduleDateInCurrentSession.getMinutes();
startTimeString = ""+((hours< 10)?"0"+hours:hours)+":"+ ((minutes< 10)?"0"+minutes:minutes);
}

this.schedulingTimeFld$el.pickatime({
default: startTimeString,
interval: 5,
container: this.$el,
containerHidden: this.$el,
twelvehour: false, // Use AM/PM or 24-hour format
donetext: window.getI18n(ti18n,"OK"), // text for done-button
cleartext: window.getI18n(ti18n,"CLEAR"), // text for clear-button
canceltext: window.getI18n(ti18n,"CANCEL"), // Text for cancel-button,
//container: 'body', // ex. 'body' will append picker to body
autoclose: true, // automatic close timepicker
ampmclickable: true, // make AM PM clickable
onClose: function() {
    $(document.activeElement).blur();
},


});
this.schedulingTimeFld$el.val(startTimeString);
},

isShowNotesButtonToggleOn : function(){
return this.$el.find(".switch.show_notes_switch  input").prop('checked');
},

onShowNotesToggleClicked : function(){
if(this.isShowNotesButtonToggleOn()){
this.noteSection$el.css("display","flex");
this.$el.find(".flexContentCenteringBox").hide();
}else{
this.noteSection$el.hide();
this.$el.find(".flexContentCenteringBox").css("display","flex");
}

},
_initShowNotesSection : function(){
this.$el.find(".switch.show_notes_switch  input").on("click",this.onShowNotesToggleClicked.bind(this));
this.onShowNotesToggleClicked();
},

_initDeleteButtonIfApplicable: function(){
const iconToChange$el = this.$el.find("#deleteButton > t");
const deleteBtn$el = this.$el.find("#deleteButton");
if(DELETE_PREVIOUS_RECORDING_WITHOUT_CONFIRMATION){
iconToChange$el.addClass(READY_TO_DELETED_ICON);
iconToChange$el.removeClass(TO_DELETE_ICON);
deleteBtn$el.attr("data-hint",HINT_READY_TO_DELETE_ICON_MESSAGE);

}else{

iconToChange$el.addClass(TO_DELETE_ICON);
iconToChange$el.removeClass(READY_TO_DELETED_ICON);
deleteBtn$el.attr("data-hint",HINT_DELETE_ICON_MESSAGE);

}
if(this._completedRecordedSnippetArray.length > 0){

deleteBtn$el.removeClass("trebble_disabled");

}else{
if(!deleteBtn$el.hasClass("trebble_disabled")){
deleteBtn$el.addClass("trebble_disabled");
}
}

},

_getRecordingCircleHtml : function(){
return '<span class="red_recording_circle"></span>';
},

afterRendering: function() {
this.noteSection$el = this.$el.find("#noteSection");
this.timeLeftToRecord$el = this.$el.find("#time_left_to_record");
this.totalRecordingTime$el = this.$el.find("#total_recording_time");

this._initializeCarousels();
this._addArrowToSelectWidget();
this._initCommentBox();
this._initSchedulingDatePicker();
this._initSchedulingTimePicker();
this._initpublishingSettingField();
this._initShowNotesSection();
this._initDeleteButtonIfApplicable();


this.progressbarContainer$el = this.recordProgressContainer$el.first();
const progressbarContainer = this.progressbarContainer$el[0];
this.deleteProgressBar = new ProgressBar.Circle(progressbarContainer, {
color: TO_BE_DELETED_RECORDING_COLOR,
trailColor: DEFAULT_TRAIL_COLOR,
strokeWidth: 1,
trailWidth: 1,
duration: this._recordMaxSize
});
this.recordedProgressBar = new ProgressBar.Circle(progressbarContainer, {
color: '#FCB03C',
trailColor: 'transparent',
strokeWidth: 1,
trailWidth: 1,
duration: this._recordMaxSize,
step: (function(state, circle) {
    const timeLeftInMilliseconds = this._recordMaxSize - (circle.value() * this._recordMaxSize)
    let timeLeftFormattedForDisplay = "0:00";
let timeElapsed = "0:00";
if(timeLeftInMilliseconds > 0){
    let m1 = moment.duration(timeLeftInMilliseconds);
    timeLeftFormattedForDisplay = m1.minutes() + ":" +(m1.seconds()<10? "0"+m1.seconds() : m1.seconds());
    if(SHOW_DECIMAL_IN_RECORDING_TIME){
        let millisec = m1.milliseconds();
        //timeLeftFormattedForDisplay = timeLeftFormattedForDisplay +"."+ ((millisec <100)?  "0"+Math.floor(millisec/10) : Math.floor(millisec/10));
        timeLeftFormattedForDisplay = timeLeftFormattedForDisplay +"."+ Math.floor(millisec/100);
    }
}
if(circle.value() > 0){
    let m2 = moment.duration(circle.value() * this._recordMaxSize);
    timeElapsed = m2.minutes() + ":" +(m2.seconds()<10? "0"+m2.seconds() : m2.seconds());
    if(SHOW_DECIMAL_IN_RECORDING_TIME){
        let millisec = m2.milliseconds();
        //timeElapsed = timeElapsed +"."+ ((millisec <100)?  "0"+Math.floor(millisec/10) : Math.floor(millisec/10));
        timeElapsed = timeElapsed +"."+ Math.floor(millisec/100);
    }
}
this.timeLeftToRecord$el.html(timeLeftFormattedForDisplay);
this.totalRecordingTime$el.html(timeElapsed);
//circle.setText((circle.value() * this._recordMaxSize / 1000).toFixed(1) + "s");
//circle.setText(timeElapsed);
this.$el.find("#recordProgressContainer .progressbar-text .timer-progress").html(timeElapsed);
}).bind(this)

});
//this.recordButton$el = this.$el.find("#recordButton > t");
this.recordButtonWrapper$el = this.$el.find("#recordButton");
this.stopRecordingButton$el  = this.$el.find("#stopRecordingButton");
this.headerBackButton$el  = this.$el.find("#header-back-button");
this.showNotesToggleBox$el  = this.$el.find("#showNotesToggleBox");

if(!this._audioRecordingSupported){
this._showUploadSection();
this.showRecordSectionButton$el.hide();
}
if(this._saveAsDraft){
this._setSaveAsDraft();
}

/*const hammertimeRecordButton = new Hammer(this.recordButton$el[0], {"threshold": 20});
hammertimeRecordButton.on('press', (function(ev) {
this._startRecording();
}).bind(this));
hammertimeRecordButton.on('pressup', (function(ev) {
this._stopRecording();
}).bind(this));
this.recordButton$el.on("touchend", (function(ev) {
this._stopRecording();
}).bind(this));*/
//this.recordButtonWrapper$el.click(this._onRecordRecordingButtonClicked.bind(this));
//this.stopRecordingButton$el.click(this._onPauseRecordingButtonClicked.bind(this));
//this.headerBackButton$el.click(this.navigateToPreviousPage.bind(this));
/* this.recordButton$el.on("mouseup", (function(ev) {
this._stopRecording();
}).bind(this));*/
//this.$el.find("#deleteButton").click(this._onDeleteBtnClicked.bind(this));
//this.$el.find("#showUploadSectionButton").click(this._showUploadSection.bind(this));
//this.$el.find("[id=showRecordSectionButton]").click(this._showRecordSection.bind(this));
if(SHOW_PLAY_BUTTON_INSIDE_CIRCULAR_TIMER){
this.$el.find("#recordControlsWrapper #playButton").insertAfter(this.$el.find(".progressbar-text"))/*.click(this._playStopPlayingRecording.bind(this))*/.hide();
this.$el.find("#deleteButton").hide();
}
//this.$el.find("#recordProgressContainer svg:last-child").append(this.$el.find(".progressbar-text"));

//this.$el.find("#saveButtonIcon").click(this._saveRecording.bind(this));
//this.$el.find("#nextButton").click(this.onNextButtonClicked.bind(this));
//this.$el.find("#previousButton").click(this.onPreviousButtonClicked.bind(this));
this._switchToDeletionMode(false);
this._enableNextButtonIfApplicable();
this.$el.find("#comment").val("cc");
this.$el.find("#comment").val("");
this.$el.on("pageAppendedToBody", (function(){
if(CONFIGURE_BACKGROUND_MUSIC){
this._setupBackgroundMusicSelector();
}else{
if(USE_DEFAULT_MUSIC_SELECTOR){
    this._setupBackgroundTrackSelector();
}
}
}).bind(this));

if(CSS_PAGE_BACKGROUND){
this.$el.css("background", CSS_PAGE_BACKGROUND);
}

//this.$el.find("#playerControlsWrapper").click(this._onUserTapOnPoupContent.bind(this));
//this.$el.find("#recordProgressContainer").click(this._onUserTapOnPoupContent.bind(this));

//this.$el.find("#comment").mention(params);
//this.$el.find(".commentForm").on("submit", this._onFormSubmit.bind(this));
//this.$el.find("#commentBtn").click(this._onCommentButtonClicked.bind(this));
},



setCreationMode : function(creationMode){
if(creationMode == CREATION_MODE.FROM_RECORDING){
return this._showRecordSection();
}
if(creationMode == CREATION_MODE.FROM_TEXT){
return this._showTextToSpeechSection();
}
if(creationMode == CREATION_MODE.FROM_FILE){
return this._showUploadSection();
}
if(creationMode == CREATION_MODE.FROM_VOICE_ACTOR){
return this._showUseVoiceActorSection();
}
if(creationMode == CREATION_MODE.FROM_TRANSCRIPT){
return this._showUseVoiceActorSection();
}
},

_showUploadSection : function(){
this.showUploadSectionButton$el.hide();
if(!DO_NOT_SHOW_HEADER_BUTTONS_TO_SWITCH_MODES && !DO_NOT_SHOW_RECORD_AUDIO_IN_PAGE_HEADER){
this.showRecordSectionButton$el.show();
this.$el.find(".orrecordsection").hide();
}else{
this.showRecordSectionButton$el.hide();
}
if(!DO_NOT_SHOW_HEADER_BUTTONS_TO_SWITCH_MODES && IS_CAPSULE_CREATION_VIA_TEXT_AVAILABLE){
this.showTextToSpeechSectionButton$el.show();
}else{
this.showTextToSpeechSectionButton$el.hide();
}
if(!DO_NOT_SHOW_HEADER_BUTTONS_TO_SWITCH_MODES && IS_CAPSULE_CREATION_VIA_VOICE_ACTOR){
this.showVoiceActorNarrationSectionButton$el.show();
}else{
this.showVoiceActorNarrationSectionButton$el.hide();
}
this.recordingBlock$el.hide();
this.showRecordingSettingsButton$el.hide();
this.textToSpeechBlock$el.hide();
this.useVoiceActorBlock$el.hide();
this._onVoiceActorNarrationUnselected();
this.uploadBlock$el.attr('style', 'display: -webkit-flex; display: flex;display: -moz-flex; display: -ms-flex');
this.trigger("setFileUploadMode");
this._setCreationMode(CREATION_MODE.FROM_FILE);
this._onWindowSizeChanged();
this._enableNextButtonIfApplicable();

},

_setCreationMode: function(creationMode){
this._creationMode = creationMode;
this.$el.attr("creationMode", this._creationMode);
},


_showRecordSection : function(){
this.showRecordSectionButton$el.hide();
if(!DO_NOT_SHOW_HEADER_BUTTONS_TO_SWITCH_MODES && !DO_NOT_SHOW_UPLOAD_AUDIO_FILE_IN_PAGE_HEADER){
this.showUploadSectionButton$el.show();
}else{
this.showUploadSectionButton$el.hide();
}    
if(!DO_NOT_SHOW_HEADER_BUTTONS_TO_SWITCH_MODES && IS_CAPSULE_CREATION_VIA_TEXT_AVAILABLE){
this.showTextToSpeechSectionButton$el.show();
}else{
this.showTextToSpeechSectionButton$el.hide();
}
if(!DO_NOT_SHOW_HEADER_BUTTONS_TO_SWITCH_MODES && IS_CAPSULE_CREATION_VIA_VOICE_ACTOR){
this.showVoiceActorNarrationSectionButton$el.show();
}else{
this.showVoiceActorNarrationSectionButton$el.hide();
}
this.uploadBlock$el.hide();
this.textToSpeechBlock$el.hide();
this.useVoiceActorBlock$el.hide();
this.recordingBlock$el.show();
if(window.waitForCordovaToLoad){
this.showRecordingSettingsButton$el.hide();
}else{
this.showRecordingSettingsButton$el.css("display","inline-flex");
}
this._onVoiceActorNarrationUnselected();
this.trigger("setRecordMode");
this._setCreationMode(CREATION_MODE.FROM_RECORDING);
this._onWindowSizeChanged();
this._enableNextButtonIfApplicable();

},

_showTextToSpeechSection : function(){
if(IS_CAPSULE_CREATION_VIA_TEXT_AVAILABLE){
this.showTextToSpeechSectionButton$el.hide();
}
if(!DO_NOT_SHOW_HEADER_BUTTONS_TO_SWITCH_MODES && !DO_NOT_SHOW_RECORD_AUDIO_IN_PAGE_HEADER){
this.showRecordSectionButton$el.show();
this.$el.find(".orrecordsection").hide();
}
if(!DO_NOT_SHOW_HEADER_BUTTONS_TO_SWITCH_MODES && !DO_NOT_SHOW_HEADER_BUTTONS_TO_SWITCH_MODES ){
this.showUploadSectionButton$el.show();
}else{
this.showUploadSectionButton$el.hide();
}
if(!DO_NOT_SHOW_HEADER_BUTTONS_TO_SWITCH_MODES && IS_CAPSULE_CREATION_VIA_VOICE_ACTOR){
this.showVoiceActorNarrationSectionButton$el.show();
}else{
this.showVoiceActorNarrationSectionButton$el.hide();
}
this.uploadBlock$el.hide();
this.showRecordingSettingsButton$el.hide();
this.recordingBlock$el.hide();
this.useVoiceActorBlock$el.hide();
this.textToSpeechBlock$el.show();
this._onVoiceActorNarrationUnselected();
this.trigger("setFromTextMode");
this._setCreationMode(CREATION_MODE.FROM_TEXT);
this._onWindowSizeChanged();
this._enableNextButtonIfApplicable();

},

_showUseVoiceActorSection : function(){
if(IS_CAPSULE_CREATION_VIA_VOICE_ACTOR){
this.showVoiceActorNarrationSectionButton$el.hide();
}
if(!DO_NOT_SHOW_HEADER_BUTTONS_TO_SWITCH_MODES && !DO_NOT_SHOW_RECORD_AUDIO_IN_PAGE_HEADER){
this.showRecordSectionButton$el.show();
this.$el.find(".orrecordsection").hide();
}
if(!DO_NOT_SHOW_HEADER_BUTTONS_TO_SWITCH_MODES && !DO_NOT_SHOW_HEADER_BUTTONS_TO_SWITCH_MODES ){
this.showUploadSectionButton$el.show();
}else{
this.showUploadSectionButton$el.hide();
}
if(!DO_NOT_SHOW_HEADER_BUTTONS_TO_SWITCH_MODES && IS_CAPSULE_CREATION_VIA_TEXT_AVAILABLE){
this.showTextToSpeechSectionButton$el.show();
}else{
this.showTextToSpeechSectionButton$el.hide();
}

this.uploadBlock$el.hide();
this.recordingBlock$el.hide();
this.showRecordingSettingsButton$el.hide();
this.textToSpeechBlock$el.hide();
this.useVoiceActorBlock$el.show();
this._onVoiceActorNarrationSelected();
this.onTextToNarrateFieldChange();
this.trigger("setFromVoiceActor");
this._setCreationMode(CREATION_MODE.FROM_VOICE_ACTOR);
this._onWindowSizeChanged();
this._enableNextButtonIfApplicable();

},

setInCreationFromTranscriptMode : function(){
this._showCreatedFromTranscriptionSection();
this.onNextButtonClicked(true)
},

_showCreatedFromTranscriptionSection : function(){
this.showUploadSectionButton$el.hide();
if(!DO_NOT_SHOW_HEADER_BUTTONS_TO_SWITCH_MODES && !DO_NOT_SHOW_RECORD_AUDIO_IN_PAGE_HEADER){
this.showRecordSectionButton$el.show();
this.$el.find(".orrecordsection").hide();
}else{
this.showRecordSectionButton$el.hide();
}
if(!DO_NOT_SHOW_HEADER_BUTTONS_TO_SWITCH_MODES && IS_CAPSULE_CREATION_VIA_TEXT_AVAILABLE){
this.showTextToSpeechSectionButton$el.show();
}else{
this.showTextToSpeechSectionButton$el.hide();
}
if(!DO_NOT_SHOW_HEADER_BUTTONS_TO_SWITCH_MODES && IS_CAPSULE_CREATION_VIA_VOICE_ACTOR){
this.showVoiceActorNarrationSectionButton$el.show();
}else{
this.showVoiceActorNarrationSectionButton$el.hide();
}
this.recordingBlock$el.hide();
this.showRecordingSettingsButton$el.hide();
this.textToSpeechBlock$el.hide();
this.useVoiceActorBlock$el.hide();
this._setCreationMode(CREATION_MODE.FROM_TRANSCRIPT);
this._onWindowSizeChanged();
this._enableNextButtonIfApplicable();
},



getLifeSpan: function() {
return this.$el.find("#capsuleLifeSpanFld option:selected").val();
},

getCategoryId: function() {
return this.$el.find("#categoryFld option:selected").val();
},

getTimezone: function() {
return this.$el.find("#schedulingTimezoneFld option:selected").val();
},

getPublishedSetting : function(){
return this.$el.find("#publishingSettingFld option:selected").val();
},

getScheduleStartDate : function(){
if(this._capsuleSchedulingAvailable && this.isScheduled()){
const dateString = this.schedulingSettingFld$el.val(); 
const dateStringSubmitFormat =  this.$el.find("input[name=schedulingSettingFld_submit]").val();
const timeString = this.schedulingTimeFld$el.val();
//return dateString ? new Date(dateString): dateString;
//return dateString ? momentTimezone(dateString + " "+ timeString,"D MMM, YYYY HH:mm", this.getTimezone()).toDate(): dateString;
return dateStringSubmitFormat ? momentTimezone(dateStringSubmitFormat + " "+ timeString,"YYYY/MM/DD HH:mm", this.getTimezone()).toDate(): dateStringSubmitFormat;
}else{
return null;
}
},

getCategoryLabel: function() {
return this.$el.find("#categoryFld option:selected").text();
},

getTimezoneLabel: function() {
return this.$el.find("#schedulingTimezoneFld option:selected").text();
},

getComment: function() {
return this.$el.find("#comment").val();
},

getTitle : function(){
return this.$el.find("#titleField").val();

},

getDoNotAddOnAir : function(){
return this.doNotAddOnAirCheckbox$el.get(0).checked;
},

onDoNotAddOnAirCheckboxChange : function(){
if(this.getDoNotAddOnAir()){
if(!this.capsuleConfigWrapper$el.hasClass("isHidden")){
    this.capsuleConfigWrapper$el.addClass("isHidden");
}
if(!this.schedulingTimezoneWrapper$el.hasClass("isHidden")){
    this.schedulingTimezoneWrapper$el.addClass("isHidden");
}
if(!this.scheduleInfoWrapper$el.hasClass("isHidden")){
    this.scheduleInfoWrapper$el.addClass("isHidden");
}
if(this._capsuleSchedulingAvailable){
    if(!this.publishingSettingWrapper$el.hasClass("isHidden")){
        this.publishingSettingWrapper$el.addClass("isHidden");
    }
    if(!this.schedulingTimeWrapper$el.hasClass("isHidden")){
        this.schedulingTimeWrapper$el.addClass("isHidden");
    }
    
}
}else{
this.capsuleConfigWrapper$el.removeClass("isHidden");
this.schedulingTimezoneWrapper$el.removeClass("isHidden");
this.scheduleInfoWrapper$el.removeClass("isHidden");
if(this._capsuleSchedulingAvailable){
    this.publishingSettingWrapper$el.removeClass("isHidden");
    this.schedulingTimeWrapper$el.removeClass("isHidden");
}
}
},


isPrivate : function(){
return this.$el.find("#privacySettingFld").val() == "private";
},

isInRecordMode :function(){
return this._creationMode == CREATION_MODE.FROM_RECORDING;
},

isInUploadMode :function(){
return this._creationMode == CREATION_MODE.FROM_FILE;
},

isInTTSMode :function(){
return this._creationMode == CREATION_MODE.FROM_TEXT;
},

isInVoiceActorMode :function(){
return this._creationMode == CREATION_MODE.FROM_VOICE_ACTOR;
},

isInCreateFromTranscriptMode : function(){
return this._creationMode == CREATION_MODE.FROM_TRANSCRIPT;
},



_enableNextButtonIfApplicable: function(forceDisable) {
this._nextButtonEnable = false;
if(forceDisable){
this._nextButtonEnable = false;
this.$el.find("#nextButton").addClass("trebble_disabled");
this.$el.find("#playButton").addClass("trebble_disabled");
this.$el.find("#deleteButton").addClass("trebble_disabled");
this.showUploadSectionButton$el.addClass("trebble_disabled");
this.showTextToSpeechSectionButton$el.addClass("trebble_disabled");
this.showVoiceActorNarrationSectionButton$el.addClass("trebble_disabled");
return;
}else{
const deleteBtn$el = this.$el.find("#deleteButton");
if(this._completedRecordedSnippetArray.length > 0){

    deleteBtn$el.removeClass("trebble_disabled");

}else{
  if(!deleteBtn$el.hasClass("trebble_disabled")){
    deleteBtn$el.addClass("trebble_disabled");
}
}
this.showUploadSectionButton$el.removeClass("trebble_disabled");
this.showTextToSpeechSectionButton$el.removeClass("trebble_disabled");
this.showVoiceActorNarrationSectionButton$el.removeClass("trebble_disabled");
if(this._canSaveOnlyAfterTranscriptionAndEditing  && this.isTranscriptionAndEditAudioScreenDisplayed() && !this.isAudioWasTranscribedAndEdited()){
 this._nextButtonEnable = false;
this.$el.find("#nextButton").addClass("trebble_disabled");
this.$el.find("#playButton").addClass("trebble_disabled");
return;
}

if(this.wasAudioContentCreated()  && !this._uploadingViewInCapsuleInfoDisplayed && !this._uploadingViewInAudioEditorDisplayed){
this._nextButtonEnable = true;
this.$el.find("#nextButton").removeClass("trebble_disabled");
this.$el.find("#playButton").removeClass("trebble_disabled");
} else {
this._nextButtonEnable = false;
this.$el.find("#nextButton").addClass("trebble_disabled");
this.$el.find("#playButton").addClass("trebble_disabled");
}

}
},

wasAudioContentCreated : function(){
return (this.isInRecordMode() && this._completedRecordedSnippetArray.length > 0) || (this.isInUploadMode() && this._uploadedFileAdded) || (this.isInTTSMode() && !this.isTextToConvertFieldEmpty()) || (this.isInVoiceActorMode () && this.isTextToNarrateFieldValid())  || (this.isAudioWasTranscribedAndEdited());
},

_isAudioRecordedForCapsule : function(){
return this._completedRecordedSnippetArray && this._completedRecordedSnippetArray.length > 0;
},
setAudioWasTranscribedAndEdited : function(audioWasTranscribedAndEdited){
this._audioWasTranscribedAndEdited = audioWasTranscribedAndEdited;
this._enableNextButtonIfApplicable();
},

isAudioWasTranscribedAndEdited : function(){
return this._audioWasTranscribedAndEdited;
},

deleteRecordedAudioConfirm : function(){
const buttonLabels = [window.getI18n(ti18n,"NO"), window.getI18n(ti18n,"YES")];
const confirmCallback = (function(buttonIndex){
if(buttonIndex ==  2){
    this.deleteRecordedAudioAndRecordAgain();
}
}).bind(this);
const message = window.getI18n(ti18n,"ARE_YOU_WANT_TO_DELETE_AND_RERECORD_AGAIN");
const subMessage = "";//window.getI18n(ti18n,"YOUR_TRANSCRIPTION_AND_EDIT_WILL_BE_LOST");
navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n,"DELETE_CAPSULE"), buttonLabels,null , null, subMessage);
} ,

navigateToPreviousPageAndLooseAudioEditChangesConfirm : function(){
const buttonLabels = [window.getI18n(ti18n,"NO"), window.getI18n(ti18n,"YES")];
const confirmCallback = (function(buttonIndex){
if(buttonIndex ==  2){
    if(this.isInCreateFromTranscriptMode()){
        this.navigateToPreviousPage();
    }else{
        this.trigger("resetTranscriptionAndAudioEdits");
        this.onPreviousButtonClicked(null, true);
    }
}
}).bind(this);
const message = window.getI18n(ti18n,"ARE_YOU_WANT_TO_NAVIGATE_TO_PREVIOUS_SCREEN");
const subMessage = window.getI18n(ti18n,"YOUR_TRANSCRIPTION_AND_EDIT_WILL_BE_LOST");
navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n,"DELETE_CAPSULE"), buttonLabels,null , null, subMessage);
} ,

confirmUserWantToDeleteExistingRecordingIfApplicable : function(){
if(!this._isAudioRecordedForCapsule()){
return RSVP.Promise.resolve(true);
}else{
return new RSVP.Promise((function(resolve, reject){
    try{
        const buttonLabels = [window.getI18n(ti18n,"NO"), window.getI18n(ti18n,"YES")];
        const confirmCallback = (function(buttonIndex){
            if(buttonIndex ==  2){
                this.deleteRecordedAudio();
                resolve(true);
            }else{
                resolve(false);
            }
        }).bind(this);
        const message = window.getI18n(ti18n,"ARE_YOU_WANT_TO_DELETE_AND_RERECORD_AGAIN");
        const subMessage = "";//window.getI18n(ti18n,"YOUR_TRANSCRIPTION_AND_EDIT_WILL_BE_LOST");
        navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n,"DELETE_CAPSULE"), buttonLabels,null , null, subMessage);
    }catch(error){
        reject(error);
    }
}).bind(this))

}
} ,


isScheduled : function(){
return this._capsuleSchedulingAvailable && PUBLISHED_SETTING_VALUES.SCHEDULED === this.getPublishedSetting();
},

_getTheRestOfTheDayCapsuleLifeSpanOption$El : function(){
const optionArray  = this.$el.find("#capsuleLifeSpanFld").children();
for(let i =0; i < optionArray.length; i++){
const option$el = $(optionArray[i]);
const value = option$el.val();
if(value && Utils.getInstance().isFloat(parseFloat(value))){
    //this is most likely the rest of the day option
    return option$el;
}
}
},

_hideShowSchedulingDateFieldIfApplicable : function(){
const theRestOfTheDayOption$el = this._getTheRestOfTheDayCapsuleLifeSpanOption$El();
const theRestOfTheDayOptionIsSelected = theRestOfTheDayOption$el.is(':selected');
if(this.isScheduled()){
theRestOfTheDayOption$el.prop("disabled","disabled");
theRestOfTheDayOption$el.removeAttr("selected");
this.$el.find("#capsuleLifeSpanFld").selectmenu('refresh');
this.schedulingSettingWrapper$el.show();
this.schedulingTimeWrapper$el.show();
this.schedulingTimezoneWrapper$el.show();
this.capsuleConfigWrapper$el.insertAfter(this.scheduleInfoWrapper$el);
}else{
theRestOfTheDayOption$el.removeAttr("disabled");
this.schedulingSettingWrapper$el.hide();
this.schedulingTimeWrapper$el.hide();
if(theRestOfTheDayOptionIsSelected){
    this.capsuleConfigWrapper$el.prependTo(this.scheduleInfoWrapper$el);
    this.schedulingTimezoneWrapper$el.show();
}else{  
    this.capsuleConfigWrapper$el.insertAfter(this.scheduleInfoWrapper$el);
    this.schedulingTimezoneWrapper$el.hide();   
}
}
},

deleteRecordedAudio : function(){
if(DELETE_PREVIOUS_RECORDING_WITHOUT_CONFIRMATION){
this._executeDeleteRecordingOperationWithoutHighlightingWhatWillBeDeleted();
}else{
this._onDeleteBtnClicked();
this._onDeleteBtnClicked();
}
},

deleteRecordedAudioAndRecordAgain : function(){
this.deleteRecordedAudio();
this._startRecording();

},

_executeDeleteRecordingOperationWithoutHighlightingWhatWillBeDeleted: function(){
//trigger delete record action on model
this.trigger("deleteRecording", this.getFileRecordingCounter());
//delete recorded file info in completed this._completedRecordedSnippetArray.pop();
this._completedRecordedSnippetArray.pop();
const numberOfSnippets = this._completedRecordedSnippetArray.length;
let lastRecordedPostion = 0;
if (numberOfSnippets > 0) {
lastRecordedPostion = this._completedRecordedSnippetArray[numberOfSnippets - 1][1];
}
this.deleteProgressBar.set(lastRecordedPostion);
this.recordedProgressBar.set(lastRecordedPostion);
const iconToChange$el = this.$el.find("#deleteButton > t");
const deleteBtn$el = this.$el.find("#deleteButton");
iconToChange$el.addClass(READY_TO_DELETED_ICON);
iconToChange$el.removeClass(TO_DELETE_ICON);
deleteBtn$el.attr("data-hint",HINT_READY_TO_DELETE_ICON_MESSAGE);

this._switchToDeletionMode(false);
this.isReadyToBedeleted = false;
},

_onDeleteBtnClicked: function() {
if (!this.deletionMode) {
this._switchToDeletionMode(true);
}
if(DELETE_PREVIOUS_RECORDING_WITHOUT_CONFIRMATION){

const buttonLabels = [window.getI18n(ti18n,"NO"), window.getI18n(ti18n,"YES")];
const confirmCallback = (function(buttonIndex){
    if(buttonIndex ==  2){
        this._executeDeleteRecordingOperationWithoutHighlightingWhatWillBeDeleted();
    }
}).bind(this);
const message = window.getI18n(ti18n,"ARE_YOU_WANT_TO_DELETE_LAST_AUDIO_SEGMENT");
navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n,"ARE_YOU_WANT_TO_DELETE_LAST_AUDIO_SEGMENT"), buttonLabels,null , null);

}else{
if (!this.isReadyToBedeleted) {
    this.deleteProgressBar.set(this.recordedProgressBar.value());
    this.deleteProgressBar.svg.display = "block";
    this.isReadyToBedeleted = true;
    const iconToChange$el = this.$el.find("#deleteButton > t");
    const deleteBtn$el = this.$el.find("#deleteButton");
    iconToChange$el.addClass(READY_TO_DELETED_ICON);
    iconToChange$el.removeClass(TO_DELETE_ICON);
    deleteBtn$el.attr("data-hint",HINT_READY_TO_DELETE_ICON_MESSAGE);
    let numberOfSnippets = this._completedRecordedSnippetArray.length;
    let previousRecordedPostion = 0;
    if (numberOfSnippets - 2 >= 0) {
        previousRecordedPostion = this._completedRecordedSnippetArray[numberOfSnippets - 2][1];
    }
    this.recordedProgressBar.set(previousRecordedPostion);
} else {
    //trigger delete record action on model
    this.trigger("deleteRecording", this.getFileRecordingCounter());
    //delete recorded file info in completed this._completedRecordedSnippetArray.pop();
    this._completedRecordedSnippetArray.pop();
    let numberOfSnippets = this._completedRecordedSnippetArray.length;
    let lastRecordedPostion = 0;
    if (numberOfSnippets > 0) {
        lastRecordedPostion = this._completedRecordedSnippetArray[numberOfSnippets - 1][1];
    }
    this.deleteProgressBar.set(lastRecordedPostion);
    this.recordedProgressBar.set(lastRecordedPostion);
    const iconToChange$el = this.$el.find("#deleteButton > t");
    const deleteBtn$el = this.$el.find("#deleteButton");
    iconToChange$el.addClass(TO_DELETE_ICON);
    iconToChange$el.removeClass(READY_TO_DELETED_ICON);
    deleteBtn$el.attr("data-hint",HINT_DELETE_ICON_MESSAGE);
    this._switchToDeletionMode(false);
    this.isReadyToBedeleted = false;
}
}
this._enableNextButtonIfApplicable();
},


_onPageShow: function() {
if(this._backgroundTrackSwiperObj){
this._backgroundTrackSwiperObj.update();
}
this._hideTrackContainer();
if(!this._isRecording && this._hasContentRecorded()){
if(this._canBackgroundMusicUsed()){
//disable background track on mobile for now
this._showTrackContainer();
}
}
},


_canBackgroundMusicUsed : function(){
return (window.waitForCordovaToLoad && ALLOW_CHOOSING_MUSIC_ON_MOBILE_APP) || (!window.isMobileBrowser || window.trebble.allowCreatingCapsuleWithBackgroundMusic)
},

_onPageChange : function(){
this.$el.find("#comment").trigger("autoresize");
},

_cancelSnippetDeletion: function() {
const wasInDeletionMode = this.deletionMode;
this._switchToDeletionMode(false);
if (wasInDeletionMode) {
const numberOfSnippets = this._completedRecordedSnippetArray.length;
let lastRecordedPostion = 0;
if (numberOfSnippets > 0) {
    lastRecordedPostion = this._completedRecordedSnippetArray[numberOfSnippets - 1][1];
}
this.deleteProgressBar.set(lastRecordedPostion);
this.recordedProgressBar.set(lastRecordedPostion);
}
const iconToChange$el = this.$el.find("#deleteButton > t");
const deleteBtn$el = this.$el.find("#deleteButton");
if(DELETE_PREVIOUS_RECORDING_WITHOUT_CONFIRMATION){

iconToChange$el.addClass(READY_TO_DELETED_ICON);
iconToChange$el.removeClass(TO_DELETE_ICON);
deleteBtn$el.attr("data-hint",HINT_READY_TO_DELETE_ICON_MESSAGE);
}else{
iconToChange$el.addClass(TO_DELETE_ICON);
iconToChange$el.removeClass(READY_TO_DELETED_ICON);
deleteBtn$el.attr("data-hint",HINT_DELETE_ICON_MESSAGE);
}
if(this._completedRecordedSnippetArray.length > 0){

deleteBtn$el.removeClass("trebble_disabled");

}else{
if(!deleteBtn$el.hasClass("trebble_disabled")){
deleteBtn$el.addClass("trebble_disabled");
}
}
},

_switchToDeletionMode: function(on) {
if (on) {
this.deletionMode = true;
this.deleteProgressBar.svg.display = "block";
} else {
this.deletionMode = false;
this.deleteProgressBar.svg.display = "none";
this.isReadyToBedeleted = false;
}
},

onNextButtonClicked: function(force) {
if(this.isTranscriptionAndEditAudioScreenDisplayed() && this.isAudioWasTranscribedAndEdited()){
this.trigger("navigatingToNextPageAFterAudioEditorPage");
}
if(this.isRecordingScreenDisplayed() && this._canSaveOnlyAfterTranscriptionAndEditing){
this.showTranscriptionParameters();
}
if(this._currentCarouselPositon +1 < this._numberOfCarouselItems){
//if(this._recordMode){
if (force || (this._nextButtonEnable && this.carousel$el)) {
const carouselData = this.carousel$el.data('owlCarousel');
if(this.isInTTSMode()){
    this._currentCarouselPositon = this._currentCarouselPositon +2;
    carouselData.goTo(this._currentCarouselPositon)
}else{
    this._currentCarouselPositon  = this._currentCarouselPositon +1;

    carouselData.next();
}
this._updateHeaderButtons();

}
this.$el.find("#comment").trigger("autoresize");
this._onCapsuleInfoEdited();
/*}else{
if (this._nextButtonEnable && this.carousel$el) {
    this._currentCarouselPositon  = this._currentCarouselPositon +2;
    const carouselData = this.carousel$el.data('owlCarousel');
    carouselData.goTo(this._currentCarouselPositon);
    this._updateHeaderButtons();

}
}*/
}
},

_onCapsuleInfoEdited : function(){
const titleValid  = this.getTitle() && this.getTitle().trim();
const publishingSettingValid = !(!this.getDoNotAddOnAir() && this.isScheduled() && !this.getScheduleStartDate());
if(titleValid && publishingSettingValid){
this.saveButton$el.removeClass("trebble_disabled");
}else{
if(!this.saveButton$el.hasClass("trebble_disabled")){
    this.saveButton$el.addClass("trebble_disabled");
}
}
},

getTimeLeftForTheRestOfTheDayBasedOnTimezone :function(){
const trebbleDefaultTimezone  = this.getTimezone();
const nextMidnightDate = momentTimezone(trebbleDefaultTimezone).set("hour",0).set("minute",0).set("second",0).add(1, "days").toDate();
const aDayInMilliseconds = 24 *60 *60 * 1000;
const timeInDaysUntilTheEndOfTheDay = (nextMidnightDate.getTime() - (new Date()).getTime())/aDayInMilliseconds; 
return timeInDaysUntilTheEndOfTheDay;
},


_onTimezoneEdited : function(){
const timezone = this.getTimezone();
const theRestOfTheDayOption$el = this._getTheRestOfTheDayCapsuleLifeSpanOption$El();
theRestOfTheDayOption$el.val(this.getTimeLeftForTheRestOfTheDayBasedOnTimezone());

},


_updateHeaderButtons : function(){
if(this._currentCarouselPositon +1 == this._numberOfCarouselItems){
this.$el.find("#nextButton").hide();
this.showUploadSectionButton$el.hide();
this.showRecordSectionButton$el.hide();
this.showTextToSpeechSectionButton$el.hide();
this.showVoiceActorNarrationSectionButton$el.hide();
this.$el.find("#saveButtonIcon").show();
this.showRecordingSettingsButton$el.hide();
}else{
if(this._currentCarouselPositon != 0 || window.waitForCordovaToLoad || !this.isInRecordMode()){
this.showRecordingSettingsButton$el.hide();
}else{
this.showRecordingSettingsButton$el.css("display","inline-flex");
}
this.$el.find("#nextButton").show();
if(this.isInRecordMode()){
this.showRecordSectionButton$el.hide();
if(!DO_NOT_SHOW_HEADER_BUTTONS_TO_SWITCH_MODES && !DO_NOT_SHOW_UPLOAD_AUDIO_FILE_IN_PAGE_HEADER){
    this.showUploadSectionButton$el.show();
}
if(!DO_NOT_SHOW_HEADER_BUTTONS_TO_SWITCH_MODES && IS_CAPSULE_CREATION_VIA_TEXT_AVAILABLE){
    this.showTextToSpeechSectionButton$el.show();
}
if(!DO_NOT_SHOW_HEADER_BUTTONS_TO_SWITCH_MODES && IS_CAPSULE_CREATION_VIA_VOICE_ACTOR){
    this.showVoiceActorNarrationSectionButton$el.show();
}
}
if(this.isTranscriptionAndEditAudioScreenDisplayed()){
    setTimeout(()=>{
        this.showSaveOrEditOnboardingInstruction();
    }, 500)
}
if(this.isInUploadMode()){
this.showUploadSectionButton$el.hide();
this.$el.find(".orrecordsection").hide();
if(!DO_NOT_SHOW_HEADER_BUTTONS_TO_SWITCH_MODES && !DO_NOT_SHOW_RECORD_AUDIO_IN_PAGE_HEADER){
    this.showRecordSectionButton$el.show();

}
if(!DO_NOT_SHOW_HEADER_BUTTONS_TO_SWITCH_MODES && IS_CAPSULE_CREATION_VIA_TEXT_AVAILABLE){
    this.showTextToSpeechSectionButton$el.show();
}
if(!DO_NOT_SHOW_HEADER_BUTTONS_TO_SWITCH_MODES && IS_CAPSULE_CREATION_VIA_VOICE_ACTOR){
    this.showVoiceActorNarrationSectionButton$el.show();
}
}
if(this.isInTTSMode()){
this.showTextToSpeechSectionButton$el.hide();
this.showVoiceActorNarrationSectionButton$el.hide();
this.$el.find(".orrecordsection").hide();
if(!DO_NOT_SHOW_HEADER_BUTTONS_TO_SWITCH_MODES && !DO_NOT_SHOW_RECORD_AUDIO_IN_PAGE_HEADER){
    this.showRecordSectionButton$el.show();
    
}
if(!DO_NOT_SHOW_HEADER_BUTTONS_TO_SWITCH_MODES && !DO_NOT_SHOW_UPLOAD_AUDIO_FILE_IN_PAGE_HEADER){
    this.showUploadSectionButton$el.show();
}

}
if(this.isTranscriptionAndEditAudioScreenDisplayed() && !this.isSequencerNodeWrapperDisplayed()){
this.$el.find("#nextButton").hide();
}
this.$el.find("#saveButtonIcon").hide();
//if(this.isTranscriptionAndEditAudioScreenDisplayed()){
this._enableNextButtonIfApplicable();
// }
}
if(this._currentCarouselPositon > 0){
this.$el.find("#previousButton").show();
this.hideBackButton();
}else{
this.$el.find("#previousButton").hide();
this.showBackButton();
}
},



hideBackButton : function(){
this.headerBackButton$el.hide();
},

showBackButton : function(){
this.headerBackButton$el.show();
},

setSelectedSongView : function(songSelectedView){
if(this._songSelectedView){
this._songSelectedView.$el.remove();
}
this._songSelectedView = songSelectedView;
if(this._songSelectedView){
this.$el.find("#selectedSongInfoPreview").append(this._songSelectedView.$el);
this.$el.find("#noSongSelectedInfoBox").hide();
}else{
this.$el.find("#noSongSelectedInfoBox").show();
}
},

isTranscriptionAndEditAudioScreenDisplayed : function(){
return this._currentCarouselPositon == 1 && TREBBLE_TEXT_BASED_AUDIO_EDITOR;
},

isRecordingScreenDisplayed : function(){
return this._currentCarouselPositon == 0;
},

isShortcastInfoScreenDisplayedDisplayed : function(){
return (this._currentCarouselPositon == 2 && TREBBLE_TEXT_BASED_AUDIO_EDITOR) ||(this._currentCarouselPositon == 1 && !TREBBLE_TEXT_BASED_AUDIO_EDITOR)
},


onPreviousButtonClicked: function(event, force) {
if(this._currentCarouselPositon > 0){
if(this.isTranscriptionAndEditAudioScreenDisplayed() && this.isAudioWasTranscribedAndEdited() && !force){
   return this.navigateToPreviousPageAndLooseAudioEditChangesConfirm();
}
if(this._recordMode){
this._currentCarouselPositon  = this._currentCarouselPositon -1;
if (this.carousel$el) {
    let carouselData = this.carousel$el.data('owlCarousel');
    carouselData.prev();
    this._updateHeaderButtons();
}
}else{
if(this.isInTTSMode()){
this._currentCarouselPositon = this._currentCarouselPositon - 2;
}else{
this._currentCarouselPositon  = this._currentCarouselPositon - 1;
}
if (this.carousel$el) {
let carouselData = this.carousel$el.data('owlCarousel');
carouselData.goTo(this._currentCarouselPositon);
this._updateHeaderButtons();

}
}
}
},

_initCommentBox: function() {
const users = [];
if (this._followersUsersInfo) {
for (const index in this._followersUsersInfo) {
    const userInfo = this._followersUsersInfo[index];
    users.push({"username": userInfo.username});
}
}
const params = {};
params.delimiter = "@";
params.users = users;
const comment$el = this.$el.find("#comment");
if(comment$el.mention){
//mention was initialized
comment$el.mention(params);
}
this.$el.find(".commentForm").on("submit", this._onFormSubmit.bind(this));
},

_onFormSubmit: function() {
return false;
},

_playStopPlayingRecording: function() {
if (this._isPlaying) {
this.trigger("stopPlayingRecordings");
} else {
this.trigger("playRecordings");
}
},

_saveRecording: function() {
this.trigger("saveRecording");
},

_showRecordingSettings: function() {
this.trigger("showRecordingSettings");
},


_startRecording: function() {
if(!ALLOW_USER_TO_RECORD_MULTIPLE_AUDIO_SEGMENT && this._isAudioRecordedForCapsule()){
this.deleteRecordedAudioConfirm();
}else{
this._cancelSnippetDeletion();
this._currentRecordedSnippet = [];
this._isRecording = true;
this.$el.attr("is-recording","true");
//set recorded file info this._currentRecordedFile = null;
this.trigger("startRecording", this.getFileRecordingCounter());
this.hideBackButton();
}
},

startRecordTimer : function(){
if(SHOW_PLAY_BUTTON_INSIDE_CIRCULAR_TIMER){
this.playButton$el.hide();
this.progressbarText$el.show();
}



this._hideTrackContainer();
this._currentRecordedSnippet.push(this.recordedProgressBar.value());
//trigger record action on model
this.recordedProgressBar.animate(1, (function() {
this._stopRecording();
}).bind(this));
this._enableNextButtonIfApplicable(true);
},

getFileRecordingCounter: function() {
return this._completedRecordedSnippetArray.length;
},

getFileRecordingKey: function() {
return this._recordKey;
},

_hasContentRecorded : function(){
return this._completedRecordedSnippetArray && this._completedRecordedSnippetArray.length > 0;
},
_stopRecording: function() {
if (this._isRecording) {
this.recordedProgressBar.stop();
this._isRecording = false;
this.$el.attr("is-recording","false");
//trigger stop record action on model
this.trigger("stopRecording", this.getFileRecordingCounter());
this._currentRecordedSnippet.push(this.recordedProgressBar.value());
//add recorded file info to completed this._completedRecordedSnippetArray.push(this._currentRecordedFile);
this._completedRecordedSnippetArray.push(this._currentRecordedSnippet);
this._currentRecordedSnippet = null;
this.deleteProgressBar.set(this.recordedProgressBar.value());
}
this.showBackButton();

if(SHOW_PLAY_BUTTON_INSIDE_CIRCULAR_TIMER){
this.progressbarText$el.hide();
this.playButton$el.show();
}
if(this._canBackgroundMusicUsed()){
//disable background track on mobile for now
this._showTrackContainer();
}
this._enableNextButtonIfApplicable();
},

_onWindowSizeChanged : function() {
const headerHeight = 57;
const footerHeight = 0;
const recordButtonsHeight = 50
const marginHeight = 25;
const progressTextWidth = 120;
const progressTextHeight =  50;
const creationModeSelector = 60;
const showNotesToggleSectionHeight = 50;
const peakMeterHeight = 20;
const backgroundMusicSelectionBoxHeight = BACKGROUND_MUSIC_SELECTION_BOX_HEIGHT;
const neededPadding =/* headerHeight + footerHeight + */recordButtonsHeight*2 + marginHeight*2 + backgroundMusicSelectionBoxHeight + creationModeSelector + showNotesToggleSectionHeight + peakMeterHeight;
const newHeight = PersistentModels.getInstance().getRouter().getViewportHeight() - neededPadding 
const newWidth =   window.getDocumentClienWidth() - marginHeight ;
const containerWidth = (newWidth < newHeight)?newWidth : newHeight;
const containerHeight = newHeight;
const circularProgressMarginTopHeight = 50;
const circularProgressMarginBottomHeight = 25;

this._circleProgressBarContainerWidth = containerWidth;
this._circleProgressBarContainerHeight = containerHeight;
//this.noteSection$el.css("width",this._circleProgressBarContainerWidth +"px");
this.noteSection$el.css("height", (this._circleProgressBarContainerHeight + circularProgressMarginTopHeight + circularProgressMarginBottomHeight  )  +"px");
this._circleProgressBarDiameter = this._circleProgressBarContainerWidth < this._circleProgressBarContainerHeight?this._circleProgressBarContainerWidth : this._circleProgressBarContainerHeight;
this._trackSwiperContainerWidth = ( this._circleProgressBarDiameter)/Math.sqrt(2) ;
const progressTextTopPosition  = (containerWidth < containerHeight)?(containerWidth/2):(containerHeight/2);
const progressTextLeftPosition  = (containerWidth)/2;  
this.progressbarText$el = this.$el.find(".progressbar-text");
this.uploadBlock$el/*.height(containerHeight)*/.width(containerWidth).css({"margin-top" :recordButtonsHeight +"px","margin-bottom" :marginHeight +"px"});
this.recordProgressContainer$el.height(containerHeight).width(containerWidth).css({"margin-top" :recordButtonsHeight +"px","margin-bottom" :marginHeight +"px"});
this.progressbarText$el.css({"top":progressTextTopPosition +"px","left": progressTextLeftPosition+"px","width" : progressTextWidth +"px","text-align": "center" });
const playButtonHeight =  93;
const playButtonWidth =  37;
this.$el.find("#recordProgressContainer #playButton").css({"top":(progressTextTopPosition - (playButtonHeight/2)) +"px","left": (progressTextLeftPosition  - (playButtonWidth/2))+"px","text-align": "center", "font-size": "70px","position":"absolute","margin":"0px","padding":"0px"});
this.uploadProgressInfo$el.css({"height": containerHeight +"px"});
this.audioEditorUploadProgressInfo$el.css({"height": containerHeight +"px"});
this.$el.find(".carouselItem").css({"height": (window.getDocumentClienHeight() - headerHeight - footerHeight) +"px"});
if(this.trackContainer$el && this._backgroundTrackSwiperObj){
if(!SHOW_BACKGROUND_TRACK_SELECTOR_OUTSIDE_OF_TIMER){
    this.trackContainer$el.width(this._trackSwiperContainerWidth);
    const containerLeft = (this._circleProgressBarDiameter - this._trackSwiperContainerWidth)/2;
    const containerBottom = containerLeft;
    this.trackContainer$el.css("left",containerLeft +"px");
    this.trackContainer$el.css("bottom",containerBottom +"px");
}else{
    this.trackContainer$el.css("width",containerWidth +"px");
}
this._backgroundTrackSwiperObj.update();
}

},



onAfterPageShow: function() {

this.carousel$el.data('owlCarousel').reload();
if(this._songModel){
const capsuleUIDs =  this._songModel.get("capsuleUIDs");
if(capsuleUIDs && capsuleUIDs.length > 0){
const buttonLabels = [window.getI18n(ti18n,"CANCEL"), window.getI18n(ti18n,"OK")];
const confirmCallback = (function(buttonIndex){
if(buttonIndex ==  2){
this.close();
}
}).bind(this);
navigator.trebbleNotification.confirm(window.getI18n(ti18n,"YOU_HAVE_ALREADY_CREATED_A_CAPSULE_FOR_THIS_SONG"), confirmCallback, window.getI18n(ti18n,"CAPSULE_ALREADY_EXISTS"), buttonLabels)
}
}
},
/*
onBeforeRemoveFromPage : function(){
this.trigger("beforeRemoveFromPage", this);
},
*/

/*onRemovedFromPage : function(){
this.undelegateEvents();
this.$el.removeData().unbind(); 
this._pageHasBeenRemoved = true;
this.trigger("removedFromPage", this);
},*/
/*
removePage : function(){
this._onPageHide(null, true);
},*/

_onPageHide : function(event, force){
//this.trigger("deleteAllRecordings");

if(!this._pageHasBeenRemoved && (force || this._canPageBeDeletedIfHidden() || PersistentModels.getInstance().getRouter().isBrowserHistoryGoingBack()))
{
//this.trigger("pageHide")
PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._pageId);
/*this.onBeforeRemoveFromPage();
this.remove();
this.onRemovedFromPage();*/
}
if(this._recordOnboardingTour){
    this._recordOnboardingTour.cancel();
    this._recordOnboardingTour = null;
}
if(this._saveOrEditOnboardingTour){
    this._saveOrEditOnboardingTour.cancel();
    this._saveOrEditOnboardingTour = null;
}
},

showWhatsACapsuleButton : function(){
this.$el.find("#whatsacapsuleButton").show();
},

hideWhatsACapsuleButton : function(){
this.$el.find("#whatsacapsuleButton").hide();
},

onWhatsACapsuleButtonClicked : function(){  
this.trigger("onWhatsACapsuleButton");
},

showHideUploadingViewInCapsuleInfo : function(show){
this._uploadingViewInCapsuleInfoDisplayed = show;
if(show){
this.previousButtn$el.hide();
this.capsuleInfo$el.hide();
this.uploadProgressInfo$el.css("display","flex");
if(!this.$el.find("#saveButtonIcon").hasClass("trebble_disabled")){
    this.$el.find("#saveButtonIcon").addClass("trebble_disabled");
}
 if(!this.$el.find("nextButton").hasClass("trebble_disabled")){
    this.$el.find("#nextButton").addClass("trebble_disabled");
}
}else{
this.previousButtn$el.show();
this.uploadProgressInfo$el.hide();
this.capsuleInfo$el.show();
this.$el.find("#saveButtonIcon").removeClass("trebble_disabled");
this.$el.find("#nextButton").removeClass("trebble_disabled");
}
},

showHideUploadingViewInAudioEditor : function(show){
this._uploadingViewInAudioEditorDisplayed = show;
if(show){
this.previousButtn$el.hide();
this.audioEditorAndTranscriptionBox$el.hide();
this.audioEditorUploadProgressInfo$el.css("display","flex");
if(!this.$el.find("#saveButtonIcon").hasClass("trebble_disabled")){
    this.$el.find("#saveButtonIcon").addClass("trebble_disabled");
}
if(!this.$el.find("#nextButton").hasClass("trebble_disabled")){
    this.$el.find("#nextButton").addClass("trebble_disabled");
}
}else{
this.previousButtn$el.show();
this.audioEditorUploadProgressInfo$el.hide();
this.audioEditorAndTranscriptionBox$el.show();
this.$el.find("#saveButtonIcon").removeClass("trebble_disabled");
this.$el.find("#nextButton").removeClass("trebble_disabled");
}
},

showHideMessageToStartTranscriptionBox : function(show){
if(show){
this.messageToStartTranscriptionBox$el.show();
this.showSaveOrEditOnboardingInstruction();
}else{
this.messageToStartTranscriptionBox$el.hide();
}
},

isSequencerNodeWrapperDisplayed : function(){
return this._sequencerNodeWrapperIsDisplayed;
},

showHideSequncerNodeWrapper : function(show){
this._sequencerNodeWrapperIsDisplayed = show;
if(show){
this.sequncerNodeWrapper$el.show();
}else{
this.sequncerNodeWrapper$el.hide();
}
this._updateHeaderButtons();
},

_buildCarouselTrackItem$el : function(trackkUri , trackLabel, artistName, trackDownloadUrl){
if(trackDownloadUrl){
return this._buildTrackCarouselWrapper().append("<div class='trackItem' data-track-uri='"+ trackkUri+"'><div><span class='trackName'>"+trackLabel +"</span><span class='artistName'>"+artistName+"</span></div><span class='downloadInfo'>"+ window.getI18n(ti18n,"DOWNLOAD_INSTRUMENTAL") +" "+"<a href='"+trackDownloadUrl+"' target='_blank'>here</a></span></div>");
}else{
return this._buildTrackCarouselWrapper().append("<div class='trackItem' data-track-uri='"+ trackkUri+"'><div><span class='trackName'>"+trackLabel +"</span><span class='artistName'>"+artistName+"</span></div></div>");
}
},

_buildTrackItem$el : function(trackkUri , trackLabel){
return $("<div class='trackItem' data-track-uri='"+ trackkUri+"'>"+trackLabel +"</div>");
},



_hideTrackContainer : function(){
if(this.trackSlectorBlockSpacer$el && this.trackContainer$el){
if(SHOW_BACKGROUND_TRACK_SELECTOR_OUTSIDE_OF_TIMER){
    this.trackContainer$el.hide();
    if(this._recordingInstructionsDisplayed){
        this.trackSlectorBlockSpacer$el.hide();
    }else{
       this.trackSlectorBlockSpacer$el.css("display","flex");
   }
}else{
this.trackContainer$el.hide();
this.trackSlectorBlockSpacer$el.hide();
}
}
},  

_showTrackContainer : function(){
if(this.trackSlectorBlockSpacer$el && this.trackContainer$el){
if(SHOW_BACKGROUND_TRACK_SELECTOR_OUTSIDE_OF_TIMER){
    this.trackContainer$el.css("display","flex");
    this.trackSlectorBlockSpacer$el.hide();
}else{
    this.trackContainer$el.show();
    this.trackSlectorBlockSpacer$el.hide();
}
}
},  
_onSelectionChanged : function(trebbleAudioModel){

},

_onDeleteSelectedBackgroundMusic : function(){
this._setBackgroundMusicTrebbleAudioModel(null);
},

_setBackgroundMusicTrebbleAudioModel : function(selectedBackgroundMusicTrebbleAudioModel){
this._selectedBackgroundMusicModel = selectedBackgroundMusicTrebbleAudioModel;
if(this._selectedBackgroundMusicModel){
const params = {"doNotShowArtistName": true,"doNotShowCoverArt": true};
params.model = this._selectedBackgroundMusicModel;
const backgroundMusicContextController = new SongContextController(params);
backgroundMusicContextController.buildModelAndRenderView().then((function(){
this.backgroundMusicSelectorView.setSelectedSongView(backgroundMusicContextController.getView())
this.trigger("backgroundTrackChange",this._selectedBackgroundMusicModel.getAudioUrl());
}).bind(this));
}else{
this.backgroundMusicSelectorView.setSelectedSongView(null);
this.trigger("backgroundTrackChange",null);
}
},

_showBackgroundMusicPicker : function(){
const onBackgroundMusicSelected = (function(selectedBackgroundMusicTrebbleAudioModel){
if(selectedBackgroundMusicTrebbleAudioModel){
    this._setBackgroundMusicTrebbleAudioModel(selectedBackgroundMusicTrebbleAudioModel);

}
}).bind(this)
return PersistentModels.getInstance().getRouter().showChooseBackgroundMusicPopup(onBackgroundMusicSelected);
},

_setupBackgroundMusicSelector: function(){
const backgroundMusicSelectorView = new SingleSongSelectorInputView({"noAudioSelectedCustomLabel":"<span class='ion-ios7-musical-notes' style='margin-right: 0.5em;margin-left: 0.5em;font-size: 1.5em;'/>"+window.getI18n(ti18n, "NO_BACKGROUND_MUSIC"),"chooseAudioCustomLabel":window.getI18n(ti18n, "ADD_BACKGROUND_MUSIC"),"removeAudioCustomLabel":window.getI18n(ti18n, "REMOVE_BACKGROUND_MUSIC")});
backgroundMusicSelectorView.render();
this.backgroundMusicSelectorView = backgroundMusicSelectorView;
this.listenTo(this.backgroundMusicSelectorView ,"deleteCurrentSelectedSong", this._onDeleteSelectedBackgroundMusic.bind(this));
this.listenTo(this.backgroundMusicSelectorView , "pickSong", this._showBackgroundMusicPicker.bind(this));
this._onSelectionChanged(this._selectedBackgroundMusicModel);
this._backgroundTrackContainerId  = 'trackContainer' + (new Date().getTime());
if(SHOW_BACKGROUND_TRACK_SELECTOR_OUTSIDE_OF_TIMER){
this.trackContainer$el  = $("<div id='"+this._backgroundTrackContainerId+"' class='swiper-container trackSelectorBox' style='width: "+this._circleProgressBarContainerWidth+"px; overflow:visible;'></div>");
this.trackContainer$el.append(backgroundMusicSelectorView.$el);
this.trackContainer$el.insertBefore(this.recordControlsWrapper$el);
this.trackSlectorBlockSpacer$el.hide();
}else{
const containerLeft = (this._circleProgressBarDiameter - this._trackSwiperContainerWidth)/2;
const containerBottom = containerLeft;
this.trackContainer$el  = $("<div id='"+this._backgroundTrackContainerId+"' class='swiper-container trackSelectorBox' style='height: 50px;width:"+this._trackSwiperContainerWidth+"px; overflow:visible; position:absolute;left:"+containerLeft+"px;bottom:"+containerBottom+"px;'></div>");
this.trackContainer$el.append(backgroundMusicSelectorView.$el);
this.progressbarContainer$el.append(this.trackContainer$el);
this.trackSlectorBlockSpacer$el.hide();
}
this.trackContainer$el.hide();
},

_setupBackgroundTrackSelector : function(){
this._backgroundTrackContainerId  = 'trackContainer' + (new Date().getTime());
if(SHOW_BACKGROUND_TRACK_SELECTOR_OUTSIDE_OF_TIMER){
this.trackContainer$el  = $("<div id='"+this._backgroundTrackContainerId+"' class='swiper-container trackSelectorBox' style='width: "+this._circleProgressBarContainerWidth+"px; '></div>");
this.trackContainer$el.append("<div class='swiper-wrapper'> </div>"+ this._buildCarouselNavigationButtons()+"</div>");
this.trackSlectorBlockSpacer$el.hide();
}else{
const containerLeft = (this._circleProgressBarDiameter - this._trackSwiperContainerWidth)/2;
const containerBottom = containerLeft;
this.trackContainer$el  = $("<div id='"+this._backgroundTrackContainerId+"' class='swiper-container trackSelectorBox' style='height: 50px;width:"+this._trackSwiperContainerWidth+"px; position:absolute;left:"+containerLeft+"px;bottom:"+containerBottom+"px;'></div>");
this.trackContainer$el.append("<div class='swiper-wrapper'> </div>"+ this._buildCarouselNavigationButtons()+"</div>");
this.trackSlectorBlockSpacer$el.hide();
}

const swiperWrapper$el  = this.trackContainer$el.find(".swiper-wrapper");
swiperWrapper$el.append(this._buildCarouselTrackItem$el("",NO_BACKGROUND_TRACK, CHOOSE_BACKGROUND_TRACK_LABEL));
for(let trackUri in BACKGROUND_MUSIC_URI_TO_INFO){
const trackInfo =  BACKGROUND_MUSIC_URI_TO_INFO[trackUri];
const trackName = trackInfo.name;
const artistName = trackInfo.artistName;
const trackDownloadUrl = trackInfo.downloadUrl;
swiperWrapper$el.append(this._buildCarouselTrackItem$el(trackUri, trackName, " "+window.getI18n(ti18n,"BY")+" " + artistName, trackDownloadUrl));
}
if(SHOW_BACKGROUND_TRACK_SELECTOR_OUTSIDE_OF_TIMER){
this.trackContainer$el.insertBefore(this.recordControlsWrapper$el);
}else{
this.progressbarContainer$el.append(this.trackContainer$el);
}

const swiperParams  =  {
speed: window.isMobileBrowser?200: 300,
longSwipesRatio : window.isMobileBrowser?0.2: 0.4,
watchSlidesProgress: true,
preloadImages: false,
watchSlidesVisibility: true,
slidesPerView: 1,
centeredSlides: true,
paginationHide: true,
slideToClickedSlide: true,
lazyLoading: true,
lazyLoadingInPrevNext: true,
lazy: {
loadPrevNext: true
},
//onInit : this._onSwiperInit.bind(this),
onSlideChangeEnd : this._onBackgroundTrackChange.bind(this),
nextButton :'.swiper-button-next',
prevButton : '.swiper-button-prev',
keyboardControl : true,
mousewheelControl : true,
mousewheel :  {
enabled: true
},
keyboard :  {
enabled: true,
onlyInViewport: false
},
on:{
"slideChangeTransitionEnd" : this._onBackgroundTrackChange.bind(this),
}
};


this._backgroundTrackSwiperObj = new Swiper("#"+this._backgroundTrackContainerId,swiperParams); 
if(!this._canBackgroundMusicUsed()){
//disable background track on mobile for now
this._hideTrackContainer();
}
this.trackContainer$el.hide();

},
_onBackgroundTrackChange : function(){
const currentBackgroundTrackUri = this.$el.find(".swiper-slide-active > .trackItem").attr("data-track-uri");
this.trigger("backgroundTrackChange",currentBackgroundTrackUri);
},

_buildTrackCarouselWrapper : function(){
return  $("<div class='slideItemWrapper swiper-slide' style='width:100%;'></div>");

},

_buildCarouselNavigationButtons : function(){
return      '<div class="swiper-button-next ion-ios7-arrow-right"></div> <div class="swiper-button-prev ion-ios7-arrow-left"></div>';
},

getTextToConvert: function(){
return this.textToConvertField$el.val();
},

getTextToNarrate: function(){
return this.textToConvertByVoiceActorField$el.val();
},

getNarrationInstructions: function(){
return this.instructionsForVoiceActorField$el.val();
},

getTTSLanguage : function(){
return this.$el.find("#ttsLanguageFld option:selected").val();
},

getNarrationLanguage : function(){
return this.$el.find("#ttsLanguageForVoiceActorFld option:selected").val();
},

getLanguageUsedInAudio : function(){
return this.$el.find("#languageUsedInAudioFld option:selected").val();
},

getVoiceProfile : function(){
return this.$el.find("#voiceProfileFld option:selected").val();
},

getVoiceTone : function(){
return this.$el.find("#voiceToneFld option:selected").val();
},

isTextToConvertFieldEmpty : function(){
const textToConvertInAudio =  this.getTextToConvert();
return !textToConvertInAudio || textToConvertInAudio.trim().length  == 0;
},

isTextToNarrateFieldEmpty : function(){
const textToNarrate =  this.getTextToNarrate();
return !textToNarrate || textToNarrate.trim().length  == 0;
},

isTextToNarrateFieldValid : function(){
return !this.isTextToNarrateFieldEmpty() && this._isTextToNarrateCountValid();
},

setPeakMeterViewwIfApplicable: function(){
if(this._peakMeterView){
this.peakMeterContainer$el.append(this._peakMeterView.$el);
}
},

setDarkTheme : function(darkTheme){
    if(darkTheme){
        this.$el.addClass("lighttext transparent");
    }else{
        this.$el.removeClass("lighttext transparent");
    }
},

load: function(){
    return this.render();
},

render : function() {
this.$el.html(this.template(this._templateParams));
this.setElement(this.$el.find("div").first());
this.$el.find(".ui-content").enhanceWithin();
//this.$el.find("#whatsacapsuleButton").click(this.onWhatsACapsuleButtonClicked.bind(this));

if(this._creationModeSelectorView){
this._creationModeSelectorView.$el.addClass("display_on_mobile");
this.$el.find("#creationModeSelectorWrapper").first().append(this._creationModeSelectorView.$el);
}
if(this._creationModeFlatListViewContainer$el){
this.$el.find("#creationModeSelectorWrapper").first().append(this._creationModeFlatListViewContainer$el);
}

this.trackSlectorBlockSpacer$el = this.$el.find(".track_selector_block_spacer");


this.uploadBlock$el =this.$el.find("#uploadBlock");
this.recordingBlock$el =  this.$el.find("#recordingBlock");
this.peakMeterContainer$el =  this.$el.find("#peak_meter_container");
this.textToConvertField$el = this.$el.find("#textToConvertField");
this.textToConvertByVoiceActorField$el = this.$el.find("#textToConvertByVoiceActorField");
this.instructionsForVoiceActorField$el = this.$el.find("#instructionsForVoiceActorField");
this.wordCountNumber$el = this.$el.find(".word_count_wrapper .word_count_number");
this.textToConvertField$el.on("keyup input", this.onTextToConvertToAudioFieldChange.bind(this));
this.textToConvertByVoiceActorField$el.on("keyup input", this.onTextToNarrateFieldChange.bind(this));
this.showRecordSectionButton$el = this.$el.find("[data-role=header] #showRecordSectionButton");
this.showUploadSectionButton$el = this.$el.find("#showUploadSectionButton");
this.showTextToSpeechSectionButton$el = this.$el.find("#showTextToSpeechSectionButton");
this.showVoiceActorNarrationSectionButton$el = this.$el.find("#showVoiceActorNarrationSectionButton");
this.textToSpeechBlock$el =  this.$el.find("#textToSpeechBlock");
this.useVoiceActorBlock$el = this.$el.find("#useVoiceActorBlock");
this.recordProgressContainer$el =this.$el.find("#recordProgressContainer");
this.uploadProgressInfo$el =  this.$el.find(".capsule_info_wrapper .uploadProgressInfo");
this.audioEditorAndTranscriptionBox$el = this.$el.find(".audio_editor_and_transcription_box");
this.transcriptionParametersBox$el = this.$el.find(".parameters_for_transcription_wrapper");
this.uploadProgressInfo$el.append(this._capsuleInfoProgressBarView.$el);
this.audioEditorUploadProgressInfo$el =  this.$el.find(".audio_editor_section .uploadProgressInfo");
this.audioEditorUploadProgressInfo$el.append(this._audioEditorProgressBarView.$el);
this.capsuleInfo$el = this.$el.find(".capsuleInfo");
this.previousButtn$el = this.$el.find("#previousButton");
this.playButton$el =  this.$el.find("#playButton");
this.saveButton$el =  this.$el.find("#saveButtonIcon");
this.messageToStartTranscriptionBox$el =  this.$el.find(".message_to_start_transcription_box_wrapper");
this.sequncerNodeWrapper$el =  this.$el.find(".sequencer_node_wrapper");
this.transcribeAndEditBtn$el = this.$el.find("#transcribe_and_edit_audio_btn");
this.continueWithNoEditBtn$el = this.$el.find("#continue_with_no_edit_btn");

this.capsuleConfigWrapper$el = this.$el.find(".capsuleConfigWrapper");
this.capsuleLifeSpanFld$el = this.$el.find("#capsuleLifeSpanFld");
this.doNotAddOnAirCheckbox$el = this.$el.find("#doNotAddOnAirCheckbox");
this.schedulingSettingWrapper$el =  this.$el.find(".schedulingSettingWrapper");
this.schedulingTimeWrapper$el =  this.$el.find(".schedulingTimeWrapper");
this.publishingSettingWrapper$el =  this.$el.find(".publishingSettingWrapper");
this.schedulingTimezoneWrapper$el =  this.$el.find(".schedulingTimezoneWrapper");
this.scheduleInfoWrapper$el =  this.$el.find(".scheduleInfoWrapper");
this.recordControlsWrapper$el = this.$el.find("#recordControlsWrapper");
this.doNotAddOnAirCheckbox$el.change(this.onDoNotAddOnAirCheckboxChange.bind(this));
this.capsuleLifeSpanFld$el.change(this._hideShowSchedulingDateFieldIfApplicable.bind(this));
this.titleField$el = this.$el.find("#titleField");
this.titleField$el.on("click blur keyup input", this._onCapsuleInfoEdited.bind(this));
this.schedulingSettingFld$el = this.$el.find("#schedulingSettingFld");
this.schedulingTimeFld$el = this.$el.find("#schedulingTimeFld");
this.schedulingTimezoneFld$el = this.$el.find("#schedulingTimezoneFld");
this.languageUsedInAudioFld$el = this.$el.find("#languageUsedInAudioFld");
this.showRecordingSettingsButton$el = this.$el.find("#showRecordingSettingsButton");
this.recordingInstruction$el = this.$el.find("#recordingInstruction");
this.counterAnimationBox$el =  this.$el.find("#counterAnimationBox");
this.comment$el = this.$el.find("#comment");
this.setPeakMeterViewwIfApplicable();
this.schedulingSettingFld$el.on("change", this._onCapsuleInfoEdited.bind(this));
this.schedulingTimeFld$el.on("change", this._onCapsuleInfoEdited.bind(this));
this.schedulingTimezoneFld$el.on("change", this._onTimezoneEdited.bind(this));
this.languageUsedInAudioFld$el.on("change", this._onTranscriptionLanguageFieldValueChange.bind(this));

this.$el.find(".orrecordsection").hide();
if(DO_NOT_SHOW_HEADER_BUTTONS_TO_SWITCH_MODES){
this.showRecordSectionButton$el.hide();
this.showUploadSectionButton$el.hide();
this.showTextToSpeechSectionButton$el.hide();
this.showVoiceActorNarrationSectionButton$el.hide();
}
if(!ENABLE_ADDING_MUSIC_TO_PLAY_AFTER_CAPSULE){
this.$el.find(".songToPlayAfterCapsuleFieldWrapper").hide();
}

if(this._canSaveOnlyAfterTranscriptionAndEditing){
this.$el.find("#transcribe_btn  .label").html(window.getI18n(ti18n,"TRANSCRIBE_AND_EDIT_AUDIO"));
}

//this.$el.page();
this.$el.enhanceWithin();

this._addCapsuleCategoriesToView();
this._addTimezonesToView();
this._addTranscriptioneSupportedLanguageToView();
PersistentModels.getInstance().getRouter().registerForViewportHeightChange(this._pageId, this._onWindowSizeChanged.bind(this), false);
this.afterRendering();
this._onWindowSizeChanged();
//this.$el.on("pagehide", this._onPageHide.bind(this));
//this.$el.on("pageshow", this._onPageShow.bind(this));
this._onPageShow();
this.setDarkTheme(LocalStorageHelper.getInstance().getUseEditorDarkTheme());

return this;
},



});

export default RecordAudioHightlightContentView;
