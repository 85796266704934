import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import ShareTrebblePopupView from "views/shareTrebble/ShareTrebblePopupView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import ti18n from "i18n!nls/shareTrebblePopupi18n";
import PersistentModels from "services/PersistentModels";
import capsuleEmbedCodeInEmailTemplate from 'text!../../../templates/shareTrebble/capsuleEmbedCodeInEmailTemplate.html';
import capsuleEmbedCodeInEmailColoredTemplate from 'text!../../../templates/shareTrebble/capsuleEmbedCodeInEmailTemplateColored.html';
import capsuleEmbedCodeInEmailWithTimelineTemplate from 'text!../../../templates/shareTrebble/capsuleEmbedCodeInEmailTemplateWithTimeline.html';
import capsuleEmbedCodeInEmailWithTimelineColoredTemplate from 'text!../../../templates/shareTrebble/capsuleEmbedCodeInEmailTemplateWithTimelineColored.html';
import Utils from "models/helper/Utils";
import RSVP from "rsvp";
import FacebookHelper from "models/helper/FacebookHelper";

const ShareSimpleCapsuleSetPopupController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.model = options.capsule;
        this._capsuleId = this.model.getCapsuleId();
        this._capsuleDurationInSeconds =  options.capsuleDurationInSeconds
        this.context = options.context;
        this._coverArtUrl = options.coverArtUrl;
        this._coverArtBlurUrl = options.coverArtBlurUrl;
        this._capsuleShareableLink = null;
        this._trebbleWidgetLandscapeLink = null;
        this._trebbleWidgetPortraitLink = null;
        this._embedCodeInNewsletter = null;
        this._embedCodeInNewsletterColored = null;
        this._initializeLinks(this._capsuleId);
        _.extend(this, Backbone.Events);
    },

    _initializeLinks : function(capsuleId){
        this._capsuleShareableLink = "https://s.trebble.fm/p/p/c/"+capsuleId;
        this._trebbleWidgetLandscapeLink = '<iframe src="https://web.trebble.fm/trebble_embedded_optimized.html#p/l/c/'+capsuleId+'" height="150" frameBorder="0" allowtransparency="true" allowfullscreen style="box-shadow:rgba(0, 0, 0, 0.6) 0px 0px 50px"></iframe>';
        this._trebbleWidgetPortraitLink = '<iframe src="https://web.trebble.fm/trebble_embedded_optimized.html#p/l/c/'+capsuleId+'" width="300" height="400" frameBorder="0" allowtransparency="true" allowfullscreen style="box-shadow:rgba(0, 0, 0, 0.6) 0px 0px 50px"></iframe>';
        const playUrlWithEmailNewsletterCustomTracking = "https://web.trebble.fm/p/p/c/"+capsuleId+"/r/"+Utils.getInstance().buildContextForEmailNewsletter().type+"/ctk/" +Utils.getInstance().getEmailNewsletterEnvironmentContext();
        const coverArtUrlResized  = this._coverArtUrl ? Utils.getInstance().getResizeImageUrl(this._coverArtUrl, 64,64): "";
        const coverArtBlurUrlResized =  this._coverArtBlurUrl;
        if(this._capsuleDurationInSeconds){
            this._embedCodeInNewsletter = _.template(capsuleEmbedCodeInEmailWithTimelineTemplate)({"playUrl": playUrlWithEmailNewsletterCustomTracking,"title":Utils.getInstance().addEllipsisIfApplicable(this.model.getTitle(), 100), "coverArtUrl": coverArtUrlResized,"durationFormatted": Utils.getInstance().getFormattedTimeDurationForDisplayInPlayer(this._capsuleDurationInSeconds)});
            this._embedCodeInNewsletterColored = _.template(capsuleEmbedCodeInEmailWithTimelineColoredTemplate)({"playUrl": playUrlWithEmailNewsletterCustomTracking,"title":Utils.getInstance().addEllipsisIfApplicable(this.model.getTitle(), 100), "coverArtUrl": coverArtUrlResized,"coverArtBlurUrl":coverArtBlurUrlResized, "durationFormatted": Utils.getInstance().getFormattedTimeDurationForDisplayInPlayer(this._capsuleDurationInSeconds)});
        }else{
            this._embedCodeInNewsletter = _.template(capsuleEmbedCodeInEmailTemplate)({"playUrl": playUrlWithEmailNewsletterCustomTracking,"title":Utils.getInstance().addEllipsisIfApplicable(this.model.getTitle(), 100), "coverArtUrl": coverArtUrlResized});
            this._embedCodeInNewsletterColored = _.template(capsuleEmbedCodeInEmailColoredTemplate)({"playUrl": playUrlWithEmailNewsletterCustomTracking,"title":Utils.getInstance().addEllipsisIfApplicable(this.model.getTitle(), 100), "coverArtUrl": coverArtUrlResized,"coverArtBlurUrl":coverArtBlurUrlResized});
        }
    },


    _copyToClipboard : function(toCopy, confirmMessage){
        if('cordova' in window && window.cordova && window.cordova.plugins && window.cordova.plugins.clipboard ){
            window.cordova.plugins.clipboard.copy(toCopy);
            Utils.getInstance().showShortToastMessage(confirmMessage);
        }else{
            if (!window.waitForCordovaToLoad && false == document.execCommand('copy')) {
                navigator.trebbleNotification.alert(window.getI18n(ti18n, "PLEASE_PRESS_CNTR_C_TO_COPY"));
                
            }else{
                try{
                    document.execCommand('copy');
                    Utils.getInstance().showShortToastMessage(confirmMessage);
                }catch(e){
                    navigator.trebbleNotification.alert(window.getI18n(ti18n, "PLEASE_PRESS_CNTR_C_TO_COPY"));
                }
            }
        }
    },

    _shareNatively : function(toShare,url, title){
        if (navigator.share) {
            navigator.share({
                'title': title? title: "",
                text: toShare? toShare:"",
                url: url
            });
        }
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    copyShareableLinkToClipboard : function(toCopy){
        this._copyToClipboard(toCopy, window.getI18n(ti18n,"SHAREABLE_LINK_COPIED_TO_CLIPBOARD"));
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Capsule", "CapsuleShareLinkCopied", "Capsule Share Link Copied");
        }
        
    },

    copyWidgetLandscapeLinkToClipboard : function(toCopy){
        this._copyToClipboard(toCopy, window.getI18n(ti18n,"EMBED_CODE_COPIED_TO_CLIPBOARD"));
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Capsule", "CapsuleEmbedCodeCopied", "Capsule Embed Code Landscape Copied");
        }
    },


    copyWidgetPortraitLinkToClipboard : function(toCopy){
        this._copyToClipboard(toCopy, window.getI18n(ti18n,"EMBED_CODE_COPIED_TO_CLIPBOARD"));
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Capsule", "CapsuleEmbedCodeCopied", "Capsule Embed Portrait Code Copied");
        } 
    },

    copyEmbedToNewsletterCodeToClipboard : function(toCopy){
        this._copyToClipboard(toCopy, window.getI18n(ti18n,"EMBED_CODE_COPIED_TO_CLIPBOARD"));
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Capsule", "CapsuleEmbedInNewsletterCodeCopied", "Capsule Embed In Newsletter Code Copied");
        } 
    },


    copyEmbedToNewsletterColoredCodeToClipboard : function(toCopy){
        this._copyToClipboard(toCopy, window.getI18n(ti18n,"EMBED_CODE_COPIED_TO_CLIPBOARD"));
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Capsule", "CapsuleEmbedInNewsletterColoredCodeCopied", "Capsule Embed In Newsletter Colored Code Copied");
        } 
    },



    shareNativelyShareableLinkToClipboard : function(toShare){
        this._shareNatively(null, toShare,/*window.getI18n(ti18n, "CAPSULE_URL")*/ null);
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Capsule", "CapsuleShareLinkSharedNatively", "Capsule Share Link Shared Natively");
        }
        
    },

    shareNativelyWidgetLandscapeLinkToClipboard : function(toShare){
        this._shareNatively(null, toShare, window.getI18n(ti18n, "CAPSULE_EMBEDDED_CODE_LANDSCAPE"));
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Capsule", "CapsuleEmbedCodeSharedNatively", "Capsule Embed Code Landscape Shared Natively");
        }
    },


    shareNativelyWidgetPortraitLinkToClipboard : function(toShare){
        this._shareNatively(null, toShare, window.getI18n(ti18n, "CAPSULE_EMBEDDED_CODE_PORTRAIT"));
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Capsule", "CapsuleEmbedCodeSharedNatively", "Capsule Embed Portrait Code Shared Natively");
        } 
    },

    shareNativelyEmbedInNewsletterCodeToClipboard : function(toShare){
        this._shareNatively(null, toShare, window.getI18n(ti18n, "CAPSULE_EMBED_NEWSLETTER_CODE"));
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Capsule", "EmbedInNewsletterCodeSharedNatively", "Embed In Newsletter Code Shared Natively");
        } 
    },

    shareNativelyEmbedInNewsletterColoredCodeToClipboard : function(toShare){
        this._shareNatively(null, toShare, window.getI18n(ti18n, "CAPSULE_EMBED_NEWSLETTER_CODE"));
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Capsule", "EmbedInNewsletterColoredCodeSharedNatively", "Embed In Newsletter Colored Code Shared Natively");
        } 
    },


    shareOnFacebook : function(toShare){
        FacebookHelper.getInstance().showDialog({
            method: 'share',
            href: toShare,
        },{"entity":"Capsule", "eventName":"CapsuleShareFacebook", "eventLabel":"Capsule URL Shared On Facebook"} ).catch(function(error){
            window.alertErrorMessage(error);
        })
        /*FB.ui({
            method: 'share',
            href: toShare,
        }, function(response){
            if(response && response.error_message){
                window.alertErrorMessage(response.error_message);
            }else{
                if(window.trebbleAnalyticsHelper){
                    window.trebbleAnalyticsHelper.trackEvent("Capsule", "CapsuleShareFacebook", "Capsule URL Shared On Facebook");
                } 
            }
        });*/
},

shareOnTwitter : function(toShare){
const w = 550;
const h = 285;
const left = window.getLeftPositionForPopupWindow(w);
const top = window.getTopPositionForPopupWindow(h);
const openedWindow =window.open("https://twitter.com/intent/tweet?via=trebblefm&url="+encodeURIComponent(toShare),"Twitter", "resizable=1,height="+h+",width="+w+",top="+top+",left="+left+"");
if((!openedWindow || openedWindow.closed || typeof openedWindow.closed=='undefined') && !window.waitForCordovaToLoad){
window.alertErrorMessage(window.getI18n(ti18n, "PLEASE_ALLOW_POPUPS"));
}
if(window.trebbleAnalyticsHelper){
window.trebbleAnalyticsHelper.trackEvent("Capsule", "CapsuleShareTwitter", "Capsule URL Shared On Twitter");
}
},

previewLink: function(linkToPreview){
const openedWindow  = window.open(linkToPreview , "_blank");
if((!openedWindow || openedWindow.closed || typeof openedWindow.closed=='undefined') && !window.waitForCordovaToLoad){
window.alertErrorMessage(window.getI18n(ti18n, "PLEASE_ALLOW_POPUPS"));
}
return openedWindow
},


buildModelAndRenderView : function() {
return new RSVP.Promise((function(resolve, reject) {
try {

    const params = {};
    params.trebbleId = this._capsuleId;
    params.trebbleShareableLink = this._capsuleShareableLink;
    params.trebbleWidgetLandscapeLink = this._trebbleWidgetLandscapeLink;
    params.trebbleWidgetPortraitLink = this._trebbleWidgetPortraitLink;
    params.embedCodeInNewsletter = this._embedCodeInNewsletter;
    params.embedCodeInNewsletterColored = this._embedCodeInNewsletterColored;
    params.canCopyEmbeddedWidgetLink = this.model.canBeEdited() || PersistentModels.getInstance().isCapsuleAddedToUserTrebbles(this.model);
    params.shareOnSmartSpeakers = false;
    params.shareSubscribePage = false;
    params.shareableLinkHasAShortcast = false;
    params.canEmbedLandscapePlayer = false;
    params.canEmbedPortraitPlayer = false;
    this.view = new ShareTrebblePopupView(params);
    this.listenTo(this.view, "preview",this.previewLink);
    this.listenTo(this.view, "copyLinkToClipboard",this.copyShareableLinkToClipboard);
    this.listenTo(this.view, "copyWidgetLandscapeToClipboard",this.copyWidgetLandscapeLinkToClipboard);
    this.listenTo(this.view, "copyWidgetPortraitToClipboard",this.copyWidgetPortraitLinkToClipboard);
    this.listenTo(this.view, "copyEmbedToNewsletterCodeToClipboard",this.copyEmbedToNewsletterCodeToClipboard);
    this.listenTo(this.view, "copyEmbedToNewsletterColoredCodeToClipboard",this.copyEmbedToNewsletterColoredCodeToClipboard);
    this.listenTo(this.view, "shareNativelyLinkToClipboard",this.shareNativelyShareableLinkToClipboard);
    this.listenTo(this.view, "shareNativelyWidgetLandscapeToClipboard",this.shareNativelyWidgetLandscapeLinkToClipboard);
    this.listenTo(this.view, "shareNativelyWidgetPortraitToClipboard",this.shareNativelyWidgetPortraitLinkToClipboard);
    this.listenTo(this.view, "shareNativelyEmbedCodeToNewsletterToClipboard",this.shareNativelyEmbedInNewsletterCodeToClipboard);
    this.listenTo(this.view, "shareNativelyEmbedCodeToNewsletterColoredToClipboard",this.shareNativelyEmbedInNewsletterColoredCodeToClipboard);
    this.listenTo(this.view, "shareOnFacebookButtonClicked",this.shareOnFacebook);
    this.listenTo(this.view, "shareOnTwitterButtonClicked",this.shareOnTwitter);

    this.view.render();
    resolve();


} catch (error) {
    reject(error);
}

}).bind(this));

}

});

export default ShareSimpleCapsuleSetPopupController;