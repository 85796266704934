import _ from 'underscore';
import AudioSegment from "models/audioEditor/segments/AudioSegment";

const CLASSNAME = "CloudVideoSegment";
const CloudVideoSegment =  AudioSegment.extend({
    idAttribute: "id",

    constructor: function(attributes, options) {
        AudioSegment.apply(this, [attributes, options]);
        this.set("type", CloudVideoSegment.CLASSNAME);
        
    },

    clone: function(){
        return new CloudVideoSegment(this.toJSON());
    },

    getVideoUrl : function(){
        return this.get("videoUrl");
    },

    setVideoUrl : function(newVideoUrl){
        this.set("videoUrl", newVideoUrl);
        this.clearStartTimeAndEndTime();
    },

    getHasVideo : function(){
        return true;
    },

    getWidth : function(){
        return this.get("width");
    },

    getHeight : function(){
        return this.get("height");
    },

    getVideoWidth : function(){
        return this.getWidth();
    },

    getVideoHeight : function(){
        return this.getHeight();
    },


});
CloudVideoSegment.CLASSNAME = CLASSNAME;
export default CloudVideoSegment; 