import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";

const mediaStatuses = {

    PLAYING:2,
    PAUSED:3,
    STOPPED:4,

};

const MediaIOS =  Backbone.Model.extend({
    "default": {
        "DEFAULT_MEDIA_STATUS" :mediaStatuses,
        "src": "",
        "mediaObjectCreatedSuccess":"",
        "mediaObjectCreatedFailure":"",
        "mediaSuccess": "",
        "mediaError": "",
        "mediaStatus": "",
    },

    initialize : function() {
        const onLoadedSuccessfully = function(){
            this.get("mediaObjectCreatedSuccess")();
        };

        const onLoadedFailed = function(error){
            this.get("mediaObjectCreatedFailure")(error);
        };
        window.onIOSMediaStatusChanged = this.get("mediaStatus");
        window.onIOSMediaFinishedPlaying = this.get("mediaSuccess");
        window.onIOSMediaFailedPlaying = this.get("mediaError");
        window.cordova.exec(onLoadedSuccessfully.bind(this),
            onLoadedFailed.bind(this),
            "MusicFileReaderPlugin",
            "loadCurrentSongByPersitId", [this.get("src")]);
    },

    play: function(){
        const onSuccess = function(){
            this.get("mediaStatus")(2);
        };

        const onFailure = function(error){
            window.log("Error trying to play music from ios player . Error"+ error);
        };
        window.cordova.exec(onSuccess.bind(this),
            onFailure.bind(this),
            "MusicFileReaderPlugin",
            "playCurrentSong", []);
    },

    pause: function(){
        const onSuccess = function(){
            this.get("mediaStatus")(3);
        };

        const onFailure = function(error){
            this.get("mediaError")(error);
        };
        window.cordova.exec(onSuccess.bind(this),
            onFailure.bind(this),
            "MusicFileReaderPlugin",
            "pauseCurrentSong", []);
    },


    stop: function(){
        const onSuccess = function(){
            this.get("mediaStatus")(4);
        };

        const onFailure = function(error){
            this.get("mediaError")(error);
        };
        window.cordova.exec(onSuccess.bind(this),
            onFailure.bind(this),
            "MusicFileReaderPlugin",
            "stopCurrentSong", []);
    },

    release: function(){
    },


    seekTo: function(position){
    },

    startRecord: function(){
        window.alertErrorMessage("Not Supported yet");
    },

    stopRecord: function(){
        window.alertErrorMessage("Not Supported yet");
    },

    getDuration: function(onSuccessCallback, onFailureCallback){
        const onSuccess = function(currentPosition){
            onSuccessCallback(currentPosition);
        };

        const onFailure = function(error){
            onFailureCallback("mediaError")(error);
        };
        window.cordova.exec(onSuccess.bind(this),
            onFailure.bind(this),
            "MusicFileReaderPlugin",
            "getSongDuration", []);
    },

    getCurrentPosition: function(onSuccessCallback, onFailureCallback){
        const onSuccess = function(currentPosition){
            onSuccessCallback(currentPosition);
        };

        const onFailure = function(error){
            onFailureCallback("mediaError")(error);
        };
        window.cordova.exec(onSuccess.bind(this),
            onFailure.bind(this),
            "MusicFileReaderPlugin",
            "getCurrentPosition", []);
    },



});
export default MediaIOS;