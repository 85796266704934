
import DynamicPageView from "views/common/DynamicPageView";
import ti18n from "i18n!nls/Useri18n";
import PersistentModels from "services/PersistentModels";
import SendPasswordRecoveryEmailPageTemplate from 'text!../../../templates/user/SendPasswordRecoveryEmailPageTemplate.html';
import Mousetrap from 'mousetrap';

const SendPasswordRecoveryEmailPageView = DynamicPageView.extend({

    //template : _.template(ExplorePageTemplate),

initialize : function(options) {
    const dynamicPageOptions = {};
    this.model =  options? options.model: {};
    this._pageId = (options && options.pageId)? options.pageId:"SendRecoveryPasswordEmailPage";
    dynamicPageOptions.pageViewTemplate = SendPasswordRecoveryEmailPageTemplate;
    dynamicPageOptions.model = this.model;
    dynamicPageOptions.pageId = this._pageId;
    dynamicPageOptions.headerFullscren = true;
    dynamicPageOptions.persistent = true;
    dynamicPageOptions.onPageShow = this.onPageShow.bind(this);
    dynamicPageOptions.pageTemplateOptions = {"ti18n": ti18n};
    //PersistentModels.getInstance().getRouter().registerForViewportHeightChange(this._pageId,this._onViewportHeightChanged.bind(this));
    return DynamicPageView.prototype.initialize.call(this, dynamicPageOptions);  
},

events : {
    "click #header-back-button" : "navigateToPreviousPage",
    "click #sendRecoveryPasswordEmailBtn:not(.disabled)" : "sendEmailButton",
},

sendEmailButton : function(){
    this.trigger("sendRecoveryPasswordEmail", this.emailField$el.val());
    this.setSendRecoveryEmailButtonEnabled(false);
},



onFieldValueChanged : function(){
    if(this.isAllMandatoryFieldsFilled()){
        this.setSendRecoveryEmailButtonEnabled(true);
    }else{
        this.setSendRecoveryEmailButtonEnabled(false);
    }
},

isAllMandatoryFieldsFilled : function(){
    const isEmailValid = this.validateEmail(this.emailField$el.val());
    return isEmailValid;
    
},

 validateEmail : function(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email? email.trim(): email);
},

navigateToPreviousPage : function(){
    PersistentModels.getInstance().getRouter().navigateToPreviousPage();
},

onBeforePageInitialized : function(){

},

setSendRecoveryEmailButtonEnabled : function(enabled){
    if(enabled){
        this.sendResetLinkBtn$el.removeClass("disabled");
    }else{
        if(!this.sendResetLinkBtn$el.hasClass("disabled")){
            this.sendResetLinkBtn$el.addClass("disabled");
        }
    }
},


onAfterPageInitialized : function(){

    this.sendResetLinkBtn$el  = this.$el.find("#sendRecoveryPasswordEmailBtn")
    this.emailField$el = this.$el.find("#emailField");
    this.$el.find("#passwordChangeFormWrapper").on("click blur keyup input", this.onFieldValueChanged.bind(this));
    this.onFieldValueChanged();
    this.setSendRecoveryEmailButtonEnabled(false);
    this.$el.on("pagehide", this._onPageHide.bind(this));
    this._mousetrapObj = Mousetrap(this.$el.find("#passwordChangeFormWrapper").get(0)).bind('enter', (function() {
        if(!this.sendResetLinkBtn$el.hasClass("disabled")){
            this.sendEmailButton();
        }
    }).bind(this));

},

onPageShow : function(){
    this.trigger("pageShow");
},
onBeforeRemoveFromPage : function(){
    DynamicPageView.prototype.onBeforeRemoveFromPage.call(this); 
    PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._pageId);
},

    });
export default SendPasswordRecoveryEmailPageView;