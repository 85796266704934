import _ from 'underscore';
import ParagraphContainerView from "views/audioEditor/sequencerNodeView/ParagraphContainerView";
import UnlabeledParagraphContainerTemplate from 'text!../../../../templates/audioEditor/sequencerNodeView/UnlabeledParagraphContainerTemplate.html';
import ti18n from 'i18n!nls/Sequenceri18n';
import Utils from 'models/helper/Utils';

const MAX_SENTENCES_PER_PARAGRAPH = 4;
const UnlabelledParagraphContainerView = ParagraphContainerView.extend({



    constructor : function(attributes, options) {
        ParagraphContainerView.apply(this, [attributes, options]);
        this.compiledTemlate =  _.template(UnlabeledParagraphContainerTemplate);
        this._maxNumberOfSentencesPerParagraph = MAX_SENTENCES_PER_PARAGRAPH;
        this._arrayOfSequencerNodeViewWithFullstop = [];
        this._sentencesCount =  0;
    },
    
    events : _.extend({
        "click": "onNodeClick",
        "click .speaker_label": "onParagraphTitleLabelClick",
    }, ParagraphContainerView.prototype.events),
    
    onNodeClick : function(){
        this.trigger("nodeClick", this);
    },

    onParagraphTitleLabelClick : function(){
        this.trigger("onParagraphTitleLabelClick", this);
    },

    createNewEmptyParagraphContainerView : function(){
        return new UnlabelledParagraphContainerView();
    },

    getSpeakerLabelContainer$el : function(){
        return this.speakerLabelContainer$el;
    },

    _isContentContainsFullstopAtTheEnd : function(sequencerNodeView){
        return this.isWordSequencerNodeView(sequencerNodeView) && sequencerNodeView.getModel().getContent() && sequencerNodeView.getModel().getContent().trim().endsWith(".")
    },

    onSequencerNodeViewAdded : function(sequencerNodeViewToAdd){
        ParagraphContainerView.prototype.onSequencerNodeViewAdded.apply(this, [sequencerNodeViewToAdd]);
        if(this._isContentContainsFullstopAtTheEnd(sequencerNodeViewToAdd)){
            this._sentencesCount = this._sentencesCount + 1;
            this._arrayOfSequencerNodeViewWithFullstop.push(sequencerNodeViewToAdd);
        }
    },

    onSequencerNodeViewRemoved : function(sequencerNodeViewToRemove){
        ParagraphContainerView.prototype.onSequencerNodeViewRemoved.apply(this, [sequencerNodeViewToRemove]);
        if(this._isContentContainsFullstopAtTheEnd(sequencerNodeViewToRemove)){
            this._sentencesCount = this._sentencesCount - 1;
            const indexOfElementToRemoveInFullstopArray = this._arrayOfSequencerNodeViewWithFullstop.indexOf(sequencerNodeViewToRemove);
            if(indexOfElementToRemoveInFullstopArray > -1){
                this._arrayOfSequencerNodeViewWithFullstop.splice(indexOfElementToRemoveInFullstopArray, 1);
            }
        }
    },

    getIndexOfLastFullstop : function(){
        return this._arrayOfSequencerNodeViewWithFullstop.reduce((maxFullstopPosition, sequencerNodeView)=>{
            const indexOfFullstop = this.getSequencerNodeViewIndex(sequencerNodeView);
            if(indexOfFullstop > maxFullstopPosition){
                return indexOfFullstop;
            }else{
                return maxFullstopPosition;
            }   
        }, -1)
    },

    isMaxNumberOfSentencesReached: function(){
       return this._sentencesCount === this._maxNumberOfSentencesPerParagraph;
    },

    isWordSequencerNodeView : function(sequencerNodeView){
        return sequencerNodeView && sequencerNodeView.getModel() && sequencerNodeView.getModel() && Utils.getInstance().isWordSequencerNodeInstance(sequencerNodeView.getModel());
    },

    isPauseSequencerNodeView : function(sequencerNodeView){
        return sequencerNodeView && sequencerNodeView.getModel() && sequencerNodeView.getModel() && Utils.getInstance().isPauseSequencerNodeInstance(sequencerNodeView.getModel());
    },

    isAddingSequencerNodeViewWillGoOverMaxSentencesPerParagraph : function(nodeViewToAdd){
        return this.isWordSequencerNodeView(nodeViewToAdd) && this._isContentContainsFullstopAtTheEnd(nodeViewToAdd) && this.isMaxNumberOfSentencesReached();
    },

    isBeingInserterteBeforeLastFullstop : function(nodeViewToAdd, nodeViewBefore, nodeViewAfter){
        if(nodeViewBefore || nodeViewAfter){
            const indexOfLastFullstop = this.getIndexOfLastFullstop();
            if(indexOfLastFullstop === -1 ){
                return true;
            }
            if(nodeViewBefore){
                const nodeBeforeIndex = this.getSequencerNodeViewIndex(nodeViewBefore);
                return nodeBeforeIndex + 1 <= indexOfLastFullstop;
            }
            if(nodeViewAfter){
                const nodeBeforeAfter = this.getSequencerNodeViewIndex(nodeViewAfter);
                return nodeBeforeAfter - 1 <= indexOfLastFullstop;
            }
        }
        return true;
    },

    canBeAddedBefore : function(nodeViewToAdd, nodeViewAlreadyInserted){
        if(this.hasReachMaxNumberOfSequencerNodeViews() && Utils.getInstance().isWordSequencerNodeInstance(nodeViewToAdd.getModel())){
            return false;
        }
        if(nodeViewToAdd && nodeViewToAdd.getModel() && nodeViewToAdd.getModel().needToBeAssociactedWithASpeaker() && nodeViewToAdd.getModel().getSpeakerInfo()){
            return false;
        }
        if(this.isWordSequencerNodeView(nodeViewToAdd) || this.isPauseSequencerNodeView(nodeViewToAdd)){
            if(this.isMaxNumberOfSentencesReached() && !this.isBeingInserterteBeforeLastFullstop(nodeViewToAdd, null, nodeViewAlreadyInserted)){
                return false;
            }else{
                return true;
            }
        }else{
            return true;
        }
    },

    canBeAddedAfter : function(nodeViewToAdd, nodeViewAlreadyInserted){
        if(this.hasReachMaxNumberOfSequencerNodeViews() && Utils.getInstance().isWordSequencerNodeInstance(nodeViewToAdd.getModel())){
            return false;
        }
        if(nodeViewToAdd && nodeViewToAdd.getModel() && nodeViewToAdd.getModel().needToBeAssociactedWithASpeaker() && nodeViewToAdd.getModel().getSpeakerInfo()){
            return false;
        }
        if(this.isWordSequencerNodeView(nodeViewToAdd) || this.isPauseSequencerNodeView(nodeViewToAdd)){
            if(this.isMaxNumberOfSentencesReached() && !this.isBeingInserterteBeforeLastFullstop(nodeViewToAdd, nodeViewAlreadyInserted, null)){
                return false;
            }else{
                return true;
            }
        }else{
            return true;
        }
    },

    getContentContainer$el : function(){
        return this.paragraphContentContainer$el;
    },


    render : function() {

        this.$el.html(this.compiledTemlate({"model": this.model, "view":this, "ti18n": ti18n}));
        this.setElement(this.$el.find("div").first());
        this.paragraphContentContainer$el = this.$el.find(".unlabeled_paragraph_content");
        this.speakerLabelContainer$el = this.$el.find(".speaker_label");
        this.afterRendering();
        return this;
    }

});
export default UnlabelledParagraphContainerView;