import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import PlaylistHelper from "models/helper/PlaylistHelper";
import Utils from "models/helper/Utils";


const ProgressiveAppHelper = Backbone.Model.extend({
    "default" : {

    },

    registerEventForUpdatingManifestAndHtmlMeta : function(playerModel){
        this.listenTo(playerModel, "playlistSet", this._onPlaylistSet.bind(this))
    },

    _updateManifestFile : function(playlistLoadedInQueue){
        let isLoadedPlaylistATrebble = false;
        isLoadedPlaylistATrebble =  PlaylistHelper.getInstance().isPlaylistOfTrebbleType(playlistLoadedInQueue);
        const existingManifestElements = document.querySelectorAll("link[rel=manifest]");
        for(let i = 0; i < existingManifestElements.length; i++){
            const linkRefNode = existingManifestElements[i];
            linkRefNode.parentNode.removeChild(linkRefNode);
        }

        const existingManifestEl = document.createElement('link');
        existingManifestEl.setAttribute('rel', "manifest");
        document.getElementsByTagName('head')[0].appendChild(existingManifestEl); 

        if(existingManifestEl){

            let manifestHref = "/manifest.json";
            //Using the "window.trebble.entryPointUrlHashLoadedPlayerQueue" to make sure to only use a trebble customized manifest
            //if the application was loaded because of a link shared to play a specific trebble, capsule,or song
            //It is assumed that the owner of the trebble, capsule, or song is the same owner of the trebble
            //that will be used to create the manifest file
            if(isLoadedPlaylistATrebble && window.trebble.entryPointUrlHashLoadedPlayerQueue){
                const trebbleId = playlistLoadedInQueue.getLoaderKey();
                //manifestHref = "http://s.trebble.fm/getManifest/byTrebbleId/"+trebbleId+"/manifest.json";
                manifestHref = "https://s.trebble.fm/getManifest/byTrebbleId/"+trebbleId+"/manifest.json";


            }

            existingManifestEl.setAttribute('href', manifestHref);
        }
    }, 

    _updateIOSPageMeta : function(playlistLoadedInQueue){
        let isLoadedPlaylistATrebble = false;
        isLoadedPlaylistATrebble =  PlaylistHelper.getInstance().isPlaylistOfTrebbleType(playlistLoadedInQueue);
        const playlistCoverArtUrl =  isLoadedPlaylistATrebble? playlistLoadedInQueue.getCoverArtUrl() : "/android-chrome-192x192.png";
        const logoUrl = playlistCoverArtUrl;
        const appName = isLoadedPlaylistATrebble? playlistLoadedInQueue.getName(): "Trebble.fm";
        let predominantColor =   isLoadedPlaylistATrebble ? Utils.getInstance().getMostPredominantColorFromCloudinaryImageInfo(playlistLoadedInQueue.getCoverArtUrlInfo()): "#333333";
        if(!predominantColor){
            predominantColor = "#333333";
        }
        const linkRelElementSelectorToUpdateMapWithUpdateValue = {
            "apple-touch-icon": logoUrl,
            "apple-touch-startup-image": playlistCoverArtUrl
        };
        for(let relValue in linkRelElementSelectorToUpdateMapWithUpdateValue){
            const value = linkRelElementSelectorToUpdateMapWithUpdateValue[relValue];
            const existingLinkElements = document.querySelectorAll("link[rel="+ relValue+"]");

            for(let i = 0; i < existingLinkElements.length; i++){
                const linkRefNode = existingLinkElements[i];
                linkRefNode.parentNode.removeChild(linkRefNode);
            }

            const linkElemToUpdate = document.createElement('link');
            linkElemToUpdate.setAttribute('rel', relValue);
            document.getElementsByTagName('head')[0].appendChild(linkElemToUpdate); 
            linkElemToUpdate.setAttribute('href', value);

        }
        const metaNameElementSelectorToUpdateMapWithUpdateValue = {
            "apple-mobile-web-app-title": appName,
            "apple-mobile-web-app-status-bar-style": predominantColor
        };
        for(let metaName in metaNameElementSelectorToUpdateMapWithUpdateValue){
            const value = metaNameElementSelectorToUpdateMapWithUpdateValue[metaName];
            const existingMetaElements = document.querySelectorAll("meta[name="+ metaName+"]");

            for(let i = 0; i < existingMetaElements.length; i++){
                const metaRefNode = existingMetaElements[i];
                metaRefNode.parentNode.removeChild(metaRefNode);
            }

            const metaElemToUpdate = document.createElement('meta');
            metaElemToUpdate.setAttribute('name', metaName);
            document.getElementsByTagName('head')[0].appendChild(metaElemToUpdate); 
            metaElemToUpdate.setAttribute('content', value);

        }
    }, 

    _onPlaylistSet : function(playlistLoadedInQueue){
        try{
            this._updateManifestFile(playlistLoadedInQueue);
            if(window.isIOSMobileDevice){
                this._updateIOSPageMeta(playlistLoadedInQueue);
            }
        }catch(e){
            console.error("Failed updating manifest information in meta tag. Error:"+ e);
        }
    },
    




});

const ProgressiveAppHelperInstance = new ProgressiveAppHelper();

export default {
	getInstance : function() {
		return ProgressiveAppHelperInstance;
	}
};