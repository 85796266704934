import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import SongStatisticsContentView from "views/songStatistics/SongStatisticsContentView";
import CustomSelectFieldController from "controllers/common/CustomSelectFieldController";
import DynamicPageView from "views/common/DynamicPageView";
import ti18n from "i18n!nls/StatsPagei18n";
import PageTemplate from 'text!../../../templates/songStatistics/songStatisticsPageTemplate.html';
import RSVP from "rsvp";

const SongStatisticPageController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = options.model;
        this._pageId = options.pageId;
        
    },
    
    _onPageShow : function(){
        
    },
    
    
    _getStatistics : function(trebbleSongId){
        return TrebbleClientAPIHelper.getInstance().getStatisticsForSong(trebbleSongId);
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },
    
    _buildDurationPeriodSelectFieldController : function(){
        const controllerParams = {};
        controllerParams.singleSelect =  true;
        controllerParams.propertyFunction = "getLabel";
        controllerParams.propertyIdFunction = "getId";
        controllerParams.showEmtptyInfoBox  = false;
        controllerParams.loadHandler = SongStatisticsContentView.prototype.getAllSupportedTinePeriods;
        controllerParams.defaultText = window.getI18n(ti18n, "SELECT_A_TIME_PERIOD"); 
        return new CustomSelectFieldController(controllerParams);
    },
    _onTimePeriodChanged : function(timePeriodInfoListItemModel){
        this._currentSelectedTimePeriodModel = timePeriodInfoListItemModel
        this.songStatisticsContentView.setStatisticsPeriod(timePeriodInfoListItemModel?timePeriodInfoListItemModel.getId():null);
    },

    buildModelAndRenderView : function() {
        $.mobile.loading("show");
        this.durationFieldSelectorController = this._buildDurationPeriodSelectFieldController();
        this.listenTo(this.durationFieldSelectorController, "selectionChanged",this._onTimePeriodChanged.bind(this));
        const defaultTimePeriodListItemModel = Utils.getInstance().getListItemModel(SongStatisticsContentView.DEFAULT_TIME_PERIOD_KEY,SongStatisticsContentView.DEFAULT_TIME_PERIOD_LABEL);
        
        return this.durationFieldSelectorController.buildModelAndRenderView().then((function(){
            
            const params = {};
            params.songTrebbleId = this.model.getTrebbleSongId();
            params.periodSelectorView = this.durationFieldSelectorController.getView();
            params.getStatisticsLoadFunction = this._getStatistics;
            const songStatisticsContentView =  new SongStatisticsContentView(params);
            songStatisticsContentView.render();
            this.songStatisticsContentView = songStatisticsContentView;
            this.durationFieldSelectorController.setSelected(defaultTimePeriodListItemModel, true);
            const pageViewParams= {};
            pageViewParams.pageId = this._pageId;
            pageViewParams.pageViewTemplate = PageTemplate;
            pageViewParams.containerSelector = ".ui-content";
            pageViewParams.contentView = songStatisticsContentView;
            pageViewParams.onPageShow = this._onPageShow.bind(this);
            pageViewParams.pageTemplateOptions = {"ti18n":ti18n};
            pageViewParams.ignoreDefaultPageUIContentPadding = true;
            /*pageViewParams.onOrientationChangeCustomHandler = (function(containerContentHeight, page$el){
                const commentBoxHeigh = window.trebble.defaultValues.commentBoxHeigth;
                const contextViewHeight = this._contextController? this._contextController.getView().$el.height(): 0;
                page$el.find("#collection_repeat_container").css("height", containerContentHeight - commentBoxHeigh - contextViewHeight + "px");
                this.collectionRepeatController.getView()._displayInfoBoxIfApplicable();
            }).bind(this);*/
this.view = new DynamicPageView(pageViewParams);
this.view.render();
this.view.$el.on("click", "#header-back-button", this.view.navigateToPreviousPage.bind(this.view));

$.mobile.loading("hide");
if(window.trebbleAnalyticsHelper){
window.trebbleAnalyticsHelper.trackEvent("Song", "SongStatistics", "View Song Statistics");
}

}).bind(this)).catch(function(error){
$.mobile.loading("hide");
throw error;
});

}

});

export default SongStatisticPageController;