import $ from 'jquery';
import _, { times } from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import CapsuleOptionView from "views/capsule/CapsuleOptionView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import SubscriptionManager from "models/helper/SubscriptionManager";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import SequencerRendererUtils  from "models/audioEditor/SequencerRendererUtils"
import ti18n from "i18n!nls/CapsuleCardi18n";
import RSVP from "rsvp";

const Rollout = RolloutHelper.getInstance();
const MAKE_DOWNLOAD_SHORTCAST_AS_PAID_FEATURE = Rollout.isFeatureEnabled(Rollout.FEATURES.MAKE_DOWNLOAD_SHORTCAST_AS_PAID_FEATURE);
const CapsuleOptionController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.model = options? options.capsuleModel : null;
        this.functionToRetrieveDefaultImageUrl = options.functionToRetrieveDefaultImageUrl?options.functionToRetrieveDefaultImageUrl: null;
        this.context = options? options.context : null;
        this.elementSource$el = options? options.elementSource$el : null;
        this.onActionExecutedOnCapsuleHandler = options? options.onActionExecutedOnCapsuleHandler: null;
        this._subContextMenuOpened =  null;
        _.extend(this, Backbone.Events);
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    executeActionCallback :function(action, data){
        if(this.onActionExecutedOnCapsuleHandler){
            this.onActionExecutedOnCapsuleHandler(this.model,action, data);
        }
    },

    showDetails: function(){
        return PersistentModels.getInstance().getRouter().showCapsulePoupForCapsuleId(this.model.getCapsuleId(), this.context, false, true, true);
    },

    _onDownloadTranscriptionTxtFileMenuClicked : async function(includeSpeaker){
        const defaultProgressMessage = `${window.getI18n(ti18n,"GENERATING")} .txt ${window.getI18n(ti18n,"FILE")}...`;
        const operationProgressPopupView = await PersistentModels.getInstance().getRouter().showMessagePopup(defaultProgressMessage, "");
        operationProgressPopupView.setLoadingMessage(defaultProgressMessage);
        operationProgressPopupView.setProgressBarIndeterminate(true);
        const progressReportFunction  = (percentageProgress, progressMessage)=>{
            if(progressMessage){
                operationProgressPopupView.setLoadingMessage(progressMessage);
            }else{
                operationProgressPopupView.setLoadingMessage(defaultProgressMessage);
            }
            if(percentageProgress){
                operationProgressPopupView.setPercentageProgress(percentageProgress);
                operationProgressPopupView.setProgressBarIndeterminate(false);
            }else{
                operationProgressPopupView.setProgressBarIndeterminate(true);
            }
        }
        const textTrancript = await SequencerRendererUtils.getInstance().getTranscriptionTextFile(this.model.getProjectId(), includeSpeaker, progressReportFunction);
        this._triggerFileDownload("text/plain;charset=utf-8", textTrancript ,`${this.model.getTitle()}.txt`);
        this.view.close();
        operationProgressPopupView.close();
        try{
            TrebbleClientAPIHelper.getInstance().trackCapsuleDownload(this.model.getCapsuleId(), null, "textTxtTranscription", this.model.getProjectId());
        }catch(error){
            console.error("failed marking text transcription download. Error"+error);
        }
    },

    _triggerFileDownload :  function(type, fileContent, filename){
        const blob = new Blob([fileContent], {type});
        Utils.getInstance().downloadFile(blob, filename);
    },

    _onDownloadSrtFileMenuClicked : async function(asVtt, doNotIncludeSpeaker){
        const defaultProgressMessage = `${window.getI18n(ti18n,"GENERATING")} ${asVtt?".vtt":".srt"} ${window.getI18n(ti18n,"FILE")}...`
        const operationProgressPopupView = await PersistentModels.getInstance().getRouter().showMessagePopup(defaultProgressMessage, "");
        operationProgressPopupView.setLoadingMessage(defaultProgressMessage);
        operationProgressPopupView.setProgressBarIndeterminate(true);
        const progressReportFunction  = (percentageProgress, progressMessage)=>{
            if(progressMessage){
                operationProgressPopupView.setLoadingMessage(progressMessage);
            }else{
                operationProgressPopupView.setLoadingMessage(defaultProgressMessage);
            }
            if(percentageProgress){
                operationProgressPopupView.setPercentageProgress(percentageProgress);
                operationProgressPopupView.setProgressBarIndeterminate(false);
            }else{
                operationProgressPopupView.setProgressBarIndeterminate(true);
            }
        }
        return SequencerRendererUtils.getInstance().getSrtText(this.model.getProjectId(), asVtt, true, progressReportFunction).then((transcriptionText)=>{
            this._triggerFileDownload("text/plain;charset=utf-8", transcriptionText ,this.model.getTitle()+`${asVtt?'.vtt':'.srt'}`)
            try{
                TrebbleClientAPIHelper.getInstance().trackCapsuleDownload(this.model.getCapsuleId(), null, `text${asVtt?'Vtt':'Srt'}Transcription`, this.model.getProjectId());
                this.view.close();
            }catch(error){
                console.error("failed marking text transcription download. Error"+error);
            }
            operationProgressPopupView.close();
        });
    },

    editDetails : function(){
        const onCapsuleUpdated = function(updatedCapsuleJson){
            const updateCapsuleModel = Utils.getInstance().getModelFromCapsuleJson(updatedCapsuleJson);
            this.model.setTitle(updateCapsuleModel.getTitle());
            this.model.setText(updateCapsuleModel.getText());
        };
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Capsule", "ClickOnOptionToShowShortcastDetailsPage", "Click On Shortcast Option To Show Shortcast Details Page");
        }
        return PersistentModels.getInstance().getRouter().showCapsuleInfoEditPage(this.model.getCapsuleId(), onCapsuleUpdated,  true );
    },

    showComments : function(){
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Capsule", "ClickOnOptionToShowComments", "Click On Shortcast Option To Show Comments");
        }
        if(this.model){
            if(Utils.getInstance().isJQMPopupOpen()){
                Utils.getInstance().closeCurrentJQMPopup().then((function(){
                    return PersistentModels.getInstance().getRouter().showPageCommentsForCapsule(this.model,false,null, this.context, true)
                }).bind(this))
            }else{
                return PersistentModels.getInstance().getRouter().showPageCommentsForCapsule(this.model,false,null, this.context, true)
            }
        }
    },

    shareCapsule : function(){
        if(this.model){
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("Capsule", "ClickOnOptionToShowSharePage", "Click On Shortcast Option To Show Share Page");
            }
            if(Utils.getInstance().isJQMPopupOpen()){
                Utils.getInstance().closeCurrentJQMPopup().then((function(){
                    return PersistentModels.getInstance().getRouter().showSimpleSetCapsuleSharePopup(this.model, this.context);
                }).bind(this))
            }else{
                PersistentModels.getInstance().getRouter().showSimpleSetCapsuleSharePopup(this.model, this.context);
            }

        }
    },

    addCapsuleToTrebble : function(){
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Capsule", "ClickOnOptionToShowAddToTrebblePage", "Click On Shortcast Option To Show Add To Trebble Page");
        }
        return Utils.getInstance().closeCurrentJQMPopup().then((function(){
            return PersistentModels.getInstance().getRouter().addCapsuleToTrebbleViaPopup(this.model,this.context);
        }).bind(this)).then((function(capsuleAdded){

        }).bind(this)).catch((function(error){
            window.alertErrorMessage(error);
        }).bind(this));

    },

    showAnalytics : function(){
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Capsule", "ClickOnOptionToShowAnalyticsPage", "Click On Shortcast Option To Show Analytics Page");
        }
        return PersistentModels.getInstance().getRouter().showCapsuleStatisticsPage(this.model, true);
    },

    removeCapsuleFromTrebble : function(){
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Capsule", "ClickOnOptionToShowRemoveFromTrebblePage", "Click On Shortcast Option To Show Remove From Trebble Page");
        }
        return PersistentModels.getInstance().removeCapsuleFromRadio(this.model, PersistentModels.getInstance().getAllMyPersonalRadioIds(),  this.context).then((function(){
            $.mobile.loading("hide");
        }).bind(this)).catch(function(error){
            $.mobile.loading("hide");
            window.alertErrorMessage(error);
        });
    },

    onVideoCurrentlyBeingRendered : function(){
        window.alertSuccessMessage(window.getI18n(ti18n,"YOUR_VIDEO_IS_BEING_PROCESSED"),window.getI18n(ti18n, "WE_WILL_NOTIFY_WHEN_YOUR_VIDEO_IS_READY_TO_DOWNLOAD"));
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Capsule", "ClickOnOptionVideoBeingRendered", "Click On Option Video Being Rendereded");
        }
    },

    deleteCapsuleConfirm : function(){
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Capsule", "ClickOnOptionToShowDeleteShortcastConfirm", "Click On Shortcast Option To Show Delete Shortcast Confirm");
        }
        const buttonLabels = [window.getI18n(ti18n, "NO"), window.getI18n(ti18n, "YES")];
        const confirmCallback = (function(buttonIndex){
            if(buttonIndex ==  2){
                this.deleteCapsule();
            }
        }).bind(this);
        const message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_CAPSULE");
        navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n, "DELETE_CAPSULE"), buttonLabels,null , null);
    } ,

    deleteCapsule : function(){
        $.mobile.loading('show');
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Capsule", "ClickOnOptionToShowDeleteShortcast", "Click On Shortcast Option To Show Delete Shortcast");
        }
        if(this.model){
            const capsuleId =  this.model.getCapsuleId();
            const actionCallback = (function(actionName, model){
                if(this.onActionExecutedOnCapsuleHandler){
                    this.onActionExecutedOnCapsuleHandler(actionName, model, this);
                }
            }).bind(this);
            TrebbleClientAPIHelper.getInstance().deleteSongCapsule(capsuleId).then((function(){
                if(this.onActionExecutedOnCapsuleHandler){
                    this.onActionExecutedOnCapsuleHandler("deleteCapsule", this.model, this);
                }
                PersistentModels.getInstance().onCapsuleDeleted({"capsuleUID": this.model.getCapsuleId()});
                $.mobile.loading('hide');
            }).bind(this)).catch((function(error){
                $.mobile.loading('hide');
                window.alertErrorMessage(error?error :window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHEN_DELETING_CAPSULE"));
            }).bind(this));

        }
    },

    addToChannel : function(){
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Capsule", "ClickOnOptionToAddShortcastToChannel", "Click On Shortcast Option To Add Shortcast To Channel");
        }
        return PersistentModels.getInstance().getRouter().addCapsuleToTrebbleViaPopup(this.model,this.context).then((function(capsuleAdded){

        }).bind(this)).catch((function(error){
            window.alertErrorMessage(error);
        }).bind(this));

    },

    removeFromChannel : function(){
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Capsule", "ClickOnOptionToRemoveShortcastFromChannel", "Click On Shortcast Option To Remove Shortcast From Channel");
        }
        return PersistentModels.getInstance().removeCapsuleFromRadio(this.model, PersistentModels.getInstance().getAllMyPersonalRadioIds(),  this.context).then((function(){
            $.mobile.loading("hide");
        }).bind(this)).catch(function(error){
            $.mobile.loading("hide");
            window.alertErrorMessage(error);
        });
        
    },

    selectAndAddToDraftJourney : function(){
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Capsule", "ClickOnOptionToAddShortcastToJourney", "Click On Shortcast Option To Add Shortcast To Journey");
        }
        return PersistentModels.getInstance().getRouter().showChooseMyDraftJourneyPopup((function(journeyModelSelected){
            if(journeyModelSelected){
                return PersistentModels.getInstance().addCapsuleToJourney(this.model.getCapsuleId(), journeyModelSelected.getLoaderKey())
            }
        }).bind(this), window.getI18n(ti18n, "ADD_TO_JOURNEY"))
    },

    downloadAudioFile : function(){
        $.mobile.loading("show");
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Capsule", "ClickOnOptionToDownloadShortcast", "Click On Shortcast Option To Download Shortcast", null, {"audioUrlDownloaded": this.model.getAudioHQUrl()?this.model.getAudioHQUrl(): this.model.getAudioUrl()});
        }
        return Utils.getInstance().downloadURI(this.model.getAudioHQUrl()?this.model.getAudioHQUrl(): this.model.getAudioUrl(), this.model.getTitle()).then((function(){
            $.mobile.loading("hide");
            try{
                TrebbleClientAPIHelper.getInstance().trackCapsuleDownload(this.model.getCapsuleId(), this.model.getAudioHQUrl()?this.model.getAudioHQUrl(): this.model.getAudioUrl(), "audio", this.model.getProjectId())
            }catch(error){
                console.error("failed marking download. Error"+error);
            }
        }).bind(this)).catch(function(error){
            $.mobile.loading("hide");
            window.alertErrorMessage(error);
        });
    },

    checkActivePaidSubscriptionBeforeTriggeringDownload : async function(asWav){
        if(MAKE_DOWNLOAD_SHORTCAST_AS_PAID_FEATURE){
            $.mobile.loading("show");
            try{
            const hasActivePaidSubscription  = await SubscriptionManager.getInstance().isCurrentUserSubscribedToTrebbleCreator()
                $.mobile.loading("hide");
                if(hasActivePaidSubscription){
                    return this._generateAndDownlaodAudioFile(asWav);
                }else{
                    return PersistentModels.getInstance().getRouter().showYouveDiscoveredPremiumFeaturePopup(null, null);
                }
            }catch(error){
                $.mobile.loading("hide");
                window.alertErrorMessage(error);
            }
        }else{
            return await this._generateAndDownlaodAudioFile(asWav);
        }
    },

    checkActivePaidSubscriptionBeforeTriggeringDownloadOld : function(){
        if(MAKE_DOWNLOAD_SHORTCAST_AS_PAID_FEATURE){
            $.mobile.loading("show");
            return SubscriptionManager.getInstance().isCurrentUserSubscribedToTrebbleCreator().then((function(hasActivePaidSubscription){
                $.mobile.loading("hide");
                if(hasActivePaidSubscription){
                    return this.downloadAudioFile();
                }else{
                    return PersistentModels.getInstance().getRouter().showYouveDiscoveredPremiumFeaturePopup(null, null);
                }
            }).bind(this)).catch(function(error){
                $.mobile.loading("hide");
                window.alertErrorMessage(error);
            });
        }else{
            return this.downloadAudioFile();
        }
    },

    editAudio: function(){
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Capsule", "ClickOnOptionToShowEditAudioPage", "Click On Shortcast Option To Show Edit Audio Page");
        }
        const onCapsuleSuccessfullyUpdated = (updatedCapsuleJson)=>{
            const updateCapsuleModel = Utils.getInstance().getModelFromCapsuleJson(updatedCapsuleJson);
            this.model.setTitle(updateCapsuleModel.getTitle());
            this.model.setText(updateCapsuleModel.getText());
        };
        return PersistentModels.getInstance().getRouter().showCreateShortcastPageFromEditingExistingCapsuleWithProjectId(this.model.getCapsuleId(), this.model.getProjectId(), true, onCapsuleSuccessfullyUpdated, null, false, null);
    },

    duplicatedAndEditAudio: function(){
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Capsule", "ClickOnOptionToShowEditAndDuplicateAudioPage", "Click On Shortcast Option To Show Edit And Duplicate Audio Page");
        }
        return PersistentModels.getInstance().getRouter().showCreateShortcastPageFromProject(this.model.getProjectId(),true, null, null, null, false, null, null);
    },

    _destroySubContextMenuIfApplicable : function(){
        if(this._subContextMenuOpened){
            this._subContextMenuOpened.destroy();
            this._subContextMenuOpened =  null;
        }
    },

    generateAndDownloadAudioFile: function(downlaodTranscriptMenu$el){
        this._destroySubContextMenuIfApplicable();
        const menuInfoArray = [];
        let contextMenuTippyJSInstance;
        menuInfoArray.push(Utils.getInstance().createMenuInfoItem("generateAndDownloadMp3File", `${window.getI18n(ti18n,"DOWNLOAD_WORD")} .mp3 ${window.getI18n(ti18n,"FILE")}`, (e)=>{this.checkActivePaidSubscriptionBeforeTriggeringDownload(false);contextMenuTippyJSInstance.destroy(); }));
        menuInfoArray.push(Utils.getInstance().createMenuInfoItem("generateAndDownloadWavFile", `${window.getI18n(ti18n,"DOWNLOAD_WORD")} .wav ${window.getI18n(ti18n,"FILE")}`, (e)=>{this.checkActivePaidSubscriptionBeforeTriggeringDownload(true);contextMenuTippyJSInstance.destroy(); }));
        contextMenuTippyJSInstance = Utils.getInstance().createContextMenu(menuInfoArray, downlaodTranscriptMenu$el.get(0),$("body").get(0), "left", true);
        this._subContextMenuOpened = contextMenuTippyJSInstance;
    },

    _generateAndDownlaodAudioFile : async function(asWav){
        const defaultProgressMessage = `${window.getI18n(ti18n,"GENERATING")} ${asWav?"wav":"mp3"}...`;
        const operationProgressPopupView = await PersistentModels.getInstance().getRouter().showMessagePopup(defaultProgressMessage, "");
        operationProgressPopupView.setLoadingMessage(defaultProgressMessage);
        operationProgressPopupView.setProgressBarIndeterminate(true);
        const filename  = `${this.model.getTitle()}.${asWav?"wav":"mp3"}`;
      
        const progressReportFunction  = (percentageProgress, progressMessage)=>{
            if(progressMessage){
                operationProgressPopupView.setLoadingMessage(progressMessage);
            }else{
                operationProgressPopupView.setLoadingMessage(defaultProgressMessage);
            }
            if(percentageProgress){
                operationProgressPopupView.setPercentageProgress(percentageProgress);
                operationProgressPopupView.setProgressBarIndeterminate(false);
            }else{
                operationProgressPopupView.setProgressBarIndeterminate(true);
            }
        }
        const downloadProgressFunction = (progressUnderOne, progressMessage) =>{
            return progressReportFunction(progressUnderOne * 100, progressMessage);
        }
        const existinAudioFileUrl = asWav? this.model.getAudioHQUrl(true): this.model.getAudioUrl();
        if(existinAudioFileUrl){
            await Utils.getInstance().downloadURI(existinAudioFileUrl, filename, downloadProgressFunction) ;   
            operationProgressPopupView.close();
            try{
                TrebbleClientAPIHelper.getInstance().trackCapsuleDownload(this.model.getCapsuleId(), this.model.getAudioHQUrl()?this.model.getAudioHQUrl(): this.model.getAudioUrl(), "audio", this.model.getProjectId())
            }catch(error){
                console.error("failed marking download. Error"+error);
            }
        }else{
            const audioBlob = await SequencerRendererUtils.getInstance().getRenderedAudioBlob(this.model.getProjectId(), asWav, false, progressReportFunction, null);
            Utils.getInstance().downloadFile(audioBlob, filename);
            operationProgressPopupView.close();
        }

    },


    downloadTranscriptionFile: function(downlaodTranscriptMenu$el){
        this._destroySubContextMenuIfApplicable();
        const menuInfoArray = [];
        let contextMenuTippyJSInstance;
        menuInfoArray.push(Utils.getInstance().createMenuInfoItem("generateAndDownloadTxtFile", `${window.getI18n(ti18n,"DOWNLOAD_WORD")} .txt ${window.getI18n(ti18n,"FILE")}`, (e)=>{this._onDownloadTranscriptionTxtFileMenuClicked(true);contextMenuTippyJSInstance.destroy(); }));
        menuInfoArray.push(Utils.getInstance().createMenuInfoItem("generateAndDownloadSrt",  `${window.getI18n(ti18n,"DOWNLOAD_WORD")} .srt ${window.getI18n(ti18n,"FILE")}`, (e)=>{this._onDownloadSrtFileMenuClicked(false, true);contextMenuTippyJSInstance.destroy(); }));
        menuInfoArray.push(Utils.getInstance().createMenuInfoItem("generateAndDownloadVtt", `${window.getI18n(ti18n,"DOWNLOAD_WORD")} .vtt ${window.getI18n(ti18n,"FILE")}`, (e)=>{this._onDownloadSrtFileMenuClicked(true, true);contextMenuTippyJSInstance.destroy();}));
        contextMenuTippyJSInstance = Utils.getInstance().createContextMenu(menuInfoArray, downlaodTranscriptMenu$el.get(0),$("body").get(0), "left", true);
        this._subContextMenuOpened = contextMenuTippyJSInstance;
    },

    downloadTranscriptionFileOld: function(){
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Capsule", "ClickOnOptionToDownloadTrancript", "Click On Shortcast Option To Download Transcript", null,{"transcriptDownloadedForProjectId": this.model.getProjectId()});
        }
        $.mobile.loading("show");
        return TrebbleClientAPIHelper.getInstance().getSequencerProjectTexTranscription(this.model.getProjectId()).then((function(transcriptionText){
            const blob = new Blob([Utils.getInstance().addNewlineAfterNSentences(transcriptionText, 3, true)], {type: "text/plain;charset=utf-8"});
            Utils.getInstance().downloadFile(blob, this.model.getTitle()+".txt");
            $.mobile.loading("hide");
            try{
                TrebbleClientAPIHelper.getInstance().trackCapsuleDownload(this.model.getCapsuleId(), null, "textTxtTranscription", this.model.getProjectId())
            }catch(error){
                console.error("failed marking text transcription download. Error"+error);
            }
        }).bind(this)).catch((function(error){
            return TrebbleClientAPIHelper.getInstance().getSequencerProjectTexTranscription(this.model.getProjectId(), true).then((function(transcriptionText){
                const blob = new Blob([transcriptionText], {type: "text/plain;charset=utf-8"});
                Utils.getInstance().downloadFile(blob, this.model.getTitle()+".txt");
                $.mobile.loading("hide");
            }).bind(this))
        }).bind(this))
    },

    downloadVideoFile: async function(){
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Capsule", "ClickOnOptionToDownloadVideo", "Click On Shortcast Option To Download Video", null, {"videoUrlDownloaded": this.model.getVideoUrl()});
        }
        const defaultProgressMessage = `${window.getI18n(ti18n,"GENERATING_VIDEO")}`;
        const operationProgressPopupView = await PersistentModels.getInstance().getRouter().showMessagePopup(defaultProgressMessage, "");
        operationProgressPopupView.setLoadingMessage(defaultProgressMessage);
        operationProgressPopupView.setProgressBarIndeterminate(false);
        const progressReportFunction  = (percentageProgress, progressMessage)=>{
            if(progressMessage){
                operationProgressPopupView.setLoadingMessage(progressMessage);
            }else{
                operationProgressPopupView.setLoadingMessage(defaultProgressMessage);
            }
            if(percentageProgress){
                operationProgressPopupView.setPercentageProgress(percentageProgress);
                operationProgressPopupView.setProgressBarIndeterminate(false);
            }else{
                operationProgressPopupView.setProgressBarIndeterminate(true);
            }
        }
        const downloadProgressFunction = (progressUnderOne, progressMessage) =>{
            return progressReportFunction(progressUnderOne * 100, progressMessage);
        }
        await  Utils.getInstance().downloadURI(this.model.getVideoUrl(), this.model.getTitle(), downloadProgressFunction)
        TrebbleClientAPIHelper.getInstance().trackCapsuleDownload(this.model.getCapsuleId(),  this.model.getVideoUrl(), "videoDownload", this.model.getProjectId())
        operationProgressPopupView.close();
        
    },

    onContextMenuClosed : function(){
        this._destroySubContextMenuIfApplicable()
    }, 


    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {

                this.view = new CapsuleOptionView({
                    "model" : this.model,
                    "elementSource$el": this.elementSource$el,
                    defaultImageUrl : this.functionToRetrieveDefaultImageUrl ? this.functionToRetrieveDefaultImageUrl(): null
                });
                this.listenTo(this.view, "editDetails",this.editDetails);
                this.listenTo(this.view, "duplicatedAndEditAudio",this.duplicatedAndEditAudio);
                this.listenTo(this.view, "editAudio",this.editAudio);
                this.listenTo(this.view, "showAnalytics", this.showAnalytics);
                this.listenTo(this.view, "deleteCapsule",this.deleteCapsuleConfirm);
                this.listenTo(this.view, "addCapsuleToTrebble",this.addCapsuleToTrebble);
                this.listenTo(this.view, "removeCapsuleFromTrebble",this.removeCapsuleFromTrebble);
                this.listenTo(this.view, "share",this.shareCapsule);
                this.listenTo(this.view, "showComments",this.showComments);
                this.listenTo(this.view, "addToChannel",this.addToChannel);
                this.listenTo(this.view, "removeFromChannel",this.removeFromChannel);
                this.listenTo(this.view, "downloadAudioFile",this.generateAndDownloadAudioFile);
                this.listenTo(this.view, "downloadTranscriptionFile",this.downloadTranscriptionFile);
                this.listenTo(this.view, "downloadVideoFile",this.downloadVideoFile);
                this.listenTo(this.view, "selectAndAddToDraftJourney",this.selectAndAddToDraftJourney);
                this.listenTo(this.view, "videoCurrentlyBeingRendered",this.onVideoCurrentlyBeingRendered);
                this.listenTo(this.view, "closed",this.onContextMenuClosed);

                this.view.render();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default CapsuleOptionController;