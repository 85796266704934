import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import AutoPlaySuggestionPopupView from "views/autoPlaySuggestion/AutoPlaySuggestionPopupView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import ti18n from "i18n!nls/AutoPlayTrebbleAfterSharedCapsulePopupi18n";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";
import FacebookHelper from "models/helper/FacebookHelper";

const AutoPlayTrebbleAfterSharedCapsulePopupController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.userInfoJson = options.userInfoJson;
        this.trebbleInfoSummaryJson =  options.trebbleInfoSummaryJson;
        this.capsuleId = options.capsuleId;
        this.songId = options.songId;
        _.extend(this, Backbone.Events);
    },

    _getShareableLink : function(){
        if(this.capsuleId){
            return "https://s.trebble.fm/p/p/c/"+this.capsuleId;
        }else{
            if(this.songId){
                return "https://s.trebble.fm/p/p/s/"+this.songId;
            }
        } 
    },




    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    

    shareOnFacebook : function(){
        const trackingData = this.capsuleId? {"entity":"Capsule", "eventName":"CapsuleShareFacebook", "eventLabel":"Capsule URL Shared On Facebook"}: {"entity":"Song", "eventName":"SongShareFacebook", "eventLabel":"Song URL Shared On Facebook"};
        FacebookHelper.getInstance().showDialog({
            method: 'share',
            href: this._getShareableLink(),
        },trackingData ).catch(function(error){
            window.alertErrorMessage(error);
        })
        /*FB.ui({
            method: 'share',
            href: this._getShareableLink(),
        }, function(response){
            if(response && response.error_message){
                window.alertErrorMessage(response.error_message);
            }
        });*/
    },

    shareOnTwitter : function(){
        const w = 550;
        const h = 285;
        const left = window.getLeftPositionForPopupWindow(w);
        const top = window.getTopPositionForPopupWindow(h);
        const openedWindow = window.open("https://twitter.com/intent/tweet?via=trebblefm&url="+encodeURIComponent(this._getShareableLink()),"Twitter", "height="+h+",width="+w+",resizable=1,top="+top+",left="+left+"");
        if((!openedWindow || openedWindow.closed || typeof openedWindow.closed=='undefined') && !window.waitForCordovaToLoad){
            window.alertErrorMessage(window.getI18n(ti18n,"PLEASE_ALLOW_POPUPS"));
        }
    },
    
    replyToFinishedPlayedContent : function(){

    },

    subscribeToFinishedPlayedOwnerContent  : function(){

    },

    _buildAutoPlayedTrebbleContext : function(){
        if(this.capsuleId){
            return Utils.getInstance().buildContextForAutoplayTrebbleSuggestionFromCapsule(this.capsuleId);
        }else{
            if(this.songId){
                return Utils.getInstance().buildContextForAutoplayTrebbleSuggestionFromSong(this.capsuleId);
            }else{
                throw window.getI18n(ti18n, "OOPS_SOMETHING_WENT_WRONG");
            }
        }
    },

    playSuggestedContent : function(){
        try{
            PersistentModels.getInstance().getRouter().changeHashAndPlayTrebble(this.trebbleInfoSummaryJson.loaderKey, this._buildAutoPlayedTrebbleContext());
            this.view.close();
        }catch(e){
            console.error(e);
        }

    },

    onPopupClosed : function(){
        this.view.cancelAutoPlay();
    },

    _getEntityNameShared :function(){
        if(this.capsuleId){
            return window.getI18n(ti18n,"CAPSULE");
        }else{
            if(this.songId){
                return window.getI18n(ti18n,"SONG");
            }else{
                throw window.getI18n(ti18n,"OOPS_SOMETHING_WENT_WRONG");
            }
        }
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {

                const params = {};
                params.contentUserAvatarUrl = this.userInfoJson.profileImageUrl;
                params.contentUserOwnerUsername =  this.userInfoJson.username;
                params.entityNameShared = this._getEntityNameShared();
                params.autoplayTimeInSeconds= 8;
                params.disableAutoplay = this.trebbleInfoSummaryJson.numberOfSongs < Utils.getInstance().getDefaultNumberOfSongsRequiredToPlayATrebble();
                params.toPlayNextTitle = this.trebbleInfoSummaryJson.name;
                params.contentIdToAutoPlay = this.trebbleInfoSummaryJson.loaderKey;
                this.view = new AutoPlaySuggestionPopupView(params);
                this.listenTo(this.view, "shareFinishedPlayedContentOnFacebook",this.shareOnFacebook);
                this.listenTo(this.view, "shareFinishedPlayedContentOnTwitter",this.shareOnTwitter);
                this.listenTo(this.view, "replyToFinishedPlayedContent",this.replyToFinishedPlayedContent);
                this.listenTo(this.view, "subscribeToFinishedPlayedOwnerContent",this.subscribeToFinishedPlayedOwnerContent);
                this.listenTo(this.view, "playSuggestedContent",this.playSuggestedContent);
                this.listenTo(this.view, "closed",this.onPopupClosed);

                this.view.render();
                resolve();


            } catch (error) {
                reject(error);
            }

        }).bind(this));

}

});

export default AutoPlayTrebbleAfterSharedCapsulePopupController;