import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import NotificationCardController from "controllers/common/NotificationCardController";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import Utils from "models/helper/Utils";
import OptionPopupView from "views/common/OptionPopupView";
import RSVP from "rsvp";

const NotificationPopupController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.model = options.model;
        this.context = options.context;
        this._showActionButton = options? options.showActionButton : false;
        this._actionButtonLabel = options? options.actionButtonLabel : null;
        this._actionButtonClickHandler = options? options.actionButtonClickHandler : null;
        this._customEventHandler = options? options.customViewEventHandler : null;
        this._customTemplate = options? options.customTemplate: null;
        const notificationCardControllerParams = {};
        notificationCardControllerParams.model = this.model;
        notificationCardControllerParams.showActionButton = this._showActionButton;
        notificationCardControllerParams.actionButtonLabel = this._actionButtonLabel;
        notificationCardControllerParams.actionButtonLabel = this._actionButtonLabel;
        notificationCardControllerParams.customTemplate = this._customTemplate;
        notificationCardControllerParams.customEventHandler = this._customEventHandler;
        this.notificationCardController = new NotificationCardController(notificationCardControllerParams);
        _.extend(this, Backbone.Events);
    },
    


    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    onViewRemoved:function(){
        this.stopListening();
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {

                this.notificationCardController.buildModelAndRenderView().then((function(){
                    const params= {};
                    params.contentView = this.notificationCardController.getView();
                    params.contentPosition = "center";
                    params.id = "notificationDetailsPopup";
                    params.addContentAfterEnhancement = true;
                    this.view = new OptionPopupView(params);
                    this.view.render();
                    this.listenTo(this.view, "remove", this.onViewRemoved.bind(this));
                    resolve();
                }).bind(this)).catch(reject);
                
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default NotificationPopupController;