import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import RSVP from "rsvp";
import VoiceActivityDetectionHelper from "models/audioEditor/VoiceActivityDetectionHelper";


const CLASSNAME =  "SequencerNode";
const SequencerNode =  Backbone.Model.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        this._nextSequencerNode = null;
        this._previousSequencerNode = null;
        this._audioSegment = attributes.audioSegment;
        this._startWrapNodes = [];
        this._endWrapNodes = [];
        this._audioFilters = [];
        this._uniqueNameToBuiltInFilter = {};
        Backbone.Model.apply(this, [attributes, options]);
        if(attributes.sequencer){
            attributes.sequencer.addToSequencerNodeCreated(this);
            this.setSequencer(attributes.sequencer);
        }
        this._listenToChangesOnAudioSegment();
    },

    getAudioSegment : function(){
        return this._audioSegment;
    },

    getSerializedData : function(){
        const stateToReturn  = this.toJSON();
        stateToReturn._cid =  this.cid;
        if(stateToReturn.audioSegment && stateToReturn.audioSegment.getSerializedData){
            stateToReturn.audioSegment = stateToReturn.audioSegment.getSerializedData();
        }
        stateToReturn._startWrapNodesCid = [];
        stateToReturn._endWrapNodesCid = [];
        if(this.getStartWrapNodes() && this.getStartWrapNodes().length >0){
            for(let i =0; i < this.getStartWrapNodes().length; i++){
                const startWrapNode = this.getStartWrapNodes()[i];
                if(startWrapNode){
                    stateToReturn._startWrapNodesCid.push(startWrapNode.cid);
                }
            }
        }
        if(this.getEndWrapNodes() && this.getEndWrapNodes().length >0){
            for(let i =0; i < this.getEndWrapNodes().length; i++){
                const endWrapNode = this.getEndWrapNodes()[i];
                if(endWrapNode){
                    stateToReturn._endWrapNodesCid.push(endWrapNode.cid);
                }
            }
        }
        stateToReturn.audioFilters = [];
        for(let i =0; i < this._audioFilters.length; i++){
            const af = this._audioFilters[i];
            stateToReturn.audioFilters.push(af.getSerializedData());
        }
        stateToReturn.builtInFiltersUniqueNames = [];
        for(let uniquename in this._uniqueNameToBuiltInFilter ){
            stateToReturn.builtInFiltersUniqueNames.push(uniquename);
        }


        delete stateToReturn.sequencer;
        return stateToReturn;
    },

    restoreFromSerializedData: function(serializedData, audioSegmentFactoryInstance, audioFilterFactoryInstance, additionalSpeakerInfoArray){
        const promises = []
        this.set(serializedData);
        this._serializedData = serializedData;
        promises.push(audioSegmentFactoryInstance.createAudioSegmentFromSerializedData(serializedData.audioSegment, additionalSpeakerInfoArray, this.getSequencer()?this.getSequencer().generateSpeakerLabelIfDuplicate.bind(this): null).then((function(audioSegmentInstanceCreated){
            this.setAudioSegment(audioSegmentInstanceCreated);
        }).bind(this)));
        
        this._startWrapNodes = [];
        this._endWrapNodes = [];
        this._audioFilters = [];
        this._uniqueNameToBuiltInFilter = {};
        const nameToAudioFilter = {};
        if(serializedData.audioFilters){
            for(let i =0; i < serializedData.audioFilters.length; i++){
                const afSerializedData = serializedData.audioFilters[i];
                const af = audioFilterFactoryInstance.createAudioFilterFromSerializedData(afSerializedData);
                if(af.getName()){
                    nameToAudioFilter[af.getName()] =  af;
                }
                this._audioFilters.push(af);
            }
        }
        if(serializedData.builtInFiltersUniqueNames){
            for(let i =0; i < serializedData.builtInFiltersUniqueNames.length; i++){
                const builtInFilterName = serializedData.builtInFiltersUniqueNames[i];
                if(nameToAudioFilter[builtInFilterName]){
                    this._uniqueNameToBuiltInFilter[builtInFilterName] = nameToAudioFilter[builtInFilterName];
                }
            }
        }
        return RSVP.Promise.all(promises);
    },

    afterAllInstancesCreatedFromSerializedData : function(serializedData, sequencerNodeCidToSequencerNodeMap){
        if(serializedData._startWrapNodesCid){
            for(let i =0; i < serializedData._startWrapNodesCid.length; i++){
                const nodeCid = serializedData._startWrapNodesCid[i];
                if(nodeCid){
                    const sequencerNode = sequencerNodeCidToSequencerNodeMap[nodeCid];
                    if(sequencerNode){
                        this.addStartWrapNode(sequencerNode);
                    }
                }
            }
        }
        if(serializedData._endWrapNodesCid){
            for(let i =0; i < serializedData._endWrapNodesCid.length; i++){
                const nodeCid = serializedData._endWrapNodesCid[i];
                if(nodeCid){
                    const sequencerNode = sequencerNodeCidToSequencerNodeMap[nodeCid];
                    if(sequencerNode){
                        this.addEndWrapNode(sequencerNode);
                    }
                }
            }
        }
        const nextNode = sequencerNodeCidToSequencerNodeMap[serializedData._nextSequenceNodeCid];
        if(nextNode){
            this.setNext(nextNode);
        }
    },

    _stopListeningToAudioSegmentChanges : function(){
        const audioSegment = this.get("audioSegment");
        if(audioSegment && audioSegment instanceof Backbone.Model){
            this.stopListening(audioSegment, "change");
        }
    },

    _listenToChangesOnAudioSegment : function(){
        const audioSegment = this.get("audioSegment");
        if(audioSegment){
            this.listenTo(audioSegment, 'change', (function(){
                this.trigger("change:audioSegment", this);
            }).bind(this));
        }
    },

    setAudioSegment : function(newAudioSegment){
        this._stopListeningToAudioSegmentChanges();
        this._audioSegment = newAudioSegment;
        this.set("audioSegment",newAudioSegment);
        this._listenToChangesOnAudioSegment();
        
    },

    cloneJSON: function(){
        const objJson = {};
        objJson.sequencer = this.get("sequencer");
        objJson.applyCrossfadeAtTheBegining = this.get("applyCrossfadeAtTheBegining");
        objJson.applyCrossfadeAtTheEnd = this.get("applyCrossfadeAtTheEnd");
        objJson.applyCrossfadeAtTheBeginingDurationInMilliseconds =  this.get("applyCrossfadeAtTheBeginingDurationInMilliseconds");
        objJson.applyCrossfadeAtTheBeginingDurationInMilliseconds =  this.get("applyCrossfadeAtTheEndDurationInMilliseconds");
        objJson.audioSegment = this.getAudioSegment().clone();
        objJson.type = SequencerNode.CLASSNAME;
        return objJson;
    },

    getClassifiedAudioCategories: function(){
        if(this.getAudioSegment() && this.getAudioSegment().getStartTime() >=0 && this.getAudioSegment().getEndTime() >=0 && this.getAudioSegment().getAudioUrl()){
            const classificationResults = this.getSequencer().getAudioBufferCache().getClassificationResultsForAudioUrl(this.getAudioSegment().getAudioUrl());
            if(classificationResults){
                return classificationResults.getClassifications(this.getAudioSegment().getStartTime(), this.getAudioSegment().getEndTime());
            }
        }
        return [];
    },

    getVoiceAcitivityInfo : function(){
        return this.getSequencer().getAudioBufferCache().getVoiceActivityDetectionResultsForAudioUrl(this.getAudioSegment().getAudioUrl());
    },

    containsVoiceActivity : function(){
        return VoiceActivityDetectionHelper.getInstance().containsVoiceActivity(this.getVoiceAcitivityInfo(),this.getAudioSegment().getStartTime(), this.getAudioSegment().getEndTime());
    },


    afterInstanceCreatedFromConstructorForCloning : function(createdClonedInstance, sequencerNodeCidToCloneNodeMap){

    },

    /*cloneUntil : function(sequencerNodeToStopCloningAt, sequencerNodeCidToCloneNodeMap){
        if(!sequencerNodeCidToCloneNodeMap){
            sequencerNodeCidToCloneNodeMap = {}
        }
        const existingClone  = sequencerNodeCidToCloneNodeMap[this.cid];
        if(existingClone){
            return existingClone;
        }
        const nextNode = this.getNext();
        console.log("type ");
        const clonedNextNode = sequencerNodeToStopCloningAt === this || !nextNode? null: nextNode.cloneUntil(sequencerNodeToStopCloningAt, sequencerNodeCidToCloneNodeMap)
        const contructorClass = this.getConstructor();
        const toReturn =  new contructorClass(this.cloneJSON(),null, true, this, sequencerNodeCidToCloneNodeMap);
        sequencerNodeCidToCloneNodeMap[this.cid] = toReturn;
        this.afterInstanceCreatedFromConstructorForCloning(toReturn, sequencerNodeCidToCloneNodeMap);
        if(clonedNextNode){
            toReturn.insertBefore(clonedNextNode);
        }
        return toReturn;
    },*/

    getAudioSegmentJson : function(){
        return this._audioSegment? this._audioSegment.toJSON(): null;
    },

    setAudioSegmentJson : function(newAudioSegmentJson){
        this._audioSegment? this._audioSegment.set(newAudioSegmentJson): null;
    },

    getCid : function(){
        return this.cid;
    },

    setSequencer : function(sequencer){
        if(this.getSequencer() && this.getSequencer().getSequencerSettings()){
            //this.stopListening(this.getSequencer().getSequencerSettings());
        }
        if(sequencer){
            this.set("sequencer", sequencer);
            /*this.listenTo(this.getSequencer().getSequencerSettings(), "change:showGapsBetweenWords change:showIgnoredContent",  (function(){
                this.trigger("sequencerViewSettingsChange", this);
            }).bind(this));*/
        }
    },

    getSequencer : function(){
        return this.get("sequencer");
    },

    addToSequencer: function(){
        this.setAddedToSequencer(true);
        this.getSequencer().add(this);
    },

    removeFromSequencer: function(){
        this.setAddedToSequencer(false);
        this.getSequencer().remove(this);
    },

    setInsertedByPasting : function(insertedByPasting){
        this.set("insertedByPasting", insertedByPasting)
    },

    getInsertedByPasting: function(){
        return this.get("insertedByPasting");
    },

    isThereEditBoundaryAtBeginning: function(){
        return !!this.getSequencerNodeToCrossfadeAtTheBeginingWith(true);
    },

    isThereEditBoundaryAtBeginningAndIsSegmentLargeEnoughForCrossfade : function(){
        return !!this.getSequencerNodeToCrossfadeAtTheBeginingWith(false);
    },

    getSequencerNodeToCrossfadeAtTheBeginingWith : function(doNotCheckMinimumDurationForNeighborNode){
        if(!this.canLetFadeIfNeighborNodesAreDifferentFromOriginal()){
            return null;
        }
        let previousSequencerNodeToCrossfeadeAtTheEndWith = null;
        let node = this.getPrevious();
        if(this.getAudioSegment() && this.getAudioSegment().getTranscribedAudioInstance()){
            const minimumDurationInMillisecondsForCrossfade  = this.getApplyCrossfadeAtTheBeginingDurationInMilliseconds() + this.getApplyCrossfadeAtTheEndDurationInMilliseconds();
            if(!doNotCheckMinimumDurationForNeighborNode && (minimumDurationInMillisecondsForCrossfade > 0 && minimumDurationInMillisecondsForCrossfade >= this.getAudioSegment().getDuration())){
                return null;
            }
            while(node != null){
                if(node.getAudioSegment() && node.getAudioSegment().getDuration() > 0){
                    if(node.needAutoCrossfadeIfNeighborNodesAreDifferentFromOriginal()){
                        previousSequencerNodeToCrossfeadeAtTheEndWith = node;
                    }
                    node = null;
                }else{
                    node = node.getPrevious();
                }
                
            }
            if(previousSequencerNodeToCrossfeadeAtTheEndWith){
                const audioSegment = previousSequencerNodeToCrossfeadeAtTheEndWith.getAudioSegment();
                const areNextToEachOther = this.getAudioSegment().getTranscribedAudioInstance().areAudioSegmentTheClosestToEachOther(audioSegment, this.getAudioSegment());
                if(!areNextToEachOther){
                    if(!doNotCheckMinimumDurationForNeighborNode && (minimumDurationInMillisecondsForCrossfade > 0 && minimumDurationInMillisecondsForCrossfade >= audioSegment.getDuration())){
                        return null;
                    }
                    return previousSequencerNodeToCrossfeadeAtTheEndWith;
                }
            }
        }else{
            return null;
        }

    },

    isThereContinuityOnLeft : function(){
        const previousNode = this.getPreviousSequencerNodeWithDuration();
        const continuityWithPreviousNodeWithDuration =  previousNode && previousNode.getAudioSegment() && this.getAudioSegment().getTranscribedAudioInstance()? this.getAudioSegment().getTranscribedAudioInstance().areAudioSegmentTheClosestToEachOther(previousNode.getAudioSegment(), this.getAudioSegment()):true;
        if(!continuityWithPreviousNodeWithDuration){
            return continuityWithPreviousNodeWithDuration;
        }else{
            if(previousNode && !this.getSequencer().isSequencerNodeVisible(previousNode)){
                return previousNode.isThereContinuityOnLeft();
            }
        }
        return true;
    },

    isThereContinuityOnRight : function(){
        const nextNode = this.getNextSequencerNodeWithDuration();
        const continuityWithNextNodeWithDuration = nextNode && nextNode.getAudioSegment() && this.getAudioSegment().getTranscribedAudioInstance()? this.getAudioSegment().getTranscribedAudioInstance().areAudioSegmentTheClosestToEachOther(this.getAudioSegment(), nextNode.getAudioSegment()) : true;
        if(!continuityWithNextNodeWithDuration){
            return continuityWithNextNodeWithDuration;
        }else{
            if(nextNode && !this.getSequencer().isSequencerNodeVisible(nextNode)){
                return nextNode.isThereContinuityOnRight();
            }
        }
        return true;
    },



    getNextSequencerNodeWithDuration : function(){
        /*const nextNode = this.getNext();
        const nodeToReturn = null;
        while(nextNode != null && !nodeToReturn){
            if(nextNode.getAudioSegment() && nextNode.getAudioSegment().getDuration() > 0){
                nodeToReturn = nextNode;
            }else{
                nextNode = nextNode.getNext();
            }

        }
        return nodeToReturn;*/
        return this.getNextNodeMatchingCondition((function(nextNode){
            return nextNode.getAudioSegment() && nextNode.getAudioSegment().getDuration() > 0
        }).bind(this));
    },

    getPreviousSequencerNodeWithDuration : function(){
        /*const previousNode = this.getPrevious();
        const nodeToReturn = null;
        while(previousNode != null && !nodeToReturn){
            if(previousNode.getAudioSegment() && previousNode.getAudioSegment().getDuration() > 0){
                nodeToReturn = previousNode;
            }else{
                previousNode = previousNode.getPrevious();
            }

        }
        return nodeToReturn;*/
        return this.getPreviousNodeMatchingCondition((function(previousNode){
            return previousNode.getAudioSegment() && previousNode.getAudioSegment().getDuration() > 0
        }).bind(this));
    },

    isThereEditBoundaryAtEnd: function(){
        return !!this.getSequencerNodeToCrossfadeAtTheEndWith(true);
    },

    isThereEditBoundaryAtEndAndIsSegmentLargeEnoughForCrossfade: function(){
        return !!this.getSequencerNodeToCrossfadeAtTheEndWith(false);
    },

    getSequencerNodeToCrossfadeAtTheEndWith : function(doNotCheckMinimumDurationForNeighborNode){
        if(!this.canLetFadeIfNeighborNodesAreDifferentFromOriginal()){
            return null;
        }
        let nextSequencerNodeToCrossfeadeAtTheEndWith = null;
        let node = this.getNext();
        if(this.getAudioSegment() && this.getAudioSegment().getTranscribedAudioInstance()){
            const minimumDurationInMillisecondsForCrossfade  = this.getApplyCrossfadeAtTheBeginingDurationInMilliseconds() + this.getApplyCrossfadeAtTheEndDurationInMilliseconds();
            if(!doNotCheckMinimumDurationForNeighborNode && (minimumDurationInMillisecondsForCrossfade > 0 && minimumDurationInMillisecondsForCrossfade >= this.getAudioSegment().getDuration())){
                return null;
            }
            while(node != null){
                if(node.getAudioSegment() && node.getAudioSegment().getDuration() > 0){
                    if(node.needAutoCrossfadeIfNeighborNodesAreDifferentFromOriginal()){
                        nextSequencerNodeToCrossfeadeAtTheEndWith = node;
                    }
                    node = null;
                }else{
                    node = node.getNext();
                }
                
            }
            if(nextSequencerNodeToCrossfeadeAtTheEndWith){
                const audioSegment = nextSequencerNodeToCrossfeadeAtTheEndWith.getAudioSegment();
                const areNextToEachOther = this.getAudioSegment().getTranscribedAudioInstance().areAudioSegmentTheClosestToEachOther(this.getAudioSegment(), audioSegment);
                if(!areNextToEachOther){
                    if(!doNotCheckMinimumDurationForNeighborNode && (minimumDurationInMillisecondsForCrossfade > 0 && minimumDurationInMillisecondsForCrossfade >= audioSegment.getDuration())){
                        return null;
                    }
                    return nextSequencerNodeToCrossfeadeAtTheEndWith;
                }
            }
        }else{
            return null;
        }
    },

    getApplyCrossfadeAtTheBegining : function(){
        const applyCrossfadeAtTheBeginingValue = this.get("applyCrossfadeAtTheBegining");
        if(applyCrossfadeAtTheBeginingValue == true || applyCrossfadeAtTheBeginingValue == false){
            return applyCrossfadeAtTheBeginingValue;
        }else{
            return null;
        }
    },

    getApplyCrossfadeAtTheEnd : function(){
        const applyCrossfadeAtTheEnd = this.get("applyCrossfadeAtTheEnd");
        if(applyCrossfadeAtTheEnd == true || applyCrossfadeAtTheEnd == false){
            return applyCrossfadeAtTheEnd;
        }else{
            return null;
        }
    },

    setApplyCrossfadeAtTheBegining : function(applyCrossfadeAtTheBegining){
        this.set("applyCrossfadeAtTheBegining",applyCrossfadeAtTheBegining);
    },

    setApplyCrossfadeAtTheEnd : function(applyCrossfadeAtTheEnd){
        this.set("applyCrossfadeAtTheEnd",applyCrossfadeAtTheEnd);
    },

    getApplyCrossfadeAtTheBeginingDurationInMilliseconds : function(){
        const applyCrossfadeAtTheBeginingDurationInMilliseconds = this.get("applyCrossfadeAtTheBeginingDurationInMilliseconds");
        if(applyCrossfadeAtTheBeginingDurationInMilliseconds > 0){
            return applyCrossfadeAtTheBeginingDurationInMilliseconds;
        }else{
            return 0;
        }
    },

    getApplyCrossfadeAtTheEndDurationInMilliseconds : function(){
        const applyCrossfadeAtTheEndDurationInMilliseconds = this.get("applyCrossfadeAtTheEndDurationInMilliseconds");
        if(applyCrossfadeAtTheEndDurationInMilliseconds > 0){
            return applyCrossfadeAtTheEndDurationInMilliseconds;
        }else{
            return 0;
        }
    },
    
    setApplyCrossfadeAtTheBeginingDurationInMilliseconds : function(applyCrossfadeAtTheBeginingDurationInMilliseconds){
        this.set("applyCrossfadeAtTheBeginingDurationInMilliseconds",applyCrossfadeAtTheBeginingDurationInMilliseconds);
    },

    setApplyCrossfadeAtTheEndDurationInMilliseconds : function(applyCrossfadeAtTheEndDurationInMilliseconds){
        this.set("applyCrossfadeAtTheEndDurationInMilliseconds",applyCrossfadeAtTheEndDurationInMilliseconds);
    },




    setNext : function(sequencerNode){
        if(!this._nextSequencerNode){
            this._nextSequencerNode = sequencerNode;
            this.set("_nextSequenceNodeCid", sequencerNode? sequencerNode.cid: null);
            sequencerNode._setPrevious(this);
        }else{
            throw "next node is already set and need to be cleared first"
        }
        this.trigger("nextNodeChange");
    },

    getNextSelectable :function(){
        /*const nextNode = this.getNext();
        const nextSelectable = null;
        while(nextNode && !nextSelectable){
            if(nextNode.getSelectable()){
                nextSelectable = nextNode;
            }else{
                nextNode = nextNode.getNext();
            }
        }
        return nextSelectable;*/

        return this.getNextNodeMatchingCondition((function(nextNode){
            return nextNode.getSelectable();
        }).bind(this));

    },


    getPreviousSelectable :function(){
        /*const previousNode = this.getPrevious();
        const previousSelectable = null;
        while(previousNode && !previousSelectable){
            if(previousNode.getSelectable()){
                previousSelectable = previousNode;
            }else{
                previousNode = previousNode.getPrevious();
            }
        }
        return previousSelectable;*/

        return this.getPreviousNodeMatchingCondition((function(previousNode){
            return previousNode.getSelectable();
        }).bind(this));

    },

    getContent : function(addSpaceAroundContent){
        return this.getAudioSegment(addSpaceAroundContent)? this.getAudioSegment().getContent(addSpaceAroundContent): null;
    },

    getSelectable : function(){
        return !this.get("not_selectable")
    },

    setSelectable : function(selectable){
        this.set("not_selectable",!selectable)
    },

    getBeingProcessed : function(){
        return !!this.get("beingProcessed")
    },

    setBeingProcessed : function(beingProcessed){
        this.set("beingProcessed",!!beingProcessed)
    },

    setSelected : function(selected){
        this.set("selected",selected);
        if(selected){
            for(let i =0; i < this._startWrapNodes.length; i++){
                const startWrapNode = this._startWrapNodes[i];
                if(!startWrapNode.isHighlighted()){
                    startWrapNode.setHighlighted(true)
                }
            } 
            for(let i =0; i < this._endWrapNodes.length; i++){
                const endWrapNode = this._endWrapNodes[i];
                if(!endWrapNode.isHighlighted()){
                    endWrapNode.setHighlighted(true)
                }
            } 
        }else{
            for(let i =0; i < this._startWrapNodes.length; i++){
                const startWrapNode = this._startWrapNodes[i];
                if(startWrapNode.isHighlighted()){
                    startWrapNode.setHighlighted(false);
                }
            } 
            for(let i =0; i < this._endWrapNodes.length; i++){
                const endWrapNode = this._endWrapNodes[i];
                if(endWrapNode.isHighlighted()){
                    endWrapNode.setHighlighted(false)
                }
            } 
        }
    },

    setPlaying : function(playing){
        this.set("playing",playing);
    },

    setHighlighted : function(selected){
        this.set("highlighted",selected);
    },

    setHoverHighlighted : function(hoverHighlighted){
        this.set("hoverHighlighted",hoverHighlighted);
    },

    setSearchHightlighted : function(searchHightlighted){
        this.set("searchHightlighted",searchHightlighted);
    },

    

    clearNext: function(){
        if(this._nextSequencerNode){
            const nextSequencerNode = this._nextSequencerNode;
            this._nextSequencerNode._previousSequencerNode = null;
            this._nextSequencerNode = null;
            this.set("_nextSequenceNodeCid", null);
            nextSequencerNode.set("_previousSequenceNodeCid", null);
            nextSequencerNode.trigger("previousNodeChange");
            this.trigger("nextNodeChange");
        }
    },

    clearPrevious: function(){
        if(this._previousSequencerNode){
            const previousSequencerNode = this._previousSequencerNode;
            this._previousSequencerNode._nextSequencerNode = null;
            this._previousSequencerNode = null;
            this.set("_previousSequenceNodeCid", null);
            previousSequencerNode.set("_nextSequenceNodeCid", null);
            previousSequencerNode.trigger("nextNodeChange");
            this.trigger("previousNodeChange");
        }
        
    },

    isSelected : function(){
        return this.get("selected");
    },

    isPlaying : function(){
        return this.get("playing");
    },

    isHighlighted : function(){
        return this.get("highlighted");
    },

    isHoverHighlighted : function(){
        return this.get("hoverHighlighted");
    },

    isSearchHightlighted : function(){
        return this.get("searchHightlighted");
    },

    setAddedToSequencer : function(added){
        this.set("addedToSequenecer",added);
    },

    isAddedToSequencer : function(){
        return this.get("addedToSequenecer");
    },


    /*setReplacementNode : function(replacementNode){
        this.set("replacementNode",replacementNode);
    },

    getReplacementNode : function(){
        return this.get("replacementNode");
    },*/


    getNext : function(){
        return this._nextSequencerNode;
    },

    _setPrevious : function(sequencerNode){
        this._previousSequencerNode = sequencerNode;
        this.set("_previousSequenceNodeCid", sequencerNode? sequencerNode.cid: null);
        this.trigger("previousNodeChange");
    },

    getPrevious : function(){
        return this._previousSequencerNode;
    },

    insertBefore : function(sequencerNode, insertDoneViaClipboardPaste){
        const initialPreviousNode = sequencerNode.getPrevious();
        if(initialPreviousNode){
            initialPreviousNode.clearNext();
            initialPreviousNode.setNext(this);
        }
        this.setNext(sequencerNode);
        this.setInsertedByPasting(!!insertDoneViaClipboardPaste);
        if(sequencerNode.isAddedToSequencer() && !this.isAddedToSequencer()){
            this.addToSequencer();
        }
        this._copyStartAndEndWrapNodes(initialPreviousNode);
        if(sequencerNode == this.getSequencer().getFirstNode()){
            this.getSequencer().setFirstNode(this);
        }
        this.trigger("wasInsertedBefore");
        sequencerNode.trigger("nodeInsertedBefore", this);
        if(initialPreviousNode){
            initialPreviousNode.trigger("nodeInsertedAfter", this);
        }
        const sequencerNodeToCrossfadeAtTheEndWith = this.getSequencerNodeToCrossfadeAtTheEndWith();
        if(sequencerNodeToCrossfadeAtTheEndWith && sequencerNodeToCrossfadeAtTheEndWith != sequencerNode &&  sequencerNodeToCrossfadeAtTheEndWith != initialPreviousNode){
            sequencerNodeToCrossfadeAtTheEndWith.trigger("crossfadeSequencerNodeNeighbourChange");
        }
        const sequencerNodeToCrossfadeAtTheBeginingWith = this.getSequencerNodeToCrossfadeAtTheBeginingWith();
        if(sequencerNodeToCrossfadeAtTheBeginingWith && sequencerNodeToCrossfadeAtTheBeginingWith != sequencerNode &&  sequencerNodeToCrossfadeAtTheBeginingWith != initialPreviousNode){
            sequencerNodeToCrossfadeAtTheBeginingWith.trigger("crossfadeSequencerNodeNeighbourChange");
        }
    },

    _copyStartAndEndWrapNodes : function(sequencerNodeToCopy){
        if(sequencerNodeToCopy){
            this._startWrapNodes = sequencerNodeToCopy._startWrapNodes.slice(0);
            this._endWrapNodes = sequencerNodeToCopy._endWrapNodes.slice(0);
        }
    },

    insertAfter : function(sequencerNode, insertDoneViaClipboardPaste){
        const initialNextNode = sequencerNode.getNext();
        if(initialNextNode){
            initialNextNode.clearPrevious();
            this.setNext(initialNextNode);
        }
        sequencerNode.setNext(this);
        this.setInsertedByPasting(!!insertDoneViaClipboardPaste);
        if(sequencerNode.isAddedToSequencer() && !this.isAddedToSequencer()){
            this.addToSequencer();
        }
        this._copyStartAndEndWrapNodes(sequencerNode);
        if(sequencerNode == this.getSequencer().getLastNode()){
            this.getSequencer().setLastNode(this);
        }
        this.trigger("wasInsertedAfter");
        sequencerNode.trigger("nodeInsertedAfter", this);
        if(initialNextNode){
            initialNextNode.trigger("nodeInsertedBefore", this);
        }
        const sequencerNodeToCrossfadeAtTheEndWith = this.getSequencerNodeToCrossfadeAtTheEndWith();
        if(sequencerNodeToCrossfadeAtTheEndWith && sequencerNodeToCrossfadeAtTheEndWith != sequencerNode &&  sequencerNodeToCrossfadeAtTheEndWith != initialNextNode){
            sequencerNodeToCrossfadeAtTheEndWith.trigger("crossfadeSequencerNodeNeighbourChange");
        }
        const sequencerNodeToCrossfadeAtTheBeginingWith = this.getSequencerNodeToCrossfadeAtTheBeginingWith();
        if(sequencerNodeToCrossfadeAtTheBeginingWith && sequencerNodeToCrossfadeAtTheBeginingWith != sequencerNode &&  sequencerNodeToCrossfadeAtTheBeginingWith != initialNextNode){
            sequencerNodeToCrossfadeAtTheBeginingWith.trigger("crossfadeSequencerNodeNeighbourChange");
        }
    },

    
    setHidden: function(hidden){
        this.set("hidden",hidden);
    },

    isHidden : function(){
        return this.get("hidden");
    },

    addStartWrapNode : function(startWrapNode){
        if(this._startWrapNodes.indexOf(startWrapNode) === -1){
            this._startWrapNodes.push(startWrapNode);
        }
    },

    needAutoCrossfadeIfNeighborNodesAreDifferentFromOriginal : function(){
        return false;
    },

    canLetFadeIfNeighborNodesAreDifferentFromOriginal : function(){
        return false;
    },


    removeStartWrapNode : function(startWrapNode){
        const indexToRemove = this._startWrapNodes.indexOf(startWrapNode);
        if(indexToRemove > -1){
            this._startWrapNodes.splice(indexToRemove, 1);
        }
    },

    addEndWrapNode : function(endWrapNode){
        if(this._endWrapNodes.indexOf(endWrapNode) === -1){
            this._endWrapNodes.push(endWrapNode);
        }
    },

    removeEndWrapNode : function(endWrapNode){
        const indexToRemove = this._endWrapNodes.indexOf(endWrapNode);
        if(indexToRemove > -1){
            this._endWrapNodes.splice(indexToRemove, 1);
        }
    },

    /*setDefaultVolumeGainFilter : function(volumeGainFilter){
        if(this._audioSegment){
            this._audioSegment.setDefaultVolumeGainFilter(volumeGainFilter);
        }
    },

    getDefaultVolumeGainFilter : function(){
        if(this._audioSegment){
            return this._audioSegment.getDefaultVolumeGainFilter();
        }
    },*/


    /*addAudioFilter : function(filter){
        if(this._audioSegment){
            this._audioSegment.addAudioFilter(filter);
        }
    },

    getAudioFilters : function(){
        if(this._audioSegment){
            return this._audioSegment.getAudioFilters();
        }
    },*/

    addAudioFilter : function(filter){
        this._audioFilters.push(filter);
    },

    getAudioFilters : function(){
        return this._audioFilters;
    },

    /*addBuiltInAudioFilter : function(name, filter){
        if(this._audioSegment){
            this._uniqueNameToBuiltInFilter[name] = filter;
            this._audioSegment.addAudioFilter(filter);
        }
    },

    getBuiltInAudioFilterByName : function(name){
        return this._uniqueNameToBuiltInFilter[name];
    },*/

    addBuiltInAudioFilter : function(name, filter){
        this._uniqueNameToBuiltInFilter[name] = filter;
        this.addAudioFilter(filter);
    },

    getBuiltInAudioFilterByName : function(name){
        return this._uniqueNameToBuiltInFilter[name];
    },

    getStartWrapNodes : function(){
        return this._startWrapNodes;
    },

    getEndWrapNodes : function(){
        return this._endWrapNodes;
    },

    getNextNodeMatchingCondition: function(conditionFunctionBoundToThisSequencerNode, numberOfNodesToSkipMatchingCondition, returnCurrentNodeIfNoNodeIsMatching){
        if(this.getNext() && this.getSequencer()){
            if(!numberOfNodesToSkipMatchingCondition){
                numberOfNodesToSkipMatchingCondition = 0;
            }
            let nextSequencerNodeToReturn = null;
            let nextSequencerNode =  this.getNext();
            while(numberOfNodesToSkipMatchingCondition >= 0 && nextSequencerNode){
                if(conditionFunctionBoundToThisSequencerNode(nextSequencerNode)){
                    nextSequencerNodeToReturn  = nextSequencerNode;
                    numberOfNodesToSkipMatchingCondition = numberOfNodesToSkipMatchingCondition - 1;
                }
                nextSequencerNode = nextSequencerNode.getNext();
            }
            if(nextSequencerNodeToReturn){
                return nextSequencerNodeToReturn;
            }else{
                return returnCurrentNodeIfNoNodeIsMatching? this: null;
            }
        }
        if(returnCurrentNodeIfNoNodeIsMatching){
            return this;
        }else{
            return null;
        }
    },

    getPreviousNodeMatchingCondition: function(conditionFunctionBoundToThisSequencerNode, numberOfNodesToSkipMatchingCondition, returnCurrentNodeIfNoNodeIsMatching){
        if(this.getPrevious() && this.getSequencer()){
            if(!numberOfNodesToSkipMatchingCondition){
                numberOfNodesToSkipMatchingCondition = 0;
            }
            let previousSequencerNodeToReturn = null;
            let previousSequencerNode =  this.getPrevious();
            while(numberOfNodesToSkipMatchingCondition >= 0 && previousSequencerNode){
                if(conditionFunctionBoundToThisSequencerNode(previousSequencerNode)){
                    previousSequencerNodeToReturn  = previousSequencerNode;
                    numberOfNodesToSkipMatchingCondition = numberOfNodesToSkipMatchingCondition - 1;
                }
                previousSequencerNode = previousSequencerNode.getPrevious();
            }
            if(previousSequencerNodeToReturn){
                return previousSequencerNodeToReturn;
            }else{
                return returnCurrentNodeIfNoNodeIsMatching? this: null;
            }
        }
        if(returnCurrentNodeIfNoNodeIsMatching){
            return this;
        }else{
            return null;
        }
    },

    getNextVisibleSequencerNode : function(){
        return this.getNextNodeMatchingCondition((function(nextNode){
            return this.getSequencer().isSequencerNodeVisible(nextNode);
        }).bind(this));
    },

    getPreviousVisibleSequencerNode : function(sequencerNode){
        return this.getPreviousNodeMatchingCondition((function(previousNode){
            return this.getSequencer().isSequencerNodeVisible(previousNode);
        }).bind(this));
    },

    getNextVisibleSequencerNodeWithDuration : function(){
        return this.getNextNodeMatchingCondition((function(nextNode){
            return this.getSequencer().isSequencerNodeVisible(nextNode) && nextNode.getDuration() > 0
        }).bind(this));
    },

    getPreviousVisibleSequencerNodeWithDuration : function(sequencerNode){
        return this.getPreviousNodeMatchingCondition((function(previousNode){
            return this.getSequencer().isSequencerNodeVisible(previousNode) && previousNode.getDuration() > 0
        }).bind(this));
    },

    canBeAssociactedWithAnySpeaker : function(){
        return this.getAudioSegment() && this.getAudioSegment().canBeAssociactedWithAnySpeaker()? this.getAudioSegment().canBeAssociactedWithAnySpeaker(): false;
    },

    needToBeAssociactedWithASpeaker : function(){
        return this.getAudioSegment() && this.getAudioSegment().needToBeAssociactedWithASpeaker()? this.getAudioSegment().needToBeAssociactedWithASpeaker(): false;
    },

    getSpeakerInfo : function(){
        return this.getAudioSegment() && this.getAudioSegment().getSpeakerInfo()? this.getAudioSegment().getSpeakerInfo(): null;
    },

    getJSONDescriptionForAIAnalysis : function(){
        const cloneJson = this.cloneJSON();
        const speakerInfo = this.getSpeakerInfo();
        return {id: this.cid, type: cloneJson.type, content: this.getContent(), speakerLabel : speakerInfo?.getSpeakerLabel() ,speakerId : speakerInfo?.getSpeakerId(), duration: this.getAudioSegment()?.getDuration() }

    },




});

SequencerNode.CLASSNAME =  CLASSNAME;
export default SequencerNode; 