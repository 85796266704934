	import $ from 'jquery';
    import _ from 'underscore';
    import Backbone from "backbone";
    import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
    import EpidemicSoundHelper from "models/helper/EpidemicSoundHelper";
    import RolloutHelper from "models/helper/FeatureRolloutHelper";
    import TrebbleAudio from "models/TrebbleAudio";
    import Utils from "models/helper/Utils";
    import RSVP from "rsvp";

    const Rollout = RolloutHelper.getInstance();
    const RETRIEVE_MUSIC_FROM_EPIDEMIC_SOUND  = window.isWebAppInProduction() ? Rollout.isFeatureEnabled(Rollout.FEATURES.RETRIEVE_MUSIC_FROM_EPIDEMIC_SOUND): false;
    const TrebbleBackgroundMusicStoreHelper = Backbone.Model.extend({

        initialize : function() {
            this._customCategoryIdToCategoryLabel = null;
            this._trebbleCategoryIdToCategoryLabel = null;
            this._categoryIdToCategoryLabel = null;
        },



        getTrebbleAudioArrayForCategoruId : function(categoryId){
            if(categoryId && RETRIEVE_MUSIC_FROM_EPIDEMIC_SOUND){
                if(categoryId in this._customCategoryIdToCategoryLabel){
                    return EpidemicSoundHelper.getInstance().getTrebbleAudioArrayForCategoryId(categoryId);
                }
            }

            return this.getDefaultsMusicLoadFunctions().defaultGetTrebbleAudioModelMusicArrayByCategoryFunction(categoryId);
            
        },

        getDefaultsMusicLoadFunctions : function(){
            const categoryToBackgroundMusicTrebbleAudioMap = {};
            const categoryIdToLabel = {};
            const  p = TrebbleClientAPIHelper.getInstance().getAvailableDefaultBackgroundMusic().then((function(trebbleBackgroundMusicTrebbleAudioArray){

                if(trebbleBackgroundMusicTrebbleAudioArray && trebbleBackgroundMusicTrebbleAudioArray.length){
                    for(let i =0; i < trebbleBackgroundMusicTrebbleAudioArray.length; i++){
                        const trebbleAudioJSON = trebbleBackgroundMusicTrebbleAudioArray[i];
                        const trebbleAudioModel =  new TrebbleAudio(trebbleAudioJSON);
                        const categoryId =  trebbleAudioModel.getCategoryId();
                        categoryIdToLabel[categoryId] = Utils.getInstance().getBackgroundMusicCategoryLabel(categoryId);
                        if(!categoryToBackgroundMusicTrebbleAudioMap[categoryId]){
                            categoryToBackgroundMusicTrebbleAudioMap[categoryId] = [];
                        }
                        categoryToBackgroundMusicTrebbleAudioMap[categoryId].push(trebbleAudioModel);

                    }
                }
                for( let categoryId in categoryToBackgroundMusicTrebbleAudioMap){
                    Utils.getInstance().sortTrebbleAudioModelArray(categoryToBackgroundMusicTrebbleAudioMap[categoryId]);
                }
            }).bind(this));
            const defaultGetMusicCategoriesFunction = function(){
                return p.then(function(){
                    return categoryIdToLabel;
                })
            }

            const defaultGetTrebbleAudioModelMusicArrayByCategoryFunction = function(categoryId){
                return p.then(function(){
                    return categoryToBackgroundMusicTrebbleAudioMap[categoryId];
                })
            }
            return {"defaultGetMusicCategoriesMapFunction": defaultGetMusicCategoriesFunction, "defaultGetTrebbleAudioModelMusicArrayByCategoryFunction": defaultGetTrebbleAudioModelMusicArrayByCategoryFunction};

        },

        getCategoryIdToCategoryLabel : function(){
            if(this._categoryIdToCategoryLabel){

                return RSVP.Promise.resolve(this._categoryIdToCategoryLabel);
            }else{
                const defaultsMusicLoadFunctions = this.getDefaultsMusicLoadFunctions();
                const promiseArray  = [];
                promiseArray.push(defaultsMusicLoadFunctions.defaultGetMusicCategoriesMapFunction().then((function(defaultCategoryIdToLabel){
                    this._trebbleCategoryIdToCategoryLabel = defaultCategoryIdToLabel;
                }).bind(this)))
                if(RETRIEVE_MUSIC_FROM_EPIDEMIC_SOUND){
                    promiseArray.push(EpidemicSoundHelper.getInstance().getCategoryIdToCategoryLabel().then((function(epdidemicSoundIdToLabel){
                        this._customCategoryIdToCategoryLabel = epdidemicSoundIdToLabel;

                    }).bind(this)))
                }

                return RSVP.Promise.all(promiseArray).then((function(){
                    this._categoryIdToCategoryLabel = {};
                    for(let categoryId in this._trebbleCategoryIdToCategoryLabel){
                        this._categoryIdToCategoryLabel[categoryId] = this._trebbleCategoryIdToCategoryLabel[categoryId];
                    }
                    for(let categoryId in this._customCategoryIdToCategoryLabel){
                        this._categoryIdToCategoryLabel[categoryId] = this._customCategoryIdToCategoryLabel[categoryId];
                    }
                    return this._categoryIdToCategoryLabel;
                }).bind(this))

            }
        },

    });

    const TrebbleBackgroundMusicStoreHelperInstance = new TrebbleBackgroundMusicStoreHelper();

    export default {
        getInstance : function() {
            return TrebbleBackgroundMusicStoreHelperInstance;
        }
    };