import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import PaidAccountSubscriptionPopupView from "views/paidAccountSubscriptionPopup/PaidAccountSubscriptionPopupView";
import SubscriptionManager from "models/helper/SubscriptionManager";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import FacebookEventTrackingHelper from "models/helper/FacebookEventTrackingHelper";
import PlayerService from "services/PlayerService";
import RSVP from "rsvp";

const PaidAccountSubscriptionPopupController = Chaplin.Controller.extend({

    initialize : function(queue) {
        this.model = queue
        _.extend(this, Backbone.Events);
        this._userInitiatedPurchase = false;
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    onViewRemoved:function(){
        this.stopListening();
    },

    upgradeToTrebblePlusMonthly : function(){
        this._userInitiatedPurchase = true;
        this.view.setOrderBeingProcessed(true);
        return SubscriptionManager.getInstance().orderTrebblePlusMonthlyInfo().catch(this.onErrorDuringOrderingUserPaidSubscription.bind(this));
    },

    upgradeToTrebblePlusYearly : function(){
        this._userInitiatedPurchase = true;
        this.view.setOrderBeingProcessed(true);
        return SubscriptionManager.getInstance().orderTrebblePlusYearlyInfo().catch(this.onErrorDuringOrderingUserPaidSubscription.bind(this));
    },

    restorePurchases: function(){
        this.view.setPurchaseBeingRestored(true);
        return SubscriptionManager.getInstance().restorePurchases().then((function(){
            this.view.setPurchaseBeingRestored(false);
        }).bind(this)).catch((function(){
            this.view.setPurchaseBeingRestored(false);
        }).bind(this));
    },

    showTermsAndConditions: function(){

    },

    onUserSubscriptionFinished : function(){
        
        return SubscriptionManager.getInstance().isCurrentUserSubscribedToTrebblePlus(false,true).then((function(isSubscribedToTrebblePlus){
            this.view.setOrderBeingProcessed(false);
            if(isSubscribedToTrebblePlus){
                this.view.close();
                if(this._userInitiatedPurchase && window.trebbleAnalyticsHelper){
                    SubscriptionManager.getInstance().getTrebbePlusSubscriptionUserIsSubscribedTo().then((function(productUserIsSubscribedTo){
                        if (productUserIsSubscribedTo && window.trebbleAnalyticsHelper) {
                            window.trebbleAnalyticsHelper.trackEvent("PaidAccountSubscription", 'userSuccessfullyPurchaseTrebblePlusSubscription', 'Trebble Plus subscription purchased', null, {"id":productUserIsSubscribedTo.id,"priceMicros":productUserIsSubscribedTo.priceMicros, "currency":productUserIsSubscribedTo.currency});
                        }
                        if(FacebookEventTrackingHelper){
                            FacebookEventTrackingHelper.getInstance().trackPurchase(productUserIsSubscribedTo, SubscriptionManager.getInstance().isSandbox())
                        }
                    }).bind(this))
                }
            }
            
        }).bind(this)).catch(this.onErrorDuringOrderingUserPaidSubscription.bind(this));
    },

    onErrorDuringOrderingUserPaidSubscription: function(error){
        this.view.setOrderBeingProcessed(false);
        if("store" in window && error.code === window.store.ERR_PAYMENT_CANCELLED)
        {
            window.log("ignore error");
        }else{
            if(error && error.message){
                window.alertErrorMessage(error.message);
            }
        }
    },

    fetchAccountSubscriptionsAvailableAndDisplay : function(){
        this.view.setRetrievingAccountSubscriptionProducts();
        SubscriptionManager.getInstance().updateSubscriptionsInfo().then((function(){
            const trebblePlusMonthyProductInfo = SubscriptionManager.getInstance().getTrebblePlusMonthlyInfo();
            const trebblePlusYearlyProductInfo = SubscriptionManager.getInstance().getTrebblePlusYearlyInfo();
            this.view.setFeedBoxContent(trebblePlusYearlyProductInfo, trebblePlusMonthyProductInfo);
        }).bind(this)).catch((function(error){
            console.error(error);
            this.view.setErrorOnFeedBoxLoading(error);
        }).bind(this));
    },
    
    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {

                this.view = new PaidAccountSubscriptionPopupView({
                    "model" : this.model
                });
                this.listenTo(this.view, "upgradeToTrebblePlusMonthly",this.upgradeToTrebblePlusMonthly);
                this.listenTo(this.view, "upgradeToTrebblePlusYearly",this.upgradeToTrebblePlusYearly);
                this.listenTo(this.view, "restorePurchases",this.restorePurchases);
                this.listenTo(this.view, "showTermsAndConditions",this.showTermsAndConditions);
                
                this.listenTo(this.view, "remove", this.onViewRemoved.bind(this));
                this.listenTo(SubscriptionManager.getInstance(),SubscriptionManager.getInstance().eventTypes.SUBSCRIPTION_ORDER_FINISHED, this.onUserSubscriptionFinished.bind(this));
                this.listenTo(SubscriptionManager.getInstance(),SubscriptionManager.getInstance().eventTypes.SUBSCRIPTION_ERROR, this.onErrorDuringOrderingUserPaidSubscription.bind(this));

                this.view.render();
                this.fetchAccountSubscriptionsAvailableAndDisplay();
                if (window.trebbleAnalyticsHelper) {
                    window.trebbleAnalyticsHelper.trackEvent("PaidAccountSubscription", 'ShowPaidAccountSubscriptionPopup', 'Show paid account subscription popup');
                }
                
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

}

});

export default PaidAccountSubscriptionPopupController;