
import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import BasicSequencerNodeInspectorController from "controllers/audioEditor/sequencerNodeInspectorSection/BasicSequencerNodeInspectorController";
import NoElementSelectedNodeInspectorView from "views/audioEditor/sequencerNodeInspectorSection/NoElementSelectedNodeInspectorView";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const NoElementSelectedNodeInspectorController = BasicSequencerNodeInspectorController.extend({


    listenToEventsOnView: function(){
    
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                const params = {};
                params.sequencerNode = this.getModel();
                this.view = new NoElementSelectedNodeInspectorView(params);
                this.view.render();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default NoElementSelectedNodeInspectorController;