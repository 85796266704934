import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import CordovaHelper from "models/helper/CordovaHelper";
import AddSongToTrebbleView from "views/addSongToTrebble/AddSongToTrebbleView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PersistentModels from "services/PersistentModels";
import RSVP from 'rsvp';

/*"models/helper/TrebbleIndexDBHelper",*/
import YoutubeSearchHelper from "models/helper/YoutubeSearchHelper";

import SoundcloudSearchHelper from "models/helper/SoundcloudSearchHelper";
import Utils from "models/helper/Utils";
import ti18n from "i18n!nls/AddSongToTrebblei18n";


const SOUNDCLOUD_URL_PREFIX = "https://soundcloud";
const AddSongToTrebbleController = Chaplin.Controller.extend({

    initialize : function(options) {

        _.extend(this, Backbone.Events);
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    

    retrieveSongModel : function(){
        let shareIntentSongInfo = null;
        let isSoundCloudLink =  false;
        
        return CordovaHelper.getInstance().executePluginOperation("MusicFileReaderPlugin","getShareSongInfo", []).then((function(sharedInfo){
            shareIntentSongInfo = sharedInfo;
            if(shareIntentSongInfo){
                if(shareIntentSongInfo.uri && shareIntentSongInfo.uri.indexOf("soundcloud") > -1)
                {
                    isSoundCloudLink = true;
                    const parseSoundcloudUri = this.parseSoundCloudTextToExtractUri(shareIntentSongInfo.uri);
                    return this.getSoundCloudInfoFromUrl(parseSoundcloudUri);
                }else{
                    isSoundCloudLink = false;
                    //check if youtube link
                    if(shareIntentSongInfo.title && shareIntentSongInfo.title.indexOf("on YouTube") > -1)
                    {
                        return this.getYoutubeVideoIdFromUrl(shareIntentSongInfo.uri);
                    }else{
                        return RSVP.resolve();
                    }
                }
            }
        }).bind(this));
    },

        getSoundCloudInfoFromUrl : function(soundCloudUrl){
        
        return new RSVP.Promise(function(resolve,reject){
            try{
            /*SC.initialize({
                  client_id: 'e1ddc5a48279cd6d15c35595c26c7b05'
                });
            
            SC.get('/resolve', { url: soundCloudUrl }, function(track) {
                resolve(track)
            });*/
                /*$.get(window.trebble.usedProtocolPrefix+'api.soundcloud.com/resolve.json?url=' +soundCloudUrl +'&client_id=e1ddc5a48279cd6d15c35595c26c7b05', function (track) {
                    resolve({"info" :track, "uriType":"soundcloud"});
                    }).fail(function(error){
                        reject(error);
                    });*/

                    SoundcloudSearchHelper.getInstance().getSoundcloudTrackInfoFromPermanentUrl(soundCloudUrl).then((function(soundcloudTrackInfo){
                        const songJson = soundcloudTrackInfo? Utils.getInstance().getTrebblesSongJsonFromSoundCloudTrackInfo(soundcloudTrackInfo): null;
                        resolve({"info" : songJson, "uriType":"soundcloud"});
                    }).bind(this)).catch(reject);
            }catch(err){
                reject(err);
            }
        });
    },
    
    
    
    getYoutubeVideoIdFromUrl : function(youtubeInfo){
        
        return new RSVP.Promise(function(resolve,reject){
            try{
                const indexOfLastSlash = youtubeInfo.lastIndexOf("/") ;
                if(indexOfLastSlash > -1)
                {
                    const youtubeVideoId = youtubeInfo.substr(indexOfLastSlash+ 1);
                    YoutubeSearchHelper.getInstance().getVideoInfoFromVideoId(youtubeVideoId).then(function(youtubeVideoInfo){
                        const songJson = youtubeVideoInfo? Utils.getInstance().getTrebblesSongJsonFromYoutubeVideoInfo(youtubeVideoInfo): null;
                        resolve({"info" : songJson, "uriType":"youtube"});
                    }).catch(reject);
                    
                }else{
                    const errorMessage = window.getI18n(ti18n,"COULDNT_RETRIEVE_YOUTUBE_VIDEO_ID_FROM")+" '" + youtubeInfo + "'";
                    console.error(errorMessage)
                    reject(errorMessage);
                }
            }catch(err){
                reject(err);
            }
        });
    },

    parseSoundCloudTextToExtractUri : function(soundcloudSharedText){
        if(soundcloudSharedText){
            const uriStartIndex = soundcloudSharedText.indexOf(SOUNDCLOUD_URL_PREFIX);
            if(uriStartIndex != -1){
                return soundcloudSharedText.substring(uriStartIndex);
            }
            return soundcloudSharedText;
        }else{
            return soundcloudSharedText;
        }
    },

    retrieveSongInfoAndAddToTrebble : function(){
        const songIdentifiedModel = null;
        const basicSongInfo = null;
        //$.mobile.loading( 'show');
        this.view.setStatus(window.getI18n(ti18n,"RETRIEVING_SONG_INFO"));
        return this.retrieveSongModel().then((function(result){
            if(result){
                const trackInfo = result.info;
                this.view.setStatus(window.getI18n(ti18n,"ADDING_SONG_TO_TREBBLE"));
                return PersistentModels.getInstance().addSongToDefaultRadio(Utils.getInstance().getModelFromSongJson(trackInfo),Utils.getInstance().buildContextForTrebbleAppExtension()).then((function(SongTabManagerPopupController){
                    //$.mobile.loading( "hide" );
                    if(SongTabManagerPopupController){
                        this.view.setStatus(window.getI18n(ti18n,"GRADING_SONG"));
                        SongTabManagerPopupController.getView().$el.on( "popupafterclose",(function(){
                            this.view.setStatus(window.getI18n(ti18n,"SONG_ADDED_TO_TREBBLE"));
                            this.closeApplication();
                        }).bind(this));
                    }
                    //
                }).bind(this));
            }else{
                //$.mobile.loading( "hide" );
                this.view.setStatus(window.getI18n(ti18n,"SOUNDCLOUD_AND_YOUTUBE"));
                window.alertErrorMessage(window.getI18n(ti18n,"ONLY_YOUTUBE_AND_SOUNDCLOUD_CAN_BE_ADDED"));
            }
        }).bind(this)).catch(function(error){
            //$.mobile.loading( "hide" );
            this.view.setStatus(window.getI18n(ti18n,"OOPS_SOMETHING_WENT_WRONGD"));

            window.alertErrorMessage(JSON.stringify(error));
        });
    },

    closeApplication : function(){
        //window.close();
        CordovaHelper.getInstance().executePluginOperation("MusicFileReaderPlugin","triggerBackButtonPressed", []);
        return CordovaHelper.getInstance().executePluginOperation("MusicFileReaderPlugin","triggerBackButtonPressed", []);
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
            
                this.view = new AddSongToTrebbleView();
                this.listenTo(this.view, "closeBtnClicked",this.closeApplication);
                this.view.render();
                resolve();
                this.retrieveSongInfoAndAddToTrebble();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default AddSongToTrebbleController;