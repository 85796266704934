import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import NotificationCardController from "controllers/common/NotificationCardController";
import CollectionRepeatController from "controllers/common/CollectionRepeatController";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import FeedAndAcitivyPageView from "views/feedAndActivities/FeedAndAcitivyPageView";
import PushNotificationHelper from "models/helper/PushNotificationHelper";
import NotificationDefaultListItemTemplate from "text!../../../templates/common/NotificationDefaultListItemTemplate.html";
import CommentNotificationListItemTemplate from "text!../../../templates/common/CommentNotificationListItemTemplate.html";
import MentionedInCommentNotificationItemTemplate from "text!../../../templates/common/MentionedInCommentNotificationItemTemplate.html";
import MentionedInCapsuleNotificationItemTemplate from "text!../../../templates/common/MentionedInCapsuleNotificationItemTemplate.html";
import CommentLikedNotificationListItemTemplate from "text!../../../templates/common/CommentLikedNotificationListItemTemplate.html";
import CommentOnCapsuleNotificationListItemTemplate from "text!../../../templates/common/CommentOnCapsuleNotificationListItemTemplate.html";
import songPickedFromTrebbleNotificationTemplate from 'text!../../../templates/common/SongPickedFromTrebbleNotificationTemplate.html';
import SongLiveLikeFromTrebbleNotificationTemplate from 'text!../../../templates/common/SongLiveLikeFromTrebbleNotificationTemplate.html';
import UserFollowingYouNotificationTemplate from 'text!../../../templates/common/UserFollowingYouNotificationTemplate.html';
import UserFollowingYourTrebbleNotificationTemplate from 'text!../../../templates/common/UserFollowingYourTrebbleNotificationTemplate.html';
import ti18n from "i18n!nls/FeedAndActivitiesPagei18n";
import RSVP from "rsvp";

const DEFAULT_NUMBER_OF_NOTIFICATION_RETRIEVED = 100;
const FeedAndActivityPageController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = options.model;
        this.context = options.context;
        const collectionRepeatControllerParams = {};
        collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildInfoBoxParams();
        collectionRepeatControllerParams.showEmtptyInfoBox = true;
        collectionRepeatControllerParams.controllerOption = null;
        collectionRepeatControllerParams.resizePlaceHolderElem = true;
        collectionRepeatControllerParams.scrollToTheTopOnDisplay = true;
        collectionRepeatControllerParams.getOptionsForModelFunctionHandler = this._getOptionsForModelFunctionHandler.bind(this);
        collectionRepeatControllerParams.itemControllerConstructor = NotificationCardController;
        collectionRepeatControllerParams.modelLoadHandler = options.loadHandler?options.loadHandler: this._getRecentNotificationLoadHandler.bind(this);
        if(options.context){
            collectionRepeatControllerParams.context = options.context;
        }
        this.collectionRepeatController = new CollectionRepeatController(collectionRepeatControllerParams);


    },

    _getOptionsForModelFunctionHandler : function(customPlayloadModel){
        switch(customPlayloadModel.getNotificationType())
        {
            case 'UserMentionedInCapsule':
            return this.getNotificationControllerParamsForMentionInCapsule(customPlayloadModel);
            break;
            case 'commentOnACapsule':
            return this.getNotificationControllerParamsForCommentOnCapsule(customPlayloadModel);
            break;
            case 'CapusleProcessingCompleted':
            return this.getNotificationControllerParamsForCapsule(customPlayloadModel);
            break;
            case 'commentWasLiked':
            return this.getNotificationControllerParamsForLikedComment(customPlayloadModel);
            break;
            case 'mentionedInComment':
            return this.getNotificationControllerParamsForMentionedInComment(customPlayloadModel);
            break;
            case 'replyToComment':
            return this.getNotificationControllerParamsForCommentOrReplies(customPlayloadModel);
            break;
            case 'commentInASongWhileListeningToTrebble':
            return this.getNotificationControllerParamsForCommentOrReplies(customPlayloadModel);
            break;
            case 'commentInASongWhileListeningToCapsuleSimpleSet':
            return this.getNotificationControllerParamsForCommentOrReplies(customPlayloadModel);
            break;
            case 'SongFromTrebbleAddedFromListener':
            return this.getNotificationControllerParamsForSongPickedByListener(customPlayloadModel);
            break;
            case 'SongFromTrebbleLiveLikedFromListener':
            return this.getNotificationControllerParamsForSongLiveLikedByListener(customPlayloadModel);
            break;
            case 'UserStartedFollowingYou':
            return this.getNotificationControllerParamsUserStartedFollowingYou(customPlayloadModel);
            break;
            case 'UserStartedFollowingYourTrebble':
            return this.getNotificationControllerParamsUserStartedFollowingYourTrebble(customPlayloadModel);
            break;	
            case 'capsuleReplyWhileListeningToTrebble':
            return this.getDefaultNotificationControllerParams(customPlayloadModel);
            break;		
            case 'capsuleReplyWhileListeningToCapsuleSimpleSet':
            return this.getDefaultNotificationControllerParams(customPlayloadModel);
            break;
            case 'CommentReply':
            return this.getNotificationControllerParamsForCommentOrReplies(customPlayloadModel.getNotificationData());
            break;	
            case 'transcriptionJobCompleted':
            return this.getNotificationControllerParamsTranscriptionJobCompleted(customPlayloadModel);
            break;

            default:
            return this.getDefaultNotificationControllerParams(customPlayloadModel);
            break;
        }
    },
    getDefaultNotificationControllerParams : function(customPlayloadModel){
        const notificationCardControllerParams = {};
        notificationCardControllerParams.model = customPlayloadModel;
        notificationCardControllerParams.showActionButton = false;
        notificationCardControllerParams.customTemplate = NotificationDefaultListItemTemplate;
        return notificationCardControllerParams;
    },
    getNotificationControllerParamsForSongPickedByListener : function(customPlayloadModel){
        const songsInfoJson  = customPlayloadModel.getNotificationData().songsInfo;
        const songsInfoModel = [];
        for(let index in songsInfoJson){
            songsInfoModel.push(Utils.getInstance().getModelFromSongJson(songsInfoJson[index]));
        }
        customPlayloadModel.getNotificationData().songsInfoModel =  songsInfoModel;
        const notificationCardControllerParams = {};
        notificationCardControllerParams.model = customPlayloadModel;
        notificationCardControllerParams.showActionButton = false;
        notificationCardControllerParams.customTemplate = songPickedFromTrebbleNotificationTemplate;
        return notificationCardControllerParams;
    },

    getNotificationControllerParamsForSongLiveLikedByListener : function(customPlayloadModel){
        const songsInfoJson  = customPlayloadModel.getNotificationData().songsInfo;
        const songsInfoModel = [];
        for(let index in songsInfoJson){
            songsInfoModel.push(Utils.getInstance().getModelFromSongJson(songsInfoJson[index]));
        }
        customPlayloadModel.getNotificationData().songsInfoModel =  songsInfoModel;
        const notificationCardControllerParams = {};
        notificationCardControllerParams.model = customPlayloadModel;
        notificationCardControllerParams.showActionButton = false;
        notificationCardControllerParams.customTemplate = SongLiveLikeFromTrebbleNotificationTemplate;
        return notificationCardControllerParams;
    },

    getNotificationControllerParamsUserStartedFollowingYou :function(customPlayloadModel){
        const userJson  = customPlayloadModel.getNotificationData().userJson;
        const userModel = Utils.getInstance().getModelFromUserJson(userJson);
        customPlayloadModel.getNotificationData().userInfoModel =  userModel;
        const customViewEventHandler = {};
        const onUsernameCliqued = function(){
            PersistentModels.getInstance().getRouter().showUserProfilePageByUsername(null,userModel.getUserId(), userModel, true);
        }
        customViewEventHandler["click .highlight.usernameFollowing"] = onUsernameCliqued;
        const notificationCardControllerParams = {};
        notificationCardControllerParams.model = customPlayloadModel;
        notificationCardControllerParams.showActionButton = false;
        notificationCardControllerParams.customTemplate = UserFollowingYouNotificationTemplate;
        notificationCardControllerParams.customEventHandler = customViewEventHandler;
        return notificationCardControllerParams;
    },

    getNotificationControllerParamsTranscriptionJobCompleted: function(customPlayloadModel){
        const customViewEventHandler = {};
        customViewEventHandler["click .content"] = (function(){
            PersistentModels.getInstance().getRouter().showCreateShortcastPageFromTranscription(this.notificationData.transcriptionId,false, true);
        }).bind({"notificationData": customPlayloadModel.getNotificationData()});
        const notificationCardControllerParams = {};
        notificationCardControllerParams.model = customPlayloadModel;
        notificationCardControllerParams.showActionButton = false;
        notificationCardControllerParams.customTemplate = NotificationDefaultListItemTemplate;
        notificationCardControllerParams.customEventHandler = customViewEventHandler;
        return notificationCardControllerParams;
    },

    getNotificationControllerParamsForCommentOrReplies :function(customPlayloadModel){
        const songInfoJson  = (customPlayloadModel.getNotificationData().commentJson && customPlayloadModel.getNotificationData().commentJson.songInfoJson)? customPlayloadModel.getNotificationData().commentJson.songInfoJson: null;
        const songInfoModel = Utils.getInstance().getModelFromSongJson(songInfoJson); 
        customPlayloadModel.getNotificationData().songInfoModel =  songInfoModel;
        const customViewEventHandler = {};
        customViewEventHandler["click .content"] = (function(){
            PushNotificationHelper.getInstance().showCommentInPopup(this.notificationData);
        }).bind({"notificationData": customPlayloadModel.getNotificationData()});
        const notificationCardControllerParams = {};
        notificationCardControllerParams.model = customPlayloadModel;
        notificationCardControllerParams.showActionButton = false;
        notificationCardControllerParams.customEventHandler = customViewEventHandler;
        notificationCardControllerParams.customTemplate = CommentNotificationListItemTemplate;
        return notificationCardControllerParams;
    },

    getNotificationControllerParamsForMentionedInComment :function(customPlayloadModel){
        const songInfoJson  = (customPlayloadModel.getNotificationData().commentJson && customPlayloadModel.getNotificationData().commentJson.songInfoJson)? customPlayloadModel.getNotificationData().commentJson.songInfoJson: null;
        const songInfoModel = Utils.getInstance().getModelFromSongJson(songInfoJson); 
        customPlayloadModel.getNotificationData().songInfoModel =  songInfoModel;
        const customViewEventHandler = {};
        customViewEventHandler["click .content"] = (function(){
            PushNotificationHelper.getInstance().showCommentInPopup(this.notificationData);
        }).bind({"notificationData": customPlayloadModel.getNotificationData()});
        const notificationCardControllerParams = {};
        notificationCardControllerParams.model = customPlayloadModel;
        notificationCardControllerParams.showActionButton = false;
        notificationCardControllerParams.customEventHandler = customViewEventHandler;
        notificationCardControllerParams.customTemplate = MentionedInCommentNotificationItemTemplate;
        return notificationCardControllerParams;
    },


    getNotificationControllerParamsForLikedComment :function(customPlayloadModel){
        const songInfoJson  = (customPlayloadModel.getNotificationData().commentJson && customPlayloadModel.getNotificationData().commentJson.songInfoJson)? customPlayloadModel.getNotificationData().commentJson.songInfoJson: null;
        const songInfoModel = Utils.getInstance().getModelFromSongJson(songInfoJson); 
        customPlayloadModel.getNotificationData().songInfoModel =  songInfoModel;
        const customViewEventHandler = {};
        customViewEventHandler["click .content"] = (function(){
            PushNotificationHelper.getInstance().showCommentInPopup(this.notificationData);
        }).bind({"notificationData": customPlayloadModel.getNotificationData()});
        const notificationCardControllerParams = {};
        notificationCardControllerParams.model = customPlayloadModel;
        notificationCardControllerParams.showActionButton = false;
        notificationCardControllerParams.customEventHandler = customViewEventHandler;
        notificationCardControllerParams.customTemplate = CommentLikedNotificationListItemTemplate;
        return notificationCardControllerParams;
    },

    getNotificationControllerParamsForCapsule :function(customPlayloadModel){
        const customViewEventHandler = {};
        customViewEventHandler["click .content"] = (function(){
            PushNotificationHelper.getInstance().showCapsuleCardInPopup(this.notificationData);
        }).bind({"notificationData": customPlayloadModel.getNotificationData()});
        const notificationCardControllerParams = {};
        notificationCardControllerParams.model = customPlayloadModel;
        notificationCardControllerParams.showActionButton = false;
        notificationCardControllerParams.customEventHandler = customViewEventHandler;
        notificationCardControllerParams.customTemplate = NotificationDefaultListItemTemplate;
        return notificationCardControllerParams;
    },

    getNotificationControllerParamsForMentionInCapsule :function(customPlayloadModel){
        const customViewEventHandler = {};
        customViewEventHandler["click .content"] = (function(){
            PushNotificationHelper.getInstance().showCapsuleCardInPopup(this.notificationData);
        }).bind({"notificationData": customPlayloadModel.getNotificationData()});
        const notificationCardControllerParams = {};
        notificationCardControllerParams.model = customPlayloadModel;
        notificationCardControllerParams.showActionButton = false;
        notificationCardControllerParams.customEventHandler = customViewEventHandler;
        notificationCardControllerParams.customTemplate = MentionedInCapsuleNotificationItemTemplate;
        return notificationCardControllerParams;
    },

    getNotificationControllerParamsForCommentOnCapsule :function(customPlayloadModel){
        const customViewEventHandler = {};
        customViewEventHandler["click .content"] = (function(){
            PushNotificationHelper.getInstance().showCommentInPopup(this.notificationData);
        }).bind({"notificationData": customPlayloadModel.getNotificationData()});
        const notificationCardControllerParams = {};
        notificationCardControllerParams.model = customPlayloadModel;
        notificationCardControllerParams.showActionButton = false;
        notificationCardControllerParams.customEventHandler = customViewEventHandler;
        notificationCardControllerParams.customTemplate = CommentOnCapsuleNotificationListItemTemplate;
        return notificationCardControllerParams;
    },

    getNotificationControllerParamsUserStartedFollowingYourTrebble :function(customPlayloadModel){
        const userJson  = customPlayloadModel.getNotificationData().userJson;
        const userModel = Utils.getInstance().getModelFromUserJson(userJson);
        customPlayloadModel.getNotificationData().userInfoModel =  userModel;
        const customViewEventHandler = {};
        const onUsernameCliqued = function(){
            PersistentModels.getInstance().getRouter().showUserProfilePageByUsername(null,userModel.getUserId(), userModel, true);
        }
        customViewEventHandler["click .highlight.usernameFollowing"] = onUsernameCliqued;
        const notificationCardControllerParams = {};
        notificationCardControllerParams.model = customPlayloadModel;
        notificationCardControllerParams.showActionButton = false;
        notificationCardControllerParams.customTemplate = UserFollowingYourTrebbleNotificationTemplate;
        notificationCardControllerParams.customEventHandler = customViewEventHandler;
        return notificationCardControllerParams;
    },

    _buildInfoBoxParams : function(){
        const params = {};
        params.iconClass = "pe-7s-bell";
        params.message = window.getI18n(ti18n, "NO_NOTIFICATION");
        params.helpMessage = null;
        return params;

    },

    _getRecentNotificationLoadHandler: function(){
        return TrebbleClientAPIHelper.getInstance().getMyRecentNotificationCustomPayload(DEFAULT_NUMBER_OF_NOTIFICATION_RETRIEVED).then(function(arrayOfCustomPayloadJson){
            const arrayOfCustomPayloadModels = [];
            if(arrayOfCustomPayloadJson){
                for(let index in arrayOfCustomPayloadJson){
                    const customPayloadJson = arrayOfCustomPayloadJson[index];
                    arrayOfCustomPayloadModels.push(Utils.getInstance().getModelFromCustomNotificationPayload(customPayloadJson));
                }
            }
            return arrayOfCustomPayloadModels;
        });
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },


    _notifyNotificationConsumed : function(){
        /*if(this.model ){
            if(Utils.getInstance().isSongInstance(this.model)){
                return PersistentModels.getInstance().notifyUserOfUnconsumedOwnerComments(this.model, this.context, this.songRefSummary);
            }else{
                if(Utils.getInstance().isCommentInstance(this.model)){
                    const commentId =  this.model.getCommentId();
                    return TrebbleClientAPIHelper.getInstance().markCommentRepliesAsConsumed(commentId);
                }else{
                    return RSVP.Promise.resolve();
                }
            }
        }else{
            return RSVP.Promise.resolve();
        }*/
    },

    showHomePage : function(){
        PersistentModels.getInstance().getRouter().showHomePage();
    },

    onNotificationPageShow : function(){
        if(this.view.getNotificationListView()){
            this.collectionRepeatController.reload().then((function(){
                return TrebbleClientAPIHelper.getInstance().markUnreadNotificationAsRead();
            }).bind(this)).then((function(){
                PersistentModels.getInstance().getRouter().getHomeMenuPanelController().setAllNotificationRead(true);
            }).bind(this));
            this.view.getNotificationListView().getCollectionContainer$el().css("display","-webkit-flex");
            this.view.getNotificationListView().getCollectionContainer$el().css("display","-moz-flex");
            this.view.getNotificationListView().getCollectionContainer$el().css("display","-ms-flex");
            this.view.getNotificationListView().getCollectionContainer$el().css("display","flex");

        }
    },

    onViewRemoved:function(){
        this.stopListening();
    },

    buildModelAndRenderView : function() {
        $.mobile.loading("show");
        return new RSVP.Promise((function(resolve, reject) {
            try {
                const promises = [];
                //promises.push(this.collectionRepeatController.buildModelAndRenderView());
                RSVP.Promise.all(promises).then((function(result){			
                    const pageViewParams = {};
                    pageViewParams.notificationListView = this.collectionRepeatController.getView();
                    this.view = new FeedAndAcitivyPageView(pageViewParams);
                    this.listenTo(this.view, "showHomePage", this.showHomePage);
                    this.listenTo(this.view, "pageShow", this.onNotificationPageShow);
                    this.listenTo(this.view, "remove", this.onViewRemoved.bind(this));
                    this.view.render();
                    $.mobile.loading("hide");
                    $.mobile.loading("show");
                    this.collectionRepeatController.buildModelAndRenderView().then((function(){
                        this.view.setNotificationListView(this.collectionRepeatController.getView());
                        $.mobile.loading("hide");
                    }).bind(this)).catch((function(error){
                        $.mobile.loading("hide");
                        window.alertErrorMessage(error);
                    }).bind(this));
                    resolve();
                }).bind(this)).catch((function(error){
                    $.mobile.loading("hide");
                    reject(error);
                }).bind(this));

            } catch (error) {
                $.mobile.loading("hide");
                reject(error);
            }

        }).bind(this));

}

});

export default FeedAndActivityPageController;