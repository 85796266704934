import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import Utils from "models/helper/Utils";
import CollectionInfiniteCarouselView from "views/common/CollectionInfiniteCarouselView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import RSVP from "rsvp";

const CollectionInfiniteCarouselController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if(options)
        {
            this._customInfoBoxInfo  = options.customInfoBoxInfo? options.customInfoBoxInfo: null;
            this._customInfoBoxInfoParams  = options.customInfoBoxInfoParams? options.customInfoBoxInfoParams: null;
            this._actionButtonHandlerFunction =  options.actionButtonHandlerFunction? options.actionButtonHandlerFunction: null;
            this._modelLoadHandler  = options.modelLoadHandler? options.modelLoadHandler: null;
            this._itemWidth = options.itemWidth;
            this._itemWidthUnit = options.itemWidthUnit;
            this._itemHeight = options.itemHeight;
            this._itemHeightUnit = options.itemHeightUnit;
            this._initMaxNumberOfItemsToLoad = options.initMaxNumberOfItemsToLoad;
            this._vertical = !!options.vertical;
            this._getModelIdFunction = options.getModelIdFunction ? options.getModelIdFunction : this._getDefaultModelId.bind(this);
            this.models = [];
            this._controllerOptions = options.controllerOption?options.controllerOption: {};
            this.idToControllers = [];
            this.indexToControllers = {};
            this.idToViews = [];
            this.indexToViews = {};
            this.indexToModel = {};
            this._onSlideChangeHandler = options.onSlideChangeHandler;
            this._itemControllerConstructor = options.itemControllerConstructor? options.itemControllerConstructor: null;

        }
    },

    getModel : function() {
        return this.models;
    },

    getView : function() {
        return this.view;
    },

    _getDefaultModelId : function(model){
        return model?model.id : null
    },

    onActionButtonPressed : function(){
        if(this._actionButtonHandlerFunction){
            this._actionButtonHandlerFunction();
        }
    },

    getControllerByModelId : function(modelId){
        return this.idToControllers[modelId];
    },

    destroy :function(){
        this.view.destroy();
    },

    _retrieveModels : function(numberOfItems, offset, doNotChangeUpdateState){
        if(this._modelLoadHandler){
            if(!doNotChangeUpdateState){
                this.view.setUpdating(true);
            }
            return this._modelLoadHandler(numberOfItems, offset).then((function(arrayOfModels){
                if(!doNotChangeUpdateState){
                    this.view.setUpdating(false);
                }
                return arrayOfModels;
            }).bind(this)).catch((function(error){
                if(!doNotChangeUpdateState){
                    this.view.setUpdating(false);
                }
                this.view.setErrorMessage(error);
            }).bind(this));
        }else{
            return RSVP.Promise.resolve();
        }
    },

    onSlideFoward : function(){

    },

    onSlideBackward : function(){

    },

    onViewItemNeededFoward : function(numberOfItemsNeeded, offset){
        if(numberOfItemsNeeded > 0){
            const controllers = [];
            const views = [];
            this._retrieveModels(numberOfItemsNeeded, offset).then((function(models){
                const promises = [];
                if(models){
                    for(let index in  models){
                        const model = models[index];
                        const p = this._buildSingleItemControllerAndView(model).then((function(controller){
                            controllers.push(controller);
                            this.indexToControllers[index + offset ] = controller;
                            views.push(controller.getView());
                            this.indexToViews[index + offset ] = controller.getView();
                            this.indexToModel[index + offset ] =model;
                        }).bind(this));
                        promises.push(p);
                    }
                }

                return RSVP.Promise.all(promises);
            }).bind(this)).then((function(){
                this.view.appendItems(views, numberOfItemsNeeded, offset);
            }).bind(this)).then((function(){
                this.onSlideChangeEnd(offset);
            }).bind(this));
        }
    },

    onViewItemNeededBackward : function(numberOfItemsNeeded, offset){
        if(numberOfItemsNeeded > 0){
            const controllers = [];
            const views = [];
            this._retrieveModels(numberOfItemsNeeded, offset).then((function(models){
                const promises = [];
                if(models){
                    for(let index in  models){
                        const model = models[index];
                        const p = this.this._buildSingleItemControllerAndView(model).then((function(controller){
                            controllers.push(controller);
                            this.indexToControllers[index + offset ] = controller;
                            views.push(controller.getView());
                            this.indexToViews[index + offset ] = controller.getView();
                            this.indexToModel[index + offset ] =model;
                        }).bind(this));
                        promises.push(p);
                    }
                }

                return RSVP.Promise.all(promises);
            }).bind(this)).then((function(){
                this.view.prependItems(views, numberOfItemsNeeded, offset);
            }).bind(this)).then((function(){
                this.onSlideChangeEnd(offset);
            }).bind(this));
        }
    },

    _buildSingleItemControllerAndView : function(model){
        const c = this._itemControllerConstructor;
        const controllerParams = Utils.getInstance().cloneJson(this._controllerOptions);
        controllerParams.model = model;
        const controller = new  c(controllerParams);
        this.idToControllers[this._getModelIdFunction(model)] = controller;
        return controller.buildModelAndRenderView().then((function(){
            this.self.idToViews[this.self._getModelIdFunction(this.model)] = this._controller.getView();
            return controller;
        }).bind({"self":this, "_controller": controller,"model": model}))
    },

    onSlideChangeEnd : function(modelIndex){
        return this._retrieveModels(1, modelIndex, true).then((function(models){
            const model = models && models.length > 0? models[0]: null;
            if(this._onSlideChangeHandler){
                this._onSlideChangeHandler(model);
            }
        }).bind(this));
    },

    updateSwiperWidget : function(){
        this.view.updateSwiperWidget();
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                const viewOptions = {};
                viewOptions.customInfoBoxInfoParams = this._customInfoBoxInfoParams;
                viewOptions.customInfoBoxInfo = this._customInfoBoxInfo;
                viewOptions.initMaxNumberOfItemsToLoad = this._initMaxNumberOfItemsToLoad;
                viewOptions.showEmtptyInfoBox = true;
                viewOptions.itemWidth =this._itemWidth;
                viewOptions.itemWidthUnit = this._itemWidthUnit;
                viewOptions.itemHeight = this._itemHeight;
                viewOptions.itemHeightUnit = this._itemHeightUnit;
                viewOptions.vertical = this._vertical;
                this.view = new CollectionInfiniteCarouselView(viewOptions);
                this.listenTo(this.view, "infoBoxButtonPressed",this.onActionButtonPressed);
                //this.listenTo(this.view, "slidingFoward",this.onSlideFoward);
                this.listenTo(this.view, "viewItemNeededFoward",this.onViewItemNeededFoward);
                this.listenTo(this.view, "slideChangeEnd",this.onSlideChangeEnd);
                this.listenTo(this.view, "viewItemNeededBackward",this.onViewItemNeededBackward);
                //this.listenTo(this.view, "slidingBackward",this.onSlideBackward);
                this.view.render();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

}

});

export default CollectionInfiniteCarouselController;