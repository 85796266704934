import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import UserReferralContentView from "views/referral/UserReferralContentView";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import DynamicPageView from "views/common/DynamicPageView";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import ti18n from "i18n!nls/UserReferrali18n";
import PageTemplate from 'text!../../../templates/referral/UserReferralPageTemplate.html';
import RSVP from "rsvp";

const Rollout =  RolloutHelper.getInstance();
const UserReferralPageController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = options.model;
        this._pageId = options.pageId;
        
    },
    
    _onPageShow : function(){
        
    },
    


    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    showHomePage : function(){
        PersistentModels.getInstance().getRouter().showHomePage();
    },

    
    onSendReferralUrlViaSMSClicked : function(){
        if(this._referralUrl && window.plugins && window.plugins.socialsharing && window.plugins.socialsharing.shareWithOptions){

            
            const onSuccess = function(result) {
                if(result.completed || (result.completed && result.app)){
                    if(window.trebbleAnalyticsHelper){
                        window.trebbleAnalyticsHelper.trackEvent("referralUrlSentViaSMS", 'referralUrlSentViaSMS', 'Referral Url was sent via SMS');
                    }
                }
            };

            const onError = function(msg) {
                window.alertErrorMessage(msg) 
            };


            window.plugins.socialsharing.shareViaSMS(this._buildReferralMessage(), null , onSuccess,onError)

        }

    },

    _buildReferralMessage : function(){
        return window.getI18n(ti18n,"HEY_I_HAVE_BEEN_USING_TREBBLE") + this._referralUrl
    },

    onCopyLinkRefferalButtonClicked : function(){
        if(this._referralUrl && 'cordova' in window && window.cordova && window.cordova.plugins && window.cordova.plugins.clipboard ){
            window.cordova.plugins.clipboard.copy(this._referralUrl);
            Utils.getInstance().showShortToastMessage(window.getI18n(ti18n,"REFERRAL_LINK_COPIED_TO_CLIPBOARD"));
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("referralLinkCopiedToClipboard", 'referralLinkCopiedToClipboard', 'Referral Link Copied To Clipboard');
            }
        }
    },

    onMoreButtonToShareReferralURLClicked : function(){
        if(this._referralUrl && window.plugins && window.plugins.socialsharing && window.plugins.socialsharing.shareWithOptions){
            const options = {
                message: this._buildReferralMessage(),
                subject: this._buildReferralMessage(),
                url: this._buildReferralMessage(),
                
            };

            const onSuccess = function(result) {
                if(result.completed || (result.completed && result.app)){
                    if(window.trebbleAnalyticsHelper){
                        window.trebbleAnalyticsHelper.trackEvent("referralURLSharedViaSocialMedia", 'referralURLSharedViaSocialMedia', 'Referral Url Shared via social media');
                    }
                }
            };

            const onError = function(msg) {
                window.alertErrorMessage(msg) 
            };
            window.plugins.socialsharing.share(this._buildReferralMessage());
            //window.plugins.socialsharing.shareWithOptions(options, onSuccess, onError);
        }

    },
    

    buildModelAndRenderView : function() {
        return Utils.getInstance().generateInviteLink(LocalStorageHelper.getInstance().getUserInfo()._id, "mobile_app", "referral_campaign_jan_15", {"username": LocalStorageHelper.getInstance().getUserInfo().username}).then((function(referralUrl){
            //const limitDateString = LocalStorageHelper.getInstance().getUserRefferalTimeStringLimit();
            //const costPerReferral = LocalStorageHelper.getInstance().getCostPerReferral();
            const limitDateString =  Rollout.getFeatureVariable(Rollout.FEATURES.USER_REFERRAL, Rollout.FEATURES.USER_REFERRAL.variables.referral_program_time_limit_string, "Fri Oct 31 2020 00:00:00 GMT-0500 (GMT-05:00)");
            const costPerReferral = Rollout.getFeatureVariable(Rollout.FEATURES.USER_REFERRAL, Rollout.FEATURES.USER_REFERRAL.variables.cost_per_referral_string, 4);
            const params = {};
            this._referralUrl = referralUrl;
            params.referralUrl =  referralUrl;
            params.limitDate = limitDateString? new Date(limitDateString): null;
            params.costPerReferral =  costPerReferral?costPerReferral: "5";
            const userReferralContentView =  new UserReferralContentView(params);
            this.userReferralContentView = userReferralContentView;
            this.listenTo(this.userReferralContentView, "sendReferralUrlViaSMSClicked",this.onSendReferralUrlViaSMSClicked.bind(this));
            this.listenTo(this.userReferralContentView, "copyLinkRefferalButtonClicked",this.onCopyLinkRefferalButtonClicked.bind(this));
            this.listenTo(this.userReferralContentView, "moreButtonToShareReferralURLClicked",this.onMoreButtonToShareReferralURLClicked.bind(this));
            const pageViewParams= {};
            pageViewParams.pageId = this._pageId;
            pageViewParams.pageViewTemplate = PageTemplate;
            pageViewParams.containerSelector = ".ui-content";
            pageViewParams.contentView = userReferralContentView;
            pageViewParams.onPageShow = this._onPageShow.bind(this);
            pageViewParams.persistent = true;
            pageViewParams.pageTemplateOptions = {"ti18n":ti18n};
            this.view = new DynamicPageView(pageViewParams);
            this.view.render();
            this.view.$el.on("click", "#homeHeaderButton", this.showHomePage.bind(this));
        }).bind(this));


}

});

export default UserReferralPageController;