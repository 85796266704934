import _ from 'underscore';
import SequencerOperation from "models/audioEditor/sequencerOperation/SequencerOperation";


const ChangeSpeakerForParagraphOperation =  SequencerOperation.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperation.apply(this, [attributes, options]);
    },

    execute : function(paragraphSequencerNodeView, newSpeakerInfo, startSequencerNodeToChange, endSequencerNodeToChange){
        if(this.isStartNodeAndEndNodeConnected(startSequencerNodeToChange, endSequencerNodeToChange) && paragraphSequencerNodeView.replaceSpeakerInfo){
            const oldSpeakerInfo  = paragraphSequencerNodeView.getModel();
            const arrayOfSequencerNodeToChange = this.getArrayOfSequencerNodes(startSequencerNodeToChange, endSequencerNodeToChange);
            this._changeSpeakerParagraph(paragraphSequencerNodeView, newSpeakerInfo, arrayOfSequencerNodeToChange);
            this.getSequencer().getHistoryManagement().addSequencerOperationChangeSpeakerForParagraphOperationLog(arrayOfSequencerNodeToChange, oldSpeakerInfo, newSpeakerInfo, paragraphSequencerNodeView);
        }
    },

    _changeSpeakerParagraph : function(paragraphSequencerNodeView, newSpeakerInfo, arrayOfSequencerNodeToChange){
        arrayOfSequencerNodeToChange.map((sequencernNode)=>{
            sequencernNode.getAudioSegment().setSpeakerInfo(newSpeakerInfo);
        })
        paragraphSequencerNodeView.replaceSpeakerInfo(newSpeakerInfo);
    },

    undo : function(sequencerOperationChangeSpeakerForParagraphOperationLog ){
        const oldSpeakerInfo = sequencerOperationChangeSpeakerForParagraphOperationLog.getOldSpeakerInfo();
        const arrayOfSequencerNodeToChange = sequencerOperationChangeSpeakerForParagraphOperationLog.getArrayOfSequencerNodeToChange();
        const paragraphSequencerNodeView = sequencerOperationChangeSpeakerForParagraphOperationLog.getParagraphSequencerNodeView();
        this._changeSpeakerParagraph(paragraphSequencerNodeView, oldSpeakerInfo, arrayOfSequencerNodeToChange);
    },

    redo: function(sequencerOperationChangeSpeakerForParagraphOperationLog){
        const newSpeakerInfo = sequencerOperationChangeSpeakerForParagraphOperationLog.getNewSpeakerInfo();
        const arrayOfSequencerNodeToChange = sequencerOperationChangeSpeakerForParagraphOperationLog.getArrayOfSequencerNodeToChange();
        const paragraphSequencerNodeView = sequencerOperationChangeSpeakerForParagraphOperationLog.getParagraphSequencerNodeView();
        this._changeSpeakerParagraph(paragraphSequencerNodeView, newSpeakerInfo, arrayOfSequencerNodeToChange);
    },

});


export default ChangeSpeakerForParagraphOperation; 