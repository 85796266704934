import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import ti18n from "i18n!nls/ArtistDetailsi18n";
import pageTemplate from 'text!../../../templates/artistDetail/ArtsistDetailPage.html';
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import TrebbleListView from "views/common/TrebbleListView";
import RSVP from "rsvp";

const ArtistDetailPageView = Backbone.View.extend({

    template : _.template(pageTemplate),
    "pageName" : "Artist Detail",

    events : {
        "click #shuffleAlbumBtn" : "onShuffleBtnTapped",
        "click #menuOptionButton" : "onOptionMenuButtonTapped",
        "click #header-back-button": "navigateToPreviousPage",
        "click  #filterButton" : "filterList",
    },

    initialize : function(options) {
        this.model = options.model;
        this.pageId =  options.pageId;
        this._defaultBackgroundColor = "#111111";
    },


    filterList : function(){
        this.trigger("filter");
    },


    navigateToPreviousPage : function(){
        PersistentModels.getInstance().getRouter().navigateToPreviousPage();
    },


    onShuffleBtnTapped : function(){
        this.trigger("shuffleAllSongFromArtist",this.model);
    },

    onOptionMenuButtonTapped : function(){
        this.trigger("showArtistMenuOptionPopup",this.model);
    },

    _getOnListItemTapHandler : function(){
        return  (function(albumJson,evnt, item) {
            this.trigger("itemTaped",this._getAlbumModelFromAlbumJsonOrCreateANewOne(albumJson));		
        }).bind(this);
    },

    _getOnListItemPressHandler : function(){
        return  (function(albumJson,evnt, item) {
            this.trigger("itemPressed",this._getAlbumModelFromAlbumJsonOrCreateANewOne(albumJson));		
        }).bind(this);
    },

    _getOnListItemPressUpHandler : function(){
        return  (function(albumJson,evnt, item) {
            this.trigger("itemPressedUp",this._getAlbumModelFromAlbumJsonOrCreateANewOne(albumJson));		
        }).bind(this);
    },


    _onItemSwipeRight : function(){
        return  (function(albumJson,evnt, item){
            this.trigger("itemSwipedRight",this._getAlbumModelFromAlbumJsonOrCreateANewOne(albumJson));
        }).bind(this);
    },

    _modelTransformHandlerBeforeTemplatePiping : function(albumJson){
        //TODO: this parsing of the song title or artist name need to be added to the song/album model
        let artistToDisplay, albumToDisplay, numberOfSongs, numberOfSongsString;
        if(albumJson)
        {
             artistToDisplay = albumJson.artist;
             albumToDisplay = albumJson.album;
             numberOfSongs =  albumJson.songs.length;
             numberOfSongsString = numberOfSongs.toString() +((numberOfSongs  > 1)? " "+window.getI18n(ti18n, "SONGS"): " "+window.getI18n(ti18n, "SONG"));
        
        }
        if(!artistToDisplay)
        {
            artistToDisplay = "";
        }
        if(!albumToDisplay)
        {
            albumToDisplay = "";
        }

        const templateData = {firstLineText : albumToDisplay, secondLineText : numberOfSongsString, model : albumJson};
        return templateData;
    },

    _getOnListButtonTappedHandler :function(){
        return  (function(albumJson,evnt, item) {
            this.trigger("itemOptionBtnTapped", this._getAlbumModelFromAlbumJsonOrCreateANewOne(albumJson));		
        }).bind(this);
    },

    _getAlbumModelFromAlbumJsonOrCreateANewOne : function(albumJson){
        const existingAlbum = PersistentModels.getInstance().getAlbumModelByAlbumIdFromMyLibrary(albumJson.albumId);
        if(existingAlbum)
        {
            return existingAlbum;
        }else{
            Utils.getInstance().getModelFromAlbumJson(albumJson);
        }
    },




    render : function() {
        this.$el.html(this.template({"model" :this.model.toJSON(), "ti18n": ti18n}));
        this.setElement(this.$el.find("div").first());
        this._buildAlbumList();
        this.$el.page();
        if(this.pageId){
            this.$el.attr("id", this.pageId);
        }
        this.$el.addClass("lighttext");
        this.loadArtistImage();
        this.$el.on("pagehide", this._onPageHide.bind(this));
        return this;
    },

    _onPageHide : function(){

        if(PersistentModels.getInstance().getRouter().isBrowserHistoryGoingBack())
        {
            //PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._listenerViewportKey);
            this.onBeforeRemoveFromPage();
            this.remove();
            this.onRemovedFromPage();
        }
    },

    loadArtistImage : function(){
        if(this.model.get("idifiedArtistImageBase64"))
        {
            return Utils.getInstance().retrieveImageBlurryFromUrl(this.model.get("idifiedArtistImageBase64"), 1, 1).then((function(scaledCoverArtInfo){
                if(scaledCoverArtInfo){
                    return Utils.getInstance().getReadyForDisplayBase64Image(scaledCoverArtInfo.albumArtBase64BlurScreenSize);
                }else{
                    return null;
                }
            }).bind(this)).then((function(base64AlbumArtBlur){
                const artistImageElem = this.el.querySelector("#artistImage");
                //artistImageElem.style.height = (window.device && window.device.platform == "browser")?"500px":"200px";
                const imageSrcData = "data:image/png;base64,"+ this.model.get("idifiedArtistImageBase64") ;
                const base64ImageData = "url('"+ imageSrcData + "')";
                const base64ImageDataBlur = "url('"+ base64AlbumArtBlur + "')";
                const tempImg = new Image();
                tempImg.onload  = (function(){
                    let backgroundInageBase64 = null;
                    const fullCoverArtImage$el = this.self.$el.find("#fullCoverArtImage");
                    if(window.trebble.config.disableZoomInCoverArtInMobileApp || (window.device && window.device.platform == "browser")){
                        backgroundInageBase64 = base64AlbumArtBlur ;
                        this.elem.style["background-image"] = base64ImageDataBlur;
                        fullCoverArtImage$el.css("background-image",base64ImageData).addClass("withShadow inFront");
                    }else{
                        backgroundInageBase64 = this.tempImg.src ;
                        this.elem.style["background-image"] = "url('"+ this.tempImg.src + "')";
                    }
                    Utils.getInstance().getMatchingColorByImageUrl(backgroundInageBase64).then((function(result){
                        this.self.$el.css("background-color",result.backgroundColor);
                        if(result.shouldUserLightColorText){
                            this.self.$el.removeClass("darktext");
                            this.self.$el.addClass("lighttext");
                        }else{
                            this.self.$el.removeClass("lighttext");
                            this.self.$el.addClass("darktext");
                        }
                        this.self.$el.find("#contentWrapper").css("background-color",result.backgroundColor);
                        this.self.listView.$el.css("background-color",result.backgroundColor);
                        const colorString = result.backgroundColorArray.join(",");
                        fullCoverArtImage$el.css({"background-color": "rgb(" + colorString +")"});
                        const basicArtistInfo$el = this.self.$el.find(".basicArtistInfo");
                        const  cornerDecorator$el = this.self.$el.find(".cornerDecorator");
                        const ui_header$el = this.self.$el.find(".ui-header");
                        basicArtistInfo$el.css({"background-image": "-webkit-linear-gradient(top,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                        cornerDecorator$el.css({"background-image": "-webkit-linear-gradient(left,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                        ui_header$el.css({"background-image": "-webkit-linear-gradient(top,rgba("+ colorString + ",0.5) ,rgba("+ colorString + ",0) 100%)"});
                        basicArtistInfo$el.css({"background-image": "-moz-linear-gradient(top,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                        cornerDecorator$el.css({"background-image": "-moz-linear-gradient(left,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                        ui_header$el.css({"background-image": "-moz-linear-gradient(top,rgba("+ colorString + ",0.5) ,rgba("+ colorString + ",0) 100%)"});
                        basicArtistInfo$el.css({"background-image": "-ms-linear-gradient(top,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                        cornerDecorator$el.css({"background-image": "-ms-linear-gradient(left,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                        ui_header$el.css({"background-image": "-ms-linear-gradient(top,rgba("+ colorString + ",0.5) ,rgba("+ colorString + ",0) 100%)"});
                        basicArtistInfo$el.css({"background-image": "-o-linear-gradient(top,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                        cornerDecorator$el.css({"background-image": "-o-linear-gradient(left,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                        ui_header$el.css({"background-image": "-o-linear-gradient(top,rgba("+ colorString + ",0.5) ,rgba("+ colorString + ",0) 100%)"});
                        basicArtistInfo$el.css({"background-image": "linear-gradient(top,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                        cornerDecorator$el.css({"background-image": "linear-gradient(left,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                        ui_header$el.css({"background-image": "linear-gradient(top,rgba("+ colorString + ",0.5) ,rgba("+ colorString + ",0) 100%)"});
                        basicArtistInfo$el.css({"background-image": "progid:DXImageTransform.Microsoft.gradient(startColorstr="+ colorString + ", endColorstr="+ colorString + ")"});
                        cornerDecorator$el.css({"background-image": "progid:DXImageTransform.Microsoft.gradient(startColorstr="+ colorString + ", endColorstr="+ colorString + ")"});
                        ui_header$el.css({"background-image": "progid:DXImageTransform.Microsoft.gradient(startColorstr="+ colorString + ", endColorstr="+ colorString + ")"});
                        if(Utils.getInstance().isBackgroundBlurImageDisabled()){
                            basicArtistInfo$el.css({"background-image": "none"});
                        }
                    }).bind(this));
}).bind({"elem":artistImageElem, "base64Data": base64ImageData, "tempImg": tempImg , "self": this});
if(imageSrcData){
tempImg.src = imageSrcData;
}else{
tempImg.onload();
}
}).bind(this));
}else{
this.retrieveArtistImageAndUpdateView();
}
},

retrieveArtistImageAndUpdateView : function(){
const artistName = this.model.get("artistName");
const screenWidth = window.getDocumentClienWidth();
const screenHeight = window.getDocumentClienHeight();
const regularCovertArtWidth = window.getDocumentClienWidth() - 10;
const regularCovertArtHeight = window.getDocumentClienHeight() -220;
const thumbnailWidth = 64;
const thumbnailHeight = 64;



/* Load some artist info. */
const p = new RSVP.Promise(function(resolve,reject){
try{
    PersistentModels.getInstance().getRouter().require(["libs/lastfm/lastfmAsync"]).then((function(LastFM){
        const lastfm = new LastFM({
            apiKey    : 'babd9fe6de92c54d46998931ceb0622c',
            apiSecret : 'ca97f76316c08d3d0499a2b26c78b90f'
        });
        lastfm.artist.getInfo({artist: artistName}, {success: resolve, error: reject});
    }).bind(this)).catch(reject);

}catch(err){
    reject(err);
}
});
p.then((function(data){
if(data && data.artist){
    const artistData = data.artist;
    const artistImages = artistData.image;
    const bioSummary = artistData.bio.summary;
    let artistImageUrl = null;
    if(artistImages.length >= 5){
        artistImageUrl = artistImages[4];
    }else{
        if(artistImages.length >0){
            artistImageUrl = artistImages[artistImages.length -1];
        }
    }
    if(artistImageUrl && artistImageUrl["#text"] && artistImageUrl["#text"] != ""){

        return Utils.getInstance().retrieveImageBlurryFromUrl(artistImageUrl["#text"], 1, 1).then((function(scaledCoverArtInfo){
            if(scaledCoverArtInfo){
                return Utils.getInstance().getReadyForDisplayBase64Image(scaledCoverArtInfo.albumArtBase64BlurScreenSize);
            }else{
                return null;
            }
        }).bind(this)).then((function(base64AlbumArtBlur){
            const artistImageElem = this.el.querySelector("#artistImage");
            artistImageElem.style.height = (window.trebble.config.disableZoomInCoverArtInMobileApp || (window.device && window.device.platform == "browser"))?"500px":"200px";
            this.el.querySelector(".bioWrapper").innerHTML = bioSummary.trim();
            const base64ImageDataBlur = "url('"+ base64AlbumArtBlur + "')";

            const tempImg = new Image();
            tempImg.onload  = (function(){
                this.elem.style["background-image"] = "url('"+ this.tempImg.src + "')";
                let backgroundInageBase64 = null;
                if(window.trebble.config.disableZoomInCoverArtInMobileApp || (window.device && window.device.platform == "browser")){
                    backgroundInageBase64 = this.tempImg.src ;
                    this.elem.style["background-image"] = base64ImageDataBlur;
                    this.self.$el.find("#fullCoverArtImage").css("background-image","url('"+ this.tempImg.src + "')");
                }else{
                    backgroundInageBase64 = this.tempImg.src ;
                    this.elem.style["background-image"] = "url('"+ this.tempImg.src + "')";
                }
                Utils.getInstance().getMatchingColorByImageUrl(window.isSafariBrowser? artistImageUrl["#text"] :backgroundInageBase64).then((function(result){
                    this.self.$el.css("background-color",result.backgroundColor);
                    this.self._defaultBackgroundColor = result.backgroundColor;
                    if(result.shouldUserLightColorText){
                        this.self.$el.removeClass("darktext");
                        this.self.$el.addClass("lighttext");
                    }else{
                        this.self.$el.removeClass("lighttext");
                        this.self.$el.addClass("darktext");
                    }
                    this.self.$el.find("#contentWrapper").css("background-color",result.backgroundColor);
                    if(this.self.listView){
                        this.self.listView.$el.css("background-color",result.backgroundColor);
                    }
                    const colorString = result.backgroundColorArray.join(",");


                    const  basicArtistInfo$el = this.self.$el.find(".basicArtistInfo");
                    const  ui_header$el = this.self.$el.find(".ui-header");
                    basicArtistInfo$el.css({"background-image": "-webkit-linear-gradient(top,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                    ui_header$el.css({"background-image": "-webkit-linear-gradient(top,rgba("+ colorString + ",0.5) ,rgba("+ colorString + ",0) 100%)"});
                    basicArtistInfo$el.css({"background-image": "-moz-linear-gradient(top,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                    ui_header$el.css({"background-image": "-moz-linear-gradient(top,rgba("+ colorString + ",0.5) ,rgba("+ colorString + ",0) 100%)"});
                    basicArtistInfo$el.css({"background-image": "-ms-linear-gradient(top,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                    ui_header$el.css({"background-image": "-ms-linear-gradient(top,rgba("+ colorString + ",0.5) ,rgba("+ colorString + ",0) 100%)"});
                    basicArtistInfo$el.css({"background-image": "-o-linear-gradient(top,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                    ui_header$el.css({"background-image": "-o-linear-gradient(top,rgba("+ colorString + ",0.5) ,rgba("+ colorString + ",0) 100%)"});
                    basicArtistInfo$el.css({"background-image": "linear-gradient(top,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                    ui_header$el.css({"background-image": "linear-gradient(top,rgba("+ colorString + ",0.5) ,rgba("+ colorString + ",0) 100%)"});
                    basicArtistInfo$el.css({"background-image": "progid:DXImageTransform.Microsoft.gradient(startColorstr="+ colorString + ", endColorstr="+ colorString + ")"});
                    ui_header$el.css({"background-image": "progid:DXImageTransform.Microsoft.gradient(startColorstr="+ colorString + ", endColorstr="+ colorString + ")"});
                }).bind(this));
}).bind({"elem":artistImageElem,  "tempImg": tempImg, "self": this});
if(artistImageUrl["#text"]){
tempImg.src = artistImageUrl["#text"];
}else{
tempImg.onload();
}
}).bind(this));

}

}
}).bind(this)).catch(function(err){
window.alertErrorMessage(err);
})



},


onBeforeRemoveFromPage : function(){
this.trigger("beforeRemoveFromPage", this);
},

onRemovedFromPage : function(){
this.undelegateEvents();
this.$el.removeData().unbind(); 
this.trigger("removedFromPage", this);
},

_buildInfoBoxViewParams : function(){
const params = {};
params.iconClass = "ion-ios7-albums";
params.message = window.getI18n(ti18n, "NO_ALBUM_FOUND_FOR_THIS_ARTIST");
params.helpMessage = "";
return params;
},



_buildAlbumList : function(){
const headerHeight = 152;
const footerHeight = 65;
const paddingHeight = 10*2;
const contentHeight = (window.getDocumentClienHeight() -headerHeight - footerHeight - paddingHeight) ;
const listRootElement = this.$el.find("#albumsListWrapper");
listRootElement.css("height",contentHeight.toString() +'px');

const listParams ={};
listParams.model = this.model.get("albums");
listParams.listRootElement = listRootElement.get(0);
listParams.defaultNumberOfElements = 80;
listParams.defaultHeightElement = 60;
listParams.resizePlaceHolderElem = true;
listParams.placeHolderHeight = contentHeight;
listParams.modelIdAttribute  = "albumId";
listParams.customInfoBoxInfoParams = this._buildInfoBoxViewParams();
const infiniteListView =  new TrebbleListView(listParams);
infiniteListView.setModelTransformHandlerBeforeTemplatePiping(this._modelTransformHandlerBeforeTemplatePiping);
infiniteListView.addOnItemTapHandler(this._getOnListItemTapHandler());
infiniteListView.addOnItemPressHandler(this._getOnListItemPressHandler());
infiniteListView.addOnItemPressUpHandler(this._getOnListItemPressUpHandler());
infiniteListView.addOnOptionButtonTappedHandlers(this._getOnListButtonTappedHandler());
infiniteListView.addOnSwipeRightRevealHandler(this._onItemSwipeRight());
infiniteListView.render();
this.listView = infiniteListView;
this.listView.$el.css("background-color",this._defaultBackgroundColor );
}

});

export default ArtistDetailPageView;