import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import UserOptionView from "views/user/UserOptionView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utils from "models/helper/Utils";
import FileUploadHelper from "models/helper/FileUploadHelper";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import PersistentModels from "services/PersistentModels";
import FacebookHelper from "models/helper/FacebookHelper";
import GooglePlusHelper from "models/helper/GooglePlusHelper";
import ti18n from "i18n!nls/UserProfileDetailsPagei18n";
import RSVP from "rsvp";
import swal from 'sweetalert';

const UserOptionController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.model = options? options.userModel : null;
        this.context = options? options.context : null;
        this.elementSource$el = options? options.elementSource$el : null;
        this.onActionExecutedOnUserHandler = options? options.onActionExecutedOnUserHandler: null;
        _.extend(this, Backbone.Events);
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    showEditUserDetails : function(){
        PersistentModels.getInstance().getRouter().showUserEditProfileInfo(true );

    },

    follow : function(){
        return TrebbleClientAPIHelper.getInstance().followUserByUsername(this.model.getUsername()).then((function(){
            this.executeActionCallback("followUser");
        }).bind(this)).catch((function(error){
            window.alertErrorMessage(error);
        }).bind(this));
    },

    unfollowConfirm : function(){
        const buttonLabels = [window.getI18n(ti18n, "CANCEL"), window.getI18n(ti18n, "UNFOLLOW")];
        const confirmCallback = (function(buttonIndex){
            if(buttonIndex == 2){
                this.self.unfollow();
            }
        }).bind({"self": this});
        const message = window.getI18n(ti18n, "UNFOLLOW")+" @"+this.model.getUsername()+"?";
        navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n, "UNFOLLOW_USER"), buttonLabels);

    },

    unfollow : function(){
        return TrebbleClientAPIHelper.getInstance().unfollowUserByUsername(this.userUsername).then((function(){
            this.executeActionCallback("unfollowUser");
        }).bind(this)).catch((function(error){
            window.alertErrorMessage(error);
        }).bind(this));
    },


    executeActionCallback :function(action, data){
        if(this.onActionExecutedOnUserHandler){
            this.onActionExecutedOnUserHandler(this.model,action, data);
        }
    },
    
    updateProfileImage : function(){
        let uploadPromise = null;
        if(window.device && window.device.platform =="browser"){
            uploadPromise =  this.uploadProfileImageFromWebBrowser();
        }else{
            uploadPromise =  this.uploadProfileImageFromMobile();
        }
        let imageProfileUrl;
        return uploadPromise.then((function(uploadedImageFileUrl){
            $.mobile.loading('show');
            if(uploadedImageFileUrl){
                imageProfileUrl = uploadedImageFileUrl;
                return TrebbleClientAPIHelper.getInstance().updateUserProfileImage(imageProfileUrl).then((function(imageProfileUrlBlur){
                    return Utils.getInstance().preloadImageUsingUrl(imageProfileUrlBlur);
                }).bind(this));
            }

        }).bind(this)).then((function(imageProfileUrlBlur){
            if(imageProfileUrl){
                this.model.setProfileImageUrl(imageProfileUrl);
                this.model.setProfileImageUrlBlur(imageProfileUrlBlur);
                this.executeActionCallback("updateProfilePicture",{"imageProfileUrl": imageProfileUrl, "imageProfileUrlBlur": imageProfileUrlBlur});
            }
        }).bind(this)).then((function(){
            $.mobile.loading('hide');
            navigator.trebbleNotification.alert(window.getI18n(ti18n, "YOUR_PROFILE_PICUTRE_WAS_SUCCESSFULLY_CHANGED"), null, false, "success");
        }).bind(this)).catch((function(error){
            $.mobile.loading('hide');
            navigator.trebbleNotification.alert(error, null, false, "error");
        }).bind(this));
    },

    uploadProfileImageFromWebBrowser : function(){
        return new  RSVP.Promise((function(resolve, reject){
            const uploadEementId = "uploadWrapper_"+((new Date()).getTime());
            const nameOfFileToUpload =  "userProfileImage_"+(new Date()).getTime()+".jpg";
            const text = window.isMobileBrowser?window.getI18n(ti18n, "TAP_TO_SELECT_IMAGE") : window.getI18n(ti18n, "CLICK_TO_SELECT_IMAGE");
            const uploadZoneHtml=  "<span class='uploadZonePlaceHolder' id='"+uploadEementId +"'><div class='pe-7s-upload uploadIcon' style='font-size:40px'></div><span  class='dz-message'>"+ text +"</span></span>";
            const buttonLabels = [window.getI18n(ti18n, "CANCEL"), window.getI18n(ti18n, "SAVE")];
            let dropZoneObj =  null;
            const confirmCallback = (function(buttonIndex){
                if(buttonIndex ==  2){
                    if(dropZoneObj){
                        if(dropZoneObj.getQueuedFiles().length == 0){
                            setTimeout(function(){swal.enableButtons()}, 300);
                        }else{
                            FileUploadHelper.getInstance().validateAndSignFunctionHanlder(dropZoneObj, nameOfFileToUpload, FileUploadHelper.getInstance().getImageFileTypeValidationFunction(), "getSignatureForUserProfileImageUpload" ).then(function(){
                                dropZoneObj.processQueue();
                            }).catch(function(error){
                                navigator.trebbleNotification.alert(error, null, false, "error");
                            })

                        }
                    }
                }
            }).bind(this);
            navigator.trebbleNotification.confirm(uploadZoneHtml , confirmCallback, window.getI18n(ti18n, "CHANGE_PROFILE_PICTURE"), buttonLabels, true, null, null, true);
            const handlers = Utils.getInstance().getConfirmDialogDeferredHandlers(resolve, reject, this);
            dropZoneObj =  FileUploadHelper.getInstance().uploadUserProfileImageInWebBrowser(nameOfFileToUpload,"#"+ uploadEementId,handlers.resolve,handlers.reject);
        }).bind(this));
},

uploadProfileImageFromMobile : function(){
const imageProfileUrl = null
return Utils.getInstance().getUserToPickAPicture().then((function(imageLocationUrl){
$.mobile.loading('show');
return FileUploadHelper.getInstance().uploadUserProfileImage(imageLocationUrl);
}).bind(this));
},

logOutConfirm : function(){
const buttonLabels = [window.getI18n(ti18n, "NO"), window.getI18n(ti18n, "YES")];
const confirmCallback = (function(buttonIndex){
if(buttonIndex ==  2){
    this.logOut();
}
}).bind(this);
const message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_LOG_OUT");
navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n, "LOG_OUT"), buttonLabels);
},
changePassword : function(){
PersistentModels.getInstance().getRouter().showUserChangePasswordPage(true );
},




logOut : function(){
LocalStorageHelper.getInstance().logoutUserAndClearInfo();
if(navigator.splashscreen){
navigator.splashscreen.show();
}

window.clearUserIdForTracking();

document.body.style.display="none";
if(window.device && window.device.platform =="browser"){
const currentUrl = document.location.href;
if(currentUrl.indexOf("https://") == 0){
    document.location.href = currentUrl.substr(0,currentUrl.indexOf("/",("https://").length));
}else{
    if(currentUrl.indexOf("http://") == 0){
        document.location.href = currentUrl.substr(0,currentUrl.indexOf("/",("http://").length));
    }else{
        if(window.waitForCordovaToLoad){
            FacebookHelper.getInstance().logout();
            GooglePlusHelper.getInstance().logout();
            window.location = window.location.href.substr(0,window.location.href.indexOf("#"))//remove hash
        }else{
            document.location.href =  "https://web.trebble.fm";
        }
    }
}
}else{
document.location.href = "trebbleLibrary140.html";
document.location.reload(); 
}
//navigator.app.loadUrl("file:///android_asset/www/index.html", {wait:2000, loadingDialog:"Wait,Loading App", loadUrlTimeoutValue: 60000});
},

buildModelAndRenderView : function() {
return new RSVP.Promise((function(resolve, reject) {
try {

    this.view = new UserOptionView({
        "model" : this.model,
        "elementSource$el": this.elementSource$el
    });
    this.listenTo(this.view, "showEditUserDetails",this.showEditUserDetails);
    this.listenTo(this.view, "follow",this.follow);
    this.listenTo(this.view, "unfollow",this.unfollowConfirm);
    this.listenTo(this.view, "updateProfileImage",this.updateProfileImage);
    this.listenTo(this.view, "changePassword",this.changePassword);
    this.listenTo(this.view, "logOut",this.logOutConfirm);
    this.view.render();
    resolve();
} catch (error) {
    reject(error);
}

}).bind(this));

}

});

export default UserOptionController;