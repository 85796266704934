import $ from 'jquery';
import Hammer from 'hammerjs';

window.requestAnimFrame = (function() {
    return window.requestAnimationFrame || 
    window.webkitRequestAnimationFrame || 
    window.mozRequestAnimationFrame || 
    function(callback) {
        window.setTimeout(callback, 1000 / 60);
    };
})();






const attachHammerToJquery = function () {
  (function (factory) {
    /* if (typeof define === 'function' && define.amd) {
        define(['jquery', 'hammerjs'], factory);
    } else if (typeof exports === 'object') {
        factory(require('jquery'), require('hammerjs'));
    } else {*/
    factory($, Hammer)
    //}
  })(function ($, Hammer) {
    function hammerify (el, options) {
      var $el = $(el)
      if (!$el.data('hammer')) {
        $el.data('hammer', new Hammer($el[0], options))
      }
    }

    $.fn.hammer = function (options) {
      return this.each(function () {
        hammerify(this, options)
      })
    }

    // extend the emit method to also trigger jQuery events
    Hammer.Manager.prototype.emit = (function (originalEmit) {
      return function (type, data) {
        originalEmit.call(this, type, data)
        $(this.element).trigger({
          type: type,
          gesture: data
        })
      }
    })(Hammer.Manager.prototype.emit)
  })
}



function infiniteListAdapter(placeHodlerElem, template, models, itemEventHandlers, numberOfDummyElem, defaultElemHeight, itemUpdateHandlerFunction, overrideMainContainerStyleMap, overrideSingleItemStyleMap, resizePlaceHolderIfModelsSizeIsInferiorToNumberDummyElems, placeHolderDefaultHeight, onModelBeingUpdatedListener, idAttribute, numberOfItemsPerRow) {
    this.nextItemToDisplay = 0;
    this.firstItemToDisplayed = 0;
    this.firstItemEl;
    this.defaultElementHeight = defaultElemHeight;
   // this.lastItemEl;
    this.lastSCrollingInPx;
    this.firstSCrollingInPx;
    this.arrayOfLiItems = [];
    this.modelKeyToLiItemMap = {};
    this.arrayOfModels = [];
    this.totalNumbErOFItems = 1000;
    this.scrollingWidth = 0;
    this.containerElem;
    this.containerRect;
    this.containerTop;
    this.containerBottom;
    this.eventQueue = [];
    this.lastEventDate = new Date();
    this.isEventTreatementRunning = false;
    this.firstItemHeight;
    //this.lastItemHeight;
    this.listPlaceHolderElem = placeHodlerElem;
    this._template = template;
    this.itemEventHandlerMap = itemEventHandlers;
    this._idAttribute = idAttribute;
    this.itemUpdateHandlerFunction = itemUpdateHandlerFunction;
    this.resizePlaceHolderIfModelsSizeIsInferiorToNumberDummyElems = resizePlaceHolderIfModelsSizeIsInferiorToNumberDummyElems ? resizePlaceHolderIfModelsSizeIsInferiorToNumberDummyElems : false;
    this.lastDragDirection;
    this.placeHolderDefaultHeight = placeHolderDefaultHeight? placeHolderDefaultHeight : document.documentElement.clientHeight;
    this.supportedHammerEventArray = ["hold", "tap", "doubletap", "pan", "panstart", "panend", "panup", "pandown", "panleft", "panright", "swipe", "swipeup", "swipedown", "swipeleft", "swiperight", "transform", "transformstart", "transformend", "rotate", "pinch", "pinchin", "pinchout", "touch", "release","press", "pressup"];
    
    this.innerContainerDefaultClassName = "infiniteListInnerContainer";
    this.outerContainerDefaultClassName = "infiniteListOuterContainer";
    this._isScrolling  = false;
    this._delayBeforeUpdateUpdatingIsScrollingValue  = 100;
    this._onModelBeingUpdatedListener = onModelBeingUpdatedListener;
    this.DEFAULT_PRESS_TIME = 1000;
    this._numberOfItemsPerRow = numberOfItemsPerRow? numberOfItemsPerRow: 1;
    
    this.innerContainerStyle = {
        "height": "0%",
        "width": "100%",
        "overflow": "visible",
        "position": "relative"
    };
    
    this.mainContainerStyle = {
        "overflow-y": "auto",
        "overflow-x": "hidden",
        "-webkit-overflow-scrolling": "touch",
        "position": "relative",
        "width": "100%",
        "height": "100%"
    };
    
    this.overrideMainContainerStyleMap = overrideMainContainerStyleMap;
    this.overrideSingleItemStyleMap = overrideSingleItemStyleMap;
    
    this.innerContainerId = "innerContainer";
    this.mainContainerId = "container";
    this.innerContainerElem = null;
    this.mainContainerElem = null;
    this.dummyItemElemStyle = {
        "position": "absolute",
        "width": "100%"
    };
    this.numberOfInitialElement = numberOfDummyElem;
    
    this.arrayOfModels = models;
    this._idToModelMap = {};
    if(this.arrayOfModels && this._idAttribute ){
        for(let index in this.arrayOfModels){
            var model =this.arrayOfModels[index];
            var key = model[this._idAttribute];
            this._idToModelMap[key] = model;
        }
    }




    //window.setInterval(this.eventTreatmentDaemon.bind(this),0);





    //		window.onload = function(){
    const itemData = [];
    this.nextItemToDisplay = 0;
    this.initContainer();
    this.containerElem = this.mainContainerElem;
    const containerElemRect = this.containerElem.getBoundingClientRect();
    this.containerTop = containerElemRect.top
    this.containerBottom = containerElemRect.bottom;

    //Creating factice data-structure
    //TODO: need to be replace by a collection of model objects to display
    for (let i = 0; i < this.totalNumbErOFItems; i++) 
    {
        itemData.push(i);
    }



    //TODO: Need to be able to provide dynamically container id
    var container = this.mainContainerElem;
    if (container.addEventListener) {
        // IE9, Chrome, Safari, Opera
        Hammer(container).on("panup ", this.MouseWheelHandler.bind(this), false);
        Hammer(container).on("pandown", this.MouseWheelHandler.bind(this), false);
        Hammer(container).on("panend", this.onDragEnd, false);
        Hammer(container).on("panstart", this.onDragStart, false);
        container.addEventListener("mousewheel", this.MouseWheelHandler.bind(this), false);
        container.addEventListener("scroll", this.MouseWheelHandler.bind(this), false);
        window.log("added scroll event handler to the container");
    }


//this.initContainer();

//}


}


infiniteListAdapter.prototype.onDragEnd = function(event) 
{
    
    window.log("This is a dragEndddddddd");
    window.log(event);
};

infiniteListAdapter.prototype.onDragStart = function(event) 
{
    
    window.log("This is a dragStarttttttt");
    window.log(event);
};

//Function to treat scrolling event
//This function will just add the event to the globalEventManager
infiniteListAdapter.prototype.MouseWheelHandler = function (event) {
  window.log(event)
  //TODO: need to create the innerDiv dynamically give a container element id
  //innerContainer = this.innerContainerElem;
  //TDO: need to figure out hot to get value from scrolling event on mobile browser
  //this.scrollingWidth = this.scrollingWidth + event.wheelDeltaY;
  //	window.log(this.scrollingWidth);
  //	innerContainer.style["-webkit-transform"] = "translate(0px, "+ this.scrollingWidth +"px)";
  //this.lastSCrollingInPx = lastSCrollingInPx + event.wheelDeltaY;
  //	this.firstSCrollingInPx = this.firstSCrollingInPx + event.wheelDeltaY;
  let newDateEVent = new Date()
  this.lastEventDate = newDateEVent
  if (event.type == 'panup') {
    this.lastDragDirection = 'panup'
    //this.eventQueue.push(event);
  }
  if (event.type == 'pandown') {
    this.lastDragDirection = 'pandown'
    //this.eventQueue.push(event);
  }
  var newScrollTopValue = null
  var scrollPositionDidntChange = false
  if (event.type == 'scroll' || event.type == 'mousewheel') {
    if (event.type == 'scroll') {
      let target = event.srcElement || event.target
      newScrollTopValue = target.scrollTop
    } else {
      newScrollTopValue = event.currentTarget.scrollTop
    }
    if (this._lastScollTopValue) {
      if (this._lastScollTopValue > newScrollTopValue) {
        this.lastDragDirection = 'pandown'
      } else {
        if (this._lastScollTopValue < newScrollTopValue) {
          this.lastDragDirection = 'panup'
        } else {
          scrollPositionDidntChange = true
          window.log("scroll position didn't change")
        }
      }
    }
    this._lastScollTopValue = newScrollTopValue
    if (this._isScrollingFinishedTriigerFunctionId) {
      clearTimeout(this._isScrollingFinishedTriigerFunctionId)
    }
    this._isScrolling = true
    this._isScrollingFinishedTriigerFunctionId = setTimeout(
      function () {
        this._isScrolling = false
        this._isScrollingFinishedTriigerFunctionId = null
      }.bind(this),
      this._delayBeforeUpdateUpdatingIsScrollingValue
    )
  }
  if (
    (event.type == 'scroll' || event.type == 'mousewheel') &&
    this.lastDragDirection &&
    !scrollPositionDidntChange
  ) {
    let newEvent = {}
    newEvent.type = this.lastDragDirection
    newEvent.converted = true
    newEvent.orginialEvent = event
    newEvent.scrollTop = newScrollTopValue

    if (event.type == 'scroll') {
      let target = event.srcElement || event.target
      newEvent.clientHeight = target.clientHeight
    } else {
      newEvent.clientHeight = event.currentTarget.clientHeight
    }
    if (this.isEventTreatementRunning) {
      this.eventQueue.push(newEvent)
      this.treatEvent()
    } else {
      this.treatEventNow(newEvent)
    }
  }
}


infiniteListAdapter.prototype.isScrolling = function() 
{
   return this._isScrolling;
}

infiniteListAdapter.prototype.isScrollingDown = function(event) 
{
    if (event.wheelDelta > 0) 
    {
        return false;
    } else {
        return true;
    }
}

infiniteListAdapter.prototype.resizePlaceHolderIfApplicable = function () {
  if (this.resizePlaceHolderIfModelsSizeIsInferiorToNumberDummyElems) {
    var placeHolderHeight = null
    var estimatedNumberOfRows = Math.round(
      this.arrayOfModels.length / this._numberOfItemsPerRow
    )
    if (estimatedNumberOfRows <= this.numberOfInitialElement) {
      placeHolderHeight = estimatedNumberOfRows * this.defaultElementHeight
    } else {
      placeHolderHeight = this.placeHolderDefaultHeight
    }
    if (placeHolderHeight && placeHolderHeight != 0) {
      this.listPlaceHolderElem.style.height =
        placeHolderHeight.toString() + 'px'
    } else {
      this.listPlaceHolderElem.style.height = 'auto'
    }
  }
}


infiniteListAdapter.prototype.initInnerContainer = function() {
    this.innerContainerElem = document.createElement("div");
    this.innerContainerElem.id = this.innerContainerId;
  //  this.applyCssStyle(this.innerContainerElem, this.innerContainerStyle);
    this.innerContainerElem.className = this.innerContainerElem.className? (this.innerContainerElem.className + " "+ this.innerContainerDefaultClassName) : this.innerContainerDefaultClassName;
    this.mainContainerElem.appendChild(this.innerContainerElem);
}

infiniteListAdapter.prototype.initMainContainer = function() {
    this.mainContainerElem = document.createElement("div");
    this.mainContainerElem.id = this.mainContainerId;
    //this.applyCssStyle(this.mainContainerElem, this.mainContainerStyle);
    this.mainContainerElem.className = this.mainContainerElem.className? (this.mainContainerElem.className + " "+ this.outerContainerDefaultClassName) : this.outerContainerDefaultClassName;
    if (this.overrideMainContainerStyleMap) 
    {
        this.applyCssStyle(this.mainContainerElem, this.overrideMainContainerStyleMap);
    }
    this.listPlaceHolderElem.appendChild(this.mainContainerElem);
}

infiniteListAdapter.prototype.initContainer = function() {
    this.lastSCrollingInPx = 0;
    this.firstSCrollingInPx = 0;
    let lastElementAddedIsVisible = true;
    var i = 0;
    this.resizePlaceHolderIfApplicable();
    this.initMainContainer();
    this.initInnerContainer();
    var liElement = null;
    var randomHeight = null;
    this.nextItemToDisplay = -1;
    while (i < this.arrayOfModels.length && lastElementAddedIsVisible) 
    // while ( lastElementAddedIsVisible) 
    {
        
     
        //TODO: Should use sandboxinh to be able to figure out the size of the item
        randomHeight = this.getRandomHeight();
        liElement = this.createDummyItem(i);
        if (i < this.arrayOfModels.length) 
        {
            liElement.model = this.getNextModelOnBottom();
        
        } else {
            liElement.cachedData.displayPropertyValue = liElement.style.display;
            liElement.style.display = "none";
            liElement.cachedData.frameworkHidden = true;
        }
        this.addEventToDummyItem(liElement, liElement.model);
        this._addModelKeyRefMapToListElement(liElement , liElement.model);
        
        randomHeight = liElement.myHeight;
        this.arrayOfLiItems.push(liElement);
        liElement.style["-webkit-transform"] = "translate3d(0px, " + this.lastSCrollingInPx + "px, 0px)";
        liElement.style["-moz-transform"] = "translate(0px, " + this.lastSCrollingInPx + "px)";
        liElement.style["transform"] = "translate(0px, " + this.lastSCrollingInPx + "px)";
        liElement.style["-ms-transform"] = "translate(0px, " + this.lastSCrollingInPx + "px)";
        liElement.style["-o-transform"] = "translate(0px, " + this.lastSCrollingInPx + "px)";
        liElement.myYSCrolling = this.lastSCrollingInPx;
        this.innerContainerElem.appendChild(liElement);
        if (i == 0) 
        {
            //Keeping track of the height of the first element
            this.firstItemHeight = liElement.myHeight;
            this.firstItemEl = liElement;
            this.firstItemToDisplayed = i;
        }
        this.lastSCrollingInPx = this.lastSCrollingInPx + randomHeight;
       // lastElementAddedIsVisible = this.isElementVisible(liElement);
        
        i = i + this._numberOfItemsPerRow;
        this.nextItemToDisplay =  this.nextItemToDisplay + this._numberOfItemsPerRow;
        if (this.numberOfInitialElement > 0) 
        {
            if (this.arrayOfLiItems.length < this.numberOfInitialElement) 
            {
                lastElementAddedIsVisible = true;
            } else {
                lastElementAddedIsVisible = false;
            }
        }
    
    }
    this.innerContainerElem._estimatedHeight = Math.floor(this.arrayOfModels.length / this._numberOfItemsPerRow) * randomHeight;
    this.innerContainerElem.style.height =Math.floor(this.arrayOfModels.length / this._numberOfItemsPerRow) * randomHeight +"px";
 /*   if(liElement)
    {
        this.lastItemEl = liElement;
        this.lastItemHeight = randomHeight;
    }*/
    

}

infiniteListAdapter.prototype._addNewDummyElement = function(modelIndex) {
    
    if (modelIndex < this.arrayOfModels.length) 
    {
        var liElement = null;
        var randomHeight = null;
        this.nextItemToDisplay = modelIndex -1;
        //TODO: Should use sandboxinh to be able to figure out the size of the item
        randomHeight = this.getRandomHeight();
        liElement = this.createDummyItem(this.nextItemToDisplay + 1);
        liElement.model = this.getNextModelOnBottom();
        var numberOfElementRendered = 1;
        if(liElement.model instanceof Array){
            numberOfElementRendered = liElement.model.length;
        }
        this._addModelKeyRefMapToListElement(liElement , liElement.model);
        this.addEventToDummyItem(liElement, liElement.model);
        
        randomHeight = liElement.myHeight;
        this.arrayOfLiItems.push(liElement);
        liElement.style["-webkit-transform"] = "translate3d(0px, " + this.lastSCrollingInPx + "px, 0px)";
        liElement.style["transform"] = "translate(0px, " + this.lastSCrollingInPx + "px)";
        liElement.style["-moz-transform"] = "translate(0px, " + this.lastSCrollingInPx + "px)";
        liElement.style["-ms-transform"] = "translate(0px, " + this.lastSCrollingInPx + "px)";
        liElement.style["-o-transform"] = "translate(0px, " + this.lastSCrollingInPx + "px)";
        liElement.myYSCrolling = this.lastSCrollingInPx;
        this.innerContainerElem.appendChild(liElement);
        let i  = modelIndex;
        if (i === 0) 
        {
            //Keeping track of the height of the first element
            this.firstItemHeight = liElement.myHeight;
            this.firstItemEl = liElement;
            this.firstItemToDisplayed = i;
        }
        this.lastSCrollingInPx = this.lastSCrollingInPx + randomHeight;
        this.nextItemToDisplay= this.nextItemToDisplay + numberOfElementRendered;
        return liElement;
        /*lastElementAddedIsVisible = this.isElementVisible(liElement);
        i++;
        if (this.numberOfInitialElement > 0) 
        {
            if (i < this.numberOfInitialElement) 
            {
                lastElementAddedIsVisible = true;
            } else {
                lastElementAddedIsVisible = false;
            }
        }*/
        
       /* this.lastItemEl = liElement;
        this.lastItemHeight = randomHeight;*/
    }
}

infiniteListAdapter.prototype._removeModelKeyRefMapToListElement = function(liElement, models){
	if(this._idAttribute){
		var modelKeys = this.getKeyForModel(models);
		if(modelKeys){
			if(modelKeys instanceof Array){
				for(let i in modelKeys){
					delete this.modelKeyToLiItemMap[modelKeys[i]];
				}
			}else{
				delete this.modelKeyToLiItemMap[modelKeys];
			}
		}
	}
}
infiniteListAdapter.prototype._addModelKeyRefMapToListElement = function(liElement){
	if(this._idAttribute){
		var modelKeys = this.getKeyForModel(liElement.model);
		if(modelKeys){
			if(modelKeys instanceof Array){
				for(let i in modelKeys){
					this.modelKeyToLiItemMap[modelKeys[i]] = liElement;
				}
			}else{
				this.modelKeyToLiItemMap[modelKeys] = liElement;
			}
		}
	}
}
infiniteListAdapter.prototype._notifyListenersOnModelUpdateStarting = function(){
	window.log("Start Updating models of infiniteList");
	if(this._onModelBeingUpdatedListener){
		this._onModelBeingUpdatedListener("updatingModels");
	}
	this._modelUpdateStatus = {};
	this._modelUpdateStatus.addModelsFunctionRecursiveStatus = false;
	this._modelUpdateStatus.addDummiesElementFunctionRecursiveStatus = false;
	this._modelUpdateStatus.hideAllOtherDummyElementUnusedFunctionRecursiveStatus = false;
}

infiniteListAdapter.prototype._notifyAddModelsFunctionRecursiveDone = function(){
	if(this._modelUpdateStatus){
	this._modelUpdateStatus.addModelsFunctionRecursiveStatus = true;
	}
	this._notifyListenersOnModelUpdateDoneIfApplicable();
}

infiniteListAdapter.prototype._notifyAddDummiesElementFunctionRecursiveDone = function(){
	if(this._modelUpdateStatus){
	this._modelUpdateStatus.addDummiesElementFunctionRecursiveStatus = true;
	}
	this._notifyListenersOnModelUpdateDoneIfApplicable();
}

infiniteListAdapter.prototype._notifyHideAllOtherDummyElementUnusedFunctionRecursiveDone = function(){
	if(this._modelUpdateStatus){
		this._modelUpdateStatus.hideAllOtherDummyElementUnusedFunctionRecursiveStatus = true;
	}
	this._notifyListenersOnModelUpdateDoneIfApplicable();
}

infiniteListAdapter.prototype._notifyListenersOnModelUpdateDoneIfApplicable = function() {

	if (this._modelUpdateStatus) {
		if (this._modelUpdateStatus.addModelsFunctionRecursiveStatus
				&& this._modelUpdateStatus.addDummiesElementFunctionRecursiveStatus
				&& this._modelUpdateStatus.hideAllOtherDummyElementUnusedFunctionRecursiveStatus) {
			if (this._onModelBeingUpdatedListener) {
				this._onModelBeingUpdatedListener("modelsUpdated");
			}
			this._modelUpdateStatus = null;
			window.log("Done Updating models of infiniteList");
		}
	} else {
		console.warn("no need to notify listeners as listeners were probably notified already");
	}

}
infiniteListAdapter.prototype.updateModel = function(newModel) {
	var modelUpdated = false;
	if(newModel){
        if(this._idAttribute ){ 
            var key = newModel[this._idAttribute];
            var oldModel = this._idToModelMap[key] ;
            if(oldModel){
                //let oldModelIndex = this.arrayOfModels.indexOf(oldModel);
                for (var attrname in newModel) {
                    oldModel[attrname] = newModel[attrname]; 
            }
            //this.arrayOfModels[oldModelIndex] = newModel;
            // this._idToModelMap[key] = newModel;
            this.refreshLiElem(this.modelKeyToLiItemMap[key]);
            modelUpdated = true;
        }
    }
        if(!modelUpdated){
            console.warn("No model was found to update so new model will be added to rest of the collection");
            this.arrayOfModels.push(newModel);
            this.updateModels(this.arrayOfModels);
        }
	}
}


infiniteListAdapter.prototype.updateModels = function(newModels) {
	if(!newModels){
		newModels = [];
	}
    //window.log("New List models:"+ JSON.stringify(newModels));
	this._notifyListenersOnModelUpdateStarting();
	var oldScrollPosition = this.containerElem.scrollTop;
    this.containerElem.scrollTop = 0;
    this.arrayOfModels = newModels;
    this._idToModelMap = {};
    if(this.arrayOfModels && this._idAttribute ){
        for(let index in this.arrayOfModels){
            var model =this.arrayOfModels[index];
            var key = model[this._idAttribute];
            this._idToModelMap[key] = model;
        }
    }
    this.nextItemToDisplay = -1;
    this.lastSCrollingInPx = 0;
    this.firstSCrollingInPx = 0;
    // lastElementAddedIsVisible = true;
    this._itemElementPlaceholder = null;
    this._updateIndex = 0;
    this._renderedModels = 0;
    this.resizePlaceHolderIfApplicable();
    var randomHeight = this.getRandomHeight();
    
 
    while ((this._renderedModels< this.arrayOfModels.length) && (Math.floor(this._renderedModels / this._numberOfItemsPerRow) < this.arrayOfLiItems.length) && this._updateIndex <  this.arrayOfLiItems.length) 
        {
            
            

            this._itemElementPlaceholder = this.arrayOfLiItems[this._updateIndex];
            this._removeModelKeyRefMapToListElement(this._itemElementPlaceholder , this._itemElementPlaceholder.model);
            if (this._itemElementPlaceholder.cachedData.frameworkHidden) 
            {
                this._itemElementPlaceholder.style.display = this._itemElementPlaceholder.cachedData.displayPropertyValue;
                this._itemElementPlaceholder.cachedData.frameworkHidden = false;
            }
            if (this.itemUpdateHandlerFunction && this._itemElementPlaceholder.model) 
            {
                this.itemUpdateHandlerFunction(this._itemElementPlaceholder, this.getNextModelOnBottom());
                this._itemElementPlaceholder.style["-webkit-transform"] = "translate3d(0px, " + this.lastSCrollingInPx + "px, 0px)";
                this._itemElementPlaceholder.style["transform"] = "translate(0px, " + this.lastSCrollingInPx + "px)";
                this._itemElementPlaceholder.style["-moz-transform"] = "translate(0px, " + this.lastSCrollingInPx + "px)";
                this._itemElementPlaceholder.style["-ms-transform"] = "translate(0px, " + this.lastSCrollingInPx + "px)";
                this._itemElementPlaceholder.style["-o-transform"] = "translate(0px, " + this.lastSCrollingInPx + "px)";
            } else {
                this._itemElementPlaceholder.innerHTML = this.getNextContentOnBottom();
                this._itemElementPlaceholder.style["-webkit-transform"] = "translate3d(0px, " + this.lastSCrollingInPx + "px, 0px)";
                this._itemElementPlaceholder.style["transform"] = "translate(0px, " + this.lastSCrollingInPx + "px)";
                this._itemElementPlaceholder.style["-moz-transform"] = "translate(0px, " + this.lastSCrollingInPx + "px)";
                this._itemElementPlaceholder.style["-ms-transform"] = "translate(0px, " + this.lastSCrollingInPx + "px)";
                this._itemElementPlaceholder.style["-o-transform"] = "translate(0px, " + this.lastSCrollingInPx + "px)";
            }
            this.addEventToDummyItem(this._itemElementPlaceholder, this._itemElementPlaceholder.model);
            this._itemElementPlaceholder.model = this.getNextModelOnBottom();
            this._addModelKeyRefMapToListElement(this._itemElementPlaceholder , this._itemElementPlaceholder.model);

            this._itemElementPlaceholder.myHeight = randomHeight;
            this._itemElementPlaceholder.myYSCrolling = this.lastSCrollingInPx;
            if (this._renderedModels== 0) 
            {
                //Keeping track of the height of the first element
                this.firstItemHeight = this._itemElementPlaceholder.myHeight;
                this.firstItemEl = this._itemElementPlaceholder;
                this.firstItemToDisplayed = this._renderedModels;
            }
            this.lastSCrollingInPx = this.lastSCrollingInPx + randomHeight;
            // lastElementAddedIsVisible = this.isElementVisible(this._itemElementPlaceholder);
            this._renderedModels= (this._itemElementPlaceholder.model instanceof Array)? this._renderedModels + this._itemElementPlaceholder.model.length: this._renderedModels + 1;
            this._updateIndex = this._updateIndex + 1;
            this.nextItemToDisplay = this._renderedModels- 1;
        }
    this._notifyAddModelsFunctionRecursiveDone();
    

    //Check if all items have been rendered using the max number of possible dummy elements
    if ((this._renderedModels< this.arrayOfModels.length) && this.arrayOfLiItems.length < this.numberOfInitialElement) 
    {
    
        //add extra element to display models
                while ((this._renderedModels< this.arrayOfModels.length) && this.arrayOfLiItems.length < this.numberOfInitialElement) 
                    {
                        var elementAdded = this._addNewDummyElement(this._renderedModels )
                        this._renderedModels= (elementAdded.model &&  elementAdded.model.length)? this._renderedModels + elementAdded.model.length: this._renderedModels + 1;
                        this._updateIndex = this._updateIndex + 1;
                    }
    
    }
    this._notifyAddDummiesElementFunctionRecursiveDone();
    
    
if (this._updateIndex < this.arrayOfLiItems.length) {
  //hide all other liste element that are not used
  while (this._updateIndex < this.arrayOfLiItems.length) {
    if (!this.arrayOfLiItems[this._updateIndex].cachedData.frameworkHidden) {
      this.arrayOfLiItems[
        this._updateIndex
      ].cachedData.displayPropertyValue = this.arrayOfLiItems[
        this._updateIndex
      ].style.display
      this.arrayOfLiItems[this._updateIndex].style.display = 'none'
      this.arrayOfLiItems[this._updateIndex].cachedData.frameworkHidden = true
    }
    this._updateIndex = this._updateIndex + 1
  }
  this._notifyHideAllOtherDummyElementUnusedFunctionRecursiveDone()
} else {
  this._notifyHideAllOtherDummyElementUnusedFunctionRecursiveDone()
}

    this.containerElem.scrollTop = oldScrollPosition;
    this.innerContainerElem._estimatedHeight = Math.floor(this.arrayOfModels.length / this._numberOfItemsPerRow) * randomHeight;
    this.innerContainerElem.style.height =Math.floor(this.arrayOfModels.length / this._numberOfItemsPerRow) * randomHeight +"px";
}


infiniteListAdapter.prototype.createDummyItem = function (modelIndex) {
  var randomHeight = this.getRandomHeight()
  var liElement = document.createElement('div')
  liElement.innerHTML = ''
  for (
    var index = 0;
    index < this._numberOfItemsPerRow &&
    index + modelIndex < this.arrayOfModels.length;
    index++
  ) {
    var modelToRender = this.arrayOfModels[modelIndex + index]
    if (modelToRender) {
      liElement.innerHTML = liElement.innerHTML + this._template(modelToRender)
    }
  }
  /*if(liElement.childNodes.length > 0){
    	for(let index =0 ; index < liElement.childNodes ; index++){
   			liElement.childNodes[index] =[];
   		}
    }*/
  liElement.hammerEventHandlers = []
  //this.applyCssStyle(liElement, this.dummyItemElemStyle);
  // liElement.className = "infintelistDummyElem";
  this.applyCssStyle(liElement, this.overrideSingleItemStyleMap)
  liElement.myHeight = randomHeight
  liElement.style.height = randomHeight + 'px'
  liElement.cachedData = {}
  liElement.cachedData.frameworkHidden = false
  liElement.cachedData.displayPropertyValue = liElement.style.display
  return liElement
}


infiniteListAdapter.prototype.addEventToDummyItemOnSingleItem = function (
  item,
  itemRoot,
  modelIndex
) {
  if (!item._isEventAttached) {
    for (let propKey in this.itemEventHandlerMap) {
      // oldHammerHandlerFunc = item.hammerEventHandlers[propKey];
      let newHammerHandlerFunc = this.itemEventHandlerMap[propKey]
      let elementSelector = null
      let selectedElement = null
      if (newHammerHandlerFunc) {
        //    	window.log("add event handler on item" + propKey);
        let parsedPropKey = propKey.match(/^(\S+)\s(.*)/).slice(1)
        let eventName = parsedPropKey[0]

        if (parsedPropKey.length > 1) {
          elementSelector = parsedPropKey[1]
          if (elementSelector) {
            elementSelector = elementSelector.trim()
          }
        }

        let handlerFuncBinded = function (evnt) {
          var model =
            this.itemRoot.model instanceof Array
              ? this.itemRoot.model[this.modelIndex]
              : this.itemRoot.model
          this.handlerFunc(model, evnt, this.item)
        }.bind({
          modelIndex: modelIndex,
          item: item,
          itemRoot: itemRoot,
          handlerFunc: newHammerHandlerFunc
        })

        if (this.supportedHammerEventArray.indexOf(eventName) > -1) {
          var hammerTime = null

          if (typeof elementSelector != 'undefined' && elementSelector) {
            selectedElement = item.querySelector(elementSelector)
            if (selectedElement) {
              hammerTime = Hammer(selectedElement, {
                drag_lock_to_axis: true
              }).on(eventName, handlerFuncBinded, false)
            } else {
              window.alertErrorMessage(
                "element matching the given selector couldn't be found"
              )
            }
          } else {
            hammerTime = Hammer(item, { drag_lock_to_axis: true }).on(
              eventName,
              handlerFuncBinded,
              false
            )
          }
          if (hammerTime && eventName == 'press') {
            //hammerTime.get( "press").options.time = this.DEFAULT_PRESS_TIME;
          }
        } else {
          if (typeof elementSelector != 'undefined' && elementSelector) {
            selectedElement = item.querySelector(elementSelector)
            if (selectedElement) {
              selectedElement.addEventListener(
                eventName,
                handlerFuncBinded,
                false
              )
            } else {
              window.alertErrorMessage(
                "element matching the given selector couldn't be found"
              )
            }
          } else {
            item.addEventListener(eventName, handlerFuncBinded, false)
          }
        }

        //item.hammerEventHandlers[propKey] = handlerFuncBinded;
      }
    }
    item._isEventAttached = true
  }
}

infiniteListAdapter.prototype.addEventToDummyItem = function (itemRoot, model) {
  var arrayOfModels = model instanceof Array ? model : [model]
  //if(model && model instanceof Array && items.childNodes.length >0){

 // for (let propKey in this.itemEventHandlerMap) {
    for (var u = 0; u < arrayOfModels.length; u++) {
      if (itemRoot.children.length > u) {
        this.addEventToDummyItemOnSingleItem(itemRoot.children[u], itemRoot, u)
      }
    }
  //}
}
/*var m = arrayOfModels[u];

		        oldHammerHandlerFunc = item.hammerEventHandlers[propKey];
		        oldHammerHandlerFunc = (oldHammerHandlerFunc instanceof Array)?oldHammerHandlerFunc[u]:oldHammerHandlerFunc;
		        newHammerHandlerFunc = this.itemEventHandlerMap[propKey];
		        if(newHammerHandlerFunc){
			        //    	window.log("add event handler on item" + propKey);
			        parsedPropKey = propKey.match(/^(\S+)\s(.*)/).slice(1);
			        eventName = parsedPropKey[0];
			        
			        if (parsedPropKey.length > 1) 
			        {
			            elementSelector = parsedPropKey[1];
			            elementSelector = (model instanceof Array)?":nth-child("+(u+1) +") > " + elementSelector:elementSelector;
			        }
			
			        handlerFunc = function(evnt) {
			            arguments.callee.hammerHandlerFunc( arrayOfModels[u], evnt, item);
			        };
			        //  if(!handlerFunc.prototype.hammerHandlerFunc)
			        {
			            handlerFunc.hammerHandlerFunc = newHammerHandlerFunc;
			        }
			        // infiniteListAdapter.prototype.hammerHandlerFunc[eventName] = newHammerHandlerFunc;
			        if (oldHammerHandlerFunc) 
			        {
			            if ((this.supportedHammerEventArray.indexOf(eventName) > -1)) 
			            {
			            	
			                if (typeof elementSelector != 'undefined' ) 
			                {
			                	var selectedElement  =  item.querySelector(elementSelector);
			                	if(selectedElement){
			                		Hammer(selectedElement).off(eventName, oldHammerHandlerFunc);
			                	}else{
			                		window.alertErrorMessage("element matching the given selector couldn't be found");
			                	}
			                } else {
			                    Hammer(item).off(eventName, oldHammerHandlerFunc);
			                }
			            } else {
			                if (typeof elementSelector != 'undefined') 
			                {
			                	var selectedElement  =  item.querySelector(elementSelector);
			                	if(selectedElement){
			                		selectedElement.removeEventListener(eventName, oldHammerHandlerFunc);
			                	}else{
			                		window.alertErrorMessage("element matching the given selector couldn't be found");
			                	}
			                } else {
			                    item.removeEventListener(eventName, oldHammerHandlerFunc);
			                }
			            
			            }
			        }
			        handlerFuncBinded = handlerFunc.bind(this);
			        if ((this.supportedHammerEventArray.indexOf(eventName) > -1)) 
			        {
			        	var hammerTime = null;
			        	
			            if (typeof elementSelector != 'undefined') 
			            {
			            	var selectedElement  =  item.querySelector(elementSelector);
			            	if(selectedElement){
			            		hammerTime = Hammer(selectedElement, {drag_lock_to_axis: true}).on(eventName, handlerFuncBinded, false);
			            	}else{
			            		window.alertErrorMessage("element matching the given selector couldn't be found");
			            	}
			            } else {
			            	hammerTime = Hammer(item, {drag_lock_to_axis: true}).on(eventName, handlerFuncBinded, false);
			            }
			            if(hammerTime && eventName == "press"){
			            	//hammerTime.get( "press").options.time = this.DEFAULT_PRESS_TIME;
			            }
			        } else {
			            if (typeof elementSelector != 'undefined') 
			            {
			            	var selectedElement  =  item.querySelector(elementSelector);
			            	if(selectedElement){
			            		selectedElement.addEventListener(eventName, handlerFuncBinded, false);
			            	}else{
			            		window.alertErrorMessage("element matching the given selector couldn't be found");
			            	}
			            } else {
			                item.addEventListener(eventName, handlerFuncBinded, false);
			            }
			        
			        }
			        funcArray.push(handlerFuncBinded);
			        
		        }
		    }
		        item.hammerEventHandlers[propKey] = (funcArray.length > 1)? funcArray: funcArray[0] ;*/

//}

//}

//}


infiniteListAdapter.prototype.addEventToDummyItemX = function (item) {
  if (!window._isHammerAttachedToJquery) {
    attachHammerToJquery()
    window._isHammerAttachedToJquery = true
  }

  for (let propKey in this.itemEventHandlerMap) {
    // oldHammerHandlerFunc = item.hammerEventHandlers[propKey];
    let newHammerHandlerFunc = this.itemEventHandlerMap[propKey]

    if (newHammerHandlerFunc) {
      //    	window.log("add event handler on item" + propKey);
      let parsedPropKey = propKey.match(/^(\S+)\s(.*)/).slice(1)
      var eventName = parsedPropKey[0]
      var elementSelector = null
      if (parsedPropKey.length > 1) {
        elementSelector = parsedPropKey[1]
      }
      /* if(!jsEventToHandlerInfos[eventName]){
	        	jsEventToHandlerInfos[eventName] = [];
	        }
	       var handlerInfos = {"elementSelector" : elementSelector, "handlerFunction": newHammerHandlerFunc};
	        jsEventToHandlerInfos[eventName].push(handlerInfos);
		
			for(let index = 0; index < this._numberOfItemsPerRow; index ++){
				Gator(item).on()
			}
	        handlerFunc = function(evnt) {
	            arguments.callee.hammerHandlerFunc(item.model, evnt, item);
	        };
	        //  if(!handlerFunc.prototype.hammerHandlerFunc)
	        {
	            handlerFunc.hammerHandlerFunc = newHammerHandlerFunc;
	        }
	        // infiniteListAdapter.prototype.hammerHandlerFunc[eventName] = newHammerHandlerFunc;
	       /* if (oldHammerHandlerFunc) 
	        {
	            if ((this.supportedHammerEventArray.indexOf(eventName) > -1)) 
	            {
	            	
	                if (typeof elementSelector != 'undefined' ) 
	                {
	                	var selectedElement  =  item.querySelector(elementSelector);
	                	if(selectedElement){
	                		Hammer(selectedElement).off(eventName, oldHammerHandlerFunc);
	                	}else{
	                		window.alertErrorMessage("element matching the given selector couldn't be found");
	                	}
	                } else {
	                    Hammer(item).off(eventName, oldHammerHandlerFunc);
	                }
	            } else {
	                if (typeof elementSelector != 'undefined') 
	                {
	                	var selectedElement  =  item.querySelector(elementSelector);
	                	if(selectedElement){
	                		selectedElement.removeEventListener(eventName, oldHammerHandlerFunc);
	                	}else{
	                		window.alertErrorMessage("element matching the given selector couldn't be found");
	                	}
	                } else {
	                    item.removeEventListener(eventName, oldHammerHandlerFunc);
	                }
	            
	            }
	        }*/

      for (var index = 0; index < this._numberOfItemsPerRow; index++) {
        var handlerFuncBinded = function (evnt) {
          evnt.preventDefault()
          if (this.self.supportedHammerEventArray.indexOf(evnt.type) > -1) {
            evnt = evnt.originalEvent
          }
          if (
            !this.modelIndex &&
            this.elementItem.model &&
            !(this.elementItem.model instanceof Array)
          ) {
            this.handlerFunction(this.elementItem.model, evnt, this.elementItem)
          } else {
            if (
              this.elementItem.model instanceof Array &&
              this.modelIndex < this.elementItem.model.length
            ) {
              this.handlerFunction(
                this.elementItem.model[this.modelIndex],
                evnt,
                this.elementItem.children[this.modelIndex]
              )
            }
          }
        }.bind({
          self: this,
          elementSelector: elementSelector,
          handlerFunction: newHammerHandlerFunc,
          modelIndex: index,
          elementItem: item
        })
        var customSelector = elementSelector
          ? ':nth-child(' + (index + 1) + ') ' + elementSelector
          : ':nth-child(' + (index + 1) + ') '
        if (this.supportedHammerEventArray.indexOf(eventName) > -1) {
          $(item)
            .hammer({ domEvents: true, drag_lock_to_axis: true })
            .on(eventName, customSelector, handlerFuncBinded)
        } else {
          $(item).on(eventName, customSelector, handlerFuncBinded)
        }
      }
    }
  }
}


infiniteListAdapter.prototype.addEventToDummyItemNew = function (item) {
    for (let propKey in this.itemEventHandlerMap) {
      let oldHammerHandlerFunc = item.hammerEventHandlers[propKey];
      let newHammerHandlerFunc = this.itemEventHandlerMap[propKey];
      let elementSelector = null;
      let selectedElement = null;
      let handlerFuncBinded = null;
  
      if (newHammerHandlerFunc) {
        let parsedPropKey = propKey.match(/^(\S+)\s(.*)/).slice(1);
        let eventName = parsedPropKey[0];
  
        if (parsedPropKey.length > 1) {
          elementSelector = parsedPropKey[1];
        }
  
        const handlerFunc = function (evnt) {
          this.hammerHandlerFunc(item.model, evnt, item);
        };
  
        handlerFunc.hammerHandlerFunc = newHammerHandlerFunc;
  
        if (oldHammerHandlerFunc) {
          if (this.supportedHammerEventArray.indexOf(eventName) > -1) {
            if (typeof elementSelector !== 'undefined') {
              selectedElement = item.querySelector(elementSelector);
              if (selectedElement) {
                Hammer(selectedElement).off(eventName, oldHammerHandlerFunc);
              } else {
                window.alertErrorMessage(
                  "element matching the given selector couldn't be found"
                );
              }
            } else {
              Hammer(item).off(eventName, oldHammerHandlerFunc);
            }
          } else {
            if (typeof elementSelector !== 'undefined') {
              selectedElement = item.querySelector(elementSelector);
              if (selectedElement) {
                selectedElement.removeEventListener(
                  eventName,
                  oldHammerHandlerFunc
                );
              } else {
                window.alertErrorMessage(
                  "element matching the given selector couldn't be found"
                );
              }
            } else {
              item.removeEventListener(eventName, oldHammerHandlerFunc);
            }
          }
        }
  
        handlerFuncBinded = handlerFunc.bind(this);
  
        if (this.supportedHammerEventArray.indexOf(eventName) > -1) {
          var hammerTime = null;
  
          if (typeof elementSelector !== 'undefined') {
            selectedElement = item.querySelector(elementSelector);
            if (selectedElement) {
              hammerTime = Hammer(selectedElement, {
                drag_lock_to_axis: true,
              }).on(eventName, handlerFuncBinded, false);
            } else {
              window.alertErrorMessage(
                "element matching the given selector couldn't be found"
              );
            }
          } else {
            hammerTime = Hammer(item, { drag_lock_to_axis: true }).on(
              eventName,
              handlerFuncBinded,
              false
            );
          }
          if (hammerTime && eventName == 'press') {
            //hammerTime.get( "press").options.time = this.DEFAULT_PRESS_TIME;
          }
        } else {
          if (typeof elementSelector !== 'undefined') {
            selectedElement = item.querySelector(elementSelector);
            if (selectedElement) {
              selectedElement.addEventListener(
                eventName,
                handlerFuncBinded,
                false
              );
            } else {
              window.alertErrorMessage(
                "element matching the given selector couldn't be found"
              );
            }
          } else {
            item.addEventListener(eventName, handlerFuncBinded, false);
          }
        }
  
        item.hammerEventHandlers[propKey] = handlerFuncBinded;
      }
    }
  };
  


infiniteListAdapter.prototype.loadMoreItems = function(event) {
    this.isEventTreatementRunning = true;
    
    if (this.isScrollingDown(event)) {
        this.loadMoreItemsOnSwipeDown();
    
    } else {
        
        this.loadMoreItemsOnSwipeUp();
    }

};
infiniteListAdapter.prototype.refreshLiElem = function(liElem){
	if(liElem){
		if (this.itemUpdateHandlerFunction && liElem.model) 
		{
			this.itemUpdateHandlerFunction(liElem, liElem.model);
		} else {
			liElem.innerHTML = this.getNextContentOnBottom();
		}
	}

} 

infiniteListAdapter.prototype.loadMoreItemsOnSwipeDown = function (event) {
    var i = 0;
    var counter = 0;

    // Create a named function for the repeating logic
    const functionToRepeat = (startTimeStamp) => {
        startTimeStamp = new Date().getTime();

        while ((Date.now() - startTimeStamp) < 16) {
            if (i < this.arrayOfLiItems.length && this.isNextContentOnBottom() && counter < this.arrayOfLiItems.length) {
                counter++;
                let liElem = this.arrayOfLiItems[i];

                if ((liElem.myYSCrolling + liElem.myHeight) < event.scrollTop) {
                    this.arrayOfLiItems.splice(i, 1);
                    this._removeModelKeyRefMapToListElement(liElem, liElem.model);
                    let oldItemHeight = liElem.myHeight;
                    let newRandoHeight = this.getRandomHeight();
                    var nOfModelBeforeRerendering = (liElem.model instanceof Array) ? (liElem.model.length) : 1;
                    liElem.model = this.getNextModelOnBottom();

                    liElem.myHeight = newRandoHeight;

                    if (this.itemUpdateHandlerFunction && liElem.model) {
                        this.itemUpdateHandlerFunction(liElem, this.getNextModelOnBottom());
                    } else {
                        liElem.innerHTML = this.getNextContentOnBottom();
                    }

                    liElem.style["-webkit-transform"] = "translate3d(0px, " + this.lastSCrollingInPx + "px, 0px)";
                    liElem.style["transform"] = "translate(0px, " + this.lastSCrollingInPx + "px)";
                    liElem.style["-moz-transform"] = "translate(0px, " + this.lastSCrollingInPx + "px)";
                    liElem.style["-ms-transform"] = "translate(0px, " + this.lastSCrollingInPx + "px)";
                    liElem.style["-o-transform"] = "translate(0px, " + this.lastSCrollingInPx + "px)";

                    this.addEventToDummyItem(liElem, liElem.model);
                    this._addModelKeyRefMapToListElement(liElem, liElem.model);

                    liElem.myYSCrolling = this.lastSCrollingInPx;
                    this.lastSCrollingInPx = newRandoHeight + this.lastSCrollingInPx;
                    this.firstSCrollingInPx = this.firstSCrollingInPx + oldItemHeight;
                    this.arrayOfLiItems.push(liElem);

                    this.firstItemToDisplayed = this.firstItemToDisplayed + nOfModelBeforeRerendering;
                    this.nextItemToDisplay = (liElem.model instanceof Array) ? (this.nextItemToDisplay + liElem.model.length) : (this.nextItemToDisplay + 1);

                    this.firstItemEl = this.arrayOfLiItems[0];
                    this.firstItemHeight = this.firstItemEl.myHeight;
                } else {
                    window.clearTimeout(repeater);
                    this.isEventTreatementRunning = false;
                    return;
                }
            } else {
                window.clearTimeout(repeater);
                this.isEventTreatementRunning = false;
                return;
            }
        }
    };

    // Start the repeating logic using setInterval
    let repeater = window.setInterval(functionToRepeat.bind(this), 0);
    functionToRepeat.myId = repeater;
}


infiniteListAdapter.prototype.loadMoreItemsOnSwipeUp = function(event) {
    var i = this.arrayOfLiItems.length - 1;
    var counter = 0;
    
    // Create a named function for the repeating logic
    const functionToRepeat = function(startTimeStamp) {
        startTimeStamp = new Date().getTime();
        
        while ((Date.now() - startTimeStamp) < 16) {
            if (i > -1 && this.isNextContentOnTop() && counter < this.arrayOfLiItems.length) {
                counter++;
                let liElem = this.arrayOfLiItems[i];

                if (liElem.myYSCrolling > (event.scrollTop + event.clientHeight)) {
                    this.arrayOfLiItems.splice(i, 1);
                    this._removeModelKeyRefMapToListElement(liElem, liElem.model);
                    let newRandoHeight = this.getRandomHeight();
                    var nOfModelBeforeRerendering = (liElem.model instanceof Array) ? (liElem.model.length) : 1;
                    liElem.model = this.getNextModelOnTop();

                    liElem.myHeight = newRandoHeight;

                    if (this.itemUpdateHandlerFunction && liElem.model) {
                        this.itemUpdateHandlerFunction(liElem, this.getNextModelOnTop());
                    } else {
                        liElem.innerHTML = this.getNextContentOnTop();
                    }

                    liElem.style["-webkit-transform"] = "translate3d(0px, " + this.firstSCrollingInPx + "px, 0px)";
                    liElem.style["transform"] = "translate(0px, " + this.firstSCrollingInPx + "px)";
                    liElem.style["-moz-transform"] = "translate(0px, " + this.firstSCrollingInPx + "px)";
                    liElem.style["-ms-transform"] = "translate(0px, " + this.firstSCrollingInPx + "px)";
                    liElem.style["-o-transform"] = "translate(0px, " + this.firstSCrollingInPx + "px)";

                    this.addEventToDummyItem(liElem, liElem.model);
                    this._addModelKeyRefMapToListElement(liElem, liElem.model);

                    liElem.myYSCrolling = this.firstSCrollingInPx;

                    this.arrayOfLiItems.unshift(liElem);

                    this.firstItemToDisplayed = (liElem.model instanceof Array) ? (this.firstItemToDisplayed - liElem.model.length) : (this.firstItemToDisplayed - 1);
                    this.nextItemToDisplay = this.nextItemToDisplay - nOfModelBeforeRerendering;

                    this.firstItemEl = liElem;
                    this.firstItemHeight = newRandoHeight;
                } else {
                    window.clearTimeout(repeater);
                    this.isEventTreatementRunning = false;
                    return;
                }
            } else {
                window.clearTimeout(repeater);
                this.isEventTreatementRunning = false;
                return;
            }
        }
    };

    // Start the repeating logic using setInterval
    let repeater = window.setInterval(functionToRepeat.bind(this), 0);
    functionToRepeat.myId = repeater;
}


infiniteListAdapter.prototype.getKeyForModel = function (model) {
  if (model) {
    if (model instanceof Array && this._idAttribute) {
      var keys = []
      for (var index in model) {
        keys.push(model[index][this._idAttribute])
      }
      return keys
    } else {
      return model[this._idAttribute]
    }
  } else {
    return null
  }
}


infiniteListAdapter.prototype.getNextContentOnBottom = function () {
  //window.log("Data added at the bottom "+(this.nextItemToDisplay+1));
  var modelToRender = this.getNextModelOnBottom()
  if (modelToRender instanceof Array) {
    var htmlToReturn = ''
    for (var index in modelToRender) {
      htmlToReturn = htmlToReturn + this._template(modelToRender[index])
    }
    return htmlToReturn
  } else {
    return modelToRender ? this._template(modelToRender) : ''
  }
}

infiniteListAdapter.prototype.getNextModelOnBottom = function () {
  if (this._numberOfItemsPerRow > 1) {
    return this.arrayOfModels.slice(
      this.nextItemToDisplay + 1,
      Math.min(
        this.nextItemToDisplay + this._numberOfItemsPerRow + 1,
        this.arrayOfModels.length
      )
    )
  } else {
    return this.arrayOfModels[this.nextItemToDisplay + 1]
  }
}


infiniteListAdapter.prototype.isNextContentOnBottom = function() 
{
    //window.log("Data added at the bottom "+(this.nextItemToDisplay+1));
    return this.arrayOfModels.length > this.nextItemToDisplay + 1 && this.nextItemToDisplay + 1 > -1;

}

infiniteListAdapter.prototype.getNextContentOnTop = function () {
  //window.log("Data added at the top "+(this.nextItemToDisplay-1));
  var modelToRender = this.getNextModelOnTop()
  if (modelToRender instanceof Array) {
    var htmlToReturn = ''
    for (var index in modelToRender) {
      htmlToReturn = htmlToReturn + this._template(modelToRender[index])
    }
    return htmlToReturn
  } else {
    return modelToRender ? this._template(modelToRender) : ''
  }
  //return "yes " + (this.firstItemToDisplayed - 1);
}


infiniteListAdapter.prototype.getNextModelOnTop = function () {
  //window.log("Data added at the top "+(this.nextItemToDisplay-1));
  // return this.arrayOfModels[this.firstItemToDisplayed - 1];
  if (this._numberOfItemsPerRow > 1) {
    return this.arrayOfModels.slice(
      Math.max(0, this.firstItemToDisplayed - this._numberOfItemsPerRow),
      this.firstItemToDisplayed
    )
  } else {
    return this.arrayOfModels[this.firstItemToDisplayed - 1]
  }
  //return "yes " + (this.firstItemToDisplayed - 1);
}

infiniteListAdapter.prototype.isNextContentOnTop = function() 
{
    //window.log("Data added at the top "+(this.nextItemToDisplay-1));
    return this.arrayOfModels.length > this.firstItemToDisplayed - 1 && this.firstItemToDisplayed - 1 > -1;
//return "yes " + (this.firstItemToDisplayed - 1);
}

infiniteListAdapter.prototype.isElementVisible = function(elem) 
{
    if (elem) 
    {
        this.containerElem = this.mainContainerElem;
        return !this.isElementCompletelyOnTopOfContainer(elem, this.containerElem) && !this.isElementCompletelyAtBottomOfContainer(elem, this.containerElem);
    }
}

infiniteListAdapter.prototype.isElementCompletelyOnTopOfContainer = function(elem) 
{
    
    this.containerRect = this.containerElem.getBoundingClientRect();
    this.containerTop = this.containerRect.top
    this.containerBottom = this.containerRect.bottom;
    const elemRect = elem.getBoundingClientRect();
    //let elemTop = elemRect.top;
    const elemBottom = elemRect.bottom;
    return (elemBottom < this.containerTop)
}

infiniteListAdapter.prototype.isElementCompletelyAtBottomOfContainer = function(elem) 
{
    
    this.containerRect = this.containerElem.getBoundingClientRect();
    this.containerBottom = this.containerRect.bottom;
    const elemRect = elem.getBoundingClientRect();
    const elemTop = elemRect.top;
    return (elemTop > this.containerBottom)
}

infiniteListAdapter.prototype.applyCssStyle = function(elem, arrayStyle) {
    if (elem) {
        for (var prop in arrayStyle) {
            elem.style[prop] = arrayStyle[prop];
        }
    }
}



infiniteListAdapter.prototype.getLiElemtNotVisibleOnTop = function() 
{
    const notVisibleLI = [];
    this.containerElem = this.mainContainerElem;
    this.containerRect = this.containerElem.getBoundingClientRect();
    this.containerTop = this.containerRect.top
    
    this.containerBottom = this.containerRect.bottom;
    for (let i in this.arrayOfLiItems) 
    {
        const liElem = this.arrayOfLiItems[i];
        const liElemRect = liElem.getBoundingClientRect();
        //const elemTop = liElemRect.top
        const elemBottom = liElemRect.bottom;
        if (elemBottom < this.containerTop) 
        {
            //isnot visible
            notVisibleLI.push(liElem)
        }
    }
    return notVisibleLI;

}

infiniteListAdapter.prototype.getLiElemtNotVisibleOnBottom = function() 
{
    const notVisibleLI = [];
    this.containerElem = this.mainContainerElem;
    this.containerRect = this.containerElem.getBoundingClientRect();
    this.containerTop = this.containerRect.top
    
    this.containerBottom = this.containerRect.bottom;
    for (let i in this.arrayOfLiItems) 
    {
        const liElem = this.arrayOfLiItems[i];
        const liElemRect = liElem.getBoundingClientRect();
        const elemTop = liElemRect.top
        //const elemBottom = liElemRect.bottom;
        if (elemTop > this.containerBottom) 
        {
            //isnot visible
            notVisibleLI.push(liElem)
        }
    }
    return notVisibleLI;
}

infiniteListAdapter.prototype.getRandomHeight = function() 
{
    if (this.defaultElementHeight) 
    {
        return this.defaultElementHeight;
    } else {
        return Math.floor(40 + (Math.random() * ((80 - 40) + 1)));
    }
}

infiniteListAdapter.prototype.eventTreatmentDaemon = function() 
{
    if (!this.isEventTreatementRunning && this.eventQueue.length > 0) 
    {
        if (this.eventQueue[0].converted) {
            window.log("this was originally a scroll event");
            window.log("Processing time " + (new Date().getTime() - this.eventQueue[0].orginialEvent.timeStamp));
        }
        //displatch event  for treatment
        if (this.eventQueue[0].type == "panup") 
        {
            window.log("this is up");
            // window.alertErrorMessage("this is up");
            this.loadMoreItemsOnSwipeDown(this.eventQueue[0], this.arrayOfLiItems);
        }
        if (this.eventQueue[0].type == "pandown") 
        {
            window.log("this is down");
            //  window.alertErrorMessage("this is down");
            this.loadMoreItemsOnSwipeUp(this.eventQueue[0], this.arrayOfLiItems);
        }
        if (this.eventQueue[0].type != "panup" && this.eventQueue[0].type != "pandown") 
        {
            this.loadMoreItems(this.eventQueue[0], this.arrayOfLiItems);
        }
        
        this.eventQueue.splice(0, 1);
    }

}

infiniteListAdapter.prototype.treatEventNow = function(event) 
{

    //displatch event  for treatment
    if (event.type == "panup") 
    {
        window.log("this is up");
        // window.alertErrorMessage("this is up");
        this.loadMoreItemsOnSwipeDown(event, this.arrayOfLiItems);
    }
    if (event.type == "pandown") 
    {
        window.log("this is down");
        //  window.alertErrorMessage("this is down");
        this.loadMoreItemsOnSwipeUp(event, this.arrayOfLiItems);
    }
    if (event.type != "panup" && event.type != "pandown") 
    {
        this.loadMoreItems(event, this.arrayOfLiItems);
    }


}

infiniteListAdapter.prototype.treatEvent = function() 
{
    if (!this.isEventTreatementRunning && this.eventQueue.length > 0) 
    {
        if (this.eventQueue[0].converted) {
            window.log("this was originally a scroll event");
            window.log("Processing time " + (new Date().getTime() - this.eventQueue[0].orginialEvent.timeStamp));
        }
        //displatch event  for treatment
        if (this.eventQueue[0].type == "panup") 
        {
            window.log("this is up");
            // window.alertErrorMessage("this is up");
            this.loadMoreItemsOnSwipeDown(this.eventQueue[0], this.arrayOfLiItems);
        }
        if (this.eventQueue[0].type == "pandown") 
        {
            window.log("this is down");
            //  window.alertErrorMessage("this is down");
            this.loadMoreItemsOnSwipeUp(this.eventQueue[0], this.arrayOfLiItems);
        }
        if (this.eventQueue[0].type != "panup" && this.eventQueue[0].type != "pandown") 
        {
            this.loadMoreItems(this.eventQueue[0], this.arrayOfLiItems);
        }
        
        this.eventQueue.splice(0, 1);
    } else {
        window.setTimeout(this.treatEvent.bind(this), 14);
    }

}

export default  infiniteListAdapter;
