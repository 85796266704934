import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import ti18n from "i18n!nls/Commoni18n";
import TranscriptionMinutesRemaningWidgetTemaplate from "text!../../../templates/common/TranscriptionMinutesRemaningWidgetTemaplate.html";
import RSVP from "rsvp";

const TranscriptionMinutesRemaningWidgetView = Backbone.View.extend({


    initialize : function(options) {
        this.defaultTemplate =  _.template(TranscriptionMinutesRemaningWidgetTemaplate);

    },

    events : {
    },


    showTranscriptionMinutesRemaning: function(showTranscriptionWidget,transcriptionMinutesLeft, totalMinutesAvailablePerMonth,hasActivePaidSubscription){
        if(showTranscriptionWidget){
            this.$el.removeClass("hide");
            this.$el.find(".minutes_used_value").html(transcriptionMinutesLeft);
            this.$el.find(".total_minutes_available_value").html(totalMinutesAvailablePerMonth);
            this.$el.find(".progress_bar_element").css("width",(transcriptionMinutesLeft *100/totalMinutesAvailablePerMonth)+"%");
        }else{
            this.$el.addClass("hide");
        }
    },


    render : function(){
        const params = {"ti18n": ti18n};
        this.$el.html(this.defaultTemplate(params));
        this.setElement(this.$el.find("section"));

        return this;
    },


});

export default TranscriptionMinutesRemaningWidgetView;
