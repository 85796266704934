
import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import BasicSequencerNodeInspectorController from "controllers/audioEditor/sequencerNodeInspectorSection/BasicSequencerNodeInspectorController";
import MultipleElementSelectedNodeInspectorView from "views/audioEditor/sequencerNodeInspectorSection/MultipleElementSelectedNodeInspectorView";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const MultipleElementSelectedNodeInspectorController = BasicSequencerNodeInspectorController.extend({

    constructor : function(attributes, options) {
        BasicSequencerNodeInspectorController.apply(this, [attributes, options]);
        this._numberOfElementsSelected  = attributes.numberOfElementsSelected;
    },


    listenToEventsOnView: function(){
    
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                const params = {};
                params.numberOfElementsSelected = this._numberOfElementsSelected;
                params.sequencerNode = this.getModel();
                this.view = new MultipleElementSelectedNodeInspectorView(params);
                this.view.render();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default MultipleElementSelectedNodeInspectorController;