import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import SequencerSettingsTemplate from 'text!../../../templates/audioEditor/SequencerSettingsTemplate.html';
import ti18n from 'i18n!nls/Sequenceri18n';
import RSVP from 'rsvp';

const VOICE_BUTTON_IDS = {"REDUCE_RUMBLE": "reduce_rumble_btn","REDUCE_HARSH_ESS":"reduce_harsh_ess_btn","VOICE_LEVELING":"voice_leveling_btn","IMPROVE_SPEECH_CLARITY":"inprove_speech_clarity_btn","AUTOMATIC_CROSSFADE":"automatic_crossfade_btn"};
const voice_track_settings_array =  [];
const createSettingsParams = function(buttonId, title, desc){
    return {"buttonId": buttonId, "title": title,"desc": desc };
}
voice_track_settings_array.push(createSettingsParams(VOICE_BUTTON_IDS.VOICE_LEVELING, window.getI18n(ti18n, "MAKE_VOICE_SOUNDS_WELL_ROUNDED"),window.getI18n(ti18n, "MAKE_VOICE_SOUNDS_WELL_ROUNDED_DETAILS")));
voice_track_settings_array.push(createSettingsParams(VOICE_BUTTON_IDS.IMPROVE_SPEECH_CLARITY, window.getI18n(ti18n, "IMPROVE_SPEED_CLARITY"), window.getI18n(ti18n, "IMPROVE_SPEED_CLARITY_DETAILS")));
voice_track_settings_array.push(createSettingsParams(VOICE_BUTTON_IDS.REDUCE_RUMBLE, window.getI18n(ti18n, "REDUCE_RUMBLE"), window.getI18n(ti18n, "REDUCE_RUMBLE_DETAILS")));
voice_track_settings_array.push(createSettingsParams(VOICE_BUTTON_IDS.AUTOMATIC_CROSSFADE, window.getI18n(ti18n, "SMOOTH_AUDIO_CUTS"), window.getI18n(ti18n, "SMOOTH_AUDIO_CUTS_DETAILS")));
voice_track_settings_array.push(createSettingsParams(VOICE_BUTTON_IDS.REDUCE_HARSH_ESS, window.getI18n(ti18n, "REDUCE_HARSH_ESS"), window.getI18n(ti18n, "REDUCE_HARSH_ESS_DETAILS")));

const SequencerSettingsView = Backbone.View.extend({



    initialize : function(options) {
        this.compiledTemlate =  _.template(SequencerSettingsTemplate);
        this.model = options? options.model : null;
        this._sequencer = this.model;
    },
    
    events : {

    },

    _getButtonSwitchClassNameByButtonId: function(buttonId){
        return  buttonId +"_switch";
    },

    _getSettingValueByButtonId : function(buttonId){
        return this.$el.find(".switch."+ this._getButtonSwitchClassNameByButtonId(buttonId) +" input").prop('checked');
    },

    _setSettingValueByButtonId : function(buttonId, turnOn){
        this.$el.find(".switch."+ this._getButtonSwitchClassNameByButtonId(buttonId) +"  input").prop('checked', turnOn);
    },

    _listenToClickOnSettingButton : function(buttonId, handler){
        this.$el.find(".switch."+ this._getButtonSwitchClassNameByButtonId(buttonId) +"  input").on("click", (function(event){
            handler(event);
            document.scrollingElement.scrollTop = 0;// fix a bug where the scroll position change when the last switch is toggled
        }).bind(this));
    },

    setReduceRumble : function(turnOn){
        this._setSettingValueByButtonId(VOICE_BUTTON_IDS.REDUCE_RUMBLE, turnOn);
    },

    isReduceRumbleOn : function(){
        return this._getSettingValueByButtonId(VOICE_BUTTON_IDS.REDUCE_RUMBLE);
    },

    onReduceRumbleClicked : function(){
        this.trigger("reduceRumbleChange", this.isReduceRumbleOn());
        
    },

    setReduceHarsEss : function(turnOn){
        this._setSettingValueByButtonId(VOICE_BUTTON_IDS.REDUCE_HARSH_ESS, turnOn);
    },

    isReduceHarsEssOn : function(){
        return this._getSettingValueByButtonId(VOICE_BUTTON_IDS.REDUCE_HARSH_ESS);
    },

    onReduceHarsEssClicked : function(){
        this.trigger("reduceHarshEssChange", this.isReduceHarsEssOn());
    },

    setVoiceLeveling : function(turnOn){
        this._setSettingValueByButtonId(VOICE_BUTTON_IDS.VOICE_LEVELING, turnOn);
    },

    isVoiceLevelingOn : function(){
        return this._getSettingValueByButtonId(VOICE_BUTTON_IDS.VOICE_LEVELING);
    },

    onVoiceLevelingClicked : function(){
        this.trigger("voiceLevelingChange", this.isVoiceLevelingOn())
    },

    setSpeechClarity : function(turnOn){
        this._setSettingValueByButtonId(VOICE_BUTTON_IDS.IMPROVE_SPEECH_CLARITY, turnOn);
    },

    isSpeechClarityOn : function(){
        return this._getSettingValueByButtonId(VOICE_BUTTON_IDS.IMPROVE_SPEECH_CLARITY);
    },

    onSpeechClarityClicked : function(){
        this.trigger("speechClarityChange", this.isSpeechClarityOn());
    },

    setAutomaticCrossfade : function(turnOn){
        this._setSettingValueByButtonId(VOICE_BUTTON_IDS.AUTOMATIC_CROSSFADE, turnOn);
    },

    isAutomaticCrossfadeOn : function(){
        return this._getSettingValueByButtonId(VOICE_BUTTON_IDS.AUTOMATIC_CROSSFADE);
    },

    onAutomaticCrossfadeClicked : function(){
        this.trigger("automaticCrossfadeChange", this.isAutomaticCrossfadeOn());
    },

    initSwitches : function(){
        this.setReduceRumble(this.model.isRumbleReductionOn());
        this.setReduceHarsEss(this.model.isRemoveHarshEssOn());
        this.setVoiceLeveling(this.model.isVoiceVolumeLevelingOn());
        this.setSpeechClarity(this.model.isImproveSpeechClarityOn());
        this.setAutomaticCrossfade(this.model.getAutoCrossfadingBetweenWordsClosesTogetherInReferenceAudio());
    },

    getModel: function(){
        return this.model;
    },

    render : function() {
        this.$el.html(this.compiledTemlate({"voice_track_settings_array": voice_track_settings_array, "ti18n": ti18n}));
        this.setElement(this.$el.find(".sequencer_settings").first());
        this.initSwitches();

        this._listenToClickOnSettingButton(VOICE_BUTTON_IDS.REDUCE_RUMBLE, this.onReduceRumbleClicked.bind(this));
        this._listenToClickOnSettingButton(VOICE_BUTTON_IDS.REDUCE_HARSH_ESS, this.onReduceHarsEssClicked.bind(this));
        this._listenToClickOnSettingButton(VOICE_BUTTON_IDS.VOICE_LEVELING, this.onVoiceLevelingClicked.bind(this));
        this._listenToClickOnSettingButton(VOICE_BUTTON_IDS.IMPROVE_SPEECH_CLARITY, this.onSpeechClarityClicked.bind(this));
        this._listenToClickOnSettingButton(VOICE_BUTTON_IDS.AUTOMATIC_CROSSFADE, this.onAutomaticCrossfadeClicked.bind(this));
        return this;
    }

    
    

});
export default SequencerSettingsView;