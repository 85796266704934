import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import User from "models/User";

const Users =  Backbone.Collection.extend({
    model: User,


    initialize: function (){
        this._sort_key ="username";

    },

      sortByField: function(fieldName) {
            this._sort_key = fieldName? fieldName : this._sort_key;
            this.sort();
      },

    
    getContext : function(){
        return this._context;
    },
    
    updateContext : function(newContext){
        this._context = newContext
    },
    
    searchOnUsernameAndFullname : function(letters, resultSize){
        if(letters == "")
        {
            if(resultSize)
            {
                return this.models.slice(0,resultSize );
            }else{
                return this.models;
            }
            
        }
        var resultFound = 0;
        letters  = letters.toLowerCase();
        
        var patternArray = [];
        var words = letters.split(/\s+/);
        for(let index in words){
            var escapedWord =  words[index].replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
            patternArray.push(new RegExp(escapedWord,"gi"));
        }
        return this.filter(function(data) {
            if(!resultSize || (resultSize  && resultFound < resultSize))
            {
                var fullname = "";
                if(data.getFullName()){
                    fullname  = data.getFullName();
                }
    
                var username = "";
                if(data.getUsername()){
                    username  = data.getUsername();
                }
        
                
                var found = true;
                for(let index in patternArray){
                    var pattern = patternArray[index];
                    found = pattern.test(fullname.toLowerCase()) || pattern.test(username.toLowerCase()); 
                    if(!found){
                        break;
                    }
                }
                if(found){
                    resultFound = resultFound+ 1;
                }
            
                return  found;
            }else{
                return false;
            }
        });
    },
    
     comparator: function(item) {
            var data = item.get(this._sort_key);
            if(data && ( typeof data == "string" || data instanceof String))
            {

                data = data.trim().toLowerCase();
            }
            if(data && ( data instanceof Date))
            {
                data = data.getTime();
            }
            if(this._descending ){
                return  -data;
            }else{
                return  data;
            }
      },
    

});
export default Users;