import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import CustomSearchFieldView from "views/common/CustomSearchFieldView";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import Sortable from "libs/sortablejs/Sortable";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import ti18n from 'i18n!nls/Homei18n';
import pageTemplate from 'text!../../../templates/home/HomePageTemplate.html';

const ON_FEED_TODAY_BOX_STATUS = {
    "LOADING": "loading",
    "ERROR":"error",
    "NO_NEW_CONTENT": "no_new_content",
    "CONTENT_LOADED": "content_loaded"
}

const Rollout = RolloutHelper.getInstance();

const HomePageView = Backbone.View.extend({

    template : _.template(pageTemplate),


    events : {
        
        "click #homeHeaderButton" : "onHomeButtonSelected",
        "click #helpHeaderButton" : "onHelpHeaderButtonClicked",
        
        "click #playMyFeedButton" : "playMyFeed",
        "click #upgradeButton": "showUpgradeToPaidSubscriptionPopup",

        "click #play_feed_btn .playIconWrapper" : "playMyFeed",
        "click #play_feed_btn .pauseIconWrapper" : "playMyFeed",
        "click #start_category_shortcast_radio_btn": "startCategoryShortcastRadio",
        "click #pauseMyFeedButton": "playMyFeed",
        "click #settingMenuBtn":"showSettingsPage",
        "click #onYourFeedTodayBoxWrapper .closeButton":"hideOnYourFeedTodayBox",
        
        
    },

    initialize : function(options) {
        
        
        
        this.selectedTab = "subscriptionTab";
        this._isPageDisplayed = false;
        this._isOnYourFeedTodayBoxHidden = false;
        
        this._onKeyUpInSearchFieldHandler = options.onKeyUpInSearchFieldHandler;
        this._onSearchFieldClearedHandler = options.onSearchFieldClearedHandler;

        PersistentModels.getInstance().getRouter().registerForViewportHeightChange("mySubscriptionPageView",this._onViewportHeightChanged.bind(this));
    },

    _onViewportHeightChanged : function(newViewPortHeigh, forceResizing) {
        if(PersistentModels.getInstance().getRouter().isEqualToCurrentPageUrl("#mySubscriptions")  || forceResizing){
            if (this.el) {
                const tabHeight = 46;
                const adjustedListContainerHeight = (newViewPortHeigh - tabHeight ) ;
                
                
                if(this.$placeHolderMyFeedListEl){
                    this.$placeHolderMyFeedListEl.css("height", adjustedListContainerHeight + "px")
                }

            }
        }
    },



    showUpgradeBtn : function(){
        if(this.upgradeToTrebblePaidSubscriptionBoxWrapper$el.hasClass("hide")){
            this.upgradeToTrebblePaidSubscriptionBoxWrapper$el.removeClass("hide");
        }
    },

    hideUpgradeBtn : function(){
        if(!this.upgradeToTrebblePaidSubscriptionBoxWrapper$el.hasClass("hide")){
            this.upgradeToTrebblePaidSubscriptionBoxWrapper$el.addClass("hide");
        }
    },

    showSettingsPage: function(){
        this.trigger("showSettingsPage");
    },


    showUpgradeToPaidSubscriptionPopup : function(){
        this.trigger("showUpgradeToPaidSubscriptionPopup");
    },

    startCategoryShortcastRadio : function(){
        this.trigger("startCategoryShortcastRadio");
    },

    showApplicableHeaderButtons : function(){
        /*if(this.showCapsuleTab){
            if(this.isSongTabSelected()){
                this.createCapsuleBtn$el.hide();
                if(this.customSearchFieldView){
                    this.customSearchFieldView.$el.show();
                    
                }
                this.sortFilterBtn$el.show();
            }else{
                //Capsule Tab Selected
                if(this.customSearchFieldView){
                    this.customSearchFieldView.clearSearchField()
                    this.customSearchFieldView.$el.hide();
                    
                }
                this.sortFilterBtn$el.hide();
                this.createCapsuleBtn$el.attr('style', 'display: -webkit-flex; display: flex');
            }
        }*/
    },


    onHomeButtonSelected: function(){
        this.trigger("showHomePopup");
    },

    onHelpHeaderButtonClicked : function(){
        this.trigger("showHelpPage");
    },



    playMyFeed : function(){
        this.trigger("playMyFeed");
    },

    setPlaying : function(playing, loading){
        if(loading){
            this.$el.removeClass("isCurrentlyPlaying");
            this.$el.addClass("isCurrentlyLoading");
        }else{
            this.$el.removeClass("isCurrentlyLoading");
            if(playing) {
                this.$el.addClass("isCurrentlyPlaying");
            } else {
                this.$el.removeClass("isCurrentlyPlaying");

            }
        }
    },

    onPageBeforeShow : function(){				
        this.showApplicableHeaderButtons();
        this.showHideFloatingPlayButtonOnScrollIfApplicable();
        this._isPageDisplayed = true;
        this.trigger("pageBeforeShow");
    },

    isPageDisplayed : function(){
        return this._isPageDisplayed;
    },	


    onPageBeforeHide : function(){
        this._isPageDisplayed = false;
    },



    setFeedBoxLoading : function(){
        this.$el.attr("state",ON_FEED_TODAY_BOX_STATUS.LOADING);
        this.onYourFeedTodayBoxWrapper$el.attr("state",ON_FEED_TODAY_BOX_STATUS.LOADING);
    },


    setErrorOnFeedBoxLoading : function(error){
        this.$el.attr("state",ON_FEED_TODAY_BOX_STATUS.ERROR);
        this.onYourFeedTodayBoxWrapper$el.attr("state",ON_FEED_TODAY_BOX_STATUS.ERROR);
        this.onYourFeedTodayBoxWrapper$el.find(".error_message").html(error);
    },

    setFeedBoxHasNoContent : function(error){
        this.$el.attr("state",ON_FEED_TODAY_BOX_STATUS.NO_NEW_CONTENT);
        this.onYourFeedTodayBoxWrapper$el.attr("state",ON_FEED_TODAY_BOX_STATUS.NO_NEW_CONTENT);
    },

    getUserSalution : function(){
        return Utils.getInstance().getGreetings() +" "+Utils.getInstance().toTitleCase(LocalStorageHelper.getInstance().getUserFirstName());
    },
    
    setFeedBoxContent : function(trebbleNamesAndLatestHeadlinesInUserSubsription){
        this.$el.attr("state",ON_FEED_TODAY_BOX_STATUS.CONTENT_LOADED);
        this.onYourFeedTodayBoxWrapper$el.attr("state",ON_FEED_TODAY_BOX_STATUS.CONTENT_LOADED);

        if(trebbleNamesAndLatestHeadlinesInUserSubsription && trebbleNamesAndLatestHeadlinesInUserSubsription.length > 0){
            const welcomeMessageAfterOnboarding = Rollout.getFeatureVariable(Rollout.FEATURES.ADD_MY_FEED_PAGE, "here_is_whats_on_your_feed_after_onboarding_"+window.getAppUsedLanguage(), window.getI18n(ti18n,"HERE_IS_WHATS_ON_YOUR_FEED"));
            const regularWelcomeMessageAfterOnboarding =  Rollout.getFeatureVariable(Rollout.FEATURES.ADD_MY_FEED_PAGE, "here_is_whats_on_your_feed_"+window.getAppUsedLanguage(), window.getI18n(ti18n,"HERE_IS_WHATS_ON_YOUR_FEED"));
            const title =  this.getUserSalution()+ ", "+ (PersistentModels.getInstance().getRouter().isOnboardingWasDisplayedInSession()? welcomeMessageAfterOnboarding: regularWelcomeMessageAfterOnboarding); 
            let trebbleCoverArtPreviews = "";
            const addBoldSpanFunction = function(textHtml){
                return  "<span style='font-weight: 700;'>"+textHtml+"</span>";
            }
            this.$el.attr("lessThanFourShortcastsInFeed",trebbleNamesAndLatestHeadlinesInUserSubsription.length <=4);
            for(let i =0 ; i < trebbleNamesAndLatestHeadlinesInUserSubsription.length; i++){
                const trebbleThumnailUrl = trebbleNamesAndLatestHeadlinesInUserSubsription[i].trebbleCoverArtThumbnailUrl;
                trebbleCoverArtPreviews = trebbleCoverArtPreviews + "<div class='image_wrapper'><img class'trebble_cover_art_preview lazy' width='40'  height='40' src='"+trebbleThumnailUrl +"'/></div>";
            }
            let headlinesSummary = "";
            if(trebbleNamesAndLatestHeadlinesInUserSubsription.length == 1){
                headlinesSummary = window.getI18n(ti18n, "NEW_CAPSULE_FROM")+ " "+ addBoldSpanFunction(trebbleNamesAndLatestHeadlinesInUserSubsription[0].trebbleName);

            }

            if(trebbleNamesAndLatestHeadlinesInUserSubsription.length == 2){
                headlinesSummary =  window.getI18n(ti18n, "NEW_CAPSULES_FROM")+ " "+ addBoldSpanFunction(trebbleNamesAndLatestHeadlinesInUserSubsription[0].trebbleName) +" "+  window.getI18n(ti18n, "AND") + " " + addBoldSpanFunction(trebbleNamesAndLatestHeadlinesInUserSubsription[1].trebbleName);

            }

            if(trebbleNamesAndLatestHeadlinesInUserSubsription.length == 3){
                headlinesSummary =  window.getI18n(ti18n, "NEW_CAPSULES_FROM")+ " "+ addBoldSpanFunction(trebbleNamesAndLatestHeadlinesInUserSubsription[0].trebbleName) +", "+addBoldSpanFunction(trebbleNamesAndLatestHeadlinesInUserSubsription[1].trebbleName) +" "+  window.getI18n(ti18n, "AND") + " " + addBoldSpanFunction(trebbleNamesAndLatestHeadlinesInUserSubsription[2].trebbleName);
            }

            if(trebbleNamesAndLatestHeadlinesInUserSubsription.length > 3){
                headlinesSummary =  window.getI18n(ti18n, "NEW_CAPSULES_FROM")+ " "+ addBoldSpanFunction(trebbleNamesAndLatestHeadlinesInUserSubsription[0].trebbleName) +", "+addBoldSpanFunction(trebbleNamesAndLatestHeadlinesInUserSubsription[1].trebbleName) + ", " + addBoldSpanFunction(trebbleNamesAndLatestHeadlinesInUserSubsription[2].trebbleName)+ " " + window.getI18n(ti18n, "AND") +" "+ (trebbleNamesAndLatestHeadlinesInUserSubsription.length - 3) +" "+ window.getI18n(ti18n, "OTHERS") ;
            }
            const trebble_thumnails_preview_box$el =this.onYourFeedTodayBoxWrapper$el.find(".trebble_thumnails_preview_box");
            trebble_thumnails_preview_box$el.html(trebbleCoverArtPreviews);
            this.onYourFeedTodayBoxWrapper$el.find(".feedOverviewBox .title").html(title);
            this.onYourFeedTodayBoxWrapper$el.find(".feedOverviewBox .feed_description").html(headlinesSummary);
            //Utils.getInstance().initJqueryLazyLoadingPluginOnElemNew(trebble_thumnails_preview_box$el, "lazy", scrollContainer$el, customEvent )

        }
    },

    hideOnYourFeedTodayBox : function(){
        this.onYourFeedTodayBoxWrapper$el.addClass("hide");
        this._isOnYourFeedTodayBoxHidden = true;
        this.showHideFloatingPlayButtonOnScrollIfApplicable();
    },

    isOnYourFeedTodayBoxHidden : function(){
        return this._isOnYourFeedTodayBoxHidden;
    },

    setLoadingSubscriptions : function(loadingSubscriptions){
        this._loadingSubscriptions =  loadingSubscriptions;
        if(this._loadingSubscriptions){
            if(!this.$el.hasClass("loading_subscriptions")){
                this.$el.addClass("loading_subscriptions");
            }
        }else{
            this.$el.removeClass("loading_subscriptions");
        }
    },

    setNoSubscription : function(noSubscription){
        this._noSubscription =  noSubscription;
        if(this._noSubscription){
            if(!this.$el.hasClass("no_subscription")){
                this.$el.addClass("no_subscription");
            }
        }else{
            this.$el.removeClass("no_subscription");
        }
    },

    hasNoSuscription : function(){
        return this._noSubscription;
    },

    getOrderSubscriptionIds :function(){
        const trebbleIds = [];
        $(this.mySubscriptionsListView.el).find("#collection_repeat_container").children().each(function(){trebbleIds.push(this.getAttribute("trebble-id"))});
        return trebbleIds;
    },


    showHideFloatingPlayButtonOnScrollIfApplicable: function(){
        if(!this._noSubscription && !this._loadingSubscriptions){
            const showHideFloatingPlayButtonFunc = (function(){
                if(!this._noSubscription && !this._loadingSubscriptions){
                    //this._pendingshowHideFloatingPlayuttonFuncExecutionId = null;

                    let floatingPlayButtonShouldBeHidden = false;
                    if(this.playFeedBtnInYourFeedTodayBox$el.visible()){
                        floatingPlayButtonShouldBeHidden = true;
                    }

                    this.$el.attr("play_button_in_on_your_feed_today_box_displayed",floatingPlayButtonShouldBeHidden ?"true":"false");


                }
            }).bind(this);
            showHideFloatingPlayButtonFunc();
        }
    },

    render : function() {
        this.$el.html(this.template({"listeningInstructionHtml": Utils.getInstance().getHtmlTrebbleListeningInstructionsNew(null, true, false, true, true, false, true), "ti18n": ti18n,"userSalutation": this.getUserSalution(), "Rollout": Rollout}));
        this.setElement(this.$el.find("div").first());
        
        this.playMyFeedButton$el = this.$el.find("#playMyFeedButton");
        this.onYourFeedTodayBoxWrapper$el =  this.$el.find("#onYourFeedTodayBoxWrapper");
        this.playFeedBtnInYourFeedTodayBox$el = this.$el.find("#play_feed_btn");
        this.upgradeToTrebblePaidSubscriptionBoxWrapper$el = this.$el.find("#upgradeButton");
        this.$el.page();

        Utils.getInstance().initJqueryLazyLoadingPluginOnElemNew(this.$el.find("#subscriptionList #collection_repeat_container"), "img.lazy", this.$el.find("#subscriptionList"));


        //manually adding the wrapper necessary by JQM for showing Panel
        //JQM does it on the fly but that can cause flickering on the screen when it is done by jquery mobile
        //the workaround is to do that manually when the page is getting initialized
        this.$el.children( ".ui-header:not(.ui-header-fixed), .ui-content:not(.ui-popup), .ui-footer:not(.ui-footer-fixed)" ).wrapAll( "<div class='ui-panel-wrapper'></div>" )

        this.$el.on("pageshow", this.onPageBeforeShow.bind(this));
        this.$el.on("pagebeforehide", this.onPageBeforeHide.bind(this));				
        

        $(this.$el.find(".ui-content")).on("resize scroll", (function() {
            this.showHideFloatingPlayButtonOnScrollIfApplicable()
        }).bind(this));
        this.hideUpgradeBtn();

        this.showApplicableHeaderButtons();
        this._onViewportHeightChanged(PersistentModels.getInstance().getRouter().getViewportHeight(), true);
        return this;
    }

});

export default HomePageView;