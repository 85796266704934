	import $ from 'jquery';
    import _ from 'underscore';
    import Backbone from "backbone";
    import RSVP from "rsvp";



    const FacebookHelper = Backbone.Model.extend({


        initialize : function() {
            this._fbLoadPromise  = window._loadScript("https://connect.facebook.net/en_US/sdk.js").then(()=>{
                window.FB.init({
                    appId      : '1159511337400568',
                    version    : 'v3.2'
                  });
            });
        },
        
        login : function(permissionsArray){
            if(!permissionsArray){
                permissionsArray = ["public_profile","email"];
            }
            const onSuccessResponse = function(resolveFunction){
                return function(response){
                    if (response.status === 'connected') {
                        // Logged into your app and Facebook.
                        resolveFunction(response.authResponse);
                    } else {
                        // The person is not logged into this app or we are unable to tell. 
                        resolveFunction(null);

                    }
                }
            }
            if(window.facebookConnectPlugin){
                return new RSVP.Promise(function(resolve, reject){
                    if(window.facebookConnectPlugin){
                        window.facebookConnectPlugin.login(permissionsArray, onSuccessResponse(resolve), reject);
                    }
                })

            }else{
                if(window.FB){
                    return new RSVP.Promise(function(resolve, reject){
                        try{
                            window.FB.login(onSuccessResponse(resolve), {scope: permissionsArray.join()});
                        }catch(error){
                            reject(error);
                        }
                    })
                }else{
                    return  this._fbLoadPromise.then(function(FB){
                        return new RSVP.Promise(function(resolve, reject){
                            try{
                                FB.login(onSuccessResponse(resolve), {scope: permissionsArray.join()});
                            }catch(error){
                                reject(error);
                            }
                        })
                    });
                }

                

            }

        },



        logout : function(){
            return new RSVP.Promise(function(resolve, reject){
                if(window.waitForCordovaToLoad){
                    if(window.facebookConnectPlugin){
                        try{
                            window.facebookConnectPlugin.logout(resolve, reject);
                        }catch(error){
                            reject(error);
                        }
                    }
                }else{
                    if(window.FB){
                        try{
                            window.FB.logout.logout(resolve);
                        }catch(error){
                            reject(error);
                        }
                    }else{
                        resolve();
                    }
                }

            });
        },

        showDialog: function(options, googleAnalyticTrackingData){
            if(window.facebookConnectPlugin){
                return new RSVP.Promise(function(resolve, reject){
                    if(options.quote){
                        options.caption = options.quote;
                    }
                    window.facebookConnectPlugin.showDialog(options, function(){
                        resolve();
                        if(googleAnalyticTrackingData && window.trebbleAnalyticsHelper){
                            window.trebbleAnalyticsHelper.trackEvent(googleAnalyticTrackingData.entity, googleAnalyticTrackingData.eventName, googleAnalyticTrackingData.eventLabel);
                        } 
                    } , reject );
                })

            }else{
                const createPromiseToShowDialog = (function(fbVarialble){
                    return new RSVP.Promise(function(resolve, reject){
                        try{
                            if(options.caption){
                                options.quote = options.caption;
                            }
                            fbVarialble.ui(options, function(response){
                                if(response && response.error_message){
                                    reject(response.error_message);
                                }else{
                                    resolve();
                                    if(googleAnalyticTrackingData && window.trebbleAnalyticsHelper){
                                        window.trebbleAnalyticsHelper.trackEvent(googleAnalyticTrackingData.entity, googleAnalyticTrackingData.eventName, googleAnalyticTrackingData.eventLabel);
                                    } 
                                }
                            });
                        }catch(error){
                            reject(error);
                        }
                    })
                }).bind(this)
                if(window.FB){
                    try{
                        createPromiseToShowDialog(window.FB);
                        return RSVP.Promise.resolve();
                    }catch(error){
                        return RSVP.Promise.reject(error);
                    }
                }else{
                    return this._fbLoadPromise.then(createPromiseToShowDialog);
                    /*return this._fbLoadPromise.then(function(FB){
                        return new RSVP.Promise(function(resolve, reject){
                            try{
                                if(options.caption){
                                    options.quote = options.caption;
                                }
                                FB.ui(options, function(response){
                                    if(response && response.error_message){
                                        reject(response.error_message);
                                    }else{
                                        resolve();
                                        if(googleAnalyticTrackingData && window.trebbleAnalyticsHelper){
                                            window.trebbleAnalyticsHelper.trackEvent(googleAnalyticTrackingData.entity, googleAnalyticTrackingData.eventName, googleAnalyticTrackingData.eventLabel);
                                        } 
                                    }
                                });
                            }catch(error){
                                reject(error);
                            }
                        })
                    });*/
                }

            }
        }

    });

    const FacebookHelperInstance = new FacebookHelper();

    export default {
		getInstance : function() {
			return FacebookHelperInstance;
		}
	};