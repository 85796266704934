import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import CustomSearchFieldView from "views/common/CustomSearchFieldView";
import ti18n from "i18n!nls/PlaylistPodcasti18n";
import PlaylistPodcastsTemplate from 'text!../../../templates/playlistPodcasts/PlaylistPodcasts.html';
import RSVP from "rsvp";
import listHeaderTemplate from "text!../../../templates/common/listHeaderTemplate.html";

const ME_TAB_ID = "myPlaylistTab";
const FOLLOWERS_TAB_ID = "myFollowersPlaylistTab";
const EXPLORE_TAB_ID = "explorePlaylistTab";
const MY_TREBBLE_TAB_ID = "myPersonalRadioTab";
const MY_TREBBLOTS_TAB_ID = "myAutoRadioTab";
const PlaylistPodecastsView = Backbone.View.extend({

    template : _.template(PlaylistPodcastsTemplate),
    listHeaderTemplateCompiled : _.template(listHeaderTemplate),

    initialize : function(options) {

        this._autoPlaylistView = options.autoPlaylistView;
        this._genrePlaylistView = options.genrePlaylistView;
        this._myRadioPlaylistView = options.myRadioPlaylistView;
        this._myFollowersPersonalRadioView =  options.myFollowersPersonalRadioView;
        this._myPersonalRadioView = options.myPersonalRadioView;
        this.selectedTab = ME_TAB_ID;
        //this.subSelectedTab = MY_TREBBLE_TAB_ID;
        this._listenerViewportKey = "playlistPodcastPageView" + (new Date()).getTime();
        
        this._onKeyUpInSearchFieldHandler = options.onKeyUpInSearchFieldHandler;
        this._onSearchFieldClearedHandler = options.onSearchFieldClearedHandler;
        PersistentModels.getInstance().getRouter().registerForViewportHeightChange(this._listenerViewportKey,this._onViewportHeightChanged.bind(this));

    },
    
    _onViewportHeightChanged : function(newViewPortHeigh, forceResizing) {
        if(PersistentModels.getInstance().getRouter().isEqualToCurrentPageUrl("#PlaylistPodcastsPage") || forceResizing){
            if (this.el) {
                const tabHeight = 46;
                const subTabHeight = 44;
                this.$contentPlaceHolder.css("height", (newViewPortHeigh - tabHeight) + "px");
                this.$myAutoRadioContentPlaceHolder.css("height", (newViewPortHeigh - tabHeight /*-subTabHeight*/) + "px");
                this.$myPersonalRadioContentPlaceHolder.css("height", (newViewPortHeigh - tabHeight /*-subTabHeight*/) + "px");
                this.$myFollowersPlaceHolder.css("height", (newViewPortHeigh - tabHeight) + "px");
            }
        }
    },

    events : {
        "click #playRecentFromAll" : "playRecentFromAll",
        "click #playRecentlyAddedOrFavoritedByAll" : "playRecentlyAddedOrFavoritedByAll",
        "click #homeHeaderButton" : "onHomeHeaderButtonClicked",
        "click #myPlaylistTab": "_onTabSelected",
        "click #myFollowersPlaylistTab": "_onTabSelected",
        "click #myPersonalRadioTab": "_onTabSelected",
        "click #myAutoRadioTab": "_onTabSelected",
    },
    
    _onTabSelected : function(ev){
        this.selectedTab = arguments[0].currentTarget.id;
    },
    
    /*_onSubTabSelected : function(ev){
        this.subSelectedTab = arguments[0].currentTarget.id;
    },*/
    
    
    _buildSearchHeaderButtonAndToPage: function(){
        const customSearchFieldViewParams = {};
        customSearchFieldViewParams.searchFieldPlaceholderSelector = "#pageHeaderTitleWrapper";
        customSearchFieldViewParams.searchFieldPlaceholderSelectorRootNodeEl  = this.el;
        this.customSearchFieldView = new CustomSearchFieldView(customSearchFieldViewParams);
        this.customSearchFieldView.render();
        this.customSearchFieldView.addKeyUpHandlerFunction(this._onKeyUpInSearchField.bind(this));
        this.customSearchFieldView.addOnSearchFieldClearedFunction(this._onSearchFieldCleared.bind(this));
        this.customSearchFieldView.addOnScreenResizeHandlerFunction(this._resizeSearchField.bind(this))
        this.$el.find(".rightHeaderButtonGroup").prepend(this.customSearchFieldView.el);
    },
    
    getSearchFieldValue : function(){
        return this.customSearchFieldView.getSearchFieldValue();
    },
    
    _resizeSearchField : function(searchField){
        const homeButtonWidth = 61;
        const searchButtonWidth = 56;
        //const identifyButtonWidth = 56;
        const newSearchFieldWidth = (window.getDocumentClienWidth() - homeButtonWidth - searchButtonWidth/* - identifyButtonWidth*/) + "px";
        searchField.width(newSearchFieldWidth);
    },
    _onKeyUpInSearchField : function(searchFieldValue){
        if(this._onKeyUpInSearchFieldHandler){
            this._onKeyUpInSearchFieldHandler(searchFieldValue);
        }
    },
    

    _onSearchFieldCleared : function(){
        if(this._onSearchFieldClearedHandler){
            this._onSearchFieldClearedHandler();
        }
    },
    

    
    isMyTrebblesTabSelected: function(){
        return this.selectedTab == ME_TAB_ID;
    },
    
    isFollowersTabSelected: function(){
        return this.selectedTab == FOLLOWERS_TAB_ID;
    },
    
    
    isMyTrebblotsTabSelected: function(){
        return this.subSelectedTab == MY_TREBBLOTS_TAB_ID;
    },
    
    /*
    
    
    _onMeTabSelected: function(){
        this.selectedTab = ME_TAB_ID;
    },
    
    isMeTabSelected: function(){
        return this.selectedTab == ME_TAB_ID;
    },
    
    _onFollowersTabSelected: function(){
        this.selectedTab = FOLLOWERS_TAB_ID;
    },
    
    isFollowersTabSelected: function(){
        return this.selectedTab == FOLLOWERS_TAB_ID;
    },
    
    _onExploreTabSelected: function(){
        this.selectedTab = EXPLORE_TAB_ID;
    },
    
    isExploreTabSelected: function(){
        return this.selectedTab == EXPLORE_TAB_ID;
    },
    
    _onMyTrebblesTabSelected: function(){
        this.subSelectedTab = MY_TREBBLE_TAB_ID;
    },
    
    isExploreTabSelected: function(){
        return this.subSelectedTab == MY_TREBBLE_TAB_ID;
    },
    
    _onMyTrebblotsTabSelected: function(){
        this.subSelectedTab = MY_TREBBLOTS_TAB_ID;
    },
    
    isMyTrebblotsTabSelected: function(){
        return this.subSelectedTab == MY_TREBBLOTS_TAB_ID;
    },
    */
    onPageBeforeShow : function(){
        this._setCurrentTabWithActiveClass();
    },
    
    
    
    _setCurrentTabWithActiveClass: function(){
        this.$el.find(".ui-tabs-anchor.ui-btn-active").removeClass("ui-btn-active");
        this.$el.find("#"+ this.selectedTab).addClass("ui-btn-active");
        //this.$el.find("#"+ this.subSelectedTab).addClass("ui-btn-active");
    },



    playRecentFromAll : function(){
        this.trigger("playRecentFromAll");
    },

    onHomeHeaderButtonClicked : function(){
        this.trigger("showHomePopupView");
    },

    playRecentlyAddedOrFavoritedByAll : function(){
        this.trigger("playRecentlyAddedOrFavoritedByAll");
    },

    _initAutoPlaylistView : function(){
        if(this._autoPlaylistView)
        {
            this.$autoPlaylistListExtendedViewContentHolder = $(document.createElement('div'));
        //this.$autoPlaylistListExtendedViewContentHolder.append(this.listHeaderTemplateCompiled({"id" : "autoPlaylistListHeader","text" : "Auto Playlists"}));
        this.$autoPlaylistListExtendedViewContentHolder.append(this._autoPlaylistView.el);
        //this._autoPlaylistView.load();
        window.setTimeout((function(){
            this._autoPlaylistView.load();
        }).bind(this),0);
        
        this.$autoPlaylistListExtendedViewContentHolder.addClass("left-right-content-padding");
        this.$myAutoRadioContentPlaceHolder.append(this.$autoPlaylistListExtendedViewContentHolder);
        //this.listenTo(this._autoPlaylistView, "modelChange",this._onMyLibrarySongCollectionChange );
    }
},

_initMyRadioPlaylistView : function(){
    if(this._myRadioPlaylistView)
    {
        this.$myRadioPlaylistListExtendedViewContentHolder = $(document.createElement('div'));
        this.$myRadioPlaylistListExtendedViewContentHolder.append(this.listHeaderTemplateCompiled({"id" : "myRadioPlaylistListHeader","text" : window.getI18n(ti18n,"MY_TREBBLES")}));
        this.$myRadioPlaylistListExtendedViewContentHolder.append(this._myRadioPlaylistView.el);
        window.setTimeout((function(){
            this._myRadioPlaylistView.load();
        }).bind(this),0);
        
        this.$myRadioPlaylistListExtendedViewContentHolder.addClass("left-right-content-padding");
        this.$contentPlaceHolder.append(this.$myRadioPlaylistListExtendedViewContentHolder);
        //this.listenTo(this._autoPlaylistView, "modelChange",this._onMyLibrarySongCollectionChange );
    }
},


_initGenrePlaylistView  : function(){
    if(this._genrePlaylistView)
    {
        this.$genrePlaylistListViewContentHolder = $(document.createElement('div'));
        this.$genrePlaylistListViewContentHolder.append(this.listHeaderTemplateCompiled({"id" : "genrePlaylistListHeader","text" : window.getI18n(ti18n,"GENRE_PLAYLISTS")}));
        this.$genrePlaylistListViewContentHolder.append(this._genrePlaylistView.el);
        window.setTimeout((function(){
            this._genrePlaylistView.load();
        }).bind(this),0);
        
        this.$genrePlaylistListViewContentHolder.addClass("left-right-content-padding");
        this.$contentPlaceHolder.append(this.$genrePlaylistListViewContentHolder);
        //this.listenTo(this._autoPlaylistView, "modelChange",this._onMyLibrarySongCollectionChange );
    }
},

_initMyFollowerPlayerView  : function(){
    if(this._myFollowersPersonalRadioView)
    {
        this.$followersPersonalRadioListViewContentHolder = $(document.createElement('div'));
        //this.$followersPersonalRadioListViewContentHolder.append(this.listHeaderTemplateCompiled({"id" : "myFollowerPersonalRadioListHeader","text" : "Trebbles I'm Following"}));
        this.$followersPersonalRadioListViewContentHolder.append(this._myFollowersPersonalRadioView.el);
        window.setTimeout((function(){
            this._myFollowersPersonalRadioView.load();
        }).bind(this),0);
        
        this.$followersPersonalRadioListViewContentHolder.addClass("left-right-content-padding");
        this.$myFollowersPlaceHolder.append(this.$followersPersonalRadioListViewContentHolder);
        //this.listenTo(this._autoPlaylistView, "modelChange",this._onMyLibrarySongCollectionChange );
    }
},

_initMyPersonalRadiosView  : function(){
    if(this._myFollowersPersonalRadioView)
    {
        this.$myPersonalRadiosListViewContentHolder = $(document.createElement('div'));
        //this.$followersPersonalRadioListViewContentHolder.append(this.listHeaderTemplateCompiled({"id" : "myFollowerPersonalRadioListHeader","text" : "Trebbles I'm Following"}));
        this.$myPersonalRadiosListViewContentHolder.append(this._myPersonalRadioView.el);
        window.setTimeout((function(){
            this._myPersonalRadioView.load();
        }).bind(this),0);
        
        this.$myPersonalRadiosListViewContentHolder.addClass("left-right-content-padding");
        this.$myPersonalRadioContentPlaceHolder.append(this.$myPersonalRadiosListViewContentHolder);
        //this.listenTo(this._autoPlaylistView, "modelChange",this._onMyLibrarySongCollectionChange );
    }
},

onBeforeRemoveFromPage : function(){
    this.trigger("beforeRemoveFromPage", this);
},

onRemovedFromPage : function(){
    this.undelegateEvents();
    this.$el.removeData().unbind(); 
    PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._listenerViewportKey);
    this.trigger("removedFromPage", this);
},


render : function(){
    this.$el.html(this.template({"ti18n": ti18n}));
    this.setElement(this.$el.find("div").first());
    this._buildSearchHeaderButtonAndToPage();
    return new RSVP.Promise((function(resolve, reject){
        this.$el.page({"create": (function(){
            //manually adding the wrapper necessary by JQM for showing Panel
            //JQM does it on the fly but that can cause flickering on the screen when it is done by jquery mobile
            //the workaround is to do that manually when the page is getting initialized
            this.$el.children( ".ui-header:not(.ui-header-fixed), .ui-content:not(.ui-popup), .ui-footer:not(.ui-footer-fixed)" ).wrapAll( "<div class='ui-panel-wrapper'></div>" )
            resolve();
        }).bind(this)});
        this.$el.on("pagebeforeshow", this.onPageBeforeShow.bind(this));
    }).bind(this)).then((function(){
        this.$contentPlaceHolder =  this.$el.find("#playlistWrapperTest").first();
        this.$myPersonalRadioContentPlaceHolder =  this.$el.find("#myPersonalRadioWrapper").first();
        this.$myAutoRadioContentPlaceHolder =  this.$el.find("#myAutoRadioWrapper").first();
        this.$myFollowersPlaceHolder =  this.$el.find("#myFollowersPlaylistWrapper").first();
        //this.$explorePlaylistsWrapperPlaceHolder =  this.$el.find("#explorePlaylistsWrapper").first();
        const newViewportSize = PersistentModels.getInstance().getRouter().getViewportHeight();
        const subTabHeight =  34;
        const tabHeight = 40;
        const tabContentTopPadding = 6;
        //const subTabContentTopPadding = 6;
        //const subTabContentHeight =  newViewportSize -tabHeight - tabContentTopPadding - subTabHeight -subTabContentTopPadding;
        const tabContentHeight =  newViewportSize -tabHeight - tabContentTopPadding ;
        this.$contentPlaceHolder.css("height", newViewportSize + "px");
        this.$myPersonalRadioContentPlaceHolder.css("height",tabContentHeight + "px");
        this.$myAutoRadioContentPlaceHolder.css("height", tabContentHeight + "px");
        this.$myFollowersPlaceHolder.css("height", tabContentHeight + "px");
        //this.$explorePlaylistsWrapperPlaceHolder.css("height", tabContentHeight + "px");
        this._initAutoPlaylistView();
        this._initMyRadioPlaylistView();
        this._initGenrePlaylistView();
        this._initMyFollowerPlayerView();
        this._initMyPersonalRadiosView();
        this._onViewportHeightChanged(PersistentModels.getInstance().getRouter().getViewportHeight(), true);
        
        return this;
    }).bind(this));


},

});
export default PlaylistPodecastsView;