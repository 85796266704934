import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utils from "models/helper/Utils";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import ti18n from "i18n!nls/Useri18n";
import UserChangePasswordPageView from "views/user/UserChangePasswordPageView";

const UserChangePasswordPageController =  Chaplin.Controller.extend({


    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = null;
        this._pageId = options.pageId;


    },
    
    getModel : function(){
        return this.model;
    },
    
    getView : function(){
        return this.view;
    },
    
    saveChanges : function(dataToChange){

        $.mobile.loading("show");
        const currentPassword = dataToChange.currentPassword;
        const newPassword = dataToChange.newPassword;
        const reenterNewPassword = dataToChange.reenterNewPassword;

        return TrebbleClientAPIHelper.getInstance().changePassword(currentPassword, newPassword, reenterNewPassword).then((function(){
            const userInfoJson= LocalStorageHelper.getInstance().getUserInfo();
            return TrebbleClientAPIHelper.getInstance().postLogin(userInfoJson.username, newPassword);
        }).bind(this)).then((function(){
            this.view.navigateToPreviousPage();
            window.alertSuccessMessage(window.getI18n(ti18n, "YOUR_PASSWORD_WAS_CHANGED"));
            $.mobile.loading("hide");
        }).bind(this)).catch((function(error){
            $.mobile.loading("hide");
            this.view.setSaveButtonEnabled(true);
            const errorMessage = error? error: window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_RESETTING_PASSWORD");
            window.alertErrorMessage(errorMessage);
        }).bind(this));
        
        
    },
    




    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {

                const pageViewParams= {};
                pageViewParams.pageId = this._pageId;
                this.view = new UserChangePasswordPageView(pageViewParams);
                this.view.render();
                this.listenTo(this.view, "saveChanges", this.saveChanges);
                resolve(this);
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }
    

});
export default UserChangePasswordPageController;