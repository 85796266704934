import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import Utils from "models/helper/Utils";
import SongTagCollectionController from "controllers/common/SongTagCollectionController";
import SongTagManagerPopupView from "views/common/SongTagManagerPopup";
import TrebbleIndexDBHelper from "models/helper/TrebbleIndexDBHelper";
import RSVP from "rsvp";

const SongTabManagerPopupController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.model = options? options.songModel : null;
        this.context = options? options.context : null;
        this._onPopupCloseHanlder = options? options.onPopupClose : null;
        this._newSelectedTagIds = null;
        this._doNotCloseOnTagSelected = false;
        _.extend(this, Backbone.Events);
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },
    
    onSelectedTagIdsChanged : function(newSelectedTagIds){
        this._newSelectedTagIds = newSelectedTagIds;
        if(!this._doNotCloseOnTagSelected && this._newSelectedTagIds && this._newSelectedTagIds.length > 0){
            this.view._close();
        }	
    },
    
    saveTagChanges : function(){
        if(this._newSelectedTagIds){
            this.model.set("tagIds",this._newSelectedTagIds);
            TrebbleIndexDBHelper.getInstance().saveSong(this.model.toJSON(), this.context);
        }
        
    },

    onPopupClosed : function(){
        this.saveTagChanges();
        if(this._onPopupCloseHanlder){
            this._onPopupCloseHanlder();
        }
    },


    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                const SongTagCollectionControllerParams =  {};
                SongTagCollectionControllerParams.model = this.model;
                const songTagCollectionController  = new SongTagCollectionController(SongTagCollectionControllerParams);
                this.listenTo(songTagCollectionController, "selectedTagIdsChange", this.onSelectedTagIdsChanged.bind(this))
                this._songTagCollectionController = songTagCollectionController;
                const promise =  songTagCollectionController.buildModelAndRenderView().then((function(){
                    const songTagsView = songTagCollectionController.getView();
                    
                    const params= {};
                    params.contentView = songTagsView;
                    params.song = this.model;
                    this.view = new SongTagManagerPopupView(params);
                    this.listenTo(this.view , "closed",this.onPopupClosed.bind(this));
                    this.view.render();
                    return null;
                }).bind(this))
                promise.then(resolve).catch(reject);
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default SongTabManagerPopupController;