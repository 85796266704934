import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import SequencerAutomatedOperationsOptionView from "views/audioEditor/SequencerAutomatedOperationsOptionView";
import SequencerAutomatedOperationsOptionContentView from "views/audioEditor/SequencerAutomatedOperationsOptionContentView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import SoundEnhancerSettingsPopupContoller from "controllers/audioEditor/SoundEnhancerSettingsPopupContoller";
import TrimSilencesContextMenuController from "controllers/audioEditor/TrimSilencesContextMenuController";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import ti18n from "i18n!nls/Sequenceri18n";
import RSVP from "rsvp";

const SequencerAutomatedOperationsOptionController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.model = options? options.sequencer : null;
        
        this.context = options? options.context : null;
        this.elementSource$el = options? options.elementSource$el : null;
        this._popupPosition = options? options.popupPosition: null;
        this._onMenuClosedHandler = options? options.onMenuClose: null;
        _.extend(this, Backbone.Events);
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    deleteFillerWords : function(){
        const numberOfFillerWordsRemoved =  this.model.deleteAllFillerWords();
        this.close();
        Utils.getInstance().showInfoNotification(`${numberOfFillerWordsRemoved} ${numberOfFillerWordsRemoved> 1? window.getI18n(ti18n, "FILLER_WORDS_REMOVED"): window.getI18n(ti18n, "FILLER_WORD_REMOVED")}`);
    },

    findDisfluencies: function(){
        this.model.findDisfluencies();
        this.close();
    },


    trimSilencesConfirm : function(){
        if(this._isUsingBottomSheet){
            this.close();
        }
        return PersistentModels.getInstance().getRouter().showTrimSilencesContextMenuPopup(false,this.model, this.contentView.getTrimSilencesMenu$el(), TrimSilencesContextMenuController, "right middle").then((function(c){
            this._trimSilencesMenuContoller = c;
            this.listenTo(this._trimSilencesMenuContoller, "popupClosed", this._onTrimSilencesPopupContollerClosed.bind(this));
            this.listenTo(this._trimSilencesMenuContoller, "silenceTrimExecuted", this._onSilenceTrimExecuted.bind(this));
            return this.contentView.setTrimSilencesMenuContoller(this._trimSilencesMenuContoller);
        }).bind(this));
    },

    findSilencesConfirm : function(){
        if(this._isUsingBottomSheet){
            this.close();
        }
        return PersistentModels.getInstance().getRouter().showTrimSilencesContextMenuPopup(true, this.model, this.contentView.getFindSilencesMenu$el(), TrimSilencesContextMenuController, "right middle").then((function(c){
            this._trimSilencesMenuContoller = c;
            this.listenTo(this._trimSilencesMenuContoller, "popupClosed", this._onTrimSilencesPopupContollerClosed.bind(this));
            this.listenTo(this._trimSilencesMenuContoller, "silenceFindExecuted", this._onSilenceFindExecuted.bind(this));
            return this.contentView.setTrimSilencesMenuContoller(this._trimSilencesMenuContoller);
        }).bind(this));
    },

    _onSilenceTrimExecuted: function(numberOfSilencesRemoved){
        this._trimSilencesMenuContoller.close();
        this.close();
        Utils.getInstance().showInfoNotification(`${numberOfSilencesRemoved} ${numberOfSilencesRemoved> 1? window.getI18n(ti18n, "SILENCES_TRIMMED"): window.getI18n(ti18n, "SILENCE_TRIMMED")}`);
    },

    _onSilenceFindExecuted: function(){
        this._trimSilencesMenuContoller.close();
        this.close();
    },

    _onTrimSilencesPopupContollerClosed: function(){
        if(this._trimSilencesMenuContoller){
            this.stopListening(this._trimSilencesMenuContoller);
        }
        this.contentView.setTrimSilencesMenuContoller(null);
    },

    _onSoundEnhancerSettingsPopupContollerClosed : function(){
        if(this._soundEnhancerSettingsPopupContoller){
            this.stopListening(this._soundEnhancerSettingsPopupContoller);
        }
        this.contentView.setSoundEnhancerSettingsPopupContoller(null);
    },

    showConfigureMagicSoundEnhancerContextMenu : function(){
        if(this._isUsingBottomSheet){
            this.close();
        }
        return PersistentModels.getInstance().getRouter().showSoundEnhancerSettingsPopupContoller(this.model, this.contentView.getSoundEnhancerMenu$el(), SoundEnhancerSettingsPopupContoller, "right middle").then((function(c){
            this._soundEnhancerSettingsPopupContoller = c;
            this.listenTo(this._soundEnhancerSettingsPopupContoller, "popupClosed", this._onSoundEnhancerSettingsPopupContollerClosed.bind(this));
            return this.contentView.setSoundEnhancerSettingsPopupContoller(this._soundEnhancerSettingsPopupContoller);
        }).bind(this));
        
    },

    close: function(){
        return this.view.close();
    },

    onMenuClosed: function(){
        if(this._onMenuClosedHandler){
            this._onMenuClosedHandler();
        }
        this.stopListening();
    },

    changeNoiseCancellation : function(){
        this.model.toggleNoiseCancellation();
    },

    onNoiseCancellationOnChange: function(noiseCancellationOn){
        this.contentView.setNoiseCancellationOn(this.getModel().getSequencerSettings().isNoiseCancellationOn())
    },

    onFirstNoiseCancellationTurnedOn :function(){
        Utils.getInstance().showWarningNotification(window.getI18n(ti18n, "NOISE_CANCELLAITON_TIME_WARNING"))
    },


    onFirstMagicSoundEnhancerTurnedOn :function(){
        
    },

    changeMagicSoundEnhancer :function(){
        this.model.toggleMagicSoundEnhancer();
    },

    onMagicSoundEnhancerOnChange: function(magicSoundEnhancerOn){
        this.contentView.setMagicSoundEnhancerOn(this.getModel().getSequencerSettings().isMagicSoundEnhancerOn())
    },

    findFillerWords : function(){
        this.model.findFillerWords();
        this.close();
    },

    findEditBoundaries: function(){
        this.model.findEditBoundaries();
        this.close();
    },


    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {

                const contentParams = {
                    "model" : this.model,
                    "elementSource$el": this.elementSource$el,
                    "popupPosition": this._popupPosition
                };
                const contentView = new SequencerAutomatedOperationsOptionContentView(contentParams);
                contentView.render();
                this.contentView = contentView;

                this.listenTo(this.model.getSequencerSettings(), "change:noiseCancellationOn", this.onNoiseCancellationOnChange);
                this.listenTo(this.model.getSequencerSettings(), "change:magicSoundEnhancerOn", this.onMagicSoundEnhancerOnChange);
                this.listenTo(this.model, "firstNoiseCancellationTurnedOn", this.onFirstNoiseCancellationTurnedOn);
                this.listenTo(this.model, "firstMagicSoundEnhancerTurnedOn", this.onFirstMagicSoundEnhancerTurnedOn);
                this.listenTo(contentView, "deleteFillerWords",this.deleteFillerWords);
                this.listenTo(contentView, "trimSilences", this.trimSilencesConfirm);
                this.listenTo(contentView, "findFillerWords", this.findFillerWords);
                this.listenTo(contentView, "findEditBoundaries", this.findEditBoundaries);
                
                this.listenTo(contentView, "findSilences", this.findSilencesConfirm);
                this.listenTo(contentView, "configureMagicSoundEnhancer",this.showConfigureMagicSoundEnhancerContextMenu);
                this.listenTo(contentView, "noiseCancellationChange", this.changeNoiseCancellation);
                this.listenTo(contentView, "magicSoundEnhancerChange", this.changeMagicSoundEnhancer);
                this.listenTo(contentView, "findDisfluencies", this.findDisfluencies);
                this._isUsingBottomSheet = false;
                if(window.isMobileBrowser){
                    //const bottomSheet = Utils.getInstance().createBottomSheet(this._sequencerNodeInspectorSectionController.getView().$el.get(0), resolve, this._onPopupClosed.bind(this));
                    const onBottomSheetDisplayed = function(){

                    }
                    const bottomSheet = Utils.getInstance().createBottomSheet(contentView.$el.get(0), onBottomSheetDisplayed, this.onMenuClosed.bind(this), true);
                    bottomSheet.activate();
                    bottomSheet.close = function(){
                        bottomSheet.deactivate();
                    }
                    this.view = bottomSheet;
                    this._isUsingBottomSheet = true;
                    resolve();
                }else{
                    contentParams.contentView = contentView;
                    this.view = new SequencerAutomatedOperationsOptionView(contentParams);
                    this.listenTo(this.view, "closed", this.onMenuClosed);
                    this.view.render();
                    resolve();
                }

            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default SequencerAutomatedOperationsOptionController;