import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import RSVP from "rsvp";

const AudioPosition =  Backbone.Model.extend({
    idAttribute: "id",

    constructor: function(attributes, options) {
        Backbone.Model.apply(this, [attributes, options]);
    },

    getStartTime : function(){
        return this.get("startTime");
    },

    getEndTime : function(){
        return this.get("endTime");
    },
    
    setStartTime : function(startTime){
        this.set("startTime",startTime);
    },

    setEndTime : function(endTime){
        this.set("endTime", endTime);
    },

    getDuration : function(){
        const startTime = this.getStartTime();
        const endTime = this.getEndTime();
        if(startTime >= 0 && endTime >= 0 ){
            return endTime - startTime;
        }
        return 0;
    },



});



export default AudioPosition; 