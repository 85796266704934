//NOT_USED_ANYMORE
import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import ListItemCollection from "collections/ListItemCollection";
import DynamicContentView from "views/common/DynamicContentView";
import RSVP from "rsvp";
import moment from 'moment';
import Swiper from 'swiper';
import {BarChart, Interpolation, Svg}  from 'chartist';


const SEVEN_DAYS_STATS_KEY = "7daysStats";
const FOURTEEN_DAYS_STATS_KEY = "14daysStats";
const THIRTY_DAYS_STATS_KEY = "30daysStats";
const TWELVE_MONTHS_STATS_KEY = "12MonthsStats";
const ALL_TIMES_STATS_KEY = "allTime";
const ALL_CONTEXT_DATA_FOUND_KEY = "allContextDataFoundKey";
const dataTypes = ["plays","completePlays","skips","comments","likes"];
const dataTypeToLabel = {"plays": "Plays",
"completePlays": "Finished Plays",
"skips": "Skips",
//"shares": "Shared", 
"comments": "Comments",
"likes": "Likes",
//	"views": "Views",
//	"repicked": "Re-picked"
};// First time listeners should be in the same graph as trebble plays
const dataTypeToFontIcon = {"plays": "ion-ios7-play-outline",
"completePlays": "ion-ios7-play-outline",
"skips": "ion-ios7-skipforward-outline", 
//"shares": "pe-7s-share", 
//	"repicked":"ion-ios7-plus-outline",
"comments": "ion-ios7-chatbubble-outline",
"likes": "Likes",
//	"views": "Views"
};
const supportedActionContext = ["trebbleContext","sharedCapsuleContext","sharedSongContext","executedBySongOwnerContext"/*,"allContext"*/];
const dataTypeWithBarChart = [];//["listeningTime"];
const dataInSecondsToBeConverted = [];
const OWNER_COUNT_ALWAYS_DISPLAYED  = true;
const ALL_CONTEXT_TYPE = "allContext";
const actionTypeToUnsupportedContext ={"comments": ["executedBySongOwnerContext"],"likes": ["executedBySongOwnerContext"]}
const contextTypeToContextLabel ={"trebbleContext": "In A Trebble","sharedCapsuleContext": "Via Capsule Shared Link or Embed","sharedSongContext": "Via Song Shared Link or Embed","executedBySongOwnerContext": "By Me"};
const chartCssClassToActionContext = function(){
	const classSuffixes ="abcdef";
	const chartCssClassToActionContextMap = {};
	for(let i in supportedActionContext){
		const actionContext = supportedActionContext[i];
		const chartClass = "ct-series-"+classSuffixes.charAt(i);
		chartCssClassToActionContextMap[chartClass]  = actionContext;
	}
	return chartCssClassToActionContextMap;
}();
const actionContextToChartCssClass = {};
for(let cssClass in chartCssClassToActionContext){
	actionContextToChartCssClass[chartCssClassToActionContext[cssClass]] = cssClass;
}

const SongStatisticsContentView = DynamicContentView.extend({


	initialize : function(options) {
		this._songStats = this._prepareData(options.songStats);
		this._periodSelectorView = options.periodSelectorView;
		this._statsGraphData = null;
		this._statsTotalCount = null;
		this._songTrebbleId = options.songTrebbleId;
		this._selectedTimePeriodKey =null;
		this._collectionContainerId =  "trebbleStatisticCollectionWrapper" + (new Date()).getTime();
		this._getStatisticsLoadFunction = options.getStatisticsLoadFunction;
		this._chartElementsArray =[];
		DynamicContentView.prototype.initialize.call(this, {"canBeAppendedBeforeLoad": true});
	},

	_isContextUnsupportedForAction : function(action, context){
		return (actionTypeToUnsupportedContext[action] && actionTypeToUnsupportedContext[action].indexOf(context)  != -1 )
	},

	_getUnsupportedContextForAction : function(action){
		return actionTypeToUnsupportedContext[action] ;
	},

	

	_prepareData: function(songData){
		const preparedSongData = {};
		let m;
		if(songData){
			for(let action in dataTypeToLabel){
				const data = songData[action];
				if(data){
					preparedSongData[action] = {"last30Days": {},"last12Months":{},"allTime": {}};
					for(let ii in supportedActionContext){
						const  contextType = supportedActionContext[ii];
						preparedSongData[action]["last30Days"][contextType] = {};
						preparedSongData[action]["last12Months"][contextType] = {};
						preparedSongData[action]["allTime"][contextType] = {};
						const contextData = data[contextType];
						//preparedSongData[action][contextType] = {"last30Days": {},"last12Months":{}};
						for(let oo in contextData.last30Days){
							let dailyData = contextData.last30Days[oo];
							if(dailyData){
								m = moment(dailyData.time).utc();
								preparedSongData[action]["last30Days"][contextType][m.format("YYYY MM DD")] = dailyData.count? dailyData.count: 0;
							}
						}
						for(let oo in contextData.last12Months){
							const monthlyData = contextData.last12Months[oo];
							if(monthlyData){
								m = moment(monthlyData.time).utc();
								preparedSongData[action]["last12Months"][contextType][m.format("YYYY MM")] = monthlyData.count? monthlyData.count: 0;
							}
						}
						for(let oo in contextData.allTime){
							const yearData = contextData.allTime[oo];
							if(yearData){
								m = moment.parseZone(yearData.time);
								preparedSongData[action]["allTime"][contextType][m.format("YYYY")] = yearData.count? yearData.count: 0;
							}
							if(!this._earliestYearToDisplay || this._earliestYearToDisplay > m.get('year')){
								this._earliestYearToDisplay = m.get('year');
							}
						}
					}

				}

			}
		}
		return preparedSongData;

	},

	buildDaysStatsData : function(){
		if(!this._statsGraphData){
			this._statsGraphData = {};
			this._statsTotalCount = {};
		}
		if(this._songStats){
			for(let i in dataTypes){
				const dataType = dataTypes[i];
				const data = this._songStats[dataType];
				const sevenDaysGraphStats = {"labels": [],"series":[]};
				const fourteenDaysGraphStats = {"labels": [],"series":[]};
				const thirtyDaysGraphStats = {"labels": [],"series":[]};
				let totalCountSevenDays = 0;
				let totalCountFourteenDays = 0;
				let totalCountThirtyDays = 0;
				const allContextTypeFound = false;
				if(data.last30Days){
					//build series for graph
					for(let ii in supportedActionContext){
						const contextType = supportedActionContext[ii]
						const contextTypeData  = data.last30Days[contextType];
						if(contextTypeData){
							const todaysDate =new Date();
							todaysDate.setHours(0,0,0,0);
							const dateCounter = todaysDate;
							const thirtyDaysyDataForContextType = contextTypeData//contextTypeData.last30Days;
							const thirtyDaysDataSerieForContextType = [];
							for(let i = 0; i < 30; i ++){
								dateCounter.setDate(dateCounter.getDate() - 1);
								if( !this._isContextUnsupportedForAction(dataType,contextType)){
									let dailyDataCount = thirtyDaysyDataForContextType[moment(dateCounter).format("YYYY MM DD")];
									if(!dailyDataCount){
										dailyDataCount = 0;
									}
									thirtyDaysDataSerieForContextType.push(dailyDataCount? dailyDataCount: 0);
									//if(ALL_CONTEXT_TYPE == contextType){
										const allContextTypeFound = true;
										if(i < 7){
											totalCountSevenDays = totalCountSevenDays + dailyDataCount;
										}
										if(i < 14){
											totalCountFourteenDays = totalCountFourteenDays +dailyDataCount;
										}
										if(i < 30){
											totalCountThirtyDays = totalCountThirtyDays + dailyDataCount;
										}
									}else{
										thirtyDaysDataSerieForContextType.push(0);
									}
									//}
								}
								const sevenDaysDataForContextType = thirtyDaysDataSerieForContextType.slice(0, 7);
								sevenDaysDataForContextType.reverse();
								sevenDaysGraphStats.series.push(sevenDaysDataForContextType);
								const fourteenDayDataForContextType = thirtyDaysDataSerieForContextType.slice(0, 14);
								fourteenDayDataForContextType.reverse();
								fourteenDaysGraphStats.series.push(fourteenDayDataForContextType);
								thirtyDaysDataSerieForContextType.reverse();
								thirtyDaysGraphStats.series.push(thirtyDaysDataSerieForContextType);

							}else{
								sevenDaysGraphStats.series.push([]);
								fourteenDaysGraphStats.series.push([]);
								thirtyDaysGraphStats.series.push([]);
							}

						}

						//generate Labels
						const todaysDate = new Date();
						todaysDate.setHours(0,0,0,0);
						const dateCounter = todaysDate;
						for(let i = 0; i < 30; i ++){
							dateCounter.setDate(dateCounter.getDate() - 1);
							const m = moment(dateCounter);
							if(i < 7){
								sevenDaysGraphStats.labels.push(m.format("ddd"));

							}
							if(i < 14){
								if(i%2 == 1 && ((window.device && window.device.platform != "browser") || window.isMobileBrowser)){
									fourteenDaysGraphStats.labels.push(" ");
								}else{
									fourteenDaysGraphStats.labels.push(m.format("MMM DD"));
								}

							}
							if(i < 30){
								if(i%3 != 0 && ((window.device && window.device.platform != "browser") || window.isMobileBrowser)){
									thirtyDaysGraphStats.labels.push(" ");
								}else{
									thirtyDaysGraphStats.labels.push(m.format("MMM DD"));
								}

							}

						}
						sevenDaysGraphStats.labels.reverse();
						fourteenDaysGraphStats.labels.reverse();
						thirtyDaysGraphStats.labels.reverse();

					}
					if(!this._statsGraphData[dataType]){
						this._statsGraphData[dataType] = {};
						this._statsTotalCount[dataType] = {}

					} 
					this._statsGraphData[dataType][SEVEN_DAYS_STATS_KEY] = sevenDaysGraphStats;
					this._statsGraphData[dataType][FOURTEEN_DAYS_STATS_KEY] = fourteenDaysGraphStats;
					this._statsGraphData[dataType][THIRTY_DAYS_STATS_KEY] = thirtyDaysGraphStats;
					this._statsTotalCount[dataType][SEVEN_DAYS_STATS_KEY] = totalCountSevenDays;
					this._statsTotalCount[dataType][FOURTEEN_DAYS_STATS_KEY] = totalCountFourteenDays;
					this._statsTotalCount[dataType][THIRTY_DAYS_STATS_KEY] = totalCountThirtyDays;

				}
			}
		},



		buildMonthStatsData : function(){
			if(!this._statsGraphData){
				this._statsGraphData = {};
				this._statsTotalCount = {};
			}
			if(this._songStats){
				for(let i in dataTypes){
					const dataType = dataTypes[i]
					const data = this._songStats[dataType];
					let totalCount12Months = 0;
					const allContextTypeFound = false;
					const twelveMonthsGraphStats = {"labels": [],"series":[]};
					if(data.last12Months){
						//build series for graph
						for(let ii in supportedActionContext){
							const contextType = supportedActionContext[ii]
							const contextTypeData  = data.last12Months[contextType];
							if(contextTypeData){
								const d  = new Date();
								const firstDayOfTheMonth = new Date(d.getFullYear(), d.getMonth(), 1);
								const monthCounter = firstDayOfTheMonth;

								const twelveMonthsDataForContextType = contextTypeData;//contextTypeData.last30Days;
								const twelveMonthsDataSerieForContextType = [];
								for(let i = 0; i < 12; i ++){
									if(i !=0){
										monthCounter.setMonth(monthCounter.getMonth() - 1);
									}
									if( !this._isContextUnsupportedForAction(dataType,contextType)){
										let monthlyDataCount = twelveMonthsDataForContextType[moment(monthCounter).format("YYYY MM")];
										if(!monthlyDataCount){
											monthlyDataCount = 0;
										}
										twelveMonthsDataSerieForContextType.push(monthlyDataCount? monthlyDataCount: 0);
										//if(contextType == ALL_CONTEXT_TYPE){
											totalCount12Months = totalCount12Months + monthlyDataCount;
											//}
										}else{
											twelveMonthsDataSerieForContextType.push(0);
										}
									}
									twelveMonthsDataSerieForContextType.reverse();
									twelveMonthsGraphStats.series.push(twelveMonthsDataSerieForContextType);

								}else{
									twelveMonthsGraphStats.series.push([]);
								}

							}

							//generate Labels
							const d  = new Date();
							const firstDayOfTheMonth = new Date(d.getFullYear(), d.getMonth(), 1);
							const monthCounter = firstDayOfTheMonth;

							for(let i = 0; i < 12; i ++){
								if(i !=0){
									monthCounter.setMonth(monthCounter.getMonth() - 1);
								}
								const m = moment(monthCounter);
								twelveMonthsGraphStats.labels.push(m.format("MMM YY"));
								

							}
							twelveMonthsGraphStats.labels.reverse();

						}
						if(!this._statsGraphData[dataType]){
							this._statsGraphData[dataType] = {};
							this._statsTotalCount[dataType] = {}

						} 
						this._statsGraphData[dataType][TWELVE_MONTHS_STATS_KEY] = twelveMonthsGraphStats;
						this._statsTotalCount[dataType][TWELVE_MONTHS_STATS_KEY] = totalCount12Months;
					}
				}
			},


			buildAllTimeStatsData : function(){
				if(!this._statsGraphData){
					this._statsGraphData = {};
					this._statsTotalCount = {};
				}
				if(this._songStats){
					for(let i in dataTypes){
						const dataType = dataTypes[i]
						const data = this._songStats[dataType];
						let totalCountAllTimes = 0;
						const allContextTypeFound = false;
						const allTimesGraphStats = {"labels": [],"series":[]};
						if(data.allTime){

							//build series for graph
							for(let ii in supportedActionContext){
								const contextType = supportedActionContext[ii]
								const contextTypeData  = data.allTime[contextType];
								if(contextTypeData){


									const allTimesDataForContextType = contextTypeData;//contextTypeData.last30Days;
									const allTimesDataSerieForContextType = [];
									const numberOfYearsAddedToGraph = 0;
									const d  = new Date();
									const thisYear = new Date(d.getFullYear(),1, 1);
									const yearCounter = thisYear;
									while(this._earliestYearToDisplay <=  yearCounter.getFullYear()){

										if( !this._isContextUnsupportedForAction(dataType,contextType)){
											let yearDataCount = allTimesDataForContextType[moment(yearCounter).format("YYYY")];
											if(!yearDataCount){
												yearDataCount = 0;
											}
											allTimesDataSerieForContextType.push(yearDataCount? yearDataCount: 0);
											//if(contextType == ALL_CONTEXT_TYPE){
												totalCountAllTimes = totalCountAllTimes + yearDataCount;
												//}
											}else{
												allTimesDataSerieForContextType.push(0);
											}
											yearCounter.setFullYear(yearCounter.getFullYear() - 1);
										}
										allTimesDataSerieForContextType.reverse();
										allTimesGraphStats.series.push(allTimesDataSerieForContextType);

									}else{
										allTimesGraphStats.series.push([]);
									}

								}

								//generate Labels
								let currentYear = new Date(new Date().getFullYear(), 1, 1);

								while(this._earliestYearToDisplay <= currentYear.getFullYear()){
									const m = moment(currentYear);
									allTimesGraphStats.labels.push(m.format("YYYY"));
									currentYear.setFullYear(currentYear.getFullYear() - 1);


								}
								allTimesGraphStats.labels.reverse();

							}
							if(!this._statsGraphData[dataType]){
								this._statsGraphData[dataType] = {};
								this._statsTotalCount[dataType] = {}

							} 
							this._statsGraphData[dataType][ALL_TIMES_STATS_KEY] = allTimesGraphStats;
							this._statsTotalCount[dataType][ALL_TIMES_STATS_KEY] = totalCountAllTimes;
						}
					}
				},




				getAllSupportedTinePeriods : function(){
					const listItemCollection = new ListItemCollection();
					listItemCollection.add(Utils.getInstance().getListItemModel(SEVEN_DAYS_STATS_KEY,  "Last 7 Days"));
					listItemCollection.add(Utils.getInstance().getListItemModel(FOURTEEN_DAYS_STATS_KEY,  "Last 14 Days"));
					listItemCollection.add(Utils.getInstance().getListItemModel(THIRTY_DAYS_STATS_KEY,  "Last 30 Days"));
					listItemCollection.add(Utils.getInstance().getListItemModel(TWELVE_MONTHS_STATS_KEY,  "Last 12 Months"));
					listItemCollection.add(Utils.getInstance().getListItemModel(ALL_TIMES_STATS_KEY,  "Overall"));

					return RSVP.Promise.resolve(listItemCollection.models);

				},




				load : function(){
					$.mobile.loading("show");
					try{
						if(this._trebbleStats){
							this.buildDaysStatsData();
							this.buildMonthStatsData();
							this.buildAllTimeStatsData();
							this._initView();
							$.mobile.loading("hide");
							return RSVP.Promise.resolve();
						}else{
							$.mobile.loading("show");
							return this._getStatisticsLoadFunction(this._songTrebbleId).then((function(songStats){
								$.mobile.loading("show");
								this._songStats = this._prepareData(songStats);
								this.buildDaysStatsData();
								this.buildMonthStatsData();
								this.buildAllTimeStatsData();
								this._initView();
								$.mobile.loading("hide");
							}).bind(this)).catch((function(error){
								$.mobile.loading("hide");
								throw error;
							}).bind(this));
						}
					}catch(error){
						$.mobile.loading("hide");
						return RSVP.Promise.reject(error);
					}
				},

				_destroyCurrentDisplayedStatisticsView : function(){
					if(this._tabSwiperObj){
						this._tabSwiperObj.destroy();
						this.swiperTabContainer$el.remove();
						this._tabSwiperObj = null;
						this.swiperTabContainer$el =  null;
					}
					if(this._statsSwiperObj){
						this._statsSwiperObj.destroy();
						this.swiperStatsContainer$el.remove();
						this._tabSwiperObj = null;
						this.swiperStatsContainer$el =  null;
					}
					for(let index in this._chartElementsArray){
						const chartElement = this._chartElementsArray[index];
						chartElement.detach();
					}
					this._chartElementsArray = [];
				},

				setStatisticsPeriod : function(statisticPeriod){
					this._selectedTimePeriodKey = statisticPeriod;
					if(this._initialized){
						this._destroyCurrentDisplayedStatisticsView();
						if(this._selectedTimePeriodKey ){
							this._swiperStatsContainerId = "trebbleStatisticSwiperContainer" + (new Date()).getTime();
							this._swiperTabContainerId = "trebbleTabSwiperContainer" + (new Date()).getTime();
							this.collectionContainer$el.append("<div id='"+this._swiperTabContainerId+"'><div class='"+ "swiper-wrapper"+"'></div></div><div id='"+this._swiperStatsContainerId+"'><div class='"+ "swiper-wrapper"+"'> </div>"+ this._buildNavigationButtons()+"</div>");
							this.swiperStatsContainer$el = this.$el.find("#"+this._swiperStatsContainerId);
							this.swiperTabContainer$el = this.$el.find("#"+this._swiperTabContainerId);
							this.swiperWrapperStatsContainer$el = this.swiperStatsContainer$el.find(".swiper-wrapper");
							this.swiperWrapperTabContainer$el = this.swiperTabContainer$el.find(".swiper-wrapper");

							for(let dataType in dataTypeToLabel){
								const allGraphData  = this._statsGraphData[dataType];
								const graphData = allGraphData?allGraphData[this._selectedTimePeriodKey] : null;
								if(graphData ){
									this.swiperWrapperTabContainer$el.append($(this._buildHtmlTabForDataType(dataType)));
									const chartUniqueElementId  = "chart_"+dataType+"_id_"+(new Date().getTime());
									this.swiperWrapperStatsContainer$el.append($(this._buildChartWrapperHtml(chartUniqueElementId)));
									this._buildChartHtml("#"+chartUniqueElementId, graphData,dataType, dataTypeToLabel[dataType] );
								}

							}
							const chartSwiperParams  =  {
								speed: window.isMobileBrowser?200: 300,
								longSwipesRatio : window.isMobileBrowser?0.2: 0.4,
								watchSlidesProgress: true,
								preloadImages: false,
								watchSlidesVisibility: true,
								slidesPerView: 1,
								centeredSlides: true,
								paginationHide: true,
								slideToClickedSlide: true,
								lazyLoading: true,
								lazyLoadingInPrevNext: true,
								lazy: {
									loadPrevNext: true
								}
							};
							if(window.device && window.device.platform =="browser" && !window.isMobileBrowser){
								chartSwiperParams.nextButton = '.swiper-button-next';
								chartSwiperParams.prevButton = '.swiper-button-prev';
								chartSwiperParams.navigation = {
									nextEl: '.swiper-button-next',
									prevEl: '.swiper-button-prev',
								};
								chartSwiperParams.keyboardControl = true;
								chartSwiperParams.mousewheelControl = true;
								chartSwiperParams.mousewheel =  {
									enabled: true
								};
								chartSwiperParams.keyboard =  {
									enabled: true,
									onlyInViewport: false
								};

							}
							this._statsSwiperObj = new Swiper("#"+this._swiperStatsContainerId,chartSwiperParams);
							const self = this;
							const tabSwiperParams  =  {
								speed: window.isMobileBrowser?200: 300,
								longSwipesRatio : window.isMobileBrowser?0.2: 0.4,
								watchSlidesProgress: true,
								preloadImages: false,
								watchSlidesVisibility: true,
								slidesPerView: "auto",
								centeredSlides: true,
								paginationHide: true,
								slideToClickedSlide: true,
								controller : {
									control : this._statsSwiperObj
								},
								lazyLoading: true,
								lazyLoadingInPrevNext: true,
								lazy: {
									loadPrevNext: true
								},
								onSlideChangeEnd : this._onTabChanged.bind(this),
								on : {
									"slideChangeTransitionEnd" : this._onTabChanged.bind(this),
								}
							};
							if(window.device && window.device.platform =="browser" && !window.isMobileBrowser){
								//tabSwiperParams.keyboardControl = true;
								//tabSwiperParams.mousewheelControl = true;
							}
							this._tabSwiperObj = new Swiper("#"+this._swiperTabContainerId,tabSwiperParams);
							this._tabSwiperObj._songStatisticsContentView = this;
							this._tabSwiperObj.params.control = this._statsSwiperObj;
							this._statsSwiperObj.params.control = this._tabSwiperObj;
						}
					}
				},

				_onTabChanged : function(swiperObj){
					const actionGraphVisible = dataTypes[swiperObj.activeIndex];
					this.$el.find(".legenItem").css("opacity", 1);
					const unsupportedContexts = this._getUnsupportedContextForAction(actionGraphVisible);
					if(unsupportedContexts){
						for(let i in unsupportedContexts){
							const unsupportedContext = unsupportedContexts[i];
							this.$el.find(".legenItem."+actionContextToChartCssClass[unsupportedContext]).css("opacity", 0.3);
						}
					}

				},

				getDurationStringFromTimeInSeconds : function(timeInSeconds, shortLabel){
					const m = moment.duration(timeInSeconds * 1000);

					const mDays = m.asDays();
					const mHours = m.asHours();
					const mMinutes = m.asMinutes();
					const mSeconds = m.asSeconds();
					if(mDays >= 1){
						if(shortLabel){
							return mDays.toFixed(2) + "d";
						}else{
							return mDays.toFixed(2) + " Days";
						}
					}else{
						if(mHours >= 1){
							if(shortLabel){
								return mHours.toFixed(2) + "h";
							}else{
								return mHours.toFixed(2) + " Hours";
							}

						}else{
							if(mMinutes >= 1){
								if(shortLabel){
									return mMinutes.toFixed(2) + "m";
								}else{
									return mMinutes.toFixed(2) + " Min";
								}

							}else{
								if(shortLabel){
									return mSeconds.toFixed(2) + "s";
								}else{
									return mSeconds.toFixed(2) + " Sec";
								}

							}
						}
					}
				},

				_buildHtmlTabForDataType : function(dataType){
					if(dataTypeWithBarChart.indexOf(dataType) > -1){
						return "<div class='slideItemWrapper swiper-slide' style='width:auto;'><div class='statsTabInfo'><div class='totalCount'>"+ this.getDurationStringFromTimeInSeconds(this._statsTotalCount[dataType][this._selectedTimePeriodKey] ) +" </div><div class='tabLabel'><span class='"+ dataTypeToFontIcon[dataType] +"'></span>"+dataTypeToLabel[dataType] +"</div></div></div>";
					}else{
						if(dataInSecondsToBeConverted.indexOf(dataType) > -1){
							return "<div class='slideItemWrapper swiper-slide' style='width:auto;'><div class='statsTabInfo'><div class='totalCount'>"+this.getDurationStringFromTimeInSeconds(this._statsTotalCount[dataType][this._selectedTimePeriodKey] ) +"</div><div class='tabLabel'><span class='"+ dataTypeToFontIcon[dataType] +"'></span>"+dataTypeToLabel[dataType] +"</div></div></div>";
						}else{
							return "<div class='slideItemWrapper swiper-slide' style='width:auto;'><div class='statsTabInfo'><div class='totalCount'>"+this._statsTotalCount[dataType][this._selectedTimePeriodKey]+"</div><div class='tabLabel'><span class='"+ dataTypeToFontIcon[dataType] +"'></span>"+dataTypeToLabel[dataType] +"</div></div></div>";
						}
					}
				},

				_buildChartWrapperHtml : function(chartId){
					return "<div class='slideItemWrapper swiper-slide' style='width:100%;'><div class='statsWrapper' id='"+chartId +"' ></div></div>";
				},

				_buildChartHtml : function(chartContainerSelector, chartData, dataType, label){
					let chartObj  = null;
					let isBarChar = false;
					let isDataInSecondsToBeConverted = false;
					const scaleMinSpaceInPx = (window.device && window.device.platform == "browser" && !window.isMobileBrowser)? 50: 25;
					const pageTitleHeight = 60;
					const legendHeight = 80;
					const tabHeight =  110;
					if(dataTypeWithBarChart.indexOf(dataType) > -1){
						isBarChar = true;
						const labelInterpolationFncY = (function(value){
							return this.getDurationStringFromTimeInSeconds(value, true);
						}).bind(this);
						isDataInSecondsToBeConverted = true;
						chartObj = new BarChart(chartContainerSelector, chartData, {"height": window.getDocumentClienHeight() - (pageTitleHeight +legendHeight +tabHeight) +"px",fullWidth: true, showArea: true,lineSmooth: Interpolation.none(), axisY: { onlyInteger: true, labelInterpolationFnc: labelInterpolationFncY, scaleMinSpace : scaleMinSpaceInPx}, axisX : {scaleMinSpace : scaleMinSpaceInPx}});
					}else{
						isBarChar = true;
						let labelInterpolationFncY =  null;
						if(dataInSecondsToBeConverted.indexOf(dataType) > -1){
							isDataInSecondsToBeConverted = true;
							labelInterpolationFncY = (function(value){
								return this.getDurationStringFromTimeInSeconds(value, true);
							}).bind(this);
						}
						const axisYConfig =  { onlyInteger: true, scaleMinSpace : scaleMinSpaceInPx };
						if(labelInterpolationFncY){
							axisYConfig.labelInterpolationFnc = labelInterpolationFncY;
						}
						const axisXConfig = { scaleMinSpace : scaleMinSpaceInPx };
						chartObj = new BarChart(chartContainerSelector, chartData, {stackBars: true,"height": window.getDocumentClienHeight() - (pageTitleHeight +legendHeight +tabHeight) +"px",fullWidth: true, showArea: true,lineSmooth: Interpolation.none(), axisY: axisYConfig , axisX : axisXConfig});
					}
					chartObj.on('draw', function(data) {
						if(data.type === 'line' || data.type === 'area') {
							data.element.animate({
								d: {
									begin: 1000 * data.index,
									dur: 1000,
									from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
									to: data.path.clone().stringify(),
									easing: Svg.Easing.easeOutQuint
								}
							});
						}
					});
					const selectorClass = isBarChar? ".ct-bar":'.ct-point';
					const $chart =  $(chartContainerSelector);
					this._chartElementsArray.push(chartObj);
					const $toolTip = $chart.append('<div class="tooltip"></div>').find('.tooltip').hide();
					const f = this.getDurationStringFromTimeInSeconds;


					
					$chart.on('mouseenter', selectorClass, function() {
						let $point = $(this),
						value = $point.attr('ct:value'),
						seriesName = label;
						const chartClassFoundViaRegex =  /ct-series-[a-e]/.exec($point.parent().attr('class'));
						let chartClassname = null;
						let contextLabel = null;
						if(chartClassFoundViaRegex && chartClassFoundViaRegex.length > 0){
							chartClassname  = chartClassFoundViaRegex[0];
							const contextType = chartCssClassToActionContext[chartClassname];
							if(contextType){
								contextLabel = contextTypeToContextLabel[contextType];
							}
						}
						if(!contextLabel){
							contextLabel = "";
						}

						if(!value){
							value = 0;
						}
						if(isDataInSecondsToBeConverted){
							value = f(value);
						}
						// $toolTip.html(seriesName + '<br>' + value).show();
						$toolTip.html(value + " " +seriesName +"<br/>"+ contextLabel ).show();
					});



					$chart.on('mouseleave',selectorClass, function() {
						$toolTip.hide();
					});

					$chart.on('mousemove', function(event) {
						$toolTip.css({
							left: (event.offsetX || event.originalEvent.layerX) - $toolTip.width() / 2 - 10,
							top: (event.offsetY || event.originalEvent.layerY) - $toolTip.height() - 40
						});
					});
				},

				_initView : function(){
					if(!this._initialized){
						this._initialized = true;
						this.setStatisticsPeriod(this._selectedTimePeriodKey);
					}
				},

				_buildNavigationButtons : function(){
					if(((window.device && window.device.platform != "browser") || window.isMobileBrowser)){
						return  "";
					}else{
						return      '<div class="swiper-button-next ion-ios7-arrow-right"></div> <div class="swiper-button-prev ion-ios7-arrow-left"></div>';
					}
				},

				_buildChartLegend : function(){
					let chartLegendString = '<div id="chartLegend">';
					for(let chartClass in chartCssClassToActionContext)
					{
						const contextTyoe = chartCssClassToActionContext[chartClass];
						const contextLabel = contextTypeToContextLabel[contextTyoe];
						chartLegendString = chartLegendString +  "<div class='legenItem "+ chartClass+" ' ><span>"+ contextLabel+ "</span><div class='legendColor "+ contextTyoe+"' ></div></div>";
					}
					chartLegendString = chartLegendString +'</div>';
					return chartLegendString;
				},


				events : {


				},



				render : function() {
					const templateParams = {};
					this.$el.append("<div  id='"+this._collectionContainerId+"'><div id='chartGeneralInfoWrapper'></div></div>");
					this.collectionContainer$el = this.$el.find("#"+this._collectionContainerId);
					this.chartGeneralInfoWrapper$el = this.$el.find("#chartGeneralInfoWrapper");
					if(this._periodSelectorView){
						this.chartGeneralInfoWrapper$el.append(this._periodSelectorView.$el);
						this.chartGeneralInfoWrapper$el.append($(this._buildChartLegend()));
					}
					return this;
				}

			});
SongStatisticsContentView.DEFAULT_TIME_PERIOD_KEY = SEVEN_DAYS_STATS_KEY;
SongStatisticsContentView.DEFAULT_TIME_PERIOD_LABEL = "Last 7 Days";
export default SongStatisticsContentView;