
import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerOperation from "models/audioEditor/sequencerOperation/SequencerOperation";
import ReplacementSequencerNode from "models/audioEditor/sequencerNodes/ReplacementSequencerNode";
import StartWrapSequencerNode from "models/audioEditor/sequencerNodes/StartWrapSequencerNode";
import EndWrapSequencerNode from "models/audioEditor/sequencerNodes/EndWrapSequencerNode";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const MoveWrapNodesOperation =  SequencerOperation.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperation.apply(this, [attributes, options]);
    },

    execute : function(startOrEndWrapSequencerNodeToMove,oldIndexInSequencer,  newIndexInSequencer, newPreviousSequencerNode, newNextSequencerNode){
        const isStartMusicWrapSequencerNode = Utils.getInstance().isStartMusicWrapSequencerNodeInstance(startOrEndWrapSequencerNodeToMove)?startOrEndWrapSequencerNodeToMove : false
        const isEndMusicWrapSequencerNode = Utils.getInstance().isEndMusicWrapSequencerNodeInstance(startOrEndWrapSequencerNodeToMove)?startOrEndWrapSequencerNodeToMove : false;
        if(!isEndMusicWrapSequencerNode && !isStartMusicWrapSequencerNode){
            return null;
        }
        let changeStartNode = null;
        const oldStartWrappedNode = startOrEndWrapSequencerNodeToMove.getStartWrappedNode();
        const oldEndWrappedNode = startOrEndWrapSequencerNodeToMove.getEndWrappedNode();
        let newStartWrappedNode = null;
        let newEndWrappedNode = null;
        if(isStartMusicWrapSequencerNode){
            newStartWrappedNode = newNextSequencerNode;
            newEndWrappedNode = startOrEndWrapSequencerNodeToMove.getEndWrappedNode();
            changeStartNode = true;
        }else{
            newStartWrappedNode = startOrEndWrapSequencerNodeToMove.getStartWrappedNode();
            newEndWrappedNode = newPreviousSequencerNode;
            changeStartNode = false;
        }
        if(!newStartWrappedNode || !newEndWrappedNode){
            //invalid wrap
            return null;
        }
        if(!(oldStartWrappedNode === newStartWrappedNode &&  oldEndWrappedNode === newEndWrappedNode) && startOrEndWrapSequencerNodeToMove.canWrapTo(newStartWrappedNode, newEndWrappedNode)){
            if(changeStartNode){
                newStartWrappedNode = startOrEndWrapSequencerNodeToMove.getSelectableStartNodeWrappedIfApplicable(newStartWrappedNode);
                startOrEndWrapSequencerNodeToMove.changeStartNodeToWrapTo(newStartWrappedNode);
            }else{
                newEndWrappedNode = startOrEndWrapSequencerNodeToMove.getSelectableEndNodeWrappedIfApplicable(newEndWrappedNode);
                startOrEndWrapSequencerNodeToMove.changeEndNodeToWrapTo(newEndWrappedNode);
            }
            this.getSequencer().getHistoryManagement().addSequencerOperationMoveWrapNodesLog(startOrEndWrapSequencerNodeToMove, changeStartNode, oldStartWrappedNode,oldEndWrappedNode,newStartWrappedNode, newEndWrappedNode);
            return;
        }else{
            //invalid wrap
            return null;
        }

    },

    undo: function(sequencerOperationMoveWrapNodesLog){
        const startOrEndWrapSequencerNodeToMove = sequencerOperationMoveWrapNodesLog.getStartOrEndWrapSequencerNodeToMove();
        const changeStartNode = sequencerOperationMoveWrapNodesLog.getChangeStartNode();
        const oldStartWrappedNode = sequencerOperationMoveWrapNodesLog.getOldStartWrappedNode();
        const oldEndWrappedNode = sequencerOperationMoveWrapNodesLog.getOldEndWrappedNode();
        const newStartWrappedNode = sequencerOperationMoveWrapNodesLog.getNewStartWrappedNode();
        const newEndWrappedNode = sequencerOperationMoveWrapNodesLog.getNewEndWrappedNode();
        if(changeStartNode){
            startOrEndWrapSequencerNodeToMove.changeStartNodeToWrapTo(oldStartWrappedNode);
        }else{
            startOrEndWrapSequencerNodeToMove.changeEndNodeToWrapTo(oldEndWrappedNode);
        }
    },

    redo: function(sequencerOperationMoveWrapNodesLog){
        const startOrEndWrapSequencerNodeToMove = sequencerOperationMoveWrapNodesLog.getStartOrEndWrapSequencerNodeToMove();
        const changeStartNode = sequencerOperationMoveWrapNodesLog.getChangeStartNode();
        const oldStartWrappedNode = sequencerOperationMoveWrapNodesLog.getOldStartWrappedNode();
        const oldEndWrappedNode = sequencerOperationMoveWrapNodesLog.getOldEndWrappedNode();
        const newStartWrappedNode = sequencerOperationMoveWrapNodesLog.getNewStartWrappedNode();
        const newEndWrappedNode = sequencerOperationMoveWrapNodesLog.getNewEndWrappedNode();
        if(changeStartNode){
            startOrEndWrapSequencerNodeToMove.changeStartNodeToWrapTo(newStartWrappedNode);
        }else{
            startOrEndWrapSequencerNodeToMove.changeEndNodeToWrapTo(newEndWrappedNode);
        }
    },

    

});

export default MoveWrapNodesOperation; 