import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import PersistentModels from "services/PersistentModels";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlaylistHelper from "models/helper/PlaylistHelper";
import PlayerService from "services/PlayerService";
import Utils from "models/helper/Utils";
import ti18n from "i18n!nls/PlaylistPodcasti18n";
import PlaylistListController from "controllers/common/PlaylistListController";
import RSVP from "rsvp";

const FollowersPersonalRadioController = Chaplin.Controller.extend({


    initialize: function(options) {
        _.extend(this, Backbone.Events);
        if (options) {

        }


    },

    getModel: function() {
        return this.model;
    },

    getView: function() {
        return this.view;
    },

    _getFollowersPersonalRadioLoadFunction : function(){
        $.mobile.loading( 'show', {
            textVisible: false,
            theme: "t",
            textonly: false,
            // html: html
        });
        return (function(){
            return TrebbleClientAPIHelper.getInstance().getFollowersPersonalUserRadios().then(function(personalUserRadioArray){
                $.mobile.loading( 'hide');
                return personalUserRadioArray? personalUserRadioArray : [];		
            }).catch((function(error){
                $.mobile.loading( 'hide');
                window.alertErrorMessage(error);
            }).bind(this));
        }).bind(this);
    },

    

    _onPersonalRadioSelected : function(playlistSummarySelectedModel){
        PersistentModels.getInstance().getRouter().showMyFollowerPersonalRadioDetailPage(playlistSummarySelectedModel, true);
    },

    _onPersonalRadioEditButtonClicked : function(playlistSummarySelectedModel){
        PersistentModels.getInstance().getRouter().showTrebbleInfoEditPage(playlistSummarySelectedModel, true);
    },

    _onPersonalRadioShareButtonClicked : function(playlistSummarySelectedModel){
        PersistentModels.getInstance().getRouter().showTrebbleSharePopup(playlistSummarySelectedModel, true);
    },

    _onPlaylistPlayButtonTapedHandler : function(playlistSummarySelectedModel){
        if(PlayerService.getInstance().isPlaylistCurrentlyLoaded(playlistSummarySelectedModel)){
            if(PlayerService.getInstance().isMusicPlaying()){
                PlayerService.getInstance().pauseMusicPlaying();
            }else{
                PlayerService.getInstance().resumeMusicPlaying();
            }
        }else{
            PlayerService.getInstance().playTrebble(playlistSummarySelectedModel);
        }
    },

    _onPlaylistPressedHandler : function(playlistSummarySelectedModel){
        if(playlistSummarySelectedModel){
            const audioPreviewUrl = playlistSummarySelectedModel.getAudioPreviewUrl();
            if(audioPreviewUrl){
                PersistentModels.getInstance().getPlayer().playAudioPreview(audioPreviewUrl);
            }else{
                Utils.getInstance().showShortToastMessage(window.getI18n(ti18n, "NO_PREVIEW_YET_AVAILEBLE_PREVIEW_ARE_AUTOMATICALLY_CREATED"));
            }
        }
    },

    _onPlaylistPressedUpHandler : function(playlistSummarySelectedModel){
        PersistentModels.getInstance().getPlayer().terminateAudioPreviewAndResumePlayback();
    },

    filterList: function(searchCharaterers, filterOptions, sortOptions){
        if(this._followerRadioSummaryController){
            this._followerRadioSummaryController.filterList(searchCharaterers,null, filterOptions, sortOptions);
        }
    },

    clearSearchfilter : function(){
        if(this._followerRadioSummaryController){
            this._followerRadioSummaryController.clearSearchfilter();
        }
    },

    getCustomInfoBoxParams : function(){
        const params = {};
        params.iconClass = "pe-7s-radio";
        params.message = window.getI18n(ti18n,"YOU_ARE_NOT_SUBSCRIBED_TO_ANY_TREBBLE");
        params.helpMessage = "";
        return params;
    },

    getCustomEmptyInfoBoxParams : function(){
        const params = {};
        params.iconClass = "pe-7s-radio";
        params.message = window.getI18n(ti18n,"NO_TREBBLE_FOUND");
        return params;
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                const autoPlaylistJsonArray = null;
                const followerRadioSummaryControllerParams = {};
                const tabHeight = 45;
                followerRadioSummaryControllerParams.loadHandler =  this._getFollowersPersonalRadioLoadFunction();
                followerRadioSummaryControllerParams.model  = null;
                followerRadioSummaryControllerParams.resizePlaceHolderElem =false;
                followerRadioSummaryControllerParams.extendedView = true;
                followerRadioSummaryControllerParams.onPlaylistSeclectedHandler =  this._onPersonalRadioSelected.bind(this);
                followerRadioSummaryControllerParams.onPlaylistPressedHandler =  this._onPlaylistPressedHandler.bind(this);
                followerRadioSummaryControllerParams.onPlaylistPlayButtonTapedHandler = this._onPlaylistPlayButtonTapedHandler.bind(this);
                followerRadioSummaryControllerParams.onPlaylistEditButtonTapedHandler = this._onPersonalRadioEditButtonClicked.bind(this);
                followerRadioSummaryControllerParams.onPlaylistShareButtonTapedHandler = this._onPersonalRadioShareButtonClicked.bind(this);
                followerRadioSummaryControllerParams.heightMargin = tabHeight ;
                followerRadioSummaryControllerParams.customInfoBoxParams = this.getCustomEmptyInfoBoxParams();
                followerRadioSummaryControllerParams.customEmptyInfoBoxInfoParams = this.getCustomEmptyInfoBoxParams();
                followerRadioSummaryControllerParams.onPlaylistPressedUpHandler =  this._onPlaylistPressedUpHandler.bind(this);
                const followerRadioSummaryController = new PlaylistListController(followerRadioSummaryControllerParams);
                this._followerRadioSummaryController = followerRadioSummaryController;



                const promiseArray = [];
                promiseArray.push(this._followerRadioSummaryController.buildModelAndRenderView());

                RSVP.Promise.all(promiseArray).then((function(){
                    this.view =  this._followerRadioSummaryController.getView();
                    this.model =  this._followerRadioSummaryController.getModel();


                    resolve();
                }).bind(this)).catch(reject);

            } catch (error) {
                reject(error);
            }

        }).bind(this));

}

});


export default FollowersPersonalRadioController;
