import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import BasicSequencerNodeInspectorView from "views/audioEditor/sequencerNodeInspectorSection/BasicSequencerNodeInspectorView";
import InfoBoxView from "views/common/InfoBoxView";
import AudioBufferHelper from "models/audioEditor/AudioBufferHelper";
import ti18n from 'i18n!nls/Sequenceri18n';
import RSVP from 'rsvp';


const MultipleElementSelectedNodeInspectorView = BasicSequencerNodeInspectorView.extend({


    constructor : function(attributes, options) {
        BasicSequencerNodeInspectorView.apply(this, [attributes, options]);
        this._numberOfElementsSelected =  attributes.numberOfElementsSelected;

    },

    listenToChangeEventsOnModel :function(){

    },

    getCustomCssClass : function(){
        return "multiple_elements_selected_inpector_container";
    },

    renderAllParamViews : function(){
        const paramViewArray  = [];
        const infoBoxView =  new InfoBoxView({
            "iconClass" :"soundwave-multi-select",
            "message" :this._numberOfElementsSelected + " "+ window.getI18n(ti18n, "ELEMENTS_SELECTED"),
        });

        infoBoxView.render();
        paramViewArray.push(infoBoxView);

        return paramViewArray;
    }


});
export default MultipleElementSelectedNodeInspectorView;