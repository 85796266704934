
import Backbone from "backbone";

const Capsule =  Backbone.Model.extend({
    idAttribute: "capsuleId",

    getCapsuleId : function(){
        return this.get("capsuleId");
    },

    getTitle : function(){
        return this.get("title");
    },

    setTitle : function(newTitle){
        return this.set("title", newTitle);
    },

    getText : function(){
        return this.get("text");
    },

    setText : function(newText){
        return this.set("text", newText);
    },

    getCreationDate : function(){
        return new Date(this.get("creationDate"));
    },

    isActive : function(){
        return this.get("active");
    },

    getDurationInSeconds : function(){
        return this.get("durationInSeconds");
    },

    getAudioUrl : function(){
        const processedAudioInfo =  this.get("auphonicProcessedAudio");
        const concatenatedAudioInfo  =  this.get("processedAudio");
        if(processedAudioInfo && processedAudioInfo.uri){
            return processedAudioInfo.uri
        }else{
            if(concatenatedAudioInfo && concatenatedAudioInfo.uri){
                return concatenatedAudioInfo.uri
            }else{
                return null;
            }
        }
    },

    getAudioHQUrl : function(dotNotDefaultToMp3IfNotAvailable){
        const processedHQAudio = this.get("processedHQAudio");
        if(processedHQAudio && processedHQAudio.uri){
            return processedHQAudio.uri
        }else{
            if(dotNotDefaultToMp3IfNotAvailable){
                return null;
            }else{
                return this.getAudioUrl();
            }
        }
    },

    getVideoUrl : function(){
        const processedVideo = this.get("processedVideo");
        if(processedVideo && processedVideo.uri){
            return processedVideo.uri
        }else{
            return null;
        }
    },

    isPlayable : function(){
        return ((this.get("doNotTriggerAudioProcessing") || window.trebble.allowProcessedAudioToPlay) && !!this.get("processedAudio"))|| !!this.get("auphonicProcessedAudio") ;
    },

    isBeingProcessed : function(){
        return !this.isPlayable();
    },

    getAiringOnRadiosInfo :function(){
        return this.get("airingOnRadios");
    },

    setAiringOnRadiosInfo :function(airingOnRadios){
        return this.set("airingOnRadios", airingOnRadios);
    },

    getExpirationDateForRadioIds: function(radioIds){
        return this.getDataForRadioIds(radioIds, "expirationDate");
    },

    getAddedDateForRadioIds: function(radioIds){
        return this.getDataForRadioIds(radioIds, "addedDate");
    },

    getExpirationDateForRadioId: function(radioId){
        return this.getDataForRadioIds([radioId], "expirationDate");
    },

    getDataForRadioIds : function(radioIds, dataName){
        const airingOnRadiosInfo =  this.get("airingOnRadios");
        if(airingOnRadiosInfo){
            for(let i =0; i < airingOnRadiosInfo.length; i++){
                const radioInfo = airingOnRadiosInfo[i];
                const radioId = radioInfo.radioUID;
                if(radioIds.indexOf(radioId) !== -1){
                    return radioInfo[dataName]
                }
            }
        }
        return null;
    },

    getLastUpdateDate :function(){
        return this.get("lastUpdateDate");
    },

    getExpirationDate: function(){
        return this.get("expirationDate");
    },

    getCanBeEdited : function(){
        return this.get("canBeEdited");
    },

    canBeEdited : function(){
        return this.get("canBeEdited");
    },

    canAudioBeEdited :function(){
        return this.get("canAudioBeEdited");
    },
    

    getProjectId : function(){
        return this.get("projectId");
    },

    isVideoBeingRendered : function(){
        return this.get("videoRenderingRequestId") && !this.get("videoRennderingRequestCompleted") && !this.get("videoRennderingRequestFailed")
    },

    setExpirationDate: function(newDate){
        return this.set("expirationDate", newDate);
    },

    isConsumed : function(){
        return this.get("isConsumed");
    },

    isPrivate : function(){
        return this.get("private");
    },

    setPrivate : function(isPrivate){
        return this.set("private", !!isPrivate);
    },


    getImageArtUrl : function(){
        return this.get("imageArtUrl");
    },

    getCreatorInfoJson : function(){
        return this.get("creatorInfoJson");
    },

    getCreatorUsername : function(){
        return this.getCreatorInfoJson()? this.getCreatorInfoJson().username : null;
    },

    getSongInfoJson : function(){
        return this.get("songInfoJson");
    },

    getNumberOfPlays : function(){
        let numberOfConsumers = this.get("numberOfFinishedPlays");
        if(!numberOfConsumers){
            const songPlaySummary = this.get("songPlaySummary");
            if(songPlaySummary){
                numberOfConsumers =  songPlaySummary.numberOfFinishedPlays?songPlaySummary.numberOfFinishedPlays: 0; 
            }
        }
        numberOfConsumers = numberOfConsumers?numberOfConsumers : 0;
        return numberOfConsumers;
    },

    getNumberOfSkips : function(){
        let numberOfSkips = this.get("numberOfSkips");
        if(!numberOfSkips){
            const songPlaySummary = this.get("songPlaySummary");
            if(songPlaySummary){
                numberOfSkips =  songPlaySummary.numberOfSkips?songPlaySummary.numberOfSkips: 0; 
            }
        }
        numberOfSkips = numberOfSkips?numberOfSkips : 0;
        return numberOfSkips;
    },
    getNumberOfComments: function(){
        return this.get("numberOfComments");
    },

    getNumberOfCapsuleReplies : function(){
        return this.get("numberOfCapsuleReplies");
    },

    isReply : function(){
        return this.get("isReply");
    },

    getPlaySuccessRate : function(){
        const numberOfPlays  =this.getNumberOfPlays();
        const numberOfSkips = this.getNumberOfSkips();
        const playSuccessRate =  (numberOfPlays && numberOfSkips && (numberOfPlays + numberOfSkips  != 0))? (numberOfPlays / (numberOfPlays + numberOfSkips )) : 0;
        return playSuccessRate;
    },


    
});
export default Capsule;