import _ from 'underscore';
import Backbone from "backbone";
import TrebbleVideo from "models/TrebbleVideo";


const TrebbleVideoFactory =  Backbone.Model.extend({
    idAttribute: "id",

    createTrebbleVideoFromSerializedData : function(serializedData){
        return new TrebbleVideo(serializedData);
    },




});


const trebbleVideoFactoryInstance = new TrebbleVideoFactory();

export default {
    getInstance : function() {
        return trebbleVideoFactoryInstance;
    }
}; 