import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import PlaylistListController from "controllers/common/PlaylistListController";
import DynamicPageView from "views/common/DynamicPageView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utils from "models/helper/Utils";
import PlayerService from "services/PlayerService";
import CollectionRepeatController from "controllers/common/CollectionRepeatController";
import TrebbleCardController from "controllers/playlistPodcast/TrebbleCardController";
import JourneyCardController from "controllers/journey/JourneyCardController";
import PageTemplate from 'text!../../../templates/common/userListPageViewTemplate.html';
import ti18n from "i18n!nls/PlaylistPodcasti18n";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";

const PlaylistListPageController = Chaplin.Controller.extend({


    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if (options) {
            if (options.playlistCollection) {
                this.model = options.playlistCollection;
                this.playlistCollection = options.playlistCollection;
            }
            if (options.pageId) {
                this.pageId = options.pageId;
            }
            if (options.searchKeyword) {
                this.searchKeyword = options.searchKeyword;
            }
            if(options.displayedInPageWithNoTab){
                this._displayedInPageWithNoTab = options.displayedInPageWithNoTab;
            }
            this._pageTitle =  options.pageTitle? options.pageTitle: window.getI18n(ti18n,"TREBBLES");
            
        }

        const controllerParams = {};

        //controllerParams.customImageUrlProperty = "data-original";
        controllerParams.notTransparent = true;
        controllerParams.smallcardsize = true;
        controllerParams.notPlayable = true;

        const collectionRepeatControllerParams = {};
        collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildInfoBoxParamsForMySubscriptions();
        collectionRepeatControllerParams.showEmtptyInfoBox = true;
        collectionRepeatControllerParams.controllerOption = controllerParams;
        collectionRepeatControllerParams.itemControllerConstructor = this.getConstructorChooserForCollection;
        collectionRepeatControllerParams.modelLoadHandler =   this.getPlaylistListLoadHandler().bind(this);
        collectionRepeatControllerParams.context =  (options && options.context)?options.context:null;//Utils.getInstance().buildContextForSongCollectionGlobalSearch(this.searchKeyword);
        collectionRepeatControllerParams.resizePlaceHolderElem = true;
        
        collectionRepeatControllerParams.heightMargin = 52 + (this._displayedInPageWithNoTab? 0: 39); //Page Header + Tab Header
        
        this.playlistListController = new CollectionRepeatController(collectionRepeatControllerParams);
        

    },

    _buildInfoBoxParamsForMySubscriptions : function(){
        const params = {};
        params.iconClass = "pe-7s-radio";
        params.message = window.getI18n(ti18n,"NO_TREBBLE");
        params.helpMessage = null;
        params.actionButtonLabel = null;
        return params;

    },
    

    
    getModel : function(){
        return this.model;
    },
    
    getView : function(){
        return this.view;
    },
    
    navigateToPreviousPage : function(){
        PersistentModels.getInstance().getRouter().navigateToPreviousPage();
    },
    
    

    getPlaylistListLoadHandler : function(){
        const searchKeyword = this.searchKeyword;
        return (function(){
            return TrebbleClientAPIHelper.getInstance().searchForTrebbles(this.searchKeyword && this.searchKeyword.trim(), 100, 0).then((function(trebbleJsonArray){
                const playlistSummaryModelArray = [];
                if(trebbleJsonArray){
                    for(let i = 0; i <trebbleJsonArray.length ; i++){
                        playlistSummaryModelArray.push(Utils.getInstance().buildPlaylistSummaryFromJson(trebbleJsonArray[i]));
                    }
                }
                return playlistSummaryModelArray;
            }).bind(this));
        }).bind(this);
    },

    onPageShow : function(){
        if(this.$el){
            this.$el.find("#collection_repeat_container").trigger("scrollstop");
        }
    },

    getConstructorChooserForCollection : function(options){
        if(options.model && options.model.get("type") == "journey"){
            const journeyModel = Utils.getInstance().buildJourneySummaryFromJson(options.model.toJSON());
            options.model = journeyModel;
            return new JourneyCardController(options);
        }else{
            const playlistModel = Utils.getInstance().buildPlaylistSummaryFromJson(options.model.toJSON());
            options.model = playlistModel;
            return new TrebbleCardController(options);
        }

    },
    
    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve,reject){
            try{
                this.playlistListController.buildModelAndRenderView().then((function(){
                    const playlistListView = this.playlistListController.getView();
                    const pageTemplateOptions=  {};
                    pageTemplateOptions.pageTitle = this._pageTitle;
                    const pageViewParams= {};
                    pageViewParams.pageId = this.pageId;
                    pageViewParams.containerSelector = ".ui-content";
                    pageViewParams.contentView = playlistListView;
                    pageViewParams.pageViewTemplate = PageTemplate;
                    pageViewParams.addContentLeftRightPadding = true;
                    pageViewParams.ignoreDefaultPageUIContentPadding = true;
                    pageViewParams.pageTemplateOptions = pageTemplateOptions;
                    this.view = new DynamicPageView(pageViewParams);
                    this.view.render();
                    this.listenTo(this.view, "pageShowing", this.onPageShow);
                    //this.view.$el.find(".ui-content").css("background-color","#ffffff");
                    const collection_repeat_container$el = this.view.$el.find(".ui-content #collection_repeat_container");
                    Utils.getInstance().initJqueryLazyLoadingPluginOnElemNew(collection_repeat_container$el, "img.lazy", collection_repeat_container$el);
                    this.view.$el.on("click","#header-back-button", this.navigateToPreviousPage.bind(this));
                    this.view.$el.find(".ui-content > div").addClass("left-right-content-padding");
                    resolve(this);
                }).bind(this)).catch(reject);

}catch(error){
reject(error);
}

}).bind(this));


}

});
export default PlaylistListPageController;