	import $ from 'jquery';
    import _ from 'underscore';
    import Backbone from "backbone";
    import LocalStorageHelper from "models/helper/LocalStorageHelper";
    import FeatureRolloutHelper from "models/helper/FeatureRolloutHelper";
    import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
    import RSVP from "rsvp";



    const ZendeskHelperInstance = Backbone.Model.extend({
        initialize : function(options) {
            if("zE" in window){
                window.zE('messenger:on', 'open', this._onZendeskChatShow.bind(this));
                window.zE('messenger:on', 'close', this._onZendeskChatHide.bind(this));
                window.zE('messenger:on', 'unreadMessages', this._onUnreadMessagesChange.bind(this));
                this._cachedUnreadMessagesCount = 0;
            }
            

        },

        _onZendeskChatShow : function(){
            this.trigger("show");
        },

        _onZendeskChatHide : function(){
            this.trigger("hide");
        },

        _onUnreadMessagesChange : function(numberOfUnreadMessages){
            this._cachedUnreadMessagesCount = numberOfUnreadMessages;
            this.trigger("unreadMessagesCountChange",numberOfUnreadMessages)
        },

        isAvailable : function(){
            return "zE" in window;
        },
        
        isUnreadConversationCountAvailable :function(){
            return "zE" in window && this._cachedUnreadMessagesCount;
        },

        getUnreadConversationCount: function(){
            if("zE" in window){
                return RSVP.Promise.resolve(this._cachedUnreadMessagesCount);
            }else{
                return RSVP.Promise.resolve(this._cachedUnreadMessagesCount);
            }
        },

        
        registerIdentifiedUser : function(userId, userEmail, firstName, lastName, profileImageUrl, creationDate, allUserFeatures){
            if("zE" in window){
                return TrebbleClientAPIHelper.getInstance().getZendeskToken().then((function(tokenInfo){
                    if(tokenInfo && tokenInfo.token){
                        window.zE("messenger", "loginUser", function (callback) {
                            callback(tokenInfo.token)
                        })
                    }
                }).bind(this))
            }
        },

        registerForPush : function(){
            
        },

        updateUser : function(userEmail, firstName, lastName, profileImageUrl, creationDate, allUserFeatures){
            /*if("zE" in window){
                return TrebbleClientAPIHelper.getInstance().getZendeskToken().then((function(tokenInfo){
                    if(tokenInfo && tokenInfo.token){
                        zE("messenger", "loginUser", function (callback) {
                            callback(tokenInfo.token)
                        })
                    }
                }).bind(this))
            }*/
        },

        updateUserAttributes :function(userAttributeUpdates){
            /*if("zE" in window){
                return TrebbleClientAPIHelper.getInstance().getZendeskToken().then((function(tokenInfo){
                    if(tokenInfo && tokenInfo.token){
                        zE("messenger", "loginUser", function (callback) {
                            callback(tokenInfo.token)
                        })
                    }
                }).bind(this))
            }*/
        },


        trackEvent : function(eventName, additionalParams){
            
        },

        displayMessenger :function(){
            if("zE" in window){
                window.zE('messenger', 'open');
            }
        },

        displayMessageComposer: function() {
            if("zE" in window){
                window.zE('messenger', 'open');
            }
        },

        displayMessageComposerWithInitialMessage: function(initialMessage) {
            if("zE" in window){
                window.zE('messenger', 'open');
            }
        },

        displayMobileCarousel: function(carouselId) {
            return RSVP.Promise.resolve();
        },


        hideMessenger :function(){
            if("zE" in window){
                window.zE('messenger', 'close');
            }

        },

        logout : function(){
            if("zE" in window){
                window.zE("messenger", "logoutUser")
            }
        }



    });

    const zendeskHelperInstance = new ZendeskHelperInstance();
    window.trebbleZendeskHelper = zendeskHelperInstance;

    export default {
		getInstance : function() {
			return zendeskHelperInstance;
		}
	};