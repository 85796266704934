import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utils from "models/helper/Utils";
import SoundBoardItem from "models/SoundBoardItem";
import KeyboardKeyHandlerHelper from "models/helper/KeyboardKeyHandlerHelper";
import ti18n from "i18n!nls/SendCapsuleToUserIntroPagei18n";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";
import SoundboardView from "views/audioHighlight/SoundboardView";

const SoundboardController =  Chaplin.Controller.extend({


    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = null;
        this._isRecording = false;
        this._timeEllapsedInMillisecToSoundUrl = {};
        this._soundBoardItemArray =  [];
    },

    getDefaultSoundboardItemArray : function(){
        const soundBoardItemArray = [];

    },
    
    getModel : function(){
        return this.model;
    },
    
    getView : function(){
        return this.view;
    },

    startRecording : function(){
        this._isRecording = true;
        this._timeEllapsedInMillisecToSoundUrl = {};
    },

    pauseRecording : function(){
        this._isRecording = false;
    },

    resumeRecording :function(){
        this._isRecording = true;

    },

    finishRecording : function(){
        this._isRecording = false;
    },

    onSoundPlayed : function(soundUrl){
        const time =  null;
        this._timeEllapsedInMillisecToSoundUrl[time] = soundUrl;
    },
    
    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                this.view = new SoundboardView();
                this.view.listenTo("soundPlayed", this.onSoundPlayed)
                this.view.render();
                resolve(this);
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }
    

});
export default SoundboardController;