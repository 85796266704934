import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import OptionPopupView from "views/common/OptionPopupView";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";

const WelcomeNewListenerPopupView = OptionPopupView.extend({


    initialize: function(options) {
        const viewOptions = {};
        const contentView = {};
        const contentViewTemplate = _.template(options.template);
        const ti18n = options.ti18n;
        const templateParams = {};
        templateParams.ti18n =  ti18n;
        templateParams.listeningInstructionsHtml = Utils.getInstance().getHtmlTrebbleListeningInstructionsNew(null, true, false, true, true, true, true);
        contentView.$el = $(contentViewTemplate(templateParams));
        viewOptions.contentView = contentView;
        viewOptions.addContentAfterEnhancement = true;
        viewOptions.contentPosition = "center";
        this._popupUniqueId = "WelcomeNewListenerPopupView";
        viewOptions.id = this._popupUniqueId;
        viewOptions.customClass = "whatsnew";
        OptionPopupView.prototype.initialize.call(this, viewOptions);
        PersistentModels.getInstance().getRouter().registerForViewportHeightChange(this._popupUniqueId,this.onOrientationChangeFunction.bind(this), true);
    },

    onOrientationChangeFunction  : function(newViewPortHeigh, forceResizing) {
       if(this.$el.is(":visible") || forceResizing){
          if (this.el) {
           const adjustedListContainerHeight = (newViewPortHeigh) ;
           this.$el.find(".ui-content").css("height", adjustedListContainerHeight + "px");
       }
   }
},

afterRendering: function() {


},


onPopupClosed: function() {
 OptionPopupView.prototype.onPopupClosed.call(this);
 PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._popupUniqueId, true);
 this.trigger("onPopupClosed");
},


onAfterPageShow: function() {
 this.onOrientationChangeFunction(PersistentModels.getInstance().getRouter().getViewportHeight(), true);

 const showExplorePageBtn$el = this.$el.find("#showExplorePageBtn");
 showExplorePageBtn$el.on("click", (function(){
    this.trigger("showExplorePage");
}).bind(this));
},



});

export default WelcomeNewListenerPopupView;
