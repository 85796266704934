import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";
import popupTemplate from 'text!../../../templates/common/OptionPopup.html';
//import Drop from 'tether-drop';
import Drop from 'dropJS';

const OptionPopupView = Backbone.View.extend({

    template : _.template(popupTemplate),

    "pageName" : "My Library",

    initialize : function(options) {
        this._popupUniqueId = options? options.id: null;
        this._contentView =  options? options.contentView: null;
        this._contentPosition = options.contentPosition?options.contentPosition : "center";
        this._addContentAfterEnhancement = options ? options.addContentAfterEnhancement : false;
        this._customClass = options ? options.customClass : null;
        this._customDropObjClass = options ? options.customDropObjClass : null;
        this._eventsToTriggerAfterPopupIsInactive = {};
        this._showAsMenuIfInBrowser = options? options.showAsMenuIfInBrowser: false;
        this._showCloseButton = options? options.showCloseButton : false;
        this._alwaysShowAsFloatingMenu = options? options.alwaysShowAsFloatingMenu: false;
        this._elementSource$el = options? options.elementSource$el : null;
        this._displayTitle = options? options.displayTitle: false;
        this._titleToDisplay = options? options.titleToDisplay: "";
        this._customPopupPosition = options? options.customPopupPosition: null;
        this._hideHeading = options? options.hideHeading: false;
        this._onBeforeClose = options? options.onBeforeClose: null;
        this.dropObj = null;

    },



    events : {


        "click #closeBtn" : "close",

        
    },
    
    setContentView : function(contentView){
        this._contentView  = contentView;
    },
    
    close : function(){
        if(this._popupClosed){
            return RSVP.Promise.resolve();
        }
        if(this.shouldContextMenuBeDisplayed() && this.dropObj){
            try{
                if(this.dropObj){
                    this._popupClosed = true;
                    this.dropObj.close();
                }
            }catch(error){
                
            }
            return RSVP.Promise.resolve();
        }else{
            const p = new RSVP.Promise((function(resolve, reject){
                try{
                    this.$el.on({
                        popupafterclose: (function() {
                            try{
                                resolve();
                            }catch(error){
                                reject(error);
                            }

                        }).bind(this)});
                }catch(error){
                    reject(error);
                }
            }).bind(this));
            this._popupClosed = true;
            this.$el.popup('close' );
            
            return p;
        }
    },

    
    hideCloseButton : function(){
        this.$el.find("#closeBtn").hide();
    },
    
    showCloseButton : function(){
        this.$el.find("#closeBtn").show();
    },
    
    addEventToTriggerAfterPopupInactive : function(eventName, params){
        this._eventsToTriggerAfterPopupIsInactive[eventName] = params;
    },
    
    _triggerEventsAfterPopupInactive : function(){
        for(let eventName in this._eventsToTriggerAfterPopupIsInactive){
            const params = this._eventsToTriggerAfterPopupIsInactive[eventName];
            this.trigger(eventName, params);
        }
    },

    beforeRendering : function(){
        
    },
    afterRendering : function(){
        
    },
    
    onAfterPageShow : function(){
        
    },
    
    onPopupClosed : function(){
        
    },

    _getDropConfiguration: function(){
        const dropDefaultOptions = {
            target: this._elementSource$el.get(0),
            content: this.el,
            classes: 'drop-theme-arrows',
            position: this._customPopupPosition? this._customPopupPosition: "bottom right",
            openOn: "click",
            remove:  true,
            beforeClose : this._onBeforeClose,
            constrainToWindow: true,
            constrainToScrollParent: true,
            tetherOptions :  {
                optimizations: {
                    moveElement: false
                },
                constraints: [
                {
                    to: 'scrollParent'
                }

                ]
            }

        };
        return dropDefaultOptions;
    },
    
    _createDropObj : function(){
        
            const TrebbleDropContext = Drop.createContext({
                //  classPrefix: "trebble-drop"
            });
            this.dropObj = new TrebbleDropContext(this._getDropConfiguration());
            this.dropObj.on("open", function(){
                try{
                    this.trigger("opened");
                }catch(error){
                    console.error(error);
                }
                this.onAfterPageShow();
            }, this);
            this.dropObj.on("close", (function(){
                if(this.dropObj){
                    this._popupClosed = true;
                    this.dropObj.destroy();
                    this.dropObj = null;
                    try{
                        this.trigger("closed");
                    }catch(error){
                        console.error(error);
                    }
                    this.onPopupClosed();
                    this._triggerEventsAfterPopupInactive();
                    this.undelegateEvents();
                }
            }).bind(this), this);
            if(this._customDropObjClass){
                $(this.dropObj.drop).addClass(this._customDropObjClass);
            }
            this.setElement(this.dropObj.drop);
        return RSVP.Promise.resolve();
        
    },

    canContextMenuBeDisplayed : function(){
        return this._elementSource$el;
    },

    shouldContextMenuBeDisplayed : function(){
        return this.canContextMenuBeDisplayed() && (this._alwaysShowAsFloatingMenu  || ( this._showAsMenuIfInBrowser && window.device && window.device.platform == "browser" && !window.isMobileBrowser));
    },

    _doRender : function(){
        this.$el.html(this.template({"contentPosition": this._contentPosition, "displayTitle": this._displayTitle , "titleToDisplay": this._titleToDisplay}));
        this.setElement(this.$el.find("div").first());
        if(this._popupUniqueId && this.shouldContextMenuBeDisplayed()){
            this.$el.attr('id', this._popupUniqueId);
        }
        const elementId = this.$el.attr('id');
        if(this.shouldContextMenuBeDisplayed()){
            this.$el.addClass("popup-menu-content")
        }
        if(this._hideHeading && this.shouldContextMenuBeDisplayed()){
            this.$el.addClass("hideHeading")
        }
        this.$el.on({
            popupafteropen: (function() {
                this._currenPagePositionValue = $(".ui-page-active").css("position");
                $(".ui-page-active").css("position","fixed");
                try{
                    this.trigger("opened");
                }catch(error){
                    console.error(error);
                }
                this.onAfterPageShow();

            }).bind(this)
        });
        this.$el.on({
            popupbeforeposition: (function() {
                if(!this.shouldContextMenuBeDisplayed()){
                    const maxHeight = $( window ).height() - 0 + "px";
                    const width = $( window ).width() - 0 + "px";
                    this.$el.css( "height", maxHeight );
                    this.$el.css( "width", width );
                    this.trigger("popupbeforeposition", this);
                }
            }).bind(this)
        });
        this.$el.on({
            popupafterclose: (function() {
                try{
                    this.trigger("closed");
                }catch(error){
                    console.error(error);
                }
                $(".ui-page-active").css("position",this._currenPagePositionValue);
                this.onPopupClosed();
                this.remove();
                this._triggerEventsAfterPopupInactive();
                this.undelegateEvents();

            }).bind(this)});
        //$.mobile.pageContainer.prepend(this.$el);
        //$("body").prepend(this.$el);
        const bodyEL = document.getElementsByTagName("BODY")[0]

        if(!(this.shouldContextMenuBeDisplayed())){
            bodyEL.insertBefore(this.$el.get(0), bodyEL.firstChild);	
        }
        this.$el.popup({transition:"fade", dismissible: false});
        if(elementId && !this.shouldContextMenuBeDisplayed())
        {
            const screenPopup$el = $("#"+elementId + "-screen");
            $("#"+elementId + "-screen").remove();
            $.mobile.pageContainer.prepend(screenPopup$el);
            if(this._customClass){
                screenPopup$el.addClass(this._customClass);
            }
        }else{
            if(elementId){
                $("#"+elementId + "-screen").remove();
                $("#"+elementId + "-popup").remove();
            }
        }

        if(!this._addContentAfterEnhancement){
            if(this._contentView){
                this.$el.find(".popupContent").append(this._contentView.$el);
                try{
                    this.trigger("popupContentWasAdded");
                }catch(error){
                    console.error(error);
                }
            }
            this.$el.enhanceWithin();
        }else{
            this.$el.enhanceWithin();
            if(this._contentView){
                this.$el.find(".popupContent").append(this._contentView.$el);
                try{
                    this.trigger("popupContentWasAdded");
                }catch(error){
                    console.error(error);
                }
            }
        }


        this.afterRendering();
        if(this._customClass){
            this.$el.addClass(this._customClass);
        }
        if(this.shouldContextMenuBeDisplayed()){
            //this.$el.popup('open',{"positionTo":this._elementSource$el.get(0)});
            return this._createDropObj().then((function(){
                this.dropObj.open();
                if(!this._showCloseButton){
                    this.hideCloseButton();
                }
                return this;
            }).bind(this));
        }else{
            this.$el.popup('open' );
            return RSVP.Promise.resolve();
            /*return Utils.getInstance().closeCurrentJQMPopup().then((function(){
                this.$el.popup('open' );
                this;
            }).bind(this));*/
            
        }

        return this;
    },
    
    render : function() {
        this.beforeRendering();
        if(!this.shouldContextMenuBeDisplayed() && Utils.getInstance().isJQMPopupOpen()){
            return Utils.getInstance().closeCurrentJQMPopup().then((function(){
                return this._doRender();
            }).bind(this));
        }
        return this._doRender();
        
    }

});

export default OptionPopupView;