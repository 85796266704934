import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import HelpPageView from "views/help/HelpPageView";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import RSVP from "rsvp";
import PersistentModels from "services/PersistentModels";

const HelpPageController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this._onHelpPageCloserHandler = options?options.onHelpPageClosedHandler : null;

    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },
    
    showDefaultPage : function(){
        if(this._onHelpPageCloserHandler){
            this._onHelpPageCloserHandler();
        }else{
            PersistentModels.getInstance().getRouter().require(["services/PersistentModels"]).then((function(PersistentModels){
                PersistentModels.getInstance().getRouter().showDefaultPage(true);
            }).bind(this));
            
        }

    },

    showSidePanel : function(){
        PersistentModels.getInstance().getRouter().require(["services/PersistentModels"]).then((function(PersistentModels){
            PersistentModels.getInstance().getRouter().showHomePage();
        }).bind(this));
        
    },

    navigateToPreviousPage : function(){
        PersistentModels.getInstance().getRouter().require(["services/PersistentModels"]).then((function(PersistentModels){
            PersistentModels.getInstance().getRouter().navigateToPreviousPage();
        }).bind(this));
    },
    
    onHelpPageRemoved : function(){
        LocalStorageHelper.getInstance().setHelpForBloggerDisplayed();
        PersistentModels.getInstance().getRouter().require(["services/PersistentModels"]).then((function(PersistentModels){
            PersistentModels.getInstance().getRouter().getHomeMenuPanelController().setHelpDisplayedAtLeastOnceToUser(true);
        }).bind(this));
        
    },

    downloadExtension : function(){
        
            if( window.chrome||  window.chrome.webstore ||  window.chrome.webstore.install){
                const downloadExtensionUrl = "https://chrome.google.com/webstore/detail/lklapkcgbghemabgngmgfblhoffnbgmd";
                window.chrome.webstore.install(downloadExtensionUrl, function(){}, function(e){throw e;});
            }else{
                return ;	
            }	
    },

    createYourFirstCapsule : function(){
        PersistentModels.getInstance().getRouter().require(["services/PersistentModels"]).then((function(PersistentModels){
            PersistentModels.getInstance().getRouter().require(["models/helper/Utils"]).then((function(Utils){
                PersistentModels.getInstance().getRouter().require(["models/helper/AudioRecorderHelper"]).then((function(AudioRecorderHelper){

                    if(window.device && window.device.platform =="browser"){
                        AudioRecorderHelper.getInstance().initUserMedia().then((function(allowed){
                            if(allowed){
                                return PersistentModels.getInstance().getRouter().showAddAudioHightlightPageNew(null,null,  Utils.getInstance().buildContextForHelpPage() , null,true, true);
                            }else{
                                return PersistentModels.getInstance().getRouter().showAddAudioHightlightPageNew(null,null,  Utils.getInstance().buildContextForHelpPage() , null,false, true);
                            }
                        }).bind(this)).catch((function(e){
                            window.navigator.trebbleNotification.alert(e, null, null, "warning");
                            return PersistentModels.getInstance().getRouter().showAddAudioHightlightPageNew(null,null,  Utils.getInstance().buildContextForHelpPage() , null,false, true);
                        }).bind(this));

                    }else{
                        return PersistentModels.getInstance().getRouter().showAddAudioHightlightPageNew(null,null,  Utils.getInstance().buildContextForHelpPage() , null,true, true);
                    }

                }).bind(this));
            }).bind(this));
        }).bind(this));
    },

showSearchPage : function(){
PersistentModels.getInstance().getRouter().require(["services/PersistentModels"]).then((function(PersistentModels){
PersistentModels.getInstance().getRouter().showGlobalSearchPage(null, true);
}).bind(this));
},

onViewRemoved:function(){
this.stopListening();
},

buildModelAndRenderView : function() {
return new RSVP.Promise((function(resolve, reject) {
try {
    this.view = new HelpPageView();
    this.view.render();

    this.listenTo(this.view, "showDefaultPage", this.showDefaultPage);
    this.listenTo(this.view, "showPreviousPage", this.navigateToPreviousPage);
    this.listenTo(this.view, "removedFromPage", this.onHelpPageRemoved);
    this.listenTo(this.view, "showSidePanel", this.showSidePanel);
    this.listenTo(this.view, "downloadExtension", this.downloadExtension);
    this.listenTo(this.view, "createYourFirstCapsule", this.createYourFirstCapsule);
    this.listenTo(this.view, "addSongs", this.showSearchPage);
    this.listenTo(this.view, "remove", this.onViewRemoved.bind(this));





    resolve();
} catch (error) {
    reject(error);
}

}).bind(this));

}

});

export default HelpPageController;