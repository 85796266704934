import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import RSVP from "rsvp";

const ListItemModel =  Backbone.Model.extend({
     idAttribute: "genreId",

     getId : function(){
         return this.get("itemId");
     },

     getLabel : function(){
         return this.get("itemLabel");
     },

     getAdditionalData : function(){
        return this.get("additionalData");
     },

});
export default ListItemModel;