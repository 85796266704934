import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import ListItemModel from "models/ListItemModel";

const ListItemCollection =  Backbone.Collection.extend({
    model: ListItemModel,


    initialize: function (){
        this._sort_key ="itemLabel";

    },

    comparator: function(item) {
        return item.get(this._sort_key);
    },
      
    sortByField: function(fieldName) {
         this._sort_key = fieldName? fieldName : this._sort_key;
         this.sort();
    },
    
    getContext : function(){
        return this._context;
    },
    
    updateContext : function(newContext){
        this._context = newContext
    },
    

});
export default ListItemCollection;