import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import RSVP from "rsvp";
import { create, ConverterType } from '@alexanderolsen/libsamplerate-js';


const AudioBufferUtils =  Backbone.Model.extend({
    idAttribute: "id",

    constructor: function(attributes, options) {
        Backbone.Model.apply(this, [attributes, options]);

    },


    isAudioBuffer : function(buffer){
        //the guess is duck-typing
        return buffer != null
        && typeof buffer.length === 'number'
        && typeof buffer.sampleRate === 'number' //swims like AudioBuffer
        && typeof buffer.getChannelData === 'function' //quacks like AudioBuffer
        // && buffer.copyToChannel
        // && buffer.copyFromChannel
        && typeof buffer.duration === 'number'
    },

    isNeg : function (number) {
        return number === 0 && (1 / number) === -Infinity;
    },

    nidx : function  (idx, length) {
        return idx == null ? 0 : this.isNeg(idx) ? length : idx <= -length ? 0 : idx < 0 ? (length + (idx % length)) : Math.min(length, idx);
    },

    validateBuffer : function(buffer){
        if (!this.isAudioBuffer(buffer)) throw new Error('Argument should be an AudioBuffer instance.');
    },

    clamp : function(value, min, max) {
        return min < max
        ? (value < min ? min : value > max ? max : value)
        : (value < max ? max : value > min ? min : value)
    },
    /**
    * remove DC offset
    */

    removeStatic : function(buffer, target, start, end) {
        const means = this.mean(buffer, start, end)

        return this.fill(buffer, target, function (value, i, ch) {
            return value - means[ch];
        }, start, end);
    },

    mixDownToMono : function(audioBuffer) {
        if (audioBuffer.numberOfChannels === 1) {
            return audioBuffer.getChannelData(0); // Already mono
        } else {
            const leftChannel = audioBuffer.getChannelData(0);
            const rightChannel = audioBuffer.getChannelData(1);
            const mixedChannel = new Float32Array(leftChannel.length);
            for (let i = 0; i < leftChannel.length; i++) {
                mixedChannel[i] = (leftChannel[i] + rightChannel[i]) / 2;
            }
            return mixedChannel;
        }
    },

    /**
    * Get average level per-channel
    */
    mean : function(buffer, start, end) {
        this.validateBuffer(buffer)

        start = start == null ? 0 : this.nidx(start, buffer.length);
        end = end == null ? buffer.length : this.nidx(end, buffer.length);

        if (end - start < 1) return []

            const result = []

        for (let c = 0; c < buffer.numberOfChannels; c++) {
            let sum = 0
            const data = buffer.getChannelData(c)
            for (let i = start; i < end; i++) {
                sum += data[i]
            }
            result.push(sum / (end - start))
        }

        return result
    },

    getDbFromAmplitude : function(amp){
        return Math.log(amp)*20;
    },

    getAmpFromDb : function(db){
        return Math.exp(db/20)
    },

    normalize : function  (buffer, target, peakDbLevel, start, end, resuseExistingBufferIfClipping) {
        //resolve optional target arg
        if (!this.isAudioBuffer(target)) {
            end = start;
            start = target;
            target = null;
        }
        if(!peakDbLevel){
            peakDbLevel =  0;
        }

        const peakAmplitude = this.getAmpFromDb(peakDbLevel);

        start = start == null ? 0 : this.nidx(start, buffer.length);
        end = end == null ? buffer.length : this.nidx(end, buffer.length);

        //for every channel bring it to max-min amplitude range
        let max = 0

        for (let c = 0; c < buffer.numberOfChannels; c++) {
            const data = buffer.getChannelData(c)
            for (let i = start; i < end; i++) {
                //console.log("data[i] " + Math.abs(data[i]));
                max = Math.max(Math.abs(data[i]), max)
            }
        }

        const amp = Math.max(peakAmplitude / max, 1)

        if(amp == 1 && resuseExistingBufferIfClipping){
            return buffer;
        }else{
            return this.fill(buffer, target, (function (value, i, ch) {
                return this.clamp(value * amp, -1, 1)
            }).bind(this), start, end);
        }
    },

    /*Calcs dB to percent with a non-standard dB range
    it does this by getting a new percentage range using min/max dB
    defaults to the standard range of -80 ~ 0 if no min or max is provided*/
    calcDb : function(perc, minDb, maxDb, minP, maxP){
        const maxPerc = maxP || (_.isNumber(maxDb) && this.getPerc(maxDb)) || 100
        const minPerc = minP || (_.isNumber(minDb) && this.getPerc(minDb)) || 0
        const range = maxPerc - minPerc
        const newPerc = (range / 100) * perc

        return Math.round(1000000000 * 20 * (Math.log(newPerc * 0.01) / Math.log(10))) / 1000000000
    },

    /*Calcs percentage to dB with a non-standard dB range
     leverages calcDb to scale to the new range
     defaults to 0 - 100 min or max percent/dB if they are not provided*/
     calcPerc : function(dB, minDb, maxDb, minP, maxP){
        const maxPerc = maxP || (_.isNumber(maxDb) && this.getPerc(maxDb)) || 100
        const minPerc = minP || (_.isNumber(minDb) && this.getPerc(minDb)) || 0
        const range = maxPerc - minPerc
        const newDb = this.calcDb((this.getPerc(dB) / range) * 100, null, null, minPerc, maxPerc)

        return Math.round(1000000000 * Math.pow(10, newDb / 20)) / 10000000
     },

     getPerc: function(db){
        return Math.round(1000000000 * Math.pow(10, db / 20)) / 10000000;
     },

     resampleAudioBuffer : async function(monoFloat32Array, inputSampleRate, targetSampleRate){
        const src = await create(1, inputSampleRate, targetSampleRate, {
            converterType: ConverterType.SRC_SINC_BEST_QUALITY, // default SRC_SINC_FASTEST. see API for more
        })
        let resampledData = src.simple(monoFloat32Array);
        src.destroy(); // clean up
        return resampledData;
     },

     fill: function (buffer, target, value, start, end) {
        this.validateBuffer(buffer);

        //if target buffer is passed
        if (!this.isAudioBuffer(target) && target != null) {
            //target is bad argument
            if (typeof value == 'function') {
                target = null;
            }
            else {
                end = start;
                start = value;
                value = target;
                target = null;
            }
        }

        if (target) {
            this.validateBuffer(target);
        }
        else {
            target = buffer;
        }

        //resolve optional start/end args
        start = start == null ? 0 : this.nidx(start, buffer.length);
        end = end == null ? buffer.length : this.nidx(end, buffer.length);
        //resolve type of value
        if (!(value instanceof Function)) {
            for (let channel = 0, c = buffer.numberOfChannels; channel < c; channel++) {
                let targetData = target.getChannelData(channel);
                for (let i = start; i < end; i++) {
                    targetData[i] = value
                }
            }
        }
        else {
            for (let channel = 0, c = buffer.numberOfChannels; channel < c; channel++) {
                let data = buffer.getChannelData(channel),
                targetData = target.getChannelData(channel);
                for (let i = start; i < end; i++) {
                    targetData[i] = value.call(buffer, data[i], i, channel, data);
                }
            }
        }

        return target;
    },
});


const audioBufferUtilsInstance = new AudioBufferUtils();

export default {
	getInstance : function() {
		return audioBufferUtilsInstance;
	}
}; 