	import $ from 'jquery';
    import _ from 'underscore';
    import Backbone from "backbone";
    import SongsCollection from "collections/myLibrary/SongsTabContentCollection";
    import ArtistCollection from "collections/myLibrary/ArtistsTabContentCollection";
    import AlbumCollection from "collections/myLibrary/AlbumsTabContentCollection";
    import Song from "models/Song";
    import Album from "models/Album";
    import TagGroup from "collections/TagGroup";
    import TrebbleIndexDBHelper from "models/helper/TrebbleIndexDBHelper";
    import TrebbleClientAPI from "models/helper/TrebbleClientAPI";
    import FacebookEventTrackingHelper from "models/helper/FacebookEventTrackingHelper";
    import PurchaseManager from "models/helper/PurchaseManager";
    import ti18n from "i18n!nls/servicei18n";
    import Utils from "models/helper/Utils";
    import PlaylistCollection from "collections/PlaylistCollection";
    import RSVP from "rsvp";

    var UNRATED_PLAYLIST_ID = "ratingId:Unrated";
    var UNRATED_PLAYLIST_NAME = "Unrated";
    var  GENERAL_RATING_TAG_GROUP_ID = "generalRatingTagGroup";
    var SHOW_NOTIFICATION_ON_SONG_ADDED_OR_REMOVED_FROM_TREBBLE = false;
    var SHOW_NOTIFICATION_ON_CAPSULE_ADDED_OR_REMOVED_FROM_TREBBLE = false;
    var SHOW_SUGGESTION_TO_CREATE_CAPSULE_AFTER_A_SONG_IS_ADDED_TO_RADIO =  false;
    var PersitentModelsModel = Backbone.Model.extend({
        "default" : {
            "playerModel" : null,
            "songCollection" : null,
            "albumCollection" : null,
            "artistCollection" : null,
            "autoPlaylists" : null,
            "allDefaultTags": null,
            "router" : null,
        },

        initialize : function() {
            this.listenTo(TrebbleIndexDBHelper.getInstance(),"onSongSaved", this._onSongSavedInDB);
            this._genreNameToSongFlatMap = {};
            this._genreIdToGenreInfo = {};
            this._levelToGenreDetailsMap = {};
            this._addUnratedTagInRadioPlaylist = true;
            this._unratedSongIdToSongMap = {};
            this._ratedTagsId = [];
            this._tagIdToSongMap = {};
            this._radioIdToRadioInfo =  {};
            this._radioIdToJourneyInfo =  {};
            this._followerJsonArray =  null;
            this._followingJsonArray =  null;
            this._capsuleIdToCapsuleInfo = {};
            this._songUriToCapsuleUIDs =  {};
            this._songUriWithConsumedComments = [];
            this._capsuleIdWithConsumedComments = [];
            this._songUriWithNotifiedComments = [];
            this._capsuleIdWithNotifiedComments = [];
            this._myPersonalTrebbleCollection = null;
            this._myFollowedTrebbleCollection = null;
            this._myFollowedRadioToRadioInfoMap = [];
            this._myFollowedJourneyToJourneyRadioInfoMap = [];
            this._numberOfSubscriptions =  0;
            this._numberOfJourneySubscriptions =  0;
            this._numberOfSubscribesInCurrentSession = 0;
            this._numberOfUnsubscribesInCurrentSession = 0;
            this.songCollection = new SongsCollection();
            //this._ratedTagIdToSongJsonArray = {};
            //this._unratedSongJsonArray = [];
            //this._ratedTagIdToFixedPlaylist = {};
            this._genreIdToSongJsonArray = {};
            this._userSongLibraryInitialized = false;
            //this.listenTo(PurchaseManager.getInstance(), PurchaseManager.getInstance().eventTypes.JOURNEY_PURCHASE_INITIATED, this.onJourneyInfoUpdated);
            this.listenTo(PurchaseManager.getInstance(), PurchaseManager.getInstance().eventTypes.JOURNEY_PURCHASE_COMPLETED, this.onJourneyInfoUpdated);
            this.listenTo(PurchaseManager.getInstance(), PurchaseManager.getInstance().eventTypes.JOURNEY_PURCHASE_INTERUPTED, this.onJourneyInfoUpdated);
            this.listenTo(PurchaseManager.getInstance(), PurchaseManager.getInstance().eventTypes.JOURNEY_PURCHASE_ERROR, this.onJourneyInfoUpdated);
            this.listenTo(PurchaseManager.getInstance(), PurchaseManager.getInstance().eventTypes.PURCHASE_OF_JOURNEY_COMPLETED_AND_PAID, this.onJourneyInfoUpdated);
            this.listenTo(PurchaseManager.getInstance(), PurchaseManager.getInstance().eventTypes.PURCHASE_OF_JOURNEY_FULLFILLED, this.onJourneyInfoUpdated);
            this.listenTo(PurchaseManager.getInstance(), PurchaseManager.getInstance().eventTypes.PURCHASE_OF_JOURNEY_PAYMENT_FAILED, this.onJourneyInfoUpdated);
            //this._genreIdToFixedPlaylist = {};
        },


        setMyPersonalTrebbleCollection : function(collection){
            this._myPersonalTrebbleCollection = collection;
        },

        setMyFollowedTrebbleCollection : function(collection){
            this._myFollowedTrebbleCollection = collection;
        },

        setUserSongLibraryInitialized : function(userSongLibraryInitialized){
            this._userSongLibraryInitialized  = userSongLibraryInitialized;
            if(this._userSongLibraryInitialized){
                this.trigger("userSongLibraryInitialized");
            }
        },

        _onSongSavedInDB : function(songSavedJson, albumUpdatedOrCreatedJson, artistUpdatedOrCreatedJson, silent){
            this._addSongJsonToMyLibraryOrFavoriteCollection(songSavedJson, albumUpdatedOrCreatedJson, artistUpdatedOrCreatedJson, silent);
        },

        /* : function(jsonToMerge, newValues){
            for(let property in newValues){
                jsonToMerge[property] = newValues[property];
            }
        },*/

        setMyFollowersInfo : function(myFollowerJsonArray){
            this._followerJsonArray = myFollowerJsonArray;
        },

        getMyFollowersInfo : function(){
            return this._followerJsonArray;
        },

        addFollowerInfoJson:function(followerInfoJson){
            this._followerJsonArray.push(followerInfoJson);
        },

        setMyFollowingsInfo : function(myFollowingsJsonArray){
            this._followingJsonArray = myFollowingsJsonArray;
        },

        getMyFollowingsInfo : function(){
            return this._followingJsonArray;
        },

        addFollowingInfoJson:function(followingInfoJson){
            this._followingJsonArray.push(followingInfoJson);
        },


        setSongCollection : function(songJsonArray){
            this.songCollection = this.songCollection.add(songJsonArray);
            this.songCollection.updateContext(Utils.getInstance().buildContextForSongCollectionMyLibrary());
            this.songCollection.each((function(songModel){
                var songJson = songModel.toJSON();
                this._addSongToTagMap(songJson);
                Utils.getInstance().addSongToGenreArray(songJson, this.getGenreNameToSongFlatMap(), this.getLevelToGenreDetailsMap(), this.getGenreIdToGenreInfo());
                //this._addSongToGenreMap(songJson);
            }).bind(this));

        },

        setAllMyPersonalRadioInfos : function(allMyRadioInfos){
            this._radioIdToRadioInfo = {};
            for(let index in allMyRadioInfos){
                var radioInfo = allMyRadioInfos[index];
                var radioId = radioInfo._id;
                this._radioIdToRadioInfo[radioId] = radioInfo;
            }
        },

        getAllMyPersonalRadioIdToRadioInfo : function(allMyRadioInfos){
            return this._radioIdToRadioInfo;
        },

        getAllMyPersonalRadioIds : function(){
            var radioIds = [];
            for(let radioId in this._radioIdToRadioInfo){
                radioIds.push(radioId);
            }
            return radioIds;

        },

        getAllMyPersonalRadioTimezones : function(){
            var timezones = [];
            for(let radioId in this._radioIdToRadioInfo){
                var radioInfo = this._radioIdToRadioInfo[radioId];
                if(radioInfo.timezone){
                    timezones.push(radioInfo.timezone);
                }
            }
            return timezones;
        },

        getAllMyPersonalRadioLanguages : function(){
            var languages = [];
            for(let radioId in this._radioIdToRadioInfo){
                var radioInfo = this._radioIdToRadioInfo[radioId];
                if(radioInfo.language){
                    languages.push(radioInfo.language);
                }
            }
            return languages;
        },

        getAllMyPersonalRadioCategories : function(){
            var categories = [];
            for(let radioId in this._radioIdToRadioInfo){
                var radioInfo = this._radioIdToRadioInfo[radioId];
                if(radioInfo.categoryId){
                    categories.push(radioInfo.categoryId);
                }
            }
            return categories;
        },

        hasAtLeastOneMusicCategory : function(){
            for(let radioId in this._radioIdToRadioInfo){
                var radioInfo = this._radioIdToRadioInfo[radioId];
                if(!radioInfo.categoryId || radioInfo.categoryId === Utils.getInstance().getTrebbleMusicCategoryId()){
                    return true;
                }
            }
            return false;
        },

        setAllMyCapsuleInfos : function(allMyCapsuleInfos){
            this._capsuleIdToCapsuleInfo = {};
            if(allMyCapsuleInfos){
                var capsuleInfos = allMyCapsuleInfos.capsuleInfos;
                this._songUriToCapsuleUIDs = allMyCapsuleInfos.songUriToCapsuleUIDs? allMyCapsuleInfos.songUriToCapsuleUIDs: {};
                for(let index in capsuleInfos){
                    var capsuleInfo = capsuleInfos[index];
                    var capsuleId = capsuleInfo._id;
                    this._capsuleIdToCapsuleInfo[capsuleInfo] = capsuleInfo;
                }
            }
        },

        createJourney : function(name, goal,description, categoryId, cityId, language, timezone, isPaid , costInUSD, coverArtUrl,coverArtBase64, context ){
            return  TrebbleClientAPI.getInstance().createAJourney(name, goal,description, categoryId, cityId, language, timezone,isPaid , costInUSD, coverArtUrl,coverArtBase64, context).then((function(journeyRadioJson){
                this.trigger("newJourneyCreated");
                return Utils.getInstance().buildJourneySummaryFromJson(journeyRadioJson);
            }).bind(this))
        },

        onJourneyInfoUpdated: function(journeyRadioId){
            return TrebbleClientAPI.getInstance().getJourneyInfoByJourneyRadioId(journeyRadioId).then((function(journeyRadioJsonInfo){
                if(journeyRadioJsonInfo){
                    var journeySummaryModel = Utils.getInstance().buildJourneySummaryFromJson(journeyRadioJsonInfo);
                    this.trigger("journeyInfoUpdated",journeySummaryModel)
                    this.trigger("journeyInfoUpdatedWithId"+journeyRadioId,journeySummaryModel);
                }
            }).bind(this))
        },

        refreshJourneyInfoWithId : function(journeyRadioId){
            return this.onJourneyInfoUpdated(journeyRadioId);
        },

        onJourneyDeleted: function(journeyRadioId){
            this.trigger("journeyDeletedWithId"+journeyRadioId,null);
        },


        setMyFollowedRadioInfoArray : function(followedRadioInfoArray){
            this._myFollowedRadioToRadioInfoMap = {};
            var numberOfSubscriptions = 0;
            var numberOfJourneySubscriptions = 0;
            for(let i in followedRadioInfoArray){
                var radioInfo = followedRadioInfoArray[i];
                this._myFollowedRadioToRadioInfoMap[radioInfo.loaderKey] = radioInfo;
                numberOfSubscriptions = numberOfSubscriptions + 1;
                if(Utils.getInstance().isJourneyRadio(radioInfo)){
                    numberOfJourneySubscriptions = numberOfJourneySubscriptions + 1;
                }
            }
            this._numberOfSubscriptions = numberOfSubscriptions;
            this._numberOfJourneySubscriptions = numberOfJourneySubscriptions;
            this.trigger("subscriptionChanged",null, false);
        },

        /*getMyFollowedRadioInfoArray :function(){
            var followedRadioInfoArray = [];
            if(this._myFollowedRadioToRadioInfoMap){
                for(let key in this._myFollowedRadioToRadioInfoMap){
                    followedRadioInfoArray.push(this._myFollowedRadioToRadioInfoMap[key])
                }
            }
            return followedRadioInfoArray;
        },*/

        getNumberOfSubscriptions :function(){
            return this._numberOfSubscriptions;
        },

        getNumberOfJourneySubscriptions : function(){
            return this._numberOfJourneySubscriptions;
        },

        onTrebbleFollowed : function(trebbleFollowed){
            if(this._myFollowedTrebbleCollection){
                this._myFollowedTrebbleCollection.add(trebbleFollowed,  {"merge": true});
            }
        },

        onTrebbleUnfollowed : function(trebbleUnfollowed){
            if(this._myFollowedTrebbleCollection){
                this._myFollowedTrebbleCollection.remove(trebbleUnfollowed);
            }
        },

        onPersonalTrebbleCreatedOrEdited: function(trebbleUpdated){
            if(this._myPersonalTrebbleCollection){
                this._myPersonalTrebbleCollection.add(trebbleUpdated,  {"merge": true});
            }
            var trebbleId = trebbleUpdated.id;
            var existingRadioInfoJson  = this._radioIdToRadioInfo[trebbleId];
            if(!existingRadioInfoJson){
                this._radioIdToRadioInfo[trebbleId] = trebbleUpdated;
            }else{
                for(let propertyInExistingRadioInfo in existingRadioInfoJson){
                    if(Object.prototype.hasOwnProperty.call(trebbleUpdated,propertyInExistingRadioInfo)){
                        existingRadioInfoJson[propertyInExistingRadioInfo] = trebbleUpdated[propertyInExistingRadioInfo];
                    }
                }
            }
        },

        onJourneyCreatedOrEdited: function(journeyUpdated){
            if(this._myJourneysCollection){
                this._myJourneysCollection.add(journeyUpdated,  {"merge": true});
            }
            var journeyId = journeyUpdated.id;
            var existingJourneyInfoJson  = this._radioIdToJourneyInfo[journeyId];
            if(!existingJourneyInfoJson){
                this._radioIdToJourneyInfo[journeyId] = journeyUpdated;
            }else{
                for(let propertyInExistingJourneyInfo in existingJourneyInfoJson){
                    if(Object.prototype.hasOwnProperty.call(journeyUpdated,propertyInExistingJourneyInfo)){
                        existingJourneyInfoJson[propertyInExistingJourneyInfo] = journeyUpdated[propertyInExistingJourneyInfo];
                    }
                }
            }
        },

        onShortcastInfoUpdated : function(shortcastId, shortcastInfo){	
            this.trigger("shortcastInfoUpdated"+shortcastId,shortcastInfo);
        },

        createAJourneyDraft : function(journeyRadioId){
            return TrebbleClientAPI.getInstance().createAJourneyDraft(journeyRadioId).then((function(operationResult){
                this.onJourneyInfoUpdated(journeyRadioId);
                return operationResult;
            }).bind(this));
        },

        onCapsuleAddedToRadio : function(capsuleAddedToRadioResultInfo, capsuleModelAddedToRadio, radioIds, lifeSpanInDays){
            this.onSongCapsuleCreatedForSong({"capsuleUID": capsuleModelAddedToRadio.getCapsuleId(),"capsuleInfo": capsuleModelAddedToRadio.toJSON()});
        },

        onCapsuleRemovedFromRadio : function(capsuleRemovedFromRadioResultInfo, capsuleModelRemovedFromRadio, radioIds){
            this.onCapsuleDeleted({"capsuleUID": capsuleModelRemovedFromRadio.getCapsuleId()});
        },

        onSongsAddedOnRadio: function(radioSongsAddedInfo){
            if(radioSongsAddedInfo){
                var radioIdToNumberOfSongAdded = {};
                for(let index in radioSongsAddedInfo){
                    var radioAddedInfo = radioSongsAddedInfo[index];
                    var radioId = radioAddedInfo.radioUID;
                    var radioInfo = this._radioIdToRadioInfo[radioId];
                    if(radioInfo){
                        if(radioInfo.songsInfo && radioAddedInfo.songsInfoAdded){
                            radioInfo.songsInfo =  radioInfo.songsInfo.concat(radioAddedInfo.songsInfoAdded);
                        }
                        if(radioIdToNumberOfSongAdded[radioId]){
                            radioIdToNumberOfSongAdded[radioId] = radioIdToNumberOfSongAdded[radioId] + 1;
                        }else{
                            radioIdToNumberOfSongAdded[radioId] = 1;
                        }
                    }
                    //{songUriToSongUID} WILL BE USED FOR A WORKAROUND TO PATCH THE FACT THAT WHEN A SONG IS ADDED TO THE USER LIBRARY THERE IS TREBBLEID SET ON THE SONG
                    var songUriToSongUID = {};
                    for(let ii in radioAddedInfo.songsInfoAdded){
                        var sInfo = radioAddedInfo.songsInfoAdded[ii];
                        songUriToSongUID[sInfo["uri"]] = sInfo["_id"];
                    }
                    for(let index in radioAddedInfo.songsURIAdded){
                        var songUriAdded = radioAddedInfo.songsURIAdded[index];
                        var songModel =  this.songCollection.get(songUriAdded);

                        if(songModel){
                            //THIS IS A WORKAROUND TO PATCH THE FACT THAT WHEN A SONG IS ADDED TO THE USER LIBRARY THERE IS TREBBLEID SET ON THE SONG
                            if(!songModel.getTrebbleSongId()){
                                songModel.set({"trebbleSongId":songUriToSongUID[songModel.get("uri")]}, {"silent": true});
                            }
                            if(!songModel.get("radioUIDs")){
                                songModel.set("radioUIDs",[]);
                            }
                            if(songModel.get("radioUIDs").indexOf(radioId) == -1){
                                songModel.get("radioUIDs").push(radioId);
                            }
                        }

                    }

                }
                this.trigger("songsAddedToRadios",radioIdToNumberOfSongAdded);
                if(window.trebbleAnalyticsHelper){
                    window.trebbleAnalyticsHelper.trackEvent("Song", "addedToTrebble", "Song Added To Trebble And Graded Using Web");
                    if(window.trebbleAnalyticsHelper.trebbleHelperTrackSongAddedToTrebble){
                        window.trebbleAnalyticsHelper.trebbleHelperTrackSongAddedToTrebble();
                    }
                } 
            }

        },

        _showSuggestionPopupToCreateACapsuleIfApplicable : function(){
            for(let radioId in this._radioIdToRadioInfo){
                var radioInfo = this._radioIdToRadioInfo[radioId];
                if(radioInfo.songsInfo && radioInfo.songsInfo.length == Utils.getInstance().getDefaultNumberOfSongsRequiredToPlayATrebble())
                {
                    this.getRouter().showSuggestionPopupToCreateACapsuleIfApplicable();
                }
            }
        },


        addCapsuleToJourney : function(capsuleId, journeyRadioId){
            return TrebbleClientAPI.getInstance().addCapsulesToDraftJourney(capsuleId, journeyRadioId).then((function(operationResult){
                if(operationResult && operationResult.capsuleWasAdded){
                    this.trigger("capsuleAddedToJourneyWithId_"+journeyRadioId);
                }
            }).bind(this)).catch((function(error){
                this.trigger("capsuleFailedBeingAddedToJourneyWithId_"+journeyRadioId);
                throw error;
            }).bind(this));
        },

        createACapsuleAndAddToJourney : function(journeyRadioId){
            var operationResult = null;
            return new RSVP.Promise((function(resolve, reject){
                this.getRouter().showCreateACapsulePageWithoutContext(null,resolve,  resolve, true, true, window.getI18n(ti18n, "ADD_SHORTCAST"), true);
            }).bind(this)).then((function(result){
                operationResult = result;
                if(operationResult && operationResult.capsuleUID){
                    this.trigger("capsuleBeingAddedToJourneyWithId_"+journeyRadioId,operationResult.capsuleUID);
                    return TrebbleClientAPI.getInstance().addCapsulesToDraftJourney(operationResult.capsuleUID, journeyRadioId);
                }
            }).bind(this)).then((function(result2){
                if(operationResult && operationResult.capsuleUID){
                    this.trigger("capsuleAddedToJourneyWithId_"+journeyRadioId);
                }
            }).bind(this)).catch((function(error){
                this.trigger("capsuleFailedBeingAddedToJourneyWithId_"+journeyRadioId);
                throw error;
            }).bind(this));
        },

        createACapsuleAndSetItAsTrailerForJourney : function(journeyRadioId){
            var operationResult = null;
            return new RSVP.Promise((function(resolve, reject){
                this.getRouter().showCreateACapsulePageWithoutContext(null,resolve,  resolve, true, true, window.getI18n(ti18n, "CREATE_TRAILER"), true);
            }).bind(this)).then((function(result){
                operationResult = result;
                if(operationResult && operationResult.capsuleUID){
                    this.trigger("capsuleBeingSetAsTrailerForJourneyWithId_"+journeyRadioId,operationResult.capsuleUID);
                    return TrebbleClientAPI.getInstance().addCapsuleAsTrailerToDraftJourney(operationResult.capsuleUID, journeyRadioId);
                }
            }).bind(this)).then((function(result2){
                if(operationResult && operationResult.capsuleUID){
                    this.trigger("capsuleSetAsTrailerForJourneyWithId_"+journeyRadioId);
                }
            }).bind(this)).catch((function(error){
                this.trigger("capsuleFailedBeingSetAsTrailerForJourneyWithId_"+journeyRadioId);
                throw error;
            }).bind(this))
        },

        publishJourney : function(journeyRadioId, availabilityDate, timezone, context){
            return TrebbleClientAPI.getInstance().publishJourney(journeyRadioId, availabilityDate, timezone, context).then((function(operationResult){
                this.onJourneyInfoUpdated(journeyRadioId);
                return operationResult;
            }).bind(this));
        },

        changeJourneyPublishedDate: function(journeyRadioId, availabilityDate, timezone, context){
            return TrebbleClientAPI.getInstance().changeJourneyPublishedDate(journeyRadioId, availabilityDate, timezone, context).then((function(operationResult){
                this.onJourneyInfoUpdated(journeyRadioId);
                return operationResult;
            }).bind(this));
        },

        removeCapsuleFromJourney : function(capsuleId, journeyRadioId){
            return TrebbleClientAPI.getInstance().removeCapsulesToDraftJourney(capsuleId, journeyRadioId).then((function(operationResult){
                this.onJourneyInfoUpdated(journeyRadioId)
                return operationResult;
            }).bind(this));
            
        },

        revertToDraft :function(journeyRadioId){
            return TrebbleClientAPI.getInstance().revertToDraft(journeyRadioId).then((function(operationResult){
                this.onJourneyInfoUpdated(journeyRadioId)
                return operationResult;
            }).bind(this));
        },

        removeFromArchive : function(journeyRadioId){
            return TrebbleClientAPI.getInstance().removeFromArchive(journeyRadioId).then((function(operationResult){
                this.onJourneyInfoUpdated(journeyRadioId)
                return operationResult;
            }).bind(this));
        },

        deleteJourney:function(journeyRadioId){
            return TrebbleClientAPI.getInstance().deletAJourney(journeyRadioId).then((function(operationResult){
                this.onJourneyDeleted(journeyRadioId)
                return operationResult;
            }).bind(this));
        },


        archivePublishedJourney :function(journeyRadioId){
            return TrebbleClientAPI.getInstance().archivePublishedJourney(journeyRadioId).then((function(operationResult){
                this.onJourneyInfoUpdated(journeyRadioId)
                return operationResult;
            }).bind(this));
        },

        hasCapsuleCreated : function(){
            return this._capsuleIdToCapsuleInfo && Object.keys(this._capsuleIdToCapsuleInfo).length > 0;
        },


        onSongCapsuleCreatedForSong: function(songCapsuleCreateddInfo){
            if(songCapsuleCreateddInfo){
                var capsuleUID = songCapsuleCreateddInfo.capsuleUID;
                this._capsuleIdToCapsuleInfo[capsuleUID] = songCapsuleCreateddInfo.capsulInfo;
                var songModel =  this.songCollection.get(songCapsuleCreateddInfo.songsURIAdded);

                if(songModel){
                    if(!songModel.get("capsuleUIDs")){
                        songModel.set("capsuleUIDs",[]);
                    }
                    if(songModel.get("capsuleUIDs").indexOf(capsuleUID) == -1){
                        songModel.get("capsuleUIDs").push(capsuleUID);
                    }
                }	
            }
            this.trigger("capsuleAddedOrRemoved");
        },


        subscribe : function(playlistSummaryModel){
            try{
                this.trigger("beforeSubscribeToTrebble",playlistSummaryModel, true);
            }catch(error){
                console.error(error);
            }
            if(playlistSummaryModel && playlistSummaryModel.canBeFollowed()){
                return TrebbleClientAPI.getInstance().followTrebble(playlistSummaryModel.getLoaderKey()).then((function(){
                    playlistSummaryModel.setFollowing(true);
                    FacebookEventTrackingHelper.getInstance().trackSubscriptionToATrebble(playlistSummaryModel.getLoaderKey());
                    this._numberOfSubscribesInCurrentSession = this._numberOfSubscribesInCurrentSession + 1;
                    if(!this._myFollowedRadioToRadioInfoMap[playlistSummaryModel.getLoaderKey()]){
                        this._myFollowedRadioToRadioInfoMap[playlistSummaryModel.getLoaderKey()] = playlistSummaryModel.toJSON();
                        this._numberOfSubscriptions = this._numberOfSubscriptions + 1; 
                    }
                    this.trigger("subscribeToTrebble",playlistSummaryModel, true);
                    this.trigger("playlistSummarySubsciptionChanged",playlistSummaryModel, true);
                    this.trigger("subscriptionChanged");
                    if(window.trebbleAnalyticsHelper){
                        window.trebbleAnalyticsHelper.trackEvent("Trebble", "subscribeToTrebble", "Subscribe To Trebble");
                        if(window.trebbleAnalyticsHelper.trebbleHelperTrackSubscribeToTrebble){
                            window.trebbleAnalyticsHelper.trebbleHelperTrackSubscribeToTrebble();
                        }
                    } 
                    return true;
                }).bind(this));
}else{
return RSVP.Promise.resolve();
}
},

subscribeToJourney : function(journeySummaryModel){
try{
this.trigger("beforeSubscribeToJourney",journeySummaryModel, true);
}catch(error){
console.error(error);
}
if(journeySummaryModel && journeySummaryModel.canBeFollowed()){
return TrebbleClientAPI.getInstance().followJourney(journeySummaryModel.getLoaderKey()).then((function(operationResult){
    journeySummaryModel.setFollowing(true);
    FacebookEventTrackingHelper.getInstance().trackSubscriptionToAJourney(journeySummaryModel.getLoaderKey());
    this._numberOfSubscribesInCurrentSession = this._numberOfSubscribesInCurrentSession + 1;
    if(!this._myFollowedJourneyToJourneyRadioInfoMap[journeySummaryModel.getLoaderKey()]){
        this._myFollowedJourneyToJourneyRadioInfoMap[journeySummaryModel.getLoaderKey()] = journeySummaryModel.toJSON();
        this._numberOfJourneySubscriptions = this._numberOfJourneySubscriptions + 1; 
    }
    if(!this._myFollowedRadioToRadioInfoMap[journeySummaryModel.getLoaderKey()]){
        this._myFollowedRadioToRadioInfoMap[journeySummaryModel.getLoaderKey()] = journeySummaryModel.toJSON();
        this._numberOfSubscriptions = this._numberOfSubscriptions + 1; 
    }
    this.onJourneyInfoUpdated(journeySummaryModel.getLoaderKey());
    this.trigger("subscribeToJourney",journeySummaryModel, true);
    this.trigger("journeySummarySubsciptionChanged",journeySummaryModel, true);
    this.trigger("subscriptionChanged");


    if(window.trebbleAnalyticsHelper){
        window.trebbleAnalyticsHelper.trackEvent("Journey", "subscribeToJourney", "Subscribe To Journey");
        if(window.trebbleAnalyticsHelper.trebbleHelperTrackSubscribeToJourney){
            window.trebbleAnalyticsHelper.trebbleHelperTrackSubscribeToJourney();
        }
    } 
    return true;
}).bind(this));
}else{
return RSVP.Promise.resolve();
}
},

purchaseJourney : function(journeySummaryModel){
try{
this.trigger("beforePurchasingJourney",journeySummaryModel, true);
}catch(error){
console.error(error);
}
if(journeySummaryModel && journeySummaryModel.isPaid() && journeySummaryModel.hasPurchased()){
return TrebbleClientAPI.getInstance().followJourney(journeySummaryModel.getLoaderKey()).then((function(operationResult){
    journeySummaryModel.setFollowing(true);
    FacebookEventTrackingHelper.getInstance().trackSubscriptionToAJourney(journeySummaryModel.getLoaderKey());
    this._numberOfSubscribesInCurrentSession = this._numberOfSubscribesInCurrentSession + 1;
    if(!this._myFollowedJourneyToJourneyRadioInfoMap[journeySummaryModel.getLoaderKey()]){
        this._myFollowedJourneyToJourneyRadioInfoMap[journeySummaryModel.getLoaderKey()] = journeySummaryModel.toJSON();
        this._numberOfJourneySubscriptions = this._numberOfJourneySubscriptions + 1; 
    }
    if(!this._myFollowedRadioToRadioInfoMap[journeySummaryModel.getLoaderKey()]){
        this._myFollowedRadioToRadioInfoMap[journeySummaryModel.getLoaderKey()] = journeySummaryModel.toJSON();
        this._numberOfSubscriptions = this._numberOfSubscriptions + 1; 
    }
    this.onJourneyInfoUpdated(journeySummaryModel.getLoaderKey());
    this.trigger("subscribeToJourney",journeySummaryModel, true);
    this.trigger("journeySummarySubsciptionChanged",journeySummaryModel, true);
    this.trigger("subscriptionChanged");


    if(window.trebbleAnalyticsHelper){
        window.trebbleAnalyticsHelper.trackEvent("Journey", "subscribeToJourney", "Subscribe To Journey");
        if(window.trebbleAnalyticsHelper.trebbleHelperTrackSubscribeToJourney){
            window.trebbleAnalyticsHelper.trebbleHelperTrackSubscribeToJourney();
        }
    } 
    return true;
}).bind(this));
}else{
return RSVP.Promise.resolve();
}
},



getNumberOfSubscribesInSession : function(){
this._numberOfSubscribesInCurrentSession;
},

isLoggedInUserSubscribeToRadio : function(radioId){
return !!this._myFollowedRadioToRadioInfoMap[radioId];
},

unsubscribe : function(playlistSummaryModel){
try{
this.trigger("beforeUnsubscribeToTrebble",playlistSummaryModel, true);
}catch(error){
console.error(error);
}
if(playlistSummaryModel){
return TrebbleClientAPI.getInstance().unfollowTrebble(playlistSummaryModel.getLoaderKey()).then((function(){
    playlistSummaryModel.setFollowing(false);
    FacebookEventTrackingHelper.getInstance().trackUnsubscribeToATrebble(playlistSummaryModel.getLoaderKey());
    
    this._numberOfUnsubscribesInCurrentSession = this._numberOfUnsubscribesInCurrentSession + 1;
    if(this._myFollowedRadioToRadioInfoMap[playlistSummaryModel.getLoaderKey()]){
        delete this._myFollowedRadioToRadioInfoMap[playlistSummaryModel.getLoaderKey()];
        this._numberOfSubscriptions = this._numberOfSubscriptions - 1; 
    }
    
    this.trigger("unsubscribeToTrebble",playlistSummaryModel, true);
    this.trigger("playlistSummarySubsciptionChanged",playlistSummaryModel, false);
    this.trigger("subscriptionChanged");
    if(window.trebbleAnalyticsHelper){
        window.trebbleAnalyticsHelper.trackEvent("Trebble", "unsubscribeToTrebble", "Unsubscribe From Trebble");
        if(window.trebbleAnalyticsHelper.trebbleHelperTrackUnsubscribeToTrebble){
            window.trebbleAnalyticsHelper.trebbleHelperTrackUnsubscribeToTrebble();
        }
    } 
    return true;
}).bind(this));
}else{
return RSVP.Promise.resolve(false);
}
},

unsubscribeFromJourney : function(journeySummaryModel){
try{
this.trigger("beforeUnsubscribeToJourney",journeySummaryModel, true);
}catch(error){
console.error(error);
}
if(journeySummaryModel ){
return TrebbleClientAPI.getInstance().unfollowJourney(journeySummaryModel.getLoaderKey()).then((function(){
    journeySummaryModel.setFollowing(false);
    FacebookEventTrackingHelper.getInstance().trackUnsubscribeToAJourney(journeySummaryModel.getLoaderKey());
    
    this._numberOfUnsubscribesInCurrentSession = this._numberOfUnsubscribesInCurrentSession + 1;
    if(this._myFollowedJourneyToJourneyRadioInfoMap[journeySummaryModel.getLoaderKey()]){
        delete this._myFollowedJourneyToJourneyRadioInfoMap[journeySummaryModel.getLoaderKey()];
        this._numberOfJourneySubscriptions = this._numberOfJourneySubscriptions - 1; 
    }
    if(this._myFollowedRadioToRadioInfoMap[journeySummaryModel.getLoaderKey()]){
        delete this._myFollowedRadioToRadioInfoMap[journeySummaryModel.getLoaderKey()];
        this._numberOfSubscriptions = this._numberOfSubscriptions - 1; 
    }
    this.onJourneyInfoUpdated(journeySummaryModel.getLoaderKey());
    this.trigger("unsubscribeToJourney",journeySummaryModel, true);
    this.trigger("journeySummarySubsciptionChanged",journeySummaryModel, false);
    this.trigger("subscriptionChanged");
    if(window.trebbleAnalyticsHelper){
        window.trebbleAnalyticsHelper.trackEvent("Journey", "unsubscribeToJourney", "Unsubscribe From Journey");
        if(window.trebbleAnalyticsHelper.trebbleHelperTrackUnsubscribeToJourney){
            window.trebbleAnalyticsHelper.trebbleHelperTrackUnsubscribeToJourney();
        }
    } 
    return true;
}).bind(this));
}else{
return RSVP.Promise.resolve(false);
}
},

getNumberOfUnsubscribesInSession : function(){
this._numberOfUnsubscribesInCurrentSession;
},

onSongsRemovedOnRadio: function(radioSongsRemovedInfo){
if(radioSongsRemovedInfo){
var radioIdToNumberOfSongRemoved = {};
for(let index in radioSongsRemovedInfo){
    var radioRemovedInfo = radioSongsRemovedInfo[index];
    var radioId = radioRemovedInfo.radioUID;
    var radioInfo = this._radioIdToRadioInfo[radioId];
    if(radioInfo){
        if(radioInfo.songsInfo && radioRemovedInfo.songsInfoRemoved){
            var songsInfoToRemove = [];
            for(let index in radioInfo.songsInfo ){
                var songInfo = radioInfo.songsInfo[index];
                if(radioRemovedInfo.songsURIRemoved.indexOf(songInfo.uri) != -1){
                    songsInfoToRemove.push(songInfo);
                    if(songsInfoToRemove.length == radioRemovedInfo.songsURIRemoved.length){
                        break;
                    }
                }
            }
            for(let index  in songsInfoToRemove){
                var songInfoToRemve = songsInfoToRemove[index];
                var indexToRemove = radioInfo.songsInfo.indexOf(songInfoToRemve);
                if( indexToRemove != -1){
                    radioInfo.songsInfo.splice(indexToRemove , 1)
                    if(radioIdToNumberOfSongRemoved[radioId]){
                        radioIdToNumberOfSongRemoved[radioId] = radioIdToNumberOfSongRemoved[radioId] + 1;
                    }else{
                        radioIdToNumberOfSongRemoved[radioId] = 1;
                    }
                }
            }
        }		
    }
    for(let index in radioRemovedInfo.songsURIRemoved){
        var songUriRemoved = radioRemovedInfo.songsURIRemoved[index];
        var songModel =  this.songCollection.get(songUriRemoved);

        if(songModel){
            if(!songModel.get("radioUIDs")){
                songModel.set("radioUIDs",[]);
            }
            if(songModel.get("radioUIDs")){
                var radionIndex = songModel.get("radioUIDs").indexOf(radioId);
                if(radionIndex != -1){
                    songModel.get("radioUIDs").splice(radionIndex, 1);
                }

            }
        }

    }

}
this.trigger("songsRemovedToRadios",radioIdToNumberOfSongRemoved);
if(window.trebbleAnalyticsHelper){
    window.trebbleAnalyticsHelper.trackEvent("Song", "removedToTrebble", "Song Removed From Trebble Using Web");
    if(window.trebbleAnalyticsHelper.trebbleHelperTrackSongRemovedToTrebble){
        window.trebbleAnalyticsHelper.trebbleHelperTrackSongRemovedToTrebble();
    }
} 
}

},


onCapsuleDeleted: function(capsuleDeletedInfo){

if(capsuleDeletedInfo){
var capsuleUID = capsuleDeletedInfo.capsuleUID;
var capsuleInfo = this._capsuleIdToCapsuleInfo[capsuleUID];
delete this._capsuleIdToCapsuleInfo[capsuleUID];
for(let index in capsuleDeletedInfo.songsURIAttachedToCapsule){
    var songURIAttachedToCapsule = capsuleDeletedInfo.songsURIAttachedToCapsule[index];
    var songModel =  this.songCollection.get(songURIAttachedToCapsule);

    if(songModel){
        if(!songModel.get("capsuleUIDs")){
            songModel.set("capsuleUIDs",[]);
        }
        if(songModel.get("capsuleUIDs")){
            var capsuleIndex = songModel.get("capsuleUIDs").indexOf(capsuleUID);
            if(capsuleIndex != -1){
                songModel.get("capsuleUIDs").splice(capsuleIndex, 1);
            }

        }
    }

}
}
this.trigger("capsuleAddedOrRemoved");

},

onCapsuleProcessingCompleted : function(){
this.trigger("capsuleProcessingCompleted");
},


initializeOtherPersistentCollections : function(){
var songURIToRadioUIDs = {};
for(let radioId in this._radioIdToRadioInfo){
var personalRadioInfo  = this._radioIdToRadioInfo[radioId];
var songsInfo = personalRadioInfo.songsInfo;
var radioUID = personalRadioInfo._id;
if(songsInfo){

    for(let index in songsInfo){
        var songInfo = songsInfo[index];
        var radioUIDS = songURIToRadioUIDs[songInfo.uri]
        if(!radioUIDS){
            radioUIDS = [];
            songURIToRadioUIDs[songInfo.uri] = radioUIDS;
        }
        radioUIDS.push(radioUID)
    }
}
}
this.songCollection.each((function(songModel){
songModel.set("radioUIDs", songURIToRadioUIDs[songModel.get("uri")]);
songModel.set("capsuleUIDs", this._songUriToCapsuleUIDs[songModel.get("uri")]);
var radioUIDs = songModel.get("radioUIDs");
var capsuleUIDs = songModel.get("capsuleUIDs");
songModel.set("addedToRadio", (radioUIDs && radioUIDs.length > 0)? true: false);
songModel.set("hasCapsules", (capsuleUIDs && capsuleUIDs.length > 0)? true: false);
}).bind(this))

},

//Public
getGenreNameToSongFlatMap : function(){
return this._genreNameToSongFlatMap;
},

//Public
getLevelToGenreDetailsMap : function(){
return this._levelToGenreDetailsMap;
},

//Public
getGenreIdToGenreInfo : function(){
return this._genreIdToGenreInfo;
},


setAlbumCollection : function(albumJsonArray){
this.albumCollection =new AlbumCollection(albumJsonArray);
/*albumJsonArray = this.albumCollection.toJSON();
this.albumIdToAlbumJson = {};
if(albumJsonArray)
{
for(index in albumJsonArray)
{
    var albumJson = albumJsonArray[index];
    this.albumIdToAlbumJson[albumJson.albumId] = albumJson;
}
}*/
},

setArtistCollection : function(artistJsonArray){
this.artistCollection =new ArtistCollection(artistJsonArray);
/*artistJsonArray =  this.artistCollection.toJSON();
this.artistIdToArtistJson =  {};
var artistJsonArray = [];
if(artistJsonArray)
{
for(index in artistJsonArray)
{
    var artistJson = artistJsonArray[index];
    this.artistIdToArtistJson[artistJson.artistId] = artistJson;
}
}*/
},

setAutoPlaylistSummaries : function(autoPlaylistSummariesJsonArray){
if(!autoPlaylistSummariesJsonArray){
autoPlaylistSummariesJsonArray = [];
}
this.autoPlaylistSummariesCollection =  new PlaylistCollection(autoPlaylistSummariesJsonArray);
},

setAllDefaultGroupTags : function(allDefaultGroupTagsJsonArray){
this._allDefaultGroupTagsJsonArray = allDefaultGroupTagsJsonArray;
if(!this._allDefaultGroupTagsJsonArray){
this._allDefaultGroupTagsJsonArray = [];
}
var groupIdToGroupInfo =[];
this._groupIdToGroupInfo = groupIdToGroupInfo;
for(let index in this._allDefaultGroupTagsJsonArray){
var groupTagInfo = this._allDefaultGroupTagsJsonArray[index];
this._groupIdToGroupInfo[groupTagInfo.id] = groupTagInfo;
}
},

setAllDefaultTags : function(allDefaultTagsJsonArray){
this.allDefaultTagsJsonArray = allDefaultTagsJsonArray;
if(!this.allDefaultTagsJsonArray){
this.allDefaultTagsJsonArray = [];
}
this.allDefaultTagSetArray = this._buildDefaultTagSets(this.allDefaultTagsJsonArray);
this._initTagIgToSongMap();
},

_buildDefaultTagSets : function(tagJsonArray){
var groupIdToTagArray = {};
var groupIdToGroupInfo = {};
if(tagJsonArray){
for(let index in tagJsonArray){
    var tag = tagJsonArray[index];
    var groupId = tag.groupId;
    let tagsForGroupId = groupIdToTagArray[groupId];
    if(!tagsForGroupId){
        tagsForGroupId = [];
        groupIdToTagArray[groupId] = tagsForGroupId;

    }
    tagsForGroupId.push(tag);
}
}
var tagSetArray = [];
for(let groupId in groupIdToTagArray){
var groupInfo = this._groupIdToGroupInfo[groupId];
var tagGroup = new TagGroup(groupIdToTagArray[groupId]);
tagGroup.setExclusive( groupInfo.exclusive);
tagGroup.setLabel( groupInfo.label);
tagGroup.setId( groupInfo.id);
tagSetArray.push(tagGroup);
}
return tagSetArray;
},

_initTagIgToSongMap : function(){
this._tagIdToSongMap = {};
this._ratedTagsId = [];
if(this.allDefaultTagsJsonArray){
for(let index in this.allDefaultTagsJsonArray){
    var tagInfo = this.allDefaultTagsJsonArray[index];
    this._tagIdToSongMap[tagInfo.id] = {};
    if(tagInfo.groupId == this.getGeneralRatingTagGroupId()){
        this._ratedTagsId.push(tagInfo.id);
    }
}


/*if(this.songUriToSongJson)
{
    for(let songUri in this.songUriToSongJson)
    {
        var songJson = this.songUriToSongJson[songUri];
        this._addSongToTagMap(songJson);
    }

    for(let songUri in this.favoriteSongUriToSongJson)
    {
        var songJson = this.favoriteSongUriToSongJson[songUri];
        this._addSongToTagMap(songJson);
    }
}*/

}
},


getTagIdToSongsMap : function(){
return this._tagIdToSongMap;
},

isTagIdRatedTag: function(tagId){
if(this._ratedTagsId){
return this._ratedTagsId.indexOf(tagId) > -1;
}
return false;
},

_addSongToTagMap : function(songJson){
var songRated = false;
if(this._tagIdToSongMap && songJson && songJson.tagIds && songJson.tagIds.length > 0){
for(let tagIdndex in songJson.tagIds){
    var tagId = songJson.tagIds[tagIdndex];
    var songUriToSongMapForTagId = this._tagIdToSongMap[tagId];
    if(songUriToSongMapForTagId){
        if(! _.has(songUriToSongMapForTagId, songJson.uri)){
            songUriToSongMapForTagId[songJson.uri] = songJson;
            if(this.isTagIdRatedTag(tagId)){
                songRated = true;
            }
        }


    }
    /*if(this.isTagIdRatedTag(tagId)){
        var songUriToSongJson  = this._ratedTagIdToSongJsonArray[tagId];
        if(!songUriToSongJson){
            songUriToSongJson = {};
            this._ratedTagIdToSongJsonArray[tagId] = songUriToSongJson;
        }
        if(songUriToSongJson.indexOf(songJson.uri) == -1){
            songUriToSongJson[songJson.uri]  = songJson;
        }

    }*/
}
}
if(songJson && !songRated){
this._unratedSongIdToSongMap[songJson.uri] = songJson;
//this._unratedSongJsonArray.push(songJson);
}
},

_addSongToGenreMap : function(songJson){
var songRated = false;
var genreIds = Utils.getInstance().getGenreIdsForSongJson(songJson);
if(genreIds){
for(let index in genreIds){
    var genreId =  genreIds;
    var songUriToSongJsonArray = this._genreIdToSongJsonArray[genreId];
    if(!songUriToSongJsonArray){
        songUriToSongJsonArray = [];
        this._genreIdToSongJsonArray[genreId] = songUriToSongJsonArray;
    }
    if(! _.has(songUriToSongJsonArray , songJson.uri)){
        songUriToSongJsonArray[songJson.uri] = songJson;
    }


}
}
},

_addSongToRatedTagIgMap : function(songJson){
var songRated = false;
var genreIds = Utils.getInstance().getGenreIdsForSongJson(songJson);
if(genreIds){
for(let index in genreIds){
    var genreId =  genreIds;
    var songArray = this._genreIdToSongJsonArray[genreId];
    if(!songArray){
        songArray = [];
        this._genreIdToSongJsonArray[genreId] = songArray;
    }
    songArray.push(songJson);

}
}
},

_addSongsToTagMap : function(songJsonArray){
if(this._tagIdToSongMap && songJsonArray && songJsonArray.length > 0){
for(let index in songJsonArray){
    this._addSongToTagMap(songJsonArray[index]);
}
}
},

getAllDefaultTagsJsonArray : function(){
return this.allDefaultTagsJsonArray;
},

getAllDefaultTagSetArray : function(){
return this.allDefaultTagSetArray;
},

getAllDefaultTagsArray : function(){
return this.allDefaultTagsJsonArray;
},

getAutoPlaylistSummariesCollection : function(){

return this.autoPlaylistSummariesCollection;
},

/*
_initGenrePlaylists : function(){
var genreNameToSongFlatMap = this.getGenreNameToSongFlatMap();
var genrePlaylistSummariesJson = [];
var playlists = [];
for(let genreName in genreNameToSongFlatMap){
var genreToSongsInfo = genreNameToSongFlatMap[genreName];
if(genreToSongsInfo.hierachyLevel == 0){
    var options =  {};
    options.name = genreToSongsInfo.genre;
    options.description = "";
    options.loaderKey = genreToSongsInfo.genreId;
    options.id = genreToSongsInfo.genreId;

    var playlist =  new FixedPlaylist(options);
    playlist.add(songJsonArray);
    playlists.push(playlist);
    this._genreIdToSongJsonArray[genreToSongsInfo.genreId] = genreToSongsInfo.songs;
    //this._genreIdToFixedPlaylist[genreToSongsInfo.genreId] = playlist;

}
}
return playlists;
},*/

getGenrePlaylistSummariesCollection : function(){
var playlistSummaries  = [];
for(let genreId in this.getGenreIdToGenreInfo()){
var options =  {};
var genreInfo = this.getGenreIdToGenreInfo()[genreId];
options.name = genreInfo.genre;
if(genreInfo.songs){
    options.description = "" + genreInfo.songs.length + " songs";
}else{
    options.description = "0 song";
}
options.loaderKey =  genreInfo.genre;
options.id =  genreInfo.genreId;
options.audioPreviewUrl =  null;
options.coverArtUrl = null;
options.coverArtUrlBlur = null;
options.coverArtUrlInfo = null;
options.coverArtUrlBlurInfo = null;
options.coverArtBase64 = null;
options.lastUpdateDateString= new Date().toString();
var playlistSummary = Utils.getInstance().buildPlaylistSummaryFromJson(options);
playlistSummaries.push(playlistSummary);
}
return new PlaylistCollection(playlistSummaries);
},

getUnratedPlaylistId : function(){
return UNRATED_PLAYLIST_ID;
},

getUnratedPlaylistName : function(){
return UNRATED_PLAYLIST_ID;
},

getGeneralRatingTagGroupId : function(){
return GENERAL_RATING_TAG_GROUP_ID;
},



getMyRatedPlaylistSummariesCollection : function(){
var playlistSummaries  = [];
var allDefaultTagSetArray = this.getAllDefaultTagSetArray();
var ratingTagGroup = null;
for(let index in allDefaultTagSetArray){
var tagSet =allDefaultTagSetArray[index];
if(tagSet.getId() == this.getGeneralRatingTagGroupId()){
    ratingTagGroup =tagSet;
    break;
}
}
var defaultRatingTags = ratingTagGroup? ratingTagGroup.toJSON() : [];
for(let index in defaultRatingTags){

var tagInfo = defaultRatingTags[index];
var tagId = tagInfo.id;
var songJsonArray = this.getSongsForTagId(tagId);
var options =  {};
options.id = tagId;
options.name = tagInfo.label["EN"];
if(songJsonArray){
    options.description = "" + songJsonArray.length + " songs";
}else{
    options.description = "0 song";
}
options.loaderKey = tagId;
options.audioPreviewUrl = null;
options.coverArtUrl = null;
options.coverArtUrlBlur = null;
options.coverArtUrlInfo = null;
options.coverArtUrlBlurInfo = null;
options.coverArtBase64 = null;
options.lastUpdateDateString= new Date().toString();
var playlistSummary = Utils.getInstance().buildPlaylistSummaryFromJson(options);
playlistSummaries.push(playlistSummary);
}
return new PlaylistCollection(playlistSummaries);
},

getSongsForTagId : function(tagId){
var songArrayToReturn  =[];
var songIdToSongJsonMap = this._tagIdToSongMap[tagId];

if(!songIdToSongJsonMap){
if(tagId == this.getUnratedPlaylistId()){
    songIdToSongJsonMap =  this._unratedSongIdToSongMap;
}
}
for(let songUri in songIdToSongJsonMap){
var songJson = songIdToSongJsonMap[songUri];
songArrayToReturn.push(songJson);
}
return songArrayToReturn;

},

getSongsForGenreId: function(genreId){
var genreInfo =  this.getGenreIdToGenreInfo()[genreId];
return genreInfo.songs;
},

setPlayer : function(playerModel){
this.playerModel = playerModel;
window.trebble.globalHelpers.playerModel = this.playerModel;
this._listenToPlayerPlaybackEvent();
},

_listenToPlayerPlaybackEvent : function(){
this.listenTo(this.playerModel, "playerEvent_FinishPlay", this._onFinishPlayingEvent.bind(this));
this.listenTo(this.playerModel, "playerEvent_IndirectFinishPlay", this._onFinishPlayingEvent.bind(this));
},

_onFinishPlayingEvent : function(){
/*if(this.playerModel.getPlayerType() == this.playerModel.PLAYER_TYPES.TREBBLE_PLAYER) {
var trebbleId = this.playerModel.getPlaylist().getLoaderKey();
this.trigger("audioFinishPlayingForRadioWithId_"+trebbleId);
}*/
},

listenToFinishPlayingEventForRadioWithId : function(listener, radioId, handler){
//listener.listenTo("audioFinishPlayingForRadioWithId_"+trebbleId, this, handler);
if(this.playerModel){
listener.listenTo(this.playerModel, "audioFinishedPlayingForRadioWitId_"+radioId, handler);
}
},

_deleteFromSongCollection :function(songUri, silent){
    const songModel =  this.songCollection.get(songUri);
 silent = silent? true: false;
if(songModel != null){
this.songCollection.remove(songModel,{"silent" : silent});
//songModel.destroy();
}
},

//Public
removeSongFromSongCollectionAndFavoriteCollection : function(songModel){
if(songModel){
    const songUri  = songModel.get("uri");
this._deleteFromSongCollection(songUri);
}
},



_addSongJsonToMyLibraryOrFavoriteCollection : function( songJsonToAdd, albumJsonToAdd, artistJsonToAdd, silent){
if(!silent || silent != true)
{
silent = false;
}

if(songJsonToAdd)
{
if(!this.getSongCollection())
{
    this.setSongCollection(null);
}
if(!this.getAlbumCollection())
{
    this.setAlbumCollection(null);
}

if(!this.getArtistCollection())
{
    this.setArtistCollection(null);
}


const songUri = songJsonToAdd.uri;
let songAlreadyExist = false;
const existingSongModel = this.getSongModelBySongIdFromMyLibraryOrFavorites(songUri);
let songModelAdded = null;
let modelChanges = null;
let opts;
if(existingSongModel){
    songAlreadyExist = true;
    const oldAbumId = existingSongModel.get("albumJson").albumId;
    const oldArtistId = existingSongModel.get("albumJson").artistJson.artistId;
    const songUri = existingSongModel.get("uri");
    const songInAlbum  = existingSongModel.get("albumJson").songs;
    let i = null;
    for(let index in songInAlbum){
        const songJson  = songInAlbum[index];
        if(songJson.uri == songUri){
            i =index;
            break;
        }
    }
    if(i != null){
        songInAlbum.splice(i, 1);
    }

    if(existingSongModel.get("albumJson").songs.length == 0)
    {
        let i = null;
        const albumsForArtist =  existingSongModel.get("albumJson").artistJson.albums;
        const albumJsonToFind = existingSongModel.get("albumJson");
        for(let index in albumsForArtist){
            const album  = albumsForArtist[index];
            if(album.albumId == albumJsonToFind.albumId){
                i =index;
                break;
            }
        }
        //var i = existingSongModel.get("albumJson").artistJson.albums.indexOf(existingSongModel.get("albumJson"));
        if(i != null){
            existingSongModel.get("albumJson").artistJson.albums.splice(i, 1);
        }

        existingSongModel.get("albumJson").artistJson = null;
    }
     opts =  {"silent": silent};
    if(silent == true)
    {
        opts["sort"] = false;
    }
    modelChanges = songJsonToAdd;
    modelChanges.albumJson =  null;

    existingSongModel.set(modelChanges,opts)
    //songJsonToAdd = existingSongJson;
    songModelAdded = existingSongModel;
}else{



     opts =  {"silent": silent};
    if(silent == true)
    {
        opts["sort"] = false;
    }
    this.getSongCollection().add(songJsonToAdd, opts);
    songModelAdded = this.getSongCollection().get(songJsonToAdd.uri);


}
const songJsonAdded = songModelAdded.toJSON();
const albumId = songModelAdded.get("linkedAlbumId");
const artistId = songModelAdded.get("linkedArtistId");
let albumAlreadyExist = false;
let artistAlreadyExist = false;
let albumModel = this.albumCollection.get(albumId);
const artistModel = this.artistCollection.get(albumId);
//var existingAlbumJson = this.getJsonAlbumByAlbumId(albumId);
//var exisitingArtistJson = this.getJsonArtistByAlbumId(albumId);
//var albumId = songJsonToAdd.idifiedAlbumId? songJsonToAdd.idifiedAlbumId: songJsonToAdd.albumId;
//var artistId = songJsonToAdd.idifiedArtistId? songJsonToAdd.idifiedArtistId: songJsonToAdd.artistId;
//var albumAlreadyExist = false;
//var artistAlreadyExist = false;
//var existingAlbumJson = this.getJsonAlbumByAlbumId(albumId);
//var exisitingArtistJson = this.getJsonArtistByAlbumId(albumId);
if(albumModel)
{
    albumAlreadyExist = true;
     opts =  {"silent": silent};
    if(silent == true)
    {
        opts["sort"] = false;
    }
    songModelAdded.set({"albumJson": albumModel.toJSON()}, opts);
     opts =  {"silent": silent};
    if(silent == true)
    {
        opts["sort"] = false;
    }
    if(!albumModel.get("songs"))
    {
        albumModel.set({"songs":[]},opts)
    }
    albumModel.get("songs").push(songJsonAdded)
}else{
     opts =  {"silent": silent};
    if(silent == true)
    {
        opts["sort"] = false;
    }
    songModelAdded.set({"albumJson" : albumJsonToAdd}, opts); 
    if(!albumJsonToAdd.songs)
    {
        albumJsonToAdd.songs = [];
    }
    if(albumJsonToAdd.songs.indexOf(songJsonAdded) == -1)
    {
        albumJsonToAdd.songs.push(songJsonAdded);
    }
}
if(!albumAlreadyExist){
     opts =  {"silent": silent};
    if(silent == true)
    {
        opts["sort"] = false;
    }
    this.getAlbumCollection().add(albumJsonToAdd,opts);
    albumModel = this.getAlbumCollection().get(albumJsonToAdd.albumId);
}
var albumJsonAdded = albumModel.toJSON();
if(artistModel)
{
    var exisitingArtistJson = artistModel.toJSON();
    artistAlreadyExist = true;
    songModelAdded("albumJson").artistJson = exisitingArtistJson;
    if(!exisitingArtistJson.albums)
    {
        exisitingArtistJson.albums = [];
    }
    exisitingArtistJson.albums.push(albumJsonAdded);
}else{
    songModelAdded.get("albumJson").artistJson = artistJsonToAdd; 
    //songJsonAdded.albumJson.artistJson = artistJsonToAdd; 
    if(!artistJsonToAdd.albums)
    {
        artistJsonToAdd.albums = [];
    }
    if(artistJsonToAdd.albums.indexOf(songModelAdded.get("albumJson")) == -1)
    {
        artistJsonToAdd.albums.push(songModelAdded.get("albumJson"));
    }
}
if(!artistAlreadyExist){
     opts =  {"silent": silent};
    if(silent == true)
    {
        opts["sort"] = false;
    }
    this.getArtistCollection().add(artistJsonToAdd, opts);
}
if(!existingSongModel)
{
    Utils.getInstance().addSongToGenreArray(songJsonAdded, this.getGenreNameToSongFlatMap(), this.getLevelToGenreDetailsMap(), this.getGenreIdToGenreInfo());
    this._addSongToTagMap(songJsonAdded);
    //this._addSongToGenreMap(songJsonAdded);
}else{
    Utils.getInstance().addSongToGenreArray(songJsonAdded, this.getGenreNameToSongFlatMap(), this.getLevelToGenreDetailsMap(), this.getGenreIdToGenreInfo());
    this._addSongToTagMap(songJsonAdded);
    //this._addSongToGenreMap(songJsonAdded);
}
}else{
return null;
}

},

addSongToDefaultRadio : function(songToAddToRadio, context, songRefSummary, doNotGrade){
if(songToAddToRadio){
songToAddToRadio.set({"beingAddedToTrebble":true} ,{"silent": false});
songToAddToRadio.set({"favorite":true} ,{"silent": true});
songToAddToRadio.set({"favoriteString":"true"} , {"silent": true});
return TrebbleIndexDBHelper.getInstance().saveSong(songToAddToRadio.toJSON(), context, true, false, null, false, true).then((function(){
    if(songToAddToRadio){
        var songUri = songToAddToRadio.get("uri");
        return TrebbleClientAPI.getInstance().addSongsToMyDefaultPersonalRadio([songUri], context);
    }
}).bind(this)).then((function(results){
    if(songToAddToRadio && results){
        var songURi = songToAddToRadio.get("uri");
        this.onSongsAddedOnRadio(results);
        var radioUIDs = songToAddToRadio.get("radioUIDs");
        if(!radioUIDs){
            radioUIDs = []; 
            songToAddToRadio.set("radioUIDs",radioUIDs);
        }
        var songUriToSongUID = {};
        for(let index in results){
            var radioOperationResult = results[index];
            //{songUriToSongUID} WILL BE USED FOR A WORKAROUND TO PATCH THE FACT THAT WHEN A SONG IS ADDED TO THE USER LIBRARY THERE IS TREBBLEID SET ON THE SONG
            for(let ii in radioOperationResult.songsInfoAdded){
                var sInfo = radioOperationResult.songsInfoAdded[ii];
                songUriToSongUID[sInfo["uri"]] = sInfo["_id"];
            }
            var radioUID = radioOperationResult.radioUID;
            var wasAddedToRadio =  (radioOperationResult.songsURIAdded)? radioOperationResult.songsURIAdded.indexOf(songURi) >= 0: false;	
            if(wasAddedToRadio){
                var indexOfRadioToAdd  = radioUIDs.indexOf(radioUID);
                if(indexOfRadioToAdd == -1){
                    radioUIDs.push(radioUID)
                }else{
                    console.warn("Song was already added to the list of Trebbles where the song is present!")
                    //Utils.getInstance().showWarningNotification("Radio was already added to the list of radio where the song is present!");
                }
            }

        }
        var currentRadioUIDs = songToAddToRadio.get("radioUIDs");
        songToAddToRadio.set({"beingAddedToTrebble":false} ,{"silent": true});
        songToAddToRadio.set("addedToRadio", (currentRadioUIDs && currentRadioUIDs.length > 0)? true: false);
        //THIS IS A WORKAROUND TO PATCH THE FACT THAT WHEN A SONG IS ADDED TO THE USER LIBRARY THERE IS TREBBLEID SET ON THE SONG
        if(!songToAddToRadio.getTrebbleSongId()){
            songToAddToRadio.set({"trebbleSongId":songUriToSongUID[songToAddToRadio.get("uri")]}, {"silent": true});
        }
        if(SHOW_NOTIFICATION_ON_SONG_ADDED_OR_REMOVED_FROM_TREBBLE){
            Utils.getInstance().showLongToastMessage("\""+songToAddToRadio.get("title")+"\" "+ window.getI18n(ti18n, "WAS_ADDED_TO_YOUR_LIBRARY"));
        }
    }else{
        songToAddToRadio.set({"beingAddedToTrebble":false} ,{"silent": false});
        if(SHOW_NOTIFICATION_ON_SONG_ADDED_OR_REMOVED_FROM_TREBBLE){
            Utils.getInstance().showWarningNotification("\""+songToAddToRadio.get("title")+"\" "+ window.getI18n(ti18n, "HAS_NOT_BEEN_ADDED_TO_YOUR_LIBRARY"));
        }
    }
}).bind(this)).then((function(){
    return TrebbleIndexDBHelper.getInstance().saveSong(songToAddToRadio.toJSON(),context,  false, true, null, true, false);
}).bind(this)).then((function(){
    this._addGenreInfoIfMissing(songToAddToRadio, context);
    if(!doNotGrade && this.getRouter() && window.trebble.config.showSongGradePopupAfterSongAddedToTrebble && (!songToAddToRadio.get("tagIds") || songToAddToRadio.get("tagIds").length == 0 )){
        var onChangeSongTagPopupClosed =  (function(){
            if(SHOW_SUGGESTION_TO_CREATE_CAPSULE_AFTER_A_SONG_IS_ADDED_TO_RADIO){
                this._showSuggestionPopupToCreateACapsuleIfApplicable();
            }
        }).bind(this);
        return this.getRouter().showSongTagManagerPopup(songToAddToRadio, context, onChangeSongTagPopupClosed);
    }else{
        if(SHOW_SUGGESTION_TO_CREATE_CAPSULE_AFTER_A_SONG_IS_ADDED_TO_RADIO){
            this._showSuggestionPopupToCreateACapsuleIfApplicable();
        }
    }
}).bind(this)).catch((function(error){
    songToAddToRadio.set({"beingAddedToTrebble":false} ,{"silent": false});
    throw error;
}).bind(this));
}else{
return RSVP.Promise.resolve();
}
},

addCapsuleToRadio : function(capsuleModelToAddToRadio, radioIds, lifeSpanInDays, isCapsuleScheduled, scheduleStartDate,timezone, context){
if(capsuleModelToAddToRadio){
capsuleModelToAddToRadio.set({"beingAddedToTrebble":true} ,{"silent": false});
return TrebbleClientAPI.getInstance().addCapsuleToRadios(capsuleModelToAddToRadio.getCapsuleId(),radioIds,lifeSpanInDays,  isCapsuleScheduled, scheduleStartDate,timezone, context).then((function(results){
    if(results){
        var capsuleId = capsuleModelToAddToRadio.getCapsuleId();

        var airingOnRadios = capsuleModelToAddToRadio.getAiringOnRadiosInfo();
        if(!airingOnRadios){
            airingOnRadios = []; 
            capsuleModelToAddToRadio.setAiringOnRadiosInfo(airingOnRadios);
        }
        var wasAddedToRadio = false;
        var radioIdsToAiringInfoToAddOrUpdate  = [];
        for(let radioId in results){
            if(radioIds.indexOf(radioId) !== -1){
                var capsuleInfoAddedToRadio = results[radioId];
                if(capsuleInfoAddedToRadio._id === capsuleId){
                    var airingInfoForRadio  = capsuleInfoAddedToRadio.airingOnRadios;
                    wasAddedToRadio =  (airingInfoForRadio && airingInfoForRadio.length > 0)? true: false;	
                    if(wasAddedToRadio){
                        for(let oo  = 0; oo < airingInfoForRadio.length; oo++){
                            var aInfo = airingInfoForRadio[oo]
                            radioIdsToAiringInfoToAddOrUpdate[aInfo.radioUID] = aInfo;
                        }
                    }
                }
            }

        }
        //UPDATE EXSITING AIRING INFO
        var i = airingOnRadios.length
        while(i--){
            var info = airingOnRadios[i];
            if(info.radioUID in radioIdsToAiringInfoToAddOrUpdate){
                //THIS AIRING INFO NEEDS TO BE UPDATED
                airingOnRadios.splice(i, 1, radioIdsToAiringInfoToAddOrUpdate[info.radioUID]);
                //REMOVING OBJECT THAT ARE UPDATED 
                delete radioIdsToAiringInfoToAddOrUpdate[info.radioUID];
            }
        }
        for(let radioId in radioIdsToAiringInfoToAddOrUpdate){
            //RADIO WHERE CAPSULE WAS ADDED NOT UDPATED
            airingOnRadios.push(radioIdsToAiringInfoToAddOrUpdate[radioId]);
        }
        if(wasAddedToRadio){
            this.onCapsuleAddedToRadio(results, capsuleModelToAddToRadio, radioIds, lifeSpanInDays);
        }
        capsuleModelToAddToRadio.set({"beingAddedToTrebble":false} ,{"silent": false});
        if(wasAddedToRadio && SHOW_NOTIFICATION_ON_CAPSULE_ADDED_OR_REMOVED_FROM_TREBBLE){
            Utils.getInstance().showLongToastMessage(window.getI18n(ti18n, "A_CAPSULE_WAS_ADDED_TO_YOUR_TREBBLE"));
        }
    }else{
        capsuleModelToAddToRadio.set({"beingAddedToTrebble":false} ,{"silent": false});
        if(SHOW_NOTIFICATION_ON_CAPSULE_ADDED_OR_REMOVED_FROM_TREBBLE){
            Utils.getInstance().showWarningNotification(window.getI18n(ti18n, "HUMM_IT_LOOKS_LIKE_YOUR_CAPSULE_WAS_NOT_ADDED_TO_YOUR_TREBBLE"));
        }
    }
}).bind(this)).catch((function(error){
    capsuleModelToAddToRadio.set({"beingAddedToTrebble":false} ,{"silent": false});
    throw error;
}).bind(this));
}else{
return RSVP.Promise.resolve();
}
},

removeCapsuleFromRadio : function(capsuleModelToRemoveFromRadio, radioIds, context){
if(capsuleModelToRemoveFromRadio){
capsuleModelToRemoveFromRadio.set({"beingRemovedFromTrebble":true} ,{"silent": false});
return TrebbleClientAPI.getInstance().removeCapsuleFromRadios(capsuleModelToRemoveFromRadio.getCapsuleId(),radioIds, context).then((function(results){
    if(results){
        var capsuleId = capsuleModelToRemoveFromRadio.getCapsuleId();

        var airingOnRadios = capsuleModelToRemoveFromRadio.getAiringOnRadiosInfo();
        if(!airingOnRadios){
            airingOnRadios = []; 
            capsuleModelToRemoveFromRadio.setAiringOnRadiosInfo(airingOnRadios);
        }
        var radioIdsToRemove  = [];
        for(let radioId in results){
            if(radioIds.indexOf(radioId) !== -1){
                var capsuleInfoRemovedToRadio = results[radioId];
                if(capsuleInfoRemovedToRadio._id === capsuleId){
                    var airingInfoForRadio  = capsuleInfoRemovedToRadio.airingOnRadios;
                    var wasRemovedToRadio =  (airingInfoForRadio || airingInfoForRadio.length === 0)? true: false;	
                    if(wasRemovedToRadio){
                        radioIdsToRemove.push(radioId);
                    }
                }
            }
        }
        //REMOVE RADIO INFO THAT WERE REMOVED
        var i = airingOnRadios.length
        while(i--){
            var info = airingOnRadios[i];
            if(radioIdsToRemove.indexOf(info.radioUID) > -1){
                //THIS AIRING INFO NEEDS TO BE REMOVED
                airingOnRadios.splice(i, 1);
            }
        }
        if(wasRemovedToRadio){
            this.onCapsuleRemovedFromRadio(results, capsuleModelToRemoveFromRadio, radioIds);
        }
        capsuleModelToRemoveFromRadio.set({"beingRemovedFromTrebble":false} ,{"silent": false});
        if(radioIdsToRemove.length > 0 && SHOW_NOTIFICATION_ON_CAPSULE_ADDED_OR_REMOVED_FROM_TREBBLE){
            Utils.getInstance().showLongToastMessage(window.getI18n(ti18n, "A_CAPSULE_WAS_REMOVED_FROM_YOUR_TREBBLE"));
        }
    }else{
        capsuleModelToRemoveFromRadio.set({"beingRemovedFromTrebble":false} ,{"silent": false});
        if(SHOW_NOTIFICATION_ON_CAPSULE_ADDED_OR_REMOVED_FROM_TREBBLE){
            Utils.getInstance().showWarningNotification(window.getI18n(ti18n, "HUMM_IT_LOOKS_LIKE_YOUR_CAPSULE_WAS_NOT_REMOVED_FROM_YOUR_TREBBLE"));
        }
    }
}).bind(this)).catch((function(error){
    capsuleModelToRemoveFromRadio.set({"beingRemovedFromTrebble":false} ,{"silent": false});
    throw error;
}).bind(this));
}else{
return RSVP.Promise.resolve();
}
},

_addGenreInfoIfMissing : function(songModel, context){
if(songModel){
var songTitle = songModel.get("title");
var artistName = songModel.get("artist");
var songJson = songModel.toJSON();
if(Utils.getInstance().isSpotifyUri(songJson.uri) && (!songJson.idifiedTopGenreId || songJson.idifiedTopGenreId.indexOf("spotify") == 0) ){
    return TrebbleClientAPI.getInstance().getIdifiedSongInfoBySongTitle(songTitle, artistName).then((function(idifiedInfo){
        if(idifiedInfo){
            songModel.updateGenreAndOtherInfo(idifiedInfo);
            return TrebbleIndexDBHelper.getInstance().saveSong(songModel.toJSON(),context,  false, true, null, true, false);
        }
    }).bind(this));
}

}
return RSVP.Promise.resolve();

},

/*addSongsFromLibraryToTrebble : function(songModelsToAdd, context){
var songUris = [];
var songUriToSongModel = {};
for(let index in songModelsToAdd){
var songModel = songModelsToAdd[index];
songModel.set({"beingAddedToTrebble":true} ,{"silent": false});
songUris.push(songModel.get("uri"));
songUriToSongModel[songModel.get("uri")] =  songModel;
}
return TrebbleClientAPI.getInstance().addSongsToMyDefaultPersonalRadio(songUris, context).then((function(results){
if(results){
    var uriOfSongAdded = [];
    for(let songUri in songUriToSongModel){
        var songModel = songUriToSongModel[songUri];
        this.onSongsAddedOnRadio(results);
        var radioUIDs = songModel.get("radioUIDs");
        if(!radioUIDs){
            radioUIDs = []; 
            songModel.set("radioUIDs",radioUIDs);
        }
        var songUriToSongUID = {};
        for(let index in results){
            var radioOperationResult = results[index];
            //{songUriToSongUID} WILL BE USED FOR A WORKAROUND TO PATCH THE FACT THAT WHEN A SONG IS ADDED TO THE USER LIBRARY THERE IS TREBBLEID SET ON THE SONG
            for(let ii in radioOperationResult.songsInfoAdded){
                var sInfo = radioOperationResult.songsInfoAdded[ii];
                songUriToSongUID[sInfo["uri"]] = sInfo["_id"];
            }
            var radioUID = radioOperationResult.radioUID;
            var wasAddedToRadio =  (radioOperationResult.songsURIAdded)? radioOperationResult.songsURIAdded.indexOf(songUri) >= 0: false;	
            if(wasAddedToRadio){
                if(uriOfSongAdded.indexOf(songUri) == -1){
                    uriOfSongAdded.push(songUri);
                }
                var indexOfRadioToAdd  = radioUIDs.indexOf(radioUID);
                if(indexOfRadioToAdd == -1){
                    radioUIDs.push(radioUID)
                }else{
                    console.warn("Song was already added to the list of Trebbles where the song is present!")

                }
            }

        }
        var currentRadioUIDs = songModel.get("radioUIDs");
        songModel.set({"beingAddedToTrebble":false} ,{"silent": true});
        songModel.set("addedToRadio", (currentRadioUIDs && currentRadioUIDs.length > 0)? true: false);
        //THIS IS A WORKAROUND TO PATCH THE FACT THAT WHEN A SONG IS ADDED TO THE USER LIBRARY THERE IS TREBBLEID SET ON THE SONG
        if(!songModel.getTrebbleSongId()){
            songModel.set({"trebbleSongId":songUriToSongUID[songModel.get("uri")]}, {"silent": true});
        }
    }
    Utils.getInstance().showLongToastMessage(uriOfSongAdded.length+" songs were added from your trebble");
}else{
    for(let index in songModelsToAdd){
        var songModel = songModelsToAdd[index];
        songModel.set({"beingAddedToTrebble":false} ,{"silent": false});
    }
    Utils.getInstance().showWarningNotification(songModelsToAdd.length+" songs were not added to your trebble");
}

}).bind(this)).then((function(){
var  promises = [];
for(let index in songModelsToAdd){
    var songModel = songModelsToAdd[index];
    promises.push(TrebbleIndexDBHelper.getInstance().saveSong(songModel.toJSON(),context,  false, true, null, true, false));
}
return promises;
}).bind(this)).catch((function(error){
for(let index in songModelsToAdd){
    var songModel = songModelsToAdd[index];
    songModel.set({"beingAddedToTrebble":false} ,{"silent": false});
}
throw error;
}).bind(this));

},*/

saveSongBeforeCreatingCapsule : function(songModel, context){
var p  = songModel? TrebbleIndexDBHelper.getInstance().saveSong(songModel.toJSON(), context, true, false, null, false, true) : RSVP.Promise.resolve();
return p;
},

updateSongCapsule : async function(capsuleId, audioPartsInfo, title, comment, imageArtUrl, context, capsuleReceiverId,wasTranscribedAndEditedInTrebble, hqAudioInfo, projectId, videoInfo, audioInfo ){
    return await TrebbleClientAPI.getInstance().updateCapsule(capsuleId, audioPartsInfo, title,comment, imageArtUrl, context, capsuleReceiverId,wasTranscribedAndEditedInTrebble, hqAudioInfo, projectId, videoInfo, audioInfo );
},

createSongCapsule : function(songModel, capsuleId, audioPartsInfo,lifeSpan,title, comment,imageArtUrl,backgroundMusicUri, doNotTriggerAudioProcessing,  categoryId, isPrivate, context, capsuleReceiverId, doNotAddOnAir, isCapsuleScheduled , scheduleStartDate, timezone, language, textToConvertToAudio, voiceToUseForTTS, ttsLanguage, narrationParams, wasTranscribedAndEditedInTrebble, hqAudioInfo, projectId, videoInfo){
if(true/*songModel || capsuleId*/){
//var p  = songModel? TrebbleIndexDBHelper.getInstance().saveSong(songModel.toJSON(), context, true, false, null, false, true) : RSVP.Promise.resolve();
var capsuleOperationResult =  null;
return this.saveSongBeforeCreatingCapsule(songModel,context ).then((function(){
    if(true/*songModel || capsuleId*/){
        var songJsonForBackend = Utils.getInstance().getSongJsonForBackendFromSongModel(songModel);
        return TrebbleClientAPI.getInstance().createSongCapsule(songJsonForBackend, capsuleId, audioPartsInfo,lifeSpan,title, comment,imageArtUrl,backgroundMusicUri, doNotTriggerAudioProcessing,categoryId, isPrivate, context, capsuleReceiverId,null,doNotAddOnAir, isCapsuleScheduled , scheduleStartDate, timezone, language, textToConvertToAudio, voiceToUseForTTS, ttsLanguage, narrationParams, wasTranscribedAndEditedInTrebble, hqAudioInfo, projectId, videoInfo);
    }
}).bind(this)).then((function(result){
    capsuleOperationResult = result;
    if((true/*songModel || capsuleId*/) && result){
        this.onSongCapsuleCreatedForSong(result);
        var capsuleUIDs = songModel? songModel.get("capsuleUIDs"): null;
        if(!capsuleUIDs && songModel){
            capsuleUIDs = []; 
            songModel.set("capsuleUIDs",capsuleUIDs);
        }


        var capsuleUID = result.capsuleUID;
        var wasCapsuleSuccessfullyCreated =  (result.songsURIAdded)? true: false;	
        if(wasCapsuleSuccessfullyCreated){
            var indexOfCapsuleToAdd  = capsuleUIDs.indexOf(capsuleUID);
            if(indexOfCapsuleToAdd == -1){
                capsuleUIDs.push(capsuleUID)
            }else{
                console.warn("Capsule was alrady created for this song!")
                //Utils.getInstance().showWarningNotification("Radio was already added to the list of radio where the song is present!");
            }
        }


        var currentCapsuleUIDs = songModel? songModel.get("capsuleUIDs"): null;
        if(songModel){
            songModel.set("hasCapsules", (currentCapsuleUIDs && currentCapsuleUIDs.length > 0)? true: false);
        }

    }else{
        Utils.getInstance().showWarningNotification("Capsule couldn't be created for \""+songModel.get("title")+"\"");
    }
}).bind(this)).then((function(){
    if(songModel){
        return TrebbleIndexDBHelper.getInstance().saveSong(songModel.toJSON(),context,  false, true, null, true, false);
    }
}).bind(this)).then(function(){
    return capsuleOperationResult;
});
}else{
return RSVP.Promise.resolve();
}
},



removeSongFromDefaultRadio : function(songToRemoveFromRadio, context, songRefSummary){
if(songToRemoveFromRadio){
songToRemoveFromRadio.set({"beingRemovedFromTrebble":true} ,{"silent": false});
songToRemoveFromRadio.set({"favorite":null} ,{"silent": true});
songToRemoveFromRadio.set({"favoriteString":"false"} , {"silent": true});
var songUri = songToRemoveFromRadio.get("uri");
return TrebbleClientAPI.getInstance().removeSongsFromMyDefaultPersonalRadio([songUri], context).then((function(results){
    if(songToRemoveFromRadio && results){
        var songURi = songToRemoveFromRadio.get("uri");
        this.onSongsRemovedOnRadio(results);
        var radioUIDs = songToRemoveFromRadio.get("radioUIDs");
        if(!radioUIDs){
            radioUIDs = []; 
            songToRemoveFromRadio.set("radioUIDs",radioUIDs);
        }
        for(let index in results){
            var radioOperationResult = results[index];
            var radioUID = radioOperationResult.radioUID;
            var wasRemovedToRadio =  (radioOperationResult.songsURIRemoved)? radioOperationResult.songsURIRemoved.indexOf(songURi) >= 0: false;
            if(wasRemovedToRadio){
                var indexOfRadioToRemove  = radioUIDs.indexOf(radioUID);
                if(indexOfRadioToRemove != -1){
                    radioUIDs.splice(indexOfRadioToRemove, 1);
                }else{
                    console.warn("Song was already removed to the list of trebbles where the song is present!")
                    //Utils.getInstance().showWarningNotification("Radio was already removed to the list of radio where the song is present!");
                }
            }
        }
        var currentRadioUIDs = songToRemoveFromRadio.get("radioUIDs");
        songToRemoveFromRadio.set({"beingRemovedFromTrebble":false} ,{"silent": true});
        songToRemoveFromRadio.set("addedToRadio", (currentRadioUIDs && currentRadioUIDs.length > 0)? true: false);
        if(SHOW_NOTIFICATION_ON_SONG_ADDED_OR_REMOVED_FROM_TREBBLE){
            Utils.getInstance().showLongToastMessage("\""+songToRemoveFromRadio.get("title")+"\" "+ window.getI18n(ti18n, "WAS_REMOVED_FROM_YOUR_TREBBLE"));
        }
    }else{
        songToRemoveFromRadio.set({"beingRemovedFromTrebble":false} ,{"silent": false});
        if(SHOW_NOTIFICATION_ON_SONG_ADDED_OR_REMOVED_FROM_TREBBLE){
            Utils.getInstance().showWarningNotification("\""+songToRemoveFromRadio.get("title")+"\" "+ window.getI18n(ti18n, "HAS_NOT_BEEN_REMOVED_FROM_YOUR_TREBBLE"));
        }
    }
}).bind(this)).then((function(){
    return TrebbleIndexDBHelper.getInstance().saveSong(songToRemoveFromRadio.toJSON(),context, false, true, null, true, false);
}).bind(this)).catch((function(error){
    songToRemoveFromRadio.set({"beingRemovedFromTrebble":false} ,{"silent": false});
    throw error;
}).bind(this));
}else{
return RSVP.Promise.resolve();
}
},



notifyUserOfUnconsumedOwnerComments : function(songModel, context, songRefSummary){
var songJsonForBackend = Utils.getInstance().getSongJsonForBackendFromSongModel(songModel);
var songUri = songJsonForBackend.uri;
if(this._songUriWithConsumedComments.indexOf(songUri) == -1){
return TrebbleClientAPI.getInstance().markSongCommentsAsConsumed( songJsonForBackend, context, songRefSummary).then((function(){
    this._songUriWithConsumedComments.push(songUri);
}).bind(this));
}else{
return RSVP.Promise.resolve();
}
},

notifyUserOfUnconsumedCapsuleComments : function(capsuleId, context, songRefSummary){
if(this._capsuleIdWithConsumedComments.indexOf(capsuleId) == -1){
return TrebbleClientAPI.getInstance().markCapsuleCommentsAsConsumed( capsuleId, context, songRefSummary).then((function(){
    this._capsuleIdWithConsumedComments.push(capsuleId);
}).bind(this));
}else{
return RSVP.Promise.resolve();
}
},


getCapsuleExpirationDateForUserTrebbles : function(capsuleJsonOrModel){
return Utils.getInstance().getCapsuleExpirationDate(capsuleJsonOrModel, this.getAllMyPersonalRadioIds());
},

isCapsuleAddedToUserTrebbles : function(capsuleJsonOrModel){
return !!Utils.getInstance().getCapsuleDataForRadioIds(capsuleJsonOrModel, this.getAllMyPersonalRadioIds(), "addedDate");
},

getCapsuleAddedToUserTrebbles : function(capsuleJsonOrModel){
return Utils.getInstance().getCapsuleDataForRadioIds(capsuleJsonOrModel, this.getAllMyPersonalRadioIds(), "addedDate");
},

getCapsuleAddedToUserTrebblesTimezone : function(capsuleJsonOrModel){
return Utils.getInstance().getCapsuleDataForRadioIds(capsuleJsonOrModel, this.getAllMyPersonalRadioIds(), "timezone");
},

isSongModelInPlayerQueueAddedToUserTrebbles : function(songModelInPlayerQueue){
var uri = songModelInPlayerQueue.get("uri");
var songTypeLabel = null;
var isSongASong = false;
var isSongACapsule = Utils.getInstance().isCapsuleUri(uri);
if(isSongACapsule){
songTypeLabel = "Capsule";
}
var isSongAJingle = Utils.getInstance().isJingleUri(uri);
if(isSongAJingle){
songTypeLabel = "Jingle";
}
var isSongAGreater = Utils.getInstance().isGreaterUri(uri);
if(isSongAGreater){
songTypeLabel = "Greater";
}
var isSongAIntro = Utils.getInstance().isIntroUri(uri);
if(isSongAIntro){
songTypeLabel = "Intro";
}
var isSongAOutro = Utils.getInstance().isOutroUri(uri);
if(isSongAOutro){
songTypeLabel = "Outro";
}
var isSongAEarcon = Utils.getInstance().isEarconUri(uri);
if(isSongAEarcon){
songTypeLabel = "Earcon";
}
var isSongATrebbleGeneratedSpeech = Utils.getInstance().isTrebbleGeneratedSpeechUri(uri);
if(isSongATrebbleGeneratedSpeech){
songTypeLabel = "TrebbleGeneratedSpeech";
}
if(isSongAGreater || isSongAJingle || isSongAIntro || isSongAOutro || isSongAEarcon || isSongATrebbleGeneratedSpeech){
return false;
}


if(!songTypeLabel){
isSongASong = true;
songTypeLabel = "Song";
}
if(isSongASong){
var radioUIDs = songModelInPlayerQueue.get("radioUIDs");
return (radioUIDs && radioUIDs.length > 0)? true: false;
}
if(isSongACapsule){
var userRadioIds = this.getAllMyPersonalRadioIds();
var airingOnRadiosInfo =  songModelInPlayerQueue.get("airingOnRadios");
if(airingOnRadiosInfo){
    for(let i =0; i < airingOnRadiosInfo.length; i++){
        var radioInfo = airingOnRadiosInfo[i];
        if(radioInfo){
            var radioId = radioInfo.radioUID;
            if(userRadioIds.indexOf(radioId) !== -1){
                return !!radioInfo.addedDate;
            }
        }
    }
}
}
return false;
},

getCapsuleAddedDateForUserTrebbles : function(capsuleJsonOrModel){
return Utils.getInstance().getCapsuleDataForRadioIds(capsuleJsonOrModel, this.getAllMyPersonalRadioIds(), "addedDate");
},



notifyUserOfUnnotifiedOwnerComments : function(songModel, context, songRefSummary){
var songJsonForBackend = Utils.getInstance().getSongJsonForBackendFromSongModel(songModel);
var songUri = songJsonForBackend.uri;
if(this._songUriWithNotifiedComments.indexOf(songUri) == -1){
Utils.getInstance().triggerShortVibration();
Utils.getInstance().triggerShortVibration();
if(window.trebbleAnalyticsHelper){
    window.trebbleAnalyticsHelper.trackEvent('Player Page Events', 'User Notification For Owner Comments On Song Playing', 'User Notification For Owner Comments On Song Playing Sent');
}
return TrebbleClientAPI.getInstance().markSongCommentsAsNotified( songJsonForBackend, context, songRefSummary).then((function(){
    this._songUriWithNotifiedComments.push(songUri);
}).bind(this));
}else{
return RSVP.Promise.resolve();
}
},

deleteCapsule : function(capsuleModelToDelete, context){
if(capsuleModelToDelete){
var capsuleId = capsuleModelToDelete.getCapsuleId();
var songsUpdated = [];
return TrebbleClientAPI.getInstance().deleteSongCapsule(capsuleId, context).then((function(result){
    if(capsuleModelToDelete && result){
        var songURi = result.songsURIAttachedToCapsule && result.songsURIAttachedToCapsule.length > 0 ? result.songsURIAttachedToCapsule[0]:null;
        var capsuleDeletedInfo = result;
        if(capsuleDeletedInfo){
            var capsuleUID = capsuleDeletedInfo.capsuleUID;
            var capsuleInfo = this._capsuleIdToCapsuleInfo[capsuleUID];
            delete this._capsuleIdToCapsuleInfo[capsuleUID];
            if(capsuleDeletedInfo.songsURIAttachedToCapsule && capsuleDeletedInfo.songsURIAttachedToCapsule.length > 0 ){
                for(let index in capsuleDeletedInfo.songsURIAttachedToCapsule){
                    var songURIAttachedToCapsule = capsuleDeletedInfo.songsURIAttachedToCapsule[index];
                    var songModel =  this.songCollection.get(songURIAttachedToCapsule);

                    if(songModel){
                        if(!songModel.get("capsuleUIDs")){
                            songModel.set("capsuleUIDs",[]);
                        }
                        if(songModel.get("capsuleUIDs")){
                            var capsuleIndex = songModel.get("capsuleUIDs").indexOf(capsuleUID);
                            if(capsuleIndex != -1){
                                songModel.get("capsuleUIDs").splice(capsuleIndex, 1);
                            }

                        }
                        var currentCapsuleUIDs = songModel.get("capsuleUIDs");
                        songModel.set("hasCapsules", (currentCapsuleUIDs && currentCapsuleUIDs.length > 0)? true: false);
                        songsUpdated.push(songModel);
                    }

                }
            }
        }
        Utils.getInstance().showLongToastMessage(window.getI18n(ti18n, "CAPSULE_WAS_SUCCESSFULLY_DELETED"));
    }else{
        Utils.getInstance().showWarningNotification(window.getI18n(ti18n, "CAPSULE_HAS_NOT_BEEN_SUCCESSFULLY_DELETED"));
    }
}).bind(this)).then((function(){
    var promises =  [];
    if(songsUpdated){
        for(let index in songsUpdated){
            var p = TrebbleIndexDBHelper.getInstance().saveSong(songsUpdated[index].toJSON(),null, false, true, null, true, false);
        }
    }
    return RSVP.Promise.all(promises);
}).bind(this));
}else{
return RSVP.Promise.resolve();
}
},

//Public
getAlbumModelByAlbumIdFromMyLibrary : function(albumId){
return  this.albumCollection.get(albumId);

},

//Public
getArtistModelByArtistIdFromMyLibrary : function(artistId){
return  this.artistCollection.get(artistId);
},

//Public
getSongModelBySongIdFromMyLibraryOrFavorites : function(songId){
var songFound = this.songCollection.get(songId);
return songFound;
},

isInUserCollection : function(songModel){
return  !!this.getSongModelBySongIdFromMyLibraryOrFavorites(songModel.get("uri"));
},

getSongModelsBySongIdsFromMyLibraryOrFavorites : function(songIds){
var songModelFounds = [];
for(let index in songIds){
var songURi = songIds[index];
var songFound = this.getSongModelBySongIdFromMyLibraryOrFavorites(songURi);
if(songFound){
    songModelFounds.push(songFound);
}
}
return songModelFounds;
},

getSongContructorFunctionForCollection: function(){
var persistentModelRef =  this;
return function(attrs, options){
if(attrs){
    var uri = (attrs instanceof Backbone.Model)? attrs.get("uri"): attrs.uri;
    var exisitingModelInLibraryOrFavorite = persistentModelRef.getSongModelBySongIdFromMyLibraryOrFavorites(uri);
    if(exisitingModelInLibraryOrFavorite){
        return exisitingModelInLibraryOrFavorite;
    }else{
        return Utils.getInstance().getModelFromSongJson(attrs, options);
    }
}else{
    return Utils.getInstance().getModelFromSongJson(attrs, options);
}
}
},



setRouter : function(router){
this.router = router;
},

getPlayer : function(){
return this.playerModel;
},

getSongCollection : function(){
return this.songCollection;
},

getAlbumCollection : function(){
return this.albumCollection;
},

getArtistCollection : function(){
return this.artistCollection;
},

getRouter : function(){
return this.router;
},


});

    var persitentModels = new PersitentModelsModel();
    if(window.trebble && window.trebble.globalHelpers){
		window.trebble.globalHelpers.persitentModels = persitentModels;
	}

    export default {
		getInstance : function() {
			return persitentModels;
		}
	};