import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import DynamicPageView from "views/common/DynamicPageView";
import Utils from "models/helper/Utils";
import ti18n from "i18n!nls/UserChangePasswordi18n";
import PersistentModels from "services/PersistentModels";
import ChangePasswordPageTemplate from 'text!../../../templates/user/ChangePasswordPageTemplate.html';
import Mousetrap from 'mousetrap';

const ChangePassowrdPageView = DynamicPageView.extend({

    //template : _.template(ExplorePageTemplate),

initialize : function(options) {
    const dynamicPageOptions = {};
    this.model =  options? options.model: {};
    this._pageId = (options && options.pageId)? options.pageId:"changePasswordPage";
    dynamicPageOptions.pageViewTemplate = ChangePasswordPageTemplate;
    dynamicPageOptions.model = this.model;
    dynamicPageOptions.pageId = this._pageId;
    dynamicPageOptions.headerFullscren = true;
    dynamicPageOptions.persistent = true;
    dynamicPageOptions.onPageShow = this.onPageShow.bind(this);
    dynamicPageOptions.pageTemplateOptions = {"ti18n": ti18n};
    //PersistentModels.getInstance().getRouter().registerForViewportHeightChange(this._pageId,this._onViewportHeightChanged.bind(this));
    return DynamicPageView.prototype.initialize.call(this, dynamicPageOptions);  
},

events : {
    "click #header-back-button" : "navigateToPreviousPage",
    "click #saveButtonIcon:not(.disabled)" : "saveChanges",
},

saveChanges : function(){
    this.trigger("saveChanges", this.getPasswordData());
    this.setSaveButtonEnabled(false);
},


getPasswordData: function(){
    const data = {};
    data.currentPassword  = this.currentPasswordField$el.get(0).value;
    data.newPassword  = this.newPassswordField$el.get(0).value;
    data.reenterNewPassword  = this.reenterNewPassswordField$el.get(0).value;
    return data;
},

onFieldValueChanged : function(event){
    if(this.isAllMandatoryFieldsFilled()){
        this.setSaveButtonEnabled(true);
    }else{
        this.setSaveButtonEnabled(false);
    }
},

isAllMandatoryFieldsFilled : function(){
    const currentPasswordValid = this.validateCurrentPasswordField();
    const passwordValid = this.validatePasswordField();
    return currentPasswordValid && passwordValid;
    
},

validateReenterPasswordField : function(){
    const passwordDecorator = this.newPassswordFieldDec$el.get(0);
    const passwordInput = this.newPassswordField$el.get(0);
    const reenterPasswordDecorator = this.reenterNewPassswordFieldDec$el.get(0);
    const reenterPasswordInput =  this.reenterNewPassswordField$el.get(0);
    const pass2 = reenterPasswordInput.value;
    const pass1 = passwordInput.value;
    const arePasswordTheSame = pass1 === pass2;
    if (!arePasswordTheSame) {
        const errorMessage = window.getI18n(ti18n, "PASSWORD_DONT_MATCH");
        //reenterPasswordInput.setCustomValidity(errorMessage);
         this.reenterNewPassswordFieldDec$el.find("label").attr("data-error",errorMessage);
        if(!this.reenterNewPassswordField$el.hasClass("invalid"))
        {
            this.reenterNewPassswordField$el.addClass("invalid");
        }
    } else {
        // empty string means no validation error
        this.reenterNewPassswordField$el.removeClass("invalid");
        //reenterPasswordInput.setCustomValidity('');
        this.reenterNewPassswordFieldDec$el.find("label").attr("data-error","");
    }
    //passwordDecorator.isInvalid = !passwordInput.validity.valid;
    //reenterPasswordDecorator.isInvalid = !reenterPasswordInput.validity.valid;
    return arePasswordTheSame;
},

validatePasswordField : function(){
    const passwordDecorator = this.newPassswordFieldDec$el.get(0);
    const passwordInput = this.newPassswordField$el.get(0);
    const pass1 = passwordInput.value;
    const re = /^.{6,20}$/;
    const isNewPasswordValid = re.test(pass1);
    if (!isNewPasswordValid) {
        const errorMessage = window.getI18n(ti18n, "PASSWORD_MUST_BE_BETWEEN_X_CHARS");
        //passwordInput.setCustomValidity(errorMessage);
        this.newPassswordFieldDec$el.find("label").attr("data-error",errorMessage);
        if(!this.newPassswordField$el.hasClass("invalid"))
        {
            this.newPassswordField$el.addClass("invalid");
        }
    } else {
        // empty string means no validation error
        this.newPassswordField$el.removeClass("invalid");
        //passwordInput.setCustomValidity('');
        this.newPassswordFieldDec$el.find("label").attr("data-error","");
    }
    //passwordDecorator.isInvalid = !passwordInput.validity.valid;
    const reenterValid = this.validateReenterPasswordField();
    return isNewPasswordValid && reenterValid;
},


validateCurrentPasswordField : function(){
    const passwordInput = this.currentPasswordField$el.get(0);
    const passwordDecorator = this.currentPasswordFieldDec$el.get(0);
    const pass1 = passwordInput.value;
    if (!pass1) {
        const errorMessage = window.getI18n(ti18n, "CURRENT_PASSWORD_CANNOT_BE_EMPTY");
        //passwordInput.setCustomValidity(errorMessage);
        this.currentPasswordFieldDec$el.find("label").attr("data-error",errorMessage);
        if(!this.currentPasswordField$el.hasClass("invalid"))
        {
            this.currentPasswordField$el.addClass("invalid");
        }
    } else {
        // empty string means no validation error
        this.currentPasswordField$el.removeClass("invalid");
        //passwordInput.setCustomValidity('');
        this.currentPasswordFieldDec$el.find("label").attr("data-error","");
    }
    //passwordDecorator.isInvalid = !passwordInput.validity.valid;
    return pass1;
},




navigateToPreviousPage : function(){
    PersistentModels.getInstance().getRouter().navigateToPreviousPage();
},

onBeforePageInitialized : function(){

},

setSaveButtonEnabled : function(enabled){
    if(enabled){
        this.saveButton$el.removeClass("disabled");
    }else{
        if(!this.saveButton$el.hasClass("disabled")){
            this.saveButton$el.addClass("disabled");
        }
    }
},


onAfterPageInitialized : function(){

    this.saveButton$el  = this.$el.find("#saveButtonIcon")
    this.currentPasswordField$el = this.$el.find("#currentpasswordField");
    this.newPassswordField$el = this.$el.find("#passwordField");
    this.reenterNewPassswordField$el = this.$el.find("#reenterPasswordField");
    this.currentPasswordFieldDec$el = this.$el.find("#currentpasswordFieldDecId");
    this.newPassswordFieldDec$el = this.$el.find("#passwordDecId");
    this.reenterNewPassswordFieldDec$el = this.$el.find("#reenterPasswordDecId");
    this.$el.find("#passwordChangeFormWrapper").on("click blur keyup input", this.onFieldValueChanged.bind(this));
    this.onFieldValueChanged();
    this.setSaveButtonEnabled(false);
    this.$el.on("pagehide", this._onPageHide.bind(this));
    this._mousetrapObj = Mousetrap(this.$el.find("#passwordChangeFormWrapper").get(0)).bind('enter', (function(e, combo) {
        this.saveChanges();
    }).bind(this));
    //this.currentPasswordField$el.get(0).validate = this.validateCurrentPasswordField.bind(this);
    //this.newPassswordField$el.get(0).validate = this.validateReenterPasswordField.bind(this);
    //this.reenterNewPassswordField$el.get(0).validate = this.validatePasswordField.bind(this);


},

onPageShow : function(){
    this.trigger("pageShow");
},
onBeforeRemoveFromPage : function(){
    DynamicPageView.prototype.onBeforeRemoveFromPage.call(this); 
    PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._pageId);
},

    });
export default ChangePassowrdPageView;