

import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import AudioBufferRegionSelectorController from "controllers/audioEditor/AudioBufferRegionSelectorController";
import SelectAudioRegionPopupView from "views/audioEditor/SelectAudioRegionPopupView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import PlaylistHelper from "models/helper/PlaylistHelper";
import TrebbleAudio from "models/TrebbleAudio";
import FileUploadHelper from "models/helper/FileUploadHelper";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import TrebbleBackgroundMusicStoreHelper from "models/helper/TrebbleBackgroundMusicStoreHelper";
import ti18n from "i18n!nls/ConfigureBackgroundMusici18n";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const Rollout = RolloutHelper.getInstance();

//const CONFIGURE_BACKGROUND_MUSIC = Rollout.isFeatureEnabled(Rollout.FEATURES.CONFIGURE_BACKGROUND_MUSIC);

const SelectAudioRegionPopupController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this._audioSegmentJson = options.audioSegmentJson;
        this._audioUniqueId = options.audioUniqueId;
        this._audioType = options.audioType;
        this._sequencerSettings = options.sequencerSettings;
        this._onRegionUpdated = options.onRegionUpdated;
        this._onRegionNotUpdated = options.onRegionNotUpdated;
        this._saveButtonWasClicked = false;

    },


    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    onPopupClosed : function(){
        if(this._saveButtonWasClicked){
            if(this._onRegionUpdated){
                this._onRegionUpdated(this.audioBufferRegionSelectorController ? this.audioBufferRegionSelectorController.getStartTime(): null, this.audioBufferRegionSelectorController ? this.audioBufferRegionSelectorController.getEndTime(): null);
            }
        }else{
            if(this._onRegionNotUpdated){
                this._onRegionNotUpdated();
            }
        }
        this.audioBufferRegionSelectorController.stopPlayback();
        this.audioBufferRegionSelectorController.destroyWaveSurferInstance();

    },


    onRegionChangeSaved : function(){
        this._saveButtonWasClicked = true;
        this.view.close();
    },

    _buildAudioBufferRegionSelectorController : function(){
        const params  = {"audioSegmentJson": this._audioSegmentJson, "sequencerSettings": this._sequencerSettings, "onRegionChangeSaved": this.onRegionChangeSaved.bind(this),"audioUniqueId":this._audioUniqueId, "audioType": this._audioType};
        const audioBufferRegionSelectorController = new AudioBufferRegionSelectorController(params);
        this.audioBufferRegionSelectorController = audioBufferRegionSelectorController;
        return this.audioBufferRegionSelectorController.buildModelAndRenderView();
    },

    _onSaveTrimButtonClicked : function(){

    },

    
    buildModelAndRenderView : function() {
        const promises = [];
        promises.push(this._buildAudioBufferRegionSelectorController());
        
        return RSVP.Promise.all(promises).then((function(){
            const params = {};
            params.audioBufferRegionSelectorView = this.audioBufferRegionSelectorController.getView();
            this.view = new SelectAudioRegionPopupView(params);
            this.listenTo(this.view, "closed", this.onPopupClosed);

            this.view.render();

        }).bind(this))


    }

});

export default SelectAudioRegionPopupController;