import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import OnboardingListenerPageView from "views/onboarding/OnboardingListenerPageView";
import CordovaHelper from "models/helper/CordovaHelper";
import PlaylistHelper from "models/helper/PlaylistHelper";
import PersistentModels from "services/PersistentModels";
import CustomSelectFieldController from "controllers/common/CustomSelectFieldController";
import Utils from "models/helper/Utils";
import ListItemCollection from "collections/ListItemCollection";
import CollectionInfiniteCarouselController from "controllers/common/CollectionInfiniteCarouselController";
import TrebbleCardController from "controllers/playlistPodcast/TrebbleCardController";
import CollectionRepeatController from "controllers/common/CollectionRepeatController";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import GoogleMapHelper from "models/helper/GoogleMapHelper";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import LiveChatWidgetHelper from "models/helper/LiveChatWidgetHelper";
import ti18n from "i18n!nls/OnboardingListenerPagei18n";
import Utilsi18n from "i18n!nls/Utilsi18n";
import PlayerService from "services/PlayerService";
import FacebookEventTrackingHelper from "models/helper/FacebookEventTrackingHelper";
import RSVP from "rsvp";

const ALL_GENRE_ID = "ALL_GENRES";
const ALL_GENRE_LABEL = window.getI18n(ti18n, "ALL_GENRE_LABEL") + "<span class='icon ion-android-earth'></span>" ;
const TRENDING_ID = "TRENDING";
const TRENDING_LABEL = window.getI18n(ti18n, "TRENDING_LABEL");
const FEATURED_ID = "FEATURED";
const FEATURED_LABEL = window.getI18n(ti18n, "FEATURED_LABEL") + "<span class='icon ion-ribbon-b'></span>" ;
const RECENTLY_ADDED_ID = "RECENTLY_ADDED";
const RECENTLY_ADDED_LABEL = window.getI18n(ti18n, "RECENTLY_ADDED_LABEL") +  "<span class='icon ion-ios7-timer'></span>" ;
const MY_SUBSCRIPTIONS_ID = "MY_SUBSCRIPTIONS";
const MY_SUBSCRIPTIONS_LABEL = window.getI18n(ti18n, "MY_SUBSCRIPTIONS_LABEL");
const ALL_LANGUAGE_ID = "ALL_LANGUAGE";
const ALL_LANGUAGE_LABEL = window.getI18n(ti18n, "ALL_LANGUAGE_LABEL");
const LOCAL_ID = Utils.getInstance().getLocalCategoryId();
const LOCAL_LABEL = window.getI18n(ti18n, "LOCAL_LABEL") + "<span class='icon ion-ios7-location'></span>" ;
const SPECIAL_CATEGORY_IDS = []
const SPECIAL_CATEGORY_SELECTIONS_INFO = [];

const Rollout = RolloutHelper.getInstance();
const SHOW_RECENTLY_ADDED =  Rollout.isFeatureEnabled(Rollout.FEATURES.SHOW_RECENTLY_ADDED_IN_BROWSE);
const SHOW_RECENTLY_ADDED_IN_ONBOARDING = Rollout.getFeatureVariable(Rollout.FEATURES.SHOW_RECENTLY_ADDED_IN_BROWSE, Rollout.FEATURES.SHOW_RECENTLY_ADDED_IN_BROWSE.variables.show_in_onboarding, true);
const NUMBER_OF_RESULTS_IN_RECENTLY_ADDED = Rollout.getFeatureVariable(Rollout.FEATURES.SHOW_RECENTLY_ADDED_IN_BROWSE, Rollout.FEATURES.SHOW_RECENTLY_ADDED_IN_BROWSE.variables.number_of_results, 25);
const SHOW_FEATURED_ONLY_IN_RECENTLY_ADDED = Rollout.getFeatureVariable(Rollout.FEATURES.SHOW_RECENTLY_ADDED_IN_BROWSE, Rollout.FEATURES.SHOW_RECENTLY_ADDED_IN_BROWSE.variables.show_featured_only, true);


SPECIAL_CATEGORY_SELECTIONS_INFO.push(Utils.getInstance().getListItemModel(ALL_GENRE_ID,  ALL_GENRE_LABEL));
//SPECIAL_CATEGORY_SELECTIONS_INFO.push(Utils.getInstance().getListItemModel(MY_SUBSCRIPTIONS_ID,  MY_SUBSCRIPTIONS_LABEL));
SPECIAL_CATEGORY_SELECTIONS_INFO.push(Utils.getInstance().getListItemModel(FEATURED_ID,  FEATURED_LABEL));
if(SHOW_RECENTLY_ADDED && SHOW_RECENTLY_ADDED_IN_ONBOARDING){
    SPECIAL_CATEGORY_SELECTIONS_INFO.push(Utils.getInstance().getListItemModel(RECENTLY_ADDED_ID,  RECENTLY_ADDED_LABEL));
}
SPECIAL_CATEGORY_SELECTIONS_INFO.push(Utils.getInstance().getListItemModel(LOCAL_ID,  LOCAL_LABEL));

SPECIAL_CATEGORY_IDS.push(ALL_GENRE_ID);
SPECIAL_CATEGORY_IDS.push(FEATURED_ID);
if(SHOW_RECENTLY_ADDED && SHOW_RECENTLY_ADDED_IN_ONBOARDING){
    SPECIAL_CATEGORY_IDS.push(RECENTLY_ADDED_ID);
}
SPECIAL_CATEGORY_IDS.push(LOCAL_ID);



const SPECIAL_LANGUAGE_SELECTIONS_INFO = [];
SPECIAL_LANGUAGE_SELECTIONS_INFO.push(Utils.getInstance().getListItemModel(ALL_LANGUAGE_ID,  ALL_LANGUAGE_LABEL));
const DEFAULT_TO_ALL_CATEGORIES_AND_ALL_LANGUAGES = Rollout.getFeatureVariable(Rollout.FEATURES.BASE_CUSTOMIZATION_FOR_BROWSE_PAGE, Rollout.FEATURES.BASE_CUSTOMIZATION_FOR_BROWSE_PAGE.variables.use_default_language_in_filter_if_not_using_all_languages, false);
const THRESHOLD_TO_ENCOURAGE_USER_TO_CHANGE_CATEGORY_OR_LANGUAGE = Rollout.getFeatureVariable(Rollout.FEATURES.PERSONALIZE_BROWSE_PAGE_ON_WEB, Rollout.FEATURES.PERSONALIZE_BROWSE_PAGE_ON_WEB.variables.min_number_of_swipes_to_suggest_category_change, 6);
//const THRESHOLD_TO_ENCOURAGE_USER_TO_PLAY_FEED_FOR_FIRST_TIME = 9;
const THRESHOLD_TO_ASK_USER_TO_GIVE_RECOMMENDATION_ON_CONTENT =  Rollout.getFeatureVariable(Rollout.FEATURES.PERSONALIZE_BROWSE_PAGE_ON_WEB, Rollout.FEATURES.PERSONALIZE_BROWSE_PAGE_ON_WEB.variables.min_number_of_swipers_to_ask_shortcast_user_interested_in, 12);
//This is limit is iOS on because there is an issue which cause the app to crash 
//when there are too many itmems in the swiper carousel. It usually crash after 50 items
const LIMIT_THE_NUMBER_OF_ITEMS_ON_IOS = Rollout.getFeatureVariable(Rollout.FEATURES.PERSONALIZE_BROWSE_PAGE_ON_IOS_APP, Rollout.FEATURES.PERSONALIZE_BROWSE_PAGE_ON_IOS_APP.variables.limit_the_number_of_shortcast_displayed, true);
const MAX_NUMBER_OF_ITEMS_TO_DISPLAY_ON_IOS = Rollout.getFeatureVariable(Rollout.FEATURES.PERSONALIZE_BROWSE_PAGE_ON_IOS_APP, Rollout.FEATURES.PERSONALIZE_BROWSE_PAGE_ON_IOS_APP.variables.max_number_of_shortcasts_to_displayed, 30);
const MAKE_DEFAULT_LANGUAGE_GENERIC_IF_SETTING_A_SPECIFIC_LANGUAGE_BY_DEFAULT = Rollout.getFeatureVariable(Rollout.FEATURES.BASE_CUSTOMIZATION_FOR_BROWSE_PAGE, Rollout.FEATURES.BASE_CUSTOMIZATION_FOR_BROWSE_PAGE.variables.display_all_categories_and_languages_by_default, true);
const MAX_NUMBER_OF_SUBSCRIPTIONS_TO_SUGGEST_USER_TO_CHANGE_CATEGORIES = Rollout.getFeatureVariable(Rollout.FEATURES.SUGGEST_CATEGORY_CHANGE_IN_LIST_VIEW_IN_BROWSE_PAGE, Rollout.FEATURES.SUGGEST_CATEGORY_CHANGE_IN_LIST_VIEW_IN_BROWSE_PAGE.variables.max_number_of_subscriptions_required_to_suggest, 3);
const NUMBER_OF_SEC_TO_WAIT_BEFORE_SUGGESTING_TO_CHANGE_CATEGORIES = Rollout.getFeatureVariable(Rollout.FEATURES.SUGGEST_CATEGORY_CHANGE_IN_LIST_VIEW_IN_BROWSE_PAGE, Rollout.FEATURES.SUGGEST_CATEGORY_CHANGE_IN_LIST_VIEW_IN_BROWSE_PAGE.variables.time_in_sec_before_displaying_popup, 60);
const MAX_NUMBER_OF_SUBSCRIPTIONS_TO_ASK_FOR_SHORTCAST_INTEREST_IN = Rollout.getFeatureVariable(Rollout.FEATURES.ASK_FOR_SHORTCASTS_USER_IS_INTERESTED_IN, Rollout.FEATURES.ASK_FOR_SHORTCASTS_USER_IS_INTERESTED_IN.variables.max_number_of_subscriptions_required_to_ask, 4);
const NUMBER_OF_SEC_TO_WAIT_BEFORE_ASK_SINCE_SUGGESTION_POPUP = Rollout.getFeatureVariable(Rollout.FEATURES.ASK_FOR_SHORTCASTS_USER_IS_INTERESTED_IN, Rollout.FEATURES.ASK_FOR_SHORTCASTS_USER_IS_INTERESTED_IN.variables.time_in_sec_before_displaying_popup_since_suggestion, 60);

const NUMBER_OF_MINIMUN_SUBS_TO_COMPLETE_SETUP = Rollout.getFeatureVariable(Rollout.FEATURES.DISPLAY_FEED_SETUP_AFTER_SIGNUP, Rollout.FEATURES.DISPLAY_FEED_SETUP_AFTER_SIGNUP.variables.number_of_subs_to_complete_setup, 1);
const NUMBER_OF_MINIMUN_JOURNEY_SUBS_TO_COMPLETE_SETUP = Rollout.getFeatureVariable(Rollout.FEATURES.DISPLAY_FEED_SETUP_AFTER_SIGNUP, Rollout.FEATURES.DISPLAY_FEED_SETUP_AFTER_SIGNUP.variables.number_of_journey_subs_to_complete_setup, 1);

const OnboardingPageController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if (options) {

        }
        this._presetCategoryId = options && options.presetCategoryId? options.presetCategoryId : null;
        this.genreSelectFieldController = this._buildGenreSelectFieldController();
        this.languageSelectFieldController = this._buildLanguageSelectFieldController();
        this.citySelectFieldController = this._buildCitySelectFieldController();
        this.listenTo(this.genreSelectFieldController, "selectionChanged",this._onGenreSelectionChanged.bind(this) );
        this.listenTo(this.languageSelectFieldController, "selectionChanged",this._onLanguageSelectionChanged.bind(this) );
        this.listenTo(this.citySelectFieldController, "selectionChanged",this._onCitySelectionChanged.bind(this) );
        this.listenTo(PersistentModels.getInstance(), "subscribeToTrebble",this._onUserSubscribeToTrebble.bind(this) );
        this.listenTo(PersistentModels.getInstance(), "subscriptionChanged",this._onNumberOfUserSubscriptionChanged.bind(this) );
        
        this.listenTo(LocalStorageHelper.getInstance(), LocalStorageHelper.getInstance().eventTypes.SUBSCRIBE_TOOLTIP_DISCARDED_BY_USER,this._onSubscribreTooltipDiscardedByUser.bind(this) );
        this._currentPlaylistSummaryModel = null;
        this._currentGenreInfoListItemModel = null;
        this._languageToCategoryIdToNumberOfTrebbleMap = {};
        this._languageToCityIdToNumberOfTrebbleAndCityInfoMap = {};
        this._numberOfTimeSlidesWereChanged = 0;
        this._numberOfTimeSlidesWereChangedToSubscribeToFirstTrebble = 0;
        this._displayAsCarousel = false
        this._didUserChangeCategories = false;
        this._didUserChangeLanguage = false;
        this._didUserSubscribeToATrebble = false;
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    showHomePopup : function(){
        PersistentModels.getInstance().getRouter().showHomePage();
    },

    _onSubscribreTooltipDiscardedByUser : function(){
        this.view.hideAllSubcribeTooltips();
    },

    _onUserSubscribeToTrebble : function(){
        if(!this._didUserSubscribeToATrebble){
            this._numberOfTimeSlidesWereChangedToSubscribeToFirstTrebble = this._numberOfTimeSlidesWereChanged;
        }
        this._didUserSubscribeToATrebble = true;
    },

    _onNumberOfUserSubscriptionChanged : function(){
        this.view.setEnablePlayMyFeedForFirstTimeButton(PersistentModels.getInstance().getNumberOfSubscriptions() >= NUMBER_OF_MINIMUN_SUBS_TO_COMPLETE_SETUP || PersistentModels.getInstance().getNumberOfJourneySubscriptions() >= NUMBER_OF_MINIMUN_JOURNEY_SUBS_TO_COMPLETE_SETUP);
        this.view.updateGuidedMessageBox(PersistentModels.getInstance().getNumberOfSubscriptions() , NUMBER_OF_MINIMUN_SUBS_TO_COMPLETE_SETUP);
    },

    _buildGenreSelectFieldController : function(){
        const controllerParams = {};
        controllerParams.singleSelect =  true;
        controllerParams.propertyFunction = "getLabel";
        controllerParams.propertyIdFunction = "getId";
        controllerParams.showEmtptyInfoBox  = true;
        controllerParams.loadHandler = this.getAllTrebbleCategories.bind(this);
        controllerParams.defaultText = window.getI18n(ti18n, "CATEGORY");
        const customSelectFieldController = new CustomSelectFieldController(controllerParams);
        return customSelectFieldController;
    },

    _buildCitySelectFieldController : function(){
        const controllerParams = {};
        controllerParams.singleSelect =  true;
        controllerParams.propertyFunction = "getLabel";
        controllerParams.propertyIdFunction = "getId";
        controllerParams.showEmtptyInfoBox  = true;
        controllerParams.infoBoxParams  = this._buildCityInfoBoxParams();
        controllerParams.loadHandler = this.getAllCities.bind(this);
        controllerParams.defaultText = window.getI18n(ti18n, "CITY");
        controllerParams.enableSearch =  true;
        const customSelectFieldController = new CustomSelectFieldController(controllerParams);
        return customSelectFieldController;
    },

    _buildCityInfoBoxParams : function(){
        const params = {};
        params.iconClass = "pe-7s-map-marker";
        params.message = window.getI18n(ti18n, "NO_LOCATION_WAS_FOUND_MATCHING_YOUR_KEYWORD");
        //params.helpMessage = "Subscribe to trebbles you like and you will find them all here.";
        return params;
    },

    _buildLanguageSelectFieldController : function(){
        const controllerParams = {};
        controllerParams.singleSelect =  true;
        controllerParams.propertyFunction = "getLabel";
        controllerParams.propertyIdFunction = "getId";
        controllerParams.showEmtptyInfoBox  = true;
        controllerParams.loadHandler = this.getAllTrebbleLanguages.bind(this);
        controllerParams.defaultText = window.getI18n(ti18n, "LANGUAGE");
        const customSelectFieldController = new CustomSelectFieldController(controllerParams);
        return customSelectFieldController;
    },

    _getCategoryIdsForRadioWithContent : function(language){
        //THIS FUNCTION WILL CACHED THE RESULT AND WILL USE IT IN THE SAME SESSION UNTIL THE APP IS RELOADED. THIS DONE FOR PERFORMANCE REASON
        if(!this._languageToCategoryIdToNumberOfTrebbleMap[language]){
            return TrebbleClientAPIHelper.getInstance().getCategoryIdsForRadioWithContent(language).then((function(categoryIdToNumberOfTrebbleMap){
                this._languageToCategoryIdToNumberOfTrebbleMap[language] = categoryIdToNumberOfTrebbleMap;
                return categoryIdToNumberOfTrebbleMap;
            }).bind(this));
        }else{
            return RSVP.Promise.resolve(this._languageToCategoryIdToNumberOfTrebbleMap[language]);
        }
    },

    _getCityInfoForRadioWithContent : function(language){
        //THIS FUNCTION WILL CACHED THE RESULT AND WILL USE IT IN THE SAME SESSION UNTIL THE APP IS RELOADED. THIS DONE FOR PERFORMANCE REASON
        if(!this._languageToCityIdToNumberOfTrebbleAndCityInfoMap[language]){
            return TrebbleClientAPIHelper.getInstance().getCityInfoForRadioWithContentForSpecificLanguage(language, window.getAppUsedLanguage()).then((function(cityIdsToNumberOfTrebbleAndCityInfoMap){
                this._languageToCityIdToNumberOfTrebbleAndCityInfoMap[language] = cityIdsToNumberOfTrebbleAndCityInfoMap;
                return cityIdsToNumberOfTrebbleAndCityInfoMap;
            }).bind(this));
        }else{
            return RSVP.Promise.resolve(this._languageToCityIdToNumberOfTrebbleAndCityInfoMap[language]);
        }
    },

    getAllTrebbleCategories : function(){
        $.mobile.loading("show");
        const selectedLanguage =  (!this._currentLanguageInfoListItemModel || this._isAllLanguageListItem(this._currentLanguageInfoListItemModel))? null: this._currentLanguageInfoListItemModel.getId();
        return this._getCategoryIdsForRadioWithContent(selectedLanguage).then((function(categoryIdToNumberOfTrebbleMap){
            const availableCategories = [];
            const allCategoryListItemArray = Utils.getInstance().getCapsuleCategoryListItemArray();
            for(let i =0; i <  allCategoryListItemArray.length; i++){
                const categoryItem = allCategoryListItemArray[i];
                if(SPECIAL_CATEGORY_IDS.indexOf(categoryItem.getId()) == -1){
                    if(categoryItem.getId() in categoryIdToNumberOfTrebbleMap ){
                        availableCategories.push(categoryItem);
                    }
                }
            }
            $.mobile.loading("hide");
            const listItemCollection = new ListItemCollection(availableCategories);
            listItemCollection.sort();
            for(let i in SPECIAL_CATEGORY_SELECTIONS_INFO){
                listItemCollection.add(SPECIAL_CATEGORY_SELECTIONS_INFO[i],{"sort": false,"at": i});
            }
            return listItemCollection.models;

        }).bind(this)).catch((function(error){
            $.mobile.loading("hide");
            throw error;
        }).bind(this));
        

    },

    getAllCities : function(keyword){
        $.mobile.loading("show");
        const selectedLanguage =  (!this._currentLanguageInfoListItemModel || this._isAllLanguageListItem(this._currentLanguageInfoListItemModel))? null: this._currentLanguageInfoListItemModel.getId();
        return this._getCityInfoForRadioWithContent(selectedLanguage).then((function(cityIdToNumberOfTrebbleAndCityInfoMap){
            const cityListModelArray = [];
            for(let cityId in cityIdToNumberOfTrebbleAndCityInfoMap){
                const cInfo = cityIdToNumberOfTrebbleAndCityInfoMap[cityId];
                const cityInfo = cInfo.cityInfo;
                const cityDescription = cityInfo.description;
                if(!keyword || cityDescription.toLowerCase().indexOf(keyword.toLowerCase()) != -1){
                    cityListModelArray.push(Utils.getInstance().getListItemModel(cityInfo.place_id,cityDescription));
                }
            }
            $.mobile.loading("hide");
            const listItemCollection = new ListItemCollection(cityListModelArray);
            return listItemCollection.models;
        }).bind(this)).catch((function(error){
            $.mobile.loading("hide");
            throw error;
        }).bind(this));
        

    },

    getCities : function(keyword){
        if(keyword){
            $.mobile.loading("show");
            return TrebbleClientAPIHelper.getInstance().searchForCities(keyword,window.getAppUsedLanguage()).then((function(cityInfoArray){
                const cityListModelArray = [];
                for(let i =0; i <  cityInfoArray.length; i++){
                    const cityInfo = cityInfoArray[i];
                    cityListModelArray.push(Utils.getInstance().getListItemModel(cityInfo.place_id,cityInfo.description));
                }
                $.mobile.loading("hide");
                const listItemCollection = new ListItemCollection(cityListModelArray);
                return listItemCollection.models;
            }).bind(this)).catch((function(error){
                $.mobile.loading("hide");
                throw error;
            }).bind(this));
        }else{
            return RSVP.Promise.resolve([]);
        }
        

    },


    getAllTrebbleLanguages : function(){
        $.mobile.loading("show");
        const isLocalCategory =  this._currentGenreInfoListItemModel && this._currentGenreInfoListItemModel.getId() == Utils.getInstance().getLocalCategoryId();
        const selectedCategoryId =  (!this._currentGenreInfoListItemModel || (this._isSpecialGenereListItem(this._currentGenreInfoListItemModel) && !isLocalCategory))? null: this._currentGenreInfoListItemModel.getId();
        const selectedCityId =  this._currentCityInfoListItemModel? this._currentCityInfoListItemModel.getId(): null;
        const languageQueryPromise = (isLocalCategory)?   TrebbleClientAPIHelper.getInstance().getLanguagesForRadioWithContentForSpecificCity(selectedCityId): TrebbleClientAPIHelper.getInstance().getLanguagesForRadioWithContent(selectedCategoryId);
        return languageQueryPromise.then((function(languageToNumberOfTrebbleMap){
            let availableLanguages = [];
            const allLanguageListItemArray = Utils.getInstance().getLanguageListItemArray();
            for(let i =0; i <  allLanguageListItemArray.length; i++){
                const languageItem = allLanguageListItemArray[i];
                if(languageItem.getId() in languageToNumberOfTrebbleMap ){
                    availableLanguages.push(languageItem);
                }
            }
            const genericLanguagesItemArray = Utils.getInstance().getGenericLanguagesFromListItemArray(availableLanguages);
            availableLanguages = availableLanguages.concat(genericLanguagesItemArray);
            

            const listItemCollection = new ListItemCollection(availableLanguages);
            listItemCollection.sort();
            for(let i in SPECIAL_LANGUAGE_SELECTIONS_INFO){
                listItemCollection.add(SPECIAL_LANGUAGE_SELECTIONS_INFO[i],{"sort": false,"at": i});
            }
            $.mobile.loading("hide");
            return listItemCollection.models;

        }).bind(this)).catch((function(error){
            $.mobile.loading("hide");
            throw error;
        }).bind(this));


    },

    isGenreDifferentFromCurrentSelected: function(genreInfoListItemModel){
        return (this._currentGenreInfoListItemModel || genreInfoListItemModel) &&( 
            (!this._currentGenreInfoListItemModel && genreInfoListItemModel) || 
            (this._currentGenreInfoListItemModel && !genreInfoListItemModel) || 
            (this._currentGenreInfoListItemModel.getId() != genreInfoListItemModel.getId()));
    },

    isLanguageDifferentFromCurrentSelected: function(languageInfoListItemModel){
        return (this._currentLanguageInfoListItemModel || languageInfoListItemModel) &&( 
            (!this._currentLanguageInfoListItemModel && languageInfoListItemModel) || 
            (this._currentLanguageInfoListItemModel && !languageInfoListItemModel) || 
            (this._currentLanguageInfoListItemModel.getId() != languageInfoListItemModel.getId()));
    },

    _isGenreCustomGenre : function(genreInfoListItemModel){
        if(genreInfoListItemModel){
            const additionalData = genreInfoListItemModel.getAdditionalData();
            if(additionalData && additionalData.isCustomGenre){
                return true;
            }
        }else{
            return false;
        }
    },

    _isSpecialGenereListItem : function(genreInfoListItemModel){
        if(genreInfoListItemModel){
            for(let i in SPECIAL_CATEGORY_SELECTIONS_INFO){
                if(SPECIAL_CATEGORY_SELECTIONS_INFO[i].getId() === genreInfoListItemModel.getId()){
                    return true;
                }
            }
        }
        return false;

    },

    _isGenericLanguage: function(languageInfoListItemModel){
        return languageInfoListItemModel.getId().indexOf("-") == -1;
    },

    _isAllLanguageListItem : function(languageInfoListItemModel){
        if(languageInfoListItemModel){
            return ALL_LANGUAGE_ID === languageInfoListItemModel.getId();
        }
        return false;

    },

    _isAllGenreListItem : function(genreInfoListItemModel){
        if(genreInfoListItemModel){
            return ALL_GENRE_ID === genreInfoListItemModel.getId();
        }
        return false;

    },

    hideShowCityFieldIfApplicable : function(){
        //const trebbleCategory = this.trebbleCategoryField$el.val();
        if(this.genreSelectFieldController._getModelId(this.genreSelectFieldController.getSelectedModel()) == Utils.getInstance().getLocalCategoryId()){
            this.citySelectFieldController.getView().$el.show();
            this.view.setThreeSelectorDisplayed(true);
        }else{
            this.citySelectFieldController.getView().$el.hide();
            this.view.setThreeSelectorDisplayed(false);
        }

    },

    _onGenreSelectionChanged : function(genreInfoListItemModel){
        this.hideShowCityFieldIfApplicable();
        if(genreInfoListItemModel && genreInfoListItemModel.getId() == LOCAL_ID){
            this._currentGenreInfoListItemModel = genreInfoListItemModel;
            this.citySelectFieldController.onSelectFieldClicked();
        }else{
            this._currentCityInfoListItemModel = null;
            if(this.isGenreDifferentFromCurrentSelected(genreInfoListItemModel)){
                this._lastSelectedCategoryId = genreInfoListItemModel ? genreInfoListItemModel.getId():null;
                this._lastSelectedCategoryLabel = genreInfoListItemModel? genreInfoListItemModel.getLabel():null;
                this._didUserChangeCategories = true;
                this._currentGenreInfoListItemModel = genreInfoListItemModel;
                this._updateRadioResults();
            }
        }
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("OnboardingPage", 'OnCategorySelectionChanged',"On Category Selection Changed", null, {"categorySelectedIs": genreInfoListItemModel && genreInfoListItemModel.getId? genreInfoListItemModel.getId():null});
            //window.trebbleAnalyticsHelper.trackEvent("OnboardingPage", 'categorySelectedIs'+genreInfoListItemModel && genreInfoListItemModel.getId? genreInfoListItemModel.getId():null, genreInfoListItemModel && genreInfoListItemModel.getId? genreInfoListItemModel.getId():null);
        }
    },


    _onCitySelectionChanged : function(cityInfoListItemModel){
        this._currentCityInfoListItemModel = cityInfoListItemModel;
        if(cityInfoListItemModel){
            this._updateRadioResults();
        }else{
            this.genreSelectFieldController.setSelected(Utils.getInstance().getListItemModel(this._lastSelectedCategoryId,this._lastSelectedCategoryLabel), true);
        }
        /*if(this.isGenreDifferentFromCurrentSelected(genreInfoListItemModel)){
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("OnboardingPage", 'OnCategorySelectionChanged', genreInfoListItemModel && genreInfoListItemModel.getId? genreInfoListItemModel.getId():null);
            }
            this._didUserChangeCategories = true;
            this._currentGenreInfoListItemModel = genreInfoListItemModel;
            this._updateRadioResults();
        }*/
    },

    _onLanguageSelectionChanged : function(languageInfoListItemModel, doNotUpdateResults){
        if(this.isLanguageDifferentFromCurrentSelected(languageInfoListItemModel)){
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("OnboardingPage", 'OnLanguageSelectionChanged',"On Language Selection Changed", null,{"languageSelectedId":languageInfoListItemModel && languageInfoListItemModel.getId? languageInfoListItemModel.getId():null});
                //window.trebbleAnalyticsHelper.trackEvent("OnboardingPage", 'languageSelectedId', languageInfoListItemModel && languageInfoListItemModel.getId? languageInfoListItemModel.getId():null);
            }
            this._currentLanguageInfoListItemModel = languageInfoListItemModel;
            this._didUserChangeLanguage = true;
            if(!doNotUpdateResults){
                this._updateRadioResults();	
            }
        }
    },

    _updateRadioResults : function(){
        if(this._displayAsCarousel){
            this._updateRadioResultsAsCarousel();
        }else{
            this._updateRadioResultsAsList();
        }
    },

    _updateRadioResultsAsCarousel : function(){
        if(this.collectionInfiniteCarouselController){
            this.collectionInfiniteCarouselController.destroy();
            this.collectionInfiniteCarouselController = null;
        }
        const controllerParams = {};
        if(this._currentGenreInfoListItemModel && !this._isSpecialGenereListItem(this._currentGenreInfoListItemModel) && !this._isGenreCustomGenre(this._currentGenreInfoListItemModel)){
            const tuneOptions= [];
            tuneOptions.push(this._currentGenreInfoListItemModel.getId());
            controllerParams.tuneOptions = tuneOptions;
        }else{
            controllerParams.tuneOptions = null
        }
        controllerParams.customImageUrlProperty = "data-src";
        const collectionRepeatControllerParams = {};
        collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildInfoBoxParams();
        collectionRepeatControllerParams.showEmtptyInfoBox = true;
        collectionRepeatControllerParams.controllerOption = controllerParams;
        collectionRepeatControllerParams.itemControllerConstructor = TrebbleCardController;
        collectionRepeatControllerParams.onSlideChangeHandler = this.onActiveTrebbleChanged.bind(this);
        collectionRepeatControllerParams.vertical = false;//window.isMobileBrowser;
        collectionRepeatControllerParams.itemWidth = collectionRepeatControllerParams.vertical?100:450;
        collectionRepeatControllerParams.itemWidthUnit = collectionRepeatControllerParams.vertical?"%":"px";
        collectionRepeatControllerParams.itemHeight = collectionRepeatControllerParams.vertical?390:100
        collectionRepeatControllerParams.itemHeightUnit = collectionRepeatControllerParams.vertical?"px":"%";
        collectionRepeatControllerParams.modelLoadHandler = this._getTrebbleLoadHanderForGenreAndLanguage(this._currentGenreInfoListItemModel, this._currentLanguageInfoListItemModel);
        if(LIMIT_THE_NUMBER_OF_ITEMS_ON_IOS){
            if(window.waitForCordovaToLoad && window.isIOSMobileDevice){
                collectionRepeatControllerParams.initMaxNumberOfItemsToLoad = MAX_NUMBER_OF_ITEMS_TO_DISPLAY_ON_IOS;
            }
        }

        /*if(options.context){
            collectionRepeatControllerParams.context = options.context;
        }*/
        this.collectionInfiniteCarouselController = new CollectionInfiniteCarouselController(collectionRepeatControllerParams);
        this.collectionInfiniteCarouselController.buildModelAndRenderView().then((function(){
            this.view.setCarouselView(this.collectionInfiniteCarouselController.getView());
        }).bind(this));
    },

    _updateRadioResultsAsList : function(){
        const controllerParams = {};
        controllerParams.customImageUrlProperty = "data-original";
        controllerParams.notTransparent = true;
        controllerParams.smallcardsize = true;
        controllerParams.notPlayable = true;
        controllerParams.notDetailable = true;
        if(this._currentGenreInfoListItemModel && !this._isSpecialGenereListItem(this._currentGenreInfoListItemModel) && !this._isGenreCustomGenre(this._currentGenreInfoListItemModel)){
            const tuneOptions= [];
            tuneOptions.push(this._currentGenreInfoListItemModel.getId());
            controllerParams.tuneOptions = tuneOptions;
        }else{
            controllerParams.tuneOptions = null
        }
        if(!this._listViewInitialized){

            

            const collectionRepeatControllerParams = {};
            collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildInfoBoxParams();
            collectionRepeatControllerParams.showEmtptyInfoBox = true;
            collectionRepeatControllerParams.controllerOption = controllerParams;
            collectionRepeatControllerParams.itemControllerConstructor = TrebbleCardController;
            collectionRepeatControllerParams.modelLoadHandler =   this._getTrebbleLoadHanderForGenreAndLanguage(this._currentGenreInfoListItemModel, this._currentLanguageInfoListItemModel);

            collectionRepeatControllerParams.modelLoadMoreHandler = this._getTrebbleLoadHanderForGenreAndLanguageWithPaging(this._currentGenreInfoListItemModel, this._currentLanguageInfoListItemModel);
            collectionRepeatControllerParams.loadOnScroll = true;
            collectionRepeatControllerParams.numberOfResultsPerPage = 21;

            //collectionRepeatControllerParams.context = this.context;
            collectionRepeatControllerParams.resizePlaceHolderElem = true;
            collectionRepeatControllerParams.addPaddingBlock = true;
            collectionRepeatControllerParams.paddingBlockHeight= 150;
            //collectionRepeatControllerParams.actionButtonHandlerFunction = this.showExplorePage.bind(this);
            //collectionRepeatControllerParams.heightMargin = 52 + 39; //Page Header + Tab Header

            this.trebbleListController = new CollectionRepeatController(collectionRepeatControllerParams);
            this.listenTo(this.trebbleListController, "startLoading", this.onTrebbleListStartLoading.bind(this));
            this.listenTo(this.trebbleListController, "finishLoading", this.onTrebbleListFinishLoading.bind(this));
            return this.trebbleListController.buildModelAndRenderView().then((function(){
                this.view.setListView(this.trebbleListController.getView());
                this.trebbleListController.setScrollContainer(this.view.$el.find("#trebbleListWrapper #collection_repeat_container"));
                this._listViewInitialized = true;
            }).bind(this));
            
        }else{
            this.trebbleListController.updateControllerOptions(controllerParams);
            this.trebbleListController.updateModelHander(this._getTrebbleLoadHanderForGenreAndLanguage(this._currentGenreInfoListItemModel, this._currentLanguageInfoListItemModel));
            this.trebbleListController.updateLoadMoreModelHander(this._getTrebbleLoadHanderForGenreAndLanguageWithPaging(this._currentGenreInfoListItemModel, this._currentLanguageInfoListItemModel));
            this._scrollToTopOnNextLoad = true;
            this.trebbleListController.reload();
            
        }

    },



    onTrebbleListStartLoading : function(){
        this.view.setLoadingTrebbleList(true);
    },

    onTrebbleListFinishLoading : function(){
        this.view.setLoadingTrebbleList(false);
        this.view.forceLoadTrebbleCoverImage();
        if(this._scrollToTopOnNextLoad){
            this.view.forceTrebbleListToScrollToTop();
            this._scrollToTopOnNextLoad = false;
        }
    },

    _buildInfoBoxParams : function(){
        const params = {};
        params.iconClass = "pe-7s-radio";
        params.message = window.getI18n(ti18n, "NO_TREBBLE_WAS_FOUND_MATCHING_YOUR_SELECTION");
        //params.helpMessage = "Subscribe to trebbles you like and you will find them all here.";
        return params;

    },


    _getPlaylistSummariesForGenereAndLanguageInfo : function(genreInfoListItemModel, languageInfoListItemModel, maxNumberOfResults){
        const language  = (!languageInfoListItemModel || this._isAllLanguageListItem(languageInfoListItemModel))? null:  languageInfoListItemModel.getId();
        const genre  = (!genreInfoListItemModel || this._isAllGenreListItem(genreInfoListItemModel))? null:  genreInfoListItemModel.getId();
        const genreLabel = genreInfoListItemModel? genreInfoListItemModel.getLabel(): null;
        const genreId = genreInfoListItemModel? genreInfoListItemModel.getId(): null;
        if(genreId ===  ALL_GENRE_ID && !language){
            return TrebbleClientAPIHelper.getInstance().retrieveTrebbleByGenres("", maxNumberOfResults);
        }
        /*if(genreId === MY_SUBSCRIPTIONS_ID){
            return TrebbleClientAPIHelper.getInstance().getFollowersPersonalUserRadios(true);
        }*/
        if(genreId === FEATURED_ID){
            return TrebbleClientAPIHelper.getInstance().getFeaturedRadios(language);
        }
        if(genreId === RECENTLY_ADDED_ID){
            return TrebbleClientAPIHelper.getInstance().getRecentlyAddedRadios(language, SHOW_FEATURED_ONLY_IN_RECENTLY_ADDED,NUMBER_OF_RESULTS_IN_RECENTLY_ADDED );
        }
        if(genreId === Utils.getInstance().getLocalCategoryId()){
            const cityId =  this._currentCityInfoListItemModel? this._currentCityInfoListItemModel.getId(): null;
            return TrebbleClientAPIHelper.getInstance().retrieveTrebbleByCityAndLanguage(cityId, language);
        }
        return TrebbleClientAPIHelper.getInstance().retrieveTrebbleByCategoryAndLanguage(genre, language, maxNumberOfResults);
    },

    _getPlaylistSummariesForGenereAndLanguageInfoWithPaging : function(genreInfoListItemModel, languageInfoListItemModel, maxNumberOfResults, pageIndex){
        const language  = (!languageInfoListItemModel || this._isAllLanguageListItem(languageInfoListItemModel))? null:  languageInfoListItemModel.getId();
        const genre  = (!genreInfoListItemModel || this._isAllGenreListItem(genreInfoListItemModel))? null:  genreInfoListItemModel.getId();
        const genreLabel = genreInfoListItemModel? genreInfoListItemModel.getLabel(): null;
        const genreId = genreInfoListItemModel? genreInfoListItemModel.getId(): null;
        if(genreId ===  ALL_GENRE_ID && !language){
            return TrebbleClientAPIHelper.getInstance().retrieveTrebbleByGenres("", maxNumberOfResults, pageIndex);
        }
        /*if(genreId === MY_SUBSCRIPTIONS_ID){
            return TrebbleClientAPIHelper.getInstance().getFollowersPersonalUserRadios(true);
        }*/
        if(genreId === FEATURED_ID){
            return TrebbleClientAPIHelper.getInstance().getFeaturedRadios(language, maxNumberOfResults, pageIndex);
        }
        if(genreId === RECENTLY_ADDED_ID){
            return TrebbleClientAPIHelper.getInstance().getRecentlyAddedRadios(language, SHOW_FEATURED_ONLY_IN_RECENTLY_ADDED, maxNumberOfResults, pageIndex );
        }
        if(genreId === Utils.getInstance().getLocalCategoryId()){
            const cityId =  this._currentCityInfoListItemModel? this._currentCityInfoListItemModel.getId(): null;
            return TrebbleClientAPIHelper.getInstance().retrieveTrebbleByCityAndLanguage(cityId, language, maxNumberOfResults, pageIndex);
        }
        return TrebbleClientAPIHelper.getInstance().retrieveTrebbleByCategoryAndLanguage(genre, language, maxNumberOfResults, pageIndex);
    },

    _getTrebbleLoadHanderForGenreAndLanguage  : function(genreInfoListItemModel, languageInfoListItemModel){
        return (function(numberOfItemToLoad, offset){
            if(this.allPlaylistSummaryModelArray){
                let result  = null;
                if(offset + numberOfItemToLoad <= this.allPlaylistSummaryModelArray.length){
                    result = this.allPlaylistSummaryModelArray.slice(offset, offset+numberOfItemToLoad );
                }
                return RSVP.Promise.resolve(result);
            }else{
                return this.self._getPlaylistSummariesForGenereAndLanguageInfo(this.genreInfoListItemModel, this.languageInfoListItemModel,numberOfItemToLoad).then((function(playlistSummaryArray){
                    const summaryModelArray = [];
                    if(playlistSummaryArray){
                        for(let index  in playlistSummaryArray){
                            const playlistSummaryJson =  playlistSummaryArray[index];
                            summaryModelArray.push(Utils.getInstance().buildPlaylistSummaryFromJson(playlistSummaryJson));
                        }
                    }
                    this.allPlaylistSummaryModelArray = summaryModelArray;
                    let result  = this.allPlaylistSummaryModelArray;
                    if(offset + numberOfItemToLoad <= this.allPlaylistSummaryModelArray.length){
                        result = this.allPlaylistSummaryModelArray.slice(offset, offset+numberOfItemToLoad );
                    }
                    return result;
                }).bind(this));
            }
        }).bind({"self": this, "genreInfoListItemModel": genreInfoListItemModel,"languageInfoListItemModel": languageInfoListItemModel});

},

_getTrebbleLoadHanderForGenreAndLanguageWithPaging  : function(genreInfoListItemModel, languageInfoListItemModel){
return (function(filter, offset, numberOfItemToLoad){
return this.self._getPlaylistSummariesForGenereAndLanguageInfoWithPaging(this.genreInfoListItemModel, this.languageInfoListItemModel,numberOfItemToLoad, offset).then((function(playlistSummaryArray){
    const summaryModelArray = [];
    if(playlistSummaryArray){
        for(let index  in playlistSummaryArray){
            const playlistSummaryJson =  playlistSummaryArray[index];
            summaryModelArray.push(Utils.getInstance().buildPlaylistSummaryFromJson(playlistSummaryJson));
        }
    }
    return summaryModelArray;
}).bind(this));
}).bind({"self": this, "genreInfoListItemModel": genreInfoListItemModel,"languageInfoListItemModel": languageInfoListItemModel});

},


onActiveTrebbleChanged : function(playlistSummaryModel){
this._currentPlaylistSummaryModel = playlistSummaryModel;
this.view.setBackgroundImage(playlistSummaryModel?playlistSummaryModel.getCoverArtUrlBlur(): null);
if(window.trebbleAnalyticsHelper){
window.trebbleAnalyticsHelper.trackEvent("OnboardingPage", 'OnActiveTrebbleChange', 'On Active Trebble Change');
}
this._numberOfTimeSlidesWereChanged = this._numberOfTimeSlidesWereChanged + 1;
if(this._numberOfTimeSlidesWereChanged >= THRESHOLD_TO_ENCOURAGE_USER_TO_CHANGE_CATEGORY_OR_LANGUAGE && !this._didUserChangeLanguage && !this._didUserChangeCategories && !this._didUserSubscribeToATrebble){
PersistentModels.getInstance().getRouter().showSuggestionToChangeCategoryOrLanguagePopupIfApplicable();
}
/*if(this._numberOfTimeSlidesWereChanged >= THRESHOLD_TO_ENCOURAGE_USER_TO_PLAY_FEED_FOR_FIRST_TIME && this._didUserSubscribeToATrebble){
PersistentModels.getInstance().getRouter().showSuggestionToListenToFeedForFirstTimeWasDisplayedPopupIfApplicable();
}*/

if(this._numberOfTimeSlidesWereChanged >= THRESHOLD_TO_ASK_USER_TO_GIVE_RECOMMENDATION_ON_CONTENT && !this._didUserSubscribeToATrebble){
PersistentModels.getInstance().getRouter().showSuggestionForContentImprovmentPopupIfApplicable();
}

if(this._autoPreviewEnabled){
const genreId = this._currentGenreInfoListItemModel?this._currentGenreInfoListItemModel.getId():null;
const isCustomGenre = this._isGenreCustomGenre(this._currentGenreInfoListItemModel);
//const isSpecialGenre = this._isSpecialGenereListItem(this._currentGenreInfoListItemModel);
//const isSpecialLanguage = this._isAllLanguageListItem(this._currentLanguageInfoListItemModel);
//const genreAudioPreviewUrl = (genreId && !isSpecialGenre && !isCustomGenre)?playlistSummaryModel.getAudioPreviewUrlByGenreId(genreId):null;
//if(!genreAudioPreviewUrl){
    const genreAudioPreviewUrl = playlistSummaryModel.getAudioPreviewUrl();
    //if(genreAudioPreviewUrl && !isSpecialGenre && !isCustomGenre){
        //Utils.getInstance().showShortToastMessage("Playing a general preview since no preview for selected genre was available");
        //}
        //}
        if(genreAudioPreviewUrl){
            PersistentModels.getInstance().getPlayer().playAudioPreview(genreAudioPreviewUrl);
        }else{
            //Utils.getInstance().showShortToastMessage("No preview yet available. Previews are automatically created every 24 hours if the Trebble has enough content to stream.");
            Utils.getInstance().showShortToastMessage(window.getI18n(ti18n, "NO_PREVIEW_AVAILABLE"));
        }
    }

},

onAutoPreviewEnabled : function(autoPreviewEnabled){
    this._autoPreviewEnabled = autoPreviewEnabled;
    if(!this._autoPreviewEnabled){
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("OnboardingPage", 'OnAutoPreviewDisabled', 'On Auto Preview Disabled');
        }
        PersistentModels.getInstance().getPlayer().terminateAudioPreviewAndResumePlayback();
    }else{
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("OnboardingPage", 'OnAutoPreviewEnabled', 'On Auto Preview Enabled');
        }
        if(this._currentPlaylistSummaryModel){
            this.onActiveTrebbleChanged(this._currentPlaylistSummaryModel);
        }
    }
},

onPageShow : function(){
    /*if(window.trebbleAnalyticsHelper){
        window.trebbleAnalyticsHelper.trackEvent("pageShow", 'OnboardingPageShow', 'My Explore Page Show');
    }*/
    if(this.collectionInfiniteCarouselController || this.trebbleListController){
        if(this.collectionInfiniteCarouselController){
            this.collectionInfiniteCarouselController.updateSwiperWidget();
        }
    }else{
        if(DEFAULT_TO_ALL_CATEGORIES_AND_ALL_LANGUAGES){
            const defaultLanguageId = ALL_LANGUAGE_ID;
            const defaultLanguageLabel = ALL_LANGUAGE_LABEL;
            const defaultLanguageItemModel =  Utils.getInstance().getListItemModel(defaultLanguageId,defaultLanguageLabel);
            this.languageSelectFieldController.setSelected(defaultLanguageItemModel, true, true);
            this._currentLanguageInfoListItemModel = defaultLanguageItemModel;
            const presetCategoryId = this._presetCategoryId ?  this._presetCategoryId : ALL_GENRE_ID;
            const presetCategoryLabel = this._presetCategoryId ?  window.getI18n(Utilsi18n,this._presetCategoryId ) : ALL_GENRE_LABEL;
            this._lastSelectedCategoryId = presetCategoryId;
            this._lastSelectedCategoryLabel = presetCategoryLabel;
            this.genreSelectFieldController.setSelected(Utils.getInstance().getListItemModel(presetCategoryId,presetCategoryLabel), true, false);
            this.hideShowCityFieldIfApplicable();
            this._didUserChangeCategories = false;
            this._didUserChangeLanguage = false;
            //this._updateRadioResults();
        }else{
            let defaultLanguageId = "en-US";
            let defaultLanguageItemModel;
            const defaultLanguageLabel = Utils.getInstance().getLanguageCodeToLanguageInfoMap()[defaultLanguageId].languageLocalizedName;
            if(MAKE_DEFAULT_LANGUAGE_GENERIC_IF_SETTING_A_SPECIFIC_LANGUAGE_BY_DEFAULT){
                 defaultLanguageItemModel =  Utils.getInstance().getGenericLanguageListItemForSpecificLanguage(defaultLanguageId,defaultLanguageLabel);
            }else{
                defaultLanguageItemModel =  Utils.getInstance().getListItemModel(defaultLanguageId,defaultLanguageLabel);	
            }
            
            this.languageSelectFieldController.setSelected(defaultLanguageItemModel, true, true);
            this._currentLanguageInfoListItemModel = defaultLanguageItemModel;
            const presetCategoryId = this._presetCategoryId ?  this._presetCategoryId : ALL_GENRE_ID;
            const presetCategoryLabel = this._presetCategoryId ?  window.getI18n(Utilsi18n,this._presetCategoryId ) : ALL_GENRE_LABEL;
            this._lastSelectedCategoryId = presetCategoryId;
            this._lastSelectedCategoryLabel = presetCategoryLabel;
            this.genreSelectFieldController.setSelected(Utils.getInstance().getListItemModel(presetCategoryId,presetCategoryLabel), true, false);
            this.hideShowCityFieldIfApplicable();
            this._didUserChangeCategories = false;
            this._didUserChangeLanguage = false;
            //this._updateRadioResults();
        }
    }
},

onPageHide: function(){
    return PersistentModels.getInstance().getPlayer().stopAudioPreviewAndRelease();
},

showCarouselView : function(){
    this._displayAsCarousel = true;
    this.view.showListView(!this._displayAsCarousel);
    this._updateRadioResults();
    if(window.trebbleAnalyticsHelper){
        window.trebbleAnalyticsHelper.trackEvent("OnboardingPage", 'showCarouselView',"Show Carousel View");
    }

},

showListView : function(){
    this._displayAsCarousel = false;
    this.view.showListView(!this._displayAsCarousel);
    this._updateRadioResults();
    if(window.trebbleAnalyticsHelper){
        window.trebbleAnalyticsHelper.trackEvent("OnboardingPage", 'showListView', "Show List View");
    }
},

_startListenerToSuggestUserToChangeCategoryIfApplicable : function(){
    if(!this._listenerToSuggestUserToChangeCategoryStarted){
        this._listenerToSuggestUserToChangeCategoryStarted  = true;
        if(Rollout.isFeatureEnabled(Rollout.FEATURES.SUGGEST_CATEGORY_CHANGE_IN_LIST_VIEW_IN_BROWSE_PAGE)){
            //TODO: the condition below should be based of the number of actual subscriptions which should be stored in PersistentModels but is not at the moment
            if(PersistentModels.getInstance().getNumberOfSubscriptions() < MAX_NUMBER_OF_SUBSCRIPTIONS_TO_SUGGEST_USER_TO_CHANGE_CATEGORIES){
                if(NUMBER_OF_SEC_TO_WAIT_BEFORE_SUGGESTING_TO_CHANGE_CATEGORIES > 0){
                    setTimeout((function(){
                        if(this.view.isPageCurrentlyDisplayed()){
                            if(!this._didUserChangeCategories && !this._didUserChangeLanguage){
                                if(PersistentModels.getInstance().getNumberOfSubscriptions()  < MAX_NUMBER_OF_SUBSCRIPTIONS_TO_SUGGEST_USER_TO_CHANGE_CATEGORIES){
                                    const suggestionWasDisplayed = PersistentModels.getInstance().getRouter().showSuggestionToChangeCategoryOrLanguagePopupIfApplicable();
                                    if(suggestionWasDisplayed){
                                        this._startListenerToAskForShortcastUserIsInterestedInIfApplicable();
                                    }
                                }
                            }
                        }else{
                            this._listenerToSuggestUserToChangeCategoryStarted  = false;
                        }
                    }).bind(this), NUMBER_OF_SEC_TO_WAIT_BEFORE_SUGGESTING_TO_CHANGE_CATEGORIES * 1000 );
                }
            }
        }
    }
},


_startListenerToAskForShortcastUserIsInterestedInIfApplicable : function(){
    if(Rollout.isFeatureEnabled(Rollout.FEATURES.ASK_FOR_SHORTCASTS_USER_IS_INTERESTED_IN)){
        //TODO: the condition below should be based of the number of actual subscriptions which should be stored in PersistentModels but is not at the moment
        if(PersistentModels.getInstance().getNumberOfSubscriptions()  < MAX_NUMBER_OF_SUBSCRIPTIONS_TO_ASK_FOR_SHORTCAST_INTEREST_IN){
            if(NUMBER_OF_SEC_TO_WAIT_BEFORE_ASK_SINCE_SUGGESTION_POPUP > 0){
                setTimeout((function(){
                    if(PersistentModels.getInstance().getNumberOfSubscriptions()  < MAX_NUMBER_OF_SUBSCRIPTIONS_TO_ASK_FOR_SHORTCAST_INTEREST_IN){
                        PersistentModels.getInstance().getRouter().showSuggestionForContentImprovmentPopupIfApplicable();
                    }
                }).bind(this), NUMBER_OF_SEC_TO_WAIT_BEFORE_ASK_SINCE_SUGGESTION_POPUP * 1000 );
            }
        }
    }
},

onUserScollListView : function(amountScrolledInPx){
    this._startListenerToSuggestUserToChangeCategoryIfApplicable();
    this.trebbleListController.loadImages();
    setTimeout(function(){
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("OnboardingPage", 'UserScrolledListView', "User scrolled list view",amountScrolledInPx, {"amountScrolled": amountScrolledInPx});
        }
    }, 0)

},

playMyFeedForTheFirstTime : function(){
    const playSubscription = Rollout.getFeatureVariable(Rollout.FEATURES.DISPLAY_FEED_SETUP_AFTER_SIGNUP, Rollout.FEATURES.DISPLAY_FEED_SETUP_AFTER_SIGNUP.variables.force_user_to_manually_push_play, false);
    PersistentModels.getInstance().getRouter().showDefaultPage(true, playSubscription).then((function(){
        this._displayPremiumPlansIfApplicable();
        this._awardTrebblePlusFreeTrialIfApplicable();
    }).bind(this));
    if(window.trebbleAnalyticsHelper){
        window.trebbleAnalyticsHelper.trackEvent("onboarding", "DoneSetupFeedAndPlayFeedForFirstTime", "Done Feed Setup And Playing Feed For the First Time");
    }
},

_awardTrebblePlusFreeTrialIfApplicable :function(){
    if(window.waitForCordovaToLoad){
        const DISPLAY_PREMIUM_PLANS_AFTER_SETUP =  Rollout.isFeatureEnabled(Rollout.FEATURES.DISPLAY_PREMIUM_PLANS_AFTER_SETUP);
        const WAS_AWARDED_TREBBLE_PLUS_FREE_TRIAL =  Rollout.isFeatureEnabled(Rollout.FEATURES.TREBBLE_PLUS_FREE_TRIAL);
        const NUMBER_OF_DAYS = Rollout.getFeatureVariable(Rollout.FEATURES.TREBBLE_PLUS_FREE_TRIAL, Rollout.FEATURES.TREBBLE_PLUS_FREE_TRIAL.variables.number_of_days, 7);
        const INTERCOM_CAROUSEL_ID = Rollout.getFeatureVariable(Rollout.FEATURES.TREBBLE_PLUS_FREE_TRIAL, Rollout.FEATURES.TREBBLE_PLUS_FREE_TRIAL.variables.intercom_carousel_id, 16593692);
        if(!DISPLAY_PREMIUM_PLANS_AFTER_SETUP && WAS_AWARDED_TREBBLE_PLUS_FREE_TRIAL && !LocalStorageHelper.getInstance().hasTrebblePlusFreeTrial() && NUMBER_OF_DAYS && INTERCOM_CAROUSEL_ID  && Number.isInteger(NUMBER_OF_DAYS) && NUMBER_OF_DAYS > 0){
            const startDate = new Date();
            const endDate = new Date();
            endDate.setDate(endDate.getDate() + NUMBER_OF_DAYS);
            LocalStorageHelper.getInstance().awardFreeTrial(NUMBER_OF_DAYS, startDate, endDate);
            LiveChatWidgetHelper.getInstance().displayMobileCarousel(INTERCOM_CAROUSEL_ID);
            this._trackUserWasAwaredTrebblePlusFreeTrial(NUMBER_OF_DAYS, startDate, endDate);
        }
    }
},

_displayPremiumPlansIfApplicable :function(){
    if(window.waitForCordovaToLoad){
        const DISPLAY_PREMIUM_PLANS_AFTER_SETUP =  Rollout.isFeatureEnabled(Rollout.FEATURES.DISPLAY_PREMIUM_PLANS_AFTER_SETUP);
        if(DISPLAY_PREMIUM_PLANS_AFTER_SETUP){
            PersistentModels.getInstance().getRouter().showUpgradeToPaidSubscriptionPopup();
        }
    }
},


_trackUserWasAwaredTrebblePlusFreeTrial : function(numberOfDays, startDate, endDate){
    try{
        const userAttributeUpdates = {"wasAwardedTrebblePlusFreeTrial": true, "startDate": startDate, "endDate":endDate, "numberOfDays": numberOfDays};
        if(window.trebbleAnalyticsHelper && window.trebbleAnalyticsHelper.updateUserProperties){
            window.trebbleAnalyticsHelper.updateUserProperties(userAttributeUpdates);
        }
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("TrebblePlusFreeTrial", "UserWasAwaredFreeTrial", "User was awared Trebble Plus Free Trial", numberOfDays);
        }
        LiveChatWidgetHelper.getInstance().updateUserAttributes(userAttributeUpdates);
        FacebookEventTrackingHelper.getInstance().trackWasAwaredTrebblePlusFreeTrial(numberOfDays);
    }catch(error){
        console.error(error);
    }
},

onSetupViewDisplayed : function(){
    this._onNumberOfUserSubscriptionChanged();
    this.trebbleListController.loadImages();
},

buildModelAndRenderView : function() {
    const promiseArray = [];
    promiseArray.push(this.genreSelectFieldController.buildModelAndRenderView());
    promiseArray.push(this.languageSelectFieldController.buildModelAndRenderView());
    promiseArray.push(this.citySelectFieldController.buildModelAndRenderView());
    return RSVP.Promise.all(promiseArray).then((function(){
        return new RSVP.Promise((function(resolve, reject) {
            try {
                const options = {};
                options.genreSelectFieldView  = this.genreSelectFieldController.getView();
                options.langaugeSelectFieldView  = this.languageSelectFieldController.getView();
                options.citySelectFieldView  = this.citySelectFieldController.getView();
                this.view = new OnboardingListenerPageView(options);
                this.view.setBackgroundImage(null);
                this.listenTo(this.view, "showHomePopup", this.showHomePopup);
                this.listenTo(this.view, "showCarouselView", this.showCarouselView);
                this.listenTo(this.view, "showListView", this.showListView);
                this.listenTo(this.view, "autoPreviewEnabled", this.onAutoPreviewEnabled);
                this.listenTo(this.view, "pageShow", this.onPageShow);
                this.listenTo(this.view, "pageHide", this.onPageHide);
                this.listenTo(this.view, "userScrolledListView", this.onUserScollListView);
                this.listenTo(this.view, "playMyFeedForFirstTime", this.playMyFeedForTheFirstTime);
                this.listenTo(this.view, "setupViewDisplayed", this.onSetupViewDisplayed);
                

                
                this.view.showListView(!this._displayAsCarousel);
                if(!this._displayAsCarousel){
                    if(window.trebbleAnalyticsHelper){
                        window.trebbleAnalyticsHelper.trackEvent("OnboardingPage", 'showListView', "Show List View");
                    }
                }else{
                    if(window.trebbleAnalyticsHelper){
                        window.trebbleAnalyticsHelper.trackEvent("OnboardingPage", 'showCarouselView',"Show Carousel View");
                    }
                }
                

                this.view.render();
                PersistentModels.getInstance().getRouter().setOnboardingWasDisplayedInSession(true);
                this.view.preloadBackgroundImage().then(function(){
                    resolve();
                }).catch(function(err){
                    console.error("Failed preloading Onboarding page background image. Error:" +err);
                    resolve();
                })

            } catch (error) {
                reject(error);
            }

        }).bind(this));
}).bind(this));

}

});

export default OnboardingPageController;