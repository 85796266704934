import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import ti18n from "i18n!nls/Commoni18n";
import SingleSongSelectorInputViewTemplate from 'text!../../../templates/common/SingleSongSelectorInputViewTemplate.html';
import RSVP from 'rsvp';

const SingleSongSelectorInputView = Backbone.View.extend({



    initialize : function(options) {
        this._noAudioSelectedCustomLabel = options.noAudioSelectedCustomLabel?options.noAudioSelectedCustomLabel:"";
        this._chooseAudioCustomLabel = options.chooseAudioCustomLabel?options.chooseAudioCustomLabel:"";
        this._removeAudioCustomLabel = options.removeAudioCustomLabel?options.removeAudioCustomLabel:"";
        this._showWarningBeforeRemoval = options.showWarningBeforeRemoval;
        this._cannotBeModified = options.cannotBeModified;
        this._warningBeforeRemovalMessage = options.warningBeforeRemovalMessage?options.warningBeforeRemovalMessage:"";
        this.compiledTemlate =  _.template(SingleSongSelectorInputViewTemplate);
        this.songView = options? options.songView : null;
    },
    
    events : {
        "click #pickSongButton": "onPickSongButtonClicked",
        "click #removeSelectedButton": "onRemoveSelectedButtonClicked"
    },

    onPickSongButtonClicked :function(){
        this.trigger("pickSong");
    },

    onRemoveSelectedButtonClicked :function(){
        if(this._showWarningBeforeRemoval){
            const buttonLabels = [window.getI18n(ti18n, "NO"),window.getI18n(ti18n, "YES")];
            const confirmCallback = (function(buttonIndex){
                if(buttonIndex ==  2){
                    setTimeout((function(){
                        this.trigger("deleteCurrentSelectedSong");
                    }).bind(this), 100);

                }
            }).bind(this);
            const message = this._warningBeforeRemovalMessage;
            navigator.trebbleNotification.confirm(message , confirmCallback, this._warningBeforeRemovalMessage, buttonLabels);
        }else{
            this.trigger("deleteCurrentSelectedSong");
        }
    },

    setSelectedSongView : function(newSongView){
        this.songView = newSongView;
        this.selectedSongViewPlaceHolder$el.empty();
        if(this.songView){
            this.selectedSongViewPlaceHolder$el.append(this.songView.$el)
            this.$el.removeClass("noSongSelected");
        }else{
            this.$el.addClass("noSongSelected");
        }

    },

    setCannotBeModified : function(cannotBeModified){
        this._cannotBeModified = cannotBeModified;
        this._onCannotBeModifiedChange();
    },

    _onCannotBeModifiedChange : function(){
        if(this._cannotBeModified){
            if(!this.$el.hasClass("not_modifiable")){
                this.$el.addClass("not_modifiable");
            }
        }else{
            this.$el.removeClass("not_modifiable");
        }
    },

    setUpdating : function(isUpdating){
        if(isUpdating){
            this.$el.find(".content").hide();
            this.$el.find("#loadingImg").show();	
        }else{
            this.$el.find(".content").show();
            this.$el.find("#loadingImg").hide();
        }
    },
    
    render : function() {
        const templateParams = {"ti18n":ti18n,"noAudioSelectedCustomLabel": this._noAudioSelectedCustomLabel,"chooseAudioCustomLabel": this._chooseAudioCustomLabel,"removeAudioCustomLabel": this._removeAudioCustomLabel};
        this.$el.html(this.compiledTemlate(templateParams));
        this.setElement(this.$el.find("div").first());
        this.selectedSongViewPlaceHolder$el = this.$el.find(".selectedSongViewPlaceHolder");
        this.setSelectedSongView(this.songView);
        this._onCannotBeModifiedChange();
        return this;
    }

});
export default SingleSongSelectorInputView;