import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import DynamicPageView from "views/common/DynamicPageView";
import RSVP from "rsvp";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import owlcarousel from "owlcarousel";
import listHeaderTemplate from "text!../../../templates/common/listHeaderTemplate.html";
import listFooterTemplate from 'text!../../../templates/common/listFooterTemplate.html';
import UserProfileDetailsTemplate from 'text!../../../templates/user/UserProfileDetailsTemplate.html';
import ti18n from "i18n!nls/UserProfileDetailsPagei18n";

const DEFAULT_USER_PROFILE_BACKGROUND_IMAGE_URL= window.getResourceURLAccordingToLocationProtocol("/img/blackWhitebackgroundplayer.jpg");
const DEFAULT_USER_PROFILE_IMAGE_URL= "none";
const UserProfileDetailsPageView = DynamicPageView.extend({
    template : _.template(UserProfileDetailsTemplate),
    listHeaderTemplateCompiled : _.template(listHeaderTemplate),
    listFooterTemplateCompiled : _.template(listFooterTemplate),
    
    initialize : function(options) {
        const dynamicPageOptions = {};
        this.model =  options? options.model: {};
        this._pageId = options?options.pageId : "u_"+encodeURIComponent(this.model.getUsername());
        dynamicPageOptions.pageViewTemplate = UserProfileDetailsTemplate;
        dynamicPageOptions.model = this.model;
        dynamicPageOptions.pageId = this._pageId;
        dynamicPageOptions.headerFullscren = true;
        dynamicPageOptions.isPlayerFooterTransparent = false;
        dynamicPageOptions.ignoreDefaultPageUIContentPadding = true;
        dynamicPageOptions.pageTemplateOptions = {"ti18n":ti18n};
        this.topSongsListView = options.topSongsListView;
        this.recentlyAddedSongsListView = options.recentlyAddedSongsListView;
        this.userTrebblesListView = options.userTrebblesListView;
        this.userCapsuleHistoryCollectionRepeatView = options.userCapsuleHistoryCollectionRepeatView;
        this.journeyCollectionRepeatView = options.journeyCollectionRepeatView;

        //PersistentModels.getInstance().getRouter().registerForViewportHeightChange(this._pageId,this._onViewportHeightChanged.bind(this));
        return DynamicPageView.prototype.initialize.call(this, dynamicPageOptions);  
    },
    
    
    
    
    updateUserModel :function(newUserModel){
        this.model =  newUserModel;
        this._showHideOptionMenuButtonIfApplicable();
        if(this.model){
            this.updateView();
        }
    },
    
    events : {
        "click .ui-header  #header-back-button": "navigateToPreviousPage",
        "taphold #userProfileImage.editable" : "changeProfileImageWithConfirmation",
        "click #editProfilePicBtn" : "changeProfileImage",
        "click div[data-role=header] #menuOptionButton" : "showOptionMenu",
        "click div[data-role=header] #editButton" : "editUserProfile",
        "click #pageHeaderTitleWrapper > .editable" : "editUserProfile",
        "click .userInfo #followBtn" : "follow",
        "click .userInfo #followingBtn" : "unfollow",
        "click #followedFollowedInfoBox" : "showUsersFollowed",
        "click #followingsFollowedInfoBox" : "showUsersFollowing",
        "click #trebblesFollowedInfoBox" : "showTrebbleFollowed",
        "click #songsInLibraryInfoBox" : "showSongsInUserLibrary",
        "click #shareUserCmailUrlBtn" :"shareUserCmailUrl",
        "click #sendCmailToUserBtn" :"sendCmailToUser",
        "click #websiteBox" :"openUsersWebsite"
        
    },
    
    showOptionMenu : function(){
        this.trigger("showOptionMenu", this.$el.find("#menuOptionButton"));
    },

    shareUserCmailUrl :function(){
        this.trigger("shareUserCmailUrl");
    },
    
    sendCmailToUser :function(){
        this.trigger("sendCmailToUser");
    },

    editUserProfile : function(){
        this.trigger("editUserProfile");
    },
    
    showUsersFollowed :function(){
        this.trigger("showUsersFollowed")
    },

    openUsersWebsite : function(){
        this.trigger("openUsersWebsite")
    },

    _showHideOptionMenuButtonIfApplicable : function(){
        if(this.model){
            this.$el.find("#menuOptionButton").show();
        }else{
            this.$el.find("#menuOptionButton").hide();
        }
    },
    
    
    getBackgroundImageWidth : function(){
        return window.getDocumentClienWidth();
    },
    
    getBackgroundImageHeight : function(){
        return 280;
    },
    
    getUserProfileImageWidth: function(){
        return 80;
    },
    
    getUserProfileImageHeight: function(){
        return 80;
    },
    
    
    
    showUsersFollowing :function(){
        this.trigger("showUsersFollowing");
    },
    
    showTrebbleFollowed :function(){
        this.trigger("showTrebbleFollowed")
        
    },
    
    follow : function(){
        //this.setFollow();
        this.trigger("follow");
    },
    
    unfollow : function(){
        //this.setUnfollow();
        this.trigger("unfollow");
    },
    
    setUnfollow : function(){
        this.model.setFollowing(false);
        this.updateFollowButton();
    },
    
    setFollow : function(){
        this.model.setFollowing(true);
        this.updateFollowButton();
    },
    
    
    showSongsInUserLibrary :function(){
        this.trigger("showSongsInUserLibrary")
    },
    
    changeProfileImage : function(){
        this.trigger("changeProfileImage");
    },

    changeProfileImageWithConfirmation : function(){
        this.trigger("changeProfileImageWithConfirmation");
    },
    
    updateUserProfilImage : function(userProfileImageUrl, userProfileImageBlurryBackgroundBase64){
        userProfileImageBlurryBackgroundBase64 = userProfileImageBlurryBackgroundBase64? userProfileImageBlurryBackgroundBase64: DEFAULT_USER_PROFILE_BACKGROUND_IMAGE_URL;
        userProfileImageUrl = userProfileImageUrl? userProfileImageUrl: DEFAULT_USER_PROFILE_IMAGE_URL;
        //this.$el.find(".userDetailCoverArtWrapper").css("height",(window.device && window.device.platform == "browser")?"500px":"270px");
        const userProfileImage$el = this.$el.find("#userProfileImage");
        const userDetailCoverArtWrapper$el = this.$el.find(".userDetailCoverArtWrapper");
        

        const screenWidth = window.getDocumentClienWidth();
        const screenHeight = window.getDocumentClienHeight();
        const regularCovertArtWidth = window.getDocumentClienWidth() - 10;
        const regularCovertArtHeight = window.getDocumentClienHeight() -220;
        const thumbnailWidth = 64;
        const thumbnailHeight = 64;
        
        
        
        return new RSVP.Promise((function(resolve, reject){
            //const artistImageElem = this.el.querySelector("#artistImage");
            //artistImageElem.style.height = (window.device && window.device.platform == "browser")?"500px":"200px";
            //const base64ImageDataBlur = "url('"+ base64AlbumArtBlur + "')";
            
            
            //this.elem.style["background-image"] = "url('"+ this.tempImg.src + "')";
            //const backgroundInageBase64 = null;
            
            if(window.trebble.config.disableZoomInCoverArtInMobileApp || (window.device && window.device.platform == "browser")){
                //backgroundInageBase64 = this.tempImg.src ;
                //this.elem.style["background-image"] = base64ImageDataBlur;
                userProfileImage$el.addClass("withShadow inFront");
            }else{
                //backgroundInageBase64 = this.tempImg.src ;
                //this.elem.style["background-image"] = "url('"+ this.tempImg.src + "')";
            }
            Utils.getInstance().getMatchingColorByImageUrl(userProfileImageUrl).then((function(result){
                this.backgroundColorInfo = result;
                this.$el.css("background-color",result.backgroundColor);
                this._defaultBackgroundColor = result.backgroundColor;
                if(result.shouldUserLightColorText){
                    this.$el.removeClass("darktext");
                    this.$el.addClass("lighttext");
                }else{
                    this.$el.removeClass("lighttext");
                    this.$el.addClass("darktext");
                }
                this.$el.find("#userProfileWrapper").css("background-color",result.backgroundColor);
                this.$el.find(".ui-panel-wrapper").css("background-color",result.backgroundColor);
                this.$el.find(".ui-content").css("background-color",result.backgroundColor);
                if(this.topSongsListView){
                    this.topSongsListView.$el.css("background-color",result.backgroundColor);
                }
                if(this.recentlyAddedSongsListView){
                    this.recentlyAddedSongsListView.$el.css("background-color",result.backgroundColor);
                }
                if(this.userTrebblesListView){
                    this.userTrebblesListView.$el.css("background-color",result.backgroundColor);
                }
                if(this.userCapsuleHistoryCollectionRepeatView){
                    this.userCapsuleHistoryCollectionRepeatView.$el.css("background-color",result.backgroundColor);
                }
                if(this.journeyCollectionRepeatView){
                    this.journeyCollectionRepeatView.$el.css("background-color",result.backgroundColor);
                }
                
                
                const colorString = result.backgroundColorArray;
                userProfileImage$el.css("background-image","url("+userProfileImageUrl+")");
                userProfileImage$el.css("-webkit-mask-image","url("+userProfileImageUrl+")");
                if(userProfileImageUrl){
                    userProfileImage$el.removeClass("nocoverimage");
                }
                
                const  basicArtistInfo$el = this.$el.find(".basicArtistInfo");
                const  ui_header$el = this.$el.find(".ui-header");
                
                basicArtistInfo$el.css({"background-image": "-webkit-linear-gradient(top,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                ui_header$el.css({"background-image": "-webkit-linear-gradient(top,rgba("+ colorString + ",0.5) ,rgba("+ colorString + ",0) 100%)"});
                basicArtistInfo$el.css({"background-image": "-moz-linear-gradient(top,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                ui_header$el.css({"background-image": "-moz-linear-gradient(top,rgba("+ colorString + ",0.5) ,rgba("+ colorString + ",0) 100%)"});
                basicArtistInfo$el.css({"background-image": "-ms-linear-gradient(top,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                ui_header$el.css({"background-image": "-ms-linear-gradient(top,rgba("+ colorString + ",0.5) ,rgba("+ colorString + ",0) 100%)"});
                basicArtistInfo$el.css({"background-image": "-o-linear-gradient(top,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                ui_header$el.css({"background-image": "-o-linear-gradient(top,rgba("+ colorString + ",0.5) ,rgba("+ colorString + ",0) 100%)"});
                basicArtistInfo$el.css({"background-image": "linear-gradient(top,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                ui_header$el.css({"background-image": "linear-gradient(top,rgba("+ colorString + ",0.5) ,rgba("+ colorString + ",0) 100%)"});
                basicArtistInfo$el.css({"background-image": "progid:DXImageTransform.Microsoft.gradient(startColorstr="+ colorString + ", endColorstr="+ colorString + ")"});
                ui_header$el.css({"background-image": "progid:DXImageTransform.Microsoft.gradient(startColorstr="+ colorString + ", endColorstr="+ colorString + ")"});
                if(Utils.getInstance().isBackgroundBlurImageDisabled()){
                    userDetailCoverArtWrapper$el.css({"background-image": "none"});
                    basicArtistInfo$el.css({"background-image": "none"});
                }else{
                    userDetailCoverArtWrapper$el.css("background-image","url("+userProfileImageBlurryBackgroundBase64+")");
                    userDetailCoverArtWrapper$el.css({"background-color": "rgb(" + colorString+")"});
                }
                resolve();
            }).bind(this)).catch(reject);


}).bind(this));

},

navigateToPreviousPage : function(){
PersistentModels.getInstance().getRouter().navigateToPreviousPage();
},
onBeforePageInitialized : function(){

},
hideJourneyListView : function(){
if(!this.$journeyResultContentHolder.hasClass("hide")){
this.$journeyResultContentHolder.addClass("hide")
}

},

showJourneyListView : function(){
if(this.$journeyResultContentHolder.hasClass("hide")){
this.$journeyResultContentHolder.removeClass("hide")
}

},

onAfterPageInitialized : function(){
this._initializeCarousels();
this._showHideOptionMenuButtonIfApplicable();

this.$capsuleHistoryWrapper =  this.$el.find("#capsuleHistoryWrapper:first").first();
this.$songsAndTrebbleSummaryWrapper = this.$el.find("#songsAndTrebbleSummaryWrapper:first").first();
this.$el.addClass("lighttext");
//this.loadArtistImage();
this._initUserTrebblesListView();
//this._initTopSongListView();

//this._initRecentlyAddedSongsListView();
this._initJourneyListtView();
this._initCapsuleHistoryListtView();



//this.updateBackgroundImageAndTextColor();
},


_initTopSongListView : function(){
this.$topSongResultContentHolder = $(document.createElement('div'));
this.$topSongResultContentHolder.addClass("top_song_list_wrapper");
this.$topSongResultContentHolder.append(this.listHeaderTemplateCompiled({"id" : "topSongListHeader","text" : window.getI18n(ti18n, "MOST_PLAYED")}));
this.$topSongResultContentHolder.append(this.topSongsListView.el);
this.topSongsListView.$el.css("background-color","#111111");
this.$topSongListFooter = $(this.listFooterTemplateCompiled({"id" : "topSongListFooter","text" : window.getI18n(ti18n, "MORE_MOST_PLAYED_SONGS")})).first();
this.$topSongResultContentHolder.append(this.$topSongListFooter);
this.$topSongListFooter.addClass("transparent");
this.$topSongListFooter.on("click",this._onSeeMoreTopSongsTapped.bind(this));
this.$topSongResultContentHolder.addClass("left-right-content-padding");
this.$songsAndTrebbleSummaryWrapper.append(this.$topSongResultContentHolder);
this.listenTo(this.topSongsListView, "modelChange",this._onTopSongCollectionChange );
this._onTopSongCollectionChange();
},
_onSeeMoreTopSongsTapped : function(){
this.trigger("showMoreMostPlayed");
},



_onTopSongCollectionChange : function(newAlbumCollection){
if(this.topSongsListView.isCollectionEmpty() || window.trebble.doNotShowMusicInSearchPage){
this.$topSongResultContentHolder.hide();
}else{
this.$topSongResultContentHolder.show();
}
this._displayInfoMessageIfNecessary();
},


_initRecentlyAddedSongsListView : function(){
this.$recentlyAddedSongsResultContentHolder = $(document.createElement('div'));
this.$recentlyAddedSongsResultContentHolder.addClass("recently_added_songs_list_wrapper");
this.$recentlyAddedSongsResultContentHolder.append(this.listHeaderTemplateCompiled({"id" : "recentlyAddedSongListHeader","text" : window.getI18n(ti18n, "RECENTLY_ADDED")}));
this.$recentlyAddedSongsResultContentHolder.append(this.recentlyAddedSongsListView.el);
this.recentlyAddedSongsListView.$el.css("background-color","#111111");
this.$recentlyAddedSongListFooter = $(this.listFooterTemplateCompiled({"id" : "recentlyAddedSongListFooter","text" : window.getI18n(ti18n, "MORE_RECENTLY_ADDED_SONGS")})).first();
this.$recentlyAddedSongsResultContentHolder.append(this.$recentlyAddedSongListFooter);
this.$recentlyAddedSongListFooter.on("click",this._onSeeMoreRecentlyAddedSongsTapped.bind(this));
this.$recentlyAddedSongListFooter.addClass("transparent");
this.$recentlyAddedSongsResultContentHolder.addClass("left-right-content-padding");
this.$songsAndTrebbleSummaryWrapper.append(this.$recentlyAddedSongsResultContentHolder);
this.listenTo(this.recentlyAddedSongsListView, "modelChange",this._onRecentlyAddedSongCollectionChange );
this._onRecentlyAddedSongCollectionChange();
},
_onSeeMoreRecentlyAddedSongsTapped : function(){
this.trigger("showMoreRecentlyAddedSongs");
},



_onRecentlyAddedSongCollectionChange : function(newAlbumCollection){
if(this.recentlyAddedSongsListView.isCollectionEmpty() || window.trebble.doNotShowMusicInSearchPage){
this.$recentlyAddedSongsResultContentHolder.hide();
}else{
this.$recentlyAddedSongsResultContentHolder.show();
}
this._displayInfoMessageIfNecessary();
},

_initUserTrebblesListView : function(){
this.$userTrebblesResultContentHolder = $(document.createElement('div'));
this.$userTrebblesResultContentHolder.append(this.listHeaderTemplateCompiled({"id" : "userTrebblesListHeader","text" : window.getI18n(ti18n, "TREBBLE")}));
this.$userTrebblesResultContentHolder.append(this.userTrebblesListView.el);
this.userTrebblesListView.$el.css("background-color","#111111");
/*this.$topSongListFooter = $(this.listFooterTemplateCompiled({"id" : "topSongListFooter","text" : "More Most Played Songs"})).first();
this.$topSongResultContentHolder.append(this.$topSongListFooter);
this.$topSongResultContentHolder.on("click",this._onSeeMoreTopSongsTapped.bind(this));*/
this.$userTrebblesResultContentHolder.addClass("left-right-content-padding");
this.$songsAndTrebbleSummaryWrapper.append(this.$userTrebblesResultContentHolder);
this.listenTo(this.topSongsListView, "modelChange",this._onUserTrebblesCollectionChange );
this._onUserTrebblesCollectionChange();
},

_initJourneyListtView : function(){
if(this.journeyCollectionRepeatView){
this.$journeyResultContentHolder = $(document.createElement('div'));
this.$journeyResultContentHolder.append(this.listHeaderTemplateCompiled({"id" : "JourneyListHeader","text" : window.getI18n(ti18n, "JOURNEYS")}));
this.$journeyResultContentHolder.append(this.journeyCollectionRepeatView.el);
this.journeyCollectionRepeatView.$el.css("background-color","#111111");

this.$journeyResultContentHolder.addClass("left-right-content-padding");
this.$capsuleHistoryWrapper.append(this.$journeyResultContentHolder);
this.journeyCollectionRepeatView.loadImages();
this.hideJourneyListView();
}
},

_initCapsuleHistoryListtView : function(){
if(this.userCapsuleHistoryCollectionRepeatView){
this.$capsuleHistoryResultContentHolder = $(document.createElement('div'));
this.$capsuleHistoryResultContentHolder.append(this.listHeaderTemplateCompiled({"id" : "CapsuleHistoryListHeader","text" : window.getI18n(ti18n, "CAPSULE_ARCHIVES")}));
this.$capsuleHistoryResultContentHolder.append(this.userCapsuleHistoryCollectionRepeatView.el);
this.userCapsuleHistoryCollectionRepeatView.$el.css("background-color","#111111");
/*this.$topSongListFooter = $(this.listFooterTemplateCompiled({"id" : "topSongListFooter","text" : "More Most Played Songs"})).first();
this.$topSongResultContentHolder.append(this.$topSongListFooter);
this.$topSongResultContentHolder.on("click",this._onSeeMoreTopSongsTapped.bind(this));*/
this.$capsuleHistoryResultContentHolder.addClass("left-right-content-padding");
this.$capsuleHistoryWrapper.append(this.$capsuleHistoryResultContentHolder);
//this.listenTo(this.topSongsListView, "modelChange",this._onUserTrebblesCollectionChange );
//this._onUserTrebblesCollectionChange();
}
},


_onUserTrebblesCollectionChange : function(newAlbumCollection){
/*if(this.topSongsListView.isCollectionEmpty()){
this.$topSongResultContentHolder.hide();
}else{
this.$topSongResultContentHolder.show();
}*/
this._displayInfoMessageIfNecessary();
},


_displayInfoMessageIfNecessary : function(){
/*if(this._infoBoxView.$el)
{
if( this._songsListView.isCollectionEmpty()
    && this._albumsListView.isCollectionEmpty() 
    && this._artistsListView.isCollectionEmpty()
    && this._soundcloudSongsListView.isCollectionEmpty()
    && this._usersListView.isCollectionEmpty()
    && this._playlistListView.isCollectionEmpty()
    && this._youtubeVideoListView.isCollectionEmpty()){
if(this.customSearchFieldView.getSearchFieldValue()){
this._setNoResultFounMessagedOnOnInfoBox();
}else{
this._setHelpMessageOnInfoBox();
}
if(!this.customSearchFieldView.isSearchFieldDisplayed()){
this._setHelpMessageOnInfoBox();
}
this._infoBoxView.$el.css("display", "");
}else{
this._infoBoxView.$el.css("display", "none");
}
}*/
//this._scrollSearchContainerToTop();
},



onBeforeRemoveFromPage : function(){
DynamicPageView.prototype.onBeforeRemoveFromPage.call(this); 
PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._pageId);
},

updateTextAndBackgroundColors : function(backgroundColor, textColor1, texgtColor2){

},

_initializeCarousels : function(){
this.carousel$el = this.$el.find(".carouselWrapper").owlCarousel({

navigation : false, // Show next and prev buttons
pagination :true,
slideSpeed : 300,
paginationSpeed : 400,
afterInit : function(elem){
    const that = this
    that.owlControls.prependTo(elem)
},
afterAction : this._onCarouselMove.bind(this),
singleItem:true
});
},

_onCarouselMove : function(){

},

updateFollowButton : function(){
if(!this.model.canBeFollowed()){
this.$el.find(".userInfo #followingBtn").css("display","none");
this.$el.find(".userInfo #followBtn").css("display","none");
}else{
if(this.model.isFollowing()){
    this.$el.find(".userInfo #followingBtn").css("display","flex");
    this.$el.find(".userInfo #followBtn").css("display","none");
}else{
    this.$el.find(".userInfo #followingBtn").css("display","none");
    this.$el.find(".userInfo #followBtn").css("display","flex");
}
}
this.$el.find("#followingsFollowedInfoBox > .value").html(this.model.getNumberOfFollowings());
this.$el.find("#followedFollowedInfoBox > .value").html(this.model.getNumberOfFollowers());
},


updateView : function(){
const username$el = this.$el.find("#pageHeaderTitleWrapper > span");
username$el.html(this.model.getFullName());
const isACreator = this.model.getProfileType() == Utils.getInstance().getProfileTypes().CREATOR || this.model.getProfileType() == Utils.getInstance().getProfileTypes().JOURNEY_CREATOR || this.model.getProfileType() == Utils.getInstance().getProfileTypes().SHORTCAST_CREATOR;
this.$el.attr("is_creator",isACreator?"true":"false");
this.$el.attr("profile_type",this.model.getProfileType());
if(this.model.canBeEdited()){
if(!username$el.hasClass("hint--right editable")){
    username$el.addClass("hint--right editable");
    username$el.attr("data-hint",window.getI18n(ti18n, "EDIT_YOUR_NAME"));
}
}else{
if(username$el.hasClass("hint--right editable")){
    username$el.removeClass("hint--right editable");
    username$el.attr("data-hint","");
}
}
if(this.model.getProfileImageUrl()){
this.$el.find("#userProfileImage").css("background-image",this.model.getProfileImageUrl());
}else{
this.$el.find("#userProfileImage").css("background-image","none");
}
if(this.model.canBeEdited()){
this.$el.find("[data-role=header]").addClass("twoButtons");
this.$el.find("#artistImage").addClass("editable");
this.$el.find("#userProfileImage").addClass("editable");
this.$el.find("#editButton").css("display","block");
this.$el.find("#shareUserCmailUrlBtn").html(window.getI18n(ti18n, "SHARE_MY_ASK")+this.model.getUsername()+" URL");
this.$el.find("#shareUserCmailUrlBtn").css("display","block");
this.$el.find("#sendCmailToUserBtn").css("display","none");
this.$el.addClass("loggedInUserProfilePage");
}else{
this.$el.find("[data-role=header]").removeClass("twoButtons");
this.$el.find("#artistImage").removeClass("editable");
this.$el.find("#userProfileImage").removeClass("editable");
this.$el.find("#shareUserCmailUrlBtn").css("display","none");
this.$el.find("#sendCmailToUserBtn").html(window.getI18n(ti18n, "HASHTAG_ASK")+this.model.getUsername());
this.$el.find("#sendCmailToUserBtn").css("display","block");
}
this.$el.find("#fullnameBox").html(this.model.getFullName());
this.$el.find("#usernameBox").html("@"+this.model.getUsername());
this.$el.find("#bioBox").html(this.model.getBio());
if(this.model.getWebsite()){
this.$el.find("#websiteBox").html('<i class="pe-7s-link"></i>'+ this.model.getWebsite());
}else{
this.$el.find("#websiteBox").html('');
}
if(!window.trebble.doNotShowMusicInSearchPage){
this.$el.find("#songsInLibraryInfoBox > .value").html(this.model.getNumberOfSongsInLibrary());
}
this.$el.find("#trebblesFollowedInfoBox > .value").html(this.model.getNumberOfFollowedTrebbles());
this.$el.find("#followingsFollowedInfoBox > .value").html(this.model.getNumberOfFollowings());
this.$el.find("#followedFollowedInfoBox > .value").html(this.model.getNumberOfFollowers());
this.updateFollowButton();
},
});

export default UserProfileDetailsPageView;