import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utils from "models/helper/Utils";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import ti18n from "i18n!nls/RedeemCodei18n";
import RedeemCodePageView from "views/user/RedeemCodePageView";

const RedeemCodePageController =  Chaplin.Controller.extend({


    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = null;
        this.redeemCode =  options.redeemCode && options.redeemCode !== "undefined"? options.redeemCode: null;
        this._pageId = options.pageId;


    },
    
    getModel : function(){
        return this.model;
    },
    
    getView : function(){
        return this.view;
    },
    
    redeem : function(code){
        $.mobile.loading("show");
        return TrebbleClientAPIHelper.getInstance().redeemCodeForCreatorSubscription(code).then((function(operationResult){
            if(operationResult && operationResult.codeSuccessfullyRedeemed){
                window.alertSuccessMessage(window.getI18n(ti18n, "YOUR_CODE_WAS_SUCCESSFULLY_REDEEMED"));
                PersistentModels.getInstance().getRouter().showDefaultPage(true);
                if(window.trebbleAnalyticsHelper){
                    window.trebbleAnalyticsHelper.trackEvent("codeRedeem", "Code was successfully redeemed", "Code was successfully redeemed", code, {"code": code});
                }
                PersistentModels.getInstance().getRouter().trackAffiliateIfApplicable();
            }else{
                if(operationResult && operationResult.codeAlreadyRedeemed){
                    window.alertErrorMessage(window.getI18n(ti18n, "THIS_CODE_WAS_ALREADY_REDEEMED"));
                    if(window.trebbleAnalyticsHelper){
                        window.trebbleAnalyticsHelper.trackEvent("codeRedeem", "Code was already redeemed", "Code was already redeemed", code, {"code": code});
                    }
                }else{
                    if(window.trebbleAnalyticsHelper){
                        window.trebbleAnalyticsHelper.trackEvent("codeRedeem", "Code redeem failed", "Code redeem failed", code, {"code": code});
                    }
                    window.alertErrorMessage(window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_REDEEMING_YOUR_CODE"));
                }
            }
            $.mobile.loading("hide");
    }).bind(this)).catch((function(error){
        $.mobile.loading("hide");
        this.view.setRedeemedButtonEnabled(true);
        const errorMessage = error? error: window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_REDEEMING_YOUR_CODE");
        window.alertErrorMessage(errorMessage);
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("codeRedeem", "Code redeem failed", "Code redeem failed",code, {"code": code});
        }
    }).bind(this));


},


buildModelAndRenderView : function() {
    return new RSVP.Promise((function(resolve, reject) {
        try {

            const pageViewParams= {};
            pageViewParams.pageId = this._pageId;
            pageViewParams.redeemCode = this.redeemCode;
            this.view = new RedeemCodePageView(pageViewParams);
            this.view.render();
            this.listenTo(this.view, "redeem", this.redeem);
            resolve(this);
        } catch (error) {
            reject(error);
        }

    }).bind(this));

}


});
export default RedeemCodePageController;