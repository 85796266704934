import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import CordovaHelper from "models/helper/CordovaHelper";
import InfoBoxController from "controllers/common/InfoBoxController";
import PlaylistListController from "controllers/common/PlaylistListController";
import Utils from "models/helper/Utils";
import Capsules from "collections/Capsules";
import PlaylistHelper from "models/helper/PlaylistHelper";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import CollectionRepeatController from "controllers/common/CollectionRepeatController";
import TrebbleCardController from "controllers/playlistPodcast/TrebbleCardController";
import JourneyCardController from "controllers/journey/JourneyCardController";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import ti18n from 'i18n!nls/MySubscriptionsPagei18n';
import PushNotificationHelper from "models/helper/PushNotificationHelper";
import FacebookEventTrackingHelper from "models/helper/FacebookEventTrackingHelper";
import SubscriptionManager from "models/helper/SubscriptionManager";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import MySubscriptionsPageView from "views/mySubscriptions/MySubscriptionsPageView";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import CapsuleCardController from "controllers/capsule/CapsuleCardController";
import RSVP from "rsvp";

const Rollout = RolloutHelper.getInstance();

const SHOW_CAPSULE_TABS = true;
const ENABLE_ADD_TO_SIRI_IN_THIS_PAGE = false;
const SHOULD_FEED_DISPLAYED = Rollout.getFeatureVariable(Rollout.FEATURES.ADD_MY_FEED_PAGE, RolloutHelper.FEATURES.ADD_MY_FEED_PAGE.variables.should_feed_info_displayed_in_subs_page, false);
const ENABLE_TREBBLE_PLUS_SUB = Rollout.isFeatureEnabled(Rollout.FEATURES.ENABLE_TREBBLE_PLUS_SUB);
//const NUMBER_OF_SUBSCRIPTIONS_TO_SUGGEST_TO_PLAY_FEED_FOR_FIRST_TIME = Rollout.getFeatureVariable(Rollout.FEATURES.SHOW_SUGGESTION_TO_LISTEN_TO_FEED, Rollout.FEATURES.SHOW_SUGGESTION_TO_LISTEN_TO_FEED.variables.min_number_of_subscriptions_to_suggest, 3);
//const SHOW_SUGGESTION_TO_LISTEN_TO_FEED = Rollout.isFeatureEnabled(Rollout.FEATURES.SHOW_SUGGESTION_TO_LISTEN_TO_FEED);

const ALWAYS_IN_EDIT_MODE = Rollout.isFeatureEnabled(Rollout.FEATURES.MY_SUBS_IN_ALWAYS_EDIT_MODE);
const HIDE_PUSH_NOTIFICATIONS = true;
const TIME_IN_MS_TO_WAIT_BEFORE_SENDING_ORDER_SAVING = Rollout.getFeatureVariable(Rollout.FEATURES.MY_SUBS_IN_ALWAYS_EDIT_MODE, RolloutHelper.FEATURES.MY_SUBS_IN_ALWAYS_EDIT_MODE.variables.time_to_wait_before_sending_save_req_in_ms, 3000);
const HIDE_ORDERING_INSTRUCTIONS = Rollout.getFeatureVariable(Rollout.FEATURES.MY_SUBS_IN_ALWAYS_EDIT_MODE, RolloutHelper.FEATURES.MY_SUBS_IN_ALWAYS_EDIT_MODE.variables.hide_instructions, true);

const MySubscriptionsPageController = Chaplin.Controller.extend({


    initialize: function(options) {
        _.extend(this, Backbone.Events);
        if (options) {
            if (options.view) {
                this.view = options.view;
            }
            if (options.model) {
                this.model = options.model;
            }
        }

        this.context = Utils.getInstance().buildContextForMySubscriptions();
        const playlist = PlaylistHelper.getInstance().generateMyCapsuleFeedPlaylist(LocalStorageHelper.getInstance().getUserInfo()._id);
        this._playlist = playlist;
        this._addSiriShortcutButtonCanBeDisplayed = false;
        this._siriShortcutWasAlreadyCreated = false;
        //this._userStartedTheSessionWithNoSubscription = false;
        this._numberOfSubscriptions = 0;
        this._listOfSubscriptionWasAlreadyFetched = false;
        this._isFirstLoad = false;
        this._listenToPlayerEvents();
        this.listenTo(PushNotificationHelper.getInstance(), "onDailyNotificationRegistrationChange", this.onUserRegistrationForDailyNotificationChanged.bind(this));
        this.listenTo(SubscriptionManager.getInstance(),SubscriptionManager.getInstance().eventTypes.ON_SUBSCRIPTION_UPDATED, this.onUserSubscriptionChanged.bind(this));
        document.addEventListener('playingCapsuleFeed', this.onPlayingCapsuleFeed, false);
    },

    getModel: function() {
        return this.model;
    },

    getView: function() {
        return this.view;
    },

    onUserSubscriptionChanged : function(productInfo){
        if(SHOULD_FEED_DISPLAYED && ENABLE_TREBBLE_PLUS_SUB && SubscriptionManager.getInstance().isPaidAccountSubscriptionOrderAvailable()){
            return SubscriptionManager.getInstance().isCurrentUserSubscribedToTrebblePlus(false, productInfo? true:false).then((function(isSubscribedToTrebblePlus){
                if(isSubscribedToTrebblePlus){
                    this.view.hideUpgradeBtn();
                }else{
                    this.view.showUpgradeBtn();
                }
            }).bind(this));
        }else{
            this.view.hideUpgradeBtn();
        }
    },


    onPlayingCapsuleFeed: function() {
        const TIME_IN_SECONDS_TO_WAIT_BEFORE_PROMPTING_USER_TO_RATE = Rollout.getFeatureVariable(Rollout.FEATURES.PROMPT_USER_TO_RATE_APP, Rollout.FEATURES.PROMPT_USER_TO_RATE_APP.variables.number_of_seconds_to_wait_before_prompting_user_to_rate, 15);
        const functionToExecute = function(){
            PersistentModels.getInstance().getRouter().showAppRatingRequestIfApplicable();
        };
        Utils.getInstance().setTimeoutAndShowAsSoonAsAppGoesInForeground(functionToExecute, TIME_IN_SECONDS_TO_WAIT_BEFORE_PROMPTING_USER_TO_RATE * 1000);

    },

    getConstructorChooserForCollection : function(options){
        if(options.model && options.model.get("type") == "journey"){
            const journeyModel = Utils.getInstance().buildJourneySummaryFromJson(options.model.toJSON());
            options.model = journeyModel;
            return new JourneyCardController(options);
        }else{
            const playlistModel = Utils.getInstance().buildPlaylistSummaryFromJson(options.model.toJSON());
            options.model = playlistModel;
            return new TrebbleCardController(options);
        }

    },

    _buildMySubscriptionsListController: function() {



        const controllerParams = {};

        controllerParams.customImageUrlProperty = "data-original";
        controllerParams.notTransparent = true;
        controllerParams.smallcardsize = true;
        controllerParams.notPlayable = true;

        const collectionRepeatControllerParams = {};
        collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildInfoBoxParamsForMySubscriptions();
        collectionRepeatControllerParams.showEmtptyInfoBox = true;
        collectionRepeatControllerParams.controllerOption = controllerParams;
        collectionRepeatControllerParams.itemControllerConstructor = this.getConstructorChooserForCollection;
        collectionRepeatControllerParams.modelLoadHandler = this.getPlaylistListLoadHandler().bind(this);
        collectionRepeatControllerParams.context = this.context;
        collectionRepeatControllerParams.resizePlaceHolderElem = true;
        collectionRepeatControllerParams.actionButtonHandlerFunction = this.showExplorePage.bind(this);
        collectionRepeatControllerParams.heightMargin = 52 + 39; //Page Header + Tab Header

        this.mySubscriptionsListController = new CollectionRepeatController(collectionRepeatControllerParams);
        this.listenTo(this.mySubscriptionsListController, "startLoading", this.onCapsuleListStartLoading.bind(this));
        this.listenTo(this.mySubscriptionsListController, "finishLoading", this.onCapsuleListFinishLoading.bind(this));
        return this.mySubscriptionsListController;
    },

    showExplorePage: function() {
        return PersistentModels.getInstance().getRouter().showExplorePage(null, true);
    },

    _listenToPlayerEvents: function() {

        this.listenTo(PersistentModels.getInstance().getPlayer(), "songStartedPlaying", this.updatePlaybackButton.bind(this));
        this.listenTo(PersistentModels.getInstance().getPlayer(), "songLoading", this.updatePlaybackButton.bind(this));
        this.listenTo(PersistentModels.getInstance().getPlayer(), "playerLoading", this.updatePlaybackButton.bind(this));
        this.listenTo(PersistentModels.getInstance().getPlayer(), "playerFinishLoading", this.updatePlaybackButton.bind(this));
        this.listenTo(PersistentModels.getInstance().getPlayer(), "songLoaded", this.updatePlaybackButton.bind(this));
        this.listenTo(PersistentModels.getInstance().getPlayer(), "songFailedLoading", this.updatePlaybackButton.bind(this));
        this.listenTo(PersistentModels.getInstance().getPlayer(), "songPaused", this.updatePlaybackButton.bind(this));
        this.listenTo(PersistentModels.getInstance(), "subscriptionChanged", this.onSubscriptionChanged);
    },

    getPlaylistListLoadHandler: function() {
        return (function() {
            let getFollowedTrebblesPromise = null;
            /*if (!this._isFirstLoad) {
                getFollowedTrebblesPromise = RSVP.Promise.resolve(PersistentModels.getInstance().getMyFollowedRadioInfoArray());
                this._isFirstLoad = true;
            } else {
                getFollowedTrebblesPromise = TrebbleClientAPIHelper.getInstance().getFollowedTrebblesForUserWithUsername(LocalStorageHelper.getInstance().getUserInfo().username);
            }*/
            getFollowedTrebblesPromise = TrebbleClientAPIHelper.getInstance().getFollowedTrebblesForUserWithUsername(LocalStorageHelper.getInstance().getUserInfo().username);
            return getFollowedTrebblesPromise.then((function(trebbleJsonArray) {
                const playlistSummaryModelArray = [];
                PersistentModels.getInstance().setMyFollowedRadioInfoArray(trebbleJsonArray);
                if (trebbleJsonArray) {
                    for (let i = 0; i < trebbleJsonArray.length; i++) {
                        playlistSummaryModelArray.push(Utils.getInstance().buildPlaylistSummaryFromJson(trebbleJsonArray[i]));
                    }
                }
                if (!this._listOfSubscriptionWasAlreadyFetched) {
                    /*if(!playlistSummaryModelArray.length){
                        this._userStartedTheSessionWithNoSubscription = true;
                    }*/
                    this._listOfSubscriptionWasAlreadyFetched = true;
                }
                this._numberOfSubscriptions = playlistSummaryModelArray.length;
                return playlistSummaryModelArray;
            }).bind(this));
}).bind(this);
},


getNumbrOfSubscriptions: function() {
return this._numberOfSubscriptions;
},

onSubscriptionChanged: function() {
this.onPageBeforeShow(true);
},

isUserStartedTheSessionWithNoSubscription: function() {
return this._userStartedTheSessionWithNoSubscription;
},


onPlaylistSelected: function(playlistSummarySelectedModel) {
PersistentModels.getInstance().getRouter().showPublicRadioDetailPage(playlistSummarySelectedModel, true);
},

updatePlaybackButton: function() {
if (this._playlist) {
if (PersistentModels.getInstance().getPlayer().isPlaylistCurrentlyLoaded(this._playlist)) {
    if (PersistentModels.getInstance().getPlayer().isLoading() || PersistentModels.getInstance().getPlayer().isPlayerLoading()) {
        this.view.setPlaying(false, true);
    } else {
        if (PersistentModels.getInstance().getPlayer().isPlaying()) {
            this.view.setPlaying(true, false);
        } else {
            this.view.setPlaying(false, false);
        }
    }
} else {
    this.view.setPlaying(false, false);
}
} else {
this.view.setPlaying(false, false);
}

},

onPlaylistPressedHandler: function(playlistSummarySelectedModel) {
if (playlistSummarySelectedModel) {
const audioPreviewUrl = playlistSummarySelectedModel.getAudioPreviewUrl();
if (audioPreviewUrl) {
    PersistentModels.getInstance().getPlayer().playAudioPreview(audioPreviewUrl);
} else {
    Utils.getInstance().showShortToastMessage(window.getI18n(ti18n, "NO_PREVIEWS_AVAILABLE") + " " + window.getI18n(ti18n, "PREVIEWS_ARE_AUTOMATICALLY_CREATED_EVERY_X_HOURS"));
}
}
},

onPlaylistPressedUpHandler: function(playlistSummarySelectedModel) {
PersistentModels.getInstance().getPlayer().terminateAudioPreviewAndResumePlayback();
},

_onPersonalRadioEditButtonClicked: function(playlistSummarySelectedModel) {
PersistentModels.getInstance().getRouter().showTrebbleInfoEditPage(playlistSummarySelectedModel, true);
},

_onPersonalRadioShareButtonClicked: function(playlistSummarySelectedModel) {
PersistentModels.getInstance().getRouter().showTrebbleSharePopup(playlistSummarySelectedModel, true);
},

onPlaylistPlayButtonTapedHandler: function(playlistSummarySelectedModel) {
if (PlayerService.getInstance().isPlaylistCurrentlyLoaded(playlistSummarySelectedModel)) {
if (PlayerService.getInstance().isMusicPlaying()) {
    PlayerService.getInstance().pauseMusicPlaying();
} else {
    PlayerService.getInstance().resumeMusicPlaying();
}
} else {
PlayerService.getInstance().playTrebble(playlistSummarySelectedModel);
}
},



_initializeMyCapsuleFeed: function() {
const controllerParams = {};
controllerParams.showActiveFlagInView = false;
controllerParams.context = this.context;
controllerParams.onActionExecutedOnUserHandler = this._onActionExecutedByUserOnDisplayedCapsule.bind(this);
const collectionRepeatControllerParams = {};
collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildInfoBoxParamsForOffAirCapsules();
collectionRepeatControllerParams.showEmtptyInfoBox = true;
collectionRepeatControllerParams.controllerOption = controllerParams;
collectionRepeatControllerParams.itemControllerConstructor = CapsuleCardController;
collectionRepeatControllerParams.modelLoadHandler = this._getMyCapsuleFeedLoadHandler.bind(this);
collectionRepeatControllerParams.context = this.context;
collectionRepeatControllerParams.resizePlaceHolderElem = true;
collectionRepeatControllerParams.heightMargin = 52 + 39; //Page Header + Tab Header
this._myCapsuleFeedListLoaded = false;
this.myCapsuleFeedCollectionRepeatController = new CollectionRepeatController(collectionRepeatControllerParams);
return this.myCapsuleFeedCollectionRepeatController;
},


_buildInfoBoxParamsForMyCapsuleFeed: function() {
const params = {};
params.iconClass = "ion-contrast";
params.message = window.getI18n(ti18n, "YOUR_CAPSULE_FEED_IS_EMPTY");


return params;

},


_buildInfoBoxParamsForMySubscriptions: function() {
const params = {};
params.iconClass = "pe-7s-radio";
params.message = window.getI18n(ti18n, "YOU_RE_NOT_SUBSCRIBED_TO_ANY_TREBBLE");
params.helpMessage = window.getI18n(ti18n, "SUBSCRIBE_TO_TREBBLE_YOU_LIKE");
params.actionButtonLabel = window.getI18n(ti18n, "ADD_SUBSCRIPTIONS");
return params;

},

showHomePopup: function() {
PersistentModels.getInstance().getRouter().showHomePage();
},

_getMyCapsuleFeedLoadHandler: function() {

return TrebbleClientAPIHelper.getInstance().getMyCapsuleFeed().then(function(capsulesInfoArray) {
if (capsulesInfoArray && capsulesInfoArray.length > 0) {
    this.capsulesCollection = new Capsules(capsulesInfoArray);
    return this.capsulesCollection.models;
} else {
    return [];
}
});
},

displayAddSiriButtonIfApplicable: function() {
const addShortcutToPlayCapsuleFeedSuggestionBoxWrapper$el = this.view.$el.find(".addShortcutToPlayCapsuleFeedSuggestionBoxWrapper");
const shortcutPhraseToPlayCapsuleFeedBoxWrapper$el = this.view.$el.find(".shortcutPhraseToPlayCapsuleFeedBoxWrapper");
if (ENABLE_ADD_TO_SIRI_IN_THIS_PAGE && 'cordova' in window && window.cordova && window.cordova.plugins && window.cordova.plugins.SiriShortcuts && window.isIOSMobileDevice) {
this._addSiriShortcutButtonCanBeDisplayed = true;
if (LocalStorageHelper.getInstance().isShortcutToPlayCapsuleFeedWasAddedToSiri() && LocalStorageHelper.getInstance().getShortcutPhraseToPlayCapsuleFeedWasAddedToSiri()) {
    this._siriShortcutWasAlreadyCreated = true;
    if (!addShortcutToPlayCapsuleFeedSuggestionBoxWrapper$el.hasClass("hide")) {
        addShortcutToPlayCapsuleFeedSuggestionBoxWrapper$el.addClass("hide");
    }
    if (shortcutPhraseToPlayCapsuleFeedBoxWrapper$el.hasClass("hide")) {
        shortcutPhraseToPlayCapsuleFeedBoxWrapper$el.removeClass("hide");
    }
    shortcutPhraseToPlayCapsuleFeedBoxWrapper$el.find(".customPhrase").html(LocalStorageHelper.getInstance().getShortcutPhraseToPlayCapsuleFeedWasAddedToSiri());
} else {
    this._siriShortcutWasAlreadyCreated = false;
    if (addShortcutToPlayCapsuleFeedSuggestionBoxWrapper$el.hasClass("hide")) {
        addShortcutToPlayCapsuleFeedSuggestionBoxWrapper$el.removeClass("hide");
    }
    if (!shortcutPhraseToPlayCapsuleFeedBoxWrapper$el.hasClass("hide")) {
        shortcutPhraseToPlayCapsuleFeedBoxWrapper$el.addClass("hide");
    }
}
} else {
this._addSiriShortcutButtonCanBeDisplayed = false;
this._siriShortcutWasAlreadyCreated = false;
if (!addShortcutToPlayCapsuleFeedSuggestionBoxWrapper$el.hasClass("hide")) {
    addShortcutToPlayCapsuleFeedSuggestionBoxWrapper$el.addClass("hide");
}
if (!shortcutPhraseToPlayCapsuleFeedBoxWrapper$el.hasClass("hide")) {
    shortcutPhraseToPlayCapsuleFeedBoxWrapper$el.addClass("hide");
}

}
},

showHideAddToSiriButtonOnScroll: function() {
if (ENABLE_ADD_TO_SIRI_IN_THIS_PAGE && this._addSiriShortcutButtonCanBeDisplayed) {
const addSiriShortcustButtonFunc = (function() {
    if (this._addSiriShortcutButtonCanBeDisplayed) {
        this._pendingshowHideAddToSiriButtonFuncExecutionId = null;
        if (this._siriShortcutWasAlreadyCreated) {
            let modifySiriBoxSuggestionVisible = false;
            let buttonLabel = window.getI18n(ti18n, "MODIFY_SIRI_PHRASE");
            if (this.shortcutPhraseToPlayCapsuleFeedBoxWrapper$el.visible()) {
                modifySiriBoxSuggestionVisible = true;
            }
            this.addToSiriBtnFloatingButton$el.find(".label").html(buttonLabel);
            if (!modifySiriBoxSuggestionVisible && !this.view.isInEditMode()) {
                this.addToSiriBtnFloatingButton$el.removeClass("hide");
            } else {
                if (!this.addToSiriBtnFloatingButton$el.hasClass("hide")) {
                    this.addToSiriBtnFloatingButton$el.addClass("hide");
                }
            }
        } else {
            let addSiriBoxSuggestionVisible = false;
            let buttonLabel = window.getI18n(ti18n, "ADD_TO_SIRI");
            if (this.addShortcutToPlayCapsuleFeedSuggestionBoxWrapper$el.visible()) {
                addSiriBoxSuggestionVisible = true;
            }
            this.addToSiriBtnFloatingButton$el.find(".label").html(buttonLabel);
            if (!addSiriBoxSuggestionVisible && !this.view.isInEditMode()) {
                this.addToSiriBtnFloatingButton$el.removeClass("hide");
            } else {
                if (!this.addToSiriBtnFloatingButton$el.hasClass("hide")) {
                    this.addToSiriBtnFloatingButton$el.addClass("hide");
                }
            }
        }
    }
}).bind(this);
addSiriShortcustButtonFunc();
}
},

updateOnFeedTodayBox: function() {
if (!this.view.isOnYourFeedTodayBoxHidden()) {
this.view.setFeedBoxLoading();
return TrebbleClientAPIHelper.getInstance().getTrebbleNamesAndLatestHeadlinesInUserSubsription().then((function(trebbleNamesAndLatestHeadlinesInUserSubsription) {
    if (trebbleNamesAndLatestHeadlinesInUserSubsription && trebbleNamesAndLatestHeadlinesInUserSubsription.length > 0) {
        this.view.setFeedBoxContent(trebbleNamesAndLatestHeadlinesInUserSubsription);
    } else {
        this.view.setFeedBoxHasNoContent();
    }
    this.view.showHideFloatingPlayButtonOnScrollIfApplicable();
}).bind(this)).catch((function(error) {
    this.view.setErrorOnFeedBoxLoading(error);

}).bind(this));
}
},

onPageBeforeShow: function(pageRefreshWasTriggerBecauseOfSubscriptionChange) {
/*if(window.trebbleAnalyticsHelper){
window.trebbleAnalyticsHelper.trackEvent("pageShow", 'MySubscriptionPageShow', 'My Subscription Page Show');
}*/
if (this.myCapsuleFeedCollectionRepeatController && this.myCapsuleFeedCollectionRepeatController.getView()) {
this.myCapsuleFeedCollectionRepeatController.reload();
}

if (this.mySubscriptionsListController && this.mySubscriptionsListController.getView() && !this.mySubscriptionsListController.isLoading()) {
    let previousScrollPosition = null;
if(this.view && this.view.$el){
previousScrollPosition = this.view.$el.find("#subscriptionList").get(0).scrollTop;
}
this.mySubscriptionsListController.reload().then((function() {
this.promptUserToEmableNotificationIfApplicable();
/*if(pageRefreshWasTriggerBecauseOfSubscriptionChange && this.mySubscriptionsListController.getNumberOfModels() == 1 && this._userStartedTheSessionWithNoSubscription){
 PersistentModels.getInstance().getRouter().showFirstSubscriptionAddedHighfivePopupIfApplicable();
}*/
if (this.mySubscriptionsListController.getNumberOfModels() > 0 && PersistentModels.getInstance().getRouter().isUserStartedTheSessionWithNoSubscription()) {
PersistentModels.getInstance().getRouter().showSuggestionToListenToFeedForFirstTimeWasDisplayedPopupIfApplicable();
}
if(this.view && this.view.$el){
this.view.$el.find("#subscriptionList").get(0).scrollTop = previousScrollPosition;
}
/*if(SHOW_SUGGESTION_TO_LISTEN_TO_FEED){
if(pageRefreshWasTriggerBecauseOfSubscriptionChange){
if(this.mySubscriptionsListController.getNumberOfModels() == NUMBER_OF_SUBSCRIPTIONS_TO_SUGGEST_TO_PLAY_FEED_FOR_FIRST_TIME && this._userStartedTheSessionWithNoSubscription){
PersistentModels.getInstance().getRouter().showSuggestionToListenToFeedForFirstTimeWasDisplayedPopupIfApplicable();
}
}else{
if(this.mySubscriptionsListController.getNumberOfModels() > 0 && this._userStartedTheSessionWithNoSubscription){
PersistentModels.getInstance().getRouter().showSuggestionToListenToFeedForFirstTimeWasDisplayedPopupIfApplicable();
}
}

}*/
}).bind(this));
}
this.updatePlaybackButton();
this.displayAddSiriButtonIfApplicable();
this.displayEnableDailyNotificationBoxIfApplicable();
this.showHideAddToSiriButtonOnScroll();
this.updateOnFeedTodayBox();


},

shouldUserBeInviteToTurnOnNotification: function() {
return !HIDE_PUSH_NOTIFICATIONS && window.waitForCordovaToLoad && window.isIOSMobileDevice && !PushNotificationHelper.getInstance().isUserRegisteredForDailyNotification() && !this.view && this.view.isPageDisplayed() && Rollout.isFeatureEnabled(Rollout.FEATURES.TURN_ON_NOTIFICATION_ON_IOS) && PersistentModels.getInstance().getNumberOfSubscriptions() > 0 && !LocalStorageHelper.getInstance().isEnableNotificationToggleDiscardedByUser();
},

promptUserToEmableNotificationIfApplicable: function() {
try {
if (this.shouldUserBeInviteToTurnOnNotification()) {
    setTimeout((function() {
        this.onUserEnableDisableDailyNotification(true, true);
        LocalStorageHelper.getInstance().setLoggedInUserHasBeenPromptedToTurnOnNotifications();
    }).bind(this), Rollout.getFeatureVariable(Rollout.FEATURES.TURN_ON_NOTIFICATION_ON_IOS, Rollout.FEATURES.TURN_ON_NOTIFICATION_ON_IOS.variables.time_in_millisec_to_wait_before_prompting, 3000));
}
} catch (error) {
console.error(error);
}
},

displayEnableDailyNotificationBoxIfApplicable: function() {
if (this.shouldUserBeInviteToTurnOnNotification()) {
this.view.showHideEnableDailyNotificationBox(true);
} else {
this.view.showHideEnableDailyNotificationBox(false);
}
},

onAddSiriButtonClicked: function() {
if (window.SiriIntentHelper && window.SiriIntentHelper.inviteUserToAddAShortcutToPlayCapsuleFeed) {
return new RSVP.Promise((function(resolve, reject) {
    window.SiriIntentHelper.inviteUserToAddAShortcutToPlayCapsuleFeed(LocalStorageHelper.getInstance().getUserInfo()._id, resolve, reject);
    if (window.trebbleAnalyticsHelper) {
        window.trebbleAnalyticsHelper.trackEvent("SiriShortcuts", 'InviteUserToAddShortcutToPlayCapsuleFeed', 'Invite User To Add A Shortcut To Play Capsule Feed');
    }
}).bind(this)).then((function(results) {
    let phraseChosenByUser;
    switch (parseInt(results.code)) {
        case 1:
        //shortcut created
        phraseChosenByUser = results.phrase;
        if (phraseChosenByUser) {
            LocalStorageHelper.getInstance().setShortcutToPlayCapsuleFeedWasAddedToSiri(true);
            LocalStorageHelper.getInstance().setShortcutPhraseToPlayCapsuleFeedWasAddedToSiri(phraseChosenByUser);

        }
        FacebookEventTrackingHelper.getInstance().trackAddedShortcutToSiri();
        if (window.trebbleAnalyticsHelper) {
            window.trebbleAnalyticsHelper.trackEvent("SiriShortcuts", 'ShortcutToPlayCapsuleFeedCreated', 'Shortcut to play capsule feed was created');
        }
        break;
        case 2:
        //shortcut modified
        phraseChosenByUser = results.phrase;
        if (phraseChosenByUser) {
            LocalStorageHelper.getInstance().setShortcutToPlayCapsuleFeedWasAddedToSiri(true);
            LocalStorageHelper.getInstance().setShortcutPhraseToPlayCapsuleFeedWasAddedToSiri(phraseChosenByUser);

        }
        FacebookEventTrackingHelper.getInstance().trackModifiedShortcutToSiri();
        if (window.trebbleAnalyticsHelper) {
            window.trebbleAnalyticsHelper.trackEvent("SiriShortcuts", 'ShortcutToPlayCapsuleFeedModified', 'Shortcut to play capsule feed was modified');
        }
        break;
        case 3:
        //shortcut deleted
        LocalStorageHelper.getInstance().setShortcutToPlayCapsuleFeedWasAddedToSiri(false);
        LocalStorageHelper.getInstance().setShortcutPhraseToPlayCapsuleFeedWasAddedToSiri(null);
        FacebookEventTrackingHelper.getInstance().trackDeletedShortcutToSiri();
        if (window.trebbleAnalyticsHelper) {
            window.trebbleAnalyticsHelper.trackEvent("SiriShortcuts", 'ShortcutToPlayCapsuleFeedDeleted', 'Shortcut to play capsule feed was deleted');
        }
        break;
        case 4:
        //shortcut dismissed
        break;
        case 5:
        //internal error
        break;
        case 6:
        //error.Migth not run iOS 12
        window.alertErrorMessage(window.getI18n(ti18n, "OOPS_REQUIRE_VERSION_X_OF_IOS"));
        if (window.trebbleAnalyticsHelper) {
            window.trebbleAnalyticsHelper.trackEvent("SiriShortcuts", 'ShortcutToPlayCapsuleFeedError', 'Shortcut was not available on iOS');
        }
        break;
        default:
        //Do nothing
        break;
    }
    this.displayAddSiriButtonIfApplicable();
}).bind(this)).catch((function(error) {
    window.alertErrorMessage(window.getI18n(ti18n, "OOPS_SOMETHING_WENT_WRONG_WHEN_SETTING_UP_A_SHORTCUT"));
    this.displayAddSiriButtonIfApplicable();
}).bind(this))
}
},

playCapsuleFeed: function() {
if (this.view.hasNoSuscription()) {
this.onPlayMyFeedWithNoSubscriptions();
} else {
if (this._playlist && PersistentModels.getInstance().getPlayer().isPlaylistCurrentlyLoaded(this._playlist)) {
    if (PersistentModels.getInstance().getPlayer().isLoading()) {

        return;
    } else {
        if (PersistentModels.getInstance().getPlayer().isPlaying()) {
            return PersistentModels.getInstance().getPlayer().pauseAudio();
        } else {
            return PersistentModels.getInstance().getPlayer().playAudio();
        }
    }
} else {
    this.view.setPlaying(false, true);
    FacebookEventTrackingHelper.getInstance().trackCapsuleFeedPlayed();
    if (window.trebbleAnalyticsHelper) {
        window.trebbleAnalyticsHelper.trackEvent("MySubscriptions", 'CapsuleFeedPlayed', 'Capsule Feed Played');
    }
    return PersistentModels.getInstance().getPlayer().setPlaylist(this._playlist, true).then((function() {

    }).bind(this)).catch((function(error) {
        this.view.setPlaying(false, false);
    }).bind(this));
}
}

},

saveOrderChanges: function() {
this.view.enableSavingModeEditMode();
TrebbleClientAPIHelper.getInstance().saveMyTrebbleSubscriptionOrder(this.view.getOrderSubscriptionIds()).then((function() {
this.view.disableEditMode();
this.onPageBeforeShow();
FacebookEventTrackingHelper.getInstance().trackSubscriptionPlayBackOrderSaved();
if (window.trebbleAnalyticsHelper) {
    window.trebbleAnalyticsHelper.trackEvent("MySubscriptions", 'SubscriptionPlayBackOrderSaved', 'Saved your subscriptions playback order');
}
}).bind(this)).catch((function(error) {
window.alertErrorMessage(error);
this.view.cancelSavingModeEditMode();
}).bind(this))

},

cancelOrderChanges: function() {
this.view.disableEditMode();
this.onPageBeforeShow();
if (window.trebbleAnalyticsHelper) {
window.trebbleAnalyticsHelper.trackEvent("MySubscriptions", 'SubscriptionPlayBackOrderChangesCancelled', 'Cancel playback order changes without saving');
}
},

onCapsuleListStartLoading: function() {
if (this.view) {
this.view.setLoadingSubscriptions(true);
}
},

onCapsuleListFinishLoading: function() {
if (this.view) {
this.view.setLoadingSubscriptions(false);
}
if (this.mySubscriptionsListController) {
this.view.setNoSubscription(!this.mySubscriptionsListController.getNumberOfModels());
}
this.view.$el.find("#subscriptionList #collection_repeat_container").trigger("scrollstop");
},

enableDailyNotificationConfirmed: function() {
PushNotificationHelper.getInstance().initNativePushNotification().then((function() {
this.view.setDailyNotification(true);
}).bind(this)).catch((function(error) {
window.alertErrorMessage(error);
this.view.setDailyNotification(false);
}).bind(this));
},


onUserEnableDisableDailyNotification: function(on, isAnAutomaticPrompt) {
if (on) {

const buttonLabels = isAnAutomaticPrompt ? [window.getI18n(ti18n, "MAYBE_LATER"), window.getI18n(ti18n, "NOTIFY_ME")] : [window.getI18n(ti18n, "NOT_NOW"), window.getI18n(ti18n, "TURN_IT_ON")];
const confirmCallback = (function(buttonIndex) {
    if (buttonIndex == 2) {
        LocalStorageHelper.getInstance().setLoggedInUserManuallyAgreedToTurnOnNotification();
        this.enableDailyNotificationConfirmed();
        FacebookEventTrackingHelper.getInstance().trackUserManuallyAgreedToTurnOnNotification();
        if (window.trebbleAnalyticsHelper) {
            window.trebbleAnalyticsHelper.trackEvent("MySubscriptions", 'UserManuallyAgreedToTurnOnNotification', 'User Manually Agreed To Turn On Notification');
        }
    } else {
        this.view.setDailyNotification(false);
        FacebookEventTrackingHelper.getInstance().trackUserDidNotAgreeToTurnOnNotification();
        if (window.trebbleAnalyticsHelper) {
            window.trebbleAnalyticsHelper.trackEvent("MySubscriptions", 'UserDidNotAgreeToTurnOnNotification', 'User Did Not Agree To Turn On Notification');
        }
    }
}).bind(this);

const message = isAnAutomaticPrompt ? window.getI18n(ti18n, "WANT_TO_KNOW_WHEN_NEW_SHORTCASTS_ARE_AVAILABLE") : window.getI18n(ti18n, "YOU_WILL_NEED_TO_TURN_ON_NOTIFICATIONS");
const subMessage = isAnAutomaticPrompt ? window.getI18n(ti18n, "TURN_ON_NOTIFICATION_AND_WELL_NOTIFY_YOU") : window.getI18n(ti18n, "YOU_WILL_BE_NOTIFIED_EACH_MORNING");

navigator.trebbleNotification.confirm(message, confirmCallback, window.getI18n(ti18n, "YOU_WILL_NEED_TO_TURN_ON_NOTIFICATIONS"), buttonLabels, null, null, subMessage);
} else {
PushNotificationHelper.getInstance().unregisterNativePushNotification().then((function() {
this.view.setDailyNotification(false);
}).bind(this)).catch((function(error) {
window.alertErrorMessage(error);
this.view.setDailyNotification(true);
}).bind(this));
}
},

onUserRegistrationForDailyNotificationChanged: function(notificationTurnedOn) {
this.view.setDailyNotification(notificationTurnedOn)
},

onPlayMyFeedWithNoSubscriptions: function() {
if (window.trebbleAnalyticsHelper) {
window.trebbleAnalyticsHelper.trackEvent("MySubscriptions", 'playMyFeedWithNoSubscriptions', 'User tried to play capsule feed with no subscription');
}

const buttonLabels = [window.getI18n(ti18n, "GOT_IT"), window.getI18n(ti18n, "ADD_SUBSCRIPTIONS")];
const confirmCallback = (function(buttonIndex) {
/*if(buttonIndex ==  2){
 this.showExplorePage();
}else{

}*/
this.showExplorePage();
}).bind(this);

const message = window.getI18n(ti18n, "NOTHING_TO_PLAY_YET_SUBSCRIBE_FIRST");
//navigator.trebbleNotification.alert(message , confirmCallback,null, null, null, window.getI18n(ti18n, "ADD_SUBSCRIPTIONS"));
navigator.trebbleNotification.confirm(message, confirmCallback, null, [window.getI18n(ti18n, "ADD_SUBSCRIPTIONS")]);
},

showSetupInstructionsToListenOnVoiceEnabledDevices: function() {
PersistentModels.getInstance().getRouter().showSetupVoiceEnabledDevicePopup();
},

enableNotificationToggleDiscardedByUser: function() {
LocalStorageHelper.getInstance().setEnableNotificationToggleDiscardedByUser();
this.displayEnableDailyNotificationBoxIfApplicable();
},

showUserRefferalpage: function() {
PersistentModels.getInstance().getRouter().showReferralPage(null, true);
},

showUpgradeToPaidSubscriptionPopup: function() {
PersistentModels.getInstance().getRouter().showUpgradeToPaidSubscriptionPopup();
},


showHideRefferalUserPageButtonIfApplicable: function() {
if (!Rollout.isFeatureEnabled(Rollout.FEATURES.USER_REFERRAL) || !Utils.getInstance().canReferralLinkBeGenerated()) {
this.view.showHideReferUserMenuBtn(false);
} else {
this.view.showHideReferUserMenuBtn(true);
}
},

hideFeedInformationIfApplicable : function(){
if(!SHOULD_FEED_DISPLAYED){
this.view.hideOnYourFeedTodayBox();
this.view.hideListenOnVoiceEnabledDeviceBtn();
this.view.hidePlayFeedButtons();
}
},



setInAlwaysEditModeIfApplicable :function(){
if(ALWAYS_IN_EDIT_MODE){
this.view.setInAlwaysEditMode(true, HIDE_ORDERING_INSTRUCTIONS);
}
},

onSubscriptionSortingChanged : function(){
if(ALWAYS_IN_EDIT_MODE){
if(this._lastPendingRequest){
    clearTimeout(this._lastPendingRequest);
}
this._lastPendingRequest = setTimeout((function(){
    this.self._lastPendingRequest = null;
    TrebbleClientAPIHelper.getInstance().saveMyTrebbleSubscriptionOrder(this.self.view.getOrderSubscriptionIds()).then((function() {
        FacebookEventTrackingHelper.getInstance().trackSubscriptionPlayBackOrderSaved();
        if (window.trebbleAnalyticsHelper) {
            window.trebbleAnalyticsHelper.trackEvent("MySubscriptions", 'SubscriptionPlayBackOrderSaved', 'Saved your subscriptions playback order');
        }
    }).bind(this.self))
}).bind({"self" :this}),TIME_IN_MS_TO_WAIT_BEFORE_SENDING_ORDER_SAVING);
}
},

showHelpPage : function(){
PersistentModels.getInstance().getRouter().showHelpAndFeedback(null, true);
},


buildModelAndRenderView: function() {
return new RSVP.Promise((function(resolve, reject) {
try {
    this._buildMySubscriptionsListController();


    const promiseArray = [];
    promiseArray.push(this.mySubscriptionsListController.buildModelAndRenderView());


    RSVP.Promise.all(promiseArray).then((function() {
        this.mySubscriptionsListView = this.mySubscriptionsListController.getView();



        const mySubscriptionPageParams = {};
        mySubscriptionPageParams.mySubscriptionsListView = this.mySubscriptionsListView;

        this.view = new MySubscriptionsPageView(mySubscriptionPageParams);

        this.listenTo(this.view, "pageBeforeShow", this.onPageBeforeShow);

        this.listenTo(this.view, "playMyFeed", this.playCapsuleFeed.bind(this));
        this.listenTo(this.view, "showExplorePage", this.showExplorePage.bind(this));
        this.listenTo(this.view, "showHomePopup", this.showHomePopup);
        this.listenTo(this.view, "showHelpPage", this.showHelpPage);
        this.listenTo(this.view, "addToSubscriptions", this.showExplorePage);
        this.listenTo(this.view, "saveOrderChanges", this.saveOrderChanges);
        this.listenTo(this.view, "cancelOrderChanges", this.cancelOrderChanges);
        this.listenTo(this.view, "enableNotificationToggleDiscardedByUser", this.enableNotificationToggleDiscardedByUser);
        this.listenTo(this.view, "onUserEnableDisableDailyNotification", this.onUserEnableDisableDailyNotification.bind(this));
        this.listenTo(this.view, "listenOnVoiceEnabledDeviceBtnClicked", this.showSetupInstructionsToListenOnVoiceEnabledDevices);
        this.listenTo(this.view, "showUserRefferalpage", this.showUserRefferalpage.bind(this));
        this.listenTo(this.view, "showUpgradeToPaidSubscriptionPopup", this.showUpgradeToPaidSubscriptionPopup.bind(this));
        this.listenTo(this.view, "subscriptionSortingChanged", this.onSubscriptionSortingChanged.bind(this));


        this.view.render();
        this.addShortcutToPlayCapsuleFeedSuggestionBoxWrapper$el = this.view.$el.find(".addShortcutToPlayCapsuleFeedSuggestionBoxWrapper");
        this.shortcutPhraseToPlayCapsuleFeedBoxWrapper$el = this.view.$el.find(".shortcutPhraseToPlayCapsuleFeedBoxWrapper");
        this.addToSiriBtnFloatingButton$el = this.view.$el.find(".addToSiriBtn.fixed");
        $(this.view.$el.find("#subscriptionList")).on("resize scroll", (function() {
            this.showHideAddToSiriButtonOnScroll()
        }).bind(this));
        this.view.$el.find(".addToSiriBtn").on("click", this.onAddSiriButtonClicked.bind(this));
        this.view.setDailyNotification(PushNotificationHelper.getInstance().isUserRegisteredForDailyNotification());
        this.showHideRefferalUserPageButtonIfApplicable();
        this.onUserSubscriptionChanged();
        this.setInAlwaysEditModeIfApplicable();
        this.hideFeedInformationIfApplicable();
        resolve();
    }).bind(this)).catch(reject);
} catch (error) {
reject(error);
}

}).bind(this));


}

});

export default MySubscriptionsPageController;