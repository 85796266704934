import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import RSVP from "rsvp";


const SequencerOperationUtils =  Backbone.Model.extend({
    idAttribute: "id",

    constructor: function(attributes, options) {
        Backbone.Model.apply(this, [attributes, options]);

    },

    getArrayOfSequencerNodes : function(startNode, endNode){
        const arrayToReturn = []
        let nextNodeToAdd = startNode;
        while(nextNodeToAdd){
            arrayToReturn.push(nextNodeToAdd)
            if(nextNodeToAdd == endNode){
                nextNodeToAdd = null;
            }else{
                nextNodeToAdd = nextNodeToAdd.getNext();
            }
        }
        return arrayToReturn;
    },

    isStartNodeAndEndNodeConnected : function(startNode, endNode){
        let foundEndNode = false;
        let nextNodeToNavigate = startNode;
        while(nextNodeToNavigate && !foundEndNode){
            if(nextNodeToNavigate == endNode){
                foundEndNode = true;
            }else{
                nextNodeToNavigate = nextNodeToNavigate.getNext();
            }
        }
        return foundEndNode;
    },

    insertAudioNodeArray : function(sequencer, arrayOfSequencerNodes, sequencerNodeToInsertNextTo, insertBefore ){
        if(arrayOfSequencerNodes && arrayOfSequencerNodes.length > 0){

            let lastInsertedNode = null;
            arrayOfSequencerNodes.forEach((function(sequencerNodeToInsert, index){

                if(index === 0){
                    if(!sequencerNodeToInsertNextTo){
                        sequencerNodeToInsert.addToSequencer();
                        sequencer.setFirstNode(sequencerNodeToInsert);
                    }
                    if(insertBefore){
                        if(sequencerNodeToInsertNextTo){
                            sequencerNodeToInsert.insertBefore(sequencerNodeToInsertNextTo, true);
                        }
                        lastInsertedNode = sequencerNodeToInsert;
                    }else{
                        if(sequencerNodeToInsertNextTo){
                            sequencerNodeToInsert.insertAfter(sequencerNodeToInsertNextTo, true);
                        }
                        lastInsertedNode = sequencerNodeToInsert;
                    }
                }else{
                    sequencerNodeToInsert.insertAfter(lastInsertedNode, true);
                    lastInsertedNode = sequencerNodeToInsert;
                }
            }).bind(this))

            if(insertBefore){
                if(sequencerNodeToInsertNextTo == sequencer.getFirstNode()){
                    sequencer.setFirstNode(lastInsertedNode);
                }
            }else{
                if(sequencerNodeToInsertNextTo == sequencer.getLastNode()){
                    sequencer.setLastNode(lastInsertedNode);
                }
            }
            if(!sequencerNodeToInsertNextTo){
                sequencer.setFirstNode(arrayOfSequencerNodes[0]);
                sequencer.setLastNode(lastInsertedNode);
            }

        }
    },

    getNodeArray : function(startNode, endNode, disconnectNode){
        const nodeArray = [];
        let nodeToAdd = startNode;
        while(nodeToAdd){
            nodeArray.push(nodeToAdd);
            const lastNodeAdded = nodeToAdd;
            if(nodeToAdd == endNode){
                nodeToAdd = null;
            }else{
                nodeToAdd = nodeToAdd.getNext();
            }
            if(disconnectNode){
                lastNodeAdded.clearPrevious();
                lastNodeAdded.clearNext();
            }
        }
        return nodeArray;
    },

    areNodeToReplaceConnectedToEachother : function(arrayOfNodeToReplace){
        if(arrayOfNodeToReplace && arrayOfNodeToReplace.length > 0){
            for(let i  =0 ; i  + 1< arrayOfNodeToReplace.length; i++){
                if(arrayOfNodeToReplace[i].getNext() !== arrayOfNodeToReplace[i + 1]){
                    return false;
                }
            }
            return true;
        }
        return false;
    },

    

    removeAudioNode : function(sequencer, audioSequencerNode){
        const nextNode =  audioSequencerNode.getNext();
        const previousNode =  audioSequencerNode.getPrevious();

        audioSequencerNode.clearNext();
        if(previousNode){
            previousNode.clearNext();
        }
        if(previousNode && nextNode){
            previousNode.setNext(nextNode);
        }
        if(audioSequencerNode == sequencer.getFirstNode()){
            sequencer.setFirstNode(nextNode);
        }

        if(audioSequencerNode == sequencer.getLastNode()){
            sequencer.setLastNode(previousNode);
        }

        audioSequencerNode.removeFromSequencer();
    },

    removeAudioNodeArray : function(sequencer, arrayOfSequencerNodes){
        if(arrayOfSequencerNodes && arrayOfSequencerNodes.length > 0){

            arrayOfSequencerNodes.forEach((function(sequencerNodeToRemove){
                this.removeAudioNode(sequencer, sequencerNodeToRemove);
            }).bind(this))

        }
    },


});


const sequencerOperationUtilsInstance = new SequencerOperationUtils();

export default {
	getInstance : function() {
		return sequencerOperationUtilsInstance;
	}
}; 