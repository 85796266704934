import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import CommentListItemController from "controllers/comment/CommentListItemController";
import CommentBoxController from "controllers/comment/CommentBoxController";
import CollectionRepeatController from "controllers/common/CollectionRepeatController";
import SongContextController from "controllers/common/SongContextController";
import CapsuleContextController from "controllers/common/CapsuleContextController";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import Comments from "collections/Comments";
import DynamicContentView from "views/common/DynamicContentView";
import ti18n from "i18n!nls/Commenti18n";
import RSVP from "rsvp";

const CommentSummaryController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = options.model;
        this._disableReply = options.disableReply;
        this._showContext = options.showContext;
        this._idOfCommentToFocusOn = options.idOfCommentToFocusOn;
        this.songRefSummary = options.songRefSummary;
        this._doNotShowEmptyBox = options.doNotShowEmptyBox?options.doNotShowEmptyBox : false ;
        this.commentsCollection = null;
        this._setHeightToFitContainer = options && options.setHeightToFitContainer ? options.setHeightToFitContainer : false;
        this.context = options.context;
        const controllerParams = {};
        controllerParams.disableReply = this._disableReply;
        controllerParams.context = this.context;
        const collectionRepeatControllerParams = {};
        collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildInfoBoxParams();
        collectionRepeatControllerParams.showEmtptyInfoBox = !this._doNotShowEmptyBox;
        collectionRepeatControllerParams.controllerOption = controllerParams;
        collectionRepeatControllerParams.resizePlaceHolderElem = true;
        collectionRepeatControllerParams.setHeightToFitContainer = this._setHeightToFitContainer;
        collectionRepeatControllerParams.itemControllerConstructor = CommentListItemController;
        collectionRepeatControllerParams.modelLoadHandler = options.loadHandler?options.loadHandler: this._getCommentsForSongLoadHandler.bind(this);
        if(options.context){
            collectionRepeatControllerParams.context = options.context;
        }
        this.collectionRepeatController = new CollectionRepeatController(collectionRepeatControllerParams);
        //this.listenTo(this.collectionRepeatController,"finishLoading", this._onCollecctionRepeaterFinishLoading.bind(this));
        const commentBoxControllerParams =  {};
        commentBoxControllerParams.commentContext = this.model;
        commentBoxControllerParams.songRefSummary =  this.songRefSummary;
        commentBoxControllerParams.context = options.context;
        this._commentBoxController = new CommentBoxController(commentBoxControllerParams);
        this._contextController  = null;
        let contextControllerParams = {};
        if(Utils.getInstance().isSongInstance(this.model)){
            contextControllerParams = {};
            contextControllerParams.model = this.model;
            this._contextController = new SongContextController(contextControllerParams);
        }else{
            if(Utils.getInstance().isCommentInstance(this.model)){
                contextControllerParams = {};
                contextControllerParams.model = this.model;
                contextControllerParams.disableReply = true;
                this._contextController = new CommentListItemController(contextControllerParams);
            }else{
                if(Utils.getInstance().isCapsuleInstance(this.model)){
                    contextControllerParams = {};
                    contextControllerParams.model = this.model;
                    contextControllerParams.disableReply = true;
                    this._contextController = new CapsuleContextController(contextControllerParams);
                }else{
                    
                }
            
            }
        }
        
    },
    
    _onPageShow : function(){
        this.focusOnCommentWithId(this._idOfCommentToFocusOn);
        this._idOfCommentToFocusOn = null;
    },
    
    _buildInfoBoxParams : function(){
        const params = {};
        params.iconClass = "ion-chatbox";
        params.message = window.getI18n(ti18n,"NO_COMMENT");
        params.helpMessage = window.getI18n(ti18n,"POST_A_COMMENT_TO_START_A_DISCUSSION");
        return params;
        
    },
    
    _getCommentsForSongLoadHandler: function(){
        if(this.model ){
            if(Utils.getInstance().isSongInstance(this.model)){
                const songJsonForBackend = Utils.getInstance().getSongJsonForBackendFromSongModel(this.model);
                return TrebbleClientAPIHelper.getInstance().getCommentsInfoForSong(songJsonForBackend, this.songRefSummary).then((function(commentsArray){
                    this.commentsCollection = new Comments(commentsArray);
                    return this.commentsCollection.models;
                }).bind(this));
            }else{
                if(Utils.getInstance().isCommentInstance(this.model)){
                    const commentId = this.model.getCommentId();
                    return TrebbleClientAPIHelper.getInstance().getRepliesForComment(commentId).then((function(commentsArray){
                        this.commentsCollection = new Comments(commentsArray);
                        return this.commentsCollection.models;
                    }).bind(this));
                }else{
                    if(Utils.getInstance().isCapsuleInstance(this.model)){
                        const capsuleId = this.model.getCapsuleId();
                        return TrebbleClientAPIHelper.getInstance().getCommentsInfoForCapsule(capsuleId, this.songRefSummary).then((function(commentsArray){
                            this.commentsCollection = new Comments(commentsArray);
                            return this.commentsCollection.models;
                        }).bind(this));
                    }else{
                        return RSVP.Promise.resolve();
                    }
                }
            }
        }else{
            return RSVP.Promise.resolve();
        }
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },
    
    _onNewCommentPosted : function(newCommentModel){
        if(newCommentModel){
            this.collectionRepeatController.addItemToBottom(newCommentModel);
        }
    },

    getContextController: function(){
        return this._contextController;
    },
    
    onOrientationChangeCustomHandler: function(containerContentHeight){
        const commentBoxHeigh = window.trebble.defaultValues.commentBoxHeigth;
        const contextViewHeight = this._contextController? this._contextController.getView().$el.height(): 0;
        this.view.$el.find("#collection_repeat_container").css("height", containerContentHeight - commentBoxHeigh - contextViewHeight + "px");
        this.collectionRepeatController.getView()._displayInfoBoxIfApplicable();
    },
    
    _notifyCommentConsumed : function(){
        if(this.model ){
            if(Utils.getInstance().isSongInstance(this.model)){
                return PersistentModels.getInstance().notifyUserOfUnconsumedOwnerComments(this.model, this.context, this.songRefSummary);
            }else{
                if(Utils.getInstance().isCommentInstance(this.model)){
                    const commentId =  this.model.getCommentId();
                    return TrebbleClientAPIHelper.getInstance().markCommentRepliesAsConsumed(commentId);
                }else{
                    if(Utils.getInstance().isCapsuleInstance(this.model)){
                        const capsuleId = this.model.getCapsuleId();
                        return PersistentModels.getInstance().notifyUserOfUnconsumedCapsuleComments(capsuleId, this.context, this.songRefSummary);
                    }else{
                        return RSVP.Promise.resolve();
                    }
                }
            }
        }else{
            return RSVP.Promise.resolve();
        }
    },
    
    focusOnCommentWithId : function(commentId){
        const commentModel  = this.commentsCollection ? this.commentsCollection.get(commentId): null;
        if(commentModel){
            this.collectionRepeatController.focusOnItem(commentModel);
        }
    },

    
    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                const promises = [];
                this.listenTo(this._commentBoxController, "newCommentCreated", this._onNewCommentPosted.bind(this));
                promises.push(this._commentBoxController.buildModelAndRenderView());
                promises.push(this.collectionRepeatController.buildModelAndRenderView());
                if(this._contextController && this._showContext){
                    promises.push(this._contextController.buildModelAndRenderView());
                }
                RSVP.Promise.all(promises).then((function(result){
                    const contentView =new DynamicContentView();
                    contentView.render();
                    if(this._contextController && this._showContext){
                        contentView.$el.append(this._contextController.getView().$el);
                    }
                    const COMMENT_TEXTAREA_HEIGHT = 90;
                    this.collectionRepeatController.getView().$el.css("height","calc(100% - "+COMMENT_TEXTAREA_HEIGHT+"px)");

                    contentView.$el.append(this.collectionRepeatController.getView().$el);
                    contentView.$el.append(this._commentBoxController.getView().$el);
                    if(Utils.getInstance().isCommentInstance(this.model)){
                        this.collectionRepeatController.getView().$el.css("padding-left","40px");
                    }

                    this.view = contentView;
                    this.view.$el.css("width","100%");
                    this._notifyCommentConsumed();
                    resolve();
                }).bind(this)).catch((function(error){
                    reject(error);
                }).bind(this));
                
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default CommentSummaryController;