import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utils from "models/helper/Utils";
import FileUploadHelper from "models/helper/FileUploadHelper";
import UserProfileDetailsPageView from "views/user/UserProfileDetailsPageView";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import SongListController from "controllers/common/SongListController2";
import PlaylistListController from "controllers/common/PlaylistListController";
import CapsuleCardController from "controllers/capsule/CapsuleCardController";
import CollectionRepeatController from "controllers/common/CollectionRepeatController";
import JourneyCardController from "controllers/journey/JourneyCardController";
import Capsules from "collections/Capsules";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import TrebbleCardController from "controllers/playlistPodcast/TrebbleCardController";
import PersistentModels from "services/PersistentModels";
import UserOptionController from "controllers/user/UserOptionController";
import ti18n from "i18n!nls/UserProfileDetailsPagei18n";
import RSVP from "rsvp";
import swal from 'sweetalert';

const Rollout = RolloutHelper.getInstance();

const ENABLE_JOURNEY_SEARCH = Rollout.isFeatureEnabled(Rollout.FEATURES.ENABLE_JOURNEY_SEARCH, true);

const DO_NOT_SHOW_ARCHIVED_CAPSULE= true;

const UserProfileDetailsPageController =  Chaplin.Controller.extend({


    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model  = (options && options.userModel)? options.userModel:Utils.getInstance().getDummyModelFromUserJson();
        this._userInfoNeedToBeRetrieved = (options &&  options.userModel)?  false: true;
        this.userUsername  = options? options.userUsername:null;
        this.userUniqueId  = options? options.userUniqueId:null;
        this._pageId = options.pageId;
        this.listenTo(LocalStorageHelper.getInstance(), LocalStorageHelper.getInstance().eventTypes.USER_INFO_UPDATED, this.onLoggedInUserInfoUpdated.bind(this));

    },
    
    getModel : function(){
        return this.model;
    },
    
    getView : function(){
        return this.view;
    },


    onLoggedInUserInfoUpdated: function(newUserInfoJson){
        if(newUserInfoJson && (this.getUserUniqueId() == newUserInfoJson._id)){
            this.model = Utils.getInstance().getModelFromUserJson(newUserInfoJson);
            this.view.updateUserModel(this.model);
        }
    },

    
    _onActionExecutedOnUserHandler : function(userModel, action, data){
        let authentificationToken;
        switch(action){
            case "updateProfilePicture":
            if(data){
                const imageProfileUrl = data.imageProfileUrl;
                const imageProfileUrlWithRandomString = false?imageProfileUrl + "?random="+ (new Date()).getTime():imageProfileUrl;
                const imageProfileUrlBlur = data.imageProfileUrlBlur;
                const imageProfileUrlBlurWithRandomString = false?imageProfileUrlBlur + "?random="+ (new Date()).getTime(): imageProfileUrlBlur;
                this.model.setProfileImageUrl(imageProfileUrl);
                this.model.setProfileImageUrlBlur(imageProfileUrlBlur);
                authentificationToken  = LocalStorageHelper.getInstance().retrieveAuthentificationToken();
                authentificationToken.userInfo.profileImageUrl = imageProfileUrlWithRandomString;
                authentificationToken.userInfo.profileImageUrlBlur = imageProfileUrlBlurWithRandomString;
                LocalStorageHelper.getInstance().saveAuthentificationToken(authentificationToken);
                return this.retrieveUserProfileImageAndUpdateView(imageProfileUrlWithRandomString, imageProfileUrlBlurWithRandomString);
                //this.updateTrebblePictureCoverUrlOnView(newCoverArtUrl)
            }
            break;
            case "followUser":
            authentificationToken  = LocalStorageHelper.getInstance().retrieveAuthentificationToken();
            if(authentificationToken.userInfo){
                let numberOfFollowing = authentificationToken.userInfo.numberOfFollowing;
                numberOfFollowing =  numberOfFollowing? numberOfFollowing: 0;
                numberOfFollowing = numberOfFollowing + 1;
                authentificationToken.userInfo.numberOfFollowing = numberOfFollowing;
                LocalStorageHelper.getInstance().saveAuthentificationToken(authentificationToken);
                this.model.setFollowing(true);
                this.view.setFollow();
                if(userModel){
                    PersistentModels.getInstance().addFollowerInfoJson(userModel.toJSON());
                }
            }
            break;
            case "unfollowUser":
            authentificationToken  = LocalStorageHelper.getInstance().retrieveAuthentificationToken();
            if(authentificationToken.userInfo){
                let numberOfFollowing = authentificationToken.userInfo.numberOfFollowing;
                numberOfFollowing =  numberOfFollowing? numberOfFollowing: 0;
                numberOfFollowing = numberOfFollowing != 0? numberOfFollowing - 1:numberOfFollowing;
                authentificationToken.userInfo.numberOfFollowing = numberOfFollowing;
                this.model.setFollowing(false);
                this.view.setUnfollow();
                LocalStorageHelper.getInstance().saveAuthentificationToken(authentificationToken);
            }
            break;

        }
        
    },
    
    
    retrieveUserInfoAndUpdateView: function(){
        if(this._userInfoNeedToBeRetrieved){
            $.mobile.loading("show");
            this.retrieveUserInfo().then((function(userProfileInfo){
                this.model = userProfileInfo;
                this.view.updateUserModel(this.model);
                const profileImageUrl = this.model.getProfileImageUrl();
                const profileImageUrlBlur = this.model.getProfileImageUrlBlur();
                return this.retrieveUserProfileImageAndUpdateView(profileImageUrl, profileImageUrlBlur)
            }).bind(this)).then((function(){
                $.mobile.loading("hide");
            }).bind(this)).catch(function(error){
                $.mobile.loading("hide");
                window.alertErrorMessage(error);
            });
        }else{
            if(this.model){
                $.mobile.loading("show");
                this.view.updateUserModel(this.model);
                const profileImageUrl = this.model.getProfileImageUrl();
                const profileImageUrlBlur = this.model.getProfileImageUrlBlur();
                return this.retrieveUserProfileImageAndUpdateView(profileImageUrl, profileImageUrlBlur).then((function(){
                    $.mobile.loading("hide");
                }).bind(this)).catch(function(error){
                    $.mobile.loading("hide");
                    window.alertErrorMessage(error);
                });

            }
        }
    },
    
    retrieveUserInfo : function(){
        
        if(this.userUsername){
            return TrebbleClientAPIHelper.getInstance().getUserProfileInfoByUsername(this.userUsername).then((function(userInfoJson){
                return Utils.getInstance().getModelFromUserJson(userInfoJson);
            }).bind(this))
        }else{
            if(this.getUserUniqueId()){
                return TrebbleClientAPIHelper.getInstance().getUserProfileInfoByUserUniqueId(this.getUserUniqueId()).then((function(userInfoJson){
                    return Utils.getInstance().getModelFromUserJson(userInfoJson);
                }).bind(this))
            }else{
                return RSVP.Promise.reject(window.getI18n(ti18n, "NO_USER_OR_ID_TO_RETRIEVE_USER_PROFILE"));
            }
        }
    },

    getUserUniqueId : function(){
        if(this.userUniqueId){
            return this.userUniqueId;
        }else{
            if(this.model){
                return this.model.get("id");
            }
        }
        return null;
    },

    retrieveUserDetails : function(){
        

        return this.retrieveUserInfo().then((function(userProfileInfo){
            this.model = userProfileInfo;
            //this.retrieveTopTracks(5);
            //this.retrieveRecentlyAddedSongs(5);
            this.retrieveUserTrebbles(5);
            this.retrieveCapsuleHistory();
        }).bind(this));
    },

    retrieveTopTracks : function(numberOfResults){
        return TrebbleClientAPIHelper.getInstance().getMostPlayedSongsForUserByUserIdOrUsername(this.getUserUniqueId(), this.userUsername, numberOfResults).then((function(songJsonArray){
            //const arrayOfSongsWithAlbumAndArtistRef = TrebbleIndexDBHelper.getInstance().getSongsWithAlbumAndArtistInfoFromSongJsonArray(topSongsJsonArray);
            if(!songJsonArray){
                songJsonArray =  [];
            }
            this.topSongsListController.updateListOfSongs(songJsonArray);
        }).bind(this));

    },

    retrieveRecentlyAddedSongs : function(numberOfResults){
        return TrebbleClientAPIHelper.getInstance().getRecentlyAddedSongsForUserByUserIdOrUsername(this.getUserUniqueId(), this.userUsername, numberOfResults).then((function(songJsonArray){
            //const arrayOfSongsWithAlbumAndArtistRef = TrebbleIndexDBHelper.getInstance().getSongsWithAlbumAndArtistInfoFromSongJsonArray(topSongsJsonArray);
            if(!songJsonArray){
                songJsonArray =  [];
            }
            this.recentlyAddedSongsListController.updateListOfSongs(songJsonArray);
        }).bind(this));
    },


    retrieveUserTrebbles : function(numberOfResults){
        /*return TrebbleClientAPIHelper.getInstance().getUserTrebblesForUserByUserIdOrUsername(this.getUserUniqueId(), this.userUsername, numberOfResults).then((function(treblePlaylistSummaryJsonArray){
            //const arrayOfSongsWithAlbumAndArtistRef = TrebbleIndexDBHelper.getInstance().getSongsWithAlbumAndArtistInfoFromSongJsonArray(topSongsJsonArray);
            if(!treblePlaylistSummaryJsonArray){
                treblePlaylistSummaryJsonArray =  [];
            }
            this.userTrebblesListController.updateListOfPlaylists(treblePlaylistSummaryJsonArray);
        }).bind(this));*/
return TrebbleClientAPIHelper.getInstance().getUserTrebblesForUserByUserIdOrUsername(this.getUserUniqueId(), this.userUsername, numberOfResults).then((function(treblePlaylistSummaryJsonArray){
//const arrayOfSongsWithAlbumAndArtistRef = TrebbleIndexDBHelper.getInstance().getSongsWithAlbumAndArtistInfoFromSongJsonArray(topSongsJsonArray);
if(!treblePlaylistSummaryJsonArray){
treblePlaylistSummaryJsonArray =  [];
}else{

for(let index in treblePlaylistSummaryJsonArray){
    this.userTrebblesListController.addItemToBottom(Utils.getInstance().buildPlaylistSummaryFromJson(treblePlaylistSummaryJsonArray[index]), true);
}
}
//this.userTrebblesListController.updateListOfPlaylists(capsuleJsonArray);
return treblePlaylistSummaryJsonArray;
}).bind(this));
},

retrieveCapsuleHistory : function(numberOfResults){
return TrebbleClientAPIHelper.getInstance().getExpiredCapsulesForUserByUserIdOrUsername(this.getUserUniqueId(), this.userUsername, numberOfResults).then((function(capsuleJsonArray){
//const arrayOfSongsWithAlbumAndArtistRef = TrebbleIndexDBHelper.getInstance().getSongsWithAlbumAndArtistInfoFromSongJsonArray(topSongsJsonArray);
if(!capsuleJsonArray){
    capsuleJsonArray =  [];
}else{
    this.capsulesCollection = new Capsules(capsuleJsonArray);
    if(!DO_NOT_SHOW_ARCHIVED_CAPSULE){
        for(let index in this.capsulesCollection.models){
            this.userCapsuleHistoryCollectionRepeatController.addItemToBottom(this.capsulesCollection.models[index], true);
        }
    }
}
//this.userTrebblesListController.updateListOfPlaylists(capsuleJsonArray);
return capsuleJsonArray;
}).bind(this));
},



changeProfileImageConfirm : function(){
if(this.model){
const buttonLabels = [window.getI18n(ti18n, "NO"), window.getI18n(ti18n, "YES")];
const confirmCallback = (function(buttonIndex){
    if(buttonIndex ==  2){
        $.mobile.loading("show");
        setTimeout((function(){
            $.mobile.loading("hide");
            this.changeProfileImage();
        }).bind(this), 1000);

    }
}).bind(this);
const message = window.getI18n(ti18n, "DO_YOU_WANT_TO_CHANGE_YOUR_PROFILE_PICTURE")
navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n, "CHANGE_PROFILE_PICTURE"), buttonLabels);
}
},
changeProfileImage : function(){

    let imageProfileUrl = null;
let uploadPromise = null;
if(window.trebble.config.disableZoomInCoverArtInMobileApp ||  (window.device && window.device.platform =="browser")){
uploadPromise =  this.uploadProfileImageFromWebBrowser();
}else{
uploadPromise =  this.uploadProfileImageFromMobile();
}
return uploadPromise.then((function(uploadedImageFileUrl){
$.mobile.loading('show');
if(uploadedImageFileUrl){
    imageProfileUrl = uploadedImageFileUrl;
    return TrebbleClientAPIHelper.getInstance().updateUserProfileImage(imageProfileUrl).then((function(imageProfileUrlBlur){
        return Utils.getInstance().preloadImageUsingUrl(imageProfileUrlBlur);
    }).bind(this));
}

}).bind(this)).then((function(imageProfileUrlBlur){
if(imageProfileUrl){

    //this is done to force the browser to reload the image
    const imageProfileUrlWithRandomString = false?imageProfileUrl + "?random="+ (new Date()).getTime(): imageProfileUrl;
    const imageProfileUrlBlurWithRandomString = false?imageProfileUrlBlur + "?random="+ (new Date()).getTime(): imageProfileUrlBlur;
    this.model.setProfileImageUrl(imageProfileUrl);
    this.model.setProfileImageUrlBlur(imageProfileUrlBlur);
    const authentificationToken  = LocalStorageHelper.getInstance().retrieveAuthentificationToken();
    authentificationToken.userInfo.profileImageUrl = imageProfileUrlWithRandomString;
    authentificationToken.userInfo.profileImageUrlBlur = imageProfileUrlBlurWithRandomString;
    LocalStorageHelper.getInstance().saveAuthentificationToken(authentificationToken);
    return this.retrieveUserProfileImageAndUpdateView(imageProfileUrlWithRandomString, imageProfileUrlBlurWithRandomString);
}
}).bind(this)).then((function(){
$.mobile.loading('hide');
navigator.trebbleNotification.alert(window.getI18n(ti18n, "YOUR_PROFILE_PICUTRE_WAS_SUCCESSFULLY_CHANGED"), null, false, "success");
}).bind(this)).catch((function(error){
$.mobile.loading('hide');
navigator.trebbleNotification.alert(error, null, false, "error");
}).bind(this));

},

uploadProfileImageFromWebBrowser : function(){
return new  RSVP.Promise((function(resolve, reject){
const uploadEementId = "uploadWrapper_"+((new Date()).getTime());
const nameOfFileToUpload =  "userProfileImage_"+(new Date()).getTime()+".jpg";
const text = window.isMobileBrowser?window.getI18n(ti18n, "TAP_TO_SELECT_IMAGE"):window.getI18n(ti18n, "CLICK_TO_SELECT_IMAGE");
const uploadZoneHtml=  "<span class='uploadZonePlaceHolder' id='"+uploadEementId +"'><div class='pe-7s-upload uploadIcon' style='font-size:40px'></div><span class='dz-message'>"+ text +"</span></span>";
const buttonLabels = [window.getI18n(ti18n, "CANCEL"),window.getI18n(ti18n, "SAVE")];
let dropZoneObj =  null;
const confirmCallback = (function(buttonIndex){
    if(buttonIndex ==  2){
        if(dropZoneObj){
            if(dropZoneObj.getQueuedFiles().length == 0){
                setTimeout(function(){swal.enableButtons()}, 300);
            }else{
                FileUploadHelper.getInstance().validateAndSignFunctionHanlder(dropZoneObj, nameOfFileToUpload, FileUploadHelper.getInstance().getImageFileTypeValidationFunction(), "getSignatureForUserProfileImageUpload" ).then(function(){
                    dropZoneObj.processQueue();
                }).catch(function(error){
                    navigator.trebbleNotification.alert(error, null, false, "error");
                })

            }
        }
    }
}).bind(this);
navigator.trebbleNotification.confirm(uploadZoneHtml , confirmCallback, window.getI18n(ti18n, "CHANGE_PROFILE_PICTURE"), buttonLabels, true, null, null, true);
const handlers = Utils.getInstance().getConfirmDialogDeferredHandlers(resolve, reject, this);
dropZoneObj =  FileUploadHelper.getInstance().uploadUserProfileImageInWebBrowser(nameOfFileToUpload, "#"+ uploadEementId,handlers.resolve,handlers.reject);
}).bind(this));
},

uploadProfileImageFromMobile : function(){
return Utils.getInstance().getUserToPickAPicture().then((function(imageLocationUrl){
$.mobile.loading('show');
return FileUploadHelper.getInstance().uploadUserProfileImage(imageLocationUrl);
}).bind(this));
},


retrieveUserProfileImageAndUpdateView : function(imageProfileUrl, imageProfileUrlBlur){
const backgroundBlurredImageHeight = (window.trebble.config.disableZoomInCoverArtInMobileApp || (window.device && window.device.platform == "browser"))?500:270;
const resizeImageUrlForBackground = Utils.getInstance().getResizeImageUrl(imageProfileUrlBlur, backgroundBlurredImageHeight, backgroundBlurredImageHeight);
const profileImageWidthHeight = window.isMobileBrowser? 100: 300;
const imageProfileUrlThumbnail = Utils.getInstance().getResizeImageUrl(imageProfileUrl, profileImageWidthHeight, profileImageWidthHeight);
let getBlurryImageData = null;
if(imageProfileUrlBlur){
getBlurryImageData  = Utils.getInstance().preloadImageUsingUrl(resizeImageUrlForBackground);
}else{
getBlurryImageData =  Utils.getInstance().retrieveImageBlurryFromUrl(resizeImageUrlForBackground, 1, 1).then((function(scaledCoverArtInfo){
    if(scaledCoverArtInfo){
        return Utils.getInstance().getReadyForDisplayBase64Image(scaledCoverArtInfo.albumArtBase64BlurScreenSize);
    }else{
        return null;
    }
}).bind(this));
}
return getBlurryImageData.then((function(blurryBase64AlbumArt){
return this.view.updateUserProfilImage(imageProfileUrlThumbnail, blurryBase64AlbumArt);
//return Utils.getInstance().getMatchingColorByImageUrl(blurryBase64AlbumArt);
//return {};
}).bind(this)).then((function(colors){
//return this.view.updateTextAndBackgroundColors(colors.backgroundColor,colors.textColor1,colors.textColor2);
}).bind(this)).catch(function(error){
window.alertErrorMessage(error);
});


},

showOptionMenu : function(elementSource$el){
PersistentModels.getInstance().getRouter().showUserMenuOption(this.model,  true,elementSource$el, this._onActionExecutedOnUserHandler.bind(this), UserOptionController);
},

editUserProfile : function(){
PersistentModels.getInstance().getRouter().showUserEditProfileInfo(true, {"onUserPorfileChanged" : this.onUserProfileChanged.bind(this)} );
},

onUserProfileChanged : function(updatedUserProfileInfo){
this.model = Utils.getInstance().getModelFromUserJson(updatedUserProfileInfo);
this._userInfoNeedToBeRetrieved = false;
this.userUsername =  this.model.get("username");
this.retrieveUserInfoAndUpdateView();
},



showUsersFollowed :function(){
PersistentModels.getInstance().getRouter().showFollowerListPage(this.model.getUsername(), true);
},

showUsersFollowing :function(){
PersistentModels.getInstance().getRouter().showFollowingListPage(this.model.getUsername(), true);
},

showTrebbleFollowed :function(){
PersistentModels.getInstance().getRouter().showFollowedTrebblesListPage(this.model.getUsername(), true);
},

showSongsInUserLibrary :function(){
PersistentModels.getInstance().getRouter().showSongListInUserLibraryPage(this.model.getUsername(), true);
},

follow : function(){
return TrebbleClientAPIHelper.getInstance().followUserByUsername(this.model?this.model.getUsername(): this.username).then((function(){
if(this._onActionExecutedOnUserHandler){
    this._onActionExecutedOnUserHandler(this.model,"followUser");
}
}).bind(this)).catch((function(error){
window.alertErrorMessage(error);
this.view.setUnfollow();
}).bind(this));
},

unfollowConfirm : function(){
const buttonLabels = [window.getI18n(ti18n, "CANCEL"), window.getI18n(ti18n, "UNFOLLOW")];
const confirmCallback = (function(buttonIndex){
if(buttonIndex == 2){
    this.self.unfollow();
}
}).bind({"self": this});
const message = window.getI18n(ti18n, "UNFOLLOW")+" @"+this.model.getUsername()+"?";
navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n, "UNFOLLOW_USER"), buttonLabels);

},

unfollow : function(){
return TrebbleClientAPIHelper.getInstance().unfollowUserByUsername(this.userUsername).then((function(){
if(this._onActionExecutedOnUserHandler){
    this._onActionExecutedOnUserHandler(this.model,"unfollowUser");
}
}).bind(this)).catch((function(error){
window.alertErrorMessage(error);
this.view.setFollow();
}).bind(this));
},

getUsersTrebbles : function(){

},

_onActionExecutedByUserOnDisplayedCapsuleList : function(){
if(!DO_NOT_SHOW_ARCHIVED_CAPSULE){
this.userCapsuleHistoryCollectionRepeatController.reload();
}
},


_buildInfoBoxParamsForCapsuleHistory : function(){
const params = {};
params.iconClass = "ion-contrast";
params.message = window.getI18n(ti18n, "NO_CAPSULE");
return params;

},

_buildInfoBoxParamsForJourneys: function(){
const params = {};
params.iconClass = "pe-7s-sun";
params.message = window.getI18n(ti18n, "NO_JOURNEY");
return params;

},

_getCapulesForUserLoadHandler: function(){
return RSVP.Promise.resolve();
},
_getUserTrebbleLoadHandler : function(){
return RSVP.Promise.resolve();
},



showMoreMostPlayedSongs : function(){
const loadHandler = (function(){
return TrebbleClientAPIHelper.getInstance().getMostPlayedSongsForUserByUserIdOrUsername(this.getUserUniqueId(), this.userUsername, 100)
}).bind(this);
PersistentModels.getInstance().getRouter().showAllSongsListPage(loadHandler, window.getI18n(ti18n, "MOST_PLAYED_SONGS_FOR")+" '"+this.userUsername+"'", true);
},

showMoreRecentlyAddedSongs : function(){
const loadHandler = (function(){
return TrebbleClientAPIHelper.getInstance().getRecentlyAddedSongsForUserByUserIdOrUsername(this.getUserUniqueId(), this.userUsername, 100)
}).bind(this);
PersistentModels.getInstance().getRouter().showAllSongsListPage(loadHandler, window.getI18n(ti18n, "MOST_RECENTLY_ADDED_SONGS_FOR")+" '"+this.userUsername+"'", true);
},

sendCmailToUser : function(){
PersistentModels.getInstance().getRouter().showCreateACapsulePageWithoutContext(this.getUserUniqueId());
},

shareUserCmailUrl : function(){
PersistentModels.getInstance().getRouter().showShareCMailUrlPopup(this.model.get("id"));
},

_buildJourneysListController: function() {



const controllerParams = {};

//controllerParams.customImageUrlProperty = "data-original";
controllerParams.notTransparent = false;
controllerParams.smallcardsize = true;
controllerParams.notPlayable = true;
controllerParams.showEditablePageIfApplicable = false;
controllerParams.doNotShowSubscribeButton = false;

const collectionRepeatControllerParams = {};
collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildInfoBoxParamsForJourneys();
collectionRepeatControllerParams.showEmtptyInfoBox = true;
collectionRepeatControllerParams.controllerOption = controllerParams;
collectionRepeatControllerParams.itemControllerConstructor = JourneyCardController;
collectionRepeatControllerParams.modelLoadHandler = this.getJourneysListLoadHandler().bind(this);
collectionRepeatControllerParams.context = this.context;
collectionRepeatControllerParams.resizePlaceHolderElem = true;
collectionRepeatControllerParams.displayCollectionInline = false;
collectionRepeatControllerParams.setHeightContainerAuto = true
collectionRepeatControllerParams.actionButtonHandlerFunction = null;
collectionRepeatControllerParams.heightMargin = 52 + 39; //Page Header + Tab Header

const journeysListController = new CollectionRepeatController(collectionRepeatControllerParams);
this.listenTo(journeysListController, "startLoading", this.onJourneyListStartLoading.bind(this));
this.listenTo(journeysListController, "finishLoading", this.onJourneyListFinishLoading.bind(this));
return journeysListController;
},

getJourneysListLoadHandler : function(){
return (function() {
return TrebbleClientAPIHelper.getInstance().getAllAvailableJourneysCreatedByUserWithId(this.getUserUniqueId()).then((function(journeyJsonArray) {
    const journeySummaryModelArray = [];
    if (journeyJsonArray) {
        for (let i = 0; i < journeyJsonArray.length; i++) {
            journeySummaryModelArray.push(Utils.getInstance().buildJourneySummaryFromJson(journeyJsonArray[i]));
        }
    }
    return journeySummaryModelArray;
}).bind(this));
}).bind(this);
},

onJourneyListStartLoading : function(){
if(this.view){
this.view.hideJourneyListView();
}
},

onJourneyListFinishLoading : function(){
if(this.view && this.journeyCollectionRepeatController && this.journeyCollectionRepeatController.getNumberOfModels() > 0){
this.view.showJourneyListView();
}
},

loadImages : function(){
if(this.view.$el){
this.view.$el.find("#collection_repeat_container").trigger("scrollstop");
}
},

openUsersWebsite : function(){
const urlWebsite = this.model.getWebsite();
if(urlWebsite){
const openedWindow = window.open(Utils.getInstance().addHttpsToURLIfApplicable(urlWebsite), "_blank");
if((!openedWindow || openedWindow.closed || typeof openedWindow.closed=='undefined') && !window.waitForCordovaToLoad){
    window.alertErrorMessage(window.getI18n(ti18n, "PLEASE_ALLOW_POPUPS_FOR_TREBBLE"));
}else{
    if(openedWindow.focus){
        openedWindow.focus();
    }
}
}
},


buildModelAndRenderView : function() {
return new RSVP.Promise((function(resolve, reject) {
try {

    const defaultParams  = {};
    defaultParams.doNotSortDisplayedCollection = true;
    defaultParams.resizePlaceHolderElem = true;
    defaultParams.displaySongOnDeviceOnFirstLoading = false;
    defaultParams.showEmtptyInfoBox = false;
    const context =  Utils.getInstance().buildContextForUserDetailsPage(this.getUserUniqueId());
    defaultParams.context = context;
    /*
    const playlistListControllerParams = {};
    playlistListControllerParams.resizePlaceHolderElem = false;
    playlistListControllerParams.doNotSortDisplayedCollection = true;
    //playlistListControllerParams.loadHandler = this.getUsersTrebbles.bind(this);
    playlistListControllerParams.extendedView = true;
    playlistListControllerParams.context =  context;
    playlistListControllerParams.showOnePlaylistPerLine = true;*/

    const playlistControllerParams = {};

    playlistControllerParams.notTransparent = false;
    playlistControllerParams.smallcardsize = true;
    playlistControllerParams.notPlayable = true;
    playlistControllerParams.showEditablePageIfApplicable = false;
    playlistControllerParams.doNotShowSubscribeButton = false;


    const playlistcollectionRepeatControllerParams = {};
    playlistcollectionRepeatControllerParams.customInfoBoxInfoParams = null;//this._buildInfoBoxParams();
    playlistcollectionRepeatControllerParams.showEmtptyInfoBox = false;
    playlistcollectionRepeatControllerParams.controllerOption = playlistControllerParams;
    playlistcollectionRepeatControllerParams.itemControllerConstructor = TrebbleCardController;
    playlistcollectionRepeatControllerParams.modelLoadHandler = this._getUserTrebbleLoadHandler.bind(this);
    playlistcollectionRepeatControllerParams.context = context;
    playlistcollectionRepeatControllerParams.resizePlaceHolderElem = true;

    const controllerParams = {};
    controllerParams.showActiveFlagInView = false;
    controllerParams.showCommentInLine = false;
    controllerParams.context = context;
    controllerParams.onActionExecutedOnUserHandler = this._onActionExecutedByUserOnDisplayedCapsuleList.bind(this);
    const collectionRepeatControllerParams = {};
    collectionRepeatControllerParams.showEmtptyInfoBox = true;
    collectionRepeatControllerParams.controllerOption = controllerParams;
    collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildInfoBoxParamsForCapsuleHistory();
    collectionRepeatControllerParams.itemControllerConstructor = CapsuleCardController;
    collectionRepeatControllerParams.resizePlaceHolderElem = true;
    collectionRepeatControllerParams.modelLoadHandler = this._getCapulesForUserLoadHandler.bind(this);
    collectionRepeatControllerParams.context = context;

    if(ENABLE_JOURNEY_SEARCH){
        this.journeyCollectionRepeatController = this._buildJourneysListController();
        this.listenTo(this.journeyCollectionRepeatController, "finishLoading", this.loadImages);
    }



    this.topSongsListController = new SongListController(defaultParams);
    this.recentlyAddedSongsListController = new SongListController(defaultParams);
    //this.userTrebblesListController =  new PlaylistListController(playlistListControllerParams);
    this.userTrebblesListController = new CollectionRepeatController(playlistcollectionRepeatControllerParams);
    if(!DO_NOT_SHOW_ARCHIVED_CAPSULE){
        this.userCapsuleHistoryCollectionRepeatController = new CollectionRepeatController(collectionRepeatControllerParams);
    }
    

    const promiseArray = [];
    promiseArray.push(this.topSongsListController.buildModelAndRenderView());
    promiseArray.push(this.recentlyAddedSongsListController.buildModelAndRenderView());
    promiseArray.push(this.userTrebblesListController.buildModelAndRenderView());
    if(!DO_NOT_SHOW_ARCHIVED_CAPSULE){
        promiseArray.push(this.userCapsuleHistoryCollectionRepeatController.buildModelAndRenderView());
    }
    
    if(this.journeyCollectionRepeatController){
        promiseArray.push(this.journeyCollectionRepeatController.buildModelAndRenderView());
    }




    RSVP.Promise.all(promiseArray).then((function(){

        this.topSongsListView = this.topSongsListController.getView();
        this.recentlyAddedSongsListView = this.recentlyAddedSongsListController.getView();
        this.userTrebblesListView = this.userTrebblesListController.getView();
        if(!DO_NOT_SHOW_ARCHIVED_CAPSULE){
            this.userCapsuleHistoryCollectionRepeatView = this.userCapsuleHistoryCollectionRepeatController.getView();
        }
        if(this.journeyCollectionRepeatController){
            this.journeyCollectionRepeatView = this.journeyCollectionRepeatController.getView();
        }


        this.topSongsCollection = this.topSongsListController.getModel();
        this.recentlyAddedSongsCollection = this.recentlyAddedSongsListController.getModel();
        this.userTrebblesCollection = this.userTrebblesListController.getModel();
        if(this.journeyCollectionRepeatController){
            this.journeyCollection = this.journeyCollectionRepeatController.getModel();
        }

        const pageViewParams= {};
        pageViewParams.model = this.model;
        pageViewParams.pageId = this._pageId;
        pageViewParams.topSongsListView = this.topSongsListView;
        pageViewParams.recentlyAddedSongsListView = this.recentlyAddedSongsListView;
        pageViewParams.userTrebblesListView = this.userTrebblesListView;
        if(!DO_NOT_SHOW_ARCHIVED_CAPSULE){
            pageViewParams.userCapsuleHistoryCollectionRepeatView = this.userCapsuleHistoryCollectionRepeatView;
        }
        pageViewParams.journeyCollectionRepeatView = this.journeyCollectionRepeatView;

        this.view = new UserProfileDetailsPageView(pageViewParams);
        this.view.render();
        this.retrieveUserInfoAndUpdateView();
        this.listenTo(this.view, "changeProfileImage",this.changeProfileImage);
        this.listenTo(this.view, "changeProfileImageWithConfirmation",this.changeProfileImageConfirm);
        this.listenTo(this.view, "showOptionMenu",this.showOptionMenu);
        this.listenTo(this.view, "editUserProfile",this.editUserProfile);
        this.listenTo(this.view, "showUsersFollowed",this.showUsersFollowed);
        this.listenTo(this.view, "showUsersFollowing",this.showUsersFollowing);
        this.listenTo(this.view, "showTrebbleFollowed",this.showTrebbleFollowed);
        this.listenTo(this.view, "showSongsInUserLibrary",this.showSongsInUserLibrary);
        this.listenTo(this.view, "showMoreMostPlayed",this.showMoreMostPlayedSongs);
        this.listenTo(this.view, "showMoreRecentlyAddedSongs",this.showMoreRecentlyAddedSongs);
        this.listenTo(this.view, "follow", this.follow);
        this.listenTo(this.view, "unfollow", this.unfollowConfirm);
        this.listenTo(this.view,"sendCmailToUser",this.sendCmailToUser);
        this.listenTo(this.view,"shareUserCmailUrl",this.shareUserCmailUrl);
        this.listenTo(this.view, "openUsersWebsite", this.openUsersWebsite);
        this.retrieveUserDetails();
        //this.view.$el.on("click", "#saveButtonIcon", this.saveChanges.bind(this));
        //this.view.$el.on("click", "#header-back-button", this.view.navigateToPreviousPage.bind(this.view));
        resolve(this);
    }).bind(this)).catch(reject);
} catch (error) {
reject(error);
}

}).bind(this));

}


});
export default UserProfileDetailsPageController;