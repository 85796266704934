import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import PersistentModels from "services/PersistentModels";
import ReactTestContentView from "views/reactTest/ReactTestContentView";
import DynamicPageView from "views/common/DynamicPageView";
import ti18n from "i18n!nls/UserReferrali18n";
import PageTemplate from 'text!../../../templates/referral/UserReferralPageTemplate.html';
import RSVP from "rsvp";


const TrebbleReactTestPageController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = options.model;
        this._pageId = options.pageId;
        
    },
    
    _onPageShow : function(){
        
    },
    
    _onPageHide: function(){
        
    },


    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    showHomePage : function(){
        PersistentModels.getInstance().getRouter().showHomePage();
    },

    
    buildModelAndRenderView : function() {
        
            const params = {};
            params.textToShow = "armel";
            const reactTestContentView =  new ReactTestContentView(params);
            this.reactTestContentView = reactTestContentView;

            const pageViewParams= {};
            pageViewParams.pageId = this._pageId;
            pageViewParams.pageViewTemplate = PageTemplate;
            pageViewParams.containerSelector = ".ui-content";
            pageViewParams.contentView = reactTestContentView;
            pageViewParams.onPageShow = this._onPageShow.bind(this);
            pageViewParams.onPageHide = this._onPageHide.bind(this);
            pageViewParams.persistent = true;
            pageViewParams.pageTemplateOptions = {"ti18n":ti18n};
            this.view = new DynamicPageView(pageViewParams);
            this.view.render();
            this.view.$el.on("click", "#homeHeaderButton", this.showHomePage.bind(this));
            return RSVP.Promise.resolve(this);
        


}

});

export default TrebbleReactTestPageController;