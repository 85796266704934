import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import ti18n from "i18n!nls/Commoni18n";
import NotificationCardViewTemplate from 'text!../../../templates/common/NotificationCardTemplate.html';
import RSVP from 'rsvp';
import moment from 'moment';

const NotificationCardView = Backbone.View.extend({



    initialize : function(options) {
        this.compiledTemlate =  options.customTemplate ? _.template(options.customTemplate): _.template(NotificationCardViewTemplate);
        this.model = options? options.model : null;
        this._showActionButton = options? options.showActionButton : false;
        this._actionButtonLabel = options? options.actionButtonLabel : window.getI18n(ti18n, "MORE");
        this._actionButtonIconClass = options? options.actionButtonIconClass : "";

    },
    
    events : {
        "click #actionButton": "onActionButtonTapped",
    },
    
    onActionButtonTapped : function(){
        this.trigger("actionButtonClicked", this.model);
    },
    
    
    render : function() {
        const params = {"moment": moment};
        params.model = this.model;
        params.actionButtonLabel = this._actionButtonLabel;
        params.displayActionButton = this._showActionButton;
        params.actionButtonIconClass = this._actionButtonIconClass;
        this.$el.html(this.compiledTemlate(params));
        this.setElement(this.$el.find("div").first());
        return this;
    }

});
export default NotificationCardView;