import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import Utils from "models/helper/Utils";
import Users from "collections/Users";
import UserListView from "views/common/UserListView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PersistentModels from "services/PersistentModels";
import ti18n from "i18n!nls/Commoni18n";
import RSVP from "rsvp";

const UserListController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if(options)
        {
            if(options.model)
            {
                if(Utils.getInstance().is("Array",options.model))
                {
                    const userJsonArray = options.model;
                    this.model = new Users(userJsonArray);
                }else{
                        this.model = options.model;
                }
                
            }
            
            if(options.resizePlaceHolderElem)
            {
                this.resizePlaceHolderElem = options.resizePlaceHolderElem;
            }else{
                this.resizePlaceHolderElem = false;
            }
            if(options.showEmtptyInfoBox){
                this._showEmtptyInfoBox = options.showEmtptyInfoBox;
            }
            if(options.loadHandler)
            {
                this._loadHandler = options.loadHandler;
            }
            if(options.context){
                if(this.model instanceof Users){
                    this.model.updateContext(options.context);
                }
                this._modelContext = options.context;
            }
            if(options.customInfoBoxInfoParams){
                this._customInfoBoxInfoParams = options.customInfoBoxInfoParams;
            }
            if(options.container$elForImageLazyLoad){
                this._container$elForImageLazyLoad = options.container$elForImageLazyLoad;
            }
            
            this._doNotSortDisplayedCollection = options.doNotSortDisplayedCollection;
            if(options.sortDescending){
                this._sortDescending = options.sortDescending;
            }
            if(options.sortField ){
                this._sortField = options.sortField;
                if(this.model){
                    this.model.sortByField(this._sortField, this._sortDescending);
                }
            }
            if(options.actionExecutedOnUserHandler){
                this._actionExecutedOnUserHandler = options.actionExecutedOnUserHandler;
            }
            if(options.container$elForImageLazyLoad){
                this._container$elForImageLazyLoad = options.container$elForImageLazyLoad;
            }
            if(options.doNotInitializeLazyLoadingAtRender){
                this._doNotInitializeLazyLoadingAtRender = options.doNotInitializeLazyLoadingAtRender;
            }
            if(options.heightMargin){
                this._heightMargin = options.heightMargin;
            }
            
        }
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },
    
    showUserProfilePage : function(userModel){
        PersistentModels.getInstance().getRouter().showUserProfilePageByUsername(null,userModel.getUserId(),  userModel, true );
    },
    
    onUserActionButtonTapped : function(userModel){
    window.alertErrorMessage(window.getI18n(ti18n,"COMING_SOON"));
    },
    
    
    updateListOfUsers : function(arrayOfUsersJson){
        this.model.reset(arrayOfUsersJson/*, {silent: true}*/);
    },
    
    
     updateInfoBoxIfApplicable : function(iconClass,message,helpMessage,infoBoxClassName,actionButtonLabel,actionButtonIconClass){
            if(this.view){
                this.view.update(iconClass,message,helpMessage,infoBoxClassName,actionButtonLabel,actionButtonIconClass);
            }
    },
    
    reload : function(){
        if(this.view && this.view.load){
            return this.view.load();
        }else{
            return  RSVP.Promise.resolve();
        }
    },
    

    initializeImageLazyLoadPlugin : function(container$elForImageLazyLoad){
        this.infiniteListView.initializeImageLazyLoadPlugin(container$elForImageLazyLoad);
    },
    
    cancelPendingImageLoading : function(){
        this.view.cancelPendingImageLoading();
    },

    retrieveUserDetails : function(){
        return RSVP.Promise.resolve();
    },
    
    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                if(!this.model)
                {
                    this.model = new Users([]);
                    if(this._modelContext){
                        this.model.updateContext(this._modelContext);
                    }
                }
                this.model.sortByField(this._sortField, this._sortDescending);
                this.view = new UserListView({
                    "collection" : this.model,
                    "delayOnCollectionUpdate" : 1000,
                    "resizeToAvailableScreenSizeOnOrientationChange" : false,
                    "resizePlaceHolderElem" : this.resizePlaceHolderElem,
                    "loadHandler" : this._loadHandler,
                    "heightMargin": this._heightMargin,
                    "showEmtptyInfoBox" :this._showEmtptyInfoBox ,
                    "customInfoBoxInfoParams": this._customInfoBoxInfoParams,
                    "container$elForImageLazyLoad": this._container$elForImageLazyLoad,
                    "doNotInitializeLazyLoadingAtRender" : this._doNotInitializeLazyLoadingAtRender,
                });
                this.listenTo(this.view, "userInfoTapped",this.showUserProfilePage);
                this.listenTo(this.view, "userActionButtonTapped",this.onUserActionButtonTapped);
                this.view.render();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default UserListController;