import React from "react";
import { Tooltip } from "antd";
import ConfigProvider from "./ConfigProvider";
import useDefaultTrebbleAntDTokenConfig from "../helper/useDefaultTrebbleAntDTokenConfig";
import PropTypes from "prop-types";

const TrebbleTooltip = ({useEditorTheme, ...restProps}) => {
  const themeConfig = useDefaultTrebbleAntDTokenConfig({useEditorTheme});
  themeConfig.token.fontSize = 14;
  themeConfig.token.borderRadius = 4;
  return (
    <ConfigProvider theme={themeConfig}>
      <Tooltip {...restProps} />
    </ConfigProvider>
  );
};
TrebbleTooltip.propTypes = {
  useEditorTheme: PropTypes.bool
}
export default TrebbleTooltip;
