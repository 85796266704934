import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import BasicInspectorNodeParamView from "views/audioEditor/sequencerNodeInspectorSection/paramView/BasicInspectorNodeParamView";
import TitleParamTemplate from 'text!../../../../../templates/audioEditor/sequencerNodeInspectorSection/paramView/TitleParamTemplate.html';
import RSVP from 'rsvp';


const TitleParamView = BasicInspectorNodeParamView.extend({

    constructor : function(attributes, options) {
        BasicInspectorNodeParamView.apply(this, [attributes, options]);
        this.compiledTemlate =  _.template(TitleParamTemplate);
    },			
    

    updateViewOnValueChange : function(newValue){
        if(this.titleLabel$el){
            this.titleLabel$el.html(newValue);
        }
    },

    render : function(){
        this.$el.html(this.compiledTemlate({ "viewObj": this}));
        this.setElement(this.$el.find(".basic_inpector_param_view").first());
        this.titleLabel$el = this.$el.find(".title_label");
    }

});
export default TitleParamView;