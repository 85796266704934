import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import DynamicContentView from "views/common/DynamicContentView";
import ti18n from "i18n!nls/PlaylistPodcasti18n";
import contentTemplate from 'text!../../../templates/playlistPodcasts/TrebbleEditDetailsContent.html';

const FORM_VALIDATION_DELAY_IN_MILLISEC = 500;
const USE_DUMMY_CUSTOM_URL = false;
const SongOptionView = DynamicContentView.extend({

    template : _.template(contentTemplate),


    initialize : function(options) {
        this._trebbleInfoJson =  {};
        this._jingleInfo =  null;
        this._greaterInfo =  null;
        this._introInfo =  null;
        this._outroInfo =  null;
        this._loadFunction = options.loadFunction;
        this._sweeperPlaying = false;
        this._greaterPlaying = false;
        this._introPlaying = false;
        this._outroPlaying = false;
        this._demoSweeperPlaying = false;
        this._demoGreaterPlaying = false;
        this._demoIntroPlaying = false;
        this._demoOutroPlaying = false;
        this._citySelectFieldView = options.citySelectFieldView;
        this._wasRemovedFromPage = false;
        DynamicContentView.prototype.initialize.call(this, {"canBeAppendedBeforeLoad": true});

    },

    load : function(){
        $.mobile.loading("show");
        return this._loadFunction().then((function(results){
            const trebbleInfoJson = results? results.trebbleInfo: null;
            const jingleInfo = results? results.jingleInfo: null;
            const greaterInfo = results? results.greaterInfo: null;
            const introInfo = results? results.introInfo: null;
            const outroInfo = results? results.outroInfo: null;
            $.mobile.loading("show");
            if(trebbleInfoJson){
                this._trebbleInfoJson = trebbleInfoJson;
                if(!this._trebbleInfoJson.description){
                    this._trebbleInfoJson.description = null;
                }
                if(!this._trebbleInfoJson.customURL && USE_DUMMY_CUSTOM_URL){
                    this._trebbleInfoJson.customURL = Utils.getInstance().getDummyCustomURL();
                }
                this._jingleInfo = jingleInfo;
                this._greaterInfo = greaterInfo;
                this._introInfo = introInfo;
                this._outroInfo = outroInfo;
                this._updateView();
            }else{
                console.error("Trebble details couldn't be retrieved.");
                throw new Error(window.getI18n(ti18n,"TREBBLE_DETAILS_COULDNT_BE_RETRIEVED"));
            }
            $.mobile.loading("hide");
        }).bind(this)).catch((function(error){
            $.mobile.loading("hide");
            throw error;
        }).bind(this));
    },

    setWasRemovedFromPage: function(){
        this._wasRemovedFromPage = true;
    },

    setJingleInfo : function(jingleInfo){
        this._jingleInfo = jingleInfo;
        this._updateView();
    },

    getJingleInfo : function(jingleInfo){
        return this._jingleInfo;
    },

    setGreaterInfo : function(greaterInfo){
        this._greaterInfo = greaterInfo;
        this._updateView();
    },

    getGreaterInfo : function(greaterInfo){
        return this._greaterInfo;
    },

    setIntroInfo : function(introInfo){
        this._introInfo = introInfo;
        this._updateView();
    },

    getIntroInfo : function(introInfo){
        return this._introInfo;
    },

    setOutroInfo : function(outroInfo){
        this._outroInfo = outroInfo;
        this._updateView();
    },

    getOutroInfo : function(outroInfo){
        return this._outroInfo;
    },

    setCoverArtInfo : function(coverArtUrl, coverArtUrlInfo){
        if(this._trebbleInfoJson){
            this._trebbleInfoJson.coverArtUrl = coverArtUrl;
            this._trebbleInfoJson.coverArtUrlInfo = coverArtUrlInfo;
        }
        this._updateView();
    },

    updateTrebbleNameAndDescription : function(newName, newDescription, customURL){
        if(this._trebbleInfoJson){
            this._trebbleInfoJson.name = newName;
            this._trebbleInfoJson.description = newDescription;
            this._trebbleInfoJson.customURL = customURL;
            this.nameField$el.val(this._trebbleInfoJson.name);
            this.description$el.html(this._trebbleInfoJson.description);
            this.description$el.trigger('autoresize');
            //Workaround for JQM mobile setting the placeholder to null
            //this.$el.find("#descriptionField").attr("placeholder", this.$el.find("#descriptionFieldDecorator").attr("label"));
            
            this.customUrlField$el.val(customURL);
            this.customUrlDisplayField$el.html(customURL);

        }
        
    },

    _updateCityFieldValue : function(cityId){
        this._cityId = cityId;
        this._citySelectFieldView.$el.hide();
        this.trigger("setCityFieldValueWithCityId",cityId);

    },

    hideShowCityFieldIfApplicable : function(){
        const trebbleCategory = this.categorySelect$el.val();
        if(trebbleCategory == Utils.getInstance().getLocalCategoryId()){
            this._citySelectFieldView.$el.show();
        }else{
            this._citySelectFieldView.$el.hide();
        }

    },

    _updateView : function(){
        if(!this._wasRemovedFromPage){
        const coverArtUrl = (this._trebbleInfoJson && this._trebbleInfoJson.coverArtUrl)? this._trebbleInfoJson.coverArtUrl: null;
        const coverArtUrlInfo = (this._trebbleInfoJson && this._trebbleInfoJson.coverArtUrlInfo)? this._trebbleInfoJson.coverArtUrlInfo: null;
        this.nameField$el.val(this._trebbleInfoJson? this._trebbleInfoJson.name: "");
        this.description$el.html(this._trebbleInfoJson? this._trebbleInfoJson.description:  "");
        this.description$el.trigger('autoresize');
        const syncWithRssFeed =  this._trebbleInfoJson && this._trebbleInfoJson.syncWithRssFeed;
        const rssFeedUrlContentSource =  this._trebbleInfoJson && this._trebbleInfoJson.rssFeedUrlContentSource;
        //Workaround for JQM mobile setting the placeholder to null
        //this.description$el.attr("placeholder", this.$el.find("#descriptionFieldDecorator").attr("label"));
        const customURL = this._trebbleInfoJson.customURL;
        this.customUrlField$el.val(customURL);
        this.customUrlDisplayField$el.html(customURL);
        const categoryId =  this._trebbleInfoJson.categoryId;
        this.categorySelect$el.val(categoryId);
        const cityId =  this._trebbleInfoJson.cityId;
        this._updateCityFieldValue(cityId);
        const timezone =  this._trebbleInfoJson.timezone;
        this.trebbleTimezoneField$el.val(timezone);
        let language =  this._trebbleInfoJson.language;
        if(language == "en"){
            language = "en-US";
        }
        this.trebbleLanguageField$el.val(language);
        this.enableRssFeedAutoImportCheckbox$el.get(0).checked = !!syncWithRssFeed;
        this.rssFeedUrlField$el.val(rssFeedUrlContentSource? rssFeedUrlContentSource: "");
        this.onEnableRssFeedAutoImportCheckboxChange();
        //this.$el.find("#trebbleCategoryField-button > span.browser-default").html(categoryId);
        this.$el.find("#trebbleCategoryField").selectmenu('refresh');
        this.$el.find("#trebbleTimezoneField").selectmenu('refresh');
        this.$el.find("#trebbleLanguageField").selectmenu('refresh');
        let resizedCoverArtUrl = window.trebble.globalHelpers.getResizeImageUrl(coverArtUrl,  200, 200, coverArtUrlInfo);
        if(!resizedCoverArtUrl){
            resizedCoverArtUrl = window.getResourceURLAccordingToLocationProtocol("/img/videoLoading.png");
            const coverArtWrapper$el = this.$el.find(".coverArtWrapper");
            if(!coverArtWrapper$el.hasClass("pe-7s-camera")){
                coverArtWrapper$el.addClass("pe-7s-camera");
            }
        }else{
            const coverArtWrapper$el = this.$el.find(".coverArtWrapper");
            if(coverArtWrapper$el.hasClass("pe-7s-camera")){
                coverArtWrapper$el.removeClass("pe-7s-camera");
            }
        }
        this.$el.find(".trebbleCoverArt").attr("src", resizedCoverArtUrl);
        if(this._jingleInfo){
            const sweeperAudioPlaceholder$el = this.$el.find(".audioPlaceholder.sweeper");
            if(sweeperAudioPlaceholder$el.hasClass("noAudioAvailable")){
                sweeperAudioPlaceholder$el.removeClass("noAudioAvailable");
            }
        }else{
            const sweeperAudioPlaceholder$el = this.$el.find(".audioPlaceholder.sweeper");
            if(!sweeperAudioPlaceholder$el.hasClass("noAudioAvailable")){
                sweeperAudioPlaceholder$el.addClass("noAudioAvailable");
            }
        }
        if(this._greaterInfo){
            const greaterAudioPlaceholder$el = this.$el.find(".audioPlaceholder.greater");
            if(greaterAudioPlaceholder$el.hasClass("noAudioAvailable")){
                greaterAudioPlaceholder$el.removeClass("noAudioAvailable");
            }
        }else{
            const greaterAudioPlaceholder$el = this.$el.find(".audioPlaceholder.greater");
            if(!greaterAudioPlaceholder$el.hasClass("noAudioAvailable")){
                greaterAudioPlaceholder$el.addClass("noAudioAvailable");
            }
        }
        if(this._introInfo){
            const introAudioPlaceholder$el = this.$el.find(".audioPlaceholder.intro");
            if(introAudioPlaceholder$el.hasClass("noAudioAvailable")){
                introAudioPlaceholder$el.removeClass("noAudioAvailable");
            }
        }else{
            const introAudioPlaceholder$el = this.$el.find(".audioPlaceholder.intro");
            if(!introAudioPlaceholder$el.hasClass("noAudioAvailable")){
                introAudioPlaceholder$el.addClass("noAudioAvailable");
            }
        }
        if(this._outroInfo){
            const outroAudioPlaceholder$el = this.$el.find(".audioPlaceholder.outro");
            if(outroAudioPlaceholder$el.hasClass("noAudioAvailable")){
                outroAudioPlaceholder$el.removeClass("noAudioAvailable");
            }
        }else{
            const outroAudioPlaceholder$el = this.$el.find(".audioPlaceholder.outro");
            if(!outroAudioPlaceholder$el.hasClass("noAudioAvailable")){
                outroAudioPlaceholder$el.addClass("noAudioAvailable");
            }
        }
        this.$el.find(".loadingImgForTrebbleInfo").hide();
        this.$el.find("#trebbleInfoDetailWrapper").show();
    }
    },

    getEditedTrebbleInfo : function(){
        const trebbleInfo = {};
        trebbleInfo.name = this.nameField$el.val();
        trebbleInfo.customURL = this.customUrlField$el.val();
        if(trebbleInfo.customURL == Utils.getInstance().getDummyCustomURL()){
            //If the value 
            trebbleInfo.customURL = null;
        }
        trebbleInfo.syncWithRssFeed = this.getEnableRssFeedAutoImport();
        trebbleInfo.rssFeedUrlContentSource = this.rssFeedUrlField$el.val();

        trebbleInfo.description = this.description$el.val();
        trebbleInfo.categoryId =  this.categorySelect$el.val();
        trebbleInfo.cityId =  this._citySelectFieldView.getSelectedId();
        trebbleInfo.timezone =  this.trebbleTimezoneField$el.val();
        trebbleInfo.language = this.trebbleLanguageField$el.val();
        return trebbleInfo;
    },

    setDemoSweeperPlaying: function(playing){
        this._demoSweeperPlaying = playing;
        const playDemoSweeperTextBtn  =this.$el.find("#playSweeperDemo > span.text");
        const playDemoSweeperIconBtn  =this.$el.find("#playSweeperDemo > span.iconButton");
        if(this._demoSweeperPlaying){
            playDemoSweeperIconBtn.removeClass("ion-ios7-play");
            playDemoSweeperIconBtn.addClass("fontello-icon-stop");
            playDemoSweeperTextBtn.html(window.getI18n(ti18n,"STOP_PLAYING_EXAMPLE"));
        }else{
            playDemoSweeperIconBtn.removeClass("fontello-icon-stop");
            playDemoSweeperIconBtn.addClass("ion-ios7-play");
            playDemoSweeperTextBtn.html(window.getI18n(ti18n,"PLAY_ME_AN_EXAMPLE"));
        }

    },

    setGreaterPlaying: function(playing){
        this._greaterPlaying = playing;
        const playGreaterBtn  =this.$el.find("#playGreaterBtn");
        if(this._greaterPlaying){
            playGreaterBtn.removeClass("ion-ios7-play");
            playGreaterBtn.addClass("fontello-icon-stop");
            playGreaterBtn.html(window.getI18n(ti18n,"STOP_GREETER"));
        }else{
            playGreaterBtn.removeClass("fontello-icon-stop");
            playGreaterBtn.addClass("ion-ios7-play");
            playGreaterBtn.html(window.getI18n(ti18n,"PLAY_GREETER"));
        }

    },

    setIntroPlaying: function(playing){
        this._introPlaying = playing;
        const playIntroBtn  =this.$el.find("#playIntroBtn");
        if(this._introPlaying){
            playIntroBtn.removeClass("ion-ios7-play");
            playIntroBtn.addClass("fontello-icon-stop");
            playIntroBtn.html(window.getI18n(ti18n,"STOP_INTRO"));
        }else{
            playIntroBtn.removeClass("fontello-icon-stop");
            playIntroBtn.addClass("ion-ios7-play");
            playIntroBtn.html(window.getI18n(ti18n,"PLAY_INTRO"));
        }

    },

    setOutroPlaying: function(playing){
        this._outroPlaying = playing;
        const playOutroBtn  =this.$el.find("#playOutroBtn");
        if(this._outroPlaying){
            playOutroBtn.removeClass("ion-ios7-play");
            playOutroBtn.addClass("fontello-icon-stop");
            playOutroBtn.html(window.getI18n(ti18n,"STOP_OUTRO"));
        }else{
            playOutroBtn.removeClass("fontello-icon-stop");
            playOutroBtn.addClass("ion-ios7-play");
            playOutroBtn.html(window.getI18n(ti18n,"PLAY_OUTRO"));
        }

    },

    setSweeperPlaying: function(playing){
        this._sweeperPlaying = playing;
        const playSweeperBtn  = this.$el.find("#playSweeperBtn");
        if(this._sweeperPlaying){
            playSweeperBtn.removeClass("ion-ios7-play");
            playSweeperBtn.addClass("fontello-icon-stop");
            playSweeperBtn.html(window.getI18n(ti18n,"STOP_SWEEPER"));
        }else{
            playSweeperBtn.removeClass("fontello-icon-stop");
            playSweeperBtn.addClass("ion-ios7-play");
            playSweeperBtn.html(window.getI18n(ti18n,"PLAY_SWEEPER"));
        }

    },

    setDemoGreaterPlaying: function(playing){
        this._demoGreaterPlaying = playing;
        const playDemoGreaterTextBtn  =this.$el.find("#playGreeterDemo > span.text");
        const playDemoGreaterIconBtn  =this.$el.find("#playGreeterDemo > span.iconButton");
        if(this._demoGreaterPlaying){
            playDemoGreaterIconBtn.removeClass("ion-ios7-play");
            playDemoGreaterIconBtn.addClass("fontello-icon-stop");
            playDemoGreaterTextBtn.html(window.getI18n(ti18n,"STOP_PLAYING_EXAMPLE"));
        }else{
            playDemoGreaterIconBtn.removeClass("fontello-icon-stop");
            playDemoGreaterIconBtn.addClass("ion-ios7-play");
            playDemoGreaterTextBtn.html(window.getI18n(ti18n,"PLAY_ME_AN_EXAMPLE"));
        }

    },

    setDemoIntroPlaying: function(playing){
        this._demoIntroPlaying = playing;
        const playDemoIntroTextBtn  =this.$el.find("#playIntroDemo > span.text");
        const playDemoIntroIconBtn  =this.$el.find("#playIntroDemo > span.iconButton");
        if(this._demoIntroPlaying){
            playDemoIntroIconBtn.removeClass("ion-ios7-play");
            playDemoIntroIconBtn.addClass("fontello-icon-stop");
            playDemoIntroTextBtn.html(window.getI18n(ti18n,"STOP_PLAYING_EXAMPLE"));
        }else{
            playDemoIntroIconBtn.removeClass("fontello-icon-stop");
            playDemoIntroIconBtn.addClass("ion-ios7-play");
            playDemoIntroTextBtn.html(window.getI18n(ti18n,"PLAY_ME_AN_EXAMPLE"));
        }

    },


    setDemoOutroPlaying: function(playing){
        this._demoOutroPlaying = playing;
        const playDemoOutroTextBtn  =this.$el.find("#playOutroDemo > span.text");
        const playDemoOutroIconBtn  =this.$el.find("#playOutroDemo > span.iconButton");
        if(this._demoOutroPlaying){
            playDemoOutroIconBtn.removeClass("ion-ios7-play");
            playDemoOutroIconBtn.addClass("fontello-icon-stop");
            playDemoOutroTextBtn.html(window.getI18n(ti18n,"STOP_PLAYING_EXAMPLE"));
        }else{
            playDemoOutroIconBtn.removeClass("fontello-icon-stop");
            playDemoOutroIconBtn.addClass("ion-ios7-play");
            playDemoOutroTextBtn.html(window.getI18n(ti18n,"PLAY_ME_AN_EXAMPLE"));
        }

    },


    playPauseSweeper : function(){
        if(this._sweeperPlaying){
            this.trigger("stopPlayingSweeper");
            this.setSweeperPlaying(false);
        }else{
            this.trigger("startPlayingSweeper", this._jingleInfo.uri);
            this.setSweeperPlaying(true);
        }
    },

    playPauseDemoSweeper : function(){
        if(this._demoSweeperPlaying){
            this.trigger("stopPlayingDemoSweeper");
            this.setDemoSweeperPlaying(false);
        }else{
            this.trigger("startPlayingDemoSweeper");
            this.setDemoSweeperPlaying(true);
        }
    },

    playPauseGreater : function(){
        if(this._greaterPlaying){
            this.trigger("stopPlayingGreater");
            this.setGreaterPlaying(false);
        }else{
            this.trigger("startPlayingGreater", this._greaterInfo.uri);
            this.setGreaterPlaying(true);
        }
    },

    playPauseIntro : function(){
        if(this._introPlaying){
            this.trigger("stopPlayingIntro");
            this.setIntroPlaying(false);
        }else{
            this.trigger("startPlayingIntro", this._introInfo.uri);
            this.setIntroPlaying(true);
        }
    },

    playPauseOutro : function(){
        if(this._outroPlaying){
            this.trigger("stopPlayingOutro");
            this.setOutroPlaying(false);
        }else{
            this.trigger("startPlayingGreater", this._outroInfo.uri);
            this.setOutroPlaying(true);
        }
    },

    playPauseDemoGreeter : function(){
        if(this._demoGreaterPlaying){
            this.trigger("stopPlayingDemoGreater");
            this.setDemoGreaterPlaying(false);
        }else{
            this.trigger("startPlayingDemoGreater");
            this.setDemoGreaterPlaying(true);
        }
    },

    playPauseDemoIntro : function(){
        if(this._demoIntroPlaying){
            this.trigger("stopPlayingDemoIntro");
            this.setDemoIntroPlaying(false);
        }else{
            this.trigger("startPlayingDemoIntro");
            this.setDemoIntroPlaying(true);
        }
    },

    playPauseDemoOutro : function(){
        if(this._demoOutroPlaying){
            this.trigger("stopPlayingDemoOutro");
            this.setDemoOutroPlaying(false);
        }else{
            this.trigger("startPlayingDemoOutro");
            this.setDemoOutroPlaying(true);
        }
    },


    events : {
        "click #editTrebbleCoverPicBtn": "editTrebbleCoverPic",
        "click #editSweeperButton": "editSweeper",
        "click #deleteSweeperButton": "deleteSweeper",
        "click #playSweeperBtn": "playPauseSweeper",
        "click #editGreaterButton": "editGreater",
        "click #editIntroButton": "editIntro",
        "click #editOutroButton": "editOutro",
        "click #deleteGreaterButton": "deleteGreater",
        "click #deleteIntroButton": "deleteIntro",
        "click #deleteOutroButton": "deleteOutro",
        "click #playGreaterBtn": "playPauseGreater",
        "click #playIntroBtn": "playPauseIntro",
        "click #playOutroBtn": "playPauseOutro",
        "click #playSweeperDemo": "playPauseDemoSweeper",
        "click #playGreeterDemo": "playPauseDemoGreeter",
        "click #playIntroDemo": "playPauseDemoIntro",
        "click #playOutroDemo": "playPauseDemoOutro",
        
    },

    editTrebbleCoverPic : function(){
        this.trigger("editTrebbleCoverPic");
    },

    editSweeper : function(){
        this.trigger("editSweeper");
    },

    deleteSweeper : function(){
        this.trigger("deleteSweeper");
    },

    editGreater : function(){
        this.trigger("editGreater");
    },

    editIntro : function(){
        this.trigger("editIntro");
    },

    editOutro : function(){
        this.trigger("editOutro");
    },

    deleteGreater : function(){
        this.trigger("deleteGreater");
    },

    deleteIntro : function(){
        this.trigger("deleteIntro");
    },

    deleteOutro : function(){
        this.trigger("deleteOutro");
    },
    
    onTrebbleNameFieldKeyUp: function(){
        this.trigger("nameValidation",this.nameField$el.val());
    },

    setNameValid : function(nameValid,errorMessage){
        //this.nameFieldDecorator$el.get(0).error = errorMessage;
        //this.nameFieldDecorator$el.get(0).isInvalid = !nameValid;
        //this.nameFieldDecorator$el.get(0).validate();
        if (!nameValid) {
            this.nameFieldDecorator$el.find("label").attr("data-error",errorMessage);
            if(!this.nameField$el.hasClass("invalid"))
            {
                this.nameField$el.addClass("invalid");
            }
        } else {
            this.nameField$el.removeClass("invalid");
            this.nameFieldDecorator$el.find("label").attr("data-error","");
        }
    },

    setCustomURLValid : function(customURLValid,errorMessage){
        //this.customUrlFieldDecorator$el.get(0).error = errorMessage;
        //this.customUrlFieldDecorator$el.get(0).isInvalid = !customURLValid;
        //this.customUrlFieldDecorator$el.get(0).validate();
        if (!customURLValid) {
            this.customUrlFieldDecorator$el.find("label").attr("data-error",errorMessage);
            if(!this.customUrlField$el.hasClass("invalid"))
            {
                this.customUrlField$el.addClass("invalid");
            }
        } else {
            this.customUrlField$el.removeClass("invalid");
            this.customUrlFieldDecorator$el.find("label").attr("data-error","");
        }
    },

    setDescriptionValid : function(descriptionValid,errorMessage){
        //this.descriptionDecorator$el.get(0).error = errorMessage;
        //this.descriptionDecorator$el.get(0).isInvalid = !descriptionValid;
        //this.descriptionDecorator$el.get(0).validate();
        if (!descriptionValid) {
            this.descriptionDecorator$el.find("label").attr("data-error",errorMessage);
            if(!this.description$el.hasClass("invalid"))
            {
                this.description$el.addClass("invalid");
            }
        } else {
            this.description$el.removeClass("invalid");
            this.descriptionDecorator$el.find("label").attr("data-error","");
        }
    },

    setRssFeedURLValid : function(rssFeedUrlValid,errorMessage){
        
        if (!rssFeedUrlValid) {
            this.rssFeedUrlFieldDecorator$el.find("label").attr("data-error",errorMessage);
            if(!this.rssFeedUrlField$el.hasClass("invalid"))
            {
                this.rssFeedUrlField$el.addClass("invalid");
            }
        } else {
            this.rssFeedUrlField$el.removeClass("invalid");
            this.rssFeedUrlFieldDecorator$el.find("label").attr("data-error","");
        }
    },



    onTrebbleCustomUrlFieldKeyUp : function(){
        this.customUrlField$el.val( this.customUrlField$el.val().replace(/ /g, "-").toLowerCase());
        const newCustomUrlValue  = this.customUrlField$el.val();
        this.customUrlDisplayField$el.html(newCustomUrlValue);

    },


    onTrebbleCustomUrlFieldBlur : function(){
        this.trigger("customURLValidation",this.customUrlField$el.val());
    },

    onRssFeedUrlFieldBlur : function(){
        this.trigger("rssFeedUrlFieldValidation",this.rssFeedUrlField$el.val());
    },

    onDataChanged : function(){
        this.trigger("formChanged");
        if(this._validationTriggerFunctionTimeout){
            clearTimeout(this._validationTriggerFunctionTimeout);
        }
        this._validationTriggerFunctionTimeout = setTimeout((function(){
            this.trigger("validateForm");
        }).bind(this), FORM_VALIDATION_DELAY_IN_MILLISEC);
    },

    validateNameField : function(){
        /* if (!arePasswordTheSame) {
            const errorMessage = "Passwords Don't Match";
            //reenterPasswordInput.setCustomValidity(errorMessage);
            this.reenterNewPassswordFieldDec$el.find("label").attr("data-error",errorMessage);
            if(!this.reenterNewPassswordField$el.hasClass("invalid"))
            {
                this.reenterNewPassswordField$el.addClass("invalid");
            }
        } else {
            // empty string means no validation error
            this.reenterNewPassswordField$el.removeClass("invalid");
            //reenterPasswordInput.setCustomValidity('');
            this.reenterNewPassswordFieldDec$el.find("label").attr("data-error","");
        }*/
        return !this.nameFieldDecorator$el.get(0).isInvalid;
    },

    validateDescriptionField : function(){
        return !this.descriptionDecorator$el.get(0).isInvalid;
    },

    validateCustomURLField : function(){
        return !this.customUrlFieldDecorator$el.get(0).isInvalid;
    },

    validateRssFeedURLField : function(){
        return !this.rssFeedUrlFieldDecorator$el.get(0).isInvalid;
    },

    _initializeTrebbleCategoryField : function(){
        const categoryListItemArray  = Utils.getInstance().getCapsuleCategoryListItemArray();
        const currentCategoryId = this._trebbleInfoJson? this._trebbleInfoJson.categoryId : null;
        const currentCityId = this._trebbleInfoJson? this._trebbleInfoJson.cityId : null;
        this._updateCityFieldValue(currentCityId);
        //Retrieve city name from city id
        categoryListItemArray.map((function(listItem,index){
            if(currentCategoryId === listItem.getId()){
                this.categorySelect$el.append("<option value='"+listItem.getId()+"' selected='selected'>"+ listItem.getLabel()+"</option>");
            }else{
                this.categorySelect$el.append("<option value='"+listItem.getId()+"'>"+ listItem.getLabel()+"</option>");
            }
        }).bind(this));
        this.categorySelect$el.after('<i class="ion-chevron-down" style="display: inline-block;"></i>');
        this.categorySelect$el.on("change",(function(){
            this.hideShowCityFieldIfApplicable();
        }).bind(this))
    },

    _initializeTrebbleTimezoneField : function(){
        const timezoneListItemArray  = Utils.getInstance().getTimezoneListItemArray();
        const timezoneId = this._trebbleInfoJson? this._trebbleInfoJson.timezone : null;
        timezoneListItemArray.map((function(listItem,index){
            if(timezoneId === listItem.getId()){
                this.trebbleTimezoneField$el.append("<option value='"+listItem.getId()+"' selected='selected'>"+ listItem.getLabel()+"</option>");
            }else{
                this.trebbleTimezoneField$el.append("<option value='"+listItem.getId()+"'>"+ listItem.getLabel()+"</option>");
            }
        }).bind(this));
        this.trebbleTimezoneField$el.after('<i class="ion-chevron-down" style="display: inline-block;"></i>');
    },

    _initializeTrebbleLanguageField : function(){
        const languageListItemArray  = Utils.getInstance().getLanguageListItemArray();
        let languageCode = this._trebbleInfoJson? this._trebbleInfoJson.language : null;
        if(languageCode == "en"){
            languageCode =  "en-US";
        }
        languageListItemArray.map((function(listItem,index){
            if(languageCode === listItem.getId()){
                this.trebbleLanguageField$el.append("<option value='"+listItem.getId()+"' selected='selected'>"+ listItem.getLabel()+"</option>");
            }else{
                this.trebbleLanguageField$el.append("<option value='"+listItem.getId()+"'>"+ listItem.getLabel()+"</option>");
            }
        }).bind(this));
        this.trebbleLanguageField$el.after('<i class="ion-chevron-down" style="display: inline-block;"></i>');
    },

    getEnableRssFeedAutoImport : function(){
        return this.enableRssFeedAutoImportCheckbox$el.get(0).checked;
    },

    onEnableRssFeedAutoImportCheckboxChange : function(){
        if(!this.getEnableRssFeedAutoImport()){
            if(!this.rssFeedUrlFieldDecorator$el.hasClass("isHidden")){
                this.rssFeedUrlFieldDecorator$el.addClass("isHidden");
            }
        }else{
            this.rssFeedUrlFieldDecorator$el.removeClass("isHidden");

        }
    },
    _initializeCityField : function(){
        this.$el.find(".cityFieldWrapper").append(this._citySelectFieldView.$el);
    },

    render : function() {
        this.$el.html(this.template({"model": this._trebbleInfoJson, "ti18n": ti18n}));
        this.$el.enhanceWithin();
        this.nameField$el = this.$el.find("#nameField");
        this.nameFieldDecorator$el = this.$el.find("#nameFieldDecorator");
        this.nameFieldDecorator$el.get(0).validate = this.validateNameField.bind(this);
        this.customUrlField$el = this.$el.find("#customUrlField");
        this.customUrlFieldDecorator$el = this.$el.find("#customUrlFieldDecorator");
        this.customUrlFieldDecorator$el.get(0).validate = this.validateCustomURLField.bind(this);
        this.customUrlDisplayField$el = this.$el.find("#currentCustomURL");
        this.description$el = this.$el.find("#descriptionField");
        this.descriptionDecorator$el = this.$el.find("#descriptionFieldDecorator");
        this.categorySelect$el = this.$el.find("#trebbleCategoryField");
        this.trebbleTimezoneField$el = this.$el.find("#trebbleTimezoneField");
        this.trebbleLanguageField$el = this.$el.find("#trebbleLanguageField");
        this.enableRssFeedAutoImportCheckbox$el = this.$el.find("#enableRssFeedAutoImportCheckbox");
        this.rssFeedUrlField$el = this.$el.find("#rssFeedUrlField");
        this.rssFeedUrlFieldDecorator$el = this.$el.find("#rssFeedUrlFieldDecorator");
        this.rssFeedUrlFieldDecorator$el.get(0).validate = this.validateRssFeedURLField.bind(this);
        
        this.descriptionDecorator$el.get(0).validate = this.validateDescriptionField.bind(this);
        this.enableRssFeedAutoImportCheckbox$el.change(this.onEnableRssFeedAutoImportCheckboxChange.bind(this));
        this._initializeTrebbleCategoryField();
        this._initializeTrebbleTimezoneField();
        this._initializeTrebbleLanguageField();
        this._initializeCityField();
        this.onEnableRssFeedAutoImportCheckboxChange();

        //this.nameField$el.on("keyup", this.onTrebbleNameFieldKeyUp.bind(this));
        this.customUrlField$el.on("keyup input", this.onTrebbleCustomUrlFieldKeyUp.bind(this));
        
        //this.customUrlField$el.on("blur", this.onTrebbleCustomUrlFieldBlur.bind(this));

        this.$el.find(".formField:not(#rssFeedUrlField):not(#rssFeedUrlFieldDecorator)").on("keyup input", this.onDataChanged.bind(this));
        this.$el.find("select").on("change", this.onDataChanged.bind(this));
        this.$el.find("#rssFeedUrlField").on("keyup input", this.onDataChanged.bind(this));
        //this.$el.find(".ui-textinput-autogrow").on("keyup", this.onDataChanged.bind(this));
        //this.$el.find(".formField").on("blur", this.onDataChanged.bind(this));
        //this.$el.find(".ui-textinput-autogrow").on("blur", this.onDataChanged.bind(this));
        //this.nameField$el.on("blur", this.onDataChanged.bind(this));
        //this.customUrlField$el.on("blur", this.onDataChanged.bind(this));
        //this.description$el.on("blur", this.onDataChanged.bind(this));
        
        //this.customUrlField$el.get(0).validate = this.validateCustomUrlField.bind(this);
        return this;
    }

});

export default SongOptionView;