export default function(soundManager){

	soundManager.setup({
		url: '/js/libs/soundmanager2/swf',
		flashVersion: 9, 
		preferFlash: false,
		debugMode: false,
		ignoreMobileRestrictions: true,
		forceUseGlobalHTML5Audio: false,
		useHTML5Audio: true,
		onready: function() {
			//console.log("soundmanager is ready");
		}
	});
}