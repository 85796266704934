
import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import ReplaceSelectedNodesOperation from "models/audioEditor/sequencerOperation/ReplaceSelectedNodesOperation";
import StartWrapSequencerNode from "models/audioEditor/sequencerNodes/StartWrapSequencerNode";
import EndWrapSequencerNode from "models/audioEditor/sequencerNodes/EndWrapSequencerNode";
import DeletedSequencerNode from "models/audioEditor/sequencerNodes/DeletedSequencerNode";
import SequencerOperation from "models/audioEditor/sequencerOperation/SequencerOperation";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";


const UpdateSequencerNodePropertyOperation =  SequencerOperation.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperation.apply(this, [attributes, options]);
    },

    execute : function(sequencerNode, updatePropertyFunctionName, retrievePropertyFunctionName, newValue){
        const oldValue = sequencerNode[retrievePropertyFunctionName]();
        sequencerNode[updatePropertyFunctionName](newValue);
        this.getSequencer().getHistoryManagement().addUpdatePropertyOnSequencerNodeLog(sequencerNode, updatePropertyFunctionName, oldValue, newValue);
    },

    undo : function(sequencerOperationChangeSequencerNodePropertyLog ){
        const sequencerNode = sequencerOperationChangeSequencerNodePropertyLog.getSequencerNode();
        const updatePropertyFunctionName = sequencerOperationChangeSequencerNodePropertyLog.getUpdatePropertyFunctionName();
        const oldValue = sequencerOperationChangeSequencerNodePropertyLog.getOldValue();
        const newValue = sequencerOperationChangeSequencerNodePropertyLog.getNewValue();
        sequencerNode[updatePropertyFunctionName](oldValue);
    },

    redo: function(sequencerOperationChangeSequencerNodePropertyLog){
        const sequencerNode = sequencerOperationChangeSequencerNodePropertyLog.getSequencerNode();
        const updatePropertyFunctionName = sequencerOperationChangeSequencerNodePropertyLog.getUpdatePropertyFunctionName();
        const oldValue = sequencerOperationChangeSequencerNodePropertyLog.getOldValue();
        const newValue = sequencerOperationChangeSequencerNodePropertyLog.getNewValue();
        sequencerNode[updatePropertyFunctionName](newValue);
    },

});


export default UpdateSequencerNodePropertyOperation; 