import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import ArtistFullDetailPageView from "views/artistDetail/ArtistFullDetailPageView";
import SongListController from "controllers/common/SongListController2";
import AlbumListController from "controllers/common/AlbumListController2";
import ArtistListController from "controllers/common/ArtistListController2";
import YoutubeListController from "controllers/common/YoutubeListController";
import YoutubeSearchHelper from "models/helper/YoutubeSearchHelper";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import TrebbleIndexDBHelper from "models/helper/TrebbleIndexDBHelper";
import ti18n from "i18n!nls/ArtistFullDetailsi18n";
import PersistentModels from "services/PersistentModels";
import PlayerService from "services/PlayerService";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const NUMBER_OF_RESULTS = 4;
const ArtistDetailController = Chaplin.Controller.extend({


    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if (options) {
            if (options.view) {
                this.view = options.view;
            }
            if (options.artistId) {
                this.model = options.artistId;
                this.artistId = options.artistId;
            }
            if(options.artistModel){
                this.artistModel = options.artistModel;
            }
            if (options.artistName) {
                this.artistName = options.artistName;
            }
            this.pageId = options.pageId;
        }
        
                    
    },
    
    getModel : function(){
        return this.model;
    },
    
    getView : function(){
        return this.view;
    },

    
    
    
    addSongToQueue : function(albumModel){
        const songs = albumModel.get("songs");
        const remoteAndLocalSongs = [];
        if(songs)
        {
            for(let songIndex in songs ){
                const songJson =  songs[songIndex];
                if(songJson)
                {	
                    let songFound = PersistentModels.getInstance().getSongModelBySongIdFromMyLibraryOrFavorites( songJson.uri);
                    if(!songFound)
                    {
                        songFound = Utils.getInstance().getModelFromSongJson(songJson);
                    }
                    if(songFound){
                        remoteAndLocalSongs.push(songFound);
                    }
                }
                
            }
        }
        PlayerService.getInstance().addSongsToQueue(remoteAndLocalSongs);
    },
    

    
    showArtistMenuOptionPopup : function(){
        PersistentModels.getInstance().getRouter().showArtistOptionPopup(this.artistModel);
    },
    
    shuffleAllSongFromArtist : function(artistModel){
        const songUris = [];
        const albums = artistModel.get("albums");
        for(let albumIndex in albums){
            const albumModel = albums[albumIndex];
            for(let songIndex in albumModel.songs){
                const songJson =  albumModel.songs[songIndex];
                songUris.push(songJson.uri);
                
            }
        }
        const arrayOfAlbumSongs = PersistentModels.getInstance().getSongCollection().getSongsWithURI(songUris);
        const shuffleArrayOfAlbumSongs =  _.shuffle(arrayOfAlbumSongs);
        let playlistName = window.getI18n(ti18n,"ALL_MY_MUSIC_FROM");
        if(this.model && this.model.get("artistName"))
        {
            playlistName = playlistName + " " + this.model.get("artistName");
        }else{
            playlistName = playlistName + " artist";
        }
        const isMusicCurrentlyPlaying =  PlayerService.getInstance().isMusicPlaying();
        const context =  Utils.getInstance().buildContextForSongCollectionArtist(this.getArtistId());
        PlayerService.getInstance().clearQueueAndSongToQueue(shuffleArrayOfAlbumSongs , playlistName, context);
        if(!isMusicCurrentlyPlaying)
        {	
            PlayerService.getInstance().playFirstSongInQueue();
        }
    },
    
    
    filterList : function(filters){
    window.alertErrorMessage(window.getI18n(ti18n,"COMING_SOON"));
    },
    
    retrieveTopTracks : function(){
        return TrebbleClientAPIHelper.getInstance().getTopTracksByArtistIdOrName(this.artistId, this.artistName).then((function(topSongsJsonArray){
            //const arrayOfSongsWithAlbumAndArtistRef = TrebbleIndexDBHelper.getInstance().getSongsWithAlbumAndArtistInfoFromSongJsonArray(topSongsJsonArray);
            this.topSongsJsonArray = topSongsJsonArray;
            let truncatedtTopSongsJsonArray = topSongsJsonArray;
            if(topSongsJsonArray){
                truncatedtTopSongsJsonArray = topSongsJsonArray.slice(0, NUMBER_OF_RESULTS+1);
            }
            this.topSongsListController.updateListOfSongs(truncatedtTopSongsJsonArray);
        }).bind(this));
        
    },

    retrieveAlbumsForArtist : function(){
        return TrebbleClientAPIHelper.getInstance().getAlbumsForArtist(this.artistId, this.artistName,  "album", NUMBER_OF_RESULTS, 0).then((function(albumJsonArray){
            this.albumListController.updateListOfAlbums(albumJsonArray);
        }).bind(this));
    },
    
    retrieveCompilationForArtist : function(){
        return TrebbleClientAPIHelper.getInstance().getAlbumsForArtist(this.artistId, this.artistName,  "compilation", NUMBER_OF_RESULTS, 0).then((function(albumJsonArray){
            this.compilationAlbumListController.updateListOfAlbums(albumJsonArray);
        }).bind(this));
    },
    
    retrieveSinglesForArtist : function(){
        return TrebbleClientAPIHelper.getInstance().getAlbumsForArtist(this.artistId, this.artistName,  "single", NUMBER_OF_RESULTS, 0).then((function(albumJsonArray){
            this.singleAlbumListController.updateListOfAlbums(albumJsonArray);
        }).bind(this));
    },
    
    retrieveAppearOnForArtist : function(){
        return TrebbleClientAPIHelper.getInstance().getAlbumsForArtist(this.artistId, this.artistName,  "appears_on", NUMBER_OF_RESULTS, 0).then((function(albumJsonArray){
            this.appearOnAlbumListController.updateListOfAlbums(albumJsonArray);
        }).bind(this));
    },

    retrieveRelatedArtists : function(){
        return TrebbleClientAPIHelper.getInstance().getRelatedArtistsByArtistIdOrName(this.artistId, this.artistName).then((function(artistJsonArray){
            this.relatedArtistJsonArray = artistJsonArray;
            let truncatedArray = artistJsonArray;
            if(artistJsonArray){
                truncatedArray = artistJsonArray.slice(0, NUMBER_OF_RESULTS);
            }
            this.relatedArtistListController.updateListOfArtists(truncatedArray);
        }).bind(this));
    },
    
    retrieveYoutubeVideos : function(){
        return YoutubeSearchHelper.getInstance().getSongJsonFromVideos(this.artistName, NUMBER_OF_RESULTS,true).then((function(arrayOfYoutubeVideoInfo){
                const arrayOfYoutubeVideosWithAlbumAndArtistRef = TrebbleIndexDBHelper.getInstance().getSongsWithAlbumAndArtistInfoFromSongJsonArray(arrayOfYoutubeVideoInfo);
                this.youtubeVideoListController.updateListOfSongs(arrayOfYoutubeVideosWithAlbumAndArtistRef);
            
        }).bind(this));
    },
    
    retrieveArtistInfo : function(){
        if(!this.artistModel || !this.artistModel.get("artistId") || this.artistModel.get("artistId").indexOf("spotify:artist") != 0 ){
            return  TrebbleClientAPIHelper.getInstance().getArtistInfoByArtistIdOrName(this.artistId, this.artistName).then((function(artistJson){
                this.artistModel = Utils.getInstance().getModelFromArtistJson(artistJson);
                if(this.artistModel){
                    this.artistId = this.artistModel.get("artistId");
                    this.artistName = this.artistModel.get("artistName");
                }
                this.view.setArtistModel(this.artistModel);
                this.updateContext(Utils.getInstance().buildContextForSongCollectionArtist(this.getArtistId()));
            }).bind(this));
        }else{
            this.updateContext(Utils.getInstance().buildContextForSongCollectionArtist(this.getArtistId()));
            this.view.setArtistModel(this.artistModel);
            return  RSVP.Promise.resolve();
        }
    },

    getArtistId : function(){
        return this.artistModel? this.artistModel.get("artistId"): this.artistId;
    },

    updateContext : function(newContext){
        if(this.topSongsListController )
        {
            this.topSongsListController.updateContext(newContext);
        }
    },
    
    retrieveArtistBio : function(){
        
            return TrebbleClientAPIHelper.getInstance().getArtistBiographyInfoByArtistIdOrName(this.artistId, this.artistName).then((function(artistBioJson){
                this.artistBioJson = artistBioJson;
                this.view.setBiographyView(this.artistBioJson);
            }).bind(this));
        
    },
    
    retrieveArtistDetails : function(){
        return this.retrieveArtistInfo().then((function(){
            this.retrieveTopTracks();
            this.retrieveArtistBio();
            this.retrieveAlbumsForArtist();
            this.retrieveCompilationForArtist();
            this.retrieveSinglesForArtist();
            this.retrieveAppearOnForArtist();
            this.retrieveRelatedArtists();
            this.retrieveYoutubeVideos()
        }).bind(this))
    
        
        
    },
    
    showAllRelatedArtists : function(){
        const loadHandler = (function(){
            return TrebbleClientAPIHelper.getInstance().getRelatedArtistsByArtistIdOrName(this.artistId, this.artistName);
        }).bind(this);
        PersistentModels.getInstance().getRouter().showAllRelatedArtistListPage(this.artistId, loadHandler, window.getI18n(ti18n,"RELATED_ARTISTS"), true);
    },
    
    showAllSinglesArtists : function(){
        const loadHandler = (function(){
            return TrebbleClientAPIHelper.getInstance().getAlbumsForArtist(this.artistId, this.artistName,  "single");
        }).bind(this);
        PersistentModels.getInstance().getRouter().showAllAlbumListPage(this.artistId, loadHandler, window.getI18n(ti18n,"SINGLES"), true);
    },
    
    showAllCompilationsArtists : function(){
        const loadHandler = (function(){
            return TrebbleClientAPIHelper.getInstance().getAlbumsForArtist(this.artistId, this.artistName,  "compilation");
        }).bind(this);
        PersistentModels.getInstance().getRouter().showAllAlbumListPage(this.artistId, loadHandler, window.getI18n(ti18n,"COMPILATIONS"), true);
    },
    
    showAllAlbumsArtists : function(){
        const loadHandler = (function(){
            return TrebbleClientAPIHelper.getInstance().getAlbumsForArtist(this.artistId, this.artistName,  "album");
        }).bind(this);
        PersistentModels.getInstance().getRouter().showAllAlbumListPage(this.artistId, loadHandler, window.getI18n(ti18n,"ALBUMS"), true);
    },


    showAllAppearsOnArtists : function(){
        const loadHandler = (function(){
            return TrebbleClientAPIHelper.getInstance().getAlbumsForArtist(this.artistId, this.artistName,  "appears_on");
        }).bind(this);
        PersistentModels.getInstance().getRouter().showAllAlbumListPage(this.artistId, loadHandler, window.getI18n(ti18n,"APPEARS_ON"), true);
    },

    
    onViewRemoved:function(){
        this.stopListening();
    },



    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve,reject){
            try{
                const defaultParams  = {};
                defaultParams.doNotSortDisplayedCollection = true;
                defaultParams.resizePlaceHolderElem = true;
                defaultParams.displaySongOnDeviceOnFirstLoading = false;
                defaultParams.showEmtptyInfoBox = false;
                const context =  Utils.getInstance().buildContextForSongCollectionArtist(this.getArtistId());
                defaultParams.context = context;

                this.topSongsListController = new SongListController(defaultParams);
                this.relatedArtistListController =  new ArtistListController(defaultParams);
                this.singleAlbumListController =  new AlbumListController(defaultParams);
                this.albumListController =  new AlbumListController(defaultParams);
                this.appearOnAlbumListController =  new AlbumListController(defaultParams);
                this.compilationAlbumListController =  new AlbumListController(defaultParams);
                this.youtubeVideoListController = new YoutubeListController(defaultParams);

                const promiseArray = [];
                promiseArray.push(this.topSongsListController.buildModelAndRenderView());
                promiseArray.push(this.relatedArtistListController.buildModelAndRenderView());
                promiseArray.push(this.singleAlbumListController.buildModelAndRenderView());
                promiseArray.push(this.albumListController.buildModelAndRenderView());
                promiseArray.push(this.compilationAlbumListController.buildModelAndRenderView());
                promiseArray.push(this.appearOnAlbumListController.buildModelAndRenderView());
                promiseArray.push(this.youtubeVideoListController.buildModelAndRenderView());
        
                
                
                RSVP.Promise.all(promiseArray).then((function(){
                    
                    this.topSongsListView = this.topSongsListController.getView();
                    this.relatedArtistListView = this.relatedArtistListController.getView();
                    this.singleAlbumListView = this.singleAlbumListController.getView();
                    this.albumListView = this.albumListController.getView();
                    this.compilationAlbumListView = this.compilationAlbumListController.getView();
                    this.appearOnAlbumListView = this.appearOnAlbumListController.getView();
                    this.youtubeVideoListView = this.youtubeVideoListController.getView();
                    
                    this.topSongsCollection = this.topSongsListController.getModel();
                    this.relatedArtistCollection = this.relatedArtistListController.getModel();
                    this.singleAlbumCollection = this.albumListController.getModel();
                    this.albumCollection = this.albumListController.getModel();
                    this.compilationAlbumCollection = this.compilationAlbumListController.getModel();
                    this.appearOnAlbumCollection = this.appearOnAlbumListController.getModel();
                    this.youtubeVideoCollection = this.youtubeVideoListController.getModel();
                
                    const artistFullDetailPageViewParams  = {};
                    
                    artistFullDetailPageViewParams.artistName =  this.artistName;
                    artistFullDetailPageViewParams.artistId =  this.model;
                    artistFullDetailPageViewParams.pageId =  this.pageId;
                    
                    artistFullDetailPageViewParams.topSongsListView = this.topSongsListView;
                    artistFullDetailPageViewParams.relatedArtistListView = this.relatedArtistListView;
                    artistFullDetailPageViewParams.singleAlbumListView = this.singleAlbumListView;
                    artistFullDetailPageViewParams.albumListView = this.albumListView;
                    artistFullDetailPageViewParams.compilationAlbumListView = this.compilationAlbumListView;
                    artistFullDetailPageViewParams.appearOnAlbumListView = this.appearOnAlbumListView;
                    artistFullDetailPageViewParams.youtubeVideoListView = this.youtubeVideoListView;
                    artistFullDetailPageViewParams.artistModel = this.artistModel;
                    
                    this.view = new ArtistFullDetailPageView(artistFullDetailPageViewParams);
                    this.listenTo(this.view, "shuffleAllSongFromArtist", this.shuffleAllSongFromArtist);
                    this.listenTo(this.view, "showArtistMenuOptionPopup", this.showArtistMenuOptionPopup);
                    this.listenTo(this.view, "showAllRelatedArtists", this.showAllRelatedArtists);
                    this.listenTo(this.view, "showAllCompilationsAlbums", this.showAllCompilationsArtists);
                    this.listenTo(this.view, "showAllAlbums", this.showAllAlbumsArtists);
                    this.listenTo(this.view, "showAllAppearsOnAlbums", this.showAllAppearsOnArtists);
                    this.listenTo(this.view, "showAllSingleAlbums", this.showAllSinglesArtists);
                    this.listenTo(this.view, "remove", this.onViewRemoved);
                    this.listenTo(this.view, "filter", this.filterList);
                    this.view.render();
                    this.retrieveArtistDetails();
                    resolve();
                }).bind(this)).catch(reject);
            }catch(error){
                reject(error);
            }
            
        }).bind(this));

        
    }

});
export default ArtistDetailController;