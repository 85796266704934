import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import DynamicPageView from "views/common/DynamicPageView";
import Utils from "models/helper/Utils";
import ti18n from "i18n!nls/RecordAudioHightlightPagei18n";
import PersistentModels from "services/PersistentModels";
import EditShortcastInfoPageTemplate from 'text!../../../templates/capsule/EditShortcastInfoPageTemplate.html';
import Mousetrap from 'mousetrap';

const BIO_MAX_LENGTH =  180;
const FORM_VALIDATION_DELAY_IN_MILLISEC = 500;
const EditShortcastInfoPageView = DynamicPageView.extend({

    //template : _.template(ExplorePageTemplate),

    initialize : function(options) {
        const dynamicPageOptions = {};
        this.model =  options? options.model: {};
        this._pageId = (options && options.pageId)? options.pageId:"EditShortcastInfoPage";
        dynamicPageOptions.pageViewTemplate = EditShortcastInfoPageTemplate;
        dynamicPageOptions.model = options.shortcastInfoJson;
        dynamicPageOptions.pageId = this._pageId;
        dynamicPageOptions.headerFullscren = true;
        dynamicPageOptions.persistent = true;
        dynamicPageOptions.onPageShow = this.onPageShow.bind(this);
        dynamicPageOptions.pageTemplateOptions = {"ti18n": ti18n};
        //PersistentModels.getInstance().getRouter().registerForViewportHeightChange(this._pageId,this._onViewportHeightChanged.bind(this));
        return DynamicPageView.prototype.initialize.call(this, dynamicPageOptions);  
    },

    events : {
        "click #header-back-button" : "navigateToPreviousPage",
        "click #saveButtonIcon:not(.disabled)" : "saveChanges",
    },

    saveChanges : function(){
        this.trigger("saveChanges", this.getFormData());
        this.setSaveButtonEnabled(false);
    },

    isSaveButtonEnabled :function(){
        return this.saveButton$el.hasClass("disabled");
    },

    setTitleValid : function(titleValid,errorMessage){
        if (!titleValid) {
            this.titleFieldDecorator$el.find("label").attr("data-error",errorMessage);
            if(!this.titleField$el.hasClass("invalid"))
            {
                this.titleField$el.addClass("invalid");
            }
        } else {
            this.titleField$el.removeClass("invalid");
            this.titleFieldDecorator$el.find("label").attr("data-error","");
        }
    },


    setFormBeingValidated : function(beingValidated){
        if(!beingValidated){
            this.$el.removeClass("validating");
        }else{
            if(!this.$el.hasClass("validating")){
                this.$el.addClass("validating");
            }
        }
    },


    getFormData: function(){
        const title  = this.$el.find("#shortcastTitleField").val();
        const notes = this.$el.find("#notesField").val();
        const data = {};
        data.title =  title;
        data.notes =  notes;
        return data;
    },



    navigateToPreviousPage : function(){
        PersistentModels.getInstance().getRouter().navigateToPreviousPage();
    },

    onBeforePageInitialized : function(){

    },

    setSaveButtonEnabled : function(enabled){
        if(enabled){
            this.saveButton$el.removeClass("disabled");
        }else{
            if(!this.saveButton$el.hasClass("disabled")){
                this.saveButton$el.addClass("disabled");
            }
        }
    },

    _onViewportHeightChanged : function(){
        const headerHeight = 54 ;
        this.$el.find(".ui-content").css("height",(window.getDocumentClienHeight() -headerHeight)+ "px");
    },



    onDataChanged : function(){
        this.trigger("formChanged");
        if(this._validationTriggerFunctionTimeout){
            clearTimeout(this._validationTriggerFunctionTimeout);
        }
        this._validationTriggerFunctionTimeout = setTimeout((function(){
            this.trigger("validateForm");
        }).bind(this), FORM_VALIDATION_DELAY_IN_MILLISEC);
    },

    onAfterPageInitialized : function(){

        this.saveButton$el  = this.$el.find("#saveButtonIcon")
        this.titleField$el = this.$el.find("#shortcastTitleField");
        this.titleFieldDecorator$el = this.$el.find("#shortcastTitleFieldDec");
        this.notesField$el = this.$el.find("#notesField");
        this.notesFieldDecorator$el = this.$el.find("#notesFieldDecorator");
        
        this.$el.find(".formField").on("keyup input", this.onDataChanged.bind(this));
        


        this.onDataChanged();
        this.setSaveButtonEnabled(false);
        this.$el.on("pagehide", this._onPageHide.bind(this));
        this._mousetrapObj = Mousetrap(this.$el.find("#updateUserInfoForm").get(0)).bind('enter', (function(e, combo) {
            if(this.isSaveButtonEnabled()){
                this.saveChanges();
            }
        }).bind(this));

    },

    onPageShow : function(){
        this.trigger("pageShow");
    },
    onBeforeRemoveFromPage : function(){
        DynamicPageView.prototype.onBeforeRemoveFromPage.call(this); 
        PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._pageId);
    },

});
export default EditShortcastInfoPageView;