import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import Utils from "models/helper/Utils";
import SongsCollection from "collections/myLibrary/SongsCollection";
import SongListView from "views/common/SongListView";
import Playlist from "collections/Playlist";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PersistentModels from "services/PersistentModels";
import PlayerService from "services/PlayerService";
import ti18n from "i18n!nls/Commoni18n";
import RSVP from "rsvp";

SongsCollection.prototype.model = PersistentModels.getInstance().getSongContructorFunctionForCollection();
const SongListController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if(options)
        {
            if(options.model)
            {
                if(Utils.getInstance().is("Array",options.model))
                {
                    const songJsonArray = options.model;
                    this.model = new SongsCollection(songJsonArray);
                }else{
                        this.model = options.model;
                }
                
            }
            
            if(options.resizePlaceHolderElem)
            {
                this.resizePlaceHolderElem = options.resizePlaceHolderElem;
            }else{
                this.resizePlaceHolderElem = false;
            }
            if(options.showEmtptyInfoBox){
                this._showEmtptyInfoBox = options.showEmtptyInfoBox;
            }
            if(options.loadHandler)
            {
                this._loadHandler = options.loadHandler;
            }
            if(options.context){
                if(this.model instanceof SongsCollection){
                    this.model.updateContext(options.context);
                }
                this._modelContext = options.context;
            }
            if(options.customInfoBoxInfoParams){
                this._customInfoBoxInfoParams = options.customInfoBoxInfoParams;
            }
            if(options.onInfoButtonPressed){
                this._onInfoButtonPressed = options.onInfoButtonPressed;
            }
            this._doNotSortDisplayedCollection = options.doNotSortDisplayedCollection;
            if(options.sortDescending){
                this._sortDescending = options.sortDescending;
            }
            if(!this._doNotSortDisplayedCollection && options.sortField ){
                this._sortField = options.sortField;
                if(this.model){
                    this.model.sortByField(this._sortField, this._sortDescending);
                }
            }
            if(options.actionExecutedOnSongHandler){
                this._actionExecutedOnSongHandler = options.actionExecutedOnSongHandler;
            }
            this._defaultHeightElement = (options && options.defaultHeightElement)? options.defaultHeightElement: null;
            this._customListClass = (options && options.customListClass)? options.customListClass: null;
            this._songWithAudioPreviewOnly = (options && options.songWithAudioPreviewOnly)? options.songWithAudioPreviewOnly: false; 
            if(options.heightMargin){
                this._heightMargin = options.heightMargin;
            }
        }
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },
    
    
    updateListOfSongs : function(arrayOfSongsJson){
        this.model.reset(arrayOfSongsJson/*, {silent: true}*/);
    },
    

    
    clearQueueAndSongAndRestToQueue : function(model, diplayedCollection){
        const isMusicCurrentlyPlaying =  PlayerService.getInstance().isMusicPlaying();
        PlayerService.getInstance().clearQueueAndSongToQueue(model, this._buildPlayListName(), diplayedCollection.getContext());
        if(!isMusicCurrentlyPlaying)
        {
            PlayerService.getInstance().playFirstSongInQueue();
        }
        window.setTimeout((function(){
            const backboneModel =  this.diplayedCollection.get(model.get("uri"));
            PlayerService.getInstance().addSongsToQueue(this.diplayedCollection.getSongsAfter(backboneModel), true);
        }).bind({"diplayedCollection": diplayedCollection}),0);
        
    },
    
    _buildPlayListName : function(){
        if(this.view.isSearchApplied()){
            const searchKey = this.view.getAppliedSearchCharacters();
            return window.getI18n(ti18n, "SEARCH_RESULTS_FROM")+ " \""+searchKey+"\" "+ window.getI18n(ti18n, "IN_MY_SONG_LIBRARY");
        }else{
            return window.getI18n(ti18n, "ALL_MUSIC_FROM_MY_SONG_LIBRARY");
        }
    },
    
    addSongToQueue :function(songModelSwiped){
        PlayerService.getInstance().addSongsToQueue(this.model.where({uri:songModelSwiped.get("uri")}));
    },
    
    showMenuOption : function(songModelTapped){
        PersistentModels.getInstance().getRouter().showSongMenuOption(songModelTapped, this._modelContext,null,this.onActionExecutedOnSongHandler.bind(this));
    },
    
    onActionExecutedOnSongHandler : function(songModel, action){
        if(this._actionExecutedOnSongHandler){
            this._actionExecutedOnSongHandler(songModel, action);
        }
    },
    
    removeSongFromCollection:function(songModel){
        if(songModel){
            this.model.remove(songModel);
            this.reload();
        }
    },
    
     updateInfoBoxIfApplicable : function(iconClass,message,helpMessage,infoBoxClassName,actionButtonLabel,actionButtonIconClass){
            if(this.view){
                this.view.updateInfoBoxIfApplicable(iconClass,message,helpMessage,infoBoxClassName,actionButtonLabel,actionButtonIconClass);
            }
    },
    
    reload : function(params){
        if(this.view && this.view.load){
            return this.view.load(params);
        }else{
            return  RSVP.Promise.resolve();
        }
    },
    
    onSongRemovedFromTrebble : function(songModel){
        this.onActionExecutedOnSongHandler(songModel,"songRemovedFromTrebble");
    },
    
    cancelPendingImageLoading : function(){
        this.view.cancelPendingImageLoading();
    },
    
    
    grade : function(songModel){
        PersistentModels.getInstance().getRouter().showSongTagManagerPopup(songModel, this.context);
        
    },
    
    
    addToRadio : function(songModel){
        return PersistentModels.getInstance().addSongToDefaultRadio(songModel, this.context);
    },
    

    
    removeFromRadio : function(songModel){
        return PersistentModels.getInstance().removeSongFromDefaultRadio(songModel, this.context).then((function(){
            this.onActionExecutedOnSongHandler(songModel,"songRemovedFromTrebble");
        }).bind(this));
    },
    
     updateModels : function(newModels){
         this.view.updateModels(newModels);
    },

    updateModel : function(newModel, silent){
        this.view.updateModel(newModel,silent);
    },
    
    updateContext : function(newContext){
        this._modelContext=newContext;
        if(this.model && this._modelContext){
            this.model.updateContext(this._modelContext);
        }
    },

    isLoading :function(){
      return this.view.isLoading();
    },

    _onListFinishLoading : function(){
        this.trigger("finishLoading");
    },
    
    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                if(!this.model)
                {
                    this.model = new SongsCollection([]);
                    if(this._modelContext){
                        this.model.updateContext(this._modelContext);
                    }
                }
                this.model.sortByField(this._sortField, this._sortDescending);
                this.view = new SongListView({
                    "collection" : this.model,
                    "delayOnCollectionUpdate" : 1000,
                    "resizeToAvailableScreenSizeOnOrientationChange" : false,
                    "resizePlaceHolderElem" : this.resizePlaceHolderElem,
                    "loadHandler" : this._loadHandler,
                    "heightMargin": this._heightMargin,
                    "showEmtptyInfoBox" :this._showEmtptyInfoBox ,
                    "customInfoBoxInfoParams": this._customInfoBoxInfoParams,
                    "defaultHeightElement": this._defaultHeightElement,
                    "customListClass" : this._customListClass,
                    "onInfoButtonPressed": this._onInfoButtonPressed,
                    "songWithAudioPreviewOnly" : this._songWithAudioPreviewOnly,
                });
                this.listenTo(this.view, "itemTaped",this.clearQueueAndSongAndRestToQueue.bind(this));
                this.listenTo(this.view, "itemSwipedRight",this.addSongToQueue.bind(this));
                this.listenTo(this.view, "itemOptionBtnTapped", this.showMenuOption.bind(this));
                this.listenTo(this.view, "songRemovedFromTrebble", this.onSongRemovedFromTrebble.bind(this));
                this.listenTo(this.view, "addToRadioButtonTapped", this.addToRadio.bind(this));
                this.listenTo(this.view, "removeFromRadioButtonTapped", this.removeFromRadio.bind(this));
                this.listenTo(this.view, "gradeButtonTapped", this.grade.bind(this));
                this.listenTo(this.view, "finishLoading", this._onListFinishLoading.bind(this));
                this.view.render();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default SongListController;