import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import DynamicPageView from "views/common/DynamicPageView";
import Utils from "models/helper/Utils";
import ti18n from "i18n!nls/EditUserInfoi18n";
import PersistentModels from "services/PersistentModels";
import EditUserInfoPageTemplate from 'text!../../../templates/user/EditUserInfoPageTemplate.html';
import Mousetrap from 'mousetrap';

const BIO_MAX_LENGTH =  180;
const FORM_VALIDATION_DELAY_IN_MILLISEC = 500;
const EditUserInfoPageView = DynamicPageView.extend({

    //template : _.template(ExplorePageTemplate),

    initialize : function(options) {
        const dynamicPageOptions = {};
        this.model =  options? options.model: {};
        this._pageId = (options && options.pageId)? options.pageId:"EditUserInfoPage";
        dynamicPageOptions.pageViewTemplate = EditUserInfoPageTemplate;
        dynamicPageOptions.model = options.userInfoJson;
        dynamicPageOptions.pageId = this._pageId;
        dynamicPageOptions.headerFullscren = true;
        dynamicPageOptions.persistent = true;
        dynamicPageOptions.onPageShow = this.onPageShow.bind(this);
        dynamicPageOptions.pageTemplateOptions = {"ti18n": ti18n};
        //PersistentModels.getInstance().getRouter().registerForViewportHeightChange(this._pageId,this._onViewportHeightChanged.bind(this));
        return DynamicPageView.prototype.initialize.call(this, dynamicPageOptions);  
    },

    events : {
        "click #header-back-button" : "navigateToPreviousPage",
        "click #saveButtonIcon:not(.disabled)" : "saveChanges",
    },

    saveChanges : function(){
        this.trigger("saveChanges", this.getFormData());
        this.setSaveButtonEnabled(false);
    },

    isSaveButtonEnabled :function(){
        return this.saveButton$el.hasClass("disabled");
    },

    setUsernameValid : function(usernameValid,errorMessage){
        if (!usernameValid) {
            this.usernameFieldDecorator$el.find("label").attr("data-error",errorMessage);
            if(!this.usernameField$el.hasClass("invalid"))
            {
                this.usernameField$el.addClass("invalid");
            }
        } else {
            this.usernameField$el.removeClass("invalid");
            this.usernameFieldDecorator$el.find("label").attr("data-error","");
        }
    },

    setEmailValid : function(emailValid,errorMessage){
        if (!emailValid) {
            this.emailFielDecorator$el.find("label").attr("data-error",errorMessage);
            if(!this.emailField$el.hasClass("invalid"))
            {
                this.emailField$el.addClass("invalid");
            }
        } else {
            this.emailField$el.removeClass("invalid");
            this.emailFielDecorator$el.find("label").attr("data-error","");
        }
    },


    setFormBeingValidated : function(beingValidated){
        if(!beingValidated){
            this.$el.removeClass("validating");
        }else{
            if(!this.$el.hasClass("validating")){
                this.$el.addClass("validating");
            }
        }
    },



    getFormData: function(){
        const username  = this.$el.find("#usernameField").val();
        const email = this.$el.find("#emailField").val();
        const firstName = this.$el.find("#firstNameField").val();
        const lastName = this.$el.find("#lastNameField").val();
        const fullName = this.$el.find("#fullNameField").val();
        const bio = this.$el.find("#bioField").val();
        const website = this.$el.find("#websiteField").val();
        //const yearBirthday = this.$el.find("#yearBirthdayField").val();
        const data = {};
        data.username =  username;
        data.email =  email;
        //data.firstName =  firstName;
        //data.lastName =  lastName;
        data.fullName =  fullName;
        data.bio =  bio;
        data.website =  website;
        //data.yearOfBirth =  yearBirthday;
        return data;
    },


    /*onFieldValueChanged : function(event){
        if(this.isAllMandatoryFieldsFilled()){
            this.setSaveButtonEnabled(true);
        }else{
            this.setSaveButtonEnabled(false);
        }
    },*/
    /*
    isAllMandatoryFieldsFilled : function(){
        return this.validateUsernameField()
        //&& this.validateFirstNameField() && this.validateLastNameField()
        && this.validateFullNameField()
        && this.validateBioField()
        && this.validateWebsiteField()
        && this.validateEmailField();
    },*/

    /*
    validateUsernameField : function(){
        const usernameInput = this.usernameField$el.get(0);
        const username = usernameInput.value;
        const re = /^[a-z0-9_-]{3,20}$/;
        const isUsernameValid = re.test(username);
        if (!isUsernameValid) {
            const errorMessage = window.getI18n(ti18n, "USERNAME_VALIDATION_ERROR");
            this.usernameFieldDecorator$el.find("label").attr("data-error",errorMessage);
            if(!this.usernameField$el.hasClass("invalid"))
            {
                this.usernameField$el.addClass("invalid");
            }
        } else {
            // empty string means no validation error
            this.usernameField$el.removeClass("invalid");
            this.usernameFieldDecorator$el.find("label").attr("data-error","");
        }

        return isUsernameValid;
    },*/


    validateBioField : function(){
        const bioInput = this.bioField$el.get(0);
        const bio = bioInput.value;
        
        const isBioValid = !bio || bio.length <= BIO_MAX_LENGTH;
        if (!isBioValid) {
            const errorMessage = window.getI18n(ti18n, "YOUR_BIO_MUST_X_CHAR_OR_FEWER");
            this.bioFieldDecorator$el.find("label").attr("data-error",errorMessage);
            if(!this.bioField$el.hasClass("invalid"))
            {
                this.bioField$el.addClass("invalid");
            }
        } else {
            // empty string means no validation error
            this.bioField$el.removeClass("invalid");
            this.bioFieldDecorator$el.find("label").attr("data-error","");
        }

        return isBioValid;
    },

    validateWebsiteField : function(){
        const websiteInput = this.websiteField$el.get(0);
        const website = websiteInput.value;
        
        const isWebsiteValid = !website || Utils.getInstance().getUrlRegex({"exact": true, "strict": true}).test(website);
        if (!isWebsiteValid) {
            const errorMessage = window.getI18n(ti18n, "INVALID_WEBSITE_URL");
            this.websiteFieldDecorator$el.find("label").attr("data-error",errorMessage);
            if(!this.websiteField$el.hasClass("invalid"))
            {
                this.websiteField$el.addClass("invalid");
            }
        } else {
            // empty string means no validation error
            this.websiteField$el.removeClass("invalid");
            this.websiteFieldDecorator$el.find("label").attr("data-error","");
        }

        return isWebsiteValid;
    },

    validateFullNameField : function(){
        const fullNameInput = this.fullNameField$el.get(0);
        const fullName = fullNameInput.value;
        const re = /^[a-z ,.'-]+$/i;
        const isFullNameValid = re.test(fullName);
        if (!isFullNameValid) {
            const errorMessage = window.getI18n(ti18n, "FULL_NAME_IS_MANDATORY");
            this.fullNameFielDecorator$el.find("label").attr("data-error",errorMessage);
            if(!this.fullNameField$el.hasClass("invalid"))
            {
                this.fullNameField$el.addClass("invalid");
            }
        } else {
            // empty string means no validation error
            this.fullNameField$el.removeClass("invalid");
            this.fullNameFielDecorator$el.find("label").attr("data-error","");
        }

        return isFullNameValid;
    },



    validateFirstNameField : function(){
        const firstNameInput = this.firstNameField$el.get(0);
        const firstName = firstNameInput.value;
        const re = /^[a-z ,.'-]+$/i;
        const isFirstNameValid = re.test(firstName);
        if (!isFirstNameValid) {
            const errorMessage = window.getI18n(ti18n, "FIRST_NAME_IS_MANDATORY");
            this.firstNameFielDecorator$el.find("label").attr("data-error",errorMessage);
            if(!this.firstNameField$el.hasClass("invalid"))
            {
                this.firstNameField$el.addClass("invalid");
            }
        } else {
            // empty string means no validation error
            this.firstNameField$el.removeClass("invalid");
            this.firstNameFielDecorator$el.find("label").attr("data-error","");
        }

        return isFirstNameValid;
    },

    validateLastNameField : function(){
        const lastNameInput = this.lastNameField$el.get(0);
        const lastName = lastNameInput.value;
        const re = /^[a-z ,.'-]+$/i;
        const isLastNameValid = re.test(lastName);
        if (!isLastNameValid) {
            const errorMessage = window.getI18n(ti18n, "LAST_NAME_IS_MANDATORY");
            this.lastNameFielDecorator$el.find("label").attr("data-error",errorMessage);
            if(!this.lastNameField$el.hasClass("invalid"))
            {
                this.lastNameField$el.addClass("invalid");
            }
        } else {
            // empty string means no validation error
            this.lastNameField$el.removeClass("invalid");
            this.lastNameFielDecorator$el.find("label").attr("data-error","");
        }

        return isLastNameValid;
    },

    /*
    validateEmailField : function(){
        const emailInput = this.emailField$el.get(0);
        const emailValue = emailInput.value;
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const isEmailValid = re.test(emailValue);
        if (!isEmailValid) {
            const errorMessage = window.getI18n(ti18n, "INVALID_EMAIL");
            this.emailFielDecorator$el.find("label").attr("data-error",errorMessage);
            if(!this.emailField$el.hasClass("invalid"))
            {
                this.emailField$el.addClass("invalid");
            }
        } else {
            // empty string means no validation error
            this.emailField$el.removeClass("invalid");
            this.emailFielDecorator$el.find("label").attr("data-error","");
        }

        return isEmailValid;
    },*/




    navigateToPreviousPage : function(){
        PersistentModels.getInstance().getRouter().navigateToPreviousPage();
    },

    onBeforePageInitialized : function(){

    },

    setSaveButtonEnabled : function(enabled){
        if(enabled){
            this.saveButton$el.removeClass("disabled");
        }else{
            if(!this.saveButton$el.hasClass("disabled")){
                this.saveButton$el.addClass("disabled");
            }
        }
    },

    _onViewportHeightChanged : function(){
        const headerHeight = 54 ;
        this.$el.find(".ui-content").css("height",(window.getDocumentClienHeight() -headerHeight)+ "px");
    },

    /*
    validateEmail : function(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email? email.trim(): email);
    },*/

    /*
    validateUsername : function(username){
        const re = /^[a-z0-9_-]{3,20}$/;
        return re.test(username);
    },*/

    onUserNameFieldKeyUp :function(){
        this.usernameField$el.val( this.usernameField$el.val().replace(/ /g, "_").toLowerCase());
    },


    onDataChanged : function(){
        this.trigger("formChanged");
        if(this._validationTriggerFunctionTimeout){
            clearTimeout(this._validationTriggerFunctionTimeout);
        }
        this._validationTriggerFunctionTimeout = setTimeout((function(){
            this.trigger("validateForm");
        }).bind(this), FORM_VALIDATION_DELAY_IN_MILLISEC);
    },

    onAfterPageInitialized : function(){

        this.saveButton$el  = this.$el.find("#saveButtonIcon")
        this.usernameField$el = this.$el.find("#usernameField");
        this.usernameFieldDecorator$el = this.$el.find("#usernameFieldDec");
        this.firstNameField$el = this.$el.find("#firstNameField");
        this.firstNameFielDecorator$el = this.$el.find("#firstNameFieldDec");
        this.lastNameField$el = this.$el.find("#lastNameField");
        this.lastNameFielDecorator$el = this.$el.find("#lastNameFieldDec");
        this.fullNameField$el = this.$el.find("#fullNameField");
        this.fullNameFielDecorator$el = this.$el.find("#fullNameFieldDec");
        this.bioField$el = this.$el.find("#bioField");
        this.bioFieldDecorator$el = this.$el.find("#bioFieldDecorator");
        this.websiteField$el = this.$el.find("#websiteField");
        this.websiteFieldDecorator$el = this.$el.find("#websiteFieldDec");

        this.emailField$el = this.$el.find("#emailField");
        this.emailFielDecorator$el = this.$el.find("#emailFieldDec");
        //this.$el.find("#updateUserInfoForm").on("click keyup input", this.onFieldValueChanged.bind(this));
        this.$el.find("#usernameField").on("click keyup input", this.onUserNameFieldKeyUp.bind(this));
        this.$el.find(".formField").on("keyup input", this.onDataChanged.bind(this));
        


        this.onDataChanged();
        this.setSaveButtonEnabled(false);
        this.$el.on("pagehide", this._onPageHide.bind(this));
        this._mousetrapObj = Mousetrap(this.$el.find("#updateUserInfoForm").get(0)).bind('enter', (function(e, combo) {
            if(this.isSaveButtonEnabled()){
                this.saveChanges();
            }
        }).bind(this));
        //this.currentPasswordField$el.get(0).validate = this.validateCurrentPasswordField.bind(this);
        //this.newPassswordField$el.get(0).validate = this.validateReenterPasswordField.bind(this);
        //this.reenterNewPassswordField$el.get(0).validate = this.validatePasswordField.bind(this);


    },

    onPageShow : function(){
        this.trigger("pageShow");
    },
    onBeforeRemoveFromPage : function(){
        DynamicPageView.prototype.onBeforeRemoveFromPage.call(this); 
        PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._pageId);
    },

});
export default EditUserInfoPageView;