import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Swiper from 'swiper';

//  "swiper",
import Utils from "models/helper/Utils";

import ti18n from "i18n!nls/Commoni18n";
import InfoBoxView from "views/common/InfoBoxView";
import RSVP from "rsvp";
const COLLECTION_CONTAINER_ID = "collection_carousel_inifinite_container";
const SWIPER_CONTAINER_ID = "swiper_carousel_inifinite_container";

const CollectionInfiniteCarouselView = Backbone.View.extend({



    initialize : function(options) {
        this._infoBoxInfoParams  = options.customInfoBoxInfoParams? options.customInfoBoxInfoParams: this._buildDefaultCustomBoxParams(); 
        this._showEmtptyInfoBox = (options.showEmtptyInfoBox == false)? false: true;
        this._vertical = !!options.vertical;
        this._customInfoBoxInfo  = options.customInfoBoxInfo? options.customInfoBoxInfo: null;
        this._itemWidth = options.itemWidth;
        this._itemWidthUnit = options.itemWidthUnit;
        this._itemHeight = options.itemHeight;
        this._itemHeightUnit = options.itemHeightUnit;
        this._initMaxNumberOfItemsToLoad =  options.initMaxNumberOfItemsToLoad;
        this._swiperContainerId =  SWIPER_CONTAINER_ID + (new Date()).getTime();
        this._collectionContainerId =  COLLECTION_CONTAINER_ID + (new Date()).getTime();
        this._isUpdating = false;
        this._initInfoBoxIfApplicable();
        const header = 50;
        const footer = 50;
        this._swiperHeight = window.getDocumentClienHeight() - header - footer;
        
        this._spaceBetweenSlides = this._vertical?10: (window.isMobileBrowser?10:50);
        const margin = 40;
        this._numberOfVisibleItems = this._vertical? 3: 1;
        if(!this._itemWidth){

            this._itemWidth = this._vertical? 100 : window.getDocumentClienWidth() - margin - (this._spaceBetweenSlides *2) ;
            this._itemWidthUnit = this._vertical? "%" : "px";
        }
        if(!this._itemHeight){
            this._itemHeight = !this._vertical? 100 : this._swiperHeight - margin - (this._spaceBetweenSlides *2) ;
            this._itemHeightUnit = !this._vertical? "%" : "px";
        }

        
        
        this._swiperObj = null;
        this._bufferSlideLeft = 2;
        this._bufferSlideRight = 2;
        this.swipeDirection  = 0;
        this._firstItemIndex = 0;
        this._lastItemIndex = 0;
        this.event_pageX = 0;
        this.event_pageY = 0;
        this._currentActiveIndex =0;
    },
    
    _buildDefaultCustomBoxParams : function(){
        const params = {};
        params.iconClass = "ion-ios7-musical-notes";
        params.message = window.getI18n(ti18n, "NO_SONG_FOUND");
        params.helpMessage = window.getI18n(ti18n, "YOU_CAN_PLAY_THIS_SONG_IN_SHUFFLE_IF_YOU_ADD_IT_TO_YOUR_LIBRARY");
        return params;
    },

    _onRootElementResize : function(){
        if(this._emptyInfoBox && this._emptyInfoBox.$el){
            const root$el = this.collectionContainer$el;
            const rootElInnerHeight =root$el.innerHeight() ;
            if( rootElInnerHeight != 0){
                this._emptyInfoBox.$el.css("height",rootElInnerHeight + "px");
            }
        }


    },


    _initInfoBoxIfApplicable : function(){
        if(this._showEmtptyInfoBox ){
            if(!this._customInfoBoxInfo){
                this._emptyInfoBox =  new InfoBoxView(this._infoBoxInfoParams);
                this._emptyInfoBox.render();
                this.el.appendChild(this._emptyInfoBox.el);
            }else{
                this._emptyInfoBox = this._customInfoBoxInfo;
            }
            this.listenTo(this._emptyInfoBox, "actionButtonPressed",this._onDefaultInfoboxButtonCalled.bind(this));
        }

    },


    setUpdating : function(updating){
        this._isUpdating = updating;
        if(this._showEmtptyInfoBox && this._emptyInfoBox){
            if(this._isUpdating){
                this._emptyInfoBox.showLoading();
                this._emptyInfoBox.show();
                this.collectionContainer$el.hide();
            }else{
                this._emptyInfoBox.hideLoading();
                this._displayInfoBoxIfApplicable();
                this.collectionContainer$el.show();
            }
        }
    },

    setErrorMessage : function(errorMessage, helpErrorMessage, errorActionButtonLabel){
        if(this._showEmtptyInfoBox && this._emptyInfoBox){
            if(errorMessage){
                this._emptyInfoBox.showErrorMessage(errorMessage, helpErrorMessage, errorActionButtonLabel);
                this._emptyInfoBox.show();
                this.collectionContainer$el.hide();
            }else{
                this._emptyInfoBox.hideErrorMessage();
                this._displayInfoBoxIfApplicable();
                this.collectionContainer$el.show();
            }
        }
    },

    updateInfoBoxIfApplicable : function(iconClass,message,helpMessage,infoBoxClassName,actionButtonLabel,actionButtonIconClass){
        if(this._showEmtptyInfoBox && this._emptyInfoBox){
            this._emptyInfoBox.update(iconClass,message,helpMessage,infoBoxClassName,actionButtonLabel,actionButtonIconClass);
            this._displayInfoBoxIfApplicable();
        }
    },

    _displayInfoBoxIfApplicable : function(){
        if(this._showEmtptyInfoBox && this._emptyInfoBox){
            if(this._swiperObj && this._swiperObj.slides &&  this._swiperObj.slides.length > 0){
                this._emptyInfoBox.hide();
                this.collectionContainer$el.show();
            }else{
                this.collectionContainer$el.hide();
                this._emptyInfoBox.show();
            }
        }

    },



    focusOnItem : function(viewItem){
        if(viewItem){
            viewItem.$el.get(0).scrollIntoView({block: "start", behavior: "smooth"});
            
            viewItem.$el.addClass("hightlightAnim").one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend',  (function() {
                this.viewItem.$el.removeClass('hightlightAnim');
            }).bind({"viewItem": viewItem}));
        }
        this._displayInfoBoxIfApplicable();
    },




    _onDefaultInfoboxButtonCalled : function(){
        this.trigger("infoBoxButtonPressed");
    },

    initSwiperContainer : function(){
        const swiperParams  =  {
            speed: window.isMobileBrowser?200: 300,
            longSwipesRatio : window.isMobileBrowser?0.2: 0.4,
            spaceBetween: this._spaceBetweenSlides,
            height : this._swiperHeight,
            watchSlidesProgress: true,
            preloadImages: false,
            watchSlidesVisibility: true,
            direction : this._vertical?"vertical":"horizontal",
            //slidesPerView :this._numberOfVisibleItems,
            slidesPerView: 'auto',
            centeredSlides: true,
            slideToClickedSlide: true,
            
            lazyLoading: true,
            lazyLoadingInPrevNext: true,
            lazy: {
                loadPrevNext: true
            },
            centerInsufficientSlides: true,
            //pagination: this._vertical? this.swiperContainer$el.find('.swiper-pagination').get(0): null,
            // onTouchMove : this._onTouchMove.bind(this),
            // onTouchEnd : this._onTouchEnd.bind(this),
            onInit : this._onSwiperInit.bind(this),
            onSlideChangeEnd : this._onSlideChangeEnd.bind(this),
            onTransitionEnd: this._onSlideChangeEnd.bind(this),
            on : {
                "init": this._onSwiperInit.bind(this),
                "transitionEnd": this._onSlideChangeEnd.bind(this),
                "slideChangeTransitionEnd": this._onSlideChangeEnd.bind(this),
            }

        };
        swiperParams.nextButton = '.swiper-button-next';
        swiperParams.prevButton = '.swiper-button-prev';
        swiperParams.navigation = {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        };
        if(window.device && window.device.platform =="browser" && !window.isMobileBrowser){
            
            swiperParams.keyboardControl = true;
            swiperParams.mousewheelControl = true;
            swiperParams.mousewheel =  {
                enabled: true
            };
            swiperParams.keyboard =  {
                enabled: true,
                onlyInViewport: false
            };

        }
        this._swiperObj = new Swiper(this.swiperContainer$el.get(0),swiperParams);   
        //this.trigger("slideChangeEnd", this._swiperObj.activeIndex);
    },

    appendItems : function(views, numberOfItemsNeeded, offset){
        const viewElements = [];
        if(views && views.length > 0){
            for(let index in views){
                const view = views[index];
                const slide$el = this._buildSlideWrapper();
                slide$el.append(view.el);
                viewElements.push(slide$el.get(0));
            }
        }
        if(viewElements.length > 0){
            this._swiperObj.appendSlide(viewElements);
            this._lastItemIndex = this._lastItemIndex + viewElements.length;
        }
        this._lastItemIndexLoadingFoward = null;
        this._deleteItemBackward();
        this._displayInfoBoxIfApplicable();
    },

    _buildSlideWrapper : function(){
        const slideWrapper$el =  $("<div class='slideItemWrapper swiper-slide' style='width:"+ this._itemWidth+""+this._itemWidthUnit+";height:"+ this._itemHeight+""+this._itemHeightUnit+";'></div>");
        return slideWrapper$el;
    },

    prependItems : function(views, numberOfItemsNeeded, offset){
        const viewElements = [];
        if(views && views.length > 0){
            for(let index in views){
                const view = views[index];
                const slide$el = this._buildSlideWrapper();
                slide$el.append(view.el);
                viewElements.push(slide$el.get(0));
            }
        }
        if(viewElements.length > 0){
            this._swiperObj.prependSlide(viewElements);
            this._lastItemIndex = this._firstItemIndex - viewElements.length;
        }
        this._firstItemIndexLoadingFoward = null;
        this._deleteItemFoward();
        this._displayInfoBoxIfApplicable();
    },

    _deleteItemFoward : function(){
        const numberOfSlideInvisibleAfterActiveSlide = this.getNumberOfSlideInvisibleBeforeActiveSlide();
        if(this._swiperObj.slides.length > 0 &&  this._swiperObj.activeIndex > -1){
            if(numberOfSlideInvisibleAfterActiveSlide > 2){
                for(let index = this._swiperObj.slides.length - numberOfSlideInvisibleAfterActiveSlide + 2; index <  this._swiperObj.slides.length; index = index + 1){
                    this._swiperObj.removeSlide(index);
                    this._lastItemIndex = this._lastItemIndex -1;
                }
            }

        }
    },

    _deleteItemBackward : function(){
        const numberOfSlideInvisibleBeforeActiveSlide = this.getNumberOfSlideInvisibleBeforeActiveSlide();
        if(this._swiperObj.slides.length > 0 &&  this._swiperObj.activeIndex > -1){
            if(numberOfSlideInvisibleBeforeActiveSlide > 2){
                for(let index =  numberOfSlideInvisibleBeforeActiveSlide - 2; index >= 0 ; index = index - 1){
                    this._swiperObj.removeSlide(index);
                    this._firstItemIndex = this._firstItemIndex -1;
                }
            }

        }
    },
    getNumberOfSlideInvisibleAfterActiveSlide : function(){
        let numberOfSlideInvisibleAfterActiveSlide = 0;
        if(this._swiperObj.slides.length >0){
            for(let index = this._swiperObj.activeIndex ; index <  this._swiperObj.slides.length; index = index + 1){
                if(!this._swiperObj.slides[index].className.indexOf( this._swiperObj.slideVisibleClass) != -1){
                    numberOfSlideInvisibleAfterActiveSlide = numberOfSlideInvisibleAfterActiveSlide +1;
                }
            }
        }
        return numberOfSlideInvisibleAfterActiveSlide;
    },

    getNumberOfSlideInvisibleBeforeActiveSlide : function(){
        let numberOfSlideInvisibleBeforeActiveSlide = 0;
        if(this._swiperObj.slides.length >0){
            for(let index = this._swiperObj.activeIndex ; index >= 0; index = index - 1){
                if(!this._swiperObj.slides[index].className.indexOf( this._swiperObj.slideVisibleClass) != -1){
                    numberOfSlideInvisibleBeforeActiveSlide = numberOfSlideInvisibleBeforeActiveSlide +1;
                }
            }
        }
        return numberOfSlideInvisibleBeforeActiveSlide;
    },

    _onSlideChangeEnd : function(swiper, event){
        if(this._swiperObj){
            /*	if(this.swipeDirection == 1){
                this._loadFoward(1);
            }else{
                if(this.swipeDirection == -1){
                    this._loadBackward(1);
                }
            }*/
            if(this._swiperObj.activeIndex != this._currentActiveIndex){
                this._currentActiveIndex = this._swiperObj.activeIndex;
                this.trigger("slideChangeEnd", this._swiperObj.activeIndex);
            }
        }

    },

    _loadFoward : function(numberOfItemLoaded){
        if(!this._lastItemIndexLoadingFoward ){
            this._lastItemIndexLoadingFoward = this._lastItemIndex + numberOfItemLoaded;
            this.trigger("viewItemNeededFoward", numberOfItemLoaded, this._lastItemIndex);
        }
    },

    _loadBackward : function(numberOfItemLoaded){
        if(!this._firstItemIndexLoadingFoward && this._firstItemIndex != 0 ){
            this._firstItemIndexLoadingFoward = this._firstItemIndex - numberOfItemLoaded;
            this.trigger("viewItemNeededFoward", numberOfItemLoaded, this._firstItemIndex - numberOfItemLoaded);
        }
    },

    _onSwiperInit : function(swiper, event){
        this._loadFoward(this._initMaxNumberOfItemsToLoad? this._initMaxNumberOfItemsToLoad :100);
    },

    _onTouchMove : function(swiper, event){
        //this.event_pageX = event.pageX;
        //this.event_pageY = event.pageY;
    },

    _onTouchEnd : function(swiper, event){
        if(this._currentActiveIndex != swiper.activeIndex){
            if(this._currentActiveIndex < swiper.activeIndex){
                this.swipeDirection =  1;
            }else{
                this.swipeDirection =  -1;
            }
            this._currentActiveIndex = swiper.activeIndex;
        }else{
            this.swipeDirection = 0;
        }

    },



    destroy : function(){
        if(!this._destroyed){
            this._swiperObj?this._swiperObj.destroy():null;
            this.undelegateEvents();
            this.$el.removeData().unbind(); 
            this.remove();  
            Backbone.View.prototype.remove.call(this);
            this._destroyed = true;
        }
    },
    _buildNavigationButtons : function(){

        if(this._vertical){
            return      '<div class="swiper-pagination"></div><div class="swiper-button-next ion-chevron-down"></div> <div class="swiper-button-prev ion-chevron-up"></div>';
        }else{
            return      '<div class="swiper-button-next ion-ios7-arrow-right"></div> <div class="swiper-button-prev ion-ios7-arrow-left"></div>';
        }
        
    },

    getCollectionContainer$el : function(){
        return this.collectionContainer$el;
    },

    updateSwiperWidget : function(){
        if(this._swiperObj){
            this._swiperObj.update();
        }
    },

    render : function() {
        const templateParams = {};
        this.$el.append("<div  id='"+this._collectionContainerId+"'><div id='"+this._swiperContainerId+"'><div class='"+ "swiper-wrapper" +( this._vertical ?" vertical ": "") +"'> </div>"+ this._buildNavigationButtons()+"</div></div>");
        this.collectionContainer$el = this.$el.find("#"+this._collectionContainerId);
        this.swiperContainer$el = this.$el.find("#"+this._swiperContainerId);
        this._displayInfoBoxIfApplicable();
        return this;
    }

});
export default CollectionInfiniteCarouselView;