import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Song from "models/Song";
import TrebbleSongListView from "views/common/TrebbleSongListView2";
import Songs from "collections/myLibrary/SongsCollection";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";

Songs.prototype.model = PersistentModels.getInstance().getSongContructorFunctionForCollection();
const SongListView = Backbone.View.extend({
    
    
    
    
    
    initialize: function(options) {
        this.displayedCollection = this.collection;
        this.collection.bind("add", this._onSongAddedOrRemoved, this);
        this.collection.bind("change", this._onSongUpdated, this);
        this.collection.bind("remove", this._onSongAddedOrRemoved, this);
        this.collection.bind("reset", this._onSongAddedOrRemoved, this);
        this._delayOnCollectionUpdate = (options && options.delayOnCollectionUpdate) ? options.delayOnCollectionUpdate :  1000;
        this._resizeToAvailableScreenSizeOnOrientationChange = options  ? options.resizeToAvailableScreenSizeOnOrientationChange :  true;
        this._showHeader = options  ? options.showHeader :  false;
        this._headerText= options  ? options.headerText :  "";
        this._showFooter= options  ? options.showFooter :  false;
        this._footerText= options  ? options.footerText :  "";
        this._container$elForImageLazyLoad = options? options.container$elForImageLazyLoad: null;
        this._customInfoBoxInfoParams = options ? options.customInfoBoxInfoParams : null;
        this._loadHandler= (options && options.loadHandler)  ? options.loadHandler :  null;
        this._heightMargin= (options && options.heightMargin)  ? options.heightMargin :  null;
        this._showEmtptyInfoBox = (options && options.showEmtptyInfoBox)  ? options.showEmtptyInfoBox :  null;
        this._resizePlaceHolderElem =  options  ? options.resizePlaceHolderElem :  false;
        this._doNotSortDisplayedCollection =  options  ? options.doNotSortDisplayedCollection :  true;
        this._doNotShowLoadingWidgetDuringListLoading = options  ? options.doNotShowLoadingWidgetDuringListLoading :  false;
        this._listenerViewportKey = "songListView" + (new Date()).getTime();
        this._doNotInitializeLazyLoadingAtRender = options? options.doNotInitializeLazyLoadingAtRender: false;
        this._actionItemButtonClass = options? options.actionItemButtonClass : null;
        this._doNotShowSongCoverImage = options? options.doNotShowSongCoverImage : null;
        this._onPostTemplateDataCreated = options.onPostTemplateDataCreated;
        this._defaultHeightElement = (options && options.defaultHeightElement)? options.defaultHeightElement: 60;
        this._customListClass = (options && options.customListClass)? options.customListClass: "";
        this._songWithAudioPreviewOnly = (options && options.songWithAudioPreviewOnly)? options.songWithAudioPreviewOnly: false;
        this._onPostItemRendered = options.onPostItemRendered;

        this._doNotShowAddToRadioButton = options.doNotShowAddToRadioButton;
        this._doNotShowGradeSongButton = options.doNotShowGradeSongButton;
if (typeof this._doNotShowAddToRadioButton === "undefined"){
  this._doNotShowAddToRadioButton = false; //(window.device && window.device.platform == "browser" && !window.isMobileBrowser)?false: true;
}
if (typeof this._doNotShowGradeSongButton === "undefined"){
  this._doNotShowGradeSongButton = (window.device && window.device.platform == "browser" && !window.isMobileBrowser)?false: true;
}
        PersistentModels.getInstance().getRouter().registerForViewportHeightChange(this._listenerViewportKey,this._onOrientationChangeFunction.bind(this));
        
    },
    
_onOrientationChangeFunction : function(newViewPortHeigh, forceResizing) {
    if(this.$el.is(":visible") || forceResizing){
        if(this._heightMargin){
            newViewPortHeigh = newViewPortHeigh - this._heightMargin;
        }
       if (!this._resizePlaceHolderElem && this.$el) {
        this.$el.css("height" , (newViewPortHeigh ) + "px"); 
       }
       this.infiniteListView._onRootElementResize();
    }
},


filterList: function(searchCharaterers, resultSize, customFilter, customSort) {
 if(!this._removeFromDom){
    this.appliedSearchCharacters = searchCharaterers;
    this._resultSize = resultSize;
    this._customFilter = customFilter;
    this._customSort = customSort;
    const searchFunction = (function() {
        //$.mobile.loading("show");
        this.infiniteListView.setUpdating(true);
        const r  = this.collection.searchOnTitleAndArtist(this.appliedSearchCharacters, resultSize, customFilter, this._songWithAudioPreviewOnly);
        if(r instanceof RSVP.Promise){
            r.then((function(arrayOfResult){
                const songCollection = new Songs();
                songCollection.sortByField(customSort);
                songCollection.add(arrayOfResult);
                songCollection.updateContext(this.displayedCollection.getContext());
                this._changeSongList(songCollection);
                this.pendingSearchFunctionTimerId = null;
                //$.mobile.loading("hide");
            }).bind(this)).catch((function(error){
                 //$.mobile.loading("hide");
                window.alertErrorMessage(error);
            }).bind(this))
        }else{
            const songCollection = new Songs();
            songCollection.sortByField(customSort);
            songCollection.add(r);
            songCollection.updateContext(this.displayedCollection.getContext());
            this._changeSongList(songCollection);
            this.pendingSearchFunctionTimerId = null;
            //$.mobile.loading("hide");
        }
    }).bind(this);
    if (this.pendingSearchFunctionTimerId) 
    {
        window.clearTimeout(this.pendingSearchFunctionTimerId);
    }
    this.pendingSearchFunctionTimerId = window.setTimeout(searchFunction,  this._delayOnCollectionUpdate);
}
},

clearSearchfilter: function(resultSize, customFilter,customSort) {
if(!this._removeFromDom){
    this.appliedSearchCharacters = null;
    this._resultSize = resultSize;
    this._customFilter = customFilter;
    this._customSort = customSort;
    const searchFunction = (function() {
        this.infiniteListView.setUpdating(true);
        const songCollection = new Songs(this.collection.searchOnTitleAndArtist("",resultSize, customFilter));
        songCollection.updateContext(this.displayedCollection.getContext());
        this._changeSongList(songCollection);
        this.pendingSearchFunctionTimerId = null;
    }).bind(this);
    if (this.pendingSearchFunctionTimerId) 
    {
        window.clearTimeout(this.pendingSearchFunctionTimerId);
    }
    this.pendingSearchFunctionTimerId = window.setTimeout(searchFunction, 0);
}

},


_changeSongList: function(newSongModels) {
if(!this._removeFromDom){
    this.displayedCollection = newSongModels;
    if(!this._doNotSortDisplayedCollection)
    {
        this.displayedCollection.sort();
    }
    this.infiniteListView.updateModels(this.displayedCollection.toJSON());
    this.infiniteListView.setUpdating(false);
}
},

_updateSongInSongList: function(songUpdateModel) {
if(!this._removeFromDom){
    this.infiniteListView.updateModel(songUpdateModel.toJSON());
    this.infiniteListView.setUpdating(false);
}
},

_getOnListItemTapHandler: function() {
return (function(songJson, evnt, item) {
    this.trigger("songInfoTapped", this._getSongModelFromSongJson(songJson) ,this.displayedCollection );
}).bind(this);
},

_getOnItemActionButtonTappedHanlder: function() {
return (function(songJson, evnt, item) {
   this.trigger("songActionButtonTapped", this._getSongModelFromSongJson(songJson));

}).bind(this);
},

_getOnCapsuleActionButtonTappedHanlder: function() {
return (function(songJson, evnt, item) {
   this.trigger("capsuleActionButtonTapped", this._getSongModelFromSongJson(songJson));

}).bind(this);
},

_getOnAddRemoveSongToTrebbleButtonTappedHanlder: function() {
return (function(songJson, evnt, item) {
    if(evnt.currentTarget.className.indexOf("ion-ios7-plus-outline") != -1){
        this.trigger("addToRadioButtonTapped", this._getSongModelFromSongJson(songJson));
    }else{
        this.trigger("removeFromRadioButtonTapped", this._getSongModelFromSongJson(songJson));
    }
}).bind(this);
},


_getOnTagSongButtonTappedHanlder: function() {
return (function(songJson, evnt, item) {
   this.trigger("gradeButtonTapped", this._getSongModelFromSongJson(songJson));

}).bind(this);
},


_getSongModelFromSongJson : function(songJon){
return this.collection.get(songJon.uri);

},


isSearchApplied : function(){
return this.appliedSearchCharacters != null;
},

getAppliedSearchCharacters : function(){
return this.appliedSearchCharacters;
}, 

_onSongAddedOrRemoved : function(){
if(!this._removeFromDom){
if(this.isSearchApplied())
{
  this.filterList(this.getAppliedSearchCharacters(), this._resultSize,this._customFilter,this._customSort);
}else{
  this.infiniteListView.setUpdating(true);
  this._changeSongList(this.collection);
}
this.trigger("modelChange", this.collection);
}
},

updateModels : function(newModels){
this.infiniteListView.updateModels(newModels);
},

updateModel : function(newModel, silent){
this.infiniteListView.updateModel(newModel,silent);
},

_onSongUpdated : function(songUpdateModel){
if(!this._removeFromDom){
this.infiniteListView.updateModel(songUpdateModel?songUpdateModel.toJSON():null);
this.trigger("modelChange", this.displayedCollection);
}
},


isCollectionEmpty : function(){
if(this.collection)
{
return this.collection.length == 0;
}else{
return true;
}
},

_onContentLoading : function(){
if(!this._doNotShowLoadingWidgetDuringListLoading){
$.mobile.loading( 'show');
}
},

_onContentLoaded : function(){
if(!this._doNotShowLoadingWidgetDuringListLoading){
$.mobile.loading( "hide" );
}
},

_onContentFailedLoading : function(error){
if(!this._doNotShowLoadingWidgetDuringListLoading){
$.mobile.loading( "hide" );
console.error("Page content failed loading. Error:"+error);
}
},

load : function(params){
if(!this._removeFromDom){
if(this._loadHandler){
  this._onContentLoading();
  try{
     this.infiniteListView.setUpdating(true);
     return this._loadHandler(params).then((function(arrayOfSongs){
         if(!this._removeFromDom){
            this.collection.reset(arrayOfSongs);
            this.infiniteListView.setUpdating(false);
        }
        this._onContentLoaded();
    }).bind(this)).catch((function(error){
        this._onContentFailedLoading(error);
        this.infiniteListView.setErrorMessage(error);
    }).bind(this));
}catch(error){
 this._onContentFailedLoading(error);
 this.infiniteListView.setErrorMessage(error);
}
}else{
this._onSongAddedOrRemoved();
return RSVP.Promise.resolve();
}
}
},

updateInfoBoxIfApplicable : function(iconClass,message,helpMessage,infoBoxClassName,actionButtonLabel,actionButtonIconClass){
if(this.infiniteListView){
this.infiniteListView.updateInfoBoxIfApplicable(iconClass,message,helpMessage,infoBoxClassName,actionButtonLabel,actionButtonIconClass);
}
},

_onNodeRemovedFromDom : function(){
this._removeFromDom  = true;
PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._listenerViewportKey);
},

/*onSongRemovedFromTrebble : function(songModel){
this.trigger("songRemovedFromTrebble",songModel);
},

onActionExecutedOnSongHandler : function( songModel, action){
this.trigger("actionExecutedOnSongHandler",songModel, action);
},*/

buildItemEventHandler : function(){
const handlers = {};
handlers["click div.songOptionBtnWrapper > #actionButton"] = this._getOnItemActionButtonTappedHanlder();
handlers["click div.capsuleBtnWrapper > #songCapsuleOptionButton"] = this._getOnCapsuleActionButtonTappedHanlder();
if(!this._doNotShowAddToRadioButton){
handlers["click div.addToTrebbleBtnWrapper > #addToTrebbleOptionButtuon"] = this._getOnAddRemoveSongToTrebbleButtonTappedHanlder();
}
//	handlers["click div.addToTrebbleBtnWrapper > #addToTrebbleOptionButtuon.ion-ios7-checkmark-outline"] = this._getOnRemoveSongToTrebbleButtonTappedHanlder();
if(!this._doNotShowGradeSongButton){
handlers["click div.gradesBtnWrapper > #gradeOptionButtuon"] = this._getOnTagSongButtonTappedHanlder();
}
handlers["click .songInfo"] = this._getOnListItemTapHandler();
return handlers;
},

initializeImageLazyLoadPlugin : function(container$elForImageLazyLoad){
this.infiniteListView.initializeImageLazyLoadPlugin(container$elForImageLazyLoad);
},

cancelPendingImageLoading : function(){
this.infiniteListView.cancelPendingImageLoading();
},


render: function() {

let newViewPortHeigh = PersistentModels.getInstance().getRouter().getViewportHeight();
if(this._heightMargin){
newViewPortHeigh = newViewPortHeigh - this._heightMargin;
}
this.$el.html(' <div id="maliste"  style="height:' +(newViewPortHeigh + "px") + ';overflow:hidden;width:100%" class="'+ this._customListClass+'"></div>');
this.setElement(this.$el.find("div").first());
const listParams = {};
if(!this._doNotSortDisplayedCollection)
{
this.displayedCollection.sort();
}
listParams.model = this.displayedCollection.toJSON();
listParams.collection = this.displayedCollection;
listParams.listRootElement = this.el;
listParams.defaultNumberOfElements = 80;
listParams.defaultHeightElement = this._defaultHeightElement;
listParams.placeHolderHeight =  PersistentModels.getInstance().getRouter().getViewportHeight();
listParams.resizePlaceHolderElem =  this._resizePlaceHolderElem;
listParams.showEmtptyInfoBox = this._showEmtptyInfoBox;
listParams.customInfoBoxParams = this._customInfoBoxInfoParams;
listParams.itemEventHandlers = this.buildItemEventHandler();
listParams.container$elForImageLazyLoad = this._container$elForImageLazyLoad;
listParams.doNotInitializeLazyLoadingAtRender = this._doNotInitializeLazyLoadingAtRender;
listParams.actionItemButtonClass = this._actionItemButtonClass;
listParams.doNotShowSongCoverImage = this._doNotShowSongCoverImage;
listParams.onPostTemplateDataCreated = this._onPostTemplateDataCreated;
listParams.onPostItemRendered = this._onPostItemRendered;
listParams.doNotShowAddToRadioButton = this._doNotShowAddToRadioButton;
listParams.doNotShowGradeSongButton = this._doNotShowGradeSongButton;
listParams.doNotShowAddToRadioButton =    this._doNotShowAddToRadioButton;
listParams.doNotShowGradeSongButton = this._doNotShowGradeSongButton;
const infiniteListView = new TrebbleSongListView(listParams);
this.infiniteListView = infiniteListView;
    

    infiniteListView.render();
    this._onOrientationChangeFunction(PersistentModels.getInstance().getRouter().getViewportHeight(), true);
    this.$el.on("remove",this._onNodeRemovedFromDom.bind(this));
//this.listenTo(this.infiniteListView, "songRemovedFromTrebble",this.onSongRemovedFromTrebble.bind(this));
//this.listenTo(this.infiniteListView, "actionExecutedOnSongHandler",this.onActionExecutedOnSongHandler.bind(this));
    return this;
}

});

export default SongListView;
