import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Artist from "models/Artist";
import TrebbleArtistListView from "views/common/TrebbleArtistListView";
import Artists from "collections/myLibrary/ArtistsTabContentCollection";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";

const ArtistListView = Backbone.View.extend({
    
    
    
    
    
    initialize: function(options) {
        this.displayedCollection = this.collection;
        this.collection.bind("add", this._onArtistAddedOrRemoved, this);
        this.collection.bind("change", this._onArtistUpdated, this);
        this.collection.bind("remove", this._onArtistAddedOrRemoved, this);
        this.collection.bind("reset", this._onArtistAddedOrRemoved, this);
        this._delayOnCollectionUpdate = (options && options.delayOnCollectionUpdate) ? options.delayOnCollectionUpdate :  1000;
        this._resizeToAvailableScreenSizeOnOrientationChange = options  ? options.resizeToAvailableScreenSizeOnOrientationChange :  true;
        this._showHeader = options  ? options.showHeader :  false;
        this._headerText= options  ? options.headerText :  "";
        this._showFooter= options  ? options.showFooter :  false;
        this._footerText= options  ? options.footerText :  "";
        this._container$elForImageLazyLoad = options? options.container$elForImageLazyLoad: null;
        this._customInfoBoxInfoParams = options ? options.customInfoBoxInfoParams : null;
        this._loadHandler= (options && options.loadHandler)  ? options.loadHandler :  null;
        this._heightMargin= (options && options.heightMargin)  ? options.heightMargin :  null;
        this._showEmtptyInfoBox = (options && options.showEmtptyInfoBox)  ? options.showEmtptyInfoBox :  null;
        this._resizePlaceHolderElem =  options  ? options.resizePlaceHolderElem :  false;
        this._doNotSortDisplayedCollection =  options  ? options.doNotSortDisplayedCollection :  true;
        this._doNotShowLoadingWidgetDuringListLoading = options  ? options.doNotShowLoadingWidgetDuringListLoading :  false;
        this._listenerViewportKey = "artistListView" + (new Date()).getTime();
        this._doNotInitializeLazyLoadingAtRender = options? options.doNotInitializeLazyLoadingAtRender: false;
        PersistentModels.getInstance().getRouter().registerForViewportHeightChange(this._listenerViewportKey,this._onOrientationChangeFunction.bind(this));
        
    },
    
_onOrientationChangeFunction : function(newViewPortHeigh, forceResizing) {
    if(this.$el.is(":visible") || forceResizing){
        if(this._heightMargin){
            newViewPortHeigh = newViewPortHeigh - this._heightMargin;
        }
       if (!this._resizePlaceHolderElem && this.$el) {
        this.$el.css("height" , (newViewPortHeigh ) + "px"); 
       }
       this.infiniteListView._onRootElementResize();
    }
},


filterList: function(searchCharaterers) {
 if(!this._removeFromDom){
    this.appliedSearchCharacters = searchCharaterers;
    const searchFunction = (function() {
        $.mobile.loading("show");
        this.infiniteListView.setUpdating(true);
        const r = this.collection.searchOnArtistname(this.appliedSearchCharacters);
        if(r instanceof RSVP.Promise){
            r.then((function(arrayOfResults){
                const artistCollection = new Artists(arrayOfResults);
                artistCollection.updateContext(this.displayedCollection.getContext());
                this._changeArtistList(artistCollection);
                this.pendingSearchFunctionTimerId = null;
                $.mobile.loading("hide");
            }).bind(this)).catch((function(error){
                $.mobile.loading("hide");
                window.alertErrorMessage(error);
            }).bind(this));
        }else{
            const artistCollection = new Artists(r);
            artistCollection.updateContext(this.displayedCollection.getContext());
            this._changeArtistList(artistCollection);
            this.pendingSearchFunctionTimerId = null;
            $.mobile.loading("hide");
        }
    
    }).bind(this);
    if (this.pendingSearchFunctionTimerId) 
    {
        window.clearTimeout(this.pendingSearchFunctionTimerId);
    }
    this.pendingSearchFunctionTimerId = window.setTimeout(searchFunction,  this._delayOnCollectionUpdate);
}
},

clearSearchfilter: function() {
if(!this._removeFromDom){
    this.appliedSearchCharacters = null;
    const searchFunction = (function() {
        this.infiniteListView.setUpdating(true);
        const artistCollection = new Artists(this.collection.searchOnArtistname(""));
        artistCollection.updateContext(this.displayedCollection.getContext());
        this._changeArtistList(artistCollection);
        this.pendingSearchFunctionTimerId = null;
    }).bind(this);
    if (this.pendingSearchFunctionTimerId) 
    {
        window.clearTimeout(this.pendingSearchFunctionTimerId);
    }
    this.pendingSearchFunctionTimerId = window.setTimeout(searchFunction, 0);
}

},


_changeArtistList: function(newArtistModels) {
if(!this._removeFromDom){
    this.displayedCollection = newArtistModels;
    if(!this._doNotSortDisplayedCollection)
    {
        this.displayedCollection.sort();
    }
    this.infiniteListView.updateModels(this.displayedCollection.toJSON());
    this.infiniteListView.setUpdating(false);
}
},

_updateArtistInArtistList: function(artistUpdateModel) {
if(!this._removeFromDom){
    this.infiniteListView.updateModel(artistUpdateModel.toJSON());
    this.infiniteListView.setUpdating(false);
}
},

_getOnListItemTapHandler: function() {
return (function(artistJson, evnt, item) {
    this.trigger("artistInfoTapped", this._getArtistModelFromArtistJson(artistJson) ,this.displayedCollection );
}).bind(this);
},

_getOnItemActionButtonTappedHanlder: function() {
return (function(artistJson, evnt, item) {
   this.trigger("artistActionButtonTapped", this._getArtistModelFromArtistJson(artistJson));

}).bind(this);
},

_getArtistModelFromArtistJson : function(artistJon){
return this.collection.get(artistJon.artistId);

},


isSearchApplied : function(){
return this.appliedSearchCharacters != null;
},

getAppliedSearchCharacters : function(){
return this.appliedSearchCharacters;
}, 

_onArtistAddedOrRemoved : function(){
if(!this._removeFromDom){
if(this.isSearchApplied())
{
  this.filterList(this.getAppliedSearchCharacters());
}else{
  this.infiniteListView.setUpdating(true);
  this._changeArtistList(this.collection);
}
this.trigger("modelChange", this.collection);
}
},

_onArtistUpdated : function(artistUpdateModel){
if(!this._removeFromDom){
if(this.isSearchApplied())
{
  this.filterList(this.getAppliedSearchCharacters());
}else{
  this.infiniteListView.setUpdating(true);
  this._updateArtistInArtistList(artistUpdateModel);
}
this.trigger("modelChange", this.displayedCollection);
}
},


isCollectionEmpty : function(){
if(this.collection)
{
return this.collection.length == 0;
}else{
return true;
}
},

_onContentLoading : function(){
if(!this._doNotShowLoadingWidgetDuringListLoading){
$.mobile.loading( 'show');
}
},

_onContentLoaded : function(){
if(!this._doNotShowLoadingWidgetDuringListLoading){
$.mobile.loading( "hide" );
}
},

_onContentFailedLoading : function(error){
if(!this._doNotShowLoadingWidgetDuringListLoading){
$.mobile.loading( "hide" );
console.error("Page content failed loading. Error:"+error);
}
},

load : function(params){
if(!this._removeFromDom){
if(this._loadHandler){
  this._onContentLoading();
  try{
     this.infiniteListView.setUpdating(true);
     return this._loadHandler(params).then((function(arrayOfArtists){
         if(!this._removeFromDom){
            this.collection.reset(arrayOfArtists);
            this.infiniteListView.setUpdating(false);
        }
        this._onContentLoaded();
    }).bind(this)).catch((function(error){
        this._onContentFailedLoading(error);
        this.infiniteListView.setErrorMessage(error);
    }).bind(this));
}catch(error){
 this._onContentFailedLoading(error);
 this.infiniteListView.setErrorMessage(error);
}
}else{
this._onArtistAddedOrRemoved();
return RSVP.Promise.resolve();
}
}
},

updateInfoBoxIfApplicable : function(iconClass,message,helpMessage,infoBoxClassName,actionButtonLabel,actionButtonIconClass){
if(this.infiniteListView){
this.infiniteListView.update(iconClass,message,helpMessage,infoBoxClassName,actionButtonLabel,actionButtonIconClass);
}
},

_onNodeRemovedFromDom : function(){
this._removeFromDom  = true;
PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._listenerViewportKey);
},

/*onSongRemovedFromTrebble : function(songModel){
this.trigger("songRemovedFromTrebble",songModel);
},

onActionExecutedOnSongHandler : function( songModel, action){
this.trigger("actionExecutedOnSongHandler",songModel, action);
},*/

buildItemEventHandler : function(){
const handlers = {};
handlers["click .songInfo"] = this._getOnListItemTapHandler();
handlers["click div.actionButtonWrapper > #actionButton"] = this._getOnItemActionButtonTappedHanlder();
return handlers;
},

initializeImageLazyLoadPlugin : function(container$elForImageLazyLoad){
this.infiniteListView.initializeImageLazyLoadPlugin(container$elForImageLazyLoad);
},

cancelPendingImageLoading : function(){
this.infiniteListView.cancelPendingImageLoading();
},

render: function() {

let newViewPortHeigh = PersistentModels.getInstance().getRouter().getViewportHeight();
if(this._heightMargin){
newViewPortHeigh = newViewPortHeigh - this._heightMargin;
}
this.$el.html(' <div id="maliste"  style="height:' +(newViewPortHeigh + "px") + ';overflow:hidden;width:100%"></div>');
this.setElement(this.$el.find("div").first());
const listParams = {};
if(!this._doNotSortDisplayedCollection)
{
this.displayedCollection.sort();
}
listParams.model = this.displayedCollection.toJSON();
listParams.collection = this.displayedCollection;
listParams.listRootElement = this.el;
listParams.defaultNumberOfElements = 80;
listParams.defaultHeightElement = 60;
listParams.placeHolderHeight =  PersistentModels.getInstance().getRouter().getViewportHeight();
listParams.resizePlaceHolderElem =  this._resizePlaceHolderElem;
listParams.showEmtptyInfoBox = this._showEmtptyInfoBox;
listParams.customInfoBoxInfoParams = this._customInfoBoxInfoParams;
listParams.itemEventHandlers = this.buildItemEventHandler();
listParams.container$elForImageLazyLoad = this._container$elForImageLazyLoad;
listParams.doNotInitializeLazyLoadingAtRender = this._doNotInitializeLazyLoadingAtRender;
const infiniteListView = new TrebbleArtistListView(listParams);
this.infiniteListView = infiniteListView;
    

    infiniteListView.render();
    this._onOrientationChangeFunction(PersistentModels.getInstance().getRouter().getViewportHeight(), true);
    this.$el.on("remove",this._onNodeRemovedFromDom.bind(this));
//this.listenTo(this.infiniteListView, "songRemovedFromTrebble",this.onSongRemovedFromTrebble.bind(this));
//this.listenTo(this.infiniteListView, "actionExecutedOnSongHandler",this.onActionExecutedOnSongHandler.bind(this));
    return this;
}

});

export default ArtistListView;
