import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import SequencerLibrary from "models/audioEditor/SequencerLibrary";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import SequencerNodeCloner from "models/audioEditor/sequencerNodes/SequencerNodeCloner";
import SequencerNode from "models/audioEditor/sequencerNodes/SequencerNode";
import AudioSequencerNode from "models/audioEditor/sequencerNodes/AudioSequencerNode";
import VideoSequencerNode from "models/audioEditor/sequencerNodes/VideoSequencerNode";
import DeletedSequencerNode from "models/audioEditor/sequencerNodes/DeletedSequencerNode";
import PauseSequencerNode from "models/audioEditor/sequencerNodes/PauseSequencerNode";
import PunctuationSequencerNode from "models/audioEditor/sequencerNodes/PunctuationSequencerNode";
import UnsupportedAudioSequencerNode from "models/audioEditor/sequencerNodes/UnsupportedAudioSequencerNode";
import WordSequencerNode from "models/audioEditor/sequencerNodes/WordSequencerNode";
import StartMusicWrapSequencerNode from "models/audioEditor/sequencerNodes/StartMusicWrapSequencerNode";
import EndMusicWrapSequencerNode from "models/audioEditor/sequencerNodes/EndMusicWrapSequencerNode";
import SequencerRendererHelper from "models/audioEditor/SequencerRendererHelper";
import AudioBufferHelper from "models/audioEditor/AudioBufferHelper";
import SequencerSettings from "models/audioEditor/SequencerSettings";
import SequencerClipboard from "models/audioEditor/SequencerClipboard";
import SequencerSearchWidget from "models/audioEditor/SequencerSearchWidget";
import SequencerHistoryManagement from "models/audioEditor/SequencerHistoryManagement";
import DeleteSelectedNodesOperation from "models/audioEditor/sequencerOperation/DeleteSelectedNodesOperation";
import DeleteBulkSelectedNodesOperation from "models/audioEditor/sequencerOperation/DeleteBulkSelectedNodesOperation";
import RevertDeleteSelectedNodesOperation from "models/audioEditor/sequencerOperation/RevertDeleteSelectedNodesOperation";
import WrapSelectedNodesWithAudioOperation from "models/audioEditor/sequencerOperation/WrapSelectedNodesWithAudioOperation";
import DeleteWrapWithAudioNodesOperation from "models/audioEditor/sequencerOperation/DeleteWrapWithAudioNodesOperation";
import UpdateSequencerNodePropertyOperation from "models/audioEditor/sequencerOperation/UpdateSequencerNodePropertyOperation";
import MoveWrapNodesOperation from "models/audioEditor/sequencerOperation/MoveWrapNodesOperation";
import InsertAudioNodeOperation from "models/audioEditor/sequencerOperation/InsertAudioNodeOperation";
import InsertVideoNodeOperation  from "models/audioEditor/sequencerOperation/InsertVideoNodeOperation";
import DeleteAudioSequencerNodeOperation from "models/audioEditor/sequencerOperation/DeleteAudioSequencerNodeOperation";
import MoveNodeOperation from "models/audioEditor/sequencerOperation/MoveNodeOperation";
import TurnNoiseCancellationOnOperation from "models/audioEditor/sequencerOperation/TurnNoiseCancellationOnOperation";
import InsertNodeArrayOperation from "models/audioEditor/sequencerOperation/InsertNodeArrayOperation";
import CutAndCopyToClipboardOperation from "models/audioEditor/sequencerOperation/CutAndCopyToClipboardOperation";
import SequencerSettingsChangeOperation from "models/audioEditor/sequencerOperation/SequencerSettingsChangeOperation";
import LoadProjectOperation from "models/audioEditor/sequencerOperation/LoadProjectOperation";
import CorrectTranscriptOnSingleSequencerNodeOperation from "models/audioEditor/sequencerOperation/CorrectTranscriptOnSingleSequencerNodeOperation";
import RenameSpeakerNameSequencerOperation from "models/audioEditor/sequencerOperation/RenameSpeakerNameSequencerOperation";
import CreateNewSpeakerAndAssignToSequencerNodesOperation from "models/audioEditor/sequencerOperation/CreateNewSpeakerAndAssignToSequencerNodesOperation"
import ChangeSpeakerForParagraphOperation from "models/audioEditor/sequencerOperation/ChangeSpeakerForParagraphOperation";
import TranscribeAndReplaceOperation from "models/audioEditor/sequencerOperation/TranscribeAndReplaceOperation";
import InsertAudioOrVideoNodesInBulkOperation from "models/audioEditor/sequencerOperation/InsertAudioOrVideoNodesInBulkOperation";
import DeleteSequencerNodeOperation from "models/audioEditor/sequencerOperation/DeleteSequencerNodeOperation";
import DeleteAllExceptSelectedNodesOperation from "models/audioEditor/sequencerOperation/DeleteAllExceptSelectedNodesOperation";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import UnknownSpeakerInfo from "models/audioEditor/UnknownSpeakerInfo";
import AudioBufferCache  from "models/audioEditor/AudioBufferCache";
import ti18n from "i18n!nls/Sequenceri18n";
import FileUploadHelper from "models/helper/FileUploadHelper";
import RSVP from "rsvp";
import TurnMagicSoundEnhancerOnOperation from 'models/audioEditor/sequencerOperation/TurnMagicSoundEnhancerOnOperation';
import DisfluencyDetectorHelper from 'models/audioEditor/DisfluencyDetectorHelper';
import TrebbleNotificationManager from '../../components/common/NotificationManager';

const DO_NOT_SHOW_NON_EXPLICITELY_DISPLAYED_PAUSE = false;
const AUTO_SAVE_DELAY = 500;
const Rollout = RolloutHelper.getInstance();
const EDIT_VIDEO_FILES = Rollout.isFeatureEnabled(Rollout.FEATURES.EDIT_VIDEO_FILES, true);
const DEFAULT_FILLER_WORDS = ["mmm", "um", "uh", "uhm", "uhh","umm", "uhs","mmhm","mm-mm","uh-uh","uh-huh","nuh-uh"];
const Sequencer =  Backbone.Model.extend({
    
    model: SequencerNode,

    constructor: function(attributes, options) {
        Backbone.Model.apply(this, [attributes, options]);
        //this._audioUrlToTranscriptionAudioMap = {};
        this._firstNode = null;
        this._lastNode = null;
        this._cidToSegmentNodeMap = {};
        this._collection =  new Backbone.Collection();
        this._historyManagement = new SequencerHistoryManagement({"sequencer": this});
        this.listenTo(this._historyManagement, "historyUpdated", this.onHistoryUpdated);
        this._deleteSelectedNodesOperation = new DeleteSelectedNodesOperation({"sequencer": this});
        this._deleteBulkSelectedNodesOperation = new DeleteBulkSelectedNodesOperation({"sequencer": this});
        this._revertDeleteSelectedNodesOperation = new RevertDeleteSelectedNodesOperation({"sequencer": this});
        this._wrapSelectedNodesWithAudioOperation = new WrapSelectedNodesWithAudioOperation({"sequencer": this});
        this._deleteWrapWithAudioNodesOperation =  new DeleteWrapWithAudioNodesOperation({"sequencer": this});
        this._updateSequencerNodePropertyOperation = new UpdateSequencerNodePropertyOperation({"sequencer": this});
        this._moveWrapNodesOperation = new MoveWrapNodesOperation({"sequencer": this});
        this._insertAudioNodeOperation = new InsertAudioNodeOperation({"sequencer": this});
        this._insertVideoNodeOperation = new InsertVideoNodeOperation({"sequencer": this});
        this._deleteAudioSequencerNodeOperation = new DeleteAudioSequencerNodeOperation({"sequencer": this});
        this._moveNodeOperation = new MoveNodeOperation({"sequencer": this});
        this._loadProjectOperation = new LoadProjectOperation({"sequencer": this});
        this._turnNoiseCancellationOnOperation = new TurnNoiseCancellationOnOperation({"sequencer": this});
        this._turnMagicSoundEnhancerOnOperation = new TurnMagicSoundEnhancerOnOperation({"sequencer": this});
        this._insertNodeArrayOperation = new InsertNodeArrayOperation({"sequencer": this});
        this._cutAndCopyToClipboardOperation = new CutAndCopyToClipboardOperation({"sequencer": this});
        this._sequencerSettingsChangeOperation = new SequencerSettingsChangeOperation({"sequencer": this});
        this._sequencerSearchWidget = new SequencerSearchWidget({"sequencer": this});
        this._correctTranscriptOnSingleSequencerNodeOperation = new CorrectTranscriptOnSingleSequencerNodeOperation({"sequencer": this});
        this._renameSpeakerNameSequencerOperation = new RenameSpeakerNameSequencerOperation({"sequencer": this});
        this._createNewSpeakerAndAssignToSequencerNodesOperation = new CreateNewSpeakerAndAssignToSequencerNodesOperation({"sequencer": this});
        this._changeSpeakerForParagraphOperation = new ChangeSpeakerForParagraphOperation({"sequencer": this});
        this._transcribeAndReplaceOperation = new TranscribeAndReplaceOperation({"sequencer": this});
        this._deleteSequencerNodeOperation = new DeleteSequencerNodeOperation({"sequencer": this});
        this._deleteAllExceptSelectedNodesOperation = new DeleteAllExceptSelectedNodesOperation({"sequencer": this});
        this._insertAudioOrVideoNodesInBulkOperation = new InsertAudioOrVideoNodesInBulkOperation({"sequencer": this, "insertAudioNodeOperation": this._insertAudioNodeOperation, "insertVideoNodeOperation": this._insertVideoNodeOperation});
        this._additionalSpeakerInfoArray = [];
        this._transcribeAudioArray = [];
        this._audioBufferCache =  new AudioBufferCache({sequencer: this});
        this._unknownSpeakerInfo = new UnknownSpeakerInfo();
        this.listenTo(this._correctTranscriptOnSingleSequencerNodeOperation, "transcriptionCorrection",(function(){
            this.trigger("transcriptionCorrection");
        }).bind(this))
        this.setAutoSaveOn(window.isWebAppInProduction() ?LocalStorageHelper.getInstance().isCurrentLoggedUserNotAVisitor(): false);
        this._projectId = null;
        this._warnedUserForFirstNoiseCancellation = false;
        this._warnedUserForFirstMagicSoundEnhancer = false;
        this._sequencerSettings = new SequencerSettings();
        this._sequencerSettings.setSequencer(this);
        this._sequencerClipboard = new SequencerClipboard();
        this._transcribedAudioFiles  = [];
        this.setAutoScroll(true);
        this._listenToEventsOnCollection();
        this._listenToEventsFromSequencerRendererHelper();
        this._listenToChangeOnSequencerSettings();
        this._masterProcessingTurnedOn = false;
        this.set("numberOfRenderingRequestPending", 0);
        this.setFillerWords(DEFAULT_FILLER_WORDS)
        this.setRerenderOnNextPlayback(true);
    },


    isEmpty :function(){

    },


    getId : function(){
        return this.cid;
    },

    _listenToEventsOnCollection : function(){
        this.listenTo(this._collection, "add", this._onSequencerNodeAddedToSequencerNodeCollection.bind(this));
        this.listenTo(this._collection, "remove", this._onSequencerNodeRemovedFromSequencerNodeCollection.bind(this));
        this.listenTo(this._collection, "reset",  this._onSequencerNodeCollectionReset.bind(this));
    },

    _onSequencerNodeAddedToSequencerNodeCollection : function(modelAdded){
        this.trigger("add", modelAdded); 
        if(modelAdded.getAudioSegment().getHasVideo()){
            this.setHasVideoSegment(true);
            this.getSequencerSettings().setVideoResolution(modelAdded.getAudioSegment().getVideoWidth(), modelAdded.getAudioSegment().getVideoHeight(), true, true);
        }
    },

    _onSequencerNodeRemovedFromSequencerNodeCollection : function(modelRemoved){
        this.trigger("remove", modelRemoved);
    },

    _onSequencerNodeCollectionReset : function(modelRemoved){
        this.trigger("reset");
        this.setHasVideoSegment(false);
    },

    _listenToEventsFromSequencerRendererHelper : function(){
        this.listenTo(SequencerRendererHelper.getInstance(), "sequencerRenderingStarted", this.onAudioRenderingStarted);
        this.listenTo(SequencerRendererHelper.getInstance(), "sequencerRenderingCompleted", this.onAudioRenderingCompleted);
        this.listenTo(SequencerRendererHelper.getInstance(), "sequencerRenderingFailed", this.onAudioRenderingFailed);
    },

    _listenToChangeOnSequencerSettings : function(){
        this.listenTo(this._sequencerSettings, "changeWhichRequiresRerender", this.onSequencerSettingChangeWhichRequiresRerender);
    },	

    getUnknownSpeakerInfo : function(){
        return this._unknownSpeakerInfo;
    },

    getAudioBufferCache : function(){
        return this._audioBufferCache;
    },

    onSequencerSettingChangeWhichRequiresRerender : function(){
        this.setRerenderOnNextPlayback(true);
        return this.rerenderAndPlayIfApplicable();
    },

    getSequencerSettings : function(){
        return this._sequencerSettings;
    },

    getAdditionalSpeakerInforArray : function(){
        return this._additionalSpeakerInfoArray;
    },

    getSequencerClipboard : function(){
        return this._sequencerClipboard;
    },

    setRerenderOnNextPlayback: function(rerenderOnNextPlayback){
        this.set("rerenderOnNextPlayback", rerenderOnNextPlayback);
    },

    isRerenderRequiredOnNexPlayback: function(){
        return this.get("rerenderOnNextPlayback");
    },

    setHasVideoSegment : function(hasVideoSegment){
        this.set("hasVideoSegment", hasVideoSegment);
    },

    getHasVideoSegment: function(){
        return this.get("hasVideoSegment");
    },

    isSpeakerWithLabelExist : function(speakerLabel){
        return !!this.getSpeakerWithLabel(speakerLabel);
    },

    getSpeakerInfoBySpeakerId : function(speakerId){
        for(let i =0; i < this._transcribeAudioArray.length; i++){
            const transcribedAudio = this._transcribeAudioArray[i];
            const speakerWithLabel= transcribedAudio.getSpeakerInfoWithId(speakerId);
            if(speakerWithLabel){
                return speakerWithLabel;
            }
        }

        for(let i =0; i < this._additionalSpeakerInfoArray.length; i++){
            const addidionalSpeakerInfo = this._additionalSpeakerInfoArray[i];
            if(addidionalSpeakerInfo.getSpeakerId() === speakerId){
                return addidionalSpeakerInfo;
            }
        } 
        return null;
    },

    getTotalNumberOfSpeakerInfo : function(){
        let totalNumberOfSpeaker = 0;
        totalNumberOfSpeaker = this._transcribeAudioArray.reduce((total, transcribeAudio)=>{
            if(transcribeAudio && transcribeAudio.getAllSpeakerInfo()){
                return total + transcribeAudio.getAllSpeakerInfo().length;
            }
            return total ;
        }, totalNumberOfSpeaker);

       if(this._additionalSpeakerInfoArray.length){
            totalNumberOfSpeaker =  totalNumberOfSpeaker + this._additionalSpeakerInfoArray.length;
       }
       return totalNumberOfSpeaker;
    },

    findDisfluencies : async function(){
        let notificationProgressUniqueId;
        try{
            if(this.areAllTranscribedFilesLoadedMatchingLanguage("en")){
            if(DisfluencyDetectorHelper.getInstance().isDisfluencyDetectionInProgress()){
                TrebbleNotificationManager.createWarningNotification(new Date().getTime().toString(), null, window.getI18n(ti18n, "DISFLUENCY_DETECTION_IN_PROGRESS_PLEASE_WAIT"), {  duration: 5,darkTheme: false, placement:"topRight" });
            }else{
                notificationProgressUniqueId = new Date().getTime().toString();
                TrebbleNotificationManager.createLoadingNotification(notificationProgressUniqueId, null, window.getI18n(ti18n, "ANALYZING_THE_TRANSCRIPT_FOR_DISFLUENCIES"),{ duration: 0 ,darkTheme: false,  placement:"topRight"});
                const disfluencySequencerNodeInfoArray = await DisfluencyDetectorHelper.getInstance().getDisluencySequencerNodes(this.getFirstNode(), this.getLastNode());
                if(!disfluencySequencerNodeInfoArray || disfluencySequencerNodeInfoArray.length === 0 ){
                    Utils.getInstance().showConfetti();
                    TrebbleNotificationManager.destroyNotification(notificationProgressUniqueId);
                    TrebbleNotificationManager.createSuccessNotification(notificationProgressUniqueId, null, window.getI18n(ti18n, "RAS_LOOKS_LIKE_YOUR_FILE_IS_CLEAR"), {duration: 5, darkTheme: false, placement:"topRight" })
                    if(window.trebbleAnalyticsHelper){
                        window.trebbleAnalyticsHelper.trackEvent("sequencer", 'noDifluencyFound', 'No Disfluency found');
                    }
                }else{
                    this._sequencerSearchWidget.applyDisfluencySearch(disfluencySequencerNodeInfoArray);
                    if(window.trebbleAnalyticsHelper){
                        window.trebbleAnalyticsHelper.trackEvent("sequencer", 'highlightDisfluencyFound', 'Highlight Disfluency Found');
                    }
                    TrebbleNotificationManager.destroyNotification(notificationProgressUniqueId);
                    TrebbleNotificationManager.createSuccessNotification(notificationProgressUniqueId, null, window.getI18n(ti18n, "YAY_WE_SPOTTED_SOME_DISFLUENCIES"), {duration: 5, darkTheme: false, placement:"topRight" })
                }
            }
        }else{
            Utils.getInstance().showSorryMessage(window.getI18n(ti18n,"CLARITY_SPARK_ONLY_AVAILABLE_IN_ENGLISH"),window.getI18n(ti18n,"OUR_TEAM_IS_DEDICATED_TO_ADD_MORE_LANGUAGE_TO_CLARITY_SPARK"))
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("sequencer", 'disfluencySearchNotAvailableInThisLanguage', 'Disfluency search not available in this lanuguage');
            }
        }
        }catch(error){
            if(!notificationProgressUniqueId){
                notificationProgressUniqueId =new Date().getTime().toString();
            }
            TrebbleNotificationManager.createErrorNotification(notificationProgressUniqueId, null, window.getI18n(ti18n, "ERROR_WHILE_DETECTING_DISFLUENCIES"), { duration: 5,darkTheme: false, placement:"topRight" })
        }
    },

    findFillerWords : function(){
        this._sequencerSearchWidget.applyFillerWordsSearch();
        this.setSearchOn(true);
    },

    findEditBoundaries : function(){
        this._sequencerSearchWidget.applyEditBoundariesSearch();
        this.setSearchOn(true);
    },

    findSilences : async function(maxSilenceInMilliseconds, onAnalysisPogressFunction ){
        const defaultProgressMessage = window.getI18n(ti18n, "ANALYZING_MEDIA");
        const totalNumberOfMedia = this.getAllTranscriptionMediaUrls().length;
        const actionToExecute = (async function(progressReportFunction){
            await Promise.all(this.getAllTranscriptionMediaUrls().map((async function(mediaUrl, index){
                let mediaIndexBeingProcess = index;
                const onProgressReported =  progressReportFunction ? function(currentMediaProgress){
                    let progress = ((currentMediaProgress + (mediaIndexBeingProcess * 100))/(totalNumberOfMedia* 100)) *100;
                    progressReportFunction(progress, defaultProgressMessage)

                }: null;
                if(!this.getAudioBufferCache().isVoiceActivityDataAvailableForAudioUrl(mediaUrl)){
                    await this.getAudioBufferCache().loadVoiceActivityDataForAudioUrl(mediaUrl, mediaUrl, AudioBufferHelper.getInstance().getAudioTypes().VOICE, this.getSequencerSettings(), onProgressReported)
                }
            }).bind(this)))
        }).bind(this);
        if(onAnalysisPogressFunction){
            await onAnalysisPogressFunction(actionToExecute, defaultProgressMessage);
        }else{
            await actionToExecute();
        }
        this._sequencerSearchWidget.applySearchResultsForSilencesToTrim(maxSilenceInMilliseconds);
        this.setSearchOn(true);
    },

    removeAdditionalSpeakerBySpeakerLabel: function(speakerLabel){
      let indexOfSpeakerInfoToRemove = -1;
      for(let i=0; i < this._additionalSpeakerInfoArray.length; i++){
          const addidionalSpeakerInfo = this._additionalSpeakerInfoArray[i];
          if(addidionalSpeakerInfo.getSpeakerLabel() === speakerLabel){
            indexOfSpeakerInfoToRemove = i;
            break;
          }
      }  
      if(indexOfSpeakerInfoToRemove > -1){
        this._additionalSpeakerInfoArray.splice(indexOfSpeakerInfoToRemove, 1);
      }
    },

    generateSpeakerLabelIfDuplicate: function(speakerId,speakerLabel){
        const numberOfSpeakerWithSameLabel = this.getNumberOfSpeakersWithSameLabelButDifferentId(speakerId, speakerLabel);
        if(numberOfSpeakerWithSameLabel > 0){
            //const totalNumberOfSpeakers = this.getTotalNumberOfSpeakerInfo();
            let speakerIndex = 1;
            let newSpeakerLabel =`${window.getI18n(ti18n, "SPEAKER")} ${speakerIndex}`
            while(this.getNumberOfSpeakersWithSameLabelButDifferentId(speakerId, newSpeakerLabel) > 0){
                speakerIndex = speakerIndex + 1;
                newSpeakerLabel = `${window.getI18n(ti18n, "SPEAKER")} ${speakerIndex}`;
            }
            return newSpeakerLabel;
        }
        return speakerLabel;
    },

    getNumberOfSpeakersWithSameLabelButDifferentId : function(speakerId, speakerLabel){
        let numberOfSpeakerWithSameLabelButDifferentId = 0;
        const uniqueSpeakerIds = [];
        for(let i =0; i < this._transcribeAudioArray.length; i++){
            const transcribedAudio = this._transcribeAudioArray[i];
            const speakerWithLabel= transcribedAudio.getSpeakerInfoWithLabel(speakerLabel);
            if(speakerWithLabel && speakerWithLabel.getSpeakerId() !== speakerId && !uniqueSpeakerIds.includes(speakerWithLabel.getSpeakerId())){
                uniqueSpeakerIds.push(speakerWithLabel.getSpeakerId());
                numberOfSpeakerWithSameLabelButDifferentId = numberOfSpeakerWithSameLabelButDifferentId + 1;
            }
        }

        for(let i =0; i < this._additionalSpeakerInfoArray.length; i++){
            const addidionalSpeakerInfo = this._additionalSpeakerInfoArray[i];
            if(addidionalSpeakerInfo.getSpeakerLabel() === speakerLabel){
                if(addidionalSpeakerInfo.getSpeakerId() !== speakerId && !uniqueSpeakerIds.includes(addidionalSpeakerInfo.getSpeakerId())){
                    uniqueSpeakerIds.push(addidionalSpeakerInfo.getSpeakerId());
                    numberOfSpeakerWithSameLabelButDifferentId = numberOfSpeakerWithSameLabelButDifferentId + 1;
                }
            }
        } 
        return numberOfSpeakerWithSameLabelButDifferentId;
    },

    getSpeakerWithLabel : function(speakerLabel){
        for(let i =0; i < this._transcribeAudioArray.length; i++){
            const transcribedAudio = this._transcribeAudioArray[i];
            const speakerWithLabel= transcribedAudio.getSpeakerInfoWithLabel(speakerLabel);
            if(speakerWithLabel){
                return speakerWithLabel;
            }
        }

        for(let i =0; i < this._additionalSpeakerInfoArray.length; i++){
            const addidionalSpeakerInfo = this._additionalSpeakerInfoArray[i];
            if(addidionalSpeakerInfo.getSpeakerLabel() === speakerLabel){
                return addidionalSpeakerInfo;
            }
        } 
        return null;
    },

    getAllSpeakerInfo : function(){
        let allSpeakerInfo = [];
        this._transcribeAudioArray.map((transcribedAudio)=>{
            allSpeakerInfo = allSpeakerInfo.concat(transcribedAudio.getAllSpeakerInfo())
        });
        this._additionalSpeakerInfoArray.map((additionalSpeakerInfo)=>{
            allSpeakerInfo.push(additionalSpeakerInfo);
        })
        return allSpeakerInfo;
    },

    createAdditionalSpeakerInfoWithLabel : function(speakerLabel){
        const newSpeakerInfo  = Utils.getInstance().getSpeakerInfo("speakerId"+Utils.getInstance().generateRandomId(), speakerLabel);
        this._additionalSpeakerInfoArray.push(newSpeakerInfo);
        return newSpeakerInfo;
    },

    createAdditionalSpeakerInfoFromSerializedData : function(serializedData) {
        this._additionalSpeakerInfoArray.push(Utils.getInstance().createSpeakerInfoFromSerializedData(serializedData));
    },

    getNumberOfRenderingRequestPending: function(){
        if(this.get("numberOfRenderingRequestPending") > 0){
            return this.get("numberOfRenderingRequestPending");
        }else{
            return 0;
        }
    },

    setFillerWords : function(arrayOfFillerWords){
        this.set("arrayOfFillerWords",arrayOfFillerWords);
    },

    getFillerWords : function(){
        return this.get("arrayOfFillerWords");
    },

    deleteAllFillerWords : function(){
        const searchResults = this.getSearchWidget().getSearhResultsForFillerWords();
        this._deleteBulkSelectedNodesOperation.execute(searchResults.allArraysOfNodesToHiglights.map((function(arrayOfNodeToDelete){
            return [arrayOfNodeToDelete[0], arrayOfNodeToDelete[arrayOfNodeToDelete.length - 1]];
        }).bind(this)));
        return searchResults.allArraysOfNodesMatchingResults && searchResults.allArraysOfNodesMatchingResults.length;
    },

    trimSilences : function(maxSilenceInMilliseconds){
        const searchResults = this.getSearchWidget().getSearchResultsForSilencesToTrim(maxSilenceInMilliseconds);
        this._deleteBulkSelectedNodesOperation.execute(searchResults.allArraysOfNodesToHiglights.map((function(arrayOfNodeToDelete){
            return [arrayOfNodeToDelete[0], arrayOfNodeToDelete[arrayOfNodeToDelete.length - 1]];
        }).bind(this)));
        return searchResults.allArraysOfNodesMatchingResults && searchResults.allArraysOfNodesMatchingResults.length;
    },

    deleteAllSearchResults : function(){
        const cachedSearchResults = this.getSearchWidget().getCachedSearchResults();
        this._deleteBulkSelectedNodesOperation.execute(cachedSearchResults.allArraysOfNodesToHiglights.map((function(arrayOfNodeToDelete){
            return [arrayOfNodeToDelete[0], arrayOfNodeToDelete[arrayOfNodeToDelete.length - 1]];
        }).bind(this)));
        const numberOfResultsDeleted =  cachedSearchResults.allArraysOfNodesMatchingResults && cachedSearchResults.allArraysOfNodesMatchingResults.length;
        TrebbleNotificationManager.createSuccessNotification(new Date().getTime().toString(), null,numberOfResultsDeleted +" " + window.getI18n(ti18n, "RESULTS_DELETED"), {duration: 3, darkTheme: false, placement:"topRight" })
        if(window.trebbleAnalyticsHelper){
            const analyticsParams = {searchType : this.getSearchWidget().getSearchType(), numberOfResultsDeleted};
            window.trebbleAnalyticsHelper.trackEvent("sequencer", 'deleleBulkSearchResults', 'Delete bulk search results for search type '+ this.getSearchWidget().getSearchType(), null, analyticsParams);
        }
        this.clearSearch();
        return numberOfResultsDeleted;
    },




    isTherePendingRenderingRequest : function(sequencer){
        return this.getNumberOfRenderingRequestPending() > 0;
    },

    increaseNumberOfRenderingRequestPending: function(sequencer){
        this.set("numberOfRenderingRequestPending", this.getNumberOfRenderingRequestPending()+ 1);
    },

    decreaseNumberOfRenderingRequestPending: function(sequencer){
        this.set("numberOfRenderingRequestPending", this.getNumberOfRenderingRequestPending() - 1);
    },

    setAutoSaveOn : function(autoSaveOn){
        this.set("autoSaveOn", autoSaveOn);
    },

    getAutoSaveOn : function(){
        return this.get("autoSaveOn");
    },

    setVideoContext : function(videoContext){
        this._videoContext = videoContext;
    },

    getVideoContext : function(){
        return this._videoContext;
    },

    setAutoScroll : function(autoscroll, silent){
        //this.set("autoscroll", autoscroll);
        this._sequencerSettings.setAutoscroll(autoscroll, silent);
    },

    getAutoScroll : function(){
        //return this.get("autoscroll");
        return this._sequencerSettings.isAutoscroll();
    },

    onHistoryUpdated : function(operationNameWhichTriggerHistoryUpdate){
        this.trigger("historyUpdated", operationNameWhichTriggerHistoryUpdate);
        this.setRerenderOnNextPlayback(true);
        if(this.getAutoSaveOn()){
            this.scheduleProjectSave(operationNameWhichTriggerHistoryUpdate);
        }
        return this.rerenderAndPlayIfApplicable();
    },

    scheduleProjectSave:function(operationNameWhichTriggerHistoryUpdate){
        if(this._pendingProductSave){
            clearTimeout(this._pendingProductSave);
        }
        const saveProjectDeferred = (function(){
            this.saveProject(null, operationNameWhichTriggerHistoryUpdate);
        }).bind(this);
        this._pendingProductSave = setTimeout(saveProjectDeferred,AUTO_SAVE_DELAY);
    },

    saveProject: function(force, operationNameWhichTriggerHistoryUpdate){
        this.trigger("projectBeingSaved");
        const serializedData = this.getSerializedData();
        if(!serializedData && !this.isSequencerPossiblyCleared()){
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("sequencer", 'FaileSavingSequencerProject', 'serializedData was null');
            }
            window.sendErrorToRaygun('serializedData was null', true);
            return RSVP.Promise.resolve();
        }

  
        const simplifiedSerializedData = {"textTranscription": serializedData.textTranscription,
        "sequencerSettings" : serializedData.sequencerSettings,
        "transcribedAudioFiles" : serializedData.transcribedAudioFiles,
        "otherTrebbleAudioFiles":  serializedData.otherTrebbleAudioFiles,
        "saveTrigger" : operationNameWhichTriggerHistoryUpdate,
        "firstNodeCid": serializedData.firstNodeCid}

        if(serializedData){
            serializedData.saveTrigger = operationNameWhichTriggerHistoryUpdate;
            serializedData.transcriptionJSONUrlToSpeakerInfoData = this._transcribeAudioArray.reduce((transcriptionJSONUrlToSpeakerInfoData, transcribedAudio)=>{
                transcriptionJSONUrlToSpeakerInfoData[transcribedAudio.getTranscriptJsonURL()] =  transcribedAudio.getAllSpeakerInfo().map((speakerInfo)=>{
                    return speakerInfo.getSerializedData();
                })
                return transcriptionJSONUrlToSpeakerInfoData;
            }, {});
        }
        const pendingSaveOperation = this._pendingSaveOperation? this._pendingSaveOperation.catch(): RSVP.Promise.resolve();
        this._pendingSaveOperation = pendingSaveOperation.then((function(){
            return TrebbleClientAPIHelper.getInstance().saveSequencerProject(this.getProjectId(), new Date(), simplifiedSerializedData, true);
        }).bind(this)).then((function(operationResult){
            if(operationResult.projectWasSaved){
                if(operationResult.preSignedUrlForUpload){
                    return FileUploadHelper.getInstance().uploadJSONToS3UsignPreSignedUrl(serializedData, operationResult.preSignedUrlForUpload).then((function(){
                        return TrebbleClientAPIHelper.getInstance().confirmProjectWasSuccessfullySaved(operationResult.serializedDataFileLocation);
                    }).bind(this)).then((function(){
                        this.trigger("projectSavedSuccessfully");
                        this.setProjectId(operationResult.projectId);
                    }).bind(this)).catch((function(error){
                        this.trigger("projectFailedToBeSaved");
                        if(window.trebbleAnalyticsHelper){
                            window.trebbleAnalyticsHelper.trackEvent("sequencer", 'faileUploadingSequencerProjectToS3', 'Failed saving sequencer project to S3', null, {"error": error});
                        }
                        window.sendErrorToRaygun('Failed saving sequencer project to S3', true);
                    }).bind(this));
                }else{
                    this.trigger("projectSavedSuccessfully");
                    this.setProjectId(operationResult.projectId);
                }
            }else{
                if(operationResult.projectToBeSaveWasOlder){
                    this.trigger("projectToBeSaveWasOlder");
                }else{
                    if(operationResult.authorNotAuthorizedToSave){
                        this.trigger("authorNotAuthorizedToSave");
                        if(window.trebbleAnalyticsHelper){
                            window.trebbleAnalyticsHelper.trackEvent("sequencer", 'savingSequencerProjectNotAuthorized', 'Saving sequencer project was not authorized');
                        }
                    }else{
                        this.trigger("projectFailedToBeSaved");
                        if(window.trebbleAnalyticsHelper){
                            window.trebbleAnalyticsHelper.trackEvent("sequencer", 'FaileSavingSequencerProject', 'Failed saving sequencer project');
                        }
                        window.sendErrorToRaygun('Failed saving sequencer project', true);
                    }
                }
            }
            
        }).bind(this)).catch((function(error){
            this.trigger("projectFailedToBeSaved");
            window.sendErrorToRaygun( 'Failed saving sequencer project. Error:'+error, true);
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("sequencer", 'FaileSavingSequencerProject', 'Failed saving sequencer project');
            }
        }).bind(this));
        return this._pendingSaveOperation;
    },

    onAudioRenderingStarted : function(){
        this.trigger("sequencerRenderingStarted");
    },

    onAudioRenderingCompleted : function(){
        this.setRenderingBeingCreated(false);
        this.trigger("sequencerRenderingCompleted");
    },

    onAudioRenderingFailed : function(error){
        this.setRenderingBeingCreated(false);
        this.trigger("sequencerRenderingFailed", error);
    },

    getAllTranscriptionIds : function(){
        return this._transcribedAudioFiles.map((t)=>{
            return t.transcriptionId
        });
    },

    getAllTranscriptionMediaUrls : function(){
        return this._transcribedAudioFiles.map((t)=>{
            return t.audioFileUrl
        });
    },

    getTranscriptionIdForAudioUrl : function(audioUrl){
        for(let i = 0; i < this._transcribedAudioFiles.length;i++){
            const transcriptionAudioFileInfo = this._transcribedAudioFiles[i];
            if(transcriptionAudioFileInfo.audioFileUrl === audioUrl){
                return transcriptionAudioFileInfo.transcriptionId;
            }
        }
        return null;
    },

    areAllTranscribedFilesLoadedMatchingLanguage : function(genericLanguageCode){
        return this._transcribedAudioFiles.reduce((areAllMatchingLanguage,transcribedAudioFile)=>{
            if(areAllMatchingLanguage){
                return transcribedAudioFile && (!transcribedAudioFile.languageCode || transcribedAudioFile.languageCode.startsWith(genericLanguageCode));
            }else{
                return areAllMatchingLanguage;
            }
        }, true)
    },
    
    getAllLanguageCodesUsed: function(){
        return this._transcribedAudioFiles.reduce((arrayOfLanguageCodes,transcribedAudioFile)=>{
            if(transcribedAudioFile.languageCode && !arrayOfLanguageCodes.includes(transcribedAudioFile.languageCode)){
                arrayOfLanguageCodes.push(transcribedAudioFile.languageCode)
                return arrayOfLanguageCodes;
            }else{
                return arrayOfLanguageCodes;
            }
        }, [])
    },

    addTranscribeAudio : function(audioUrl, transcriptionJsonURL, transcriptionId, doNotAddToSequencer, isVideo, progressReportFunction, speakerInfoSerializedDataArray, additionalSpeakerInfoArray, languageCode, videoWidth, videoHeight){
        this.trigger("audioTranscribedBeingAdded");
        this._transcribedAudioFiles.push({ audioFileUrl:audioUrl, transcriptionFileLocation : transcriptionJsonURL, transcriptionId: transcriptionId, isVideo: isVideo, languageCode, videoWidth, videoHeight});
        return this.getAudioBufferCache().loadAudioBufferFromUrl(audioUrl, audioUrl, AudioBufferHelper.getInstance().getAudioTypes().VOICE, this.getSequencerSettings(), progressReportFunction).then((function(audioBuffer){
            if(progressReportFunction){
                progressReportFunction(null, window.getI18n(ti18n, "PARSING_TRANSCRIPTION"))
            }
            return SequencerLibrary.getInstance().getTranscribeAudio(audioUrl, transcriptionJsonURL, audioBuffer? AudioBufferHelper.getInstance().getMaxAudioBufferDuration(audioBuffer)*1000: 0, transcriptionId, isVideo, speakerInfoSerializedDataArray, additionalSpeakerInfoArray, this.generateSpeakerLabelIfDuplicate.bind(this), languageCode,  videoWidth, videoHeight,);
        }).bind(this)).then((function(transcribeAudio){
            if(transcribeAudio && transcribeAudio.getAudioSegmentArray() && !doNotAddToSequencer){
                const audioSegmentArray = transcribeAudio.getAudioSegmentArray();
                for(let i = 0; i < audioSegmentArray.length; i++){
                    const audioSegment = audioSegmentArray[i];
                    const functionToCreateSequencerNode = (function(){
                        return this._createSequencerNode(audioSegment);
                    }).bind(this)
                    this._addSequencerNodeToTheEnd(functionToCreateSequencerNode);
                }
            }
            if(transcribeAudio){
                this._transcribeAudioArray.push(transcribeAudio);
            }
            this.trigger("audioTranscribedAdded");
            return transcribeAudio;
        }).bind(this))
    },

    createSequencerNodesFromTransribedAudio : function(transcribedAudio){
        if(transcribedAudio && transcribedAudio.getAudioSegmentArray()){
            return transcribedAudio.getAudioSegmentArray().map((audioSegment)=>{
                return this._createSequencerNode(audioSegment);
            })
        }
        return [];
    },

    /*getFirstNode : function(){
        //return this._firstNode;
        return this._collection.at(0);
    },*/	

    _createSequencerNode : function(audioSegment){
        let sequencerNode =  null;
        if(Utils.getInstance().isPauseAudioSegmentInstance(audioSegment)){
            sequencerNode  = new PauseSequencerNode({"audioSegment": audioSegment,"sequencer": this});
        }
        if(Utils.getInstance().isDeletedAudioSegmentInstance(audioSegment)){
            sequencerNode  = new DeletedSequencerNode({"audioSegment": audioSegment,"sequencer": this});
        }
        if(Utils.getInstance().isWordAudioSegmentInstance(audioSegment)){
            sequencerNode  = new WordSequencerNode({"audioSegment": audioSegment,"sequencer": this});
        }
        if(Utils.getInstance().isPunctuationAudioSegmentInstance(audioSegment)){
            sequencerNode  = new PunctuationSequencerNode({"audioSegment": audioSegment,"sequencer": this});
        }
        if(Utils.getInstance().isUnsupportedAudioSegmentInstance(audioSegment)){
            sequencerNode  = new UnsupportedAudioSequencerNode({"audioSegment": audioSegment,"sequencer": this});
        }
        if(Utils.getInstance().isCloudAudioSegmentInstance(audioSegment)){
            sequencerNode  = new AudioSequencerNode({"audioSegment": audioSegment,"sequencer": this});
        }
        if(Utils.getInstance().isCloudVideoSegmentInstance(audioSegment)){
            sequencerNode  = new VideoSequencerNode({"audioSegment": audioSegment,"sequencer": this});
        }
        return sequencerNode;
    },

    addToSequencerNodeCreated : function(sequencerNodeCreated){
        this._cidToSegmentNodeMap[sequencerNodeCreated.getCid()] = sequencerNodeCreated;
    },

    setFirstNode : function(sequencerNode){
        this._firstNode = sequencerNode;
    },

    getFirstNode : function(){
        return this._firstNode;
    },

    setLastNode : function(sequencerNode){
        this._lastNode = sequencerNode;
    },

    getLastNode : function(){
        //return this._lastNode;
        return this._collection.at(this._collection.length - 1)
    },


    updateClipboard : function(startSequencerNodeModel, endSequencerNodeModel, shouldBeClonedWhenRetrieved){
        this._sequencerClipboard.updateClipboard(startSequencerNodeModel, endSequencerNodeModel, shouldBeClonedWhenRetrieved);
    },

    isClipboardEmpty : function(){
        return this._sequencerClipboard.isClipboardEmpty();
    },


    _getSequencerNodeFromCid : function(nodeCid){
        return this._cidToSegmentNodeMap[nodeCid];
    },

    _addSequencerNodeToTheEnd : function(functionToCreateSequnecerNode){
        const sequencerNode = functionToCreateSequnecerNode();
        //this.addToSequencerNodeCreated(sequencerNode);
        if(!this._firstNode){
            this._firstNode = sequencerNode;
        }
        if(!this._lastNode){
            this._lastNode = sequencerNode;
        }else{
            this._lastNode.setNext(sequencerNode);
        }

        this._lastNode = sequencerNode;
        sequencerNode.addToSequencer();
        return sequencerNode;
    },

    add : function(model){
        const previousNode = model.getPrevious();
        const nextNode = model.getNext();
        const indexOfPreviousNode = previousNode?this._collection.indexOf(previousNode): -1;
        if(indexOfPreviousNode > -1){
            return this._collection.add(model, {"at": indexOfPreviousNode+1});
        }
        const indexOfNextNode = nextNode?this._collection.indexOf(nextNode): -1;
        if(indexOfNextNode > -1){
            return this._collection.add(model, {"at": indexOfNextNode});
        }
        //Just in case then add without an index
        return this._collection.add(model);
    },

    remove : function(model){
        this._collection.remove(model);
    },

    getAllSequencerNodesInRange : function(audioUrl, startTime, endTime, filterConditionFunction, hasToBeInsideTheRange ){
        const matchingSequencerNodes = [];
        Object.values(this._cidToSegmentNodeMap).map((function(sequencerNode){
            if(sequencerNode.getAudioSegment() && sequencerNode.getAudioSegment().getAudioUrl() === audioUrl && (!filterConditionFunction || filterConditionFunction(sequencerNode))){
                if(hasToBeInsideTheRange){
                    if(sequencerNode.getAudioSegment().getStartTime() >=  startTime && sequencerNode.getAudioSegment().getEndTime() <= endTime){
                        matchingSequencerNodes.push(sequencerNode)
                    }
                }else{
                    if((sequencerNode.getAudioSegment().getStartTime() >=  startTime && sequencerNode.getAudioSegment().getStartTime() < endTime) || (sequencerNode.getAudioSegment().getEndTime() <= endTime &&  sequencerNode.getAudioSegment().getEndTime() > startTime)){
                        matchingSequencerNodes.push(sequencerNode)
                    }
                }
            }
        }).bind(this))
        return matchingSequencerNodes;

    },

    deleteSelectedNodes : function(startSequencerNodeToDelete, endSequencerNodeToDelete ){
        try{
            const deleteNodeCreated =  this._deleteSelectedNodesOperation.execute(startSequencerNodeToDelete, endSequencerNodeToDelete);
            
        }catch(error){
            if(error instanceof DeleteSelectedNodesOperation.InterruptedBecauseOfWrapNodeOrphanError){
                this.trigger("deleteOperationInteruptedBecauseOfWrapNodeOrphan");
            }else{
                if(error instanceof DeleteSelectedNodesOperation.SelectedNodeNeedToBeNextToEachotherError){
                    this.trigger("selectedNodeNeedToBeNextToEachotherError");
                }else{
                    throw error;
                }
            }
        }
    },

    undoDeleteSelectedNodes : function(sequencerOperationDeleteNodesLog ){
        return this._deleteSelectedNodesOperation.undo(sequencerOperationDeleteNodesLog);
    },

    redoDeleteSelectedNodes : function(sequencerOperationDeleteNodesLog ){
        return this._deleteSelectedNodesOperation.redo(sequencerOperationDeleteNodesLog);
    },

    undoDeleteSelectedNodesBulk : function(sequencerOperationCreateDeleteNodesBulkLog ){
        return this._deleteBulkSelectedNodesOperation.undo(sequencerOperationCreateDeleteNodesBulkLog);
    },

    redoDeleteSelectedNodesBulk : function(sequencerOperationCreateDeleteNodesBulkLog ){
        return this._deleteBulkSelectedNodesOperation.redo(sequencerOperationCreateDeleteNodesBulkLog);
    },

    revertDeletedNodeCreation : function(deletedSequencerNode){
        return this._revertDeleteSelectedNodesOperation.execute(deletedSequencerNode);
    },

    undoRevertDeletedNodeCreation : function(sequencerOperationDeleteDeleteNodesLog){
        return this._revertDeleteSelectedNodesOperation.undo(sequencerOperationDeleteDeleteNodesLog);
    },

    redoRevertDeletedNodeCreation : function(sequencerOperationDeleteDeleteNodesLog){
        return this._revertDeleteSelectedNodesOperation.redo(sequencerOperationDeleteDeleteNodesLog);
    },

    wrapSelectedNodesWithMusic : function(trebbleAudio,introPaddingInMilliseconds, outroPaddingInMilliseconds, introFadeInMilliseconds, outroFadeInMilliseconds, volumePercentage, loopToMatchWrapNodeDuration, ducking,  startSequencerNodeToWrap, endSequencerNodeToWrap ){
        return this._wrapSelectedNodesWithAudioOperation.execute(trebbleAudio,introPaddingInMilliseconds, outroPaddingInMilliseconds, introFadeInMilliseconds, outroFadeInMilliseconds, volumePercentage, loopToMatchWrapNodeDuration,ducking,  startSequencerNodeToWrap, endSequencerNodeToWrap);
    },

    undoWrapSelectedNodesWithMusic : function(sequencerOperationWrapWithMusicLog){
        return this._wrapSelectedNodesWithAudioOperation.undo(sequencerOperationWrapWithMusicLog);
    },

    redoWrapSelectedNodesWithMusic : function(sequencerOperationWrapWithMusicLog){
        return this._wrapSelectedNodesWithAudioOperation.redo(sequencerOperationWrapWithMusicLog);
    },

    deleteMusicWrapNodeCreated : function(startOrEndMusicWrapSequencerNode){
        return this._deleteWrapWithAudioNodesOperation.execute(startOrEndMusicWrapSequencerNode);
    },

    undoDeleteMusicWrapNodeCreation : function(sequencerOperationDeleteWrapWithMusicLog){
        return this._deleteWrapWithAudioNodesOperation.undo(sequencerOperationDeleteWrapWithMusicLog);
    },

    redoDeleteMusicWrapNodeCreation : function(sequencerOperationDeleteWrapWithMusicLog){
        return this._deleteWrapWithAudioNodesOperation.redo(sequencerOperationDeleteWrapWithMusicLog);
    },

    deleteAudioSequencerNodeCreated: function(audioSequencerNode){
        return this._deleteAudioSequencerNodeOperation.execute(audioSequencerNode);
    },

    undoDeleteAudioSequencerNodeCreated : function(sequencerOperationDeleteAudioNodeLog){
        return this._deleteAudioSequencerNodeOperation.undo(sequencerOperationDeleteAudioNodeLog);
    },

    redoDeleteAudioSequencerNodeCreated : function(sequencerOperationDeleteAudioNodeLog){
        return this._deleteAudioSequencerNodeOperation.redo(sequencerOperationDeleteAudioNodeLog);
    },

    updateSequencerNodeProperty : function(sequencerNode, updatePropertyFunctionName, retrievePropertyFunctionName, newValue){
        return this._updateSequencerNodePropertyOperation.execute(sequencerNode, updatePropertyFunctionName, retrievePropertyFunctionName, newValue);
    },

    undoUpdatePropertyOnSequencerNode : function(sequencerOperationChangeSequencerNodePropertyLog){
        return this._updateSequencerNodePropertyOperation.undo(sequencerOperationChangeSequencerNodePropertyLog);
    },

    redoUpdatePropertyOnSequencerNode : function(sequencerOperationChangeSequencerNodePropertyLog){
        return this._updateSequencerNodePropertyOperation.redo(sequencerOperationChangeSequencerNodePropertyLog);
    },

    moveWrapNode : function(startOrEndWrapSequencerNodeToMove,oldIndexInSequencer,  newIndexInSequencer, newPreviousSequencerNode, newNextSequencerNode){
        return this._moveWrapNodesOperation.execute(startOrEndWrapSequencerNodeToMove,oldIndexInSequencer,  newIndexInSequencer, newPreviousSequencerNode, newNextSequencerNode);
    },

    undoMoveWrapNode : function(sequencerOperationMoveWrapNodesLog){
        return this._moveWrapNodesOperation.undo(sequencerOperationMoveWrapNodesLog);
    },

    redoMoveWrapNode : function(sequencerOperationMoveWrapNodesLog){
        return this._moveWrapNodesOperation.redo(sequencerOperationMoveWrapNodesLog);
    },

    moveNode : function(nodeToMove, newPreviousSequencerNode, newNextSequencerNode){
        return this._moveNodeOperation.execute(nodeToMove, newPreviousSequencerNode, newNextSequencerNode);
    },

    undoMoveNode : function(sequencerOperationMoveNodeLog){
        return this._moveNodeOperation.undo(sequencerOperationMoveNodeLog);
    },

    redoMoveNode : function(sequencerOperationMoveNodeLog){
        return this._moveNodeOperation.redo(sequencerOperationMoveNodeLog);
    },

    insertAudioNode : function(trebbleAudio, sequencerNodeToInsertNextTo, insertBefore,introPaddingInMilliseconds, outroPaddingInMilliseconds, introFadeInMilliseconds,outroFadeinMilliseconds, volume){
        return this._insertAudioNodeOperation.execute(trebbleAudio, sequencerNodeToInsertNextTo, insertBefore,introPaddingInMilliseconds, outroPaddingInMilliseconds, introFadeInMilliseconds, outroFadeinMilliseconds, volume);
    },

    undoInsertAudioNode : function(sequencerOperationInsertAudioNodeLog){
        return this._insertAudioNodeOperation.undo(sequencerOperationInsertAudioNodeLog);
    },

    redoInsertAudioNode : function(sequencerOperationInsertAudioNodeLog){
        return this._insertAudioNodeOperation.redo(sequencerOperationInsertAudioNodeLog);
    },

    insertVideoNode : function(trebbleVideo, sequencerNodeToInsertNextTo, insertBefore,introPaddingInMilliseconds, outroPaddingInMilliseconds, introFadeInMilliseconds,outroFadeinMilliseconds, volume){
        return this._insertVideoNodeOperation.execute(trebbleVideo, sequencerNodeToInsertNextTo, insertBefore,introPaddingInMilliseconds, outroPaddingInMilliseconds, introFadeInMilliseconds, outroFadeinMilliseconds, volume);
    },

    undoInsertVideoNode : function(sequencerOperationInsertVideoNodeLog){
        return this._insertVideoNodeOperation.undo(sequencerOperationInsertVideoNodeLog);
    },

    redoInsertVideoNode : function(sequencerOperationInsertVideoNodeLog){
        return this._insertVideoNodeOperation.redo(sequencerOperationInsertVideoNodeLog);
    },

    deleteAllExceptSelectedNodes : function(startSequencerNodeToKeep, endSequencerNodeToKeep){
        this._deleteAllExceptSelectedNodesOperation.execute(startSequencerNodeToKeep, endSequencerNodeToKeep);
    },

    undoDeleteAllExceptSelectedNodes : function(sequencerOperationDeleteAllExceptSelectedNodesLog){
        this._deleteAllExceptSelectedNodesOperation.undo(sequencerOperationDeleteAllExceptSelectedNodesLog);
    },

    redoDeleteAllExceptSelectedNodes : function(sequencerOperationDeleteAllExceptSelectedNodesLog){
        this._deleteAllExceptSelectedNodesOperation.redo(sequencerOperationDeleteAllExceptSelectedNodesLog);
    },

    insertAudioOrVideoNodesInBulk: function(sequencerNodeTargetToInsertBefore, insertBefore, audioOrVideoModelsToInsert){
        this._insertAudioOrVideoNodesInBulkOperation.execute(sequencerNodeTargetToInsertBefore, insertBefore, audioOrVideoModelsToInsert);
    },

    undoInsertAudioOrVideoNodesBulk: function(sequencerOperationInsertAudioOrVideoNodesInBulkLog){
        this._insertAudioOrVideoNodesInBulkOperation.undo(sequencerOperationInsertAudioOrVideoNodesInBulkLog);
    },

    redoInsertAudioOrVideoNodesBulk: function(sequencerOperationInsertAudioOrVideoNodesInBulkLog){
        this._insertAudioOrVideoNodesInBulkOperation.redo(sequencerOperationInsertAudioOrVideoNodesInBulkLog);
    },


    getAllLoadedTranscribedFilesWithoutEnhancedCounterpart : async function(){
        const arrayToReturn = await this._transcribedAudioFiles.reduce((async function (urlWihoutCounterPartArrayPromise, transcribedFileInfo){
            const urlWihoutCounterPartArray = await urlWihoutCounterPartArrayPromise;
            const enhancedVersionCounterPartUrl =  await this.getAudioBufferCache().getEnhancedSpeechVersionUrlForMediaFile(transcribedFileInfo.audioFileUrl);
            if(!enhancedVersionCounterPartUrl){
                urlWihoutCounterPartArray.push(transcribedFileInfo.audioFileUrl);
            }
            return urlWihoutCounterPartArray;
        }).bind(this),[]);
        return arrayToReturn;
    },

    triggerSpeechEnhancementForTranscribedUrlsAndToggleEnhancementSettingsWhenReady : async function(audioUrls){
            TrebbleNotificationManager.createLoadingNotification(audioUrls[0],  null, window.getI18n(ti18n, "YOUR_FILE_IS_BEING_ENHANCED")+" " +window.getI18n(ti18n, "THIS_MIGHT_TAKE_A_FEW_MINUTES_WE_WILL_NOTIFY_YOU"),{ duration: 0 ,darkTheme: false,  placement:"bottomRight"});
            const transcribedFileInfoToEnhanceArray =  this._transcribedAudioFiles.reduce((transcribedFileInfoToEnhanceArray, transcribedFileInfo)=>{
            if(audioUrls.includes(transcribedFileInfo.audioFileUrl)){
                transcribedFileInfoToEnhanceArray.push(transcribedFileInfo);
            }
            return transcribedFileInfoToEnhanceArray;
        },[])
        await this.getAudioBufferCache().enhanceTranscribedFilesAndTunOnSetting(transcribedFileInfoToEnhanceArray, this);

    },

    setNoiseCancellation: function(on, doNoRecordInHistory){
        if(doNoRecordInHistory){
            this.getSequencerSettings().setNoiseCancellation(on);
        }else{
            this._turnNoiseCancellationOnOperation.execute(!this.getSequencerSettings().isNoiseCancellationOn());
            if(!this._warnedUserForFirstNoiseCancellation && this.getSequencerSettings().isNoiseCancellationOn()){
                this._warnedUserForFirstNoiseCancellation = true;
                this.trigger("firstNoiseCancellationTurnedOn")
            }
        }
    },

    toggleNoiseCancellation: function(){

        this._turnNoiseCancellationOnOperation.execute(!this.getSequencerSettings().isNoiseCancellationOn());
        if(!this._warnedUserForFirstNoiseCancellation && this.getSequencerSettings().isNoiseCancellationOn()){
            this._warnedUserForFirstNoiseCancellation = true;
            this.trigger("firstNoiseCancellationTurnedOn")
        }
    },

    setMagicSoundEnhancer: function(on, doNoRecordInHistory){
        if(doNoRecordInHistory){
            this.getSequencerSettings().setMagicSoundEnhancerOn(on);
        }else{
            this._turnMagicSoundEnhancerOnOperation.execute(!this.getSequencerSettings().isMagicSoundEnhancerOn());
            if(!this._warnedUserForFirstMagicSoundEnhancer && this.getSequencerSettings().isMagicSoundEnhancerOn()){
                this._warnedUserForFirstMagicSoundEnhancer = true;
                this.trigger("firstMagicSoundEnhancerTurnedOn")
            }
        }
    },

    toggleMagicSoundEnhancer: function(){

        this._turnMagicSoundEnhancerOnOperation.execute(!this.getSequencerSettings().isMagicSoundEnhancerOn());
        if(!this._warnedUserForFirstMagicSoundEnhancer && this.getSequencerSettings().isMagicSoundEnhancerOn()){
            this._warnedUserForFirstMagicSoundEnhancer = true;
            this.trigger("firstMagicSoundEnhancerTurnedOn")
        }
    },


    undoToggleNoiseCancellation : function(sequencerOperationTurnNoiseCancellationOnOperationLog){
        return this._turnNoiseCancellationOnOperation.undo(sequencerOperationTurnNoiseCancellationOnOperationLog);
    },

    redoToggleNoiseCancellation : function(sequencerOperationTurnNoiseCancellationOnOperationLog){
        return this._turnNoiseCancellationOnOperation.redo(sequencerOperationTurnNoiseCancellationOnOperationLog);
    },

    undoToggleMagicSoundEnhancer : function(sequencerOperationTurnMagicSoundEnhancerOnOperationLog){
        return this._turnMagicSoundEnhancerOnOperation.undo(sequencerOperationTurnMagicSoundEnhancerOnOperationLog);
    },

    redoToggleMagicSoundEnhancer : function(sequencerOperationTurnMagicSoundEnhancerOnOperationLog){
        return this._turnMagicSoundEnhancerOnOperation.redo(sequencerOperationTurnMagicSoundEnhancerOnOperationLog);
    },

    pasteClipboard : function(sequencerNodeToInsertNextTo, insertBefore){
        return this._insertNodeArrayOperation.execute(this._sequencerClipboard.getSequencerNodesInClipboard(), sequencerNodeToInsertNextTo, insertBefore);
    },

    undoInsertNodeArray: function(sequencerOperationInsertNodeArrayOperationLog){
        return this._insertNodeArrayOperation.undo(sequencerOperationInsertNodeArrayOperationLog);
    },

    redoInsertNodeArray: function(sequencerOperationInsertNodeArrayOperationLog){
        return this._insertNodeArrayOperation.redo(sequencerOperationInsertNodeArrayOperationLog);
    },

    cutAndCopyToClipboard : function(startSequencerNodeModel, endSequencerNodeModel){
        return this._cutAndCopyToClipboardOperation.execute(startSequencerNodeModel, endSequencerNodeModel);
    },

    undoCutAndCopyToClipboard: function(sequencerOperationCutAndCopyToClipboardOperationLog){
        return this._cutAndCopyToClipboardOperation.undo(sequencerOperationCutAndCopyToClipboardOperationLog);
    },

    redoCutAndCopyToClipboard: function(sequencerOperationCutAndCopyToClipboardOperationLog){
        return this._cutAndCopyToClipboardOperation.redo(sequencerOperationCutAndCopyToClipboardOperationLog);
    },


    setSequencerSettings : function(settingToChange, settingValue, silent){
        return this._sequencerSettingsChangeOperation.execute(settingToChange, settingValue, silent);
    },

    undoSequencerSettingChange: function(sequencerOperationSettingsChangeLog){
        return this._sequencerSettingsChangeOperation.undo(sequencerOperationSettingsChangeLog);
    },

    redoSequencerSettingChange: function(sequencerOperationSettingsChangeLog){
        return this._sequencerSettingsChangeOperation.redo(sequencerOperationSettingsChangeLog);
    },

    transcribeSequencerNode : async function(sequencerNode, languageCode, onTranscriptionStarted, onTranscriptionEnded, onReplacementStarted, onReplacementEnded){
        return this._transcribeAndReplaceOperation.execute(sequencerNode, languageCode, onTranscriptionStarted, onTranscriptionEnded, onReplacementStarted, onReplacementEnded);
    },

    undoTransribeAndReplaceSequencerNode : function(sequencerOperationTranscribeAndReplaceSequencerNodeLog){
        return this._transcribeAndReplaceOperation.undo(sequencerOperationTranscribeAndReplaceSequencerNodeLog);
    },

    redoTransribeAndReplaceSequencerNode : function(sequencerOperationTranscribeAndReplaceSequencerNodeLog){
        return this._transcribeAndReplaceOperation.redo(sequencerOperationTranscribeAndReplaceSequencerNodeLog);
    },

    deleteSequencerNode: function(sequencerNode){
        return this._deleteSequencerNodeOperation.execute(sequencerNode);
    },

    undoDeleteSequencerNode: function(sequencerOperationDeleteNodeLog){
        return this._deleteSequencerNodeOperation.undo(sequencerOperationDeleteNodeLog);
    },

    redoDeleteSequencerNode: function(sequencerOperationDeleteNodeLog){
        return this._deleteSequencerNodeOperation.redo(sequencerOperationDeleteNodeLog);
    },

    getLoadedProjectId : function(){
        return this._loadedProjectId;
    },

    loadProject: function(projectId, sequencerNodeToInsertNextTo, insertBefore, clearExistingProject, duplicateProject,  silent, progressReportFunction){
        this._loadedProjectId = projectId;
        return this._loadProjectOperation.execute(projectId, sequencerNodeToInsertNextTo, insertBefore, clearExistingProject, duplicateProject, silent, progressReportFunction);
    },

    undoLoadProject : function(sequencerOperationLoadProjectLog){
        return this._loadProjectOperation.undo(sequencerOperationLoadProjectLog);
    },

    redoLoadProject : function(sequencerOperationLoadProjectLog){
        return this._loadProjectOperation.redo(sequencerOperationLoadProjectLog);
    },

    correctTextOnSequencerNodes : function(arrayOfSequencerNodes, newTranscriptText){
        if(arrayOfSequencerNodes && arrayOfSequencerNodes.length === 1 && newTranscriptText && newTranscriptText.trim().length >0 ){
            this._correctTranscriptOnSingleSequencerNodeOperation.execute(arrayOfSequencerNodes[0], newTranscriptText);
        }
    },

    undoCorrectTextOnSequencerNodes : function(sequencerOperationCorrectTranscriptSingleSequencerNodeOperationLog){
        return this._correctTranscriptOnSingleSequencerNodeOperation.undo(sequencerOperationCorrectTranscriptSingleSequencerNodeOperationLog);
    },

    redoCorrectTextOnSequencerNodes : function(sequencerOperationCorrectTranscriptSingleSequencerNodeOperationLog){
        return this._correctTranscriptOnSingleSequencerNodeOperation.redo(sequencerOperationCorrectTranscriptSingleSequencerNodeOperationLog);
    },

    renameSpeakerInfo : function(speakerInfo, newModifiedSpeakerLabel){
        if(speakerInfo && newModifiedSpeakerLabel){
            return this._renameSpeakerNameSequencerOperation.execute(speakerInfo, newModifiedSpeakerLabel);
        }
    },

    undoRenameSpeakerInfo : function(sequencerOperationRenameSpeakerLabelLog){
        return this._renameSpeakerNameSequencerOperation.undo(sequencerOperationRenameSpeakerLabelLog);
    },

    redoRenameSpeakerInfo : function(sequencerOperationRenameSpeakerLabelLog){
        return this._renameSpeakerNameSequencerOperation.redo(sequencerOperationRenameSpeakerLabelLog);
    },

    createNewSpeakerInfoAndAssign : function(speakerLabel, paragraphContainerView, startSequencerNodeToAssign, endSequencerNodeToAssign){
        if(speakerLabel && paragraphContainerView && startSequencerNodeToAssign && endSequencerNodeToAssign){
            return this._createNewSpeakerAndAssignToSequencerNodesOperation.execute(speakerLabel, paragraphContainerView, startSequencerNodeToAssign, endSequencerNodeToAssign);
        }
    },

    undoCreateNewSpeakerInfoAndAssign : function(sequencerOperationCreateNewSpeakerAndAssignToSequencerNodesOperationLog){
        return this._createNewSpeakerAndAssignToSequencerNodesOperation.undo(sequencerOperationCreateNewSpeakerAndAssignToSequencerNodesOperationLog);
    },

    redoCreateNewSpeakerInfoAndAssign : function(sequencerOperationCreateNewSpeakerAndAssignToSequencerNodesOperationLog){
        return this._createNewSpeakerAndAssignToSequencerNodesOperation.redo(sequencerOperationCreateNewSpeakerAndAssignToSequencerNodesOperationLog);
    },

    changeSpeakerParagraph: function(paragraphSequencerNodeView, newSpeakerInfo, startSequencerNodeToChange, endSequencerNodeToChange){
        return this._changeSpeakerForParagraphOperation.execute(paragraphSequencerNodeView, newSpeakerInfo, startSequencerNodeToChange, endSequencerNodeToChange);
    },

    undoChangeSpeakerParagraph: function(sequencerOperationChangeSpeakerForParagraphOperationLog){
        return this._changeSpeakerForParagraphOperation.undo(sequencerOperationChangeSpeakerForParagraphOperationLog);
    },

    redoChangeSpeakerParagraph: function(sequencerOperationChangeSpeakerForParagraphOperationLog){
        return this._changeSpeakerForParagraphOperation.redo(sequencerOperationChangeSpeakerForParagraphOperationLog);
    },

    


    getRenderedAudioBlob : function(renderWavFile, asVideo, progressReportFunction, alreadyRenderedAudioWAVBlob){
        return SequencerRendererHelper.getInstance().getRenderedAudioBlob(this, true, renderWavFile, asVideo, progressReportFunction, alreadyRenderedAudioWAVBlob)
    },

    getRenderedAudioBlobClip : function(renderWavFile, sequencerNodeToStartFrom, sequencerNodeToEndOn, asVideo, progressReportFunction, alreadyRenderedAudioWAVBlob, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible){
        return SequencerRendererHelper.getInstance().getRenderedAudioBlobClip(this, true, renderWavFile, sequencerNodeToStartFrom, sequencerNodeToEndOn,  asVideo, progressReportFunction, alreadyRenderedAudioWAVBlob,  shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible);
    },

    getSrtText : function(sequencerNodeToStartFrom, sequencerNodeToEndOn, progressReportFunction, asVtt, doNotIncludeSpeaker, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible){
        return SequencerRendererHelper.getInstance().getSrtText(this,sequencerNodeToStartFrom, sequencerNodeToEndOn, progressReportFunction, asVtt, doNotIncludeSpeaker, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible);
    },

    getTranscriptionAndEditsInfo : function(sequencerNodeToStartFrom, sequencerNodeToEndOn, progressReportFunction, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible){
        return SequencerRendererHelper.getInstance().getTranscriptionAndEditsInfo(this,sequencerNodeToStartFrom, sequencerNodeToEndOn, progressReportFunction, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible);
    },

    createFFMPEGParamsToRenderVideoByContatenation : function(applyMasterProcessing, audioUrl,sequencerNodeToStartFrom, sequencerNodeToEndOn, srtFileUrl ){
        return SequencerRendererHelper.getInstance().createFFMPEGParamsToRenderVideoByContatenation(this, applyMasterProcessing, sequencerNodeToStartFrom? sequencerNodeToStartFrom : this.getFirstNode(), sequencerNodeToEndOn? sequencerNodeToEndOn: this.getLastNode(), audioUrl, srtFileUrl);
    },

    createFFMPEGParamsByContatenationForServerRendering : function(applyMasterProcessing,sequencerNodeToStartFrom, sequencerNodeToEndOn , audioUrl, maxDurationInMilliseconds){
        return SequencerRendererHelper.getInstance().createFFMPEGParamsByContatenationForServerRendering(this, applyMasterProcessing, sequencerNodeToStartFrom? sequencerNodeToStartFrom : this.getFirstNode(), sequencerNodeToEndOn? sequencerNodeToEndOn: this.getLastNode(), null, maxDurationInMilliseconds, audioUrl);
    },

    isSequencerNodeVisible : function(sequencerNode){
        if(Utils.getInstance().isPunctuationSequencerNodeInstance(sequencerNode)){
            return false;
        }
        if(Utils.getInstance().isDeletedSequencerNodeInstance(sequencerNode)){
            if(!this.getSequencerSettings().isShowIgnoredContent()){
                return false;
            }
        }
        if(Utils.getInstance().isPauseSequencerNodeInstance(sequencerNode)){
            if(!this.getSequencerSettings().isShowGapsBetweenWords()){
                return false;
            }
            if(DO_NOT_SHOW_NON_EXPLICITELY_DISPLAYED_PAUSE){
                if(sequencerNode.getAudioSegment() && sequencerNode.getAudioSegment().isExplicitlyDisplayed()){
                    return false;
                }
            }
        }
        return true;
    },

    showNotDisplayedNotExplicitelyDisplayedPause : function(){
        return !DO_NOT_SHOW_NON_EXPLICITELY_DISPLAYED_PAUSE;
    },

    getAllSequencerNodesInSequencer : function(){
        return this._collection.models;
    },

    getNumberOfVisibleSequencerNodes : function(){
        return this._collection.models.reduce((function(numberOfVisibleSequencerNodesCount, sequencerNode){
            return this.isSequencerNodeVisible(sequencerNode)? numberOfVisibleSequencerNodesCount+1: numberOfVisibleSequencerNodesCount;
        }).bind(this), 0)
    },

    getHistoryManagement :function(){
        return this._historyManagement;
    },

    isPlaying : function(){
        return SequencerRendererHelper.getInstance().isPlaying(this);
    },

    updatePlaybackPositionInMilliseconds : function(timeInMilliseconds){
        if(SequencerRendererHelper.getInstance().isPlaying(this)){
            return this.playSequencerRenderingFromPositionInMilliseconds(timeInMilliseconds);
        }else{
            this._playbackProgressInMillisecondsBeforePausing = timeInMilliseconds;
        }
    },

    playPauseSequencerRenderingFromNode : function(nodeToPlayFrom, forcePlay, stopAfterMilliseconds, nodeToStopPlayAt, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible){
        this.getAudioBufferCache().resumeAudiContextToKeepHeartbeat();
        if(SequencerRendererHelper.getInstance().isPlaying(this)){
            this._playbackProgressInMillisecondsBeforePausing = this.getPlaybackProgressInMilliseconds();
        }
        
        const startTime = new Date();
        const playSequencerPromise = forcePlay? SequencerRendererHelper.getInstance().renderAndPlay(this, nodeToPlayFrom, this._masterProcessingTurnedOn, stopAfterMilliseconds, nodeToStopPlayAt, null, window.chrome && EDIT_VIDEO_FILES && this.getHasVideoSegment()? this.getVideoContext(): null, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible): SequencerRendererHelper.getInstance().playPause(this, nodeToPlayFrom, this._masterProcessingTurnedOn, stopAfterMilliseconds, nodeToStopPlayAt, null, window.chrome && EDIT_VIDEO_FILES && this.getHasVideoSegment()? this.getVideoContext(): null, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible);
        return playSequencerPromise.then((function(){
            //Utils.getInstance().showTrebbleReadyNotification("It took "+((new Date().getTime() - startTime.getTime())/1000 )+"seconds to generate the audio")
        }).bind(this));
        
    },
    
    setAndLoadProject: function(projectId, duplicateProject, progressReportFunction){
        return this.loadProject(projectId, null, null, true, duplicateProject, true, progressReportFunction);
    },

    rerenderAndPlayIfApplicable :function(){
        if(SequencerRendererHelper.getInstance().isPlaying(this) || this._rerenderAndPlayInProgressPromise){
            if(!this._rerenderAndPlayInProgressPromise){
                this._rerenderAndPlayInProgressPromise  = RSVP.Promise.resolve();
                this._playbackProgressInMillisecondsBeforePausing = this.getPlaybackProgressInMilliseconds();
            }
            this._rerenderAndPlayInProgressPromise = this._rerenderAndPlayInProgressPromise.then((function(){
                if(SequencerRendererHelper.getInstance().isPlaying(this)){
                    return SequencerRendererHelper.getInstance().stopPlayingLatestRenderedBuffer(this);
                }
            }).bind(this)).then((function(){
                return this.playSequencerRenderingFromPositionInMilliseconds(this._playbackProgressInMillisecondsBeforePausing);
            }).bind(this)).then((function(result){
                this._rerenderAndPlayInProgressPromise = null;
                return result;
            }).bind(this));
            return this._rerenderAndPlayInProgressPromise;
        }else{
            return RSVP.Promise.resolve();
        }
    },

    closeAudioContext : async function(){
        return await this.getAudioBufferCache().closeAudioContext();
    },

    clearSequencer :function(clearBlobCache){
        const promises = [];
        this.getAudioBufferCache().unloadAllUrlsUsedBySequencer(this, clearBlobCache);
        if(this._transcribedAudioFiles && this._transcribedAudioFiles.length > 0){
            for(let i =0; i < this._transcribedAudioFiles.length; i++){
                const transcribeAudioFileInfo = this._transcribedAudioFiles[i];
                SequencerLibrary.getInstance().clearCachedTranscribeAudio(transcribeAudioFileInfo.audioFileUrl)
                promises.push(this.getAudioBufferCache().unloadAudioBufferFromUrl(transcribeAudioFileInfo.audioFileUrl, transcribeAudioFileInfo.audioFileUrl, AudioBufferHelper.getInstance().getAudioTypes().VOICE));
            }
        }

        this.getHistoryManagement().clear();
        this.getSequencerClipboard().clear();
        this.getSequencerSettings().clear();

        
        
        this._collection.reset();
        this._firstNode = null;
        this._lastNode = null;
        this._cidToSegmentNodeMap = {};
        this._warnedUserForFirstNoiseCancellation = false;
        this._warnedUserForFirstMagicSoundEnhancer = false;
        this.trigger("sequencerBeingCleared", this);

        return RSVP.Promise.all(promises);
    },

    isSequencerPossiblyCleared : function(){
        return !this._cidToSegmentNodeMap && Object.keys(this._cidToSegmentNodeMap).length === 0;
    },

    getSerializedData : function(){
        if(this.getFirstNode()){
            const jsonState = {};
            jsonState.authorId = LocalStorageHelper.getInstance().getLoggedInUserID();
            const cidToCloneSequencerNodes = {};
            const cloneFirstNode = SequencerNodeCloner.getInstance().cloneLinkedSequencerNodes(this.getFirstNode(),this.getLastNode(), cidToCloneSequencerNodes);
            const cloneCidToSequencerNodeSerializedData = {};
            const trebbleAudioIdAndTypeToAudioFiledata = {};
            const audioFilesDataArray = []
            for(let cid in cidToCloneSequencerNodes){
                const cloneSequencerNode = cidToCloneSequencerNodes[cid];
                const sequencerNodeSerializedData = cloneSequencerNode.getSerializedData();
                delete sequencerNodeSerializedData.sequencer;
                cloneCidToSequencerNodeSerializedData[sequencerNodeSerializedData._cid] = sequencerNodeSerializedData;
                if(cloneSequencerNode.getAudio && cloneSequencerNode.getAudio()){
                    const audioIdAndType = cloneSequencerNode.getAudio().getAudioType()+ ":"+cloneSequencerNode.getAudio().getAudioId();
                    if(!trebbleAudioIdAndTypeToAudioFiledata[audioIdAndType]){
                        const audioSerialiazedData = cloneSequencerNode.getAudio().toJSON();
                        trebbleAudioIdAndTypeToAudioFiledata[audioIdAndType]  = audioSerialiazedData;
                        audioFilesDataArray.push(audioSerialiazedData);
                    }

                }
            }

            jsonState.firstNodeCid = cloneFirstNode.cid;
            jsonState.cloneCidToSequencerNodeSerializedData = cloneCidToSequencerNodeSerializedData;
            jsonState.sequencerSettings  = this._sequencerSettings.getSerializedData();
            jsonState.transcribedAudioFiles = this._transcribedAudioFiles;
            jsonState.otherTrebbleAudioFiles = audioFilesDataArray;
            jsonState.textTranscription = this.getSelectedNodesTextContent(this.getFirstNode(), this.getLastNode(), true);
            jsonState.additionalSpeakerInfoArray = this._additionalSpeakerInfoArray.map((speakerInfo)=>{return speakerInfo.getSerializedData()});
            return jsonState;
        }
    },

    getSelectedNodesTextContent: function(startNode, endNode, includeSpeaker){
        let textContent = "";
        if(!startNode && !endNode){
            startNode =  this.getFirstNode();
            endNode =  this.getLastNode();
        }
        let sequencerNode = startNode;
        let currentSpeakerLabel = null;
        const UNKNOWN_SPEAKER_LABEL  = "Unknown";
        while(sequencerNode){
            if(Utils.getInstance().isWordSequencerNodeInstance(sequencerNode)){
                if(includeSpeaker && currentSpeakerLabel !== sequencerNode.getAudioSegment().getSpeakerLabel()){
                    currentSpeakerLabel = sequencerNode.getAudioSegment().getSpeakerLabel() || UNKNOWN_SPEAKER_LABEL;
                    textContent += "\n\n"+currentSpeakerLabel+": ";
                }
                if(textContent === ""){
                    textContent = sequencerNode.getAudioSegment().getContent();
                }else{
                    textContent =  textContent + " " + sequencerNode.getAudioSegment().getContent();
                }

            }
            if(sequencerNode === endNode){
                sequencerNode = null;
            }else{
                sequencerNode =  sequencerNode.getNextVisibleSequencerNode();
            }
        }
        return textContent;
    },

    getJSONDescriptionForAIAnalysis : function(startNode, endNode, includeSpeaker){
        const nodeJsonDescriptionArray = [];
        if(!startNode && !endNode){
            startNode =  this.getFirstNode();
            endNode =  this.getLastNode();
        }
        let sequencerNode = startNode;
        let currentSpeakerLabel = null;
        const UNKNOWN_SPEAKER_LABEL  = "Unknown";
        while(sequencerNode){
            const nodeJsonDescription = sequencerNode.getJSONDescriptionForAIAnalysis();
            if(nodeJsonDescription){
                nodeJsonDescriptionArray.push(nodeJsonDescription);
            }
            if(sequencerNode === endNode){
                sequencerNode = null;
            }else{
                sequencerNode =  sequencerNode.getNextVisibleSequencerNode();
            }
        }
        return nodeJsonDescriptionArray;
    },


    setProjectId : function(projectId){
        const oldProjectId = this._projectId;
        this._projectId = projectId;
        if(oldProjectId !== projectId){
            this.trigger("projectIdChange",this._projectId);
        }
    },

    getProjectId : function(){
        return this._projectId;
    },

    stopPlaybackIfApplicable : function(){
        if(SequencerRendererHelper.getInstance().isPlaying(this)){
            this._playbackProgressInMillisecondsBeforePausing = this.getPlaybackProgressInMilliseconds();
            return SequencerRendererHelper.getInstance().stopPlayingLatestRenderedBuffer(this);
        }	
    },

    playPauseSequencerRendering : function(){
        this.getAudioBufferCache().resumeAudiContextToKeepHeartbeat();
        if(SequencerRendererHelper.getInstance().isPlaying(this)){
            return this.stopPlaybackIfApplicable();
        }else{
            return this.playSequencerRenderingFromPositionInMilliseconds(this._playbackProgressInMillisecondsBeforePausing);
        }
    },

    stopPlayingSequencerRenderingAndSuspendAudioContextForStalledPlayback: function(){
        this.getAudioBufferCache().suspendAudioContextInStalledPlayback();
        return SequencerRendererHelper.getInstance().stopPlayingLatestRenderedBuffer(this);
    },

    restartPlayingSequencerRenderingAndSuspendAudioContextForStalledPlayback: function(){
        const playbackProgressInMilliseconds = this.getPlaybackProgressInMilliseconds();
        this.getAudioBufferCache().suspendAudioContextInStalledPlayback();
        return SequencerRendererHelper.getInstance().stopPlayingLatestRenderedBuffer(this).then((function(){
            this.playSequencerRenderingFromPositionInMilliseconds(playbackProgressInMilliseconds);
        }).bind(this));
    },

    playSequencerRenderingFromPositionInMilliseconds: function(timeInMilliseconds){
        this.getAudioBufferCache().resumeAudiContextToKeepHeartbeat();
        this._playbackProgressInMillisecondsBeforePausing = timeInMilliseconds;
        const startTime = new Date();
        return SequencerRendererHelper.getInstance().playFromTimeInMilliseconds(this, timeInMilliseconds, this._masterProcessingTurnedOn, null, window.chrome && EDIT_VIDEO_FILES && this.getHasVideoSegment()? this.getVideoContext(): null).then((function(){
            //Utils.getInstance().showTrebbleReadyNotification("It took "+((new Date().getTime() - startTime.getTime())/1000 )+"seconds to generate the audio")
        }).bind(this));
    },

    getSearchWidget : function(){
        return this._sequencerSearchWidget;
    },

    setSearchOn: function(searchOn){
        this._sequencerSearchWidget.setSearchOn(searchOn);
        this.setRerenderOnNextPlayback(true);
    },

    isInSearchMode : function(searchOn){
        return this._sequencerSearchWidget.isSearchOn();
    },

    applyTextSearch : function(searchKeyword){
        this._sequencerSearchWidget.applyTextSearch(searchKeyword);
    },

    setPlaybackOn : function(playbackOn){
        this._sequencerSearchWidget.setPlaybackOn(playbackOn);
    },

    clearSearch: function(){
        this._sequencerSearchWidget.clearSearch();
    },

    showNextSequencerNodeFoundInSearch: function(){
        this._sequencerSearchWidget.moveToNextFoundNode();
    },

    showPreviousSequencerNodeFoundInSearch: function(){
        this._sequencerSearchWidget.moveToPreviousFoundNode();
    },

    resetPlaybackPositionOnAudioPlaybackCompleted : function(){
        this._playbackProgressInMillisecondsBeforePausing = 0;
    },

    setRenderedBufferPlaying : function(isPlaying){
        this.set("renderedBufferPlaying", isPlaying)
    },

    setRenderingBeingCreated : function(renderingBeingCreated){
        if(renderingBeingCreated){
            this.setRerenderOnNextPlayback(false);
        }
        this.set("renderingBeingCreated", renderingBeingCreated)
    },

    setRenderedBufferPlaybackLoading : function(renderedBufferPlaybackLoading){
        this.set("renderedBufferPlaybackLoading", renderedBufferPlaybackLoading)
    },

    isRenderingBeingCreated : function(){
        return this.get("renderingBeingCreated");
    },

    isRenderedBufferPlaying : function(){
        return this.get("renderedBufferPlaying")
    },	

    isRenderedBufferPlaybackLoading : function(){
        return this.get("renderedBufferPlaybackLoading")
    },

    getDurationInMilliseconds : function(){
        return SequencerRendererHelper.getInstance().getDuration(this);
    },

    getPlaybackProgressInMilliseconds : function(){
        return SequencerRendererHelper.getInstance().getPlaybackProgress(this);
    },

    getPlaybackAudioContextCurrentTime: function(){
        return SequencerRendererHelper.getInstance().getPlaybackAudioContextCurrentTime(this);
    },

    canPlayThrough: function(){
        return SequencerRendererHelper.getInstance().canPlayThrough(this);
    },

});
window.trebble.globalHelpers.Sequencer = Sequencer;
export default Sequencer; 