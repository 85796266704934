import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import noUiSlider from "nouislider";
import BasicInspectorNodeParamView from "views/audioEditor/sequencerNodeInspectorSection/paramView/BasicInspectorNodeParamView";
import SliderParamTemplate from 'text!../../../../../templates/audioEditor/sequencerNodeInspectorSection/paramView/SliderParamTemplate.html';
import RSVP from 'rsvp';


const SliderInputParamView = BasicInspectorNodeParamView.extend({

    constructor : function(attributes, options) {
        BasicInspectorNodeParamView.apply(this, [attributes, options]);
        this.compiledTemlate =  _.template(SliderParamTemplate);
        this._getDisplayedLabelFromValueFunction =  attributes.getDisplayedLabelFromValueFunction;
        this._getValueFromDisplayedLabelFunction = attributes.getValueFromDisplayedLabelFunction;
        this._maxValue = null;
        this._minValue = null;
    },			

    setMaxValue : function(maxValue){
        this._maxValue = maxValue;
        if(this.maxValueContainer$el){
            this.maxValueContainer$el.html(this.getDisplayedLabelFromValue(this.getMaxValue()));
        }
    },

    getMaxValue : function(){
        return this._maxValue;
    },

    setStep : function(step){
        this._step =  step;
    },

    getStep: function(){
        return this._step;
    },

    setMinValue : function(minValue){
        this._minValue = minValue;
        if(this.minValueContainer$el){
            this.minValueContainer$el.html(this.getDisplayedLabelFromValue(this.getMinValue()));
        }
    },

    getMinValue : function(){
        return this._minValue;
    },

    
    updateViewOnValueChange : function(newValue){
        if(this._sliderEl && this._sliderEl.noUiSlider &&  newValue >= this.getMinValue()  && newValue <= this.getMaxValue()){
            this._sliderEl.noUiSlider.set(newValue);
        }
    },

    _initSlider : function(){
        const sliderParams = {
            start: 0,
            connect: [true, false],
            behaviour :"tap-drag",
            orientation: 'horizontal', // 'horizontal' or 'vertical'
            step: this.getStep(),
            range: {
                'min': this.getMinValue(),
                'max': this.getMaxValue()
            },
            tooltips: true
        };
        if(this._getDisplayedLabelFromValueFunction && this._getValueFromDisplayedLabelFunction){
            sliderParams.format = {"to": this._getDisplayedLabelFromValueFunction, "from": this._getValueFromDisplayedLabelFunction};
        }
        
        this._noUiSliderObj = noUiSlider.create(this._sliderEl,sliderParams );
        this._sliderEl.noUiSlider.on('change',this._onUserSliderPosition.bind(this));
        this.sliderValue$el.html(this.getDisplayedLabelFromValue(this.getValue()));
        this._sliderEl.noUiSlider.set(this.getDisplayedLabelFromValue(this.getValue()));
    },

    _onUserSliderPosition: function(formatedValues, handleNumber, unformattedValues ){
        const newValue = unformattedValues && unformattedValues.length > 0? unformattedValues[0]: this._sliderEl.noUiSlider.get();
        this.sliderValue$el.html(this.getDisplayedLabelFromValue(newValue));
        this.onViewValueChanged(newValue);
    },

    getDisplayedLabelFromValue : function(value){
        return this._getDisplayedLabelFromValueFunction? this._getDisplayedLabelFromValueFunction(value): value;
    },


    render : function(){
        this.$el.html(this.compiledTemlate({ "viewObj": this}));
        this.setElement(this.$el.find(".basic_inpector_param_view").first());
        this.sliderContainer$el = this.$el.find(".slider_container");
        this.sliderValue$el = this.$el.find(".slide_value");
        this.minValueContainer$el = this.$el.find(".min_value");
        this.maxValueContainer$el = this.$el.find(".max_value");
        this.minValueContainer$el.html(this.getDisplayedLabelFromValue(this.getMinValue()));
        this.maxValueContainer$el.html(this.getDisplayedLabelFromValue(this.getMaxValue()));
        this._sliderEl = this.sliderContainer$el.get(0);
        this._initSlider();
    }
});
export default SliderInputParamView;