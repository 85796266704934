import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import ti18n from "i18n!nls/helperi18n";
import RSVP from "rsvp";

const mediaStatuses = {
    
    PLAYING : 2,
    PAUSED : 3,
    STOPPED : 4,

};

const SOUNDCLOUD_URI_PREFIX = "soundcloud:";
const MediaSoundcloud =  Backbone.Model.extend({
    
    "default": {
        "DEFAULT_MEDIA_STATUS" :mediaStatuses,
        "src": "",
        "soundObject" :"",
        "mediaSuccess": "",
        "mediaError": "",
        "mediaStatus": "",
        
    },

    initialize : function(options) {
        this.src = options.uri;
        this.onMediaSuccess = options.onSuccess;
        this.onMediaError = options.onError;
        this.onMediaStatus = options.onStatus;
        this.onMediaFailedLoading = options.onFailedLoading;
        this.soundObjectIsReadyPromise = new RSVP.Promise((function(resolve,reject){
            try{
                /*SC.initialize({
                    client_id: 'e1ddc5a48279cd6d15c35595c26c7b05'
                });
SC._baseUrl = "http://connect.soundcloud.com";*/
const onStreamCompleted = function(soundObject){
this.soundObject = soundObject;
if(this.soundObject)
{
//success
/*this.soundObject.load(
{
    onplay : this._onSongPlay.bind(this),
    onfinish : this._onSongFinishedPlaying.bind(this),
    onresume : this._onSongResume.bind(this),
    onstop : this._onSongStop.bind(this),
    onload: this._onSongReadyToPlay.bind(this),
    onpause : this._onSongpause.bind(this),
    whileloading : this._onSongDownloading.bind(this),
    onid3 : this._onLoadedmetadata.bind(this),
    
});*/
this.soundObject.on("play-start",this._onSongPlay.bind(this));
this.soundObject.on("play-resume",this._onSongResume.bind(this));
this.soundObject.on("finish",this._onSongFinishedPlaying.bind(this));
this.soundObject.on("pause",this._onSongpause.bind(this));
this.soundObject.on("buffering_start",this._onSongDownloading.bind(this));
this.soundObject.on("audio_error",this._triggerError(window.getI18n(ti18n, "SOMETHING_WENT_WRONG_DURING_THE_PLAYBACK")));
this.soundObject.on("no_streams",this._triggerError(window.getI18n(ti18n, "FAILED_FETCHING_STREAM_INFO")));
this.soundObject.on("no_protocol",this._triggerError(window.getI18n(ti18n, "NO_SUPPORTED_PROTOCOL_COULD_BE_FOUND")));
this.soundObject.on("no_connection",this._triggerError(window.getI18n(ti18n, "FAILED_TO_CONNECT")));
this.soundObject.on("geo_blocked",this._triggerError(window.getI18n(ti18n, "THIS_AUDIO_IS_NOT_ALLOWED_TO_PLAY_IN_YOUR_LOCATION")));
this._onLoadedmetadata();
this._onSongReadyToPlay(true);
resolve(this.soundObject);
}else{
//failure
reject(window.getI18n(ti18n, "SOUND_OBJECT_COULDNT_BE_CREATED"));
}

};
window.SC.stream("/tracks/"+this.src).then((function(player){
onStreamCompleted.call(this, player);
}).bind(this)).catch((function(error){
reject(error);
}).bind(this));

}catch(err){
reject(err);
}

}).bind(this));



},

_triggerError : function(errorMessage){
return (function(){
this._loadingError = errorMessage;
console.error(errorMessage);
this.onMediaError(errorMessage, this);
}).bind(this);
},

_getSoundObject : function(){
if(this.soundObject)
{
return new RSVP.resolve(this.soundObject);
}else{
return this.soundObjectIsReadyPromise;
}
},

isLoading : function(){
return this._loading;
},

getLoadingError : function(){
return this._loadingError;
},

play: function(){
if(!this._released ){
if(this.soundObject)
{

    this._loading = false;
    this.trigger("loaded");
    this.soundObject.play();
    this._isWaitingForSongToBeReadyToPlay = false;
    if(!this._gettingReadyToPlayPromise){
        const gettingReadyToPlayPromise = new RSVP.Promise((function(resolve, reject){
            this._gettingReadyToPlayPromiseResolve = resolve;
            this._gettingReadyToPlayPromiseReject = reject;
        }).bind(this));
        this._gettingReadyToPlayPromise = gettingReadyToPlayPromise;
    }
    
}else{
    this.soundObjectIsReadyPromise = this.soundObjectIsReadyPromise.then((function(){
        //this.soundObject.play();
        if(!this._released ){
            this._loading = false;
            this.trigger("loaded");
            this.soundObject.play();
            this._isWaitingForSongToBeReadyToPlay = false;
            if(!this._gettingReadyToPlayPromise){
                const gettingReadyToPlayPromise = new RSVP.Promise((function(resolve, reject){
                    this._gettingReadyToPlayPromiseResolve = resolve;
                    this._gettingReadyToPlayPromiseReject = reject;
                }).bind(this));
                this._gettingReadyToPlayPromise = gettingReadyToPlayPromise;
            }
        }
    }).bind(this));
}
}

},

pause: function(){
if(!this._released ){
if(this.soundObject)
{
    this.soundObject.pause();
}else{
    this.soundObjectIsReadyPromise = this.soundObjectIsReadyPromise.then((function(){
        if(!this._released ){
            this.soundObject.pause();
        }	
    }).bind(this));
}
if(this._gettingReadyToPlayPromise){
    this._gettingReadyToPlayPromise.then((function(){
        if(this.soundObject){
            this.soundObject.pause();
        }
    }).bind(this));
}
}
},


stop: function(){
if(!this._released ){
if(this.soundObject)
{
    this.soundObject.pause();
    this.soundObject.seek(0);
    this._onSongStop();
}else{
    this.soundObjectIsReadyPromise = this.soundObjectIsReadyPromise.then((function(){
        if(!this._released ){
            this.soundObject.pause();
            this.soundObject.seek(0);
            this._onSongStop();
        }
    }).bind(this));
}
if(this._gettingReadyToPlayPromise){
    this._gettingReadyToPlayPromise.then((function(){
        if(this.soundObject){
            this.soundObject.pause();
            this.soundObject.seek(0);
            this._onSongStop();
        }
    }).bind(this));
}
}

},

release: function(){
if(this.soundObject)
{
this.soundObject.pause();
this.soundObject.seek(0);
}else{
this.soundObjectIsReadyPromise = this.soundObjectIsReadyPromise.then((function(){
    this.soundObject.pause();
    this.soundObject.seek(0);
}).bind(this));
}
this._released = true;
this._isWaitingForSongToBeReadyToPlay = false;
if(this._gettingReadyToPlayPromise){
this._gettingReadyToPlayPromise.then((function(){
    if(this.soundObject){
        this.soundObject.pause();
        this.soundObject.seek(0);
    }
}).bind(this));
}
},


seekTo: function(position){
if(!this._released ){
if(this.soundObject)
{
    this.soundObject.seek(position);
}else{
    this.soundObjectIsReadyPromise = this.soundObjectIsReadyPromise.then((function(){
        if(!this._released ){
            this.soundObject.seek(position);
        }
    }).bind(this));
}
}

},

startRecord: function(position){
window.alertErrorMessage(window.getI18n(ti18n, "NOT_SUPPORTED_YET"));
},

stopRecord: function(position){
window.alertErrorMessage(window.getI18n(ti18n, "NOT_SUPPORTED_YET"));
},

getDuration: function(position){
window.alertErrorMessage(window.getI18n(ti18n, "NOT_SUPPORTED_YET"));
},

getCurrentPosition: function(onSuccess, onFailure){
if(this.soundObject)
{
if(this.soundObject.currentTime())
{
    onSuccess(this.soundObject.currentTime()/1000);
}else{
    onSuccess(0);
}
}else{
onFailure(window.getI18n(ti18n, "COULDNT_FIND_THE_AUDIO_CURRENT_TIME_PROGRES"));
}
},

_onSongFinishedPlaying : function(){
this.onMediaSuccess();
},

_onSongResume : function(){
this.onMediaStatus(mediaStatuses.PLAYING);
window.log("Song resumed");
},

_onSongPlay : function(){
this.onMediaStatus(mediaStatuses.PLAYING);
try{
if(this._gettingReadyToPlayPromise){
    this._gettingReadyToPlayPromiseResolve();
    this._gettingReadyToPlayPromise = null;
}
}catch(error){
console.error(error);
this._gettingReadyToPlayPromise = null;
}
if(this._released){
if(this.soundObject){
    this.soundObject.pause();
    this.soundObject.seek(0);
}
}

},

_onSongDownloading :  function(){
if(  this.soundObject && this.soundObject.buffered  && this.soundObject.buffered.length > 0 && this.soundObject.buffered[0].end > 0 && !this._isFirstLoadingEvent ){
//TODO: THIS HACK WAS ADDED TO RESOLVE A PRESUMABLY BUG WITH SOUNDMANAGER
this._isFirstLoadingEvent = true;
window.log("first loading");
return;
}
if( this.soundObject && this.soundObject.buffered && this.soundObject.buffered.length > 0){
const percentageDownloaded = 100 *  this.soundObject.buffered[0].end/ this.soundObject.duration;
window.log("video progress "+percentageDownloaded);
this.trigger("downloading",percentageDownloaded);
}

},


_onLoadedmetadata : function(){
window.log("Loaded Video Meta data. ");
this.trigger("loadedMetaData");
},

_onSongStop : function(){
this.onMediaStatus(mediaStatuses.STOPPED);
window.log("Song Stopped");
},
_onSongReadyToPlay: function(isReady){
if(isReady){
if(this._isWaitingForSongToBeReadyToPlay){
    this._loading = false;
    this.trigger("loaded");
    this.play();
    this._isWaitingForSongToBeReadyToPlay = false;
}else{
    //do nothing
}
}else{
if(this.soundObject){
    this.soundObject.pause();
    this.soundObject.seek(0);
}
this._loading = false;
this._loadingError = window.getI18n(ti18n, "AUDIO_FAILED_LOADING");
this.trigger("failedLoading",window.getI18n(ti18n, "AUDIO_FAILED_LOADING"));
console.error("Soundcloud Failed Loading");
if(this.onMediaFailedLoading){
    //call handler for failed loading
    this.onMediaFailedLoading(SOUNDCLOUD_URI_PREFIX + this.src);
}else{
    //act like the song finish playing,
    //player will probably just play the next song
    this.onMediaSuccess();
}

}
if(this.soundObject && this.soundObject.duration){
this.trigger("durationChange",this.soundObject.duration);
}
},

_onSongpause : function() {
this.onMediaStatus(mediaStatuses.PAUSED);
window.log('Song Paused');
},


});
export default MediaSoundcloud;