import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import OptionPopupView from "views/common/OptionPopupView";
import RSVP from "rsvp";
import ti18n from "i18n!nls/CapsuleCardi18n";
import popupTemplate from 'text!../../../templates/journey/OutlineItemOptionTemplate.html';

const OutlineItemOptionView = OptionPopupView.extend({

    


    initialize : function(options) {
        const viewOptions =  {};
        this._outlineItemModel = options.model;
        this.elementSource$el = options.elementSource$el;
        const contentView = {};
        const contentViewTemplate = _.template(popupTemplate);
        const templateParams = {"model" : this._outlineItemModel, "ti18n":ti18n, "defaultImageUrl": options.defaultImageUrl?options.defaultImageUrl: null};
        contentView.$el  = $(contentViewTemplate(templateParams));
        viewOptions.contentView = contentView;
        viewOptions.contentPosition = "center";
        viewOptions.elementSource$el = this.elementSource$el;
        viewOptions.id = "PersonalTrebbleOptionPopup";
        viewOptions.hideHeading = true;
        viewOptions.showAsMenuIfInBrowser = true;
        OptionPopupView.prototype.initialize.call(this, viewOptions);
    },
    
    afterRendering : function(){
        this.$el.find("#removeFromOutlineBtn").click(this._onRemoveFromOutlineBtnClicked.bind(this));
        this.$el.find("#viewStatsBtn").click(this._onViewStatsButtonClicked.bind(this));
        this.$el.find("#likeBtn").click(this._onLikeBtnClicked.bind(this));
        this.$el.find("#showDetailsBtn").click(this._onShowDetailsBtnClicked.bind(this));
        this.$el.find("#editDetailsBtn").click(this._onEditDetailsBtnClicked.bind(this));
        
    },
    
    _close : function(){
        /*const p = new RSVP.Promise((function(resolve, reject){
            try{
                    this.$el.on({
                        popupafterclose: (function() {
                            try{
                                resolve();
                            }catch(error){
                                reject(error);
                            }
                        
                 }).bind(this)});
            }catch(error){
                reject(error);
            }
        }).bind(this));
        this.$el.popup('close' );*/
        return this.close();
    },

    _onShareButtonClicked :function(){
        this._close().then((function(){
            this.trigger("share", this._trebbleModel);
        }).bind(this));
    },

    _onLikeBtnClicked : function(){
        this._close().then((function(){
            this.trigger("like", this._trebbleModel);
        }).bind(this));
    },

    _onShowDetailsBtnClicked: function(){
        this._close().then((function(){
            this.trigger("showDetails", this._trebbleModel);
        }).bind(this));
    },

    _onEditDetailsBtnClicked: function(){
        this._close().then((function(){
            this.trigger("editDetails", this._trebbleModel);
        }).bind(this));
    },
    
    
    _onRemoveFromOutlineBtnClicked : function(){
        this._close().then((function(){
            this.trigger("removeFromOutline", this._trebbleModel);
        }).bind(this));
    },
    

    _onViewStatsButtonClicked : function(){
        this._close().then((function(){
            this.trigger("viewStatistics", this._trebbleModel);
        }).bind(this));
    },
    
    
    onAfterPageShow : function(){

    },



});

export default OutlineItemOptionView;