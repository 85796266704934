import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import GroupParamsTemplate from 'text!../../../../../templates/audioEditor/sequencerNodeInspectorSection/GroupParamsTemplate.html';
import RSVP from 'rsvp';


const GroupParamsView = Backbone.View.extend({


    constructor : function(attributes, options) {
        Backbone.View.apply(this, [attributes, options]);
        this.compiledTemlate =  _.template(GroupParamsTemplate);
        this._paramRenderedViewArray = [];
    },


    setTitle : function(title){
        this._title =  title
        if(this.groupParamsTitle$el){
            this.groupParamsTitle$el.html(this._title);
        }
    },

    getTitle: function(){
        return this._title;
    },

    getSequencerNode : function(){
        return this._sequencerNode;
    },

    addRenderedParamView : function(renderedParamView){
        this._paramRenderedViewArray.push(renderedParamView);
        if(renderedParamView && renderedParamView.$el && this.paramsListContainer$el){
            this.paramsListContainer$el.append(renderedParamView.$el);
        }
    },

    getParamRenderedViewArray :function(){
        return this._paramRenderedViewArray;
    },

    
    render : function(){
        this.$el.html(this.compiledTemlate({"viewObj": this}));
        this.setElement(this.$el.find(".group_params").first());
        if(!this._paramRenderedViewArray){
            this._paramRenderedViewArray = [];
        }
        this.paramsListContainer$el = this.$el.find(".group_param_list_container");
        this.groupParamsTitle$el = this.$el.find(".group_params_title");
        for(let i =0; i < this._paramRenderedViewArray.length; i++){
            const paramView =  this._paramRenderedViewArray[i];
            this.paramsListContainer$el.append(paramView.$el);
        }
    }
    

});
export default GroupParamsView;