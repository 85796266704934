import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import TagItemView from "views/common/TagItemView";
import ti18n from "i18n!nls/Commoni18n";
import AllTagTemplate from 'text!../../../templates/common/allTagTemplate.html';
import RSVP from 'rsvp';

const ALL_OPTION_TAG_ID =  "#all";
const ALL_OPTION_TAG_LABEL =  window.getI18n(ti18n, "ALL");
const ALL_OPTION_TAG_VALUE =  "All";
const TAG_VIEW_SELECTED_CLASSNAME = "selected"
const AllTagItemView = TagItemView.extend({



    initialize : function(options) {
        this.compiledTemlate =  _.template(AllTagTemplate);
        this._selected = false;
            
    },
    
    events : {
        "click": "onClick",
    },

    onClick : function(){
        this.toggleSelected();
    },
    
    toggleSelected : function(silent){
        this.setSelected(!this.isSelected(),silent);
    },
    
    setSelected : function(selected,silent){
        this._selected = selected;
        if(this.$el){
            if(selected){
                this.$el.addClass(TAG_VIEW_SELECTED_CLASSNAME);
            }else{
                this.$el.removeClass(TAG_VIEW_SELECTED_CLASSNAME);
            }
        }
        
        if(!silent){
            this.trigger("selectionChange",null, selected);
        }
        
    },
    
    isSelected : function(){
        return this._selected;
    },
    
    render : function() {
        const templateParams = {};
        templateParams.label = ALL_OPTION_TAG_LABEL;
        templateParams.tagId = ALL_OPTION_TAG_ID;
        templateParams.selected = this._selected;
        templateParams.selectedClassName = TAG_VIEW_SELECTED_CLASSNAME;
        this.$el.html(this.compiledTemlate(templateParams));
        this.setElement(this.$el.find("div").first());
        return this;
    }

});
export default AllTagItemView;