import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import OutlineItemOptionView from "views/journey/OutlineItemOptionView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import ti18n from "i18n!nls/CapsuleCardi18n";
import RSVP from "rsvp";

const OutlineItemOptionController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.model = options? options.outlineItemModel : null;
        this.functionToRetrieveDefaultImageUrl = options.functionToRetrieveDefaultImageUrl?options.functionToRetrieveDefaultImageUrl: null;
        this.context = options? options.context : null;
        this.elementSource$el = options? options.elementSource$el : null;
        this.onActionExecutedOnOutlineItemHandler = options? options.onActionExecutedOnOutlineItemHandler: null;
        _.extend(this, Backbone.Events);
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },


    viewStatistics : function(){
        //return PersistentModels.getInstance().getRouter().showTrebbleStatisticsPage(this.model, true);
    },

    share : function(){
        //return PersistentModels.getInstance().getRouter().showTrebbleSharePopup(this.model, true);
    },

    like : function(){
        //return PersistentModels.getInstance().getRouter().showTrebbleSharePopup(this.model, true);
    },
    
    removeFromOutlineConfirm : function(){
                        const buttonLabels = [window.getI18n(ti18n, "NO"),window.getI18n(ti18n, "YES")];
        const confirmCallback = (function(buttonIndex){
            if(buttonIndex ==  2){
                setTimeout((function(){
                    this.removeFromOutline();
                }).bind(this), 100);

            }
        }).bind(this);
        const message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_SHORTCAST");
        navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n, "REMOVE_FROM_OUTLINE"), buttonLabels);
    },

    removeFromOutline: function(){
        return PersistentModels.getInstance().removeCapsuleFromJourney(this.model.getCapsuleId(), this.model.getRadioId());
    },



    executeActionCallback :function(action, data){
        if(this.onActionExecutedOnOutlineItemHandler){
            this.onActionExecutedOnOutlineItemHandler(this.model,action, data);
        }
    },

    showDetails: function(){
        return PersistentModels.getInstance().getRouter().showCapsulePoupForCapsuleId(this.model.getCapsuleId(), this.context, false, true, true);
    },

    editDetails : function(){
        const onCapsuleUpdated = function(updatedCapsuleJson){
            const updateCapsuleModel = Utils.getInstance().getModelFromCapsuleJson(updatedCapsuleJson);
            this.model.setTitle(updateCapsuleModel.getTitle());
            this.model.setText(updateCapsuleModel.getText());
        };
        return PersistentModels.getInstance().getRouter().showCapsuleInfoEditPage(this.model.getCapsuleId(), onCapsuleUpdated,  true );
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {

                this.view = new OutlineItemOptionView({
                    "elementSource$el": this.elementSource$el,
                    "model" : this.model,
                     defaultImageUrl : this.functionToRetrieveDefaultImageUrl ? this.functionToRetrieveDefaultImageUrl(): null
                });
                this.listenTo(this.view, "viewStatistics",this.viewStatistics);
                this.listenTo(this.view, "share",this.share);
                this.listenTo(this.view, "like",this.like);
                this.listenTo(this.view, "removeFromOutline",this.removeFromOutlineConfirm);
                this.listenTo(this.view, "showDetails",this.showDetails);
                this.listenTo(this.view, "editDetails",this.editDetails);

                this.view.render();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default OutlineItemOptionController;