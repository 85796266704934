import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import ListItemCollection from "collections/ListItemCollection";
import DynamicContentView from "views/common/DynamicContentView";
import ti18n from "i18n!nls/StatsPagei18n";
import RSVP from "rsvp";
import moment from 'moment';
import Swiper from 'swiper';
import {BarChart, Interpolation, Svg, LineChart}  from 'chartist';

const SEVEN_DAYS_STATS_KEY = "7daysStats";
const FOURTEEN_DAYS_STATS_KEY = "14daysStats";
const THIRTY_DAYS_STATS_KEY = "30daysStats";
const TWELVE_MONTHS_STATS_KEY = "12MonthsStats";
const ALL_TIMES_STATS_KEY = "allTime";
const ALL_CONTEXT_DATA_FOUND_KEY = "allContextDataFoundKey";
const dataTypeWithBarChart = [];
const dataInSecondsToBeConverted = [];
const OWNER_COUNT_ALWAYS_DISPLAYED  = true;
const ALL_CONTEXT_TYPE = "allContext";


const EVENTS = {"CHART_LOADING": "chartLoading",
"NO_DATA_FOR_CHART": "noDataForChart",
"CHART_READY": "chartReady",
"ERROR_BUILDING_CHART": "errorLoadingChart"};

const ROUND_NUMBER_LABELS = true;

const StatisticsContentView = DynamicContentView.extend({


    initialize : function(options) {
        this._trebbleStats = this._prepareData(options.trebbleStats);
        this._periodSelectorView = options.periodSelectorView;
        this._statsGraphData = null;
        this._statsTotalCount = null;
        this._dataStatsId = options.dataStatsId;
        this._selectedTimePeriodKey =null;
        this._collectionContainerId =  "trebbleStatisticCollectionWrapper" + (new Date()).getTime();
        this._getStatisticsLoadFunction = options.getStatisticsLoadFunction;
        this._dataTypes = options.dataTypes;
        this._dataTypeToLabel = options.dataTypeToLabel;
        this._dataTypeToFontIcon = options.dataTypeToFontIcon;
        this._dataTypeWithBarChart = options.dataTypeWithBarChart? options.dataTypeWithBarChart: [] ;
        this._dataInSecondsToBeConverted = options.dataInSecondsToBeConverted? options.dataInSecondsToBeConverted: [] ;
        this._supportedActionContext = options.supportedActionContext;
        this._actionTypeToUnsupportedContext = options.actionTypeToUnsupportedContext;
        this._contextTypeToContextLabel = options.contextTypeToContextLabel;
        this._chartCssClassToActionContext = {};
        this._buildActionContextToChartCssClass();
        this._chartElementsArray =[];
        
        DynamicContentView.prototype.initialize.call(this, {"canBeAppendedBeforeLoad": true});
    },

    _buildActionContextToChartCssClass : function(){
        const classSuffixes ="abcdef";
        this._chartCssClassToActionContext = {};
        for(let i in this._supportedActionContext){
            const actionContext = this._supportedActionContext[i];
            const chartClass = "ct-series-"+classSuffixes.charAt(i);
            this._chartCssClassToActionContext[chartClass]  = actionContext;
        }
        this._actionContextToChartCssClass = {};
        for(let cssClass in this._chartCssClassToActionContext){
            this._actionContextToChartCssClass[this._chartCssClassToActionContext[cssClass]] = cssClass;
        }
    },
    _isContextUnsupportedForAction : function(action, context){
        return (this._actionTypeToUnsupportedContext[action] && this._actionTypeToUnsupportedContext[action].indexOf(context)  != -1 )
    },

    _getUnsupportedContextForAction : function(action){
        return this._actionTypeToUnsupportedContext[action] ;
    },



    _prepareData: function(statsData){
        const preparedStatsData = null;
        let m;
        if(statsData){
            const preparedStatsData = {};
            for(let action in this._dataTypeToLabel){
                const data = statsData[action];
                if(data){
                    preparedStatsData[action] = {"last30Days": {},"last12Months":{},"allTime": {}};
                    for(let ii in this._supportedActionContext){
                        const  contextType = this._supportedActionContext[ii];
                        preparedStatsData[action]["last30Days"][contextType] = {};
                        preparedStatsData[action]["last12Months"][contextType] = {};
                        preparedStatsData[action]["allTime"][contextType] = {};
                        const contextData = data[contextType];
                        //preparedStatsData[action][contextType] = {"last30Days": {},"last12Months":{}};
                        if(contextData){
                            for(let oo in contextData.last30Days){
                                const dailyData = contextData.last30Days[oo];
                                if(dailyData){
                                     m = moment(dailyData.time).utc();
                                    preparedStatsData[action]["last30Days"][contextType][m.format("YYYY MM DD")] = dailyData.count? dailyData.count: 0;
                                }
                            }
                            for(let oo in contextData.last12Months){
                                const monthlyData = contextData.last12Months[oo];
                                if(monthlyData){
                                     m = moment(monthlyData.time).utc();
                                    preparedStatsData[action]["last12Months"][contextType][m.format("YYYY MM")] = monthlyData.count? monthlyData.count: 0;
                                }
                            }
                            for(let oo in contextData.allTime){
                                const yearData = contextData.allTime[oo];
                                if(yearData){
                                     m = moment.parseZone(yearData.time);
                                    preparedStatsData[action]["allTime"][contextType][m.format("YYYY")] = yearData.count? yearData.count: 0;
                                }
                                if(!this._earliestYearToDisplay || this._earliestYearToDisplay > m.get('year')){
                                    this._earliestYearToDisplay = m.get('year');
                                }
                            }
                        }
                    }

                }

            }
        }
        return preparedStatsData;

    },

    buildDaysStatsData : function(){
        if(!this._statsGraphData){
            this._statsGraphData = {};
            this._statsTotalCount = {};
        }
        if(this._trebbleStats){
            for(let i in this._dataTypes){
                const dataType = this._dataTypes[i];
                const data = this._trebbleStats[dataType];
                const sevenDaysGraphStats = {"labels": [],"series":[]};
                const fourteenDaysGraphStats = {"labels": [],"series":[]};
                const thirtyDaysGraphStats = {"labels": [],"series":[]};
                let totalCountSevenDays = 0;
                let totalCountFourteenDays = 0;
                let totalCountThirtyDays = 0;
                let allContextTypeFound = false;
                if(data.last30Days){
                    //build series for graph
                    for(let ii in this._supportedActionContext){
                        const contextType = this._supportedActionContext[ii]
                        const contextTypeData  = data.last30Days[contextType];
                        if(contextTypeData){
                            const todaysDate =new Date();
                            todaysDate.setHours(0,0,0,0);
                            const dateCounter = todaysDate;
                            const thirtyDaysyDataForContextType = contextTypeData//contextTypeData.last30Days;
                            const thirtyDaysDataSerieForContextType = [];
                            for(let i = 0; i < 30; i ++){
                                dateCounter.setDate(dateCounter.getDate() - 1);
                                if( !this._isContextUnsupportedForAction(dataType,contextType)){
                                    let dailyDataCount = thirtyDaysyDataForContextType[moment(dateCounter).format("YYYY MM DD")];
                                    if(!dailyDataCount){
                                        dailyDataCount = 0;
                                    }
                                    thirtyDaysDataSerieForContextType.push(dailyDataCount? dailyDataCount: 0);
                                    //if(ALL_CONTEXT_TYPE == contextType){
                                        allContextTypeFound = true;
                                        if(i < 7){
                                            totalCountSevenDays = totalCountSevenDays + dailyDataCount;
                                        }
                                        if(i < 14){
                                            totalCountFourteenDays = totalCountFourteenDays +dailyDataCount;
                                        }
                                        if(i < 30){
                                            totalCountThirtyDays = totalCountThirtyDays + dailyDataCount;
                                        }
                                    }else{
                                        thirtyDaysDataSerieForContextType.push(0);
                                    }
                                    //}
                                }
                                const sevenDaysDataForContextType = thirtyDaysDataSerieForContextType.slice(0, 7);
                                sevenDaysDataForContextType.reverse();
                                sevenDaysGraphStats.series.push(sevenDaysDataForContextType);
                                const fourteenDayDataForContextType = thirtyDaysDataSerieForContextType.slice(0, 14);
                                fourteenDayDataForContextType.reverse();
                                fourteenDaysGraphStats.series.push(fourteenDayDataForContextType);
                                thirtyDaysDataSerieForContextType.reverse();
                                thirtyDaysGraphStats.series.push(thirtyDaysDataSerieForContextType);

                            }else{
                                sevenDaysGraphStats.series.push([]);
                                fourteenDaysGraphStats.series.push([]);
                                thirtyDaysGraphStats.series.push([]);
                            }

                        }

                        //generate Labels
                        const todaysDate = new Date();
                        todaysDate.setHours(0,0,0,0);
                        const dateCounter = todaysDate;
                        for(let i = 0; i < 30; i ++){
                            dateCounter.setDate(dateCounter.getDate() - 1);
                            const m = moment(dateCounter);
                            if(i < 7){
                                sevenDaysGraphStats.labels.push(m.format("ddd"));

                            }
                            if(i < 14){
                                if(i%2 == 1 && ((window.device && window.device.platform != "browser") || window.isMobileBrowser)){
                                    fourteenDaysGraphStats.labels.push(" ");
                                }else{
                                    fourteenDaysGraphStats.labels.push(m.format("MMM DD"));
                                }

                            }
                            if(i < 30){
                                if(i%3 != 0 && ((window.device && window.device.platform != "browser") || window.isMobileBrowser)){
                                    thirtyDaysGraphStats.labels.push(" ");
                                }else{
                                    thirtyDaysGraphStats.labels.push(m.format("MMM DD"));
                                }

                            }

                        }
                        sevenDaysGraphStats.labels.reverse();
                        fourteenDaysGraphStats.labels.reverse();
                        thirtyDaysGraphStats.labels.reverse();

                    }
                    if(!this._statsGraphData[dataType]){
                        this._statsGraphData[dataType] = {};
                        this._statsTotalCount[dataType] = {}

                    } 
                    this._statsGraphData[dataType][SEVEN_DAYS_STATS_KEY] = sevenDaysGraphStats;
                    this._statsGraphData[dataType][FOURTEEN_DAYS_STATS_KEY] = fourteenDaysGraphStats;
                    this._statsGraphData[dataType][THIRTY_DAYS_STATS_KEY] = thirtyDaysGraphStats;
                    this._statsTotalCount[dataType][SEVEN_DAYS_STATS_KEY] = totalCountSevenDays;
                    this._statsTotalCount[dataType][FOURTEEN_DAYS_STATS_KEY] = totalCountFourteenDays;
                    this._statsTotalCount[dataType][THIRTY_DAYS_STATS_KEY] = totalCountThirtyDays;

                }
            }
        },



        buildMonthStatsData : function(){
            if(!this._statsGraphData){
                this._statsGraphData = {};
                this._statsTotalCount = {};
            }
            if(this._trebbleStats){
                for(let i in this._dataTypes){
                    const dataType = this._dataTypes[i]
                    const data = this._trebbleStats[dataType];
                    let totalCount12Months = 0;
                    let allContextTypeFound = false;
                    const twelveMonthsGraphStats = {"labels": [],"series":[]};
                    if(data.last12Months){
                        //build series for graph
                        for(let ii in this._supportedActionContext){
                            const contextType = this._supportedActionContext[ii]
                            const contextTypeData  = data.last12Months[contextType];
                            if(contextTypeData){
                                let d  = new Date();
                                const firstDayOfTheMonth = new Date(d.getFullYear(), d.getMonth(), 1);
                                const monthCounter = firstDayOfTheMonth;

                                const twelveMonthsDataForContextType = contextTypeData;//contextTypeData.last30Days;
                                const twelveMonthsDataSerieForContextType = [];
                                for(let i = 0; i < 12; i ++){
                                    if(i !=0){
                                        monthCounter.setMonth(monthCounter.getMonth() - 1);
                                    }
                                    if( !this._isContextUnsupportedForAction(dataType,contextType)){
                                        let monthlyDataCount = twelveMonthsDataForContextType[moment(monthCounter).format("YYYY MM")];
                                        if(!monthlyDataCount){
                                            monthlyDataCount = 0;
                                        }
                                        twelveMonthsDataSerieForContextType.push(monthlyDataCount? monthlyDataCount: 0);
                                        //if(contextType == ALL_CONTEXT_TYPE){
                                            totalCount12Months = totalCount12Months + monthlyDataCount;
                                            //}
                                        }else{
                                            twelveMonthsDataSerieForContextType.push(0);
                                        }
                                    }
                                    twelveMonthsDataSerieForContextType.reverse();
                                    twelveMonthsGraphStats.series.push(twelveMonthsDataSerieForContextType);

                                }else{
                                    twelveMonthsGraphStats.series.push([]);
                                }

                            }

                            //generate Labels
                            let d  = new Date();
                            const firstDayOfTheMonth = new Date(d.getFullYear(), d.getMonth(), 1);
                            const monthCounter = firstDayOfTheMonth;

                            for(let i = 0; i < 12; i ++){
                                if(i !=0){
                                    monthCounter.setMonth(monthCounter.getMonth() - 1);
                                }
                                const m = moment(monthCounter);
                                twelveMonthsGraphStats.labels.push(m.format("MMM YY"));


                            }
                            twelveMonthsGraphStats.labels.reverse();

                        }
                        if(!this._statsGraphData[dataType]){
                            this._statsGraphData[dataType] = {};
                            this._statsTotalCount[dataType] = {}

                        } 
                        this._statsGraphData[dataType][TWELVE_MONTHS_STATS_KEY] = twelveMonthsGraphStats;
                        this._statsTotalCount[dataType][TWELVE_MONTHS_STATS_KEY] = totalCount12Months;
                    }
                }
            },


            buildAllTimeStatsData : function(){
                if(!this._statsGraphData){
                    this._statsGraphData = {};
                    this._statsTotalCount = {};
                }
                if(this._trebbleStats){
                    let contextTypeData;
                    for(let i in this._dataTypes){
                        const dataType = this._dataTypes[i]
                        const data = this._trebbleStats[dataType];
                        let totalCountAllTimes = 0;
                        const allContextTypeFound = false;
                        const allTimesGraphStats = {"labels": [],"series":[]};
                        if(data.allTime){

                            //build series for graph
                            for(let ii in this._supportedActionContext){
                                const contextType = this._supportedActionContext[ii]
                                contextTypeData  = data.allTime[contextType];
                                if(contextTypeData){


                                    const allTimesDataForContextType = contextTypeData;//contextTypeData.last30Days;
                                    const allTimesDataSerieForContextType = [];
                                    const numberOfYearsAddedToGraph = 0;
                                    const d  = new Date();
                                    const thisYear = new Date(d.getFullYear(),1, 1);
                                    const yearCounter = thisYear;
                                    while(this._earliestYearToDisplay <=  yearCounter.getFullYear()){

                                        if( !this._isContextUnsupportedForAction(dataType,contextType)){
                                            let yearDataCount = allTimesDataForContextType[moment(yearCounter).format("YYYY")];
                                            if(!yearDataCount){
                                                yearDataCount = 0;
                                            }
                                            allTimesDataSerieForContextType.push(yearDataCount? yearDataCount: 0);
                                            //if(contextType == ALL_CONTEXT_TYPE){
                                                totalCountAllTimes = totalCountAllTimes + yearDataCount;
                                                //}
                                            }else{
                                                allTimesDataSerieForContextType.push(0);
                                            }
                                            yearCounter.setFullYear(yearCounter.getFullYear() - 1);
                                        }
                                        allTimesDataSerieForContextType.reverse();
                                        allTimesGraphStats.series.push(allTimesDataSerieForContextType);

                                    }else{
                                        allTimesGraphStats.series.push([]);
                                    }

                                }

                                //generate Labels
                                const currentYear = new Date(new Date().getFullYear(), 1, 1);

                                while(this._earliestYearToDisplay <= currentYear.getFullYear()){
                                    const m = moment(currentYear);
                                    allTimesGraphStats.labels.push(m.format("YYYY"));
                                    currentYear.setFullYear(currentYear.getFullYear() - 1);


                                }
                                allTimesGraphStats.labels.reverse();

                            }
                            if(!this._statsGraphData[dataType]){
                                this._statsGraphData[dataType] = {};
                                this._statsTotalCount[dataType] = {}

                            } 
                            this._statsGraphData[dataType][ALL_TIMES_STATS_KEY] = allTimesGraphStats;
                            this._statsTotalCount[dataType][ALL_TIMES_STATS_KEY] = totalCountAllTimes;
                        }
                    }
                },




                getAllSupportedTinePeriods : function(){
                    const listItemCollection = new ListItemCollection();
                    listItemCollection.add(Utils.getInstance().getListItemModel(SEVEN_DAYS_STATS_KEY,  window.getI18n(ti18n,"LAST_7_DAYS")));
                    listItemCollection.add(Utils.getInstance().getListItemModel(FOURTEEN_DAYS_STATS_KEY, window.getI18n(ti18n,"LAST_14_DAYS")));
                    listItemCollection.add(Utils.getInstance().getListItemModel(THIRTY_DAYS_STATS_KEY,  window.getI18n(ti18n,"LAST_30_DAYS")));
                    listItemCollection.add(Utils.getInstance().getListItemModel(TWELVE_MONTHS_STATS_KEY,  window.getI18n(ti18n,"LAST_12_MONTHS")));
                    //listItemCollection.add(Utils.getInstance().getListItemModel(ALL_TIMES_STATS_KEY,  "Overall"));

                    return RSVP.Promise.resolve(listItemCollection.models);

                },




                load : function(){
                    this.setViewLoading();
                    try{
                        if(this._trebbleStats){
                            this.buildDaysStatsData();
                            this.buildMonthStatsData();
                            this.buildAllTimeStatsData();
                            this._initView();
                            return RSVP.Promise.resolve();
                        }else{
                            this.setViewLoading();
                            return this._getStatisticsLoadFunction(this._dataStatsId).then((function(statsData){
                                this._trebbleStats = this._prepareData(statsData);
                                this.buildDaysStatsData();
                                this.buildMonthStatsData();
                                this.buildAllTimeStatsData();
                                this._initView();
                            }).bind(this)).catch((function(error){
                                this.setViewFailedLoading(error);
                                throw error;
                            }).bind(this));
                        }
                    }catch(error){
                        this.setViewFailedLoading(error);
                        return RSVP.Promise.reject(error);
                    }
                },

                _destroyCurrentDisplayedStatisticsView : function(){
                    if(this._tabSwiperObj){
                        this._tabSwiperObj.destroy();
                        this.swiperTabContainer$el.remove();
                        this._tabSwiperObj = null;
                        this.swiperTabContainer$el =  null;
                    }
                    if(this._statsSwiperObj){
                        this._statsSwiperObj.destroy();
                        this.swiperStatsContainer$el.remove();
                        this._tabSwiperObj = null;
                        this.swiperStatsContainer$el =  null;
                    }
                    for(let index in this._chartElementsArray){
                        const chartElement = this._chartElementsArray[index];
                        chartElement.detach();
                    }
                    this._chartElementsArray = [];
                },

                setStatisticsPeriod : function(statisticPeriod){
                    this._selectedTimePeriodKey = statisticPeriod;
                    if(this._initialized){
                        this._destroyCurrentDisplayedStatisticsView();
                        if(this._selectedTimePeriodKey ){
                            this._swiperStatsContainerId = "trebbleStatisticSwiperContainer" + (new Date()).getTime();
                            this._swiperTabContainerId = "trebbleTabSwiperContainer" + (new Date()).getTime();
                            this.collectionContainer$el.append("<div id='"+this._swiperTabContainerId+"'><div class='"+ "swiper-wrapper"+"'></div></div><div id='"+this._swiperStatsContainerId+"'><div class='"+ "swiper-wrapper"+"'> </div>"+ this._buildNavigationButtons()+"</div>");
                            this.swiperStatsContainer$el = this.$el.find("#"+this._swiperStatsContainerId);
                            this.swiperTabContainer$el = this.$el.find("#"+this._swiperTabContainerId);
                            this.swiperWrapperStatsContainer$el = this.swiperStatsContainer$el.find(".swiper-wrapper");
                            this.swiperWrapperTabContainer$el = this.swiperTabContainer$el.find(".swiper-wrapper");

                            for(let dataType in this._dataTypeToLabel){
                                const allGraphData  = this._statsGraphData[dataType];
                                const graphData = allGraphData?allGraphData[this._selectedTimePeriodKey] : null;
                                if(graphData ){
                                    this.swiperWrapperTabContainer$el.append($(this._buildHtmlTabForDataType(dataType)));
                                    const chartUniqueElementId  = "chart_"+dataType+"_id_"+(new Date().getTime());
                                    this.swiperWrapperStatsContainer$el.append($(this._buildChartWrapperHtml(chartUniqueElementId)));
                                    this._buildChartHtml("#"+chartUniqueElementId, graphData,dataType, this._dataTypeToLabel[dataType] );
                                }

                            }
                            const chartSwiperParams  =  {
                                speed: window.isMobileBrowser?200: 300,
                                longSwipesRatio : window.isMobileBrowser?0.2: 0.4,
                                watchSlidesProgress: true,
                                preloadImages: false,
                                watchSlidesVisibility: true,
                                slidesPerView: 1,
                                centeredSlides: true,
                                paginationHide: true,
                                slideToClickedSlide: true,
                                lazyLoading: true,
                                lazyLoadingInPrevNext: true,
                                lazy: {
                                    loadPrevNext: true
                                }
                            };
                            if(window.device && window.device.platform =="browser" && !window.isMobileBrowser){
                                chartSwiperParams.nextButton = '.swiper-button-next';
                                chartSwiperParams.prevButton = '.swiper-button-prev';
                                chartSwiperParams.navigation = {
                                    nextEl: '.swiper-button-next',
                                    prevEl: '.swiper-button-prev',
                                };
                                chartSwiperParams.keyboardControl = true;
                                chartSwiperParams.mousewheelControl = true;
                                chartSwiperParams.mousewheel =  {
                                    enabled: true
                                };
                                chartSwiperParams.keyboard =  {
                                    enabled: true,
                                    onlyInViewport: false
                                };

                            }
                            this._statsSwiperObj = new Swiper("#"+this._swiperStatsContainerId,chartSwiperParams);
                            const self = this;
                            const tabSwiperParams  =  {
                                speed: window.isMobileBrowser?200: 300,
                                longSwipesRatio : window.isMobileBrowser?0.2: 0.4,
                                watchSlidesProgress: true,
                                preloadImages: false,
                                watchSlidesVisibility: true,
                                slidesPerView: "auto",
                                centeredSlides: true,
                                paginationHide: true,
                                controller : {
                                    control : this._statsSwiperObj
                                },
                                slideToClickedSlide: true,
                                lazyLoading: true,
                                lazyLoadingInPrevNext: true,
                                lazy: {
                                    loadPrevNext: true
                                },
                                onSlideChangeEnd : this._onTabChanged.bind(this),
                                on : {
                                    "slideChangeTransitionEnd" : this._onTabChanged.bind(this),
                                },
                            };
                            if(window.device && window.device.platform =="browser" && !window.isMobileBrowser){
                                //tabSwiperParams.keyboardControl = true;
                                //tabSwiperParams.mousewheelControl = true;
                            }
                            this._tabSwiperObj = new Swiper("#"+this._swiperTabContainerId,tabSwiperParams);
                            this._tabSwiperObj._statisticsContentView = this;
                            this._tabSwiperObj.params.control = this._statsSwiperObj;
                            this._statsSwiperObj.params.control = this._tabSwiperObj;
                        }
                    }
                },

                _onTabChanged : function(swiperObj){
                    const actionGraphVisible = this._dataTypes[swiperObj.activeIndex];
                    this.$el.find(".legenItem").css("opacity", 1);
                    const unsupportedContexts = this._getUnsupportedContextForAction(actionGraphVisible);
                    if(unsupportedContexts){
                        for(let i in unsupportedContexts){
                            const unsupportedContext = unsupportedContexts[i];
                            this.$el.find(".legenItem."+this._actionContextToChartCssClass[unsupportedContext]).css("opacity", 0.3);
                        }
                    }

                },

                getDurationStringFromTimeInSeconds : function(timeInSeconds, shortLabel){
                    const m = moment.duration(timeInSeconds * 1000);

                    const mDays = m.asDays();
                    const mHours = m.asHours();
                    const mMinutes = m.asMinutes();
                    const mSeconds = m.asSeconds();
                    if(mDays >= 1){
                        if(shortLabel){
                            return mDays.toFixed(2) + window.getI18n(ti18n,"DAYS");
                        }else{
                            return mDays.toFixed(2) + " "+window.getI18n(ti18n,"DAYS_ABBREVIATION");
                        }
                    }else{
                        if(mHours >= 1){
                            if(shortLabel){
                                return mHours.toFixed(2) + window.getI18n(ti18n,"HOURS");
                            }else{
                                return mHours.toFixed(2) + " "+window.getI18n(ti18n,"HOURS_ABBREVIATION");
                            }

                        }else{
                            if(mMinutes >= 1){
                                if(shortLabel){
                                    return mMinutes.toFixed(2) + window.getI18n(ti18n,"MINUTES");
                                }else{
                                    return mMinutes.toFixed(2) + " "+window.getI18n(ti18n,"MINUTES_ABBREVIATION");
                                }

                            }else{
                                if(shortLabel){
                                    return mSeconds.toFixed(2) + window.getI18n(ti18n,"SECONDS");
                                }else{
                                    return mSeconds.toFixed(2) + " "+window.getI18n(ti18n,"SECONDS_ABBREVIATION");
                                }

                            }
                        }
                    }
                },

                _buildHtmlTabForDataType : function(dataType){
                    if(this._dataTypeWithBarChart.indexOf(dataType) > -1){
                        return "<div class='slideItemWrapper swiper-slide' style='width:auto;'><div class='statsTabInfo'><div class='totalCount'>"+ Utils.getInstance().formatNumber( this.getDurationStringFromTimeInSeconds(this._statsTotalCount[dataType][this._selectedTimePeriodKey] ) )+" </div><div class='tabLabel'><span class='"+ this._dataTypeToFontIcon[dataType] +"'></span>"+this._dataTypeToLabel[dataType] +"</div></div></div>";
                    }else{
                        if(this._dataInSecondsToBeConverted.indexOf(dataType) > -1){
                            return "<div class='slideItemWrapper swiper-slide' style='width:auto;'><div class='statsTabInfo'><div class='totalCount'>"+Utils.getInstance().formatNumber( this.getDurationStringFromTimeInSeconds(this._statsTotalCount[dataType][this._selectedTimePeriodKey] )) +"</div><div class='tabLabel'><span class='"+ this._dataTypeToFontIcon[dataType] +"'></span>"+this._dataTypeToLabel[dataType] +"</div></div></div>";
                        }else{
                            return "<div class='slideItemWrapper swiper-slide' style='width:auto;'><div class='statsTabInfo'><div class='totalCount'>"+Utils.getInstance().formatNumber(this._statsTotalCount[dataType][this._selectedTimePeriodKey])+"</div><div class='tabLabel'><span class='"+ this._dataTypeToFontIcon[dataType] +"'></span>"+this._dataTypeToLabel[dataType] +"</div></div></div>";
                        }
                    }
                },

                _buildChartWrapperHtml : function(chartId){
                    return "<div class='slideItemWrapper swiper-slide' style='width:100%;'><div class='statsWrapper' id='"+chartId +"' ></div></div>";
                },

                _buildChartHtml : function(chartContainerSelector, chartData, dataType, label){
                    let chartObj  = null;
                    const isBarChar = false;
                    let labelInterpolationFncY;
                    let isDataInSecondsToBeConverted = false;
                    const scaleMinSpaceInPx = (window.device && window.device.platform == "browser" && !window.isMobileBrowser)? 50: 25;
                    const pageTitleHeight = 60;
                    const legendHeight = (window.device && window.device.platform == "browser" && !window.isMobileBrowser)? 122: 100;
                    const tabHeight =  110;
                    if(this._dataTypeWithBarChart.indexOf(dataType) > -1){
                        let isBarChar = true;
                        labelInterpolationFncY = (function(value){
                            return this.getDurationStringFromTimeInSeconds(value, true);
                        }).bind(this);
                        isDataInSecondsToBeConverted = true;
                        chartObj = new BarChart(chartContainerSelector, chartData, {"height": window.getDocumentClienHeight() - (pageTitleHeight +legendHeight +tabHeight) +"px",fullWidth: true, showArea: true,lineSmooth: Interpolation.none(), axisY: { onlyInteger: true, labelInterpolationFnc: labelInterpolationFncY, scaleMinSpace : scaleMinSpaceInPx}, axisX : {scaleMinSpace : scaleMinSpaceInPx}});
                    }else{
                        const isBarChar = false;
                        labelInterpolationFncY =  null;
                        if(this._dataInSecondsToBeConverted.indexOf(dataType) > -1){
                            isDataInSecondsToBeConverted = true;
                            labelInterpolationFncY = (function(value){
                                return this.getDurationStringFromTimeInSeconds(value, true);
                            }).bind(this);
                        }
                        const axisYConfig =  { onlyInteger: true, scaleMinSpace : scaleMinSpaceInPx };
                        if(labelInterpolationFncY){
                            axisYConfig.labelInterpolationFnc = labelInterpolationFncY;
                        }
                        const axisXConfig = { scaleMinSpace : scaleMinSpaceInPx };
                        chartObj = new LineChart(chartContainerSelector, chartData, {stackBars: true,"height": window.getDocumentClienHeight() - (pageTitleHeight +legendHeight +tabHeight) +"px",fullWidth: true, showArea: true,lineSmooth: Interpolation.none(), axisY: axisYConfig , axisX : axisXConfig});
                    }
                    chartObj.on('draw', function(data) {
                        if(data.type === 'line' || data.type === 'area') {
                            data.element.animate({
                                d: {
                                    begin: 1000 * data.index,
                                    dur: 1000,
                                    from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
                                    to: data.path.clone().stringify(),
                                    easing: Svg.Easing.easeOutQuint
                                }
                            });
                        }
                    });
                    const selectorClass = isBarChar? ".ct-bar":'.ct-point';
                    const $chart =  $(chartContainerSelector);
                    this._chartElementsArray.push(chartObj);
                    const $toolTip = $chart.append('<div class="tooltip"></div>').find('.tooltip').hide();
                    const f = this.getDurationStringFromTimeInSeconds;


                    const chartCssClassToActionContextRef = this._chartCssClassToActionContext;
                    const contextTypeToContextLabelRef = this._contextTypeToContextLabel;
                    $chart.on('mouseenter', selectorClass, function() {
                        const seriesToInfoToDisplayMap = {}
                        const pointHoveredOn$el = $(this);
                        const hoveredOnPointIndex = pointHoveredOn$el.index();
                        const seriesWrapperArray$el = pointHoveredOn$el.parent().parent().children();
                        $.each(seriesWrapperArray$el, function(i, seriesEl){
                            const pointElement = seriesEl.children.length >hoveredOnPointIndex? seriesWrapperArray$el[i].children[hoveredOnPointIndex] : null;
                            if(pointElement){
                                const point$el =  $(pointElement);
                                let value = point$el.attr('ct:value');
                                const seriesName = label;
                                const chartClassFoundViaRegex =  /ct-series-[a-f]/.exec(point$el.parent().attr('class'));
                                let chartClassname = null;
                                let contextLabel = null;
                                if(chartClassFoundViaRegex && chartClassFoundViaRegex.length > 0){
                                    chartClassname  = chartClassFoundViaRegex[0];
                                    const contextType = chartCssClassToActionContextRef[chartClassname];
                                    if(contextType){
                                        contextLabel = contextTypeToContextLabelRef[contextType];
                                    }
                                }
                                if(!contextLabel){
                                    contextLabel = "";
                                }

                                if(!value){
                                    value = 0;
                                }
                                if(isDataInSecondsToBeConverted){
                                    value = f(value);
                                }
                                seriesToInfoToDisplayMap[chartClassname] = {"chartClassname": chartClassname, "value": value, "seriesName": seriesName,"contextLabel": contextLabel};
                            }
                        });
                        let tooltipHtmlContent = '<div id="statsTooltip">';
for(let chartClassname in seriesToInfoToDisplayMap)
{
const infoToDisplay = seriesToInfoToDisplayMap[chartClassname];
tooltipHtmlContent = tooltipHtmlContent +  "<div class='info "+ chartClassname+" ' ><div class='legendColor' ></div><span>"+ infoToDisplay.contextLabel+  " : "+ infoToDisplay.value+ "</span></div>";
}
tooltipHtmlContent = tooltipHtmlContent +'</div>';								
$toolTip.html(tooltipHtmlContent).show();
});


$chart.on('mouseleave',selectorClass, function() {
$toolTip.hide();
});

$chart.on('mousemove', function(event) {
$toolTip.css({
left: (event.offsetX || event.originalEvent.layerX) - $toolTip.width() / 2 - 10,
top: (event.offsetY || event.originalEvent.layerY) - $toolTip.height() - 40
});
});
},

_initView : function(){
if(this._trebbleStats){
this.setViewHasData();
if(!this._initialized){
    this._initialized = true;
    this.setStatisticsPeriod(this._selectedTimePeriodKey);
}
}else{
this.setViewHasNoData();
}
},

_buildNavigationButtons : function(){
if(((window.device && window.device.platform != "browser") || window.isMobileBrowser)){
return  "";
}else{
return      '<div class="swiper-button-next ion-ios7-arrow-right"></div> <div class="swiper-button-prev ion-ios7-arrow-left"></div>';
}
},

_buildChartLegend : function(){
    let chartLegendString = '<div id="chartLegend">';
for(let chartClass in this._chartCssClassToActionContext)
{
const contextTyoe = this._chartCssClassToActionContext[chartClass];
const contextLabel = this._contextTypeToContextLabel[contextTyoe];
chartLegendString = chartLegendString +  "<div class='legenItem "+ chartClass+" ' ><span>"+ contextLabel+ "</span><div class='legendColor "+ contextTyoe+"' ></div></div>";
}
chartLegendString = chartLegendString +'</div>';
return chartLegendString;
},

setViewLoading : function(){
this.trigger(EVENTS.CHART_LOADING);

},

setViewHasNoData : function(){
this.trigger(EVENTS.NO_DATA_FOR_CHART);

},

setViewHasData : function(){
this.trigger(EVENTS.CHART_READY);

},

setViewFailedLoading : function(error){
this.trigger(EVENTS.ERROR_BUILDING_CHART);

},

events : {


},



render : function() {
const templateParams = {};
this.$el.append("<div  id='"+this._collectionContainerId+"'><div id='chartGeneralInfoWrapper'></div></div>");
this.collectionContainer$el = this.$el.find("#"+this._collectionContainerId);
this.chartGeneralInfoWrapper$el = this.$el.find("#chartGeneralInfoWrapper");

if(this._periodSelectorView){
this.chartGeneralInfoWrapper$el.append(this._periodSelectorView.$el);
this.chartGeneralInfoWrapper$el.append($(this._buildChartLegend()));
}
return this;
}

});
StatisticsContentView.DEFAULT_TIME_PERIOD_KEY = SEVEN_DAYS_STATS_KEY;
StatisticsContentView.DEFAULT_TIME_PERIOD_LABEL = window.getI18n(ti18n,"LAST_7_DAYS");
StatisticsContentView.EVENTS =  EVENTS;

export default StatisticsContentView;