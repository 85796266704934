
import _ from 'underscore';
import Backbone from "backbone";




const RnnoiseVoiceActivityDetectionHelper = Backbone.Model.extend({
    idAttribute: "id",

    constructor: function(attributes, options) {
        Backbone.Model.apply(this, [attributes, options]);

    },
    
    _fetchWasmModule: async function(url) {
        const response = await fetch(url);
        const bytes = await response.arrayBuffer();
        return WebAssembly.compile(bytes);
    },
    
    _setupRnnoiseVAD: async function (audioContext, wasmModule, vadThreshold = 0.5, totalFrames) {
        await audioContext.audioWorklet.addModule(`${document.location.origin}/rnnoise-vad-processor.js`);
      
        const rnnoiseVADNode = new AudioWorkletNode(audioContext, 'rnnoise-vad', {
          processorOptions: { module: wasmModule, vadThreshold: vadThreshold , totalFrames}
        });
      
        rnnoiseVADNode.connect(audioContext.destination);
        return rnnoiseVADNode;
    },


    processAudioBuffer: async function(audioBuffer, vadThreshold = 0.5, onProgress) {
        const wasmModule = await this._fetchWasmModule(`${document.location.origin}/rnnoise-processor.wasm`);
        const sampleRate = audioBuffer.sampleRate;
        const length = audioBuffer.length;
        const audioContext = new OfflineAudioContext(1, length, sampleRate);
      
        const rnnoiseVADNode = await this._setupRnnoiseVAD(audioContext, wasmModule, vadThreshold, audioBuffer.length);
      
        const source = audioContext.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(rnnoiseVADNode);
        source.start();
      
  
      
        return new Promise((resolve, reject) => {
          rnnoiseVADNode.port.onmessage = ({ data }) => {
            if (data.type === 'progress' && onProgress) {
              onProgress(data.progress);
            }
            if (data.type === "segments") {
              resolve(data.segments);
            }
          };
           audioContext.startRendering().then(function(){
            rnnoiseVADNode.port.postMessage({ type: "getSegments" });
           }).catch(reject);
        });
    }

});



const RnnoiseVoiceActivityDetectionHelperInstance = new RnnoiseVoiceActivityDetectionHelper();

export default {
    getInstance: function ()
    {
        return RnnoiseVoiceActivityDetectionHelperInstance;
    }
}; 