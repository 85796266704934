import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import PersistentModels from "services/PersistentModels";
import ti18n from "i18n!nls/PlayerPagei18n";
import PlayerService from "services/PlayerService";
import PlayerFooterTemplate from 'text!../../../templates/playerFooter/playerFooter.html';

const ENABLE_MARQUEE = true;
const PlayerFooterView = Backbone.View.extend({

    template : _.template(PlayerFooterTemplate),

    initialize : function(options) {
        
        if(this.model){
            this.listenTo(this.model, 'songChanged',
                this.onSongChange);
            /*this.listenTo(this.model, 'newSongAddedToPlayerQueue',
                this.onSongChange);*/

this.listenTo(this.model, 'thumbnailAlbumCoverUpdate',
this.onThumbnailAlbumCoverUpdate);
this.listenTo(this.model, 'songStartedPlaying',
this.onSongStartedPlaying);
this.listenTo(this.model, 'songPaused',
this.updatePlayPauseButtonClass);
this.listenTo(this.model, 'songLoading', this._onSongLoading);
this.listenTo(this.model, 'songLoaded', this._onSongFinishLoading);
this.listenTo(this.model, 'playerLoading', this.updatePlayPauseButtonClass);
this.listenTo(this.model, 'playerFinishLoading', this.updatePlayPauseButtonClass);
this.listenTo(this.model, 'songFailedLoading', this._onSongFailedLoading);
this.listenTo(this.model, 'songPreviewLoaded', this._onSongPreviewLoaded);
this.listenTo(this.model, 'songPreviewUnLoaded', this._onSongPreviewUnLoaded);
this.listenTo(this.model, 'songPreviewCancelled', this._onSongPreviewUnLoaded);
}
this.canBeShownOnActivePage = (options && options.canBeShownOnActivePage) || false;

const onOrientationChangeFunction  = (function(){
if(ENABLE_MARQUEE && this.isPlayerFooterViewDisplayed()){
this.applyMarqueeOnTitleIfApplicable(this.$el.find("#footerCurrentSongTitle"));
this.applyMarqueeOnTitleIfApplicable(this.$el.find("#footerCurrentSongArtist"));
}

}).bind(this);
window.addEventListener('orientationchange', onOrientationChangeFunction);
window.addEventListener('resize', onOrientationChangeFunction);


},

events : {
"click #playPauseBtn" : "_onPlayPauseBtnClick",
"click #footerCurrentSongInfo" : "showPlayerPage",
"click #mediaSourceBtn": "_onMediaSourceButtonClicked",
"click #shareBtn":"showShareCurrentPlayingSongPopup",
"click #shareSongBtn":"showShareCurrentPlayingSongPopup",
},

showPlayerPage : function(){
PersistentModels.getInstance().getRouter().showPlayerPage(true, true);
},

getModel : function(){
return this.model;
},

_onPlayPauseBtnClick :function(){
if (this.model.isPlaying()) {
this.model.pauseAudio();
} else {
this.model.playAudio();
}
this.updatePlayPauseButtonClass();
this.trigger("onSongPlayPaused");

},

_onSongLoading : function(){
this.updatePlayPauseButtonClass();
},

_onSongPreviewLoaded : function(){
/*$.mobile.loading( 'show', {
text: "Playing Preview ...",
textVisible: false,
theme: "t",
textonly: false,
// html: html
});*/
},

_onSongPreviewUnLoaded : function(){
//$.mobile.loading( 'hide');
},
_onSongFinishLoading : function(){
this.updatePlayPauseButtonClass();
},

_onSongFailedLoading : function(error){
this.updatePlayPauseButtonClass();
},

_onSongPlayingCurrentInfoClicked : function(){
this.trigger("showPlayerPage");
},

_updateMediaSourceButton : function(){
    let mediaType = "";
if(!window.waitForCordovaToLoad && this.model.isCurrentMediaTypeSoundcloud()){
mediaType = "Via SoundCloud";
}
if(!window.waitForCordovaToLoad && this.model.isCurrentMediaTypeYoutubeVideo()){
mediaType = "Via Youtube";
}
const mediaSourceBtn$el = this.$el.find("#mediaSourceBtn");
mediaSourceBtn$el.html(mediaType);
if(mediaType == ""){
mediaSourceBtn$el.hide();
}else{
mediaSourceBtn$el.show();
}
},

_onMediaSourceButtonClicked : function(){
this.model.openExternalSongPage();
},

onSongStartedPlaying : function() {
this.model.getCurrentSong().then((function(currentSong){
//this._onDurationChanged(currentSong.get("duration"));
//this.$el.find("#trackDuration").html(this.formatPositionToString(currentSong.get("duration")/1000));
this.updatePlayPauseButtonClass();
this._updateMediaSourceButton();
}).bind(this));


},


setCanBeShownOnActivePage : function(canBeShownOnActivePage, transparent){
this.canBeShownOnActivePage = canBeShownOnActivePage;
let isDisplayed = null;
const currentlyLoadedPlaylist =  this.model.getPlaylist();
if(this.canBeShownOnActivePage && currentlyLoadedPlaylist && currentlyLoadedPlaylist.models && currentlyLoadedPlaylist.models.length > 0)
{
this.$el.show();
isDisplayed = true;
if(ENABLE_MARQUEE){
    this.applyMarqueeOnTitleIfApplicable(this.$el.find("#footerCurrentSongTitle"));
    this.applyMarqueeOnTitleIfApplicable(this.$el.find("#footerCurrentSongArtist"));
}
}else{
this.$el.hide();
isDisplayed = false;
}
$.mobile.resetActivePageHeight(canBeShownOnActivePage? window.getDocumentClienHeight() +60: null); // work around to resolve issue where with the footer transparent, the current might not be tall enough

if(transparent){
this.$el.addClass("transparent");
}else{
this.$el.removeClass("transparent");
}
this.trigger("onPlayerFooterHiddenOrDisplayed", isDisplayed);

},

isPlayerFooterViewDisplayed : function(){
return this.$el.is(":visible") ;
},

getCanBeShownOnActivePage :  function(){
return this.canBeShownOnActivePage;
}, 

onSongChange : function() {
    let currentSongPlaying = null;
let currentArtistPlaying = null;
let albumArtSrc = window.getResourceURLAccordingToLocationProtocol("/img/Beyonce-4-Deluxe-Edition-2011-Album-Cover.png");
this.model.getCurrentSong().then((function(currentSong){
if(currentSong)
{
    currentSongPlaying = currentSong.get("title");
    currentArtistPlaying = currentSong.get("artist");
}else{
    currentSongPlaying = "";
    currentArtistPlaying = "";
}
if (currentSong && currentSong.get("albumArtData")) {
    albumArtSrc = "data:image/png;base64,"+ currentSong.get("albumArtData");
} else {
    albumArtSrc = "";
}
this.$el.find("#footerCurrentSongTitle").html(currentSongPlaying);
this.$el.find("#footerCurrentSongArtist").html(currentArtistPlaying);
let isDisplayed = null
if (this.canBeShownOnActivePage && currentSong != null) {
    this.$el.show();
    isDisplayed =  true;
    if(ENABLE_MARQUEE && this.isPlayerFooterViewDisplayed()){
        this.applyMarqueeOnTitleIfApplicable(this.$el.find("#footerCurrentSongTitle"));
        this.applyMarqueeOnTitleIfApplicable(this.$el.find("#footerCurrentSongArtist"));
    }
}else{
    this.$el.hide();
    isDisplayed =  false;
}

this.updatePlayPauseButtonClass();
this.trigger("onPlayerFooterHiddenOrDisplayed", isDisplayed);
}).bind(this));
this._updateMediaSourceButton();



},

applyMarqueeOnTitleIfApplicable : function(element$el){
element$el.removeClass("marquee");
if(this.isJqueryElementOverflow(element$el)){
this.wrapContentsInMarquee(element$el.get(0));
}
},

isJqueryElementOverflow : function(element$el){
return element$el.width() && (element$el.get(0).scrollWidth > element$el.width())
},

wrapContentsInMarquee: function(element) {
const marquee = document.createElement('span'),
contents = element.innerText;

marquee.innerText = contents;
element.innerHTML = '';
element.appendChild(marquee);
const element$el = $(element);
if(!element$el.hasClass("marquee")){
element$el.addClass("marquee");
}
},


onThumbnailAlbumCoverUpdate : function(isIdifiedAlbumArt, idifiedAlbumArtBase64, idifiedAlbumArtBase64Blur, songURI) {
return this.model.getCurrentSong().then((function(currentSong){
if(songURI == currentSong.get("uri"))
{
    const playerFooterElem = document.getElementById("playerFooter");
    let usingDefaultCoverArt = true;
    if (idifiedAlbumArtBase64) {

        const albumArtSrc = "data:image/png;base64,"
        + idifiedAlbumArtBase64;
        const albumArtSrcBlur = "data:image/png;base64,"+ idifiedAlbumArtBase64Blur;
        usingDefaultCoverArt= false;
        /*this.$el.find("#footerCurrentSongImg").attr({
            "src" : albumArtSrc
        });*/
//document.getElementById("footerCurrentSongImg").style["background-image"]="url('"+albumArtSrc+"')";
const tempImg2 = new Image();
tempImg2.onload  = function(){
const albumdata = tempImg2.src;
document.getElementById("footerCurrentSongImg").style["background-image"]="url('"+albumdata+"')";
}
tempImg2.src = albumArtSrc;
/*const tempImg = new Image();
tempImg.onload  = function(){
const blurredData = tempImg.src;
document.getElementById("playerFooter").style["background-image"]="url('"+blurredData+"')";
}
tempImg.src = albumArtSrcBlur;*/


}else{
const albumArtSrc = window.getResourceURLAccordingToLocationProtocol("/img/noCoverArt8.png");
playerFooterElem.style["background-image"] = "";


document.getElementById("footerCurrentSongImg").style["background-image"]="url('"+ window.getResourceURLAccordingToLocationProtocol("/img/videoLoading.png") +"')";

//playerFooterElem.style.backgroundColor = "#111111";
}
}else{
//user probaly changed song already before cover art got retrieve so ignore

}
}).bind(this));

},



updatePlayPauseButtonClass : function(){
const pauseClass =  "ion-ios7-pause";//"fontello-icon-pause-1";
const playClass =  "ion-ios7-play";//"fontello-icon-play-1";
const loadingClass = "ion-loading-c";
const playButtonEl = this.$el.find("#playPauseBtn t");
const playButtonWrapperEl = this.$el.find("#playPauseBtn");
if(this.model.isLoading() || this.model.isPlayerLoading()){
playButtonEl.removeClass(playClass);
playButtonEl.removeClass(pauseClass);
playButtonEl.addClass(loadingClass);
playButtonWrapperEl.attr("data-hint",window.getI18n(ti18n, "LOADING"));
}else{
playButtonEl.removeClass(loadingClass);
if (this.model.isPlaying()) {
    playButtonEl.removeClass(playClass);
    playButtonEl.addClass(pauseClass);
    playButtonWrapperEl.attr("data-hint",window.getI18n(ti18n, "PAUSE"));
} else {
    playButtonEl.removeClass(pauseClass);
    playButtonEl.addClass(playClass);
    playButtonWrapperEl.attr("data-hint",window.getI18n(ti18n, "PLAY"));
}
}

},

showShareCurrentPlayingSongPopup : function(){
return PlayerService.getInstance().showShareCurrentPlayingSongPopup(true);
},

render : function(){
return this.model.getCurrentSong().then((function(currentSongLoadedInPlayer){
this.$el.html(this.template({"ti18n": ti18n}));
this.setElement(this.$el.find("div").first());
this.$el.hide();
this.$el.toolbar({"updatePagePadding": false});
$("#featurePage").css("padding","0px");
/*if(this.getCanBeShownOnActivePage() && this.getModel())
{
    this.$el.show();
}else{
    this.$el.hide();
}		
$.mobile.resetActivePageHeight();*/
this.onSongChange();
this.setCanBeShownOnActivePage(this.getCanBeShownOnActivePage());
return this;
}).bind(this));


},

});
export default PlayerFooterView;