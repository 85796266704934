

import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import Utils from "models/helper/Utils";
import ChooseTranscriptionLanguagePopupView from "views/audioEditor/ChooseTranscriptionLanguagePopupView";
import ListItemCollection from "collections/ListItemCollection";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import RSVP from "rsvp";

const ChooseTranscriptionLanguagePopupController = Chaplin.Controller.extend({

    initialize : function(options) {
        this._transcribeProxyFunction = options.transcribeProxyFunction;
        this._onPopupCloseHanlder = options? options.onPopupClose : null;
        _.extend(this, Backbone.Events);
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    _onPopupClosed : function(){
        if(this._onPopupCloseHanlder){
            this._onPopupCloseHanlder();
        }
    },

    _onTranscribeButtonClick: function(){
        this._transcribeProxyFunction(this.view.getLanguageCode());
        this.view._close();
    },



    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                const params = {};
                params.selectedDefaultTranscriptionLanguage  = LocalStorageHelper.getInstance().getLastLanguageUsedDuringTranscription();
                params.supportedTranscriptionLanguagesCollection = new ListItemCollection(Utils.getInstance().getSupportedTranscribedLanguage());
                const chooseTranscriptionLanguagePopupView = new ChooseTranscriptionLanguagePopupView(params);
                chooseTranscriptionLanguagePopupView.render();
                this.view = chooseTranscriptionLanguagePopupView;
                this.listenTo(this.view, "onPopupClosed", this._onPopupClosed.bind(this));
                this.listenTo(this.view, "transcribe", this._onTranscribeButtonClick.bind(this));
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

}

});

export default ChooseTranscriptionLanguagePopupController;