import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import CapsuleListController from "controllers/capsule/CapsuleListController";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import Utils from "models/helper/Utils";
import CapsuleListOptionView from "views/capsule/CapsuleListOptionView";
import RSVP from "rsvp";

const CapsuleListOptionController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.model = options.model;
        this.context = options.context;
        this.songRefSummary = options.songRefSummary;
        const capsuleListControllerParams = {};
        capsuleListControllerParams.resizePlaceHolderElem = true;
        capsuleListControllerParams.doNotSortDisplayedCollection = true;
        capsuleListControllerParams.loadHandler = options.loadHandler?options.loadHandler: this._getCapulesForSongLoadHandler.bind(this);
        capsuleListControllerParams.showEmtptyInfoBox = true;
        capsuleListControllerParams.customInfoBoxInfoParams = this._customInfoBoxInfoParams;
        if(options.context){
            capsuleListControllerParams.context = options.context;
        }
        this.capsuleListController = new CapsuleListController(capsuleListControllerParams);
        _.extend(this, Backbone.Events);
    },
    
    _getCapulesForSongLoadHandler: function(){
        if(this.model){
            const songJsonForBackend = Utils.getInstance().getSongJsonForBackendFromSongModel(this.model);
            return TrebbleClientAPIHelper.getInstance().getCapsulesInfoForSong(songJsonForBackend , this.songRefSummary);
        }else{
            return RSVP.Promise.resolve();
        }
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {

                this.capsuleListController.buildModelAndRenderView().then((function(){
                    const capsuleListOptionViewParams = {};
                    capsuleListOptionViewParams.capsuleListView = this.capsuleListController.getView();
                    const capsuleListOptionView = new CapsuleListOptionView(capsuleListOptionViewParams);
                    capsuleListOptionView.render();
                    this.capsuleListController.getView().load();
                    this.view = capsuleListOptionView;
                    resolve();
                }).bind(this)).catch(reject);
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default CapsuleListOptionController;