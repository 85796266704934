import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import OptionPopupView from "views/common/OptionPopupView";
import RSVP from "rsvp";
import ti18n from "i18n!nls/PlaylistPodcasti18n";
import popupTemplate from 'text!../../../templates/playlistPodcasts/PersonalTrebbleOptionContent.html';

const PersonalTrebbleOptionView = OptionPopupView.extend({




    initialize : function(options) {
        const viewOptions =  {};
        this._trebbleModel = options.model;
        this.elementSource$el = options.elementSource$el;
        const contentView = {};
        const contentViewTemplate = _.template(popupTemplate);
        const templateParams = {"model" : this._trebbleModel, "ti18n":ti18n};
        contentView.$el  = $(contentViewTemplate(templateParams));
        viewOptions.contentView = contentView;
        viewOptions.contentPosition = "center";
        viewOptions.id = "PersonalTrebbleOptionPopup";
        viewOptions.elementSource$el = this.elementSource$el;
        viewOptions.hideHeading = true;
        viewOptions.showAsMenuIfInBrowser = true;
        OptionPopupView.prototype.initialize.call(this, viewOptions);
    },

    afterRendering : function(){
        this.$el.find("#listenToPreviewBtn").click(this._onListenToPreviewButtonClicked.bind(this));
        this.$el.find("#editDetailsBtn").click(this._onEditDetailsButtonClicked.bind(this));
        this.$el.find("#setSweeperBtn").click(this._onSetSweeperButtonClicked.bind(this));
        this.$el.find("#setGreaterBtn").click(this._onSetGreaterButtonClicked.bind(this));
        this.$el.find("#setIntroBtn").click(this._onSetIntroButtonClicked.bind(this));
        this.$el.find("#setOutroBtn").click(this._onSetOutroButtonClicked.bind(this));
        this.$el.find("#editArtistSummaryBtn").click(this._onEditArtistSummaryButtonClicked.bind(this));
        this.$el.find("#editSongsInPreviewBtn").click(this._onEditSongsInPreviewBtnButtonClicked.bind(this));
        this.$el.find("#viewStatsBtn").click(this._onViewStatsButtonClicked.bind(this));
        this.$el.find("#shareBtn").click(this._onShareButtonClicked.bind(this));
        this.$el.find("#followBtn").click(this._onFollowButtonClicked.bind(this));
        this.$el.find("#UnfollowBtn").click(this._onUnfollowButtonClicked.bind(this));
        this.$el.find("#updateCoverArtBtn").click(this._onUpdateCoverArtButtonClicked.bind(this));
        this.$el.find("#allAllFromLibraryBtn").click(this._addAllSongFromLibraryBtnClicked.bind(this));
        this.$el.find("#archiveBtn").click(this._onArchiveBtnClicked.bind(this));

    },

    _onShareButtonClicked :function(){
        this.close().then((function(){
            this.trigger("share", this._trebbleModel);
        }).bind(this));
    },

    _addAllSongFromLibraryBtnClicked : function(){
        this.close().then((function(){
            this.trigger("allAllFromLibrary", this._trebbleModel);
        }).bind(this));
    },

    _onUpdateCoverArtButtonClicked :function(){
        this.close().then((function(){
            this.trigger("upadteCoverArt", this._trebbleModel);
        }).bind(this));
    },

    _onArchiveBtnClicked:function(){
        this.close().then((function(){
            this.trigger("archiveJourney", this._trebbleModel);
        }).bind(this));
    },

    _onFollowButtonClicked :function(){
        this.close().then((function(){
            this.trigger("follow", this._trebbleModel);
        }).bind(this));
    },

    _onUnfollowButtonClicked :function(){
        this.close().then((function(){
            this.trigger("unfollow", this._trebbleModel);
        }).bind(this));
    },

    _onListenToPreviewButtonClicked : function(){
        this.close().then((function(){
            this.trigger("listenToPreview", this._trebbleModel);
        }).bind(this));
    },

    _onEditDetailsButtonClicked : function(){
        this.close().then((function(){
            this.trigger("showEditTrebbleDetails", this._trebbleModel);
        }).bind(this));
    },

    _onSetSweeperButtonClicked : function(){
        this.close().then((function(){
            this.trigger("changeSweeper", this._trebbleModel);
        }).bind(this));
    },

    _onSetGreaterButtonClicked : function(){
        this.close().then((function(){
            this.trigger("changeGreater", this._trebbleModel);
        }).bind(this));
    },

    _onSetIntroButtonClicked : function(){
        this.close().then((function(){
            this.trigger("changeIntro", this._trebbleModel);
        }).bind(this));
    },

    _onSetOutroButtonClicked : function(){
        this.close().then((function(){
            this.trigger("changeOutro", this._trebbleModel);
        }).bind(this));
    },

    _onEditArtistSummaryButtonClicked : function(){
        this.close().then((function(){
            this.trigger("editArtistSummary", this._trebbleModel);
        }).bind(this));
    },

    _onEditSongsInPreviewBtnButtonClicked : function(){
        this.close().then((function(){
            this.trigger("viewStatistics", this._trebbleModel);
        }).bind(this));
    },

    _onViewStatsButtonClicked : function(){
        this.close().then((function(){
            this.trigger("viewStatistics", this._trebbleModel);
        }).bind(this));
    },


    onAfterPageShow : function(){

    },



});

export default PersonalTrebbleOptionView;