import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import ProgressBarController from "controllers/common/ProgressBarController";
import ProgressBarPopupView from "views/common/ProgressBarPopupView";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";


const ProgressBarPopupController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
    },


    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    onPopupClosed : function(){
        
    },

    close : function(){
        this.view.close();
    },

    _buildProgressBarController : function(){
        const progressBarController = new ProgressBarController({});
        this.progressBarController = progressBarController;
        return this.progressBarController.buildModelAndRenderView();

    },

    setPercentageProgress : function(percentageProgress){
        this.progressBarController.setPercentageProgress(percentageProgress);
    },

    getPercentageProgress : function(){
        return this.progressBarController.getPercentageProgress();
    },

    setProgressMessage : function(progressMessage){
        this.progressBarController.setProgressMessage(progressMessage);
    },

    setProgressSubMessage: function(progressSubMessage){
        this.progressBarController.setProgressSubMessage(progressSubMessage);
    },

    setIndeterminate: function(indeterminate){
        this.progressBarController.setIndeterminate(indeterminate);
    },
    
    buildModelAndRenderView : function() {
        const promises = [];
        promises.push(this._buildProgressBarController());
        
        return RSVP.Promise.all(promises).then((function(){
            const params = {};
            params.progressBarView = this.progressBarController.getView();

            this.view = new ProgressBarPopupView(params);
            this.listenTo(this.view, "closed", this.onPopupClosed);

            this.view.render();

        }).bind(this))


    }

});

export default ProgressBarPopupController;