import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import AudioFilterFactory from "models/audioEditor/filters/AudioFilterFactory";
import Utils from "models/helper/Utils";

const APPLY_CROSSFADE_BETWEEN_WORDS_FOR_SMOOTH_PLAYBACK = true;


const SequencerNodeSchedulingInfo =  Backbone.Model.extend({
    idAttribute: "id",

    constructor: function(attributes, options) {
        Backbone.Model.apply(this, [attributes, options]);
        this.set("sequencer", attributes.sequencer);
    },

    getSequencer : function(){
        return this.get("sequencer");
    },

    getAudioNode : function(){
        return this.get("audioNode");
    },

    setAudioNode : function(audioNode){
        this.set("audioNode",audioNode);
    },

    getAudioBuffer : function(){
        return this.getAudioNode()? this.getAudioNode().buffer: null;
    },

    getLoopToMatchDuration: function(){
        return this.get("loopToMatchDuration");
    },

    setLoopToMatchDuration: function(loopToMatchDuration){
        this.set("loopToMatchDuration",loopToMatchDuration);
    },

    getSequencerNodeReference : function(){
        return this.get("sequencerNodeReference");
    },

    setSequencerNodeReference: function(sequencerNodeReference){
        this.set("sequencerNodeReference",sequencerNodeReference);
    },

    getRelativeStartTime : function(){
        return this.get("relativeStartTime");
    },

    getRelativeEndTime : function(){
        return this.get("relativeEndTime");
    },

    setRelativeStartTime : function(relativeStartTime){
        this.set("relativeStartTime",relativeStartTime);
    },

    setRelativeEndTime : function(relativeEndTime){
        this.set("relativeEndTime", relativeEndTime);
    },

    getStartTimeOffset : function(){
        return this.get("startTimeOffset");
    },

    setStartTimeOffset : function(startTimeOffset){
        this.set("startTimeOffset",startTimeOffset);
    },

    getStartTimeOffsetForSourceBuffer : function(){
        return this.get("startTimeOffsetForSourceBuffer");
    },

    setStartTimeOffsetForSourceBuffer : function(startTimeOffsetForSourceBuffer){
        this.set("startTimeOffsetForSourceBuffer",startTimeOffsetForSourceBuffer);
    },


    getStartTimeOffsetLoopForSourceBuffer : function(){
        return this.get("startTimeOffsetLoopForSourceBuffer");
    },

    setStartTimeOffsetLoopForSourceBuffer : function(startTimeOffsetLoopForSourceBuffer){
        this.set("startTimeOffsetLoopForSourceBuffer",startTimeOffsetLoopForSourceBuffer);
    },

    getEndTimeOffsetForSourceBuffer : function(){
        return this.get("endTimeOffsetForSourceBuffer");
    },

    setEndTimeOffsetForSourceBuffer : function(endTimeOffsetForSourceBuffer){
        this.set("endTimeOffsetForSourceBuffer",endTimeOffsetForSourceBuffer);
    },

    getEndTimeOffset : function(){
        return this.getStartTimeOffset() + this.getDuration();
    },


    getDuration : function(){
        if(this.get("duration") > 0){
            return this.get("duration");
        }else{
            return this.getEndTimeOffsetForSourceBuffer() - this.getStartTimeOffsetForSourceBuffer() > 0 || Utils.getInstance().isWordSequencerNodeInstance(this.getSequencerNodeReference()) ? this.getEndTimeOffsetForSourceBuffer() - this.getStartTimeOffsetForSourceBuffer(): this.getAudioBuffer().duration *1000;
        }
    },

    setDuration : function(duration){
        this.set("duration", duration);
    },

    getAudioBufferPlaybackDuration : function(){
        if(this.get("audioBufferPlayackDuration") > 0){
            return this.get("audioBufferPlayackDuration");
        }else{
            return this.getEndTimeOffsetForSourceBuffer() - this.getStartTimeOffsetForSourceBuffer() > 0 || Utils.getInstance().isWordSequencerNodeInstance(this.getSequencerNodeReference()) ? this.getEndTimeOffsetForSourceBuffer() - this.getStartTimeOffsetForSourceBuffer(): this.getAudioBuffer().duration *1000;
        }
    },

    setAudioBufferPlaybackDuration : function(audioBufferPlayackDuration){
        this.set("audioBufferPlayackDuration", audioBufferPlayackDuration);
    },

    cloneWithNewContext : function(audioContext){
        const cloneSequencerNodeSchedulingInfo  = this.clone();
        const newAudioNode =  audioContext.createBufferSource();
        newAudioNode.buffer = this.getAudioBuffer();
        newAudioNode.loop = this.getAudioNode().loop;
        newAudioNode.loopStart = this.getAudioNode().loopStart;
        newAudioNode.loopEnd = this.getAudioNode().loopEnd;
        cloneSequencerNodeSchedulingInfo.setAudioNode(newAudioNode);
        return cloneSequencerNodeSchedulingInfo;
    },

    _getAudioFiltersFromWrappedNodes: function(){
        const node = this.getSequencerNodeReference();
        let audioFiltersFromWrappedNode = [];
        if(node.getStartWrapNodes()){
            for(let i =0; i < node.getStartWrapNodes().length; i++){
                const startNode = node.getStartWrapNodes()[i];
                if(startNode.isModifyingAudioBufferFromWrappedNodeAudioSegment()){
                    audioFiltersFromWrappedNode = audioFiltersFromWrappedNode.concat(startNode.getAudioFilters());
                }
            }
        }
        return audioFiltersFromWrappedNode;
    },

    _getFiltersForAutomaticCrossfade : function( audioContext, startTimeForNodeInSeconds, endTimeForNodeInSeconds, arrayOfAudioNodesUsed, isRenderingForPlayback){
        if(this.getAudioNode()){
            const filterForAutomaticCrossfade = [];
            const targetNode = this.getSequencerNodeReference();
            const audioNode = this.getAudioNode();
            const crossfadeDuratioInMillisecondAtBeginning =Math.abs(this.getRelativeStartTime());
            const crossfadeDuratioInMillisecondAtEnd =Math.abs(this.getRelativeEndTime());
            if( crossfadeDuratioInMillisecondAtBeginning> 0){
                filterForAutomaticCrossfade.push(AudioFilterFactory.getInstance().createFadeStartFilter(0, 1, crossfadeDuratioInMillisecondAtBeginning, true));
            }else{
                if(isRenderingForPlayback && APPLY_CROSSFADE_BETWEEN_WORDS_FOR_SMOOTH_PLAYBACK && (Utils.getInstance().isWordSequencerNodeInstance(targetNode) || Utils.getInstance().isPauseSequencerNodeInstance(targetNode))){
                    filterForAutomaticCrossfade.push(AudioFilterFactory.getInstance().createFadeStartFilter(0, 1, 10, true));
                }
            }
            if(crossfadeDuratioInMillisecondAtEnd > 0){
                filterForAutomaticCrossfade.push(AudioFilterFactory.getInstance().createFadeEndFilter(1, 0, crossfadeDuratioInMillisecondAtEnd, true));
            }else{
                if(isRenderingForPlayback && APPLY_CROSSFADE_BETWEEN_WORDS_FOR_SMOOTH_PLAYBACK && (Utils.getInstance().isWordSequencerNodeInstance(targetNode) || Utils.getInstance().isPauseSequencerNodeInstance(targetNode))){
                    filterForAutomaticCrossfade.push(AudioFilterFactory.getInstance().createFadeEndFilter(1, 0, 10, true));
                }
            }
            return filterForAutomaticCrossfade;
        }
    },

    getAllUserDefinedAudioFilters :function(){
        const nodeSpecificAudioFilters = this.getSequencerNodeReference().getAudioFilters();
        const audioFiltersFromWrappedNode = this._getAudioFiltersFromWrappedNodes();
        return nodeSpecificAudioFilters.concat(audioFiltersFromWrappedNode);
    },

    getAllAudioFiltersForRendering : function(audioContext, startTimeForNodeInSeconds, endTimeForNodeInSeconds, arrayOfAudioNodesUsed, isRenderingForPlayback){
        const filterForAutomaticCrossfade = this._getFiltersForAutomaticCrossfade( audioContext, startTimeForNodeInSeconds, endTimeForNodeInSeconds, arrayOfAudioNodesUsed, isRenderingForPlayback);
        return filterForAutomaticCrossfade.concat(this.getAllUserDefinedAudioFilters());
    },

    getAudioSegmentMediaUrl : function(){
        return this.getSequencerNodeReference().getAudioSegment().getAudioUrl();
    },

    downloadAndCacheUrlAsBlob : function(){
        return this.getSequencer().getAudioBufferCache().loadUrlInBlobCache(this.getAudioSegmentMediaUrl()).then((function(blobUrl){
            this.setCachedBlobUrl(blobUrl);
            if(this.isSequencerNodeReferenceHasVideo()){
                return this.getSequencer().getAudioBufferCache().loadUrlInVideoSizeCache(blobUrl);	
            }
        }).bind(this)).then((function(videoSize){
            if(this.isSequencerNodeReferenceHasVideo() && videoSize){
                this.setCachedVideoWidth(videoSize.videoWidth);
                this.setCachedVideoHeight(videoSize.videoHeight);
            }
            return this.getCachedBlobUrl();
        }).bind(this))
    },

    setCachedBlobUrl : function(blobUrl){
        this.set("cachedBlobUrl",blobUrl);
    },

    setCachedVideoWidth : function(cachedVideoWidth){
        this.set("cachedVideoWidth",cachedVideoWidth);
    },

    setCachedVideoHeight : function(cachedVideoHeight){
        this.set("cachedVideoHeight",cachedVideoHeight);
    },

    getCachedVideoWidth : function(){
        return this.get("cachedVideoWidth");
    },

    getCachedVideoHeight : function(){
        return this.get("cachedVideoHeight");
    },

    getCachedBlobUrl : function(){
        return this.get("cachedBlobUrl")
    },

    isSequencerNodeReferenceHasVideo: function(){
        return this.getSequencerNodeReference() && this.getSequencerNodeReference().getAudioSegment() && this.getSequencerNodeReference().getAudioSegment().getHasVideo();
    },

    getSequencerNodeReferenceVideoWidth: function(){
        return this.getSequencerNodeReference() && this.getSequencerNodeReference().getAudioSegment() && this.getSequencerNodeReference().getAudioSegment().getVideoWidth();
    },

    getSequencerNodeReferenceVideoHeight: function(){
        return this.getSequencerNodeReference() && this.getSequencerNodeReference().getAudioSegment() && this.getSequencerNodeReference().getAudioSegment().getVideoHeight();
    },

    /*createHTMLMediaElementReadyToPlay : function(){
        if(this.getAudioBuffer()){
            const currentTimeOffset = this.getStartTimeOffsetForSourceBuffer()/1000 + (this.getStartTimeOffsetLoopForSourceBuffer()/1000);
            const mediaURL = this.getSequencerNodeReference().getAudioSegment().getAudioUrl();
            const mediaElement = document.createElement(this.isSequencerNodeReferenceHasVideo()?"video":"audio");
            mediaElement.setAttribute("crossorigin", "anonymous");
            mediaElement.setAttribute("webkit-playsinline", "");
            mediaElement.setAttribute("playsinline", "");

            mediaElement.preload = "auto";


            return this.getSequencer().getAudioBufferCache().loadUrlInBlobCache(mediaURL).then((function(blobUrl){
                if (window.MediaStream !== undefined && mediaElement instanceof MediaStream) {
                    mediaElement.srcObject = blobUrl;
                } else {
                    mediaElement.src = blobUrl;
                }
                mediaElement.currentTime = currentTimeOffset;
                return new RSVP.Promise(function(resolve, reject){
                    mediaElement.addEventListener('canplaythrough', (event) => {
                        resolve(mediaElement);
                    });
                    mediaElement.addEventListener('error', () => {
                        resolve(mediaElement);
                    });

                })
            }).bind(this))*/
            /*if (window.MediaStream !== undefined && mediaElement instanceof MediaStream) {
                mediaElement.srcObject = mediaURL+`#t=${currentTimeOffset},${this.getDuration()/1000+currentTimeOffset}`;
            } else {
                mediaElement.src = mediaURL+`#t=${currentTimeOffset},${this.getDuration()/1000+currentTimeOffset}`;
            }
            mediaElement.currentTime = currentTimeOffset;

            return new RSVP.Promise(function(resolve, reject){
                mediaElement.addEventListener('canplaythrough', (event) => {
                    resolve(mediaElement);
                });
                mediaElement.addEventListener('error', () => {
                    resolve(mediaElement);
                });

            })*/

        /*}else{
            return RSVP.Promise.resolve(null);
        }
    },*/




});



export default SequencerNodeSchedulingInfo; 