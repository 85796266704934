import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerOperationLog from "models/audioEditor/sequencerOperationLog/SequencerOperationLog";
import RSVP from "rsvp";

const SequencerOperationCreateDeleteNodesBulkLog =  SequencerOperationLog.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperationLog.apply(this, [attributes, options]);
        
    },

    getOperationName : function(){
        return "Create Delete Node Bulk";
    },

    setArrayOfNodeArrayToDelete : function(arrayOfNodeArrayToDelete){
        this.set("arrayOfNodeArrayToDelete", arrayOfNodeArrayToDelete);
    },

    getArrayOfNodeArrayToDelete : function(){
        return this.get("arrayOfNodeArrayToDelete");
    },


    setArrayOfDeleteNode : function(arrayOfDeleteNode){
        this.set("arrayOfDeleteNode", arrayOfDeleteNode);
    },

    getArrayOfDeleteNode : function(){
        return this.get("arrayOfDeleteNode");
    },




});

export default SequencerOperationCreateDeleteNodesBulkLog; 