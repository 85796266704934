import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import JourneyCreationWizardPageView from "views/journeyCreation/JourneyCreationWizardPageView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PersistentModels from "services/PersistentModels";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import Utils from "models/helper/Utils";
import CustomSelectFieldController from "controllers/common/CustomSelectFieldController";
import ListItemCollection from "collections/ListItemCollection";
import ti18n from "i18n!nls/JourneyCreationWizardi18n";
import RSVP from "rsvp";

const JourneyCreationWizardController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = options.trebbleModel;
        this._hideBackButton = options? options.hideBackButton : false;				
        this.onActionExecutedOnJourneyHandler = options? options.onActionExecutedOnJourneyHandler: null;
        if(!this.onActionExecutedOnJourneyHandler){
            this.onActionExecutedOnJourneyHandler = (function(){});
        }
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    executeActionCallback :function(action, data){
        if(this.onActionExecutedOnJourneyHandler){
            this.onActionExecutedOnJourneyHandler(this.model,action, data);
        }
    },


    createJourney : function(data){
        
        $.mobile.loading("show");

        let name = data.trebbleName;
        const goal =  data.journeyGoal;
        const description =  data.trebbleDescription;
        
        const categoryId = data.trebbleCategoryId;
        const timezone = data.trebbleTimezone;
        const language = data.trebbleLanguage;
        const cityId =  data.trebbleCityId;
        const isPaid =  data.isPaid;
        const costInUSD =  data.costInUSD;
        
        name = Utils.getInstance().capitalizeFirstLetter(name);
        
        return PersistentModels.getInstance().createJourney(name, goal,description, categoryId, cityId, language, timezone, isPaid , costInUSD, null,null, null).then((function(journeySummaryModel){
            this.executeActionCallback("newJourneyInfo", {"name":name,"goal":goal,"description":description,"categoryId": categoryId,"timezone": timezone, "language":language,"cityId":cityId });
            
            $.mobile.loading("hide");
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("Journey", "NewJourneyCreationSucceed", "New journey creation succeed");
            } 
            window.history.back();
            return PersistentModels.getInstance().getRouter().showPublicJourneyDetailPageeByJourneyRadioId( journeySummaryModel.getLoaderKey(), null, true, true);

        }).bind(this)).catch((function(error){
            $.mobile.loading("hide");
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("Onboarding", "NewJourneyCreationFailed", "New journey creation failed");
            } 
            window.alertErrorMessage("Something went wrong when creating this journey. Error:"+ error);
        }).bind(this));

    },

    onViewRemovedFromDocument : function(){

    },


    validateJourneyName : function(name){
        let errorMessage =  null;
        if(!name){
            errorMessage  = window.getI18n(ti18n, "TREBBLE_CANNOT_BE_EMPTY");
        }else{
            if(name && name.length >= Utils.getInstance().getJourneyNameMaxLength()){
                errorMessage = window.getI18n(ti18n, "TREBBLE_NAME_IS_LESS_THAN_X_CHAR");
            }
            if(!name.match(Utils.getInstance().getTrebbleNameRegexValidation())){
                errorMessage = window.getI18n(ti18n, "TREBBLE_NAME_CAN_ONLY_CONTAIN");
            }
            if(name.indexOf("_") != -1){
                errorMessage = window.getI18n(ti18n, "TREBBLE_CANNOT_CONTAIN_HYPHENS");
            }
        }
        const isValid = !errorMessage;
        if(isValid){
            this.view.setTrebbleNameValid(true);
            return RSVP.Promise.resolve(isValid);
        }else{
            this.view.setTrebbleNameValid(isValid, errorMessage);
            return RSVP.Promise.resolve(isValid);
        }
        return isValid;
    },



    validateJourneyDescription : function(description){
        let errorMessage =  null;
        if(!description){
            errorMessage  = window.getI18n(ti18n, "JOURNEY_DESCRIPTION_VALIDATION_ERROR");
        }else{
            if(description && description.length >= Utils.getInstance().getJourneyDescriptionMaxLength()){
                errorMessage = window.getI18n(ti18n, "SORRY_BUT_JOURNEY_DESCRIPTION_IS_BETWEEN_X_CHAR");
            }
        }
        const isValid = !errorMessage;
        this.view.setTrebbleDescriptionValid(isValid, errorMessage);
        return RSVP.Promise.resolve(isValid);
    },

    validateJourneyGoal : function(goal){
        let errorMessage =  null;
        if(!goal){
            errorMessage  = window.getI18n(ti18n, "JOURNEY_GOAL_EMPTY_VALIDATION_ERROR");
        }else{
            if(goal && goal.length >= Utils.getInstance().getJourneyGoalMaxLength()){
                errorMessage = window.getI18n(ti18n, "SORRY_BUT_JOURNEY_GOAL_IS_BETWEEN_X_CHAR");
            }
        }
        const isValid = !errorMessage;
        this.view.setJourneyGoalValid(isValid, errorMessage);
        return RSVP.Promise.resolve(isValid);
    },


    getCities : function(keyword){
        if(keyword){
            $.mobile.loading("show");
            return TrebbleClientAPIHelper.getInstance().searchForCities(keyword,window.getAppUsedLanguage()).then((function(cityInfoArray){
                const cityListModelArray = [];
                for(let i =0; i <  cityInfoArray.length; i++){
                    const cityInfo = cityInfoArray[i];
                    cityListModelArray.push(Utils.getInstance().getListItemModel(cityInfo.place_id,cityInfo.description));
                }
                $.mobile.loading("hide");
                const listItemCollection = new ListItemCollection(cityListModelArray);
                return listItemCollection.models;
            }).bind(this)).catch((function(error){
                $.mobile.loading("hide");
                throw error;
            }).bind(this));
        }else{
            return RSVP.Promise.resolve([]);
        }

    },

    _buildCitySelectFieldController : function(){
        const controllerParams = {};
        controllerParams.singleSelect =  true;
        controllerParams.propertyFunction = "getLabel";
        controllerParams.propertyIdFunction = "getId";
        controllerParams.showEmtptyInfoBox  = false;
        controllerParams.loadHandler = this.getCities.bind(this);
        controllerParams.defaultText = window.getI18n(ti18n, "CITY");
        controllerParams.enableSearch =  true;
        const customSelectFieldController = new CustomSelectFieldController(controllerParams);
        return customSelectFieldController;
    },

    _initControllers : function(){
        this.citySelectFieldController = this._buildCitySelectFieldController();
        this.listenTo(this.citySelectFieldController, "selectionChanged",this._onCitySelectionChanged.bind(this) );
        const promiseArray = [];

        promiseArray.push(this.citySelectFieldController.buildModelAndRenderView());
        return RSVP.Promise.all(promiseArray);
    },


    _onCitySelectionChanged : function(cityInfoListItemModel){
        this.view.onTrebbleCategoryChanged();
    },

    onViewRemoved:function(){
        this.stopListening();
    },

    buildModelAndRenderView : function() {
        return this._initControllers().then((function(){
            return new RSVP.Promise((function(resolve, reject) {
                try {
                    const options = {};
                    options.hideBackButton  = this._hideBackButton;
                    options.citySelectFieldView  = this.citySelectFieldController.getView();
                    this.view = new JourneyCreationWizardPageView(options);
                    this.view.render();
                    this.listenTo(this.view, "onFinishButtonClicked", this.createJourney);
                    this.listenTo(this.view, "removedFromPage", this.onViewRemovedFromDocument);
                    this.listenTo(this.view, "validateJourneyName", this.validateJourneyName);
                    this.listenTo(this.view, "validateJourneyGoal", this.validateJourneyGoal);
                    this.listenTo(this.view, "validateJourneyDescription", this.validateJourneyDescription);
                    this.listenTo(this.view, "remove", this.onViewRemoved.bind(this));

                    resolve();
                    if(window.trebbleAnalyticsHelper){
                        window.trebbleAnalyticsHelper.trackEvent("journey", "JourneyCreationWizardPageOpened", "Journey Creation Wizard Page Opened");
                    } 
                } catch (error) {
                    reject(error);
                }

            }).bind(this));
}).bind(this));

}

});

export default JourneyCreationWizardController;