import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import LoudnessMeter from "libs/needles/needles";
import RSVP from "rsvp";


const IntegratedLoudnessHelper =  Backbone.Model.extend({
    idAttribute: "id",

    constructor: function(attributes, options) {
        Backbone.Model.apply(this, [attributes, options]);

    },

    getIntegratedLoudness : function(buffer){
        return new RSVP.Promise((function(resolve, reject){
            try{
                const offlineAudioContext = new OfflineAudioContext(buffer.numberOfChannels, buffer.length, buffer.sampleRate);
                const source = offlineAudioContext.createBufferSource()
                source.buffer = buffer

                const loudnessMeter = new LoudnessMeter({
                    source: source,
                    modes: ['integrated'],
                    workerUri: './js/libs/needles/needles-worker.js'
                })

                loudnessMeter.on('dataavailable', function (event) {
                    try{
                        loudnessMeter.terminate();
                    }catch(error){
                        console.error("Failed to terminate worker for measuring loudness. Error: "+error);
                    }
                    resolve(event.data.value);
                })

                loudnessMeter.start()
            }catch(error){
                reject(error);
            }
        }).bind(this));
    },

    getMaxAudioBufferDuration : function(audioBuffer){
        return audioBuffer && audioBuffer._durationCalculatedByMediaElement && audioBuffer._durationCalculatedByMediaElement > audioBuffer.duration? audioBuffer._durationCalculatedByMediaElement: audioBuffer.duration;
    },


    getIntegratedLoudnessOld : function(buffer){
        return new RSVP.Promise((function(resolve, reject){
            try{
                const channel_count = buffer.numberOfChannels;

                const untouched_buffers = [];

                    // we will also need the untouched channels for PSR calculation
                const leftChannel_untouched = buffer.getChannelData(0);
                untouched_buffers.push(leftChannel_untouched);
                if (channel_count == 2){
                    const rightChannel_untouched = buffer.getChannelData(1);
                    untouched_buffers.push(rightChannel_untouched);
                }
                if (channel_count > 2){
                    console.error("Cannot handle more than 2 channels.")
                    reject("Cannot handle more than 2 channels.")
                    return;
                }

                    /* INTEGRATED LOUDNESS */

                    //get an audioBuffer, in which EBU-S values are stored
                const lengthInSeconds = leftChannel_untouched.length / buffer.sampleRate;
                    //do not resample
                const targetSampleRate = buffer.sampleRate;
                const OAC_IL = new OfflineAudioContext(channel_count, lengthInSeconds * targetSampleRate, targetSampleRate);
                const source = OAC_IL.createBufferSource();
                source.buffer = buffer;

                const splitter = OAC_IL.createChannelSplitter(2);
                const merger = OAC_IL.createChannelMerger(2);

                    //first stage shelving filter
                const highshelf_filter = OAC_IL.createBiquadFilter();
                highshelf_filter.type = "highshelf";
                highshelf_filter.Q.value = 1;
                highshelf_filter.frequency.value = 1500;
                highshelf_filter.gain.value = 4;

                    // second stage highpass filter
                const highpass_filter = OAC_IL.createBiquadFilter();
                highpass_filter.frequency.value = 76;
                highpass_filter.Q.value = 1;
                highpass_filter.type = "highpass";

                    //SQUARING EVERY CHANNEL
                const square_gain = OAC_IL.createGain();
                square_gain.gain.value = 0;

                    //CONNECTING EBU GRAPH
                source.connect(highshelf_filter);
                highshelf_filter.connect(highpass_filter);
                highshelf_filter.connect(square_gain);
                highpass_filter.connect(square_gain.gain);
                square_gain.connect(OAC_IL.destination);

                source.start();

                OAC_IL.startRendering().then((function(renderedBuffer) {
                    source.disconnect();
                    highshelf_filter.disconnect();
                    highpass_filter.disconnect();
                    square_gain.disconnect();
                    OAC_IL.destination.disconnect();

                        //console.log('Rendering completed successfully');
                    const signal_filtered_squared = [
                        renderedBuffer.getChannelData(0),
                        channel_count == 2?renderedBuffer.getChannelData(1): renderedBuffer.getChannelData(0)
                        ];

                    const il_worker = new Worker("js/libs/loudEv/workers/integrated-loudness-worker.js");
                        //compute integrated loudness
                    il_worker.postMessage({
                        buffers: signal_filtered_squared,
                        duration: this.getMaxAudioBufferDuration(buffer)
                    });

                    il_worker.onmessage = (function(e) {
                        const data = e.data;
                        if (data.type == "finished"){
                            resolve(data.integratedLoudness);
                            il_worker.terminate();
                        }
                    }).bind(this)
                }).bind(this));
            }catch(error){
                reject(error);
            }
        }).bind(this))

    }


});

const IntegratedLoudnessHelperInstance = new IntegratedLoudnessHelper();

export default {
    getInstance : function() {
        return IntegratedLoudnessHelperInstance;
    }
}; 