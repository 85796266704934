import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import FacebookEventTrackingHelper from "models/helper/FacebookEventTrackingHelper";
import RSVP from "rsvp";

const eventTypes = {
    "BEFORE_LOGOUT" : "beforeLogout",
    "SUBSCRIBE_TOOLTIP_DISCARDED_BY_USER": "subscribreTooltipDiscardedByUser",
    "SUBSCRIBE_TO_JOURNEY_TOOLTIP_DISCARDED_BY_USER":"subscribreToJourneyTooltipDiscardedByUser",
    "SHARE_TOOLTIP_DISCARDED_BY_USER": "shareTooltipDiscardedByUser",
    "ENABLE_NOTIFICATION_TOGGLE_DISCARDED_BY_USER": "enableNotificationToggleDiscardedByUser",
    "LISTEN_ON_VOICE_ENABLED_DEVICES_TOOLTIP_DISCARDED_BY_USER": "listenOnVoiceEnabledDevicesTooltipDiscardedByUser",
    "AUTO_PREVIEW_TOOLTIP_DISCARDED_BY_USER": "autoPreviewTooltipDiscardedByUser",
    "USER_INFO_UPDATED": "userInfoUpdated",
    "PREFERRED_AUDIO_DEVICE_ID_UPDATED":"preferredAudioDeviceIdUpdated"
}

const songListKey = "songList";
const userTasteProfileIdKey = "trebbleEchonestTasteProfileId";
const trebbleUsernameKey = "defaultTrebbleUsername";
const trebbleUserPasswordKey = "defaultTrebblePassword";
const authentificationTokenKey = "authentificationToken";
const databaseInitializedKey  = "databaseInitializedKey";
const jqmHistoryStackKey = "jqmHistoryStackKey";
const lastSongPlayedUriKey = "lastSongPLayedUriKey";
const lastSongUrisOfPlayerQueueKey = "lastSongUrisOfPlayerQueueKey";
const autoPlaylistVersionKey = "AutoPlaylists";
const defaultTagVersionKey = "defaultTagVersionKey";
const defaultGroupTagVersionKey = "defaultGroupTagVersionKey";
const pushNotificationRegistrationIdKey = "pushNotificationRegistrationIdKey";
const pushNotificationRegistrationTypeKey = "pushNotificationRegistrationTypeKey";
const firstApplicationLaunchDoneKey = "firstApplicationLaunchKey";
const whatsNewPageShownPrefixKey = "whatsNewPageShownPrefix";
const whatsACapsuleCapsulePlayedKey = "whatsACapsuleCapsulePlayedKey";
const usingNewLoginModuleKey  = "usingNewLoginModule";
const helpForBloggerDisplayedKey = "helpForBloggerDisplayedKey";
const lastAppFailedStart = "lastAppFailedStart";
const subscribreTooltipDiscardedByUser =  "subscribreTooltipDiscardedByUser";
const subscribreToJourneyTooltipDiscardedByUser =  "subscribreToJourneyTooltipDiscardedByUser";
const shareTooltipDiscardedByUser =  "shareTooltipDiscardedByUser";
const listenOnVoiceEnabledDevicesTooltipDiscardedByUser = "listenOnVoiceEnabledDevicesTooltipDiscardedByUser";
const shortcutToPlayCapsuleFeedWasAddedToSiri = "shortcutToPlayCapsuleFeedWasAddedToSiri";
const shortcutPhraseToPlayCapsuleFeedWasAddedToSiri = "shortcutPhraseToPlayCapsuleFeedWasAddedToSiri";
const autoPreviewTooltipDiscardedByUser =  "autoPreviewTooltipDiscardedByUser";
const manuallyAgreedToTurnOnNotification = "manuallyAgreedToTurnOnNotification";
const loggedInUserHasBeenPromptedToTurnOnNotifications = "loggedInUserHasBeenPromptedToTurnOnNotifications";
const highFiveForAddingFirstSubscriptionWasDisplayed = "highFiveForAddingFirstSubscriptionWasDisplayed";
const userHasChangeLanguageInExplorePage = "userHasChangeLanguageInExplorePage";
const userHasChangeCategogryInExplorePage = "userHasChangeCategogryInExplorePage";
const suggestionToChangeCategoryOrLanguageDisplayed = "suggestionToChangeCategoryOrLanguageDisplayed";
const numberOfDaysCapsuleFeedWasPlayed_deprecated =  "numberOfDaysCapsuleFeedWasPlayed";
const numberOfDaysCapsuleFeedWasPlayed =  "numberOfDaysCapsuleFeedWasPlayed_new";
const lastDateUserPlayedCapsuleFeed = "lastDateUserPlayedCapsuleFeed";
const lastTimeUserWasPromptedToRateTheApp = "lastTimeUserWasPromptedToRateTheAppV2";

const numberOfDaysTheAppWasRan =  "numberOfDaysTheAppWasRan";
const lastTimeTheAppWasRan = "lastTimeTheAppWasRan";
const userRatedTheApp = "userRatedTheApp";
const appDarkMode = "appDarkMode";
const lastLanguageUsedDuringTranscriptionKey = "lastLanguageUsedDuringTranscriptionKey";
const useEditorDarkThemeKey = "useEditorDarkThemeKey";
const userWasAskedToRateTheApp = "userWasAskedToRateTheApp";
const numberOfTimesUserRefuseToRateTheApp = "numberOfTimesUserRefuseToRateTheApp";
const numberOfTimesUserPostponedToRateTheApp = "numberOfTimesUserPostponedToRateTheApp";
const numberOfTimesUserSentSuggestionToImproveTheApp = "numberOfTimesUserSentSuggestionToImproveTheApp";

const suggestionToListenToFeedForFirstTimeWasDisplayed =  "suggestionToListenToFeedForFirstTimeWasDisplayed";
const requestToGiveFeedBackOnContentWasDisplayed = "requestToGiveFeedBackOnContentWasDisplayed";

const enableNotificationToggleDiscardedByUser = "enableNotificationToggleDiscardedByUser";

const userExperimentalFeaturesKey = "userExperimentalFeatures";
const alacarteFeaturesKey = "alacarteFeaturesKey";
const premiumFeaturesKey = "premiumFeaturesKey";

const SHARED_PREFERENCES_NAME = "fm_trebble_shared_preferences";
const LOGGED_IN_USER_JSON_SERVER_REF_STRING_KEY_SHARED_PREF ="fm.trebble.loggedInUserJsonServerRefString";
const LOGGED_IN_USER_TOKEN_KEY_SHARED_PREF ="fm.trebble.loggedInUserToken";
const AUDIO_ANNOUNCEMENTS_DISABLED_KEY_SHARED_PREF ="fm.trebble.audioAnnouncementsEnabled";
const MANUALLY_ENBALED_DAILY_FEED_NOTIF = "manually_enabled_daily_feed_notif_key";
const DOWNLOAD_FILES_TO_DELETE ="downloadedFilesToCache";
const WAS_AWARDED_TREBBLE_PLUS_TRIAL ="wasAwaredTrebblePlusTrial";
const TREBBLE_PLUS_FREE_TRIAL_START_DATE = "freeTrialStartDate";
const TREBBLE_PLUS_FREE_TRIAL_END_DATE = "freeTrialEndDate";
const TOOLTIP_TO_EXPLAND_FLOATING_PLAYER_WAS_DISMISSED ="tooltipToExpandFLoadingPlayerWasDismissed";
const AUDIO_EDITOR_ONBOARDING_WAS_COMPLETED_OR_DISMISSED ="audioEditorOnboardingWasCompletedOrDismissed";
const MY_LIBRARY_ONBOARDING_WAS_COMPLETED_OR_DISMISSED ="myLibraryOnboardingWasCompletedOrDismissed";
const RECORD_PAGE_ONBOARDING_WAS_COMPLETED_OR_DISMISSED = "recordPageOnboardingWasCompletedOrDismissed";
const SAVE_FILE_OR_EDIT_PAGE_ONBOARDING_WAS_COMPLETED_OR_DISMISSED = "saveFileOrEditPageOnboardingWasCompletedOrDismissed";

const DISABLE_AUDIO_ANNOUNCEMENTS = "disableAudioAnnouncements";
const AUDIO_ANNOUNCEMENT_MANUALLY_CHANGED = "audioAnnouncementManuallyChanged";
const PREFERRED_AUDIO_DEVICE_ID = "preferredAudioDeviceId";

const RANDOM_ID_FOR_USER_KEY ="randomIdForUser";

const DARK_MODE_VALUES = {"ENABLED":"ENABLED","DISABLED":"DISABLED"};

//This is a placeholder for token which was passed via url
//this token will not erase the one store in localstorage but 
//will temporary act like a token as long the user is not closing his browser
let temporarlySessionTokenInfo = null;
const TrebbleLocalStorageHelperModel = Backbone.Model.extend({
    "default" : {

    },

    initialize : function() {
        this._userInfoChangedListeners = {};
        this._firstApplicationLaunchDoneListeners = {};
        this._localStorageMemCache= {};
        this._updateModelWithLocalStorageValues();
        document.addEventListener('playingCapsuleFeed', this.onCapsuleFeedPlayed.bind(this), false);
    },

    isLocalStorageEnabled : function(){
        //this is needed because in some browsers like safari, if you are in incognito mode , the browser will throw weird errors when trying to access the localstorage. view  https://michalzalecki.com/why-using-localStorage-directly-is-a-bad-idea/
        if(this._isLocalStorageTested){
            return this._isLocalStorageAvailable ;
        }else{
            try {
                localStorage.setItem("testStore", "testStore");
                localStorage.removeItem("testStore");
                this._isLocalStorageTested = true;
                this._isLocalStorageAvailable = true;
                return true;
            } catch(e) {
                this._isLocalStorageTested = true;
                this._isLocalStorageAvailable = false;
                return false;
            }
        }
    },

    _updateModelWithLocalStorageValues : function(){
        for(let key in window.localStorage){
            this.set(key, window.localStorage.getItem(key));
        }
    },
    
    getItemFromLocalStorage : function(key){
        if(this.isLocalStorageEnabled()){
            return window.localStorage.getItem(key);
        }else{
            return this._localStorageMemCache[key];
        }
    },
    
    setItemFromLocalStorage : function(key, value){
        if(this.isLocalStorageEnabled()){
            if(!value && value !== false){
                this.removeItemFromLocalStorage(key);
            }else{
                window.localStorage.setItem(key, value);
                this.set(key,value);
            }
        }else{
            this._localStorageMemCache[key] = value;
        }
    },
    
    removeItemFromLocalStorage : function(key){
        if(this.isLocalStorageEnabled()){
            const itemRemoved = window.localStorage.removeItem(key);
            this.unset(key);
            return itemRemoved;
        }else{
            delete this._localStorageMemCache[key];
        }
    },

    generateRandomId: function() {
        // Math.random should be unique because of its seeding algorithm.
        // Convert it to base 36 (numbers + letters), and grab the first 9
        // characters
        // after the decimal.
        return '_' + Math.random().toString(36).substr(2, 9);
    },

    // function to save the retrieve list of song in the local storage
    saveSongList : function(songsInfoJSON) {
        this.setItemFromLocalStorage(songListKey, JSON
            .stringify(songsInfoJSON));
    },

    setDatabaseInitialized : function(){
        this.setItemFromLocalStorage(databaseInitializedKey,true);
    },
    
    setFirstApplicationLaunchDone : function(){
        this.setItemFromLocalStorage(firstApplicationLaunchDoneKey,true);
        for(let handlerKey  in this._firstApplicationLaunchDoneListeners){
            const handlerFunction= this._firstApplicationLaunchDoneListeners[handlerKey];
            if(handlerFunction != null){
                try{
                    handlerFunction();
                }catch(error){
                    window.alertErrorMessage(error);
                }
            }
        }
    },

    awardFreeTrial : function(numberOfDays, startDate, endDate){
        if(!this.getItemFromLocalStorage(WAS_AWARDED_TREBBLE_PLUS_TRIAL) && Number.isInteger(numberOfDays) && numberOfDays > 0 && startDate && endDate){
            this.setItemFromLocalStorage(WAS_AWARDED_TREBBLE_PLUS_TRIAL,true);
            this.setItemFromLocalStorage(TREBBLE_PLUS_FREE_TRIAL_START_DATE,(startDate).toString());
            this.setItemFromLocalStorage(TREBBLE_PLUS_FREE_TRIAL_END_DATE,endDate.toString());
            return true;
        }
        return false;
    },

    generateUUID: function() { // Public Domain/MIT
        let d = new Date().getTime();//Timestamp
        let d2 = (performance && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            let r = Math.random() * 16;//random number between 0 and 16
            if(d > 0){//Use timestamp until depleted
                r = (d + r)%16 | 0;
                d = Math.floor(d/16);
            } else {//Use microseconds since page-load if supported
                r = (d2 + r)%16 | 0;
                d2 = Math.floor(d2/16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    },

    getTrebbleUseUUIDCookie : function(){
        const TREBBLE_UID_COOKIE_NAME = "trebbleUserUUID";
        if(!this.isCookieSet(TREBBLE_UID_COOKIE_NAME)){
            this.setCookie(TREBBLE_UID_COOKIE_NAME, this.generateUUID());
        }
        return this.getCookie(TREBBLE_UID_COOKIE_NAME);
    },



    setCookie: function(cname, cvalue, exdays) {
        try{
            const d = new Date();
            d.setTime(d.getTime() + (exdays*24*60*60*1000));
            const expires = "expires="+ d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        }catch(error){
            console.error("Error setting a cookie. Error:"+error);
        }
    },

    deleteCookie: function(cname) {
        try{
            const d = new Date();
            d.setTime(d.getTime() - 1000);// a dathe epast
            const expires = "expires="+ d.toUTCString();
            document.cookie = cname + "=" + "" + ";" + expires + ";path=/";
        }catch(error){
            console.error("Error dleting a cookie. Error:"+error);
        }
    },


    isCookieSet : function(cname){
        const cvalue = this.getCookie(cname);
        if (cvalue != "") {
            return true;
        } else {
            return false;
        }
    },

    getUniqueUserIdentifier : function(){
        try{
            return this.isCurrentLoggedUserNotAVisitor()? this.getLoggedInUserID() : this.getTrebbleUseUUIDCookie();
        }catch(error){
            console.error("Error retrieving Unique user identifier.Error:"+error);
        }
    },

    getCookie: function(cname) {
        try{
            const name = cname + "=";
            const decodedCookie = decodeURIComponent(document.cookie);
            const ca = decodedCookie.split(';');
            for(let i = 0; i <ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        }catch(error){
            console.error("Error getting a cookie. Error:"+error);
        }
        return "";
    },

    hasTrebblePlusFreeTrial : function(){
        try{
            if(this.getItemFromLocalStorage(WAS_AWARDED_TREBBLE_PLUS_TRIAL)){
                const startDateString = this.getItemFromLocalStorage(TREBBLE_PLUS_FREE_TRIAL_START_DATE);
                const endDateString = this.getItemFromLocalStorage(TREBBLE_PLUS_FREE_TRIAL_END_DATE);
                if(startDateString && endDateString){
                    const startDate = new Date(startDateString);
                    const endDate = new Date(endDateString);
                    const currentDate = new Date();
                    return startDate.getTime() <= currentDate.getTime() && endDate.getTime() >= currentDate.getTime();
                }

            }
        }catch(error){
            console.error(error);
        }
        return false;

    },

    setHelpForBloggerDisplayed : function(){
        this.setItemFromLocalStorage(helpForBloggerDisplayedKey,true);
    },

    isHelpForBloggerDisplayedKey : function(){
        if(this.getItemFromLocalStorage(helpForBloggerDisplayedKey)){
            return true;
        }else{
            return false;
        }
    },

    isWhatsACapsuleCapsulePlayed : function(){
        if(this.getItemFromLocalStorage(whatsACapsuleCapsulePlayedKey)){
            return true;
        }else{
            return false;
        }
    },

    isAudioAnnouncementsDisabled : function(){
        if(this.getItemFromLocalStorage(DISABLE_AUDIO_ANNOUNCEMENTS)){
            return true;
        }else{
            return false;
        }
    },


    isAudioAnnouncementsManuallyChanged : function(){
        if(this.getItemFromLocalStorage(AUDIO_ANNOUNCEMENT_MANUALLY_CHANGED)){
            return true;
        }else{
            return false;
        }
    },

    isUserRatedTheApp : function(){
        if(this.getItemFromLocalStorage(userRatedTheApp)){
            return true;
        }else{
            return false;
        }
    },

    wasDarkModeManuallyConfigured : function(){
        if(this.getItemFromLocalStorage(appDarkMode)){
            return true;
        }else{
            return false;
        }
    },

    isAppConfiguredToBeInDarkMode : function(){
        if(this.getItemFromLocalStorage(appDarkMode)){
            return this.getItemFromLocalStorage(appDarkMode) == DARK_MODE_VALUES.ENABLED;
        }else{
            return false;
        }
    },

    isAppConfiguredToBeAlwaysInDarkMode : function(){
        if(this.getItemFromLocalStorage(appDarkMode)){
            return this.getItemFromLocalStorage(appDarkMode) == DARK_MODE_VALUES.ENABLED;
        }else{
            return false;
        }
    },


    setAppConfiguredToBeAlwaysInDarkMode : function(configureAppToBeInDarkMode){
        if(configureAppToBeInDarkMode){
            this.setItemFromLocalStorage(appDarkMode, DARK_MODE_VALUES.ENABLED);
        }else{
            this.removeItemFromLocalStorage(appDarkMode);
        }
    },

    setUserRatedTheApp :function(){
        this.setItemFromLocalStorage(userRatedTheApp, true);
        if(window.trebbleAnalyticsHelper && !this._analyticsRecordedForCapsuleAudioRecording){
            window.trebbleAnalyticsHelper.trackEvent("AppRating", "UserRatedApp", "User rated app");
        }
        FacebookEventTrackingHelper.getInstance().trackUserRatedApp();
    },

    isUserWasAskedToRateTheApp : function(){
        if(this.getItemFromLocalStorage(userWasAskedToRateTheApp)){
            return true;
        }else{
            return false;
        }
    },

    setUserWasAskedToRateTheApp :function(){
        this.setItemFromLocalStorage(userWasAskedToRateTheApp, true);
    },

    setWhatsACapsuleCapsulePlayed : function(){
        this.setItemFromLocalStorage(whatsACapsuleCapsulePlayedKey, true);
    },

    setTooltipToExpandFloatingPlayerWasDismissed : function(){
        this.setItemFromLocalStorage(TOOLTIP_TO_EXPLAND_FLOATING_PLAYER_WAS_DISMISSED, true);
    },

    isTooltipToExpandFloatingPlayerWasDismissed : function(){
        if(this.getItemFromLocalStorage(TOOLTIP_TO_EXPLAND_FLOATING_PLAYER_WAS_DISMISSED)){
            return true;
        }else{
            return false;
        }
    },

    setAudioEditorOnboardingWasCompletedOrDismissed: function(){
        this.setItemFromLocalStorage(AUDIO_EDITOR_ONBOARDING_WAS_COMPLETED_OR_DISMISSED, true);
    },

    isAudioEditorOnboardingWasCompletedOrDismissed : function(){
        if(this.getItemFromLocalStorage(AUDIO_EDITOR_ONBOARDING_WAS_COMPLETED_OR_DISMISSED)){
            return true;
        }else{
            return false;
        }
    },

    setMyLibraryOnboardingWasCompletedOrDismissed: function(){
        this.setItemFromLocalStorage(MY_LIBRARY_ONBOARDING_WAS_COMPLETED_OR_DISMISSED, true);
    },

    isMyLibraryOnboardingWasCompletedOrDismissed : function(){
        if(this.getItemFromLocalStorage(MY_LIBRARY_ONBOARDING_WAS_COMPLETED_OR_DISMISSED)){
            return true;
        }else{
            return false;
        }
    },

    setRecordPageOnboardingWasCompletedOrDismissed: function(){
        this.setItemFromLocalStorage(RECORD_PAGE_ONBOARDING_WAS_COMPLETED_OR_DISMISSED, true);
    },

    isRecordPageOnboardingWasCompletedOrDismissed : function(){
        if(this.getItemFromLocalStorage(RECORD_PAGE_ONBOARDING_WAS_COMPLETED_OR_DISMISSED)){
            return true;
        }else{
            return false;
        }
    },

    setSaveOrEditPageOnboardingWasCompletedOrDismissed: function(){
        this.setItemFromLocalStorage(SAVE_FILE_OR_EDIT_PAGE_ONBOARDING_WAS_COMPLETED_OR_DISMISSED, true);
    },

    isSaveOrEditPageOnboardingWasCompletedOrDismissed : function(){
        if(this.getItemFromLocalStorage(SAVE_FILE_OR_EDIT_PAGE_ONBOARDING_WAS_COMPLETED_OR_DISMISSED)){
            return true;
        }else{
            return false;
        }
    },



    isDatabaseInitialized : function(){
        if(this.getItemFromLocalStorage(databaseInitializedKey)){
            return true;
        }else{
            return false;
        }
    },
    
    isFirstApplicationLaunchDone : function(){
        if(this.getItemFromLocalStorage(firstApplicationLaunchDoneKey)){
            return true;
        }else{
            return false;
        }
    },
    isUsingNewLoginModule : function(){
        if(this.getItemFromLocalStorage(usingNewLoginModuleKey)){
            return true;
        }else{
            return true;
        }
    },

    isSubscribreTooltipDiscardedByUser : function(){
        if(this.getItemFromLocalStorage(subscribreTooltipDiscardedByUser)){
            return true;
        }else{
            return false;
        }
    },

    isSubscribreToJourneyTooltipDiscardedByUser : function(){
        if(this.getItemFromLocalStorage(subscribreToJourneyTooltipDiscardedByUser)){
            return true;
        }else{
            return false;
        }
    },

    isShareTooltipDiscardedByUser : function(){
        if(this.getItemFromLocalStorage(shareTooltipDiscardedByUser)){
            return true;
        }else{
            return false;
        }
    },

    isEnableNotificationToggleDiscardedByUser : function(){
        if(this.getItemFromLocalStorage(enableNotificationToggleDiscardedByUser)){
            return true;
        }else{
            return false;
        }
    },

    isListenOnVoiceEnabledDevicesTooltipDiscardedByUser : function(){
        if(this.getItemFromLocalStorage(listenOnVoiceEnabledDevicesTooltipDiscardedByUser)){
            return true;
        }else{
            return false;
        }
    },

    isAutoPreviewTooltipDiscardedByUser : function(){
        if(this.getItemFromLocalStorage(autoPreviewTooltipDiscardedByUser)){
            return true;
        }else{
            return false;
        }
    },

    isLoggedInUserManuallyAgreedToTurnOnNotification : function(){
        if(this.getItemFromLocalStorage(manuallyAgreedToTurnOnNotification)){
            return true;
        }else{
            return false;
        }
    },

    isloggedInUserHasBeenPromptedToTurnOnNotifications : function(){
        if(this.getItemFromLocalStorage(loggedInUserHasBeenPromptedToTurnOnNotifications)){
            return true;
        }else{
            return false;
        }
    },

    isHighFiveForAddingFirstSubscriptionWasDisplayed : function(){
        if(this.getItemFromLocalStorage(highFiveForAddingFirstSubscriptionWasDisplayed)){
            return true;
        }else{
            return false;
        }
    },

    isSuggestionToListenToFeedForFirstTimeWasDisplayed : function(){
        if(this.getItemFromLocalStorage(suggestionToListenToFeedForFirstTimeWasDisplayed)){
            return true;
        }else{
            return false;
        }
    },

    isRequestToGiveFeedBackOnContentWasDisplayed : function(){
        if(this.getItemFromLocalStorage(requestToGiveFeedBackOnContentWasDisplayed)){
            return true;
        }else{
            return false;
        }
    },

    isUserHasChangeCategogryInExplorePage : function(){
        if(this.getItemFromLocalStorage(userHasChangeCategogryInExplorePage)){
            return true;
        }else{
            return false;
        }
    },

    isUserHasChangeLanguageInExplorePage : function(){
        if(this.getItemFromLocalStorage(userHasChangeLanguageInExplorePage)){
            return true;
        }else{
            return false;
        }
    },


    getNumberOfTimesUserRefuseToRateTheApp : function(){
        const n = this.getItemFromLocalStorage(numberOfTimesUserRefuseToRateTheApp);
        if(!n){
            return 0;
        }else{
            return n;
        }
    },


    incrementNumberOfTimesUserRefuseToRateTheApp : function(){
        this.setItemFromLocalStorage(numberOfTimesUserRefuseToRateTheApp, this.getNumberOfTimesUserRefuseToRateTheApp()+ 1);
        if(window.trebbleAnalyticsHelper && !this._analyticsRecordedForCapsuleAudioRecording){
            window.trebbleAnalyticsHelper.trackEvent("AppRating", "UserRefusedToRateApp", "User refuse to rate app");
        }
        FacebookEventTrackingHelper.getInstance().trackUserRefusedToRateApp();
    },

    

    getNumberOfTimesUserPostponedToRateTheApp : function(){
        const n = this.getItemFromLocalStorage(numberOfTimesUserPostponedToRateTheApp);
        if(!n){
            return 0;
        }else{
            return n;
        }
    },

    setUserManuallyEnabledDisabledDailyFeedNotification : function(enabled){
        this.setItemFromLocalStorage(MANUALLY_ENBALED_DAILY_FEED_NOTIF, enabled);
    },

    getUserManuallyEnabledDisabledDailyFeedNotification : function(){
        const value =  this.getItemFromLocalStorage(MANUALLY_ENBALED_DAILY_FEED_NOTIF);
        if(value ==="false"){
            return false;
        }
        if(value ==="true"){
            return true;
        }
        return value;
    },

    incrementNumberOfTimesUserPostponedToRateTheApp : function(){
        this.setItemFromLocalStorage(numberOfTimesUserPostponedToRateTheApp, this.getNumberOfTimesUserPostponedToRateTheApp()+ 1);
        const numberOfTimesUserPosponed = this.getNumberOfTimesUserPostponedToRateTheApp(); 
        if(window.trebbleAnalyticsHelper && !this._analyticsRecordedForCapsuleAudioRecording){
            window.trebbleAnalyticsHelper.trackEvent("AppRating", "UserPostponedAppRating", "User postponed app rating",numberOfTimesUserPosponed);
        }
        FacebookEventTrackingHelper.getInstance().trackUserPostponedAppRating();
        FacebookEventTrackingHelper.getInstance().trackNumberOfTimesUserPostponedAppRating(numberOfTimesUserPosponed);
    },

    getNumberOfTimesUserSentSuggestionToImproveTheApp : function(){
        const n = this.getItemFromLocalStorage(numberOfTimesUserSentSuggestionToImproveTheApp);
        if(!n){
            return 0;
        }else{
            return n;
        }
    },

    incrementNumberOfTimesUserSentSuggestionToImproveTheApp : function(){
        this.setItemFromLocalStorage(numberOfTimesUserSentSuggestionToImproveTheApp, this.getNumberOfTimesUserSentSuggestionToImproveTheApp()+ 1);
        if(window.trebbleAnalyticsHelper && !this._analyticsRecordedForCapsuleAudioRecording){
            window.trebbleAnalyticsHelper.trackEvent("AppRating", "UserSentSuggestionToImproveApp", "User sent suggestion to improve the app");
        }
        FacebookEventTrackingHelper.getInstance().trackUserSentSuggestionToImproveApp();
    },


    getNumberOfDaysCapsuleFeedWasPlayed : function(){
        const oldValue = this.getItemFromLocalStorage(numberOfDaysCapsuleFeedWasPlayed_deprecated);
        if(oldValue){
            const newConvertedValue = oldValue.length;
            this.setItemFromLocalStorage(numberOfDaysCapsuleFeedWasPlayed, newConvertedValue);
            this.removeItemFromLocalStorage(numberOfDaysCapsuleFeedWasPlayed_deprecated);
        }

        const n = this.getItemFromLocalStorage(numberOfDaysCapsuleFeedWasPlayed);
        if(!n){
            return 0;
        }else{
            return parseInt(n);
        }
    },

    getRandomUserIdForUser :function(){
        let currentValue =  this.getItemFromLocalStorage(RANDOM_ID_FOR_USER_KEY);
        if(!currentValue){
            currentValue = "anonymous_client_user_id_"+ this.generateRandomId();
            this.setItemFromLocalStorage(RANDOM_ID_FOR_USER_KEY, currentValue);
        }
        return currentValue;
    },

    onCapsuleFeedPlayed : function(){
        const today = new Date();
        const dd = today.getDate();
        const mm = today.getMonth() + 1; //January is 0!
        const yyyy = today.getFullYear();
        const dateTodayFormatted = dd +"-"+mm+"-"+yyyy;

        const lastTime = this.getLastDateUserPlayedCapsuleFeed();
        const lastDate = !lastTime? null : new Date(lastTime);
        const lastDateFormatted = !lastDate? null:  lastDate.getDate() +"-"+(lastDate.getMonth() + 1)+"-"+lastDate.getFullYear();
        if(lastDateFormatted != dateTodayFormatted){
            const n = this.getNumberOfDaysCapsuleFeedWasPlayed();
            this.setItemFromLocalStorage(numberOfDaysCapsuleFeedWasPlayed, n + 1);
            this.setItemFromLocalStorage(lastDateUserPlayedCapsuleFeed, dateTodayFormatted);
            
            if(window.trebbleAnalyticsHelper && !this._analyticsRecordedForCapsuleAudioRecording){
                window.trebbleAnalyticsHelper.trackEvent("AppRating", "numberOfDaysUserPlayedCapsuleFeed", "Number of days capsule feed was played",this.getNumberOfDaysCapsuleFeedWasPlayed());
                if(window.trebbleAnalyticsHelper.trebbleHelperTrackPlayedFeedForTheDay){
                    window.trebbleAnalyticsHelper.trebbleHelperTrackPlayedFeedForTheDay();
                }
            }
            FacebookEventTrackingHelper.getInstance().trackNumberOfDaysCapsuleFeedWasPlayed(this.getNumberOfDaysCapsuleFeedWasPlayed());
        }
    },

    getLastTimeUserWasPromptedToRateTheApp : function(){
        return this.getItemFromLocalStorage(lastTimeUserWasPromptedToRateTheApp);
    },

    setLastTimeUserWasPromptedToRateTheApp : function(){
        return this.setItemFromLocalStorage(lastTimeUserWasPromptedToRateTheApp,  new Date().getTime());
    },

    getLastDateUserPlayedCapsuleFeed : function(){
        return this.getItemFromLocalStorage(lastDateUserPlayedCapsuleFeed);
    },

    

    getNumberOfDaysAppWasRan : function(){
        const n = this.getItemFromLocalStorage(numberOfDaysTheAppWasRan);
        if(!n){
            return 0;
        }else{
            return n;
        }
    },

    onAppStarted : function(){
        const today = new Date();
        const dd = today.getDate();
        const mm = today.getMonth() + 1; //January is 0!
        const yyyy = today.getFullYear();
        const dateTodayFormatted = dd +"-"+mm+"-"+yyyy;

        const lastTime = this.getLastTimeTheAppWasRan();
        const lastDate = !lastTime? null : new Date(lastTime);
        const lastDateFormatted = !lastDate? null:  lastDate.getDate() +"-"+(lastDate.getMonth() + 1)+"-"+lastDate.getFullYear();
        if(lastDateFormatted != dateTodayFormatted){
            const n = this.getNumberOfDaysAppWasRan();
            this.setItemFromLocalStorage(numberOfDaysTheAppWasRan, n + 1);
            this.setItemFromLocalStorage(lastTimeTheAppWasRan, today.getTime());
        }
    },

    getLastTimeTheAppWasRan: function(){
        return this.getItemFromLocalStorage(lastTimeTheAppWasRan);
    },
    
    
    isSuggestionToChangeCategoryOrLanguageDisplayed : function(){
        if(this.getItemFromLocalStorage(suggestionToChangeCategoryOrLanguageDisplayed)){
            return true;
        }else{
            return false;
        }
    },
    
    setUsingNewLoginModule : function(){
        this.setItemFromLocalStorage(usingNewLoginModuleKey, true);
    },

    isShortcutToPlayCapsuleFeedWasAddedToSiri : function(){
        if(this.getItemFromLocalStorage(shortcutToPlayCapsuleFeedWasAddedToSiri)){
            return true;
        }else{
            return false;
        }
    },
    
    setShortcutToPlayCapsuleFeedWasAddedToSiri : function(shorcutAdded){
        if(shorcutAdded){
            this.setItemFromLocalStorage(shortcutToPlayCapsuleFeedWasAddedToSiri, shorcutAdded);
        }else{
            this.removeItemFromLocalStorage(shortcutToPlayCapsuleFeedWasAddedToSiri);
        }
    },

    setShortcutPhraseToPlayCapsuleFeedWasAddedToSiri : function(phrase){
        if(phrase){
            this.setItemFromLocalStorage(shortcutPhraseToPlayCapsuleFeedWasAddedToSiri, phrase);
        }else{
            this.removeItemFromLocalStorage(shortcutPhraseToPlayCapsuleFeedWasAddedToSiri);	
        }
    },

    getShortcutPhraseToPlayCapsuleFeedWasAddedToSiri : function(){
        return this.getItemFromLocalStorage(shortcutPhraseToPlayCapsuleFeedWasAddedToSiri);
    },


    setLastLanguageUsedDuringTranscription : function(transcriptionLangaguage){
        if(transcriptionLangaguage){
            this.setItemFromLocalStorage(lastLanguageUsedDuringTranscriptionKey, transcriptionLangaguage);
        }else{
            this.removeItemFromLocalStorage(lastLanguageUsedDuringTranscriptionKey);	
        }
    },

    getLastLanguageUsedDuringTranscription : function(){
        return this.getItemFromLocalStorage(lastLanguageUsedDuringTranscriptionKey);
    },

    setUseEditorDarkTheme : function(useEditorDarkTheme){
        if(useEditorDarkTheme){
            this.setItemFromLocalStorage(useEditorDarkThemeKey, useEditorDarkTheme);
        }else{
            this.removeItemFromLocalStorage(useEditorDarkThemeKey);	
        }
    },

    getUseEditorDarkTheme : function(){
        return this.getItemFromLocalStorage(useEditorDarkThemeKey);
    },

    setSubscribreTooltipDiscardedByUser  : function(){
        this.setItemFromLocalStorage(subscribreTooltipDiscardedByUser, true);
        this.trigger(eventTypes.SUBSCRIBE_TOOLTIP_DISCARDED_BY_USER);
    },


    setSubscribreToJourneyTooltipDiscardedByUser  : function(){
        this.setItemFromLocalStorage(subscribreToJourneyTooltipDiscardedByUser, true);
        this.trigger(eventTypes.SUBSCRIBE_TO_JOURNEY_TOOLTIP_DISCARDED_BY_USER);
    },

    setShareTooltipDiscardedByUser  : function(){
        this.setItemFromLocalStorage(shareTooltipDiscardedByUser, true);
        this.trigger(eventTypes.SHARE_TOOLTIP_DISCARDED_BY_USER);
    },

    setEnableNotificationToggleDiscardedByUser  : function(){
        this.setItemFromLocalStorage(enableNotificationToggleDiscardedByUser, true);
        this.trigger(eventTypes.ENABLE_NOTIFICATION_TOGGLE_DISCARDED_BY_USER);
    },

    setListenOnVoiceEnabledDevicesTooltipDiscardedByUser  : function(){
        this.setItemFromLocalStorage(listenOnVoiceEnabledDevicesTooltipDiscardedByUser, true);
        this.trigger(eventTypes.LISTEN_ON_VOICE_ENABLED_DEVICES_TOOLTIP_DISCARDED_BY_USER);
    },

    setAutoPreviewTooltipDiscardedByUser : function(){
        this.setItemFromLocalStorage(autoPreviewTooltipDiscardedByUser, true);
        this.trigger(eventTypes.AUTO_PREVIEW_TOOLTIP_DISCARDED_BY_USER);
    },

    setAudioAnnouncementsDisabled : function(audioAnnouncementDisabled){
        if(audioAnnouncementDisabled){
            this.setItemFromLocalStorage(DISABLE_AUDIO_ANNOUNCEMENTS, audioAnnouncementDisabled);
            this._saveStringToSharedPreferenceIfAvailable(AUDIO_ANNOUNCEMENTS_DISABLED_KEY_SHARED_PREF, audioAnnouncementDisabled);
        }else{
            this.removeItemFromLocalStorage(DISABLE_AUDIO_ANNOUNCEMENTS);
            this._deleteInSharedPreferenceIfAvailable(AUDIO_ANNOUNCEMENTS_DISABLED_KEY_SHARED_PREF);
        }

    },

    setAudioAnnouncementsManuallyChanged : function(audioAnnouncementManuallyChanged){
        if(audioAnnouncementManuallyChanged){
            this.setItemFromLocalStorage(AUDIO_ANNOUNCEMENT_MANUALLY_CHANGED, true);
        }else{
            this.removeItemFromLocalStorage(AUDIO_ANNOUNCEMENT_MANUALLY_CHANGED);
        }

    },

    setLoggedInUserManuallyAgreedToTurnOnNotification :function(){
        this.setItemFromLocalStorage(manuallyAgreedToTurnOnNotification, true);
    },

    setLoggedInUserHasBeenPromptedToTurnOnNotifications :function(){
        this.setItemFromLocalStorage(loggedInUserHasBeenPromptedToTurnOnNotifications, true);
    },

    setHighFiveForAddingFirstSubscriptionWasDisplayed :function(){
        this.setItemFromLocalStorage(highFiveForAddingFirstSubscriptionWasDisplayed, true);
    },

    setSuggestionToListenToFeedForFirstTimeWasDisplayed :function(){
        this.setItemFromLocalStorage(suggestionToListenToFeedForFirstTimeWasDisplayed, true);
    },

    setRequestToGiveFeedBackOnContentWasDisplayed : function(){
        this.setItemFromLocalStorage(requestToGiveFeedBackOnContentWasDisplayed, true);
    },

    setUserHasChangeLanguageInExplorePage :function(){
        this.setItemFromLocalStorage(userHasChangeLanguageInExplorePage, true);
    },

    setUserHasChangeCategogryInExplorePage :function(){
        this.setItemFromLocalStorage(userHasChangeCategogryInExplorePage, true);
    },

    setSuggestionToChangeCategoryOrLanguageDisplayed :function(){
        this.setItemFromLocalStorage(suggestionToChangeCategoryOrLanguageDisplayed, true);
    },

    _clearUserInfo : function(){
        this.trigger(eventTypes.BEFORE_LOGOUT);
        this.setItemFromLocalStorage(userTasteProfileIdKey, null);
        this.setItemFromLocalStorage(trebbleUsernameKey, null);
        this.setItemFromLocalStorage(trebbleUserPasswordKey, null);
        this.setItemFromLocalStorage(lastSongPlayedUriKey, null);
        this.setItemFromLocalStorage(lastSongUrisOfPlayerQueueKey, null);
        this.setItemFromLocalStorage(autoPlaylistVersionKey, null);
        this.setItemFromLocalStorage(defaultTagVersionKey, null);
        this.setItemFromLocalStorage(defaultGroupTagVersionKey, null);
        this.setItemFromLocalStorage(pushNotificationRegistrationIdKey, null);
        this.setItemFromLocalStorage(pushNotificationRegistrationTypeKey, null);
        this.setItemFromLocalStorage(lastAppFailedStart,null);
        this.setItemFromLocalStorage(subscribreTooltipDiscardedByUser, null);
        this.setItemFromLocalStorage(subscribreToJourneyTooltipDiscardedByUser, null);
        this.setItemFromLocalStorage(shareTooltipDiscardedByUser, null);
        this.setItemFromLocalStorage(listenOnVoiceEnabledDevicesTooltipDiscardedByUser,null);
        this.setItemFromLocalStorage(autoPreviewTooltipDiscardedByUser, null);
        this.setItemFromLocalStorage(DISABLE_AUDIO_ANNOUNCEMENTS, null);
        this.setItemFromLocalStorage(AUDIO_ANNOUNCEMENT_MANUALLY_CHANGED, null);
        this.setItemFromLocalStorage(manuallyAgreedToTurnOnNotification, null);
        this.setItemFromLocalStorage(loggedInUserHasBeenPromptedToTurnOnNotifications, null);
        this.setItemFromLocalStorage(highFiveForAddingFirstSubscriptionWasDisplayed, null);
        this.setItemFromLocalStorage(userHasChangeLanguageInExplorePage, null);
        this.setItemFromLocalStorage(userHasChangeCategogryInExplorePage, null);
        this.setItemFromLocalStorage(suggestionToChangeCategoryOrLanguageDisplayed, null);
        this.setItemFromLocalStorage(enableNotificationToggleDiscardedByUser, null);
        this.setItemFromLocalStorage(userExperimentalFeaturesKey, null);
        this.setItemFromLocalStorage(alacarteFeaturesKey, null);
        this.setItemFromLocalStorage(premiumFeaturesKey, null);
        this.setItemFromLocalStorage(WAS_AWARDED_TREBBLE_PLUS_TRIAL, null);
        this.setItemFromLocalStorage(TREBBLE_PLUS_FREE_TRIAL_START_DATE, null);
        this.setItemFromLocalStorage(TREBBLE_PLUS_FREE_TRIAL_END_DATE, null);
        this.setItemFromLocalStorage(TOOLTIP_TO_EXPLAND_FLOATING_PLAYER_WAS_DISMISSED, null);
        this.setItemFromLocalStorage(AUDIO_EDITOR_ONBOARDING_WAS_COMPLETED_OR_DISMISSED, null);
        this.setItemFromLocalStorage(MY_LIBRARY_ONBOARDING_WAS_COMPLETED_OR_DISMISSED, null);
        this.setItemFromLocalStorage(RECORD_PAGE_ONBOARDING_WAS_COMPLETED_OR_DISMISSED, null);
        this.setItemFromLocalStorage(SAVE_FILE_OR_EDIT_PAGE_ONBOARDING_WAS_COMPLETED_OR_DISMISSED, null);
        this.setItemFromLocalStorage(useEditorDarkThemeKey, null);
        this._deleteInSharedPreferenceIfAvailable(AUDIO_ANNOUNCEMENTS_DISABLED_KEY_SHARED_PREF);
        this._deleteInSharedPreferenceIfAvailable(LOGGED_IN_USER_TOKEN_KEY_SHARED_PREF);
        this._deleteInSharedPreferenceIfAvailable(LOGGED_IN_USER_JSON_SERVER_REF_STRING_KEY_SHARED_PREF);

        //this.setItemFromLocalStorage(appDarkMode, null);

    },



    setLastAppFailedStart : function(timestamp){
        this.setItemFromLocalStorage(lastAppFailedStart,timestamp);
    },


    getLastAppFailedStart : function(){
        return this.getItemFromLocalStorage(lastAppFailedStart)?parseInt(this.getItemFromLocalStorage(lastAppFailedStart)): null;
    },
    
    isWhatsNewPageAlreadyShowedToUser : function(){
        const currentAppVersion = window.trebble.config.version;
        if(this.getItemFromLocalStorage(whatsNewPageShownPrefixKey + currentAppVersion)){
            return true;
        }else{
            return false;
        }
    },
    setWhatsNewPageAlreadyShowedToUser : function(){
        const currentAppVersion = window.trebble.config.version;
        this.setItemFromLocalStorage(whatsNewPageShownPrefixKey + currentAppVersion,true);
    },

    retrieveSongList : function() {
        return JSON.parse(this.getItemFromLocalStorage(songListKey));
    },

    isSongListSaved : function() {
        return this.getItemFromLocalStorage(songListKey) != null;
    },

    saveUserTasteProfileId : function(userTasteProfileId) {
        this.setItemFromLocalStorage(userTasteProfileIdKey, userTasteProfileId);
    },
    
    retrieveUserTasteProfileId : function() {
        return this.getItemFromLocalStorage(userTasteProfileIdKey);
    },

    addLocalFileURLDownloadedToDelete : function(remoteURL, localURLToDelete){
        const fileMapString = this.getItemFromLocalStorage(DOWNLOAD_FILES_TO_DELETE);
        let fileMap = JSON.parse(fileMapString);
        if(!fileMap){
            fileMap = {};
        }
        fileMap[remoteURL] = localURLToDelete;
        this.setItemFromLocalStorage(DOWNLOAD_FILES_TO_DELETE, JSON.stringify(fileMap));
        
        
        

    },

    removeLocalFileURLDownloadedToDelete : function(remoteURL){

        const fileMapString = this.getItemFromLocalStorage(DOWNLOAD_FILES_TO_DELETE);
        if(fileMapString){
            const fileMap = JSON.parse(fileMapString);
            if(fileMap[remoteURL]){
                delete fileMap[remoteURL];
                this.setItemFromLocalStorage(DOWNLOAD_FILES_TO_DELETE, JSON.stringify(fileMap));
            }
        }
    },

    getLocalFileURLsDownloadedToDeleteMap : function(){
        const fileMapString = this.getItemFromLocalStorage(DOWNLOAD_FILES_TO_DELETE);
        if(fileMapString){
            const fileMap = JSON.parse(fileMapString);
            return fileMap;
        }
    },



    removeAllLocalFileURLsDownloadedToDelete : function(){
        this.removeItemFromLocalStorage(DOWNLOAD_FILES_TO_DELETE);
    },
    
    retrieveAuthentificationToken : function(){
        if(temporarlySessionTokenInfo){
            return temporarlySessionTokenInfo;
        }else{
            const tokenInfoString =  this.getItemFromLocalStorage(authentificationTokenKey);
            if(tokenInfoString){
                try{
                    return JSON.parse(tokenInfoString);
                }catch(error){
                    console.error(error);
                    return null;
                }
            }else{
                return null;
            }
        }
    },

    saveTemporarlySessionTokenInfo : function(tokenInfo){
        temporarlySessionTokenInfo = tokenInfo;
    },

    isEmailDailyDigestDisabled : function(){
        const userInfo = this.getUserInfo();
        return userInfo && userInfo.settings && userInfo.settings.emailNotifications && userInfo.settings.emailNotifications.disableDailyDigest;
    },

    getTimeReminderForNotifications : function(){
        const userInfo = this.getUserInfo();
        return userInfo && userInfo.dailyDigestTimeReminder;
    },

    getTimezoneReminderForNotifications : function(){
        const userInfo = this.getUserInfo();
        return userInfo && userInfo.dailyDigestTimeTimezone;
    },

    isUserHasCapsuleSchedulingFeature : function(){
        const userInfo = this.getUserInfo();
        const premiumFeatures = userInfo? userInfo.premiumFeatures: null;
        //return premiumFeatures && premiumFeatures.scheduleCapsules;
        //Enabling scheduking feature by default
        return true;
    },

    getCustomMaxCapsuleLimitInSeconds : function(){
        const userInfo = this.getUserInfo();
        const premiumFeatures = userInfo? userInfo.premiumFeatures: null;
        return premiumFeatures && premiumFeatures.maxCapsuleTimeInSeconds;
    },

    getCustomMaxCapsuleLimitInSecondsOnUpload : function(){
        const userInfo = this.getUserInfo();
        const premiumFeatures = userInfo? userInfo.premiumFeatures: null;
        return premiumFeatures && premiumFeatures.maxCapsuleTimeInSecondsOnUpload;
    },


    isUserAllowToSetupATrebble : function(){
        const userInfo = this.getUserInfo();
        const premiumFeatures = userInfo? userInfo.premiumFeatures: null;
        return premiumFeatures && premiumFeatures.allowToSetupATrebble;
    },

    isCurrentLoggedUserNotAVisitor : function(){
        const userInfo = this.getUserInfo();
        return userInfo && !userInfo.temporary? true: false;
    },

    getLoggedInUserID : function(){
        if(this.isCurrentLoggedUserNotAVisitor()){
            const userInfo = this.getUserInfo();
            return userInfo? userInfo._id: null;
        }else{
            return null;
        }
    },
    
    
    saveAuthentificationToken : function(tokenInfo){
        if(temporarlySessionTokenInfo){
            temporarlySessionTokenInfo = tokenInfo;
        }else{
            if(tokenInfo && tokenInfo.userInfo){
                const isRegisteredUserTokenSavedBeforeUpdate =  this.isCurrentLoggedUserNotAVisitor(); 
                if(tokenInfo.userInfo.temporary){
                    tokenInfo.userInfo.temporaryUserRandomId = this.generateRandomId();
                }
                this.setItemFromLocalStorage(authentificationTokenKey, JSON.stringify(tokenInfo));
                this.saveUsername(tokenInfo.userInfo.username);

                this._saveStringToSharedPreferenceIfAvailable(LOGGED_IN_USER_TOKEN_KEY_SHARED_PREF, tokenInfo.token);
                this._saveStringToSharedPreferenceIfAvailable(LOGGED_IN_USER_JSON_SERVER_REF_STRING_KEY_SHARED_PREF, JSON.stringify(tokenInfo.userInfo));

                if(window.trebbleAnalyticsHelper){
                    window.trebbleAnalyticsHelper.setUserId((tokenInfo.userInfo.temporary && tokenInfo.userInfo.temporaryUserRandomId) ? tokenInfo.userInfo.username +"_"+ tokenInfo.userInfo.temporaryUserRandomId:  tokenInfo.userInfo.username);
                }
                if(window.trebbleAnalyticsHelper && !this._analyticsRecordedForCapsuleAudioRecording){
                    if(isRegisteredUserTokenSavedBeforeUpdate){
                        window.trebbleAnalyticsHelper.trackEvent("UserLogin", "saveAuthentificationToken", "User updated authentification token info");	
                    }else{
                        window.trebbleAnalyticsHelper.trackEvent("UserLogin", "UserSuccessfullLogin", "User sucessfully logged in");	
                    }
                }
            }else{
                //User is probably logging out
                this.setItemFromLocalStorage(authentificationTokenKey,tokenInfo );
                this._deleteInSharedPreferenceIfAvailable(LOGGED_IN_USER_TOKEN_KEY_SHARED_PREF);
                this._deleteInSharedPreferenceIfAvailable(LOGGED_IN_USER_JSON_SERVER_REF_STRING_KEY_SHARED_PREF);
                if(window.trebbleAnalyticsHelper && !this._analyticsRecordedForCapsuleAudioRecording){
                    window.trebbleAnalyticsHelper.trackEvent("UserLogout", "UserSuccessfullLogout", "User sucessfully logged out");
                }
            }
            this._onUserInfoChangedChanged(this.getUserInfo());
        }
        this.trigger(eventTypes.USER_INFO_UPDATED,this.getUserInfo());
    },

    logoutUserAndClearInfo : function(){

        this.saveAuthentificationToken(null);
        this._clearUserInfo();
        
    },
    
    
    isUsernameSaved : function() {
        return this.getItemFromLocalStorage(trebbleUsernameKey) != null;
    },
    
    isPushNotificationRegistrationIdSaved : function(){
        return this.getItemFromLocalStorage(pushNotificationRegistrationIdKey) != null; 
    },
    
    getPushNotificationRegistrationId : function(){
        return this.getItemFromLocalStorage(pushNotificationRegistrationIdKey);
    },
    
    savePushNotificationRegistrationId : function(registrationId){
        this.setItemFromLocalStorage(pushNotificationRegistrationIdKey, registrationId);
    },

    isPushNotificationRegistrationTypeSaved : function(){
        return this.getItemFromLocalStorage(pushNotificationRegistrationTypeKey) != null; 
    },
    
    getPushNotificationRegistrationType : function(){
        return this.getItemFromLocalStorage(pushNotificationRegistrationTypeKey);
    },
    
    savePushNotificationRegistrationType : function(registrationType){
        this.setItemFromLocalStorage(pushNotificationRegistrationTypeKey, registrationType);
    },

    saveUsername : function(username) {
        this.setItemFromLocalStorage(trebbleUsernameKey, username);
    },
    
    retrieveUsername : function() {
        return this.getItemFromLocalStorage(trebbleUsernameKey);
    },
    
    isUserPasswordSaved : function() {
        return this.getItemFromLocalStorage(trebbleUserPasswordKey) != null;
    },

    saveUserPassword : function(username) {
        this.setItemFromLocalStorage(trebbleUserPasswordKey, username);
    },
    
    retrieveUserPassword : function() {
        return this.getItemFromLocalStorage(trebbleUserPasswordKey);
    },

    getPreferredDeviceAudioId : function() {
        return this.getItemFromLocalStorage(PREFERRED_AUDIO_DEVICE_ID);
    },

    setPreferredDeviceAudioId: function(preferredDeviceId){
        this.setItemFromLocalStorage(PREFERRED_AUDIO_DEVICE_ID, preferredDeviceId);
        this.trigger(eventTypes.PREFERRED_AUDIO_DEVICE_ID_UPDATED,preferredDeviceId);
    },
    
    saveJQMHistoryStack : function(pageHash){
        this.setItemFromLocalStorage(jqmHistoryStackKey, JSON.stringify($.mobile.navigate.history.stack));
    },
    
    getJQMHistoryStack : function(){
        if(this.getItemFromLocalStorage(jqmHistoryStackKey))
        {
            return JSON.parse(this.getItemFromLocalStorage(jqmHistoryStackKey));
        }else{
            return null;
        }
    },
    
    isAutoPlaylistsNeedUpdate : function(){
        if(this.getAutoPlaylistsVersion() == window.trebble.config.version){
            return false;
        }else{
            return true;
        }
    },
    
    getAutoPlaylistsVersion: function(){
        return this.getItemFromLocalStorage(autoPlaylistVersionKey);
    },
    
    setAutoPlaylistsVersion: function(autoPlaylistVersion){
        return this.setItemFromLocalStorage(autoPlaylistVersionKey, autoPlaylistVersion);
    },

    getUserExperimentalFeatures: function(){
        const userExperimentalFeaturesString =  this.getItemFromLocalStorage(userExperimentalFeaturesKey);
        return userExperimentalFeaturesString? JSON.parse(userExperimentalFeaturesString): null;
    },

    getAlacarteFeatures: function(){
        const alacarteFeaturesString =  this.getItemFromLocalStorage(alacarteFeaturesKey);
        return alacarteFeaturesString? JSON.parse(alacarteFeaturesString): null;
    },

    getPremiumFeatures: function(){
        const premiumFeaturesString =  this.getItemFromLocalStorage(premiumFeaturesKey);
        return premiumFeaturesString? JSON.parse(premiumFeaturesString): null;
    },

    isUserAllowedUserReferral : function(){
        const experimentalFeatures =  this.getUserExperimentalFeatures();
        return experimentalFeatures && experimentalFeatures.allowedToReferralProgram;
    },
    
    getUserRefferalTimeStringLimit : function(){
        const experimentalFeatures =  this.getUserExperimentalFeatures();
        return experimentalFeatures && experimentalFeatures.userReferralTimeString;
    },

    getCostPerReferral : function(){
        const experimentalFeatures =  this.getUserExperimentalFeatures();
        return experimentalFeatures && experimentalFeatures.costPerReferral;
    },

    setUserExperimentalFeatures: function(experimentalFeatures){
        return this.setItemFromLocalStorage(userExperimentalFeaturesKey, experimentalFeatures? JSON.stringify(experimentalFeatures):null);
    },

    setAlacarteFeatures: function(alacarteFeatures){
        return this.setItemFromLocalStorage(alacarteFeaturesKey, alacarteFeatures? JSON.stringify(alacarteFeatures):null);
    },

    setPremiumFeatures: function(premiumFeatures){
        return this.setItemFromLocalStorage(premiumFeaturesKey, premiumFeatures? JSON.stringify(premiumFeatures):null);
    },
    
    isDefaultTagsNeedUpdate : function(){
        if(this.getDefaultTagVersion() == window.trebble.config.version){
            return false;
        }else{
            return true;
        }
    },

    getUserFirstName : function(){
        const userInfo =  this.getUserInfo();
        if(userInfo && !userInfo.temporary){
            return userInfo.firstName? userInfo.firstName: "";
        }
        return "";
    },

    getAllUserFeatures : function(){
        const userInfo =  this.getUserInfo();
        const alacarteFeatures =  this.getAlacarteFeatures();
        const premiumFeatures =  this.getPremiumFeatures();
        const experimentalFeatures =  this.getUserExperimentalFeatures();
        let allUserFeatures  = null;
        if(userInfo){
            allUserFeatures  = {};
            allUserFeatures.username = userInfo.username;
            const numberOfDaysAppWasRan =  this.getNumberOfDaysAppWasRan();
            if(numberOfDaysAppWasRan){
                allUserFeatures.numberOfDaysAppWasRan = numberOfDaysAppWasRan;
            }
            const numberOfDaysCapsuleFeedWasPlayed = this.getNumberOfDaysCapsuleFeedWasPlayed();
            if(numberOfDaysAppWasRan){
                allUserFeatures.numberOfDaysCapsuleFeedWasPlayed = numberOfDaysCapsuleFeedWasPlayed;
            }
            const appContext = window.getAppContext();
            if(appContext){
                allUserFeatures.appContext = appContext;
            }
            const appEnvironment = window.getAppEnvironment();
            if(appEnvironment){
                allUserFeatures.appEnvironment = appEnvironment;
            }
            const appLanguage = window.getAppUsedLanguage();
            if(appLanguage){
                allUserFeatures.appLanguage = appLanguage;
            }
            if(window.trebbleAppVersion){
                allUserFeatures.appUnixTimestampVersion = window.trebbleAppVersion;
            }
            if( userInfo.creationDate){
                allUserFeatures.creationDate = userInfo.creationDate;
            }
            if( userInfo.profileType){
                allUserFeatures.profileType = userInfo.profileType;
            }
            if(alacarteFeatures){
                for(let key in  alacarteFeatures){
                    const alacarteFeatureValue = alacarteFeatures[key];
                    if(alacarteFeatureValue){
                        allUserFeatures[key] = alacarteFeatureValue
                    }
                }
            }
            if(premiumFeatures){
                for(let key in  premiumFeatures){
                    const premiumFeatureValue = premiumFeatures[key];
                    if(premiumFeatureValue){
                        allUserFeatures[key] = premiumFeatureValue;
                    }
                }
            }
            if(experimentalFeatures){
                for(let key in  experimentalFeatures){
                    const experimentalFeatureValue = experimentalFeatures[key];
                    if(experimentalFeatureValue){
                        allUserFeatures[key] = experimentalFeatureValue;
                    }
                }
            }
        }
        return allUserFeatures;
    },
    
    onFirstApplicationLaunchDone : function(key,handlerFunc){
        this._firstApplicationLaunchDoneListeners[key] = handlerFunc;
    },
    
    isDefaultGroupTagsNeedUpdate : function(){
        if(this.getDefaultGroupTagVersion() == window.trebble.config.version){
            return false;
        }else{
            return true;
        }
    },
    
    getDefaultGroupTagVersion: function(){
        return this.getItemFromLocalStorage(defaultGroupTagVersionKey);
    },
    
    getDefaultTagVersion: function(){
        return this.getItemFromLocalStorage(defaultTagVersionKey);
    },
    
    setDefaultTagVersion: function(defaultTagVersion){
        return this.setItemFromLocalStorage(defaultTagVersionKey, defaultTagVersion);
    },
    
    setDefaultGroupTagVersion: function(defaultGroupTagVersion){
        return this.setItemFromLocalStorage(defaultGroupTagVersionKey, defaultGroupTagVersion);
    },
    
    
    saveLastSongPlayedUri : function(songUri){
        this.setItemFromLocalStorage(lastSongPlayedUriKey, songUri);
    },
    
    getLastSongPlayedUri : function(){
        return this.getItemFromLocalStorage(lastSongPlayedUriKey);
    },
    
    saveLastSongUrisOfPlayerQueue : function(arrayOfSongUris){
        this.setItemFromLocalStorage(lastSongUrisOfPlayerQueueKey, arrayOfSongUris);
    },
    
    getLastSongUrisOfPlayerQueue : function(){
        return this.getItemFromLocalStorage(lastSongUrisOfPlayerQueueKey);
    },
    

    registerForUserInfoChange : function(key , handlerFunction){
        this._userInfoChangedListeners[key] = handlerFunction;
    },
    
    unRegisterForUserInfoChange : function(key){
        delete this._userInfoChangedListeners[key];
    },
    
    _onUserInfoChangedChanged : function(newUserInfo){

        for(let handlerKey  in this._userInfoChangedListeners){
            const handlerFunction= this._userInfoChangedListeners[handlerKey];
            if(handlerFunction != null){
                try{
                    handlerFunction(newUserInfo);
                }catch(error){
                    window.alertErrorMessage(error);
                }
            }
        }
    },
    
    getUserInfo : function(){
        if(temporarlySessionTokenInfo){
            return temporarlySessionTokenInfo.userInfo;
        }else{
            const tokenInfo = this.retrieveAuthentificationToken();
            return tokenInfo?tokenInfo.userInfo: null;
        }
    },

    getAuthenticatedUserInfo : function(){
        const userInfo = this.getUserInfo();
        return userInfo && !userInfo.temporary? userInfo: null;
    },

    getIntercomHash : function(){
        try{
            const tokenInfo = this.retrieveAuthentificationToken();
            return tokenInfo?tokenInfo.intercomHash: null;
        }catch(error){
            console.error(error);
            return null;
        }
    },

    isLoggedInUserAListener : function(){
        const userInfo =  this.getUserInfo();
        return userInfo && userInfo.profileType == "listener";
    },

    isLoggedInUserAJourneyCreator : function(){
        const userInfo =  this.getUserInfo();
        return userInfo && userInfo.profileType == "journey_creator";
    },

    isLoggedInUserAShortcastreator : function(){
        const userInfo =  this.getUserInfo();
        return userInfo && userInfo.profileType == "shortcast_creator";
    },

    isLoggedInUserACreator : function(){
        const userInfo =  this.getUserInfo();
        return userInfo && userInfo.profileType == "creator";
    },

    _saveStringToSharedPreferenceIfAvailable: function(key, stringValue){
        
        return new RSVP.Promise(function(resolve, reject){
            try{
                if(window.plugins && window.plugins.SharedPreferences && window.plugins.SharedPreferences.getInstance){
                    const sharedPreferences = window.plugins.SharedPreferences.getInstance(SHARED_PREFERENCES_NAME);
                    if(sharedPreferences){
                        if(typeof stringValue == "boolean"){
                            sharedPreferences.putBoolean(key, stringValue, resolve, reject);
                        }else{
                            sharedPreferences.putString(key, stringValue, resolve, reject);
                        }
                        return;
                    }
                }
                resolve();
                return;
            }catch(error){
                console.error("Something went wrong saving shared preferences. Error:"+error);
                reject(error);
            }
        })

    },

    _retrieveStringToSharedPreferenceIfAvailable: function(key, defaultValue){
        
        return new RSVP.Promise(function(resolve, reject){
            try{
                if(window.plugins && window.plugins.SharedPreferences && window.plugins.SharedPreferences.getInstance){
                    const sharedPreferences = window.plugins.SharedPreferences.getInstance(SHARED_PREFERENCES_NAME);
                    if(sharedPreferences){
                        sharedPreferences.getString(key, defaultValue, resolve, reject);
                        return;
                    }
                }
                resolve();
                return;
            }catch(error){
                console.error("Something went wrong saving shared preferences. Error:"+error);
                reject(error);
            }
        })

    },

    _deleteInSharedPreferenceIfAvailable: function(key){
        return new RSVP.Promise(function(resolve, reject){
            try{
                if(window.plugins && window.plugins.SharedPreferences && window.plugins.SharedPreferences.getInstance){
                    const sharedPreferences = window.plugins.SharedPreferences.getInstance(SHARED_PREFERENCES_NAME);
                    if(sharedPreferences){
                        sharedPreferences.del(key, resolve, reject);
                        return;
                    }
                }
                resolve();
                return;
            }catch(error){
                console.error("Something went wrong saving shared preferences. Error:"+error);
                reject(error);
            }
        })

    },


    updateUserInfo : function(updatedUserInfo){
        if(temporarlySessionTokenInfo){
            return temporarlySessionTokenInfo.userInfo = updatedUserInfo;
        }else{
            const tokenInfo = this.retrieveAuthentificationToken();
            if(tokenInfo){
                tokenInfo.userInfo = updatedUserInfo;
            }
            return this.saveAuthentificationToken(tokenInfo);
        }
    },


});

const TrebbleLocalStorageHelper = new TrebbleLocalStorageHelperModel();
TrebbleLocalStorageHelper.eventTypes = eventTypes;

export default {
	getInstance : function() {
		return TrebbleLocalStorageHelper;
	}
};