import React from "react";
import ti18n from "i18n!nls/MyLibraryi18n";
import PersistentModels from "../services/PersistentModels";
import TrebbleClientAPI from "../models/helper/TrebbleClientAPI";
import useDefaultTrebbleAntDTokenConfig from "./helper/useDefaultTrebbleAntDTokenConfig";
import PropTypes from "prop-types";
import InfiniteList, { ListItem, ListItemMeta, Skeleton } from "./common/InfiniteList";
import Button from "./common/Button";
import moment from "moment";

const UserEditHistoryList = (props) => {
    
    const {inDarkMode} = useDefaultTrebbleAntDTokenConfig();
    const fetchSequencerProjects = (pageIndex, pageSize)=>{
        return TrebbleClientAPI.getInstance().getSequencerProjects(props.searchFieldValue, pageIndex, pageSize, false);
    };
    return (
        <InfiniteList
        containerId="editHistoryListContainer"
        containerHeightMargin={props.containerHeightMargin}
        containerClassname={`overflow-auto px-4 py-0 ${inDarkMode? "": "bg-white"} m-auto max-w-3xl`}
        locale={{ emptyText: window.getI18n(ti18n, "NO_EDITS_FOUND") }}
        fetchData={fetchSequencerProjects}
        loader={
          <div>
            <Skeleton className="py-5" paragraph={{ rows: 1, width: "80%" }} active />
            <Skeleton className="py-5" paragraph={{ rows: 1, width: "80%" }} active />
          </div>
        }
        renderItem={(item) => {
          return (
            <ListItem
              key={item._id}
              actions={[
                <Button
                  key={0}
                  type="link"
                  onClick={() => {
                    PersistentModels.getInstance().getRouter().showCreateShortcastPageFromProject(item._id, true);
                  }}
                >
                  <i className="pe-7s-note text-xl my-0 mx-2.5" />
                  <div className="hidden lg:inline-block">
                    {window.getI18n(ti18n, "DUPLICATE_AND_EDIT").toUpperCase()}
                  </div>
                </Button>,
              ]}
            >
              <ListItemMeta
                title={
                  <span className="py-0 px-1">{moment(new Date(item.createdDate)).format("MMMM D, YYYY h:mm A")}</span>
                }
                description={<span className="py-0 px-1">Version {item.version}</span>}
              />
            </ListItem>
          );
        }}
        pageSize={props.pageSize}
      />
    )
}

UserEditHistoryList.propTypes = {
    containerHeightMargin: PropTypes.number,
    pageSize: PropTypes.number,
    searchFieldValue: PropTypes.string,
};


export default UserEditHistoryList;

