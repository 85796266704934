import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import AudioFilter from "models/audioEditor/filters/AudioFilter";
import RSVP from "rsvp";
import LimiterNode from "audio-limiter"

const PRESETS ={};

const CLASSNAME = "LimiterFilter";
const LimiterFilter =  AudioFilter.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        AudioFilter.apply(this, [attributes, options]);
        this.set("type", LimiterFilter.CLASSNAME);
    },

    setCeiling : function(ceiling){
        this.set("ceiling", ceiling);
    },


    getCeiling : function(){
        return this.get("ceiling")
    },

    isEqual : function(filter){
        if(filter instanceof LimiterFilter){
            return (this.isDisabled() == filter.isDisabled() && this.getCeiling() === filter.getCeiling()); 
        }
        return false;
    },

    clone : function(){
        const cloneFilter = new LimiterFilter();
        cloneFilter.setDisabled(this.isDisabled());
        cloneFilter.setCeiling(this.getCeiling());
        return cloneFilter;
    },

    needToPrepareAudioContext: function(){
        return false;
    },

    prepareAudioContext: function(audioContext){
        return RSVP.Promise.resolve(audioContext);
    },

    applyFilterToNode : function(audioNode, audioContext,arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime, sequencerRendererTracksInfo, limiterNode){
        if(!this.isDisabled()){
            audioNode.connect(limiterNode);
            return limiterNode;
        }else{
            return audioNode;
        }
    },

_createLimiterNode : async function(audioContext){
    const limiter = new LimiterNode(audioContext, {time: 0.005});
    return limiter.isReady.then(() => {
        limiter.threshold.setValueAtTime(this.getCeiling(), 0);
        return limiter;
    })
},

applyFilter : function(previousOutputNode, audioContext, arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime, sequencerRendererTracksInfo, audioBuffer){
if(!this.isDisabled()){
    if(!previousOutputNode){
        previousOutputNode = audioContext.createBufferSource();
        previousOutputNode.buffer = audioBuffer;
        if(arrayOfAudioNodesUsed){
            arrayOfAudioNodesUsed.push(previousOutputNode);
        }
    }
    return this._createLimiterNode(audioContext).then((function(limiterAudioWorkletNode){
        return this.applyFilterToNode(previousOutputNode, audioContext,arrayOfAudioNodesUsed, audioBufferStartTime,audioBufferEndTime, sequencerRendererTracksInfo, limiterAudioWorkletNode);
    }).bind(this))
    
}else{
    return RSVP.Promise.resolve(previousOutputNode);
}
},

    /*
    needToPrepareAudioContext: function(){
        return true;
    },

    prepareAudioContext: function(audioContext){
        return audioContext.audioWorklet.addModule('js/libs/limiterAudio/limiter-audio-worklet-processor.js').catch((function(error){
            
        }).bind(this)).then((function(){
            return audioContext;
        }).bind(this))
    },

    applyFilterToNode : function(audioNode, audioContext, arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime ){
        if(!this.isDisabled()){
            const ceiling = this.getCeiling();
            const limiterNode = new LimiterNode(audioContext, {time: 0.005});
            audioNode.connect(limiterNode);
            if(arrayOfAudioNodesUsed){
                arrayOfAudioNodesUsed.push(limiterNode);
            }

            limiterNode.threshold.setValueAtTime(ceiling, 0);
            return limiterNode;
        }else{
            return audioNode;
        }
    },




    applyFilter : function(audioBuffer, audioContext, previousOutputNode, arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime){
        if(!this.isDisabled()){
            


            if(!previousOutputNode){
                previousOutputNode = audioContext.createBufferSource();
                previousOutputNode.buffer = audioBuffer;
                if(arrayOfAudioNodesUsed){
                    arrayOfAudioNodesUsed.push(previousOutputNode);
                }
            }

            return audioContext.audioWorklet.addModule('js/libs/limiterAudio/limiter-audio-worklet-processor.js').catch((function(error){
                
            }).bind(this)).then((function(){
                return RSVP.Promise.resolve(this.applyFilterToNode(previousOutputNode, audioContext, arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime ));
            }).bind(this));
        }else{
            return RSVP.Promise.resolve(previousOutputNode);
        }
    },*/




});

LimiterFilter.CLASSNAME =  CLASSNAME;

export default LimiterFilter; 