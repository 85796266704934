import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import SignupPageView from "views/signup/FullSignupPageView";
import FacebookHelper from "models/helper/FacebookHelper";
import AppleIDHelper from "models/helper/AppleIDHelper";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utils from "models/helper/Utils";
import FacebookEventTrackingHelper from "models/helper/FacebookEventTrackingHelper";
import CustomSelectFieldController from "controllers/common/CustomSelectFieldController";
import ListItemCollection from "collections/ListItemCollection";
import ti18n from "i18n!nls/FullSignupPagei18n";
import RSVP from "rsvp";

const SignupController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.onSignupSuccessfullyCompleted =  options? options.onSignupSuccessfullyCompleted:null;
        this.signupApi =  options? options.signupApi:null;
        this.signupWithFacebookApi =  options? options.signupWithFacebookApi:null;
        this.signupWithGoogleApi =  options? options.signupWithGoogleApi:null;
        this.signupWithAppleApi =  options? options.signupWithAppleApi:null;
        this._accessCode = options? options.accessCode : null;
        this._hideBackButton = options? options.hideBackButton : false;
        if(!this._accessCode && window.trebble.presetAccessCode){
            this._accessCode = window.trebble.presetAccessCode;
        }
        this._forwardAfterSignup = options? options.forwardAfterSignup: false;
        this.isUserpreviouslyLoggedInAsGuest =  options? options.isUserpreviouslyLoggedInAsGuest:false;

    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },


    doSignup : function(data){
        $.mobile.loading("show");
        FacebookEventTrackingHelper.getInstance().trackLeadWithIntentToSignup();
        this.signupApi(data).then((function(operationResult){
            if(operationResult && operationResult.userCreated){
                if(this.onSignupSuccessfullyCompleted){
                    this.onSignupSuccessfullyCompleted(data);
                }
                if(window.trebbleAnalyticsHelper){
                    window.trebbleAnalyticsHelper.trackEvent("Onboarding", "SignupCompleted", "Sign-up Completed");
                } 
                FacebookEventTrackingHelper.getInstance().trackRegistration(!!data.trebbleName, !!data.rssFeedUrlContentSource, data.accessCode);
            }else{
                if(window.trebbleAnalyticsHelper){
                    window.trebbleAnalyticsHelper.trackEvent("Onboarding", "SignupFailed", "Sign-up Failed");
                } 
                if(operationResult.userAlreadyExist){
                    throw (window.getI18n(ti18n, "USER_WITH_USERNAME")+" '"+ data.username  + "' "+ window.getI18n(ti18n, "ALREADY_EXISTS")+".");
                }else{
                    if(operationResult.emailAlreadyUsed){
                        throw (window.getI18n(ti18n, "USER_WITH_EMAIL")+" '"+ data.email  + "' "+ window.getI18n(ti18n, "ALREADY_EXISTS")+".");
                    }
                }
                throw window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_CREATING_NEW_ACCOUNT");
            }
        }).bind(this)).then((function(){
            $.mobile.loading("hide");
            window.trebble.accountSignupHasOccuredInSession = true;
            if(!this._forwardAfterSignup){
                window.history.back();  
            }
        }).bind(this)).catch((function(error){
            $.mobile.loading("hide");
            if(error === window.getI18n(ti18n, "INVALID_ACCESS_CODE")){
                this.showErrorMessageForAccessCode(window.getI18n(ti18n, "INVALID_ACCESS_CODE"));
            }else{
                window.alertErrorMessage(error);
            }
        }).bind(this));
    },


    doLoginViaFacebookPopupWindow : function(){
        /*return new RSVP.Promise(function(resolve, reject){
            FB.login(function(response){
                if (response.status === 'connected') {
                    // Logged into your app and Facebook.
                    resolve(response.authResponse);
                } else {
                    // The person is not logged into this app or we are unable to tell.
                    resolve(null); 
                    //resolve({"accessToken":"EAAQekcVgZBPgBAOPHZCYG2aX6eC0VztgdGJIXWnab4DH3nItnBDZAwrMivPJHGeY2DLCgojxWX8kVdByG5GEi6dK7GzGJWaG5u9qqRZAw2S51OSU6yNH7j9kZBlKpYZCX7rZBYdT3vjdWaQQuUrAC41ZAPKfLFZASOZCxKtHl9sTX3VofUVFSKSRArXuC9tkAmq2QBecBwT7sN2gZDZD"});
                }
            }, {scope: 'public_profile,email'})
})*/
return FacebookHelper.getInstance().login();
},


doSignupThroughFacebook : function(data){

if(data && data.accessCode){
$.mobile.loading("show");
let facebookAuthInfo = null;
FacebookEventTrackingHelper.getInstance().trackLeadWithIntentToSignup();
return this.doLoginViaFacebookPopupWindow().then((function(authInfo){
    facebookAuthInfo = authInfo;
    if(facebookAuthInfo){
        return this.signupWithFacebookApi(facebookAuthInfo.accessToken, data.accessCode,null, data.trebbleName, data.trebbleDescription,data.trebbleCategoryId, data.trebbleCityId, data.trebbleCustomUrl, data.trebbleTimezone, data.trebbleLanguage, data.rssFeedUrlContentSource, data.profileType);
    }else{
        throw window.getI18n(ti18n, "PLEASE_LOGIN_FACEBOOK_TO_CREATE_ACCOUNT");
    }
}).bind(this)).then((function(operationResult){
    if(operationResult && operationResult.userCreated){
        if(this.onSignupSuccessfullyCompleted){
            this.onSignupSuccessfullyCompleted({"externalProfileSignup":"Facebook" ,"facebookAccessToken": facebookAuthInfo.accessToken});
        }
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Onboarding", "SignupCompleted", "Sign-up Completed");
        } 
        FacebookEventTrackingHelper.getInstance().trackRegistration(!!data.trebbleName, !!data.rssFeedUrlContentSource, data.accessCode);
    }else{
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Onboarding", "SignupFailed", "Sign-up Failed");
        } 
        if(operationResult.userAlreadyExist){
            throw (window.getI18n(ti18n, "USER_WITH_FACEBOOK_ACCOUNT_ALREADY_EXISTS"))
        }else{
            if(operationResult.emailAlreadyUsed){
                throw (window.getI18n(ti18n, "USER_WITH_EMAIL_ALREADY_EXISTS"));
            }
        }
        throw window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_CREATING_NEW_ACCOUNT");
    }
}).bind(this)).then((function(){
    $.mobile.loading("hide");
    window.trebble.accountSignupHasOccuredInSession = true;
    if(!this._forwardAfterSignup){
        window.history.back();  
    }
}).bind(this)).catch((function(error){
    $.mobile.loading("hide");
    window.alertErrorMessage(error);
}).bind(this));
}else{
//window.alertErrorMessage("Please enter your access code");
this.showErrorMessageForAccessCode(window.getI18n(ti18n, "PLEASE_ENTER_ACCESS_CODE"));

}
},

doLoginViaApplePopupWindow : function(){			
return AppleIDHelper.getInstance().login();
},


doSignupThroughApple : function(data){

if(data && data.accessCode){
$.mobile.loading("show");
let appleAuthInfo = null;
FacebookEventTrackingHelper.getInstance().trackLeadWithIntentToSignup();
return this.doLoginViaApplePopupWindow().then((function(authInfo){
    appleAuthInfo = authInfo;
    if(appleAuthInfo){
        return this.signupWithAppleApi(appleAuthInfo.authorization.id_token, data.accessCode,null, data.trebbleName, data.trebbleDescription,data.trebbleCategoryId, data.trebbleCityId, data.trebbleCustomUrl, data.trebbleTimezone, data.trebbleLanguage, data.rssFeedUrlContentSource, data.profileType);
    }else{
        throw window.getI18n(ti18n, "PLEASE_LOGIN_APPLE_TO_CREATE_ACCOUNT");
    }
}).bind(this)).then((function(operationResult){
    if(operationResult && operationResult.userCreated){
        if(this.onSignupSuccessfullyCompleted){
            this.onSignupSuccessfullyCompleted({"externalProfileSignup":"Apple" ,"appleAccessToken": appleAuthInfo.authorization.id_token});
        }
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Onboarding", "SignupCompleted", "Sign-up Completed");
        } 
        FacebookEventTrackingHelper.getInstance().trackRegistration(!!data.trebbleName, !!data.rssFeedUrlContentSource);
    }else{
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Onboarding", "SignupFailed", "Sign-up Failed");
        } 
        if(operationResult.userAlreadyExist){
            throw (window.getI18n(ti18n, "USER_WITH_APPLE_ACCOUNT_ALREADY_EXISTS"))
        }else{
            if(operationResult.emailAlreadyUsed){
                throw (window.getI18n(ti18n, "USER_WITH_EMAIL_ALREADY_EXISTS"));
            }
        }
        throw window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_CREATING_NEW_ACCOUNT");
    }
}).bind(this)).then((function(){
    $.mobile.loading("hide");
    window.trebble.accountSignupHasOccuredInSession = true;
    if(!this._forwardAfterSignup){
        window.history.back();  
    }
}).bind(this)).catch((function(error){
    $.mobile.loading("hide");
    window.alertErrorMessage(error);
}).bind(this));
}else{
//window.alertErrorMessage("Please enter your access code");
this.showErrorMessageForAccessCode(window.getI18n(ti18n, "PLEASE_ENTER_ACCESS_CODE"));

}
},


onGoogleLoginFailed :function(error){
window.alertErrorMessage(window.getI18n(ti18n, "PLEASE_LOGIN_GOOGLE_TO_CREATE_ACCOUNT"));
if(window.trebbleAnalyticsHelper){
window.trebbleAnalyticsHelper.trackEvent("Onboarding", 'googleSignupFailed', 'Google Signup Failed',error,{"error":error});
}
},

doSignupThroughGoogle : function(data){

if(data && data.accessCode){
$.mobile.loading("show");
FacebookEventTrackingHelper.getInstance().trackLeadWithIntentToSignup();
return  this.signupWithGoogleApi(data.googleUser.getAuthResponse().id_token, data.accessCode,null, data.trebbleName, data.trebbleDescription,data.trebbleCategoryId, data.trebbleCityId,data.trebbleCustomUrl, data.trebbleTimezone, data.trebbleLanguage, data.rssFeedUrlContentSource, data.profileType).then((function(operationResult){
    if(operationResult && operationResult.userCreated){
        if(this.onSignupSuccessfullyCompleted){
            this.onSignupSuccessfullyCompleted({"externalProfileSignup":"Google" ,"googleAccessToken": data.googleUser.getAuthResponse().id_token, "googleUser": data.googleUser});
        }
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Onboarding", "SignupCompleted", "Sign-up Completed");
        } 
        FacebookEventTrackingHelper.getInstance().trackRegistration(!!data.trebbleName, !!data.rssFeedUrlContentSource);
    }else{
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("Onboarding", "SignupFailed", "Sign-up Failed");
        } 
        if(operationResult.userAlreadyExist){
            throw (window.getI18n(ti18n, "USER_WITH_GOOGLE_ACCOUNT_ALREADY_EXISTS"))
        }else{
            if(operationResult.emailAlreadyUsed){
                throw (window.getI18n(ti18n, "USER_WITH_EMAIL_ALREADY_EXISTS"))
            }
        }
        throw window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_CREATING_NEW_ACCOUNT");
    }
}).bind(this)).then((function(){
    $.mobile.loading("hide");
    window.trebble.accountSignupHasOccuredInSession = true;
    if(!this._forwardAfterSignup){
        window.history.back();  
    }
}).bind(this)).catch((function(error){
    $.mobile.loading("hide");
    window.alertErrorMessage(error);
}).bind(this));
}else{
//window.alertErrorMessage("Please enter your access code");
this.showErrorMessageForAccessCode(window.getI18n(ti18n, "PLEASE_ENTER_ACCESS_CODE"));

}
},

showErrorMessageForAccessCode : function(errorMessage){
const buttonLabels =  [window.getI18n(ti18n, "OK"), window.getI18n(ti18n, "REQUEST_ONE")];
const confirmCallback = (function(buttonIndex){
if(buttonIndex ==  2){
    this.openRequesInvitePage();
}
}).bind(this);
window.navigator.trebbleNotification.confirm(errorMessage, confirmCallback,  null,buttonLabels, null, "error");
},

onViewRemovedFromDocument : function(){
if(this.onSignupSuccessfullyCompleted){
this.onSignupSuccessfullyCompleted(false);
}
},

openRequesInvitePage : function(){
const openedWindow = window.open("https://trebble.typeform.com/to/JXo8tX", "_blank");
if((!openedWindow || openedWindow.closed || typeof openedWindow.closed=='undefined') && !window.waitForCordovaToLoad){
window.alertErrorMessage(window.getI18n(ti18n, "PLEASE_ALLOW_POPUPS_FOR_TREBBLE"));
}else{
if(openedWindow.focus){
    openedWindow.focus();
}
}

},

validateTrebbleName : function(name){
    let errorMessage =  null;
if(!name){
errorMessage  = window.getI18n(ti18n, "TREBBLE_CANNOT_BE_EMPTY");
}else{
if(name && name.length >= 100){
    errorMessage = window.getI18n(ti18n, "TREBBLE_NAME_IS_LESS_THAN_X_CHAR");
}
if(!name.match(Utils.getInstance().getTrebbleNameRegexValidation())){
    errorMessage = window.getI18n(ti18n, "TREBBLE_NAME_CAN_ONLY_CONTAIN");
}
if(name.indexOf("_") != -1){
    errorMessage = window.getI18n(ti18n, "TREBBLE_CANNOT_CONTAIN_HYPHENS");
}
}
const isValid = !errorMessage;
if(isValid){
return TrebbleClientAPIHelper.getInstance().isRadioWithSameName(name).then((function(alreadyExists){
    if(alreadyExists){
        this.view.setTrebbleNameValid(false, window.getI18n(ti18n, "TREBBLE_NAME_VALIDATION_ERROR"));
    }else{
        if(!this.view.getCustomUrl() || !this.view.isCustomUrlUserEdited()){
            return TrebbleClientAPIHelper.getInstance().getGeneratedCustomURLFromTrebbleName(name).then((function(generatedCustomURL){
                this.view.setCustomUrl(generatedCustomURL);
                this.view.setTrebbleNameValid(true);
            }).bind(this)).catch((function(error){
                //something went wrong silently continue
                this.view.setTrebbleNameValid(true);
            }).bind(this))
        }else{
            this.view.setTrebbleNameValid(true);
        }
        
    }
    return !alreadyExists;
}).bind(this)).catch((function(error){
    this.view.setTrebbleNameValid(false, error);
}).bind(this));
}else{
this.view.setTrebbleNameValid(isValid, errorMessage);
return RSVP.Promise.resolve(isValid);
}
return isValid;
},

validateTrebbleCustomUrl : function(customUrl){
    let errorMessage =  null;
if(!customUrl){
errorMessage  =  window.getI18n(ti18n, "YOU_NEED_TO_CHOOSE_A_CUSTOM_URL");
}
const isValid = !errorMessage;
if(isValid){
return TrebbleClientAPIHelper.getInstance().isRadioWithSameCustomURL(customUrl).then((function(alreadyExists){
    if(alreadyExists){
        this.view.setTrebbleCustomUrlValid(false,  window.getI18n(ti18n, "CUSTOM_URL_ERROR_MESSAGE"));
    }else{
        this.view.setTrebbleCustomUrlValid(true);
        
    }
    return !alreadyExists;
}).bind(this)).catch((function(){
    this.view.setTrebbleCustomUrlValid(false, errorMessage);
}).bind(this));
}else{
this.view.setTrebbleCustomUrlValid(isValid, errorMessage);
return RSVP.Promise.resolve(isValid);
}
return isValid;
},

validateRSSFeedURL : function(rssFeedUrl){
    let errorMessage =  null;
if(rssFeedUrl && !Utils.getInstance().isUrl(rssFeedUrl) ){
if(!rssFeedUrl){
    errorMessage = window.getI18n(ti18n, "RSS_URL_CANNOT_BE_EMPTY");
}else{
    errorMessage = window.getI18n(ti18n, "INVALID_URL");
}
}
const isValid = !errorMessage;
if(isValid && rssFeedUrl){
return TrebbleClientAPIHelper.getInstance().isUrlASupportedFeed(rssFeedUrl).then((function(isValid){
    if(!isValid){
        this.view.setRSSFeedUrlValid(false, window.getI18n(ti18n, "THE_URL_PROVIDED_IS_NOT_SUPPORTED"));
    }else{
        this.view.setRSSFeedUrlValid(true);
    }
    return isValid;
}).bind(this)).catch((function(){
    this.view.setRSSFeedUrlValid(false, errorMessage);
}).bind(this));

}else{
this.view.setRSSFeedUrlValid(isValid, errorMessage);
return RSVP.Promise.resolve(isValid);
}
return isValid;
},

validateTrebbleDescription : function(description){
    let errorMessage =  null;
if(!description){
errorMessage  = window.getI18n(ti18n, "YOUR_TREBBLE_DESCRIPTION_CANNOT_BE_EMPTY");
}else{
if(description && description.length >= 400){
    errorMessage = window.getI18n(ti18n, "SORRY_BUT_TREBBLE_DESCRIPTION_IS_BETWEEN_X_CHAR");
}
}
const isValid = !errorMessage;
this.view.setTrebbleDescriptionValid(isValid, errorMessage);
return RSVP.Promise.resolve(isValid);
},

getCities : function(keyword){
if(keyword){
$.mobile.loading("show");
return TrebbleClientAPIHelper.getInstance().searchForCities(keyword,window.getAppUsedLanguage()).then((function(cityInfoArray){
const cityListModelArray = [];
for(let i =0; i <  cityInfoArray.length; i++){
    const cityInfo = cityInfoArray[i];
    cityListModelArray.push(Utils.getInstance().getListItemModel(cityInfo.place_id,cityInfo.description));
}
$.mobile.loading("hide");
const listItemCollection = new ListItemCollection(cityListModelArray);
return listItemCollection.models;
}).bind(this)).catch((function(error){
$.mobile.loading("hide");
throw error;
}).bind(this));
}else{
return RSVP.Promise.resolve([]);
}

},

_buildCitySelectFieldController : function(){
const controllerParams = {};
controllerParams.singleSelect =  true;
controllerParams.propertyFunction = "getLabel";
controllerParams.propertyIdFunction = "getId";
controllerParams.showEmtptyInfoBox  = false;
controllerParams.loadHandler = this.getCities.bind(this);
controllerParams.defaultText = window.getI18n(ti18n, "CITY");
controllerParams.enableSearch =  true;
const customSelectFieldController = new CustomSelectFieldController(controllerParams);
return customSelectFieldController;
},

_initControllers : function(){
this.citySelectFieldController = this._buildCitySelectFieldController();
this.listenTo(this.citySelectFieldController, "selectionChanged",this._onCitySelectionChanged.bind(this) );
const promiseArray = [];

promiseArray.push(this.citySelectFieldController.buildModelAndRenderView());
return RSVP.Promise.all(promiseArray);
},


_onCitySelectionChanged : function(cityInfoListItemModel){
this.view.onTrebbleCategoryChanged();
/*if(this.isGenreDifferentFromCurrentSelected(genreInfoListItemModel)){
if(window.trebbleAnalyticsHelper){
    window.trebbleAnalyticsHelper.trackEvent("ExplorePage", 'OnCategorySelectionChanged', genreInfoListItemModel && genreInfoListItemModel.getId? genreInfoListItemModel.getId():null);
}
this._didUserChangeCategories = true;
this._currentGenreInfoListItemModel = genreInfoListItemModel;
this._updateRadioResults();
}*/
},

onViewRemoved:function(){
this.stopListening();
},

buildModelAndRenderView : function() {
return this._initControllers().then((function(){
return new RSVP.Promise((function(resolve, reject) {
try {
    const options = {};
    options.isUserpreviouslyLoggedInAsGuest = this.isUserpreviouslyLoggedInAsGuest;
    options.accessCode = this._accessCode;
    options.hideBackButton  = this._hideBackButton;
    options.citySelectFieldView  = this.citySelectFieldController.getView();
    this.view = new SignupPageView(options);
    this.view.render();

    this.listenTo(this.view, "signUpBtnTapped", this.doSignup);
    this.listenTo(this.view, "signUpWithFacebookBtnTapped", this.doSignupThroughFacebook);
    this.listenTo(this.view, "signUpWithAppleBtnTapped", this.doSignupThroughApple);
    this.listenTo(this.view, "signUpWithGoogleBtnSuccess", this.doSignupThroughGoogle);
    this.listenTo(this.view, "signUpWithGoogleBtnFailed", this.onGoogleLoginFailed);
    this.listenTo(this.view, "removedFromPage", this.onViewRemovedFromDocument);
    this.listenTo(this.view, "validateTrebbleName", this.validateTrebbleName);
    this.listenTo(this.view, "validateTrebbleCustomUrl", this.validateTrebbleCustomUrl);
    this.listenTo(this.view, "validateRSSFeedURL", this.validateRSSFeedURL);
    this.listenTo(this.view, "validateTrebbleDescription", this.validateTrebbleDescription);
    this.listenTo(this.view, "remove", this.onViewRemoved.bind(this));

    resolve();
    if(window.trebbleAnalyticsHelper){
        window.trebbleAnalyticsHelper.trackEvent("Onboarding", "SignupPageOpened", "Sign-up Page Opened");
    } 
} catch (error) {
    reject(error);
}

}).bind(this));
}).bind(this));

}

});

export default SignupController;