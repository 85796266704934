var amdi18n={"__root":{"SIGN_IN":"Sign In","HOME":"HOME","MY_CAPSULES":"MY SHORTCASTS","MY_TREBBLE":"MY CHANNEL","MY_LIBRARY":"MY LIBRARY","MY_SUBSCRIPTIONS":"SUBSCRIPTIONS","BROWSE":"BROWSE","SEARCH":"SEARCH","ON_AIR":"ON AIR","HELP_AND_FEEDBACK":"HELP & FEEDBACK","REFER_AND_EARN":"REFER A FRIEND & EARN","INSTALL_CHROME_EXT":"Install Chrome Extension","INSTALL_APP":"Install App","SETUP_MY_OWN_TREBBLE":"Setup My Own Content Channel","GET_STARTED":"GET STARTED","COMING_SOON":"Coming Soon!","UPGRADE_TO_TREBBLE_PLUS":"UPGRADE TO TREBBLE PLUS","SETTINGS":"SETTINGS","PROFILE":"PROFILE"},"__fr":{"SIGN_IN":"Se connecter","HOME":"ACCUEIL","MY_CAPSULES":"MES CONTENUS","MY_TREBBLE":"MA chaîne","MY_LIBRARY":"MA BIBLIOTHÈQUE","MY_SUBSCRIPTIONS":"ABONNEMENTS","BROWSE":"EXPLORE","SEARCH":"RECHERCHE","ON_AIR":"À L'ANTENNE","HELP_AND_FEEDBACK":"AIDE ET RÉTROACTION","REFER_AND_EARN":"RÉFÉRER UN AMI ET GAGNER","INSTALL_CHROME_EXT":"Installer l'extension Chrome","INSTALL_APP":"Installer l'application","SETUP_MY_OWN_TREBBLE":"Créer ma propre chaîne","GET_STARTED":"COMMENCER","COMING_SOON":"Arrive bientôt!","UPGRADE_TO_TREBBLE_PLUS":"PASSER À TREBBLE PLUS","SETTINGS":"PARAMÈTRES","PROFILE":"PROFIL"},"__en":{"SIGN_IN":"Sign In","HOME":"HOME","MY_CAPSULES":"MY CONTENT","MY_TREBBLE":"MY CHANNEL","MY_LIBRARY":"MY LIBRARY","MY_SUBSCRIPTIONS":"SUBSCRIPTIONS","BROWSE":"BROWSE","SEARCH":"SEARCH","ON_AIR":"ON AIR","HELP_AND_FEEDBACK":"HELP & FEEDBACK","REFER_AND_EARN":"REFER A FRIEND & EARN","INSTALL_CHROME_EXT":"Install Chrome Extension","INSTALL_APP":"Install App","SETUP_MY_OWN_TREBBLE":"Setup My Own Content Channel","GET_STARTED":"GET STARTED","COMING_SOON":"Coming Soon!","UPGRADE_TO_TREBBLE_PLUS":"UPGRADE TO TREBBLE PLUS","SETTINGS":"SETTINGS","PROFILE":"PROFILE"}};amdi18n.init=function(language){
		// get the default language
		if(!language){
			if(typeof window !== 'undefined' && window._i18n && window._i18n.locale){
				language = window._i18n.locale;
			}else if(typeof document !== 'undefined' && document.documentElement.lang){
				language = document.documentElement.lang;
			}else if(typeof navigator !== 'undefined' && (navigator.languages || navigator.language || navigator.userLanguage)){
				// navigator.languages does not exist in IE 11
				language = (navigator.languages && navigator.languages[0]) ||
					navigator.language ||
					navigator.userLanguage;
				language = (language || 'root').toLowerCase();
			}else{
				language = 'root';
			}
		}
		var target = this['__' + language] || this.__root;

		// copy definition to root level
		if (target) {
			for (var name in target) {
				this[name] = target[name];
			}
		}

		// fallback to root
		var recursiveFallback = function(target, source){
			for(var name in source){
				switch(typeof target[name]){
				case 'undefined':
					target[name] = source[name];
					break;
				case 'object':
					recursiveFallback(target[name], source[name]);
				}
			}
		};
		recursiveFallback(this, this.__root);
	};amdi18n.init();module.exports=amdi18n;