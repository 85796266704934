import $ from "jquery";
import Backbone from "backbone";
import TrebbleClientAPI from "models/helper/TrebbleClientAPI";
import ti18n from "i18n!nls/helperi18n";
import TrebbleIndexDBHelperDummy from "models/helper/TrebbleIndexDBHelperDummy";
import RSVP from "rsvp";


/*
Song ObjectStore blueprint
artist               TEXT
title                TEXT
duration               TIME
uri             	 TEXT  NOT NULL
coverArtFound		 boolean
albumArtData		 TEXT
albumId		 TEXT
album    TEXT
echonestid           TEXT
synced           BOOLEAN
idified           BOOLEAN
deleted           BOOLEAN
idifiedSuccess           BOOLEAN
idifiedTitle           TEXT
idifiedArtist           TEXT
idifiedAlbum           TEXT
idifiedAlbumId           TEXT
idifiedAlbumArtBase64           TEXT
idifiedAlbumArtBase64Blur           TEXT
idifiedAlbumArtURL          TEXT
songGracenoteId           TEXT
idifiedInfo           TEXT
importdate           DATE

*/

const _TREBBLE_INDEX_DB_NAME = "trebbleIndexDB";
const _INDEX_DB_VERSION  = 1;
const MAX_NUMBER_OF_SONGS_TO_SEND_DURING_SYNC = 500;

let syncingAll = false;

const EXECUTE_IOS_QUIRK= true;
const IOS_SUFFIX_WORKAROUND = " ";
const isEmbedded  = $("body").hasClass("embedded");
let indexDBFailedInitialization = false;
const TrebbleLocalIndexDBHelper = Backbone.Model
.extend({



  "default" : {
    "db" : null
  },
  
  initialize : function () {
    this.openDB();
  },
  
  listenersReady : [],
  listenersReadyFailed : [],
  
  openDB : function(){
    window.log("openDb ...");
    const indexedDB = window.indexedDB || 
    window.mozIndexedDB ||
    window.webkitIndexedDB || 
    window.msIndexedDB || 
    window.shimIndexedDB;

    try{
      if(indexedDB){
        const req = indexedDB.open(_TREBBLE_INDEX_DB_NAME, _INDEX_DB_VERSION);
        if(req){
          req.onsuccess = (function (evt) {
           this.db = req.result;
           this.set("db",this.db);
           this.notifyListeners();
           window.log("openDb DONE");
         }).bind(this);

          req.onerror = (function (evt) {
           indexDBFailedInitialization = true;
           console.error("openDb:", evt.target.errorCode);
           //this.notifyListenersOfFailure();
           this.notifyListeners();
         }).bind(this);

          req.onupgradeneeded = function (evt) {
            window.log("openDb.onupgradeneeded");
            const songStore = evt.currentTarget.result.createObjectStore("song", { keyPath: 'uri' });
            songStore.createIndex('uri', 'uri', { unique: true });

            songStore.createIndex('deleted', 'deleted', { unique: false });

            songStore.createIndex('idifiedString', 'idifiedString', { unique: false });
            songStore.createIndex('synced', 'synced', { unique: false });
            songStore.createIndex('type', 'type', { unique: false });


            const albumStore = evt.currentTarget.result.createObjectStore("album", { keyPath: 'albumId' });
            albumStore.createIndex('albumId', 'albumId', { unique: true });
            albumStore.createIndex('album', 'album', { unique: false });
            albumStore.createIndex('year', 'year', { unique: false });

            const artistStore = evt.currentTarget.result.createObjectStore("artist", { keyPath: 'artistId', "autoIncrement" : 1 });
            artistStore.createIndex('artistId', 'artistId', { unique: true });
            artistStore.createIndex('artistName', 'artistName', { unique: false });

            const LogStore = evt.currentTarget.result.createObjectStore("log", { keyPath: 'logId', "autoIncrement" : 1 });
            artistStore.createIndex('type', 'type', { unique: false });
            artistStore.createIndex('target', 'target', { unique: false });
            artistStore.createIndex('additionalData', 'additionalData', { unique: false });
            artistStore.createIndex('timestamp', 'timestamp', { unique: false });
            artistStore.createIndex('synced', 'synced', { unique: false });




            const genreStore = evt.currentTarget.result.createObjectStore("genre", { keyPath: 'genreName' });
            genreStore.createIndex('genreName', 'genreName', { unique: true });

            const autoPlaylistStore = evt.currentTarget.result.createObjectStore("autoPlaylist", { keyPath: 'id' });
            autoPlaylistStore.createIndex('id', 'id', { unique: true });

            const smartPlaylistStore = evt.currentTarget.result.createObjectStore("smartPlaylist", { keyPath: 'id' });
            smartPlaylistStore.createIndex('id', 'id', { unique: true });

            const staticPlaylistStore = evt.currentTarget.result.createObjectStore("staticPLaylist", { keyPath: 'id' });
            staticPlaylistStore.createIndex('id', 'id', { unique: true });

            const tagStore = evt.currentTarget.result.createObjectStore("tag", { keyPath: 'id' });
            tagStore.createIndex('id', 'id', { unique: true });
            tagStore.createIndex('custom', 'custom', { unique: false });
            tagStore.createIndex('groupId', 'groupId', { unique: false });
            tagStore.createIndex('exclusive', 'exclusive', { unique: false });

            const groupTagStore = evt.currentTarget.result.createObjectStore("groupTag", { keyPath: 'id' });
            groupTagStore.createIndex('id', 'id', { unique: true });
            groupTagStore.createIndex('custom', 'custom', { unique: false });
            groupTagStore.createIndex('exclusive', 'exclusive', { unique: false });




          };
        }else{
         indexDBFailedInitialization = true;
       }
     }else{
       indexDBFailedInitialization = true;
     }
   }catch(err){
     console.error("Failed opening DB:"+err);
     indexDBFailedInitialization = true;
   }
 },
 
 
 shouldExecuteIOSQuirk : function(){
   if(EXECUTE_IOS_QUIRK && window.device.platform == "iOS"){
    return true;
  }else{
    return false;
  }
},

isReady : function(){
  if(this.get("db"))
  {
   window.log("DB is already ready");
   return RSVP.Promise.resolve();
 }else{
   window.log("DB is not ready yet so wait...");
   return new RSVP.Promise((function(resolve,reject){
     window.log("Getting added to the list of of listeners to be notified ");
     this.listenersReady.push(resolve);
     this.listenersReadyFailed.push(reject);
   }).bind(this));
 }
},


notifyListeners : function(){
 window.log("Number of listeners to be notified :" +this.listenersReady.length);
 if(this.listenersReady){
  for(let i in this.listenersReady)
  {
    let listener = this.listenersReady[i];
    listener();
  }
 }
},

notifyListenersOfFailure : function(error){
window.log("Number of listeners to be notified of failure :" +this.listenersReady.length);
for(const i in this.listenersReadyFailed)
{
let listener = this.listenersReadyFailed[i];
listener(error);
}
},

getAllAbums : function(){
const transaction = this.getTransaction(["album"],"readonly");
const albumObjectStore = transaction.objectStore("album");
const albumTitleIndex = albumObjectStore.index("album");
return this._getDataFromIndex(albumTitleIndex,null, "next");

},

getAllArtists :function(){
const transaction = this.getTransaction(["artist"],"readonly");
const artistObjectStore = transaction.objectStore("artist");
const artistNameIndex = artistObjectStore.index("artistName");
return this._getDataFromIndex(artistNameIndex,null, "next");

},

getAllAutoPlaylists : function(){
  const transaction = this.getTransaction(["autoPlaylist"],"readonly");
const autoPlaylistObjectStore = transaction.objectStore("autoPlaylist");
const idIndex = autoPlaylistObjectStore.index("id");
return this._getDataFromIndex(idIndex,null, "next");
return RSVP.Promise.resolve();
},

getAllTags : function(){
  const transaction = this.getTransaction(["tag"],"readonly");
const tagObjectStore = transaction.objectStore("tag");
const idIndex = tagObjectStore.index("id");
return this._getDataFromIndex(idIndex,null, "next");
return RSVP.Promise.resolve();
},

getAllGroupTags : function(){
  const transaction = this.getTransaction(["groupTag"],"readonly");
const groupTagObjectStore = transaction.objectStore("groupTag");
const idIndex = groupTagObjectStore.index("id");
return this._getDataFromIndex(idIndex,null, "next");
return RSVP.Promise.resolve();
},


getSongWithURI : function(songURI, transaction){
 if(this.shouldExecuteIOSQuirk() && songURI){
     songURI = songURI.trim() +  IOS_SUFFIX_WORKAROUND;
 }
return this.getObjectWithKey(songURI, "song", transaction);
},


getAlbumWithAlbumId: function(albumId, transaction){
return this.getObjectWithKey(albumId, "album", transaction);

},

getArtistWithArtistId: function(artistId, transaction){
return this.getObjectWithKey(artistId, "artist", transaction);

},



getObjectWithKey: function(objectKey,objectStorName, transaction){

return new RSVP.Promise((function(resolve, reject){
try{
 if(!transaction){
   transaction = this.getTransaction([objectStorName],"readonly");
 }
 const objectStore = transaction.objectStore(objectStorName);
 const request = objectStore.get(objectKey);
 request.onerror = function(event) {
   reject(event.value);
 };
 request.onsuccess = function(event) {
   resolve(request.result);
 };
}catch(error){
 reject(error)
}

}).bind(this));

},

//API Method
getNextSongToIdify : function(){
const transaction = this.getTransaction(["song"],"readonly");
const songObjectStore = transaction.objectStore("song");
const idifiedIndex = songObjectStore.index("idifiedString");
const singleKeyRange = IDBKeyRange.only("false");
return this._getDataFromIndex(idifiedIndex,singleKeyRange, "next");

},

getUnsyncedSongs : function(maxNumberOfSongToReturn){
const transaction = this.getTransaction(["song"],"readonly");
const songObjectStore = transaction.objectStore("song");
const idifiedIndex = songObjectStore.index("synced");
const singleKeyRange = IDBKeyRange.only("false");
return this._getDataFromIndex(idifiedIndex, singleKeyRange, "next", maxNumberOfSongToReturn);
},

syncSongs : function(numberOfSongsToSync, minimumNumberToSync){
  let songToSync = undefined;
  let syncedExecuted = false;
return this.getUnsyncedSongs(numberOfSongsToSync).then(function(arrayOfSongToSync){
songToSync = arrayOfSongToSync;
if(songToSync  && songToSync.length > 0 && (!minimumNumberToSync || (minimumNumberToSync && songToSync.length >= minimumNumberToSync)) )
{
 syncedExecuted = true;
 return TrebbleClientAPI.getInstance().syncSongsArrayWithDB(songToSync);
}else{
 return RSVP.Promise.resolve();
}
}).then((function(){
if(syncedExecuted)
{
return this._markSongSynced(songToSync);
}else{
return RSVP.Promise.resolve(0);
}
}).bind(this));
},

savePlayerHistoryLog : function(playerHistoryLogToSave){
if(playerHistoryLogToSave)
{
const transactionOnLogStore = this.getTransaction(["log"],"write");
const logStore = transactionOnLogStore.objectStore("log");
this.putToObjectStore(logStore, playerHistoryLogToSave);
}
},

isSyncingAll : function(){
return syncingAll;
},

//API Method
syncAllSongs :function(minimumNumber, applyMinimumNumberOnlyAFterFirstCall){
syncingAll = true;
let isFirstCall = true;
const recursiveSyncFunction = (function(){
  let tresholdNumber = minimumNumber;
if(applyMinimumNumberOnlyAFterFirstCall && isFirstCall){
 tresholdNumber =  null;
 isFirstCall = false;
}
return this.syncSongs(MAX_NUMBER_OF_SONGS_TO_SEND_DURING_SYNC, tresholdNumber).then(function(numberOfSyncedSongs){
 if(numberOfSyncedSongs == 0)
 {
   syncingAll = false;
   return RSVP.Promise.resolve();
 }else{
   return recursiveSyncFunction();
 }
}).catch(function(error){
window.log("Failed Syncing songs. Error:" + error);
syncingAll = false;
throw error;
});
}).bind(this);
return recursiveSyncFunction();
},




_markSongSynced : function(arrayOfSongToMarkSynced){
const markSyncedSongPromises = [];
const uriToSongMap =[];
uriToSongMap.mapLength = 0;
for(let i in arrayOfSongToMarkSynced){
const song = arrayOfSongToMarkSynced[i];
uriToSongMap[song.uri] = song;
uriToSongMap.mapLength = uriToSongMap.mapLength + 1;
}
return new RSVP.Promise((function(resolve,reject){
try{
 const transactionOnSong = this.getTransaction(["song"],"readwrite");
 const songObjectStore = transactionOnSong.objectStore("song");
 songObjectStore.openCursor().onsuccess = (function(event) {
   const cursor = event.target.result;
   if(uriToSongMap.mapLength > 0)
   {
     if (cursor) {
      if(this.shouldExecuteIOSQuirk() && cursor.value && cursor.value.uri && cursor.value.uri.trim){
       cursor.value.uri = cursor.value.uri.trim();
     }
     const songInDB  = cursor.value;
     if(songInDB.uri in uriToSongMap){
       songInDB.synced = "true";
       markSyncedSongPromises.push(this.putToObjectStore(songObjectStore, songInDB));
       delete uriToSongMap[songInDB.uri];
       uriToSongMap.mapLength = uriToSongMap.mapLength -1;
     }
     cursor.continue();
   }else{
     resolve();
   }
 }else{
   resolve();
 }
}).bind(this);
}catch(error){
 reject(error);
}
}).bind(this)).then(function(){
return RSVP.Promise.all(markSyncedSongPromises).then(function(){
return RSVP.Promise.resolve(markSyncedSongPromises.length);
});
});
},


//API Method
//Need to be rewritten
saveSongArray : function(songArray){
const uriToSongArray = [];
for (const i in songArray) {
const song = songArray[i];
uriToSongArray[song.uri]  = song;
}
return this._markSongAsDeletedInDbIfNotPresentInArray(uriToSongArray).then((function(cleanedUriToSongArray){
const songtTransaction = this.getTransaction(["song"],"readwrite");
const albumTransaction = this.getTransaction(["album"],"readwrite");
const artistTransaction = this.getTransaction(["artist"],"readwrite");
const artistObjectStore = artistTransaction.objectStore("artist");
const albumObjectStore = albumTransaction.objectStore("album");
const songObjectStore = songtTransaction.objectStore("song");
const promiseArray = [];
for (const i in cleanedUriToSongArray) {
  const song = cleanedUriToSongArray[i];
  song.deleted = "false";
  if(!song.idified){
    song.idified = false;
    song.idifiedString = "false";
    song.idifiedTitle = "";
    song.synced = "false";
    song.idifiedSuccess = false;
    song.idifiedSuccessString = "false";
  }
  song.albumId = song.albumId?song.albumId:"Unknow Album";
  song.album = song.album?song.album:"Unknow Album";
  const album = {
    "albumId" : song.albumId,
    "album" : song.album,
    "year" : song.year,
    "artist" : song.artist,
    "artistId" : song.artist
  };
  /*  const idifiedAlbum = {
   
  };*/
  song.artist = song.artist?song.artist:"Unknow Artist";
  const artist = {
    "artistId" : song.artist,
    "artistName" : song.artist
  };
  /*const idifiedArtist = {
   
  }*/
  promiseArray.push(this.putToObjectStore(songObjectStore, song));
  promiseArray.push(this.putToObjectStore(albumObjectStore, album));
  promiseArray.push(this.putToObjectStore(artistObjectStore, artist));
}
return RSVP.Promise.all(promiseArray);

}).bind(this));

},



getAllLocalSongs : function(){
const transaction = this.getTransaction(["song"],"readonly");
const songObjectStore = transaction.objectStore("song");
const deletedIndex = songObjectStore.index("type");

//  const deletedIndex = songObjectStore.index("deleted");

const singleKeyRange = IDBKeyRange.bound("localFile", "localFile");
return this._getDataFromIndex(deletedIndex,null, "next");

},

getAllRemoteSongs : function(){
const transaction = this.getTransaction(["song"],"readonly");
const songObjectStore = transaction.objectStore("song");
const deletedIndex = songObjectStore.index("type");
/*  boundary = ["","Tuscan Leather","false"];
boundary[2]= "false";*/
//  const deletedIndex = songObjectStore.index("deleted");

const singleKeyRange = IDBKeyRange.bound("remote", "remote");
return this._getDataFromIndex(deletedIndex,null, "next");

},

_markSongAsDeletedInDbIfNotPresentInArray :function(uriToSongMap){
const markDeletedSongPromises = [];
return new RSVP.Promise((function(resolve,reject){
try{
 const transactionOnSong = this.getTransaction(["song"],"readwrite");
 const songObjectStore = transactionOnSong.objectStore("song");
 songObjectStore.openCursor().onsuccess = (function(event) {
   const cursor = event.target.result;
   if (cursor) {
    //if(this.shouldExecuteIOSQuirk() && event.target && event.target.source && event.target.source.objectStore && event.target.source.objectStore.name == "song" && cursor.value && cursor.value.uri){
      if(this.shouldExecuteIOSQuirk() && cursor.value && cursor.value.uri && cursor.value.uri.trim){
       cursor.value.uri = cursor.value.uri.trim();
     }
     const songInDB  = cursor.value;
     if(songInDB.uri in uriToSongMap){
       //remove song from array
       //TODO: this might cause issue if the user add a different file with the same file name
       //TODO: Song was markedas unsyced need .//FOR DEV PURPOSE ONLY
       
       // songInDB.synced = "false";
       // markDeletedSongPromises.push(self.putToObjectStore(songObjectStore, songInDB));
       delete uriToSongMap[songInDB.uri];
     }else{
       if(songInDB.type == "localFile")
       {
         songInDB.deleted = "true";
         markDeletedSongPromises.push(this.putToObjectStore(songObjectStore, songInDB));
       }
     }
     cursor.continue();
   }else{
     resolve();
   }
 }).bind(this);
}catch(error){
reject(error);
}
}).bind(this)).then(function(){
return RSVP.Promise.all(markDeletedSongPromises).then(function(){
 return RSVP.Promise.resolve(uriToSongMap);
});
});

},

//API Method
getSongsAlbumArtists : function(){
const songJsonArray = [];
const songUriToSongJsonMap = {};
const albumJsonArray = [];
const albumIdToAlbumJsonMap = {};
const artistJsonArray = [];
const artistIdToArtistJsonMap =  {};
const artistKeyToAlbumKeyMap = [];
const albumKeyToSongMap = [];
const albumKeyToAlbum = [];

return this.getAllLocalSongs().then((function(songs){

for(let index in songs){
 var	song  =  songs[index];
 if(song.deleted == "false"  && song.type == "localFile")
 {
   songUriToSongJsonMap[song.uri] = song;
   songJsonArray.push(song);
   let artistKey = null;
   let albumKey = null;
   if(song.idifiedSuccess == "true" || song.idifiedSuccess == true)
   {
     artistKey = song.idifiedArtistId;
     albumKey = song.idifiedAlbumId;
   }else{
     artistKey = song.artist;
     albumKey = song.albumId;
   }
   if(!(artistKey in artistKeyToAlbumKeyMap))
   {
     artistKeyToAlbumKeyMap[artistKey] = [];
   }
   if(artistKeyToAlbumKeyMap[artistKey].indexOf(albumKey) < 0)
   {
     artistKeyToAlbumKeyMap[artistKey].push(albumKey);
   }
   if(!(albumKey in albumKeyToSongMap))
   {
     albumKeyToSongMap[albumKey] = [];
   }
   albumKeyToSongMap[albumKey].push(song);
   
 }
 
}
return this.getAllAbums();
}).bind(this)).then((function(albums){
for(let index in albums){
const album = albums[index];
if(album.albumId in albumKeyToSongMap)
{
  album.songs   = albumKeyToSongMap[album.albumId];
  albumIdToAlbumJsonMap[album.albumId] = album;
  albumJsonArray.push(album);
  albumKeyToAlbum[album.albumId] = album;
  for(const songIndex in album.songs)
  {
    const songJSON = album.songs[songIndex];
    songJSON.albumJson = album;
  }
}
if(album && !album.albumArtURL &&  album.songs && album.songs.length > 0 &&  album.songs[0].albumArtUrl){
 album.albumArtURL =  album.songs[0].albumArtUrl;
 album.albumArtImages =  album.songs[0].albumArtImages;
}
}
return this.getAllArtists();
}).bind(this)).then((function(artists){
for(const index in artists){
 const artist = artists[index];
 if(artist.artistId in artistKeyToAlbumKeyMap)
 {
   artist.albums = [];
   const artistAlbumKeyArray = artistKeyToAlbumKeyMap[artist.artistId];
   for(const artistAlbumKey in artistAlbumKeyArray)
   {
     const album = albumKeyToAlbum[artistAlbumKeyArray[artistAlbumKey]];
     if(album && !(album in artist.albums))
     {
       //artist.albums.push(albumKeyToAlbum[artistAlbumKeyArray[artistAlbumKey]]);
       artist.albums.push(album);
       album.artistJson = artist;
     }
   }
   artistIdToArtistJsonMap[artist.artistId] = artist;
   artistJsonArray.push(artist);
 }
}
const result = { "songJsonArray" : songJsonArray,
"songUriToSongJsonMap" : songUriToSongJsonMap,
"albumJsonArray" : albumJsonArray,
"albumIdToAlbumJsonMap" : albumIdToAlbumJsonMap,
"artistJsonArray" : artistJsonArray,
"artistIdToArtistJsonMap" : artistIdToArtistJsonMap
}
return result;
}).bind(this));



},

//API Method


getFavoriteSongsAlbumArtists : function(){
const songJsonArray = [];
const songUriToSongJsonMap = {};
const albumJsonArray = [];
const albumIdToAlbumJsonMap = {};
const artistJsonArray = [];
const artistIdToArtistJsonMap =  {};
const artistKeyToAlbumKeyMap = [];
const albumKeyToSongMap = [];
const albumKeyToAlbum = [];


const result = { "songJsonArray" : songJsonArray,
"songUriToSongJsonMap" : songUriToSongJsonMap,
"albumJsonArray" : albumJsonArray,
"albumIdToAlbumJsonMap" : albumIdToAlbumJsonMap,
"artistJsonArray" : artistJsonArray,
"artistIdToArtistJsonMap" : artistIdToArtistJsonMap
}
return RSVP.Promise.resolve(result);

},

_buildSongFromIdifiedInfo : function(idifiedInfo){
if( idifiedInfo)
{
const song = {};
song.title  =  idifiedInfo.title;
song.artist = idifiedInfo.artist;
song.album = idifiedInfo.album;
song.albumId = idifiedInfo.albumId;
song.explicit = idifiedInfo.explicit;
let dateAdded = idifiedInfo.dateAdded;
if(dateAdded && ( dateAdded instanceof Date))
{
  dateAdded = dateAdded.getTime();
}
if(dateAdded && ( typeof dateAdded == "string"))
{
  dateAdded = new Date(dateAdded).getTime();
}
if(!dateAdded){
  dateAdded = Date().getTime();
}
song.dateAdded =  dateAdded;

if(idifiedInfo.dateModified){
  song.dateModified = idifiedInfo.dateModified;
}else{
  song.dateModified = new Date().getTime();
}
song.uri =  "gracenoteId:" + idifiedInfo.gracenoteId;
song.type = "remote";
song.favorite = true;
song.favoriteString = "true";
song.duration = idifiedInfo.duration;
song.synced = "false";
song.idified = true;
song.idifiedSuccess = true;
song.idifiedDate = new Date();
song.idifiedTitle = idifiedInfo.title;
song.idifiedExplicit = idifiedInfo.explicit;
song.idifiedArtist = idifiedInfo.artist;
song.idifiedAlbum = idifiedInfo.album;
song.idifiedArtistId = idifiedInfo.artistId;
song.songGracenoteId = idifiedInfo.gracenoteId;
song.idifiedAlbumArtURL = idifiedInfo.albumArtUrl;
song.idifiedAlbumArtImages = idifiedInfo.albumArtImages;
song.idifiedReleaseDate = idifiedInfo.releaseDate;
song.idifiedReleaseDateString = idifiedInfo.releaseDateString;
song.idifiedAlbumId = idifiedInfo.albumId;
song.idifiedTopGenreId = idifiedInfo.topGenreId;
song.idifiedTopGenre = idifiedInfo.topGenre;
song.idifiedTopOriginId = idifiedInfo.topOriginId;
song.idifiedTopOrigin = idifiedInfo.topOrigin;
song.idifiedTopEraId = idifiedInfo.topEraId;
song.idifiedTopEra = idifiedInfo.topEra;
song.idifiedTopMoodId = idifiedInfo.topMoodId;
song.idifiedTopMood = idifiedInfo.topMood;
song.idifiedTopTempoId = idifiedInfo.topTempoId;
song.idifiedTopTempo = idifiedInfo.topTempo;
song.idifiedTopArtistTypeId = idifiedInfo.topArtistTypeId;
song.idifiedTopArtistType = idifiedInfo.topArtistType;
song.idifiedGenreArray = idifiedInfo.genreArray;
song.idifiedOriginArray = idifiedInfo.originArray;
song.idifiedEraArray = idifiedInfo.eraArray;
song.idifiedMoodArray = idifiedInfo.moodArray;
song.idifiedTempoArray = idifiedInfo.tempoArray;
song.idifiedArtistTypeArray = idifiedInfo.artistTypeArray;
song.idifiedTempoArray = idifiedInfo.tempoArray;
song.idifiedFeaturedArtists = idifiedInfo.featuredArtists? idifiedInfo.featuredArtists : null;
song.idifiedInfo = idifiedInfo ;
return song;
}else{
return null;
}

},

/* _updateSongWithIdifiedInfo : function(song,idifiedInfo){

},*/
_buildAlbumFromSong : function(song){
const idifiedInfo = song.idifiedInfo;
let album = null;
if(idifiedInfo){
album = {
  "albumId" : idifiedInfo.albumId,
  "album" : idifiedInfo.album,
  "artist" : idifiedInfo.artist,
  "artistId" : idifiedInfo.artistId,
  "year" : idifiedInfo.year,
  "idifiedAlbumLinkDataArray" : idifiedInfo.albumLinkData ,
  "albumArtURL" : idifiedInfo.albumArtUrl,
  "albumArtImages": idifiedInfo.albumArtImages,
  "albumTrackCount" : idifiedInfo.albumTrackCount,
  "topGenreId" : idifiedInfo.topGenreId,
  "topGenre" : idifiedInfo.topGenre,
  "topOriginId" : idifiedInfo.topOriginId,
  "topOrigin" : idifiedInfo.topOrigin,
  "topEraId" : idifiedInfo.topEraId,
  "topEra" : idifiedInfo.topEra,
  "topMoodId" : idifiedInfo.topModId,
  "topMood" : idifiedInfo.topMood,
  "topTempoId" : idifiedInfo.topTempoId,
  "topTempo" : idifiedInfo.topTempo,
  "topArtistTypeId" : idifiedInfo.topArtistTypeId,
  "topArtistType" : idifiedInfo.topArtistType,
  "genreArray" : idifiedInfo.genreArray,
  "originArray" : idifiedInfo.originArray,
  "eraArray" : idifiedInfo.eraArray,
  "moodArray" : idifiedInfo.moodArray,
  "tempoArray" : idifiedInfo.tempoArray,
  "artistTypeArray" : idifiedInfo.artistTypeArray,
  
};
}else{
album = {
  "albumId" : song.albumId,
  "album" : song.album,
  "artist" : song.artist,
  "artistId" : song.artistId,
  "year" : song.year,
  "albumArtURL" : song.albumArtUrl,
  "albumArtImages": song.albumArtImages,
  
};
album.albumArtURL =  song.albumArtUrl;
album.albumArtImages = song.albumArtImages;
}
/*	if(song.doNotUseIdifiedUrl == true)
{
album.doNotUseIdifiedUrl = song.doNotUseIdifiedUrl;
album.preferredAlbumIdForCoverArt = song.preferredAlbumIdForCoverArt;
album.preferredAlbumArtURL = song.preferredAlbumArtURL;
}*/

return album;
},

_buildArtistFromSong : function(song){
const idifiedInfo = song.idifiedInfo;
let artist = null;
if(idifiedInfo){
artist = {
  "artistId" : idifiedInfo.artistId,
  "artistName" : idifiedInfo.artist
};

}else{
artist = {
  "artistId" : song.artistId,
  "artistName" : song.artist
};
}
return artist;
},



_addBidirectionalLinkBetweenSongAndAlbum :function(song, album){
song.albumJson = album;
if(!album.songs){
album.songs = [];
}
album.songs.push(song);
},

_addBidirectionalLinkBetweenAlbumAndArtist :function(album, artist){
album.artistJson = artist;
if(!artist.albums){
artist.albums = [];
}
artist.albums.push(album);
},


//API Method

getSongWithAlbumAndArtistInfoFromSongJson : function(songJsonWithoutAlbumOrArtistInfo){
const songClone = JSON.parse(JSON.stringify(songJsonWithoutAlbumOrArtistInfo));
const album = this._buildAlbumFromSong(songClone);
this._addBidirectionalLinkBetweenSongAndAlbum(songClone, album);
const artist  = this._buildArtistFromSong(songClone);
this._addBidirectionalLinkBetweenAlbumAndArtist(album, artist);
const songJsonWithAlbumOrArtistInfo = songClone;
return songJsonWithAlbumOrArtistInfo;
},

getSongsWithAlbumAndArtistInfoFromSongJsonArray : function(songArray){
const arrayOfSongsWithAlbumAndArtist = [];
for(let index in songArray){
const songJson  = songArray[index];
arrayOfSongsWithAlbumAndArtist.push(this.getSongWithAlbumAndArtistInfoFromSongJson(songJson));
}
return arrayOfSongsWithAlbumAndArtist;
},

deleteSong : function(songJson){
if(songJson && songJson.uri)
{
const transactionOnSongStore = this.getTransaction(["song"],"readwrite");
const songStore = transactionOnSongStore.objectStore("song");
return this._deleteInObjectStore(songJson.uri, songStore).then((function(){
  //mark song as delete in the backend
  
  
  if(songJson.favorite == "true" || songJson.favorite == true)
  {
    songJson.favorite = false;
    songJson.favoriteString = "false";
  }
  if(songJson.albumJson && songJson.albumJson && songJson.albumJson.songs){
    const album = songJson.albumJson;
    const songIndexInAlbum = album.songs.indexOf(songJson);
    if(songIndexInAlbum != -1){
      album.songs.splice(songIndexInAlbum, 1);
      if(album.songs.length == 0){
        this.deleteAlbum(album);
      }
    }
  }
  try{
    TrebbleClientAPI.getInstance().markSongsAsDeleted([songJson.uri]);
  }catch(err){
    //ignore Error
    console.error("An error occuredn when marking song as deleted in the backend. Error: "+err);
  }
}).bind(this));
}else{
return Promise.resolve();
}

},

deleteAlbum : function(albumJson){
if(albumJson && albumJson.albumId)
{
const transactionOnAlbumStore = this.getTransaction(["album"],"readwrite");
const albumStore = transactionOnAlbumStore.objectStore("album");
this._deleteInObjectStore(albumJson.albumId, albumStore).then((function(){
 if(albumJson.artistJson && albumJson.artistJson && albumJson.artistJson.albums){
   const artist = albumJson.artistJson;
   const albumIndexInArtist = artist.albums.indexOf(albumJson);
   if(albumIndexInArtist != -1){
     artist.albums.splice(albumIndexInArtist, 1);
     if(artist.albums.length == 0){
       this.deleteArtist(artist);
     }
   }
 }
 
}).bind(this));
}

},

deleteArtist : function(artistJson){
if(artistJson && artistJson.artistId)
{
const transactionOnArtistStore = this.getTransaction(["artist"],"readwrite");
const artistStore = transactionOnArtistStore.objectStore("artist");
this._deleteInObjectStore(artistJson.artistId, artistStore);
}

},
_deleteInObjectStore : function(objectKey, objectStore){
return new  Promise(function(resolve, reject){
const requestDelete = objectStore.delete(objectKey);
requestDelete.onerror = function(event) {
  reject(event.value);
};
requestDelete.onsuccess = function(event) {
  resolve(event.result);
};
});

},
saveSongFromIdifiedInfo : function(idifiedInfo,context, silent){
const song = this._buildSongFromIdifiedInfo(idifiedInfo);
return this.saveSong(song, context, silent);
},

_addAutoPlaylist : function(autoPlaylist){
if(autoPlaylist){
const transaction = this.getTransaction(["autoPlaylist"],"readwrite");
const autoPlaylistObjectStore = transaction.objectStore("autoPlaylist");
return  this.putToObjectStore(autoPlaylistObjectStore, autoPlaylist)
}else{
return RSVP.Promise.resolve();
}
},

_addAutoPlaylistArray : function(autoPlaylistArray){
const promiseArray = [];
if(autoPlaylistArray){
for(let index in autoPlaylistArray){
  const autoPlaylist = autoPlaylistArray[index];
  promiseArray.push(this._addAutoPlaylist(autoPlaylist));
}
}
return RSVP.Promise.all(promiseArray);
//return RSVP.Promise.resolve();
},

_addTag : function(tagInfo){
if(tagInfo){
const transaction = this.getTransaction(["tag"],"readwrite");
const tagObjectStore = transaction.objectStore("tag");
return  this.putToObjectStore(tagObjectStore, tagInfo)
}else{
return RSVP.Promise.resolve();
}
},

_addGroupTag : function(groupTagInfo){
if(groupTagInfo){
const transaction = this.getTransaction(["groupTag"],"readwrite");
const groupTagObjectStore = transaction.objectStore("groupTag");
return  this.putToObjectStore(groupTagObjectStore, groupTagInfo)
}else{
return RSVP.Promise.resolve();
}
},

_addTagArray : function(tagInfoArray){
const promiseArray = [];
if(tagInfoArray){
for(let index in tagInfoArray){
  const tagInfo = tagInfoArray[index];
  promiseArray.push(this._addTag(tagInfo));
}
}
return RSVP.Promise.all(promiseArray);
//return RSVP.Promise.resolve();
},

_addGroupTagArray : function(groupTagInfoArray){
const promiseArray = [];
if(groupTagInfoArray){
for(let index in groupTagInfoArray){
  const groupTagInfo = groupTagInfoArray[index];
  promiseArray.push(this._addGroupTag(groupTagInfo));
}
}
return RSVP.Promise.all(promiseArray);
//return RSVP.Promise.resolve();
},

_getGeneratedReleaseDate : function (songJson) {
if(!songJson){
return null;
}else{
  let generatedReleaseDate = null;
if(songJson.idifiedInfo){
  const idifiedInfo = songJson.idifiedInfo;
  if(songJson.idifiedReleaseDate){
    if(songJson.idifiedReleaseDate && ( songJson.idifiedReleaseDate instanceof Date))
    {
      generatedReleaseDate = songJson.idifiedReleaseDate;
    }
    if(songJson.idifiedReleaseDate && ( typeof songJson.idifiedReleaseDate == "string"))
    {
      generatedReleaseDate = new Date(songJson.idifiedReleaseDate);
    }
    
  }else{
    if(songJson.idifiedReleaseDateString){
      generatedReleaseDate = new Date(songJson.idifiedReleaseDateString);
    }else{
      if(idifiedInfo.year){
        generatedReleaseDate = new Date(Math.abs(idifiedInfo.year), 1, 1, 0, 0, 0, 0);
      }
    }
  }
  
}
if(!generatedReleaseDate ){
  if(songJson.publishedAt){
    generatedReleaseDate = new Date(songJson.publishedAt);
  }else{
    let dateAdded = songJson.dateAdded;
    if(dateAdded && ( dateAdded instanceof Date))
    {
      dateAdded = dateAdded.getTime();
    }
    if(dateAdded && ( typeof dateAdded == "string"))
    {
      dateAdded = new Date(dateAdded).getTime();
    }
    if(!dateAdded){
      dateAdded = Date().getTime();
    }
    songJson.dateAdded =  dateAdded;
    if(songJson.dateAdded){
      generatedReleaseDate = new Date(songJson.dateAdded);
    }
  }
}
return generatedReleaseDate;
}
},

deleteHiddenDataFromServerOnSong : function(songJson){
delete songJson.prefetchedDirectVideoUrl;
delete songJson.prefetchedDirectVideoUrlTimestamp;
delete songJson.suggestedUri;
delete songJson._hiddedDBData;
delete songJson._buildOnServer;
},

saveSong : function(song, context, silent, doNotSync, minimumSyncData, markSynced, waitForSyncOperation){

song.deleted = "false";
song._sourceContext = context;
this.deleteHiddenDataFromServerOnSong(song);
let albumUpdatedOrCreated = null ;
let albumCreated = null;
let artistUpdatedOrCreated = null ;
let artistCreated = null;
if(song.idified == "true" || song.idified == true)
{
if(song.idifiedInfo && song.idifiedInfo != "OK")
{
  const idifiedInfo = song.idifiedInfo;
  //   song.idifiedSuccess = "true";
  //  song.idifiedDate = new Date();
  song.idifiedTitle = idifiedInfo.title;
  song.idifiedExplicit = idifiedInfo.explicit;
  song.idifiedArtist = idifiedInfo.artist;
  song.idifiedAlbum = idifiedInfo.album;
  song.idifiedArtistId = idifiedInfo.artistId;
  song.songGracenoteId = idifiedInfo.gracenoteId;
  song.idifiedAlbumArtURL = idifiedInfo.albumArtUrl;
  song.idifiedAlbumArtImages = idifiedInfo.albumArtImages;
  song.idifiedAlbumId = idifiedInfo.albumId;
  song.idifiedTopGenreId = idifiedInfo.topGenreId;
  song.idifiedTopGenre = idifiedInfo.topGenre;
  song.idifiedTopOriginId = idifiedInfo.topOriginId;
  song.idifiedTopOrigin = idifiedInfo.topOrigin;
  song.idifiedTopEraId = idifiedInfo.topEraId;
  song.idifiedTopEra = idifiedInfo.topEra;
  song.idifiedTopMoodId = idifiedInfo.topMoodId;
  song.idifiedTopMood = idifiedInfo.topMood;
  song.idifiedTopTempoId = idifiedInfo.topTempoId;
  song.idifiedTopTempo = idifiedInfo.topTempo;
  song.idifiedTopArtistTypeId = idifiedInfo.topArtistTypeId;
  song.idifiedTopArtistType = idifiedInfo.topArtistType;
  song.idifiedGenreArray = idifiedInfo.genreArray;
  song.idifiedOriginArray = idifiedInfo.originArray;
  song.idifiedEraArray = idifiedInfo.eraArray;
  song.idifiedMoodArray = idifiedInfo.moodArray;
  song.idifiedTempoArray = idifiedInfo.tempoArray;
  song.idifiedArtistTypeArray = idifiedInfo.artistTypeArray;
  song.idifiedTempoArray = idifiedInfo.tempoArray;
  song.idifiedFeaturedArtists = idifiedInfo.featuredArtists? idifiedInfo.featuredArtists : null;
  song.idifiedInfo = idifiedInfo ;
}else{
  if(song.idifiedInfo == "OK"){
    song.idifiedInfo = null;
  }
  // song.idifiedSuccess = "false";
  // song.idifiedDate = new Date();
}
}
if(!song.dateAdded){
song.dateAdded = new Date().getTime();

}else{
//make sure that time is in milliseconds
let dateAdded = song.dateAdded;
if(dateAdded && ( dateAdded instanceof Date))
{
  dateAdded = dateAdded.getTime();
}
if(dateAdded && ( typeof dateAdded == "string"))
{
  dateAdded = new Date(dateAdded).getTime();
}
if(!dateAdded){
  dateAdded = Date().getTime();
}
song.dateAdded =  dateAdded;
}
song.dateModified = new Date().getTime();
if(song.uri.indexOf("/") == 0){
song.type = "localFile";
}else{
song.type = "remote";
}
song.generatedReleaseDate = this._getGeneratedReleaseDate(song);
song.generatedReleaseYear = song.generatedReleaseDate.getFullYear();
if(markSynced){
song.synced = "true";
}else{
song.synced = "false";
}



const albumJsonRef = song.albumJson;
song.albumJson = null;
const songCloneUri = song.uri;
song.uri = null;
const songClone = JSON.parse(JSON.stringify(song));
song.albumJson = albumJsonRef;
songClone.uri  = songCloneUri ;
song.uri = songCloneUri;
// songClone.uri = songClone.uri.trim();
if( song.type == "localFile"){
const transaction = this.getTransaction(["song"],"readwrite");
const songObjectStore = transaction.objectStore("song");
return  this.putToObjectStore(songObjectStore, songClone).then((function(){
  const albumId =  song.idifiedAlbumId? song.idifiedAlbumId:song.albumId; 
  return this.getAlbumWithAlbumId(albumId, this.getTransaction(["album"],"readwrite"));
}).bind(this)).then((function(albumFound){
 if(!albumFound){
   const album = this._buildAlbumFromSong(song);
   albumUpdatedOrCreated = album;
   albumCreated = true;
   
   const artistJsonRef = album.artistJson;
   album.artistJson = null;
   const albumClone = JSON.parse(JSON.stringify(album));
   album.artistJson = artistJsonRef;
   const t = this.getTransaction(["album"],"readwrite");
   const albumObjectStore = t.objectStore("album");
   return this.putToObjectStore(albumObjectStore, albumClone);
 }else{
   albumCreated = false;
   albumUpdatedOrCreated = albumFound;
   if(song.doNotUseIdifiedUrl !=  true && song.idifiedInfo ){
     
     albumUpdatedOrCreated.albumArtURL = song.idifiedInfo.albumArtUrl;
     albumUpdatedOrCreated.albumArtImages = song.albumArtImages;
     const t = this.getTransaction(["album"],"readwrite");
     const albumObjectStore = t.objectStore("album");
     /*update current album reference if same id as idified album*/
     if(song.albumJson && (song.albumJson.albumId == albumUpdatedOrCreated.albumId)){
       song.albumJson.albumArtURL = song.idifiedInfo.albumArtUrl;
       song.albumJson.albumArtImages = song.idifiedInfo.albumArtImages;
     }
     return this.putToObjectStore(albumObjectStore, albumUpdatedOrCreated)
   }
   
   return null;
 }
}).bind(this)).then((function(){
const artistId =  song.idifiedArtistId? song.idifiedArtistId:song.artistId; 
return this.getArtistWithArtistId(artistId, this.getTransaction(["artist"],"readwrite") );
}).bind(this)).then((function(artistFound){
if(!artistFound){
 const artist  = this._buildArtistFromSong(song);
 artistUpdatedOrCreated = artist;
 artistCreated =true;
 const t = this.getTransaction(["artist"],"readwrite");
 const artistObjectStore = t.objectStore("artist");
 return this.putToObjectStore(artistObjectStore, artist);
}else{
 artistCreated = false;
 artistUpdatedOrCreated = artistFound;
 return null;
}

}).bind(this)).then((function(){

this.trigger("onSongSaved", song, albumUpdatedOrCreated,artistUpdatedOrCreated, silent);

}).bind(this)).then((function(){

if(!doNotSync && !this.isSyncingAll()){
 const p = this.syncAllSongs(minimumSyncData);
 if(waitForSyncOperation){
   return p;
 }
}

}).bind(this));
}else{
//not saving remote file anymore since they get saved in the db anyway
const album = this._buildAlbumFromSong(song);
albumUpdatedOrCreated = album;
const artist  = this._buildArtistFromSong(song);
artistUpdatedOrCreated = artist;
this.trigger("onSongSaved", song, albumUpdatedOrCreated,artistUpdatedOrCreated, silent);
if(!doNotSync){
const p =  TrebbleClientAPI.getInstance().syncSongsArrayWithDB([songClone]);
if(waitForSyncOperation){
 return p;
}
}
return RSVP.Promise.resolve();
}
},





_getDataFromIndex : function(index, keyRange,sortOrder,maxNumberOfItemsToReturn){
return new  RSVP.Promise((function(resolve, reject){
const results  = [];
if(!sortOrder)
{
 sortOrder = "next";
}
const onOpenCursorSuccessCallback = (function(event) {
 const cursor = event.target.result;
 /*if(!arguments.callee.maxNumberOfItemsToReturn || (arguments.callee.numberOfItemsRetrieved < arguments.callee.maxNumberOfItemsToReturn))
 {
   if (cursor) {
    if(this.shouldExecuteIOSQuirk() && event.target && event.target.source && event.target.source.objectStore && event.target.source.objectStore.name == "song" && cursor.value && cursor.value.uri && cursor.value.uri.trim){
      cursor.value.uri = cursor.value.uri.trim();
    }
    results.push(cursor.value);
    cursor.continue();
  }else{
   resolve(results);
 }
 arguments.callee.numberOfItemsRetrieved  = arguments.callee.numberOfItemsRetrieved  + 1;
}else{*/
 resolve(results);
//}
}).bind(this);
onOpenCursorSuccessCallback.maxNumberOfItemsToReturn = maxNumberOfItemsToReturn;
onOpenCursorSuccessCallback.numberOfItemsRetrieved = 0;
index.openCursor(keyRange,sortOrder).onsuccess = onOpenCursorSuccessCallback;
}).bind(this));
},

_getAllDataFromObjectStore : function(objectStore){
return new  RSVP.Promise((function(resolve, reject){
const results  = [];
objectStore.openCursor().onsuccess = (function(event) {
 const cursor = event.target.result;
 if (cursor) {
     if(this.shouldExecuteIOSQuirk() && event.target && event.target.source && event.target.source.objectStore && event.target.source.objectStore.name == "song" && cursor.value && cursor.value.uri && cursor.value.uri.trim){
         cursor.value.uri = cursor.value.uri.trim();
     }
   results.push(cursor.value);
   cursor.continue();
 }else{
   resolve(results);
 }
}).bind(this);
}).bind(this));
},


getTransaction : function(objectStoreArray, transactionType){
const db = this.get("db");
const transaction = db.transaction(objectStoreArray,transactionType);
return transaction;
},

putToObjectStore : function(objectStore, model){
return new  Promise ((function(resolve, reject){
if(this.shouldExecuteIOSQuirk() && objectStore.name == "song"){
model.uri =  model.uri.trim() + " ";
}
try{
const requestUpdate = objectStore.put(model);
requestUpdate.onerror = (function(event) {
if(!this.objectToSave){
 this.objectToSave = {};
}
console.error("Failed saving the object "+ JSON.stringify(this.objectToSave) + " in the store " + this.theStoreName);
window.alertErrorMessage(window.getI18n(ti18n, "FAILED_SAVING_DATA"));
reject(event.value);
}).bind({"theStoreName": objectStore.name, "objectToSave": model});
requestUpdate.onsuccess = function(event) {
 resolve(event.result);
};
}catch(ex){
if(!model){
model = {};
}
console.error("Failed saving the object "+ JSON.stringify(this.model) + " in the store " +objectStore.name);
window.alertErrorMessage(window.getI18n(ti18n, "FAILED_SAVING_DATA"));
}
}).bind(this));
},



});


const trebbleLocalIndexDBSingleton = new TrebbleLocalIndexDBHelper();

export default {
 getInstance : function() {
  if(isEmbedded || indexDBFailedInitialization || (window.waitForCordovaToLoad && window.isIOSMobileDevice)){
    return TrebbleIndexDBHelperDummy.getInstance();
  }else{
    return trebbleLocalIndexDBSingleton;
  }
}
};