import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import RSVP from "rsvp";

const SoundBoardItem =  Backbone.Model.extend({
    
getId : function(){
    this.get("id");
},

getUrl : function(){
    this.get("url");
},	

getName : function(){
    this.get("name");
},

getIconUrl :function(){
    this.get("iconUrl");
},

setUrl : function(url){
    this.set("url",url);
},	

setName : function(name){
    this.set("name", name);
},

setIconUrl :function(iconUrl){
    this.set("iconUrl", iconUrl);
},

    
});
export default SoundBoardItem;