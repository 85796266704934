

import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerOperation from "models/audioEditor/sequencerOperation/SequencerOperation";

const SequencerSettingsChangeOperation =  SequencerOperation.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperation.apply(this, [attributes, options]);
    },

    execute : function(settingToChange, newValue, silent){
        const oldValue = this.getSequencer().getSequencerSettings().getSetting(settingToChange);
        this.getSequencer().getSequencerSettings().changeSetting(settingToChange, newValue);
        if(!silent){
            this.getSequencer().getHistoryManagement().addSequencerOperationSettingsChangeLog(settingToChange, oldValue, newValue);
        }
    },


    undo: function(settingsChangeLog){
        this.getSequencer().getSequencerSettings().changeSetting(settingsChangeLog.getSettingToChange(), settingsChangeLog.getOldValue());
    },

    redo: function(settingsChangeLog){
        this.getSequencer().getSequencerSettings().changeSetting(settingsChangeLog.getSettingToChange(), settingsChangeLog.getNewValue());
    },





});

export default SequencerSettingsChangeOperation; 