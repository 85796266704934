
import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import SequencerController from "controllers/audioEditor/SequencerController";
import DynamicPageView from "views/common/DynamicPageView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import Utils from "models/helper/Utils";
import ti18n from 'i18n!nls/Sequenceri18n';
import UserPageTemplate from 'text!../../../templates/audioEditor/AudioEditorDemoPageViewTemplate.html';
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";

const AudioEditorDemoPageController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if (options) {

            if (options.pageId) {
                this.pageId = options.pageId;
            }
            this._pageTitle =  options.pageTitle? options.pageTitle: window.getI18n(ti18n, "AUDIO_EDITOR_DEMO");
            
        }

    },

    signUpAndRecord: function(){
        return window.open(PersistentModels.getInstance().getRouter().getSignUpAndRecordPageURL());
    },

    getModel : function(){
        return this.model;
    },
    
    getView : function(){
        return this.view;
    },
    
    navigateToPreviousPage : function(){
        PersistentModels.getInstance().getRouter().navigateToPreviousPage();
    },
    
    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve,reject){
            try{

                this.sequencerController =  new SequencerController();
                this.sequencerController.setAutoSaveOn(false);
                this.sequencerController.addTranscribeAudio("https://s3.amazonaws.com/com.trebble.capsules.plain/573cfeb2f538f40a0042a37a/armel_audio_editor_samson_clearn-2db_2db.mp3","https://s3.us-east-1.amazonaws.com/com.trebble.audio.transcriptions/62abdda08cc09900041555a6.json", "62aaa1db373b600004baab7c").catch(window.alertErrorMessage);
                //this.sequencerController.addTranscribeAudio("https://s3.amazonaws.com/com.trebble.trebble.audio.recordings/573cfeb2f538f40a0042a37a/capsulePart_1655349721190","https://s3.us-east-1.amazonaws.com/com.trebble.audio.transcriptions/62aaa1db373b600004baab7c.json").catch(window.alertErrorMessage);
                //this.sequencerController.addTranscribeAudio("https://s3.amazonaws.com/com.trebble.songs.hightlights/573cfeb2f538f40a0042a37a/1634646552863.mp3","https://s3.amazonaws.com/com.trebble.songs.hightlights/573cfeb2f538f40a0042a37a/1634646552863_asrOutput.json").catch(window.alertErrorMessage);
                //this.sequencerController.addTranscribeAudio("https://s3.amazonaws.com/com.trebble.capsules.plain/61f2073de4240400049c1fb3/userCreatedCapsule_1646366333729.mp3","https://s3.us-east-1.amazonaws.com/com.trebble.audio.transcriptions/62218e951c32db0004b88b93.json").catch(window.alertErrorMessage);
                //this.sequencerController.addTranscribeAudio("https://s3.amazonaws.com/com.trebble.capsules.plain/573cfeb2f538f40a0042a37a/userCreatedCapsule_1646064229449.mp3","https://s3.us-east-1.amazonaws.com/com.trebble.audio.transcriptions/621cf347978d2000043a0392.json").catch(window.alertErrorMessage);
                this.sequencerController.buildModelAndRenderView().then((function(){
                    this.sequencerController.setHeightMargin(10);
                    const sequencerView = this.sequencerController.getView();
                    const pageTemplateOptions=  {};
                    pageTemplateOptions.pageTitle = this._pageTitle;
                    pageTemplateOptions.ti18n = ti18n;
                    const pageViewParams= {};
                    pageViewParams.pageId = this.pageId;
                    pageViewParams.containerSelector = ".ui-content";
                    pageViewParams.contentView = sequencerView;
                    pageViewParams.pageViewTemplate = UserPageTemplate;
                    pageViewParams.addContentLeftRightPadding = true;
                    pageViewParams.pageTemplateOptions = pageTemplateOptions;
                    pageViewParams.ignoreDefaultPageUIContentPadding = true;
                    this.view = new DynamicPageView(pageViewParams);
                    this.view.render();
                    this.view.$el.find(".ui-content > div").addClass("left-right-content-padding");
                    this.view.$el.on("click", "#signUpAndRecordBtn", this.signUpAndRecord.bind(this));
                    this.sequencerController.setJqueryPageElWhereSequencerWillBeAdded(this.view.$el);
                    resolve(this);
                }).bind(this)).catch(reject);

            }catch(error){
                reject(error);
            }
            
        }).bind(this));

        
    }

});
export default AudioEditorDemoPageController;