import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const trebbleServerUrl = window.trebble.usedProtocolPrefix+"polar-temple-1881.herokuapp.com";
//const trebbleServerUrl = "192.168.2.108:8888"; //maison
//const trebbleServerUrl = "http://10.15.209.251:8888";
//const trebbleServerUrl = "http://172.16.1.129:8888"; //travail
//const trebbleServerUrl = "http://192.168.0.102:8888"; //

const TrebbleSockJSHelper = Backbone.Model.extend({
    "default" : {
        
    },

    initialize : function() {/*
        this._ready =  false;
        this._initializationFailed = false;
        this._initializationError = null;
        this._isHandshaking = true;
        this._socket =  null;
        this._readyPromiseResolve =  null;
        this._readyPromiseReject =  null;
        this._successPromisePending = {};
        this._failurePromisePending = {};
        this._readyPromise  = new RSVP.Promise((function(resolve, reject){
            this._readyPromiseResolve = resolve;
            this._readyPromiseReject =  reject;
            this._socket = new SockJS(trebbleServerUrl +"/messages", null, {debug: true});
            this._socket.onopen = this._onSocketOpen.bind(this);
            this._socket.onmessage = this._onMessageReceived.bind(this);
            this._socket.onclose = this._onSocketClose.bind(this);
        }).bind(this)).catch((function(error){
            this._ready = true;
            this._initializationFailed = true;
            this._initializationError = error;
            this._readyPromiseReject(error);
            this._isHandshaking = false;
        }).bind(this));
        return this._readyPromise;
*/
        
    },

    _getSocket: function(){
        if(this._socket){
            return RSVP.Promise.resolve(this._socket);
        }else{
            return this.initialize().then((function(){
                return this._socket;
            }).bind(this));
        }
    },
    
    _onSocketOpen : function(){
        try{
            const userInfoJson = LocalStorageHelper.getInstance().getUserInfo();
            const username = userInfoJson?  userInfoJson.username : window.device.platform+ window.device.uuid;
            const message = {"username": username};
            const action = "handshake";
            this._sendClientRequestMessage(action, message);
        }catch(err){
            if(this._isHandshaking)
            {
                this._isHandshaking = false;
                this._readyPromiseReject(err);
            }else{
                throw err;
            }
        }
    },
    
    _sendClientRequestMessage : function(action, message){

        const wrappedMessage = this._buildDefaultClientRequestMessage(action, message );
        this._getSocket().then((function(socket){
            socket.send(JSON.stringify(wrappedMessage));
            window.log("Socket sent Message. Message:" + JSON.stringify(wrappedMessage));
        }).bind(this));
        return new RSVP.Promise((function(resolve, reject){
            this._successPromisePending[wrappedMessage._id] = resolve;
            this._failurePromisePending[wrappedMessage._id] = reject;
        }).bind(this));
    },
    
    _sendClientInfoMessage : function(infoType, message){
        const wrappedMessage = this._buildDefaultClientInfoMessage(infoType, message );
        this._getSocket().then((function(socket){
            socket.send(JSON.stringify(wrappedMessage));
            window.log("Socket sent Message. Message:" + JSON.stringify(wrappedMessage));
        }).bind(this));
        return new RSVP.Promise((function(resolve, reject){
            this._successPromisePending[wrappedMessage._id] = resolve;
            this._failurePromisePending[wrappedMessage._id] = reject;
        }).bind(this));
    },
    
    _buildDefaultClientRequestMessage : function(actionType, message){
        const m  = {};
        m.isClientRequest = true;
        m.isClientResponse = false;
        m.isClientInfo = false;
        m.action = actionType;
        m.socketUID = this._socketUID;
        m.requestMessage  = message;
        m._id = window.ID();
        return m;
    },
    
    _buildDefaultClientInfoMessage : function(infoType, message){
        const m  = {};
        m.isClientRequest = false;
        m.isClientResponse = false;
        m.isClientInfo = true;
        m.infoType = infoType;
        m.socketUID = this._socketUID;
        m.infoMessage  = message;
        m._id = window.ID();
        return m;
    },
    
    
    _buildDefaultClientResponseMessage : function(respondTo, message){
        const m  = {};
        m.isClientRequest = false;
        m.isClientResponse = true;
        m.isClientInfo = false;
        m.respondTo = respondTo;
        m.socketUID = this._socketUID;
        m.responseMessage  = message;
        m._id = window.ID();
        return m;
    },
    
    
    _onMessageReceived : function(e){
        try{
            window.log("message was recieved from socket");
            const dataJson = JSON.parse(e.data);
            if(dataJson.isError)
            {
                this._onErrorResponseMessage(dataJson);
            }else{
                if(dataJson.isServerRequest)
                {
                    this._onServerRequest(dataJson);
                }else{
                    if(dataJson.isServerResponse)
                    {
                        this._onServerResponse(dataJson);
                    }
                }
            }
        }catch(err){
            if(this._isHandshaking)
            {
                this._isHandshaking = false;
                this._readyPromiseReject(err);
            }else{
                throw err;
            }
        }
    },
    
    _onErrorResponseMessage : function(message){
        const errorMessage = message.errorMessage;
        const responseTo = message.responseTo;
        console.error("Error was received in response to '"+responseTo+"' .Error :"+errorMessage);
        const failureCallback = this._failurePromisePending[message._id];
        if(failureCallback)
        {
            failureCallback(errorMessage);
        }
    },
    
    _onServerRequest : function(message){
        
    },
    
    _onServerResponse : function(message){
        const successCallback = this._successPromisePending[message._id];
        if(successCallback)
        {
            successCallback(message.responseMessage);
        }
        if(message.responseTo == "handshake")
        {
            this._onHandshakeResponse(message.responseMessage);
        }
    },
    
    _onHandshakeResponse : function(response){
        this._socketUID  = response.socketUID;
        this._ready  = true;
        this._isHandshaking = false;
        this._readyPromiseResolve();

    },
    
    _onSocketClose : function(){
        this._ready = false;
        this._socket =  null;
        this._socketUID  = null;
        window.log("Socket is closed");
        if(Utils.getInstance().isOverWifi() || Utils.getInstance().isOverDataPlan() || window.device.platform == "browser"){
            this.initialize();
        }else{
            //try to connect every 60seconds
            window.setTimeout((function(){
                this.initialize();
            }).bind(this), 60* 1000);
        }
        
    },

    sendPlayerStatus : function(currentLoadedSongJSON, stoppedPlaying){
        const message = {};
        message.playerStatus = "PLAYING";
        const cache = [];
        message.songInfoString = JSON.stringify(currentLoadedSongJSON,function(key, value) {
            if (typeof value === 'object' && value !== null) {
                if (cache.indexOf(value) !== -1) {
                    // Circular reference found, discard key
                    return;
                }
                // Store value in our collection
                cache.push(value);
            }
            return value;
        });
        if(stoppedPlaying){
            const playerStatus = "STOPPED";
        }
        return this._sendClientInfoMessage("playerState", message);
    },

    getOnlineUsersInfo : function(){
        const actionType = "getListOfOnlineUsers";
        const message = {};
        return this._sendClientRequestMessage(actionType, message);
    },

});

const trebbleSockJSHelperInstance = new TrebbleSockJSHelper();

export default {
    getInstance : function() {
        return trebbleSockJSHelperInstance;
    }
};