import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Artist from "models/Artist";

var ArtistsTabContentCollection =  Backbone.Collection.extend({
    model: Artist,


    initialize: function(models, options){
        this._sort_key = "artistName";

    },

    url: function() {
        //return '/myOwnSongLibrary'; 
    },
    
    searchOnArtistname : function(letters, resultSize){
        if(!letters){
            letters = "";
        }
        if(letters == ""){
            if(resultSize)
            {
                return this.models.slice(0,resultSize );
            }else{
                return this.models;
            }
            
        }
        var resultFound = 0;
        letters  = letters.toLowerCase();
        var patternArray = [];
        var words = letters.split(/\s+/);
        for(let index in words){
            var escapedWord =  words[index].replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
            patternArray.push(new RegExp(escapedWord,"gi"));
        }
        return this.filter(function(data) {
            if(!resultSize || (resultSize  && resultFound < resultSize))
            {
                var artistName = "";
                if(data.get("artistName")){
                    artistName =  artistName + data.get("artistName");
                }
                
                var found = true;
                for(let index in patternArray){
                    var pattern = patternArray[index];
                    found = pattern.test(artistName.toLowerCase()); 
                    if(!found){
                        break;
                    }
                }
                if(found){
                    resultFound = resultFound+ 1;
                }
                return  found;
            }else{
                return false;
            }
        });
    },
    
     comparator: function(item) {
            var data = item.get(this._sort_key);
            if(data && ( typeof data == "string" || data instanceof String))
            {
                data = data.trim().toLowerCase();
            }
            return  data;
      },
      
      sortByField: function(fieldName) {
            this._sort_key = fieldName? fieldName : this._sort_key;
            this.sort();
      },

      
      getContext : function(){
            return this._context;
        },
        
        updateContext : function(newContext){
            this._context = newContext;
        },
        
    save: function(attrs, options){
        window.alertErrorMessage("attrs" + JSON.stringify(attrs));
        window.alertErrorMessage("options" + JSON.stringify(options));

        Backbone.sync('create', this, {
            success: function() {
                window.log('Saved!');
            },
            failure: function(){
                window.alertErrorMessage("savin..6");
            }
        });
    }
});
export default ArtistsTabContentCollection;