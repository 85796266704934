import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import CapsuleCardController from "controllers/capsule/CapsuleCardController";
import CollectionRepeatController from "controllers/common/CollectionRepeatController";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import CapsulesPageView from "views/capsulesPage/CapsulesPageView";
import Capsules from "collections/Capsules";
import Songs from "collections/Songs";
import AudioRecorderHelper from "models/helper/AudioRecorderHelper";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import SongSelectorPopupController from "controllers/common/SongSelectorPopupController";
import ti18n from "i18n!nls/CapsulesPagei18n";
import RSVP from "rsvp";
import NotificationDefaultListItemTemplate from "text!../../../templates/common/NotificationDefaultListItemTemplate.html";

const CapsulesPageController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = options.model;
        this.context = options.context;
        this._initializeUserOwnedCapsules(options);
        this._initializeUserReceivedCapsules(options);
        this._initializeOffAirCapsules(options);
        this._initializeDraftCapsules(options);
        this._initializeUserScheduledCapsules(options);
    },

    _initializeUserOwnedCapsules : function(options){
        const controllerParams = {};
        controllerParams.showActiveFlagInView = true;
        controllerParams.context = Utils.getInstance().buildContextForMyCapsulePage();
        controllerParams.onActionExecutedOnUserHandler = this._onActionExecutedByUserOnDisplayedCapsule.bind(this);
        const collectionRepeatControllerParams = {};
        collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildInfoBoxParams();
        collectionRepeatControllerParams.showEmtptyInfoBox = true;
        collectionRepeatControllerParams.controllerOption = controllerParams;
        collectionRepeatControllerParams.itemControllerConstructor = CapsuleCardController;
        collectionRepeatControllerParams.modelLoadHandler = options.loadHandler?options.loadHandler: this._getMyOnAirCapsulesLoadHandler.bind(this);
        collectionRepeatControllerParams.resizePlaceHolderElem = true;
        collectionRepeatControllerParams.heightMargin = 52 + 39 + 33;
        if(options.context){
            collectionRepeatControllerParams.context = options.context;
        }
        this._capuleListLoaded = false;
        collectionRepeatControllerParams.actionButtonHandlerFunction = this.createCapsuleNew.bind(this);
        this.onAirCollectionRepeatController = new CollectionRepeatController(collectionRepeatControllerParams);
    },

    _initializeUserScheduledCapsules : function(){
        const controllerParams = {};
        controllerParams.showActiveFlagInView = true;
        controllerParams.context = this.context;
        controllerParams.onActionExecutedOnUserHandler = this._onActionExecutedByUserOnDisplayedCapsule.bind(this);
        const collectionRepeatControllerParams = {};
        collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildInfoBoxParamsForScheduledCapsules();
        collectionRepeatControllerParams.showEmtptyInfoBox = true;
        collectionRepeatControllerParams.controllerOption = controllerParams;
        collectionRepeatControllerParams.itemControllerConstructor = CapsuleCardController;
        collectionRepeatControllerParams.modelLoadHandler = this._getMyScheduledCapsulesLoadHandler.bind(this);
        collectionRepeatControllerParams.resizePlaceHolderElem = true;
        collectionRepeatControllerParams.heightMargin = 52 + 39 + 33;
        collectionRepeatControllerParams.context = this.context;
        this._capuleListLoaded = false;
        collectionRepeatControllerParams.actionButtonHandlerFunction = this.createCapsuleNew.bind(this);
        this.scheduledCollectionRepeatController = new CollectionRepeatController(collectionRepeatControllerParams);
    },

    _initializeUserReceivedCapsules : function(options){
        const controllerParams = {};
        controllerParams.showActiveFlagInView = false;
        controllerParams.context = Utils.getInstance().buildContextForMyCapsulePage();
        controllerParams.onActionExecutedOnUserHandler = this._onActionExecutedByUserOnDisplayedCapsule.bind(this);
        const collectionRepeatControllerParams = {};
        collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildInfoBoxParamsForReceivedCapsules();
        collectionRepeatControllerParams.showEmtptyInfoBox = true;
        collectionRepeatControllerParams.controllerOption = controllerParams;
        collectionRepeatControllerParams.itemControllerConstructor = CapsuleCardController;
        collectionRepeatControllerParams.modelLoadHandler =  this._getMyReceivedCapsulesLoadHandler.bind(this);
        collectionRepeatControllerParams.resizePlaceHolderElem = true;
        collectionRepeatControllerParams.heightMargin = 52 + 39 + 33;
        if(options.context){
            collectionRepeatControllerParams.context = options.context;
        }
        this._receivedCapuleListLoaded = false;
        collectionRepeatControllerParams.actionButtonHandlerFunction = this.createCapsuleNew.bind(this);
        this.receivedCollectionRepeatController = new CollectionRepeatController(collectionRepeatControllerParams);
    },



    _initializeOffAirCapsules : function(options){
        const controllerParams = {};
        controllerParams.showActiveFlagInView = false;
        controllerParams.context = Utils.getInstance().buildContextForMyCapsulePage();
        controllerParams.onActionExecutedOnUserHandler = this._onActionExecutedByUserOnDisplayedCapsule.bind(this);
        const collectionRepeatControllerParams = {};
        collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildInfoBoxParamsForOffAirCapsules();
        collectionRepeatControllerParams.showEmtptyInfoBox = true;
        collectionRepeatControllerParams.controllerOption = controllerParams;
        collectionRepeatControllerParams.itemControllerConstructor = CapsuleCardController;
        collectionRepeatControllerParams.modelLoadHandler =  this._getMyOffAirCapsulesLoadHandler.bind(this);
        collectionRepeatControllerParams.resizePlaceHolderElem = true;
        collectionRepeatControllerParams.heightMargin = 52 + 39 + 33;
        if(options.context){
            collectionRepeatControllerParams.context = options.context;
        }
        this._offAirCapuleListLoaded = false;
        collectionRepeatControllerParams.actionButtonHandlerFunction = this.createCapsuleNew.bind(this);
        this.offAirCollectionRepeatController = new CollectionRepeatController(collectionRepeatControllerParams);
    },

    _initializeDraftCapsules : function(options){
        const controllerParams = {};
        controllerParams.showActiveFlagInView = false;
        controllerParams.context = Utils.getInstance().buildContextForMyCapsulePage();
        controllerParams.onActionExecutedOnUserHandler = this._onActionExecutedByUserOnDisplayedCapsule.bind(this);
        const collectionRepeatControllerParams = {};
        collectionRepeatControllerParams.customInfoBoxInfoParams = this._buildInfoBoxParamsForDraftCapsules();
        collectionRepeatControllerParams.showEmtptyInfoBox = true;
        collectionRepeatControllerParams.controllerOption = controllerParams;
        collectionRepeatControllerParams.itemControllerConstructor = CapsuleCardController;
        collectionRepeatControllerParams.modelLoadHandler =  this._getMyDraftCapsulesLoadHandler.bind(this);
        collectionRepeatControllerParams.resizePlaceHolderElem = true;
        collectionRepeatControllerParams.heightMargin = 52 + 39 + 33;
        if(options.context){
            collectionRepeatControllerParams.context = options.context;
        }
        this._draftCapuleListLoaded = false;
        collectionRepeatControllerParams.actionButtonHandlerFunction = this.createCapsuleNew.bind(this);
        this.draftCollectionRepeatController = new CollectionRepeatController(collectionRepeatControllerParams);
    },

    _onSongSelectedForCapsuleCreation: function(songSelected, noSongSelectedButtonClicked){
        if(songSelected || noSongSelectedButtonClicked){
            $.mobile.loading("show");
            if(window.device && window.device.platform =="browser"){
                AudioRecorderHelper.getInstance().initUserMedia().then((function(allowed){
                    $.mobile.loading("hide");
                    if(allowed){
                        return PersistentModels.getInstance().getRouter().showAddAudioHightlightPageNew(songSelected,null, this.context, this._onCapsuleSuccessfullyCreated.bind(this),true, true);
                    }else{
                        return PersistentModels.getInstance().getRouter().showAddAudioHightlightPageNew(songSelected,null, this.context, this._onCapsuleSuccessfullyCreated.bind(this),false, true);
                    }
                }).bind(this)).catch((function(e){
                    $.mobile.loading("hide");
                    window.navigator.trebbleNotification.alert(e, null, null, "warning"); 
                    return PersistentModels.getInstance().getRouter().showAddAudioHightlightPageNew(songSelected,null, this.context, this._onCapsuleSuccessfullyCreated.bind(this),false, true);
                }).bind(this));
            }else{
                $.mobile.loading("hide");
                return PersistentModels.getInstance().getRouter().showAddAudioHightlightPageNew(songSelected, null, this.context, this._onCapsuleSuccessfullyCreated.bind(this),true, true);
            }
        }
    },

    _onActionExecutedByUserOnDisplayedCapsule : function(actionName, capsuleJson, capsuleCardControllerInstance){
        this.onAirCollectionRepeatController.reload();
        this.offAirCollectionRepeatController.reload();
        this.draftCollectionRepeatController.reload();
        this.receivedCollectionRepeatController.reload();
    },

    _onCapsuleSuccessfullyCreated : function(){
        this.onAirCollectionRepeatController.reload();
        this.receivedCollectionRepeatController.reload();
        this.offAirCollectionRepeatController.reload();
        this.draftCollectionRepeatController.reload();
    },

    createCapsule :function(){

        const params = {};
        params.songCollection =  new Songs( PersistentModels.getInstance().getSongCollection().searchOnTitleAndArtist(""));//new YTSCRemoteSongCollection();
        //	params.songCollection.setSource(YTSCRemoteSongCollection.sourceTypes.YOUTUBE);
        params.selectedFilterOption = null;//Utils.getInstance().getFilterListItemModel("ON_DEVICE");
        params.singleSelect = true;
        if(window.device && window.device.platform =="browser" && !window.isMobileBrowser){
            params.title = window.getI18n(ti18n,"SONG_TO_PLAY_AFTER_CAPSULE");
        }else{
            params.title = window.getI18n(ti18n,"CHOOSE_SONG_TO_PLAY_AFTER_CAPSULE");
        }
        params.infoBoxMessageOnFieldEmpty = window.getI18n(ti18n,"CHOOSE_A_SONG_FROM_YOUR_LIBRARY");
        params.onPopupClose = this._onSongSelectedForCapsuleCreation.bind(this);
        params.noSongSelectionOptionAllowed = true;
        params.noSongSelectionButtonLabel = window.getI18n(ti18n,"DO_NOT_PLAY_A_SPECIFIC_SONG_AFTER_CAPSULE");
        const songSelectorPopupController = new SongSelectorPopupController(params);
        songSelectorPopupController.buildModelAndRenderView();
        

},

createCapsuleNew :function(){
this._onSongSelectedForCapsuleCreation(null, true);
},


getDefaultNotificationControllerParams : function(customPlayloadModel){
const notificationCardControllerParams = {};
notificationCardControllerParams.model = customPlayloadModel;
notificationCardControllerParams.showActionButton = false;
notificationCardControllerParams.customTemplate = NotificationDefaultListItemTemplate;
return notificationCardControllerParams;
},



_buildInfoBoxParams : function(){
const params = {};
params.iconClass = "ion-contrast";
params.message = window.getI18n(ti18n,"NO_CAPSULE_ON_AIR");
params.helpMessage = window.getI18n(ti18n,"CREATE_A_CAPSULE_BY_RECORDING_YOURSELF");
params.actionButtonLabel = window.getI18n(ti18n,"CREATE_CAPSULE");
return params;

},

_buildInfoBoxParamsForScheduledCapsules : function(){
const params = {};
params.iconClass = "ion-contrast";
params.message = window.getI18n(ti18n,"NO_SCHEDULED_CAPSULE");
params.helpMessage = window.getI18n(ti18n,"THIS_IS_WHERE_YOU_WILL_SEE_THE_LIST_OF_ALL_CAPSULES");
return params;

},


_buildInfoBoxParamsForOffAirCapsules : function(){
const params = {};
params.iconClass = "ion-contrast";
params.message = window.getI18n(ti18n,"NO_ARCHIVED_CAPSULE");
params.helpMessage = window.getI18n(ti18n,"THIS_IS_WHERE_YOU_WILL_ARCHIVED_CAPSULES");
return params;

},

_buildInfoBoxParamsForDraftCapsules : function(){
const params = {};
params.iconClass = "ion-contrast";
params.message = window.getI18n(ti18n,"NO_DRAFT_CAPSULE");
params.helpMessage = window.getI18n(ti18n,"THIS_IS_WHERE_YOU_WILL_SEE_DRAFT_CAPSULES");
return params;

},


_buildInfoBoxParamsForReceivedCapsules : function(){
const params = {};
params.iconClass = "ion-contrast";
params.message = window.getI18n(ti18n,"NO_RECEIVED_CAPSULE");
params.helpMessage = window.getI18n(ti18n,"THIS_IS_WHERE_YOU_WILL_SEE_RECEIVED_CAPSULE");
return params;

},

_getMyOnAirCapsulesLoadHandler: function(){
return TrebbleClientAPIHelper.getInstance().getOnAirCapsules().then(function(capsulesInfoArray){
if(capsulesInfoArray && capsulesInfoArray.length > 0){
    this.capsulesCollection = new Capsules(capsulesInfoArray);
    return this.capsulesCollection.models;
}else{
    return  [];
}
});
},


_getMyScheduledCapsulesLoadHandler: function(){
return TrebbleClientAPIHelper.getInstance().getScheduledCapsules().then(function(capsulesInfoArray){
if(capsulesInfoArray && capsulesInfoArray.length > 0){
    this.capsulesCollection = new Capsules(capsulesInfoArray);
    return this.capsulesCollection.models;
}else{
    return  [];
}
});
},

_getMyReceivedCapsulesLoadHandler: function(){
return TrebbleClientAPIHelper.getInstance().getReceivedCapsules().then(function(receivedCapsulesInfoArray){
if(receivedCapsulesInfoArray && receivedCapsulesInfoArray.length > 0){
    this.receivedCapsulesCollection = new Capsules(receivedCapsulesInfoArray);
    return this.receivedCapsulesCollection.models;
}else{
    return  [];
}
});
},

_getMyOffAirCapsulesLoadHandler: function(){
return TrebbleClientAPIHelper.getInstance().getOffAirCapsules().then(function(offAirCapsulesInfoArray){
if(offAirCapsulesInfoArray && offAirCapsulesInfoArray.length > 0){
    this.offAirCapsulesCollection = new Capsules(offAirCapsulesInfoArray);
    return this.offAirCapsulesCollection.models;
}else{
    return  [];
}
});
},

_getMyDraftCapsulesLoadHandler: function(){
return TrebbleClientAPIHelper.getInstance().getDraftCapsules().then(function(draftCapsulesInfoArray){
if(draftCapsulesInfoArray && draftCapsulesInfoArray.length > 0){
    this.draftCapsulesCollection = new Capsules(draftCapsulesInfoArray);
    return this.draftCapsulesCollection.models;
}else{
    return  [];
}
});
},

getModel : function() {
return this.model;
},

getView : function() {
return this.view;
},

showAndPlayWhatsACapsuleCapsule: function(){
PersistentModels.getInstance().getRouter().showAndPlayWhatACapusleCapsule().then((function(capsuleDetailsPopupController){
capsuleDetailsPopupController.playCapsule();
this.listenTo(capsuleDetailsPopupController,"finishPlaying", (function(){
    LocalStorageHelper.getInstance().setWhatsACapsuleCapsulePlayed();
}).bind(this))
}).bind(this));
},



_notifyNotificationConsumed : function(){
/*if(this.model ){
if(Utils.getInstance().isSongInstance(this.model)){
    return PersistentModels.getInstance().notifyUserOfUnconsumedOwnerComments(this.model, this.context, this.songRefSummary);
}else{
    if(Utils.getInstance().isCommentInstance(this.model)){
        const commentId =  this.model.getCommentId();
        return TrebbleClientAPIHelper.getInstance().markCommentRepliesAsConsumed(commentId);
    }else{
        return RSVP.Promise.resolve();
    }
}
}else{
return RSVP.Promise.resolve();
}*/
},

showHomePage : function(){
PersistentModels.getInstance().getRouter().showHomePage();
},

onCapsulePageShow :function(){
if(this.view.getCapsuleListView()){
this.onAirCollectionRepeatController.reload();
}
if(this.view.getReceivedCapsuleListView()){
this.receivedCollectionRepeatController.reload();
}
if(this.view.getMyOffAirCapsuleListView()){
this.offAirCollectionRepeatController.reload();
}
if(this.view.getMyDraftCapsuleListView()){
this.draftCollectionRepeatController.reload();
}
if(!LocalStorageHelper.getInstance().isWhatsACapsuleCapsulePlayed() && !PersistentModels.getInstance().hasCapsuleCreated()){
this.view.showWhatsACapsuleButton();
}else{
this.view.hideWhatsACapsuleButton();
}
},

onViewRemoved:function(){
this.stopListening();
},


buildModelAndRenderView : function() {
$.mobile.loading("show");
return new RSVP.Promise((function(resolve, reject) {
try {
    const promises = [];
    //promises.push(this.onAirCollectionRepeatController.buildModelAndRenderView());
    RSVP.Promise.all(promises).then((function(result){			
        const pageViewParams = {};
        pageViewParams.capsulesListView = this.onAirCollectionRepeatController.getView();
        pageViewParams.receivedCapsulesListView = this.receivedCollectionRepeatController.getView();
        this.view = new CapsulesPageView(pageViewParams);
        this.listenTo(this.view, "showHomePage", this.showHomePage);
        this.listenTo(this.view, "createCapsule", this.createCapsuleNew.bind(this));
        this.listenTo(this.view, "pageShow", this.onCapsulePageShow.bind(this));
        this.listenTo(this.view, "remove", this.onViewRemoved.bind(this));
        this.listenTo(this.view, "onWhatsACapsuleButton", this.showAndPlayWhatsACapsuleCapsule.bind(this));
        this.view.render();
        $.mobile.loading("hide");
        $.mobile.loading("show");

        const promisesList = [];
        const p1 = this.onAirCollectionRepeatController.buildModelAndRenderView().then((function(){
            this.view.setCapsuleListView(this.onAirCollectionRepeatController.getView());
            $.mobile.loading("hide");
        }).bind(this)).catch((function(error){
            $.mobile.loading("hide");
            window.alertErrorMessage(error);
        }).bind(this));
        promisesList.push(p1);

        const p2 = this.receivedCollectionRepeatController.buildModelAndRenderView().then((function(){
            this.view.setReceivedCapsuleListView(this.receivedCollectionRepeatController.getView());
            $.mobile.loading("hide");
        }).bind(this)).catch((function(error){
            $.mobile.loading("hide");
            window.alertErrorMessage(error);
        }).bind(this));
        promisesList.push(p2);

        const p3 = this.offAirCollectionRepeatController.buildModelAndRenderView().then((function(){
            this.view.setMyOffAirCapsuleListView(this.offAirCollectionRepeatController.getView());
            $.mobile.loading("hide");
        }).bind(this)).catch((function(error){
            $.mobile.loading("hide");
            window.alertErrorMessage(error);
        }).bind(this));
        promisesList.push(p3);

        const p4 = this.draftCollectionRepeatController.buildModelAndRenderView().then((function(){
            this.view.setMyDraftCapsuleListView(this.draftCollectionRepeatController.getView());
            $.mobile.loading("hide");
        }).bind(this)).catch((function(error){
            $.mobile.loading("hide");
            window.alertErrorMessage(error);
        }).bind(this));
        promisesList.push(p4);

        resolve();
    }).bind(this)).catch((function(error){
        $.mobile.loading("hide");
        reject(error);
    }).bind(this));

} catch (error) {
    $.mobile.loading("hide");
    reject(error);
}

}).bind(this));

}

});

export default CapsulesPageController;