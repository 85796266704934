import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import AlbumListController from "controllers/common/AlbumListController2";
import PageView from "views/common/PageView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import RSVP from "rsvp";

const AlbumListPageController = Chaplin.Controller.extend({


    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if (options) {
            if (options.albumCollection) {
                this.model = options.albumCollection;
                this.albumCollection = options.albumCollection;
            }
            if (options.pageTitle) {
                this.pageTitle = options.pageTitle;
            }
            if (options.pageId) {
                this.pageId = options.pageId;
            }
            if (options.searchKeyword) {
                this.searchKeyword = options.searchKeyword;
            }
            
        }
        const albumListControllerParams = {};
        albumListControllerParams.sortField = "album";
        albumListControllerParams.resizePlaceHolderElem = false;
        albumListControllerParams.loadHandler = (options && options.albumListLoadHandler)?options.albumListLoadHandler: this.getAlbumListLoadHandler().bind(this);
        this.albumListController = new AlbumListController(albumListControllerParams);
                    
    },
    getSearchKeyword :function(){
        return this.searchKeyword;
    },
    
    getModel : function(){
        return this.model;
    },
    
    getView : function(){
        return this.view;
    },
    
    updateListOfAlbums : function(arrayOfAlbumsJson){
        this.model.reset(arrayOfAlbumsJson);
    },
    



    
    getAlbumListLoadHandler : function(){
        const searchKeyword = this.searchKeyword;
        return function(){
            return TrebbleClientAPIHelper.getInstance().searchForAllAlbums(searchKeyword.trim(), 100, 1).then(function(results){
                return results.albums;
            });
        }
    },
    
    onViewRemoved:function(){
        this.stopListening();
    },
        
    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve,reject){
            try{
                this.albumListController.buildModelAndRenderView().then((function(){
                    const albumListView = this.albumListController.getView();
                    const pageViewParams= {};
                    pageViewParams.pageId = this.pageId;
                    pageViewParams.pageTitle = this.pageTitle;
                    pageViewParams.contentView = albumListView;
                    pageViewParams.addContentLeftRightPadding = true;
                    this.view = new PageView(pageViewParams);
                    this.view.render();
                    this.listenTo(this.view, "remove", this.onViewRemoved.bind(this));
                    resolve(this);
                }).bind(this)).catch(reject);

            }catch(error){
                reject(error);
            }
            
        }).bind(this));

        
    }

});
export default AlbumListPageController;