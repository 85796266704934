import _ from 'underscore';
import Backbone from "backbone";
import TrebbleClientAPI from "models/helper/TrebbleClientAPI";
import RSVP from "rsvp";


const TrebbleDBHelper = Backbone.Model.extend({
    

    initialize : function() {
        this.songArray = [];
        this.albumArray = [];
        this.artistArray = [];	
        this.autoPlaylistArray = [];
        this.tagInfoArray = [];
        this.groupTagInfoArray = [];
    },

    isReady : function(){
        return RSVP.Promise.resolve();
    }, 
    
    saveSongArray : function(songArrayToAdd){
        for (const i in songArrayToAdd) {
            const song = songArrayToAdd[i];
            song.deleted = "false";
            if(!song.idified){
                song.idified = false;
                song.idifiedString = "false";
                song.idifiedTitle = "";
                song.synced = "false";
                song.idifiedSuccess = false;
                song.idifiedSuccessString = "false";
            }
            song.albumId = song.albumId?song.albumId:"Unknow Album";
            song.album = song.album?song.album:"Unknow Album";
            const album = {
                "albumId" : song.albumId,
                "album" : song.album,
                "year" : song.year,
                "artist" : song.artist,
                "artistId" : song.artist
            };

            song.artist = song.artist?song.artist:"Unknow Artist";
            const artist = {
                "artistId" : song.artist,
                "artistName" : song.artist
            };

            this.songArray.push(song);
            this.albumArray.push(album);
            this.artistArray.push(artist);

        }

        return RSVP.Promise.resolve();
    }, 

    getSongsAlbumArtists : function(){
        const songJsonArray = [];
        const songUriToSongJsonMap = {};
        const albumJsonArray = [];
        const albumIdToAlbumJsonMap = {};
        const artistJsonArray = [];
        const artistIdToArtistJsonMap =  {};
        const artistKeyToAlbumKeyMap = [];
        const albumKeyToSongMap = [];
        const albumKeyToAlbum = [];

        for(let index in this.songArray){
            var	song  =  this.songArray[index];
            if(song.deleted == "false"  && song.type == "localFile")
            {
                songUriToSongJsonMap[song.uri] = song;
                songJsonArray.push(song);
                let artistKey = null;
                let albumKey = null;
                if(song.idifiedSuccess == "true" || song.idifiedSuccess == true)
                {
                    artistKey = song.idifiedArtistId;
                    albumKey = song.idifiedAlbumId;
                }else{
                    artistKey = song.artist;
                    albumKey = song.albumId;
                }
                if(!(artistKey in artistKeyToAlbumKeyMap))
                {
                    artistKeyToAlbumKeyMap[artistKey] = [];
                }
                if(artistKeyToAlbumKeyMap[artistKey].indexOf(albumKey) < 0)
                {
                    artistKeyToAlbumKeyMap[artistKey].push(albumKey);
                }
                if(!(albumKey in albumKeyToSongMap))
                {
                    albumKeyToSongMap[albumKey] = [];
                }
                albumKeyToSongMap[albumKey].push(song);

            }

        }

        for(let index in this.albumArray){
            const album = this.albumArray[index];
            if(album.albumId in albumKeyToSongMap)
            {
                album.songs   = albumKeyToSongMap[album.albumId];
                albumIdToAlbumJsonMap[album.albumId] = album;
                albumJsonArray.push(album);
                albumKeyToAlbum[album.albumId] = album;
                for(let songIndex in album.songs)
                {
                    const songJSON = album.songs[songIndex];
                    songJSON.albumJson = album;
                }
            }
            if(album && !album.albumArtURL &&  album.songs && album.songs.length > 0 &&  album.songs[0].albumArtUrl){
                album.albumArtURL =  album.songs[0].albumArtUrl;
                album.albumArtImages =  album.songs[0].albumArtImages;
            }
        }

        for(let index in this.artistArray){
            const artist = this.artistArray[index];
            if(artist.artistId in artistKeyToAlbumKeyMap)
            {
                artist.albums = [];
                const artistAlbumKeyArray = artistKeyToAlbumKeyMap[artist.artistId];
                for(let artistAlbumKey in artistAlbumKeyArray)
                {
                    const album = albumKeyToAlbum[artistAlbumKeyArray[artistAlbumKey]];
                    if(album && !(album in artist.albums))
                    {
                        artist.albums.push(album);
                        album.artistJson = artist;
                    }
                }
                artistIdToArtistJsonMap[artist.artistId] = artist;
                artistJsonArray.push(artist);
            }
        }
        const result = { "songJsonArray" : songJsonArray,
        "songUriToSongJsonMap" : songUriToSongJsonMap,
        "albumJsonArray" : albumJsonArray,
        "albumIdToAlbumJsonMap" : albumIdToAlbumJsonMap,
        "artistJsonArray" : artistJsonArray,
        "artistIdToArtistJsonMap" : artistIdToArtistJsonMap}

        return RSVP.Promise.resolve(result);


    },

    getNextSongToIdify : function(){
        return RSVP.Promise.resolve();
    }, 

    deleteHiddenDataFromServerOnSong : function(songJson){
        delete songJson.prefetchedDirectVideoUrl;
        delete songJson.prefetchedDirectVideoUrlTimestamp;
        delete songJson.suggestedUri;
        delete songJson._hiddedDBData;
        delete songJson._buildOnServer;
    },

    _getGeneratedReleaseDate : function (songJson) {
        if(!songJson){
            return null;
        }else{
            let generatedReleaseDate = null;
            if(songJson.idifiedInfo){
                const idifiedInfo = songJson.idifiedInfo;
                if(songJson.idifiedReleaseDate){
                    if(songJson.idifiedReleaseDate && ( songJson.idifiedReleaseDate instanceof Date))
                    {
                        generatedReleaseDate = songJson.idifiedReleaseDate;
                    }
                    if(songJson.idifiedReleaseDate && ( typeof songJson.idifiedReleaseDate == "string"))
                    {
                        generatedReleaseDate = new Date(songJson.idifiedReleaseDate);
                    }

                }else{
                    if(songJson.idifiedReleaseDateString){
                        generatedReleaseDate = new Date(songJson.idifiedReleaseDateString);
                    }else{
                        if(idifiedInfo.year){
                            generatedReleaseDate = new Date(Math.abs(idifiedInfo.year), 1, 1, 0, 0, 0, 0);
                        }
                    }
                }

            }
            if(!generatedReleaseDate ){
                if(songJson.publishedAt){
                    generatedReleaseDate = new Date(songJson.publishedAt);
                }else{
                    let dateAdded = songJson.dateAdded;
                    if(dateAdded && ( dateAdded instanceof Date))
                    {
                        dateAdded = dateAdded.getTime();
                    }
                    if(dateAdded && ( typeof dateAdded == "string"))
                    {
                        dateAdded = new Date(dateAdded).getTime();
                    }
                    if(!dateAdded){
                        dateAdded = Date().getTime();
                    }
                    songJson.dateAdded =  dateAdded;
                    if(songJson.dateAdded){
                        generatedReleaseDate = new Date(songJson.dateAdded);
                    }
                }
            }
            return generatedReleaseDate;
        }
    },


    saveSong : function(song, context, silent, doNotSync, minimumSyncData, markSynced, waitForSyncOperation){
        song.deleted = "false";
        song._sourceContext = context;
        this.deleteHiddenDataFromServerOnSong(song);
        let albumUpdatedOrCreated = null ;
        let albumCreated = null;
        let artistUpdatedOrCreated = null ;
        let artistCreated = null;
        if(song.idified == "true" || song.idified == true)
        {
            if(song.idifiedInfo && song.idifiedInfo != "OK")
            {
                const idifiedInfo = song.idifiedInfo;
           //   song.idifiedSuccess = "true";
           //  song.idifiedDate = new Date();
           song.idifiedTitle = idifiedInfo.title;
           song.idifiedArtist = idifiedInfo.artist;
           song.idifiedAlbum = idifiedInfo.album;
           song.idifiedArtistId = idifiedInfo.artistId;
           song.songGracenoteId = idifiedInfo.gracenoteId;
           song.idifiedAlbumArtURL = idifiedInfo.albumArtUrl;
           song.idifiedAlbumArtImages = idifiedInfo.albumArtImages;
           song.idifiedAlbumId = idifiedInfo.albumId;
           song.idifiedTopGenreId = idifiedInfo.topGenreId;
           song.idifiedTopGenre = idifiedInfo.topGenre;
           song.idifiedTopOriginId = idifiedInfo.topOriginId;
           song.idifiedTopOrigin = idifiedInfo.topOrigin;
           song.idifiedTopEraId = idifiedInfo.topEraId;
           song.idifiedTopEra = idifiedInfo.topEra;
           song.idifiedTopMoodId = idifiedInfo.topMoodId;
           song.idifiedTopMood = idifiedInfo.topMood;
           song.idifiedTopTempoId = idifiedInfo.topTempoId;
           song.idifiedTopTempo = idifiedInfo.topTempo;
           song.idifiedTopArtistTypeId = idifiedInfo.topArtistTypeId;
           song.idifiedTopArtistType = idifiedInfo.topArtistType;
           song.idifiedGenreArray = idifiedInfo.genreArray;
           song.idifiedOriginArray = idifiedInfo.originArray;
           song.idifiedEraArray = idifiedInfo.eraArray;
           song.idifiedMoodArray = idifiedInfo.moodArray;
           song.idifiedTempoArray = idifiedInfo.tempoArray;
           song.idifiedArtistTypeArray = idifiedInfo.artistTypeArray;
           song.idifiedTempoArray = idifiedInfo.tempoArray;
           song.idifiedFeaturedArtists = idifiedInfo.featuredArtists? idifiedInfo.featuredArtists : null;
           song.idifiedInfo = idifiedInfo ;
       }else{
        if(song.idifiedInfo == "OK"){
            song.idifiedInfo = null;
        }
           // song.idifiedSuccess = "false";
           // song.idifiedDate = new Date();
       }
   }
   if(!song.dateAdded){
        song.dateAdded = new Date().getTime();
   }else{
           //make sure that time is in milliseconds
           let dateAdded = song.dateAdded;
           if(dateAdded && ( dateAdded instanceof Date))
           {
            dateAdded = dateAdded.getTime();
           }
           if(dateAdded && ( typeof dateAdded == "string"))
           {
            dateAdded = new Date(dateAdded).getTime();
           }
           if(!dateAdded){
            dateAdded = Date().getTime();
           }
           song.dateAdded =  dateAdded;
       }
       song.dateModified = new Date().getTime();
       if(song.uri.indexOf("/") == 0){
        song.type = "localFile";
       }else{
        song.type = "remote";
       }
       song.generatedReleaseDate = this._getGeneratedReleaseDate(song);
       song.generatedReleaseYear = song.generatedReleaseDate.getFullYear();
       if(markSynced){
        song.synced = "true";
       }else{
        song.synced = "false";
       }
       const albumJsonRef = song.albumJson;
       song.albumJson = null;
       const songCloneUri = song.uri;
       song.uri = null;
       const songClone = JSON.parse(JSON.stringify(song));
       song.albumJson = albumJsonRef;
       songClone.uri  = songCloneUri ;
       song.uri = songCloneUri;
          // songClone.uri = songClone.uri.trim();
          if( song.type == "localFile"){
            this.songArray.push(songClone);

            const albumId =  song.idifiedAlbumId? song.idifiedAlbumId:song.albumId; 
            const albumFound =  this.getAlbumWithAlbumId(albumId);

            if(!albumFound){
                const album = this._buildAlbumFromSong(song);
                albumUpdatedOrCreated = album;
                albumCreated = true;

                const artistJsonRef = album.artistJson;
                album.artistJson = null;
                const albumClone = JSON.parse(JSON.stringify(album));
                album.artistJson = artistJsonRef;
                this.albumArray.push(albumClone);
            }else{
                albumCreated = false;
                albumUpdatedOrCreated = albumFound;
                if(song.doNotUseIdifiedUrl !=  true && song.idifiedInfo ){

                    albumUpdatedOrCreated.albumArtURL = song.idifiedInfo.albumArtUrl;
                    albumUpdatedOrCreated.albumArtImages = song.idifiedInfo.albumArtImages;
                    const t = this.getTransaction(["album"],"readwrite");
                    const albumObjectStore = t.objectStore("album");
                    /*update current album reference if same id as idified album*/
                    if(song.albumJson && (song.albumJson.albumId == albumUpdatedOrCreated.albumId)){
                        song.albumJson.albumArtURL = song.idifiedInfo.albumArtUrl;
                        song.albumJson.albumArtImages = song.idifiedInfo.albumArtImages;
                    }
                    this.albumArray.push(albumUpdatedOrCreated);

                }


            }
            const artistId =  song.idifiedArtistId? song.idifiedArtistId:song.artistId; 
            const artistFound =  this.getArtistWithArtistId(artistId);
            if(!artistFound){
                const artist  = this._buildArtistFromSong(song);
                artistUpdatedOrCreated = artist;
                artistCreated =true;
                this.artistArray.push(artist);

            }else{
                artistCreated = false;
                artistUpdatedOrCreated = artistFound;
                this.artistArray.push(artistUpdatedOrCreated);
            }
            this.trigger("onSongSaved", song, albumUpdatedOrCreated,artistUpdatedOrCreated, silent);
            if(!doNotSync && !this.isSyncingAll()){
                const p = this.syncAllSongs(minimumSyncData);
                if(waitForSyncOperation){
                    return p;
                }
            }
            return RSVP.Promise.resolve();
          }else{
             //not saving remote file anymore since they get saved in the db anyway
             const album = this._buildAlbumFromSong(song);
             albumUpdatedOrCreated = album;
             const artist  = this._buildArtistFromSong(song);
             artistUpdatedOrCreated = artist;
             this.trigger("onSongSaved", song, albumUpdatedOrCreated,artistUpdatedOrCreated, silent);
             if(!doNotSync){
                const p =  TrebbleClientAPI.getInstance().syncSongsArrayWithDB([songClone]);
                if(waitForSyncOperation){
                    return p;
                }
             }
             return RSVP.Promise.resolve();
            }
        },

        syncAllSongs : function(){
            return RSVP.Promise.resolve();
        },

        _addAutoPlaylistArray : function(autoPlaylistArray){
            const promiseArray = [];
            if(autoPlaylistArray){
                for(let index in autoPlaylistArray){
                    const autoPlaylist = autoPlaylistArray[index];
                    this.autoPlaylistArray.push(autoPlaylist);
                }
            }
            return RSVP.Promise.resolve(this.autoPlaylistArray);
        },

        getAllAutoPlaylists : function(){
            return RSVP.Promise.resolve(this.autoPlaylistArray);
        },

        _addTagArray : function(tagInfoArray){
            if(tagInfoArray){
                for(let index in tagInfoArray){
                    const tagInfo = tagInfoArray[index];
                    this.tagInfoArray.push(tagInfo);
                }
            }
            return RSVP.Promise.resolve(this.tagInfoArray);
        },
        getAllTags : function(){
            return RSVP.Promise.resolve(this.tagInfoArray);
        },

        _addGroupTagArray : function(groupTagInfoArray){
            if(groupTagInfoArray){
                for(let index in groupTagInfoArray){
                    const groupTagInfo = groupTagInfoArray[index];
                    this.groupTagInfoArray.push(groupTagInfo);
                }
            }
            return RSVP.Promise.resolve(this.groupTagInfoArray);
        },

        getAllGroupTags : function(){
            return RSVP.Promise.resolve(this.groupTagInfoArray);
        },

        getSongsWithAlbumAndArtistInfoFromSongJsonArray : function(songArray){
            const arrayOfSongsWithAlbumAndArtist = [];
            for(let index in songArray){
                const songJson  = songArray[index];
                arrayOfSongsWithAlbumAndArtist.push(this.getSongWithAlbumAndArtistInfoFromSongJson(songJson));
            }
            return arrayOfSongsWithAlbumAndArtist;
        },

        getSongWithAlbumAndArtistInfoFromSongJson : function(songJsonWithoutAlbumOrArtistInfo){
            const songClone = JSON.parse(JSON.stringify(songJsonWithoutAlbumOrArtistInfo));
            const album = this._buildAlbumFromSong(songClone);
            this._addBidirectionalLinkBetweenSongAndAlbum(songClone, album);
            const artist  = this._buildArtistFromSong(songClone);
            this._addBidirectionalLinkBetweenAlbumAndArtist(album, artist);
            const songJsonWithAlbumOrArtistInfo = songClone;
            return songJsonWithAlbumOrArtistInfo;
        },

        _addBidirectionalLinkBetweenSongAndAlbum :function(song, album){
            song.albumJson = album;
            if(!album.songs){
                album.songs = [];
            }
            album.songs.push(song);
        },

        _addBidirectionalLinkBetweenAlbumAndArtist :function(album, artist){
            album.artistJson = artist;
            if(!artist.albums){
                artist.albums = [];
            }
            artist.albums.push(album);
        },

        _buildArtistFromSong : function(song){
            const idifiedInfo = song.idifiedInfo;
            let artist = null;
            if(idifiedInfo){
                artist = {
                    "artistId" : idifiedInfo.artistId,
                    "artistName" : idifiedInfo.artist
                };

            }else{
                artist = {
                    "artistId" : song.artistId,
                    "artistName" : song.artist
                };
            }
            return artist;
        },



        _buildAlbumFromSong : function(song){
            const idifiedInfo = song.idifiedInfo;
            let album = null;
            if(idifiedInfo){
                album = {
                    "albumId" : idifiedInfo.albumId,
                    "album" : idifiedInfo.album,
                    "artist" : idifiedInfo.artist,
                    "artistId" : idifiedInfo.artistId,
                    "year" : idifiedInfo.year,
                    "idifiedAlbumLinkDataArray" : idifiedInfo.albumLinkData ,
                    "albumArtURL" : idifiedInfo.albumArtUrl,
                    "albumArtImages" : idifiedInfo.albumArtImages,
                    "albumTrackCount" : idifiedInfo.albumTrackCount,
                    "topGenreId" : idifiedInfo.topGenreId,
                    "topGenre" : idifiedInfo.topGenre,
                    "topOriginId" : idifiedInfo.topOriginId,
                    "topOrigin" : idifiedInfo.topOrigin,
                    "topEraId" : idifiedInfo.topEraId,
                    "topEra" : idifiedInfo.topEra,
                    "topMoodId" : idifiedInfo.topModId,
                    "topMood" : idifiedInfo.topMood,
                    "topTempoId" : idifiedInfo.topTempoId,
                    "topTempo" : idifiedInfo.topTempo,
                    "topArtistTypeId" : idifiedInfo.topArtistTypeId,
                    "topArtistType" : idifiedInfo.topArtistType,
                    "genreArray" : idifiedInfo.genreArray,
                    "originArray" : idifiedInfo.originArray,
                    "eraArray" : idifiedInfo.eraArray,
                    "moodArray" : idifiedInfo.moodArray,
                    "tempoArray" : idifiedInfo.tempoArray,
                    "artistTypeArray" : idifiedInfo.artistTypeArray,

                };
            }else{
                album = {
                    "albumId" : song.albumId,
                    "album" : song.album,
                    "artist" : song.artist,
                    "artistId" : song.artistId,
                    "year" : song.year,
                    "albumArtURL" : song.albumArtUrl,
                    "albumArtImages" : song.albumArtImages,

                };
                album.albumArtURL =  song.albumArtUrl;
                album.albumArtImages =  song.albumArtImages;
            }

            return album;
        },

        getSongWithURI : function(songURI, transaction){
            for(let index =0; index< this.songArray; index++){
                const songJson = this.songArray[index];
                if(songJson.uri == songURI){
                    return RSVP.Promise.resolve(songJson);
                }
            }
            return RSVP.Promise.resolve();
        },


        getAlbumWithAlbumId: function(albumId, transaction){
            for(let index =0; index< this.albumArray; index++){
                const albumJson = this.albumArray[index];
                if(albumJson.albumId == albumId){
                    return RSVP.Promise.resolve(albumJson);
                }
            }
            return RSVP.Promise.resolve();

        },

        getArtistWithArtistId: function(artistId, transaction){
            for(let index =0; index< this.artistArray; index++){
                const artistJson = this.artistArray[index];
                if(artistJson.artistId == artistId){
                    return RSVP.Promise.resolve(artistJson);
                }
            }
            return RSVP.Promise.resolve();
        },

        updateSongWithAlbumArtBase64  : function(){
            return RSVP.Promise.resolve();
        },



    });

const trebbleDBHelperSingleton = new TrebbleDBHelper();

export default {
	getInstance : function() {
		return trebbleDBHelperSingleton;
	}
};