import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import ti18n from "i18n!nls/ConfigureBackgroundMusici18n";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import Utils from "models/helper/Utils";
import ChooseMusicContentTemplate from 'text!../../../templates/configureBackgroundMusic/ChooseMusicContentTemplate.html';

const Rollout = RolloutHelper.getInstance();
const SHOW_YOUR_IMPORT_OPTION_VALUE ="showYourImports";
const ChooseMusicWidgetView = Backbone.View.extend({

    initialize : function(options) {
        this.contentViewTemplate = _.template(ChooseMusicContentTemplate);
        this.trebbleAudioListView = options.trebbleAudioListView;
        this._categoryListItemCollectionToDisplay = null;
        this._selectedCategortButton$el = null;              
    },

    setCategoryIdToCategorylabelMapAvailable : function(categoryIdToCategoryLabelMap){
        this.background_music_category_fld$el.html("");
        const categoryLabelsArray = [];
        const categoryLabelToCategoryId = {}
        for(let categoryId in categoryIdToCategoryLabelMap){
            const categoryLabel = categoryIdToCategoryLabelMap[categoryId];
            categoryLabelsArray.push(categoryLabel);
            categoryLabelToCategoryId[categoryLabel]= categoryId;
        }
        categoryLabelsArray.sort();
        for(let i =0; i < categoryLabelsArray.length; i++){
            const categoryLabel = categoryLabelsArray[i];
            const categoryId = categoryLabelToCategoryId[categoryLabel];
            const categoryMusicTagButton$el = this._getHtmlForCategory(categoryId, categoryLabel)
            this.backgroundMusicCategoryList$el.append(categoryMusicTagButton$el)
            this.background_music_category_fld$el.append("<option value='"+categoryId+"'    >"+categoryLabel+"</option>");
        }
    },

    _setCategory$elAsSelected : function(list_tag_button$el){
        if(this._selectedCategortButton$el){
            this._selectedCategortButton$el.removeClass("selected");
        }
        this._selectedCategortButton$el = list_tag_button$el;
        this._selectedCategortButton$el.addClass("selected");

    },

    showFirstCategory : function(){
        this.showSelectedCategory(this.$el.find(".list_tag_button:first-child"));
    },


    showSelectedCategory : function(categoryListTagButton$el ){
     this._setCategory$elAsSelected(categoryListTagButton$el);
 },

 _getHtmlForCategory : function(categoryId, categoryLabel){
    const categor$el = $("<div class='list_tag_button' ><div class='itemLabel'>"+categoryLabel+"</div></div>");
    categor$el.on("click", (function(){
        this.trigger("backgroundMusicCategorySelected", categoryId);
        this.showSelectedCategory(categor$el, false);
    }).bind(this));
    return categor$el;
},


isLoading : function(isLoading){
    this.$el.find(".choose_background_music_popup_content_wrapper").attr("isLoading",isLoading)
},

_onCategorySelectionChangedOnSelectField : function(){
    const categoryId =  this.$el.find("#background_music_category_fld option:selected").val();
    Utils.getInstance().setJQMSelectFieldValue(this.background_music_category_fld$el, categoryId);
    this.trigger("backgroundMusicCategorySelected", categoryId);    
},

afterRendering : function() {
    this.backgroundMusicList$el = this.$el.find(".background_music_list");
    this.backgroundMusicCategoryList$el = this.$el.find(".tag_button_list_wrapper");
    this.backgroundMusicList$el.append(this.trebbleAudioListView.$el);
    this.background_music_category_fld$el = this.$el.find("#background_music_category_fld");
    this.background_music_category_fld$el.on("change", this._onCategorySelectionChangedOnSelectField.bind(this));
},


render : function(){
  const  templateParams = {};
  templateParams.ti18n =  ti18n;
  templateParams.Rollout = Rollout;
  this.$el.html(this.contentViewTemplate(templateParams));
  this.setElement(this.$el.find(".choose_background_music_popup_content_wrapper").first());
  this.afterRendering();
  return this;

},



});

export default ChooseMusicWidgetView;
