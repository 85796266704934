import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlaylistHelper from "models/helper/PlaylistHelper";
import PersistentModels from "services/PersistentModels";
import PlayerService from "services/PlayerService";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";
import ti18n from "i18n!nls/MyLibraryi18n";
import Hammer from 'hammerjs';
import PlaylistDetailsController from 'controllers/common/PlaylistDetailsController';

const RatedPlaylistDetailsController = PlaylistDetailsController.extend({


    initialize : function(options) {
        options.getAllSongsInPlaylistHandler  = this.loadRatedPlaylistDetails.bind(this);
        const eventHandlerMap = {};
        this._detailedPlaylistInfoRetrieved = false;
        eventHandlerMap["click #playAllBtn"] = this.onShuffleButtonTapped.bind(this);
        eventHandlerMap["click div[data-role=header] #editButton"] = this.showEditPage.bind(this);
        eventHandlerMap["click div[data-role=header] #menuOptionButton"] = this.showOptionPopup.bind(this);
        eventHandlerMap["click #addSongsBtn"] = this.showGlobalSearch.bind(this);
        options.eventHandlerMap = eventHandlerMap;
        options.customInfoBoxInfoParams = this._buildCustomInfoBoxParams();
        options.context = Utils.getInstance().buildContextForSongCollectionMyLocalAutoRadio(options.playlistSummary.get("id"));
        return PlaylistDetailsController.prototype.initialize.call(this, options);
    
                    
    },
    
    _buildCustomInfoBoxParams  : function(){
        const params = {};
        params.iconClass = "ion-ios7-musical-notes";
        params.message = window.getI18n(ti18n,"NO_SONG_RATED_IN_THIS_CATEGORY");
        params.helpMessage = window.getI18n(ti18n,"RATE_SONG_IN_YOUR_LIBRARY_OR_YOUR_TREBBLE_AND_IT_WILL_SHOW_UP_HERE");
        return params;
    },
    
    afterPageRendered : function(){
        const editButton$El = this.view.$el.find("#playPreviewBtn");
        const editButtonEl = editButton$El.get(0);
        if(editButtonEl){
            Hammer(editButtonEl, {drag_lock_to_axis: true}).on("press", this.onPreviewButtonPressed.bind(this), false);
            Hammer(editButtonEl, {drag_lock_to_axis: true}).on("pressUp", this.onPreviewButtonPressedUp.bind(this), false);
            //editButton$El.on("touchend",this.onPreviewButtonPressedUp.bind(this));
            //editButton$El.on("mouseup",this.onPreviewButtonPressedUp.bind(this));
            editButton$El.hoverIntent(this.onPreviewButtonPressed.bind(this),this.onPreviewButtonPressedUp.bind(this));
        }
    },
    
    onPreviewButtonPressed : function(){
        this._previewButtonPressed = true;
        this.playPreview();
    },
    
    onPreviewButtonPressedUp : function(){
        if(this._previewButtonPressed){
            this._previewButtonPressed = false;
            this.stopPreview();
        }
    },
    
    showOptionPopup : function(){
        PersistentModels.getInstance().getRouter().showMyPersonalRadioMenuOption(this._playlist, true);
    },
    
    showEditPage : function(){
        PersistentModels.getInstance().getRouter().showTrebbleInfoEditPage(this._playlist, true);
    },
    
    playPreview : function(){
        PersistentModels.getInstance().getPlayer().playAudioPreview(this._playlist.getAudioPreviewUrl());
    },
    
    showGlobalSearch : function(){
        PersistentModels.getInstance().getRouter().showGlobalSearchPage(null, true);
    },
    
    
    onShuffleButtonTapped : function(){
        $.mobile.loading( 'show');
        
        this._retrieveDetailedPlaylistInfo().then((function(){
             PersistentModels.getInstance().getPlayer().setPlaylist(this._playlist, true);
        }).bind(this)).then((function(){
            $.mobile.loading( 'hide');
        }).bind(this)).catch(function(error){
            $.mobile.loading( 'hide');
            console.error("Coudn't load playlist.Error:"+ error);
        window.alertErrorMessage(window.getI18n(ti18n,"COULDNT_LOAD_PLAYLIST")+ error);
        });
    },
    
    _retrieveDetailedPlaylistInfo : function(){
        //if(!this._playlist){
            if(this._playlistRetrievalPendingPromise){
                return this._playlistRetrievalPendingPromise;
            }else{
                const p = PlaylistHelper.getInstance().getMyRadioPlaylistById(this.model.get("id")).then((function(playlist){
                    this._detailedPlaylistInfoRetrieved = true; 
                    this._playlistRetrievalPendingPromise = null;
                    this._playlist = playlist;
                    return this.playlist;
                }).bind(this));
                this._playlistRetrievalPendingPromise =  p;
                return this._playlistRetrievalPendingPromise;
            }
        /*}else{
            return RSVP.Promise.resolve(this._playlist);
        }*/
    },
    
    loadRatedPlaylistDetails : function(){
        return this._retrieveDetailedPlaylistInfo().then((function(){
            if(this._playlist){
                if(this._playlist.load){
                    return this._playlist.load().then((function(){
                        return this._playlist.toJSON();
                    }).bind(this));
                }else{
                    return this._playlist.toJSON();
                }
            }else{
                return [];
            }
            
        }).bind(this)).catch(function(error){
            console.error("Coudn't find playlist with id '", error);
        window.alertErrorMessage(window.getI18n(ti18n,"COULDNT_FIND_PLAYLIST_WITH_ID")+" '"+this._playlist.getLoaderKey() + "'. "+window.getI18n(ti18n,"ERROR")+error);
        });
        
    },

    

});
export default RatedPlaylistDetailsController;