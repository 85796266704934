import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Recorder from "libs/browserRecorder/recorderNew";
import AudioBufferHelper from "models/audioEditor/AudioBufferHelper";
import RSVP from "rsvp";


const TrebbleRecorder =  Backbone.Model.extend({
    

    initialize : function(options) {
        this._useMediaRecorderForAudioRecording = options.useMediaRecorderForAudioRecording;
        this._processor = options.processor;
        this._nodeAlreadyConnected = options.nodeAlreadyConnected;
        this._stream = options.stream;
        window._browserAudioStream = this._stream;
        
        let supportedMimeType = null;
        let mediaRecorderHasSupportForAudioRecording = false;
        if('MediaRecorder' in window){
            
            if(MediaRecorder.isTypeSupported('audio/wav')){
                supportedMimeType = 'audio/wav';
                mediaRecorderHasSupportForAudioRecording=  true;
            }else{
                if(MediaRecorder.isTypeSupported('audio/webm')){
                    supportedMimeType = 'audio/webm';
                    mediaRecorderHasSupportForAudioRecording=  true;
                }else{
                    if(MediaRecorder.isTypeSupported('audio/webm;codecs=opus')){
                        supportedMimeType = 'audio/webm;codecs=opus';
                        mediaRecorderHasSupportForAudioRecording=  true;
                    }else{

                        if(MediaRecorder.isTypeSupported('audio/ogg;codecs=opus')){
                            supportedMimeType = 'audio/ogg;codecs=opus';
                            mediaRecorderHasSupportForAudioRecording=  true;
                        }else{
                            if(MediaRecorder.isTypeSupported('audio/mp4')){
                                supportedMimeType = 'audio/mp4';
                                mediaRecorderHasSupportForAudioRecording=  true;
                            }
                        }
                    }
                }
            }
            
        }
        if(!supportedMimeType){
            supportedMimeType  = 'audio/wav';
        }
        this._supportedMimeType = supportedMimeType;
        if(this._useMediaRecorderForAudioRecording && 'MediaRecorder' in window && mediaRecorderHasSupportForAudioRecording){
            this._mediaRecorder = new MediaRecorder(this._stream, {mimeType : supportedMimeType});
        }else{
            supportedMimeType  = 'audio/wav';
            this._input = window._trebbleAudioContext.createMediaStreamSource(this._stream);
            this._recorderObj = new Recorder(this._input, { 
                "processor": this._processor,
                "numChannels": 1,
                "nodeAlreadyConnected": this._nodeAlreadyConnected
            })
        }
    },

    canInputMonitoringTurnedOn : function(){
        return this._mediaRecorder;
    },

    turnOnInputMonitoring : function(inputMonitoringOn){
        if(inputMonitoringOn && !this._isInputMonitoringOn){
            this._input.connect(window._trebbleAudioContext.destination);
            this._isInputMonitoringOn = true;
        }else{
            if(this._isInputMonitoringOn){
                this._input.disconnect(window._trebbleAudioContext.destination);
                this._isInputMonitoringOn = false;
            }
        }

    },


    record : function(){
        if(this._mediaRecorder){
            this._mediaRecorder.start();
        }else{
            this._recorderObj.record();
        }
    },

    stop : function(){
        if(this._mediaRecorder){
            this._mediaRecorder.pause();
        }else{
            this._recorderObj.stop();
        }
    },

    exportWAV : function(onSuccess){
        if(this._mediaRecorder){
            this._mediaRecorder.ondataavailable = function(event){
                onSuccess(event.data);
            };	
            this._mediaRecorder.requestData();
        }else{
            this._recorderObj.exportWAV(onSuccess);
        }
    },

    getStream : function(){
        return this._stream;
    },
    
    clear : function(){
        if(this._mediaRecorder){
            this._mediaRecorder.stop();
        }else{
            this._recorderObj.clear();
        }
    },



});
export default TrebbleRecorder;