import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import SelectionListItemController from "controllers/selectionList/SelectionListItemController";
import CollectionRepeatController from "controllers/common/CollectionRepeatController";
import SelectionListView from "views/selectionList/SelectionListView";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const SelectionListController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = options.model;
        this._singleSelect  = options.singleSelect;
        this.context = options.context;
        this._propertyFunction = options? options.propertyFunction : null;
        this._propertyName = options? options.propertyName : null;
        this._enableSearch = options? options.enableSearch : null;
        const controllerParams = {};
        controllerParams.propertyFunction = options? options.propertyFunction : null;
        controllerParams.propertyName = options? options.propertyName : null;
        controllerParams.onItemSelected = this.onListItemSelected.bind(this);
        this._onItemSelectedHandler  = (options && options.onItemSelected)?options.onItemSelected: null;
        this._selectedControllers = [];
        const collectionRepeatControllerParams = {};
        collectionRepeatControllerParams.customInfoBoxInfoParams = options && options.infoBoxParams? options.infoBoxParams:  this._buildInfoBoxParams();
        collectionRepeatControllerParams.showEmtptyInfoBox = options && options.showEmtptyInfoBox? options.showEmtptyInfoBox:  false;
        collectionRepeatControllerParams.controllerOption = controllerParams;
        collectionRepeatControllerParams.getModelIdFunction = this._getModelId.bind(this);
        collectionRepeatControllerParams.itemControllerConstructor = SelectionListItemController;
        collectionRepeatControllerParams.resizePlaceHolderElem = true;
        collectionRepeatControllerParams.modelLoadHandler = options.loadHandler;
        if(options.context){
            collectionRepeatControllerParams.context = options.context;
        }
        this.collectionRepeatController = new CollectionRepeatController(collectionRepeatControllerParams);

    },

    _getModelId : function(model){
        if(model){
            return this._propertyFunction?model[this._propertyFunction]():model[this._propertyName ];
        }
        return null;
    },

    onListItemSelected: function(selected, itemController){
        if(this._singleSelect){
            if(this._selectedControllers && this._selectedControllers.length){
                this._selectedControllers[0].setSelected(false, false);
            }
            this._selectedControllers.splice(0, 1);
        }
        if(selected){
            this._selectedControllers.push(itemController);
        }else{
            const index = this._selectedControllers.indexOf(itemController);
            if(index > -1){
                this._selectedControllers.splice(index, 1);
            }
        }
        if(this._onItemSelectedHandler){
            this._onItemSelectedHandler(selected, itemController.getModel());
        }
    },

    setSelected : function(model, selected){
        if(model){
            const itemController = this.collectionRepeatController.getControllerByModelId(this._getModelId(model));
            if(itemController){
                itemController.setSelected(selected, true);
            }
        }
    },

    scrollToTheTop : function(){
        this.collectionRepeatController.scrollToTheTop();
    },

    getSelectedModel: function(){
        const selectedModels = [];
        for(let index in this._selectedControllers){
            const itemController = this._selectedControllers[index];
            selectedModels.push(itemController.getModel());
        }
        if(this._singleSelect){
            if(selectedModels.length > 0){
                return selectedModels[0];
            }else{
                return null;
            }
        }else{
            return selectedModels;
        }
    },



    _buildInfoBoxParams : function(){
        const params = {};

        return params;

    },


    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    onKeyUpOnSearchField : function(keyword){
        this.collectionRepeatController.setFilter(keyword);

    },

    onSearchFieldCleared : function(){
        this.collectionRepeatController.clearFilter();
    },

    focusOnSearchFieldIfApplicable : function(){
        this.view.focusOnSearchFieldIfApplicable();
    },
    
    setSearchFieldValueIfApplicable : function(searchValue, triggerSeaarch){
        this.view.setSearchFieldValueIfApplicable(searchValue, triggerSeaarch);
    },

    onStartedLoading : function(){
        this.trigger("startLoading");
    },

    onFinishLoading : function(){
        this.trigger("finishLoading");
        this.scrollToTheTop();
    },

    buildModelAndRenderView : function() {
        $.mobile.loading("show");
        return new RSVP.Promise((function(resolve, reject) {
            try {
                this.collectionRepeatController.buildModelAndRenderView().then((function(){
                    const selectionListView = new SelectionListView({"listView": this.collectionRepeatController.getView(),"enableSearch": this._enableSearch});
                    this.view = selectionListView;
                    this.listenTo(this.view,"keyUpOnSearchField", this.onKeyUpOnSearchField.bind(this));
                    this.listenTo(this.view,"searchFieldCleared", this.onSearchFieldCleared.bind(this));
                    this.listenTo(this.collectionRepeatController,"startLoading", this.onStartedLoading.bind(this));
                    this.listenTo(this.collectionRepeatController,"finishLoading", this.onFinishLoading.bind(this));
                    return this.view.render();
                }).bind(this)).then((function(){
                    resolve(this);
                    $.mobile.loading("hide");
                }).bind(this)).catch((function(error){
                    $.mobile.loading("hide");
                    reject(error);
                }).bind(this));

            } catch (error) {
                $.mobile.loading("hide");
                reject(error);
            }

        }).bind(this));

    }

});

export default SelectionListController;