import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import CordovaHelper from "models/helper/CordovaHelper";
import SingleSongSelectorInputView from "views/common/SingleSongSelectorInputView";
import Utils from "models/helper/Utils";
import PlayerService from "services/PlayerService";
import ListItemCollection from "collections/ListItemCollection";
import SongSelectorPopupController from "controllers/common/SongSelectorPopupController";
import CustomListSelectorPopupController from "controllers/common/CustomListSelectorPopupController";
import SongContextController from "controllers/common/SongContextController";
import ti18n from "i18n!nls/Commoni18n";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";

const SingleSongSelectorInputController = Chaplin.Controller.extend({


    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this._songCollectionToChooseFrom =  options.songCollectionToChooseFrom;
        this._labelToSongCollectionToChooseFrom = options.labelToSongCollectionToChooseFrom;
        this._selectionSongPopupTitle = options.selectionSongPopupTitle? options.selectionSongPopupTitle: window.getI18n(ti18n,"CHOOSE_A_SONG");
        this._helperMessageInSongSelectionPopup = options.infoBoxMessageInSongSelectionPopupOnEmptySearchField? options.infoBoxMessageInSongSelectionPopupOnEmptySearchField: "Choose a song";
        this._helperinfoBoxMessageInSongSelectionPopupOnEmptySearchField = options.helperinfoBoxMessageInSongSelectionPopupOnEmptySearchField? options.helperinfoBoxMessageInSongSelectionPopupOnEmptySearchField: "";
        this._sourceLabelToInfoBoxMessageInSongSelectionPopupOnEmptySearchField = options.sourceLabelToInfoBoxMessageInSongSelectionPopupOnEmptySearchField;
        this._sourceLabelToInfoBoxHelplerMessageInSongSelectionPopupOnEmptySearchField = options.sourceLabelToInfoBoxHelplerMessageInSongSelectionPopupOnEmptySearchField;
        this._songWithAudioPreviewOnly =  options.songWithAudioPreviewOnly ? options.songWithAudioPreviewOnly : false;
        this._noAudioSelectedCustomLabel = options.noAudioSelectedCustomLabel?options.noAudioSelectedCustomLabel:"";
        this._chooseAudioCustomLabel = options.chooseAudioCustomLabel?options.chooseAudioCustomLabel:"";
        this._removeAudioCustomLabel = options.removeAudioCustomLabel?options.removeAudioCustomLabel:"";
    },
    
    getModel : function(){
        return this.model;
    },
    
    
    
    getView : function(){
        return this.view;
    },

    getSelectedSongModel : function(){
        return this._selectedSongModel;
    },

    setSelectedSongModel : function(songModel){
        this._onSelectionChanged(songModel);
    },
    

    _onSelectionChanged : function(songSelection){
        if(songSelection){
            const params = {};
            params.model = songSelection;
            const songContextController = new SongContextController(params);
            this._songContextController  = songContextController;
            this._songContextController.buildModelAndRenderView().then((function(){
                this.view.setSelectedSongView(this._songContextController.getView());
            }).bind(this));
        }else{
            this.view.setSelectedSongView(null);
        }
        this._selectedSongModel = songSelection;
    },

    _onDeleteCurrentSelectedSong : function(){
        this._onSelectionChanged(null);
    },

    _showSongPickerPopupForSingleSource : function(){
        if(!this._isSongSelectorPopupOpen){
            const params = {};
            params.songCollection =  this._songCollectionToChooseFrom;
            params.selectedFilterOption = null;
            params.singleSelect = true;
            params.title = this._selectionSongPopupTitle;
            params.infoBoxMessageOnFieldEmpty = this._helperMessageInSongSelectionPopup;
            params.infoBoxHelperMessageOnFieldEmpty =  this._helperinfoBoxMessageInSongSelectionPopupOnEmptySearchField;
            const onSelectorPopupClose = (function(selectedSong){
                this._isSongSelectorPopupOpen = false;
                if(selectedSong){
                    this._onSelectionChanged(selectedSong);
                }
            }).bind(this);
            params.onPopupClose = onSelectorPopupClose;
            params.noSongSelectionOptionAllowed = false;
            const songSelectorPopupController = new SongSelectorPopupController(params);
            songSelectorPopupController.buildModelAndRenderView();
            this._isSongSelectorPopupOpen = true;
        }

    },

    _showSongPickerPopup : function(){
        if(this._labelToSongCollectionToChooseFrom){
            this._showSongPickerPopupForMultipleSources();
        }else{
            this._showSongPickerPopupForSingleSource();
        }
    },

    _getAllAvailableSongSourceListItemCollection: function(){
        const sourceListItemCollection = new ListItemCollection();
        for(let label in this._labelToSongCollectionToChooseFrom){
            sourceListItemCollection.add(Utils.getInstance().getListItemModel(label, label));
        }
        return RSVP.Promise.resolve(sourceListItemCollection.models);
    },

    _getSelectedSourceLabel : function(){
        const controllerParams = {};
        controllerParams.singleSelect =  true;
        controllerParams.propertyFunction = "getLabel";
        controllerParams.showEmtptyInfoBox  = false;
        controllerParams.maxWidth = 600;
        controllerParams.listTitleToDisplay =  window.getI18n(ti18n,"SEARCH_SONG_ON");
        controllerParams.sourceElement$el  = null;
        controllerParams.loadHandler = this._getAllAvailableSongSourceListItemCollection.bind(this);
        const songSourceSelectorPopupController = new CustomListSelectorPopupController(controllerParams);
        const promiseResult = songSourceSelectorPopupController.getResultPromise();
        songSourceSelectorPopupController.buildModelAndRenderView();
        return promiseResult;
    },

    _getInfoBoxMessageForEmptySearchBoxForSource : function( sourceLabel){
        return this._sourceLabelToInfoBoxMessageInSongSelectionPopupOnEmptySearchField? this._sourceLabelToInfoBoxMessageInSongSelectionPopupOnEmptySearchField[sourceLabel]: "Search A Song";
    },

    _getHelperInfoBoxMessageForEmptySearchBoxForSource : function( sourceLabel){
        return this._sourceLabelToInfoBoxHelplerMessageInSongSelectionPopupOnEmptySearchField? this._sourceLabelToInfoBoxHelplerMessageInSongSelectionPopupOnEmptySearchField[sourceLabel]: "";
    },

    _showSongPickerPopupForMultipleSources : function(){
        if(!this._isSongSelectorPopupOpen){
            let sourceLabel =  null;
            return this._getSelectedSourceLabel().then((function(sourceListItem){
                if(sourceListItem){
                    sourceLabel = sourceListItem.getLabel();
                    return this._labelToSongCollectionToChooseFrom[sourceListItem.getLabel()];

                }
            }).bind(this)).then((function(songCollectionToChooseFrom){
                if(sourceLabel){
                    const params = {};
                    params.songCollection =  songCollectionToChooseFrom;
                    params.selectedFilterOption = null;
                    params.singleSelect = true;
                    params.title = this._selectionSongPopupTitle;
                    params.infoBoxMessageOnFieldEmpty = this._getInfoBoxMessageForEmptySearchBoxForSource(sourceLabel);
                    params.infoBoxHelperMessageOnFieldEmpty =  this._getHelperInfoBoxMessageForEmptySearchBoxForSource(sourceLabel);
                    const onSelectorPopupClose = (function(selectedSong){
                        this._isSongSelectorPopupOpen = false;
                        if(selectedSong){
                            this._onSelectionChanged(selectedSong);
                        }
                    }).bind(this);
                    params.onPopupClose = onSelectorPopupClose;
                    params.noSongSelectionOptionAllowed = false;
                    params.songWithAudioPreviewOnly = this._songWithAudioPreviewOnly;
                    const songSelectorPopupController = new SongSelectorPopupController(params);
                    songSelectorPopupController.buildModelAndRenderView();
                    this._isSongSelectorPopupOpen = true;
                }

            }).bind(this));

}

},

buildModelAndRenderView : function() {
return new RSVP.Promise((function(resolve,reject){
try{
    
    const view = new SingleSongSelectorInputView({"noAudioSelectedCustomLabel": this._noAudioSelectedCustomLabel,"chooseAudioCustomLabel": this._chooseAudioCustomLabel,"removeAudioCustomLabel": this._removeAudioCustomLabel});
    view.render();
    this.view = view;
    this.listenTo(this.view ,"deleteCurrentSelectedSong", this._onDeleteCurrentSelectedSong.bind(this));
    this.listenTo(this.view , "pickSong", this._showSongPickerPopup.bind(this));
    this._onSelectionChanged(this._selectedSongModel);
    resolve();
    
    
}catch(error){
    reject(error);
}

}).bind(this));


}

});

export default SingleSongSelectorInputController;