		import $ from 'jquery';
        import _ from 'underscore';
        import Backbone from "backbone";
        import MixpanelJS from "mixpanel";
        import LocalStorageHelper from "models/helper/LocalStorageHelper";
        import PersistentModels from "services/PersistentModels";
        import FeatureRolloutHelper from "models/helper/FeatureRolloutHelper";
        import SubscriptionManager from "models/helper/SubscriptionManager";
        import RSVP from "rsvp";

        const BANNER_AD_ID = "";
        const INTERSTITIAL_AD_ID_ANDROID = "ca-app-pub-3327435010002282/5574046689";
        const INTERSTITIAL_AD_ID_ANDROID_NO_VIDEO ="ca-app-pub-3327435010002282/5675007083";
        const INTERSTITIAL_AD_ID_IOS = "ca-app-pub-3327435010002282/5304429858";
        const INTERSTITIAL_AD_ID_IOS_NO_VIDEO = "ca-app-pub-3327435010002282/2398300579";
        const TAPPX_ID_IOS = "pub-56913-ios-7050";
        const TAPPX_ID_ANDROID = "pub-56906-android-5486";

        const FACEBOOK_IOS_INTERSTITIAL_AD_ID_IOS = "444889046854582_444896156853871";
        const FACEBOOK_IOS_INTERSTITIAL_AD_ID_ANDROID =  "444889046854582_444898203520333";


        const TAPPX_SHARE = "";
        const Rollout = FeatureRolloutHelper.getInstance();
        const MIN_TIME_TO_WAIT_BEFORE_SHOWING_ADS = Rollout.getFeatureVariable(Rollout.FEATURES.SHOW_INTERSTITIAL_ADS, Rollout.FEATURES.SHOW_INTERSTITIAL_ADS.variables.min_time_in_millsec_to_wait_before_showing_ads, 5000*60);
        const USE_FACEBOOK_ADS = Rollout.getFeatureVariable(Rollout.FEATURES.SHOW_INTERSTITIAL_ADS, Rollout.FEATURES.SHOW_INTERSTITIAL_ADS.variables.use_faecbook_ads);

        const adIds = {
            ios: {
                interstitialAdId: INTERSTITIAL_AD_ID_IOS,
                interstitialAdNoVideoId: INTERSTITIAL_AD_ID_IOS_NO_VIDEO,
            },
            android: {
                interstitialAdId: INTERSTITIAL_AD_ID_ANDROID,
                interstitialAdNoVideoId: INTERSTITIAL_AD_ID_ANDROID_NO_VIDEO,
            },
            web: {
                publisherId: 'ca-app-pub-XXXXXXXXXXXXXXXX', // AdSense
                adSlot: 'BBBBBBBBBB', // AdSense
            }
        };

        const AdHelper = Backbone.Model.extend({
            initialize : function(options) {
                this._initialized = false;
                this._lastTimeAdWasDisplayed =  0;

            },

            init : function(){
                let admobIds;
                try{
                    if(window.waitForCordovaToLoad){
                        if("admob" in window){
                            this._initialized =  true;
                        }
                    }else{

                    }
                }catch(e){
                    console.error("Failed initializing ad mob. Error:"+e);
                }
                return RSVP.Promise.resolve();
            },

            _registerAdEvents : function(){
                if("admob" in window){
                    document.addEventListener("admob.interstitial.load", this._onAdLoaded.bind(this));
                    document.addEventListener("admob.interstitial.loadFail", this._onAdFailedToLoad.bind(this));
                    document.addEventListener("admob.interstitial.show", this._onAdOpened.bind(this));
                    document.addEventListener("admob.interstitial.showFail", this._onAdFailedToShow.bind(this));
                    document.addEventListener("admob.interstitial.dismiss", this._onAdClosed.bind(this));
                }
            },

            _onAdLoaded : function(e){
                console.log("Admob ad loaded");
            },

            _onAdFailedToLoad : function(e){
                console.log("Admob ad failed to load");
            },


            _onAdFailedToShow : function(e){
                console.log("Admob ad failed to show");
            },

            _onAdOpened : function(e){
                console.log("Admob ad opened");
            },

            _onAdClosed : function(e){
                console.log("Admob Ad closed");
            },

            doesUserHasAdFreeSubscription : function(){
                return SubscriptionManager.getInstance().isCurrentUserSubscribedToTrebblePlus();
            },

            _loadFacebookAds: function(){
                if("cordova" in window && window.cordova.plugins &&  window.cordova.plugins.codeplayfacebookads){
                    return  new RSVP.Promise((function(resolve, reject){
                        const options={
                            interstitialid:window.isIOSMobileDevice? FACEBOOK_IOS_INTERSTITIAL_AD_ID_IOS: FACEBOOK_IOS_INTERSTITIAL_AD_ID_ANDROID,
                            isTesting:true
                        };

                        window.cordova.plugins.codeplayfacebookads.loadInterstitialAds(options,events,fail);

                        function events(event)
                        {
                            if(event === "AdLoaded"){
                                console.log("Facebook AdLoaded");
                                if(window.trebbleAnalyticsHelper){
                                    window.trebbleAnalyticsHelper.trackEvent("adTracking", 'adLoadedAndAboutToBeDisplayed', 'Ad loaded about to be displayed');
                                }
                                resolve();
                            }
                            else if(event === "AdClosed"){
                                console.log("Facebook AdClosed");
                                if(window.trebbleAnalyticsHelper){
                                    window.trebbleAnalyticsHelper.trackEvent("adTracking", 'adClosed', 'Ad closed');
                                }
                            }
                            else if(event === "AdDisplayed"){
                                console.log("Facebook AdDisplayed");
                                if(window.trebbleAnalyticsHelper){
                                    window.trebbleAnalyticsHelper.trackEvent("adTracking", 'adDisplayed', 'Ad displayed');
                                }
                            }
                            else if(event === "AdClicked"){
                                console.log("Facebook AdClicked");
                                if(window.trebbleAnalyticsHelper){
                                    window.trebbleAnalyticsHelper.trackEvent("adTracking", 'adClicked', 'Ad clicked');
                                }
                            }
                            else if(event === "AdLogged"){
                                console.log("Facebook AdLogged");
                            }
                        }
                        function fail(result)
                        {
                            console.error(result);
                            reject(result);
                        }

                    }).bind(this))
}else{
return RSVP.Promsie.resolve();
}
},

_showFacebookAds : function(){
if("cordova" in window && window.cordova.plugins &&  window.cordova.plugins.codeplayfacebookads){
return  new RSVP.Promise((function(resolve, reject){
    window.cordova.plugins.codeplayfacebookads.showInterstitialAds("",success,fail);

    function success(result)
    {
        console.log(result);
        resolve()
    }
    function fail(result)
    {
        console.error(result);
        reject(result)
    }

}).bind(this))
}else{
return RSVP.Promsie.resolve();
}
},

_loadAndShowFacebookAds : function(showVideo, muteAudio){
return this._loadFacebookAds().then((function(){
this._showFacebookAds();
}).bind(this))
},

_showAdMobAdsWithAdMobFreePlugin : function(showVideo, muteAudio){
if("admob" in window ){
window.admob.interstitial.config({
    autoShow: true,
    id: window.isIOSMobileDevice? (showVideo?adIds.ios.interstitialAdId:adIds.ios.interstitialAdNoVideoId): (showVideo?adIds.android.interstitialAdId:adIds.android.interstitialAdNoVideoId),
})

window.admob.interstitial.prepare();
this._lastTimeAdWasDisplayed = new Date().getTime();
window.log("Ad should be displayed");
//admob.interstitial.show();
return RSVP.Promsie.resolve();
}else{
return RSVP.Promsie.resolve();
}

},


_showAdMobAds : function(showVideo, muteAudio){
if("admob" in window ){
let interstitial = null;
this._lastTimeAdWasDisplayed = new Date().getTime();
if(muteAudio){
    window.admob.setAppMuted(true);
}
return window.admob.start().then((function(){
    interstitial = new window.admob.InterstitialAd({
        adUnitId: window.isIOSMobileDevice? (showVideo?adIds.ios.interstitialAdId:adIds.ios.interstitialAdNoVideoId): (showVideo?adIds.android.interstitialAdId:adIds.android.interstitialAdNoVideoId),
    });
    window.log("Ad should be about to be loaded");
    if(window.trebbleAnalyticsHelper){
        window.trebbleAnalyticsHelper.trackEvent("adTracking", 'adAboutToBeLoaded', 'Ad about to be loaded');
    }
    return interstitial.load();
}).bind(this)).then((function(){
    if(interstitial){
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("adTracking", 'adLoadedAndAboutToBeDisplayed', 'Ad loaded about to be displayed');
        }
        window.log("Ad should be about to be displayed");
        interstitial.show();
    }
}).bind(this)).then((function(){
    window.log("Ad should be displayed");
    if(window.trebbleAnalyticsHelper){
        window.trebbleAnalyticsHelper.trackEvent("adTracking", 'adDisplayed', 'Ad displayed');
    }
}).bind(this)).catch((function(error){
    window.log("Error displaying admob ads. Error:"+error);
    if(window.trebbleAnalyticsHelper){
        window.trebbleAnalyticsHelper.trackEvent("adTracking", 'errorOccuredDisplayedAd', 'Error occured displaying ad', null);
    }
}).bind(this))
//admob.interstitial.show();
}else{
return RSVP.Promsie.resolve();
}

},

_showInterstitialAds : function(showVideo, muteAudio){
if(USE_FACEBOOK_ADS){
return this._loadAndShowFacebookAds(showVideo, muteAudio);
}else{
return this._showAdMobAds(showVideo, muteAudio);
}
},

showInterstitialAd : function(showVideo, muteAudio){
try{
if ("admob" in window && this._initialized && ((new Date().getTime()) - this._lastTimeAdWasDisplayed) > MIN_TIME_TO_WAIT_BEFORE_SHOWING_ADS) {
    return this.doesUserHasAdFreeSubscription().then((function(adFreeUserSubscription){
        if(!adFreeUserSubscription && !LocalStorageHelper.getInstance().hasTrebblePlusFreeTrial()){
            if(SubscriptionManager.getInstance().isOrderInProgress() || (PersistentModels.getInstance().getRouter() && PersistentModels.getInstance().getRouter().isUpgradeToPaidSubscriptionPopupOpen())){
                window.log("Order is in progress so don't display an ad");
            }else{
                this._showInterstitialAds(showVideo, muteAudio);
            }
        }else{
            window.log("User is premium so no ad will be displayed or has free trial");
        }
    }).bind(this)).catch((function(e){
        console.error("Error displaying an insterstitial ad. Error:"+e);
    }).bind(this))

}
}catch(e){
console.error("Error displaying an insterstitial ad. Error:"+e);
}
return RSVP.Promise.resolve(); 
},



});

        const AdHelperInstance = new AdHelper();

        export default {
			getInstance : function() {
				return AdHelperInstance;
			}
		};