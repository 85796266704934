import Backbone from "backbone";


const Comment =  Backbone.Model.extend({
     idAttribute: "commentId",

     getCommentId : function(){
         return this.get("commentId");
     },

     
     getText : function(){
         return this.get("text");
     },

     
     getCreationDate : function(){
         return new Date(this.get("createdDate"));
     },
         
     getUrlPreviews : function(){

     },


     getCanBeEdited : function(){
         return this.get("canBeEdited");
     },
    
     
     isConsumed : function(){
         return this.get("isConsumed");
     },
     
     isNotified : function(){
         return this.get("isNotified");
     },

    getCreatorInfoJson : function(){
        return this.get("creatorInfoJson");
    },

    getSongInfoJson : function(){
        return this.get("songInfoJson");
    },
    
    getNumberOfLikes : function(){
        let numberOfThumbsUp = this.get("numberOfThumbsUp");
        if(!numberOfThumbsUp){
            numberOfThumbsUp = 0;
        }
        return numberOfThumbsUp;
    },
    
    hasThumbsUp : function(){
        return this.get("hasThumbsUp");
        
    },
    
    setThumbsUp : function(hasThumbsUp){
        if(hasThumbsUp){
            let numberOfThumbsUp = this.get("numberOfThumbsUp");
            if(!numberOfThumbsUp){
                numberOfThumbsUp = 0;
            }
            numberOfThumbsUp = numberOfThumbsUp  + 1;
            this.set("numberOfThumbsUp",numberOfThumbsUp);
        }else{
            let numberOfThumbsUp = this.get("numberOfThumbsUp");
            if(!numberOfThumbsUp){
                numberOfThumbsUp = 0;
            }else{
                numberOfThumbsUp = numberOfThumbsUp  - 1;
            }
            this.set("numberOfThumbsUp",numberOfThumbsUp);
        }
    },

    isAReply : function(){
        return this.get("isAReply");
    },
    
    getNumberOfReplies : function(){
        return this.get("numberOfReplies");
    },

    getContext : function(){
        return this.get("_sourceContext");
    },
    
});
export default Comment;