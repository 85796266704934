

import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerOperation from "models/audioEditor/sequencerOperation/SequencerOperation";
import AudioSequencerNode from "models/audioEditor/sequencerNodes/AudioSequencerNode";
import AudioBufferHelper from "models/audioEditor/AudioBufferHelper";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const InsertAudioNodeOperation =  SequencerOperation.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperation.apply(this, [attributes, options]);
    },

    execute : function(trebbleAudio, sequencerNodeToInsertNextTo, insertBefore, introPaddingInMilliseconds, outroPaddingInMilliseconds, introFadeInMilliseconds,outroFadeInMilliseconds, volumePercentage, silent){
        const audioSequencerNode = new AudioSequencerNode({"audioSegment":Utils.getInstance().getCloudAudioSegment(trebbleAudio.getAudioUrl()), "sequencer": this.getSequencer()})
        audioSequencerNode.setAudio(trebbleAudio);
        audioSequencerNode.setIntroFadeInMilliseconds(introFadeInMilliseconds);
        audioSequencerNode.setOutroFadeInMilliseconds(outroFadeInMilliseconds);
        audioSequencerNode.setIntroPaddingInMilliseconds(introPaddingInMilliseconds);
        audioSequencerNode.setOutroPaddingInMilliseconds(outroPaddingInMilliseconds);
        audioSequencerNode.setMainVolumePercentage(volumePercentage);
        this.insertAudioNode(audioSequencerNode, sequencerNodeToInsertNextTo, insertBefore);
        this.getSequencer().getAudioBufferCache().loadAudioBufferFromUrl(trebbleAudio.getAudioUrl(), trebbleAudio.getAudioUrl(), AudioBufferHelper.getInstance().getAudioTypes().INSERT, this.getSequencer().getSequencerSettings());
        if(!silent){
            this.getSequencer().getHistoryManagement().addCreateInsertAudioNodeLog(audioSequencerNode,sequencerNodeToInsertNextTo , insertBefore);
        }
        return audioSequencerNode;
    },

    insertAudioNode : function(audioSequencerNodeToInsert, sequencerNodeToInsertNextTo, insertBefore ){
        if(sequencerNodeToInsertNextTo){
            if(insertBefore){
                audioSequencerNodeToInsert.insertBefore(sequencerNodeToInsertNextTo);
                if(sequencerNodeToInsertNextTo == this.getSequencer().getFirstNode()){
                    this.getSequencer().setFirstNode(audioSequencerNodeToInsert);
                }
            }else{
                audioSequencerNodeToInsert.insertAfter(sequencerNodeToInsertNextTo);
                if(sequencerNodeToInsertNextTo == this.getSequencer().getLastNode()){
                    this.getSequencer().setLastNode(audioSequencerNodeToInsert);
                }
            }
        }else{
            //this is to handle the unique case where the sequencer is empty
            audioSequencerNodeToInsert.addToSequencer();
            this.getSequencer().setFirstNode(audioSequencerNodeToInsert);
            this.getSequencer().setLastNode(audioSequencerNodeToInsert);
        }
    },

    removeAudioNode : function(audioSequencerNode){
        const nextNode =  audioSequencerNode.getNext();
        const previousNode =  audioSequencerNode.getPrevious();

        audioSequencerNode.clearNext();
        if(previousNode){
            previousNode.clearNext();
        }
        if(previousNode && nextNode){
            previousNode.setNext(nextNode);
        }
        if(audioSequencerNode == this.getSequencer().getFirstNode()){
            this.getSequencer().setFirstNode(nextNode);
        }

        if(audioSequencerNode == this.getSequencer().getLastNode()){
            this.getSequencer().setLastNode(previousNode);
        }

        audioSequencerNode.removeFromSequencer();
    },

    undo: function(insertAudioNodeLog){
        const audioSequencerNode = insertAudioNodeLog.getAudioSequencerNode();
        this.removeAudioNode(audioSequencerNode);
    },

    redo: function(insertAudioNodeLog){
        this.insertAudioNode(insertAudioNodeLog.getAudioSequencerNode(), insertAudioNodeLog.getSequencerNodeToInsertNextTo(), insertAudioNodeLog.getInsertBefore());
    },





});

export default InsertAudioNodeOperation; 