import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import BasicSequencerNodeInspectorController from "controllers/audioEditor/sequencerNodeInspectorSection/BasicSequencerNodeInspectorController";
import WordSequencerNodeInspectorView from "views/audioEditor/sequencerNodeInspectorSection/WordSequencerNodeInspectorView";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const WordSequencerNodeInspectorController = BasicSequencerNodeInspectorController.extend({


    listenToEventsOnView: function(){
        this.listenTo(this.view, "audioSegmentChanged", (function(newAudioSegmentJson){
            this._sequencer.updateSequencerNodeProperty(this.getModel(), "setAudioSegmentJson", "getAudioSegmentJson", newAudioSegmentJson);
        }).bind(this));
    },


    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                const params = {};
                params.sequencerNode = this.getModel();
                this.view = new WordSequencerNodeInspectorView(params);
                this.view.render();
                this.listenToEventsOnView();
                resolve();

            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default WordSequencerNodeInspectorController;