import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import Levenshtein from "libs/levenshtein/levenshtein";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import TrebbleAudio from "models/TrebbleAudio";
import ti18n from "i18n!nls/helperi18n";
import RSVP from "rsvp";


const FREESOUND_ID_PREFIX = "bigBankSound:";
const NUMBER_OF_RESULTS_PER_PAGE = 10;
const BigBankSoundSearchHelper =  Backbone.Model.extend({

    initialize : function() {

    },

    getNumberOfResultsPerPage : function(){
        return NUMBER_OF_RESULTS_PER_PAGE;
    },

    searchForSounds : function(query, pageIndex){
        const params = {};
        params.name = query;
        params.format = "api";
        params.page = pageIndex + 1;
        return TrebbleClientAPIHelper.getInstance().searchForBigBankSounds(query, this.getNumberOfResultsPerPage(), pageIndex).then((function(results){
            const trebbleAudioArray = [];
            if(results && results.length > 0){
                for(let i =0; i < results.length; i++){
                    trebbleAudioArray.push(new TrebbleAudio(results[i]));
                }
            }
            return trebbleAudioArray;
        }).bind(this))
    },


});
const bigBankSoundSearchHelperInstance = new BigBankSoundSearchHelper();

export default {
    getInstance : function() {
        return bigBankSoundSearchHelperInstance;
    }
};
