	import $ from 'jquery';
    import _ from 'underscore';
    import Backbone from "backbone";
    import LocalStorageHelper from "models/helper/LocalStorageHelper";
    import FeatureRolloutHelper from "models/helper/FeatureRolloutHelper";
    import IntercomHelper from "models/helper/IntercomHelper";
    import ZendeskHelper from "models/helper/ZendeskHelper";
    import TawkToHelper from "models/helper/TawkToHelper";
    import RSVP from "rsvp";
    const Rollout = FeatureRolloutHelper.getInstance();
    const SHOULD_USE_ZENDESK = Rollout.isFeatureEnabled(Rollout.FEATURES.USE_ZENDESK_FOR_LIVE_CHAT, false);
    const _chatWidgetHelper = SHOULD_USE_ZENDESK? ZendeskHelper.getInstance() :IntercomHelper.getInstance();
    //_chatWidgetHelper = TawkToHelper.getInstance();

    const LiveChatWidgetHelper = Backbone.Model.extend({
        initialize : function(options) {

        },


        isAvailable : function(){
            return _chatWidgetHelper.isAvailable();
        },
        
        isUnreadConversationCountAvailable :function(){
            return _chatWidgetHelper.isUnreadConversationCountAvailable();
        },

        getUnreadConversationCount: function(){
            return _chatWidgetHelper.getUnreadConversationCount();
        },

        
        registerIdentifiedUser : function(userId, userEmail, firstName, lastName, profileImageUrl, creationDate, allUserFeatures){
            return _chatWidgetHelper.registerIdentifiedUser(userId, userEmail, firstName, lastName, profileImageUrl, creationDate, allUserFeatures);
        },

        registerForPush : function(){
            return _chatWidgetHelper.registerForPush();
        },

        updateUser : function(userEmail, firstName, lastName, profileImageUrl, creationDate, allUserFeatures){
            return _chatWidgetHelper.updateUser(userEmail, firstName, lastName, profileImageUrl, creationDate, allUserFeatures);
        },

        updateUserAttributes :function(userAttributeUpdates){
            return _chatWidgetHelper.updateUserAttributes(userAttributeUpdates);
        },

        

        trackEvent : function(eventName, additionalParams){
            return _chatWidgetHelper.trackEvent(eventName, additionalParams);
        },

        displayMessenger :function(){
            return _chatWidgetHelper.displayMessenger();
        },

        displayMessageComposer: function() {
            return _chatWidgetHelper.displayMessageComposer();
        },

        displayMessageComposerWithInitialMessage: function(initialMessage) {
            return _chatWidgetHelper.displayMessageComposerWithInitialMessage(initialMessage);
        },

        displayMobileCarousel: function(carouselId) {
            return _chatWidgetHelper.displayMobileCarousel(carouselId);
        },


        hideMessenger :function(){
            return _chatWidgetHelper.hideMessenger();
        },

        logout : function(){
            return _chatWidgetHelper.logout();
        }



    });

    const LiveChatWidgetHelperInstance = new LiveChatWidgetHelper();
    window.trebbleLiveChatWidgetHelper = LiveChatWidgetHelperInstance;

    export default {
		getInstance : function() {
			return LiveChatWidgetHelperInstance;
		}
	};