import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import OptionPopupView from "views/common/OptionPopupView";
import ti18n from "i18n!nls/AutoPlayTrebbleAfterSharedCapsulePopupi18n";
import AutoPlaySuggestionContentTemplate from 'text!../../../templates/autoPlaySuggestion/AutoPlaySuggestionContentTemplate.html';

const AutoPlaySuggestionPopupView = OptionPopupView.extend({

    "pageName": "Auto Play Suggestion",

    initialize: function(options) {
        const viewOptions = {};
        this._contentUserAvatarUrl = options.contentUserAvatarUrl;
        this._contentUserOwnerUsername = options.contentUserOwnerUsername;
        this._entityNameShared = options.entityNameShared;
        this._autoplayTimeInSeconds = options.autoplayTimeInSeconds;
        this._toPlayNextTitle = options.toPlayNextTitle;
        this._contentIdToAutoPlay =  options.contentIdToAutoPlay;
        this._disableAutoplay = options.disableAutoplay;
        const contentView = {};
        const contentViewTemplate = _.template(AutoPlaySuggestionContentTemplate);
        const templateParams = options;
        templateParams.ti18n = ti18n;
        contentView.$el = $(contentViewTemplate(templateParams));
        viewOptions.contentView = contentView;
        viewOptions.contentPosition = "center";
        viewOptions.id = "AutoPlayTrebbleSuggestion";
        this._currentTimerValue =  this._autoplayTimeInSeconds ;


        OptionPopupView.prototype.initialize.call(this, viewOptions);
    },

    _initializeAutoplayTimer: function() {
        if(!this._disableAutoplay){
            const incrementIntervallInMillisec = 1000;
            this._timerFunctionKey =  setInterval((function(){
                if(this._currentTimerValue <= 0){
                    this._disableTimer();
                    this._onPlaySuggestedContent();

                }else{
                    this._currentTimerValue =this._currentTimerValue - incrementIntervallInMillisec/1000;

                }
                this.secondCounter$el.html(this._currentTimerValue );
            }).bind(this), incrementIntervallInMillisec)
        }else{
            this.$el.find("#upNextContentInfo").hide();
        }
    },

    _disableTimer : function(){
        if(this._timerFunctionKey){
         clearInterval(this._timerFunctionKey);
     }
     this.$el.find("#cancelAutoPlayBtn").css({"visibility": "hidden"});
     this.$el.find("#upNextContentInfo > .message").css({"visibility": "hidden"});
 },

 _onShareFinishedPlayedContentOnFacebook : function(){
    this.trigger("shareFinishedPlayedContentOnFacebook");
},

_onShareFinishedPlayedContentOnTwitter : function(){
    this.trigger("shareFinishedPlayedContentOnTwitter");
},

_onReplyToFinishedPlayedContent : function(){
    this.trigger("replyToFinishedPlayedContent");
},

_onSubscribeToFinishedPlayedOwnerContent : function(){
    this.trigger("subscribeToFinishedPlayedOwnerContent");
},

_onPlaySuggestedContent : function(){
    this.trigger("playSuggestedContent");
},

cancelAutoPlay : function(){
    //this.trigger("playSuggestedContent");
    this._disableTimer();
},

afterRendering: function() {
    this.$el.find("#shareOnFacebookBtn").click(this._onShareFinishedPlayedContentOnFacebook.bind(this));
    this.$el.find("#shareOnTwitterBtn").click(this._onShareFinishedPlayedContentOnTwitter.bind(this));
    this.$el.find("#replyBtn").click(this._onReplyToFinishedPlayedContent.bind(this));
    this.$el.find("#subscribeBtn").click(this._onSubscribeToFinishedPlayedOwnerContent.bind(this));
    this.$el.find(".toPlayNext").click(this._onPlaySuggestedContent.bind(this));
    this.$el.find("#cancelAutoPlayBtn").click(this.cancelAutoPlay.bind(this));
    this.secondCounter$el = this.$el.find(".secondsCounter");
    this._initializeAutoplayTimer();

},

onPopupClosed: function() {

},

onAfterPageShow: function() {

},



});

export default AutoPlaySuggestionPopupView;
