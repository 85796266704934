import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import DynamicContentView from "views/common/DynamicContentView";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import ti18n from "i18n!nls/Journeyi18n";
import contentTemplate from 'text!../../../templates/journey/JourneyEditDetailsContent.html';

const Rollout = RolloutHelper.getInstance();

const ALLOW_USER_TO_CREATE_PAID_JOURNEYS = Rollout.isFeatureEnabled(Rollout.FEATURES.ALLOW_USER_TO_CREATE_PAID_JOURNEYS, true);

const FORM_VALIDATION_DELAY_IN_MILLISEC = 500;
const USE_DUMMY_CUSTOM_URL = false;
const JourneyEditDetailsContentView = DynamicContentView.extend({

    template : _.template(contentTemplate),


    initialize : function(options) {
        this._trebbleInfoJson =  {};
        this._jingleInfo =  null;
        this._greaterInfo =  null;
        this._introInfo =  null;
        this._outroInfo =  null;
        this._loadFunction = options.loadFunction;
        this._sweeperPlaying = false;
        this._greaterPlaying = false;
        this._introPlaying = false;
        this._outroPlaying = false;
        this._demoSweeperPlaying = false;
        this._demoGreaterPlaying = false;
        this._demoIntroPlaying = false;
        this._demoOutroPlaying = false;
        this._citySelectFieldView = options.citySelectFieldView;
        this._wasRemovedFromPage = false;
        DynamicContentView.prototype.initialize.call(this, {"canBeAppendedBeforeLoad": true});

    },

    load : function(){
        $.mobile.loading("show");
        return this._loadFunction().then((function(results){
            const trebbleInfoJson = results? results.journeyInfo: null;
            const jingleInfo = results? results.jingleInfo: null;
            const greaterInfo = results? results.greaterInfo: null;
            const introInfo = results? results.introInfo: null;
            const outroInfo = results? results.outroInfo: null;
            $.mobile.loading("show");
            if(trebbleInfoJson){
                this._trebbleInfoJson = trebbleInfoJson;
                if(!this._trebbleInfoJson.description){
                    this._trebbleInfoJson.description = null;
                }
                
                this._jingleInfo = jingleInfo;
                this._greaterInfo = greaterInfo;
                this._introInfo = introInfo;
                this._outroInfo = outroInfo;
                this._updateView();
            }else{
                console.error("Journey details couldn't be retrieved.");
                throw new Error(window.getI18n(ti18n,"JOURNEY_DETAILS_COULDNT_BE_RETRIEVED"));
            }
            $.mobile.loading("hide");
        }).bind(this)).catch((function(error){
            $.mobile.loading("hide");
            throw error;
        }).bind(this));
    },

    setWasRemovedFromPage: function(){
        this._wasRemovedFromPage = true;
    },

    setJingleInfo : function(jingleInfo){
        this._jingleInfo = jingleInfo;
        this._updateView();
    },

    getJingleInfo : function(jingleInfo){
        return this._jingleInfo;
    },

    setGreaterInfo : function(greaterInfo){
        this._greaterInfo = greaterInfo;
        this._updateView();
    },

    getGreaterInfo : function(greaterInfo){
        return this._greaterInfo;
    },

    setIntroInfo : function(introInfo){
        this._introInfo = introInfo;
        this._updateView();
    },

    getIntroInfo : function(introInfo){
        return this._introInfo;
    },

    setOutroInfo : function(outroInfo){
        this._outroInfo = outroInfo;
        this._updateView();
    },

    getOutroInfo : function(outroInfo){
        return this._outroInfo;
    },

    setCoverArtInfo : function(coverArtUrl, coverArtUrlInfo){
        if(this._trebbleInfoJson){
            this._trebbleInfoJson.coverArtUrl = coverArtUrl;
            this._trebbleInfoJson.coverArtUrlInfo = coverArtUrlInfo;
        }
        this._updateView();
    },

    updateTrebbleNameAndDescription : function(newName, newGoal, newDescription){
        if(this._trebbleInfoJson){
            this._trebbleInfoJson.name = newName;
            this._trebbleInfoJson.goal = newGoal;
            this._trebbleInfoJson.description = newDescription;
            this.description$el.html(this._trebbleInfoJson.description);
            this.description$el.trigger('autoresize');
            //Workaround for JQM mobile setting the placeholder to null
            //this.$el.find("#descriptionField").attr("placeholder", this.$el.find("#descriptionFieldDecorator").attr("label"));

        }
        
    },

    _updateCityFieldValue : function(cityId){
        this._cityId = cityId;
        this._citySelectFieldView.$el.hide();
        this.trigger("setCityFieldValueWithCityId",cityId);

    },

    hideShowCityFieldIfApplicable : function(){
        const trebbleCategory = this.categorySelect$el.val();
        if(trebbleCategory == Utils.getInstance().getLocalCategoryId()){
            this._citySelectFieldView.$el.show();
        }else{
            this._citySelectFieldView.$el.hide();
        }

    },

    hideShowJourneyCostIfApplicable : function(){
        if(this.isJourneyCostFree()){
            this.$el.find("#journeyCostField-button").hide();
        }else{
            this.$el.find("#journeyCostField-button").show();
        }
    },

    _updateView : function(){
        if(!this._wasRemovedFromPage){
            const coverArtUrl = (this._trebbleInfoJson && this._trebbleInfoJson.coverArtUrl)? this._trebbleInfoJson.coverArtUrl: null;
            const coverArtUrlInfo = (this._trebbleInfoJson && this._trebbleInfoJson.coverArtUrlInfo)? this._trebbleInfoJson.coverArtUrlInfo: null;
            this.nameField$el.val(this._trebbleInfoJson? this._trebbleInfoJson.name: "");
            this.goalField$el.val(this._trebbleInfoJson? this._trebbleInfoJson.goal: "");
            this.description$el.html(this._trebbleInfoJson? this._trebbleInfoJson.description:  "");
            this.description$el.trigger('autoresize');
            //Workaround for JQM mobile setting the placeholder to null
            //this.description$el.attr("placeholder", this.$el.find("#descriptionFieldDecorator").attr("label"));

            const categoryId =  this._trebbleInfoJson.categoryId;
            this.categorySelect$el.val(categoryId);
            const cityId =  this._trebbleInfoJson.cityId;
            this._updateCityFieldValue(cityId);
            const timezone =  this._trebbleInfoJson.timezone;
            this.trebbleTimezoneField$el.val(timezone);
            let language =  this._trebbleInfoJson.language;
            if(language == "en"){
                language = "en-US";
            }
            this.trebbleLanguageField$el.val(language);

            let visibilityTypeId = this._trebbleInfoJson? this._trebbleInfoJson.visibility : null;
            if(!visibilityTypeId){
                visibilityTypeId =  Utils.getInstance().getJourneyVisibility().PUBLIC
            }
            this.trebbleVisibiltiyField$el.val(visibilityTypeId);
            let isPaid = this._trebbleInfoJson.isPaid;
            this.isJourneyFreeField$el.val(isPaid ? "false": "true");
            let costInUSD = this._trebbleInfoJson.costInUSD;
            this.journeyCostField$el.val(costInUSD);
            if(ALLOW_USER_TO_CREATE_PAID_JOURNEYS || isPaid){
                this.$el.find(".price_section").show();
            }else{
                this.$el.find(".price_section").hide();
            }

            this.$el.find("#trebbleCategoryField").selectmenu('refresh');
            this.$el.find("#trebbleTimezoneField").selectmenu('refresh');
            this.$el.find("#trebbleLanguageField").selectmenu('refresh');
            this.$el.find("#isJourneyFreeField").selectmenu('refresh');
            this.trebbleVisibiltiyField$el.selectmenu('refresh');
            this.$el.find("#journeyCostField").selectmenu('refresh');
            this.hideShowJourneyCostIfApplicable();

            let resizedCoverArtUrl = window.trebble.globalHelpers.getResizeImageUrl(coverArtUrl,  200, 200, coverArtUrlInfo);
            if(!resizedCoverArtUrl){
                resizedCoverArtUrl = window.getResourceURLAccordingToLocationProtocol("/img/videoLoading.png");
                let coverArtWrapper$el = this.$el.find(".coverArtWrapper");
                if(!coverArtWrapper$el.hasClass("pe-7s-camera")){
                    coverArtWrapper$el.addClass("pe-7s-camera");
                }
            }else{
                let coverArtWrapper$el = this.$el.find(".coverArtWrapper");
                if(coverArtWrapper$el.hasClass("pe-7s-camera")){
                    coverArtWrapper$el.removeClass("pe-7s-camera");
                }
            }
            this.$el.find(".trebbleCoverArt").attr("src", resizedCoverArtUrl);
            if(this._jingleInfo){
                let sweeperAudioPlaceholder$el = this.$el.find(".audioPlaceholder.sweeper");
                if(sweeperAudioPlaceholder$el.hasClass("noAudioAvailable")){
                    sweeperAudioPlaceholder$el.removeClass("noAudioAvailable");
                }
            }else{
                let sweeperAudioPlaceholder$el = this.$el.find(".audioPlaceholder.sweeper");
                if(!sweeperAudioPlaceholder$el.hasClass("noAudioAvailable")){
                    sweeperAudioPlaceholder$el.addClass("noAudioAvailable");
                }
            }
            if(this._greaterInfo){
                let greaterAudioPlaceholder$el = this.$el.find(".audioPlaceholder.greater");
                if(greaterAudioPlaceholder$el.hasClass("noAudioAvailable")){
                    greaterAudioPlaceholder$el.removeClass("noAudioAvailable");
                }
            }else{
                let greaterAudioPlaceholder$el = this.$el.find(".audioPlaceholder.greater");
                if(!greaterAudioPlaceholder$el.hasClass("noAudioAvailable")){
                    greaterAudioPlaceholder$el.addClass("noAudioAvailable");
                }
            }
            if(this._introInfo){
                let introAudioPlaceholder$el = this.$el.find(".audioPlaceholder.intro");
                if(introAudioPlaceholder$el.hasClass("noAudioAvailable")){
                    introAudioPlaceholder$el.removeClass("noAudioAvailable");
                }
            }else{
                let introAudioPlaceholder$el = this.$el.find(".audioPlaceholder.intro");
                if(!introAudioPlaceholder$el.hasClass("noAudioAvailable")){
                    introAudioPlaceholder$el.addClass("noAudioAvailable");
                }
            }
            if(this._outroInfo){
                let outroAudioPlaceholder$el = this.$el.find(".audioPlaceholder.outro");
                if(outroAudioPlaceholder$el.hasClass("noAudioAvailable")){
                    outroAudioPlaceholder$el.removeClass("noAudioAvailable");
                }
            }else{
                let outroAudioPlaceholder$el = this.$el.find(".audioPlaceholder.outro");
                if(!outroAudioPlaceholder$el.hasClass("noAudioAvailable")){
                    outroAudioPlaceholder$el.addClass("noAudioAvailable");
                }
            }
            this.$el.find(".loadingImgForTrebbleInfo").hide();
            this.$el.find("#trebbleInfoDetailWrapper").show();
        }
    },

    getEditedTrebbleInfo : function(){
        const trebbleInfo = {};
        trebbleInfo.name = this.nameField$el.val();
        trebbleInfo.goal = this.goalField$el.val();

        trebbleInfo.description = this.description$el.val();
        trebbleInfo.categoryId =  this.categorySelect$el.val();
        trebbleInfo.cityId =  this._citySelectFieldView.getSelectedId();
        trebbleInfo.timezone =  this.trebbleTimezoneField$el.val();
        trebbleInfo.language = this.trebbleLanguageField$el.val();
        trebbleInfo.visibility = this.trebbleVisibiltiyField$el.val();
        trebbleInfo.isPaid = !this.isJourneyCostFree();
        trebbleInfo.costInUSD = this.getJourneyCostInUSD();

        return trebbleInfo;
    },

    setDemoSweeperPlaying: function(playing){
        this._demoSweeperPlaying = playing;
        const playDemoSweeperTextBtn  =this.$el.find("#playSweeperDemo > span.text");
        const playDemoSweeperIconBtn  =this.$el.find("#playSweeperDemo > span.iconButton");
        if(this._demoSweeperPlaying){
            playDemoSweeperIconBtn.removeClass("ion-ios7-play");
            playDemoSweeperIconBtn.addClass("fontello-icon-stop");
            playDemoSweeperTextBtn.html(window.getI18n(ti18n,"STOP_PLAYING_EXAMPLE"));
        }else{
            playDemoSweeperIconBtn.removeClass("fontello-icon-stop");
            playDemoSweeperIconBtn.addClass("ion-ios7-play");
            playDemoSweeperTextBtn.html(window.getI18n(ti18n,"PLAY_ME_AN_EXAMPLE"));
        }

    },

    setGreaterPlaying: function(playing){
        this._greaterPlaying = playing;
        const playGreaterBtn  =this.$el.find("#playGreaterBtn");
        if(this._greaterPlaying){
            playGreaterBtn.removeClass("ion-ios7-play");
            playGreaterBtn.addClass("fontello-icon-stop");
            playGreaterBtn.html(window.getI18n(ti18n,"STOP_GREETER"));
        }else{
            playGreaterBtn.removeClass("fontello-icon-stop");
            playGreaterBtn.addClass("ion-ios7-play");
            playGreaterBtn.html(window.getI18n(ti18n,"PLAY_GREETER"));
        }

    },

    setIntroPlaying: function(playing){
        this._introPlaying = playing;
        const playIntroBtn  =this.$el.find("#playIntroBtn");
        if(this._introPlaying){
            playIntroBtn.removeClass("ion-ios7-play");
            playIntroBtn.addClass("fontello-icon-stop");
            playIntroBtn.html(window.getI18n(ti18n,"STOP_INTRO"));
        }else{
            playIntroBtn.removeClass("fontello-icon-stop");
            playIntroBtn.addClass("ion-ios7-play");
            playIntroBtn.html(window.getI18n(ti18n,"PLAY_INTRO"));
        }

    },

    setOutroPlaying: function(playing){
        this._outroPlaying = playing;
        const playOutroBtn  =this.$el.find("#playOutroBtn");
        if(this._outroPlaying){
            playOutroBtn.removeClass("ion-ios7-play");
            playOutroBtn.addClass("fontello-icon-stop");
            playOutroBtn.html(window.getI18n(ti18n,"STOP_OUTRO"));
        }else{
            playOutroBtn.removeClass("fontello-icon-stop");
            playOutroBtn.addClass("ion-ios7-play");
            playOutroBtn.html(window.getI18n(ti18n,"PLAY_OUTRO"));
        }

    },

    setSweeperPlaying: function(playing){
        this._sweeperPlaying = playing;
        const playSweeperBtn  = this.$el.find("#playSweeperBtn");
        if(this._sweeperPlaying){
            playSweeperBtn.removeClass("ion-ios7-play");
            playSweeperBtn.addClass("fontello-icon-stop");
            playSweeperBtn.html(window.getI18n(ti18n,"STOP_SWEEPER"));
        }else{
            playSweeperBtn.removeClass("fontello-icon-stop");
            playSweeperBtn.addClass("ion-ios7-play");
            playSweeperBtn.html(window.getI18n(ti18n,"PLAY_SWEEPER"));
        }

    },

    setDemoGreaterPlaying: function(playing){
        this._demoGreaterPlaying = playing;
        const playDemoGreaterTextBtn  =this.$el.find("#playGreeterDemo > span.text");
        const playDemoGreaterIconBtn  =this.$el.find("#playGreeterDemo > span.iconButton");
        if(this._demoGreaterPlaying){
            playDemoGreaterIconBtn.removeClass("ion-ios7-play");
            playDemoGreaterIconBtn.addClass("fontello-icon-stop");
            playDemoGreaterTextBtn.html(window.getI18n(ti18n,"STOP_PLAYING_EXAMPLE"));
        }else{
            playDemoGreaterIconBtn.removeClass("fontello-icon-stop");
            playDemoGreaterIconBtn.addClass("ion-ios7-play");
            playDemoGreaterTextBtn.html(window.getI18n(ti18n,"PLAY_ME_AN_EXAMPLE"));
        }

    },

    setDemoIntroPlaying: function(playing){
        this._demoIntroPlaying = playing;
        const playDemoIntroTextBtn  =this.$el.find("#playIntroDemo > span.text");
        const playDemoIntroIconBtn  =this.$el.find("#playIntroDemo > span.iconButton");
        if(this._demoIntroPlaying){
            playDemoIntroIconBtn.removeClass("ion-ios7-play");
            playDemoIntroIconBtn.addClass("fontello-icon-stop");
            playDemoIntroTextBtn.html(window.getI18n(ti18n,"STOP_PLAYING_EXAMPLE"));
        }else{
            playDemoIntroIconBtn.removeClass("fontello-icon-stop");
            playDemoIntroIconBtn.addClass("ion-ios7-play");
            playDemoIntroTextBtn.html(window.getI18n(ti18n,"PLAY_ME_AN_EXAMPLE"));
        }

    },


    setDemoOutroPlaying: function(playing){
        this._demoOutroPlaying = playing;
        const playDemoOutroTextBtn  =this.$el.find("#playOutroDemo > span.text");
        const playDemoOutroIconBtn  =this.$el.find("#playOutroDemo > span.iconButton");
        if(this._demoOutroPlaying){
            playDemoOutroIconBtn.removeClass("ion-ios7-play");
            playDemoOutroIconBtn.addClass("fontello-icon-stop");
            playDemoOutroTextBtn.html(window.getI18n(ti18n,"STOP_PLAYING_EXAMPLE"));
        }else{
            playDemoOutroIconBtn.removeClass("fontello-icon-stop");
            playDemoOutroIconBtn.addClass("ion-ios7-play");
            playDemoOutroTextBtn.html(window.getI18n(ti18n,"PLAY_ME_AN_EXAMPLE"));
        }

    },


    playPauseSweeper : function(){
        if(this._sweeperPlaying){
            this.trigger("stopPlayingSweeper");
            this.setSweeperPlaying(false);
        }else{
            this.trigger("startPlayingSweeper", this._jingleInfo.uri);
            this.setSweeperPlaying(true);
        }
    },

    playPauseDemoSweeper : function(){
        if(this._demoSweeperPlaying){
            this.trigger("stopPlayingDemoSweeper");
            this.setDemoSweeperPlaying(false);
        }else{
            this.trigger("startPlayingDemoSweeper");
            this.setDemoSweeperPlaying(true);
        }
    },

    playPauseGreater : function(){
        if(this._greaterPlaying){
            this.trigger("stopPlayingGreater");
            this.setGreaterPlaying(false);
        }else{
            this.trigger("startPlayingGreater", this._greaterInfo.uri);
            this.setGreaterPlaying(true);
        }
    },

    playPauseIntro : function(){
        if(this._introPlaying){
            this.trigger("stopPlayingIntro");
            this.setIntroPlaying(false);
        }else{
            this.trigger("startPlayingIntro", this._introInfo.uri);
            this.setIntroPlaying(true);
        }
    },

    playPauseOutro : function(){
        if(this._outroPlaying){
            this.trigger("stopPlayingOutro");
            this.setOutroPlaying(false);
        }else{
            this.trigger("startPlayingGreater", this._outroInfo.uri);
            this.setOutroPlaying(true);
        }
    },

    playPauseDemoGreeter : function(){
        if(this._demoGreaterPlaying){
            this.trigger("stopPlayingDemoGreater");
            this.setDemoGreaterPlaying(false);
        }else{
            this.trigger("startPlayingDemoGreater");
            this.setDemoGreaterPlaying(true);
        }
    },

    playPauseDemoIntro : function(){
        if(this._demoIntroPlaying){
            this.trigger("stopPlayingDemoIntro");
            this.setDemoIntroPlaying(false);
        }else{
            this.trigger("startPlayingDemoIntro");
            this.setDemoIntroPlaying(true);
        }
    },

    playPauseDemoOutro : function(){
        if(this._demoOutroPlaying){
            this.trigger("stopPlayingDemoOutro");
            this.setDemoOutroPlaying(false);
        }else{
            this.trigger("startPlayingDemoOutro");
            this.setDemoOutroPlaying(true);
        }
    },


    events : {
        "click #editTrebbleCoverPicBtn": "editTrebbleCoverPic",
        "click #editSweeperButton": "editSweeper",
        "click #deleteSweeperButton": "deleteSweeper",
        "click #playSweeperBtn": "playPauseSweeper",
        "click #editGreaterButton": "editGreater",
        "click #editIntroButton": "editIntro",
        "click #editOutroButton": "editOutro",
        "click #deleteGreaterButton": "deleteGreater",
        "click #deleteIntroButton": "deleteIntro",
        "click #deleteOutroButton": "deleteOutro",
        "click #playGreaterBtn": "playPauseGreater",
        "click #playIntroBtn": "playPauseIntro",
        "click #playOutroBtn": "playPauseOutro",
        "click #playSweeperDemo": "playPauseDemoSweeper",
        "click #playGreeterDemo": "playPauseDemoGreeter",
        "click #playIntroDemo": "playPauseDemoIntro",
        "click #playOutroDemo": "playPauseDemoOutro",
        
    },

    editTrebbleCoverPic : function(){
        this.trigger("editTrebbleCoverPic");
    },

    editSweeper : function(){
        this.trigger("editSweeper");
    },

    deleteSweeper : function(){
        this.trigger("deleteSweeper");
    },

    editGreater : function(){
        this.trigger("editGreater");
    },

    editIntro : function(){
        this.trigger("editIntro");
    },

    editOutro : function(){
        this.trigger("editOutro");
    },

    deleteGreater : function(){
        this.trigger("deleteGreater");
    },

    deleteIntro : function(){
        this.trigger("deleteIntro");
    },

    deleteOutro : function(){
        this.trigger("deleteOutro");
    },
    
    onTrebbleNameFieldKeyUp: function(){
        this.trigger("nameValidation",this.nameField$el.val());
        this.onDataChanged();
    },

    onTrebbleGoalFieldKeyUp: function(){
        this.trigger("goalValidation",this.goalField$el.val());
        this.onDataChanged();
    },

    onTrebbleDescriptionFieldKeyUp: function(){
        this.trigger("descriptionValidation",this.description$el.val());
        this.onDataChanged();
    },

    setNameValid : function(nameValid,errorMessage){
        if (!nameValid) {
            this.nameFieldDecorator$el.find("label").attr("data-error",errorMessage);
            if(!this.nameField$el.hasClass("invalid"))
            {
                this.nameField$el.addClass("invalid");
            }
        } else {
            this.nameField$el.removeClass("invalid");
            this.nameFieldDecorator$el.find("label").attr("data-error","");
        }
    },

    setGoalValid : function(goalValid,errorMessage){
        if (!goalValid) {
            this.goalDecorator$el.find("label").attr("data-error",errorMessage);
            if(!this.goalField$el.hasClass("invalid"))
            {
                this.goalField$el.addClass("invalid");
            }
        } else {
            this.goalField$el.removeClass("invalid");
            this.goalDecorator$el.find("label").attr("data-error","");
        }
    },


    setDescriptionValid : function(descriptionValid,errorMessage){
        if (!descriptionValid) {
            this.descriptionDecorator$el.find("label").attr("data-error",errorMessage);
            if(!this.description$el.hasClass("invalid"))
            {
                this.description$el.addClass("invalid");
            }
        } else {
            this.description$el.removeClass("invalid");
            this.descriptionDecorator$el.find("label").attr("data-error","");
        }
    },


    onDataChanged : function(){
        this.trigger("formChanged");
        if(this._validationTriggerFunctionTimeout){
            clearTimeout(this._validationTriggerFunctionTimeout);
        }
        this._validationTriggerFunctionTimeout = setTimeout((function(){
            this.trigger("validateForm");
        }).bind(this), FORM_VALIDATION_DELAY_IN_MILLISEC);
        
    },

    validateNameField : function(){

        return !this.nameFieldDecorator$el.get(0).isInvalid;
    },	

    validateGoalField : function(){
        return !this.goalDecorator$el.get(0).isInvalid;
    },


    validateDescriptionField : function(){
        return !this.descriptionDecorator$el.get(0).isInvalid;
    },

    validateJourneyCost : function(){
        return this.isJourneyCostFree() || this.getJourneyCostInUSD() > 0;
    },


    _initializeTrebbleCategoryField : function(){
        const categoryListItemArray  = Utils.getInstance().getCapsuleCategoryListItemArray();
        const currentCategoryId = this._trebbleInfoJson? this._trebbleInfoJson.categoryId : null;
        const currentCityId = this._trebbleInfoJson? this._trebbleInfoJson.cityId : null;
        this._updateCityFieldValue(currentCityId);
        //Retrieve city name from city id
        categoryListItemArray.map((function(listItem,index){
            if(currentCategoryId === listItem.getId()){
                this.categorySelect$el.append("<option value='"+listItem.getId()+"' selected='selected'>"+ listItem.getLabel()+"</option>");
            }else{
                this.categorySelect$el.append("<option value='"+listItem.getId()+"'>"+ listItem.getLabel()+"</option>");
            }
        }).bind(this));
        this.categorySelect$el.after('<i class="ion-chevron-down" style="display: inline-block;"></i>');
        this.categorySelect$el.on("change",(function(){
            this.hideShowCityFieldIfApplicable();
        }).bind(this))
    },

    _initializeTrebbleTimezoneField : function(){
        const timezoneListItemArray  = Utils.getInstance().getTimezoneListItemArray();
        const timezoneId = this._trebbleInfoJson? this._trebbleInfoJson.timezone : null;
        timezoneListItemArray.map((function(listItem,index){
            if(timezoneId === listItem.getId()){
                this.trebbleTimezoneField$el.append("<option value='"+listItem.getId()+"' selected='selected'>"+ listItem.getLabel()+"</option>");
            }else{
                this.trebbleTimezoneField$el.append("<option value='"+listItem.getId()+"'>"+ listItem.getLabel()+"</option>");
            }
        }).bind(this));
        this.trebbleTimezoneField$el.after('<i class="ion-chevron-down" style="display: inline-block;"></i>');
    },

    _initializeTrebbleLanguageField : function(){
        const languageListItemArray  = Utils.getInstance().getLanguageListItemArray();
        let languageCode = this._trebbleInfoJson? this._trebbleInfoJson.language : null;
        if(languageCode == "en"){
            languageCode =  "en-US";
        }
        languageListItemArray.map((function(listItem,index){
            if(languageCode === listItem.getId()){
                this.trebbleLanguageField$el.append("<option value='"+listItem.getId()+"' selected='selected'>"+ listItem.getLabel()+"</option>");
            }else{
                this.trebbleLanguageField$el.append("<option value='"+listItem.getId()+"'>"+ listItem.getLabel()+"</option>");
            }
        }).bind(this));
        this.trebbleLanguageField$el.after('<i class="ion-chevron-down" style="display: inline-block;"></i>');
    },

    _initializeVisibilityField : function(){
        const journeyVisibiltyListItemArray  = Utils.getInstance().getJourneyVisibiltyListItemArray();
        let visibilityTypeId = this._trebbleInfoJson? this._trebbleInfoJson.visibility : null;
        if(!visibilityTypeId){
            visibilityTypeId =  Utils.getInstance().getJourneyVisibility().PUBLIC
        }
        journeyVisibiltyListItemArray.map((function(listItem,index){
            if(visibilityTypeId === listItem.getId()){
                this.trebbleVisibiltiyField$el.append("<option value='"+listItem.getId()+"' selected='selected'>"+ listItem.getLabel()+"</option>");
            }else{
                this.trebbleVisibiltiyField$el.append("<option value='"+listItem.getId()+"'>"+ listItem.getLabel()+"</option>");
            }
        }).bind(this));
        this.trebbleVisibiltiyField$el.after('<i class="ion-chevron-down" style="display: inline-block;"></i>');
        this.trebbleVisibiltiyField$el.selectmenu('refresh');
    },

    isVisibilityPublic : function(){
        return this.trebbleVisibiltiyField$el.val() === Utils.getInstance().getJourneyVisibility().PUBLIC;
    },

    resetVisibilityFieldToPublic : function(){
        this.trebbleVisibiltiyField$el.val(Utils.getInstance().getJourneyVisibility().PUBLIC);
        this.trebbleVisibiltiyField$el.selectmenu('refresh');
    },

    _initializeCityField : function(){
        this.$el.find(".cityFieldWrapper").append(this._citySelectFieldView.$el);
    },

    _initializeFreeOrPaidField : function(){
        if(!this._isJourneyFreeInitialized){
            const isJourneyFreeField$el  = this.$el.find("#isJourneyFreeField");
            isJourneyFreeField$el.append("<option value='true'>"+ window.getI18n(ti18n, "FREE") +"</option>");
            isJourneyFreeField$el.append("<option value='false'>"+ window.getI18n(ti18n, "PAID") +"</option>");
            isJourneyFreeField$el.after('<i class="ion-chevron-down" style="display: inline-block;"></i>');
            isJourneyFreeField$el.selectmenu('refresh');
            this._isJourneyFreeInitialized = true;
            this.isJourneyFreeField$el.on("change",(function(){
                this.hideShowJourneyCostIfApplicable();
            }).bind(this))
        }
    },


    _initializeJourneyCostField : function(){
        if(!this._isJourneyCostInitialized){
            const journeyCostField$el  = this.$el.find("#journeyCostField");
            const journeyCostInUSDListItemArray  = Utils.getInstance().getPossibleJourneyCostInUSDListItemArray();
            journeyCostInUSDListItemArray.map(function(listItem, index){
                journeyCostField$el.append("<option value='"+listItem.getId()+"'>"+ listItem.getLabel()+"</option>");
            });
            journeyCostField$el.after('<i class="ion-chevron-down" style="display: inline-block;"></i>');
            journeyCostField$el.selectmenu('refresh');
            this._isJourneyCostInitialized = true;
        }
    },

    isJourneyCostFree : function(){
        return this.isJourneyFreeField$el.val() == "true";
    },

    getJourneyCostInUSD : function(){
        return this.journeyCostField$el.val();
    },

    onJourneyFreeOrPaidChanged : function(){
        this.hideShowJourneyCostIfApplicable();
    },

    onJourneyPriceChanged : function(){
        this.onDataChanged();
    },

    showHidePricingSectionIfApplicable : function(){
        if(ALLOW_USER_TO_CREATE_PAID_JOURNEYS){
            this.$el.find(".price_section").show();
        }else{
            this.$el.find(".price_section").hide();
        }
    },

    onJourneyVisibilityChange : function(){
        this.trigger("onJourneyVisibilityChange");
    },


    render : function() {
        this.$el.html(this.template({"model": this._trebbleInfoJson, "ti18n": ti18n}));
        this.$el.enhanceWithin();
        this.nameField$el = this.$el.find("#nameField");
        this.nameField$el.on(" keyup input", this.onTrebbleNameFieldKeyUp.bind(this));
        this.nameFieldDecorator$el = this.$el.find("#nameFieldDecorator");
        this.nameFieldDecorator$el.get(0).validate = this.validateNameField.bind(this);
        this.goalField$el = this.$el.find("#goalField");
        this.goalField$el.on(" keyup input", this.onTrebbleGoalFieldKeyUp.bind(this));
        this.goalDecorator$el = this.$el.find("#goalFieldDecorator");
        this.goalDecorator$el.get(0).validate = this.validateGoalField.bind(this);
        this.description$el = this.$el.find("#descriptionField");
        this.descriptionDecorator$el = this.$el.find("#descriptionFieldDecorator");
        this.description$el.on(" keyup input", this.onTrebbleDescriptionFieldKeyUp.bind(this));
        this.categorySelect$el = this.$el.find("#trebbleCategoryField");
        this.trebbleTimezoneField$el = this.$el.find("#trebbleTimezoneField");
        this.trebbleLanguageField$el = this.$el.find("#trebbleLanguageField");
        this.trebbleVisibiltiyField$el = this.$el.find("#trebbleVisibiltiyField");
        this.isJourneyFreeField$el = this.$el.find("#isJourneyFreeField");
        this.journeyCostField$el = this.$el.find("#journeyCostField");
        this.trebbleVisibiltiyField$el.on("change",this.onJourneyVisibilityChange.bind(this));

        this.descriptionDecorator$el.get(0).validate = this.validateDescriptionField.bind(this);
        this.isJourneyFreeField$el.change(this.onJourneyFreeOrPaidChanged.bind(this));
        this.journeyCostField$el.change(this.onJourneyPriceChanged.bind(this));

        this._initializeTrebbleCategoryField();
        this._initializeTrebbleTimezoneField();
        this._initializeTrebbleLanguageField();
        this._initializeVisibilityField();
        this._initializeCityField();
        this._initializeFreeOrPaidField();
        this._initializeJourneyCostField();
        this.showHidePricingSectionIfApplicable();

        this.$el.find("select").on("change", this.onDataChanged.bind(this));
        
        
        
        return this;
    }

});

export default JourneyEditDetailsContentView;