import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import SequencerNodeView from "views/audioEditor/sequencerNodeView/SequencerNodeView";
import SequencerVideoNodeTemplate from 'text!../../../../templates/audioEditor/sequencerNodeView/SequencerVideoNodeTemplate.html';
import ti18n from 'i18n!nls/Sequenceri18n';
import RSVP from 'rsvp';


const SequencerVideoNodeView = SequencerNodeView.extend({



    constructor : function(attributes, options) {
        SequencerNodeView.apply(this, [attributes, options]);
        this.compiledTemlate =  _.template(SequencerVideoNodeTemplate);
    },
    
    events : _.extend({
        "click": "onNodeClick",
        "mouseenter": "onMouseEnter",
        "mouseleave": "onMouseLeave",
        "taphold": "onTapHold",
    }, SequencerNodeView.prototype.events),
    
    onNodeClick : function(){
        //this.trigger("startWrapNodeClick", this);
    },

    onTapHold : function(e){
        //this.trigger("tapholdOnWrapNode",e)
    },

    onMouseEnter: function(){
        //this.model.setAllNodesHoverHighlighted(true);
    },

    onMouseLeave: function(){
        //this.model.setAllNodesHoverHighlighted(false);
    },
    
    
    
    render : function() {

        this.$el.html(this.compiledTemlate({"model": this.model}));
        this.setElement(this.$el.find("span").first());
        this.afterRendering();
        return this;
    }

});
export default SequencerVideoNodeView;