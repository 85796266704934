

import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import ChooseSoundWidgetController from "controllers/chooseSound/ChooseSoundWidgetController";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import CollectionRepeatController from "controllers/common/CollectionRepeatController";
import TrebbleAudioListItemController from "controllers/common/TrebbleAudioListItemController";
import PlaylistHelper from "models/helper/PlaylistHelper";
import TrebbleAudio from "models/TrebbleAudio";
import FileUploadHelper from "models/helper/FileUploadHelper";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import ti18n from "i18n!nls/MyLibraryi18n";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const Rollout = RolloutHelper.getInstance();

//const CONFIGURE_BACKGROUND_MUSIC = Rollout.isFeatureEnabled(Rollout.FEATURES.CONFIGURE_BACKGROUND_MUSIC);

const ChooseCapsuleFromLibraryWidgetController = ChooseSoundWidgetController.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this._selectedCategoryId = options.selectedCategoryId;
        if(!options.numberOfResultsPerPage){
            options.numberOfResultsPerPage = 21;
        }
        options.onTrebbleAudioSelected = function(trebbleAudioSelected){
            trebbleAudioSelected.setSelected(true);
            if(options.onCapsuleSelected){
                options.onCapsuleSelected(trebbleAudioSelected? trebbleAudioSelected.getModelSourceReference(): null);
            }
        }
        options.onTrebbleAudioUnselected = function(trebbleAudioUnselected){
            trebbleAudioUnselected.setSelected(false);
            if(options.onCapsuleUnselected){
                options.onCapsuleUnselected(trebbleAudioUnselected? trebbleAudioUnselected.getModelSourceReference(): null);
            }
        }

        options.getTrebbleAudiosLoadHandler = this._getCapsulesLoadHandler;
        
        this._onTrebbleAudioUnselected = options.onTrebbleAudioUnselected;
        return ChooseSoundWidgetController.prototype.initialize.call(this, options);
    },



    _getCapsulesLoadHandler:  function(filter, offset, numberOfItemToLoad){
        return this._getCapsulesLoadHandlerWithPaging(filter, offset, numberOfItemToLoad).then((function(results){
            const trebbleAudioModelArray = [];
            if(results && results.length > 0){
                for(let i =0; i < results.length; i++){
                    const capsuleModel = Utils.getInstance().getModelFromCapsuleJson(results[i]);
                    const trebbleAudioForCapsuleMode = new TrebbleAudio({
                        "audioId": capsuleModel.getCapsuleId(),
                        "audioType": Utils.getInstance().getTrebbleAudioType().CAPSULE,
                        "title": capsuleModel.getTitle(),
                        "artist": capsuleModel.getText(),
                        "creationDate": capsuleModel.getCreationDate(),
                        "audioUrl": capsuleModel.getAudioUrl(),
                        "imageArtUrl": capsuleModel.getImageArtUrl(),
                        "modelSourceReference":capsuleModel
                    })
                    trebbleAudioModelArray.push(trebbleAudioForCapsuleMode);
                }
            }
            return trebbleAudioModelArray;
        }).bind(this))
    },

    _getCapsulesLoadHandlerWithPaging : function(filter, offset, numberOfItemToLoad){
        if(Utils.getInstance().getCapsuleStatuses().ALL == this._selectedCategoryId){
            return this._getAllMyCapsulesLoadHandlerWithPaging(filter, offset, numberOfItemToLoad);
        }
        if(Utils.getInstance().getCapsuleStatuses().ON_AIR == this._selectedCategoryId){
            return this._getMyOnAirCapsulesLoadHandlerWithPaging(filter, offset, numberOfItemToLoad);
        }
        if(Utils.getInstance().getCapsuleStatuses().DRAFTS == this._selectedCategoryId){
            return this._getMyDraftCapsulesLoadHandlerWithPaging(filter, offset, numberOfItemToLoad);
        }
        if(Utils.getInstance().getCapsuleStatuses().SCHEDULED == this._selectedCategoryId){
            return this._getMyScheduledCapsulesLoadHandlerWithPaging(filter, offset, numberOfItemToLoad);
        }
        /*if(Utils.getInstance().getCapsuleStatuses().RECEIVED == this._selectedCategoryId){
            return this._getMyReceivedCapsulesLoadHandlerWithPaging(filter, offset, numberOfItemToLoad);
        }*/
        return this._getAllMyCapsulesLoadHandlerWithPaging(filter, offset, numberOfItemToLoad);
    },


    _getAllMyCapsulesLoadHandlerWithPaging:  function(filter, offset, numberOfItemToLoad){
        return TrebbleClientAPIHelper.getInstance().getAllMyCapsulesWithPaging(this._searchKeyword, offset, numberOfItemToLoad);
    },


    _getMyOnAirCapsulesLoadHandlerWithPaging:  function(filter, offset, numberOfItemToLoad){
        return TrebbleClientAPIHelper.getInstance().getOnAirCapsulesWithPaging(this._searchKeyword, offset, numberOfItemToLoad);
    },


    _getMyScheduledCapsulesLoadHandlerWithPaging:  function(filter, offset, numberOfItemToLoad){
        return TrebbleClientAPIHelper.getInstance().getScheduledCapsulesWithPaging(this._searchKeyword, offset, numberOfItemToLoad);
    },


    _getMyReceivedCapsulesLoadHandlerWithPaging:  function(filter, offset, numberOfItemToLoad){
        return TrebbleClientAPIHelper.getInstance().getReceivedCapsulesWithPaging(this._searchKeyword, offset, numberOfItemToLoad);
    },

    _getMyOffAirCapsulesLoadHandlerWithPaging:  function(filter, offset, numberOfItemToLoad){
        return TrebbleClientAPIHelper.getInstance().getOffAirCapsulesWithPaging(this._searchKeyword, offset, numberOfItemToLoad);
    },


    _getMyDraftCapsulesLoadHandlerWithPaging:  function(filter, offset, numberOfItemToLoad){
        return TrebbleClientAPIHelper.getInstance().getDraftCapsulesWithPaging(this._searchKeyword, offset, numberOfItemToLoad);
    },


    
    _buildInfoBoxParamsForTrebbleAudioListController : function(){
        const params = {};
        params.iconClass = "ion-contrast";
        params.message = window.getI18n(ti18n,"NO_SHORTCAST");
        return params;

    },



});

export default ChooseCapsuleFromLibraryWidgetController;