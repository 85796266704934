import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import SequencerNodeBasicContextOptionView from "views/audioEditor/SequencerNodeBasicContextOptionView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import ti18n from "i18n!nls/Sequenceri18n";
import RSVP from "rsvp";

const SequencerNodeBasicContextOptionController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.model = options? options.sequencerController : null;
        
        this.context = options? options.context : null;
        this.elementSource$el = options? options.elementSource$el : null;
        this._popupPosition = options? options.popupPosition: null;
        this._onMenuClosedHandler = options? options.onMenuClose: null;
        _.extend(this, Backbone.Events);
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    ignoreSelectedNodes : function(){
        return this.model.onIgnoreActionTriggered();
    },

    wrapWithMusic : function(){
        return this.model.onWrapWithMusicActionTriggered();
    },

    insert : function(){
        return this.model.onInsertAudioNodeTriggered();
    },

    playFromHere : function(){
        return this.model.onPlayFromTriggered();
    },

    showMoreOptions : function(){
        const onSubMenuClosed = (function(){
            this.close();
        }).bind(this)
        return this.model.showMoreOptionMenu(this.view.getMoreOptionBtn$el(), onSubMenuClosed);
    },

    close: function(){
        return this.view.close();
    },

    onMenuClosed: function(){
        if(this._onMenuClosedHandler){
            this._onMenuClosedHandler();
        }
    },

    downloadClip: function(){
        return this.model.downloadSelectionAsClip();
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {

                this.view = new SequencerNodeBasicContextOptionView({
                    "model" : this.model,
                    "elementSource$el": this.elementSource$el,
                    "deleteAvailable": this.model.isDeleteActionAvailable(), 
                    "wrapWithBackgroundMusicAvailable": this.model.isWrapWithBackgroundMusicActionAvailable(),
                    "playFromHereAvailable":this.model.isPlayFromHereActionAvailable(),
                    "insertAvailable": this.model.isInsertActionAvailable(),
                    "moreOptionAvailable": this.model.isMoreOptionAvailable(),
                    "downloadClipAvailable": this.model.isDownloadClipAvailable(),
                    "popupPosition": this._popupPosition
                });

                this.listenTo(this.view, "ignoreSelectedNodes",this.ignoreSelectedNodes);
                this.listenTo(this.view, "wrapWithMusic", this.wrapWithMusic);
                this.listenTo(this.view, "insert",this.insert);
                this.listenTo(this.view, "playFromHere",this.playFromHere);
                this.listenTo(this.view, "showMoreOptions",this.showMoreOptions);
                this.listenTo(this.view, "downloadClip",this.downloadClip);
                this.listenTo(this.view, "closed", this.onMenuClosed);

                this.view.render();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default SequencerNodeBasicContextOptionController;