import SongListController2 from 'controllers/common/SongListController2';

const YoutubeListController = SongListController2.extend({

    initialize : function(options) {
        if(!options){
            options = {};
        }
        options.defaultHeightElement = 65;
        options.customListClass = "youtubeList";
        options.showSongCoverImage = true;
        options.actionItemButtonClass = null;
        options.doNotShowAddToRadioButton = true;
        options.doNotShowGradeSongButton = true;
        SongListController2.prototype.initialize.call(this, options);
            
        
    },



});

export default YoutubeListController;