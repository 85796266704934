import _ from 'underscore';
import Backbone from "backbone";

const SpeakerInfo =  Backbone.Model.extend({
    idAttribute: "id",

    constructor: function(attributes, options) {
        Backbone.Model.apply(this, [attributes, options]);
        this.set("editable",true);
    },

    getSpeakerId : function(){
        return this.get("speakerId");
    },

    setSpeakerId : function(speakerId){
        this.set("speakerId",speakerId);
    },

    getInitialSpeakerLabel : function(){
        return this.get("initialSpeakerLabel");
    },

    setInitialSpeakerLabel : function(initialSpeakerLabel){
        this.set("initialSpeakerLabel",initialSpeakerLabel);
    },

    getModifiedSpeakerLabel : function(){
        return this.get("modifiedSpeakerLabel");
    },

    setModifiedSpeakerLabel : function(modifiedSpeakerLabel){
        this.set("modifiedSpeakerLabel",modifiedSpeakerLabel);
    },

    getSpeakerLabel : function(){
        return this.getModifiedSpeakerLabel()?this.getModifiedSpeakerLabel(): this.getInitialSpeakerLabel();
    },

    isSameSpeaker : function(speakerLabelToCompareTo){
        return this.getSpeakerLabel() === speakerLabelToCompareTo;
    },

    isEditable : function(){
        return this.get("editable");
    },

    getSerializedData : function(){
        const stateToReturn  = this.toJSON();
        return stateToReturn;
    },

    restoreFromSerializedData: function(serializedData){
        this.set(serializedData);
    },

});



export default SpeakerInfo; 