import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import AudioSegment from "models/audioEditor/segments/AudioSegment";
import RSVP from "rsvp";

const CLASSNAME = "SoundAudioSegment";
const SoundAudioSegment = AudioSegment.extend({
    idAttribute: "id",


constructor: function(attributes, options) {
    AudioSegment.apply(this, [attributes, options]);
    this.set("type", SoundAudioSegment.CLASSNAME);
    
},

clone: function(){
    return new SoundAudioSegment(this.toJSON());
},


});
SoundAudioSegment.CLASSNAME = CLASSNAME;
export default SoundAudioSegment; 