import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import PersistentModels from "services/PersistentModels";
import PlayerService from "services/PlayerService";
import swal from 'sweetalert';

/*"controllers/myLibrary/SongOptionController",*/
/*"controllers/albumDetail/AlbumDetailController",*/
//"controllers/myLibrary/myLibraryPageController", //THIS LINE IS COULD BE COMMENTED OUT
//import PlayerController from "controllers/player/PlayerController";

import PlaylistHelper from "models/helper/PlaylistHelper";
import ti18n from "i18n!nls/routeri18n";

/*"controllers/albumOptionMenu/AlbumOptionMenuController",   */
/*  "controllers/queue/QueueController",  */
/*"controllers/artistOptionMenu/ArtistOptionMenuController",*/
/*"controllers/queueOptionMenu/QueueOptionMenuController",*/
/*"controllers/albumDetail/AlbumDetailController",*/
/*"controllers/artistDetail/ArtistDetailController",*/
/*"controllers/artistDetail/ArtistFullDetailController",*/
//import PlayerFloatingController from "controllers/playerFooter/PlayerFloatingController";  //THIS LINE IS COULD BE COMMENTED OUT

//import PlayerFooterController from "controllers/playerFooter/PlayerFooterController";  //THIS LINE IS COULD BE COMMENTED OUT
//import PlayerFooterBrowserController from "controllers/playerFooter/PlayerFooterBrowserController";  //THIS LINE IS COULD BE COMMENTED OUT

//"controllers/playlistPodcast/PlaylistPodcastsController", 
/*"controllers/home/HomeController",*/
/*"controllers/songListPageController/SongListPageController",*/
/*"controllers/songListPageController/SongInUserLibraryPageController",*/
/*"controllers/albumListPageController/AlbumListPageController",*/
/*"controllers/user/FollowersListPageController",*/
/*"controllers/user/FollowingListPageController",*/
/*"controllers/playlistPodcast/PlaylistListPageController",*/
/*"controllers/user/UserListPageController",*/
/*"controllers/artistListPageController/ArtistListPageController",*/
/* "controllers/common/SongTabManagerPopupController",*/
/*"controllers/myLibrary/GenrePlaylistDetailsController",*/
/*"controllers/myLibrary/RatedPlaylistDetailsController",*/
/*"controllers/playlistPodcast/FollowerPersonalRadioDetailsController",*/
/*"controllers/playlistPodcast/MyAutoRadioDetailsController",*/
import FileUploadHelper from "models/helper/FileUploadHelper";

//import MyPersonalRadioDetailsController from "controllers/playlistPodcast/MyPersonalRadioDetailsController"; //THIS LINE IS COULD BE COMMENTED OUT

/*"controllers/playlistPodcast/PersonalTrebbleOptionController",*/
/*"controllers/user/UserOptionController",*/
/*"controllers/playlistPodcast/TrebbleEditDetailsController",*/
/*"controllers/playlistPodcast/FollowedTrebbleListPageController",*/
/*"controllers/capsule/CapsuleDetailsController",*/
/*"controllers/common/NotificationPopupController",*/
//   "controllers/capsule/CapsuleListOptionController",
/*"controllers/capsule/CapsuleListOptionControllerNew",*/
/*"controllers/comment/CommentListOptionController",*/
//	"controllers/user/UserProfileDetailsPageController", //THIS LINE IS COULD BE COMMENTED OUT
/*"controllers/comment/CommentListPageController",*/
/*"controllers/capsule/CapsuleDetailsPopupController",*/
/*"controllers/queue/QueueTuningPopupController",*/
/*"controllers/trebbleStatistics/TrebbleStatisticsPageController",*/
//import ExplorePageController from "controllers/explore/ExplorePageController"; //THIS LINE IS COULD BE COMMENTED OUT

//import OnboardingListenerController from "controllers/onboarding/OnboardingListenerController";

/*"controllers/soundcloudSongListPageController/SoundcloudSongListPageController",*/
/*"controllers/youtubeListPageController/YoutubeListPageConttroller",*/
import HomeMenuPanelController from "controllers/homeMenuPanel/HomeMenuPanelController";

/*"controllers/comment/CommentController",*/
/*"controllers/shareTrebble/ShareTrebblePopupController",*/
/*"controllers/featurePage/FeaturePageController",*/
//	"controllers/globalSearch/GlobalSearchController", //THIS LINE IS COULD BE COMMENTED OUT
import LocalStorageHelper from "models/helper/LocalStorageHelper";

import Album from "models/Album";
import Artist from "models/Artist";
//import Song from "models/Song";
import Utils from "models/helper/Utils";
import PlayerModel from "models/player/PlayerModel";

//"services/SongIdentificationService",T
// "views/common/OptionPopupView",
import PushNotificationHelper from "models/helper/PushNotificationHelper";

/*"controllers/whatsnew/WhatsNewPopupController",*/
import KeyboardKeyHandlerHelper from "models/helper/KeyboardKeyHandlerHelper";

import ProgressiveAppHelper from "models/helper/ProgressiveAppHelper";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import LiveChatWidgetHelper from "models/helper/LiveChatWidgetHelper";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import FreeTranscriptionMinLeftWarningController from "controllers/common/FreeTranscriptionMinLeftWarningController";
import RSVP from "rsvp";
/*	var routeMap = {
    //"" : "showHomePage",
    "albumDetailsPage_:albumId": "showAlbumDetailPage", 
    "artistDetailsPage_:artistId": "showArtistDetailPage", 
    "artistFullDetailsPage_:artistId": "showArtistFullDetailPage", 
    "queue": "showQueuePage", 
    "player": "showPlayerPage", 
    "p/p/t/:trebbleId/r/:referrer": "playTrebbleAndShowPlayer", 
    "p/p/t/:trebbleId/r/:referrer/ctk/:customTrackingKey": "playTrebbleAndShowPlayer", 
    "p/p/t/:trebbleId": "playTrebbleAndShowPlayer", 
    "p/l/t/:trebbleId/r/:referrer": "preloadTrebbleAndShowPlayer",
    "p/l/t/:trebbleId/r/:referrer/ctk/:customTrackingKey": "preloadTrebbleAndShowPlayer",
    "p/l/t/:trebbleId": "preloadTrebbleAndShowPlayer",
    "player/action/:actionType/trebble/:trebbleId": "loadTrebbleAndShowPlayer", 
    "player/action/:actionType/trebble/:trebbleId/visitor/:isVisitor/context/:contextid": "loadTrebbleAndShowPlayer", 
    "p/p/c/:trebbleId/r/:referrer": "playCapsuleSimpleSetAndShowPlayer", 
    "p/p/c/:trebbleId/r/:referrer/ctk/:customTrackingKey": "playCapsuleSimpleSetAndShowPlayer", 
    "p/p/c/:trebbleId": "playCapsuleSimpleSetAndShowPlayer", 
    "p/l/c/:trebbleId/r/:referrer": "preloadCapsuleSimpleSetAndShowPlayer",
    "p/l/c/:trebbleId/r/:referrer/ctk/:customTrackingKey": "preloadCapsuleSimpleSetAndShowPlayer",
    "p/l/c/:trebbleId": "preloadCapsuleSimpleSetAndShowPlayer", 
    "player/action/:actionType/capsule/:capsuleId/visitor/:isVisitor/context/:contextid": "loadCapsuleSimpleSetAndShowPlayer",
    "player/action/:actionType/capsule/:capsuleId": "loadCapsuleSimpleSetAndShowPlayer", 
    "p/p/s/:songId/r/:referrer": "playSongSimpleSetAndShowPlayer", 
    "p/p/s/:songId/r/:referrer/ctk/:customTrackingKey": "playSongSimpleSetAndShowPlayer", 
    "p/p/s/:songId": "playSongSimpleSetAndShowPlayer", 
    "p/l/s/:songId/r/:referrer": "preloadSongSimpleSetAndShowPlayer",
    "p/l/s/:songId/r/:referrer/ctk/:customTrackingKey": "preloadSongSimpleSetAndShowPlayer",
    "p/l/s/:songId": "preloadSongSimpleSetAndShowPlayer", 
    "player/action/:actionType/song/:songId/visitor/:isVisitor/context/:contextid": "loadSongSimpleSetAndShowPlayer",
    "player/action/:actionType/song/:songId": "loadSongSimpleSetAndShowPlayer", 
    "songComments/:songId": "showCommentsForSongWithId",
    "songCapsules/:songId": "showCapsulesForSongWithId",
    "capsule/:capsuleId" : "showCapsuleForCapsuleId",
    "home": "showOpenedHomePage", 
    "mylibrary" : "showMyLibraryPage",
    "addSongToTrebble" : "showAddSongToTrebblePage",
    "mylibrary%2F:searchKeyword" : "showMyLibraryPage",
    "home" : "showHomePage",
    "PlaylistPodcastsPage" : "showPlaylistPodcasts",
    "signupPage" : "showSignupPage",
    "OnAirPage" : "showOnAirPage",
    "globalSearch" : "showGlobalSearchPage",
    "globalSearch%2F:searchKeyword" : "showGlobalSearchPage",
    "ExplorePage" : "showExplorePage",
    "FeedAndActivityPage" : "showFeedAndNotificationPage",
    "CapsulesPage" : "showCapsulesPage",
    "songListPage_:searchKeyword" : "showSongListPage",
    "songListPageInUserLibrary_:username":"showSongListInUserLibraryPageByUsername",
    "albumListPage_:searchKeyword" : "showAlbumListPage",
    "albumListPageForArtist_:artistId" : "showAllAlbumListPage",
    "relatedArtistListPageForArtist_:artistId" : "showAllRelatedArtistListPage",
    "artistListPage_:searchKeyword" : "showArtistListPage",
    "followerPageForUser_:username" : "showFollowerListPageByUsername",
    "followingPageForUser_:username" : "showFollowingListPageByUsername",
    "followedTrebblesPageForUser_:username": "showFollowedTrebblesListPageByUsername",
    "soundcloudSongListPage_:searchKeyword" : "showSoundcloudSongListPage",
    "youtubeVideoListPage_:searchKeyword" : "showYoutubeVideoListPage",
    "searchForTrebbleForKeyword_:searchKeyword" : "showTrebbleListPageBySearchKeyword",
    "searchForUsersForKeyword_:searchKeyword" : "showUserListPageBySearchKeyword",
    "localGenrePlaylistDetails_:genreId" : "showLocalGenrePlaylistDetailPageByGenreId",
    "localRatedPlaylistDetails_:ratedPlaylistId" : "showLocalRatedPlaylistDetailPageByRatedPlaylistId",
    "trebble_:radioId" : "showMyPersonalRadioDetailPageeByRadioId",
    "trebbleInfoEditPage_:trebbleId" : "showTrebbleInfoEditPageByTrebbleId",
    "pageCommentForSong_:songUri" : "showPageCommentsForSongBySongUri",
    "pageCommentForCapsule_:capsuleId" : "showPageCommentsForCapsuleByCapsuleId",
    "trebble_statistic_page_:trebbleId" : "showTrebbleStatisticsPageByTrebbleId",
    "pageCommentReplies_:commentId" : "showPageCommentReplyByCommentId",
    "capsuleInfoEditPage_:capsuleId" : "showCapsuleInfoEditPageByCapsuleId",
    "u_:username" : "showExitingUserProfilePageByUsername",
    "user_:userUID" : "showExitingUserProfilePageByUserUID",
    "createCapsuleForSongPage_:songUri" : "showCreateCapsulePageBySongId",
    "myAutoRadioDetails_:radioId" : "showMyAutoRadioDetailPageByRadioId",
    "myFollowerPersonalRadioDetails_:radioId" : "showMyFollowerPersonalRadioDetailPageByRadioId",
    "radioDetails_:radioId" : "showRadioDetailPageByRadioId",
    "featurePage" : "showFeaturesPage",
    "GetStartedForCuratorsPage" : "showHelpPage",
    "": "showDefaultPage",
    "*trebbleCustomUrl" : "preloadTrebbleViaCustomUrl",
};

*/
const _buildRouteInfo= function(functionName, isGuestLoginAllowed, isReferrerPassedInUrl, referrerArgumentIndex, loadPlayerQueue, isAccessCodePreset, allowEasySignupForListenersAtLogin){
    return {"functionName" :functionName,"isGuestLoginAllowed": isGuestLoginAllowed,"isReferrerPassedInUrl":isReferrerPassedInUrl, "referrerArgumentIndex": referrerArgumentIndex,"loadPlayerQueue": loadPlayerQueue,"isAccessCodePreset": isAccessCodePreset, "allowEasySignupForListenersAtLogin": allowEasySignupForListenersAtLogin};
}
var routeMapInfo = {
    //"" : "showHomePage",
    "albumDetailsPage_:albumId": "showAlbumDetailPage", 
    "artistDetailsPage_:artistId": "showArtistDetailPage", 
    "artistFullDetailsPage_:artistId": "showArtistFullDetailPage", 
    "queue": "showQueuePage", 
    "player": "showPlayerPage", 
    //"p/p/t/:trebbleId/r/:referrer": "playTrebbleAndShowPlayer", 
    "p/p/t/:trebbleId(/r/:referrer)(/ctk/:customTrackingKey)": _buildRouteInfo("playTrebbleAndShowPlayer", true, true, 1, true, true),
    "playCapsuleFeed/:userId(/r/:referrer)(/ctk/:customTrackingKey)(/startAt/:capsuleId)": _buildRouteInfo("playCapsuleFeedAndShowPlayer", true, true, 1, true, true),
    "playJourneyTrailer/:trebbleId(/r/:referrer)(/ctk/:customTrackingKey)": _buildRouteInfo("playJourneyTrailerAndShowPlayer", true, true, 1, true, true),
    "p/p/j/:trebbleId(/r/:referrer)(/ctk/:customTrackingKey)": _buildRouteInfo("playJourneyTrailerAndShowPlayer", true, true, 1, true, true),
    //"p/p/t/:trebbleId": "playTrebbleAndShowPlayer", 
    //"p/l/t/:trebbleId/r/:referrer": "preloadTrebbleAndShowPlayer",
    "p/l/t/:trebbleId(/r/:referrer)(/ctk/:customTrackingKey)":   _buildRouteInfo("preloadTrebbleAndShowPlayer", true, true, 1, true, true),
    "forceauth/p/l/t/:trebbleId(/r/:referrer)(/ctk/:customTrackingKey)":   _buildRouteInfo("preloadTrebbleAndShowPlayer", false, true, 1, false),
    //"p/l/t/:trebbleId": "preloadTrebbleAndShowPlayer",
    //"player/action/:actionType/trebble/:trebbleId": "loadTrebbleAndShowPlayer", 
    "player/action/:actionType/trebble/:trebbleId(/visitor/:isVisitor)(/context/:contextid)(/contextType/:contextType)": _buildRouteInfo("loadTrebbleAndShowPlayer", true, true, 1, true, true), 
    //"p/p/c/:trebbleId/r/:referrer": "playCapsuleSimpleSetAndShowPlayer", 
    "p/p/c/:trebbleId(/r/:referrer)(/ctk/:customTrackingKey)": _buildRouteInfo("playCapsuleSimpleSetAndShowPlayer", true, true, 1, true, true), 
    //"p/p/c/:trebbleId": "playCapsuleSimpleSetAndShowPlayer", 
    //"p/l/c/:trebbleId/r/:referrer": "preloadCapsuleSimpleSetAndShowPlayer",
    "p/l/c/:trebbleId(/r/:referrer)(/ctk/:customTrackingKey)": _buildRouteInfo("preloadCapsuleSimpleSetAndShowPlayer", true, true, 1, true, true), 
    //"p/l/c/:trebbleId": "preloadCapsuleSimpleSetAndShowPlayer", 
    "player/action/:actionType/capsule/:capsuleId(/visitor/:isVisitor)(/context/:contextid)(/contextType/:contextType)":  _buildRouteInfo("loadCapsuleSimpleSetAndShowPlayer", true, true, 1, true, true), 
    //"player/action/:actionType/capsule/:capsuleId": "loadCapsuleSimpleSetAndShowPlayer", 
    //"p/p/s/:songId/r/:referrer": "playSongSimpleSetAndShowPlayer", 
    "p/p/s/:songId(/r/:referrer)(/ctk/:customTrackingKey)": _buildRouteInfo("playSongSimpleSetAndShowPlayer", true, true, 1, true, true),
    //"p/p/s/:songId": "playSongSimpleSetAndShowPlayer", 
    //"p/l/s/:songId/r/:referrer": "preloadSongSimpleSetAndShowPlayer",
    "p/l/s/:songId(/r/:referrer)(/ctk/:customTrackingKey)": _buildRouteInfo("preloadSongSimpleSetAndShowPlayer", true, true, 1, true, true), 
    //"p/l/s/:songId": "preloadSongSimpleSetAndShowPlayer", 
    "player/action/:actionType/song/:songId(/visitor/:isVisitor)(/context/:contextid)(/contextType/:contextType)": _buildRouteInfo("loadSongSimpleSetAndShowPlayer", true, true, 1, true, true), 
    //"player/action/:actionType/song/:songId": "loadSongSimpleSetAndShowPlayer", 
    "songComments/:songId": _buildRouteInfo("showCommentsForSongWithId", true, false, -1, true),
    "capsuleComments/:capsuleId": _buildRouteInfo("showCommentsForCapsuleWithId", true, false, -1, true),
    "songCapsules/:songId": _buildRouteInfo("showCapsulesForSongWithId" , true, false, -1, true),
    "capsule/:capsuleId" : _buildRouteInfo("showCapsuleForCapsuleId", true, false, -1, true),
    "activeCapsules/:radioId" : _buildRouteInfo("showActiveCapsuleForRadioId", true, false, -1, true),
    "ss/t/:audioPlayerToken(/s/:searchQuery)" : _buildRouteInfo("showCapsulesMatchingSearchFromRecentlyListenOnSmartSpeakers", true, false, -1, true),
    //"home": "showOpenedHomePage", 
    "settings":"showSettingsPage",
    "mylibrary" : "showMyLibraryPage",
    "addSongToTrebble" : "showAddSongToTrebblePage",
    "mylibrary(%2F:searchKeyword)(/tab/:selectedtab)" : "showMyLibraryPage",
    //"home" : "showHomePage",
    "PlaylistPodcastsPage" : "showPlaylistPodcasts",
    "signupPage" : "showSignupPage",
    //"fullSignupPage" : "showFullSignupPage",
    //"newSignupPageInline(/accessCode/:accessCode)" : _buildRouteInfo("showNewSignupPageInline", true, false, -1, false),
    "newSignupPage(/accessCode/:accessCode)(/forward_url/:forward_url)" : _buildRouteInfo("showNewSignupPage", true, false, -1, false),
    "signupForAudioEditor(/accessCode/:accessCode)(/forward_url/:forward_url)" : _buildRouteInfo("newSignupPageForAudioEditor", true, false, -1, false),
    "onboardingSignupPage(/accessCode/:accessCode)(/forward_url/:forward_url)" : _buildRouteInfo("showFullSignupPage", true, false, -1, false),
    "OnAirPage" : "showOnAirPage",
    "globalSearch" : "showGlobalSearchPage",
    "helpAndFeedback" : "showHelpAndFeedback",
    "resetPassword": _buildRouteInfo("showSendPasswordResetLinkPage", true, false, -1, false),
    "globalSearch%2F:searchKeyword" : "showGlobalSearchPage",
    "ExplorePage(/category/:categoryId)" : _buildRouteInfo("showExplorePage", true, false, -1, true),
    "Onboarding(/category/:categoryId)" : _buildRouteInfo("showListenerOnboardingPage", true, false, -1, false),
    "Discover" : _buildRouteInfo("showExplorePage", true, false, -1, true),
    "discover" : _buildRouteInfo("showExplorePage", true, false, -1, true),
    "mySubscriptions" : _buildRouteInfo("showMySubscriptionsPage", false, false, -1, false, false, true), 
    "myJourneys": _buildRouteInfo("showMyJourneysPage", false, false, -1, false, false, true), 
    "home" : _buildRouteInfo("showNewHomePage", false, false, -1, false, false, true), 
    "myReferralPage" : _buildRouteInfo("showReferralPage", false, false, -1, false, false, true), 
    "FeedAndActivityPage" : "showFeedAndNotificationPage",
    "reactTestPage":"showReactTestPage",
    "CapsulesPage" : "showCapsulesPage",
    "songListPage_:searchKeyword" : "showSongListPage",
    "songListPageInUserLibrary_:username":"showSongListInUserLibraryPageByUsername",
    "albumListPage_:searchKeyword" : "showAlbumListPage",
    "editProfile":"showUserEditProfileInfo",
    "albumListPageForArtist_:artistId" : "showAllAlbumListPage",
    "relatedArtistListPageForArtist_:artistId" : "showAllRelatedArtistListPage",
    "artistListPage_:searchKeyword" : "showArtistListPage",
    "followerPageForUser_:username" : "showFollowerListPageByUsername",
    "followingPageForUser_:username" : "showFollowingListPageByUsername",
    "followedTrebblesPageForUser_:username": "showFollowedTrebblesListPageByUsername",
    "soundcloudSongListPage_:searchKeyword" : "showSoundcloudSongListPage",
    "youtubeVideoListPage_:searchKeyword" : "showYoutubeVideoListPage",
    "searchForTrebbleForKeyword_:searchKeyword" : "showTrebbleListPageBySearchKeyword",
    "searchForJourneyForKeyword_:searchKeyword" : "showJourneyListPageBySearchKeyword",
    "searchForUsersForKeyword_:searchKeyword" : "showUserListPageBySearchKeyword",
    "localGenrePlaylistDetails_:genreId" : "showLocalGenrePlaylistDetailPageByGenreId",
    "localRatedPlaylistDetails_:ratedPlaylistId" : "showLocalRatedPlaylistDetailPageByRatedPlaylistId",
    "trebbleInfoEditPage_:trebbleId" : "showTrebbleInfoEditPageByTrebbleId",
    "pageCommentForSong_:songUri" : _buildRouteInfo("showPageCommentsForSongBySongUri", true, false, -1, false),
    "pageCommentForCapsule_:capsuleId" :  _buildRouteInfo("showPageCommentsForCapsuleByCapsuleId", true, false, -1, false),
    "trebble_statistic_page_:trebbleId" : "showTrebbleStatisticsPageByTrebbleId",
    "trebble_stats_page_:trebbleId" : "showTrebbleStatsPageByTrebbleId",
    "capsule_stats_page_/:capsuleId" : "showCapsuleStatsPageByCapsuleId",
    "journey_stats_page_:journeyRadioId": "showJourneyStatsPageByRadioId",
    "write_review_:journeyRadioId": "showWriteReviewOnJourneyPageByRadioId",
    "trebble_:radioId" : _buildRouteInfo("showMyPersonalRadioDetailPageeByRadioId", true, false, -1, true, true),
    "setupVoiceEnabledDevicesPage": _buildRouteInfo("showSetupVoiceEnabledDevicesPageController", true, false, -1, true, true),
    "public_:radioId" : _buildRouteInfo("showPublicRadioDetailPageeByRadioId", true, false, -1, true, true),
    "journey_:radioId" : _buildRouteInfo("showPublicJourneyDetailPageeByJourneyRadioId", true, false, -1, true, true),
    "journeyPreview_:radioId" : _buildRouteInfo("showPreviewPublicJourneyDetailPageeByJourneyRadioId", true, false, -1, true, true),
    "journeyManagePage_:radioId" : _buildRouteInfo("showJourneyManagePageByJourneyRadioId", true, false, -1, true, true),
    "j/:radioId(/r/:referrer)" : _buildRouteInfo("showPublicJourneyDetailPageeByJourneyRadioId", true, false, -1, true, true),
    "shortcast_:shortcastId" : _buildRouteInfo("showShortcastDetailPageByShortcastId", true, false, -1, true, true),
    "songStatisticPage/:songTrebbleId" : "showSongStatisticsPageBySongId",
    "pageCommentReplies_:commentId" : _buildRouteInfo("showPageCommentReplyByCommentId", true, false, -1, false),
    "capsuleInfoEditPage_:capsuleId" : "showCapsuleInfoEditPageByCapsuleId",
    "u_:username" : "showExitingUserProfilePageByUsername",
    "user_:userUID" : "showExitingUserProfilePageByUserUID",
    "createCapsuleForSongPage_:songUri" : "showCreateShortcastPageByTimestamp",
    "createAShortcastPage_:timestamp" : "showCreateShortcastPageByTimestamp",
    "createShortcastFromTranscription_:transcriptionId(/enhanced/:enhanced)" : "showCreateShortcastPageFromTranscription",
    "createShortcastFromProject_:projectId" : "showCreateShortcastPageFromProject",
    "createShortcastFromCapsuleId%2F:capsuleId%2Fprojectid%2F:projectId":"showCreateShortcastPageFromEditingExistingCapsuleWithProjectId",
    "createShortcastDemo_:transcriptionId" :  _buildRouteInfo("showCreateShortcastPageFromTranscription", true, false, -1, false, true),
    "successJourneyPurchase/:journeyRadioId":"showJourneyPageWithSuccessPurchaseIfApplicable",
    "myAutoRadioDetails_:radioId" : "showMyAutoRadioDetailPageByRadioId",
    "sendCapsuleToUserWithId:uniqueId": _buildRouteInfo("showSendCapsuleToUserByUserIdIntroPage", true, false, -1, true, true),
    "myFollowerPersonalRadioDetails_:radioId" : "showMyFollowerPersonalRadioDetailPageByRadioId",
    "uploadAudioFileAsCapsule(/addMusic/:addMusic)(/musicUri/:musicUri)": "showUploadAudioFileAsCapsule",
    "featurePage" : "showFeaturesPage",
    "token/:jwtToken(/forward_url/:forward_url)" : "launchApplicationWithPresetToken",
    "accessCode/:code" : "launchApplicationWithPresetAccessCode",
    "adRef/:code" : "launchApplicationWithPresetAdReference",
    "onStripeOnboardingCompleted": "onStripeOnboardingCompleted",
    "onStripeOnboardingInterupted": "onStripeOnboardingInterupted",
    "onStripePurchaseSuccessfull": "onStripePurchaseSuccessfull",
    "onStripePurchaseCancelled": "onStripePurchaseCancelled",
    "onSubscriptionPaymentCompleted": "onSubscriptionPaymentCompleted",
    "onPaymentSetupCompleted": "onPaymentSetupCompleted",
    "confirmPaymentSetupAndResumeCreatorPlanUpgrade": "confirmPaymentSetupAndResumeCreatorPlanUpgrade",
    "confirmPaymentSetupAndRedirectToSettingsPage": "confirmPaymentSetupAndRedirectToSettingsPage",
    "confirmUpgradeCreatorSubscriptionAndRedirectToCreateShortcastPage": "confirmUpgradeCreatorSubscriptionAndRedirectToCreateShortcastPage",
    "confirmPaymentMethodUpdateAndRedirectToCreateShortcastPage": "confirmPaymentMethodUpdateAndRedirectToCreateShortcastPage",
    "doLoginOnly_:timestamp": "doLoginOnly",
    "trebbleSetup": "showTrebbleSetupPage",
    "channelSetup": "showTrebbleSetupPage",
    "journeyCreation":"showJourneyCreationWizardPage",
    "redeem(/:code)(/accessCode/:accessCode)": "showRedeemCodePage",
    "upgradeCreator(/accessCode/:accessCode)": "showDefaultPageAndUpgradeCreatorSubscriptionPopup",
    "subscribeTo/:trebbleId": "showSubscribeToTrebblePage",
    "createACapsule" :  _buildRouteInfo("showCreateACapsulePageWithoutContext", true, false, -1, false, true),
    "createShortcast" :  _buildRouteInfo("showCreateACapsulePageWithoutContext", true, false, -1, false, true),
    "editAudioFile": _buildRouteInfo("createShortcastByUploadingFile", true, false, -1, false, true),
    "recordAndEdit": _buildRouteInfo("recordAudioAndEdit", true, false, -1, false, true),
    "signUpAndRecord": "showCreateACapsulePageWithoutContext",
    "ask/:receiverUsername(/r/:referrer)(/ctk/:customTrackingKey)" :  _buildRouteInfo("showSendCapsuleToUserByUsernameIntroPage", true, false, -1, false, true),
    "profile/:trebbleCustomUrl(/r/:referrer)" : _buildRouteInfo("showPublicRadioPageByCustomUrl", true, false, -1, true, true),
    "send_cmail/:receiverUserId(/r/:referrer)(/ctk/:customTrackingKey)" :  _buildRouteInfo("showSendCapsuleToUserByUserIdIntroPage", true, false, -1, false, true),
    "send_cmail_to/:receiverUsername(/r/:referrer)(/ctk/:customTrackingKey)" :  _buildRouteInfo("showSendCapsuleToUserByUsernameIntroPage", true, false, -1, false, true),
    "GetStartedForCuratorsPage" : _buildRouteInfo("showHelpPage", true, false, -1, false),
    "afterSignup" : "showDefaultPageAfterSignup",
    "tad":  _buildRouteInfo("testAudioEditor", true, false, -1, false, true),
    "audioEditorDemo":  _buildRouteInfo("testAudioEditor", true, false, -1, false, true),
    "/": "showDefaultPage",
    "": "showDefaultPage",
    //"*trebbleCustomUrl" : _buildRouteInfo("preloadTrebbleViaCustomUrl", true, false, -1, true, true),
    "*trebbleCustomUrl" : _buildRouteInfo("showPublicRadioPageByCustomUrl", true, false, -1, true, true),
};

var DEFAULT_PAGES = {"ListenerOnboarding":1, "MySubscriptionPage": 2, "MyPersonalRadioPage": 3,"ExplorePage": 4,"HomePage": 5, "JourneyPage": 6, "MyLibraryPage": 7};

var pageNamePrefixAlwasInDark = [];
pageNamePrefixAlwasInDark.push("albumDetailsPage_");
pageNamePrefixAlwasInDark.push("artistDetailsPage_");
pageNamePrefixAlwasInDark.push("artistFullDetailsPage_");
pageNamePrefixAlwasInDark.push("player");
pageNamePrefixAlwasInDark.push("p/p/t");
pageNamePrefixAlwasInDark.push("playCapsuleFeed");
pageNamePrefixAlwasInDark.push("playJourneyTrailer");
pageNamePrefixAlwasInDark.push("p/p/j");
pageNamePrefixAlwasInDark.push("p/l/t");
pageNamePrefixAlwasInDark.push("p/l/j");
pageNamePrefixAlwasInDark.push("p/p/c");
pageNamePrefixAlwasInDark.push("p/l/c");
pageNamePrefixAlwasInDark.push("p/p/s");
pageNamePrefixAlwasInDark.push("p/l/s");
pageNamePrefixAlwasInDark.push("PlaylistPodcastsPage");
pageNamePrefixAlwasInDark.push("Onboarding");
pageNamePrefixAlwasInDark.push("public_");
pageNamePrefixAlwasInDark.push("journey_");
pageNamePrefixAlwasInDark.push("journeyPreview_");
pageNamePrefixAlwasInDark.push("journeyManagePage_");
pageNamePrefixAlwasInDark.push("j/");
pageNamePrefixAlwasInDark.push("shortcast_");
pageNamePrefixAlwasInDark.push("trebble_");
pageNamePrefixAlwasInDark.push("user_");
pageNamePrefixAlwasInDark.push("u_");
pageNamePrefixAlwasInDark.push("createCapsuleForSongPage_");
pageNamePrefixAlwasInDark.push("createAShortcastPage_");
pageNamePrefixAlwasInDark.push("createShortcastFromTranscription_");
pageNamePrefixAlwasInDark.push("createShortcastFromProject_");
pageNamePrefixAlwasInDark.push("createACapsule");

var WHATS_A_CAPSULE_CAPSULE_ID = "5af865b1527d480013dc38c4";
var TMA_CAPSULE_ACCESS_CODE ="2E62-E3SR-33FI";
var TREBBLE_WEB_APP_HOME_PAGE = "https://web.trebble.fm";
var DISPLAY_LISTENING_INSTRUCTIONS_AFTER_USER_IS_SUBSCRIBED = false;
var ENABLE_WELCOME_POPUP = false;
var ALWAYS_SHOW_MY_SUBS_PAGE_BY_DEFAULT_ON_MOBILE_APP = true;


var Rollout = RolloutHelper.getInstance();
var REDIRECT_USER_TO_BROWSE_PAGE_IF_USER_HAS_NO_SUBSCRIPTION_AND_IT_IS_FIRST_SESSION = Rollout.isFeatureEnabled(Rollout.FEATURES.REDIRECT_TO_BROWSE_PAGE_AFTER_LOGIN_IF_NO_SUBSCRIPTION);
var SHOW_SUGGESTION_TO_LISTEN_TO_FEED = Rollout.isFeatureEnabled(Rollout.FEATURES.SHOW_SUGGESTION_TO_LISTEN_TO_FEED);
var SHOW_SUGGESTION_TO_LISTEN_TO_FEED_ON_FIRST_SUB = Rollout.isFeatureEnabled(Rollout.FEATURES.SHOW_SUGGESTION_TO_LISTEN_TO_FEED_ON_FIRST_SUBSCRIPTION);
var NUMBER_OF_SUBSCRIPTIONS_TO_SUGGEST_TO_PLAY_FEED_FOR_FIRST_TIME = Rollout.getFeatureVariable(Rollout.FEATURES.SHOW_SUGGESTION_TO_LISTEN_TO_FEED, Rollout.FEATURES.SHOW_SUGGESTION_TO_LISTEN_TO_FEED.variables.min_number_of_subscriptions_to_suggest, 3);
var SHOW_SETUP_FEED_STEP_TO_NEW_USERS = Rollout.isFeatureEnabled(Rollout.FEATURES.DISPLAY_FEED_SETUP_AFTER_SIGNUP);
var NUMBER_OF_MINIMUN_SUBS_TO_COMPLETE_SETUP = Rollout.getFeatureVariable(Rollout.FEATURES.DISPLAY_FEED_SETUP_AFTER_SIGNUP, Rollout.FEATURES.DISPLAY_FEED_SETUP_AFTER_SIGNUP.variables.number_of_subs_to_complete_setup, 1);
var NUMBER_OF_MINIMUN_JOURNEY_SUBS_TO_COMPLETE_SETUP = Rollout.getFeatureVariable(Rollout.FEATURES.DISPLAY_FEED_SETUP_AFTER_SIGNUP, Rollout.FEATURES.DISPLAY_FEED_SETUP_AFTER_SIGNUP.variables.number_of_journey_subs_to_complete_setup, 1);
var SHOW_FLOATING_PLAYER = Rollout.isFeatureEnabled(Rollout.FEATURES.SHOW_FLOATING_PLAYER);
var USE_FLOATING_PLAYER_ON_WEB = Rollout.getFeatureVariable(Rollout.FEATURES.SHOW_FLOATING_PLAYER, Rollout.FEATURES.SHOW_FLOATING_PLAYER.variables.use_floating_player_on_web);
var SHOW_FOOTER_MENU = Rollout.isFeatureEnabled(Rollout.FEATURES.SHOW_FOOTER_MENU);
var SHOW_FOOTER_MENU_ON_WEB = Rollout.getFeatureVariable(Rollout.FEATURES.SHOW_FOOTER_MENU, Rollout.FEATURES.SHOW_FOOTER_MENU.variables.show_on_web_app);
var ALWAYS_DISPLAY_HELP  =  Rollout.getFeatureVariable(Rollout.FEATURES.SHOW_FOOTER_MENU, Rollout.FEATURES.SHOW_FOOTER_MENU.variables.always_display_help);
var DARK_MODE_FEATURE_AVAILABLE =Rollout.isFeatureEnabled(Rollout.FEATURES.DARK_MODE);
var ENABLE_JOURNEY_CREATION = Rollout.isFeatureEnabled(Rollout.FEATURES.ENABLE_JOURNEY_CREATION);
//var OPEN_STRIPE_CHECKOUT_CONFIG_FOR_JOURNEY_NEW_TAB_ON_WEB =  Rollout.getFeatureVariable(Rollout.FEATURES.JOURNEY_PURCHASE_CONFIG, Rollout.FEATURES.JOURNEY_PURCHASE_CONFIG.variables.open_stripe_checkout_in_new_window_on_web);

var ENABLE_TOOLBAR_COLOR_CHANGE_ON_HASH_CHANGE = Rollout.isFeatureEnabled(Rollout.FEATURES.CHANGE_TOOLBAR_COLOR_ON_PAGE_CHANGE);
var SHOW_WARNING_WITH_NUMBER_OF_FREE_HOURS_LEFT_OF_TRANSCRIPTION = Rollout.isFeatureEnabled(Rollout.FEATURES.SHOW_WARNING_WITH_NUMBER_OF_FREE_HOURS_LEFT_OF_TRANSCRIPTION);

var DARK_MODE_BODY_CLASSNAME ="darkTheme";




//var routeWithGuestLoginAllowed = ["*trebbleCustomUrl" ];
//COPIED FROM BACKBONE
// Convert a route string into a regular expression, suitable for matching
// against the current location hash.
var optionalParam = /\((.*?)\)/g;
var namedParam    = /:\w+/g;
var splatParam    = /\*\w+/g;
const newLocal = /[-{}[\]+?.,\\^$|#\s]/g;
var escapeRegExp  = newLocal;
var _routeToRegExp =  function(route) {
    route = route.replace(escapeRegExp, '\\$&')
    .replace(optionalParam, '(?:$1)?')
    .replace(namedParam, '([^/]+)')
    .replace(splatParam, '(.*?)');
    return new RegExp('^' + route + '$');
}


var buildRouteMapSummary = function(){
    var routeRegexMappedToGuestLoginAllowed = [];
    var backboneMap = {};
    for(let route  in routeMapInfo){
        var info = routeMapInfo[route];
        var isGuestLoginAllowed = false;
        var loadPlayerQueue = false;
        var isReferrerPassedInUrl = false;
        var isAccessCodePreset = false;
        var allowEasySignupForListenersAtLogin = false;
        var referrerArgumentIndex = -1;
        var validRegex = null;
        var functionName = null;
        if(!(typeof info === 'string' || info instanceof String)){
            isGuestLoginAllowed = info.isGuestLoginAllowed;
            functionName = info.functionName;
            loadPlayerQueue = info.loadPlayerQueue;
            isReferrerPassedInUrl = info.isReferrerPassedInUrl;
            referrerArgumentIndex = info.referrerArgumentIndex;
            isAccessCodePreset =  info.isAccessCodePreset;
            allowEasySignupForListenersAtLogin =  info.allowEasySignupForListenersAtLogin;
        }else{
            isGuestLoginAllowed = false;
            functionName = info;
        }
        if(route instanceof RegExp){
            validRegex = route;
        }else{
            validRegex = _routeToRegExp(route+"(/)") ;// Adding forward slash since this is a valid case and some time for deeplink to work properly with cordova, a forward slash need to be added at the end
        }
        backboneMap[route] = functionName;
        if(route && route.charAt(0) != "*" && route.charAt(0) != "/" ){
            backboneMap["/"+route] = functionName;
        }
        routeRegexMappedToGuestLoginAllowed.push({"regex":validRegex, "isGuestLoginAllowed" :isGuestLoginAllowed,"loadPlayerQueue": loadPlayerQueue,"isReferrerPassedInUrl": isReferrerPassedInUrl ,"referrerArgumentIndex": referrerArgumentIndex,"isAccessCodePreset": isAccessCodePreset, "allowEasySignupForListenersAtLogin": allowEasySignupForListenersAtLogin});
    }
    return {"backboneMap":backboneMap, "routeRegexMappedToGuestLoginAllowed": routeRegexMappedToGuestLoginAllowed};
}
var routeMapSummary = buildRouteMapSummary();



var Router =  Backbone.Router.extend({

    routes: routeMapSummary.backboneMap,

    controllers: [],

    currentController : null ,

    initialize : function(options){
        _.extend(this, Backbone.Events);
        //this.songs = options.songs;
        //this.albums = options.albums;
        //this.artists = options.artists;
        //this.defaultTags = options.defaultTags;
        //this.defaultGroupTags = options.defaultGroupTags;
        //this.myRadioInfos =  options.myRadioInfos;
        //this.myFollowerInfos =  options.myFollowerInfos;
        //this.myFollowingsInfos =  options.myFollowingsInfos;
        //this.allMyCapsuleInfosArray = options.allMyCapsuleInfosArray;
        //this.autoPlaylistSummariesJsonArray = options.autoPlaylistSummaries;
        //this.myFollowedRadioInfoArray = options.allMyFollowedRadioInfoArray;

        this._numberOfSubscriptionsWhenTheSessionStarted = options.allMyFollowedRadioInfoArray ? options.allMyFollowedRadioInfoArray.length: 0;
        this._userStartedTheSessionWithNoSubscription = this._numberOfSubscriptionsWhenTheSessionStarted == 0;
        if(LocalStorageHelper.getInstance().isCurrentLoggedUserNotAVisitor()){
            if(this._userStartedTheSessionWithNoSubscription){
                if(window.trebbleAnalyticsHelper){
                    window.trebbleAnalyticsHelper.trackEvent("subscription", 'startedSessionWithNoSubscription', 'Started session with no subscription');
                }
            }else{
                if(window.trebbleAnalyticsHelper){
                    window.trebbleAnalyticsHelper.trackEvent("subscription", 'startedSessionWithSubscription', 'Started session with subscriptions',this._userStartedTheSessionWithNoSubscription,{"numberOfSubscriptions": this._userStartedTheSessionWithNoSubscription});
                }
            }
        }
        PersistentModels.getInstance().setRouter(this);
        this.initializeServices(options);
        this.baseUrl = window.getAppBaseUrl();
        this.canPlayerFooterBeShowOnActivePage = false;
        this._viewportSizeChangedListeners = {};
        this._viewportSizeChangedListenersPopups = {};
        this._browserHistoryLength = window.history.length;
        this._pageIdToPageElOffDomCache = {};
        this._remiderToShareTrebbleDisplayedToUser = false;
        this._remiderToCreateACapsuleDisplayedToUser = false;
        this._isMobileAPpThemeDetectorAvailable =  false;
        this._appSystemInDarkMode = false;
        this._isDisplayingFirstPage =  true;
        this._firstPageUrl =window.location.href;
        this.setAppropriateAppTheme();
        document.addEventListener("resume", this._retrieveAppSystemThemeAndSetAppropirateTheme.bind(this), false);
        window.addEventListener('orientationchange', this._onViewportHeightChanged.bind(this));
        window.addEventListener('popstate', this._changeStatusBarOnHashchange.bind(this));
        window.addEventListener('resize', this._onViewportHeightChanged.bind(this));
        if(window.matchMedia){
            window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', this.setAppropriateAppTheme.bind(this));
        }
        $(document).on('click',"[data-rel='back']", (function() {
            this._isBrowserHistoryGoingBack =true;
            window.history.back();
            return false;
        }).bind(this));

        /*window.onbeforeunload = (function (e) {
            if(window.trebble && window.trebble.globalHelpers && window.trebble.globalHelpers.playerModel){
                window.trebble.globalHelpers.playerModel._saveMusicPlaybackTrackedTimeIfApplicable(true);
            }

            if(window.waitForCordovaToLoad && window.isIOSMobileDevice){
                this.reloadApp();
            }
        }).bind(this);*/

        window.addEventListener('beforeunload', (function () {
            if(window.trebble && window.trebble.globalHelpers && window.trebble.globalHelpers.playerModel){
                window.trebble.globalHelpers.playerModel._saveMusicPlaybackTrackedTimeIfApplicable(true);
            }

/*if(window.waitForCordovaToLoad && window.isIOSMobileDevice){
    this.reloadApp();
}*/
}).bind(this));

    },

    _changeStatusBarOnHashchange : function(){
        if(ENABLE_TOOLBAR_COLOR_CHANGE_ON_HASH_CHANGE){
            this.setAppropriateAppTheme(true);
        }
    },



    _retrieveAppSystemThemeAndSetAppropirateTheme:function(){
        return this._updateDarkModeAppDefaultSettings().then((function(){
            return this.setAppropriateAppTheme();
        }).bind(this));
    },



    initializeServices : function(options){
        PersistentModels.getInstance().setAllDefaultGroupTags(options.defaultGroupTags)
        PersistentModels.getInstance().setAllDefaultTags(options.defaultTags);
        PersistentModels.getInstance().setSongCollection(options.songs);
        PersistentModels.getInstance().setAlbumCollection(options.albums);
        PersistentModels.getInstance().setArtistCollection(options.artists);
        PersistentModels.getInstance().setAutoPlaylistSummaries(options.autoPlaylistSummaries);
        PersistentModels.getInstance().setAllMyPersonalRadioInfos(options.myRadioInfos);
        PersistentModels.getInstance().setAllMyCapsuleInfos(options.allMyCapsuleInfosArray);
        PersistentModels.getInstance().setMyFollowersInfo(options.myFollowerInfos);
        PersistentModels.getInstance().setMyFollowingsInfo(options.myFollowingsInfos);
        PersistentModels.getInstance().setMyFollowedRadioInfoArray(options.allMyFollowedRadioInfoArray);
        PersistentModels.getInstance().setPlayer(new PlayerModel());
        this.listenTo(LocalStorageHelper.getInstance(), LocalStorageHelper.getInstance().eventTypes.USER_INFO_UPDATED, this._onUserInfoUpdatedInLocalStorage.bind(this));
        this.listenTo(PersistentModels.getInstance(), "playlistSummarySubsciptionChanged", this.onPlaylistSummarySubsciptionChanged);
        this.listenTo(PersistentModels.getInstance(), "journeySummarySubsciptionChanged", this.onPlaylistSummarySubsciptionChanged);
        this.listenTo(PersistentModels.getInstance(), "beforeSubscribeToTrebble", this.onBeforeSubscribeToTrebble);
        this._onUserInfoUpdatedInLocalStorage(LocalStorageHelper.getInstance().getUserInfo());
// PersistentModels.getInstance().initializeOtherPersistentCollections();
// SongIdentificationService.getInstance().listenToOnSongChangePlayerEvent(PersistentModels.getInstance().getPlayer());
this._registerForKeyboardEventIfApplicable();
this._initializeProgressiveAppHelper();
},
initializeOtherPersistentCollections : function(){
PersistentModels.getInstance().initializeOtherPersistentCollections();
},


initializePersistenPages : function(){
return this._initializeLoginController().then((function(){
    return this._initializeHomeMenuPanelController();
}).bind(this)).then((function(){
    var promises = [];
    promises.push(this._initializePlayerController());
    promises.push(this._initializeFreeMinutesLeftWarningControllerIfApplicable());
    // promises.push(this._initializePlayerFooterController());
    // promises.push(this._initializePlaylistPodcastController());
    //  promises.push(this._initializeMyLibrary());
    return RSVP.Promise.all(promises);
}).bind(this));
},

require : function (arrayOfFilesToLoad, doNotShowLoading) {
if (!doNotShowLoading) {
  $.mobile.loading("show");
}

return new Promise((resolve, reject) => {
  try {
    const loadedFiles = [];

    // Define contexts for each of the directories
    const contexts = {
      controllers: require.context('./controllers', true, /\.js$/),
      models: require.context('./models', true, /\.js$/),
      views: require.context('./views', true, /\.js$/),
      services: require.context('./services', true, /\.js$/),
      components: require.context('./components', true, /\.js$/),
    };

    // Iterate through arrayOfFilesToLoad
    arrayOfFilesToLoad.forEach((fileToLoad) => {
      // Check which directory the file belongs to
      let context;
      let fileToLoadBasedOnContext;
      if (fileToLoad.startsWith("controllers/")) {
        context = contexts.controllers;
        fileToLoadBasedOnContext = fileToLoad.substr("controllers/".length);
      } else if (fileToLoad.startsWith("models/")) {
        context = contexts.models;
        fileToLoadBasedOnContext = fileToLoad.substr("models/".length);
      } else if (fileToLoad.startsWith("views/")) {
        context = contexts.views;
        fileToLoadBasedOnContext = fileToLoad.substr("views/".length);
      } else if (fileToLoad.startsWith("components/")) {
        context = contexts.views;
        fileToLoadBasedOnContext = fileToLoad.substr("components/".length);
      } else if (fileToLoad.startsWith("services/")) {
        context = contexts.services;
        fileToLoadBasedOnContext = fileToLoad.substr("services/".length);
      }

      if (context) {
        try {
          const module = context(`./${fileToLoadBasedOnContext}.js`);
          loadedFiles.push(module.default);
        } catch (error) {
          // Handle errors here
          console.error(`Error loading module '${fileToLoad}':`, error);
        }
      } else {
        // Handle unsupported directories or file paths
        console.error(`Unsupported file path: '${fileToLoad}'`);
      }
    });

    if (!doNotShowLoading) {
      $.mobile.loading("hide");
    }

    resolve(loadedFiles[0]);

  } catch (error) {
    if (!doNotShowLoading) {
      $.mobile.loading("hide");
    }

    reject(error);
  }
});
},


isDarkModeOn: function(){
if(DARK_MODE_FEATURE_AVAILABLE){
    /*if(LocalStorageHelper.getInstance().wasDarkModeManuallyConfigured()){
        if(LocalStorageHelper.getInstance().isAppConfiguredToBeInDarkMode()){
            return true;
        }else{
            return false;
        }
    }else{*/
        if(LocalStorageHelper.getInstance().isAppConfiguredToBeAlwaysInDarkMode()){
            return RSVP.Promise.resolve(true);
        }
        if(window.waitForCordovaToLoad){
            var is_default_in_app = Rollout.getFeatureVariable(Rollout.FEATURES.DARK_MODE, Rollout.FEATURES.DARK_MODE.variables.is_default_in_app);
            if(is_default_in_app){
                return RSVP.Promise.resolve(true);
            }
        }else{
            var is_default_in_web = Rollout.getFeatureVariable(Rollout.FEATURES.DARK_MODE, Rollout.FEATURES.DARK_MODE.variables.is_default_in_web);
            if(is_default_in_web){
                return RSVP.Promise.resolve(true);
            }
        }
        //}
        if(window.waitForCordovaToLoad){
            return this._updateDarkModeAppDefaultSettings().catch(function(error){console.error(error)}).then((function(){
                return this._appSystemInDarkMode;
            }).bind(this))
        }else{
            if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                return RSVP.Promise.resolve(true);
            }else{
                return RSVP.Promise.resolve(false);
            }
        }
    }
    return RSVP.Promise.resolve(false);
},

setAppropriateAppTheme : function(doNotTriggerDarkModeEvent){
    try{
        this.isDarkModeOn().then((function(darkMode){
            this._setAppInDarkMode(darkMode, doNotTriggerDarkModeEvent);
        }).bind(this))
    }catch(error){
        console.error(error);
    }
},

_setAppInDarkMode: function(darkMode, doNotTriggerDarkModeEvent){
    try{
        var body$el = $("body");
        if(darkMode){
            if(!body$el.hasClass(DARK_MODE_BODY_CLASSNAME)){
                body$el.addClass(DARK_MODE_BODY_CLASSNAME)
            }
        }else{
            body$el.removeClass(DARK_MODE_BODY_CLASSNAME)
        }


        var pageNameInUrl = window.getPathNameWithoutHtmlPage();
        var statusBarColorShouldBeDark = darkMode;
        if(pageNameInUrl && pageNameInUrl.length > 1){


            for(let i =0; i < pageNamePrefixAlwasInDark.length; i++){
                var pageNamePrefix = pageNamePrefixAlwasInDark[i];
                if(pageNameInUrl.substr(1).indexOf(pageNamePrefix) == 0){
                    statusBarColorShouldBeDark = true;
                    break;
                }
            }


        }else{
            if(this._defaultPageShownInSession == DEFAULT_PAGES.ListenerOnboarding 
                || this._defaultPageShownInSession == DEFAULT_PAGES.MyPersonalRadioPage 
                || this._defaultPageShownInSession == DEFAULT_PAGES.ExplorePage
                || this._defaultPageShownInSession == DEFAULT_PAGES.JourneyPage
                || this._defaultPageShownInSession == DEFAULT_PAGES.MyLibraryPage
                ){
                statusBarColorShouldBeDark = true;
        }
    }

    $("meta[name=theme-color]").attr("content",statusBarColorShouldBeDark?"#000000":"#FFFFFF");
    if("StatusBar" in window){
        if(statusBarColorShouldBeDark){
            window.StatusBar.backgroundColorByHexString("#000000");
            window.StatusBar.styleLightContent();
        }else{
            window.StatusBar.backgroundColorByHexString("#FFFFFF");
            window.StatusBar.styleDefault();
        }
    }
    if(doNotTriggerDarkModeEvent){
        this.trigger("onDarkMode", darkMode);
    }
    const event = new CustomEvent('darkMode', {
        darkMode
      });
    window.dispatchEvent(event);
}catch(error){
    console.error(error);
}	
},


_updateDarkModeAppDefaultSettings: function(){
if(window.waitForCordovaToLoad && window.cordova.plugins && window.cordova.plugins.ThemeDetection){
    return new RSVP.Promise(function(resolve, reject){
        window.cordova.plugins.ThemeDetection.isAvailable( resolve,reject)
    }).then((function(responseIsAvailable){
        this._isMobileAPpThemeDetectorAvailable = responseIsAvailable && responseIsAvailable.value;
        if(this._isMobileAPpThemeDetectorAvailable){
            return new RSVP.Promise(function(resolve, reject){
                window.cordova.plugins.ThemeDetection.isDarkModeEnabled( resolve,reject)
            })
        }
    }).bind(this)).then((function(isDarkModeEnabledResponse){
        if(isDarkModeEnabledResponse && isDarkModeEnabledResponse.value){
            this._appSystemInDarkMode = true;
        }else{
            this._appSystemInDarkMode = false;
        }
        return this._appSystemInDarkMode;
    }).bind(this)).catch(function(error){
        console.error(error);
    })
}else{
    return RSVP.Promise.resolve();
}
},

navigate : function(url,triggerHashChange, options){
if(triggerHashChange){
    this._isBrowserHistoryGoingBack = false;
    this._currentPageUrl = url;
    Backbone.Router.prototype.navigate.call(this,window.trebble.supportPushState?"/"+ url: "#"+ url, options)
}else{
    this._isBrowserHistoryGoingBack = true;
}

},
_onUserInfoUpdatedInLocalStorage : function(userInfo){
$("body").attr("isTemporaryUser", userInfo ? userInfo.temporary: null);
$("body").attr("userProfileType", userInfo ? userInfo.profileType: null);
$("body").attr("journey_feature_enabled", ENABLE_JOURNEY_CREATION || LocalStorageHelper.getInstance().isLoggedInUserAJourneyCreator());

},

_registerForKeyboardEventIfApplicable : function(){
/*KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent("shift+s",(function(){
    if(Utils.getInstance().isTokenValid()){
        this.showGlobalSearchPage(null, true);
    }
}).bind(this));
KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent("shift+e",(function(){
    if(Utils.getInstance().isTokenValid()){
        this.showExplorePage(null, true);
    }
}).bind(this));
KeyboardKeyHandlerHelper.getInstance().registerForKeyboardEvent("shift+h",(function(){
    if(Utils.getInstance().isTokenValid()){
        this.showHelpPopup();
    }
}).bind(this));*/

},

_initializeProgressiveAppHelper : function(){
ProgressiveAppHelper.getInstance().registerEventForUpdatingManifestAndHtmlMeta(PersistentModels.getInstance().getPlayer())
},

showHelpPopup : function(){
//window.alertErrorMessage("help Coming soon!");
},

pushPageElToOffDomCache : function(pageId, pageEl$){
this._pageIdToPageElOffDomCache[pageId] = pageEl$;
},

popPageElToOffDomCacheIfApplicable : function(pageId){
var pageEl$ = this._pageIdToPageElOffDomCache[pageId];
if(pageEl$){
    delete this._pageIdToPageElOffDomCache[pageId];
    $('body').append(pageEl$);
}
return pageEl$;
},

isPageWithIdOffDomCached : function (pageId) {
return !!this._pageIdToPageElOffDomCache[pageId];
},

getCurrentPageUrl : function(){
return  this._currentPageUrl;
},

isEqualToCurrentPageUrl : function(pageUrlToCompareWith){
if(this._currentPageUrl == pageUrlToCompareWith){
    return true;
}
if(pageUrlToCompareWith || this._currentPageUrl){
    if(pageUrlToCompareWith &&  this._currentPageUrl && pageUrlToCompareWith.startsWith("#") && !this._currentPageUrl.startsWith("#")){
        return pageUrlToCompareWith == "#"+ this._currentPageUrl;
    }
    if(pageUrlToCompareWith &&  this._currentPageUrl && !pageUrlToCompareWith.startsWith("#") && this._currentPageUrl.startsWith("#")){
        return "#"+pageUrlToCompareWith == this._currentPageUrl;
    }
}
return false;
},

isBrowserHistoryGoingBack : function(){
return this._isBrowserHistoryGoingBack;
},
changePage : function(pageHash, silent){
if(silent)
{
    window.location.replace(window.getPageFragmentUrl(pageHash)) ;
}else{
    window.location.assign(window.getPageFragmentUrl(pageHash));
}
},

//Overriding the default method of the backbone router to make sure that parameters exracted by the router decoded automatically
_extractParameters: function(route, fragment) {
var argsExtracted =  route.exec(fragment).slice(1);
try{
    if(argsExtracted && argsExtracted.length > 0){
        var decodedArgs =  [];
        for(let i=0 ; i < argsExtracted.length; i++){
            decodedArgs.push(decodeURIComponent(argsExtracted[i]));
        }
        return decodedArgs;
    }else{
        return argsExtracted;
    }
}catch(error){
    console.error(error);
    return argsExtracted;
}
},

_addToBrowserHistory: function( pageHash){
window.location.assign(window.getPageFragmentUrl(pageHash));
},

_replaceCurrentBrowserHistory: function( pageHash){
window.location.replace(window.getPageFragmentUrl(pageHash));
},


navigateToPreviousPage : function(){
this._isBrowserHistoryGoingBack = true;
if(window.waitForCordovaToLoad && window.history.length == 1){
    return this.showDefaultPage(true);
}else{
    window.history.back(); 
    return RSVP.Promise.resolve(); 
}

},

updateWindowLocationWithNewProjectId : function(projectId){
history.replaceState(null, '',"/"+encodeURIComponent("createShortcastFromProject_")+projectId);
},

isFirstPageDisplayed : function(){
return this._isDisplayingFirstPage;
},

isCurrentPageHasSameUrlAsFirstPage : function(){
return this._firstPageUrl ===  window.location.href;
},

getFirstPageUrl : function(){
return this._firstPageUrl;
},

navigateToPageWithId : function(pageId,pageLabel, canShowPlayerFooter,triggerHashChange, pageElementToAppendToBody$el, panelMethodToCall, playerFooterTransparent, useTransition, customTransition, replaceBrowserHistoryWithPageid){
let t0 = (new Date()).getTime();
$.mobile.loading("show");
triggerHashChange = triggerHashChange || false;
let isNavatigatingToPagePromise = window.trebble.pageContainerReadyPromise.then((function(){
    this._previousPageVisitedId = this._currentPageId;
    this._previousPageVisitedLabel = this._currentPageLabel;
    this._previousPageNavigationTimestamp =  this._currentPageNavigationTimestamp;
    if(pageElementToAppendToBody$el && pageElementToAppendToBody$el.attr("appendedToBody") != true){
        $('body').append(pageElementToAppendToBody$el);
        pageElementToAppendToBody$el.trigger("pageAppendedToBody")
        pageElementToAppendToBody$el.attr("appendedToBody", true);
    }
    this.popPageElToOffDomCacheIfApplicable(pageId);
    this.setCanPlayerFooterBeShowOnActivePage(canShowPlayerFooter, playerFooterTransparent);
    this.navigate(pageId, triggerHashChange);
    //if(triggerHashChange){
        this._isDisplayingFirstPage = false;
    //}
    let t1 = (new Date()).getTime();
    window.log("Time taken for setting up page transition  for "+ pageLabel+" took " + (t1 - t0) + " milliseconds.");
    if(window.trebbleAnalyticsHelper){
        window.trebbleAnalyticsHelper.trackTiming("Page Transition",  (t1 - t0), "Time taken for setting up page transition  for "+ pageLabel, "Time taken for setting up page transition  for "+ pageLabel) ;
    }
    let t2 = (new Date()).getTime();
    this._slidePage(triggerHashChange?"left":"right");
    if(useTransition){
        $.mobile.changePage("#"+pageId.replace(/\//g , "\\/"),{changeHash:false, "reverse": triggerHashChange?false: true ,"transition": "trebbleCustomPageTransition"});
    }else{
        $.mobile.changePage("#"+pageId.replace(/\//g , "\\/"),{changeHash:false });
    }
    if(triggerHashChange){
        this._changeStatusBarOnHashchange();
    }
    var t3 = (new Date()).getTime();
    $.mobile.resetActivePageHeight(); 
    window.log("Time to make the actual page transition  for "+ pageLabel+" took " + (t3 - t2) + " milliseconds.");
    if(window.trebbleAnalyticsHelper){
        window.trebbleAnalyticsHelper.trackTiming("Page Transition",  (t3 - t2), "Time to make the actual page transition  for "+ pageLabel, "Time to make the actual page transition  for "+ pageLabel) ;
        /*if(window.waitForCordovaToLoad){
            //track page show since page tracking does not seem to work with Google Analytics
            window.trebbleAnalyticsHelper.trackEvent("PageShow", pageLabel, pageLabel);
        }*/

    }
    var t4 = (new Date()).getTime();
    if(panelMethodToCall ){

        this.trigger("panelMenuMethodCall", panelMethodToCall)
        if(this.getHomeMenuPanelController() && this.getHomeMenuPanelController().getView() && this.getHomeMenuPanelController().getView()[panelMethodToCall]){
            this.getHomeMenuPanelController().getView()[panelMethodToCall]();
            var METHOD_FOR_WHICH_NO_DESELECT = ["setMySubsciptionsPageAsSelectedMenu","setHomePageAsSelectedMenu","setExplorePageAsSelectedMenu"];
            if(this.getFooterMenuBarController() && METHOD_FOR_WHICH_NO_DESELECT.indexOf(panelMethodToCall) == -1){
                this.getFooterMenuBarController().deselectSelectedMenu();
            }
        }else{
            this._initializeHomeMenuPanelController().then((function(){
                if(this.getHomeMenuPanelController() && this.getHomeMenuPanelController().getView() && this.getHomeMenuPanelController().getView()[panelMethodToCall]){
                    this.getHomeMenuPanelController().getView()[panelMethodToCall]();
                    if(this.getFooterMenuBarController()){
                        this.getFooterMenuBarController().deselectSelectedMenu();
                    }
                }
            }).bind(this));
            
        }
    }

    $.mobile.resetActivePageHeight(canShowPlayerFooter? window.getDocumentClienHeight() +60: null); // work around to resolve issue where with the footer transparent, the current might not be tall enough
    this._currentPageId  = pageId;
    this._currentPageLabel = pageLabel;
    this._currentPageNavigationTimestamp =  (new Date()).getTime();
    if(window.trebbleAnalyticsHelper){
        //var timeSpentInMs =  this._previousPageNavigationTimestamp?(this._currentPageNavigationTimestamp -  this._previousPageNavigationTimestamp) : null;
        window.trebbleAnalyticsHelper.trackView(pageLabel);
        //let label;
        /*if(timeSpentInMs > 0){
            var category = "Time Spent On Page";
            if(triggerHashChange){
                label = " Time spent on '" + this._previousPageVisitedLabel + "' before going to '" + this._currentPageLabel +"'"; 
            }else{
                label = " Time spent on '" + this._previousPageVisitedLabel + "' before returning  to page'" + this._currentPageLabel +"'";
            }
            if(false){
                window.trebbleAnalyticsHelper.trackTiming(category, timeSpentInMs, 'none', label) ;
            }
        }*/

    } 
    if(replaceBrowserHistoryWithPageid){
        history.replaceState(null, '', window.location.search?"/"+pageId+window.location.search: "/"+pageId);
    }
    var t5 = (new Date()).getTime();
    $.mobile.loading("hide");
    window._trebbleIsNavatigatingToPagePromise = null;
    window.log("Time to doing postTransition code execution for page transition  for "+ pageLabel+" took " + (t5 - t4) + " milliseconds.");
    if(window.trebbleAnalyticsHelper){
        window.trebbleAnalyticsHelper.trackTiming("Page Transition", (t5 - t4) , "PostTransition code execution for page transition  for "+ pageLabel, "PostTransition code execution for page transition  for "+ pageLabel) ;
    }
}).bind(this)).catch((function(error){
    $.mobile.loading("hide");
    window._trebbleIsNavatigatingToPagePromise = null;
    throw error;
}).bind(this));
window._trebbleIsNavatigatingToPagePromise = isNavatigatingToPagePromise;
return isNavatigatingToPagePromise;

},

updateActivePageHeightToMatchPlayerFooterHeight : function(){
if(this.getPlayerFooterController()){
    this.getPlayerFooterController().updateActivePageHeightToMatchPlayerFooterHeight();
}
if(this.getPlayerFloatingController()){
    //this.getPlayerFloatingController().updateActivePageHeightToMatchPlayerFooterHeight();
}
if(this.getFooterMenuBarController()){
    this.getFooterMenuBarController().updateActivePageHeightToMatchFooterMenuHeight();
}
},

_slidePage : function() {
/* if( window.plugins && window.plugins.nativepagetransitions){
    window.plugins.nativepagetransitions.slide(
    {
        'direction': direction
    },
    function () {
        window.log('slide transition finished');
    });
}*/
},
setupOffDomCachingOnPage : function(pageId, pageEl$, offDomCaching){
if(offDomCaching){
    pageEl$.on("pagehide", (function(){
        if(!PersistentModels.getInstance().getRouter().isBrowserHistoryGoingBack())
        {
            pageEl$.remove();
            this.pushPageElToOffDomCache(pageId, pageEl$);
        }
    }).bind(this));
}
},

isDefaultPageBeingDisplayed : function(){
return !!this._defaultPageGettingDisplayedPromise;
},

getisDefaultPageBeingDisplayedPromise: function(){
return this._defaultPageGettingDisplayedPromise;
},

didSignUpOccuredInCurrentSession : function(){
return window.accountSignupHasOccuredInSession;
},

/*
showDefaultPageOld : function(triggerHashChange){
var defaultPageGettingDisplayedPromise = null;
if(LocalStorageHelper.getInstance().isLoggedInUserAListener() ||(ALWAYS_SHOW_MY_SUBS_PAGE_BY_DEFAULT_ON_MOBILE_APP && window.waitForCordovaToLoad)){
    if(REDIRECT_USER_TO_BROWSE_PAGE_IF_USER_HAS_NO_SUBSCRIPTION_AND_IT_IS_FIRST_SESSION && (this.didSignUpOccuredInCurrentSession() || PersistentModels.getInstance().getNumberOfSubscriptions() == 0)){
        defaultPageGettingDisplayedPromise = this.showListenerOnboardingPage(null, triggerHashChange);
    }else{
        defaultPageGettingDisplayedPromise = this.showMySubscriptionsPage(triggerHashChange);
    }
}else{
    if(this._getDefaultPageIsBeingDisplayedPromise()){
        defaultPageGettingDisplayedPromise = this._getDefaultPageIsBeingDisplayedPromise();
    }else{
        var p = this.showMyPersonalTrebbleDetailPage(triggerHashChange);
        this._setDefaultPageIsBeingDisplayedPromise(p);
        p.then((function(){
            this._setDefaultPageIsBeingDisplayedPromise(null);
            this._initalizeNotifications();
        }).bind(this));
defaultPageGettingDisplayedPromise = p;

}
}
this._defaultPageGettingDisplayedPromise =  defaultPageGettingDisplayedPromise;
defaultPageGettingDisplayedPromise.then((function(){
//page has been displayed
this._defaultPageGettingDisplayedPromise = null;

}).bind(this)).catch(function(error){
this._defaultPageGettingDisplayedPromise = null;
throw error;
})
return this._defaultPageGettingDisplayedPromise;
},
*/
showDefaultPage : function(triggerHashChange, playFeed){
var defaultPageGettingDisplayedPromise = null;


if(!this._defaultPageShownInSession){
    if(LocalStorageHelper.getInstance().isLoggedInUserAListener() ||(ALWAYS_SHOW_MY_SUBS_PAGE_BY_DEFAULT_ON_MOBILE_APP && window.waitForCordovaToLoad)){
        if(SHOW_SETUP_FEED_STEP_TO_NEW_USERS  && (this.didSignUpOccuredInCurrentSession() || (PersistentModels.getInstance().getNumberOfSubscriptions() < NUMBER_OF_MINIMUN_SUBS_TO_COMPLETE_SETUP && PersistentModels.getInstance().getNumberOfJourneySubscriptions() < NUMBER_OF_MINIMUN_JOURNEY_SUBS_TO_COMPLETE_SETUP ))){
            Rollout.trackActivation(Rollout.FEATURES.DISPLAY_FEED_SETUP_AFTER_SIGNUP);
            this._defaultPageShownInSession = DEFAULT_PAGES.ListenerOnboarding;
        }else{
            if(REDIRECT_USER_TO_BROWSE_PAGE_IF_USER_HAS_NO_SUBSCRIPTION_AND_IT_IS_FIRST_SESSION && (this.didSignUpOccuredInCurrentSession() || PersistentModels.getInstance().getNumberOfSubscriptions() == 0)){
                this._defaultPageShownInSession = DEFAULT_PAGES.ExplorePage;
            }
        }
        if(!this._defaultPageShownInSession){
            if(RolloutHelper.getInstance().getFeatureVariable(RolloutHelper.FEATURES.ADD_MY_FEED_PAGE, RolloutHelper.FEATURES.ADD_MY_FEED_PAGE.variables.should_be_default_page)){
                this._defaultPageShownInSession = DEFAULT_PAGES.HomePage;
            }else{
                this._defaultPageShownInSession = DEFAULT_PAGES.MySubscriptionPage;
            }
        }
    }else{
        if(LocalStorageHelper.getInstance().isLoggedInUserAShortcastreator()){
            this._defaultPageShownInSession = DEFAULT_PAGES.MyLibraryPage;
        }else{
            if(LocalStorageHelper.getInstance().isLoggedInUserAJourneyCreator()){
                this._defaultPageShownInSession = DEFAULT_PAGES.JourneyPage;
            }else{
                this._defaultPageShownInSession = DEFAULT_PAGES.MyPersonalRadioPage;
            }
        }
    }
}

if(this._getDefaultPageIsBeingDisplayedPromise()){
    return this._getDefaultPageIsBeingDisplayedPromise();
}else{
    switch (this._defaultPageShownInSession) {
        case DEFAULT_PAGES.ListenerOnboarding:
        defaultPageGettingDisplayedPromise = this.showListenerOnboardingPage(null, triggerHashChange);
        break;
        case DEFAULT_PAGES.ExplorePage:
        defaultPageGettingDisplayedPromise = this.showExplorePage(null, triggerHashChange);
        break;
        case DEFAULT_PAGES.MySubscriptionPage:
        defaultPageGettingDisplayedPromise = this.showMySubscriptionsPage(triggerHashChange, playFeed);
        break;
        case DEFAULT_PAGES.HomePage:
        defaultPageGettingDisplayedPromise = this.showNewHomePage(triggerHashChange, playFeed);
        break;
        case DEFAULT_PAGES.MyPersonalRadioPage:
        defaultPageGettingDisplayedPromise = this.showMyPersonalTrebbleDetailPage(triggerHashChange);
        break;
        case DEFAULT_PAGES.JourneyPage:
        defaultPageGettingDisplayedPromise = this.showMyJourneysPage(triggerHashChange);
        break;
        case DEFAULT_PAGES.MyLibraryPage:
        defaultPageGettingDisplayedPromise = this.showMyLibraryPage(null, null, triggerHashChange);
        break;
    }
    this._setDefaultPageIsBeingDisplayedPromise(defaultPageGettingDisplayedPromise);
    
    defaultPageGettingDisplayedPromise.then((function(){
        //page has been displayed
        this._defaultPageShownInSession = null;
        window._trebbleDefaultPageIsBeingDisplayed = null;
        this._setDefaultPageIsBeingDisplayedPromise(null);
    }).bind(this)).catch((function(error){
        this._defaultPageShownInSession = null;
        window._trebbleDefaultPageIsBeingDisplayed = null;
        this._setDefaultPageIsBeingDisplayedPromise(null);
        throw error;
    }).bind(this))
    window._trebbleDefaultPageIsBeingDisplayed = defaultPageGettingDisplayedPromise;
    return defaultPageGettingDisplayedPromise;
    
}

},


_initalizeNotifications : function(){
if(window.waitForCordovaToLoad && (!window.isIOSMobileDevice || LocalStorageHelper.getInstance().isLoggedInUserManuallyAgreedToTurnOnNotification())){
    PushNotificationHelper.getInstance().initNativePushNotification();
}
},

showDefaultPageAfterSignup : function(triggerHashChange){
return this.showDefaultPage( triggerHashChange).then((function(){
    document.body.style["background-color"]="rgba(17,17,17,1)";
    document.body.style["border-color"]="rgba(17,17,175,1)";
    $("#loadingBoxWidget").css("display","none");
    if(navigator.splashscreen){
        navigator.splashscreen.hide();
    }
    if(!LocalStorageHelper.getInstance().isLoggedInUserAListener()){
        this.showWelcomeNewUserPopupIfApplicable();
    }else{
        this.showWelcomeNewListenerPopupIfApplicable();
    }

}).bind(this));
},

onBeforeSubscribeToTrebble: function(){
var numberOfSubscriptions = PersistentModels.getInstance().getNumberOfSubscriptions();
if(SHOW_SUGGESTION_TO_LISTEN_TO_FEED_ON_FIRST_SUB && numberOfSubscriptions == 0 && this.isUserStartedTheSessionWithNoSubscription()){
    //This done to make the app more responsive and not wait for the subscription to complete which might take more time
    //We are proactively congratulating the user for adding a subscription
    return PersistentModels.getInstance().getRouter().showFirstSubscriptionAddedHighfivePopupIfApplicable();
}
},

onPlaylistSummarySubsciptionChanged: function(){
var numberOfSubscriptions = PersistentModels.getInstance().getNumberOfSubscriptions();
/*if(SHOW_SUGGESTION_TO_LISTEN_TO_FEED_ON_FIRST_SUB && numberOfSubscriptions == 1 && this.isUserStartedTheSessionWithNoSubscription()){
    return PersistentModels.getInstance().getRouter().showFirstSubscriptionAddedHighfivePopupIfApplicable();
}*/
if(SHOW_SUGGESTION_TO_LISTEN_TO_FEED){
    if(numberOfSubscriptions == NUMBER_OF_SUBSCRIPTIONS_TO_SUGGEST_TO_PLAY_FEED_FOR_FIRST_TIME && this.isUserStartedTheSessionWithNoSubscription()){
        return PersistentModels.getInstance().getRouter().showSuggestionToListenToFeedForFirstTimeWasDisplayedPopupIfApplicable();
    }
}

},

showMyPersonalTrebbleDetailPage : function(triggerHashChange){
var radioIds  = PersistentModels.getInstance().getAllMyPersonalRadioIds();
if(radioIds && radioIds.length > 0){
    return this.showMyPersonalRadioDetailPageeByRadioId(radioIds[0],triggerHashChange).then((function(myPersonalRadioDetailsController){
        if(this.getHomeMenuPanelController() && this.getHomeMenuPanelController().getView()){
            this.getHomeMenuPanelController().getView().setTrebblesPageAsSelectedMenu();
        }else{
            
            this._initializeHomeMenuPanelController().then((function(){
                if(this.getHomeMenuPanelController()){
                    this.getHomeMenuPanelController().getView().setTrebblesPageAsSelectedMenu();
                }
            }).bind(this));
            
        }
        if(myPersonalRadioDetailsController){
            this.setMyPersonalRadioDetailsController(myPersonalRadioDetailsController);
        }
    }).bind(this));
}else{
    window.alertErrorMessage(window.getI18n(ti18n, "TREBBLE_INFO_COULDNT_BE_FOUND"));
}
},

setMyPersonalRadioDetailsController: function(myPersonalRadioDetailsController){
this._myPersonalRadioDetailsController = myPersonalRadioDetailsController;
},

getMyPersonalRadioDetailsController: function(){
return this._myPersonalRadioDetailsController;
},

_changeToolBarColorToMatchPopupOpenAndChangeColorBackWhenItCloses : function(controller){
if(ENABLE_TOOLBAR_COLOR_CHANGE_ON_HASH_CHANGE && controller && controller.view && controller.view.$el && "StatusBar" in window){
    try{
        $("meta[name=theme-color]").attr("content","#000000");
        if(window.StatusBar){
            window.StatusBar.backgroundColorByHexString("#000000");
            window.StatusBar.styleLightContent();
        }
        controller.view.$el.on("popupafterclose",(function(){
            this.setAppropriateAppTheme(true);
        }).bind(this))
    }catch(error){
        console.error(error);
    }
}
},

renderPopupAndDisplayPage : function(controller,pageLabel, doNotShowLoading){
let t0 = (new Date()).getTime();
if(!doNotShowLoading){
    $.mobile.loading("show");
}
//let t2  = null;
return controller.buildModelAndRenderView().then((function(){
    let t1 = (new Date()).getTime();
    window.log("Creating controller for "+ pageLabel+" took " + (t1 - t0) + " milliseconds.");
    if(window.trebbleAnalyticsHelper){
        window.trebbleAnalyticsHelper.trackTiming("Page Transition",  (t1 - t0), "Creating controller for "+ pageLabel, "Creating controller for "+ pageLabel) ;
    }
    //t2 = (new Date()).getTime();
    return window.trebble.pageContainerReadyPromise;
}).bind(this)).then((function(){
    if(window.trebbleAnalyticsHelper){
        window.trebbleAnalyticsHelper.trackView(pageLabel);
    } 
    if(!doNotShowLoading){
        $.mobile.loading("hide");
    }
    //this._changeToolBarColorToMatchPopupOpenAndChangeColorBackWhenItCloses(controller)
    return controller;
}).bind(this)).catch(function(error){
    console.error("An error occured while displaying '"+ pageLabel+"'.Error:"+ error, error);
    if(!doNotShowLoading){
        $.mobile.loading("hide");
    }
    window.alertErrorMessage(error);
});
},

renderAndDisplayPage : function(controller,pageId,pageLabel, canShowPlayerFooter,triggerHashChange, panelMethodToCall, playerFooterTransparent, useTransition,replaceBrowserHistoryWithPageid){
let t0 = (new Date()).getTime();
let t2  = null;
$.mobile.loading("show");
let  pageBeingRenderPromise =  controller.buildModelAndRenderView().then((function(){
    let t1 = (new Date()).getTime();
    window.log("Creating controller for "+ pageLabel+" took " + (t1 - t0) + " milliseconds.");
    if(window.trebbleAnalyticsHelper){
        window.trebbleAnalyticsHelper.trackTiming("Page Transition",  (t1 - t0), "Creating controller for "+ pageLabel, "Creating controller for "+ pageLabel) ;
    }
    t2 = (new Date()).getTime();
    return window.trebble.pageContainerReadyPromise;
}).bind(this)).then((function(){
    this.router.setupOffDomCachingOnPage(pageId,this.controller.getView().$el,false );
    this.router.navigateToPageWithId(pageId, pageLabel, canShowPlayerFooter, triggerHashChange,this.controller.getView().$el,panelMethodToCall ,playerFooterTransparent, useTransition, null, replaceBrowserHistoryWithPageid);
    let t3 = (new Date()).getTime();
    $.mobile.loading("hide");
    window.log("making page transition  for "+ pageLabel+" took " + (t3 - t2) + " milliseconds.");
    if(window.trebbleAnalyticsHelper){
        window.trebbleAnalyticsHelper.trackTiming("Page Transition",  (t3 - t2), "making page transition  for "+ pageLabel, "making page transition  for "+ pageLabel) ;
    }
    window._trebblePageBeingRenderPromise = null;
    return controller;
}).bind({"controller":controller, "router": this})).catch(function(error){
    console.error("An error occured while displaying '"+ pageLabel+"'.Error:"+ error, error);
    $.mobile.loading("hide");
    window.alertErrorMessage(error);
    window._trebblePageBeingRenderPromise = null;
});
window._trebblePageBeingRenderPromise = pageBeingRenderPromise;
return pageBeingRenderPromise;
},



showAlbumDetailPage : function(albumId, albumModelOrJSON, triggerHashChange){
triggerHashChange = triggerHashChange || false;
var albumModel =  null;
if(albumModelOrJSON)
{
    if(albumModelOrJSON instanceof Album)
    {
        albumModel = albumModelOrJSON;
    }else{
        var exisitingAlbumModel = PersistentModels.getInstance().getAlbumModelByAlbumIdFromMyLibrary(albumModelOrJSON.albumId);
        if(!exisitingAlbumModel)
        {
            albumModel = Utils.getInstance().getModelFromAlbumJson(albumModelOrJSON);
        }else{
            albumModel = exisitingAlbumModel;
        }
    }
}else{
    albumModel =   PersistentModels.getInstance().getAlbumModelByAlbumIdFromMyLibrary(albumId);
}
var pageId =  encodeURIComponent(albumId?"albumDetailsPage_"+ albumId: "albumDetailsPage_" +  albumModel.get("albumId"));
var pageName =  "Album Details Page ";
//var hashChangeUrl = "#"+ pageId;
if(this.isJQMPageWithIdExist(pageId)){
    this.navigateToPageWithId( pageId,pageName  , true,triggerHashChange,null, null, true);
    return RSVP.Promise.resolve();
}else{

    var albumDetailPageControllerParams = {};
    albumDetailPageControllerParams.model = albumModel;
    albumDetailPageControllerParams.pageId = pageId;
    albumDetailPageControllerParams.albumId = albumId;
    albumDetailPageControllerParams.displayLocalSongAtFirstRendering = false;//(albumModel && albumModel.get("_buildOnServer") == true)? false: true;
    return this.require(["controllers/albumDetail/AlbumDetailController"]).then((function(AlbumDetailController){
        var albumDetailController  = new AlbumDetailController(albumDetailPageControllerParams);
        return this.renderAndDisplayPage(albumDetailController,pageId,pageName, true,triggerHashChange,null, true);
    }).bind(this));
}

},


showGlobalSearchPage : function(searchKeyword, triggerHashChange){
triggerHashChange = triggerHashChange || false;
var pageId = "globalSearch";
var pageName =  "Global Search Page";
//var hashChangeUrl = "#"+ pageId;
if(searchKeyword){
    //hashChangeUrl = hashChangeUrl + "/" + searchKeyword;
}
if(this.isGlobalSearchControllerInitialized())
{
    this.navigateToPageWithId( pageId,pageName, true,triggerHashChange, null, "setSearchPageAsSelectedMenu", false);
    if(searchKeyword){
        this.getGlobalSearchController().setSearchValue(searchKeyword);
    }
}else{

    return this.require(["controllers/globalSearch/GlobalSearchController"]).then((function(GlobalSearchController){
        var controllerParams = {};
        var globalSearchController  = new GlobalSearchController(controllerParams);
        return this.renderAndDisplayPage(globalSearchController,pageId,pageName, true,triggerHashChange,"setSearchPageAsSelectedMenu").then((function(){
            this.router.setGlobalSearchController(this.globalSearchController);
            this.router.setGlobalSearchControllerInitialized(true);
            if(searchKeyword){
                this.globalSearchController.setSearchValue(searchKeyword);
            }
        }).bind({"globalSearchController" :globalSearchController, "router": this}));
    }).bind(this));

}
},

showHelpAndFeedback : function(){
LiveChatWidgetHelper.getInstance().displayMessenger();
if(window.trebbleAnalyticsHelper){
    window.trebbleAnalyticsHelper.trackView('Show Help And FeedBack');
}
},

showExplorePage : function(presetCategoryId, triggerHashChange){
triggerHashChange = triggerHashChange || false;
var pageId = "ExplorePage";
var pageName =  "Explore Page";
//var hashChangeUrl = "#"+ pageId;
if(this.isExploreControllerInitialized())
{
    return this.isDarkModeOn().then((function(darkMode){
        return this.navigateToPageWithId( pageId,pageName, true, triggerHashChange, null, "setExplorePageAsSelectedMenu", this.isFooterMenuUsedInApp()?false: darkMode);
    }).bind(this))
    
}else{
    this.showExplorePageWelcomeMessageIfApplicable();
    return this.isDarkModeOn().then((function(darkMode){
        return this.require(["controllers/explore/ExplorePageController"]).then((function(ExplorePageController){
            var controllerParams = {};
            controllerParams.presetCategoryId = (presetCategoryId && presetCategoryId != "undefined")? presetCategoryId: null;
            var explorePageController  = new  ExplorePageController(controllerParams);
            return this.renderAndDisplayPage(explorePageController,pageId,pageName, true,triggerHashChange,"setExplorePageAsSelectedMenu", this.isFooterMenuUsedInApp()?false: darkMode).then((function(){
                this.router.setExploreController(this.explorePageController);
                this.router.setExploreControllerInitialized(true);
            }).bind({"explorePageController" :explorePageController, "router": this}));
        }).bind(this));
    }).bind(this))

}
},

showListenerOnboardingPage :  function(presetCategoryId, triggerHashChange){
triggerHashChange = triggerHashChange || false;
var pageId = "Onboarding";
var pageName =  "Onboarding Listener Page";
//var hashChangeUrl = "#"+ pageId;
if(this.isListenerOnboardingControllerInitialized())
{
    return this.navigateToPageWithId( pageId,pageName, false,triggerHashChange, null, null, true);
}else{
    this.showExplorePageWelcomeMessageIfApplicable();
    return this.require(["controllers/onboarding/OnboardingListenerController"]).then((function(OnboardingListenerController){
        var controllerParams = {};
        controllerParams.presetCategoryId = (presetCategoryId && presetCategoryId != "undefined")? presetCategoryId: null;
        var onboardingListenerController  = new  OnboardingListenerController(controllerParams);
        return this.renderAndDisplayPage(onboardingListenerController,pageId,pageName, false,triggerHashChange,null, true).then((function(){
            this.router.setListenerOnboardingController(this.onboardingListenerController);
            this.router.setListenerOnboardingControllerInitialized(true);
        }).bind({"onboardingListenerController" :onboardingListenerController, "router": this}));
    }).bind(this));

}
},

showReferralPage : function(presetCategoryId, triggerHashChange){
triggerHashChange = triggerHashChange || false;
var pageId = "ReferalPage";
var pageName =  "Referal Page";
//var hashChangeUrl = "#"+ pageId;
if(this.isReferalPageControllerInitialized())
{
    this.navigateToPageWithId( pageId,pageName, true,triggerHashChange, null, "setReferPageAsSelectedMenu", false);
}else{
    return this.require(["controllers/referral/UserReferralPageController"]).then((function(UserReferralPageController){
        var controllerParams = {};
        controllerParams.pageId  = pageId;
        var userReferralPageController  = new  UserReferralPageController(controllerParams);
        return this.renderAndDisplayPage(userReferralPageController,pageId,pageName, true,triggerHashChange,"setReferPageAsSelectedMenu", false).then((function(){
            this.router.setReferralPageController(this.userReferralPageController);
            this.router.setReferalPageControllerInitialized(true);
        }).bind({"userReferralPageController" :userReferralPageController, "router": this}));
    }).bind(this));

}
},

showReactTestPage : function(triggerHashChange){
    triggerHashChange = triggerHashChange || false;
    var pageId = "reactTestPage";
    var pageName =  "React Test Page";
    //var hashChangeUrl = "#"+ pageId;

    if(this.isJQMPageWithIdExist(pageId)){
        this.navigateToPageWithId( pageId,pageName  , true,triggerHashChange,null, null, true);
        return RSVP.Promise.resolve();
    }else{

        return this.require(["controllers/reactTest/TrebbleReactTestPageController"]).then((function(TrebbleReactTestPageController){
            var trebbleReactTestPageController  = new TrebbleReactTestPageController({pageId});
            return this.renderAndDisplayPage(trebbleReactTestPageController,pageId,pageName, true,triggerHashChange,null, true);
        }).bind(this));
    }
  
    },

isUserStartedTheSessionWithNoSubscription : function(){
return this._userStartedTheSessionWithNoSubscription;
},

getNumberOfSubscriptionWhenSessionStarted :function(){
return this._numberOfSubscriptionsWhenTheSessionStarted;
},

showFeedAndNotificationPage : function( triggerHashChange){
triggerHashChange = triggerHashChange || false;
var pageId = "FeedAndActivityPage";
var pageName =  "Feed And Notification Page";
//var hashChangeUrl = "#"+ pageId;
if(this.isJQMPageWithIdExist(pageId)){
    this.navigateToPageWithId( pageId,pageName  , true,triggerHashChange,null, "setFeedAndNotificationsPageAsSelectedMenu", false);
    return RSVP.Promise.resolve();
}else{
    return this.require(["controllers/feedAndActivities/FeedAndActivitiesPageController"]).then((function(FeedAndActivitiesPageController){
        var controllerParams = {};
        var feedAndActivitiesPageController  = new  FeedAndActivitiesPageController(controllerParams);
        return this.renderAndDisplayPage(feedAndActivitiesPageController,pageId,pageName, true,triggerHashChange,"setFeedAndNotificationsPageAsSelectedMenu", false);
    }).bind(this));

}

},

showAddSongToTrebblePage : function(triggerHashChange){
triggerHashChange = triggerHashChange || false;
var pageId = "addSongToTrebblePage";
var pageName =  "Add Song To Trebble Page Extension";
//var hashChangeUrl = "#"+ pageId;
if(this.isJQMPageWithIdExist(pageId)){
    this.navigateToPageWithId( pageId,pageName  , true,triggerHashChange,null, null, false);
    return RSVP.Promise.resolve();
}else{
    return this.require(["controllers/addSongToTrebble/AddSongToTrebbleController"]).then((function(AddSongToTrebbleController){
        var controllerParams = {};
        var addSongToTrebbleController  = new  AddSongToTrebbleController(controllerParams);
        return this.renderAndDisplayPage(addSongToTrebbleController,pageId,pageName, true,triggerHashChange,null, false);
    }).bind(this));

}
},

showCapsulesPage : function( triggerHashChange){
triggerHashChange = triggerHashChange || false;
var pageId = "CapsulesPage";
var pageName =  "Capsules Page";
//var hashChangeUrl = "#"+ pageId;
if(this.isJQMPageWithIdExist(pageId)){
    this.navigateToPageWithId( pageId,pageName  , true,triggerHashChange,null, "setCapsulesPageAsSelectedMenu", false);
    return RSVP.Promise.resolve();
}else{
    return this.require(["controllers/capsulesPage/CapsulesPageController"]).then((function(CapsulesPageController){
        var controllerParams = {};
        var capsulesPageController  = new  CapsulesPageController(controllerParams);
        return this.renderAndDisplayPage(capsulesPageController,pageId,pageName, true,triggerHashChange,"setCapsulesPageAsSelectedMenu", false);
    }).bind(this));

}

},

showLocalGenrePlaylistDetailPageByGenreId  : function(genreId,triggerHashChange ){

triggerHashChange = triggerHashChange || false;
this.navigateToPageWithId( encodeURIComponent("localGenrePlaylistDetails_")+ genreId,"Personal User Genre Playlist Page", true,triggerHashChange, null, null,true);
},


showLocalGenrePlaylistDetailPage  : function(genrePlaylistSummaryModel,triggerHashChange ){

triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageId =encodeURIComponent("localGenrePlaylistDetails_"+ genrePlaylistSummaryModel.getLoaderKey());
controllerParams.pageId  = pageId;
controllerParams.playlistSummary  = genrePlaylistSummaryModel;
var pageName = "Personal User Genre Playlist Page";
return this.require(["controllers/myLibrary/GenrePlaylistDetailsController"]).then((function(GenrePlaylistDetailsController){
    var playlistDetailsController  = new GenrePlaylistDetailsController(controllerParams);
    return this.renderAndDisplayPage(playlistDetailsController,pageId,pageName, true,triggerHashChange, null,true);
}).bind(this));

},

newSignupPageForAudioEditor: function(accessCode,forwardUrl, triggerHashChange){
if(accessCode){
    accessCode = "WEBB-58D2-V8V";
}
return this.showNewSignupPage(accessCode,forwardUrl, triggerHashChange , null, null, null,null,  null, null, true);
},

showNewSignupPage  : function(accessCode,forwardUrl, triggerHashChange , signupApi, signupWithFacebookApi, signupWithGoogleApi,signupWithAppleApi,  onSignupSuccessfullyCompleted, isUserpreviouslyLoggedInAsGuest, signupAsShortcastCreator){
var pageId ="newSignupPage";
var pageName = "User Sign Up Page";
var decodedForwardUrl = forwardUrl?decodeURIComponent(forwardUrl): null;
var forwardAfterSignup = decodedForwardUrl? true:false;
if(this.isJQMPageWithIdExist(pageId)){
    this.navigateToPageWithId( pageId,pageName  , true,triggerHashChange);
    return RSVP.Promise.resolve();
}else{
    triggerHashChange = triggerHashChange || false;
    var controllerParams = {};
    controllerParams.accessCode =  accessCode;
    controllerParams.isUserpreviouslyLoggedInAsGuest = isUserpreviouslyLoggedInAsGuest;
    controllerParams.signupApi = signupApi? signupApi : TrebbleClientAPIHelper.getInstance().signup.bind(TrebbleClientAPIHelper.getInstance());
    controllerParams.signupWithFacebookApi = signupWithFacebookApi? signupWithFacebookApi : TrebbleClientAPIHelper.getInstance().signupWithFacebook.bind(TrebbleClientAPIHelper.getInstance());
    controllerParams.signupWithGoogleApi = signupWithGoogleApi? signupWithGoogleApi : TrebbleClientAPIHelper.getInstance().signupWithGoogle.bind(TrebbleClientAPIHelper.getInstance());
    controllerParams.signupWithAppleApi = signupWithAppleApi? signupWithAppleApi : TrebbleClientAPIHelper.getInstance().signupWithApple.bind(TrebbleClientAPIHelper.getInstance());
    controllerParams.hideBackButton = this.isFirstPageDisplayed();
    controllerParams.forwardAfterSignup = forwardAfterSignup;
    controllerParams.signupAsShortcastCreator = signupAsShortcastCreator;
    if(onSignupSuccessfullyCompleted){
        controllerParams.onSignupSuccessfullyCompleted = onSignupSuccessfullyCompleted;
    }
    if( window.opener && window.opener.postMessage){
        controllerParams.onSignupSuccessfullyCompleted =  function(userData){
            if(window.opener && window.opener.postMessage){
                var stringMessageToSend = TrebbleClientAPIHelper.getInstance().buildBrowserMessageStringToPost("signup", true, userData);
                window.opener.postMessage(stringMessageToSend, "https://s.trebble.fm");
            }
        };
    }
    if(forwardUrl && forwardUrl != "undefined"){
        controllerParams.onSignupSuccessfullyCompleted =  function(){
            window.location  = decodedForwardUrl;
        };
    }
    return this.require(["controllers/signup/SignUpController"]).then((function(SignUpController){
        var signUpController  = new SignUpController(controllerParams);
        return this.renderAndDisplayPage(signUpController,pageId,pageName, false,triggerHashChange);
    }).bind(this));
}

},

showHelpCenterPage : function(){
if(window.trebbleAnalyticsHelper){
    window.trebbleAnalyticsHelper.trackEvent("helpRedirect", "Open help center page", "Open help center page");
}
window.open("https://help.trebble.fm");
},

showFeatureRequestsPage : function(){
if(window.trebbleAnalyticsHelper){
    window.trebbleAnalyticsHelper.trackEvent("helpRedirect", "Open feature requests page", "Open feature requests page");
}
window.open("https://trebble.canny.io/feature-requests");
},

showBookOneOnOneSessionPage : function(){
if(window.trebbleAnalyticsHelper){
    window.trebbleAnalyticsHelper.trackEvent("helpRedirect", "Open book one-on-one session page", "Open book one-on-one session page");
}
window.open("https://calendly.com/armelbeaudry/15min");
},


showFullSignupPage  : function(accessCode,forwardUrl, triggerHashChange , signupApi,signupWithFacebookApi, signupWithGoogleApi,signupWithAppleApi, onSignupSuccessfullyCompleted, isUserpreviouslyLoggedInAsGuest){
var pageId ="onboardingSignupPage";
var pageName = "User Full Sign Up Page";
var decodedForwardUrl = forwardUrl?decodeURIComponent(forwardUrl): null;
var forwardAfterSignup = decodedForwardUrl? true:false;
if(this.isJQMPageWithIdExist(pageId)){
    this.navigateToPageWithId( pageId,pageName  , true,triggerHashChange, null, null, null, false);
    return RSVP.Promise.resolve();
}else{
    triggerHashChange = triggerHashChange || false;
    var controllerParams = {};
    controllerParams.accessCode =  accessCode;
    controllerParams.isUserpreviouslyLoggedInAsGuest = isUserpreviouslyLoggedInAsGuest;
    controllerParams.signupApi = signupApi? signupApi : TrebbleClientAPIHelper.getInstance().signup.bind(TrebbleClientAPIHelper.getInstance());
    controllerParams.signupWithFacebookApi = signupWithFacebookApi? signupWithFacebookApi : TrebbleClientAPIHelper.getInstance().signupWithFacebook.bind(TrebbleClientAPIHelper.getInstance());
    controllerParams.signupWithGoogleApi = signupWithGoogleApi? signupWithGoogleApi : TrebbleClientAPIHelper.getInstance().signupWithGoogle.bind(TrebbleClientAPIHelper.getInstance());
    controllerParams.signupWithAppleApi = signupWithAppleApi? signupWithAppleApi : TrebbleClientAPIHelper.getInstance().signupWithApple.bind(TrebbleClientAPIHelper.getInstance());
    controllerParams.hideBackButton = this.isFirstPageDisplayed();
    controllerParams.forwardAfterSignup = forwardAfterSignup;
    if(onSignupSuccessfullyCompleted){
        controllerParams.onSignupSuccessfullyCompleted = onSignupSuccessfullyCompleted;
    }
    if( window.opener && window.opener.postMessage){
        controllerParams.onSignupSuccessfullyCompleted =  function(userData){
            if(window.opener && window.opener.postMessage){
                var stringMessageToSend = TrebbleClientAPIHelper.getInstance().buildBrowserMessageStringToPost("signup", true, userData);
                window.opener.postMessage(stringMessageToSend, "https://s.trebble.fm");
            }
        };
    }
    //let routerReference = this;
    if((forwardUrl && forwardUrl != "undefined") || (accessCode && accessCode != "undefined")){
        controllerParams.onSignupSuccessfullyCompleted =  function(userData){
            window.trebble.accountSignupHasOccuredInSession = true;
            if((forwardUrl && forwardUrl != "undefined")){
                window.location  = decodedForwardUrl;
            }else{
                //assume that this first page the user sees so redirect to home page
                return TrebbleClientAPIHelper.getInstance().doLoginWithLoginPage(true, true, userData).then(function(){
                    return TrebbleClientAPIHelper.getInstance().getAllMyPersonalRadioInfos();
                }).then(function(radioInfoArray){
                    PersistentModels.getInstance().setAllMyPersonalRadioInfos(radioInfoArray);
                    window.location.href  = (window.location.protocol == "http:"||window.location.protocol == "https:")? location.origin + window.getAppBaseUrl() + "#"+"afterSignup" :window.getBaseUrlWithoutEndSlash()+"#"+"afterSignup";
                    //window.location.reload();
                }).catch(function(error){
                    console.error(error);
                    //Utils.getInstance().showErrorNotification(error);
                    window.sendErrorToRaygun(error);
                    window.location.reload();
                })
            }
        };
    }
    return this.require(["controllers/signup/FullSignUpController"]).then((function(FullSignUpController){
        var signUpController  = new FullSignUpController(controllerParams);
        return this.renderAndDisplayPage(signUpController,pageId,pageName, false,triggerHashChange, null, null, false);
    }).bind(this));
}

},


showLocalRatedPlaylistDetailPageByRatedPlaylistId  : function(ratedPlaylistId,triggerHashChange ){

triggerHashChange = triggerHashChange || false;
this.navigateToPageWithId( encodeURIComponent("localRatedPlaylistDetails_")+ ratedPlaylistId,"Personal User Rated Playlist Page", true,triggerHashChange, null,null,true);
},

showLocalRatedPlaylistDetailPage  : function(ratedPlaylistSummaryModel,triggerHashChange ){

triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageId = encodeURIComponent("localRatedPlaylistDetails_"+ratedPlaylistSummaryModel.getLoaderKey());
controllerParams.pageId  = pageId;
controllerParams.playlistSummary  = ratedPlaylistSummaryModel;
var pageName = "Personal User Rated Playlist Page";
return this.require(["controllers/myLibrary/RatedPlaylistDetailsController"]).then((function(RatedPlaylistDetailsController){
    var playlistDetailsController  = new RatedPlaylistDetailsController(controllerParams);
    return this.renderAndDisplayPage(playlistDetailsController,pageId,pageName, true,triggerHashChange, null,true);
}).bind(this));

},
showAutoPlayTrebblePopup : function(userInfoJson, trebbleInfoSummaryJson, capsuleId, songId){
var params = {};
params.userInfoJson = userInfoJson;
params.capsuleId = capsuleId;
params.songId = songId;
params.trebbleInfoSummaryJson = trebbleInfoSummaryJson;
return this.require([ "controllers/autoPlaySuggestion/AutoPlayTrebbleAfterSharedCapsulePopupController"]).then((function(AutoPlayTrebbleAfterSharedCapsulePopupController){
    var autoPlayTrebbleAfterSharedCapsulePopupController = new AutoPlayTrebbleAfterSharedCapsulePopupController(params);
    return this.renderPopupAndDisplayPage (autoPlayTrebbleAfterSharedCapsulePopupController, "Auto Play Trebble Popup");
}).bind(this));
},


showProgressBarPopupController : function(){
return this.require([ "controllers/common/ProgressBarPopupController"]).then((function(ProgressBarPopupController){
    var progressBarPopupController = new ProgressBarPopupController({});
    return this.renderPopupAndDisplayPage (progressBarPopupController, "Show Progress Bar Popup");
}).bind(this));
},

executeFunctionAndShowProgressBarPopup : async function(actionToExecute, defaultProgressMessage){
    let result = null;
    let progressBarPopupController = null;
        const progressReportFunction = function (progress, operationInProgress){
            if (progressBarPopupController){
                if (progress){
                    progressBarPopupController.setIndeterminate(false);
                    progressBarPopupController.setProgressMessage(operationInProgress ? operationInProgress : defaultProgressMessage);
                    progressBarPopupController.setPercentageProgress(Math.round(progress) + "%");
                }else{
                    progressBarPopupController.setIndeterminate(true);
                    progressBarPopupController.setProgressMessage(operationInProgress ? operationInProgress : defaultProgressMessage);
                }
            }
        }
        try{
            progressBarPopupController = await this.showProgressBarPopupController();
            progressBarPopupController.setIndeterminate(true);
            progressBarPopupController.setProgressMessage(defaultProgressMessage);
            result = await actionToExecute(progressReportFunction);
            if(progressBarPopupController){
                progressBarPopupController.close();
            }
        }catch(error){
            if(progressBarPopupController){
                progressBarPopupController.close();
            }
        }
        return result;
},

showMessagePopup : function(message, messageDetails){
var params = {};
$.mobile.loading("show");
return this.require([ "views/common/OperationProgressPopupView"]).then((function(OperationProgressPopupView){
    $.mobile.loading("hide");
    var operationProgressPopupView = new OperationProgressPopupView(params);
    operationProgressPopupView.render();
    operationProgressPopupView.setSuccessMessage(message, messageDetails);
    return operationProgressPopupView;
}).bind(this)).catch(function(error){
    $.mobile.loading("hide");
    throw error;
});
},

showTrebbleSubscribeSuggestionPopup : function( personalRadioSummaryModel){
var params = {};
params.personalRadioSummaryModel = personalRadioSummaryModel;
return this.require([ "controllers/trebbleSubscribeSuggestion/TrebbleSubscribeSuggestionPopupController"]).then((function(TrebbleSubscribeSuggestionPopupController){
    var trebbleSubscribeSuggestionPopupController = new TrebbleSubscribeSuggestionPopupController(params);
    return this.renderPopupAndDisplayPage (trebbleSubscribeSuggestionPopupController, "Trebble Subscribe Suggestion Popup");
}).bind(this));
},

showSetupVoiceEnabledDevicePopup : function(){
var params = {};
/*if(window.trebbleAnalyticsHelper){
    window.trebbleAnalyticsHelper.trackEvent("PageShow", 'ShowSetupVoiceEnabledDevicePopup', 'Show Setup Voice Enabled Devivce Popup');
}*/
return this.require([ "controllers/setupVoiceEnabledDevices/SetupVoiceEnabledDevicesPopupController"]).then((function(SetupVoiceEnabledDevicesPopupController){
    var setupVoiceEnabledDevicesPopupController = new SetupVoiceEnabledDevicesPopupController(params);
    return this.renderPopupAndDisplayPage (setupVoiceEnabledDevicesPopupController, "Setup Voice Enabled Devices Popup");
}).bind(this));
},

showStartCategoryShortcastRadioPopup : function(){
var params = {};

return this.require([ "controllers/startCategoryShortcastRadio/StartCategoryShortcastRadioPopupController"]).then((function(StartCategoryShortcastRadioPopupController){
    var startCategoryShortcastRadioPopupController = new StartCategoryShortcastRadioPopupController(params);
    return this.renderPopupAndDisplayPage (startCategoryShortcastRadioPopupController, "Start Category Shortcast Radio Popup");
}).bind(this));
},

showChooseBackgroundMusicPopup : function(onBackgroundMusicSelected){
var params = {};
params.onBackgroundMusicSelected =  onBackgroundMusicSelected;
return this.require([ "controllers/configureBackgroundMusic/ConfigureBackgroundMusicPopupController"]).then((function(ConfigureBackgroundMusicPopupController){
    var configureBackgroundMusicPopupController = new ConfigureBackgroundMusicPopupController(params);
    return this.renderPopupAndDisplayPage (configureBackgroundMusicPopupController, "Choose Background Music Popup");
}).bind(this));
},


showChooseFreesoundSoundPopup : function(onFreesoundSoundSelected){
var params = {};
params.onTrebbleAudioSelected =  onFreesoundSoundSelected;
return this.require([  "models/helper/FreesoundHelper"]).then((function(FreesoundHelper){
    return this.require([ "controllers/chooseSound/ChooseSoundPopupController"]).then((function(ChooseSoundPopupController){
        params.getTrebbleAudiosLoadHandler = FreesoundHelper.getInstance().searchForSounds.bind(FreesoundHelper.getInstance());
        params.numberOfResultsPerPage = 21;
        var chooseSoundPopupController = new ChooseSoundPopupController(params);
        return this.renderPopupAndDisplayPage (chooseSoundPopupController, "Choose Freesound Audio Popup");
    }).bind(this));
}).bind(this));
},

showChooseMyInstantsSoundPopup : function(onMyInstantsSoundSelected){
var params = {};
params.onTrebbleAudioSelected =  onMyInstantsSoundSelected;
return this.require([  "models/helper/MyInstantsSoundSearchHelper"]).then((function(MyInstantsSoundSearchHelper){
    return this.require([ "controllers/chooseSound/ChooseSoundPopupController"]).then((function(ChooseSoundPopupController){
        params.getTrebbleAudiosLoadHandler = MyInstantsSoundSearchHelper.getInstance().searchForSounds.bind(MyInstantsSoundSearchHelper.getInstance());
        params.numberOfResultsPerPage = MyInstantsSoundSearchHelper.getInstance().getNumberOfResultsPerPage();
        var chooseSoundPopupController = new ChooseSoundPopupController(params);
        return this.renderPopupAndDisplayPage (chooseSoundPopupController, "Choose MyInstants Audio Popup");
    }).bind(this));
}).bind(this));
},


showChooseMyDraftJourneyPopup : function(onDraftJourneySelected, popupTitle){
return this.require([ "controllers/journey/ChooseMyDraftJourneyPopupController"]).then((function(ChooseMyDraftJourneyPopupController){
    var params = {};
    params.onJourneySelected = onDraftJourneySelected;
    params.popupTitle = popupTitle;
    var chooseMyDraftJourneyPopupController = new ChooseMyDraftJourneyPopupController(params);
    return this.renderPopupAndDisplayPage (chooseMyDraftJourneyPopupController, "Choose My Draft Journey Popup");
}).bind(this));
},

showDefaultPageAndUpgradeCreatorSubscriptionPopup: function(accessCode){
if(accessCode){
    window.trebble.presetAccessCode = accessCode;
}
return this.showDefaultPage().then((function(){
    return this.showUpgradeCreatorSubscriptionPopup();
}).bind(this));
},

showUpgradeCreatorSubscriptionPopup : function(returnUrlOnPaymentUpgradeCompleted, returnUrlOnPaymentMethodUpdated){
return this.require([ "controllers/upgradeCreatorSubscription/UpgradeCreatorSubscriptionPopupController"]).then((function(UpgradeCreatorSubscriptionPopupController){
    var params = {};
    params.returnUrlOnPaymentUpgradeCompleted = returnUrlOnPaymentUpgradeCompleted;
    params.returnUrlOnPaymentMethodUpdated = returnUrlOnPaymentMethodUpdated;
    var upgradeCreatorSubscriptionPopupController = new UpgradeCreatorSubscriptionPopupController(params);
    return this.renderPopupAndDisplayPage (upgradeCreatorSubscriptionPopupController, "Upgrade Creator Subscription Popup");
}).bind(this));
},

showReplacePaymentForCreatorSubscriptionPopup : function(returnUrlAfterSetupCompleted){
return this.require([ "controllers/upgradeCreatorSubscription/ReplacePaymentForCreatorSubscriptionPopupController"]).then((function(ReplacePaymentForCreatorSubscriptionPopupController){
    var params = {};
    params.returnUrlAfterSetupCompleted =  returnUrlAfterSetupCompleted;
    var replacePaymentForCreatorSubscriptionPopupController = new ReplacePaymentForCreatorSubscriptionPopupController(params);
    return this.renderPopupAndDisplayPage (replacePaymentForCreatorSubscriptionPopupController, "Replace Payment For Creator Subscription Popup");
}).bind(this));
},

showChooseCapsuleFromLibraryPopup : function(onCapsuleSelected, popupTitle, selectedCategoryId){
return this.require([ "controllers/capsule/ChooseCapsuleFromLibraryPopupController"]).then((function(ChooseCapsuleFromLibraryPopupController){
    var params = {};
    params.onCapsuleSelected = onCapsuleSelected;
    params.popupTitle =  popupTitle;
    params.selectedCategoryId = selectedCategoryId;
    var chooseCapsuleFromLibraryPopupController = new ChooseCapsuleFromLibraryPopupController(params);
    return this.renderPopupAndDisplayPage (chooseCapsuleFromLibraryPopupController, "Choose Capsule From Library Popup");
}).bind(this));
},


showChooseMusicSoundAndUploadsPopup : function(onTrebbleAudioSelected, hideMusicTab, moveBigBankSoundsTabFirst){
var params = {};
params.onTrebbleAudioSelected =  onTrebbleAudioSelected;
params.onTrebbleAudioUnselected = null;
//return this.require([  "models/helper/MyInstantsSoundSearchHelper"]).then((function(MyInstantsSoundSearchHelper){
    return this.require([  "models/helper/MyInstantsSoundFromClearVoiceSearchHelper"]).then((function(MyInstantsSoundSearchHelper){
        return this.require([  "models/helper/BigBankSoundSearchHelper"]).then((function(BigBankSoundSearchHelper){

            //return this.require([  "models/helper/FreesoundHelper"]).then((function(FreesoundHelper){
                return this.require([ "controllers/chooseMusicSoundsAndUploads/ChooseMusicSoundsAndUploadsPopupController"]).then((function(ChooseMusicSoundsAndUploadsPopupController){
                    params.getTrebbleAudioSoundLoadHandler = MyInstantsSoundSearchHelper.getInstance().searchForSounds.bind(MyInstantsSoundSearchHelper.getInstance());
                    params.getTrebbleAudioBigBankSoundLoadHandler = BigBankSoundSearchHelper.getInstance().searchForSounds.bind(BigBankSoundSearchHelper.getInstance());
                    params.numberOfResultsPerPageForSounds = MyInstantsSoundSearchHelper.getInstance().getNumberOfResultsPerPage();
                    params.numberOfResultsPerPageForBigBankSounds = BigBankSoundSearchHelper.getInstance().getNumberOfResultsPerPage();
                    params.hideMusicTab = hideMusicTab;
                    params.moveBigBankSoundsTabFirst =  moveBigBankSoundsTabFirst;


                    //params.numberOfResultsPerPageForSounds = 21;
                    //params.getTrebbleAudioSoundLoadHandler = FreesoundHelper.getInstance().searchForSounds.bind(FreesoundHelper.getInstance());


                    params.musicWidgetHeightMargin = 100;

                    var chooseMusicSoundsAndUploadsPopupController = new ChooseMusicSoundsAndUploadsPopupController(params);
                    return this.renderPopupAndDisplayPage (chooseMusicSoundsAndUploadsPopupController, "Choose Music, Sounds And Uploads Popup");
                }).bind(this));
            }).bind(this));
    }).bind(this));
},

showChooseUploadedVideosPopup : async function(onTrebbleVideoSelected){
    var params = {};
    params.onTrebbleVideoSelected =  onTrebbleVideoSelected;
    params.onTrebbleAudioUnselected = null;
    params.numberOfResultsPerPage = 20;
    const ChooseUploadedVideosPopupController = await this.require([ "controllers/chooseUploadedVideos/ChooseUploadedVideosPopupController"]);    
    const chooseUploadedVideosPopupController = new ChooseUploadedVideosPopupController(params);
    return await this.renderPopupAndDisplayPage (chooseUploadedVideosPopupController, "Choose User Video Uploads Popup");
                    
    },


showSelectAudioRegion : function(audioSegmentJson, onAudioSegmentRegionChanged, onAudioSegmentRegionNotChanged, audioUniqueId, audioType, sequencerSettings){
    return this.require([ "controllers/audioEditor/SelectAudioRegionPopupController"]).then((function(SelectAudioRegionPopupController){
        var params = {};
        params.audioSegmentJson =  audioSegmentJson;
        params.audioUniqueId = audioUniqueId;
        params.audioType = audioType;
        params.sequencerSettings = sequencerSettings;
        params.onRegionUpdated = onAudioSegmentRegionChanged;
        params.onRegionNotUpdated = onAudioSegmentRegionNotChanged;
        var selectAudioRegionPopupController = new SelectAudioRegionPopupController(params);
        return this.renderPopupAndDisplayPage (selectAudioRegionPopupController, "Select Audio Region Popup");
    }).bind(this));
},




addCapsuleToTrebbleViaPopup : function(capsuleModel, context, presetScheduledTime, changeScheduleTime, presetOnAirDurationInDays, presetTimezone){

    return new RSVP.Promise((function(resolve, reject){
        var params = {};
        params.capsuleModel = capsuleModel;
        params.radioIdsToAddCapsuleTo  = PersistentModels.getInstance().getAllMyPersonalRadioIds();
        params.onCapsuleAddedHandler = resolve;
        params.onCapsuleAddedErrorHandler = reject;
        params.presetScheduledTime = presetScheduledTime;
        params.changeScheduleTime = changeScheduleTime;
        params.presetOnAirDurationInDays =  presetOnAirDurationInDays;
        params.presetTimezone =  presetTimezone;
        params.context = context;
        if(LocalStorageHelper.getInstance().isUserHasCapsuleSchedulingFeature()){
            return this.require(["controllers/capsule/AddOrScheduleCapsuleToTrebblePopupController"]).then((function(AddOrScheduleCapsuleToTrebblePopupController){
                var addOrScheduleCapsuleToTrebblePopupController = new AddOrScheduleCapsuleToTrebblePopupController(params);
                return this.renderPopupAndDisplayPage (addOrScheduleCapsuleToTrebblePopupController, "Add Or Schedule Capsule To Trebble Via Popup");
            }).bind(this)).catch(reject);
        }else{
            return this.require(["controllers/capsule/AddCapsuleToTrebblePopupController"]).then((function(AddCapsuleToTrebblePopupController){
                var addCapsuleToTrebblePopupController = new AddCapsuleToTrebblePopupController(params);
                return this.renderPopupAndDisplayPage (addCapsuleToTrebblePopupController, "Add Capsule To Trebble Via Popup");
            }).bind(this)).catch(reject);
        }
    }).bind(this));

},


showRecordingSettingsPopup : function(){
    var params = {};

    return this.require([ "controllers/recordingSettings/RecordingSettingsPopupController"]).then((function(RecordingSettingsPopupController){
        var recordingSettingsPopupController = new RecordingSettingsPopupController(params);
        return this.renderPopupAndDisplayPage (recordingSettingsPopupController, "Show Recording Settings");
    }).bind(this));
},

publishJourneyViaPopup : function(journeyRadioId,journeyName, context, presetScheduledTime, changeScheduleTime, presetTimezone, onJourneyPublishHandler, onJourneyPublisErrorhHandler){

    return new RSVP.Promise((function(resolve, reject){
        var params = {};
    //params.capsuleModel = capsuleModel;
    params.journeyRadioId = journeyRadioId;
    params.journeyName = journeyName;
    params.radioIdsToAddCapsuleTo  = PersistentModels.getInstance().getAllMyPersonalRadioIds();
    params.onCapsuleAddedHandler = resolve;
    params.onCapsuleAddedErrorHandler = reject;
    params.presetScheduledTime = presetScheduledTime;
    params.changeScheduleTime = changeScheduleTime;
    params.presetTimezone =  presetTimezone;
    params.context = context;
    params.onJourneyPublishHandler = onJourneyPublishHandler;
    params.onJourneyPublisErrorhHandler = onJourneyPublisErrorhHandler;

    return this.require(["controllers/journey/PublishOrScheduleJourneyPopupController"]).then((function(PublishOrScheduleJourneyPopupController){
        var publishOrScheduleJourneyPopupController = new PublishOrScheduleJourneyPopupController(params);
        return this.renderPopupAndDisplayPage (publishOrScheduleJourneyPopupController, "Publish Journey Via Popup");
    }).bind(this)).catch(reject);
    
}).bind(this));

},

showChooseLanguageForTranscriptionPopup : async function(transcribeProxyFunction){
    const params = {};
    params.transcribeProxyFunction = transcribeProxyFunction;
    const ChooseTranscriptionLanguagePopupController = await this.require(["controllers/audioEditor/ChooseTranscriptionLanguagePopupController"]);
    var chooseTranscriptionLanguagePopupController = new ChooseTranscriptionLanguagePopupController(params);
    return this.renderPopupAndDisplayPage (chooseTranscriptionLanguagePopupController, "Choose language and transcribe Popup");
},

showMyPersonalRadioDetailPageeByRadioId  : function(radioId,triggerHashChange ){

    triggerHashChange = triggerHashChange || false;
    var pageId = encodeURIComponent("trebble_")+ radioId

    if(this.isJQMPageWithIdExist(pageId)){
        this.navigateToPageWithId( pageId,"Personal Trebble Details Page", true,triggerHashChange, null,null, true);
        return RSVP.Promise.resolve(this.getMyPersonalRadioDetailsController());
    }else{
        return TrebbleClientAPIHelper.getInstance().getMyTrebbleInfoByTrebbleId(radioId).then((function(radioJsonInfo){
            if(radioJsonInfo){
                var personalRadioSummaryModel = Utils.getInstance().buildPlaylistSummaryFromJson(radioJsonInfo);
                return this.showMyPersonalRadioDetailPage(personalRadioSummaryModel, triggerHashChange);
            }else{
                window.alertErrorMessage(window.getI18n(ti18n, "UNABLE_TO_FIND_THIS_TREBBLE"));
            }
        }).bind(this));
    }

},

showTrebbleSetupPage  : function(radioId,triggerHashChange ){
    triggerHashChange = triggerHashChange || false;
    var pageId = "trebbleSetup";
    if(!radioId){
        var radioIds  = PersistentModels.getInstance().getAllMyPersonalRadioIds();
        if(radioIds && radioIds.length){
            radioId =  radioIds[0];
        }
    }
    if(this.isJQMPageWithIdExist(pageId)){
        this.navigateToPageWithId( pageId,"Trebble Setup Page", false,triggerHashChange, null,null, false);
        return RSVP.Promise.resolve();
    }else{
        return TrebbleClientAPIHelper.getInstance().getMyTrebbleInfoByTrebbleId(radioId).then((function(radioJsonInfo){
            if(radioJsonInfo){
                var personalRadioSummaryModel = Utils.getInstance().buildPlaylistSummaryFromJson(radioJsonInfo);
                var controllerParams = {};
                controllerParams.trebbleModel  = personalRadioSummaryModel;
                controllerParams.hideBackButton = false;
            //var myPersonalRadioDetailsController = true;
            //controllerParams.onActionExecutedOnTrebbleHandler = myPersonalRadioDetailsController? myPersonalRadioDetailsController._onActionExecutedOnTrebbleHandler.bind(myPersonalRadioDetailsController): null;
            return this.require(["controllers/trebbleSetup/TrebbleSetupController"]).then((function(TrebbleSetupController){
                var trebbleSetupController  = new TrebbleSetupController(controllerParams);
                var pageName = "Trebble Setup Page";
                return this.renderAndDisplayPage(trebbleSetupController,pageId,pageName, false,triggerHashChange, null,true);
            }).bind(this));
        }else{
            window.alertErrorMessage(window.getI18n(ti18n, "UNABLE_TO_FIND_THIS_TREBBLE"));
        }
    }).bind(this));

    }

},

showJourneyCreationWizardPage  : function(triggerHashChange, onActionExecutedOnJourneyHandler ){
    triggerHashChange = triggerHashChange || false;
    var pageId = "journeyCreation";
    if(this.isJQMPageWithIdExist(pageId)){
        this.navigateToPageWithId( pageId,"Journey Creator Page", false,triggerHashChange, null,null, false);
        return RSVP.Promise.resolve();
    }else{
        var controllerParams = {};
        controllerParams.hideBackButton = false;
        controllerParams.onActionExecutedOnJourneyHandler = onActionExecutedOnJourneyHandler;
        return this.require(["controllers/journeyCreation/JourneyCreationWizardController"]).then((function(JourneyCreationWizardController){
            var journeyCreationWizardController  = new JourneyCreationWizardController(controllerParams);
            var pageName = "Journey Creation Page";
            return this.renderAndDisplayPage(journeyCreationWizardController,pageId,pageName, false,triggerHashChange, null,true);
        }).bind(this));
    }
},

showMyPersonalRadioDetailPage  : function(personalRadioSummaryModel,triggerHashChange ){

    triggerHashChange = triggerHashChange || false;
    var controllerParams = {};
    var pageId = encodeURIComponent("trebble_"+personalRadioSummaryModel.getLoaderKey());
    controllerParams.pageId  = pageId;
    controllerParams.playlistSummary  = personalRadioSummaryModel;
    controllerParams.doNotRemoveFromDocument = true;
    controllerParams.showBackButton = true;
    return this.require(["controllers/playlistPodcast/MyPersonalRadioDetailsController"]).then((function(MyPersonalRadioDetailsController){
        var playlistDetailsController  = new MyPersonalRadioDetailsController(controllerParams);
        var pageName = "Personal Trebble Details Page";
        return this.renderAndDisplayPage(playlistDetailsController,pageId,pageName, true,triggerHashChange, null,true);
    }).bind(this));

},

testAudioEditorOld : function(){
    return this.showMySubscriptionsPage(false).then((function(){
        return this.require(["controllers/audioEditor/SequencerController"]).then((function(SequencerController){
            var sequencerController =  new SequencerController();
            sequencerController.addTranscribeAudio("https://s3.amazonaws.com/com.trebble.songs.hightlights/573cfeb2f538f40a0042a37a/1634646552863.mp3","https://s3.amazonaws.com/com.trebble.songs.hightlights/573cfeb2f538f40a0042a37a/1634646552863_asrOutput.json");
            sequencerController.buildModelAndRenderView().then((function(){
                $("#mySubscriptions #mySubscriptionsListWrapper  #collection_repeat_container").append(sequencerController.getView().$el);
                sequencerController.setHeightMargin(135);
                $("#mySubscriptions #mySubscriptionsListWrapper  #collection_repeat_container .collection_content_wrapper").hide();
                sequencerController.setJqueryPageElWhereSequencerWillBeAdded($("#mySubscriptions"));
            }).bind(this))
        }).bind(this));
    }).bind(this))

},

testAudioEditor: function(triggerHashChange){
    triggerHashChange = triggerHashChange || false;
    var controllerParams = {};
    var pageId = "audioEditorDemoPage";
    controllerParams.pageId  = pageId;
    return this.require(["controllers/audioEditor/AudioEditorDemoPageController"]).then((function(AudioEditorDemoPageController){
        var audioEditorDemoPageController  = new AudioEditorDemoPageController(controllerParams);
        var pageName = "Audio Editor Demo Page";
        return this.renderAndDisplayPage(audioEditorDemoPageController,pageId,pageName, true,triggerHashChange);
    }).bind(this));
},


showSequencerNodeInspectorSectionPopup : function(sequencer, selectionInfoForInitialization){
    var params= {"sequencer":sequencer, "selectionInfoForInitialization": selectionInfoForInitialization};
    return this.require([ "controllers/audioEditor/sequencerNodeInspectorSection/SequencerNodeInspectorSectionPopupController"]).then((function(SequencerNodeInspectorSectionPopupController){
        var sequencerNodeInspectorSectionPopupController = new SequencerNodeInspectorSectionPopupController(params);
        return this.renderPopupAndDisplayPage (sequencerNodeInspectorSectionPopupController, "Sequencer Node Inspector Popup")
    }).bind(this));

},

showSoundEnhancerSettingsPopupContoller : function(sequencer, elementSource$el, prefetchedSoundEnhancerSettingsPopupContoller, popupPosition){

    var params= {"sequencer":sequencer, "elementSource$el": elementSource$el, "popupPosition": popupPosition};
    return (prefetchedSoundEnhancerSettingsPopupContoller? RSVP.Promise.resolve(prefetchedSoundEnhancerSettingsPopupContoller): this.require(["controllers/audioEditor/SoundEnhancerSettingsPopupContoller"])).then((function(SoundEnhancerSettingsPopupContoller){
        var soundEnhancerSettingsPopupContoller = new SoundEnhancerSettingsPopupContoller(params);
        return this.renderPopupAndDisplayPage (soundEnhancerSettingsPopupContoller, "Sound Enhancer Settings Popup", elementSource$el? true: false);
    }).bind(this));
},

showSequencerAutomatedOperationsPopup : function(sequencer, elementSource$el, prefetchedSequencerAutomatedOperationsOptionController, popupPosition){

    var params= {"sequencer":sequencer, "elementSource$el": elementSource$el, "popupPosition": popupPosition};
    return (prefetchedSequencerAutomatedOperationsOptionController? RSVP.Promise.resolve(prefetchedSequencerAutomatedOperationsOptionController): this.require(["controllers/audioEditor/SequencerAutomatedOperationsOptionController"])).then((function(SequencerAutomatedOperationsOptionController){
        var sequencerAutomatedOperationsOptionController = new SequencerAutomatedOperationsOptionController(params);
        return this.renderPopupAndDisplayPage (sequencerAutomatedOperationsOptionController, "Sequencer Automated Operations Popup", elementSource$el? true: false);
    }).bind(this));
},




showSequencerHelpContextPopup : function(sequencer, elementSource$el, prefetchedSequencerHelpContextOptionController, popupPosition){
    var params= {"sequencer":sequencer, "elementSource$el": elementSource$el, "popupPosition": popupPosition};
    return (prefetchedSequencerHelpContextOptionController? RSVP.Promise.resolve(prefetchedSequencerHelpContextOptionController): this.require(["controllers/audioEditor/SequencerHelpContextOptionController"])).then((function(SequencerHelpContextOptionController){
        var sequencerHelpContextOptionController = new SequencerHelpContextOptionController(params);
        return this.renderPopupAndDisplayPage (sequencerHelpContextOptionController, "Sequencer Help Context Menu Popup", elementSource$el? true: false);
    }).bind(this));
},


showTrimSilencesContextMenuPopup : function(applySearch, sequencer, elementSource$el, prefetchedTrimSilencesContextMenuController, popupPosition){
    var params= {"sequencer":sequencer, "elementSource$el": elementSource$el,applySearch, "popupPosition": popupPosition};
    return (prefetchedTrimSilencesContextMenuController? RSVP.Promise.resolve(prefetchedTrimSilencesContextMenuController): this.require(["controllers/audioEditor/TrimSilencesContextMenuController"])).then((function(TrimSilencesContextMenuController){
        var trimSilencesContextMenuController = new TrimSilencesContextMenuController(params);
        return this.renderPopupAndDisplayPage (trimSilencesContextMenuController, "Trim Silences Menu Popup", elementSource$el? true: false);
    }).bind(this));
},

showSequencerViewConfigSettingsPopupContoller : function(sequencer, elementSource$el, prefetchedSequencerViewConfigSettingsPopupContoller){

    var params= {"sequencer":sequencer, "elementSource$el": elementSource$el};
    return (prefetchedSequencerViewConfigSettingsPopupContoller? RSVP.Promise.resolve(prefetchedSequencerViewConfigSettingsPopupContoller): this.require(["controllers/audioEditor/SequencerViewConfigSettingsPopupContoller"])).then((function(SequencerViewConfigSettingsPopupContoller){
        var sequencerViewConfigSettingsPopupContoller = new SequencerViewConfigSettingsPopupContoller(params);
        return this.renderPopupAndDisplayPage (sequencerViewConfigSettingsPopupContoller, "Sequencer Configure View Settings Popup", elementSource$el? true: false);
    }).bind(this));
},


showMyAutoRadioDetailPageByRadioId  : function(radioId,triggerHashChange ){

    triggerHashChange = triggerHashChange || false;
    this.navigateToPageWithId( encodeURIComponent("myAutoRadioDetails_")+ radioId,"Personal Trebblot Details Page", true,triggerHashChange,null,null,true);
},

doLoginOnly : function(){
//THIS IS A SPECIALLY WHICH IS HANDLED BY THE MAIN. THE CODE IN MAIN SHOULD PROBABLY BE MOVED HERE
},

showMyAutoRadioDetailPage  : function(myAutoRadioSummaryModel,triggerHashChange ){

triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageId = encodeURIComponent("myAutoRadioDetails_"+myAutoRadioSummaryModel.getLoaderKey());
controllerParams.pageId  = pageId;
controllerParams.playlistSummary  = myAutoRadioSummaryModel;
return this.require(["controllers/playlistPodcast/MyAutoRadioDetailsController"]).then((function(MyAutoRadioDetailsController){
    var playlistDetailsController  = new MyAutoRadioDetailsController(controllerParams);
    var pageName = "Personal Trebblot Details Page";
    return this.renderAndDisplayPage(playlistDetailsController,pageId,pageName, true,triggerHashChange,null, true);
}).bind(this));

},

showSubscribeToTrebblePage  : function(trebbleId,triggerHashChange ){

triggerHashChange = triggerHashChange || false;
var controllerParams = {};
controllerParams.trebbleId  = trebbleId;
return this.require(["controllers/subscribeToTrebble/SubscribeToTrebblePageController"]).then((function(SubscribeToTrebblePageController){
    var subscribeToTrebblePageController  = new SubscribeToTrebblePageController(controllerParams);
    var pageName = "Subscribe To Trebble";
    return this.renderAndDisplayPage(subscribeToTrebblePageController,subscribeToTrebblePageController.getPageId(),pageName, true,triggerHashChange,null, true);
}).bind(this));

},

showSubscribeToTrebblePopup : function(trebbleId, onTrebbleSubscriptionSucessHandler, onTrebbleSubscriptionFailureHandler, showThankYouMessage){
var params= {};
params.trebbleId  = trebbleId;
params.displayAsPopup = true;
params.onTrebbleSubscriptionSucessHandler =  onTrebbleSubscriptionSucessHandler;
params.onTrebbleSubscriptionFailureHandler =  onTrebbleSubscriptionFailureHandler;
params.showThankYouMessage = showThankYouMessage;

return this.require([ "controllers/subscribeToTrebble/SubscribeToTrebblePageController"]).then((function(SubscribeToTrebblePageController){
    var subscribeToTrebblePageController = new SubscribeToTrebblePageController(params);
    window._subscribePopupDisplayed = true;
    return this.renderPopupAndDisplayPage (subscribeToTrebblePageController, "Subscribe To Trebble Popup")
}).bind(this));


},

showSubscribeToJourneyPopup : function(journeyRadioId, onTrebbleSubscriptionSucessHandler, onTrebbleSubscriptionFailureHandler, showThankYouMessage){
var params= {};
params.journeyRadioId  = journeyRadioId;
params.displayAsPopup = true;
params.onTrebbleSubscriptionSucessHandler =  onTrebbleSubscriptionSucessHandler;
params.onTrebbleSubscriptionFailureHandler =  onTrebbleSubscriptionFailureHandler;
params.showThankYouMessage = showThankYouMessage;

return this.require([ "controllers/journey/SubscribeToJourneyPageController"]).then((function(SubscribeToJourneyPageController){
    var subscribeToJourneyPageController = new SubscribeToJourneyPageController(params);
    window._subscribePopupDisplayed = true;
    return this.renderPopupAndDisplayPage (subscribeToJourneyPageController, "Subscribe To Journey Popup")
}).bind(this));


},

showPurchaseJourneyPopup : function(journeyRadioId, onJourneyPurchaseSuccessHandler, onJourneyPurchaseFailureHandler, showThankYouMessage){
var params= {};
params.journeyRadioId  = journeyRadioId;
params.displayAsPopup = true;
params.onJourneyPurchaseSuccessHandler =  onJourneyPurchaseSuccessHandler;
params.onJourneyPurchaseFailureHandler =  onJourneyPurchaseFailureHandler;
params.showThankYouMessage = showThankYouMessage;

return this.require([ "controllers/journey/PurchaseJourneyPageController"]).then((function(PurchaseJourneyPageController){
    var purchaseJourneyPageController = new PurchaseJourneyPageController(params);
    return this.renderPopupAndDisplayPage (purchaseJourneyPageController, "Purchase Journey Popup")
}).bind(this));


},

showSubscribeToTrebblePopupIfApplicable :function(trebbleId, onTrebbleSubscriptionSucessHandler, onTrebbleSubscriptionFailureHandler, showThankYouMessage){
if(window._subscribePopupDisplayed || !DISPLAY_LISTENING_INSTRUCTIONS_AFTER_USER_IS_SUBSCRIBED){
    $.mobile.loading("show");
    return TrebbleClientAPIHelper.getInstance().getMyTrebbleInfoByTrebbleId(trebbleId).then((function(radioJsonInfo){
        if(radioJsonInfo){
            var personalRadioSummaryModel = Utils.getInstance().buildPlaylistSummaryFromJson(radioJsonInfo);
            return PersistentModels.getInstance().subscribe(personalRadioSummaryModel).then((function(){
                $.mobile.loading("hide");
                if(onTrebbleSubscriptionSucessHandler){
                    onTrebbleSubscriptionSucessHandler();
                }
            }).bind(this)).catch((function(error){
                $.mobile.loading("hide");
                if(onTrebbleSubscriptionFailureHandler){
                    onTrebbleSubscriptionFailureHandler(error);
                }
            }).bind(this));
        }else{
            $.mobile.loading("hide");
            if(onTrebbleSubscriptionFailureHandler){
                onTrebbleSubscriptionFailureHandler(window.getI18n(ti18n, "WE_COULDNT_FIND_THE_SPECIFIED_TREBBLE"));
            }
        }
    }).bind(this));
}else{
    return this.showSubscribeToTrebblePopup(trebbleId, onTrebbleSubscriptionSucessHandler, onTrebbleSubscriptionFailureHandler, showThankYouMessage);
}
},



showPurchaseJourneyPopupIfApplicable :function(journeyId, onTrebblePurchaseSucessHandler, onTrebblePurchaseFailureHandler, showThankYouMessage){
if(window._subscribePopupDisplayed || !DISPLAY_LISTENING_INSTRUCTIONS_AFTER_USER_IS_SUBSCRIBED){
    $.mobile.loading("show");
    return TrebbleClientAPIHelper.getInstance().getJourneyInfoByJourneyRadioId(journeyId).then((function(radioJsonInfo){
        if(radioJsonInfo){
            var journeySummaryModel = Utils.getInstance().buildJourneySummaryFromJson(radioJsonInfo);
            return PersistentModels.getInstance().subscribeToJourney(journeySummaryModel).then((function(){
                $.mobile.loading("hide");
                if(onTrebblePurchaseSucessHandler){
                    onTrebblePurchaseSucessHandler();
                }
            }).bind(this)).catch((function(error){
                $.mobile.loading("hide");
                if(onTrebblePurchaseFailureHandler){
                    onTrebblePurchaseFailureHandler(error);
                }
            }).bind(this));
        }else{
            $.mobile.loading("hide");
            if(onTrebblePurchaseFailureHandler){
                onTrebblePurchaseFailureHandler(window.getI18n(ti18n, "WE_COULDNT_FIND_THE_SPECIFIED_TREBBLE"));
            }
        }
    }).bind(this));
}else{
    return this.showSubscribeToJourneyPopup(journeyId, onTrebblePurchaseSucessHandler, onTrebblePurchaseFailureHandler, showThankYouMessage);
}
},

showUnsubscribeToTrebblePopupIfApplicable :function(trebbleId, onTrebbleSubscriptionSucessHandler, onTrebbleSubscriptionFailureHandler, showThankYouMessage){
if(window._subscribePopupDisplayed || !DISPLAY_LISTENING_INSTRUCTIONS_AFTER_USER_IS_SUBSCRIBED){
    $.mobile.loading("show");
    return TrebbleClientAPIHelper.getInstance().getMyTrebbleInfoByTrebbleId(trebbleId).then((function(radioJsonInfo){
        if(radioJsonInfo){
            var personalRadioSummaryModel = Utils.getInstance().buildPlaylistSummaryFromJson(radioJsonInfo);
            return PersistentModels.getInstance().unsubscribe(personalRadioSummaryModel).then((function(){
                $.mobile.loading("hide");
                if(onTrebbleSubscriptionSucessHandler){
                    onTrebbleSubscriptionSucessHandler();
                }
            }).bind(this)).catch((function(error){
                $.mobile.loading("hide");
                if(onTrebbleSubscriptionFailureHandler){
                    onTrebbleSubscriptionFailureHandler(error);
                }
            }).bind(this));
        }else{
            $.mobile.loading("hide");
            if(onTrebbleSubscriptionFailureHandler){
                onTrebbleSubscriptionFailureHandler(window.getI18n(ti18n, "WE_COULDNT_FIND_THE_SPECIFIED_TREBBLE"));
            }
        }
    }).bind(this));
}else{
    return this.showSubscribeToTrebblePopup(trebbleId, onTrebbleSubscriptionSucessHandler, onTrebbleSubscriptionFailureHandler, showThankYouMessage);
}
},


showUnsubscribeToJourneyPopupIfApplicable :function(journeyId, onTrebbleSubscriptionSucessHandler, onTrebbleSubscriptionFailureHandler, showThankYouMessage){
if(window._subscribePopupDisplayed || !DISPLAY_LISTENING_INSTRUCTIONS_AFTER_USER_IS_SUBSCRIBED){
    $.mobile.loading("show");
    return TrebbleClientAPIHelper.getInstance().getJourneyInfoByJourneyRadioId(journeyId).then((function(radioJsonInfo){
        if(radioJsonInfo){
            var journeySummaryModel = Utils.getInstance().buildJourneySummaryFromJson(radioJsonInfo);
            return PersistentModels.getInstance().unsubscribeFromJourney(journeySummaryModel).then((function(){
                $.mobile.loading("hide");
                if(onTrebbleSubscriptionSucessHandler){
                    onTrebbleSubscriptionSucessHandler();
                }
            }).bind(this)).catch((function(error){
                $.mobile.loading("hide");
                if(onTrebbleSubscriptionFailureHandler){
                    onTrebbleSubscriptionFailureHandler(error);
                }
            }).bind(this));
        }else{
            $.mobile.loading("hide");
            if(onTrebbleSubscriptionFailureHandler){
                onTrebbleSubscriptionFailureHandler(window.getI18n(ti18n, "WE_COULDNT_FIND_THE_SPECIFIED_TREBBLE"));
            }
        }
    }).bind(this));
}else{
    return this.showSubscribeToTrebblePopup(journeyId, onTrebbleSubscriptionSucessHandler, onTrebbleSubscriptionFailureHandler, showThankYouMessage);
}
},



showMyFollowerPersonalRadioDetailPageByRadioId  : function(radioId,triggerHashChange ){

triggerHashChange = triggerHashChange || false;
this.navigateToPageWithId( encodeURIComponent("myFollowerPersonalRadioDetails_")+ radioId,"Followed Trebble Details Page", true,triggerHashChange,null, null, true);
},

showMyFollowerPersonalRadioDetailPage  : function(myFolowerPersonalRadioSummaryModel,triggerHashChange ){

triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageId = encodeURIComponent("myFollowerPersonalRadioDetails_"+myFolowerPersonalRadioSummaryModel.getLoaderKey()+"_"+(new Date()).getTime());
controllerParams.pageId  = pageId;
controllerParams.playlistSummary  = myFolowerPersonalRadioSummaryModel;
return this.require(["controllers/playlistPodcast/FollowerPersonalRadioDetailsController"]).then((function(FollowerPersonalRadioDetailsController){
    var playlistDetailsController  = new FollowerPersonalRadioDetailsController(controllerParams);
    var pageName = "Followed Trebble Details Page";
    return this.renderAndDisplayPage(playlistDetailsController,pageId,pageName, true,triggerHashChange,null, true);
}).bind(this));

},

showRadioDetailPageByRadioId  : function(radioId,triggerHashChange ){

triggerHashChange = triggerHashChange || false;
var pageName = "A Trebble Details Page"
this.navigateToPageWithId( encodeURIComponent("trebble_")+ radioId,pageName, true,triggerHashChange,null, null, true);

},

showRadioDetailPage  : function(radioSummaryModel,triggerHashChange ){

triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageId = encodeURIComponent("trebble_"+radioSummaryModel.getLoaderKey());//+"_"+(new Date()).getTime());

if(this.isJQMPageWithIdExist(pageId)){
    this.navigateToPageWithId( pageId,"A Trebble Details Page", true,triggerHashChange, null,null, true);
    return RSVP.Promise.resolve();
}else{
    controllerParams.pageId  = pageId;
    controllerParams.playlistSummary  = radioSummaryModel;
    controllerParams.doNotRemoveFromDocument = false;
    controllerParams.showBackButton = true;
    return this.require(["controllers/playlistPodcast/MyPersonalRadioDetailsController"]).then((function(MyPersonalRadioDetailsController){
        var playlistDetailsController  = new MyPersonalRadioDetailsController(controllerParams);
        var pageName = "A Trebble Details Page";
        return this.renderAndDisplayPage(playlistDetailsController,pageId,pageName, true,triggerHashChange,null, true);
    }).bind(this));
}

},

showPublicRadioDetailPageeByRadioId  : function(radioId,triggerHashChange ){

triggerHashChange = triggerHashChange || false;
var pageId = encodeURIComponent("public_")+ radioId

if(this.isJQMPageWithIdExist(pageId)){
    this.navigateToPageWithId( pageId,"Public Trebble Details Page", true,triggerHashChange, null,null, true);
    return RSVP.Promise.resolve();
}else{
    return TrebbleClientAPIHelper.getInstance().getMyTrebbleInfoByTrebbleId(radioId).then((function(radioJsonInfo){
        if(radioJsonInfo){
            var personalRadioSummaryModel = Utils.getInstance().buildPlaylistSummaryFromJson(radioJsonInfo);
            return this.showPublicRadioDetailPage(personalRadioSummaryModel, triggerHashChange);
        }else{
            window.alertErrorMessage(window.getI18n(ti18n, "UNABLE_TO_FIND_THIS_TREBBLE"));
        }
    }).bind(this));
}

},

/*showPublicJourneyDetailPageeByJourneyRadioId  : function(jouneyRadioId,referrer, triggerHashChange ){

triggerHashChange = triggerHashChange || false;
var pageId = encodeURIComponent("journey_")+ jouneyRadioId

if(this.isJQMPageWithIdExist(pageId)){
    if(referrer){
        window.trebble.referrer =referrer;
    }
    this.navigateToPageWithId( pageId,"Public Journey Details Page", true,triggerHashChange, null,null, true);
    return RSVP.Promise.resolve();
}else{
    return TrebbleClientAPIHelper.getInstance().getJourneyInfoByJourneyRadioId(jouneyRadioId).then((function(journeyRadioJsonInfo){
        if(journeyRadioJsonInfo){
            if(referrer){
                window.trebble.referrer =referrer;
            }
            var journeySummaryModel = Utils.getInstance().buildJourneySummaryFromJson(journeyRadioJsonInfo);
            return this.showPublicJourneyDetailPage(journeySummaryModel, triggerHashChange);
        }else{
            window.alertErrorMessage(window.getI18n(ti18n, "UNABLE_TO_FIND_THIS_JOURNEY"));
        }
    }).bind(this));
}

},*/

showJourneyPageWithSuccessPurchaseIfApplicable: function(journeyRadioId){
return this.showPublicJourneyDetailPageeByJourneyRadioId(journeyRadioId,null, false , false).then(function(){
    return TrebbleClientAPIHelper.getInstance().hasPurchasedJourney(journeyRadioId);
}).then(function(journeyWasPurchased){
    if(journeyWasPurchased){
        this.showThankYouMessageForPurchasingJourneyPopup();
    }

})
},

showThankYouMessageForPurchasingJourneyPopup :function(journeyModel){
var buttonLabels = [window.getI18n(ti18n, "OK")];
var customImages  = ["goodjob1.gif","goodjob2.gif","goodjob3.gif","goodjob4.gif","goodjob5.gif","goodjob6.gif","goodjob7.gif","goodjob8.gif","goodjob9.gif","goodjob10.gif"];
var randomImage = customImages[Math.floor(Math.random() * customImages.length)];
var customImageUrl = (window.waitForCordovaToLoad?"":"/") + "css/images/"+ randomImage;
var customSize = window.getDocumentClienWidth() <= 450 ?"200x80":"383x138";
var title = Rollout.getFeatureVariable(Rollout.FEATURES.JOURNEY_PURCHASE_CONFIG, "label_thank_you_for_purchasing_this_journey_"+window.getAppUsedLanguage(),window.getI18n(ti18n, "THANK_YOU_FOR_PURCHASING_THIS_JOURNEY"));
if(journeyModel){
    title = Rollout.getFeatureVariable(Rollout.FEATURES.JOURNEY_PURCHASE_CONFIG, "label_thank_you_for_purchasing_"+window.getAppUsedLanguage(),window.getI18n(ti18n, "THANK_YOU_FOR_PURCHASING_THIS_JOURNEY")) + " '"+journeyModel.getName()+"'"
}
var subtitle = Rollout.getFeatureVariable(Rollout.FEATURES.JOURNEY_PURCHASE_CONFIG, "label_thank_you_for_purchasing_this_journey_submessage_"+window.getAppUsedLanguage(),window.getI18n(ti18n, "WE_WISH_YOU_A_HAPPY_LISTENING"));

Utils.getInstance().showSweetAlertDialog((function(){ 
    navigator.trebbleNotification.confirm(title, function(){}, "", buttonLabels,null , null, subtitle, false, customImageUrl,customSize);
}).bind(this),1000);
},

showPublicJourneyDetailPageeByJourneyRadioId  : function(jouneyRadioIdWithTimestamp,referrer, triggerHashChange , showEditablePageIfApplicable){
var jouneyRadioId = jouneyRadioIdWithTimestamp? jouneyRadioIdWithTimestamp.split("_")[0]: null;
triggerHashChange = triggerHashChange || false;
let pageId;
if(showEditablePageIfApplicable){
    pageId = encodeURIComponent("journeyManagePage_"+jouneyRadioId);
}else{
    pageId = encodeURIComponent("journey_"+jouneyRadioId);
}

if(this.isJQMPageWithIdExist(pageId)){
    if(referrer){
        window.trebble.referrer =referrer;
    }
    this.navigateToPageWithId( pageId,"Public Journey Details Page", true,triggerHashChange, null,null, true);
    return RSVP.Promise.resolve();
}else{
    return TrebbleClientAPIHelper.getInstance().getJourneyInfoByJourneyRadioId(jouneyRadioId).then((function(journeyRadioJsonInfo){
        if(journeyRadioJsonInfo){
            if(referrer){
                window.trebble.referrer =referrer;
            }
            var journeySummaryModel = Utils.getInstance().buildJourneySummaryFromJson(journeyRadioJsonInfo);
            return this.showPublicJourneyDetailPage(journeySummaryModel, triggerHashChange, showEditablePageIfApplicable);
        }else{
            window.alertErrorMessage(window.getI18n(ti18n, "UNABLE_TO_FIND_THIS_JOURNEY"));
        }
    }).bind(this));
}

},

onStripePurchaseSuccessfull : function(){
window.close();
},
onStripeOnboardingCompleted : function(){
window.close();
},

getSignUpAndRecordPageURL : function(){
var urlRoute =  "signUpAndRecord";
if(window.waitForCordovaToLoad){
    return "https://web.trebble.fm/"+ urlRoute;
}else{
    if(window.isWebAppInProduction()){
        return "https://web.trebble.fm/"+urlRoute;
    }else{
        return "https://localhost:8000/"+urlRoute;
    }
}
},


getOnStripeOnboardingCompletedURL : function(){
var urlRoute =  "onStripeOnboardingCompleted";
if(window.waitForCordovaToLoad){
    return "https://web.trebble.fm/"+ urlRoute;
}else{
    if(window.isWebAppInProduction()){
        return "https://web.trebble.fm/"+urlRoute;
    }else{
        return "https://localhost:8000/"+urlRoute;
    }
}

},

onStripePurchaseCancelled : function(){
window.close();
},

onStripeOnboardingInterupted : function(){
window.close();
},

getOnStripePurchaseCancelledURL : function(){
var urlRoute =  "onStripePurchaseCancelled";
return (window.waitForCordovaToLoad)?"https://web.trebble.fm/"+ urlRoute: "https://web.trebble.fm/"+urlRoute;
},
getOnStripeOnboardingInteruptedURL : function(){
var urlRoute =  "onStripeOnboardingInterupted";
return (window.waitForCordovaToLoad)?"https://web.trebble.fm/"+ urlRoute: "https://web.trebble.fm/"+urlRoute;
},

trackAffiliateIfApplicable: function(){
try{
    if("rewardful" in window){
        var userInfo = LocalStorageHelper.getInstance().getUserInfo();
        if(userInfo && userInfo.email){
            window.rewardful('convert', { email: userInfo.email });
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("referral", "Premium subscription was referred by an affiliate", "Premium subscription was referred by an affiliate", null, {"affiliate": window.rewardful.affiliate});
            }
        }
    }
    
}catch(error){
    console.error("Failed tracking affiliate. Error:"+ error);
}
},

onSubscriptionPaymentCompleted : function(customDisplayPagePromise){
var clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');
if(!customDisplayPagePromise){
    customDisplayPagePromise = this.showDefaultPage(true);
}
return customDisplayPagePromise.then((function(){
    return this.require(["models/helper/StripeHelper"]).then((function(StripeHelper){
        return StripeHelper.getInstance().getPaymentIntentStatus(clientSecret).then((function(status){
            if(status){
                if(status === StripeHelper.getInstance().PAYMENT_INTENT_STATUS.SUCCEEDED){
                    return new RSVP.Promise((function(resolve){
                        return this.showSuccessMessageWithGifPopup(window.getI18n(ti18n, "WEVE_RECEIVED_THE_PAYMENT_FOR_CREATOR_SUBSCRIPTION"), null, [window.getI18n(ti18n, "OKAY")], resolve)
                    }).bind(this)).then((function(){
                        this.trackAffiliateIfApplicable();
                        if(window.trebbleAnalyticsHelper){
                            window.trebbleAnalyticsHelper.trackEvent("creatorSubscription", "paymentForCreatorSubscriptionCompleted", "Payment for creator subscription completed");
                        }
                    }).bind(this));
                    
                }
                if(status === StripeHelper.getInstance().PAYMENT_INTENT_STATUS.PROCESSING){
                    return new RSVP.Promise(function(resolve){
                        if(window.trebbleAnalyticsHelper){
                            window.trebbleAnalyticsHelper.trackEvent("creatorSubscription", "paymentForCreatorSubscriptionBeingProcessed", "Payment for creator subscription is being processed");
                        }
                        return window.alertWarningMessage(window.getI18n(ti18n, "YOUR_CREATOR_PAYMENT_IS_BEING_PROCESSED"), null, resolve)
                    })
                    
                }
                if(status === StripeHelper.getInstance().PAYMENT_INTENT_STATUS.REQUIRES_PAYMENT_METHOD){
                    Utils.getInstance().showErrorNotification(window.getI18n(ti18n, "PAYMENT_FAILED_PLEASE_TRY_ANOTHER_METHOD"), null, function(){
                        if(window.trebbleAnalyticsHelper){
                            window.trebbleAnalyticsHelper.trackEvent("creatorSubscription", "paymentForCreatorSubscriptionFailed", "Payment for creator subscription failed");
                        }
                        return this.showUpgradeCreatorSubscriptionPopup();
                    });
                    
                }
            }else{
                return window.alertErrorMessage("Something went wrong.")
            }

        }).bind(this));

    }).bind(this));
}).bind(this))
},

confirmPaymentSetupAndResumeCreatorPlanUpgrade:function(){
return this.onPaymentSetupCompleted().then((function(){
    return this.showUpgradeCreatorSubscriptionPopup();
}).bind(this))
},

confirmPaymentSetupAndRedirectToSettingsPage:function(){
return this.onPaymentSetupCompleted(this.showSettingsPage(false));
},

confirmUpgradeCreatorSubscriptionAndRedirectToCreateShortcastPage:function(){
return this.onSubscriptionPaymentCompleted(this.showAddAudioHightlightPageNew(null,null, null,null,true, false, null,null,null,null,null,true));
},

confirmPaymentMethodUpdateAndRedirectToCreateShortcastPage:function(){
return this.onPaymentSetupCompleted(this.showAddAudioHightlightPageNew(null,null, null,null,true, false, null,null,null,null,null,true));
},

onPaymentSetupCompleted: function(customDisplayPagePromise){
var clientSecret = new URLSearchParams(window.location.search).get('setup_intent_client_secret');
if(!customDisplayPagePromise){
    customDisplayPagePromise = this.showDefaultPage(true);
}
return customDisplayPagePromise.then((function(){
    return this.require(["models/helper/StripeHelper"]).then((function(StripeHelper){
        return StripeHelper.getInstance().getPaymentSetupIntentStatus(clientSecret).then((function(status){
            if(status){
                if(status === StripeHelper.getInstance().PAYMENT_SETUP_INTENT_STATUS.SUCCEEDED){
                    return new RSVP.Promise((function(resolve){
                        if(window.trebbleAnalyticsHelper){
                            window.trebbleAnalyticsHelper.trackEvent("creatorSubscriptionPaymentSetup", "creatorSubscriptionPaymentSetupSucceeded", "Payment setup for creator subscription succeeded");
                        }
                        return this.showSuccessMessageWithGifPopup(window.getI18n(ti18n, "YOU_VE_SUCCESSFULLY_UPDATED_YOUR_PAYMENT_METHOD"), null,[window.getI18n(ti18n, "OKAY")], resolve)
                    }).bind(this))
                }
                if(status === StripeHelper.getInstance().PAYMENT_SETUP_INTENT_STATUS.PROCESSING){
                    return new RSVP.Promise(function(resolve){
                        if(window.trebbleAnalyticsHelper){
                            window.trebbleAnalyticsHelper.trackEvent("creatorSubscriptionPaymentSetup", "creatorSubscriptionPaymentSetupBeingProcessed", "Payment setup for creator subscription being processed");
                        }
                        return window.alertWarningMessage(window.getI18n(ti18n, "YOUR_PAYMENT_IS_BEING_PROCESSED"), null, resolve)
                    })
                }
                if(status === StripeHelper.getInstance().PAYMENT_SETUP_INTENT_STATUS.REQUIRES_PAYMENT_METHOD){
                    Utils.getInstance().showErrorNotification(window.getI18n(ti18n, "SOMETHING_WENT_WRONG_WHILE_UPDATING_YOUR_PAYMENT_METHOD"), null, function(){
                        if(window.trebbleAnalyticsHelper){
                            window.trebbleAnalyticsHelper.trackEvent("creatorSubscriptionPaymentSetup", "creatorSubscriptionPaymentSetupFailed", "Payment setup for creator subscription failed");
                        }
                        return this.showReplacePaymentForCreatorSubscriptionPopup();
                    });
                }
            }else{
                return window.alertErrorMessage("Something went wrong.")
            }
        }).bind(this));

    }).bind(this));
}).bind(this))
},

getOnSubscriptionPaymentCompletedURL : function(){
var urlRoute =  "onSubscriptionPaymentCompleted";
if(window.waitForCordovaToLoad){
    return "https://web.trebble.fm/"+ urlRoute;
}else{
    if(window.isWebAppInProduction()){
        return "https://web.trebble.fm/"+urlRoute;
    }else{
        return "https://localhost:8000/"+urlRoute;
    }
}
},

getCompletePaymentSetupAndResumeCreatorPlanUpgradeURL : function(){
var urlRoute =  "confirmPaymentSetupAndResumeCreatorPlanUpgrade";
if(window.waitForCordovaToLoad){
    return "https://web.trebble.fm/"+ urlRoute;
}else{
    if(window.isWebAppInProduction()){
        return "https://web.trebble.fm/"+urlRoute;
    }else{
        return "https://localhost:8000/"+urlRoute;
    }
}
},

getCompletePaymentSetupAndRediredtToSettingsPageURL : function(){
var urlRoute =  "confirmPaymentSetupAndRedirectToSettingsPage";
if(window.waitForCordovaToLoad){
    return "https://web.trebble.fm/"+ urlRoute;
}else{
    if(window.isWebAppInProduction()){
        return "https://web.trebble.fm/"+urlRoute;
    }else{
        return "https://localhost:8000/"+urlRoute;
    }
}
},

getConfirmUpgradeCreatorSubscriptionAndRedirectToCreateShortcastPageURL : function(){
var urlRoute =  "confirmUpgradeCreatorSubscriptionAndRedirectToCreateShortcastPage";
if(window.waitForCordovaToLoad){
    return "https://web.trebble.fm/"+ urlRoute;
}else{
    if(window.isWebAppInProduction()){
        return "https://web.trebble.fm/"+urlRoute;
    }else{
        return "https://localhost:8000/"+urlRoute;
    }
}
},

getConfirmPaymentUpdateAndRedirectToCreateShortcastPageURL : function(){
var urlRoute =  "confirmPaymentMethodUpdateAndRedirectToCreateShortcastPage";
if(window.waitForCordovaToLoad){
    return "https://web.trebble.fm/"+ urlRoute;
}else{
    if(window.isWebAppInProduction()){
        return "https://web.trebble.fm/"+urlRoute;
    }else{
        return "https://localhost:8000/"+urlRoute;
    }
}
},


getOnSetupPaymentCompletedURL : function(){
var urlRoute =  "onPaymentSetupCompleted";
if(window.waitForCordovaToLoad){
    return "https://web.trebble.fm/"+ urlRoute;
}else{
    if(window.isWebAppInProduction()){
        return "https://web.trebble.fm/"+urlRoute;
    }else{
        return "https://localhost:8000/"+urlRoute;
    }
}
},


showPreviewPublicJourneyDetailPageeByJourneyRadioId  : function(jouneyRadioIdWithTimestamp,referrer, triggerHashChange ){
var jouneyRadioId = jouneyRadioIdWithTimestamp? jouneyRadioIdWithTimestamp.split("_")[0]: null;
triggerHashChange = triggerHashChange || false;

var pageId = encodeURIComponent("journeyPreview_"+jouneyRadioId);


if(this.isJQMPageWithIdExist(pageId)){
    if(referrer){
        window.trebble.referrer =referrer;
    }
    this.navigateToPageWithId( pageId,"Public Journey Preview Details Page", true,triggerHashChange, null,null, true);
    return RSVP.Promise.resolve();
}else{
    return TrebbleClientAPIHelper.getInstance().getJourneyInfoByJourneyRadioId(jouneyRadioId).then((function(journeyRadioJsonInfo){
        if(journeyRadioJsonInfo){
            if(referrer){
                window.trebble.referrer =referrer;
            }
            var journeySummaryModel = Utils.getInstance().buildJourneySummaryFromJson(journeyRadioJsonInfo);
            return this.showPreviewPublicJourneyDetailPage(journeySummaryModel, triggerHashChange);
        }else{
            window.alertErrorMessage(window.getI18n(ti18n, "UNABLE_TO_FIND_THIS_JOURNEY"));
        }
    }).bind(this));
}

},

showShortcastDetailPageByShortcastId : function(shortcastId, triggerHashChange){
triggerHashChange = triggerHashChange || false;
var pageId = encodeURIComponent("shortcast_")+ shortcastId

if(this.isJQMPageWithIdExist(pageId)){
    this.navigateToPageWithId( pageId,"Shortcast Details Page", true,triggerHashChange, null,null, true);
    return RSVP.Promise.resolve();
}else{
    return TrebbleClientAPIHelper.getInstance().getRadioInfoAndCapsuleInfoByShortcastId(shortcastId).then((function(radioAndCapsulesInfo){
        if(radioAndCapsulesInfo){
            var radioJsonInfo = radioAndCapsulesInfo.radioInfo;
            var capsulesInfoArray = radioAndCapsulesInfo.capsulesInfoArray;
            var personalRadioSummaryModel = Utils.getInstance().buildPlaylistSummaryFromJson(radioJsonInfo);
            return this.showShortcastDetailPage(personalRadioSummaryModel, capsulesInfoArray, triggerHashChange);
        }else{
            window.alertErrorMessage(window.getI18n(ti18n, "UNABLE_TO_FIND_THIS_SHORTCAST"));
        }
    }).bind(this));
}
},

showWebAppHomePage : function(){
if(window.waitForCordovaToLoad){
    window.location = window.location.href.substr(0,window.location.href.indexOf("#"))//remove hash
}else{
    document.location.href =  TREBBLE_WEB_APP_HOME_PAGE;
}
},

showPublicRadioPageByCustomUrl : function(trebbleCustomUrl, referrer, triggerHashChange){
triggerHashChange = triggerHashChange || false;
return TrebbleClientAPIHelper.getInstance().getMyTrebbleInfoByTrebbleCustomURL(trebbleCustomUrl).then((function(trebbleInfoJson){
    if(trebbleInfoJson){
        var personalRadioSummaryModel = Utils.getInstance().buildPlaylistSummaryFromJson(trebbleInfoJson);
        if(referrer){
            window.trebble.referrer =referrer;
        }
        return this.showPublicRadioDetailPage(personalRadioSummaryModel, triggerHashChange);
    }else{
        
        //window.alertErrorMessage(window.getI18n(ti18n,"OOPS_WE_WERE_NOT_ABLE_TO_FIND_THE_TREBBLE"));
        console.error(window.getI18n(ti18n,"OOPS_WE_WERE_NOT_ABLE_TO_FIND_THE_TREBBLE"));
        this.showWebAppHomePage();
    }

}).bind(this)).catch((function(error){
    console.error(error);
    console.error(window.getI18n(ti18n,"OOPS_WE_WERE_NOT_ABLE_TO_FIND_THE_TREBBLE"));
    //window.alertErrorMessage(window.getI18n(ti18n,"OOPS_SOMETHING_WENT_WRONG_WHEN_RETRIEVING_THE_TREBBLE"));
    this.showWebAppHomePage();

}).bind(this))
},

showJourneyManagePageByJourneyRadioId : function(jouneyRadioIdWithTimestamp,referrer, triggerHashChange ){
var jouneyRadioId = jouneyRadioIdWithTimestamp? jouneyRadioIdWithTimestamp.split("_")[0]: null;
triggerHashChange = triggerHashChange || false;
var pageId = encodeURIComponent("journeyManagePage_")+ jouneyRadioId

if(this.isJQMPageWithIdExist(pageId)){
    if(referrer){
        window.trebble.referrer =referrer;
    }
    this.navigateToPageWithId( pageId,"Journey Manage Details Page", true,triggerHashChange, null,null, true);
    return RSVP.Promise.resolve();
}else{
    return TrebbleClientAPIHelper.getInstance().getJourneyInfoByJourneyRadioId(jouneyRadioId).then((function(journeyRadioJsonInfo){
        if(journeyRadioJsonInfo){
            if(referrer){
                window.trebble.referrer =referrer;
            }
            var journeySummaryModel = Utils.getInstance().buildJourneySummaryFromJson(journeyRadioJsonInfo);
            return this.showPublicJourneyDetailPage(journeySummaryModel, triggerHashChange, true);
        }else{
            window.alertErrorMessage(window.getI18n(ti18n, "UNABLE_TO_FIND_THIS_JOURNEY"));
        }
    }).bind(this));
}
},

showPublicJourneyDetailPage  : function( journeySummaryModel, triggerHashChange, showEditablePageIfApplicable){

triggerHashChange = triggerHashChange || false;
var controllerParams = {};
let pageId;
if(showEditablePageIfApplicable){
    pageId = encodeURIComponent("journeyManagePage_"+journeySummaryModel.getLoaderKey());
}else{
    pageId = encodeURIComponent("journey_"+journeySummaryModel.getLoaderKey()+"_"+(new Date()).getTime());
}
if(this.isJQMPageWithIdExist(pageId)){
    this.navigateToPageWithId( pageId,"A Public Journey Details Page", true,triggerHashChange, null,null, true);
    return RSVP.Promise.resolve();
}else{
    controllerParams.pageId  = pageId;
    controllerParams.playlistSummary  = journeySummaryModel;
    controllerParams.isPublicPage = showEditablePageIfApplicable?!journeySummaryModel.canBeEdited(): true;
    controllerParams.showBackButton = true;
    controllerParams.doNotRemoveFromDocument = false;
    return this.require(["controllers/journey/MyPersonalJourneyDetailsController"]).then((function(MyPersonalJourneyDetailsController){
        var journeyDetailsController  = new MyPersonalJourneyDetailsController(controllerParams);
        var pageName = "A Journey Details Page";
        return this.renderAndDisplayPage(journeyDetailsController,pageId,pageName, true,triggerHashChange,null, true);
    }).bind(this)).then((function(){
        if(controllerParams.isPublicPage && journeySummaryModel){
            TrebbleClientAPIHelper.getInstance().logRadioPageVisited(journeySummaryModel.getLoaderKey());
        }
    }).bind(this));
}

},

showPreviewPublicJourneyDetailPage  : function( journeySummaryModel, triggerHashChange){

triggerHashChange = triggerHashChange || false;
var controllerParams = {};

var pageId = encodeURIComponent("journeyPreview_"+journeySummaryModel.getLoaderKey()+"_"+(new Date()).getTime());

if(this.isJQMPageWithIdExist(pageId)){
    this.navigateToPageWithId( pageId,"A Public Journey Preview Details Page", true,triggerHashChange, null,null, true);
    return RSVP.Promise.resolve();
}else{
    controllerParams.pageId  = pageId;
    controllerParams.playlistSummary  = journeySummaryModel;
    controllerParams.isInPreviewMode = journeySummaryModel.canBeEdited()? true: false;
    controllerParams.isPublicPage = true;
    controllerParams.showBackButton = true;
    controllerParams.doNotRemoveFromDocument = false;
    return this.require(["controllers/journey/MyPersonalJourneyDetailsController"]).then((function(MyPersonalJourneyDetailsController){
        var journeyDetailsController  = new MyPersonalJourneyDetailsController(controllerParams);
        var pageName = "A Journey Details Page";
        return this.renderAndDisplayPage(journeyDetailsController,pageId,pageName, true,triggerHashChange,null, true);
    }).bind(this)).then((function(){
        if(controllerParams.isPublicPage && journeySummaryModel){
            TrebbleClientAPIHelper.getInstance().logRadioPageVisited(journeySummaryModel.getLoaderKey());
        }
    }).bind(this));
}

},


showPublicRadioDetailPage  : function( radioSummaryModel, triggerHashChange){

triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageId = encodeURIComponent("public_"+radioSummaryModel.getLoaderKey());//+"_"+(new Date()).getTime());
if(this.isJQMPageWithIdExist(pageId)){
    this.navigateToPageWithId( pageId,"A Public Trebble Details Page", true,triggerHashChange, null,null, true);
    return RSVP.Promise.resolve();
}else{
    controllerParams.pageId  = pageId;
    controllerParams.playlistSummary  = radioSummaryModel;
    controllerParams.isPublicPage = true
    controllerParams.showBackButton = true;
    controllerParams.doNotRemoveFromDocument = false;
    return this.require(["controllers/playlistPodcast/MyPersonalRadioDetailsController"]).then((function(MyPersonalRadioDetailsController){
        var playlistDetailsController  = new MyPersonalRadioDetailsController(controllerParams);
        var pageName = "A Trebble Details Page";
        return this.renderAndDisplayPage(playlistDetailsController,pageId,pageName, true,triggerHashChange,null, true);
    }).bind(this)).then((function(){
        if(controllerParams.isPublicPage && radioSummaryModel){
            TrebbleClientAPIHelper.getInstance().logRadioPageVisited(radioSummaryModel.getLoaderKey());
        }
    }).bind(this));
}

},

showShortcastDetailPage  : function( radioSummaryModel,capsulesArray, triggerHashChange){

triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageId = encodeURIComponent("shortcast_"+radioSummaryModel.id);//+"_"+(new Date()).getTime());
if(this.isJQMPageWithIdExist(pageId)){
    this.navigateToPageWithId( pageId,"A Shortcast Details Page", true,triggerHashChange, null,null, true, true);
    return RSVP.Promise.resolve();
}else{
    controllerParams.pageId  = pageId;
    controllerParams.playlistSummary  = radioSummaryModel;
    controllerParams.isPublicPage = true
    controllerParams.showBackButton = true;
    controllerParams.doNotRemoveFromDocument = false;
    controllerParams.customCapsuleTitleHeader = window.getI18n(ti18n, "CAPSULES");
    controllerParams.customCapsuleLoadHandler =  function(){
        return RSVP.Promise.resolve(capsulesArray);
    };
    return this.require(["controllers/playlistPodcast/MyPersonalRadioDetailsController"]).then((function(MyPersonalRadioDetailsController){
        var playlistDetailsController  = new MyPersonalRadioDetailsController(controllerParams);
        var pageName = "A Shortcast Details Page";
        return this.renderAndDisplayPage(playlistDetailsController,pageId,pageName, true,triggerHashChange,null, true, true);
    }).bind(this));
}

},


showSongListPage : function(searchKeyword, triggerHashChange){
triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageTitle ="\""+searchKeyword.trim()+ "\" in Songs";
var pageId = encodeURIComponent("songListPage_"+searchKeyword );
var pageName = "Song Search Result";

if(this.isJQMPageWithIdExist(pageId)){
    this.navigateToPageWithId( pageId,pageName  , true,triggerHashChange);
    return RSVP.Promise.resolve();
}else{
    controllerParams.pageTitle = pageTitle;
    controllerParams.pageId  = pageId;
    controllerParams.searchKeyword  = searchKeyword;
    return this.require(["controllers/songListPageController/SongListPageController"]).then((function(SongListPageController){
        var songListPageController  = new SongListPageController(controllerParams);
        return this.renderAndDisplayPage(songListPageController,pageId,pageName, true,triggerHashChange);
    }).bind(this));

}
},

showSuggestionPopupToShareTrebbleIfApplicable : function(){
if(!this._remiderToShareTrebbleDisplayedToUser && window._capsuleCreatedAndProcessedInThisSession){
    var shareTrebbleBtnClicked =  (function(){
        var radioIds =  PersistentModels.getInstance().getAllMyPersonalRadioIds();
        
        if(radioIds && radioIds.length){
            this.showTrebbleSharePopupByRadioId(radioIds[0]).then(function(controller){
                if(controller && controller.showPlayerLink){
                    controller.showPlayerLink();
                }
            });
        }
        this._remiderToShareTrebbleDisplayedToUser = true;
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("onboarding", "Clicked on suggestion popup to share Trebble to get listeners", "Clicked on suggestion popup to share Trebble to get listeners");
        }
    }).bind(this);
    var buttonLabels = [window.getI18n(ti18n, "IM_NOT_READY"),window.getI18n(ti18n, "GET_LISTENERS")];
    var confirmCallback = (function(buttonIndex){
        if(buttonIndex ==  2){
            shareTrebbleBtnClicked();
        }
    }).bind(this);
    var customImages  = ["goodjob1.gif","goodjob2.gif","goodjob3.gif","goodjob4.gif","goodjob5.gif","goodjob6.gif","goodjob7.gif","goodjob8.gif","goodjob9.gif","goodjob10.gif"];
    var randomImage = customImages[Math.floor(Math.random() * customImages.length)];
    var message = window.getI18n(ti18n, "ALL_YOU_NEED_NOW_IS_TO_SHARE");
    var customImageUrl = (window.waitForCordovaToLoad?"":"/") + "css/images/"+ randomImage;
    var customSize = window.getDocumentClienWidth() <= 450 ?"200x80":"383x138";
    //let title = this._remiderToCreateACapsuleDisplayedToUser?window.getI18n(ti18n, "CONGRATS_YOUR_VOICE_IS_ON_AIR"):window.getI18n(ti18n, "CONGRATS_YOUR_TREBBLE_IS_READY_TO_STREAM");
    
    Utils.getInstance().showSweetAlertDialog((function(){ navigator.trebbleNotification.confirm(window.getI18n(ti18n, "CONGRATS_YOUR_TREBBLE_IS_READY_TO_STREAM"), confirmCallback, "", buttonLabels,null , null, message, false, customImageUrl,customSize);}).bind(this),1000);
    if(window.trebbleAnalyticsHelper){
        window.trebbleAnalyticsHelper.trackEvent("onboarding", "View suggestion popup to share Trebble to get listeners", "View suggestion popup to share Trebble to get listeners");
    }
    
}
},

showSuccessMessageWithGifPopup : function(mainMessage, subMessage, buttonLabelsArray, onSuccessButtonClicked, gifFileArray){
var buttonLabels = buttonLabelsArray;
var confirmCallback = (function(buttonIndex){
    if(onSuccessButtonClicked){
        if(!buttonLabelsArray || buttonLabelsArray.length === 1 || (buttonLabelsArray.length === 2 && buttonIndex ===  2)){
            onSuccessButtonClicked();
        }
    }
}).bind(this);
var customImages  = gifFileArray? gifFileArray: ["goodjob1.gif","goodjob2.gif","goodjob3.gif","goodjob4.gif","goodjob5.gif","goodjob6.gif","goodjob7.gif","goodjob8.gif","goodjob9.gif","goodjob10.gif"];
var randomImage = customImages[Math.floor(Math.random() * customImages.length)];
var customImageUrl = (window.waitForCordovaToLoad?"":"/") + "css/images/"+ randomImage;
var customSize = window.getDocumentClienWidth() <= 450 ?"200x80":"383x138";


Utils.getInstance().showSweetAlertDialog((function(){
    navigator.trebbleNotification.confirm(mainMessage, confirmCallback, "", buttonLabels,null , null, subMessage, false, customImageUrl,customSize);
}).bind(this), 1000);
if(window.trebbleAnalyticsHelper){
    window.trebbleAnalyticsHelper.trackEvent("premiumFeatures", "We received payment for your creator subscription popup", "We received payment for your creator subscription popup");
}
},

showYouveDiscoveredPremiumFeaturePopup : function(mainMessage, subMessage){
var onUpgradeNowBtnClicked =  (function(){
    this.showUpgradeCreatorSubscriptionPopup();
}).bind(this);
var buttonLabels = [window.getI18n(ti18n, "OKAY_GOT_IT"),window.getI18n(ti18n, "UPGRADE_NOW")];
var confirmCallback = (function(buttonIndex){
    if(buttonIndex ==  2){
        onUpgradeNowBtnClicked();
    }
}).bind(this);
var customImages  = ["goodjob1.gif","goodjob2.gif","goodjob3.gif","goodjob4.gif","goodjob5.gif","goodjob6.gif","goodjob7.gif","goodjob8.gif","goodjob9.gif","goodjob10.gif"];
var randomImage = customImages[Math.floor(Math.random() * customImages.length)];
var message = subMessage? subMessage : window.getI18n(ti18n, "WITH_A_TREBBLE_CREATOR_PAID_SUBSCRIPTION_YOU_WILL_ACCESS_THIS_AND_MORE");
var customImageUrl = (window.waitForCordovaToLoad?"":"/") + "css/images/"+ randomImage;
var customSize = window.getDocumentClienWidth() <= 450 ?"200x80":"383x138";
var title = mainMessage?mainMessage:window.getI18n(ti18n, "YOU_VE_DISCOVERED_A_FEATURE_AVAILBLE_ON_PAID_SUBSCRIPTION");

Utils.getInstance().showSweetAlertDialog((function(){
    navigator.trebbleNotification.confirm(title, confirmCallback, "", buttonLabels,null , null, message, false, customImageUrl,customSize);
}).bind(this), 1000);
if(window.trebbleAnalyticsHelper){
    window.trebbleAnalyticsHelper.trackEvent("premiumFeatures", "You've discovered a premium feature popup", "You've discovered a premium feature popup");
}
},

showCongratulationsForJourneyPublishedOrScheduledPopup : function(journeyRadioId, journeyRadioName, journeyWasScheduled){
var shareJourneyBtnClicked =  (function(){
    PersistentModels.getInstance().getRouter().showShareJourneyPopup(journeyRadioId);
    if(window.trebbleAnalyticsHelper){
        window.trebbleAnalyticsHelper.trackEvent("journey", "Clicked on suggestion popup to share journey to get listeners", "Clicked on suggestion popup to share journey to get listeners");
    }
}).bind(this);
var buttonLabels = [window.getI18n(ti18n, "IM_NOT_READY"),window.getI18n(ti18n, "GET_LISTENERS")];
var confirmCallback = (function(buttonIndex){
    if(buttonIndex ==  2){
        shareJourneyBtnClicked();
    }
}).bind(this);
var customImages  = ["goodjob1.gif","goodjob2.gif","goodjob3.gif","goodjob4.gif","goodjob5.gif","goodjob6.gif","goodjob7.gif","goodjob8.gif","goodjob9.gif","goodjob10.gif"];
var randomImage = customImages[Math.floor(Math.random() * customImages.length)];
var message = window.getI18n(ti18n, "YOUR_JOURNEY") + ' "'+ journeyRadioName+'" ' +(journeyWasScheduled? window.getI18n(ti18n, "HAS_BEEN_SUCCESS_SCHEUDLED"):window.getI18n(ti18n, "HAS_BEEN_SUCCESS_PUBLISHED"))+". " +window.getI18n(ti18n, "ALL_YOU_NEED_NOW_IS_TO_SHARE");
var customImageUrl = (window.waitForCordovaToLoad?"":"/") + "css/images/"+ randomImage;
var customSize = window.getDocumentClienWidth() <= 450 ?"200x80":"383x138";
var title = window.getI18n(ti18n, "CONGRATULATIONS");

Utils.getInstance().showSweetAlertDialog((function(){ navigator.trebbleNotification.confirm(title, confirmCallback, "", buttonLabels,null , null, message, false, customImageUrl,customSize);}).bind(this),1000);

setTimeout((function(){
    Utils.getInstance().showConfetti();
}).bind(this), 2000)

if(window.trebbleAnalyticsHelper){
    window.trebbleAnalyticsHelper.trackEvent("journey", "Congratulation on journey launched displayed", "Congratulation on journey launched displayed");
}
},


showFirstSubscriptionAddedHighfivePopupIfApplicable : function(){
if(!LocalStorageHelper.getInstance().isHighFiveForAddingFirstSubscriptionWasDisplayed()){
    var buttonLabels = [window.getI18n(ti18n, "GOT_IT")+ " 👍"];
    var customImages  = ["goodjob1.gif","goodjob2.gif","goodjob3.gif","goodjob4.gif","goodjob5.gif","goodjob6.gif","goodjob7.gif","goodjob8.gif","goodjob9.gif","goodjob10.gif"];
    var randomImage = customImages[Math.floor(Math.random() * customImages.length)];
    var customImageUrl = (window.waitForCordovaToLoad?"":"/") + "css/images/"+ randomImage;
    var customSize = window.getDocumentClienWidth() <= 450 ?"200x80":"383x138";
    var title = Rollout.getFeatureVariable(Rollout.FEATURES.SHOW_SUGGESTION_TO_LISTEN_TO_FEED_ON_FIRST_SUBSCRIPTION, "label_yay_just_added_your_first_sub_"+window.getAppUsedLanguage(),window.getI18n(ti18n, "YAY_YOUVE_JUST_ADDED_YOUR_FIRST_SUBSCRIPTION"))
    var subtitle = Rollout.getFeatureVariable(Rollout.FEATURES.SHOW_SUGGESTION_TO_LISTEN_TO_FEED_ON_FIRST_SUBSCRIPTION, "label_add_2_or_3_more_"+window.getAppUsedLanguage(),window.getI18n(ti18n, "ADD_TWO_OR_THREE_MORE")+" 😀");

    Utils.getInstance().showSweetAlertDialog((function(){ 
        navigator.trebbleNotification.confirm(title, function(){}, "", buttonLabels,null , null, subtitle, false, customImageUrl,customSize);
    }).bind(this),1000);
    LocalStorageHelper.getInstance().setHighFiveForAddingFirstSubscriptionWasDisplayed(true);
    if(window.trebbleAnalyticsHelper){
        window.trebbleAnalyticsHelper.trackEvent("onboarding", "UserJustAddedFirstSubscription", "User just added his/her first subscription");
    }
}
},

showSuggestionToListenToFeedForFirstTimeWasDisplayedPopupIfApplicable : function(){
if(/*this.isUserStartedTheSessionWithNoSubscription() &&*/ !LocalStorageHelper.getInstance().isSuggestionToListenToFeedForFirstTimeWasDisplayed()){
    var buttonLabels = [window.getI18n(ti18n, "KEEP_ADDING"), window.getI18n(ti18n, "LISTEN_NOW")+ " 🎧"];
    var customImages  = ["listen_1.gif"];
    var randomImage = customImages[Math.floor(Math.random() * customImages.length)];
    var customImageUrl = (window.waitForCordovaToLoad?"":"/") + "css/images/"+ randomImage;
    var customSize = window.getDocumentClienWidth() <= 450 ?"200x80":"383x138";
    var title = Rollout.getFeatureVariable(Rollout.FEATURES.SHOW_SUGGESTION_TO_LISTEN_TO_FEED, "label_Its_a_great_time_to_listen_"+window.getAppUsedLanguage(),window.getI18n(ti18n, "NOW_THAT_YOU_HAVE_ENOUGH_SUBS_IS_A_GREAT_TIME_TO_LISTEN"));
    var confirmCallback = (function(buttonIndex){
        if(buttonIndex ==  2){
            setTimeout((function(){
                var playSubscription = Rollout.getFeatureVariable(Rollout.FEATURES.SHOW_SUGGESTION_TO_LISTEN_TO_FEED, Rollout.FEATURES.SHOW_SUGGESTION_TO_LISTEN_TO_FEED.variables.force_user_to_manually_push_play);
                PersistentModels.getInstance().getRouter().showDefaultPage(true, playSubscription);
                if(window.trebbleAnalyticsHelper){
                    window.trebbleAnalyticsHelper.trackEvent("onboarding", "UserAcceptedSuggestionToPlayFeedForFistTime", "User accepted suggestion to play feed for fist time");
                }
            }).bind(this), 100);

        }else{
            if(window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("onboarding", "UserDecideToContinueAddingSubscriptions", "User decided to continue adding subscriptions");
            }
            
        }
    }).bind(this);

    Utils.getInstance().showSweetAlertDialog((function(){ 
        navigator.trebbleNotification.confirm(title, confirmCallback, "", buttonLabels,null , null, null, false, customImageUrl,customSize);
    }).bind(this),1000);
    LocalStorageHelper.getInstance().setSuggestionToListenToFeedForFirstTimeWasDisplayed(true);
    if(window.trebbleAnalyticsHelper){
        window.trebbleAnalyticsHelper.trackEvent("onboarding", "SuggestionToListenToCapsuleFeedForFirstTime", "Suggestion To Listen To CapsuleFeed For First Time");
    }
}
},


showSuggestionToChangeCategoryOrLanguagePopupIfApplicable : function(){
if(!LocalStorageHelper.getInstance().isSuggestionToChangeCategoryOrLanguageDisplayed()){
    var buttonLabels = [window.getI18n(ti18n, "GOT_IT")+ " 👍"];
    var customImages  = ["cantfindwhatyouwant2.gif"];
    var randomImage = customImages[Math.floor(Math.random() * customImages.length)];
    var customImageUrl = (window.waitForCordovaToLoad?"":"/") + "css/images/"+ randomImage;
    var customSize = window.getDocumentClienWidth() <= 450 ?"200x80":"383x138";
    var title = Rollout.getFeatureVariable(Rollout.FEATURES.SUGGEST_CATEGORY_CHANGE_IN_LIST_VIEW_IN_BROWSE_PAGE, "label_cant_find_what_to_subscribe_to_"+window.getAppUsedLanguage(),window.getI18n(ti18n, "CANT_FIND_WHAT_TO_SUBSCRIBE_TO"));
    var subtitle = null;

    Utils.getInstance().showSweetAlertDialog((function(){ 
        navigator.trebbleNotification.confirm(title, function(){}, "", buttonLabels,null , null, subtitle, false, customImageUrl,customSize);
    }).bind(this),1000);
    LocalStorageHelper.getInstance().setSuggestionToChangeCategoryOrLanguageDisplayed(true);
    if(window.trebbleAnalyticsHelper){
        window.trebbleAnalyticsHelper.trackEvent("onboarding", "UserCantFindWhatToSubscribeTo", "User cannot find what to subscribe to");
    }
    return true;
}else{
    return false;
}
},

showExplorePageWelcomeMessageIfApplicable : function(){
var loggedInUserInfo = LocalStorageHelper.getInstance().getUserInfo();
var isTemporaryUser = loggedInUserInfo ? loggedInUserInfo.temporary : false;
if(false && isTemporaryUser  && !window._explorePageWelcomePageWasDisplayedInThisSession && this.getFirstPageUrl() && this.getFirstPageUrl().indexOf("#ExplorePage") != -1){
    
    var params= {};
    return this.require([ "controllers/welcomeNewUser/WelcomeNewListenerPopupController"]).then((function(WelcomeNewListenerPopupController){
        var welcomeNewListenerPopupController = new WelcomeNewListenerPopupController(params);
        this.setWelcomeNewUserPageDisplayedInSession(true);
        return this.renderPopupAndDisplayPage (welcomeNewListenerPopupController, "Welcome New Listener Popup")
    }).bind(this));
}
},


showSuggestionPopupToCreateACapsuleIfApplicable : function(){
if(!this._remiderToCreateACapsuleDisplayedToUser && !PersistentModels.getInstance().hasCapsuleCreated() && !window._capsuleCreatedInThisSession){
    var onIntroduceYourTrebbleBtnClicked =  (function(){
        this.showCreateACapsulePageWithoutContext();
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("onboarding", "Clicked on introduce  Trebble to the world", "Clicked on introduce  Trebble to the world");
        }
    }).bind(this);
    var buttonLabels = [window.getI18n(ti18n, "IM_NOT_READY"), window.getI18n(ti18n, "INTRODUCE_YOUR_TREBBLE")];
    var confirmCallback = (function(buttonIndex){
        if(buttonIndex ==  2){
            onIntroduceYourTrebbleBtnClicked();
        }
    }).bind(this);
    var customImages  = ["goodjob1.gif","goodjob2.gif","goodjob3.gif","goodjob4.gif","goodjob5.gif","goodjob6.gif","goodjob7.gif","goodjob8.gif","goodjob9.gif","goodjob10.gif"];
    var randomImage = customImages[Math.floor(Math.random() * customImages.length)];
    var message = window.getI18n(ti18n, "CREATE_A_CAPSULE_TO_INTRODUCE_YOUR_TREBBLE");
    var customImageUrl = (window.waitForCordovaToLoad?"":"/") + "css/images/"+ randomImage;
    var customSize = window.getDocumentClienWidth()  <= 450 ?"200x80":"383x138";
    
    this._remiderToCreateACapsuleDisplayedToUser = true;
    Utils.getInstance().showSweetAlertDialog((function(){ navigator.trebbleNotification.confirm(window.getI18n(ti18n, "CONGRATS_YOUR_TREBBLE_IS_READY_TO_STREAM"), confirmCallback, "", buttonLabels,null , null, message, false, customImageUrl,customSize);}).bind(this),1000);
    
    
    if(window.trebbleAnalyticsHelper){
        window.trebbleAnalyticsHelper.trackEvent("onboarding", "View suggestion popup to create capsule", "View suggestion popup to create capsule");
    }
    
}else{
    this.showSuggestionPopupToShareTrebbleIfApplicable();
}
},


showTrebbleSharePopupByRadioId : function(radioId){
return TrebbleClientAPIHelper.getInstance().getMyTrebbleInfoByTrebbleId(radioId).then((function(radioJsonInfo){
    if(radioJsonInfo){
        var personalRadioSummaryModel = Utils.getInstance().buildPlaylistSummaryFromJson(radioJsonInfo);
        return this.showTrebbleSharePopup(personalRadioSummaryModel, null);
    }else{
        window.alertErrorMessage(window.getI18n(ti18n,"UNABLE_TO_FIND_THIS_TREBBLE"));
    }
}).bind(this));
},

showSongListInUserLibraryPageByUsername : function(username){
var pageId = encodeURIComponent("songListPageInUserLibrary_"+username);
var pageName = "Song Search Result In User Personal Library";
this.navigateToPageWithId( pageId,pageName  , true,false);
return RSVP.Promise.resolve();
},

showSongListInUserLibraryPage : function(username, triggerHashChange){
triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageId = encodeURIComponent("songListPageInUserLibrary_"+username +"_"+ (new Date()).getTime() );
controllerParams.pageId  = pageId;
controllerParams.username  = username;
var pageName = "Song Search Result In User Personal Library";
return this.require(["controllers/songListPageController/SongInUserLibraryPageController"]).then((function(SongInUserLibraryPageController){
    var songInUserLibraryPageController  = new SongInUserLibraryPageController(controllerParams);
    return this.renderAndDisplayPage(songInUserLibraryPageController,pageId,pageName, true,triggerHashChange);
}).bind(this));


},



showAlbumListPage : function(searchKeyword, triggerHashChange){
triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageTitle ="\""+searchKeyword.trim()+ "\" in Albums";
var pageId = encodeURIComponent("albumListPage_"+searchKeyword);
var pageName = "Album List Page";

if(this.isJQMPageWithIdExist(pageId)){
    this.navigateToPageWithId( pageId,pageName  , true,triggerHashChange);
    return RSVP.Promise.resolve();
}else{
    controllerParams.pageTitle = pageTitle;
    controllerParams.pageId  = pageId;
    controllerParams.searchKeyword  = searchKeyword;
    return this.require(["controllers/albumListPageController/AlbumListPageController"]).then((function(AlbumListPageController){
        var albumListPageController  = new AlbumListPageController(controllerParams);
        return this.renderAndDisplayPage(albumListPageController,pageId,pageName, true,triggerHashChange);
    }).bind(this));

}
},


launchAppInNewTab : function(){
var openedWindow = window.open(TREBBLE_WEB_APP_HOME_PAGE , "_blank");
if((!openedWindow || openedWindow.closed || typeof openedWindow.closed=='undefined') && !window.waitForCordovaToLoad){
    window.alertErrorMessage(window.getI18n(ti18n,"PLEASE_ALLOW_POPUPS"));
}
return openedWindow;
},	

showAllAlbumListPage : function(artistId, albumLoadHandler, pageTitle, triggerHashChange){
triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageId = encodeURIComponent("albumListPageForArtist_"+artistId);
var pageName = "All Album List Page For Artist";

if(this.isJQMPageWithIdExist(pageId)){
    this.navigateToPageWithId( pageId,pageName  , true,triggerHashChange);
    return RSVP.Promise.resolve();
}else{
    controllerParams.pageTitle = pageTitle;
    controllerParams.pageId  = pageId;
    controllerParams.albumListLoadHandler = albumLoadHandler;
    //controllerParams.searchKeyword  = searchKeyword;
    return this.require(["controllers/albumListPageController/AlbumListPageController"]).then((function(AlbumListPageController){
        var albumListPageController  = new AlbumListPageController(controllerParams);
        return this.renderAndDisplayPage(albumListPageController,pageId,pageName, true,triggerHashChange);
    }).bind(this));

}
},


showAllSongsListPage : function(songLoadHandler,pageTitle, triggerHashChange){

triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageId = encodeURIComponent("songListPage_"+(new Date()).getTime());
var pageName = "Custom Songs List Page";

if(this.isJQMPageWithIdExist(pageId)){
    this.navigateToPageWithId( pageId,pageName  , true,triggerHashChange);
    return RSVP.Promise.resolve();
}else{
    controllerParams.pageTitle = pageTitle;
    controllerParams.pageId  = pageId;
    controllerParams.songListLoadHandler = songLoadHandler;
    controllerParams.doNotSortDisplayedCollection = true;
    //controllerParams.searchKeyword  = searchKeyword;
    return this.require(["controllers/songListPageController/SongListPageController"]).then((function(SongListPageController){
        var songListPageController  = new SongListPageController(controllerParams);
        return this.renderAndDisplayPage(songListPageController,pageId,pageName, true,triggerHashChange);
    }).bind(this));

}

},

showAllRelatedArtistListPage : function(artistId, artistLoadHandler, pageTitle, triggerHashChange){
triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageId = encodeURIComponent("relatedArtistListPageForArtist_"+artistId);
var pageName = "All Related Artist List Page For Artist";

if(this.isJQMPageWithIdExist(pageId)){
    this.navigateToPageWithId( pageId,pageName  , true,triggerHashChange);
    return RSVP.Promise.resolve();
}else{
    controllerParams.pageTitle = pageTitle;
    controllerParams.pageId  = pageId;
    controllerParams.artistListLoadHandler = artistLoadHandler;
    //controllerParams.searchKeyword  = searchKeyword;

    return this.require(["controllers/artistListPageController/ArtistListPageController"]).then((function(ArtistListPageController){
        var artistListPageController  = new ArtistListPageController(controllerParams);
        return this.renderAndDisplayPage(artistListPageController,pageId,pageName, true,triggerHashChange);
    }).bind(this));

}
},





showFollowerListPageByUsername : function(username){
var pageId = encodeURIComponent("followerPageForUser_"+username);
var pageName = "List Of Followers Page";
this.navigateToPageWithId( pageId,pageName  , true,false);
},

showFollowerListPage : function(username, triggerHashChange){
triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageId = encodeURIComponent("followerPageForUser_"+username+"_"+(new Date()).getTime());
controllerParams.pageId  = pageId;
controllerParams.username  = username;
var pageName = "List Of Followers Page";
return this.require(["controllers/user/FollowersListPageController"]).then((function(FollowersListPageController){
    var followersListPageController  = new FollowersListPageController(controllerParams);
    return this.renderAndDisplayPage(followersListPageController,pageId,pageName, true,triggerHashChange);
}).bind(this));

},

showFollowedTrebblesListPageByUsername : function(username){
var pageId = encodeURIComponent("followedTrebblesPageForUser_"+username);
var pageName = "Subscription Trebble For User With Username Page";
this.navigateToPageWithId( pageId,pageName  , true,false);
return RSVP.Promise.resolve();
},

showFollowedTrebblesListPage : function(username, triggerHashChange){
triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageId = encodeURIComponent("followedTrebblesPageForUser_"+username+"_"+(new Date()).getTime());
controllerParams.pageId  = pageId;
controllerParams.username  = username;
var pageName = "Subscription Trebble For User With Username Page";
return this.require(["controllers/playlistPodcast/FollowedTrebbleListPageController"]).then((function(FollowedTrebbleListPageController){
    var followedTrebbleListPageController  = new FollowedTrebbleListPageController(controllerParams);
    return this.renderAndDisplayPage(followedTrebbleListPageController,pageId,pageName, true,triggerHashChange);
}).bind(this));

},


showFollowingListPageByUsername : function(username){
var pageId = encodeURIComponent("followingPageForUser_"+username);
var pageName = "Following Users By Username"

this.navigateToPageWithId( pageId,pageName  , true,false);
return RSVP.Promise.resolve();
},

showFollowingListPage : function(username, triggerHashChange){
triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageId = encodeURIComponent("followingPageForUser_"+username+"_"+(new Date()).getTime());
controllerParams.pageId  = pageId;
controllerParams.username  = username;
var pageName = "Following Users By Username";
return this.require(["controllers/user/FollowingListPageController"]).then((function(FollowingListPageController){
    var followingListPageController  = new FollowingListPageController(controllerParams);
    return this.renderAndDisplayPage(followingListPageController,pageId,pageName, true,triggerHashChange);
}).bind(this));

},


isJQMPageWithIdExist : function(pageId){
var pageElement = document.getElementById(pageId);
if(pageElement && $(pageElement).attr("data-role") == "page"){
    return true;
}else{
    return false;
}
},
showArtistListPage : function(searchKeyword, triggerHashChange){
triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageTitle ="\""+searchKeyword.trim()+ "\" in Artists";
var pageId = encodeURIComponent("artistListPage_"+searchKeyword );
controllerParams.pageTitle = pageTitle;
controllerParams.pageId  = pageId;
controllerParams.searchKeyword  = searchKeyword;
var pageName = "Artist Search List Page";
if(this.isJQMPageWithIdExist(pageId)){
    this.navigateToPageWithId( pageId,pageName  , true,triggerHashChange);
    return RSVP.Promise.resolve();
}else{
    return this.require(["controllers/artistListPageController/ArtistListPageController"]).then((function(ArtistListPageController){
        var artistListPageController  = new ArtistListPageController(controllerParams);
        return this.renderAndDisplayPage(artistListPageController,pageId,pageName, true,triggerHashChange);
    }).bind(this));

}
},

showSoundcloudSongListPage : function(searchKeyword, triggerHashChange){
triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageTitle ="\""+searchKeyword.trim()+ "\" on Soundcloud";
var pageId = encodeURIComponent("soundcloudSongListPage_"+searchKeyword );
controllerParams.pageTitle = pageTitle;
controllerParams.pageId  = pageId;
controllerParams.searchKeyword  = searchKeyword;

var pageName = "Souncloud Search List Page";
if(this.isJQMPageWithIdExist(pageId)){
    this.navigateToPageWithId( pageId,pageName  , true,triggerHashChange);
    return RSVP.Promise.resolve();
}else{
    return this.require(["controllers/soundcloudSongListPageController/SoundcloudSongListPageController"]).then((function(SoundcloudSongListPageController){
        var soundcloudSongListPageController  = new SoundcloudSongListPageController(controllerParams);
        return this.renderAndDisplayPage(soundcloudSongListPageController,pageId,pageName, true,triggerHashChange);
    }).bind(this));

}
},

showYoutubeVideoListPage : function(searchKeyword, triggerHashChange){
triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageTitle ="\""+searchKeyword.trim()+ "\" on Youtube";
var pageId =encodeURIComponent( "youtubeVideoListPage_"+searchKeyword);
controllerParams.pageTitle = pageTitle;
controllerParams.pageId  = pageId;
controllerParams.searchKeyword  = searchKeyword;
var pageName = "Youtube Search List Page";

if(this.isJQMPageWithIdExist(pageId)){
    this.navigateToPageWithId( pageId,pageName  , true,triggerHashChange);
    return RSVP.Promise.resolve();
}else{
    return this.require(["controllers/youtubeListPageController/YoutubeListPageConttroller"]).then((function(YoutubeListPageConttroller){
        var youtubeListPageConttroller  = new YoutubeListPageConttroller(controllerParams);
        return this.renderAndDisplayPage(youtubeListPageConttroller,pageId,pageName, true,triggerHashChange);
    }).bind(this));
}
},


showTrebbleListPageBySearchKeyword : function(keyword){
var pageId = encodeURIComponent("searchForTrebbleForKeyword_"+keyword);
var pageName = "Trebble Search List Page";
this.navigateToPageWithId( pageId,pageName  , true,false);
return RSVP.Promise.resolve();
},


showJourneyListPageBySearchKeyword : function(keyword){
var pageId = encodeURIComponent("searchForJourneyForKeyword_"+keyword);
var pageName = "Journey Search List Page";
this.navigateToPageWithId( pageId,pageName  , true,false);
return RSVP.Promise.resolve();
},

showTrebbleListPage : function(keyword, triggerHashChange){
triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageId = encodeURIComponent("searchForTrebbleForKeyword_"+keyword+"_"+(new Date()).getTime());
controllerParams.pageId  = pageId;
controllerParams.searchKeyword  = keyword;
controllerParams.displayedInPageWithNoTab  = true;
return this.require(["controllers/playlistPodcast/PlaylistListPageController"]).then((function(PlaylistListPageController){
    var playlistListPageController  = new PlaylistListPageController(controllerParams);
    var pageName = "Trebble Search List Page";
    return this.renderAndDisplayPage(playlistListPageController,pageId,pageName, true,triggerHashChange);
}).bind(this));

},


showJourneyListPage : function(keyword, triggerHashChange){
triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageId = encodeURIComponent("searchForJourneyForKeyword_"+keyword+"_"+(new Date()).getTime());
controllerParams.pageId  = pageId;
controllerParams.searchKeyword  = keyword;
controllerParams.displayedInPageWithNoTab  = true;
return this.require(["controllers/journey/JourneyListPageController"]).then((function(JourneyListPageController){
    var journeyListPageController  = new JourneyListPageController(controllerParams);
    var pageName = "Journey Search List Page";
    return this.renderAndDisplayPage(journeyListPageController,pageId,pageName, true,triggerHashChange);
}).bind(this));

},
showUserListPageBySearchKeyword : function(keyword){
var pageId = encodeURIComponent("searchForUsersForKeyword_"+keyword);
var pageName = "User Search List Page";
this.navigateToPageWithId( pageId,pageName  , true,false);
return RSVP.Promise.resolve();
},

showUserListPage : function(keyword, triggerHashChange){
triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageId = encodeURIComponent("searchForUsersForKeyword_"+keyword+"_"+(new Date()).getTime());
controllerParams.pageId  = pageId;
controllerParams.searchKeyword  = keyword;
return this.require(["controllers/user/UserListPageController"]).then((function(UserListPageController){
    var userListPageController  = new UserListPageController(controllerParams);
    var pageName = "User Search List Page";
    return this.renderAndDisplayPage(userListPageController,pageId,pageName, true,triggerHashChange);
}).bind(this));

},



showArtistDetailPage : function(artistId, artistModelOrJSON, triggerHashChange){
triggerHashChange = triggerHashChange || false;
var pageId = encodeURIComponent(artistId?"artistDetailsPage_"+ artistId: "artistDetailsPage_" +  artistModel.get("artistId"));
var pageName = "Artist Detail Page";
if(this.isJQMPageWithIdExist(pageId)){
    this.navigateToPageWithId( pageId,pageName  , true,triggerHashChange,null, null, true);
    return RSVP.Promise.resolve();
}else{
    var artistModel =  null;
    if(artistModelOrJSON)
    {
        if(artistModelOrJSON instanceof Artist)
        {
            artistModel = artistModelOrJSON;
        }else{
            var exisitingArtistModel = PersistentModels.getInstance().getArtistModelByArtistIdFromMyLibrary(artistModelOrJSON.artistId);
            if(!exisitingArtistModel)
            {
                artistModel = Utils.getInstance().getModelFromArtistJson(artistModelOrJSON);
            }else{
                artistModel = exisitingArtistModel;
            }
        }
    }else{
        artistModel =  PersistentModels.getInstance().getArtistModelByArtistIdFromMyLibrary(artistId);
    }
    var artistDetailPageControllerParams = {};
    artistDetailPageControllerParams.model = artistModel;
    artistDetailPageControllerParams.pageId =  pageId;
    return this.require(["controllers/artistDetail/ArtistDetailController"]).then((function(ArtistDetailController){
        var artistDetailController  = new ArtistDetailController(artistDetailPageControllerParams);

        return this.renderAndDisplayPage(artistDetailController,pageId,pageName, true,triggerHashChange,null, true);
    }).bind(this));
}

},


showArtistFullDetailPage : function(artistId, artistName, artistModelOrJSON, triggerHashChange){
triggerHashChange = triggerHashChange || false;
var pageId = encodeURIComponent(artistId?"artistFullDetailsPage_"+ artistId: "artistFullDetailsPage_" +  artistModel.get("artistId"));
var pageName = "Artist Full Detail Page";
if(this.isJQMPageWithIdExist(pageId)){
    this.navigateToPageWithId( pageId,pageName  , true,triggerHashChange,null, null, true);
    return RSVP.Promise.resolve();
}else{
    var artistModel =  null;
    if(artistModelOrJSON)
    {
        if(artistModelOrJSON instanceof Artist)
        {
            artistModel = artistModelOrJSON;
        }else{
            var exisitingArtistModel = PersistentModels.getInstance().getArtistModelByArtistIdFromMyLibrary(artistModelOrJSON.artistId);
            if(!exisitingArtistModel)
            {
                artistModel = Utils.getInstance().getModelFromArtistJson(artistModelOrJSON);
            }else{
                artistModel = exisitingArtistModel;
            }
        }
    }else{
        artistModel =  PersistentModels.getInstance().getArtistModelByArtistIdFromMyLibrary(artistId);
    }
    var artistDetailPageControllerParams = {};
    artistDetailPageControllerParams.artistId = artistId;
    artistDetailPageControllerParams.artistModel = artistModel;
    artistDetailPageControllerParams.artistName = artistName;
    artistDetailPageControllerParams.pageId =  pageId;
    return this.require(["controllers/artistDetail/ArtistFullDetailController"]).then((function(ArtistFullDetailController){
        var artistFullDetailController  = new ArtistFullDetailController(artistDetailPageControllerParams);
        return this.renderAndDisplayPage(artistFullDetailController,pageId,pageName, true,triggerHashChange, null, true);
    }).bind(this));
}

},

showQueuePage : function(triggerHashChange){
triggerHashChange = triggerHashChange || false;
var queueControllerParams = {};
queueControllerParams.model = PersistentModels.getInstance().getPlayer().getQueue();
return this.require([ "controllers/queue/QueueController"]).then((function(QueueController){
    var queueController  = new QueueController(queueControllerParams);
    var pageName = "Queue Page";
    var pageId = "queue";
    return this.renderAndDisplayPage(queueController,pageId,pageName, false,triggerHashChange);
}).bind(this));

},

showSongMenuOption : function(songModel, context, songRefSummary, onActionExecutedOnSongHandler){
var params = {};
params.songModel = songModel;
params.context = context;
params.songRefSummary =  songRefSummary;
params.onActionExecutedOnSongHandler = onActionExecutedOnSongHandler;
return this.require([ "controllers/myLibrary/SongOptionController"]).then((function(SongOptionController){
    var songOptionController = new SongOptionController(params);
    return this.renderPopupAndDisplayPage (songOptionController, "Song Menu Option");
}).bind(this));



},

showSkipCurrentPlayingWithReasonPopup : function(/*sourceElement$el*/){
var params = {};
params.sourceElement$el = null;//sourceElement$el;
return this.require([ "controllers/player/SkipCurrentPlayingSongWithReasonController"]).then((function(SkipCurrentPlayingSongWithReasonController){
    var skipCurrentPlayingSongWithReasonController = new SkipCurrentPlayingSongWithReasonController(params);
    return this.renderPopupAndDisplayPage(skipCurrentPlayingSongWithReasonController, "Skip Song With Reason");
}).bind(this));
},

showWhatsNewPopupIfApplicable : function(){
if( !LocalStorageHelper.getInstance().isWhatsNewPageAlreadyShowedToUser() ){
    var params= {};
    return this.require([ "controllers/whatsnew/WhatsNewPopupController"]).then((function(WhatsNewPopupController){
        var whatsNewPopupController = new WhatsNewPopupController(params);
        return this.renderPopupAndDisplayPage (whatsNewPopupController, "Whats New Popup")
    }).bind(this));

}


},

setWelcomeNewUserPageDisplayedInSession : function(welcomeNewUserPageDisplayedInSession){
this._welcomeNewUserPageDisplayedInSession = welcomeNewUserPageDisplayedInSession;

},

isWelcomeNewUserPageDisplayedInSession : function(){
return this._welcomeNewUserPageDisplayedInSession;

},


setTrebbleWasSetupInCurrentSession :function(trebbleWasSetupInCurrentSession){
this._trebbleWasSetupInCurrentSession = trebbleWasSetupInCurrentSession;
},

getTrebbleWasSetupInCurrentSession : function(){
return 	this._trebbleWasSetupInCurrentSession;
},

showWelcomeNewUserPopupIfApplicable : function(){
if(ENABLE_WELCOME_POPUP && ((this.didSignUpOccuredInCurrentSession() && !this.isWelcomeNewUserPageDisplayedInSession()) || this.getTrebbleWasSetupInCurrentSession())){
    var params= {};
    return this.require([ "controllers/welcomeNewUser/WelcomeNewUserPopupController"]).then((function(WelcomeNewUserPopupController){
        var welcomeNewUserPopupController = new WelcomeNewUserPopupController(params);
        this.setWelcomeNewUserPageDisplayedInSession(true);
        return this.renderPopupAndDisplayPage (welcomeNewUserPopupController, "Welcome New User Popup")
    }).bind(this));

}
},

showWelcomeNewListenerPopupIfApplicable : function(){
//TODO: The condition on 'isUserAllowToSetupATrebble' should be removed because it was added for the Webby Awards
if(ENABLE_WELCOME_POPUP && ((this.didSignUpOccuredInCurrentSession() && !this.isWelcomeNewUserPageDisplayedInSession()) || this.getTrebbleWasSetupInCurrentSession() || LocalStorageHelper.getInstance().isUserAllowToSetupATrebble())){
    var params= {};
    return this.require([ "controllers/welcomeNewUser/WelcomeNewListenerPopupController"]).then((function(WelcomeNewListenerPopupController){
        var welcomeNewListenerPopupController = new WelcomeNewListenerPopupController(params);
        this.setWelcomeNewUserPageDisplayedInSession(true);
        return this.renderPopupAndDisplayPage (welcomeNewListenerPopupController, "Welcome New Listener Popup")
    }).bind(this));

}
},

showSuggestionForContentImprovmentPopupIfApplicable : function(){
if(/*this.isUserStartedTheSessionWithNoSubscription() &&*/ !LocalStorageHelper.getInstance().isRequestToGiveFeedBackOnContentWasDisplayed()){
    var message = Rollout.getFeatureVariable(Rollout.FEATURES.ASK_FOR_SHORTCASTS_USER_IS_INTERESTED_IN, "label_let_us_know_what_you_want_to_hear_"+window.getAppUsedLanguage(),window.getI18n(ti18n, "LET_US_KNOW_WHAT_YOU_LL_LIKE_TO_HEAR"));
    var inputFieldPlaceholder = Rollout.getFeatureVariable(Rollout.FEATURES.ASK_FOR_SHORTCASTS_USER_IS_INTERESTED_IN, "label_enter_your_suggesion_here_"+window.getAppUsedLanguage(),window.getI18n(ti18n, "LET_US_KNOW_HOW_WE_CAN_DO_BETTER_HERE"));
    var p =  this.showRequestFeedbackFromUserPopup("suggestionForContentImprovment", message, null, null, inputFieldPlaceholder);
    LocalStorageHelper.getInstance().setRequestToGiveFeedBackOnContentWasDisplayed();
    if(window.trebbleAnalyticsHelper){
        window.trebbleAnalyticsHelper.trackEvent("appSuggestion", "SuggestionOnContentFormDisplayed", "Suggestion On Content Form Displayed");
    } 
    return p;
}
},


showSuggestionOnHowToImproveTheAppPopup : function(){
var p = this.showRequestFeedbackFromUserPopup("suggestionToImproveApp", window.getI18n(ti18n,"MIND_GIVING_SOME_FEEDBACK"));
if(window.trebbleAnalyticsHelper){
    window.trebbleAnalyticsHelper.trackEvent("appSuggestion", "SuggestionOnHowToImproveAppFormDisplayed", "Suggestion On How To Improve App Form Displayed");
} 
return p;
},

showRequestFeedbackFromUserPopup : function(feedbackType, headlineText, imageUrl, buttonLabel, textareaPlaceholder){
var params= {};
params.headline =  headlineText;
params.imageUrl =  imageUrl;
params.feedbackType =  feedbackType;
params.buttonLabel =  buttonLabel;
params.textareaPlaceholderText =  textareaPlaceholder;
return this.require([ "controllers/userGivesFeedback/UserGivesFeedbackPopupController"]).then((function(UserGivesFeedbackPopupController){
    var userGivesFeedbackPopupController = new UserGivesFeedbackPopupController(params);
    return this.renderPopupAndDisplayPage (userGivesFeedbackPopupController, "User give feedback Popup")
}).bind(this));
},


showAppRatingRequestIfApplicable : function(forceShowingAppRating, simpleMode){
if(window.AppRate && (forceShowingAppRating || Utils.getInstance().isDialogToRateAppShouldBeDisplayed())){
    this.configureAppRatePluginIfApplicable();
    if(window.AppRate && window.AppRate.preferences){
        window.AppRate.preferences.simpleMode = simpleMode; 
    }
    if(window.AppRate){
        window.AppRate.promptForRating(true);
    }
    LocalStorageHelper.getInstance().setLastTimeUserWasPromptedToRateTheApp();
}	

},

logOut : function(){
var buttonLabels = [window.getI18n(ti18n, "NO"), window.getI18n(ti18n, "YES")];
var confirmCallback = (function(buttonIndex){
    if(buttonIndex ==  2){
        this.logOutExecute();
    }
}).bind(this);
var message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_LOG_OUT");
navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n, "LOG_OUT"), buttonLabels);
},



reloadApp : function(){
//window.trebble.helper.requirePromise(["models/helper/FacebookHelper"]).then((function(FacebookHelper){
   // window.trebble.helper.requirePromise(["models/helper/GooglePlusHelper"]).then((function(GooglePlusHelper){
        if(navigator.splashscreen){
            navigator.splashscreen.show();
        }

        document.body.style.display="none";
        if(window.device && window.device.platform =="browser"){
            var currentUrl = document.location.href;
            if(currentUrl.indexOf("https://") == 0){
                document.location.href = currentUrl.substr(0,currentUrl.indexOf("/",("https://").length));
            }else{
                if(currentUrl.indexOf("http://") == 0){
                    document.location.href = currentUrl.substr(0,currentUrl.indexOf("/",("http://").length));
                }else{
                    if(window.waitForCordovaToLoad){
                        window.location = window.location.href.substr(0,window.location.href.indexOf("#"))//remove hash
                    }else{
                        document.location.href =  "https://web.trebble.fm";
                    }
                }
            }
        }else{
            document.location.href = "trebbleLibrary140.html";
            document.location.reload(); 
        }
        //navigator.app.loadUrl("file:///android_asset/www/index.html", {wait:2000, loadingDialog:"Wait,Loading App", loadUrlTimeoutValue: 60000});
  //  }).bind(this))
//}).bind(this))
},

logOutExecute : function(){
LocalStorageHelper.getInstance().logoutUserAndClearInfo();
if(navigator.splashscreen){
    navigator.splashscreen.show();
}

window.clearUserIdForTracking();
this.require(["models/helper/FacebookHelper"]).then((function(FacebookHelper){
    this.require(["models/helper/GooglePlusHelper"]).then((function(GooglePlusHelper){
        if(window.waitForCordovaToLoad){
            FacebookHelper.getInstance().logout();
            GooglePlusHelper.getInstance().logout();
        }

        document.body.style.display="none";
        if(window.device && window.device.platform =="browser"){
            var currentUrl = document.location.href;
            if(currentUrl.indexOf("https://") == 0){
                document.location.href = currentUrl.substr(0,currentUrl.indexOf("/",("https://").length));
            }else{
                if(currentUrl.indexOf("http://") == 0){
                    document.location.href = currentUrl.substr(0,currentUrl.indexOf("/",("http://").length));
                }else{
                    if(window.waitForCordovaToLoad){
                        window.location = window.location.href.substr(0,window.location.href.indexOf("#"))//remove hash
                    }else{
                        document.location.href =  "https://web.trebble.fm";
                    }
                }
            }
        }else{
            document.location.href = "trebbleLibrary140.html";
            document.location.reload(); 
        }
        //navigator.app.loadUrl("file:///android_asset/www/index.html", {wait:2000, loadingDialog:"Wait,Loading App", loadUrlTimeoutValue: 60000});
    }).bind(this))
}).bind(this))
},

navigateToTheAppStoreToRateTheApp : function(){
if(window.AppRate){
    this.configureAppRatePluginIfApplicable()
    window.AppRate.navigateToAppStore();
}
},

configureAppRatePluginIfApplicable : function(){
if(window.AppRate && (!window.AppRate.preferences || !window.AppRate.preferences._isConfiguredByTrebble)){

    window.AppRate.preferences = {
        displayAppName: 'Trebble FM',
        usesUntilPrompt: 5,
        promptAgainForEachNewVersion: false,
        inAppReview: true,
        useLanguage : window.getAppUsedLanguage(),
        storeAppURL: {
            ios: '1450812196',
            android: 'market://details?id=fm.trebble',
        },
        customLocale: {
            title: window.getI18n(ti18n, "WOULD_YOU_MIND_RATING"),
            message: window.getI18n(ti18n, "IT_WONT_TAKE_MORE_THAN_A_MINUTE"),
            cancelButtonLabel:  window.getI18n(ti18n, "NO_THANKS"),
            laterButtonLabel:  window.getI18n(ti18n, "REMIND_ME_LATER"),
            rateButtonLabel:  window.getI18n(ti18n, "RATE_NOW"),
            yesButtonLabel:  window.getI18n(ti18n, "YES_BANG"),
            noButtonLabel:  window.getI18n(ti18n, "NOT_REALLY"),
            appRatePromptTitle:  window.getI18n(ti18n, "DO_YOU_LIKE_USING"),
            feedbackPromptTitle:  window.getI18n(ti18n, "MIND_GIVING_SOME_FEEDBACK_SENTENCE"),
        },
        callbacks: {
            handleNegativeFeedback: (function(){
                LocalStorageHelper.getInstance().incrementNumberOfTimesUserSentSuggestionToImproveTheApp();
                if(LiveChatWidgetHelper.getInstance().isAvailable() && RolloutHelper.getInstance().getFeatureVariable(RolloutHelper.FEATURES.PROMPT_USER_TO_RATE_APP, RolloutHelper.FEATURES.PROMPT_USER_TO_RATE_APP.variables.use_intercom_to_send_suggestion)){
                    var intialMessage = Rollout.getFeatureVariable(Rollout.FEATURES.PROMPT_USER_TO_RATE_APP, "label_how_i_think_the_app_could_be_improved_"+window.getAppUsedLanguage(),window.getI18n(ti18n, "HOW_I_THINK_THE_APP_COULD_BE_IMPROVED"))
                    LiveChatWidgetHelper.getInstance().displayMessageComposerWithInitialMessage(intialMessage);
                }else{
                    this.showSuggestionOnHowToImproveTheAppPopup();
                }
            }).bind(this),
            onRateDialogShow: function(callback){
                callback(1) // cause immediate click on 'Rate Now' button
            },
            onButtonClicked: function(buttonIndex){
                if(buttonIndex == 0 ){
                    LocalStorageHelper.getInstance().incrementNumberOfTimesUserRefuseToRateTheApp();
                }

                if(buttonIndex == 1 ){
                    LocalStorageHelper.getInstance().incrementNumberOfTimesUserPostponedToRateTheApp();
                }

                if(buttonIndex == 2 ){
                    LocalStorageHelper.getInstance().setUserRatedTheApp();
                }

            }
        }
    };
    if(window.AppRate){
        window.AppRate.preferences.openUrl = function (url) {
        if (!window.SafariViewController) {
            window.open(url, '_system', 'location=yes');
        }
        else {
            window.SafariViewController.isAvailable(function (available) {
                if (available) {
                    window.SafariViewController.show({
                        url: url,
                        barColor: "#0000ff", // on iOS 10+ you can change the background color as well
                        controlTintColor: "#00ffff", // on iOS 10+ you can override the default tintColor
                        tintColor: "#00ffff", // should be set to same value as controlTintColor and will be a fallback on older ios
                    },
                    // this success handler will be invoked for the lifecycle events 'opened', 'loaded' and 'closed'
                    function(result) {
                        if (result.event === 'opened') {
                            console.log('opened');
                        } else if (result.event === 'loaded') {
                            console.log('loaded');
                        } else if (result.event === 'closed') {
                            console.log('closed');
                        }
                    },
                    function(msg) {
                        console.log("error: " + msg);
                    })
                } else {
                    window.open(url, '_blank', 'location=yes');
                }
            });
        }
    }
    }

    window.AppRate.preferences._isConfiguredByTrebble = true;
}
},



showCapsulesForSongWithId : function(songId){
$.mobile.loading("show");
return TrebbleClientAPIHelper.getInstance().getSongInfoBySongId(songId).then((function(songJson){
    $.mobile.loading("hide");
    if(songJson){
        var songModel = Utils.getInstance().getModelFromSongJson(songJson);
        return this.showCapsulesForSong(songModel);
    }else{
        window.alertErrorMessage(window.getI18n(ti18n,"UNABLE_TO_RETRIEVE_CAPSULES"));
    }
}).bind(this)).catch((function(error){
    $.mobile.loading("hide");
    throw error;
}).bind(this));
},

showCapsulesForSong : function(songModel, context, songRefSummary){
var params = {};
params.model = songModel;
params.context = context;
params.songRefSummary = songRefSummary;
$.mobile.loading("show");
return this.require([ "controllers/capsule/CapsuleListOptionControllerNew"]).then((function(CapsuleListOptionControllerNew){
    $.mobile.loading("hide");
    var capsuleListOptionControllerNew = new CapsuleListOptionControllerNew(params);
    return this.renderPopupAndDisplayPage (capsuleListOptionControllerNew, "Capsule for Song Popup");
}).bind(this)).catch((function(error){
    $.mobile.loading("hide");
    throw error;
}).bind(this));


},


showCommentsFromAudioObjJson : function(audioObjJson, disableReply, context, triggerHashChange){
if(Utils.getInstance().isCapsuleUri(audioObjJson.uri)){
    return this.showCommentsForCapsuleWithId(Utils.getInstance().getCapsuleReferenceFromAudioObj(audioObjJson), disableReply,  context, triggerHashChange);
}
/*if(Utils.getInstance().isSongUri(audioObjJson.uri)){
    return this.showCommentsForSongWithId(Utils.getInstance().getSongReferenceFromAudioObj(audioObjJson), disableReply);
}*/
return RSVP.Promise.resolve();
},

showInfoPopupFromAudioObjJson : function(audioObjJson, context){
if(Utils.getInstance().isCapsuleUri(audioObjJson.uri)){
    return this.showCapsulePoupForCapsuleId(Utils.getInstance().getCapsuleReferenceFromAudioObj(audioObjJson), context, false, true, false);
}
return RSVP.Promise.resolve();
},


showInfoFromAudioObjJson : function(audioObjJson, disableReply){
if(Utils.getInstance().isCapsuleUri(audioObjJson.uri)){
    return this.showCommentsForCapsuleWithId(Utils.getInstance().getCapsuleReferenceFromAudioObj(audioObjJson), disableReply);
}
if(Utils.getInstance().isSongUri(audioObjJson.uri)){
    return this.showCommentsForSongWithId(Utils.getInstance().getSongReferenceFromAudioObj(audioObjJson), disableReply);
}
return RSVP.Promise.reject(window.getI18n(ti18n,"UNABLE_TO_RETRIEVE_COMMENTS"));
},

showCommentsForSongWithId : function(songId, disableReply){
$.mobile.loading("show");
return TrebbleClientAPIHelper.getInstance().getSongInfoBySongId(songId).then((function(songJson){
    $.mobile.loading("hide");
    if(songJson){
        var songModel = Utils.getInstance().getModelFromSongJson(songJson);
        return this.showPageCommentsForSong(songModel, disableReply);
    }else{
        window.alertErrorMessage(window.getI18n(ti18n,"UNABLE_TO_RETRIEVE_COMMENTS"));
    }
}).bind(this)).catch((function(error){
    $.mobile.loading("hide");
    throw error;
}).bind(this));
},

showCommentsForCapsuleWithId : function(capsuleId, disableReply, context, triggerHashChange){
$.mobile.loading("show");
return TrebbleClientAPIHelper.getInstance().getCapsuleInfoByCapsuleId(capsuleId).then((function(capsuleJson){
    $.mobile.loading("hide");
    if(capsuleJson){
        var capsuleModel = Utils.getInstance().getModelFromCapsuleJson(capsuleJson);
        return this.showPageCommentsForCapsule(capsuleModel, disableReply,null, context, triggerHashChange);
    }else{
        window.alertErrorMessage(window.getI18n(ti18n,"UNABLE_TO_RETRIEVE_COMMENTS"));
    }
}).bind(this)).catch((function(error){
    $.mobile.loading("hide");
    throw error;
}).bind(this));
},

showCommentsForSong : function(songModel,disableReply,idOfCommentToFocusOn, context, songRefSummary){
var params = {};
params.model = songModel;
params.context = context;
params.disableReply = disableReply;
params.idOfCommentToFocusOn = idOfCommentToFocusOn;
params.songRefSummary = songRefSummary;
return this.require([ "controllers/comment/CommentListOptionController"]).then((function(CommentListOptionController){
    var commentListOptionController = new CommentListOptionController(params);
    return this.renderPopupAndDisplayPage (commentListOptionController, "Comments for Song Popup");
}).bind(this));



},

showPageCommentsForSongBySongUri  : function(songId,triggerHashChange ){

triggerHashChange = triggerHashChange || false;
var pageId = encodeURIComponent("songComments/")+songId;
var pageName = "Comment On Song Page";
this.navigateToPageWithId( pageId,pageName  , false,triggerHashChange);
},


showPageCommentsForSong : function(songModel,disableReply,idOfCommentToFocusOn, context, triggerHashChange){

triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageId = encodeURIComponent("songComments/"+songModel.get("id"));
controllerParams.pageId  = pageId;
controllerParams.model = songModel;
controllerParams.disableReply = disableReply;
controllerParams.idOfCommentToFocusOn = idOfCommentToFocusOn;
controllerParams.context = context;
return this.require(["controllers/comment/CommentListPageController"]).then((function(CommentListPageController){
    var commentListPageController  = new CommentListPageController(controllerParams);
    var pageName = "Comment On Song Page";
    return this.renderAndDisplayPage(commentListPageController,pageId,pageName, false,triggerHashChange);
}).bind(this));


},






showCommentsForCapsule : function(capsuleModel,disableReply,idOfCommentToFocusOn, context, songRefSummary){
var params = {};
params.model = capsuleModel;
params.context = context;
params.disableReply = disableReply;
params.idOfCommentToFocusOn = idOfCommentToFocusOn;
params.songRefSummary = songRefSummary;
return this.require([ "controllers/comment/CommentListOptionController"]).then((function(CommentListOptionController){
    var commentListOptionController = new CommentListOptionController(params);
    return this.renderPopupAndDisplayPage (commentListOptionController, "Comments for Capsule Popup");
}).bind(this));



},

showPageCommentsForCapsuleByCapsuleId  : function(capsuleId,triggerHashChange ){

triggerHashChange = triggerHashChange || false;
var pageId = encodeURIComponent("pageCommentForCapsule_")+capsuleId;
var pageName = "Comment On Capsule Page";
return this._navigateIfPageExistOrRetrieveCapsule(pageId, pageName,triggerHashChange, capsuleId, (function(capsuleModel, pageId, pageName,triggerHashChange){
    return this.showPageCommentsForCapsule(capsuleModel,false,null, null, triggerHashChange);
}).bind(this));
},


showPageCommentsForCapsule : function(capsuleModel,disableReply,idOfCommentToFocusOn, context, triggerHashChange){

triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageId = encodeURIComponent("pageCommentForCapsule_"+capsuleModel.getCapsuleId());
controllerParams.pageId  = pageId;
controllerParams.model = capsuleModel;
controllerParams.disableReply = disableReply;
controllerParams.idOfCommentToFocusOn = idOfCommentToFocusOn;
controllerParams.context = context;
return this.require(["controllers/comment/CommentListPageController"]).then((function(CommentListPageController){
    var commentListPageController  = new CommentListPageController(controllerParams);
    var pageName = "Comment On Capsule Page";
    return this.renderAndDisplayPage(commentListPageController,pageId,pageName, false,triggerHashChange);
}).bind(this));


},


showTrebbleStatisticsPageByTrebbleId  : function(trebbleId,triggerHashChange ){
triggerHashChange = triggerHashChange || false;
var pageId = encodeURIComponent("trebble_statistic_page_")+trebbleId;
var pageName = "Trebble Statistic Page";
this._navigateIfPageExistOrRetrieveTrebble(pageId, pageName,triggerHashChange, trebbleId, (function(playSummaryModel, pageId, pageName,triggerHashChange){
    return this.showTrebbleStatisticsPage(playSummaryModel, triggerHashChange);
}).bind(this));

},

showTrebbleStatsPageByTrebbleId : function(trebbleId,triggerHashChange ){
triggerHashChange = triggerHashChange || false;
var pageId = encodeURIComponent("trebble_stats_page_")+trebbleId;
var pageName = "Trebble Statistic Page";
this._navigateIfPageExistOrRetrieveTrebble(pageId, pageName,triggerHashChange, trebbleId, (function(playSummaryModel, pageId, pageName,triggerHashChange){
    return this.showTrebbleStatisticsPage(playSummaryModel, triggerHashChange);
}).bind(this));
}, 

showCapsuleStatsPageByCapsuleId : function(capsuleId,triggerHashChange ){
triggerHashChange = triggerHashChange || false;
var pageId = encodeURIComponent("capsule_stats_page_/")+capsuleId;
var pageName = "Capsule Statistic Page";
this._navigateIfPageExistOrRetrieveCapsule(pageId, pageName,triggerHashChange, capsuleId, (function(capsuleModel, pageId, pageName,triggerHashChange){
    return this.showCapsuleStatisticsPage(capsuleModel, triggerHashChange);
}).bind(this));
}, 


showSongStatisticsPageBySongId  : function(songTrebbleId,triggerHashChange ){
triggerHashChange = triggerHashChange || false;
var pageId = encodeURIComponent("songStatisticPage/")+songTrebbleId;
var pageName = "Song Statistic Page";
this.navigateToPageWithId( pageId,pageName  , false,triggerHashChange);
},




showTrebbleStatisticsPageOld : function(trebblePlaylistSummary, triggerHashChange){
triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageId = encodeURIComponent("trebble_statistic_page_"+trebblePlaylistSummary.getLoaderKey());
controllerParams.pageId  = pageId;
controllerParams.model = trebblePlaylistSummary;
return this.require(["controllers/trebbleStatistics/TrebbleStatisticsPageController"]).then((function(TrebbleStatisticsPageController){
    var trebbleStatisticsPageController  = new TrebbleStatisticsPageController(controllerParams);
    var pageName = "Trebble Statistic Page";
    return this.renderAndDisplayPage(trebbleStatisticsPageController,pageId,pageName, false,triggerHashChange);
}).bind(this));

},

showTrebbleStatisticsPage : function(trebblePlaylistSummary, triggerHashChange){
triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageId = encodeURIComponent("trebble_stats_page_"+trebblePlaylistSummary.getLoaderKey());
controllerParams.pageId  = pageId;
controllerParams.model = trebblePlaylistSummary;
return this.require(["controllers/trebbleStatistics/TrebbleStatsPageController"]).then((function(TrebbleStatsPageController){
    var trebbleStatsPageController  = new TrebbleStatsPageController(controllerParams);
    var pageName = "Trebble Statistic Page";
    return this.renderAndDisplayPage(trebbleStatsPageController,pageId,pageName, false,triggerHashChange);
}).bind(this));

},

showJourneyStatsPageByRadioId : function(journeyRadioId, triggerHashChange){
triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageId = encodeURIComponent("journey_stats_page_"+journeyRadioId);
var pageName = "Journey Statistic Page";
controllerParams.pageId  = pageId;
controllerParams.radioId = journeyRadioId;
if(this.isJQMPageWithIdExist(pageId)){
    return this.navigateToPageWithId( pageId,pageName  , false,triggerHashChange);
}else{
    return this.require(["controllers/journey/JourneyStatsPageController"]).then((function(JourneyStatsPageController){
        var journeyStatsPageController  = new JourneyStatsPageController(controllerParams);
        return this.renderAndDisplayPage(journeyStatsPageController,pageId,pageName, false,triggerHashChange);
    }).bind(this));
}

},

showWriteReviewOnJourneyPageByRadioId : function(journeyRadioId, triggerHashChange){
triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageId = encodeURIComponent("write_review_"+journeyRadioId);
var pageName = "Write A Review For Journey Page";
controllerParams.pageId  = pageId;
controllerParams.radioId = journeyRadioId;
if(this.isJQMPageWithIdExist(pageId)){
    return this.navigateToPageWithId( pageId,pageName  , false,triggerHashChange);
}else{
    return this.require(["controllers/journey/WriteAReviewForJourneyPageController"]).then((function(WriteAReviewForJourneyPageController){
        var writeAReviewForJourneyPageController  = new WriteAReviewForJourneyPageController(controllerParams);
        return this.renderAndDisplayPage(writeAReviewForJourneyPageController,pageId,pageName, false,triggerHashChange);
    }).bind(this));
}

},


showSongStatisticsPageOld : function(songModel, triggerHashChange){
triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageId = encodeURIComponent("songStatisticPage/"+songModel.getTrebbleSongId());
controllerParams.pageId  = pageId;
controllerParams.model = songModel;
return this.require(["controllers/songStatistics/SongStatisticPageController"]).then((function(SongStatisticsPageController){
    var songStatisticsPageController  = new SongStatisticsPageController(controllerParams);
    var pageName = "Song Statistic Page";
    return this.renderAndDisplayPage(songStatisticsPageController,pageId,pageName, false,triggerHashChange);
}).bind(this));

},

showSetupVoiceEnabledDevicesPageController : function(triggerHashChange){
triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageId = encodeURIComponent("setupVoiceEnabledDevicesPage");
controllerParams.pageId  = pageId;
return this.require(["controllers/SetupVoiceEnabledDevices/SetupVoiceEnabledDevicesPageController"]).then((function(SetupVoiceEnabledDevicesPageController){
    var setupVoiceEnabledDevicesPageController  = new SetupVoiceEnabledDevicesPageController(controllerParams);
    var pageName = "Setup Voice Enabled Devices Page";
    return this.renderAndDisplayPage(setupVoiceEnabledDevicesPageController,pageId,pageName, false,triggerHashChange);
}).bind(this));

},

showSongStatisticsPage : function(songModel, triggerHashChange){
triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageId = encodeURIComponent("songStatisticPage/"+songModel.getTrebbleSongId());
controllerParams.pageId  = pageId;
controllerParams.model = songModel;
return this.require(["controllers/songStatistics/SongStatsPageController"]).then((function(SongStatsPageController){
    var songStatsPageController  = new SongStatsPageController(controllerParams);
    var pageName = "Song Statistic Page";
    return this.renderAndDisplayPage(songStatsPageController,pageId,pageName, false,triggerHashChange);
}).bind(this));

},

showCapsuleStatisticsPage : function(capsuleModel, triggerHashChange){
triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageId = encodeURIComponent("capsule_stats_page_/"+capsuleModel.getCapsuleId());
controllerParams.pageId  = pageId;
controllerParams.model = capsuleModel;
return this.require(["controllers/capsuleStatistics/CapsuleStatsPageController"]).then((function(CapsuleStatsPageController){
    var capsuleStatsPageController  = new CapsuleStatsPageController(controllerParams);
    var pageName = "Capsule Statistic Page";
    return this.renderAndDisplayPage(capsuleStatsPageController,pageId,pageName, false,triggerHashChange);
}).bind(this));

},

showPageCommentReplyByCommentId  : function(commentId,triggerHashChange ){

triggerHashChange = triggerHashChange || false;
var pageId = encodeURIComponent("pageCommentReplies_")+commentId;
var pageName = "Comment Replies Page";

return this._navigateIfPageExistOrRetrieveComment(pageId, pageName,triggerHashChange, commentId, (function(commentModel, pageId, pageName,triggerHashChange){
    this.showPageCommentReplies(commentModel,false, null, null, triggerHashChange);
}).bind(this));

},

showPageCommentReplies : function(commentModel,disableReply, idOfCommentToFocusOn, context, triggerHashChange){

triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageId = encodeURIComponent("pageCommentReplies_"+commentModel.get("id"));
controllerParams.pageId  = pageId;
controllerParams.model = commentModel;
controllerParams.disableReply = disableReply;
controllerParams.idOfCommentToFocusOn = idOfCommentToFocusOn;
controllerParams.context = context;
return this.require(["controllers/comment/CommentListPageController"]).then((function(CommentListPageController){
    var commentListPageController  = new CommentListPageController(controllerParams);
    var pageName = "Comment Replies Page";
    return this.renderAndDisplayPage(commentListPageController,pageId,pageName, false,triggerHashChange);
}).bind(this));

},




showCapsuleForCapsuleId : function(capsuleId, context, triggerHashChange){
var pageId = encodeURIComponent("capsule_page_"+capsuleId);
var params = {};
params.pageTitle =  "Capsule";
params.pageId = pageId;
params.capsulesListLoader = function(){
    return TrebbleClientAPIHelper.getInstance().getCapsuleInfoByCapsuleId(capsuleId).then((function(capsuleJson){
        if(capsuleJson){
            var capsuleModel = Utils.getInstance().getModelFromCapsuleJson(capsuleJson);
            return [capsuleModel];
        }else{
            return null;
        }
    }).bind(this));
};
return this.require(["controllers/capsulesPage/CapsuleListPageController"]).then((function(CapsuleListPageController){
    var capsuleListPageController = new CapsuleListPageController(params);
    var pageName = "Capsule Page";
    return this.renderAndDisplayPage(capsuleListPageController,pageId,pageName, false,triggerHashChange);
}).bind(this));
},

showCapsulePoupForCapsuleId : function(capsuleId, context, playCapsule, doNotAllowToBeAddedToTrebble, doNotAllowToBePlayed){
return TrebbleClientAPIHelper.getInstance().getCapsuleInfoByCapsuleId(capsuleId).then((function(capsuleJson){
    if(capsuleJson){
        var capsuleModel = Utils.getInstance().getModelFromCapsuleJson(capsuleJson);
        return this.showCapsuleInPopup(capsuleModel, context, playCapsule, doNotAllowToBeAddedToTrebble, doNotAllowToBePlayed)
    }else{
        return null;
    }
}).bind(this));

},

showActiveCapsuleForRadioId : function(radioId, context, triggerHashChange){
var pageId = encodeURIComponent("active_capsules_for_radio_"+radioId);
var params = {};
params.pageTitle =  "Capsules";
params.pageId = pageId;
params.capsulesListLoader = function(){
    return TrebbleClientAPIHelper.getInstance().getOnAirCapsulesForRadioId(radioId).then((function(capsuleJsonArray){
        if(capsuleJsonArray){
            var modelArray = [];
            for(let i =0; i <capsuleJsonArray.length; i++){
                modelArray.push(Utils.getInstance().getModelFromCapsuleJson(capsuleJsonArray[i]));
            }
            return modelArray;
        }else{
            return null;
        }
    }).bind(this));
};
return this.require(["controllers/capsulesPage/CapsuleListPageController"]).then((function(CapsuleListPageController){
    var capsuleListPageController = new CapsuleListPageController(params);
    var pageName = "Active Capsule List For Radio Id Page";
    return this.renderAndDisplayPage(capsuleListPageController,pageId,pageName, false,triggerHashChange);
}).bind(this));
},

showCapsulesMatchingSearchFromRecentlyListenOnSmartSpeakers : function(audioPlayerToken, searchCriteria, triggerHashChange){
var pageId = encodeURIComponent("ss_capsule_search_"+audioPlayerToken+"_"+searchCriteria);
searchCriteria = searchCriteria?decodeURIComponent(searchCriteria): null;
var params = {};
params.pageTitle =  "Capsules";
params.pageId = pageId;
params.capsulesListLoader = function(){
    return TrebbleClientAPIHelper.getInstance().getCapsulesMatchingSearchFromRecentlyListenOnSmartSpeakers(audioPlayerToken, searchCriteria).then((function(capsuleJsonArray){
        if(capsuleJsonArray){
            var modelArray = [];
            for(let i =0; i <capsuleJsonArray.length; i++){
                modelArray.push(Utils.getInstance().getModelFromCapsuleJson(capsuleJsonArray[i]));
            }
            return modelArray;
        }else{
            return null;
        }
    }).bind(this));
};
return this.require(["controllers/capsulesPage/CapsuleListPageController"]).then((function(CapsuleListPageController){
    var capsuleListPageController = new CapsuleListPageController(params);
    var pageName = "Capsule Search On Recently Listened On Smart Speakers";
    return this.renderAndDisplayPage(capsuleListPageController,pageId,pageName, false,triggerHashChange);
}).bind(this));
},


showCapsuleInPopup  : function(capsuleModel, context, playCapsule, doNotAllowToBeAddedToTrebble, doNotAllowToBePlayed){
var params = {};
params.model = capsuleModel;
params.context = context;
params.doNotAllowToBeAddedToTrebble = doNotAllowToBeAddedToTrebble;
params.doNotAllowToBePlayed = doNotAllowToBePlayed;
return this.require(["controllers/capsule/CapsuleDetailsPopupController"]).then((function(CapsuleDetailsPopupController){
    var capsuleDetailsPopupController = new CapsuleDetailsPopupController(params);
    return this.renderPopupAndDisplayPage (capsuleDetailsPopupController, "Capsule Details Popup");
}).bind(this)).then((function(controller){
    if(playCapsule){
        controller.playCapsule();
    }
    return controller;
}).bind(this));

},



showNotificationInPopup  : function(customNotificationPayloadModel, customTemplate, showActionButton,customViewEventHandler, actionButtonLabel, actionButtonClickHandler, context){
var params = {};
params.model = customNotificationPayloadModel;
params.context = context;
params.showActionButton = showActionButton;
params.customTemplate = customTemplate;
params.actionButtonClickHandler = actionButtonClickHandler;
params.actionButtonLabel = actionButtonLabel;
params.customViewEventHandler =  customViewEventHandler;
return this.require(["controllers/common/NotificationPopupController"]).then((function(NotificationPopupController){
    var notificationPopupController = new NotificationPopupController(params);
    return this.renderPopupAndDisplayPage (notificationPopupController, "Notification Details Popup");
}).bind(this));


},

showMyPersonalRadioMenuOption : function(trebbleModel, context, onActionExecutedOnTrebbleHandler,  elementSource$el, prefetchedPersonalTrebbleOptionController){
var params = {};
params.trebbleModel = trebbleModel;
params.elementSource$el = elementSource$el;
params.context = context;
params.onActionExecutedOnTrebbleHandler = onActionExecutedOnTrebbleHandler;
return (prefetchedPersonalTrebbleOptionController? RSVP.Promise.resolve(prefetchedPersonalTrebbleOptionController): this.require(["controllers/playlistPodcast/PersonalTrebbleOptionController"])).then((function(PersonalTrebbleOptionController){
    var personalTrebbleOptionController = new PersonalTrebbleOptionController(params);
    return this.renderPopupAndDisplayPage (personalTrebbleOptionController, "My Personal Trebble Options Popup",  elementSource$el? true: false)
}).bind(this));
},

showJourneyMenuOption : function(journeySummaryModel, context, onActionExecutedOnTrebbleHandler, elementSource$el, prefetchedJourneyOptionController){
var params = {};
params.journeySummaryModel = journeySummaryModel;
params.context = context;
params.elementSource$el = elementSource$el;
params.onActionExecutedOnTrebbleHandler = onActionExecutedOnTrebbleHandler;
return (prefetchedJourneyOptionController? RSVP.Promise.resolve(prefetchedJourneyOptionController): this.require(["controllers/journey/JourneyOptionController"])).then((function(JourneyOptionController){
    var journeyOptionController = new JourneyOptionController(params);
    return this.renderPopupAndDisplayPage (journeyOptionController, "Journey Options Popup",  elementSource$el? true: false)
}).bind(this));
},


showOutlineItemOption : function(outlineItemModel,functionToRetrieveDefaultImageUrl, elementSource$el, context, prefetchedOutlineItemOptionController){
var params = {};
params.outlineItemModel = outlineItemModel;
params.elementSource$el = elementSource$el;
params.functionToRetrieveDefaultImageUrl = functionToRetrieveDefaultImageUrl;
params.context = context;
return (prefetchedOutlineItemOptionController ? RSVP.Promise.resolve(prefetchedOutlineItemOptionController): this.require(["controllers/journey/OutlineItemOptionController"])).then((function(OutlineItemOptionController){
    var outlineItemOptionController = new OutlineItemOptionController(params);
    return this.renderPopupAndDisplayPage(outlineItemOptionController, "Outline Item Options Popup", elementSource$el? true: false)
}).bind(this));
},

showUserMenuOption : function(userModel, context,elementSource$el, onActionExecutedOnUserHandler, prefetchedUserOptionController){
var params = {};
params.userModel = userModel;
params.context = context;
params.elementSource$el = elementSource$el;
params.onActionExecutedOnUserHandler = onActionExecutedOnUserHandler;
return (prefetchedUserOptionController? RSVP.Promise.resolve(prefetchedUserOptionController): this.require(["controllers/user/UserOptionController"])).then((function(UserOptionController){
    var userOptionController = new UserOptionController(params);
    return this.renderPopupAndDisplayPage (userOptionController, "My User Account Options Popup", elementSource$el? true: false);
}).bind(this));

},

showCapsuleMenuOption : function(capsuleModel, context, elementSource$el, onActionExecutedOnCapsuleHandler, functionToRetrieveDefaultImageUrl, prefetchedCapsuleOptionController){

var params = {};
params.capsuleModel = capsuleModel;
params.context = context;
params.elementSource$el = elementSource$el;
params.onActionExecutedOnCapsuleHandler = onActionExecutedOnCapsuleHandler;
params.functionToRetrieveDefaultImageUrl = functionToRetrieveDefaultImageUrl;
return (prefetchedCapsuleOptionController ? RSVP.Promise.resolve(prefetchedCapsuleOptionController): this.require(["controllers/capsule/CapsuleOptionController"])).then((function(CapsuleOptionController){
    var capsuleOptionController = new CapsuleOptionController(params);
    return this.renderPopupAndDisplayPage (capsuleOptionController, "Capsule Menu Options Popup", elementSource$el? true: false);
}).bind(this));
},


showSequencerNodeMoreOption : function(sequencerController, moreButtonButton$el, onMenuClose, onMenuItemClicked, popupPosition, prefetchSequencerNodeMoreOptionController){

var params = {};
params.sequencerController = sequencerController;
params.elementSource$el = moreButtonButton$el;
params.onMenuClose = onMenuClose;
params.popupPosition =  popupPosition;
params.onMenuItemClicked = onMenuItemClicked;

return (prefetchSequencerNodeMoreOptionController? RSVP.Promise.resolve(prefetchSequencerNodeMoreOptionController): this.require(["controllers/audioEditor/SequencerNodeMoreOptionController"])).then((function(SequencerNodeMoreOptionController){
    var sequencerNodeMoreOptionController = new SequencerNodeMoreOptionController(params);
    return this.renderPopupAndDisplayPage (sequencerNodeMoreOptionController, "Sequencer Menu More Options Popup", moreButtonButton$el? true: false);
}).bind(this));
},




showSequencerNodeBasicContextMenuOption : function(sequencerController, sequencerNode$el, onMenuClose){

var params = {};
params.sequencerController = sequencerController;
params.elementSource$el = sequencerNode$el;
params.onMenuClose = onMenuClose;

return this.require(["controllers/audioEditor/SequencerNodeBasicContextOptionController"],sequencerNode$el? true: false).then((function(SequencerNodeBasicContextOptionController){
    var sequencerNodeBasicContextOptionController = new SequencerNodeBasicContextOptionController(params);
    return this.renderPopupAndDisplayPage (sequencerNodeBasicContextOptionController, "Sequencer node basic options context menu Popup", sequencerNode$el? true: false);
}).bind(this));
},


_navigateIfPageExistOrRetrieveTrebble : function(pageId, pageName,triggerHashChange, trebbleId, handlerIfPageDoesntExists){
if(this.isJQMPageWithIdExist(pageId)){
    this.navigateToPageWithId( pageId,pageName  , false,triggerHashChange);
}else{
    $.mobile.loading("show");
    return TrebbleClientAPIHelper.getInstance().getMyTrebbleInfoByTrebbleId(trebbleId).then((function(trebbleInfoJson){
        $.mobile.loading("hide");
        //var loadingDonePromise = RSVP.Promise.resolve();
        if(trebbleInfoJson){
            var playSummaryModel = Utils.getInstance().buildPlaylistSummaryFromJson(trebbleInfoJson);
            return handlerIfPageDoesntExists(playSummaryModel, pageId, pageName,triggerHashChange);
        }else{
            //window.alertErrorMessage(window.getI18n(ti18n,"OOPS_WE_WERE_NOT_ABLE_TO_FIND_THE_TREBBLE"));
            console.error(window.getI18n(ti18n,"OOPS_WE_WERE_NOT_ABLE_TO_FIND_THE_TREBBLE"));
            this.showWebAppHomePage();
        }
        
    }).bind(this)).catch((function(error){
        $.mobile.loading("hide");
        console.error(error);
        window.alertErrorMessage(window.getI18n(ti18n,"OOPS_SOMETHING_WENT_WRONG"));
    }).bind(this))
}

},

_navigateIfPageExistOrRetrieveCapsule : function(pageId, pageName,triggerHashChange, capsuleId, handlerIfPageDoesntExists){
if(this.isJQMPageWithIdExist(pageId)){
    this.navigateToPageWithId( pageId,pageName  , false,triggerHashChange);
}else{
    $.mobile.loading("show");
    return TrebbleClientAPIHelper.getInstance().getCapsuleInfoByCapsuleId(capsuleId).then((function(capsuleJson){
        $.mobile.loading("hide");
        //var loadingDonePromise = RSVP.Promise.resolve();
        if(capsuleJson){
            var capsuleModel = Utils.getInstance().getModelFromCapsuleJson(capsuleJson);
            return handlerIfPageDoesntExists(capsuleModel, pageId, pageName,triggerHashChange);
        }else{
            //window.alertErrorMessage(window.getI18n(ti18n,"OOPS_WE_WERE_NOT_ABLE_TO_FIND_THE_TREBBLE"));
            console.error(window.getI18n(ti18n,"OOPS_WE_WERE_NOT_ABLE_TO_FIND_THE_CAPSULE"));
            this.showWebAppHomePage();
        }
        
    }).bind(this)).catch((function(error){
        $.mobile.loading("hide");
        console.error(error);
        window.alertErrorMessage(window.getI18n(ti18n,"OOPS_SOMETHING_WENT_WRONG"));
    }).bind(this))
}

},


_navigateIfPageExistOrRetrieveComment : function(pageId, pageName,triggerHashChange, commentId, handlerIfPageDoesntExists){
if(this.isJQMPageWithIdExist(pageId)){
    this.navigateToPageWithId( pageId,pageName  , false,triggerHashChange);
}else{
    $.mobile.loading("show");
    return TrebbleClientAPIHelper.getInstance().getCommentInfoByCommentId(commentId).then((function(commentJson){
        $.mobile.loading("hide");
        //var loadingDonePromise = RSVP.Promise.resolve();
        if(commentJson){
            var commentModel  = Utils.getInstance().getModelFromCommentJson(commentJson);
            return handlerIfPageDoesntExists(commentModel, pageId, pageName,triggerHashChange);
        }else{
            //window.alertErrorMessage(window.getI18n(ti18n,"OOPS_WE_WERE_NOT_ABLE_TO_FIND_THE_TREBBLE"));
            console.error(window.getI18n(ti18n,"OOPS_WE_WERE_NOT_ABLE_TO_FIND_THE_COMMENT"));
            this.showWebAppHomePage();
        }
        
    }).bind(this)).catch((function(error){
        $.mobile.loading("hide");
        console.error(error);
        window.alertErrorMessage(window.getI18n(ti18n,"OOPS_SOMETHING_WENT_WRONG"));
    }).bind(this))
}

},

showTrebbleInfoEditPageByTrebbleId  : function(trebbleId,triggerHashChange ){

triggerHashChange = triggerHashChange || false;
//var controllerParams = {};
var pageId = encodeURIComponent("trebbleInfoEditPage_")+trebbleId;
var pageName = "Trebble Edit Info Page";
this._navigateIfPageExistOrRetrieveTrebble(pageId, pageName,triggerHashChange, trebbleId, (function(playSummaryModel, pageId, pageName,triggerHashChange){
    return this.showTrebbleInfoEditPage(playSummaryModel,triggerHashChange ,null );
}).bind(this));


},

showCapsuleInfoEditPageByCapsuleId  : function(capsuleId,triggerHashChange ){

triggerHashChange = triggerHashChange || false;
//var controllerParams = {};
var pageId = encodeURIComponent("capsuleInfoEditPage_")+capsuleId;
var pageName = "Capsule Edit Info Page";
return this._navigateIfPageExistOrRetrieveCapsule(pageId, pageName,triggerHashChange, capsuleId, (function(capsuleModel, pageId, pageName,triggerHashChange){
    return this.showCapsuleByInfoEditPage(capsuleModel,capsuleId,true, triggerHashChange ,null);
}).bind(this));
},





showTrebbleInfoEditPage :  function(trebbleModel,triggerHashChange ,handlers ){

triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageId = encodeURIComponent("trebbleInfoEditPage_")+trebbleModel.getLoaderKey();
controllerParams.pageId  = pageId;
controllerParams.trebbleModel  = trebbleModel;
if(handlers){
    controllerParams.onCoverPictureChangeTrigger = handlers.onCoverPictureChangeTrigger;
    controllerParams.onSweeperChangeTrigger = handlers.onSweeperChangeTrigger;
    controllerParams.onSweeperChangeTriggerConfirm = handlers.onSweeperChangeTriggerConfirm;
    controllerParams.onDeleteSweeperTrigger = handlers.onDeleteSweeperTrigger;

    controllerParams.onGreaterChangeTrigger = handlers.onGreaterChangeTrigger;
    controllerParams.onIntroChangeTrigger = handlers.onIntroChangeTrigger;
    controllerParams.onOutroChangeTrigger = handlers.onOutroChangeTrigger;
    controllerParams.onGreaterChangeTriggerConfirm = handlers.onGreaterChangeTriggerConfirm;
    controllerParams.onIntroChangeTriggerConfirm = handlers.onIntroChangeTriggerConfirm;
    controllerParams.onOutroChangeTriggerConfirm = handlers.onOutroChangeTriggerConfirm;
    controllerParams.onDeleteGreaterTrigger = handlers.onDeleteGreaterTrigger;
    controllerParams.onDeleteIntroTrigger = handlers.onDeleteIntroTrigger;
    controllerParams.onDeleteOutroTrigger = handlers.onDeleteOutroTrigger;

    controllerParams.onActionExecutedOnTrebbleHandler = handlers.onActionExecutedOnTrebbleHandler;
}
return this.require(["controllers/playlistPodcast/TrebbleEditDetailsController"]).then((function(TrebbleEditDetailsController){
    var trebbleEditDetailsController  = new TrebbleEditDetailsController(controllerParams);
    var pageName =   "Trebble Edit Info Page";
    return this.renderAndDisplayPage(trebbleEditDetailsController,pageId,pageName, false,triggerHashChange);
}).bind(this)).then((function(controller){
    this.trigger("editPageOpened",controller);
    if(controller.getView() && controller.getView().$el){
        controller.getView().$el.on("remove", (function () {
            this.trigger("editPageClosed",controller);
        }).bind(this))
    }
    return controller;
}).bind(this));


},



showJourneyInfoEditPage :  function(journeyModel,triggerHashChange ,handlers ){

triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageId = encodeURIComponent("journeyInfoEditPage_")+journeyModel.getLoaderKey();
controllerParams.pageId  = pageId;
controllerParams.trebbleModel  = journeyModel;
if(handlers){
    controllerParams.onCoverPictureChangeTrigger = handlers.onCoverPictureChangeTrigger;
    controllerParams.onSweeperChangeTrigger = handlers.onSweeperChangeTrigger;
    controllerParams.onSweeperChangeTriggerConfirm = handlers.onSweeperChangeTriggerConfirm;
    controllerParams.onDeleteSweeperTrigger = handlers.onDeleteSweeperTrigger;

    controllerParams.onGreaterChangeTrigger = handlers.onGreaterChangeTrigger;
    controllerParams.onIntroChangeTrigger = handlers.onIntroChangeTrigger;
    controllerParams.onOutroChangeTrigger = handlers.onOutroChangeTrigger;
    controllerParams.onGreaterChangeTriggerConfirm = handlers.onGreaterChangeTriggerConfirm;
    controllerParams.onIntroChangeTriggerConfirm = handlers.onIntroChangeTriggerConfirm;
    controllerParams.onOutroChangeTriggerConfirm = handlers.onOutroChangeTriggerConfirm;
    controllerParams.onDeleteGreaterTrigger = handlers.onDeleteGreaterTrigger;
    controllerParams.onDeleteIntroTrigger = handlers.onDeleteIntroTrigger;
    controllerParams.onDeleteOutroTrigger = handlers.onDeleteOutroTrigger;

    controllerParams.onActionExecutedOnTrebbleHandler = handlers.onActionExecutedOnTrebbleHandler;
}
return this.require(["controllers/journey/JourneyEditDetailsController"]).then((function(JourneyEditDetailsController){
    var journeyEditDetailsController  = new JourneyEditDetailsController(controllerParams);
    var pageName =   "Journey Edit Info Page";
    return this.renderAndDisplayPage(journeyEditDetailsController,pageId,pageName, false,triggerHashChange);
}).bind(this)).then((function(controller){
    this.trigger("editPageOpened",controller);
    if(controller.getView() && controller.getView().$el){
        controller.getView().$el.on("remove", (function () {
            this.trigger("editPageClosed",controller);
        }).bind(this))
    }
    return controller;
}).bind(this));


},

showCapsuleInfoEditPage : function(shortcastId, onShortcastInfoChanged,  triggerHashChange ){
triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageId = encodeURIComponent("shortcastInfoEditPage_")+shortcastId;
controllerParams.pageId  = pageId;
controllerParams.shortcastId  = shortcastId;
controllerParams.onShortcastInfoChanged  = onShortcastInfoChanged;
return this.require(["controllers/capsule/EditShortcastInfoPageController"]).then((function(EditShortcastInfoPageController){
    var editShortcastInfoPageController  = new EditShortcastInfoPageController(controllerParams);
    var pageName =   "Shortcast Edit Info Page";
    return this.renderAndDisplayPage(editShortcastInfoPageController,pageId,pageName, false,triggerHashChange);
}).bind(this)).then((function(controller){
    this.trigger("editPageOpened",controller);
    if(controller.getView() && controller.getView().$el){
        controller.getView().$el.on("remove", (function () {
            this.trigger("editPageClosed",controller);
        }).bind(this))
    }
    return controller;
}).bind(this));
},


showUserChangePasswordPage :  function(triggerHashChange ){

triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageId = encodeURIComponent("userChangePassword_")+(new Date()).getTime();
controllerParams.pageId  = pageId;

return this.require(["controllers/user/UserChangePasswordPageController"]).then((function(UserChangePasswordPageController){
    var userChangePasswordPageController  = new UserChangePasswordPageController(controllerParams);
    var pageName =   "Change Password Page";
    return this.renderAndDisplayPage(userChangePasswordPageController,pageId,pageName, true,triggerHashChange);
}).bind(this));


},

showRedeemCodePage : function(redeemCode, accessCode,  triggerHashChange){
triggerHashChange = triggerHashChange || false;
var controllerParams = {"redeemCode": redeemCode};
var pageId = "redeem";
controllerParams.pageId  = pageId;
if(accessCode){
    window.trebble.presetAccessCode = accessCode;
}

return this.require(["controllers/user/RedeemCodePageController"]).then((function(RedeemCodePageController){
    var redeemCodePageController  = new RedeemCodePageController(controllerParams);
    var pageName =   "Redeem Code Page";
    return this.renderAndDisplayPage(redeemCodePageController,pageId,pageName, true,triggerHashChange);
}).bind(this));
},

showSendPasswordResetLinkPage :  function(triggerHashChange ){

triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageId = encodeURIComponent("sendPasswordResetLink_")+(new Date()).getTime();
controllerParams.pageId  = pageId;

return this.require(["controllers/user/SendRecoveryPasswordEmailController"]).then((function(SendRecoveryPasswordEmailController){
    var sendRecoveryPasswordEmailController  = new SendRecoveryPasswordEmailController(controllerParams);
    var pageName =   "Reset Password";
    return this.renderAndDisplayPage(sendRecoveryPasswordEmailController,pageId,pageName, true,triggerHashChange);
}).bind(this));


},

showUserEditProfileInfo : function(triggerHashChange, handlers){

triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageId = encodeURIComponent("editMyUserProfile_")+(new Date()).getTime();
controllerParams.pageId  = pageId;
controllerParams.onUserPorfileChanged = handlers? handlers.onUserPorfileChanged : null;
return this.require(["controllers/user/EditUserInfoPageController"]).then((function(EditUserInfoPageController){
    var editUserInfoPageController  = new EditUserInfoPageController(controllerParams);
    var pageName =   "Edit My User Profile";
    return this.renderAndDisplayPage(editUserInfoPageController,pageId,pageName, true,triggerHashChange);
}).bind(this));

},

showSettingsPage : function(triggerHashChange){
triggerHashChange = triggerHashChange || false;
var pageId = encodeURIComponent("settings");
var pageName = "Settings Page";
if(this.isJQMPageWithIdExist(pageId)){
    this.navigateToPageWithId( pageId,pageName  , true,triggerHashChange, null, null, false);
    return RSVP.Promise.resolve();
}else{
    var controllerParams = {};
    controllerParams.pageId  = pageId;
    return this.require(["controllers/settingsPage/SettingsPageController"]).then((function(SettingsPageController){
        var settingsPageController  = new SettingsPageController(controllerParams);

        return this.renderAndDisplayPage(settingsPageController,pageId,pageName, true,triggerHashChange, false, false);
    }).bind(this));
}
},





showCapsuleByInfoEditPage :  function(capsuleModel,capsuleId,editMode, triggerHashChange ,onActionExecutedOnUserHandler){
triggerHashChange = triggerHashChange || false;
var controllerParams = {};
capsuleId = capsuleId? capsuleId: capsuleModel.getCapsuleId();
var pageId = encodeURIComponent("capsuleInfoEditPage_"+capsuleId);
editMode = capsuleModel? capsuleModel.getCanBeEdited(): editMode;
controllerParams.pageId  = pageId;
controllerParams.model  = capsuleModel;
controllerParams.capsuleId  = capsuleId;
controllerParams.editMode = editMode;
controllerParams.onActionExecutedOnUserHandler = onActionExecutedOnUserHandler;
return this.require(["controllers/capsule/CapsuleDetailsController"]).then((function(CapsuleDetailsController){
    var capsuleDetailsController  = new CapsuleDetailsController(controllerParams);
    var pageName =   "Capsule Edit Info Page";
    return this.renderAndDisplayPage(capsuleDetailsController,pageId,pageName, false,triggerHashChange);
}).bind(this));


},

showExitingUserProfilePageByUsername  : function(username,triggerHashChange ){

triggerHashChange = triggerHashChange || false;
//var controllerParams = {};
var pageId = "u_"+ username;
var pageName = "A User Profile Page";
if(this.isJQMPageWithIdExist(pageId)){
    this.navigateToPageWithId( pageId,pageName  , true,triggerHashChange, null, null, true);
    return RSVP.Promise.resolve();
}else{
    return this.showUserProfilePageByUsername(decodeURIComponent(username), null, null, true);

}


},

showExitingUserProfilePageByUserUID  : function(userUID,triggerHashChange ){

triggerHashChange = triggerHashChange || false;
//var controllerParams = {};
var pageId =  "user_"+ userUID;
var pageName = "A User Profile Page";

if(this.isJQMPageWithIdExist(pageId)){
    this.navigateToPageWithId( pageId,pageName  , true,triggerHashChange, null, null, true);
    return RSVP.Promise.resolve();
}else{
    return this.showUserProfilePageByUsername(null, decodeURIComponent(userUID), null, true);

}

},


showUserProfilePageByUsername :  function(username,userUID, userModel, triggerHashChange ){
triggerHashChange = triggerHashChange || false;
var controllerParams = {};
let pageId;
if(username){
     pageId = "u_"+ encodeURIComponent(username);
}else{
     pageId = "user_"+encodeURIComponent(userUID);
}
controllerParams.pageId  = pageId;
controllerParams.userModel  = userModel;
controllerParams.userUsername  = username;
controllerParams.userUniqueId = userUID;
return this.require(["controllers/user/UserProfileDetailsPageController"]).then((function(UserProfileDetailsPageController){
    var userProfileDetailsPageController  = new UserProfileDetailsPageController(controllerParams);
    var pageName = "A User Profile Page";
    return this.renderAndDisplayPage(userProfileDetailsPageController,pageId,pageName, true,triggerHashChange, null, true);
}).bind(this));

},





showSongComment : function(songModel, context){
var params = {};
params.songModel = songModel;
params.context = context;
return this.require(["controllers/comment/CommentController"]).then((function(CommentController){
    var commentController = new CommentController(params);
    return this.renderPopupAndDisplayPage (commentController, "Comment For Song Popup");
}).bind(this));


},

showSendCapsuleToUserByUserIdIntroPage : function(userUID,referrer,customTrackingKey,triggerHashChange , showInANewBrowserWindowPopup){
triggerHashChange = triggerHashChange || false;
var controllerParams = {};
var pageName = "Send Capsule To User Intro";
var pageId = "sendCapsuleToUserWithId"+encodeURIComponent(userUID);
if(showInANewBrowserWindowPopup){
    var urlWithoutSearchQuery =  location.origin;//location.href.replace(location.search,"");
    var newPageUrl = urlWithoutSearchQuery + "/" + pageId;//urlWithoutSearchQuery.replace(location.hash, "") + "/" + pageId;
    //window.open(newPageUrl, "_blank", "toolbar=no, scrollbars=yes, resizable=yes, width=500, height=740");
    var openedWindow = window.open(newPageUrl, "_blank");
    if((!openedWindow || openedWindow.closed || typeof openedWindow.closed=='undefined') && !window.waitForCordovaToLoad){
        window.alertErrorMessage(window.getI18n(ti18n,"PLEASE_ALLOW_POPUPS"));
    }else{
        if(openedWindow.focus){
            openedWindow.focus();
        }
    }
}else{
    if(this.isJQMPageWithIdExist(pageId)){
        this.navigateToPageWithId( pageId,pageName  , true,triggerHashChange, null, null, true);
        return RSVP.Promise.resolve();
    }else{
        if(!window.trebble.presetAccessCode){
            window.trebble.presetAccessCode = TMA_CAPSULE_ACCESS_CODE;
        }
        if(referrer){
            window.trebble.referrer =referrer;
        }
        if(customTrackingKey){
            window.trebble.customTrackingKey = customTrackingKey;
        }
        controllerParams.pageId  = pageId;
        controllerParams.userUniqueId = userUID;
        controllerParams.alwaysShowBackButton = triggerHashChange;
        return this.require(["controllers/audioHighlight/SendCapsuleToUserIntroPageController"]).then((function(SendCapsuleToUserIntroPageController){
            var sendCapsuleToUserIntroPageController  = new SendCapsuleToUserIntroPageController(controllerParams);

            return this.renderAndDisplayPage(sendCapsuleToUserIntroPageController,pageId,pageName, true,triggerHashChange, null, true);
        }).bind(this));
    }
}


},


showSendCapsuleToUserByUsernameIntroPage : function(username,referrer,customTrackingKey,triggerHashChange ){
triggerHashChange = triggerHashChange || false;
var controllerParams = {};

var pageId = "sendCapsuleToUserWithName"+ encodeURIComponent(username);
var pageName = "Send Capsule To User Intro";

if(this.isJQMPageWithIdExist(pageId)){
    this.navigateToPageWithId( pageId,pageName  , true,triggerHashChange, null, null, true);
    return RSVP.Promise.resolve();
}else{
    if(!window.trebble.presetAccessCode){
        window.trebble.presetAccessCode = TMA_CAPSULE_ACCESS_CODE;
    }
    if(referrer){
        window.trebble.referrer =referrer;
    }
    if(customTrackingKey){
        window.trebble.customTrackingKey = customTrackingKey;
    }
    controllerParams.pageId  = pageId;
    controllerParams.userUsername  = username;
    controllerParams.alwaysShowBackButton = triggerHashChange;
    return this.require(["controllers/audioHighlight/SendCapsuleToUserIntroPageController"]).then((function(SendCapsuleToUserIntroPageController){
        var sendCapsuleToUserIntroPageController  = new SendCapsuleToUserIntroPageController(controllerParams);
        
        return this.renderAndDisplayPage(sendCapsuleToUserIntroPageController,pageId,pageName, true,triggerHashChange, null, true);
    }).bind(this));
}


},


showCreateACapsulePageWithoutContext  : function(receiverUserId, onCapsuleSuccessfullyCreated, onCapsuleCreationPageClosed, saveAsDraft, createAShortcast, customPageTitle, doNotReturnToDefaultPage, startCreationInFileUploadMode, canSaveOnlyAfterTranscriptionAndEditing,doNotTriggerHashChange){
saveAsDraft = true;
this.require(["services/PersistentModels"]).then((function(PersistentModels){
   // this.require(["models/helper/Utils"]).then((function(Utils){
        this.require(["models/helper/AudioRecorderHelper"]).then((function(AudioRecorderHelper){

            if(window.device && window.device.platform =="browser"){
                AudioRecorderHelper.getInstance().initUserMedia().then((function(allowed){
                    if(allowed){
                        return PersistentModels.getInstance().getRouter().showAddAudioHightlightPageNew(null,null,  null , onCapsuleSuccessfullyCreated,true, !doNotTriggerHashChange, receiverUserId, null, null, !doNotReturnToDefaultPage, onCapsuleCreationPageClosed, saveAsDraft, createAShortcast, customPageTitle, startCreationInFileUploadMode, canSaveOnlyAfterTranscriptionAndEditing);
                    }else{
                        return PersistentModels.getInstance().getRouter().showAddAudioHightlightPageNew(null,null, null, onCapsuleSuccessfullyCreated,false, !doNotTriggerHashChange, receiverUserId, null, null, !doNotReturnToDefaultPage, onCapsuleCreationPageClosed, saveAsDraft,createAShortcast, customPageTitle, startCreationInFileUploadMode, canSaveOnlyAfterTranscriptionAndEditing);
                    }
                }).bind(this)).catch((function(e){
                    window.navigator.trebbleNotification.alert(e, null, null, "warning");
                    return PersistentModels.getInstance().getRouter().showAddAudioHightlightPageNew(null,null,  null, onCapsuleSuccessfullyCreated,false, !doNotTriggerHashChange, receiverUserId, null, null, !doNotReturnToDefaultPage, onCapsuleCreationPageClosed, saveAsDraft, createAShortcast, customPageTitle, startCreationInFileUploadMode, canSaveOnlyAfterTranscriptionAndEditing);
                }).bind(this));

            }else{
                return PersistentModels.getInstance().getRouter().showAddAudioHightlightPageNew(null,null, null, onCapsuleSuccessfullyCreated,true, !doNotTriggerHashChange, receiverUserId, null, null, !doNotReturnToDefaultPage, onCapsuleCreationPageClosed, saveAsDraft, createAShortcast, customPageTitle, startCreationInFileUploadMode, canSaveOnlyAfterTranscriptionAndEditing);
            }

        }).bind(this));
   // }).bind(this));
}).bind(this));

},

showCreateAShortcastPageToPublishToChannel  : function(receiverUserId, onCapsuleSuccessfullyCreated, onCapsuleCreationPageClosed, saveAsDraft, createAShortcast, customPageTitle, doNotReturnToDefaultPage){
this.require(["services/PersistentModels"]).then((function(PersistentModels){
    //this.require(["models/helper/Utils"]).then((function(Utils){
        this.require(["models/helper/AudioRecorderHelper"]).then((function(AudioRecorderHelper){

            if(window.device && window.device.platform =="browser"){
                AudioRecorderHelper.getInstance().initUserMedia().then((function(allowed){
                    if(allowed){
                        return PersistentModels.getInstance().getRouter().showAddAudioHightlightPageNew(null,null,  null , onCapsuleSuccessfullyCreated,true, true, receiverUserId, null, null, !doNotReturnToDefaultPage, onCapsuleCreationPageClosed, saveAsDraft, createAShortcast, customPageTitle);
                    }else{
                        return PersistentModels.getInstance().getRouter().showAddAudioHightlightPageNew(null,null, null, onCapsuleSuccessfullyCreated,false, true, receiverUserId, null, null, !doNotReturnToDefaultPage, onCapsuleCreationPageClosed, saveAsDraft,createAShortcast, customPageTitle);
                    }
                }).bind(this)).catch((function(e){
                    window.navigator.trebbleNotification.alert(e, null, null, "warning");
                    return PersistentModels.getInstance().getRouter().showAddAudioHightlightPageNew(null,null,  null, onCapsuleSuccessfullyCreated,false, true, receiverUserId, null, null, !doNotReturnToDefaultPage, onCapsuleCreationPageClosed, saveAsDraft, createAShortcast, customPageTitle);
                }).bind(this));

            }else{
                return PersistentModels.getInstance().getRouter().showAddAudioHightlightPageNew(null,null, null, onCapsuleSuccessfullyCreated,true, true, receiverUserId, null, null, !doNotReturnToDefaultPage, onCapsuleCreationPageClosed, saveAsDraft, createAShortcast, customPageTitle);
            }

        }).bind(this));
   // }).bind(this));
}).bind(this));

},


showUploadAudioFileAsCapsule : function(addBackgroundMusicToUploadedAudio, musicUri){
return this.showAddAudioHightlightPageNew(null,null,  null , null,false, true, null, addBackgroundMusicToUploadedAudio === "yes", decodeURIComponent(musicUri));
},

createShortcastByUploadingFile : function(onCapsuleSuccessfullyCreated, onCapsuleCreationPageClosed){
return this.showCreateACapsulePageWithoutContext(null, onCapsuleSuccessfullyCreated, onCapsuleCreationPageClosed, true, false, window.getI18n(ti18n,"EDIT_AUDIO"), null, true, true, true);
},

recordAudioAndEdit : function(onCapsuleSuccessfullyCreated, onCapsuleCreationPageClosed){
return this.showCreateACapsulePageWithoutContext(null, onCapsuleSuccessfullyCreated, onCapsuleCreationPageClosed, true, false, null, null, false, true, true);
},


showAddAudioHightlightPageNew: function(songModel,capsuleId,  context , onCapsuleSuccessfullyCreated,audioRecordingSupported, triggerHashChange, receiverUserId, addBackgroundMusicToUploadedAudio, backgroundMusicUri, returnToDefaultAfterCreation, onCapsuleCreationPageClosed, saveAsDraft, createAShortcast, customPageTitle, startCreationInFileUploadMode, canSaveOnlyAfterTranscriptionAndEditing){

var pageId = encodeURIComponent("createAShortcastPage_"+(new Date().getTime()));
var params = {};
params.songModel = songModel;
params.capsuleId = capsuleId;
params.context = context;
params.audioRecordingSupported = audioRecordingSupported;
params.pageId  = pageId;
params.receiverUserId = receiverUserId;
params.onCapsuleSuccessfullyCreated = onCapsuleSuccessfullyCreated;
params.addBackgroundMusicToUploadedAudio = addBackgroundMusicToUploadedAudio;
params.backgroundMusicUri =  backgroundMusicUri;
params.returnToDefaultAfterCreation = returnToDefaultAfterCreation;
params.onCapsuleCreationPageClosed =  onCapsuleCreationPageClosed;
params.saveAsDraft = saveAsDraft;
params.createAShortcast =  createAShortcast;
params.customPageTitle =  customPageTitle;
params.startCreationInFileUploadMode = startCreationInFileUploadMode;
params.canSaveOnlyAfterTranscriptionAndEditing =  canSaveOnlyAfterTranscriptionAndEditing;
return this.require(["controllers/audioHighlight/RecordAudioHightlightPageNewController"]).then((function(RecordAudioHightlightController){
    var recordAudioHightlightController = new RecordAudioHightlightController(params);
    var pageName = "Create Shortcast Page";
    return this.renderAndDisplayPage (recordAudioHightlightController,pageId,pageName, false, triggerHashChange, null, true, null, true);
}).bind(this));
},

showAudioRecorderPage: function( onAllAudioRecorded, onPageClosed,  customPageTitle, triggerHashChange){

    var pageId = encodeURIComponent("recordAudioPage_"+(new Date().getTime()));
    var params = {};
    params.audioRecordingSupported = true;
    params.pageId  = pageId;
    params.onAllAudioRecorded = onAllAudioRecorded;
    params.returnToDefaultAfterCreation = false;
    params.onCapsuleCreationPageClosed =  onPageClosed;
    params.saveAsDraft = true;
    params.customPageTitle =  customPageTitle;
    params.recordOnly =  true;
    return this.require(["controllers/audioHighlight/RecordAudioHightlightPageNewController"]).then((function(RecordAudioHightlightController){
        var recordAudioHightlightController = new RecordAudioHightlightController(params);
        var pageName = "Create Audio Recording page";
        return this.renderAndDisplayPage (recordAudioHightlightController,pageId,pageName, false, triggerHashChange, null, true, null, true);
    }).bind(this));
    },
    

showCreateShortcastPageByTimestamp : function(timestamp,triggerHashChange){
triggerHashChange = triggerHashChange || false;
//var controllerParams = {};
var pageId = encodeURIComponent("createAShortcastPage_")+timestamp;
var pageName = "Create Capsule For Song Page";
if(this.isJQMPageWithIdExist(pageId)){
    this.navigateToPageWithId( pageId,pageName  , true,triggerHashChange,null, null, true);
    return RSVP.Promise.resolve();
}else{
    return this.showAddAudioHightlightPageNew(null,null, null,null,true, triggerHashChange, null,null,null,true,null,true);
}
},

showCreateShortcastPageFromTranscription : function(transcriptionId, enhancedString, triggerHashChange, onCapsuleSuccessfullyCreated, addBackgroundMusicToUploadedAudio, backgroundMusicUri, returnToDefaultAfterCreation, onCapsuleCreationPageClosed, customPageTitle){
triggerHashChange = triggerHashChange || false;
const enhanced = enhancedString === "true";
//var controllerParams = {};
var pageId = encodeURIComponent("createShortcastFromTranscription_")+transcriptionId;
var pageName = "Create Shortcast from transcription";
if(this.isJQMPageWithIdExist(pageId)){
    this.navigateToPageWithId( pageId,pageName  , true,triggerHashChange,null, null, true);
    return RSVP.Promise.resolve();
}else{
    var params = {};
    params.transcriptionId = transcriptionId;
    params.audioRecordingSupported = true;
    params.pageId  = pageId;
    params.onCapsuleSuccessfullyCreated = onCapsuleSuccessfullyCreated;
    params.addBackgroundMusicToUploadedAudio = addBackgroundMusicToUploadedAudio;
    params.backgroundMusicUri =  backgroundMusicUri;
    params.returnToDefaultAfterCreation = !(returnToDefaultAfterCreation && this.isFirstPageDisplayed()) ? true: returnToDefaultAfterCreation;
    if(returnToDefaultAfterCreation === false){
        params.returnToDefaultAfterCreation = false;
    }
    params.onCapsuleCreationPageClosed =  onCapsuleCreationPageClosed;
    params.saveAsDraft = true;
    params.createAShortcast =  true;
    params.customPageTitle =  customPageTitle;
    let recordAudioHightlightController;
    return this.require(["controllers/audioHighlight/RecordAudioHightlightPageNewController"]).then((function(RecordAudioHightlightController){
        recordAudioHightlightController = new RecordAudioHightlightController(params);
        var pageName = "Create Shortcast Page";
        return this.renderAndDisplayPage (recordAudioHightlightController,pageId,pageName, false, triggerHashChange, null, true);
    }).bind(this)).then((function(){
        if(enhanced){
            recordAudioHightlightController.setMagicSoundEnhancer(true, false);
        }
    }).bind(this));
}
},

showCreateShortcastPageFromEditingExistingCapsuleWithProjectId : function(capsuleId, projectId, triggerHashChange, onCapsuleSuccessfullyUpdated, returnToDefaultAfterCreation, onCapsuleCreationPageClosed, customPageTitle){
    triggerHashChange = triggerHashChange || false;
    //var controllerParams = {};
    var pageId = encodeURIComponent("createShortcastFromCapsuleId/"+capsuleId+"/projectid/"+projectId);
    var pageName = "Edit Shortcast From Project";
    if(!customPageTitle){
        customPageTitle = window.getI18n(ti18n, "UPDATE_SHORTCAST");
    }
    if(this.isJQMPageWithIdExist(pageId)){
        this.navigateToPageWithId( pageId,pageName  , true,triggerHashChange,null, null, true);
        return RSVP.Promise.resolve();
    }else{
        var params = {};
        params.projectIdToEdit = projectId;
        params.capsuleIdToEdit = capsuleId;
        params.audioRecordingSupported = true;
        params.pageId  = pageId;
        params.onCapsuleSuccessfullyUpdated = onCapsuleSuccessfullyUpdated;
        params.returnToDefaultAfterCreation = !(returnToDefaultAfterCreation && this.isFirstPageDisplayed()) ? true: returnToDefaultAfterCreation;
        if(returnToDefaultAfterCreation === false){
            params.returnToDefaultAfterCreation = false;
        }
        params.onCapsuleCreationPageClosed =  onCapsuleCreationPageClosed;
        params.saveAsDraft = true;
        params.createAShortcast =  true;
        params.customPageTitle =  customPageTitle;
        let recordAudioHightlightController;
        return this.require(["controllers/audioHighlight/RecordAudioHightlightPageNewController"]).then((function(RecordAudioHightlightController){
            recordAudioHightlightController = new RecordAudioHightlightController(params);
            var pageName = "Edit Shortcast Page";
            return this.renderAndDisplayPage (recordAudioHightlightController,pageId,pageName, false, triggerHashChange, null, true);
        }).bind(this));
    }
    },

showCreateShortcastPageFromProject : function(projectId,triggerHashChange, onCapsuleSuccessfullyCreated, addBackgroundMusicToUploadedAudio, backgroundMusicUri, returnToDefaultAfterCreation, onCapsuleCreationPageClosed, customPageTitle){
triggerHashChange = triggerHashChange || false;
//var controllerParams = {};
var pageId = encodeURIComponent("createShortcastFromProject_")+projectId;
var pageName = "Create Shortcast from Project";
if(this.isJQMPageWithIdExist(pageId)){
    this.navigateToPageWithId( pageId,pageName  , true,triggerHashChange,null, null, true);
    return RSVP.Promise.resolve();
}else{
    var params = {};
    params.projectIdToDuplicate = projectId;
    params.audioRecordingSupported = true;
    params.pageId  = pageId;
    params.onCapsuleSuccessfullyCreated = onCapsuleSuccessfullyCreated;
    params.addBackgroundMusicToUploadedAudio = addBackgroundMusicToUploadedAudio;
    params.backgroundMusicUri =  backgroundMusicUri;
    params.returnToDefaultAfterCreation = !(returnToDefaultAfterCreation && this.isFirstPageDisplayed()) ? true: returnToDefaultAfterCreation;
    if(returnToDefaultAfterCreation === false){
        params.returnToDefaultAfterCreation = false;
    }
    params.onCapsuleCreationPageClosed =  onCapsuleCreationPageClosed;
    params.saveAsDraft = true;
    params.createAShortcast =  true;
    params.customPageTitle =  customPageTitle;
    return this.require(["controllers/audioHighlight/RecordAudioHightlightPageNewController"]).then((function(RecordAudioHightlightController){
        var recordAudioHightlightController = new RecordAudioHightlightController(params);
        var pageName = "Create Shortcast Page";
        return this.renderAndDisplayPage (recordAudioHightlightController,pageId,pageName, false, triggerHashChange, null, true);
    }).bind(this));
}
},


showTrebbleSharePopup : function(trebblePlaylistSummary, context, isTrebblePlayableFunction){
var isPlayable = (isTrebblePlayableFunction && typeof isTrebblePlayableFunction === "function")? isTrebblePlayableFunction(trebblePlaylistSummary): PlayerService.getInstance().isTrebblePlayable(trebblePlaylistSummary);
if(true || isPlayable){
    //Always allow users to share a trebble even if it has no content
    var params = {};
    params.trebblePlaylistSummary = trebblePlaylistSummary;
    params.context = context;
    return this.require(["controllers/shareTrebble/ShareTrebblePopupController"]).then((function(ShareTrebblePopupController){
        var shareTrebblePopupController = new ShareTrebblePopupController(params);
        return this.renderPopupAndDisplayPage (shareTrebblePopupController, "Share Trebble Popup");
    }).bind(this));
}else{
    if(trebblePlaylistSummary.canBeEdited()){
        //var buttonLabels = ["Play Demo Trebble","Add Songs Now"];
        //var buttonLabels = ["Add Music", "Create Capsule"];
        var buttonLabels = [window.getI18n(ti18n,"OK"), window.getI18n(ti18n,"CREATE_CAPSULE")];
        var confirmCallback = (function(buttonIndex){
            if(buttonIndex ==  2){
                setTimeout((function(){
                    PersistentModels.getInstance().getRouter().showCreateACapsulePageWithoutContext(null);
                }).bind(this), 100);

            }else{
                /*setTimeout((function(){
                    PlayerService.getInstance().playDemoTrebble();
                }).bind(this), 100);*/
        /*setTimeout((function(){
            PersistentModels.getInstance().getRouter().showGlobalSearchPage(null, true);
        }).bind(this), 100);*/
    }
}).bind(this);
       // var minimumOfSongsNeeded  =  Utils.getInstance().getDefaultNumberOfSongsRequiredToPlayATrebble();
        //var message = "A Trebble needs to contain 1 capsule or "+ minimumOfSongsNeeded +" songs minimum to be played."
        var message = window.getI18n(ti18n,"YOUR_TREBBLE_CURRENTLY_HAS_NO_AUDIO_CONTENT");
        var subMessage = window.getI18n(ti18n,"YOU_CAN_ADD_CONTENT_BY_CREATING_A_CAPSULE");//"Go the search page or use our chrome extension to add songs.";
        navigator.trebbleNotification.confirm(message , confirmCallback, window.getI18n(ti18n,"NEED_TO_HAVE_A_CERTAIN_MINIMUM_OF_SONGS_TO_BE_SHARED"), buttonLabels, null, null, subMessage);
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("onboarding", "Discover Mininum Song Number For Trebble", "Discover Mininum Song Number For Trebble");
        } 
    }else{
        navigator.trebbleNotification.alert(window.getI18n(ti18n,"THIS_TREBBLE_DOES_NOT_HAVE_ACTIVE_CAPSULE_TO_PLAY"), null, false);
    }
}
},

showChromeExtensionInstallHelperPopup : function(context){
var params = {};
params.context = context;

return this.require(["controllers/installChromeExtensionHelper/InstallChromeExtensionHelperPopupController"]).then((function(InstallChromeExtensionHelperController){
    var installChromeExtensionHelperController = new InstallChromeExtensionHelperController(params);
    return this.renderPopupAndDisplayPage (installChromeExtensionHelperController, "Install Chrome Extension Helper Popup");
}).bind(this));
},

showSimpleSetCapsuleSharePopup : function(capsuleObj, context){
var params = {};
params.capsule = capsuleObj;
params.context = context;
params.capsuleDurationInSeconds =  null;
$.mobile.loading("show");

var buildAndDisplayPopup = (function(){
    return this.router.require(["controllers/shareCapsule/ShareSimpleCapsuleSetPopupController"]).then((function(ShareSimpleCapsuleSetPopupController){
        var shareSimpleCapsuleSetPopupController = new ShareSimpleCapsuleSetPopupController(this.params);
        return this.router.renderPopupAndDisplayPage(shareSimpleCapsuleSetPopupController, "Share Capsule Popup");
    }).bind(this));
}).bind({"router": this, "params": params})

return TrebbleClientAPIHelper.getInstance().getCapsuleCoverArtImages(capsuleObj.getCapsuleId()).then((function(coverArtImagesInfo){
    params.coverArtUrl = coverArtImagesInfo  && coverArtImagesInfo.regularImageUrl ? coverArtImagesInfo.regularImageUrl: null;
    params.coverArtBlurUrl = coverArtImagesInfo  && coverArtImagesInfo.blurImageUrl ? coverArtImagesInfo.blurImageUrl: null;
    var capsuleAudioUrl =  capsuleObj.getAudioUrl();
    if(capsuleAudioUrl){
        return Utils.getInstance().getAudioDuration(capsuleAudioUrl).then(function(duration){
            params.capsuleDurationInSeconds = duration;
            $.mobile.loading("hide");
            //Show embed code with duration
            return buildAndDisplayPopup();
        });
    }else{
        $.mobile.loading("hide");
        return buildAndDisplayPopup();
    }
    
}).bind(this)).catch(function(error){
    console.error(error);
    $.mobile.loading("hide");
    return buildAndDisplayPopup();
});

},

showSimpleSetSongSharePopup : function(songObj, context){
var params = {};
params.song = songObj;
params.context = context;
return this.require(["controllers/shareSong/ShareSimpleSongSetPopupController"]).then((function(ShareSimpleSongSetPopupController){
    var shareSimpleSongSetPopupController = new ShareSimpleSongSetPopupController(params);
    return this.renderPopupAndDisplayPage(shareSimpleSongSetPopupController, "Share Song Popup");
}).bind(this));
},

showShareJourneyPopup : function(journeyRadioId, context){

var params = {};
params.journeyRadioId = journeyRadioId;
params.context = context;
return this.require(["controllers/journey/ShareJourneyPopupController"]).then((function(ShareJourneyPopupController){
    var shareJourneyPopupController = new ShareJourneyPopupController(params);
    return this.renderPopupAndDisplayPage(shareJourneyPopupController, "Share Journey Popup");
}).bind(this));

},

showShareCMailUrlPopup : function(userId, username){

var params = {};
params.username = username;
params.userId = userId;
return this.require(["controllers/shareCmailUrl/ShareCMailUrlPopupController"]).then((function(ShareCMailUrlPopupController){
    var shareCMailUrlPopupController = new ShareCMailUrlPopupController(params);
    return this.renderPopupAndDisplayPage(shareCMailUrlPopupController, "Share C-Mail Popup");
}).bind(this));

},

showSongTagManagerPopup : function(songModel, context, onSongTagManagerPopupClose){
var params = {};
params.songModel = songModel;
params.context = context;
params.onPopupClose = onSongTagManagerPopupClose;
return this.require(["controllers/common/SongTabManagerPopupController"]).then((function(SongTabManagerPopupController){
    var songTabManagerPopupController = new SongTabManagerPopupController(params);
    return this.renderPopupAndDisplayPage (songTabManagerPopupController, "Song Grading Popup");
}).bind(this));

},

showQueueTuningPopup : function(queue){
return this.require(["controllers/queue/QueueTuningPopupController"]).then((function(QueueTuningPopupController){
    var queueTuningPopupController = new QueueTuningPopupController(queue);
    return this.renderPopupAndDisplayPage (queueTuningPopupController, "Player Queue Tuning Popup");
}).bind(this));

},

showAlbumOptionPopup : function(albumModel){
return this.require(["controllers/albumOptionMenu/AlbumOptionMenuController"]).then((function(AlbumOptionMenuController){
    var albumOptionMenuController = new AlbumOptionMenuController(albumModel);
    return this.renderPopupAndDisplayPage (albumOptionMenuController, "Album Menu Option Popup");
}).bind(this));

},

showArtistOptionPopup : function(artistModel){
return this.require(["controllers/artistOptionMenu/ArtistOptionMenuController"]).then((function(ArtistOptionMenuController){
    var artistOptionMenuController = new ArtistOptionMenuController(artistModel);
    return this.renderPopupAndDisplayPage (artistOptionMenuController, "Artist Menu Option Popup");
}).bind(this));

},

showQueueOptionPopup : function(queueModel){
return this.require(["controllers/queueOptionMenu/QueueOptionMenuController"]).then((function(QueueOptionMenuController){
    var queueOptionMenuController = new QueueOptionMenuController(queueModel);
    return this.renderPopupAndDisplayPage (queueOptionMenuController, "Player Queue Menu Option Popup");
}).bind(this));


},

isUpgradeToPaidSubscriptionPopupOpen: function(){
return Utils.getInstance().isJQMPopupWithIdOpen("paidAccountSubsPopup");
},

showUpgradeToPaidSubscriptionPopup : function(queueModel){
return this.require(["controllers/paidAccountSubscriptionPopup/PaidAccountSubscriptionPopupController"]).then((function(PaidAccountSubscriptionPopupController){
    var paidAccountSubscriptionPopupController = new PaidAccountSubscriptionPopupController(queueModel);
    return this.renderPopupAndDisplayPage (paidAccountSubscriptionPopupController, "Updagre To Paid Susbcription Popup");
}).bind(this));


},


playTrebbleAndShowPlayer : function( trebbleId, referrer, customTrackingKey){
if(referrer){
    window.trebble.referrer =referrer;
}
if(customTrackingKey){
    window.trebble.customTrackingKey = customTrackingKey;
}
return this.loadTrebbleAndShowPlayer("play", trebbleId);
},


preloadTrebbleAndShowPlayer : function( trebbleId, referrer, customTrackingKey){
if(referrer){
    window.trebble.referrer =referrer;
}
if(customTrackingKey){
    window.trebble.customTrackingKey = customTrackingKey;
}
return this.loadTrebbleAndShowPlayer("load", trebbleId);
},



preloadTrebbleViaCustomUrl : function(trebbleCustomUrl){
return TrebbleClientAPIHelper.getInstance().getMyTrebbleInfoByTrebbleCustomURL(trebbleCustomUrl).then((function(trebbleInfoJson){
    if(trebbleInfoJson){
        return this.loadTrebbleAndShowPlayer("play", trebbleInfoJson.id, null, null,null, true);
    }else{
        //window.alertErrorMessage(window.getI18n(ti18n,"OOPS_WE_WERE_NOT_ABLE_TO_FIND_THE_TREBBLE"));
        console.error(window.getI18n(ti18n,"OOPS_WE_WERE_NOT_ABLE_TO_FIND_THE_TREBBLE"));
        this.showWebAppHomePage();
    }

}).bind(this)).catch((function(error){
    console.error(error);
    window.alertErrorMessage(window.getI18n(ti18n,"OOPS_SOMETHING_WENT_WRONG_WHEN_RETRIEVING_THE_TREBBLE"));
}).bind(this))
},

loadTrebbleAndShowPlayer : function(actionType, trebbleId, isVisitor, contextId,contextType, useTrebbleCoverAsDefaultAlbumArt){
if(!PersistentModels.getInstance().getPlayer().getPlaylist() || PersistentModels.getInstance().getPlayer().getPlaylist().getLoaderKey() != trebbleId){
    $.mobile.loading("show");
    return this.showPlayerPage(false, true).then((function(){
        if(trebbleId){
            return TrebbleClientAPIHelper.getInstance().getMyTrebbleInfoByTrebbleId(trebbleId);
        }
    }).bind(this)).then((function(trebbleInfoJson){
        $.mobile.loading("hide");
        var loadingDonePromise = RSVP.Promise.resolve();
        if(trebbleInfoJson){
            var loadOnly = (actionType == "load")?true: false;
            var playSummaryModel = Utils.getInstance().buildPlaylistSummaryFromJson(trebbleInfoJson);
            var context = null;
            if(contextType && contextId){
                context = {"type": contextType, "id": contextId};
            }
            loadingDonePromise = PlayerService.getInstance().playTrebble(playSummaryModel, null, loadOnly, context);
            //if(useTrebbleCoverAsDefaultAlbumArt || $("body").hasClass("embedded") || window.trebble.forceAuth){
                //THIS IS AN EMBEDDED PLAYER SO SET TREBBLE COVER ART AS DEFAULT ALBUM ART
                PersistentModels.getInstance().getPlayer().setDefaultAlbumArtUrl(playSummaryModel.getCoverArtUrl());

                //}
                if(useTrebbleCoverAsDefaultAlbumArt || $("body").hasClass("embedded") || window.trebble.forceAuth){
                    //THIS IS AN EMBEDDED PLAYER SO SET TREBBLE COVER ART AS DEFAULT ALBUM ART
                    //PersistentModels.getInstance().getPlayer().setDefaultAlbumArtUrl(playSummaryModel.getCoverArtUrl());
                    loadingDonePromise.then((function(){
                        try{
                            PersistentModels.getInstance().getPlayer().retrieveScaledCoverArts();
                        }catch(error){
                            console.error(error);
                        }
                    }).bind(this));
                }
            }
            var triggerTrebbleLoadedInPlayerEvent = (function(){
                parent.postMessage("trebbleLoadedInPlayer", "*");
            }).bind(this);
            var triggerTrebbleLoadedInPlayerFailureEvent = (function(){
                parent.postMessage("trebbleLoadingFailedInPlayer", "*");
            }).bind(this);
            loadingDonePromise.then(triggerTrebbleLoadedInPlayerEvent).catch(triggerTrebbleLoadedInPlayerFailureEvent);
        }).bind(this))
}else{
    return this.showPlayerPage(!PersistentModels.getInstance().getRouter().isBrowserHistoryGoingBack(), true);
}
},

playCapsuleFeedAndShowPlayer : function(userId, referrer, customTrackingKey, capsuleIdToStartAt){
if(capsuleIdToStartAt === "undefined"){
    capsuleIdToStartAt = null;
}
if(referrer){
    window.trebble.referrer =referrer;
}
if(customTrackingKey){
    window.trebble.customTrackingKey = customTrackingKey;
}
var playFirstInQueue = capsuleIdToStartAt?  false: true;
if(!window.waitForCordovaToLoad){
    playFirstInQueue = false;
}
$.mobile.loading("show");
return this.showPlayerPage(!PersistentModels.getInstance().getRouter().isBrowserHistoryGoingBack(), true).then((function(){
    if(userId){
        //this user id should be validated for security reason
    }
}).bind(this)).then((function(){
    var capsuleFeedId = userId? userId: LocalStorageHelper.getInstance().getUserInfo()._id;
    if(!PersistentModels.getInstance().getPlayer().getPlaylist() || PersistentModels.getInstance().getPlayer().getPlaylist().getLoaderKey() != capsuleFeedId){
        $.mobile.loading("hide");
        var loadingDonePromise = RSVP.Promise.resolve();
        if(capsuleFeedId){
            var playlist =  PlaylistHelper.getInstance().generateMyCapsuleFeedPlaylist(capsuleFeedId);
            loadingDonePromise = PersistentModels.getInstance().getPlayer().setPlaylist(playlist, playFirstInQueue);
        }
        var triggerTrebbleLoadedInPlayerEvent = (function(){
            parent.postMessage("trebbleLoadedInPlayer", "*");
        }).bind(this);
        var triggerTrebbleLoadedInPlayerFailureEvent = (function(){
            parent.postMessage("trebbleLoadingFailedInPlayer", "*");
        }).bind(this);
        return loadingDonePromise.then(triggerTrebbleLoadedInPlayerEvent).catch(triggerTrebbleLoadedInPlayerFailureEvent);
    }else{
        //Feed Is Already Loaded
    }
}).bind(this)).then((function(){
    if(capsuleIdToStartAt){
        var loadedPlaylist = PersistentModels.getInstance().getPlayer().getPlaylist();
        var audioIndex = 0;
        var isServerCapsuleObjectWithIdFunc = function(capsuleServerObj, index){
            var isSameObject =  Utils.getInstance().isServerCapsuleObjectWithId(capsuleServerObj.toJSON(), capsuleIdToStartAt);
            if(isSameObject){
                audioIndex = index;
            }
            return isSameObject;
        }
        var audioModelFound = _.find(loadedPlaylist.models,isServerCapsuleObjectWithIdFunc);
        var audioModelIndex = -1;
        if(audioModelFound){
            audioModelIndex = audioIndex;//_.indexOf(loadedPlaylist.models, audioModelFound);
        }
        if(audioModelIndex == -1){
            //If audio id couldn't be found, start at the beginning
            audioModelIndex = 0;
        }
        if(window.waitForCordovaToLoad){
            return PersistentModels.getInstance().getPlayer()._playSongAtCursonPosition(audioModelIndex);
        }else{
            return PersistentModels.getInstance().getPlayer().setCurrentPlayingIndexInPlaylist(audioModelIndex);
        }
    }

}).bind(this))

},

playJourneyTrailerAndShowPlayer : function(trebbleId, referrer, customTrackingKey){

if(referrer){
    window.trebble.referrer =referrer;
}
if(customTrackingKey){
    window.trebble.customTrackingKey = customTrackingKey;
}
if(window.trebbleAnalyticsHelper){
    window.trebbleAnalyticsHelper.trackEvent("journey", 'playJourneyTrailerInPlayer', 'Play Trailer Journey In Player',null,{"trebbleId": trebbleId});
}
$.mobile.loading("show");
return this.showPlayerPage(!PersistentModels.getInstance().getRouter().isBrowserHistoryGoingBack(), true).then((function(){
    if(trebbleId){
        return TrebbleClientAPIHelper.getInstance().getTrailerForAvailableJourney(trebbleId);
        //this user id should be validated for security reason
    }
}).bind(this)).then((function(trailerCapsuleInfo){
    $.mobile.loading("hide");
    if(trailerCapsuleInfo){
        var trailerCapsuleModel = Utils.getInstance().getModelFromCapsuleJson(trailerCapsuleInfo);
        return this.loadCapsuleSimpleSetAndShowPlayer("play", trailerCapsuleModel.getCapsuleId(), null, null, null, trebbleId);
    }
}).bind(this))

},


changeHashAndPlayTrebble : function(trebbleId, context){
var newHash = "player/action/play/trebble/"+ trebbleId ;
if(context){
    if(context.id){
        newHash  = newHash +"/context/"+context.id 
    }
    if(context.type){
        newHash  = newHash + "/contextType/"+ context.type ;
    }
    
}
window.location.hash =  newHash;
},

playCapsuleSimpleSetAndShowPlayer : function( capsuleId, referrer, customTrackingKey){
if(referrer){
    window.trebble.referrer =referrer;
}
if(customTrackingKey){
    window.trebble.customTrackingKey = customTrackingKey;
}
return this.loadCapsuleSimpleSetAndShowPlayer("play", capsuleId);
},

playSongSimpleSetAndShowPlayer : function( songId, referrer, customTrackingKey){
if(referrer){
    window.trebble.referrer =referrer;
}
if(customTrackingKey){
    window.trebble.customTrackingKey = customTrackingKey;
}
return this.loadSongSimpleSetAndShowPlayer("play", songId);
},

preloadCapsuleSimpleSetAndShowPlayer : function( capsuleId, referrer, customTrackingKey){
if(referrer){
    window.trebble.referrer =referrer;
}
if(customTrackingKey){
    window.trebble.customTrackingKey = customTrackingKey;
}
return this.loadCapsuleSimpleSetAndShowPlayer("load", capsuleId);
},

preloadSongSimpleSetAndShowPlayer : function( songId, referrer, customTrackingKey){
if(referrer){
    window.trebble.referrer =referrer;
}
if(customTrackingKey){
    window.trebble.customTrackingKey = customTrackingKey;
}
return this.loadSongSimpleSetAndShowPlayer("load", songId);
},

loadCapsuleSimpleSetAndShowPlayer : function(actionType, capsuleId, isVisitor, contextId, contextType){
if(!PersistentModels.getInstance().getPlayer().getPlaylist() || PersistentModels.getInstance().getPlayer().getPlaylist().getLoaderKey() != capsuleId){
    $.mobile.loading("show");
    var defaultTrebbleAlbumURL =  null;
    return this.showPlayerPage(false, true).then((function(){
        if(capsuleId){
            return TrebbleClientAPIHelper.getInstance().getCapsuleCoverArtUrl(capsuleId)
        }
    }).bind(this)).then((function(coverArtUrl){
        if(coverArtUrl){
            defaultTrebbleAlbumURL = coverArtUrl;
        }
        if(capsuleId){
            return TrebbleClientAPIHelper.getInstance().getCapsuleInfoByCapsuleId(capsuleId)
        }
    }).bind(this)).then((function(capsulInfoJson){
        $.mobile.loading("hide");
        var loadingDonePromise = RSVP.Promise.resolve();
        if(capsulInfoJson){
            var loadOnly = (actionType == "load")?true: false;
            var playSummaryModel = Utils.getInstance().buildPlaylistSummaryFromCapsuleInfoJson(capsulInfoJson);
            var context = null;
            if(contextType && contextId){
                context = {"type": contextType, "id": contextId};
            }
            loadingDonePromise = PlayerService.getInstance().playSimpleCapsuleSet(playSummaryModel, null, loadOnly, context);
            if(loadOnly){
                loadingDonePromise.then((function(){
                    if(PersistentModels.getInstance().getPlayer().isSongLoaded()){
                        //ONLY LOAD THE FIRST SONG IF THE PLAYLIST HAS BEEN SET IN LOAD ONLY
                        // AND THE PLAYER GOT THE PLAYLIST FULLLY SET (PROMISE RETURNED AFTER SETPLAYLIS)
                        // AND THERE ARE CURRENTLY NO SONG LOADED IN THE QUEUE
                        //KEEP IN MIND WE DO NOY LOADSONG WHEN THE ACTION TYPE IS "PLAY" AS 
                        //THAT ACTION TIME WILL LOAD A SONG IN THE REGARDLESS
                        return PersistentModels.getInstance().getPlayer().loadFirstSong();
                    }
                }).bind(this));
            }
            //if($("body").hasClass("embedded") || window.trebble.forceAuth){
                //THIS IS AN EMBEDDED PLAYER SO SET TREBBLE COVER ART AS DEFAULT ALBUM ART
                PersistentModels.getInstance().getPlayer().setDefaultAlbumArtUrl(defaultTrebbleAlbumURL? defaultTrebbleAlbumURL: playSummaryModel.getCoverArtUrl());
                //}
                if($("body").hasClass("embedded") || window.trebble.forceAuth){
                    //THIS IS AN EMBEDDED PLAYER SO SET TREBBLE COVER ART AS DEFAULT ALBUM ART
                    //PersistentModels.getInstance().getPlayer().setDefaultAlbumArtUrl(playSummaryModel.getCoverArtUrl());
                    loadingDonePromise.then((function(){
                        try{
                            PersistentModels.getInstance().getPlayer().retrieveScaledCoverArts();
                        }catch(error){
                            console.error(error);
                        }
                    }).bind(this));

                }
            }
            var triggerCapsuleLoadedInPlayerEvent = (function(){
                parent.postMessage("capsuleLoadedInPlayer", "*");
            }).bind(this);
            var triggerCapsuleLoadedInPlayerFailureEvent = (function(){
                parent.postMessage("capsuleLoadingInPlayerFailed", "*");
            }).bind(this);
            loadingDonePromise.then(triggerCapsuleLoadedInPlayerEvent).catch(triggerCapsuleLoadedInPlayerFailureEvent);
        }).bind(this))
}else{
    return this.showPlayerPage(!PersistentModels.getInstance().getRouter().isBrowserHistoryGoingBack(), true);
}
},

loadSongSimpleSetAndShowPlayer : function(actionType, songId, isVisitor, contextId, contextType){
if(!PersistentModels.getInstance().getPlayer().getPlaylist() || PersistentModels.getInstance().getPlayer().getPlaylist().getLoaderKey() != songId){
    $.mobile.loading("show");
    return this.showPlayerPage(false, true).then((function(){
        if(songId){
            return TrebbleClientAPIHelper.getInstance().getSongInfoBySongId(songId)
        }
    }).bind(this)).then((function(songInfoJson){
        $.mobile.loading("hide");
        var loadingDonePromise = RSVP.Promise.resolve();
        if(songInfoJson){
            var loadOnly = (actionType == "load")?true: false;
            var context = null;
            if(contextType && contextId){
                context = {"type": contextType, "id": contextId};
            }
            var playSummaryModel = Utils.getInstance().buildPlaylistSummaryFromSongInfoJson(songInfoJson);
            loadingDonePromise = PlayerService.getInstance().playSimpleSongSet(playSummaryModel, null, loadOnly, context);
            if(loadOnly){
                loadingDonePromise.then((function(){
                    if(PersistentModels.getInstance().getPlayer().isSongLoaded()){
                        //ONLY LOAD THE FIRST SONG IF THE PLAYLIST HAS BEEN SET IN LOAD ONLY
                        // AND THE PLAYER GOT THE PLAYLIST FULLLY SET (PROMISE RETURNED AFTER SETPLAYLIS)
                        // AND THERE ARE CURRENTLY NO SONG LOADED IN THE QUEUE
                        //KEEP IN MIND WE DO NOY LOADSONG WHEN THE ACTION TYPE IS "PLAY" AS 
                        //THAT ACTION TIME WILL LOAD A SONG IN THE REGARDLESS
                        return PersistentModels.getInstance().getPlayer().loadFirstSong();
                    }
                }).bind(this));
            }
            
            if($("body").hasClass("embedded") || window.trebble.forceAuth){
                //THIS IS AN EMBEDDED PLAYER SO SET TREBBLE COVER ART AS DEFAULT ALBUM ART
                //PersistentModels.getInstance().getPlayer().setDefaultAlbumArtUrl(playSummaryModel.getCoverArtUrl());
                //PersistentModels.getInstance().getPlayer().retrieveScaledCoverArts();
            }
        }
        var triggerSongLoadedInPlayerEvent = (function(){
            parent.postMessage("songLoadedInPlayer", "*");
        }).bind(this);
        var triggerSongLoadedInPlayerFailureEvent = (function(){
            parent.postMessage("songLoadingInPlayerFailed", "*");
        }).bind(this);
        loadingDonePromise.then(triggerSongLoadedInPlayerEvent).catch(triggerSongLoadedInPlayerFailureEvent);
    }).bind(this))
}else{
    return this.showPlayerPage(!PersistentModels.getInstance().getRouter().isBrowserHistoryGoingBack(),true);
}
},

showPlayerPage: function(triggerHashChange, showPlayerPageEventEvenIfPlayerIsNotLoaded){
triggerHashChange = triggerHashChange || false;

if(!PersistentModels.getInstance().getPlayer().getPlaylist() && !showPlayerPageEventEvenIfPlayerIsNotLoaded){
    //player has nothing loaded
    if(this.isFirstPageDisplayed()){
        this.showDefaultPage(true);
    }else{
        if(!triggerHashChange){
            this.navigateToPreviousPage();
        }else{
            //do nothing and do not change page
        }
    }
}else{
    //player has something loaded
    if(this.isPlayerControllerInitialized())
    {
        return new RSVP.Promise((function(resolve , reject){
            try{
                var pageName = "Player Page";
                var pageId = "player";
                this.navigateToPageWithId( pageId,pageName , false,triggerHashChange);
                resolve();
            }catch(error){
                reject(error);
            }
        }).bind(this));

    }else{
        return this._initializePlayerController().then((function(){
            var pageName = "Player Page";
            var pageId = "player";
            this.navigateToPageWithId( pageId,pageName , false,triggerHashChange);
        }).bind(this)).catch(function(error){
            console.error("An error occured while displaying Player Page.Error:"+ error);
            throw error;
        });
    }
}
},

showMyLibraryPage: function(searchKeyword, selectedTabId, triggerHashChange){
triggerHashChange = triggerHashChange || false;
//var hashChangeUrl = "#mylibrary";
if(searchKeyword){
    //hashChangeUrl = hashChangeUrl + "/" + searchKeyword;
}
var p = null
if(this.isMyLibraryControllerInitialized())
{
    p =  new RSVP.Promise((function(resolve , reject){
        try{
            var pageName = "My Library Page";
            var pageId = "mylibrary";
            this.navigateToPageWithId( pageId,pageName , true,triggerHashChange,null, "setMyLibraryPageAsSelectedMenu");

            if(searchKeyword){
                this.getMyLibraryController().setSearchValue(searchKeyword);
            }
            if(selectedTabId){
                this.getMyLibraryController().setSelectedTab(selectedTabId);
            }
            resolve();
        }catch(error){
            reject(error);
        }
    }).bind(this));

}else{
    p = this._initializeMyLibrary().then((function(){
        var pageName = "My Library Page";
        var pageId = "mylibrary";
        this.navigateToPageWithId( pageId,pageName , true,triggerHashChange, null,"setMyLibraryPageAsSelectedMenu");
        if(searchKeyword){
            this.getMyLibraryController().setSearchValue(searchKeyword);

        }
        if(selectedTabId){
            this.getMyLibraryController().setSelectedTab(selectedTabId);
        }

    }).bind(this)).catch(function(error){
        console.error("An error occured while displaying My library Page.Error:"+ error);
        throw error;
    });
}

return p;
},

showMySubscriptionsPage : function(triggerHashChange, playCapsuleFeed){
triggerHashChange = triggerHashChange || false;
var pageId = "mySubscriptions";
var pageName =  "My Subscrptions Page";
//var hashChangeUrl = "#"+ pageId;
if(this.isMySubscriptionsPageControllerInitialized())	
{
    if(playCapsuleFeed && this.getMySubscriptionsPageController()){
        this.getMySubscriptionsPageController().playCapsuleFeed();
    }
    return this.navigateToPageWithId( pageId,pageName, true,triggerHashChange, null, "setMySubsciptionsPageAsSelectedMenu", false);
}else{
    return this.require(["controllers/mySubscriptions/MySubscriptionsPageController"]).then((function(MySubscriptionsPageController){
        var controllerParams = {};
        var mySubscriptionsPageController  = new  MySubscriptionsPageController(controllerParams);
        return this.renderAndDisplayPage(mySubscriptionsPageController,pageId,pageName, true,triggerHashChange,"setMySubsciptionsPageAsSelectedMenu", false).then((function(){
            this.router.setMySubscriptionsPageController(this.mySubscriptionsPageController);
            this.router.setMySubscriptionsPageControllerInitialized(true);
            if(playCapsuleFeed && this.router.getMySubscriptionsPageController()){
                this.router.getMySubscriptionsPageController().playCapsuleFeed();
            }
        }).bind({"mySubscriptionsPageController" :mySubscriptionsPageController, "router": this}));
    }).bind(this));

}
},

showMyJourneysPage : function(triggerHashChange){
triggerHashChange = triggerHashChange || false;
var pageId = "myJourneys";
var pageName =  "My Journeys Page";
//var hashChangeUrl = "#"+ pageId;
if(this.isMyJourneysPageControllerInitialized())	
{
    return this.navigateToPageWithId( pageId,pageName, true,triggerHashChange, null, "setMyJourneysPageAsSelectedMenu", false);
}else{
    return this.require(["controllers/myJourneys/MyJourneysPageController"]).then((function(MyJourneysPageController){
        var controllerParams = {};
        var myJourneysPageController  = new  MyJourneysPageController(controllerParams);
        return this.renderAndDisplayPage(myJourneysPageController,pageId,pageName, true,triggerHashChange,"setMyJourneysPageAsSelectedMenu", false).then((function(){
            this.router.setMyJourneysPageController(this.myJourneysPageController);
            this.router.setMyJourneysPageControllerInitialized(true);
        }).bind({"myJourneysPageController" :myJourneysPageController, "router": this}));
    }).bind(this));

}
},


showNewHomePage : function(triggerHashChange, playCapsuleFeed){
triggerHashChange = triggerHashChange || false;
var pageId = "home";
var pageName =  "Home";
//var hashChangeUrl = "#"+ pageId;
if(this.isHomePageControllerInitialized())	
{
    if(playCapsuleFeed && this.getHomePageController()){
        this.getHomePageController().playCapsuleFeed();
    }
    return this.navigateToPageWithId( pageId,pageName, true,triggerHashChange, null, "setHomePageAsSelectedMenu", false);
}else{
    return this.require(["controllers/home/HomePageController"]).then((function(HomePageController){
        var controllerParams = {};
        var homePageController  = new HomePageController(controllerParams);
        return this.renderAndDisplayPage(homePageController,pageId,pageName, true,triggerHashChange,"setHomePageAsSelectedMenu", false).then((function(){
            this.router.setHomePageController(this.homePageController);
            this.router.setHomePageControllerInitialized(true);
            if(playCapsuleFeed && this.router.getHomePageController()){
                this.router.getHomePageController().playCapsuleFeed();
            }
        }).bind({"homePageController" :homePageController, "router": this}));
    }).bind(this));

}
},


showOpenedHomePage : function(){
var pageName = "Home Page";
var pageId = "home";
this.navigateToPageWithId( pageId,pageName  , true,false);
},



showHomePage : function(){
if(this.isHomeMenuPanelControllerInitialized())
{
    return window.trebble.pageContainerReadyPromise.then((function(){
        if(this.getHomeMenuPanelController()){
            this.getHomeMenuPanelController().getView().toggle();
        }

    }).bind(this)).catch(function(error){
        console.error("An error occured while displaying home menu panel.Error:"+ error);
        throw error;
    });

}else{
    return this._initializeHomeMenuPanelController().then(function(){
        return window.trebble.pageContainerReadyPromise;
    }).then((function(){
        if(this.getHomeMenuPanelController()){
            this.getHomeMenuPanelController().getView().toggle();
        }
    }).bind(this)).catch(function(error){
        console.error("An error occured while displaying home menu panel.Error:"+ error);
        throw error;
    });
}

},


showFeaturesPage : function(triggerHashChange){
triggerHashChange = triggerHashChange || false;
return this.require(["controllers/featurePage/FeaturePageController"]).then((function(FeaturePageController){
    var featurePageController  = new FeaturePageController();
    this.setCanPlayerFooterBeShowOnActivePage(false);
    var pageName = "Feature Page";
    var pageId = "featurePage";
    return this.renderAndDisplayPage(featurePageController, pageId, pageName, false,triggerHashChange);
}).bind(this));

},

showHelpPage : function(triggerHashChange){
triggerHashChange = triggerHashChange || false;
return this.require(["controllers/help/HelpPageController"]).then((function(HelpPageController){
    var helpPageController  = new HelpPageController();
    this.setCanPlayerFooterBeShowOnActivePage(false);
    var pageName = "Help Page For Curators";
    var pageId = "GetStartedForCuratorsPage";
    return this.renderAndDisplayPage(helpPageController, pageId, pageName, false,triggerHashChange, "setHelpPageAsSelectedMenu", true);
}).bind(this));

},

launchApplicationWithPresetToken : function(token, forwardUrl){
return TrebbleClientAPIHelper.getInstance().getTokenInfo(token).then((function(tokenInfo){
    if(tokenInfo){
        LocalStorageHelper.getInstance().saveTemporarlySessionTokenInfo(tokenInfo);
        if(history.pushState) {
            history.pushState(null, null, '');
        }else{
            location.hash = '';
        }
        if(forwardUrl && forwardUrl != "undefined"){
            var decodedForwardUrl = forwardUrl?decodeURIComponent(forwardUrl): null;
            window.location = decodedForwardUrl;
        }else{
            return this.showDefaultPage(true);
        }
    }else{
        var baseUrl = (window.location.protocol == "http:"||window.location.protocol == "https:")? location.origin + window.getAppBaseUrl():window.getBaseUrlWithoutEndSlash();
        return window.location.assign(baseUrl);
    }
}).bind(this)).catch((function(error){
    console.error(error);
    var baseUrl = (window.location.protocol == "http:"||window.location.protocol == "https:")? location.origin + window.getAppBaseUrl():window.getBaseUrlWithoutEndSlash();
    return window.location.assign(baseUrl);
}).bind(this));
},

launchApplicationWithPresetAccessCode  : function(accessCode){
if(accessCode){
    window.trebble.presetAccessCode = accessCode;
    if(history.pushState) {
        history.pushState(null, null, '');
    }else{
        location.hash = '';
    }
    return this.showDefaultPage(true);
}else{
    var baseUrl = (window.location.protocol == "http:"||window.location.protocol == "https:")? location.origin + window.getAppBaseUrl():window.getBaseUrlWithoutEndSlash();
    window.location.assign(baseUrl);
    return RSVP.Promise.resolve();
}

},



launchApplicationWithPresetAdReference  : function(adReference){
if(adReference){
    window.trebble.adReference = adReference;
    if(history.pushState) {
        history.pushState(null, null, '');
    }else{
        location.hash = '';
    }
    return this.showDefaultPage(true);
}else{
    var baseUrl = (window.location.protocol == "http:"||window.location.protocol == "https:")? location.origin + window.getAppBaseUrl():window.getBaseUrlWithoutEndSlash();
    window.location.assign(baseUrl);
    return RSVP.Promise.resolve();
}

},


showPlaylistPodcasts: function(triggerHashChange){
triggerHashChange = triggerHashChange || false;

if(this.isPlaylistPodcastInitialized())
{
    var pageName = "Playlist Podcast Page";
    var pageId = "PlaylistPodcastsPage";
    return this.navigateToPageWithId( pageId,pageName , true,triggerHashChange,null, "setTrebblesPageAsSelectedMenu");


}else{
    return this._initializePlaylistPodcastController().then((function(){
        var pageName = "Playlist Podcast Page";
        var pageId = "PlaylistPodcastsPage";
        this.navigateToPageWithId( pageId,pageName , true,triggerHashChange,null, "setTrebblesPageAsSelectedMenu");

    }).bind(this)).catch(function(error){
        console.error("An error occured while displaying playlist podcast Page.Error:"+ error);
        throw error;
    });
}
},




showSignupPage: function(triggerHashChange){
triggerHashChange = triggerHashChange || false;
return new RSVP.Promise((function(resolve , reject){
    try{

        var pageName = "Signup Page";
        var pageId = "signupPage";
        this.navigateToPageWithId( pageId,pageName , false,triggerHashChange);
        resolve();
    }catch(error){
        reject(error);
    }
}).bind(this));
},


_initializeFreeMinutesLeftWarningControllerIfApplicable : function(){
if(SHOW_WARNING_WITH_NUMBER_OF_FREE_HOURS_LEFT_OF_TRANSCRIPTION){
    var freeTranscriptionMinLeftWarningController = new FreeTranscriptionMinLeftWarningController();
    this._freeTranscriptionMinLeftWarningController = freeTranscriptionMinLeftWarningController
    this.listenTo(this._freeTranscriptionMinLeftWarningController, "warningTranscriptionLeftVisibilityChange", this._onViewportHeightChanged.bind(this));
    return freeTranscriptionMinLeftWarningController.buildModelAndRenderView();
}else{
    return RSVP.Promise.resolve();
}
},

_getFreeTranscriptionWarningHeight : function(){
return  this._freeTranscriptionMinLeftWarningController ? this._freeTranscriptionMinLeftWarningController.getHeight(): 0;
},


_isFreeTranscriptionWarningDisplayed : function(){
return this._freeTranscriptionMinLeftWarningController ?this._freeTranscriptionMinLeftWarningController.isVisible(): false;
},

_initializePlayerController : function(){
if(this._getInitializationPlayerControllerPromise()){
    return this._getInitializationPlayerControllerPromise();
}else{
    var p =  this.require(["controllers/player/PlayerController"]).then((function(PlayerController){
        var playerControllerParams = {};
        playerControllerParams.model = PersistentModels.getInstance().getPlayer();
        var playerController  = new PlayerController(playerControllerParams);
        playerController.listenTo(this,"songItemTapped", playerController.onSongItemTapped );
        playerController.listenTo(this,"addSongToEndOfQueue", playerController.addSongToEndOfQueue );
        this.setPlayerController(playerController);
        return playerController.buildModelAndRenderView().then((function(){
            $('body').append(this.playerController.getView().$el);
            this.router.setPlayerControllerInitialized.call(this.router, true);
            this.router.setPlayerController(this.playerController);
        }).bind({"router":this, "playerController": playerController }));
        

    }).bind(this))
    this._setInitializationPlayerControllerPromise(p);
    return p;

}

},

_initializeHomeMenuPanelController : function(){
if(this._getInitializationHomeMenuPanelControllerPromise()){
    return this._getInitializationHomeMenuPanelControllerPromise();
}else{
    var homeMenuPanelController = new HomeMenuPanelController();

    this.setHomeMenuPanelController(homeMenuPanelController);
    var p = homeMenuPanelController.buildModelAndRenderView().then((function(){
        $('body').append(this.homeMenuPanelController.getView().$el);
        this.router.listenForCloseEventOnHomeMenu();
        this.router.setHomeMenuPanelControllerInitialized.call(this.router, true);
        this.router.setHomeMenuPanelController(this.homeMenuPanelController);
    }).bind({"router":this, "homeMenuPanelController": homeMenuPanelController }));
    this._setInitializationHomeMenuPanelControllerPromise(p);
    return p;

}

},
listenForCloseEventOnHomeMenu : function(){
if(this.homeMenuPanelController && this.homeMenuPanelController.getView() && this.homeMenuPanelController.getView().$el){
    var homeMenuPanel$el = this.homeMenuPanelController.getView().$el;
    homeMenuPanel$el.on("panelclose",(function(){
        if(this._currentPageUrl){
            $(this._currentPageUrl.replace(/\//g , "\\/")).trigger("pageshow");
        }
    }).bind(this));
}
},
_initializePlaylistPodcastController : function(){
if(this._getInitializationPlaylistPodcastPromise()){
    return this._getInitializationPlaylistPodcastPromise();
}else{

    var controllerParams = {};
    var playlistPodcastsController = null;
    var p =  this.require(["controllers/playlistPodcast/PlaylistPodcastsController"]).then((function(PlaylistPodcastsController){
        playlistPodcastsController  = new PlaylistPodcastsController(controllerParams);
        return playlistPodcastsController.buildModelAndRenderView();
    }).bind(this)).then((function(){
        $('body').append(playlistPodcastsController.getView().$el);
        this.router.setPlaylistPodcastInitialized.call(this.router, true);
        this.router.setPlaylistPodcastController(playlistPodcastsController);
    }).bind({"playlistPodcastsController" :playlistPodcastsController, "router": this})).catch(function(error){
        console.error("An error occured while displaying Playlist podcast Page.Error:"+ error);
        throw error;
    });
    this._setInitializationPlaylistPodcastPromise(p);
    return p;

}

},

_setInitializationPlayerControllerPromise : function(p){
this._initializationPlayerControllerPromise = p;
},

_getInitializationPlayerControllerPromise : function(){
return this._initializationPlayerControllerPromise ;
},

_setInitializationHomeMenuPanelControllerPromise : function(p){
this._initializationHomeMenuPanelControllerPromise = p;
},

_getInitializationHomeMenuPanelControllerPromise : function(){
return this._initializationHomeMenuPanelControllerPromise ;
},

_setInitializationPlaylistPodcastPromise : function(p){
this._initializationPlaylistPodcastPromise = p;
},

_getInitializationPlaylistPodcastPromise : function(){
return this._initializationPlaylistPodcastPromise ;
},

setPlayerControllerInitialized : function(initialized){
this.playerInitialized = initialized;
},

setHomeMenuPanelControllerInitialized : function(initialized){
this.homeMenuPanelInitialized = initialized;
},

setPlaylistPodcastInitialized : function(initialized){
this.playlisPodcastInitialized = initialized;
},

isPlayerControllerInitialized : function(){
return this.playerInitialized;
},


_setDefaultPageIsBeingDisplayedPromise : function(pageIsBeingDisplayedPromise){
this.defaultPageIsBeingDisplayedPromise = pageIsBeingDisplayedPromise;
},

_getDefaultPageIsBeingDisplayedPromise : function(){
return this.defaultPageIsBeingDisplayedPromise;
},

isHomeMenuPanelControllerInitialized : function(){
return this.homeMenuPanelInitialized;
},

isPlaylistPodcastInitialized : function(){
return this.playlisPodcastInitialized;
},  

setGlobalSearchControllerInitialized : function(initialized){
this._globalSearchControllerInitialized = initialized;
},

isGlobalSearchControllerInitialized : function(){
return this._globalSearchControllerInitialized;
},

setGlobalSearchController : function(globalSearchController){
this._globalSearchController = globalSearchController;
},

setExploreControllerInitialized : function(initialized){
this._exploreControllerInitialized = initialized;
},

setListenerOnboardingControllerInitialized : function(initialized){
this._listenerOnboardingControllerInitialized = initialized;
},

setOnboardingWasDisplayedInSession: function(onboardingWasDisplayed){
this._onboardingWasDisplayedInSession = onboardingWasDisplayed;
},

isOnboardingWasDisplayedInSession: function(){
return this._onboardingWasDisplayedInSession;
},

setReferalPageControllerInitialized : function(initialized){
this._referalPageControllerInitialized = initialized;
},

setMySubscriptionsPageControllerInitialized : function(initialized){
this._mySubscriptionsPageControllerInitialized = initialized;
},

setMyJourneysPageControllerInitialized : function(initialized){
this._myJourneysPageControllerInitialized = initialized;
},

setHomePageControllerInitialized : function(initialized){
this._homePageControllerInitialized = initialized;
},


isExploreControllerInitialized : function(){
return this._exploreControllerInitialized;
},


isListenerOnboardingControllerInitialized : function(){
return this._listenerOnboardingControllerInitialized;
},

isReferalPageControllerInitialized : function(){
return this._referalPageControllerInitialized;
},

isMySubscriptionsPageControllerInitialized : function(){
return this._mySubscriptionsPageControllerInitialized;
},

isMyJourneysPageControllerInitialized : function(){
return this._myJourneysPageControllerInitialized;
},

isHomePageControllerInitialized : function(){
return this._homePageControllerInitialized;
},


setExploreController : function(exploreController){
this._exploreController = exploreController;
},

setListenerOnboardingController : function(listenerOnboardingController){
this._listenerOnboardingController = listenerOnboardingController;
},



setReferralPageController : function(referralPageController){
this._referralPageController = referralPageController;
},

setMySubscriptionsPageController : function(mySubscriptionsPageController){
this._mySubscriptionsPageController = mySubscriptionsPageController;
},

setMyJourneysPageController : function(myJourneysPageController){
this._myJourneysPageController = myJourneysPageController;
},

setHomePageController : function(homePageController){
this._homePageController = homePageController;
},

getExploreController : function(){
return this._exploreController;
},


getListenerOnboardingController : function(){
return this._listenerOnboardingController;
},

getMySubscriptionsPageController : function(){
return this._mySubscriptionsPageController;
},

getMyJourneysPageController : function(){
return this._myJourneysPageController;
},

getHomePageController : function(){
return this._homePageController;
},

setLoginControllerInitialized : function(initialized){
this.loginInitialized = initialized;
},

isLoginControllerInitialized : function(){
return this.loginInitialized;
},

setPlayerFooterControllerInitialized : function(initialized){
this.playerFooterInitialized = initialized;
},

setFooterMenuBarControllerInitialized : function(initialized){
this.footerMenuBarInitialized = initialized;
},

setPlayerFloatingControllerInitialized : function(initialized){
this.playerFloatingInitialized = initialized;
},

isPlayerFooterControllerInitialized : function(){
return this.playerFooterInitialized;
},

isFooterMenuBarControllerInitialized : function(){
return this.footerMenuBarInitialized;
},

isPlayerFloatingControllerInitialized : function(){
return this.playerFloatingInitialized;
},

setMyLibraryControllerInitialized : function(initialized){
this.myLibraryInitialized = initialized;
},

setMyLibraryController : function(myLibraryController){
this.myLibraryController = myLibraryController;
},

getMyLibraryController : function(){
return this.myLibraryController;
},

setPlayerFooterController : function(playerFooterController){
this.playerFooterController = playerFooterController;
},

setFooterMenuBarController : function(footerMenuBarController){
this.footerMenuBarController = footerMenuBarController;
},

setPlayerFloatingController : function(playerFloatingController){
this.playerFloatingController = playerFloatingController;
},

getPlayerFooterController : function(){
return this.playerFooterController;
},

getFooterMenuBarController : function(){
return this.footerMenuBarController;
},

getPlayerFloatingController : function(){
return this.playerFloatingController;
},

setPlayerController : function(playerController){
this.playerController = playerController;
},

getHomeMenuPanelController : function(){
return this.homeMenuPanelController;
},

setHomeMenuPanelController : function(homeMenuPanelController){
this.homeMenuPanelController = homeMenuPanelController;
},


getPlayerController : function(){
return this.playerController;
},

setPlaylistPodcastController : function(playlistPodcastController){
this.playlistPodcastController = playlistPodcastController;
},

getPlaylistPodcastController : function(){
return this.playlistPodcastController;
},

setLoginController : function(loginController){
this.loginController = loginController;
},

getLoginController : function(){
return this.loginController;
},

isMyLibraryControllerInitialized : function(){
return this.myLibraryInitialized;
},

isFooterMenuUsedInApp : function(){
return SHOW_FOOTER_MENU && (window.waitForCordovaToLoad || SHOW_FOOTER_MENU_ON_WEB)
},

isFloatingPlayerUsedInApp : function(){
return SHOW_FLOATING_PLAYER && (window.waitForCordovaToLoad || USE_FLOATING_PLAYER_ON_WEB);
},

setCanPlayerFooterBeShowOnActivePage : function(canPlayerFooterBeShowOnActivePage, transparent){
var body$el = $("body");
body$el.attr("canPlayerFooterBeShowOnActivePage",canPlayerFooterBeShowOnActivePage);
body$el.attr("playerFooterShouldBeTransparent",transparent);
if(this.isFooterMenuUsedInApp()){
    this.setCanFooterMenuBarShowOnActivePage(canPlayerFooterBeShowOnActivePage, transparent);
}
if(this.isFloatingPlayerUsedInApp()){
    return this.setCanPlayerFloatingBeShowOnActivePage(canPlayerFooterBeShowOnActivePage, transparent);
}else{
    this.canPlayerFooterBeShowOnActivePage = canPlayerFooterBeShowOnActivePage;

    if(!window.isEmbedded){
        if( this.getPlayerFooterController())
        {
            this.getPlayerFooterController().setCanBeShownOnActivePage(this.canPlayerFooterBeShowOnActivePage, transparent);
        }else{
            if(canPlayerFooterBeShowOnActivePage){
                var initializationPlayerFooterControllerPromise = this._getInitializationPlayerFooterControllerPromise()? this._getInitializationPlayerFooterControllerPromise(): this._initializePlayerFooterController();
                if(initializationPlayerFooterControllerPromise){
                    //player footer is being initialized
                    initializationPlayerFooterControllerPromise.then((function(){
                        if(this.getPlayerFooterController()){
                            this.getPlayerFooterController().setCanBeShownOnActivePage(this.canPlayerFooterBeShowOnActivePage, transparent);
                        }

                    }).bind(this));
                }
            }
        }
    }
}

},

setCanPlayerFloatingBeShowOnActivePage : function(canPlayerFooterBeShowOnActivePage, transparent){
this.canPlayerFooterBeShowOnActivePage = canPlayerFooterBeShowOnActivePage;

if(!window.isEmbedded){
    if( this.getPlayerFloatingController())
    {
        this.getPlayerFloatingController().setCanBeShownOnActivePage(this.canPlayerFooterBeShowOnActivePage, transparent);
    }else{
        if(canPlayerFooterBeShowOnActivePage){
            var initializationPlayerFloatingControllerPromise =  this._getInitializationPlayerFloatingControllerPromise()? this._getInitializationPlayerFloatingControllerPromise(): this._initializePlayerFloatingController();
            if(initializationPlayerFloatingControllerPromise){
                //player footer is being initialized
                initializationPlayerFloatingControllerPromise.then((function(){
                    if(this.getPlayerFloatingController()){
                        this.getPlayerFloatingController().setCanBeShownOnActivePage(this.canPlayerFooterBeShowOnActivePage, transparent);
                    }

                }).bind(this));
            }
        }
    }
}

},

setCanFooterMenuBarShowOnActivePage : function(canFooterMenuBeShowOnActivePage, transparent){
this.canFooterMenuBeShowOnActivePage = canFooterMenuBeShowOnActivePage;

if(!window.isEmbedded){
    if( this.getFooterMenuBarController())
    {
        this.getFooterMenuBarController().setCanBeShownOnActivePage(this.canFooterMenuBeShowOnActivePage, transparent);
    }else{
        if(canFooterMenuBeShowOnActivePage){
            var initializationFooterControllerPromise =this._getInitializationFooterMenuBarControllerPromise()? this._getInitializationFooterMenuBarControllerPromise(): this._initializeFooterMenuBarController();
            if(initializationFooterControllerPromise){
                //player footer is being initialized
                initializationFooterControllerPromise.then((function(){
                    if(this.getFooterMenuBarController()){
                        this.getFooterMenuBarController().setCanBeShownOnActivePage(this.canFooterMenuBeShowOnActivePage, transparent);
                    }

                }).bind(this));
            }
        }
    }
}

},

isFooterDisplayed : function(){
return this.isPlayerFooterViewDisplayed() || this.isFooterMenuViewDisplayed();
},

isFooterMenuViewDisplayed: function(){
if( this.getFooterMenuBarController())
{
    return this.getFooterMenuBarController().isFooterMenuViewDisplayed();
}
return false;
},

isPlayerFooterViewDisplayed : function(){
if( this.getPlayerFooterController())
{
    return this.getPlayerFooterController().isPlayerFooterViewDisplayed();
}
return false;
},

isPlayerFooterViewTransparent : function(){
if( this.getPlayerFooterController())
{
    return this.getPlayerFooterController().isPlayerFooterViewTransparent();
}
return false;
},

isPlayerFloatingViewDisplayed : function(){
if( this.getPlayerFloatingController())
{
    return this.getPlayerFloatingController().isPlayerFloatingViewDisplayed();
}
return false;
},

isPlayerFloatingViewTransparent : function(){
if( this.getPlayerFloatingController())
{
    return this.getPlayerFloatingController().isPlayerFloatingViewTransparent();
}
return false;
},

registerForViewportHeightChange : function(key , handlerFunction, isPopup){
if(!isPopup){
    this._viewportSizeChangedListeners[key] = handlerFunction;
}else{
    this._viewportSizeChangedListenersPopups = handlerFunction;
}
},

unRegisterForViewportHeightChange : function(key ,isPopup){
if(!isPopup){
    delete this._viewportSizeChangedListeners[key];
}else{
    delete this._viewportSizeChangedListenersPopups[key];
}


},

_onViewportHeightChanged : function(){
var headerHeight =  this.getDefaultHeaderHeight();
var footerHeight = 67;
var freeTranscriptionWarningHeight = this._getFreeTranscriptionWarningHeight();
//var paddingHeight = 10 * 2;
var newViewportSize = (window.getDocumentClienHeight() - headerHeight ) ;
var viewPortSizeForPopup = window.getDocumentClienHeight();
if(this.isFooterDisplayed()){
    newViewportSize = newViewportSize - footerHeight; 
}
if(this._isFreeTranscriptionWarningDisplayed()){
    newViewportSize = newViewportSize - freeTranscriptionWarningHeight;
}
for(let handlerKey  in this._viewportSizeChangedListeners){
    let handlerFunction= this._viewportSizeChangedListeners[handlerKey];
    if(handlerFunction != null){
        try{
            handlerFunction(newViewportSize);
        }catch(error){
            window.alertErrorMessage(error);
        }
    }
}
for(let handlerKey  in this._viewportSizeChangedListenersPopups){
    let handlerFunction= this._viewportSizeChangedListenersPopups[handlerKey];
    if(handlerFunction != null){
        try{
            handlerFunction(viewPortSizeForPopup);
        }catch(error){
            window.alertErrorMessage(error);
        }
    }
}

setTimeout(this.updateActivePageHeightToMatchPlayerFooterHeight.bind(this), 100);
},

getViewportHeight : function(){
var headerHeight = this.getDefaultHeaderHeight();
var footerHeight = 64;
var freeTranscriptionWarningHeight = this._getFreeTranscriptionWarningHeight();
//var paddingHeight = 10 * 2;
var viewportHeight = (window.getDocumentClienHeight() - headerHeight ) ;

if(this.isFooterDisplayed()){
    viewportHeight = viewportHeight - footerHeight; 
}
if(this._isFreeTranscriptionWarningDisplayed()){
    viewportHeight = viewportHeight - freeTranscriptionWarningHeight;
}
return viewportHeight;
},

getDefaultHeaderHeight : function(){
var headerHeight = 54;
return headerHeight;
},

getDefaultPageUiContentPadding : function(){
var paddingLeft = 16;
return paddingLeft;
},

getCanPlayerFooterBeShowOnActivePage : function(){
return this.canPlayerFooterBeShowOnActivePage;
},

_onControllerEvent: function(eventName, paramsArray){
this.trigger(eventName,paramsArray);
if(eventName in this.routes){
    const handlerFunctionName = this.routes[eventName];
    this[handlerFunctionName](paramsArray);
}
},

_initializePlayerFooterController : function(){
if(this._getInitializationPlayerFooterControllerPromise()){
    return this._getInitializationPlayerFooterControllerPromise();
}else{
    var playerFooterControllerParams = {};
    playerFooterControllerParams.model = PersistentModels.getInstance().getPlayer();
    playerFooterControllerParams.canBeShownOnActivePage = this.getCanPlayerFooterBeShowOnActivePage();
    var playerFooterController = null;
    var footerFileToLoad = null;
    if((window.device && window.device.platform !="browser" )|| window.isMobileBrowser){
        footerFileToLoad  =  "controllers/playerFooter/PlayerFooterController";
    }else{
        footerFileToLoad  =  "controllers/playerFooter/PlayerFooterBrowserController";
    }
    var p =  this.require([footerFileToLoad]).then((function(PlayerFooterController){
        playerFooterController  = new PlayerFooterController(playerFooterControllerParams);
        //this._registerController(playerFooterController,"playerFooter");
        return playerFooterController.buildModelAndRenderView();
    }).bind(this)).then((function(){
        $('body').append(playerFooterController.getView().$el);
        this.router.listenTo(playerFooterController.getView(), "onPlayerFooterHiddenOrDisplayed", this.router._onPlayerFooterHiddenOrDisplayed);
        this.router.setPlayerFooterControllerInitialized.call(this.router, true);
        this.router.setPlayerFooterController(playerFooterController);
    }).bind({"router": this, "playerFooterController": playerFooterController}));
    this._setInitializationPlayerFooterControllerPromise(p);
    return p;

}

},

_initializePlayerFloatingController : function(){
if(this._getInitializationPlayerFloatingControllerPromise()){
    return this._getInitializationPlayerFloatingControllerPromise();
}else{
    var playerFloatingControllerParams = {};
    playerFloatingControllerParams.model = PersistentModels.getInstance().getPlayer();
    playerFloatingControllerParams.canBeShownOnActivePage = this.getCanPlayerFooterBeShowOnActivePage();
    var playerFloatingController = null;
    
    var p =  this.require(["controllers/playerFooter/PlayerFloatingController"]).then((function(PlayerFloatingController){
        playerFloatingController  = new PlayerFloatingController(playerFloatingControllerParams);
        return playerFloatingController.buildModelAndRenderView();
    }).bind(this)).then((function(){
        $('body').append(playerFloatingController.getView().$el);
        $('body').addClass("useFloatingPlayer");
        this.router.listenTo(playerFloatingController.getView(), "onPlayerFloatingHiddenOrDisplayed", this.router._onPlayerFloatingHiddenOrDisplayed);
        this.router.setPlayerFloatingControllerInitialized.call(this.router, true);
        this.router.setPlayerFloatingController(playerFloatingController);
    }).bind({"router": this, "playerFloatingController": playerFloatingController}));
    this._setInitializationPlayerFloatingControllerPromise(p);
    return p;

}

},

_initializeFooterMenuBarController : function(){
if(this._getInitializationFooterMenuBarControllerPromise()){
    return this._getInitializationFooterMenuBarControllerPromise();
}else{
    var footerMenuBarControllerParams = {};
    footerMenuBarControllerParams.canBeShownOnActivePage = this.getCanPlayerFooterBeShowOnActivePage();
    var footerMenuBarController = null;

    var p =  this.require(["controllers/footerMenuBar/FooterMenuBarController"]).then((function(FooterMenuBarController){
        footerMenuBarController  = new FooterMenuBarController(footerMenuBarControllerParams);
        //this._registerController(playerFooterController,"playerFooter");
        return footerMenuBarController.buildModelAndRenderView();
    }).bind(this)).then((function(){
        $('body').append(footerMenuBarController.getView().$el);
        this.router.listenTo(footerMenuBarController.getView(), "onFooterMenuHiddenOrDisplayed", this.router._onFooterMenuBarHiddenOrDisplayed);
        this.router.setFooterMenuBarControllerInitialized.call(this.router, true);
        this.router.setFooterMenuBarController(footerMenuBarController);
    }).bind({"router": this, "footerMenuBarController": footerMenuBarController}));
    this._setInitializationFooterMenuBarControllerPromise(p);
    return p;

}

},


_onPlayerFooterHiddenOrDisplayed : function(){
this._onViewportHeightChanged();
var body$el = $(document.body);
if(this.getPlayerFooterController().isPlayerFooterViewDisplayed()){
    if(!body$el.hasClass("footerplayerdisplayed")){
        body$el.addClass("footerplayerdisplayed");
    }
}else{
    body$el.removeClass("footerplayerdisplayed");
}
},

_onPlayerFloatingHiddenOrDisplayed : function(){
this._onViewportHeightChanged();
var body$el = $(document.body);
if(this.getPlayerFloatingController().isPlayerFloatingViewDisplayed()){
    if(!body$el.hasClass("floatingplayerdisplayed")){
        body$el.addClass("floatingplayerdisplayed");
    }
}else{
    body$el.removeClass("floatingplayerdisplayed");
}
},

_onFooterMenuBarHiddenOrDisplayed : function(){
this._onViewportHeightChanged();
var body$el = $(document.body);
if(ALWAYS_DISPLAY_HELP){
    if(!body$el.hasClass("alwaysDisplayHelp")){
        body$el.addClass("alwaysDisplayHelp");
    }
}else{
    body$el.removeClass("alwaysDisplayHelp");
}
if(this.getFooterMenuBarController().isFooterMenuViewDisplayed()){
    if(!body$el.hasClass("footerMenuBardisplayed")){
        body$el.addClass("footerMenuBardisplayed");
    }
}else{
    body$el.removeClass("footerMenuBardisplayed");
}
},


_setInitializationPlayerFooterControllerPromise : function(p){
this._initializationPlayerFooterControllerPromise = p;
},

_setInitializationPlayerFloatingControllerPromise : function(p){
this._initializationPlayerFloatingControllerPromise = p;
},

_setInitializationFooterMenuBarControllerPromise : function(p){
this._initializationFooterMenuBarControllerPromise = p;
},


_getInitializationPlayerFooterControllerPromise : function(){
return this._initializationPlayerFooterControllerPromise ;
},

_getInitializationPlayerFloatingControllerPromise : function(){
return this._initializationPlayerFloatingControllerPromise ;
},

_getInitializationFooterMenuBarControllerPromise : function(){
return this._initializationFooterMenuBarControllerPromise ;
},



_initializeLoginController : function(){
//var loadPushNotificationHelperIfApplicable = window.trebble.appRunningInQuickStartMode? RSVP.Promise.resolve():this.require(["models/helper/PushNotificationHelper"]);
//return //loadPushNotificationHelperIfApplicable.then((function(PushNotificationHelper){
    var savedJQMHistoryStack = LocalStorageHelper.getInstance().getJQMHistoryStack() ;
    if(window.trebble.config.useSavedJQMHistory && savedJQMHistoryStack && savedJQMHistoryStack.length >1 )
    {
        //let backbone router handle that
    }else{

        if( PushNotificationHelper && PushNotificationHelper.getInstance().isUnconsumedNotificationNeedRouterPageRedirection()){
            document.body.style["background-color"]="rgba(17,17,17,1)";
            document.body.style["border-color"]="rgba(17,17,17,1)";
            $("#loadingBoxWidget").css("display","none");
            if(navigator.splashscreen){
                navigator.splashscreen.hide();
            }
            return window.trebble.pageContainerReadyPromise.then((function(){
                PushNotificationHelper.getInstance().processedUnconsumedNotificationEvent();
            }).bind(this)).catch(function(error){
                window.alertErrorMessage("An error occured while displaying notification.Error:"+ error);
                throw error;
            }); 
        }else{
            /*if(!location.hash){
                return this.showDefaultPage( true).then((function(){
                    document.body.style["background-color"]="rgba(17,17,17,1)";
                    document.body.style["border-color"]="rgba(17,17,175,1)";
                    $("#loadingBoxWidget").css("display","none");
                    if(navigator.splashscreen){
                        navigator.splashscreen.hide();
                    }
                    if(!LocalStorageHelper.getInstance().isLoggedInUserAListener()){
                        this.showWelcomeNewUserPopupIfApplicable();
                    }else{
                        this.showWelcomeNewListenerPopupIfApplicable();
                    }
                    if(PushNotificationHelper){
                        return PushNotificationHelper.getInstance().processedUnconsumedNotificationEvent();
                    }else{
                        return;
                    }
                }).bind(this));
            }else{*/
                document.body.style["background-color"]="rgba(17,17,17,1)";
                document.body.style["border-color"]="rgba(17,17,17,1)";
                $("#loadingBoxWidget").css("display","none");
                if(navigator.splashscreen){
                    navigator.splashscreen.hide();
                }
                if(!LocalStorageHelper.getInstance().isLoggedInUserAListener()){
                    this.showWelcomeNewUserPopupIfApplicable();
                }else{
                    this.showWelcomeNewListenerPopupIfApplicable();
                }
                if(PushNotificationHelper){
                    return PushNotificationHelper.getInstance().processedUnconsumedNotificationEvent();
                }else{
                    return RSVP.Promise.resolve();
                }
//}
}

}
//}).bind(this));
return RSVP.Promise.resolve();
},

showAndPlayWhatACapusleCapsule : function(){
if(window.trebbleAnalyticsHelper){
    window.trebbleAnalyticsHelper.trackEvent("onboarding", "View Capsule Definition Page", "View Capsule Definition Page");
} 
return this.showCapsulePoupForCapsuleId(WHATS_A_CAPSULE_CAPSULE_ID, null, true, true, false);
},

_setInitializationLoginControllerPromise : function(p){
this._initializationLoginControllerPromise = p;
},

_getInitializationLoginControllerPromise : function(){
return this._initializationLoginControllerPromise ;
},

_initializeMyLibrary : function(){
if(this._getInitializationMyLibraryControllerPromise()){
    return this._getInitializationMyLibraryControllerPromise();
}else{
    var myLibraryPageControllerParams = {};
    myLibraryPageControllerParams.songs = PersistentModels.getInstance().getSongCollection()?PersistentModels.getInstance().getSongCollection().toJSON():[];
    myLibraryPageControllerParams.albums = PersistentModels.getInstance().getAlbumCollection()?PersistentModels.getInstance().getAlbumCollection().toJSON():[];
    myLibraryPageControllerParams.artists = PersistentModels.getInstance().getArtistCollection()?PersistentModels.getInstance().getArtistCollection().toJSON():[];
    var myLibraryPageController = null;
    var promise = this.require(["controllers/myLibrary/MyLibraryNewPageController"]).then((function(MyLibraryPageController){
        //myLibraryPageControllerParams.playerFooterController = this.getPlayerFooterController();
        myLibraryPageController  = new MyLibraryPageController(myLibraryPageControllerParams);
        return myLibraryPageController.buildModelAndRenderView();
        
    }).bind(this)).then((function(){
        $('body').append(myLibraryPageController.getView().$el);
        this.router.setMyLibraryControllerInitialized.call(this.router,true);
        this.router.setMyLibraryController(myLibraryPageController);
    }).bind({"router": this, "myLibraryPageController":myLibraryPageController})).catch(function(error){
        window.log("Error loading library Page:" +error);
        throw error;
    });
    this._setInitializationMyLibraryControllerPromise(promise);
    return promise;

}
},

_setInitializationMyLibraryControllerPromise : function(p){
this._initializationMyLibraryControllerPromise = p;
},

_getInitializationMyLibraryControllerPromise : function(){
return this._initializationMyLibraryControllerPromise ;
},


changeProfileImage : function(){

var imageProfileUrl = null;
var uploadPromise = null;
if(window.trebble.config.disableZoomInCoverArtInMobileApp ||  (window.device && window.device.platform =="browser")){
    uploadPromise =  this._uploadProfileImageFromWebBrowser();
}else{
    uploadPromise =  this._uploadProfileImageFromMobile();
}
return uploadPromise.then((function(uploadedImageFileUrl){
    $.mobile.loading('show');
    if(uploadedImageFileUrl){
        imageProfileUrl = uploadedImageFileUrl;
        return TrebbleClientAPIHelper.getInstance().updateUserProfileImage(imageProfileUrl).then((function(imageProfileUrlBlur){
            return Utils.getInstance().preloadImageUsingUrl(imageProfileUrlBlur);
        }).bind(this));
    }

}).bind(this)).then((function(imageProfileUrlBlur){
    if(imageProfileUrl){
        //this is done to force the browser to reload the image

        var imageProfileUrlWithRandomString = false?imageProfileUrl + "?random="+ (new Date()).getTime(): imageProfileUrl;
        var imageProfileUrlBlurWithRandomString = false?imageProfileUrlBlur + "?random="+ (new Date()).getTime(): imageProfileUrlBlur;
        
        var authentificationToken  = LocalStorageHelper.getInstance().retrieveAuthentificationToken();
        if(authentificationToken && authentificationToken.userInfo){
            authentificationToken.userInfo.profileImageUrl = imageProfileUrlWithRandomString;
            authentificationToken.userInfo.profileImageUrlBlur = imageProfileUrlBlurWithRandomString;
            LocalStorageHelper.getInstance().saveAuthentificationToken(authentificationToken);

        }
    }
    $.mobile.loading('hide');
}).bind(this)).catch((function(error){
    $.mobile.loading('hide');
    throw error;
}).bind(this));
},

_uploadProfileImageFromMobile : function(){
return Utils.getInstance().getUserToPickAPicture().then((function(imageLocationUrl){
    $.mobile.loading('show');
    return FileUploadHelper.getInstance().uploadUserProfileImage(imageLocationUrl);
}).bind(this));
},

_uploadProfileImageFromWebBrowser : function(){
return new  RSVP.Promise((function(resolve, reject){
    var uploadEementId = "uploadWrapper_"+((new Date()).getTime());
    var nameOfFileToUpload =  "userProfileImage_"+(new Date()).getTime()+".jpg";
    var text = window.isMobileBrowser?window.getI18n(ti18n, "TAP_TO_SELECT_IMAGE"):window.getI18n(ti18n, "CLICK_TO_SELECT_IMAGE");
    var uploadZoneHtml=  "<span class='uploadZonePlaceHolder' id='"+uploadEementId +"'><div class='pe-7s-upload uploadIcon' style='font-size:40px'></div><span class='dz-message'>"+ text +"</span></span>";
    var buttonLabels = [window.getI18n(ti18n, "CANCEL"),window.getI18n(ti18n, "SAVE")];
    var dropZoneObj =  null;
    var confirmCallback = (function(buttonIndex){
        if(buttonIndex ==  2){
            if(dropZoneObj){
                if(dropZoneObj.getQueuedFiles().length == 0){
                    setTimeout(function(){swal.enableButtons()}, 300);
                }else{
                    FileUploadHelper.getInstance().validateAndSignFunctionHanlder(dropZoneObj, nameOfFileToUpload, FileUploadHelper.getInstance().getImageFileTypeValidationFunction(), "getSignatureForUserProfileImageUpload" ).then(function(){
                        dropZoneObj.processQueue();
                    }).catch(function(error){
                        navigator.trebbleNotification.alert(error, null, false, "error");
                    })

                }
            }
        }
    }).bind(this);
    navigator.trebbleNotification.confirm(uploadZoneHtml , confirmCallback, window.getI18n(ti18n, "CHANGE_PROFILE_PICTURE"), buttonLabels, true, null, null, true);
    var handlers = Utils.getInstance().getConfirmDialogDeferredHandlers(resolve, reject, this);
    dropZoneObj =  FileUploadHelper.getInstance().uploadUserProfileImageInWebBrowser(nameOfFileToUpload, "#"+ uploadEementId,handlers.resolve,handlers.reject);
}).bind(this));
},


help: function() {

},

search: function() {

}


});


Router.prototype.routeRegexMappedToGuestLoginAllowed = routeMapSummary.routeRegexMappedToGuestLoginAllowed;

/*
var routeRegexMappedToGuestLoginAllowedString = [];
for(let i in routeWithGuestLoginAllowed){
	var route  = routeWithGuestLoginAllowed[i];
	if(route instanceof RegExp){
		routeRegexMappedToGuestLoginAllowedString.push(String(route));
	}else{
		routeRegexMappedToGuestLoginAllowedString.push(route);
	}
}
Router.prototype.routeMap = routeMap;
var routeRegexMappedToGuestLoginAllowed = [];
for(let route in  routeMap){
	var isGuestLoginAllowed = false;
	var validRegex = null;
	if(!(route instanceof RegExp)){
		isGuestLoginAllowed = routeWithGuestLoginAllowed.indexOf(route) != -1;
		validRegex = _routeToRegExp(route);
	}else{
		isGuestLoginAllowed = routeWithGuestLoginAllowed.indexOf(String(route)) != -1;
		validRegex = route;
	}
	routeRegexMappedToGuestLoginAllowed.push({"regex":validRegex, "isGuestLoginAllowed" :isGuestLoginAllowed });
}
Router.prototype.routeRegexMappedToGuestLoginAllowed = routeRegexMappedToGuestLoginAllowed; */
export default Router;