		import $ from 'jquery';
        import _ from 'underscore';
        import Backbone from "backbone";
        import MixpanelJS from "mixpanel";
        import LocalStorageHelper from "models/helper/LocalStorageHelper";
        import FeatureRolloutHelper from "models/helper/FeatureRolloutHelper";
        import PusherHelper from "models/helper/PusherHelper";
        import Utils from "models/helper/Utils";
        import SubscriptionManager from "models/helper/SubscriptionManager";
        import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
        import ti18n from "i18n!nls/Sequenceri18n";
        import RSVP from "rsvp";


        const eventTypes = {
            "TRANSCRIPTION_STARTED": "transcriptionStarted",
            "TRANSCRIPTION_COMPLETED": "transcriptionCompleted",
            "TRANSCRIPTION_FAILED": "transcriptionFailed",
            "TRANSCRIPTION_MINUTES_CHANGED": "transcriptionMinutesChanged",
        }

        const Rollout = FeatureRolloutHelper.getInstance();

        const TranscriptionManager = Backbone.Model.extend({
            initialize : function(options) {
                this.init();
            },

            init : function(){
                this._transcriptionIdToTrebbleAudio = {};
                this._transcriptionIdToStatus = {};
                this._transcriptionIdToTranscriptionLocation = {};
                this._transcriptionIdToIsVideo= {};
                this._transcriptionIdToOnTranscriptionCompleted = {};
                this._transcriptionIdToOperationResult = {};
                this._bulkTranscriptionIdsToBulkTranscriptionInfo = {};
                
                try{
                    this.listenTo( PusherHelper.getInstance(),PusherHelper.getInstance().eventTypes.WEB_PUSH_NOTIFICATION, this._processNotificationEventWeb.bind(this));
                    this.listenTo( SubscriptionManager.getInstance(),SubscriptionManager.getInstance().eventTypes.ON_CREATOR_SUBSCRIPTION_UPDATED, this._onTranscriptionMinutesPossibleChanged.bind(this));
                }catch(e){
                    console.error("Failed initializing Transcription Manager. Error:"+e);
                }
                return RSVP.Promise.resolve();
            },

            _getAuthenticatedUserId : function(){
                const userInfo = LocalStorageHelper.getInstance().getAuthenticatedUserInfo();
                return userInfo? userInfo._id: null;

            },

            startBulklTranscription: function(arrayOfTrebbleAudio, onTranscriptionCompleted, language, onTranscriptionFailedStartingBecuaseNotEnoughMinutes, onTranscriptionFailedStartingForOtherReason){
                const promises = [];
                const transcriptionIdsSortedByIndex = new Array(arrayOfTrebbleAudio.length);
                const bulkTranscriptionInfo = {"transcriptionIdsSortedByIndex":transcriptionIdsSortedByIndex, "arrayOfTrebbleAudio":arrayOfTrebbleAudio,"language":language, "onTranscriptionFailedStartingBecuaseNotEnoughMinutes": onTranscriptionFailedStartingBecuaseNotEnoughMinutes, "onTranscriptionFailedStartingForOtherReason":onTranscriptionFailedStartingForOtherReason};
                for(let i =0; i < arrayOfTrebbleAudio.length; i++){
                    const trebbleAudio = arrayOfTrebbleAudio[i];
                    const p = this.startTranscription(trebbleAudio, onTranscriptionCompleted, language, onTranscriptionFailedStartingBecuaseNotEnoughMinutes, onTranscriptionFailedStartingForOtherReason);
                    
                    p.then((function(operationResult){
                        transcriptionIdsSortedByIndex[this.audioIndex] = operationResult.transcriptionId;
                        this.self._bulkTranscriptionIdsToBulkTranscriptionInfo[operationResult.transcriptionId] = bulkTranscriptionInfo;
                    }).bind({"audioIndex": i, "trebbleAudio": trebbleAudio,"self": this}));
                    promises.push(p);
                }
                if(window.trebbleAnalyticsHelper){
                    window.trebbleAnalyticsHelper.trackEvent("transcription", "Bulk Transcription", "Bulk Transcription");
                }
                return RSVP.Promise.all(promises);
            },

            _onTranscriptionMinutesPossibleChanged: function(){
                if(LocalStorageHelper.getInstance().isCurrentLoggedUserNotAVisitor() && !LocalStorageHelper.getInstance().isLoggedInUserAListener()){
                    return TrebbleClientAPIHelper.getInstance().getNumberOfMinutesOfTranscriptionLeft().then((function(transcriptionInfo){
                        this.trigger(eventTypes.TRANSCRIPTION_MINUTES_CHANGED, transcriptionInfo);
                    }).bind(this));
                }else{
                    this.trigger(eventTypes.TRANSCRIPTION_MINUTES_CHANGED);
                }

            },

            startTranscriptionAsync: async function(trebbleAudio, language){
                return await new Promise((async function(resolve, reject){
                    const onTranscriptionFailedStartingBecuaseNotEnoughMinutes = ()=>{
                        reject(window.getI18n(ti18n,"YOU_DONT_HAVE_ENOUGH_MINUTES_TO_EXECUTE_THIS_TRANSCRIPTION"));
                    }
                    try{
                        await this.startTranscription(trebbleAudio, resolve, language, onTranscriptionFailedStartingBecuaseNotEnoughMinutes, reject);
                    }catch(error){
                        reject(error);
                    }
                }).bind(this))
            },

            startTranscription: function(trebbleAudio, onTranscriptionCompleted, language, onTranscriptionFailedStartingBecuaseNotEnoughMinutes, onTranscriptionFailedStartingForOtherReason){
                if(window.trebbleAnalyticsHelper){
                    window.trebbleAnalyticsHelper.trackEvent("transcription", "On Transcription Started", "On Transcription Started", null, {"audioId":trebbleAudio? trebbleAudio.getAudioId() :null, "language": language });
                }
                LocalStorageHelper.getInstance().setLastLanguageUsedDuringTranscription(language);
                try{
                    this.trigger(eventTypes.TRANSCRIPTION_STARTED);
                    this._onTranscriptionMinutesPossibleChanged();
                }catch(error){
                    console.error(error);
                }
                return TrebbleClientAPIHelper.getInstance().startTranscriptionForAudioRecording(trebbleAudio.getAudioId(), language, trebbleAudio.getAudioType()).then((function(operationResult){
                    if(operationResult && operationResult.transcriptionWasSuccessfull && operationResult.transcriptionId){
                        const transcriptionId = operationResult.transcriptionId
                        this._transcriptionIdToTrebbleAudio[transcriptionId] = trebbleAudio;
                        this._transcriptionIdToOnTranscriptionCompleted[transcriptionId] = onTranscriptionCompleted;
                        this._transcriptionIdToOperationResult[transcriptionId] = operationResult;
                        this._transcriptionIdToStatus[transcriptionId] = Utils.getInstance().getTrebbleJobStatus().INITIALIZED;
                    }else{
                        if(operationResult && operationResult.userDoesNotHaveEnoughTranscriptionMinutes){
                            if(onTranscriptionFailedStartingBecuaseNotEnoughMinutes){
                                onTranscriptionFailedStartingBecuaseNotEnoughMinutes()
                            }
                        }else{
                            if(onTranscriptionFailedStartingForOtherReason){
                                onTranscriptionFailedStartingForOtherReason();
                            }
                        }
                    }
                    return operationResult;
                }).bind(this))
            },


            _processNotificationEventWeb: function(event, wasDeferred) {
                const isForeground = true;
                return  TrebbleClientAPIHelper.getInstance().getNotificationCustomPayloadByNotificationId(event.notificationId).then((function(customPlayloadJson){
                    const customPlayloadModel =  Utils.getInstance().getModelFromCustomNotificationPayload(customPlayloadJson);
                    const notiticationType =  customPlayloadModel.getNotificationType();

                    if(notiticationType == Utils.getInstance().getNotificationTypes().TRANSCRIPTION_JOB_COMPLETED){
                        return this._onTranscriptionCompleted(customPlayloadModel.getNotificationData())
                    }

                    if(notiticationType == Utils.getInstance().getNotificationTypes().TRANSCRIPTION_JOB_FAILED){
                        return this._onTranscriptionFailed(customPlayloadModel.getNotificationData())
                    }


                }).bind(this))
            },


            _callCallbackHandlerIfNecessary : function(transcriptionIdWithCallback){
                const callbackFunction = this._transcriptionIdToOnTranscriptionCompleted[transcriptionIdWithCallback];
                let results = [];
                if(callbackFunction){
                    if(this._bulkTranscriptionIdsToBulkTranscriptionInfo[transcriptionIdWithCallback]){
                        const bulkTranscriptionInfo = this._bulkTranscriptionIdsToBulkTranscriptionInfo[transcriptionIdWithCallback];
                        for(let i  in bulkTranscriptionInfo.transcriptionIdsSortedByIndex ){
                            const transcriptionId = bulkTranscriptionInfo.transcriptionIdsSortedByIndex[i];
                            const transcriptionFileLocation = this._transcriptionIdToTranscriptionLocation[transcriptionId]; 
                            const isVideo = this._transcriptionIdToIsVideo[transcriptionId]; 
                            const status = this._transcriptionIdToStatus[transcriptionId];
                            const isSlow = this._transcriptionIdToOperationResult[transcriptionId] && this._transcriptionIdToOperationResult[transcriptionId].isSlow;
                            if(status == Utils.getInstance().getTrebbleJobStatus().COMPLETED || status == Utils.getInstance().getTrebbleJobStatus().FAILED){
                                results.push({"transcriptionId":transcriptionId,"isSlow":isSlow, "trebbleAudio": this._transcriptionIdToTrebbleAudio[transcriptionId], "status": status, "transcriptionFileLocation": transcriptionFileLocation, "isVideo": isVideo});
                            }else{
                                //still waiting for a transcription to complete so return without notifiying
                                return;
                            }
                        }
                    }else{
                        const status = this._transcriptionIdToStatus[transcriptionIdWithCallback];
                        const transcriptionFileLocation = this._transcriptionIdToTranscriptionLocation[transcriptionIdWithCallback]; 
                        const isVideo = this._transcriptionIdToIsVideo[transcriptionIdWithCallback]; 
                        const isSlow = this._transcriptionIdToOperationResult[transcriptionIdWithCallback] && this._transcriptionIdToOperationResult[transcriptionIdWithCallback].isSlow;
                        if(status == Utils.getInstance().getTrebbleJobStatus().COMPLETED || status == Utils.getInstance().getTrebbleJobStatus().FAILED){
                            results = {"transcriptionId":transcriptionIdWithCallback, "isSlow":isSlow, "trebbleAudio": this._transcriptionIdToTrebbleAudio[transcriptionIdWithCallback], "status": status, "transcriptionFileLocation": transcriptionFileLocation,"isVideo": isVideo};
                        }else{
                            //still waiting for a transcription to complete so return without notifiying
                            return;
                        }
                    }
                    /*for(let transcriptionId  in this._transcriptionIdToOnTranscriptionCompleted ){
                        const cb = this._transcriptionIdToOnTranscriptionCompleted[transcriptionId];
                        if(cb === callbackFunction){
                            const status = this._transcriptionIdToStatus[transcriptionId];
                            if(status == Utils.getInstance().getTrebbleJobStatus().COMPLETED || status == Utils.getInstance().getTrebbleJobStatus().FAILED){
                                results.push({"transcriptionId":transcriptionId, "trebbleAudio": this._transcriptionIdToTrebbleAudio[transcriptionId], "status": status, "transcriptionFileLocation": transcriptionFileLocation});
                            }else{
                                //still waiting for a transcription to complete so return without notifiying
                                return;
                            }
                        }
                    }*/
                    callbackFunction(results);
                }
            },

            _onTranscriptionCompleted : function(notificationData){
                const transcriptionId = notificationData? notificationData.transcriptionId: null;
                const transcriptionFileLocation = notificationData? notificationData.transcriptionFileLocation: null;
                const isVideo = notificationData? notificationData.isVideo: false;
                this._transcriptionIdToStatus[transcriptionId] = Utils.getInstance().getTrebbleJobStatus().COMPLETED;
                this._transcriptionIdToTranscriptionLocation[transcriptionId] = transcriptionFileLocation;
                this._transcriptionIdToIsVideo[transcriptionId] = isVideo;
                this._callCallbackHandlerIfNecessary(transcriptionId, transcriptionFileLocation);
                if(window.trebbleAnalyticsHelper){
                    window.trebbleAnalyticsHelper.trackEvent("transcription", "On Transcription Completed", "On Transcription Completed", null, {"transcriptionId":transcriptionId, "transcriptionFileLocation": transcriptionFileLocation });
                }
                try{
                    this.trigger(eventTypes.TRANSCRIPTION_COMPLETED);
                    this._onTranscriptionMinutesPossibleChanged();
                }catch(error){
                    console.error(error);
                }
            },

            _onTranscriptionFailed : function(notificationData){
                const transcriptionId = notificationData? notificationData.transcriptionId: null;
                const transcriptionFileLocation = notificationData? notificationData.transcriptionFileLocation: null;
                const isVideo = notificationData? notificationData.isVideo: false;
                this._transcriptionIdToStatus[transcriptionId] = Utils.getInstance().getTrebbleJobStatus().FAILED;
                this._transcriptionIdToTranscriptionLocation[transcriptionId] = transcriptionFileLocation;
                this._transcriptionIdToIsVideo[transcriptionId] = isVideo;
                this._callCallbackHandlerIfNecessary(transcriptionId, transcriptionFileLocation);
                if(window.trebbleAnalyticsHelper){
                    window.trebbleAnalyticsHelper.trackEvent("transcription", "On Transcription Failed", "On Transcription Failed", null, {"transcriptionId":transcriptionId, "transcriptionFileLocation": transcriptionFileLocation });
                }
                try{
                    this.trigger(eventTypes.TRANSCRIPTION_FAILED);
                    this._onTranscriptionMinutesPossibleChanged();
                }catch(error){
                    console.error(error);
                }

            },




        });

        const transcriptionManagerInstance = new TranscriptionManager();

        transcriptionManagerInstance.eventTypes = eventTypes;




        export default {
			getInstance : function() {
				return transcriptionManagerInstance;
			}
		};