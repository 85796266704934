import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import RSVP from "rsvp";

const UserReview =  Backbone.Model.extend({
    idAttribute: "reviewId",

    getReviewId : function(){
        return this.get("reviewId");
    },

    getNumberOfStars : function(){
        return this.get("numberOfStars");
    },

    getCreationDate : function(){
        return new Date(this.get("creationDate"));
    },

    getLastUpdatedDate : function(){
        return new Date(this.get("lastUpdatedDate"));
    },

    getTextReview : function(){
        return this.get("textReview");
    },

    getUserId : function(){
        return this.get("userId");
    },

    getRadioId : function(){
        return this.get("radioId");
    },


    getJourneyId : function(){
        return this.get("journeyId");
    },

    
});
export default UserReview;