import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import PageTemplate from 'text!../../../templates/common/OperationProgressPageTemplate.html';

const pendingOperationStateAttributeName =  "pending-operation-state";
const OPERATION_STATE = {
    "PENDNG": "pending",
    "SUCCESS": "success",
    "ERROR": "error",
}
const OperationProgressPageView = Backbone.View.extend({

    template : _.template(PageTemplate),


    initialize : function() {

    },


    setLoadingMessage : function(message){
        this.$el.find("#loadingBoxWrapper .message").html(message);
        this.$el.attr(pendingOperationStateAttributeName, OPERATION_STATE.PENDNG);
    },

    setSuccessMessage : function(message, messageDetails){
        this.$el.find("#successBoxWrapper .message").html(message);
        this.$el.find("#successBoxWrapper .messageDetails").html(messageDetails);
        this.$el.attr(pendingOperationStateAttributeName, OPERATION_STATE.SUCCESS);
    },


    setErrorMessage : function(message, messageDetails){
        this.$el.find("#errorBoxWrapper .message").html(message);
        this.$el.find("#errorBoxWrapper .messageDetails").html(messageDetails);
        this.$el.attr(pendingOperationStateAttributeName, OPERATION_STATE.ERROR);
    },



    onBeforeRemoveFromPage : function(){
        this.trigger("beforeRemoveFromPage", this);
    },

    onRemovedFromPage : function(){
        this.undelegateEvents();
        this.$el.removeData().unbind(); 
        this.trigger("removedFromPage", this);
    },





    render : function(){

        this.$el.html(this.template());
        this.setElement(this.$el.find("div").first());

        this.$el.on("pagehide", this._onPageHide.bind(this));
        return this;
    },

    _onPageHide : function(){
        this.onBeforeRemoveFromPage();
        this.remove();
        this.onRemovedFromPage();

    },
});
export default OperationProgressPageView;