import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import Utils from "models/helper/Utils";
import WhatsNewPopupView from "views/whatsnew/WhatsNewPopupView";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import whatsNewTemplate from 'text!../../../templates/whatsnew/WhatsNewTemplate-0.6.0.html';
import RSVP from "rsvp";
import PersistentModels from "services/PersistentModels";

const WhatsNewPopupController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.template = whatsNewTemplate;
        this.context = options? options.context: null;

        _.extend(this, Backbone.Events);
    },
    
    

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    _onPopupClosed : function(){
        LocalStorageHelper.getInstance().setWhatsNewPageAlreadyShowedToUser();
    },

    showGetStartedPage : function(){
        PersistentModels.getInstance().getRouter().showHelpPage(true);
    },
    
    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                    if(this.template){
                        const params = {};
                        params.template = this.template;
                        const whatsNewPopupView = new WhatsNewPopupView(params);
                        whatsNewPopupView.render();
                        this.view = whatsNewPopupView;
                        this.listenTo(this.view, "onPopupClosed", this._onPopupClosed.bind(this))
                        this.listenTo(this.view, "showGetStartedPage", this.showGetStartedPage.bind(this))
                        
                    }
                    resolve();
                
                
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default WhatsNewPopupController;