import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import RSVP from "rsvp";

const CLASSNAME = "AudioSegment";
const AudioSegment =  Backbone.Model.extend({
    idAttribute: "id",

    constructor: function(attributes, options) {
        Backbone.Model.apply(this, [attributes, options]);
        this.set("type",AudioSegment.CLASSNAME);
        //this.set("initialStartTime", this.get("startTime"));
        //this.set("initialEndTime", this.get("endTime"));
        //this._audioFilters = [];
    },

    clone : function(){
        throw new Error("Need to be implemented in subclass!");
    },

    getAudioUrl : function(){
        return this.get("audioUrl");
    },

    getSerializedData: function(){
        const stateToReturn  = this.toJSON();
        if(stateToReturn.speakerInfo && stateToReturn.speakerInfo.getSerializedData){
            stateToReturn.speakerInfoJSON = stateToReturn.speakerInfo.getSerializedData();
            delete stateToReturn.speakerInfo;
        }
        if(stateToReturn.transcribedAudioInstance){
            stateToReturn.transcribedAudioInstance = stateToReturn.transcribedAudioInstance.getSerializedData();
        }
        return stateToReturn;
    },

    restoreFromSerializedData: function(serializedData, additionalSpeakerInfoArray){
        let speakerLabel = null;
        if(serializedData && serializedData.speakerInfoJSON){
            if(serializedData.speakerInfoJSON.modifiedSpeakerLabel){
                speakerLabel = serializedData.speakerInfoJSON.modifiedSpeakerLabel;
            }else{
                speakerLabel = serializedData.speakerInfoJSON.initialSpeakerLabel;
            }
        } 
        let speakerInfo = null;
        if(serializedData.transcribedAudioInstance){
            speakerInfo = serializedData.transcribedAudioInstance.getSpeakerInfoWithLabel(speakerLabel);
        }
        if(speakerLabel){
            for(let i =0; i <additionalSpeakerInfoArray.length; i++){
                const additionalSpeakerInfo = additionalSpeakerInfoArray[i];
                if(additionalSpeakerInfo.getSpeakerLabel()=== speakerLabel){
                    speakerInfo = additionalSpeakerInfo;
                    break;
                }
            }
        }
        this.set(serializedData);
        if(serializedData.speakerInfoJSON){
            this.setSpeakerInfo(speakerInfo);
        }
    },



    setAudioUrl : function(newAudioUrl){
        this.set("audioUrl", newAudioUrl);
        this.clearStartTimeAndEndTime();
    },
    
    clearStartTimeAndEndTime  :function(){
        this.set("startTime", null);
        this.set("endTime", null);
    },

    getStartTime : function(){
        return this.get("startTime");
    },

    getEndTime : function(){
        return this.get("endTime");
    },

    setStartTime : function(startTime){
        this.set("startTime", startTime);
    },

    setEndTime : function(endTime){
        this.set("endTime",endTime);
    },


    getInitialStartTime : function(){
        return this.get("initialStartTime");
    },

    getInitialEndTime : function(){
        return this.get("initialEndTime");
    },

    getInitialContent : function(){
        return this.get("initialContent");
    },

    getDuration : function(){
        const startTime = this.getStartTime();
        const endTime = this.getEndTime();
        if(startTime >= 0 && endTime >= 0 ){
            return endTime - startTime;
        }
        return 0;
    },

    canBeAssociactedWithAnySpeaker: function(){
        return true;
    },

    needToBeAssociactedWithASpeaker : function(){
        return false;
    },

    getSpeakerLabel : function(){
        return this.getSpeakerInfo()? this.getSpeakerInfo().getSpeakerLabel(): null;
    },

    getSpeakerInfo : function(){
        return this.get("speakerInfo");
    },

    setSpeakerInfo : function(speakerInfo){
        this.set("speakerInfo",speakerInfo);
    },

    getContent : function(addSpaceAroundContent){
        if(addSpaceAroundContent && this.get("content")){
            return " "+this.get("content")+" ";
        }else{
            return this.get("content");
        }
    },

    getConfidence : function(){
        return this.get("confidence");
    },

    setContent : function(newContent){
        this.set("content",newContent);
    },

    getHasVideo : function(){
        return this.getTranscribedAudioInstance()? this.getTranscribedAudioInstance().getHasVideo(): false;
    },

    getVideoWidth : function(){
        return this.getTranscribedAudioInstance()? this.getTranscribedAudioInstance().getVideoWidth(): false;
    },

    getVideoHeight : function(){
        return this.getTranscribedAudioInstance()? this.getTranscribedAudioInstance().getVideoHeight(): false;
    },

    /*addAudioFilter : function(filter){
        this._audioFilters.push(filter);
    },

    getAudioFilters : function(){
        return this._audioFilters;
    },*/

    getTranscribedAudioInstance: function(){
        return this.get("transcribedAudioInstance");
    },

    getStartTimeAndEndTimeOfNextAudioSegment : function(targetDurationInMilliseconds){
        if(this.getTranscribedAudioInstance() && targetDurationInMilliseconds > 0){
            if(this.getEndTime() + targetDurationInMilliseconds <= this.getTranscribedAudioInstance().getTotalDurationInMilliseconds()){
                return [this.getEndTime(), this.getEndTime() + targetDurationInMilliseconds];
            }else{
                return [this.getEndTime(), this.getEndTime() + this.getTranscribedAudioInstance().getTotalDurationInMilliseconds()];
            }
        }else{
            return null;
        }
        
    },

    getStartTimeAndEndTimeOfPreviousAudioSegment : function(targetDurationInMilliseconds){
        if(this.getTranscribedAudioInstance() && targetDurationInMilliseconds > 0){
            if(this.getStartTime() - targetDurationInMilliseconds >= 0){
                return [this.getStartTime() - targetDurationInMilliseconds, this.getStartTime() ];
            }else{
                return [0, this.getStartTime() ];
            }
        }else{
            return null;
        }
        
    },




});


AudioSegment.CLASSNAME = CLASSNAME;
export default AudioSegment; 