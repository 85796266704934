import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import QueuePageView from "views/queue/QueuePageView";
import Utils from "models/helper/Utils";
import PersistentModels from "services/PersistentModels";
import PlayerService from "services/PlayerService";
import RSVP from "rsvp";

const QueueController = Chaplin.Controller.extend({


    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if (options) {
            if (options.view) {
                this.view = options.view;
            }
            if (options.model) {
                this.model = options.model;
            }
        }
        
                    
    },
    
    getModel : function(){
        return this.model;
    },
    
    getView : function(){
        return this.view;
    },
    
    playSong : function(song){
        PersistentModels.getInstance().getPlayer().playSongInQueue(song);
    },
    
    
    showMenuOption : function(songJson){
        const songModel = Utils.getInstance().getModelFromSongJson(songJson);
        const context = this.model? this.model.getContext(): null;
        PersistentModels.getInstance().getRouter().showSongMenuOption(songModel, context, null,this.onActionExecutedOnSongHandler.bind(this));	
    },
    
    
    
    showQueueMenuOption : function(){
        PersistentModels.getInstance().getRouter().showQueueOptionPopup(this.model);
    },
    
    showQueueTuningOption : function(){
        PersistentModels.getInstance().getRouter().showQueueTuningPopup(this.model);
    },
    
    
    onActionExecutedOnSongHandler : function(songModel, action){
        if(action == "delete" || action == "removeFromRadio" || action == "removeToFavorite"){
            //Do notthing because it is already handled by the collection listeners
        }
    },
    
    grade : function(songModel){
        PersistentModels.getInstance().getRouter().showSongTagManagerPopup(songModel, this.context);
        
    },
    
    
    addToRadio : function(songModel){
        return PersistentModels.getInstance().addSongToDefaultRadio(songModel, this.context);
    },
    

    
    removeFromRadio : function(songModel){
        return PersistentModels.getInstance().removeSongFromDefaultRadio(songModel, this.context).then((function(){
            this.onActionExecutedOnSongHandler(songModel,"songRemovedFromTrebble");
        }).bind(this));
    },
    
    onViewRemoved:function(){
        this.stopListening();
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve,reject){
            try{
        
                //this.model =  PersistentModels.getInstance().getPlayer().getPlaylist();
                this.view = new QueuePageView({
                    "model" : this.model
                });
                this.listenTo(this.view, "itemTaped",this.playSong);
                this.listenTo(this.view, "itemOptionBtnTapped",this.showMenuOption);
                this.listenTo(this.view, "showQueueMenuOption", this.showQueueMenuOption);
                this.listenTo(this.view, "showQueueTuningOption", this.showQueueTuningOption);
                this.listenTo(this.view, "addToRadioButtonTapped", this.addToRadio);
                this.listenTo(this.view, "removeFromRadioButtonTapped", this.removeFromRadio);
                this.listenTo(this.view, "gradeButtonTapped", this.grade);
                this.listenTo(this.view, "remove", this.onViewRemoved.bind(this));
                this.view.render().then(resolve()).catch(reject);
                
            }catch(error){
                reject(error);
            }
            
        }).bind(this));

        
    }

});
export default QueueController;