import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import AlbumDetailPageView from "views/albumDetail/AlbumDetailPageView";
import PersistentModels from "services/PersistentModels";
import PlayerService from "services/PlayerService";
import SongListController from "controllers/common/SongListController";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import ti18n from "i18n!nls/AlbumDetailsi18n";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const AlbumDetailController = Chaplin.Controller.extend({


    initialize: function(options) {
        _.extend(this, Backbone.Events);
        if (options) {
            if (options.view) {
                this.view = options.view;
            }
            if (options.model) {
                this.model = options.model;
            }
            if(options.displayLocalSongAtFirstRendering === true || options.displayLocalSongAtFirstRendering === false){
               this.displayLocalSongAtFirstRendering = options.displayLocalSongAtFirstRendering ;
           }else{
               this.displayLocalSongAtFirstRendering = false;
           }
           if (options.pageId) {
            this._pageId = options.pageId;
        }
        this._albumId = options.albumId;
    }
    this.localFileDisplayOnly = this.displayLocalSongAtFirstRendering;
    const songListControllerParams = {};
    songListControllerParams.resizePlaceHolderElem = true;
    songListControllerParams.doNotSortDisplayedCollection = false;
    songListControllerParams.sortField = "track";
    songListControllerParams.loadHandler = this.getListOfSongToDisplay.bind(this);
    songListControllerParams.context =  this.getContext();
    this.songListController = new SongListController(songListControllerParams);


},

getAlbumId :function(){
    return this._albumId ? this._albumId: this.model.get("albumId");
},

getModel: function() {
    return this.model;
},

getView: function() {
    return this.view;
},

isLocalFileDisplayOnly: function(){
 return this.localFileDisplayOnly;
},

setLocalFileDisplayOnly: function(localFileDisplayOnly){
this.localFileDisplayOnly = localFileDisplayOnly;
},

getListOfSongToDisplay: function(){
if(this.isLocalFileDisplayOnly())
{
/*const songCollection = new SongsCollection(this.model.get("songs"));
const context =  Utils.getInstance().buildContextForSongCollectionAlbum(this.model.get("albumId"));
songCollection.updateContext(context);
return RSVP.Promise.resolve(songCollection);*/

/*const albumModelInUserLibrary = PersistentModels.getInstance().getAlbumModelByAlbumIdFromMyLibrary(this.model.get("albumId"));
return RSVP.Promise.resolve( albumModelInUserLibrary?albumModelInUserLibrary.get("songs"):[]);*/

return RSVP.Promise.resolve(this.model.get("songs"));
}else{
return this.loadSongListForAlbum().then((function(){
   return (this._completeAlbumInfoJson && this._completeAlbumInfoJson.songs)? this._completeAlbumInfoJson.songs : [];
}).bind(this));
}
},

loadSongListForAlbum :function(){
if(this._completeAlbumInfoJson)
{
return RSVP.Promise.resolve(this._completeAlbumInfoJson);
}else{
return 	TrebbleClientAPIHelper.getInstance().getAllSongsInAlbumFromAlbumId(this.getAlbumId()).then((function(completeAlbumInfo){
this._completeAlbumInfoJson = completeAlbumInfo;

return this._completeAlbumInfoJson;
}).bind(this));
}
},
/* 
clearQueueAndPlaySongAndTheRest: function(songSelected) {
const isMusicCurrentlyPlaying =  PlayerService.getInstance().isMusicPlaying();
const context = Utils.getInstance().buildContextForSongCollectionAlbum(this.model.get("albumId"));
PlayerService.getInstance().clearQueueAndSongToQueue(songSelected, "All My Music From \"" + this.model.get("album") +"\"", context );
if(!isMusicCurrentlyPlaying)
{
PlayerService.getInstance().playFirstSongInQueue();
}
window.setTimeout((function() {
this.getView().getDisplayedSongCollectionPromise().then(function(displayedSongCollection) {
const backboneSongSelectedModel = displayedSongCollection.get(songSelected.get("uri"));
PlayerService.getInstance().addSongsToQueue(displayedSongCollection.getSongsAfter(backboneSongSelectedModel));
});
}).bind(this), 0);

},



addSongToQueue: function(songModel) {
const arrayOfSongToAdd = [songModel];
if(songModel.type == "localFile")
{
songFound = PersistentModels.getInstance().getSongModelBySongIdFromMyLibraryOrFavorites(songModel.get("uri")); 
}
PlayerService.getInstance().addSongsToQueue([songFound]);
},

showMenuOption: function(songModel) {
const context =  this.getContext();
PersistentModels.getInstance().getRouter().showSongMenuOption(songModel, context, null,null);

},*/

showAlbumOptionPopup: function() {
PersistentModels.getInstance().getRouter().showAlbumOptionPopup(this.model);
},

shuffleLocalAlbum: function() {
const songUris = [];
const albumModel= this.model;
const songs = albumModel.get("songs");
for (const songIndex in songs) {
const songJson = songs[songIndex];
songUris.push(songJson.uri);

}
const arrayOfAlbumSongs = PersistentModels.getInstance().getSongModelsBySongIdsFromMyLibraryOrFavorites(songUris);
const shuffleArrayOfAlbumSongs = _.shuffle(arrayOfAlbumSongs);
const isMusicCurrentlyPlaying =  PlayerService.getInstance().isMusicPlaying();
const context = this.getContext();
PlayerService.getInstance().clearQueueAndSongToQueue(shuffleArrayOfAlbumSongs, window.getI18n(ti18n, "ALL_MY_MUSIC_FROM")+" \"" + this.model.get("album") + "\"", context);
if(!isMusicCurrentlyPlaying)
{
PlayerService.getInstance().playFirstSongInQueue();
}
},

getContext : function(){
return Utils.getInstance().buildContextForSongCollectionAlbum(this.getAlbumId());
},

shuffleAllSongsInAlbum: function(completeAlbumInfo) {
return this.loadSongListForAlbum().then((function(){
const isMusicCurrentlyPlaying =  PlayerService.getInstance().isMusicPlaying();
const context = Utils.getInstance().buildContextForSongCollectionAlbum(this.getAlbumId());
PlayerService.getInstance().clearQueueAndSongToQueue(completeAlbumInfo.toJSON(), window.getI18n(ti18n, "ALL_SONGS_FROM")+" \"" + this.model.get("album") + "\"",context);
if(!isMusicCurrentlyPlaying)
{
PlayerService.getInstance().playFirstSongInQueue();
}
}).bind(this));

},

filterList : function(filters){
window.alertErrorMessage(window.getI18n(ti18n,"COMING_SOON"));
},



showMenuOption : function(songModelTapped){
const context = this.getContext();
PersistentModels.getInstance().getRouter().showSongMenuOption(songModelTapped, context,null, this.onActionExecutedOnSongHandler.bind(this));
},

onActionExecutedOnSongHandler : function(songModel, action){
if(action == "delete" || action == "removeFromRadio" || action == "removeToFavorite"){
//Do notthing because it is already handled by the collection listeners
}
},

onViewRemoved:function(){
this.stopListening();
},

/*	grade : function(songModel){
PersistentModels.getInstance().getRouter().showSongTagManagerPopup(songModel, this.context);

},


addToRadio : function(songModel){
return PersistentModels.getInstance().addSongToDefaultRadio(songModel, this.context);
},



removeFromRadio : function(songModel){
return PersistentModels.getInstance().removeSongFromDefaultRadio(songModel, this.context).then((function(){
this.onActionExecutedOnSongHandler(songModel,"songRemovedFromTrebble");
}).bind(this));
},*/
buildModelAndRenderView: function() {
return new RSVP.Promise((function(resolve, reject) {
try {
   this.songListController.buildModelAndRenderView().then((function(){
    if(!this.model){
        return TrebbleClientAPIHelper.getInstance().getAlbumByAlbumId(this._albumId).then((function(albumJson){
            if(albumJson){
                this.model =  Utils.getInstance().getModelFromAlbumJson(albumJson);
            }else{
                throw window.getI18n(ti18n, "WE_COULDNT_FIND_THIS_ALBUM");
            }
        }).bind(this))
    }
}).bind(this)).then((function(){
  const songListView = this.songListController.getView();
  this.view = new AlbumDetailPageView({
      "model": this.model,
      "pageId": this._pageId ,
      "songListView": songListView,
      "displayLocalSongAtFirstRendering": this.displayLocalSongAtFirstRendering,
  });
  this.listenTo(this.view, "shuffleAlbum", this.shuffleLocalAlbum);
  this.listenTo(this.view, "showAlbumOptionPopup", this.showAlbumOptionPopup);
  this.listenTo(this.view, "shuffleAllSongsInAlbum", this.shuffleAllSongsInAlbum);
  this.listenTo(this.view, "filter", this.filterList);
  this.listenTo(this.view, "remove", this.onViewRemoved);
  this.view.render();
  resolve(this);
}).bind(this)).catch(reject);

} catch (error) {
reject(error);
}

}).bind(this));


}

});
export default AlbumDetailController;
