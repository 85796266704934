import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import ti18n from "i18n!nls/helperi18n";
import RSVP from "rsvp";

const mediaStatuses = {

    PLAYING : 2,
    PAUSED : 3,
    STOPPED : 4,

};

//Location to find sounds for Soundboard https://www.myinstants.com/index/ca/
const SoundNativeAudio =  Backbone.Model.extend({

    "default": {
        "DEFAULT_MEDIA_STATUS" :mediaStatuses,
        "src": "",
        "soundObject" :"",
        "mediaSuccess": "",
        "mediaError": "",
        "mediaStatus": "",

    },

    initialize : function(options) {
        this.src = options.uri;
        if(window.isIOSMobileDevice){
            this.src = this.src.replace("file:///","/private/")
        }
        this.onMediaSuccess = options.onSuccess;
        this.onMediaError = options.onError;
        this.onMediaStatus = options.onStatus;
        this.onMediaFailedLoading = options.onFailedLoading;
        this.onMediaReadyToPlay = options.onMediaReadyToPlay;
        this._autoLoad =  !!options.autoLoad;
        this._mediaFunctionHandlerContext = options.handlerContext;
        this._looping =  false;
        this._audioId = 'sound'+ (new Date().getTime());
        this._audioWasLoaded =  false;
        this.soundObjectIsReadyPromise = new RSVP.Promise((function(resolve,reject){
            try{

                window.plugins.NativeAudio.preloadComplex( this._audioId, this.src, 1, 1, 0, (function(){
                    this._audioWasLoaded = true;
                    this._loading = false;
                    this._onSongReadyToPlay(true);
                    resolve();
                }).bind(this), (function(error){
                    this._loading = false;
                    this._onSongError(null, error);
                    reject(error);
                }).bind(this));

            }catch(err){
                reject(err);
            }

        }).bind(this));



    },



    isLoading : function(){
        return this._loading;
    },

    getLoadingError : function(){
        return this._loadingError;
    },

    _playInternal :function(){
        this._loading = true;
        this.trigger("loading");

        const successCallback =  (function(){
            this._loading = false;
            this.trigger("loaded");
            this._isWaitingForSongToBeReadyToPlay = false;
            this._onSongPlay();
        }).bind(this);

        const errorCallback =  (function(error){
            this._onSongError(null, error);
        }).bind(this);

        const completeCallback =  (function(){
            this._onSongFinishedPlaying();
        }).bind(this);

        window.plugins.NativeAudio.play(this._audioId, successCallback, errorCallback, completeCallback);
    },

    play: function(){
        if(!this._released ){
            //this._exitFullscreen();
            if(this._audioWasLoaded)
            {
                if(!this._gettingReadyToPlayPromise){
                    const gettingReadyToPlayPromise = new RSVP.Promise((function(resolve, reject){
                        this._gettingReadyToPlayPromiseResolve = resolve;
                        this._gettingReadyToPlayPromiseReject = reject;
                    }).bind(this));
                    this._gettingReadyToPlayPromise = gettingReadyToPlayPromise;
                }
                this._playInternal();
            }else{
                this.soundObjectIsReadyPromise = this.soundObjectIsReadyPromise.then((function(){
                    //this.soundObject.play();
                    if(!this._released ){
                        if(!this._gettingReadyToPlayPromise){
                            const gettingReadyToPlayPromise = new RSVP.Promise((function(resolve, reject){
                                this._gettingReadyToPlayPromiseResolve = resolve;
                                this._gettingReadyToPlayPromiseReject = reject;
                            }).bind(this));
                            this._gettingReadyToPlayPromise = gettingReadyToPlayPromise;
                        }
                        this._playInternal();
                    }
                }).bind(this));
            }
        }

    },

    pause: function(){
        this.stop();
    },

    setVolume : function(volume){
        if(!this._released ){
            if(this._audioWasLoaded)
            {
                const successCallback =  (function(){

                }).bind(this);

                const errorCallback =  (function(error){
                    this._onSongError(null, error);
                }).bind(this);


                window.plugins.NativeAudio.setVolumeForComplexAsset(this._audioId, volume, successCallback, errorCallback);
            }
        }
    },

    _onSongError : function(errorCode, errorDesc){
        console.error("Error while playing audio. Error Code:"+errorCode+". Description:"+errorDesc);
        if(!this._released ){
            /*if(this._audioWasLoaded)
            {
                //Playback already started so log error message and pause playback
                this._stopInternal();
                //This might not be needed
                //this._onSongpause();

            }else{*/
                if(this.onMediaFailedLoading){
                    this.onMediaFailedLoading(errorDesc);
                }
                if(this.onMediaError){
                    this.onMediaError(errorDesc);
                }
                //}
            }
        },

        setLooping : function(looping){
            this._looping = looping;
        },

        _stopInternal : function(){
            const successCallback =  (function(){
                this._onSongStop();
            }).bind(this);

            const errorCallback =  (function(error){
                this._onSongError(null, error);
            }).bind(this);


            window.plugins.NativeAudio.stop(this._audioId, successCallback, errorCallback);

        },
        stop: function(){
            if(!this._released ){
                if(this._audioWasLoaded)
                {
                    this._stopInternal();
                }else{
                    this.soundObjectIsReadyPromise = this.soundObjectIsReadyPromise.then((function(){
                        if(!this._released ){
                            this.soundObject.stop();
                            this.soundObject.setPosition(0);
                        }
                    }).bind(this));
                }
                if(this._gettingReadyToPlayPromise){
                    this._gettingReadyToPlayPromise.then((function(){
                        if(this._audioWasLoaded){
                            this._stopInternal();
                        }
                    }).bind(this));
                }
            }

        },

        _releaseInternal : function(){
            const successCallback =  (function(){
                //this._onSongStop();
            }).bind(this);

            const errorCallback =  (function(error){
                this._onSongError(null, error);
            }).bind(this);


            window.plugins.NativeAudio.unload(this._audioId, successCallback, errorCallback);
        },

        release: function(){
            if(this._audioWasLoaded)
            {
                this._releaseInternal();
            }else{
                this.soundObjectIsReadyPromise = this.soundObjectIsReadyPromise.then((function(){
                    this.soundObject.destruct();
                }).bind(this));
            }
            this._released = true;
            this._isWaitingForSongToBeReadyToPlay = false;
            if(this._gettingReadyToPlayPromise){
                this._gettingReadyToPlayPromise.then((function(){
                    if(this._audioWasLoaded){
                        this._releaseInternal();
                    }
                }).bind(this));
            }
        },


        seekTo: function(position){
            /*if(!this._released ){
                if(this.soundObject)
                {
                    this.soundObject.setPosition(position);
                }else{
                    this.soundObjectIsReadyPromise = this.soundObjectIsReadyPromise.then((function(){
                        this.soundObject.setPosition(position);
                    }).bind(this));
                }
            }*/
            window.alertErrorMessage(window.getI18n(ti18n, "NOT_SUPPORTED_YET"));

        },

        startRecord: function(position){
            window.alertErrorMessage(window.getI18n(ti18n, "NOT_SUPPORTED_YET"));
        },

        stopRecord: function(position){
            window.alertErrorMessage(window.getI18n(ti18n, "NOT_SUPPORTED_YET"));
        },

        getDuration: function(position){
            if(!this._released){
                return 0;
            }else{
                return 0;
            }
        },

        getCurrentPosition: function(onSuccess, onFailure){
            return 0;
        },

        _onSongFinishedPlaying : function(){
            if(this._looping){
                this._playInternal();
            }else{
                this.onMediaSuccess.call(this._mediaFunctionHandlerContext);
            }

        },

        _onSongResume : function(){
            this.onMediaStatus.call(this._mediaFunctionHandlerContext,mediaStatuses.PLAYING, this);
            window.log("Song resumed");
        },

        _onSongPlay : function(){
            this.onMediaStatus.call(this._mediaFunctionHandlerContext,mediaStatuses.PLAYING, this);
            try{
                if(this._gettingReadyToPlayPromise){
                    this._gettingReadyToPlayPromiseResolve();
                    this._gettingReadyToPlayPromise = null;
                }
            }catch(error){
                console.error(error);
                this._gettingReadyToPlayPromise = null;
            }
            if(this._released){
                if(this._audioWasLoaded){
                    this._stopInternal();
                    this._releaseInternal();
                }
            }
            window.log("Song playing");
        },

        


        _onSongStop : function(){
            this._loading = false;
            this.trigger("loaded");
            this.onMediaStatus.call(this._mediaFunctionHandlerContext,mediaStatuses.STOPPED, this);
            window.log("Song Stopped");
        },

        _onSongReadyToPlay: function(isReady){
            if(isReady){
                if(this._isWaitingForSongToBeReadyToPlay){
                    this._playInternal();
                }else{
                    //do nothing
                }
                if(this.onMediaReadyToPlay){
                    this.onMediaReadyToPlay();
                }
            }else{
                if(this._audioWasLoaded){
                    this._releaseInternal();
                }
                this._loading = false;
                this._loadingError = window.getI18n(ti18n, "AUDIO_FAILED_LOADING");
                this.trigger("failedLoading",window.getI18n(ti18n, "AUDIO_FAILED_LOADING"));
                console.error("Audio Failed Loading");
                if(this.onMediaFailedLoading){
                    //call handler for failed loading
                    this.onMediaFailedLoading.call(this._mediaFunctionHandlerContext);
                }else{
                    //act like the song finish playing,
                    //player will probably just play the next song
                    this.onMediaSuccess.call(this._mediaFunctionHandlerContext);
                }

            }
            this._onDurationchange();
        },

        _onDurationchange : function(){
            
        },

        

        _onSongpause : function() {
            this._loading = false;
            this.trigger("loaded");
            this.onMediaStatus.call(this._mediaFunctionHandlerContext,mediaStatuses.PAUSED, this);
            window.log('Song Paused');
        },



    });
export default SoundNativeAudio;