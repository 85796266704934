import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import OptionPopupView from "views/common/OptionPopupView";
import Utils from "models/helper/Utils";

const ProgressBarPopupView = OptionPopupView.extend({

    "pageName": "Progress bar Popup",

    initialize: function(options) {
        const viewOptions = {};

        const contentView = {};

        contentView.$el = options.progressBarView.$el;
        viewOptions.contentView = contentView;
        viewOptions.contentPosition = "center";
        viewOptions.addContentAfterEnhancement = true;

        viewOptions.id = "progressBarViewPopup";
        OptionPopupView.prototype.initialize.call(this, viewOptions);
    },



    afterRendering: function() {
        
    },

    onPopupClosed: function() {

    },

    onAfterPageShow: function() {

    },



});

export default ProgressBarPopupView;
