

import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import ChooseSoundWidgetController from "controllers/chooseSound/ChooseSoundWidgetController";
import ChooseUploadsWidgetController from "controllers/chooseUploads/ChooseUploadsWidgetController";
import ChooseMusicWidgetController from "controllers/configureBackgroundMusic/ChooseMusicWidgetController";
import ChooseMusicSoundsAndUploadsWidgetView from "views/chooseMusicSoundsAndUploads/ChooseMusicSoundsAndUploadsWidgetView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import PlaylistHelper from "models/helper/PlaylistHelper";
import TrebbleAudio from "models/TrebbleAudio";
import FileUploadHelper from "models/helper/FileUploadHelper";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import ti18n from "i18n!nls/ConfigureBackgroundMusici18n";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const Rollout = RolloutHelper.getInstance();

//const CONFIGURE_BACKGROUND_MUSIC = Rollout.isFeatureEnabled(Rollout.FEATURES.CONFIGURE_BACKGROUND_MUSIC);

const ChooseMusicSoundsAndUploadsWidgetController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this._selectedTrebbleAudio = null;
        this._onTrebbleAudioSelected = options.onTrebbleAudioSelected;
        this._onTrebbleAudioUnselected = options.onTrebbleAudioUnselected;
        this._numberOfResultsPerPageForSounds  = options.numberOfResultsPerPageForSounds;
        this._numberOfResultsPerPageForBigBankSounds  = options.numberOfResultsPerPageForBigBankSounds;
        this._getTrebbleAudioSoundLoadHandler = options.getTrebbleAudioSoundLoadHandler;
        this._getTrebbleAudioBigBankSoundLoadHandler = options.getTrebbleAudioBigBankSoundLoadHandler;
        this._hideMusicTab = options.hideMusicTab;
        this._moveBigBankSoundsTabFirst = options.moveBigBankSoundsTabFirst;


        this._getTrebbleAudioModelMusicArrayByCategoryFunction = options.getTrebbleAudioModelMusicArrayByCategoryFunction;
        this._getMusicCategoryIdsFunction = options.getMusicCategoriesFunction;
        this._getMusicCategoryMapFunction = options.getMusicCategoriesMapFunction;
        this._musicWidgetHeightMargin  = options.musicWidgetHeightMargin? options.musicWidgetHeightMargin: 0;
    },


    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    setViewLoading :function(isLoading){
        this.view.isLoading(isLoading);
    },

    getSelectedTrebbleAudio : function(){
        return this._selectedTrebbleAudio;
    },

    onTrebbleAudioSelected : function(trebbleAudioSelected){
        if(this._selectedTrebbleAudio){
            this._selectedTrebbleAudio.setSelected(false);
        }
        if(trebbleAudioSelected){
            trebbleAudioSelected.setSelected(true);
        }
        this._selectedTrebbleAudio = trebbleAudioSelected;
        if(this._onTrebbleAudioSelected){
            this._onTrebbleAudioSelected(this._selectedTrebbleAudio)
        }
    },

    onTrebbleAudioUnselected : function(trebbleAudioUnselected){
        trebbleAudioUnselected.setSelected(false);
        this._selectedTrebbleAudio = null;
        if(this._onTrebbleAudioUnselected){
            this._onTrebbleAudioUnselected(trebbleAudioUnselected)
        }
    },

    _buildSoundWidgetController : function(){
        const params  = {};
        params.numberOfResultsPerPage = this._numberOfResultsPerPageForSounds;
        params.onTrebbleAudioSelected = this.onTrebbleAudioSelected.bind(this);
        params.onTrebbleAudioUnselected = this.onTrebbleAudioUnselected.bind(this);
        params.getTrebbleAudiosLoadHandler = this._getTrebbleAudioSoundLoadHandler;
        params.sourceCreditName = "Myinstants";
        params.sourceCreditWebsite = "https://www.myinstants.com";
        
        const chooseSoundWidgetController = new ChooseSoundWidgetController(params);
        this.chooseSoundWidgetController = chooseSoundWidgetController;
        return this.chooseSoundWidgetController.buildModelAndRenderView();
    },

    _buildBigBankSoundWidgetController : function(){
        const params  = {};
        params.numberOfResultsPerPage = this._numberOfResultsPerPageForBigBankSounds;
        params.onTrebbleAudioSelected = this.onTrebbleAudioSelected.bind(this);
        params.onTrebbleAudioUnselected = this.onTrebbleAudioUnselected.bind(this);
        params.getTrebbleAudiosLoadHandler = this._getTrebbleAudioBigBankSoundLoadHandler;
        params.sourceCreditName = "BigSoundBank";
        params.sourceCreditWebsite = "https://www.bigsoundbank.com/";
        
        const chooseSoundWidgetController = new ChooseSoundWidgetController(params);
        this.chooseBigBankSoundWidgetController = chooseSoundWidgetController;
        return this.chooseBigBankSoundWidgetController.buildModelAndRenderView();
    },

    _buildMusicWidgetController : function(){
        const params  = {};
        params.onTrebbleAudioSelected = this.onTrebbleAudioSelected.bind(this);
        params.onTrebbleAudioUnselected = this.onTrebbleAudioUnselected.bind(this);
        params.getTrebbleAudioModelArrayByCategoryFunction = this._getTrebbleAudioModelMusicArrayByCategoryFunction;
        params.getCategoriesFunction = this._getMusicCategoryIdsFunction;
        params.getCategoriesMapFunction = this._getMusicCategoryMapFunction;
        params.heightMargin = this._musicWidgetHeightMargin;
        
        const chooseMusicWidgetController = new ChooseMusicWidgetController(params);
        this.chooseMusicWidgetController = chooseMusicWidgetController;
        return this.chooseMusicWidgetController.buildModelAndRenderView();
    },

    _buildSoundUploadsWidgetController : function(){

        const params  = {};
        params.numberOfResultsPerPage = this._numberOfResultsPerPageForSounds;
        params.onTrebbleAudioSelected = this.onTrebbleAudioSelected.bind(this);
        params.onTrebbleAudioUnselected = this.onTrebbleAudioUnselected.bind(this);
        
        const chooseUploadsWidgetController = new ChooseUploadsWidgetController(params);
        this.chooseUploadsWidgetController = chooseUploadsWidgetController;
        return this.chooseUploadsWidgetController.buildModelAndRenderView();
    },

    
    buildModelAndRenderView : function() {
        const promises = [];
        if(!this._hideMusicTab){
            promises.push(this._buildMusicWidgetController());
        }
        promises.push(this._buildSoundWidgetController());
        if(!TrebbleClientAPIHelper.getInstance().isUserpreviouslyLoggedInAsGuest()){
            promises.push(this._buildSoundUploadsWidgetController());
        }
        promises.push(this._buildBigBankSoundWidgetController());
        
        return RSVP.Promise.all(promises).then((function(){
            const params = {};
            if(!this._hideMusicTab){
                params.musicWidgetView = this.chooseMusicWidgetController.getView();
            }
            params.soundWidgetView = this.chooseSoundWidgetController.getView();
            params.bigBankSoundWidgetView = this.chooseBigBankSoundWidgetController.getView();
            if(this.chooseUploadsWidgetController){
                params.uploadsWidgetView = this.chooseUploadsWidgetController.getView();
            }



            this.view = new ChooseMusicSoundsAndUploadsWidgetView(params);

            this.view.render();
            if(!this._hideMusicTab){
                this.view.showMusicTab();
            }else{
                this.view.hideMusicTab();
                this.view.showBigBankSoundsTab();
            }

            if(!this.chooseUploadsWidgetController){
                this.view.hideUploadab();
            }
            if(this._moveBigBankSoundsTabFirst){
                this.view.moveBigBankSoundTabFirst();
                this.view.showBigBankSoundsTab();
            }
            this.chooseBigBankSoundWidgetController.setScrollContainer(this.view.$el.find(".big_bank_sounds_widget_wrapper .selected_content_wrapper"));
            this.chooseSoundWidgetController.setScrollContainer(this.view.$el.find(".sounds_widget_wrapper .selected_content_wrapper"));
            if(this.chooseUploadsWidgetController){
                this.chooseUploadsWidgetController.setScrollContainer(this.view.$el.find(".uploads_widget_wrapper .selected_content_wrapper"));
            }
        }).bind(this))


    }

});

export default ChooseMusicSoundsAndUploadsWidgetController;