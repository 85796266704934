import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import CapsuleContextViewTemplate from 'text!../../../templates/common/CapsuleContextViewTemplate.html';
import RSVP from 'rsvp';

const CapsuleContextView = Backbone.View.extend({



    initialize : function(options) {
        this.compiledTemlate =  _.template(CapsuleContextViewTemplate);
        this.model = options? options.model : null;
    },
    
    events : {
        
    },

    setCoverArtUrl : function(coverArtUrl){
        this._coverArtUrl = coverArtUrl;
        this.updateCoverArt();
    },

    updateCoverArt : function(){
        if(this._isRendered){
            if(this._coverArtUrl){
                this.$el.find(".songCoverArt").removeClass("failedLoadingImage");
                this.$el.find(".songCoverArt").attr("src",window.trebble.globalHelpers.getResizeImageUrl(this._coverArtUrl , 60, 60, null));
            }else{
                //do nothing
            }
        }
    },
    
    render : function() {
        const templateParams = {};
        templateParams.model  = this.model;
        this.$el.html(this.compiledTemlate(templateParams));
        this.setElement(this.$el.find("div").first());
        this._isRendered = true;
        this.updateCoverArt();
        return this;
    }

});
export default CapsuleContextView;