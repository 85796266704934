

import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerOperationLog from "models/audioEditor/sequencerOperationLog/SequencerOperationLog";
import RSVP from "rsvp";

const SequencerOperationTranscribeAndReplaceSequencerNodeLog =  SequencerOperationLog.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperationLog.apply(this, [attributes, options]);
        
    },

    getOperationName : function(){
        return "Trascribe and Replace Node";
    },
    
    setSequencerNode : function(sequencerNode){
        this.set("sequencerNode", sequencerNode);
    },

    getSequencerNode : function(){
        return this.get("sequencerNode");
    },

    setLanguageCode : function(languageCode){
        this.set("languageCode", languageCode);
    },

    getLanguageCode : function(){
        return this.get("languageCode");
    },

    setArrayOfSequencerNodesToAdd : function(arrayOfSequencerNodesToAdd){
        this.set("arrayOfSequencerNodesToAdd", arrayOfSequencerNodesToAdd);
    },

    getArrayOfSequencerNodesToAdd : function(){
        return this.get("arrayOfSequencerNodesToAdd");
    },



});

export default SequencerOperationTranscribeAndReplaceSequencerNodeLog; 