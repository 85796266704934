import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import AudioFilter from "models/audioEditor/filters/AudioFilter";
import RSVP from "rsvp";

const PRESETS ={};

const PRESET_IDS = {
    "NYQUIST_FILTER":"nyquist",
    "AM_TALK_RADIO":"amTalkRadio",
    "AM_MUSIC_RADIO":"amMusicRadio",
    "FM_TALK_RADIO":"fmTalkRadio",
    "KILL_NON_AUDIBLE":"killNonAudible",
}

const createLowPassConfigPreset = function(frequency, Q){
    return {"frequency": frequency, "Q": Q};
}

PRESETS[PRESET_IDS.NYQUIST_FILTER] = createLowPassConfigPreset(2000);
PRESETS[PRESET_IDS.AM_TALK_RADIO] = createLowPassConfigPreset(5000);
PRESETS[PRESET_IDS.AM_MUSIC_RADIO] = createLowPassConfigPreset(6000);
PRESETS[PRESET_IDS.FM_TALK_RADIO] = createLowPassConfigPreset(15000);
PRESETS[PRESET_IDS.KILL_NON_AUDIBLE] = createLowPassConfigPreset(20000, 0.71);

const CLASSNAME = "LowPassFilter";

const LowPassFilter =  AudioFilter.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        AudioFilter.apply(this, [attributes, options]);
        this.setPresetIdToConfigMap(PRESETS);
        this.set("type", LowPassFilter.CLASSNAME);
    },

    
    setFrequency : function(frequency){
        this.set("frequency",frequency);
    },


    getFrequency : function(){
        return this.get("frequency");
    },

    setQ : function(Q){
        this.set("Q",Q);
    },


    getQ: function(){
        return this.get("Q");
    },

    isEqual : function(filter){
        if(filter instanceof LowPassFilter){
            return (this.isDisabled() == filter.isDisabled() && this.getFrequency() === filter.getFrequency() && this.getQ() === filter.getQ()); 
        }
        return false;
    },


    clone : function(){
        const cloneFilter = new LowPassFilter();
        cloneFilter.setDisabled(this.isDisabled());
        cloneFilter.setFrequency(this.getFrequency());
        cloneFilter.setQ(this.getQ());
        return cloneFilter;
    },

    
    needToPrepareAudioContext: function(){
        return false;
    },

    prepareAudioContext: function(audioContext){
        return RSVP.Promise.resolve(audioContext);
    },


    applyFilterToNode : function(audioNode, audioContext, arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime, sequencerRendererTracksInfo ){
        if(!this.isDisabled()){
            const frequency = this.getFrequency();
            const Q = this.getQ();
            const lowPassFilterNode = audioContext.createBiquadFilter();
            lowPassFilterNode.type = "lowpass";
            lowPassFilterNode.frequency.value = frequency;
            if(Q){
                lowPassFilterNode.frequency.Q = Q;
            }

            audioNode.connect(lowPassFilterNode);

            if(arrayOfAudioNodesUsed){
                arrayOfAudioNodesUsed.push(lowPassFilterNode);
            }

            return lowPassFilterNode;
        }else{
            return audioNode;
        }
    },



    applyFilter : function(previousOutputNode, audioContext, arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime, sequencerRendererTracksInfo, audioBuffer){
        if(!this.isDisabled()){

            if(!previousOutputNode){
                previousOutputNode = audioContext.createBufferSource();
                previousOutputNode.buffer = audioBuffer;
                if(arrayOfAudioNodesUsed){
                    arrayOfAudioNodesUsed.push(previousOutputNode);
                }
            }
            return RSVP.Promise.resolve(this.applyFilterToNode(previousOutputNode, audioContext, arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime, sequencerRendererTracksInfo ));
        }else{
            return RSVP.Promise.resolve(previousOutputNode);
        }
    },


});
LowPassFilter.PRESET_IDS = PRESET_IDS;
LowPassFilter.PRESETS = PRESETS;
LowPassFilter.CLASSNAME =  CLASSNAME;

export default LowPassFilter; 