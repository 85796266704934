import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";

const UserModel =  Backbone.Model.extend({
     idAttribute: "username",
     
    "default": {

    },

    getUserId : function(){
        return this.get("_id");
    },
    
    constructor: function (attributes, options){
         Backbone.Model.prototype.constructor.call(this, attributes,options);
            /*if(this.get("profileImageUrl"))
            {
                const profileImageUrl = this.get("profileImageUrl");
                //adding a timestamp on the image to make sure that we are not retrieving an old image from the cache
                const profileImageUrlWithTimestamp =profileImageUrl + "?random="+ (new Date()).getTime();
                this.set("profileImageUrl",profileImageUrlWithTimestamp);
            }*/
        },
    
    getFullName: function(){
        let fullname  = "";
        if(this.get("fullName")){
            return this.toTitleCase(this.get("fullName").trim());
        }
        if(this.get("firstName")){
            fullname = fullname + this.get("firstName");
        }
        if(this.get("lastName")){
            fullname = fullname + " "+ this.get("lastName");
        }
        return this.toTitleCase(fullname.trim());
    },

    getBio : function(){
        return this.get("bio");
    },

    getWebsite : function(){
        return this.get("website");
    },
    
    toTitleCase : function(str)
    {
        return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
    },
    
    canBeEdited : function(){
        return this.get("canBeEdited");
    },
    
    getUsername : function(){
        return this.get("username");
    },
    
    getNumberOfFollowers : function(){
        const numberOfFollowers = this.get("numberOfFollowers");
        return numberOfFollowers? numberOfFollowers: 0;
    },
    
    getNumberOfSongsInLibrary : function(){
        const numberOfSongsInLibrary = this.get("numberOfSongsInLibrary");
        return numberOfSongsInLibrary? numberOfSongsInLibrary: 0;
    },
    
    getNumberOfFollowings : function(){
        const numberOfFollowing = this.get("numberOfFollowing");
        return numberOfFollowing? numberOfFollowing: 0;
    },
    
    getNumberOfFollowedTrebbles : function(){
        const numberOfFollowedTrebbles = this.get("numberOfFollowedTrebbles");
        return numberOfFollowedTrebbles? numberOfFollowedTrebbles: 0;
    },
    
    getProfileImageUrl: function(){
        return this.get("profileImageUrl");
    },

    setProfileImageUrl: function(profileImageUrl){
        return this.set("profileImageUrl", profileImageUrl);
    },
    
    getProfileImageUrlBlur: function(){
        return this.get("profileImageUrlBlur");
    },

    setProfileImageUrlBlur: function(profileImageUrlBlur){
        return this.set("profileImageUrlBlur", profileImageUrlBlur);
    },
    
    getProfileImageUrlInfo: function(){
        return this.get("profileImageUrlInfo");
    },

    setProfileImageUrlInfo: function(profileImageUrlInfo){
        return this.set("profileImageUrlInfo", profileImageUrlInfo);
    },
    
    getProfileImageUrlBlurInfo: function(){
        return this.get("profileImageUrlBlurInfo");
    },

    setProfileImageUrlBlurInfo: function(profileImageUrlBlurInfo){
        return this.set("profileImageUrlBlurInfo", profileImageUrlBlurInfo);
    },
    
    canBeFollowed : function(){
        return this.get("canBeFollowed");
    },
    
    isFollowing : function(){
        return this.get("isFollowing");
    },
    
    setFollowing : function(following){
        this.set("isFollowing", following);
    },

    addFollowing : function(){
        let numberOfFollowing = this.get("numberOfFollowing");
        numberOfFollowing =  numberOfFollowing? numberOfFollowing: 0;
        numberOfFollowing = numberOfFollowing + 1;
        this.set("numberOfFollowing",numberOfFollowing);
    },
    
    removeFollowing : function(){
        let numberOfFollowing = this.get("numberOfFollowing");
        numberOfFollowing =  numberOfFollowing? numberOfFollowing: 0;
        if(numberOfFollowing != 0){
            numberOfFollowing = numberOfFollowing - 1;
        }
        this.set("numberOfFollowing",numberOfFollowing);
    },
    

    addFollower : function(){
        let numberOfFollowers = this.get("numberOfFollowers");
        numberOfFollowers =  numberOfFollowers? numberOfFollowers: 0;
        numberOfFollowers = numberOfFollowers + 1;
        this.set("numberOfFollowers",numberOfFollowers);
    },

    getProfileType : function(){
        return this.get("profileType");
    },
    
    removeFollower : function(){
        let numberOfFollowers = this.get("numberOfFollowers");
        numberOfFollowers =  numberOfFollowers? numberOfFollowers: 0;
        if(numberOfFollowers != 0){
            numberOfFollowers = numberOfFollowers - 1;
        }
        this.set("numberOfFollowers",numberOfFollowers);
    },

});
export default UserModel;