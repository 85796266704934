import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import CustomSearchFieldView from "views/common/CustomSearchFieldView";
import PersistentModels from "services/PersistentModels";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import listHeaderTemplate from "text!../../../templates/common/listHeaderTemplate.html";
import infoBoxTemplate from "text!../../../templates/common/infoBoxTemplate.html";
import GlobalSearchPageTemplate from 'text!../../../templates/globalSearch/GlobalSearchPageTemplate.html';
import ti18n from "i18n!nls/GlobalSearchi18n";
import listFooterTemplate from 'text!../../../templates/common/listFooterTemplate.html';


const Rollout = RolloutHelper.getInstance();

const ENABLE_JOURNEY_SEARCH = Rollout.isFeatureEnabled(Rollout.FEATURES.ENABLE_JOURNEY_SEARCH, true);
const GlobalSearchPageView = Backbone.View.extend({

    template : _.template(GlobalSearchPageTemplate),
    listHeaderTemplateCompiled : _.template(listHeaderTemplate),
    listFooterTemplateCompiled : _.template(listFooterTemplate),
    infoBoxTemplateCompiled : _.template(infoBoxTemplate),

    initialize : function(options) {
        this._songsListView = options.songsListView;
        this._albumsListView = options.albumsListView;
        this._artistsListView = options.artistsListView;
        this._soundcloudSongsListView =  options.soundcloudSongsListView;
        this._usersListView =  options.usersListView;
        this._playlistListView =  options.playlistListView;
        this._myLibrarySongsListView =  options.myLibrarySongsListView;
        this._youtubeVideoListView =  options.youtubeVideoListView;
        this._journeyListView = options.journeyListView;
        this._infoBoxView = options.infoBoxView;
        this._delayInMilliSecondOnSearchQueryExecution = 500;
        PersistentModels.getInstance().getRouter().registerForViewportHeightChange("globalsearchView",this._onViewportHeightChanged.bind(this));
        
    },
    
    _onViewportHeightChanged : function(newViewPortHeigh, forceResizing){
        if(PersistentModels.getInstance().getRouter().isEqualToCurrentPageUrl("#globalSearch") || forceResizing){
            if (this.el) {
                this.$el.find(".ui-content:first").first().css("height",(newViewPortHeigh) + "px") ; 
            }
            if(this._emptyInfoBox && this._emptyInfoBox.$el){
                this._emptyInfoBox.$el.css("height",newViewPortHeigh + "px");
            }
        }
    },
    
    
    events : {
        "click #homeHeaderButton": "showHomePage",
        "click #analyseAudioBtn" : "onAnalyseAudioBtnTapped",
        "click #header-back-button": "navigateToPreviousPage",
    },
    
    showHomePage : function(){
        this.trigger("showHomePage")
    },

    navigateToPreviousPage : function(){
        PersistentModels.getInstance().getRouter().navigateToPreviousPage();
    },

    getSearchFieldValue : function(){
        return this.customSearchFieldView.getSearchFieldValue();
    },
    
    onAnalyseAudioBtnTapped: function(){
        this.trigger("analyseAudioOutput");
    },

    _resizeSearchField : function(searchField){
        const homeButtonWidth = 61;
        const searchButtonWidth = 56;
        const newSearchFieldWidth = (window.getDocumentClienWidth() - homeButtonWidth) + "px";
        searchField.width(newSearchFieldWidth);
    },
    _onKeyUpInSearchField : function(searchFieldValue){
        if(this._pendingSearchQueryId)
        {
            clearTimeout(this._pendingSearchQueryId);
        }
        //wait a certain amount of time before sending search event
        this._pendingSearchQueryId = setTimeout((function(){
            this._scrollSearchContainerToTop();
            this.trigger("onKeyUpInSearchField",searchFieldValue);
        }).bind(this), this._delayInMilliSecondOnSearchQueryExecution);
        
    },
    

    _initSongListView : function(){
        this.$songResultContentHolder = $(document.createElement('div'));
        this.$songResultContentHolder.append(this.listHeaderTemplateCompiled({"id" : "songListHeader","text" : window.getI18n(ti18n, "SONGS")}));
        this.$songResultContentHolder.append(this._songsListView.el);
        this.$songListFooter = $(this.listFooterTemplateCompiled({"id" : "songListFooter","text" : window.getI18n(ti18n, "SEE_ALL_SONGS")})).first();
        this.$songListFooter.on("click",this._onSeeAllSongTapped.bind(this));
        this.$songResultContentHolder.append(this.$songListFooter);
        this.$songResultContentHolder.addClass("left-right-content-padding");
        this.$contentPlaceHolder.append(this.$songResultContentHolder);
        this.listenTo(this._songsListView, "modelChange",this._onSongCollectionChange );
    },
    
    _initMyLibrarySongListView : function(){
        this.$myLibrarySongResultContentHolder = $(document.createElement('div'));
        this.$myLibrarySongResultContentHolder.append(this.listHeaderTemplateCompiled({"id" : "myLibrarySongListHeader","text" : window.getI18n(ti18n, "MY_LIBRARY_SONGS")}));
        this.$myLibrarySongResultContentHolder.append(this._myLibrarySongsListView.el);
        this.$myLibrarySongListFooter = $(this.listFooterTemplateCompiled({"id" : "nyLibrarySongListFooter","text" :  window.getI18n(ti18n, "SEE_ALL_MY_LIBRARY_SONGS")})).first();
        this.$myLibrarySongListFooter.on("click",this._onSeeAllSongInMyLibraryTapped.bind(this));
        this.$myLibrarySongResultContentHolder.append(this.$myLibrarySongListFooter);
        this.$myLibrarySongResultContentHolder.addClass("left-right-content-padding");
        this.$contentPlaceHolder.append(this.$myLibrarySongResultContentHolder);
        this.listenTo(this._myLibrarySongsListView, "modelChange",this._onMyLibrarySongCollectionChange );
    },
    
    _initSoundcloudSongListView : function(){
        this.$soundcloudSongResultContentHolder = $(document.createElement('div'));
        this.$soundcloudSongResultContentHolder.append(this.listHeaderTemplateCompiled({"id" : "soundcloudSongListHeader","text" : window.getI18n(ti18n, "SOUNDCLOUD_SONGS")}));
        this.$soundcloudSongResultContentHolder.append(this._soundcloudSongsListView.el);
        this.$soundcloudSongListFooter = $(this.listFooterTemplateCompiled({"id" : "soundcloudSongListFooter","text" : window.getI18n(ti18n, "SEE_ALL_SOUNDCLOUD_SONGS")})).first();
        this.$soundcloudSongListFooter.on("click",this._onSeeAllSoundcloudSongTapped.bind(this));
        this.$soundcloudSongResultContentHolder.append(this.$soundcloudSongListFooter);
        this.$soundcloudSongResultContentHolder.addClass("left-right-content-padding");
        this.$contentPlaceHolder.append(this.$soundcloudSongResultContentHolder);
        this.listenTo(this._soundcloudSongsListView, "modelChange",this._onSoundcloudSongCollectionChange );
    },
    
    _initUserListView : function(){
        this.$userResultContentHolder = $(document.createElement('div'));
        this.$userResultContentHolder.append(this.listHeaderTemplateCompiled({"id" : "userResultListHeader","text" : window.getI18n(ti18n, "USERS")}));
        this.$userResultContentHolder.append(this._usersListView.el);
        this.$userResultListFooter = $(this.listFooterTemplateCompiled({"id" : "userResultListFooter","text" : window.getI18n(ti18n, "SEE_ALL_USERS")})).first();
        this.$userResultListFooter.on("click",this._onSeeAllUsersTapped.bind(this));
        this.$userResultContentHolder.append(this.$userResultListFooter);
        this.$userResultContentHolder.addClass("left-right-content-padding");
        this.$contentPlaceHolder.append(this.$userResultContentHolder);
        this._usersListView.initializeImageLazyLoadPlugin(this.$contentPlaceHolder);
        this.listenTo(this._usersListView, "modelChange",this._onUserCollectionChange );
    },
    
    _initTrebbleListView : function(){
        this.$trebbleResultContentHolder = $(document.createElement('div'));
        this.$trebbleResultContentHolder.append(this.listHeaderTemplateCompiled({"id" : "trebbleResultListHeader","text" : window.getI18n(ti18n, "TREBBLES")}));
        this.$trebbleResultContentHolder.append(this._playlistListView.el);
        this.$trebbleResultListFooter = $(this.listFooterTemplateCompiled({"id" : "trebbleResultListFooter","text" : window.getI18n(ti18n, "SEE_ALL_TREBBLES")})).first();
        this.$trebbleResultListFooter.on("click",this._onSeeAllTrebblesTapped.bind(this));
        this.$trebbleResultContentHolder.append(this.$trebbleResultListFooter);
        this.$trebbleResultContentHolder.addClass("left-right-content-padding");
        this.$contentPlaceHolder.append(this.$trebbleResultContentHolder);
        this._playlistListView.initializeImageLazyLoadPlugin(this.$contentPlaceHolder);
        this.listenTo(this._playlistListView, "modelChange",this._onTrebbleCollectionChange );
    },


    _initJourneyListView : function(){
        this.$journeyResultContentHolder = $(document.createElement('div'));
        this.$journeyResultContentHolder.append(this.listHeaderTemplateCompiled({"id" : "journeyResultListHeader","text" : window.getI18n(ti18n, "JOURNEYS")}));
        this.$journeyResultContentHolder.append(this._journeyListView.el);
        this.$journeyResultListFooter = $(this.listFooterTemplateCompiled({"id" : "journeyResultListFooter","text" : window.getI18n(ti18n, "SEE_ALL_JOURNEYS")})).first();
        this.$journeyResultListFooter.on("click",this._onSeeAllJourneysTapped.bind(this));
        this.$journeyResultContentHolder.append(this.$journeyResultListFooter);
        this.$journeyResultContentHolder.addClass("left-right-content-padding");
        this.$contentPlaceHolder.append(this.$journeyResultContentHolder);
        this._journeyListView.initializeImageLazyLoadPlugin(this.$contentPlaceHolder);
        this.listenTo(this._journeyListView, "modelChange",this._onJourneyCollectionChange );
    },
    
    _initYoutubeVideoListView : function(){
        this.$youtubeVideoResultContentHolder = $(document.createElement('div'));
        this.$youtubeVideoResultContentHolder.append(this.listHeaderTemplateCompiled({"id" : "youtubeSongListHeader","text" : window.getI18n(ti18n, "YOUTUBE_VIDEOS")}));
        this.$youtubeVideoResultContentHolder.append(this._youtubeVideoListView.el);
        this.$youtubeVideoListFooter = $(this.listFooterTemplateCompiled({"id" : "youtubeVideoListFooter","text" : window.getI18n(ti18n, "SEE_ALL_YOUTUBE_VIDEOS")})).first();
        this.$youtubeVideoListFooter.on("click",this._onSeeAllYoutubeVideoTapped.bind(this));
        this.$youtubeVideoResultContentHolder.append(this.$youtubeVideoListFooter);
        this.$youtubeVideoResultContentHolder.addClass("left-right-content-padding");
        this.$contentPlaceHolder.append(this.$youtubeVideoResultContentHolder);
        this.listenTo(this._youtubeVideoListView, "modelChange",this._onYoutubeVideoCollectionChange );
    },
    
    _onSeeAllSongInMyLibraryTapped : function(){
        this.trigger("showAllSongsInLibrary",this.getSearchFieldValue());
    },
    
    _onSeeAllSongTapped : function(){
        this.trigger("showAllSongs",this.getSearchFieldValue());
    },
    
    _onSeeAllSoundcloudSongTapped : function(){
        this.trigger("showAllSouncloudSongs",this.getSearchFieldValue());
    },
    
    _onSeeAllUsersTapped : function(){
        this.trigger("showAllUsersMatchingCriteria",this.getSearchFieldValue());
    },
    
    _onSeeAllTrebblesTapped : function(){
        this.trigger("showAllTrebblesMatchingCriteria",this.getSearchFieldValue());
    },


    _onSeeAllJourneysTapped : function(){
        this.trigger("showAllJourneysMatchingCriteria",this.getSearchFieldValue());
    },

    _onSeeAllYoutubeVideoTapped : function(){
        this.trigger("showAllYoutubeVideo",this.getSearchFieldValue());
    },
    
    _onSeeAllAlbumsTapped : function(){
        this.trigger("showAllAlbums",this.getSearchFieldValue());
    },
    
    _onSeeAllArtistsTapped : function(){
        this.trigger("showAllArtists",this.getSearchFieldValue());
    },

    
    _initAlbumListView : function(){

        this.$albumResultContentHolder = $(document.createElement('div'));
        this.$albumResultContentHolder.append(this.listHeaderTemplateCompiled({"id" : "albumListHeader","text" : window.getI18n(ti18n, "ALBUMS")}));
        this.$albumResultContentHolder.append(this._albumsListView.el);
        this.$albumListFooter = $(this.listFooterTemplateCompiled({"id" : "albumListFooter","text" : window.getI18n(ti18n, "SEE_ALL_ALBUMS")})).first();
        this.$albumResultContentHolder.append(this.$albumListFooter);
        this.$albumListFooter.on("click",this._onSeeAllAlbumsTapped.bind(this));
        this.$albumResultContentHolder.addClass("left-right-content-padding");
        this.$contentPlaceHolder.append(this.$albumResultContentHolder);
        this.listenTo(this._albumsListView, "modelChange",this._onAlbumCollectionChange );
        
    },
    

    _initArtistListView : function(){

        this.$artistResultContentHolder = $(document.createElement('div'));
        this.$artistResultContentHolder.append(this.listHeaderTemplateCompiled({"id" : "artistListHeader","text" : window.getI18n(ti18n, "ARTISTS")}));
        this.$artistResultContentHolder.append(this._artistsListView.el);
        this.$artistListFooter = $(this.listFooterTemplateCompiled({"id" : "artistListFooter","text" : window.getI18n(ti18n, "SEE_ALL_ARTISTS")})).first();
        this.$artistResultContentHolder.append(this.$artistListFooter);
        this.$artistListFooter.on("click",this._onSeeAllArtistsTapped.bind(this));
        this.$artistResultContentHolder.addClass("left-right-content-padding");
        this.$contentPlaceHolder.append(this.$artistResultContentHolder);
        this.listenTo(this._artistsListView, "modelChange",this._onArtistCollectionChange );
    },
    
    _onAlbumCollectionChange : function(newAlbumCollection){
        if(this._albumsListView.isCollectionEmpty() || window.trebble.doNotShowMusicInSearchPage){
            this.$albumResultContentHolder.hide();
        }else{
            this.$albumResultContentHolder.css('display', 'inline-block');
        }
        this._displayInfoMessageIfNecessary();
    },
    
    _onArtistCollectionChange : function(newArtistCollection){
        if(this._artistsListView.isCollectionEmpty() || window.trebble.doNotShowMusicInSearchPage){
            this.$artistResultContentHolder.hide();
        }else{
            this.$artistResultContentHolder.css('display', 'inline-block');
        }
        this._displayInfoMessageIfNecessary();
    },
    
    _onSongCollectionChange : function(newSongCollection){
        if(this._songsListView.isCollectionEmpty() || window.trebble.doNotShowMusicInSearchPage){
            this.$songResultContentHolder.hide();
        }else{
            this.$songResultContentHolder.css('display', 'inline-block');
        }
        this._displayInfoMessageIfNecessary();
    },
    
    _onSoundcloudSongCollectionChange : function(newSoundcloudSongCollection){
        if(this._soundcloudSongsListView.isCollectionEmpty() || window.trebble.doNotShowMusicInSearchPage){
            this.$soundcloudSongResultContentHolder.hide();
        }else{
            this.$soundcloudSongResultContentHolder.css('display', 'inline-block');
        }
        this._displayInfoMessageIfNecessary();
    },
    
    _onUserCollectionChange : function(newUserCollection){
        if(this._usersListView.isCollectionEmpty()){
            this.$userResultContentHolder.hide();
        }else{
            this.$userResultContentHolder.css('display', 'inline-block');
        }
        this._displayInfoMessageIfNecessary();
    },
    
    _onTrebbleCollectionChange : function(newTrebbleCollection){
        if(this._playlistListView.isCollectionEmpty()){
            this.$trebbleResultContentHolder.hide();
        }else{
            this.$trebbleResultContentHolder.css('display', 'inline-block');
        }
        this._displayInfoMessageIfNecessary();
    },

    _onJourneyCollectionChange : function(newJourneyCollection){
        if(this._journeyListView.isCollectionEmpty() || !ENABLE_JOURNEY_SEARCH){
            this.$journeyResultContentHolder.hide();
        }else{
            this.$journeyResultContentHolder.css('display', 'inline-block');
        }
        this._displayInfoMessageIfNecessary();
    },
    
    _onMyLibrarySongCollectionChange : function(myLibrarySongCollection){
        if(this._myLibrarySongsListView.isCollectionEmpty() || window.trebble.doNotShowMusicInSearchPage){
            this.$myLibrarySongResultContentHolder.hide();
        }else{
            this.$myLibrarySongResultContentHolder.css('display', 'inline-block');
        }
        this._displayInfoMessageIfNecessary();
    },
    
    _onYoutubeVideoCollectionChange : function(newYoutubeVideoCollection){
        if(this._youtubeVideoListView.isCollectionEmpty() || window.trebble.doNotShowMusicInSearchPage){
            this.$youtubeVideoResultContentHolder.hide();
        }else{
            this.$youtubeVideoResultContentHolder.css('display', 'inline-block');
        }
        this._displayInfoMessageIfNecessary();
    },
    
    _onSearchFieldCleared : function(){
        
    },
    
    
    
    _buildSearchHeaderButtonAndToPage: function(){
        const customSearchFieldViewParams = {};
        customSearchFieldViewParams.searchFieldPlaceholderSelector = "#pageHeaderTitleWrapper";
        customSearchFieldViewParams.searchFieldPlaceholderSelectorRootNodeEl  = this.el;
        this.customSearchFieldView = new CustomSearchFieldView(customSearchFieldViewParams);
        this.customSearchFieldView.render();
        this.customSearchFieldView.addKeyUpHandlerFunction(this._onKeyUpInSearchField.bind(this));
        this.customSearchFieldView.addOnSearchFieldClearedFunction(this._onSearchFieldCleared.bind(this));
        this.customSearchFieldView.addOnScreenResizeHandlerFunction(this._resizeSearchField.bind(this))
        this.$el.find(".rightHeaderButtonGroup").prepend(this.customSearchFieldView.el);

    },
    
    setSearchValue : function(newSearchValue){
        this.customSearchFieldView.setSearchFieldValue(newSearchValue, true);
    },
    _initDefaultInfoMessage: function(){
        if(this._infoBoxView){
            this.$contentPlaceHolder.append(this._infoBoxView.$el);
            this._infoBoxView.$el.css("height", "100%");
        }
        
    },
    
    _setHelpMessageOnInfoBox : function(){
        const iconClass = "pe-7s-search";
        const message = window.getI18n(ti18n, "SEARCH_TREBBLE_FM");
        const helpMessage = window.trebble.doNotShowMusicInSearchPage ? window.getI18n(ti18n, "FIND_USERS_TREBBLES") :window.getI18n(ti18n, "FIND_SONGS_ALBUMS_ARTISTS_USERS");
        const infoBoxClassName = null;
        const actionButtonLabel = null;
        const actionButtonIconClass = null;
        this._infoBoxView.update(iconClass,message,helpMessage,infoBoxClassName,actionButtonLabel,actionButtonIconClass)
    },
    
    _setNoResultFounMessagedOnOnInfoBox : function(){
        const iconClass = "pe-7s-search";
        const message = window.getI18n(ti18n, "NO_RESULT_FOUND_FROM_YOUR_SEARCH");
        const helpMessage = window.getI18n(ti18n, "PLEASE_TRY_DIFFERENT_KEYWORDS");
        const infoBoxClassName = null;
        const actionButtonLabel = null;
        const actionButtonIconClass = null;
        this._infoBoxView.update(iconClass,message,helpMessage,infoBoxClassName,actionButtonLabel,actionButtonIconClass)
    },
    
    _displayInfoMessageIfNecessary : function(){
        if(this._infoBoxView.$el)
        {
            if( (this._songsListView.isCollectionEmpty() || window.trebble.doNotShowMusicInSearchPage)
                && (this._albumsListView.isCollectionEmpty() || window.trebble.doNotShowMusicInSearchPage)
                && (this._artistsListView.isCollectionEmpty() || window.trebble.doNotShowMusicInSearchPage)
                && (this._soundcloudSongsListView.isCollectionEmpty() || window.trebble.doNotShowMusicInSearchPage)
                && this._usersListView.isCollectionEmpty()
                && this._playlistListView.isCollectionEmpty()
                && this._journeyListView.isCollectionEmpty()
                && (this._youtubeVideoListView.isCollectionEmpty() || window.trebble.doNotShowMusicInSearchPage)){
                if(this.customSearchFieldView.getSearchFieldValue()){
                    this._setNoResultFounMessagedOnOnInfoBox();
                }else{
                    this._setHelpMessageOnInfoBox();
                }
                if(!this.customSearchFieldView.isSearchFieldDisplayed()){
                    this._setHelpMessageOnInfoBox();
                }
                this._infoBoxView.$el.css("display", "");
            }else{
                this._infoBoxView.$el.css("display", "none");
            }
        }
        //this._scrollSearchContainerToTop();
    },
    
    _scrollSearchContainerToTop : function(){
        this.$contentPlaceHolder.scrollTop(0);
    },
    _onPageShow: function(){
        if(this.journeyListView){
            this.journeyListView.loadImages();
        }
        this.customSearchFieldView.showSearchField(true);
        if(window.trebbleAnalyticsHelper){
            window.trebbleAnalyticsHelper.trackEvent("pageShow", 'SearchPageShow', 'Search Page Show');
        }
        if(window.device && window.device.platform == "browser" && !window.isMobileBrowser){
            //this.customSearchFieldView.showSearchField();
            setTimeout((function(){
                if(this.customSearchFieldView.getSearchFieldValue()){
                    this.customSearchFieldView.selectAllText();
                }else{
                    this.customSearchFieldView.focusSearchField();
                }
            }).bind(this), 50);
        }
    },
    render : function(){
        this.$el.html(this.template());
        this.setElement(this.$el.find("div").first());
        this._buildSearchHeaderButtonAndToPage();
        this.$el.page();
        this.$el.on("pageshow", this._onPageShow.bind(this));
        this.$contentPlaceHolder =  this.$el.find(".ui-content:first").first();
        //manually adding the wrapper necessary by JQM for showing Panel
        //JQM does it on the fly but that can cause flickering on the screen when it is done by jquery mobile
        //the workaround is to do that manually when the page is getting initialized
        this.$el.children( ".ui-header:not(.ui-header-fixed), .ui-content:not(.ui-popup), .ui-footer:not(.ui-footer-fixed)" ).wrapAll( "<div class='ui-panel-wrapper'></div>" )
        
        if(window.device && window.device.platform == "browser"){
            // Hide analyze audio button if on browser platform
            this.$el.find("#analyseAudioBtn").hide();
        }
        this._initJourneyListView();
        this._initTrebbleListView();
        this._initMyLibrarySongListView();
        this._initDefaultInfoMessage();
        this._initUserListView();
        this._initSongListView();
        this._initAlbumListView();
        this._initArtistListView();
        this._initSoundcloudSongListView();
        this._initYoutubeVideoListView();			
        this._onMyLibrarySongCollectionChange();
        this._onSongCollectionChange();
        this._onAlbumCollectionChange();
        this._onArtistCollectionChange();
        this._onSoundcloudSongCollectionChange();
        this._onUserCollectionChange();
        this._onTrebbleCollectionChange();
        this._onJourneyCollectionChange();
        this._onYoutubeVideoCollectionChange();
        if(PersistentModels.getInstance().getRouter().isFooterMenuUsedInApp()){
            this.$el.find("#homeHeaderButton").hide();
        }else{
            this.$el.find("#header-back-button").hide();
        }
        //this.customSearchFieldView.showSearchField();
        //HIDE PAGE HEADER CONTROL_GROUP BUTTONS
        this.$el.find("#globalSearchPageHeader > .ui-controlgroup").hide();
        this._onViewportHeightChanged(PersistentModels.getInstance().getRouter().getViewportHeight(), true);
        return this;
    },

});
export default GlobalSearchPageView;