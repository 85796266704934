import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import OptionPopupView from "views/common/OptionPopupView";
import popupTemplate from 'text!../../../templates/audioEditor/SequencerHelpContextMenuTemplate.html';
import ti18n from "i18n!nls/Sequenceri18n";

const SequencerHelpContextOptionView = OptionPopupView.extend({

    initialize : function(options) {
        const viewOptions =  {};
        this.model = options.model;
        this.elementSource$el = options.elementSource$el;
        viewOptions.contentView = options.contentView;
        viewOptions.contentPosition = "center";
        viewOptions.elementSource$el = this.elementSource$el;
        viewOptions.showAsMenuIfInBrowser = true;
        viewOptions.alwaysShowAsFloatingMenu = true;
        viewOptions.customPopupPosition = options.popupPosition? options.popupPosition: "bottom right";
        viewOptions.id = "SequencerHelpContextPopup";
        viewOptions.customDropObjClass = "SequencerHelpContextPopupDrop";
        viewOptions.hideHeading = true;
        viewOptions.onBeforeClose = this.onBeforeClose.bind(this);
        OptionPopupView.prototype.initialize.call(this, viewOptions);
    },

    afterRendering : function(){

        
    },

    onBeforeClose : function(event){
        return true;
    },



    onAfterPageShow : function(){
        this.$el.addClass("SequencerHelpContextPopup");
    },



});

export default SequencerHelpContextOptionView;