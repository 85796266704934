import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import TrebbleView from "views/playlistPodcast/TrebbleCardView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import ti18n from 'i18n!nls/TrebbleCardi18n';
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const TrebbleCardController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.model = options.model;
        this.context = options.context;
        this.tuneOptions =  options.tuneOptions;
        this._customImageUrlProperty = options.customImageUrlProperty? options.customImageUrlProperty : null;
        this._notTransparent = options.notTransparent ? options.notTransparent: false;
        this._notPlayable =  options.notPlayable ? options.notPlayable: false;
        this._notDetailable =  options.notDetailable ? options.notDetailable: false;
        this._smallcardsize = options.smallcardsize ? options.smallcardsize: false;
        _.extend(this, Backbone.Events);
        this._listenToPlayerEvents();
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    destroy: function(){
        try{
            this.stopListening();
            if(this.view && this.view.remove){
                this.view.remove();
            }
        }catch(error){
            console.error(error);
        }
    },

    _listenToPlayerEvents : function(){
        if(!this._notPlayable){
            this.listenTo(PersistentModels.getInstance().getPlayer(),"songStartedPlaying",this.updatePlaybackButton.bind(this));
            this.listenTo(PersistentModels.getInstance().getPlayer(),"songLoading",this.updatePlaybackButton.bind(this));
            this.listenTo(PersistentModels.getInstance().getPlayer(),"playerLoading",this.updatePlaybackButton.bind(this));
            this.listenTo(PersistentModels.getInstance().getPlayer(),"playerFinishLoading",this.updatePlaybackButton.bind(this));
            this.listenTo(PersistentModels.getInstance().getPlayer(),"songLoaded",this.updatePlaybackButton.bind(this));
            this.listenTo(PersistentModels.getInstance().getPlayer(),"songFailedLoading",this.updatePlaybackButton.bind(this));
            this.listenTo(PersistentModels.getInstance().getPlayer(),"songPaused",this.updatePlaybackButton.bind(this));
        }
        this.listenTo(PersistentModels.getInstance(), "playlistSummarySubsciptionChanged", this.onPlaylistSummarySubsciptionChanged);
    },

    playTrebblePreview : function(){
        if(this.model){
            const genreId =  (this.tuneOptions &&  this.tuneOptions.length > 0)?this.tuneOptions[0]: null;
            let audioPreviewUrl = genreId?this.model.getAudioPreviewUrlByGenreId(genreId): null;
            if(!audioPreviewUrl){
                audioPreviewUrl = this.model.getAudioPreviewUrl();
            }
            if(audioPreviewUrl){
                PersistentModels.getInstance().getPlayer().playAudioPreview(audioPreviewUrl);
            }else{
                Utils.getInstance().showShortToastMessage(window.getI18n(ti18n, "NO_PREVIEW_YET_AVAILABLE"));
            }
        }
    },

    onPlaylistSummarySubsciptionChanged : function(playlistSummaryModel, subscribed){
        if(this.model && playlistSummaryModel && this.model.getLoaderKey() === playlistSummaryModel.getLoaderKey()){
            this.model.setFollowing(subscribed);
            this.updateFollowButton();
        }
    },

    updateFollowButton : function(){
        if(!this.model.canBeFollowed()){
            this.view.$el.find("[id=followingBtn]").css("display","none");
            this.view.$el.find("[id=followBtn]").css("display","none");
        }else{
            if(this.model.isFollowing()){
                this.view.$el.find("[id=followingBtn]").css("display","block");
                this.view.$el.find("[id=followBtn]").css("display","none");
            }else{
                this.view.$el.find("[id=followingBtn]").css("display","none");
                this.view.$el.find("[id=followBtn]").css("display","block");
            }
        }
    },

    onSubscribeTooltipIfApplicableClickedOn : function(){
        this.hideSubscribeTooltipIfApplicable();
    },

    hideSubscribeTooltipIfApplicable: function(noNeedToUpdateLocalStorageFlag){
        const subscribe_tooltip$el = this.view.$el.find("[id=followBtn] .subscribe_tooltip");
        if(!subscribe_tooltip$el.hasClass("hide")){
            subscribe_tooltip$el.addClass("hide");
            if(!noNeedToUpdateLocalStorageFlag){
                window.trebble.subscribeTooltipWasDiscardedByUserInCurrentSession = true;
                const loggedInUserInfo = LocalStorageHelper.getInstance().getUserInfo();
                const isTemporaryUser = loggedInUserInfo ? loggedInUserInfo.temporary : false;
                if(!isTemporaryUser){
                    LocalStorageHelper.getInstance().setSubscribreTooltipDiscardedByUser();
                }
            }
            this.view.$el.find(".listeningInstructionsBox.transparentChatbubble").each(function(){
                $( this ).removeClass( "transparentChatbubble" );
            });
        }
    },

    follow : function(){
        if(this.model && this.model.canBeFollowed()){
            this.hideSubscribeTooltipIfApplicable();
            return TrebbleClientAPIHelper.getInstance()._doLogin(false, true).then((function(){
                if(true){
                    const onTrebbleSubscriptionSucessHandler =  (function(){

                    }).bind(this);
                    const onTrebbleSubscriptionFailureHandler =  (function(error){

                    }).bind(this);

                    return PersistentModels.getInstance().getRouter().showSubscribeToTrebblePopupIfApplicable(this.model.getLoaderKey(), onTrebbleSubscriptionSucessHandler, onTrebbleSubscriptionFailureHandler);
                }else{
                    return PersistentModels.getInstance().subscribe(this.model);
                }
            }).bind(this));
        }else{
            return RSVP.Promise.resolve();
        }
    },

    unfollowConfirm : function(){
        return new RSVP.Promise((function(resolve, reject){
            try{
                const buttonLabels = [window.getI18n(ti18n, "NO"), window.getI18n(ti18n, "YES")];
                const confirmCallback = (function(buttonIndex){
                    if(buttonIndex ==  2){
                        return this.unfollow().then(resolve).catch(reject);
                    }else{
                        resolve();
                    }
                }).bind(this);
                const message = window.getI18n(ti18n, "ARE_YOU_SURE_YOU_WANT_TO_UNSUBSCRIBE_FROM") +" \""+ this.model.getName()+"\"?"
                navigator.trebbleNotification.confirm(message , confirmCallback,  window.getI18n(ti18n, "UNSCUBSCRIBE_FROM_TREBBLE"), buttonLabels,null , null);
            }catch(error){
                reject(error);
            }
        }).bind(this));
    } ,


    unfollow : function(){
        if(this.model){
            return PersistentModels.getInstance().unsubscribe(this.model);
        }else{
            return RSVP.Promise.resolve();
        }
    },


    updatePlaybackButton : function(){
        const playerModel =  PersistentModels.getInstance().getPlayer();
        if(playerModel.isPlaylistCurrentlyLoaded(this.model)){
            if(playerModel.isLoading() || playerModel.isPlayerLoading()){
                this.view.$el.removeClass("isCurrentlyPlaying");
                this.view.$el.addClass("isCurrentlyLoading");
            }else{
                this.view.$el.removeClass("isCurrentlyLoading");
                if (playerModel.isPlaying()) {
                    this.view.$el.addClass("isCurrentlyPlaying");
                } else {
                    this.view.$el.removeClass("isCurrentlyPlaying");
                }
            }
        }else{
            this.view.$el.removeClass("isCurrentlyPlaying");
            this.view.$el.removeClass("isCurrentlyLoading");
        }

    },

    setTrebbleIsLoading  : function(){
        if(!this.view.$el.hasClass("isCurrentlyLoading")){
            this.view.$el.addClass("isCurrentlyLoading");
        }
    },

    pauseTrebble : function(){
        if(PersistentModels.getInstance().getPlayer().isPlaylistCurrentlyLoaded(this.model)){
            PersistentModels.getInstance().getPlayer().pauseAudio();
        }
    },

    stopPreviewAndResumePlayback : function(){
        PersistentModels.getInstance().getPlayer().terminateAudioPreviewAndResumePlayback();
    },

    playTrebble  : function(){
        if(PlayerService.getInstance().isPlaylistCurrentlyLoaded(this.model)){
            if(PlayerService.getInstance().isMusicPlaying()){
                PlayerService.getInstance().pauseMusicPlaying();
            }else{
                PlayerService.getInstance().resumeMusicPlaying();
            }
        }else{
            this.setTrebbleIsLoading();
            PlayerService.getInstance().playTrebble(this.model, this.tuneOptions);
        }
    },


    showTrebbleDetailsPage  : function(){
        PersistentModels.getInstance().getRouter().showPublicRadioDetailPage(this.model, true);
    },

    showEditTrebblePage : function(){
        PersistentModels.getInstance().getRouter().showTrebbleInfoEditPage(this.model, true);
    },

    showShareTrebblePopup : function(){
        PersistentModels.getInstance().getRouter().showTrebbleSharePopup(this.model, true);
    },


    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                const params = {};
                params.model = this.model;
                params.customImageUrlProperty = this._customImageUrlProperty;
                params.notTransparent = this._notTransparent;
                params.smallcardsize = this._smallcardsize;
                params.notDetailable  = this._notDetailable;
                params.notPlayable = this._notPlayable;
                this.view = new TrebbleView(params);
                this.listenTo(this.view, "showTrebbleDetailsPage",this.showTrebbleDetailsPage);
                this.listenTo(this.view, "showEditTrebblePage",this.showEditTrebblePage);
                this.listenTo(this.view, "showShareTrebblePopup",this.showShareTrebblePopup);
                this.listenTo(this.view, "playTrebble",this.playTrebble);
                this.listenTo(this.view, "stopPreviewAndResumePlayback",this.stopPreviewAndResumePlayback);
                this.listenTo(this.view, "playTrebblePreview",this.playTrebblePreview);
                this.listenTo(this.view, "pauseTrebble", this.pauseTrebble);
                this.listenTo(this.view, "follow", this.follow);
                this.listenTo(this.view, "unfollow", this.unfollowConfirm);
                this.listenTo(this.view, "subscribeTooltipIfApplicableClickedOn", this.onSubscribeTooltipIfApplicableClickedOn);
                this.view.render();
                this.updatePlaybackButton();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

}

});

export default TrebbleCardController;