import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import DynamicContentView from "views/common/DynamicContentView";
import viewContentTemplate from 'text!../../../templates/capsule/CapsuleViewDetailContentTemplate.html';
import editContentTemplate from 'text!../../../templates/capsule/CapsuleEditDetailContentTemplate.html';
import ti18n from "i18n!nls/CapsuleDetailsi18n";
import RSVP from "rsvp";

const CapsuleEditContentView = DynamicContentView.extend({


    initialize : function(options) {
        
        this._isPlaying = false;
        this._initialLifeSpan = null;
        this._initialCategoryId = null;
        this._lifeSpanChanged = false;
        this._capsuleModel = options.capsuleModel;
        this._initialLifeSpan =  this._capsuleModel? this._capsuleModel.get("numberOfDaysToLive") : null;
        this._initialCategoryId =  this._capsuleModel? this._capsuleModel.get("categoryId") : null;
        this._capsuleId = options.capsuleId;
        this._loadFunction = options.loadFunction;
        this._editMode =  options.editMode;
        this._capsuleCategoryCollection = options.capsuleCategoryCollection;
        if(this._editMode){
            this.template = _.template(editContentTemplate);
        }else{
            this.template = _.template(viewContentTemplate);
        }

        DynamicContentView.prototype.initialize.call(this, {"canBeAppendedBeforeLoad": true});
    },
    
    load : function(){
        if(this._capsuleModel){
            this._initView();
            return RSVP.Promise.resolve();
        }else{
            return this._loadFunction(this._capsuleId).then((function(capsuleModel){
                if(capsuleModel){
                    this._capsuleModel = capsuleModel;
                    this._initialLifeSpan =  this._capsuleModel.get("numberOfDaysToLive");
                    this._initialCategoryId =  this._capsuleModel.get("categoryId");
                    this._initView();
                }else{
                    console.error("Capsule details couldn't be retrieved.");
                    throw new Error(window.getI18n(ti18n, "CAPSULE_DETAILS_COULDNT_BE_RETRIEVED"));
                }
                
            }).bind(this));
        }
    },
    
    _initView : function(){
        if(!this._initialized){
            const capsuleJson = this._capsuleModel? this._capsuleModel.toJSON(): {};
            
            this.$el.html(this.template({"model": capsuleJson, "showActiveFlag" : true, "isBeingProcessed": this._capsuleModel.isBeingProcessed(), "ti18n": ti18n}));
            this.$el.find("#titleField").val(capsuleJson.title);
            this._addCapsuleCategoriesToView();
            this.$el.enhanceWithin();
            this._addArrowToSelectWidget();
            this.$el.find("#playCapsuleBtn").on("click", this.playPauseCapsule.bind(this))
            this._initialized = true;
        }
    },

     _addCapsuleCategoriesToView : function(){
        const selectCategory$El =  this.$el.find("#categoryFld");

        if(!this._capsuleCategoriesAdded && this._capsuleCategoryCollection){
            this._capsuleCategoriesAdded = true;
            this._capsuleCategoryCollection.each((function(categoryListItemModel){
                if(this._capsuleModel && this._capsuleModel.get("categoryId") == categoryListItemModel.getId()){
                    selectCategory$El.append("<option value='"+categoryListItemModel.getId()+"' selected='selected'>"+ categoryListItemModel.getLabel()+"</option>");
                }else{
                    selectCategory$El.append("<option value='"+categoryListItemModel.getId()+"'>"+ categoryListItemModel.getLabel()+"</option>");
                }
            }).bind(this));
        }
    },

    onBeforePageInitialized : function(){
        this._addCapsuleCategoriesToView();
        this._addArrowToSelectWidget();
    },
    
    getEditableCapsuleInfo : function(){
        const capsuleInfo = {};
        let newLifeSpanValue = this.$el.find("#capsuleLifeSpanFld option:selected").val();
        newLifeSpanValue = newLifeSpanValue == ""?null: newLifeSpanValue;
        let newCategoryFld = this.$el.find("#categoryFld option:selected").val();
        newCategoryFld = newCategoryFld == ""?null: newCategoryFld;
        capsuleInfo.capsuleId = this._capsuleModel.getCapsuleId();
        capsuleInfo.notes = this.$el.find("#comment").val();
        capsuleInfo.title = this.$el.find("#titleField").val();
        capsuleInfo.private = this.$el.find("#privacySettingFld").val() == "private";
        capsuleInfo.numberOfDaysToLive =newLifeSpanValue;
        capsuleInfo.lifeSpanChanged = false;
        capsuleInfo.categoryId = newCategoryFld;
        capsuleInfo.isActive =  this._capsuleModel.isActive();
        if(newLifeSpanValue != this._initialLifeSpan){
            capsuleInfo.lifeSpanChanged = true;
        }
        if( newCategoryFld != this._initialCategoryId){
            capsuleInfo.updateCategoryId = true;
        }

        return capsuleInfo;
    },
      _addArrowToSelectWidget: function() {
          //TOOD: the following line is a workaround  to fix but with "select" tag in template not working properly
          //	this.$el.find(".expirationInfo * a" ).append(this.$el.find("select"));
         // this.$el.find(".expirationInfo * select" ).selectmenu();
            this.$el.find("#capsuleLifeSpanFld-button").append('<i class="ion-chevron-down" style="color: #bbb;display: inline-block;"></i>');
         //   this.$el.find(".categoryConfigWrapper * a" ).append(this.$el.find("select"));
         //this.$el.find(".categoryConfigWrapper * select" ).selectmenu();
            this.$el.find("#categoryFld-button").append('<i class="ion-chevron-down" style="color: #bbb;display: inline-block;"></i>');
            this.$el.find("#privacySettingFld-button").append('<i class="ion-chevron-down" style="color: #bbb;display: inline-block;"></i>');
        },
    

        events : {
            
            
        },
        
        setIsPlaying : function(isPlaying){
            this._isPlaying = isPlaying;
            const playCapsuleBtn  =this.$el.find("#playCapsuleBtn");
            if(this._isPlaying){
                //playCapsuleBtn.removeClass("fontello-icon-play-1");
                playCapsuleBtn.removeClass("ion-ios7-play");
                playCapsuleBtn.addClass("fontello-icon-stop");
                playCapsuleBtn.html("Stop Capsule");
            }else{
                playCapsuleBtn.removeClass("fontello-icon-stop");
                //playCapsuleBtn.addClass("fontello-icon-play-1");
                playCapsuleBtn.addClass("ion-ios7-play");
                playCapsuleBtn.html("Play Capsule");
            }
        },

        playPauseCapsule : function(){
            if(this._isPlaying){
                this.trigger("stopPlaying");
                this.setIsPlaying(false);
            }else{
                this.trigger("startPlaying");
                this.setIsPlaying(true);
            }
        },
    
    render : function() {
        const capsuleJson = this._capsuleModel? this._capsuleModel.toJSON(): null;
        return this;
    }

});

export default CapsuleEditContentView;