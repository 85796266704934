var amdi18n={"__root":{"RETRIEVING_JOURNEY_INFORMATION":"Retrieving Journey Information...","WATITING_FOR_YOUR_ORDER":"Waiting for you to complete your order...","THANKS_FOR_PURCHASING":"Thanks for purchasing","PLEASE_ALLOW_POPUPS":"Please allow popups","AN_ERROR_OCCURED_WHEN_TRYING_TO_ORDER":"An error occured when we were trying to order","WE_COULDNT_FIND_THE_SPECIFIED_JOURNEY":"We couldn't find the specifed journey.","THE_ORDER_FOR":"The order for","WAS_NOT_COMPLETED":"was not completed","WE_WISH_YOU_A_HAPPY_LISTENING":"We wish you a happy listening!","COMPLETING_YOUR_ORDER":"Completing your order..."},"__fr":{"RETRIEVING_JOURNEY_INFORMATION":"Chargement des informations de parcours...","WATITING_FOR_YOUR_ORDER":"En attente de la finalisation de votre commande...","THANKS_FOR_PURCHASING":"Merci d'avoir acheté","PLEASE_ALLOW_POPUPS":"Veuillez autoriser les popups","AN_ERROR_OCCURED_WHEN_TRYING_TO_ORDER":"Une erreur s'est produite lors de la tentative de commande","WE_COULDNT_FIND_THE_SPECIFIED_JOURNEY":"Nous n'avons pas pu trouver le parcours spécifié","THE_ORDER_FOR":"La commande pour","WAS_NOT_COMPLETED":"n'a pas été finalisée","WE_WISH_YOU_A_HAPPY_LISTENING":"Nous vous souhaitons une bonne écoute!","COMPLETING_YOUR_ORDER":"Finalisation de votre commande..."},"__en":{"RETRIEVING_JOURNEY_INFORMATION":"Retrieving Journey Information...","WATITING_FOR_YOUR_ORDER":"Waiting for you to complete your order...","THANKS_FOR_PURCHASING":"Thanks for purchasing","PLEASE_ALLOW_POPUPS":"Please allow popups","AN_ERROR_OCCURED_WHEN_TRYING_TO_ORDER":"An error occured when we were trying to order","WE_COULDNT_FIND_THE_SPECIFIED_JOURNEY":"We couldn't find the specifed journey.","THE_ORDER_FOR":"The order for","WAS_NOT_COMPLETED":"was not completed","WE_WISH_YOU_A_HAPPY_LISTENING":"We wish you a happy listening!","COMPLETING_YOUR_ORDER":"Completing your order..."}};amdi18n.init=function(language){
		// get the default language
		if(!language){
			if(typeof window !== 'undefined' && window._i18n && window._i18n.locale){
				language = window._i18n.locale;
			}else if(typeof document !== 'undefined' && document.documentElement.lang){
				language = document.documentElement.lang;
			}else if(typeof navigator !== 'undefined' && (navigator.languages || navigator.language || navigator.userLanguage)){
				// navigator.languages does not exist in IE 11
				language = (navigator.languages && navigator.languages[0]) ||
					navigator.language ||
					navigator.userLanguage;
				language = (language || 'root').toLowerCase();
			}else{
				language = 'root';
			}
		}
		var target = this['__' + language] || this.__root;

		// copy definition to root level
		if (target) {
			for (var name in target) {
				this[name] = target[name];
			}
		}

		// fallback to root
		var recursiveFallback = function(target, source){
			for(var name in source){
				switch(typeof target[name]){
				case 'undefined':
					target[name] = source[name];
					break;
				case 'object':
					recursiveFallback(target[name], source[name]);
				}
			}
		};
		recursiveFallback(this, this.__root);
	};amdi18n.init();module.exports=amdi18n;