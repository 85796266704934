var amdi18n={"__root":{"SEARCH":"Search","CLEAR_SEARCH":"Clear Search","FIND_SONGS_ALBUMS_ARTISTS_USERS":"Find Songs, Albums, Artist, Users or Channels.","FIND_USERS_TREBBLES":"Find channels or users."},"__fr":{"SEARCH":"Recherche","CLEAR_SEARCH":"Effacer la recherche","FIND_SONGS_ALBUMS_ARTISTS_USERS":"Trouver des chansons, albums, artistes, utilisateurs ou chaînes.","FIND_USERS_TREBBLES":"Trouvez des chaînes ou des utilisateurs."},"__en":{"SEARCH":"Search","CLEAR_SEARCH":"Clear Search","FIND_SONGS_ALBUMS_ARTISTS_USERS":"Find Songs, Albums, Artist, Users or Channels.","FIND_USERS_TREBBLES":"Find channels or users."}};amdi18n.init=function(language){
		// get the default language
		if(!language){
			if(typeof window !== 'undefined' && window._i18n && window._i18n.locale){
				language = window._i18n.locale;
			}else if(typeof document !== 'undefined' && document.documentElement.lang){
				language = document.documentElement.lang;
			}else if(typeof navigator !== 'undefined' && (navigator.languages || navigator.language || navigator.userLanguage)){
				// navigator.languages does not exist in IE 11
				language = (navigator.languages && navigator.languages[0]) ||
					navigator.language ||
					navigator.userLanguage;
				language = (language || 'root').toLowerCase();
			}else{
				language = 'root';
			}
		}
		var target = this['__' + language] || this.__root;

		// copy definition to root level
		if (target) {
			for (var name in target) {
				this[name] = target[name];
			}
		}

		// fallback to root
		var recursiveFallback = function(target, source){
			for(var name in source){
				switch(typeof target[name]){
				case 'undefined':
					target[name] = source[name];
					break;
				case 'object':
					recursiveFallback(target[name], source[name]);
				}
			}
		};
		recursiveFallback(this, this.__root);
	};amdi18n.init();module.exports=amdi18n;