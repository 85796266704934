import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import ArtistDetailPageView from "views/artistDetail/ArtistDetailPageView";
import PersistentModels from "services/PersistentModels";
import PlayerService from "services/PlayerService";
import ti18n from "i18n!nls/ArtistDetailsi18n";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const ArtistDetailController = Chaplin.Controller.extend({


    initialize : function(options) {
        _.extend(this, Backbone.Events);
        if (options) {
            if (options.view) {
                this.view = options.view;
            }
            if (options.model) {
                this.model = options.model;
            }
            this.pageId = options.pageId;
        }
        
        
    },
    
    getModel : function(){
        return this.model;
    },
    
    getView : function(){
        return this.view;
    },
    
    showAlbumDetailsPage : function(albumModel){
        PersistentModels.getInstance().getRouter().showAlbumDetailPage(albumModel.get("albumId") , albumModel, true);
    },
    
    
    
    addSongToQueue : function(albumModel){
        const songs = albumModel.get("songs");
        const remoteAndLocalSongs = [];
        if(songs)
        {
            for(let songIndex in songs ){
                let songJson =  songs[songIndex];
                if(songJson)
                {	
                    let songFound = PersistentModels.getInstance().getSongModelBySongIdFromMyLibraryOrFavorites( songJson.uri);
                    if(!songFound)
                    {
                        songFound = Utils.getInstance().getModelFromSongJson(songJson);
                    }
                    if(songFound){
                        remoteAndLocalSongs.push(songFound);
                    }
                }
                
            }
        }
        PlayerService.getInstance().addSongsToQueue(remoteAndLocalSongs);
    },
    
    showMenuOption : function(albumModel){
        PersistentModels.getInstance().getRouter().showAlbumOptionPopup(albumModel);
    },
    
    showArtistMenuOptionPopup : function(artistModel){
        PersistentModels.getInstance().getRouter().showArtistOptionPopup(artistModel);
    },
    
    shuffleAllSongFromArtist : function(artistModel){
        const songUris = [];
        const albums = artistModel.get("albums");
        for(let albumIndex in albums){
            const albumModel = albums[albumIndex];
            for(let songIndex in albumModel.songs){
                const songJson =  albumModel.songs[songIndex];
                songUris.push(songJson.uri);
                
            }
        }
        const arrayOfAlbumSongs = PersistentModels.getInstance().getSongCollection().getSongsWithURI(songUris);
        const shuffleArrayOfAlbumSongs =  _.shuffle(arrayOfAlbumSongs);
        let playlistName = window.getI18n(ti18n, "ALL_MY_MUSIC_FROM");
        if(this.model && this.model.get("artistName"))
        {
            playlistName = playlistName + " " + this.model.get("artistName");
        }else{
            playlistName = playlistName + " artist";
        }
        const isMusicCurrentlyPlaying =  PlayerService.getInstance().isMusicPlaying();
        const context =  Utils.getInstance().buildContextForSongCollectionArtist(this.model.get("artistId"));
        PlayerService.getInstance().clearQueueAndSongToQueue(shuffleArrayOfAlbumSongs , playlistName, context);
        if(!isMusicCurrentlyPlaying)
        {	
            PlayerService.getInstance().playFirstSongInQueue();
        }
    },
    
    
    filterList : function(filters){
        window.alertErrorMessage(window.getI18n(ti18n, "COMING_SOON"));
    },
    
    onViewRemoved:function(){
        this.stopListening();
    },


    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve,reject){
            try{
                
                
                this.view = new ArtistDetailPageView({
                    "model" : this.model,
                    "pageId": this.pageId,
                });
                this.listenTo(this.view, "itemTaped",this.showAlbumDetailsPage);
                this.listenTo(this.view, "itemSwipedRight",this.addSongToQueue);
                this.listenTo(this.view, "itemOptionBtnTapped",this.showMenuOption);
                this.listenTo(this.view, "shuffleAllSongFromArtist", this.shuffleAllSongFromArtist);
                this.listenTo(this.view, "showArtistMenuOptionPopup", this.showArtistMenuOptionPopup);
                this.listenTo(this.view, "filter", this.filterList);
                this.listenTo(this.view, "remove", this.onViewRemoved);
                this.view.render();
                resolve();
            }catch(error){
                reject(error);
            }
            
        }).bind(this));

        
    }

});
export default ArtistDetailController;