
import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import AudioFilter from "models/audioEditor/filters/AudioFilter";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import RSVP from "rsvp";

const PRESETS ={};

const PRESET_IDS = {
    "TWO_STEP_VOICE_COMPRESSION_PART_1":"2stepVoiceCompressionPart1",
    "TWO_STEP_VOICE_COMPRESSION_PART_2":"2stepVoiceCompressionPart2",
};


const Rollout = RolloutHelper.getInstance();
const CACHING_AUDIO_NODE_ON_CHROME = Rollout.getFeatureVariable(Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR, Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR.variables.apply_chrome_workaround_by_resuing_audio_worklet_processor, true);
const USE_SCRIPT_PROCESSOR_IN_SAFARI = Rollout.getFeatureVariable(Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR, Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR.variables.apply_safari_workaround_for_audioworklet_incomplete_impl, true);

const PARAMS ={};
PARAMS.ATTACK = "/COMPRESSOR/0x00/Compression_Response/Attack";
PARAMS.RELEASE = "/COMPRESSOR/0x00/Compression_Response/Release";
PARAMS.RATIO = "/COMPRESSOR/0x00/Compression_Control/Ratio";
PARAMS.THRESHOLD = "/COMPRESSOR/0x00/Compression_Control/Threshold";
PARAMS.GAIN = "/COMPRESSOR/Makeup_Gain";
PARAMS.PRE_GAIN = "PRE_GAIN";


const createCompressorWithGainConfigPreset =function(preGain, threshold, ratio, attack, release, gainInDb){
    const presetParams = {};
    presetParams[PARAMS.THRESHOLD] = threshold;
    presetParams[PARAMS.RATIO] = ratio;
    presetParams[PARAMS.ATTACK] = attack;
    presetParams[PARAMS.RELEASE] = release;
    presetParams[PARAMS.GAIN] = gainInDb;
    presetParams[PARAMS.PRE_GAIN] = preGain;
    return presetParams;
};


PRESETS[PRESET_IDS.TWO_STEP_VOICE_COMPRESSION_PART_1] = createCompressorWithGainConfigPreset(1, -18, 4, 0.02, 0.250, 0);
PRESETS[PRESET_IDS.TWO_STEP_VOICE_COMPRESSION_PART_2] = createCompressorWithGainConfigPreset(1, -22, 2.1, 0.204, 0.738, 3.9);

const CLASSNAME = "CompressorWithGainFilter";
const CompressorWithGainFilter =  AudioFilter.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        AudioFilter.apply(this, [attributes, options]);
        this.setPresetIdToConfigMap(PRESETS);
        this.set("type",CompressorWithGainFilter.CLASSNAME);
        
        
    },
    
    setAttack : function(attack){
        this.set(PARAMS.ATTACK,attack);
    },

    getAttack : function(){
        return this.get(PARAMS.ATTACK);
    },

    setThreshold : function(threshold){
        this.set(PARAMS.THRESHOLD,threshold);
    },

    getThreshold : function(){
        return this.get(PARAMS.THRESHOLD);
    },

    setRelease : function(release){
        this.set(PARAMS.RELEASE,release);
    },

    getRelease : function(){
        return this.get(PARAMS.RELEASE);
    },

    setRatio : function(ratio){
        this.set(PARAMS.RATIO,ratio);
    },

    getRatio : function(){
        return this.get(PARAMS.RATIO);
    },

    setGain : function(gain){
        this.set(PARAMS.GAIN,gain);
    },

    getGain : function(){
        return this.get(PARAMS.GAIN);
    },

    setPreGain : function(preGain){
        this.set(PARAMS.PRE_GAIN,preGain);
    },

    getPreGain : function(){
        return this.get(PARAMS.PRE_GAIN);
    },


    isEqual : function(filter){
        if(filter instanceof CompressorWithGainFilter){
            return (this.isDisabled() == filter.isDisabled() && this.getAttack() === filter.getAttack() && this.getThreshold() === filter.getThreshold() && this.getRelease() === filter.getRelease() && this.getRatio() === filter.getRatio() && this.getGain() === filter.getGain() && this.getPreGain() === filter.getPreGain() ) 
        }
        return false;
    },

    clone : function(){
        const cloneFilter = new CompressorWithGainFilter();
        cloneFilter.setDisabled(this.isDisabled());
        cloneFilter.setThreshold(this.getThreshold());
        cloneFilter.setRelease(this.getRelease());
        cloneFilter.setRatio(this.getRatio());
        cloneFilter.setGain(this.getGain());
        cloneFilter.setPreGain(this.getPreGain());

        return cloneFilter;
    },

    needToPrepareAudioContext: function(){
        return true;
    },

    prepareAudioContext: function(audioContext){
        return RSVP.Promise.resolve(audioContext);
    },

    _applyParameterOnNode:function(compressorWithGainNode,paramName){
        compressorWithGainNode.setParamValue(paramName,this.get(paramName));
    },


    applyFilterToNode : function(audioNode, audioContext, arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime, sequencerRendererTracksInfo, createdFilterNode  , sourceNodeToMonitor){
        if(!this.isDisabled()){
            
            // Create a compressor node
            const compressorWithGainNode = createdFilterNode;
            this._applyParameterOnNode(compressorWithGainNode, PARAMS.RATIO);
            this._applyParameterOnNode(compressorWithGainNode, PARAMS.ATTACK);
            this._applyParameterOnNode(compressorWithGainNode, PARAMS.RELEASE);
            this._applyParameterOnNode(compressorWithGainNode, PARAMS.THRESHOLD);
            this._applyParameterOnNode(compressorWithGainNode, PARAMS.GAIN);

            


            if(this.getPreGain() && this.getPreGain() !== 1 ){
                const preGainNode =  audioContext.createGain();
                preGainNode.gain.value = this.getPreGain(); 
                audioNode.connect(preGainNode);
                preGainNode.connect(compressorWithGainNode);
                if(arrayOfAudioNodesUsed){
                    arrayOfAudioNodesUsed.push(preGainNode);
                }
            }else{
                audioNode.connect(compressorWithGainNode);
            }

            if(arrayOfAudioNodesUsed){
                arrayOfAudioNodesUsed.push(compressorWithGainNode);

            }

            return compressorWithGainNode;

        }else{
            return audioNode;
        }
    },

    _createCompressorWithGainNode : function(audioContext){
        const presetId = this.getPreset();
        return new RSVP.Promise(function(resolve, reject){
            require(["libs/compressorWithGain/CompressorWithGain","libs/compressorWithGain/CompressorWithGainScriptProcessor"],function({CompressorWithGain}, {CompressorWithGainScriptProcessor}){
                if((window.isSafariBrowser || window.isIOSMobileDevice) && USE_SCRIPT_PROCESSOR_IN_SAFARI){
                    return CompressorWithGainScriptProcessor.createDSP(audioContext, 1024, "js/libs/compressorWithGain").then(resolve).catch(reject);
                }else{
                    const CompressorWithGainFactory = new CompressorWithGain(audioContext,"js/libs/compressorWithGain")
                    if(window.chrome && CACHING_AUDIO_NODE_ON_CHROME){
                        if(presetId  === PRESET_IDS.TWO_STEP_VOICE_COMPRESSION_PART_1 && audioContext._lastCompressorWithGainNodeCreatedOne && !audioContext._lastCompressorWithGainNodeCreatedOne.isConnected){
                            return resolve(audioContext._lastCompressorWithGainNodeCreatedOne);
                        }
                        if(presetId  === PRESET_IDS.TWO_STEP_VOICE_COMPRESSION_PART_2 && audioContext._lastCompressorWithGainNodeCreatedTwo && !audioContext._lastCompressorWithGainNodeCreatedTwo.isConnected){
                            return resolve(audioContext._lastCompressorWithGainNodeCreatedTwo);
                        }
                        
                    }
                    return CompressorWithGainFactory.load().then(resolve).catch(reject);	
                }
            })

        })
    },

    applyFilter : function(previousOutputNode, audioContext, arrayOfAudioNodesUsed, audioBufferStartTime, audioBufferEndTime,  sequencerRendererTracksInfo,audioBuffer){
        if(!this.isDisabled()){
            if(!previousOutputNode){
                previousOutputNode = audioContext.createBufferSource();
                previousOutputNode.buffer = audioBuffer;
                if(arrayOfAudioNodesUsed){
                    arrayOfAudioNodesUsed.push(previousOutputNode);
                }
            }
            return this._createCompressorWithGainNode(audioContext).then((function(compressorWithGainNode){
                if(window.chrome && CACHING_AUDIO_NODE_ON_CHROME && compressorWithGainNode){
                    if(this.getPreset()  === PRESET_IDS.TWO_STEP_VOICE_COMPRESSION_PART_1){
                        audioContext._lastCompressorWithGainNodeCreatedOne = compressorWithGainNode;
                    }
                    if(this.getPreset()  === PRESET_IDS.TWO_STEP_VOICE_COMPRESSION_PART_2){
                        audioContext._lastCompressorWithGainNodeCreatedTwo = compressorWithGainNode;
                    }
                    compressorWithGainNode.isConnected = true;
                }
                return this.applyFilterToNode(previousOutputNode, audioContext,arrayOfAudioNodesUsed, audioBufferStartTime,audioBufferEndTime, sequencerRendererTracksInfo, compressorWithGainNode);
            }).bind(this));


        }else{
            return RSVP.Promise.resolve(previousOutputNode);
        }
    },


});

CompressorWithGainFilter.PRESET_IDS = PRESET_IDS;
CompressorWithGainFilter.PRESETS = PRESETS;
CompressorWithGainFilter.CLASSNAME =  CLASSNAME;

export default CompressorWithGainFilter; 