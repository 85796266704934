import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import TrebbleAudio from "models/TrebbleAudio";
import EpidemicSoundHelper from "models/helper/EpidemicSoundHelper";
import RSVP from "rsvp";


const TrebbleAudioFactory =  Backbone.Model.extend({
    idAttribute: "id",

    createTrebbleAudioFromSerializedData : function(serializedData){
        if(serializedData.audioType === "epidemicSound"){
            return EpidemicSoundHelper.getInstance().createTrebbleAudioFromSerializedData(serializedData);
        }else{
            return new TrebbleAudio(serializedData);
        }
    },




});


const trebbleAudioFactoryInstance = new TrebbleAudioFactory();

export default {
    getInstance : function() {
        return trebbleAudioFactoryInstance;
    }
}; 