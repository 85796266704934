		import $ from 'jquery';
        import _ from 'underscore';
        import Backbone from "backbone";
        import MixpanelJS from "mixpanel";
        import LocalStorageHelper from "models/helper/LocalStorageHelper";
        import RSVP from "rsvp";

        const MIXPANEL_API_KEY = "6d00748f1fa9935eb56b7b2ed66f4b53";

        const MixpanelHelper = Backbone.Model.extend({
            initialize : function(options) {
                

            },

            init : function(){
                if(window.waitForCordovaToLoad){
                    if("mixpanel" in window){
                        window.mixpanel.init(MIXPANEL_API_KEY,function(){},function(e){console.error(e)});
                    }
                }else{
                    MixpanelJS.init(MIXPANEL_API_KEY, {api_host: "https://mpproxy.trebble.fm"});
                    this._parseCampaignParamsIfApplicable();
                    this._setCampaignParams();
                }
            },

            _getQueryParam: function(url, param) {
                param = param.replace(/\[/g, "\\[").replace(/\]/g, "\\]");
                const regexS = "[\?&]" + param + "=([^&#]*)",
                regex = new RegExp( regexS ),
                results = regex.exec(url);
                if (results === null || (results && typeof(results[1]) !== 'string' && results[1].length)) {
                    return '';
                } else {
                    return decodeURIComponent(results[1]).replace(/\W/gi, ' ');
                }
            },

            _parseCampaignParamsIfApplicable : function(){
                try{
                    let campaign_keywords = 'utm_source utm_medium utm_campaign utm_content utm_term _campaignname utm_creative utm_device utm_placement gclid rdt_cid'.split(' ')
                    , kw = ''
                    , params = {}
                    , first_params = {};
                    let index;
                    for (index = 0; index < campaign_keywords.length; ++index) {
                        kw = this._getQueryParam(document.URL, campaign_keywords[index]);
                        if (kw.length) {
                            params[campaign_keywords[index] + ' [last touch]'] = kw;
                        }
                    }
                    for (index = 0; index < campaign_keywords.length; ++index) {
                        kw = this._getQueryParam(document.URL, campaign_keywords[index]);
                        if (kw.length) {
                            first_params[campaign_keywords[index] + ' [first touch]'] = kw;
                        }
                    }
                    this._campaignParams = params;
                    this._campaignFirstParams = first_params;
                }catch(error){
                    console.error("Failed setting up campaign params for Mixpanel.Error:"+error);
                }
            },


            _setCampaignParams : function(){
                try{
                    if(this._campaignParams){
                        MixpanelJS.people.set(this._campaignParams);
                        MixpanelJS.people.set_once(this._campaignFirstParams);
                        MixpanelJS.register(this._campaignParams);
                    }
                }catch(error){
                    console.error("Failed setting up campaign params for Mixpanel.Error:"+error);
                }
            },

            "people" : {
                set : function(peopleProperties){
                    if(window.waitForCordovaToLoad){
                        if("mixpanel" in window){
                            window.mixpanel.people.set(peopleProperties, function(){},function(e){console.error(e)});
                        }
                    }else{
                        MixpanelJS.people.set(peopleProperties);
                    }
                },

                increment : function(peopleProperties){
                    if(window.waitForCordovaToLoad){
                        if("mixpanel" in window){
                            window.mixpanel.people.increment(peopleProperties, function(){},function(e){console.error(e)});
                        }
                    }else{
                        MixpanelJS.people.increment(peopleProperties);
                    }
                },

                setPushId : function(registrationId){
                    if(window.waitForCordovaToLoad){
                        if("mixpanel" in window){
                            window.mixpanel.people.setPushId(registrationId, function(){},function(e){console.error(e)});
                        }
                    }
                }
            },


            identify : function(userId){
                if(window.waitForCordovaToLoad){
                    if("mixpanel" in window){
                        window.mixpanel.identify(userId,true, function(){},function(e){console.error(e)});
                    }
                }else{
                    MixpanelJS.identify(userId);
                }
            },

            registerSuperProperties: function(superProperties){
                if(window.waitForCordovaToLoad){
                    if("mixpanel" in window){
                        window.mixpanel.registerSuperProperties(superProperties, function(){},function(e){console.error(e)});
                    }
                }else{
                    MixpanelJS.register(superProperties);
                }

            },

            track : function(eventName, eventProperties){
                if(window.waitForCordovaToLoad){
                    if("mixpanel" in window){
                        window.mixpanel.track(eventName, eventProperties, function(){},function(e){console.error(e)});
                    }
                }else{
                    MixpanelJS.track(eventName, eventProperties);
                }
            },

            reset: function(){
                if(window.waitForCordovaToLoad){
                    if("mixpanel" in window){
                        window.mixpanel.reset( function(){},function(e){console.error(e)});
                    }
                }else{
                    MixpanelJS.reset();
                    this._setCampaignParams();
                }
            }

        });

        const MixpanelHelperInstance = new MixpanelHelper();

        export default {
            getInstance : function() {
                return MixpanelHelperInstance;
            }
        };