import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import Levenshtein from "libs/levenshtein/levenshtein";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import TrebbleAudioCompilation from "models/TrebbleAudioCompilation";
import ti18n from "i18n!nls/helperi18n";
import RSVP from "rsvp";


const JOURNEY_PREFIX = "journey:";
const JourneySearchHelper =  Backbone.Model.extend({

    initialize : function() {

    },


    searchForDraftJourneys : function(query, pageIndex, numberOfResultsPerPage){
        return TrebbleClientAPIHelper.getInstance().searchForMyDraftJourneys(query, numberOfResultsPerPage, pageIndex).then((function(journeyJsonArray){
            const journeySummaryModelArray = [];
            if (journeyJsonArray) {
                for (let i = 0; i < journeyJsonArray.length; i++) {
                    journeySummaryModelArray.push(Utils.getInstance().buildJourneySummaryFromJson(journeyJsonArray[i]));
                }
            }
            return journeySummaryModelArray;
        }).bind(this))
    },


    searchForDraftJourneysAndConvertToTrebbleAudioCompilation : function(query, pageIndex, numberOfResultsPerPage){

        return this.searchForDraftJourneys(query, pageIndex, numberOfResultsPerPage).then((function(journeyModelArray){
            return journeyModelArray.map(function(journeyModel){
                return new TrebbleAudioCompilation({
                    "audioId": JOURNEY_PREFIX+ journeyModel.getLoaderKey(),
                    "title": journeyModel.getName(),
                    "description": journeyModel.getDescription(),
                    "creationDate": null,
                    "audioUrl": null,
                    "imageArtUrl": journeyModel.getCoverArtUrl(),
                    "modelSourceReference":journeyModel
                })
            })
        }).bind(this))

    },


});
const journeySearchHelperInstance = new JourneySearchHelper();

export default {
	getInstance : function() {
		return journeySearchHelperInstance;
	}
};
