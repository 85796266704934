import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";



const SequencerNodeCloner =  Backbone.Model.extend({
    idAttribute: "id",


    

    cloneLinkedSequencerNodes : function(sequencerNodeToStartCloningAt, sequencerNodeToStopCloningAt, sequencerNodeCidToCloneNodeMap){
        if(!sequencerNodeCidToCloneNodeMap){
            sequencerNodeCidToCloneNodeMap = {}
        }
        const firstNodeCid = sequencerNodeToStartCloningAt.cid;
        let sequencerNodeToClone = sequencerNodeToStartCloningAt;
        let lastSequencerNodeToClone = null;
        const nodeCidToOriginalSequencerNode = {};
        while(sequencerNodeToClone){
            if(sequencerNodeToClone === sequencerNodeToStopCloningAt || !sequencerNodeToClone.getNext()){
                lastSequencerNodeToClone = sequencerNodeToClone;
                sequencerNodeToClone = null;
            }else{
                sequencerNodeToClone = sequencerNodeToClone.getNext();
            }
        }
        sequencerNodeToClone = lastSequencerNodeToClone;
        const nodeCidCloned = [];
        while(sequencerNodeToClone){
            const existingClone  = sequencerNodeCidToCloneNodeMap[sequencerNodeToClone.cid];
            if(!existingClone){
                const contructorClass = sequencerNodeToClone.getConstructor();
                const newClone =  new contructorClass(sequencerNodeToClone.cloneJSON(),null, true, sequencerNodeToClone, sequencerNodeCidToCloneNodeMap);
                nodeCidToOriginalSequencerNode[sequencerNodeToClone.cid] = sequencerNodeToClone;
                sequencerNodeCidToCloneNodeMap[sequencerNodeToClone.cid] = newClone;
                nodeCidCloned.push(sequencerNodeToClone.cid);
            }
            if(sequencerNodeToClone !== sequencerNodeToStartCloningAt){
                sequencerNodeToClone = sequencerNodeToClone.getPrevious();
            }else{
                sequencerNodeToClone = null;
            }
        }
        for(let nodeCid in nodeCidToOriginalSequencerNode){
            const originalSequencerNode = nodeCidToOriginalSequencerNode[nodeCid];
            const clonedSequencerNode = sequencerNodeCidToCloneNodeMap[nodeCid];
            originalSequencerNode.afterInstanceCreatedFromConstructorForCloning(clonedSequencerNode, sequencerNodeCidToCloneNodeMap);
        }
        let nodeCidToLink = firstNodeCid;
        while(nodeCidToLink){
            let nextNodeCidToLink = nodeCidToOriginalSequencerNode[nodeCidToLink].getNext()? nodeCidToOriginalSequencerNode[nodeCidToLink].getNext().cid: null;
            const sequencerNodeToLinkWith = sequencerNodeCidToCloneNodeMap[nodeCidToLink];
            if(nextNodeCidToLink){
                const nextNodeToLinkWith = sequencerNodeCidToCloneNodeMap[nextNodeCidToLink];
                if(nextNodeToLinkWith){
                    sequencerNodeToLinkWith.insertBefore(nextNodeToLinkWith);
                }else{
                    nextNodeCidToLink = null;
                }
            }
            nodeCidToLink =  nextNodeCidToLink;
            if(sequencerNodeToStopCloningAt && nodeCidToLink === sequencerNodeToStopCloningAt.cid){
                nodeCidToLink = null;	
            }
        }
        const firstSequenerNodeClone = sequencerNodeCidToCloneNodeMap[firstNodeCid];

        return firstSequenerNodeClone;

    },

    


});


const sequencerNodeClonerInstance = new SequencerNodeCloner();

export default {
    getInstance : function() {
        return sequencerNodeClonerInstance;
    }
}; 