
import _ from 'underscore';
import ReplaceSelectedNodesOperation from "models/audioEditor/sequencerOperation/ReplaceSelectedNodesOperation";
import TrebbleVideo from "models/TrebbleVideo";


const InsertAudioOrVideoNodesInBulkOperation =  ReplaceSelectedNodesOperation.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        ReplaceSelectedNodesOperation.apply(this, [attributes, options]);
        this._insertAudioNodeOperation =  attributes.insertAudioNodeOperation;
        this._insertVideoNodeOperation =  attributes.insertVideoNodeOperation;
    },

  
    execute : async function(sequencerNodeTargetToInsertBefore, insertBefore, audioOrVideoModelsToInsert){
       for(let i =0; i < audioOrVideoModelsToInsert.length; i++){
        const audioOrVideoModel = audioOrVideoModelsToInsert[i];
        const introFadeInMilliseconds = 0;
        const outroFadeinMilliseconds = 0;
        const introPaddingInMilliseconds = 0;
        const outroPaddingInMilliseconds = 0;
        const volume = 0.5;
        const doNotLog =  true;
        const arrayOfAudioOrVideoSequencerNodesAdded = []
        if(audioOrVideoModel instanceof TrebbleVideo){
            arrayOfAudioOrVideoSequencerNodesAdded.push(this._insertVideoNodeOperation.execute(audioOrVideoModel, sequencerNodeTargetToInsertBefore, insertBefore, introPaddingInMilliseconds, outroPaddingInMilliseconds,introFadeInMilliseconds,outroFadeinMilliseconds, volume, doNotLog));
        }else{
            arrayOfAudioOrVideoSequencerNodesAdded.push(this._insertAudioNodeOperation.execute(audioOrVideoModel, sequencerNodeTargetToInsertBefore, insertBefore, introPaddingInMilliseconds, outroPaddingInMilliseconds,introFadeInMilliseconds,outroFadeinMilliseconds, volume, doNotLog));
        }
        this.getSequencer().getHistoryManagement().addInsertAudioOrVideoNodesInBulk(arrayOfAudioOrVideoSequencerNodesAdded, sequencerNodeTargetToInsertBefore , insertBefore);
       }
    },

    
    undo : function(sequencerOperationInsertAudioOrVideoNodesInBulkLog ){
        const audioOrVideoSequencerNodeArray = sequencerOperationInsertAudioOrVideoNodesInBulkLog.getAudioOrVideoSequencerNodeArray();
        this.removeAudioNodeArray(audioOrVideoSequencerNodeArray);
    },

    redo: function(sequencerOperationInsertAudioOrVideoNodesInBulkLog){
        const audioOrVideoSequencerNodeArray = sequencerOperationInsertAudioOrVideoNodesInBulkLog.getAudioOrVideoSequencerNodeArray();
        const sequencerNodeToInsertNextTo = sequencerOperationInsertAudioOrVideoNodesInBulkLog.getSequencerNodeToInsertNextTo();
        const insertBefore = sequencerOperationInsertAudioOrVideoNodesInBulkLog.getInsertBefore();
        this.insertAudioNodeArray(audioOrVideoSequencerNodeArray, sequencerNodeToInsertNextTo, insertBefore);
    },

});


export default InsertAudioOrVideoNodesInBulkOperation; 