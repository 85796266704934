import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";

/*"models/Song",
/* "collections/Playlist",
"collections/FixedPlaylist",
"collections/AutoFixedPlaylist",
"collections/DynamicPlaylist",
"collections/SocialDynamicPlaylist",*/import RSVP from "rsvp";

const PlaylistSummary =  Backbone.Model.extend({
     idAttribute: "id",

     
     getLoaderKey : function(){
            return this.get("loaderKey");
        },
        
    
        
        getName : function(){
            return this.get("name");
        },
        
        
        getCreator : function(){
            return this.get("author");
        },
        
        
        getSummary : function(){
            return this.get("artistSample");
        },
        
        
        getlastUpdateDateString : function(){
            return this.get("lastUpdateDateString");
        },
        getDescription : function(){
            return this.get("description");
        },

        isPaid : function(){
            return this.get("isPaid");
        },

        getCostInUSD : function(){
            return this.get("costInUSD");
        },
        
        
        getAudioPreviewUrl : function(){
            return this.get("audioPreviewUrl");
        },
        
        getGenreIdToAudioPreviewUrl : function(){
            return this.get("genreIdToAudioPreviewUrl");
        },
        
        getAudioPreviewUrlByGenreId : function(genreId){
            return this.getGenreIdToAudioPreviewUrl()? this.getGenreIdToAudioPreviewUrl()[genreId]:null;
        },
        
        isFollowing : function(){
            return this.get("isFollowing");
        },

        hasPurchased : function(){
            return this.get("hasPurchased");
        },
        
        
        getCoverArtUrl : function(){
            return this.get("coverArtUrl");
        },
        
        getCategoryId: function(){
            return this.get("categoryId");
        },

        getCityId: function(){
            return this.get("cityId");
        },

        setCoverArtUrl : function(newCoverArtUrl){
            return this.set("coverArtUrl",newCoverArtUrl);
        },
        
        getCoverArtUrlBlur : function(){
            return this.get("coverArtUrlBlur");
        },
        
        setCoverArtUrlBlur : function(newCoverArtUrlBlur){
            return this.set("coverArtUrlBlur",newCoverArtUrlBlur);
        },
        
        getCoverArtUrlInfo : function(){
            return this.get("coverArtUrlInfo");
        },
        
        setCoverArtUrlInfo : function(newCoverArtUrlBlur){
            return this.set("coverArtUrlInfo",newCoverArtUrlBlur);
        },

        setCustomURL : function(newCustomURL){
            return this.set("customURL",newCustomURL);
        },
        
        getCustomURL : function(){
            return this.get("customURL");
        },

        getCoverArtUrlBlurInfo : function(){
            return this.get("coverArtUrlBlurInfo");
        },
        
        setCoverArtUrlBlurInfo : function(newCoverArtUrlBlurInfo){
            return this.set("coverArtUrlBlurInfo",newCoverArtUrlBlurInfo);
        },
        
        getCoverArtBase64 : function(){
            return this.get("coverArtBase64");
        },
        
        canBeFollowed : function(){
            return this.get("canBeFollowed");
        },
        
        
        canBeEdited : function(){
            return this.get("canBeEdited");
        },
        
        getNumberOfFollowers : function(){
            return this.get("numberOfFollowers");
        },

        getNumberOfPurchasers : function(){
            return this.get("numberOfPurchasers");
        },
        
        getNumberOfSongs : function(){
            return this.get("numberOfSongs");
        },

        setNumberOfSongs : function(numberOfSongs){
            return this.set("numberOfSongs",numberOfSongs);
        },
        
        getTotalNumberOfPlays : function(){
            return this.get("totalNumberOfPlays");
        },
        
        getOwnerNumberOfPlays : function(){
            return this.get("ownerNumberOfPlays");
        },
        
        getPlaySuccessRate : function(){
            return this.get("playSuccessRate");
        },
        
        getNumberOfSongsFavorited : function(){
            return this.get("numberOfSongsFavorited");
        },
        
        getNumberOfCapsules : function(){
            return this.get("numberOfCapsules");
        },
        
        getNumberOfSongsComments : function(){
            return this.get("numberOfSongsComments");
        },

        setName: function(name){
            this.set("name", name);
        },
        
        setDescription : function(description){
            this.set("description", description);
        },
        
        setFollowing : function(following){
            const isFollowingOldValue = this.get("isFollowing");
            this.set("isFollowing", following);
            if(following !== isFollowingOldValue){
                if(following){
                    this.addFollower();
                }else{
                    this.removeFollower();
                }
            }
        },
        
        setPlaying : function(isPlaying){
            if(this.isPlaying() != isPlaying){
                this.set("isPlaying", isPlaying);
            }
        },
        
        isPlaying : function(isPlaying){
            return this.get("isPlaying");
        },
        
        addFollower : function(){
            let numberOfFollowers = this.get("numberOfFollowers");
            numberOfFollowers =  numberOfFollowers? numberOfFollowers: 0;
            numberOfFollowers = numberOfFollowers + 1;
            this.set("numberOfFollowers", numberOfFollowers);
        },
        
        removeFollower : function(){
            let numberOfFollowers = this.get("numberOfFollowers");
            numberOfFollowers =  numberOfFollowers? numberOfFollowers: 0;
            if(numberOfFollowers != 0){
                numberOfFollowers = numberOfFollowers - 1;
            }
            this.set("numberOfFollowers",numberOfFollowers);
        },

    
});
export default PlaylistSummary;