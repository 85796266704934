import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import CommentBoxView from "views/comment/CommentBoxView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const CommentBoxController = Chaplin.Controller.extend({

    initialize : function(options) {
        this.model = options.commentContext;
        this.songRefSummary = options.songRefSummary;
        this.context = options.context;
        _.extend(this, Backbone.Events);
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },
    postCommentAsReply :function(commentToPost){
        $.mobile.loading("show");
        if(commentToPost){
            commentToPost = commentToPost.trim();
        }
        const songJsonForBackend = Utils.getInstance().getSongJsonForBackendFromSongModel(this.model);
        TrebbleClientAPIHelper.getInstance().postReply(this.model.getCommentId(), commentToPost).then((function(createdCommentJson){
            const commentModel = Utils.getInstance().getModelFromCommentJson(createdCommentJson);
            this.trigger("newCommentCreated",commentModel);
            this.view.clearAndBlur();
            $.mobile.loading("hide");
        }).bind(this)).catch(function(error){
            $.mobile.loading("hide");
            console.error(error);
            Utils.getInstance().showErrorNotification(error);
        });
    },
    

    
    postComment : function(commentToPost){
        if(Utils.getInstance().isSongInstance(this.model)){
            this.postCommentAsCommentOnSong(commentToPost);
        }else{
            if(Utils.getInstance().isCommentInstance(this.model)){
                this.postCommentAsReply(commentToPost);
            }else{
                if(Utils.getInstance().isCapsuleInstance(this.model)){
                    this.postCommentAsCommentOnCapsule(commentToPost);
                }
                
            }
        }
        if(window.trebbleAnalyticsHelper && window.trebbleAnalyticsHelper.trebbleHelperTrackComment){
            window.trebbleAnalyticsHelper.trebbleHelperTrackComment();
        }
    },
    postCommentAsCommentOnSong : function(commentToPost){
        $.mobile.loading("show");
        if(commentToPost){
            commentToPost = commentToPost.trim();
        }
        const songJsonForBackend = Utils.getInstance().getSongJsonForBackendFromSongModel(this.model);
        TrebbleClientAPIHelper.getInstance().postComment(songJsonForBackend,this.songRefSummary, commentToPost, this.context).then((function(createdCommentJson){
            const commentModel = Utils.getInstance().getModelFromCommentJson(createdCommentJson);
            this.trigger("newCommentCreated",commentModel);
            this.view.clearAndBlur();
            $.mobile.loading("hide");

        }).bind(this)).catch(function(error){
            $.mobile.loading("hide");
            console.error(error);
            Utils.getInstance().showErrorNotification(error);
        });
    },

    postCommentAsCommentOnCapsule : function(commentToPost){
        $.mobile.loading("show");
        if(commentToPost){
            commentToPost = commentToPost.trim();
        }
        const capsuleId = this.model.getCapsuleId();
        TrebbleClientAPIHelper.getInstance().commentOnCapsule(capsuleId,this.songRefSummary, commentToPost, this.context).then((function(createdCommentJson){
            const commentModel = Utils.getInstance().getModelFromCommentJson(createdCommentJson);
            this.trigger("newCommentCreated",commentModel);
            this.view.clearAndBlur();
            $.mobile.loading("hide");
        }).bind(this)).catch(function(error){
            $.mobile.loading("hide");
            console.error(error);
            Utils.getInstance().showErrorNotification(error);
        });
    },

    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                const params = {};
                params.model = this.model;
                params.followersUsersInfo = PersistentModels.getInstance().getMyFollowingsInfo();
                this.view = new CommentBoxView(params);
                this.listenTo(this.view, "postComment",this.postComment);
                this.view.render();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default CommentBoxController;