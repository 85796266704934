import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import SelectionListController from "controllers/selectionList/SelectionListController";
import CustomSelectFieldView from "views/common/CustomSelectFieldView";
import Utils from "models/helper/Utils";
import OptionPopupView from "views/common/OptionPopupView";
import RSVP from "rsvp";

const CustomSelectFieldController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = options.model;
        options.singleSelect = true;
        this.context = options.context;
        this._propertyFunction = options? options.propertyFunction : null;
        this._propertyName = options? options.propertyName : null;
        this._propertyIdFunction = options? options.propertyIdFunction : null;
        this._propertyIdName = options? options.propertyIdName : null;
        options.onItemSelected =  this._onItemSelectedHandler.bind(this);
        this.selectionListControllerOptions = options;
        this._defaultText = options.defaultText ;
        this._selectedModel = null;
        this._currentlyDisplayedSelectionListController = null;
        this._currentlyDisplayedselectionPopupView = null;
        this._enableSearch = options.enableSearch;
        this._doNotCloseOnItemSelected = options? options.doNotCloseOnItemSelected : false;

    },
    
    _onItemSelectedHandler : function(selected, model){
        this.view.setSelected(selected, this._getModelLabel(model), this._getModelId(model));
        if(selected){
            this._selectedModel = model;
        }else{
            this._selectedModel = null;
        }
        if(!this._doNotCloseOnItemSelected && this._selectedModel && this._currentlyDisplayedselectionPopupView){
            this._currentlyDisplayedselectionPopupView.close();
        }
        
    },
    
    setSelected : function(model, selected, doNotTriggerSelectionChange){
        this._onItemSelectedHandler(selected, model);
        if(!doNotTriggerSelectionChange){
            this._triggerSelectionChange();
        }
    },

    getSelectedModel : function(){
        return this._selectedModel;
    },

    setSelectedByModelId : function(modelId){
        
    },
    
    _getModelLabel : function(model){
        if(model){
            return this._propertyFunction?model[this._propertyFunction]():model[this._propertyName ];
        }
        return null;
    },

    _getModelId : function(model){
        if(model){
            return this._propertyIdFunction?model[this._propertyIdFunction]():model[this._propertyIdName ];
        }
        return null;
    },
    
    
    
    

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },

    _triggerSelectionChange  : function(){
        this._currentlyDisplayedSelectionListController = null;
        this._currentlyDisplayedselectionPopupView = null;
        this.trigger("selectionChanged", this._selectedModel);
    }, 

    _onPopupDisplayed : function(){
        //this._currentlyDisplayedSelectionListController.setSearchFieldValueIfApplicable(this._getModelLabel(this.getSelectedModel()), true);
        //this._currentlyDisplayedSelectionListController.focusOnSearchFieldIfApplicable();
        this._currentlyDisplayedSelectionListController ? this._currentlyDisplayedSelectionListController.scrollToTheTop(): null;

    },

    onSelectFieldClicked : function(){
        const controller = new SelectionListController(this.selectionListControllerOptions);
        this._currentlyDisplayedSelectionListController = controller;
        controller.buildModelAndRenderView().then((function(){
            controller.setSelected(this._selectedModel, true);
            const params= {};
            params.contentView = controller.getView();
            params.contentPosition = this._enableSearch ? "top" : "center";
            params.id = "customSelectPopup"+ (new Date()).getTime();
            params.addContentAfterEnhancement = true;
            const selectionPopupView = new OptionPopupView(params);
            this._currentlyDisplayedselectionPopupView = selectionPopupView;
            this.listenTo(selectionPopupView ,"popupbeforeposition", (function(popupView){
                const content$el  = popupView.$el.find(".popupContent > div:not(.trebblePopupHeader)");
                content$el.css("margin-top","40px");
                content$el.css("margin-bottom","40px");
                content$el.css("overflow","auto");
                content$el.css("max-width","500px");
            }).bind(this));
            
            this.listenTo(selectionPopupView,"opened", this._onPopupDisplayed.bind(this));
            this.listenTo(selectionPopupView,"closed", this._triggerSelectionChange.bind(this));
            selectionPopupView.render();
        }).bind(this));
        
        
    },

    onViewRemoved:function(){
        this.stopListening();
    },


    buildModelAndRenderView : function() {
        const options = {};
        options.defaultText  = this._defaultText;
        this.view =  new CustomSelectFieldView(options);
        this.view.render();
        this.listenTo(this.view,"selectFieldClicked", this.onSelectFieldClicked);
        this.listenTo(this.view, "remove", this.onViewRemoved.bind(this));
        return RSVP.Promise.resolve();
    }

});

export default CustomSelectFieldController;