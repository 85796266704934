import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Song from "models/Song";
import TrebbleListView from "views/common/TrebbleListView";
import ArtistListItemTemplate from "text!../../../templates/common/artistListItemTemplate.html";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";

const TrebbleArtistListView = TrebbleListView.extend({
    
    
    
    
    
    initialize : function(options) {
        if(!options){
            options= {};
        }
        if(!options.rightSwipeRevealOption){
            options.rightSwipeRevealOption = {};// this._buildItemRightOption()
        }
        if(!options.leftSwipeRevealOption){
            options.leftSwipeRevealOption = {};//this._buildItemLeftOption()
        }

        this.collection = options.collection
        options.modelIdAttribute  = "artistId";
        options.listItemTemplate = ArtistListItemTemplate;
        options.doNotShowAddToRadioButton = true;
        options.doNotShowGradeSongButton = true;
        const self = TrebbleListView.prototype.initialize.call(this, options);
        this.setModelTransformHandlerBeforeTemplatePiping(this._modelTransformHandlerBeforeTemplatePiping);
        this.setItemViewUpdateDuringScrollingHandler(this._getItemViewUpdateDuringScrollingHandler);
        return self;
        
    },
    
    _getDefaultItemEventHandler : function(){
        let defaultItemEventHandler =  TrebbleListView.prototype._getDefaultItemEventHandler.call(this);
        defaultItemEventHandler = defaultItemEventHandler? defaultItemEventHandler : [];
        delete defaultItemEventHandler["click div.songOptionBtnWrapper > #songLibraryOptionButtuon"];
        return defaultItemEventHandler;
    },
    reload : function(){
        this.setUpdating(true);
        try{
            const displayedArtistJSONArray = this.collection? this.collection.toJSON(): [];
            this.updateModels(displayedArtistJSONArray);
            this.setUpdating(false);
        }catch(error){
            this.setErrorMessage(error);
        }
    },
    
    /*_getInternalOnCapsuleIconTapHandler : function(model,evnt, item) {
        const songModel = this._getArtistModelFromSongJson(model);
        const context = this.getContext();
        if(songModel){
            return PersistentModels.getInstance().getRouter().showCapsulesForSong(songModel, context);
        }else{
            return RSVP.Promise.reject("Song couldn't be found");
        }	
    },*/

    
/*_buildItemRightOption : function(){
        const optionConfig = [];
        optionConfig.push( {"optionLabel" : "..."});
    optionConfig.push( {"optionLabel" : "Add To My Trebble<i class='ion-ios7-plus-outline'></i>","name": "addToRadio", "validationHandler": this._canBeAddedToRadio.bind(this), "onOptionSelected": this._onAddSongToRadioMenuSelected.bind(this)});//"<i class='fontello-icon-list-add'></i>","name": "addToQueue"};// "Add To Queue"};
    optionConfig.push( {"optionLabel" : "Grade<i class='ion-ios7-pricetags-outline'></i>","name": "rateOrTag" , "validationHandler": null, "onOptionSelected": this._onTagSongOptionMenuSelected.bind(this)});//"<i class='ion-radio-waves'></i>","name": "playSimilarInLibrary"};//: "Play Similar In Library"};
    return optionConfig;
},

_buildItemLeftOption : function(){
    const optionConfig = [];
    optionConfig.push( {"optionLabel" : "..."});
    optionConfig.push( {"optionLabel": "<i class='pe-7s-more'></i>Show Options","name": "showOptions", "validationHandler": null, "onOptionSelected": this._onShowSongOptionMenuSelected.bind(this)});//"<i class='fontello-icon-list-add'></i>"};// "Add To Queue"};
    optionConfig.push( {"optionLabel": "<i class='ion-ios7-minus-outline'></i>Remove From My Trebble","name": "removeFromRadio", "validationHandler":  this._canBeRemovedToRadio.bind(this), "onOptionSelected": this._onRemoveSongToRadioMenuSelected.bind(this)});//"<i class='ion-radio-waves'></i>"};// : "Play Similar In Library"};
    return optionConfig;
},
*/


onActionExecutedOnArtistHandler : function(artistModel, action){
    this.trigger("actionExecutedOnArtistHandler", artistModel, action);
    /*if(action == "delete" || action == "removeFromRadio" || action == "removeToFavorite"){
        
    }*/
},



getContext : function(){
    return this.collection? this.collection.getContext(): null;
},


_getArtistModelFromSongJson : function(artistJson){
    return this.collection.get(artistJson.artistId);
},

_modelTransformHandlerBeforeTemplatePiping: function(artistJson) {
    const artistArtURL = artistJson.artistArtURL?window.trebble.globalHelpers.getResizeImageUrl(artistJson.artistArtURL, 64, 64, artistJson.artistArtImages): "";
    const templateData = {"artistArtURL": artistArtURL, "artistName": artistJson.artistName, "actionButtonIconClass": "pe-7s-more"};
    return templateData;
},

_getItemViewUpdateDuringScrollingHandler : function(elem,templateData){
    const profileImageElem = elem.querySelector(".userCoverImage");
    profileImageElem.src = window.getResourceURLAccordingToLocationProtocol("/img/videoLoading.png");
    profileImageElem.setAttribute("data-original", templateData.artistArtURL);
    if(!profileImageElem.className ||  profileImageElem.className.indexOf("lazy") == -1){
        profileImageElem.className = (!profileImageElem.className)? "lazy" :  profileImageElem.className +" lazy";
    }
    
    const artistNameElem = elem.querySelector(".songName");
    artistNameElem.textContent = templateData.artistName;
    

    const actionButtonElem = elem.querySelector("#actionButton");
    if(templateData.actionButtonIconClass){
        
        actionButtonElem.className = "actionIcon enabled"+templateData.actionButtonIconClass;
        actionButtonElem.style.display = "flex";
        if(actionButtonElem.style.display != "flex"){
            actionButtonElem.style.display = "-webkit-flex";
        }
    }else{
        actionButtonElem.className = "actionIcon ";
        actionButtonElem.style.display = "none";
    }
},


});

export default TrebbleArtistListView;
