import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import AudioFilterFactory from "models/audioEditor/filters/AudioFilterFactory";
import AudioBufferUtils from "models/audioEditor/AudioBufferUtils";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import RSVP from "rsvp";
import Utils from "models/helper/Utils";
import ti18n from "i18n!nls/Sequenceri18n";



const Rollout = RolloutHelper.getInstance();
const INSERT_SOUND_DB_LEVEL = -1 * Rollout.getFeatureVariable(Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR, Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR.variables.inserted_sound_db_level, 18);
const FINAL_RENDERED_FILE_DB_LEVEL =  Rollout.getFeatureVariable(Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR, Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR.variables.final_rendered_file_db_lebel, -16);
const AUTOMATIC_CROSSFADE_DURATION_IN_MILLISECONDS =  Rollout.getFeatureVariable(Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR, Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR.variables.automatic_crossfade_duration_in_milliseconds, 20);
const TURN_ON_AUTOMATIC_CROSSFADE_BETWEEN_CUTS = Rollout.getFeatureVariable(Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR, Rollout.FEATURES.TREBBLE_TEXT_BASED_AUDIO_EDITOR.variables.turn_on_automatic_crossfade_between_cuts, false);
const USE_TWO_STEP_COMPRESSION_FOR_VOICE =  true;
const USE_RNNOISE_WEB_AUDIO_NODE = window.RNNoiseNode;

const CHANGEABLE_SETTINGS = {"VOICE_ENHANCER_ON":"voiceEnhancerOn", "MUSIC_ENHANCER_ON":"musicEnhancerOn","SOUND_FX_ENHANCER_ON":"soundFxEnhancerOn", "DELETED_NODES_DISPLAYED":"showIgnoredContent","REVEAL_DELETED_CONTENT":"revealDeletedContent","GAP_NODES_DISPLAYED":"showGapsBetweenWords","EDIT_BOUNDARIES_DISPLAYED":"showEditBoundaries","AUTOSCROLL":"autoscroll","SHOW_DARK_THEME":"showdarkTheme"};
const SequencerSettings =  Backbone.Model.extend({
    idAttribute: "id",

    constructor: function(attributes, options) {
        this._voiceRecordingDefaultFiltersCollection =  new Backbone.Collection();
        this._musicDefaultFiltersCollection =  new Backbone.Collection();
        this._soundDefaultFiltersCollection =  new Backbone.Collection();
        this._undefinedDefaultFiltersCollection =  new Backbone.Collection();
        this._masterDefaultFiltersCollection =  new Backbone.Collection();

        Backbone.Model.apply(this, [attributes, options]);
        this.init();
    },

    init : function(){
        this.setNoiseCancellationOn(false, true);
        this.setMagicSoundEnhancerOn(false, true, true);
        this.setAllDefaultVoiceFiltersOn(false, true);
        this.setMusicFiltersOn(false, true);
        this.setSoundFiltersOn(false, true);
        this.setShowIgnoredContent(true);
        this.setRevealDeletedContent(false);
        this.setShowGapsBetweenWords(true);
        this.setShowEditBoundaries(true);
        this.setShowDarkTheme(LocalStorageHelper.getInstance().getUseEditorDarkTheme());
        this.setAutoscroll(true);
        this.resetToDefaultFilters();
    },

    getId : function(){
        this.cid;
    },

    clear : function(){
        this.init();
    },

    getSerializedData: function(){
        const stateToReturn  = this.toJSON();
        return stateToReturn;
    },

    restoreFromSerializedData: function(serializedData){
        this.set(serializedData);
        return RSVP.Promise.resolve();
    },

    isNoiseCancellationOn : function(){
        return this.get("noiseCancellationOn");
    },

    isMagicSoundEnhancerOn : function(){
        return this.get("magicSoundEnhancerOn");
    },

    _triggerChangeEventWhichRequiresPlaybackRerender : function(){
        this.trigger("changeWhichRequiresRerender");
    },

    setSequencer  : function(sequencer){
        this._sequencer = sequencer;
    },

    getSequencer : function(){
        return this._sequencer;
    },

    setNoiseCancellationOn : async function(noiseCancellationOn, doNotResetFilters){
        this.set("noiseCancellationOn", !!noiseCancellationOn);
        if(!doNotResetFilters){
            this.resetToDefaultFilters();
        }   
        this._triggerChangeEventWhichRequiresPlaybackRerender();
    },

    setMagicSoundEnhancerOn : async function(magicSoundEnhancerOn, doNotResetFilters, doNotTrackChangeEvent){
        if(magicSoundEnhancerOn){
            const urlWihoutCounterPartArray = await this.getSequencer().getAllLoadedTranscribedFilesWithoutEnhancedCounterpart();
            if(!urlWihoutCounterPartArray || urlWihoutCounterPartArray.length ===  0){
                this.set("magicSoundEnhancerOn", !!magicSoundEnhancerOn);
                if(!doNotResetFilters){
                    this.resetToDefaultFilters();
                }
            }else{
                this.getSequencer().triggerSpeechEnhancementForTranscribedUrlsAndToggleEnhancementSettingsWhenReady(urlWihoutCounterPartArray);
                this.set("magicSoundEnhancerOn", false);
                this.trigger("change:magicSoundEnhancerOn",false);
                if(!doNotTrackChangeEvent && window.trebbleAnalyticsHelper){
                    window.trebbleAnalyticsHelper.trackEvent("sequencer", 'turnOnSpeechEnhancement', 'Turn on speech enhancement with vocal glow');
                }
            }
        }else{
            this.set("magicSoundEnhancerOn", !!magicSoundEnhancerOn);
            if(!doNotResetFilters){
                this.resetToDefaultFilters();
            }
            if(!doNotTrackChangeEvent && window.trebbleAnalyticsHelper){
                window.trebbleAnalyticsHelper.trackEvent("sequencer", 'turnOffSpeechEnhancement', 'Turn off speech enhancement with vocal glow');
            }
        }
        this._triggerChangeEventWhichRequiresPlaybackRerender();
    },

    isRumbleReductionOn : function(){
        return this.get("rumbleReductionOn");
    },

    setRumbleReductionOn : function(rumbleReductionOn, doNotResetFilters){
        this.set("rumbleReductionOn", !!rumbleReductionOn);
        this._triggerChangeEventWhichRequiresPlaybackRerender();
        if(!doNotResetFilters){
            this.resetToDefaultFilters();
        }
    },

    isRemoveHarshEssOn : function(){
        return this.get("removeHarshEssOn");
    },

    setRemoveHarshEssOn : function(removeHarshEssOn, doNotResetFilters){
        this.set("removeHarshEssOn", !!removeHarshEssOn);
        this._triggerChangeEventWhichRequiresPlaybackRerender();
        if(!doNotResetFilters){
            this.resetToDefaultFilters();
        }
    },

    setVideoResolution : function(width, height, updateOnlyIfNoVideoResolution, doNotResetFilters){
        if(!updateOnlyIfNoVideoResolution || !this.hasVideoResolution()){
            this.set("width", width);
            this.set("height", height);
            this._triggerChangeEventWhichRequiresPlaybackRerender();
            if(!doNotResetFilters){
                this.resetToDefaultFilters();
            }
        }
    },

    hasVideoResolution: function(){
        return !(!this.get("width") &&  !this.get("height"));
    },

    getVideoResolution : function(){
        return {width: this.get("width"), height: this.get("height")};
    },

    isVoiceVolumeLevelingOn : function(){
        return this.get("voiceVolumeLevelingOn");
    },

    setVoiceVolumeLevelingOn : function(voiceVolumeLevelingOn, doNotResetFilters){
        this.set("voiceVolumeLevelingOn", !!voiceVolumeLevelingOn);
        this._triggerChangeEventWhichRequiresPlaybackRerender();
        if(!doNotResetFilters){
            this.resetToDefaultFilters();
        }
    },

    isImproveSpeechClarityOn : function(){
        return this.get("improveSpeechClarityOn");
    },

    setImproveSpeechClarityOn : function(improveSpeechClarityOn, doNotResetFilters){
        //toggle can be switched
        this.set("improveSpeechClarityOn", !!improveSpeechClarityOn);
        this._triggerChangeEventWhichRequiresPlaybackRerender();
        if(!doNotResetFilters){
            this.resetToDefaultFilters();
        }
      
    },

    isAllDefaultVoiceFiltersOn: function(){
        return this.isRumbleReductionOn() && this.isRemoveHarshEssOn() && this.isVoiceVolumeLevelingOn() && this.isImproveSpeechClarityOn();
    },

    setAllDefaultVoiceFiltersOn : function(turnOn, doNotResetFilters){
        this.setRumbleReductionOn(turnOn, true);
        this.setRemoveHarshEssOn(turnOn, true);
        this.setVoiceVolumeLevelingOn(turnOn, true);
        this.setImproveSpeechClarityOn(turnOn, true);
        this.setAutoCrossfadingBetweenWordsClosesTogetherInReferenceAudio(turnOn && TURN_ON_AUTOMATIC_CROSSFADE_BETWEEN_CUTS);
        this.setAutoCrossfadingBetweenWordsClosesTogetherInReferenceAudioDurationInMilliseconds(AUTOMATIC_CROSSFADE_DURATION_IN_MILLISECONDS);
        if(!doNotResetFilters){
            this.resetToDefaultFilters();
        }
        this.trigger("change:allDefaultVoiceFiltersOn");
    },

    isMusicFiltersOn : function(){
        return this.get("musicFiltersOn");
    },

    setMusicFiltersOn : function(musicFiltersOn, doNotResetFilters){
        this.set("musicFiltersOn", !!musicFiltersOn);
        this._triggerChangeEventWhichRequiresPlaybackRerender();
        if(!doNotResetFilters){
            this.resetToDefaultFilters();
        }
    },

    isSoundFiltersOn : function(){
        return this.get("soundFiltersOn");
    },

    setSoundFiltersOn : function(soundFiltersOn, doNotResetFilters){
        this.set("soundFiltersOn", !!soundFiltersOn);
        this._triggerChangeEventWhichRequiresPlaybackRerender();
        if(!doNotResetFilters){
            this.resetToDefaultFilters();
        }
    },

    resetVoiceRecordingDefaultFilters: function(){
        this._voiceRecordingDefaultFiltersCollection.reset();
        if(this.isNoiseCancellationOn()){
            if(USE_RNNOISE_WEB_AUDIO_NODE){
                this._voiceRecordingDefaultFiltersCollection.add(AudioFilterFactory.getInstance().createRNNoiseFilter());	
            }
            this._voiceRecordingDefaultFiltersCollection.add(AudioFilterFactory.getInstance().createNoiseGateFilter(AudioFilterFactory.getInstance().getNoiseGateFilterPresets().DEFAULT_NOISE_GATE));
        }
        if(this.isRumbleReductionOn()){
            this._voiceRecordingDefaultFiltersCollection.add(AudioFilterFactory.getInstance().createHighPassFilter(AudioFilterFactory.getInstance().getHighPassFilterPresets().VOCAL_LIMIT_BASS));
            this._voiceRecordingDefaultFiltersCollection.add(AudioFilterFactory.getInstance().createLowPassFilter(AudioFilterFactory.getInstance().getLowPassFilterPresets().KILL_NON_AUDIBLE));
        }
        //Improve the quality of the Compression
        if(this.isVoiceVolumeLevelingOn()){
            if(!USE_TWO_STEP_COMPRESSION_FOR_VOICE){
                this._voiceRecordingDefaultFiltersCollection.add(AudioFilterFactory.getInstance().createCompressorFilter(AudioFilterFactory.getInstance().getCompressorFilterPresets().CLASSIC_VOICE_OVER));
            }else{
                const DEFAULT_PEAK_NORMALIZATION_IN_DB = -1;
                const IDEAL_PEAK_FOR_COMPRESSION = -12;
                const gainBeforeCompression = AudioBufferUtils.getInstance().getAmpFromDb(IDEAL_PEAK_FOR_COMPRESSION - DEFAULT_PEAK_NORMALIZATION_IN_DB);
                this._voiceRecordingDefaultFiltersCollection.add(AudioFilterFactory.getInstance().createGainFilter(gainBeforeCompression));
                this._voiceRecordingDefaultFiltersCollection.add(AudioFilterFactory.getInstance().createCompressorWithGainFilter(AudioFilterFactory.getInstance().getCompressorWithGainFilterPresets().TWO_STEP_VOICE_COMPRESSION_PART_1));
                this._voiceRecordingDefaultFiltersCollection.add(AudioFilterFactory.getInstance().createCompressorWithGainFilter(AudioFilterFactory.getInstance().getCompressorWithGainFilterPresets().TWO_STEP_VOICE_COMPRESSION_PART_2));
            }
        }
        if(this.isImproveSpeechClarityOn() && false){
            this._voiceRecordingDefaultFiltersCollection.add(AudioFilterFactory.getInstance().createParametricEqFilter(AudioFilterFactory.getInstance().getParametricEqFilterPresets().GENERIC_VOICE_EQ));
        }
        if(this.isVoiceVolumeLevelingOn()){
            if(USE_TWO_STEP_COMPRESSION_FOR_VOICE){
                const gainAfterCompression = AudioBufferUtils.getInstance().getAmpFromDb(11); // max peak based on my calculation is -15.49 DB instead of 8DB based on other compressor settings
                this._voiceRecordingDefaultFiltersCollection.add(AudioFilterFactory.getInstance().createGainFilter(gainAfterCompression));
                
            }
        }
        //have hum reduction 
        //Have dereverb
        //mouth click removal

            
        if(this.isRemoveHarshEssOn()){
            this._voiceRecordingDefaultFiltersCollection.add(AudioFilterFactory.getInstance().createDeesserFilter(AudioFilterFactory.getInstance().getDeesserFilterPresets().DEFAULT_DEESSER));
        }
        //Add Custom EQ to boost Bass and Treble
    },

    resetMusicDefaultFilters : function(){
        this._musicDefaultFiltersCollection.reset();
        if(this.isMusicFiltersOn()){
            this._musicDefaultFiltersCollection.add(AudioFilterFactory.getInstance().createCompressorFilter(AudioFilterFactory.getInstance().getCompressorFilterPresets().GENTLE_MUSIC));
        }
    },

    resetSoundDefaultFilters : function(){
        this._soundDefaultFiltersCollection.reset();
        if(this.isSoundFiltersOn()){
            this._soundDefaultFiltersCollection.add(AudioFilterFactory.getInstance().createHighPassFilter(AudioFilterFactory.getInstance().getHighPassFilterPresets().AUDIBLE_SOUND_MIN_LIMIT));
        }
        //this._soundDefaultFiltersCollection.add(AudioFilterFactory.getInstance().createCompressorFilter(AudioFilterFactory.getInstance().getCompressorFilterPresets().SOUND_FX));
        
        //this._soundDefaultFiltersCollection.add(AudioFilterFactory.getInstance().createEBULoudnessNormalizationFilter(INSERT_SOUND_DB_LEVEL));
        
        /*const soundLimiterFilter = AudioFilterFactory.getInstance().createLimiterFilter();
        soundLimiterFilter.setCeiling(-16);*/
        //this._soundDefaultFiltersCollection.add(soundLimiterFilter);
    },

    resetUndefinedDefaultFilters : function(){
        this._soundDefaultFiltersCollection.reset();

    },

    getSetting : function(setting){
        if(CHANGEABLE_SETTINGS.VOICE_ENHANCER_ON === setting){
            return this.isAllDefaultVoiceFiltersOn();
        }
        if(CHANGEABLE_SETTINGS.MUSIC_ENHANCER_ON === setting){
            return this.isMusicFiltersOn();
        }

        if(CHANGEABLE_SETTINGS.SOUND_FX_ENHANCER_ON === setting){
            return this.isSoundFiltersOn();
        }
        if(CHANGEABLE_SETTINGS.DELETED_NODES_DISPLAYED === setting){
            return this.isShowIgnoredContent();
        }

        if(CHANGEABLE_SETTINGS.REVEAL_DELETED_CONTENT === setting){
            return this.isRevealDeletedContentOn();
        }

        if(CHANGEABLE_SETTINGS.GAP_NODES_DISPLAYED === setting){
            return this.isShowGapsBetweenWords();
        }

        if(CHANGEABLE_SETTINGS.EDIT_BOUNDARIES_DISPLAYED ===  setting){
            return this.isShowEditBoundaries()
        }

        if(CHANGEABLE_SETTINGS.SHOW_DARK_THEME ===  setting){
            return this.isShowDarkTheme()
        }

        if(CHANGEABLE_SETTINGS.AUTOSCROLL ===  setting){
            return this.isAutoscroll()
        }
    },

    changeSetting : function(settingToChange, settingValue){
        if(CHANGEABLE_SETTINGS.VOICE_ENHANCER_ON === settingToChange){
            return this.setAllDefaultVoiceFiltersOn(settingValue)
        }
        if(CHANGEABLE_SETTINGS.MUSIC_ENHANCER_ON === settingToChange){
            return this.setMusicFiltersOn(settingValue)
        }

        if(CHANGEABLE_SETTINGS.SOUND_FX_ENHANCER_ON === settingToChange){
            return this.setSoundFiltersOn(settingValue)
        }
        if(CHANGEABLE_SETTINGS.DELETED_NODES_DISPLAYED === settingToChange){
            return this.setShowIgnoredContent(settingValue);
        }

        if(CHANGEABLE_SETTINGS.REVEAL_DELETED_CONTENT === settingToChange){
            return this.setRevealDeletedContent(settingValue);
        }

        if(CHANGEABLE_SETTINGS.GAP_NODES_DISPLAYED === settingToChange){
            return this.setShowGapsBetweenWords(settingValue);
        }

        if(CHANGEABLE_SETTINGS.EDIT_BOUNDARIES_DISPLAYED === settingToChange){
            return this.setShowEditBoundaries(settingValue);
        }

        if(CHANGEABLE_SETTINGS.SHOW_DARK_THEME === settingToChange){
            return this.setShowDarkTheme(settingValue);
        }

        if(CHANGEABLE_SETTINGS.AUTOSCROLL === settingToChange){
            return this.setAutoscroll(settingValue);
        }
    },



    resetMasterRecordingDefaultFilters: function(){
        this._masterDefaultFiltersCollection.reset();
        this._masterDefaultFiltersCollection.add(AudioFilterFactory.getInstance().createCompressorFilter(AudioFilterFactory.getInstance().getCompressorFilterPresets().MAIN_BUSS_COMPRESSION));
        const masterLimiterFilter = AudioFilterFactory.getInstance().createLimiterFilter();
        masterLimiterFilter.setCeiling(-1);
        this._masterDefaultFiltersCollection.add(masterLimiterFilter);

        //this._masterDefaultFiltersCollection.add(AudioFilterFactory.getInstance().createEBULoudnessNormalizationFilter(-16));
    },

    resetToDefaultFilters : function(){
        this.resetVoiceRecordingDefaultFilters();
        this.resetMusicDefaultFilters();
        this.resetSoundDefaultFilters();
        this.resetUndefinedDefaultFilters();
        this.resetMasterRecordingDefaultFilters();

    },

    getMasterEBULoudnessNormalization :function(){
        return FINAL_RENDERED_FILE_DB_LEVEL;
    },

    isShowIgnoredContent:function(){
        return this.get("showIgnoredContent");
    },

    setShowIgnoredContent:function(showIgnoredContent){
        if(!showIgnoredContent && this.isRevealDeletedContentOn()){
            this.setRevealDeletedContent(false);
        }
        return this.set("showIgnoredContent", showIgnoredContent);
    },

    isRevealDeletedContentOn:function(){
        return this.get("revealDeletedContent");
    },

    setRevealDeletedContent:function(revealDeletedContent){
        if(revealDeletedContent && !this.isShowIgnoredContent()){
            this.setShowIgnoredContent(true);
        }
        return this.set("revealDeletedContent", revealDeletedContent);
    },

    isShowGapsBetweenWords:function(){
        return this.get("showGapsBetweenWords");
    },

    setShowGapsBetweenWords:function(showGapsBetweenWords){
        return this.set("showGapsBetweenWords", showGapsBetweenWords);
    },

    isShowEditBoundaries:function(){
        return this.get("showEditBoundaries");
    },

    isShowDarkTheme:function(){
        return this.get("showDarkTheme");
    },

    setShowEditBoundaries:function(showEditBoundaries){
        return this.set("showEditBoundaries", showEditBoundaries);
    },

    setShowDarkTheme:function(showDarkTheme){
        LocalStorageHelper.getInstance().setUseEditorDarkTheme(showDarkTheme);
        return this.set("showDarkTheme", showDarkTheme);
    },

    isAutoscroll:function(){
        return this.get("autoscroll");
    },

    setAutoscroll:function(autoscroll, silent){
        return this.set({"autoscroll": autoscroll}, {"silent" : silent});
    },


    getAutoCrossfadingBetweenWordsClosesTogetherInReferenceAudio : function(){
        return this.get("autoCrossfadingBetweenWordsClosesTogetherInReferenceAudio");
    },

    setAutoCrossfadingBetweenWordsClosesTogetherInReferenceAudio : function(autoCrossfadingBetweenWordsClosesTogetherInReferenceAudio){
        this.set("autoCrossfadingBetweenWordsClosesTogetherInReferenceAudio",autoCrossfadingBetweenWordsClosesTogetherInReferenceAudio);
        this._triggerChangeEventWhichRequiresPlaybackRerender();
    },

    getAutoCrossfadingBetweenWordsClosesTogetherInReferenceAudioDurationInMilliseconds : function(){
        return this.get("autoCrossfadingBetweenWordsClosesTogetherInReferenceAudioDurationInMilliseconds");
    },

    setAutoCrossfadingBetweenWordsClosesTogetherInReferenceAudioDurationInMilliseconds : function(autoCrossfadingBetweenWordsClosesTogetherInReferenceAudioDurationInMilliseconds){
        this.set("autoCrossfadingBetweenWordsClosesTogetherInReferenceAudioDurationInMilliseconds",autoCrossfadingBetweenWordsClosesTogetherInReferenceAudioDurationInMilliseconds);
        this._triggerChangeEventWhichRequiresPlaybackRerender();
    },

    getChangeableSettings : function(){
        return CHANGEABLE_SETTINGS;
    },

    getVoiceDefaultFiltersArray : function(){
        return this._voiceRecordingDefaultFiltersCollection.toArray();
    },

    getMusicDefaultFiltersArray : function(){
        return this._musicDefaultFiltersCollection.toArray();
    },

    getSoundDefaultFiltersArray : function(){
        return this._soundDefaultFiltersCollection.toArray();
    },

    getUndefinedDefaultFiltersArray : function(){
        return this._undefinedDefaultFiltersCollection.toArray();
    },

    getMasterDefaultFiltersArray : function(){
        return this._masterDefaultFiltersCollection.toArray();
    },


});

SequencerSettings.CHANGEABLE_SETTINGS =  CHANGEABLE_SETTINGS

export default SequencerSettings; 