import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Utils from "models/helper/Utils";
import SequencerNodeView from "views/audioEditor/sequencerNodeView/SequencerNodeView";
import SequencerPauseNodeTemplate from 'text!../../../../templates/audioEditor/sequencerNodeView/SequencerPauseNodeTemplate.html';
import ti18n from 'i18n!nls/Sequenceri18n';
import RSVP from 'rsvp';


const SequencerPauseNodeView = SequencerNodeView.extend({



    constructor : function(attributes, options) {
        SequencerNodeView.apply(this, [attributes, options]);
        this.compiledTemlate =  _.template(SequencerPauseNodeTemplate);
    },
    
    events : _.extend({
        
    }, SequencerNodeView.prototype.events),

    shouldBeAddedToParagraphContainerOfNextNodeFirst : function(){
        return false;
    },

    render : function() {

        this.$el.html(this.compiledTemlate({"model": this.model}));
        this.setElement(this.$el.find("span").first());
        this.afterRendering();

        return this;
    }

});
export default SequencerPauseNodeView;