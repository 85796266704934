import _ from 'underscore';
import SequencerOperation from "models/audioEditor/sequencerOperation/SequencerOperation";
import ti18n from 'i18n!nls/Sequenceri18n';

const CreateNewSpeakerAndAssignToSequencerNodesOperation =  SequencerOperation.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperation.apply(this, [attributes, options]);
    },

    execute : function(speakerLabel, paragraphSequencerNodeView, startSequencerNodeToAssign, endSequencerNodeToAssign){
        const speakerLabelAlreadyExist = this.getSequencer().isSpeakerWithLabelExist(speakerLabel);
        if(speakerLabelAlreadyExist){
            throw window.getI18n(ti18n, "A_SPEAKER_WITH_THE_SAME_NAME_ALREADY_EXIST")
        }
        if(this.isStartNodeAndEndNodeConnected(startSequencerNodeToAssign, endSequencerNodeToAssign)){
            const oldSpeakerInfo = paragraphSequencerNodeView.getModel();
            const arrayOfSequencerNodeToAssign = this.getArrayOfSequencerNodes(startSequencerNodeToAssign, endSequencerNodeToAssign);
            const {sequencerNodeCidToOldSpeakerId, newSpeakerInfoWithLabel, newSpeakerWasAdded} = this.createNewSpeakerAndAssignToParagraphAnSequencerNodes(arrayOfSequencerNodeToAssign, speakerLabel, paragraphSequencerNodeView);
            this.getSequencer().getHistoryManagement().addSequencerOperationCreateNewSpeakerAndAssignToSequencerNodesOperationLog(arrayOfSequencerNodeToAssign, speakerLabel, paragraphSequencerNodeView, oldSpeakerInfo, sequencerNodeCidToOldSpeakerId, newSpeakerWasAdded);
            return newSpeakerInfoWithLabel;
        }
    },

    createNewSpeakerAndAssignToParagraphAnSequencerNodes : function(arrayOfSequencerNodeToAssign, speakerLabel, paragraphSequencerNodeView){
        const sequencerNodeCidToOldSpeakerId = {};
        let newSpeakerInfoWithLabel = this.getSequencer().getSpeakerWithLabel(speakerLabel);
        let newSpeakerWasAdded =  false;
        if(!newSpeakerInfoWithLabel){
            newSpeakerInfoWithLabel  = this.getSequencer().createAdditionalSpeakerInfoWithLabel(speakerLabel);
            newSpeakerWasAdded = true;
        }
        arrayOfSequencerNodeToAssign.map((sequencerNode)=>{
            sequencerNodeCidToOldSpeakerId[sequencerNode.cid] = sequencerNode.getAudioSegment().getSpeakerInfo()? sequencerNode.getAudioSegment().getSpeakerInfo().getSpeakerId(): null;
            sequencerNode.getAudioSegment().setSpeakerInfo(newSpeakerInfoWithLabel);
        })
        paragraphSequencerNodeView.replaceSpeakerInfo(newSpeakerInfoWithLabel);
        const oldSpeakerInfo = paragraphSequencerNodeView.getModel();
        return {sequencerNodeCidToOldSpeakerId, newSpeakerInfoWithLabel, newSpeakerWasAdded};
    },

    undo: function(createNewSpeakerAndAssignToSequencerNodesOperationLog){
        const arrayOfSequencerNodeToAssign = createNewSpeakerAndAssignToSequencerNodesOperationLog.getArrayOfSequencerNodeToAssign();
        const sequencerNodeCidToOldSpeakerId = createNewSpeakerAndAssignToSequencerNodesOperationLog.getSequencerNodeCidToOldSpeakerId();
        const newSpeakerWasAdded = createNewSpeakerAndAssignToSequencerNodesOperationLog.getNewSpeakerWasAdded();
        const speakerLabel = createNewSpeakerAndAssignToSequencerNodesOperationLog.getSpeakerLabel();
        const paragraphSequencerNodeView = createNewSpeakerAndAssignToSequencerNodesOperationLog.getParagraphSequencerNodeView();
        const oldSpeakerInfo = createNewSpeakerAndAssignToSequencerNodesOperationLog.getOldSpeakerInfo();
        arrayOfSequencerNodeToAssign.map((sequencerNode)=>{
            const speakerId = sequencerNodeCidToOldSpeakerId[sequencerNode.cid];
            const speakerInfoWithId = this.getSequencer().getSpeakerInfoBySpeakerId(speakerId);
            sequencerNode.getAudioSegment().setSpeakerInfo(speakerInfoWithId);
        })
        if(newSpeakerWasAdded){
            this.getSequencer().removeAdditionalSpeakerBySpeakerLabel(speakerLabel);
        }
        paragraphSequencerNodeView.replaceSpeakerInfo(oldSpeakerInfo);
    },

    redo: function(createNewSpeakerAndAssignToSequencerNodesOperationLog){
        const arrayOfSequencerNodeToAssign = createNewSpeakerAndAssignToSequencerNodesOperationLog.getArrayOfSequencerNodeToAssign();
        const speakerLabel = createNewSpeakerAndAssignToSequencerNodesOperationLog.getSpeakerLabel();
        const paragraphSequencerNodeView = createNewSpeakerAndAssignToSequencerNodesOperationLog.getParagraphSequencerNodeView();
        const {newSpeakerInfoWithLabel} = this.createNewSpeakerAndAssignToParagraphAnSequencerNodes(arrayOfSequencerNodeToAssign, speakerLabel, paragraphSequencerNodeView);
        return newSpeakerInfoWithLabel;
    },



});

export default CreateNewSpeakerAndAssignToSequencerNodesOperation; 