import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import SongsTabContentCollection from "collections/myLibrary/SongsTabContentCollection";
import SongsTabContentView from "views/myLibrary/SongsTabContentView";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PersistentModels from "services/PersistentModels";
import PlayerService from "services/PlayerService";
import RSVP from "rsvp";
import ti18n from "i18n!nls/MyLibraryi18n";
import Utils from "models/helper/Utils";

const SongsTabContentController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this.model = options.model;
    },

    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },
    

    
    clearQueueAndSongAndRestToQueue : function(model, displayedCollection){
        const isMusicCurrentlyPlaying =  PlayerService.getInstance().isMusicPlaying();
        PlayerService.getInstance().clearQueueAndSongToQueue(model, this._buildPlayListName(), displayedCollection.getContext());
        if(!isMusicCurrentlyPlaying)
        {
            PlayerService.getInstance().playFirstSongInQueue();
        }
        window.setTimeout((function(){
            const backboneModel =  this.displayedCollection.get(model.get("uri"));
            PlayerService.getInstance().addSongsToQueue(this.displayedCollection.getSongsAfter(backboneModel), true);
        }).bind({"displayedCollection": displayedCollection}),0);
        
    },
    
    _buildPlayListName : function(){
        if(this.view.isSearchApplied()){
            const searchKey = this.view.getAppliedSearchCharacters();
            return window.getI18n(ti18n,"SEARCH_RESULTS_FROM")+" \""+searchKey+"\" "+window.getI18n(ti18n,"IN_MY_SONG_LIBRARY");
        }else{
            return window.getI18n(ti18n,"ALL_MUSIC_FROM_SONG_LIBRARY");
        }
    },
    
    addSongToQueue :function(songModelSwiped){
        PlayerService.getInstance().addSongsToQueue(this.model.where({uri:songModelSwiped.get("uri")}));
    },
    
    showMenuOption : function(songModelTapped){
        const context = this.getContext();
        PersistentModels.getInstance().getRouter().showSongMenuOption(songModelTapped, context,null, this.onActionExecutedOnSongHandler.bind(this));
    },
    
    onActionExecutedOnSongHandler : function(songModel, action){
        if(action == "delete" || action == "removeFromRadio" || action == "removeToFavorite"){
            //Do notthing because it is already handled by the collection listeners
        }
    },
    
     filterList: function(searchCharaterers, filterOptions, sortOptions){
         if(this.view){
             this.view.filterList(searchCharaterers,null, filterOptions, sortOptions);
         }
     },
     
     clearSearchfilter : function(){
         if(this.view){
             this.view.clearSearchfilter();
         }
     },
    

    getContext : function(){
        return Utils.getInstance().buildContextForSongCollectionMyLibrary();
    },
    
    grade : function(songModel){
        PersistentModels.getInstance().getRouter().showSongTagManagerPopup(songModel, this.context);
        
    },
    
    
    addToRadio : function(songModel){
        return PersistentModels.getInstance().addSongToDefaultRadio(songModel, this.context);
    },
    

    
    removeFromRadio : function(songModel){
        return PersistentModels.getInstance().removeSongFromDefaultRadio(songModel, this.context).then((function(){
            this.onActionExecutedOnSongHandler(songModel,"songRemovedFromTrebble");
        }).bind(this));
    },
    
    
    buildModelAndRenderView : function() {
        return new RSVP.Promise((function(resolve, reject) {
            try {
                if(!this.model)
                {
                    this.model = new SongsTabContentCollection([]);
                    const context = this.getContext();
                    this.model.updateContext(context);
                }
                this.view = new SongsTabContentView({
                    "collection" : this.model
                });
                this.listenTo(this.view, "itemTaped",this.clearQueueAndSongAndRestToQueue);
                this.listenTo(this.view, "itemSwipedRight",this.addSongToQueue);
                this.listenTo(this.view, "songOptionTapped", this.showMenuOption);
                this.listenTo(this.view, "addToRadioButtonTapped", this.addToRadio);
                this.listenTo(this.view, "removeFromRadioButtonTapped", this.removeFromRadio);
                this.listenTo(this.view, "gradeButtonTapped", this.grade);
                this.view.render();
                resolve();
            } catch (error) {
                reject(error);
            }

        }).bind(this));

    }

});

export default SongsTabContentController;