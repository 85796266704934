

import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Chaplin from "chaplin";
import ChooseCapsuleFromLibraryWidgetController from "controllers/capsule/ChooseCapsuleFromLibraryWidgetController";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import PlayerService from "services/PlayerService";
import PersistentModels from "services/PersistentModels";
import LocalStorageHelper from "models/helper/LocalStorageHelper";
import CollectionRepeatController from "controllers/common/CollectionRepeatController";
import TrebbleAudioListItemController from "controllers/common/TrebbleAudioListItemController";
import PlaylistHelper from "models/helper/PlaylistHelper";
import TrebbleAudio from "models/TrebbleAudio";
import FileUploadHelper from "models/helper/FileUploadHelper";
import RolloutHelper from "models/helper/FeatureRolloutHelper";
import ti18n from "i18n!nls/ConfigureBackgroundMusici18n";
import FacebookEventTrackingHelper from "models/helper/FacebookEventTrackingHelper";
import OptionPopupView from "views/common/OptionPopupView";
import Utils from "models/helper/Utils";
import RSVP from "rsvp";

const Rollout = RolloutHelper.getInstance();


const ChooseCapsuleFromLibraryPopupController = Chaplin.Controller.extend({

    initialize : function(options) {
        _.extend(this, Backbone.Events);
        this._selectedCapsuleModel = null;
        this._popupTitle = options.popupTitle;
        this._selectedCategoryId = options.selectedCategoryId;
        this._onCapsuleSelected = options.onCapsuleSelected;
    },


    getModel : function() {
        return this.model;
    },

    getView : function() {
        return this.view;
    },


    onPopupClosed : function(){
        this._onCapsuleSelected(this._selectedCapsuleModel);
    },


    _onCapsuleSelectedViaWidget : function(capsuleModel){
        this._selectedCapsuleModel = capsuleModel;
        this.view.close();
    },


    _buildChooseCapsuleFromLibraryWidgetController : function(){
        const params = {};
        params.onCapsuleSelected = this._onCapsuleSelectedViaWidget.bind(this);
        params.selectedCategoryId = this._selectedCategoryId;
        this.chooseCapsuleFromLibraryWidgetController = new ChooseCapsuleFromLibraryWidgetController(params);
    },

    buildModelAndRenderView : function() {
        this._buildChooseCapsuleFromLibraryWidgetController();
        return this.chooseCapsuleFromLibraryWidgetController.buildModelAndRenderView().then((function(){
            let params = {};
            params.id = "chooseCapsuleFromLibrayPopup";
            params.customClass = "chooseCapsuleFromLibrayPopup";
            params.contentView =  this.chooseCapsuleFromLibraryWidgetController.getView();
            params.contentPosition = "center";
            params.addContentAfterEnhancement = true;
            params.displayTitle = true;
            params.titleToDisplay = this._popupTitle;

            this.view = new OptionPopupView(params);
            this.listenTo(this.view, "closed",this.onPopupClosed);
            this.view.render();
            this.chooseCapsuleFromLibraryWidgetController.setScrollContainer(this.view.$el.find(".selected_content_wrapper"));
        }).bind(this))


    }

});

export default ChooseCapsuleFromLibraryPopupController;