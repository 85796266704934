import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import Song from "models/Song";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import CordovaHelper from "models/helper/CordovaHelper";

/* "models/helper/ImageStoreHelper",*/
import SongsCollection from "collections/myLibrary/SongsCollection";

import ti18n from "i18n!nls/AlbumDetailsi18n";
import pageTemplate from 'text!../../../templates/albumDetail/AlbumDetailPage.html';
import TrebbleSongListView from "views/common/TrebbleSongListView";
import SongListView from "views/common/SongListView";
import Utils from "models/helper/Utils";
import PersistentModels from "services/PersistentModels";
import RSVP from "rsvp";
import moment from "moment";
const SHOW_ALL_SONG_IN_ALBUM_BUTTON_LABEL = "Show All";
const SHOW_ONLY_ON_DEVICE_BUTTON_LABEL = "Show Only On Device";
SongsCollection.prototype.model = PersistentModels.getInstance().getSongContructorFunctionForCollection();
const AlbumDetailPageView = Backbone.View.extend({

    template : _.template(pageTemplate),

    "pageName" : "Album Detail",
    
    
    events : {
        "click #shuffleAlbumBtn" : "onShuffleBtnTapped",
        "click #menuOptionButton" : "onOptionMenuButtonTapped",
        "click #showAllBtn" : "onShowAllSongsInAlbum",
        "click #header-back-button": "navigateToPreviousPage",
        "click  #filterButton" : "filterList",
    },

    initialize : function(options) {
        this.model = options.model;
        let  displayLocalSongAtFirstRendering = true;
        if(options.displayLocalSongAtFirstRendering == true || options.displayLocalSongAtFirstRendering == false){
            displayLocalSongAtFirstRendering = options.displayLocalSongAtFirstRendering ;
        }
        this.listView = options.songListView;
        this.setLocalFileDisplayOnly(displayLocalSongAtFirstRendering);
        this.pageId =  options.pageId;
        this._listenerViewportKey = this.pageId? this.pageId: "albumDetailsPage" + (new Date()).getTime();
        this._defaultBackgroundColor  = "#111111";
    },
    

    filterList : function(){
        this.trigger("filter");
    },

    navigateToPreviousPage : function(){
        PersistentModels.getInstance().getRouter().navigateToPreviousPage();
    },
    
    onShuffleBtnTapped : function(){

        this.trigger("shuffleAlbum");
    },

    onOptionMenuButtonTapped : function(){

        this.trigger("showAlbumOptionPopup");

    },

    isLocalFileDisplayOnly: function(){
        return this.localFileDisplayOnly;
    },

    setLocalFileDisplayOnly: function(localFileDisplayOnly){
        this.localFileDisplayOnly = localFileDisplayOnly;
    },

    onShowAllSongsInAlbum : function(){
        this.setLocalFileDisplayOnly(!this.isLocalFileDisplayOnly());
        this._updateLocalOrAllSongsButtonLabel();
        //this.updateListViewModelAndListViewWrapperSize();
    },


    render : function() {
        this.$el.html(this.template({"model" :this.model.toJSON(), "ti18n": ti18n, "moment": moment}));
        this.setElement(this.$el.find("div").first());
        //this._buildSongList();
        const listRootElement = this.$el.find("#albumSongListWrapper");
        $(listRootElement).append(this.listView.el);
        this.listView.load();
        this.listView.$el.css("background-color",this._defaultBackgroundColor);
        this.$el.page();
        if(this.pageId){
            this.$el.attr("id",this.pageId);
        }
        this.$el.addClass("lighttext");
        this.$el.on("pagehide", this._onPageHide.bind(this));
        this._updateLocalOrAllSongsButtonLabel();
        this.loadCoverArt();
        return this;
    },

    _onPageHide : function(){

        if(PersistentModels.getInstance().getRouter().isBrowserHistoryGoingBack())
        {
            //PersistentModels.getInstance().getRouter().unRegisterForViewportHeightChange(this._listenerViewportKey);
            this.onBeforeRemoveFromPage();
            this.remove();
            this.onRemovedFromPage();
        }
    },
    _updateLocalOrAllSongsButtonLabel: function(){
        const buttonLabel = this.isLocalFileDisplayOnly() ? SHOW_ALL_SONG_IN_ALBUM_BUTTON_LABEL : SHOW_ONLY_ON_DEVICE_BUTTON_LABEL;
        this.$el.find("#showAllBtn").html(buttonLabel);
    },

    loadCoverArt : function(){

        let albumId = this.model.get("albumId");
        if(this.model.get("doNotUseIdifiedUrl") == true)
        {
            albumId = this.model.get("preferredAlbumIdForCoverArt");
        }
        const p = CordovaHelper.getInstance().executePluginOperation("MusicFileReaderPlugin","getAlbumArtInfoFromImageStore", [albumId]);

        p.then((function(albumImageInfo){
            if(albumImageInfo && albumImageInfo != "OK")
            {
                const albumArtBase64Stored = albumImageInfo.originalBase64Image;
                //const albumArtBase64BlurStored = albumImageInfo.originalBlurBase64Image;
                if(albumArtBase64Stored)
                {
                    this._setAlbumArtImage(albumArtBase64Stored);
                }else{
                    //try to retrieve from native 
                    this.retrieveScaledCoverArtsAndUpdateView(false);
                }
            }else{
                this.retrieveScaledCoverArtsAndUpdateView(false);
            }

        }).bind(this));


    },

    _setAlbumArtImage : function(albumArtBase64, albumArtBase64Blur, imageUrl){
        const albumImageElem = this.el.querySelector("#albumImage");
        //albumImageElem.style.height = (window.device && window.device.platform == "browser")?"500px":"400px";
        const imageSrcData = "data:image/png;base64,"+ albumArtBase64 ;
        const imageSrcDataBlur = "data:image/png;base64,"+ albumArtBase64Blur ;
        const base64ImageData = "url('"+ imageSrcData + "')";
        const base64ImageDataBlur = "url('"+ imageSrcDataBlur + "')";
        const tempImg = new Image();
        tempImg.onload  = (function(){
            let backgroundInageBase64 = null;
            const fullCoverArtImage$el = this.self.$el.find("#fullCoverArtImage");
            if(window.trebble.config.disableZoomInCoverArtInMobileApp || (window.device && window.device.platform == "browser")){
                backgroundInageBase64 = imageSrcDataBlur ;
                if(window.device && window.device.platform == "browser"){
                    this.elem.style["background-image"] = base64ImageDataBlur;
                }else{
                    const tempBlurImg = new Image();
                    tempBlurImg.onload = (function(){
                        this.elem.style["background-image"] = "url('"+ this.tempBlurImg.src + "')";
                    }).bind({"tempBlurImg": tempBlurImg,"elem":this.elem});
                    tempBlurImg.src = this.imageSrcDataBlur;
                    tempBlurImg.onload();

                }
                fullCoverArtImage$el.css("background-image", "url('"+ this.tempImg.src + "')" ).addClass("withShadow inFront");
            }else{
                backgroundInageBase64 = this.tempImg.src ;
                this.elem.style["background-image"] = "url('"+ this.tempImg.src + "')";
            }

            Utils.getInstance().getMatchingColorByImageUrl(window.isSafariBrowser? imageUrl: backgroundInageBase64).then((function(result){
                this.self.$el.css("background-color",result.backgroundColor);
                this.self._defaultBackgroundColor  = result.backgroundColor;
                if(result.shouldUserLightColorText){
                    this.self.$el.addClass("lighttext");
                    this.self.$el.removeClass("darktext");
                }else{
                    this.self.$el.removeClass("lighttext");
                    this.self.$el.addClass("darktext");
                }
                this.self.$el.find("#contentWrapper").css("background-color",result.backgroundColor);
                if(this.self.listView){ 
                    this.self.listView.$el.css("background-color",result.backgroundColor);
                }
                const colorString = result.backgroundColorArray.join(",");

                const  basicAlbumInfo$el = this.self.$el.find(".basicAlbumInfo");
                const  cornerDecorator$el = this.self.$el.find(".cornerDecorator");
                const  ui_header$el = this.self.$el.find(".ui-header");
                fullCoverArtImage$el.css({"background-color": "rgb("+ colorString +")"});
                basicAlbumInfo$el.css({"background-image": "-webkit-linear-gradient(top,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                cornerDecorator$el.css({"background-image": "-webkit-linear-gradient(left,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                ui_header$el.css({"background-image": "-webkit-linear-gradient(top,rgba("+ colorString + ",0.5) ,rgba("+ colorString + ",0) 100%)"});
                basicAlbumInfo$el.css({"background-image": "-moz-linear-gradient(top,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                cornerDecorator$el.css({"background-image": "-moz-linear-gradient(left,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                ui_header$el.css({"background-image": "-moz-linear-gradient(top,rgba("+ colorString + ",0.5) ,rgba("+ colorString + ",0) 100%)"});
                basicAlbumInfo$el.css({"background-image": "-ms-linear-gradient(top,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                cornerDecorator$el.css({"background-image": "-ms-linear-gradient(left,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                ui_header$el.css({"background-image": "-ms-linear-gradient(top,rgba("+ colorString + ",0.5) ,rgba("+ colorString + ",0) 100%)"});
                basicAlbumInfo$el.css({"background-image": "-o-linear-gradient(top,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                cornerDecorator$el.css({"background-image": "-o-linear-gradient(left,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                ui_header$el.css({"background-image": "-o-linear-gradient(top,rgba("+ colorString + ",0.5) ,rgba("+ colorString + ",0) 100%)"});
                basicAlbumInfo$el.css({"background-image": "linear-gradient(top,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                cornerDecorator$el.css({"background-image": "linear-gradient(left,rgba("+ colorString + ",0) , rgba("+ colorString +",1) 100%)"});
                ui_header$el.css({"background-image": "linear-gradient(top,rgba("+ colorString + ",0.5) ,rgba("+ colorString + ",0) 100%)"});
                basicAlbumInfo$el.css({"background-image": "progid:DXImageTransform.Microsoft.gradient(startColorstr="+ colorString + ", endColorstr="+ colorString + ")"});
                cornerDecorator$el.css({"background-image": "progid:DXImageTransform.Microsoft.gradient(startColorstr="+ colorString + ", endColorstr="+ colorString + ")"});
                ui_header$el.css({"background-image": "progid:DXImageTransform.Microsoft.gradient(startColorstr="+ colorString + ", endColorstr="+ colorString + ")"});
                if(Utils.getInstance().isBackgroundBlurImageDisabled()){
                    basicAlbumInfo$el.css({"background-image": "none"});
                    cornerDecorator$el.css({"background-image": "none"});
                }
            }).bind(this));
}).bind({"elem":albumImageElem, "base64Data": base64ImageData, "tempImg": tempImg,"self": this, "imageSrcDataBlur": imageSrcDataBlur});
if(imageSrcData){
tempImg.src = imageSrcData;
}else{
tempImg.onload();
}
},


onBeforeRemoveFromPage : function(){
this.trigger("beforeRemoveFromPage", this);
},

onRemovedFromPage : function(){
this.undelegateEvents();
this.$el.removeData().unbind(); 
this.trigger("removedFromPage", this);
},

retrieveScaledCoverArtsAndUpdateView: function(saveCoverArtInLocalDB){

const mp3AlbumId = parseInt(this.model.get("albumId"));
const screenWidth = window.getDocumentClienWidth();
const screenHeight = window.getDocumentClienHeight();
const regularCovertArtWidth = window.getDocumentClienWidth() - 10;
const regularCovertArtHeight = window.getDocumentClienHeight() -220;
const thumbnailWidth = 64;
const thumbnailHeight = 64;
const basicOrIdifiedCoverArtUrl = this.model.get("albumArtURL");
const albumArtImages = this.model.get("albumArtImages");



const paramsArray = [ mp3AlbumId, screenWidth,screenHeight,
regularCovertArtWidth,regularCovertArtHeight,
thumbnailWidth,thumbnailHeight,basicOrIdifiedCoverArtUrl, albumArtImages];
CordovaHelper.getInstance().executePluginOperation("MusicFileReaderPlugin","retrieveScaledCoverArtImages", paramsArray).then((function(scaledCovertArts){
if( scaledCovertArts && scaledCovertArts.isIdifedCoverArt)
{
    const albumModel = this.model;
    if(saveCoverArtInLocalDB){
        CordovaHelper.getInstance().executePluginOperation("MusicFileReaderPlugin","saveAlbumArtInfoToImageStore", [albumModel.get("albumId"), basicOrIdifiedCoverArtUrl,scaledCovertArts.albumArtBase64RegularSize,scaledCovertArts.albumArtBase64BlurScreenSize]);
    }
}
this._setAlbumArtImage(scaledCovertArts?scaledCovertArts.albumArtBase64RegularSize:null,scaledCovertArts?scaledCovertArts.albumArtBase64BlurScreenSize :null, scaledCovertArts.url);
}).bind(this)).catch(function(error){
window.log(error)
//window.alertErrorMessage(JSON.stringify(error));
});
},



});

export default AlbumDetailPageView;