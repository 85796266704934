import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import OptionPopupView from "views/common/OptionPopupView";
import Utils from "models/helper/Utils";
import ti18n from "i18n!nls/CapsuleDetailsi18n";
import popupTemplate from 'text!../../../templates/common/OptionPopup.html';

const AddCapsuleToTrebblePopup = Backbone.View.extend({

    template : _.template(popupTemplate),

    "pageName" : "On-Air For",

    initialize : function(options) {
        this._contentView =  options? options.contentView: null;
        this._capsuleModel =  options? options.capsuleModel: null;
    },


    events : {
        "click #closeBtn" : "_close",		
    },
    
    setContentView : function(contentView){
        this._contentView  = contentView;
    },

    _close : function(){
        this.$el.popup('close' );
    },

    setAddingCapsuleToTrebble : function(addingCapsuleToTrebble){
        if(addingCapsuleToTrebble){
            this._contentView.$el.hide();
            this.loadingWidget$el.show();
        }else{
            this.loadingWidget$el.hide();
            this._contentView.$el.show();
        }
    },

    
    
    render : function() {
        this.$el.html(this.template({"contentPosition": "center","displayTitle": false, "titleToDisplay": ""}));
        this.setElement(this.$el.find("div").first());
        const elementId = this.$el.attr('id');
        this.$el.on({
            popupbeforeposition: (function() {
                const maxHeight = $( window ).height() - 0 + "px";
                const width = $( window ).width() - 0 + "px";
                this.$el.css( "height", maxHeight );
                this.$el.css( "width", width );

            }).bind(this)
        });
        this.$el.on({
            popupafterclose: (function() {
                this.trigger("closed");
                this.remove();
                this.undelegateEvents();

            }).bind(this)});
        $.mobile.pageContainer.prepend(this.$el);
        this.$el.popup({transition:"fade", dismissible: false,  tolerance: "0,0"});
        if(elementId)
        {
            const screenPopup$el = $("#"+elementId + "-screen");
            $.mobile.pageContainer.remove("#"+elementId + "-screen");
            $.mobile.pageContainer.prepend(screenPopup$el);
        }
        if(this._contentView){
            const popupContent$el = this.$el.find(".popupContent");
            popupContent$el.append($("<div class='trebblePopupHeader'>"+ window.getI18n(ti18n,"ADD_ON_AIR_FOR") +"</div>"));
            popupContent$el.append($("<div id='loadingImg' width='100' height='100'  style='display:none;''></div>"));
            popupContent$el.append(this._contentView.$el);
            this.loadingWidget$el = popupContent$el.find("#loadingImg");
        }
        this.$el.enhanceWithin();

        Utils.getInstance().closeCurrentJQMPopup().then((function(){
            this.$el.popup('open' );
        }).bind(this));
        return this;
    }

});

export default AddCapsuleToTrebblePopup;