
import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import WrapSelectedNodesOperation from "models/audioEditor/sequencerOperation/WrapSelectedNodesOperation";
import StartMusicWrapSequencerNode from "models/audioEditor/sequencerNodes/StartMusicWrapSequencerNode";
import Utils from "models/helper/Utils";
import AudioBufferHelper from "models/audioEditor/AudioBufferHelper";
import AudioFilterFactory from "models/audioEditor/filters/AudioFilterFactory";
import RSVP from "rsvp";

const WrapSelectedNodesWithAudioOperation =  WrapSelectedNodesOperation.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        WrapSelectedNodesOperation.apply(this, [attributes, options]);
    },

    execute : function(trebbleAudio,introPaddingInMilliseconds, outroPaddingInMilliseconds, introFadeInMilliseconds, outroFadeInMilliseconds, volumePercentage, loopToMatchWrapNodeDuration, ducking, startSequencerNodeToWrap, endSequencerNodeToWrap ){
        const startMusicWrapSequencerNode = new StartMusicWrapSequencerNode({"audioSegment":Utils.getInstance().getMusicAudioSegment(trebbleAudio.getAudioUrl()), "sequencer": this.getSequencer()});
        startMusicWrapSequencerNode.setAudio(trebbleAudio);
        startMusicWrapSequencerNode.setIntroPaddingInMilliseconds(introPaddingInMilliseconds);
        startMusicWrapSequencerNode.setOutroPaddingInMilliseconds(outroPaddingInMilliseconds);
        startMusicWrapSequencerNode.setIntroFadeInMilliseconds(introFadeInMilliseconds);
        startMusicWrapSequencerNode.setOutroFadeInMilliseconds(outroFadeInMilliseconds);
        startMusicWrapSequencerNode.setMainVolumePercentage(volumePercentage);
        startMusicWrapSequencerNode.setLoopToMatchWrapNodeDuration(loopToMatchWrapNodeDuration);
        startMusicWrapSequencerNode.setDucking(ducking);
        startSequencerNodeToWrap = startMusicWrapSequencerNode.getSelectableStartNodeWrappedIfApplicable(startSequencerNodeToWrap);
        endSequencerNodeToWrap = startMusicWrapSequencerNode.getSelectableEndNodeWrappedIfApplicable(endSequencerNodeToWrap);
        const nodesCreated = this.wrapSequencerNodes(startMusicWrapSequencerNode, startSequencerNodeToWrap, endSequencerNodeToWrap);
        this.getSequencer().getAudioBufferCache().loadAudioBufferFromUrl(trebbleAudio.getAudioUrl(), trebbleAudio.getAudioUrl(), AudioBufferHelper.getInstance().getAudioTypes().WRAP, this.getSequencer().getSequencerSettings());
        this.getSequencer().getHistoryManagement().addCreateWrapWithMusicLog(startSequencerNodeToWrap, endSequencerNodeToWrap, nodesCreated.startWrapSequencerNode, nodesCreated.endWrapSequencerNode);
    },

    
    undo : function(sequencerOperationWrapWithMusicLog ){
        this.revertWrapNodeCreation(sequencerOperationWrapWithMusicLog.getStartWrapMusicNode());
    },

    redo: function(sequencerOperationWrapWithMusicLog){
        const nodesCreated = this.wrapSequencerNodes(sequencerOperationWrapWithMusicLog.getStartWrapMusicNode(), sequencerOperationWrapWithMusicLog.getStartSequencerNodeToWrap(), sequencerOperationWrapWithMusicLog.getEndSequencerNodeToWrap());
        this.getSequencer().getAudioBufferCache().loadAudioBufferFromUrl(sequencerOperationWrapWithMusicLog.getStartWrapMusicNode().getAudio().getAudioUrl(), sequencerOperationWrapWithMusicLog.getStartWrapMusicNode().getAudio().getAudioUrl(), AudioBufferHelper.getInstance().getAudioTypes().WRAP, this.getSequencer().getSequencerSettings());

    },

});


export default WrapSelectedNodesWithAudioOperation; 