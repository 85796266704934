import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerOperationLog from "models/audioEditor/sequencerOperationLog/SequencerOperationLog";
import RSVP from "rsvp";

const SequencerOperationTurnNoiseCancellationOnOperationLog =  SequencerOperationLog.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperationLog.apply(this, [attributes, options]);	
    },

    getOperationName : function(){
        return "Turn Noise Cancellation On";
    },

    setNoiseCancellationOn : function(noiseCancellationOn){
        this.set("noiseCancellationOn", noiseCancellationOn);
    },

    getNoiseCancellationOn : function(){
        return this.get("noiseCancellationOn");
    },


});

export default SequencerOperationTurnNoiseCancellationOnOperationLog; 