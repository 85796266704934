
import _ from 'underscore';
import Backbone from "backbone";
import SequencerNodeUtils from 'models/audioEditor/SequencerNodeUtils';
import JSZip from 'jszip';
import Utils from "models/helper/Utils";
import PersistentModels from "services/PersistentModels";
import TrebbleClientAPIHelper from "models/helper/TrebbleClientAPI";
import FileUploadHelper from "models/helper/FileUploadHelper";
import AudioClassificationHelper from 'models/audioEditor/AudioClassificationHelper';
import LanguageDetectionHelper from "models/audioEditor/LanguageDetectionHelper";


const SequencerEditBoundariesClipDownloadUtils = Backbone.Model.extend({
    idAttribute: "id",

    getBlobOfFileWithEditBoundary: async function (sequencer, sequencerNodeWithEditBoundary, includeDeletedSegmentsWhenPossible){
        const firstSequencerNodeToDownload = SequencerNodeUtils.getInstance().getPreviousWordSequencerNodeAtBeginningOfSentence(sequencerNodeWithEditBoundary, 4, true);
        const lastSequencerNodeToDownload = SequencerNodeUtils.getInstance().getNextWordSequencerNodeAtEndOfSentence(sequencerNodeWithEditBoundary, 4, true);
        const shouldSequencerNodeBePlayedValidatorFunction = (sequencerNode)=>{
            if(Utils.getInstance().isNonRetainedDeletedSequencerNode(sequencerNode)){
                //return sequencerNodeWithEditBoundary.getPrevious() === sequencerNode
                return true;
            }
            if(Utils.getInstance().isDeletedSequencerNodeInstance(sequencerNode)){
                //return sequencerNodeWithEditBoundary.getPrevious() === sequencerNode;
                return true;
            }else{
                return true;
            }
        }
        const progressReportFunction = (progress, operationInProgress)=>{
                if(progress){
                    console.log(`operationInProgress: ${operationInProgress}`);
                    console.log(`progress: ${Math.round(progress)}%`);
                }else{
                    console.log(`operationInProgress: ${operationInProgress}`);
                    console.log(`loading...`);
                }
        }
        const blob = await sequencer.getRenderedAudioBlobClip(true, firstSequencerNodeToDownload, lastSequencerNodeToDownload, false, progressReportFunction, null, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible);
        const audioClassificationArray = {};// await AudioClassificationHelper.getInstance().decodeAndClassify(blob);
        const {transcriptionInfoArray, editBoundariesTimestamps, totalDurationInMilliseconds, deletedRegionsArray, srtText} = await sequencer.getTranscriptionAndEditsInfo(firstSequencerNodeToDownload, lastSequencerNodeToDownload, progressReportFunction, shouldSequencerNodeBePlayedValidatorFunction, includeDeletedSegmentsWhenPossible);
        return {transcriptionInfoArray, editBoundariesTimestamps, srtText, audioClassificationArray, totalDurationInMilliseconds,deletedRegionsArray, blob} ;
    },

    uploadFileToCloudStorage : async function(projectIdOrTranscriptionId, filename, mimeType, blob){
        const { presignedUrl, fileLocationUrl} = await TrebbleClientAPIHelper.getInstance().getPresignedUrlForDataUploadForEditQualityClassifier(projectIdOrTranscriptionId, filename, false, mimeType);
        await FileUploadHelper.getInstance().uploadBlobToS3UsignPreSignedUrlWithProgress(blob, presignedUrl);
        return fileLocationUrl;
    },

    convertDeletedSequencerNodeTranscriptionInfoForLabeling: function(deletedSequencerNodeTranscriptionInfo){
        const arrayOfTranscriptionInfoToReturn = [];
        if(deletedSequencerNodeTranscriptionInfo.referenceNodesArrayOrdered){
            deletedSequencerNodeTranscriptionInfo.map((sequencerNode)=>{

            })
        }
    },

    convertArrayTranscriptionInfoForLabeling :function(arrayOfTranscriptionInfo, filterFunction){
        const filteredArrayOfTranscriptionInfo = filterFunction? arrayOfTranscriptionInfo.filter(filterFunction): arrayOfTranscriptionInfo;
        return filteredArrayOfTranscriptionInfo.map((transcriptionInfo)=>{
            return {...transcriptionInfo, start: transcriptionInfo.startTime/1000 , end: transcriptionInfo.endTime/1000, text: transcriptionInfo.content, author: transcriptionInfo.speakerId?transcriptionInfo.speakerId:"unknown" };
        })

    },
    convertMillisecondsInSeconds : function(timestampInMilliseconds){
        return timestampInMilliseconds/1000
    },

    _createPredictionsFromTimestampsArray: function(timestampsArray, totalDurationInMilliseconds, edit_timestamp, from_name, to_name){
        return timestampsArray.map((timestampInMilliseconds)=>{
            const TIME_STAMP_PADDING = 10
            return {
                "original_length": totalDurationInMilliseconds/1000,
                "value": {
                "start": (timestampInMilliseconds - TIME_STAMP_PADDING) < 0? 0: (timestampInMilliseconds -TIME_STAMP_PADDING)/1000,
                "end": (timestampInMilliseconds + TIME_STAMP_PADDING) > totalDurationInMilliseconds? totalDurationInMilliseconds/1000: (timestampInMilliseconds +TIME_STAMP_PADDING)/1000,
                "channel": 0,
                "labels": [
                    edit_timestamp
                ]
                },
                "readonly": true,
                "id": Utils.getInstance().generateRandomId(),
                "from_name": from_name,
                "to_name": to_name,
                "type": "labels",
            }
        })
    },

    _createPredictionsFromDeletedRegionArray: function(deletedRegionArray, totalDurationInMilliseconds, edit_timestamp, from_name, to_name){
        return deletedRegionArray.map((deletedRegion)=>{
            return {
                "original_length": totalDurationInMilliseconds/1000,
                "value": {
                "start": deletedRegion.start,
                "end": deletedRegion.end,
                "channel": 0,
                "labels": [
                    edit_timestamp
                ]
                },
                "readonly": false,
                "id": Utils.getInstance().generateRandomId(),
                "from_name": from_name,
                "to_name": to_name,
                "type": "labels",
            }
        })
    },




    downloadAllClipWithEditBoundaries : async function (sequencer, searchWidgetModel){
        let progressBarPopupController = null;
        const progressReportFunction = function (progress, operationInProgress){
            if (progressBarPopupController){
                let defaultProgresMessageOnUpload = "Generating edited clips...";
                if (progress){
                    progressBarPopupController.setIndeterminate(false);
                    progressBarPopupController.setProgressMessage(operationInProgress ? operationInProgress : defaultProgresMessageOnUpload);
                    progressBarPopupController.setPercentageProgress(Math.round(progress) + "%");
                }else{
                    progressBarPopupController.setIndeterminate(true);
                    progressBarPopupController.setProgressMessage(operationInProgress ? operationInProgress : defaultProgresMessageOnUpload);
                }
            }
        }

        PersistentModels.getInstance().getRouter().showProgressBarPopupController().then(function(popupController){
            progressBarPopupController = popupController;
            progressReportFunction();

        })
        try{
            const zip = new JSZip();
            let projectId = sequencer.getProjectId()? sequencer.getProjectId(): sequencer.getLoadedProjectId();
            let transcriptionIds = sequencer.getAllTranscriptionIds();
            let filenameBase ;
            let url = ""
            if(projectId && !filenameBase){ 
                filenameBase =`p_${projectId}`;
                url = "https://beta-web.trebble.fm/createShortcastFromProject_"+projectId
            }
            if(!filenameBase && transcriptionIds && transcriptionIds.length > 0){
                filenameBase = `t_${transcriptionIds[0]}`;
                url = "https://beta-web.trebble.fm/createShortcastFromTranscription_"+transcriptionIds[0]
            }
            const projectIdOrTranscriptionId = filenameBase? `${filenameBase}`:'unknown'
            if(!filenameBase ){
                filenameBase = `random_${Utils.getInstance().generateRandomId()}`;
            }
            
            const dataCreatedArray = [];
            const filesDirectory = zip.folder(filenameBase);

            const filterForWordOnlyInTranscriptionInfo = (transcriptionInfo) =>{return transcriptionInfo.type === "word"};

            const filterForWordAndDeleteUnregonizedInTranscriptionInfo = (transcriptionInfo)=>{
                return transcriptionInfo.type === "word" || (transcriptionInfo.type === "unrecognized" && transcriptionInfo.wasDeleted)
            }

            const generateTableHTML =  function(data) {
                let tableHTML = `
                    <table>
                        <thead>
                            <tr>
                                <th>Start</th>
                                <th>End</th>
                                <th>Content</th>
                                <th>Duration</th>
                                <th>Type</th>
                                <th>Confidence</th>
                            </tr>
                        </thead>
                        <tbody>
                `;
            
                data.forEach(item => {
                    tableHTML += `
                        <tr>
                            <td>${item.start}</td>
                            <td>${item.end}</td>
                            <td>${item.content}</td>
                            <td>${item.duration}</td>
                            <td>${item.type}</td>
                            <td>${item.confidence}</td>
                        </tr>
                    `;
                });
            
                tableHTML += `
                        </tbody>
                    </table>
                `;
            
                return tableHTML;
            }
            

        
            if(searchWidgetModel.getAllArraysOfNodesMatchingResults() && searchWidgetModel.getAllArraysOfNodesMatchingResults().length > 0){
                for(let i = 0; i < searchWidgetModel.getAllArraysOfNodesMatchingResults().length; i++){
                    const uniqueIdsOfEditedFiles = [];
                    const resultArray = searchWidgetModel.getAllArraysOfNodesMatchingResults()[i];
                    if(resultArray && resultArray.length > 0){
                        let languageLastUsed = null;
                        for(let ii = 0; ii < resultArray.length; ii++){
                            const sequencerNode = resultArray[ii];
                            const filename = `${filenameBase}${Utils.getInstance().generateRandomId()}_${(new Date()).toISOString().slice(0, 10)}`;
                            const {transcriptionInfoArray:transcriptionInfoArrayEdited, editBoundariesTimestamps: editBoundariesTimestampsEdited,blob: editedClipBlob, srtText: editedClipSrtText,audioClassificationArray: editedAudioClassificationArray,totalDurationInMilliseconds: editedFileTotalDuration} = await this.getBlobOfFileWithEditBoundary(sequencer, sequencerNode, false);
                            const uniqueIdentifier = JSON.stringify(transcriptionInfoArrayEdited);
                            
                            if(!uniqueIdsOfEditedFiles.includes(uniqueIdentifier)){
                                uniqueIdsOfEditedFiles.push(uniqueIdsOfEditedFiles);
                                const dataCreated = {projectId, url}
                                const editedAudioFileName = `${filename}_edited.wav`;
                                const editedTranscriptionFileName = `${filename}_transcriptionInfo_edited.json`;
                                const editedAudioClassificationsFileName = `${filename}_audio_classification_edited.json`;
                                const editTimestampsInEditedFileName = `${filename}_editBoundariesTimestamps_edited.json`;
                                const uneditedAudioFileName = `${filename}_unedited.wav`;
                                const uneditedTranscriptionFileName = `${filename}_transcriptionInfo_unedited.json`;
                                const uneditedAudioClassificationsFileName = `${filename}_audio_classification_unedited.json`;
                                const editTimestampsInUneditedFileName = `${filename}_editBoundariesTimestamps_unedited.json`;
                                const deletedRegionsInUneditedFile = `${filename}_deleted_regions_in_unedited.json`;
                                const jsonDataInfoFile = `${filename}_data.json`;
                                dataCreated.edited_wav_file = await this.uploadFileToCloudStorage(projectIdOrTranscriptionId, editedAudioFileName, Utils.getInstance().getMimeTypeFromUrl(editedAudioFileName), editedClipBlob);
                                dataCreated.edited_transcription_word_only = this.convertArrayTranscriptionInfoForLabeling(transcriptionInfoArrayEdited,filterForWordOnlyInTranscriptionInfo)
                                dataCreated.edited_transcription_word_only_in_table_html = generateTableHTML(dataCreated.edited_transcription_word_only);
                                dataCreated.edited_transcription_file = await this.uploadFileToCloudStorage(projectIdOrTranscriptionId, editedTranscriptionFileName, Utils.getInstance().getMimeTypeFromUrl(editedTranscriptionFileName), new Blob([JSON.stringify(transcriptionInfoArrayEdited)], {type: Utils.getInstance().getMimeTypeFromUrl(editedTranscriptionFileName)}));
                                dataCreated.edited_audio_classifications = await this.uploadFileToCloudStorage(projectIdOrTranscriptionId, editedAudioClassificationsFileName, Utils.getInstance().getMimeTypeFromUrl(editedAudioClassificationsFileName), new Blob([JSON.stringify(editedAudioClassificationArray)], {type: Utils.getInstance().getMimeTypeFromUrl(editedAudioClassificationsFileName)}));
                                dataCreated.edit_timestamps_in_edited_in_seconds = editBoundariesTimestampsEdited.map(this.convertMillisecondsInSeconds);
                                dataCreated.edit_timestamps_in_edited_in_seconds_in_list = `<ul>${editBoundariesTimestampsEdited.map(this.convertMillisecondsInSeconds).reduce((acc, v)=>{return `${acc}<li>${v}</li>`},"")}</ul>`;
                                dataCreated.edit_timestamps_in_edited_in_display_string = editBoundariesTimestampsEdited.map(Utils.getInstance().convertTimeInSrtFormat);
                                dataCreated.edited_wav_file_srt_text = this.convertArrayTranscriptionInfoForLabeling(transcriptionInfoArrayEdited,filterForWordOnlyInTranscriptionInfo).map((t)=>{return t.content}).join(" ");
                                dataCreated.edit_timestamps_in_edited_file = await this.uploadFileToCloudStorage(projectIdOrTranscriptionId, editTimestampsInEditedFileName, Utils.getInstance().getMimeTypeFromUrl(editTimestampsInEditedFileName), new Blob([JSON.stringify(editBoundariesTimestampsEdited)], {type: Utils.getInstance().getMimeTypeFromUrl(editTimestampsInEditedFileName)}));
                                const {transcriptionInfoArray: transcriptionInfoArrayUnedited, editBoundariesTimestamps: editBoundariesTimestampsUnedited,blob: uneditedClipBlob,srtText : uneditedClipSrtText,audioClassificationArray: uneditedAudioClassificationArray,totalDurationInMillisecods: uneditedFileTotalDuration, deletedRegionsArray} = await this.getBlobOfFileWithEditBoundary(sequencer, sequencerNode, true);
                                dataCreated.unedited_wav_file = await this.uploadFileToCloudStorage(projectIdOrTranscriptionId, uneditedAudioFileName, Utils.getInstance().getMimeTypeFromUrl(uneditedAudioFileName), uneditedClipBlob, {type: Utils.getInstance().getMimeTypeFromUrl(uneditedAudioFileName)});
                                dataCreated.unedited_transcription_word_only = this.convertArrayTranscriptionInfoForLabeling(transcriptionInfoArrayUnedited,filterForWordOnlyInTranscriptionInfo)
                                dataCreated.unedited_transcription_word_only_in_table_html = generateTableHTML(dataCreated.unedited_transcription_word_only);
                                dataCreated.unedited_transcription_file = await this.uploadFileToCloudStorage(projectIdOrTranscriptionId, uneditedTranscriptionFileName, Utils.getInstance().getMimeTypeFromUrl(uneditedTranscriptionFileName), new Blob([JSON.stringify(transcriptionInfoArrayUnedited)], {type: Utils.getInstance().getMimeTypeFromUrl(uneditedTranscriptionFileName)}));
                                dataCreated.unedited_audio_classifications = await this.uploadFileToCloudStorage(projectIdOrTranscriptionId, uneditedAudioClassificationsFileName, Utils.getInstance().getMimeTypeFromUrl(uneditedAudioClassificationsFileName), new Blob([JSON.stringify(uneditedAudioClassificationArray)], {type: Utils.getInstance().getMimeTypeFromUrl(uneditedAudioClassificationsFileName)}));
                                dataCreated.edit_timestamps_in_unedited_in_seconds = editBoundariesTimestampsUnedited.map(this.convertMillisecondsInSeconds);
                                dataCreated.edit_timestamps_in_unedited_in_seconds_in_list = `<ul>${editBoundariesTimestampsUnedited.map(this.convertMillisecondsInSeconds).reduce((acc, v)=>{return `${acc}<li>${v}</li>`},"")}</ul>`;
                                dataCreated.edit_timestamps_in_unedited_in_display_string = editBoundariesTimestampsUnedited.map(Utils.getInstance().convertTimeInSrtFormat);
                                dataCreated.edit_timestamps_in_unedited_file =  await this.uploadFileToCloudStorage(projectIdOrTranscriptionId, editTimestampsInUneditedFileName, Utils.getInstance().getMimeTypeFromUrl(editTimestampsInUneditedFileName), new Blob([JSON.stringify(editBoundariesTimestampsUnedited)], {type: Utils.getInstance().getMimeTypeFromUrl(editTimestampsInUneditedFileName)}));
                                dataCreated.unedited_wav_file_srt_text = this.convertArrayTranscriptionInfoForLabeling(transcriptionInfoArrayUnedited,filterForWordAndDeleteUnregonizedInTranscriptionInfo).map((t)=>{return t.content && t.wasDeleted?`[${t.content}]`:t.content}).join(" ");
                                dataCreated.deleted_region_in_unedited = this.convertArrayTranscriptionInfoForLabeling(deletedRegionsArray);//this.convertArrayTranscriptionInfoForLabeling(transcriptionInfoArrayUnedited, (r)=>{return r.type === "deleted_section"});
                                dataCreated.deleted_region_in_unedited_file = await this.uploadFileToCloudStorage(projectIdOrTranscriptionId, deletedRegionsInUneditedFile, Utils.getInstance().getMimeTypeFromUrl(deletedRegionsInUneditedFile), new Blob([JSON.stringify(dataCreated.deleted_region_in_unedited)], {type: Utils.getInstance().getMimeTypeFromUrl(deletedRegionsInUneditedFile)}));
                                dataCreated.languages_used = languageLastUsed? languageLastUsed: await LanguageDetectionHelper.getInstance().detect(dataCreated.edited_wav_file_srt_text);
                                if(!languageLastUsed){
                                    languageLastUsed = dataCreated.languages_used;
                                }
                                dataCreated.json_data_info_file_name = jsonDataInfoFile;
                                const predictions = [{model_version:"trebble","score": 1, result: this._createPredictionsFromTimestampsArray(editBoundariesTimestampsEdited, editedFileTotalDuration,"edit timestamp","edit_timestamp_labels", "audioEdited").concat(this._createPredictionsFromDeletedRegionArray(dataCreated.deleted_region_in_unedited, uneditedFileTotalDuration,"deleted region","deleted_timestamp_labels", "audioUnedited"))}];
                                dataCreated.json_data_info_file_url = await this.uploadFileToCloudStorage("data-summary-json", jsonDataInfoFile, Utils.getInstance().getMimeTypeFromUrl(jsonDataInfoFile), new Blob([JSON.stringify({data:dataCreated, predictions})], {type: Utils.getInstance().getMimeTypeFromUrl(jsonDataInfoFile)}));
                                dataCreatedArray.push(dataCreated);
                            }else{
                                console.log("this file was already added",transcriptionInfoArrayEdited)
                            }
                        }
                    }
                    progressReportFunction((i+1)*100/ searchWidgetModel.getAllArraysOfNodesMatchingResults().length)
                }
            }
            progressReportFunction(null);
            const csvFilename = `${filenameBase}_${(new Date()).toISOString().slice(0, 10)}_info.csv`;
            const csvUploadUrl = await this.uploadFileToCloudStorage("data-summary-csv", csvFilename, Utils.getInstance().getMimeTypeFromUrl(csvFilename), new Blob([Utils.getInstance().writeCsv(dataCreatedArray)], {type: Utils.getInstance().getMimeTypeFromUrl(csvFilename)}));
            console.log("csv file uploaded:"+csvUploadUrl);
            filesDirectory.file(csvFilename, Utils.getInstance().writeCsv(dataCreatedArray),{binary: false, date: new Date()}).name;
            const zippedFileBlob = await zip.generateAsync({type: "blob", compression: "DEFLATE"});
            await Utils.getInstance().downloadFile(zippedFileBlob, `${filenameBase}.zip`)
            progressBarPopupController.close();
        }catch(error){
            progressBarPopupController.close();
            window.alertErrorMessage("Something went wrong while generating the clips. Error:"+error);
        }

    },

});



const SequencerEditBoundariesClipDownloadUtilsInstance = new SequencerEditBoundariesClipDownloadUtils();

export default {
    getInstance: function ()
    {
        return SequencerEditBoundariesClipDownloadUtilsInstance;
    }
}; 