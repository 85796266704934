import $ from 'jquery';
import _ from 'underscore';
import Backbone from "backbone";
import SequencerOperationLog from "models/audioEditor/sequencerOperationLog/SequencerOperationLog";
import RSVP from "rsvp";

const SequencerOperationChangeSequencerNodePropertyLog =  SequencerOperationLog.extend({
    idAttribute: "id",


    constructor: function(attributes, options) {
        SequencerOperationLog.apply(this, [attributes, options]);	
    },

    getOperationName : function(){
        return "Change Sequencer Node Property";
    },

    setSequencerNode : function(sequencerNode){
        this.set("sequencerNode", sequencerNode);
    },

    getSequencerNode : function(){
        return this.get("sequencerNode");
    },

    getUpdatePropertyFunctionName : function(){
        return this.get("updatePropertyFunctionName");
    },

    setUpdatePropertyFunctionName : function(updatePropertyFunctionName){
        this.set("updatePropertyFunctionName", updatePropertyFunctionName);
    },

    getOldValue : function(){
        return this.get("oldValue");
    },

    setOldValue : function(oldValue){
        this.set("oldValue", oldValue);
    },

    getNewValue : function(){
        return this.get("newValue");
    },

    setNewValue : function(newValue){
        this.set("newValue", newValue);
    },


});

export default SequencerOperationChangeSequencerNodePropertyLog; 